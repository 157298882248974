import React from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";

export interface AutocompleteTextFieldComponentProps {
  property: string;
  label: string;
  value: string;
  options: object[];
  pathValue: string;
  pathName: string;
  isLoading?: boolean;
  handleEnterSearchText: (key: string) => void;
  handleSetSearchText: (searchText: string) => void;
  setValue: (value: object) => void;
}

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: 10,
  },
}));

export const AutocompleteTextFieldComponent: React.FC<AutocompleteTextFieldComponentProps> =
  (props: AutocompleteTextFieldComponentProps) => {
    const classes = useStyles();

    return (
      <React.Fragment>
        <Autocomplete
          id={"autocomplete"}
          onKeyDown={(event) => props.handleEnterSearchText(event.key)}
          inputValue={props.value}
          options={props.options}
          onInputChange={(_event: any, newInputValue) =>
            props.handleSetSearchText(newInputValue)
          }
          onChange={(_event: any, value: any) =>
            props.setValue(get(value, "_source"))
          }
          getOptionLabel={(option) => get(option, props.pathName, "")}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {props.isLoading ? (
                      <CircularProgress color="primary" size={18} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              className={classes.textField}
              label={props.label}
              variant="outlined"
            />
          )}
        />
      </React.Fragment>
    );
  };
