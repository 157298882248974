import * as React from "react";
import { FC } from "react";
import { ICellChipProps } from "./CellChip.interfaces";
import { Box, Chip, Tooltip } from "@mui/material";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import { defaultTo } from "lodash";
import { getColorByStatus } from "../../../shared/utils/general_utils";
import { CATALOG_TRANSACTION_STATUS } from "../../../shared/enums/TransactionStatusEnum";

export const CellChipStatus: FC<ICellChipProps> = ({
  text,
  textTooltipMain = "",
}: ICellChipProps) => {
  const color_status = getColorByStatus(defaultTo(text, ""));
  const label_text: string = defaultTo(
    CATALOG_TRANSACTION_STATUS[defaultTo(text, "")],
    ""
  );

  if (text !== StatusEnum.PENDING) textTooltipMain = "";

  return (
    <Box>
      <Tooltip title={`${textTooltipMain}`} placement="top">
        <Chip
          data-testid={"chip-status"}
          label={label_text}
          size="small"
          color={color_status}
        />
      </Tooltip>
    </Box>
  );
};
