import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  copyContainer: {
    flexDirection: "row-reverse",
  },
  informationCard: {
    background: "#F7FAFC",
    borderColor: "neutral.grey4",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px !important",
    marginBottom: "24px",
    paddingBottom: "16px",
    paddingLeft: "16px",
  },
  transactionDataText: {
    "&:after": {
      borderBottom: "0 !important",
      borderWidth: "0 !important",
    },
    "&:before": {
      borderBottom: "0 !important",
      borderWidth: "0 !important",
    },
    "&:hover:after": {
      borderBottom: "0 !important",
      borderWidth: "0 !important",
    },
    "&:hover:before": {
      borderBottom: "0 !important",
      borderWidth: "0 !important",
    },
    borderWidth: 0,
    color: "text.dark",
    fontFamily: "IBM Plex Mono !important",
    fontSize: "14px !important",
    fontWeight: "400",
    lineHeight: "179.5%",
    paddingTop: 0,
    whiteSpace: "pre-wrap",
    width: "100%",
    wordBreak: "break-all",
  },
};
