/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_GUATEMALA: IOptionSelection[] = [
  { value: "Agromercantil", label: "Agromercantil" },
  { value: "Banco Azteca", label: "Banco Azteca" },
  { value: "Banco de Antigua", label: "Banco de Antigua" },
  { value: "Banco BAC", label: "Banco BAC" },
  { value: "Banco Citi", label: "Banco Citi" },
  { value: "Banco Credito Hipotecario", label: "Banco Credito Hipotecario" },
  { value: "Banco Bantrab", label: "Banco Bantrab" },
  { value: "Banco G&T Continental", label: "Banco G&T Continental" },
  { value: "Banco Industrial", label: "Banco Industrial" },
  { value: "Banco Inmobiliario", label: "Banco Inmobiliario" },
  { value: "Banco Internacional", label: "Banco Internacional" },
  { value: "Banco Ficohsa", label: "Banco Ficohsa" },
  { value: "Banco Reformador", label: "Banco Reformador" },
  { value: "Banco Vivibanco", label: "Banco Vivibanco" },
];
