export enum FilterLabelEnum {
  PAYMENT = "Abono",
  CHARGE = "Cargo",
  MASTERCARD = "Mastercard",
  VISA = "Visa",
  AMEX = "Amex",
  CARNET = "Carnet",
  DEBIT = "Débito",
  CREDIT = "Crédito",
  CONCILIATION = "Conciliado",
  OMIT = "Omitido",
  NO_CONCILIATION = "No conciliado",
}
