import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";
import infoSVG from "../../../assets/images/info.svg";
import { useConciliationState } from "./state/useConciliationState";
import { Controller, ControllerRenderProps, useWatch } from "react-hook-form";
import { UploadCard } from "../../UploadConciliation/UploadCard";
import { OriginEnum } from "../../../shared/infrastructure/OriginEnum";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  setConciliationUploadError,
  setModalConciliation,
} from "../../../store/actionCreators";
import { ConciliationTitleEnum } from "../../../shared/infrastructure/ConciliationTitleEnum";
import { get, sumBy } from "lodash";
import { TransactionData } from "../../../../types/transaction_data";
import { ActionModal } from "../../ActionModal/ActionModal";
import { ActionModalContentEnum } from "../../../shared/infrastructure/ActionModalContentEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { CloudSecurityEnum } from "../../../shared/infrastructure/CloudSecurityEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    buttonReconcile: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      "&:disabled": {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
      },
      padding: "6px 24px",
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,
    },
    dialogContentText: {
      marginBottom: 24,
    },
    dialogContentTextStyle: {
      fontSize: "15px",
      fontWeight: "normal",
      color: ConciliationTitleEnum.greyColor,
    },
    dialogContentComment: {
      color: ConciliationTitleEnum.greyColor,
      fontSize: "16px",
      fontWeight: 500,
    },
    dialogContentCommentIcon: {
      color: ConciliationTitleEnum.greyColor,
      fontSize: "16px",
      fontWeight: 500,
      paddingLeft: "5px",
    },
    dialogInstructionTF: {
      color: ConciliationTitleEnum.greyColor,
      fontSize: "14px",
      fontWeight: 500,
    },
    dialogInstructionRed: {
      color: ConciliationTitleEnum.redColor,
      fontSize: "14px",
      fontcolor: ConciliationTitleEnum.redColor,
      fontWeight: 500,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    textFieldFont: {
      color: ConciliationTitleEnum.blackColor,
      fontSize: "16px",
      fontcolor: ConciliationTitleEnum.blackColor,
      fontWeight: 500,
    },
    textFieldError: {
      color: ConciliationTitleEnum.redColor,
      fontSize: "16px",
      fontcolor: ConciliationTitleEnum.redColor,
      fontWeight: 500,
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    cancelAction: {
      color: theme.palette.primary.dark,
      border: "1px solid #CBD5E0",
      padding: "6px 24px",
    },
    titleFont: {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
    textField: {
      "& .MuiInputBase-root": {
        height: "auto",
      },
    },
  })
);

export const ConcilationModel = () => {
  const {
    form,
    submit,
    uploadError,
    fileUploader,
    isUploadedConciliation,
    isUploadingConciliation,
    handleAlertModalSubmit,
    handleCloseAlertModal,
    openAlertModal,
    actionModalProps,
  } = useConciliationState();
  const { modalConciliation } = useSelector((state: IAppState) => state);
  const useField = useWatch({ control: form.control, name: "comment" });
  const totalCheck: number = sumBy(
    modalConciliation?.transactions,
    (item: TransactionData) => get(item, "net_amount", 0)!
  );
  totalCheck.toFixed(2);

  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      setModalConciliation({
        conciliation: OriginEnum.SUMMARY,
        conciliationMasive: false,
        transactions: [],
        transactionsNoSelected: [],
        open: false,
        total: 0,
      })
    );
    fileUploader.handlerDelete({ prop: "conciliationFile" });
    dispatch(setConciliationUploadError(false));
    form.reset();
  };
  const assignTitle = (): string => {
    return modalConciliation!.conciliation === OriginEnum.SUMMARY
      ? ConciliationTitleEnum.registro
      : modalConciliation!.conciliationMasive
      ? ConciliationTitleEnum.trxs
      : ConciliationTitleEnum.trx;
  };

  const messageComment = (): string => {
    return useField === ""
      ? `${ConciliationTitleEnum.max100}`
      : useField.length <= 100
      ? `${ConciliationTitleEnum.max100} (${100 - useField.length})`
      : `${ConciliationTitleEnum.exc100}`;
  };

  return (
    <React.Fragment>
      <Dialog
        open={modalConciliation!.open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Typography
              id={"my_typo"}
              variant="h5"
              color="primary"
              className={classes.titleFont}
            >
              {assignTitle()}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start" onClick={handleClose}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                handleClose();
              }}
            >
              <X size={18} />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          {modalConciliation!.conciliation === OriginEnum.SUMMARY ? (
            <div id="liquidationAmount_controller">
              <Typography
                className={classes.dialogContentComment}
                style={{ marginBottom: "10px" }}
              >
                <b>{ConciliationTitleEnum.valueLiquidate}</b>
              </Typography>
              <Controller
                name={"liquidationAmount"}
                control={form.control}
                rules={{
                  required: true,
                  pattern: /((\d+)(\.\d{2}))$/,
                  validate: (value) => value !== "0.00",
                  min: 0,
                }}
                defaultValue={""}
                render={({ field }: { field: ControllerRenderProps }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      classes: {
                        input: !form.formState.errors.liquidationAmount
                          ? classes.textField
                          : classes.textFieldError,
                      },
                    }}
                    label="Valor liquidado"
                    error={!!form.formState.errors.liquidationAmount}
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                  />
                )}
              />
              <Typography
                className={classes.dialogInstructionTF}
                style={{ marginLeft: "18px" }}
              >
                {ConciliationTitleEnum.mustDecimal}
              </Typography>
            </div>
          ) : (
            <div></div>
          )}
          {modalConciliation!.conciliationMasive && (
            <Typography
              id={"tgyMasive"}
              style={{
                fontSize: "15px",
                fontWeight: "normal",
                color: ConciliationTitleEnum.greyColor,
              }}
            >
              Se conciliarán{" "}
              <b>{get(modalConciliation, "transactions", []).length}</b>{" "}
              transacciones por un monto de <b>{totalCheck.toFixed(2)} MXN</b>.
              Te quedan{" "}
              <b>
                {get(modalConciliation, "transactionsNoSelected", []).length}{" "}
                transacciones pendientes.
              </b>
            </Typography>
          )}
          <Typography
            className={classes.dialogContentComment}
            style={{ marginTop: "20px" }}
          >
            <b>{ConciliationTitleEnum.commentAction}</b>
          </Typography>
          <div className={classes.textField} id={"comment_controller"}>
            <Controller
              name={"comment"}
              control={form.control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }: { field: ControllerRenderProps }) => (
                <TextField
                  {...field}
                  InputProps={{
                    classes: {
                      input:
                        useField.length <= 100
                          ? classes.dialogInstructionTF
                          : classes.dialogInstructionRed,
                    },
                  }}
                  placeholder={"Comentario"}
                  fullWidth
                  multiline
                  rows="1"
                  rowsMax="10"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </div>
          <Typography className={classes.dialogInstructionTF}>
            {messageComment()}
          </Typography>

          <Grid
            container
            style={{
              paddingTop: "16px",
              paddingBottom: "16px",
              paddingLeft: "18px",
            }}
          >
            <Grid spacing={3} item xs={12}>
              <UploadCard
                origin={modalConciliation!.conciliation}
                headingText={
                  "Ingrese el respaldo bancario de movimientos (opcional)"
                }
                fileUploader={fileUploader}
                isUploadingConciliation={isUploadingConciliation}
                uploadError={uploadError}
                isUploadedConciliation={isUploadedConciliation}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <img src={infoSVG}></img>
            <Typography className={classes.dialogContentCommentIcon}>
              {ConciliationTitleEnum.conciliationAction}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            id={"my_buttton"}
            onClick={() => {
              handleClose();
            }}
            className={classes.cancelAction}
          >
            {"Cancelar"}
          </Button>
          <ComponentSecurityWrapper componentId={CloudSecurityEnum.CONCILIATE}>
            <Button
              id={"button_aceptar"}
              onClick={submit}
              className={classes.buttonReconcile}
              autoFocus
              color="primary"
              variant="contained"
              disabled={!form.formState.isValid || useField.length > 100}
            >
              <Typography>{"Conciliar"}</Typography>
            </Button>
          </ComponentSecurityWrapper>
        </DialogActions>
      </Dialog>
      <ActionModal
        title={actionModalProps.title}
        message={ActionModalContentEnum.message}
        openDialog={openAlertModal}
        buttonLbl={actionModalProps.buttonLbl}
        onCancel={handleCloseAlertModal}
        submitAction={handleAlertModalSubmit}
      />
    </React.Fragment>
  );
};
