import React, { FC } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import { ITableProps } from "../../../shared/interfaces/Table.interfaces";
import BackofficeTableHead from "./TableHead/TableHead";
import { TableStyles } from "./Table.styles";
import TableSkeleton from "./TableSkeleton/TableSkeleton";
import BackofficeTableBody from "./TableBody/TableBody";

const BackofficeTable: FC<ITableProps> = (props: ITableProps) => {
  const { columns, data, loading, onCheckboxClick, onRowClick, rowId } = props;

  return (
    <TableContainer sx={TableStyles.container}>
      <Table>
        <BackofficeTableHead columns={columns} />
        <TableBody>
          {loading ? (
            <TableSkeleton columns={columns} times={7} />
          ) : (
            <BackofficeTableBody
              data={data}
              columns={columns}
              onCheckboxClick={onCheckboxClick}
              onRowClick={onRowClick}
              rowId={rowId}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BackofficeTable;
