import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { IconDownload } from "@kushki/connect-ui";
import { IExecutivesTemplateFile } from "@components/organism/ExecutivesTemplateFile/ExecutivesTemplateFile.interfaces";
import LoadingButton from "@mui/lab/LoadingButton";
import { executivesTemplateFileStyles as styles } from "@components/organism/ExecutivesTemplateFile/ExecutivesTemplateFile.styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { DownloadExecutiveTemplateLabels } from "@shared/constants/labels/DownloadExecutiveTemplateLabels";

const ExecutivesTemplateFile: FC<IExecutivesTemplateFile> = ({
  isLoadingDownloadExecutives,
  handleDownloadExecutives,
}: IExecutivesTemplateFile) => {
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Typography color={"text.primary"} variant="subtitle2">
          {DownloadExecutiveTemplateLabels.FIRST_TEXT}
        </Typography>
        <ComponentSecurityWrapper
          componentId={
            SecurityWrapperRoles.M_COMPLIANCE_EXECUTIVES_DOWNLOAD_FILE
          }
        >
          <LoadingButton
            data-testid={"btn-download-test"}
            startIcon={<IconDownload />}
            onClick={handleDownloadExecutives}
            loading={isLoadingDownloadExecutives}
            loadingPosition={"start"}
            variant={"text"}
            sx={styles.buttonContainer}
          >
            {DownloadExecutiveTemplateLabels.FILE_LABEL}
          </LoadingButton>
        </ComponentSecurityWrapper>
      </Box>
      <Typography color={"text.primary"} variant={"body2"}>
        {DownloadExecutiveTemplateLabels.INSTRUCTIONS}
      </Typography>
    </Box>
  );
};

export default ExecutivesTemplateFile;
