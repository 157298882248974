import { IHeaderTable } from "../headers/defaultHeaders";

export const _selectableColumnsConciliation: IHeaderTable[] = [
  {
    label: "status",
    id: "bankConciliationStatus",
    disabled: false,
    orderBy: "",
    isCustom: true,
  },
  {
    label: "Nombre del comercio",
    id: "merchantName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "ID del comercio",
    id: "merchantId",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Valor total de la transacción",
    id: "approvedTransactionAmount",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto a recibir",
    id: "netAmount",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Fecha de la transacción",
    id: "transactionCreated",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Tax ID",
    id: "tax_id",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Tarjeta Enmascarada",
    id: "masked_card_number",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Código de autorización",
    id: "approval_code",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Tasa del procesador",
    id: "kushki_comission",
    disabled: true,
    orderBy: "",
  },
  {
    label: "IVA tasa adquiriencia",
    id: "iva_kushki_comission",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Retenciones adquiriencia",
    id: "retention",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto Liquidado Adquiriencia",
    id: "net_liquidate_kushki",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Marca de tarjeta",
    id: "card_brand",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Tipo de tarjeta",
    id: "card_type",
    disabled: true,
    orderBy: "",
  },
  {
    label: "País",
    id: "country",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Método de pago",
    id: "payment_method",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Procesador",
    id: "processor_name",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Moneda",
    id: "currency_code",
    disabled: true,
    orderBy: "",
  },
];

export const _selectableColumnsConciliationHistoric: IHeaderTable[] = [
  ..._selectableColumnsConciliation,
  {
    label: "Usuario",
    id: "userName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Fecha de conciliación",
    id: "bankConciliationDate",
    disabled: true,
    orderBy: "",
  },
];

export const _selectableColumnsDeposit: IHeaderTable[] = [
  {
    label: "País",
    id: "country",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Método de pago",
    id: "paymentMethod",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Procesador",
    id: "processorName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Moneda",
    id: "currencyCode",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto a recibir",
    id: "netAmountTotal",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Valor liquidado",
    id: "liquidationAmount",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Usuario",
    id: "userName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Fecha y hora de conciliación",
    id: "conciliationCreated",
    disabled: true,
    orderBy: "",
  },
];
