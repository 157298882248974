export enum InputTypeEnum {
  TEXT = "text",
  PHONE = "phone",
  DATE = "date",
  COUNTRIES = "countries",
  RADIO = "radio",
  RADIO_PUBLICLY_EXPOSED = "radio_publicly",
  RADIO_SH = "radio_sh",
  SELECT = "select",
  SELECT_SH = "select_sh",
  SELECT_BM = "select_bm",
  EMAIL = "email",
  CHECKBOX = "checkbox",
  SELECT_COMBO_NATIONALITY = "test",
}
