export enum Sections {
  CREATE_NODE = "createNode",
  CREATE_BRANCH = "createBranch",
  EDIT_BRANCH = "editBranch",
  CREATE_BRANCHES = "createBranches",
  EDIT_BRANCHES = "editBranches",
  EDIT_CUSTOMER = "editCustomer",
  CREATE_OWNER = "createOwner",
  CREATE_CUSTOMER = "createCustomer",
  SELECT_EDITION_BRANCH = "selectionBranch",
  MASSIVE_EDITION = "massiveEditionBranch",
}

export enum OperationEnum {
  EDIT = "edit",
  EDITION = "edition",
  MASSIVE_EDITION = "massive-edition",
}

export enum pathValidateIsContainsUrl {
  batch = "/batch",
}
