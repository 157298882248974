import React from "react";
import {
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AuthenticationType,
  Suppliers,
  validateProviderOption,
} from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { Controller } from "react-hook-form";
import ConnectForm from "../../common/ConnectForm/ConnectForm";
import { IRuleAuthenticationForm } from "../../../shared/infrastructure/interfaces/IRuleAuthenticationForm";
import {
  ProviderEnum,
  ProviderOptionsEnum,
} from "../../../shared/infrastructure/constants/ProviderOptionsEnum";
import { AuthenticationRequestDynamo } from "../../../../types/remote/authentication_request_dynamo";
import { get } from "lodash";
import { kindEnum } from "../../../shared/infrastructure/enums/KindEnum";

const useStyles = makeStyles((theme: Theme) => ({
  labelText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  textField: {
    padding: "10px 20px",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    textAlign: "left",
  },
}));

export interface IGeneralDataStateProps {
  merchantData: AuthenticationRequestDynamo;
  setKind?: (newKind: string) => void;
}

export interface IGeneralDataFunctionsProps {}

export type TGeneralDataIndexProps = IGeneralDataStateProps &
  IGeneralDataFunctionsProps;

export const GeneralData: React.FC<Partial<TGeneralDataIndexProps>> = (
  _props: Partial<TGeneralDataIndexProps>
) => {
  const classes = useStyles();

  return (
    <ConnectForm<IRuleAuthenticationForm>>
      {({ errors, getErrorMessage, getValues, setValue, control }) => {
        return (
          <Container>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.labelText}
                  align="left"
                  color="primary"
                >
                  Datos del Comercio
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6} xl={6}>
                <Controller
                  control={control}
                  name={"merchantName"}
                  rules={{ required: true }}
                  defaultValue={getValues().merchantName}
                  render={(field) => (
                    <TextField
                      {...field}
                      label="Comercio"
                      variant="outlined"
                      disabled
                      fullWidth
                      margin="normal"
                      id="merchantName"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} lg={6} xl={6}>
                <Controller
                  control={control}
                  defaultValue={getValues().publicMerchantId}
                  name={"publicMerchantId"}
                  render={(field) => (
                    <TextField
                      {...field}
                      label="ID del Comercio"
                      variant="outlined"
                      id={"publicMerchantId"}
                      disabled
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="left">
                  Ingresa un alias que te permita identificar la regla.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} xl={12} className={classes.textField}>
                <Controller
                  control={control}
                  name={"alias"}
                  defaultValue={getValues().alias}
                  rules={{ required: true }}
                  render={(field) => (
                    <TextField
                      {...field}
                      label="Alias"
                      id={"alias"}
                      variant="outlined"
                      error={!!get(errors, "alias")}
                      required
                      fullWidth
                      margin="normal"
                      helperText={
                        !!get(errors, "alias") &&
                        getErrorMessage(get(errors, "alias")!)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: -8, paddingRight: -8 }}>
                <Divider variant={"fullWidth"} />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.labelText}
                  variant="h5"
                  align="left"
                  color="primary"
                >
                  Datos del Servicio
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6} xl={6} className={classes.textField}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="provider-label">
                    Nombre del proveedor
                  </InputLabel>
                  <Controller
                    defaultValue={getValues().provider}
                    control={control}
                    name={"provider"}
                    render={(field) => (
                      <Select
                        inputProps={{ "data-testid": "provider-wrapper" }}
                        id="provider"
                        {...field}
                        label="Nombre del Proveedor"
                        onChange={(event) => {
                          field.onChange(event);
                          if (event.target.value === ProviderEnum.KUSHKI) {
                            setValue("kind", ProviderOptionsEnum.OTP);
                            _props.setKind!(ProviderOptionsEnum.OTP);
                          } else {
                            setValue("kind", ProviderOptionsEnum.DS);
                            _props.setKind!(ProviderOptionsEnum.DS);
                          }
                        }}
                      >
                        {Suppliers.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={6} xl={6} className={classes.textField}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="kind-label">Tipo de autenticación</InputLabel>
                  <Controller
                    control={control}
                    name={"kind"}
                    defaultValue={getValues().kind}
                    render={(field) => (
                      <Select
                        {...field}
                        label="Tipo de autenticación"
                        onChange={(event) => {
                          field.onChange(event);
                          _props.setKind!(
                            get(event, "target.value", kindEnum["OTP"])
                          );
                        }}
                      >
                        {AuthenticationType.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            disabled={validateProviderOption(
                              getValues("provider"),
                              item.value
                            )}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        );
      }}
    </ConnectForm>
  );
};
