import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../../interfaces/app.interfaces";
import { getMerchantNodeInfo } from "../../thunks/app/app.thunks";
import { defaultMerchantNodeValues } from "../../../shared/constants/main_container_constants";

export const initialState: AppState = {};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantNodeInfo.fulfilled, (state, { payload }) => {
        state.merchantNodeInfo = payload;
      })
      .addCase(getMerchantNodeInfo.rejected, (state) => {
        state.merchantNodeInfo = defaultMerchantNodeValues;
      });
  },
  initialState,
  name: "app",
  reducers: {},
});

export default appSlice.reducer;
