import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { BreadcrumItem, BreadcrumProps } from "./Breadcrumb.interfaces";
import { breacrumbStyles } from "./Breadcrumb.styles";
import { ChevronRight } from "react-feather";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Breadcrumb = (props: BreadcrumProps) => {
  const classes = breacrumbStyles();

  return (
    <Breadcrumbs
      separator={<ChevronRight size={16} />}
      aria-label="breadcrumb"
      className={classes.breadcrumb}
    >
      {props.items.map((item: BreadcrumItem) => (
        <Link
          key={item.label}
          component={RouterLink}
          to={item.url}
          color="inherit"
        >
          {item.label}
        </Link>
      ))}
      <Typography color="primary">{props.lastItem}</Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
