import { format, parse, sub } from "date-fns";
import EsLocale from "date-fns/locale/es";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  SPANISH_MONTHS,
  SPANISH_MONTHS_SHORT,
} from "../../shared/catalogs/spanishMonths";
import { defaultTo } from "lodash";
import { DateFormatEnum } from "../enums/DateFormatEnum";

import("dayjs/locale/es");

dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.locale("es");
dayjs.updateLocale("es", {
  months: SPANISH_MONTHS,
  monthsShort: SPANISH_MONTHS_SHORT,
});

const MONTHS_ABREVIATIONS_REGEX =
  /ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic/;

export const transformUnixTimestampToFormatDate = (
  timestamp: number,
  formatDate: string
): string => {
  return dayjs(new Date(timestamp))
    .utc()
    .locale("es")
    .format(formatDate)
    .replace(
      MONTHS_ABREVIATIONS_REGEX,
      (value) => `${value[0].toUpperCase()}${value.substring(1)}`
    );
};

export const verifyDate = (date?: number): string => {
  return !isNaN(date!)
    ? transformUnixTimestampToFormatDate(
        defaultTo(date, new Date().getTime()),
        DateFormatEnum.DD_MMM_YYYY
      )
    : "";
};

export const transformDateToTimestamp = (
  date: string,
  formatDate: string
): number => {
  return dayjs(date, formatDate, "es").utc().valueOf();
};

export const getDate = (
  timeStamp: number,
  formatOutput: string = "yyyy-MM-dd"
): string => format(timeStamp, formatOutput);

export const getFormattedDate = (date: string): string => {
  return format(new Date(date), "dd-MM-yyyy", {
    locale: EsLocale,
  });
};

export const getSimplifiedFormatedDate = (date: string): string => {
  return format(new Date(date), "dd MMM. yyyy HH:mm", {
    locale: EsLocale,
  }).replace(
    MONTHS_ABREVIATIONS_REGEX,
    (value) => `${value[0].toUpperCase()}${value.substring(1)}`
  );
};

export const getFormattedDateTime = (date: Date): string =>
  format(date, "dd/MM/yyyy HH:mm", { locale: EsLocale });

export const parseDateTime = (dateTime: string): Date =>
  parse(dateTime, "dd/MM/yyyy HH:mm", new Date(), {
    locale: EsLocale,
  });

export const subOneDay = (date: Date): Date => sub(date, { days: 1 });
