import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  downloadTransactions,
  getFirebaseID,
  getMerchantInfo,
  getMerchantsRequest,
  getNodeInfo,
} from "../../thunks/app/app.thunks";
import { get, isEmpty } from "lodash";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { MerchantForm } from "../../../../types/merchant_form";

export const appInitialState: IAppState = {
  error: "",
  file: {
    totalProcessed: 0,
    totalRecords: 0,
    url: "",
  },
  firebaseId: "",
  isLoadingMassiveStatus: false,
  isLoadingMerchantInfo: false,
  isLoadingNodeInfo: false,
  isLoadingTransaction: false,
  isOpenMassiveStatusEmailsModal: false,
  isOpenMassiveStatusInstructionsModal: false,
  isOpenMassiveStatusModal: false,
  loadingDownload: false,
  loadingMerchants: false,
  merchantInfo: {
    accountNumber: "",
    accountType: "0",
    address: "",
    bankId: "",
    chargeFrequency: "none",
    chargeMin: "",
    chargeMinAmount: 0,
    city: "",
    contactPerson: "",
    country: "",
    documentType: "0",
    email: "",
    invoiceFrequency: "none",
    name: "",
    phoneNumber: "",
    province: "",
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
    zipCode: "",
  },
  merchants: {
    data: [],
    total: 0,
  },
  nodeInfo: {
    configCoreId: "",
  },
  transactionFatherData: {
    data: [],
  },
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingMerchantInfo = true;
      })
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        state.merchantInfo = action.payload;
        state.isLoadingMerchantInfo = false;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingMerchantInfo = false;
      });
    builder
      .addCase(getNodeInfo.pending, (state) => {
        state.isLoadingNodeInfo = true;
      })
      .addCase(getNodeInfo.fulfilled, (state, action) => {
        state.nodeInfo = action.payload;
        state.isLoadingNodeInfo = false;
      })
      .addCase(getNodeInfo.rejected, (state) => {
        state.isLoadingNodeInfo = false;
      });
    builder
      .addCase(getFirebaseID.pending, (state) => {
        state.loadingDownload = true;
      })
      .addCase(getFirebaseID.fulfilled, (state, action) => {
        state.firebaseId = action.payload.id;
      })
      .addCase(getFirebaseID.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
        state.error = "Se superó el número máximo de registros en el archivo";
        state.loadingDownload = false;
      });
    builder
      .addCase(downloadTransactions.pending, (state) => {
        state.loadingDownload = true;
      })
      .addCase(downloadTransactions.fulfilled, (state, action) => {
        state.file = action.payload;
        state.loadingDownload = false;
      })
      .addCase(downloadTransactions.rejected, (state) => {
        state.loadingDownload = false;
      });
    builder
      .addCase(getMerchantsRequest.pending, (state) => {
        state.loadingMerchants = true;
      })
      .addCase(getMerchantsRequest.fulfilled, (state, action) => {
        const merchantsWithName: MerchantForm[] = action.payload.data.filter(
          (res) => !isEmpty(res.name)
        );

        state.merchants.data = merchantsWithName;
        state.merchants.total = merchantsWithName.length;
        state.loadingMerchants = false;
      })
      .addCase(getMerchantsRequest.rejected, (state) => {
        state.loadingMerchants = false;
      });
  },
  initialState: appInitialState,
  name: "app",
  reducers: {
    resetFirebaseId: (state) => {
      state.firebaseId = appInitialState.firebaseId;
    },
    setIsLoadingMassiveStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingMassiveStatus = payload;
    },
    setIsOpenMassiveStatusEmailsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isOpenMassiveStatusEmailsModal = payload;
    },
    setIsOpenMassiveStatusInstructionsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isOpenMassiveStatusInstructionsModal = payload;
    },
    setIsOpenMassiveStatusModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isOpenMassiveStatusModal = payload;
    },
    setLoadingDownload: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingDownload = payload;
    },
  },
});

export default appSlice.reducer;
