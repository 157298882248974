import React from "react";
import { ModalSimple } from "@kushki/connect-ui";
import { useContinueModal } from "./state/useContinueModal";
import { defaultTo, isEqual } from "lodash";
import { ACTION_TYPE_MODAL } from "../../shared/constants/modal-info";

export const ContinueModal = () => {
  const {
    modalInfo,
    handleCloseModal,
    handlePrimaryButtonModal,
  } = useContinueModal();
  return (
    <ModalSimple
      buttonPrimary={modalInfo.buttonPrimaryText}
      buttonPrimaryDisabled={false}
      isOpen={modalInfo.isOpen}
      buttonSecondary={modalInfo.buttonSecondaryText}
      buttonSecondaryDisabled={isEqual(
        defaultTo(modalInfo.actionType, ""),
        ACTION_TYPE_MODAL.BATCH
      )}
      descriptionText={modalInfo.descriptionText}
      onClickSecondary={handleCloseModal}
      onClose={handleCloseModal}
      titleText={modalInfo.titleText}
      typeModal={1}
      onClickPrimary={handlePrimaryButtonModal}
    />
  );
};
