import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { CheckboxList } from "../CheckboxList/CheckboxList";
import { usePaymentMethodCheckList } from "./usePaymentMethodCheckList/usePaymentMethodCheckList";
import { PAYMENT_METHOD_LABELS } from "../../shared/infrastructure/constants/OptionsEnum";

const PaymentMethodCheckList: React.FC = () => {
  const {
    payinOptions,
    payoutOptions,
    productOptions,
    otherOptions,
    areAllOptionsSelected,
    selectedOptions,
    handler: { handleChange, handleSelectAll },
  } = usePaymentMethodCheckList();
  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={handleSelectAll}
              checked={areAllOptionsSelected}
            />
          }
          label={"Seleccionar todo"}
        />
      </Grid>
      {payinOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckboxList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.PAYIN}
            onChange={handleChange}
            items={payinOptions}
          />
        </Grid>
      )}
      {payoutOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckboxList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.PAYOUTS}
            onChange={handleChange}
            items={payoutOptions}
          />
        </Grid>
      )}
      {productOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckboxList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.PRODUCT}
            onChange={handleChange}
            items={productOptions}
          />
        </Grid>
      )}
      {otherOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckboxList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.OTHER}
            onChange={handleChange}
            items={otherOptions}
          />
        </Grid>
      )}
    </>
  );
};

export { PaymentMethodCheckList };
