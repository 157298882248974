import { createSlice } from "@reduxjs/toolkit";
import { ITransactionsState } from "../../interfaces/TransactionsState.interfaces";

export const initialState: ITransactionsState = {
  areTransactionsLoading: false,
  selectedTransactions: [],
  transactions: [],
};

export const transactionsSlice = createSlice({
  initialState,
  name: "transactions",
  reducers: {},
});

export default transactionsSlice.reducer;
