import React, { PropsWithChildren } from "react";
import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { get } from "lodash";
import { FormHelperTextComponent } from "../FormHelperTextComponent/FormHelperTextComponent";
import { IGenericSelectComponentProps } from "../../../../shared/infrastructure/interfaces/IGenericSelectComponentProps";
import { BasicSelect } from "./components/BasicSelect/BasicSelect";

export const GenericSelectComponent: React.FC<IGenericSelectComponentProps> = (
  props: PropsWithChildren<IGenericSelectComponentProps>
) => {
  const name = `condition.${props.mainProps.index}.${props.name}`;
  const defaultValue = get(props, `mainProps.item.${props.name}`, "");
  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        name={name}
        control={props.control}
        defaultValue={defaultValue}
        rules={props.isRuleRequired}
        render={({ ...selectProps }) => (
          <BasicSelect selectProps={selectProps} mainProps={props} />
        )}
      />
      <FormHelperTextComponent
        className={props.className}
        errors={props.errors}
        path={`condition.${props.mainProps.index}.${props.name}`}
      />
    </FormControl>
  );
};
