import React, { FC } from "react";
import { Box, Chip, Tooltip } from "@mui/material";
import { IChipStatusProps } from "./ChipStatus.interfaces";
import { chipStatusStyles } from "./ChipStatus.styles";

export const ChipStatus: FC<IChipStatusProps> = (props: IChipStatusProps) => {
  const classes = chipStatusStyles(props);

  return (
    <Box>
      <Tooltip title={`${props.text}`} placement="top">
        <Chip
          className={classes.chip}
          data-testid={"chip-status"}
          label={props.text}
          size="small"
        />
      </Tooltip>
    </Box>
  );
};
