/* eslint sort-keys: 0 */
export const Palette = {
  alternate: {
    dark: "rgba(189, 98, 180, 1)",
    dark2: "rgba(143, 79, 139, 1)",
    light: "rgba(230, 166, 211, 1)",
    light2: "rgba(253, 217, 242, 1)",
    light3: "rgba(254, 236, 248, 1)",
    main: "rgba(221, 133, 195, 1)",
  },
  background: {
    default: "rgba(240, 244, 251, 1)",
    paper: "rgba(255,255,255,1)",
  },
  common: {
    disabled: "rgba(238,238,238,1)",
    grey: "rgba(109,119,129,1)",
    headTable: "rgba(103,119,132,1)",
    white: "rgba(255,255,255,1)",
  },
  error: {
    dark: "rgba(214, 44, 75, 1)",
    dark2: "rgba(173, 12, 42, 1)",
    light: "rgba(226, 108, 129, 1)",
    light2: "rgba(246, 174, 187, 1)",
    light3: "rgba(255, 234, 238, 1)",
    main: "rgba(226, 71, 99, 1)",
  },
  gradients: {
    gradientA: "linear-gradient(180deg, #00E6B2 0%, #023365 100%)",
    gradientB: "linear-gradient(180deg, #0093A2 0%, #00E6B2 100%)",
    gradientC: "linear-gradient(135deg, #023365 0%, #1E65AE 100%)",
    gradientD: "linear-gradient(180deg, #023365 100% , #00E6B2 0%)",
    gradientE: "linear-gradient(180deg, #FFFFFF 0%, #F7FAFC 100%)",
  },
  info: {
    dark: "rgba(78, 167, 85, 1)",
    dark2: "rgba(53, 142, 60, 1)",
    light: "rgba(118, 207, 125, 1)",
    light2: "rgba(155, 244, 162, 1)",
    light3: "rgba(230, 252, 234, 1)",
    main: "rgba(102, 191, 109, 1)",
  },
  neutral: {
    50: "rgba(255, 255, 255, 1)",
    // eslint-disable-next-line sort-keys
    100: "rgba(251, 252, 254, 1)",
    200: "rgba(247, 250, 252, 1)",
    300: "rgba(240, 244, 249, 1)",
    400: "rgba(226, 232, 240, 1)",
    500: "rgba(203, 213, 224, 1)",
    600: "rgba(103, 118, 132, 1)",
    700: "rgba(70, 82, 92, 1)",
    800: "rgba(41, 48, 54, 1)",
  },
  primary: {
    dark: "rgba(17, 43, 69, 1)",
    dark2: "rgba(14, 30, 46, 1)",
    light: "rgba(30, 101, 174, 1)",
    light2: "rgba(68, 152, 238, 1)",
    light3: "rgba(238, 246, 255, 1)",
    main: "rgba(2, 51, 101, 1)",
    white: "rgba(255,255,255,1)",
  },
  secondary: {
    dark: "rgba(13, 194, 152, 1)",
    dark2: "rgba(59, 157, 134, 1)",
    light: "rgba(0, 252, 193, 1)",
    light2: "rgba(183, 255, 238, 1)",
    light3: "rgba(224, 255, 248, 1)",
    main: "rgba(0, 230, 178, 1)",
  },
  success: {
    dark: "rgba(13, 194, 152, 1)",
    light: "rgba(0, 252, 193, 1)",
    main: "rgba(0, 230, 178, 1)",
  },
  text: {
    dark: "rgba(41, 48, 54, 1)",
    disabled: "rgba(180, 185, 198, 1)",
    grey: "rgba(109, 119, 129, 1)",
    lightGrey: "rgba(180, 185, 190, 1)",
    primary: "rgba(2, 51, 101, 1)",
    red: "rgba(236, 78, 61, 1)",
    secondary: "rgba(109, 119, 129, 1)",
    white: "rgba(255, 255, 255, 1)",
  },
  warning: {
    dark: "rgba(223, 167, 32, 1)",
    dark2: "rgba(207, 113, 26, 1)",
    light: "rgba(255, 216, 121, 1)",
    light2: "rgba(254, 225, 155, 1)",
    light3: "rgba(255, 241, 208, 1)",
    main: "rgba(251, 199, 72, 1)",
  },
};

const ThemeFonts = {
  web: {
    avatarLetter: 20,
    body1: 18,
    body2: 16,
    caption: 15,
    button: 16,
    buttonsm: 14,
    buttonlg: 20,
    h1: 40,
    h2: 32,
    h3: 28,
    h4: 24,
    h5: 20,
    h6: 16,
    helperText: 12,
    inputLabel: 12,
    inputText: 16,
    subtitle1: 20,
    subtitle2: 16,
    overline: 13,
    numericText: 16,
    tooltip: 14,
  },
  webxs: {
    h1: 38,
    h2: 30,
    h3: 26,
    h4: 22,
    h5: 18,
    h6: 16,
    subtitle1: 20,
    subtitle2: 16,
    body1: 18,
    body2: 16,
    caption: 14,
    overline: 13,
    numericText: 16,
    button: 16,
    buttonsm: 14,
    buttonlg: 20,
    inputLabel: 12,
    inputText: 16,
    helperText: 12,
    tooltip: 13,
    avatarLetter: 18,
  },
  mobile: {
    h1: 28,
    h2: 24,
    h3: 22,
    h4: 20,
    h5: 18,
    h6: 16,
    subtitle1: 18,
    subtitle2: 15,
    body1: 17,
    body2: 16,
    caption: 14,
    overline: 13,
    numericText: 15,
    button: 15,
    buttonsm: 13,
    buttonlg: 18,
    inputLabel: 11,
    inputText: 14,
    helperText: 11,
    tooltip: 12,
    avatarLetter: 16,
  },
  mobilexs: {
    h1: 23,
    h2: 21,
    h3: 20,
    h4: 19,
    h5: 18,
    h6: 16,
    subtitle1: 17,
    subtitle2: 14,
    body1: 16,
    body2: 14,
    caption: 12,
    overline: 12,
    numericText: 14,
    button: 14,
    buttonsm: 13,
    buttonlg: 16,
    inputLabel: 12,
    inputText: 14,
    helperText: 11,
    tooltip: 12,
    avatarLetter: 16,
  },
};

export const themeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  spacing: 8,
  palette: {
    common: {
      white: Palette.common.white,
      headTable: Palette.common.headTable,
      disabled: Palette.common.disabled,
      grey: Palette.common.grey,
    },
    gradients: {
      gradientA: Palette.gradients.gradientA,
      gradientB: Palette.gradients.gradientB,
      gradientC: Palette.gradients.gradientC,
      gradientD: Palette.gradients.gradientD,
      gradientE: Palette.gradients.gradientE,
    },
    primary: {
      dark2: Palette.primary.dark2,
      dark: Palette.primary.dark,
      main: Palette.primary.main,
      light: Palette.primary.light,
      light2: Palette.primary.light2,
      light3: Palette.primary.light3,
    },
    secondary: {
      dark2: Palette.secondary.dark2,
      dark: Palette.secondary.dark,
      main: Palette.secondary.main,
      light: Palette.secondary.light,
      light2: Palette.secondary.light2,
      light3: Palette.secondary.light3,
    },
    info: {
      dark2: Palette.info.dark2,
      dark: Palette.info.dark,
      main: Palette.info.main,
      light: Palette.info.light,
      light2: Palette.info.light2,
      light3: Palette.info.light3,
    },
    error: {
      dark2: Palette.error.dark2,
      dark: Palette.error.dark,
      main: Palette.error.main,
      light: Palette.error.light,
      light2: Palette.error.light2,
      light3: Palette.error.light3,
    },
    warning: {
      dark2: Palette.warning.dark2,
      main: Palette.warning.main,
      dark: Palette.warning.dark,
      light: Palette.warning.light,
      light2: Palette.warning.light2,
      light3: Palette.warning.light3,
    },
    alternate: {
      dark2: Palette.alternate.dark2,
      dark: Palette.alternate.dark,
      main: Palette.alternate.main,
      light: Palette.alternate.light,
      light2: Palette.alternate.light2,
      light3: Palette.alternate.light3,
    },
    success: {
      main: Palette.success.main,
      dark: Palette.success.dark,
      light: Palette.success.light,
    },
    background: {
      paper: Palette.background.paper,
      default: Palette.background.default,
    },
    text: {
      primary: Palette.text.primary,
      secondary: Palette.text.secondary,
      disabled: Palette.text.disabled,
      red: Palette.text.red,
      dark: Palette.text.dark,
      grey: Palette.text.grey,
      lightGrey: Palette.text.lightGrey,
    },
    neutral: {
      white: Palette.neutral[50],
      grey1: Palette.neutral[100],
      grey2: Palette.neutral[200],
      grey3: Palette.neutral[300],
      grey4: Palette.neutral[400],
      grey5: Palette.neutral[500],
      grey6: Palette.neutral[600],
      grey7: Palette.neutral[700],
      grey8: Palette.neutral[800],
    },
  },
  typography: {
    fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
    h1: {
      fontSize: ThemeFonts.web.h1 + "px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "140%",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.h1 + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.h1 + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.h1 + "px",
      },
    },
    h2: {
      fontSize: ThemeFonts.web.h2 + "px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.h2 + "px",
        fontWeight: "500",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.h2 + "px",
        fontWeight: "500",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.h2 + "px",
        fontWeight: "500",
      },
    },
    h3: {
      fontSize: ThemeFonts.web.h3 + "px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.h3 + "px",
        fontWeight: "bold",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.h3 + "px",
        fontWeight: "bold",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.h3 + "px",
        fontWeight: "bold",
      },
    },
    h4: {
      fontSize: ThemeFonts.web.h4 + "px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.h4 + "px",
        fontWeight: "bold",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.h4 + "px",
        fontWeight: "bold",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.h4 + "px",
        fontWeight: "bold",
      },
    },
    h5: {
      fontSize: ThemeFonts.web.h5 + "px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.h5 + "px",
        fontWeight: "bold",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.h5 + "px",
        fontWeight: "bold",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.h5 + "px",
        fontWeight: "bold",
      },
    },
    h6: {
      fontSize: ThemeFonts.web.h6 + "px",
      fontWeight: 400,
      lineHeight: "140%",
      fontStyle: "normal",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.h6 + "px",
        fontWeight: "bold",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.h6 + "px",
        fontWeight: "bold",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.h6 + "px",
        fontWeight: "bold",
      },
    },
    subtitle1: {
      fontFamily: "IBM Plex Sans",
      fontSize: ThemeFonts.web.subtitle1 + "px",
      fontWeight: 500,
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.subtitle1 + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.subtitle1 + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.subtitle1 + "px",
      },
    },
    subtitle2: {
      fontSize: ThemeFonts.web.subtitle2 + "px",
      fontWeight: 500,
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.subtitle2 + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.subtitle2 + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.subtitle2 + "px",
      },
    },
    body1: {
      fontSize: ThemeFonts.web.body1 + "px",
      fontWeight: "regular",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.body1 + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.body1 + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.body1 + "px",
      },
    },
    body2: {
      fontSize: ThemeFonts.web.body2 + "px",
      fontWeight: "regular",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.body2 + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.body2 + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.body2 + "px",
      },
    },
    caption: {
      fontSize: ThemeFonts.mobile.caption + "px",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.caption + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.caption + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.caption + "px",
      },
    },
    overline: {
      fontWeight: "regular",
      textTransform: "inherit",
      lineHeight: "140%",
      fontSize: ThemeFonts.web.overline + "px",
      "@media (min-width:961px) and (max-width:1280px)": {
        fontSize: ThemeFonts.webxs.overline + "px",
      },
      "@media (min-width:361px) and (max-width:960px)": {
        fontSize: ThemeFonts.mobile.overline + "px",
      },
      "@media (max-width:360px)": {
        fontSize: ThemeFonts.mobilexs.overline + "px",
      },
    },
  },
  props: {
    MuiButtonGroup: {
      disableElevation: true,
    },
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
    },
    MuiIconButton: {
      disableElevation: true,
      disableRipple: true,
    },
  },
  overrides: {
    MuiTypography: {
      colorInherit: {
        color: Palette.text.dark + "!important",
      },
      colorPrimary: {
        color: Palette.text.primary,
      },
      colorSecondary: {
        color: Palette.text.secondary,
      },
      colorTextPrimary: {
        color: Palette.text.lightGrey,
      },
      colorTextSecondary: {
        color: Palette.text.white,
      },
    },
    MuiCssBaseline: {
      "@global": {
        ".sb-main-centered": {
          "@font-face": ["IBM Plex Sans"],
        },
        ".custom-drawer .MuiPaper-root": {
          overflowX: "hidden",
        },
        "*::-webkit-scrollbar": {
          width: "10px",
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.2)",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: Palette.neutral[500],
          outline: "1px solid" + Palette.neutral[500],
        },
      },
    },
    MuiPaper: {
      root: {
        color: Palette.text.dark,
      },
      elevation1: {
        //Shadow xs
        boxShadow:
          "0px 1px 8px rgba(2, 51, 101, 0.08), 0px 0px 0px rgba(14, 30, 46, 0.1), 0px 1px 3px rgba(14, 30, 46, 0.2);", //noPalletcolor
      },
      elevation2: {
        //Shadow sm
        boxShadow:
          "0px 4px 6px rgba(50, 50, 93, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08)", //noPalletcolor
      },
      elevation3: {
        //Shadow md
        boxShadow:
          "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)", //noPalletcolor
      },
      elevation4: {
        //Shadow lg
        boxShadow:
          "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)", //noPalletcolor
      },
      elevation5: {
        //Shadow xl
        boxShadow:
          "0px 15px 35px rgba(60, 66, 87, 0.12), 0px 50px 100px rgba(60, 66, 87, 0.12), 0px 5px 15px rgba(0, 0, 0, 0.12)", //noPalletcolor
      },
    },
    MuiTableContainer: {
      root: {
        backgroundColor: Palette.neutral[100],
      },
    },
    MuiTableRow: {
      hover: {},
    },
    MuiMenuItem: {
      root: {
        fontSize: "16px",
        padding: "0px 0px 0px 0px",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "16px",
      },
      outlined: {
        borderRadius: "4px",
        top: "-5px",
      },
      shrink: {
        top: "0px!important",
      },
      marginDense: {
        top: "-3px!important",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "4px",
        position: "relative",
        "& $notchedOutline": {
          borderWidth: "1px",
          borderColor: Palette.neutral[500],
          color: Palette.text.dark,
          "&:hover": {
            borderWidth: "1.5px",
            borderColor: Palette.primary.light + "!important",
          },
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderWidth: "1.5px",
          borderColor: Palette.primary.light + "!important",
          "@media (hover: none)": {
            borderWidth: "1.5px",
            borderColor: Palette.primary.light + "!important",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: Palette.primary.light,
          borderWidth: "1.5px",
        },
        "&$disabled $notchedOutline": {
          color: Palette.text.lightGrey,
          borderColor: Palette.text.disabled + "!important",
        },
      },
    },

    MuiFormLabel: {
      root: {
        "&$focused": {
          color: Palette.primary.light,
        },
      },
    },
    MuiAlert: {
      root: {
        borderRadius: "4px",
        fontSize: ThemeFonts.web.tooltip + "px",
        backgroundColor: Palette.neutral[800],
        color: Palette.text.secondary,
      },
      filledInfo: {
        backgroundColor: Palette.neutral[800],
      },
      filledWarning: {
        backgrondColor: Palette.warning.dark2 + "!important",
      },
      filledError: {
        backgroundColor: Palette.error.dark2,
      },
      filledSuccess: {
        backgroundColor: Palette.secondary.dark,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: "4px",
        fontSize: ThemeFonts.web.tooltip + "px",
      },
    },
    MuiTableCell: {
      head: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "140%",
        color: Palette.common.headTable,
      },
    },
    MuiButtonGroup: {
      groupedContainedPrimary: {
        padding: "8px 12px",
        backgroundColor: Palette.primary.light,
        border: "1px solid " + Palette.primary.main + "!important",
        "&:hover": {
          backgroundColor: Palette.primary.dark,
          border: "1px solid " + Palette.primary.dark,
        },
        "&:focus": {
          border: "1px solid " + Palette.primary.dark,
        },
        "&:active": {
          color: Palette.common.white,
          border: "1px solid " + Palette.primary.dark,
        },
        "&$disabled": {
          backgroundColor: Palette.primary.main,
          color: Palette.common.white,
        },
        "&.splitBtn": {
          backgroundColor: Palette.primary.main + "!important",
          "&:hover": {
            backgroundColor: Palette.primary.dark + "!important",
          },
          "&:focus": {
            border: "1px solid " + Palette.primary.light + "!important",
            borderLeft: "1px solid " + Palette.common.white + "!important",
            backgroundColor: Palette.primary.main + "!important",
          },
          "&:active": {
            backgroundColor: Palette.primary.main + "!important",
          },
          "&$disabled": {
            opacity: "30%",
          },
          "&.raisedButton": {
            boxShadow:
              "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)!important",
          },
        },
        "&.splitArrow": {
          paddingRight: "2px!important",
          paddingLeft: "2px!important",
          borderLeft: "1px solid " + Palette.common.white + "!important",
          minWidth: "auto",
        },
      },
      groupedContainedSecondary: {
        padding: "8px 14px",
        backgroundColor: Palette.primary.light,
        border: "1px solid " + Palette.neutral[500] + "!important",
        color: Palette.primary.main,
        fontWeight: 500,
        "&:hover": {
          border: "1px solid " + Palette.primary.dark,
        },
        "&:focus": {
          border: "1px solid " + Palette.primary.dark,
        },
        "&:active": {
          border: "1px solid " + Palette.primary.dark,
        },
        "&$disabled": {
          backgroundColor: Palette.primary.main,
        },
        "&.splitBtn": {
          color: Palette.text.dark,
          "&:focus": {
            border: "1px solid " + Palette.primary.light + "!important",
          },
          "&:active": {
            border: "1px solid " + Palette.primary.light + "!important",
          },
          "&$disabled": {
            opacity: "30%",
          },
          "&.raisedButton": {
            boxShadow:
              "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)!important",
          },
        },
        "&.splitArrow": {
          paddingRight: "2px!important",
          paddingLeft: "2px!important",
          minWidth: "auto",
        },
      },
      groupedText: {
        padding: "8px 14px",
        backgroundColor: "transparent",
        border: "1px solid " + Palette.neutral[100],
        color: Palette.primary.main,
        fontWeight: 600,
        "&:hover": {
          backgroundColor: Palette.neutral[200],
        },
        "&:focus": {
          backgroundColor: Palette.neutral[300],
        },
        "&:not(:last-child)": {
          borderTop: "1px solid " + Palette.neutral[100] + "!important",
          borderRight: "1px solid " + Palette.neutral[500] + "!important",
          borderLeft: "none!important",
          borderBottom: "1px solid " + Palette.neutral[100] + "!important",
        },
      },
      groupedOutlinedPrimary: {
        padding: "8px 20px",
      },
      groupedTextPrimary: {
        padding: "8px 20px",
      },
    },
    MuiIconButton: {
      sizeSmall: {
        "&.squareButton": {
          height: "36px",
          width: "36px",
          padding: "0px",

          "&:hover": {
            padding: "0px!important",
            backgroundColor: Palette.neutral[200],
          },
          "&:focus": {
            backgroundColor: Palette.neutral[300],
            border: "none",
          },
          "&:active": {
            animation: "fadeIn 5s",
          },
        },
      },
      colorPrimary: {
        "&.squareButton": {
          borderRadius: "4px",
          backgroundColor: Palette.neutral[50],
          padding: "10px",
          "&:hover": {
            backgroundColor: Palette.neutral[100],
          },
          "&:active": {
            backgroundColor: Palette.neutral[300],
          },
          "&:focus": {
            backgroundColor: Palette.neutral[300],
          },
          "&$disabled": {
            backgroundColor: Palette.neutral[200],
            opacity: "0.5",
          },
          "&.large": {
            padding: "0px!important",
            height: "52px",
            width: "52px",
          },
          "&.small": {
            border: "1px solid " + Palette.neutral[100],
            height: "36px",
            width: "36px",
            padding: "0px",
          },
        },
      },
      colorSecondary: {
        "&.squareButton": {
          borderRadius: "4px",
          backgroundColor: Palette.neutral[50],
          padding: "10px",
          border: "1px solid " + Palette.neutral[500],
          "&:hover": {
            backgroundColor: Palette.neutral[300],
            border: "1px solid " + Palette.neutral[300],
          },
          "&:active": {
            border: "1px solid " + Palette.primary.light,
            backgroundColor: Palette.neutral[50],
          },
          "&:focus": {
            border: "1px solid " + Palette.primary.light,
            backgroundColor: Palette.neutral[50],
          },
          "&$disabled": {
            opacity: "0.5",
          },
          "&.large": {
            padding: "0px!important",
            height: "52px",
            width: "52px",
          },
          "&.small": {
            border: "1px solid " + Palette.neutral[300],
            height: "36px",
            width: "36px",
            padding: "0px",
          },
        },
      },
    },
    MuiButton: {
      root: {
        padding: "12px 30px" + "!important",
        textTransform: "capitalize",
        height: "44px",
        borderRadius: "4px",
        fontWeight: "500",
        letterSpacing: "0.27px",
        fontSize: ThemeFonts.web.button + "px",
        "@media (min-width:961px) and (max-width:1280px)": {
          fontSize: ThemeFonts.webxs.button + "px",
        },
        "@media (min-width:361px) and (max-width:960px)": {
          fontSize: ThemeFonts.mobile.button + "px",
        },
        "@media (max-width:360px)": {
          fontSize: ThemeFonts.mobilexs.button + "px",
        },
        "&.dangerButton": {
          backgroundColor: "none !important",
          padding: "12px 30px",
          textTransform: "none",
          border: "none" + "!important",
          color: Palette.error.dark,
          fontWeight: "400",
          "&:active": {
            color: Palette.error.light + "!important",
            border: "none!important",
            backgroundColor: "none" + "!important",
          },
          "&:hover": {
            border: "none" + "!important",
            backgroundColor: Palette.error.light3,
          },
          "&:focus": {
            color: Palette.error.dark + "!important",
          },
          "&$disabled": {
            opacity: "0.3",
          },
          "&.raisedButton": {
            color: Palette.error.dark2 + "!important",
          },
        },
      },
      sizeLarge: {
        height: "52px",
        padding: "12px 30px",
        fontSize: ThemeFonts.web.buttonlg + "px",
        "@media (min-width:961px) and (max-width:1280px)": {
          fontSize: ThemeFonts.webxs.buttonlg + "px",
        },
        "@media (min-width:361px) and (max-width:960px)": {
          fontSize: ThemeFonts.mobile.buttonlg + "px",
        },
        "@media (max-width:360px)": {
          fontSize: ThemeFonts.mobilexs.buttonlg + "px",
        },
      },
      sizeSmall: {
        height: "36px",

        fontSize: ThemeFonts.web.buttonsm + "px",
        "@media (min-width:961px) and (max-width:1280px)": {
          fontSize: ThemeFonts.webxs.buttonsm + "px",
        },
        "@media (min-width:361px) and (max-width:960px)": {
          fontSize: ThemeFonts.mobile.buttonsm + "px",
        },
        "@media (max-width:360px)": {
          fontSize: ThemeFonts.mobilexs.buttonsm + "px",
        },
      },
      containedPrimary: {
        textTransform: "none",
        border: "1px solid " + Palette.primary.main,
        fontWeight: "400",
        backgroundColor: Palette.primary.main,
        "&:hover": {
          color: Palette.common.white,
          backgroundColor: Palette.primary.dark,
          border: "1px solid " + Palette.primary.dark,
        },
        "&:focus": {
          backgroundColor: Palette.primary.main,
          border: "1px solid " + Palette.primary.main,
          color: Palette.common.white,
        },
        "&:active": {
          backgroundColor: Palette.secondary.dark2,
          border: "1px solid " + Palette.secondary.dark2,
          color: Palette.common.white,
        },
        "&$disabled": {
          backgroundColor: Palette.primary.main,
          border: "1px solid " + Palette.primary.main,
          color: Palette.common.white,
          opacity: "30%",
        },
        "&.raisedButton": {
          boxShadow:
            "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)",
        },
      },
      containedSecondary: {
        textTransform: "none",
        backgroundColor: "transparent!important",
        border: "1px solid " + Palette.neutral[500],
        color: Palette.text.dark,
        fontWeight: "300",
        "&:hover": {
          backgroundColor: Palette.neutral[200] + "!important",
          color: Palette.text.dark,
        },
        "&:focus": {
          border: "1px solid " + Palette.primary.light,
        },
        "&:active": {
          border: "1px solid " + Palette.primary.light,
        },
        "&$disabled": {
          backgroundColor: Palette.primary.white + "!important",
          border: "1px solid " + Palette.neutral[500],
          color: Palette.text.dark,
          opacity: "30%",
        },
        "&.raisedButton": {
          boxShadow:
            "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)",
        },
      },
      textPrimary: {
        padding: "8px 43px",
        color: Palette.text.dark,
        border: "none!important;",
        fontWeight: "400",
        "&:hover": {
          border: "none!important;",
          backgroundColor: Palette.neutral[100] + "!important",
          color: Palette.text.primary,
        },
        "&:focus": {
          border: "none!important;",
          backgroundColor: Palette.neutral[200] + "!important",
        },
        "&:active": {
          color: Palette.primary.main + "!important",
        },
        "&$disabled": {
          backgroundColor: Palette.neutral[100] + "!important",
          border: "none!important;",
          color: Palette.text.dark,
          opacity: "30%",
        },
        "&.raisedButton": {
          backgroundColor: Palette.neutral[300],
          color: Palette.primary.main,
        },
      },
      outlinedPrimary: {
        border: "unset",
        borderRadius: "4px",
        backgroundColor: Palette.neutral[200],
        textTransform: "none",
        color: Palette.primary.main,
        "&:hover": {
          border: "unset",
          backgroundColor: Palette.primary.light3,
        },
      },
      outlined: {
        textTransform: "none",
      },
    },
    MuiFormHelperText: {
      root: {
        color: Palette.text.grey,
      },
    },
    MuiInput: {
      root: {
        fontSize: "16px",
        fontFamily: "IBM Plex Sans",
        "&$disabled": {
          borderBottom: "none",
          color: Palette.neutral[500],
        },
      },
      underline: {
        "&:before": {
          borderBottom: "1px solid " + Palette.neutral[500] + "!important",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "1px solid " + Palette.primary.light,
        },
        "&:after": {
          borderBottom: "1px solid " + Palette.primary.light,
        },
      },
    },
    MuiInputBase: {
      root: {
        height: "44px",
      },
      marginDense: {
        height: "36px",
      },
      multiline: {
        height: "auto",
        minHeight: "212px",
        alignItems: "start",
      },
    },
    MuiSwitch: {
      colorPrimary: {
        color: Palette.neutral[50],
        "&$disabled": {
          color: Palette.neutral[500] + "!important",
        },
        "&$track": {
          opacity: 1,
          backgroundColor: Palette.neutral[300],
          boxShadow:
            "0px 4px 6px rgba(50, 50, 93, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08)",
        },
        "&$checked": {
          color: Palette.primary.main,
        },
        "&$checked + $track": {
          backgroundColor: Palette.primary.light3,
          color: Palette.neutral[500],
          opacity: "1",
        },
        "&$disabled + $track": {
          opacity: "1",
          backgroundColor: Palette.neutral[300] + "!important",
        },
      },
    },
    MuiCheckbox: {
      root: {
        border: "none",
        "&label": {
          fontSize: ThemeFonts.web.inputLabel + "!important",
        },
      },
      colorPrimary: {
        color: Palette.text.grey,
        "&$checked": {
          color: Palette.primary.main,
        },
        "&$disabled": {
          color: Palette.text.disabled + "!important",
        },
      },
    },
    MuiRadio: {
      root: {
        border: "none",
        backgroundColor: "none",
      },
      colorPrimary: {
        color: Palette.primary.main,
        "&label": {
          fontSize: ThemeFonts.web.inputLabel,
        },
        "&$checked": {
          color: Palette.primary.main,
        },
        "&$disabled": {
          color: Palette.text.disabled + "!important",
        },
      },
    },
    MuiSelect: {
      root: {
        borderRadius: "16px!important",
        color: Palette.text.dark,
      },
    },
    transitions: {
      create: () => "none",
    },
  },
};
