/* istanbul ignore file */
import {
  IOptionSelection,
  MessageDisabledIconEdit,
  RuleState,
  RuleType,
} from "./infrastructure/constants/RulesConstants";
import { find, get } from "lodash";
import * as moment from "moment";
import { RuleItemsObject } from "../../types/rule_items_object";

moment.locale("es-es");

export const translateRuleKeys = (
  text: string,
  targets: IOptionSelection[]
): string => {
  if (text === undefined) return "";
  const index: number = targets.findIndex(
    (detail: IOptionSelection) => detail.value === text
  );

  if (index >= 0) {
    const translation: string | undefined = get(targets, `[${index}].name`);

    return translation !== undefined ? translation : text;
  }

  return text;
};

export const splitString = (
  separator: string,
  value: string,
  position: number
): string => {
  const splitResult: string[] = value.split(separator);

  return splitResult[position];
};

export const ampm = (hour: number): string => (hour >= 12 ? "p.m." : "a.m.");

export const formatDate = (date: number): string => {
  const month: string = moment.unix(date).format("MMM");
  const day: string = moment.unix(date).format("DD");
  const year: string = moment.unix(date).format("yyyy");
  const time: string = moment.unix(date).format("HH:mm:ss");
  return `${day} ${month[0].toUpperCase() + month.slice(1)} ${year} ${time}`;
};

export const formatDateAndHour = (date: number): string => {
  const month: string = moment.unix(date).format("MMM");
  const day: string = moment.unix(date).format("DD");
  const year: string = moment.unix(date).format("yyyy");
  const time: string = moment
    .unix(date)
    .format("h:mm:ss a")
    .replace(/(a|p)(m)/, "$1.$2.");
  return `${day} ${month[0].toUpperCase() + month.slice(1)} ${year} ${time}`;
};

export const getValues = (list: IOptionSelection[]): string[] => {
  let values: string[] = [];

  list.forEach((value) => values.push(value.value));

  return values;
};

export const findName = (
  list: IOptionSelection[],
  value: string,
  compare: "name" | "value",
  path: "name" | "value"
): string => {
  return get(
    find(list, (item) => {
      return value === get(item, compare);
    }),
    path,
    ""
  );
};

export const isDisabledOrPending = (rule: RuleItemsObject) => {
  const status = get(rule, "status", "");

  return status === RuleState.DISABLED || status === RuleState.PENDING;
};

export const getTooltipEdit = (data: RuleItemsObject) => {
  const status = get(data, "status", "");
  const action = get(data, "rule.action", "");
  const is_3ds_or_otp: boolean =
    action === RuleType.THREEDS || action === RuleType.OTP;
  if (status === RuleState.PENDING && is_3ds_or_otp) {
    return MessageDisabledIconEdit.PENDING;
  }

  if (status === RuleState.DISABLED && is_3ds_or_otp) {
    return MessageDisabledIconEdit.DISABLED;
  }

  return "";
};

export const is3dsOtp = (data: RuleItemsObject) => {
  const action = get(data, "rule.action", "");

  return action === RuleType.THREEDS || action == RuleType.OTP;
};
