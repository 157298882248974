import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../Filters/ItemCategory/interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { IFilterItem } from "@kushki/connect-ui";
import {
  FILTER_GROUPS,
  OPERATIONAL_COUNTRY_LABELS,
  STATE_ITEM_VALUES,
  STATE_LABEL_ITEM,
} from "../../shared/constants/labels/header_filters_labels";
import { CountryEnum } from "../../shared/enums/CountryEnum";
import { FilterSideBarProps } from "../Filters/FilterSideBar/FilterSideBar.interfaces";
import { cloneDeep } from "lodash";
import { FilterTextsEnum } from "../../shared/enums/FilterTextsEnum";
import { emptyFunction } from "../../shared/utils";

export const getItemsFilterDetailBar = (
  itemCategory: ItemCategoryProps[]
): TFilterDetailBar[] => {
  let itemsFilterDetailBar: TFilterDetailBar[] = [];

  itemCategory.map((itemSideBar: ItemCategoryProps) => {
    let itemsFilterBar: IFilterItem[] = [];

    itemSideBar.items.map((itemFilterSideBar: ItemCategoryItem) => {
      return (
        itemFilterSideBar.selected &&
        itemsFilterBar.push({
          label: itemFilterSideBar.label,
          value: itemFilterSideBar.value,
        })
      );
    });
    if (itemsFilterBar.length > 0)
      itemsFilterDetailBar.push({
        itemCategoryType: "byChips",
        items: itemsFilterBar,
        title: itemSideBar.groupSection,
      });
  });

  return itemsFilterDetailBar;
};
export const DEFAULT_OPERATIONAL_COUNTRIES: ItemCategoryItem[] = [
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.mexico],
    selected: false,
    value: CountryEnum.mexico,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.peru],
    selected: false,
    value: CountryEnum.peru,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.chile],
    selected: false,
    value: CountryEnum.chile,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.colombia],
    selected: false,
    value: CountryEnum.colombia,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.el_salvador],
    selected: false,
    value: CountryEnum.el_salvador,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.brasil],
    selected: false,
    value: CountryEnum.brasil,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.nicaragua],
    selected: false,
    value: CountryEnum.nicaragua,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.panama],
    selected: false,
    value: CountryEnum.panama,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.ecuador],
    selected: false,
    value: CountryEnum.ecuador,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.costa_rica],
    selected: false,
    value: CountryEnum.costa_rica,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.guatemala],
    selected: false,
    value: CountryEnum.guatemala,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.honduras],
    selected: false,
    value: CountryEnum.honduras,
  },
  {
    label: OPERATIONAL_COUNTRY_LABELS[CountryEnum.usa],
    selected: false,
    value: CountryEnum.usa,
  },
];

export const FILTER_SIDE_BAR_INITIAL_STATE: FilterSideBarProps = {
  categoryItems: [
    {
      groupSection: FILTER_GROUPS.STATE,
      isMinimize: true,
      items: [
        {
          label: STATE_LABEL_ITEM.ACTIVE,
          selected: false,
          value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.ACTIVE],
        },
        {
          label: STATE_LABEL_ITEM.PENDING,
          selected: false,
          value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.PENDING],
        },
        {
          label: STATE_LABEL_ITEM.INACTIVE,
          selected: false,
          value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.INACTIVE],
        },
      ],
      selectType: "multiple",
      type: "byChips",
    },
    {
      groupSection: FILTER_GROUPS.CONSTITUTIONAL_COUNTRY,
      isMinimize: true,
      items: cloneDeep(DEFAULT_OPERATIONAL_COUNTRIES),
      selectType: "multiple",
      type: "byChips",
    },
  ],
  filtersCount: 0,
  isMinimized: false,
  isOpen: true,
  onClick: emptyFunction,
  title: FilterTextsEnum.FILTER_BY,
};

export const FILTER_SIDE_BAR_INITIAL_STATE_MASTER_CUSTOMER: FilterSideBarProps =
  {
    categoryItems: [
      {
        groupSection: FILTER_GROUPS.CONSTITUTIONAL_COUNTRY,
        isMinimize: true,
        items: cloneDeep(DEFAULT_OPERATIONAL_COUNTRIES),
        selectType: "multiple",
        type: "byChips",
      },
    ],
    filtersCount: 0,
    isMinimized: false,
    isOpen: true,
    onClick: emptyFunction,
    title: FilterTextsEnum.FILTER_BY,
  };
