import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";

export interface CardSectionProps {
  isLoading: boolean;
  sectionTitle: string;
  sectionDescription: string;
  sectionComponent: JSX.Element;
  sectionSkeletonComponent: JSX.Element;
}

export const CardSection: React.FC<CardSectionProps> = (
  props: CardSectionProps
) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={3}>
        <Typography variant={"subtitle1"}>{props.sectionTitle}</Typography>
        <Box pt={1}>
          <Typography variant={"h4"} color={"textSecondary"}>
            {props.sectionDescription}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={9}>
        <Card>
          <CardContent>
            {props.isLoading ? (
              <Grid container spacing={2} direction="column">
                {props.sectionSkeletonComponent}
              </Grid>
            ) : (
              <Grid container spacing={2} direction="column">
                {props.sectionComponent}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
};
