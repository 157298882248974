import { Button } from "@mui/material";
import React, { FC, forwardRef } from "react";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import { DateTimeButtonProps } from "./DateTimeButton.interfaces";
import { buttonStyles } from "./DateTimeButton.styles";

const DateTimeButton: FC<DateTimeButtonProps> = forwardRef(
  ({ setAnchor, textValue, ...rest }: DateTimeButtonProps, ref) => (
    <Button
      endIcon={<CalendarIcon sx={buttonStyles.iconCalendar} />}
      onClick={(e) => setAnchor(e.currentTarget as Element)}
      {...rest}
      ref={ref}
      fullWidth
    >
      {textValue}
    </Button>
  )
);

export default DateTimeButton;
