import React, { useState } from "react";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import { Filter } from "@kushki/frontend-molecules/filter";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { get, set } from "lodash";
import { RangeAmount } from "../../../../types/range_amount";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";
import { FilterTypeEnum } from "../../../shared/infrastructure/FilterTypeEnum";

export interface IBoxFilter {
  idPopover?: string;
  openPopover: boolean;
  anchorEl: HTMLButtonElement | null;
  filters: IOptionFilter[];
  handleApplyFilters: (
    optionsChip: IChipSelect[],
    rangeAmount?: RangeAmount
  ) => void;
  handleClosePopover: () => void;
  rangeAmount?: RangeAmount;
  showAmount?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    filterPopover: {
      "&.MuiPopover-root .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline:focus:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:after": {
        borderBottom: "2px solid #0DC298",
      },
    },
  })
);

export interface IOptionFilter {
  id: string;
  title: string;
  options: {
    key: string;
    label: string;
    selected: boolean;
    canDisable?: string[];
  }[];
  countries?: string[];
  onlyWith?: string;
  multiple?: boolean;
  canDisableMultiple?: boolean;
}

export interface IChipSelect {
  key: string;
  selected: boolean;
  color: "inherit" | "primary" | "secondary" | "default";
  filter: string;
  label: string;
  textColor: "white" | "rgb(70, 82, 92)";
}

const populateOptionChips = (
  options: IOptionFilter[],
  handleApplyFilters: (optionsChip: any, rangeAmount?: RangeAmount) => void,
  rangeAmount?: IRangeAmount
) => {
  let result: { [key: string]: IChipSelect } = {};
  let chips: IChipSelect[] = [];
  let rangeFilter: RangeAmount | undefined = undefined;

  options.forEach((option: IOptionFilter) => {
    chips = chips.concat(
      option.options.map(
        (op: { key: string; label: string; selected: boolean }) => ({
          color: op.selected ? "secondary" : "default",
          filter: option.id,
          key: op.key,
          label: op.label,
          selected: op.selected,
          textColor: op.selected ? "white" : "rgb(70, 82, 92)",
        })
      )
    );
  });
  if (rangeAmount && rangeAmount.value) {
    rangeFilter = {
      type: rangeAmount.type as "min" | "max" | "eq" | "range",
      max: Number(rangeAmount.max),
      min: Number(rangeAmount.min),
      eq: Number(rangeAmount.eq),
      value: Number(rangeAmount.value),
    };
  }
  chips.forEach((chip: IChipSelect) => (result[chip.key] = chip));
  handleApplyFilters(chips, rangeFilter);
};

const BoxFilter = (props: IBoxFilter): JSX.Element => {
  const {
    idPopover,
    openPopover,
    anchorEl,
    handleClosePopover,
    handleApplyFilters,
    rangeAmount,
  } = props;
  const classes = useStyles();
  const [currency, setCurrency] = useState("MXN");

  const handleClearFilters = () => {
    props.filters.map((filter) => {
      filter.options.map((option) => {
        get(filter, "id") === FilterTypeEnum.models &&
          set(option, "disabled", true);
        option.selected = false;
      });
    });
    populateOptionChips(props.filters, handleApplyFilters);
  };

  const handleChangeCurrency = (filterResponse: IFilterResponse) => {
    const filterCurrency = filterResponse.listFilter.find(
      (filter) => filter.id === "currency"
    );
    if (!filterCurrency) return;

    const currencyOption = filterCurrency.options.find(
      (option) => option.selected
    );
    setCurrency(
      get(currencyOption, "key", get(filterCurrency, "options[0].key", "MXN"))
    );
  };

  const getAmountToShow = (show: boolean | undefined) =>
    show
      ? {
          listFilters: {
            filters: props.filters,
            multiple: false,
            txtToolTip: `Para seleccionar este modelo necesitas
              activar el tipo de transacción “Pay - In”
            `,
          },
          rangeFilter: {
            rangeAmount: {
              type: get(rangeAmount, "type", "min"),
              eq: `${get(rangeAmount, "eq", "")}`,
              min: `${get(rangeAmount, "min", "")}`,
              max: `${get(rangeAmount, "max", "")}`,
              value: `${get(rangeAmount, "value", "")}`,
            },
            maxAmount: 100000000,
            suffix: currency,
          },
        }
      : {
          listFilters: {
            filters: props.filters,
            multiple: false,
          },
        };

  return (
    <Filter
      className={classes.filterPopover}
      idPopover={idPopover}
      openPopover={openPopover}
      isMobile={false}
      openModal={false}
      anchorPopover={anchorEl}
      styles={{
        removeButtonText: "Restablecer",
        applyButtonStyle: "contained",
      }}
      handleClearFilters={handleClearFilters}
      handleChangeFilters={handleChangeCurrency}
      filters={getAmountToShow(props.showAmount)}
      handleApplyFilters={(filterResponse: IFilterResponse) => {
        populateOptionChips(
          filterResponse.listFilter,
          handleApplyFilters,
          filterResponse.rangeFilter?.filter
        );
      }}
      handleClose={handleClosePopover}
    />
  );
};

export default BoxFilter;
