import { TPaymentMethod, TTransactionStatus } from "../../shared/types";

export interface IComment {
  user?: string;
  created?: number;
  comment?: string;
}

export enum HistoricalItemEventTypeEnum {
  TRANSACTION = "transaction",
  FILE = "file",
  REFUND = "refund",
}

export enum HistoricalItemStatus {
  APPROVED = "APPROVED",
  APPROVAL = "APPROVAL",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  FAILED = "FAILED",
  GENERATED = "GENERATED",
  INITIALIZED = "INITIALIZED",
  RETRIED = "RETRIED",
  VOID = "VOID",
  REFUND = "REFUND",
  REQUESTED = "REQUESTED",
}

export interface IHistoricalItem {
  amount?: number;
  eventType?: string;
  paymentMethod?: string;
  status?: string;
  updatedAt?: string;
}
export interface ITransaction {
  id: string;
  date: string;
  hour: string;
  ticketNumber: string;
  transactionId?: string;
  transactionReference?: string;
  email: string;
  paymentMethod: TPaymentMethod;
  vehiclePlate: string;
  transactionAmount: string;
  status: TTransactionStatus;
  comments?: IComment[];
  certificateURL?: string;
  historical?: IHistoricalItem[];
  name: string;
  phoneNumber: string;
  approvalNumber: string;
  cardType: string;
  cardNumber: string;
  bank: string;
  bankID: string;
  cus: string;
  certificateState: string;
  sendAnswer: string;
  sendStatus: string;
  refund: string;
  refundState: string;
  answerCode: string;
  processor: string;
  answerMessage: string;
  socialReason?: string;
  reattemps: string;
  document_number: string;
  acquirerBank: string;
  months: string;
  fileStatusDownload?: string;
}

export interface ITransactionsState {
  areTransactionsLoading: boolean;
  currentActionTransaction: ITransaction;
  currentTransaction: ITransaction;
  isSelectedModalOpen: boolean;
  selectedTransactions: ITransaction[];
  transactions: ITransaction[];
}
