export enum BoardMemberContentEnum {
  MAIN = "Main",
  SECONDARY = "Secondary",
}

export enum BoardMemberModalEnum {
  CONFIRM = "Sí, eliminar",
  CANCEL = "Cancelar",
  EDIT = "Editar",
  DELETE = "Eliminar",
  DESCRIPTION_SIMPLE = "Se borrará el board member seleccionado",
  TITLE_SIMPLE = "¿Estás seguro de que deseas eliminar el board member?",
  SEE_ALL = "Ver todos",
  CAPTION = "Board member",
  ADDED_BM = "Board members agregados",
}
