import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { get } from "lodash";
import errorValidation from "../../assets/images/errorValidation.png";
import partialValidation from "../../assets/images/partialValidation.png";
import { CountRowsValidation } from "../common/CountRowsValidation/CountRowsValidation";
import { fileValidationDetailsStyles } from "./FileValidationDetails.styles";
import {
  LabelsDispersion,
  TypeFileEnum,
} from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { useFileValidationDetailsState } from "./state/useFileValidationDetailsState";
import { ValidationDetailSkeleton } from "../ValidationDetailSkeleton/ValidationDetailSkeleton";

export const FileValidationDetails: React.FC = () => {
  const classes = fileValidationDetailsStyles();
  const { handle, fileValidationDetails, isLoadingDetails } =
    useFileValidationDetailsState();
  const validatePartiallyProcessed: boolean =
    get(fileValidationDetails, "summary[0].rows_ok", 0) !== 0 &&
    get(fileValidationDetails, "summary[0].rows_error", 0) !== 0;

  return (
    <Grid container spacing={2} className={classes.mainGrid}>
      {isLoadingDetails ? (
        <ValidationDetailSkeleton />
      ) : (
        <Grid item xs={12}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={3}
          >
            {validatePartiallyProcessed && (
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="primary"
                  align={"center"}
                  className={classes.partialTitle}
                >
                  {
                    LabelsDispersion.VALIDATION_DETAILS_PARTIALY_PROCESSED_TITTLE
                  }
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"center"}>
                <img
                  src={
                    validatePartiallyProcessed
                      ? partialValidation
                      : errorValidation
                  }
                  alt={"error"}
                  className={classes.resultImage}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                align={"center"}
                className={
                  validatePartiallyProcessed
                    ? classes.partialDescription
                    : classes.title
                }
              >
                {validatePartiallyProcessed
                  ? LabelsDispersion.VALIDATION_DETAILS_PARTIALY_PROCESSED_DESCRIPTION
                  : LabelsDispersion.VALIDATION_DETAILS_ERROR_TITTLE}
              </Typography>
            </Grid>

            {validatePartiallyProcessed && (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="primary"
                    align={"center"}
                    className={classes.partialSubtitleOne}
                  >
                    {
                      LabelsDispersion.VALIDATION_DETAILS_PARTIALY_PROCESSED_SUBTITLE_ONE
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="primary"
                    align={"center"}
                    className={classes.partialSubtitleTwo}
                  >
                    {
                      LabelsDispersion.VALIDATION_DETAILS_PARTIALY_PROCESSED_SUBTITLE_TWO
                    }
                  </Typography>
                </Grid>
              </Grid>
            )}

            <CountRowsValidation
              url={get(fileValidationDetails, "summary[0].url")}
              typeFile={TypeFileEnum.INPUT}
              correctRowsTotal={get(
                fileValidationDetails,
                "summary[0].rows_ok",
                0
              )}
              errorRowsTotal={get(
                fileValidationDetails,
                "summary[0].rows_error",
                0
              )}
              rowsMessage={get(fileValidationDetails, "summary[0].details", [])}
            />

            <Grid item xs={12} className={classes.buttonsContainer}>
              <Grid
                container
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <Grid item xs={5}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handle.handleRequestOption}
                    fullWidth
                  >
                    {LabelsDispersion.REQUEST_OPTION}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
