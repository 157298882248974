const apiPrefix = {
  paymentCredentials: "/payment-credentials/v1/admin",
  hierarchy: "/hierarchy/v1",
};

export const ApiRoutesConstants = {
  adminCredentials: `${apiPrefix.paymentCredentials}/credentials`,
  adminCredentialSearch: `${apiPrefix.paymentCredentials}/credential/search`,
  adminCredentialSuggestions: `${apiPrefix.paymentCredentials}/credential/suggestions`,
  nodeFirstParent: `${apiPrefix.hierarchy}/node/first-parent`,
  hierarchyMerchant: `${apiPrefix.hierarchy}/merchant/`,
  merchantNodeSearch: "/billing-core-node/v1/merchant-node/search",
};
