import React from "react";
import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { RefundsPaginationStyles as styles } from "./RefundsPagination.styles";

export interface TablePaginationProps {
  total: number;
  page: number;
  rowsPerPage: number;
  changePage: (_event: React.ChangeEvent<unknown>, value: number) => void;
  changeRowsPerPage: (rowsPerPage: number) => void;
}

export default function RefundsPagination(props: TablePaginationProps) {
  return (
    <Box sx={styles.root}>
      <Grid container>
        <Grid item sx={styles.marginButton}>
          <Typography
            color={"grey"}
            variant={"body2"}
            sx={styles.typographyLabelPagination}
          >
            Transacciones por página
          </Typography>
          <Select
            value={props.rowsPerPage.toString()}
            onChange={(e) => props.changeRowsPerPage(Number(e.target.value))}
            sx={styles.selectPages}
            data-testid="select"
          >
            <MenuItem value={"10"}>10</MenuItem>
            <MenuItem value={"25"}>25</MenuItem>
            <MenuItem value={"50"}>50</MenuItem>
          </Select>
          <Typography
            color={"grey"}
            variant={"body2"}
            sx={styles.typographyLabelPagination}
          >
            {`${(props.page - 1) * props.rowsPerPage + 1}-${
              (props.page - 1) * props.rowsPerPage + props.rowsPerPage
            } of ${props.total}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" alignItems="center">
        <Pagination
          count={Math.ceil(Number(props.total / props.rowsPerPage))}
          onChange={props.changePage}
          color="primary"
          page={props.page}
        />
      </Grid>
    </Box>
  );
}
