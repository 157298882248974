enum FileUploadLabels {
  ADD_FILE = "Agregar archivo",
  FIND_DOCUMENT_1 = "Haz clic en ",
  FIND_DOCUMENT_2 = " para buscar un documento en tu dispositivo",
  LOADING_FILE = "Cargando archivo...",
  SIZE_LIMIT = 20,
}

const MAX_SIZE = (size: number) => `Máx. ${size} MB`;

enum CloseModalLabels {
  TITLE = "¿Deseas cerrar la alarma?",
  CANCEL = "Cancelar",
  CLOSE_ALARM = "Cerrar alarma",
  MODAL_BODY = "Al realizar este cambio, la alarma se marcará como cerrada y pasará a la sección histórico. Esta acción no puede deshacerse.",
  COMMENTS_PLACEHOLDER = "Agrega tus comentarios",
  ADD_COMMENTS = "Agregar comentarios",
  ATTACHMENTS = "Adjunta uno o más archivos de evidencia:",
  NORMAL_OPTION = "Cerrar alarma como normal",
  ROS_OPTION = "Cerrar alarma como ROS",
}

export { FileUploadLabels, CloseModalLabels, MAX_SIZE };
