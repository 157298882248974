import { Badge, Popover } from "@mui/material";
import React, { FC, useState } from "react";
import Filter from "./Filter/Filter";
import { FilterBoxProps, IFilters } from "./FilterBox.interfaces";
import { countFiltersSelected } from "./utils/functions";

const FilterBox: FC<FilterBoxProps> = ({
  filters = [],
  onChange = () => undefined,
  renderComponent,
}: FilterBoxProps) => {
  const [anchorEl, setAnchorEl] = useState<Element>();

  const numberOfFiltersSelected = countFiltersSelected(filters);

  const innerComponent = renderComponent({
    setAnchor: setAnchorEl,
  });

  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(undefined);

  const handleChange = (newFilters: IFilters[]) => {
    handleClose();
    onChange(newFilters);
  };

  return (
    <>
      <Badge color="secondary" badgeContent={numberOfFiltersSelected}>
        {innerComponent}
      </Badge>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Filter filters={filters} onChangeFilters={handleChange} />
      </Popover>
    </>
  );
};

export default FilterBox;
