import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

export interface IUseSelectComponentSectionState {
  selectValue: string;
  setSelectValue: (value: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IUseSelectComponentSectionProps {
  selectedValue: string;
  setTextValue: (value: string[]) => void;
}

export const useSelectComponentSectionState = (
  props: IUseSelectComponentSectionProps
): IUseSelectComponentSectionState => {
  const [selectValue, setSelectValue] = useState<string>(
    isEmpty(props.selectedValue) ? "" : props.selectedValue
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value);
    props.setTextValue([event.target.value]);
  };

  useEffect(() => {
    if (!isEmpty(props.selectedValue)) {
      props.setTextValue([props.selectedValue]);
    }
  }, []);

  return {
    selectValue,
    setSelectValue,
    handleChange,
  };
};
