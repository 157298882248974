import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { applyMiddleware, createStore, Store } from "redux";
import { Provider } from "react-redux";
import { rulesReducer } from "./store/reducer";
import thunk from "redux-thunk";
import RulesIndex from "./containers/RulesIndex/RulesIndex";
import BusinessRulesIndex from "./containers/BusinessRulesIndex/BusinessRulesIndex";
import BusinessRulesEdit from "./containers/BusinessRulesEdit/BusinessRulesEdit";
import WhitelistRulesEdit from "./containers/WhitelistRuleEdit/WhitelistRulesEdit";
import SecurityRulesEdit from "./containers/SecurityRulesEdit/SecurityRulesEdit";
// @ts-ignore
import Normalize from "react-normalize";
// @ts-ignore
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityRulesRoles } from "./shared/infrastructure/constants/SecurityRulesConstants";
export const store: Store = createStore(rulesReducer, applyMiddleware(thunk));

const Root = () => {
  return (
    <React.StrictMode>
      <Normalize />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ModuleSecurityWrapper
              basePath={`${environment.kushkiUrl}`}
              componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}`}
            >
              <Switch>
                <Route path="/rules" exact component={RulesIndex} />
                <Route
                  path="/rules/business/:merchantId"
                  exact
                  component={BusinessRulesIndex}
                />
                <Route
                  path="/rules/business/rule/:merchantId/:ruleId"
                  exact
                  component={BusinessRulesEdit}
                />
                <Route
                  path="/rules/business/rule/:merchantId"
                  exact
                  component={BusinessRulesEdit}
                />
                <Route
                  path="/rules/security/rule/:ruleId"
                  exact
                  component={SecurityRulesEdit}
                />
                <Route
                  path="/rules/security/rule"
                  exact
                  component={SecurityRulesEdit}
                />
                <Route
                  path="/rules/whitelist/rule"
                  exact
                  component={WhitelistRulesEdit}
                />
                <Route
                  path="/rules/whitelist/rule/:merchantId"
                  exact
                  component={WhitelistRulesEdit}
                />
              </Switch>
            </ModuleSecurityWrapper>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
