import { createNamedStyles } from "../../shared/utils";

export const TableHeaderFilterStyles = createNamedStyles({
  baseBtn: {
    border: 1,
    borderColor: "neutral.grey5",
    borderRadius: "4px",
    borderStyle: "solid",
    height: "36px",
  },
  btnTime: {
    color: "text.grey",
    ml: 2,
    mr: 2,
    px: 1,
    fontSize: 16,
    fontFamily: "sans-serif",
    height: 44,
    background: "#F0F4F9",
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
      background: "#F0F4F9",
    },
    "&:focus": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
      background: "#F0F4F9",
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  iconCalendar: {
    p: "2px",
  },
  iconSearch: {
    ml: 1,
    height: 24,
    width: 24,
    color: "#6D7781",
  },
  input: {
    borderRadius: 1,
    background: "#F0F4F9",
    height: 44,
    width: 200,
    fontWeight: 400,
    color: "text.dark",
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.dark",
    },
    "&:focus-within": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.dark",
    },
  },
});
