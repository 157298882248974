import React, { RefObject } from "react";
import { AppBar, Box, Tab, Tabs, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyValue } from "../../../types/key_value";
import { FieldError, NestDataObject } from "react-hook-form";
import { CardFormUploadComponent } from "../CardFormUploadComponent/CardFormUpdloadComponent";
import { ModalDialog } from "../ModalDialog/ModalDialog";
import { get } from "lodash";
import { FileUploadActionButtonsComponentProps } from "../UploadActionButtonsComponent/UploadActionButtonsComponent";
import { SecurityWrapperEnum } from "../../shared/infrastructure/Enums/SecurityWrapperEnum";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  labelDescription: {
    marginTop: theme.spacing(1),
    fontSize: "18px",
  },
  labelTitle: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  labelDescriptionButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  labelTitleStep: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  contentWidth: {
    maxWidth: "fit-content",
  },
  cardStyle: { backgroundColor: "#F7FAFC", border: "none" },
  subtitle2: {
    ...theme.typography.body1,
    fontSize: "16px",
    fontWeight: 400,
    color: "#6D7781",
  },
  content: {
    marginTop: 10,
    padding: 10,
  },
  root: {
    display: "block",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    "& .MuiTab-root": {
      textTransform: "none",
    },
  },
}));

function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  );
}

export interface IFormUploadCardComponentProps {
  ivaRetention?: KeyValue[] | undefined;
  methods: {
    handleOnChangeSelection: (
      taxpayer: KeyValue | null,
      identifier: string
    ) => void;
    handleValueRetentionChange: (value: string, identifier: string) => void;
    handleOnChangeTab: (value: number) => void;
  };
  values: {
    taxpayerValue?: KeyValue | null;
    activityValue?: KeyValue | null;
    ivaRetentionValues?: KeyValue | null;
    incomeSourceConceptValue?: KeyValue | null;
    rentRetentionValue?: string;
  };
  register(
    obj: object
  ): ((instance: any) => void) | RefObject<any> | null | undefined;
  errors: NestDataObject<Record<string, any>, FieldError>;
  tabPosition?: number;
  editDisable?: boolean;
  formActionButtonProps?: FileUploadActionButtonsComponentProps;
  rol?: SecurityWrapperEnum;
}

export const FormUploadCardComponent: React.FC<IFormUploadCardComponentProps> = (
  props: IFormUploadCardComponentProps
) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(get(props, "tabPosition", 0));
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleChange = (_event: React.ChangeEvent<{}>) => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOk = () => {
    const localValue = value === 0 ? 1 : 0;
    setValue(localValue);
    props.methods.handleOnChangeTab(localValue);
    handleCloseDialog();
  };
  const titleModalIVA =
    "¿Deseas salir de Retención IVA sin guardar las configuraciones?";
  const titleModalRetention =
    "¿Deseas salir de Retención Renta sin guardar las configuraciones?";

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={(event) => handleChange(event)}
            aria-label="simple tabs example"
            variant="fullWidth"
            indicatorColor={"primary"}
            centered={true}
          >
            <Tab label="Retención IVA" {...tabProps(0)} />
            <Tab label="Retención Renta" {...tabProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <CardFormUploadComponent
            errors={props.errors}
            register={props.register}
            methods={props.methods}
            values={props.values}
            ivaRetention={props.ivaRetention}
            title={"Configuración retención IVA"}
            isIva={true}
            editDisable={props.editDisable}
            rolAzure={get(props, "rol")}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CardFormUploadComponent
            errors={props.errors}
            register={props.register}
            methods={props.methods}
            values={props.values}
            title={"Configuración retención Renta"}
            isIva={false}
            editDisable={props.editDisable}
            rolAzure={get(props, "rol")}
          />
        </TabPanel>
      </div>
      <ModalDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleOk={handleOk}
        title={value === 1 ? titleModalRetention : titleModalIVA}
        labelBtnAccept={"Salir sin guardar"}
      />
    </>
  );
};
