import { namesFormEnum } from "../enums/NamesFormEnum";

export const INPUT_ERROR_MESSAGES = {
  aliasError: "Es necesario identificar el webhook teniendo un alias",
  eventError:
    "Es necesario seleccionar al menos un evento para configurar el webhook.",
  paymentMethodError:
    "Es necesario seleccionar al menos un medio de pago para configurar el webhook.",
  required: "Campo requerido",
  type: "manual",
  urlError: "Es necesario agregar al menos un URL para configurar el webhook.",
};

const formErrors = {
  alias: {
    errorMessage: INPUT_ERROR_MESSAGES.aliasError,
    name: namesFormEnum.ALIAS,
  },
  event: {
    errorMessage: INPUT_ERROR_MESSAGES.eventError,
    name: namesFormEnum.EVENT,
  },
  options: {
    errorMessage: INPUT_ERROR_MESSAGES.paymentMethodError,
    name: namesFormEnum.OPTIONS,
  },
  url: {
    errorMessage: INPUT_ERROR_MESSAGES.urlError,
    name: namesFormEnum.URL,
  },
};

export const ERRORS_SECTION = {
  [namesFormEnum.ALIAS]: formErrors.alias,
  [namesFormEnum.URL]: formErrors.url,
  [namesFormEnum.EVENT]: formErrors.event,
  [namesFormEnum.OPTIONS]: formErrors.options,
};
