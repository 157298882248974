import React from "react";
import { get } from "lodash";
import { format } from "date-fns";
import { TransactionTable } from "../../../types/transaction_table";
import PaperInfo from "../Common/PaperInfo/PaperInfo";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { CatalogsEnum } from "../../shared/infrastructure/catalogs-enum";
import { getFromCatalog } from "../ModalBodyBilling/ModalBodyTrxBilling";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { createStyles, Grid, Paper } from "@material-ui/core";
import moment from "moment";
import { ModalBodyContentDetail } from "../ModalTabs/ModalBodyContentDetail";
import {
  subSectionEnum,
  tabEnum,
} from "../../shared/infrastructure/AdvanceConfigModal";
import { makeStyles } from "@material-ui/core/styles";
import { InfoTrxProps } from "./ModalBodyTabs";
import { TextArea } from "./ModalBodyColombia";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { getValueWithDefault } from "../../shared/utils/utils";
import { ValueStatusFinalEnum } from "../../shared/infrastructure/CatalogFinalStatus";

const formatDate: string = "dd MMM. yyyy";

const getDate = (trx: TransactionTable | undefined, field: string) => {
  return format(moment(get(trx, field, new Date())).valueOf(), formatDate);
};

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
  })
);

const PaperContentDetail = (
  props: InfoTrxProps,
  subSection: subSectionEnum,
  isCharge: boolean = false
) => {
  const classes = useStyles();
  const trx = props.trxInfo;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ModalBodyContentDetail
              tabName={tabEnum.infoTab}
              trxProps={props}
              country={get(trx, "country", CountryEnum.ecuador)}
              subSection={subSection}
              isMinAmount={props.isMinAmount}
              isCharge={isCharge}
              isHistoric={props.isHistoric}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ModalBodyEcuador = (props: InfoTrxProps) => {
  const trx = props.trxInfo;
  const validation: boolean =
    props.statusFlow == StatusFlowEnum.VALIDATOR &&
    get(props.trxInfo, "status", "") === "manual";

  const ModalKind = () => {
    switch (get(trx, "kind", "")) {
      case KindEnum.INVOICE:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCard1)}
            {PaperContentDetail(props, subSectionEnum.infoCard2)}
            {get(props, "trxInfo.is_ocb", false)
              ? PaperContentDetail(props, subSectionEnum.infoCard4)
              : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
            {PaperContentDetail(props, subSectionEnum.infoCard3)}
          </>
        );
      case KindEnum.CHARGE:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCardCharge1, true)}
            {PaperContentDetail(props, subSectionEnum.infoCardCharge2, true)}
            {get(props, "trxInfo.is_ocb", false)
              ? PaperContentDetail(props, subSectionEnum.infoCard4)
              : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
            {PaperContentDetail(props, subSectionEnum.infoCardCharge3, true)}
          </>
        );
      case KindEnum.DISPERSION:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCardDispersion1)}
            {PaperContentDetail(props, subSectionEnum.infoCardDispersion2)}
            {get(props, "trxInfo.is_ocb", false)
              ? PaperContentDetail(props, subSectionEnum.infoCard4)
              : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
            {PaperContentDetail(props, subSectionEnum.infoCard3)}
          </>
        );
      case KindEnum.RECEIVABLE:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCardReceivable1)}
            {PaperContentDetail(props, subSectionEnum.infoCardReceivable2)}
            {PaperContentDetail(props, subSectionEnum.infoCard3)}

            {validation && <TextArea {...props} />}
          </>
        );

      default:
        return (
          <React.Fragment>
            <PaperInfo
              values={[
                {
                  label: "Referencia",
                  value: get(trx, "invoice_number"),
                  xs: 4,
                },
                {
                  label: "Referencia",
                  value: get(trx, "invoice_number"),
                  xs: 4,
                },
                {
                  label: "Fecha creación",
                  value: getDate(trx, "created"),
                  xs: 4,
                },
                {
                  label: "Fecha procesado",
                  xs: 4,
                  value: getDate(trx, "transacted"),
                },
                {
                  label: "Monto total",
                  xs: 4,
                  value: get(trx, "amount"),
                },
                {
                  label: "Forma de cobro",
                  xs: 4,
                  value:
                    get(trx, "status", "") === "completed"
                      ? "Automático"
                      : "Manual",
                },
                {
                  label: "Tipo",
                  xs: 4,
                  value: get(trx, "charge_min", ""),
                },
                {
                  label: "Descripción",
                  xs: 12,
                  value: get(trx, "description", ""),
                },
              ]}
            />
            <PaperInfo
              values={[
                {
                  label: "Contrapartida:",
                  value: get(trx, "transaction_id", ""),
                  xs: 4,
                  xs2: 8,
                },
                {
                  label: "Banco:",
                  value: getFromCatalog(
                    get(trx, "country") as CountryEnum,
                    CatalogsEnum.Banks,
                    get(trx, "bank_id"),
                    ValueStatusFinalEnum.Guion
                  ),
                  xs: 4,
                  xs2: 8,
                },
                {
                  label: "N° de cuenta",
                  value: getValueWithDefault(
                    trx,
                    "account_bank",
                    ValueStatusFinalEnum.Guion
                  ),
                  xs: 4,
                  xs2: 8,
                },
                {
                  label: "N° de documento",
                  value: get(trx, "tax_id"),
                  xs: 4,
                  xs2: 8,
                },
              ]}
              direction={"row"}
            />
            <PaperInfo
              values={[
                {
                  label: "Razón social",
                  value: get(trx, "social_reason"),
                  xs: 4,
                },
                {
                  label: "Tax ID",
                  value: get(trx, "tax_id"),
                  xs: 4,
                },
                {
                  label: "Email",
                  value: get(trx, "email"),
                  xs: 4,
                },
                {
                  label: "Dirección:",
                  value: get(trx, "address"),
                  xs: 12,
                },
              ]}
            />
          </React.Fragment>
        );
    }
  };

  return <ModalKind />;
};

export default ModalBodyEcuador;
