export enum ConfigurationCodeEnum {
  CN007 = "cn007",
  CN008 = "cn008",
  CN010 = "cn010",
}

export enum EntityNameEnum {
  CUSTOMER = "CUSTOMER",
  OWNER = "OWNER",
  BRANCH = "BRANCH",
}
