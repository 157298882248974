import { useEffect, useRef, useState } from "react";
import { ItemCategoryProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import {
  searchMerchants,
  setBranchesData,
} from "../../../store/actionCreators";
import { EntityNameEnum } from "../../../shared/infrastructure/enums/EntityNameEnum";
import { MerchantStatusEnum } from "../../../shared/infrastructure/enums/MerchantStatusEnum";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { defaultTo, get, concat, debounce, set, isEmpty } from "lodash";
import { FilterSideBarProps } from "@kushki/connect-ui";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { HeaderFiltersProps } from "../HeaderFilters.interfaces";
import {
  FILTER_GROUPS,
  STATE_ITEM_VALUES,
  STATE_LABEL_ITEM,
} from "../../../shared/constants/header_filters_labels";
import { GetCredentialsRequest } from "../../../../types/get_credentials_request";
import { merchantIdBranchesJoined } from "../../../shared/utils/joinMerchantId";
import { changeItemCategoryToFilterDetailBar } from "../../../shared/utils/filterDetailBarUtil";

interface IUseHeaderFilterState {
  filterSideBar: FilterSideBarProps;
  onChangeFilterDetailBar: (changeFilter: TOnChangeFilters) => void;
  filterDetailBar: TFilterDetailBar[];
}
export const useHeaderFilterState = (
  props: HeaderFiltersProps
): IUseHeaderFilterState => {
  const dispatch = useDispatch();
  const { branchesData } = useSelector((state: IAppState) => state);
  const [requestSearch, setRequestSearch] = useState({
    entityName: EntityNameEnum.BRANCH,
    limit: 10,
    filter: {
      merchantStatus: MerchantStatusEnum.ACTIVE,
    },
    name: "",
    offset: 0,
    path: props.path,
    strictSearch: false,
  });

  const searchBranches = (value: string) => {
    firstChargeBranches.current = false;

    setRequestSearch((prevState) => ({ ...prevState, name: value }));
  };

  useEffect(() => {
    setRequestSearch((prevState) => ({ ...prevState, path: props.path }));
  }, [props.path]);

  useEffect(() => {
    if (!isEmpty(requestSearch.name))
      dispatch(searchMerchants(requestSearch, setBranchesData));
  }, [requestSearch.name]);

  const debouncedOnChange = debounce(searchBranches, 500);
  const [categoryItems, setCategoryItems] = useState<ItemCategoryProps[]>(
    props.isBackofficeMasterCustomer
      ? [
          {
            title: FILTER_GROUPS.STATE,
            isMinimize: true,
            items: [
              {
                label: STATE_LABEL_ITEM.ACTIVE,
                selected: false,
                value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.ACTIVE],
              },
              {
                label: STATE_LABEL_ITEM.INACTIVE,
                selected: false,
                value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.INACTIVE],
              },
            ],
            selectType: "single",
            type: "byChips",
            onItemsSelect: () => "",
            placeHolder: FILTER_GROUPS.STATE,
          },
          {
            isMinimize: true,
            items: [],
            title: "Branch",
            placeHolder: "Selecciona uno o varios",
            selectType: "multiple",
            type: "bySelectCheck",
            isLoading: false,
            useFilteredSelection: true,
            mergePreviousSelections: true,
            displaySelectedChipsBelow: true,
            onlyPlaceholderInput: true,
            persistInputText: true,
            onItemsSelect: () => "",
            onChangeTextField: debouncedOnChange,
          },
        ]
      : [
          {
            title: FILTER_GROUPS.STATE,
            isMinimize: true,
            items: [
              {
                label: STATE_LABEL_ITEM.ACTIVE,
                selected: false,
                value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.ACTIVE],
              },
              {
                label: STATE_LABEL_ITEM.INACTIVE,
                selected: false,
                value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.INACTIVE],
              },
            ],
            selectType: "single",
            type: "byChips",
            onItemsSelect: () => "",
            placeHolder: FILTER_GROUPS.STATE,
          },
        ]
  );

  const [filterDetailBar, setFilterDetailBar] = useState<TFilterDetailBar[]>(
    changeItemCategoryToFilterDetailBar(categoryItems)
  );
  const firstChargeBranches = useRef(true);
  const firstRender = useRef(true);
  const onApply = (selected: ItemCategoryProps[]) => {
    setFilterDetailBar(changeItemCategoryToFilterDetailBar(selected));
  };
  const onChangeFilterDetailBar = (changeFilter: TOnChangeFilters) => {
    setFilterDetailBar(changeFilter.newArgs);
    categoryItems.map((item) => {
      if (
        defaultTo(item.title, item.placeHolder) === changeFilter.affected.title
      )
        item.items.map((subItem) => {
          if (subItem.label === changeFilter.affected.itemDeleted.label)
            subItem.selected = false;
        });
    });
    setCategoryItems(categoryItems);
  };
  const buildRequestSidebarFilters = (
    filterSelected: TFilterDetailBar[]
  ): Partial<GetCredentialsRequest> => {
    let concatBranches: string = "";
    const filterRequest: Partial<GetCredentialsRequest> = {};

    set(filterRequest, "path", get(props, "path", ""));
    filterSelected.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case FILTER_GROUPS.STATE:
          set(filterRequest, "filter.enable", get(filter, "items[0].value"));
          break;
        case FILTER_GROUPS.BRANCHES_FILTER:
          concatBranches = merchantIdBranchesJoined(
            get(filter, "items", []),
            "value"
          );
          set(filterRequest, "merchantId", concatBranches);
          break;
      }
    });

    return filterRequest;
  };

  useEffect(() => {
    const filtered: ItemCategoryProps[] = [];
    const aux_category = categoryItems.map((item) => {
      if (item.title === "Branch") {
        const actual_items = item.items.map((actualItem) => actualItem.value);
        item.items = concat(
          item.items,
          defaultTo(get(branchesData, "data"), [])
            .filter((itemBr) => !actual_items.includes(itemBr.merchant_id!))
            .map((itemBranch, index) => ({
              label: itemBranch.name!,
              value: itemBranch.merchant_id!,
              selected: firstChargeBranches.current && index < 10,
            }))
        );
      }
      const aux_selected = item.items.filter(
        (itemFilter) => itemFilter.selected
      );
      if (aux_selected.length > 0) {
        filtered.push({ ...item, items: aux_selected });
      }
      return item;
    });
    setCategoryItems(aux_category);
    if (
      firstChargeBranches.current &&
      defaultTo(branchesData?.data, []).length > 0
    ) {
      onApply(filtered);
    }
  }, [branchesData]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    props.onChangeFilterSidebar(buildRequestSidebarFilters(filterDetailBar));
  }, [filterDetailBar]);

  return {
    filterSideBar: {
      onClick: onApply,
      categoryItems,
      filtersCount: 0,
      title: "Filtrar",
      isOpen: false,
      isMinimized: false,
    },
    onChangeFilterDetailBar,
    filterDetailBar,
  };
};
