export enum ModalContentTypeEnum {
  DEFAULT = "Default",
  ACTIVE = "Active",
  ERROR = "Error",
}

export enum ModalContentButtonTypeEnum {
  ERROR = "error",
  PRIMARY = "primary",
}
