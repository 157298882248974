import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const modalDownloadFilesStyles = createNamedStyles({
  listItemText: {
    paddingRight: "20px",
    maxWidth: "400px",
  },
  textStyles: {
    fontSize: "14px",
  },
});
