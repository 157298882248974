/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
KushkiUrlWS: string;
envName: string;
devTools: boolean;
rollbarToken: string;
} = {
kushkiUrl: "https://api.kushkipagos.com",
KushkiUrlWS: "wss://api-ws.kushkipagos.com",
envName: "primary",
rollbarToken: "89f7e1a557c745448415cf5b0b63b1f5",
devTools: false,
};
