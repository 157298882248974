import React from "react";
import { Webhook } from "../../../types/webhook_response";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Copy } from "react-feather";
import {
  OPTIONS,
  TRANSACTION_STATUS,
} from "../../shared/infrastructure/constants/OptionsEnum";
import { CheckCircle, XCircle } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { WebhookStatusEnum } from "../../shared/infrastructure/constants/WebhookListConstants";

const useStyles = makeStyles(() => ({
  successBox: {
    width: "32px",
    height: "32px",
    backgroundColor: "#D2FFF4",
    borderRadius: "4px",
    textAlign: "center",
  },
  circleCheck: {
    marginTop: "7px",
    color: "#0DC298",
  },
  errorBox: {
    width: "32px",
    height: "32px",
    backgroundColor: "#FFDFE5",
    borderRadius: "4px",
    textAlign: "center",
  },
  xCircle: {
    marginTop: "7px",
    color: "#AD0C2A",
  },
}));

export interface BodyDetailProps {
  webhook: Webhook;
  handleCopyTextMetadata: (value: string) => void;
}

export const BodyDetail: React.FC<BodyDetailProps> = (
  props: BodyDetailProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h6"} color={"primary"}>
            URL general
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          {props.webhook.status && props.webhook.status.length > 0 ? (
            <Grid item xs={12}>
              <Paper elevation={0} style={{ backgroundColor: "#F7FAFC" }}>
                <Grid container spacing={2} component={Box} px={2}>
                  <Grid item xs={12}>
                    {props.webhook.status.map((data, index) => (
                      <Box display={"flex"} key={index}>
                        <Box
                          mr={1}
                          className={
                            data.status === WebhookStatusEnum.OK
                              ? style.successBox
                              : style.errorBox
                          }
                        >
                          {data.status === WebhookStatusEnum.OK ? (
                            <CheckCircle
                              size={17}
                              className={style.circleCheck}
                            />
                          ) : (
                            <XCircle size={17} className={style.xCircle} />
                          )}
                        </Box>
                        <Box flexGrow={1}>
                          <Typography style={{ marginTop: "2px" }}>
                            {data.url}
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            onClick={() =>
                              props.handleCopyTextMetadata(data.url)
                            }
                          >
                            <Copy size={14} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h6"} color={"primary"}>
            Eventos configurados
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ backgroundColor: "#F7FAFC" }}>
            {props.webhook.eventTransform.map(
              (
                event: { service: string; status: string[]; urls?: string[] },
                index: number
              ) => (
                <React.Fragment key={`frag_${index}`}>
                  <Grid
                    container
                    component={Box}
                    p={2}
                    key={`container_${index}`}
                  >
                    <Grid item xs={6} key={`service_${index}`}>
                      <Typography>
                        {OPTIONS[event.service]}
                        {":"}
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} key={`status_${index}`}>
                      {event.status.map((status: string) => (
                        <Grid item xs={12} key={status}>
                          <Typography>{TRANSACTION_STATUS[status]}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} key={`divider_${index}`}>
                    <Box px={2}>
                      <Divider color={"#CBD5E0"} />
                    </Box>
                  </Grid>
                </React.Fragment>
              )
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
