import {
  NEW_CONSOLE,
  QueryParamEnum,
} from "../../../shared/enum/QueryParamEnum";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IGenericBreadcrumbProps } from "../../../shared/interfaces/IGenericBreadcrumbProps";
import {
  BreadcrumbState,
  IUserForm,
  IUseUserCreateEdit,
  RedirectLabel,
} from "./useUserCreateEdit.interfaces";
import {
  defaultTo,
  each,
  filter,
  find,
  get,
  has,
  isEmpty,
  isEqual,
  keys,
  set,
} from "lodash";
import { ROUTES } from "../../../shared/constants/routes";
import { PathEnum } from "../../../shared/enum/path-enum";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { StatusTypeEnum } from "../../../shared/enum/InputTypeEnum";
import { UserFormLabel } from "../../../shared/constants/labels/UserFormLabel";
import { useForm, UseFormReturn } from "react-hook-form";
import { findMerchantNode } from "../../../shared/utils/functions/find_merchant_node";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import {
  isRoleFIN,
  Role,
  ROLES,
  ROLES_MERCHANT_BACKOFFICE,
  ROLES_NAMES,
  ROLES_NO_ADMIN,
  ROLES_WITHOUT_REPEATS,
  RolesId,
} from "../../../shared/constants/RoleNames";
import { IFooterNewConsoleProps } from "../../../shared/interfaces/IFooterNewConsoleProps";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { ConfigTypeEnum } from "../../../shared/enum/configEnum";
import { format, formatISO } from "date-fns";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import {
  createUser,
  editUser,
  findUser,
  getMerchants,
  getSearchMerchantsInfo,
  validateUsername,
} from "../../../store/thunks/userForm/userForm.thunk";
import {
  INITIAL_USER_STORE,
  setStateLoading,
  setUser,
  setValidateUserName,
} from "../../../store/reducers/userForm/userForm.slice";
import { MerchantForm } from "../../../../types/merchant_form";
import { getFirstParent } from "../../../store/thunks/users/users.thunk";
import { SearchMerchantNodeRequest } from "../../../../types/search_merchant_node_request";
import { requiredConfigs } from "../../../shared/constants/requiredConfigs";
import { RoleNameEnum } from "../../../shared/enum/roleNameEnum";
import { ErrorMessageEnum } from "../../../shared/enum/error_messages_form";
import {
  IActivateNodeMerchant,
  IActivateUser,
} from "../../../store/interfaces/userForm.interfaces";
import {
  addMerchantIdByConfigsRequired,
  validateStatusMerchant,
} from "../../../shared/utils/functions/validate_required_configs";
import {
  StatusConfigEnum,
  StatusNodeEnum,
} from "../../../shared/enum/statusConfigEnum";
import { IRedirect } from "../../../store/interfaces/generalData.interfaces";
import axios, { CancelTokenSource } from "axios";

const INITIAL_USER_FORM = {
  email: "",
  familyName: "",
  group: {},
  kindRoles: "",
  name: "",
  publicMerchantId: "",
  userName: "",
};
const getUserRole = (roles: string[]): boolean => {
  const aux_role = roles.filter((role) => {
    return get(
      defaultTo(JSON.parse(localStorage.getItem("roles")!), {}),
      role,
      [""]
    );
  });

  return get(
    defaultTo(JSON.parse(localStorage.getItem("roles")!), {}),
    aux_role[0],
    false
  );
};
const uncheckRoles = (list: Role[][], setValue: UseFormSetValue<IUserForm>) => {
  list.forEach((listItem) => {
    listItem.forEach((role) => {
      // @ts-ignore
      setValue(`group.${role.id}`, false);
    });
  });
};

export const useUserCreateEdit = (): IUseUserCreateEdit => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    merchantsInfo,
    firstParent,
    merchants,
    user: userStore,
    isLoadingCreateEditUser,
    userValidate: isValidUserName,
    isLoadingValidateUsername,
  } = useAppSelector((state: RootState) => ({
    ...state.userForm,
    ...state.users,
  }));
  const consoleQuery: string = searchParams.get(QueryParamEnum.CONSOLE) || "";
  const publicMerchantId: string =
    searchParams.get(QueryParamEnum.PUBLIC_MERCHANT_ID) ||
    localStorage.getItem("merchantId")! ||
    "";
  const userName = params[QueryParamEnum.USER_NAME];
  const merchantName: string =
    searchParams.get(QueryParamEnum.MERCHANT_NAME) || "";
  const isOwner: boolean = useMemo(
    () => get(firstParent, "entityName", "") === EntityEnum.OWNER,
    [get(firstParent, "entityName", "")]
  );
  const is_admin: boolean = getUserRole([
    RolesId.BackofficeAdmin,
    RolesId.RiskManager,
  ]);
  const is_snr_admin: boolean = getUserRole([RolesId.SnrCoordinator]);

  const kindRoles = !publicMerchantId && is_admin ? ROLES_NAMES : [];
  const isCreateForm = isEmpty(userName);
  const labelForm = isCreateForm
    ? UserFormLabel.CREATE_USER
    : UserFormLabel.EDIT_USER;
  const [isCustomerSelected, setIsCustomerSelected] = useState<boolean>(false);
  const [isChargeBackManagerSelected, setisChargeBackManagerSelected] =
    useState<boolean>(false);

  const [viewCheckboxRoles, setViewCheckboxRoles] = useState<boolean>(false);
  const [breadcrumbState, setBreadcrumbState] = useState<BreadcrumbState>({
    isHideSideBar: searchParams.get(QueryParamEnum.HIDE_SIDE_BAR) === "true",
    lastItemBreadcrumb: labelForm,
    merchantName: searchParams.get(QueryParamEnum.MERCHANT_NAME) || "",
  });
  const [footerLabels, setFooterLabels] = useState<
    Pick<IFooterNewConsoleProps, "modifyName" | "date">
  >({
    date: "",
    modifyName: "",
  });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [viewMerchantSelect, setViewMerchantSelect] = useState(
    is_admin || !!merchantName
  );
  const form: UseFormReturn<IUserForm> = useForm<IUserForm>({
    defaultValues: { ...INITIAL_USER_FORM, publicMerchantId },
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  const [kindSelectedCheck, setKindSelectedCheck] = useState<string>("");

  const redirectLabels = (
    publicMerchantId: string,
    merchantName: string,
    isHideSideBar: boolean
  ): RedirectLabel => {
    const labels: RedirectLabel = {
      firstLabel: "Inicio",
      firstUrl: "/dashboard",
      secondLabel: "Usuarios",
      secondUrl: `/${ROUTES.SPA_BASENAME}${ROUTES.USERS}`,
    };

    if (!merchantName) {
      return labels;
    }

    labels.firstLabel = "Comercios";
    labels.firstUrl = PathEnum.MERCHANT_LIST;
    labels.secondLabel = isHideSideBar ? "Crear comercio" : merchantName;
    if (isHideSideBar) {
      labels.secondUrl = `${PathEnum.MERCHANT_DETAILS}?publicMerchantId=${publicMerchantId}&hideSideBar=${isHideSideBar}`;
    } else {
      labels.secondUrl = `${PathEnum.MERCHANT_CONFIG}/${publicMerchantId}`;
    }

    return labels;
  };
  const breadcrumbItem: RedirectLabel = redirectLabels(
    publicMerchantId,
    breadcrumbState.merchantName,
    breadcrumbState.isHideSideBar
  );

  const valuesRoles: object = form.watch("group");
  const watchEmail = form.watch("email");
  const watchGroupStringified = JSON.stringify(valuesRoles);
  const watch_kind_role: string = form.watch("kindRoles");

  const handleBreadcrumbItem = (): IGenericBreadcrumbProps => {
    return {
      breadcrumbItems: [
        { label: breadcrumbItem.firstLabel, url: breadcrumbItem.firstUrl },
        {
          label: breadcrumbItem.secondLabel,
          url: breadcrumbItem.secondUrl,
        },
      ],
      lastItem: breadcrumbState.lastItemBreadcrumb,
    };
  };
  const handleReturn = () => {
    const branchName: String = get(
      findMerchantNode(get(merchantsInfo, "data", []), publicMerchantId),
      "name",
      ""
    );

    if (!isOwner) {
      navigate(
        `${
          ROUTES.USERS
        }?publicMerchantId=${publicMerchantId}&merchantName=${defaultTo(
          merchantName,
          branchName
        )}&console=3`
      );
    } else {
      window.location.href = ROUTES.RESUME(publicMerchantId);
    }
  };

  const handleOpenCloseModal = () => {
    setOpenModal(!openModal);
  };
  const handleOk = () => {
    window.location.href = breadcrumbItem.secondUrl;
    handleOpenCloseModal();
  };

  const handleChangeMerchant = (
    _event: object,
    value: { _source: MerchantForm } | null
  ) => {
    const cancelToken: CancelTokenSource = axios.CancelToken.source();

    if (value !== null) {
      uncheckRoles([ROLES_MERCHANT_BACKOFFICE], form.setValue);
      setIsCustomerSelected(
        isEqual(get(value, "_source.entityName", ""), EntityEnum.CUSTOMER)
      );
    } else {
      form.setValue("publicMerchantId", "");
      setIsCustomerSelected(false);
    }
    cancelToken.cancel("");
  };
  const handleChangeMerchants = (value: string): void => {
    dispatch(
      getMerchants({
        offset: 0,
        text: value,
      })
    );
  };
  const handleChangeRoles = (
    _: ChangeEvent<HTMLInputElement>,
    nameCheck: string
  ) => {
    let currentKind: string = form.getValues().kindRoles;

    setisChargeBackManagerSelected(false);
    if (get(form.getValues().group, RolesId.ChargebackManager)) {
      const listChecks: Role[] = filter(
        ROLES_MERCHANT_BACKOFFICE,
        (item) => item.id !== RolesId.ChargebackManager
      );

      setisChargeBackManagerSelected(true);
      uncheckRoles([listChecks], form.setValue);
    }

    if (isRoleFIN(nameCheck.replace("group.", "")))
      currentKind = RoleNameEnum.FIN_BACKOFFICE;

    if (!isEqual(currentKind, kindSelectedCheck)) {
      const listRoles: Role[] = ROLES_WITHOUT_REPEATS.filter(
        (item: Role) => !isEqual(item.kind, currentKind)
      );

      uncheckRoles([listRoles], form.setValue);
      setKindSelectedCheck(currentKind);
    }
  };
  const handleValidateUser = async (_event: object, value: string) => {
    if (isEmpty(value)) return;
    const validate = await dispatch(validateUsername(value));

    if (has(validate, "error")) {
      form.setError("userName", {
        message: ErrorMessageEnum.UNAVAILABLE_USER,
        type: "validate",
      });
    }
  };
  const handleValidateEmail = (value: string) => {
    let selected_admin = false;

    keys(valuesRoles).forEach((item) => {
      if (item === RolesId.BackofficeAdmin && !!valuesRoles[item]) {
        selected_admin = true;
      }
    });

    return selected_admin && !value.includes("@kushkipagos.com")
      ? ErrorMessageEnum.EMAIL_DOMAIN
      : true;
  };
  const activeUser = (): IActivateUser => {
    let path_redirect: IRedirect = {
      path: -1,
      type: "inside",
    };

    if (consoleQuery === NEW_CONSOLE) {
      path_redirect = {
        path: `/merchant-resume?${QueryParamEnum.PUBLIC_MERCHANT_ID}=${publicMerchantId}`,
        type: "outside",
      };
    }
    if (consoleQuery === NEW_CONSOLE && isCreateForm) {
      const branchNode: MerchantNodeData = findMerchantNode(
        get(merchantsInfo, "data", []),
        publicMerchantId
      );
      const customerNode: MerchantNodeData = findMerchantNode(
        get(merchantsInfo, "data", []),
        get(firstParent, "merchantId", "")
      );
      const validMerchantsConfigs: string[] = addMerchantIdByConfigsRequired(
        branchNode,
        customerNode
      );
      const validMerchantsToActive: string[] = validateStatusMerchant(
        validMerchantsConfigs,
        get(merchantsInfo, "data", [])
      );
      const isCustomer: boolean =
        defaultTo(
          get(merchantsInfo, "data", []).find(
            (item) => item.public_merchant_id === publicMerchantId
          ),
          { entity_name: "" }
        ).entity_name === EntityEnum.CUSTOMER;

      const activateMerchantRequest: IActivateNodeMerchant = {
        config: {
          configs: [
            {
              configuration: ConfigTypeEnum.cn019,
              status: StatusConfigEnum.COMPLETE,
              value: get(branchNode, "merchant_id", ""),
            },
          ],
          nodeId: get(branchNode, "node_id", ""),
        },
        status: {
          isFirstActive: true,
          publicMerchantId: validMerchantsToActive,
          status: StatusNodeEnum.ACTIVE,
        },
      };

      let isMerchantPending: boolean | null = null;

      each(get(merchantsInfo, "data", []), (merchant: MerchantNodeData) => {
        isMerchantPending =
          isMerchantPending ||
          merchant.merchant_status === StatusNodeEnum.PENDING;
      });

      return {
        activatedNode: Boolean(
          validMerchantsToActive.length > 0 && isMerchantPending
        ),
        activateMerchantRequest,
        isCustomer: isCustomer,
        redirectPath: path_redirect,
      };
    }

    return {
      activatedNode: false,
      activateMerchantRequest: {} as IActivateNodeMerchant,
      redirectPath: path_redirect,
    };
  };
  const onSubmit = (data: IUserForm) => {
    const request_user = {
      email: data.email,
      familyName: data.familyName,
      group: keys(data.group).filter((item) => !!data.group[item]),
      name: data.name,
      publicMerchantId: data.publicMerchantId,
      userName: data.userName,
    };

    dispatch(setStateLoading({ isLoading: true, status: StatusTypeEnum.FAIL }));
    if (isCreateForm) {
      dispatch(
        createUser({
          activateUser: {
            ...activeUser(),
            isConsole3: consoleQuery === NEW_CONSOLE,
          },
          userForm: request_user,
        })
      );

      return;
    }
    dispatch(editUser({ userForm: request_user }));
  };

  useEffect(() => {
    setBreadcrumbState({
      ...breadcrumbState,
      lastItemBreadcrumb: labelForm,
    });

    if (!is_admin && !isOwner)
      form.setValue("kindRoles", RoleNameEnum.MERCHANT_BACKOFFICE);

    if (!isCreateForm && isEqual(userStore, INITIAL_USER_STORE)) {
      dispatch(
        findUser({
          limit: "10",
          username: defaultTo(userName, ""),
        })
      );
    }
  }, [isCreateForm]);

  useEffect(() => {
    if (!isEmpty(merchantsInfo)) {
      const branch: MerchantNodeData = findMerchantNode(
        get(merchantsInfo, "data", []),
        publicMerchantId
      );

      const branchConfig = find(
        get(branch, "configs", []),
        (c) => c.configuration === ConfigTypeEnum.cn019
      );

      const dateIso = formatISO(
        new Date(get(branchConfig, "updatedAt", new Date())),
        {
          format: "extended",
        }
      );
      const dateFormatted = format(new Date(dateIso), "dd/MM/yyyy '-' HH:mm");

      setFooterLabels({
        date: dateFormatted,
        modifyName: get(branch, "updated_by", ""),
      });
    }
  }, [merchantsInfo]);

  useEffect(() => {
    if (viewMerchantSelect)
      dispatch(
        getMerchants({
          offset: 0,
          text: publicMerchantId,
        })
      );
    if (userStore && !isEmpty(get(userStore, "userName", ""))) {
      const roles_user_store = {};
      let kind_role_user = "";

      if (userStore.group.length > 0) {
        const first_role: string = userStore.group[0];
        const find_role_kind = ROLES.find((item) => item.id === first_role);

        kind_role_user = get(find_role_kind, "kind", "");
      }

      userStore.group.forEach((item) => {
        set(roles_user_store, item, true);
      });
      form.reset({
        email: userStore.email,
        familyName: userStore.familyName,
        group: roles_user_store,
        kindRoles: kind_role_user,
        name: userStore.name,
        publicMerchantId: userStore.publicMerchantId,
        userName: userStore.userName,
      });
    }
  }, [userStore]);

  useEffect(() => {
    if (!isEmpty(publicMerchantId) && consoleQuery === NEW_CONSOLE) {
      dispatch(getFirstParent(publicMerchantId));
    }

    return function () {
      form.reset(INITIAL_USER_FORM);
      dispatch(setUser(INITIAL_USER_STORE));
      dispatch(setValidateUserName(true));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(firstParent) && consoleQuery === NEW_CONSOLE) {
      const searchRequest: SearchMerchantNodeRequest = {
        configs: [...requiredConfigs, ConfigTypeEnum.cn019],
        limit: 2,
        offset: 0,
        publicMerchantId: [publicMerchantId],
      };

      if (!isOwner) {
        searchRequest.publicMerchantId.push(get(firstParent, "merchantId", ""));
      }

      dispatch(getSearchMerchantsInfo(searchRequest));
    }
  }, [firstParent]);

  useEffect(() => {
    let is_valid: boolean =
      (merchantName && watch_kind_role === RoleNameEnum.MERCHANT_BACKOFFICE) ||
      !merchantName;

    if (watch_kind_role === RoleNameEnum.SNR) {
      is_valid = is_valid && (is_snr_admin || is_admin);
    }
    if (isEmpty(watch_kind_role)) {
      is_valid = false;
    }
    setViewMerchantSelect(
      (watch_kind_role == RoleNameEnum.MERCHANT_BACKOFFICE && is_admin) ||
        !!merchantName
    );
    setViewCheckboxRoles(is_valid);
  }, [watch_kind_role]);

  useEffect(() => {
    if (!!merchantName) {
      form.setValue("kindRoles", RoleNameEnum.MERCHANT_BACKOFFICE);
    }
  }, [merchantName]);

  useEffect(() => {
    if (!isEmpty(watchEmail)) form.trigger("email").then();
  }, [watchGroupStringified]);

  useEffect(() => {
    form.register("group", {
      validate: (value) => {
        return (
          Object.values(value || {}).some((checkboxValue) => checkboxValue) ||
          ErrorMessageEnum.REQUIRED_ROLE
        );
      },
    });
  }, [form.register]);

  return {
    breadcrumbState,
    footerLabels,
    form,
    handleBreadcrumbItem,
    handleChangeMerchant,
    handleChangeMerchants,
    handleChangeRoles,
    handleOk,
    handleOpenCloseModal,
    handleReturn,
    handleValidateEmail,
    handleValidateUser,
    isAdmin: is_admin,
    isChargeBackManagerSelected,
    isConsole3: consoleQuery === NEW_CONSOLE,
    isCreateForm,
    isCustomerSelected,
    isLoadingCreateEditUser,
    isLoadingValidateUsername,
    isOwner,
    isValidUserName,
    kindRoles,
    merchantName,
    merchants,
    onSubmit,
    openModal,
    rolesNoAdmin: ROLES_NO_ADMIN,
    valuesRoles,
    viewCheckboxRoles,
    viewMerchantSelect,
  };
};
