import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect, useState } from "react";
import { IUseWalletDashboard } from "../../../shared/infrastructure/interfaces/useWalletDashboard.interface";
import { FilterWalletDashboardRequest } from "../../../../types/filter_wallet_dashboard_request";
import {
  getTransactions,
  setFilterWalletDashboardRequest,
  setShowAlertOnGetTransactions,
} from "../../../store/actionCreators";
import { format, startOfMonth } from "date-fns";
import { FilterSortEnum } from "../../../shared/infrastructure/enums/FilterEnum";
import { Data } from "../../../../types/filter_wallet_dashboard_response";

export interface IState {
  transactionSelected: Data;
  openModal: boolean;
}

export const useWalletDashboard = (): IUseWalletDashboard => {
  const dispatch = useDispatch();
  const showAlertOnGetTransactionsError: boolean | undefined = useSelector(
    (state: IAppState) => state.showAlertOnGetTransactions
  );

  const [state, setState] = useState<IState>({
    openModal: false,
    transactionSelected: {
      balance: 9940293883432,
      created: "",
      currency: "",
      description: "",
      kushki_fee: 0,
      merchant_id: "",
      merchant_name: "",
      origin: "",
      origin_ticket_number: "",
      origin_transaction_reference: "",
      payment_method: "",
      total_amount: 300,
      transaction_id: "",
      transaction_status: "",
      transaction_type: "",
    },
  });

  const handleTransactionSelected = (transactionSelected: Data): void => {
    setState({
      ...state,
      openModal: true,
      transactionSelected: transactionSelected,
    });
  };

  const handleCloseModal = (): void => {
    setState({
      ...state,
      openModal: false,
    });
  };

  useEffect(() => {
    const startDate: string = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const endDate: string = format(new Date(), "yyyy-MM-dd");
    const publicMerchantId: string =
      new URLSearchParams(location.search).get("publicMerchantId") || "";

    const initialFilterTransactionsRequest: FilterWalletDashboardRequest = {
      from: `${startDate}T00:00:00`,
      limit: 10,
      offset: 0,
      sort: {
        field: FilterSortEnum.CREATED,
        order: FilterSortEnum.DESC,
      },
      to: `${endDate}T23:59:59`,
    };

    dispatch(setFilterWalletDashboardRequest(initialFilterTransactionsRequest));
    dispatch(
      getTransactions(initialFilterTransactionsRequest, publicMerchantId)
    );
  }, []);

  useEffect(() => {
    if (showAlertOnGetTransactionsError) {
      setTimeout(() => {
        dispatch(setShowAlertOnGetTransactions(false));
      }, 1500);
    }
  }, [showAlertOnGetTransactionsError]);

  return {
    handlers: {
      handleCloseModal,
      handleTransactionSelected,
    },
    showAlertOnGetTransactionsError,
    state,
  };
};
