import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const selectedNodesStyles = createNamedStyles({
  container: {
    width: "fit-content",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid #E2E8F0",
    backgroundColor: "#FBFCFE",
    borderRadius: "8px",
    padding: "12px 16px",
    gap: "8px",
    margin: "22px 0px",
  },
  entityName: {
    fontSize: {
      xs: "13px",
    },
    fontWeight: "bold",
    color: "#6D7781",
    textDecorationLine: "underline",
  },
  label: {
    fontSize: {
      xs: "13px",
    },
    color: "#6D7781",
  },
  wrapper: {
    display: "flex",
    gap: "5px",
    cursor: "pointer",
  },
  crossIcon: {
    width: "24px",
    height: "24px",
    padding: "0px",
  },
});
