import { createNamedStyles } from "../../../shared/utils";

export const searchStyles = createNamedStyles({
  input: {
    border: 1,
    borderStyle: "solid",
    borderColor: "neutral.grey5",
    bgcolor: "background.default",
    height: "36px",
    px: 2,
  },
});
