import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const dashboardTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    helperText: {
      alignSelf: "end",
      marginRight: 0,
    },
    ksh_multiline: {
      "& > label+div": {
        height: "auto",
      },
    },
    root: { background: "transparent", boxShadow: "none" },
    tabStyle: {
      textTransform: "none",
    },
    td: {
      paddingBottom: 5,
      paddingTop: 5,
      width: 225,
    },
    textField: {
      paddingBottom: 5,
      paddingTop: 13,
    },
    typography: {
      fontWeight: 200,
    },
  })
);
