import React, { useEffect, useState } from "react";
import { IUseModalDetailInfo } from "./useModalDetailInfo.interfaces";
import { Button, IModalDetailInfo } from "../ModalDetailInfo.interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { TransactionInfo } from "../../../../types/transactions_data";
import { buildTimeLine } from "../../../shared/utils/card_tabs_utils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { TransactionTypeEnum } from "../../../shared/enums/TransactionTypeEnum";
import { resetOriginalTrx } from "../../../store/actions/app.actions";
import {
  modalHeaderButtons,
  modalTabs,
} from "../../../shared/constants/ModalConstants";
import { ITabInfo } from "../../GenericTabs/GenericTabs.interfaces";
import { validateVoidTrx } from "../../../shared/utils/dashboard_transaction_utils";

export const useModalDetailInfo = (
  props: IModalDetailInfo
): IUseModalDetailInfo => {
  const { transactionFatherData, originalTrx, isLoadingOriginalTrx } =
    useAppSelector((state) => ({
      ...state.app,
    }));

  const dispatchApp = useAppDispatch();
  const transaction: TransactionInfo = get(props.transactionInfo, "_source")!;
  const [currentTransaction, setCurrentTransaction] = useState<TransactionInfo>(
    { ticket_number: "" }
  );
  const [availableSectionSummary, setAvailableSectionSummary] =
    React.useState<boolean>(false);
  const [transactionTimeLine, setTransactionTimeLine] =
    React.useState<TransactionInfo>({});
  const [buttonsRow, setButtonsRow] = React.useState<Button[]>(
    modalHeaderButtons(
      props.modalButtonActions,
      get(props.transactionInfo, "_source")!,
      false,
      false
    )
  );
  const [tabsInfo, setTabInfo] = React.useState<ITabInfo[]>([]);

  const handleClose = (): void => {
    props.modal.setIsOpenModalDetailInfo(false);
    setTransactionTimeLine({});
    dispatchApp(resetOriginalTrx());
  };

  useEffect(() => {
    if (!props.modal.isOpenModalDetailInfo) {
      setCurrentTransaction(transaction);
      setAvailableSectionSummary(false);
      setButtonsRow(
        modalHeaderButtons(
          props.modalButtonActions,
          get(props.transactionInfo, "_source")!,
          false,
          false
        )
      );
      setTabInfo(
        modalTabs(
          props,
          availableSectionSummary,
          buildTimeLine(transaction),
          transaction,
          transaction
        )
      );
    }
  }, [props.modal.isOpenModalDetailInfo]);

  useEffect(() => {
    if (!isEmpty(defaultTo(transaction, undefined))) {
      setCurrentTransaction(transaction);
      setButtonsRow(
        modalHeaderButtons(props.modalButtonActions, transaction, false, false)
      );
      setTabInfo(
        modalTabs(
          props,
          availableSectionSummary,
          buildTimeLine(transaction),
          transaction,
          transaction
        )
      );
    }
  }, [props.transactionInfo]);

  useEffect(() => {
    if (transactionFatherData.data!.length > 0) {
      const getTransactionFatherData: TransactionInfo = validateVoidTrx(
        get(transactionFatherData, "data[0]._source"),
        []
      );

      const transaction_type: string = get(
        getTransactionFatherData,
        "transaction_type",
        ""
      );

      setTransactionTimeLine(getTransactionFatherData);

      setAvailableSectionSummary(
        transaction_type === TransactionTypeEnum.VOID ||
          transaction_type === TransactionTypeEnum.VOID_PARTIAL ||
          transaction_type === TransactionTypeEnum.REFUND_TOTAL ||
          transaction_type === TransactionTypeEnum.REFUND_PARTIAL ||
          transaction_type === TransactionTypeEnum.REVERSE
      );
      setButtonsRow(
        modalHeaderButtons(
          props.modalButtonActions,
          getTransactionFatherData,
          false,
          false
        )
      );
      setTabInfo(
        modalTabs(
          props,
          availableSectionSummary,
          buildTimeLine(getTransactionFatherData),
          getTransactionFatherData,
          getTransactionFatherData
        )
      );
      setCurrentTransaction(getTransactionFatherData);
    }
  }, [transactionFatherData]);

  useEffect(() => {
    if (originalTrx.data!.length > 0) {
      const trx: TransactionInfo = validateVoidTrx(
        get(originalTrx, "data[0]._source"),
        []
      );

      setTransactionTimeLine({});
      setCurrentTransaction(trx);
      setAvailableSectionSummary(false);
      setButtonsRow(
        modalHeaderButtons(props.modalButtonActions, trx, false, false)
      );
      setTabInfo(modalTabs(props, false, buildTimeLine(trx), trx, trx));
    }
  }, [originalTrx]);

  useEffect(() => {
    if (isLoadingOriginalTrx) {
      setButtonsRow(
        modalHeaderButtons(
          props.modalButtonActions,
          currentTransaction,
          isLoadingOriginalTrx,
          isLoadingOriginalTrx
        )
      );
    }
  }, [isLoadingOriginalTrx]);

  return {
    buttonsRow,
    handleClose,
    tabsInfo,
    transactionInfo: currentTransaction,
    transactionTimeLine,
  };
};
