import React from "react";
import { BusinessRulesSection } from "../../components/BusinessRulesSection/BusinessRulesSection";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { useEditBusinessRulesState } from "./state/useEditBusinessRulesState";
import {
  getMerchantInfo,
  getRulesListInfo,
  INotification,
  LabelField,
  RulesNotificationResultEnum,
  TitleSection,
} from "../../shared/infrastructure/constants/RulesConstants";
import {
  createBusinessRule,
  getBusinessRule,
  getMerchantCredential,
  getProcessors,
  IRulesState,
  RulesAction,
  setIsLoading,
  setIsRequest,
  setNotification,
  setRequestError,
  updateBusinessRule,
  setShowFinalModal,
} from "../../store/actionCreator";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";
import { get, defaultTo } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FormProvider } from "react-hook-form";
import { Category } from "../../../types/category";
import { BusinessRulesResponse } from "../../../types/business_rules_response";
import { SecurityRule } from "../../../types/security_rule";
import { RulesDialog } from "../../components/Dialog/RulesDialog";
import { LoadingDialog } from "../../components/Dialog/LoadingDialog";
import { MerchantInfo } from "../../../types/merchant_info";
import { ProcessorsInformation } from "../../../types/processors_list";
export interface BusinessRulesEditStateProps {
  isLoading: boolean | undefined;
  showFinalLoading: boolean | undefined;
  isRequest: boolean | undefined;
  requestError: boolean | undefined;
  notification: INotification | undefined;
  processors: ProcessorsInformation[] | undefined;
  credentials: Category[] | undefined;
  businessRule: BusinessRulesResponse | undefined;
  showFinalModal: boolean | undefined;
}

export interface BusinessRulesEditFunctionsProps {
  setIsLoading: (payload: boolean) => RulesAction;
  setIsRequest: (payload: boolean) => RulesAction;
  setRequestError: (payload: boolean) => RulesAction;
  setNotification: (payload: INotification) => RulesAction;
  getProcessors: (merchantId: string, text?: string) => void;
  getCredentials: (merchantId: string) => void;
  getBusinessRule: (ruleId: string) => void;
  createBusinessRule: (
    payload: SecurityRule,
    merchantInfo: MerchantInfo,
    redirectPath: string
  ) => void;
  updateBusinessRule: (
    ruleId: string,
    payload: SecurityRule,
    merchantId: string
  ) => void;
  setShowFinalModal: (payload: boolean) => RulesAction;
}

export type BusinessRulesEditProps = BusinessRulesEditStateProps &
  BusinessRulesEditFunctionsProps;

export const BusinessRulesEdit: React.FC<BusinessRulesEditProps> = (
  props: BusinessRulesEditProps
) => {
  const { form, merchantInfo, actions } = useEditBusinessRulesState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb
          items={defaultTo(
            get(
              getMerchantInfo(
                merchantInfo.publicMerchantId,
                merchantInfo.name,
                merchantInfo.nextStep
              )[`${merchantInfo.origin}`],
              "breadcrumb"
            ),
            getRulesListInfo(merchantInfo.publicMerchantId, merchantInfo.name)
              .breadcrumb
          )}
          lastItem={TitleSection.ADD_BUSINESS_RULES}
        />
        <Box pt={2} pb={3}>
          <Typography variant={"h1"}>
            {TitleSection.ADD_BUSINESS_RULES}
          </Typography>
        </Box>
        <FormProvider {...form}>
          <Grid container spacing={4}>
            <BusinessRulesSection
              isLoading={props.isLoading!}
              isRequest={props.isRequest!}
              processors={get(props, "processors", [])}
              credentials={get(props, "credentials", [])}
              handleAddRule={actions.handleAddRule}
              handleRemoveRule={actions.handleRemoveRule}
              country={merchantInfo.country}
              handleEnterSearchTextProcessors={
                actions.handleEnterSearchTextProcessors
              }
              handleSetSearchTextProcessors={
                actions.handleSetSearchTextProcessors
              }
            />
          </Grid>
          <ActionButtons
            onCancel={actions.handleCancelAction}
            onSave={form.handleSubmit(actions.handleSubmitForm)}
            disabled={props.isLoading!}
            mainActionText={LabelField.SAVE_RULE}
            secondaryActionText={LabelField.RETURN}
            withIcon={false}
          />
        </FormProvider>

        {get(props, "notification.action") !==
          RulesNotificationResultEnum.NO_ACTION && (
          <SnackbarAlert
            type={get(props, "notification.type")}
            msg={get(props, "notification.message")}
            open={get(props, "notification.open")}
            handlerClose={actions.handleCloseNotification}
          />
        )}
      </Container>
      <LoadingDialog open={props.showFinalLoading!} />
      <RulesDialog
        open={props.showFinalModal!}
        redirectObject={defaultTo(
          getMerchantInfo(
            merchantInfo.publicMerchantId,
            merchantInfo.name,
            merchantInfo.nextStep
          )[`${merchantInfo.origin}`],
          getRulesListInfo(merchantInfo.publicMerchantId, merchantInfo.name)
        )}
        onClose={actions.handleCloseFinalModal}
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IRulesState
) => BusinessRulesEditStateProps = (
  state: IRulesState
): BusinessRulesEditStateProps => ({
  isLoading: state.isLoading,
  showFinalLoading: state.showFinalLoading,
  isRequest: state.isRequest,
  requestError: state.requestError,
  notification: state.notification,
  processors: state.processorsMerchant,
  credentials: state.credentials,
  businessRule: state.businessRule,
  showFinalModal: state.showFinalModal,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => BusinessRulesEditFunctionsProps = (
  dispatch: ThunkDispatch<IRulesState, undefined, RulesAction>
): BusinessRulesEditFunctionsProps => ({
  setIsLoading: (payload: boolean): RulesAction =>
    dispatch(setIsLoading(payload)),
  setIsRequest: (payload: boolean): RulesAction =>
    dispatch(setIsRequest(payload)),
  setRequestError: (payload: boolean): RulesAction =>
    dispatch(setRequestError(payload)),
  setNotification: (payload: INotification): RulesAction =>
    dispatch(setNotification(payload)),
  getProcessors: (merchantId: string, text?: string): void =>
    dispatch(getProcessors(merchantId, text)),
  getCredentials: (merchantId: string): void =>
    dispatch(getMerchantCredential(merchantId)),
  getBusinessRule: (ruleId: string): void => dispatch(getBusinessRule(ruleId)),
  updateBusinessRule: (
    ruleId: string,
    payload: SecurityRule,
    merchantId: string
  ): void => dispatch(updateBusinessRule(ruleId, payload, merchantId)),
  createBusinessRule: (
    payload: SecurityRule,
    merchantInfo: MerchantInfo,
    redirectPath: string
  ): void => dispatch(createBusinessRule(payload, merchantInfo, redirectPath)),
  setShowFinalModal: (payload: boolean): RulesAction =>
    dispatch(setShowFinalModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessRulesEdit);
