import React, { FC } from "react";

import { validateRouteRender } from "../../shared/utils/routeRenderUtils";
import { Container, Grid } from "@mui/material";
import { transferInternationalProcessorContainerStyles } from "./InternationalContainer.styles";
import InternationalTransferComponent from "../../components/InternationalTransferComponent/InternationalTransferComponent";

const InternationalContainer: FC = () => {
  const renderCondition: boolean = validateRouteRender();

  return (
    <>
      {renderCondition && (
        <React.Fragment>
          <Grid
            sx={
              transferInternationalProcessorContainerStyles.principalContainer
            }
          >
            <Container fixed>
              <InternationalTransferComponent />
            </Container>
          </Grid>
        </React.Fragment>
      )}
    </>
  );
};

export default InternationalContainer;
