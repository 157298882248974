import { ItemGetCredentialsResponse } from "../../../types/get_credentials_response";
import { MerchantNodeData } from "../../../types/search_merchant_response";
import { get } from "lodash";

export const getBranchName = (
  credentials: ItemGetCredentialsResponse,
  branches: MerchantNodeData[]
): string => {
  const matchBranch: MerchantNodeData | undefined = branches.find(
    (b: MerchantNodeData) =>
      b.merchant_id === get(credentials, "_source.merchantId", "")
  );

  return get(matchBranch, "name", "");
};
