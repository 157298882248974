import { Box, Collapse, IconButton, Typography } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import ArrowDownIcon from "../../../../assets/icons/ArrowDownIcon";
import { ExpandableProps } from "./Expandable.interfaces";
import { buttonStyle, expandableStyles as styles } from "./Expandable.styles";

const Expandable: FC<ExpandableProps> = ({
  children,
  expanded = false,
  onStateChange = () => undefined,
  sx,
  title = "",
  ...rest
}: PropsWithChildren<ExpandableProps>) => {
  const containerStyle = { ...styles.container, ...sx };
  const titleStyle = { ...styles.title, ...styles.colorPrimary };

  return (
    <Box sx={containerStyle} {...rest}>
      <Box
        sx={styles.header}
        onClick={() => onStateChange(!expanded)}
        role="button"
        aria-label={title}
      >
        <Typography variant="subtitle2" sx={titleStyle}>
          {title}
        </Typography>

        <IconButton
          aria-label={`expand ${title}`}
          aria-expanded={expanded}
          sx={buttonStyle(expanded)}
        >
          <ArrowDownIcon sx={styles.colorPrimary} />
        </IconButton>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
};

export default Expandable;
