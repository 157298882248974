import React from "react";
import { ModalFooter } from "@kushki/frontend-molecules/modal-footer";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import { useHistory } from "react-router-dom";
import { PaymentMethodEnum } from "../../../shared/infrastructure/constants/PaymentMethodEnum";
import { get } from "lodash";

export interface IModalFooter {
  handleClick(processor: ProcessorFetch): void;
  processorInfo?: ProcessorFetch;
  isCountryIncluded?: boolean;
}

export const ModalFooterSection = (props: IModalFooter) => {
  const { processorInfo, handleClick, isCountryIncluded } = props;
  const history = useHistory();
  const handleOnFailOver = (): void => {
    handleClick?.(processorInfo!);
  };
  const handleOnEdit = (): void => {
    const routes: object = {
      [PaymentMethodEnum.CASH]: "processor-cash",
      [PaymentMethodEnum.PAYOUTS_CASH]: "processor-payouts-cash",
      [PaymentMethodEnum.TRANSFER]: "processor-transfer",
      [PaymentMethodEnum.PAYOUTS_TRANSFER]: "processor-payouts-transfer",
      [PaymentMethodEnum.CARD]: "processor-card",
      [PaymentMethodEnum.PAYOUTS_CARD]: "processor-payouts-card",
    };
    history.push(
      `/${routes[get(processorInfo, "paymentMethod", "card")]}/${
        processorInfo?.merchantId
      }/${processorInfo?.publicProcessorId}`
    );
  };
  const hasSecondaryLabel =
    get(processorInfo, "paymentMethod", "") === "card" && !isCountryIncluded
      ? "Asignar Fail Over"
      : "";

  return (
    <ModalFooter
      extraLabelButton={"Más Acciones"}
      extraLabelPrimary={"Editar procesador"}
      extraLabelSecondary={hasSecondaryLabel}
      extraActionPrimary={handleOnEdit}
      extraActionSecondary={handleOnFailOver}
    />
  );
};
