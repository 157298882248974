import React, { useEffect, useState } from "react";
import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ITabHeader } from "../../shared/infrastructure/constants/BankConciliationConstans";
import { useDispatch } from "react-redux";
import { indexTransactionSummaryTab } from "../../store/actionCreators";
import { useLocation } from "react-router-dom";
import { RoutesEnum } from "../../shared/infrastructure/RoutesEnum";

export interface DashboardTabsProps {
  tabsHeader: ITabHeader[];
  tabsContent: ITabContent[];
}

export interface ITabContent {
  index: number;
  children: React.ReactNode;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontWeight: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "75%",
      height: "10%",
    },
    root: { background: "transparent", boxShadow: "none" },
    tabStyle: {
      textTransform: "none",
    },
    tabs: {
      textTransform: "none",
      borderBottom: "1px solid #CBD5E0",
    },
    paper: {
      marginTop: "15px",
      padding: "0px",
    },
  })
);
export const DashboardTabs: React.FC<DashboardTabsProps> = (
  props: DashboardTabsProps
) => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const [value, setValue] = useState<number>(0);
  const [path, setPath] = useState<string>(location);
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
    dispatch(indexTransactionSummaryTab(newValue));
  };
  const classes = useStyles();
  useEffect(() => {
    setPath(location);
  }, []);
  useEffect(() => {
    if (location === RoutesEnum.SUMMARY && value === 0)
      dispatch(indexTransactionSummaryTab(0));
    if (location === RoutesEnum.TRANSACTIONS && value === 0)
      dispatch(indexTransactionSummaryTab(0));
  }, [path]);
  useEffect(() => {
    dispatch(indexTransactionSummaryTab(value));
  }, [value]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="static" className={classes.root}>
            <Tabs
              value={value}
              onChange={handleTabsChange}
              aria-label="billing tabs"
              variant="fullWidth"
              indicatorColor={"primary"}
              className={classes.tabs}
            >
              {props.tabsHeader.map((ItemTabHeader: ITabHeader) => (
                <Tab
                  key={`tabheader_${ItemTabHeader.index}`}
                  label={
                    <Typography variant={"h6"} color={"textPrimary"}>
                      {ItemTabHeader.label}
                    </Typography>
                  }
                  {...tabProps(ItemTabHeader.index)}
                  className={classes.tabStyle}
                />
              ))}
            </Tabs>
          </AppBar>
          {props.tabsContent.map((itemTabContent: ITabContent) => (
            <TabPanel
              key={`tabpanel_${itemTabContent.index}`}
              value={value}
              index={itemTabContent.index}
            >
              <Box className={classes.paper}>{itemTabContent.children}</Box>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
