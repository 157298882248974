import { IUseGenericTabs } from "./useGenericTabs.interfaces";
import React from "react";

export const useGenericTabs = (): IUseGenericTabs => {
  const [tabNumber, setTabNumber] = React.useState(0);
  const handleChange = (newTabNumber: number) => {
    setTabNumber(newTabNumber);
  };

  return {
    handleChange,
    tabNumber,
  };
};
