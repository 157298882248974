import { Box } from "@mui/material";
import SearchInput from "../common/SearchInput/SearchInput";
import DateTimeRangePicker from "../common/DateTimeRangePicker/DateTimeRangePicker";
import DateTimeButton from "../common/DateTimeButton/DateTimeButton";
import { useTableHeaderFilterState } from "./state/useTableHeaderFilterState";
import SearchIcon from "../../assets/icons/SearchIcon";
import { TableHeaderFilterStyles as styles } from "./TableHeaderFilter.styles";
import React from "react";

export const TableHeaderFilter: React.FC = () => {
  const {
    from,
    handlers: { onDatePickerChange, onSearchInputChange, onSearchInputClick },
    to,
  } = useTableHeaderFilterState();

  const containerStyle = { ...styles.container };
  const timeStyle = { ...styles.baseBtn, ...styles.btnTime };

  return (
    <Box sx={containerStyle}>
      <SearchInput
        inputProps={{ "data-testid": "text" }}
        placeholder={"Buscar..."}
        endAdornment={
          <SearchIcon
            sx={styles.iconSearch}
            onClick={(event) => {
              onSearchInputClick(event.timeStamp);
            }}
            cursor={"pointer"}
          />
        }
        onChange={(event) => {
          onSearchInputChange(event.target.value);
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onSearchInputClick(event.timeStamp);
          }
        }}
        sx={styles.input}
      />
      <DateTimeRangePicker
        disableFuture
        from={from}
        to={to}
        onChange={onDatePickerChange}
        renderComponent={(props) => (
          <DateTimeButton {...props} sx={timeStyle} />
        )}
      />
    </Box>
  );
};
