import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { IconAlertTriangle } from "@kushki/connect-ui";
import { RulesInformationData } from "../RulesInformation/RulesInformation.data";

export enum MessageAlertColor {
  backgroundColor = "#FFF1D0",
  textColor = "#CF711A",
}

export const MessageAlertRulesBatch: React.FC = () => {
  return (
    <Box
      sx={{
        boxShadow: 0.5,
        backgroundColor: MessageAlertColor.backgroundColor,
        borderRadius: 2,
      }}
      mb={4}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        pb={2}
      >
        <Grid item xs={1}>
          <IconAlertTriangle
            sx={{
              backgroundColor: MessageAlertColor.backgroundColor,
              color: MessageAlertColor.textColor,
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography color={MessageAlertColor.textColor}>
            {RulesInformationData.PendingRulesMesssage}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
