import { FilterLabelEnum } from "../FilterLabelEnum";
import { FilterTitleEnum } from "../FilterTitleEnum";
import { KeyStatusEnum } from "../KeyStatusEnum";
import { IdFilterEnum } from "../IdFilterEnum";
import { IOptionFilter } from "../../../components/FilterComponent/FilterComponent.interfaces";

export const DefaultTransactionalConciliationFilter: IOptionFilter[] = [
  {
    id: IdFilterEnum.transactionStatusKushki,
    options: [
      {
        key: KeyStatusEnum.APPROVAL_KUSHKI_STATUS,
        label: FilterLabelEnum.APPROVED,
        selected: false,
      },
      {
        key: KeyStatusEnum.DECLINED_KUSHKI_STATUS,
        label: FilterLabelEnum.DECLINED,
        selected: false,
      },
      {
        key: KeyStatusEnum.NOT_EXIST_KUSHKI_STATUS,
        label: FilterLabelEnum.NOT_EXIST,
        selected: false,
      },
    ],
    title: FilterTitleEnum.kushkiStatus,
  },
  {
    id: IdFilterEnum.transactionStatusProcessor,
    options: [
      {
        key: KeyStatusEnum.APPROVED_PROCESSOR_STATUS,
        label: FilterLabelEnum.APPROVED,
        selected: false,
      },
      {
        key: KeyStatusEnum.DECLINED_PROCESSOR_STATUS,
        label: FilterLabelEnum.DECLINED,
        selected: false,
      },
      {
        key: KeyStatusEnum.NOT_EXIST_PROCESSOR_STATUS,
        label: FilterLabelEnum.NOT_EXIST,
        selected: false,
      },
    ],
    title: FilterTitleEnum.processorStatus,
  },
  {
    id: IdFilterEnum.transactionType,
    options: [
      {
        key: KeyStatusEnum.SALE_TRX_TYPE,
        label: FilterLabelEnum.SALE,
        selected: false,
      },
      {
        key: KeyStatusEnum.REFUND_TRX_TYPE,
        label: FilterLabelEnum.REFUND,
        selected: false,
      },
      {
        key: KeyStatusEnum.VOID_TRX_TYPE,
        label: FilterLabelEnum.VOID,
        selected: false,
      },
    ],
    title: FilterTitleEnum.transactionType,
  },
  {
    id: IdFilterEnum.conciliationAction,
    options: [
      {
        key: KeyStatusEnum.CONCILIATED_STATUS,
        label: FilterLabelEnum.CONCILIATED,
        selected: false,
      },
      {
        key: KeyStatusEnum.OMITTED_STATUS,
        label: FilterLabelEnum.OMITTED,
        selected: false,
      },
      {
        key: KeyStatusEnum.REFUSED_REFUND_STATUS,
        label: FilterLabelEnum.REFUSED_REFUND,
        selected: false,
      },
      {
        key: KeyStatusEnum.PENDING_REFUND_STATUS,
        label: FilterLabelEnum.PENDING_REFUND,
        selected: false,
      },
      {
        key: KeyStatusEnum.APPROVED_REFUND_STATUS,
        label: FilterLabelEnum.APPROVED_REFUND,
        selected: false,
      },
    ],
    title: FilterTitleEnum.conciliationAction,
  },
  {
    id: IdFilterEnum.currencyCode,
    options: [
      {
        key: KeyStatusEnum.MXN_CURRENCY,
        label: FilterLabelEnum.MXN_CURRENCY,
        selected: false,
      },
    ],
    title: FilterTitleEnum.currencyCode,
  },
];
