import { IUserData } from "../../../components/TransactionList/state/IUseTransactionList.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { IUseChargebackContainer } from "./useChargebackContainer.interfaces";
import { useEffect, useMemo, useRef, useState } from "react";
import { get, isEmpty } from "lodash";
import {
  getMerchantInfo,
  getMerchantsRequest,
  getNodeInfo,
} from "../../../store/thunks/app/app.thunks";
import { ConfigEnum } from "../../../shared/enums/ConfigEnum";
import { searchMerchants } from "../../../store/thunks/merchantNode/merchantNode.thunks";
import { setSelectItems } from "../../../store/actions/merchantNode.actions";
import { ListButtons } from "../../../store/reducers/merchantNode/merchantNode.slice";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { isCustomerRol } from "../../../shared/utils/roles";
import { findConfigurationValue } from "../../../shared/utils/configuration_utils";
import {
  getColumnsUser,
  joinItemList,
} from "../../../shared/utils/lists_utils";
import { Chargeback } from "../../../../types/chargeback";
import {
  setNotificationStatus,
  setSearchChargebackRequest,
} from "../../../store/actions/chargeback.actions";
import { useSnackbar } from "@kushki/connect-ui";
import { IAllFilters } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheckChips/SelectCheckChips.interfaces";
import { selectChargeback } from "../../../store/selectors/selectors";
import { ItemCategoryItem } from "../../../components/Filters/ItemCategory/interfaces";
import {
  buildMerchantRequest,
  getEntityName,
} from "../../../store/utils/store-utils";
import { itemCategoryItems } from "../../../shared/utils/chips_selected_merchants";
import { SearchRetrieveChildRequest } from "../../../../types/search_retrive_child_request";
import { searchRetrieveChild } from "../../../store/thunks/chargeback/chargeback.thunks";
import { SearchChargebackRequest } from "../../../../types/search_chargeback_request";

export const useChargebackContainer = (): IUseChargebackContainer => {
  const dispatch = useAppDispatch();
  const {
    merchantInfo,
    nodeInfo,
    listButton,
    notificationStatus,
    merchants,
    loadingMerchants,
    retrieveChildResponse,
    isWaitingForRequest,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.merchantNode,
    ...state.chargeback,
    ...state.retriveChild,
  }));

  const { showSnackbar } = useSnackbar();
  const { searchChargebackRequest } = useAppSelector(selectChargeback);
  const publicMerchantId = localStorage.getItem("merchantId")!;
  const isRol: boolean = useMemo(() => isCustomerRol(), []);
  const isCustomer: boolean = useMemo(() => {
    return EntityNameEnum.CUSTOMER === nodeInfo.entityName;
  }, [nodeInfo]);
  const [selectedItems, setSelectedItems] = useState<ICategory[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [auxValue, setAuxValue] = useState<string>("new");
  const [merchantChips, setMerchantChips] =
    useState<ItemCategoryItem[]>(itemCategoryItems);
  const [merchantNameTimeOut, setMerchantNameTimeOut] = useState<any>(0);
  const [listItemsMerchants, setListItemsMerchants] = useState<
    ItemCategoryItem[]
  >([]);
  const [subTitle, setSubTitle] = useState<string>("");
  const firstUpdate = useRef(true);
  const [openModalInformation, setOpenModalInformation] =
    useState<boolean>(false);
  const [currentChargeback, setCurrentChargeback] = useState<Chargeback>();
  const listMerchants: ItemCategoryItem[] = [];
  const user_profile: IUserData = getColumnsUser();
  const setBranches = (data: ListButtons[] | ICategory[]) => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }
    dispatch(setSelectItems(data));
    if (data.length > 0) {
      setSubTitle(joinItemList(data, ", ", "."));
    }
  };

  function buildStringPublicMerchantId(filterItem: string[]): string {
    let value: string = "";

    filterItem.map((item, index: number) => {
      value = value.concat(item!);
      if (index + 1 < filterItem.length) {
        value = value.concat("|");
      }
    });

    return value;
  }

  const setMerchantNames = () => {
    let names_aux: ItemCategoryItem[] = [...selectedItems];

    merchants.data.forEach((item) => {
      names_aux.push({
        description: `${get(item, "publicMerchantId")} - ${getEntityName(
          get(item, "entityName", EntityNameEnum.MERCHANT)
        )}`,
        entityName: get(item, "entityName", EntityNameEnum.MERCHANT),
        label: get(item, "name"),
        selected: false,
        value: get(item, "publicMerchantId", ""),
      });
    });
    names_aux = names_aux.filter((names) => !names.selected);
    if (auxValue === "data" && names_aux.length === 0) {
      setAuxValue("empty");
    }
    if (auxValue === "new" && names_aux.length > 0) {
      setAuxValue("data");
    }
    setListItemsMerchants(names_aux);
  };

  function searchChargebackMerchants(filter: ItemCategoryItem[]) {
    if (filter.length > 0) {
      let retrieveChildRequestState: SearchRetrieveChildRequest = {
        Items: [{}],
      };

      retrieveChildRequestState.Items.splice(-1);

      filter.map((merchant) => {
        let request: object = {
          entityName: merchant.entityName,
          publicMerchantId: merchant.value,
        };

        retrieveChildRequestState.Items.push(request);
      });
      setSearchValue("");
      dispatch(
        searchRetrieveChild({
          isWating: isWaitingForRequest,
          request: retrieveChildRequestState,
          type: "retriveChild",
        })
      );
    }
  }

  const totalFilters = (e: IAllFilters) => {
    listMerchants.length = 0;
    e.itemsList.map((merchant: ItemCategoryItem) => {
      if (merchant.selected) {
        listMerchants.push(merchant);
      }
    });
    searchChargebackMerchants(listMerchants);
  };
  const onItemSelect = (selectedItem: ICategory[]) => {
    setSelectedItems(selectedItem);
    const names_aux: ItemCategoryItem[] = [...selectedItems];

    if (
      selectedItem.length === 0 &&
      merchantChips.length == 0 &&
      names_aux.length > 0
    ) {
      const request: SearchChargebackRequest = {
        ...searchChargebackRequest,
        filter: {
          ...searchChargebackRequest.filter,
        },
        limit: 10,
        offset: 0,
        publicMerchantId: "",
      };

      dispatch(setSearchChargebackRequest(request));
    }
  };
  const onChangeTextField = (value: string) => {
    setSearchValue(value);
    if (merchantNameTimeOut) clearTimeout(merchantNameTimeOut);

    setMerchantNameTimeOut(
      setTimeout(() => {
        if (!isEmpty(value) || value === "")
          dispatch(
            getMerchantsRequest(buildMerchantRequest(merchantChips, value))
          );
      }, 1500)
    );
  };
  const onChipChange = (chips: ItemCategoryItem[]) => {
    setMerchantChips(chips);
    dispatch(getMerchantsRequest(buildMerchantRequest(chips, searchValue)));
  };

  const handleCloseModalInformation = (): void => {
    setOpenModalInformation(false);
    setCurrentChargeback(undefined);
  };
  const handleSetCurrentChargeback = (current: Chargeback): void => {
    setCurrentChargeback(current);
    setOpenModalInformation(true);
  };

  useEffect(() => {
    if (!user_profile.admin)
      dispatch(
        getNodeInfo({
          publicMerchantId,
        })
      );
  }, []);

  useEffect(() => {
    let items: string[] = [];

    retrieveChildResponse.items.map((retriveChild) => {
      if (retriveChild.publicMerchantId !== undefined) {
        retriveChild.publicMerchantId.map((merchantId) => {
          items.push(merchantId);
        });
      }
    });

    const filterMerchants: string = buildStringPublicMerchantId(items);

    if (!isEmpty(filterMerchants)) {
      const request: SearchChargebackRequest = {
        ...searchChargebackRequest,
        filter: {
          ...searchChargebackRequest.filter,
        },
        limit: 10,
        offset: 0,
        publicMerchantId: filterMerchants,
      };

      dispatch(setSearchChargebackRequest(request));
    }
  }, [isWaitingForRequest]);

  useEffect(() => {
    if (!isEmpty(nodeInfo.generalInfo?.name)) {
      const configurations = nodeInfo.configs || [];
      const basicDataValue: string = findConfigurationValue(
        configurations,
        ConfigEnum.BASIC_DATA,
        publicMerchantId
      );

      dispatch(getMerchantInfo({ publicMerchantId: basicDataValue }));

      if (isCustomer) {
        dispatch(
          searchMerchants({
            filter: {
              entityName: [EntityNameEnum.BRANCH],
            },
            limit: 5000,
            offset: 1,
            sort: {
              field: "name",
              order: "asc",
            },
          })
        );
      }
    } else {
      setAuxValue("new");
      if (user_profile.admin) dispatch(getMerchantsRequest({}));
    }
  }, [nodeInfo]);

  useEffect(() => setMerchantNames(), [merchants]);

  useEffect(() => {
    if (auxValue === "empty") {
      setMerchantNameTimeOut(
        setTimeout(() => {
          setAuxValue("new");
          dispatch(getMerchantsRequest({}));
        }, 1500)
      );
    }
  }, [auxValue]);

  useEffect(() => {
    if (notificationStatus) {
      showSnackbar({
        ...notificationStatus,
      });
      dispatch(setNotificationStatus(undefined));
    }
  }, [notificationStatus]);

  return {
    currentChargeback,
    handleSetCurrentChargeback,
    isAdmin: user_profile.admin,
    isCustomer,
    isRol: isRol,
    listBranches: listButton.length > 0 ? listButton : [],
    listMerchants: listItemsMerchants,
    loadingMerchants,
    merchantInfo,
    modal: {
      actions: {
        handleCloseModalInformation,
      },
      openModalInformation,
    },
    onChangeTextField,
    onChipChange,
    onItemSelect,
    searchLabel: user_profile.searchLabel,
    setBranches,
    subTitle,
    totalFilters,
  };
};
