import { CountryFilterEnum } from "../../enums/CountryFilterEnum";

export enum FILTER_GROUPS {
  STATE = "Estado",
  CONSTITUTIONAL_COUNTRY = "País de Constitución",
  CLIENT_TYPE = "Tipo de cliente",
}
export enum STATE_LABEL_ITEM {
  ACTIVE = "Activo",
  PENDING = "Pendiente",
  INACTIVE = "Inactivo",
}

export const STATE_ITEM_VALUES: Record<STATE_LABEL_ITEM, string> = {
  [STATE_LABEL_ITEM.ACTIVE]: "active",
  [STATE_LABEL_ITEM.PENDING]: "pending",
  [STATE_LABEL_ITEM.INACTIVE]: "inactive",
};

export const OPERATIONAL_COUNTRY_LABELS: Record<CountryFilterEnum, string> = {
  [CountryFilterEnum.generic]: CountryFilterEnum.generic,
  [CountryFilterEnum.mexico]: "México",
  [CountryFilterEnum.peru]: "Perú",
  [CountryFilterEnum.chile]: "Chile",
  [CountryFilterEnum.colombia]: "Colombia",
  [CountryFilterEnum.el_salvador]: "El Salvador",
  [CountryFilterEnum.nicaragua]: "Nicaragua",
  [CountryFilterEnum.panama]: "Panamá",
  [CountryFilterEnum.ecuador]: "Ecuador",
  [CountryFilterEnum.costa_rica]: "Costa Rica",
  [CountryFilterEnum.guatemala]: "Guatemala",
  [CountryFilterEnum.honduras]: "Honduras",
  [CountryFilterEnum.usa]: "Estados Unidos",
  [CountryFilterEnum.brasil]: "Brasil",
};
