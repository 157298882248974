import { createAsyncThunk } from "@reduxjs/toolkit";
import { WEBSOCKETS_ENDPOINTS } from "../../../../shared/constants/websockets_endpoints";
import { downloadTransactionsWS } from "../../../../shared/utils/websockets/downloadTransaction/download_transactions_ws";
import { IHandleResponseWS } from "../../../../shared/utils/websockets/downloadTransaction/download_transactions_ws.interfaces";
import {
  IDownloadTransactions,
  IResponseDownloadWS,
} from "./download_transactions.interfaces";

export const handleResponse = (
  response: MessageEvent<string>
): IHandleResponseWS => {
  const { payload, status }: IResponseDownloadWS = JSON.parse(response.data);

  const messageError = payload.message ?? "Download failed";

  if (status === "COMPLETED_EMPTY" || payload.error) {
    return {
      closeConnection: true,
      messageError,
    };
  }

  if (status === "COMPLETED_SUCCESS") {
    if (!payload.url) {
      return {
        closeConnection: true,
        messageError,
      };
    }
    try {
      window.open(payload.url, "_blank", "noreferrer");
    } catch (e) {}

    return {
      closeConnection: true,
    };
  }

  return {
    closeConnection: false,
  };
};

const downloadTransactions = createAsyncThunk(
  "transactions/downloadTransactions",
  async ({ data, isSelectedTransactions }: IDownloadTransactions) => {
    return await downloadTransactionsWS(
      isSelectedTransactions
        ? WEBSOCKETS_ENDPOINTS.DOWNLOAD_SELECTED_TRANSACTIONS
        : WEBSOCKETS_ENDPOINTS.DOWNLOAD_TRANSACTIONS,
      {
        data,
        handleResponse,
      }
    );
  }
);

export default downloadTransactions;
