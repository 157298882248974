import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "40px !important",
    },
    paperTitle: {
      color: "#023365",
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
    },
  })
);

export interface IModalContainerInfo {
  label: string;
  value: string | React.ReactNode;
  xs: any;
  xs2?: any;
  title?: string;
}

export interface IPaperInfo {
  title?: string;
  values: IModalContainerInfo[];
  direction?: "row" | "col";
  insideTitle?: string;
  outsideTitle?: string;
}

const PaperInfo = ({
  values,
  title,
  direction,
  insideTitle,
  outsideTitle,
}: IPaperInfo) => {
  const classes = styles();

  return (
    <React.Fragment>
      <Typography className={classes.paperTitle}>{title}</Typography>
      <Paper className={classes.paper}>
        {outsideTitle && (
          <Grid item xs={12}>
            <Typography variant="h6">
              <Box fontWeight={600}>{outsideTitle}</Box>
            </Typography>
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          color="textSecondary"
          style={{
            marginTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          {insideTitle && (
            <Grid item xs={12}>
              <Typography variant={"h6"} color={"textPrimary"}>
                <Box fontWeight={500}>{insideTitle}</Box>
              </Typography>
            </Grid>
          )}
          {direction !== "row"
            ? values.map((col: IModalContainerInfo) => (
                <Grid item xs={col.xs} key={col.label.replace(/ /g, "_")}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ lineHeight: 1.9, fontSize: "13px" }}
                  >
                    {col.label}
                  </Typography>
                  {typeof col.value === "string" && (
                    <Typography
                      variant="body2"
                      style={{ fontSize: "15px", wordWrap: "break-word" }}
                    >
                      {col.value}
                    </Typography>
                  )}
                  {typeof col.value !== "string" && col.value}
                </Grid>
              ))
            : values.map((col: IModalContainerInfo) => (
                <React.Fragment key={col.label.replace(/ /g, "_")}>
                  <Grid item xs={col.xs}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: "15px" }}
                    >
                      {col.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={col.xs2}>
                    {typeof col.value === "string" && (
                      <Typography
                        variant="body2"
                        style={{ fontSize: "15px", wordWrap: "break-word" }}
                      >
                        {col.value}
                      </Typography>
                    )}
                    {typeof col.value !== "string" && col.value}
                  </Grid>
                </React.Fragment>
              ))}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default PaperInfo;
