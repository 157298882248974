import React from "react";
import { ISelectedCustomersProps } from "@components/SelectedCustomers/SelectedCustomers.interfaces";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { IconEdit } from "@kushki/connect-ui";
import { selectedCustomersStyles as styles } from "@components/SelectedCustomers/SelectedCustomers.styles";
import SelectedNodes from "@components/SelectedNodes/SelectedNodes";
import { alarmRuleUri } from "@shared/constants/alarmRuleUri";

const SelectedCustomers: React.FC<ISelectedCustomersProps> = ({
  subtitle,
  pathParam,
  buttonLabel,
  selectedNodes,
  isDisabled,
}: ISelectedCustomersProps) => {
  const LinkContent = (): JSX.Element => (
    <Box sx={styles.editWrapper}>
      <IconEdit sx={{ color: "primary.light2" }} />
      <Typography sx={styles.editButton}>{buttonLabel}</Typography>
    </Box>
  );

  return (
    <Box sx={styles.selectCustomersWrapper}>
      <Box sx={styles.selectedCustomersTitle}>
        <Typography variant={"subtitle2"}>{subtitle}</Typography>
        {isDisabled ? (
          <LinkContent />
        ) : (
          <Link to={alarmRuleUri(pathParam).linkButton}>
            <LinkContent />
          </Link>
        )}
      </Box>
      <SelectedNodes selectedNodes={selectedNodes} showClearButton={false} />
    </Box>
  );
};

export default SelectedCustomers;
