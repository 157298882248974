import React, { FC } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { FILE_STATUS_TEXT_ENUM } from "../../../../../shared/enums/file_status_download_enum";
import { TRANSACTION_RESULT_TEXT_ENUM } from "../../../../../shared/enums/transaction_result_enum";
import { ITransactionTab } from "../../../../../shared/interfaces/TransactionModal.interfaces";
import { getMonthsLabel } from "../../../../../shared/utils";
import { InformationTabStyles } from "./InformationTab.styles";
import { isEmpty } from "lodash";
import CardIcon from "../../../../common/CardIcon/CardIcon";
import {
  CardMethod,
  TransferMethod,
} from "../../../../../assets/icons/PaymentMethods";
import InputResendCertificate from "../../../../InputResendCertificate/InputResendCertificate";
import { TRANSACTION_STATUS_ENUM_LOOKUP } from "../../../../../shared/types";

const InformationTab: FC<ITransactionTab> = (props: ITransactionTab) => {
  const { transaction } = props;

  const handleCopyEmail = async () => {
    return await navigator.clipboard.writeText(transaction.email);
  };

  return (
    <Box>
      <Grid container sx={InformationTabStyles.informationCard}>
        <Box sx={InformationTabStyles.informationContainer}>
          <Grid item container xs={12} pb={2}>
            <Grid item xs={8}>
              <Typography sx={InformationTabStyles.text}>Cliente:</Typography>
              <Typography sx={InformationTabStyles.value}>
                {isEmpty(transaction.name.trim())
                  ? transaction.socialReason
                  : transaction.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={InformationTabStyles.text}>
                CC/RUC/Pasaporte:
              </Typography>
              <Typography sx={InformationTabStyles.value}>
                {transaction.document_number}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} pb={2}>
            <Grid item xs={8}>
              <Typography sx={InformationTabStyles.text}>E-mail:</Typography>
              <Grid container direction="row">
                <Grid item xs={10}>
                  <Typography sx={InformationTabStyles.value}>
                    {transaction.email}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    sx={InformationTabStyles.copyButton}
                    aria-label="copyBtn"
                    onClick={handleCopyEmail}
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={InformationTabStyles.text}>
                N° de teléfono:
              </Typography>
              <Typography sx={InformationTabStyles.value}>
                {transaction.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
          <InputResendCertificate
            status={TRANSACTION_STATUS_ENUM_LOOKUP[transaction.status]}
            transaction={transaction}
            transactionId={transaction.id}
          />
        </Box>
      </Grid>

      {transaction.paymentMethod === "card" ? (
        <>
          <Grid container sx={InformationTabStyles.informationCard}>
            <Box sx={InformationTabStyles.informationContainer}>
              <Grid item container xs={12} pb={2}>
                <Grid item xs={12}>
                  <Typography sx={InformationTabStyles.text}>
                    N° de placa:
                  </Typography>
                  <Typography sx={InformationTabStyles.value}>
                    {transaction.vehiclePlate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} pb={2}>
                <Grid item xs={4}>
                  <Typography sx={InformationTabStyles.text}>
                    Medio de pago:
                  </Typography>
                  <Typography sx={InformationTabStyles.value}>
                    <CardMethod />
                    Tarjeta
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={InformationTabStyles.text}>
                    No. de Aprobación:
                  </Typography>
                  <Typography sx={InformationTabStyles.value}>
                    {transaction.approvalNumber}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={InformationTabStyles.text}>
                    Tarjeta:
                  </Typography>
                  <Typography sx={InformationTabStyles.valueCard}>
                    <CardIcon cardType={transaction.cardType} />
                    {transaction.cardNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} pb={2}>
                <Grid item xs={4}>
                  <Typography sx={InformationTabStyles.text}>
                    Cuotas:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={InformationTabStyles.value}>
                    {transaction.months} {getMonthsLabel(transaction.months)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={InformationTabStyles.text}>Total:</Typography>
                <Typography sx={InformationTabStyles.value}>
                  {transaction.transactionAmount}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </>
      ) : (
        <Grid container sx={InformationTabStyles.informationCard}>
          <Box sx={InformationTabStyles.informationContainer}>
            <Grid item container xs={12} pb={2}>
              <Grid item xs={4}>
                <Typography sx={InformationTabStyles.text}>
                  Medio de pago:
                </Typography>
                <Typography sx={InformationTabStyles.value}>
                  <TransferMethod />
                  Transferencia
                </Typography>
              </Grid>
              <Grid item xs={4} pb={2}>
                <Typography sx={InformationTabStyles.text}>
                  N° de placa:
                </Typography>
                <Typography sx={InformationTabStyles.value}>
                  {transaction.vehiclePlate}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} pb={2}>
              <Grid item xs={6}>
                <Typography sx={InformationTabStyles.text}>
                  ID del Banco:
                </Typography>
                <Typography sx={InformationTabStyles.value}>
                  {transaction.bankID}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={InformationTabStyles.text}>CUS:</Typography>
                <Typography sx={InformationTabStyles.value}>
                  {transaction.cus}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={InformationTabStyles.text}>Total:</Typography>
              <Typography sx={InformationTabStyles.value}>
                {transaction.transactionAmount}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      )}

      <Grid container sx={InformationTabStyles.informationCard}>
        <Box sx={InformationTabStyles.informationContainer}>
          <Grid item container xs={12} pb={2}>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.text}>
                Estado del certificado:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.value}>
                {TRANSACTION_RESULT_TEXT_ENUM[transaction.certificateState]}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} pb={2}>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.text}>
                Respuesta del envio:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.value}>
                {FILE_STATUS_TEXT_ENUM[transaction.sendStatus]}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} pb={2}>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.text}>
                Solicitud de reembolso:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.value}>
                {transaction.refund}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.text}>
                Estado del reembolso:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.value}>
                {transaction.refundState}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid container sx={InformationTabStyles.informationCard}>
        <Box sx={InformationTabStyles.informationContainer}>
          <Grid item container xs={12} pb={2}>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.text}>
                Mensaje de respuesta:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.value}>
                {transaction.answerMessage}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.text}>
                Reintentos:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={InformationTabStyles.value}>
                {transaction.reattemps}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default InformationTab;
