import { useEffect, useState } from "react";
import { useKshRedux } from "../../../../store/hooks/hooks";
import { UseFormSetValue } from "react-hook-form";

export const useResendRefundModal = (
  setValue: UseFormSetValue<{
    email: string;
  }>
) => {
  const [checked, setChecked] = useState(true);
  const [disableInput, setDisableInput] = useState(checked);
  const [loading, setLoading] = useState(false);
  const {
    actions: { resendRefundEpic, hideRefundModal },
    store: { refundModalData },
  } = useKshRedux();

  useEffect(() => {
    setValue("email", refundModalData.email);
  }, [refundModalData.email]);

  useEffect(() => {
    if (checked) {
      setDisableInput(true);
      setValue("email", refundModalData.email);
    } else {
      setDisableInput(false);
      setValue("email", "");
    }
  }, [checked]);

  const handleClose = () => {
    setLoading(false);
    hideRefundModal();
  };

  const onSubmit = async (data: { email: string }) => {
    setLoading(true);
    const body = {
      ...refundModalData,
      email: data.email,
    };

    await resendRefundEpic(body);

    setLoading(false);
    handleClose();
  };

  return {
    checked,
    disableInput,
    handleClose,
    loading,
    onSubmit,
    setChecked,
  };
};
