import React from "react";
import { Grid } from "@mui/material";
import { useCredentialComponentSection } from "./state/useCredentialComponentSection";
import { SelectSearchBy } from "@kushki/connect-ui";
import { ItemsCategoryData } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";
import { isEmpty } from "lodash";

export interface ICredentialComponentProps {
  placeHolder: string;
  selectedValue: string;
  setTextValue: (value: string[]) => void;
}
export const CredentialComponentSection: React.FC<ICredentialComponentProps> = (
  props: ICredentialComponentProps
) => {
  const { listHierarchyCredentialItems, defaultValueSelect, handleChange } =
    useCredentialComponentSection({
      selectedValue: props.selectedValue,
      setTextValue: props.setTextValue,
    });
  const isCreate: boolean = isEmpty(defaultValueSelect) ? true : false;

  return (
    <Grid item xs={12}>
      <Grid item xs={6}>
        {!isCreate && (
          <SelectSearchBy
            isDisabled={true}
            defaultValue={defaultValueSelect}
            items={listHierarchyCredentialItems}
            placeholder={props.placeHolder}
            onItemSelected={(event: ItemsCategoryData) => {
              handleChange(event);
            }}
            onInputChange={() => {}}
          />
        )}
        {isCreate && (
          <SelectSearchBy
            items={listHierarchyCredentialItems}
            placeholder={props.placeHolder}
            onItemSelected={(event: ItemsCategoryData) => {
              handleChange(event);
            }}
            onInputChange={() => {}}
          />
        )}
      </Grid>
    </Grid>
  );
};
