import { ICellDeleteProps } from "./CellDelete.interfaces";
import { useTableContext } from "../../Table/TableContext";
import React from "react";
import { IValueCell } from "../CellHeader/interfaces";
import { ITableRowProps } from "../../Table/TableSimple/interfaces";

interface IUseCellDeleteRow {
  handler: {
    handleClick: (event: React.MouseEvent) => void;
  };
  isLoading: boolean;
}

const useCellDelete = ({
  rowIndex,
  handleDeleteRow,
  id,
}: ICellDeleteProps): IUseCellDeleteRow => {
  const {
    state: { isLoading, valueCells, selectedRows },
    handler: { handleSetCellValues, handleSetSelectedRows },
  } = useTableContext();

  const handleClick = (event: React.MouseEvent) => {
    handleDeleteRow(rowIndex);
    handleSetCellValues(
      valueCells
        .filter((cell: IValueCell) => cell.rowIndex !== rowIndex)
        .map((cellFiltered: IValueCell) => {
          if (cellFiltered.rowIndex > rowIndex) {
            const newIndex: number = cellFiltered.rowIndex - 1;
            const splitId: string[] = cellFiltered.id.split("-");

            splitId.shift();

            cellFiltered.rowIndex = newIndex;
            cellFiltered.id = `${newIndex}-${splitId.join("-")}`;
          }

          return cellFiltered;
        })
    );
    handleSetSelectedRows(
      selectedRows.filter((row: ITableRowProps) => row.id !== id)
    );
    event.stopPropagation();
  };

  return {
    handler: {
      handleClick,
    },
    isLoading,
  };
};

export default useCellDelete;
