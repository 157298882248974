import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const containerIndexStyles: IStyles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gridRowGap: (theme) => theme.spacing(5),
  },
  buttons: {
    py: 3,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gridColumnGap: (theme) => theme.spacing(5),
  },
  button: {
    px: 3,
  },
};
