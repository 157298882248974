export enum ButtonVariantEnum {
  Contained = "contained",
  Outlined = "outlined",
  SecondaryOutlined = "secondaryOutlined",
}

export enum ButtonColorEnum {
  Primary = "primary",
  Info = "info",
  Error = "error",
  Pink = "#E24763",
}
