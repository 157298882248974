import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const transactionHeaderStyles: IStyles = {
  typography: {
    fontFamily: "IBM Plex Sans,sans-serif",
    color: "primary.main",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 550,
  },
  typographyMobile: {
    color: "pimary.main",
    fontSize: "32px",
    fontWeight: 200,
  },
  checkBox: {
    color: "#677684",
  },
};
