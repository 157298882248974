export enum ModalActionLabel {
  TITLE_DELETE = "¿Estás seguro de que deseas eliminar esta alarma?",
  DESCRIPTION_DELETE = "Al eliminar la alarma se perderá la información ingresada.",
  DELETE_BUTTON = "Eliminar",
  CANCEL_BUTTON = "Cancelar",

  TITLE_INACTIVATE = "¿Estás seguro de que deseas inactivar esta alarma?",
  DESCRIPTION_INACTIVATE = "Al inactivar esta alarma, los cambios se aplicaran a todos los clientes seleccionados.",
  INACTIVATE_BUTTON = "Inactivar",
}
