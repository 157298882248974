export enum WebhookStatus {
  FAILED = "FAILED",
  OK = "OK",
  EMPTY = "",
}

export enum WebhookService {
  CARD = "card",
  CASH = "cash",
  PAYOUTS_CASH = "payouts-cash",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  TRANSFER = "transfer",
  PAYOUTS_TRANSFER = "payouts-transfer",
  SUBSCRIPTIONS = "subscriptions",
  WEBCHECKOUT = "webcheckout",
  VPOS = "vpos",
  CARD_CHARGEBACK = "card-chargeback",
}
