import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const selectedCustomersStyles = createNamedStyles({
  selectCustomersWrapper: {
    marginTop: " 52px",
  },
  selectedCustomersTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "20px",
  },
  editWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  editButton: {
    color: "primary.light2",
    fontSize: "14px !important",
    fontWeight: "500",
  },
});
