enum ModalReactivateLabels {
  TITLE = "¿Estás seguro de que deseas reactivar esta alarma?",
  MASSIVE_TITLE = "¿Estás seguro de que deseas reactivar estas alarmas?",
  DESCRIPTION = "Al reactivar la alarma se enviará al módulo de alarmas activas con un estado pendiente por revisar",
  MASSIVE_DESCRIPTION = "Al reactivar las alarmas se enviará al módulo de alarmas activas con un estado pendiente por revisar.",
  CANCEL = "Cancelar",
  REACTIVATE = "Reactivar alarma",
}

export { ModalReactivateLabels };
