import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "../../themeui";

export const titleStyles = makeStyles({
  titleContainer: {
    display: "flex",
    marginBottom: 24,
    width: "100%",
  },
  title: {
    color: Palette.primary.main,
    fontFamily: "IBM Plex Sans",
    fontSize: "40px",
    fontWeight: 200,
    lineHeight: "52px",
  },
});
