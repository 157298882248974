import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { modalFormStyle as styles } from "../../ModalForm/ModalForm.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useBoardMemberForm } from "./state/useBoardMemberForm";
import { BoardMemberFormProps } from "./BoardMemberForm.interfaces";
import FormControl from "../../../FormControl/FormControl";

const BoardMemberForm: React.FC<BoardMemberFormProps> = ({
  closeModal,
  control,
  formNode,
  handleSubmit,
  reset,
}: BoardMemberFormProps) => {
  const {
    handleCloseModal,
    onSubmit,
    isEditedBoardMember,
    constitutionalCountry,
  } = useBoardMemberForm({
    closeModal,
    reset,
  });

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant={"h3"}>
            {isEditedBoardMember.value ? "Editar" : "Agregar "} board member
          </Typography>
          <IconButton
            data-testid="close-button"
            color="secondary"
            onClick={handleCloseModal}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12}>
              <Typography variant={"body2"} color={"text.dark"}>
                Información personal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"body2"} color={"text.dark"}>
                Tipo de persona
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                control={control!}
                inputComponent="radio"
                label=""
                name="personType"
                country={constitutionalCountry}
              />
            </Grid>
          </Grid>
          {formNode}
        </DialogContent>
      </Box>
      <DialogActions sx={styles.footer}>
        <Button onClick={handleCloseModal}>Cancelar</Button>
        <Button
          data-testid="button-form"
          variant={"contained"}
          onClick={handleSubmit(onSubmit)}
        >
          {isEditedBoardMember.value ? "Guardar" : "Agregar"}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default BoardMemberForm;
