import {
  BranchEnum,
  CredentialEnum,
  CustomerEnum,
  NotificationEnum,
  WebhooksEnum,
} from "@shared/enum/developersSectionEnum";
import { ROUTES } from "@shared/constants/api_routes";
import { RolesEnum } from "@shared/enum/rolesEnum";
import { IDevelopersSection } from "@components/DeveloperSection/DeveloperSections.interfaces";

export const backofficeMasterCredentialsSections = (
  publicMerchantId: string
): IDevelopersSection[] => [
  {
    description: CredentialEnum.DESCRIPTION,
    redirect: `${ROUTES.DEVELOPERS_CREDENTIALS}/${publicMerchantId}`,
    role: RolesEnum.BackofficeMasterCredentials,
    title: CredentialEnum.TITLE,
  },
];

export const allRolesSections = (
  publicMerchantId: string
): IDevelopersSection[] => [
  {
    description: CredentialEnum.DESCRIPTION,
    redirect: `${ROUTES.DEVELOPERS_CREDENTIALS}/${publicMerchantId}`,
    title: CredentialEnum.TITLE,
  },
  {
    description: WebhooksEnum.DESCRIPTION,
    redirect: `${ROUTES.WEBHOOKS}/${publicMerchantId}`,
    title: WebhooksEnum.TITLE,
  },
  {
    description: NotificationEnum.DESCRIPTION,
    redirect: `${ROUTES.NOTIFICATIONS}/${publicMerchantId}`,
    title: NotificationEnum.TITLE,
  },
];

export const backofficeMasterCustomerSections = (
  publicMerchantId: string
): IDevelopersSection[] => [
  {
    description: BranchEnum.DESCRIPTION,
    redirect: `${ROUTES.DEVELOPERS_CREDENTIALS}/${publicMerchantId}`,
    role: RolesEnum.BackofficeMasterCustomer,
    title: BranchEnum.TITLE,
    tooltip: BranchEnum.TOOLTIP_TEXT,
  },
  {
    description: CustomerEnum.DESCRIPTION,
    redirect: `${ROUTES.DEVELOPERS_CREDENTIALS_CUSTOMER}/${publicMerchantId}`,
    role: RolesEnum.BackofficeMasterCustomer,
    title: CustomerEnum.TITLE,
    tooltip: CustomerEnum.TOOLTIP_TEXT,
  },
];
