/* istanbul ignore file */

export enum ErrorsEnum {
  REQUIRED_FIELD = "Campo requerido",
  SELECT_SERVICE = "No se encuentra activado ningun medio de pago",
  SELECT_MERCHANT = "Seleccione un comercio",
  REQUIRED_MIN_RULE = "Se requiere al menos una regla",
  MIN_4_CHARACTER = "Mínimo se requiere 4 caracteres",
  MIN_12_CHARACTER = "Mínimo se requiere 12 caracteres",
  MAX_10_CHARACTER = "Máximo se requiere 10 caracteres",
  MAX_12_CHARACTER = "Máximo se requiere 12 caracteres",
  MAX_22_CHARACTER = "Máximo se requiere 22 caracteres",
  MAX_32_CHARACTER = "Máximo se requiere 32 caracteres",
  NOT_ALLOWED_VALUE = "Valor no permitido",
  LESS_THAN_ERROR = "El primer monto debe ser menor que el segundo monto",
  GREATER_THAN_ERROR = "El segundo monto debe ser mayor que el primer monto",
  ONLY_VALID_IPS = "No es una IP válida",
  NUMBERS_AND_LETTERS = "Se admiten letras y números",
  NOT_NUMBERS = "No se permite números",
  REQUEST_ERROR = "Hubo un error al cargar datos",
}
