import { ISnackBar } from "@kushki/connect-ui";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { CreateNodeResponse } from "../../../../types/create_node_response";
import { createOwner, listOwners } from "../../thunks/owner/owner.thunk";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { get, isEmpty } from "lodash";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/utils/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { camelToSnake } from "../../../shared/utils/camelToSnake.utils";

export interface OwnerData {
  ownerDetail: CreateNodeResponse;
  listOwner: SearchMerchantResponse;
  isLoading: boolean;
  notification: ISnackBar | undefined;
  isLoadingFlow: boolean;
}

export const initialState: OwnerData = {
  isLoading: false,
  isLoadingFlow: false,
  listOwner: { data: [] } as SearchMerchantResponse,
  notification: undefined,
  ownerDetail: {},
};

export const ownerDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(listOwners.pending, (state: Draft<OwnerData>) => {
        state.isLoadingFlow = true;
      })
      .addCase(listOwners.rejected, (state: Draft<OwnerData>) => {
        state.isLoadingFlow = false;
      })
      .addCase(listOwners.fulfilled, (state, action) => {
        state.listOwner = action.payload;
        if (isEmpty(state.listOwner.data)) state.isLoadingFlow = false;
      })
      .addCase(createOwner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOwner.fulfilled, (state, action) => {
        state.ownerDetail = camelToSnake(action.payload);
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
        state.isLoading = false;
        window.location.href = `/clients/${action.payload.nodeId}`;
      })
      .addCase(createOwner.rejected, (state) => {
        state.isLoading = false;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
  },
  initialState,
  name: "ownerData",
  reducers: {
    setIsLoadingFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingFlow = payload;
    },
    setNotification: (
      state,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setOwnerDetail: (state, { payload }: PayloadAction<CreateNodeResponse>) => {
      state.ownerDetail = payload;
    },
  },
});

export default ownerDataSlice.reducer;
