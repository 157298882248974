import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const paginationFooterStyles: IStyles = {
  container: {
    backgroundColor: "white",
    display: "inline-flex",
    flexWrap: "nowrap",
    padding: "0px 12px",
  },
  label: {
    color: "primary.main",
    fontSize: "13px",
    lineHeight: "20px",
    paddingLeft: "16px",
  },
  optionText: {
    "& .MuiSelect-select": {
      paddingLeft: "6px",
    },
    paddingLeft: "28px",
    paddingTop: "5px",
  },
  // @ts-ignore
  pagination: {
    "& .MuiPagination-ul": {
      "& .MuiPaginationItem-root": {
        "&.Mui-selected": {
          backgroundColor: "#EEF6FF",
          color: "#023365",
          fontSize: "13px",
        },
        background: "#FFFFFF",
        borderRadius: "4px",
        boxShadow: "0px 8px 20px rgba(91, 91, 91, 0.0585)",
        fontSize: "13px",
        height: "32px",
        marginLeft: "8px",
        width: "32px",
      },
    },
  },
  paginationBlock: {
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
  },
  paginationButton: {
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 8px 20px rgba(91, 91, 91, 0.0584805)",
    color: "primary.main",
    cursor: "select",
    display: "flex",
    fontSize: "13px",
    height: "32px",
    justifyContent: "center",
    marginLeft: "9px",
    minWidth: "32px",
    padding: 0,
    width: "32px",
  },
  paginationButtonActive: {
    alignItems: "center",
    background: "rgba(25, 118, 210, 0.04)",
    borderRadius: "4px",
    boxShadow: "0px 8px 20px rgba(91, 91, 91, 0.0584805)",
    color: "primary.main",
    cursor: "select",
    display: "flex",
    fontSize: "13px",
    fontWeight: 500,
    height: "32px",
    justifyContent: "center",
    marginLeft: "9px",
    minWidth: "32px",
    padding: 0,
    width: "32px",
  },
  paginationButtonIcon: {
    color: "primary.main",
  },
  paginationButtons: {
    alignItems: "center",
    display: "flex",
    padding: "20px 65px",
  },
  paginationFooterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",
  },
  paginationLabel: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  paginationSkeleton: {
    "& .MuiSkeleton-rectangular": {
      marginLeft: "8px",
    },
    display: "inline-flex",
  },
  paginationText: {
    color: "primary.main",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "170%",
  },
  paginationTextBlock: {
    whiteSpace: "nowrap",
  },
  paginationTextSkeleton: {
    "& .desktop": {},
    display: "inline-flex",
    paddingBottom: "0px",
  },
  textPageNumber: {
    fontSize: "13px",
  },
};
