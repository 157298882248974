import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigData } from "../../interfaces/configData.interfaces";
import {
  getMerchantCardData,
  getMerchantCommissionData,
  getMerchantSmartlinkData,
  getMerchantSubscriptionData,
  getMerchantSubscriptionDataSandbox,
  getMerchantVPOSData,
  getNodeInfo,
  getPaymentCredentials,
  getProcessors,
  setDefaultServiceConfig,
  setMassiveServiceConfig,
  updateHierarchyNodeConfig,
} from "../../thunks/general/general.thunk";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/utils/snackbarUtils";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { get } from "lodash";
import { ISnackBar } from "@kushki/connect-ui";
import { redirectUtil } from "../../../shared/utils/redirectUtil";
import {
  CONTINUE_REDIRECT_RATES,
  VIEW_BRANCHES_REDIRECT,
} from "../../../shared/constants/routes";
import { MassiveInformation } from "../../../../types/massive_information";
import { setLocalStorageObject } from "../../../shared/utils/localStorageUtils";
import { LocalStoragePathEnum } from "../../../shared/enums/LocalStoragePathEnum";
import { MassiveServiceConfiguration } from "../../../../types/massive_service_configuration";
import {
  getMassiveConfig,
  setIsModifyByBranch,
} from "../../../shared/utils/massiveConfigUtils";

export const initialState: ConfigData = {
  cardData: {},
  commissionData: {},
  defaultServiceConfig: {},
  isLoading: false,
  isMassiveDefault: false,
  massiveInformation: {
    listBranches: [],
    title: "",
  },
  merchantSettings: {
    active_3dsecure: false,
    country: "",
    merchant_name: "",
    processor_name: "",
    processors: {},
    prodAccountId: "",
    prodBaconKey: "",
    sandboxAccountId: "",
    sandboxBaconKey: "",
  },
  nodeInfo: {
    configCoreId: "",
  },
  notification: undefined,
  paymentCredentials: [],
  smartlinkData: {},
  subscriptionData: {},
  subscriptionDataSandbox: {},
  vposData: {},
};

export const configDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getNodeInfo.fulfilled, (state, { payload }) => {
        state.nodeInfo = payload;
      })
      .addCase(getProcessors.fulfilled, (state, action) => {
        state.merchantSettings = action.payload;
      })
      .addCase(getPaymentCredentials.fulfilled, (state, action) => {
        state.paymentCredentials = action.payload;
      })
      .addCase(getMerchantSmartlinkData.fulfilled, (state, action) => {
        state.smartlinkData = action.payload;
      })
      .addCase(getMerchantVPOSData.fulfilled, (state, action) => {
        state.vposData = action.payload;
      })
      .addCase(getMerchantCardData.fulfilled, (state, action) => {
        state.cardData = action.payload;
      })
      .addCase(getMerchantSubscriptionData.fulfilled, (state, action) => {
        state.subscriptionData = action.payload;
      })
      .addCase(getMerchantCommissionData.fulfilled, (state, action) => {
        state.commissionData = action.payload;
      })
      .addCase(setDefaultServiceConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setDefaultServiceConfig.fulfilled, (state, action) => {
        state.defaultServiceConfig = action.payload;
        state.isLoading = false;
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
      })
      .addCase(setDefaultServiceConfig.rejected, (state) => {
        state.isLoading = false;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      })
      .addCase(updateHierarchyNodeConfig.fulfilled, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
        redirectUtil(
          CONTINUE_REDIRECT_RATES(get(state, "nodeInfo.merchantId", ""))
        );
      })
      .addCase(updateHierarchyNodeConfig.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      })
      .addCase(setMassiveServiceConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setMassiveServiceConfig.fulfilled, (state) => {
        setIsModifyByBranch();
        const massiveConfig: MassiveServiceConfiguration = getMassiveConfig();

        setLocalStorageObject(
          LocalStoragePathEnum.MASSIVE_SERVICE_CONFIGURATIONS,
          { ...massiveConfig, alreadySent: true }
        );
        state.isLoading = false;
        if (state.redirectToBranchList) {
          redirectUtil(VIEW_BRANCHES_REDIRECT);
        } else {
          state.notification = buildNotification(
            NotificationTypeEnum.SUCCESS,
            get(state, "notification", defaultDataSnackbar)
          );
        }
      })
      .addCase(setMassiveServiceConfig.rejected, (state) => {
        const massiveConfig: MassiveServiceConfiguration = getMassiveConfig();

        setLocalStorageObject(
          LocalStoragePathEnum.MASSIVE_SERVICE_CONFIGURATIONS,
          { ...massiveConfig, alreadySent: false }
        );
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
        state.isLoading = false;
        if (state.redirectToBranchList) redirectUtil(VIEW_BRANCHES_REDIRECT);
      })
      .addCase(
        getMerchantSubscriptionDataSandbox.fulfilled,
        (state, action) => {
          state.subscriptionDataSandbox = action.payload;
        }
      );
  },
  initialState,
  name: "configData",
  reducers: {
    setIsMassiveDefault: (state, { payload }: PayloadAction<boolean>) => {
      state.isMassiveDefault = payload;
    },
    setMassiveInformation(
      state,
      action: PayloadAction<Partial<MassiveInformation>>
    ) {
      state.massiveInformation = {
        ...state.massiveInformation,
        ...action.payload,
      };
    },
    setNotification: (
      state,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setRedirectToBranchList: (state, { payload }: PayloadAction<boolean>) => {
      state.redirectToBranchList = payload;
    },
  },
});

export default configDataSlice.reducer;
