import { IUseListCatalogGeneralRulesComponent } from "../../../shared/infrastructure/interfaces/IModalCatalogGeneralRule";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect, useState } from "react";
import { getRuleRequestCatalog } from "../../../store/actionCreators";
import { defaultTo, get, isEmpty } from "lodash";
import { RuleGeneralRequest } from "../../../../types/rule_general_request";
import { SearchRuleCatalogResponse } from "../../../../types/search_rule_catalog_response";
import { ICardListProps } from "@kushki/connect-ui";
import { SearchRuleCatalogRequest } from "../../../../types/search_rule_catalog_request";
import { TextFieldSearchByItem } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldSearchBy/TextFieldSearchBy.interfaces";
import { IListCatalogGeneralRulesComponent } from "../ListCatalogGeneralRulesComponent";
import { ListCatalogEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { SearchCatalogEnum } from "../../../shared/infrastructure/enums/FilterEnum";
import {
  Codes,
  ConfigurationResponse,
  GeneralActions,
  Messages,
  Providers,
} from "../../../../types/configuration_response";

export const useListCatalogGeneralRulesComponentState = (
  props: IListCatalogGeneralRulesComponent
): IUseListCatalogGeneralRulesComponent => {
  const dispatch = useDispatch();

  const searchRuleCatalogRequest: SearchRuleCatalogRequest = {
    name: "",
    limit: 100,
    offset: 0,
    sort: {
      field: SearchCatalogEnum.CREATED,
      order: SearchCatalogEnum.ORDER_ASC,
    },
  };

  const searchRuleCatalogResponse: SearchRuleCatalogResponse | undefined =
    useSelector((state: IAppState) => state.ruleCatalog);

  const configurationValue: ConfigurationResponse | undefined = useSelector(
    (state: IAppState) => state.configuration
  );

  const isLoading: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingRules
  );

  const [ruleCatalogList, setRuleCatalogList] = useState<
    TextFieldSearchByItem[]
  >([]);

  const [totalItems, setTotalItems] = useState<number>(0);

  const [itemsCatalogGeneralRule, setItemsCatalogGeneralRule] = useState<any[]>(
    []
  );

  const [itemsPerPage] = useState<number>(5);

  const itemsPerPageData = (data: any[], start: number, end: number): void => {
    if (data.slice(start, end).length > 0) {
      props.setSelectedRule(data.slice(start, end)[0]);
    } else {
      props.setSelectedRule(undefined);
    }
    setItemsCatalogGeneralRule(data.slice(start, end));
  };

  useEffect(() => {
    const items: TextFieldSearchByItem[] = [];

    itemsPerPageData(
      get(searchRuleCatalogResponse, "data", []),
      0,
      itemsPerPage
    );
    get(searchRuleCatalogResponse, "data", []).forEach(
      (data: RuleGeneralRequest) => {
        items.push({
          text: data.name,
          secondaryText: data.rule.name,
        });
      }
    );
    setRuleCatalogList(items);
    setTotalItems(get(searchRuleCatalogResponse, "data.length", 0));
  }, [searchRuleCatalogResponse]);

  const handleSearch = (searchItem: object): void => {
    const value: any = get(searchItem, "target.value", "");

    if (value.length > 3) {
      searchRuleCatalogRequest.name = get(searchItem, "target.value");
      dispatch(getRuleRequestCatalog(searchRuleCatalogRequest));
    }
    if (value.length === 0) {
      searchRuleCatalogRequest.name = "";
      dispatch(getRuleRequestCatalog(searchRuleCatalogRequest));
    }
  };

  const handleSelectedPage = (selected: number, last: number) => {
    itemsPerPageData(
      get(searchRuleCatalogResponse, "data", []),
      selected - 1,
      last
    );
  };

  const getRuleItem = (item: RuleGeneralRequest): ICardListProps => {
    return {
      title: item.name,
      orientation: "horizontal",
      separator: false,
      isLoading: false,
      items: [
        {
          label: ListCatalogEnum.GENERAL_ACTION,
          text: defaultTo(
            get(configurationValue, "generalActions", []).find(
              (action: GeneralActions) =>
                action.name === item.rule.generalAction
            ),
            { title: "" }
          ).title,
          secondaryText: "",
        },
        {
          label: ListCatalogEnum.PROVIDER,
          text: defaultTo(
            get(configurationValue, "providers", []).find(
              (provider: Providers) =>
                provider.name === item.rule.partnerValidator
            ),
            { title: "" }
          ).title,
          secondaryText: "",
        },
        {
          label: ListCatalogEnum.CODE,
          text: defaultTo(
            get(configurationValue, "codes", []).find(
              (code: Codes) => code.code === item.rule.code
            ),
            { title: "" }
          ).title,
          secondaryText: "",
        },
        {
          label: ListCatalogEnum.MESSAGE,
          text: defaultTo(
            get(configurationValue, "messages", []).find(
              (message: Messages) => message.title === item.rule.message
            ),
            { title: "" }
          ).title,
          secondaryText: "",
        },
      ],
    };
  };

  const handleFilterRule = (selected: any) => {
    const itemSearch: string = get(selected, "text", "");
    const selectedItem: RuleGeneralRequest[] | undefined = get(
      searchRuleCatalogResponse,
      "data",
      []
    ).filter((item: RuleGeneralRequest) => item.name === itemSearch);

    if (!isEmpty(selectedItem)) {
      props.setSelectedRule(selectedItem[0]);
      searchRuleCatalogRequest.name = itemSearch;
      dispatch(getRuleRequestCatalog(searchRuleCatalogRequest));
    }
  };

  return {
    itemsCatalogGeneralRule,
    totalItems,
    isLoading,
    actions: {
      handleSelectedPage,
      handleSearch,
      handleFilterRule,
    },
    getRuleItem,
    ruleCatalogList,
  };
};
