import React from "react";
import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material";
import { TableBodyStyles } from "../../../components/Table/TableBody/TableBody.style";
import { get } from "lodash";
import { formatDate, validateCurrency } from "../../../shared/utils/utils";
import { RatesConfigDynamo } from "../../../../types/rates_config_dynamo";
import {
  PAYMENT_METHOD_ICON_TABLE,
  PAYMENT_METHOD_TRANSLATE,
  SERVICES_TRANSLATE,
} from "../../../shared/utils/IconPaymentMethods";
import { RatesCollapse } from "./RatesCollapse";
import { CountriesEnum } from "../../../shared/enums/CountriesEnum";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";
import { RatesTableLabelEnum } from "../../../shared/enums/RatesTableLabelEnum";

export interface RatesTableRowPropsInterface {
  id: string;
  index: number;
  columns: number;
  country: CountriesEnum;
  rate: RatesConfigDynamo;
  merchantInfo?: MerchantBillingInfo;
  currency?: string;
}

export const RatesTableRow: React.FC<RatesTableRowPropsInterface> = (
  props: RatesTableRowPropsInterface
) => {
  const { rate, country } = props;
  const isItemSelected = false;
  const [open, setOpen] = React.useState(false);
  const collapseButtonText = open
    ? RatesTableLabelEnum.HIDE_DETAILS
    : RatesTableLabelEnum.SHOW_DETAILS;
  const isChile: boolean = CountriesEnum.CHILE === country;
  const currencyCountry: string =
    isChile && rate.currency ? rate.currency : validateCurrency(country);

  return (
    <>
      <TableRow
        aria-label={`table-row-${props.id}`}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={props.id}
        sx={TableBodyStyles.row}
        selected={isItemSelected}
        data-testid="table-row"
      >
        <TableCell
          key={`row-${props.index}-cell-date`}
          scope="row"
          padding="none"
          align={get(props, "align", "center")}
        >
          <Box sx={TableBodyStyles.innerFirstData}>
            {" "}
            {rate.created && formatDate(rate.created, "dd MMM. yyyy HH:mm")}
          </Box>
        </TableCell>
        <TableCell
          key={`row-${props.index}-cell-payment`}
          scope="row"
          padding="none"
          align={get(props, "align", "center")}
        >
          {rate.paymentMethod.map((item, index) => (
            <Box key={index} display="flex" alignItems={"center"} gap={"12px"}>
              {PAYMENT_METHOD_ICON_TABLE[item]}
              <Typography sx={TableBodyStyles.innerFirstDataClick}>
                {PAYMENT_METHOD_TRANSLATE[item] ?? SERVICES_TRANSLATE[item]}
              </Typography>
            </Box>
          ))}
        </TableCell>
        <TableCell
          key={`row-${props.index}-cell-fixed`}
          scope="row"
          padding="none"
          align={get(props, "align", "center")}
          sx={TableBodyStyles.innerFirstData}
        >
          {get(rate.configuration[0], "fixedAmount")} {currencyCountry}
        </TableCell>
        <TableCell
          key={`row-${props.index}-cell-variable`}
          scope="row"
          padding="none"
          align={get(props, "align", "center")}
          sx={TableBodyStyles.innerFirstData}
        >
          {get(rate.configuration[0], "variableAmount")}
          {" %"}
        </TableCell>
        <TableCell
          key={`row-${props.index}-cell-min`}
          scope="row"
          padding="none"
          align={get(props, "align", "center")}
          sx={TableBodyStyles.innerFirstData}
        >
          {get(rate, "minimumAmount")} {currencyCountry}
        </TableCell>
        <TableCell
          key={`row-${props.index}-cell-max`}
          scope="row"
          padding="none"
          align={get(props, "align", "center")}
          sx={TableBodyStyles.innerFirstData}
        >
          {get(rate, "maximumAmount")} {currencyCountry}
        </TableCell>
        <TableCell
          key={`row-${props.index}-cell-button`}
          scope="row"
          padding="none"
          align={get(props, "align", "right")}
          sx={TableBodyStyles.contentClick}
        >
          <Typography
            variant={"body2"}
            color={"primary"}
            sx={TableBodyStyles.innerFirstDataClickBlue}
            onClick={() => setOpen(!open)}
          >
            {collapseButtonText}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            background: "#F7FAFC",
            border: "none",
            display: `${open ? "" : "none"}`,
          }}
          colSpan={props.columns}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <RatesCollapse rate={rate} country={country} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
