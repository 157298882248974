export enum Routes {
  INDEX = "/",
  CONTACT_DETAIL = "/contact-details",
  BASIC_DETAILS = "/basic-details",
  BILLING_DETAILS = "/billing-details",
  LEGAL_DETAILS = "/legal-details",
  CREATE_NODE = "/create-node/customer",
  CREATE_NODE_BRANCH_SELECTION = "/create-node/branch/selection",
  EDIT_BRANCH_BASIC_DETAILS = "/batch/basic-details",
  EDIT_BRANCH_CONTACT_DETAIL = "/batch/contact-details",
  EDIT_BRANCH_BILLING_DETAILS = "/batch/billing-details",
  EDIT_BRANCH_LEGAL_DETAILS = "/batch/legal-details",
}

export const SPA_BASENAME = "merchant";
