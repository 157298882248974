import { createNamedStyles } from "../../../../shared/utils";

export const transactionsTablePaginationStyles = createNamedStyles({
  paginationItem: {
    color: "text.grey",
    "& .MuiPaginationItem-ellipsis": {
      color: "text.grey",
    },
    "& .MuiSvgIcon-root": {
      fill: "none",
    },
  },
});
