import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { RatesAndDiscountResumeStyles } from "./RatesAndDiscountResume.styles";
import { TableInfo } from "@kushki/connect-ui";
import { ALL_TABLE_COLUMNS_DISCOUNT } from "../../shared/infrastructure/constants/RatesAndDiscountResumeConstants";
import useRatesAndDiscountResume from "./state/useRatesAndDiscountResume";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";

const RatesAndDiscountResume = () => {
  const { discountList, rows, showRatesAndDiscountResume } =
    useRatesAndDiscountResume();

  return (
    <>
      {showRatesAndDiscountResume ? (
        <Box bgcolor="neutral.grey3" sx={RatesAndDiscountResumeStyles.papper}>
          <Typography color="text.dark" variant="subtitle2" sx={{ mb: "10px" }}>
            Tarifas agregadas
          </Typography>
          <Box
            sx={{
              overflowX: "auto",
            }}
          >
            <TableInfo
              header={{
                cells: ALL_TABLE_COLUMNS_DISCOUNT,
                headerProps: {
                  isDisable: false,
                },
              }}
              rows={rows}
            />
          </Box>
          {discountList && (
            <>
              <Typography
                color="text.dark"
                variant="subtitle2"
                sx={{ my: "16px" }}
              >
                Descuentos
              </Typography>
              <Grid container rowSpacing={1}>
                {discountList.map((item, i) => (
                  <Grid item xs={12} key={`${item.label}-${i}`}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        color="text.grey"
                        sx={RatesAndDiscountResumeStyles.discountText}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        color="text.dark"
                        sx={
                          RatesAndDiscountResumeStyles.discountDescriptionText
                        }
                      >
                        {item.value}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      ) : (
        <MissingInfoAlert />
      )}
    </>
  );
};

export default RatesAndDiscountResume;
