import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconChevronDown: React.FunctionComponent<SvgIconProps> = (props) => (
  <ThemeProvider theme={theme}>
    <SvgIcon
      {...props}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.96373 11.716C7.87409 11.7131 7.78597 11.6921 7.70464 11.6543C7.62331 11.6165 7.55044 11.5626 7.4904 11.496L1.83706 5.86268C1.72784 5.73515 1.67077 5.5711 1.67726 5.40331C1.68374 5.23553 1.75329 5.07637 1.87202 4.95764C1.99075 4.83891 2.14991 4.76936 2.31769 4.76287C2.48548 4.75639 2.64953 4.81346 2.77706 4.92268L7.96373 10.1093L13.2971 4.77602C13.422 4.65185 13.5909 4.58215 13.7671 4.58215C13.9432 4.58215 14.1122 4.65185 14.2371 4.77602C14.2995 4.83799 14.3491 4.91172 14.383 4.99296C14.4168 5.0742 14.4343 5.16134 14.4343 5.24935C14.4343 5.33736 14.4168 5.42449 14.383 5.50573C14.3491 5.58697 14.2995 5.66071 14.2371 5.72268L8.43706 11.496C8.37702 11.5626 8.30415 11.6165 8.22282 11.6543C8.14149 11.6921 8.05337 11.7131 7.96373 11.716Z" />
    </SvgIcon>
  </ThemeProvider>
);

export default IconChevronDown;
