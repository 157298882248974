import { ICellRow, ICellText } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  HeaderCellProps,
  ITableCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { NotificationStatus } from "../status-enum";
import { IStyles } from "../interfaces/Styles.interfaces";
import { Box, Chip } from "@mui/material";
import { WEBHOOK_SERVICE_TEXT } from "../../constants/customer_webhooks";

enum CELL_TYPES {
  TEXT = "text",
  TAG = "tag",
}

const CellTagStyle: IStyles = {
  box: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    padding: "4px",
  },
};

type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

enum tableProperties {
  CREATION_DATE = "creationDate",
  NAME = "name",
  PAYMENTMETHOD = "paymentMethod",
  BRANCHID = "branchId",
  STATUS = "status",
}

export const ALL_TABLE_COLUMNS = (
  isBranch: boolean
): CustomHeaderCellProps[] => {
  const branchId = {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.BRANCHID,
    spacing: 0,
    text: "Branch ID",
  };

  const columns = [
    {
      align: "center",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.CREATION_DATE,
      spacing: 0,
      text: "Fecha",
    },
    {
      align: "center",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.NAME,
      spacing: 0,
      text: "Alias",
    },
    {
      align: "center",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.PAYMENTMETHOD,
      spacing: 0,
      text: "Medio de pago o producto",
    },
    {
      align: "center",
      cellType: CELL_TYPES.TAG,
      key: tableProperties.STATUS,
      spacing: 0,
      text: "Estado",
    },
  ];

  if (!isBranch) columns.splice(3, 0, branchId);

  return columns as CustomHeaderCellProps[];
};

export const toTag = (paymentMethod: string[]) => {
  return (
    <Box sx={CellTagStyle.box}>
      {paymentMethod.map((tag, index) => (
        <Chip
          key={index}
          label={WEBHOOK_SERVICE_TEXT[tag]}
          size="small"
          color="info"
        />
      ))}
    </Box>
  );
};

export const colorSelector = (
  status: string
): { color: string; label: string } => {
  switch (status) {
    case NotificationStatus.SUCCESSFUL:
      return { color: "success", label: "EXITOSO" };
    case NotificationStatus.FAILED:
      return { color: "error", label: "FALLIDO" };
    default:
      return { color: "primary", label: "" };
  }
};

export const PAYMENT_CELL = (line1: string[]) =>
  ({
    props: {
      cellProps: {
        align: "center",
        spacing: 1,
      },
      //@ts-ignore
      line1: toTag(line1),
      type: "oneLine",
    } as unknown as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

export const TAG_CELL = (status: string) =>
  ({
    props: {
      cellProps: {
        align: "center",
        spacing: 1,
      } as ICellRow,
      color: colorSelector(status).color,
      text: colorSelector(status).label,
    },
    type: "TAG" as TableBodyCellEnum,
  } as ITableCellProps);
