import React, { FC } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import {
  FilterAmountLabelEnum,
  FilterAmountValueEnum,
  LabelTransactionEnum,
} from "../../shared/enums/TransactionEnum";
import { useAmountFilter } from "./state/useAmountFilter";
import { headerStyles as styles } from "./AmountFilter.styles";
import { IAmountFilter } from "./AmountFilter.interfaces";

const AmountFilter: FC<IAmountFilter> = (props: IAmountFilter) => {
  const {
    isBetween,
    onChangeAmount,
    onChangeRange,
    onChangeAdditionalAmount,
    valueAmount,
    valueAdditionalAmount,
    valueRange,
    errorRange,
  } = useAmountFilter(props);

  return (
    <Box sx={styles.amountContainer}>
      <TextField
        data-testid={"rangeText"}
        sx={styles.fieldContainer}
        onChange={onChangeRange}
        label={LabelTransactionEnum.RANGE}
        disabled={false}
        value={valueRange}
        select
      >
        <MenuItem value={FilterAmountValueEnum.MIN}>
          {FilterAmountLabelEnum.MIN}
        </MenuItem>
        <MenuItem value={FilterAmountValueEnum.MAX}>
          {FilterAmountLabelEnum.MAX}
        </MenuItem>
        <MenuItem value={FilterAmountValueEnum.EQ}>
          {FilterAmountLabelEnum.EQ}
        </MenuItem>
        <MenuItem value={FilterAmountValueEnum.BET}>
          {FilterAmountLabelEnum.BET}
        </MenuItem>
      </TextField>
      <TextField
        error={errorRange}
        label={isBetween ? LabelTransactionEnum.MIN : ""}
        sx={styles.fieldContainer}
        onChange={onChangeAmount}
        disabled={false}
        helperText={errorRange && LabelTransactionEnum.MESSAGE_ERROR}
        value={valueAmount}
        placeholder={"00.00"}
        variant="outlined"
      />
      {isBetween && (
        <Box sx={styles.additionalContainer}>
          <span>y</span>
          <TextField
            label={LabelTransactionEnum.MAX}
            onChange={onChangeAdditionalAmount}
            disabled={false}
            placeholder={"00.00"}
            variant="outlined"
            value={valueAdditionalAmount}
          />
        </Box>
      )}
    </Box>
  );
};

export default AmountFilter;
