import { IAppState, IMerchantsResponse, INotification } from "./reducer";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import { auth } from "../shared/auth";
import { ActionTypes } from "./actionTypes";
import axios from "../shared/axios-util";
import { Color } from "@material-ui/lab";
import { SessionRequest } from "../../types/session_request";
import { environment } from "../environments/environment";
import { VerificationResponse } from "../../types/verification_code_response";
import { AxiosError, AxiosResponse } from "axios";
import { SessionHistoryData } from "../../types/session_history_data";
import { RequestQuerySearch } from "../../types/request_query_search";
import { ConnectionRequest } from "../../types/connection_request";
import {
  ErrorCodeEnum,
  mapErrors,
} from "../shared/infrastructure/CatalogError";
import { defaultTo, get } from "lodash";
import { databaseRef } from "../shared/firebase";
import { SessionStatusEnum } from "../shared/infrastructure/catalogues/SessionStatusEnum";
export type IAppAction = { type: string } & IAppState;

export const setIsLoading = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_IS_LOADING,
  isLoading: payload,
});

export const setAuthInfo = (payload: IAuthInfo): IAppAction => ({
  type: ActionTypes.SET_AUTH_INFO,
  authInfo: payload,
});

export const setAccessCode = (payload: string): IAppAction => ({
  type: ActionTypes.SET_ACCESS_CODE,
  accessCode: payload,
});

export const setVerifyAccessCodeMerchant = (
  payload: VerificationResponse
): IAppAction => ({
  type: ActionTypes.SET_VERIFY_ACCESS_CODE_MERCHANT,
  merchantAccessCode: payload,
});
export const setConnectionStatus = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_CONNECTION_STATUS,
  isConnectionSuccessful: payload,
});

export const setRedirectModal = (payload: {
  show: boolean;
  url: string;
}): IAppAction => {
  return {
    type: ActionTypes.SET_REDIRECT_MODAL,
    redirectModal: payload,
  };
};

export const setNotification = (notify: INotification): IAppAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: notify,
  };
};

export const setHistoryData = (payload: SessionHistoryData): IAppAction => {
  return {
    type: ActionTypes.SET_HISTORY_DATA,
    historyData: payload,
  };
};

export const setMerchants = (payload: IMerchantsResponse): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANTS,
    merchants: payload,
  };
};

const dispatchNotification = (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  msg: string,
  type: Color
): void => {
  dispatch(
    setNotification({
      message: msg,
      show: true,
      type: type,
    })
  );
};

export const saveAccessCode = (accessCode: string) => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setAccessCode(accessCode));
  };
};

export const redirectZendesk = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoading(true));
    axios
      .get(`${environment.kushkiUrl}/help-center/v1/zendeksUrl`, {})
      .then((response) => {
        if (response.status !== 200) return;
        dispatch(setIsLoading(false));
        dispatch(
          setRedirectModal({ show: true, url: get(response, "data", "") })
        );
      })
      .catch(() => {
        dispatch(setIsLoading(false));
        const code: string = ErrorCodeEnum.ZENDESK_DEFAULT;
        dispatchNotification(dispatch, mapErrors(code), "error");
      });
  };
};

export const getAccessCode = (
  sessionRequest: SessionRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(
        `${environment.kushkiUrl}/authorizer/v1/sessionCode`,
        sessionRequest
      )
      .then((response) => {
        if (response.status !== 200) return;

        let expiredDate = auth.getExpiredDateSession(
          sessionRequest.timeConnect
        );

        auth.setSessionSupport({
          accessCode: response.data.accessCode,
          userMaster: sessionRequest.username,
          expired: `${expiredDate.getTime()}`,
        });
        dispatch(setAccessCode(response.data.accessCode));
      })
      .catch((error: AxiosError) => {
        const code: string =
          get(error, "response.data.code") !== ErrorCodeEnum.AUT006
            ? ErrorCodeEnum.ACCESS_CODE_ERROR
            : get(error, "response.data,code", ErrorCodeEnum.AUT006);
        dispatchNotification(dispatch, mapErrors(code), "error");
      });
  };
};

export const verifyAccessCode = (
  accessCode: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/authorizer/v1/verifySession`, {
        accessCode,
        userAgent: defaultTo(localStorage.getItem("username"), ""),
      })
      .then((response: AxiosResponse<VerificationResponse>) => {
        dispatch(setVerifyAccessCodeMerchant(response.data));
      })
      .catch((_err) => {
        const code = get(_err, "response.data.code", "VERIFY_ACCESS_CODE");
        dispatchNotification(dispatch, mapErrors(code, ""), "error");
      });
  };
};
export const connectSession = (
  body: ConnectionRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/authorizer/v1/connectSession`, body)
      .then((response: AxiosResponse<{ message: string; status: string }>) => {
        dispatch(setConnectionStatus(true));
        dispatchNotification(dispatch, response.data.message, "success");
      })
      .catch((_err) => {
        dispatch(setConnectionStatus(false));
        dispatchNotification(
          dispatch,
          get(
            _err,
            "response.data.message",
            "Lo sentimos, no se pudo realizar la conexión con el comercio"
          ),
          "error"
        );
      });
  };
};

export const getAuthInfo = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const authInfo: IAuthInfo = {
      isAdmin: auth.isAdmin(),
    };
    dispatch(setAuthInfo(authInfo));
  };
};

export const getMerchantsRequest = (payload: {
  offset: number;
  text: string;
  merchant_id?: string;
}): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    if (payload.text === "") delete payload.text;

    const method: string = "analytics/v1/admin/merchant/get";
    const body: {
      limit: number;
      offset: number;
      text?: string;
      merchant_id?: string;
    } = {
      ...payload,
      limit: 15,
    };
    axios
      .post<IMerchantsResponse>(`${environment.kushkiUrl}/${method}`, body)
      .then((axios_response: AxiosResponse<IMerchantsResponse>) => {
        const response: IMerchantsResponse = axios_response.data;

        dispatch(setMerchants(response));
      });
  };
};

export const getHistorySessionData = (
  request: RequestQuerySearch
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const end_point: string = request.userType;
    const method: string = `/authorizer/v1/${end_point}/listSession`;
    axios
      .post(`${environment.kushkiUrl}${method}`, {
        ...request,
        text: request.text,
      })
      .then((axios_response: AxiosResponse<SessionHistoryData>) => {
        const response: SessionHistoryData = axios_response.data;
        dispatch(setHistoryData({ ...response }));
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
};

export const statusCodeSupport = (
  payload: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    databaseRef
      .child(`${environment.envName}/authorizer/session/${payload}`)
      .on("value", (snapshot) => {
        const status:
          | { accessCode: string; status: string; username: string }
          | undefined = snapshot.val();
        if (
          status !== undefined &&
          status.status !== SessionStatusEnum.ACTIVE &&
          status.status !== SessionStatusEnum.CREATED
        ) {
          auth.removeSessionSupport();
          dispatch(setAccessCode(""));
        }
      });
  };
};
