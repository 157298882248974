export interface IColumnsTable {
  id: string;
  label: string;
  view: boolean;
  filter: boolean;
  filterLabel?: string;
  filterOrder?: number;
  checkboxHeader?: ICheckboxHeader;
  align?: string;
  orderBy?: boolean;
}
export enum ColumnsEnum {
  updated = "updated",
  created = "created",
  public_merchant_id = "public_merchant_id",
  merchant_name = "merchant_name",
  country = "country",
  merchant_size = "merchant_size",
  kind = "kind",
  user_name = "user_name",
  user_nameRequest = "user_name_request",
  applicant = "applicant",
  user_alias = "user_alias",
  status = "status",
  button = "button",
  rule_alias = "rule_alias",
  risk_analyst = "risk_analyst",
}

export const defaultHistoricColumnsSelectable: string[] = [
  "modified_date",
  "merchant_name",
  "public_merchant_id",
  "rule_alias",
  "country",
  "merchant_size",
  "kind",
];
export const historicColumnsTable: IColumnsTable[] = [
  {
    id: ColumnsEnum.updated,
    label: "F.últ.modif.",
    view: true,
    filterLabel: "Fecha de última modificación",
    filter: true,
    filterOrder: 1,
  },
  {
    id: ColumnsEnum.created,
    label: "F.Creación",
    filterLabel: "Fecha de creación",
    view: false,
    filterOrder: 8,
    filter: true,
  },
  {
    id: ColumnsEnum.merchant_name,
    label: "Nombre del comercio",
    view: true,
    filter: true,
    orderBy: true,
    filterOrder: 0,
  },
  {
    id: ColumnsEnum.public_merchant_id,
    label: "ID del comercio",
    view: true,
    filter: true,
    filterOrder: 2,
  },
  {
    id: ColumnsEnum.rule_alias,
    label: "Alias de la regla",
    view: true,
    filter: true,
    filterOrder: 3,
  },
  {
    id: ColumnsEnum.country,
    label: "País",
    view: true,
    filter: true,
    filterOrder: 4,
  },
  {
    id: ColumnsEnum.merchant_size,
    label: "Tamaño",
    view: true,
    filter: true,
    align: "center",
    filterOrder: 5,
  },
  {
    id: ColumnsEnum.kind,
    label: "Autenticador",
    view: true,
    filter: true,
    align: "center",
    filterOrder: 6,
  },
  {
    id: ColumnsEnum.risk_analyst,
    label: "Analista de Riesgo",
    view: false,
    filter: true,
    orderBy: true,
    filterOrder: 7,
  },
];

export const columnsTable: IColumnsTable[] = [
  {
    id: "created",
    label: "F. creación",
    view: true,
    filter: false,
  },
  {
    id: "merchant_name",
    label: "Nombre del Comercio",
    view: true,
    filter: false,
  },
  {
    id: "merchant_id",
    label: "ID del Comercio",
    view: true,
    filter: false,
  },
  {
    id: "country",
    label: "País",
    view: false,
    filter: true,
  },
  {
    id: "rule-size",
    label: "Tamaño",
    view: true,
    filter: true,
  },
  {
    id: "authenticator",
    label: "Autenticador",
    view: true,
    filter: false,
  },
  {
    id: "risk_analyst",
    label: "Analista de riesgo ",
    view: true,
    filter: false,
  },
  {
    id: "rule-alias",
    label: "Alias de la regla",
    view: true,
    filter: false,
  },
  {
    id: "initial_status",
    label: "Estatus",
    view: true,
    filter: false,
  },
];
export interface ICheckboxHeader {
  checked: boolean;
  handleChange: (name: string, value: boolean) => void;
  name: string;
}
export enum LabelEnum {
  created = "F. creación",
  name = "Nombre del Comercio",
  public_merchant_id = "Id del comercio",
  country = "País",
  rule_size = "Tamaño",
  authenticator = "Autenticador",
  user_name = "Analista de riesgo",
  user_nameRequest = "Analista de riesgo",
  applicant = "Solicitante",
}
export const columnsTableApproval: IColumnsTable[] = [
  {
    id: ColumnsEnum.created,
    label: LabelEnum.created,
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.merchant_name,
    label: LabelEnum.name,
    view: true,
    filter: false,
    orderBy: true,
  },
  {
    id: ColumnsEnum.public_merchant_id,
    label: LabelEnum.public_merchant_id,
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.country,
    label: LabelEnum.country,
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnum.merchant_size,
    label: LabelEnum.rule_size,
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnum.kind,
    label: LabelEnum.authenticator,
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.user_name,
    label: LabelEnum.user_name,
    view: true,
    filter: false,
    orderBy: true,
  },
];

export const columnsTableRequest: IColumnsTable[] = [
  {
    id: ColumnsEnum.created,
    label: LabelEnum.created,
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.merchant_name,
    label: LabelEnum.name,
    view: true,
    filter: false,
    orderBy: true,
  },
  {
    id: ColumnsEnum.public_merchant_id,
    label: LabelEnum.public_merchant_id,
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.country,
    label: LabelEnum.country,
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnum.merchant_size,
    label: LabelEnum.rule_size,
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnum.kind,
    label: LabelEnum.authenticator,
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.applicant,
    label: LabelEnum.applicant,
    view: true,
    filter: true,
    orderBy: true,
  },
  {
    id: ColumnsEnum.user_nameRequest,
    label: LabelEnum.user_nameRequest,
    view: true,
    filter: false,
    orderBy: true,
  },
];

export const columnsAnalystTable: IColumnsTable[] = [
  {
    id: ColumnsEnum.created,
    label: "F. creación",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.merchant_name,
    label: "Nombre del Comercio",
    view: true,
    filter: false,
    orderBy: true,
  },
  {
    id: ColumnsEnum.public_merchant_id,
    label: "ID del Comercio",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.country,
    label: "País",
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnum.merchant_size,
    label: "Tamaño",
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnum.kind,
    label: "Autenticador",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.user_alias,
    label: "Solicitante",
    view: true,
    filter: false,
    orderBy: true,
  },
  {
    id: ColumnsEnum.status,
    label: "Estado",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnum.button,
    label: "",
    view: true,
    filter: false,
  },
];
