import React from "react";
import { Controller } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";
import { textInputStyles as styles } from "./PercentageInput.styles";
import { PercentageInputProps } from "./PercentageInput.interfaces";
import { physicalPersonRules } from "../../../shared/constants/form_labels_legal_details";
import { DEFAULT_PERCENTAGE } from "../../../shared/constants/shareholder_constants";

export const validatePercentage = (value: string, percentage: string) => {
  const result = parseFloat(value) + parseFloat(percentage);

  return result <= 100;
};

export const showPercentage = (percentage: string): string => {
  const percentageValue: number = DEFAULT_PERCENTAGE - Number(percentage);

  if (percentageValue % 1 === 0) {
    return percentageValue.toString();
  } else {
    return percentageValue.toFixed(2);
  }
};
const PercentageInput: React.FC<PercentageInputProps> = ({
  control,
  name,
  label,
  rules = {},
  isDisable = false,
  percentage,
}: PercentageInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={{
        ...rules,
        ...physicalPersonRules.percentage,
        validate: {
          min: (value) => parseFloat(value) >= 1 || "Porcentaje no válido",
          percentage: (value) =>
            validatePercentage(value, percentage) ||
            "Porcentaje de participación disponible excedido",
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const helperText = error
          ? error.message
          : `Porcentaje de participación disponible: ${showPercentage(
              percentage
            )}%`;

        return (
          <>
            <TextField
              {...field}
              label={label}
              variant="outlined"
              disabled={isDisable}
              fullWidth
              helperText={helperText}
              error={!!error}
              sx={styles.error1}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              data-testid="PercentageInput"
            />
            {}
          </>
        );
      }}
    />
  );
};

export default PercentageInput;
