import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { layoutStepContainerStyles as styles } from "./LayoutStepContainer.styles";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { IFooterOptions } from "../../components/FooterOptions/FooterOptions.interfaces";
import { IconEdit } from "@kushki/connect-ui";
import Subheader from "../../components/SubHeader/Subheader";
import WrappedTabs from "../../components/WrappedTabs/WrappedTabs";
import { IHeaderProps } from "./LayoutStepContainer.interfaces";
import { useLayoutStepContainer } from "./state/useLayoutStepContainer";

export interface ILayoutStepContainer {
  headerProps: IHeaderProps;
  renderComponent: JSX.Element;
  footerProps: IFooterOptions;
  isMassive?: boolean;
}

export const LayoutStepContainer: FC<ILayoutStepContainer> = (
  props: ILayoutStepContainer
) => {
  useLayoutStepContainer();

  return (
    <React.Fragment>
      <Box
        sx={
          props.headerProps.isEdit ? styles.headerStyleEdit : styles.headerStyle
        }
      >
        <Grid container xs={12} spacing={5}>
          <Grid item xs={12}>
            <Subheader
              country={props.headerProps.country}
              id={props.headerProps.id}
              redirectPath={props.headerProps.apiUrl}
              title={props.headerProps.title}
              subtitle={props.headerProps.subTitle}
              iconType={<IconEdit />}
              isEdit={props.headerProps.isEdit}
            />
          </Grid>
          {!props.headerProps.isEdit && (
            <Grid item xs={12}>
              <WrappedTabs tabs={props.headerProps.tabsInfo.tabs} />
            </Grid>
          )}
        </Grid>
      </Box>

      <React.Fragment>{props.renderComponent}</React.Fragment>

      <Box sx={styles.boxFooter}>
        <FooterOptions
          primaryButton={props.footerProps.primaryButton}
          secondaryButton={props.footerProps.secondaryButton}
          isLoading={props.footerProps.isLoading}
          label={props.footerProps.label}
          isEdit={props.footerProps.isEdit}
        />
      </Box>
    </React.Fragment>
  );
};
