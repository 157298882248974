import React from "react";
import { IActionButtonsProps } from "../../shared/interfaces/IActionButtonsProps";
import { Box, Button, Grid } from "@mui/material";
import { LabelEnum } from "../../shared/enums/LabelEnum";
import { useActionButtonState } from "./state/useActionButton";

export const ActionButtons: React.FC<IActionButtonsProps> = (
  props: IActionButtonsProps
) => {
  const {
    getPrimaryLabel,
    isButtonDisabled,
    saveDraftConditionRender,
    saveForm,
  } = useActionButtonState(props);

  const { onSaveDraft, isMassive, onRedirectPath } = props;

  return (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction={"row"}
      >
        {saveDraftConditionRender() && (
          <Box mr={3} pt={4}>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={isMassive ? onRedirectPath : onSaveDraft}
            >
              {isMassive ? LabelEnum.BACK : LabelEnum.BTN_SAVE_DRAFT}
            </Button>
          </Box>
        )}

        <Box pt={4}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={saveForm}
            disabled={isButtonDisabled}
          >
            {getPrimaryLabel()}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
