import { IStyles } from "../../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  container: {
    backgroundColor: "transparent",
    borderWidth: 0,
    paddingY: 0,
    marginY: 0,
  },
};
