import { makeStyles } from "@material-ui/core/styles";

export const configByFileIndexStyles = makeStyles({
  buttonBack: {
    marginRight: "15px",
  },
  marginGrid: {
    marginBottom: "15px",
  },
});
