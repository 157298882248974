import { environment } from "@environments/environment";

export const API_ROUTES = {
  test: `${environment.kushkiUrl}/test`,
};

export enum ROUTES {
  DEVELOPERS = "/developers",
  DEVELOPERS_CREDENTIALS = "/v3/credentials",
  DEVELOPERS_CREDENTIALS_CUSTOMER = "/v3/credentials/customer",
  WEBHOOKS = "/webhook",
  NOTIFICATIONS = "/webhook/notifications",
}
