import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const titleComponentStyles = createNamedStyles({
  titleInfo: {
    marginBottom: "40px",
  },
  titleSkeleton: {
    width:400,
    height:100
  },

});
