import React from "react";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import FormControl from "@components/FormControl/FormControl";
import { InputTypeEnum } from "@shared/enum/InputTypeEnum";
import {
  InputLabelForm,
  InputNameForm,
} from "@shared/constants/AlarmConfigConstants";
import { RULES } from "@shared/constants/formRules";
import { OPTION } from "@shared/constants/selectOptions";
import { alarmConfigFormStyles as styles } from "@components/AlarmConfigForm/AlarmConfigForm.styles";
import VariablesWrapper from "@components/VariablesWrapper/VariablesWrapper";
import { useAlarmConfigForm } from "@components/AlarmConfigForm/state/useAlarmConfigForm";
import { IconCircleWarn, IconPlus } from "@kushki/connect-ui";
import { IAlarmConfigFormProps } from "@components/AlarmConfigForm/AlarmConfigForm.interfaces";
import SkeletonEditForm from "@components/SkeletonEditForm/SkeletonEditForm";
import { TooltipText } from "@shared/enum/TooltipEnum";

const AlarmConfigForm: React.FC<IAlarmConfigFormProps> = ({
  isDisabledAlias,
  isLoadingEditForm,
}: IAlarmConfigFormProps) => {
  const {
    variablesFields,
    watch,
    options,
    addVariablesSection,
    disableFieldOptions,
    handleBehaviorSections,
    disabledButton,
    showTooltip,
  } = useAlarmConfigForm();

  return (
    <Box sx={styles.container}>
      {isLoadingEditForm ? (
        <SkeletonEditForm />
      ) : (
        <>
          <Box sx={styles.headerWrapper}>
            <Typography variant={"subtitle2"} sx={styles.subtitle}>
              Información de la alarma
            </Typography>
            {showTooltip && (
              <Tooltip title={TooltipText.Deviation} placement={"top"} arrow>
                <Box>
                  <IconCircleWarn />
                </Box>
              </Tooltip>
            )}
          </Box>

          <Box sx={styles.alarmInfoInputs}>
            <FormControl
              inputType={InputTypeEnum.TEXT}
              name={InputNameForm.ALIAS}
              label={InputLabelForm.ALIAS}
              rules={RULES.alias}
              disabled={isDisabledAlias}
            />
            <FormControl
              inputType={InputTypeEnum.SELECT}
              name={InputNameForm.FREQUENCY}
              label={InputLabelForm.FREQUENCY}
              options={OPTION.frequency}
              rules={RULES.frequency}
            />
          </Box>

          <Divider sx={styles.divider} />

          <Typography variant={"subtitle2"} sx={styles.subtitle}>
            Variables
          </Typography>
          <Box>
            <VariablesWrapper
              variablesFields={variablesFields}
              options={options}
              watch={watch}
              disableFieldOptions={disableFieldOptions}
              handleBehaviorSections={handleBehaviorSections}
            />
            <Button
              sx={styles.addButton}
              startIcon={<IconPlus />}
              onClick={addVariablesSection}
              disabled={disabledButton}
              variant={"text"}
            >
              Agregar variable
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AlarmConfigForm;
