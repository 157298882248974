import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import { IErrorUpload, IFileUpload } from "../FileUploadContainer.interfaces";
import { IUseFileUpload } from "./useFileUploadContainer.interfaces";
import {
  DragTypeEnum,
  FileStatusEnum,
  FileValidation,
  MessageError,
} from "../../../shared/enum/FileUploadEnum";
import {
  errorUploadingFileInit,
  fileSelectedInit,
} from "../../../shared/constants/FileUpload";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  setBase64File,
  setFileValidated,
  setIsLoadingSocket,
  setNotification,
} from "../../../store/reducers/massiveUserForm/massiveUserForm.slice";
import { useDispatch } from "react-redux";
import { validateFileUserSocket } from "../../../store/thunks/massiveUserForm/massiveUserForm.thunk";
import { defaultTo, isEmpty } from "lodash";
import { uploadingFileDelay } from "../../../shared/utils/uploadFileDelay";
import { buildNotification } from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enum/SnackbarEnum";
import { getBase64File } from "../../../shared/utils/getBase64File";

export const useFileUploadContainer = (): IUseFileUpload => {
  const [dragActive, setDragActive] = React.useState(false);
  const [fileSelected, setFileSelected] =
    useState<IFileUpload>(fileSelectedInit);
  const [fileStatus, setFileStatus] = useState<string>(FileStatusEnum.DEFAULT);
  const [errorUploadingFile, setErrorUploadingFile] = useState<IErrorUpload>(
    errorUploadingFileInit
  );
  const [showValidateBtn, setShowValidateBtn] = useState(false);

  const dispatch = useAppDispatch();
  const wsDispatch = useDispatch();
  const { isLoadingSocket, base64File, fileValidated } = useAppSelector(
    (state) => state.massiveUserForm
  );

  const changeUploadFileProcess = async (file: File) => {
    setErrorUploadingFile(errorUploadingFileInit);
    setFileStatus(FileStatusEnum.UPLOADING);
    await uploadingFileDelay(1000);

    const type = file.type.split("/")[1];
    const size = file.size / 1000000;
    const name = file.name;

    if (type !== FileValidation.TYPE) {
      setErrorUploadingFile({ errorFile: true, message: MessageError.TYPE });

      return setFileStatus(FileStatusEnum.DEFAULT);
    } else if (size > FileValidation.MAX_SIZE) {
      setErrorUploadingFile({
        errorFile: true,
        message: MessageError.MAX_SIZE,
      });

      return setFileStatus(FileStatusEnum.DEFAULT);
    } else if (!name.includes(FileValidation.NAME)) {
      setErrorUploadingFile({ errorFile: true, message: MessageError.NAME });

      return setFileStatus(FileStatusEnum.DEFAULT);
    }

    setFileSelected({ name, size, type });
    getBase64File(file, dispatch);
    setFileStatus(FileStatusEnum.WITH_FILE);
    setErrorUploadingFile({ errorFile: false, message: "" });
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || !e.target.files[0]) {
      setFileStatus(FileStatusEnum.DEFAULT);
      dispatch(
        setNotification(
          buildNotification(NotificationTypeEnum.FAILED, {
            color: "danger",
            message: MessageError.SNACK_BAR_UPLUOAD,
            variant: "simple",
            withIcon: false,
          })
        )
      );

      return setFileSelected(fileSelectedInit);
    }
    const file = e.target.files[0];

    await changeUploadFileProcess(file);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
      setFileStatus(FileStatusEnum.DEFAULT);
      dispatch(
        setNotification(
          buildNotification(NotificationTypeEnum.FAILED, {
            color: "danger",
            message: MessageError.SNACK_BAR_UPLUOAD,
            variant: "simple",
            withIcon: false,
          })
        )
      );

      return setFileSelected(fileSelectedInit);
    }
    const file = e.dataTransfer.files[0];

    await changeUploadFileProcess(file);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === DragTypeEnum.ENTER || e.type === DragTypeEnum.OVER) {
      setDragActive(true);
    } else if (e.type === DragTypeEnum.LEAVE) {
      setDragActive(false);
    }
  };

  const handleCloseFile = () => {
    setFileStatus(FileStatusEnum.DEFAULT);
    dispatch(setBase64File(""));
    dispatch(setIsLoadingSocket(null));
    dispatch(setFileValidated({}));
  };
  const handleValidateFile = () => {
    wsDispatch(validateFileUserSocket(base64File));
    setFileStatus(FileStatusEnum.VALIDATING);
  };

  useEffect(() => {
    setShowValidateBtn(
      errorUploadingFile.errorFile === false &&
        fileStatus === FileStatusEnum.WITH_FILE &&
        isEmpty(fileValidated)
    );
  }, [fileStatus, fileValidated, errorUploadingFile]);

  useEffect(() => {
    if (isLoadingSocket === false) setFileStatus(FileStatusEnum.WITH_FILE);
  }, [isLoadingSocket]);

  useEffect(() => {
    if (defaultTo(fileValidated.total_error, 0) > 0)
      setErrorUploadingFile({
        errorFile: true,
        message: MessageError.VALIDATION,
      });
  }, [fileValidated]);

  return {
    handlers: {
      handleChange,
      handleCloseFile,
      handleDrag,
      handleDrop,
      handleValidateFile,
    },
    state: {
      dragActive,
      errorUploadingFile,
      fileSelected,
      fileStatus,
      showTotalRecords: !isEmpty(fileValidated),
      showValidateBtn,
    },
  };
};
