import { Button, Badge, Typography } from "@material-ui/core";
import { BoxFilter } from "../BoxFilter/BoxFilter";
import { Filter } from "react-feather";
import { IFilter } from "../../containers/RefundIndex/state/useRefundIndexState.interfaces";
import { IFilterOptionsProps } from "../DashboardTabs/DashboardTabs.interfaces";
import { IOptionFilter } from "../../../types/option_filter";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";
import { RangeAmount } from "../../../types/range_amount";
import React from "react";
import { useStyles } from "./FilterOptions.styles";

export const FilterOptions: React.FC<IFilterOptionsProps> = (
  props: IFilterOptionsProps
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    const filters: IFilter = {};

    props.filterProps.filters.map((filter) => {
      filter.options.map((option) => {
        filters[option.key] = false;
      });
    });
    props.filterProps.handleSetFilters(filters);
    setAnchorEl(null);
  };

  const mapFilterResponse = (filters: IOptionFilter[]) => {
    const result: IFilter = {};

    filters.map((option) => {
      option.options.map((item) => {
        result[item.key] = item.selected;
      });
    });

    return result;
  };

  const populateOptionChips = (
    options: IOptionFilter[],
    // @ts-ignore
    handleApplyFilters: (optionsChip: any, rangeAmount?: RangeAmount) => void,
    rangeAmount?: RangeAmount | IRangeAmount
  ) => {
    props.filterProps.handleSetFilters(
      mapFilterResponse(options),
      rangeAmount as RangeAmount
    );
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        className={classes.filterButton}
        aria-describedby={id}
        size={"medium"}
        startIcon={<Filter style={{ color: "#023365" }} size={20} />}
        variant="outlined"
        onClick={handleClick}
      >
        <Badge
          color="secondary"
          style={{ color: "#023365", paddingBottom: "5px", paddingTop: "5px" }}
        >
          <Typography variant="body1">Filtro</Typography>
        </Badge>
      </Button>
      <BoxFilter
        idPopover={id}
        openPopover={open}
        anchorEl={anchorEl}
        populateOptionChips={populateOptionChips}
        filters={props.filterProps.filters}
        rangeAmount={props.filterProps.rangeAmount}
        handleClearFilters={handleClearFilters}
        handleClosePopover={handleClose}
        handleAction={props.setActionFilter}
      />
    </React.Fragment>
  );
};
