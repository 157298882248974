import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { EntityName } from "../../../shared/enums/entityName";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { updateForm } from "../../../store/actions/billing/billing.actions";
import { BillingForm } from "../../../store/interfaces/billing.interfaces";
import {
  UseBillingForm,
  UseBillingFormProps,
} from "./useBillingForm.interfaces";
import { get, isEmpty } from "lodash";
import { ConfigIdEnum } from "../../../shared/constants/node_config_enums";

export const useBillingForm = ({
  control,
}: UseBillingFormProps): UseBillingForm => {
  const billingFormState = useWatch({ control });
  const dispatch = useAppDispatch();
  const { nodeInfo } = useAppSelector((state) => state.app);
  const [isCentralized, setIsCentralized] = useState<boolean>(false);

  useEffect(() => {
    dispatch(updateForm(billingFormState as BillingForm));
  }, [billingFormState]);

  useEffect(() => {
    if (!isEmpty(nodeInfo)) {
      const centralizedNodesIdList = get(nodeInfo, "configs", []).filter(
        (info) => info.configuration === ConfigIdEnum.CN003
      );
      const centralizedNodesId = get(
        centralizedNodesIdList,
        "[0].centralizedNodesId"
      );

      setIsCentralized(
        !isEmpty(centralizedNodesId) &&
          get(nodeInfo, "entityName") == EntityName.BRANCH
      );
    }
  }, [nodeInfo]);

  const getShowBillingMode = () =>
    (get(nodeInfo, "entityName") === EntityName.CUSTOMER ||
      get(nodeInfo, "entityName") === EntityName.BRANCH) &&
    get(nodeInfo, "generalInfo.country") === CountriesEnum.MEXICO;

  return {
    country: get(nodeInfo, "generalInfo.country") as CountriesEnum,
    isCentralized,
    showBillingMode: getShowBillingMode(),
  };
};
