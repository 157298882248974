import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { UploadSection } from "../UploadSection/UploadSection";
import { uploadFileDispersionStyles } from "./UploadFileDispersion.styles";
import { useUploadFileDispersionState } from "./state/useUploadFileDispersionState";
import { FileLoadingModal } from "../FileLoadingModal/FileLoadingModal";
import { FileValidationResult } from "../FileValidationResult/FileValidationResult";

export const UploadFileDispersion = () => {
  const classes = uploadFileDispersionStyles();
  const {
    isChooseInputFile,
    isChooseUafFile,
    handleOnChangeInputFile,
    handleOnChangeUafFile,
    inputFileName,
    uafFileName,
    isLoadInputFileSuccessful,
    isLoadUafFileSuccessful,
    isProcessButtonEnabled,
    openLoadingModal,
    validationResults,
    handleProcessFile,
    isSuccessInputFileSize,
    isSuccessInputFormatFile,
    isSuccessUafFileSize,
    isSuccessUafFormatFile,
  } = useUploadFileDispersionState();

  return (
    <Grid container direction={"column"}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {LabelsDispersion.TITLE_FILE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UploadSection
          isChooseFile={isChooseInputFile}
          buttonId={"contained-button-input-file"}
          title={LabelsDispersion.FILE_INPUT}
          buttonText={LabelsDispersion.CHOOSE_FILE}
          fileStatus={LabelsDispersion.FILE_NOT_SELECTED}
          buttonDescription={LabelsDispersion.BUTTON_DESCRIPTION}
          handleOnChange={handleOnChangeInputFile}
          fileName={inputFileName}
          isFileLoadSuccessful={isLoadInputFileSuccessful}
          isSuccessFileSize={isSuccessInputFileSize}
          isSuccessFormatFile={isSuccessInputFormatFile}
        />
      </Grid>
      <Grid item xs={12} className={classes.secondGrid}>
        <UploadSection
          isChooseFile={isChooseUafFile}
          buttonId={"contained-button-uaf-file"}
          title={LabelsDispersion.FILE_UAF}
          buttonText={LabelsDispersion.CHOOSE_FILE}
          fileStatus={LabelsDispersion.FILE_NOT_SELECTED}
          buttonDescription={LabelsDispersion.BUTTON_DESCRIPTION}
          handleOnChange={handleOnChangeUafFile}
          fileName={uafFileName}
          isFileLoadSuccessful={isLoadUafFileSuccessful}
          isSuccessFileSize={isSuccessUafFileSize}
          isSuccessFormatFile={isSuccessUafFormatFile}
        />
      </Grid>
      <Grid container item xs={8} direction={"row"} justifyContent={"flex-end"}>
        <Button
          variant={"contained"}
          color={"primary"}
          className={
            !isProcessButtonEnabled
              ? classes.processButtonDisabled
              : classes.processButton
          }
          onClick={() => {
            isProcessButtonEnabled && handleProcessFile();
          }}
        >
          {LabelsDispersion.PROCESS}
        </Button>
      </Grid>
      <FileLoadingModal openLoadingModal={openLoadingModal} />
      <FileValidationResult validationResults={validationResults} />
    </Grid>
  );
};
