import { BankAccountInfo } from "../../../types/bank_account_info";
import { MerchantData } from "../../../types/merchant_data";
import { MerchantResponse } from "../../../types/merchant_response";
import { Configs, NodeInfoResponse } from "../../../types/node_info_response";
import { defaultTo, get, has, isEmpty, isEqual, pickBy } from "lodash";
import { PostDispersionConfigRequest } from "../../../types/post_dispersion_config_request";
import { PostRatesRequest } from "../../../types/post_rates_request";
import { IBankAccount } from "../../store/interfaces/dispersion.interfaces";
import {
  AccountOrder,
  AccountType,
} from "../constants/dispersions/account_bank_constants";
import { getCatalog } from "../constants/dispersions/dispersions";
import { CatalogsEnum } from "../enums/CatalogsEnum";
import { CountriesEnum } from "../enums/countriesEnum";
import { CurrencyEnum } from "../enums/currencyEnum";
import { ClientType, ConfigIdDispersion } from "../enums/dispersionsEnum";
import { FetchStateEnum } from "../enums/fetchStateEnum";
import { FrequencyEnum } from "../enums/frequencyEnum";
import { MerchantTypeEnum } from "../enums/merchantTypeEnum";
import { SemaphoreStatusEnum } from "../enums/statusEnum";
import {
  IDispersionConfigPayload,
  IDispersionMerchantNodePayload,
  IDispersionRatesPayload,
} from "../interfaces/dispersion";
import { INaturalPerson } from "../interfaces/natural_person";
import { sortBanksAccount, validateIfHasMainAccount } from "./bankAccountUtils";
import { removeAccents } from "./parseData/parse_data";
import { getMerchantNodeInfo } from "../../store/thunks/app/app.thunks";
import {
  disableContinueButton,
  disableSaveButton,
  editBankAccount,
  editBeneficiaryName,
  updateConstitutionalCountry,
  updateFrequencyAndFraudForm,
} from "../../store/actions/dispersion/dispersion.actions";
import { BillingCoreInfoRequest } from "../../../types/billing_core_info_request_schema";

const getConfigCn001 = (nodeInfo: NodeInfoResponse) =>
  nodeInfo.configs.find(
    (c) => c.configuration === ConfigIdDispersion.CN001 && !isEmpty(c.value)
  );

const getConfigCn004 = (nodeInfo: NodeInfoResponse) =>
  nodeInfo.configs.find(
    (c) => c.configuration === ConfigIdDispersion.CN004 && !isEmpty(c.value)
  );

export const getConfigsDispersion = (nodeInfo: NodeInfoResponse) => ({
  configCn001: getConfigCn001(nodeInfo),
  configCn004: getConfigCn004(nodeInfo),
});

export const isBasicDataResponse = (response: MerchantResponse) =>
  has(response, "taxId") && !has(response, "dispersion");

export const isDispersionDataResponse = (response: MerchantResponse) =>
  has(response, "dispersion") && !has(response, "taxId");

export const getBankAccounts = (
  merchantNode: MerchantResponse
): IBankAccount[] => {
  const accounts: BankAccountInfo[] = get(
    merchantNode,
    "dispersion.accountInfo",
    []
  );
  const bankAccounts: IBankAccount[] = accounts.map((acc) => {
    return {
      ...acc,
      ...(acc.bankName && { bankName: acc.bankName }),
      accountNumber: defaultTo(acc.accountNumber, ""),
      accountOrder: defaultTo(acc.accountOrder, ""),
      accountType: defaultTo(acc.accountType, ""),
      country: removeAccents(acc.bankOfOrigin),
      currency: defaultTo(acc.currency, ""),
      interbankAccountCode: defaultTo(acc.interbankAccountCode, ""),
      nameBeneficiary: defaultTo(acc.nameBeneficiary, ""),
    };
  });

  return sortBanksAccount(bankAccounts);
};

export const getConstitutionalCountry = (nodeInfo: NodeInfoResponse) => {
  const country = get(nodeInfo, "generalInfo.constitutionalCountry");

  return !isEmpty(country) ? country : CountriesEnum.MEXICO;
};

export const getBeneficiaryName = (merchantNode: MerchantResponse): string => {
  const emptyName = "";

  if (merchantNode.merchantType === MerchantTypeEnum.FISICO) {
    const naturalPerson: INaturalPerson = get(
      merchantNode,
      "naturalPerson",
      {}
    );

    return isEmpty(naturalPerson)
      ? emptyName
      : `${defaultTo(naturalPerson.name, "").trim()} ${defaultTo(
          naturalPerson.lastname,
          ""
        ).trim()}`.trim();
  }

  if (merchantNode.merchantType === MerchantTypeEnum.MORAL)
    return defaultTo(merchantNode.socialReason, emptyName);

  return emptyName;
};

const getBankAccountsToSave = (
  bankAccounts: IBankAccount[]
): BankAccountInfo[] => {
  const bankAccountsOrdered = sortBanksAccount(bankAccounts);

  return bankAccountsOrdered.map((acc) => {
    const accountToSave = pickBy<BankAccountInfo>({
      ...acc,
      accountOrder: acc.accountOrder as AccountOrder,
      accountType: acc.accountType as AccountType,
      bankOfOrigin: acc.country,
    });

    delete accountToSave["country"];

    return accountToSave;
  });
};

export const getDispersionMerchantNodePayload = (
  data: IDispersionMerchantNodePayload
): MerchantData => ({
  configId: ConfigIdDispersion.CN004,
  constitutionalCountry: get(data, "constitutionalCountry", ""),
  country: data.country,
  dispersion: {
    accountInfo: getBankAccountsToSave(data.bankAccounts),
    enable4x1000: data.dispersionForm.enable4x1000,
    isExclusive:
      data.country == CountriesEnum.CHILE
        ? get(data, "isExclusive", false)
        : undefined,
    keepCommission: data.dispersionForm.keepCommission,
  },
  dispersionFrequency: data.dispersionForm.frequency,
  publicMerchantId: data.publicMerchantId,
});

export const getDispersionConfigPayload = (
  data: IDispersionConfigPayload
): PostDispersionConfigRequest => ({
  constitutionalCountry: get(data, "constitutionalCountry", ""),
  country: data.country,
  dispersionConfigInfo: {
    accountInfo: getBankAccountsToSave(data.bankAccounts),
    documentType: data.documentType,
    enable4x1000: get(data.dispersionForm, "enable4x1000"),
    frequency: data.dispersionForm.frequency as FrequencyEnum,
    isExclusive: get(data, "isExclusive"),
    keepCommission: data.dispersionForm.keepCommission,
  },
  publicMerchantId: data.publicMerchantId,
  taxId: data.taxId,
});

export const getRatesPayload = (
  data: IDispersionRatesPayload
): PostRatesRequest => {
  const principalAccount = data.bankAccounts.find(
    (b) => b.accountOrder === AccountOrder.PRINCIPAL
  );
  const keepFraud = data.dispersionForm.keepFraud;

  const currency = principalAccount
    ? (principalAccount.currency as CurrencyEnum)
    : (getCatalog(data.constitutionalCountry, CatalogsEnum.Currency)[0]
        .value as CurrencyEnum);

  return {
    constitutionalCountry: get(data, "constitutionalCountry", ""),
    country: data.country,
    defaultCurrency: currency,
    isB2C: data.clientType === ClientType.B2C,
    keepFraud,
    merchantId: data.publicMerchantId,
    specialTaxpayer: false,
    ...(keepFraud && {
      fraudPercentage: data.dispersionForm.fraudPercentage,
      retentionPeriod: data.dispersionForm.retentionPeriod,
    }),
  };
};

export const getHierarchyNodeConfigPayload = (
  nodeInfo: NodeInfoResponse,
  configResponse: Configs | undefined,
  publicMerchantId: string,
  accountBanks: IBankAccount[]
): BillingCoreInfoRequest => ({
  configs: [
    {
      ...configResponse,
      centralizedNodesId: undefined,
      status: validateIfHasMainAccount(accountBanks)
        ? SemaphoreStatusEnum.COMPLETE
        : SemaphoreStatusEnum.PENDING,
      updatedAt: Date.now(),
      updatedBy: localStorage.getItem("username") ?? "backoffice",
      value: publicMerchantId,
    },
  ],
  nodeId: get(nodeInfo, "nodeId", ""),
});

const isStatesPending = (states: (string | undefined)[]) =>
  !states.some((state) => state === FetchStateEnum.FAILED) &&
  states.some((state) => state === FetchStateEnum.PENDING);

const isStatesFailed = (states: (string | undefined)[]) =>
  states.some((state) => state === FetchStateEnum.FAILED);

const isStatesSuccess = (states: (string | undefined)[]) =>
  states.every((state) => state === FetchStateEnum.SUCCESS);

export const getStatesResume = (states: (string | undefined)[]) => {
  return {
    isFailed: isStatesFailed(states),
    isPending: isStatesPending(states),
    isSuccess: isStatesSuccess(states),
  };
};

export const requestMerchantNodeInfo = (
  configId: string,
  merchantId: string,
  dispatch: any
) => {
  dispatch(
    getMerchantNodeInfo({
      configId,
      publicMerchantId: merchantId,
    })
  );
};

export const handleDispatchBasicDataResponse = async (
  response: MerchantResponse,
  dispatch: any
) => {
  await dispatch(editBeneficiaryName(getBeneficiaryName(response)));
};

export const handleDispatchDispersionResponse = async (
  response: MerchantResponse,
  frequencyAndFraudForm: any,
  dispatch: any
) => {
  const { dispersionFrequency, dispersion } = response;

  dispatch(
    updateFrequencyAndFraudForm({
      ...frequencyAndFraudForm,
      ...(!isEmpty(dispersionFrequency) && {
        frequency: dispersionFrequency,
      }),
      ...(has(dispersion, "keepCommission") && {
        keepCommission: dispersion?.keepCommission,
      }),
      ...(has(dispersion, "isExclusive") && {
        isExclusive: get(dispersion, "isExclusive"),
      }),
      ...(has(dispersion, "enable4x1000") && {
        enable4x1000: get(dispersion, "enable4x1000", false).toString(),
      }),
    })
  );
  await dispatch(editBankAccount(getBankAccounts(response)));
};

export const getCommonPayloadData = (
  bankAccounts: IBankAccount[],
  nodeInfo: NodeInfoResponse | undefined,
  frequencyAndFraudForm: any,
  publicMerchantId: string
) => {
  return {
    bankAccounts,
    constitutionalCountry: get(
      nodeInfo,
      "generalInfo.constitutionalCountry",
      ""
    ),
    country: get(nodeInfo, "generalInfo.country", ""),
    dispersionForm: frequencyAndFraudForm,
    publicMerchantId: publicMerchantId,
  };
};

export const handleUseEffectMerchantNodeInfo = (
  merchantNodeInfo: any,
  setMerchantBasicData: any,
  handleBasicDataResponse: any,
  handleDispersionDataResponse: any,
  setIsExclusiveMassive?: (payload: boolean) => void
) => {
  if (!merchantNodeInfo) return;

  if (isBasicDataResponse(merchantNodeInfo)) {
    setMerchantBasicData(merchantNodeInfo);
    handleBasicDataResponse(merchantNodeInfo).then();
  }

  if (isDispersionDataResponse(merchantNodeInfo)) {
    handleDispersionDataResponse(merchantNodeInfo).then();
    setIsExclusiveMassive &&
      setIsExclusiveMassive(
        get(merchantNodeInfo.dispersion, "isExclusive", false)
      );
  }
};

export const handleUseEffectNodeInfo = (
  nodeInfo: any,
  requestDataFromNodeInfo: any,
  dispatch: any
) => {
  if (isEmpty(nodeInfo)) return;

  dispatch(updateConstitutionalCountry(getConstitutionalCountry(nodeInfo!)));
  requestDataFromNodeInfo(nodeInfo!);
};

export const handleUseEffectIsAllDataLoaded = (
  loadingForm: any,
  loadingRatesConfig: any,
  isBankDataUpdated: any,
  isBeneficiaryNameUpdated: any,
  isValid: any,
  bankAccounts: any,
  dispatch: any
) => {
  const isAllDataLoaded =
    !(loadingForm && loadingRatesConfig) &&
    isBankDataUpdated &&
    isBeneficiaryNameUpdated;

  dispatch(
    disableContinueButton(
      !(isAllDataLoaded && isValid && validateIfHasMainAccount(bankAccounts))
    )
  );
  dispatch(disableSaveButton(!(isAllDataLoaded && isValid)));
};

export const handleUseEffectDispersionFormLoaded = (
  loadingForm: any,
  loadingRatesConfig: any,
  frequencyAndFraudForm: any,
  reset: any
) => {
  const isDispersionFormDataLoaded = !(loadingForm && loadingRatesConfig);
  const values = {
    enable4x1000: frequencyAndFraudForm.enable4x1000.toString(),
    fraudPercentage: get(
      frequencyAndFraudForm,
      "fraudPercentage",
      ""
    ).toString(),
    frequency: get(frequencyAndFraudForm, "frequency", FrequencyEnum.NONE),
    isExclusive: get(frequencyAndFraudForm, "isExclusive", "").toString(),
    keepCommission: frequencyAndFraudForm.keepCommission.toString(),
    keepFraud: frequencyAndFraudForm.keepFraud.toString(),
    retentionPeriod: get(
      frequencyAndFraudForm,
      "retentionPeriod",
      ""
    ).toString(),
  };

  if (isDispersionFormDataLoaded) {
    reset(values);
  }
};

export const getIsExclusiveValue = (
  constitutionalCountry: string,
  isExclusive: boolean
): boolean =>
  isEqual(constitutionalCountry, CountriesEnum.CHILE) ? isExclusive : false;

export const getEnable4x1000 = (
  constitutionalCountry: string,
  enable4x1000: boolean
) =>
  isEqual(constitutionalCountry, CountriesEnum.COLOMBIA) ? enable4x1000 : false;
