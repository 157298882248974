import React from "react";
import { Grid, TextField, Typography, MenuItem } from "@mui/material";
import { IOptionSelection } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { get } from "lodash";
import { useSelectComponentSectionState } from "./state/useSelectComponentSectionState";

export interface ISelectComponentProps {
  placeHolder: string;
  items: IOptionSelection[];
  message: string;
  selectedValue: string;
  setTextValue: (value: string[]) => void;
  isDisable: boolean;
}
export const SelectComponentSection: React.FC<ISelectComponentProps> = (
  props: ISelectComponentProps
) => {
  const { selectValue, handleChange } = useSelectComponentSectionState({
    selectedValue: props.selectedValue,
    setTextValue: props.setTextValue,
  });

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant={"subtitle2"}>{props.message}</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={selectValue}
          onChange={handleChange}
          fullWidth
          label={props.placeHolder}
          select
          sx={{ mt: 1 }}
          disabled={props.isDisable}
        >
          {props.items.map((item: IOptionSelection) => {
            return (
              <MenuItem
                key={`item-${get(item, "label", "")}`}
                value={get(item, "value", "")}
              >
                {get(item, "label", "")}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    </Grid>
  );
};
