import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const ModalHeaderStyles: IStyles = {
  boxFlag: {
    alignSelf: "center",
    paddingLeft: "8px",
    svg: {
      height: "16px",
      width: "16px",
    },
  },
  centerPaddingItems: {
    alignSelf: "center",
    paddingBottom: "8px",
    paddingLeft: "8px",
    paddingTop: "8px",
  },
  flexDisplay: {
    display: "flex",
  },
  leftBox: {
    display: "grid",
  },
  leftBoxMobile: {
    display: "grid",
    marginBottom: "16px",
  },
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  mainBoxMobile: {
    display: "block",
    width: "100%",
  },
  rightBox: {
    alignItems: "center",
    display: "flex",
  },
  tag: {
    height: "21px",
    marginTop: "12px",
  },
  normalButton: {},
  typographyCountry: {
    alignSelf: "center",
    paddingBottom: "8px",
    paddingLeft: "8px",
    paddingTop: "8px",
  },
  centerItems: {
    alignSelf: "center",
  },
  boxButton: {
    paddingRight: "24px",
  },
  boxButtonMobile: {
    paddingRight: "16px",
  },
  empty: {},
  oneButton: {
    width: "100%",
  },
  regularButton: {
    minWidth: "150px",
    width: "fit-content",
  },
  contentDescription: {
    padding: "24px 0",
    margin: 0,
    width: "70%",
  },
  boxButtons: {
    display: "flex",
    alignItems: "center",
    gap: 3,
    justifyContent: "flex-end",
  },
  boxTitle: {
    flexDirection: "column",
    gap: 1,
    display: "flex",
    width: "100%",
  },
  boxContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 3,
    display: "flex",
    width: "100%",
  },
};

export { ModalHeaderStyles };
