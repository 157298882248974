import { CountriesEnum } from "./countries";

export enum CurrencyEnum {
  COP = "COP",
  USD = "USD",
  PEN = "PEN",
  UF = "UF",
  MXN = "MXN",
  BRL = "BRL",
  PAB = "PAB",
  CRC = "CRC",
  CLP = "CLP",
  NIO = "NIO",
  HNL = "HNL",
  GTQ = "GTQ",
}

export const getCurrencyByCountry: Record<string, CurrencyEnum> = {
  [CountriesEnum.EL_SALVADOR]: CurrencyEnum.USD,
  [CountriesEnum.ECUADOR]: CurrencyEnum.USD,
  [CountriesEnum.COLOMBIA]: CurrencyEnum.COP,
  [CountriesEnum.PERU]: CurrencyEnum.PEN,
  [CountriesEnum.MEXICO]: CurrencyEnum.MXN,
  [CountriesEnum.BRAZIL]: CurrencyEnum.BRL,
  [CountriesEnum.UNITED_STATES]: CurrencyEnum.USD,
  [CountriesEnum.PANAMA]: CurrencyEnum.PAB,
  [CountriesEnum.COSTA_RICA]: CurrencyEnum.CRC,
  [CountriesEnum.CHILE]: CurrencyEnum.CLP,
  [CountriesEnum.NICARAGUA]: CurrencyEnum.NIO,
  [CountriesEnum.HONDURAS]: CurrencyEnum.HNL,
  [CountriesEnum.GUATEMALA]: CurrencyEnum.GTQ,
};
