import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { environment } from "./environments/environment";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    authority: environment.authority,
    clientId: environment.clientId,
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authRequest: RedirectRequest = {
  scopes: [environment.scope],
};
