import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { genericBreadcrumsStyles as styles } from "./GenericBreadcrums.styles";
import { IGenericBreadcrumbProps } from "@components/GenericBreadcrumb/GenericBreadcrumbs.interfaces";
import { ColorConstants } from "@shared/constants/ColorConstants";

export const GenericBreadcrumb: React.FC<IGenericBreadcrumbProps> = ({
  breadcrumbItems,
  lastItem,
}: IGenericBreadcrumbProps) => {
  return (
    <Breadcrumbs color={ColorConstants.GRAY} aria-label="breadcrumb">
      {breadcrumbItems.map(({ color = ColorConstants.GRAY, url, label }, i) => (
        <Link key={`bcItem-${i}`} underline={"none"} color={color} href={url}>
          {label}
        </Link>
      ))}
      {!isEmpty(lastItem) && (
        <Typography sx={styles.lastSection}>{lastItem}</Typography>
      )}
    </Breadcrumbs>
  );
};
