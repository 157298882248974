import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainContainerStyles = createNamedStyles({
  boxContainer: {
    width: 1,
    height: "100%",
    position: "fixed",
    display: "flex",
  },
  boxSidebar: {
    width: {
      md: "10%",
      sm: "30%",
    },
    height: 1,
    bgcolor: {
      md: "primary.light",
      sm: "secondary.main",
    },
  },
  box: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    mb: "20vh",
  },
  boxHeader: {
    width: 1,
    heigth: {
      md: "10%",
      sm: "30%",
    },
    bgcolor: {
      md: "#B6C5EE",
      sm: "lightskyblue",
    },
    display: "flex",
    flexDirection: {
      sm: "column",
    },
  },
  boxContent: {
    height: "90%",
  },
  boxConfirmModal: {
    display: "flex",
    justifyContent: "center",
  },
  boxValidateModal: {
    display: "flex",
    justifyContent: "center",
  },
  boxWrapper: {
    display: "flex",
    height: 1,
    justifyContent: "center",
    width: 1,
  },
});
