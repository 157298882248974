import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { dispersionTabsStyles } from "./DispersionTabs.styles";
import React, { PropsWithChildren } from "react";
import { DispersionTabsProps } from "../../shared/infrastructure/interfaces/DispersionTabs.interface";
import { DispersionTabEnum } from "../../shared/infrastructure/constants/DispersionTabEnum";

interface TabPanelProps {
  children: JSX.Element;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DispersionTabs: React.FC<DispersionTabsProps> = (
  dispersionTabsProps: PropsWithChildren<DispersionTabsProps>
) => {
  const classes = dispersionTabsStyles();

  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    dispersionTabsProps.handleSetValue(newValue);
  };

  return (
    <Grid container className={classes.main}>
      <Grid item md={12}>
        <Box className={classes.box}>
          <Tabs
            data-testid="tabs"
            onChange={handleTabsChange}
            value={dispersionTabsProps.value}
            variant="fullWidth"
            indicatorColor={"primary"}
          >
            <Tab
              {...tabProps(DispersionTabEnum.REQUEST_DISPERSION_INDEX)}
              label="Solicitar dispersión"
              className={classes.tab}
            />
            <Tab
              data-testid="Dispersiones procesadas"
              {...tabProps(DispersionTabEnum.PROCESSOR_DISPERSION_INDEX)}
              label="Solicitudes procesadas"
              className={classes.tab}
            />
          </Tabs>
          <TabPanel
            value={dispersionTabsProps.value}
            index={DispersionTabEnum.REQUEST_DISPERSION_INDEX}
          >
            {dispersionTabsProps.requestDispersionComponent}
          </TabPanel>
          <TabPanel
            value={dispersionTabsProps.value}
            index={DispersionTabEnum.PROCESSOR_DISPERSION_INDEX}
          >
            {dispersionTabsProps.dispersionRequestedComponent}
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DispersionTabs;
