import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";

export const GenericLoaderStyles: IStyles = {
  circularColor: {
    color: "#023365",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: "130px",
    justifyContent: "space-around",
    marginX: 8,
    marginY: 4,
    width: "473px",
    margin: "24px 32px",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontWeight: "bold",
  },
  subLabel: {
    color: "text.dark",
    marginTop: 0.5,
  },
};
