import React from "react";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  createCredentials,
  getCredentials,
  getMerchants,
  ISiftScienceState,
  migrateSiftScience,
  setDisabled,
  setIsLoading,
  setIsMigrated,
  setIsVisible,
  setMigrateSiftScienceResponse,
  setNotification,
  setShowButtonMigrated,
  setShowModalCreate,
  SiftScienceAction,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { useSiftScienceState } from "./state/useSiftScienceState";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Merchants } from "../../../types/merchants";
import {
  LabelField,
  SiftScienceNotificationResultEnum,
  SiftScienceSubtitleSection,
} from "../../shared/infrastructure/constants/SiftScienceConstants";

import SaveIcon from "@material-ui/icons/Save";
import { DialogMessage } from "../../components/commons/Dialog/DialogMessage";
import { FormProvider } from "react-hook-form";

import { get } from "lodash";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";
import { AutocompleteTextFieldComponent } from "../../components/AutocompleteTextField/AutocompleteTextFieldComponent";
import SiftScienceCredentialsSection from "../SiftScienceCredentialsSection/SiftScienceCredentialsSection";
import { ICredentialsRequest } from "../../shared/infrastructure/interfaces/ICredentialsRequest";
import { SiftScienceCredentials } from "../../../types/siftscience_credentials";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";
import { MigrateSiftScienceResponse } from "../../../types/migrate_siftscience_response";
import { MonitoringRoles } from "../../shared/infrastructure/constants/MonitoringConstantIndex";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface SiftScienceStateProps {
  isLoading: boolean | undefined;
  disabled: boolean | undefined;
  merchants: Merchants;
  queryMerchants: Merchants;
  isMigrated: boolean | undefined;
  credentials: SiftScienceCredentials;

  notification: INotification | undefined;
  showModalCreate: boolean | undefined;
  isVisible: boolean | undefined;
  showButtonMigrated: boolean | undefined;
  migrateSiftScienceResponse: MigrateSiftScienceResponse | undefined;
}

export interface SiftScienceFunctionsProps {
  setIsLoading: (payload: boolean) => SiftScienceAction;
  setDisabled: (payload: boolean) => SiftScienceAction;
  getMerchants: (payload: {
    offset: number;
    pageSize: number;
    text: string;
  }) => void;
  getCredentials: (merchantId: string) => void;
  migrateSiftScience: (merchantId: string) => void;
  setIsMigrated: (payload: boolean) => SiftScienceAction;
  createCredentials: (
    payload: ICredentialsRequest,
    redirectPath: string
  ) => void;
  setNotification: (payload: INotification) => SiftScienceAction;
  setShowModalCreate: (payload: boolean) => SiftScienceAction;
  setIsVisible: (payload: boolean) => SiftScienceAction;
  setShowButtonMigrated: (payload: boolean) => SiftScienceAction;
  setMigrateSiftScienceResponse: (
    payload: MigrateSiftScienceResponse
  ) => SiftScienceAction;
}

export type SiftScienceProps = SiftScienceStateProps &
  SiftScienceFunctionsProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      padding: 0,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    avatarWidth: {
      minWidth: "35px",
    },
    button: {
      margin: "16px",
      marginRight: "0px",
      paddingInline: "20px",
    },
    labelTitle: {
      color: "#023365",
      fontSize: "20px",
      fontWeight: 500,
      fontStyle: "normal",
    },
    innerLabel: {
      color: "#6D7781",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      paddingBottom: "6px",
    },
    label: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "170%",
      color: "#677784",
      padding: "12px",
      paddingLeft: "0px",
    },
  })
);

export const SiftScience: React.FC<SiftScienceProps> = (
  props: SiftScienceProps
) => {
  const classes = useStyles();
  const { form, data, actions, values, dialog, credentials, handler } =
    useSiftScienceState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb
          items={[
            { label: "Inicio", url: "/dashboard" },
            { label: "Prevención de Fraude", url: "/monitoring" },
          ]}
          lastItem={"Sift Science"}
        />
        <Box pt={2} pb={3}>
          <Typography variant={"h1"}>Sift Science</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={3}>
            <Typography className={classes.labelTitle}>Comercio</Typography>
            <Box pt={1}>
              <Typography
                variant={"subtitle2"}
                color={"textPrimary"}
                className={classes.innerLabel}
              >
                Selecciona el comercio para el cual quieres realizar...
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <Card>
              <CardContent>
                <AutocompleteTextFieldComponent
                  property={"merchantId"}
                  label={SiftScienceSubtitleSection.MERCHANT_HEADER}
                  value={values.queryValue}
                  options={
                    data.queryMerchants.data.length === 0
                      ? data.merchants.data
                      : data.queryMerchants.data
                  }
                  pathValue={"_source.publicMerchantId"}
                  pathName={"_source.name"}
                  isLoading={props.isLoading}
                  handleEnterSearchText={actions.handleSearchBar}
                  handleSetSearchText={actions.handleSetQuery}
                  setValue={actions.handleClick}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {props.isVisible && (
          <FormProvider {...form}>
            <SiftScienceCredentialsSection
              isMigrated={props.isMigrated}
              credentials={props.credentials}
              handleClickMigrated={credentials.handleClickMigrated}
              migrateSiftScienceResponse={props.migrateSiftScienceResponse}
              isLoading={props.isLoading}
            />
            <DialogMessage
              id="sift-save"
              title=""
              titleButton="Guardar"
              message={LabelField.SAVE}
              onCancel={dialog.handleCloseDialogSave}
              onOk={form.handleSubmit(dialog.handleSubmitForm)}
              openDialog={dialog.openDialogSave}
            />
          </FormProvider>
        )}

        <Grid container justify="flex-end">
          <Grid item>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={dialog.handleOpenDialog}
            >
              Salir sin guardar
            </Button>
          </Grid>
          <ComponentSecurityWrapper
            componentId={`${MonitoringRoles.M_FRAUD_PREVENTION}.${MonitoringRoles.MONITORING_SIFTSCIENCE_SAVE}`}
          >
            <Grid item>
              <Button
                disabled={props.disabled}
                startIcon={<SaveIcon />}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={
                  props.showModalCreate
                    ? dialog.handleOpenDialogSave
                    : form.handleSubmit(dialog.handleSubmitForm)
                }
              >
                Guardar
              </Button>
            </Grid>
          </ComponentSecurityWrapper>
        </Grid>
        <DialogMessage
          id="sift-cancel"
          title={LabelField.EXIT_NOT_SAVE_TITLE}
          titleButton="Salir sin guardar"
          message={LabelField.EXIT_NOT_SAVE}
          onCancel={dialog.handleCloseDialog}
          onOk={dialog.handleClickDialog}
          openDialog={dialog.openDialog}
        />
        {get(props, "notification.action") !==
          SiftScienceNotificationResultEnum.NO_ACTION && (
          <SnackbarAlert
            type={get(props, "notification.type")}
            msg={get(props, "notification.message")}
            open={get(props, "notification.open")}
            handlerClose={handler.handleCloseNotification}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: ISiftScienceState
) => SiftScienceStateProps = (
  state: ISiftScienceState
): SiftScienceStateProps => ({
  isLoading: state.isLoading,
  disabled: state.disabled,
  merchants: state.merchants,
  queryMerchants: state.queryMerchants,
  isMigrated: state.isMigrated,
  credentials: state.credentials,
  notification: state.notification,
  showModalCreate: state.showModalCreate,
  isVisible: state.isVisible,
  showButtonMigrated: state.showButtonMigrated,
  migrateSiftScienceResponse: state.migrateSiftScienceResponse,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => SiftScienceFunctionsProps = (
  dispatch: ThunkDispatch<ISiftScienceState, undefined, SiftScienceAction>
): SiftScienceFunctionsProps => ({
  getMerchants: (payload: {
    offset: number;
    pageSize: number;
    text: string;
  }): void => dispatch(getMerchants(payload)),
  setIsLoading: (payload: boolean): SiftScienceAction =>
    dispatch(setIsLoading(payload)),
  setDisabled: (payload: boolean): SiftScienceAction =>
    dispatch(setDisabled(payload)),
  getCredentials: (merchantId: string): void =>
    dispatch(getCredentials(merchantId)),
  migrateSiftScience: (merchantId: string): void =>
    dispatch(migrateSiftScience(merchantId)),
  setIsMigrated: (payload: boolean): SiftScienceAction =>
    dispatch(setIsMigrated(payload)),
  createCredentials: (
    payload: ICredentialsRequest,
    redirectPath: string
  ): void => dispatch(createCredentials(payload, redirectPath)),
  setNotification: (payload: INotification): SiftScienceAction =>
    dispatch(setNotification(payload)),
  setShowModalCreate: (payload: boolean): SiftScienceAction =>
    dispatch(setShowModalCreate(payload)),
  setIsVisible: (payload: boolean): SiftScienceAction =>
    dispatch(setIsVisible(payload)),
  setShowButtonMigrated: (payload: boolean): SiftScienceAction =>
    dispatch(setShowButtonMigrated(payload)),
  setMigrateSiftScienceResponse: (
    payload: MigrateSiftScienceResponse
  ): SiftScienceAction => dispatch(setMigrateSiftScienceResponse(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiftScience);
