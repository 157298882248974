/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
devTools: boolean;
kushkiUrlWS: string;
rollbarToken: string;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
kushkiUrlWS: "api-ws.kushkipagos.com",
rollbarToken: '5f78a722ccc94384979c4d1b3dd4a063',
};
