import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CategoryData } from "@kushki/connect-ui";
import { IAppState } from "../../../../store/reducer";
import { useEffect, useState, useRef } from "react";
import { defaultTo, get, isEmpty, omit, set } from "lodash";
import { IUseMerchantWithoutHierarchyState } from "../../../../shared/infrastructure/interfaces/IUseMerchantWithoutHierarchy";
import {
  getMerchantCredential,
  getMerchantInformation,
  getProcessors,
  getSecurityRulesByType,
  setListCommerceWithoutHierachy,
  setRuleDetails,
} from "../../../../store/actionCreators";
import {
  GetMerchantResponse,
  MerchantProperties,
} from "../../../../../types/get_merchant_response";
import { GetMerchantRequest } from "../../../../../types/get_merchant_request";
import { IMerchantWithoutHierarchyComponentProps } from "../../../../shared/infrastructure/interfaces/IMerchantWithoutHierarchyComponentProps";
import { FieldsRule } from "../../../../shared/infrastructure/constants/RuleRequestConstants";
import { SearchRequestRuleByIdResponse } from "../../../../../types/search_request_rule_by_id_response";
import { checkIsWhiteList, isFormEditMode } from "../../../../utils/utilsFile";
import { useLocation, useParams } from "react-router-dom";
import { IUseParams } from "../../../../shared/infrastructure/interfaces/IUseParams";
import { Category } from "../../../../shared/infrastructure/interfaces/Category";
import { SubTypeEnum } from "../../../../shared/infrastructure/enums/CreateRuleConstans";
import {
  // @ts-ignore
  ItemsCategoryData,
  OptionsSelectSearchBy,
} from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";
import { GetRuleByIdResponse } from "../../../../../types/get_rule_by_id";
import { RuleDetail } from "../../../../../types/rule_detail";
import { TIMEOUT_VALUE } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";

export const useMerchantWithoutHierarchyState = (
  props: IMerchantWithoutHierarchyComponentProps
): IUseMerchantWithoutHierarchyState => {
  const dispatch = useDispatch();
  const { control, errors, setValue, watch } = useFormContext();
  const { id, ruleId } = useParams<IUseParams>();
  const service: string | undefined = watch("service");
  const idValue: string = id || ruleId;

  const isWhiteList = checkIsWhiteList(useLocation().pathname);

  const [listMerchantsItems, setListMerchantsItems] = useState<CategoryData[]>(
    []
  );

  const [isCreationRule, setIsCreationRule] = useState<boolean>(false);

  const [defaultValueSelect, setDefaultValueSelect] = useState<
    OptionsSelectSearchBy | undefined
  >(undefined);

  const merchantInformation: GetMerchantResponse | undefined = useSelector(
    (state: IAppState) => state.merchantInformation
  );
  const merchantCredential: Category[] | undefined = useSelector(
    (state: IAppState) => state.credentials
  );

  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );

  const searchRequestRuleByIdResponse:
    | SearchRequestRuleByIdResponse
    | undefined = useSelector(
    (state: IAppState) => state.searchRequestRuleById
  );
  const searchRuleById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );
  const debounceTimerReference = useRef<NodeJS.Timeout | undefined>(undefined);
  const [showSearchingTextMessage, setShowSearchingTextMessage] =
    useState(false);
  const invokeProcessorsEndpoint = (merchantIdValue: string) => {
    dispatch(getProcessors(merchantIdValue));
  };

  const onItemSelectWithoutHierarchy = (item: OptionsSelectSearchBy): void => {
    const merchantIdValue: string = get(item, "secondaryText", "");
    if (item && !isEmpty(item.id)) {
      const merchantName: string = get(item, "text", "");
      const merchantData = get(merchantInformation, "data", []).find(
        (dataItem: MerchantProperties) =>
          get(dataItem, "_source.merchantId", "") === merchantIdValue
      );

      setValue("withoutHierarchy", item);
      dispatch(
        setRuleDetails({
          service: defaultTo(service, ""),
          merchantId: merchantIdValue,
          subType: SubTypeEnum.COMMERCE,
          merchantName: merchantName,
          country: [get(merchantData, "_source.country", "")],
        })
      );
      dispatch(getMerchantCredential(merchantIdValue));

      props.setIsItemSelectedWithoutHierarchy(true);

      if (isWhiteList && !isEmpty(merchantIdValue))
        dispatch(
          getSecurityRulesByType(FieldsRule.WHITE_LIST, {
            merchantId: merchantIdValue,
            limit: 20,
            lastEvaluatedKey: null,
          })
        );
    }
    if (item && !isEmpty(item.id) && !isWhiteList) {
      invokeProcessorsEndpoint(merchantIdValue);
    }
  };
  const doMerchantSearch = (event: string) => {
    if (!isEmpty(event) && event.length > 1) {
      setShowSearchingTextMessage(true);
      const isTextWithMerchantId: boolean = !isEmpty(
        event.match(new RegExp(/^\d/))
      );

      let request: GetMerchantRequest = {
        merchant_id: event,
        text: event,
        limit: 20,
        offset: 0,
      };

      if (isTextWithMerchantId) {
        request = omit(request, "text");
      } else {
        request = omit(request, "merchant_id");
      }

      dispatch(getMerchantInformation(request, "list"));
    }

    if (isEmpty(event)) {
      props.setIsItemSelectedWithoutHierarchy(false);
    }
  };
  const handleOnChangeInputWithoutHierarchy = (event: string): void => {
    clearTimeout(debounceTimerReference.current);

    debounceTimerReference.current = setTimeout(() => {
      doMerchantSearch(event);
    }, TIMEOUT_VALUE);
  };

  useEffect(() => {
    setShowSearchingTextMessage(false);
    if (defaultTo(get(merchantInformation, "total.value"), 0) > 0) {
      const categoryData: CategoryData[] = [];
      const categoryDataItems: ItemsCategoryData[] = [];

      defaultTo(get(merchantInformation, "data"), []).forEach(
        (data: MerchantProperties) => {
          categoryDataItems.push({
            id: data._source.merchantId,
            text: data._source.name,
            secondaryText: data._source.merchantId,
          });
        }
      );

      categoryData.push({
        categoryId: "",
        categoryName: "",
        items: categoryDataItems,
      });

      setListMerchantsItems(categoryData);
      dispatch(setListCommerceWithoutHierachy(categoryData));
      if (!isWhiteList) {
        const merchantId: string = get(
          merchantInformation,
          "data[0]._source.merchantId",
          ""
        );

        invokeProcessorsEndpoint(merchantId);
      }
    }
  }, [merchantInformation]);

  useEffect(() => {
    if (merchantCredential) {
      const categoryData: CategoryData[] = [];
      const categoryDataItems: ItemsCategoryData[] = [];

      merchantCredential.forEach((data: Category) => {
        categoryDataItems.push({
          id: String(data.value),
          text: data.name,
          secondaryText: "",
        });
      });

      categoryData.push({
        categoryId: "",
        categoryName: "",
        items: categoryDataItems,
      });

      dispatch(setListCommerceWithoutHierachy(categoryData));
    }
  }, [merchantCredential]);

  useEffect(() => {
    if (searchRequestRuleByIdResponse && isFormEditMode(idValue)) {
      const categoryData: CategoryData[] = [];
      const item: OptionsSelectSearchBy = {
        id: searchRequestRuleByIdResponse.merchantId,
        text: searchRequestRuleByIdResponse.merchantName,
        secondaryText: searchRequestRuleByIdResponse.merchantId,
        categoryName: "",
        categoryId: "",
      };

      categoryData.push({
        categoryId: "",
        categoryName: "",
        items: [item],
      });

      setDefaultValueSelect(item);
      setValue("withoutHierarchy", item);
      setListMerchantsItems(categoryData);
      dispatch(setListCommerceWithoutHierachy(categoryData));
    }
  }, [searchRequestRuleByIdResponse, idValue]);

  useEffect(() => {
    if (searchRuleById && isFormEditMode(idValue)) {
      const merchantId: string = get(searchRuleById, "rule.merchantId", "");
      const categoryData: CategoryData[] = [];
      const item: OptionsSelectSearchBy = {
        id: merchantId,
        text: searchRuleById.rule.merchantName,
        secondaryText: merchantId,
        categoryName: "",
        categoryId: "",
      };

      set(ruleDetails!, "merchantId", merchantId);
      set(
        ruleDetails!,
        "merchantName",
        get(searchRuleById, "rule.merchantName", "")
      );
      set(ruleDetails!, "service", get(searchRuleById, "rule.service", ""));

      categoryData.push({
        categoryId: "",
        categoryName: "",
        items: [item],
      });

      setDefaultValueSelect(item);
      setValue("withoutHierarchy", item);
      setListMerchantsItems(categoryData);
      dispatch(setListCommerceWithoutHierachy(categoryData));

      if (!isEmpty(merchantId)) dispatch(getMerchantCredential(merchantId));
      setIsCreationRule(false);
    }
  }, [searchRuleById, idValue]);

  useEffect(() => {
    if (
      ruleDetails &&
      isEmpty(get(ruleDetails, "entityName", "")) &&
      !isEmpty(get(ruleDetails, "merchantId", ""))
    ) {
      const categoryData: CategoryData[] = [];
      const categoryDataItems: ItemsCategoryData[] = [];

      categoryDataItems.push({
        id: ruleDetails.merchantId,
        text: get(ruleDetails, "merchantName", ""),
        secondaryText: ruleDetails.merchantId,
      });

      categoryData.push({
        categoryId: "",
        categoryName: "",
        items: categoryDataItems,
      });

      const item: OptionsSelectSearchBy = {
        ...categoryDataItems[0],
        categoryName: "",
        categoryId: "",
      };

      setDefaultValueSelect(item);
      setValue("withoutHierarchy", item);
      props.setIsItemSelectedWithoutHierarchy(true);

      setListMerchantsItems(categoryData);
    }
  }, []);

  return {
    control,
    errors,
    listMerchantsItems,
    onItemSelectWithoutHierarchy,
    handleOnChangeInputWithoutHierarchy,
    defaultValueSelect,
    id: idValue,
    isCreationRule,
    isWhiteList,
    showSearchingTextMessage,
  };
};
