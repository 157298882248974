import React, { FC } from "react";
import { ModalDetailCard } from "@kushki/connect-ui/dist/Components/Organism/ModalDetailCard";
import { Box, Grid, Typography } from "@mui/material";
import {
  background_color,
  infoModalStyles as styles,
} from "./DetailModal.styles";
import {
  bodyColumns,
  MODAL_DETAIL_ARGS_DEFAULT,
  useDetailModal,
} from "./state/useDetailModal";
import { IModalDetailCard } from "./DetailModal.interfaces";

const DetailModal: FC<IModalDetailCard> = (props: IModalDetailCard) => {
  const { header, items, operationType, onClose } = useDetailModal(props);
  const columns: number = bodyColumns[operationType];

  return (
    <React.Fragment>
      <ModalDetailCard
        {...MODAL_DETAIL_ARGS_DEFAULT}
        isOpen={props.isOpen}
        onClose={onClose}
        header={header}
      >
        <Box style={{ backgroundColor: background_color }}>
          <Typography sx={styles.title}>Datos de la transacción</Typography>
          <Grid container sx={styles.container}>
            {items.map(({ title, description }, index) => (
              <Grid
                key={`${index}-${props.trx.transaction_id}`}
                item
                xs={columns}
              >
                <Box sx={styles.itemWrapper}>
                  <Typography sx={styles.itemTitle} color={"text.grey"}>
                    {title}
                  </Typography>
                  <Typography color={"text.dark"} variant={"caption"}>
                    {description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ModalDetailCard>
    </React.Fragment>
  );
};

export default DetailModal;
