export enum PersonTypeCOEnum {
  LEGAL = "02",
  PHYSICAL = "01",
}

export enum documentTypeCOValuesEnum {
  ONE = "1",
  TWO = "2",
  THREE = "3",
}
