import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useInvoiceAnnulStyles = makeStyles(() => ({
  cancelButton: {
    color: theme.palette.error.dark,
    fontWeight: "bolder",
  },
  annulButton: {
    color: theme.palette.text.primary,
    textTransform: "none",
    fontWeight: "bolder",
  },
  fileMessage: {
    color: "#6D7781",
    fontSize: "12px",
  },
  messageText: {
    fontSize: 10,
  },
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "5px",
  },
  divider: {
    margin: "32px 0",
  },
  uploadFileContainer: {
    marginTop: theme.spacing(3),
  },
  sizeMessage: {
    fontStyle: "italic",
  },
  textFieldMultiLine: {
    marginTop: theme.spacing(3),
    "& .MuiInputBase-root": { height: "inherit" },
  },
  infoIcon: {
    flexGrow: 0.08,
    color: "orange",
  },
  bold: {
    fontWeight: 550,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  subTextBlue: {
    color: "#1E65AE",
  },
  fileSelected: {
    padding: 5,
    backgroundColor: "#F1FFFD",
    borderRadius: "4px;",
    minWidth: "200px",
    "& svg": {
      float: "right",
    },
  },
  buttonFile: {
    color: "primary",
    border: `2px solid ${theme.palette.primary.dark}`,
    fontWeight: 550,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  fileSelectedError: {
    padding: 5,
    backgroundColor: theme.palette.error.light,
    borderRadius: "4px;",
    minWidth: "290px",
    "& svg": {
      float: "right",
    },
  },
}));
