import { IUseDataTableComponentBranch } from "../../../shared/infrastructure/interfaces/IUseDataTableComponentBranch";
import { useEffect, useState } from "react";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import {
  LabelEnum,
  TABLE_COLUMNS,
} from "../../../shared/infrastructure/enums/LabelEnum";
import {
  BusinessConditions,
  Operators,
} from "../../../../types/configuration_response";
import { get, isEmpty } from "lodash";
import { buildOneCell } from "../../../shared/utils/utilsFile";
import {
  COLUMNS_BY_TABS,
  TableBodyCellEnum,
} from "../../../shared/infrastructure/enums/DataTableEnum";
import { ItemBusinessRule } from "../../../../types/item_business-rule";
import { useAppSelector } from "../../../store/hooks/store-hook";
import { TRANSACTION_TYPE_MAPPING_MODAL } from "../../../shared/infrastructure/enums/ItemByTagEnum";
import { RuleEnum } from "../../../shared/infrastructure/enums/RuleEnum";

export const useConditionsBranch = (): IUseDataTableComponentBranch => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [cells] = useState<HeaderCellProps[]>(
    TABLE_COLUMNS[LabelEnum.CONDITIONS]
  );

  const requestRuleById: ItemBusinessRule | undefined = useAppSelector(
    (state) => get(state.layout, "requestRule", undefined)
  );

  const configurationResponse = useAppSelector((state) =>
    get(state.layout, "configList", undefined)
  );

  useEffect(() => {
    generateSectionConditionGeneral(requestRuleById);
  }, [requestRuleById, configurationResponse]);

  function mapValueToConditionText(field: string, value: string): string {
    if (field === RuleEnum.CARD_TYPE)
      return TRANSACTION_TYPE_MAPPING_MODAL[value];

    return value;
  }

  function generateSectionConditionGeneral(item: ItemBusinessRule | undefined) {
    let tableRowsProps: ITableRowProps[] = [];
    const rows: BusinessConditions[][] = [];
    configurationResponse?.businessConditions?.forEach(
      (condition: BusinessConditions) => {
        if (get(item, `rule.rule.${condition.field}`)) {
          const row: any[] = [];
          const value: string = get(item, `rule.rule.${condition.field}`, "");
          const values = value.split("|");
          row.push({ text: condition.title });
          row.push({
            text: configurationResponse?.operators?.find(
              (operador: Operators) => operador.value === values[0]
            )?.title,
          });
          row.push({
            text: mapValueToConditionText(condition.field!, values[1]),
          });
          rows.push(row);
        }
      }
    );

    rows.forEach((item: BusinessConditions, index: number) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          rowProps: {
            color: "default",
            onClick: () => {},
          },
          cells: buildCellsConditions(item),
          id: index.toString(),
        },
      ];
    });

    setRows(tableRowsProps);
  }

  const buildCellsConditions = (
    item: BusinessConditions
  ): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const itemsByTabs: string[] = COLUMNS_BY_TABS[LabelEnum.CONDITIONS];

    itemsByTabs.forEach((_: string, index: number) => {
      const itemText: string = get(item[index], "text", "");

      if (isEmpty(itemText)) {
        cells.push(buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY));
      } else {
        cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
      }
    });

    return cells;
  };

  return {
    rows,
    cells,
  };
};
