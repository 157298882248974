import { appSlice } from "../reducers/app/app";

export const {
  showErrorSnackbar,
  showLoading,
  showSuccessSnackbar,
  setLoadingText,
  setValueTab,
  setDownloadFile,
  setLoadingDownload,
} = appSlice.actions;
