import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { IDeveloperSectionsProps } from "@components/DeveloperSection/DeveloperSections.interfaces";
import { developerSectionsStyles as styles } from "@components/DeveloperSection/DeveloperSections.styles";
import { IconCircleInformation } from "@kushki/connect-ui";
import { get } from "lodash";
import { RolesEnum } from "@shared/enum/rolesEnum";
import { SessionStorageEnum } from "@shared/enum/sessionStorageEnum";
const DeveloperSections: React.FC<IDeveloperSectionsProps> = ({
  items,
}: IDeveloperSectionsProps) => {
  return (
    <Box sx={styles.sections}>
      {items.map((item, index) => {
        return (
          <Box
            data-testid={"sectionComponent"}
            key={index}
            sx={styles.link}
            onClick={() => {
              window.location.href = item.redirect;
              sessionStorage.setItem(
                SessionStorageEnum.basicMerchantInformation,
                JSON.stringify({
                  layout: { origin: "" },
                })
              );
            }}
          >
            <Box sx={styles.titleWrapper}>
              <Typography variant={"h6"}>{item.title}</Typography>
              {item.role === RolesEnum.BackofficeMasterCustomer && (
                <Tooltip title={get(item, "tooltip", "")} placement="top" arrow>
                  <Box sx={styles.tooltip}>
                    <IconCircleInformation fontSize={"small"} />
                  </Box>
                </Tooltip>
              )}
            </Box>
            <Typography variant={"caption"} sx={styles.description}>
              {item.description}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default DeveloperSections;
