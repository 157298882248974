import React, { ChangeEvent, useEffect } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { ConfirmModal } from "../ConfirmModal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { isEmpty } from "lodash";

export interface RejectConfirmModalProps {
  open: boolean;
  handleCloseReject(): void;
  handleAcceptReject(rejectReason: string): void;
  title: string;
  content: string | React.ReactElement;
  label: string;
  maxCharacter: number;
  acceptText: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(1),
      "&.MuiFormControl-root": {
        marginLeft: 0,
        borderColor: theme.palette.error.dark,
      },
      "& > label+div": {
        height: "auto",
      },
      marginTop: "-6px",
    },
    inputRed: {
      marginTop: "30px",
      margin: theme.spacing(1),
      "&.MuiFormControl-root": { marginLeft: 0 },
      "& > label+div": {
        height: "auto",
      },
      "& fieldset": {
        borderColor: "#D62C4B",
      },
      "& label.Mui-focused": {
        color: "#D62C4B",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#D62C4B",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#D62C4B",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#D62C4B",
        },
      },
    },
    errorInput: {
      color: theme.palette.error.dark,
    },
    errorColor: {
      color: theme.palette.error.dark,
    },
  })
);
export const RejectConfirmModal: React.FC<RejectConfirmModalProps> = (
  props: React.PropsWithChildren<RejectConfirmModalProps>
) => {
  const classes = useStyles();

  const [rejectReason, setRejectReason] = React.useState("");
  const [openModalReject, setOpenModalReject] = React.useState(props.open);
  const [disableAcceptButton, setDisableAcceptButton] = React.useState(true);
  const [validateInputText, setValidateInputText] = React.useState(false);

  useEffect(() => {
    setRejectReason("");
    setOpenModalReject(props.open);
  }, [props.open, openModalReject]);

  useEffect(() => {
    const isOverflow = rejectReason.length >= props.maxCharacter;
    setValidateInputText(isOverflow);
    setDisableAcceptButton(isEmpty(rejectReason));
  }, [rejectReason]);

  const handleCloseReject = () => () => {
    props.handleCloseReject();
  };

  const handleAcceptReject = () => () => {
    props.handleAcceptReject(rejectReason);
  };

  return (
    <ConfirmModal
      open={openModalReject}
      titleText={props.title}
      contentText={props.content}
      acceptText={props.acceptText}
      acceptColor="primary"
      handleClose={handleCloseReject()}
      handleAccept={handleAcceptReject()}
      disableAccept={disableAcceptButton}
    >
      <Grid>
        <TextField
          id="input-edit"
          fullWidth
          multiline
          label={props.label}
          variant="outlined"
          value={rejectReason}
          inputProps={{ maxLength: props.maxCharacter }}
          className={`${classes.input} ${
            validateInputText ? classes.inputRed : ""
          }`}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setRejectReason(event.target.value)
          }
        />
        <Box
          display="inherit"
          textAlign="end"
          className={validateInputText ? classes.errorColor : undefined}
        >
          {`${rejectReason.length}/${props.maxCharacter}`}
        </Box>
      </Grid>
    </ConfirmModal>
  );
};
