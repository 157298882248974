import * as React from "react";
import { TableSimple } from "@kushki/connect-ui";
import { useConditions } from "./state/useConditions";

export const Conditions: React.FC = () => {
  const { rows, cells } = useConditions();

  return (
    <TableSimple
      header={{
        cells,
        headerProps: {
          isDisable: false,
        },
      }}
      rows={rows}
      showPagination={false}
    />
  );
};
