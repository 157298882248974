import React from "react";
import { useDateRangePickerState } from "./state/useDateRangePickerState";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";

export const DateRangePickerComponent = () => {
  const { value, handleDate } = useDateRangePickerState();

  return (
    <KshDateRangePicker
      handleDate={handleDate}
      disableFuture={true}
      value={value}
      enableTimePicker={true}
    />
  );
};
