import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useCheckoutAlertState } from "./state/useCheckoutAlertState";
import { IconBlock, IconCheck } from "@kushki/connect-ui";
import { Box, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  backgroundColor,
  checkoutAlertStyles as styles,
} from "./CheckoutAlert.styles";

export interface CheckoutAlertProps {
  msg: string;
  open: boolean;
  type: "error" | "success";
  handlerClose?: () => void;
}

export const CheckoutAlert: React.FC<CheckoutAlertProps> = (
  props: CheckoutAlertProps
) => {
  const { open, handleClose } = useCheckoutAlertState(props);

  return (
    <React.Fragment>
      <Box sx={styles.alertContainer}>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={open}
          onClose={handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={props.type}
            style={{
              backgroundColor: `${backgroundColor(props.type)}`,
              color: "#FFF",
            }}
            iconMapping={{
              error: <IconBlock fontSize="medium" />,
              success: <IconCheck fontSize="medium" />,
            }}
            action={
              <IconButton
                data-testid="icon-button"
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={handleClose}
              >
                <HighlightOffIcon fontSize="medium" />
              </IconButton>
            }
          >
            {props.msg}
          </MuiAlert>
        </Snackbar>
      </Box>
    </React.Fragment>
  );
};
