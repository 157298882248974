import { FrequencyEnum } from "../../enums/frequencyEnum";
import {
  DispersionsFrequencyLabelEnum,
  YesNoEnum,
} from "../../enums/dispersionsEnum";
import { CountriesEnum } from "../../enums/countriesEnum";
import { CatalogEc } from "../catalogs/catalog-ec";
import { CatalogBr } from "../catalogs/catalog-br";
import { CatalogCr } from "../catalogs/catalog-cr";
import { CatalogCl } from "../catalogs/catalog-cl";
import { CatalogCo } from "../catalogs/catalog-co";
import { CatalogEl } from "../catalogs/catalog-el";
import { CatalogMx } from "../catalogs/catalog-mx";
import { CatalogPa } from "../catalogs/catalog-pa";
import { CatalogPe } from "../catalogs/catalog-pe";
import { CatalogGt } from "../catalogs/catalog-gt";
import { CatalogNi } from "../catalogs/catalog-ni";
import { CatalogHn } from "../catalogs/catalog-hn";
import { CatalogEeuu } from "../catalogs/catalog-eeuu";
import { CatalogWorld } from "../catalogs/catalog-world";
import { Category } from "../../interfaces/category";

export const DISPERSION_FREQUENCY_LIST: {
  label: DispersionsFrequencyLabelEnum;
  value: FrequencyEnum;
}[] = [
  {
    label: DispersionsFrequencyLabelEnum.NONE,
    value: FrequencyEnum.NONE,
  },
  {
    label: DispersionsFrequencyLabelEnum.DAILY,
    value: FrequencyEnum.DAILY,
  },
  {
    label: DispersionsFrequencyLabelEnum.WEEKLY,
    value: FrequencyEnum.WEEKLY,
  },
  {
    label: DispersionsFrequencyLabelEnum.TWO_WEEKLY,
    value: FrequencyEnum.TWO_WEEKLY,
  },
  {
    label: DispersionsFrequencyLabelEnum.BI_WEEKLY,
    value: FrequencyEnum.BI_WEEKLY,
  },
  {
    label: DispersionsFrequencyLabelEnum.THREE_WEEKLY,
    value: FrequencyEnum.THREE_WEEKLY,
  },
  {
    label: DispersionsFrequencyLabelEnum.MONTHLY,
    value: FrequencyEnum.MONTHLY,
  },
];

export const YES_NO_OPTIONS: { label: YesNoEnum; value: boolean }[] = [
  { label: YesNoEnum.YES, value: true },
  { label: YesNoEnum.NO, value: false },
];

export const parseStringToBoolean: Record<string, boolean> = {
  ["false"]: false,
  ["true"]: true,
};

export const MAX_PERCENTAGE_VALUE = 100;

export const getCatalog = (country: string, catalog: string): Category[] => {
  switch (country) {
    case CountriesEnum.ECUADOR:
      return CatalogEc[catalog];
    case CountriesEnum.BRAZIL:
      return CatalogBr[catalog];
    case CountriesEnum.CHILE:
      return CatalogCl[catalog];
    case CountriesEnum.COLOMBIA:
      return CatalogCo[catalog];
    case CountriesEnum.COSTA_RICA:
      return CatalogCr[catalog];
    case CountriesEnum.EL_SALVADOR:
      return CatalogEl[catalog];
    case CountriesEnum.MEXICO:
      return CatalogMx[catalog];
    case CountriesEnum.PANAMA:
      return CatalogPa[catalog];
    case CountriesEnum.PERU:
      return CatalogPe[catalog];
    case CountriesEnum.GUATEMALA:
      return CatalogGt[catalog];
    case CountriesEnum.NICARAGUA:
      return CatalogNi[catalog];
    case CountriesEnum.HONDURAS:
      return CatalogHn[catalog];
    case CountriesEnum.ESTADOS_UNIDOS:
      return CatalogEeuu[catalog];
    default:
      return CatalogWorld[catalog];
  }
};
