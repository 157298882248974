import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const checkoutContainerStyles = createNamedStyles({
  button: {
    margin: { lg: "0px 10px 0px 0px", md: "0px 10px 0px 0px", sm: "0px auto" },
  },
  contentExpressCheckout: {
    ".MuiGrid-item": {
      padding: 0,
    },
    margin: 0,
    padding: 0,
  },
  contentMobile: {
    marginTop: "20px",
    minHeight: "25rem",
  },
  contentWeb: {
    marginTop: "20px",
  },
  groupButtonMobile: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  groupButtonWeb: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
  main: {
    background: "#fff",
    borderRadius: "10px",
    marginLeft: "0px",
    padding: "35px 20px 20px 40px",
    width: "100%",
  },
  mainMobile: {
    background: "#fff",
    marginLeft: "0pt",
    padding: "15px",
    width: "100%",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignJustify: {
    textAlign: "justify",
  },
});
