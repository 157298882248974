import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import theme from "../../theme";

export const formActionsStyles = createNamedStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    columnGap: (theme) => theme.spacing(1),
    p: (theme) => theme.spacing(0, 7.5, 0, 7.5),
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid transparent",
      boxShadow: "none",
      color: "text.primary",
    },
    fontSize: theme.spacing(2.25),
    height: theme.spacing(6.5),
    p: (theme) => theme.spacing(1.75, 3.75, 1.75, 3.75),
  },
  acceptButton: {
    fontSize: theme.spacing(2.25),
    height: theme.spacing(6.5),
    p: (theme) => theme.spacing(1.75, 5.375, 1.75, 5.375),
    width: theme.spacing(19.25),
  },
});
