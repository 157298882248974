import React, { FC } from "react";
import { IconArrowLeft, Tab, Tabs } from "@kushki/connect-ui";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import { TransactionLabelsEnum } from "../../shared/enums/transactionLabelsEnum";
import { useModalDetailInfo } from "./state/useModalDetailInfo";
import { IModalDetailInfo } from "./ModalDetailInfo.interfaces";
import { ModalDetailInfoStyles } from "./ModalDetailInfo.styles";
import { ModalDetailInfoHeader } from "./ModalDetailInfoHeader/ModalDetailInfoHeader";
import { get, has } from "lodash";
import { getColorByStatus } from "../../shared/utils/general_utils";
import { CATALOG_TRANSACTION_STATUS } from "../../shared/enums/TransactionStatusEnum";
import { CardTax } from "../CardInformation/CardInformation";
import { validateTooltipVoid } from "../../shared/utils/tooltip_refund";
import { formatDate } from "../../shared/constants/table_transactions";
import { showMetadataCredentials } from "../../shared/utils/metaData_utils";
import { modalHeaderButtons } from "../../shared/constants/ModalButtons";
import { Chargeback } from "../../../types/chargeback";
import TransactionSectionModal from "../SectionsModal/TransactionSectionModal/TransactionSectionModal";
import { TitleSection } from "../../shared/constants/ChargebackConstants";
import { useStyles } from "../ModalDetailInfo/ModalDetailInfo.styles";
import ChargebackSection from "../SectionsModal/ChargebackSectionModal/ChargebackSection";
import NotificationSectionModal from "../SectionsModal/NotificationSectionModal/NotificationSectionModal";
import DocumentationSection from "../SectionsModal/DocumentationSectionModal/DocumentationSectionModal";
import MerchantSection from "../SectionsModal/MerchantSectionModal/MerchantSection";

const ModalDetailInfo: FC<IModalDetailInfo> = (props: IModalDetailInfo) => {
  const { changeTab, handleClose, tabIndex, transactionInfo } =
    useModalDetailInfo(props);
  const { modal } = props;
  const valueTransaction = (): Chargeback => {
    return transactionInfo;
  };
  const is_mongo: boolean = has(props, "transactionInfo._id");

  const { primaryButton, secondaryButton } = modalHeaderButtons(
    props,
    valueTransaction()
  );
  const classes = useStyles();

  return (
    <Modal open={modal.isOpenModalDetailInfo} data-testid={"modalDetail"}>
      <Slide
        in={modal.isOpenModalDetailInfo}
        direction="left"
        timeout={1000}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={ModalDetailInfoStyles.modalContainer}>
          <Box sx={ModalDetailInfoStyles.modalInformation}>
            <Box sx={ModalDetailInfoStyles.iconButton}>
              <Box sx={ModalDetailInfoStyles.iconButton}>
                <IconButton
                  color="secondary"
                  onClick={handleClose}
                  size="large"
                >
                  <IconArrowLeft />
                </IconButton>
              </Box>
              <ModalDetailInfoHeader
                title={TransactionLabelsEnum.MODAL_DETAIL_TITLE}
                tooltipPrimaryTitle={
                  validateTooltipVoid(valueTransaction()).message
                }
                buttonPrimary={primaryButton}
                buttonSecondary={secondaryButton}
                secondaryText={TransactionLabelsEnum.MODAL_DETAIL_DATE.concat(
                  formatDate(
                    Date.parse(get(transactionInfo, "created", "")),
                    TransactionLabelsEnum.MODAL_DETAIL_DATE_FORMAT
                  )
                )}
                subtitle={get(valueTransaction(), "ticket_number")!}
                tags={[
                  {
                    color: getColorByStatus(
                      get(valueTransaction(), "transaction_status")!
                    ),
                    text: CATALOG_TRANSACTION_STATUS[
                      get(valueTransaction(), "transaction_status")!
                    ],
                  },
                ]}
              />
            </Box>
            <Box sx={ModalDetailInfoStyles.detailModal}>
              <Box display="flex">
                <Box
                  display="flex"
                  flexDirection="column"
                  marginRight="8px"
                  width="100%"
                >
                  <Tabs
                    onChange={changeTab}
                    orientation="horizontal"
                    variant="fullWidth"
                    valueTab={tabIndex}
                  >
                    <Tab
                      text={TransactionLabelsEnum.MODAL_DETAIL_TAB_1}
                      variant="primary"
                      defaultTab={true}
                    />
                    <Tab
                      text={TransactionLabelsEnum.MODAL_DETAIL_TAB_3}
                      variant="primary"
                    />
                  </Tabs>

                  <Box
                    sx={
                      tabIndex === 1
                        ? ModalDetailInfoStyles.boxTabsNoMargin
                        : ModalDetailInfoStyles.boxTabs
                    }
                  >
                    {tabIndex === 0 && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          className={classes.container}
                        >
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant={"h5"}
                                    color={"text.dark"}
                                  >
                                    {TitleSection.MERCHANT_DETAILS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <MerchantSection
                                    data={props.transactionInfo}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant={"h5"}
                                    color={"text.dark"}
                                  >
                                    {TitleSection.CHARGEBACK_DETAILS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <ChargebackSection
                                    data={props.transactionInfo}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant={"h5"}
                                    color={"text.dark"}
                                  >
                                    {TitleSection.TRANSACTION_DETAILS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <TransactionSectionModal
                                    data={props.transactionInfo}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant={"h5"}
                                    color={"text.dark"}
                                  >
                                    {TitleSection.NOTIFICATION_DETAILS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <NotificationSectionModal
                                    data={props.transactionInfo}
                                    isMongo={is_mongo}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant={"h5"}
                                    color={"text.dark"}
                                  >
                                    {TitleSection.DOCUMENTATION_DETAILS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <DocumentationSection
                                    data={props.transactionInfo}
                                    isMongo={is_mongo}
                                  />
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {tabIndex === 1 && (
                      <>
                        <CardTax
                          data={showMetadataCredentials(transactionInfo)}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ModalDetailInfo;
