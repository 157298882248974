import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import { ISelectedProduct } from "../../shared/infrastructure/interfaces/ISelectedProduct";
import { EventList } from "../../shared/infrastructure/constants/OptionsEnum";

export interface IEventCheckList {
  title: string;
  items: EventList;
  option: string;
  handleStatus: (
    event: React.ChangeEvent<HTMLInputElement>,
    product: ISelectedProduct
  ) => void;
  handleCheck: (product: ISelectedProduct) => boolean;
}

const useStyles = makeStyles({
  container: {
    borderRadius: "4px 4px 0px 0px",
    marginTop: -5,
  },
  label: {
    background: "#F7FAFC",
  },
  title: {
    paddingLeft: 8,
    color: "#6D7781",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "36px",
  },
  checkbox: {
    width: "100%",
  },
});

const EventCheckList: React.FC<IEventCheckList> = (props: IEventCheckList) => {
  const { title, items, handleStatus, handleCheck, option } = props;
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="none"
      className={classes.container}
    >
      <FormLabel className={classes.label}>
        <span className={classes.title}>{title}</span>
      </FormLabel>
      <Box>
        <FormGroup row>
          {Object.entries(items[option]).map(([value, label], index) => (
            <FormControlLabel
              key={index}
              className={classes.checkbox}
              control={
                <Box style={{ paddingLeft: 8 }}>
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      handleStatus(e, { service: option, status: value });
                    }}
                    checked={handleCheck({ service: option, status: value })}
                  />
                </Box>
              }
              label={`${label}`}
            />
          ))}
        </FormGroup>
      </Box>
    </FormControl>
  );
};

export { EventCheckList };
