import { createSlice } from "@reduxjs/toolkit";
import { getRetentions } from "../../thunks/retention/retention.thunks";
import { IRetentionState } from "../../interfaces/RetentionState.interface";

export const initialState: IRetentionState = {
  isLoadingRetention: false,
  retentionInfo: {
    records: [],
    total: 0,
  },
};

export const retentionAppSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getRetentions.pending, (state) => {
        state.isLoadingRetention = true;
      })
      .addCase(getRetentions.fulfilled, (state, action) => {
        state.retentionInfo = action.payload;
        state.isLoadingRetention = false;
      })
      .addCase(getRetentions.rejected, (state) => {
        state.isLoadingRetention = false;
      });
  },
  initialState,
  name: "retentionApp",
  reducers: {},
});

export default retentionAppSlice.reducer;
