import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  getBusinessRules,
  getDeferredList,
  getNodeInfo,
  getCardAdminMerchant,
  deleteRule,
  deleteDeferred,
  getAppConfigPaymentMethods,
  getPayoutBusinessRules,
  deletePayoutRule,
} from "../../../store/thunks/layout/layout.thunks";
import { useSearchParams } from "react-router-dom";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import { GetBusinessRulesRequest } from "../../../../types/get_business_rules_request";
import { UseOnInitProcessing } from "./useOnInitProcessing.interface";
import { PathParamsEnum } from "../../../shared/enums/PathParamsEnum";
import { get, defaultTo, isEmpty, includes, set } from "lodash";
import {
  ConfigurationCodeEnum,
  tabIndexToProcessingStep,
} from "../../../shared/enums/ProcessingStepEnum";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import {
  getBasicMerchantInformation,
  setBasicMerchantInformation,
} from "../../../shared/utils/local-storage/basic-merchant-information";
import { BasicMerchantInformationEnum } from "../../../shared/infrastructure/enums/BasicMerchantInformationEnum";
import {
  CountriesEnum,
  DEFERRED_COUNTRIES,
} from "../../../shared/infrastructure/enums/CountriesEnum";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import { filterNodeInfoByConfiguration } from "../../../shared/utils/filter-node-info-by-conf";
import {
  changeTab,
  dismissSnackBar,
  setBranchEditList,
  setMassiveBranches,
  setMassiveInformation,
  showDeleteModal,
  showLoadingModal,
  changeLoadingValue,
} from "../../../store/reducers/layout/layout.slice";
import { DeleteRuleModalProperties } from "../../../shared/constants/modal-info";
import { LoadingModal } from "../../../store/interfaces/layout/loading-modal.interface";
import { LoadingModalEnum } from "../../../shared/enums/LoadingModalEnum";
import { IBasicMerchantInformation } from "../../../shared/infrastructure/interfaces/IBasicMerchantInformation";
import { TabIndexRecord } from "../../../shared/enums/TabIndexEnum";
import { getMerchantId } from "../../../utils/utilsFile";
import {
  setBusinessRulesBatch,
  setIsMassiveRules,
  setTimeoutPromise,
} from "../../../shared/utils/local-storage/massive-rules-information";
import {
  GetBusinessRulesResponse,
  RuleItem,
} from "../../../../types/get_business_rules_response";
import { BranchEditList } from "../../../../types/branch_edit_list";
import { BranchEditListPathEnum } from "../../../shared/infrastructure/enums/SessionStoragePathEnum";
import { BATCH } from "../../../shared/constants/branch_massive";
import { LocalStoragePathEnum } from "../../../shared/infrastructure/enums/LocalStoragePathEnum";
import { DeferredOptions } from "../../../../types/get_deferred_admin_merchant_response";
import { ProcessorConstants } from "../../../shared/constants/ProcessorConstants";
import {
  getStorageBranchEditList,
  isBatchPath,
  listBranchesSelected,
} from "../../../utils/utilsFile";
import { IconCheck, IconCircleWarn, useSnackbar } from "@kushki/connect-ui";
import { SnackBarVariantEnum } from "../../../shared/infrastructure/enums/SnackBarVariantEnum";
import { SnackBarColors } from "../../../shared/infrastructure/enums/SnackBarColorsEnum";
import { GetPayoutsBusinessRulesRequest } from "../../../../types/get_payouts_business_rules_request";
import {
  GetPayoutsBusinessRulesResponse,
  PayoutRule,
} from "../../../../types/get_payouts_business_rules_response";

export const useOnInitProcessing = (isBatch?: boolean): UseOnInitProcessing => {
  const [searchParams] = useSearchParams();
  const publicMerchantId = defaultTo(
    searchParams.get(PathParamsEnum.PUBLIC_MERCHANT_ID),
    getMerchantId()
  );
  const { showSnackbar } = useSnackbar();
  const [filterNodeInfoConfigByTab, setFilterNodeInfoConfigByTab] = useState<
    Configs
  >({});
  const dispatch = useAppDispatch();
  const {
    nodeInfo,
    businessRules,
    snackBarInfo,
    modalInfo,
    deferred,
    tabSelected,
    loadingModal,
    deleteModal,
    openAlertDeferredPE,
    loadingNodeInfo,
    isMassiveBranches,
    merchantDeferredData,
    payoutsBusinessRules,
  } = useAppSelector((store) => store.layout);
  const [ruleID, setRuleID] = React.useState<string>("");
  const [deferredID, setDeferredID] = React.useState<string>("");
  const isMassivePath: boolean = isBatchPath();
  const [deferredLocalS, setDeferredLocalS] = React.useState<DeferredOptions>(
    get(merchantDeferredData, "deferredOptions", []) as DeferredOptions
  );

  useEffect(() => {
    if (snackBarInfo.isOpen)
      showSnackbar({
        message: get(snackBarInfo, "message", ""),
        variant: get(snackBarInfo, "variant", SnackBarVariantEnum.SIMPLE),
        color: get(snackBarInfo, "color", SnackBarColors.SUCCESS),
        withIcon: get(snackBarInfo, "withIcon", true),
        icon: get(
          snackBarInfo,
          "icon",
          snackBarInfo.isError ? <IconCircleWarn /> : <IconCheck />
        ),
      });
  }, [snackBarInfo]);

  const getNodeInformation = (publicMerchantId: string) => {
    const configIds = ConfigurationCodeEnum.PROCESSOR.concat(
      ",",
      ConfigurationCodeEnum.BUSINESS_RULES,
      ",",
      ConfigurationCodeEnum.DEFERRED
    );
    const nodeInfoRequest: GetNodeInfoRequest = {
      publicMerchantId,
      configIds,
    };
    dispatch(getNodeInfo(nodeInfoRequest));
  };

  const getBasicInformation = () => {
    dispatch(changeLoadingValue({ property: "loadingNodeInfo", value: true }));

    if (publicMerchantId) {
      getNodeInformation(publicMerchantId);
    }
  };
  const cardsPeruMassive = (country: string, deferredValue: string) => {
    if (country === CountriesEnum.PERU) {
      dispatch(
        getCardAdminMerchant({
          merchantId: deferredValue,
        })
      );
    }
  };
  const getBranchEditList = () => {
    const item_value: BranchEditList = getStorageBranchEditList();
    const basicMerchantInformation: IBasicMerchantInformation = getBasicMerchantInformation();
    const path: string[] = window.location.pathname.split("/");
    localStorage.removeItem(LocalStoragePathEnum.CONSOLE_MERCHANT_MASSIVE);
    dispatch(setMassiveBranches(false));
    if (
      !isEmpty(get(item_value, BranchEditListPathEnum.DATA, [])) &&
      path.includes(BATCH)
    ) {
      dispatch(setMassiveBranches(true));
      dispatch(setBranchEditList({ data: item_value.data }));
      localStorage.setItem(
        LocalStoragePathEnum.CONSOLE_MERCHANT_MASSIVE,
        JSON.stringify(true)
      );
      setBasicMerchantInformation({
        country: defaultTo(
          get(item_value, "data[0].country", ""),
          get(item_value, "data[0].constitutional_country", "")
        ),
        origin: ProcessorConstants.ORIGIN,
        redirectPath: location.pathname.concat(location.search),
      });
      if (basicMerchantInformation.redirectTab)
        dispatch(
          changeTab(TabIndexRecord[basicMerchantInformation.redirectTab])
        );
      const merchantIdMassive: Configs = findConfigById(
        ConfigurationCodeEnum.DEFERRED,
        get(item_value, "data[0].configs", { value: "" })
      );
      cardsPeruMassive(
        defaultTo(
          get(item_value, "data[0].country", ""),
          get(item_value, "data[0].constitutional_country", "")
        ),
        get(merchantIdMassive, "value", "")
      );
    }
  };

  const setRuleIDForDelete = (ruleID: string) => {
    setRuleID(ruleID);
  };
  const setDeferredIDForDelete = (deferredID: string) => {
    setDeferredID(deferredID);
  };

  const deleteRuleByID = async (ruleID: string, payoutRule?: boolean) => {
    const merchantId = get(
      JSON.parse(
        defaultTo(localStorage.getItem("basicMerchantInformation"), "{}")
      ),
      "publicMerchantId",
      ""
    );
    const limit: number = 1000;

    if (payoutRule) {
      await dispatch(deletePayoutRule({ ruleID }));
      closeDeleteRuleModal();
      await setTimeoutPromise(3000);
      await dispatch(
        getPayoutBusinessRules({
          merchantId,
          limit,
          offset: 0,
        })
      );
    } else {
      await dispatch(deleteRule({ ruleID }));
      await dispatch(
        getBusinessRules({
          merchantId,
          limit,
        })
      );
      closeDeleteRuleModal();
    }
  };

  const deleteRuleByIdStorage = (ruleID: string, payoutRule?: boolean) => {
    const ruleItems: RuleItem[] | PayoutRule[] = payoutRule
      ? [...payoutsBusinessRules]
      : [...businessRules.items];
    const indexOfObject = ruleItems.findIndex((object) => object.id === ruleID);
    ruleItems.splice(indexOfObject, 1);

    if (payoutRule) {
      setBusinessRulesBatch<GetPayoutsBusinessRulesResponse>(
        { data: ruleItems, total: ruleItems.length },
        LocalStoragePathEnum.PAYOUTS_BUSINESS_RULES_BATCH
      );
      dispatch(
        getPayoutBusinessRules({
          isBatch,
          merchantId: "",
          limit: 1000,
          offset: 0,
        })
      );
    } else {
      setBusinessRulesBatch<GetBusinessRulesResponse>(
        { items: ruleItems as RuleItem[] },
        LocalStoragePathEnum.BUSINESS_RULES_BATCH
      );
      dispatch(
        getBusinessRules({
          merchantId: "",
          limit: 1000,
          isBatch,
        })
      );
    }
    dispatch(
      showDeleteModal({
        ...DeleteRuleModalProperties.DELETE_RULE_MODAL,
        isOpen: false,
      })
    );
  };

  const deleteDeferredByID = async (deferredId: string) => {
    const deleteProcessor: LoadingModal = {
      message: LoadingModalEnum.DELETE_DEFERRED,
      isOpen: true,
    };

    const merchantId = get(
      JSON.parse(
        defaultTo(localStorage.getItem("basicMerchantInformation"), "{}")
      ),
      "publicMerchantId",
      ""
    );
    await dispatch(showLoadingModal(deleteProcessor));
    await dispatch(deleteDeferred({ deferredId, merchantId }));
    await dispatch(getDeferredList({ merchantId }));
    dispatch(
      showDeleteModal({
        ...DeleteRuleModalProperties.DELETE_RULE_MODAL,
        isOpen: false,
      })
    );
    setTimeout(() => dispatch(dismissSnackBar()), 3000);
  };

  const closeDeleteRuleModal = () => {
    dispatch(
      showDeleteModal({
        ...DeleteRuleModalProperties.DELETE_RULE_MODAL,
        isOpen: false,
      })
    );
  };

  useEffect(() => {
    getBasicInformation();
    setIsMassiveRules(isBatch);
    getBranchEditList();
    dispatch(getAppConfigPaymentMethods());
    if (isMassivePath) dispatch(setMassiveInformation(listBranchesSelected()));
  }, []);

  const callDependantInformation = (nodeInfo: GetNodeInfoResponse) => {
    const merchantId: string = get(nodeInfo, NodeInfoPath.MerchantID, "");
    const country: string = get(nodeInfo, NodeInfoPath.InfoCountry, "");
    const limit: number = 1000;
    const businessRulesRequest: GetBusinessRulesRequest = {
      merchantId,
      limit,
    };
    const payoutsBusinessRulesReq: GetPayoutsBusinessRulesRequest = {
      merchantId,
      limit,
      offset: 0,
    };

    if (isBatch) {
      set(businessRulesRequest, "isBatch", isBatch);
      set(payoutsBusinessRulesReq, "isBatch", isBatch);
    }

    dispatch(getBusinessRules(businessRulesRequest));
    dispatch(getPayoutBusinessRules(payoutsBusinessRulesReq));
    if (isDeferredAllowed(country)) {
      const basicMerchantInformation: IBasicMerchantInformation = getBasicMerchantInformation();

      if (basicMerchantInformation.redirectTab)
        dispatch(
          changeTab(TabIndexRecord[basicMerchantInformation.redirectTab])
        );
    }
  };

  useEffect(() => {
    if (
      !isEmpty(nodeInfo) &&
      !isEmpty(nodeInfo.merchantId) &&
      !isMassiveBranches
    ) {
      setBasicMerchantInformation({
        country: get(nodeInfo, NodeInfoPath.InfoCountry, ""),
        constitutionalCountry: get(
          nodeInfo,
          NodeInfoPath.InfoConstitutionalCountry,
          ""
        ),
        name: get(nodeInfo, NodeInfoPath.InfoName, ""),
        publicMerchantId: get(nodeInfo, NodeInfoPath.InfoPublicMerchantID, ""),
        nodeId: get(nodeInfo, NodeInfoPath.NodeID, ""),
        origin: BasicMerchantInformationEnum.CONSOLE_MERCHANT,
        redirectPath: location.pathname.concat(location.search),
      });
      callDependantInformation(nodeInfo);
    }
  }, [nodeInfo]);

  useEffect(() => {
    if (isMassiveBranches) {
      const businessRulesRequest: GetBusinessRulesRequest = {
        merchantId: "",
        limit: 0,
        isBatch: true,
      };
      dispatch(getBusinessRules(businessRulesRequest));
      dispatch(getPayoutBusinessRules({ ...businessRulesRequest, offset: 0 }));
    }
  }, [isMassiveBranches]);

  useEffect(() => {
    const actualProcessingStep = tabIndexToProcessingStep.get(tabSelected);

    setFilterNodeInfoConfigByTab(
      filterNodeInfoByConfiguration(nodeInfo, actualProcessingStep)
    );
  }, [tabSelected, nodeInfo]);

  useEffect(() => {
    setDeferredLocalS(
      get(merchantDeferredData, "deferredOptions", []) as DeferredOptions
    );
  }, [merchantDeferredData]);

  return {
    isMassivePath,
    businessRules,
    payoutsBusinessRules,
    openAlertDeferredPE,
    modalInfo,
    isMassiveBranches,
    deferred: isMassiveBranches ? deferredLocalS : deferred,
    tabSelected,
    filterNodeInfoConfigByTab,
    loadingModal,
    isLoading: loadingNodeInfo,
    nodeInfo,
    deleteModalInfo: {
      deleteRuleByID,
      deleteDeferredByID,
      deleteModal,
      ruleID,
      deferredID,
      setRuleIDForDelete,
      setDeferredIDForDelete,
      closeDeleteRuleModal,
      deleteRuleByIDStorage: deleteRuleByIdStorage,
    },
  };
};

export const findConfigById = (
  id: ConfigurationCodeEnum,
  configs: Configs[]
): Configs => {
  const config = configs.find((config) => {
    const configId = get(config, "configuration", "");
    return configId === id;
  });

  return defaultTo(config, { value: "" });
};

export const isDeferredAllowed = (merchantCountry: string): boolean => {
  return includes(DEFERRED_COUNTRIES, merchantCountry);
};
