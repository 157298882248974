import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { UploadSelectableEC } from "../UploadSelectableEC/UploadSelectableEC";
import { UploadSelectableCO } from "../UploadSelectableCO/UploadSelectableCO";
import { capitalize } from "lodash";
const useStyles = makeStyles({
  root: {
    minHeight: "350px",
  },
});
export interface IUploadSelectableProps {
  country: CountryValueEnum;
}
export const UploadSelectable: React.FC<IUploadSelectableProps> = (
  props: IUploadSelectableProps
) => {
  const { country } = props;
  const classes = useStyles();

  const identifiedDashboardByCountry = (): JSX.Element => {
    switch (capitalize(country!)) {
      case CountryValueEnum.Colombia:
        return <UploadSelectableCO />;
      case CountryValueEnum.Ecuador:
      default:
        return <UploadSelectableEC />;
    }
  };

  return (
    <Card>
      <CardContent className={classes.root}>
        <Grid
          className={classes.root}
          container
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          {identifiedDashboardByCountry()}
        </Grid>
      </CardContent>
    </Card>
  );
};
