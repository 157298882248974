import React, { forwardRef } from "react";
import { InputAttributes } from "react-number-format";
import { default as NumberFormat } from "react-number-format";
import { INumericInputFieldProps } from "../NumericInputField/NumericInputField.interfaces";
import { numberFormatOnChange } from "../../../shared/utils/formValidations/form_validations";

const NumberFormatInput = forwardRef<
  NumberFormat<InputAttributes>,
  INumericInputFieldProps
>(function NumberFormatCustom(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      // eslint-disable-next-line react/prop-types
      onValueChange={numberFormatOnChange.bind(null, onChange, props.name)}
      allowNegative={false}
    />
  );
});

export default NumberFormatInput;
