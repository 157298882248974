import { getCatalog } from "../../../shared/constants/MerchantInformationConstants";
import { CurrencyEnum } from "../../../shared/enums/CurrencyEnum";
import { FrequencyEnum } from "../../../shared/enums/FrequencyEnum";
import { IPaymentMethodObject } from "../../../shared/infrastructure/interface/IPaymentMethodObject";
import { ProcessorTypeEnum } from "../../../shared/enums/ProcessorTypeEnum";
import { StatusTypeEnum } from "../../../shared/enums/StatusTypeEnum";
import { FrequencyNameEnum } from "../../../shared/enums/FrequencyNameEnum";
import { PeriodEnum } from "../../../shared/enums/PeriodEnum";
import { CountriesEnum } from "../../../shared/enums/CountriesEnum";
import {
  BillingInfoStateProps,
  IGetNameOnCatalog,
} from "./useBillingInfoState.interfaces";
import { TypeChargeEnum } from "../../../shared/enums/TypeChargeEnum";

export const useBillingInfoState = (): BillingInfoStateProps => {
  const mapFrequency = (frequency: string): string => {
    switch (frequency) {
      case FrequencyEnum.DAILIY:
        return FrequencyNameEnum.DAILIY;
      case FrequencyEnum.THREEWEEKLY:
        return FrequencyNameEnum.THREEWEEKLY;
      case FrequencyEnum.WEEKLY:
        return FrequencyNameEnum.WEEKLY;
      case FrequencyEnum.BIWEEKLY:
        return FrequencyNameEnum.BIWEEKLY;
      case FrequencyEnum.TWOWEEKLY:
        return FrequencyNameEnum.TWOWEEKLY;
      case FrequencyEnum.MONTHLY:
        return FrequencyNameEnum.MONTHLY;
      case FrequencyEnum.NONE:
        return FrequencyNameEnum.NONE;
      default:
        return frequency;
    }
  };

  const formatAccountNumber = (account: string): string =>
    account !== "N/A" && account !== null
      ? `****${account.substr(-4)}`
      : account;

  const getNameOnCatalog: IGetNameOnCatalog = (
    catalog,
    country,
    search,
    defaultResponse = "N/A"
  ) => {
    let response: string = defaultResponse;

    getCatalog(country, catalog).forEach(
      (result) => (response = result.value === search ? result.name : response)
    );

    return response;
  };

  const getCurrency = (country: string) => {
    switch (country) {
      case CountriesEnum.COLOMBIA:
        return CurrencyEnum.COP;
      case CountriesEnum.ECUADOR:
        return CurrencyEnum.USD;
      case CountriesEnum.CHILE:
        return CurrencyEnum.CLP;
      case CountriesEnum.PERU:
        return CurrencyEnum.PEN;
      case CountriesEnum.MEXICO:
        return CurrencyEnum.MXN;
      default:
        return CurrencyEnum.USD;
    }
  };

  const formatGracePeriod = (gracePeriod: number): string => {
    return gracePeriod !== 1
      ? gracePeriod + " " + PeriodEnum.PERIODS
      : gracePeriod + " " + PeriodEnum.PERIOD;
  };

  const hasDispersionProcessor = (
    paymentMethods: IPaymentMethodObject | undefined
  ): boolean => {
    if (paymentMethods) {
      return !!Object.entries(paymentMethods).find(
        ([key]) =>
          (paymentMethods[`${key}`].processorType ===
            ProcessorTypeEnum.AGGREGATOR ||
            paymentMethods[`${key}`].processorType ===
              ProcessorTypeEnum.GATEWAY_AGGREGATOR) &&
          paymentMethods[`${key}`].status === StatusTypeEnum.ACTIVE
      );
    }

    return false;
  };

  const formatDate = (
    epoch: number,
    country: string,
    graceMonths: number
  ): string => {
    let countryCode = country.substr(0, 2).toUpperCase();
    let date = new Date(epoch);
    let months = graceMonths + date.getMonth();

    date.setMonth(months);
    let graceDate = date.toLocaleString("es-" + countryCode, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });

    return graceDate;
  };

  const mapTypeMinCharge = (
    charge: string
  ): { type: string; typeTooltip: string; minTooltip: string } => {
    switch (charge) {
      case TypeChargeEnum.DEDUCTIBLE:
        return {
          minTooltip:
            "Es el monto mínimo comisional, que el comercio debe generar para evitar que se le realice el cobro por incumplimiento de lo establecido.",
          type: "Deducible",
          typeTooltip:
            "Es la modalidad de cobro, donde el comercio tiene que pagar a Kushki sólo la diferencia entre las comisiones totales y el monto del mínimo comisional establecido.Ej.: Si las comisiones totales suman 80.00 USD y el monto deducible (cobro mínimo) es 100.00 USD, se cobrará  al comercio 20.00 USD de mínimo comisional.",
        };
      case TypeChargeEnum.FIXED:
        return {
          minTooltip:
            "Para el cobro mínimo comisional, se considera el total de las comisiones que hayan obtenido los merchant id asociados al mismo tax id y se restará el monto mínimo comisional establecido.",
          type: "Fijo",
          typeTooltip:
            "Es la modalidad de cobro, donde el comercio tiene que pagar a Kushki el total del monto mínimo comisional establecido. Ej.: Si las comisiones totales suman 80.00 USD y el monto fijo (cobro mínimo) es 100.00 USD, se cobrará al comercio 100.00 USD de mínimo comisional.",
        };
      case TypeChargeEnum.NONE:
        return { minTooltip: "-", type: "-", typeTooltip: "-" };
      default:
        return { minTooltip: "", type: "", typeTooltip: "" };
    }
  };

  return {
    handlers: {
      formatAccountNumber,
      formatDate,
      formatGracePeriod,
      getCurrency,
      getNameOnCatalog,
      hasDispersionProcessor,
      mapFrequency,
      mapTypeMinCharge,
    },
  };
};
