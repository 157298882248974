import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";

export interface WhitelistCardSectionProps {
  isLoading: boolean;
  sectionComponent: JSX.Element;
  sectionSkeletonComponent: JSX.Element;
}

export const WhitelistCardSection: React.FC<WhitelistCardSectionProps> = (
  props: WhitelistCardSectionProps
) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              direction="column"
              style={{ display: !props.isLoading ? "none" : "block" }}
            >
              {props.sectionSkeletonComponent}
            </Grid>
            <Grid
              container
              spacing={2}
              direction="column"
              style={{ display: props.isLoading ? "none" : "block" }}
            >
              {props.sectionComponent}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
};
