import { routes } from "../routes";
import { CountryValueEnum } from "./CountryEnum";
import { IOptionFormat } from "../interfaces/IOptionFormat";
import { TabEnum } from "./TabEnum";

export enum FormatUpload {
  form = "form",
  csv = "csv",
  xlsx = "xslx",
}

export enum FormatFileAccepted {
  csv = "text/csv",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

enum MenuItemNameEnum {
  UPLOAD_CSV = "Cargar con archivo .CSV",
  UPLOAD_XLSX = "Cargar con archivo .xlsx",
  UPLOAD_FORM = "Cargar con formulario",
}

const OPTIONS_FORMAT: IOptionFormat[] = [
  {
    name: MenuItemNameEnum.UPLOAD_CSV,
    value: FormatUpload.csv,
    url: routes.WITH_CSV,
  },
  {
    name: MenuItemNameEnum.UPLOAD_FORM,
    value: FormatUpload.form,
    url: routes.WITH_FORM,
  },
];

const OPTIONS_CATALOG_FORMAT: IOptionFormat[] = [
  {
    name: MenuItemNameEnum.UPLOAD_XLSX,
    url: routes.CATALOG_FILE,
    value: FormatUpload.csv,
  },
  {
    name: MenuItemNameEnum.UPLOAD_FORM,
    url: routes.CATALOG_FILE,
    value: FormatUpload.form,
    event: TabEnum.CATALOG,
  },
];

export const FormatFileAcceptedCatalog: Record<string, string> = {
  [FormatUpload.csv]: FormatFileAccepted.csv,
  [FormatUpload.xlsx]: FormatFileAccepted.xlsx,
};

export const buildOptionsFormatWithCountry = (
  country: CountryValueEnum,
  isCatalogTab: boolean
): IOptionFormat[] => {
  const option_format: IOptionFormat[] = isCatalogTab
    ? OPTIONS_CATALOG_FORMAT
    : OPTIONS_FORMAT;

  return option_format.map((option: IOptionFormat) => ({
    ...option,
    url: `${routes.BASE_PATH_RETENTIONS}${option.url}?country=${country}`,
  }));
};
