import { defaultTo, isUndefined } from "lodash";
import { CountryNameEnum, CountryValueEnum } from "../Enums/CountryEnum";

export interface ICountry {
  value: string;
  name: string;
  flag: string;
}
export const getCountry = (country: string): ICountry => {
  const countryValue: CountryValueEnum = defaultTo(
    country,
    CountryValueEnum.Ecuador
  ) as CountryValueEnum;
  const countryFind: ICountry = COUNTRIES.find(
    (itemCountry: ICountry) =>
      itemCountry.value.toLowerCase() === countryValue.toLowerCase()
  )!;
  return !isUndefined(countryFind) ? countryFind : COUNTRIES[0];
};
export const COUNTRIES: ICountry[] = [
  {
    value: CountryValueEnum.Ecuador,
    name: CountryNameEnum.Ecuador,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
  },
  {
    value: CountryValueEnum.Colombia,
    name: CountryNameEnum.Colombia,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
  },
  {
    value: CountryValueEnum.Peru,
    name: CountryNameEnum.Peru,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
  },
  {
    value: CountryValueEnum.Chile,
    name: CountryNameEnum.Chile,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
  },
  {
    value: CountryValueEnum.Mexico,
    name: CountryNameEnum.Mexico,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
  },
  {
    value: CountryValueEnum.Brazil,
    name: CountryNameEnum.Brazil,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
  },
  {
    value: CountryValueEnum.ElSalvador,
    name: CountryNameEnum.ElSalvador,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
  },
  {
    value: CountryValueEnum.Panama,
    name: CountryNameEnum.Panama,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
  },
  {
    value: CountryValueEnum.CostaRica,
    name: CountryNameEnum.CostaRica,
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
  },
];
