import { InvoiceModeEnum } from "../enums/InvoiceModeEnum";

export const InvoiceModeCatalog = [
  {
    name: "Facturar con IVA",
    value: InvoiceModeEnum.WITH_IVA,
  },
  {
    name: "Facturar sin IVA",
    value: InvoiceModeEnum.WITHOUT_IVA,
  },
];
