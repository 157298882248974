import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { DeferredSettingsSectionConstants } from "../../shared/infrastructure/constants/DeferredConstants";
import { PopUpDeferredQuotas } from "../PopUpDeferredQuotas/PopUpDeferredQuotas";
import { MonthChipSelector } from "../MonthChipSelector/MonthChipSelector";
import { useDeferredSettingsStyles } from "./DeferredSettings.style";
import { IDeferredSettingsSectionProps } from "./DeferredSettings.interface";

export const DeferredSettings: React.FC<IDeferredSettingsSectionProps> = (
  props: IDeferredSettingsSectionProps
) => {
  const classes = useDeferredSettingsStyles();

  return (
    <div className={classes.paddingContent}>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.titleContent}>
              {DeferredSettingsSectionConstants.QUOTAS_TITLE}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.descriptionContent}>
              {DeferredSettingsSectionConstants.QUOTAS_DESCRIPTION}
            </Typography>
          </Grid>
        </Grid>
        <PopUpDeferredQuotas
          months={props.months}
          disable={false}
          handleSelectedMonth={props.handleSelectedMonth}
        />
        <MonthChipSelector
          handleDeleteSelectedMonth={props.handleDeleteSelectedMonth}
          months={props.months}
        />
      </Grid>
    </div>
  );
};
