import React, { FC } from "react";
import { Box } from "@mui/material";
import { basicStateContainerMassiveStyles as styles } from "./BasicStateContainerMassive.styles";
import { BasicContainer } from "../BasicContainer/BasicContainer";
import { IButtonOptions } from "../../components/FooterOptions/FooterOptions.interfaces";
import { useBasicFormStateContainerMassive } from "./state/useBasicFormStateContainerMassive";
import { get, isEmpty } from "lodash";
import { ModalLoader } from "@kushki/connect-ui";
import { LayoutStepContainerBranch } from "../LayoutStepContainerBranch/LayoutStepContainerBranch";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { ActionFooterEnum } from "../../shared/enums/ActionFooterEnum";
import { isAvailableCountry } from "../../shared/services/CatalogPersonTypeByCountry";
import { CountryEnum } from "../../shared/countryEnum";
import { BasicContainerSkeleton } from "../../components/Skeletons/BasicContainerSkeleton/BasicContainerSkeleton";

const BasicStateFormContainerMassive: FC = () => {
  const {
    auxRules,
    continueButton,
    countriesInfo: { country, constitutionalCountry },
    countryElements,
    form,
    footerLabel,
    headerProps,
    saveValues,
    saveButton,
    modalOpen,
    numberBranches,
    showGenericInput,
    isEdit,
    isDisable,
    isModalFinalization,
    setFinalization,
    textSave,
    title,
    isLoadingBasicInformation,
  } = useBasicFormStateContainerMassive();

  const secondaryButton: IButtonOptions = {
    isDisabled: isDisable
      ? isDisable
      : isEmpty(form.watch("rfc"))
      ? true
      : saveButton,
    isHidden: true,
    onAction: async () => {
      saveValues(ActionFooterEnum.SAVE);
    },
  };

  const primaryButton: IButtonOptions = {
    isDisabled: continueButton,
    isHidden: true,
    onAction: () => {
      setFinalization(true);
    },
  };

  return (
    <>
      <LayoutStepContainerBranch
        headerProps={{
          country: isAvailableCountry(country) ? country : CountryEnum.generic,
          isEdit,
          numberBranches,
          tabsInfo: headerProps.tabsNodeInfo,
          title: `${title}`,
        }}
        renderComponent={
          <Box sx={styles.boxContent}>
            {isLoadingBasicInformation ? (
              <BasicContainerSkeleton />
            ) : (
              <BasicContainer
                country={constitutionalCountry}
                title={get(headerProps.nodeInfo, "generalInfo.clientType")}
                form={form}
                countryElements={countryElements}
                auxRules={auxRules}
                showGenericInput={showGenericInput}
                isDisable={isDisable}
              />
            )}
            <>
              <ModalLoader
                descriptionText={LOADER_DESCRIPTION}
                titleText={LOADER_TITLE}
                isOpen={modalOpen}
              />
            </>
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: textSave,
          label: footerLabel,
          primaryButton,
          secondaryButton,
        }}
        modalProps={{
          isModalFinalization: isModalFinalization,
          saveValues: saveValues,
          setFinalization: setFinalization,
        }}
      />
    </>
  );
};

export default BasicStateFormContainerMassive;
