import React, { useEffect, useRef, useState } from "react";
import {
  IFiltersDashboard,
  IUseFilterDashboard,
  IUseFilterDashboardProps,
} from "./useDashboard.interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import {
  cloneDeep,
  get,
  has,
  isEmpty,
  isEqual,
  isNil,
  omitBy,
  set,
} from "lodash";
import {
  addAditionalProperties,
  searchMerchants,
} from "../../../store/thunks/customer/searchMerchants.thunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { builderRows } from "../../Table/TableNodes/constants";
import { IItemList } from "../../TableCells/CellActionMenu/CellActionMenu.interfaces";
import { ITableRowProps } from "../../Table/TableNodes/interfaces";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import {
  validateMultipleStatus,
  validateMultipleStatusPending,
  verifyEditButton,
  verifyIsPending,
  verifyStatus,
} from "../../../shared/utils/verifyStatus.utils";
import { CATALOG_ROWS_NODE } from "../../../shared/catalogs/CatalogConfigTable";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { RootState } from "../../../store/store";
import { useSnackbar } from "@kushki/connect-ui";
import {
  resetCustomerList,
  setChangeStatus,
  setDisabledActiveBtn,
  setIsDisabledEditBtn,
} from "../../../store/actions/customer.action";
import { useNavigate } from "react-router-dom";
import { redirectPageUtils } from "../../../shared/utils/redirectPage.utils";
import { REDIRECT_PATH_EDIT_MASSIVE_BRANCH } from "../../../shared/constants/content_slides";
import { SessionStoragePath } from "../../../shared/constants/sessionStoragePath";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { getFilteredBalanceFromCustomerFamily } from "../../../store/thunks/balance/balance.thunk";
import { MENU_OPTIONS } from "../../../shared/constants/menu_options";

export const useDashboard = ({
  entityName,
  path,
  nodeData,
  checkModalStatusText,
  handleChangeStatus,
  customerNode,
  showEmptyScreen,
}: IUseFilterDashboardProps): IUseFilterDashboard => {
  const dispatchApp = useAppDispatch();
  const {
    ownerDetail,
    notifyChangeStatus,
    customerList,
    loadEdit,
    isDisabledEditBtn,
    isDisabledActiveBtn,
    isLoadingMerchant,
  } = useAppSelector((state: RootState) => ({
    ...state.branchReducer,
    ...state.ownerReducer,
    ...state.customerReducer,
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const totalData: number = get(nodeData, "total", 0);

  const filtersRef = useRef({});

  const [initialFilters] = useState<IFiltersDashboard>({
    entityName,
    filterDetailBar: {},
    name: "",
    paginationFilters: {
      offset: rowsPerPage * page,
      rowsPerPage: rowsPerPage,
    },
    path,
  });
  const [filterDashboard, setFilterDashboard] =
    useState<IFiltersDashboard>(initialFilters);

  const [nodeDataSelected, setNodeDataSelected] = useState<MerchantNodeData>(
    {}
  );
  const [showMassiveActions, setShowMassiveActions] = useState<boolean>(false);
  const [itemsSelected, setItemsSelected] = useState<MerchantNodeData[]>([]);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [resetFilters, setResetFilters] = useState<boolean>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [resetItemChecked, setResetItemChecked] = useState<boolean>();
  const [openFamily, setOpenFamily] = useState<boolean>(false);
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [countData, setCountData] = useState<number>(0);
  const [processChangeStatus, setProcessChangeStatus] = useState<boolean>(true);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    });
  };

  const buildStringFilter = (filterItem: IFilterItem[]): string => {
    let value: string = "";

    filterItem.map((item: IFilterItem, index: number) => {
      value = value.concat(item.value);
      if (index + 1 < filterItem.length) {
        value = value.concat("|");
      }
    });

    return value;
  };

  const handleChangeFilterDetails = (
    selectedFilters: TFilterDetailBar[]
  ): void => {
    const filterRequest = {};

    selectedFilters.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case "País de Constitución":
          set(
            filterRequest,
            "constitutionalCountry",
            buildStringFilter(filter.items)
          );
          break;
        case "Estado":
          set(filterRequest, "merchantStatus", buildStringFilter(filter.items));
          break;
        case "Nombre":
          const isNum = /^\d+$/.test(buildStringFilter(filter.items));

          isNum
            ? set(filterRequest, "merchantId", buildStringFilter(filter.items))
            : set(filterDashboard, "name", buildStringFilter(filter.items));
          break;
        case "RangeDateFrom":
          set(filterDashboard, "from", buildStringFilter(filter.items));
          break;
        case "RangeDateTo":
          set(filterDashboard, "to", buildStringFilter(filter.items));
          break;
        case "País de Operación":
          set(filterRequest, "country", buildStringFilter(filter.items));
          break;
      }
    });

    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      filterDetailBar: filterRequest,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
      },
    });
  };

  const handleSelectedRows = (rowsSelected: MerchantNodeData[]) => {
    setShowMassiveActions(!isEmpty(rowsSelected));
    setItemsSelected(rowsSelected);
  };

  const builderSearchMerchantRequest = (
    filters: IFiltersDashboard
  ): SearchMerchantRequest => {
    const isStrictSearch: boolean = !isEmpty(
      get(filters, "filterDetailBar.merchantId", "")
    );

    return {
      entityName: get(filters, "entityName"),
      filter: get(filters, "filterDetailBar"),
      from: get(filters, "from"),
      limit: get(filters, "paginationFilters.rowsPerPage"),
      name: has(get(filters, "filterDetailBar"), "merchantId")
        ? undefined
        : get(filters, "name"),
      offset: get(filters, "paginationFilters.offset"),
      path: get(filters, "path"),
      strictSearch: isStrictSearch,
      to: get(filters, "to"),
    };
  };

  const closeModalStatus = () => {
    setIsOpenModalStatus(false);
  };

  const inactiveItems = () => {
    checkModalStatusText("", itemsSelected.length);
    setIsOpenModalStatus(true);
  };

  const handleCloseFamily = () => {
    setOpenFamily(false);
  };

  const builderMenuActions = (): IItemList[] => {
    const menu_actions: IItemList[] = [];

    if (entityName === EntityNameEnum.CUSTOMER) {
      menu_actions.push({
        action: () => {
          navigate(
            `/${path}/customers/${get(nodeDataSelected, "node_id", "")}`
          );
          dispatchApp(resetCustomerList());
        },
        text: MENU_OPTIONS.VIEW_BRANCHES,
      });
    }

    menu_actions.push({
      action: () => {
        setOpenFamily(true);
      },
      text: MENU_OPTIONS.VIEW_FAMILY,
    });

    if (
      entityName === EntityNameEnum.BRANCH &&
      nodeDataSelected.merchant_status === StatusEnum.ACTIVE
    ) {
      menu_actions.push({
        action: () => {
          window.location.href = API_ROUTES.RECHARGE_WALLET(
            get(nodeDataSelected, "merchant_id", "")
          );
        },
        text: MENU_OPTIONS.RECHARGE_WALLET,
      });

      menu_actions.push({
        action: () => {
          window.location.href = API_ROUTES.DEBIT_WALLET(
            get(nodeDataSelected, "merchant_id", "")
          );
        },
        text: MENU_OPTIONS.DEBIT_WALLET,
      });
    }

    menu_actions.push({
      action: () => {
        setItemsSelected([nodeDataSelected]);
        setProcessChangeStatus(!processChangeStatus);
      },
      text: MENU_OPTIONS.BUILD_BY_STATUS,
    });

    return menu_actions;
  };
  const rows: ITableRowProps[] = builderRows<MerchantNodeData>(
    get(nodeData, "data", []),
    CATALOG_ROWS_NODE[entityName],
    entityName,
    builderMenuActions(),
    page.toString()
  );

  const firstUpdate = useRef(true);
  const buildSessionBranchToEdit = () => {
    return itemsSelected.map((item) => ({
      branchName: get(item, "name", ""),
      centralize: false,
      clientType: get(item, "client_type", ""),
      constitucionalCountry: get(item, "constitutional_country", ""),
      publicMerchantId: get(item, "merchant_id", ""),
    }));
  };

  const redirectEditMassiveBranch = () => {
    sessionStorage.removeItem(SessionStoragePath.BRANCH_EDIT_LIST);
    sessionStorage.setItem(
      "branchList",
      JSON.stringify(buildSessionBranchToEdit())
    );

    sessionStorage.setItem(
      "customerMerchantId",
      get(customerNode, "merchantId", "")
    );
    redirectPageUtils(`${REDIRECT_PATH_EDIT_MASSIVE_BRANCH}`);
  };

  useEffect(() => {
    if (firstUpdate.current || isEqual(filtersRef.current, filterDashboard)) {
      firstUpdate.current = false;

      return;
    }

    const filters: SearchMerchantRequest =
      builderSearchMerchantRequest(filterDashboard);
    const clean_filters: object = omitBy(
      filters,
      (item) => isNil(item) || isEmpty(item)
    );

    filtersRef.current = cloneDeep(filterDashboard);

    setIsFiltering(
      has(clean_filters, "filter") ||
        has(clean_filters, "name") ||
        has(clean_filters, "from") ||
        has(clean_filters, "to")
    );

    dispatchApp(searchMerchants(filters));
  }, [filterDashboard]);

  useEffect(() => {
    if (entityName !== EntityNameEnum.OWNER && !isEmpty(customerList.data)) {
      const addProperties = async () => {
        await dispatchApp(addAditionalProperties(customerList.data!));
        if (entityName === EntityNameEnum.BRANCH) {
          dispatchApp(
            getFilteredBalanceFromCustomerFamily({
              dataResponse: customerList,
              filtersStatusEnum: [StatusEnum.ACTIVE, StatusEnum.INACTIVE],
            })
          );
        }
      };

      addProperties().catch(console.error);
    }
  }, [loadEdit]);

  useEffect(() => {
    if (!isEmpty(path)) {
      setHasFilters(false);
      setCountData(0);
      setFilterDashboard({
        ...initialFilters,
        entityName,
        path,
      });
    }
    setResetFilters(!resetFilters);
  }, [path, entityName]);

  useEffect(() => {
    setResetItemChecked(!resetItemChecked);
  }, [path, entityName, page]);

  useEffect(() => {
    if (notifyChangeStatus) {
      showSnackbar(notifyChangeStatus);
      if (notifyChangeStatus.color === "success") {
        setResetItemChecked(!resetItemChecked);
        setItemsSelected([]);
        setShowMassiveActions(false);
        setTimeout(() => {
          dispatchApp(
            searchMerchants(builderSearchMerchantRequest(filterDashboard))
          );
        }, 2200);
      }
    }
  }, [notifyChangeStatus]);

  useEffect(() => {
    if (!hasFilters) {
      setCountData(get(nodeData, "total", 0));
      setHasFilters(true);
    }
  }, [nodeData]);

  useEffect(() => {
    if (!firstUpdate.current) {
      if (nodeDataSelected.merchant_status === StatusEnum.ACTIVE) {
        checkModalStatusText(get(nodeDataSelected, "name", ""));
        setIsOpenModalStatus(true);
      }
      if (nodeDataSelected.merchant_status === StatusEnum.INACTIVE) {
        handleChangeStatus(itemsSelected, StatusEnum.INACTIVE);
        dispatchApp(
          setChangeStatus({
            entityName,
            status: StatusEnum.ACTIVE,
          })
        );
      }
    }
  }, [processChangeStatus]);

  useEffect(() => {
    dispatchApp(setIsDisabledEditBtn(verifyEditButton(itemsSelected)));
    if (verifyIsPending(itemsSelected)) {
      dispatchApp(setDisabledActiveBtn(true));
    } else {
      dispatchApp(setDisabledActiveBtn(false));
    }
  }, [itemsSelected]);

  return {
    countData,
    handleChangeFilterDetails,
    handleSelectedRows,
    itemsSelected,
    massiveActions: {
      activeStatusAction: () => {
        handleChangeStatus(itemsSelected, StatusEnum.INACTIVE);
        dispatchApp(
          setChangeStatus({
            entityName,
            status: StatusEnum.ACTIVE,
          })
        );
      },
      inactiveStatusAction: inactiveItems,
      isActivateStatus: verifyStatus(itemsSelected, StatusEnum.ACTIVE),
      isDisabledActiveBtn,
      isDisabledEditBtn,
      isEditBranch: validateMultipleStatus(itemsSelected, entityName),
      redirectEditBranch: redirectEditMassiveBranch,
      showBtnMassiveAction: validateMultipleStatusPending(itemsSelected),
    },
    modalFamilyProps: {
      customerData:
        entityName === EntityNameEnum.BRANCH ? customerNode : nodeDataSelected,
      entityName: entityName as EntityNameEnum,
      handleClose: handleCloseFamily,
      isLoadingMerchant,
      nodeDataSelected,
      open: openFamily,
      ownerData: ownerDetail as MerchantNodeData,
      path:
        entityName === EntityNameEnum.CUSTOMER
          ? `${path}:${get(nodeDataSelected, "node_id", "")}`
          : path,
    },
    modalStatus: {
      closeModal: closeModalStatus,
      handleClick: () => {
        handleChangeStatus(itemsSelected, StatusEnum.ACTIVE);
        setIsOpenModalStatus(false);
        dispatchApp(
          setChangeStatus({
            entityName,
            status: StatusEnum.INACTIVE,
          })
        );
      },
      isOpen: isOpenModalStatus,
    },
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    resetFilters,
    resetItemChecked,
    rows,
    setNodeDataSelected,
    showEmptyScreen: isFiltering ? false : showEmptyScreen,
    showMassiveActions: showMassiveActions,
  };
};
