import React from "react";
import { mainSubHeaderStyles as styles } from "./SubHeader.styles";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CountryEnum } from "../../shared/countryEnum";
import { Flag } from "../Flag/Flag";
import { useLocation } from "react-router";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { get, set } from "lodash";
import { FlagsNames } from "../../shared/enum/CountryEnumArray";
import { validCountries } from "../../shared/constants/validCountries";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { SubHeaderMerchantSkeleton } from "../Skeletons/SubHeaderMerchantSkeleton/SubHeaderMerchantSkeleton";

export interface SubHeaderProps {
  country: CountryEnum;
  id: string;
  redirectPath: string;
  title?: string;
  subtitle: string;
  iconType: JSX.Element;
  isEdit?: boolean;
}

export const Subheader: React.FC<SubHeaderProps> = (props: SubHeaderProps) => {
  const idTitlte = "ID:";
  const location = useLocation();

  const saveLastRoute = () => {
    const json = JSON.parse(
      sessionStorage.getItem("basicMerchantInformation") || "{}"
    );

    set(json, "layout.lastRoute", `/merchant${location.pathname}`);
    sessionStorage.setItem("basicMerchantInformation", JSON.stringify(json));
  };

  return (
    <Container disableGutters maxWidth={false}>
      <Box>
        <Grid container spacing={1} alignItems="center">
          {props.isEdit && (
            <IconButton
              style={{ color: "#4399ED" }}
              onClick={() => window.history.go(-1)}
              sx={styles.arrowBtn}
            >
              <ArrowBackIosIcon />
              Regresar
            </IconButton>
          )}
        </Grid>
        {props.title ? (
          <>
            <Grid
              container
              alignItems="center"
              sx={styles.gridTitle}
              spacing={1}
            >
              <Grid item>
                <Typography
                  color="text.primary"
                  fontSize={26}
                  variant="h1"
                  fontWeight={600}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid item spacing={2}>
                <ComponentSecurityWrapper
                  componentId={ComponentIdsEnum.M_CLIENTS_EDIT}
                >
                  <IconButton
                    sx={styles.iconColor}
                    onClick={() => {
                      saveLastRoute();
                      window.location.href = props.redirectPath;
                    }}
                  >
                    {props.iconType}
                  </IconButton>
                </ComponentSecurityWrapper>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item container xs={12} md={"auto"} alignItems="center">
                <Grid item sx={styles.gridText}>
                  <Typography variant="caption" color="text.primary">
                    {props.subtitle}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box sx={styles.flagPadding}>
                    {validCountries.includes(props.country) ? (
                      <Flag country={props.country} />
                    ) : (
                      <Flag country={CountryEnum.generic} />
                    )}
                  </Box>
                </Grid>
                <Grid item sx={styles.gridCountry}>
                  <Typography variant="body1" color="text.primary">
                    {get(FlagsNames[props.country], "title", props.country)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={"auto"} alignItems="center">
                <Divider orientation="vertical" flexItem sx={styles.divider} />
                <Grid item sx={styles.gridId}>
                  <Typography variant="caption" color="text.primary">
                    {idTitlte}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="text.primary">
                    {props.id}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    data-testid={"IconCopy"}
                    sx={styles.iconColor}
                    onClick={(e) => {
                      clipboard(props.id);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Copy />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <SubHeaderMerchantSkeleton />
        )}
      </Box>
    </Container>
  );
};

export default Subheader;
