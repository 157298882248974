import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

const SvgIcon = styled(MuiSvgIcon, {
  name: "IconBilling",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 16 16",
  focusable: "false",
  "aria-hidden": "true",
};

const IconBilling: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_22_72229)">
          <circle cx="18" cy="18" r="18" fill="#023365" />
          <g clip-path="url(#clip1_22_72229)">
            <path
              d="M25.9798 28H10.0202C8.90909 28 8 27.0576 8 25.9058V10.0942C8 8.94241 8.90909 8 10.0202 8H25.9798C27.0909 8 28 8.94241 28 10.0942V25.9058C28 27.0576 27.0909 28 25.9798 28ZM10.0202 9.04712C9.51515 9.04712 9.0101 9.57068 9.0101 10.0942V25.9058C9.0101 26.534 9.51515 26.9529 10.0202 26.9529H25.9798C26.5859 26.9529 26.9899 26.4293 26.9899 25.9058V10.0942C26.9899 9.46597 26.4848 9.04712 25.9798 9.04712H10.0202Z"
              fill="white"
            />
            <path
              d="M27.495 10.9319H8.50513V11.9791H27.495V10.9319Z"
              fill="white"
            />
            <path
              d="M24.7677 24.1257H16.1818C15.8788 24.1257 15.6768 23.9162 15.6768 23.6021C15.6768 23.288 15.8788 23.0785 16.1818 23.0785H24.8687C25.1717 23.0785 25.3737 23.288 25.3737 23.6021C25.3737 23.9162 25.0707 24.1257 24.7677 24.1257Z"
              fill="white"
            />
            <path
              d="M13.3535 24.1257H11.1313C10.8282 24.1257 10.6262 23.9162 10.6262 23.6021C10.6262 23.288 10.8282 23.0785 11.1313 23.0785H13.2525C13.5555 23.0785 13.7575 23.288 13.7575 23.6021C13.7575 23.9162 13.5555 24.1257 13.3535 24.1257Z"
              fill="white"
            />
            <path
              d="M14.7676 25.5916C13.7575 25.5916 12.8484 24.6492 12.8484 23.6021C12.8484 22.555 13.6565 21.6126 14.7676 21.6126C15.8787 21.6126 16.6868 22.555 16.6868 23.6021C16.6868 24.6492 15.7777 25.5916 14.7676 25.5916ZM14.7676 22.6597C14.2625 22.6597 13.8585 23.0785 13.8585 23.6021C13.8585 24.1257 14.2625 24.5445 14.7676 24.5445C15.2726 24.5445 15.6767 24.1257 15.6767 23.6021C15.6767 23.0785 15.2726 22.6597 14.7676 22.6597Z"
              fill="white"
            />
            <path
              d="M24.7677 15.9581H16.1818C15.8788 15.9581 15.6768 15.7487 15.6768 15.4346C15.6768 15.1204 15.8788 14.911 16.1818 14.911H24.8687C25.1717 14.911 25.3737 15.1204 25.3737 15.4346C25.3737 15.7487 25.0707 15.9581 24.7677 15.9581Z"
              fill="white"
            />
            <path
              d="M13.3535 15.9581H11.1313C10.8282 15.9581 10.6262 15.7487 10.6262 15.4346C10.6262 15.1204 10.8282 14.911 11.1313 14.911H13.2525C13.5555 14.911 13.7575 15.1204 13.7575 15.4346C13.7575 15.7487 13.5555 15.9581 13.3535 15.9581Z"
              fill="white"
            />
            <path
              d="M14.7676 17.4241C13.7575 17.4241 12.8484 16.4817 12.8484 15.4346C12.8484 14.3874 13.7575 13.445 14.7676 13.445C15.7777 13.445 16.6868 14.3874 16.6868 15.4346C16.6868 16.4817 15.7777 17.4241 14.7676 17.4241ZM14.7676 14.4921C14.2625 14.4921 13.8585 14.911 13.8585 15.4346C13.8585 15.9581 14.2625 16.377 14.7676 16.377C15.2726 16.377 15.6767 15.9581 15.6767 15.4346C15.6767 14.911 15.2726 14.4921 14.7676 14.4921Z"
              fill="white"
            />
            <path
              d="M19.8181 20.0419H11.1313C10.8282 20.0419 10.6262 19.8325 10.6262 19.5183C10.6262 19.2042 10.8282 18.9948 11.1313 18.9948H19.8181C20.1212 18.9948 20.3232 19.2042 20.3232 19.5183C20.3232 19.8325 20.1212 20.0419 19.8181 20.0419Z"
              fill="white"
            />
            <path
              d="M24.7676 20.0419H22.6464C22.3434 20.0419 22.1414 19.8325 22.1414 19.5183C22.1414 19.2042 22.3434 18.9948 22.6464 18.9948H24.7676C25.0707 18.9948 25.2727 19.2042 25.2727 19.5183C25.2727 19.8325 25.0707 20.0419 24.7676 20.0419Z"
              fill="white"
            />
            <path
              d="M21.2322 21.5078C20.2221 21.5078 19.313 20.5654 19.313 19.5183C19.313 18.4712 20.2221 17.5288 21.2322 17.5288C22.2423 17.5288 23.1514 18.4712 23.1514 19.5183C23.1514 20.5654 22.3433 21.5078 21.2322 21.5078ZM21.2322 18.5759C20.7271 18.5759 20.3231 18.9948 20.3231 19.5183C20.3231 20.0419 20.7271 20.4607 21.2322 20.4607C21.7372 20.4607 22.1413 20.0419 22.1413 19.5183C22.1413 18.9948 21.7372 18.5759 21.2322 18.5759Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_22_72229">
            <rect width="36" height="36" fill="white" />
          </clipPath>
          <clipPath id="clip1_22_72229">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconBilling;
