import { defaultTo, isUndefined } from "lodash";
import { Grid } from "@mui/material";
import { CommerceTypeComponent } from "../CreateWhiteListSection/CommerceTypeComponent/CommerceTypeComponent";
import { CountrySelectedComponent } from "../CreateRuleSection/CountrySelectedComponent/CountrySelectedComponent";
import { MerchantHierarchyComponent } from "../CreateRuleSection/MerchantHierarchyComponent/MerchantHierarchyComponent";
import { MerchantWithoutHierarchyComponent } from "../CreateRuleSection/MerchantWithoutHierarchyComponent/MerchantWithoutHierarchyComponent";
import React from "react";
import { ICommerceTypeCountry } from "../../shared/infrastructure/interfaces/ICommerceTypeCountry";

export const CommerceTypeCountry: React.FC<ICommerceTypeCountry> = (
  props: ICommerceTypeCountry
) => {
  return (
    <>
      {!props.showControllerField(defaultTo(props.subType, "")) && (
        <Grid item xs={12}>
          <CommerceTypeComponent setNodesChange={props.setNodesChange} />
        </Grid>
      )}
      {props.showControllerField(defaultTo(props.subType, "")) ? (
        <Grid item xs={12}>
          <CountrySelectedComponent
            setIsItemCountrySelected={props.setIsItemCountrySelected}
          />
        </Grid>
      ) : (
        !isUndefined(props.commerceType) &&
        (props.showCommerceType(defaultTo(props.commerceType, "")) ? (
          <Grid item xs={12}>
            <MerchantHierarchyComponent
              setNodesChange={props.setNodesChange}
              setIsItemSelectedWithHierarchy={
                props.setIsItemSelectedWithHierarchy
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <MerchantWithoutHierarchyComponent
              setIsItemSelectedWithoutHierarchy={
                props.setIsItemSelectedWithoutHierarchy
              }
            />
          </Grid>
        ))
      )}
    </>
  );
};
