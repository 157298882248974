import React from "react";
import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
} from "@kushki/connect-ui";
import { CountryEnum } from "../../enums/CountryEnum";

export const getCountryFlag: Record<string, JSX.Element> = {
  [CountryEnum.ECUADOR]: <FlagEcuador fontSize="inherit" />,
  [CountryEnum.MEXICO]: <FlagMexico fontSize="inherit" />,
  [CountryEnum.BRAZIL]: <FlagBrasil fontSize="inherit" />,
  [CountryEnum.CHILE]: <FlagChile fontSize="inherit" />,
  [CountryEnum.PANAMA]: <FlagPanama fontSize="inherit" />,
  [CountryEnum.PERU]: <FlagPeru fontSize="inherit" />,
  [CountryEnum.COLOMBIA]: <FlagColombia fontSize="inherit" />,
  [CountryEnum.NICARAGUA]: <FlagNicaragua fontSize="inherit" />,
  [CountryEnum.GUATEMALA]: <FlagGuatemala fontSize="inherit" />,
  [CountryEnum.HONDURAS]: <FlagHonduras fontSize="inherit" />,
  [CountryEnum.COSTA_RICA_FLAG]: <FlagCostaRica fontSize="inherit" />,
  [CountryEnum.SALVADOR_FLAG]: <FlagElSalvador fontSize="inherit" />,
};
