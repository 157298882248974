import { Typography } from "@mui/material";
import React from "react";
import { ITransactionHistoryInfoProps } from "./TransactionHistoryInfo.interfaces";
import { styles } from "./TransactionHistoryInfo.styles";

const TransactionHistoryInfo = ({
  entryTitle,
  entryName,
  entryRole,
  entryReason,
}: ITransactionHistoryInfoProps) => {
  return (
    <>
      <Typography variant={"h6"} sx={styles.entryTitle}>
        {entryTitle}
      </Typography>
      {entryName && <Typography sx={styles.entryBody}>{entryName}</Typography>}
      {entryRole && <Typography sx={styles.entryBody}>{entryRole}</Typography>}
      {entryReason && (
        <Typography sx={styles.entryBody}>{entryReason}</Typography>
      )}
    </>
  );
};

export default TransactionHistoryInfo;
