import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMassiveBranch } from "../../interfaces/massiveBranch/massiveBranch.interfaces";
import { ResponseWSUpdateMassiveBranch } from "../../../shared/utils/company_data_utils";

export const initialState: IMassiveBranch = {
  dataBranchList: [],
};

export const massiveMerchantSlice = createSlice({
  initialState,
  name: "massiveBranch",
  reducers: {
    setMassiveBranchResponse: (
      state,
      { payload }: PayloadAction<ResponseWSUpdateMassiveBranch[]>
    ) => {
      state.dataBranchList = payload;
    },
    setStatusSocket: (state, { payload }: PayloadAction<string>) => {
      state.statusSocket = payload;
    },
    setUpdateMassiveStatus: (
      state,
      { payload }: PayloadAction<boolean | undefined>
    ) => {
      state.updateMassiveBranchStatus = payload;
    },
  },
});

export default massiveMerchantSlice.reducer;
