import { ICatalogConfirmDialogState } from "../CatalogConfirmDialog.interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  getRetentionCatalogsByCountry,
  setBase64CatalogsMatchWithMerchantRetentionConfig,
  setCatalogRequest,
  setIsOpenCatalogConfirmPopup,
  setIsOpenCatalogFormPopup,
  updateCatalogItem,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { Dispatch } from "redux";
import { CountryRetentionEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { getRetentionType } from "../../../utils/functions/Catalog";
import { CatalogPropertyEnum } from "../../../shared/infrastructure/Enums/CatalogEnum";
import { defaultTo, get } from "lodash";
import { ActionEnum } from "../../../shared/infrastructure/Enums/ActionEnum";

export const useCatalogConfirmDialogState = (): ICatalogConfirmDialogState => {
  const dispatch: Dispatch<any> = useDispatch();
  const { base64Csv, catalogRequest } = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const base64CsvFile: string = defaultTo(base64Csv, "");

  const handleUpdateItemCatalog = (): void => {
    const action: string = defaultTo(
      get(catalogRequest, CatalogPropertyEnum.ACTION),
      ""
    );
    const canUpdateOrDelete: boolean =
      action === ActionEnum.UPDATE || action === ActionEnum.DELETE;

    if (canUpdateOrDelete) {
      dispatch(updateCatalogItem(catalogRequest!));
    }
  };

  const handleCancelOption = (): void => {
    dispatch(setIsOpenCatalogFormPopup(true));
    dispatch(setIsOpenCatalogConfirmPopup(false));
    dispatch(
      setCatalogRequest({
        action: "",
        code: defaultTo(get(catalogRequest, CatalogPropertyEnum.CODE), ""),
        id: defaultTo(get(catalogRequest, CatalogPropertyEnum.ID), ""),
        value: defaultTo(get(catalogRequest, CatalogPropertyEnum.VALUE), ""),
      })
    );
    dispatch(setBase64CatalogsMatchWithMerchantRetentionConfig(""));
  };

  const handleClosePopup = (): void => {
    dispatch(setCatalogRequest({ action: "", code: "", id: "", value: "" }));
    dispatch(setIsOpenCatalogConfirmPopup(false));
    dispatch(setBase64CatalogsMatchWithMerchantRetentionConfig(""));
  };

  const handleRetentionListByType = (catalogName: string): void => {
    dispatch(
      getRetentionCatalogsByCountry(
        CountryRetentionEnum.ecuador,
        getRetentionType(catalogName)
      )
    );
  };

  return {
    base64CsvFile,
    handleCancelOption,
    handleUpdateItemCatalog,
    handleClosePopup,
    handleRetentionListByType,
  };
};
