import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { useSnackbar } from "@kushki/connect-ui";
import { useMerchantCompanyInfo } from "../../hooks/merchantCompanyInfo/useMerchantCompanyInfo";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { IMerchantPeopleNodeRequest } from "../../../store/interfaces/LegalDetailsState.interfaces";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import { defaultTo, get, isArray, isEmpty, parseInt, reduce } from "lodash";
import {
  becomeSameLevel,
  constructRequestCompliance,
} from "../basic_data_utils";
import { transformCompliancePeopleIntoAccordion } from "../../../containers/LegalDetailsContainer/state/LegalContainerState.utils";
import {
  getCompliancePeople,
  getMerchantNodeInfo,
  saveLegalDetails,
} from "../../../store/thunks/legalDetails/legalDetails.thunk";
import { TabItemStatusEnum } from "../../enum/tabItemStatusEnum";
import {
  ConfigIdEnum,
  PersonTypeEnum,
} from "../../constants/initial_state_legal_details";
import { ILabel } from "../../../components/FooterOptions/FooterOptions.interfaces";
import { format } from "date-fns";
import {
  getNodeInfoInitialData,
  updateHierarchyNodeInfo,
} from "../../../store/thunks/general/general.thunk";
import {
  IAccordionValue,
  IValueNodeInfo,
} from "../../../containers/LegalDetailsContainer/state/LegalContainerState.interfaces";
import { EntityTypes } from "../../enum/EntityTypeEnum";
import getTime from "date-fns/getTime";
import {
  setBoardMembersArray,
  setCompliancePeopleNode,
  setDisabledInputs,
  setGeneralPercentage,
  setHierarchyNodeInfoResponse,
  setIsLoadingCompliancePeople,
  setLegalRepresentativesArray,
  setNotificationLD,
  setSaveLegalDetailsError,
  setShareHolder,
} from "../../../store/reducers/legalDetails/legalDetails";
import { searchParams } from "../useQuery_utils";
import { isCentralizedConfig } from "../isCentralizedConfig";
import { CompliancePeopleNodeResponse } from "../../../../types/get_compliance_people_response";
import { FieldValues, useForm } from "react-hook-form";
import { PubliclyExposedEnum } from "../../enums/RadioButtonEnum";

export const useLegalData = (isMassive?: boolean) => {
  const {
    boardMembers,
    shareHolders,
    legalRepresentative,
    notification,
    merchantNodeInfo,
    compliancePeopleNode,
    hierarchyNodeResponse,
    saveLegalDetailsError,
    nodeInfo,
    configsInfo,
    personType,
    nodeInfoMassiveLegalData,
    isLoadingCompliancePeople,
    isPubliclyExposed,
  } = useAppSelector((state: RootState) => ({
    ...state.legalDetails,
    ...state.initialData,
  }));

  const form = useForm<FieldValues, object>({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
  });

  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const query = searchParams();
  const publicMerchantId: string = query.publicMerchantId;
  const { countriesInfo, headerProps, handleChangeStatusNodeInfo } =
    useMerchantCompanyInfo(isMassive);
  const [isDisabledContinue, setIsDisabledContinue] = useState<boolean>(true);
  const [openModalLoader, setOpenModalLoader] = useState<boolean>(false);
  const [fromNext, setFromNext] = useState<boolean | undefined>(undefined);
  const history = useHistory();
  const [isEdit, setIsEdit] = useState<boolean>(query.isEdit);
  const [isDisabledSave, setIsDisabledSave] = useState<boolean>(false);
  const [isCentralize, setIsCentralize] = useState<boolean>(false);

  const legalConfig: Configs = get(configsInfo, ConfigIdEnum.CN015);

  const handleSaveInfo = async () => {
    const legalDetails: IMerchantPeopleNodeRequest = constructRequestCompliance(
      legalRepresentative,
      becomeSameLevel(shareHolders),
      boardMembers,
      merchantNodeInfo,
      nodeInfo,
      publicMerchantId,
      form.watch("exposed") === PubliclyExposedEnum.YES ? true : false,
      compliancePeopleNode
    );

    dispatch(
      setCompliancePeopleNode(legalDetails as CompliancePeopleNodeResponse)
    );

    setOpenModalLoader(true);

    await dispatch(saveLegalDetails(legalDetails));

    handleChangeStatusNodeInfo(
      isDisabledContinue
        ? TabItemStatusEnum.IN_PROCESS
        : TabItemStatusEnum.COMPLETE,
      ConfigIdEnum.CN015
    );
  };

  const handleNextInfo = async () => {
    setFromNext(true);
    const legalDetails: IMerchantPeopleNodeRequest = constructRequestCompliance(
      legalRepresentative,
      becomeSameLevel(shareHolders),
      boardMembers,
      merchantNodeInfo,
      nodeInfo,
      publicMerchantId,
      form.watch("exposed") === PubliclyExposedEnum.YES ? true : false,
      compliancePeopleNode
    );

    setOpenModalLoader(true);
    await dispatch(saveLegalDetails(legalDetails));
    handleChangeStatusNodeInfo(TabItemStatusEnum.COMPLETE, ConfigIdEnum.CN015);
  };

  const footerLabel: ILabel = {
    date: format(
      get(configsInfo, `${ConfigIdEnum.CN015}.updatedAt`, 0),
      "dd/MM/yyyy '-' HH:mm"
    ),
    editDetail: false,
    isHidden: true,
    text:
      "Modificado por " +
      get(configsInfo, `${ConfigIdEnum.CN015}.updatedBy`, ""),
  };

  const getGeneralPercentage = () => {
    if (shareHolders.length === 0) {
      return "0";
    }

    let result = reduce(
      shareHolders,
      (acum, curr) => acum + parseInt(curr.participationPercentage),
      0
    );

    return String(result);
  };

  const filterConfigs = (arrayConfig: Configs[]) => {
    if (!isEmpty(get(arrayConfig, "[0]centralizedNodesId", ""))) {
      setIsDisabledSave(true);
    }
  };

  const isComplete: boolean = useMemo(
    () => legalConfig.status === TabItemStatusEnum.COMPLETE,
    [legalConfig.status]
  );

  useEffect(() => {
    dispatch(setIsLoadingCompliancePeople(true));
    setIsEdit(query.isEdit);
    const publicMerchantID: string = isMassive
      ? get(nodeInfoMassiveLegalData, "merchantId", "")
      : publicMerchantId;

    dispatch(
      getNodeInfoInitialData({
        configIds: `${ConfigIdEnum.CN001},${ConfigIdEnum.CN016},${ConfigIdEnum.CN015},${ConfigIdEnum.CN002}`,
        publicMerchantId: publicMerchantID,
      })
    );
  }, []);

  const valueNodeInfo: IValueNodeInfo = useMemo(() => {
    const currentValueNodeInfo: IValueNodeInfo = {
      cn001: "",
      cn015: "",
    };

    const infoNode: GetNodeInfoResponse = isMassive
      ? nodeInfoMassiveLegalData
      : nodeInfo;

    if (isArray(infoNode.configs))
      infoNode!.configs!.map((conf: Configs) => {
        if (
          [ConfigIdEnum.CN001.toString(), ConfigIdEnum.CN015].includes(
            get(conf, "configuration", "")
          )
        ) {
          if (
            get(nodeInfo, "entityName", "") === EntityTypes.BRANCH &&
            isEdit &&
            get(conf, "configuration", "") === ConfigIdEnum.CN015
          ) {
            filterConfigs([conf]);
          }
          currentValueNodeInfo[get(conf, "configuration", "")] = get(
            conf,
            "value"
          );
        }
      });

    return currentValueNodeInfo;
  }, [nodeInfo.configs]);
  const isEmptyConfig = (arrayConfig: Configs[], config: string) => {
    const getConfig: Configs = arrayConfig.filter(
      (item) => item.configuration === config
    );

    if (isEmpty(getConfig.value))
      setTimeout(() => {
        dispatch(setIsLoadingCompliancePeople(false));
      }, 300);
  };

  useEffect(() => {
    if (saveLegalDetailsError === false) {
      dispatch(
        updateHierarchyNodeInfo({
          configs: [
            {
              configuration: ConfigIdEnum.CN015,
              status: isDisabledContinue
                ? TabItemStatusEnum.PENDING
                : TabItemStatusEnum.COMPLETE,
              updatedAt: getTime(Date.now()),
              updatedBy: defaultTo(localStorage.getItem("username"), ""),
              value: publicMerchantId,
            },
          ],
          nodeId: get(nodeInfo, "nodeId", ""),
        })
      );
      dispatch(setSaveLegalDetailsError(undefined));
    }
  }, [saveLegalDetailsError]);

  useEffect(() => {
    if (hierarchyNodeResponse && fromNext) {
      setOpenModalLoader(false);
      setFromNext(false);
      history.push(headerProps.tabsNodeInfo.tabs[3].redirectPath);
    } else {
      setOpenModalLoader(false);
      dispatch(setSaveLegalDetailsError(undefined));
      dispatch(setHierarchyNodeInfoResponse(undefined));
    }
  }, [hierarchyNodeResponse]);

  useEffect(() => {
    if (!isEmpty(valueNodeInfo.cn001))
      dispatch(getMerchantNodeInfo(valueNodeInfo.cn001));
  }, [valueNodeInfo]);
  useEffect(() => {
    if (!isEmpty(valueNodeInfo.cn015)) {
      dispatch(getCompliancePeople(valueNodeInfo.cn015));
    }
  }, [valueNodeInfo.cn015]);

  useEffect(() => {
    if (
      !isEmpty(compliancePeopleNode.shareholders) ||
      !isEmpty(compliancePeopleNode.legalRepresentatives)
    ) {
      const AccordionItems: IAccordionValue =
        transformCompliancePeopleIntoAccordion(compliancePeopleNode);

      dispatch(
        setLegalRepresentativesArray(AccordionItems.legalRepresentatives)
      );
      dispatch(setShareHolder(AccordionItems.shareholders));
    }
  }, [
    compliancePeopleNode.shareholders.length,
    compliancePeopleNode.legalRepresentatives.length,
  ]);

  useEffect(() => {
    if (!isEmpty(compliancePeopleNode.boardmembers)) {
      const AccordionItems: IAccordionValue =
        transformCompliancePeopleIntoAccordion(compliancePeopleNode);

      dispatch(setBoardMembersArray(AccordionItems.boardMembers));
    }
  }, [get(compliancePeopleNode, "boardmembers", []).length]);

  useEffect(() => {
    dispatch(setGeneralPercentage(getGeneralPercentage()));

    const isLegalRepresetative: boolean =
      personType === PersonTypeEnum.FISICA && legalRepresentative.length > 0;

    setIsDisabledContinue(
      !(
        isLegalRepresetative ||
        (personType === PersonTypeEnum.MORAL &&
          legalRepresentative.length > 0 &&
          shareHolders.length > 0)
      ) || form.watch("exposed") == ""
    );

    setIsDisabledSave(
      !(
        isLegalRepresetative ||
        (personType === PersonTypeEnum.MORAL &&
          legalRepresentative.length > 0) ||
        shareHolders.length > 0
      ) || form.watch("exposed") == ""
    );
  }, [shareHolders, legalRepresentative, form.watch("exposed")]);

  useEffect(() => {
    const localData = get(legalRepresentative[0], "isPubliclyExposed", null);

    if (localData === null) {
      form.setValue("exposed", "");
    } else {
      form.setValue(
        "exposed",
        localData ? PubliclyExposedEnum.YES : PubliclyExposedEnum.NO
      );
    }
  }, [isPubliclyExposed]);

  useEffect(() => {
    if (isComplete) setIsDisabledSave(isDisabledContinue);
  }, [isDisabledContinue]);

  useEffect(() => {
    setIsCentralize(isCentralizedConfig(nodeInfo, ConfigIdEnum.CN015));
    if (isCentralize) {
      setIsDisabledSave(true);
      setIsDisabledContinue(true);
      dispatch(setDisabledInputs(true));
    }
    if (nodeInfo.configs) isEmptyConfig(nodeInfo.configs, ConfigIdEnum.CN015);
  }, [nodeInfo, isDisabledSave]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotificationLD(undefined));
    }
  }, [notification]);

  return {
    boardMembers,
    countriesInfo,

    footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN015}.updatedBy`))
      ? footerLabel
      : {
          date: "",
          editDetail: false,
          isHidden: true,
          text: "",
        },
    form,
    handleChangeStatusNodeInfo,
    headerProps,
    isCentralize,
    isEdit,
    isLoadingCompliancePeople,
    isPubliclyExposed,
    isValidateForm: isDisabledContinue,
    legalRepresentative,
    merchantNodeInfo,
    nodeInfo,

    openModalLoader,
    primaryButton: {
      isDisabled: isDisabledContinue,
      isHidden: true,
      onAction: handleNextInfo,
    },
    secondaryButton: {
      isDisabled: isDisabledSave,
      isHidden: true,
      onAction: handleSaveInfo,
    },
    shareHolders,
  };
};
