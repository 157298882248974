import { createAsyncThunk } from "@reduxjs/toolkit";
import { MerchantDeferredResponse } from "../../../../types/merchant_deferred_response";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { BrazilMerchantDeferredResponse } from "../../../../types/brazil_merchant_deferred_response";

export const getMerchantDeferred = createAsyncThunk<MerchantDeferredResponse[]>(
  "app/getMerchantDeferred",
  async () => {
    const response = await axios.get<MerchantDeferredResponse[]>(
      API_ROUTES.MERCHANT
    );

    return response.data;
  }
);

export const getBrazilMerchantDeferred = createAsyncThunk<
  BrazilMerchantDeferredResponse[]
>("app/getBrazilMerchantDeferred", async () => {
  const response = await axios.get<BrazilMerchantDeferredResponse[]>(
    API_ROUTES.LIST_MERCHANT
  );

  return response.data;
});
