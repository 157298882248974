import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  saveBasicDataMerchant,
  updateMerchant,
} from "../../thunks/basicDataForm/basicDataForm.thunks";
import { MinAmountDynamo } from "../../../../types/min_amount_dynamo";
import { MerchantData } from "../../../../types/merchant-data";
import { format } from "date-fns";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { getMerchantInfo } from "../../thunks/general/general.thunk";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { get } from "lodash";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { NONE } from "../../../shared/enum/socketEnum";

export interface BasicDataForm {
  continueButton: boolean;
  hourComputer: string;
  isLoadingBasicInformation: boolean;
  isSaveButton: boolean;
  saveButton: boolean;
  modalOpen: boolean;
  loaderState: boolean;
  textSave: boolean;
  //merchant-node-info
  merchantNodeInfo: MerchantData;
  //minamount
  minAmount: MinAmountDynamo;
  notification: ISnackBar | undefined;
  typeButton: string;
  isErrorSave?: boolean;
}

export const initialState: BasicDataForm = {
  continueButton: true,
  hourComputer: format(new Date(), "dd/MM/yyyy '-' HH:mm"),
  isLoadingBasicInformation: true,
  isSaveButton: false,
  loaderState: false,
  merchantNodeInfo: {
    companyType: "",
    configId: "",
    constitutionDate: 0,
    country: "",
    documentType: "",
    economicActivityId: "",
    gracePeriod: 0,
    industryType: "",
    mcc: "",
    merchantType: "02",
    naturalPerson: {
      lastname: "",
      name: "",
    },
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
  },
  minAmount: {
    amount: 0,
    country: "",
    created: 0,
    currency: "",
    gracePeriod: 0,
    merchants: [],
    parentMerchant: "",
    taxId: "",
    type: "",
    updated: 0,
  },
  modalOpen: false,
  notification: undefined,
  saveButton: false,
  textSave: false,
  typeButton: NONE,
};

export const basicDataFormSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        if (action.payload.configId === ConfigIdEnum.CN001) {
          state.isLoadingBasicInformation = false;
          state.merchantNodeInfo = {
            ...action.payload.data,
            configId: action.payload.configId,
          } as MerchantData;
        }
      })
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingBasicInformation = true;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingBasicInformation = false;
      })
      .addCase(updateMerchant.fulfilled, (state, action) => {
        state.merchantNodeInfo = action.payload;
      })
      .addCase(updateMerchant.rejected, () => {})
      .addCase(saveBasicDataMerchant.pending, (state) => {
        state.modalOpen = true;
      })
      .addCase(saveBasicDataMerchant.fulfilled, (state) => {
        state.isErrorSave = false;
        state.modalOpen = false;
        if (state.isSaveButton) {
          state.textSave = true;
        }
        state.isSaveButton = false;
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
      })
      .addCase(saveBasicDataMerchant.rejected, (state) => {
        state.isErrorSave = true;
        state.modalOpen = false;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
  },
  initialState,
  name: "basicDataForm",
  reducers: {
    setButtonContinueFooter: (state, { payload }: PayloadAction<boolean>) => {
      state.continueButton = payload;
    },
    setButtonSaveFooter: (state, { payload }: PayloadAction<boolean>) => {
      state.saveButton = payload;
    },
    setHourComputer: (state, { payload }: PayloadAction<string>) => {
      state.hourComputer = payload;
    },
    setIsErrorSave: (
      state,
      { payload }: PayloadAction<boolean | undefined>
    ) => {
      state.isErrorSave = payload;
    },
    setIsSaveButton: (state, { payload }: PayloadAction<boolean>) => {
      state.isSaveButton = payload;
    },
    setLoaderState: (state, { payload }: PayloadAction<boolean>) => {
      state.loaderState = payload;
    },
    setNotification: (
      state,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setOpenModal: (state, { payload }: PayloadAction<boolean>) => {
      state.modalOpen = payload;
    },
    setTextSave: (state, { payload }: PayloadAction<boolean>) => {
      state.textSave = payload;
    },
    setTypeButton: (state, { payload }: PayloadAction<string>) => {
      state.typeButton = payload;
    },
  },
});

export default basicDataFormSlice.reducer;
