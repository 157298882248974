import { withStyles } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";

export const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    "&:first-child": {
      "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "#0DC298",
        borderRadius: "4px",
        color: "black",
        fontSize: "14px",
        height: "38px",
        lineHeight: "100%",
        margin: "4px",
        width: "61px",
      },
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "#CBD5E0",
      borderRadius: "4px",
      color: "black",
      fontSize: "14px",
      height: "38px",
      lineHeight: "100%",
      margin: "4px",
      width: "61px",
    },
    "&:not(:first-child)": {
      "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "#0DC298",
        borderRadius: "4px",
        color: "black",
        fontSize: "14px",
        height: "38px",
        lineHeight: "100%",
        margin: "4px",
        width: "61px",
      },
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "#CBD5E0",
      borderRadius: "4px",
      color: "black",
      fontSize: "14px",
      height: "38px",
      lineHeight: "100%",
      margin: "4px",
      width: "61px",
    },
    margin: theme.spacing(2),
  },
}))(ToggleButtonGroup);
