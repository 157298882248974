import React, { useEffect } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  CARD_ISSUER_VALUES,
  CARD_TYPE_VALUES,
  getBanksByCountry,
  getBrandsByCountry,
  getCurrencyByCountry,
  getLabelValues,
  getNames,
  getOperatorValues,
  SecurityRulesLabelField,
  SecurityRulesSubtitleSection,
  YES_NO_VALUES,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { useWatch } from "react-hook-form";
import { defaultTo, get, isEmpty, take } from "lodash";
import { MinusCircle, PlusCircle } from "react-feather";
import SelectFieldComponent from "./ValuesFields/SelectFieldComponent";
import TextFieldComponent from "./ValuesFields/TextFieldComponent";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import AutocompleteFieldComponent from "./ValuesFields/AutocompleteFieldComponent";
import { getCountries } from "../../shared/infrastructure/constants/CountryConstant";
import AutocompleteMultipleFieldComponent from "./ValuesFields/AutocompleteMultipleFieldComponent";
import { IOptionSelection } from "../../shared/infrastructure/constants/RulesConstants";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import { MerchantCredential } from "../../../types/merchant_credential";
import { AutocompleteTextFieldSuggestedComponent } from "../AutocompleteTextFieldSuggested/AutocompleteTextFieldSuggestedComponent";
import {
  TRANSACTION_TYPES,
  TRANSACTION_TYPES_OTP,
  TRANSACTION_TYPES_TO_CONTAINS,
} from "../../shared/infrastructure/constants/BusinessRulesEnum";
import { GeneralActionsEnum } from "../../shared/infrastructure/enums/GeneralActionsEnum";
import SelectFieldScheduleComponent from "./ValuesFields/SelectFieldScheduleComponent";
import { getValues } from "../../shared/utils";
import { RegexExpressionEnum } from "../../shared/infrastructure/constants/RegexExpressionEnum";
import { OperatorsEnum } from "../../shared/infrastructure/enums/OperatorsEnum";

export interface StrictConditionsSectionProps {
  index: number;
  total: string[];
  addStrictCondition: (index: number) => void;
  removeStrictCondition: (item: number) => void;
  disabled: boolean;
  countryGeneral: string;
  terminateFillFields: boolean;
  credentials: IOptionSelection[];
  credentialsSuggested: MerchantCredential[];
  isLoadingGetSuggestedCredentials: boolean;
  handleEnterSearchTextCredentialList: (key: string) => void;
  handleSetSearchTextCredentialList: (key: string) => void;
  setCredentialInformation: (value: object, index: number) => void;
  generalAction: string;
}

const renderValuesField = (
  label: string,
  operator: string,
  index: number,
  disabled: boolean,
  countriesGeneral: string[],
  credentials: IOptionSelection[],
  generalAction: string,
  edit: boolean
): JSX.Element => {
  const isAll: boolean = defaultTo(operator, OperatorsEnum.EQUAL_ALL).includes(
    OperatorsEnum.ALL
  );

  switch (label) {
    case LabelEnum.DEFERRED:
      return (
        <SelectFieldComponent
          disabled={disabled}
          index={index}
          items={YES_NO_VALUES}
          label={label}
        />
      );
    case LabelEnum.CARD_TYPE:
      return (
        <SelectFieldComponent
          disabled={disabled}
          index={index}
          items={CARD_TYPE_VALUES}
          label={label}
        />
      );
    case LabelEnum.CARD_ISSUER:
      return (
        <SelectFieldComponent
          disabled={disabled}
          index={index}
          items={CARD_ISSUER_VALUES}
          label={label}
        />
      );
    case LabelEnum.IP:
      if (defaultTo(operator, "=|all").includes("all")) return <div />;
      return (
        <TextFieldComponent
          disabled={disabled}
          index={index}
          pattern={{
            pattern:
              operator !== OperatorsEnum.EQUAL || edit
                ? RegexExpressionEnum.IP
                : RegexExpressionEnum.ONE_IP,
            message: ErrorsEnum.INVALID_IP,
          }}
        />
      );
    case LabelEnum.MASKED_CARD:
      if (defaultTo(operator, "=|all").includes("all")) return <div />;
      return <TextFieldComponent disabled={disabled} index={index} />;
    case LabelEnum.BIN:
      return (
        <TextFieldComponent
          disabled={disabled}
          index={index}
          pattern={{
            pattern: RegexExpressionEnum.ONLY_NUMBERS,
            message: ErrorsEnum.INVALID_BIN,
          }}
        />
      );
    case LabelEnum.AMOUNT:
      return (
        <TextFieldComponent
          disabled={disabled}
          index={index}
          type={"number"}
          pattern={{
            pattern: RegexExpressionEnum.CURRENCY_WITH_FRACTION,
            message: ErrorsEnum.ONLY_NUMBERS_WITH_FRACTION,
          }}
        />
      );
    case LabelEnum.COUNTRY:
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          index={index}
          items={getNames(getCountries())}
          options={getCountries()}
          compare={"name"}
          path={"name"}
        />
      );
    case LabelEnum.BRAND:
      if (defaultTo(operator, "=").includes("="))
        return (
          <AutocompleteFieldComponent
            disabled={disabled}
            index={index}
            items={getValues(getBrandsByCountry(countriesGeneral))}
            options={getBrandsByCountry(countriesGeneral)}
            compare={"value"}
            path={"name"}
          />
        );
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          index={index}
          items={getValues(getBrandsByCountry(countriesGeneral))}
          options={getBrandsByCountry(countriesGeneral)}
          compare={"value"}
          path={"name"}
        />
      );
    case LabelEnum.BANK:
      if (defaultTo(operator, "=").includes("="))
        return (
          <AutocompleteFieldComponent
            disabled={disabled}
            index={index}
            items={getNames(getBanksByCountry(countriesGeneral))}
            options={getBanksByCountry(countriesGeneral)}
            compare={"name"}
            path={"name"}
          />
        );
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          index={index}
          items={getNames(getBanksByCountry(countriesGeneral))}
          options={getBanksByCountry(countriesGeneral)}
          compare={"name"}
          path={"name"}
        />
      );
    case LabelEnum.CURRENCY:
      return (
        <AutocompleteFieldComponent
          disabled={disabled}
          index={index}
          items={getValues(getCurrencyByCountry(countriesGeneral))}
          options={getCurrencyByCountry(countriesGeneral)}
          compare={"value"}
          path={"name"}
        />
      );
    case LabelEnum.CREDENTIAL:
      if (defaultTo(operator, "=").includes("=")) {
        return (
          <AutocompleteFieldComponent
            disabled={disabled}
            index={index}
            items={getValues(credentials)}
            options={credentials}
            compare={"value"}
            path={"name"}
          />
        );
      }
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          index={index}
          items={getValues(credentials)}
          options={credentials}
          compare={"value"}
          path={"name"}
        />
      );
    case LabelEnum.SCHEDULE:
      return <SelectFieldScheduleComponent disabled={disabled} index={index} />;
    case LabelEnum.TRANSACTION_TYPE:
      if (generalAction === GeneralActionsEnum.OTP)
        return (
          <SelectFieldComponent
            disabled={disabled}
            index={index}
            items={TRANSACTION_TYPES_OTP}
            label={label}
          />
        );
      if (validateOperatorValue(operator)) {
        return (
          <AutocompleteMultipleFieldComponent
            disabled={disabled}
            index={index}
            items={getValues(TRANSACTION_TYPES_TO_CONTAINS)}
            options={TRANSACTION_TYPES_TO_CONTAINS}
            compare={"value"}
            path={"name"}
          />
        );
      }
      return (
        <SelectFieldComponent
          disabled={disabled}
          index={index}
          items={TRANSACTION_TYPES}
          label={label}
        />
      );
    case LabelEnum.PHONE_NUMBER:
      if (isAll) return <div />;
      return (
        <TextFieldComponent
          disabled={disabled}
          index={index}
          pattern={{
            pattern:
              operator === OperatorsEnum.CONTAIN
                ? RegexExpressionEnum.PHONE_NUMBER_WITH_COMMA
                : RegexExpressionEnum.PHONE_NUMBER,
            message: ErrorsEnum.INVALID_PHONE_NUMBER,
          }}
        />
      );
    case LabelEnum.EMAIL:
      if (isAll) return <div />;
      return (
        <TextFieldComponent
          disabled={disabled}
          index={index}
          type={"email"}
          pattern={{
            pattern:
              operator !== OperatorsEnum.EQUAL || edit
                ? RegexExpressionEnum.EMAIL
                : RegexExpressionEnum.ONE_EMAIL,
            message: ErrorsEnum.INVALID_EMAIL,
          }}
        />
      );
    case LabelEnum.CARD_HOLDER_NAME:
      if (isAll) return <div />;
      return (
        <TextFieldComponent
          disabled={disabled}
          index={index}
          pattern={{
            pattern:
              operator === OperatorsEnum.CONTAIN
                ? RegexExpressionEnum.ONLY_TEXTS_WITH_COMMA
                : RegexExpressionEnum.ONLY_TEXTS,
            message: ErrorsEnum.INVALID_CARD_HOLDER_NAME,
          }}
        />
      );
    case LabelEnum.CARD_ID: {
      if (isAll) return <div />;
      return (
        <ConnectForm<ISecurityRuleForm>>
          {(_) => {
            const value = useWatch({
              name: `strictCondition[${index}].value`,
            }) as string;
            const format = new RegExp("^[0-9a-zA-Z\\-, ]+$");
            const checkSpecialCharacters =
              !format.test(value) && !isEmpty(value);

            return (
              <>
                <TextFieldComponent
                  disabled={disabled}
                  index={index}
                  pattern={{
                    pattern:
                      operator === OperatorsEnum.CONTAIN ||
                      operator === OperatorsEnum.NOT_CONTAIN
                        ? RegexExpressionEnum.MORE_THAN_ONE_UUID
                        : RegexExpressionEnum.ONLY_ONE_UUID,
                    message: !checkSpecialCharacters
                      ? ErrorsEnum.INVALID_REGEX
                      : ErrorsEnum.INVALID_CARD_ID,
                  }}
                />
                {(operator === OperatorsEnum.CONTAIN ||
                  operator === OperatorsEnum.NOT_CONTAIN) && (
                  <Typography variant={"body2"} style={{ color: "#6D7781" }}>
                    Puedes ingresar más de un número seguido de una
                    &quot;,&quot;
                  </Typography>
                )}
              </>
            );
          }}
        </ConnectForm>
      );
    }
    default:
      return <div></div>;
  }
};

export const StrictConditionsSection: React.FC<StrictConditionsSectionProps> = (
  props: StrictConditionsSectionProps
) => {
  return (
    <ConnectForm<ISecurityRuleForm>>
      {({ control, errors, getErrorMessage }) => {
        const TypedController = useTypedController<ISecurityRuleForm>({
          control: control,
        });
        const label = useWatch({
          name: `strictCondition[${props.index}].label`,
        }) as string;

        const operator = useWatch({
          name: `strictCondition[${props.index}].operator`,
        }) as string;

        const edit = useWatch({
          name: `strictCondition[${props.index}].edit`,
        }) as boolean;

        const countries = useWatch({
          name: "country",
        }) as string[];

        const service = useWatch({
          name: "service",
        }) as string;

        const ruleType = useWatch({
          name: "type",
        }) as string;

        const action = useWatch({
          name: "action",
        }) as string;

        useEffect(() => {
          if (props.terminateFillFields) {
            control.setValue(
              `strictCondition[${props.index}].operator`,
              getOperatorValues(label, props.generalAction)[0].value
            );
            control.setValue(
              `strictCondition[${props.index}].value`,
              undefined
            );
          }
        }, [label]);
        useEffect(() => {
          if (props.terminateFillFields) {
            control.setValue(
              `strictCondition[${props.index}].value`,
              undefined
            );
          }
        }, [operator]);

        const relativeTotal: number = props.total.filter(
          (a) => a === "value"
        ).length;
        const relativeIndex: number =
          props.index -
          take(props.total, props.index + 1).filter((a) => a === "deleted")
            .length;

        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl
                variant="outlined"
                error={!!get(errors, `strictCondition.${props.index}.label`)}
                fullWidth
              >
                <InputLabel>{SecurityRulesLabelField.LABEL}</InputLabel>
                <TypedController
                  name={["strictCondition", props.index, "label"]}
                  rules={{ required: true }}
                  defaultValue={get(
                    getLabelValues(service, ruleType),
                    `${relativeIndex}.value`,
                    getLabelValues(service, ruleType)[0].value
                  )}
                  render={(properties) => (
                    <Select
                      {...properties}
                      label={SecurityRulesLabelField.LABEL}
                      disabled={props.disabled}
                    >
                      {getLabelValues(service, ruleType, action).map(
                        (
                          actionValue: { name: string; value: string },
                          index: number
                        ) => (
                          <MenuItem
                            key={`lab${index}`}
                            value={actionValue.value}
                          >
                            {actionValue.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
                <FormHelperText
                  error={!!get(errors, `strictCondition.${props.index}.label`)}
                >
                  {!!get(errors, `strictCondition.${props.index}.label`) &&
                    getErrorMessage(
                      get(errors, `strictCondition.${props.index}.label`)
                    )}
                </FormHelperText>
              </FormControl>
            </Grid>
            {label !== LabelEnum.SCHEDULE ? (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  variant="outlined"
                  error={
                    !!get(errors, `strictCondition.${props.index}.operator`)
                  }
                  fullWidth
                >
                  <InputLabel>{SecurityRulesLabelField.OPERATOR}</InputLabel>
                  <TypedController
                    name={["strictCondition", props.index, "operator"]}
                    rules={{ required: true }}
                    defaultValue={
                      getOperatorValues(label, props.generalAction)[0].value
                    }
                    render={(properties) => (
                      <Select
                        {...properties}
                        label={SecurityRulesLabelField.OPERATOR}
                        disabled={props.disabled}
                      >
                        {getOperatorValues(label, props.generalAction).map(
                          (
                            actionValue: { name: string; value: string },
                            index: number
                          ) => (
                            <MenuItem
                              key={`ope-${index}`}
                              value={actionValue.value}
                            >
                              {actionValue.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    error={
                      !!get(errors, `strictCondition.${props.index}.operator`)
                    }
                  >
                    {!!get(errors, `strictCondition.${props.index}.operator`) &&
                      getErrorMessage(
                        get(errors, `strictCondition.${props.index}.operator`)
                      )}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
            {label === LabelEnum.CREDENTIAL ? (
              <Grid item xs={8}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={
                    !!get(errors, `strictCondition.${props.index}.operator`)
                  }
                >
                  <TypedController
                    name={["strictCondition", props.index, "value"]}
                    rules={{ required: true }}
                    render={({ value }) => (
                      <AutocompleteTextFieldSuggestedComponent
                        property={"value"}
                        index={props.index}
                        label={SecurityRulesSubtitleSection.CREDENTIAL_HEADER}
                        value={value as string}
                        options={get(props, "credentialsSuggested", [])}
                        pathValue={"alias"}
                        pathName={"alias"}
                        isLoading={props.isLoadingGetSuggestedCredentials}
                        handleEnterSearchText={
                          props.handleEnterSearchTextCredentialList
                        }
                        handleSetSearchText={
                          props.handleSetSearchTextCredentialList
                        }
                        setValue={props.setCredentialInformation}
                        disabled={edit}
                        error={!!errors.merchantId}
                        errorMessage={ErrorsEnum.SELECT_MERCHANT}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                md={8}
                lg={label !== LabelEnum.SCHEDULE ? 8 : 6}
              >
                {renderValuesField(
                  label,
                  operator,
                  props.index,
                  props.disabled,
                  ruleType === "securityCommerce"
                    ? [props.countryGeneral]
                    : defaultTo(countries, []),
                  props.credentials,
                  props.generalAction,
                  edit
                )}
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2}>
              <Grid container direction={"row"}>
                {relativeIndex === relativeTotal - 1 &&
                relativeTotal !== getLabelValues(service, ruleType).length ? (
                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={() => props.addStrictCondition(props.index)}
                      disabled={props.disabled}
                    >
                      <PlusCircle size={30} />
                    </IconButton>
                  </Grid>
                ) : null}
                {relativeIndex === 0 && relativeTotal === 1 ? null : (
                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={() => props.removeStrictCondition(props.index)}
                      disabled={props.disabled}
                    >
                      <MinusCircle size={30} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
const validateOperatorValue = (operator: string): boolean => {
  return operator == "contain" || operator == "!contain";
};

export default StrictConditionsSection;
