import { IOptionSelection } from "../interfaces/IOptionSelection";
import { CountryEnum } from "../enums/CountryEnum";
import { BANKS_ECUADOR } from "./CatalogEC";
import { BANKS_MEXICO } from "./CatalogMX";
import { BANKS_PERU } from "./CatalogPE";
import { BANKS_COLOMBIA } from "./CatalogCO";
import { BANKS_CHILE } from "./CatalogCL";
import { BANKS_BRAZIL } from "./CatalogBR";
import { BANKS_NICARAGUA } from "./CatalogNI";
import { BANKS_GUATEMALA } from "./CatalogGT";
import { BANKS_HONDURAS } from "./CatalogHN";
import { BANKS_PANAMA } from "./CatalogPA";
import { BANKS_SALVADOR } from "./CatalogSV";
import { BANKS_COSTA_RICA } from "./CatalogCR";
import { union } from "lodash";
import { RuleEnum } from "../enums/RuleEnum";

export const BINS: IOptionSelection[] = [
  { label: "Bin1", value: "Bin1" },
  { label: "Bin2", value: "BIn2" },
  { label: "Bin3", value: "Bin3" },
];

export const getBanksByCountry = (countries: string[]): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.ECUADOR))
    banks = [...banks, ...BANKS_ECUADOR];
  if (countries.includes(CountryEnum.PERU)) banks = [...banks, ...BANKS_PERU];
  if (countries.includes(CountryEnum.MEXICO))
    banks = [...banks, ...BANKS_MEXICO];
  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...BANKS_COLOMBIA];
  if (countries.includes(CountryEnum.CHILE)) banks = [...banks, ...BANKS_CHILE];
  if (countries.includes(CountryEnum.BRAZIL))
    banks = [...banks, ...BANKS_BRAZIL];
  if (countries.includes(CountryEnum.NICARAGUA))
    banks = [...banks, ...BANKS_NICARAGUA];
  if (countries.includes(CountryEnum.GUATEMALA))
    banks = [...banks, ...BANKS_GUATEMALA];
  if (countries.includes(CountryEnum.HONDURAS))
    banks = [...banks, ...BANKS_HONDURAS];
  if (countries.includes(CountryEnum.PANAMA))
    banks = [...banks, ...BANKS_PANAMA];
  if (countries.includes(CountryEnum.SALVADOR))
    banks = [...banks, ...BANKS_SALVADOR];
  if (countries.includes(CountryEnum.COSTA_RICA))
    banks = [...banks, ...BANKS_COSTA_RICA];

  return banks;
};

export const getCurrencyByCountry = (
  countries: string[],
  currencyByCountry: Record<string, IOptionSelection[]> | undefined
): IOptionSelection[] => {
  let currencies: IOptionSelection[] = [];
  if (!currencyByCountry) return currencies;

  if (countries.includes(CountryEnum.ECUADOR))
    currencies = union(currencies, currencyByCountry["CURRENCY_ECUADOR"]);
  if (countries.includes(CountryEnum.PERU))
    currencies = union(currencies, currencyByCountry["CURRENCY_PERU"]);
  if (countries.includes(CountryEnum.MEXICO))
    currencies = union(currencies, currencyByCountry["CURRENCY_MEXICO"]);
  if (countries.includes(CountryEnum.COLOMBIA))
    currencies = union(currencies, currencyByCountry["CURRENCY_COLOMBIA"]);
  if (countries.includes(CountryEnum.CHILE))
    currencies = union(currencies, currencyByCountry["CURRENCY_CHILE"]);
  if (countries.includes(CountryEnum.BRAZIL))
    currencies = union(currencies, currencyByCountry["CURRENCY_BRAZIL"]);
  if (countries.includes(CountryEnum.NICARAGUA))
    currencies = union(currencies, currencyByCountry["CURRENCY_NICARAGUA"]);
  if (countries.includes(CountryEnum.GUATEMALA))
    currencies = union(currencies, currencyByCountry["CURRENCY_GUATEMALA"]);
  if (countries.includes(CountryEnum.HONDURAS))
    currencies = union(currencies, currencyByCountry["CURRENCY_HONDURAS"]);
  if (countries.includes(CountryEnum.COSTA_RICA))
    currencies = union(currencies, currencyByCountry["CURRENCY_COSTA_RICA"]);
  if (countries.includes(CountryEnum.PANAMA))
    currencies = union(currencies, currencyByCountry["CURRENCY_PANAMA"]);
  if (countries.includes(CountryEnum.SALVADOR))
    currencies = union(currencies, currencyByCountry["CURRENCY_SALVADOR"]);
  return currencies;
};

export enum HelperText {
  AMOUNT_HELPER = "Solo se permiten números",
  CREDENTIALS = "No se encontraron credenciales",
}

export enum Labels {
  ADD_CARD = "Añadir Tarjeta",
  CONTINENT = "Continente",
  BIN = "Ingresa número bin:",
  BRAND = "Marca",
  BANKS = "Seleccionar franquicia",
  IP = "Ingresa un valor IP",
  CREDENTIAL = "Seleccionar ID de Credencial",
  CREDENTIAL_BRANCH = "Seleccionar alias de credencial",
  SEARCH_CREDENTIALS = "Buscando Credenciales...",
  CURRENCY_LIST = "Listado de monedas",
}

export const getAliasTransactionRule = (environment: string) => {
  return `${environment}-usrv-transaction-rule-alias-counter`;
};

export const ItemsPerPage = [10, 25, 50, 75, 100];

export const PAYMENT_BRANDS_OPTIONS: IOptionSelection[] = [
  { label: "Visa", value: "Visa" },
  { label: "Mastercard", value: "Mastercard" },
  { label: "Amex", value: "Amex" },
  { label: "American Express", value: "american express" },
  { label: "Diners", value: "Diners" },
  { label: "Diners Club", value: "diners club" },
  { label: "Discover", value: "Discover" },
  { label: "Redcompra", value: "Redcompra" },
  { label: "Alia", value: "Alia" },
  { label: "Credencial", value: "Credencial" },
  { label: "Maestro", value: "maestro" },
  { label: "Propia", value: "propia" },
  { label: "Cmrfalabella", value: "Cmrfalabella" },
  { label: "JCB", value: "JCB" },
  { label: "Serfinanza", value: "Serfinanza" },
  { label: "Unionpay", value: "unionpay" },
];

export enum PlaceHolders {
  AMOUNT_FIRST_VALUE = "Primer valor",
  AMOUNT_SECOND_VALUE = "Segundo valor",
  AMOUNT_SINGLE_VALUE = "Valor",
  AMOUNT_VALUE = "Ingresa un valor",
  CONTINENT_VALUE = "Seleccione un continente",
  COUNTRY_VALUE = "Selecciona un país",
  NUMBER = "Número",
  REGION_VALUE = "Selecciona una región",
  TRANSACTION_TYPE = "Tipo de transacción",
  CURRENCY = "Selecciona el tipo de moneda",
  CREDENTIAL = "Selecciona el tipo de credencial:",
  BIN = "BIN",
}

export enum Operators {
  BETWEEN = "between",
}

export enum TextFieldsNames {
  FirstAmount = "firstAmount",
  SecondAmount = "secondAmount",
}

export enum AriaLabels {
  DELETE = "eliminar",
  EDIT = "editar",
}

export const DEFAULT_BUTTON_TITTLE: Record<string, string> = {
  [RuleEnum.IP]: "Añadir IP",
};

export enum CardTypeValueEnum {
  CREDIT = "Crédito",
  DEBIT = "Débito",
}

export enum BusinessRuleStatus {
  DISABLE = "DISABLE",
  DRAFT = "DRAFT",
  ENABLE = "ENABLED",
}

export enum ConditionsEnum {
  transaction_bank = "transaction_bank",
  transaction_brand = "transaction_brand",
  transaction_is_deferred = "transaction_is_deferred",
  transaction_country = "transaction_country",
  transaction_total_amount = "transaction_total_amount",
  transaction_ip = "transaction_ip",
  transaction_bin = "transaction_bin",
  transaction_is_credit_card = "transaction_is_credit_card",
  transaction_masked_credit_card = "transaction_masked_credit_card",
  transaction_type = "transaction_type",
  transaction_credential = "transaction_credential",
  transaction_currency = "transaction_currency",
}

export enum ConditionsEnumBatch {
  transactionBank = "transactionBank",
  transactionBrand = "transactionBrand",
  transactionIsDeferred = "transactionIsDeferred",
  transactionCountry = "transactionCountry",
  transactionTotalAmount = "transactionTotalAmount",
  transactionIp = "transactionIP",
  transactionBin = "transactionBIN",
  transactionIsCreditCard = "transactionIsCreditCard",
  transactionMaskedCreditCard = "transactionMaskedCreditCard",
  transactionType = "transactionType",
  transactionCredential = "transactionCredential",
  transactionCurrency = "transactionCurrency",
}

export enum CategoryEnum {
  bank = "Institución Financiera",
  brand = "Marca",
  deferred = "Diferido",
  country = "País",
  amount = "Monto",
  currency = "Moneda",
  ip = "IP",
  type = "Tipo de transacción",
  credential = "Credencial",
  bin = "BIN",
  credit_card = "Tipo de tarjeta",
  masked_credit_card = "Tarjeta enmascarada",
}

export enum DateFormatEnum {
  yyyy_mm_dd_hh_mm = "yyyy-MM-dd HH:mm",
}

export const CATEGORY_BY_CONDITIONS: Record<string, string> = {
  [ConditionsEnum.transaction_bank]: CategoryEnum.bank,
  [ConditionsEnum.transaction_brand]: CategoryEnum.brand,
  [ConditionsEnum.transaction_is_deferred]: CategoryEnum.deferred,
  [ConditionsEnum.transaction_country]: CategoryEnum.country,
  [ConditionsEnum.transaction_total_amount]: CategoryEnum.amount,
  [ConditionsEnum.transaction_ip]: CategoryEnum.ip,
  [ConditionsEnum.transaction_type]: CategoryEnum.type,
  [ConditionsEnum.transaction_credential]: CategoryEnum.credential,
  [ConditionsEnum.transaction_bin]: CategoryEnum.bin,
  [ConditionsEnum.transaction_is_credit_card]: CategoryEnum.credit_card,
  [ConditionsEnum.transaction_currency]: CategoryEnum.currency,
  [ConditionsEnum.transaction_masked_credit_card]:
    CategoryEnum.masked_credit_card,
};

export const CATEGORY_BY_CONDITIONS_BATCH: Record<string, string> = {
  [ConditionsEnumBatch.transactionBank]: CategoryEnum.bank,
  [ConditionsEnumBatch.transactionBrand]: CategoryEnum.brand,
  [ConditionsEnumBatch.transactionIsDeferred]: CategoryEnum.deferred,
  [ConditionsEnumBatch.transactionCountry]: CategoryEnum.country,
  [ConditionsEnumBatch.transactionTotalAmount]: CategoryEnum.amount,
  [ConditionsEnumBatch.transactionIp]: CategoryEnum.ip,
  [ConditionsEnumBatch.transactionType]: CategoryEnum.type,
  [ConditionsEnumBatch.transactionCredential]: CategoryEnum.credential,
  [ConditionsEnumBatch.transactionBin]: CategoryEnum.bin,
  [ConditionsEnumBatch.transactionCurrency]: CategoryEnum.currency,
  [ConditionsEnumBatch.transactionIsCreditCard]: CategoryEnum.credit_card,
  [ConditionsEnumBatch.transactionMaskedCreditCard]:
    CategoryEnum.masked_credit_card,
};

export const CONDITIONS_PATHS: string[] = [
  "transactionBIN",
  "transactionBank",
  "transactionBrand",
  "transactionCountry",
  "transactionCreated",
  "transactionCredential",
  "transactionCurrency",
  "transactionIP",
  "transactionIsCreditCard",
  "transactionIsDeferred",
  "transactionMaskedCreditCard",
  "transactionTotalAmount",
  "transactionType",
];
