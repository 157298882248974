import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconCircleCheck: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.98288 9.9717C7.02993 9.99067 7.08031 10.0003 7.13113 9.99999C7.18195 10.0003 7.23233 9.99067 7.27938 9.9717C7.32643 9.95274 7.36922 9.92479 7.40531 9.88947L10.6877 6.65009C10.7596 6.57868 10.8 6.48209 10.8 6.38141C10.8 6.28073 10.7596 6.18413 10.6877 6.11273C10.6518 6.07701 10.6091 6.04866 10.562 6.02931C10.515 6.00996 10.4645 6 10.4135 6C10.3626 6 10.3121 6.00996 10.265 6.02931C10.218 6.04866 10.1753 6.07701 10.1394 6.11273L7.13113 9.08153L5.86065 7.8277C5.82475 7.79198 5.78204 7.76363 5.73498 7.74428C5.68792 7.72493 5.63745 7.71497 5.58647 7.71497C5.53549 7.71497 5.48502 7.72493 5.43796 7.74428C5.3909 7.76363 5.34819 7.79198 5.31229 7.8277C5.24037 7.8991 5.2 7.9957 5.2 8.09638C5.2 8.19706 5.24037 8.29365 5.31229 8.36506L6.85695 9.88947C6.89304 9.92479 6.93583 9.95274 6.98288 9.9717Z"
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconCircleCheck;
