import React, { FC } from "react";
import { Category } from "../../shared/enum/BasicStateGeneral/CatalogBasicData";
import { IComboBox } from "./ComboBox.interfaces";
import { Controller } from "react-hook-form";
import { Autocomplete, AutocompleteValue, TextField } from "@mui/material";
import { comboBoxStyles } from "./ComboBox.style";
import { TypeElement } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { defaultTo, find, get, has, uniqBy } from "lodash";

const verifyGenericCountryOption = (coincidence: Category | undefined) => {
  if (get(coincidence, "id", "") === "Otra") {
    return `${get(coincidence, "label", "")}`;
  } else {
    return `${get(coincidence, "id", "")} - ${get(coincidence, "label", "")}`;
  }
};

const findArrayCatalog = (
  option: string | Category,
  catalg: Array<Category | string>,
  isMcc: boolean
): string => {
  const catlogCategory: Array<Category> = catalg as Array<Category>;

  if (option === "") {
    return "";
  }

  let coincidence: Category | undefined;

  coincidence = catlogCategory.find(
    (item) =>
      get(item, "id", "") === get(option, "id", "") ||
      get(item, "id", "") === option ||
      item === option ||
      item === get(option, "id", "")
  );

  return isMcc
    ? verifyGenericCountryOption(coincidence)
    : get(coincidence, "label", "");
};

const ComboBox: FC<IComboBox> = ({
  errors,
  name,
  options,
  control,
  label,
  typeCombo,
  rules,
  isMcc,
  isDisable,
}: IComboBox): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultTo(
        get(find(options, { selected: true }) as Category, "id") as string,
        ""
      )}
      rules={rules}
      render={({ field }: any) => {
        return (
          <Autocomplete
            disabled={isDisable}
            {...field}
            getOptionLabel={(value: string | Category) => {
              return typeCombo === TypeElement.COMBO_ARRAY
                ? findArrayCatalog(value, options, isMcc)
                : (value as string);
            }}
            onChange={(
              _,
              data: AutocompleteValue<Category | string, any, any, any>
            ) => {
              field.onChange(has(data, "id") ? get(data, "id") : data);
            }}
            error={get(errors, name) !== undefined}
            fullWidth
            sx={comboBoxStyles.error1}
            id="combo-box-demo"
            options={uniqBy(options, (item) =>
              has(item, "id") ? get(item, "id") : item
            )}
            noOptionsText={() => ""}
            renderInput={(params) => (
              <TextField
                {...params}
                error={get(errors, name) !== undefined}
                helperText={get(errors, name)?.message ?? ""}
                label={label}
              />
            )}
          />
        );
      }}
    />
  );
};

export default ComboBox;
