import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { FormFieldEnum } from "../../../shared/enums/FormFieldEnum";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "@kushki/connect-ui";
import { defaultTo, get, isEmpty } from "lodash";
import { LabelEnum } from "../../../shared/enums/LabelEnum";
import { BusinessRuleStatus } from "../../../shared/constants/BusinessRuleConstants";
import { Condition } from "../../../../types/get_payouts_rules_response";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { StylesEnum } from "../../../shared/enums/StyleEnum";
import {
  AlertEnum,
  ERROR_MESSAGE_ALERT,
} from "../../../shared/enums/AlertEnum";
import { IActionButtonsProps } from "../../../shared/interfaces/IActionButtonsProps";
import { IUseActionButtonState } from "../../../shared/interfaces/IUseActionButtonState";
import { RuleEnum } from "../../../shared/enums/RuleEnum";

export const useActionButtonState = (
  props: IActionButtonsProps
): IUseActionButtonState => {
  const { ruleId } = useParams();
  const { watch, getValues } = useFormContext();
  const watchProcessorId = watch(FormFieldEnum.RULE_PROCESSOR_ID);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    setIsButtonDisabled(isEmpty(watchProcessorId) || props.fields.length === 0);
  }, [watchProcessorId, props.fields]);

  const getPrimaryLabel = (): string => {
    if (isEmpty(ruleId)) {
      return LabelEnum.BTN_ADD_RULE;
    }
    if (get(props, "editRule.status", "") === BusinessRuleStatus.ENABLE)
      return LabelEnum.BTN_EDIT_RULE_ENABLE_PRIMARY;
    if (get(props, "editRule.status", "") === BusinessRuleStatus.DISABLE)
      return LabelEnum.BTN_ENABLE_RULE_DRAFT_PRIMARY;

    return LabelEnum.BTN_EDIT_RULE_DRAFT_PRIMARY;
  };

  const saveDraftConditionRender = (): boolean => {
    return (
      isEmpty(ruleId) ||
      get(props, "editRule.status", "") === BusinessRuleStatus.DRAFT
    );
  };

  const saveForm = (): void => {
    const conditions: Condition[] = defaultTo(getValues()["conditions"], []);

    for (const condition of conditions) {
      if (condition.field == RuleEnum.VALIDATE_ENROLL) continue;
      if (
        isEmpty(condition.value) ||
        isEmpty(get(condition.value, "[0]", ""))
      ) {
        showSnackbar({
          color: "danger",
          icon: (
            <ErrorOutlinedIcon
              fontSize={StylesEnum.INHERIT}
              color={StylesEnum.INHERIT}
            />
          ),
          message: ERROR_MESSAGE_ALERT[AlertEnum.ORIGIN_EMPTY],
          variant: "simple",
          withIcon: true,
        });

        return;
      }
    }
    props.onAddRule();
  };

  return {
    getPrimaryLabel,
    isButtonDisabled,
    saveDraftConditionRender,
    saveForm,
  };
};
