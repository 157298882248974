import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import { setTransactionsLimit } from "../../../../../store/actions/filters/filters";
import { selectPaginationFilter } from "../../../../../store/selectors/filters/filters";
import { selectTransactionsLoading } from "../../../../../store/selectors/transactions/transactions";
import { IUseTransactionsTableLimitSelectState } from "./useTransactionsTableLimitSelectState.interfaces";

export const useTransactionsTableLimitSelectState =
  (): IUseTransactionsTableLimitSelectState => {
    const dispatch = useAppDispatch();
    const areTransactionsLoading = useAppSelector(selectTransactionsLoading);
    const { limit, page, total } = useAppSelector(selectPaginationFilter);

    const handleSetTransactionsLimit = (limit: number) => {
      dispatch(setTransactionsLimit(limit));
    };

    const stepRange = limit * (page - 1);
    const startRange = stepRange + 1;
    const endRange =
      stepRange + (stepRange + limit > total ? total - stepRange : limit);
    const pagesRange = `${startRange}-${endRange}`;

    return {
      areTransactionsLoading,
      handleSetTransactionsLimit,
      limit,
      pagesRange,
      total,
    };
  };
