export const AppRoutes = {
  INDEX: "/processing",
  BATCH: "/processing/batch",
  BATCH_ALL: "/processing/batch?menuItem=true",
  CREATE_NODE_BRANCH_EDIT: "/create-node/branch/edit",
  CREATE_NODE_BRANCH_SELECTION: "/create-node/branch/selection",
  MAIN: "/",
  SERVICES: (publicMerchantId: string) =>
    `/service-configuration?menuItem=true&publicMerchantId=${publicMerchantId}`,
  CREATE_RULE: "/business-rules/create",
  CREATE_PAYOUT_RULE: "/payout-business-rules/create",
  EDIT_RULE: (ruleID: string) => `/business-rules/edit/${ruleID}`,
  EDIT_PAYOUT_RULE: (ruleID: string) => `/payout-business-rules/edit/${ruleID}`,
  RULES_LIST: (publicMerchantId: string): string =>
    `/business-rules/list/${publicMerchantId}`,
  PAYOUT_RULES_LIST: (publicMerchantId: string): string =>
    `/payout-business-rules/list/${publicMerchantId}`,
  RULES_LIST_BATCH: (publicMerchantId: string) =>
    `/business-rules/list/batch/${publicMerchantId}`,
  RULES_LIST_MASSIVE_BATCH: "/business-rules/list/batch",
  PAYOUT_RULES_LIST_MASSIVE_BATCH: "/payout-business-rules/list/batch",
  CREATE_DEFERRED: "/deferred/create",
  DEFERRED: "/deferred",
  BRANCH_EDIT: "/create-node/branch/edit",
  EDIT_DEFERRED: (deferredId: string) => `/deferred/edit/${deferredId}`,
  PROCESSOR_LIST: (merchantId: string) =>
    `/processor-list?merchantId=${merchantId}`,
  CREATE_TRANSFER_PROCESSOR: (merchantId: string) =>
    `/processor-transfer/${merchantId}`,
  CREATE_TRANSFER_INTERNATIONAL_PROCESSOR: (merchantId: string) =>
    `/processor-international-transfer/${merchantId}`,
  EDIT_TRANSFER_INTERNATIONAL_PROCESSOR: (
    merchantId: string,
    processorId: string
  ) => `/processor-international-transfer/${merchantId}/${processorId}`,
  DELETE_TRANSFER_INTERNATIONAL_PROCESSOR: (processorId: string) =>
    `/international-transfer/v1/admin/processor/${processorId}`,
  EDIT_TRANSFER_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-transfer/${merchantId}/${processorId}`,
  DELETE_TRANSFER_PROCESSOR: (processorId: string) =>
    `/transfer/v1/admin/processor/${processorId}`,
  CREATE_TRANSFER_SUBS_PROCESSOR: (merchantId: string) =>
    `/processor-transfer-subscriptions/${merchantId}`,
  EDIT_TRANSFER_SUBS_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-transfer-subscriptions/${merchantId}/${processorId}`,
  DELETE_TRANSFER_SUBS_PROCESSOR: (processorId: string) =>
    `/transfer-subscriptions/v1/admin/processor/${processorId}`,
  CREATE_CASH_PROCESSOR: (merchantId: string) =>
    `/processor-cash/${merchantId}`,
  EDIT_CASH_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-cash/${merchantId}/${processorId}`,
  DELETE_CASH_PROCESSOR: (processorId: string) =>
    `/cash/v1/admin/processor/${processorId}`,
  CREATE_PAYOUTS_TRANSFER_PROCESSOR: (merchantId: string) =>
    `/processor-payouts-transfer/${merchantId}`,
  EDIT_PAYOUTS_TRANSFER_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-payouts-transfer/${merchantId}/${processorId}`,
  DELETE_PAYOUTS_TRANSFER_PROCESSOR: (processorId: string) =>
    `/payouts/transfer/v1/admin/processor/${processorId}`,
  CREATE_PAYOUTS_CASH_PROCESSOR: (merchantId: string) =>
    `/processor-payouts-cash/${merchantId}`,
  EDIT_PAYOUTS_CASH_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-payouts-cash/${merchantId}/${processorId}`,
  DELETE_PAYOUTS_CASH_PROCESSOR: (processorId: string) =>
    `/payouts/cash/v1/admin/processor/${processorId}`,
  CREATE_CARD_PROCESSOR: (merchantId: string) =>
    `/processor-card/${merchantId}`,
  CREATE_PAYOUT_CARD_PROCESSOR: (merchantId: string) =>
    `/processor-payouts-card/${merchantId}`,
  EDIT_PAYOUT_CARD_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-payouts-card/${merchantId}/${processorId}`,
  DELETE_PAYOUT_CARD_PROCESSOR: (processorId: string) =>
    `/payouts/card/v1/admin/processor/${processorId}`,
  EDIT_CARD_PROCESSOR: (merchantId: string, processorId: string) =>
    `/processor-card/${merchantId}/${processorId}`,
  DELETE_CARD_PROCESSOR: (merchantId: string, processorId: string) =>
    `/rules/v1/admin/processor/${merchantId}/${processorId}`,
  INDEX_MERCHANT: (merchantId: string) =>
    `?menuItem=true&publicMerchantId=${merchantId}`,
  EDIT_MASSIVE: () => "/create-node/branch/edit",
};
