import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import {
  setBackgroundColor,
  setColor,
} from "../../shared/infrastructure/Colors";
import React from "react";
import { format } from "date-fns-tz";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { ModalStyles } from "../Common/ModalCard/ModalStyles";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import { defaultTo, get, isEmpty } from "lodash";
import {
  DashboardTableComponentProps,
  getValue,
} from "../DashboardList/Table/DashboardBillingTable/DashboardTable";
import { InvoiceModeEnum } from "../../shared/infrastructure/InvoiceModeEnum";
import { getTransactionTypeTraduce } from "../../shared/infrastructure/TransactionTypeTraduce";
import { TransactionTypeEnum } from "../../shared/infrastructure/TransactionTypeEnum";

const formatDate = (trxDate: number, formatDate: string): string => {
  if (!trxDate) return "";
  const date: string = new Date(
    trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
  ).toISOString();

  return format(new Date(date.replace("Z", "")), formatDate, {
    timeZone: "UTC",
  });
};

const getTitle = (
  kind: string,
  invoiceMode: string = "",
  is3DS?: boolean
): string => {
  let title: string;
  switch (kind) {
    case KindEnum.INVOICE:
      if (invoiceMode === InvoiceModeEnum.WITH_IVA) title = "Factura con IVA";
      else if (invoiceMode === InvoiceModeEnum.WITHOUT_IVA)
        title = "Factura sin IVA";
      else if (is3DS) title = "factura 3DS";
      else title = "Factura";
      break;
    case KindEnum.CREDIT_NOTE:
      title = "Nota de Crédito";
      break;
    case KindEnum.DEBIT_NOTE:
      title = "Nota de Débito";
      break;
    case KindEnum.CHARGE:
      title = "Cobro";
      break;
    case KindEnum.RECEIVABLE:
      title = "Cuenta por cobrar";
      break;
    case KindEnum.DISPERSION:
      title = "Dispersión";
      break;
    case KindEnum.VOUCHER:
      title = "Boleta";
      break;
    default:
      title = "";
      break;
  }

  return title;
};
export const validate3DS = (
  additionalServices: string[],
  hasAdditionalServices: boolean
): boolean => {
  return !!(
    !isEmpty(additionalServices) &&
    additionalServices.includes("3ds") &&
    hasAdditionalServices
  );
};
const itemChipTrxType = (props: DashboardTableComponentProps) => ({
  background: "#F0F4F9",
  color: "inherit",
  value:
    validate3DS(
      get(
        props,
        "modalOverBilling.trxBilling.trx.transaction.additionalServices",
        []
      ),
      get(
        props,
        "modalOverBilling.trxBilling.trx.transaction.hasAdditionalServices",
        false
      )
    ) && getValue(props, "kind") === KindEnum.INVOICE
      ? TransactionTypeEnum.ADDITIONAL_SERVICE
      : getTransactionTypeTraduce(
          get(
            props,
            "modalOverBilling.trxBilling.trx.transaction.transactionType"
          )
        ),
});

export const ModalHeaderBilling = (props: DashboardTableComponentProps) => {
  const classes = ModalStyles();
  const chipListItems = [
    {
      background: setBackgroundColor(getValue(props, "status")),
      color: setColor(getValue(props, "status")),
      value: translate(
        getValue(props, "status"),
        getValue(props, "country"),
        getValue(props, "kind")
      ),
    },
  ];

  (getValue(props, "kind") === KindEnum.INVOICE ||
    getValue(props, "kind") === KindEnum.CREDIT_NOTE ||
    getValue(props, "kind") === KindEnum.DEBIT_NOTE) &&
    chipListItems.push(itemChipTrxType(props));

  !validate3DS(
    get(
      props,
      "modalOverBilling.trxBilling.trx.transaction.additionalServices",
      []
    ),
    get(
      props,
      "modalOverBilling.trxBilling.trx.transaction.hasAdditionalServices",
      false
    )
  ) &&
    get(
      props,
      "modalOverBilling.trxBilling.trx.transaction.transactionType"
    ) === TransactionTypeEnum.THREE_DS &&
    chipListItems.pop();
  return (
    <ModalHeader
      className={classes.modalHeader}
      highlightValue={getValue(props, "socialReason")}
      tittle={`Detalle de ${getTitle(
        getValue(props, "kind"),
        get(props, "invoiceMode"),
        validate3DS(
          get(
            props,
            "modalOverBilling.trxBilling.trx.transaction.additionalServices",
            []
          ),
          get(
            props,
            "modalOverBilling.trxBilling.trx.transaction.hasAdditionalServices",
            false
          )
        )
      )}`}
      secondaryValue={`${
        defaultTo(getValue(props, "isExclusive"), false)
          ? translate("exclusive")
          : translate(getValue(props, "cycle"))
      } ${formatDate(
        getValue(props, "startDate"),
        "dd/MM/yyyy"
      )} - ${formatDate(getValue(props, "endDate"), "dd/MM/yyyy")}`}
      chipList={chipListItems}
    />
  );
};
