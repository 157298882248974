import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { DetailFooterStyles as style } from "./DetailFooter.styles";
import { MenuOptions } from "../../../MenuOptions/MenuOptions";
import {
  CHECK_DIALOG,
  REFUND_DIALOG,
  SKIP_DIALOG,
} from "../../../../shared/constants/dialog_types";
import { ItemTitleEnum } from "../../../../shared/infrastructure/enums/ItemTitleEnum";
import { defaultTo, get } from "lodash";
import { GenericModal } from "../../ConfirmTransactionModal/GenericModal";
import { refundModalStyles } from "../../RefundModal/RefundModal.styles";
import { Typography } from "@mui/material";
import { ConciliationActionEnum } from "../../../../shared/enums/concilation_action_enum";
import { setConciliationAction } from "../../../../store/actions/app/app";
import { useDispatch } from "react-redux";
import updatePendingTransaction from "../../../../store/thunks/transactions/updatePendingTransactions/updatePendingTransactions";
import { UpdatePendingTransactions } from "../../../../../types/transaction_conciliation_update_request";
import { DetailFooterProps } from "./DetailFooter.interfaces";

const DetailFooter = ({
  conciliationTransaction,
  dialogActions,
}: DetailFooterProps) => {
  const [openProcessLoaderModal, setOpenProcessLoaderModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const payload = JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));

  const handleOpenProcessLoaderModal = () => {
    setOpenProcessLoaderModal(true);
  };

  const handleCloseProcessLoaderModal = () => {
    setOpenProcessLoaderModal(false);
  };

  const handleClickConfirmModal = () => {
    const request: UpdatePendingTransactions = {
      action: "processConciliationTransactions",
      data: {
        conciliationAction: ConciliationActionEnum.PENDING,
        data: [{ conciliationId: conciliationTransaction.conciliationId }],
        observationInfo: {
          reason: "revert to pending",
        },
        source: "historic",
      },
    };

    dispatch(setConciliationAction(ConciliationActionEnum.PENDING));
    dispatch(updatePendingTransaction(request));
    setOpenProcessLoaderModal(false);
  };

  return (
    <>
      {conciliationTransaction.conciliationAction != "omitted" &&
      dialogActions ? (
        <Box sx={style.box} p={1}>
          <MenuOptions
            items={[
              {
                handleClick: () =>
                  dialogActions.handleOpen(
                    conciliationTransaction,
                    REFUND_DIALOG
                  ),
                title: ItemTitleEnum.REFUND,
                type: "primary",
              },
              {
                handleClick: () =>
                  dialogActions.handleOpen(
                    conciliationTransaction,
                    REFUND_DIALOG
                  ),
                title: ItemTitleEnum.LIQUIDATE,
                type: "primary",
              },
              {
                handleClick: () =>
                  dialogActions.handleOpen(
                    conciliationTransaction,
                    SKIP_DIALOG
                  ),
                title: ItemTitleEnum.OMITTED,
                type: "primary",
              },
              {
                handleClick: () =>
                  dialogActions.handleOpen(
                    conciliationTransaction,
                    CHECK_DIALOG
                  ),
                title: ItemTitleEnum.REVIEW,
                type: "primary",
              },
            ]}
            transaction={conciliationTransaction}
            username={get(payload, "cognito:username", "")}
          />
        </Box>
      ) : conciliationTransaction.conciliationAction == "omitted" ? (
        <Box sx={style.box_left} p={1}>
          <Button
            sx={refundModalStyles.sendBtn}
            id={"sendRevertPending"}
            autoFocus
            color="primary"
            variant="contained"
            onClick={handleOpenProcessLoaderModal}
          >
            <Typography sx={refundModalStyles.buttonsText}>
              {"Regresar a pendiente"}
            </Typography>
          </Button>
          <GenericModal
            title="Enviar a bandeja de pendientes"
            message="Esta transacción se encuentra en estado Omitido y se enviará a la bandeja de pendientes. ¿Estás seguro de que deseas efectuar este cambio?"
            open={openProcessLoaderModal}
            handleClose={handleCloseProcessLoaderModal}
            handleAction={handleClickConfirmModal}
          ></GenericModal>
        </Box>
      ) : undefined}
    </>
  );
};

export default DetailFooter;
