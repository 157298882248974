import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const dialogStyles = createNamedStyles({
  dialogProcessor:{
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "634px",  // Set your width here
      },
    }},
  dialogLoading:{
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "537px",  // Set your width here
      },
    }},
  dialogTitle: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
  },
  dialogSubtitle: {
    color: "#293036",
    marginLeft:"46px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "140%",
  },
  finishButton: {
    color: "#6D7781",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  actionButton: {
    backgroundColor: "#023365",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  buttonPrincipal: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  title: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
  },
  subtitle: {
    color: "#6D7781",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "120%",
  },
  note: {
    paddingTop: "5px",
  },
  newProcessor: {
    color: "white",
  }
});

export const returningDialogStyles = createNamedStyles({

  actionButton: {
    color: "#6D7781",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  buttonPrincipal: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  title: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
  },
  subtitle: {
    color: "#6D7781",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "120%",
  },
  note: {
    paddingTop: "5px",
  },
  exitButton: {
    color: "white",
  },
});
