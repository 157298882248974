import { useEffect, useState } from "react";
import { INotification, IValidateDomain } from "../../../../store/reducer";
import { MessageDialogProps } from "../../../MessageDialog/MessageDialog.interfaces";
import { isEmpty } from "lodash";
import { SaveCredential } from "../../../../../types/save_credential_request";
import { DomainByMerchant } from "../../../../shared/interfaces/DomainByMerchant";
import { environment } from "../../../../environments/environment";
import axiosInstance from "../../../../shared/axios-util";
import { AliasCredentialEnum } from "../../../../shared/infrastructure/constants/AliasCredentialEnum";
import { ChannelEnum } from "../../../../shared/infrastructure/constants/ChannelEnum";
import { PluginTypeEnum } from "../../../../shared/infrastructure/constants/PluginTypeEnum";

const REGEX_VALID_DOMAIN = /^(?!https?:)(?:[\w-]+\.)+[a-z]{2,6}$/i;

const useMagentov2State = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showBlurredText, setShowBlurredText] = useState<boolean>(false);
  const [privateCredential, setPrivateCredential] = useState<string>();
  const [publicCredential, setPublicCredential] = useState<string>();
  const [executeSave, setExecuteSave] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [hasCredentials, setHasCredentials] = useState<boolean>(false);
  const [messageDialog, setMessageDialog] = useState<MessageDialogProps>({
    open: false,
  });
  const [magentov2Domain, setMagentov2Domain] = useState("");
  const [blockSaveButton, setBlockSaveButton] = useState(false);
  const merchantId = localStorage.getItem("merchantId")!;
  const [notification, setNotification] = useState<INotification>({
    message: "",
    type: "error",
    open: false,
  });
  const [isValid, setValid] = useState<IValidateDomain>({
    validDomain: false,
    httpErrorMessage: "",
  });
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [notificationSuccess, setNotificationSuccess] = useState<INotification>(
    {
      message: "",
      type: "success",
      open: false,
    }
  );

  const handleOnCloseNotification = () => {
    setNotification({
      message: "",
      type: "error",
      open: false,
    });
  };
  const handleOnCloseNotificationSuccess = () => {
    setNotificationSuccess({
      message: "",
      type: "success",
      open: false,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (isEmpty(publicCredential) || isEmpty(privateCredential)) {
      try {
        const credentialsRequest = {
          alias: AliasCredentialEnum.ADOBE_COMMERCE,
          merchantId: merchantId,
          hidden: false,
        };

        const credentialsResponse = await axiosInstance.post(
          `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials`,
          credentialsRequest
        );

        setPrivateCredential(credentialsResponse.data.privateCredentialId);
        setPublicCredential(credentialsResponse.data.publicCredentialId);
        setExecuteSave(true);
      } catch (_error) {
        setExecuteSave(false);
        setNotification({
          message:
            "Ha ocurrido un error al guardar las credenciales. Vuelve a intentarlo.",
          open: true,
          type: "error",
        });
        setTimeout(() => {
          setBlockSaveButton(false);
        }, 3000);
      }
    } else {
      setExecuteSave(true);
    }
  };

  useEffect(() => {
    if (executeSave) {
      const save = async () => {
        setBlockSaveButton(true);
        const request: SaveCredential = {
          domain: magentov2Domain,
          merchantId: merchantId,
          pluginType: PluginTypeEnum.ADOBE_COMMERCE,
        };
        await axiosInstance.post(
          `${environment.kushkiUrl}/plugins/v1/saveCredentials`,
          isUpdate
            ? request
            : {
                ...request,
                privateKey: privateCredential,
                publicKey: publicCredential,
              }
        );
        setLoading(false);
        isUpdate
          ? setNotificationSuccess({
              message: "Tu dominio se ha actualizado con éxito.",
              open: true,
              type: "success",
            })
          : setShowSuccessDialog(true);
        setBlockSaveButton(true);
        setHasCredentials(true);
        setIsUpdate(true);
      };

      save().catch((error) => {
        setLoading(false);
        const messageError = (error as {
          response?: {
            data?: {
              message: string;
            };
          };
        }).response?.data?.message;
        if (
          messageError &&
          messageError ===
            "Este Dominio se encuentra vinculado a otro comercio."
        ) {
          setMessageDialog({
            description:
              "Este dominio ya es utilizado por otro comercio. Por favor intenta ingresar otro.",
            onClose: () => {
              setMessageDialog({
                open: false,
              });
            },
            open: true,
            title: "Validación de dominio",
          });
          setBlockSaveButton(false);
        } else {
          setNotification({
            message:
              "Ha ocurrido un error al guardar el dominio. Vuelve a intentarlo.",
            open: true,
            type: "error",
          });
          setBlockSaveButton(true);
          setTimeout(() => {
            setBlockSaveButton(false);
          }, 3000);
        }
      });
      setExecuteSave(false);
    }
  }, [executeSave]);

  const handleChangeText: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setMagentov2Domain(e.target.value);
  };

  const handleClickShowBlurredText = () => {
    setShowBlurredText(!showBlurredText);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setBlockSaveButton(false);
    const domain = e.target.value;
    const valid_domain = REGEX_VALID_DOMAIN.test(domain);
    setValid({
      validDomain: false,
      httpErrorMessage: "",
    });
    setMagentov2Domain(domain);
    handleDomain(valid_domain, domain);
  };

  const handleDomain = (valid_domain: boolean, domain: string) => {
    if (valid_domain) {
      (async () => {
        try {
          const request = {
            channel: PluginTypeEnum.ADOBE_COMMERCE,
            domain: domain,
          };
          const { data } = await axiosInstance.post(
            `${environment.kushkiUrl}/plugins/v1/validateDomain`,
            request
          );
          setBlockSaveButton(!data);
          setValid(data);
        } catch (error) {
          if (error.response.status !== 504) {
            setBlockSaveButton(true);
            setValid({
              validDomain: false,
              httpErrorMessage: "",
            });
          }
        }
      })();
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const merchantId = localStorage.getItem("merchantId")!;
      try {
        const { data } = await axiosInstance.get<DomainByMerchant[]>(
          `${environment.kushkiUrl}/plugins/v1/getDomainsByMerchant/${merchantId}`
        );

        const domainByMerchant = data.find(
          (d) => d.channel === ChannelEnum.ADOBE_COMMERCE
        );

        if (domainByMerchant) {
          setMagentov2Domain(domainByMerchant.domain);
          setPublicCredential(domainByMerchant.publicKey);
          setPrivateCredential(domainByMerchant.privateKey);
          setIsUpdate(true);
        }

        setValid({
          validDomain: Boolean(domainByMerchant),
          httpErrorMessage: "",
        });
        setBlockSaveButton(true);
        setHasCredentials(Boolean(domainByMerchant));
      } catch {
        setValid({
          validDomain: false,
          httpErrorMessage: "",
        });
        setHasCredentials(false);
      } finally {
        setLoading(false);
      }
    })();
    setMessageDialog({ open: false });
  }, []);
  return {
    handleChange,
    handleChangeText,
    handleClickShowBlurredText,
    handleCloseSuccessDialog,
    handleOnCloseNotification,
    handleSubmit,
    handleOnCloseNotificationSuccess,
    blockSaveButton,
    hasCredentials,
    isValid,
    loading,
    messageDialog,
    notification,
    privateCredential,
    publicCredential,
    showBlurredText,
    showSuccessDialog,
    magentov2Domain,
    notificationSuccess,
  };
};

export default useMagentov2State;
