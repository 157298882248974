import React from "react";
import { Typography, Grid, Button, IconButton } from "@material-ui/core";
import { Copy, Plus } from "react-feather";
import Breadcrumb, { BreadcrumProps } from "../Breadcrumb/Breadcrumb";
import { useHistory } from "react-router-dom";

export interface PageHeaderProps {
  isAdmin: boolean;
  isWebhookList?: boolean;
  title: string;
  breadcrumbs: BreadcrumProps;
  merchantName?: string;
  merchantId?: string;
  handleNotification: () => void;
  navigateCreate?: () => void;
  webhookSignature?: string;
  returnCreateMerchant?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = (
  props: PageHeaderProps
) => {
  const clipboard = async (text: string): Promise<void> => {
    await window.navigator.clipboard.writeText(text);
  };
  const history = useHistory();

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Breadcrumb
            items={props.breadcrumbs.items}
            lastItem={props.breadcrumbs.lastItem}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Typography color="primary" variant={"h1"}>
                        {props.title}
                      </Typography>
                    </Grid>
                    {props.returnCreateMerchant && (
                      <Grid item>
                        <Button
                          variant={"outlined"}
                          size={"small"}
                          onClick={() =>
                            history.push(
                              `/create-merchant/details?publicMerchantId=${props.merchantId}&hideSideBar=true`
                            )
                          }
                        >
                          Volver a la creación del comercio
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {props.isWebhookList ? (
                  <Grid item>
                    <Grid container direction={"row"} spacing={1}>
                      <Grid item>
                        <Typography variant={"subtitle1"}>
                          Webhook signature
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={"subtitle1"}>
                          {props.webhookSignature}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => clipboard(props.webhookSignature!)}
                        >
                          <Copy size={14} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              {props.isWebhookList && props.isAdmin ? (
                <Grid container direction={"row"} justify={"flex-end"}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Plus size={18} />}
                      onClick={props.navigateCreate}
                    >
                      Agregar webhooks
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
