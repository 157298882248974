import { Box, Button, Grid, Typography } from "@mui/material";
import { FC } from "react";
import React from "react";
import { footerStyles } from "./FooterOptions.styles";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  DEFAULT_IS_DISABLE_BUTTON,
  DEFAULT_IS_EDIT_LABEL,
  DEFAULT_IS_HIDDEN_LABEL,
  SAVE_TEXT,
} from "../../shared/constants/labels/main_footer_labels";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
  },
  label = {
    editDetail: DEFAULT_IS_EDIT_LABEL,
    isHidden: DEFAULT_IS_HIDDEN_LABEL,
    modify: "",
    text: "",
  },
}: IFooterOptions) => {
  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.buttonContainer}>
            <Button
              data-testid="idButtonTest"
              variant="contained"
              sx={footerStyles.saveButton}
              disabled={primaryButton.isDisabled}
              onClick={primaryButton.onAction}
            >
              {SAVE_TEXT}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          {!label.isHidden && (
            <Box sx={footerStyles.labelContainer}>
              <Box sx={footerStyles.labelBox}>
                <Typography sx={footerStyles.label}>
                  Modificado por: {label.text}
                </Typography>
              </Box>
              <Box sx={footerStyles.labelBox}>
                <Typography sx={footerStyles.label}>{label.modify}</Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
