import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@material-ui/core/styles";
import { BillingConfig } from "../../../types/billing_config";
import { get } from "lodash";
import { PARAMETERIZATION_CATALOG } from "../../shared/infrastructure/constants/MerchantInformationConstant";

export interface ParameterizationSectionProps {
  billingConfig: BillingConfig;
}

const useStyles = makeStyles(() => ({
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelColumns: {
    color: "#293036",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelValues: {
    color: "#293036",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: 12,
  },
}));

export const ParameterizationSection: React.FC<ParameterizationSectionProps> = (
  props: ParameterizationSectionProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Grid container alignItems="baseline">
        <Grid item container xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item container>
              <Grid item xs={12}>
                <Typography className={style.labelTitle2}>
                  Valores a facturar por transacciones y servicios adicionales
                </Typography>
                <Typography className={style.labelTitle3}>
                  Son los valores que verás facturados en tus comprobantes de
                  pago.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography className={style.labelColumns}>
                  <b>Aprobado - Suma</b>
                </Typography>
                <div>
                  <List>
                    {props.billingConfig.valuesBilling.elementsApproved.map(
                      (element, index) => {
                        return (
                          <ListItem
                            key={index}
                            dense={true}
                            disableGutters={true}
                          >
                            <ListItemIcon
                              classes={{ root: style.listItemIcon }}
                            >
                              <DoneIcon fontSize={"small"} />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography className={style.labelValues}>
                                {get(
                                  PARAMETERIZATION_CATALOG.filter(
                                    (frequency) => frequency.value === element
                                  )[0],
                                  "name",
                                  ""
                                )}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item container>
              <Grid item xs={12}>
                <Typography className={style.labelTitle2}>
                  Valores a restar por transacciones y servicios adicionales
                </Typography>
                <Typography className={style.labelTitle3}>
                  Son los valores que verás devueltos en tus comprobantes de
                  pago.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography className={style.labelColumns}>
                  <b>Aprobado - Resta</b>
                </Typography>
                <div>
                  <List>
                    {props.billingConfig.valuesSubtractBilling.elementsApproved.map(
                      (element, index) => {
                        return (
                          <ListItem
                            key={index}
                            dense={true}
                            disableGutters={true}
                          >
                            <ListItemIcon
                              classes={{ root: style.listItemIcon }}
                            >
                              <DoneIcon fontSize={"small"} />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography className={style.labelValues}>
                                {get(
                                  PARAMETERIZATION_CATALOG.filter(
                                    (frequency) => frequency.value === element
                                  )[0],
                                  "name",
                                  ""
                                )}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
