export enum RuleAlarmsItemsEnum {
  ALARM_GENERAL = "generalAlarm",
  ALARM_USER = "userAlarm",
}

export enum RuleAlarmsLabelsEnum {
  ALARM_GENERAL = "Crear alarma general",
  ALARM_USER = "Crear alarma por cliente(s)",
  ALARM_USER_EDIT = "Editar alarma por cliente(s)",
  ALARM_GENERAL_EDIT = "Editar alarma general",
}
