import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { InsertCredential } from "../../../../types/insert_credential";
import { useEffect, useState } from "react";
import {
  insertCredentials,
  setIsOpenCredentialModal,
  updateCredential,
} from "../../../store/actionCreators";
import { defaultTo } from "lodash";
import { IAddCredentialModalProps } from "../../../shared/infrastructure/interfaces/IAddCredentialModalProps";
import { IParams } from "../../../shared/infrastructure/interfaces/IParams";
import { GetCredentialsResponse } from "../../../../types/get_credentials_response";

export interface IUseAddCredentialModalState {
  isOpenCredentialModal: boolean;
  handleInsertAlias: () => void;
  handleCloseModal: () => void;
  isDuplicate: boolean;
  setValueAlias: (value: ((prevState: string) => string) | string) => void;
  merchantId: string;
  valueAlias: string;
  isButtonDisabled: boolean;
}

export const useAddCredentialModalState = (
  props: IAddCredentialModalProps
): IUseAddCredentialModalState => {
  const { merchantId } = useParams<IParams>();
  const dispatch = useDispatch();
  const credentialList: GetCredentialsResponse = useSelector(
    (state: IAppState) =>
      defaultTo(state.credentialsListModal, { data: [], total: 0 })
  );
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [valueAlias, setValueAlias] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const isOpenCredentialModal: boolean = useSelector((state: IAppState) =>
    defaultTo(state.isOpenCredentialModal, false)
  );

  const handleInsertAlias = (): void => {
    setIsButtonDisabled(true);
    const insertRequest: InsertCredential = {
      alias: valueAlias,
      merchantId: merchantId,
      hidden: false,
      customerMerchantId: props.parentMerchantID,
    };
    if (props.isEdit && props.editCredentialRequest) {
      dispatch(
        updateCredential({
          ...props.editCredentialRequest,
          alias: valueAlias,
          customerMerchantId: props.parentMerchantID,
        })
      );
    } else {
      dispatch(insertCredentials(insertRequest));
    }
  };

  useEffect(() => {
    setIsDuplicate(false);
    setIsButtonDisabled(false);
  }, [isOpenCredentialModal]);

  useEffect(() => {
    if (credentialList) {
      const cAlias = valueAlias;
      const aliasDuplicateArr = credentialList.data.filter(
        (credential: any) => credential._source.alias === cAlias
      );
      setIsDuplicate(aliasDuplicateArr.length > 0);
    }
  }, [credentialList]);

  const handleCloseModal = (): void => {
    dispatch(setIsOpenCredentialModal(false));
  };

  return {
    handleCloseModal,
    handleInsertAlias,
    isDuplicate,
    isOpenCredentialModal,
    merchantId,
    setValueAlias,
    valueAlias,
    isButtonDisabled,
  };
};
