import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { Edit } from "react-feather";
import { ITableEndOptions } from "./TableEndOptions.interfaces";
import { tableEndOptionsStyles as styles } from "./TableEndOptions.styles";
import { CustomSwitch } from "../CustomSwitch/CustomSwitch";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { CompomentIdsEnum } from "../../shared/constants/labels/main_containter_labels";

const TableEndOptions = <T,>(props: ITableEndOptions<T>): JSX.Element => {
  const { checkedCondition, handleChangeSwitch, handleClickEditButton, row } =
    props;
  const classes = styles();

  return (
    <Box className={classes.boxContainer}>
      <ComponentSecurityWrapper
        componentId={
          props.isModule
            ? CompomentIdsEnum.M_ROLES_SWITCH_STATUS_MODULE
            : CompomentIdsEnum.M_ROLES_SWITCH_STATUS_COMPONENT
        }
      >
        <CustomSwitch
          disabled={props.isModule}
          hidden={props.isModule}
          checked={checkedCondition(props.row)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (props.isModule) {
            } else {
              handleChangeSwitch(event, row);
            }
          }}
        />
      </ComponentSecurityWrapper>

      <ComponentSecurityWrapper
        componentId={
          props.isModule
            ? CompomentIdsEnum.M_ROLES_EDIT_MODULE
            : CompomentIdsEnum.M_ROLES_EDIT_COMPONENT
        }
      >
        <IconButton
          className={classes.button}
          onClick={() => {
            handleClickEditButton(row);
          }}
        >
          <Edit size={17.5} />
        </IconButton>
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default TableEndOptions;
