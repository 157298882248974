import React from "react";
import { IUseDropDown, IUseDropDownProps } from "../DropDown.interfaces";
import { defaultTo } from "lodash";

export const useDropDown = ({ values }: IUseDropDownProps): IUseDropDown => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent) => {
    setAnchor(null);
    event.stopPropagation();
  };

  function getValueName(id: string): string {
    const foundValue: { id: string; value: string } | undefined = values.find(
      (valueItem: { id: string; value: string }) =>
        valueItem.value.toLowerCase() === defaultTo(id, "").toLowerCase()
    );

    if (foundValue) return foundValue.value;

    return "";
  }

  return { anchor, getValueName, handleClick, handleClose, open };
};
