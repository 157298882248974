import React, { FC } from "react";
import {
  SHAREHOLDER_BUTTON_TEXT,
  SHAREHOLDER_DESCRIPTION,
  SHAREHOLDER_TITLE,
  ShareholderFields,
} from "../../shared/constants/shareholder_constants";
import { get, isEmpty, toString } from "lodash";
import { IAccordionProps } from "../Accordion/Accordion.interfaces";
import { IconEdit, IconTrash } from "../Icons/index.export";
import { IShareHolder } from "../../store/interfaces/LegalDetailsState.interfaces";
import {
  PARTICIPATION_SHAREHOLDERS_TEXT,
  REGISTERED_SHAREHOLDERS_TEXT,
} from "../../shared/constants/labels/AccordionConstants";
import { Badge, Box, Grid, Typography } from "@mui/material";
import ModalDialog from "../ModalDialog/ModalDialog";
import {
  ACCEPT_BUTTON_DELETE_TEXT,
  CANCEL_BUTTON_TEXT,
  DESCRIPTION_DELETE_TEXT,
  TITLE_DELETE_TEXT,
} from "../../shared/constants/labels/ModalDialogConstants";
import { ShareholderSectionStyles as styles } from "./ShareholderSection.styles";
import Accordion from "../Accordion/Accordion";
import { useShareholderSection } from "./state/useShareholderSection";
import ModalForm from "../LegalDetailsBody/ModalForm/ModalForm";
import ShareholderForm from "../LegalDetailsBody/Forms/ShareholderForm/ShareholderForm";
import SectionTitle from "../SectionTitle/SectionTitle";
import AccordionStyles from "../Accordion/Accordion.styles";
import {
  getNestingLabel,
  getTotalParticipationPercentage,
  getTotalShareholderNumber,
  getUsedPercentage,
  isMoralShareholder,
  validateIsPossibleAddShareholderChildren,
} from "../../shared/utils/shareholderUtils";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { AccordionDefault } from "../../shared/constants/shareholder_section_constants";
import {
  isAvailableCountry,
  PERSON_TYPE_BY_COUNTRY_VALUES,
} from "../../shared/services/CatalogPersonTypeByCountry";
import { IMultipleFormValues } from "../../shared/constants/initial_state_legal_details";
import { CountryEnum } from "../../shared/countryEnum";
import { getCountryName } from "../../shared/constants/Countries";
import { IShareHolderSection } from "./ShareHolderSection.interfaces";

const ShareholderSection: FC<IShareHolderSection> = ({
  isEdit,
  isCentralize,
}: IShareHolderSection) => {
  const {
    handleOpenModal,
    handleAcceptModalDialog,
    handleCloseModalDialog,
    handleOpenModalDialog,
    isPossibleAddShareholder,
    openModalDeleteShareholder,
    openModalSh,
    shareholderPosition,
    shareHolders,
    country,
  } = useShareholderSection();

  const getButtonAddSubShareholders = (
    isMoral: boolean,
    nesting: string,
    shareholder: IShareHolder
  ) => ({
    handleClick: (nesting: string) => handleOpenModal(nesting),
    hidden: !isMoral,
    isDisabled: !validateIsPossibleAddShareholderChildren(
      nesting,
      shareholder,
      shareHolders
    ),
  });

  const getListDropDown = (nesting: string) => [
    {
      icon: <IconEdit fontSize="small" />,
      id: "1",
      onSelect: () => {
        handleOpenModal(nesting);
      },
      text: "Editar",
      variant: "withIcon",
    },
    {
      icon: <IconTrash fontSize="small" />,
      id: "2",
      onSelect: () => {
        handleOpenModalDialog(nesting);
      },
      text: "Eliminar",
      variant: "withIcon",
    },
  ];

  const getPersonType = (country: string, value: string) => {
    const countryAux: string = isAvailableCountry(country)
      ? country
      : CountryEnum.generic;

    return PERSON_TYPE_BY_COUNTRY_VALUES[countryAux]
      .filter((item: IMultipleFormValues) => item.value === value)
      .map((item: IMultipleFormValues) => item.name.toLowerCase());
  };

  function buildDataByPersonType(
    shareholderInfo: IShareHolder,
    index: string
  ): IAccordionProps {
    const isMoral = isMoralShareholder(shareholderInfo.personType);

    return isMoral
      ? {
          ...AccordionDefault,
          badgeText: String(shareholderInfo.participationPercentage).concat(
            "%"
          ),
          buttonAddSubShareholders: getButtonAddSubShareholders(
            isMoral,
            index,
            shareholderInfo
          ),
          isDisableDropdownList: isEdit && isCentralize,
          listDropdown: getListDropDown(index),
          nesting: index,
          nextNesting: `${index}-${
            get(shareholderInfo, ShareholderFields.CHILDREN, []).length
          }`,
          shareholderInfo: {
            ...AccordionDefault.shareholderInfo,
            country: getCountryName(
              get(shareholderInfo, "merchantCountry", "")
            ),
            id: shareholderInfo.id,
            isMoral,
            participationPercentage: `${getUsedPercentage(
              get(shareholderInfo, ShareholderFields.CHILDREN, [])
            )}%`,
            registeredShareholders: `${
              get(shareholderInfo, ShareholderFields.CHILDREN, []).length
            }`,
            socialReason: shareholderInfo.socialReason,
          },
          text: AccordionDefault.text.concat(getNestingLabel(index)),
          text2: AccordionDefault.text2?.concat(getPersonType(country, "02")),
        }
      : {
          ...AccordionDefault,
          badgeText: String(shareholderInfo.participationPercentage).concat(
            "%"
          ),
          buttonAddSubShareholders: getButtonAddSubShareholders(
            isMoral,
            index,
            shareholderInfo
          ),
          isDisableDropdownList: isEdit && isCentralize,
          listDropdown: getListDropDown(index),
          nesting: index,
          nextNesting: `${index}-${
            get(shareholderInfo, ShareholderFields.CHILDREN, []).length
          }`,
          shareholderInfo: {
            ...AccordionDefault.shareholderInfo,
            country: getCountryName(get(shareholderInfo, "country", "")),
            date: shareholderInfo.birthdate!,
            id: shareholderInfo.id,
            isMoral,
            name: get(shareholderInfo, "name", "")
              .concat(" ")
              .concat(get(shareholderInfo, "lastName", "")),
            registeredShareholders: "0",
          },
          text: AccordionDefault.text.concat(getNestingLabel(index)),
          text2: AccordionDefault.text2?.concat(getPersonType(country, "01")),
        };
  }

  const getShareholderChildren = (
    shareholderInfo: IShareHolder,
    fatherIndex: string
  ): React.ReactNode =>
    get(shareholderInfo, ShareholderFields.CHILDREN, []).map(
      (children: IShareHolder, index: number) => {
        const traceIndex = `${fatherIndex}-${index}`;
        const info = buildDataByPersonType(children, traceIndex);

        return (
          <>
            <Accordion {...info} key={`ch-${fatherIndex}`} />
            {getShareholderChildren(children, traceIndex)}
          </>
        );
      }
    );

  return (
    <Box sx={styles.container}>
      <SectionTitle
        buttonText={SHAREHOLDER_BUTTON_TEXT}
        componentId={ComponentIdsEnum.M_CLIENTS_ADD_SHAREHOLDER_BUTTON}
        handleOnClick={() => handleOpenModal(toString(shareHolders.length))}
        isDisabled={isPossibleAddShareholder || (isEdit! && isCentralize!)}
        title={SHAREHOLDER_TITLE}
      />
      <Box>
        <Typography variant="caption" color="text.lightGrey">
          {SHAREHOLDER_DESCRIPTION}
        </Typography>
        {!isEmpty(shareHolders) && (
          <Grid item xs={12} marginTop={2}>
            <ModalDialog
              acceptButtonText={ACCEPT_BUTTON_DELETE_TEXT}
              cancelButtonText={CANCEL_BUTTON_TEXT}
              descriptionText={DESCRIPTION_DELETE_TEXT}
              onClickAcceptButton={handleAcceptModalDialog}
              onClickCancelButton={handleCloseModalDialog}
              onClose={handleCloseModalDialog}
              openModalDialog={openModalDeleteShareholder}
              titleText={TITLE_DELETE_TEXT}
            />
            <Grid item xs={12} marginBottom={1} sx={AccordionStyles.gridFlex}>
              <Box>
                <Typography sx={AccordionStyles.typographyLabelMain}>
                  {REGISTERED_SHAREHOLDERS_TEXT}
                </Typography>
              </Box>
              <Box sx={AccordionStyles.normalBox}>
                <Badge
                  badgeContent={getTotalShareholderNumber(shareHolders)}
                  sx={AccordionStyles.nestedBadgeComponentLvl1}
                />
              </Box>
            </Grid>
            <Grid item xs={12} marginBottom={1} sx={AccordionStyles.gridFlex}>
              <Box>
                <Typography sx={AccordionStyles.typographyLabelMain}>
                  {PARTICIPATION_SHAREHOLDERS_TEXT}
                </Typography>
              </Box>
              <Box>
                <Box sx={AccordionStyles.normalBox}>
                  <Badge
                    color={"secondary"}
                    badgeContent={String(
                      getTotalParticipationPercentage(shareHolders)
                    ).concat("%")}
                  />
                </Box>
              </Box>
            </Grid>
            {shareHolders.map((shareholder, index) => {
              const indexString = String(index);
              const info = buildDataByPersonType(shareholder, indexString);

              return (
                <Grid key={`sh-${index}`}>
                  <Accordion {...info} />
                  {getShareholderChildren(shareholder, indexString)}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <ModalForm modalSize={"sm"} openModal={openModalSh}>
        <ShareholderForm position={shareholderPosition} />
      </ModalForm>
    </Box>
  );
};

export default ShareholderSection;
