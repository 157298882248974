import {
  DISPLAYED_LANGUAGES,
  LANGUAGES,
} from "infrastructure/constants/Languages";
import { ILanguage } from "infrastructure/interfaces/ILanguage";
import React, { useEffect } from "react";
import { LOCALSTORAGE } from "../../../infrastructure/constants/LocalStorage_Enum";

declare var Localize: any;

export interface IUseLanguageSelector {
  anchorEl: HTMLElement | null;
  displayedLanguages: ILanguage[];
  currentLanguage: ILanguage;
  handlers: {
    handleChange: (selectedLanguage: ILanguage) => void;
    handleClose: () => void;
    showLanguages: (event: React.MouseEvent<HTMLElement>) => void;
  };
}

const extractLanguage = (): string | null => {
  return localStorage.getItem(LOCALSTORAGE.LANGUAGE_CODE);
};

export const useLanguageSelector = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentLanguage, setCurrentLanguage] = React.useState<ILanguage>(
    LANGUAGES[0]
  );

  const [displayedLanguages, setDisplayedLanguages] = React.useState<
    ILanguage[]
  >(DISPLAYED_LANGUAGES);

  const initializeLocalize = () => {
    Localize.initialize({
      key: "6UIFbVhtI0lxy",
      rememberLanguage: true,
      targetLanguage: "es-LA",
    });
  };

  useEffect(() => {
    const language = extractLanguage();
    if (language) {
      const currentLanguage =
        LANGUAGES.find((l) => l.code === language) ?? LANGUAGES[0];

      setCurrentLanguage(currentLanguage);
      if (currentLanguage.code === "en") {
        initializeLocalize();
      }
    }
  }, []);

  const showLanguages = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setDisplayedLanguages(
      LANGUAGES.filter((language) => language.code !== currentLanguage.code)
    );
  };

  const handleChange = (selectedLanguage: ILanguage) => {
    setCurrentLanguage(selectedLanguage);
    initializeLocalize();
    Localize.setLanguage(selectedLanguage.code);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    currentLanguage,
    displayedLanguages,
    handlers: {
      handleChange,
      handleClose,
      showLanguages,
    },
  };
};
