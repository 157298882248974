import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { WebhookListRequest } from "../../../../types/webhooks_list_request";
import { WebhookListResponse } from "../../../../types/webhook_list_response";
import { IHierarchyNodeRequest } from "../../../shared/interfaces/IHierarchyNodeRequest";

const getWebhookList = createAsyncThunk<
  WebhookListResponse,
  WebhookListRequest
>("developers/getWebhookList", async (payload) => {
  const response = await axios.post<WebhookListResponse>(
    API_ROUTES.LIST_WEBHOOK,
    payload
  );

  return response.data;
});

const editHierarchyWebhookConfig = createAsyncThunk<
  boolean,
  IHierarchyNodeRequest
>(
  "developers/editHierarchyWebhookConfig",
  async (hierarchyPayload): Promise<boolean> => {
    return await axios.put(API_ROUTES.HIERARCHY_NODE_INFO, hierarchyPayload);
  }
);

export { editHierarchyWebhookConfig, getWebhookList };
