import { FilterEnum } from "../infrastructure/enums/FilterEnum";
import { LabelsWallet } from "../infrastructure/enums/LabelWalletEnum";
import { CountryNameEnum } from "../infrastructure/enums/CountryEnum";

export const formatNumber = (
  amount: number,
  currency: string,
  country: string
): string => {
  const formattedAmount: string =
    country === CountryNameEnum.chile
      ? Intl.NumberFormat("es", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: true,
        }).format(amount)
      : Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount);

  return `${Number(formattedAmount) !== 0 ? formattedAmount : "0"} ${currency}`;
};

export const renderSymbol = (transactionType: string): string => {
  switch (transactionType) {
    case FilterEnum.DEBIT:
      return LabelsWallet.DEBIT_SYMBOL;
    case FilterEnum.CREDIT:
      return LabelsWallet.CREDIT_SYMBOL;
    default:
      return LabelsWallet.DEBIT_SYMBOL;
  }
};

export const countriesPayOut: Record<string, boolean> = {
  [CountryNameEnum.mexico]: true,
  [CountryNameEnum.chile]: true,
  [CountryNameEnum.peru]: false,
  [CountryNameEnum.colombia]: false,
  [CountryNameEnum.ecuador]: false,
};
