import React from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { get } from "lodash";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";

export interface AutocompleteTextFieldComponentProps {
  property: string;
  label: string;
  value: string;
  options: object[];
  pathValue: string;
  pathCountry: string;
  pathName: string;
  isLoading: boolean;
  handleEnterSearchText: (key: string) => void;
  handleSetSearchText: (searchText: string) => void;
  setValue: (value: object) => void;
  disabled: boolean;
  error: boolean;
  errorMessage: string;
  className?: string;
  showCountry: boolean;
}

export const AutocompleteTextFieldComponent: React.FC<AutocompleteTextFieldComponentProps> =
  (props: AutocompleteTextFieldComponentProps) => {
    const setName = (option: object, showCountry: boolean): string => {
      if (showCountry)
        return `${get(option, props.pathName, "")} (${get(
          option,
          props.pathCountry,
          ""
        )})`;

      return get(option, props.pathName, "");
    };

    return (
      <React.Fragment>
        <Autocomplete
          id={props.property}
          onKeyDown={(event) => props.handleEnterSearchText(event.key)}
          disabled={props.disabled}
          inputValue={
            props.disabled
              ? get(props.options, `0.${props.pathName}`, "")
              : props.value
          }
          options={props.options}
          onInputChange={(_event: any, newInputValue) =>
            props.handleSetSearchText(newInputValue)
          }
          onChange={(_event: any, value: any) =>
            props.showCountry
              ? props.setValue(get(value, "_source"))
              : props.setValue(get(value, props.pathName))
          }
          getOptionLabel={(option) => setName(option, props.showCountry)}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {props.isLoading ? (
                      <CircularProgress color="primary" size={18} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              className={props.className}
              error={props.error}
              disabled={props.isLoading || props.disabled}
              label={props.label}
              variant="outlined"
              helperText={!!props.error && props.errorMessage}
            />
          )}
        />
      </React.Fragment>
    );
  };
