import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDateFilter,
  IFiltersState,
  IGeneralFilter,
  IHeaderFilter,
  IPaginationFilter,
} from "../../interfaces/FiltersState.interfaces";
import esLocale from "date-fns/locale/es";
import {
  today,
  yesterday,
} from "../../../components/common/DateTimeRangePicker/utils/functions";
import {
  COUNTRIES,
  PAYMENT_METHODS,
  PROCESSORS,
} from "../../../shared/infrastructure/constants/HeaderFilters";
import { GetConciliationTransactionsRequest } from "../../../../types/get_conciliation_transactions_request";

export const initialState: IFiltersState = {
  bodyFilter: {} as GetConciliationTransactionsRequest,
  date: {
    from: `${yesterday(esLocale)} 00:00`,
    timeZone: "",
    to: `${today(esLocale)} 23:59`,
  },
  general: {},
  headerFilter: {
    country: COUNTRIES[0].value,
    paymentMethod: PAYMENT_METHODS[0].value,
    processorName: PROCESSORS[0],
  },
  pagination: {
    limit: 10,
    page: 0,
    resetPagination: true,
    total: 0,
  },
  search: {
    status: 12,
    text: "",
  },
};

export const filtersSlice = createSlice({
  initialState,
  name: "filters",
  reducers: {
    setBodyFilter: (
      state,
      { payload }: PayloadAction<GetConciliationTransactionsRequest>
    ) => {
      state.bodyFilter = payload;
    },
    setDateFilter: (state, { payload }: PayloadAction<IDateFilter>) => {
      state.pagination.page = 0;
      state.pagination.resetPagination = true;
      state.date = payload;
    },
    setGeneralFilter: (state, { payload }: PayloadAction<IGeneralFilter>) => {
      state.pagination.resetPagination = true;
      state.general = payload;
    },
    setHeaderFilter: (state, { payload }: PayloadAction<IHeaderFilter>) => {
      state.pagination.page = 0;
      state.pagination.resetPagination = true;
      state.headerFilter = payload;
    },
    setPaginationFilter: (
      state,
      { payload }: PayloadAction<IPaginationFilter>
    ) => {
      state.pagination = payload;
    },
    setSearchClick: (state, { payload }: PayloadAction<number>) => {
      state.pagination.page = 0;
      state.pagination.resetPagination = true;
      state.search.status = payload;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.pagination.page = 0;
      state.pagination.resetPagination = true;
      state.search.text = payload;
    },
    setTransactionsLimit: (state, { payload }: PayloadAction<number>) => {
      state.pagination.page = 0;
      state.pagination.resetPagination = true;
      state.pagination.limit = payload;
    },
    setTransactionsPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.resetPagination = false;
      state.pagination.page = payload;
    },
  },
});

export default filtersSlice.reducer;
