import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const helperTextStyles = createNamedStyles({
  helpCard: {
    display: "flex",
    gap: "15px",
    marginTop: " 32px",
    padding: "24px",
    backgroundColor: "#FBFCFE",
  },
  iconWrapper: {
    display: "grid",
    placeContent: "center",
  },
  description: {
    color: "#6D7781",
    display: "grid",
    placeContent: "center",
  },
});
