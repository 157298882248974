import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { Grid, InputBase, MenuItem, Select } from "@material-ui/core";
import { CurrencyEnum } from "../../../shared/infrastructure/CurrencyEnum";
import { PaymentMethodEnum } from "../../../shared/infrastructure/PaymentMethodEnum";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { ProcessorEnum } from "../../../shared/infrastructure/ProcessorEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridPadding: {
      paddingRight: 24,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    container: {
      marginTop: "25px",
      marginBottom: "25px",
      width: "100%",
    },
  })
);

type IOptionMenuVerticalProps = number | "bottom" | "top" | "center";
type IOptionMenuHorizontalProps = "left" | "right";

interface IMenuProps {
  anchorOrigin: {
    vertical: IOptionMenuVerticalProps;
    horizontal: IOptionMenuHorizontalProps;
  };
  transformOrigin: {
    vertical: IOptionMenuVerticalProps;
    horizontal: IOptionMenuHorizontalProps;
  };
  getContentAnchorEl: ((element: Element) => Element) | null | undefined;
}

const PropsMenu: IMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export const BootstrapInput = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      "&:focus": {
        borderRadius: 4,
      },
    },
  });
})(InputBase);

export const MainFilters: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.container}>
      <Grid item xs={6} md={3} className={classes.gridPadding}>
        <Select
          MenuProps={PropsMenu}
          id="select-country"
          name={"country"}
          displayEmpty
          disabled={false}
          value={CountryEnum.mexico}
          inputProps={{ "aria-label": "Without label" }}
          input={<BootstrapInput />}
          classes={{ icon: classes.icon }}
          fullWidth
        >
          <MenuItem value={CountryEnum.mexico}>México</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6} md={3} className={classes.gridPadding}>
        <Select
          MenuProps={PropsMenu}
          id="select-paymentMethod"
          name={"paymentMethod"}
          disabled={false}
          value={PaymentMethodEnum.card}
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
          input={<BootstrapInput />}
          classes={{ icon: classes.icon }}
        >
          <MenuItem value={PaymentMethodEnum.card}>Tarjeta</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6} md={3} className={classes.gridPadding}>
        <Select
          MenuProps={PropsMenu}
          id="select-processor"
          fullWidth
          name={"processor"}
          value={ProcessorEnum.acquirer}
          inputProps={{ "aria-label": "Without label" }}
          input={<BootstrapInput />}
          classes={{ icon: classes.icon }}
        >
          <MenuItem value={ProcessorEnum.acquirer}>Kushki adquirente</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6} md={3}>
        <Select
          MenuProps={PropsMenu}
          id="select-currency"
          fullWidth
          name={"currency"}
          value={CurrencyEnum.MXN}
          inputProps={{ "aria-label": "Without label" }}
          input={<BootstrapInput />}
          classes={{ icon: classes.icon }}
        >
          <MenuItem value={CurrencyEnum.MXN}>MXN</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};
