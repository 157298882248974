import { environment } from "@environments/environment";

export const API_ROUTES = {
  CATALOGS: `${environment.kushkiUrl}/catalog/v1/list-catalog`,
  DOWNLOAD_ALARMS: `${environment.KushkiUrlWS}/compliance`,
  DOWNLOAD_EXECUTIVES: `${environment.kushkiUrl}/compliance/v2/executives/download`,
  generateRuleAlarm: `${environment.KushkiUrlWS}/compliance`,
  GET_FILE_COMMENTS: `${environment.kushkiUrl}/compliance/v2/downloadFileAlarms`,
  GET_FILES_SIGNED: `${environment.kushkiUrl}/compliance/v1/files/signed`,
  GET_MERCHANTS: `${environment.kushkiUrl}/billing-core-node/v1/merchants`,
  GET_RULE_ALARM: `${environment.kushkiUrl}/compliance/v1/rule-alarm/`,
  GET_RULE_ALARMS: `${environment.kushkiUrl}/compliance/v1/rules-alarm`,
  SEARCH_ALARM_BY_FILTER: `${environment.kushkiUrl}/compliance/v2/alarm/searchByFilter`,
  SEARCH_MERCHANT_NODE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search`,
  SET_ALARM_ACTION_V2: `${environment.kushkiUrl}/compliance/v2/alarm/action`,
  UPDATE_STATUS_RULE: `${environment.kushkiUrl}/compliance/v1/rule-alarm/status`,
  UPLOAD_COMMERCIAL_EXECUTIVE: `${environment.kushkiUrl}/compliance/v2/executives/upload`,
  VALIDATE_EXECUTIVES: `${environment.kushkiUrl}/compliance/v1/executives/validate`,
};
