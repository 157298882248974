import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const TableFooterStyles = createNamedStyles({
  pagination: {
    "&.MuiPagination-root": {
      li: {
        borderRadius: "2px",
        fontFamily: "IBM Plex Sans",
      },
      "li button": {
        backgroundColor: "",
        borderRadius: "4px",
        boxShadow: "#5b5b5b3d 0px 8px 20px",
        height: "32px",
        marginLeft: "8px",
        width: "32px",
      },
      marginRight: "12px",
      paddingTop: "12px",
    },
  },
});

export { TableFooterStyles };
