/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
webSocketMassiveUrl: string;
envName: string;
devTools: boolean;
rollbarToken: string;
} = {
kushkiUrl: "https://api.kushkipagos.com",
webSocketMassiveUrl: "api-ws.kushkipagos.com",
envName: "primary",
devTools: false,
rollbarToken: '880c71a656e042888352ef4cb474740c',
};
