import React from "react";
import { Badge, Box, Typography } from "@mui/material";
import { boardMembersStyles as styles } from "./BoardMembers.styles";
import { IBoardMembersProps } from "./BoardMembers.interfaces";
import BoardMemberAccordion from "./BoardMembersAccordion/BoardMemberAccordion";
import { isEmpty, slice } from "lodash";
import { Boardmembers } from "../../../types/get_compliance_people_response";
import ModalBoardMembers from "./ModalBoardMembers/ModalBoardMembers";
import { useBoardMembers } from "./state/useBoardMembers";
import EmptyBoardMembers from "./EmptyBoardMembers/EmptyBoardMembers";

const BoardMembers: React.FC<IBoardMembersProps> = ({
  boardMembers,
}: IBoardMembersProps) => {
  const {
    openModal,
    handlers: { handleOpenModal, handleCloseModal },
  } = useBoardMembers();

  return (
    <Box sx={styles.container}>
      <Typography color="text.dark" variant="caption" sx={styles.text}>
        Board members registrados:
        <Badge
          sx={styles.registeredBoardMembers}
          color="primary"
          badgeContent={boardMembers.length || "0"}
        />
      </Typography>
      {isEmpty(boardMembers) ? (
        <EmptyBoardMembers />
      ) : (
        <Box>
          <Box>
            {slice(boardMembers, 0, 3).map((boardMember: Boardmembers) => (
              <BoardMemberAccordion
                key={boardMember.documentNumber}
                boardMember={boardMember}
                showMenuActions={false}
              />
            ))}
          </Box>
          {boardMembers.length > 3 && (
            <Box sx={styles.showAllWapper}>
              <Typography sx={styles.showAll} onClick={handleOpenModal}>
                Ver todos
              </Typography>
            </Box>
          )}
          <ModalBoardMembers
            boardMembers={boardMembers}
            handleCloseModal={handleCloseModal}
            openModal={openModal}
          />
        </Box>
      )}
    </Box>
  );
};

export default BoardMembers;
