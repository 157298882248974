import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

interface BreadcrumItem {
  label: string;
  url: string;
}

export interface BreadcrumProps {
  items: BreadcrumItem[];
  lastItem: string;
}

const useStyles = makeStyles({
  text: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "170%",
    color: "#677784",
  },
});

export default function Breadcrumb(props: BreadcrumProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Breadcrumbs separator={">"} aria-label="breadcrumb">
        {props.items.map((item: BreadcrumItem) => (
          <Link
            key={item.label}
            className={classes.text}
            component={RouterLink}
            to={item.url}
          >
            {item.label}
          </Link>
        ))}
        <Typography className={classes.text}>{props.lastItem}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
}
