import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DashboardHistoricTableRow from "./DashboardHistoricTableRow";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../../../../types/remote/response_search_blling_by_filters";
import { Autorenew, UnfoldMore } from "@material-ui/icons";
import { Download as IconDownload, Trash2 as IconDelete } from "react-feather";
import { defaultTo, get, includes, isEmpty, split } from "lodash";
import { DashboardListPagination } from "../../Pagination/DashboardListPagination";
import {
  HistoricFilters,
  HistoricFiltersProps,
} from "../../../Filters/HistoricFilters/HistoricFilters";
import { EmptyList } from "../../../EmptyList/EmptyList";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import ModalHeaderInfo from "../../../ModalHeaderInfo/ModalHeaderInfo";
import { ModalBodyTabs } from "../../../ModalBodyInfo/ModalBodyTabs";
import {
  CountryEnum,
  LEDGER_FLOW_COUNTRIES,
} from "../../../../shared/infrastructure/CountryEnum";
import { KindEnum } from "../../../../shared/infrastructure/KindEnum";
import { ModalFooter } from "@kushki/frontend-molecules/modal-footer";
import moment from "moment";
import { openFileUri } from "../../../../shared/infrastructure/constants/MerchantInformationConstants";
import { DownloadLiquidationDetail } from "../../../../../types/download_liquidation_detail";
import { DownloadInvoiceDetail } from "../../../../../types/download_invoice_detail";
import { ModalStyles } from "../../../Common/ModalCard/ModalStyles";
import {
  ISort,
  ITextArea,
} from "../../../../containers/BillingDashboard/state/useBillingDashboardState";
import { InvoiceAnnulModal } from "../../../InvoiceAnnulModal/InvoiceAnnulModal";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  annulDeadLineValidate,
  BillingActions,
  setBillingHistoricDataRow,
  setOpenCreditDebitNoteModal,
  setOpenModalAnnul,
  transactionIdFilterHistory,
} from "../../../../store/actionCreators";
import { useHistory } from "react-router-dom";
import {
  FinalStatusEnum,
  InitialStatusEnum,
  StatusEnum,
  StatusFlowEnum,
} from "../../../../shared/infrastructure/StatusEnum";
import { ButtonGenerateNcNd } from "../../../ButtonGenerateNcNd/ButtonGenerateNcNd";
import { CreditDebitNoteModal } from "../../../CreditDebitNoteModal/CreditDebitNoteModal";
import { CreditDebitNoteEnum } from "../../../../shared/infrastructure/CreditDebitNoteEnum";
import { IModalFooterProps } from "../../../../shared/infrastructure/IModalFooterInterface";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IBillingDashboardState } from "../../../../store/reducer";
import { TableContainerHistoricDownload } from "../../../ToDownloadTable/TableContainerHistoricDownload/TableContainerHistoricDownload";
import { FooterTable } from "../../../ToDownloadTable/FooterTable/FooterTable";
import { ConfirmModal } from "../../../ConfirmModal/ConfirmModal";
import { IColumnsTable } from "../../../../shared/infrastructure/table/IColumnsTable";
import { DocumentTypeEnum } from "../../../../shared/infrastructure/DocumentTypeEnum";
import { DownloadsEnums } from "../../../../shared/historicTableEnum";
import { transactionTypeEnum } from "../../../../shared/infrastructure/PaymentMethodEnum";
import { MINIMAL_COMMISSION_ARRAY } from "../../../../shared/infrastructure/constants/MinimalCommissions";
import { DownloadLedgerDetail } from "../../../../../types/download_ledger_detail";
import { WebsocketActionEnum } from "../../../../shared/infrastructure/WebsocketActionEnum";
import { BackRetentionModalEnum } from "../../../../shared/infrastructure/enum/BackRetentionModalEnum";
import { RetentionContentEnum } from "../../../../shared/infrastructure/constants/RetentionConstants";
import { KindRetentionEnum } from "../../../../shared/infrastructure/KindRetentionEnum";
import { HistoricTooltipValuesEnum } from "../../../../shared/infrastructure/enum/HistoricTooltipValuesEnum";
import { RolesHistoricEnum } from "../../../../shared/infrastructure/enum/Roles/RolesEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { DownloadChargeDetail } from "../../../../../types/download_charge_detail";
import { Metadata } from "../../../../../types/metadata";

export interface DashboardHistoricTableProps {
  statusFlow: StatusFlowEnum;
  historicBillingData: ResponseSearchBillingByFilters;
  page: number;
  pageSize: number;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  hideNext: boolean;
  hidePrevious: boolean;
  historicFilterProps: HistoricFiltersProps;
  country: string;
  reprocess: { reprocessNumber: number; reprocessTransactions: () => void };
  modalOver: {
    openModal: boolean;
    openModalRetention: boolean;
    handlerOpenModalRetention: () => void;
    handlerCloseModalRetention: () => void;
    handleInputReasonDeleteRetention: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handlerCloseModal: () => void;
    handlerDeleteRetention: () => void;
    handlerSelectHistoricTrx: (row: InvoiceRecord | undefined) => void;
    handleOpenDialogDetail?: (data: InvoiceRecord) => void;
    handleCloseDialogDetail?: () => void;
    pdfViewer?: {
      fileName: string;
      openModal: boolean;
      base64File: string;
      handleCloseModal: () => void;
      handleOpenModal: () => void;
      handlePdfViewer: (
        transactionId: string,
        kindRentenion: KindRetentionEnum
      ) => void;
    };
    downloadFileRetention: (
      transactionId: string,
      extension: "pdf" | "xml" | "csv"
    ) => void;
    historicTrx: InvoiceRecord;
    downloadFilePDF: (
      invoiceId: string,
      merchantName: string,
      extension: string
    ) => void;
    isLoadingDownloadEfact: boolean;
    isHistoricMinAmountTrx: boolean;
    downloadPreprocessFileWS: (params: DownloadDocumentParams) => void;
    isLoadingFileDetail: boolean;
    isLoadingDownloadPreProcess: boolean;
    isLoadingDeleteRetention?: boolean;
    availableDeleteRetentionButton: boolean;
    downloadFilePDFS3: (invoiceId: string) => void;
    downloadPdfProps: string;
    reasonDeleteRetention: string | undefined;
    countCharacterReasonDeleteRetention: number;
    downloadPdfLoading: boolean;
    isLoadingFileRetention: boolean;
  };
  modalBackRetention: {
    openModalBackRetention: boolean;
    handlerOpenModalBackRetention: (row: InvoiceRecord | undefined) => void;
    handlerCloseModalBackRetention: () => void;
    handlerAcceptTransaction: () => void;
    isLoading: boolean;
  };
  textArea?: ITextArea;
  tableColumns: {
    renderColumns: IColumnsTable[];
  };
  sortProps: ISort;
  downloadFileState?: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
}

export interface DashboardHistoricTableFunctionsProps {
  setBillingHistoricDataRow(billingDataRow: InvoiceRecord): void;
  transactionIdFilterHistory: () => void;
}

export interface DashboardHistoricTableStateProps {
  historicBillingData: ResponseSearchBillingByFilters;
  transactionTableCollectionsHistory: InvoiceRecord | undefined;
}

export type DashboardTableComponentProps = DashboardHistoricTableProps &
  DashboardHistoricTableFunctionsProps &
  DashboardHistoricTableStateProps;

export type DownloadDocumentParams =
  | DownloadLiquidationDetail
  | DownloadInvoiceDetail
  | DownloadLedgerDetail
  | DownloadChargeDetail;

const verifyTwoDays = (issueDate: string): boolean => {
  let isAnulate: boolean = false;
  const now = moment().utc();

  if (!isEmpty(issueDate)) {
    const issueDateMoment = moment(issueDate);
    const diff = now.diff(issueDateMoment, "days");

    if (diff <= 2) {
      return !isAnulate;
    }
  }
  return isAnulate;
};

const getTransactionType = (transactionType: string): string => {
  const transactionTypeSplit: string[] = split(transactionType, "_");
  if (MINIMAL_COMMISSION_ARRAY.includes(transactionType))
    return DownloadsEnums.TRANSACTION_TYPE_MINIMAL_COMMISSION;
  if (transactionTypeSplit[1] === transactionTypeEnum.payout)
    return DownloadsEnums.TRANSACTION_TYPE_PAYOUT;
  return DownloadsEnums.TRANSACTION_TYPE_ALL;
};

const validateDownload = (trx: InvoiceRecord, isS3: boolean) => {
  if (
    get(trx, "kind") === KindEnum.INVOICE &&
    get(trx, "status") !== StatusEnum.COMPLETED
  )
    return true;

  if (isS3) return !get(trx, "invoice_kushki_id");

  return !get(trx, "pdf");
};

export const DashboardHistoricTable: React.FC<DashboardTableComponentProps> = (
  props: DashboardTableComponentProps
) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [modalKind, setModalKind] = React.useState(CreditDebitNoteEnum.CREDIT);
  const history = useHistory();
  useEffect(() => {
    if (props.modalOver.downloadPdfProps !== "")
      openFileUri(props.modalOver.downloadPdfProps);
  }, [props.modalOver.downloadPdfProps]);

  const isCreditDebitNote: boolean = [
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
  ].includes(props.modalOver.historicTrx.kind);
  const isStatusAnnul: boolean = [StatusEnum.ANNUL].includes(
    props.modalOver.historicTrx.status
  );
  const isInvoiceCompleted: boolean =
    props.modalOver.historicTrx &&
    props.modalOver.historicTrx.status === StatusEnum.COMPLETED &&
    props.modalOver.historicTrx.initial_status === InitialStatusEnum.APPROVE;
  const isDispersion: boolean = [KindEnum.DISPERSION].includes(
    get(props.modalOver.historicTrx, "kind", "")
  );
  const isReceivable: boolean = [KindEnum.RECEIVABLE].includes(
    get(props.modalOver.historicTrx, "kind", "")
  );

  const isAnnulInvoice: boolean = [KindEnum.ANNUL_INVOICE].includes(
    get(props.modalOver.historicTrx, "kind", "")
  );
  const handleCreditDebitModal = (kind: CreditDebitNoteEnum) => {
    setModalKind(kind);
    dispatch(setOpenCreditDebitNoteModal(true));
  };
  const [fileHistoricState, setFileHistoricState] = React.useState(false);
  const urlDownloadPDFS3 = () => {
    if (
      includes(
        [
          CountryEnum.mexico,
          CountryEnum.ecuador,
          CountryEnum.chile,
          CountryEnum.peru,
        ],
        get(props, "country")
      ) &&
      get(props.modalOver.historicTrx, "invoice_kushki_id")
    ) {
      props.modalOver.downloadFilePDFS3(
        get(props.modalOver.historicTrx, "invoice_kushki_id")
      );
    }
  };
  const redirectNewInvoice = () => {
    setTimeout(() => history.push("/billing-dashboard/invoice"), 500);
  };

  const validateStatusRetentionIsApprove = (
    initialStatus: string,
    status: string
  ) => {
    return (
      get(props, "modalOver.historicTrx.initial_status", "") ===
        initialStatus &&
      get(props, "modalOver.historicTrx.status", "") === status
    );
  };

  const getPaymentMethod = (transactionType: string) => {
    if (MINIMAL_COMMISSION_ARRAY.includes(transactionType)) return "";

    transactionType = transactionType.toLowerCase();
    const parts =
      transactionType.indexOf("_") >= 0 ? transactionType.split("_") : [];

    return parts.length > 0 ? parts[parts.length - 1] : "";
  };

  const getProcessorType = (processorType: string) => {
    if (MINIMAL_COMMISSION_ARRAY.includes(processorType)) return "";

    processorType = processorType.toLowerCase();
    const parts =
      processorType.indexOf("_") >= 0 ? processorType.split("_") : [];

    return parts.length > 0 ? parts[parts.length - 2] : "aggregator";
  };

  const validateRenderedCanceledRetention = (
    initialStatus: string,
    status: string
  ) => {
    return (
      validateStatusRetentionIsApprove(initialStatus, status) &&
      get(props.modalOver.historicTrx, "allow_delete_retention", false) &&
      get(props, "statusFlow", "") === StatusFlowEnum.EXECUTOR
    );
  };
  const openHistoricPDF = (uri: string) => {
    setFileHistoricState(true);
    openFileUri(uri);
    setFileHistoricState(false);
  };

  const update = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InvoiceRecord[]
  ) => {
    if (!event.target.checked) {
      data.map((row: InvoiceRecord) => {
        props.setBillingHistoricDataRow({ ...row, statusAll: false });
      });
    }
    if (event.target.checked) {
      data.map((row: InvoiceRecord) => {
        props.setBillingHistoricDataRow({ ...row, statusAll: true });
      });
    }
    props.transactionIdFilterHistory();
    setCheckValue(!checkValue);
  };
  const [checkValue, setCheckValue] = useState<boolean>(false);

  const { reprocess } = props;

  const [valueTab, setValueTab] = useState<number>(0);

  const handleChangeTab = (newValue: number) => {
    setValueTab(newValue);
  };

  const validateTypeDocuments = (document: string, country: string) => {
    return includes(DocumentTypeEnum[country], document);
  };

  const classes = ModalStyles();

  const verifyInvoiceTransaction = (historicTrx: InvoiceRecord) => {
    const trxInvoiceWsData = {
      startDate: new Date(get(historicTrx, "start_date", "")).getTime(),
      endDate: new Date(get(historicTrx, "end_date")).getTime(),
      merchantId: get(historicTrx, "merchant_id"),
      discount: get(historicTrx, "kushki_commission_discount_percentage"),
      country: get(historicTrx, "country"),
      paymentMethod: getPaymentMethod(
        defaultTo(get(historicTrx, "transaction_type", "card"), "")
      ),
      transactionType: getTransactionType(
        defaultTo(get(historicTrx, "transaction_type", ""), "")
      ),
      processorType: getProcessorType(
        defaultTo(
          get(props.modalOver.historicTrx, "processor_type", "aggregator"),
          ""
        )
      ),
    };
    return get(historicTrx, "has_additional_services", false) &&
      get(historicTrx, "additional_services", []).includes("3ds")
      ? { ...trxInvoiceWsData, additionalService: "3ds" }
      : trxInvoiceWsData;
  };

  const getDynamoTrx = (historicTrx: InvoiceRecord) => {
    const getFromTransactionObj = (path: string, defaultValue?: any) =>
      get(historicTrx, path, defaultValue);
    const createdVal = new Date(getFromTransactionObj("created")).getTime();

    return {
      amountCharge: getFromTransactionObj("amount_charge", 0),
      amountRetention: getFromTransactionObj("amount_retention", 0),
      amountSumChargePending: getFromTransactionObj(
        "amount_sum_charge_pending",
        0
      ),
      created: createdVal,
      createdCharge: getFromTransactionObj("created_charge", createdVal),
      createdRetention: getFromTransactionObj("created_retention", createdVal),
      currency: getFromTransactionObj("currency_code", ""),
      hasCharge: getFromTransactionObj(
        "has_charge",
        getFromTransactionObj("kind") === KindEnum.CHARGE
      ),
      hasFraudRetention: getFromTransactionObj("has_fraud_retention", false),
      hasFraudRetentionPending: getFromTransactionObj(
        "has_fraud_retention_pending",
        false
      ),
      hasMinAmountCharge: getFromTransactionObj("has_min_amount_charge", false),
      merchantId: getFromTransactionObj("merchant_id"),
      merchantName: getFromTransactionObj("merchant_name"),
      minAmount: getFromTransactionObj("min_amount", 0),
      transactionId: getFromTransactionObj("transaction_id"),
      transactionType: getFromTransactionObj("transaction_type"),
    };
  };

  const buildLedgerDetailParams = (
    metaData: Metadata,
    historicTrx: InvoiceRecord
  ) => {
    return {
      action: WebsocketActionEnum.listLedgerDetail,
      data: {
        ...metaData,
        merchantId: defaultTo(get(historicTrx, "merchant_id"), ""),
        transactionId: defaultTo(get(historicTrx, "transaction_id"), ""),
      },
    };
  };

  const buildLiquidationDetailParams = (
    metaData: Metadata,
    historicTrx: InvoiceRecord
  ) => {
    return {
      action: WebsocketActionEnum.liquidationDetail,
      data: {
        ...metaData,
        dynamoTransaction: getDynamoTrx(historicTrx),
      },
    };
  };

  const downloadDocumentAction = (props: DashboardTableComponentProps) => {
    const historicTrx: InvoiceRecord = props.modalOver.historicTrx;
    const country: string = get(historicTrx, "country", "");
    const metaData: Metadata = {
      country,
      startDate: new Date(get(historicTrx, "start_date", "")).getTime(),
      endDate: new Date(get(historicTrx, "end_date", "")).getTime(),
    };
    const params: DownloadDocumentParams = includes(
      LEDGER_FLOW_COUNTRIES,
      country
    )
      ? buildLedgerDetailParams(metaData, historicTrx)
      : buildLiquidationDetailParams(metaData, historicTrx);

    props.modalOver.downloadPreprocessFileWS(params);
  };
  const footerPropsDownloadDispersion: Partial<IModalFooterProps> = {
    dropDwLabelButton: "Descargar",
    dropDwButtonVariant: "contained",
    dropDwButtonColor: "primary",
    classNamedrowButtonPrimary: "splitBtn",
    classNamedrowButton: "splitBtn splitArrow",
    separate: true,
    dropDwStyleButton: { minWidth: "8em" },
    dropDwLoading:
      fileHistoricState ||
      props.modalOver.isLoadingFileDetail ||
      props.modalOver.downloadPdfLoading,
    dropDwMenuItems: [
      {
        label: "Descargar detalle",
        disable: false,
        menuItemActionButton: () => downloadDocumentAction(props),
        hidden: false,
      },
    ],
    dropDwActionIcon: <IconDownload xlinkTitle={"Descargar"} size={20} />,
  };
  const footerPropsDownloadInvoice: Partial<IModalFooterProps> = {
    dropDwLabelButton: "Descargar",
    dropDwButtonVariant: "contained",
    dropDwButtonColor: "primary",
    classNamedrowButtonPrimary: "splitBtn",
    classNamedrowButton: "splitBtn splitArrow",
    separate: true,
    dropDwStyleButton: { minWidth: "8em" },
    dropDwLoading:
      fileHistoricState ||
      props.modalOver.isLoadingFileDetail ||
      props.modalOver.downloadPdfLoading,
    dropDwMenuItems: [
      {
        label: "Descargar detalle",
        disable: false,
        menuItemActionButton: () => {
          props.modalOver.downloadPreprocessFileWS({
            action: "trxInvoice",
            data: verifyInvoiceTransaction(props.modalOver.historicTrx),
          });
        },
        hidden: false,
      },
      {
        label: "Descargar en PDF",
        disable: validateDownload(props.modalOver.historicTrx, false),
        menuItemActionButton: () =>
          openHistoricPDF(get(props.modalOver.historicTrx, "pdf")),
        hidden: !(
          [CountryEnum.colombia].includes(props.country as CountryEnum) &&
          validateTypeDocuments(
            get(props.modalOver.historicTrx, "kind"),
            props.country
          )
        ),
      },
      {
        label: "Descargar en PDF",
        disable: validateDownload(props.modalOver.historicTrx, true),
        menuItemActionButton: () => urlDownloadPDFS3(),
        hidden: !(
          [
            CountryEnum.ecuador,
            CountryEnum.peru,
            CountryEnum.chile,
            CountryEnum.mexico,
          ].includes(props.country as CountryEnum) &&
          validateTypeDocuments(
            get(props.modalOver.historicTrx, "kind"),
            props.country
          )
        ),
      },
    ],
    dropDwActionIcon: <IconDownload xlinkTitle={"Descargar"} size={20} />,
  };

  const footerPropsGenerateCreditDebitNote: Partial<IModalFooterProps> = {
    customPrimaryButtons: (
      <ButtonGenerateNcNd
        options={[
          {
            text: "Generar nota de crédito",
            value: "Generar nota de crédito",
          },
          {
            text: "Generar nota de débito",
            value: "Generar nota de débito",
          },
        ]}
        isLoading={false}
        historicTrx={props.modalOver.historicTrx}
        modalKind={handleCreditDebitModal}
      />
    ),
  };

  const isOmittedCondition: boolean = [FinalStatusEnum.OMITTED].includes(
    get(props, "modalOver.historicTrx.initial_status", "")
  );

  const isRetentionColombia: boolean =
    get(props, "country") === CountryEnum.colombia &&
    get(props, "modalOver.historicTrx.kind", "") === KindEnum.RETENTION_CO;

  const footerPropsGenerateOmittedRet: Partial<IModalFooterProps> = {
    customPrimaryButtons: (
      <Grid item container xs={12} direction="row-reverse">
        <Grid item xs={5}>
          <Tooltip
            classes={{ tooltip: classes.tooltipButton }}
            arrow
            title={HistoricTooltipValuesEnum.backRegisterTextTex}
            placement="top-start"
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                props.modalBackRetention.handlerOpenModalBackRetention(
                  props.modalOver.historicTrx
                );
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {RetentionContentEnum.RETURN_RET}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    ),
  };

  const footerPropsGenerateNewInvoice: Partial<IModalFooterProps> = {
    separate: true,
    secondaryLabelButton: "Generar nueva factura",
    secondaryFontColor: "default",
    dropDwButtonVariant: "outlined",
    secondaryActionIcon: <Autorenew />,
    secondaryActionButton: () => redirectNewInvoice(),
  };

  const footerPropsAnullInvoice: Partial<IModalFooterProps> = {
    separate: true,
    secondaryLabelButton: "Anular documento",
    secondaryFontColor: "error",
    dropDwButtonVariant: "outlined",
    secondaryActionIcon: <IconDelete xlinkTitle={"Anular"} size={20} />,
    secondaryActionButton: () => dispatch(setOpenModalAnnul(true)),
  };

  const fixedHistoricHeaders = [
    {
      id: "statusAll",
      label: "Tipo",
      view: true,
      filter: false,
    },
    ...props.tableColumns.renderColumns,
  ];
  const footerPropsAnnulInvoiceMex: Partial<IModalFooterProps> = {
    separate: true,
    secondaryLabelButton: "Anular documento",
    secondaryFontColor: "error",
    dropDwButtonVariant: "outlined",
    secondaryActionIcon: <IconDelete xlinkTitle={"Anular"} size={20} />,
    secondaryDisableButton: get(state, "annulDeadLineValidate.value", true),
    secondaryTooltipText: get(state, "annulDeadLineValidate.text", ""),
    secondaryActionButton: () => dispatch(setOpenModalAnnul(true)),
  };

  useEffect(() => {
    if (props.modalOver.openModal) {
      dispatch(annulDeadLineValidate());
    }
  }, [props.modalOver.historicTrx]);

  return (
    <React.Fragment>
      <HistoricFilters {...props.historicFilterProps} reprocess={reprocess} />
      <TableContainer component={Paper} elevation={0}>
        {get(props, "transactionTableCollectionsHistory", []).length > 0 && (
          <TableContainerHistoricDownload
            historicBillingData={props.historicBillingData}
            update={update}
            setBillingHistoricDataRow={props.setBillingHistoricDataRow}
            checkValue={checkValue}
            transactionTableCollectionsHistory={
              props.transactionTableCollectionsHistory
            }
            transactionIdFilterHistory={props.transactionIdFilterHistory}
            tableColumns={props.tableColumns}
            fixedHistoricHeaders={fixedHistoricHeaders}
            modalOver={props.modalOver}
            country={props.country}
            modalBackRetention={props.modalBackRetention}
          >
            <FooterTable
              count={
                get(props, "transactionTableCollectionsHistory", []).length
              }
              onClick={() => {
                props.historicFilterProps.downloadFileState.downloadFile(
                  "csv",
                  props.transactionTableCollectionsHistory
                );
              }}
              isLoading={
                props.historicFilterProps.downloadFileState.isDownloadingCheck
              }
            />
          </TableContainerHistoricDownload>
        )}
      </TableContainer>
      {get(props, "transactionTableCollectionsHistory", []).length > 0 && (
        <Divider style={{ marginTop: "40px" }} />
      )}
      <TableContainer component={Paper} elevation={0}>
        {props.historicBillingData.records.length > 0 ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow key="header">
                <TableCell>
                  <Checkbox
                    id={"checkStatusAllHistoric"}
                    color="primary"
                    onChange={(e) =>
                      update(e, props.historicBillingData.records)
                    }
                    checked={checkValue}
                  />
                </TableCell>
                {props.tableColumns.renderColumns.map(
                  (column: IColumnsTable) => (
                    <TableCell className={classes.modalHeader} key={column.id}>
                      <TableSortLabel
                        IconComponent={UnfoldMore}
                        disabled={column.disabled}
                        active={props.sortProps.orderBy === column.orderBy}
                        direction={
                          props.sortProps.orderBy === column.orderBy
                            ? props.sortProps.order
                            : "asc"
                        }
                        onClick={(
                          event: React.MouseEvent<unknown, MouseEvent>
                        ) =>
                          props.sortProps.handleRequestSort(
                            event,
                            column.orderBy!
                          )
                        }
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.historicBillingData.records.map(
                (item: InvoiceRecord, index: number) => {
                  return (
                    <DashboardHistoricTableRow
                      renderColumns={fixedHistoricHeaders}
                      key={`historicBilling_${get(item, "tax_id", 0)}_${index}`}
                      billingInitialStatus={get(item, "initial_status", "")}
                      billingState={get(item, "status", "")}
                      historicBillingData={item}
                      handlerOpenRow={props.modalOver.handlerSelectHistoricTrx}
                      country={props.country}
                      index={index}
                      modalBackRetention={props.modalBackRetention}
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        ) : (
          <EmptyList />
        )}
        {props.historicBillingData.records.length > 0 && (
          <DashboardListPagination
            disableNext={props.hideNext}
            disablePrevious={props.hidePrevious}
            handleChangePage={props.handleChangePage}
            handleChangePageSize={props.handleChangePageSize}
            isMobile={false}
            page={props.page}
            pageSize={props.pageSize}
          />
        )}
      </TableContainer>
      <InvoiceAnnulModal trxInfo={props.modalOver.historicTrx} />
      <CreditDebitNoteModal
        trxInfo={props.modalOver.historicTrx}
        modalKind={modalKind}
      />
      <ModalOverlay
        header={
          <ModalHeaderInfo
            trxInfo={props.modalOver.historicTrx}
            isMinAmount={props.modalOver.isHistoricMinAmountTrx}
          />
        }
        body={
          <ModalBody>
            <ModalBodyTabs
              trxInfo={props.modalOver.historicTrx}
              country={props.country}
              statusFlow={props.statusFlow}
              isMinAmount={props.modalOver.isHistoricMinAmountTrx}
              handleChangeTab={handleChangeTab}
              handleOpenDialogDetail={props.modalOver.handleOpenDialogDetail}
              handleCloseDialogDetail={props.modalOver.handleCloseDialogDetail}
              valueTab={valueTab}
              textArea={props.textArea}
              isHistoric={true}
              pdfViewer={props.modalOver.pdfViewer}
              downloadFileState={props.downloadFileState}
            />
          </ModalBody>
        }
        footer={
          [CountryEnum.ecuador, CountryEnum.chile].includes(
            props.country as CountryEnum
          ) &&
          [KindEnum.RETENTION_EC].includes(
            get(props, "modalOver.historicTrx.kind", "")
          ) &&
          valueTab === 0 ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter
                    className={classes.modalRetentionFooterHistoric}
                    secondaryActionIcon={
                      validateRenderedCanceledRetention(
                        InitialStatusEnum.APPROVE,
                        StatusEnum.REJECTED
                      ) ? (
                        <IconDelete xlinkTitle={"Anular retención"} size={15} />
                      ) : undefined
                    }
                    secondaryFontColor={
                      validateRenderedCanceledRetention(
                        InitialStatusEnum.APPROVE,
                        StatusEnum.REJECTED
                      )
                        ? "error"
                        : undefined
                    }
                    secondaryLabelButton={
                      validateRenderedCanceledRetention(
                        InitialStatusEnum.APPROVE,
                        StatusEnum.REJECTED
                      )
                        ? "Anular retención"
                        : undefined
                    }
                    secondaryActionButton={() => {
                      validateRenderedCanceledRetention(
                        InitialStatusEnum.APPROVE,
                        StatusEnum.REJECTED
                      )
                        ? props.modalOver.handlerOpenModalRetention()
                        : undefined;
                    }}
                    dropDwLoading={props.modalOver.isLoadingFileRetention}
                    dropDwLabelButton={"Descargar"}
                    dropDwButtonVariant={"contained"}
                    dropDwButtonColor={"primary"}
                    classNamedrowButtonPrimary="splitBtn"
                    classNamedrowButton="splitBtn splitArrow"
                    dropDwActionIcon={
                      <IconDownload xlinkTitle={"Descargar"} size={15} />
                    }
                    dropDwMenuItems={[
                      {
                        label: "Descargar en PDF",
                        disable: false,
                        hidden: !validateStatusRetentionIsApprove(
                          InitialStatusEnum.APPROVE,
                          StatusEnum.COMPLETED
                        ),
                        menuItemActionButton: () => {
                          props.modalOver.downloadFileRetention(
                            get(
                              props.modalOver.historicTrx,
                              "transaction_id",
                              ""
                            ),
                            "pdf"
                          );
                        },
                      },
                      {
                        label: "Descargar en XML",
                        disable: false,
                        hidden: !validateStatusRetentionIsApprove(
                          InitialStatusEnum.APPROVE,
                          StatusEnum.COMPLETED
                        ),
                        menuItemActionButton: () => {
                          props.modalOver.downloadFileRetention(
                            get(
                              props.modalOver.historicTrx,
                              "transaction_id",
                              ""
                            ),
                            "xml"
                          );
                        },
                      },
                      {
                        label: "Descargar detalle",
                        disable: false,
                        hidden: false,
                        menuItemActionButton: () => {
                          props.modalOver.downloadFileRetention(
                            get(
                              props.modalOver.historicTrx,
                              "transaction_id",
                              ""
                            ),
                            "csv"
                          );
                        },
                      },
                    ]}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : isAnnulInvoice ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter
                    {...footerPropsAnnulInvoiceMex}
                    {...footerPropsDownloadInvoice}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : isStatusAnnul ? (
            <React.Fragment>
              {includes([CountryEnum.mexico], props.country) &&
              isStatusAnnul ? (
                <ComponentSecurityWrapper
                  componentId={
                    RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER
                  }
                >
                  <Box>
                    <ModalFooter {...footerPropsDownloadInvoice} />
                  </Box>
                </ComponentSecurityWrapper>
              ) : (
                <ComponentSecurityWrapper
                  componentId={
                    RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER
                  }
                >
                  <Box>
                    <ModalFooter
                      {...footerPropsGenerateNewInvoice}
                      {...footerPropsDownloadInvoice}
                    />
                  </Box>
                </ComponentSecurityWrapper>
              )}
            </React.Fragment>
          ) : (isDispersion || isReceivable) &&
            includes(
              [
                CountryEnum.colombia,
                CountryEnum.chile,
                CountryEnum.ecuador,
                CountryEnum.mexico,
                CountryEnum.peru,
              ],
              props.country
            ) ? (
            <ComponentSecurityWrapper
              componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
            >
              <Box>
                <ModalFooter {...footerPropsDownloadDispersion} />
              </Box>
            </ComponentSecurityWrapper>
          ) : isCreditDebitNote ? (
            <ComponentSecurityWrapper
              componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
            >
              <Box>
                <ModalFooter className={classes.modalRetentionFooterHistoric} />
              </Box>
            </ComponentSecurityWrapper>
          ) : [CountryEnum.ecuador, CountryEnum.chile].includes(
              props.country as CountryEnum
            ) ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter {...footerPropsDownloadInvoice} />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : [CountryEnum.mexico].includes(props.country as CountryEnum) ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter
                    {...footerPropsAnnulInvoiceMex}
                    {...footerPropsDownloadInvoice}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : isRetentionColombia && isOmittedCondition && valueTab === 0 ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter {...footerPropsGenerateOmittedRet} />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : [CountryEnum.colombia].includes(props.country as CountryEnum) &&
            !isOmittedCondition &&
            !isRetentionColombia ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter
                    {...footerPropsGenerateCreditDebitNote}
                    {...footerPropsDownloadInvoice}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : [CountryEnum.peru].includes(props.country as CountryEnum) &&
            verifyTwoDays(get(props.modalOver.historicTrx, "issue_date")) ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter
                    {...footerPropsAnullInvoice}
                    {...footerPropsDownloadInvoice}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : [CountryEnum.peru].includes(props.country as CountryEnum) &&
            !verifyTwoDays(get(props.modalOver.historicTrx, "issue_date")) ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter
                    {...footerPropsGenerateCreditDebitNote}
                    {...footerPropsDownloadInvoice}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : !isInvoiceCompleted && !isRetentionColombia && valueTab === 0 ? (
            <React.Fragment>
              <ComponentSecurityWrapper
                componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
              >
                <Box>
                  <ModalFooter {...footerPropsDownloadInvoice} />
                </Box>
              </ComponentSecurityWrapper>
            </React.Fragment>
          ) : (
            <ComponentSecurityWrapper
              componentId={RolesHistoricEnum.M_BILLING_HISTORIC_MODAL_FOOTER}
            >
              <Box>
                <ModalFooter className={classes.modalRetentionFooterHistoric} />
              </Box>
            </ComponentSecurityWrapper>
          )
        }
        openModal={props.modalOver.openModal}
        onCloseModal={props.modalOver.handlerCloseModal}
        overlay={false}
        backdrop={false}
        transition={"left"}
      />
      <ConfirmModal
        open={props.modalOver.openModalRetention}
        titleText={"¿Deseas anular esta retención?"}
        acceptText="Anular Retención"
        handleClose={props.modalOver.handlerCloseModalRetention}
        handleAccept={props.modalOver.handlerDeleteRetention}
        acceptColor={"primary"}
        disableAccept={props.modalOver.availableDeleteRetentionButton}
      >
        <Grid>
          <Box fontSize={15}>
            {"Estas a punto de anular la"}
            <Box
              className={classes.primaryColor}
              color={"textPrimary"}
              display={"inline"}
            >
              {`
              ${get(props.modalOver.historicTrx, "kind", "")} ${get(
                props.modalOver.historicTrx,
                "retention_number",
                ""
              )}`}
            </Box>
            <Box display={"inline"}>{" por un monto total de "}</Box>
            <Box className={classes.primaryColor} display={"inline"}>{`${Number(
              get(props.modalOver.historicTrx, "ret_total_amount", 0)
            ).toFixed(2)} ${get(
              props.modalOver.historicTrx,
              "currency_code",
              ""
            )}`}</Box>
            <Box display={"inline"}>{". Para anular dar click en "}</Box>
            <Box style={{ fontWeight: "bold" }} display={"inline"}>
              &quot;Anular retención&quot;
            </Box>
            {
              " recuerda que esa acción es irreversible y posterior a ello se generará un nuevo registro de retención con la información del registro anulado."
            }
          </Box>
          <Typography style={{ marginTop: 20 }}>
            {
              "*Indique el motivo de la anulación, para guardar la modificación."
            }
          </Typography>
          <TextField
            multiline
            className={
              props.modalOver.countCharacterReasonDeleteRetention === 200
                ? classes.inputRed
                : classes.input
            }
            fullWidth
            label="Ingresa el motivo de la anulación"
            variant="outlined"
            value={props.modalOver.reasonDeleteRetention}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.modalOver.handleInputReasonDeleteRetention(event)}
          />
          <Box
            display="inherit"
            textAlign="end"
            className={
              props.modalOver.countCharacterReasonDeleteRetention === 200
                ? classes.errorColor
                : undefined
            }
          >
            {`${props.modalOver.countCharacterReasonDeleteRetention}/200`}
          </Box>
        </Grid>
      </ConfirmModal>
      <ConfirmModal
        open={props.modalBackRetention.openModalBackRetention}
        handleClose={props.modalBackRetention.handlerCloseModalBackRetention}
        handleAccept={props.modalBackRetention.handlerAcceptTransaction}
        titleText={BackRetentionModalEnum.tittle}
        contentText={BackRetentionModalEnum.contentText}
        acceptColor={"primary"}
        buttonTextSize={"16px"}
        disableAccept={props.modalBackRetention.isLoading}
      />
    </React.Fragment>
  );
};
export const mapStateToProps: (
  state: IBillingDashboardState
) => DashboardHistoricTableStateProps = (
  state: IBillingDashboardState
): DashboardHistoricTableStateProps => ({
  historicBillingData: state.historicBillingData,
  transactionTableCollectionsHistory: state.transactionTableCollectionsHistory,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => DashboardHistoricTableFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): DashboardHistoricTableFunctionsProps => ({
  setBillingHistoricDataRow: (billingDataRow: InvoiceRecord): void =>
    dispatch(setBillingHistoricDataRow(billingDataRow)),
  transactionIdFilterHistory: (): void => {
    dispatch(transactionIdFilterHistory());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHistoricTable);
