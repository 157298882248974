import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const cellFlagStyles: IStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  skeletonIcon: {
    height: "24px",
    marginBottom: "4px",
    width: "24px",
  },
  skeletonText: {
    height: "16px",
    width: "28px",
  },
  textFlag: {
    marginTop: "4px",
  },
};

export { cellFlagStyles };
