/* eslint-disable no-trailing-spaces */
import React, { SVGProps } from "react";

const SvgAlia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="img"
    aria-label="alia card icon"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="surface1">
      <path
        stroke="none"
        fillRule="evenodd"
        fill="rgb(45.098039%,70.196078%,10.980392%)"
        fillOpacity="1"
        d="M 4.238281
        0.03125 C 3.988281 0.0429688 3.757812 0.0625 3.730469 0.0742188 C 3.703125 0.0859375 3.554688 0.113281 3.40625 0.128906 C 3.257812 0.144531 3.121094 0.167969
        3.105469 0.179688 C 3.09375 0.191406 3.039062 0.210938 2.988281 0.21875 C 2.941406 0.230469 2.875 0.246094 2.847656 0.257812 C 2.679688 0.328125 2.644531
        0.339844 2.597656 0.339844 C 2.570312 0.339844 2.546875 0.351562 2.546875 0.363281 C 2.546875 0.378906 2.53125 0.390625 2.511719 0.390625 C 2.492188 0.390625
        2.457031 0.40625 2.4375 0.425781 C 2.417969 0.445312 2.375 0.46875 2.339844 0.480469 C 2.304688 0.492188 2.242188 0.523438 2.207031 0.550781 C 2.167969 0.574219
        2.125 0.597656 2.109375 0.597656 C 2.09375 0.597656 2.078125 0.609375 2.078125 0.625 C 2.078125 0.636719 2.0625 0.648438 2.039062 0.648438 C 2.019531 0.648438
        1.992188 0.667969 1.988281 0.6875 C 1.980469 0.707031 1.945312 0.730469 1.910156 0.738281 C 1.875 0.746094 1.8125 0.789062 1.769531 0.832031 C 1.722656 0.875
        1.679688 0.910156 1.664062 0.910156 C 1.652344 0.910156 1.597656 0.957031 1.539062 1.015625 C 1.484375 1.070312 1.429688 1.117188 1.421875 1.117188 C 1.402344
        1.117188 1.117188 1.402344 1.117188 1.421875 C 1.117188 1.429688 1.070312 1.484375 1.015625 1.539062 C 0.957031 1.597656 0.910156 1.652344 0.910156 1.664062 C
        0.910156 1.679688 0.875 1.722656 0.832031 1.769531 C 0.789062 1.8125 0.746094 1.875 0.738281 1.910156 C 0.730469 1.945312 0.707031 1.980469 0.6875 1.988281 C
        0.667969 1.992188 0.648438 2.019531 0.648438 2.039062 C 0.648438 2.0625 0.636719 2.078125 0.625 2.078125 C 0.609375 2.078125 0.597656 2.09375 0.597656 2.109375
        C 0.597656 2.125 0.574219 2.167969 0.550781 2.207031 C 0.523438 2.242188 0.492188 2.304688 0.480469 2.339844 C 0.46875 2.375 0.445312 2.417969 0.425781 2.4375 C
        0.40625 2.457031 0.390625 2.492188 0.390625 2.511719 C 0.390625 2.53125 0.378906 2.546875 0.363281 2.546875 C 0.351562 2.546875 0.339844 2.570312 0.339844
        2.597656 C 0.339844 2.644531 0.328125 2.679688 0.257812 2.847656 C 0.246094 2.875 0.230469 2.941406 0.21875 2.988281 C 0.210938 3.039062 0.191406 3.09375
        0.179688 3.105469 C 0.167969 3.121094 0.144531 3.257812 0.128906 3.40625 C 0.113281 3.554688 0.0898438 3.703125 0.0742188 3.730469 C -0.0390625 3.992188
        -0.0390625 22.007812 0.0742188 22.269531 C 0.0898438 22.296875 0.113281 22.445312 0.128906 22.59375 C 0.144531 22.742188 0.171875 22.878906 0.183594 22.894531 C
        0.195312 22.910156 0.214844 22.972656 0.222656 23.03125 C 0.234375 23.089844 0.25 23.140625 0.257812 23.140625 C 0.265625 23.140625 0.289062 23.210938 0.308594
        23.296875 C 0.328125 23.382812 0.355469 23.453125 0.367188 23.453125 C 0.378906 23.453125 0.390625 23.46875 0.390625 23.488281 C 0.390625 23.507812 0.40625
        23.542969 0.425781 23.5625 C 0.445312 23.582031 0.46875 23.625 0.480469 23.660156 C 0.492188 23.695312 0.523438 23.757812 0.550781 23.792969 C 0.574219
        23.832031 0.597656 23.875 0.597656 23.890625 C 0.597656 23.90625 0.609375 23.921875 0.625 23.921875 C 0.636719 23.921875 0.648438 23.9375 0.648438 23.960938 C
        0.648438 23.980469 0.667969 24.007812 0.6875 24.011719 C 0.707031 24.019531 0.730469 24.054688 0.738281 24.089844 C 0.746094 24.125 0.789062 24.1875 0.832031
        24.230469 C 0.875 24.277344 0.910156 24.320312 0.910156 24.332031 C 0.910156 24.339844 0.957031 24.398438 1.011719 24.453125 C 1.070312 24.511719 1.117188
        24.566406 1.117188 24.574219 C 1.117188 24.601562 1.390625 24.882812 1.417969 24.882812 C 1.429688 24.882812 1.484375 24.929688 1.539062 24.984375 C 1.597656
        25.042969 1.652344 25.089844 1.664062 25.089844 C 1.679688 25.089844 1.722656 25.125 1.769531 25.167969 C 1.8125 25.210938 1.875 25.253906 1.910156 25.261719 C
        1.945312 25.269531 1.980469 25.292969 1.988281 25.3125 C 1.992188 25.332031 2.019531 25.351562 2.039062 25.351562 C 2.0625 25.351562 2.078125 25.363281 2.078125
        25.375 C 2.078125 25.390625 2.09375 25.402344 2.109375 25.402344 C 2.125 25.402344 2.167969 25.425781 2.207031 25.453125 C 2.246094 25.480469 2.308594 25.511719
        2.347656 25.519531 C 2.386719 25.53125 2.417969 25.546875 2.417969 25.5625 C 2.417969 25.574219 2.433594 25.585938 2.457031 25.585938 C 2.476562 25.585938
        2.507812 25.597656 2.527344 25.617188 C 2.546875 25.636719 2.621094 25.667969 2.691406 25.6875 C 2.761719 25.707031 2.832031 25.730469 2.847656 25.742188 C
        2.859375 25.75 2.917969 25.769531 2.976562 25.777344 C 3.035156 25.789062 3.09375 25.804688 3.105469 25.816406 C 3.121094 25.832031 3.257812 25.855469 3.40625
        25.871094 C 3.554688 25.886719 3.703125 25.910156 3.730469 25.925781 C 3.992188 26.039062 22.007812 26.039062 22.269531 25.925781 C 22.296875 25.910156
        22.445312 25.886719 22.59375 25.871094 C 22.742188 25.855469 22.878906 25.828125 22.894531 25.816406 C 22.910156 25.804688 22.972656 25.785156 23.03125
        25.777344 C 23.089844 25.765625 23.140625 25.75 23.140625 25.738281 C 23.140625 25.722656 23.164062 25.714844 23.191406 25.714844 C 23.25 25.714844 23.5625
        25.589844 23.582031 25.558594 C 23.589844 25.546875 23.640625 25.523438 23.699219 25.507812 C 23.757812 25.488281 23.8125 25.457031 23.824219 25.4375 C
        23.835938 25.417969 23.863281 25.402344 23.882812 25.402344 C 23.902344 25.402344 23.921875 25.390625 23.921875 25.375 C 23.921875 25.363281 23.9375 25.351562
        23.960938 25.351562 C 23.980469 25.351562 24.007812 25.332031 24.011719 25.3125 C 24.019531 25.292969 24.054688 25.269531 24.089844 25.261719 C 24.125 25.253906
        24.1875 25.210938 24.230469 25.167969 C 24.277344 25.125 24.324219 25.089844 24.339844 25.089844 C 24.351562 25.089844 24.410156 25.042969 24.464844 24.984375 C
        24.515625 24.929688 24.570312 24.882812 24.582031 24.882812 C 24.609375 24.882812 24.882812 24.609375 24.882812 24.582031 C 24.882812 24.570312 24.929688
        24.515625 24.984375 24.464844 C 25.042969 24.410156 25.089844 24.351562 25.089844 24.339844 C 25.089844 24.324219 25.125 24.277344 25.167969 24.230469 C
        25.210938 24.1875 25.253906 24.125 25.261719 24.089844 C 25.269531 24.054688 25.292969 24.019531 25.3125 24.011719 C 25.332031 24.007812 25.351562 23.980469
        25.351562 23.960938 C 25.351562 23.9375 25.363281 23.921875 25.375 23.921875 C 25.390625 23.921875 25.402344 23.902344 25.402344 23.882812 C 25.402344 23.863281
        25.417969 23.835938 25.4375 23.824219 C 25.457031 23.8125 25.488281 23.757812 25.507812 23.699219 C 25.523438 23.640625 25.546875 23.589844 25.558594 23.582031
        C 25.589844 23.5625 25.714844 23.25 25.714844 23.191406 C 25.714844 23.164062 25.722656 23.140625 25.738281 23.140625 C 25.75 23.140625 25.765625 23.089844
        25.777344 23.03125 C 25.785156 22.972656 25.804688 22.910156 25.816406 22.894531 C 25.828125 22.878906 25.855469 22.742188 25.871094 22.59375 C 25.886719
        22.445312 25.910156 22.296875 25.925781 22.269531 C 26.039062 22.007812 26.039062 3.992188 25.925781 3.730469 C 25.910156 3.703125 25.886719 3.554688 25.871094
        3.40625 C 25.855469 3.257812 25.832031 3.121094 25.816406 3.105469 C 25.804688 3.09375 25.789062 3.035156 25.777344 2.976562 C 25.769531 2.917969 25.75 2.859375
        25.742188 2.847656 C 25.730469 2.832031 25.707031 2.761719 25.6875 2.691406 C 25.667969 2.621094 25.636719 2.546875 25.617188 2.527344 C 25.597656 2.507812
        25.585938 2.476562 25.585938 2.457031 C 25.585938 2.433594 25.574219 2.417969 25.5625 2.417969 C 25.546875 2.417969 25.53125 2.386719 25.519531 2.347656 C
        25.511719 2.308594 25.480469 2.246094 25.453125 2.207031 C 25.425781 2.167969 25.402344 2.125 25.402344 2.109375 C 25.402344 2.09375 25.390625 2.078125 25.375
        2.078125 C 25.363281 2.078125 25.351562 2.0625 25.351562 2.039062 C 25.351562 2.019531 25.332031 1.992188 25.3125 1.988281 C 25.292969 1.980469 25.269531
        1.945312 25.261719 1.910156 C 25.253906 1.875 25.210938 1.8125 25.167969 1.769531 C 25.125 1.722656 25.089844 1.679688 25.089844 1.664062 C 25.089844 1.652344
        25.042969 1.597656 24.984375 1.539062 C 24.929688 1.484375 24.882812 1.429688 24.882812 1.417969 C 24.882812 1.390625 24.601562 1.117188 24.574219 1.117188 C
        24.566406 1.117188 24.511719 1.070312 24.453125 1.011719 C 24.398438 0.957031 24.339844 0.910156 24.332031 0.910156 C 24.320312 0.910156 24.277344 0.875
        24.230469 0.832031 C 24.1875 0.789062 24.125 0.746094 24.089844 0.738281 C 24.054688 0.730469 24.019531 0.707031 24.011719 0.6875 C 24.007812 0.667969 23.980469
        0.648438 23.960938 0.648438 C 23.9375 0.648438 23.921875 0.636719 23.921875 0.625 C 23.921875 0.609375 23.90625 0.597656 23.890625 0.597656 C 23.875 0.597656
        23.832031 0.574219 23.792969 0.550781 C 23.757812 0.523438 23.695312 0.492188 23.660156 0.480469 C 23.625 0.46875 23.582031 0.445312 23.5625 0.425781 C
        23.542969 0.40625 23.507812 0.390625 23.488281 0.390625 C 23.46875 0.390625 23.453125 0.378906 23.453125 0.367188 C 23.453125 0.355469 23.382812 0.328125
        23.296875 0.308594 C 23.210938 0.289062 23.140625 0.265625 23.140625 0.257812 C 23.140625 0.25 23.089844 0.234375 23.03125 0.222656 C 22.972656 0.214844
        22.910156 0.195312 22.894531 0.183594 C 22.878906 0.171875 22.742188 0.144531 22.59375 0.128906 C 22.445312 0.113281 22.296875 0.0898438 22.269531 0.0742188 C
        22.101562 0.00390625 5.660156 -0.0390625 4.238281 0.03125 M 12.390625 3.363281 C 12.546875 3.441406 13.378906 4.121094 13.519531 4.289062 C 13.527344 4.296875
        13.613281 4.378906 13.714844 4.472656 C 13.933594 4.667969 14.390625 5.097656 14.867188 5.539062 C 15.582031 6.210938 15.824219 6.429688 15.871094 6.453125 C
        15.902344 6.472656 16.171875 6.464844 16.714844 6.4375 C 17.484375 6.398438 18.042969 6.375 19.539062 6.316406 C 19.925781 6.300781 20.5625 6.273438 20.957031
        6.25 C 21.636719 6.214844 22.125 6.203125 22.125 6.222656 C 22.125 6.226562 22.117188 6.265625 22.101562 6.308594 C 22.0625 6.4375 21.925781 6.894531 21.867188
        7.097656 C 21.835938 7.207031 21.789062 7.34375 21.769531 7.410156 C 21.746094 7.472656 21.722656 7.5625 21.710938 7.605469 C 21.691406 7.675781 21.40625
        8.628906 21.308594 8.9375 C 21.210938 9.25 20.921875 10.214844 20.902344 10.28125 C 20.875 10.394531 20.726562 10.894531 20.695312 10.984375 C 20.671875 11.0625
        20.679688 11.070312 21.523438 11.921875 C 21.992188 12.394531 22.457031 12.867188 22.554688 12.972656 C 22.917969 13.367188 23.214844 13.691406 23.335938
        13.839844 C 23.40625 13.921875 23.472656 13.988281 23.484375 13.988281 C 23.496094 13.988281 23.503906 14.003906 23.503906 14.027344 C 23.503906 14.046875
        23.515625 14.066406 23.527344 14.066406 C 23.554688 14.066406 23.832031 14.4375 23.894531 14.5625 C 24.121094 15.003906 23.851562 15.285156 22.21875 16.28125 C
        22.03125 16.398438 21.839844 16.511719 21.789062 16.542969 C 21.738281 16.570312 21.632812 16.632812 21.554688 16.679688 C 21.167969 16.90625 20.136719
        17.480469 19.511719 17.808594 C 19.160156 17.996094 19.175781 17.976562 19.140625 18.269531 C 19.121094 18.410156 19.089844 18.628906 19.070312 18.757812 C
        19.050781 18.886719 19.023438 19.105469 19.007812 19.238281 C 18.992188 19.375 18.960938 19.617188 18.941406 19.773438 C 18.902344 20.054688 18.84375 20.59375
        18.796875 21.046875 C 18.785156 21.175781 18.761719 21.355469 18.746094 21.449219 C 18.734375 21.542969 18.710938 21.71875 18.691406 21.839844 C 18.640625
        22.222656 18.53125 22.371094 18.304688 22.371094 C 18.109375 22.367188 16.511719 21.882812 15.40625 21.488281 C 15.261719 21.4375 15.058594 21.367188 14.949219
        21.332031 C 14.84375 21.292969 14.691406 21.238281 14.613281 21.210938 C 14.535156 21.179688 14.445312 21.148438 14.417969 21.136719 C 13.957031 20.96875
        13.765625 20.898438 13.714844 20.878906 C 13.527344 20.796875 13.535156 20.796875 13.195312 20.957031 C 13.046875 21.027344 12.917969 21.085938 12.910156
        21.085938 C 12.90625 21.085938 12.796875 21.132812 12.671875 21.191406 C 12.542969 21.25 12.394531 21.320312 12.335938 21.347656 C 12.042969 21.480469 11.929688
        21.53125 11.75 21.613281 C 11.640625 21.664062 11.523438 21.710938 11.488281 21.722656 C 11.457031 21.734375 11.414062 21.753906 11.398438 21.765625 C 11.382812
        21.777344 11.324219 21.804688 11.269531 21.824219 C 11.210938 21.84375 11.113281 21.882812 11.050781 21.914062 C 10.984375 21.941406 10.855469 21.996094
        10.765625 22.035156 C 10.671875 22.070312 10.570312 22.113281 10.542969 22.125 C 10.148438 22.304688 9.371094 22.574219 9.089844 22.628906 C 8.644531 22.722656
        8.121094 22.574219 8.113281 22.351562 C 8.113281 22.339844 8.066406 22.230469 8.007812 22.109375 C 7.949219 21.988281 7.902344 21.882812 7.902344 21.878906 C
        7.902344 21.871094 7.863281 21.777344 7.808594 21.664062 C 7.757812 21.554688 7.675781 21.375 7.632812 21.269531 C 7.53125 21.035156 7.183594 20.238281 7.152344
        20.164062 C 7.09375 20.039062 6.761719 19.25 6.578125 18.8125 C 6.519531 18.667969 6.457031 18.515625 6.4375 18.472656 C 6.417969 18.429688 6.347656 18.265625
        6.28125 18.109375 C 6.164062 17.824219 6.160156 17.824219 6.039062 17.773438 C 5.96875 17.746094 5.867188 17.703125 5.8125 17.679688 C 5.753906 17.652344
        5.578125 17.578125 5.421875 17.507812 C 5.039062 17.347656 4.015625 16.90625 3.886719 16.847656 C 3.6875 16.757812 2.664062 16.308594 2.523438 16.25 C 2.441406
        16.214844 2.367188 16.179688 2.351562 16.171875 C 2.339844 16.160156 2.210938 16.101562 2.066406 16.042969 C 1.75 15.90625 1.746094 15.902344 1.808594 15.828125
        C 1.863281 15.765625 1.886719 15.730469 2.277344 15.132812 C 2.507812 14.773438 3.304688 13.574219 3.453125 13.363281 C 3.496094 13.300781 3.617188 13.128906
        3.714844 12.988281 C 4.148438 12.371094 4.121094 12.476562 3.964844 12.050781 C 3.867188 11.78125 3.535156 10.785156 3.414062 10.398438 C 3.378906 10.277344
        3.320312 10.097656 3.285156 9.996094 C 3.253906 9.898438 3.214844 9.769531 3.199219 9.710938 C 3.1875 9.652344 3.148438 9.523438 3.121094 9.425781 C 2.917969
        8.738281 2.730469 7.886719 2.730469 7.644531 C 2.730469 7.492188 2.761719 7.429688 2.894531 7.347656 C 2.976562 7.292969 3.828125 7.203125 5.058594 7.125 C
        5.285156 7.109375 5.707031 7.078125 5.992188 7.058594 C 6.277344 7.039062 6.710938 7.011719 6.953125 6.996094 C 7.199219 6.980469 7.605469 6.953125 7.859375
        6.9375 L 8.320312 6.90625 L 8.441406 6.75 C 8.507812 6.664062 8.570312 6.585938 8.578125 6.578125 C 8.589844 6.570312 8.671875 6.472656 8.761719 6.355469 C
        8.9375 6.140625 8.996094 6.070312 9.152344 5.890625 C 9.203125 5.832031 9.300781 5.714844 9.375 5.628906 C 9.773438 5.144531 10.859375 4.003906 10.921875
        4.003906 C 10.933594 4.003906 10.945312 3.992188 10.945312 3.980469 C 10.945312 3.957031 11.339844 3.636719 11.472656 3.554688 C 11.871094 3.304688 12.144531
        3.246094 12.390625 3.363281 M 8.035156 7.257812 C 7.589844 7.289062 6.625 7.355469 6.121094 7.382812 C 5.878906 7.398438 5.453125 7.429688 5.175781 7.449219 C
        4.515625 7.5 4.078125 7.53125 3.613281 7.566406 C 3.136719 7.601562 3.03125 7.625 3.050781 7.703125 C 3.058594 7.734375 3.070312 7.808594 3.082031 7.863281 C
        3.105469 8.019531 3.167969 8.289062 3.269531 8.683594 C 3.40625 9.21875 3.421875 9.285156 3.464844 9.425781 C 3.488281 9.496094 3.523438 9.613281 3.542969
        9.683594 C 3.683594 10.160156 4.304688 12.046875 4.425781 12.371094 C 4.464844 12.476562 4.5 12.476562 4.554688 12.375 C 4.582031 12.324219 4.613281 12.300781
        4.632812 12.3125 C 4.652344 12.324219 4.65625 12.320312 4.644531 12.300781 C 4.632812 12.285156 4.636719 12.273438 4.652344 12.273438 C 4.671875 12.273438
        4.675781 12.261719 4.671875 12.253906 C 4.65625 12.230469 4.71875 12.132812 4.742188 12.148438 C 4.75 12.152344 4.847656 12.019531 4.960938 11.855469 C 5.296875
        11.367188 6.035156 10.320312 6.324219 9.925781 C 6.421875 9.792969 6.519531 9.660156 6.539062 9.632812 C 7.199219 8.71875 7.90625 7.832031 8.28125 7.441406 C
        8.519531 7.195312 8.550781 7.21875 8.035156 7.257812 M 16.378906 7.417969 C 16.378906 7.421875 16.417969 7.460938 16.464844 7.503906 L 16.550781 7.578125 L
        16.472656 7.496094 C 16.402344 7.414062 16.378906 7.398438 16.378906 7.417969 M 17.289062 8.273438 C 17.351562 8.34375 17.410156 8.398438 17.414062 8.398438 C
        17.433594 8.398438 17.410156 8.375 17.296875 8.265625 L 17.171875 8.152344 L 17.289062 8.273438 M 11.96875 12.140625 C 11.964844 13.4375 11.972656 14.515625
        11.980469 14.539062 C 11.996094 14.582031 11.996094 11.65625 11.980469 10.335938 C 11.976562 10.035156 11.96875 10.847656 11.96875 12.140625 M 12.933594
        9.835938 C 12.925781 9.855469 12.925781 10.0625 12.929688 10.296875 L 12.933594 10.726562 L 13.804688 10.726562 L 12.945312 10.710938 L 12.953125 10.257812 C
        12.960938 9.808594 12.960938 9.769531 12.933594 9.835938 M 13.234375 9.839844 C 13.3125 9.859375 13.382812 9.871094 13.386719 9.867188 C 13.398438 9.851562
        13.203125 9.800781 13.144531 9.804688 C 13.113281 9.804688 13.15625 9.820312 13.234375 9.839844 M 11.558594 9.855469 C 11.523438 9.867188 11.519531 9.875
        11.542969 9.875 C 11.566406 9.875 11.605469 9.867188 11.636719 9.855469 C 11.667969 9.839844 11.671875 9.832031 11.648438 9.832031 C 11.625 9.832031 11.585938
        9.839844 11.558594 9.855469 M 11.335938 9.953125 C 11.269531 9.992188 11.203125 10.050781 11.179688 10.078125 C 11.152344 10.117188 11.15625 10.117188 11.207031
        10.070312 C 11.242188 10.039062 11.324219 9.984375 11.386719 9.949219 C 11.453125 9.910156 11.492188 9.878906 11.476562 9.878906 C 11.460938 9.878906 11.398438
        9.914062 11.335938 9.953125 M 11.097656 12.53125 C 11.09375 13.820312 11.097656 14.871094 11.109375 14.871094 C 11.121094 14.871094 11.128906 13.820312
        11.128906 12.53125 C 11.128906 11.246094 11.121094 10.191406 11.117188 10.191406 C 11.109375 10.191406 11.097656 11.246094 11.097656 12.53125 M 20.257812
        11.121094 C 20.285156 11.164062 20.285156 11.203125 20.261719 11.300781 C 20.207031 11.519531 20.019531 12.300781 19.96875 12.519531 C 19.941406 12.632812
        19.878906 12.898438 19.828125 13.105469 C 19.683594 13.71875 19.613281 14.027344 19.511719 14.480469 C 19.460938 14.71875 19.410156 14.957031 19.394531
        15.015625 C 19.34375 15.214844 19.179688 16.015625 19.082031 16.535156 C 19.054688 16.6875 19.019531 16.867188 19.007812 16.9375 C 18.960938 17.191406 18.90625
        17.511719 18.886719 17.648438 L 18.871094 17.785156 L 19 17.707031 C 19.070312 17.664062 19.136719 17.628906 19.144531 17.628906 C 19.15625 17.628906 19.265625
        17.570312 19.390625 17.503906 C 19.515625 17.433594 19.695312 17.332031 19.792969 17.28125 C 20.207031 17.054688 20.324219 16.992188 20.445312 16.921875 C
        20.511719 16.882812 20.574219 16.847656 20.578125 16.847656 C 20.589844 16.847656 20.941406 16.648438 21.390625 16.390625 C 22.808594 15.574219 23.667969
        14.953125 23.625 14.78125 C 23.601562 14.691406 23.289062 14.257812 23.164062 14.144531 C 23.15625 14.136719 23.0625 14.03125 22.957031 13.90625 C 22.472656
        13.347656 20.164062 10.96875 20.257812 11.121094 M 8.289062 11.175781 C 8.265625 11.183594 8.4375 11.191406 8.671875 11.191406 C 8.90625 11.191406 9.078125
        11.183594 9.054688 11.175781 C 9.027344 11.164062 8.855469 11.15625 8.671875 11.15625 C 8.484375 11.15625 8.3125 11.164062 8.289062 11.175781 M 15.878906
        11.171875 C 15.871094 11.183594 16.042969 11.191406 16.261719 11.191406 C 16.480469 11.191406 16.648438 11.183594 16.632812 11.175781 C 16.589844 11.148438
        15.890625 11.148438 15.878906 11.171875 M 7.878906 11.257812 C 7.824219 11.273438 7.824219 11.277344 7.863281 11.28125 C 7.894531 11.28125 7.941406 11.269531
        7.96875 11.257812 C 8.035156 11.230469 7.96875 11.230469 7.878906 11.257812 M 16.898438 11.269531 C 16.9375 11.292969 16.976562 11.308594 16.992188 11.308594 C
        17.003906 11.308594 16.988281 11.292969 16.953125 11.269531 C 16.917969 11.25 16.875 11.234375 16.859375 11.234375 C 16.847656 11.234375 16.863281 11.25
        16.898438 11.269531 M 12.933594 11.289062 C 12.925781 11.308594 12.925781 12.125 12.929688 13.105469 L 12.933594 14.886719 L 12.949219 11.285156 L 13.382812
        11.277344 L 13.820312 11.269531 L 13.382812 11.265625 C 13.054688 11.257812 12.941406 11.265625 12.933594 11.289062 M 7.652344 11.335938 C 7.5625 11.371094
        7.578125 11.390625 7.671875 11.359375 C 7.714844 11.34375 7.746094 11.328125 7.746094 11.320312 C 7.746094 11.304688 7.714844 11.308594 7.652344 11.335938 M
        9.4375 11.324219 C 9.4375 11.328125 9.472656 11.351562 9.519531 11.371094 C 9.578125 11.402344 9.59375 11.402344 9.570312 11.378906 C 9.542969 11.34375 9.4375
        11.300781 9.4375 11.324219 M 17.03125 11.320312 C 17.03125 11.328125 17.066406 11.355469 17.113281 11.378906 C 17.160156 11.402344 17.261719 11.480469 17.339844
        11.554688 L 17.484375 11.683594 L 17.359375 11.554688 C 17.25 11.4375 17.03125 11.28125 17.03125 11.320312 M 7.417969 11.464844 C 7.371094 11.492188 7.332031
        11.523438 7.332031 11.53125 C 7.332031 11.535156 7.371094 11.519531 7.421875 11.488281 C 7.472656 11.460938 7.515625 11.429688 7.515625 11.425781 C 7.515625
        11.40625 7.503906 11.410156 7.417969 11.464844 M 14.886719 11.546875 C 14.816406 11.601562 14.804688 11.660156 14.847656 11.726562 C 14.867188 11.753906
        14.867188 11.746094 14.855469 11.6875 C 14.839844 11.625 14.851562 11.601562 14.914062 11.550781 C 14.953125 11.519531 14.980469 11.492188 14.972656 11.492188 C
        14.964844 11.492188 14.925781 11.515625 14.886719 11.546875 M 8.113281 11.960938 C 8.050781 11.972656 7.992188 11.992188 7.984375 12 C 7.976562 12.007812
        8.015625 12.007812 8.074219 11.996094 C 8.128906 11.988281 8.242188 11.96875 8.320312 11.960938 C 8.460938 11.941406 8.460938 11.941406 8.347656 11.941406 C
        8.28125 11.941406 8.175781 11.949219 8.113281 11.960938 M 8.671875 11.972656 C 8.757812 11.992188 8.832031 12.003906 8.835938 11.996094 C 8.851562 11.984375
        8.648438 11.933594 8.578125 11.9375 C 8.542969 11.9375 8.585938 11.953125 8.671875 11.972656 M 15.742188 11.960938 C 15.695312 11.972656 15.726562 11.976562
        15.835938 11.96875 C 15.925781 11.960938 16.007812 11.949219 16.015625 11.945312 C 16.035156 11.925781 15.824219 11.941406 15.742188 11.960938 M 16.183594
        11.960938 C 16.296875 11.988281 16.390625 11.988281 16.300781 11.960938 C 16.265625 11.949219 16.207031 11.9375 16.171875 11.941406 C 16.113281 11.941406
        16.113281 11.941406 16.183594 11.960938 M 7.421875 12.039062 C 7.445312 12.082031 7.46875 12.117188 7.476562 12.117188 C 7.484375 12.117188 7.472656 12.082031
        7.449219 12.039062 C 7.425781 11.996094 7.402344 11.960938 7.394531 11.960938 C 7.386719 11.960938 7.402344 11.996094 7.421875 12.039062 M 10.078125 12.0625 C
        10.085938 12.101562 10.105469 12.148438 10.117188 12.167969 C 10.136719 12.195312 10.136719 12.1875 10.125 12.140625 C 10.117188 12.105469 10.097656 12.058594
        10.082031 12.039062 C 10.066406 12.011719 10.066406 12.019531 10.078125 12.0625 M 15.425781 12.039062 C 15.335938 12.074219 15.355469 12.089844 15.445312
        12.058594 C 15.488281 12.046875 15.523438 12.027344 15.523438 12.023438 C 15.523438 12.007812 15.492188 12.011719 15.425781 12.039062 M 16.59375 12.109375 C
        16.640625 12.148438 16.691406 12.199219 16.710938 12.21875 C 16.8125 12.355469 16.828125 12.367188 16.796875 12.300781 C 16.761719 12.230469 16.566406 12.039062
        16.53125 12.039062 C 16.515625 12.039062 16.542969 12.070312 16.59375 12.109375 M 15.054688 12.105469 C 15.054688 12.144531 15.132812 12.246094 15.164062
        12.246094 C 15.179688 12.246094 15.175781 12.230469 15.152344 12.214844 C 15.128906 12.195312 15.101562 12.15625 15.085938 12.128906 C 15.070312 12.101562
        15.054688 12.089844 15.054688 12.105469 M 7.621094 12.175781 C 7.5625 12.230469 7.5625 12.230469 7.527344 12.183594 C 7.503906 12.15625 7.488281 12.144531
        7.488281 12.160156 C 7.488281 12.242188 7.570312 12.253906 7.636719 12.183594 C 7.675781 12.148438 7.703125 12.117188 7.695312 12.117188 C 7.6875 12.117188
        7.65625 12.140625 7.621094 12.175781 M 17.75 12.363281 C 17.757812 12.421875 17.769531 12.472656 17.773438 12.476562 C 17.792969 12.5 17.78125 12.359375
        17.757812 12.3125 C 17.742188 12.273438 17.738281 12.289062 17.75 12.363281 M 10.1875 13.289062 C 10.191406 13.808594 10.195312 14.292969 10.199219 14.363281 C
        10.203125 14.4375 10.210938 14.054688 10.210938 13.519531 C 10.214844 12.984375 10.207031 12.5 10.195312 12.441406 C 10.1875 12.386719 10.183594 12.765625
        10.1875 13.289062 M 9.300781 12.652344 L 9.316406 12.839844 L 9.164062 12.851562 L 9.007812 12.863281 L 9.320312 12.855469 L 9.328125 12.75 C 9.332031 12.691406
        9.324219 12.601562 9.3125 12.554688 C 9.292969 12.488281 9.289062 12.511719 9.300781 12.652344 M 16.90625 12.726562 C 16.90625 12.796875 16.914062 12.824219
        16.917969 12.785156 C 16.925781 12.746094 16.925781 12.6875 16.917969 12.65625 C 16.914062 12.625 16.90625 12.65625 16.90625 12.726562 M 16.511719 12.871094 C
        16.457031 12.882812 16.511719 12.882812 16.640625 12.875 C 16.761719 12.867188 16.867188 12.859375 16.871094 12.851562 C 16.890625 12.835938 16.605469 12.851562
        16.511719 12.871094 M 8.761719 12.898438 C 8.71875 12.910156 8.746094 12.914062 8.839844 12.902344 C 8.917969 12.894531 8.988281 12.882812 8.992188 12.878906 C
        9.015625 12.859375 8.828125 12.875 8.761719 12.898438 M 16.019531 12.949219 C 15.972656 12.957031 15.929688 12.976562 15.917969 12.984375 C 15.902344 13
        16.035156 12.980469 16.144531 12.945312 C 16.234375 12.917969 16.136719 12.921875 16.019531 12.949219 M 8.359375 12.972656 C 8.292969 13.003906 8.359375
        13.003906 8.449219 12.972656 C 8.503906 12.957031 8.503906 12.953125 8.464844 12.953125 C 8.433594 12.953125 8.386719 12.960938 8.359375 12.972656 M 8.164062
        13.027344 C 8.109375 13.042969 8.109375 13.046875 8.152344 13.050781 C 8.179688 13.050781 8.21875 13.039062 8.242188 13.027344 C 8.289062 12.996094 8.257812
        12.996094 8.164062 13.027344 M 15.664062 13.050781 C 15.632812 13.066406 15.628906 13.074219 15.652344 13.074219 C 15.671875 13.074219 15.714844 13.0625
        15.742188 13.050781 C 15.777344 13.039062 15.78125 13.03125 15.757812 13.03125 C 15.734375 13.03125 15.695312 13.039062 15.664062 13.050781 M 7.839844 13.144531
        C 7.773438 13.175781 7.773438 13.179688 7.824219 13.164062 C 7.898438 13.148438 7.984375 13.105469 7.941406 13.105469 C 7.929688 13.105469 7.882812 13.125
        7.839844 13.144531 M 15.429688 13.144531 C 15.378906 13.183594 15.378906 13.183594 15.429688 13.160156 C 15.460938 13.148438 15.5 13.128906 15.523438 13.121094
        C 15.554688 13.109375 15.554688 13.109375 15.519531 13.105469 C 15.5 13.105469 15.457031 13.121094 15.429688 13.144531 M 7.664062 13.222656 C 7.566406 13.277344
        7.570312 13.296875 7.671875 13.246094 C 7.714844 13.226562 7.746094 13.199219 7.746094 13.195312 C 7.746094 13.175781 7.746094 13.179688 7.664062 13.222656 M
        7.289062 13.488281 C 7.214844 13.554688 7.125 13.65625 7.085938 13.714844 L 7.015625 13.820312 L 7.089844 13.726562 C 7.128906 13.679688 7.226562 13.574219
        7.304688 13.5 C 7.382812 13.425781 7.441406 13.363281 7.4375 13.363281 C 7.429688 13.363281 7.363281 13.421875 7.289062 13.488281 M 9.300781 13.921875 L
        9.308594 14.449219 L 9.210938 14.53125 C 9.160156 14.574219 9.121094 14.613281 9.132812 14.613281 C 9.140625 14.613281 9.191406 14.574219 9.242188 14.53125 L
        9.335938 14.449219 L 9.335938 13.933594 C 9.335938 13.652344 9.324219 13.414062 9.316406 13.40625 C 9.304688 13.398438 9.296875 13.632812 9.300781 13.921875 M
        8.890625 13.480469 C 8.84375 13.5 8.820312 13.515625 8.839844 13.515625 C 8.863281 13.515625 8.921875 13.5 8.96875 13.480469 C 9.019531 13.460938 9.042969
        13.445312 9.023438 13.445312 C 9 13.445312 8.941406 13.460938 8.890625 13.480469 M 16.472656 13.480469 C 16.367188 13.515625 16.367188 13.519531 16.457031
        13.503906 C 16.5625 13.484375 16.671875 13.445312 16.613281 13.445312 C 16.59375 13.445312 16.527344 13.460938 16.472656 13.480469 M 14.777344 13.578125 C
        14.699219 13.667969 14.65625 13.726562 14.667969 13.726562 C 14.675781 13.726562 14.71875 13.679688 14.765625 13.625 C 14.855469 13.511719 14.863281 13.480469
        14.777344 13.578125 M 16.210938 13.558594 C 16.144531 13.589844 16.144531 13.59375 16.199219 13.582031 C 16.273438 13.5625 16.355469 13.519531 16.316406
        13.523438 C 16.300781 13.523438 16.253906 13.539062 16.210938 13.558594 M 15.761719 13.78125 C 15.699219 13.824219 15.652344 13.863281 15.652344 13.871094 C
        15.652344 13.882812 15.796875 13.785156 15.871094 13.722656 C 15.933594 13.671875 15.855469 13.710938 15.761719 13.78125 M 8.046875 13.890625 L 7.96875
        13.976562 L 8.058594 13.898438 C 8.109375 13.855469 8.15625 13.816406 8.160156 13.8125 C 8.167969 13.808594 8.160156 13.804688 8.148438 13.804688 C 8.132812
        13.804688 8.085938 13.84375 8.046875 13.890625 M 15.574219 13.941406 C 15.496094 14.054688 15.492188 14.078125 15.558594 14 C 15.617188 13.933594 15.640625
        13.882812 15.621094 13.882812 C 15.617188 13.882812 15.59375 13.910156 15.574219 13.941406 M 6.898438 14.132812 C 6.867188 14.242188 6.878906 14.640625 6.914062
        14.714844 L 6.945312 14.78125 L 6.929688 14.714844 C 6.894531 14.554688 6.886719 14.304688 6.914062 14.195312 C 6.941406 14.066406 6.929688 14.015625 6.898438
        14.132812 M 7.863281 14.15625 C 7.84375 14.207031 7.828125 14.273438 7.828125 14.304688 C 7.832031 14.34375 7.839844 14.328125 7.855469 14.265625 C 7.867188
        14.210938 7.890625 14.144531 7.90625 14.117188 C 7.917969 14.089844 7.925781 14.066406 7.917969 14.066406 C 7.910156 14.066406 7.882812 14.105469 7.863281
        14.15625 M 14.472656 14.28125 C 14.464844 14.355469 14.464844 14.476562 14.476562 14.546875 C 14.496094 14.65625 14.496094 14.636719 14.496094 14.410156 C
        14.496094 14.105469 14.496094 14.089844 14.472656 14.28125 M 15.433594 14.1875 C 15.414062 14.210938 15.417969 14.53125 15.4375 14.589844 C 15.449219 14.617188
        15.457031 14.53125 15.457031 14.402344 C 15.457031 14.273438 15.457031 14.171875 15.453125 14.171875 C 15.453125 14.171875 15.445312 14.179688 15.433594 14.1875
        M 12.035156 14.683594 C 12.074219 14.757812 12.191406 14.839844 12.285156 14.855469 C 12.355469 14.871094 12.351562 14.867188 12.273438 14.839844 C 12.226562
        14.824219 12.144531 14.769531 12.097656 14.71875 C 12.039062 14.660156 12.015625 14.644531 12.035156 14.683594 M 17.832031 14.6875 C 17.847656 14.710938
        17.878906 14.75 17.90625 14.769531 C 17.933594 14.792969 17.925781 14.773438 17.886719 14.722656 C 17.820312 14.628906 17.792969 14.613281 17.832031 14.6875 M
        15.503906 14.71875 C 15.550781 14.792969 15.667969 14.863281 15.695312 14.835938 C 15.703125 14.828125 15.695312 14.820312 15.671875 14.820312 C 15.648438
        14.820312 15.59375 14.78125 15.546875 14.734375 C 15.476562 14.664062 15.46875 14.660156 15.503906 14.71875 M 7.917969 14.71875 C 7.933594 14.765625 8.113281
        14.871094 8.167969 14.871094 C 8.1875 14.871094 8.164062 14.851562 8.113281 14.832031 C 8.0625 14.808594 7.996094 14.765625 7.960938 14.734375 C 7.921875
        14.695312 7.90625 14.691406 7.917969 14.71875 M 13.992188 14.773438 C 14.035156 14.8125 14.082031 14.84375 14.097656 14.84375 C 14.117188 14.84375 14.109375
        14.832031 14.078125 14.816406 C 14.050781 14.800781 14.003906 14.769531 13.972656 14.746094 C 13.945312 14.722656 13.953125 14.734375 13.992188 14.773438 M
        8.539062 14.832031 C 8.484375 14.851562 8.460938 14.867188 8.488281 14.867188 C 8.519531 14.867188 8.582031 14.851562 8.632812 14.832031 C 8.75 14.789062
        8.679688 14.789062 8.539062 14.832031 M 10.492188 14.832031 C 10.535156 14.855469 10.585938 14.871094 10.609375 14.867188 C 10.628906 14.867188 10.605469
        14.851562 10.554688 14.832031 C 10.4375 14.789062 10.390625 14.789062 10.492188 14.832031 M 16.132812 14.832031 C 16.03125 14.871094 16.03125 14.871094
        16.121094 14.855469 C 16.226562 14.835938 16.335938 14.796875 16.277344 14.796875 C 16.253906 14.796875 16.191406 14.8125 16.132812 14.832031 M 17.980469
        14.816406 C 17.992188 14.832031 18.039062 14.851562 18.082031 14.859375 C 18.183594 14.882812 18.160156 14.863281 18.042969 14.824219 C 17.996094 14.804688
        17.964844 14.804688 17.980469 14.816406 M 6.941406 14.824219 C 6.941406 14.855469 7.019531 15.027344 7.035156 15.027344 C 7.039062 15.027344 7.027344 14.984375
        7.007812 14.933594 C 6.96875 14.839844 6.941406 14.796875 6.941406 14.824219 M 18.261719 15.222656 C 18.261719 15.421875 18.265625 15.5 18.269531 15.398438 C
        18.277344 15.292969 18.277344 15.128906 18.269531 15.035156 C 18.265625 14.9375 18.261719 15.023438 18.261719 15.222656 M 9.34375 15.085938 L 9.296875 15.144531
        L 9.355469 15.09375 C 9.40625 15.050781 9.421875 15.046875 9.46875 15.078125 C 9.511719 15.105469 9.519531 15.105469 9.511719 15.074219 C 9.492188 15.015625
        9.398438 15.023438 9.34375 15.085938 M 16.851562 15.164062 L 16.734375 15.300781 L 16.863281 15.179688 C 16.933594 15.113281 17.015625 15.054688 17.042969
        15.046875 C 17.085938 15.035156 17.082031 15.035156 17.03125 15.03125 C 16.984375 15.03125 16.933594 15.066406 16.851562 15.164062 M 17.109375 15.148438 C
        17.109375 15.164062 17.144531 15.222656 17.1875 15.277344 C 17.285156 15.398438 17.316406 15.429688 17.253906 15.339844 C 17.230469 15.304688 17.1875 15.238281
        17.160156 15.195312 C 17.132812 15.152344 17.109375 15.132812 17.109375 15.148438 M 7.125 15.167969 C 7.125 15.171875 7.160156 15.207031 7.207031 15.25 L
        7.292969 15.328125 L 7.214844 15.242188 C 7.144531 15.164062 7.125 15.144531 7.125 15.167969 M 9.582031 15.257812 C 9.609375 15.304688 9.65625 15.363281
        9.683594 15.386719 C 9.710938 15.414062 9.703125 15.386719 9.660156 15.328125 C 9.554688 15.183594 9.527344 15.15625 9.582031 15.257812 M 16.589844 15.367188 C
        16.539062 15.394531 16.507812 15.417969 16.519531 15.417969 C 16.546875 15.417969 16.691406 15.34375 16.691406 15.328125 C 16.691406 15.308594 16.691406
        15.308594 16.589844 15.367188 M 8.800781 15.457031 C 8.734375 15.488281 8.734375 15.492188 8.789062 15.480469 C 8.863281 15.460938 8.945312 15.417969 8.90625
        15.421875 C 8.890625 15.421875 8.84375 15.4375 8.800781 15.457031 M 7.699219 15.523438 C 7.722656 15.535156 7.792969 15.550781 7.855469 15.558594 C 7.960938
        15.574219 7.960938 15.570312 7.824219 15.535156 C 7.65625 15.492188 7.644531 15.488281 7.699219 15.523438 M 8.476562 15.535156 L 8.332031 15.570312 L 8.460938
        15.558594 C 8.53125 15.550781 8.613281 15.535156 8.640625 15.523438 C 8.722656 15.488281 8.640625 15.496094 8.476562 15.535156 M 11.230469 15.535156 C 11.132812
        15.558594 11.113281 15.570312 11.167969 15.570312 C 11.210938 15.570312 11.292969 15.554688 11.347656 15.535156 C 11.480469 15.492188 11.417969 15.492188
        11.230469 15.535156 M 11.699219 15.519531 C 11.722656 15.53125 11.828125 15.550781 11.933594 15.558594 C 12.128906 15.570312 12.128906 15.570312 11.972656
        15.546875 C 11.886719 15.535156 11.78125 15.519531 11.738281 15.511719 C 11.6875 15.5 11.671875 15.503906 11.699219 15.519531 M 13.609375 15.523438 C 13.652344
        15.535156 13.769531 15.554688 13.871094 15.558594 C 14.042969 15.570312 14.042969 15.570312 13.910156 15.550781 C 13.546875 15.496094 13.527344 15.496094
        13.609375 15.523438 M 14.976562 15.523438 C 14.921875 15.539062 14.921875 15.542969 14.964844 15.546875 C 14.992188 15.546875 15.03125 15.535156 15.054688
        15.523438 C 15.097656 15.492188 15.066406 15.492188 14.976562 15.523438 M 15.277344 15.523438 C 15.289062 15.535156 15.359375 15.550781 15.429688 15.558594 C
        15.5625 15.570312 15.539062 15.5625 15.355469 15.523438 C 15.300781 15.511719 15.265625 15.511719 15.277344 15.523438 M 16.042969 15.535156 C 15.945312
        15.558594 15.921875 15.570312 15.976562 15.570312 C 16.019531 15.570312 16.101562 15.554688 16.160156 15.535156 C 16.289062 15.492188 16.230469 15.492188
        16.042969 15.535156 M 17.511719 15.535156 C 17.554688 15.554688 17.605469 15.570312 17.628906 15.570312 C 17.648438 15.570312 17.625 15.554688 17.574219
        15.535156 C 17.457031 15.488281 17.410156 15.488281 17.511719 15.535156 M 6.464844 17.675781 C 6.484375 17.726562 6.519531 17.804688 6.539062 17.847656 C
        6.554688 17.890625 6.65625 18.132812 6.757812 18.382812 C 7.105469 19.21875 7.375 19.859375 7.503906 20.140625 C 7.539062 20.214844 7.566406 20.285156 7.566406
        20.292969 C 7.566406 20.300781 7.601562 20.378906 7.640625 20.46875 C 7.683594 20.558594 7.753906 20.71875 7.800781 20.824219 C 7.847656 20.933594 7.910156
        21.078125 7.945312 21.152344 C 7.976562 21.222656 8.097656 21.492188 8.210938 21.75 C 8.449219 22.289062 8.472656 22.3125 8.71875 22.328125 C 9.039062 22.347656
        9.859375 22.078125 10.984375 21.578125 C 11.011719 21.566406 11.105469 21.527344 11.1875 21.492188 C 11.269531 21.453125 11.34375 21.425781 11.347656 21.425781
        C 11.355469 21.425781 11.445312 21.382812 11.546875 21.335938 C 11.652344 21.289062 11.828125 21.210938 11.933594 21.164062 C 12.042969 21.117188 12.226562
        21.035156 12.34375 20.976562 C 12.464844 20.921875 12.566406 20.878906 12.570312 20.878906 C 12.574219 20.878906 12.742188 20.800781 12.9375 20.707031 C
        13.136719 20.617188 13.34375 20.519531 13.398438 20.492188 L 13.5 20.445312 L 13.140625 20.300781 C 11.835938 19.78125 10.695312 19.320312 10.570312 19.265625 C
        10.511719 19.242188 10.40625 19.199219 10.335938 19.175781 C 10.261719 19.148438 10.191406 19.117188 10.179688 19.109375 C 10.164062 19.097656 10.125 19.082031
        10.089844 19.070312 C 10.050781 19.0625 9.988281 19.035156 9.945312 19.015625 C 9.902344 18.996094 9.585938 18.867188 9.242188 18.730469 C 8.898438 18.59375
        8.46875 18.417969 8.28125 18.34375 C 8.09375 18.265625 7.808594 18.152344 7.644531 18.085938 C 7.480469 18.019531 7.320312 17.953125 7.292969 17.941406 C
        7.265625 17.925781 7.113281 17.863281 6.953125 17.800781 C 6.796875 17.738281 6.613281 17.660156 6.546875 17.632812 L 6.425781 17.578125 L 6.464844 17.675781 M
        17.328125 18.550781 C 17.300781 18.570312 17.289062 18.589844 17.304688 18.589844 C 17.316406 18.589844 17.351562 18.570312 17.378906 18.550781 C 17.40625
        18.527344 17.417969 18.511719 17.402344 18.511719 C 17.390625 18.511719 17.355469 18.527344 17.328125 18.550781 M 17.148438 18.640625 C 17.046875 18.710938
        17.082031 18.710938 17.191406 18.640625 C 17.238281 18.613281 17.265625 18.589844 17.25 18.589844 C 17.238281 18.589844 17.191406 18.613281 17.148438 18.640625
        M 16.78125 18.824219 C 16.679688 18.890625 16.71875 18.890625 16.828125 18.824219 C 16.875 18.796875 16.902344 18.773438 16.886719 18.773438 C 16.871094
        18.773438 16.824219 18.796875 16.78125 18.824219 M 16.417969 19.007812 C 16.375 19.035156 16.351562 19.058594 16.367188 19.058594 C 16.382812 19.058594
        16.429688 19.035156 16.472656 19.007812 C 16.515625 18.976562 16.539062 18.953125 16.523438 18.953125 C 16.507812 18.953125 16.460938 18.976562 16.417969
        19.007812 M 16.078125 19.175781 C 16.050781 19.195312 16.039062 19.210938 16.054688 19.210938 C 16.070312 19.210938 16.105469 19.195312 16.132812 19.175781 C
        16.160156 19.152344 16.171875 19.136719 16.15625 19.136719 C 16.140625 19.136719 16.105469 19.152344 16.078125 19.175781 M 15.898438 19.265625 C 15.796875
        19.332031 15.832031 19.332031 15.945312 19.265625 C 15.992188 19.238281 16.015625 19.214844 16.003906 19.214844 C 15.988281 19.214844 15.941406 19.238281
        15.898438 19.265625 "
      />
      <path
        stroke="none"
        fillRule="evenodd"
        fill="rgb(97.254902%,97.647059%,97.647059%)"
        fillOpacity="1"
        d="M 11.882812 3.351562 C 11.75 3.394531 11.640625 3.449219 11.472656 3.554688 C 11.339844 3.636719 10.945312 3.957031 10.945312
        3.980469 C 10.945312 3.992188 10.933594 4.003906 10.921875 4.003906 C 10.859375 4.003906 9.773438 5.144531 9.375 5.628906 C 9.300781 5.714844 9.203125 5.832031
        9.152344 5.890625 C 8.996094 6.070312 8.9375 6.140625 8.761719 6.355469 C 8.671875 6.472656 8.589844 6.570312 8.578125 6.578125 C 8.570312 6.585938 8.507812
        6.664062 8.441406 6.75 L 8.320312 6.90625 L 7.859375 6.9375 C 7.605469 6.953125 7.199219 6.980469 6.953125 6.996094 C 6.710938 7.011719 6.277344 7.039062
        5.992188 7.058594 C 5.707031 7.078125 5.285156 7.109375 5.058594 7.125 C 3.828125 7.203125 2.976562 7.292969 2.894531 7.347656 C 2.761719 7.429688 2.730469
        7.492188 2.730469 7.644531 C 2.730469 7.886719 2.917969 8.738281 3.121094 9.425781 C 3.148438 9.523438 3.1875 9.652344 3.199219 9.710938 C 3.214844 9.769531
        3.253906 9.898438 3.285156 9.996094 C 3.320312 10.097656 3.378906 10.277344 3.414062 10.398438 C 3.535156 10.785156 3.867188 11.78125 3.964844 12.050781 C
        4.121094 12.476562 4.148438 12.371094 3.714844 12.988281 C 3.617188 13.128906 3.496094 13.300781 3.453125 13.363281 C 3.304688 13.574219 2.507812 14.773438
        2.277344 15.132812 C 1.886719 15.730469 1.863281 15.765625 1.808594 15.828125 C 1.746094 15.902344 1.75 15.90625 2.066406 16.042969 C 2.210938 16.101562
        2.339844 16.160156 2.351562 16.171875 C 2.367188 16.179688 2.441406 16.214844 2.523438 16.25 C 2.664062 16.308594 3.6875 16.757812 3.886719 16.847656 C 4.015625
        16.90625 5.039062 17.347656 5.421875 17.507812 C 5.578125 17.578125 5.753906 17.652344 5.8125 17.679688 C 5.867188 17.703125 5.96875 17.746094 6.039062
        17.773438 C 6.160156 17.824219 6.164062 17.824219 6.28125 18.109375 C 6.347656 18.265625 6.417969 18.429688 6.4375 18.472656 C 6.457031 18.515625 6.519531
        18.667969 6.578125 18.8125 C 6.761719 19.25 7.09375 20.039062 7.152344 20.164062 C 7.183594 20.238281 7.53125 21.035156 7.632812 21.269531 C 7.675781 21.375
        7.757812 21.554688 7.808594 21.664062 C 7.863281 21.777344 7.902344 21.871094 7.902344 21.878906 C 7.902344 21.882812 7.949219 21.988281 8.007812 22.109375 C
        8.066406 22.230469 8.113281 22.339844 8.113281 22.351562 C 8.121094 22.574219 8.644531 22.722656 9.089844 22.628906 C 9.371094 22.574219 10.148438 22.304688
        10.542969 22.125 C 10.570312 22.113281 10.671875 22.070312 10.765625 22.035156 C 10.855469 21.996094 10.984375 21.941406 11.050781 21.914062 C 11.113281
        21.882812 11.210938 21.84375 11.269531 21.824219 C 11.324219 21.804688 11.382812 21.777344 11.398438 21.765625 C 11.414062 21.753906 11.457031 21.734375
        11.488281 21.722656 C 11.523438 21.710938 11.640625 21.664062 11.75 21.613281 C 11.929688 21.53125 12.042969 21.480469 12.335938 21.347656 C 12.394531 21.320312
        12.542969 21.25 12.671875 21.191406 C 12.796875 21.132812 12.90625 21.085938 12.910156 21.085938 C 12.917969 21.085938 13.046875 21.027344 13.195312 20.957031 C
        13.535156 20.796875 13.527344 20.796875 13.714844 20.878906 C 13.765625 20.898438 13.957031 20.96875 14.417969 21.136719 C 14.445312 21.148438 14.535156
        21.179688 14.613281 21.210938 C 14.691406 21.238281 14.84375 21.292969 14.949219 21.332031 C 15.058594 21.367188 15.261719 21.4375 15.40625 21.488281 C
        16.511719 21.882812 18.109375 22.367188 18.304688 22.371094 C 18.53125 22.371094 18.640625 22.222656 18.691406 21.839844 C 18.710938 21.71875 18.734375
        21.542969 18.746094 21.449219 C 18.761719 21.355469 18.785156 21.175781 18.796875 21.046875 C 18.84375 20.59375 18.902344 20.054688 18.941406 19.773438 C
        18.960938 19.617188 18.992188 19.375 19.007812 19.238281 C 19.023438 19.105469 19.050781 18.886719 19.070312 18.757812 C 19.089844 18.628906 19.121094 18.410156
        19.140625 18.269531 C 19.175781 17.976562 19.160156 17.996094 19.511719 17.808594 C 20.136719 17.480469 21.167969 16.90625 21.554688 16.679688 C 21.632812
        16.632812 21.738281 16.570312 21.789062 16.542969 C 21.839844 16.511719 22.03125 16.398438 22.21875 16.28125 C 23.851562 15.285156 24.121094 15.003906 23.894531
        14.5625 C 23.832031 14.4375 23.554688 14.066406 23.527344 14.066406 C 23.515625 14.066406 23.503906 14.046875 23.503906 14.027344 C 23.503906 14.003906
        23.496094 13.988281 23.484375 13.988281 C 23.472656 13.988281 23.40625 13.921875 23.335938 13.839844 C 23.214844 13.691406 22.917969 13.367188 22.554688
        12.972656 C 22.457031 12.867188 21.992188 12.394531 21.523438 11.921875 C 20.679688 11.070312 20.671875 11.0625 20.695312 10.984375 C 20.726562 10.894531 20.875
        10.394531 20.902344 10.28125 C 20.921875 10.214844 21.210938 9.25 21.308594 8.9375 C 21.40625 8.628906 21.691406 7.675781 21.710938 7.605469 C 21.722656 7.5625
        21.746094 7.472656 21.769531 7.410156 C 21.789062 7.34375 21.835938 7.207031 21.867188 7.097656 C 21.925781 6.894531 22.0625 6.4375 22.101562 6.308594 C
        22.117188 6.265625 22.125 6.226562 22.125 6.222656 C 22.125 6.203125 21.636719 6.214844 20.957031 6.25 C 20.5625 6.273438 19.925781 6.300781 19.539062 6.316406
        C 18.042969 6.375 17.484375 6.398438 16.714844 6.4375 C 16.171875 6.464844 15.902344 6.472656 15.871094 6.453125 C 15.824219 6.429688 15.582031 6.210938
        14.867188 5.539062 C 14.390625 5.097656 13.933594 4.667969 13.714844 4.472656 C 13.613281 4.378906 13.527344 4.296875 13.519531 4.289062 C 13.484375 4.25
        12.84375 3.6875 12.691406 3.566406 C 12.367188 3.304688 12.183594 3.257812 11.882812 3.351562 M 12.367188 3.734375 C 12.535156 3.859375 12.769531 4.058594
        12.960938 4.238281 C 13.046875 4.316406 13.214844 4.46875 13.335938 4.574219 C 13.457031 4.683594 13.574219 4.789062 13.59375 4.808594 C 13.617188 4.832031
        13.71875 4.921875 13.820312 5.015625 C 13.917969 5.105469 14.101562 5.277344 14.222656 5.394531 C 14.34375 5.507812 14.578125 5.726562 14.742188 5.878906 C
        14.90625 6.027344 15.195312 6.296875 15.386719 6.480469 L 15.730469 6.808594 L 15.957031 6.796875 C 16.082031 6.792969 16.511719 6.773438 16.914062 6.761719 C
        17.3125 6.746094 18.003906 6.714844 18.445312 6.695312 C 18.890625 6.675781 19.570312 6.644531 19.953125 6.628906 C 20.339844 6.613281 20.878906 6.589844
        21.148438 6.578125 C 21.421875 6.566406 21.648438 6.558594 21.65625 6.566406 C 21.671875 6.585938 21.566406 6.980469 21.4375 7.371094 C 21.398438 7.492188
        21.324219 7.734375 21.242188 8.007812 C 21.210938 8.117188 21.15625 8.296875 21.121094 8.410156 C 21.085938 8.527344 20.972656 8.894531 20.871094 9.230469 C
        20.773438 9.566406 20.675781 9.894531 20.65625 9.957031 C 20.597656 10.144531 20.339844 11.015625 20.3125 11.101562 C 20.308594 11.128906 20.535156 11.378906
        20.957031 11.804688 C 21.667969 12.523438 22.644531 13.550781 22.957031 13.90625 C 23.0625 14.03125 23.15625 14.136719 23.164062 14.144531 C 23.289062 14.257812
        23.601562 14.691406 23.625 14.78125 C 23.652344 14.894531 23.296875 15.207031 22.699219 15.59375 C 22.535156 15.699219 21.566406 16.289062 21.390625 16.390625 C
        20.941406 16.648438 20.589844 16.847656 20.578125 16.847656 C 20.574219 16.847656 20.511719 16.882812 20.445312 16.921875 C 20.320312 16.992188 20.128906
        17.101562 19.792969 17.28125 C 19.695312 17.332031 19.582031 17.394531 19.539062 17.421875 C 19.496094 17.445312 19.34375 17.527344 19.199219 17.601562 C
        18.871094 17.773438 18.859375 17.785156 18.839844 17.949219 C 18.832031 18.023438 18.808594 18.210938 18.785156 18.367188 C 18.765625 18.527344 18.730469
        18.800781 18.707031 18.980469 C 18.683594 19.160156 18.652344 19.433594 18.628906 19.589844 C 18.609375 19.75 18.582031 20 18.5625 20.148438 C 18.546875
        20.300781 18.519531 20.546875 18.5 20.695312 C 18.480469 20.847656 18.449219 21.097656 18.433594 21.253906 C 18.402344 21.574219 18.339844 21.988281 18.316406
        22.019531 C 18.300781 22.046875 18.1875 22.023438 17.875 21.929688 C 17.753906 21.894531 17.550781 21.835938 17.425781 21.800781 C 17.167969 21.726562 17.128906
        21.714844 16.222656 21.414062 C 15.859375 21.292969 15.414062 21.140625 15.234375 21.078125 C 14.691406 20.882812 14.191406 20.703125 14 20.632812 C 13.902344
        20.59375 13.746094 20.539062 13.660156 20.507812 L 13.5 20.453125 L 13.359375 20.511719 C 13.285156 20.546875 13.078125 20.640625 12.898438 20.726562 C
        12.722656 20.808594 12.574219 20.878906 12.570312 20.878906 C 12.566406 20.878906 12.464844 20.921875 12.34375 20.976562 C 12.226562 21.035156 12.042969
        21.117188 11.933594 21.164062 C 11.828125 21.210938 11.652344 21.289062 11.546875 21.335938 C 11.445312 21.382812 11.355469 21.425781 11.347656 21.425781 C
        11.34375 21.425781 11.269531 21.453125 11.1875 21.492188 C 11.105469 21.527344 11.011719 21.566406 10.984375 21.578125 C 9.859375 22.078125 9.039062 22.347656
        8.71875 22.328125 C 8.472656 22.3125 8.449219 22.289062 8.210938 21.75 C 8.097656 21.492188 7.976562 21.222656 7.945312 21.152344 C 7.910156 21.078125 7.847656
        20.933594 7.800781 20.824219 C 7.753906 20.71875 7.683594 20.558594 7.640625 20.46875 C 7.601562 20.378906 7.566406 20.300781 7.566406 20.292969 C 7.566406
        20.285156 7.539062 20.214844 7.503906 20.140625 C 7.375 19.859375 7.105469 19.21875 6.757812 18.382812 C 6.65625 18.132812 6.554688 17.890625 6.539062 17.847656
        C 6.519531 17.804688 6.484375 17.726562 6.464844 17.675781 C 6.425781 17.582031 6.210938 17.445312 6.097656 17.445312 C 6.074219 17.445312 6.058594 17.433594
        6.058594 17.421875 C 6.058594 17.40625 6.046875 17.394531 6.03125 17.394531 C 5.996094 17.394531 5.570312 17.21875 5.246094 17.070312 C 5.199219 17.050781
        5.101562 17.007812 5.03125 16.976562 C 4.960938 16.945312 4.847656 16.898438 4.785156 16.871094 C 4.398438 16.699219 4.265625 16.640625 4.25 16.640625 C
        4.246094 16.640625 4.140625 16.59375 4.015625 16.535156 C 3.894531 16.480469 3.789062 16.433594 3.78125 16.433594 C 3.765625 16.433594 3.648438 16.378906
        3.296875 16.214844 C 3.183594 16.164062 3.085938 16.121094 3.078125 16.121094 C 3.070312 16.121094 3.003906 16.09375 2.929688 16.058594 C 2.855469 16.023438
        2.679688 15.949219 2.542969 15.886719 C 2.253906 15.761719 2.257812 15.769531 2.371094 15.59375 C 2.515625 15.371094 3.292969 14.191406 3.378906 14.066406 C
        3.421875 14 3.640625 13.6875 3.863281 13.363281 C 4.085938 13.042969 4.3125 12.714844 4.371094 12.632812 L 4.472656 12.488281 L 4.410156 12.320312 C 4.261719
        11.925781 3.703125 10.234375 3.542969 9.691406 C 3.414062 9.25 3.429688 9.308594 3.269531 8.683594 C 3.167969 8.289062 3.105469 8.019531 3.082031 7.863281 C
        3.070312 7.808594 3.058594 7.734375 3.050781 7.703125 C 3.03125 7.625 3.136719 7.601562 3.613281 7.566406 C 4.21875 7.523438 4.632812 7.492188 5.160156 7.449219
        C 5.433594 7.429688 5.871094 7.398438 6.136719 7.382812 C 6.402344 7.367188 6.84375 7.339844 7.125 7.320312 C 7.402344 7.296875 7.777344 7.273438 7.957031
        7.265625 C 8.503906 7.238281 8.46875 7.25 8.683594 6.980469 C 8.785156 6.851562 8.894531 6.71875 8.925781 6.683594 C 8.957031 6.644531 9.027344 6.558594
        9.085938 6.488281 C 9.140625 6.414062 9.214844 6.328125 9.246094 6.292969 C 9.277344 6.257812 9.34375 6.179688 9.390625 6.121094 C 9.492188 5.992188 9.925781
        5.496094 10.074219 5.328125 C 10.136719 5.265625 10.265625 5.125 10.363281 5.019531 C 11.417969 3.867188 12.027344 3.476562 12.367188 3.734375 M 11.777344
        9.820312 C 11.527344 9.863281 11.277344 9.984375 11.183594 10.105469 C 11.128906 10.171875 11.128906 10.183594 11.128906 12.523438 L 11.128906 14.875 L
        10.820312 14.867188 C 10.210938 14.851562 10.210938 14.851562 10.1875 13.441406 C 10.179688 12.816406 10.160156 12.324219 10.144531 12.273438 C 10.078125
        12.046875 10.023438 11.910156 9.945312 11.789062 C 9.851562 11.644531 9.6875 11.464844 9.644531 11.464844 C 9.632812 11.464844 9.621094 11.457031 9.621094
        11.441406 C 9.621094 11.429688 9.535156 11.378906 9.429688 11.328125 C 8.863281 11.050781 7.773438 11.175781 7.308594 11.5625 L 7.226562 11.636719 L 7.382812
        11.925781 C 7.539062 12.210938 7.570312 12.25 7.605469 12.191406 C 7.734375 11.980469 8.558594 11.859375 8.871094 12.003906 C 9.144531 12.132812 9.3125
        12.386719 9.316406 12.6875 L 9.320312 12.855469 L 9.242188 12.863281 C 8.84375 12.894531 8.578125 12.9375 8.136719 13.054688 C 7.140625 13.3125 6.644531
        14.160156 7.023438 14.964844 C 7.363281 15.6875 8.8125 15.785156 9.320312 15.117188 C 9.40625 15.003906 9.476562 15 9.527344 15.113281 C 9.59375 15.269531
        9.714844 15.40625 9.855469 15.484375 L 9.996094 15.5625 L 10.515625 15.570312 C 10.960938 15.578125 11.074219 15.570312 11.289062 15.523438 C 11.527344
        15.476562 11.546875 15.476562 11.753906 15.515625 C 12.082031 15.578125 13.070312 15.578125 13.242188 15.515625 C 13.359375 15.472656 13.378906 15.472656
        13.644531 15.515625 C 14.027344 15.574219 14.832031 15.578125 15.011719 15.515625 C 15.128906 15.476562 15.152344 15.476562 15.363281 15.523438 C 15.90625
        15.644531 16.5625 15.484375 16.855469 15.160156 C 17 14.996094 17.058594 14.992188 17.121094 15.132812 C 17.257812 15.4375 17.519531 15.574219 17.976562
        15.574219 L 18.253906 15.574219 L 18.253906 14.871094 L 18.167969 14.871094 C 18.050781 14.871094 17.914062 14.792969 17.84375 14.691406 C 17.785156 14.605469
        17.785156 14.601562 17.785156 13.570312 C 17.785156 12.183594 17.734375 11.953125 17.34375 11.570312 C 16.832031 11.0625 15.726562 11.03125 14.992188 11.5 C
        14.808594 11.621094 14.808594 11.628906 15.003906 11.980469 C 15.136719 12.214844 15.164062 12.246094 15.199219 12.191406 C 15.238281 12.125 15.4375 12.03125
        15.632812 11.984375 C 16.34375 11.816406 16.863281 12.09375 16.917969 12.675781 C 16.9375 12.867188 16.972656 12.847656 16.523438 12.894531 C 15.34375 13.023438
        14.59375 13.507812 14.507812 14.195312 C 14.484375 14.382812 14.503906 14.722656 14.546875 14.828125 C 14.5625 14.867188 14.546875 14.871094 14.359375 14.871094
        C 14.109375 14.871094 14.003906 14.824219 13.898438 14.664062 L 13.820312 14.546875 L 13.8125 12.914062 L 13.804688 11.285156 L 12.949219 11.285156 L 12.949219
        14.875 L 12.589844 14.867188 C 11.910156 14.851562 11.960938 15.074219 11.960938 12.136719 L 11.960938 9.800781 L 11.902344 9.804688 C 11.871094 9.808594
        11.8125 9.8125 11.777344 9.820312 M 12.964844 9.820312 C 12.957031 9.828125 12.949219 10.035156 12.949219 10.273438 L 12.949219 10.710938 L 13.804688 10.710938
        L 13.804688 10.175781 L 13.707031 10.078125 C 13.542969 9.910156 13.046875 9.738281 12.964844 9.820312 M 9.335938 13.933594 L 9.335938 14.445312 L 9.261719
        14.511719 C 9.058594 14.703125 8.617188 14.871094 8.324219 14.871094 C 7.617188 14.871094 7.65625 14.003906 8.382812 13.660156 C 8.640625 13.539062 9.242188
        13.375 9.316406 13.410156 C 9.324219 13.414062 9.335938 13.648438 9.335938 13.933594 M 16.925781 13.90625 C 16.925781 14.507812 16.929688 14.496094 16.648438
        14.652344 C 15.964844 15.039062 15.417969 14.917969 15.417969 14.386719 C 15.417969 13.882812 15.878906 13.574219 16.90625 13.394531 C 16.917969 13.390625
        16.925781 13.621094 16.925781 13.90625 "
      />
      <path
        stroke="none"
        fillRule="evenodd"
        fill="rgb(10.980392%,36.078431%,20%)"
        fillOpacity="1"
        d="M 15.339844 6.828125 C 15.191406 6.835938 14.261719 6.894531 13.703125 6.929688 C 13.59375 6.9375 13.367188 6.949219 13.195312 6.957031 C 13.023438 6.964844 12.824219 6.976562 
        12.753906 6.980469 C 12.679688 6.988281 12.40625 7.007812 12.140625 7.019531 C 11.878906 7.035156 11.386719 7.0625 11.050781 7.085938 C 10.714844 7.105469 10.304688 7.128906 10.140625
        7.136719 C 9.976562 7.144531 9.699219 7.164062 9.527344 7.175781 C 9.355469 7.1875 9.054688 7.207031 8.851562 7.214844 L 8.488281 7.234375 L 8.28125 7.445312 C 7.914062 7.820312
        7.207031 8.710938 6.539062 9.632812 C 6.519531 9.660156 6.421875 9.792969 6.324219 9.925781 C 5.839844 10.585938 4.609375 12.34375 4.527344 12.5 C 4.503906 12.546875 4.527344
        12.632812 4.683594 13.054688 C 4.785156 13.332031 4.882812 13.601562 4.898438 13.648438 C 5.046875 14.054688 5.359375 14.894531 5.378906 14.9375 C 5.402344 14.984375 5.488281
        15.203125 5.652344 15.640625 C 5.691406 15.738281 5.734375 15.84375 5.746094 15.871094 C 5.757812 15.902344 5.816406 16.046875 5.875 16.199219 C 5.933594 16.347656 6 16.507812
        6.015625 16.550781 C 6.035156 16.59375 6.09375 16.738281 6.148438 16.875 C 6.199219 17.011719 6.257812 17.15625 6.277344 17.199219 C 6.296875 17.242188 6.339844 17.347656 6.371094
        17.429688 C 6.441406 17.601562 6.40625 17.578125 6.953125 17.800781 C 7.113281 17.863281 7.265625 17.925781 7.292969 17.941406 C 7.320312 17.953125 7.480469 18.019531 7.644531
        18.085938 C 7.808594 18.152344 8.09375 18.265625 8.28125 18.34375 C 8.46875 18.417969 8.898438 18.59375 9.242188 18.730469 C 9.585938 18.867188 9.902344 19 9.949219 19.019531 C
        9.992188 19.039062 10.039062 19.058594 10.054688 19.058594 C 10.066406 19.058594 10.105469 19.074219 10.140625 19.089844 C 10.175781 19.109375 10.261719 19.148438 10.335938
        19.171875 C 10.40625 19.199219 10.511719 19.242188 10.570312 19.265625 C 10.679688 19.316406 11.300781 19.566406 12.039062 19.859375 C 12.289062 19.960938 12.714844 20.128906
        12.984375 20.238281 C 13.339844 20.382812 13.496094 20.433594 13.546875 20.421875 C 13.582031 20.414062 14.792969 19.816406 16.242188 19.097656 L 18.871094 17.785156 L
        18.886719 17.648438 C 18.90625 17.511719 18.960938 17.191406 19.007812 16.9375 C 19.019531 16.867188 19.054688 16.6875 19.082031 16.535156 C 19.179688 16.015625 19.34375
        15.214844 19.394531 15.015625 C 19.410156 14.957031 19.460938 14.71875 19.511719 14.480469 C 19.613281 14.027344 19.683594 13.71875 19.828125 13.105469 C 19.878906 12.898438
        19.941406 12.632812 19.96875 12.519531 C 20.019531 12.304688 20.207031 11.523438 20.257812 11.304688 C 20.300781 11.132812 20.316406 11.15625 19.523438 10.398438 C 19.140625
        10.035156 18.691406 9.605469 18.53125 9.445312 C 18.371094 9.28125 18.050781 8.980469 17.824219 8.769531 C 17.59375 8.558594 17.289062 8.277344 17.148438 8.140625 C 17.003906
        8.003906 16.800781 7.808594 16.691406 7.710938 C 16.585938 7.613281 16.355469 7.402344 16.183594 7.242188 C 15.730469 6.816406 15.726562 6.8125 15.609375 6.816406 C 15.554688
        6.820312 15.433594 6.824219 15.339844 6.828125 M 11.992188 12.199219 L 12 14.597656 L 12.0625 14.683594 C 12.167969 14.824219 12.273438 14.859375 12.617188 14.867188 L 12.921875
        14.875 L 12.929688 13.074219 L 12.933594 11.269531 L 13.371094 11.265625 L 13.804688 11.257812 L 13.804688 12.851562 C 13.808594 14.585938 13.804688 14.558594 13.957031 14.726562
        C 14.089844 14.871094 14.558594 14.945312 14.523438 14.8125 C 14.378906 14.214844 14.542969 13.714844 15.003906 13.382812 C 15.289062 13.175781 15.796875 12.980469 16.1875 12.921875
        C 16.285156 12.910156 16.429688 12.886719 16.507812 12.871094 C 16.585938 12.855469 16.707031 12.84375 16.777344 12.84375 L 16.90625 12.84375 L 16.894531 12.679688 C 16.878906
        12.421875 16.714844 12.15625 16.503906 12.046875 C 16.183594 11.882812 15.4375 11.964844 15.210938 12.195312 C 15.132812 12.269531 15.128906 12.265625 14.867188 11.757812 C 14.792969
        11.613281 14.808594 11.589844 15.050781 11.449219 C 15.28125 11.316406 15.429688 11.261719 15.664062 11.222656 C 15.765625 11.203125 15.867188 11.179688 15.890625 11.171875 C
        15.960938 11.136719 16.617188 11.167969 16.769531 11.210938 C 17.230469 11.335938 17.5625 11.671875 17.722656 12.167969 C 17.761719 12.292969 17.769531 12.457031 17.785156 13.457031
        L 17.796875 14.601562 L 17.867188 14.695312 C 17.9375 14.785156 18.113281 14.871094 18.230469 14.871094 C 18.273438 14.871094 18.277344 14.902344 18.277344 15.222656 L 18.277344 15.574219
        L 17.933594 15.574219 C 17.476562 15.574219 17.203125 15.429688 17.105469 15.140625 C 17.066406 15.019531 17.023438 15.03125 16.839844 15.199219 C 16.550781 15.46875 16.179688 15.585938
        15.664062 15.570312 C 15.429688 15.5625 15.308594 15.546875 15.277344 15.523438 C 15.234375 15.488281 15.152344 15.496094 14.964844 15.546875 C 14.789062 15.589844 13.90625 15.582031
        13.640625 15.53125 C 13.4375 15.492188 13.398438 15.492188 13.289062 15.523438 C 13.101562 15.582031 11.835938 15.578125 11.671875 15.515625 C 11.558594 15.476562 11.542969 15.476562
        11.386719 15.523438 C 11.234375 15.570312 11.160156 15.574219 10.582031 15.570312 L 9.945312 15.5625 L 9.796875 15.464844 C 9.6875 15.394531 9.625 15.328125 9.566406 15.222656 C
        9.453125 15.027344 9.433594 15.023438 9.28125 15.175781 C 8.597656 15.875 7.21875 15.65625 6.9375 14.808594 C 6.65625 13.953125 7.242188 13.222656 8.410156 12.964844 C 8.777344
        12.886719 8.875 12.871094 9.09375 12.851562 L 9.316406 12.835938 L 9.300781 12.660156 C 9.257812 12.164062 8.871094 11.894531 8.300781 11.964844 C 7.921875 12.011719 7.773438
        12.058594 7.640625 12.1875 C 7.542969 12.277344 7.558594 12.300781 7.300781 11.804688 C 7.199219 11.605469 7.21875 11.570312 7.527344 11.40625 C 8.285156 11.007812 9.292969
        11.074219 9.761719 11.554688 C 10.160156 11.957031 10.199219 12.121094 10.21875 13.496094 C 10.230469 14.648438 10.226562 14.597656 10.359375 14.730469 C 10.453125 14.824219
        10.65625 14.875 10.882812 14.867188 L 11.089844 14.859375 L 11.101562 12.519531 C 11.113281 10.332031 11.117188 10.171875 11.160156 10.105469 C 11.261719 9.949219 11.589844
        9.804688 11.847656 9.804688 L 11.984375 9.800781 L 11.992188 12.199219 M 13.375 9.859375 C 13.503906 9.902344 13.585938 9.949219 13.679688 10.035156 L 13.804688 10.15625 L
        13.804688 10.738281 L 13.371094 10.730469 L 12.933594 10.726562 L 12.929688 10.296875 C 12.925781 10.0625 12.925781 9.855469 12.933594 9.835938 C 12.953125 9.78125 13.175781
        9.792969 13.375 9.859375 M 9.191406 13.433594 C 8.222656 13.546875 7.558594 14.222656 7.9375 14.707031 C 8.140625 14.964844 8.796875 14.875 9.21875 14.527344 L 9.308594
        14.453125 L 9.308594 13.933594 C 9.308594 13.648438 9.300781 13.417969 9.289062 13.417969 C 9.277344 13.421875 9.234375 13.429688 9.191406 13.433594 M 16.78125 13.433594
        C 16.273438 13.496094 15.726562 13.757812 15.546875 14.019531 C 15.367188 14.28125 15.425781 14.699219 15.652344 14.808594 C 15.921875 14.941406 16.617188 14.753906 16.851562
        14.488281 L 16.925781 14.402344 L 16.925781 13.910156 C 16.925781 13.53125 16.917969 13.417969 16.894531 13.417969 C 16.875 13.421875 16.824219 13.429688 16.78125 13.433594 "
      />
      <path
        stroke="none"
        fillRule="evenodd"
        fill="rgb(17.254902%,73.72549%,79.607843%)"
        fillOpacity="1"
        d="M 11.957031 3.675781 C 11.613281 3.800781 11.03125 4.289062 10.363281 5.019531 C 10.265625 5.125 10.136719 5.265625 10.074219 5.328125 C 9.925781 5.496094 9.492188 5.992188
        9.390625 6.121094 C 9.34375 6.179688 9.28125 6.257812 9.246094 6.292969 C 9.214844 6.328125 9.144531 6.414062 9.085938 6.488281 C 9.03125 6.558594 8.972656 6.628906 8.957031
        6.644531 C 8.921875 6.675781 8.542969 7.152344 8.515625 7.199219 C 8.492188 7.238281 8.417969 7.242188 9.671875 7.164062 C 10.007812 7.140625 10.410156 7.117188 10.570312 7.109375
        C 10.726562 7.101562 11.007812 7.085938 11.191406 7.074219 C 11.378906 7.058594 11.660156 7.042969 11.816406 7.03125 C 11.972656 7.023438 12.390625 7 12.738281 6.980469 C 13.089844
         6.960938 13.511719 6.9375 13.675781 6.929688 C 13.839844 6.917969 14.226562 6.898438 14.535156 6.878906 C 14.839844 6.855469 15.234375 6.832031 15.410156 6.824219 L 15.726562 6.804688
          L 15.410156 6.5 C 15.234375 6.335938 14.941406 6.058594 14.753906 5.890625 C 14.570312 5.71875 14.324219 5.492188 14.210938 5.382812 C 14.09375 5.269531 13.917969 5.105469 13.820312
           5.015625 C 13.71875 4.921875 13.617188 4.832031 13.59375 4.808594 C 13.574219 4.789062 13.457031 4.683594 13.335938 4.574219 C 13.214844 4.46875 13.046875 4.316406 12.960938
            4.238281 C 12.335938 3.660156 12.203125 3.585938 11.957031 3.675781 M 18.695312 17.867188 C 18.265625 18.105469 14.441406 20.019531 14.390625 20.019531 C 14.386719 20.019531 
            14.183594 20.117188 13.941406 20.238281 L 13.5 20.453125 L 13.660156 20.507812 C 13.746094 20.539062 13.902344 20.59375 14 20.632812 C 14.191406 20.703125 14.691406 20.882812 
            15.234375 21.078125 C 15.414062 21.140625 15.859375 21.292969 16.222656 21.414062 C 17.128906 21.714844 17.167969 21.726562 17.425781 21.800781 C 17.550781 21.835938 17.753906
            21.894531 17.875 21.929688 C 18.1875 22.023438 18.300781 22.046875 18.316406 22.019531 C 18.339844 21.988281 18.402344 21.574219 18.433594 21.253906 C 18.449219 21.097656 18.480469
            20.847656 18.5 20.695312 C 18.519531 20.546875 18.546875 20.300781 18.5625 20.148438 C 18.582031 20 18.609375 19.75 18.628906 19.589844 C 18.652344 19.433594 18.683594 19.164062
            18.707031 18.992188 C 18.742188 18.6875 18.804688 18.226562 18.847656 17.933594 C 18.871094 17.757812 18.878906 17.761719 18.695312 17.867188 "
      />
      <path
        stroke="none"
        fillRule="evenodd"
        fill="rgb(91.372549%,50.588235%,7.45098%)"
        fillOpacity="1"
        d="M 21.058594 6.582031 C 20.824219 6.59375 20.320312 6.613281 19.941406 6.628906 C 19.269531 6.65625 18.136719 6.707031 17.003906 6.761719 C 16.695312 6.773438 16.328125
        6.785156 16.183594 6.785156 C 15.917969 6.785156 15.753906 6.804688 15.757812 6.835938 C 15.757812 6.84375 15.949219 7.023438 16.179688 7.234375 C 17.148438 8.117188 18.210938
        9.117188 18.730469 9.632812 C 19.527344 10.421875 20.289062 11.128906 20.308594 11.097656 C 20.320312 11.078125 20.371094 10.917969 20.421875 10.738281 C 20.527344 10.386719 20.597656
        10.140625 20.65625 9.957031 C 20.675781 9.894531 20.773438 9.566406 20.871094 9.230469 C 20.972656 8.894531 21.085938 8.527344 21.121094 8.410156 C 21.15625 8.296875 21.210938 8.117188 
        21.242188 8.007812 C 21.347656 7.648438 21.402344 7.476562 21.429688 7.40625 C 21.441406 7.367188 21.464844 7.296875 21.476562 7.25 C 21.488281 7.203125 21.53125 7.046875 21.578125 
        6.902344 C 21.679688 6.566406 21.679688 6.550781 21.574219 6.554688 C 21.527344 6.558594 21.296875 6.570312 21.058594 6.582031 M 4.585938 12.335938 C 4.558594 12.367188 4.523438
        12.417969 4.503906 12.445312 C 4.484375 12.472656 4.351562 12.664062 4.203125 12.871094 C 3.785156 13.457031 3.0625 14.53125 2.589844 15.261719 C 2.492188 15.414062 2.382812 15.578125
        2.351562 15.628906 C 2.261719 15.761719 2.261719 15.765625 2.542969 15.886719 C 2.679688 15.945312 2.855469 16.023438 2.929688 16.058594 C 3.003906 16.09375 3.082031 16.121094 3.101562
        16.121094 C 3.121094 16.121094 3.148438 16.128906 3.160156 16.144531 C 3.175781 16.15625 3.261719 16.199219 3.355469 16.234375 C 3.582031 16.332031 3.761719 16.40625 3.980469 16.507812
        C 4.046875 16.539062 4.117188 16.5625 4.136719 16.5625 C 4.160156 16.5625 4.1875 16.574219 4.199219 16.585938 C 4.214844 16.601562 4.3125 16.644531 4.421875 16.6875 C 4.527344 16.730469 
        4.675781 16.796875 4.75 16.832031 C 4.828125 16.871094 4.894531 16.898438 4.902344 16.898438 C 4.910156 16.898438 4.960938 16.917969 5.015625 16.941406 C 5.648438 17.226562 6.371094 
        17.53125 6.382812 17.519531 C 6.394531 17.507812 6.261719 17.152344 6.1875 16.992188 C 6.175781 16.960938 6.140625 16.875 6.109375 16.796875 C 6 16.515625 5.96875 16.445312 5.921875 
        16.335938 C 5.898438 16.277344 5.875 16.21875 5.875 16.210938 C 5.875 16.199219 5.859375 16.15625 5.839844 16.109375 C 5.816406 16.066406 5.710938 15.800781 5.601562 15.523438 C 
        5.496094 15.242188 5.390625 14.980469 5.371094 14.9375 C 5.351562 14.894531 5.328125 14.828125 5.316406 14.792969 C 5.304688 14.757812 5.183594 14.425781 5.042969 14.054688 C 4.90625
         13.679688 4.753906 13.273438 4.707031 13.144531 C 4.664062 13.015625 4.617188 12.890625 4.605469 12.871094 C 4.59375 12.847656 4.5625 12.765625 4.539062 12.683594 L 4.492188 12.53125 
         L 4.554688 12.449219 C 4.617188 12.367188 4.664062 12.273438 4.640625 12.273438 C 4.636719 12.273438 4.609375 12.300781 4.585938 12.335938 "
      />
    </g>
  </svg>
);

export default SvgAlia;
