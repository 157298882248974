import { IStyles } from "../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  entryDate: {
    color: "neutral.grey7",
    fontFamily: "IBM Plex Sans, Helvetica !important",
    fontWeight: "400 !important",
    lineHeight: "140% !important",
    fontSize: "15px",
  },
  entryHour: {
    color: "neutral.grey6",
    fontFamily: "IBM Plex Sans, Helvetica !important",
    fontWeight: "400 !important",
    lineHeight: "140% !important",
    fontSize: "15px",
  },
};
