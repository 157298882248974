import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import find from "lodash/find";
import { IDataSet, IInputsFormProps } from "../InputsFormProps.interfaces";
import { ERROR_MESSAGES } from "../../../shared/constants/inputFormConstants";
import { get } from "lodash";

const SelectAutocompleteItemsInput: FC<IInputsFormProps> = ({
  name,
  control,
  controlName = name,
  errors,
  label,
  defaultValue,
  items = [],
  sx = {},
  isRequired,
}) => {
  return (
    <Controller
      name={name}
      rules={{ ...(isRequired && { required: ERROR_MESSAGES.required }) }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={items}
          value={find(items, { value }) ?? { text: "", value: "" }}
          getOptionLabel={(option: IDataSet) => option.text!}
          onChange={(_, data) => onChange(data?.value)}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={label}
              error={get(errors, controlName) !== undefined}
              helperText={get(errors, controlName)?.message ?? ""}
              variant="outlined"
              sx={sx}
            />
          )}
        />
      )}
    />
  );
};

export default SelectAutocompleteItemsInput;
