import React, { FC } from "react";
import { TableTransactions } from "../Table/TableTransactions/TableTransactions";
import { HeaderFilters } from "../HeaderFilters/HeaderFilters";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { BRANCH_PER_PAGE } from "../../shared/constants/labels/label_rows_per_page";
import { useDashboardTransaction } from "./state/useDashboardTransaction";
import EmptyResults from "../EmptyResults/EmptyResults";
import { IDashboardProps } from "./DashboardTransaction.interfaces";
import { TransactionEnum } from "../../shared/enums/TransactionEnum";
import ModalDetailInfo from "../ModalDetailInfo/ModalDetailInfo";
import { ModalStartChargeback } from "../ModalStartChargeback/ModalStartChargeback";
import { ModalLoader } from "@kushki/connect-ui";

const DashboardTransaction: FC<IDashboardProps> = ({
  showEmptyScreen,
  emptyAddText,
  basicNodeInfo: { entityName, path },
  transactionData,
  isBranchTab,
  handleOpenDialog,
  mechantFilter,
}: IDashboardProps) => {
  const {
    countData,
    downloadOptions,
    dataPicker,
    handleChangeFilterDetails,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rows,
    handleSearchTrxParent,
    handleSelectedRows,
    isLoadingTransaction,
    resetFilters,
    showEmptyScreen: showEmptyScreenHook,
    subTitle,
    setRangeAmount,
    rangeAmount,
    setIsApplyFilter,
    isApplyFilter,
    transactionInfo,
    setTransactionInfo,
    modalDetailInfo,
    modalStartChargeback,
    handleDownloadReceipt,
    handleSortQuery,
    setRowSelectChargeback,
    selectRowChargeback,
    selectedChargebackData,
    setSelectedChargebackData,
    loadingChargebackAction,
  } = useDashboardTransaction({
    entityName,
    mechantFilter,
    path,
    showEmptyScreen,
    transactionData,
  });

  return (
    <>
      <HeaderFilters
        entityNameType={entityName as EntityNameEnum}
        getItemsSelected={handleChangeFilterDetails}
        resetFilters={resetFilters}
        isBranchListTab={true}
        downloadFileOptions={downloadOptions}
        dataPicker={dataPicker}
        subTitle={subTitle!}
        setRangeAmount={setRangeAmount}
        rangeAmountInfo={rangeAmount}
        setIsApplyFilter={setIsApplyFilter}
        isApplyFilter={isApplyFilter}
      />
      {showEmptyScreenHook && countData < 1 ? (
        <EmptyResults addText={emptyAddText} title={TransactionEnum.NO_DATA} />
      ) : (
        <TableTransactions
          handleSelectedRows={handleSelectedRows}
          handleSortQuery={handleSortQuery}
          headTable={dataPicker.headerCell}
          rows={rows}
          labelRowsPerPage={BRANCH_PER_PAGE}
          paginationProps={{
            handleChangePage,
            handleChangeRowsPerPage,
            page,
            rowsPerPage,
            totalData,
          }}
          isBranchTab={isBranchTab}
          handleOpenDialog={handleOpenDialog}
          setIsOpenModalDetailInfo={modalDetailInfo.setIsOpenModalDetailInfo}
          setTransactionInfo={setTransactionInfo}
          setRowSelectChargeback={setRowSelectChargeback}
          setSelectedChargebackData={setSelectedChargebackData}
        />
      )}
      <ModalDetailInfo
        transactionInfo={transactionInfo}
        modal={modalDetailInfo}
        modalButtonActions={{
          downloadButton: handleDownloadReceipt,
          refundButton: handleOpenDialog,
          searchOriginalTrx: modalDetailInfo.handleSearchOriginalTrx,
        }}
        handlerSearchTrxParent={handleSearchTrxParent}
        isLoadingTransaction={isLoadingTransaction}
      />
      <ModalStartChargeback
        modalStartChargeback={modalStartChargeback}
        emails={[]}
        transactionInfo={transactionInfo}
        rowSelectChargeback={selectRowChargeback}
        selectedChargebackData={selectedChargebackData}
      />
      {loadingChargebackAction && (
        <ModalLoader
          descriptionText={"Este proceso puede tardar unos minutos..."}
          titleText={"Estamos procesando tu solicitud"}
          isOpen={loadingChargebackAction}
        />
      )}
    </>
  );
};

export default DashboardTransaction;
