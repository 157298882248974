import * as React from "react";
import IconArrows from "../../assets/icons/IconArrows";
import { FC, useEffect, useState } from "react";
import { ITooltipArrowProps } from "./IconToolTipArrow.interfaces";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { ORDER_LABELS } from "../../shared/constants/labels/order_labels";

const IconTooltipArrow: FC<ITooltipArrowProps> = (
  props: ITooltipArrowProps
) => {
  const [tittleToolTip, setTittleToolTip] = useState<string>(
    ORDER_LABELS.DEFAULT
  );
  const [order, setOrder] = useState<string>("");
  const [colorArrowTop, setColorArrowTop] = useState<string>("#B4B9BE");
  const [colorArrowBottom, setColorArrowBottom] = useState<string>("#B4B9BE");
  const [count, setCount] = useState(0);

  const tittleTooltipValue = (valueNumber: number) => {
    switch (valueNumber) {
      case 1:
        setTittleToolTip(ORDER_LABELS.ASC);
        setOrder("asc");
        setColorArrowTop("#B4B9BE");
        setColorArrowBottom("#FFFFF");
        break;
      case 2:
        setTittleToolTip(ORDER_LABELS.DESC);
        setOrder("desc");
        setColorArrowTop("#FFFFF");
        setColorArrowBottom("#B4B9BE");
        break;
      default:
        setTittleToolTip(ORDER_LABELS.DEFAULT);
        setOrder("");
        setColorArrowTop("#B4B9BE");
        setColorArrowBottom("#B4B9BE");
        break;
    }
  };

  const changeOrder = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    if (count >= 3) setCount(0);
    tittleTooltipValue(count);
    props.onClick(props.valueOrder, order, count);
  }, [count, order]);

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={tittleToolTip}
      placement={"top"}
      arrow
    >
      <IconButton onClick={changeOrder}>
        <IconArrows
          colorArrowTop={colorArrowTop}
          colorArrowBottom={colorArrowBottom}
        />
      </IconButton>
    </Tooltip>
  );
};

export default IconTooltipArrow;
