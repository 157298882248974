export enum TextEnum {
  addPaymentProcessorTitle = "Agregar Procesador",
  editPaymentProcessorTitle = "Editar Procesador",
  paymentProcessorTitle = "Procesador de pago",
  paymentProcessorDescription = "Selecciona el procesador que vas a agregar según el método de pago elegido.",
  paymentProcessorChipHeader = "Procesador para:",
  processorInfoTitle = "Información del procesador",
  processorInfoDesc = "Ingresa los datos principales del procesador.",
  processorInfoHeader = "Datos del procesador",
  basicInfoTitle = "Información básica",
  basicInfoDescription = "Elige el modelo y categoría para el procesador que vas a agregar.",
  modelTitle = "Modelo",
  categoryTitle = "Categoría",
  messageBoxTitle = "Información",
  messageBoxDescription = "Recuerda que para Card PayOut ya tienes configurado el procesador {p} por defecto. Si deseas configurar este procesador por defecto, se reemplazará",
  setAsDefaultProcessor = "Configurar este procesador por defecto para Card Payout",
  selectPaymentType = "Selecciona un tipo de pago",
  saveProcessor = "Creando procesador",
  updatingProcessor = "Actualizando procesador",
  waiting = "Por favor, espera...",
  requiredDataMessage = "Es un dato requerido.",
  existingAliasMessage = "El alias ya existe. Inténtalo nuevamente con uno diferente.",
  processorSuccessfullySaved = "El procesador fue creado con éxito, ¿Deseas agregar otro procesador?",
  finishAddProcessor = "Finalizar",
}

export enum ErrorMessageEnum {
  ProcessorInfoError = "No ha sido posible obtener la información de configuración del procesador del merchant.",
  MerchantInfoError = "No ha sido posible obtener la información del merchant.",
  MerchantProcessorsInfoError = "No ha sido posible obtener la información de los procesadores del merchant.",
  ProcessorsInfoError = "No ha sido posible obtener la información del procesador.",
  CreateProcessorError = "Ha sucedido un error inesperado y no ha sido posible crear el procesador.",
  UpdateProcessorError = "Ha sucedido un error inesperado y no ha sido posible actualizar el procesador.",
  DefaultProcessorError = "Ha sucedido un error inesperado y no ha sido posible configurar el procesador por defecto.",
}
