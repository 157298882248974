import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../../interfaces/app.interfaces";
import { getWebhookList } from "../../thunks/app/app.thunks";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";

export const initialState: AppState = {
  getWebhookList: FetchStateEnum.PENDING,
  webhookList: {
    hits: [],
  },
};
export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getWebhookList.pending, (state: AppState) => {
      state.getWebhookList = FetchStateEnum.PENDING;
    });
    builder.addCase(getWebhookList.fulfilled, (state, { payload }) => {
      state.getWebhookList = FetchStateEnum.SUCCESS;
      state.webhookList = payload;
    });
  },
  initialState,
  name: "app",
  reducers: {},
});

export default appSlice.reducer;
