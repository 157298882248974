import { makeStyles } from "@material-ui/core/styles";

export const breacrumbStyles = makeStyles({
  breadcrumb: {
    "& .MuiBreadcrumbs-separator": {
      marginLeft: 0,
      marginRight: 0,
    },
    marginBottom: 10,
  },
});
