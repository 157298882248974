import React from "react";
import { Box, Container } from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { TitlePage } from "../../components/common/TitlePage/TitlePage";
import { useFileUploadFormIndexEcuadorState } from "./state/useFileUploadFormIndexEcuadorState";
import { FileUploadCardComponentEcuador } from "../../components/FileUploadCardComponentEcuador/FileUploadCardComponentEcuador";
import { UploadActionButtonsComponentEcuador } from "../../components/UploadActionButtonsComponentEcuador/UploadActionButtonsComponentEcuador";
import { Loader } from "@kushki/frontend-molecules/loader";
import { SnackBarAlert } from "../../components/common/SnackBar/SnackBarAlert";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { CatalogsTextEnum } from "../../shared/infrastructure/Enums/CatalogsTextEnum";

export const FileUploadFormIndexEcuador: React.FC = () => {
  const {
    country,
    breadCrumbs,
    onSubmit,
    openError,
    fileUploader,
    handleCloseError,
    stateFile,
    downloadTemplateCsv,
    downloadTemplateExcel,
    isLoadingDownloadFile,
    isLoadingDownloadFileData,
    modalDetailProps,
    isFileLoaded,
    errorCsvMessage,
  } = useFileUploadFormIndexEcuadorState();
  const isEcuador: boolean =
    country === CountryValueEnum.Ecuador.toLocaleLowerCase();
  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        <TitlePage
          title={CatalogsTextEnum.LOAD_CATALOG_FILE}
          country={country}
        />
        {isEcuador && (
          <form>
            <FileUploadCardComponentEcuador
              isLoadingDownloadFile={isLoadingDownloadFile}
              isLoadingDownloadFileData={isLoadingDownloadFileData}
              fileUploader={fileUploader}
              downloadTemplateCsv={downloadTemplateCsv}
              downloadTemplateExcel={downloadTemplateExcel}
            />
            <Box mb={2} mt={2}>
              <UploadActionButtonsComponentEcuador
                onSubmit={onSubmit}
                handleBack={modalDetailProps.setOpenModalDetail}
                isFileLoaded={isFileLoaded}
                buttonText={"Cargar archivo"}
              />
            </Box>
          </form>
        )}
      </Container>
      <SnackBarAlert
        open={openError}
        msg={errorCsvMessage}
        handlerClose={handleCloseError}
        type="dark"
      />
      <Loader
        open={stateFile.isLoading}
        headerText={"Estamos enviando la solicitud."}
        bodyText={"Por favor, espere..."}
      />
    </React.Fragment>
  );
};
