import { AppBar, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import {
  BodyTab,
  BodyTabContentBlock,
  ModalBodyTabProps,
} from "./ModalBodyTab.interfaces";
import { get } from "lodash";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import React from "react";
import refundTimeLine from "../../../assets/images/refund-time-line.svg";
import { TabContentBlock } from "../TabContentBlocks/TabContentBlock";
import { TabContentBlockType } from "../TabContentBlocks/TabContentBlock.interfaces";
import { useStyles } from "./ModalBodyTab.styles";

export const ModalBodyTab = (props: ModalBodyTabProps) => {
  const classes = useStyles();
  const tabs: BodyTab[] = get(props, "tabs", []);
  const dataTimeLine: boolean = tabs.some((bodyTab: BodyTab) =>
    bodyTab.content.some(
      (content: BodyTabContentBlock) =>
        content.blockType === TabContentBlockType.TIME_LINE_ROW
    )
  );

  return (
    <ModalBody>
      <div className={classes.tabs}>
        <AppBar position="sticky" color="transparent">
          <Tabs
            value={props.selectedTab}
            variant="fullWidth"
            onChange={props.handleSelectTab}
            indicatorColor={"primary"}
            centered={true}
          >
            {tabs.map((block: BodyTab, tabIndex: number) => (
              <Tab
                label={
                  <Typography variant="subtitle2" className={classes.label}>
                    {block.title}
                  </Typography>
                }
                aria-controls={`scrollable-prevent-tabpanel-${tabIndex}`}
                id={`scrollable-prevent-tab-${tabIndex}`}
                value={tabIndex}
                key={`tabHeader_${tabIndex}`}
              />
            ))}
          </Tabs>
        </AppBar>

        <div className={classes.tabContent}>
          {tabs.map((bodyTab: BodyTab, tabIndex: number) => (
            <>
              {bodyTab.content.map(
                (content: BodyTabContentBlock, key: number) => (
                  <TabContentBlock
                    blockType={content.blockType}
                    tabIndex={tabIndex}
                    selectedTab={props.selectedTab}
                    transaction={props.transaction}
                    lines={content.lines}
                    timeLine={content.timeLine}
                    key={`block_${key}`}
                  />
                )
              )}
              {tabIndex == 1 && !dataTimeLine && (
                <Grid hidden={props.selectedTab !== tabIndex} container xs={12}>
                  <img alt={"non authorized icon"} src={refundTimeLine} />
                </Grid>
              )}
            </>
          ))}
        </div>
      </div>
    </ModalBody>
  );
};
