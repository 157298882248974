import React, { useEffect } from "react";
import {
  IRadioGroupProps,
  RadioInfoItems,
} from "../RadioGroupComponent.interfaces";
import { cloneDeep, defaultTo, filter, get, isUndefined, set } from "lodash";

export interface UseRadioButtonProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  items: RadioInfoItems[];
  validateProps: () => void;
  setSelectedValue: (value: string) => void;
  selectedValue: string;
}

export const useRadioButton = (
  props: IRadioGroupProps
): UseRadioButtonProps => {
  const [radioItems, setRadioItems] = React.useState<RadioInfoItems[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [selectedValue, setSelectedValue] = React.useState<string>("");

  const getSelectedValue = (
    values: RadioInfoItems[]
  ): RadioInfoItems | undefined => {
    return filter(values, (item: RadioInfoItems) =>
      get(item, "selected", false)
    )[0];
  };

  const getEnableRadioItems = (items?: RadioInfoItems[]): RadioInfoItems[] => {
    const new_items: RadioInfoItems[] = filter(
      defaultTo(items, []),
      (item: RadioInfoItems) => !get(item, "hidden", false)
    );

    let selected_value: RadioInfoItems | undefined =
      getSelectedValue(new_items);

    if (isUndefined(selected_value)) {
      set(new_items, "0.selected", true);
      selected_value = new_items[0];
    }
    setSelectedIndex(new_items.indexOf(selected_value));
    setSelectedValue(selected_value.value);

    return new_items;
  };

  const validateProps = (): void => {
    const actual_value: RadioInfoItems | undefined = getSelectedValue(
      get(props, "items", [])
    );

    if (get(actual_value, "value") != selectedValue) {
      const items: RadioInfoItems[] = getEnableRadioItems(props.items);

      setRadioItems(items);
    }
  };

  useEffect(() => {
    const items: RadioInfoItems[] = getEnableRadioItems(props.items);

    setRadioItems(items);
    props.updateList(items);
  }, []);

  useEffect(() => {
    validateProps();
  }, [props.items]);

  const updateRadioValue = (
    list: RadioInfoItems[],
    lastIndex: number,
    value: string
  ): [RadioInfoItems[], number] => {
    const new_list: RadioInfoItems[] = cloneDeep(list);

    const selectedItem: RadioInfoItems = filter(
      list,
      (item: RadioInfoItems) => item.value === value
    )[0];

    const actualIndex: number = list.indexOf(selectedItem);

    set(new_list, lastIndex, { ...new_list[lastIndex], selected: false });
    set(new_list, actualIndex!, { ...new_list[actualIndex!], selected: true });

    return [new_list, actualIndex];
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = get(event, "target.value");

    const [new_items, actualIndex]: [RadioInfoItems[], number] =
      updateRadioValue(radioItems, selectedIndex, value);

    setSelectedIndex(actualIndex);
    setSelectedValue(String(new_items[actualIndex].value));
    setRadioItems(new_items);

    props.updateList(new_items);
  };

  return {
    items: radioItems,
    onChange,
    selectedValue,
    setSelectedValue,
    validateProps,
  };
};
