import React from "react";
import { servicesInfoStyles as styles } from "./ServicesInfo.styles";
import { Grid, Typography } from "@mui/material";
import {
  Smartlinks,
  VirtualPOS,
  Webcheckout,
} from "../../shared/constants/labels/services_labels";
import { ChipStatus } from "../../components/ChipStatus/ChipStatus";
import { StatusChipEnum } from "../../shared/enums/StatusEnum";
import IconVirtualPos from "../../assets/icons/IconVirtualPos";
import IconWebCheckOut from "../../assets/icons/IconWebCheckOut";
import IconSmartlinks from "../../assets/icons/IconSmartlinks";
import { useServicesInfo } from "./state/useServicesInfo";
import { ServicesInfoProps } from "./ServicesInfo.interfaces";
import { ServicesInfoSkeleton } from "../../components/Skeleton/ServicesInfoSkeleton/ServicesInfoSkeleton";

export const ServicesInfo: React.FC<ServicesInfoProps> = ({
  publicMerchantId,
}: ServicesInfoProps) => {
  const { smartlinks, vpos } = useServicesInfo(publicMerchantId);

  return (
    <>
      {!smartlinks.publicMerchantId && !vpos.publicId ? (
        <ServicesInfoSkeleton />
      ) : (
        <Grid container spacing={3} sx={styles.contentGrid}>
          <Grid item xs={12} md={4} sx={styles.gridItem}>
            <IconVirtualPos />
            <Typography sx={styles.textTitle} color="primary">
              {VirtualPOS}
            </Typography>
            <ChipStatus
              text={
                vpos.active ? StatusChipEnum.ACTIVE : StatusChipEnum.INACTIVE
              }
            />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.gridItem}>
            <IconWebCheckOut />
            <Typography sx={styles.textTitle} color="primary">
              {Webcheckout}
            </Typography>
            <ChipStatus
              text={
                smartlinks.webcheckoutStatus
                  ? StatusChipEnum.ACTIVE
                  : StatusChipEnum.INACTIVE
              }
            />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.gridItem}>
            <IconSmartlinks />
            <Typography sx={styles.textTitle} color="primary">
              {Smartlinks}
            </Typography>
            <ChipStatus
              text={
                smartlinks.status
                  ? StatusChipEnum.ACTIVE
                  : StatusChipEnum.INACTIVE
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
