import React from "react";
import { RuleContainerProps } from "../BusinessRules.interfaces";
import { Grid, Typography } from "@mui/material";
import { RulesInformation } from "../RulesInformation/RulesInformation";
import { DeleteRuleModal } from "../DeleteRuleModal/DeleteRuleModal";
import { MessageAlertRulesBatch } from "../MessageAlertRulesBatch/MessageAlertRulesBatch";
import { RuleList } from "../RuleList/RuleList";
import { isEmpty } from "lodash";

export const RuleContainer: React.FC<RuleContainerProps> = ({
  rules,
  payoutRules,
  deleteModalInfo,
  isBatch,
  containerTitle,
  existPendingRulesSent,
  existMoreThanFiveRules,
  isPendingDraft,
}: RuleContainerProps) => {
  const hasPayouts: boolean = !isEmpty(payoutRules);

  return (
    <Grid container paddingTop={"1rem"}>
      <Grid item xs={12}>
        <Typography
          variant={"subtitle2"}
          color={"primary"}
          style={{ fontSize: "large" }}
        >
          {containerTitle}
        </Typography>
      </Grid>
      <RulesInformation
        existMoreThanFiveRules={existMoreThanFiveRules}
        isPendingDraft={isPendingDraft}
        isBatch={isBatch}
        hasPayouts={hasPayouts}
      />
      <DeleteRuleModal
        deleteModalInfo={deleteModalInfo}
        isBatch={isBatch}
        payoutRule={hasPayouts}
      />
      <Grid item xs={12}>
        {existPendingRulesSent && isBatch && <MessageAlertRulesBatch />}
      </Grid>
      <Grid item xs={12}>
        <RuleList
          rules={rules}
          payoutRules={payoutRules}
          deleteModalInfo={deleteModalInfo}
          isBatch={isBatch}
        />
      </Grid>
    </Grid>
  );
};
