import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  container: {
    boxShadow: "none",
    backgroundColor: "transparent",
    "& th": {
      padding: "8px 16px",
      borderBottom: "1.5px solid #E2E8F0",
    },
    "& td": {
      padding: "10px 16px",
      borderBottom: "1.5px solid #E2E8F0",
    },
  },
});

export interface ISkeletonTableContainer {
  rows: number;
  cells: number;
}
export const SkeletonTableProcessors: React.FC<ISkeletonTableContainer> = ({
  rows,
  cells,
}: React.PropsWithChildren<ISkeletonTableContainer>) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table size="small">
        <TableBody>
          {Array(rows)
            .fill("")
            .map((_, indexRow) => (
              <TableRow key={indexRow}>
                {Array(cells)
                  .fill("")
                  .map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={"120px"}
                        height={"20px"}
                      />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
