import React, { useEffect, useState } from "react";
import { IChoiceButtonInterfaces } from "@kushki/connect-ui/dist/Components/Atoms/FormControls/ChoiceButton/ChoiceButton.interfaces";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  ButtonTypeLabelEnum,
  ServiceEnum,
} from "../../../../shared/infrastructure/enums/CreateRuleConstans";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { IUsePaymentTypeComponent } from "../../../../shared/infrastructure/interfaces/IUsePaymentTypeComponent";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/reducer";
import { useFormContext } from "react-hook-form";
import { TableValueEnum } from "../../../../shared/infrastructure/enums/DataTableEnum";
import { setIsPaymentMethod } from "../../../../store/actionCreators";

export const usePaymentTypeComponent = (): IUsePaymentTypeComponent => {
  const { setValue, clearErrors, watch } = useFormContext();
  const isUpdatedRule: boolean | undefined = watch("isUpdatedRule");
  const service = watch("service");
  const subType: string | undefined = watch("subType");
  const commerceType: string | undefined = watch("commerceType");
  const dispatch = useDispatch();
  const flagSubtype: boolean = subType === TableValueEnum.COUNTRY;
  const [items, setItems] = useState<IChoiceButtonInterfaces[]>([
    {
      checked: false,
      img: <CreditCardIcon className="iconChoice" />,
      label: ButtonTypeLabelEnum.CARD,
      selected: false,
      value: ServiceEnum.CARD,
      isDisabled: true,
    },
    {
      checked: false,
      img: <AccountBalanceIcon className="iconChoice" />,
      label: ButtonTypeLabelEnum.TRANSFER,
      selected: false,
      value: ServiceEnum.TRANSFER,
      isDisabled: true,
    },
  ]);
  const isPaymentMethod: boolean | undefined = useSelector(
    (state: IAppState) => state.isPaymentMethod
  );
  const isLoadingProcessor: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingProcessor
  );

  const getButtonSelect = (value: string | undefined): void => {
    setValue("service", value);
    clearErrors("service");
  };

  useEffect(() => {
    setItems([
      {
        checked: false,
        img: <CreditCardIcon className="iconChoice" />,
        label: ButtonTypeLabelEnum.CARD,
        selected: false,
        value: ServiceEnum.CARD,
        isDisabled: true,
      },
      {
        checked: false,
        img: <AccountBalanceIcon className="iconChoice" />,
        label: ButtonTypeLabelEnum.TRANSFER,
        selected: false,
        value: ServiceEnum.TRANSFER,
        isDisabled: true,
      },
    ]);
    dispatch(setIsPaymentMethod(false));
  }, [commerceType]);

  useEffect(() => {
    if (isPaymentMethod) {
      setValue("service", "card");
      setItems([
        {
          checked: true,
          img: <CreditCardIcon className="iconChoice" />,
          label: ButtonTypeLabelEnum.CARD,
          selected: service === ServiceEnum.CARD,
          value: ServiceEnum.CARD,
          isDisabled: false,
        },
        {
          checked: false,
          img: <AccountBalanceIcon className="iconChoice" />,
          label: ButtonTypeLabelEnum.TRANSFER,
          selected: false,
          value: ServiceEnum.TRANSFER,
          isDisabled: true,
        },
      ]);
    } else if (flagSubtype) {
      setValue("service", "card");
      setItems([
        {
          checked: true,
          img: <CreditCardIcon className="iconChoice" />,
          label: ButtonTypeLabelEnum.CARD,
          selected: true,
          value: ServiceEnum.CARD,
          isDisabled: false,
        },
        {
          checked: false,
          img: <AccountBalanceIcon className="iconChoice" />,
          label: ButtonTypeLabelEnum.TRANSFER,
          selected: false,
          value: ServiceEnum.TRANSFER,
          isDisabled: true,
        },
      ]);
    } else {
      setValue("service", "transfer");
      setItems([
        {
          checked: false,
          img: <CreditCardIcon className="iconChoice" />,
          label: ButtonTypeLabelEnum.CARD,
          selected: false,
          value: ServiceEnum.CARD,
          isDisabled: true,
        },
        {
          checked: false,
          img: <AccountBalanceIcon className="iconChoice" />,
          label: ButtonTypeLabelEnum.TRANSFER,
          selected: false,
          value: ServiceEnum.TRANSFER,
          isDisabled: true,
        },
      ]);
    }
  }, [isPaymentMethod, service, subType]);

  return {
    items,
    isUpdatedRule,
    getButtonSelect,
    isLoadingProcessor,
  };
};
