import { ICellTextComponent } from "./CellTitle.interfaces";
import { FC, PropsWithChildren } from "react";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useCellTitle } from "./state/useCellTitle";
import { cellTitleStyles } from "./CellTitle.styles";
import { FLEX_JUSTIFY_BY_ALIGN } from "../../Table/TableBodySimple/constants";
import { useMobileState } from "../../../shared/state/useMobileState";

export const CellTitleItem: FC<ICellTextComponent> = (
  props: PropsWithChildren<ICellTextComponent>
) => {
  const { type, line1, line2, cellProps } = props;

  const {
    tooltipEnabled,
    handlers: { handleShouldShow, hideTooltip, getStyle },
    isLoading,
  } = useCellTitle(props);

  const { isMobile } = useMobileState();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={
        isMobile ? "start" : FLEX_JUSTIFY_BY_ALIGN[cellProps.align || "center"]
      }
    >
      <Tooltip
        title={line1}
        arrow
        placement={"top"}
        disableHoverListener={isLoading || !tooltipEnabled}
        onMouseEnter={handleShouldShow}
        onMouseLeave={hideTooltip}
        sx={cellTitleStyles.toolTip}
      >
        <Typography
          noWrap
          align={cellProps.align}
          sx={cellTitleStyles.subtitle2}
          variant={"subtitle2"}
        >
          {isLoading ? (
            <Box width="100%" minWidth="50px">
              <Skeleton
                data-testid="loading1"
                variant="rectangular"
                animation="wave"
                width={"100%"}
              />
            </Box>
          ) : (
            line1
          )}
        </Typography>
      </Tooltip>

      {type === "twoLines" && (
        <Tooltip
          title={line2!}
          arrow
          placement={"top"}
          disableHoverListener={isLoading || !tooltipEnabled}
          onMouseEnter={handleShouldShow}
          onMouseLeave={hideTooltip}
          sx={cellTitleStyles.toolTip}
        >
          <Typography
            noWrap
            align={cellProps.align}
            sx={cellTitleStyles.description}
            variant={"overline"}
          >
            {isLoading ? (
              <Skeleton
                data-testid="loading2"
                variant="rectangular"
                animation="wave"
                sx={getStyle(cellProps.align || "center")}
              />
            ) : (
              line2
            )}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};
