export enum RegexExpressionEnum {
  CURRENCY_WITH_FRACTION = "^[0-9]+$|^[0-9]+\\.[0-9]{1,2}$",
  IP = "^([0-9]+\\.[0-9]+(\\.[0-9]+)+)(,[\\s][0-9]+\\.[0-9]+(\\.[0-9]+)+)*$",
  ONE_IP = "^(?:(?:^|\\.)(?:2(?:5[0-5]|[0-4]\\d)|1?\\d?\\d)){4}$",
  ONLY_NUMBERS = "^([0-9A]+)(,[\\s][0-9A]+)*$",
  PHONE_NUMBER = "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$",
  PHONE_NUMBER_WITH_COMMA = "^(([\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}(, )?)*)+$",
  EMAIL = "[A-Z0-9._%+-+a-z]+@[a-z]+\\.[a-z]{2,3}",
  ONE_EMAIL = "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
  ONLY_TEXTS = "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$",
  ONLY_TEXTS_WITH_COMMA = "^[a-zA-ZÀ-ÿ\u00f1\u00d1, ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1, ]+$",
  MORE_THAN_ONE_UUID = "^(?:[a-zA-Z\\d]{8})(?:-[a-zA-Z\\d]{4}){3}(?:-[a-zA-Z\\d]{12})(?:,[ ]*(?:[a-zA-Z\\d]{8})(?:-[a-zA-Z\\d]{4}){3}(?:-[a-zA-Z\\d]{12}))*$",
  ONLY_ONE_UUID = "^(?:[a-zA-Z\\d]{8})(?:-[a-zA-Z\\d]{4}){3}(?:-[a-zA-Z\\d]{12})$",
}
