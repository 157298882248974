/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_SALVADOR: IOptionSelection[] = [
  { value: "10100003", label: "BANCO CITIBANK DE EL SALVADOR S.A." },
  { value: "10100001", label: "BANCO AGRICOLA S.A." },
  { value: "10100002", label: "BANCO CENTRAL DE RESERVA DE EL SALVADOR" },
  { value: "10100004", label: "BANCO DE AMERICA CENTRAL S.A." },
  { value: "10100005", label: "BANCO HIPOTECARIO DE EL SALVADOR S.A." },
  { value: "10100006", label: "BANCO HSBC SALVADORENO S.A." },
  { value: "10100007", label: "BANCO MULTISECTORIAL DE INVERSIONES" },
  { value: "10100008", label: "BANCO PROCREDIT S.A." },
  { value: "10100009", label: "SCOTIABANK EL SALVADOR S.A." },
];
