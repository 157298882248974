import React from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  withStyles,
} from "@material-ui/core";
import { SecondaryActions } from "./secondaryActions/SecondaryActions";
import { IRuleAuthenticationForm } from "../../../shared/infrastructure/interfaces/IRuleAuthenticationForm";
import ConnectForm from "../../common/ConnectForm/ConnectForm";
import { ArrayField, Controller } from "react-hook-form";
import { get } from "lodash";
import { SpeedEnum } from "../../../shared/infrastructure/enums/SpeedEnum";
export interface ISpeedRulesProps {
  indexParent: number;
  item: Partial<ArrayField>;
}
const KushkiDeepBlueSwitch = withStyles({
  switchBase: {
    color: "#023365",
    "&$checked": {
      color: "#023365",
    },
    "&$checked + $track": {
      background: "#b2d6ff",
    },
  },
  checked: {},
  track: {
    background: "#444",
  },
})(Switch);
export const SpeedRules: React.FC<ISpeedRulesProps> = (
  props: ISpeedRulesProps
) => {
  const { indexParent, item } = props;

  return (
    <ConnectForm<IRuleAuthenticationForm>>
      {({ control, getValues, watch }) => {
        const kind = watch(`strictCondition[${indexParent}].kind`);
        return (
          <Grid container direction="row" style={{ padding: "0px !important" }}>
            <Grid item xs={12} style={{ paddingBottom: "0px !important" }}>
              <Controller
                control={control}
                name={`strictCondition[${indexParent}].kind`}
                rules={{ required: true }}
                defaultValue={get(item, "kind", SpeedEnum.strict)}
                render={(field) => (
                  <FormControlLabel
                    {...field}
                    control={
                      <KushkiDeepBlueSwitch
                        data-testid="switchValueWrapper"
                        checked={
                          get(
                            getValues(),
                            `strictCondition[${indexParent}].kind`,
                            SpeedEnum.strict
                          ) === SpeedEnum.speed
                        }
                        onChange={(_, v) =>
                          field.onChange(
                            v === true ? SpeedEnum.speed : SpeedEnum.strict
                          )
                        }
                        color={"primary"}
                      />
                    }
                    labelPlacement={"start"}
                    label="Condiciones de Velocidad"
                  />
                )}
              />
            </Grid>
            {kind === "speed" ? (
              <Box
                id="SecondaryActionsWrapper"
                data-testid="SecondaryActionsWrapper"
                width={"100%"}
                padding={"0px"}
              >
                <SecondaryActions indexParent={indexParent} item={item} />
              </Box>
            ) : null}
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
