import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  createStyles,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Download } from "react-feather";
import { useDownloadButton } from "./useDownloadButton";
import { ArrowDropDown } from "@material-ui/icons";

export interface IDownloadButton {
  handleDownload: (format: string) => void;
  loadingDownload?: boolean;
  shadow?: boolean;
}

const useStyles = makeStyles<Theme, { shadow: boolean }>(() =>
  createStyles({
    root: {
      display: "flex",
    },
    downloadBtn: {
      marginTop: "4px",
      height: "39px",
      padding: "7px 15px 7px 15px",
      backgroundColor: "#ffffff",
      border: "0px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      color: "#023365",
      fontWeight: 500,
      boxShadow: ({ shadow }) =>
        shadow ? "0px 2px 3px 0.25px rgba(14,30,46,0.2)" : undefined,
    },
    menu: {
      zIndex: 1301,
    },
    buttonOptions: {
      padding: "5px 16px",
    },
    menuList: {
      display: "flex",
      flexDirection: "column",
    },
    arrowIcon: {
      fontSize: 23,
    },
    menuButton: {
      borderLeft: "1px solid rgba(2, 51, 101, 1)",
      paddingLeft: 3,
      paddingRight: 2,
      minHeight: 37,
      transform: "translateX(30%)",
    },
    paddingWord: {
      paddingRight: 13,
    },
  })
);

export const DownloadButton: React.FC<IDownloadButton> = (
  props: IDownloadButton
) => {
  const styles = useStyles({ shadow: props.shadow! });
  const { anchorRef, open, handler } = useDownloadButton(props);

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        variant="outlined"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        startIcon={<Download xlinkTitle={"Descargar"} size={16} />}
        className={styles.downloadBtn}
        onClick={handler.handleToggle}
        disabled={props.loadingDownload}
        endIcon={props.loadingDownload && <CircularProgress size={20} />}
      >
        <Typography variant="body1" className={styles.paddingWord}>
          Descargar
        </Typography>
        <Box className={styles.menuButton} display="flex" alignItems="center">
          <ArrowDropDown className={styles.arrowIcon} />
        </Box>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={styles.menu}
        placement={"bottom"}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handler.handleClose}>
                <MenuList
                  className={styles.menuList}
                  autoFocusItem={open}
                  id="menu-list-grow"
                >
                  <MenuItem
                    className={styles.buttonOptions}
                    onClick={() => handler.handleDownload("json")}
                  >
                    JSON
                  </MenuItem>
                  <MenuItem
                    className={styles.buttonOptions}
                    onClick={() => handler.handleDownload("csv")}
                  >
                    CSV
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
