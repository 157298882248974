import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const SubHeaderSkeletonStyles = createNamedStyles({
  container: {
    width: "50%",
  },
  skeletonText: {
    height: "24px",
  },
  flagCountry: {
    borderRadius: "100% !important",
    height: "24px",
    transform: "scale(1, 1)",
    width: "24px",
  },
});
