import React, { FC } from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { get, isUndefined } from "lodash";
import { IInputFieldsFormProps } from "../../../shared/interfaces/InputFieldsForm.interfaces";

const SelectItemsField: FC<IInputFieldsFormProps> = ({
  name,
  control,
  errors,
  label,
  items = [],
  onClick,
  disabled,
  rules,
}: IInputFieldsFormProps) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          select
          variant="outlined"
          fullWidth
          label={label}
          disabled={disabled}
          error={!isUndefined(errors[name])}
          helperText={get(errors, `${name}.message`, "")}
          onClick={onClick}
        >
          {items}
        </TextField>
      )}
    />
  );
};

export default SelectItemsField;
