export const TEXT = {
  centralize: "Configurar centralización",
  centralized: "Centralizado",
  decentralize: "Descentralizar",
  decentralized: "Descentralizado",
  tooltip: (isCentralized: boolean) =>
    `Deberás realizar la ${
      isCentralized ? "descentralización" : "centralización"
    } desde el customer`,
};
