/**
 * DeferredStatusEnum
 */
export enum DeferredStatusEnum {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export enum StatusEnum {
  ENABLED = "ACTIVO",
  DISABLED = "INACTIVO",
}
