import React from "react";
import { ITextFieldComponentProps } from "../../../../../shared/infrastructure/interfaces/ITextFieldComponentProps";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { ErrorsEnum } from "../../../../../shared/infrastructure/enums/ErrorEnum";
import {
  HelperText,
  TextFieldsNames,
} from "../../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { retrieveFormError } from "../../../../../utils/utilsFile";
import { get, isEmpty } from "lodash";

export const TextFieldComponent: React.FC<{
  value: ITextFieldComponentProps;
  defaultValueList: string[];
}> = (props: {
  value: ITextFieldComponentProps;
  defaultValueList: string[];
}) => {
  const {
    placeholder,
    actions,
    value,
    name,
    id,
    errors,
    control,
    register,
    setValue,
    compareValue,
  } = props.value;
  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        name={id}
        control={control}
        rules={{
          required: {
            message: ErrorsEnum.REQUIRED_FIELD,
            value: false,
          },
          pattern: {
            message: ErrorsEnum.NOT_ALLOWED_VALUE,
            value: /^([0-9]{1,12})$|(^[0-9]{1,12}[.][0-9]{1,2})$/,
          },
          validate: (val: string) => {
            if (id === TextFieldsNames.FirstAmount)
              return actions.validateAmounts(val, compareValue, id);
            return actions.validateAmounts(compareValue, val, id);
          },
        }}
        render={() => (
          <TextField
            key={id}
            id={id}
            name={id}
            value={value}
            label={placeholder}
            variant="outlined"
            fullWidth
            inputRef={register}
            error={get(errors, id)}
            helperText={retrieveFormError(errors, id)[1]}
            onChange={() => {
              actions.getOtherPlaceholder(value);
              if (!isEmpty(value)) setValue(name, actions.getValues());
            }}
            onKeyDown={(e: any) => actions.keyDownHandler(e)}
          />
        )}
      />
      {value.length === 0 && (
        <FormHelperText>{HelperText.AMOUNT_HELPER}</FormHelperText>
      )}
    </FormControl>
  );
};
