export enum ColumnSelectEnum {
  AMOUNT = "Monto",
  TICKET_NUMBER = "Número de ticket",
  CARD_NUMBER = "Número de tarjeta",
  CARD_BRAND = "Marca de tarjeta",
  ISSUING_BANK = "Banco emisor",
  SUBSCRIPTION_ID = "Id. de suscripción",
  NAME_ON_CARD = "Nombre en tarjeta",
  EMAIL = "Correo electrónico",
  CREDENTIAL_ALIASES = "Alias de la credencial",
  METHOD = "Medio",
  CREDENTIAL_ID = "Id. de la credencial",
  PUBLIC_KEY = "Llave pública",
  TYPE = "Tipo",
  DATE = "Fecha",
  STATUS = "Estado",
  OWNER = "Owner",
  CUSTOMER = "Customer",
  BRANCH = "Branch/Merchant",
  COMPLETED = "Fecha fin",
}
