import React from "react";
import { IMultipleFormValues } from "../FormControl/FormControl.interfaces";
import FormControl from "../FormControl/FormControl";
import { InputTypeEnum } from "../../shared/enum/InputTypeEnum";

export interface RolesRadioButtonsProps {
  roles: { id: string; value: string }[];
}

function buildOptions(
  roles: { id: string; value: string }[]
): IMultipleFormValues[] {
  const result: IMultipleFormValues[] = roles.map((role) => ({
    name: role.value,
    value: role.id,
  }));

  return result;
}

const RolesRadioButtons = (props: RolesRadioButtonsProps) => {
  return (
    <React.Fragment>
      {props.roles.length > 0 && (
        <FormControl
          inputComponent={InputTypeEnum.RADIO}
          name={"kindRoles"}
          label={"radio"}
          values={buildOptions(props.roles)}
        />
      )}
    </React.Fragment>
  );
};

export default RolesRadioButtons;
