import React, { FC } from "react";
import { ModalSimpleSecurity } from "../../components/shared/ModalSimpleSecurity/ModalSimpleSecurity";
import { SecurityWrapperEnum } from "../../shared/infrastructure/SecurityWrapperEnum";
import { ModalBranchCreate } from "../../components/ModalBranchCreate/ModalBranchCreate";
import { useResumeContainerState } from "./useResumeContainerState/useResumeContainerState";
import {
  MODAL_CANCELAR,
  MODAL_DES1,
  MODAL_DES2,
  MODAL_PRIMARY_BUTTON,
  MODAL_TITLE,
} from "../../shared/constants/labels/resume_container_label";
import { ModalLoader } from "@kushki/connect-ui";

const ResumeContainer: FC = () => {
  const {
    form,
    openModalCreate,
    closeModal,
    //TODO USE THIS TO OPEN ModalBranchCreate
    openBranchModal,
    createBranchButton,
    validateNameBranch,
    openLoaderModal,
    openConfirmationModal,
  } = useResumeContainerState();

  return (
    <React.Fragment>
      <ModalSimpleSecurity
        buttonPrimary={{
          action: () => {},
          securityRol: SecurityWrapperEnum.BRANCH_ACTIVADO,
          text: "Listo",
        }}
        buttonSecondary={{
          action: () => {},
          securityRol: SecurityWrapperEnum.CREAR_NUEVO_BRANCH,
          text: "Crear nuevo branch",
        }}
        descriptionText={
          "La configuración del customer y de su primer branch ha sido guardada con éxito."
        }
        isOpen={openConfirmationModal}
        onClose={() => {}}
        titleText={"El cliente ya está activo"}
        typeModal={1}
      />
      <ModalBranchCreate
        buttonPrimary={{
          action: validateNameBranch,
          isDisabled: createBranchButton,
          securityRol: SecurityWrapperEnum.CREAR_PRIMER_BRANCH,
          text: MODAL_PRIMARY_BUTTON,
        }}
        buttonSecondary={{
          action: closeModal,
          securityRol: SecurityWrapperEnum.CANCELAR_BRANCH,
          text: MODAL_CANCELAR,
        }}
        descriptionText2={MODAL_DES2}
        descriptionText={MODAL_DES1}
        isOpen={openModalCreate}
        onClose={closeModal}
        titleText={MODAL_TITLE}
        form={form}
      />
      <ModalLoader
        descriptionText="Este proceso puede tardar un poco..."
        titleText="Creando Branch"
        isOpen={openLoaderModal}
      />

      <button onClick={openBranchModal}>Hola a todos</button>
    </React.Fragment>
  );
};

export default ResumeContainer;
