import { ActionTypes } from "./actionTypes";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { DownloadFileResponse } from "../../types/download_file_response";
import { IAppAction } from "./actionCreators";
import { ValidateFilesResponse } from "../../types/validate_files_response";
import { ProcessFileResponse } from "../../types/process_file_response";
import { UploadFiles } from "../../types/upload_files";
import { FilterDispersionResponse } from "../../types/filter_dispersion_response";
import { FilterDispersionRequest } from "../../types/filter_dispersion_request";
import { FileValidationDetailsResponse } from "../../types/file_validation_details_response";
import { FileStateDispersionResponse } from "../../types/file_state_dispersion_response";

export interface IAppState {
  notification?: INotification;
  downloadFilesResponse?: DownloadFileResponse;
  validateFilesResponse?: ValidateFilesResponse;
  showValidationErrorAlert?: boolean;
  uploadFiles?: UploadFiles;
  processFileDispersionsResponse?: ProcessFileResponse;
  showAlertOnProcessFileRequest?: boolean;
  showAlertOnGetDispersionFile?: boolean;
  getFileDispersionResponse?: FilterDispersionResponse;
  isLoadingGetDispersionFile?: boolean;
  filterDispersionRequest?: FilterDispersionRequest;
  fileValidationDetails?: FileValidationDetailsResponse;
  fileStateDispersionDetails?: FileStateDispersionResponse;
  isLoadingDetails?: boolean;
  showAlertOnDispersionsValidation?: boolean;
  selectedIndexDispersionTab?: number;
}

export const INITIAL_STATE: IAppState = {
  notification: {
    message: "",
    open: false,
    type: "success",
  },
  downloadFilesResponse: {
    status: "",
    templates: {
      inputFile: "",
      uafFile: "",
    },
  },
  processFileDispersionsResponse: {
    status: "",
    currency: "",
    referenceNumber: 0,
    rows: 0,
    totalAmount: 0,
  },
  showAlertOnProcessFileRequest: false,
  showAlertOnGetDispersionFile: false,
  getFileDispersionResponse: {
    data: [],
    total: 0,
  },
  isLoadingGetDispersionFile: false,
  fileValidationDetails: {
    status: "",
    referenceNumber: "",
    summary: [],
  },
  isLoadingDetails: false,
  showAlertOnDispersionsValidation: false,
  selectedIndexDispersionTab: 0,
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_DOWNLOAD_FILES_RESPONSE:
      return {
        ...state,
        downloadFilesResponse: action.downloadFilesResponse,
      };
    case ActionTypes.SET_VALIDATE_FILES_RESPONSE:
      return {
        ...state,
        validateFilesResponse: action.validateFilesResponse,
      };
    case ActionTypes.SET_SHOW_VALIDATION_ALERT:
      return {
        ...state,
        showValidationErrorAlert: action.showValidationErrorAlert,
      };
    case ActionTypes.SET_UPLOAD_FILES:
      return {
        ...state,
        uploadFiles: action.uploadFiles,
      };
    case ActionTypes.SET_PROCESS_FILE_DISPERSIONS_RESPONSE:
      return {
        ...state,
        processFileDispersionsResponse: action.processFileDispersionsResponse,
      };
    case ActionTypes.SET_SHOW_ALERT_ON_PROCESS_FILE_REQUEST:
      return {
        ...state,
        showAlertOnProcessFileRequest: action.showAlertOnProcessFileRequest,
      };
    case ActionTypes.SET_SHOW_ALERT_ON_GET_DISPERSION_FILE:
      return {
        ...state,
        showAlertOnGetDispersionFile: action.showAlertOnGetDispersionFile,
      };
    case ActionTypes.SET_DISPERSION_FILE_RESPONSE:
      return {
        ...state,
        getFileDispersionResponse: action.getFileDispersionResponse,
      };
    case ActionTypes.SET_IS_LOADING_GET_DISPERSION_FILE:
      return {
        ...state,
        isLoadingGetDispersionFile: action.isLoadingGetDispersionFile,
      };
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filterDispersionRequest: action.filterDispersionRequest,
      };
    case ActionTypes.SET_FILE_DISPERSION_DETAILS:
      return {
        ...state,
        fileStateDispersionDetails: action.fileStateDispersionDetails,
      };
    case ActionTypes.SET_FILE_VALIDATION_DETAILS:
      return {
        ...state,
        fileValidationDetails: action.fileValidationDetails,
      };
    case ActionTypes.SET_IS_LOADING_DETAILS:
      return {
        ...state,
        isLoadingDetails: action.isLoadingDetails,
      };
    case ActionTypes.SET_SHOW_ALERT_ON_DISPERSIONS_VALIDATION:
      return {
        ...state,
        showAlertOnDispersionsValidation:
          action.showAlertOnDispersionsValidation,
      };
    case ActionTypes.SET_SELECTED_DISPERSIONS_TAB:
      return {
        ...state,
        selectedIndexDispersionTab: action.selectedIndexDispersionTab,
      };

    default:
      return state;
  }
};
