import React from "react";
import { FormControl, Grid, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useConditionSimpleLabelState } from "./state/useConditionSimpleLabelState";
import {
  ITEM_SIMPLE_CONDITION,
  ValuesConditionsProps,
} from "../../../../shared/infrastructure/enums/ItemByTagEnum";
import { get } from "lodash";
import { RadioGroupContainer } from "./components/RadioGroupContainer/RadioGroupContainer";

export const ConditionSimpleLabel: React.FC<ValuesConditionsProps> = (
  props: ValuesConditionsProps
) => {
  const { selectSimpleCondition, control, simpleCondition } =
    useConditionSimpleLabelState(props);

  return (
    <Grid item xs={12}>
      <FormControl variant="outlined" fullWidth>
        <Controller
          name={get(ITEM_SIMPLE_CONDITION[props.tag], "name", "")}
          control={control}
          rules={{
            required: false,
          }}
          render={({ ...selectProps }) => (
            <Grid>
              <Grid>
                <Typography variant={"subtitle2"}>
                  {get(ITEM_SIMPLE_CONDITION[props.tag], "label", "")}
                </Typography>
              </Grid>
              <RadioGroupContainer
                tag={props.tag}
                simpleCondition={simpleCondition}
                selectSimpleCondition={selectSimpleCondition}
                selectProps={selectProps}
                isDisable={props.isDisable}
              />
            </Grid>
          )}
        />
      </FormControl>
    </Grid>
  );
};
