import { IStyles } from "../../../shared/interfaces/create_named_styles";

export const useTabItemStyles = (
  isDisabled: boolean,
  selected: boolean,
  statusColor: string
): IStyles => {
  return {
    icon: {
      marginRight: "4px",
    },
    statusText: {
      color: isDisabled ? "text.lightGrey" : statusColor,
    },
    statusContainer: {
      alignItems: "center",
      display: "flex",
      width: "100%",
    },
    tabContainer: {
      "&:hover": {
        backgroundColor: "neutral.grey3",
      },
      backgroundColor:
        selected && !isDisabled ? "neutral.white" : "primary.light3",
      border: "none",
      borderBottom: "3px solid",
      borderBottomColor: selected ? "primary.light2" : "text.lightGrey",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: {
        sm: "56px",
        md: "auto",
        lg: "56px",
      },
      width: "100%",
      px: 2,
      py: 1,
    },
    titleText: {
      textAlign: "left",
      color: isDisabled ? "text.lightGrey" : "text.dark",
      fontWeight: selected ? "500" : "400",
    },
  };
};
