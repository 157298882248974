import { IStyles } from "../../../../../shared/interfaces/Styles.interfaces";

export const InformationTabStyles: IStyles = {
  container: {
    paddingTop: "16px",
  },
  copyButton: {
    color: "text.primary",
    height: "auto",
    padding: 0,
  },
  divider: {
    borderColor: "neutral.grey5",
  },
  dividerContainer: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  informationCard: {
    background: "#F7FAFC",
    borderRadius: "4px",
    marginBottom: "24px",
    paddingX: "16px",
    paddingY: "16px",
  },
  informationContainer: {
    padding: 2,
    width: "100%",
  },
  methodIcon: {
    paddingRight: "10px",
  },
  resendButton: {
    backgroundColor: "text.primary",
    borderRadius: "4px",
  },
  text: {
    color: "text.grey",
    typography: "caption",
  },
  value: {
    color: "text.dark",
    typography: "body2",
  },
};
