import { ChangeEvent, SetStateAction, useState } from "react";
import { IUseDialogsState } from "./useDialogsState.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { ConciliationActionEnum } from "../../../shared/infrastructure/enums/ConciliationActionEnum";
import { TabSourceEnum } from "../../../shared/infrastructure/enums/TabSourceEnum";
import { UpdateTransactionUpdateRequestWS } from "../../../store/thunks/updateTransactionState/updateTransactionState.interfaces";
import updateTransactionState from "../../../store/thunks/updateTransactionState/updateTransactionState";
import { defaultTo, get } from "lodash";
import { RootState } from "../../../store/store";
import { setNotification, showLoading } from "../../../store/actions/app/app";
import { IDialogsProps } from "../Dialogs.interfaces";

const useDialogsState = ({
  handleClose,
  transaction,
  dialog: { buttonText },
}: IDialogsProps): IUseDialogsState => {
  const currentApp = useAppSelector((state: RootState) => state.app);

  const dispatch = useAppDispatch();

  const isSaveButton: boolean = buttonText === "Guardar";

  const isSkipButton: boolean = buttonText === "Omitir";

  const [transactionState, setTransactionState] = useState<string>("");

  const [skipText, setskipText] = useState<string>("");

  const isDisabledAcceptBtn: boolean =
    (isSaveButton && transactionState === "") ||
    (isSkipButton && skipText === "");

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTransactionState(event.target.value as SetStateAction<string>);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setskipText(event.target.value);
    event.preventDefault();
  };

  const handleReset = () => {
    handleClose();
    setskipText("");
    setTransactionState("");
  };

  const handleOnCloseSnackBarAlert = () => {
    dispatch(
      setNotification({
        message: "Close Modal",
        open: false,
        type: "error",
      })
    );
  };

  const getConciliationAction = (type: string) => {
    const action = {
      Enviar: ConciliationActionEnum.PENDING_REFUND,
      Guardar: transactionState,
      Omitir: ConciliationActionEnum.OMITTED,
    };

    return get(action, type, "");
  };

  const handleUpdateTransactionState = async () => {
    const payload = JSON.parse(
      defaultTo(localStorage.getItem("payload"), "{}")
    );

    const observationInfo = isSkipButton
      ? {
          modifiedDate: new Date().getTime().toString(),
          reason: skipText,
          userName: get(payload, "cognito:username", ""),
          userRole: get(payload, "cognito:groups", []).join(","),
        }
      : undefined;

    const request: UpdateTransactionUpdateRequestWS = {
      action: "processConciliationTransactions",
      data: {
        conciliationAction: getConciliationAction(
          buttonText
        ) as ConciliationActionEnum,
        data: [{ conciliationId: transaction.conciliationId }],
        observationInfo: observationInfo,
        source: TabSourceEnum.PENDING,
      },
    };

    handleClose();
    dispatch(showLoading());
    await dispatch(updateTransactionState(request));
    handleReset();

    return request;
  };

  return {
    handleChange,
    handleOnCloseSnackBarAlert,
    handleReset,
    handleTextChange,
    handleUpdateTransactionState,
    isDisabledAcceptBtn,
    isSaveButton,
    isSkipButton,
    notification: currentApp.notification,
    transactionState,
  };
};

export { useDialogsState };
