import React from "react";
import { Box, Typography } from "@material-ui/core";

export interface ICellWithDescriptionProps {
  title: string;
  description: string;
}
export const CellWithDescription: React.FC<ICellWithDescriptionProps> = (
  props: ICellWithDescriptionProps
) => {
  const { title, description } = props;

  return (
    <>
      <Box>{title}</Box>
      <Typography variant="body2" color="textSecondary">
        <Box fontSize={12}>{description}</Box>
      </Typography>
    </>
  );
};
