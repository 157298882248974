import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Typography,
  Tooltip,
} from "@material-ui/core";
import TableMenu from "../commons/TableMenu/TableMenu";
import { format, utcToZonedTime } from "date-fns-tz";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../../shared/infrastructure/constants/TimeZoneCatalog";
import { get, has, isEmpty, isEqual } from "lodash";
import { PaymentMethodEnum } from "../../shared/infrastructure/constants/PaymentMethodEnum";
import { ProcessorFetch } from "../../shared/infrastructure/interfaces/ProcessorFetch";
import { RefreshCcw, Copy } from "react-feather";
import { useProcessorComponentsState } from "../../shared/hooks/ProcessorComponents/useProcessorComponentsState";
import tooltipSvg from "../../assets/images/tooltip.svg";
import { IDefaultProcessorForm } from "../../shared/infrastructure/interfaces/IDefaultProcessorForm";
import { getTranslatedProcessor } from "../../shared/infrastructure/constants/ProcessorsEnum";
import { IUpdateProcessor } from "../../shared/infrastructure/interfaces/IUpdateProcessor";
import {
  ChipProcessorStatusEnum,
  ProcessorStatusEnum,
} from "../../shared/infrastructure/constants/ProcessorsEnum";
import { StyleNamesEnum } from "../../shared/infrastructure/constants/StyleNamesEnum";
import { verifyIsDefaultProcessor } from "../../utils/utilsFile";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    color: "#46525C",
    fontSize: 15,
    padding: "10px 16px",
  },
  cellPrimary: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: 15,
    lineHeight: "120%",
    padding: "8px",
  },
  cellSecondary: {
    color: "#B4B9BE",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "136%",
  },
  cell: {
    color: "#46525C",
    fontWeight: "normal",
    fontSize: 14,
    padding: "8px",
  },
  refreshButton: {
    minWidth: 36,
    width: 36,
    height: 36,
    padding: 7,
  },
  tableCell: {
    padding: "8px",
  },
  [StyleNamesEnum.ACTIVE_STYLE]: {
    height: 0,
    background: "#E0FFF8",
    borderRadius: "4px",
    color: "#0DC298",
    fontSize: 9,
    fontWeight: 600,
    padding: "12px",
  },
  [StyleNamesEnum.DISABLE_STYLE]: {
    height: 0,
    background: "#FEF1D1",
    borderRadius: "4px",
    color: "#DC9350",
    fontSize: 9,
    fontWeight: 600,
    padding: "12px",
  },
  tooltip: {
    marginLeft: "5px",
  },
});

const timeZoneHour = (text: string, value: string): string => {
  const index: number = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  return get(TimeZoneCatalog[index], value, undefined);
};

export const formatDate = (date: number, formatToDate: string): string => {
  const user_time_zone: string = timeZoneHour(
    "UTC-05 (Bogota, Quito, Lima)",
    "region"
  );

  return format(utcToZonedTime(date, user_time_zone), formatToDate);
};

export const formatModel = (model: string): string => {
  if (model === "GATEWAY") return "Gateway";
  if (model === "AGGREGATOR") return "Agregador";

  return model;
};

export const formatPaymentMethod = (
  paymentMethod: string
): { type: string; method: string } => {
  switch (paymentMethod) {
    case PaymentMethodEnum.PAYOUTS_TRANSFER:
      return { type: "Transferencia", method: "Pay Out" };
    case PaymentMethodEnum.TRANSFER:
      return { type: "Transferencia", method: "Pay In" };
    case PaymentMethodEnum.PAYOUTS_CASH:
      return { type: "Efectivo", method: "Pay Out" };
    case PaymentMethodEnum.CASH:
      return { type: "Efectivo", method: "Pay In" };
    case PaymentMethodEnum.TRANSFER_SUBSCRIPTION:
      return { type: "Débito recurrente", method: "Pay In" };
    case PaymentMethodEnum.CARD:
      return { type: "Tarjeta", method: "Pay In" };
    case PaymentMethodEnum.PAYOUTS_CARD:
      return { type: "Tarjeta", method: "Pay Out" };
    case PaymentMethodEnum.INTERNATIONAL_TRANSFER:
      return { type: "Transferencia", method: "Internacional" };
    default:
      return { type: "", method: "" };
  }
};
export const formatPaymentRoute = (paymentMethod: string): string => {
  const routes: object = {
    [PaymentMethodEnum.CASH]: "processor-cash",
    [PaymentMethodEnum.PAYOUTS_CASH]: "processor-payouts-cash",
    [PaymentMethodEnum.PAYOUTS_CARD]: "processor-payouts-card",
    [PaymentMethodEnum.TRANSFER]: "processor-transfer",
    [PaymentMethodEnum.TRANSFER_SUBSCRIPTION]:
      "processor-transfer-subscriptions",
    [PaymentMethodEnum.PAYOUTS_TRANSFER]: "processor-payouts-transfer",
    [PaymentMethodEnum.CARD]: "processor-card",
    [PaymentMethodEnum.INTERNATIONAL_TRANSFER]:
      "processor-international-transfer",
  };

  return routes[paymentMethod];
};

export const getProcessorName = (processor: ProcessorFetch): string => {
  return get(
    processor,
    "publicProcessorId",
    get(processor, "processorName", "")
  );
};

const clipboard = async (text: string): Promise<void> => {
  await window.navigator.clipboard.writeText(text);
};

const validTooltipFailOver = (processor: ProcessorFetch): boolean => {
  return (
    has(processor, "failOverProcessor") &&
    !isEmpty(get(processor, "failOverProcessor"))
  );
};

export interface ITableComponent {
  processors?: ProcessorFetch[];
  defaultProcessors?: IDefaultProcessorForm;
  defaultPayoutsTransferProcessor?: IDefaultProcessorForm;
  handleUpdateProcessor(processor: IUpdateProcessor): void;
  handleOpenDialogFailOver(processor: ProcessorFetch): void;
  handleOpenModalDetail(processor: ProcessorFetch): void;
  handleRefreshProcessors(): void;
}

export const TableComponent = (props: ITableComponent) => {
  const classes = useStyles();
  const { isActionButton } = useProcessorComponentsState();
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow key="header">
              <TableCell key={"head_date"} className={classes.headerCell}>
                Modificado
              </TableCell>
              <TableCell key={"public_id"} className={classes.headerCell}>
                Alias / Public ID
              </TableCell>
              <TableCell key={"processors"} className={classes.headerCell}>
                Procesador
              </TableCell>
              <TableCell key={"model"} className={classes.headerCell}>
                Modelo/Categoría
              </TableCell>
              <TableCell key={"payment_method"} className={classes.headerCell}>
                Medio de pago
              </TableCell>
              <TableCell key={"status"} className={classes.headerCell}>
                Estado
              </TableCell>
              <TableCell key={"options"} className={classes.headerCell}>
                <Button
                  variant={"outlined"}
                  size={"small"}
                  className={classes.refreshButton}
                  onClick={props.handleRefreshProcessors}
                >
                  <RefreshCcw size={24} />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.processors!.map((processor: ProcessorFetch) => {
              const isDefaultProcessor: boolean = verifyIsDefaultProcessor(
                processor,
                props.defaultProcessors,
                props.defaultPayoutsTransferProcessor
              );
              const handleOpenModalDetail = (): void => {
                props.handleOpenModalDetail(processor);
              };
              const [chipText, chipStyle]: string[] = isEqual(
                processor.status,
                ProcessorStatusEnum.DISABLED
              )
                ? [
                    ChipProcessorStatusEnum.DISABLED,
                    StyleNamesEnum.DISABLE_STYLE,
                  ]
                : [
                    ChipProcessorStatusEnum.ENABLED,
                    StyleNamesEnum.ACTIVE_STYLE,
                  ];

              return (
                <TableRow
                  key={`row_${processor.publicProcessorId}`}
                  style={{
                    ...(isDefaultProcessor && { backgroundColor: "#EEF6FF" }),
                  }}
                >
                  <TableCell
                    key={`column_created_${processor.publicProcessorId}`}
                    onClick={handleOpenModalDetail}
                    className={classes.tableCell}
                  >
                    <Box display="block" className={classes.cellPrimary}>
                      <Box>
                        {processor.created
                          ? formatDate(
                              Number(processor.created),
                              "dd-MM-yyyy'T'HH:mm:ss"
                            ).split("T")[0]
                          : ""}{" "}
                        -
                        {processor.created
                          ? formatDate(
                              Number(processor!.created),
                              "dd-MM-yyyy'T'HH:mm:ss"
                            )
                              .split("T")[1]
                              .split(".")[0]
                          : ""}
                      </Box>

                      <Typography
                        variant="subtitle2"
                        className={classes.cellSecondary}
                      >
                        {get(processor, "username", "")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    key={`column_public_id_${processor.publicProcessorId}`}
                    className={classes.cellPrimary}
                    onClick={handleOpenModalDetail}
                  >
                    <Box display="flex" alignItems="center">
                      <Box>
                        {get(processor, "alias", "")}
                        <Typography
                          variant="subtitle2"
                          className={classes.cellSecondary}
                        >
                          {get(
                            processor,
                            "publicProcessorId",
                            get(processor, "processorName", "")
                          )}{" "}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={(e) => {
                          clipboard(getProcessorName(processor));
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Copy />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell
                    key={`column_active_${processor.publicProcessorId}`}
                    className={classes.headerCell}
                    onClick={handleOpenModalDetail}
                  >
                    {getTranslatedProcessor(
                      get(processor, "processorName", "")
                    )}
                  </TableCell>
                  <TableCell
                    key={`column_model_${processor.publicProcessorId}`}
                    className={classes.cell}
                    onClick={handleOpenModalDetail}
                  >
                    <Box>
                      {formatModel(get(processor, "processorType", "-"))}
                      <Typography
                        variant="subtitle2"
                        className={classes.cellSecondary}
                      >
                        {get(processor, "categoryModel", "")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    key={`column_payment_${processor.publicProcessorId}`}
                    className={classes.cell}
                    onClick={handleOpenModalDetail}
                  >
                    {formatPaymentMethod(get(processor, "paymentMethod", "-"))
                      .type === "Tarjeta" ? (
                      <Box display="flex">
                        {
                          formatPaymentMethod(
                            get(processor, "paymentMethod", "-")
                          ).type
                        }
                        {validTooltipFailOver(processor) && (
                          <Tooltip
                            title={`El Failover asignado es ${get(
                              processor,
                              "failOverProcessor",
                              ""
                            )}`}
                            arrow
                            placement="top"
                          >
                            <img
                              alt={"tooltip"}
                              src={tooltipSvg}
                              className={classes.tooltip}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    ) : (
                      formatPaymentMethod(get(processor, "paymentMethod", "-"))
                        .type
                    )}
                    <Typography
                      variant="subtitle2"
                      className={classes.cellSecondary}
                    >
                      {
                        formatPaymentMethod(
                          get(processor, "paymentMethod", "-")
                        ).method
                      }
                    </Typography>
                  </TableCell>
                  <TableCell
                    key={`column_status_${processor.publicProcessorId}`}
                  >
                    <Chip label={chipText} className={classes[chipStyle]} />
                  </TableCell>
                  <TableCell
                    key={`column_cancel_${processor.publicProcessorId}`}
                  >
                    {isActionButton && (
                      <TableMenu
                        isDefaultProcessor={isDefaultProcessor}
                        processors={props.processors}
                        processor={processor}
                        onFailOver={props.handleOpenDialogFailOver}
                        handleUpdateProcessor={props.handleUpdateProcessor}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
