import React, { FC } from "react";
import { Container, Typography } from "@material-ui/core";
import { useDepositDashboardState } from "./state/useDepositDashboardState";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { MainFilters } from "../../components/Filters/MainFilters/MainFilters";
import { DashboardTabs } from "../../components/DashboardTabs/DashboardTabs";
import { TabsTable } from "../../shared/infrastructure/constants/BankConciliationConstans";
import { DashboardTable } from "../../components/DashboardList/Table/DashboardConciliationTable/DashboardTable";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { ModalConciliation } from "@kushki/frontend-molecules/modal-conciliation";
import { ModalDialogDetail } from "../../components/ModalDialogDetail/ModalDialogDetail";
import { SummaryHistoricTable } from "../../components/DashboardList/Table/SummaryHistoricTable/SummaryHistoricTable";
import { SnackBarAlert } from "../../components/common/SnackBar/SnackBarAlert";
import { ConcilationModel } from "../../components/common/ConcilationModal/ConciliationModal";

export const DepositDashboard: FC = () => {
  const {
    actions,
    breadCrumbs,
    state,
    pagination,
    modalOver,
    orderSort,
    filterProps,
    data,
    modalDetail,
    trxReconcileSummaryProps,
    pendingFiltersProps,
    notification,
    loadingFileDownload,
  } = useDepositDashboardState();

  return (
    <>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        <Typography variant="h1" color="primary">
          {state.statusFlow.name}
        </Typography>
        <MainFilters />
        <ModalDialogDetail
          open={modalDetail.open}
          handleCloseDialogDetail={modalDetail.handleCloseDialogDetail}
          transactionSumaryData={modalDetail.transactionTimelineSelected}
          statusFlow={state.statusFlow.value}
        />
        <DashboardTabs
          tabsHeader={[...TabsTable]}
          tabsContent={[
            {
              index: 0,
              children: (
                <>
                  <DashboardTable
                    country={state.country as CountryEnum}
                    isLoading={state.isLoadingConciliation!}
                    statusFlow={state.statusFlow.value as StatusFlowEnum}
                    pagination={pagination}
                    modalOver={modalOver}
                    orderSort={orderSort}
                    filterProps={filterProps}
                    conciliationData={data.conciliationData}
                    pendingFiltersProps={pendingFiltersProps}
                    trxReconcileSummary={trxReconcileSummaryProps}
                    handleDownloadFile={actions.handleDownloadFile}
                    loadingFileDownload={loadingFileDownload}
                    titleNoData={data.titleNoData}
                    sbtNoData={data.sbtNoData}
                  />
                </>
              ),
            },
            {
              index: 1,
              children: (
                <>
                  <SummaryHistoricTable
                    country={state.country as CountryEnum}
                    isLoading={state.isLoadingConciliation!}
                    statusFlow={state.statusFlow.value as StatusFlowEnum}
                    pagination={pagination}
                    modalOver={modalOver}
                    orderSort={orderSort}
                    filterProps={filterProps}
                    conciliationData={data.conciliationData}
                    handleDownloadFile={actions.handleDownloadFile}
                    loadingFileDownload={loadingFileDownload}
                    pendingFiltersProps={pendingFiltersProps}
                    trxReconcileSummary={trxReconcileSummaryProps}
                    titleNoData={data.titleNoData}
                    sbtNoData={data.sbtNoData}
                  />
                </>
              ),
            },
          ]}
        />
        <ModalConciliation {...modalOver.modalDetail} />
        <SnackBarAlert {...notification} />
        <ConcilationModel />
      </Container>
    </>
  );
};
