import * as React from "react";
import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

const SvgIcon = styled(MuiSvgIcon, {
  name: "IconPaymentMethod",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color }) => ({
  fill: color + "!important",
  fontSize: "36px !important",
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconPaymentMethod: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_421_114452)">
          <circle cx="18" cy="18" r="18" fill="#023365" />
          <path
            d="M25.9798 25.0942H10.0202C8.90909 25.0942 8 24.1518 8 23V13.0942C8 11.9424 8.90909 11 10.0202 11H25.9798C27.0909 11 28 11.9424 28 13.0942V23C28 24.1518 27.0909 25.0942 25.9798 25.0942ZM10.0202 12.0471C9.51515 12.0471 9.0101 12.5707 9.0101 13.0942V23C9.0101 23.6283 9.51515 24.0471 10.0202 24.0471H25.9798C26.5859 24.0471 26.9899 23.5236 26.9899 23V13.0942C26.9899 12.466 26.4848 12.0471 25.9798 12.0471H10.0202Z"
            fill="white"
          />
          <path d="M27.2929 14H8V15.0471H27.2929V14Z" fill="white" />
          <path d="M20.5404 19H8V20.0471H20.5404V19Z" fill="white" />
          <path d="M20.5404 21H8.43774V22.0471H20.5404V21Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_421_114452">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconPaymentMethod;
