export enum ActionTypes {
  SET_NOTIFICATION = "[RETENTIONS] SET_NOTIFICATION",
  SET_NOTIFICATION_CONFIG = "[RETENTIONS] SET_NOTIFICATION_CONFIG",
  SET_LOADING = "[RETENTIONS] SET_LOADING",
  SET_RETENTION = "[RETENTIONS] SET_RETENTION",
  SET_EDIT_RETENTION = "[RETENTIONS] SET_EDIT_RETENTION",
  SET_CSV_TEMPLATE = "[RETENTIONS] SET_CSV_TEMPLATE",
  SET_EXCEL_TEMPLATE = "[RETENTIONS] SET_EXCEL_TEMPLATE",
  SET_RETENTION_CONFIG = "[RETENTIONS] SET_RETENTION_CONFIG",
  SET_OPEN_MODAL_CONFIRM_ADD_CONFIG = "[RETENTIONS] SET_OPEN_MODAL_CONFIRM_ADD_CONFIG",
  SET_CATALOG_REQUEST = "[RETENTIONS] SET_CATALOG_REQUEST",
  SET_CATALOG_ACTION = "[RETENTIONS] SET_CATALOG_ACTION",
  SET_IS_OPEN_CATALOG_FORM_POPUP = "[RETENTIONS] SET_IS_OPEN_CATALOG_FORM_POPUP",
  SET_IS_OPEN_CATALOG_CONFIRM_POPUP = "[RETENTIONS] SET_IS_OPEN_CATALOG_CONFIRM_POPUP",
  SET_IS_OPEN_CATALOG_INFO_POPUP = "[RETENTIONS] SET_IS_OPEN_CATALOG_INFO_POPUP",
  SET_LOADING_RETENTION_DASHBOARD = "[RETENTIONS] SET_LOADING_RETENTION_DASHBOARD",
  SET_LOADING_DELETE_RETAIL_CONFIG = "[RETENTIONS] SET_LOADING_DELETE_RETAIL_CONFIG",
  SET_COMPLETED_DELETE_RETAIL_CONFIG = "[RETENTIONS] SET_COMPLETED_DELETE_RETAIL_CONFIG",
  SET_ERROR_CSV = "[RETENTIONS] SET_ERROR_CSV",
  SET_FILE_SENT = "[RETENTIONS] SET_FILE_SENT",
  SET_LOAD_BY_FORM_ERROR = "[RETENTIONS] SET_LOAD_BY_FORM_ERROR",
  EXIST_RETENTION = "[RETENTIONS] EXIST_RETENTION",
  SET_LOADING_VERIFY_EXIST_RETENTION = "[RETENTIONS]  SET_LOADING_VERIFY_EXIST_RETENTION",
  SET_ADD_RETENTION = "[RETENTIONS]  SET_ADD_RETENTION",
  SET_IS_EDIT_RETENTION = "[RETENTIONS] SET_IS_EDIT_RETENTIONS",
  SET_COLUMNS_CONFIG_RETENTION_EC = "[RETENTIONS] SET_COLUMNS_CONFIG_RETENTION_EC",
  SET_INDEX_TAB_EDIT_RETENTION = "[RETENTIONS] SET_INDEX_TAB_EDIT_RETENTION",
  SET_EDIT_RETENTION_DASHBOARD = "[RETENTIONS] SET_EDIT_RETENTION_DASHBOARD",
  SET_EDIT_RETENTION_VALIDATE = "[RETENTIONS] SET_EDIT_RETENTION VALIDATE",
  SET_ADD_RETENTION_DIRECTLY = "[RETENTIONS] SET_ADD_RETENTION_DIRECTLY",
  SET_CODE_DATIL = "[RETENTIONS] SET_CODE_DATIL",
  SET_RETENTIONS_CONFIG_FILE = "[RETENTIONS] SET_RETENTIONS_EC_CONFIG_FILE",
  SET_CATALOGS_LIST = "[RETENTIONS] SET_CATALOGS_LIST",
  SET_FILE_SENT_CATALOG = "[RETENTIONS] SET_FILE_SENT_CATALOG",
  SET_BASE64_CSV = "SET_BASE64_CSV",
}
