import React from "react";
import { Dialog } from "@mui/material";
import { ModalFormProps } from "./ModalForm.interfaces";
import { modalFormStyle } from "./ModalForm.styles";

const ModalForm: React.FC<ModalFormProps> = ({
  children,
  modalSize,
  openModal,
}: ModalFormProps) => {
  return modalSize === "md" ? (
    <Dialog open={openModal} maxWidth={modalSize} fullWidth={true}>
      {children}
    </Dialog>
  ) : (
    <Dialog open={openModal} sx={modalFormStyle.shareHolders} fullWidth={true}>
      {children}
    </Dialog>
  );
};

export default ModalForm;
