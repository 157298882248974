import React from "react";
import visa from "../../assets/images/logos/newVisa.svg";
import mastercard from "../../assets/images/logos/newMastercard.svg";
import diners from "../../assets/images/logos/newDiners.svg";
import amex from "../../assets/images/logos/newAmex.svg";
import {
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  DeferredOption,
  PeruDeferredOption,
} from "../../../types/get_merchant_deferred_response";
import { StatusEnum } from "../../shared/infrastructure/constants/statusEnum";
import CardsComponent, {
  ICard,
} from "../commons/CardsComponent/CardsComponent";

const useStyles = makeStyles((theme: Theme) => ({
  merchantStatusDisableText: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    width: 240,
  },
  tableBodyCell: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(5)}px ${theme.spacing(
      1.5
    )}px ${theme.spacing(2.25)}px`,
  },
  tableHead: {
    backgroundColor: "#F7FAFC",
  },
  tableHeadCell: {
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
    paddingRight: theme.spacing(3),
  },
}));

export interface ISimpleDeferredTableProps {
  merchantDeferred: DeferredOption;
  deferredStatus: boolean;
  handleClickSwitchStatus: () => void;
}

const brands: ICard[] = [
  {
    height: 38,
    source: mastercard,
    width: 37,
  },
  {
    height: 38,
    source: visa,
    width: 38,
  },
  {
    bgColor: "#006FCF",
    height: 26,
    source: amex,
    width: 37,
  },
  {
    height: 26,
    source: diners,
    width: 37,
  },
];

const SimpleDeferredTable: React.FC<ISimpleDeferredTableProps> = ({
  merchantDeferred,
  deferredStatus,
  handleClickSwitchStatus,
}: ISimpleDeferredTableProps) => {
  const classes = useStyles();
  const deferredOption: PeruDeferredOption =
    merchantDeferred as PeruDeferredOption;

  const getStatusHeader = (merchantStatus: string) => {
    if (merchantStatus === StatusEnum.ENABLED)
      return (
        <TableCell className={classes.tableHeadCell} align={"right"}>
          Desactivar/Activar
        </TableCell>
      );
    else
      return (
        <TableCell className={classes.tableHeadCell} align={"center"}>
          Estado
        </TableCell>
      );
  };

  const getStatusBody = (merchantStatus: string) => {
    if (merchantStatus === StatusEnum.ENABLED)
      return (
        <TableCell
          align={"right"}
          className={classes.tableBodyCell}
          onClick={handleClickSwitchStatus}
        >
          <Switch color="primary" checked={deferredStatus} />
        </TableCell>
      );
    else
      return (
        <TableCell className={classes.merchantStatusDisableText}>
          Este diferido esta deshabilitado para tu comercio.
        </TableCell>
      );
  };

  return (
    <Box key="box_container" bgcolor="white" mt={3}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>
              Marca de tarjetas
            </TableCell>
            {getStatusHeader(deferredOption.merchantStatus)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableBodyCell}>
              <CardsComponent
                cards={brands}
                height={34}
                width={68}
                marginRight={2}
              />
            </TableCell>
            {getStatusBody(deferredOption.merchantStatus)}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default SimpleDeferredTable;
