import { IStyles } from "../../shared/interfaces/create_named_styles";

export const selectCountryStyles: IStyles = {
  listBox: {
    maxHeight: "240px",
    borderRadius: "8px",
  },
  option: {
    color: "text.dark",
    svg: { color: "text.dark", width: "16px", height: "16px" },
  },
  inputProps: {
    ".MuiAutocomplete-input": { pl: "0px !important" },
  },
  inputAdornment: {
    svg: { color: "text.primary", width: "16px", height: "16px" },
  },
  autoComplete: {
    width: "100%",
  },
};
