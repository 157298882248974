import { Rule } from "../../../types/get_payouts_rules_response";
import { ISaveRuleModal } from "../interfaces/ISimpleModal";
import { ILoadingModal } from "../interfaces/ILoadingModal";
import { IAlert } from "../interfaces/IAlert";

export const DEFAULT_LOADING_MODAL: ILoadingModal = {
  isLoading: false,
  message: "",
};
export const DEFAULT_SIMPLE_MODAL: ISaveRuleModal = {
  action: "",
  buttonPrimaryText: "",
  buttonSecondary: "",
  descriptionText: "",
  isOpen: false,
  titleText: "",
};

export const RULE_INITIAL_STATE: Rule = {
  action: "",
  conditions: [],
  id: "",
  merchantId: "",
  name: "",
  processorId: "",
  processorName: "",
  status: "",
  type: "",
};

export const OPEN_ALERT_INITIAL_STATE: IAlert = {
  isError: false,
  open: false,
  originAlert: "",
};
