import { filtersSlice } from "../../reducers/filters/filters";

export const {
  setDateFilter,
  setPaginationFilter,
  setSearchFilter,
  setHeaderFilter,
  setTransactionsPage,
  setTransactionsLimit,
  setGeneralFilter,
  setSearchClick,
  setBodyFilter,
} = filtersSlice.actions;
