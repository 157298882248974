import React, { PropsWithChildren } from "react";
import { ModalSimple } from "@kushki/connect-ui";
import {
  ButtonColorEnum,
  ButtonVariantEnum,
} from "../../shared/enums/ButtonStylesEnum";
import { IMassiveDeferredModalProps } from "./IMassiveDeferredModalProps.interfaces";

export const MassiveDeferredModal: React.FC<IMassiveDeferredModalProps> = (
  props: PropsWithChildren<IMassiveDeferredModalProps>
) => {
  return (
    <ModalSimple
      onClickPrimary={props.onClickPrimary}
      onClickSecondary={props.onClickSecondary}
      buttonPrimary={props.buttonPrimaryText}
      descriptionText={props.descriptionText}
      isOpen={props.isOpenModal}
      onClose={props.handleCloseModal}
      titleText={props.titleText}
      typeModal={1}
      buttonSecondary={props.buttonSecondaryText}
      buttonPrimaryVariant={ButtonVariantEnum.Contained}
      buttonPrimaryColor={ButtonColorEnum.Primary}
    />
  );
};
