import React from "react";
import { Box, Container, Divider, Grid } from "@mui/material";
import {
  breadcrumbGeneralRuleCreation,
  breadcrumbRuleCreationDetailValues,
  breadcrumbRuleUpdateValues,
  VALID_GENERAL_ACTIONS,
  SubTitleSection,
  TitleSection,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { CardSection } from "../../components/CardSection/CardSection";
import { GeneralInformationSection } from "../../components/GeneralInformation/GeneralInformationSection";
import { useCreateRuleState } from "./state/useCreateRuleState";
import { FormProvider } from "react-hook-form";
import GeneralAction from "../../components/GeneralAction/GeneralAction";
import { GeneralConditionSection } from "../../components/GeneralConditionSection/GeneralConditionSection";
import SpeedConditionSection from "../../components/SpeedConditionSection/SpeedConditionSection";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { CustomBreadcrumb } from "../../components/common/CustomBreadcrumb/CustomBreadcrumb";
import { securityRuleDetailStyles } from "./SecurityRuleDetail.styles";
import { isFormEditMode } from "../../utils/utilsFile";
import { CreateRuleSection } from "../../components/CreateRuleSection/CreateRuleSection";
import { ConfirmModalEnum } from "../../shared/infrastructure/enums/ConfirmModalEnum";
import { AdditionalConfigSection } from "../../components/AdditionalConfig/AdditionalConfig";
import { IBreadCrumbItemProps } from "../../components/common/Breadcrumb/Breadcrumb.interfaces";
import { ModalSimple } from "@kushki/connect-ui";
import SpecialConditionSection from "../../components/SpecialConditionSection/SpecialConditionSection";
import { defaultTo } from "lodash";

export const SecurityRuleDetail: React.FC = () => {
  const {
    availableConditions,
    form,
    fields,
    handle,
    ruleId,
    ruleName,
    actions,
    processors,
    credentials,
    geographyList,
    configuration,
    ruleCountries,
    branchValue,
    customerValue,
    isLoading,
    isGeneralRule,
    isUpdatedRule,
    isSelectedGeneralRule,
    isRender,
    openConfirmGeneralRuleModal,
    propsModalConfirm,
    savingRule,
    transactionTypeList,
    mccList,
    merchantCategoryList,
    isDisabledSpeed,
    isDisabledSpecial,
    actionType,
  } = useCreateRuleState();

  const classes = securityRuleDetailStyles();
  let breadCrumb: IBreadCrumbItemProps[];

  if (isFormEditMode(ruleId || ruleName)) {
    breadCrumb = breadcrumbRuleUpdateValues;
  } else if (isGeneralRule) {
    breadCrumb = breadcrumbGeneralRuleCreation;
  } else {
    breadCrumb = breadcrumbRuleCreationDetailValues;
  }

  return (
    <Container>
      <Box pb={2}>
        <CustomBreadcrumb
          items={breadCrumb}
          splitButton={undefined}
          type={"titleBase"}
          title={
            isFormEditMode(ruleId || ruleName)
              ? TitleSection.RULES_UPDATE_RULE
              : TitleSection.RULES_CREATE
          }
        />
      </Box>
      {(isUpdatedRule ? isRender() : true) && (
        <FormProvider {...form}>
          <Grid container spacing={2}>
            {isFormEditMode(ruleId) &&
              !isGeneralRule &&
              !isSelectedGeneralRule && (
                <CardSection
                  sectionComponent={<CreateRuleSection />}
                  sectionTitle={TitleSection.RULES_DATA_GENERAL}
                  sectionDescription={SubTitleSection.RULES_CREATE_SUBTITLE}
                />
              )}
            <CardSection
              sectionComponent={<GeneralInformationSection />}
              sectionTitle={TitleSection.RULES_CONFIGURATION}
              sectionDescription={SubTitleSection.RULES_CONFIGURATION_SUBTITLE}
            />
            <CardSection
              sectionComponent={<GeneralAction configuration={configuration} />}
              sectionTitle={TitleSection.RULES_GENERAL_ACTION}
              sectionDescription={SubTitleSection.RULES_GENERAL_ACTION_SUBTITLE}
            />
            {
              <CardSection
                sectionComponent={
                  <>
                    {fields.map(
                      (
                        item: Partial<
                          Record<string, any> & Record<"id", string>
                        >,
                        index: number
                      ) => (
                        <React.Fragment key={item.id}>
                          <GeneralConditionSection
                            append={actions.append}
                            country={ruleCountries}
                            credentials={credentials}
                            generalConditions={availableConditions}
                            generalOperators={configuration?.operators}
                            generalTransactionsType={transactionTypeList}
                            mccList={mccList}
                            merchantCategoryList={merchantCategoryList}
                            geographyList={geographyList}
                            index={index}
                            isLoading={false}
                            item={item}
                            key={item.id}
                            processors={processors}
                            remove={actions.remove}
                            total={fields}
                            isSpecialCond={false}
                          />
                        </React.Fragment>
                      )
                    )}
                    {VALID_GENERAL_ACTIONS.includes(
                      defaultTo(actionType, "")
                    ) && (
                      <>
                        <Grid container sx={{ mt: 3, mb: 1 }}>
                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                        </Grid>
                        <SpecialConditionSection
                          configuration={configuration}
                          country={ruleCountries}
                          form={form}
                          isDisabledSpecial={isDisabledSpecial}
                          setIsDisabledSpeed={handle.setIsDisabledSpeed}
                          fieldsCond={fields}
                        />
                      </>
                    )}
                    <Grid container sx={{ mt: 3, mb: 1 }}>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    </Grid>
                    <SpeedConditionSection
                      configuration={configuration}
                      country={ruleCountries}
                      form={form}
                      isDisabledSpeed={isDisabledSpeed}
                      setIsDisabledSpecial={handle.setIsDisabledSpecial}
                      fieldsCond={fields}
                    />
                    <AdditionalConfigSection form={form} />
                  </>
                }
                sectionTitle={TitleSection.RULES_GENERAL_CONDITIONS}
                sectionDescription={
                  SubTitleSection.RULES_GENERAL_CONDITIONS_SUBTITLE
                }
              />
            }
            <ActionButtons
              onCancel={actions.handleCancelAction}
              form={form}
              fields={fields}
              handleSendToReview={handle.handleSendToReview}
            />
            <ConfirmModal
              modalTitle={
                isFormEditMode(ruleId || ruleName)
                  ? ConfirmModalEnum.TITLE_EDIT
                  : ConfirmModalEnum.TITLE
              }
              modalContentText={
                isFormEditMode(ruleId || ruleName)
                  ? ConfirmModalEnum.BODY_EDIT
                  : ConfirmModalEnum.BODY
              }
              branch={branchValue}
              customer={customerValue}
              isFormEditMode={isFormEditMode(ruleId || ruleName)}
              onClose={handle.handleCancelModal}
              onSubmit={handle.handleSendToReview}
              isLoading={isLoading}
            />
            <ModalSimple
              isOpen={openConfirmGeneralRuleModal}
              onClose={handle.handleCancelModalConfirmGeneralRule}
              typeModal={1}
              onClickPrimary={propsModalConfirm.onClickPrimary}
              onClickSecondary={propsModalConfirm.onClickSecondary}
              buttonPrimary={propsModalConfirm.buttonPrimary}
              buttonSecondary={propsModalConfirm.buttonSecondary}
              descriptionText={propsModalConfirm.descriptionText}
              titleText={propsModalConfirm.titleText}
              buttonPrimaryDisabled={savingRule}
              buttonSecondaryDisabled={savingRule}
            />
          </Grid>
        </FormProvider>
      )}
    </Container>
  );
};
