import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import {
  FieldOptionValue,
  OperatorOptionLabel,
  OperatorOptionValue,
  SiNoEnum,
  TypeEnum,
  VariableLabels,
  YesNoEnum,
} from "@shared/constants/AlarmConfigConstants";
import { FrequencyLabel, FrequencyValue } from "@shared/enum/FrequencyEnum";
import { MerchantCategoryEnum } from "@shared/enum/merchantCategoryEnum";

export const OPTION: Record<string, ISelectOption[]> = {
  field: [
    {
      isDisabled: false,
      label: VariableLabels.PEP,
      value: FieldOptionValue.PEP,
    },
    {
      isDisabled: false,
      label: VariableLabels.AVERAGE_TICKET,
      value: FieldOptionValue.AVERAGE_TICKET,
    },
    {
      isDisabled: false,
      label: VariableLabels.TPV,
      value: FieldOptionValue.TPV,
    },
    {
      isDisabled: false,
      label: VariableLabels.MODEL,
      value: FieldOptionValue.MODEL,
    },
    {
      isDisabled: false,
      label: VariableLabels.MCC,
      value: FieldOptionValue.MCC,
    },
    {
      isDisabled: false,
      label: VariableLabels.CONSTITUTION_COUNTRY,
      value: FieldOptionValue.CONSTITUTIONAL_COUNTRY,
    },
    {
      isDisabled: false,
      label: VariableLabels.CATEGORY,
      value: FieldOptionValue.CATEGORY,
    },
    {
      isDisabled: false,
      label: VariableLabels.CONSTITUTIONAL_DATE,
      value: FieldOptionValue.CONSTITUTION_DATE,
    },
    {
      isDisabled: false,
      label: VariableLabels.STANDARD_DEVIATION,
      value: FieldOptionValue.STANDARD_DEVIATION,
    },
  ],
  frequency: [
    { label: FrequencyLabel.DIARIA, value: FrequencyValue.DAILY },
    { label: FrequencyLabel.SEMANAL, value: FrequencyValue.WEEKLY },
    { label: FrequencyLabel.QUINCENAL, value: FrequencyValue.BIWEEKLY },
    { label: FrequencyLabel.MENSUAL, value: FrequencyValue.MONTHLY },
    { label: FrequencyLabel.TRIMESTRAL, value: FrequencyValue.QUARTERLY },
    { label: FrequencyLabel.SEMESTRAL, value: FrequencyValue.BIANNUAL },
  ],
  merchantCategory: [
    { label: MerchantCategoryEnum.SMALL, value: MerchantCategoryEnum.SMALL },
    { label: MerchantCategoryEnum.MEDIUM, value: MerchantCategoryEnum.MEDIUM },
    {
      label: MerchantCategoryEnum.ENTERPRISE,
      value: MerchantCategoryEnum.ENTERPRISE,
    },
  ],
  model: [
    { label: "Gateway", value: "Gateway" },
    { label: "Agregador Formal", value: "Agregador | Formal" },
    { label: "Agregador Pasarela", value: "Agregador | Pasarela" },
    {
      label: "Agregador Recaudo directo",
      value: "Agregador | Recaudo directo",
    },
  ],
  operator: [
    {
      isDisabled: false,
      label: OperatorOptionLabel.IGUAL,
      value: OperatorOptionValue.EQUAL,
    },
    {
      isDisabled: false,
      label: OperatorOptionLabel.DIFERENTE,
      value: OperatorOptionValue.DIFFERENT,
    },
    {
      isDisabled: false,
      label: OperatorOptionLabel.MAYOR_QUE,
      value: OperatorOptionValue.GREATER_THAN,
    },
    {
      isDisabled: false,
      label: OperatorOptionLabel.MENOR_QUE,
      value: OperatorOptionValue.LESS_THAN,
    },
  ],
  yes_no: [
    { label: SiNoEnum.SI, value: YesNoEnum.YES },
    { label: SiNoEnum.No, value: YesNoEnum.NO },
  ],
};

export const fieldOptionsByType = (type: string): ISelectOption[] => {
  if (type === TypeEnum.INDIVIDUAL) {
    return OPTION.field.filter(
      (opt) =>
        ![
          FieldOptionValue.MCC,
          FieldOptionValue.CONSTITUTIONAL_COUNTRY,
          FieldOptionValue.CATEGORY,
          FieldOptionValue.CONSTITUTION_DATE,
          FieldOptionValue.STANDARD_DEVIATION,
        ].includes(opt.value as FieldOptionValue)
    );
  } else if (type === TypeEnum.GENERAL) {
    return OPTION.field;
  } else return [];
};
