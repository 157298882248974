import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { IRoleInfo } from "../infrastructure/interfaces/IRoleInfo";
import { fromUnixTime, differenceInMinutes } from "date-fns";
import { ConfirmModal } from "./ConfirmModal/ConfirmModal";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../store/reducer";
import {
  IAppAction,
  logoutSupportSessionRequest,
  statusCodeSupport,
} from "../store/actionCreators";
import { connect } from "react-redux";
import { RoleService } from "../services/RoleService";
import { useForm } from "react-hook-form";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import { IStatusSessionSupport } from "../infrastructure/interfaces/IStatusSessionSupport";
import { SessionStatusEnum } from "../shared/SessionStatusEnum";

const styles = () => ({
  box: {
    background: "white",
    borderRadius: 4,
  },
  title: {
    fontSize: 10,
    fontWeight: 400,
    textAlign: "center" as "center",
    color: "#293036",
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center" as "center",
    color: "#293036",
  },
  closeSessionLabel: {
    textTransform: "none" as "none",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#023365",
  },
});

interface ISupportCommentFormData {
  supportComment: string;
}

interface ISupportSessionDispatchProps {
  logoutSupportSessionRequest: (supportClose: object) => void;
  getStatusSession: (payload: string) => void;
}

interface ISupportSessionTimerStateProps {
  roleInfo: IRoleInfo | null | undefined;
  merchantInfo: IMerchantForm | null | undefined;
  statusSession: IStatusSessionSupport | undefined;
}

type SupportSessionTimerProps = ISupportSessionDispatchProps &
  ISupportSessionTimerStateProps;

const SupportSessionTimer = (props: SupportSessionTimerProps) => {
  const { errors, handleSubmit, register } = useForm<ISupportCommentFormData>();
  const inlineStyles = styles();
  const [supportSessionTimeLeft, setSupportSessionTimeLeft] = useState<
    number
  >();
  const [openLogoutConfirmModal, setOpenLogoutConfirmModal] = useState<boolean>(
    false
  );
  const [
    openSupportSessionCommentsModal,
    setOpenSupportSessionCommentsModal,
  ] = useState<boolean>(false);
  let timeInterval: NodeJS.Timeout;

  useEffect(() => {
    timeInterval = setInterval(() => {
      const diffInMinutes = differenceInMinutes(
        new Date(),
        fromUnixTime(Number(props.roleInfo!.supportSessionTimeConnectStart))
      );
      const leftMinutes =
        getSupportSessionTimeConnectInMinutes(
          props.roleInfo!.supportSessionTimeConnect
        ) - diffInMinutes;

      if (leftMinutes < 0) {
        handleLogoutSupportSession();
        clearTimeInterval();
        setSupportSessionTimeLeft(0);
        return;
      }

      if (leftMinutes === 0) setSupportSessionTimeLeft(1);
      if (leftMinutes > 0) setSupportSessionTimeLeft(leftMinutes);
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.roleInfo!.supportSessionAccessCode) {
      props.getStatusSession(props.roleInfo!.supportSessionAccessCode);
    }
  }, [props.roleInfo]);

  useEffect(() => {
    if (props.statusSession !== undefined) {
      if (props.statusSession.status === SessionStatusEnum.CLOSE) {
        setOpenSupportSessionCommentsModal(true);
      }
      if (props.statusSession.status === SessionStatusEnum.COMPLETED) {
        RoleService.restoreAuthPayload();
        window.location.reload();
      }
    }
  }, [props.statusSession]);

  const clearTimeInterval = () => {
    clearInterval(timeInterval);
  };

  const getSupportSessionTimeConnectInMinutes = (
    supportSessionTimeConnect: string
  ): number => {
    if (supportSessionTimeConnect.includes("m"))
      return Number(supportSessionTimeConnect.split("m")[0]);
    else supportSessionTimeConnect.includes("h");
    return Number(supportSessionTimeConnect.split("h")[0]) * 60;
  };

  const handleClickLogout = () => () => {
    setOpenLogoutConfirmModal(true);
  };

  const handleCloseLogoutConfirmModal = () => {
    setOpenLogoutConfirmModal(false);
  };

  const handleAcceptLogoutConfirmModal = () => {
    setOpenLogoutConfirmModal(false);
    handleLogoutSupportSession();
  };

  const handleLogoutSupportSession = () => {
    setOpenSupportSessionCommentsModal(true);
  };

  const handleCloseSupportSessionCommentsModal = () => {
    setOpenSupportSessionCommentsModal(false);
  };

  const handleAcceptSupportSessionCommentsModal = (
    formData: UnpackNestedValue<ISupportCommentFormData>
  ) => {
    const data: object = {
      accessCode: props.roleInfo?.supportSessionAccessCode,
      isCompleted: true,
      messageCloseSession: formData.supportComment,
    };

    setOpenSupportSessionCommentsModal(false);
    props.logoutSupportSessionRequest(data);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={inlineStyles.box}
        mr={2}
        ml={2}
        mt={0.5}
        mb={0.5}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          pl={2}
          pr={1}
        >
          <Typography style={inlineStyles.title}>Sesión activa:</Typography>
          <Typography style={inlineStyles.subTitle}>
            {props.roleInfo?.supportSessionAccessCode}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          px={1}
          py={0.5}
        >
          <Typography style={inlineStyles.title}>Tiempo restante:</Typography>
          <Typography style={inlineStyles.subTitle}>
            {supportSessionTimeLeft !== undefined &&
              `${supportSessionTimeLeft} ${
                supportSessionTimeLeft === 1 ? "minuto" : "minutos"
              }`}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          px={1}
          onClick={handleClickLogout()}
        >
          <Button color="primary">
            <Typography style={inlineStyles.closeSessionLabel}>
              Cerrar Sesión
            </Typography>
          </Button>
        </Box>
      </Box>
      <ConfirmModal
        open={openLogoutConfirmModal}
        titleText={"¿Deseas cerrar sesión?"}
        contentText={`Si cierras sesión ahora, no tendrás acceso a visualizar tu cuenta de ${props.merchantInfo?.name} en la Console de Cliente.`}
        acceptText="Cerrar sesión"
        handleClose={handleCloseLogoutConfirmModal}
        handleAccept={handleAcceptLogoutConfirmModal}
      />
      <ConfirmModal
        open={openSupportSessionCommentsModal}
        titleText={"Comentarios"}
        contentText={
          "La sesión ha finalizado. Indica brevemente el problema del cliente y como fue resuelto"
        }
        acceptText="Guardar"
        acceptColor="primary"
        handleClose={handleCloseSupportSessionCommentsModal}
        handleAccept={handleSubmit(handleAcceptSupportSessionCommentsModal)}
      >
        <TextField
          id="support-comment"
          label="Comentarios"
          name="supportComment"
          variant="outlined"
          fullWidth
          multiline
          error={!!errors.supportComment}
          rowsMax={4}
          rows={4}
          className={"support-comment"}
          inputRef={register({
            required: true,
          })}
          margin="normal"
          helperText={
            !!errors.supportComment &&
            "Debes ingresar un comentario para finalizar la sesión."
          }
        />
      </ConfirmModal>
    </>
  );
};

const mapDispatchToProps: (
  dispatch: Dispatch
) => ISupportSessionDispatchProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): ISupportSessionDispatchProps => ({
  logoutSupportSessionRequest: (supportClose: object): void =>
    dispatch(logoutSupportSessionRequest(supportClose)),
  getStatusSession: (payload: string): void =>
    dispatch(statusCodeSupport(payload)),
});

const mapStateToProps: (state: IAppState) => ISupportSessionTimerStateProps = (
  state: IAppState
): ISupportSessionTimerStateProps => ({
  merchantInfo: state.merchant,
  roleInfo: state.roleInfo,
  statusSession: state.statusSession,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportSessionTimer);
