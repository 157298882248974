import React, { useEffect } from "react";
import {
  Button as MuiButton,
  Grid,
  Typography,
  withStyles,
  IconButton,
  Box,
} from "@material-ui/core";
import { routes } from "../../../shared/infrastructure/routes";
import { useHistory } from "react-router-dom";
import { MerchantCreateUpdateResponse } from "../../../../types/merchant_create_update_response";
import {
  COUNTRIES,
  ICountry,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { makeStyles } from "@material-ui/core/styles";
import CopyIcon from "../../../assets/image/copy.svg";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SkeletonHeader } from "../SkeletonHeader/SkeletonHeader";
import { getAppConfigPaymentMethods } from "../../../store/actionCreators";

export type CreateMerchantHeaderProps = {
  setIsEdit(isEdit: boolean): void;
  merchant: MerchantCreateUpdateResponse;
  onCopy: (text: string, result: boolean) => void;
};

const useStyles = makeStyles({
  root: {
    marginBottom: "48px",
  },
  countryFlag: {
    marginRight: 10,
  },
  copyIcon: {
    marginLeft: 10,
    color: "#CBD5E0",
  },
});

export const EditMerchantButton = withStyles({
  root: {
    marginLeft: "24px",
    width: "100px",
  },
  outlined: {
    padding: "4px 12px",
  },
})(MuiButton);

const LabelText = withStyles({
  root: {
    fontWeight: 500,
    fontSize: "14px",
  },
})(Typography);

const MerchantIdLabel = withStyles({
  root: {
    fontWeight: 400,
    fontSize: "14px",
  },
})(Typography);

const HeaderText = withStyles((theme) => ({
  root: {
    ...theme.typography.h1,
    fontWeight: 100,
  },
}))(Typography);

export const CreateMerchantHeader: React.FC<CreateMerchantHeaderProps> = (
  props
) => {
  const { merchant, setIsEdit, onCopy } = props;
  if (!merchant) return <SkeletonHeader />;

  const classes = useStyles();
  const history = useHistory();
  const country: ICountry = COUNTRIES.find(
    (c) => c.value === merchant.country
  )!;

  useEffect(() => {
    getAppConfigPaymentMethods();
  }, []);

  return (
    <div className={classes.root}>
      <HeaderText color="primary" data-testid="header">
        Crear nuevo comercio
      </HeaderText>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid container direction="row" justify="flex-start">
            <Grid item>
              <Typography
                variant="h4"
                color="primary"
                data-testid="merchant-name"
              >
                {merchant.name}
              </Typography>
            </Grid>
            <Grid item>
              <EditMerchantButton
                onClick={() => {
                  setIsEdit(true);
                  history.push(
                    `${routes.BASE_PATH_CREATE_MERCHANT}${routes.INDEX}?hideSideBar=true`
                  );
                }}
                variant="outlined"
              >
                <LabelText>Editar</LabelText>
              </EditMerchantButton>
            </Grid>
          </Grid>
          <Grid container>
            <img
              src={country.flag}
              width="20px"
              height="20px"
              alt={country.flag}
              className={classes.countryFlag}
            />
            <Typography data-testid="country" color="primary">
              {country.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          alignItems={"flex-end"}
          direction={"column"}
        >
          <div>
            <LabelText>{"ID de Comercio"}</LabelText>
            <Grid container direction={"row"} justify={"flex-end"}>
              <Box display="flex" alignItems="center">
                <MerchantIdLabel data-testid="public-merchant-id">
                  {merchant.publicMerchantId}
                </MerchantIdLabel>
                <IconButton className={classes.copyIcon}>
                  <CopyToClipboard
                    text={merchant.publicMerchantId}
                    onCopy={onCopy}
                  >
                    <img
                      src={CopyIcon.toString()}
                      width="20px"
                      height="20px"
                      alt="copy"
                    />
                  </CopyToClipboard>
                </IconButton>
              </Box>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
