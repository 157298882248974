import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { Palette } from "../../themeui";
import { ICustomSwitchProps } from "./CustomSwitch.interfaces";

export const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    checked: {},
    focusVisible: {},
    root: {
      border: "none",
      height: 24,
      margin: theme.spacing(1),
      padding: 0,
      width: 40,
    },
    switchBase: {
      "&$checked": {
        "& + $track": {
          backgroundColor: Palette.primary.light2,
          opacity: 1,
        },
        color: theme.palette.common.white,
        transform: "translateX(16px)",
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
      },
      padding: 1,
      paddingLeft: 4,
      paddingTop: 4,
    },
    thumb: {
      height: 16,
      width: 16,
    },
    track: {
      backgroundColor: Palette.neutral[600],
      border: "none",
      borderRadius: 26 / 2,
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
  })
)(({ classes, ...props }: ICustomSwitchProps) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      checked: classes.checked,
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
    }}
    {...props}
  />
));
