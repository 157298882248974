export enum ComponentIdEnum {
  M_FRAUD_PREVENTION = "M_FRAUD_PREVENTION",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_ADD = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.Agregar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.PrevencionDeFraude",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasDeSeguridad",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_LEVEL_ONE = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.Nivel1",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATED = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.Crear",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_UPDATED = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DETAIL = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.Detalle",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DETAIL_SENT_TO_REVISION = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.DetalleRegla.EnviarARevision",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SAVE_GENERAL_RULE = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglaGeneral.GuardarRegla",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_ADD_OR_REJECT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.PendienteRevision.AgregarRechazar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FIRST_LEVEL_EDIT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasPrimerNivel.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_REJECTED_EDIT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.SolicitudRechazadas.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_REJECTED_HEADER = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.SolicitudRechazadas.Modal.Header",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_REJECTED_GENERAL_INFO = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.SolicitudRechazadas.Modal.Rejected.GeneralInfo",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_DISABLE_GENERAL_INFO = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasPrimerNivel.Modal.Disable.GeneralInfo",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_BUTTON_RIGHT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.PendienteRevision.Modal.Rechazar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_BUTTON_LEFT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.PendienteRevision.Modal.Aprobar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DISABLE_BUTTON_RIGHT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasPrimerNivel.Disable.Modal.Habilitar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DELETE_BUTTON_LEFT = "M_FRAUD_PREVENTION.ReglasPrimerNivel.Eliminar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_ENABLE_BUTTON_RIGHT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasPrimerNivel.Enable.Modal.Deshabilitar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_ENABLE_BUTTON_LEFT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasPrimerNivel.Enable.Modal.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_REJECTED_BUTTON_LEFT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.SolicitudRechazadas.Modal.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_EDIT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ListasBlancas.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_ENABLE_BUTTON_RIGHT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ListasBlancas.Enable.Modal.Editar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_ENABLE_BUTTON_LEFT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ListasBlancas.Enable.Modal.Deshabilitar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_DISABLE_BUTTON_RIGHT = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ListasBlancas.Disable.Modal.Habilitar",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATE_GENERAL_RULE_ADMIN = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasSeguridad.CrearReglaGeneral.Admin",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATE_GENERAL_RULE_APPROVER = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasSeguridad.CrearReglaGeneral.Aprobador",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATE_SECURITY_RULE = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasSeguridad.CrearReglaSeguridad",
  M_FRAUD_PREVENTION_SECURITY_RULES_THREE_LIST_CATALOGS = "M_FRAUD_PREVENTION.ReglasDeSeguridad3.ReglasSeguridad.ListarCatalogos",
}
