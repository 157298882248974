import React from "react";
import { Typography } from "@mui/material";
import { ISearchResultTitleProps } from "@components/SearchResultTitle/SearchResultTitle.interfaces";
import { translateEntityName } from "@shared/constants/translateEntityName";
import { defaultTo } from "lodash";
const SearchResultTitle: React.FC<ISearchResultTitleProps> = ({
  total,
  nodeType,
}: ISearchResultTitleProps) => {
  const node: string = defaultTo(translateEntityName[nodeType], "");

  return (
    <>
      <Typography variant={"h6"}>
        {node} - Resultados de la búsqueda ({total})
      </Typography>
    </>
  );
};

export default SearchResultTitle;
