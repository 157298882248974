/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_HONDURAS: IOptionSelection[] = [
  { value: "Banco Atlántida S.A.", label: "Banco Atlántida S.A." },
  { value: "Banco de Occidente S.A.", label: "Banco de Occidente S.A." },
  { value: "Banco de Los Trabajadores", label: "Banco de Los Trabajadores" },
  {
    value: "Banco Financiera Centroamericana S.A. (FICENSA)",
    label: "Banco Financiera Centroamericana S.A. (FICENSA)",
  },
  {
    value: "Banco Hondureño del Café S.A. (BANHCAFE)",
    label: "Banco Hondureño del Café S.A. (BANHCAFE)",
  },
  { value: "Banco del País S.A.", label: "Banco del País S.A." },
  {
    value: "Banco Financiera Comercial Hondureña S.A. (FICOHSA)",
    label: "Banco Financiera Comercial Hondureña S.A. (FICOHSA)",
  },
  { value: "Banco Lafise Honduras S.A.", label: "Banco Lafise Honduras S.A." },
  { value: "Banco Azteca Hoduras", label: "Banco Azteca Hoduras" },
];
