import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerStyles = createNamedStyles({
  amountContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "5px",
    gap: "10px",
  },
  fieldContainer: {
    width: "100%",
  },
  additionalContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    width: "100%",
    gap: "10px",
  },
});
