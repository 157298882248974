import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const FileUploadExecutivesStyles = createNamedStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "200px",
    width: "400px",
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CBD5E0FF' stroke-width='1' stroke-dasharray='9%2c 9' stroke-dashoffset='73' stroke-linecap='round'/%3e%3c/svg%3e\");\n" +
      "border-radius: 8px",
  },
  fileUploadContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  divider: {
    border: "none",
    backgroundColor: "transparent",
    "&::after, ::before": {
      top: "0px !important",
    },
    "& span p": {
      margin: "-13px",
    },
  },
  dragContainer: {
    backgroundColor: "rgba(161,193,236,0.1)",
    height: "100%",
    position: "absolute",
    top: "0px",
    width: "100%",
    borderRadius: "8px",
    transition: "all 300ms linear",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "60%",
    height: "100%",
  },
  dragUpload: {
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
  },
  textUpload: {
    color: "#B4B9BE",
    fontSize: { sm: "14px", md: "16px" },
  },
  circularProgress: {
    color: "#4499EE !important",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "60%",
    height: "80%",
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "95%",
    height: "80%",
    marginTop: "10px",
  },
  name: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F7FAFC",
    borderRadius: "8px",
    height: "56px",
    padding: "0px 10px",
    marginTop: "10px",
    width: "100%",
  },
  nameText: {
    textAlign: "center",
    flexGrow: 1,
    fontSize: { sm: "14px" },
  },
  crossIcon: {
    padding: "0px",
    margin: "0px",
    width: "25px",
  },
  validateButton: {
    marginTop: "15px",
  },
  alertContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  wrapper: {
    width: "100%",
  },
  validFormat: {
    fontSize: { sm: "13px" },
    color: "#6D7781",
    textAlign: "center",
  },
  alertWrapper: {
    marginBottom: "2rem",
    width: {
      sm: "100%",
      md: "70%",
    },
  },
});
