/**
 * CountriesCredomaticEnum
 */
export enum CountriesCredomaticEnum {
  COSTA_RICA = "CostaRica",
  EL_SALVADOR = "ElSalvador",
  GUATEMALA = "Guatemala",
  HONDURAS = "Honduras",
  NICARAGUA = "Nicaragua",
  PANAMA = "Panama",
  PERU = "Peru",
  MEXICO = "Mexico",
  ECUADOR = "Ecuador",
  BRAZIL = "Brazil",
}
