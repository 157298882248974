import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useSectionStyles as styles } from "./SectionContainer.styles";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import { ProcessingLoadingPanel } from "../Processors/ProcessingLoadingPanel/ProcessingLoadingPanel";
import { AlertDeferredProcessor } from "../AlertDeferredProcessor/AlertDeferredProcessor";

export interface SectionContainerProps {
  titleSection: string;
  children: React.ReactNode;
  isLoading?: boolean;
  alertDeferredProcessor?: boolean;
}

export const SectionContainer: React.FC<SectionContainerProps> = ({
  titleSection,
  children,
  isLoading,
  alertDeferredProcessor,
}) => {
  return (
    <Card sx={styles.mainCard}>
      <MaskSkeleton isLoading={isLoading} skeleton={<ProcessingLoadingPanel />}>
        <CardContent>
          <Box>
            {!alertDeferredProcessor ? (
              <Typography
                variant={"h3"}
                color={"primary"}
                sx={styles.mainTitle}
              >
                {titleSection}
              </Typography>
            ) : (
              <AlertDeferredProcessor titleSection={titleSection} />
            )}
            {children}
          </Box>
        </CardContent>
      </MaskSkeleton>
    </Card>
  );
};
