import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const usePendingFiltersStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      maxWidth: 400,
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    divider: {
      height: 28,
      marginLeft: 8,
      border: "0.5px solid",
      boxSizing: "border-box",
    },
    textField: {
      padding: 0,
      height: "36px",
      marginTop: "-3px",
    },
    iconButton: {
      "& .MuiIconButton-root": {
        height: "39px",
        width: "39px",
        marginTop: "0px",
        borderStyle: "solid",
        borderColor: "#C4C4C4",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: "#FFFFFF",
      },
    },
    reprocessButton: {
      paddingLeft: 20,
    },
  })
);
