import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const selectItemStyles = createNamedStyles({
  select: {},
  selectContainer: {
    width: 1,
    "& > img": { flexShrink: 0, mr: 2 },
  },
  error1: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A",
    },
  },
});
