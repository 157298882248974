export const THUNK_PREFIXES = {
  MASSIVE_SERVICES_CONFIG: "general/massiveServicesConfig",
  MERCHANT_CARD: "general/merchantCard",
  MERCHANT_COMMISSION: "general/merchantCommission",
  MERCHANT_SETTINGS: "general/merchantSettings",
  MERCHANT_SMARTLINKS: "general/merchantSmartlinks",
  MERCHANT_SUBSCRIPTION: "general/merchantSubscription",
  MERCHANT_SUBSCRIPTION_SANDBOX: "general/merchantSubscriptionSandbox",
  MERCHANT_VPOS: "general/merchantVpos",
  NODE_CONFIG: "general/updateHierarchyNodeConfig",
  NODE_INFO: "contactData/nodeInfo",
  PAYMENT_CREDENTIALS: "general/paymentCredentials",
  SERVICES_DEFAULT: "general/servicesDefault",
};
