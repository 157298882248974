import { sortBy } from "lodash";

export const TransactionsTableConstants = {
  getSelectColumns: (
    columns: string[]
  ): { key: string; value: string; order: number }[] =>
    TransactionsTableConstants.selectColumns.filter(
      (col: { key: string; value: string; order: number }) =>
        columns.includes(col.key.toString())
    ),
  selectColumns: [
    { key: "created", order: 1, value: "Fecha del est. de transacción" },
    { key: "merchantDescription", order: 2, value: "Nombre y ID de comercio" },
    { key: "transactionType", order: 3, value: "Tipo de Transacción" },
    { key: "approvedTransactionAmount", order: 4, value: "Monto" },
    { key: "transactionStatusKushki", order: 5, value: "Estado Kushki" },
    { key: "transactionStatusProcessor", order: 6, value: "Estado Procesador" },
    { key: "approvalCode", order: 7, value: "Código de Aprobación" },
    { key: "country", order: 8, value: "País" },
    { key: "issuingBank", order: 9, value: "Banco Emisor" },
    { key: "maskedCreditCard", order: 10, value: "Número de Tarjeta" },
    { key: "paymentMethod", order: 11, value: "Medio de pago" },
    { key: "processorName", order: 12, value: "Procesador" },
    { key: "purchaseNumber", order: 13, value: "Número de Compra" },
    { key: "transactionReference", order: 14, value: "Referencia" },
    { key: "transactionId", order: 15, value: "ID Transacción" },
    { key: "status", order: 16, value: "Estado de la Transacción" },
    { key: "conciliationCreation", order: 17, value: "Fecha de Conciliación" },
    {
      key: "conciliationAction",
      order: 18,
      value: "Acción y Respuesta",
    },
  ],
  sortKeysSelectedColumns: (columns: string[]): string[] =>
    sortBy(TransactionsTableConstants.getSelectColumns(columns), [
      (col: { key: string; value: string; order: number }) => col.order,
    ]).map((col: { key: string; value: string; order: number }) =>
      col.key.toString()
    ),
  sortValuesSelectedColumns: (columns: string[]): string[] =>
    sortBy(TransactionsTableConstants.getSelectColumns(columns), [
      (col: { key: string; value: string; order: number }) => col.order,
    ]).map((col: { key: string; value: string; order: number }) =>
      col.value!.toString()
    ),
};
