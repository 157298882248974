import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { TransacationStatus } from "../status-enum";
import { CategoryEnum, LabelCategoryEnum } from "../../enums/CategoryEnum";

enum CELL_TYPES {
  TEXT = "text",
  TAG = "tag",
}

type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

enum tableProperties {
  CREATION_DATE = "creationDate",
  NAME = "name",
  CATEGORY = "category",
  SENDTO = "sendTo",
  STATUS = "status",
}

export const ALL_TABLE_COLUMNS: CustomHeaderCellProps[] = [
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.CREATION_DATE,
    spacing: 0,
    text: "Fecha de creación",
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.NAME,
    spacing: 0,
    text: "Nombre",
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.CATEGORY,
    spacing: 0,
    text: "Categoria",
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.SENDTO,
    spacing: 0,
    text: "Enviar a",
  },
  {
    align: "center",
    cellType: CELL_TYPES.TAG,
    key: tableProperties.STATUS,
    spacing: 0,
    text: "Estado",
  },
];

export const Categories = [
  {
    key: "transactionBank",
    label: "Institución Financiera",
  },
  {
    key: "transactionBrand",
    label: "Marca",
  },
  {
    key: "transactionIsDeferred",
    label: "Diferido",
  },
  {
    key: "transactionCountry",
    label: "País",
  },
  {
    key: "transactionTotalAmount",
    label: "Monto",
  },
  {
    key: "transactionIP",
    label: "IP",
  },
  {
    key: "transactionType",
    label: "Tipo de transacción",
  },
  {
    key: "transactionCredential",
    label: "Credencial",
  },
  {
    key: "transactionBIN",
    label: "BIN",
  },
  {
    key: "transactionIsCreditCard",
    label: "Tipo de tarjeta",
  },
  {
    key: "transactionMaskedCreditCard",
    label: "Tarjeta enmascarada",
  },
  {
    key: "transactionCurrency",
    label: "Moneda",
  },
];

export const PayoutRuleCategories = [
  {
    key: CategoryEnum.transactionBank,
    label: LabelCategoryEnum.transactionBank,
  },
  {
    key: CategoryEnum.documentType,
    label: LabelCategoryEnum.documentType,
  },
  {
    key: CategoryEnum.accountType,
    label: LabelCategoryEnum.accountType,
  },
  {
    key: CategoryEnum.transactionTotalAmount,
    label: LabelCategoryEnum.transactionTotalAmount,
  },
  {
    key: CategoryEnum.enrollment,
    label: LabelCategoryEnum.enrollment,
  },
];

export const colorSelector = (
  status: string
): { color: string; label: string } => {
  switch (status) {
    case TransacationStatus.ENABLE:
      return { color: "success", label: "HABILITADO" };
    case TransacationStatus.DISABLE:
      return { color: "warning", label: "DESHABILITADO" };
    case TransacationStatus.DRAFT:
      return { color: "error", label: "BORRADOR" };
    default:
      return { color: "primary", label: "" };
  }
};
