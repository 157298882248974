import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import { assign, defaultTo, set as set_lodash, mapKeys } from "lodash";
import {
  PAY_OUTS_LIST_FILTERS_INITIAL_STATE,
  INIT_RANGE_FILTER,
} from "../../../shared/infrastructure/constants/PayoutsListConstants";
import { Transaction } from "../../../../types/transactions_data";
import { downloadTransactions, search } from "../../../store/actionCreators";
import { format, set, sub } from "date-fns";
import { MerchantInfo } from "../../../../types/merchant-info";
import { PayoutsTransactionFunctionsProps } from "../PayoutsTransaction";
import {
  defaultFilters,
  getInitialSelectedColumns,
} from "../../../shared/infrastructure/constants/PayoutsTransactionsConstants";
import { auth } from "../../../shared/auth";
import { IAppState } from "../../../store/reducer";
import { TransactionsRequest } from "../../../../types/transactions_request";
import { IColumns } from "../../../shared/infrastructure/interfaces/IColumns";
import { TransactionInfo } from "../../../../types/transaction";
import { BANK_LIST } from "../../../shared/infrastructure/constants/Banks";

export interface IOptionFilter {
  id: string;
  title: string;
  options: { key: string; label: string; selected: boolean }[];
}

export interface IRegister {
  key: string;
  value: string | number;
  opaqueValue?: boolean;
}

export interface IState {
  filterKind: IOptionFilter[];
  payoutsTransactions: Transaction[];
  payoutsTransactionsSelected: Transaction[];
  selectedColumns: IColumns[];
  sortByKey?: string;
  sortDirection?: "asc" | "desc";
  total: number;
  page: number;
  pageSize: number;
  receipt?: Buffer;
  openModal: boolean;
}

export interface IUsePayoutsTransactionState {
  isAdmin: boolean;
  filters: {
    multipleMerchant: { name: string; id?: string }[];
    rangeFilter: IRangeAmount;
    countFilter: number;
    selectedDateRange: any;
    searchText: string;
    actions: {
      handleEnterSearchText: (key: string) => void;
      handleChangeSearchText: (value: string) => void;
      handleApplyFilterAmount: (response: IFilterResponse) => void;
      handleClearFilters: () => void;
      handleChangeDateRange: (date: any) => void;
      handleChangeMerchants: (
        _event: unknown,
        value: string,
        reason: string
      ) => void;
      handleChangeMerchant: (
        _event: object,
        value: { _source: MerchantInfo }[],
        _reason: string
      ) => void;
    };
  };
  state: IState;
  receipt?: Buffer;
  handlers: {
    handleSelectPayoutsTransaction(checked: boolean, row: Transaction): void;
    handleCheckSelectedPayoutsTransaction(
      payoutsTransactionToCheck: Transaction
    ): boolean;
    handleSortByKey(property: string): void;
    handleSetPayuotTransactionSelected(
      payoutTransactionSelected: TransactionInfo
    ): void;
    handleCloseModal(): void;
    handleChangePage(page: number): void;
    handleChangePageSize(pageSize: number): void;
    handleSelectColumns(columns: IColumns[]): void;
    handlerDownloadReceipt: (payload: string) => void;
    handleDownloadTransactions(format: string): void;
    handleDownloadSelectedTransactions(format: string): void;
  };
}

export interface IOptionFilter {
  id: string;
  title: string;
  options: { key: string; label: string; selected: boolean }[];
}

export interface IRegister {
  key: string;
  value: string | number;
  opaqueValue?: boolean;
}

export interface IState {
  filterKind: IOptionFilter[];
  payoutsTransactions: Transaction[];
  payoutsTransactionsSelected: Transaction[];
  payoutTransactionSelected?: TransactionInfo;
  selectedColumns: IColumns[];
  sortByKey?: string;
  sortDirection?: "asc" | "desc";
}

export const usePayoutsTransactionState = (
  props: PayoutsTransactionFunctionsProps
): IUsePayoutsTransactionState => {
  const dispatch = useDispatch();
  const payoutsTransactions = useSelector(
    (state: IAppState) => state.transactionData
  );
  const receiptState = useSelector((state: IAppState) => state.receipt);
  const isAdmin: boolean = auth.isAdmin();
  const publicMerchantId: string = auth.getAuthMerchant().publicMerchantId;

  const [state, setState] = useState<IState>({
    openModal: false,
    payoutTransactionSelected: undefined,
    sortByKey: "",
    sortDirection: undefined,
    payoutsTransactions: [],
    payoutsTransactionsSelected: [],
    selectedColumns: getInitialSelectedColumns(),
    filterKind: defaultFilters,
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [searchRequest, setSearchRequest] = useState<TransactionsRequest>({
    ...PAY_OUTS_LIST_FILTERS_INITIAL_STATE,
  });

  const [searchText, setSearchText] = useState<string>("");

  const handleEnterSearchText = (key: string): void => {
    if (key !== "Enter") return;
    setSearchRequest({
      ...searchRequest,
      offset: 0,
      text: searchText,
    });
  };

  const handleChangeSearchText = (value: string): void => {
    if (value === "") {
      setSearchRequest({
        ...searchRequest,
        offset: 0,
        text: value,
      });
    }
    setSearchText(value);
  };

  const [rangeFilter, setRangeFilter] = useState<IRangeAmount>(
    assign({}, INIT_RANGE_FILTER)
  );

  const [multipleMerchant, setMultipleMerchant] = useState<
    { name: string; id?: string }[]
  >([]);

  const [countFilter, setCountFilter] = useState<number>(0);

  const [refTimeOut, setRefTimeOut] = useState<NodeJS.Timeout>(
    setTimeout(() => {}, 0)
  );

  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
  ]);

  const handleChangeMerchants = (
    _event: unknown,
    value: string,
    reason: string
  ): void => {
    if (reason === "input") {
      clearTimeout(refTimeOut);
      const time_out: NodeJS.Timeout = setTimeout(() => {
        props.searchMerchantsList({
          offset: 0,
          text: value,
        });
      }, 200);
      setRefTimeOut(time_out);
    }
  };

  const handleChangeMerchant = (
    _event: object,
    value: { _source: MerchantInfo }[],
    _reason: string
  ) => {
    const multiple_public_merchant: { name: string; id?: string }[] = value.map(
      (merchant: { _source: MerchantInfo }) => ({
        name: merchant._source.name,
        id: merchant._source.publicMerchantId,
      })
    );
    const multiple_public_merchant_id: string = multiple_public_merchant
      .map((merchant: { name: string; id?: string }) => merchant.id)
      .join("|");

    setMultipleMerchant(multiple_public_merchant);
    setSearchRequest({
      ...searchRequest,
      offset: 0,
      publicMerchantId: multiple_public_merchant_id,
    });
  };

  const handleChangeDateRange = (date: any): void => {
    setSelectedDateRange(date);
    const [fromDate, toDate]: [Date, Date] = date;

    if (fromDate === null || toDate === null) return;

    setSearchRequest({
      ...searchRequest,
      offset: 0,
      from: format(fromDate, "yyyy-MM-dd'T'HH:mm:ss"),
      to: format(toDate, "yyyy-MM-dd'T'HH:mm:ss"),
    });
  };

  const handleApplyFilterAmount = (response: IFilterResponse) => {
    setRangeFilter(response.rangeFilter?.filter!);
    const export_filter_selected: {
      filter: object;
    } = { filter: {} };
    let count: number = 0;

    response.listFilter.forEach((filter: IOptionFilter) => {
      const filter_parent: string[] = filter.options
        .filter((option) => option.selected)
        .map((option) => option.key);

      export_filter_selected.filter[filter.id] = filter_parent.join("|");
    });

    const getRangeAmountFilter = (filter?: object) => {
      if (isEmpty(filter)) return;

      if (filter!.hasOwnProperty("max") && filter!.hasOwnProperty("min"))
        return filter;

      return mapKeys(filter, (_, key) =>
        key === "max" ? "min" : key === "min" ? "max" : key
      );
    };

    setSearchRequest({
      ...searchRequest,
      filter: {
        paymentMethod: export_filter_selected.filter["paymentMethod"],
        transactionStatus: export_filter_selected.filter["transactionStatus"],
        transactionType: "",
      },
      rangeAmount: getRangeAmountFilter(get(response, "rangeFilter.values")),
      offset: 0,
    });
    count++;
    setCountFilter(count);
  };

  useEffect(() => {
    dispatch(search({ ...searchRequest }));
  }, [searchRequest]);

  useEffect(() => {
    if (get(payoutsTransactions, "data", false))
      setState({
        ...state,
        payoutsTransactions: payoutsTransactions.data!,
        total: get(payoutsTransactions, "total.value", 0),
      });
  }, [payoutsTransactions]);

  const handleClearFilters = () => {
    handleApplyFilterAmount({
      listFilter: [],
      rangeFilter: {
        filter: assign({}, INIT_RANGE_FILTER),
        values: undefined,
      },
    });
  };

  const handleSortByKey = (property: string) => {
    const order: "asc" | "desc" =
      state.sortByKey === property && state.sortDirection === "asc"
        ? "desc"
        : "asc";
    setState({ ...state, sortByKey: property, sortDirection: order });
    setSearchRequest({
      ...searchRequest,
      sort: {
        field: property,
        order: order,
      },
    });
  };

  const handleSelectPayoutsTransaction = (
    checked: boolean,
    row: Transaction
  ): void => {
    const arrayPayoutTransactions: Transaction[] = checked
      ? [...state.payoutsTransactionsSelected, row]
      : [...state.payoutsTransactionsSelected.filter((s) => s._id !== row._id)];
    setState({
      ...state,
      payoutsTransactionsSelected: arrayPayoutTransactions,
    });
  };

  const handleCheckSelectedPayoutsTransaction = (
    payoutsTransactionToCheck: Transaction
  ) => {
    return state.payoutsTransactionsSelected.some(
      (selected) => selected._id === payoutsTransactionToCheck._id
    );
  };

  const handleSetPayuotTransactionSelected = (
    payoutTransactionSelected: TransactionInfo
  ): void => {
    const ids = defaultTo(
      BANK_LIST[get(payoutTransactionSelected, "country", "")],
      []
    );
    const bank_name_obj = defaultTo(
      ids.find(
        (element: { code: string; name: string }) =>
          element.code === payoutTransactionSelected.bank_id
      ),
      {}
    );
    const bank_name = get(bank_name_obj, "name");
    set_lodash(payoutTransactionSelected, "bank_name", bank_name);
    setState({
      ...state,
      payoutTransactionSelected: payoutTransactionSelected,
      openModal: true,
    });
  };

  const handleCloseModal = (): void => {
    setState({
      ...state,
      payoutTransactionSelected: undefined,
      openModal: false,
    });
  };

  const handleChangePage = (page: number): void => {
    setState({ ...state, page });
    setSearchRequest({
      ...searchRequest,
      offset: (page - 1) * state.pageSize,
    });
  };

  const handleChangePageSize = (pageSize: number): void => {
    setState({ ...state, page: 1, pageSize });
    setSearchRequest({
      ...searchRequest,
      offset: 0,
      limit: pageSize,
    });
  };

  const handleSelectColumns = (columns: IColumns[]) => {
    setState({ ...state, selectedColumns: [...columns] });
  };

  const handleDownloadTransactions = (
    format: string,
    transactionsId?: string[]
  ) => {
    const downloadById = !!transactionsId;
    const filters: TransactionsRequest = {
      ...searchRequest,
      offset: 0,
      format,
      text: searchRequest.text,
      type: "payout",
      ...(!transactionsId && { publicMerchantId }),
      ...(transactionsId && {
        field: "_id",
        fields: transactionsId,
      }),
    };

    dispatch(downloadTransactions(filters, downloadById));
  };

  const handleDownloadSelectedTransactions = (format: string) => {
    handleDownloadTransactions(
      format,
      state.payoutsTransactionsSelected.map((t) => t._id!)
    );
  };

  const handlerDownloadReceipt = (reference: string) => {
    props.getPaymentReceipt(reference);
  };

  useEffect(() => {
    if (receiptState) {
      const blob = base64ToBlob(receiptState.toString());

      const url: string = window.URL.createObjectURL(blob);

      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    }
  }, [receiptState]);

  const base64ToBlob = (base64: string) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: "application/pdf" });
  };

  return {
    isAdmin,
    filters: {
      searchText,
      rangeFilter,
      countFilter,
      multipleMerchant,
      selectedDateRange,
      actions: {
        handleEnterSearchText,
        handleChangeSearchText,
        handleApplyFilterAmount,
        handleClearFilters,
        handleChangeMerchants,
        handleChangeMerchant,
        handleChangeDateRange,
      },
    },
    state,
    handlers: {
      handleSelectPayoutsTransaction,
      handleCheckSelectedPayoutsTransaction,
      handleSortByKey,
      handleSetPayuotTransactionSelected,
      handleCloseModal,
      handleChangePage,
      handleChangePageSize,
      handleSelectColumns,
      handleDownloadTransactions,
      handleDownloadSelectedTransactions,
      handlerDownloadReceipt,
    },
  };
};
