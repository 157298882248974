import { IBankAccount } from "../../store/interfaces/dispersion.interfaces";
import { get, sortBy } from "lodash";
import { AccountOrder } from "../constants/dispersions/account_bank_constants";
import { ADD_BANK_ACCOUNT_MODAL_LABELS } from "../constants/labels/add_bank_account_modal_labels";
import { getCatalog } from "../constants/dispersions/dispersions";
import { CatalogsEnum } from "../enums/CatalogsEnum";

export const validateIfHasMainAccount = (
  banksAccounts: IBankAccount[]
): boolean => {
  const mainAccount = banksAccounts.find(
    (account) => account.accountOrder.toLowerCase() === AccountOrder.PRINCIPAL
  );

  return mainAccount !== undefined;
};

export const getChargeBankAccountOrder = (
  bankAccounts: IBankAccount[]
): string =>
  validateIfHasMainAccount(bankAccounts)
    ? AccountOrder.ALTERNATE
    : AccountOrder.PRINCIPAL;

export const sortChargeBanksAccount = (
  banksAccounts: IBankAccount[]
): IBankAccount[] => {
  return sortBy(
    banksAccounts,
    (bankAccount) => bankAccount.accountOrder !== AccountOrder.PRINCIPAL
  );
};

export const getInitialChargeBankAccountValues = (data: {
  accountType?: string;
  bankAccounts: IBankAccount[];
  constitutionalCountry: string;
  beneficiaryNameFromBasicDetails: string;
}): IBankAccount => ({
  accountNumber: "",
  accountOrder: validateIfHasMainAccount(data.bankAccounts)
    ? AccountOrder.ALTERNATE
    : AccountOrder.PRINCIPAL,
  accountType: get(data, "accountType", "0"),
  address: "",
  bankId: "",
  bankName: "",
  country: data.constitutionalCountry,
  currency: getCatalog(data.constitutionalCountry, CatalogsEnum.Currency)[0]
    .value as string,
  interbankAccountCode: "",
  nameBeneficiary: data.beneficiaryNameFromBasicDetails,
});

export const getLabelsChargeBankModal = (
  orderAccount: string,
  isEdition: boolean
): string => {
  const editCreateLabel = isEdition ? "edition" : "create";
  const labels = {
    [AccountOrder.PRINCIPAL]: {
      create: ADD_BANK_ACCOUNT_MODAL_LABELS.principal.titleAdd,
      edition: ADD_BANK_ACCOUNT_MODAL_LABELS.principal.titleEdit,
    },
    [AccountOrder.ALTERNATE]: {
      create: ADD_BANK_ACCOUNT_MODAL_LABELS.alternative.titleAdd,
      edition: ADD_BANK_ACCOUNT_MODAL_LABELS.alternative.titleEdit,
    },
  };

  return labels[orderAccount.toLowerCase()][editCreateLabel];
};
