import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const detailCardBodyAlarmsStyles = createNamedStyles({
  container: {
    height: "calc(100vh - 218px)",
  },
  containerBody: {
    height: "100%",
    paddingTop: "1rem",
  },
});
