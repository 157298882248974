export enum ActionTypes {
  SET_NOTIFICATION = "[WEBHOOK] SET_NOTIFICATION",
  SET_WEBHOOK = "[WEBHOOK] SET_WEBHOOK",
  SET_WEBHOOK_SELECTED = "[WEBHOOK] SET_WEBHOOK_SELECTED",
  SET_WEBHOOK_NOTIFICATIONS = "[WEBHOOK] SET_WEBHOOK_NOTIFICATIONS",
  SET_VERIFIED_URLS = "[WEBHOOK] SET_VERIFIED_URLS",
  SET_LOADING = "[WEBHOOK] SET_LOADING",
  SET_WEBHOOK_SIGNATURE = "[WEBHOOK] SET_WEBHOOK_SIGNATURE",
  SET_MERCHANT_SETTINGS = "[WEBHOOK] SET_MERCHANT_SETTINGS",
  SET_VPOS_ACTIVE = "[WEBHOOK] SET_VPOS_ACTIVE",
  SET_SMARTLINK_ACTIVE = "[WEBHOOK] SET_SMARTLINK_ACTIVE",
  SET_SUBSCRIPTION_ACTIVE = "[WEBHOOK] SET_SUBSCRIPTION_ACTIVE",
  SET_SHOW_ERROR = "[WEBHOOK] SET_SHOW_ERROR",
  SET_PREAUTH_ENABLE = "[WEBHOOK] SET_PREAUTH_ENABLE",
  SET_LOADING_RESEND_NOTIFICATION = "[WEBHOOK] SET_LOADING_RESEND_NOTIFICATION",
  SET_MERCHANT_STATUS = "[WEBHOOK] SET_MERCHANT_STATUS",
  SET_NODE_INFO = "[WEBHOOK] SET_NODE_INFO",
}
