/**
 * MenuItemNames enum
 */
export enum MenuItemNamesEnum {
  MERCHANT_DATA = "Datos de la empresa",
  BILLING_CONFIGURATION = "Configuración financiera",
  RATES_AND_DISCOUNTS = "Tarifas y descuentos",
  PROCESSING = "Procesamiento",
  SERVICES = "Servicios",
  RESUME = "Resumen",
  DEVELOPERS = "Desarrolladores (Opcional)",
}

export const MENU_ID_PROPS = {
  [MenuItemNamesEnum.MERCHANT_DATA]: "MERCHANT_DATA",
  [MenuItemNamesEnum.BILLING_CONFIGURATION]: "BILLING_CONFIGURATION",
  [MenuItemNamesEnum.RATES_AND_DISCOUNTS]: "RATES_AND_DISCOUNTS",
  [MenuItemNamesEnum.PROCESSING]: "PROCESSING",
  [MenuItemNamesEnum.SERVICES]: "SERVICES",
  [MenuItemNamesEnum.RESUME]: "RESUME",
  [MenuItemNamesEnum.DEVELOPERS]: "DEVELOPERS",
};
