/**
 * DynamoDB's Index enum
 */
export enum FilterTypeEnum {
  processorName = "processorName",
  action = "action",
  actionResponse = "actionResponse",
  transactionType = "transactionType",
  response = "response",
  currency = "currency",
  stateRefund = "stateRefund",
  executor = "executor",
}
