export enum nodeConfigId {
  BASIC_DATA = "cn001",
  CONTACT_DATA = "cn002",
  BILLING = "cn003",
  DISPERSION = "cn004",
  DISCOUNTS = "cn006",
  RATES = "cn005",
  CHARGE = "cn017",
}
export enum ConfigIdEnum {
  CN001 = "cn001",
  CN002 = "cn002",
  CN003 = "cn003",
  CN004 = "cn004",
  CN005 = "cn005",
  CN006 = "cn006",
  CN016 = "cn016",
  CN017 = "cn017",
}

export const AllConfigs = [
  ConfigIdEnum.CN001,
  ConfigIdEnum.CN002,
  ConfigIdEnum.CN003,
  ConfigIdEnum.CN004,
  ConfigIdEnum.CN005,
  ConfigIdEnum.CN006,
  ConfigIdEnum.CN017,
].toString();
