import React from "react";
import { Box, Skeleton } from "@mui/material";
import { accordionSkeletonStyles as styles } from "../Accordion/AccordionSkeleton.styles";

export const AccordionSkeleton: React.FC = () => {
  return (
    <Box sx={styles.boxPrincipal}>
      <Skeleton
        data-testid={"circularSkeleton"}
        sx={styles.circularSkeleton}
        variant="circular"
        animation="wave"
        width={45}
        height={45}
      />

      <Box sx={styles.boxLines}>
        <Skeleton
          data-testid={"linearSkeleton"}
          animation="wave"
          width={"45%"}
          height={50}
        />
        <Skeleton
          data-testid={"linearSkeleton2"}
          animation="wave"
          width={"60%"}
          height={50}
        />
      </Box>
    </Box>
  );
};
