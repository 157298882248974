import React from "react";
import { InfoTrxProps } from "../ModalBodyTabs";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { Button, createStyles, Grid, Typography } from "@material-ui/core";
import {
  HistoricalData,
  useModalBodyTimelineState,
} from "./useModalBodyTimelineState";
import { makeStyles } from "@material-ui/core/styles";
import expandImage from "../../../assets/images/expand.svg";
import { RequestDescriptionStatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import { DialogTimeLine } from "../../DialogTimeline/DialogTimeLine";
import { TimeLineConstants } from "../../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
import { get } from "lodash";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
    timeline: {
      padding: 16,
    },
    timelineContent: {
      textAlign: "start",
      flex: 1,
      paddingTop: 16,
      paddingLeft: 32,
      paddingBottom: 0,
      paddingRight: 0,
    },
    timelineOppositeItem: {
      textAlign: "start",
      flex: 0.3,
      paddingTop: 16,
      paddingLeft: 16,
      paddingBottom: 0,
      paddingRight: 0,
    },
    lineDashed: {
      backgroundColor: "#f5f5f5",
      borderRight: "1px dashed #CBD5E0",
      width: "1px",
    },
    styleDot: {
      background: "#E2E8F0",
    },
    styleDotActive: {
      background: "#4498EE",
    },
    principalText: {
      fontSize: "15px !important",
      lineHeight: "120% !important",
      color: "#46525C !important",
    },
    secondText: {
      fontSize: "12px !important",
      lineHeight: "136% !important",
      color: "#6D7781 !important",
    },
    expand: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textDecorationLine: "underline",
      borderRadius: "50%",
    },
    image: {
      maxWidth: 15,
      maxHeight: 15,
    },
    defaultColor: {
      color: "#023365",
    },
  })
);

export const ModalBodyTimeline: React.FC<InfoTrxProps> = (
  props: InfoTrxProps
) => {
  const classes = useStyles();
  const { historicalData, openDialogTimeLine, modalData, actions } =
    useModalBodyTimelineState(props);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Timeline className={classes.timeline}>
          {historicalData.map((data: HistoricalData, index: number) => {
            return (
              <React.Fragment key={index}>
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    className={classes.timelineOppositeItem}
                  >
                    <Typography className={classes.principalText}>
                      {data.dateFormatter}
                    </Typography>
                    <Typography className={classes.secondText}>
                      {data.hourFormatter}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector className={classes.lineDashed} />
                    <TimelineDot
                      className={
                        index === 0 ? classes.styleDotActive : classes.styleDot
                      }
                    />
                    <TimelineConnector className={classes.lineDashed} />
                  </TimelineSeparator>
                  <TimelineContent className={classes.timelineContent}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography className={classes.principalText}>
                          {data.descriptionStatus}
                        </Typography>
                        <Typography className={classes.secondText}>
                          {data.userName}
                        </Typography>
                      </Grid>
                      {data.descriptionStatus ===
                        RequestDescriptionStatusEnum.REJECT && (
                        <Grid item xs={4}>
                          <Button
                            style={{ padding: "0px" }}
                            onClick={() => {
                              actions.handleShowDialogTimeline();
                              actions.handleSetModalData(data);
                            }}
                          >
                            <Grid className={classes.expand}>
                              <img
                                alt=""
                                src={expandImage}
                                className={classes.image}
                              />
                              <Typography variant="caption" color="primary">
                                {TimeLineConstants.VIEW_DETAIL}
                              </Typography>
                            </Grid>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </TimelineContent>
                </TimelineItem>
              </React.Fragment>
            );
          })}
        </Timeline>
        {get(modalData, "descriptionStatus", "") ===
          RequestDescriptionStatusEnum.REJECT && (
          <DialogTimeLine
            open={openDialogTimeLine}
            data={modalData as HistoricalData}
            handleFinish={actions.handleFinish}
          />
        )}
      </div>
    </React.Fragment>
  );
};
