import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const UpdateStatusButtonStyles = createNamedStyles({
  iconButton: {
    "& .MuiIconButton-root": {
      height: "39px",
      width: "39px",
      marginTop: "4px",
      borderStyle: "solid",
      borderColor: "#F0F4F9",
      borderWidth: 1,
      borderRadius: 4,
      backgroundColor: "#F0F4F9",
    },
  },
});
