import React from "react";
import { TextField } from "@mui/material";
import { IInputTextProps } from "@components/InputsForm/Inputs/InputText/InputText.interfaces";
import { inputTextStyles as styles } from "@components/InputsForm/Inputs/InputText/InputText.styles";
const InputText: React.FC<IInputTextProps> = ({
  label,
  field,
  error,
  disabled,
}: IInputTextProps) => {
  return (
    <TextField
      {...field}
      disabled={disabled}
      value={field.value}
      placeholder={label}
      helperText={error ? error.message : ""}
      error={!!error}
      onChange={(e) => field.onChange(e.target.value)}
      sx={styles.input}
    />
  );
};

export default InputText;
