import { IConfigCells, TableBodyCellMassiveEnum } from "../utils/constants";
import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";

export enum HeadCellsLabels {
  BRANCH_ID = "Branch ID",
  NAME = "Nombre",
  STATUS = "Estado (usuarios)",
}

export const headCells: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    id: "line1",
    label: HeadCellsLabels.NAME,
  },
  {
    align: "left",
    disablePadding: false,
    id: "title",
    label: HeadCellsLabels.BRANCH_ID,
  },
  {
    disablePadding: false,
    id: "text",
    label: HeadCellsLabels.STATUS,
  },
];

export const CatalogConfigTableBranch: IConfigCells[] = [
  {
    align: "center",
    isChecked: false,
    type: TableBodyCellMassiveEnum.CHECK,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "Name Default",
      hasUrl: true,
      path: "name",
      text: "$var",
    },
    type: TableBodyCellMassiveEnum.TITLE,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "MerchantId Default",
      path: "merchant_id",
      text: "$var",
    },
    type: TableBodyCellMassiveEnum.TEXT_COPY,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "pending",
      path: "status_user_config",
      text: "$var",
    },
    type: TableBodyCellMassiveEnum.TAG,
  },
];
export const CatalogConfigTableRoleCustomerMaster: IConfigCells[] = [
  {
    align: "center",
    isChecked: false,
    type: TableBodyCellMassiveEnum.CHECK,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "Name Default",
      hasUrl: true,
      path: "name",
      text: "$var",
    },
    type: TableBodyCellMassiveEnum.TITLE,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "MerchantId Default",
      path: "merchant_id",
      text: "$var",
    },
    type: TableBodyCellMassiveEnum.TEXT_COPY,
  },
];

export enum TooltipEnum {
  STATUS = "Completado: Comercio cuenta con usuarios configurados, podrá configurarle usuarios adicionales.\nPendiente: Comercio no cuenta con usuarios configurados, podrá configurarle usuarios.",
}
