import React, { FC } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ICellActionOptionMenu } from "./CellActionOptionMenu.interface";
import { CellTextActionStyles } from "../CellTextAction/CellTextAction.styles";
import { get, isNil } from "lodash";
import { useCellActionOptionMenuState } from "./state/useCellActionOptionMenuState";
import {
  CellActionOptionMenuStyles,
  divStyles,
} from "./CellActionOptionMenu.styles";

export const CellActionOptionMenu: FC<ICellActionOptionMenu> = ({
  actions,
  text,
  value,
}: ICellActionOptionMenu) => {
  const isDisabled: boolean = value! > 0;
  const classes = divStyles();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const { onClick, handleToggle } = useCellActionOptionMenuState();
  const builderMessageToolTip = () => "No aplica en este ciclo";

  const closeBox = (event: Event | React.SyntheticEvent, element: object) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (!isNil(get(element, "data"))) {
      onClick(get(element, "data"));
    }
    setOpen(false);
  };

  return (
    <>
      {!isDisabled && (
        <div className={classes.menuContainer}>
          <Tooltip
            data-testid={"tooltipAction"}
            title={isDisabled ? "" : builderMessageToolTip()}
            placement={"left"}
          >
            <Typography
              sx={
                isDisabled
                  ? CellTextActionStyles.textActionActive
                  : CellTextActionStyles.textActionInactive
              }
            >
              {text}
            </Typography>
          </Tooltip>
        </div>
      )}
      {isDisabled && (
        <div>
          <Button
            data-testid={"buttonAction"}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={() => {
              setOpen(handleToggle(open));
            }}
            disabled={!isDisabled}
            className={classes.menuBotton}
          >
            <Typography
              sx={
                isDisabled
                  ? CellTextActionStyles.textActionActive
                  : CellTextActionStyles.textActionInactive
              }
            >
              {text}
            </Typography>
          </Button>
          <Popper
            data-testid={"popperAction"}
            open={open}
            anchorEl={anchorRef.current}
            placement={"bottom-start"}
          >
            <Box sx={CellActionOptionMenuStyles.menuBox}>
              <ClickAwayListener
                onClickAway={(e) => {
                  closeBox(e, {});
                }}
              >
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {actions.map((element) => {
                    return (
                      <MenuItem
                        key={
                          get(element, "optionText") +
                          "-" +
                          get(element, "data.kind")
                        }
                        data-testid={
                          get(element, "optionText") +
                          "-" +
                          get(element, "data.kind")
                        }
                        onClick={(e) => {
                          closeBox(e, element);
                        }}
                      >
                        {get(element, "optionText")}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Box>
          </Popper>
        </div>
      )}
    </>
  );
};
