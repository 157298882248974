import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { processorsStyles } from "./Processors.styles";
import {
  ChipProcessorData,
  listDropDownAccordion,
  ProcessorConstants,
  processorMethodsByCountry,
} from "../../shared/constants/ProcessorConstants";
import {
  Accordion,
  Alert,
  IconEdit,
  IconMore,
  IconPlus,
  SplitButton,
} from "@kushki/connect-ui";
import { CheckCircle, Trash } from "react-feather";
import { InfoIconsProcessorAccordion } from "../InfoIconsProcessorAccordion/InfoIconsProcessorAccordion";
import { AccordionBody } from "../AccordionBody/AccordionBody";
import { useProcessorState } from "./state/useProcessorState";
import { GetProcessorResponse } from "../../../types/get_processor_response";
import { AppRoutes } from "../../shared/infrastructure/routes";
import { emptyHandle, getMerchantId } from "../../utils/utilsFile";
import { defaultTo, get, isEmpty } from "lodash";
import { SimpleModal } from "../SimpleModal/SimpleModal";
import { SimpleModalWithSelect } from "../SimpleModalWithSelect/SimpleModalWithSelect";
import { ProcessingLoadingPanel } from "./ProcessingLoadingPanel/ProcessingLoadingPanel";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import { AlertPanel } from "../AlertPanel/AlertPanel";
import { AlertPanelType } from "../AlertPanel/AlertPanel.data";

export const Processors: React.FC = () => {
  const classes = processorsStyles();
  const {
    isMassivePath,
    disableProcessorsTab,
    processor,
    isDefaultProcessor,
    isOpenDeleteProcessorAlert,
    isRenderFailoverOption,
    isShowFailoverMessage,
    isOpenFailoverModalProcessor,
    failoverProcessorList,
    isProcessorsCentralized,
    country,
    appConfigPaymentMethods,
    handleOnCloseAlert,
    handleOnClickEditProcessor,
    handleOnClickDeleteProcessor,
    handleOnAcceptModalDeleteProcessor,
    handleOnClickFailover,
    handleOnCloseFailoverProcessorModal,
    handleOnChangeFailoverProcessorModalSelect,
    handleConfigureFailover,
    failoverPerDefault,
    loadingProcessor,
  } = useProcessorState();

  return (
    <Card className={classes.mainCard}>
      <MaskSkeleton
        skeleton={<ProcessingLoadingPanel />}
        isLoading={loadingProcessor}
      >
        <CardContent>
          {isMassivePath && disableProcessorsTab && (
            <Box p={5}>
              <Alert
                title={ProcessorConstants.ALERT_DECENTRALIZE_PROCESSORS}
                type={AlertPanelType.warning}
              />
            </Box>
          )}
          {!(isMassivePath && disableProcessorsTab) && (
            <>
              <Box pt={5} px={6}>
                <Typography variant="h3" mb={2}>
                  {ProcessorConstants.PROCESSORS}
                </Typography>
                <Divider className={classes.marginBottom} />
                <Grid
                  container
                  direction={"row"}
                  spacing={3}
                  alignItems={"center"}
                >
                  <Grid item xs={12} sm={6} md={8}>
                    {get(processor, "length", 0) <= 0 &&
                      !isProcessorsCentralized && (
                        <AlertPanel
                          text={ProcessorConstants.PROCESSORS_DESCRIPTION}
                          type={AlertPanelType.error}
                        />
                      )}
                    {isMassivePath && (
                      <Box pb={2}>
                        <AlertPanel
                          text={ProcessorConstants.MASSIVE_MESSAGE}
                          type={AlertPanelType.info}
                        />
                      </Box>
                    )}
                    {isShowFailoverMessage && (
                      <AlertPanel
                        text={ProcessorConstants.FAILOVER_MESSAGE}
                        type={AlertPanelType.error}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {!isProcessorsCentralized &&
                      !isEmpty(country) &&
                      !isEmpty(appConfigPaymentMethods) && (
                        <SplitButton
                          onItemSelected={emptyHandle}
                          items={processorMethodsByCountry(
                            country,
                            appConfigPaymentMethods
                          )}
                          type={"accordionSingle"}
                          variant={"secondaryOutlined"}
                          size={"medium"}
                          text={ProcessorConstants.ADD_PROCESSOR}
                          icon={<IconPlus />}
                        />
                      )}
                  </Grid>
                  {!isEmpty(processor) && (
                    <Grid item xs={12}>
                      <Box
                        display={"flex"}
                        justifyContent={"end"}
                        alignItems={"self-end"}
                      >
                        <Button
                          className={"buttonLink"}
                          color={"primary"}
                          href={AppRoutes.PROCESSOR_LIST(getMerchantId())}
                        >
                          {isProcessorsCentralized
                            ? ProcessorConstants.REVIEW_ALL_PROCESSORS
                            : ProcessorConstants.EDIT_REVIEW_ALL_PROCESSORS}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box px={6} pb={6}>
                <Grid container direction={"column"} spacing={2}>
                  {processor
                    .slice(0, 5)
                    .map((data: GetProcessorResponse, index: number) => (
                      <Grid key={`${data.alias}-${index}`} item>
                        <Accordion
                          text={data.alias}
                          isOpen={false}
                          divider={false}
                          nesting={1}
                          tagText={
                            defaultTo(
                              ChipProcessorData[data.status],
                              ChipProcessorData[
                                ProcessorConstants.STATUS_ENABLED
                              ]
                            ).label
                          }
                          tagColor={
                            defaultTo(
                              ChipProcessorData[data.status],
                              ChipProcessorData[
                                ProcessorConstants.STATUS_ENABLED
                              ]
                            ).type
                          }
                          nestingLabel={""}
                          listDropdown={listDropDownAccordion(
                            <IconEdit />,
                            <Trash />,
                            <CheckCircle />,
                            () => {
                              handleOnClickEditProcessor(data);
                            },
                            () => {
                              handleOnClickDeleteProcessor(data);
                            },
                            () => {
                              handleOnClickFailover(data);
                            },
                            isRenderFailoverOption,
                            data
                          )}
                          iconButton={<IconMore />}
                          iconRight={
                            <InfoIconsProcessorAccordion processor={data} />
                          }
                          // eslint-disable-next-line react/no-children-prop
                          children={<AccordionBody processor={data} />}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </>
          )}
          <Box>
            <SimpleModal
              isDefaultProcessor={isDefaultProcessor}
              isOpenDeleteProcessorAlert={isOpenDeleteProcessorAlert}
              handleOnAcceptModalDeleteProcessor={
                handleOnAcceptModalDeleteProcessor
              }
              handleOnCloseAlert={handleOnCloseAlert}
            />
          </Box>
          <Box>
            <SimpleModalWithSelect
              isOpenFailoverProcessorModal={isOpenFailoverModalProcessor}
              handleOnCloseFailoverProcessorModal={
                handleOnCloseFailoverProcessorModal
              }
              handleOnChangeFailoverProcessorModalSelect={
                handleOnChangeFailoverProcessorModalSelect
              }
              handleConfigureFailover={handleConfigureFailover}
              failoverProcessors={failoverProcessorList}
              failoverPerDefault={failoverPerDefault}
            />
          </Box>
        </CardContent>
      </MaskSkeleton>
    </Card>
  );
};
