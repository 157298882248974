import { ChargebackStatusEnum } from "../enums/ChargebackStatusEnum";

export const getColorByStatus = (status: string) => {
  switch (status) {
    case ChargebackStatusEnum.APPROVED:
    case ChargebackStatusEnum.APPROVAL:
      return "success";
    case ChargebackStatusEnum.DECLINED:
      return "error";
    case ChargebackStatusEnum.INITIALIZED:
      return "primary";
    case ChargebackStatusEnum.EXPIRED:
      return "error";
    case ChargebackStatusEnum.WON:
      return "success";
    case ChargebackStatusEnum.REVIEW:
      return "warning";
    case ChargebackStatusEnum.LOST:
      return "error";
    case ChargebackStatusEnum.PENDING:
      return "default";
    default:
      return "info";
  }
};
