import React from "react";
import { Button, Grid } from "@material-ui/core";
import { uploadActionButtonComponentEcuadorStyle } from "./UploadActionButtonComponentEcuador.style";
import { FileUploadActionButtonsComponentProps } from "./UploadActionButtonsComponentEcuador.interfaces";

export const UploadActionButtonsComponentEcuador: React.FC<FileUploadActionButtonsComponentProps> = (
  props: FileUploadActionButtonsComponentProps
) => {
  const style = uploadActionButtonComponentEcuadorStyle(0);

  return (
    <Grid container justify="flex-end">
      <Grid item xs={12} md={2}>
        <Button
          id="backbtn"
          fullWidth
          onClick={props.handleBack}
          className={style.back}
        >
          Regresar
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          id="savebtn"
          size="medium"
          fullWidth
          disabled={!props.isFileLoaded}
          variant="contained"
          color="primary"
          onClick={props.onSubmit}
        >
          {props.buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};
