import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMassiveBranch } from "../../../shared/interfaces/massiveBranch/massiveBranch.interfaces";

export const initialState: IMassiveBranch = {};

export const massiveMerchantSlice = createSlice({
  initialState,
  name: "massiveBranch",
  reducers: {
    setUpdateMassiveStatus: (
      state,
      { payload }: PayloadAction<boolean | undefined>
    ) => {
      state.updateMassiveBranchStatus = payload;
    },
  },
});

export default massiveMerchantSlice.reducer;
