import { ITableColumn } from "../interfaces/Table.interfaces";

export const HISTORICAL_TABLE_HEADERS_COLUMNS: ITableColumn[] = [
  {
    id: "created",
    label: "F. de inicio",
    sortable: true,
    type: "text",
  },
  {
    id: "merchant_name",
    label: "Comercios",
    sortable: false,
    type: "text",
  },
  {
    id: "original_transactional_reference",
    label: "Referencia de la transacción original",
    sortable: false,
    type: "text",
  },
  {
    align: "center",
    id: "amount",
    label: "Monto total",
    sortable: false,
    type: "text",
  },
  {
    align: "center",
    id: "status",
    label: "E. Final",
    sortable: false,
    type: "chip",
  },
];
