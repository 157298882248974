import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const branchCreationContainerStyles = createNamedStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: 1,
    justifyContent: "center",
    left: "50%",
    position: "fixed",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 1,
  },
  options: {
    pb: 10,
    pt: 8,
  },
});
