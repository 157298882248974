import { SchemaValueName } from "../../../../types/schema_value_name";

export enum DynamoName {
  COMMERCIAL_EXECUTIVE = "commercialExecutive",
  MERCHANT_SIZE = "merchantSize",
  EMAIL = "email",
  NAME = "name",
  CONTACT_AREAS = "contactAreas",
}

export const CATEGORY_MERCHANT: SchemaValueName[] = [
  { name: "Small", value: "Small" },
  { name: "Medium", value: "Medium" },
  { name: "Enterprise", value: "Enterprise" },
];
