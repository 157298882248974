import { createNamedStyles } from "../../../shared/utils";

export const buttonStyles = createNamedStyles({
  iconCalendar: {
    p: "2px",
    height: 24,
    width: 24,
    color: "components.disabled",
  },
});
