import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createBranch } from "../../thunks/resumeNode/resumenode.thunks";
import { get } from "lodash";
import { ErrorEnum } from "../../../shared/enums/ErrorEnum";

export interface ResumeForm {
  createBranchButton: boolean;
  openModalCreate: boolean;
  openConfirmationModal: boolean;
  validateBranchName: boolean;
  openLoaderModal: boolean;
}

export const initialState: ResumeForm = {
  createBranchButton: true,
  openConfirmationModal: false,
  openLoaderModal: false,
  openModalCreate: false,
  validateBranchName: false,
};

export const resumeNodeSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(createBranch.pending, (state) => {
        state.openModalCreate = false;
        state.openLoaderModal = true;
      })
      .addCase(createBranch.fulfilled, (state) => {
        state.openLoaderModal = false;
        state.openConfirmationModal = true;
      })
      .addCase(createBranch.rejected, (state, { payload }) => {
        if (get(payload, "code", "") === ErrorEnum.node_create_duplicate) {
          state.openLoaderModal = false;
          state.openModalCreate = true;
          state.validateBranchName = true;
        }

        state.openLoaderModal = false;
        state.openModalCreate = true;
      });
  },
  initialState,
  name: "resumeNode",
  reducers: {
    setCreateBranchButton: (state, { payload }: PayloadAction<boolean>) => {
      state.createBranchButton = payload;
    },
    setOpenModalCreate: (state, { payload }: PayloadAction<boolean>) => {
      state.openModalCreate = payload;
    },
  },
});

export default resumeNodeSlice.reducer;
