import React from "react";
import {
  Box,
  createStyles,
  Grid,
  MuiThemeProvider,
  Paper,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ModalBodyProps } from "../ModalBody";
import { defaultTo, get, omit } from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import { RuleSchemaEnum } from "../../../shared/infrastructure/constants/RulesConstants";
import { ConfigurationSectionsEnum } from "../../../shared/infrastructure/constants/ModalTabs";
import { useConfigurationState } from "./state/useConfigurationState";
import {
  GENERAL_ACTIONS,
  GeneralActionsEnum,
} from "../../../shared/infrastructure/enums/GeneralActionsEnum";
import { CARD_TYPE_VALUES } from "../../../shared/infrastructure/constants/SecurityRulesConstants";

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      color: "#293036 !important",
      backgroundColor: "#F7FAFC !important",
      borderRadius: "4px !important",
      marginRight: "8px !important",
      marginBottom: "8px !important",
    },
    paper: {
      paddingBottom: 15,
      backgroundColor: "#F7FAFC !important",
      borderRadius: 3,
      boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
      width: "100%",
    },
  })
);

const ConfigurationComponent = (props: ModalBodyProps) => {
  const classes = useStyles();
  const { businessRulesGeneral, rule } = useConfigurationState(props);

  return (
    <Box p={4}>
      <Grid container spacing={2}>
        <Paper elevation={0} className={classes.paper}>
          <Grid
            container
            spacing={1}
            color="secondary"
            style={{
              marginTop: 16,
              padding: "0px 20px",
            }}
          >
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MuiThemeProvider theme={theme}>
                    <Typography variant="h6" color="textPrimary">
                      {ConfigurationSectionsEnum.GENERAL_DATA}
                    </Typography>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {ConfigurationSectionsEnum.ALIAS}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {props.data.rule?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {ConfigurationSectionsEnum.PROVIDER_NAME}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {props.data.rule?.action === GeneralActionsEnum.THREE_DS
                      ? ConfigurationSectionsEnum.PROVIDER_3DS
                      : ConfigurationSectionsEnum.PROVIDER_OTP}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {ConfigurationSectionsEnum.AUTHENTICATION_TYPE}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {defaultTo(
                      GENERAL_ACTIONS.get(get(props, "data.rule.action")),
                      get(props, "data.rule.action", "")
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          style={{ marginTop: "24px", paddingRight: 0 }}
          elevation={0}
          className={classes.paper}
        >
          <Grid
            container
            spacing={1}
            color="secondary"
            style={{
              marginTop: 20,
              padding: "0px 20px",
            }}
          >
            <Grid>
              <Grid container spacing={4}>
                <Grid item>
                  <MuiThemeProvider theme={theme}>
                    <Typography variant="h6" color="textPrimary">
                      {ConfigurationSectionsEnum.AUTHENTICATION_RULES}
                    </Typography>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {ConfigurationSectionsEnum.GENERAL_CONDITIONS}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {Object.keys(omit(rule, ["name"])).map(
              (property: string, idx: number) =>
                businessRulesGeneral[property] &&
                rule[property] && (
                  <Grid key={idx} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {businessRulesGeneral[property]}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {businessRulesGeneral[rule[property].split("|")[0]]}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {property === RuleSchemaEnum.transactionProcessor
                            ? rule
                            : property === RuleSchemaEnum.transactionCredential
                            ? rule
                            : property ===
                              RuleSchemaEnum.transactionIsCreditCard
                            ? businessRulesGeneral[
                                rule[property].split("|")[1]!
                              ] === "true"
                              ? CARD_TYPE_VALUES[0].name
                              : CARD_TYPE_VALUES[1].name
                            : property === RuleSchemaEnum.transactionIsDeferred
                            ? businessRulesGeneral[
                                businessRulesGeneral[rule[property]!]
                              ]
                            : rule[property].split("|")[1]}

                          {property === RuleSchemaEnum.transactionTotalAmount &&
                          rule[RuleSchemaEnum.transactionCurrency]
                            ? ` ${
                                rule[RuleSchemaEnum.transactionCurrency]!.split(
                                  "|"
                                )[1]
                              }`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
            )}

            {rule["transactionCreated"] && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2">Horario</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                          Desde
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {
                            rule[RuleSchemaEnum.transactionCreated]!.split(
                              "|"
                            )[1].split(";")[0]
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                          Hasta
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {
                            rule[RuleSchemaEnum.transactionCreated]!.split(
                              "|"
                            )[1].split(";")[1]
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {rule[RuleSchemaEnum.transactionStatusSum] && (
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {ConfigurationSectionsEnum.SPEED_CONDITIONS}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body2">Sumar</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {
                          businessRulesGeneral[
                            rule[RuleSchemaEnum.periodicitySum]!
                          ]
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {
                          businessRulesGeneral[
                            rule[RuleSchemaEnum.transactionStatusSum]!
                          ]
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {rule[RuleSchemaEnum.maxAmount]!}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {rule[RuleSchemaEnum.transactionStatusCount] && (
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {ConfigurationSectionsEnum.SPEED_CONDITIONS}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="body2">{"Contar"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {
                          businessRulesGeneral[
                            rule[RuleSchemaEnum.periodicityCount]!
                          ]
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {
                          businessRulesGeneral[
                            rule[RuleSchemaEnum.transactionStatusCount]!
                          ]
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {rule[RuleSchemaEnum.maxAmount]!}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
        {!!get(rule, "ignore3DS") ? (
          <Paper
            style={{ marginTop: "24px", paddingRight: 0 }}
            elevation={0}
            className={classes.paper}
          >
            <Grid
              container
              spacing={1}
              color="secondary"
              style={{
                marginTop: 20,
                padding: "0px 20px",
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <MuiThemeProvider theme={theme}>
                      <Typography variant="h6" color="textPrimary">
                        {ConfigurationSectionsEnum.ADDITIONAL_SETTINGS}
                      </Typography>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={1}>
                    {get(rule, "ignore3DS", false) && (
                      <CheckIcon
                        style={{ marginTop: "-4px", color: "#0DC298" }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2" color="textSecondary">
                      {ConfigurationSectionsEnum.ASSUME_TRANSACTIONAL_RISK}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Grid
            container
            style={{
              marginTop: 20,
            }}
          />
        )}
      </Grid>
    </Box>
  );
};

export default ConfigurationComponent;
