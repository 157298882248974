import React, { ChangeEvent } from "react";
import {
  Grid,
  Typography,
  Switch,
  Theme,
  createStyles,
  Paper,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { merchantIdDeferredAction } from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { Loader } from "@kushki/frontend-molecules/loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

export const ProcessorsDeferredSection = ({
  publicMerchantId,
}: {
  publicMerchantId: string;
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isloaderDeferred, deferredSwitch } = useSelector(
    (state: IAppState) => state
  );
  const validDeferred = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deferredOptions = e.target.checked;
    dispatch(merchantIdDeferredAction(publicMerchantId, deferredOptions));
  };

  return (
    <Grid component={Paper} className={classes.paper} elevation={0}>
      <Typography variant={"h5"}>Diferidos</Typography>
      <Divider className={classes.divider} />
      <Grid>
        <Typography variant={"caption"}>
          ¿Activar diferidos para este comercio?
        </Typography>

        <Switch
          color="primary"
          onChange={(e: ChangeEvent<HTMLInputElement>) => validDeferred(e)}
          checked={!!deferredSwitch}
        />
      </Grid>
      <Loader
        open={isloaderDeferred!}
        headerText={"Cargando...."}
        bodyText={"Cargando...."}
      />
    </Grid>
  );
};
