import { makeStyles } from "@mui/styles";
import { Palette } from "../../Themes/thememui";

export const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 16,
    boxShadow: "0px 0px 10px 5px #6aa7c529",
    margin: 50,
    padding: 20,
  },
  titleBilling: {
    color: Palette.primary.dark,
    fontFamily: "IBM Plex Sans",
    fontSize: "26px !important",
    fontWeight: "bold !important",
  },
  subTitleCountry: {
    color: Palette.text.disabled,
    fontFamily: "IBM Plex Sans",
    fontSize: "16px !important",
  },
  phone: {
    marginTop: "2px !important",
  },
  divider: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
    height: 0,
  },
  countryFlag: {
    paddingTop: "6px",
  },
  subtitle: {
    fontSize: "20px !important",
    marginTop: "20px !important",
    marginBottom: "30px !important",
    color: Palette.text.grey,
  },
  satDivider: {
    marginTop: "30px !important",
    marginBottom: "10px !important",
    height: 0,
  },
  satSubtitle: {
    fontSize: "20px !important",
    marginTop: "20px !important",
    marginBottom: "10px !important",
    color: Palette.text.grey,
  },
  satMessage: {
    fontSize: "13px !important",
    color: Palette.text.dark,
    marginTop: "11px !important",
  },
  satForm: {
    marginTop: "11px !important",
  },
  satInfoMessage: {
    fontSize: "16px !important",
    color: Palette.text.dark,
  },
  errorMessage: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A !important",
    },
  },
}));
