import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { CreateNodeRequest } from "../../../../types/create_node_request";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { CreateNodeResponse } from "../../../../types/create_node_response";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";

export const createOwner = createAsyncThunk<
  CreateNodeResponse,
  CreateNodeRequest
>("owner/createOwner", async (request) => {
  const response = await axios.post<CreateNodeResponse>(
    API_ROUTES.CREATE_NODE,
    request,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const listOwners = createAsyncThunk<
  SearchMerchantResponse,
  SearchMerchantRequest
>("owner/listOwners", async (request) => {
  const response = await axios.post<SearchMerchantResponse>(
    API_ROUTES.SEARCH_MERCHANT_NODE,
    request,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});
