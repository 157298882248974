import React, { useEffect, useState } from "react";
import { IButtonCardProps } from "../../ButtonCard/ButtonCard.interfaces";
import {
  MENU_ID_PROPS,
  MenuItemNamesEnum,
} from "../../../shared/infrastructure/MenuItemNamesEnum";
import {
  MenuItemMassivePathEnum,
  MenuItemPathsEnumPathEnum,
} from "../../../shared/infrastructure/MenuItemPathsEnum";
import { DisableBillingConfigurationIcon } from "../../../assets/icons/DisableBillingConfigurationIcon";
import { DisableProcessingIcon } from "../../../assets/icons/DisableProcessingIcon";
import { DisableServicesIcon } from "../../../assets/icons/DisableServicesIcon";
import { useLocation, useSearchParams } from "react-router-dom";
import { DisabledBankIcon } from "../../../assets/icons/DisabledBankIcon";
import { MenuItemStepsEnum } from "../../../shared/infrastructure/MenuItemStepsEnum";
import { IMenuItemProps } from "../MenuItem.interfaces";
import { auth } from "../../../shared/auth";
import {
  defaultTo,
  each,
  find,
  get,
  isArray,
  isEmpty,
  isNil,
  set,
} from "lodash";
import { useAppSelector } from "../../../store/hooks/storeHook";
import {
  BUTTON_CARD_STATUS_PROPS,
  LIST_STATUS_SIDEBAR_COMPLETE,
  StatusEnum,
} from "../../../shared/constants/button_card_status_props";
import { MenuPathKeysEnum } from "../../../shared/infrastructure/MenuPathKeysEnum";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import {
  MenuBasePathsEnum,
  TranslatePathStep,
} from "../../../shared/infrastructure/MenuPathsEnum";
import {
  IconBank,
  IconCardSwipe,
  IconChartSmall,
  IconMixer,
  IconStickyFilled,
} from "@kushki/connect-ui";
import { DisableStickyFilled } from "../../../assets/icons/DisableStickyFilled";
import { DisableDevelopersIcon } from "../../../assets/icons/DisableDevelopersIcon";
import { DevelopersIcon } from "../../../assets/icons/DevelopersIcon";
import { getSessionStorageValueByPath } from "../../../store/utils/sessionStorage-utils";
import {
  getMassiveUrlByTitle,
  getSideMenuActiveIconByTitle,
  getSideMenuItemStepByTitle,
} from "../../../shared/utils/menuItemUtils";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { RootState } from "../../../store/store";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import { getConfigsByStep } from "../../../shared/utils/GetConfigsByStep";
import {
  OperationEnum,
  pathValidateIsContainsUrl,
} from "../../../shared/enums/sections";

export interface MenuItem {
  btnResume: IButtonCardProps;
  buttons: IButtonCardProps[];
  isMassiveOrBatch: boolean;
}

export const useMenuItem = (): MenuItem => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { navigate } = useAppNavigate();
  const merchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);
  const [isMassiveOrBatch, setIsMassiveOrBatch] = useState<boolean>(false);

  const { nodeInfo } = useAppSelector((store: RootState) => store.layout);

  const findConfigsByStep = (
    listConfigs: Configs[],
    configsByStep: string[]
  ): Configs[] => {
    const filterConfigs: Configs[] = [];

    defaultTo(configsByStep, []).map((config: string) => {
      const findConfig: Configs = defaultTo(
        find(listConfigs, ["configuration", config]),
        {}
      );

      if (!isEmpty(findConfig)) filterConfigs.push(findConfig);
    });

    return filterConfigs;
  };

  const verifyProgressCompleteStep = (
    step: Configs[],
    lengthStep: number
  ): boolean => {
    if (isEmpty(step)) return false;

    const filter_complete: Configs[] = step.filter((value: Configs) =>
      LIST_STATUS_SIDEBAR_COMPLETE.includes(get(value, "status", ""))
    );

    return filter_complete.length === lengthStep;
  };
  const getStatus = (
    info: GetNodeInfoResponse,
    path: MenuPathKeysEnum,
    isCurrentStepComplete: boolean
  ): StatusEnum => {
    const currentUrl = location.pathname;
    const stepSelected = get(MenuBasePathsEnum, path, "-");
    const country: string = get(info, "generalInfo.country", "");
    const permitted_configs: string[] = getConfigsByStep(path, country);
    const configsStep: Configs[] = findConfigsByStep(
      get(info, "configs", []),
      permitted_configs
    );

    const isStepComplete = verifyProgressCompleteStep(
      configsStep,
      permitted_configs.length
    );

    let isInMenuItem: boolean = false;
    const basePath = `/${currentUrl.split("/").join("?").split("?")[1]}`;

    Object.values(MenuBasePathsEnum).map((path) => {
      if (basePath === path) {
        isInMenuItem = true;
      }
    });

    if (isEmpty(configsStep)) return StatusEnum.DISABLED;

    if (basePath === stepSelected) {
      return isStepComplete ? StatusEnum.COMPLETE : StatusEnum.SELECTED;
    } else if (isInMenuItem) {
      return isStepComplete
        ? StatusEnum.COMPLETE
        : isCurrentStepComplete
        ? StatusEnum.PENDING
        : StatusEnum.DISABLED;
    } else return StatusEnum.PENDING;
  };

  const buildSideButtonsMerchantIdFlow = () => {
    return [
      {
        ...BUTTON_CARD_STATUS_PROPS[
          getStatus(nodeInfo, MenuPathKeysEnum.MERCHANT_DATA, false)
        ],
        disabledIcon: <DisabledBankIcon />,
        enableIcon: (
          <IconBank className="iconChoice" color="primary" fontSize="large" />
        ),
        id: MENU_ID_PROPS[MenuItemNamesEnum.MERCHANT_DATA],
        step: MenuItemStepsEnum.BASIC_DATA,
        title: MenuItemNamesEnum.MERCHANT_DATA,
        url: MenuItemPathsEnumPathEnum.MERCHANT_DATA.replace(
          "{merchantId}",
          merchantId as string
        ),
      },
      {
        ...BUTTON_CARD_STATUS_PROPS[
          getStatus(nodeInfo, MenuPathKeysEnum.BILLING_CONFIGURATION, false)
        ],
        disabledIcon: <DisableBillingConfigurationIcon />,
        enableIcon: (
          <IconChartSmall
            className="iconChoice"
            color="primary"
            fontSize="large"
          />
        ),
        id: MENU_ID_PROPS[MenuItemNamesEnum.BILLING_CONFIGURATION],
        step: MenuItemStepsEnum.CONFIG_RATES_INVOICE,
        title: MenuItemNamesEnum.BILLING_CONFIGURATION,
        url: MenuItemPathsEnumPathEnum.BILLING_CONFIGURATION.replace(
          "{merchantId}",
          merchantId as string
        ),
      },
      {
        ...BUTTON_CARD_STATUS_PROPS[
          getStatus(nodeInfo, MenuPathKeysEnum.PROCESSING, false)
        ],
        disabledIcon: <DisableProcessingIcon />,
        enableIcon: (
          <IconCardSwipe
            className="iconChoice"
            color="primary"
            fontSize="large"
          />
        ),
        id: MENU_ID_PROPS[MenuItemNamesEnum.PROCESSING],
        step: MenuItemStepsEnum.PROCESSING,
        title: MenuItemNamesEnum.PROCESSING,
        url: MenuItemPathsEnumPathEnum.PROCESSING.replace(
          "{merchantId}",
          merchantId as string
        ),
      },
      {
        ...BUTTON_CARD_STATUS_PROPS[
          getStatus(nodeInfo, MenuPathKeysEnum.SERVICES, false)
        ],
        disabledIcon: <DisableServicesIcon />,
        enableIcon: (
          <IconMixer className="iconChoice" color="primary" fontSize="large" />
        ),
        id: MENU_ID_PROPS[MenuItemNamesEnum.SERVICES],
        step: MenuItemStepsEnum.SERVICES,
        title: MenuItemNamesEnum.SERVICES,
        url: MenuItemPathsEnumPathEnum.SERVICES.replace(
          "{merchantId}",
          merchantId as string
        ),
      },
      {
        ...BUTTON_CARD_STATUS_PROPS[
          getStatus(nodeInfo, MenuPathKeysEnum.RATES_AND_DISCOUNTS, false)
        ],
        disabledIcon: <DisableStickyFilled />,
        enableIcon: (
          <IconStickyFilled
            className="iconChoice"
            color="primary"
            fontSize="large"
          />
        ),
        id: MENU_ID_PROPS[MenuItemNamesEnum.RATES_AND_DISCOUNTS],
        step: MenuItemStepsEnum.RATES_AND_DISCOUNTS,
        title: MenuItemNamesEnum.RATES_AND_DISCOUNTS,
        url: MenuItemPathsEnumPathEnum.RATES_AND_DISCOUNTS.replace(
          "{merchantId}",
          merchantId as string
        ),
      },
      {
        ...BUTTON_CARD_STATUS_PROPS[
          getStatus(nodeInfo, MenuPathKeysEnum.DEVELOPERS, false)
        ],
        disabledIcon: <DisableDevelopersIcon />,
        enableIcon: <DevelopersIcon />,
        id: MENU_ID_PROPS[MenuItemNamesEnum.DEVELOPERS],
        step: MenuItemStepsEnum.DEVELOPERS,
        title: MenuItemNamesEnum.DEVELOPERS,
        url: MenuItemPathsEnumPathEnum.DEVELOPERS.replace(
          "{merchantId}",
          merchantId as string
        ),
      },
    ];
  };

  const buildSideButtonsBatchEdition = (): IButtonCardProps[] => {
    const menuItem = isArray(getSessionStorageValueByPath("batchSections"))
      ? getSessionStorageValueByPath("batchSections")
      : [];

    return menuItem.map((menu: IButtonCardProps) => {
      return {
        checked: true,
        enableIcon: getSideMenuActiveIconByTitle.get(
          menu.title as MenuItemNamesEnum
        ),
        id: menu.id,
        isDisabled: false,
        isSelected:
          location.pathname ===
          getMassiveUrlByTitle.get(menu.title as MenuItemNamesEnum),
        step: getSideMenuItemStepByTitle.get(menu.title as MenuItemNamesEnum),
        title: menu.title,
        url: menu.url,
      };
    });
  };

  const [buttons, setButtons] = useState<IButtonCardProps[]>(
    isNil(merchantId)
      ? buildSideButtonsBatchEdition()
      : buildSideButtonsMerchantIdFlow()
  );

  const [btnResume] = useState<IButtonCardProps>({
    id: MENU_ID_PROPS[MenuItemNamesEnum.RESUME],
    isDisabled: false,
    isSelected: false,
    title: MenuItemNamesEnum.RESUME,
    url: `${MenuItemPathsEnumPathEnum.RESUME}?publicMerchantId=${merchantId}&menuItem=true`,
  });

  const getBtnProps = () => {
    const keyValuesPath = Object.keys(MenuPathKeysEnum);
    const currentBasePAthUrl = location.pathname.split("/")[1];
    const currentStep = TranslatePathStep[
      `/${currentBasePAthUrl}`
    ] as MenuPathKeysEnum;
    const configs: Configs[] = get(nodeInfo, "configs", []);
    const country: string = get(nodeInfo, "generalInfo.country", "");

    const isCurrentStepComplete = verifyProgressCompleteStep(
      findConfigsByStep(configs, getConfigsByStep(currentStep, country)),
      getConfigsByStep(currentStep, country).length
    );

    const isMerchantComplete = verifyProgressCompleteStep(
      findConfigsByStep(
        configs,
        getConfigsByStep(MenuPathKeysEnum.MERCHANT_DATA, country)
      ),
      getConfigsByStep(MenuPathKeysEnum.MERCHANT_DATA, country).length
    );

    let modifiedBtn = buttons.map((btn: IButtonCardProps, index) => {
      if (btn.id === MenuPathKeysEnum.MERCHANT_DATA) {
        return {
          ...btn,
          ...BUTTON_CARD_STATUS_PROPS[
            isMerchantComplete ? StatusEnum.COMPLETE : StatusEnum.PENDING
          ],
        };
      }

      const currentStepStatus = isMerchantComplete || isCurrentStepComplete;

      const status: StatusEnum = getStatus(
        nodeInfo,
        keyValuesPath[index] as MenuPathKeysEnum,
        currentStepStatus
      );

      return {
        ...btn,
        ...BUTTON_CARD_STATUS_PROPS[status],
      };
    });

    each(modifiedBtn, (btn: IButtonCardProps) => {
      if (btn.url.includes(currentBasePAthUrl)) {
        set(btn, "isSelected", true);
      }
    });

    setButtons(modifiedBtn);
  };

  const setMenuItemResponse = (): IMenuItemProps[] => {
    let getMenuItem: IMenuItemProps[] = buttons.map((btn: IButtonCardProps) => {
      return {
        disabledIcon: btn.disabledIcon,
        enableIcon: btn.enableIcon,
        isDisabled: btn.isDisabled,
        isSelected: btn.isSelected,
        redirect: btn.url,
        status: auth.getMenuItemStep(btn.step!),
        title: btn.title,
      };
    });

    getMenuItem.push({
      redirect: btnResume.url,
      title: btnResume.title,
    });

    return getMenuItem;
  };
  const setIsFromMassive = () => {
    const massiveEdition: string = defaultTo(
      searchParams.get(QueryParamsEnum.MODE),
      ""
    );

    setIsMassiveOrBatch(
      location.pathname.includes(pathValidateIsContainsUrl.batch) ||
        massiveEdition === OperationEnum.MASSIVE_EDITION
    );
  };

  useEffect(() => {
    if (!isNil(merchantId)) {
      getBtnProps();
    } else {
      setButtons(buildSideButtonsBatchEdition());
    }
    setIsFromMassive();
  }, [location.pathname, nodeInfo]);

  const handleFunctionsMerchantIdFlow = () => {
    setMenuItemResponse();
  };

  const setFirstPath = () => {
    const menuItem = getSessionStorageValueByPath("batchSections");
    const titleFirstMenuItem = get(
      menuItem,
      "[0].title",
      MenuItemNamesEnum.MERCHANT_DATA
    );

    navigate(getMassiveUrlByTitle.get(titleFirstMenuItem)!);
  };

  useEffect(() => {
    const isBatch = location.pathname.includes(pathValidateIsContainsUrl.batch);

    if (
      isNil(merchantId) &&
      !isBatch &&
      !location.pathname.includes(
        MenuItemMassivePathEnum.RATES_DISCOUNTS_MASSIVE
      )
    ) {
      setFirstPath();
    } else {
      handleFunctionsMerchantIdFlow();
    }
  }, []);

  return {
    btnResume,
    buttons,
    isMassiveOrBatch,
  };
};
