/**
 * Catalog Colombia
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../enums/CatalogsEnum";

export const CatalogMx: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "CURP", value: "0" },
    { name: "Pasaporte", value: "1" },
    { name: "RFC", value: "3" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "AGUASCALIENTES", value: "AGU" },
    { name: "BAJA CALIFORNIA", value: "BCN" },
    { name: "BAJA CALIFORNIA SUR", value: "BCS" },
    { name: "CAMPECHE", value: "CAM" },
    { name: "CHIAPAS", value: "CHP" },
    { name: "CHIHUAHUA", value: "CHH" },
    { name: "COAHUILA", value: "COA" },
    { name: "COLIMA", value: "COL" },
    { name: "DISTRITO FEDERAL", value: "DIF" },
    { name: "DURANGO", value: "DUR" },
    { name: "GUANAJUATO", value: "GUA" },
    { name: "GUERRERO", value: "GRO" },
    { name: "HIDALGO", value: "HID" },
    { name: "JALISCO", value: "JAL" },
    { name: "MÉXICO", value: "MEX" },
    { name: "MICHOACÁN", value: "MIC" },
    { name: "MORELOS", value: "MOR" },
    { name: "NAYARIT", value: "NAY" },
    { name: "NUEVO LEÓN", value: "NLE" },
    { name: "OAXACA", value: "OAX" },
    { name: "PUEBLA", value: "PUE" },
    { name: "QUERÉTARO", value: "QUE" },
    { name: "QUINTANA ROO", value: "ROO" },
    { name: "SAN LUIS POTOSÍ", value: "SLP" },
    { name: "SINALOA", value: "SIN" },
    { name: "SONORA", value: "SON" },
    { name: "TABASCO", value: "TAB" },
    { name: "TAMAULIPAS", value: "TAM" },
    { name: "TLAXCALA", value: "TLA" },
    { name: "VERACRUZ", value: "VER" },
    { name: "YUCATÁN", value: "YUC" },
    { name: "ZACATECAS", value: "ZAC" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "BANCOMEXT", value: "37006" },
    { name: "BANOBRAS", value: "37009" },
    { name: "BANJERCITO", value: "37019" },
    { name: "NAFIN", value: "37135" },
    { name: "BANSEFI", value: "37166" },
    { name: "HIPOTECARIA FED", value: "37168" },
    { name: "BANAMEX", value: "40002" },
    { name: "BBVA BANCOMER", value: "40012" },
    { name: "SANTANDER", value: "40014" },
    { name: "HSBC", value: "40021" },
    { name: "BAJIO", value: "40030" },
    { name: "INBURSA", value: "40036" },
    { name: "INTERACCIONES", value: "40037" },
    { name: "MIFEL", value: "40042" },
    { name: "SCOTIABANK", value: "40044" },
    { name: "BANREGIO", value: "40058" },
    { name: "INVEX", value: "40059" },
    { name: "BANSI", value: "40060" },
    { name: "AFIRME", value: "40062" },
    { name: "BANORTE/IXE", value: "40072" },
    { name: "INVESTA BANK", value: "40102" },
    { name: "AMERICAN EXPRES", value: "40103" },
    { name: "BAMSA", value: "40106" },
    { name: "TOKYO", value: "40108" },
    { name: "JP MORGAN", value: "40110" },
    { name: "BMONEX", value: "40112" },
    { name: "VE POR MAS", value: "40113" },
    { name: "DEUTSCHE", value: "40124" },
    { name: "CREDIT SUISSE", value: "40126" },
    { name: "AZTECA", value: "40127" },
    { name: "AUTOFIN", value: "40128" },
    { name: "BARCLAYS", value: "40129" },
    { name: "COMPARTAMOS", value: "40130" },
    { name: "BANCO FAMSA", value: "40131" },
    { name: "MULTIVA BANCO", value: "40132" },
    { name: "ACTINVER", value: "40133" },
    { name: "INTERCAM BANCO", value: "40136" },
    { name: "BANCOPPEL", value: "40137" },
    { name: "ABC CAPITAL", value: "40138" },
    { name: "CONSUBANCO", value: "40140" },
    { name: "VOLKSWAGEN", value: "40141" },
    { name: "CIBANCO", value: "40143" },
    { name: "BBASE", value: "40145" },
    { name: "BANKAOOL", value: "40147" },
    { name: "PAGATODO", value: "40148" },
    { name: "INMOBILIARIO", value: "40150" },
    { name: "DONDE", value: "40151" },
    { name: "BANCREA", value: "40152" },
    { name: "BANCO FINTERRA", value: "40154" },
    { name: "ICBC", value: "40155" },
    { name: "SABADELL", value: "40156" },
    { name: "MIZUHO BANK", value: "40158" },
    { name: "MONEXCB", value: "90600" },
    { name: "GBM", value: "90601" },
    { name: "MASARI", value: "90602" },
    { name: "VALUE", value: "90605" },
    { name: "ESTRUCTURADORES", value: "90606" },
    { name: "VECTOR", value: "90608" },
    { name: "MULTIVA CBOLSA", value: "90613" },
    { name: "ACCIVAL", value: "90614" },
    { name: "FINAMEX", value: "90616" },
    { name: "VALMEX", value: "90617" },
    { name: "PROFUTURO", value: "90620" },
    { name: "CB ACTINVER", value: "90621" },
    { name: "SKANDIA", value: "90623" },
    { name: "CBDEUTSCHE", value: "90626" },
    { name: "ZURICH", value: "90627" },
    { name: "ZURICHVI", value: "90628" },
    { name: "CB INTERCAM", value: "90630" },
    { name: "CI BOLSA", value: "90631" },
    { name: "FINCOMUN", value: "90634" },
    { name: "HDI SEGUROS", value: "90636" },
    { name: "ORDER", value: "90637" },
    { name: "AKALA", value: "90638" },
    { name: "CB JPMORGAN", value: "90640" },
    { name: "REFORMA", value: "90642" },
    { name: "STP", value: "90646" },
    { name: "EVERCORE", value: "90648" },
    { name: "OSKNDIA", value: "90649" },
    { name: "ASEA", value: "90652" },
    { name: "KUSPIT", value: "90653" },
    { name: "SOFIEXPRESS", value: "90655" },
    { name: "UNAGRA", value: "90656" },
    { name: "ASP INTEGRA OPC", value: "90659" },
    { name: "LIBERTAD", value: "90670" },
    { name: "HUASTECAS", value: "90671" },
    { name: "AXA", value: "90674" },
    { name: "CAJA POP MEXICA", value: "90677" },
    { name: "SURA", value: "90678" },
    { name: "FND", value: "90679" },
    { name: "CRISTOBAL COLON", value: "90680" },
    { name: "PRINCIPAL", value: "90681" },
    { name: "CAJA TELEFONIST", value: "90683" },
    { name: "TRANSFER", value: "90684" },
    { name: "FONDO (FIRA)", value: "90685" },
    { name: "INVERCAP", value: "90686" },
    { name: "INFONAVIT", value: "90687" },
    { name: "FOMPED", value: "90689" },
    { name: "CLS", value: "90901" },
    { name: "INDEVAL", value: "90902" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
