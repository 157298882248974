import { makeStyles } from "@material-ui/core/styles";

export const sucessValidationResultStyles = makeStyles({
  arrowIcon: {
    width: "22px",
    height: "31px",
    cursor: "pointer",
  },
  detailsButton: {
    color: "#4498EE",
    fontWeight: 400,
    fontSize: 12,
    paddingRight: 4,
  },
  title: {
    fontWeight: 5,
    fontStyle: "normal",
    fontSize: "20px",
    color: "#023365",
    marginTop: "20px",
  },
  rowIcon: {
    float: "left",
    width: "12px",
    margin: "0 8px 0 2px",
    height: "21px",
  },
  checkIcon: {
    fill: "#4498EE",
  },
  closeIcon: {
    fill: "#AD0C2A",
  },
  resultImage: {
    width: "300px",
    marginTop: 25,
    marginBottom: 20,
  },
  textPrimary: {
    color: "#023365",
    fontSize: 15,
    whiteSpace: "nowrap",
  },
  textSecondary: {
    color: "#6D7781",
    fontSize: 13,
  },
  buttonsContainer: {
    width: 400,
    marginTop: 20,
  },
  countRowsContainer: {
    width: 256,
  },
  boxButtonExpansive: {
    display: "flex",
    alignItems: "center",
  },
  boxRowDetail: {
    display: "flex",
    alignItems: "center",
    marginLeft: 30,
  },
  textDownloadFiles: {
    marginTop: 12,
    color: "#4498EE",
    fontWeight: 400,
    fontSize: 12,
    paddingLeft: 6,
    textDecoration: "underline",
    cursor: "pointer",
  },
  descriptionSuccess: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "15px",
    color: "#677784",
    paddingTop: "20px",
    textAlign: "center",
  },
  referenceStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "20px",
    gap: "100px",
    marginBottom: "20px",
  },
});
