import React, { PropsWithChildren } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { HistoricalData } from "../ModalBodyInfo/TimelineComponent/useModalBodyTimelineState";
import { TimeLineConstants } from "../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";

export interface IDialogTimeLineProps {
  open: boolean;
  data: HistoricalData;
  handleFinish: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute!important" as "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogActions: {
      marginRight: "10px",
      marginBottom: "5px",
    },
    padTop: {
      paddingTop: 20,
    },
    padBottom: {
      paddingBottom: 20,
    },
    primaryButton: {
      fontSize: "16px !important",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#023365 !important",
      padding: "10px 23px !important",
      lineHeight: "100% !important",
    },
    principalText: {
      fontSize: "14px !important",
      lineHeight: "140% !important",
      color: "#46525C !important",
      paddingLeft: 10,
    },
    title: {
      color: "#000000",
      paddingRight: 102.75,
      paddingTop: 20,
      paddingBottom: 10,
      width: 540,
    },
    titleContent: {
      fontSize: "16px !important",
      lineHeight: "150% !important",
      color: "#293036",
      paddingBottom: 8,
    },
  })
);

export const DialogTimeLine: React.FC<IDialogTimeLineProps> = (
  props: PropsWithChildren<IDialogTimeLineProps>
) => {
  const classes = useStyles();
  const { data, open, handleFinish } = props;

  return (
    <Dialog open={open} onClose={handleFinish}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography variant="h5">{`${TimeLineConstants.DISABLED_REQUEST} ${data.userName}`}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleFinish}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.titleContent}>
              {TimeLineConstants.DATE_REQUEST}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="span"
              className={classes.principalText}
            >
              {data.dateFormatter}
            </Typography>
            <Typography className={classes.principalText}>
              {data.hourFormatter}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padTop}>
            <Typography className={classes.titleContent}>
              {TimeLineConstants.REASON}
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.padBottom}>
            <Typography
              variant="h6"
              component="span"
              className={classes.principalText}
            >
              {data.reason}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleFinish} className={classes.primaryButton}>
          {TimeLineConstants.OK}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
