/* istanbul ignore file */

export enum LabelEnum {
  BANK = "transactionBank",
  BRAND = "transactionBrand",
  PROCESSOR = "transactionProcessor",
  CARD_TYPE = "transactionIsCreditCard",
  DEFERRED = "transactionIsDeferred",
  CARD_ISSUER = "transactionCardIssuer",
  CURRENCY = "transactionCurrency",
  COUNTRY = "transactionCountry",
  BIN = "transactionBIN",
  IP = "transactionIP",
  MASKED_CARD = "transactionMaskedCreditCard",
  AMOUNT = "transactionTotalAmount",
  CREDENTIAL = "transactionCredential",
  TRANSACTION_TYPE = "transactionType",
  SCHEDULE = "transactionCreated",
  PHONE_NUMBER = "transactionPhoneNumber",
  EMAIL = "transactionEmail",
  CARD_HOLDER_NAME = "transactionCardHolderName",
  CARD_ID = "transactionCardId",
}

export enum NameLabelEnum {
  BANK = "Banco",
  BRAND = "Marca",
  DEFERRED = "Diferido",
  PROCESSOR = "Procesador",
  COUNTRY = "País",
  AMOUNT = "Monto",
  IP = "IP",
  BIN = "Bin",
  CARD_TYPE = "Tipo de tarjeta",
  MASKED_CARD = "Tarjeta enmascarada",
  CARD_ISSUER = "Emision de la tarjeta",
  CURRENCY = "Moneda",
  CREDENTIAL = "Credencial",
  TRANSACTION_TYPE = "Tipo de transacción",
  SCHEDULE = "Horario",
  PHONE_NUMBER = "Teléfono",
  EMAIL = "Correo",
  CARD_HOLDER_NAME = "Nombre del titular de la tarjeta",
  CARD_ID = "Kushki ID",
}
