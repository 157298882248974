import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { get } from "lodash";
import { getBusinessRules } from "../../../store/actionCreators";
import { DataTableBody } from "../../../../types/data_table_body";
import { IItems } from "../../../../types/merchant_processors_rules_response";

export interface IConfigurationIndexStateProps {
  rules?: DataTableBody;
  actions: {
    handleRedirectActionRule: () => void;
  };
  isRuleLoaded: boolean | undefined;
}

export interface TableBusinessStateProps {
  merchantId: string;
}

export interface IRules {
  rule: { name: string; action: string };
}

export const RulesTableState = (
  props: TableBusinessStateProps
): IConfigurationIndexStateProps => {
  const { rules, isRuleLoaded } = useSelector((state: IAppState) => state);
  const items: DataTableBody = (get(rules, "items", []) as IItems[]).map(
    ({ rule: { name: alias, action } }) => ({
      alias,
      action,
    })
  );
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  const history = useHistory();
  useEffect(() => {
    dispatch(getBusinessRules(props.merchantId));
  }, [props.merchantId]);

  const handleRedirectActionRule = () => {
    history.push(`/rules/business/rule/${merchantId}`);
  };

  return {
    rules: items,
    actions: {
      handleRedirectActionRule,
    },
    isRuleLoaded,
  };
};
