import { Box, Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { footerStyles } from "./FooterOptions.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  CONTINUE_TEXT,
  DEFAULT_IS_DISABLE_BUTTON,
  DEFAULT_IS_EDIT_LABEL,
  DEFAULT_IS_HIDDEN_BUTTON,
  DEFAULT_IS_HIDDEN_LABEL,
  SAVE_TEXT,
  SAVED_TEXT,
} from "../../shared/constants/labels/main_footer_labels";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  secondaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  isLoading,
  label = {
    date: "Jun/4/2022",
    editDetail: DEFAULT_IS_EDIT_LABEL,
    isHidden: DEFAULT_IS_HIDDEN_LABEL,
    text: "",
  },
  isEdit,
}: IFooterOptions) => {
  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={4} md={6}>
          <Box sx={footerStyles.buttonContainer}>
            {!secondaryButton.isHidden && (
              <Button
                data-testid="idButtonTest"
                variant="outlined"
                sx={footerStyles.saveButton}
                disabled={secondaryButton.isDisabled}
                onClick={() => secondaryButton.onAction!()}
                startIcon={isLoading && <CheckIcon />}
              >
                {isLoading ? SAVED_TEXT : SAVE_TEXT}
              </Button>
            )}
            {!isEdit && (
              <Button
                data-testid="idButtonContinue"
                variant="contained"
                disabled={primaryButton.isDisabled}
                onClick={() => primaryButton.onAction!()}
                sx={footerStyles.continueButton}
                endIcon={<ArrowForwardIcon />}
              >
                {CONTINUE_TEXT}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={8} md={6}>
          {!label.isHidden && (
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>
                Modificado por: {label.text}
              </Typography>
              <Typography sx={footerStyles.label}>{label.date}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
