import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../store/hooks/storeHook";
import IconSquareCheck from "../../assets/icons/IconSquareCheck";
import IconSquareCross from "../../assets/icons/IconSquareCross";
import { IconDownload } from "@kushki/connect-ui";
import { TotalRecordsStyles as styles } from "./TotalRecords.styles";
import { defaultTo } from "lodash";
const TotalRecords: React.FC = () => {
  const {
    fileValidated: { total_error, total_processed, total_success, url },
  } = useAppSelector((state) => state.massiveUserForm);

  return (
    <Box mt="10px">
      <Typography sx={styles.text("#293036")}>
        Total de registros: {total_processed}
      </Typography>
      <Box sx={styles.textContainer}>
        <IconSquareCheck />
        <Typography sx={styles.text("#0DC298")}>
          Registros con formato correcto: {total_success}
        </Typography>
      </Box>
      {defaultTo(total_error, 0) > 0 && (
        <Box sx={styles.textContainer}>
          <IconSquareCross />
          <Typography sx={styles.text("#E24763")}>
            Registros con formato incorrecto: {total_error}
          </Typography>
        </Box>
      )}
      {defaultTo(total_error, 0) > 0 && (
        <Box mt="20px">
          <Typography sx={styles.text("#6D7781")}>
            Se generó un documento con los errores encontrados.
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<IconDownload />}
            sx={styles.btn}
          >
            <Box
              component="a"
              href={`${url}`}
              download
              sx={styles.textBtn}
              className="buttonText"
            >
              Descargar y corregir archivo
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TotalRecords;
