import { CatalogData } from "../../../../types/catalog_data";

export const secureServiceCatalog: CatalogData[] = [
  { name: "3DS", value: "3dsecure" },
  { name: "Kushki OTP", value: "KushkiOTP" },
];

export const SECURE_SERVICE_NAME: Record<string, string> = {
  ["3dsecure"]: "3DS",
  ["KushkiOTP"]: "Kushki OTP",
};
