import { routes } from "./routes";

export const deferredRoutes: { [key: string]: string } = {
  Ecuador: routes.MERCHANT_DEFERRED,
  Brazil: routes.MERCHANT_DEFERRED,
  Mexico: routes.MERCHANT_DEFERRED,
  Peru: routes.CONFIG_DEFERRED,
  CostaRica: routes.MERCHANT_DEFERRED,
  "Costa Rica": routes.MERCHANT_DEFERRED,
  "El Salvador": routes.MERCHANT_DEFERRED,
  Guatemala: routes.MERCHANT_DEFERRED,
  Honduras: routes.MERCHANT_DEFERRED,
  Nicaragua: routes.MERCHANT_DEFERRED,
  Panama: routes.MERCHANT_DEFERRED,
};
