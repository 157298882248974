import { environment } from "../../environments/environment";

export const API_ROUTES = {
  GET_SEMAPHORE_DATA: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/billing-core/v1/getSemaphore/${publicMerchantId}`,
  UPDATE_SEMAPHORE_DATA: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/semaphore`,
  GET_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  GET_BUSINESS_RULES: `${environment.kushkiUrl}/rules/v1/admin/business-type/rules`,
  GET_PAYOUT_BUSINESS_RULES: `${environment.kushkiUrl}/payouts-rules/v1/admin/rule/list`,
  UPDATE_PAYOUT_BUSINESS_RULES: (ruleID: string) =>
    `${environment.kushkiUrl}/payouts-rules/v1/admin/rule/${ruleID}`,
  UPDATE_HIERARCHY_NODE_CONFIG: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  GET_BUSINESS_LIST_CONFIGURATION: `${environment.kushkiUrl}/request-manager/v1/configuration`,
  POST_MASSIVE_CREATE: `${environment.kushkiUrl}/card-massive-rule-management/v1/massive/create`,
  POST_MASSIVE_PAYOUT_RULES: `${environment.kushkiUrl}/payouts-rules/v1/admin/rule/massive`,
  GET_DEFERRED: (merchantID: string) =>
    `${environment.kushkiUrl}/deferred/v1/admin/deferred/${merchantID}`,
  GET_DEFERRED_CONFIG: `${environment.kushkiUrl}/deferred/v1/config`,
  GET_CARD_ADMIN_MERCHANT: (merchantID: string) =>
    `${environment.kushkiUrl}/card/v1/admin/merchant/${merchantID}`,
  DELETE_PROCESSOR: (deferredID: string) =>
    `${environment.kushkiUrl}/deferred/v1/admin/merchant/${deferredID}`,
  DELETE_RULE: (ruleID: string) =>
    `${environment.kushkiUrl}/rules/v1/admin/business/rules/${ruleID}`,
  POST_UPDATE_MERCHANT: (merchantID: string) =>
    `${environment.kushkiUrl}/deferred/v1/admin/deferred/merchant/${merchantID}`,
  POST_UPDATE_MERCHANT_MASSIVE_DEFERRED: () =>
    `${environment.kushkiUrl}/deferred/v1/admin/deferred/merchant`,
  PUT_UPDATE_HIERARCHY_NODE_CONFIG: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  GET_PROCESSORS: (merchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}/processors`,
  GET_PROCESSORS_CUSTOMER: (merchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}/customer/processors`,
  GET_MERCHANT_INFO: `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
  GET_DEFAULT_PROCESSOR: (merchantId: string) =>
    `${environment.kushkiUrl}/rules/v1/admin/merchant/${merchantId}`,
  GET_DEFAULT_PAYOUT_PROCESSOR: (merchantId: string): string =>
    `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/${merchantId}`,
  DELETE_PROCESSOR_BY_PAYMENT_METHOD: (url: string) =>
    `${environment.kushkiUrl}${url}`,
  UPDATE_FAILOVER_PROCESSOR: (merchantId: string, publicProcessorId: string) =>
    `${environment.kushkiUrl}/rules/v1/admin/processor/${merchantId}/${publicProcessorId}`,
  CENTRALIZE: `${environment.kushkiUrl}/hierarchy/v1/node/centralize`,
  CENTRALIZE_PROCESSORS: {
    CASH: `${environment.kushkiUrl}/cash/v1/centralizeProcessor`,
    PAYOUTS_CASH: `${environment.kushkiUrl}/payouts/cash/v1/centralizeProcessor`,
    PAYOUTS_TRANSFER: `${environment.kushkiUrl}/payouts/transfer/v1/centralizeProcessor`,
    TRANSFER: `${environment.kushkiUrl}/transfer/v1/centralizeProcessor`,
    TRANSFER_SUBS: `${environment.kushkiUrl}/transfer-subscriptions/v1/centralizeProcessor`,
    TRX_RULE: `${environment.kushkiUrl}/rules/v1/centralizeProcessor`,
  },
  GET_PAYMENT_METHODS: `${environment.kushkiUrl}/config-manager/v1/admin/configProfile?appName=HiddenPaymentMethods&env=develop&profileName=generalConfig`,
};
