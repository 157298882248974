import { useFormContext } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { get, isEmpty } from "lodash";
import { DEFAULT_ITEM_BY_TAG } from "../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { IGenericLabelConditionSectionProps } from "../../../../../shared/infrastructure/interfaces/IGenericLabelConditionSection";
import { IUseGenericLabelConditionSectionState } from "../../../../../shared/infrastructure/interfaces/IUseGenericLabelConditionSectionState";

export const useGenericLabelConditionSectionState = (
  props: IGenericLabelConditionSectionProps
): IUseGenericLabelConditionSectionState => {
  const { register } = useFormContext();

  const [isValidFiledTextField, setIsValidFiledTextField] =
    useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [textFieldList, setTextFieldList] = useState<string[]>(
    props.defaultListTextField || []
  );

  useEffect(() => {
    const dataToRetrieve: ICategory[] = textFieldList
      .filter((data: string) => !isEmpty(data))
      .map((data) => {
        return {
          label: DEFAULT_ITEM_BY_TAG[props.tag].label,
          value: data,
          selected: true,
        };
      });

    props.setSelectedItems(dataToRetrieve);
  }, [textFieldList]);

  useEffect(() => {
    setValue("");
    setIsValidFiledTextField(false);
  }, [props.operator]);

  useEffect(() => {
    const defaultListTextField: string[] = get(
      props,
      "defaultListTextField",
      []
    );
    setTextFieldList(
      defaultListTextField.length > 0 ? defaultListTextField : []
    );
  }, [props.defaultListTextField]);

  const handleAddTextField = (): void => {
    setValue("");
    setTextFieldList((array) => [...array, value]);
  };

  const handleEditTextField = (value: string, index: number): void => {
    const newFieldList = [...textFieldList];

    newFieldList[index] = value;
    setTextFieldList(newFieldList);
  };

  const handleOnChangeTextField = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const fieldValue: string = get(event, "target.value", "");
    setIsValidFiledTextField(false);
    if (DEFAULT_ITEM_BY_TAG[props.tag].pattern.test(fieldValue)) {
      setIsValidFiledTextField(true);
    }

    if (DEFAULT_ITEM_BY_TAG[props.tag].patternInput.test(fieldValue)) {
      setValue(fieldValue);
      return;
    }
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setValue(get(event, "target.value", ""));
  };

  return {
    value,
    register,
    handleOnBlur,
    textFieldList,
    setTextFieldList,
    handleAddTextField,
    handleEditTextField,
    isValidFiledTextField,
    handleOnChangeTextField,
  };
};
