import {
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { TEXTS } from "../../../shared/infrastructure/constants/ModalTexts";
import { OPTIONS } from "../../../shared/infrastructure/constants/OptionsEnum";
import { StatusEnum } from "../../../shared/infrastructure/constants/StatusEnum";
import { CheckCircle, XCircle } from "react-feather";
import React from "react";
import { IValidUrl } from "../../../shared/infrastructure/interfaces/IValidUrl";
import { makeStyles } from "@material-ui/core/styles";
import { useShowVerifiedUrls } from "./state/useShowVerifiedUrls";

export interface IverifiedUrls {
  verifiedUrls: IValidUrl;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 300,
    },
    listSection: {
      backgroundColor: "inherit",
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0,
    },
    error: {
      padding: 9,
      background: theme.palette.error.light,
      minWidth: "30px",
      borderRadius: "4px",
    },
    success: {
      padding: 9,
      background: theme.palette.success.light,
      minWidth: "30px",
      borderRadius: "4px",
    },
    itemText: {
      paddingLeft: "16px",
    },
  })
);

export const ShowVerifiedUrls: React.FC<IverifiedUrls> = (
  props: IverifiedUrls
) => {
  const classes = useStyles();
  const theme = useTheme();
  const { showVerifiedUrls } = useShowVerifiedUrls(props);

  return (
    <Grid container spacing={1} justify="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {showVerifiedUrls ? TEXTS.invalidUrls : TEXTS.validUrls}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List className={classes.root} subheader={<li />}>
          {Object.keys(props.verifiedUrls).map((key) => (
            <li key={`section-${key}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader disableGutters>{OPTIONS[key]}</ListSubheader>
                {Array.isArray(props.verifiedUrls[key]) &&
                  props.verifiedUrls[key].map(
                    (url: { status: string; url: string }, index: number) => (
                      <ListItem key={`${url}-${index}`} disableGutters>
                        <ListItemIcon
                          className={
                            url.status === StatusEnum.OK
                              ? classes.success
                              : classes.error
                          }
                        >
                          {url.status === StatusEnum.OK ? (
                            <CheckCircle
                              size={15}
                              color={theme.palette.success.dark}
                            />
                          ) : (
                            <XCircle
                              size={15}
                              color={theme.palette.error.dark}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.itemText}
                          secondary={url.url}
                          secondaryTypographyProps={{
                            style: { wordWrap: "break-word" },
                          }}
                        />
                      </ListItem>
                    )
                  )}
              </ul>
            </li>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
