import { useEffect, useState } from "react";
import { isNationCountry } from "../../../shared/utils/bankAccountUtils";
import {
  IUseAddBankAccountSameCountryFlow,
  IUseAddBankAccountSameCountryFlowProps,
} from "./useAddBankAccountSameCountryFlow.interfaces";
import { get, includes, isNil } from "lodash";
import { RoutingNumberCountriesEnum } from "../../../shared/enums/RoutingNumberCountriesEnum";
import { BankAccountFields } from "../../../shared/enums/dispersionsEnum";
import { CitibankAllowedCurrencyEnum } from "../../../shared/enums/CitibankAllowedCurrencyEnum";
import { accountRules } from "../../../shared/constants/form_account_labels";

export const useAddBankAccountSameCountryFlow = ({
  banksForm,
  country,
  changeIsInternationalTransfer,
  isInternationalTransfer,
  isDispersionTab,
}: IUseAddBankAccountSameCountryFlowProps): IUseAddBankAccountSameCountryFlow => {
  const [isCountryInKushkiNation, setIsCountryInKushkiNation] = useState(false);
  const [isCountryWithRoutingNumber, setCountryWithRoutingNumber] =
    useState(false);
  const [isInvalidCurrency, setIsInvalidCurrency] = useState(
    !includes(
      CitibankAllowedCurrencyEnum,
      banksForm.getValues!(BankAccountFields.CURRENCY)
    )
  );

  const [dynamicRules, setDynamicRules] = useState({
    beneficiaryName: accountRules.beneficiaryName,
    financialInstitutionName: accountRules.financialInstitutionName,
  });

  useEffect(() => {
    if (isInternationalTransfer) {
      setDynamicRules({
        ...dynamicRules,
        beneficiaryName: accountRules.beneficiaryNameInternationalTransfer,
        financialInstitutionName:
          accountRules.financialInstitutionNameInternationalTransfer,
      });
    } else {
      setDynamicRules({
        ...dynamicRules,
        beneficiaryName: accountRules.beneficiaryName,
        financialInstitutionName: accountRules.financialInstitutionName,
      });
    }
  }, [isInternationalTransfer]);

  const validateCurrency = (
    currency: string,
    changeCurrency?: boolean
  ): void => {
    setIsInvalidCurrency(!includes(CitibankAllowedCurrencyEnum, currency));
    banksForm.setValue!(BankAccountFields.CURRENCY, currency as never);
    if (isDispersionTab)
      changeCurrency && changeIsInternationalTransfer!(false);
  };

  const onChangeCurrency = (selectEvent: object): void => {
    const currentCurrency: string = get(selectEvent, "target.value", "");

    validateCurrency(currentCurrency, true);
  };

  const countryHasProperty = (
    country: string,
    propertyEnum: object
  ): boolean => {
    for (const item of Object.values(propertyEnum)) {
      if (item.toUpperCase().trim() === country.toUpperCase().trim()) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!isNil(banksForm.clearErrors)) {
      banksForm.clearErrors();
      banksForm.resetField!(BankAccountFields.SWIFT_CODE);
    }
    setIsCountryInKushkiNation(isNationCountry(country));
    setCountryWithRoutingNumber(
      countryHasProperty(country, RoutingNumberCountriesEnum)
    );
  }, [country]);

  return {
    dynamicRules,
    isCountryInKushkiNation,
    isCountryWithRoutingNumber,
    isInvalidCurrency,
    onChangeCurrency,
  };
};
