import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const skeletonEditFormStyles = createNamedStyles({
  skeleton: {
    width: "100%",
    height: "60px",
    borderRadius: "8px !important",
  },
  container: {
    marginBottom: "32px",
  },
  subtitle: {
    marginBottom: "22px",
  },
  alarmInfoInputs: {
    display: "flex",
    gap: "30px",
  },
  divider: {
    border: "none",
    margin: "32px 0px 22px 0px",
  },
  variablesWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 50%)",
    columnGap: "30px",
    overflow: "hidden",
    alignItems: "center",
  },
});
