import React from "react";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { mainContainerStyles as styles } from "../../../containers/MainContainer/MainContainer.styles";
import { LegalContainerSkeletonStyles } from "./LegalContainerSkeleton.styles";
import { SubHeaderLegalSkeleton } from "../SubHeaderLegalSkeleton/SubHeaderLegalSkeleton";

export const LegalContainerSkeleton: React.FC = () => {
  return (
    <Card sx={styles.cardContent} data-testid="legal-skeleton">
      <CardContent>
        <Box sx={styles.boxContent2}>
          <SubHeaderLegalSkeleton />

          <Box sx={LegalContainerSkeletonStyles.contentBox}>
            <Skeleton sx={LegalContainerSkeletonStyles.persons} />
          </Box>

          <Box sx={LegalContainerSkeletonStyles.contentLegend}>
            <Typography sx={LegalContainerSkeletonStyles.subTitle4}>
              <Skeleton />
            </Typography>
          </Box>

          <Box sx={LegalContainerSkeletonStyles.contentBall}>
            <Skeleton sx={LegalContainerSkeletonStyles.balls} />
            <Skeleton sx={LegalContainerSkeletonStyles.balls} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
