import React from "react";
import {
  createStyles,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useWalletTableState } from "../WalletTable/state/useWalletTableState";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "12px",
    },
  })
);

const WalletPagination: React.FC = () => {
  const classes = useStyles();
  const { size, page, total, handleChangePageSize, handleChangePage } =
    useWalletTableState();

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2} direction={"row"} alignItems={"center"}>
            <Grid item>
              <Typography color={"textSecondary"} variant={"body2"}>
                Transacciones por página
              </Typography>
            </Grid>
            <Grid item>
              <Select
                id={"select"}
                disableUnderline
                value={size}
                onChange={(event) =>
                  handleChangePageSize(event.target.value as number)
                }
              >
                <MenuItem value={10}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    10
                  </Typography>
                </MenuItem>
                <MenuItem value={15}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    15
                  </Typography>
                </MenuItem>
                <MenuItem value={20}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    20
                  </Typography>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Typography color={"textSecondary"} variant={"body2"}>
                {`${(page - 1) * size + 1}-${
                  (page - 1) * size + size
                } de ${total}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          container
          justify="flex-end"
          alignItems="flex-start"
          direction="row"
        >
          <Pagination
            id={"pagination"}
            count={Math.ceil(Number(total / size))}
            color={"primary"}
            onChange={(_event, value) => handleChangePage(value as number)}
            page={page}
            size={"small"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default WalletPagination;
