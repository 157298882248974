/**
 * Catalog Panama
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogGt: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "DPI", value: "0" },
    { name: "NIT", value: "1" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Quetzales", value: CurrencyEnum.GTQ },
    { name: "Dólares", value: CurrencyEnum.USD },
  ],
  [CatalogsEnum.Banks]: [
    { name: "AGROMERCANTIL", value: "3001" },
    { name: "BANCO AZTECA", value: "3002" },
    { name: "BANCO DE ANTIGUA", value: "3003" },
    { name: "BANCO BAC", value: "3004" },
    { name: "BANCO CITI", value: "3005" },
    { name: "BANCO CREDITO HIPOTECARIO", value: "3006" },
    { name: "BANCO BANTRAB", value: "3007" },
    { name: "BANCO G&T CONTINENTAL", value: "3008" },
    { name: "BANCO INDUSTRIAL", value: "3009" },
    { name: "BANCO INMOBILIARIO", value: "3010" },
    { name: "BANCO INTERNACIONAL", value: "3011" },
    { name: "BANCO FICOHSA", value: "3012" },
    { name: "BANCO REFORMADOR", value: "3013" },
    { name: "BANCO VIVIBANCO", value: "3014" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "Guatemala", value: "01" },
    { name: "El Progreso", value: "02" },
    { name: "Sacatepéquez", value: "03" },
    { name: "Chimaltenango", value: "04" },
    { name: "Escuintla", value: "05" },
    { name: "Santa Rosa", value: "06" },
    { name: "Sololá", value: "07" },
    { name: "Totonicapán", value: "08" },
    { name: "Quetzaltenango", value: "09" },
    { name: "Suchitepéquez", value: "10" },
    { name: "Retalhuleu", value: "11" },
    { name: "San Marcos", value: "12" },
    { name: "Huehuetenango", value: "13" },
    { name: "Quiché", value: "14" },
    { name: "Baja Verapaz", value: "15" },
    { name: "Alta Verapaz", value: "16" },
    { name: "Petén", value: "17" },
    { name: "Izabal", value: "18" },
    { name: "Zacapa", value: "19" },
    { name: "Chiquimula", value: "20" },
    { name: "Jalapa", value: "21" },
    { name: "Jutiapa", value: "22" },
  ],
  [CatalogsEnum.Cities]: [
    { name: "Guatemala", parent: "01", value: "0101" },
    { name: "Santa Catarina Pinula", parent: "01", value: "0102" },
    { name: "San José Pinula", parent: "01", value: "0103" },
    { name: "San José del Golfo", parent: "01", value: "0104" },
    { name: "Palencia", parent: "01", value: "0105" },
    { name: "Chinautla", parent: "01", value: "0106" },
    { name: "San Pedro Ayampuc", parent: "01", value: "0107" },
    { name: "Mixco", parent: "01", value: "0108" },
    { name: "San Pedro Sacatepéquez", parent: "01", value: "0109" },
    { name: "San Juan Sacatepéquez", parent: "01", value: "0110" },
    { name: "San Raymundo", parent: "01", value: "0111" },
    { name: "Chuarrancho", parent: "01", value: "0112" },
    { name: "Fraijanes", parent: "01", value: "0113" },
    { name: "Amatitlán", parent: "01", value: "0114" },
    { name: "Villa Nueva", parent: "01", value: "0115" },
    { name: "Villa Canales", parent: "01", value: "0116" },
    { name: "San Miguel Petapa", parent: "01", value: "0117" },
    { name: "Guastatoya", parent: "02", value: "0201" },
    { name: "Morazán", parent: "02", value: "0202" },
    { name: "San Agustín Acasaguastlán", parent: "02", value: "0203" },
    { name: "San Cristóbal Acasaguastlán", parent: "02", value: "0204" },
    { name: "El Jícaro", parent: "02", value: "0205" },
    { name: "Sansare", parent: "02", value: "0206" },
    { name: "Sanarate", parent: "02", value: "0207" },
    { name: "San Antonio La Paz", parent: "02", value: "0208" },
    { name: "Antigua Guatemala", parent: "03", value: "0301" },
    { name: "Jocotenango", parent: "03", value: "0302" },
    { name: "Pastores", parent: "03", value: "0303" },
    { name: "Sumpango", parent: "03", value: "0304" },
    { name: "Santo Domingo Xenacoj", parent: "03", value: "0305" },
    { name: "Santiago Sacatepéquez", parent: "03", value: "0306" },
    { name: "San Bartolomé Milpas Altas", parent: "03", value: "0307" },
    { name: "San Lucas Sacatepéquez", parent: "03", value: "0308" },
    { name: "Santa Lucía Milpas Altas", parent: "03", value: "0309" },
    { name: "Magdalena Milpas Altas", parent: "03", value: "0310" },
    { name: "Santa María de Jesús", parent: "03", value: "0311" },
    { name: "Ciudad Vieja", parent: "03", value: "0312" },
    { name: "San Miguel Dueñas", parent: "03", value: "0313" },
    { name: "Alotenango", parent: "03", value: "0314" },
    { name: "San Antonio Aguas Calientes", parent: "03", value: "0315" },
    { name: "Santa Catarina Barahona", parent: "03", value: "0316" },
    { name: "Chimaltenango", parent: "04", value: "0401" },
    { name: "San José Poaquil", parent: "04", value: "0402" },
    { name: "San Martín Jilotepeque", parent: "04", value: "0403" },
    { name: "San Juan Comalapa", parent: "04", value: "0404" },
    { name: "Santa Apolonia", parent: "04", value: "0405" },
    { name: "Tecpán Guatemala", parent: "04", value: "0406" },
    { name: "Patzún", parent: "04", value: "0407" },
    { name: "San Miguel Pochuta", parent: "04", value: "0408" },
    { name: "Patzicía", parent: "04", value: "0409" },
    { name: "Santa Cruz Balanyá", parent: "04", value: "0410" },
    { name: "Acatenango", parent: "04", value: "0411" },
    { name: "San Pedro Yepocapa", parent: "04", value: "0412" },
    { name: "Parramos", parent: "04", value: "0413" },
    { name: "San Andrés Itzapa", parent: "04", value: "0413" },
    { name: "Zaragoza", parent: "04", value: "0414" },
    { name: "El Tejar", parent: "04", value: "0415" },
    { name: "Escuintla", parent: "05", value: "0501" },
    { name: "Santa Lucía Cotzumalguapa", parent: "05", value: "0502" },
    { name: "La Democracia", parent: "05", value: "0503" },
    { name: "Siquinalá", parent: "05", value: "0504" },
    { name: "Masagua", parent: "05", value: "0505" },
    { name: "Tiquistate", parent: "05", value: "0506" },
    { name: "La Gomera", parent: "05", value: "0507" },
    { name: "Guanagazapa", parent: "05", value: "0508" },
    { name: "San José", parent: "05", value: "0509" },
    { name: "Iztapa", parent: "05", value: "0510" },
    { name: "Palín", parent: "05", value: "0511" },
    { name: "San Vicente Pacaya", parent: "05", value: "0512" },
    { name: "Nueva Concepción", parent: "05", value: "0513" },
    { name: "Cuilapa", parent: "06", value: "0601" },
    { name: "Barberena", parent: "06", value: "0602" },
    { name: "Santa Rosa de Lima", parent: "06", value: "0603" },
    { name: "Casillas", parent: "06", value: "0604" },
    { name: "San Rafael las Flores", parent: "06", value: "0605" },
    { name: "Oratorio", parent: "06", value: "0606" },
    { name: "San Juan Tecuaco", parent: "06", value: "0607" },
    { name: "Chiquimulilla", parent: "06", value: "0608" },
    { name: "Taxisco", parent: "06", value: "0609" },
    { name: "Santa María Ixhuatán", parent: "06", value: "0610" },
    { name: "Guazacapán", parent: "06", value: "0611" },
    { name: "Santa Cruz Naranjo", parent: "06", value: "0612" },
    { name: "Pueblo Nuevo Viñas", parent: "06", value: "0613" },
    { name: "Nueva Santa Rosa", parent: "06", value: "0614" },
    { name: "Sololá", parent: "07", value: "0701" },
    { name: "San José Chacayá", parent: "07", value: "0702" },
    { name: "Santa María Visitación", parent: "07", value: "0703" },
    { name: "Santa Lucía Utatlán", parent: "07", value: "0704" },
    { name: "Nahualá", parent: "07", value: "0705" },
    { name: "Santa Catarina Ixtahuacan", parent: "07", value: "0706" },
    { name: "Santa Clara La Laguna", parent: "07", value: "0707" },
    { name: "Concepción", parent: "07", value: "0708" },
    { name: "San Andrés Semetabaj", parent: "07", value: "0709" },
    { name: "Panajachel", parent: "07", value: "0710" },
    { name: "Santa Caratina Palopó", parent: "07", value: "0711" },
    { name: "San Antonio Palopó", parent: "07", value: "0712" },
    { name: "San Lucas Tolimán", parent: "07", value: "0713" },
    { name: "Santa Cruz La Laguna", parent: "07", value: "0714" },
    { name: "San Pablo La Laguna", parent: "07", value: "0715" },
    { name: "San Marcos La Laguna", parent: "07", value: "0716" },
    { name: "San Juan La Laguna", parent: "07", value: "0717" },
    { name: "San Pedro La Laguna", parent: "07", value: "0718" },
    { name: "Santiago Atitlán", parent: "07", value: "0719" },
    { name: "Totonicapán", parent: "08", value: "0801" },
    { name: "San Cristóbal Totonicapán", parent: "08", value: "0802" },
    { name: "San Francisco El Alto", parent: "08", value: "0803" },
    { name: "San Andrés Xecul", parent: "08", value: "0804" },
    { name: "Momostenango", parent: "08", value: "0805" },
    { name: "Santa María Chiquimula", parent: "08", value: "0806" },
    { name: "Santa Lucía La Reforma", parent: "08", value: "0807" },
    { name: "San Bartolo", parent: "08", value: "0808" },
    { name: "Quetzaltenango", parent: "09", value: "0901" },
    { name: "Salcajá", parent: "09", value: "0902" },
    { name: "Olintepeque", parent: "09", value: "0903" },
    { name: "San Carlos Sija", parent: "09", value: "0904" },
    { name: "Sibilia", parent: "09", value: "0905" },
    { name: "Cabricán", parent: "09", value: "0906" },
    { name: "Cajolá", parent: "09", value: "0907" },
    { name: "San Miguel Sigüilá", parent: "09", value: "0908" },
    { name: "San Juan Ostuncalco", parent: "09", value: "0909" },
    { name: "San Mateo", parent: "09", value: "0910" },
    { name: "Concepción Chiquirichapa", parent: "09", value: "0911" },
    { name: "San Martín Sacatepéquez", parent: "09", value: "0912" },
    { name: "Almolonga", parent: "09", value: "0913" },
    { name: "Cantel", parent: "09", value: "0914" },
    { name: "Huitán", parent: "09", value: "0915" },
    { name: "Zunil", parent: "09", value: "0916" },
    { name: "Colomba", parent: "09", value: "0917" },
    { name: "San Francisco La Unión", parent: "09", value: "0918" },
    { name: "El Palmar", parent: "09", value: "0919" },
    { name: "Coatepeque", parent: "09", value: "0920" },
    { name: "Génova", parent: "09", value: "0921" },
    { name: "Flores Costa Cuca", parent: "09", value: "0922" },
    { name: "La Esperanza", parent: "09", value: "0923" },
    { name: "Palestina de los Altos", parent: "09", value: "0924" },
    { name: "Mazatenango", parent: "10", value: "1001" },
    { name: "Cuyotenango", parent: "10", value: "1002" },
    { name: "San Francisco Zapotitlán", parent: "10", value: "1003" },
    { name: "San Bernardino", parent: "10", value: "1004" },
    { name: "San José El Idolo", parent: "10", value: "1005" },
    { name: "Santo Domingo Suchitepéquez", parent: "10", value: "1006" },
    { name: "San Lorenzo", parent: "10", value: "1007" },
    { name: "Samayac", parent: "10", value: "1008" },
    { name: "San Pablo Jocopilas", parent: "10", value: "1009" },
    { name: "San Antonio Suchitepéquez", parent: "10", value: "1010" },
    { name: "San Miguel Panán", parent: "10", value: "1011" },
    { name: "San Gabriel", parent: "10", value: "1012" },
    { name: "Chicacao", parent: "10", value: "1013" },
    { name: "Patulul", parent: "10", value: "1014" },
    { name: "Santa Bárbara", parent: "10", value: "1015" },
    { name: "San Juan Bautista", parent: "10", value: "1016" },
    { name: "Santo Tomás la Unión", parent: "10", value: "1017" },
    { name: "Zunilito", parent: "10", value: "1018" },
    { name: "Pueblo Nuevo", parent: "10", value: "1019" },
    { name: "Río Bravo", parent: "10", value: "1020" },
    { name: "Retalhuleu", parent: "11", value: "1101" },
    { name: "San Sebastián", parent: "11", value: "1102" },
    { name: "Santa Cruz Muluá", parent: "11", value: "1103" },
    { name: "San Martín Zapotitlán", parent: "11", value: "1104" },
    { name: "San Felipe", parent: "11", value: "1105" },
    { name: "San Andrés Villa Seca", parent: "11", value: "1106" },
    { name: "Champerico", parent: "11", value: "1107" },
    { name: "Nuevo San Carlos", parent: "11", value: "1108" },
    { name: "El Asintal", parent: "11", value: "1109" },
    { name: "San Marcos", parent: "12", value: "1201" },
    { name: "San Pedro Sacatepéquez", parent: "12", value: "1202" },
    { name: "San Antonio Sacatepéquez", parent: "12", value: "1203" },
    { name: "Comitancillo", parent: "12", value: "1204" },
    { name: "San Miguel Ixtahuacán", parent: "12", value: "1205" },
    { name: "Concepción Tutuapa", parent: "12", value: "1206" },
    { name: "Tacaná", parent: "12", value: "1207" },
    { name: "Sibinal", parent: "12", value: "1208" },
    { name: "Tajumulco", parent: "12", value: "1209" },
    { name: "Tejutla", parent: "12", value: "1210" },
    { name: "San Rafael Pie de la Cuesta", parent: "12", value: "1211" },
    { name: "Nuevo Progreso", parent: "12", value: "1212" },
    { name: "El Tumbador", parent: "12", value: "1213" },
    { name: "El Rodeo", parent: "12", value: "1214" },
    { name: "Malacatán", parent: "12", value: "1215" },
    { name: "Catarina", parent: "12", value: "1216" },
    { name: "Ayutla", parent: "12", value: "1217" },
    { name: "Ocós", parent: "12", value: "1218" },
    { name: "San Pablo", parent: "12", value: "1219" },
    { name: "El Quetzal", parent: "12", value: "1220" },
    { name: "La Reforma", parent: "12", value: "1221" },
    { name: "Pajapita", parent: "12", value: "1222" },
    { name: "Ixchigán", parent: "12", value: "1223" },
    { name: "San José Ojetenam", parent: "12", value: "1224" },
    { name: "San Cristóbal Cucho", parent: "12", value: "1225" },
    { name: "Sipacapa", parent: "12", value: "1226" },
    { name: "Esquipulas Palo Gordo", parent: "12", value: "1227" },
    { name: "Río Blanco", parent: "12", value: "1228" },
    { name: "San Lorenzo", parent: "12", value: "1229" },
    { name: "Huehuetenango", parent: "13", value: "1301" },
    { name: "Chiantla", parent: "13", value: "1302" },
    { name: "Malacatancito", parent: "13", value: "1303" },
    { name: "Cuilco", parent: "13", value: "1304" },
    { name: "Nentón", parent: "13", value: "1305" },
    { name: "San Pedro Necta", parent: "13", value: "1306" },
    { name: "Jacaltenango", parent: "13", value: "1307" },
    { name: "San Pedro Soloma", parent: "13", value: "1308" },
    { name: "San Ildefonso Ixtahuacán", parent: "13", value: "1309" },
    { name: "Santa Bárbara", parent: "13", value: "1310" },
    { name: "La Libertad", parent: "13", value: "1311" },
    { name: "La Democracia", parent: "13", value: "1312" },
    { name: "San Miguel Acatán", parent: "13", value: "1313" },
    { name: "San Rafael la Independencia", parent: "13", value: "1314" },
    { name: "Todos Santos Cuchumatán", parent: "13", value: "1315" },
    { name: "San Juan Atitán", parent: "13", value: "1316" },
    { name: "Santa Eulalia", parent: "13", value: "1317" },
    { name: "San Mateo Ixtatán", parent: "13", value: "1318" },
    { name: "Colotenango", parent: "13", value: "1319" },
    { name: "Santiago Chimaltenango", parent: "13", value: "1320" },
    { name: "San Sebastián Huehuetenango", parent: "13", value: "1320" },
    { name: "Tectitán", parent: "13", value: "1321" },
    { name: "Concepción Huista", parent: "13", value: "1322" },
    { name: "San Juan Ixcoy", parent: "13", value: "1323" },
    { name: "San Antonio Huista", parent: "13", value: "1324" },
    { name: "San Sebastián Coatán", parent: "13", value: "1325" },
    { name: "Santa Cruz Barillas", parent: "13", value: "1326" },
    { name: "Aguacatán", parent: "13", value: "1327" },
    { name: "San Rafael Petzal", parent: "13", value: "1328" },
    { name: "San Gaspar Ixchil", parent: "13", value: "1329" },
    { name: "Unión Cantil", parent: "13", value: "1331" },
    { name: "Santa Ana Huista", parent: "13", value: "1331" },
    { name: "Santa Cruz del Quiché", parent: "14", value: "1401" },
    { name: "Chiché", parent: "14", value: "1402" },
    { name: "Chinique", parent: "14", value: "1403" },
    { name: "Zacualpa", parent: "14", value: "1404" },
    { name: "Chajul", parent: "14", value: "1405" },
    { name: "Chichicastenango", parent: "14", value: "1406" },
    { name: "Patzité", parent: "14", value: "1407" },
    { name: "San Antonio Ilotenango", parent: "14", value: "1408" },
    { name: "San Pedro Jocopilas", parent: "14", value: "1409" },
    { name: "Cunén", parent: "14", value: "1410" },
    { name: "San Juan Cotzal", parent: "14", value: "1411" },
    { name: "Joyabaj", parent: "14", value: "1412" },
    { name: "Nebaj", parent: "14", value: "1413" },
    { name: "San Andrés Sajcabajá", parent: "14", value: "1414" },
    { name: "Uspantán", parent: "14", value: "1415" },
    { name: "Sacapulas", parent: "14", value: "1416" },
    { name: "San Bartolomé Jocotenango", parent: "14", value: "1417" },
    { name: "Canilla", parent: "14", value: "1418" },
    { name: "Chicamán", parent: "14", value: "1419" },
    { name: "Ixcán", parent: "14", value: "1420" },
    { name: "Pachalúm", parent: "14", value: "1421" },
    { name: "Salamá", parent: "15", value: "1501" },
    { name: "San Miguel Chicaj", parent: "15", value: "1502" },
    { name: "Rabinal", parent: "15", value: "1503" },
    { name: "Cubulco", parent: "15", value: "1504" },
    { name: "Granados", parent: "15", value: "1505" },
    { name: "Santa Cruz el Chol", parent: "15", value: "1506" },
    { name: "San Jerónimo", parent: "15", value: "1507" },
    { name: "Purulhá", parent: "15", value: "1508" },
    { name: "Cobán", parent: "16", value: "1601" },
    { name: "Santa Cruz Verapaz", parent: "16", value: "1602" },
    { name: "San Cristóbal Verapaz", parent: "16", value: "1603" },
    { name: "Tactic", parent: "16", value: "1604" },
    { name: "Tamahú", parent: "16", value: "1605" },
    { name: "Tucurú", parent: "16", value: "1606" },
    { name: "Panzós", parent: "16", value: "1607" },
    { name: "Senahú", parent: "16", value: "1608" },
    { name: "San Pedro Carchá", parent: "16", value: "1609" },
    { name: "San Juan Chamelco", parent: "16", value: "1610" },
    { name: "Lanquín", parent: "16", value: "1611" },
    { name: "Santa María Cahabón", parent: "16", value: "1612" },
    { name: "Chisec", parent: "16", value: "1613" },
    { name: "Chahal", parent: "16", value: "1614" },
    { name: "Fray Bartolomé de las Casas", parent: "16", value: "1615" },
    { name: "La Tinta", parent: "16", value: "1616" },
    { name: "Raxruhá", parent: "16", value: "1617" },
    { name: "Flores", parent: "17", value: "1701" },
    { name: "San José", parent: "17", value: "1702" },
    { name: "San Benito", parent: "17", value: "1703" },
    { name: "San Andrés", parent: "17", value: "1704" },
    { name: "La Libertad", parent: "17", value: "1705" },
    { name: "San Francisco", parent: "17", value: "1706" },
    { name: "Santa Ana", parent: "17", value: "1707" },
    { name: "Dolores", parent: "17", value: "1708" },
    { name: "San Luis", parent: "17", value: "1709" },
    { name: "Sayaxche", parent: "17", value: "1710" },
    { name: "Melchor de Mencos", parent: "17", value: "1711" },
    { name: "Poptun", parent: "17", value: "1712" },
    { name: "Puerto Barrios", parent: "18", value: "1801" },
    { name: "Livingston", parent: "18", value: "1802" },
    { name: "El Estor", parent: "18", value: "1803" },
    { name: "Morales", parent: "18", value: "1804" },
    { name: "Los Amates", parent: "18", value: "1805" },
    { name: "Zacapa", parent: "19", value: "1901" },
    { name: "Estanzuela", parent: "19", value: "1902" },
    { name: "Río Hondo", parent: "19", value: "1903" },
    { name: "Gualán", parent: "19", value: "1904" },
    { name: "Teculután", parent: "19", value: "1905" },
    { name: "Usumatlán", parent: "19", value: "1906" },
    { name: "Cabañas", parent: "19", value: "1907" },
    { name: "San Diego", parent: "19", value: "1908" },
    { name: "La Unión", parent: "19", value: "1909" },
    { name: "Huité", parent: "19", value: "1910" },
    { name: "Chiquimula", parent: "20", value: "2001" },
    { name: "San José La Arada", parent: "20", value: "2002" },
    { name: "San Juan La Ermita", parent: "20", value: "2003" },
    { name: "Jocotán", parent: "20", value: "2004" },
    { name: "Camotán", parent: "20", value: "2005" },
    { name: "Olopa", parent: "20", value: "2006" },
    { name: "Esquipulas", parent: "20", value: "2007" },
    { name: "Concepción Las Minas", parent: "20", value: "2008" },
    { name: "Quezaltepeque", parent: "20", value: "2009" },
    { name: "San Jacinto", parent: "20", value: "2010" },
    { name: "Ipala", parent: "20", value: "2011" },
    { name: "Jalapa", parent: "21", value: "2101" },
    { name: "San Pedro Pinula", parent: "21", value: "2102" },
    { name: "San Luis Jilotepeque", parent: "21", value: "2103" },
    { name: "San Manuel Chaparrón", parent: "21", value: "2104" },
    { name: "San Carlos Alzatate", parent: "21", value: "2105" },
    { name: "Monjas", parent: "21", value: "2106" },
    { name: "Mataquescuintla", parent: "21", value: "2107" },
    { name: "Jutiapa", parent: "22", value: "2201" },
    { name: "El Progreso", parent: "22", value: "2202" },
    { name: "Santa Catarina Mita", parent: "22", value: "2203" },
    { name: "Agua Blanca", parent: "22", value: "2204" },
    { name: "Asunción Mita", parent: "22", value: "2205" },
    { name: "Yupiltepeque", parent: "22", value: "2206" },
    { name: "Atescatempa", parent: "22", value: "2207" },
    { name: "Jérez", parent: "22", value: "2208" },
    { name: "El Adelanto", parent: "22", value: "2209" },
    { name: "Zapotitlán", parent: "22", value: "2210" },
    { name: "Comapa", parent: "22", value: "2211" },
    { name: "Jalpatagua", parent: "22", value: "2212" },
    { name: "Conguaco", parent: "22", value: "2213" },
    { name: "Moyuta", parent: "22", value: "2214" },
    { name: "Pasaco", parent: "22", value: "2215" },
    { name: "San José Acatempa", parent: "22", value: "2216" },
    { name: "Quesada", parent: "22", value: "2217" },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Física", value: "01" },
    { name: "Moral", value: "02" },
  ],
};
