import { ICatalogFormDialogState } from "../CatalogFormDialog.interfaces";
import { useEffect, useState } from "react";
import {
  setCatalogRequest,
  setIsOpenCatalogConfirmPopup,
  setIsOpenCatalogFormPopup,
  updateCatalogItem,
} from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatalogPropertyById,
  validateCodeExpression,
  verifyExistsItemCatalog,
} from "../../../utils/functions/Catalog";
import { IAppState } from "../../../store/reducer";
import { isEmpty } from "lodash";
import { CatalogRequest } from "../../../../types/catalog_request";
import { ActionEnum } from "../../../shared/infrastructure/Enums/ActionEnum";
import { get, defaultTo } from "lodash";
import { CatalogsNameEnum } from "../../../shared/infrastructure/Enums/CatalogsNameEnum";
import { ErrorsEnum } from "../../../shared/infrastructure/Enums/ErrorsEnum";
import { CatalogPropertyEnum } from "../../../shared/infrastructure/Enums/CatalogEnum";

export const useCatalogFormDialogState = (): ICatalogFormDialogState => {
  const { catalogsList, catalogRequest } = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>(
    defaultTo(get(catalogRequest, CatalogPropertyEnum.CODE), "")
  );
  const action: string = defaultTo(
    get(catalogRequest, CatalogPropertyEnum.ACTION),
    ""
  );
  const [description, setDescription] = useState<string>(
    defaultTo(get(catalogRequest, CatalogPropertyEnum.VALUE), "")
  );
  const [optionSelected, setOptionSelected] = useState<string>(
    getCatalogPropertyById(
      catalogsList,
      CatalogPropertyEnum.NAME,
      "",
      defaultTo(get(catalogRequest, CatalogPropertyEnum.ID), "")
    )
  );
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [messageCodeError, setMessageCodeError] = useState<string>("");
  const [errorInputCode, setErrorInputCode] = useState<boolean>(false);
  const [errorInputDescription, setErrorInputDescription] = useState<boolean>(
    false
  );

  const isErrorForm = (): boolean =>
    !validateCodeExpression(optionSelected as CatalogsNameEnum, code) ||
    isEmpty(code) ||
    isEmpty(description) ||
    isEmpty(optionSelected);

  const handleCatalogCode = (value: string): void => {
    setCode(value);
    handleFormCompleted();
  };

  const handleCatalogDescription = (value: string): void => {
    setDescription(value);
    handleFormCompleted();
  };

  const handleOptionSelected = (value: string): void => {
    setOptionSelected(value);
    handleFormCompleted();
  };

  const handleFormCompleted = (): void => {
    if (isErrorForm()) {
      setDisableButton(true);
    } else setDisableButton(false);
  };

  const handleCloseDialog = (): void => {
    dispatch(
      setCatalogRequest({
        action: "",
        code: "",
        id: "",
        value: "",
      })
    );
    dispatch(setIsOpenCatalogFormPopup(false));
  };

  const handleErrorDescription = (): void => {
    if (description.length === 0) setErrorInputDescription(true);
    else setErrorInputDescription(false);
    handleFormCompleted();
  };

  const handleErrorCode = (): void => {
    if (code.length === 0) {
      setMessageCodeError(ErrorsEnum.FIELD_REQUIRED);
      setErrorInputCode(true);
    } else if (
      !isEmpty(optionSelected) &&
      validateCodeExpression(optionSelected as CatalogsNameEnum, code)
    ) {
      setMessageCodeError("");
      setErrorInputCode(false);
    } else {
      setMessageCodeError(ErrorsEnum.INVALID_FORMAT);
      setErrorInputCode(true);
    }
    handleFormCompleted();
  };

  const handleCreateCatalogItem = (): void => {
    const { id, itemCatalog } = verifyExistsItemCatalog(
      catalogsList!,
      CatalogPropertyEnum.NAME,
      optionSelected,
      code
    );

    if (isEmpty(itemCatalog) || action === ActionEnum.UPDATE) {
      const request: CatalogRequest = {
        action: action ? action : ActionEnum.NEW,
        code,
        id,
        value: description,
      };
      dispatch(updateCatalogItem(request!));
    } else {
      dispatch(
        setCatalogRequest({
          action: ActionEnum.UPDATE,
          code,
          id,
          value: description,
        })
      );
      dispatch(setIsOpenCatalogFormPopup(false));
      dispatch(setIsOpenCatalogConfirmPopup(true));
    }
  };

  useEffect(() => {
    handleFormCompleted();
  }, []);

  return {
    action,
    code,
    description,
    optionSelected,
    disableButton,
    errorInputCode,
    errorInputDescription,
    messageCodeError,
    handleCatalogCode,
    handleCatalogDescription,
    handleOptionSelected,
    handleCloseDialog,
    handleCreateCatalogItem,
    handleErrorDescription,
    handleErrorCode,
    handleFormCompleted,
  };
};
