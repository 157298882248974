import { paymentMethodCardStyles as styles } from "../PaymentMethodCard/PaymentMethodCard.styles";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { IconCircleInformation } from "@kushki/connect-ui";
import { ProcessorTypeEnum } from "../../shared/enums/ProcessorTypeEnum";
import { StatusChipEnum } from "../../shared/enums/StatusEnum";
import { ChipStatus } from "../ChipStatus/ChipStatus";
import { StatusTypeEnum } from "../../shared/enums/StatusTypeEnum";
import { PAYMENT_METHOD_ICON } from "../../shared/utils/IconPaymentMethods";
import { getProcessorType } from "../../shared/utils/getProcessorType";
import {
  IPaymentMethodProps,
  Processor,
} from "../../shared/interfaces/PaymentMethodCard.interfces";
import { TYPE } from "../../shared/constants/PaymentMethodConstants";
import { PaymentMethodEnum } from "../../shared/enums/PaymentMethodEnum";

const PaymentMethodCard = (props: IPaymentMethodProps) => {
  const { paymentMethod } = props;
  const { translatedName, processorType, status } = paymentMethod;
  let processor: Processor;
  let aggregatorProcessor: Processor = {
    legend: "",
    name: "",
  };

  if (processorType === ProcessorTypeEnum.GATEWAY_AGGREGATOR) {
    processor = getProcessorType(ProcessorTypeEnum.GATEWAY);
    aggregatorProcessor = getProcessorType(ProcessorTypeEnum.AGGREGATOR);
  } else {
    processor = getProcessorType(processorType as ProcessorTypeEnum);
  }

  const isCreditCardOrTransfer: boolean = [
    PaymentMethodEnum.CARD,
    PaymentMethodEnum.TRANSFER,
    PaymentMethodEnum.PAYOUTS_CARD,
  ].includes(paymentMethod.name);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} sx={styles.gridPaymentMethodCard}>
      <Box sx={styles.principalBox}>
        <Box sx={styles.secondBox}>
          {PAYMENT_METHOD_ICON[paymentMethod.name]}
          <Typography sx={styles.title}>{translatedName}</Typography>
        </Box>
        <ChipStatus
          text={
            status === StatusTypeEnum.ACTIVE
              ? StatusChipEnum.ACTIVE
              : StatusChipEnum.INACTIVE
          }
        />
      </Box>
      <Box sx={styles.principalBox}>
        <Typography sx={styles.secondaryTitle}>{TYPE}</Typography>
      </Box>
      <Box sx={styles.box2}>
        <Typography data-testid={"processorFirst"} sx={styles.titleAgregator}>
          {processor.name}
        </Typography>

        {isCreditCardOrTransfer && (
          <Tooltip title={processor.legend} placement="top">
            <Box data-testid={"firstTooltip"}>
              <IconCircleInformation
                fontSize="inherit"
                sx={{
                  height: "14.67px",
                  marginLeft: "30px",
                  marginTop: "7px",
                  width: "14.67px",
                }}
              />
            </Box>
          </Tooltip>
        )}
      </Box>
      {processorType === ProcessorTypeEnum.GATEWAY_AGGREGATOR && (
        <Box sx={styles.box2}>
          <Typography sx={styles.titleAgregator}>
            {aggregatorProcessor.name}
          </Typography>

          {isCreditCardOrTransfer && (
            <Tooltip title={aggregatorProcessor.legend} placement="top">
              <Box data-testid={"secondTooltip"}>
                <IconCircleInformation
                  fontSize="inherit"
                  sx={{
                    height: "14.67px",
                    marginLeft: "30px",
                    marginTop: "7px",
                    width: "14.67px",
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
      <Box sx={styles.box2} />
    </Grid>
  );
};

export default PaymentMethodCard;
