import React from "react";
import { IDialogReturnRequest } from "./DialogReturnRequest.interfaces";
import { useStyles } from "./DialogReturnRequest.styles";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export const DialogReturnRequest: React.FC<IDialogReturnRequest> = (
  props: IDialogReturnRequest
) => {
  const classes = useStyles();

  return (
    <Dialog open={props.dialog}>
      <CircularProgress
        size={55}
        color={"primary"}
        className={classes.loading}
      />
      <DialogContent className={classes.containerDialog}>
        <DialogContentText className={classes.contentTitle}>
          Estamos validando tu solicitud.
        </DialogContentText>
        <DialogContentText className={classes.contentSubtitle}>
          *Considera que en algunos casos el resultado de la solicitud puede ser
          denegada.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
