import { createStyles } from "@mui/styles";

export const alarmsStyles = createStyles({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "#023365",
  },
  commentsBox: {
    marginTop: "24px",
  },
  textArea: {
    width: "695px !important",
    display: "inline-grid",
    height: "220px",
  },
  textMargin: {
    marginBottom: "36px",
  },
  modalContent: {
    overflowY: "scroll",
    maxHeight: "inherit",
  },
  btnDownload: {
    fontSize: "15px",
    minWidth: "230px",
    maxWidth: "100%",
  },
});
