export enum EntityNameEnum {
  OWNER = "OWNER",
  CUSTOMER = "CUSTOMER",
  BRANCH = "BRANCH",
  EMPTY = "",
  NA = "N/A",
  MERCHANT = "MERCHANT",
}

export enum EntityNameEnumChips {
  OWNER = "Merchants",
  CUSTOMER = "Customer",
  BRANCH = "Branch",
}
