import { applyMiddleware, compose, createStore, Store } from "redux";
import React from "react";
import { SnackBarProvider, theme } from "@kushki/connect-ui";
import thunk from "redux-thunk";
import { reducer } from "./store/reducer";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Navigation from "./navigation.component";
import { environment } from "./environments/environment";
import { Provider } from "react-redux";
import { ComponentIdEnum } from "./shared/infrastructure/enums/ComponentIdEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

export const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <StyledEngineProvider>
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackBarProvider>
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={ComponentIdEnum.M_CONSOLE_CREDENTIALS}
              >
                <Navigation />
              </ModuleSecurityWrapper>
            </SnackBarProvider>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </StyledEngineProvider>
  );
};

export default Root;
