import { ICategory, LanguageCatalog } from "./LanguageCatatog";

export const translate = (text: string): string => {
  if (text === undefined) return "";
  const index: number = LanguageCatalog.findIndex(
    (detail: ICategory) => detail.lang === text || "".toLowerCase()
  );

  if (index >= 0) {
    const lang: string | undefined = LanguageCatalog[index]["es"];

    return lang !== undefined ? lang : text;
  }

  return text;
};

export const ampm = (hour: number): string => (hour >= 12 ? "p.m." : "a.m.");
