import React from "react";
import {
  Box,
  Chip,
  Typography,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ICustomerHeader } from "../../shared/infrastructure/interfaces/ICustomerHeader";
import { Variant } from "@mui/material/styles/createTypography";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { getCountryFlag } from "../../shared/infrastructure/constants/flags/countryFlag";
import { merchantBasicInfoStyles as styles } from "../MerchantBasicInfoComponent/MerchantBasicInfoComponent.styles";
import { clipboard } from "../../shared/utils/clipboard";
import { Copy } from "react-feather";

const modalDetailStyles = makeStyles({
  title: {
    color: "#023365",
    fontWeight: "bold",
    fontSize: "26px",
  },
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  rightBox: {
    alignItems: "center",
    display: "flex",
  },
  dataBox: {
    alignItems: "center",
    display: "flex",
    borderBottom: "1px solid #cbd5e0",
    paddingBottom: "10px",
  },
  tag: {
    height: "21px",
    marginTop: "12px",
  },
  iconText: {
    verticalAlign: "middle",
    display: "inline-block",
    color: "text.grey",
  },
  iconAlign: {
    alignContent: "flex-start",
  },
});

export const CustomerHeader: React.FC<ICustomerHeader> = (
  props: ICustomerHeader
) => {
  const classes = modalDetailStyles();
  const typographyVariant: Variant = "caption";

  return (
    <>
      <Box className={classes.rightBox}>
        <Typography className={classes.title}>{props.title}</Typography>
        <Chip
          sx={{ p: 2, ml: 2 }}
          label={props.tagLabel}
          color={props.tagColor}
        />
      </Box>
      <Box display="flex" gap={"5px"} mt={2} className={classes.dataBox}>
        <Grid container spacing={1}>
          <Grid item>
            <Typography
              variant={typographyVariant}
              className={classes.iconText}
            >
              {props.subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={typographyVariant}
              className={classes.iconText}
            >
              {getCountryFlag[props.flag]}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={typographyVariant}
              className={classes.iconText}
            >
              {props.constitutionalCountry}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography
              variant={typographyVariant}
              className={classes.iconText}
            >
              {LabelEnum.MERCHANT_ID}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={typographyVariant}
              className={classes.iconText}
            >
              {props.data}
            </Typography>
          </Grid>
          <Box className={classes.iconAlign}>
            <IconButton
              sx={styles.iconEffect}
              data-testid={"IconCopy"}
              onClick={(e) => {
                clipboard(props.data);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Copy />
            </IconButton>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
