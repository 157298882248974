import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

interface BreadcrumItem {
  label: string;
  url: string;
  beforeAction?(): void;
  disabled?: boolean;
}

export interface BreadcrumProps {
  items: BreadcrumItem[];
  lastItem: string;
}

const useStyles = makeStyles({
  separator: {
    "& .MuiBreadcrumbs-separator": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
});

const Breadcrumb = (props: BreadcrumProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<ChevronRight size={16} />}
        aria-label="breadcrumb"
        style={{ marginBottom: 10 }}
        className={classes.separator}
      >
        {props.items.map((item: BreadcrumItem, index: number) =>
          item.disabled ? (
            <Typography key={index} color="inherit">
              {item.label}
            </Typography>
          ) : (
            <Link
              key={index}
              component={RouterLink}
              to={item.url}
              color="inherit"
            >
              {item.label}
            </Link>
          )
        )}
        <Typography color="primary">{props.lastItem}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default Breadcrumb;
