import React from "react";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IOptionSelection } from "../../shared/infrastructure/constants/RulesConstants";
import { defaultTo } from "lodash";

export interface MultipleSelectionComponentProps {
  property: string;
  label: string;
  value: string[];
  currencyValues: string[];
  checkAll: boolean;
  items: IOptionSelection[];
  onChange: () => void;
  handleCheckedAll: () => void;
}

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export const MultipleSelectionComponent: React.FC<MultipleSelectionComponentProps> =
  (props: MultipleSelectionComponentProps) => {
    const classes = useStyles();

    return (
      <React.Fragment>
        <Select
          label={props.label}
          multiple
          id={props.property}
          value={props.value}
          onChange={props.onChange}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value: string) => (
                <Chip
                  key={value}
                  label={value}
                  className={classes.chip}
                  onDelete={() => {}}
                />
              ))}
            </div>
          )}
        >
          <MenuItem key={"select-all"}>
            <FormControlLabel
              onClick={props.handleCheckedAll}
              control={<Checkbox color="primary" checked={props.checkAll} />}
              label={"Seleccionar todo"}
              labelPlacement="end"
            />
          </MenuItem>
          {props.items.map((name, index) => (
            <MenuItem key={index} value={name.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={defaultTo(props.currencyValues, []).includes(
                      name.value
                    )}
                  />
                }
                label={name.name}
                labelPlacement="end"
              />
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  };
