import React from "react";
import { Box, IconButton } from "@mui/material";
import { variablesWrapperStyles as styles } from "@components/VariablesWrapper/VariablesWrapper.styles";
import FormControl from "@components/FormControl/FormControl";
import { InputTypeEnum } from "@shared/enum/InputTypeEnum";
import {
  inputFieldName,
  InputLabelForm,
} from "@shared/constants/AlarmConfigConstants";
import { RULES } from "@shared/constants/formRules";
import { IconCircleCross } from "@kushki/connect-ui";
import { UseFieldArrayRemove } from "react-hook-form";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";

type Props = {
  index: number;
  remove: UseFieldArrayRemove;
  options: ISelectOption[];
};
const FieldWrapper: React.FC<Props> = ({ index, remove, options }: Props) => {
  return (
    <Box sx={styles.fieldContainer}>
      <Box sx={styles.wrapper}>
        <FormControl
          inputType={InputTypeEnum.SELECT}
          name={inputFieldName(index)}
          label={InputLabelForm.FIELD}
          options={options}
          rules={RULES.field}
        />
        <Box sx={styles.deleteButton}>
          <IconButton onClick={() => remove(index)}>
            <IconCircleCross fontSize={"large"} color={"disabled"} />
          </IconButton>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default FieldWrapper;
