import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaginationRefunds } from "../../../../../store/reducers/refunds/refunds";

export const usePagination = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state["refunds"]["pagination"]["page"]);
  const rowsPerPage = useSelector(
    (state) => state["refunds"]["pagination"]["rowsPerPage"]
  );

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(setPaginationRefunds({ page: value, rowsPerPage }));
  };

  const handleChangeRowsPerPage = (rowsPerPage: number): void => {
    dispatch(setPaginationRefunds({ page: 1, rowsPerPage: rowsPerPage }));
  };

  return {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
  };
};
