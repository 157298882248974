/**
 * CatalogEc
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogEeuu: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    {
      name: "Banco Azteca",
      value: "11000",
    },
    {
      name: "Bancomer",
      value: "11001",
    },
    {
      name: "Capital One",
      value: "11002",
    },
    {
      name: "Bancolombia En Nueva York",
      value: "11003",
    },
    {
      name: "Agricola",
      value: "11004",
    },
    {
      name: "Banorte",
      value: "11005",
    },
    {
      name: "Ocean Bank Of Miami",
      value: "11006",
    },
    {
      name: "Hsbc",
      value: "11007",
    },
    {
      name: "Scotiabank",
      value: "11008",
    },
    {
      name: "Banco Del Pichinca Del Ecuador",
      value: "11009",
    },
    {
      name: "Citibank",
      value: "11010",
    },
    {
      name: "Banco Del Austro",
      value: "11011",
    },
    {
      name: "Scotia Bank",
      value: "11012",
    },
    {
      name: "Wells Fargo Bank",
      value: "11013",
    },
    {
      name: "Banco Cuscatlan",
      value: "11014",
    },
    {
      name: "Ficohsa",
      value: "11015",
    },
    {
      name: "Banco De Occidente",
      value: "11016",
    },
    {
      name: "Bb&t",
      value: "11017",
    },
    {
      name: "Banco Ficohsa",
      value: "11018",
    },
    {
      name: "Chase",
      value: "11019",
    },
    {
      name: "Bancolombia",
      value: "11020",
    },
    {
      name: "Banco Guayaquil",
      value: "11021",
    },
    {
      name: "Fidelity Bank Plc",
      value: "11022",
    },
    {
      name: "Wachovia Bank",
      value: "11023",
    },
    {
      name: "Santander",
      value: "11024",
    },
    {
      name: "First Bank",
      value: "11025",
    },
    {
      name: "Boi Bank Corp",
      value: "11026",
    },
    {
      name: "Chase Bank",
      value: "11027",
    },
    {
      name: "Ibc Commerce Bank",
      value: "11028",
    },
    {
      name: "State Trust Life And Annuities",
      value: "11029",
    },
    {
      name: "Bbva",
      value: "11030",
    },
    {
      name: "American Premier Trust",
      value: "11031",
    },
    {
      name: "Td Bank",
      value: "11032",
    },
    {
      name: "Banco Pichincha",
      value: "11033",
    },
    {
      name: "Banamex",
      value: "11034",
    },
    {
      name: "Citibank (banamex Usa)",
      value: "11035",
    },
    {
      name: "Banco De Venezuela",
      value: "11036",
    },
    {
      name: "Banco Pacifico Ecuador",
      value: "11037",
    },
    {
      name: "Pnc Bank N.a. Citizens Plaza",
      value: "11038",
    },
    {
      name: "Banco De America Central",
      value: "11039",
    },
    {
      name: "Banco Popular De Puerto Rico",
      value: "11040",
    },
    {
      name: "Money Gram",
      value: "11041",
    },
    {
      name: "Scotiabank Inverlat",
      value: "11042",
    },
    {
      name: "Banco Continental",
      value: "11043",
    },
    {
      name: "Credit Union",
      value: "11044",
    },
    {
      name: "Royal Bank De Plc Londres De Escocia",
      value: "11045",
    },
    {
      name: "Credit One",
      value: "11046",
    },
    {
      name: "Chase Manhattan Bank",
      value: "11047",
    },
    {
      name: "Apex Credit Bank",
      value: "11048",
    },
    {
      name: "Banco Santander Hispano",
      value: "11049",
    },
    {
      name: "Shevi",
      value: "11050",
    },
    {
      name: "Banco Del Austro Miami",
      value: "11051",
    },
    {
      name: "Banco Popular",
      value: "11052",
    },
    {
      name: "Compass Bank",
      value: "11053",
    },
    {
      name: "Statetrust Bank & Trust Ltd.",
      value: "11054",
    },
    {
      name: "Union Bank",
      value: "11055",
    },
    {
      name: "Discover",
      value: "11056",
    },
    {
      name: "Banco Del Pacifico",
      value: "11057",
    },
    {
      name: "Banco De Credito",
      value: "11058",
    },
    {
      name: "Ocean Bank",
      value: "11059",
    },
    {
      name: "Av Villas",
      value: "11060",
    },
    {
      name: "Us Bank",
      value: "11061",
    },
    {
      name: "Banco Pnc",
      value: "11062",
    },
    {
      name: "Internacional Bank",
      value: "11063",
    },
    {
      name: "State Trust Bank",
      value: "11064",
    },
    {
      name: "First National Bank",
      value: "11065",
    },
    {
      name: "Gmac",
      value: "11066",
    },
    {
      name: "Banco Ficosa",
      value: "11067",
    },
    {
      name: "Chebi Chafe Bank",
      value: "11068",
    },
    {
      name: "Bank Of America",
      value: "11069",
    },
    {
      name: "Bic",
      value: "11070",
    },
    {
      name: "Banca Intesa Spa New York",
      value: "11071",
    },
    {
      name: "Citybank",
      value: "11072",
    },
    {
      name: "Metabank",
      value: "11073",
    },
    {
      name: "Idb Bank",
      value: "11074",
    },
    {
      name: "Banco Region",
      value: "11075",
    },
    {
      name: "Hell Bank",
      value: "11076",
    },
    {
      name: "Bolivariano",
      value: "11077",
    },
    {
      name: "Banco Provincial",
      value: "11078",
    },
    {
      name: "Banco De Bogota",
      value: "11079",
    },
    {
      name: "Wachovia",
      value: "11080",
    },
    {
      name: "Wells Fargo",
      value: "11081",
    },
    {
      name: "Banco De Desarrollo Rural",
      value: "11082",
    },
    {
      name: "First National Bank De South Africa",
      value: "11083",
    },
    {
      name: "Atlantic Bank",
      value: "11084",
    },
    {
      name: "Rabobank",
      value: "11085",
    },
    {
      name: "Lome-togo",
      value: "11086",
    },
    {
      name: "Jp Morgan",
      value: "11087",
    },
    {
      name: "Bank Alliance Financier",
      value: "11088",
    },
    {
      name: "Societe Generale Bank & Trust",
      value: "11089",
    },
    {
      name: "Banco Santander",
      value: "11090",
    },
    {
      name: "Barclays",
      value: "11091",
    },
    {
      name: "Lnb",
      value: "11092",
    },
    {
      name: "Alpine Bank",
      value: "11093",
    },
    {
      name: "Chase Bank Plc",
      value: "11094",
    },
    {
      name: "Royal Bank Of Scotland Plc",
      value: "11095",
    },
    {
      name: "Bbst",
      value: "11096",
    },
    {
      name: "Stanford Bank",
      value: "11097",
    },
    {
      name: "Banco Agricola",
      value: "11098",
    },
    {
      name: "Mbna",
      value: "11099",
    },
    {
      name: "Deutsch Bank",
      value: "11100",
    },
    {
      name: "Banesto",
      value: "11101",
    },
    {
      name: "Citigroup Inc C.n",
      value: "11102",
    },
    {
      name: "Suntrack",
      value: "11103",
    },
    {
      name: "Great Western Bank",
      value: "11104",
    },
    {
      name: "Chafe",
      value: "11105",
    },
    {
      name: "Zions Bank",
      value: "11106",
    },
    {
      name: "Capital One En Miami",
      value: "11107",
    },
    {
      name: "Barclays Banc",
      value: "11108",
    },
    {
      name: "Mercantil",
      value: "11109",
    },
    {
      name: "Schlumberger Credit Union",
      value: "11110",
    },
    {
      name: "Zenith Bank",
      value: "11111",
    },
    {
      name: "Merryl Lynch",
      value: "11112",
    },
    {
      name: "Royal Bank Of Scotland",
      value: "11113",
    },
    {
      name: "Standard Chartered Bsnk",
      value: "11114",
    },
    {
      name: "Bank Of China",
      value: "11115",
    },
    {
      name: "Ibc",
      value: "11116",
    },
    {
      name: "Banco Salvadoreño",
      value: "11117",
    },
    {
      name: "Standard Chartered Bank Plc Del Reino Unido",
      value: "11118",
    },
    {
      name: "Columbia River Bank",
      value: "11119",
    },
    {
      name: "Security Service Federal Credit Union",
      value: "11120",
    },
    {
      name: "Scotian Bank",
      value: "11121",
    },
    {
      name: "Ge Money Bank",
      value: "11122",
    },
    {
      name: "Banco Bcp",
      value: "11123",
    },
    {
      name: "East West Bank",
      value: "11124",
    },
    {
      name: "Coamerica",
      value: "11125",
    },
    {
      name: "M&t",
      value: "11126",
    },
    {
      name: "Bbva En Estados Unidos",
      value: "11127",
    },
    {
      name: "Ocean Bak",
      value: "11128",
    },
    {
      name: "State Bank Of Fargo",
      value: "11129",
    },
    {
      name: "Banco Avevillas",
      value: "11130",
    },
    {
      name: "F.b.t Bank Lome-togo",
      value: "11131",
    },
    {
      name: "First Financial Bank Plc",
      value: "11132",
    },
    {
      name: "Lantian Li",
      value: "11133",
    },
    {
      name: "United Trust Bank",
      value: "11134",
    },
    {
      name: "Stanley Smith Barney",
      value: "11135",
    },
    {
      name: "Bank Wachovya",
      value: "11136",
    },
    {
      name: "Broadway Bank",
      value: "11137",
    },
    {
      name: "Banque Atlantique",
      value: "11138",
    },
    {
      name: "Sopra North Bank",
      value: "11139",
    },
    {
      name: "Bank Ok America",
      value: "11140",
    },
    {
      name: "Brou",
      value: "11141",
    },
    {
      name: "Saint Germain",
      value: "11142",
    },
    {
      name: "Charter One",
      value: "11143",
    },
    {
      name: "Moneda Usa Inc",
      value: "11144",
    },
    {
      name: "Bankia",
      value: "11145",
    },
    {
      name: "Strasbourg Sovereign Trust",
      value: "11146",
    },
    {
      name: "Gordwady Finance Cuit",
      value: "11147",
    },
    {
      name: "Austro",
      value: "11148",
    },
    {
      name: "Bank Banco De Venezuela Usa",
      value: "11149",
    },
    {
      name: "Bank Pic Del Reino Unido",
      value: "11150",
    },
    {
      name: "Pnc",
      value: "11151",
    },
    {
      name: "Sundtrack",
      value: "11152",
    },
    {
      name: "Solvay Bank",
      value: "11153",
    },
    {
      name: "Banamex Usa",
      value: "11154",
    },
    {
      name: "Federal",
      value: "11155",
    },
    {
      name: "Banco Colmena",
      value: "11156",
    },
    {
      name: "Fifth Third Bank",
      value: "11157",
    },
    {
      name: "Banco Chase",
      value: "11158",
    },
    {
      name: "Consolidated Bank Plc",
      value: "11159",
    },
    {
      name: "Fifth District Bank",
      value: "11160",
    },
    {
      name: "Commerce Bank",
      value: "11161",
    },
    {
      name: "Banco Helm",
      value: "11162",
    },
    {
      name: "Commerzbank",
      value: "11163",
    },
    {
      name: "Us Bank National Association",
      value: "11164",
    },
    {
      name: "Banco Mercanti",
      value: "11165",
    },
    {
      name: "Trasatlantic Bank",
      value: "11166",
    },
    {
      name: "Stelling Bank",
      value: "11167",
    },
    {
      name: "Banco Regions",
      value: "11168",
    },
    {
      name: "Hbc",
      value: "11169",
    },
    {
      name: "M&p",
      value: "11170",
    },
    {
      name: "Standard Bank",
      value: "11171",
    },
    {
      name: "International Bank Of Commerce",
      value: "11172",
    },
    {
      name: "Bananex",
      value: "11173",
    },
    {
      name: "Mercantil Bank & Trust Limited",
      value: "11174",
    },
    {
      name: "United Comercial Bank",
      value: "11175",
    },
    {
      name: "Wachobia",
      value: "11176",
    },
    {
      name: "Bcp",
      value: "11177",
    },
    {
      name: "City Banck",
      value: "11178",
    },
    {
      name: "American Express",
      value: "11179",
    },
    {
      name: "Banco De Credito Continental",
      value: "11180",
    },
    {
      name: "Bancoagricola",
      value: "11181",
    },
    {
      name: "Metro Bank",
      value: "11182",
    },
    {
      name: "Scotia",
      value: "11183",
    },
    {
      name: "Cash Bank",
      value: "11184",
    },
    {
      name: "Ficosa",
      value: "11185",
    },
    {
      name: "Chase American Bank Association",
      value: "11186",
    },
    {
      name: "Banco Central De Florida",
      value: "11187",
    },
    {
      name: "Premier Bank International",
      value: "11188",
    },
    {
      name: "Conciban",
      value: "11189",
    },
    {
      name: "Banco De Cibao",
      value: "11190",
    },
    {
      name: "Grand Pacific Corp",
      value: "11191",
    },
    {
      name: "Mercantio Commercebank",
      value: "11192",
    },
    {
      name: "Quantum",
      value: "11193",
    },
    {
      name: "Consultant Bankers",
      value: "11194",
    },
    {
      name: "Usbank",
      value: "11195",
    },
    {
      name: "Banco Del Gobierno",
      value: "11196",
    },
    {
      name: "Royal Bank Plc. Londres Escocia.",
      value: "11197",
    },
    {
      name: "Globe Bank",
      value: "11198",
    },
    {
      name: "Oceanic Bank Plc",
      value: "11199",
    },
    {
      name: "Rbs",
      value: "11200",
    },
    {
      name: "Chfe",
      value: "11201",
    },
    {
      name: "Salt Lake City",
      value: "11202",
    },
    {
      name: "Bank Of Kansas",
      value: "11203",
    },
    {
      name: "Strasbourg",
      value: "11204",
    },
    {
      name: "Wachovia Bank En Estados Unidos",
      value: "11205",
    },
    {
      name: "Coconaut Globe Bank",
      value: "11206",
    },
    {
      name: "Royal Trust Bank",
      value: "11207",
    },
    {
      name: "Banco Comercial Internacional",
      value: "11208",
    },
    {
      name: "Bank Darlet",
      value: "11209",
    },
    {
      name: "Fym",
      value: "11210",
    },
    {
      name: "Bancoficosa",
      value: "11211",
    },
    {
      name: "Royalbank",
      value: "11212",
    },
    {
      name: "Consolidated Bank",
      value: "11213",
    },
    {
      name: "First National Bank Plc London",
      value: "11214",
    },
    {
      name: "Banco Nova",
      value: "11215",
    },
    {
      name: "Sorben Bank",
      value: "11216",
    },
    {
      name: "Strasbourg Sobereign Trust",
      value: "11217",
    },
    {
      name: "Banco Real De Escocia Plc",
      value: "11218",
    },
    {
      name: "Compassbank",
      value: "11219",
    },
    {
      name: "Banrural",
      value: "11220",
    },
    {
      name: "Bbva Bancomer",
      value: "11221",
    },
    {
      name: "Western Union",
      value: "11222",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    {
      name: "Cheques",
      value: "0",
    },
    {
      name: "Ahorros",
      value: "1",
    },
    {
      name: "Inversión",
      value: "2",
    },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
};
