import React from "react";
import {
  IInformationSection,
  InformationDetail,
} from "../../../components/common/InformationDetail/InformationDetail";
import { formatDate } from "../utils/date";
import { TransactionSummaryData } from "../../../../types/transaction_summary_data";
import { get, defaultTo } from "lodash";
import { TransactionData } from "../../../../types/transaction_data";
import { FormatDate } from "./FormatDate";

const getInfoDetailConciliation = (
  trx: TransactionData
): IInformationSection[] => [
  {
    title: "Datos transaccionales",
    items: [
      {
        label: "Fecha y hora de transacción",
        value: formatDate(
          get(trx, "transaction_created", "1"),
          FormatDate.DMY_dash
        ),
      },
      {
        label: "ID transacción",
        value: trx.transaction_id,
      },
      {
        label: "Nombre del comercio",
        value: trx.merchant_name,
      },
      {
        label: "ID del comercio",
        value: trx.merchant_id,
      },
      {
        label: "Tax ID",
        value: trx.tax_id,
      },
      {
        label: "Tarjeta enmascarada",
        value: trx.masked_card_number,
      },
      {
        label: "Código de autorización",
        value: trx.approval_code,
      },
      {
        label: "Valor total de la transacción",
        value: trx.approved_transaction_amount,
      },
    ],
  },
  {
    title: "Datos de liquidación",
    items: [
      {
        label: "Procesador",
        value: trx.processor_name,
      },
      {
        label: "Moneda",
        value: trx.currency_code,
      },
      {
        label: "Valor total de la transacción",
        value: trx.approved_transaction_amount,
      },
      {
        label: "Tasa del procesador",
        value: trx.kushki_comission,
      },
      {
        label: "IVA tasa ADQ",
        value: trx.iva_kushki_comission,
      },
      {
        label: "Retenciones ADQ",
        value: trx.retention,
      },
      {
        label: "Neto a recibir",
        value: trx.net_amount,
      },
      {
        label: "Neto liquidado Adq",
        value: trx.net_liquidate_kushki,
      },
    ],
  },
  {
    title: "Datos complementarios",
    items: [
      {
        label: "Método de pago",
        value: trx.payment_method,
      },
      {
        label: "Marca de tarjeta",
        value: trx.card_brand,
      },
      {
        label: "Tipo de tarjeta",
        value: trx.card_type,
      },
      {
        label: "País",
        value: trx.country,
      },
    ],
  },
];

const getInfoDetailDeposit = (
  trx: TransactionSummaryData,
  actualTab: number
): IInformationSection[] => {
  const infoDepositSection: IInformationSection = {
    title: "Datos de liquidación",
    items: [
      {
        label: "Fecha de liquidación",
        value: formatDate(
          get(trx, "conciliation_created", "1"),
          FormatDate.DMY_dash
        ),
      },
      {
        label: "País",
        value: trx.country,
      },
      {
        label: "Método de pago",
        value: trx.payment_method,
      },
      {
        label: "Procesador",
        value: trx.processor_name,
      },
      {
        label: "Moneda",
        value: trx.currency_code,
      },
      {
        label: "Neto a recibir",
        value: trx.net_amount_total,
      },
    ],
  };

  if (actualTab === 1) {
    infoDepositSection.items.push({
      label: "Valor liquidado",
      value: defaultTo(trx.liquidation_amount, 0),
    });
  }

  return [infoDepositSection];
};

const getInfoDetailConciliationComp = (trx: TransactionData): JSX.Element => (
  <InformationDetail infoDetail={getInfoDetailConciliation(trx)} />
);

const getInfoDetailDepositComp = (
  trx: TransactionSummaryData,
  actualTab: number
): JSX.Element => (
  <InformationDetail infoDetail={getInfoDetailDeposit(trx, actualTab)} />
);

export { getInfoDetailConciliationComp, getInfoDetailDepositComp };
