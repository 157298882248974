import React from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { IGenericTableProps } from "@components/GenericTable/GenericTable.interfaces";
import { IconSearch, TableSimple } from "@kushki/connect-ui";
import { useGenericTable } from "@components/GenericTable/state/useGenericTable";
import "@components/GenericTable/GenericTable.css";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { TABLE_FOOTER_TEXT } from "@shared/constants/tableHeaderConstants";

const GenericTable: <T = unknown, U = unknown>(
  props: IGenericTableProps<T, U>
) => JSX.Element = <T, U>({
  allowSelection,
  headerProps,
  headers,
  inputPlaceholder,
  isLoading,
  rows,
  showInput,
  showTitle,
  sortCatalog,
  tableColumns,
  title,
  onSelectedRows,
  onChangeInput,
  searchInputValue,
  onSelectedRow,
  tableFooter,
}: IGenericTableProps<T, U>) => {
  const { handledHeaders, handledRows, isLoadingTable } = useGenericTable({
    headers,
    isLoading,
    rows,
    sortCatalog,
    tableColumns,
  });

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          {showTitle && (
            <Typography variant={"h6"} color="text.primary">
              {title}
            </Typography>
          )}
        </Grid>
        {showInput && (
          <Grid item xs={3}>
            <ComponentSecurityWrapper
              componentId={SecurityWrapperRoles.M_COMPLIANCE_TABLE_LOCAL_SEARCH}
            >
              <TextField
                placeholder={inputPlaceholder}
                margin="normal"
                fullWidth
                className="solidTextfield"
                onChange={onChangeInput}
                value={searchInputValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconSearch color="primary" fontSize="medium" />
                    </InputAdornment>
                  ),
                }}
              />
            </ComponentSecurityWrapper>
          </Grid>
        )}
      </Grid>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperRoles.M_COMPLIANCE_TABLE_NODE}
      >
        <TableSimple
          allowSelection={allowSelection}
          header={{
            cells: handledHeaders,
            headerProps,
          }}
          onSelectedRow={onSelectedRow}
          rows={handledRows}
          showPagination
          isLoading={isLoadingTable}
          onSelectedRows={onSelectedRows}
          footerPagination={{
            isDisable: false,
            itemsPerPage: tableFooter.itemsPerPage,
            onItemsPerPageChange: tableFooter.handleItemsPerPage,
            onPageClick: tableFooter.handleSelectedPage,
            paginationText: TABLE_FOOTER_TEXT,
            selectedPage: tableFooter.selectedPage,
            textFieldSelector: tableFooter.textFieldSelector,
            totalItems: tableFooter.total,
          }}
        />
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default GenericTable;
