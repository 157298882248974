import { ModalDetail } from "@kushki/connect-ui";
import { ContentModalDetail } from "../ModalDetail/ContentModalDetail";
import { ElementModalComponent } from "../ModalDetail/ElementModalComponent/ElementModalComponent";
import { ElementModalTableComponent } from "../ModalDetail/ElementModalTableComponent/ElementModalTableComponent";
import React from "react";
import { useModalContainerState } from "./state/useModalContainerState";
import { IModalContainer } from "../../shared/infrastructure/interfaces/IModalContainer";
import { Box } from "@mui/material";

export const ModalContainer: React.FC<IModalContainer> = (
  props: IModalContainer
) => {
  const {
    onChangeModal,
    header,
    data,
    isOpenModal,
    isLoading,
    isLoadingRequest,
  } = useModalContainerState(props);

  return (
    <React.Fragment>
      {header && !props.isLoadingRules && (
        <ModalDetail
          header={{
            ...header,
            buttonSecondary: header.buttonSecondary
              ? {
                  ...header.buttonSecondary,
                  isDisabled: isLoadingRequest,
                }
              : undefined,
          }}
          onClose={onChangeModal}
          isOpen={isOpenModal}
          isLoading={isLoading}
          variant={"content"}
        >
          <Box display="flex">
            <ContentModalDetail>
              <ElementModalComponent
                {...data.generalInfo}
                isLoading={isLoading}
              />
              <ElementModalComponent
                {...data.generalCountryMerchantInfo}
                isLoading={isLoading}
              />
            </ContentModalDetail>
            <ContentModalDetail>
              <ElementModalComponent
                {...data.configInfo.firstSection}
                isLoading={isLoading}
                secondSectionBody={data.configInfo.secondSection}
              />
              <ElementModalTableComponent
                {...data.generalConditions}
                isLoading={isLoading}
              />
              <ElementModalTableComponent
                {...data.specialConditions}
                isLoading={isLoading}
              />
              <ElementModalTableComponent
                {...data.speedConditions}
                isLoading={isLoading}
              />
              <ElementModalComponent
                {...data.generalAdditionalInfo}
                isLoading={isLoading}
              />
            </ContentModalDetail>
          </Box>
        </ModalDetail>
      )}
    </React.Fragment>
  );
};
