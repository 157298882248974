import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";

export interface InformationCommentsSupportProps {
  supportInfo: object | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
    box: {
      color: "#293036",
      fontWeight: 400,
    },
  })
);

const InformationCommentsSupport: React.FC<InformationCommentsSupportProps> = (
  props: InformationCommentsSupportProps
) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <Box className={classes.box}>Comentarios</Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  {get(
                    props.supportInfo,
                    "_source.message_close_session",
                    "N/A"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default InformationCommentsSupport;
