import React, { FC } from "react";
import { useMainContainer } from "./state/useMainContainer";
import ClientsHeaderWrapper from "../../components/ClientsHeaderWrapper/ClientsHeaderWrapper";
import OwnerHeader from "../../components/OwnerHeader/OwnerHeader";
import CustomerHeader from "../../components/CustomerHeader/CustomerHeader";
import { Box } from "@mui/material";
import { mainContainerStyles as styles } from "./MainContainer.styles";

const MainContainer: FC = () => {
  const {
    emptyAddText,
    handleCreateOwner,
    isLoading,
    isOpenModal,
    isOwnerCreated,
    onCloseModal,
    openModal,
    showEmptyScreen,
    isLoadingFlow,
  } = useMainContainer();

  return (
    <Box sx={styles.boxContainer}>
      <ClientsHeaderWrapper
        onCloseModal={onCloseModal}
        isLoading={isLoading}
        isOpenModal={isOpenModal}
        handleCreateOwner={handleCreateOwner}
      >
        {!isOwnerCreated ? (
          <OwnerHeader
            onClickButton={openModal}
            emptyAddText={emptyAddText}
            showEmptyScreen={showEmptyScreen}
            isLoadingFlow={isLoadingFlow}
          />
        ) : (
          <CustomerHeader
            onClickAddBtn={openModal}
            emptyAddText={emptyAddText}
            showEmptyScreen={showEmptyScreen}
          />
        )}
      </ClientsHeaderWrapper>
    </Box>
  );
};

export default MainContainer;
