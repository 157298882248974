import { makeStyles } from "@material-ui/core/styles";

export const accordionPopUpStyle = makeStyles({
  nonSpace: {
    margin: "0px",
    padding: "0px",
  },
  filterTitles: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#293036",
  },
});
