/* istanbul ignore file */
export enum CountryEnum {
  BRAZIL = "Brazil",
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  ECUADOR = "Ecuador",
  MEXICO = "Mexico",
  PERU = "Peru",
  GUATEMALA = "Guatemala",
  PANAMA = "Panama",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  SALVADOR = "El Salvador",
  COSTA_RICA = "Costa Rica",
}

export enum CustomCountryEnum {
  BRASIL = "Brasil",
  EL_SALVADOR = "ElSalvador",
  COSTA_RICA = "CostaRica",
}

export const FILTER_COUNTRIES_MAP: Record<string, string> = {
  [CustomCountryEnum.BRASIL]: CountryEnum.BRAZIL,
  [CountryEnum.ECUADOR]: CountryEnum.ECUADOR,
  [CountryEnum.GUATEMALA]: CountryEnum.GUATEMALA,
  [CountryEnum.HONDURAS]: CountryEnum.HONDURAS,
  [CountryEnum.CHILE]: CountryEnum.CHILE,
  [CountryEnum.MEXICO]: CountryEnum.MEXICO,
  [CountryEnum.PANAMA]: CountryEnum.PANAMA,
  [CustomCountryEnum.EL_SALVADOR]: CountryEnum.SALVADOR,
  [CountryEnum.COLOMBIA]: CountryEnum.COLOMBIA,
  [CountryEnum.PERU]: CountryEnum.PERU,
  [CustomCountryEnum.COSTA_RICA]: CountryEnum.COSTA_RICA,
  [CountryEnum.NICARAGUA]: CountryEnum.NICARAGUA,
};
