import React, { useEffect } from "react";
import { SelectCheck } from "@kushki/connect-ui";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { isEmpty } from "lodash";
import { IOptionSelection } from "../../../../shared/interfaces/option_selection";
import { ISelectFieldComponentProps } from "../../../../shared/interfaces/select_field_component_props";

export const SelectFieldComponent: React.FC<ISelectFieldComponentProps> = (
  props: ISelectFieldComponentProps
) => {
  useEffect(() => {
    const dataToRetrieve: ICategory[] = (
      Array.isArray(props.selected) ? props.selected : []
    )
      .filter((data: string) => !isEmpty(data))
      .map((data) => {
        return {
          label: props.conditionProperty,
          selected: true,
          value: data,
        };
      });

    props.setSelectedItems(dataToRetrieve);
  }, [props.selected]);

  return (
    <SelectCheck
      maxSelectedItems={
        props.maxSelectedItems ? props.maxSelectedItems : undefined
      }
      maxWidthDeskt={120}
      maxWidthMob={70}
      isActiveSelectAll
      items={props.items.map((item: IOptionSelection) => {
        const value: string = item.value;

        if (props.selected.includes(value))
          return { label: item.label, selected: true, value: item.value };

        return { label: item.label, selected: false, value: item.value };
      })}
      onItemSelect={props.setSelectedItems}
      placeholder={props.placeHolder}
      type="withoutCategory"
      isLoading={false}
      onChangeTextField={() => {}}
    />
  );
};
