import { createNamedStyles } from "../../shared/utils";

export const loadMoreStyles = createNamedStyles({
  button: {
    bgcolor: "primary.main",
    border: "none",
    borderRadius: "4px",
    px: 12,

    "&:focus, &:active": {
      bgcolor: "primary.main",
      border: "none",
      borderRadius: "4px",
    },

    "&:hover": {
      bgcolor: "primary.components.light.hover",
      border: "none",
      borderRadius: "4px",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    p: 4,
  },
});
