import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../components/Filters/ItemCategory/interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import {
  FILTER_GROUPS,
  STATE_ITEM_VALUES,
} from "../constants/labels/header_filters_labels";
import { CategoryTypeFilterEnum } from "../enums/CategoryTypeFilterEnum";
import { FilterSideBarProps } from "../../components/Filters/FilterSideBar/FilterSideBar.interfaces";
import { TransactionStatusLabelEnum } from "../enums/TransactionStatusEnum";
import { emptyFunction } from "./index";
import { FilterTextsEnum } from "../enums/FilterTextsEnum";
import { ItemCategoryType } from "../enums/FilterSelectTypeEnum";
import { cloneDeep } from "lodash";
import { DEFAULT_PAYMENT_SUB_METHODS_TYPE } from "../enums/PaymentMethodEnum";

export const getItemsFilterDetailBar = (
  itemCategory: ItemCategoryProps[]
): TFilterDetailBar[] => {
  let itemsFilterDetailBar: TFilterDetailBar[] = [];

  itemCategory.map((itemSideBar: ItemCategoryProps) => {
    let itemsFilterBar: IFilterItem[] = [];

    itemSideBar.items.map((itemFilterSideBar: ItemCategoryItem) => {
      return (
        itemFilterSideBar.selected &&
        itemsFilterBar.push({
          label: itemFilterSideBar.label,
          value: itemFilterSideBar.value,
        })
      );
    });
    if (itemsFilterBar.length > 0)
      itemsFilterDetailBar.push({
        itemCategoryType: "byChips" as ItemCategoryType,
        items: itemsFilterBar,
        title: itemSideBar.groupSection,
      });
  });

  return itemsFilterDetailBar;
};

export const FILTER_SIDE_BAR_INITIAL_STATE: FilterSideBarProps = {
  categoryItems: [
    {
      groupSection: FILTER_GROUPS.PAYMENT_METHODS,
      isMinimize: true,
      items: cloneDeep(DEFAULT_PAYMENT_SUB_METHODS_TYPE),
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.STATE,
      isMinimize: true,
      items: [
        {
          label: TransactionStatusLabelEnum.INITIALIZED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.INITIALIZED],
        },
        {
          label: TransactionStatusLabelEnum.ON_REVISION,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.ON_REVISION],
        },
        {
          label: TransactionStatusLabelEnum.WON,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.WON],
        },
        {
          label: TransactionStatusLabelEnum.DOCUMENTATION_SENT,
          selected: false,
          value:
            STATE_ITEM_VALUES[TransactionStatusLabelEnum.DOCUMENTATION_SENT],
        },
        {
          label: TransactionStatusLabelEnum.LOST,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.LOST],
        },
        {
          label: TransactionStatusLabelEnum.EXPIRED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.EXPIRED],
        },
        {
          label: TransactionStatusLabelEnum.FAILED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.FAILED],
        },
      ],
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.AMOUNTS,
      isMinimize: true,
      items: [],
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_AMOUNT,
    },
  ],
  filtersCount: 0,
  isApplyFilter: false,
  isMinimized: false,
  isOpen: true,
  onClick: emptyFunction,
  setIsApplyFilter: () => {},
  setRangeAmount: () => {},
  title: FilterTextsEnum.FILTER_BY,
};

export const FILTER_SIDE_BAR_INITIAL_STATE_ADMIN: FilterSideBarProps = {
  categoryItems: [
    {
      groupSection: FILTER_GROUPS.PAYMENT_METHODS,
      isMinimize: true,
      items: cloneDeep(DEFAULT_PAYMENT_SUB_METHODS_TYPE),
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.STATE,
      isMinimize: true,
      items: [
        {
          label: TransactionStatusLabelEnum.INITIALIZED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.INITIALIZED],
        },
        {
          label: TransactionStatusLabelEnum.PENDING,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.PENDING],
        },
        {
          label: TransactionStatusLabelEnum.ON_REVISION,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.ON_REVISION],
        },
        {
          label: TransactionStatusLabelEnum.WON,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.WON],
        },
        {
          label: TransactionStatusLabelEnum.DOCUMENTATION_SENT,
          selected: false,
          value:
            STATE_ITEM_VALUES[TransactionStatusLabelEnum.DOCUMENTATION_SENT],
        },
        {
          label: TransactionStatusLabelEnum.LOST,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.LOST],
        },
        {
          label: TransactionStatusLabelEnum.EXPIRED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.EXPIRED],
        },
        {
          label: TransactionStatusLabelEnum.FAILED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.FAILED],
        },
      ],
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.AMOUNTS,
      isMinimize: true,
      items: [],
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_AMOUNT,
    },
  ],
  filtersCount: 0,
  isApplyFilter: false,
  isMinimized: false,
  isOpen: true,
  onClick: emptyFunction,
  setIsApplyFilter: () => {},
  setRangeAmount: () => {},
  title: FilterTextsEnum.FILTER_BY,
};
