import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MerchantResponse } from "../../../types/merchant_response";
import { INVOICE_FRECUENCY } from "../../shared/infrastructure/constants/MerchantInformationConstant";
import { get } from "lodash";
import { Edit } from "react-feather";

export interface BillingSectionProps {
  onClickEdit: () => void;
  merchant: MerchantResponse;
}
const useStyles = makeStyles(() => ({
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelColumns: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelValues: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  editBtn: {
    width: "99px",
    height: "32px",
  },
}));

export const BillingSection: React.FC<BillingSectionProps> = (
  props: BillingSectionProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>
                  Facturación
                </Typography>
              </Box>
              <Box>
                <Button
                  className={style.editBtn}
                  variant={"outlined"}
                  color={"default"}
                  startIcon={<Edit xlinkTitle={"editar"} size={16} />}
                  onClick={props.onClickEdit}
                >
                  Editar
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pt={0}>
              <Typography className={style.labelTitle3}>
                Datos que utilizamos para emitir las facturas del comercio.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Razón social
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.socialReason}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Frecuencia</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {get(
                  INVOICE_FRECUENCY.filter(
                    (frequency) =>
                      frequency.value === props.merchant!.invoiceFrequency
                  )[0],
                  "name",
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>TAX ID</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.taxId}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Dirección</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.address}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Email</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Departamento
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.province}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Teléfono</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Ciudad</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.city}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
