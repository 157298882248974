import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  TextField,
} from "@material-ui/core";

import {
  AuthenticationRulesLabelField,
  getLabelValues,
  getOperatorValues,
} from "../../shared/infrastructure/constants/AuthenticationRulesConstants";

import { defaultTo, get } from "lodash";
import {
  IOptionSelection,
  RuleSchemaEnum,
} from "../../shared/infrastructure/constants/RuleRequestConstants";

import ConnectForm from "../common/ConnectForm/ConnectForm";
import { ArrayField, Controller } from "react-hook-form";
import {
  IRuleAuthenticationForm,
  IStrictCondition,
} from "../../shared/infrastructure/interfaces/IRuleAuthenticationForm";
import { BuildField } from "./ValuesFields/BuildField";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { Minus } from "react-feather";

export interface StrictConditionsSectionProps {
  index: number;
  disabled: boolean;
  processors: IOptionSelection[];
  credentials: IOptionSelection[];
  indexStrict: number;
  remove: (index?: number | number[] | undefined) => void;
  item: Partial<ArrayField<IStrictCondition>>;
  countries: string | string[];
  service: string;
  ruleType: string;
}
export const StrictConditionsSection: React.FC<StrictConditionsSectionProps> = (
  props: StrictConditionsSectionProps
) => {
  const { remove, indexStrict, index, item, service, ruleType, countries } =
    props;
  return (
    <ConnectForm<IRuleAuthenticationForm>>
      {({ control, errors, getErrorMessage, setValue, watch, getValues }) => {
        const label: string = watch(
          `strictCondition[${indexStrict}].authRules[${index}].label`
        ) as string;
        const operator: string = watch(
          `strictCondition[${indexStrict}].authRules[${index}].operator`
        ) as string;
        return (
          <React.Fragment key={props.item.id}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>{AuthenticationRulesLabelField.LABEL}</InputLabel>
                  <Controller
                    control={control}
                    name={`strictCondition[${indexStrict}].authRules[${index}].label`}
                    rules={{ required: true }}
                    defaultValue={item.label}
                    error={
                      !!get(
                        errors,
                        `strictCondition[${props.index}].authRules[${index}].label`
                      )
                    }
                    render={(field) => (
                      <Select
                        {...field}
                        label={AuthenticationRulesLabelField.LABEL}
                        onChange={(e) => {
                          setValue(
                            `strictCondition[${indexStrict}].authRules[${index}].value`,
                            ""
                          );
                          return field.onChange(e);
                        }}
                      >
                        {getLabelValues(service, ruleType).map(
                          (
                            actionValue: {
                              name: string;
                              value: string;
                            },
                            index: number
                          ) => (
                            <MenuItem key={index} value={actionValue.value}>
                              {actionValue.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexStrict}].authRules[${index}].label`
                      )
                    }
                  >
                    {!!get(
                      errors,
                      `strictCondition[${indexStrict}].authRules[${index}].label`
                    ) &&
                      getErrorMessage(
                        get(
                          errors,
                          `strictCondition[${indexStrict}].authRules[${index}].label`
                        )
                      )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                {label !== RuleSchemaEnum.transactionCreated && (
                  <FormControl
                    variant="outlined"
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexStrict}].authRules[${index}].operator`
                      )
                    }
                    fullWidth
                  >
                    <InputLabel>
                      {AuthenticationRulesLabelField.OPERATOR}
                    </InputLabel>
                    <Controller
                      control={control}
                      name={`strictCondition[${indexStrict}].authRules[${index}].operator`}
                      rules={{ required: true }}
                      defaultValue={item.operator}
                      render={(field) => (
                        <Select
                          {...field}
                          label={AuthenticationRulesLabelField.OPERATOR}
                        >
                          {getOperatorValues(label).map(
                            (
                              actionValue: { name: string; value: string },
                              index: number
                            ) => (
                              <MenuItem key={index} value={actionValue.value}>
                                {actionValue.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      )}
                    />
                    <FormHelperText
                      error={
                        !!get(
                          errors,
                          `strictCondition[${indexStrict}].authRules[${index}].operator`
                        )
                      }
                    >
                      {!!get(
                        errors,
                        `strictCondition[${indexStrict}].authRules[${index}].operator`
                      ) &&
                        getErrorMessage(
                          get(
                            errors,
                            `strictCondition[${indexStrict}].authRules[${index}].operator`
                          )
                        )}
                    </FormHelperText>
                  </FormControl>
                )}
                {label === RuleSchemaEnum.transactionCreated && (
                  <Controller
                    control={control}
                    name={`strictCondition[${indexStrict}].authRules[${index}].from`}
                    rules={{ required: true }}
                    defaultValue={item.from}
                    render={(field) => (
                      <TextField
                        {...field}
                        type={"time"}
                        label="Desde"
                        variant="outlined"
                        fullWidth
                        id={`strictCondition[${indexStrict}].authRules[${index}].from`}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                {label !== RuleSchemaEnum.transactionCreated && (
                  <Controller
                    control={control}
                    name={`strictCondition[${indexStrict}].authRules[${index}].value`}
                    rules={{ required: true }}
                    defaultValue={item.value}
                    render={(field) => (
                      <BuildField
                        credentials={props.credentials}
                        countriesGeneral={defaultTo(countries, []) as string[]}
                        operator={operator}
                        value={get(
                          getValues(),
                          `strictCondition[${indexStrict}].authRules[${index}].value`,
                          ""
                        )}
                        defaultValue={get(
                          getValues(),
                          `strictCondition[${indexStrict}].authRules[${index}].value`,
                          ""
                        )}
                        index={index}
                        label={label as LabelEnum}
                        properties={field}
                        name={`strictCondition[${indexStrict}].authRules[${index}].value`}
                        helperText={
                          !!get(
                            errors,
                            `strictCondition[${props.indexStrict}].authRules.[${props.index}].value`
                          ) &&
                          getErrorMessage(
                            get(
                              errors,
                              `strictCondition[${props.indexStrict}].authRules.[${props.index}].value`
                            )
                          )
                        }
                        error={
                          !!get(
                            errors,
                            `strictCondition[${props.indexStrict}].authRules.[${props.index}].value`
                          )
                        }
                      />
                    )}
                  />
                )}
                {label === RuleSchemaEnum.transactionCreated && (
                  <Controller
                    control={control}
                    name={`strictCondition[${indexStrict}].authRules[${index}].to`}
                    rules={{ required: true }}
                    defaultValue={item.to}
                    render={(field) => (
                      <TextField
                        {...field}
                        type={"time"}
                        label="Hasta"
                        variant="outlined"
                        fullWidth
                        id={`strictCondition[${indexStrict}].authRules[${index}].to`}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <Grid container direction={"row"} justifyContent={"flex-end"}>
                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={() => remove(index)}
                      style={{
                        paddingTop: "0 !important",
                        paddingBottom: "0 !important",
                      }}
                    >
                      <Minus />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      }}
    </ConnectForm>
  );
};

export default StrictConditionsSection;
