/**
 * CatalogCl
 */
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { Category } from "../../interfaces/category";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogCl: Record<string, Category[]> = {
  [CatalogsEnum.BankAccountTypes]: [
    {
      name: "Corriente",
      value: "0",
    },
    {
      name: "Vista",
      value: "1",
    },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.CLP} - Pesos`,
      value: CurrencyEnum.CLP,
    },
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
};
