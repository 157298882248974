import { KindEnum } from "./KindEnum";
import { CountryEnum } from "./CountryEnum";
import { InitialStatusEnum } from "./StatusEnum";
import { get } from "lodash";
import { themeOptions } from "../../themeui";
import { translate } from "./LanguageCatatog";
import {
  setInitialStatusBackgroundColor,
  setInitialStatusColor,
} from "./Colors";
import { IChipProps } from "./CatalogFinalStatus";

export enum initialStatusValuesEnum {
  REJECTED = "Rechazado",
  APPROVED = "Aprobado",
  OMITTED = "Omitida",
}

export const InitialStatusCatalog = {
  [KindEnum.INVOICE]: {
    [InitialStatusEnum.APPROVE]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.APPROVED,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [InitialStatusEnum.OMIT]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.OMITTED,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [InitialStatusEnum.REJECT]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.REJECTED,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.CHARGE]: {
    [InitialStatusEnum.APPROVE]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.APPROVED,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [InitialStatusEnum.OMIT]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.OMITTED,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [InitialStatusEnum.REJECT]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.REJECTED,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.DISPERSION]: {
    [InitialStatusEnum.APPROVE]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.APPROVED,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [InitialStatusEnum.OMIT]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.OMITTED,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [InitialStatusEnum.REJECT]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: initialStatusValuesEnum.REJECTED,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
};

export const getCatalogInitialStatusInfo = (
  kind: KindEnum,
  country: CountryEnum,
  initialStatus: string
): IChipProps => {
  let catalogInfo: IChipProps = {
    value: translate(initialStatus),
    backgroundColor: setInitialStatusBackgroundColor(initialStatus),
    color: setInitialStatusColor(initialStatus),
  };
  const catalogTabs: object = get(
    InitialStatusCatalog,
    `[${kind}][${initialStatus}]`,
    {}
  );
  Object.keys(catalogTabs).map((key: string) => {
    if (key.split("|").includes(country)) catalogInfo = catalogTabs[key];
  });
  return catalogInfo;
};
