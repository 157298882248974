import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";
import theme from "../../theme";

export const actionTransactionButtonStyles: IStyles = {
  menu: {
    fontSize: "14px",
    lineHeight: "19.6px",
    fontWeight: "400",
    color: "neutral.grey8",
  },
  button: {
    backgroundColor: "neutral.grey2",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "neutral.grey2",
    },
  },
};
