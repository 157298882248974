/**
 * CountryEnum
 */
export enum CountryNameEnum {
  Colombia = "Colombia",
  Chile = "Chile",
  Ecuador = "Ecuador",
  México = "México",
  Perú = "Perú",
}
export enum CountryValueEnum {
  Colombia = "Colombia",
  Chile = "Chile",
  Ecuador = "Ecuador",
  México = "Mexico",
  Perú = "Peru",
}
