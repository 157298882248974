import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  IconSquareCheck,
  IconTransferCurve,
  IconUser,
  LogoVerifiedBy,
} from "@kushki/connect-ui";
import IconEnvelope from "@kushki/connect-ui/dist/Components/Atoms/Icons/IconEnvelope";
import { rechargeWalletFormStyles as styles } from "./RechargeWalletForm.styles";
import { useRechargeWalletState } from "./state/useRechargeWalletFormState";
import {
  ACCEPT_CONDITIONS_ERROR,
  ACCEPT_CONDITIONS_MESSAGE,
  CANCEL_BUTTON,
  EMAIL_PLACEHOLDER,
  EMAIL_VALUE_ERROR,
  MINIMAL_MOUNT_ERROR,
  NAME_PLACEHOLDER,
  PERSONAL_DATA,
  RECHARGE_BUTTON,
  RECHARGE_DATA,
  RECHARGE_QUESTION,
  REQUIRED_INPUT,
} from "../../../shared/constants/labels/recharge_wallet_labels";
import { useNavigate } from "react-router";
import FormWrapper from "../../FormWrapper/FormWrapper";
import FooterButtonsWrapper from "../../FooterButtonsWrapper/FooterButtonsWrapper";
import { RouteEnum } from "../../../shared/infrastructure/routes";
import { IRechargeWalletData } from "../../../shared/interfaces/RechargeWalletData.interfaces";

export interface IRechargeWalletForm {
  onRecharge: (data: IRechargeWalletData, email: string) => void;
}

export const RechargeWalletForm = (props: IRechargeWalletForm) => {
  const {
    saveRechargeWallet,
    setEmailChange,
    setAmountChange,
    setNameChange,
    setCheckBoxChange,
    rechargeWalletData,
    rechargeWalletDataFormSettings,
  } = useRechargeWalletState(props);
  const navigate = useNavigate();
  const { currency, icon, iconHeight, minimalAmount, transferTitle } =
    rechargeWalletDataFormSettings;

  return (
    <>
      <FormWrapper>
        <Grid container>
          <Grid item sx={styles.headerContent}>
            <Box className={"title-box"}>
              <IconTransferCurve
                color="primary"
                fontSize="medium"
              ></IconTransferCurve>
              <Typography className={"title-text"} variant="h3">
                {transferTitle}
              </Typography>
            </Box>
            <Box className={"img-box"}>
              <img height={iconHeight} src={icon} alt="Image Processor" />
            </Box>
          </Grid>
          <Grid item md={8} xs={12} sx={styles.dataInputGrid}>
            <Typography variant="h6" className={"data-input-title"}>
              {RECHARGE_DATA}
            </Typography>
            <Box sx={styles.rechargeValueBox}>
              <Typography variant="body2" color="text.grey">
                {RECHARGE_QUESTION}
              </Typography>
              <TextField
                className={"recharge-value-input"}
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{currency}</InputAdornment>
                  ),
                }}
                onChange={setAmountChange}
                error={
                  rechargeWalletData.amountError ||
                  rechargeWalletData.minimalAmountError
                }
                helperText={
                  rechargeWalletData.amountError
                    ? REQUIRED_INPUT
                    : rechargeWalletData.minimalAmountError &&
                      MINIMAL_MOUNT_ERROR + minimalAmount + " " + currency + "."
                }
                placeholder={minimalAmount.toString()}
                value={rechargeWalletData.amount}
              />
            </Box>
          </Grid>
          <Grid item md={8} xs={12} sx={styles.dataInputGrid}>
            <Typography variant="h6" className={"data-input-title"}>
              {PERSONAL_DATA}
            </Typography>
            <TextField
              placeholder={NAME_PLACEHOLDER}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconUser color="secondary" fontSize="medium" />
                  </InputAdornment>
                ),
              }}
              onChange={setNameChange}
              error={rechargeWalletData.nameError}
              helperText={rechargeWalletData.nameError && REQUIRED_INPUT}
              defaultValue={localStorage.getItem("fullName")}
            />
            <Box marginBottom={"25px"}></Box>
            <TextField
              placeholder={EMAIL_PLACEHOLDER}
              variant="outlined"
              fullWidth
              type="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconEnvelope color="secondary" fontSize="medium" />
                  </InputAdornment>
                ),
              }}
              onChange={setEmailChange}
              error={
                rechargeWalletData.emailError ||
                rechargeWalletData.emailValueError
              }
              helperText={
                rechargeWalletData.emailError
                  ? REQUIRED_INPUT
                  : rechargeWalletData.emailValueError && EMAIL_VALUE_ERROR
              }
              defaultValue={localStorage.getItem("email")}
            />
          </Grid>
          <Grid item md={8} xs={12} sx={styles.dataContentGrid}>
            <FormControl
              sx={
                rechargeWalletData.checkBoxError
                  ? styles.checkBoxControlError
                  : styles.checkBoxControl
              }
              required
              error={rechargeWalletData.checkBoxError}
              component="fieldset"
              variant="standard"
            >
              <FormControlLabel
                className={"label-control"}
                control={
                  <Checkbox
                    checkedIcon={
                      <IconSquareCheck
                        color="primary"
                        fontSize="medium"
                      ></IconSquareCheck>
                    }
                    defaultValue="false"
                    color="primary"
                    onChange={setCheckBoxChange}
                  />
                }
                label={ACCEPT_CONDITIONS_MESSAGE}
              />
              {rechargeWalletData.checkBoxError && (
                <FormHelperText sx={styles.checkBoxErrorText}>
                  {ACCEPT_CONDITIONS_ERROR}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <LogoVerifiedBy />
          </Grid>
        </Grid>
      </FormWrapper>
      <FooterButtonsWrapper>
        <>
          <Button
            size="small"
            sx={styles.cancelButton}
            onClick={() => navigate(RouteEnum.DASHBOARD)}
          >
            {CANCEL_BUTTON}
          </Button>
          <Button
            size="small"
            sx={styles.rechargeButton}
            type={"submit"}
            onClick={saveRechargeWallet}
          >
            {RECHARGE_BUTTON}
          </Button>
        </>
      </FooterButtonsWrapper>
    </>
  );
};
