import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { dispatch } from "../../store";
import { SearchChargebackRequest } from "../../../../types/search_chargeback_request";
import {
  setIsWaitingForRequest,
  setNotification,
  setNotificationStatus,
  setSearchChargebackResponse,
  setSearchingChargeback,
  setSetIsLoadingStatus,
} from "../../actions/chargeback.actions";
import { ChargebackData } from "../../../../types/chargeback_data";
import { cloneDeep, has, unset } from "lodash";
import {
  ChargebackResultEnum,
  messageEnum,
} from "../../../shared/enums/ConfigEnum";
import { UploadFilesRequest } from "../../../containers/ChargebackManageFiles/state/useChargebackManageFiles.interfaces";
import { SearchRetrieveChildRequest } from "../../../../types/search_retrive_child_request";
import { SearchRetrieveChildResponse } from "../../../../types/search_retrive_child_response";
import {
  setIsWaitingForRequestChild,
  setRetrieveChildRequest,
  setRetrieveChildResponse,
} from "../../actions/retrieveChild.actions";
import { getMerchantIdsRequest } from "../../../shared/utils/transaction_list_utils";
import { isKushkiUser } from "../../../shared/utils/roles";
import { UpdateChargebackStatusMassiveRequest } from "../../../../types/update_chargeback_status_massive_request";
import { setIsLoadingMassiveStatus } from "../../actions/app.actions";
import { ROUTES } from "../../../shared/constants/routes";

export const searchChargeback = createAsyncThunk<void, SearchChargebackRequest>(
  "chargeback/searchChargeback",
  async (request) => {
    dispatch(setSearchingChargeback(true));
    try {
      const isAdmin: boolean = isKushkiUser();
      const url: string = isAdmin
        ? API_ROUTES.SEARCH_CHARGEBACK_ADMIN
        : API_ROUTES.SEARCH_CHARGEBACK;

      const headers = cloneDeep(request).headers;
      const body = cloneDeep(request);

      unset(body, "headers");
      unset(body, "isAdmin");

      const response = await axios.post<ChargebackData>(
        url,
        body,
        isAdmin
          ? {}
          : {
              headers: { ...headers },
            }
      );

      const is_mongo: boolean = has(response, "data.data.[0]._source._id");

      dispatch(setSearchChargebackResponse({ data: response.data, is_mongo }));
      isAdmin &&
        !is_mongo &&
        dispatch(setRetrieveChildRequest(getMerchantIdsRequest(response.data)));
    } catch (e) {}
    dispatch(setSearchingChargeback(false));
  }
);

export const updateChargebackStatus = createAsyncThunk<
  void,
  {
    status: string;
    id: string;
    searchChargebackRequest: SearchChargebackRequest;
  }
>("chargeback/updateChargebackStatus", async (request) => {
  dispatch(setSetIsLoadingStatus(true));
  try {
    await axios.patch<{
      status: string;
      id: string;
    }>(`${API_ROUTES.CHARGEBACK_STATUS}${request.id}`, {
      status: request.status,
    });

    dispatch(setSetIsLoadingStatus(false));

    dispatch(
      setNotificationStatus({
        color: "success",
        message: messageEnum.SUCCESS,
        variant: "simple",
      })
    );

    dispatch(searchChargeback(request.searchChargebackRequest));
  } catch (e) {
    dispatch(setSetIsLoadingStatus(false));
    dispatch(
      setNotificationStatus({
        color: "danger",
        message: messageEnum.ERROR,
        variant: "simple",
      })
    );
  }
});

export const searchRetrieveChild = createAsyncThunk(
  "chargeback/retrieveChild",
  async ({
    request,
    type,
    isWating,
  }: {
    request: SearchRetrieveChildRequest;
    type: string;
    isWating: boolean;
  }) => {
    dispatch(setSearchingChargeback(true));
    try {
      const response = await axios.post<SearchRetrieveChildResponse>(
        API_ROUTES.RETRIEVE_CHILD,
        {
          ...request,
        }
      );

      dispatch(setRetrieveChildResponse(response.data));
      if (type === "retriveChild")
        dispatch(setIsWaitingForRequestChild(!isWating));
    } catch (e) {}
    dispatch(setSearchingChargeback(false));
  }
);

export const uploadChargebackFiles = createAsyncThunk<void, UploadFilesRequest>(
  "uploadFiles/uploadChargebackFiles",
  async (request) => {
    dispatch(setIsWaitingForRequest(true));

    try {
      await axios.post<UploadFilesRequest>(API_ROUTES.UPLOAD_FILES, {
        ...request,
      });
      dispatch(
        setNotification({
          action: ChargebackResultEnum.CHARGEBACK_REQUEST_SUCCESS,
          message: "Se cargó el archivo con éxito",
          open: true,
        })
      );
    } catch (e) {}
    dispatch(setIsWaitingForRequest(false));
    // @ts-ignore
    window.location.href = ROUTES.CHARGEBACK;
  }
);

export const updateChargebackStatusMassive = createAsyncThunk<
  void,
  UpdateChargebackStatusMassiveRequest
>("chargeback/updateChargebackStatusMassive", async (request) => {
  dispatch(setIsLoadingMassiveStatus(true));

  try {
    await axios.post<UploadFilesRequest>(
      API_ROUTES.UPDATE_CHARGEBACK_STATUS_MASSIVE,
      {
        ...request,
      }
    );
    dispatch(setIsLoadingMassiveStatus(false));
    dispatch(
      setNotificationStatus({
        color: "success",
        message: messageEnum.STATUS_MASSIVE_UPDATE_SUCCESS,
        variant: "simple",
      })
    );
    setTimeout(() => {
      window.location.href = ROUTES.CHARGEBACK;
    }, 1000);
  } catch (e) {
    dispatch(setIsLoadingMassiveStatus(false));
    dispatch(
      setNotificationStatus({
        color: "danger",
        message: messageEnum.STATUS_MASSIVE_UPDATE_ERROR,
        variant: "simple",
      })
    );
    setTimeout(() => {
      window.location.href = ROUTES.CHARGEBACK;
    }, 1000);
  }
});
