import { CountryEnum } from "../countryEnum";
import { Category, tipoIndustria } from "./BasicStateGeneral/CatalogBasicData";
import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "./BasicStateGeneral/FieldsBasicData";
import {
  companyTypeHN,
  TypeOfTaxIdListHN,
} from "./BasicStateHN/CatalogBasicDataHN";
import { tipoEmpresaMX } from "./BasicStateMX/CatalogBasicDataMX";
import {
  fieldsRender,
  PersonTypeMXlist,
} from "./BasicStateMX/FieldsBasicDataMX";
import { tipoEmpresaPE } from "./BasicStatePE/CatalogBasicDataPE";
import {
  fieldsRenderPE,
  PersonTypePEList,
} from "./BasicStatePE/FieldsBasicDataPE";
import {
  fieldsRenderCR,
  PersonTypeCRList,
} from "./BasicStateCR/FieldsBasicDataCR";
import {
  tipoEmpresaEC,
  TypeOfTaxIdList,
} from "./BasicStateEC/CatalogBasicDataEC";
import {
  fieldsRenderEC,
  PersonTypeEClist,
} from "./BasicStateEC/FieldsBasicDataEC";
import { tipoEmpresaBra } from "./BasicStateBRA/CatalogBasicDataBRA";
import {
  fieldsRenderBra,
  PersonTypeBRAlist,
} from "./BasicStateBRA/FieldsBasicDataBRA";
import { companyTypeCHI } from "./BasicStateCHI/CatalogBasicDataCHI";
import {
  fieldRenderCHI,
  PersonTypeCHIList,
} from "./BasicStateCHI/FieldsBasicDataCHI";
import { tipoEmpresaCR } from "./BasicStateCR/CatalogDataCR";
import { companyTypeCo } from "./BasicStateCo/CatalogBasicDataCo";
import {
  fieldsRenderCO,
  PersonTypeColist,
} from "./BasicStateCo/FieldsBasicDataCo";
import { companyTypePAN } from "./BasicStatePAN/CatalogBasicDataPAN";
import {
  fieldRenderPAN,
  PersonTypePANList,
} from "./BasicStatePAN/FieldsBasicDataPAN";
import { tipoEmpresaUSA } from "./BasicStateUSA/CatalogDataUSA";
import {
  docTypeUSA,
  fieldsRenderUSA,
  PersonTypeUSAlist,
} from "./BasicStateUSA/FieldsBasicDataUSA";
import {
  fieldsRenderHN,
  PersonTypeHNList,
} from "./BasicStateHN/FieldsBasicDataHN";
import { tipoEmpresaNI } from "./BasicStateNI/CatalogDataNI";
import {
  fieldsRenderNI,
  PersonTypeNIList,
} from "./BasicStateNI/FieldsBasicDataNI";
import { tipoEmpresaSV } from "./BasicStateSV/CatalogDataSV";
import { tipoEmpresaGeneric } from "./BasicStateGeneric/CatalogDataGeneric";
import {
  fieldsRenderGeneric,
  PersonTypeGenericList,
} from "./BasicStateGeneric/FieldsBasicDataGeneric";
import {
  fieldsRenderSV,
  PersonTypeSVList,
} from "./BasicStateSV/FieldsBasicDataSV";
import { fieldsRenderGUA } from "./BasicStateGUA/FieldsBasicDataGUA";
import {
  companyTypeGUA,
  docTypeGUA,
} from "./BasicStateGUA/CatalogBasicDataGUA";

export interface BasicDataByCountryElements {
  companyType: Array<string>;
  mcc?: Array<Category>;
  industryType: Array<string>;
  fieldsRender: Array<PersonTypeInterface>;
  radioListPersons: Array<PersonTypeObjectInterface>;
  typeOfTaxId?: Array<string> | Array<Category>;
  retentionICA?: Array<Category>;
}

export const BasicDataByCountry: Record<string, BasicDataByCountryElements> = {
  [CountryEnum.mexico]: {
    companyType: tipoEmpresaMX,
    fieldsRender: fieldsRender,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeMXlist,
  },

  [CountryEnum.peru]: {
    companyType: tipoEmpresaPE,
    fieldsRender: fieldsRenderPE,
    industryType: tipoIndustria,
    radioListPersons: PersonTypePEList,
  },
  [CountryEnum.costa_rica]: {
    companyType: tipoEmpresaCR,
    fieldsRender: fieldsRenderCR,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeCRList,
  },

  [CountryEnum.ecuador]: {
    companyType: tipoEmpresaEC,
    fieldsRender: fieldsRenderEC,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeEClist,
    typeOfTaxId: TypeOfTaxIdList,
  },

  [CountryEnum.brasil]: {
    companyType: tipoEmpresaBra,
    fieldsRender: fieldsRenderBra,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeBRAlist,
  },
  [CountryEnum.chile]: {
    companyType: companyTypeCHI,
    fieldsRender: fieldRenderCHI,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeCHIList,
  },

  [CountryEnum.nicaragua]: {
    companyType: tipoEmpresaNI,
    fieldsRender: fieldsRenderNI,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeNIList,
  },

  [CountryEnum.el_salvador]: {
    companyType: tipoEmpresaSV,
    fieldsRender: fieldsRenderSV,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeSVList,
  },

  [CountryEnum.panama]: {
    companyType: companyTypePAN,
    fieldsRender: fieldRenderPAN,
    industryType: tipoIndustria,
    radioListPersons: PersonTypePANList,
  },

  [CountryEnum.usa]: {
    companyType: tipoEmpresaUSA,
    fieldsRender: fieldsRenderUSA,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeUSAlist,
    typeOfTaxId: docTypeUSA,
  },

  [CountryEnum.colombia]: {
    companyType: companyTypeCo,

    fieldsRender: fieldsRenderCO,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeColist,
  },

  [CountryEnum.guatemala]: {
    companyType: companyTypeGUA,
    fieldsRender: fieldsRenderGUA,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeMXlist,
    typeOfTaxId: docTypeGUA,
  },

  [CountryEnum.honduras]: {
    companyType: companyTypeHN,
    fieldsRender: fieldsRenderHN,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeHNList,
    typeOfTaxId: TypeOfTaxIdListHN,
  },

  [CountryEnum.generic]: {
    companyType: tipoEmpresaGeneric,
    fieldsRender: fieldsRenderGeneric,
    industryType: tipoIndustria,
    radioListPersons: PersonTypeGenericList,
  },
};

export const europeCountriesPSPCatalog: CountryEnum[] = [
  CountryEnum.ALBANIA,
  CountryEnum.ALEMANIA,
  CountryEnum.ANDORRA,
  CountryEnum.ARMENIA,
  CountryEnum.AUSTRIA,
  CountryEnum.AZERBAIYAN,
  CountryEnum.BELGICA,
  CountryEnum.BIELORRUSIA,
  CountryEnum.BOSNIA_Y_HERZEGOVIA,
  CountryEnum.BULGARIA,
  CountryEnum.CHIPRE,
  CountryEnum.CIUDAD_DEL_VATICANO,
  CountryEnum.CROACIA,
  CountryEnum.DINAMARCA,
  CountryEnum.ESLOVAQUIA,
  CountryEnum.ESLOVENIA,
  CountryEnum.ESPANIA,
  CountryEnum.ESTONIA,
  CountryEnum.FINLANDIA,
  CountryEnum.FRANCIA,
  CountryEnum.GEORGIA,
  CountryEnum.GIBRALTAR,
  CountryEnum.GRECIA,
  CountryEnum.GUERNSEY,
  CountryEnum.HUNGRIA,
  CountryEnum.ISLA_DE_MAN,
  CountryEnum.ISLANDIA,
  CountryEnum.ISLAS_DE_ALAND,
  CountryEnum.ISLAS_FEROE,
  CountryEnum.ITALIA,
  CountryEnum.JERSEY,
  CountryEnum.KAZAJISTAN,
  CountryEnum.KIRGUISTAN,
  CountryEnum.LETONIA,
  CountryEnum.LIECHTENSTEIN,
  CountryEnum.LITUANIA,
  CountryEnum.LUXEMBURGO,
  CountryEnum.MONACO,
  CountryEnum.MACEDONIA,
  CountryEnum.MALTA,
  CountryEnum.MOLDAVIA,
  CountryEnum.NORUEGA,
  CountryEnum.PAISES_BAJOS,
  CountryEnum.POLONIA,
  CountryEnum.PORTUGAL,
  CountryEnum.REINO_UNIDO,
  CountryEnum.REPUBLICA_CHECA,
  CountryEnum.RUMANIA,
  CountryEnum.RUSIA,
  CountryEnum.SAN_MARINO,
  CountryEnum.SERBIA,
  CountryEnum.SUECIA,
  CountryEnum.SUIZA,
  CountryEnum.SVALBARD_Y_JAN_MAYEN,
  CountryEnum.TURQUIA,
  CountryEnum.UCRANIA,
];
