import { BankEntityEnum } from "./BankEntityEnum";
import discover from "../../../assets/images/logos/discover.svg";
import jcb from "../../../assets/images/logos/jcb.svg";
import carnet from "../../../assets/images/logos/carnet.svg";
import newVisa from "../../../assets/images/logos/newVisa.svg";
import newMastercard from "../../../assets/images/logos/newMastercard.svg";
import amex from "../../../assets/images/logos/amex.svg";
import newDiners from "../../../assets/images/logos/newDiners.svg";
import { CountriesEnum } from "./CountriesEnum";

export const CARDS = {
  amex: {
    height: 24,
    source: amex,
    width: 50,
  },
  carnet: {
    height: 24,
    source: carnet,
    width: 45,
  },
  diners: {
    height: 26,
    source: newDiners,
    width: 37,
  },
  discover: {
    height: 24,
    source: discover,
    width: 45,
  },
  jcb: {
    height: 24,
    source: jcb,
    width: 45,
  },
  mastercard: {
    height: 38,
    source: newMastercard,
    width: 37,
  },
  visa: {
    height: 38,
    source: newVisa,
    width: 38,
  },
};

export const DEFAULT_CARDS = [
  CARDS.mastercard,
  CARDS.visa,
  CARDS.discover,
  CARDS.amex,
  CARDS.diners,
  CARDS.jcb,
];

export const CARDS_BY_ENTITY = new Map([
  [BankEntityEnum.AMEX, [CARDS.amex]],
  [BankEntityEnum.PROSA, [CARDS.visa, CARDS.mastercard, CARDS.carnet]],
  [BankEntityEnum.BBVA, [CARDS.visa, CARDS.mastercard]],
  [BankEntityEnum.CITIBANAMEX, [CARDS.visa, CARDS.mastercard]],
  [
    BankEntityEnum.DATAFAST,
    [CARDS.visa, CARDS.mastercard, CARDS.diners, CARDS.amex],
  ],
  [
    BankEntityEnum.CREDIMATIC,
    [CARDS.visa, CARDS.mastercard, CARDS.diners, CARDS.amex],
  ],
]);

export const CARDS_BY_COUNTRIES_DEFAULT = new Map([
  [CountriesEnum.COSTA_RICA, DEFAULT_CARDS],
  [CountriesEnum.PANAMA, [CARDS.mastercard, CARDS.visa, CARDS.amex]],
]);
