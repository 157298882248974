import React from "react";
import { Grid, Typography } from "@mui/material";
import { merchantPeopleHeaderStyles } from "./MerchantPeopleHeader.styles";
import { IMerchantBillingProps } from "./MerchantPeopleHeader.interfaces";

export const MerchantPeopleHeader: React.FC<IMerchantBillingProps> = (
  props: IMerchantBillingProps
) => (
  <React.Fragment>
    <Grid container spacing={1} sx={merchantPeopleHeaderStyles.padding}>
      <Grid
        item
        xs={6}
        md={6}
        container
        alignItems="center"
        justifyContent="flex-start"
        direction="row"
      >
        <Typography
          sx={merchantPeopleHeaderStyles.typography}
          color={"primary"}
          variant={"h2"}
        >
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  </React.Fragment>
);
