import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import legalDetailsReducer from "./reducers/legalDetails/legalDetails";
import commercialExecutiveReducer from "./reducers/commercialExecutiveList/commercialExecutiveList.slice";
import contactDataReducer from "./reducers/contactData/contactData";
import merchantCompanyReducer from "./reducers/merchantBillingData/merchantBillingData.slice";
import basicDataFormReducer from "./reducers/basicDataForm/basicDataForm.slice";
import initialDataReducer from "./reducers/initialData/initialData.slice";
import semaphoreInfo from "./reducers/semaphore/semaphore.slice";
import merchantComplianceReducer from "./reducers/merchantComplianceData/merchantComplianceData.slice";
import massiveBranchReducer from "./reducers/massiveBranch/massiveBranch.slice";
import massiveMerchantReducer from "./reducers/massiveMerchant/massiveMerchant";
import catalogsReducer from "./reducers/catalogs/catalogs.slice";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    basicDataForm: basicDataFormReducer,
    branchBatch: massiveBranchReducer,
    catalogs: catalogsReducer,
    commercialExecutiveList: commercialExecutiveReducer,
    contactData: contactDataReducer,
    initialData: initialDataReducer,
    legalDetails: legalDetailsReducer,
    massiveBranch: massiveMerchantReducer,
    merchantCompany: merchantCompanyReducer,
    merchantComplianceData: merchantComplianceReducer,
    semaphoreData: semaphoreInfo,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
