import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FileUploadContainerStyles as styles } from "../FileUploadContainer.styles";
import { FileUploadProps } from "../FileUploadContainer.interfaces";
import FileTextIcon from "../../../assets/icons/FileTextIcon";
import {
  FileUploadLabels,
  MAX_SIZE,
} from "@shared/constants/labels/fileUploadLabels";

const FileUpload: React.FC<FileUploadProps> = ({
  handleDrag,
  handleDrop,
  handleChange,
  dragActive,
  size,
}: FileUploadProps) => {
  return (
    <Box onDragEnter={handleDrag} sx={styles.fileUploadContainer}>
      <Box sx={styles.uploadContainer}>
        <Box sx={styles.dragUpload}>
          <FileTextIcon />
        </Box>
        <Button variant="outlined" component="label" color={"secondary"}>
          {FileUploadLabels.ADD_FILE}
          <input hidden multiple type="file" onChange={handleChange} />
        </Button>
        <Box sx={styles.fileDescription}>
          <Typography sx={styles.validFormat}>
            {FileUploadLabels.FIND_DOCUMENT_1}&quot;{FileUploadLabels.ADD_FILE}
            &quot;{FileUploadLabels.FIND_DOCUMENT_2}
          </Typography>
          <Typography sx={styles.validFormat}>
            {size && MAX_SIZE(size)}
          </Typography>
        </Box>
      </Box>
      {dragActive && (
        <Box
          component="div"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          sx={styles.dragContainer}
        ></Box>
      )}
    </Box>
  );
};

export default FileUpload;
