import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    cardTypeHeader: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      height: 100,
      margin: "auto",
      width: 450,
    },
    circular: {
      color: "#F0F4F9",
    },
    content: {
      alignContent: "left",
      display: "block",
      padding: "26px 0px 0 0",
    },
    div: {
      position: "relative",
    },
    gridCircular: {
      padding: "20px 24px 20px 28px",
    },
    gridContent: {
      alignContent: "center",
    },
    modal: {
      alignContent: "center",
      display: "flex",
      justifyContent: "center",
    },
    subTitle: {
      color: "#293036",
      fontSize: 14,
      fontStyle: "normal",
    },
    title: {
      color: "#023365",
      fontFamily: "IBM Plex Sans,sans-serif",
      fontSize: 17,
      fontStyle: "normal",
      fontWeight: "bold",
    },
    top: {
      fontFamily: "IBM Plex Sans,sans-serif",
      left: 0,
      position: "absolute",
    },
  })
);

export const Loader: React.FC = () => {
  const classes = useStyles();

  return (
    <Modal
      open={true}
      className={classes.modal}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <Box justifyContent={"center"} className={classes.cardTypeHeader}>
        <Grid item md={3} className={classes.gridCircular}>
          <div className={classes.div}>
            <CircularProgress
              variant="determinate"
              size={60}
              thickness={5}
              value={100}
              className={classes.circular}
            />
            <CircularProgress
              size={60}
              color={"primary"}
              thickness={5}
              className={classes.top}
              value={10}
            />
          </div>
        </Grid>
        <Grid item md={9} className={classes.gridContent}>
          <Box className={classes.content}>
            <Typography className={classes.title}>
              Estamos cargando tu información
            </Typography>
            <Typography className={classes.subTitle}>
              Esto puede tardar un poco...
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};
