import { makeStyles } from "@material-ui/core/styles";
import { createStyles, StepIconProps } from "@material-ui/core";
import clsx from "clsx";
import { defaultTo, floor, get, isNil, set } from "lodash";
import { format, utcToZonedTime } from "date-fns-tz";
import NumberFormat from "react-number-format";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";
import React from "react";
import { timeZoneHour } from "../../shared/infrastructure/constants/timeZoneHour";
import {
  GetCustomStepBackground,
  GetCustomStepIcon,
} from "../../shared/infrastructure/constants/StepIconStyles";
import { InfoTrxProps } from "./ModalBodyTabs";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import {
  TabsTextConfigOptionsEnum,
  TabsTextOptionsEnum,
} from "../../shared/infrastructure/enum/TabsTextOptionsEnum";
import {
  CatalogRenderTab,
  ITabInfo,
} from "../../shared/infrastructure/CatalogRenderTab";
import { environment } from "../../environments/environment";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
      marginTop: "30px",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    linearProgress: {
      marginTop: 3,
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    margin: {
      marginTop: 10,
    },
    title: {
      color: "#46525C",
      lineHeight: "170%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
    },
    subtitle: {
      color: "#6D7781",
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
    },
    description: {
      color: "#023365",
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
    },
  })
);

export function CustomStepIcon(props: StepIconProps) {
  let iconType = props.icon;
  const icon: string = GetCustomStepIcon(iconType);
  const backgroundColor = GetCustomStepBackground(iconType);
  const useCustomStepIconStyles = makeStyles({
    root: {
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "4px",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundColor,
    },
  });
  const classes = useCustomStepIconStyles();

  return (
    <div className={clsx(classes.root, { [classes.active]: true })}>
      <img src={icon} alt={icon} />
    </div>
  );
}

export const formatDate = (
  trxDate: any,
  formatDate: string,
  isHistoric: boolean
): string => {
  let finalDate: string;
  try {
    let date: string;
    isHistoric
      ? (date = trxDate)
      : (date = new Date(
          trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
        ).toISOString());

    finalDate = format(new Date(date.replace("Z", "")), formatDate, {
      timeZone: "UTC",
    });
  } catch (e) {
    finalDate = "";
  }
  return finalDate;
};

export const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

const setUpdatedAt = (
  trx: InvoiceRecord | undefined,
  isHistoric: boolean
): InvoiceRecord => {
  if (trx !== undefined) {
    set(
      trx,
      getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, isHistoric),
      new Date(
        get(
          trx,
          getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, isHistoric),
          0
        ).toString().length === 10
          ? get(
              trx,
              getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, isHistoric),
              0
            ) * 1000
          : get(
              trx,
              getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, isHistoric),
              0
            )
      ).toISOString()
    );
  } else {
    trx = {};
  }
  return trx;
};

export const sortTrxList = (
  trxList: InvoiceRecord[],
  trxInfo: InvoiceRecord | undefined,
  isHistoric: boolean
): InvoiceRecord[] => {
  let auxTrxList: InvoiceRecord[] = [];
  auxTrxList = auxTrxList.concat(trxList);
  trxInfo = setUpdatedAt(trxInfo, isHistoric);

  for (let i in auxTrxList) {
    auxTrxList[i] = setUpdatedAt(auxTrxList[i], false);
  }

  auxTrxList.sort((trx1: InvoiceRecord, trx2: InvoiceRecord) => {
    let updatedTrx2 = get(
      trx2,
      getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, false),
      ""
    );

    let updatedTrx1 = get(
      trx1,
      getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, false),
      ""
    );

    return updatedTrx2 < updatedTrx1 ? -1 : updatedTrx2 > updatedTrx1 ? 1 : 0;
  });
  auxTrxList.unshift(defaultTo(trxInfo, {}));
  return auxTrxList;
};

export const timeZoneRegion = timeZoneHour(
  localStorage.getItem("timeZone")!,
  "region"
);

export const validateDate = (date: string): string => {
  if (date)
    return format(
      utcToZonedTime(date, timeZoneRegion),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  else return "yyyy-MM-dd'T'HH:mm:ss";
};

export const getCatalogInfo = (props: InfoTrxProps): ITabInfo[] => {
  let catalogInfo: ITabInfo[] = [];
  const kind_path: string = props.isHistoric ? "kind" : "transaction.kind";
  const status_flow_path: string = props.isHistoric
    ? "status_flow"
    : "transaction.statusFlow";
  const catalogTabs: object = get(
    CatalogRenderTab,
    `[${get(props.trxInfo, kind_path, "")}][${get(
      props.trxInfo,
      status_flow_path,
      ""
    )}]`,
    []
  );

  Object.keys(catalogTabs).map((key: string) => {
    if (key.split("|").includes(defaultTo(props.country, CountryEnum.ecuador)))
      catalogInfo = catalogTabs[key];
  });

  return catalogInfo;
};

export const getEmptyTitleByKind = (kind: string, isObservation: boolean) => {
  if (isObservation)
    switch (kind) {
      case KindEnum.DISPERSION:
        return TabsTextOptionsEnum.observationEmptyDispersionTitle;
      case KindEnum.INVOICE:
        return TabsTextOptionsEnum.observationEmptyInvoiceTitle;
      case KindEnum.VOUCHER:
        return TabsTextOptionsEnum.observationEmptyVoucherTitle;
      default:
        return TabsTextOptionsEnum.observationEmptyChargeTitle;
    }
  else
    switch (kind) {
      case KindEnum.INVOICE:
        return TabsTextConfigOptionsEnum.configEmptyInvoiceTitle;
      case KindEnum.VOUCHER:
        return TabsTextConfigOptionsEnum.configEmptyVoucherTitle;
      default:
        return TabsTextConfigOptionsEnum.configEmptyChargeTitle;
    }
};

export const getAdvanceSettings = (trxProps: InvoiceRecord) => {
  if (
    !isNil(get(trxProps, "advanceSettingsReference")) ||
    !isNil(get(trxProps, "advanceSettingsParameters"))
  ) {
    return {
      advanceSettingsReference: get(trxProps, "advanceSettingsReference"),
      advanceSettingsParameters: get(trxProps, "advanceSettingsParameters"),
    };
  } else if (
    !isNil(get(trxProps, "advance_settings_reference")) ||
    !isNil(get(trxProps, "advance_settings_parameters"))
  ) {
    return {
      advanceSettingsReference: get(trxProps, "advance_settings_reference"),
      advanceSettingsParameters: get(trxProps, "advance_settings_parameters"),
    };
  } else
    return {
      advanceSettingsReference: get(
        trxProps,
        "transaction.modify.advanceSettingsReference"
      ),
      advanceSettingsParameters: get(
        trxProps,
        "transaction.modify.advanceSettingsParameters"
      ),
    };
};

export const getIvaByCountry = (country: string): string => {
  switch (country) {
    case CountryEnum.colombia:
      return environment.ivaColombia;
    case CountryEnum.mexico:
      return environment.ivaMexico;
    case CountryEnum.peru:
      return environment.ivaPeru;
    case CountryEnum.chile:
      return environment.ivaChile;
    default:
      return environment.ivaEC;
  }
};

export const getIvaValue = (amount: number, country: string): number => {
  const iva_value: number = Number(getIvaByCountry(country)) / 100;
  const ivaAmount: number = amount * iva_value;
  return country === CountryEnum.colombia
    ? floor(ivaAmount, 2)
    : Number(ivaAmount.toFixed(2));
};
