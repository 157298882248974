import React, { FC } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {
  informationFormStyles,
  informationFormStyles as styles,
} from "./InformationCustomerForm.styles";
import { INFORMATION_CUSTOMER_LABELS } from "../../shared/constants/labels/information_customer_container_labels";
import { ModalLoader, ModalSimple } from "@kushki/connect-ui";
import { Controller } from "react-hook-form";
import { FieldsCustomerEnum } from "../../shared/enums/FieldsCustomerEnum";
import SelectItemComplete from "../SelectItemComplete/SelectItemComplete";
import { CLIENT_TYPE } from "../../shared/infrastructure/ClientType";
import { COUNTRIES } from "../../shared/infrastructure/Countries";
import { COUNTRIES_CONSTITUTIONAL } from "../../shared/infrastructure/CountriesConstitutional";
import { InfoOutlined } from "@mui/icons-material";
import {
  ERROR_MESSAGE_REQUIRED,
  ErrorMessageCustomerForm,
} from "../../shared/enums/ErrorMessageCustomerForm";
import { get, isEmpty } from "lodash";
import { useInformationCustomerForm } from "./state/useInformationCustomerForm";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { RegularExpEnum } from "../../shared/enums/RegularExpEnum";

const InformationCustomerForm: FC = () => {
  const {
    countryConstitutionCheck,
    editTitle,
    form,
    handleOpenEditModal,
    handleOnChangeCheckBox,
    handleOnClickBack,
    handleOnChange,
    handleOnClickContinue,
    handleContinueModal,
    isFromUrl,
    isOpenLoaderModal,
    modalOpenEdit,
    errorName,
  } = useInformationCustomerForm();

  const getMessage = (error: any, value: FieldsCustomerEnum) => {
    if (!isEmpty(get(error, "type", "")))
      if (
        get(error, "type") === "required" &&
        isEmpty(get(error, "message", ""))
      )
        return ERROR_MESSAGE_REQUIRED[value];
      else return get(form.formState.errors, value).message;

    return "";
  };

  return (
    <Box display={"flex"} flexDirection={"column"} sx={styles.boxContent}>
      <Typography sx={informationFormStyles.title} color={"primary"}>
        {isFromUrl ? editTitle : INFORMATION_CUSTOMER_LABELS.CUSTOMER_TITLE}
      </Typography>
      <Box component="ul" sx={informationFormStyles.boxUl} hidden={isFromUrl}>
        <Box component="li">{INFORMATION_CUSTOMER_LABELS.DESCRIPTION}</Box>
      </Box>
      <Typography
        sx={informationFormStyles.subtitle}
        color={"primary"}
        hidden={isFromUrl}
      >
        {INFORMATION_CUSTOMER_LABELS.CUSTOMER_SUBTITLE_CREATE}
      </Typography>
      <Box sx={informationFormStyles.bodyStyle}>
        <Controller
          name={FieldsCustomerEnum.commercialName}
          control={form.control}
          rules={{
            maxLength: {
              message: ErrorMessageCustomerForm.COMMERCIAL_NAME_LENGTH,
              value: 50,
            },
            required: ErrorMessageCustomerForm.COMMERCIAL_NAME,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              defaultValue={form.watch(FieldsCustomerEnum.commercialName)}
              label={INFORMATION_CUSTOMER_LABELS.CUSTOMER_COMMERCIAL_TEXTBOX}
              id="outlined-basic"
              variant="outlined"
              helperText={getMessage(
                get(form.formState.errors, FieldsCustomerEnum.commercialName),
                FieldsCustomerEnum.commercialName
              )}
              error={
                !!get(
                  form.formState.errors,
                  FieldsCustomerEnum.commercialName,
                  null
                )
              }
              sx={informationFormStyles.containerBox}
              onChange={(e) => {
                if (
                  e.target.value === "" ||
                  new RegExp(RegularExpEnum.CAPITAL_LETTER_NUMBERS).test(
                    e.target.value.toUpperCase()
                  )
                ) {
                  field.onChange(e.target.value.toUpperCase());
                  handleOnChange();
                }
              }}
            />
          )}
        />
        {errorName && (
          <FormHelperText sx={informationFormStyles.error}>
            {ErrorMessageCustomerForm.ERROR_NAME}
          </FormHelperText>
        )}
      </Box>
      <Box
        sx={informationFormStyles.bodyStyle}
        data-testid={FieldsCustomerEnum.clientType}
      >
        <Controller
          name={FieldsCustomerEnum.clientType}
          control={form.control}
          rules={{ required: ErrorMessageCustomerForm.CLIENT_TYPE }}
          render={({ field }) => (
            <SelectItemComplete
              items={CLIENT_TYPE}
              label={INFORMATION_CUSTOMER_LABELS.CLIENT_TYPE}
              handleOnChange={(e) => field.onChange(e.target.value)}
              helperText={
                get(form.formState.errors, FieldsCustomerEnum.clientType)
                  ?.message ?? ""
              }
              hasError={
                !!get(
                  form.formState.errors,
                  FieldsCustomerEnum.clientType,
                  null
                )
              }
              value={form.watch(FieldsCustomerEnum.clientType)}
              disable={false}
              isCountry={false}
            />
          )}
        />
      </Box>
      <Box
        sx={informationFormStyles.bodyStyle}
        data-testid={FieldsCustomerEnum.operationCountry}
      >
        <Controller
          name={FieldsCustomerEnum.operationCountry}
          control={form.control}
          rules={{ required: ErrorMessageCustomerForm.OPERATION_COUNTRY }}
          render={({ field }) => (
            <SelectItemComplete
              items={COUNTRIES}
              label={INFORMATION_CUSTOMER_LABELS.COUNTRY_OPERATION}
              handleOnChange={field.onChange}
              helperText={
                get(form.formState.errors, FieldsCustomerEnum.operationCountry)
                  ?.message ?? ""
              }
              hasError={
                !!get(
                  form.formState.errors,
                  FieldsCustomerEnum.operationCountry,
                  null
                )
              }
              value={form.watch(FieldsCustomerEnum.operationCountry)}
              disable={isFromUrl}
              isCountry
            />
          )}
        />
      </Box>

      {!isFromUrl && (
        <Box sx={informationFormStyles.containerCheckBox}>
          <Checkbox
            sx={informationFormStyles.checkBox}
            onChange={(event) => handleOnChangeCheckBox(event.target.checked)}
            checked={countryConstitutionCheck}
          />
          <Typography
            sx={informationFormStyles.checkBoxLabel}
            color={"primary"}
          >
            {INFORMATION_CUSTOMER_LABELS.CHECKBOX_COUNTRY_CONSTITUTION_LABEL}
          </Typography>
        </Box>
      )}
      {!countryConstitutionCheck && (
        <Box
          sx={informationFormStyles.bodyStyle}
          data-testid={FieldsCustomerEnum.constitutionCountry}
        >
          <Controller
            name={FieldsCustomerEnum.constitutionCountry}
            control={form.control}
            render={({ field }) => (
              <SelectItemComplete
                items={COUNTRIES_CONSTITUTIONAL}
                label={INFORMATION_CUSTOMER_LABELS.COUNTRY_CONSTITUTION}
                helperText={getMessage(
                  get(
                    form.formState.errors,
                    FieldsCustomerEnum.constitutionCountry
                  ),
                  FieldsCustomerEnum.constitutionCountry
                )}
                hasError={
                  !!get(
                    form.formState.errors,
                    FieldsCustomerEnum.constitutionCountry,
                    null
                  )
                }
                handleOnChange={field.onChange}
                value={form.watch(FieldsCustomerEnum.constitutionCountry)}
                disable={isFromUrl}
                isCountry
              />
            )}
          />
        </Box>
      )}

      {isFromUrl && (
        <Box sx={informationFormStyles.containerInformation}>
          <InfoOutlined />
          <Typography sx={informationFormStyles.information} color={"primary"}>
            {INFORMATION_CUSTOMER_LABELS.COUNTRY_CHANGE_INFORMATION}
          </Typography>
        </Box>
      )}

      {!isFromUrl && (
        <Box sx={informationFormStyles.containerInformation}>
          <InfoOutlined />
          <Typography sx={informationFormStyles.information} color={"primary"}>
            {INFORMATION_CUSTOMER_LABELS.CUSTOMER_INFORMATION}
          </Typography>
        </Box>
      )}
      <Box sx={informationFormStyles.containerButton}>
        <Button
          sx={informationFormStyles.backButton}
          variant={"outlined"}
          onClick={handleOnClickBack}
        >
          {INFORMATION_CUSTOMER_LABELS.BACK_BUTTON_LBL}
        </Button>
        <ComponentSecurityWrapper
          componentId={ComponentIdsEnum.M_CLIENTS_CREATE_CUSTOMER}
        >
          <Button
            disabled={!form.formState.isValid}
            sx={informationFormStyles.nextButton}
            variant={"contained"}
            onClick={handleOnClickContinue}
          >
            {INFORMATION_CUSTOMER_LABELS.BUTTON_CONTINUE}
          </Button>
        </ComponentSecurityWrapper>
      </Box>
      <ModalLoader
        titleText={INFORMATION_CUSTOMER_LABELS.LOADER_MODAL_TITLE}
        descriptionText={INFORMATION_CUSTOMER_LABELS.LOADER_MODAL_DESCRIPTION}
        isOpen={isOpenLoaderModal}
      />
      <Box>
        <ModalSimple
          onClickPrimary={handleContinueModal}
          onClickSecondary={() => {
            handleOpenEditModal(false);
          }}
          buttonPrimary={"Si, cambiar"}
          buttonSecondary={"No, cancelar"}
          descriptionText={INFORMATION_CUSTOMER_LABELS.MODAL_EDIT_BODY}
          isOpen={modalOpenEdit}
          onClose={() => {
            handleOpenEditModal(false);
          }}
          titleText={INFORMATION_CUSTOMER_LABELS.MODAL_EDIT_TITLE}
          typeModal={1}
          buttonPrimaryDisabled={false}
          tagColor={"error"}
        />
      </Box>
    </Box>
  );
};

export default InformationCustomerForm;
