import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/utils/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SemaphoreData } from "../../../../types/semaphore_request_response";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { GetBusinessRulesRequest } from "../../../../types/get_business_rules_request";
import { GetBusinessRulesResponse } from "../../../../types/get_business_rules_response";
import { UpdateConfigHierarchyRequest } from "../../../../types/update_config_hierarchy_request";
import { BusinessConfiguration } from "../../../../types/business_configuration";
import {
  DeferredMerchantAdminResponse,
  DeferredOptions,
} from "../../../../types/get_deferred_admin_merchant_response";
import { forEach, get, isEmpty, unset } from "lodash";
import { DeferredConfigResponse } from "../../../../types/get_deferred_config_response";
import { DeferredConfigRequest } from "../../../../types/get_deferred_config_request";
import { GetMerchantOptionsResponse } from "../../../../types/get_merchant_options_response";
import { UpdateMerchantRequest } from "../../../../types/update_merchant_request";
import { AxiosResponse } from "axios";
import { GetProcessorResponse } from "../../../../types/get_processor_response";
import { GetMerchantInformation } from "../../../../types/get_merchant_information";
import { GetDefaultProcessorResponse } from "../../../../types/get_default_processor_response";
import {
  getBusinessRulesBatch,
  getPayoutsBusinessRulesBatch,
  setTimeoutPromise,
} from "../../../shared/utils/local-storage/massive-rules-information";
import { MassiveRulesRequest } from "../../../../types/massive_rules_request";
import { ICentralizeNodeResponse } from "../../../shared/infrastructure/interfaces/ICentralizeNodeResponse";
import { ICentralizeNodeRequest } from "../../../shared/infrastructure/interfaces/ICentralizeNodeRequest";
import { ICentralizeProcessorsRequest } from "../../../shared/infrastructure/interfaces/ICentralizeProcessorsRequest";
import { ICentralizeProcessorsResponse } from "../../../shared/infrastructure/interfaces/ICentralizeProcessorsResponse";
import { UpdateMerchantMassiveRequest } from "../../../../types/update_merchant_massive_request";
import { GetAppConfigPaymentMethodsResponse } from "../../../../types/get_app_config_payment_method_response";
import { GetDefaultPayoutProcessorResponse } from "../../../../types/get_default_payout_processor_response";
import { GetPayoutsBusinessRulesRequest } from "../../../../types/get_payouts_business_rules_request";
import { GetPayoutsBusinessRulesResponse } from "../../../../types/get_payouts_business_rules_response";
import { StatusRuleEnum } from "../../../shared/enums/StatusRuleEnum";

const getCardAdminMerchant = createAsyncThunk<
  GetMerchantOptionsResponse,
  { merchantId: string }
>("layout/getCardAdminMerchant", async ({ merchantId }) => {
  const response = await axios.get<GetMerchantOptionsResponse>(
    API_ROUTES.GET_CARD_ADMIN_MERCHANT(merchantId)
  );

  return response.data;
});

const getDeferredList = createAsyncThunk<
  DeferredOptions,
  { merchantId: string }
>("layout/getDeferred", async ({ merchantId }) => {
  const response = await axios.get<DeferredMerchantAdminResponse>(
    API_ROUTES.GET_DEFERRED(merchantId)
  );

  return get(response, "data.deferredOptions", []);
});

const getDeferredConfig = createAsyncThunk<
  DeferredConfigResponse,
  DeferredConfigRequest
>("layout/getDeferredConfig", async (payload) => {
  const response = await axios.post<DeferredConfigResponse>(
    API_ROUTES.GET_DEFERRED_CONFIG,
    payload
  );

  return response.data;
});

const getBusinessRules = createAsyncThunk<
  GetBusinessRulesResponse,
  GetBusinessRulesRequest
>("layout/getBusinessRules", async (payload) => {
  if (payload.isBatch) {
    await setTimeoutPromise(1);
    return await getBusinessRulesBatch();
  }

  const response = await axios.post<GetBusinessRulesResponse>(
    API_ROUTES.GET_BUSINESS_RULES,
    payload
  );

  return response.data;
});

const getPayoutBusinessRules = createAsyncThunk<
  GetPayoutsBusinessRulesResponse,
  GetPayoutsBusinessRulesRequest
>("layout/getPayoutBusinessRules", async (payload) => {
  if (payload.isBatch) {
    return await getPayoutsBusinessRulesBatch();
  }

  const response = await axios.post<GetPayoutsBusinessRulesResponse>(
    API_ROUTES.GET_PAYOUT_BUSINESS_RULES,
    payload
  );

  return response.data;
});

const getNodeInfo = createAsyncThunk<GetNodeInfoResponse, GetNodeInfoRequest>(
  "layout/getNodeInfo",
  async (payload: GetNodeInfoRequest) => {
    const response: AxiosResponse = await axios.post<GetNodeInfoResponse>(
      API_ROUTES.GET_NODE_INFO,
      payload
    );

    return response.data;
  }
);

const updateHierarchyNodeConfig = createAsyncThunk<
  Boolean,
  UpdateConfigHierarchyRequest
>("layout/updateHierarchyNodeConfig", async (payload) => {
  const response = await axios.put<Boolean>(
    API_ROUTES.UPDATE_HIERARCHY_NODE_CONFIG,
    payload
  );

  return response.data;
});

const getSemaphoreData = createAsyncThunk<
  SemaphoreData,
  { publicMerchantId: string }
>("layout/getSemaphoreData", async ({ publicMerchantId }) => {
  const response = await axios.get<SemaphoreData>(
    API_ROUTES.GET_SEMAPHORE_DATA(publicMerchantId)
  );

  return response.data;
});

const updateSemaphoreData = createAsyncThunk<SemaphoreData, SemaphoreData>(
  "layout/updateSemaphoreData",
  async (payload) => {
    const response = await axios.post<SemaphoreData>(
      API_ROUTES.UPDATE_SEMAPHORE_DATA,
      payload
    );

    return response.data;
  }
);

const getBusinessListConfiguration = createAsyncThunk<
  BusinessConfiguration | undefined
>("layout/getBusinessListConfiguration", async () => {
  const response = await axios.get<BusinessConfiguration>(
    API_ROUTES.GET_BUSINESS_LIST_CONFIGURATION
  );
  return response.data;
});

const updateMerchantDeferredData = createAsyncThunk<
  any,
  { merchantId: string; payload: UpdateMerchantRequest }
>("layout/updateMerchantDeferredData", async ({ payload, merchantId }) => {
  const response = await axios.post<UpdateMerchantRequest, any>(
    API_ROUTES.POST_UPDATE_MERCHANT(merchantId),
    payload
  );

  return { data: response.data, deferredOptions: payload.deferredOptions };
});

const updateMerchantMassiveDeferredData = createAsyncThunk<
  any,
  { payload: UpdateMerchantMassiveRequest }
>("layout/updateMerchantMassiveDeferredData", async ({ payload }) => {
  const response = await axios.post<UpdateMerchantMassiveRequest, any>(
    API_ROUTES.POST_UPDATE_MERCHANT_MASSIVE_DEFERRED(),
    payload
  );

  return { data: response.data };
});

const deleteRule = createAsyncThunk<any, { ruleID: string }>(
  "layout/deleteRule",
  async ({ ruleID }) => {
    const response = await axios.delete<UpdateMerchantRequest, any>(
      API_ROUTES.DELETE_RULE(ruleID)
    );

    return response.data;
  }
);

const deletePayoutRule = createAsyncThunk<any, { ruleID: string }>(
  "layout/deletePayoutRule",
  async ({ ruleID }) => {
    const response = await axios.post<{ status: string }, any>(
      API_ROUTES.UPDATE_PAYOUT_BUSINESS_RULES(ruleID),
      {
        status: StatusRuleEnum.DELETE,
      }
    );

    return response.data;
  }
);

const deleteDeferred = createAsyncThunk<
  any,
  { deferredId: string; merchantId: string }
>("layout/deleteDeferred", async ({ deferredId, merchantId }) => {
  return await axios.delete<string>(API_ROUTES.DELETE_PROCESSOR(deferredId), {
    data: { merchantId: merchantId },
  });
});

const putHierarchyNodeConfig = createAsyncThunk<
  boolean,
  UpdateConfigHierarchyRequest
>("layout/putHierarchyNodeConfig", async (payload) => {
  const response = await axios.put<boolean>(
    API_ROUTES.PUT_UPDATE_HIERARCHY_NODE_CONFIG,
    payload
  );

  return response.data;
});

const getProcessors = createAsyncThunk<
  GetProcessorResponse[],
  { merchantId: string; entity: string }
>("layout/getProcessors", async ({ merchantId, entity }) => {
  const api: string =
    entity == "CUSTOMER"
      ? API_ROUTES.GET_PROCESSORS_CUSTOMER(merchantId)
      : API_ROUTES.GET_PROCESSORS(merchantId);
  const response = await axios.get<GetProcessorResponse[]>(api);

  return response.data;
});

const getMerchantInformation = createAsyncThunk<
  GetMerchantInformation,
  { offset: number; text: string }
>("layout/getMerchantInformation", async (payload) => {
  if (isEmpty(payload.text)) unset(payload, "text");

  const body: {
    limit: number;
    offset: number;
    text?: string;
  } = {
    ...payload,
    limit: 10,
  };

  const response = await axios.post<GetMerchantInformation>(
    API_ROUTES.GET_MERCHANT_INFO,
    body
  );

  return response.data;
});

const getDefaultProcessor = createAsyncThunk<
  GetDefaultProcessorResponse,
  { merchantId: string }
>("layout/getDefaultProcessor", async ({ merchantId }) => {
  const response = await axios.get<GetDefaultProcessorResponse>(
    API_ROUTES.GET_DEFAULT_PROCESSOR(merchantId)
  );

  return response.data;
});

const getDefaultPayoutProcessor = createAsyncThunk<
  GetDefaultPayoutProcessorResponse,
  { merchantId: string }
>("layout/getDefaultPayoutProcessor", async ({ merchantId }) => {
  const response = await axios.get<GetDefaultPayoutProcessorResponse>(
    API_ROUTES.GET_DEFAULT_PAYOUT_PROCESSOR(merchantId)
  );

  return response.data;
});

const deleteProcessorByPaymentMethod = createAsyncThunk<
  boolean,
  {
    url: string;
    merchantId?: string;
  }
>("layout/deleteProcessorByPaymentMethod", async ({ url, merchantId }) => {
  const response = await axios.delete(
    API_ROUTES.DELETE_PROCESSOR_BY_PAYMENT_METHOD(url),
    {
      data: { merchantId },
    }
  );

  return response.data;
});

const updateFailoverProcessor = createAsyncThunk<
  { status: string },
  {
    failoverAlias: string;
    merchantId: string;
    publicProcessorId: string;
  }
>(
  "layout/updateFailoverProcessor",
  async ({ failoverAlias, merchantId, publicProcessorId }) => {
    const response = await axios.patch<{ status: string }>(
      API_ROUTES.UPDATE_FAILOVER_PROCESSOR(merchantId, publicProcessorId),
      {
        failoverAlias,
      }
    );

    return response.data;
  }
);

const createMassiveRule = createAsyncThunk<
  MassiveRulesRequest,
  MassiveRulesRequest
>("layout/createMassiveRule", async (payload) => {
  const response = await axios.post<MassiveRulesRequest>(
    API_ROUTES.POST_MASSIVE_CREATE,
    payload
  );

  return response.data;
});

const createMassivePayoutRule = createAsyncThunk<
  MassiveRulesRequest,
  MassiveRulesRequest
>("layout/createMassivePayoutRule", async (payload) => {
  const response = await axios.post<MassiveRulesRequest>(
    API_ROUTES.POST_MASSIVE_PAYOUT_RULES,
    payload
  );

  return response.data;
});

const centralizeBranch = createAsyncThunk<
  ICentralizeNodeResponse,
  ICentralizeNodeRequest
>(
  "layout/centralizeBranch",
  async (centralizeNodeRequest: ICentralizeNodeRequest) => {
    const response = await axios.post<ICentralizeNodeResponse>(
      API_ROUTES.CENTRALIZE,
      centralizeNodeRequest
    );

    return response.data;
  }
);

const centralizeProcessors = createAsyncThunk<
  boolean,
  ICentralizeProcessorsRequest
>(
  "processors/centralizeProcessors",
  async (centralizeProcessorsRequest: ICentralizeProcessorsRequest) => {
    forEach(API_ROUTES.CENTRALIZE_PROCESSORS, async (path: string) => {
      await axios.post<ICentralizeProcessorsResponse>(
        path,
        centralizeProcessorsRequest
      );
    });

    return true;
  }
);

const getAppConfigPaymentMethods = createAsyncThunk<
  GetAppConfigPaymentMethodsResponse
>("layout/getAppConfigPaymentMethods", async () => {
  const response = await axios.get<GetAppConfigPaymentMethodsResponse>(
    API_ROUTES.GET_PAYMENT_METHODS
  );
  return response.data;
});

export {
  getBusinessRules,
  getPayoutBusinessRules,
  getSemaphoreData,
  updateSemaphoreData,
  getNodeInfo,
  updateHierarchyNodeConfig,
  getBusinessListConfiguration,
  getDeferredList,
  getDeferredConfig,
  getCardAdminMerchant,
  updateMerchantDeferredData,
  deleteRule,
  deletePayoutRule,
  deleteDeferred,
  putHierarchyNodeConfig,
  getProcessors,
  getMerchantInformation,
  getDefaultProcessor,
  getDefaultPayoutProcessor,
  deleteProcessorByPaymentMethod,
  updateFailoverProcessor,
  createMassiveRule,
  createMassivePayoutRule,
  updateMerchantMassiveDeferredData,
  centralizeBranch,
  centralizeProcessors,
  getAppConfigPaymentMethods,
};
