import {
  SubmitHandler,
  useForm,
  UseFormMethods,
  useWatch,
} from "react-hook-form";
import {
  defaultTo,
  filter,
  get,
  isEmpty,
  isNil,
  isUndefined,
  keys,
  set,
} from "lodash";
import { useParams } from "react-router-dom";
import { ISecurityRuleForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { SecurityRulesEditProps } from "../SecurityRulesEdit";
import {
  IOptionSelection,
  RuleSchemaEnum,
  RulesNotificationResultEnum,
} from "../../../shared/infrastructure/constants/RulesConstants";
import React, { useEffect } from "react";
import {
  ALL_COUNTRY_VALUES,
  getLabelValues,
  SPEED_ACTION_VALUES,
} from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { SecurityRule } from "../../../../types/security_rule";
import { MerchantsData } from "../../../store/actionCreator";
// @ts-ignore
import * as cleaner from "deep-cleaner";
import { Category } from "../../../../types/category";
import { AuthRule } from "../../../../types/auth_rule";
import { AuthRuleFormFields } from "../../../shared/infrastructure/enums/AuthRuleFormFields";
import { GeneralActionsEnum } from "../../../shared/infrastructure/enums/GeneralActionsEnum";
import { getValues } from "../../../shared/utils";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { RuleTypeEnum } from "../../../shared/infrastructure/enums/RuleTypeEnum";

export interface SecurityRulesState {
  form: UseFormMethods<ISecurityRuleForm>;
  isEdit: boolean;
  terminateFillFields: boolean;
  isValidCard: boolean;
  isValidTransfer: boolean;
  checkedAllCountries: boolean;
  indexesStrictCondition: string[];
  indexesSpeedCondition: string[];
  countryGeneral: string;
  credentials: IOptionSelection[];
  generalAction: string;
  actions: {
    handleSubmitForm: SubmitHandler<ISecurityRuleForm>;
    handleCancelAction: () => void;
    handleCloseNotification: () => void;
    setFormValue: (name: string, value: string) => void;
    handleCheckedAllCountries: () => void;
    handleSetSearchTextMerchantList: (searchText: string) => void;
    handleEnterSearchTextMerchantList: (key: string) => void;
    addStrictCondition: (index: number) => void;
    removeStrictCondition: (item: number) => void;
    addSpeedCondition: (index: number) => void;
    removeSpeedCondition: (item: number) => void;
    setMerchantInformation: (value: object) => void;
    handleEnterSearchTextCredentialList: (key: string) => void;
    handleSetSearchTextCredentialList: (searchTextCredential: string) => void;
    setCredentialInformation: (value: object, index: number) => void;
    handleSetGeneralAction: (generalAction: string) => void;
    handleSetCurrentSecurityRule: (rule: SecurityRule | undefined) => void;
  };
}

export interface StrictConditions {
  label: string;
  operator: string;
  value: string | string[];
  edit: boolean;
}

export const useSecurityRulesState = (
  props: SecurityRulesEditProps
): SecurityRulesState => {
  const { ruleId } = useParams();
  const isEdit: boolean = !isEmpty(ruleId);

  const form = useForm<ISecurityRuleForm>({
    mode: "all",
    defaultValues: {
      type: "securityCommerce",
      service: "",
    },
  });

  const type: string = useWatch({
    control: form.control,
    name: "type",
    defaultValue: "",
  });

  const countries: string[] = useWatch({
    control: form.control,
    name: "country",
    defaultValue: [],
  });

  const merchantId: string = useWatch({
    control: form.control,
    name: "merchantId",
    defaultValue: "",
  });

  const service: string = useWatch({
    control: form.control,
    name: "service",
    defaultValue: "",
  });

  const [role, setRole] = React.useState<string>("");
  const [terminateFillFields, setTerminateFillFields] = React.useState<boolean>(
    !isEdit
  );

  const [checkedAllCountries, setCheckedAllCountries] =
    React.useState<boolean>(false);
  const [searchTextMerchant, setSearchTextMerchant] =
    React.useState<string>("");
  const [searchTextCredential, setSearchTextCredential] =
    React.useState<string>("");

  const [currentSecurityRule, setCurrentSecurityRule] = React.useState<
    SecurityRule | undefined
  >({
    country: [],
    historical: [],
    id: "",
    merchantId: "",
    merchantName: "",
    rule: {},
    service: "",
    status: "",
    type: "",
    createdAt: 0,
  });

  const [countryGeneral, setCountryGeneral] = React.useState<string>("");
  const [merchantName, setMerchantName] = React.useState<string>("");

  const [isValidCard, setIsValidCard] = React.useState<boolean>(false);
  const [isValidTransfer, setIsValidTransfer] = React.useState<boolean>(false);

  const [indexesStrictCondition, setIndexesStrictCondition] = React.useState([
    "value",
  ]);
  const [indexesSpeedCondition, setIndexesSpeedCondition] = React.useState([
    "value",
  ]);
  const [credentials, setCredentials] = React.useState<IOptionSelection[]>([]);
  const [generalAction, setGeneralAction] = React.useState<string>("");
  const [formObjStruct, setFormObjStruct] = React.useState<object>({});

  useEffect(() => {
    getRole();
    if (isEdit) {
      props.getSecurityRule({
        ruleId,
      });
    } else {
      props.searchMerchantsList({
        offset: 0,
        limit: 6,
      });
    }
  }, []);

  useEffect(() => {
    if (
      isEdit &&
      !isEmpty(props.currentSecurityRule) &&
      props.currentSecurityRule?.type === "securityCountry"
    ) {
      convertSecurityRuleForm(props.currentSecurityRule as SecurityRule);
    }
    if (
      isEdit &&
      !isEmpty(props.currentSecurityRule) &&
      props.currentSecurityRule?.type === "securityCommerce" &&
      !isEmpty(props.currentMerchant)
    ) {
      convertSecurityRuleForm(props.currentSecurityRule as SecurityRule);
    }
  }, [
    props.currentSecurityRule,
    props.currentMerchant,
    props.processors,
    props.securityCredentials,
  ]);

  useEffect(() => {
    getRole();
    if (isEdit && !isEmpty(props.currentMerchant)) {
      props.setMerchants({
        data: [{ _source: props.currentMerchant }],
      } as MerchantsData);
      setMerchantInformation(defaultTo(props.currentMerchant, {}));
    }
  }, [props.currentMerchant]);

  useEffect(() => {
    const withCard: object[] = filter(props.processors, [
      "paymentMethod",
      "card",
    ]);
    const withTransfer: object[] = filter(props.processors, [
      "paymentMethod",
      "transfer",
    ]);

    if (terminateFillFields) {
      if (withTransfer.length > 0) {
        setIsValidTransfer(true);
        setFormValue("service", "transfer");
      } else {
        setIsValidTransfer(false);
        setFormValue("service", "");
      }

      if (withCard.length > 0) {
        setIsValidCard(true);
        setFormValue("service", "card");
      } else {
        setIsValidCard(false);
        setFormValue("service", "");
      }
    } else {
      if (withTransfer.length > 0) {
        setIsValidTransfer(true);
      } else {
        setIsValidTransfer(false);
      }

      if (withCard.length > 0) {
        setIsValidCard(true);
      } else {
        setIsValidCard(false);
      }
    }
  }, [props.processors]);

  useEffect(() => {
    if (!isEmpty(props.securityCredentials)) {
      const merchantCredentials: IOptionSelection[] = [];

      defaultTo(props.securityCredentials, []).map((credential: Category) => {
        merchantCredentials.push({
          name: get(credential, "name", ""),
          value: get(credential, "value", "").toString(),
        });
      });

      setCredentials(merchantCredentials);
    }
  }, [props.securityCredentials]);

  useEffect(() => {
    if (countries.length < 6) {
      setCheckedAllCountries(false);
      return;
    }

    if (countries.length === 6) {
      setCheckedAllCountries(true);
      form.setValue("country", ALL_COUNTRY_VALUES);
      return;
    }
  }, [countries]);

  useEffect(() => {
    if (checkedAllCountries) {
      form.setValue("country", ALL_COUNTRY_VALUES);
      return;
    }

    if (
      !checkedAllCountries &&
      countries.length - 1 === ALL_COUNTRY_VALUES.length
    ) {
      form.setValue("country", []);
      return;
    }
  }, [checkedAllCountries]);

  useEffect(() => {
    if (terminateFillFields) {
      if (type === "securityCountry") {
        setIsValidTransfer(false);
        setIsValidCard(true);
        setFormValue("service", "card");
        resetStrictCondition();
        return;
      }
      if (type === "securityCommerce") {
        setIsValidTransfer(false);
        setIsValidCard(false);
        setFormValue("service", "");
        setIndexesStrictCondition(["value"]);
        resetStrictCondition();
        return;
      }
    } else {
      if (type === "securityCountry") {
        setIsValidTransfer(false);
        setIsValidCard(true);
        return;
      }
      if (type === "securityCommerce") {
        setIsValidTransfer(false);
        setIsValidCard(false);
        return;
      }
    }
  }, [type]);

  useEffect(() => {
    if (terminateFillFields) {
      if (!isEmpty(service)) form.clearErrors("service");
      resetStrictCondition();
    }
  }, [service]);

  useEffect(() => {
    if (!isEmpty(merchantId)) {
      props.getCredentials(merchantId);
      props.getProcessors(merchantId, { offset: 0, limit: 10 });
    }
  }, [merchantId]);

  const setFormValue = (name: string, value: string | string[]) => {
    form.control.setValue(name, value);
  };

  const getRole = () => {
    const roles: object = JSON.parse(
      defaultTo(localStorage.getItem("roles"), "{}")
    );

    if (get(roles, "BackofficeMonitoring") === true)
      setRole("BackofficeMonitoring");
  };

  const setMerchantInformation = (value: object) => {
    setFormValue("merchantId", get(value, "publicMerchantId", ""));
    form.clearErrors("merchantId");
    setCountryGeneral(get(value, "country", ""));
    setMerchantName(get(value, "name", ""));
  };

  const handleSetGeneralAction = (generalAction: string) => {
    setGeneralAction(generalAction);
  };

  const resetStrictCondition = () => {
    setIndexesStrictCondition(["value"]);
    setIndexesSpeedCondition(["value"]);
    setFormValue(
      "strictCondition[0].label",
      getLabelValues(service, type)[0].value
    );
    setFormValue("speedCondition[0].label", "");
    form.clearErrors("strictCondition[0].label");
    form.clearErrors("strictCondition[0].operator");
    form.clearErrors("strictCondition[0].value");
    form.clearErrors("speedCondition[0].control");
    form.clearErrors("speedCondition[0].periodicity");
    form.clearErrors("speedCondition[0].transactionStatus");
    form.clearErrors("speedCondition[0].value");
  };

  const addStrictCondition = async (index: number) => {
    form.setValue(`strictCondition[${index}].edit`, false);

    if (
      form.getValues(`strictCondition[${index}].from`) ||
      form.getValues(`strictCondition[${index}].until`)
    ) {
      const authRuleFrom = await form.trigger(`strictCondition[${index}].from`);
      const authRuleUntil = await form.trigger(
        `strictCondition[${index}].until`
      );
      if (authRuleFrom && authRuleUntil) {
        return setIndexesStrictCondition((prevIndex) => [
          ...prevIndex,
          "value",
        ]);
      }
    }

    const resultLabel = await form.trigger(`strictCondition[${index}].label`);
    const resultOperator = await form.trigger(
      `strictCondition[${index}].operator`
    );
    let resultValue: boolean;

    if (form.getValues(`strictCondition[${index}].operator`) === "=|all") {
      resultValue = true;
    } else {
      resultValue = await form.trigger(`strictCondition[${index}].value`);
    }
    const relativeTotal: number = indexesStrictCondition.filter(
      (a) => a === "value"
    ).length;

    if (
      resultLabel &&
      resultOperator &&
      resultValue &&
      relativeTotal !== getLabelValues(service, type).length
    )
      setIndexesStrictCondition((prevIndex) => [...prevIndex, "value"]);
  };

  const removeStrictCondition = (index: number) => {
    const aux: string[] = [...indexesStrictCondition];
    aux[index] = "deleted";
    setIndexesStrictCondition(aux);
  };

  const addSpeedCondition = async (index: number) => {
    const resultControl = await form.trigger(
      `speedCondition[${index}].control`
    );
    const resultPeriodicity = await form.trigger(
      `speedCondition[${index}].periodicity`
    );
    const resultTransaction = await form.trigger(
      `speedCondition[${index}].transactionStatus`
    );
    const resultValue = await form.trigger(`speedCondition[${index}].value`);

    const relativeTotal: number = indexesSpeedCondition.filter(
      (index) => index === "value"
    ).length;

    if (
      resultControl &&
      resultPeriodicity &&
      resultTransaction &&
      resultValue &&
      relativeTotal !== SPEED_ACTION_VALUES.length
    )
      setIndexesSpeedCondition((prevIndex) => [...prevIndex, "value"]);
  };

  const removeSpeedCondition = (index: number) => {
    const aux: string[] = [...indexesSpeedCondition];
    aux[index] = "deleted";
    setIndexesSpeedCondition(aux);
  };

  const transformCardName = (cardName: string): string => {
    const map_obj: object = {
      á: "a",
      Á: "A",
      é: "e",
      É: "E",
      í: "i",
      Í: "I",
      Ñ: "N",
      ñ: "n",
      ó: "o",
      Ó: "O",
      ú: "u",
      Ú: "U",
    };
    let str: string;

    str = defaultTo(cardName, "").replace(
      /[áéíóúÁÉÍÓÚÑñ]/gi,
      (matched: string): string => map_obj[matched]
    );
    str = str.replace(/[^\x00-\x7F]/g, "");
    str = str.replace(/[^a-zA-Z 0-9]+/g, "");

    return str;
  };

  const handleSubmitForm: SubmitHandler<ISecurityRuleForm> = async (
    formData
  ) => {
    if (!isNil(formData.country) && get(formData, "country", []).length === 0) {
      form.setError("country", {
        type: "manual",
      });
      return;
    }

    const security_rule: SecurityRule = convertSecurityRule(formData, isEdit);

    if (isEdit) {
      props.updateSecurityRule({
        ruleId,
        rule: security_rule,
      });
    } else {
      props.createSecurityRule({
        role: role,
        rule: security_rule,
      });
    }
  };

  const handleCancelAction = (): void => {
    window.location.href = "/rules";
  };

  const handleCheckedAllCountries = (): void => {
    setCheckedAllCountries((value) => !value);
  };

  const handleSetSearchTextMerchantList = (searchText: string) => {
    setSearchTextMerchant(searchText);
  };

  const handleSetCurrentSecurityRule = (rule: SecurityRule | undefined) => {
    setCurrentSecurityRule(rule);
  };

  const handleEnterSearchTextMerchantList = (key: string) => {
    if (key !== "Enter") return;
    props.searchMerchantsList({
      offset: 0,
      limit: 6,
      text: searchTextMerchant,
    });
  };

  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.NO_ACTION,
    });
  };

  const convertSecurityRuleForm = (rule: SecurityRule): void => {
    const security_rule: SecurityRule = cleaner(rule);
    const formObject: object = {
      name: security_rule.rule?.name,
      type: security_rule.type,
      service: security_rule.service,
      action: security_rule.rule?.action,
    };
    if (security_rule.type === "securityCountry") {
      setIsValidTransfer(false);
      setIsValidCard(true);
      set(formObject, "country", security_rule.country);
    }
    if (security_rule.type === "securityCommerce") {
      setIsValidTransfer(false);
      setIsValidCard(false);
      if (!isEmpty(security_rule.merchantId)) {
        set(formObject, "merchantId", security_rule.merchantId);
      }
    }

    if (!isEmpty(security_rule.rule?.code)) {
      set(formObject, "code", security_rule.rule?.code);
    }

    if (!isEmpty(security_rule.rule?.message)) {
      set(formObject, "message", security_rule.rule?.message);
    }

    if (!isEmpty(security_rule.rule?.partnerValidator)) {
      set(formObject, "partnerValidator", security_rule.rule?.partnerValidator);
    }

    //strict
    let strictCount: number = 0;
    const strictFields: string[] = [];

    keys(security_rule.rule).forEach((key: string) => {
      if (
        getValues(
          getLabelValues(
            security_rule.service,
            security_rule.type,
            get(security_rule, "rule.action")
          )
        ).includes(key)
      ) {
        strictFields.push("value");
      }
    });

    setIndexesStrictCondition(strictFields);

    keys(security_rule.rule).forEach((key: string) => {
      if (key === LabelEnum.SCHEDULE) {
        const transaction_schedule = get(
          security_rule,
          `rule[${RuleSchemaEnum.transactionCreated}]`
        ).split("|")[1];

        const transaction_schedule_from = transaction_schedule.split(";")[0];

        const transaction_schedule_until = transaction_schedule.split(";")[1];

        set(formObject, `strictCondition.${strictCount}.label`, key);
        set(
          formObject,
          `strictCondition.${strictCount}.from`,
          transaction_schedule_from
        );
        set(
          formObject,
          `strictCondition.${strictCount}.until`,
          transaction_schedule_until
        );

        strictCount = strictCount + 1;
        return;
      }

      if (
        getValues(
          getLabelValues(
            security_rule.service,
            security_rule.type,
            get(security_rule, "rule.action")
          )
        ).includes(key)
      ) {
        const operatorAux: string = get(
          get(security_rule, `rule.${key}`, "").split("|"),
          "0",
          ""
        );
        const valueAux: string = get(
          get(security_rule, `rule.${key}`, "").split("|"),
          "1",
          ""
        );
        set(formObject, `strictCondition.${strictCount}.label`, key);
        set(formObject, `strictCondition.${strictCount}.edit`, true);

        if (get(security_rule, `rule.${key}`, "") === "=|all") {
          set(
            formObject,
            `strictCondition.${strictCount}.operator`,
            get(security_rule, `rule.${key}`, "")
          );
        } else if (
          operatorAux.includes("=") ||
          operatorAux.includes(">") ||
          operatorAux.includes("<")
        ) {
          set(
            formObject,
            `strictCondition.${strictCount}.operator`,
            operatorAux
          );
          set(formObject, `strictCondition.${strictCount}.value`, valueAux);
        } else {
          set(
            formObject,
            `strictCondition.${strictCount}.operator`,
            operatorAux
          );
          set(
            formObject,
            `strictCondition.${strictCount}.value`,
            valueAux.split(",")
          );
        }
        strictCount = strictCount + 1;
      }
    });

    // speed
    let speedCount: number = 0;
    const speedFields: string[] = [];

    if (
      !isEmpty(security_rule.rule?.periodicitySum) ||
      !isEmpty(security_rule.rule?.periodicityCount)
    ) {
      set(formObject, "activeSpeedCondition", true);
    }
    if (get(security_rule, "rule.ignore3DS", false)) {
      set(
        formObject,
        "activeTransactionRisk",
        get(security_rule.rule, "ignore3DS", false)
      );
    }

    if (!isEmpty(security_rule.rule?.periodicitySum)) {
      speedFields.push("value");
    }

    if (!isEmpty(security_rule.rule?.periodicityCount)) {
      speedFields.push("value");
    }

    if (!isEmpty(security_rule.rule?.periodicitySum)) {
      set(formObject, `speedCondition.${speedCount}.control`, "sum");
      set(
        formObject,
        `speedCondition.${speedCount}.periodicity`,
        security_rule.rule?.periodicitySum
      );
      set(
        formObject,
        `speedCondition.${speedCount}.transactionStatus`,
        security_rule.rule?.transactionStatusSum
      );
      set(
        formObject,
        `speedCondition.${speedCount}.value`,
        security_rule.rule?.maxAmount
      );
      speedCount = speedCount + 1;
    }

    if (!isEmpty(security_rule.rule?.periodicityCount)) {
      set(formObject, `speedCondition.${speedCount}.control`, "count");
      set(
        formObject,
        `speedCondition.${speedCount}.periodicity`,
        security_rule.rule?.periodicityCount
      );
      set(
        formObject,
        `speedCondition.${speedCount}.transactionStatus`,
        security_rule.rule?.transactionStatusCount
      );
      set(
        formObject,
        `speedCondition.${speedCount}.value`,
        security_rule.rule?.limit
      );
      speedCount = speedCount + 1;
    }

    setFormObjStruct(formObject);
    form.reset(formObject);

    setTimeout(() => {
      setTerminateFillFields(true);
    }, 1000);
  };

  const convertSecurityRule = (
    form: ISecurityRuleForm,
    isEdit: boolean
  ): SecurityRule => {
    const securityRule: SecurityRule = {
      merchantName,
      country: form.country,
      merchantId: get(form, "merchantId", "all"),
      service: form.service,
      type: form.type,
      rule: {
        action: form.action,
        code: form.code,
        message: form.message,
        name: form.name,
        partnerValidator: form.partnerValidator,
        kind: "strict",
      },
    };

    const created: number = new Date().getTime();
    const username: string = `${localStorage.getItem("username")}`;
    const fullname: string = `${localStorage.getItem("fullName")}`;
    const is3dsOtp: boolean =
      form.action === GeneralActionsEnum.THREE_DS ||
      form.action === GeneralActionsEnum.OTP;

    const authRule: AuthRule = {
      created,
      id: created.toString(),
      requestType: AuthRuleFormFields.REQUEST_TYPE_CREATE,
      userName: username,
      authRules: [],
      historical: [
        {
          created,
          userName: username,
          eventName: AuthRuleFormFields.STATUS_SENT.toUpperCase(),
        },
      ],
      publicMerchantId: get(form, "merchantId"),
      merchantName: merchantName,
      country: countryGeneral,
      ignore3DS: form.activeTransactionRisk,
      alias: form.name,
      status: AuthRuleFormFields.STATUS_SENT,
      userNameRequest: username,
      kind:
        form.action === GeneralActionsEnum.THREE_DS
          ? AuthRuleFormFields.THREE_DS
          : AuthRuleFormFields.OTP,
      provider:
        form.action === GeneralActionsEnum.THREE_DS
          ? AuthRuleFormFields.PROVIDER_CYBERSOURCE
          : AuthRuleFormFields.PROVIDER_KUSHKI,
      userAliasRequest: fullname,
      ruleCreated: created,
      userAlias: fullname,
    };

    if (!isEmpty(form.speedCondition)) set(securityRule, "rule.kind", "speed");

    get(form, "strictCondition", []).forEach((condition: object) => {
      buildRulesConditions(condition, securityRule, "rule");
    });

    get(form, "speedCondition", []).forEach((condition: object) => {
      if (get(condition, "control") === "count") {
        set(
          securityRule,
          "rule.periodicityCount",
          get(condition, "periodicity")
        );
        set(
          securityRule,
          "rule.transactionStatusCount",
          get(condition, "transactionStatus")
        );
        set(
          securityRule,
          "rule.transactionStatus",
          get(condition, "transactionStatus")
        );
        set(securityRule, "rule.limit", get(condition, "value"));
      } else {
        set(securityRule, "rule.periodicitySum", get(condition, "periodicity"));
        set(
          securityRule,
          "rule.transactionStatusSum",
          get(condition, "transactionStatus")
        );
        set(securityRule, "rule.maxAmount", get(condition, "value"));
      }
    });

    set(
      securityRule,
      "rule.transactionBank",
      get(securityRule, "rule.transactionBank", "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );

    const cardHolderName: string = get(
      securityRule,
      "rule.transactionCardHolderName"
    );
    if (!isEmpty(cardHolderName)) {
      const splitCardHolderName: string[] = cardHolderName.split("|");
      set(
        securityRule,
        "rule.transactionCardHolderName",
        splitCardHolderName[0] +
          "|" +
          concatenateCardHolderName(splitCardHolderName[1])
      );
    }

    const phoneNumber: string = get(
      securityRule,
      "rule.transactionPhoneNumber"
    );
    set(
      securityRule,
      "rule.transactionPhoneNumber",
      !isEmpty(phoneNumber) ? phoneNumber : undefined
    );

    if (is3dsOtp) {
      set(authRule, "authRules[0]", securityRule.rule);
      set(securityRule, "authRules", authRule);
      if (isEdit) {
        set(securityRule, "historical", [
          ...get(currentSecurityRule, "historical", []),
          ...authRule.historical,
        ]);
        set(securityRule, "rule.ignore3DS", authRule.ignore3DS);
        if (securityRule.type !== RuleTypeEnum.COUNTRY)
          set(securityRule, "status", AuthRuleFormFields.STATUS_PENDING);
      }
    }

    return cleaner(securityRule);
  };

  const concatenateCardHolderName = (cardHolderName: string): string => {
    const cardHolderNames: string[] = cardHolderName.split(",");
    let str: string;

    str = cardHolderNames
      .map((name: string) => transformCardName(name))
      .join(",");
    return str;
  };

  const buildRulesConditions = (
    condition: object,
    rule: object,
    path: string
  ) => {
    if (get(condition, "label") === LabelEnum.SCHEDULE) {
      set(
        rule,
        `${path}.${get(condition, "label")}`,
        `betweenDate|${get(condition, "from", "00:00")};${get(
          condition,
          "until",
          "00:00"
        )}`
      );
    } else {
      if (
        typeof get(condition, "value") === "string" &&
        get(condition, "operator") !== "=|all"
      )
        set(
          rule,
          `${path}.${get(condition, "label")}`,
          `${get(condition, "operator")}|${get(condition, "value")}`
        );
      else if (get(condition, "operator") === "=|all") {
        set(
          rule,
          `${path}.${get(condition, "label")}`,
          `${get(condition, "operator")}`
        );
      } else
        set(
          rule,
          `${path}.${get(condition, "label")}`,
          `${get(condition, "operator")}|${get(condition, "value", []).join(
            ","
          )}`
        );
    }
  };

  const handleEnterSearchTextCredentialList = (key: string) => {
    if (key !== "Enter") return;
    props.getSearchKeyCredentials(merchantId, searchTextCredential);
  };
  const handleSetSearchTextCredentialList = (searchText: string) => {
    setSearchTextCredential(searchText);
  };
  const setCredentialInformation = (value: object, index: number) => {
    setFormValue(
      `strictCondition[${index}].value`,
      get(value, "credentialId", "")
    );
    setSearchTextCredential(get(value, "alias", ""));
  };

  useEffect(() => {
    let countEmptyValue = 0;
    const strictConditionArr: StrictConditions[] = get(
      form.getValues(),
      "strictCondition",
      []
    );

    strictConditionArr.forEach((data: StrictConditions, index: number) => {
      if (isUndefined(data.value) || isEmpty(data.value)) {
        set(formObjStruct, `strictCondition[${index}].value`, data.value);
        countEmptyValue++;
      }
    });

    if (countEmptyValue > 0) {
      form.reset({
        ...formObjStruct,
      });

      return;
    }
  }, [formObjStruct]);

  return {
    form,
    isEdit,
    terminateFillFields,
    isValidCard,
    isValidTransfer,
    checkedAllCountries,
    indexesStrictCondition,
    indexesSpeedCondition,
    countryGeneral,
    credentials,
    generalAction,
    actions: {
      handleCancelAction,
      handleCloseNotification,
      handleSubmitForm,
      setFormValue,
      handleCheckedAllCountries,
      handleSetSearchTextMerchantList,
      handleEnterSearchTextMerchantList,
      addStrictCondition,
      removeStrictCondition,
      addSpeedCondition,
      removeSpeedCondition,
      setMerchantInformation,
      handleEnterSearchTextCredentialList,
      handleSetSearchTextCredentialList,
      setCredentialInformation,
      handleSetGeneralAction,
      handleSetCurrentSecurityRule,
    },
  };
};
