import React, { FC } from "react";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import { genericLoaderStyle } from "./GenericLoader.style";
import { IGenericLoader } from "./GenericLoader.interfaces";

export const GenericLoader: FC<IGenericLoader> = ({
  showLoading,
  textLoading = "Cargando...",
}: IGenericLoader) => {
  return (
    <Dialog maxWidth="md" open={showLoading}>
      <Box sx={genericLoaderStyle.container}>
        <Box>
          <CircularProgress
            sx={genericLoaderStyle.circularColor}
            thickness={4}
            size={60}
          />
        </Box>
        <div>
          <Typography variant="h6" sx={genericLoaderStyle.textContainer}>
            {textLoading}
          </Typography>
        </div>
      </Box>
    </Dialog>
  );
};
