import { CustomStyles } from "../../../shared/infrastructure/interfaces/CustomStyles";

export const styles: CustomStyles = {
  container: {
    alignItems: "center",
    display: "flex",
    paddingTop: "1.5rem",
  },
  registeredShareholders: {
    left: "1.5rem",
  },
  shareholdersParticipation: {
    "& span": {
      padding: "0.7rem 0.6rem",
    },
    left: "2.25rem",
  },
  text: {
    lineHeight: "180%",
    mr: "4.5rem",
  },
};
