import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const FilterSideBarStyles = createNamedStyles({
  badge: {
    "& .MuiBadge-badge": {
      bottom: "3%",
      height: "18px",
      lineHeight: "6px",
      minWidth: "18px",
      right: "-18%",
    },
  },
  btnApply: {
    marginLeft: "10px",
  },
  divider: {
    borderColor: "#CBD5E0",
    margin: "24px",
  },
  dividerBorder: {
    border: "1px solid #F0F4F9",
    margin: "24px 0px 24px 0px",
  },
  filterSideBar: {
    zIndex: "20000",
    "& .MuiDrawer-paper": {
      boxSizing: "unset",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px 0px 0px 8px",
      flexDirection: "column",
      height: "100%",
      maxWidth: "447px",
    },
  },
  filterSideBarBody: {
    "& .MuiAccordion-root": {
      width: "100%",
    },
    height: "100%",
    overflow: "overlay",
    overflowY: "auto",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  filterSideBarFooter: {
    background: "#F7FAFC",
    borderRadius: "0px 0px 8px 8px",
    bottom: "0px",
    display: "block",
    height: "36px",
    justifyContent: "end",
    padding: "16px 24px",
    textAlign: "end",
  },
  filterSideBarHeader: {
    padding: "24px 24px 0px 24px",
    width: "calc(100% - 48px)",
  },
  iconButton: {
    background: "#FFFFFF",
    border: "1px solid #FFFFFF",
    borderRadius: "48px",
    boxShadow:
      "0px 51px 80px rgba(106, 167, 197, 0.07), 0px 33.0556px 46.8519px rgba(106, 167, 197, 0.0531481), 0px 19.6444px 25.4815px rgba(106, 167, 197, 0.0425185), 0px 10.2px 13px rgba(106, 167, 197, 0.035), 0px 4.15556px 6.51852px rgba(106, 167, 197, 0.0274815), 0px 0.944444px 3.14815px rgba(106, 167, 197, 0.0168519)",
    boxSizing: "border-box",
  },
  linkBox: {
    float: "right",
    paddingTop: "2px",
  },
  minimizeButton: {
    textDecoration: "none",
  },
  paperProps: {
    backgroundColor: "red",
  },
  typographyBox: {
    display: "inline-block",
  },
});
