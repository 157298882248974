import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import DispersionTabs from "../../components/Tabs/DispersionTabs";
import {
  breadcrumbValues,
  LabelsDispersion,
} from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { RequestDispersion } from "../../components/RequestDispersion/RequestDispersion";
import { ProcessedDispersions } from "../../components/ProcessedDispersions/ProcessedDispersions";
import { usePayoutsDispersionIndexState } from "./state/usePayoutsDispersionIndexState";

export interface IUsePayoutsDispersionIndex {
  handleSetValue: (value: number) => void;
}

export interface IUsePayoutsDispersionIndexState {
  value: number;
  handleSetValue: (value: number) => void;
}

export const PayoutsDispersionIndex: React.FC = () => {
  const { value, handleSetValue } = usePayoutsDispersionIndexState();

  return (
    <Container fixed>
      <Breadcrumb {...breadcrumbValues} />
      <Grid container>
        <Grid container item xs={12} direction={"column"}>
          <Grid item>
            <Typography variant={"h1"} color={"primary"}>
              {LabelsDispersion.DISPERSION}
            </Typography>
            <DispersionTabs
              value={value}
              handleSetValue={handleSetValue}
              requestDispersionComponent={
                <RequestDispersion handleSetValue={handleSetValue} />
              }
              dispersionRequestedComponent={<ProcessedDispersions />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
