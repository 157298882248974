import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { format } from "date-fns-tz";
import { get, isEmpty } from "lodash";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { TransactionSummaryData } from "../../../types/transaction_summary_data";
import NumberFormat from "react-number-format";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { DownloadFile } from "../DownloadFile/DownloadFile";
import { BankConciliationStatusEnum } from "../../shared/infrastructure/BankConciliationStatusEnum";
import { FormatDate } from "../../shared/infrastructure/constants/FormatDate";

export interface IModalDialogDetail {
  open?: boolean;
  handleCloseDialogDetail?: () => void;
  transactionSumaryData: TransactionSummaryData;
  statusFlow: StatusFlowEnum;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.dark,
    fontSize: "16px",
  },
  buttonClose: {
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    marginTop: "0px !important",
    maxHeight: "50px !important",
    background: "#023365",
    borderRadius: "4px",
    padding: "7px 38px",
    "&:hover": {
      background: "#023365",
    },
  },
  iconButtonClose: {
    position: "absolute",
    alignContent: "end",
  },
  dialog: {
    padding: "20px 27px !important",
    width: "723px",
  },
  titleText: {
    fontSize: "24px !important",
    fontStyle: "normal",
    fontWeight: "bolder",
    margin: "10px",
    color: "#023365",
    lineHeight: "120%",
  },
  alert: {
    marginTop: "15px",
  },
  labelContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "370px",
  },
  labelContent: {
    display: "flex",
    flexWrap: "nowrap",
  },
  downloadButton: {
    marginLeft: "280px",
  },
  titleDialog: {
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: 500,
    margin: "10px",
    color: "#023365",
    lineHeight: "120%",
  },
  dateDialog: {
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: 500,
    margin: "10px",
    color: "#6D7781",
    lineHeight: "120%",
  },
  textDialog: {
    fontSize: "16px !important",
    marginBottom: "10px !important",
    marginLeft: "10px !important",
    color: "#6D7781",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none !important",
    borderRadius: "0px !important",
    backgroundColor: "#F7FAFC !important",
    marginBottom: "20px",
  },
  modifiedDateText: {
    fontSize: "16px !important",
    marginTop: "-10px !important",
    marginLeft: "10px !important",
  },
}));

export const ModalDialogDetail: React.FC<IModalDialogDetail> = (
  props: IModalDialogDetail
) => {
  const styles = useStyles();
  const formatDate = (trxDate: number, formatDate: string): string => {
    if (!trxDate) return "";
    const date: string = new Date(
      trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
    ).toISOString();

    return format(new Date(date.replace("Z", "")), formatDate, {
      timeZone: "UTC",
    });
  };

  const formatNumber = (value: number, currency: string) => {
    return (
      <NumberFormat
        value={value}
        thousandSeparator
        displayType={"text"}
        decimalScale={2}
        suffix={` ${currency}`}
      />
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open!}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.dialog}>
          <Grid container xs={12} direction={"row"}>
            <Grid
              style={{
                fontSize: 15,
                fontWeight: 500,
                padding: "0px 15px",
              }}
              item
              xs={9}
            >
              <Typography
                variant={"h5"}
                color="textPrimary"
                className={styles.titleText}
              >
                {get(
                  props.transactionSumaryData,
                  "bank_conciliation_status",
                  ""
                ) === BankConciliationStatusEnum.OMIT
                  ? "Registro omitido por "
                  : get(
                      props.transactionSumaryData,
                      "bank_conciliation_status",
                      ""
                    ) === BankConciliationStatusEnum.CONCILIATION &&
                    get(props, "statusFlow", "") === StatusFlowEnum.SUMMARY
                  ? "Registro Creado por "
                  : "Transacción creada por "}
                {get(props.transactionSumaryData, "user_name", "")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <IconButton
                  onClick={props.handleCloseDialogDetail}
                  className={styles.iconButtonClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <DialogContent style={{ padding: "0px 15px" }}>
            <Grid container xs={12}>
              {get(props.transactionSumaryData, "created") && (
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={styles.dateDialog}
                  >
                    Fecha de registro:{" "}
                    {formatDate(
                      get(props.transactionSumaryData, "created", 0),
                      FormatDate.DMY_dash
                    )}
                  </Typography>
                </Grid>
              )}
              {get(
                props.transactionSumaryData,
                "bank_conciliation_status",
                ""
              ) === BankConciliationStatusEnum.CONCILIATION &&
                get(props, "statusFlow", "") === StatusFlowEnum.SUMMARY && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={styles.titleDialog}
                    >
                      Valor Liquidado:
                    </Typography>
                    <Typography variant="body2" className={styles.textDialog}>
                      {formatNumber(
                        get(
                          props.transactionSumaryData,
                          "liquidation_amount",
                          0
                        ),
                        get(props.transactionSumaryData, "currency_code", "USD")
                      )}
                    </Typography>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={styles.titleDialog}
                >
                  Motivo:
                </Typography>
                <Typography variant="body2" className={styles.textDialog}>
                  {get(props.transactionSumaryData, "comment", "")}
                </Typography>
              </Grid>
              {get(
                props.transactionSumaryData,
                "bank_conciliation_status",
                ""
              ) === BankConciliationStatusEnum.CONCILIATION &&
                !isEmpty(get(props.transactionSumaryData, "file_name", "")) &&
                !isEmpty(get(props.transactionSumaryData, "file_size", "")) && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={styles.titleDialog}
                    >
                      Respaldo Bancario:
                    </Typography>
                    <Typography variant="body2" className={styles.textDialog}>
                      <DownloadFile
                        path={get(props.transactionSumaryData, "path", "")}
                        origin={props.statusFlow}
                        fileSize={get(
                          props.transactionSumaryData,
                          "file_size",
                          ""
                        )}
                      />
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "7px 38px !important" }}>
            <Button
              onClick={props.handleCloseDialogDetail}
              className={styles.buttonClose}
            >
              {"Salir"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
