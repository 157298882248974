import React, { FC } from "react";
import AccountPreferencesForm from "../../components/AccountPreferencesForm/AccountPreferencesForm";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";

const MainContainer: FC = () => {
  return (
    <WrapperContainer>
      <AccountPreferencesForm title={"Preferencias de cuenta"} />
    </WrapperContainer>
  );
};

export default MainContainer;
