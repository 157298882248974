import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material";
import React from "react";
import {
  BankAccountFields,
  BankAccountPlaceholders,
} from "../../shared/enums/dispersionsEnum";
import NumericInputField from "../FormInputFields/NumericInputField/NumericInputField";

import SelectItemsField from "../FormInputFields/SelectItemsField/SelectItemsField";
import { Category } from "../../shared/interfaces/category";
import { CatalogsEnum } from "../../shared/enums/CatalogsEnum";
import RadioButtonField from "../FormInputFields/RadioButtonField/RadioButtonField";
import { CountriesEnum } from "../../shared/enums/countriesEnum";
import AlfaNumericInput from "../FormInputFields/AlfaNumericInput/AlfaNumericInput";
import { accountRules } from "../../shared/constants/form_account_labels";
import { useAddBankAccountDifferentCountryState } from "./state/useAddBankAccountDifferentCountryState";
import { IAddBankAccountSameCountryFlowProps } from "../AddBankAccountSameCountryFlow/AddBankAccountSameCountryFlow.interfaces";
import { SelectCountry } from "../SelectCountry/SelectCountry";
import { CountryListType } from "../../shared/constants/select_country_constants";
import InfoText from "../InfoText/InfoText";
import { ADD_BANK_ACCOUNT_MODAL_LABELS } from "../../shared/constants/labels/add_bank_account_modal_labels";
import SelectCurrency from "../FormInputFields/SelectCurrency/SelectCurrency";
import InternationalTransferConfiguration from "../InternationalTranferConfiguration/InternationalTransferConfiguration";
import { dispersionFormStyles as styles } from "../DispersionForm/DispersionForm.styles";
import { ACCOUNT_TYPE_LABEL } from "../../shared/constants/labels/dispersion_container_labels";
import { IBAN_COUNTRIES } from "../../shared/constants/catalogs/catalog-iban-countries";
import { defaultTo } from "lodash";

const AddBankAccountDifferentCountryFlow = ({
  changeCountry,
  country,
  countryIs,
  currenciesList,
  banksList,
  getCatalogToRender,
  banksForm,
  changeIsInternationalTransfer,
  isInternationalTransfer,
  isEditingForm,
  isDispersionTab,
}: IAddBankAccountSameCountryFlowProps) => {
  const {
    isCountryInKushkiNation,
    isCountryWithIBAN,
    isCountryWithSWIFT,
    isCountryWithRoutingNumber,
    isInvalidCurrency,
    dynamicRules,
    onChangeCurrency,
  } = useAddBankAccountDifferentCountryState({
    banksForm,
    changeIsInternationalTransfer,
    country,
    isDispersionTab,
    isEditingForm,
    isInternationalTransfer,
  });
  const sortByName = (a: { name: string }, b: { name: string }): number =>
    a.name.localeCompare(b.name);

  const sortBankAccounts: Category[] = defaultTo(banksList, []).sort(
    sortByName
  );

  const sortedCurrencies: Category[] = defaultTo(currenciesList, []).sort(
    sortByName
  );

  return (
    <Grid container display={"flex"} flexDirection={"row"}>
      {isInternationalTransfer && (
        <Grid item xs={12}>
          <InfoText
            text={
              ADD_BANK_ACCOUNT_MODAL_LABELS.principal
                .internationalDispersionHelperText
            }
          />
        </Grid>
      )}
      <Grid item xs={6} sx={{ p: 1 }}>
        <SelectCountry
          onChange={changeCountry}
          placeHolder={"País de origen de la cuenta bancaria"}
          typeList={CountryListType.ALL}
          value={country}
        />
      </Grid>
      <Grid item xs={6} sx={{ p: 1 }}>
        <SelectCurrency
          name={BankAccountFields.CURRENCY}
          control={banksForm.control}
          errors={banksForm.errors}
          label={"Moneda de la cuenta de destino"}
          disabled={sortedCurrencies.length === 1}
          onChange={onChangeCurrency}
          defaultValue={sortedCurrencies[0].value}
          items={sortedCurrencies.map((item: Category, index: number) => (
            <MenuItem key={`df-${index}`} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={6} sx={{ p: 1 }}>
        {isCountryInKushkiNation ? (
          <SelectItemsField
            name={BankAccountFields.BANK_ID}
            control={banksForm.control}
            errors={banksForm.errors}
            label={"Nombre de la institución financiera"}
            items={sortBankAccounts.map((item: Category, index: number) => (
              <MenuItem key={`df-${index}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          />
        ) : (
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.BANK_NAME}
            label={BankAccountPlaceholders.BANK_NAME}
            rules={dynamicRules.financialInstitutionName}
          />
        )}
      </Grid>
      <Grid item xs={6} sx={{ p: 1 }}>
        <AlfaNumericInput
          control={banksForm.control}
          errors={banksForm.errors}
          name={BankAccountFields.FINANCIAL_INSTITUTION_ADDRESS}
          label={BankAccountPlaceholders.FINANCIAL_INSTITUTION_ADDRESS}
          rules={dynamicRules.financialInstitutionAddress}
        />
      </Grid>
      {isCountryWithRoutingNumber ? (
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item xs={6} sx={{ p: 1 }}>
            <AlfaNumericInput
              control={banksForm.control}
              errors={banksForm.errors}
              name={BankAccountFields.BENEFICIARY_NAME}
              label={BankAccountPlaceholders.BENEFICIARY_NAME}
              rules={dynamicRules.beneficiaryName}
            />
          </Grid>
          <Grid item xs={6} sx={{ p: 1 }}>
            <AlfaNumericInput
              control={banksForm.control}
              errors={banksForm.errors}
              name={BankAccountFields.ROUTING_NUMBER}
              label={BankAccountPlaceholders.ROUTING_NUMBER}
              rules={accountRules.routingNumber}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} sx={{ p: 1 }}>
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.BENEFICIARY_NAME}
            label={BankAccountPlaceholders.BENEFICIARY_NAME}
            rules={dynamicRules.beneficiaryName}
          />
        </Grid>
      )}
      {isDispersionTab && (
        <InternationalTransferConfiguration
          banksForm={banksForm}
          changeIsInternationalTransfer={changeIsInternationalTransfer}
          isInternationalTransfer={isInternationalTransfer}
          isInvalidCurrency={isInvalidCurrency}
          country={country}
        />
      )}

      {!isInternationalTransfer && (
        <>
          <Grid item xs={12} sx={{ pt: 1, px: 1 }}>
            <Typography color="text.grey" variant="body2" sx={styles.label}>
              {ACCOUNT_TYPE_LABEL}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: 1 }}>
            <RadioButtonField
              name={BankAccountFields.ACCOUNT_TYPE}
              control={banksForm.control}
              errors={banksForm.errors}
              items={getCatalogToRender(
                country,
                CatalogsEnum.BankAccountTypes
              ).map((option: Category) => (
                <FormControlLabel
                  key={`rb-${option.value}`}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Typography color="text.dark" variant="caption">
                      {option.name}
                    </Typography>
                  }
                />
              ))}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sx={{ p: 1 }}>
        <NumericInputField
          control={banksForm.control}
          customErrorMessages={{
            required: "Debe ingresar el número de cuenta.",
          }}
          maxLengthValue={isInternationalTransfer ? 34 : undefined}
          decimalScale={0}
          errors={banksForm.errors}
          allowLeadingZeros={true}
          helperText={""}
          isNumericString={true}
          isNumeric={isCountryInKushkiNation}
          label={"Número de la cuenta bancaria"}
          name={BankAccountFields.ACCOUNT_NUMBER}
          thousandSeparator={""}
        />
      </Grid>

      {countryIs(CountriesEnum.ESTADOS_UNIDOS) && !isInternationalTransfer && (
        <Grid data-testid="aba-code-id" item xs={6} sx={{ p: 1 }}>
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.ABA_CODE}
            label={BankAccountPlaceholders.ABA_CODE}
            rules={accountRules.abaCode}
          />
        </Grid>
      )}

      {(isCountryWithSWIFT || isInternationalTransfer) && (
        <Grid data-testid="swift-code-id" item xs={6} sx={{ p: 1 }}>
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.SWIFT_CODE}
            label={BankAccountPlaceholders.SWIFT_CODE}
            rules={dynamicRules.swiftCode}
          />
        </Grid>
      )}

      {((isCountryWithIBAN && !isInternationalTransfer) ||
        (IBAN_COUNTRIES.includes(country) && isInternationalTransfer)) && (
        <Grid item xs={6} sx={{ p: 1 }}>
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.IBAN_CODE}
            label={BankAccountPlaceholders.IBAN_CODE}
            rules={dynamicRules.ibanCode}
          />
        </Grid>
      )}

      {(countryIs(CountriesEnum.MEXICO) || countryIs(CountriesEnum.PERU)) &&
        !isInternationalTransfer && (
          <Grid item xs={6} sx={{ p: 1 }}>
            <NumericInputField
              control={banksForm.control}
              customErrorMessages={{
                required: `Debe ingresar el número de cuenta ${
                  countryIs(CountriesEnum.MEXICO) ? "CLABE/" : ""
                }Interbancaria de su cuenta de cobro.`,
              }}
              decimalScale={0}
              errors={banksForm.errors}
              allowLeadingZeros={true}
              helperText={""}
              isNumericString={true}
              label={
                countryIs(CountriesEnum.PERU)
                  ? "Número de cuenta Interbancario"
                  : "Número de la cuenta CLABE"
              }
              name={BankAccountFields.KEY_ACCOUNT_NUMBER}
              thousandSeparator={""}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default AddBankAccountDifferentCountryFlow;
