/**
 * Billing Transaction Status Enum
 */
import { CountryNameEnum } from "./constants/CountrysEnum";

export const UTC_FORMAT_TIME: Record<CountryNameEnum, string> = {
  [CountryNameEnum.Ecuador]: "UTC - 5",
  [CountryNameEnum.Perú]: "UTC - 5",
  [CountryNameEnum.Colombia]: "UTC - 5",
  [CountryNameEnum.Chile]: "UTC - 4",
  [CountryNameEnum.México]: "UTC - 6",
};
