import { IStyles } from "../../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  container: {
    marginLeft: "15px",
    paddingBottom: "16px",
    paddingTop: "16px",
    width: "100%",
  },
};
