import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const infoContentDataStyles = createNamedStyles({
  gridContainer: { width: "100%", marginLeft: 0, marginTop: 0 },
  texBillingTitle: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "21.6px",
  },
  descriptionCards: {
    color: "#293036",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "21.6px",
  },
  descriptionCardsTable: {
    color: "#293036",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21.6px",
  },

  texBillingTitleTable: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21.6px",
  },
  flex: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  },
});
