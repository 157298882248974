import { formatCurrency } from "../../../../../../shared/utils/currency_utils";
import { getDate, getHour } from "../../../../../../shared/utils";
import { paymentMethodMap } from "../../../../../../shared/constants/paymentMethodMap";
import { TimeLineItem } from "../../../../../../shared/interfaces/TimeLineItem.interfaces";
import { HistoricalItemStatus } from "../../../../../../store/interfaces/TransactionsState.interfaces";
import { transactionTitleMap } from "../../../../../../shared/constants/transactionTitleMap";
import { transactionIconMap } from "../../../../../../shared/constants/transactionIconMap";
import {
  ITimeLineTabState,
  IUseTimeLineTabStateProps,
} from "./useTimeLineTabState.interfaces";

/**
 * helper functions
 */

const getItemTitle = (eventType: string, status: string): string => {
  return transactionTitleMap[`${eventType}_${status}`] ?? "";
};

const getItemIcon = (eventType: string, status: string) => {
  return transactionIconMap[`${eventType}_${status}`];
};

/**
 * main function/hook
 */

const useTimeLineTabState = ({
  historicalItems,
}: IUseTimeLineTabStateProps): ITimeLineTabState => {
  const sortedHistoricalItems = (historicalItems ?? [])
    .slice()
    .sort((a, b) => Number(a.updatedAt) - Number(b.updatedAt))
    .filter((item) => item.status !== HistoricalItemStatus.REFUND);

  const timelineItems = sortedHistoricalItems.map<TimeLineItem>((hItem) => {
    const title = getItemTitle(hItem.eventType ?? "", hItem.status ?? "");
    const icon = getItemIcon(hItem.eventType ?? "", hItem.status ?? "");
    const paymentMethod = hItem.paymentMethod
      ? paymentMethodMap[hItem.paymentMethod]
      : "";
    const notes = hItem.amount
      ? `Monto solicitado: ${formatCurrency(hItem.amount)} COP`
      : "";
    const date = hItem.updatedAt ? `${getDate(Number(hItem.updatedAt))}` : "";
    const time = hItem.updatedAt ? `${getHour(Number(hItem.updatedAt))}` : "";

    return {
      date,
      icon,
      notes,
      time,
      title,
      type: paymentMethod,
    };
  });

  return {
    items: timelineItems,
  };
};

export default useTimeLineTabState;
