import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const columnConfigurationContentStyles: IStyles = {
  checkButton: {
    "&.Mui-checked, &.MuiCheckbox-root": {
      color: "primary.main",
    },
  },
  label: {
    color: "text.dark",
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
  },
};

export { columnConfigurationContentStyles };
