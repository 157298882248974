import { Typography } from "@mui/material";
import React, { FC } from "react";
import { textLineStyles as styles } from "@components/TextLine/TextLine.styles";

export interface ITextLineProps {
  text: string;
}

const TextLine: FC<ITextLineProps> = ({ text }: ITextLineProps) => {
  return (
    <>
      <Typography variant="subtitle2" sx={styles.text}>
        {text}
      </Typography>
    </>
  );
};

export default TextLine;
