import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";

export const shareHoldersStyles: IStyles = {
  boxContent: {
    alignItems: "center",
    backgroundColor: "#F0F4F9",
    borderRadius: "8px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    padding: "20px 15px",
  },
  txtBox: {
    fontSize: "18px",
    ml: 2,
  },
};
