import React, { FC } from "react";
import { IWrapperContainerProps } from "@containers/WrapperContainer/WrapperContainer.interfaces";
import { useWrapperContainer } from "@containers/WrapperContainer/state/useWrapperContainer";

export const WrapperContainer: FC<IWrapperContainerProps> = ({
  children,
}: IWrapperContainerProps) => {
  const {} = useWrapperContainer();

  return <>{children}</>;
};
