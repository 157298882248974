import React, { FC } from "react";
import { mainConfigDataCardStyles as styles } from "./ConfigDataCard.styles";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { IConfigDataCardProps } from "./ConfigDataCard.interfaces";
import ConfigButtonSection from "../ConfigButtonSection/ConfigButtonSection";

export const ConfigDataCard: FC<IConfigDataCardProps> = ({
  cardContent,
  configureButton,
  hideEditBtn,
  isCentralized,
}: IConfigDataCardProps) => {
  const { content } = cardContent;

  return (
    <Box>
      <ConfigButtonSection
        cardContent={cardContent}
        configureButton={configureButton}
        hideEditBtn={hideEditBtn}
        isCentralized={isCentralized}
      />
      <Paper elevation={0}>
        {content.map((rowContent) => (
          <Grid sx={styles.outerPadding} key={rowContent.subtitle}>
            <Grid sx={styles.mb1}>
              <Typography color="text.primary" variant="subtitle2">
                {rowContent.subtitle}
              </Typography>
            </Grid>
            {rowContent.rowValues.map((rowValue, idx) => (
              <Grid container key={rowValue.label ?? idx}>
                {rowValue.label && (
                  <Grid sx={styles.mb1} flexDirection={"column"} item xs={3}>
                    <Typography color="text.primary" variant="body2">
                      {rowValue.label}:
                    </Typography>
                  </Grid>
                )}
                {rowValue.value && (
                  <Grid sx={styles.mb1} flexDirection={"column"} item xs={6}>
                    <Typography color="text.primary" variant="body2">
                      {rowValue.value}
                    </Typography>
                  </Grid>
                )}
                {rowValue.chips && (
                  <Grid sx={styles.mb1} flexDirection={"column"} item xs={6}>
                    {rowValue.chips.map((chipStr, idx) => (
                      <Chip
                        sx={styles.marginChips}
                        key={`${chipStr}${idx}`}
                        label={chipStr}
                      />
                    ))}
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        ))}
      </Paper>
    </Box>
  );
};

export default ConfigDataCard;
