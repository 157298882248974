import { MassiveUserForm } from "../../interfaces/massiveUserForm.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { downloadMassiveUser } from "../../thunks/massiveUserForm/massiveUserForm.thunk";
import { MassiveUserResponse } from "../../../../types/massive_user_response";
import { get } from "lodash";
export const initialState: MassiveUserForm = {
  base64File: "",
  fileValidated: {},
  isLoadingSocket: null,
  loadingDownload: false,
  notification: undefined,
};

export const massiveUserFormSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(downloadMassiveUser.fulfilled, (state, { payload }) => {
        state.loadingDownload = false;
        state.signedUrl = get(payload, "data");
      })
      .addCase(downloadMassiveUser.pending, (state) => {
        state.loadingDownload = true;
      });
  },
  initialState,
  name: "massiveUserForm",
  reducers: {
    setBase64File: (state, { payload }: PayloadAction<string>) => {
      state.base64File = payload;
    },
    setFileValidated: (state, { payload }) => {
      state.fileValidated = payload;
    },
    setIsLoadingSocket: (state, { payload }) => {
      state.isLoadingSocket = payload;
    },
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setUrlMassiveUser: (
      state,
      { payload }: PayloadAction<MassiveUserResponse>
    ) => {
      state.signedUrl = payload;
    },
  },
});

export default massiveUserFormSlice.reducer;
export const {
  setUrlMassiveUser,
  setBase64File,
  setFileValidated,
  setIsLoadingSocket,
  setNotification,
} = massiveUserFormSlice.actions;
