import React, { useState } from "react";

export interface IUsePopUpDeferredQuotasState {
  anchorEl: null | HTMLElement;
  actions: {
    handleCloseEl(): void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  };
}

export const usePopUpDeferredQuotasState = (): IUsePopUpDeferredQuotasState => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleCloseEl = (): void => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return {
    actions: {
      handleClick,
      handleCloseEl,
    },
    anchorEl,
  };
};
