export enum FieldNameEnum {
  RETENTION_CYCLE = "cycle",
  PROCESSOR_TYPE = "processorType",
  RET_IVA = "retIva",
  RET_ICA = "retIca",
  RET_FUE = "retFue",
}

export enum FieldSnakeNameEnum {
  START_DATE = "start_date",
}
