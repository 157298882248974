import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { defaultTo, get, isEmpty } from "lodash";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { IUseConditionItemComponentStateProps } from "../../../../../shared/interfaces/use_condition_item_component_state_props";
import { useAppSelector } from "../../../../../store/hooks/storeHook";
import { IUseConditionItemComponentState } from "../../../../../shared/interfaces/use_condition_item_component_state";
import {
  Conditions,
  Operators,
} from "../../../../../../types/configuration_response";
import { IConditionItemComponentProps } from "../../../../../shared/interfaces/contition_item_component_props";
import { TextFieldContainer } from "../../TextFieldContainer/TextFieldContainer";
import { getBasicMerchantInformation } from "../../../../../shared/utils/localStorage";
import { RootState } from "../../../../../store/store";
import { IBasicMerchantInformation } from "../../../../../shared/interfaces/IBasicMerchantInformation";

export const useConditionItemComponentState = (
  props: PropsWithChildren<IUseConditionItemComponentStateProps>
): IUseConditionItemComponentState => {
  const { configList } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));

  const basePathProps: IConditionItemComponentProps = get(
    props,
    "conditionsItemComponentProps"
  );

  const { control, watch, setValue, register, errors } = useFormContext();
  const [selectedItems, setSelectedItems] = useState<ICategory[]>([]);
  const [availableConditions, setAvailableConditions] = useState<Conditions[]>(
    []
  );

  useEffect(() => {
    const payoutBusinessConditions: Conditions[] = get(
      configList,
      "payoutBusinessConditions",
      []
    );

    setAvailableConditions(payoutBusinessConditions);
  }, [configList]);

  const rule: string = defaultTo(
    watch(`conditions.${basePathProps.index}.field`),
    ""
  );
  const operator: string = defaultTo(
    watch(`conditions.${basePathProps.index}.operator`),
    get(basePathProps, "item.operator", "")
  );

  const saveInArray = (): string[] => {
    return selectedItems.map((x: ICategory) => x.value);
  };
  const value: string[] = useMemo(() => saveInArray(), [selectedItems]);

  const merchantInformation: IBasicMerchantInformation = useMemo(
    () => getBasicMerchantInformation(),
    []
  );

  useEffect(() => {
    register(`conditions.${basePathProps.index}.value`);
  }, [basePathProps.index]);

  useEffect(() => {
    setValue(`conditions.${basePathProps.index}.value`, value);
  }, [value]);

  const getOperatorsByConditions = (): Operators[] => {
    const action: Conditions | undefined = get(
      configList,
      "payoutBusinessConditions",
      []
    ).find((conditions: Conditions) => {
      return conditions.field === rule;
    });
    const newInfo: Operators[] = [];

    if (action !== undefined) {
      get(action, "operators", "")
        .split(",")
        .forEach((itemOperator: string) => {
          const operator = get(configList, "operators", []).find(
            (item: Operators) => item.value === itemOperator
          );

          if (operator) newInfo.push(operator);
        });
    }

    if (!isEmpty(newInfo)) {
      return newInfo;
    }

    return [];
  };

  const isFilledTypeOperator = (): boolean => {
    return (
      (operator != "all" && operator != "") ||
      !isEmpty(get(basePathProps, "item.operator", ""))
    );
  };

  const retrieveTextFieldsByRuleAndOperator = (): JSX.Element => (
    <TextFieldContainer
      operatorValue={operator}
      className={props.classes.subTitleConditions}
      mainProps={props}
      setSelectedItems={setSelectedItems}
      rule={rule}
      country={[merchantInformation.country]}
    />
  );

  return {
    control,
    errors,
    getOperatorsByConditions,
    isFilledTypeOperator,
    retrieveTextFieldsByRuleAndOperator,
    selectConditions: availableConditions,
  };
};
