import { makeStyles } from "@mui/styles";

export const simpleModalWithSelectStyles = makeStyles({
  mainDialog: {
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: 500,
    fontSize: "20px",
    color: "#023365",
  },
  formControl: {
    marginTop: "6px",
  },
  mainGrid: {
    paddingTop: "20px",
  },
  cancelButton: {
    color: "#293036",
    boxShadow: "none",
  },
});
