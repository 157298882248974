import { createStyles, makeStyles } from "@material-ui/core/styles";

const alertColor: string = "#AD0C2A";
const confirmColor: string = "#023365";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: alertColor,
    },
    subtitle: {
      color: "#6D7781",
      padding: "24px 0",
    },
    legend: {
      color: "#023365",
    },
    btnAgreed: {
      marginTop: "10px",
      padding: "10px 40px",
      backgroundColor: confirmColor,
      color: "#fff",
      "&:hover": {
        backgroundColor: confirmColor,
        color: "#fff",
      },
      "&:disabled": {
        backgroundColor: "#FAFAFA",
      },
    },
    headTitle: { color: "#293036" },
    actions: {
      paddingRight: "24px",
      paddingBottom: "24px",
    },
    columnItem: {
      paddingLeft: "0",
      color: "#6D7781",
    },
  })
);

export default useStyles;
