import { ICatalogDetailState } from "../CatalogDetail.interfaces";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  setCatalogRequest,
  setIsOpenCatalogInfoPopup,
  setIsOpenCatalogFormPopup,
  setIsOpenCatalogConfirmPopup,
  getRetentionCatalogsByCountry,
  downloadCsvFileOfMerchantConcernedByCatalog,
} from "../../../store/actionCreators";
import { ActionEnum } from "../../../shared/infrastructure/Enums/ActionEnum";
import { IAppState } from "../../../store/reducer";
import {
  getRetentionType,
  verifyItemCatalogsConfig,
} from "../../../utils/functions/Catalog";
import { CountryRetentionEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { useEffect } from "react";
import { get, defaultTo } from "lodash";

export const useCatalogDetailState = (): ICatalogDetailState => {
  const dispatch: Dispatch<any> = useDispatch();
  const { catalogsList, catalogRequest, retention, isLoading } = useSelector<
    IAppState,
    IAppState
  >((state: IAppState) => state);

  const handleDeleteItem = (
    catalogName: string,
    id: string,
    value: string,
    code: string
  ): void => {
    dispatch(
      getRetentionCatalogsByCountry(
        CountryRetentionEnum.ecuador,
        getRetentionType(catalogName)
      )
    );
    dispatch(
      downloadCsvFileOfMerchantConcernedByCatalog({
        filter: catalogName,
        code,
      })
    );
    dispatch(
      setCatalogRequest({
        action: ActionEnum.DELETE,
        id,
        value,
        code,
      })
    );
  };

  const handleEditItem = (id: string, value: string, code: string): void => {
    dispatch(
      setCatalogRequest({
        action: ActionEnum.UPDATE,
        id,
        value,
        code,
      })
    );
    dispatch(setIsOpenCatalogFormPopup(true));
  };

  useEffect(() => {
    if (
      retention &&
      retention.length > 0 &&
      get(catalogRequest, "action") === ActionEnum.DELETE
    ) {
      const canDelete: boolean = !verifyItemCatalogsConfig(
        catalogsList!,
        retention!,
        catalogRequest!
      );
      if (canDelete) dispatch(setIsOpenCatalogConfirmPopup(true));
      else dispatch(setIsOpenCatalogInfoPopup(true));
    }
  }, [retention]);

  return {
    isLoading: defaultTo(isLoading, false),
    handleDeleteItem,
    handleEditItem,
  };
};
