import { ColumnsItem } from "@kushki/connect-ui";
import { ColumnSelectEnum } from "../enums/ColumnSelectEnum";
import { HeaderColumnIdEnum } from "../enums/HeaderColumnIdEnum";
import { auth } from "../auth";

export const column_selected: ColumnsItem[] = [
  {
    field: HeaderColumnIdEnum.AMOUNT,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.AMOUNT,
  },
  {
    field: HeaderColumnIdEnum.PAYMENT_SUB_METHOD_TYPE,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.PAYMENT_SUB_METHOD_TYPE,
  },
  {
    field: HeaderColumnIdEnum.TICKET_NUMBER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.TICKET_NUMBER,
  },
  {
    field: HeaderColumnIdEnum.CARD_NUMBER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.CARD_NUMBER,
  },
  {
    field: HeaderColumnIdEnum.CARD_BRAND,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.CARD_BRAND,
  },
  {
    field: HeaderColumnIdEnum.ISSUING_BANK,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.ISSUING_BANK,
  },
  {
    field: HeaderColumnIdEnum.SUBSCRIPTION_ID,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.SUBSCRIPTION_ID,
  },
  {
    field: HeaderColumnIdEnum.NAME_ON_CARD,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.NAME_ON_CARD,
  },
  {
    field: HeaderColumnIdEnum.CREDENTIAL_ID,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.CREDENTIAL_ID,
  },
  {
    field: HeaderColumnIdEnum.CREDENTIAL_ALIASES,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.CREDENTIAL_ALIASES,
  },
  {
    field: HeaderColumnIdEnum.PUBLIC_KEY,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.PUBLIC_KEY,
  },
];

export const column_selected_hierarchy: ColumnsItem[] = [
  {
    field: HeaderColumnIdEnum.OWNER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.OWNER,
  },
  {
    field: HeaderColumnIdEnum.CUSTOMER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.CUSTOMER,
  },
];

export const column_static_user_start: ColumnsItem[] = [
  {
    field: HeaderColumnIdEnum.START_DATE,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.START_DATE,
  },
  {
    field: HeaderColumnIdEnum.CHARGEBACK_NUMBER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.CHARGEBACK_NUMBER,
  },
];

export const column_static_start: ColumnsItem[] = [
  {
    field: HeaderColumnIdEnum.START_DATE,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.START_DATE,
  },
  {
    field: HeaderColumnIdEnum.DATE_MAX,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.DATE_MAX,
  },
  {
    field: HeaderColumnIdEnum.BRANCH,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.BRANCH,
  },
  {
    field: HeaderColumnIdEnum.CHARGEBACK_NUMBER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.CHARGEBACK_NUMBER,
  },
];

export const column_static_end: ColumnsItem = {
  field: HeaderColumnIdEnum.STATUS,
  isDisabled: false,
  isSelected: true,
  label: ColumnSelectEnum.STATUS,
};

export const getMaxColumns = (): 1 | 2 | 3 | 4 | 5 | 6 | 7 => {
  if (auth.isAdmin()) return 6;

  return 4;
};
