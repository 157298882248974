import { Box, Toolbar, Typography } from "@material-ui/core";
import visaLogo from "../../assets/images/logos/brands/Visa.svg";
import mastercardLogo from "../../assets/images/logos/brands/Master.svg";
import dinersLogo from "../../assets/images/logos/brands/Diners.svg";
import amex from "../../assets/images/logos/brands/Amex.svg";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrandDeferredEnum } from "../../shared/infrastructure/constants/BrandDeferredEnum";

export interface ConfigDeferredHeaderProps {
  isMobile: boolean;
  type: string;
  brandList: string[];
}

const useStyles = makeStyles(() => ({
  cardH4: {
    color: "#293036",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "26px",
  },
  cardLogos: {
    height: "32px",
    paddingRight: "8px",
  },
  label: {
    color: "#6D7781",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.08px",
  },
  mainContent: {
    color: "#293036",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "28.2px",
    paddingTop: "24px",
  },
}));

export const getBrandIcon = (brand: string): string => {
  switch (brand) {
    case BrandDeferredEnum.VISA:
      return visaLogo;
    case BrandDeferredEnum.MASTERCARD:
      return mastercardLogo;
    case BrandDeferredEnum.DINERS:
      return dinersLogo;
    case BrandDeferredEnum.AMEX:
      return amex;
    default:
      return visaLogo;
  }
};

export const ConfigDeferredHeader = (props: ConfigDeferredHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      {!props.isMobile ? (
        <Toolbar disableGutters>
          <Box display="flex" flexGrow={1}>
            <Box>
              <Typography className={classes.cardH4}>
                Configuraciones
              </Typography>
              <Typography className={classes.mainContent}>
                {props.type}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.label}>
              Tarjetas a las que aplica la configuración
            </Typography>
            <Box display="flex" alignItems="center">
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                pt={"8px"}
                width={"100%"}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                >
                  {props.brandList.map((brand: string, index: number) => (
                    <img
                      key={index}
                      src={getBrandIcon(brand)}
                      className={classes.cardLogos}
                      alt={`${brand}-icon`}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      ) : (
        <>
          <Box display="flex" flexGrow={1}>
            <Box>
              <Typography className={classes.cardH4}>
                Configuraciones
              </Typography>
              <Typography className={classes.mainContent}>
                {props.type}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.label}>
              Tarjetas a las que aplica la configuración
            </Typography>
            <Box display="flex" alignItems="center">
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                pt={"8px"}
                width={"100%"}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  flexWrap={"wrap"}
                >
                  {props.brandList.map((brand: string, index: number) => (
                    <img
                      key={index}
                      src={getBrandIcon(brand)}
                      className={classes.cardLogos}
                      alt={`${brand}-icon`}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
