import React, { useEffect, useState } from "react";
import { Box, MenuItem } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { IAppState } from "../../../store/reducer";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";
import { centralcounties } from "../../../shared/utils";

export interface ICustomMenuItemProps {
  keyName: string;
  navigateTo: string;
  menuTitle: string;
  menuIcon: string;
  country?: string | undefined;
}

export const CustomMenuItem: React.FC<ICustomMenuItemProps> = (
  props: React.PropsWithChildren<ICustomMenuItemProps>
) => {
  const [enabledList, setEnabledList] = useState([]);
  const countryConfigProfile = useSelector((state: IAppState) =>
    get(state, "appConfigPaymentMethods", [])
  );

  useEffect(() => {
    if (!isEmpty(countryConfigProfile)) {
      setEnabledList(countryConfigProfile[0]);
    }
  }, [countryConfigProfile]);

  const showItem: boolean = ((country: string, payName: string) => {
    if (centralcounties.includes(country)) {
      country = CountriesEnum.CENTRO_AMERICA;
    }
    return !(enabledList[country] || []).includes(payName);
  })(get(props, "country", ""), props.keyName);

  return (
    <>
      {!showItem && (
        <MenuItem
          key={props.keyName}
          component={RouterLink}
          to={props.navigateTo}
          disabled={showItem}
        >
          <Box display="flex" flexDirection="row">
            <Box style={{ marginRight: 10 }}>
              <img alt={props.keyName} src={props.menuIcon} />
            </Box>
            <Box>{props.menuTitle}</Box>
          </Box>
        </MenuItem>
      )}
    </>
  );
};
