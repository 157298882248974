import React from "react";
import { CardList, CardTable } from "@kushki/connect-ui";
import { IDetailCatalogGeneralRulesComponent } from "../../shared/infrastructure/interfaces/IModalCatalogGeneralRule";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDetailCatalogGeneralRulesComponentState } from "./state/useDetailCatalogGeneralRulesComponentState";

export const DetailCatalogGeneralRulesComponent: React.FC<
  IDetailCatalogGeneralRulesComponent
> = (props: IDetailCatalogGeneralRulesComponent) => {
  const {
    title,
    sectionResume,
    sectionConditionGeneral,
    sectionSpeed,
    showSectionSpeed,
    showSpecialSection,
    sectionSpecialConditions,
    showButton,
    actions,
  } = useDetailCatalogGeneralRulesComponentState(props);

  return (
    <React.Fragment>
      <Grid container sx={{ paddingBottom: "16px", paddingTop: "68px" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Box
        bgcolor={"neutral.grey3"}
        marginBottom={"8px"}
        paddingBottom={"24px"}
        borderRadius={"16px"}
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <CardList {...sectionResume} />
          </Grid>
          <Grid item md={12}>
            <CardTable
              key={"sectionConditionGeneral"}
              {...sectionConditionGeneral}
            />{" "}
          </Grid>
          {showSpecialSection && (
            <Grid item md={12}>
              <CardTable
                key={"sectionSpecialConditions"}
                {...sectionSpecialConditions}
              />{" "}
            </Grid>
          )}
          {showSectionSpeed && (
            <Grid item md={12}>
              <CardTable key={"sectionSpeed"} {...sectionSpeed} />
            </Grid>
          )}
        </Grid>
      </Box>
      {showButton && !sectionResume.isLoading && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ paddingTop: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={actions.handleSelectRule}
          >
            Seleccionar regla
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};
