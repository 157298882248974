import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const columnConfigurationTitleStyles: IStyles = {
  title: {
    "&.MuiTypography-root": {
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "120%",
    },
  },
  subtitle: {
    color: "text.grey",
    mt: 1,
    fontSize: "15px",
  },
  closeIcon: {
    color: (theme) => theme.palette.grey[500],
    position: "absolute",
    right: 8,
    top: 8,
  },
};

export { columnConfigurationTitleStyles };
