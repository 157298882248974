import { SelectCheck } from "@kushki/connect-ui";
import { UseFormReturn } from "react-hook-form";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import React from "react";
import { Divider, Grid, TextField } from "@mui/material";
import { ContactDataRequest } from "../../../types/contact_data_request";
import {
  getContactDataRules,
  getEmailRules,
} from "../../shared/constants/contact_data_form_constants";
import { DynamoName } from "../../shared/enum/ContactDataStateMX/MerchantCompanyInfo";
import { ContactDataLabelMX } from "../../shared/enum/ContactDataStateMX/ContactDataLabelMX";
import { ErrorMessageContactDataMX } from "../../shared/enum/ContactDataStateMX/ErrorMessageContactDataMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import { modalComponentStyles as styles } from "./ModalAddContactComponent.styles";
import { get, isEmpty } from "lodash";

export interface ModalComponentProps {
  contactData: {
    name: string;
    email: string;
  };
  isNameValid: boolean;
  categoryAreas: ICategory[];
  isDataCompleted: boolean;
  handleIsDataNoErrors: (validateDataNoErrors: boolean) => void;
  isAreasSelected: boolean;
  handleFormChange(
    name: string,
    value: string,
    selectedItems?: ICategory[]
  ): void;
  isCentralizedBranch: boolean;
  isEditing: boolean;
  form: UseFormReturn<ContactDataRequest>;
}

export const ModalAddContactComponent: React.FC<ModalComponentProps> = (
  props: ModalComponentProps
) => {
  const { isCentralizedBranch } = props;

  const emailMessageError = (): string => {
    let errorMessage: string = "";

    if (get(props.form.formState.errors, "email"))
      errorMessage = get(props.form.formState.errors, "email.message", "");
    if (isEmpty(props.contactData.email) && !props.isDataCompleted)
      errorMessage = ErrorMessageContactDataMX.FIELD_REQUIRED;

    return errorMessage;
  };

  const nameMessageError = (): string => {
    let errorMessage: string = "";

    if (get(props.form.formState.errors, "name"))
      errorMessage = get(props.form.formState.errors, "name.message", "");
    if (!props.isNameValid)
      errorMessage = ErrorMessageContactDataMX.REPEATED_NAME;
    if (isEmpty(props.contactData.name) && !props.isDataCompleted)
      errorMessage = ErrorMessageContactDataMX.FIELD_REQUIRED;

    return errorMessage;
  };

  return (
    <Grid sx={styles.modal}>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{
          md: 3,
          sm: 2,
          xs: 1,
        }}
      >
        <Grid item xs={12} md={12}>
          <Divider sx={styles.divider} />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectCheck
            isActiveSelectAll={true}
            type={"withoutCategory"}
            placeholder={ContactDataLabelMX.SELECT_AREA}
            items={props.categoryAreas}
            onItemSelect={(selectedItems: ICategory[]) => {
              props.handleFormChange(
                DynamoName.CONTACT_AREAS,
                "",
                selectedItems
              );
            }}
            onChangeTextField={() => {}}
            error={!props.isAreasSelected}
            helperText={
              !props.isAreasSelected
                ? ErrorMessageContactDataMX.FIELD_REQUIRED
                : ""
            }
            minChipsItem={5}
            isDisabled={
              !props.isEditing ? isCentralizedBranch : props.isEditing
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            {...props.form.register(
              FieldsEnum.name,
              getContactDataRules()[FieldsEnum.name]
            )}
            label={ContactDataLabelMX.NAME_LASTNAME}
            variant="outlined"
            fullWidth
            error={
              !!get(props.form.formState.errors, FieldsEnum.name) ||
              !props.isNameValid
            }
            helperText={nameMessageError()}
            disabled={isCentralizedBranch}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            {...props.form.register(FieldsEnum.email, getEmailRules())}
            label={ContactDataLabelMX.EMAIL}
            variant="outlined"
            fullWidth
            id={"email-select-input-field"}
            error={!!get(props.form.formState.errors, FieldsEnum.email)}
            helperText={emailMessageError()}
            disabled={isCentralizedBranch}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
