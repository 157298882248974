export enum FileStatusEnum {
  DEFAULT = "default",
  ERROR = "error",
  UPLOADING = "uploading",
  WITH_FILE = "with_file",
  VALIDATING = "validating",
}

export const FileValidation = {
  MAX_SIZE: 10,
  NAME: `massive-user-${new Date().getFullYear()}`,
  TYPE: "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export enum MessageError {
  TYPE = "El formato de plantilla del archivo es incorrecto. Sube el archivo con el formato de plantilla correcto.",
  MAX_SIZE = "El archivo supera el tamaño máximo permito (10MB).",
  NAME = "El nombre del archivo no es permitido",
  VALIDATION = "Descarga el archivo y corrige los errores. Luego elimina el archivo y reemplázalo por el correcto.",
  SNACK_BAR_VALIDATION = "Ocurrio un error al validar el archivo. Por favor inténtalo de nuevo",
  SNACK_BAR_UPLUOAD = "Ocurrio un error al subir el archivo. Por favor inténtalo de nuevo",
  RESTORE_PASSWORD_SUCCES = "Se envió el correo para el restablecimiento de contraseña",
  GENERAL_ERROR = "Algo salió mal! No pudimos cargar los usuarios",
  RESTORE_PASSWORD_ERROR_AUT016 = "Es necesario validar la clave temporal para resetear la contraseña",
  ERROR_SUPPORT = "No es posible realizar ninguna acción en modo Asistencia Remota",
  DELETE_USER = "El usuario __USERNAME__ se ha eliminado con éxito",
  RESTORE_PASSWORD_ERROR = "Ocurrió un error con uno de los usuarios seleccionados",
  RESTORE_PASSWORD_SUCCESS_MASSIVE = "Se envió el correo para el restablecimiento de contraseña",
  ERROR_DELETE_USER = "Hubo un error al borrar el usuario",
}

export enum DragTypeEnum {
  ENTER = "dragenter",
  OVER = "dragover",
  LEAVE = "dragleave",
}

export enum ErrorAlertEnum {
  TITLE = "Se detectaron algunos errores",
}
