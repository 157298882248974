import { FC } from "react";
import { CellFlagItem } from "../../TableCell/CellFlag/CellFlag";
import { CellTitleItem } from "../../TableCell/CellTitle/CellTitle";
import { CellTextCopy } from "../../TableCell/CellTextCopy/CellTextCopy";
import { CellCheckItem } from "../../TableCell/CellCheck/CellCheck";
import { ITableCellProps, ITableRowProps } from "./interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { CellChipStatus } from "../../TableCell/CellChip/CellChipStatus";
import { getSimplifiedFormatedDate } from "../../../store/utils/date_utils";

enum TableBodyCellEnum {
  CHECK = "CHECK",
  TITLE = "TITLE",
  TEXT_COPY = "TEXT_COPY",
  TAG = "TAG",
  TAG_GROUP = "TAG_GROUP",
  FLAG = "FLAG",
}
interface IConfig {
  //Valor por defecto en caso que no se obtenga un valor del objeto con el path.
  defaultValue?: any;
  //Path para obtener el valor del objeto.
  path: string;
  //Text que se mostrara en la tabla, se agrega $var para mostrar la posición donde estara el valor obtenido del objeto.
  text: string;
}

export interface IConfigCellBasic {
  configMain?: IConfig;
  configSecondary?: IConfig;
  type: TableBodyCellEnum;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

interface IConfigCellCheck extends IConfigCellBasic {
  isChecked: boolean;
}
interface IConfigCellFlag extends IConfigCellBasic {
  isCodeCountry?: boolean;
}

export type IConfigCells =
  | IConfigCellBasic
  | IConfigCellCheck
  | IConfigCellFlag;

const CELL_COMPONENTS_BY_TABLE_ENUM: Record<TableBodyCellEnum, FC<any>> = {
  [TableBodyCellEnum.CHECK]: CellCheckItem,
  [TableBodyCellEnum.TITLE]: CellTitleItem,
  [TableBodyCellEnum.TEXT_COPY]: CellTextCopy,
  [TableBodyCellEnum.FLAG]: CellFlagItem,
  [TableBodyCellEnum.TAG]: CellChipStatus,
  [TableBodyCellEnum.TAG_GROUP]: CellChipStatus,
};

export const truncteText = (label: string, length: number) => {
  if (label.length < length) {
    return label;
  }

  const sliceLabel: string = label.split("").slice(0, length).join("");

  return `${sliceLabel}...`;
};

function generateText<T = object>(
  obj: T,
  text: string = "",
  path: string,
  defaultValue?: string,
  isDate?: boolean
): string {
  let required_value = get(obj, path, defaultTo(defaultValue, ""));

  if (isDate && !isEmpty(required_value)) {
    required_value = getSimplifiedFormatedDate(required_value);
  }

  return text.replace("$var", required_value);
}

export function builderRows<T = object>(
  data: T[],
  configRows: IConfigCells[]
): ITableRowProps<T>[] {
  const rows_props: ITableRowProps<T>[] = [];
  let table_cells: ITableCellProps[];
  let cell: ITableCellProps;

  data.map((obj: T, index: number) => {
    table_cells = [];
    configRows.map((config: IConfigCells) => {
      switch (config.type) {
        case TableBodyCellEnum.CHECK:
          cell = {
            props: {
              align: get(config, "align"),
              id: new Date().getTime().toString(),
              isChecked: false,
            },
            type: TableBodyCellEnum.CHECK,
          };
          break;
        case TableBodyCellEnum.TITLE:
          cell = {
            props: {
              align: get(config, "align"),
              line1: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              line2: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                true
              ),
              type: !isEmpty(get(config, "configSecondary.path"))
                ? "twoLines"
                : "oneLine",
            },
            type: TableBodyCellEnum.TITLE,
          };
          break;
        case TableBodyCellEnum.TEXT_COPY:
          const info = generateText<T>(
            obj,
            get(config, "configMain.text"),
            get(config, "configMain.path"),
            get(config, "configMain.defaultValue")
          );

          cell = {
            props: {
              align: get(config, "align"),
              textToCopy: info,
              title: truncteText(info, 22),
            },
            type: TableBodyCellEnum.TEXT_COPY,
          };
          break;
        case TableBodyCellEnum.FLAG:
          cell = {
            props: {
              align: get(config, "align"),
              country: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              isCodeCountry: get(config, "isCodeCountry", false),
              type: "twolines",
            },
            type: TableBodyCellEnum.FLAG,
          };
          break;
        case TableBodyCellEnum.TAG:
          cell = {
            props: {
              align: get(config, "align"),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
            },
            type: TableBodyCellEnum.TAG,
          };
          break;
        case TableBodyCellEnum.TAG_GROUP:
          cell = {
            props: {
              align: get(config, "align"),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
            },
            type: TableBodyCellEnum.TAG_GROUP,
          };
          break;
      }
      table_cells.push(cell);
    });
    rows_props.push({
      cells: table_cells,
      id: index.toString(),
      info: obj,
    });
  });

  return rows_props;
}

export { TableBodyCellEnum, CELL_COMPONENTS_BY_TABLE_ENUM };
