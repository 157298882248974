import React, { FC } from "react";
import Chip from "@mui/material/Chip";
import { StateChipProps, Status } from "./StatusChip.interfaces";

const statusOptions: Status = {
  APPROVED: { color: "success", label: "Reembolsado" },
  EXPIRED: { color: "error", label: "Vencido" },
  INITIALIZED: { color: "error", label: "Inicializado" },
  MANUAL: { color: "error", label: "Manual" },
  PENDING: { color: "error", label: "Pendiente" },
};

const StatusChip: FC<StateChipProps> = ({
  status,
  ...rest
}: StateChipProps) => {
  const currentStatus = statusOptions[status];

  return (
    <Chip
      color={currentStatus.color}
      label={currentStatus.label.toUpperCase()}
      {...rest}
    />
  );
};

export default StatusChip;
