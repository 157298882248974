import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  ksh_multiline: {
    "& > label+div": {
      height: "auto",
    },
  },
}));

export interface IKshTextArea {
  error?: boolean;
  id: string;
  label: string;
  labelError?: string;
  onBlur(value: { prop: string; value?: string | number }): void;
  value?: string | number;
  maxLength?: number;
}

export const KshTextArea: React.FC<IKshTextArea> = (props: IKshTextArea) => {
  const classes = useStyles();

  const [value, setValue] = useState<string | number | undefined>(
    get(props, "value", "")
  );

  useEffect(() => {
    setValue(get(props, "value", ""));
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(get(event, "target.value", "").slice(0, props.maxLength));

  return (
    <TextField
      className={classes.ksh_multiline}
      id={props.id}
      label={props.label}
      aria-label={props.label}
      onBlur={(e) =>
        props.onBlur({
          prop: props.id,
          value: e.target.value,
        })
      }
      multiline
      error={props.error}
      helperText={props.labelError}
      value={value}
      onChange={handleChange}
      rows={4}
      variant="outlined"
      style={{ width: "100%" }}
      name={props.id}
    />
  );
};
