import { CountryEnum } from "./CountryEnum";
import { StatusEnum } from "./StatusEnum";
import { KindEnum } from "./KindEnum";

import { TransactionTypeLangEnum } from "./TransactionTypeLangEnum";

export const LanguageCatalog: ICategory[] = [
  {
    lang: "daily",
    es: "Diaria",
  },
  {
    lang: "biweekly",
    es: "Quincenal",
  },
  {
    lang: "weekly",
    es: "Semanal",
  },
  {
    lang: "monthly",
    es: "Mensual",
  },
  {
    lang: "quarterly",
    es: "Trimestral",
  },
  {
    lang: "twoweekly",
    es: "Mar.-Vier.",
  },
  {
    lang: "threeweekly",
    es: "Lun.-Miérc.-Vier.",
  },
  {
    lang: "annual",
    es: "Anual",
  },
  {
    lang: "invoice",
    es: "Factura",
  },
  {
    lang: "charge",
    es: "Cobro",
  },
  {
    lang: "dispersion",
    es: "Dispersión",
  },
  {
    lang: "retention_dispersion",
    es: "Retención",
  },
  {
    lang: "retention",
    es: "Retención",
  },
  {
    lang: "credit_note",
    es: "N. de crédito",
  },
  {
    lang: "debit_note",
    es: "N. de débito",
  },
  {
    lang: "voucher",
    es: "Boleta",
  },
  {
    lang: "pending",
    es: "Pendiente",
  },
  {
    lang: "pending_verify",
    es: "PENDIENTE\nVERIFICACIÓN",
  },
  {
    lang: "manual",
    es: "Manual",
  },
  {
    lang: "omitted",
    es: "Omitida",
  },
  {
    lang: "omit",
    es: "Omitida",
  },
  {
    lang: "annul",
    es: "Anulada",
  },
  {
    lang: "annulled",
    es: "ANULADA",
  },
  {
    lang: "emitted",
    es: "Emitida",
  },
  {
    lang: "completed",
    es: "Emitida",
  },
  {
    lang: "failed",
    es: "Declinada",
  },
  {
    lang: "finished",
    es: "Liquidada",
  },
  {
    lang: "successful",
    es: "Exitosa",
  },
  {
    lang: "expired",
    es: "Expirado",
  },
  {
    lang: "card",
    es: "Tarjeta",
  },
  {
    lang: "to_charge",
    es: "Por cobrar",
  },
  {
    lang: "approve",
    es: "Aprobado",
  },
  {
    lang: "approved",
    es: "Aprobado",
  },
  {
    lang: "reject",
    es: "Rechazado",
  },
  {
    lang: "rejected",
    es: "Rechazado",
  },
  {
    lang: "deleted",
    es: "Anulada",
  },
  {
    lang: "processed",
    es: "Procesado",
  },
  {
    lang: "with_iva",
    es: " con IVA",
  },
  {
    lang: "without_iva",
    es: " sin IVA",
  },
  {
    lang: "receivable",
    es: "Por cobrar",
  },
  {
    lang: "payincommission",
    es: "Pay-In",
  },
  {
    lang: "payoutcommission",
    es: "Pay-Out",
  },
  {
    lang: "initial",
    es: "Inicializado",
  },
  {
    lang: "process",
    es: "En proceso",
  },
  {
    lang: "pre_processed",
    es: "En proceso",
  },
  {
    lang: "aggregator_gateway",
    es: "Pasarela",
  },
  {
    lang: "aggregator_formal",
    es: "Formal",
  },
  {
    lang: "aggregator_collection",
    es: "Recaudo directo",
  },
  {
    lang: "prosa_aggregator_formal",
    es: "Giro Natural",
  },
  {
    lang: "minimalcommission",
    es: "Mínimo Comisional",
  },
  {
    lang: "approved",
    es: "Aprobada",
  },
  {
    lang: "manualfromfile",
    es: TransactionTypeLangEnum.MANUAL_FROM_FILE,
  },
  {
    lang: "fromconsole",
    es: TransactionTypeLangEnum.FROM_CONSOLE,
  },
  {
    lang: "exclusive",
    es: "Exclusiva",
  },
];
export const translate = (
  text: string,
  country?: string,
  kind?: string
): string => {
  if (text === undefined || text === null) return "";
  const index: number = LanguageCatalog.findIndex(
    (detail: ICategory) => detail.lang === text.toLowerCase()
  );

  if (index >= 0) {
    const lang: string | undefined = LanguageCatalog[index]["es"];
    if (country === CountryEnum.mexico && kind === KindEnum.ANNUL_INVOICE) {
      switch (text) {
        case StatusEnum.PRE_PROCESSED:
          return "ANULACIÓN EN APROBACIÓN";
        case StatusEnum.PENDING:
          return "ANULACIÓN PENDIENTE";
        default:
          return lang!;
      }
    }

    return lang !== undefined ? lang : text;
  }

  return text;
};

export interface ICategory {
  lang: string;
  es?: string;
}
