import { GetDispersionRatesConfigResponse } from "../../../../types/get_dispersion_rates_config_response";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";
import {
  IBankAccount,
  IDispersionForm,
  IDispersionStore,
} from "../../interfaces/dispersion.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { sortBanksAccount } from "../../../shared/utils/bankAccountUtils";
import {
  getDispersionRatesConfig,
  postDispersionConfig,
  postRates,
} from "../../thunks/app/app.thunks";
import { defaultTo } from "lodash";

export const initialState: IDispersionStore = {
  banksAccounts: [],
  beneficiaryNameFromBasicDetails: "",
  buttons: {},
  constitutionalCountry: CountriesEnum.MEXICO,
  frequencyAndFraudForm: {
    enable4x1000: false,
    fraudPercentage: undefined,
    frequency: FrequencyEnum.NONE,
    isExclusive: undefined,
    keepCommission: false,
    keepFraud: false,
    retentionPeriod: undefined,
  },
  isLoadingDispersionInformation: true,
  loading: {
    loadingForm: true,
    loadingRatesConfig: true,
  },
  state: {},
};

export const dispersionSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getDispersionRatesConfig.pending, (state) => {
        state.loading.loadingRatesConfig = true;
        state.isLoadingDispersionInformation = true;
      })
      .addCase(
        getDispersionRatesConfig.fulfilled,
        (
          state,
          { payload }: PayloadAction<GetDispersionRatesConfigResponse>
        ) => {
          state.frequencyAndFraudForm = {
            ...state.frequencyAndFraudForm,
            fraudPercentage: payload.fraudPercentage,
            keepFraud: defaultTo(
              payload.keepFraud,
              initialState.frequencyAndFraudForm.keepFraud
            ),
            retentionPeriod: payload.retentionPeriod,
          };
          state.loading.loadingRatesConfig = false;
          state.isLoadingDispersionInformation = false;
        }
      )
      .addCase(getDispersionRatesConfig.rejected, (state) => {
        state.loading.loadingRatesConfig = false;
        state.isLoadingDispersionInformation = false;
      })
      .addCase(postDispersionConfig.pending, (state) => {
        state.state.postDispersionConfig = FetchStateEnum.PENDING;
      })
      .addCase(postDispersionConfig.fulfilled, (state) => {
        state.state.postDispersionConfig = FetchStateEnum.SUCCESS;
      })
      .addCase(postDispersionConfig.rejected, (state) => {
        state.state.postDispersionConfig = FetchStateEnum.FAILED;
      })
      .addCase(postRates.pending, (state) => {
        state.state.postDispersionRates = FetchStateEnum.PENDING;
      })
      .addCase(postRates.fulfilled, (state) => {
        state.state.postDispersionRates = FetchStateEnum.SUCCESS;
      })
      .addCase(postRates.rejected, (state) => {
        state.state.postDispersionRates = FetchStateEnum.FAILED;
      });
  },
  initialState,
  name: "dispersion",
  reducers: {
    addBankAccount: (state, { payload }: PayloadAction<IBankAccount>) => {
      const bankAccounts: IBankAccount[] = [...state.banksAccounts, payload];

      state.banksAccounts = sortBanksAccount(bankAccounts);
    },
    disableContinueButton: (state, { payload }: PayloadAction<boolean>) => {
      state.buttons.disabledContinueButton = payload;
    },
    disableSaveButton: (state, { payload }: PayloadAction<boolean>) => {
      state.buttons.disabledSaveButton = payload;
    },
    editBankAccount: (state, { payload }: PayloadAction<IBankAccount[]>) => {
      state.banksAccounts = payload;
    },
    editBeneficiaryName: (state, { payload }: PayloadAction<string>) => {
      state.beneficiaryNameFromBasicDetails = payload;
    },
    setIsLoadingDispersionInformation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingDispersionInformation = payload;
    },
    updateBankAccountIndexOnFocus: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.bankAccountIndexOnFocus = payload;
    },
    updateConstitutionalCountry: (
      state,
      { payload }: PayloadAction<CountriesEnum>
    ) => {
      state.constitutionalCountry = payload;
    },
    updateFrequencyAndFraudForm: (
      state,
      { payload }: PayloadAction<IDispersionForm>
    ) => {
      state.frequencyAndFraudForm = payload;
      state.loading.loadingForm = false;
    },
    updateLoadingForm: (state, { payload }) => {
      state.loading.loadingForm = payload;
      state.loading.loadingRatesConfig = payload;
    },
  },
});

export default dispersionSlice.reducer;
