import { defaultTo, get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { IUseRuleDetailState } from "@containers/RuleDetail/state/useRuleDetailState.interfaces";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { queryMerchants } from "@store/thunks/nodeSelection/nodeSelection.thunk";
import { useNavigate, useParams } from "react-router-dom";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  Merchant,
  SearchMerchantsResponse,
} from "../../../../types/search_merchants_response";
import { ICellRow } from "@kushki/connect-ui";
import {
  buildClipboardTextCell,
  buildFlagCell,
} from "@shared/utils/buildTableUtils";
import { EntityName } from "@shared/enum/entityNameEnum";
import { SearchMerchantsRequest } from "../../../../types/search_merchants_request";
import {
  LowerStatusEnum,
  orderNumToStr,
  RULE_DETAIL_TABLE_CELLS,
} from "@shared/constants/ruleDetailTable";
import { getRuleAlarm } from "@store/thunks/alarmConfig/alarmConfig.thunk";
import { NodeRule } from "../../../../types/rule_alarm";
import { RuleDetailLabels } from "@shared/constants/labels/ruleDetailLabels";
import { ISortClick } from "@shared/interfaces/ISortClick";
import TextLine from "@components/TextLine/TextLine";

export const useRuleDetailState = (): IUseRuleDetailState => {
  const { ruleId, type } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [requestRuleDetail, setRequestRuleDetail] =
    useState<SearchMerchantsRequest>({
      filter: {
        entityName: [EntityName.BRANCH, EntityName.NA],
        merchantId: [],
        path: [],
        status: [LowerStatusEnum.ACTIVE],
      },
      findWithOr: true,
      limit: 10,
      offset: 1,
    });
  const [total, setTotal] = useState<number>(0);
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const { alarmRule, viewData, isLoadingTable, isLoading } = useAppSelector(
    (state) => ({
      ...state.nodeSelection,
      ...state.alarmConfig,
    })
  );
  const [sortClick, setSortClick] = useState<ISortClick>({
    field: "",
    numClick: 0,
  });
  const commonCellProps: ICellRow = { align: "left", spacing: 0 };

  const calculateOffset = (page: number, itemsPerPage: number) => {
    setRequestRuleDetail({
      ...requestRuleDetail!,
      limit: itemsPerPage,
      offset: page - 1 === 0 ? 1 : page,
    });
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    calculateOffset(value, requestRuleDetail.limit);
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    calculateOffset(1, value);
  };

  const returnToMain = () => {
    navigate(`/${defaultTo(type, "").toUpperCase()}`);
  };

  const buildTitleCell = (text: string): ITableCellProps => {
    return {
      props: {
        cellProps: commonCellProps,
        line1: text,
      },
      type: TableBodyCellEnum.TITLE,
    };
  };

  const buildCell = (merchant: Merchant): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];

    const ownerText: string =
      merchant.entityName === EntityName.NA
        ? RuleDetailLabels.KUSHKI_TWO
        : get(merchant, "ownerName", "");
    const customerText: string =
      merchant.entityName === EntityName.NA
        ? RuleDetailLabels.KUSHKI_TWO
        : get(merchant, "customerName", "");
    const merchantName: string = get(merchant, "name", "");

    cells.push({
      props: {
        cellProps: commonCellProps,
        child: <TextLine text={merchantName} />,
        childSkeleton: <div></div>,
      },
      type: TableBodyCellEnum.BASE,
    });
    cells.push(buildClipboardTextCell(get(merchant, "publicMerchantId", "")));
    cells.push({
      props: {
        cellProps: commonCellProps,
        child: <TextLine text={ownerText} />,
        childSkeleton: <div></div>,
      },
      type: TableBodyCellEnum.BASE,
    });
    cells.push({
      props: {
        cellProps: commonCellProps,
        child: <TextLine text={customerText} />,
        childSkeleton: <div></div>,
      },
      type: TableBodyCellEnum.BASE,
    });
    cells.push(buildFlagCell(get(merchant, "constitutionalCountry", "")));
    cells.push(buildFlagCell(get(merchant, "country", "")));
    cells.push(buildTitleCell(get(merchant, "categoryMerchant", "")));

    return cells;
  };

  const buildTableRows = (data: SearchMerchantsResponse): ITableRowProps[] => {
    if (get(data, "total", 0) > 0) {
      return get(data, "data", []).map(
        (merchant: Merchant): ITableRowProps => ({
          cells: buildCell(merchant),
          id: merchant._id,
          rowProps: {
            color: "default",
            onClick: () => {},
          },
        })
      );
    }

    return [];
  };

  useEffect(() => {
    dispatch(getRuleAlarm(defaultTo(ruleId, "")));
  }, []);

  useEffect(() => {
    setRows(buildTableRows(viewData));
    setTotal(get(viewData, "total", 0));
  }, [viewData]);

  useEffect(() => {
    if (isEmpty(get(alarmRule, "nodes", []))) return;

    const merchantsNodes: NodeRule[] = defaultTo(
      alarmRule?.nodes.filter(
        ({ entityName }) =>
          entityName === EntityName.NA || entityName === EntityName.BRANCH
      ),
      []
    );

    const pathsNodes: NodeRule[] = defaultTo(
      alarmRule?.nodes.filter(
        ({ entityName }) => entityName === EntityName.CUSTOMER
      ),
      []
    );

    const ownerNodes: NodeRule[] = defaultTo(
      alarmRule?.nodes.filter(
        ({ entityName }) => entityName === EntityName.OWNER
      ),
      []
    );

    let merchantIds: string[] = merchantsNodes.map((node) =>
      get(node, "merchantId", "")
    );
    let paths: string[] = pathsNodes.map((node) => get(node, "path", ""));
    let ownerIds: string[] = ownerNodes.map((node) => get(node, "path", ""));

    setRequestRuleDetail({
      ...requestRuleDetail,
      filter: {
        ...requestRuleDetail.filter,
        merchantId: merchantIds,
        ownerId: ownerIds,
        path: paths,
      },
    });
  }, [alarmRule]);

  useEffect(() => {
    if (isEmpty(alarmRule)) return;
    dispatch(queryMerchants(requestRuleDetail));
  }, [requestRuleDetail]);

  useEffect(() => {
    if (isEmpty(sortClick.field)) return;

    setRequestRuleDetail({
      ...requestRuleDetail,
      sort: {
        field: get(sortClick, "field", ""),
        order: orderNumToStr(get(sortClick, "numClick", 0)),
      },
    });
  }, [sortClick]);

  const handleCountClick = (field: string) => {
    const sortField: string = get(sortClick, "field", "");
    const numClick: number = get(sortClick, "numClick", 0);

    if (sortField !== field && sortField !== "") {
      setSortClick({ field, numClick: 1 });

      return;
    }

    if (numClick >= 2) {
      setSortClick({ field, numClick: 0 });
    } else {
      setSortClick({
        field,
        numClick: numClick + 1,
      });
    }
  };

  return {
    footer: {
      handleItemsPerPage,
      handleSelectedPage,
      itemsPerPage: defaultTo(requestRuleDetail.limit, 10),
      selectedPage,
      total,
    },
    headers: RULE_DETAIL_TABLE_CELLS(
      handleCountClick,
      { field: sortClick.field },
      sortClick.numClick
    ),
    isLoading: isLoadingTable || isLoading,
    returnToMain,
    rows,
    totalBranches: get(viewData, "total", 0),
  };
};
