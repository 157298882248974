import { StatusStepEnum } from "../enums/StatusStepEnum";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../types/get_node_info_response";
import { filterNodeInfoByConfigAndStatus } from "./filter-node-info-by-conf";
import { get } from "lodash";
import { ConfigurationCodeEnum } from "../enums/ProcessingStepEnum";

export const getNodeStatus = (
  nodeInfo: GetNodeInfoResponse,
  config: string
): string => {
  const filter_configs: Configs = filterNodeInfoByConfigAndStatus(
    nodeInfo,
    config,
    StatusStepEnum.COMPLETE
  );

  return get(filter_configs, "status", StatusStepEnum.PENDING);
};

export const isBussinessDeferredNotComplete = (
  nodeInfo: GetNodeInfoResponse
): boolean => {
  const businessStatus: string = getNodeStatus(
    nodeInfo,
    ConfigurationCodeEnum.BUSINESS_RULES
  );
  const deferredStatus: string = getNodeStatus(
    nodeInfo,
    ConfigurationCodeEnum.DEFERRED
  );
  return (
    businessStatus !== StatusStepEnum.COMPLETE ||
    deferredStatus !== StatusStepEnum.COMPLETE
  );
};
