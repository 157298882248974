import { DashboardTableComponentProps } from "../DashboardTable";
import React, { useEffect, useState } from "react";
import {
  RowStatusEnum,
  StatusEnum,
  StatusType,
} from "../../../../../shared/infrastructure/StatusEnum";
import { TransactionTable } from "../../../../../../types/transaction_table";
import { get, includes, set, sortBy } from "lodash";
import { KindEnum } from "../../../../../shared/infrastructure/KindEnum";
import { CountryEnum } from "../../../../../shared/infrastructure/CountryEnum";

import {
  columnsTableBilling,
  defaultColumnsSelectableBilling,
  IColumnsTable,
} from "../../../../../shared/infrastructure/table/IColumnsTable";
import { StatusFlowEnum } from "../../../../../shared/infrastructure/StatusFlowEnum";
import { setBillingDataRow } from "../../../../../store/actionCreators";

export interface DashboardTableRowState {
  selectProps: {
    statusRows: string;
    checkedRows: boolean;
    order: "asc" | "desc";
    orderBy: string;
    openAdvanceSettingsDialog: boolean;
    openInvoiceInfoDialog: boolean;
    openModalOmit: boolean;
    openRejectModal: boolean;
    reasonCheckBox: string;
  };
  actions: {
    handleChangeCheckbox: (
      event: React.ChangeEvent<HTMLInputElement>,
      type: StatusEnum,
      statusFlow: StatusFlowEnum
    ) => void;
    addAdditionalData: (trxUpdated: TransactionTable) => void;
    validateCheckboxByTaxId: (
      checkedRow: TransactionTable,
      status: StatusEnum
    ) => void;
    handleRequestSort: (
      event: React.MouseEvent<unknown>,
      property: string
    ) => void;
    handleOpenAdvanceSettings: (open: boolean) => void;
    handleOpenInvoiceInfo: (open: boolean) => void;
    openDialogsHandler: () => void;
    handleCloseOmit: () => void;
    handleAcceptOmit: (omitReason: string) => void;
    handleCloseReject: () => void;
    handleAcceptReject: (rejectReason: string) => void;
    handleDefaultValuesRows: () => void;
  };
  settingsColumns: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    handleAppliedColumnsFilters: (restore?: boolean) => void;
    renderColumns: IColumnsTable[];
  };
}

const KINDS_FOR_OPENING_ADVANCED_SETTINGS_DIALOG = [
  KindEnum.INVOICE,
  KindEnum.VOUCHER,
];
const KINDS_FOR_OPENING_INVOICE_INFO_DIALOG = [KindEnum.CREDIT_NOTE];

const getValue = (trx: DashboardTableComponentProps, value: string) =>
  get(trx, `modalOverBilling.trxBilling.trx.transaction.${value}`);

export const useDashboardTableState = (
  props: DashboardTableComponentProps
): DashboardTableRowState => {
  const [statusRows, setStatusRows] = React.useState("process");
  const [checkedRows, setCheckedRows] = React.useState(false);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [
    openAdvanceSettingsDialog,
    setOpenAdvanceSettingsDialog,
  ] = React.useState(false);
  const [openModalOmit, setOpenModalOmit] = useState<boolean>(false);
  const [typeBeforeCheck, setTypeBeforeCheck] = useState<string>("process");
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [openInvoiceInfoDialog, setOpenInvoiceInfoDialog] = React.useState(
    false
  );
  const [reasonCheckBox, setReasonCheckBox] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = React.useState<string[]>(
    defaultColumnsSelectableBilling
  );
  useEffect(() => {
    let [key]: string[] = orderBy.split(".");
    if (key === "") key = "merchantName";

    props.orderBillingData(
      order === "desc"
        ? sortBy(props.billingData, [
            (billing: TransactionTable) =>
              get(billing.transaction, key, "merchantName"),
          ]).reverse()
        : sortBy(props.billingData, [
            (billing: TransactionTable) =>
              get(billing.transaction, key, "merchantName"),
          ])
    );
  }, [order, orderBy]);

  const handleDefaultValuesRows = () => {
    setStatusRows("");
    setCheckedRows(false);
    setReasonCheckBox("");
  };

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: StatusEnum,
    statusFlow: StatusFlowEnum
  ) => {
    setCheckedRows(event.target.checked);
    setReasonCheckBox("");
    setTypeBeforeCheck(type);
    if (!event.target.checked && statusFlow === StatusFlowEnum.VALIDATOR) {
      setStatusRows(StatusEnum.PENDING);
      return;
    }
    if (type === StatusEnum.REJECT) {
      setOpenRejectModal(true);
      return;
    }
    if (type === StatusEnum.PROCESS && !event.target.checked) {
      setStatusRows(StatusEnum.OMIT);
      setOpenModalOmit(true);
      return;
    }
    if (type === StatusEnum.PENDING && !event.target.checked) {
      setStatusRows(StatusEnum.OMIT);
      setOpenModalOmit(true);
      return;
    }
    if (type === StatusEnum.REPROCESS && !event.target.checked) {
      setStatusRows(StatusEnum.OMIT);
      setOpenModalOmit(true);
      return;
    }
    if (type === StatusEnum.REPROCESS) {
      setStatusRows(StatusEnum.REPROCESS);
      handleAcceptReprocess(StatusEnum.PROCESS, RowStatusEnum.Reprocess);
      return;
    }
    setStatusRows(type);
  };

  const validateCheckboxByTaxId = (
    checkedRow: TransactionTable,
    status: StatusEnum
  ) => {
    const {
      invoiceId: invoiceIdChangedBillingRow,
      kind: kindChangedBillingRow,
    } = checkedRow.transaction!;

    const transactionsFilterInvoiceId = props.billingData.filter(
      ({ transaction }) => transaction?.invoiceId === invoiceIdChangedBillingRow
    );

    transactionsFilterInvoiceId.forEach((transactionRow: TransactionTable) => {
      const { kind: kindBillingRow } = transactionRow.transaction!;
      const validationNotes = [KindEnum.CREDIT_NOTE, KindEnum.DEBIT_NOTE];
      if (
        validationNotes.includes(kindBillingRow as KindEnum) &&
        validationNotes.includes(kindChangedBillingRow as KindEnum)
      )
        transactionRow.status = status as StatusType;
    });
  };
  const [renderColumns, setRenderColumns] = useState<IColumnsTable[]>(
    columnsTableBilling.filter((column: IColumnsTable) => column.view)
  );
  const handleAppliedColumnsFilters = (restore?: boolean) => {
    const validCountry: boolean = [
      CountryEnum.peru,
      CountryEnum.colombia,
      CountryEnum.ecuador,
      CountryEnum.mexico,
      CountryEnum.chile,
    ].includes(props.country);
    let selectedColumns_aux: string[] = selectedColumns;
    if (restore) {
      setSelectedColumns(defaultColumnsSelectableBilling);
      selectedColumns_aux = defaultColumnsSelectableBilling;
    }
    if (selectedColumns_aux.length === 0 && validCountry) {
      setRenderColumns(columnsTableBilling);
      return;
    }
    let aux_columns: IColumnsTable[] = columnsTableBilling.filter(
      (column: IColumnsTable) => {
        return !column.country || column.country.includes(props.country);
      }
    );
    if (validCountry) {
      aux_columns = aux_columns.filter((column: IColumnsTable) => {
        return !column.filter || selectedColumns_aux.includes(column.id);
      });
    }
    setRenderColumns(aux_columns);
  };
  const addAdditionalData = (trxUpdated: TransactionTable) => {
    props.setBillingDataRow(
      trxUpdated,
      props.modalOverBilling.trxBilling.index
    );
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOpenAdvanceSettings = (open: boolean) => {
    setOpenAdvanceSettingsDialog(open);
  };

  const handleOpenInvoiceInfo = (open: boolean) => {
    setOpenInvoiceInfoDialog(open);
  };

  const openDialogsHandler = () => {
    const kind: KindEnum = getValue(props, "kind");

    if (KINDS_FOR_OPENING_ADVANCED_SETTINGS_DIALOG.includes(kind))
      handleOpenAdvanceSettings(true);

    if (KINDS_FOR_OPENING_INVOICE_INFO_DIALOG.includes(kind))
      handleOpenInvoiceInfo(true);
  };

  const handleCloseOmit = () => {
    setStatusRows(typeBeforeCheck);
    setOpenModalOmit(false);
  };

  const handleAcceptOmit = (omitReason: string) => {
    setOpenModalOmit(false);
    setReasonCheckBox(omitReason);
    setStatusRows(StatusEnum.OMIT);
    props.billingData.map((value) => {
      set(value, "omit.reason", omitReason);
      set(value, "statusRow", RowStatusEnum.Omit);
      props.setBillingRow(value);
    });
  };

  const handleCloseReject = () => {
    setOpenRejectModal(false);
  };

  const handleAcceptReprocess = (status: StatusEnum, statusRow: string) => {
    const data_aux: TransactionTable[] = props.billingData.map((txr) => {
      if (
        includes(
          [
            KindEnum.INVOICE,
            KindEnum.VOUCHER,
            KindEnum.CHARGE,
            KindEnum.DISPERSION,
            KindEnum.RECEIVABLE,
          ],
          get(txr, "transaction.kind", "")
        ) &&
        get(txr, "transaction.statusRow", "") === RowStatusEnum.Reprocess
      ) {
        set(txr, "status", status);
        set(txr, "statusRow", statusRow);
      }
      return txr;
    });
    props.setBillingTrxData(data_aux);

    props.billingData.forEach((trx: TransactionTable) => {
      if (
        includes(
          [
            KindEnum.INVOICE,
            KindEnum.VOUCHER,
            KindEnum.CHARGE,
            KindEnum.DISPERSION,
            KindEnum.RECEIVABLE,
          ],
          get(trx, "transaction.kind", "")
        ) &&
        get(trx, "transaction.statusRow", "") === RowStatusEnum.Reprocess
      ) {
        set(trx, "transaction.status", status);
        set(trx, "transaction.statusRow", statusRow);
      }
      setBillingDataRow(trx);
    });
  };

  const handleAcceptReject = (rejectReason: string) => {
    const data_aux: TransactionTable[] = props.billingData.map((txr) => ({
      ...txr,
      reject: { reason: rejectReason },
    }));
    props.setBillingTrxData(data_aux);
    setOpenRejectModal(false);
    setReasonCheckBox(rejectReason);
    setStatusRows(StatusEnum.REJECT);
    props.billingData.forEach((trx: TransactionTable) => {
      set(trx, "transaction.reject", { reason: rejectReason });
      setBillingDataRow(trx);
    });
  };
  const initialColumns = () => {
    let initialColumns = columnsTableBilling.filter((column) => column.view);
    setRenderColumns(initialColumns);
  };
  useEffect(() => {
    initialColumns();
  }, []);

  return {
    selectProps: {
      statusRows,
      checkedRows,
      order,
      orderBy,
      openAdvanceSettingsDialog,
      openInvoiceInfoDialog,
      openModalOmit,
      openRejectModal,
      reasonCheckBox,
    },
    settingsColumns: {
      handleAppliedColumnsFilters,
      selectedColumns,
      setSelectedColumns,
      renderColumns,
    },
    actions: {
      handleChangeCheckbox: handleChangeCheckbox,
      addAdditionalData: addAdditionalData,
      validateCheckboxByTaxId: validateCheckboxByTaxId,
      handleRequestSort: handleRequestSort,
      handleOpenAdvanceSettings: handleOpenAdvanceSettings,
      openDialogsHandler: openDialogsHandler,
      handleOpenInvoiceInfo: handleOpenInvoiceInfo,
      handleCloseOmit: handleCloseOmit,
      handleAcceptOmit: handleAcceptOmit,
      handleCloseReject: handleCloseReject,
      handleAcceptReject: handleAcceptReject,
      handleDefaultValuesRows: handleDefaultValuesRows,
    },
  };
};
