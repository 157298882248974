import { ActionTypes } from "./actionTypes";
import CheckIcon from "@material-ui/icons/Check";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { TransactionHistoryTable } from "../../types/transaction_history_table";
import { TransactionTable } from "../../types/transaction_table";

export interface IAppState {
  notification?: INotification;
  refundData: TransactionTable;
  refundHistoryData: TransactionHistoryTable;
  isLoadingRefundData?: boolean;
  isLoading: boolean;
  loadingMassive?: boolean;
  dialogClosed?: boolean;
  updateData?: object;
  tab?: number;
  activeTab?: number;
  errorMassiveAsync?: boolean;
  errorMassiveAsyncResult?: boolean;
  actionMassiveTransaction?: string;
  wsResponse?: string;
}

export const INITIAL_STATE: IAppState = {
  actionMassiveTransaction: "",
  activeTab: 0,
  dialogClosed: false,
  isLoading: false,
  isLoadingRefundData: false,
  loadingMassive: false,
  notification: {
    horizontal: "right",
    icon: CheckIcon,
    message: "",
    open: false,
    type: "success",
    vertical: "bottom",
  },
  refundData: { data: [], total: 0 },
  refundHistoryData: { data: [], total: 0 },
  tab: 0,
  wsResponse: "",
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_REFUND_DATA:
      return {
        ...state,
        refundData: action.refundData!,
      };
    case ActionTypes.SET_REFUND_TRX_DATA:
      return {
        ...state,
        refundData: action.refundData!,
      };
    case ActionTypes.SET_IS_LOADING_REFUND_DATA:
      return {
        ...state,
        isLoadingRefundData: action.isLoadingRefundData,
      };
    case ActionTypes.SET_LOADING_MASSIVE:
      return {
        ...state,
        loadingMassive: action.loadingMassive!,
      };
    case ActionTypes.SET_UPDATE_TRX_STATUS:
      return {
        ...state,
        updateData: action.updateData,
      };
    case ActionTypes.SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case ActionTypes.SET_REFUND_HISTORY_DATA:
      return {
        ...state,
        refundHistoryData: action.refundHistoryData!,
      };
    case ActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case ActionTypes.SET_ERROR_MASSIVE_ASYNC_RESULT:
      return {
        ...state,
        errorMassiveAsyncResult: action.errorMassiveAsyncResult,
      };
    case ActionTypes.SET_ERROR_MASSIVE_ASYNC:
      return {
        ...state,
        errorMassiveAsync: action.errorMassiveAsync,
      };
    case ActionTypes.SET_WS_RESPONSE:
      return {
        ...state,
        wsResponse: action.wsResponse,
      };
    case ActionTypes.SET_ACTION_MASSIVE_TRANSACTION:
      return {
        ...state,
        actionMassiveTransaction: action.actionMassiveTransaction,
      };
    default:
      return state;
  }
};
