import React, { useEffect } from "react";

type PromiseStatus = "initial" | "pending" | "fulfilled" | "rejected";

interface usePromiseRes<T, E = unknown> {
  data: T | null;
  dispatch: () => Promise<void>;
  error: E | null;
  status: PromiseStatus;
}

type Effects<R, E> = {
  onInitial?: () => void | Promise<void>;
  onPending?: () => void | Promise<void>;
  onFulfilled?: (res: R) => void | Promise<void>;
  onError?: (err: E) => void | Promise<void>;
  onFinally?: () => void | Promise<void>;
};

export function usePromise<T, E = unknown>(
  promise: () => Promise<T>,
  effects?: Effects<T, E>
): usePromiseRes<T, E> {
  const [data, setData] = React.useState<T | null>(null);
  const [error, setError] = React.useState<E | null>(null);
  const [status, setStatus] = React.useState<PromiseStatus>("initial");
  const dispatch = React.useCallback(async () => {
    setStatus("pending");
    await effects?.onPending?.();

    return promise()
      ?.then(async (res) => {
        setStatus("fulfilled");
        setData(res);
        await effects?.onFulfilled?.(res);
      })
      ?.catch(async (error) => {
        setStatus("rejected");
        setError(error);
        await effects?.onError?.(error);
      })
      ?.finally(effects?.onFinally);
  }, [promise]);

  useEffect(() => {
    effects?.onInitial?.();
  }, []);

  return {
    data,
    dispatch,
    error,
    status,
  };
}
