/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_CHILE: IOptionSelection[] = [
  { value: "970320008", name: "Banco BBVA" },
  { value: "970040005", name: "Banco de Chile" },
  { value: "970060006", name: "Banco BCI" },
  { value: "970040005", name: "Banco Edwards" },
  { value: "970510001", name: "Banco Desarrollo" },
  { value: "970300007", name: "Banco Estado" },
  { value: "965096604", name: "Banco Falabella" },
  { value: "76645030K", name: "Banco Itaú" },
  { value: "970110003", name: "Banco Internacional" },
  { value: "979470002", name: "Banco Ripley" },
  { value: "97036000K", name: "Banco Santander-Santiago" },
  { value: "970530002", name: "Banco Security" },
  { value: "97080000K", name: "Banco Bice" },
  { value: "995004100", name: "Banco Consorcio" },
  { value: "828789007", name: "Banco Coopeuch" },
  { value: "970230009", name: "Corpbanca" },
  { value: "979510004", name: "Banco HSBC" },
  { value: "970180001", name: "Scotiabank" },
];

export const CURRENCY_CHILE: IOptionSelection[] = [
  { value: CurrencyEnum.CLP, name: CurrencyEnum.CLP },
  { value: CurrencyEnum.UF, name: CurrencyEnum.UF },
];
