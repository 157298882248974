import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { MaterialSizeEnum } from "../../../shared/infrastructure/MaterialSizeEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,
    },
    dialogContentText: {
      marginBottom: 24,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    primaryColor: {
      color: theme.palette.primary.main,
      "&.MuiButton-root": {
        fontSize: theme.spacing(2),
      },
    },
    errorColor: {
      color: theme.palette.error.main,
      "&.MuiButton-root": {
        fontSize: theme.spacing(2),
      },
    },
    cancelAction: {
      color: theme.palette.error.dark,
      "&.MuiButton-root": {
        fontSize: theme.spacing(2),
      },
    },
  })
);

export interface ConfirmRetentionModalProps {
  open: boolean;
  titleText: string;
  contentText?: string | React.ReactElement;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  maxWidth?: MaterialSizeEnum;
  handleClose: () => void;
  handleAccept: () => void;
}

export const ConfirmRetentionModal: React.FC<ConfirmRetentionModalProps> = (
  props: React.PropsWithChildren<ConfirmRetentionModalProps>
) => {
  const classes = useStyles(props);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" color="primary">
            {props.titleText}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText
            className={classes.dialogContentText}
            id="alert-dialog-description"
          >
            <Typography variant="caption" color="inherit">
              {props.contentText}
            </Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={props.handleClose}
          className={classes.cancelAction}
          color="primary"
        >
          <Typography variant="caption" color="inherit">
            {props.cancelText}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleAccept}
          autoFocus
        >
          <Typography variant="caption">{props.acceptText}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmRetentionModal.defaultProps = {
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: MaterialSizeEnum.MD,
};
