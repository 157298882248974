import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Upload, X } from "react-feather";

import { get } from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { OriginEnum } from "../../../shared/infrastructure/OriginEnum";
const accept_files: string[] = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/csv",
  "application/pdf",
  "image/png",
  "image/jpg",
];

export interface IFileUpload {
  prop: string;
  files?: KshFile[];
  origin: OriginEnum;

  onChange?(value: { prop: string; files?: KshFile; origin: OriginEnum }): void;

  onDelete?(
    resultFiles:
      | { prop: string; files?: KshFile[] }
      | { prop: string; files?: KshFile }
  ): void;
  isUploading: boolean;
  isUploaded: boolean;
  uploadError: boolean;
  maxSizeMb?: number;
  multiple?: boolean;
}

export interface KshFile {
  id: string;
  name: string;
  extension: string;
  blob: Blob;
  fileSize: string;
  base64?: string;
  file?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: "12px",
      paddingLeft: "30px",
      paddingRight: "30px",
      boxShadow: "none",
      borderRadius: "8px",
      backgroundColor: "#F7FAFC",
      marginBottom: "20px",
      display: "flex",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
    },
    centerItemsVertically: {
      display: "flex",
      alignItems: "center",
    },
    smallSubtitle: {
      fontSize: "12px",
    },
  })
);

const generateNameFiles = (files: KshFile[]) =>
  files.map((f: KshFile) => ({
    id: f.name.replace(/ /g, "_"),
    name: f.name,
    blob: f.blob,
    fileSize: f.fileSize,
    base64: f.base64,
    extension: f.extension,
  }));

const getFileType = (nameFile: string) => {
  const parts: string[] = nameFile.split(".");
  return parts[parts.length - 1];
};

export const FileUpload: React.FC<IFileUpload> = (props: IFileUpload) => {
  const [nameFiles, setNameFiles] = useState<KshFile[]>(
    generateNameFiles(get(props, "files", []))
  );

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setNameFiles(generateNameFiles(get(props, "files", [])));
  }, [props.files]);

  const handleDelete = (id: string) => {
    if (!props.multiple) {
      props.onDelete?.({ files: undefined, prop: props.prop });
      setNameFiles([]);

      return;
    }

    const copy_names = nameFiles.filter((f: KshFile) => f.id !== id);
    props.onDelete?.({ prop: props.prop, files: copy_names });
    setNameFiles([...copy_names]);
  };

  const byteToMb = (sizeInBytes: number) => {
    const fileInMb = (sizeInBytes / (1024 * 1024)).toFixed(4);
    return parseFloat(fileInMb);
  };

  const handleCaptureFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    setError(false);

    if (!event.target.files || event.target.files.length === 0) return;
    if (!isValidFile(event.target.files[0], props.maxSizeMb)) {
      setError(true);
      return;
    }

    const all_files = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    const fileBlob: Blob = event.target.files[0];
    reader.onload = (): void => {
      const base64_file: string | undefined | null = reader.result
        ?.toString()
        .split(",")[1];

      const ksh_file: KshFile = {
        id: all_files[0].name.replace(/ /g, "_"),
        base64: base64_file!,
        blob: fileBlob,
        fileSize: `(${byteToMb(all_files[0].size)}mb)`,
        name: all_files[0].name,
        extension: `.${getFileType(all_files[0].name)}`,
      };
      props.onChange?.({
        prop: props.prop,
        files: ksh_file,
        origin: props.origin,
      });

      if (!props.multiple) {
        setNameFiles([ksh_file]);

        return;
      }

      const copy_name_files: KshFile[] = [...nameFiles];
      copy_name_files.push(ksh_file);
      setNameFiles(copy_name_files);
    };
  };

  const isValidFile = (file: File, maxSize?: number) => {
    const fileMb = byteToMb(file.size);
    const is_valid = 0 < fileMb;
    const checkType: boolean = accept_files.some(
      (ftype) => ftype === file.type
    );
    if (!checkType) return false;
    if (maxSize) return fileMb < maxSize && is_valid;

    return is_valid;
  };

  const { isUploading, isUploaded, uploadError } = props;
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container item xs={12} spacing={1}>
          <Paper className={classes.paper}>
            {nameFiles.length > 0 ? (
              <Grid
                item
                container
                xs={7}
                className={classes.centerItemsVertically}
              >
                <Grid item container xs={10}>
                  <Grid item xs={10}>
                    <Typography
                      noWrap
                      variant={"body2"}
                      color={"textSecondary"}
                    >
                      {nameFiles[0].name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                      {isUploaded && nameFiles[0].fileSize}
                    </Typography>
                  </Grid>
                </Grid>

                {isUploading && (
                  <Grid item xs={12}>
                    <Typography
                      variant={"body2"}
                      color={"primary"}
                      className={classes.smallSubtitle}
                    >
                      Esto puede tardar unos minutos
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid item xs={8} className={classes.centerItemsVertically}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  No se ha seleccionado ningún documento
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={5}
              justify={"flex-end"}
              className={classes.buttonContainer}
            >
              {isUploaded && (
                <IconButton
                  onClick={() => handleDelete(nameFiles[0].id)}
                  color="primary"
                >
                  <X size={20} />
                </IconButton>
              )}
              {isUploading && (
                <CircularProgress
                  color="primary"
                  size={30}
                  thickness={4}
                  style={{ fontWeight: 800 }}
                />
              )}
              {!isUploaded && !isUploading && (
                <label htmlFor={props.prop}>
                  <input
                    id={props.prop}
                    color="primary"
                    accept={accept_files.join(",")}
                    type="file"
                    onChange={handleCaptureFile}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    size={"medium"}
                    color="primary"
                    startIcon={<Upload size={20} />}
                  >
                    {"Cargar archivo"}
                  </Button>
                </label>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {error || uploadError ? (
        <Grid item xs={12}>
          <Typography
            id="typography_error"
            variant="caption"
            color="error"
            display="block"
            gutterBottom
            style={{ marginTop: 3 }}
          >
            Error en el archivo, inténtalo nuevamente
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
