/**
 * Catalog Panama
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogHn: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    {
      name: "BANCO ATLÁNTIDA S.A.",
      value: "1001",
    },
    {
      name: "BANCO DE OCCIDENTE S.A.",
      value: "1002",
    },
    {
      name: "BANCO DE LOS TRABAJADORES",
      value: "1003",
    },
    {
      name: "BANCO FINANCIERA CENTROAMERICANA S.A (FICENSA)",
      value: "1004",
    },
    {
      name: "BANCO HONDUREÑO DEL CAFÉ S.A (BANHCAFE)",
      value: "1005",
    },
    {
      name: "BANCO DEL PAÍS S.A.",
      value: "1006",
    },
    {
      name: "BANCO FINANCIERA COMERCIAL HONDUREÑA S.A. (FICOHSA)",
      value: "1007",
    },
    {
      name: "BANCO LAFISE HONDURAS S.A.",
      value: "1008",
    },
    {
      name: "BANCO AZTECA HONDURAS",
      value: "1009",
    },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.HNL} - Lempiras`,
      value: CurrencyEnum.HNL,
    },
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
