import { IUseMassiveStatusModal } from "../../../shared/interfaces/IUseModalMassiveStatus";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { selectApp } from "../../../store/selectors/selectors";
import {
  setIsOpenMassiveStatusEmailsModal,
  setIsOpenMassiveStatusInstructionsModal,
  setIsOpenMassiveStatusModal,
} from "../../../store/actions/app.actions";
import { defaultTo, get, isNil, isUndefined, last, split } from "lodash";
import { useEffect, useState } from "react";
import * as React from "react";
import { FileMassiveData } from "../../../../types/update_chargeback_status_massive_request";

export const useMassiveStatusModal = (props: {
  massiveStatusFile: FileMassiveData | undefined;
  setMassiveStatusFile: React.Dispatch<
    React.SetStateAction<FileMassiveData | undefined>
  >;
}): IUseMassiveStatusModal => {
  const dispatch = useAppDispatch();
  const { isOpenMassiveStatusModal } = useAppSelector(selectApp);
  const [isDisabledContinueBtn, setIsDisabledContinueBtn] =
    useState<boolean>(false);
  const allowedExtensions: string = ".csv";

  const onCloseModal = (): void => {
    props.setMassiveStatusFile(undefined);
    dispatch(setIsOpenMassiveStatusModal(false));
  };

  const handleCheckHereLink = (): void => {
    dispatch(setIsOpenMassiveStatusInstructionsModal(true));
  };

  const handleContinue = (): void => {
    dispatch(setIsOpenMassiveStatusModal(false));
    dispatch(setIsOpenMassiveStatusEmailsModal(true));
  };
  const handleFilesUploaded = (event: any): void => {
    if (!isNil(get(event, "target.files.[0].name"))) {
      let files = event.target.files;
      let sizeFile: number = 0;

      for (let index: number = 0; index < files.length; index++) {
        let reader: FileReader = new FileReader();
        const fileName: string = event.target.files[index].name;

        sizeFile = event.target.files[index].size / 1024 / 1024;

        reader.onload = () => {
          const value: string = defaultTo(
            last(split(String(reader.result), ",")),
            ""
          );

          const fileData: FileMassiveData = {
            data: value,
            fileName,
            size: sizeFile,
          };

          props.setMassiveStatusFile(fileData);
        };

        reader.readAsDataURL(files[index]);
      }
    }
  };

  const handleRemoveFile = (): void => {
    props.setMassiveStatusFile(undefined);
  };

  const validateFileSize = (size: number): boolean => {
    // Validate if the file size is greater than 1MB.
    return size > 1;
  };

  useEffect(() => {
    if (isUndefined(props.massiveStatusFile)) {
      setIsDisabledContinueBtn(true);
    } else {
      setIsDisabledContinueBtn(validateFileSize(props.massiveStatusFile.size));
    }
  }, [props.massiveStatusFile]);

  return {
    allowedExtensions,
    handleCheckHereLink,
    handleContinue,
    handleFilesUploaded,
    handleRemoveFile,
    isDisabledContinueBtn,
    isOpen: isOpenMassiveStatusModal,
    onCloseModal,
  };
};
