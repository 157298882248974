import { buildNotification } from "./../constants/snackBar";
import {
  ISnackBar,
  ISnackBarWithAction,
} from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { get, isEmpty } from "lodash";
import React from "react";
import { Branch } from "../../containers/MerchantCentralization/state/useBasicMerchantCentralization";
import { MASSIVE_SERVICE_ACTIONS } from "../constants/websocket_actions";
import { NotificationTypeEnum } from "../enums/SnackbarEnum";
import { WebSocketMessageEnum } from "../enums/WebSocketMessageEnum";
import { setSelectedRows } from "../../store/actions/infoBranches.action";
import {
  IOnErrorWebSocket,
  IOnMessageGeneralWebSocket,
  IOnMessageWebSocket,
  IOnOpenGeneralWebSocket,
  IOnOpenWebSocket,
} from "../interfaces/webSockets.interface";

export const onOpenWebSocket = (
  nodesToDecentralize: React.MutableRefObject<string[]>,
  rowsDecentralized: Branch[],
  webSocket: WebSocket | null,
  paramsWS: object
) => {
  webSocket?.send(JSON.stringify(paramsWS));
  nodesToDecentralize.current = rowsDecentralized.map(
    (branch) => branch.merchantId
  );
};

export const onMessageWebSocket = (
  event: MessageEvent,
  webSocket: WebSocket | null,
  successMessage: string,
  nodesToDecentralize: React.MutableRefObject<string[]>,
  customerMerchantId: string,
  showSnackbar: (value: ISnackBar | ISnackBarWithAction) => void,
  resetVariables: () => void,
  getInfoBranches: (searchMode: boolean) => void,
  dispatch: any
) => {
  const getMessage = JSON.parse(get(event, "data", "{}"));
  const flowType = get(getMessage, "data.type", "");
  const merchantId = get(getMessage, "data.merchantId", "");
  const isRollbackDecentralizeBranch = get(getMessage, "data.rollback", false);

  const handleSuccessWSFlow = (message: string) => {
    showSnackbar(
      buildNotification(NotificationTypeEnum.DESCENTRALIZE_SUCCESS, {
        color: "success",
        message,
        variant: "simple",
        withIcon: false,
      })
    );
    resetVariables();
    getInfoBranches(true);
    dispatch(setSelectedRows(0));
  };

  const removeSuccessDecentralizeBranchFromList = (
    successMerchantId: string
  ) => {
    nodesToDecentralize.current = nodesToDecentralize.current.filter(
      (merchantId: string) => merchantId !== successMerchantId
    );
  };

  const showErrorNotification = (errorMessage: string) => {
    showSnackbar({
      color: "danger",
      message: errorMessage,
      variant: "simple",
      withIcon: false,
    });
  };

  const handleDecentralizeBranchFailure = (
    message: string,
    merchantId: string
  ) => {
    showSnackbar(
      buildNotification(NotificationTypeEnum.DESCENTRALIZE_SUCCESS, {
        color: "danger",
        message,
        variant: "simple",
        withIcon: false,
      })
    );
    removeSuccessDecentralizeBranchFromList(merchantId);
  };

  const websocketStatusCompleteAndFlowTypeIsCentralize =
    getMessage.status === WebSocketMessageEnum.COMPLETE &&
    flowType === MASSIVE_SERVICE_ACTIONS.centralize;
  const websocketStatusCompleteAndFlowTypeIsDecentralizeAndIsNoErrorOnDecentralize =
    getMessage.status === WebSocketMessageEnum.COMPLETE &&
    flowType === MASSIVE_SERVICE_ACTIONS.decentralize &&
    !isRollbackDecentralizeBranch;
  const websocketStatusCompleteAndFlowTypeIsDecentralizeAndExistErrorOnDecentralize =
    getMessage.status === WebSocketMessageEnum.COMPLETE &&
    flowType === MASSIVE_SERVICE_ACTIONS.decentralize &&
    isRollbackDecentralizeBranch;
  const messageIsNotEmpty = !isEmpty(get(getMessage, "data.message", ""));

  if (websocketStatusCompleteAndFlowTypeIsCentralize) {
    webSocket?.close();
    handleSuccessWSFlow(successMessage);
  }

  if (
    websocketStatusCompleteAndFlowTypeIsDecentralizeAndIsNoErrorOnDecentralize
  ) {
    removeSuccessDecentralizeBranchFromList(merchantId);

    if (nodesToDecentralize.current.length === 0) {
      webSocket?.close();
      handleSuccessWSFlow(successMessage);
    }
  }

  if (
    websocketStatusCompleteAndFlowTypeIsDecentralizeAndExistErrorOnDecentralize
  ) {
    handleDecentralizeBranchFailure(
      get(getMessage, "data.message", ""),
      merchantId
    );

    if (nodesToDecentralize.current.length === 0) {
      webSocket?.close();
      handleSuccessWSFlow(successMessage);
    }
  }

  if (getMessage.status === WebSocketMessageEnum.ERROR) {
    removeSuccessDecentralizeBranchFromList(merchantId);
    messageIsNotEmpty &&
      showErrorNotification(get(getMessage, "data.message", ""));

    if (nodesToDecentralize.current.length === 0) {
      webSocket?.close();
      resetVariables();
    }

    if (merchantId === customerMerchantId) {
      webSocket?.close();
      resetVariables();
    }
  }
};

export const handleOnErrorWebSocket = (
  webSocket: WebSocket | null,
  showSnackbar: (value: ISnackBar | ISnackBarWithAction) => void,
  resetVariables: () => void,
  errorMessage: string
) => {
  const showErrorNotification = (errorMessage: string) => {
    showSnackbar({
      color: "danger",
      message: errorMessage,
      variant: "simple",
      withIcon: false,
    });
  };

  webSocket?.close();
  showErrorNotification(errorMessage);
  resetVariables();
};

export const onOpenGeneralWebSocket = (
  webSocket: WebSocket | null,
  paramsWS: object
) => {
  webSocket?.send(JSON.stringify(paramsWS));
};

export const handleOnMessageGeneralWebSocket = (
  event: MessageEvent,
  webSocket: WebSocket | null,
  paramsWS: object,
  showSnackbar: (value: ISnackBar | ISnackBarWithAction) => void,
  resetVariables: () => void,
  getInfoBranches: (searchMode: boolean) => void,
  dispatch: any
) => {
  const showErrorNotification = (errorMessage: string) => {
    showSnackbar({
      color: "danger",
      message: errorMessage,
      variant: "simple",
      withIcon: false,
    });
  };

  const getMessage = JSON.parse(get(event, "data", "{}"));

  if (
    getMessage.message === WebSocketMessageEnum.DECENTRALIZE &&
    getMessage.status === WebSocketMessageEnum.COMPLETE
  ) {
    webSocket?.send(
      JSON.stringify({
        ...paramsWS,
        action: "copyConfigurationsMerchantNode",
      })
    );
  }
  if (
    getMessage.message === WebSocketMessageEnum.COPY &&
    getMessage.status === WebSocketMessageEnum.COMPLETE
  ) {
    webSocket?.close();
    showSnackbar(
      buildNotification(NotificationTypeEnum.DESCENTRALIZE_SUCCESS, {
        color: "success",
        message: "Se descentralizó los Branches con éxito",
        variant: "simple",
        withIcon: false,
      })
    );
    resetVariables();
    getInfoBranches(true);
    dispatch(setSelectedRows(0));
  }
  if (getMessage.status === WebSocketMessageEnum.ERROR) {
    webSocket?.close();
    showErrorNotification(get(getMessage, "data.message", ""));
    resetVariables();
  }
};

export const massiveServicesEditionWebsocket = {
  connect: (
    onErrorParams: IOnErrorWebSocket,
    onMessageParams: IOnMessageWebSocket,
    onOpenParams: IOnOpenWebSocket,
    socket: WebSocket
  ) => {
    socket.onopen = () =>
      onOpenWebSocket(
        onOpenParams.nodesToDecentralize,
        onOpenParams.rowsDecentralized,
        socket,
        onOpenParams.paramsWS
      );
    socket.onmessage = (event: MessageEvent) =>
      onMessageWebSocket(
        event,
        socket,
        onMessageParams.successMessage,
        onMessageParams.nodesToDecentralize,
        onMessageParams.customerMerchantId,
        onMessageParams.showSnackbar,
        onMessageParams.resetVariables,
        onMessageParams.getInfoBranches,
        onMessageParams.dispatch
      );
    socket.onerror = () =>
      handleOnErrorWebSocket(
        socket,
        onErrorParams.showSnackbar,
        onErrorParams.resetVariables,
        onErrorParams.errorMessage
      );
  },
};

export const decentralizeGeneralWebsocket = {
  connect: (
    onErrorParams: IOnErrorWebSocket,
    onMessageParams: IOnMessageGeneralWebSocket,
    onOpenParams: IOnOpenGeneralWebSocket,
    socket: WebSocket
  ) => {
    socket.onopen = () => onOpenGeneralWebSocket(socket, onOpenParams.paramsWS);
    socket.onmessage = (event: MessageEvent) =>
      handleOnMessageGeneralWebSocket(
        event,
        socket,
        onMessageParams.paramsWS,
        onMessageParams.showSnackbar,
        onMessageParams.resetVariables,
        onMessageParams.getInfoBranches,
        onMessageParams.dispatch
      );
    socket.onerror = () =>
      handleOnErrorWebSocket(
        socket,
        onErrorParams.showSnackbar,
        onErrorParams.resetVariables,
        onErrorParams.errorMessage
      );
  },
};
