import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
export interface IModalDialog {
  open: boolean;
  handleClose: () => void;
  handleOk: () => void;
  title: string;
  labelBtnAccept: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.dark,
    fontSize: "16px",
  },
  buttonClose: {
    fontSize: 16,
    color: theme.palette.grey[900],
  },
  text: {
    color: "black",
    align: "justify",
  },
}));
export const ModalDialog: React.FC<IModalDialog> = (props: IModalDialog) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: 10 }}>
          <Typography
            variant="h6"
            style={{
              fontSize: 15,
              fontWeight: 500,
              padding: 12,
            }}
          >
            <Typography variant={"h5"} color="primary">
              {props.title}
            </Typography>
          </Typography>
          <DialogContent style={{ padding: "8px 15px" }}>
            <DialogContentText id="alert-dialog-description">
              <Typography variant={"h6"} className={styles.text}>
                Se perderán las configuraciones agregadas. Este paso no se puede
                revertir.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "8px 25px" }}>
            <Button onClick={props.handleClose} className={styles.buttonClose}>
              Cancelar
            </Button>
            <Button onClick={props.handleOk} color={"default"}>
              <a style={{ color: "red", fontSize: 16 }}>
                {props.labelBtnAccept}
              </a>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
