import { IFilters, IOption } from "../FilterBox.interfaces";

export const clearFilterChips = (filters: IFilters[]): IFilters[] =>
  filters.map((f) => ({
    ...f,
    options: f.options.map((o) => ({ ...o, selected: false })),
  }));

export const chipBoolToNumber = (selected: boolean): number =>
  selected ? 1 : 0;

export const countChipsSelected = (chips: IOption[]) =>
  chips.reduce((acc, chip) => acc + chipBoolToNumber(chip.selected), 0);

export const countFiltersSelected = (filters: IFilters[]) =>
  filters.reduce((acc, filter) => acc + countChipsSelected(filter.options), 0);
