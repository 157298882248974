import {
  CountryEnum,
  CurrencyEnum,
} from "../../../shared/constants/processorConstants";

export interface IUseProcessorInfoState {
  getCurrencyByCountry: (country: string) => string[];
}

export const useProcessorInfoState = (): IUseProcessorInfoState => {
  const getCurrencyByCountry = (country: string): string[] => {
    switch (country) {
      case CountryEnum.PERU:
        return [CurrencyEnum.PEN, CurrencyEnum.USD];
      case CountryEnum.COLOMBIA:
        return [CurrencyEnum.COP];
      case CountryEnum.MEXICO:
        return [CurrencyEnum.MXN];
      case CountryEnum.CHILE:
        return [CurrencyEnum.CLP, CurrencyEnum.UF];
      default:
        return [""];
    }
  };

  return {
    getCurrencyByCountry,
  };
};
