import { useState, ChangeEvent } from "react";
import { RowElementEditProps } from "../RowElementEdit";

export interface RowElementEditState {
  selectProps: {
    value: string;
    editState: boolean;
    showEditIcon: boolean;
    editValue: string;
  };
  actions: {
    handleShowEditIcon: (editState: boolean) => () => void;
    handleSetEditState: (editState: boolean) => () => void;
    handleCancelEdit: () => () => void;
    handleSaveEdit: () => () => void;
    handleInputEditChange: () => (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handleEditValueChange: (value: string) => void;
  };
}

export const useRowElementEditState = (
  props: RowElementEditProps
): RowElementEditState => {
  const [editState, setEditState] = useState<boolean>(false);
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(props.value);

  const handleShowEditIcon = (show: boolean) => () => {
    setShowEditIcon(show);
  };

  const handleSetEditState = (editState: boolean) => () => {
    setEditState(editState);
  };

  const handleCancelEdit = () => () => {
    setShowEditIcon(false);
    setEditState(false);
    setEditValue(props.value);
  };

  const handleSaveEdit = () => () => {
    setShowEditIcon(false);
    setEditState(false);
    props.handleEditValueChange(editValue);
    setEditValue(props.value);
  };

  const handleInputEditChange = () => (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEditValue(e.target.value);
  };

  return {
    selectProps: {
      editState,
      showEditIcon,
      editValue,
      value: props.value,
    },
    actions: {
      handleShowEditIcon: handleShowEditIcon,
      handleSetEditState: handleSetEditState,
      handleCancelEdit: handleCancelEdit,
      handleSaveEdit: handleSaveEdit,
      handleInputEditChange: handleInputEditChange,
      handleEditValueChange: props.handleEditValueChange,
    },
  };
};
