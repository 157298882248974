import React, { FC } from "react";
import { RouteEnum } from "../../shared/infrastructure/routes";
import { HeaderCard } from "../../components/HeaderCard/HeaderCard";
import {
  NAVIGATION_INDEX,
  NAVIGATION_RECHARGE,
  RECHARGE_TITLE,
} from "../../shared/constants/labels/recharge_wallet_labels";
import { useGetMerchantCountry } from "../../store/hooks/hooks";
import { RechargeWalletCard } from "../../components/RechargeWalletSPEI/Card/RechargeWalletCard";
import { countriesList } from "../../shared/utils/utils";

const RechargeWallet: FC = () => {
  const country = useGetMerchantCountry();

  return (
    <>
      <HeaderCard
        title={RECHARGE_TITLE}
        navItems={[
          { id: "1", text: NAVIGATION_INDEX, url: RouteEnum.DASHBOARD },
          { id: "2", text: NAVIGATION_RECHARGE, url: RouteEnum.CHECKOUT },
        ]}
      />
      {countriesList.includes(country) && <RechargeWalletCard />}
    </>
  );
};

export { RechargeWallet };
