import { useEffect, useState } from "react";
import { RootState } from "../../../store/store";
import {
  deleteShareholders,
  validateIsPossibleAddShareholder,
} from "../../../shared/utils/shareholderUtils";
import {
  setOpenModalSh,
  setShareHolder,
} from "../../../store/reducers/legalDetails/legalDetails";
import { IUseShareholderSection } from "./useShareholderSection.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { get } from "lodash";

export const useShareholderSection = (): IUseShareholderSection => {
  const { shareHolders, openModalSh, basicDataForm } = useAppSelector(
    (state: RootState) => ({
      ...state.legalDetails,
      ...state,
    })
  );
  const dispatch = useAppDispatch();
  const [isPossibleAddShareholder, setIsPossibleAddShareholder] =
    useState(true);

  const [shareholderPosition, setShareholderPosition] = useState<string>(
    shareHolders.length.toString()
  );
  const [openModalDeleteShareholder, setOpenModalDeleteShareholder] =
    useState(false);
  const [nestingToDelete, setNestingToDelete] = useState("");

  const [country, setCountry] = useState<string>("");

  const handleOpenModal = (nesting: string) => {
    dispatch(setOpenModalSh(true));
    setShareholderPosition(nesting);
  };

  const handleOpenModalDialog = (nesting: string) => {
    setNestingToDelete(nesting);
    setOpenModalDeleteShareholder(true);
  };

  const handleCloseModalDialog = () => {
    setOpenModalDeleteShareholder(false);
    setNestingToDelete("");
  };

  const handleAcceptModalDialog = () => {
    dispatch(setShareHolder(deleteShareholders(nestingToDelete, shareHolders)));

    handleCloseModalDialog();
  };

  useEffect(() => {
    setIsPossibleAddShareholder(validateIsPossibleAddShareholder(shareHolders));
  }, [shareHolders]);

  useEffect(() => {
    const country: string = get(
      basicDataForm,
      "nodeInfo.generalInfo.country",
      ""
    );

    setCountry(country);
  }, [basicDataForm]);

  return {
    country,
    handleAcceptModalDialog,
    handleCloseModalDialog,
    handleOpenModal,
    handleOpenModalDialog,
    isPossibleAddShareholder,
    openModalDeleteShareholder,
    openModalSh,
    shareholderPosition,
    shareHolders,
  };
};
