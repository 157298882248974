import React, { PropsWithChildren } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { genericAddButtonStyles } from "./GenericAddButton.styles";
import { IGenericAddButtonProps } from "../../../../shared/infrastructure/interfaces/IGenericAddButtonProps";

export const GenericAddButton: React.FC<IGenericAddButtonProps> = (
  props: PropsWithChildren<IGenericAddButtonProps>
) => {
  const classes = genericAddButtonStyles();

  return (
    <Button
      variant="text"
      color="primary"
      sx={{ boxShadow: 1, backgroundColor: "#FFFF" }}
      className={classes.addButton}
      onClick={props.handleOnClick}
    >
      <AddIcon />
      {props.buttonTitle}
    </Button>
  );
};
