export enum LabelsWallet {
  APPROVAL = "Aprobado",
  DECLINED = "Rechazado",
  DEBIT = "Débito",
  CREDIT = "Recarga",
  DEBIT_SYMBOL = "-",
  CREDIT_SYMBOL = "+",
  CASH_OUT = "Cash out",
  LIQUIDACION = "Liquidación",
  TRANSFER_OUT = "Transfer out",
  ERROR_GET_TRANSACTIONS = "Hubo un error al consultar los registros. Por favor intenta nuevamente",
  ERROR_DOWNLOAD_FILE = "Ha ocurrido un error al descargar el archivo",
  SUCCESS_DOWNLOAD_FILE = "La descarga del archivo se ha completado con éxito",
  NOT_COINCIDENCES = "No se encontraron coincidencias.",
  KUSHKI = "Kushki",
  COMMERCE = "Comercio",
  DATE = "F. Creación",
}
