import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ILoadingDialogProps } from "../../../shared/infrastructure/interfaces/ILoadingDialogProps";

const useStyles = makeStyles({
  container: {
    padding: "8px 16px",
  },
  title: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "140%",
  },
  subtitle: {
    color: "#293036",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "150%",
  },
});

export const LoadingDialog: React.FC<ILoadingDialogProps> = (
  props: ILoadingDialogProps
) => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} fullWidth>
      <DialogContent>
        <DialogContentText>
          <Grid container className={classes.container}>
            <Grid item xs={2}>
              <CircularProgress size={60} thickness={5} />
            </Grid>
            <Grid item xs={10} container alignItems="center" direction="row">
              <Grid xs={12}>
                <Typography className={classes.title}>{props.title}</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.subtitle}>
                  {props.subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
