export const cardBrands: { [k: string]: string } = {
  ["alia"]: "Alia",
  ["amex"]: "Amex",
  ["carnet"]: "Carnet",
  ["diners"]: "Diners",
  ["discover"]: "Discover",
  ["jcb"]: "JCB",
  ["magna"]: "Magna",
  ["mastercard"]: "MasterCard",
  ["union pay"]: "Union Pay",
  ["visa"]: "Visa",
};
