import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const menuOptionsStyles: IStyles = {
  btn: {
    minWidth: "40px",
    paddingLeft: "2px",
    paddingRight: "2px",
    border: "none",
    borderRadius: "48px",
    "&:hover": {
      border: "none",
      borderRadius: "48px",
    },
  },
};

export { menuOptionsStyles };
