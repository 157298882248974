export const parseDate = (dateStr: string): Date => {
  const [day, month, yearTime]: string[] = dateStr.split("-");
  const [year, time]: string[] = yearTime.split(" ");
  const [hours, minutes, seconds]: string[] = time.split(":");

  return new Date(
    Number(year),
    Number(Number(month) - 1),
    Number(day),
    Number(hours),
    Number(minutes),
    Number(seconds)
  );
};

export const subtractDates = (dateStr1: string, dateStr2: string): number => {
  const date1: Date = parseDate(dateStr1);
  const date2: Date = parseDate(dateStr2);

  // Calculate the difference in milliseconds
  const differenceMs: number = Number(date1) - Number(date2);

  // Convert milliseconds to days
  const oneDayInMs: number = 24 * 60 * 60 * 1000;
  const differenceDays: number = Math.floor(differenceMs / oneDayInMs);

  return differenceDays;
};
