/* istanbul ignore file */

export enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  CLP = "CLP",
  UF = "UF",
  PEN = "PEN",
  MXN = "MXN",
  BRL = "BRL",
  GTQ = "GTQ",
  CRC = "CRC",
  HNL = "HNL",
  NIO = "NIO",
}
