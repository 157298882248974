import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const metricsContainerStyles = createNamedStyles({
  divider: {
    background: "#000",
  },
  main: {
    background: "#fff",
    marginLeft: "14px",
    marginRight: "14px",
    padding: "14px 14px",
    width: "100%",
  },
  mainMobile: {
    background: "#fff",
    marginLeft: "0pt",
    padding: "14px 14px",
    width: "100%",
  },
});
