import { IModalActionProps } from "@store/interfaces/RulesAlarm.interfaces";

export const initialModalActionProps: IModalActionProps = {
  description: "",
  disablePrimaryButton: false,
  isOpen: false,
  primaryButtonLabel: "",
  ruleId: "",
  secondaryButtonLabel: "",
  title: "",
  type: "",
};
