import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { LoadingTextEnum } from "../../../shared/enums/loading_text";
import { TRANSACTION_STATUS_ENUM_LOOKUP } from "../../../shared/types";
import { useKshRedux } from "../../../store/hooks/hooks";
import { ITransaction } from "../../../store/interfaces/TransactionsState.interfaces";
import { IFormInputResendCertificate } from "../InputResendCertificate";
import { IUseInputResendCertificateState } from "./useInputResendCertificateState.interfaces";

export const useInputResendCertificateState = (
  status: string,
  transaction: ITransaction,
  transactionId: string
): IUseInputResendCertificateState => {
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [showInputs, setShowInputs] = useState<boolean>(false);

  const {
    actions: { setLoadingText, resendCertificateEpic },
  } = useKshRedux();

  useEffect(() => {
    const item = localStorage.getItem("roles");

    if (item) {
      const roles = JSON.parse(item);

      setShowInputs(
        transaction.certificateURL !== "" &&
          roles.B2CCoordinator &&
          status === TRANSACTION_STATUS_ENUM_LOOKUP["approved"]
      );
    }

    setLoadingText(LoadingTextEnum.LOADING);
  }, []);

  const onSubmit: SubmitHandler<IFormInputResendCertificate> = async (data) => {
    setDisabledInput(true);
    setDisabledButton(true);

    const body = {
      email: data.email,
      id: transactionId,
    };

    await resendCertificateEpic(body);

    setDisabledInput(false);
    setDisabledButton(false);
  };

  return {
    disabledButton,
    disabledInput,
    onSubmit,
    showInputs,
  };
};
