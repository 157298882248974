import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const sectionInfoComponentStyles = createNamedStyles({
  principal: {
    marginBottom: "32px",
  },
  sectionInfoComponent: {
    marginRight: "40px",
  },
  sectionTitleComponent: {
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "7px",
    color: "293036",
  },
  sectionDesc: {
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 500,
    marginRight: "40px",
    color: "#677784",
  },
});
