import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const bankListChipStyles = createNamedStyles({
  chip: {
    borderRadius: 4,
    background: "#F0F4F9",
  },
  link: {
    color: "#1E65AE",
    lineHeight: "170%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    cursor: "pointer",
  },
});
