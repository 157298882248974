import { Role, ROLES_MERCHANT_BACKOFFICE } from "../constants/RoleNames";
import { ItemCategoryItem } from "@kushki/connect-ui";
import { LabelStatusEnum } from "../enum/StatusUserEnum";

export const buildRoleItemsCategory = (): ItemCategoryItem[] =>
  ROLES_MERCHANT_BACKOFFICE.map((role: Role) => ({
    label: role.value,
    selected: false,
    value: role.id,
  }));

export const buildStatusItemsCategory = (): ItemCategoryItem[] => {
  return [
    {
      label: LabelStatusEnum.ACTIVE,
      selected: false,
      value: LabelStatusEnum.ACTIVE,
    },
    {
      label: LabelStatusEnum.INACTIVE,
      selected: false,
      value: LabelStatusEnum.INACTIVE,
    },
  ];
};
