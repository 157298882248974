import { defaultTo, filter, get, isEmpty, isNil, omit } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  AllConfigs,
  ConfigIdEnum,
} from "../../../shared/constants/node_config_enums";
import { EntityName } from "../../../shared/enums/entityName";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import { InvoiceModeEnum } from "../../../shared/enums/InvoiceModeEnum";
import { SemaphoreStatusEnum, status } from "../../../shared/enums/statusEnum";
import { getStatesResume } from "../../../shared/utils/dispersionUtils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { BillingForm } from "../../../store/interfaces/billing.interfaces";
import { SHOW_SECTION } from "../../../store/reducers/app/app.slice";
import {
  getNodeInfo,
  patchMerchantNodeInfo,
  postHierarchyNodeConfig,
  ratesBillingConfig,
} from "../../../store/thunks/app/app.thunks";
import { IUseBillingContainer } from "./useBillingContainer.interfaces";
import { Configs } from "../../../../types/node_info_response";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { setIsLoadingBillingInformation } from "../../../store/actions/billing/billing.actions";

export const useBillingContainer = (): IUseBillingContainer => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const publicMerchantId = searchParams.get("publicMerchantId");
  const {
    configsResponse,
    form,
    nodeInfo,
    merchantNodeInfo,
    getBillingConfigInfo,
    showPanel: isTransactionModifyChecked,
    valuesBillingState,
    isLoadingBillingInformation,
    valuesDeclinedBillingState,
    valuesSubtractBillingState,
    footerButtonsClicked,
    appState: {
      patchMerchantNodeInfo: statePatchMerchantNodeInfo,
      postRatesBilling: statePostRatesBilling,
    },
  } = useAppSelector((state) => ({
    ...state.billing,
    ...state.app,
  }));

  const {
    control,
    formState: { errors },
    reset,
  } = useForm<BillingForm>({
    defaultValues: form,
  });

  const catchBillingRates = () => {
    const billing_elements_approved: string[] = filter(
        valuesBillingState!.checkBillItems,
        ["checked", true]
      ).map((item) => item.value),
      billing_substract_elements_approved: string[] = filter(
        valuesSubtractBillingState!.checkSubtractItems,
        ["checked", true]
      ).map((item) => item.value),
      billing_substract_elements_declined: string[] = filter(
        valuesSubtractBillingState!.checkSubtractItems,
        ["checked", false]
      ).map((item) => item.value),
      billing_declined_elements: string[] = filter(
        valuesDeclinedBillingState!.checkDeclinedItems,
        ["checked", true]
      ).map((item) => item.value),
      billing_declined_status: boolean =
        valuesDeclinedBillingState!.statusDeclined;

    dispatch(
      ratesBillingConfig({
        constitutionalCountry: get(
          nodeInfo,
          "generalInfo.constitutionalCountry",
          ""
        ),
        country: get(nodeInfo, "generalInfo.country", ""),
        publicMerchantId: publicMerchantId!,
        valuesBilling: {
          elementsApproved: [...billing_elements_approved],
          elementsDeclined: billing_declined_status
            ? [...billing_declined_elements]
            : [],
          isModify: valuesBillingState!.isModify,
          statusApproved: valuesBillingState!.statusApproved,
          statusDeclined: billing_declined_status,
        },
        valuesSubtractBilling: {
          elementsApproved: [...billing_substract_elements_approved],
          elementsDeclined: [...billing_substract_elements_declined],
          statusApproved: valuesSubtractBillingState!.statusApproved,
          statusDeclined: status.declined,
        },
      })
    );
  };

  const saveBillingData = () => {
    if (!isEmpty(form) && publicMerchantId) {
      const country = get(nodeInfo, "generalInfo.country", "");
      const constitutionalCountry = get(
        nodeInfo,
        "generalInfo.constitutionalCountry",
        ""
      );

      dispatch(
        patchMerchantNodeInfo({
          configId: ConfigIdEnum.CN003,
          constitutionalCountry,
          country,
          publicMerchantId,
          ...(constitutionalCountry === CountriesEnum.MEXICO
            ? { ...form }
            : { ...omit(form, ["invoiceMode"]) }),
        })
      );
    }

    catchBillingRates();
  };

  const isEmptyConfig = (arrayConfig: Configs[]) => {
    const getConfig: Configs[] = arrayConfig.filter(
      (item) => item.configuration === ConfigIdEnum.CN003
    );

    if (isEmpty(get(getConfig, "[0].value")))
      dispatch(setIsLoadingBillingInformation(false));
  };

  useEffect(() => {
    if (!isEmpty(get(nodeInfo, "configs", [])))
      isEmptyConfig(get(nodeInfo, "configs", []));
  }, [nodeInfo]);

  const catchHierarchyConfig = () => {
    const configResponse: Configs | undefined = defaultTo(
      configsResponse,
      []
    ).find((conf) => get(conf, "configuration", "") === ConfigIdEnum.CN003);

    dispatch(
      postHierarchyNodeConfig({
        configs: [
          {
            ...configResponse,
            centralizedNodesId: undefined,
            status: SemaphoreStatusEnum.COMPLETE,
            updatedAt: Date.now(),
            updatedBy: localStorage.getItem("username") ?? "backoffice",
            value: publicMerchantId!,
          },
        ],
        nodeId: get(nodeInfo, "nodeId", ""),
      })
    );
  };

  const validateToInvokeHierarchy = (isLastSuccess: boolean) => {
    if (isLastSuccess) catchHierarchyConfig();
  };

  useEffect(() => {
    if (!isNil(getBillingConfigInfo)) {
      dispatch(SHOW_SECTION(true));
    }
  }, [getBillingConfigInfo]);

  useEffect(() => {
    if (merchantNodeInfo) {
      reset({
        invoiceFrequency: get(
          merchantNodeInfo,
          "invoiceFrequency",
          FrequencyEnum.NONE
        ) as FrequencyEnum,
        invoiceMode: get(
          merchantNodeInfo,
          "invoiceMode",
          InvoiceModeEnum.WITH_IVA
        ) as InvoiceModeEnum,
      });
    }
  }, [merchantNodeInfo]);

  useEffect(() => {
    if (
      footerButtonsClicked &&
      nodeInfo &&
      publicMerchantId &&
      (nodeInfo.entityName === EntityName.CUSTOMER ||
        nodeInfo.entityName === EntityName.BRANCH)
    )
      saveBillingData();
  }, [footerButtonsClicked]);

  useEffect(() => {
    if (!statePatchMerchantNodeInfo) return;

    if (isTransactionModifyChecked && statePostRatesBilling) {
      const { isSuccess } = getStatesResume([
        statePatchMerchantNodeInfo,
        statePostRatesBilling,
      ]);

      validateToInvokeHierarchy(isSuccess);
    }

    if (!isTransactionModifyChecked) {
      const { isSuccess } = getStatesResume([statePatchMerchantNodeInfo]);

      validateToInvokeHierarchy(isSuccess);
    }
  }, [statePatchMerchantNodeInfo, statePostRatesBilling]);

  useEffect(() => {
    dispatch(
      getNodeInfo({
        configIds: AllConfigs,
        publicMerchantId: publicMerchantId!,
      })
    );
  }, []);

  return {
    form: {
      control,
      errors,
    },
    isLoadingBillingInformation,
  };
};
