import { UseFormReturn } from "react-hook-form";
import { BasicDataInterface } from "../interfaces/BasicData.interface";
import { tipoEmpresaGeneric } from "../enum/BasicStateGeneric/CatalogDataGeneric";

export const setCompanyTypeGeneric = (
  form: UseFormReturn<BasicDataInterface>,
  companyType: string
) => {
  let verifyCompanyType = tipoEmpresaGeneric.some(
    (item) => item === form.getValues("typeCompany")
  );

  if (!verifyCompanyType) {
    form.setValue("typeCompany", "Otra");
    form.setValue("typeCompanyGeneric", companyType);
    form.trigger(["typeCompanyGeneric", "typeCompany"]);
  }
};
