import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/commons/Breadcrumb/Breadcrumb";
import { BreadcrumbItemsEnum } from "../../shared/infrastructure/enums/BreadCrumbItemsEnum";
import BalanceDescription from "../../components/BalanceDescription/BalanceDescription";
import { defaultTo, get } from "lodash";
import { WalletDashboard } from "../../components/WalletDashboard/WalletDashboard";
import { TitleEnum } from "../../shared/infrastructure/enums/TitleEnum";

const merchantData = defaultTo(
  JSON.parse(`${window.localStorage.getItem("merchantBasicInformation")}`),
  {}
);

export const WalletDashboardIndex: React.FC = () => {
  const merchantName = get(merchantData, "name", "");

  return (
    <Container fixed>
      <Grid container>
        <Grid container item xs={12} direction={"column"}>
          <Grid item xs={12}>
            <Breadcrumb items={BreadcrumbItemsEnum} lastItem={merchantName} />
            <Typography
              variant={"h1"}
              color={"primary"}
              style={{ color: "#023365", fontWeight: 400 }}
            >
              {TitleEnum.Wallet}
            </Typography>
            <BalanceDescription />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WalletDashboard />
        </Grid>
      </Grid>
    </Container>
  );
};
