import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { WEBSOCKETS_ENDPOINTS } from "../../../../shared/constants/websockets_endpoints";
import { generateCertificateWS } from "../../../../shared/utils/websockets/generateCertifcate/generate_certificate_ws";
import { IHandleResponseWS } from "../../../../shared/utils/websockets/downloadTransaction/download_transactions_ws.interfaces";
import {
  IGenerateCertificate,
  IResponseCertificateWS,
} from "./generate_certificate.interfaces";
import {
  setMessageAlert,
  setTypeAlert,
  showAlert,
} from "../../../actions/app_actions";
import { MessageAlertEnum } from "../../../../shared/enums/message_alert_enum";
import { hideLoading } from "../../../actions/actions";

export const handleResponse = (
  response: MessageEvent<string>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
): IHandleResponseWS => {
  const { payload, status }: IResponseCertificateWS = JSON.parse(response.data);

  const messageError = payload?.message ?? "Generation failed";

  if (status === "ERROR" || payload?.error) {
    dispatch(hideLoading());
    dispatch(setTypeAlert("error"));
    dispatch(setMessageAlert(MessageAlertEnum.ERROR));
    dispatch(showAlert());

    return {
      closeConnection: true,
      messageError,
    };
  }

  if (status === "COMPLETED_SUCCESS") {
    dispatch(hideLoading());
    dispatch(setTypeAlert("success"));
    dispatch(setMessageAlert(MessageAlertEnum.SUCCESS));
    dispatch(showAlert());

    return {
      closeConnection: true,
    };
  }

  return {
    closeConnection: false,
  };
};

const generateCertificate = createAsyncThunk(
  "transactions/generateCertificate",
  async ({ data }: IGenerateCertificate, thunkAPI) => {
    return await generateCertificateWS(
      WEBSOCKETS_ENDPOINTS.GENERATE_CERTIFICATES,
      {
        data,
        dispatch: thunkAPI.dispatch,
        handleResponse,
      }
    );
  }
);

export default generateCertificate;
