export const INFORMATION_LABELS = {
  DESCRIPTION_BEGIN: "El owner es el ",
  DESCRIPTION_END:
    " de los customers group, customers, stores y branches que debajo de él se conformen.",
  NEXT_BUTTON_LBL: "Continuar",
  OWNER_LABEL: "propietario",
  OWNER_SUBTITLE: "Elige el owner al que pertenece este customer.",
  OWNER_TITLE: "Selecciona un Owner",
  PLACEHOLDER_OWNER: "Buscar Owner",
};
