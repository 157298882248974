import { Box, Grid, Typography } from "@mui/material";
import { dispersionFormStyles as styles } from "../DispersionForm/DispersionForm.styles";
import { BANK_DISCLAIM_LABEL } from "../../shared/constants/labels/dispersion_container_labels";
import DropDownItem from "../DropDownItem/DropDownItem";
import React from "react";
import { IAccordionBanksAccountsProps } from "./AccordionBanksAccounts.interfaces";
import { defaultTo } from "lodash";

const AccordionBanksAccounts = ({
  handleEditBankAccount,
  handleDeleteBankAccount,
  banksAccounts,
  disabledActions,
  massive,
}: IAccordionBanksAccountsProps) => {
  return (
    <Box>
      {defaultTo(banksAccounts, []).length == 0 ? (
        <Grid item xs={12}>
          <Typography color="text.grey" variant="body2" sx={styles.disclaimer}>
            {BANK_DISCLAIM_LABEL}
          </Typography>
        </Grid>
      ) : (
        banksAccounts.map((item, index: number) => (
          <Grid
            item
            key={`bk-${index}-${item.accountNumber}`}
            xs={12}
            sx={styles.dropdown}
          >
            <DropDownItem
              {...item}
              handleEditBankAccount={() => handleEditBankAccount(index)}
              handleDeleteBankAccount={() => handleDeleteBankAccount(index)}
              index={index}
              disabledActions={disabledActions}
              isMainAccount={index === 0}
              massive={massive}
            />
          </Grid>
        ))
      )}
    </Box>
  );
};

export default AccordionBanksAccounts;
