import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    dialogActions: {
      paddingBottom: 24,
      paddingRight: 34,
    },
    iconGrid: {
      alignContent: "center",
      display: "flex",
      paddingLeft: "30px",
      paddingTop: "15px",
    },
    iconMargin: {
      marginRight: "10px",
    },
    paddingLeft: {
      paddingLeft: "30px",
      paddingTop: "15px",
    },
    responseText: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    textButton: {
      fontSize: "15px",
    },
  })
);

export interface MassiveTransactionModalResultProps {
  open: boolean;
  handleClose: () => void;
  wsResponseSuccess: number;
  wsResponseFailed: number;
}

export const MassiveTransactionModalResult: React.FC<
  MassiveTransactionModalResultProps
> = (props: MassiveTransactionModalResultProps) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth={"xs"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <br />
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={classes.iconGrid}>
            <CheckIcon className={classes.iconMargin} />
            <Typography className={classes.responseText} display={"inline"}>
              {`${props.wsResponseSuccess} respuestas ingresadas con éxito.`}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.iconGrid}>
            <NotInterestedIcon className={classes.iconMargin} />
            <Typography className={classes.responseText} display={"inline"}>
              {`${props.wsResponseFailed} respuestas tuvieron un error.`}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.paddingLeft}>
            <Typography variant={"body2"} color={"textSecondary"}>
              Intenta nuevamente ingresar las respuestas fallidas.
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"}>
              Permanecen sin cambios sobre esta página filtrada.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          onClick={props.handleClose}
          autoFocus
          className={classes.textButton}
        >
          {"Entendido"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
