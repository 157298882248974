import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { IHelperCardProps } from "@components/HelperCard/HelperCard.interfaces";
import { helperTextStyles as styles } from "@components/HelperCard/HelperText.styles";
const HelperCard: React.FC<IHelperCardProps> = ({
  text,
  icon,
}: IHelperCardProps) => {
  return (
    <Card sx={styles.helpCard}>
      <Box sx={styles.iconWrapper}>{icon}</Box>
      <Typography
        variant={"overline"}
        sx={styles.description}
        data-testid="helperCard-text"
      >
        {text}
      </Typography>
    </Card>
  );
};

export default HelperCard;
