import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

const SvgIcon = styled(MuiSvgIcon, {
  name: "RatesIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

const IconRates: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
          fill="#023365"
        />
        <path
          d="M19.5 22.7H15.6C15.4 22.7 15.3 22.6 15.2 22.5C15.1 22.4 15 22.2 15 22V20.3C15 20 15.2 19.8 15.5 19.8C15.8 19.8 16 20 16 20.3V21.8H19.3V18.9H15.9C15.5 18.9 15.1 18.5 15.1 18.1V14.6C15.1 14.2 15.5 13.8 15.9 13.8H19.5C19.9 13.8 20.3 14.2 20.3 14.6V16.3C20.3 16.6 20.1 16.8 19.8 16.8C19.5 16.8 19.3 16.6 19.3 16.3V14.8H16.1V17.7H19.5C19.9 17.7 20.3 18.1 20.3 18.5V21.9C20.3 22.3 20 22.7 19.5 22.7Z"
          fill="white"
        />
        <path
          d="M17.7 14.8C17.4 14.8 17.2 14.6 17.2 14.3V12.5C17.2 12.2 17.4 12 17.7 12C18 12 18.2 12.2 18.2 12.5V14.3C18.2 14.6 17.9 14.8 17.7 14.8Z"
          fill="white"
        />
        <path
          d="M17.7 24.5C17.4 24.5 17.2 24.3 17.2 24V22.2C17.2 21.9 17.4 21.7 17.7 21.7C18 21.7 18.2 21.9 18.2 22.2V24C18.2 24.2 17.9 24.5 17.7 24.5Z"
          fill="white"
        />
        <path
          d="M17.5 15.6C17.3 15.6 17.1 15.8 17.1 16V16.4C17.1 16.6 17.3 16.8 17.5 16.8H17.8C18 16.8 18.2 16.6 18.2 16.4V16.1C18.2 15.9 18 15.7 17.8 15.7H17.5V15.6Z"
          fill="white"
        />
        <path
          d="M17.8 20.9C18 20.9 18.2 20.7 18.2 20.5V20.1C18.2 19.9 18 19.7 17.8 19.7H17.5C17.3 19.7 17.1 19.9 17.1 20.1V20.5C17.1 20.7 17.3 20.9 17.5 20.9H17.8Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27ZM18 28C23.5228 28 28 23.5228 28 18C28 12.4772 23.5228 8 18 8C12.4772 8 8 12.4772 8 18C8 23.5228 12.4772 28 18 28Z"
          fill="white"
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconRates;
