import React, { FC } from "react";
import { Box, Button, makeStyles, Theme, Typography } from "@material-ui/core";

export interface IPluginConfiguration {
  handlerOnClick: (plugin: string) => void;
  getPluginSvg: (plugin: string) => string;
  currentPlugin: string;
  plugin: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    position: "relative",
  },
  button: {
    marginTop: theme.spacing(1),
    height: "66px",
    backgroundColor: "#F7FAFC",
    boxShadow: "none",
    border: "2px solid #CBD5E0",
    borderRadius: "4px",
    flex: 1,
    display: "flex",
    "&.selected": {
      border: "2px solid #0DC298;",
    },
  },
  small: {
    width: "25%",
    height: "25%",
  },
  img: {
    height: "20px",
  },
  version: {
    position: "absolute",
    width: "40px",
    height: "40px",
    right: "0px",
    top: "0px",
    background:
      "linear-gradient(to bottom right, transparent 0%, transparent 50%, #0DC298 50%, #0DC298 100%)",
    transform: "rotate(270deg)",
    zIndex: 999,
    borderRadius: "0px 0px 2px 0px",
    justifyContent: "center",
    alignItems: "center",
  },
  versionText: {
    color: "#ffffff",
    fontSize: "9px",
    lineHeight: "9px",
    transform: "rotate(135deg) translate(-50%, 70%)",
    top: "105%",
    position: "absolute",
  },
}));

export const PluginConfiguration: FC<IPluginConfiguration> = ({
  plugin,
  handlerOnClick,
  getPluginSvg,
  currentPlugin,
}: IPluginConfiguration) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Button
        className={`${classes.button} ${
          currentPlugin === plugin ? "selected" : null
        }`}
        onClick={() => handlerOnClick(plugin)}
      >
        {plugin === "magentov2" && (
          <Box className={classes.version}>
            <Typography variant={"body2"} className={classes.versionText}>
              Desde V2.4
            </Typography>
          </Box>
        )}
        <img src={getPluginSvg(plugin)} className={classes.img} />
      </Button>
    </Box>
  );
};
