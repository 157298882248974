import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const UploadFileInputStyles = createNamedStyles({
  label: { mt: 5.5 },
  subLabel: { fontSize: "15px !important", mb: 1.5, mt: 1 },
  itemContainer: {
    alignItems: "center",
    display: "flex",
    marginTop: 2,
  },
  errorCardContainer: {
    "& .MuiTypography-root": {
      fontWeight: "normal",
    },
    maxWidth: "60%",
    mb: 2,
  },
  buttonBase: {
    bgcolor: "white",
    borderRadius: 100,
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    height: 44,
    ml: 1,
    width: 44,
  },
  uploadButton: {
    py: 1,
    fontSize: "14px",
  },
});

export const InputStyles = { color: "transparent" };
