import { useEffect, useState } from "react";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { IUseTableComponent } from "./useTableComponent.interfaces";
import {
  TABLE_COLUMNS,
  TEXT_TRANSFORM_COLUMNS,
  TEXT_TWO_LINES,
  WALLET_TRANSACTION_COLUMNS,
} from "../../../shared/constants/table_container";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { ITableComponent } from "../TableComponent.interfaces";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/constants";
import { defaultTo, get, pick } from "lodash";
import {
  buildDate,
  buildOneCell,
  convertTableValues,
  setOriginText,
  setTransactionTypeText,
} from "../../../shared/utils/walletUtils";
import { WalletTransaction } from "../../../../types/wallet_transaction";
import { WalletTransactionPath } from "../../../shared/enums/wallet_transaction_path";
import { RootState } from "../../../store/store";
import { Dispatch } from "redux";
import {
  setCurrentTransaction,
  setShowDetailModal,
} from "../../../store/actions/walletTransaction.action";

export const UseTableComponent = (
  props: ITableComponent
): IUseTableComponent => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [cells] = useState<HeaderCellProps[]>(TABLE_COLUMNS);
  const { isLoadingTrx } = useAppSelector((state: RootState) => ({
    ...state.walletTransaction,
  }));

  const buildCells = (item: WalletTransaction): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const wallet_trx: object = pick(item, WALLET_TRANSACTION_COLUMNS);

    Object.keys(wallet_trx).forEach((key: string) => {
      const itemText: string = get(item, key, "");

      switch (key) {
        case WalletTransactionPath.CREATED:
          cells.push(
            buildOneCell(
              TEXT_TWO_LINES,
              buildDate(itemText, true),
              buildDate(itemText, false)
            )
          );
          break;
        case WalletTransactionPath.TRX_TYPE:
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TITLE,
              setTransactionTypeText(itemText),
              setOriginText(item)
            )
          );
          break;
        case WalletTransactionPath.TRX_STATUS:
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TAG,
              defaultTo(TEXT_TRANSFORM_COLUMNS[itemText], "").toUpperCase(),
              undefined,
              { align: "center", spacing: 0 },
              itemText
            )
          );
          break;
        default:
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TEXT,
              convertTableValues(item, get(props, "country", ""))[key]
            )
          );
          break;
      }
    });

    return cells;
  };

  const transformDataToRowTableInfo = (): void => {
    let tableRowsProps: ITableRowProps[] = [];

    get(props.transactions, WalletTransactionPath.DATA, []).forEach(
      (trx: WalletTransaction) => {
        tableRowsProps = [
          ...tableRowsProps,
          {
            cells: buildCells(trx),
            id: trx.transaction_id,
            rowProps: {
              color: "default",
              onClick: () => {
                dispatch(setShowDetailModal(true));
                dispatch(setCurrentTransaction(trx));
              },
            },
          },
        ];
      }
    );
    setRows(tableRowsProps);
  };

  useEffect(() => {
    transformDataToRowTableInfo();
  }, [props.transactions]);

  return {
    cells,
    isLoadingTrx,
    rows,
  };
};
