import { FC } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { CellTextActionStyles } from "./CellTextAction.styles";
import { ICellTextAction } from "./CellTextAction.interfaces";
import { BillingInfoLabelsEnum } from "../../../shared/enums/BillingInfoLabelsEnum";

export const CellTextAction: FC<ICellTextAction> = ({
  value,
  text,
  align,
}: ICellTextAction) => {
  const isDisabled: boolean = value > 0;

  return (
    <Box alignItems={align}>
      <Tooltip
        title={isDisabled ? "" : BillingInfoLabelsEnum.WITHOUT_CYCLE}
        placement="top-start"
        data-testid={"tool-tip"}
        arrow
      >
        <Typography
          sx={
            isDisabled
              ? CellTextActionStyles.textActionActive
              : CellTextActionStyles.textActionInactive
          }
        >
          {text}
        </Typography>
      </Tooltip>
    </Box>
  );
};
