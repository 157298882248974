import {
  PendingFilters,
  PendingFiltersProps,
} from "../PendingFilters/PendingFilters";
import { StatusFlowEnum } from "../../../shared/infrastructure/StatusFlowEnum";
import React from "react";
import { DateRangePicker } from "../../DateRange/DateRangePicker";
import { ColumnsConfig } from "../../ColumnsConfig/ColumnsConfig";
import { Grid } from "@material-ui/core";
import { PopoverFilter } from "../../PopoverFilters/PopoverFilter";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ButtonDownloadFile } from "../../ButtonDownloadFile/ButtonDownloadFile";
import { CloudSecurityEnum } from "../../../shared/infrastructure/CloudSecurityEnum";

export interface TransactionsSummaryFiltersProps {
  origin: string;
  isHistoric?: boolean;
  pendingFiltersProps?: PendingFiltersProps;
  handleDownloadFile?: () => void;
  loadingFileDownload?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "stretch",
  },
  column: { marginLeft: theme.spacing(2) },
  filter: { marginLeft: theme.spacing(1) },
  date: {
    marginLeft: theme.spacing(2),
  },
  downloadButton: {
    paddingRight: "8px",
  },
  containerTransaction: {
    background: "#f0f4f8",
    paddingInline: "13px",
  },
}));

export const TransactionsSummaryFilters: React.FC<TransactionsSummaryFiltersProps> =
  (props: TransactionsSummaryFiltersProps) => {
    const classes = useStyles();
    const { isHistoric } = props;

    const handleComponentId = () => {
      return isHistoric
        ? CloudSecurityEnum.HISTORIC_DOWNLOAD_CSV
        : CloudSecurityEnum.PENDING_DOWNLOAD_CSV;
    };

    return (
      <>
        {props.origin === StatusFlowEnum.SUMMARY ? (
          <PendingFilters
            handleDateRangeChange={
              props.pendingFiltersProps!.handleDateRangeChange
            }
            dateRange={props.pendingFiltersProps!.dateRange}
            minDate={props.pendingFiltersProps!.minDate}
            downloadFile={props.handleDownloadFile!}
            loading={props.loadingFileDownload!}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.container}>
                    <Grid item className={classes.filter}>
                      <PopoverFilter
                        handleSetFilters={
                          props.pendingFiltersProps!.handleSetFilters!
                        }
                        counterFilter={
                          props.pendingFiltersProps!.counterFilter!
                        }
                        filters={props.pendingFiltersProps!.filters!}
                      />
                    </Grid>
                    <Grid item className={classes.date}>
                      <DateRangePicker
                        handleDate={
                          props.pendingFiltersProps!.handleDateRangeChange
                        }
                        disableFuture={true}
                        enableTimePicker={false}
                        value={props.pendingFiltersProps!.dateRange}
                        minDate={props.pendingFiltersProps!.minDate}
                      />
                    </Grid>
                    <Grid item className={classes.column}>
                      <ColumnsConfig
                        origin={props.origin}
                        isHistoric={props.isHistoric}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <ButtonDownloadFile
                  downloadFile={props.handleDownloadFile!}
                  loading={props.loadingFileDownload!}
                  componentId={handleComponentId()}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };
