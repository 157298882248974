import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const dispersionFormStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: 0.5,
  },
  gridSelect: {
    paddingBottom: 4.4,
    paddingTop: 4.1,
  },
  gridRadios: {
    paddingBottom: 3,
    paddintTop: 2,
  },
  label: {
    fontWeight: 500,
    paddingBottom: 1,
    paddingTop: 1,
  },
  paddingInputFraud: {
    paddingRight: {
      xs: 0,
      md: 2,
    },
  },
  gridNumericInputs: {
    paddingY: 4,
  },
  gridLabel: {
    paddingTop: 1,
  },
  disclaimer: {
    paddingTop: 1,
    fontWeight: 300,
  },
  button: {
    fontWeight: 500,
  },
  dropdown: {
    paddingTop: 2,
  },
  boxFlex: {
    display: "flex",
  },
  boxFlexEnd: {
    display: "flex",
    flexDirection: "row-reverse",
  },
});
