export enum RedirectRoutesEnum {
  CONSOLE_USER_USERS = "/console-user/users/",
}

export const ROUTE = {
  BRANCH_LIST: (rootId: string, customerId: string) =>
    `/clients/${rootId}/customers/${customerId}`,
  CLIENTS: (rootId: string) => `/clients/${rootId}`,
  CREATE_MASSIVE_BRANCH: "/create-node/branch/batch",
  MERCHANT_RESUME: (publicMerchantId: string) =>
    `/merchant-resume?publicMerchantId=${publicMerchantId}&mode=edition`,
  USERS: (name: string, publicMerchantId: string) =>
    `${RedirectRoutesEnum.CONSOLE_USER_USERS}user?publicMerchantId=${publicMerchantId}&merchantName=${name}&console=3`,
};
