import { Reducer, useReducer } from "react";
import { IAction, IInit } from "./useExpandItems.interfaces";

const init = ({ numberItems, bool }: IInit) => {
  const internalNumber = numberItems < 0 ? 0 : Math.floor(numberItems);

  return Array.from(Array(internalNumber)).map(() => bool);
};

/* istanbul ignore next */
const reducer: Reducer<boolean[], IAction> = (state, action) => {
  switch (action.type) {
    case "change":
      return state.map((b, i) => (i === action.index ? !b : b));
    case "expand_all":
      return state.map(() => true);
    case "collapse_all":
      return state.map(() => false);
    default:
      return state;
  }
};

const useExpandItems = (numberItems: number) => {
  const [state, dispatch] = useReducer(
    reducer,
    { bool: false, numberItems },
    init
  );

  const isAllExpanded = state.every((b) => b === true);

  const expandItem = (index: number) => dispatch({ index, type: "change" });

  const expandAll = () => dispatch({ type: "expand_all" });

  const collapseAll = () => dispatch({ type: "collapse_all" });

  return {
    collapseAll,
    expandAll,
    expandItem,
    isAllExpanded,
    isExpanded: state,
  };
};

export default useExpandItems;
