import React, { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  IAlarmComments,
  ICommentsTab,
} from "@components/organism/DetailCardBodyAlarms/state/IUseDetailCardBodyAlarms.interfaces";
import CommentSection from "@components/molecule/CommentSection/CommentSection";
import { commentsTabStyles as styles } from "@components/molecule/CommentsTab/CommentsTab.styles";
import { TextArea } from "@kushki/connect-ui";
import { CommentSectionLabel } from "@shared/enum/AlarmDetailCardEnum";
import { isEmpty } from "lodash";
import EmptyComment from "@components/atom/EmptyComment/EmptyComment";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
const CommentsTab: FC<ICommentsTab> = ({
  comments,
  textAreaValue,
  handleAddEditComment,
  handleInputChange,
  componentIdAddCommentBtn,
}: ICommentsTab) => {
  return (
    <Box sx={styles.container}>
      {isEmpty(comments) ? (
        <EmptyComment message={CommentSectionLabel.EMPTY} />
      ) : (
        <Box sx={styles.commentsWrapper} id={"commentsWrapper"}>
          {comments.map((comment: IAlarmComments, index: number) => (
            <CommentSection key={index} {...comment} />
          ))}
        </Box>
      )}
      <Box sx={styles.textAreaWrapper}>
        <Typography variant={"body2"}>
          {CommentSectionLabel.ADD_COMMENTS}
        </Typography>
        <Box sx={styles.textArea}>
          <TextArea
            ref={React.createRef()}
            height={150}
            maxCounter={500}
            name=""
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder={CommentSectionLabel.PLACEHOLDER}
            value={textAreaValue}
          />
        </Box>
      </Box>
      <ComponentSecurityWrapper componentId={componentIdAddCommentBtn}>
        <Box sx={styles.addCommentBtn}>
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={handleAddEditComment}
            disabled={isEmpty(textAreaValue)}
          >
            {CommentSectionLabel.ADD_COMMENT}
          </Button>
        </Box>
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default CommentsTab;
