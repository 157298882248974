import { get, isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  StatusStepEnum,
  statusStepsToTabInfo,
} from "../../../shared/enums/StatusStepEnum";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { ConfigurationCodeEnum } from "../../../shared/enums/ProcessingStepEnum";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import { filterNodeInfoByConfiguration } from "../../../shared/utils/filter-node-info-by-conf";
import { GetProcessorResponse } from "../../../../types/get_processor_response";
import { isAvailableCardProcessor } from "../../../utils/utilsFile";
import { useEffect } from "react";
import { changeLoadingValue } from "../../../store/reducers/layout/layout.slice";
import {
  BranchEditList,
  Configs,
  Data,
} from "../../../../types/branch_edit_list";
import { getDecentralizedBranchStatus } from "../../../utils/branch/branch-util";
import { BranchEditListPathEnum } from "../../../shared/infrastructure/enums/SessionStoragePathEnum";

export const useProcessingTab = (): string[] => {
  const {
    nodeInfo,
    processor,
    isMassiveBranches,
    branchEditList,
    loadingProcessor,
    loadingDeferred,
    loadingNodeInfo,
    isNotRendererTabs,
  } = useAppSelector((store) => store.layout);
  const dispatch = useAppDispatch();
  const loadingValidator: (boolean | undefined)[] = [
    loadingProcessor,
    loadingNodeInfo,
    Boolean(loadingDeferred),
  ];

  useEffect(() => {
    dispatch(
      changeLoadingValue({ property: "isNotRendererTabs", value: true })
    );
  }, []);

  useEffect(() => {
    if (!loadingValidator.includes(true) && isNotRendererTabs) {
      dispatch(
        changeLoadingValue({ property: "isNotRendererTabs", value: false })
      );
    }
  }, [loadingValidator]);

  return !isMassiveBranches
    ? getSectionsStatus(nodeInfo, processor)
    : getSectionsStatusByDecentralizedBranch(branchEditList, nodeInfo);
};

const getProcessorTabStatus = (
  nodeInfo: GetNodeInfoResponse
): StatusStepEnum => {
  return get(
    filterNodeInfoByConfiguration(nodeInfo, ConfigurationCodeEnum.PROCESSOR),
    NodeInfoPath.Status,
    StatusStepEnum.IN_PROCESS
  ) as StatusStepEnum;
};

export const getSectionsStatus = (
  nodeInfo: GetNodeInfoResponse,
  processor: GetProcessorResponse[]
): string[] => {
  const defaultStatus: StatusStepEnum = isAvailableCardProcessor(processor)
    ? StatusStepEnum.PENDING
    : StatusStepEnum.NOT_AVAILABLE;

  let statusBusinessRules: StatusStepEnum = get(
    filterNodeInfoByConfiguration(
      nodeInfo,
      ConfigurationCodeEnum.BUSINESS_RULES
    ),
    NodeInfoPath.Status,
    defaultStatus
  ) as StatusStepEnum;

  const statusProcessor: StatusStepEnum = getProcessorTabStatus(nodeInfo);

  const statusDeferred: StatusStepEnum = get(
    filterNodeInfoByConfiguration(nodeInfo, ConfigurationCodeEnum.DEFERRED),
    NodeInfoPath.Status,
    defaultStatus
  ) as StatusStepEnum;

  if (isMassiveBranches()) {
    statusBusinessRules = isCentralizedBranches()
      ? StatusStepEnum.NOT_AVAILABLE
      : StatusStepEnum.PENDING;
  }

  return [
    statusStepsToTabInfo.get(statusProcessor)!!,
    statusStepsToTabInfo.get(statusBusinessRules)!!,
    statusStepsToTabInfo.get(statusDeferred)!!,
  ];
};

function getMassiveBranches(): Data[] {
  const dataEdit = sessionStorage.getItem("branchEditList");
  return dataEdit ? get(JSON.parse(dataEdit), "data", []) : [];
}

function isMassiveBranches(): boolean {
  return getMassiveBranches().length > 0;
}

function isCentralizedBranches(): boolean {
  const branches: Data[] = getMassiveBranches();
  return branches.some((branch: Data) => {
    const configs: Configs[] = get(branch, "configs", []);
    return configs.some((config: Configs) => {
      return !isEmpty(config.centralizedNodesId);
    });
  });
}

const getSectionsStatusByDecentralizedBranch = (
  branchEditList: BranchEditList,
  nodeInfo: GetNodeInfoResponse
): string[] => {
  const branchData: Data[] = get(
    branchEditList,
    BranchEditListPathEnum.DATA,
    []
  );
  const statusProcessor: StatusStepEnum = getProcessorTabStatus(nodeInfo);
  const statusBusinessRules: StatusStepEnum = getStatusByConfigurationCode(
    branchData,
    ConfigurationCodeEnum.BUSINESS_RULES
  );
  const statusDeferred: StatusStepEnum = getStatusByConfigurationCode(
    branchData,
    ConfigurationCodeEnum.DEFERRED
  );

  return [
    statusStepsToTabInfo.get(statusProcessor)!!,
    statusStepsToTabInfo.get(statusBusinessRules)!!,
    statusStepsToTabInfo.get(statusDeferred)!!,
  ];
};

const getStatusByConfigurationCode = (
  branchData: Data[],
  configCode: ConfigurationCodeEnum
): StatusStepEnum => {
  return branchData
    .map((branch: Data) => getDecentralizedBranchStatus(configCode, branch))
    .every((v: boolean) => isEqual(v, true))
    ? StatusStepEnum.COMPLETE
    : StatusStepEnum.PENDING;
};
