import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { LegalContainerSkeletonStyles } from "../LegalContainerSkeleton/LegalContainerSkeleton.styles";

export const SubHeaderLegalSkeleton: React.FC = () => {
  return (
    <>
      <Typography
        variant="h3"
        mb={"10px"}
        sx={LegalContainerSkeletonStyles.subTitle}
        data-testid="legal-subheader-skeleton"
      >
        <Skeleton />
      </Typography>
      <Box sx={LegalContainerSkeletonStyles.container}>
        <Grid container justifyContent={"space-between"}>
          <Typography sx={LegalContainerSkeletonStyles.subTitle2}>
            <Skeleton />
          </Typography>
          <Typography sx={LegalContainerSkeletonStyles.subTitle2}>
            <Skeleton />
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Typography sx={LegalContainerSkeletonStyles.subTitle3}>
          <Skeleton />
        </Typography>
      </Box>
    </>
  );
};
