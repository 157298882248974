import React from "react";
import { Box, Grid, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "react-feather";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { SmartlinkDatePicker } from "../../DatePicker/SmartlinkDatePicker";
import { PopoverFilter } from "../PopoverFilters/PopoverFilter";
import { IOptionFilter } from "../BoxFilter/BoxFilter";

export interface IHeaderProps {
  range: DateRange;
  handleSetDate: (date: DateRange | MouseEvent) => void;
  handleInputChange: (search: string) => void;
  handleSearchBar: (key: string) => void;
  handleSetFilters: (
    filters: { [key: string]: boolean },
    filtersList: IOptionFilter[]
  ) => void;
  filterChips: { [key: string]: boolean };
  counterFilter: number;
  filters: IOptionFilter[];
  viewSettingColumn?: boolean;
  selectedColumns?: string[];
  setSelectedColumns?: (data: string[]) => void;
  handleAppliedColumnsFilters?: (restore?: boolean) => void;
}

const useStyles = makeStyles({
  titleContent: {
    lineHeight: "40% !important",
  },
  topHeader: {
    marginTop: "50px",
  },
  kshBtnFilter: {
    padding: "7px 30px",
  },
  textField: {
    padding: "0px 25px",
    height: "36px",
  },
  iconButton: {
    "& .MuiIconButton-root": {
      height: "39px",
      width: "39px",
      marginTop: "0px",
      borderStyle: "solid",
      borderColor: "#C4C4C4",
      borderWidth: 1,
      borderRadius: 4,
      backgroundColor: "white",
    },
  },
  bgHeader: {
    backgroundColor: "#F7FAFC",
    padding: "15px 5px",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
  },
});

export const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const classes = useStyles();
  const { range, handleSetDate } = props;
  return (
    <React.Fragment>
      <Box className={classes.bgHeader}>
        <Grid container direction={"row"}>
          <Grid item xs={8}>
            <Grid container direction={"row"}>
              <Grid item xs={2}>
                <PopoverFilter
                  handleSetFilters={props.handleSetFilters}
                  filterChips={props.filterChips}
                  counterFilter={props.counterFilter}
                  filters={props.filters}
                />
              </Grid>
              <Grid item xs={5}>
                <SmartlinkDatePicker
                  disableFuture={false}
                  handleDate={handleSetDate}
                  value={range}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="outlined-search"
                  key={"outlinedSearch"}
                  autoFocus
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search size={16} />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                  variant={"outlined"}
                  type="search"
                  size={"small"}
                  margin={"none"}
                  placeholder={"Buscar comercio o ID"}
                  fullWidth
                  onKeyDown={(event: any) => {
                    if (event.key !== "Enter") return;
                    props.handleSearchBar(event.key);
                  }}
                  onChange={(event: any) => {
                    props.handleInputChange(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
