import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useFormContext, useWatch } from "react-hook-form";
import {
  IHeaderToRender,
  IUseAddWebhookFormState,
  IUseAddWebhookFormStateProps,
  IWebhookEvents,
} from "./useAddWebhookFormState.interfaces";
import { IWebhookForm } from "../../../store/interfaces/webhook.interfaces";
import {
  addWebhook,
  closeWebhookModal,
  openSubmitModal,
  resetTemp,
  resetWebhook,
  updateAddWebhookForm,
} from "../../../store/actions/webhook/webhook.actions";
import { initialState } from "../../../store/reducers/webhook/webhook.slice";
import { defaultTo, get } from "lodash";
import { ICustomHeaders } from "../../../shared/interfaces/customHeaders.interfaces";
import {
  createWebhook,
  createWebhookConnection,
  editWebhook,
} from "../../../store/thunks/webhook/webhook.thunks";
import { WebhookServiceEnum } from "../../../shared/constants/enums/WebhookServiceEnum";
import { VariantEnum } from "../../../shared/constants/enums/VariantEnum";
import { INPUT_ERROR_MESSAGES } from "../../../shared/constants/labels/input_form_errors";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { namesFormEnum } from "../../../shared/constants/enums/NamesFormEnum";
import {
  WebhookCreateRequest,
  WebhookEvent,
} from "../../../../types/webhook_create_request";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IHierarchyNodeRequest } from "../../../shared/interfaces/IHierarchyNodeRequest";
import { getTime } from "date-fns";
import { WebhookEventsEnum } from "../../WebhookEventsCheckList/enums";
import {
  CARD_CHARGEBACK,
  PAYMENT_METHODS_EVENTS,
} from "../../WebhookEventsCheckList/constants";
import { ServiceOptionEnum } from "../../PaymentMethodCheckList/enums";

export const useAddWebhookFormState = ({
  control,
  reset,
  resetField,
  setError,
}: IUseAddWebhookFormStateProps): IUseAddWebhookFormState => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);
  const webhookId = searchParams.get(QueryParamsEnum.WEBHOOK_ID);
  const dispatch = useAppDispatch();
  const { trigger, getValues } = useFormContext();
  const { addWebhookForm, nodeInfo, webhookActions } = useAppSelector(
    (state) => ({
      ...state.webhook,
    })
  );
  let fieldsWatch = useWatch({
    control,
  });
  let updatedHeaders: ICustomHeaders[] = [];
  let updatedUrls: string[] = [];

  updatedHeaders = [...addWebhookForm.headers];
  updatedUrls = [...addWebhookForm.urls];
  const headersToRender: IHeaderToRender[] = updatedHeaders.map((item) => {
    return {
      headerItem: item,
      isEditable: false,
      variant: VariantEnum.STANDARD,
    };
  });
  const [headersLength, setHeadersLength] = useState<number>(
    updatedHeaders.length
  );
  const [headersList, setHeaderList] =
    useState<IHeaderToRender[]>(headersToRender);

  const isEdition =
    searchParams.get(QueryParamsEnum.MODE) === QueryParamsEnum.EDITION;
  const handleOnCancel = () => {
    reset({ ...initialState.addWebhookForm });
    dispatch(resetWebhook());
    navigate(-1);
  };
  const handleOnAccept = () => {
    trigger().then((isValid: boolean) => {
      let finalError: boolean = !isValid;
      const hasUrls: boolean =
        get(webhookActions, "data.general", []).length > 0;

      const { events = [], options = [] } = getValues();

      if (!hasUrls) {
        finalError = true;
        setError("url", {
          message:
            "Es necesario agregar al menos un URL para configurar el webhook",
        });
      }

      if (events.length === 0) {
        finalError = true;
        setError("events", {
          message:
            "Es necesario seleccionar al menos un evento para configurar el webhook",
        });
      }

      if (options.length === 0) {
        finalError = true;
        setError("options", {
          message:
            "Es necesario seleccionar al menos un método de pago para configurar el webhook",
        });
      }

      if (!finalError) {
        dispatch(openSubmitModal(true));
      }
    });
  };

  const handleWebhook = () => {
    const labelItem = get(fieldsWatch, namesFormEnum.URL, "");

    if (labelItem === "") {
      setError(namesFormEnum.URL, {
        message: INPUT_ERROR_MESSAGES.required,
        type: INPUT_ERROR_MESSAGES.type,
      });
    } else {
      updatedUrls.push(labelItem);
      dispatch(
        createWebhookConnection({
          webhookData: [
            {
              service: WebhookServiceEnum.GENERAL,
              urls: [get(fieldsWatch, "url", "")],
            },
          ],
        })
      );
      resetField(namesFormEnum.URL, { defaultValue: "" });
    }
  };
  const handleWebhookClose = () => {
    dispatch(closeWebhookModal(false));
    dispatch(resetTemp());
  };
  const handleWebhookSave = () => {
    dispatch(addWebhook());
    dispatch(closeWebhookModal(false));
  };

  const handleEdit = (index: number) => {
    headersToRender[index] = {
      ...headersToRender[index],
      isEditable: true,
      variant: VariantEnum.OUTLINED,
    };
    setHeaderList(headersToRender);
  };

  const handleOnChangeLabelHeaders = (item: string, index: number) => {
    headersToRender[index] = {
      ...headersToRender[index],
      headerItem: {
        label: item,
        value: headersList[index].headerItem.value,
      },
      isEditable: true,
      variant: VariantEnum.OUTLINED,
    };

    setHeaderList(headersToRender);
  };

  const handleOnChangeValueHeaders = (item: string, index: number) => {
    headersToRender[index] = {
      ...headersToRender[index],
      headerItem: {
        label: headersList[index].headerItem.label,
        value: item,
      },
      isEditable: true,
      variant: VariantEnum.OUTLINED,
    };
    setHeaderList(headersToRender);
  };

  const handleSaveChanges = (index: number) => {
    updatedHeaders[index] = {
      label: headersList[index].headerItem.label,
      value: headersList[index].headerItem.value,
    };

    headersToRender[index] = {
      ...headersToRender[index],
      headerItem: {
        label: headersList[index].headerItem.label,
        value: headersList[index].headerItem.value,
      },
      isEditable: false,
      variant: VariantEnum.STANDARD,
    };

    const objectUpdated = {
      ...addWebhookForm,
      headers: updatedHeaders,
    };

    setHeaderList(headersToRender);
    dispatch(updateAddWebhookForm(objectUpdated));
  };

  const handleDelete = (index: number) => {
    headersToRender.splice(index, 1);
    updatedHeaders.splice(index, 1);

    const objectUpdated = {
      ...addWebhookForm,
      headers: updatedHeaders,
    };

    dispatch(updateAddWebhookForm(objectUpdated));
    setHeadersLength(headersToRender.length);
    setHeaderList(headersToRender);
  };

  const handleCancel = (index: number) => {
    headersToRender[index] = {
      ...headersToRender[index],
      isEditable: false,
      variant: VariantEnum.STANDARD,
    };
    setHeaderList(headersToRender);
  };

  const handleAddHeaderConfig = () => {
    const labelItem = get(fieldsWatch, namesFormEnum.LABEL, "");
    const valueItem = get(fieldsWatch, namesFormEnum.VALUE, "");

    if (labelItem === "" || valueItem === "") {
      labelItem === "" &&
        setError(namesFormEnum.LABEL, {
          message: INPUT_ERROR_MESSAGES.required,
          type: INPUT_ERROR_MESSAGES.type,
        });
      valueItem === "" &&
        setError(namesFormEnum.VALUE, {
          message: INPUT_ERROR_MESSAGES.required,
          type: INPUT_ERROR_MESSAGES.type,
        });
    } else {
      const header: ICustomHeaders = {
        label: labelItem,
        value: valueItem,
      };

      updatedHeaders.push(header);
      const objectUpdated = {
        ...addWebhookForm,
        headers: updatedHeaders,
      };

      headersToRender.push({
        headerItem: header,
        isEditable: false,
        variant: VariantEnum.STANDARD,
      });
      setHeaderList(headersToRender);
      setHeadersLength(headersToRender.length);

      dispatch(updateAddWebhookForm(objectUpdated));
      resetField(namesFormEnum.LABEL, { defaultValue: "" });
      resetField(namesFormEnum.VALUE, { defaultValue: "" });
    }
  };

  useEffect(() => {
    if (isEdition && addWebhookForm.headers.length > 0) {
      setHeaderList(headersToRender);
      setHeadersLength(headersToRender.length);
    }
  }, [addWebhookForm]);

  useEffect(() => {
    let { label, value, url, ...formState } = fieldsWatch;

    fieldsWatch = {
      ...fieldsWatch,
      headers: updatedHeaders,
      label,
      url,
      urls: updatedUrls,
      value,
    };

    formState = {
      ...formState,
      headers: updatedHeaders,
      urls: updatedUrls,
    };

    dispatch(updateAddWebhookForm(formState as IWebhookForm));
  }, [fieldsWatch]);

  const handleCloseSubmit = (): void => {
    dispatch(openSubmitModal(false));
  };

  const hasAllChargebackEvents = (countCurrentEvents: number): boolean => {
    const allChargebackEvents =
      PAYMENT_METHODS_EVENTS[ServiceOptionEnum.CHARGEBACK];

    return Object.values(allChargebackEvents).length === countCurrentEvents;
  };

  const getCountCurrentChargebackEvents = (
    currentEvents: IWebhookEvents[]
  ): number => {
    return currentEvents.filter(
      (event: IWebhookEvents) =>
        event.service === ServiceOptionEnum.CHARGEBACK &&
        CARD_CHARGEBACK[event.status] === event.status
    ).length;
  };

  const hasChargebackEvent = (countCurrentEvents: number): boolean => {
    return countCurrentEvents > 0;
  };

  const buildCurrentEvents = (currentEvents: IWebhookEvents[]) =>
    currentEvents.map(
      (event: { service: ServiceOptionEnum; status: WebhookEventsEnum }) => {
        if (event.service === ServiceOptionEnum.CHARGEBACK_VOID)
          return {
            service: ServiceOptionEnum.CHARGEBACK,
            status: event.status,
          };

        return event;
      }
    );

  const transformEventChargeBack = (): WebhookEvent[] => {
    let currentEvents = getValues("events");

    const countCurrentChargebackEvents: number =
      getCountCurrentChargebackEvents(currentEvents);

    const hasChargebackReturn: boolean =
      currentEvents.findIndex(
        (event: { service: ServiceOptionEnum }) =>
          event.service === ServiceOptionEnum.CHARGEBACK_VOID
      ) > -1;

    if (hasAllChargebackEvents(countCurrentChargebackEvents)) {
      currentEvents = currentEvents.filter(
        (event: { service: ServiceOptionEnum; status: WebhookEventsEnum }) =>
          event.service !== ServiceOptionEnum.CHARGEBACK
      );

      currentEvents = [
        ...currentEvents,
        {
          service: ServiceOptionEnum.CHARGEBACK,
          status: WebhookEventsEnum.chargeback,
        },
      ];
    } else if (hasChargebackEvent(countCurrentChargebackEvents)) {
      currentEvents = currentEvents.filter(
        (event: { service: ServiceOptionEnum; status: WebhookEventsEnum }) =>
          WebhookEventsEnum.chargeback !== event.status
      );
    }
    if (hasChargebackReturn) {
      currentEvents = buildCurrentEvents(currentEvents);
    }

    return currentEvents;
  };

  const handleCreateWebhook = () => {
    let currEvents = transformEventChargeBack();

    const payload: WebhookCreateRequest = {
      alias: getValues("alias"),
      events: [...currEvents],
      headers: headersList.map(({ headerItem }) => ({ ...headerItem })),
      merchantId: `${merchantId}`,
      status: webhookActions.data.general!.map(({ url, status }) => ({
        status: `${status}`,
        url: `${url}`,
      })),
      urls: webhookActions.data.general!.map(({ url }) => `${url}`),
    };

    const hierarchyPayload: IHierarchyNodeRequest = {
      configs: [
        {
          configuration: "cn014",
          status: "complete",
          updatedAt: getTime(Date.now()),
          updatedBy: defaultTo(localStorage.getItem("username"), ""),
          value: merchantId!,
        },
      ],
      nodeId: get(nodeInfo, "nodeId", ""),
    };

    isEdition
      ? dispatch(editWebhook({ hierarchyPayload, payload, webhookId }))
      : dispatch(createWebhook({ hierarchyPayload, payload }));
    handleCloseSubmit();
  };

  return {
    handleAddHeaderConfig,
    handleCancel,
    handleDelete,
    handleEdit,
    handleOnAccept,
    handleOnCancel,
    handleOnChangeLabelHeaders,
    handleOnChangeValueHeaders,
    handleSaveChanges,
    handleWebhook,
    handleWebhookClose,
    handleWebhookSave,
    headersLength,
    headersList,
    isEdition,
    submitModal: {
      handleClose: handleCloseSubmit,
      handleCreateWebhook,
      open: get(webhookActions.submitModal, "open", false),
    },
    webhookActions,
  };
};
