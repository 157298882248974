import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useCredentialsGrid } from "./state/useCredentialsGrid";
import { SkeletonTableProcessors } from "../../Skeletons/SkeletonTableProcessors/SkeletonTableProcessors";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
  },
}));

export const CredentialsGrid = () => {
  const style = useStyles();
  const {
    paymentCredentials,
    isLoadingPaymentCredentials,
  } = useCredentialsGrid();

  return isLoadingPaymentCredentials ? (
    <SkeletonTableProcessors rows={3} cells={1} />
  ) : (
    <Grid container direction={"column"} spacing={2}>
      {paymentCredentials?.slice(0, 5).map((data, index) => (
        <Grid key={`${data.alias}-${index}`} item xs={12}>
          <Typography className={style.title}>{data.alias}</Typography>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
};
