import { environment } from "../../environments/environment";

export const API_ROUTES = {
  CHANGE_STATUS_BY_ID: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/status`,
  CREATE_MASSIVE_USERS: (publicMerchantId: string) =>
    `/console-user?publicMerchantId=${publicMerchantId}`,
  CREATE_NODE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/create`,
  DEBIT_WALLET: (merchantId: string) =>
    `/console-wallet?merchantId=${merchantId}&type=DEBIT`,
  GET_BALANCE: (merchantId: string) =>
    `${environment.kushkiUrl}/wallet/v1/balance/${merchantId}`,
  HIERARCHY_GET_FIRST_PARENT: `${environment.kushkiUrl}/hierarchy/v1/node/first-parent`,
  MERCHANT_RESUME: (publiMerchantId: string, edition: boolean) =>
    `/merchant-resume?publicMerchantId=${publiMerchantId}${
      edition ? "&mode=edition" : ""
    }`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  RECHARGE_WALLET: (merchantId: string) =>
    `/console-wallet?merchantId=${merchantId}&type=CREDIT`,
  SEARCH_MERCHANT_NODE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search`,
  SEARCH_MERCHANT_NODE_BY_NODE_ID: `${environment.kushkiUrl}/hierarchy/v1/node/`,
  SEARCH_MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search`,
};
