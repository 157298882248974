import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: "#293036 !important",
      fontSize: "13px !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important" as "normal",
      lineHeight: "170% !important",
      textTransform: "none" as "none",
    },
    tabContent: {
      padding: "32px 24px",
    },
    tabs: {
      "& .MuiPaper-root": {
        borderBottom: "1px solid #CBD5E0",
        boxShadow: "none",
        marginBottom: "10px",
      },
      margin: "-24px",
    },
  })
);
