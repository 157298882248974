import React, { useState, useEffect } from "react";
import { globalInformationComponentStyles } from "../GlobalInformationComponent/GlobalInformationComponent.styles";
import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { IconCircleWarn } from "@kushki/connect-ui";
import { MerchantProcessors } from "../../../types/merchant_processors";
import { isEmpty } from "lodash";
import {
  useFormContext,
  Controller,
  ControllerRenderProps,
} from "react-hook-form";
import { get, defaultTo } from "lodash";
import { useAppSelector } from "../../store/hooks/store-hook";
import { FormFieldEnum } from "../../shared/infrastructure/enums/FormFieldEnum";
import { getIsMassiveRules } from "../../shared/utils/localStorage";
import { IRuleConfigProps } from "./RuleConfigurationComponent.interfaces";

export const RuleConfigurationComponent: React.FC<IRuleConfigProps> = ({
  handleEnterSearchTextProcessors,
  handleSetSearchTextProcessors,
}) => {
  const { processors } = useAppSelector((store) => store.layout);
  const { control, watch } = useFormContext();
  const [selectedProcessor, setSelectedProcessor] =
    useState<MerchantProcessors>({
      merchantId: "",
      publicProcessorId: "",
      alias: "",
    });
  const watchProcessorName = watch(FormFieldEnum.RULE_PROCESSOR_NAME);

  useEffect(() => {
    if (!isEmpty(processors)) {
      const processor = verifyIsProcessorSelected(
        watchProcessorName,
        processors!
      );
      setSelectedProcessor(processor!);
    }
  }, [watchProcessorName, processors]);
  const classes = globalInformationComponentStyles();
  const isMassiveRules = getIsMassiveRules();
  const descriptionRuleConfiguration: string = ((flagMassive: boolean) => {
    return flagMassive
      ? LabelEnum.DESCRIPTION_BATCH_RULE_CONFIGURATION
      : LabelEnum.DESCRIPTION_RULE_CONFIGURATION;
  })(isMassiveRules);
  return (
    <Grid item xs={12} className={classes.paddingTop32}>
      <Card elevation={2} className={classes.rootCard}>
        <CardContent className={classes.cardContent}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" color="text.primary">
                {LabelEnum.RULE_CONFIGURATION}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingTop16}>
              <Typography variant="body2" color="text.dark">
                {descriptionRuleConfiguration}
              </Typography>
            </Grid>
            <Grid item xs={5} className={classes.paddingTop20}>
              <Controller
                name={FormFieldEnum.RULE_PROCESSOR_NAME}
                control={control}
                defaultValue=""
                render={(
                  parentProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <Autocomplete
                    disablePortal
                    disableClearable
                    value={parentProps.value}
                    onChange={(_event: any, value: any) =>
                      parentProps.onChange(get(value, "value"))
                    }
                    options={processors!.map(
                      (processor: MerchantProcessors) => {
                        return {
                          label: processor.alias,
                          value: processor.alias,
                        };
                      }
                    )}
                    noOptionsText={"No hay opciones"}
                    onKeyDown={(event) =>
                      handleEnterSearchTextProcessors(event.key)
                    }
                    onInputChange={(_event: any, newInputValue: string) =>
                      handleSetSearchTextProcessors(newInputValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LabelEnum.SELECT_PROCESSOR}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5} alignSelf={"end"}>
              <Typography variant="body2" color="text.grey">
                <IconCircleWarn fontSize="small" />
                {` ${LabelEnum.PROCESSOR_PUBLIC_ID} ${get(
                  selectedProcessor,
                  "publicProcessorId",
                  ""
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const verifyIsProcessorSelected = (
  value: string,
  processors: MerchantProcessors[]
): MerchantProcessors => {
  const processor = processors.find(
    (processor: MerchantProcessors) => processor.alias === value
  );

  return defaultTo(processor, {
    merchantId: "",
    publicProcessorId: "",
    alias: "",
  });
};
