import { format, parse, sub } from "date-fns";
import { DATE_FORMAT } from "./constants";

export const formatDateTime = (date: Date, time: string, locale: Locale) =>
  `${format(date, DATE_FORMAT, { locale })} ${time}`;

export const parseDate = (date: string, locale: Locale) =>
  parse(date, DATE_FORMAT, new Date(), { locale });

export const today = (locale: Locale) =>
  format(new Date(), DATE_FORMAT, { locale });

export const yesterday = (locale: Locale) =>
  format(sub(new Date(), { days: 1 }), DATE_FORMAT, { locale });
