import React, { FC } from "react";
import { WrapperContainer } from "../WrapperContainer/WrapperContainer";
import Dashboard from "../../components/Dashboard/Dashboard";
import { useBranchListContainer } from "./state/useBranchListContainer";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { Box } from "@mui/material";
import { branchListContainerStyles } from "./BranchListContainer.styles";

const BranchListContainer: FC = () => {
  const {
    showEmptyScreen,
    customerList,
    primaryButton,
    secondaryButton,
    rowsPerPage,
  } = useBranchListContainer();

  return (
    <WrapperContainer paginationLimit={rowsPerPage}>
      <Box sx={branchListContainerStyles.centerContainer}>
        <Dashboard
          showEmptyScreen={showEmptyScreen}
          nodeData={customerList}
        ></Dashboard>
      </Box>
      <Box sx={branchListContainerStyles.boxFooter}>
        <FooterOptions
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      </Box>
    </WrapperContainer>
  );
};

export default BranchListContainer;
