import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { get, defaultTo, has } from "lodash";
import { useSelector } from "react-redux";
import { IBillingDashboardState } from "../../store/reducer";
import { StepTitleEnum } from "../../shared/infrastructure/StepTitleEnum";

import { formatNumber } from "../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTableRow";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";
import { InitialStatusEnum } from "../../shared/infrastructure/StatusEnum";
import { validateDate } from "../ModalBodyInfo/utilsModalBody";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { themeOptions } from "../../themeui";
import { DETAIL_MODAL_TITLE_TRANSLATE } from "../../shared/infrastructure/constants/StepTitlesDetailTraslate";
import { IStepRetentionTimeLine } from "../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { formatDate } from "../../shared/utils/date-time-util";
import { BackofficeTable } from "@kushki/frontend-molecules/backoffice-table";
import { TransactionTable } from "../../../types/transaction_table";

export interface IModalDialogDetail {
  open?: boolean;
  handleCloseDialogDetail?: () => void;
  trxInfo?: TransactionTable;
}

export interface IHeaderDialog {
  stepTimeLineData: IStepRetentionTimeLine;
  handleCloseDialogDetail?: () => void;
}

export interface IDateDialog {
  stepTimeLineData: IStepRetentionTimeLine;
}
export interface IReasonDialog {
  stepTimeLineData: IStepRetentionTimeLine;
}

export interface IModifyAmountDialog {
  stepTimeLineData: IStepRetentionTimeLine;
}

export interface IFooterDialog {
  handleCloseDialogDetail?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.dark,
    fontSize: "16px",
  },
  buttonClose: {
    maxHeight: "50px !important",
  },
  dialog: {
    padding: "20px 25px",
    width: "605px !important",
    minHeight: "344px !important",
  },
  titleText: {
    margin: "10px",
  },
  alert: {
    marginTop: "15px",
  },
  titleDialog: {
    fontSize: "18px !important",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  rejectDialog: {
    fontSize: "18px !important",
    marginBottom: "8px !important",
  },
  gridSpacingReject: {
    paddingBottom: 24,
  },
  subTitleDialog: {
    fontSize: "18px !important",
    marginTop: "30px !important",
  },
  textDialog: {
    color: themeOptions.palette.neutral.grey7,
    marginBottom: "10px !important",
    width: "calc(100% - 125px)",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none !important",
    borderRadius: "0px !important",
    backgroundColor: "#F7FAFC !important",
    marginBottom: "20px",
  },
  modifiedDateText: {
    textTransform: "lowercase",
    marginTop: "-10px !important",
    marginRight: "30px !important",
  },
  gridAmount: { marginTop: "10px" },
  gridAmountRetention: { marginTop: "35px" },
  gridAmountTrx: { margin: "40px 0px 20px 0px" },
  boldText: { fontWeight: 500 },
}));

export const HeaderDialog: React.FC<IHeaderDialog> = ({
  stepTimeLineData,
  handleCloseDialogDetail,
}: IHeaderDialog) => {
  const styles = useStyles();
  const getTitle = (stepTimeLineData: IStepRetentionTimeLine): string => {
    const backofficeUser = get(
      stepTimeLineData.value,
      getDynamoElasticRef(
        DynamoReferenceEnum.BACKOFFICE_USER,
        get(stepTimeLineData, "isHistoric", false)
      )
    );
    const initialStatus = get(
      stepTimeLineData.value,
      getDynamoElasticRef(
        DynamoReferenceEnum.INITIAL_STATUS,
        get(stepTimeLineData, "isHistoric", false)
      )
    );

    switch (get(stepTimeLineData, "value.kind", "")) {
      case KindEnum.RETENTION_EC:
        if (initialStatus === InitialStatusEnum.REJECT)
          return get(stepTimeLineData, "title", "")
            .concat(" ")
            .concat(get(stepTimeLineData, "subtitle", ""))
            .concat(" por ")
            .concat(backofficeUser);
        return get(stepTimeLineData, "title", "")
          .concat(" ")
          .concat(get(stepTimeLineData, "subtitle", ""));
      case KindEnum.DISPERSION:
        if (
          [InitialStatusEnum.OMITTED, InitialStatusEnum.REJECT].includes(
            initialStatus
          )
        ) {
          return defaultTo(
            DETAIL_MODAL_TITLE_TRANSLATE[get(stepTimeLineData, "title", "")],
            get(stepTimeLineData, "title", "")
          )
            .concat(" ")
            .concat("(")
            .concat(backofficeUser)
            .concat(")");
        }
        return get(stepTimeLineData, "title", "");
      default:
        if (
          get(stepTimeLineData, "title", "") ===
          StepTitleEnum.rejectTransactionTime
        ) {
          return StepTitleEnum.rejectTransaction
            .concat(" ")
            .concat("(")
            .concat(backofficeUser)
            .concat(")");
        }
        if (
          get(stepTimeLineData, "title", "") ===
          StepTitleEnum.omittedTransactionTime
        ) {
          return StepTitleEnum.omittedTransaction
            .concat(" ")
            .concat("(")
            .concat(backofficeUser)
            .concat(")");
        }
        return get(stepTimeLineData, "title", "")
          .concat(" ")
          .concat("(")
          .concat(backofficeUser)
          .concat(")");
    }
  };

  return (
    <Grid container direction={"row"} id={"modalTitle"} alignItems={"center"}>
      <Grid item xs={11}>
        <Typography variant={"h5"} className={styles.titleText}>
          {getTitle(stepTimeLineData)}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={handleCloseDialogDetail}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const DateDialog: React.FC<IDateDialog> = ({
  stepTimeLineData,
}: IDateDialog) => {
  const styles = useStyles();
  const getDateTitle = (title: string): string => {
    switch (title) {
      case StepTitleEnum.modifiedAmount:
      case StepTitleEnum.partiallyCompleted:
        return "Fecha de modificación:";
      case StepTitleEnum.rejetedDNote:
      case StepTitleEnum.rejetedCNote:
      case StepTitleEnum.rejectTransactionTime:
      case StepTitleEnum.rejectedVoucher:
      case StepTitleEnum.rejectRetention:
        return "Fecha de rechazo:";
      case StepTitleEnum.omittedCNote:
      case StepTitleEnum.omittedDNote:
      case StepTitleEnum.omittedVoucher:
      case StepTitleEnum.omittedTransactionTime:
        return "Fecha de Omisión:";
      case StepTitleEnum.canceledRetention:
        return "Fecha de anulación:";
      case StepTitleEnum.omittedTransaction:
        return "Fecha omitida:";
      default:
        return "";
    }
  };

  return (
    <Grid container direction={"column"} id={"modaBody"}>
      <Typography variant="h6" className={styles.titleDialog}>
        <span className={styles.boldText}>
          {getDateTitle(get(stepTimeLineData, "title", ""))}
        </span>
      </Typography>

      <Typography variant="body2" className={styles.textDialog}>
        <span className={styles.modifiedDateText}>
          {formatDate(
            get(
              stepTimeLineData.value,
              getDynamoElasticRef(
                DynamoReferenceEnum.UPDATED_AT,
                get(stepTimeLineData, "isHistoric", false)
              )
            ),
            "yyyy-MM-dd"
          )}
        </span>
        <span>
          {
            validateDate(
              get(
                stepTimeLineData.value,
                getDynamoElasticRef(
                  DynamoReferenceEnum.UPDATED_AT,
                  get(stepTimeLineData, "isHistoric", false)
                ),
                ""
              )!
            )
              .split("T")[1]
              .split(".")[0]
          }
          {" hrs"}
        </span>
      </Typography>
    </Grid>
  );
};

export const ModifyAmountDialog: React.FC<IModifyAmountDialog> = ({
  stepTimeLineData,
}: IModifyAmountDialog) => {
  const styles = useStyles();
  switch (get(stepTimeLineData, "value.kind", "")) {
    case KindEnum.RETENTION_EC:
      return (
        <Grid container id={"modify-amount-retention"}>
          <Grid item xs={12} className={styles.gridAmount}>
            <Typography variant="body2" className={styles.titleDialog}>
              <span className={styles.boldText}>Montos:</span>
            </Typography>
            <Paper className={styles.paper}>
              <Grid container direction={"row"}>
                <Grid item xs={4} className={styles.gridAmountRetention}>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Monto ret. IVA
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Monto ret. Fuente
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Monto Total
                  </Typography>
                </Grid>
                <Grid container item xs={3} justify={"flex-start"}>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Montos iniciales
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.initialAmount.retIva",
                        0
                      ),
                      get(stepTimeLineData, "value.currency", "USD")
                    )}
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.initialAmount.retFue",
                        0
                      ),
                      get(stepTimeLineData, "value.currency", "USD")
                    )}
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.initialAmount.retTotalAmount",
                        0
                      ),
                      get(stepTimeLineData, "value.currency", "USD")
                    )}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item xs={4}>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Montos modificados
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.modifyAmount.retIva",
                        0
                      ),
                      get(stepTimeLineData, "value.currency", "USD")
                    )}
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.modifyAmount.retFue",
                        0
                      ),
                      get(stepTimeLineData, "value.currency", "USD")
                    )}
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.modifyAmount.retTotalAmount",
                        0
                      ),
                      get(stepTimeLineData, "value.currency", "USD")
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      );
    case KindEnum.DISPERSION:
      return (
        <Grid container id={"modify-amount"} className={styles.gridAmountTrx}>
          <Grid item xs={12} className={styles.gridAmount}>
            <Typography variant="body2" className={styles.titleDialog}>
              <span className={styles.boldText}>Montos:</span>
            </Typography>
            <Paper className={styles.paper}>
              <Grid container direction={"row"}>
                <Grid item xs={3}>
                  <Typography variant="body2" className={styles.subTitleDialog}>
                    Monto total
                  </Typography>
                </Grid>
                <Grid item xs={3} justify={"flex-start"}>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Monto inicial
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.initialAmount.retTotalAmount",
                        get(
                          stepTimeLineData,
                          "value.amount_change_info.initial_amounts.retTotalAmount",
                          0
                        )
                      ),
                      get(stepTimeLineData, "value.currency_code", "USD")
                    )}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid item xs={4} justify={"flex-start"}>
                  <Typography variant="body2" className={styles.titleDialog}>
                    Monto modificado
                  </Typography>
                  <Typography variant="body2" className={styles.titleDialog}>
                    {formatNumber(
                      get(
                        stepTimeLineData,
                        "value.modify.modifyAmount.retTotalAmount",
                        get(
                          stepTimeLineData,
                          "value.amount_change_info.modified_amounts.retTotalAmount",
                          0
                        )
                      ),
                      get(stepTimeLineData, "value.currency_code", "USD")
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      );
    default:
      return <></>;
  }
};

export const ReasonDialog: React.FC<IReasonDialog> = ({
  stepTimeLineData,
}: IReasonDialog) => {
  const styles = useStyles();
  const getReason = (stepTimeLineData: IStepRetentionTimeLine) => {
    switch (get(stepTimeLineData, "title", "")) {
      case StepTitleEnum.rejectRetention:
        return get(stepTimeLineData, "value.initial_status", "") ===
          InitialStatusEnum.REJECT
          ? get(stepTimeLineData, "value.reject.reason", "")
          : get(
              stepTimeLineData,
              "value.response_text.mensajes[0].mensaje",
              ""
            ) === ""
          ? get(stepTimeLineData, "value.responseText.mensajes[0].mensaje", "")
          : get(
              stepTimeLineData,
              "value.response_text.mensajes[0].mensaje",
              ""
            );
      case StepTitleEnum.canceledRetention:
        return get(stepTimeLineData, "value.amount_change_info.reason", "");
      case StepTitleEnum.rejectedVoucher:
      case StepTitleEnum.rejectTransactionTime:
        return get(stepTimeLineData, "value.reject.reason", "");
      case StepTitleEnum.rejetedDNote:
      case StepTitleEnum.rejetedCNote:
        return get(stepTimeLineData, "value.evidence.reason", "");
      case StepTitleEnum.omittedVoucher:
      case StepTitleEnum.omittedDNote:
      case StepTitleEnum.omittedCNote:
      case StepTitleEnum.omittedTransactionTime:
        if (get(stepTimeLineData, "value.kind", "") === KindEnum.CHARGE)
          return get(stepTimeLineData, "value.omit.external_detail", "");

        return get(stepTimeLineData, "value.omit.reason", "");
      case StepTitleEnum.modifiedAmount:
        return get(
          stepTimeLineData,
          "value.modify.reason_change_amount",
          get(stepTimeLineData, "value.amount_change_info.comments", "")
        );
      default:
        return get(stepTimeLineData, "value.modify.reason_change_amount", "");
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2" className={styles.titleDialog}>
          <span className={styles.boldText}>Motivo:</span>
        </Typography>
        <Typography variant="body2" className={styles.textDialog}>
          {getReason(stepTimeLineData)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const FooterDialog: React.FC<IFooterDialog> = ({
  handleCloseDialogDetail,
}: IFooterDialog) => {
  const styles = useStyles();

  return (
    <Grid container direction={"row"} justify={"flex-end"} id={"modaAction"}>
      <Button
        onClick={handleCloseDialogDetail}
        className={styles.buttonClose}
        variant={"contained"}
        color="primary"
      >
        {"Entendido"}
      </Button>
    </Grid>
  );
};

export const ModalDialogDetail: React.FC<IModalDialogDetail> = (
  props: IModalDialogDetail
) => {
  const styles = useStyles();
  const { stepTimeLineData } = useSelector(
    (state: IBillingDashboardState) => state
  );
  const paymentsInitialData = get(props.trxInfo, "payments", []);
  const [paymentsData, setPaymentsData] = useState(
    paymentsInitialData.slice(0, 5)
  );
  const [page, setPage] = useState(1);
  const [limitPage, setLimitPage] = useState(5);

  const handleLimitPage = (newLimit: string): void => {
    setLimitPage(Number(newLimit));
    setPaymentsData(paymentsInitialData.slice(0, Number(newLimit)));
  };

  const handlePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    const pageRange = value * limitPage;
    setPage(value);
    setPaymentsData(
      paymentsInitialData.slice(pageRange - limitPage, pageRange)
    );

    if (value == 1) {
      setPaymentsData(paymentsInitialData.slice(0, limitPage));
    }
  };

  return (
    <Dialog
      open={props.open!}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
    >
      <Grid
        container
        direction={"row"}
        justify={"space-between"}
        className={styles.dialog}
      >
        <HeaderDialog
          stepTimeLineData={stepTimeLineData}
          handleCloseDialogDetail={props.handleCloseDialogDetail}
        />
        <DateDialog stepTimeLineData={stepTimeLineData} />
        {get(stepTimeLineData, "title", "") ===
          StepTitleEnum.modifiedAmount && (
          <ModifyAmountDialog stepTimeLineData={stepTimeLineData} />
        )}
        {has(props.trxInfo, "payments") ? (
          <>
            <Typography variant="body2" className={styles.titleDialog}>
              Operaciones Realizadas:
            </Typography>
            <Grid item xs={12} container alignItems="center" justify={"center"}>
              <Box
                justifyContent="center"
                display="inline-table"
                style={{
                  marginBottom: "5%",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                <BackofficeTable
                  items={paymentsData}
                  style={{}}
                  headers={[
                    {
                      id: "ticketNumber",
                      label: "Transaction Id H.",
                      disabled: false,
                      orderBy: "ticketNumber",
                    },
                    {
                      id: "amount",
                      label: "Monto",
                      disabled: false,
                      orderBy: "amount",
                    },
                    {
                      id: "status",
                      label: "Estado",
                      disabled: false,
                      orderBy: "status",
                    },
                  ]}
                  handleOnRowSelection={() => {}}
                  skeleton={false}
                  sort={{
                    order: "asc",
                    orderBy: "ticketNumber",
                    handleRequestSort: () => {},
                  }}
                  emptyState={{
                    title: "No hay datos",
                    subtitle: "no se ha encontrado ninguna concidencia...",
                  }}
                  pagination={{
                    limit: limitPage,
                    isMobile: false,
                    total: paymentsInitialData.length,
                    page: page,
                    handleChangeLimit: handleLimitPage,
                    handleChangePage: handlePage,
                    text: "",
                    trxPerPage: limitPage,
                  }}
                />
              </Box>
            </Grid>
          </>
        ) : (
          <ReasonDialog stepTimeLineData={stepTimeLineData} />
        )}

        <FooterDialog handleCloseDialogDetail={props.handleCloseDialogDetail} />
      </Grid>
    </Dialog>
  );
};
