export enum ResponseCode {
  MINUTES = "30m",
  ONE = "1h",
  TWO = "2h",
  FOUR = "4h",
}

export const RESPONSE_CODE_TYPES: Record<ResponseCode, string> = {
  [ResponseCode.MINUTES]: "00:30:00",
  [ResponseCode.ONE]: "01:00:00",
  [ResponseCode.TWO]: "02:00:00",
  [ResponseCode.FOUR]: "04:00:00",
};
