import { Grid, FormControl, Select, MenuItem } from "@material-ui/core";
import { useStyles, BootstrapInput } from "./DashboardHeader.styles";
import { COUNTRY_LIST } from "../../shared/infrastructure/enums/CountryEnum";
import { CountryCatalog } from "../../shared/infrastructure/catalogs/CountryCatalog";
import { DashboardHeaderProps } from "./DashboardHeader.interfaces";
import { PAYMENT_METHODS_LIST } from "../../shared/infrastructure/enums/PaymentMethodEnum";
import React from "react";

export const DashboardHeader: React.FC<DashboardHeaderProps> = (
  props: DashboardHeaderProps
) => {
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={4} md={4}>
          <FormControl className={classes.formControl}>
            <Select
              id="country-list"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={props.country}
              input={<BootstrapInput />}
              classes={{ icon: classes.icon }}
              onChange={props.handleSelectCountryChange}
            >
              {COUNTRY_LIST.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  {CountryCatalog[item]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} md={4}>
          <FormControl className={classes.formControl}>
            <Select
              id="payment-method-list"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={props.paymentMethod}
              input={<BootstrapInput />}
              classes={{ icon: classes.icon }}
              onChange={props.handleSelectPaymentMethodChange}
            >
              {PAYMENT_METHODS_LIST.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
