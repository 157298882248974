import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
} from "@material-ui/core";
import IconClose from "../../assets/icons/IconClose";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,
    },
    dialogContentText: {
      marginTop: -10,
      marginBottom: 24,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    dialogPaper: {
      maxWidth: "500px",
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    cancelAction: {
      color: theme.palette.primary.main,
    },
  })
);

export interface ShowDialogBankColombiaProps {
  open: boolean;
  titleText: string | React.ReactElement;
  contentText?: string | React.ReactElement;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  disableAccept?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  active?: boolean;
  adjustedSize?: boolean;
  handleClose: () => void;
  handleAccept?: () => void;
}

export const ShowDialogBankColombia: React.FC<ShowDialogBankColombiaProps> = (
  props: React.PropsWithChildren<ShowDialogBankColombiaProps>
) => {
  const classes = useStyles(props);
  return (
    <Dialog
      classes={props.adjustedSize ? { paper: classes.dialogPaper } : undefined}
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          flexGrow={1}
        >
          <div>
            <Typography
              variant="h5"
              color={"initial"}
              style={{ fontWeight: "bold" }}
            >
              {props.titleText}
            </Typography>
          </div>
          <div>
            <IconButton onClick={props.handleClose}>
              <IconClose />
            </IconButton>
          </div>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText
            className={classes.dialogContentText}
            id="alert-dialog-description"
          >
            <Typography component="span" variant="h6" color="inherit">
              {props.contentText}
            </Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

ShowDialogBankColombia.defaultProps = {
  fullWidth: true,
  maxWidth: "md",
};
