import React, { FC } from "react";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import { ROUTES } from "../../shared/constants/routes";
import { SnackBarProvider } from "@kushki/connect-ui";
import ChargeForm from "../../components/ChargeForm/ChargeForm";
import { CHARGE } from "../../shared/constants/labels/main_containter_labels";
import ChargeBankAccount from "../../components/ChargeBankAccount/ChargeBankAccount";
import WrapperMassiveContainer from "../../components/WrapperMassiveContanier/WrapperMassiveContainer";
import { useMassiveChargeContainer } from "./state/useMassiveChargeContainer";

const MassiveChargeContainer: FC = () => {
  const { form, loading } = useMassiveChargeContainer();

  return (
    <WrapperMassiveContainer currentRoute={ROUTES.MASSIVE_CHARGE} type={CHARGE}>
      <FormWrapper title={"Cobro"}>
        <SnackBarProvider>
          <ChargeForm
            form={form}
            isBranchCentralized={false}
            loading={loading}
          />
          <ChargeBankAccount isCentralizedBranch={false} />
        </SnackBarProvider>
      </FormWrapper>
    </WrapperMassiveContainer>
  );
};

export default MassiveChargeContainer;
