import React, { PropsWithChildren } from "react";
import { IBasicSelectProps } from "../../../../../../shared/infrastructure/interfaces/IBasicSelectProps";
import { MenuItem, Select } from "@mui/material";
import {
  Conditions,
  Operators,
} from "../../../../../../../types/configuration_response";

export const BasicSelect: React.FC<IBasicSelectProps> = (
  props: PropsWithChildren<IBasicSelectProps>
) => {
  return (
    <>
      <Select
        {...props.selectProps}
        name={props.mainProps.name}
        label={props.mainProps.name}
        disabled={props.mainProps.disableAdd}
      >
        {props.mainProps.isRenderLabel
          ? props.mainProps.selectConditions!.map(
              (item: Conditions, index: number) => (
                <MenuItem key={index} value={item.field!}>
                  {item.title}
                </MenuItem>
              )
            )
          : props.mainProps.getOperatorsByConditions!.map(
              (item: Operators, index: number) => (
                <MenuItem key={index} value={item.value}>
                  {item.title}
                </MenuItem>
              )
            )}
      </Select>
    </>
  );
};
