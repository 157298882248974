import { COUNTRY_ISO_CODE, CurrencyMapByCountry } from "../enums/CurrencyEnum";
import { CountryValueEnum } from "../enums/CountryEnum";

export const getMaximumFractionPerCountry = (country: string) => {
  switch (country) {
    case CountryValueEnum.COLOMBIA:
    case CountryValueEnum.CHILE:
      return 0;
    default:
      return 2;
  }
};

export const formatAmount = (amount: number, country: string) => {
  const formatter = new Intl.NumberFormat(`es-${COUNTRY_ISO_CODE[country]}`, {
    currency: CurrencyMapByCountry[country],
    maximumFractionDigits: getMaximumFractionPerCountry(country),
    style: "currency",
  });

  return formatter.format(amount);
};
