import { IStyles } from "../../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  defaultConnector: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: "#CBD5E0",
    backgroundColor: "transparent",
  },
  defaultConnectorTransparent: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: "transparent",
    backgroundColor: "transparent",
  },
  genericContainer: { height: "100%" },
};
