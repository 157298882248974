import * as React from "react";
import { SvgIconProps } from "@mui/material";

const IconWebCheckOut: React.FunctionComponent<SvgIconProps> = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_334_144522)">
        <path
          d="M6.87492 14.6667C7.24311 14.6667 7.54159 14.3682 7.54159 14C7.54159 13.6318 7.24311 13.3333 6.87492 13.3333C6.50673 13.3333 6.20825 13.6318 6.20825 14C6.20825 14.3682 6.50673 14.6667 6.87492 14.6667Z"
          stroke="black"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.2084 14.6667C14.5766 14.6667 14.8751 14.3682 14.8751 14C14.8751 13.6318 14.5766 13.3333 14.2084 13.3333C13.8402 13.3333 13.5417 13.6318 13.5417 14C13.5417 14.3682 13.8402 14.6667 14.2084 14.6667Z"
          stroke="black"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.54175 0.666656H4.20841L5.99508 9.59332C6.05604 9.90025 6.22302 10.176 6.46678 10.3722C6.71054 10.5684 7.01555 10.6727 7.32842 10.6667H13.8084C14.1213 10.6727 14.4263 10.5684 14.6701 10.3722C14.9138 10.176 15.0808 9.90025 15.1417 9.59332L16.2084 3.99999H4.87508"
          stroke="black"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_334_144522">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconWebCheckOut;
