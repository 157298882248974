import { Badge, Box, Grid, Typography } from "@mui/material";
import { styles } from "./ShareholderInfo.styles";

interface Props {
  registeredShareholders: number;
  shareholdersParticipation: string;
}

export const ShareholderInfo: React.FC<Props> = ({
  registeredShareholders,
  shareholdersParticipation,
}) => (
  <Grid sx={styles.container}>
    <Box>
      <Typography color="text.dark" variant="caption" sx={styles.text}>
        Accionistas registrados:
        <Badge
          sx={styles.registeredShareholders}
          color="primary"
          badgeContent={registeredShareholders || "0"}
        />
      </Typography>
    </Box>
    <Box>
      <Typography color="text.dark" variant="caption" sx={styles.text} mr={4}>
        Total de participación de accionistas:
      </Typography>
      <Badge color="secondary" badgeContent={shareholdersParticipation} />
    </Box>
  </Grid>
);
