export const kushkiCardStyles = {
  container: {
    alignItems: "start",
    backgroundColor: "neutral.grey3",
    display: "flex",
    gap: "8px",
    justifyContent: "flex-start",
    padding: "16px 24px",
  },
};
