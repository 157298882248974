import { FC } from "react";
import { ICardTableProps } from "./CardTable.interfaces";
import { CardTableStyles } from "./CardTable.styles";
import { CardListStyles } from "./CardList.styles";
import {
  Box,
  Skeleton,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Table, TableBody, TableRow } from "@mui/material/";
import { get } from "lodash";

const CardTable: FC<ICardTableProps> = (props: ICardTableProps) => {
  return (
    <>
      {get(props, "isLoading", false) ? (
        <Box sx={CardTableStyles.mainBox}>
          <Box sx={CardTableStyles.boxContainerSkeleton}>
            <Box pb={2}>
              <Skeleton
                variant={"rectangular"}
                animation="wave"
                sx={CardTableStyles.titleSkeletonMobile}
              />
            </Box>
            <Box sx={CardListStyles.description}>
              <Box pb={2} sx={CardTableStyles.skeletonRectangle}>
                <Skeleton
                  variant={"rectangular"}
                  animation="wave"
                  sx={CardTableStyles.descriptionLabelSkeleton}
                />
              </Box>
              <Box pb={2}>
                <Skeleton
                  variant={"rectangular"}
                  animation="wave"
                  sx={CardTableStyles.descriptionTextSkeleton}
                />
              </Box>
            </Box>
            <Box sx={CardListStyles.description}>
              <Box pb={2} sx={CardTableStyles.skeletonRectangle}>
                <Skeleton
                  variant={"rectangular"}
                  animation="wave"
                  sx={CardListStyles.descriptionLabelSkeleton}
                />
              </Box>
              <Box pb={2}>
                <Skeleton
                  variant={"rectangular"}
                  animation="wave"
                  sx={CardListStyles.descriptionTextSkeleton}
                />
              </Box>
            </Box>
            <Box sx={CardListStyles.description}>
              <Box pb={2} sx={{ paddingRight: { md: 8, sm: 5.7, xs: 3.9 } }}>
                <Skeleton
                  variant={"rectangular"}
                  animation="wave"
                  height={"20px"}
                  sx={CardListStyles.skeletonBody}
                />
              </Box>
              <Box pb={2} sx={CardListStyles.skeletonRectangleMobile}>
                <Skeleton
                  variant={"rectangular"}
                  animation="wave"
                  height={"20px"}
                  sx={CardListStyles.skeletonFooter}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={CardTableStyles.mainBox}>
          <TableContainer sx={CardTableStyles.tableContainer}>
            <Table sx={CardTableStyles.table}>
              <TableHead sx={CardTableStyles.header}>
                <TableRow>
                  <TableCell sx={CardTableStyles.cellHead} colSpan={3}>
                    <Typography
                      variant={"h6"}
                      color={"text.dark"}
                      sx={CardTableStyles.title}
                    >
                      {props.title}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={CardTableStyles.body}>
                {props.rows.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={CardTableStyles.rowTable}
                      style={
                        index % 2
                          ? { background: "#F7FAFC" }
                          : { background: "transparent" }
                      }
                    >
                      {row.map((r, indexCell) => {
                        return (
                          <TableCell
                            sx={CardTableStyles.cellRow}
                            key={indexCell}
                          >
                            <Typography color="text.dark" variant="caption">
                              {r.text}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export { CardTable };
