import { IStyles } from "../../../../../shared/interfaces/Styles.interfaces";

export const CommentsTabStyles: IStyles = {
  box: {
    backgroundColor: "neutral.grey2",
    borderRadius: "4px",
    mb: "20px",
    padding: "16px",
  },
  container: {
    backgroundColor: "white",
    display: "block",
    mt: "20px",
    paddingTop: "20px",
    width: "100%",
    margin: 0,
  },
  date: {
    typography: "caption",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labelText: {
    color: "#677684",
    typography: "caption",
  },
  text: {
    typography: "caption",
  },
  time: {
    color: "#677684",
    fontSize: "12px",
  },
  username: {
    color: "#023365",
    typography: "caption",
  },
  value: {
    typography: "body2",
  },
};
