/* istanbul ignore file */
/* eslint-disable */
export const environment: {
    kushkiUrl: string;
    s3UrlTemplate: string;
    envName: string;
    devTools: boolean;
} = {
    kushkiUrl: "https://api.kushkipagos.com",
    s3UrlTemplate: "https://spa-backoffice-config-by-file-primary.s3.amazonaws.com/config-file/cycle_instructions.xlsx",
    envName: "primary",
    devTools: false,
};
