import React, { FC } from "react";
import { Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { sectionTitleStyles as styles } from "./SectionTitle.styles";
import { ISectionTitle } from "./SectionTitle.interfaces";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const SectionTitle: FC<ISectionTitle> = ({
  buttonText,
  componentId,
  handleOnClick,
  isDisabled,
  title,
}: ISectionTitle) => {
  return (
    <Grid container justifyContent={"space-between"}>
      <Typography variant="body1" color="text.grey">
        {title}
      </Typography>
      <ComponentSecurityWrapper componentId={componentId}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleOnClick}
          disabled={isDisabled}
        >
          <AddIcon sx={styles.icon} /> {buttonText}
        </Button>
      </ComponentSecurityWrapper>
    </Grid>
  );
};

export default SectionTitle;
