import React from "react";

export const EmptyStateImg = () => {
  return (
    <svg
      width="322"
      height="286"
      viewBox="0 0 322 286"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M288.36 47.7707C249.391 17.9241 211.593 42.2486 169.433 33.6482C126.969 24.9856 110.051 -3.85809 61.6851 9.45606C18.841 21.2498 -7.15549 68.5351 16.3974 108.123C23.1092 119.404 34.5431 127.914 39.2158 140.537C46.7532 160.899 35.28 176.531 37.5181 196.542C46.8182 279.686 174.276 247.481 221.175 221.613C294.726 181.044 344.484 90.7576 288.36 47.7707Z"
        fill="url(#paint0_linear_398_70735)"
        fill-opacity="0.3"
      />
      <path
        opacity="0.24"
        d="M252 17C249.822 17 247.645 16.1707 245.987 14.5133C242.671 11.198 242.671 5.80293 245.987 2.48697C249.302 -0.828989 254.698 -0.828989 258.013 2.48697C261.329 5.80293 261.329 11.1974 258.013 14.5133C256.356 16.1707 254.178 17 252 17ZM252.001 5.86216C251.324 5.86216 250.649 6.11904 250.134 6.63397C249.106 7.66265 249.106 9.33705 250.134 10.3663C251.163 11.395 252.838 11.3956 253.867 10.3663C254.896 9.33764 254.896 7.66324 253.867 6.63397C253.352 6.11904 252.676 5.86216 252.001 5.86216V5.86216Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.24"
        d="M28.5 85C26.1942 85 23.8878 84.1707 22.1329 82.5132C18.6224 79.1971 18.6224 73.8019 22.1329 70.4864C25.6434 67.1715 31.3566 67.1709 34.8671 70.4864C38.3776 73.8025 38.3776 79.1971 34.8671 82.5132C33.1122 84.1707 30.8058 85 28.5 85ZM28.5 73.8617C27.7846 73.8617 27.0686 74.1186 26.524 74.6336C25.4341 75.6623 25.4341 77.3368 26.524 78.3661C27.6132 79.3954 29.3868 79.3948 30.476 78.3661C31.5659 77.3373 31.5659 75.6629 30.476 74.6336C29.9314 74.1186 29.216 73.8617 28.5 73.8617Z"
        fill="#4498EE"
      />
      <path
        opacity="0.48"
        d="M24.5686 112C23.8182 112 23.0685 111.7 22.4958 111.099L18.9997 107.429L15.5036 111.099C14.3588 112.3 12.5035 112.3 11.3586 111.099C10.2138 109.897 10.2138 107.949 11.3586 106.747L16.9275 100.901C18.0723 99.6997 19.9277 99.6997 21.0725 100.901L26.6414 106.747C27.7862 107.949 27.7862 109.898 26.6414 111.099C26.0687 111.699 25.3189 112 24.5686 112Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.48"
        d="M261.569 222C260.818 222 260.069 221.7 259.496 221.099L256 217.429L252.504 221.099C251.359 222.3 249.503 222.3 248.359 221.099C247.214 219.897 247.214 217.949 248.359 216.747L253.927 210.901C255.072 209.7 256.928 209.7 258.073 210.901L263.641 216.747C264.786 217.949 264.786 219.898 263.641 221.099C263.069 221.699 262.319 222 261.569 222Z"
        fill="#1E65AE"
      />
      <g filter="url(#filter0_dddd_398_70735)">
        <rect
          x="64.8047"
          y="76.7969"
          width="183"
          height="109"
          rx="8"
          transform="rotate(-5.36674 64.8047 76.7969)"
          fill="#FBFCFE"
        />
      </g>
      <path
        opacity="0.48"
        d="M273 50C270.824 50.0006 268.647 49.1713 266.99 47.5133C265.384 45.9069 264.5 43.7715 264.5 41.5C264.5 39.2286 265.384 37.0932 266.99 35.4868C270.303 32.1714 275.696 32.1708 279.01 35.4868C280.616 37.0932 281.5 39.2286 281.5 41.5C281.5 43.7721 280.616 45.9075 279.01 47.5133C277.354 49.1707 275.177 50 273 50ZM273 38.862C272.325 38.862 271.649 39.1189 271.135 39.6338C270.636 40.1323 270.362 40.7951 270.362 41.5C270.362 42.205 270.637 42.8677 271.135 43.3662C272.164 44.3955 273.837 44.3955 274.866 43.3662C275.365 42.8677 275.638 42.205 275.638 41.5C275.638 40.7951 275.364 40.1329 274.866 39.6338C274.352 39.1189 273.676 38.862 273 38.862Z"
        fill="#CBD5E0"
      />
      <path
        d="M91.1863 91.4255C91.133 90.8583 91.929 90.3196 92.9643 90.2224L143.105 85.512C144.14 85.4148 145.023 85.7958 145.076 86.363L145.438 90.2146C145.491 90.7818 144.695 91.3205 143.66 91.4177L93.5191 96.1281C92.4838 96.2253 91.6014 95.8443 91.5482 95.2771L91.1863 91.4255Z"
        fill="#CBD5E0"
      />
      <path
        d="M150.241 85.8777C150.188 85.3104 151.007 84.7696 152.07 84.6697L203.585 79.8303C204.648 79.7304 205.554 80.1092 205.607 80.6765L205.969 84.528C206.022 85.0953 205.203 85.6361 204.14 85.736L152.625 90.5754C151.562 90.6753 150.656 90.2965 150.603 89.7292L150.241 85.8777Z"
        fill="#CBD5E0"
      />
      <path
        d="M209.3 80.3298C209.246 79.7626 209.582 79.2671 210.05 79.2231L232.717 77.0938C233.185 77.0498 233.607 77.474 233.661 78.0413L234.022 81.8928C234.076 82.4601 233.74 82.9556 233.272 82.9995L210.605 85.1288C210.137 85.1728 209.715 84.7486 209.661 84.1814L209.3 80.3298Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.1332 91.0289L83.2817 91.3908C82.8562 91.4307 82.5438 91.808 82.5837 92.2334L82.9455 96.085C82.9855 96.5104 83.3628 96.8229 83.7882 96.7829L87.6398 96.4211C88.0652 96.3811 88.3777 96.0039 88.3377 95.5784L87.9759 91.7269C87.9359 91.3014 87.5586 90.989 87.1332 91.0289ZM83.2575 91.134C82.6903 91.1873 82.2737 91.6903 82.327 92.2575L82.6888 96.1091C82.7421 96.6763 83.2451 97.093 83.8123 97.0397L87.6639 96.6779C88.2311 96.6246 88.6478 96.1215 88.5945 95.5543L88.2327 91.7028C88.1794 91.1355 87.6763 90.7189 87.1091 90.7722L83.2575 91.134Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.8288 92.3042C81.7497 91.462 82.3683 90.7152 83.2104 90.636L87.062 90.2742C87.9042 90.1951 88.651 90.8137 88.7301 91.6559L89.0919 95.5074C89.1711 96.3496 88.5525 97.0964 87.7103 97.1755L83.8588 97.5374C83.0166 97.6165 82.2697 96.9979 82.1906 96.1557L81.8288 92.3042ZM83.3281 91.8884C83.1776 91.9026 83.067 92.036 83.0812 92.1865L83.443 96.0381C83.4571 96.1886 83.5906 96.2991 83.7411 96.285L87.5927 95.9232C87.7432 95.909 87.8537 95.7756 87.8396 95.6251L87.4777 91.7735C87.4636 91.623 87.3301 91.5125 87.1796 91.5266L83.3281 91.8884Z"
        fill="#CBD5E0"
      />
      <path
        d="M92.8641 109.279C92.8108 108.712 93.6068 108.173 94.642 108.076L144.783 103.366C145.818 103.268 146.7 103.649 146.754 104.217L147.115 108.068C147.169 108.635 146.373 109.174 145.338 109.271L95.1968 113.982C94.1616 114.079 93.2792 113.698 93.2259 113.131L92.8641 109.279Z"
        fill="#CBD5E0"
      />
      <path
        d="M151.919 103.731C151.865 103.164 152.684 102.623 153.748 102.523L205.262 97.6838C206.326 97.5839 207.231 97.9627 207.285 98.53L207.647 102.382C207.7 102.949 206.881 103.49 205.817 103.59L154.303 108.429C153.239 108.529 152.334 108.15 152.281 107.583L151.919 103.731Z"
        fill="#CBD5E0"
      />
      <path
        d="M210.977 98.1833C210.924 97.6161 211.26 97.1206 211.728 97.0766L234.395 94.9473C234.863 94.9034 235.285 95.3276 235.338 95.8948L235.7 99.7463C235.753 100.314 235.417 100.809 234.949 100.853L212.283 102.982C211.815 103.026 211.392 102.602 211.339 102.035L210.977 98.1833Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.811 108.882L84.9594 109.244C84.534 109.284 84.2215 109.662 84.2615 110.087L84.6233 113.938C84.6632 114.364 85.0405 114.676 85.466 114.636L89.3175 114.275C89.7429 114.235 90.0554 113.857 90.0154 113.432L89.6536 109.58C89.6137 109.155 89.2364 108.842 88.811 108.882ZM84.9353 108.987C84.368 109.041 83.9514 109.544 84.0047 110.111L84.3665 113.963C84.4198 114.53 84.9228 114.946 85.4901 114.893L89.3416 114.531C89.9089 114.478 90.3255 113.975 90.2722 113.408L89.9104 109.556C89.8571 108.989 89.3541 108.572 88.7868 108.626L84.9353 108.987Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.5065 110.158C83.4274 109.316 84.046 108.569 84.8882 108.49L88.7397 108.128C89.5819 108.049 90.3287 108.667 90.4079 109.509L90.7697 113.361C90.8488 114.203 90.2302 114.95 89.388 115.029L85.5365 115.391C84.6943 115.47 83.9475 114.851 83.8684 114.009L83.5065 110.158ZM85.0058 109.742C84.8553 109.756 84.7448 109.89 84.7589 110.04L85.1207 113.892C85.1349 114.042 85.2683 114.153 85.4188 114.138L89.2704 113.777C89.4209 113.763 89.5314 113.629 89.5173 113.479L89.1555 109.627C89.1413 109.477 89.0079 109.366 88.8574 109.38L85.0058 109.742Z"
        fill="#CBD5E0"
      />
      <path
        d="M94.5418 127.132C94.4885 126.564 95.2845 126.026 96.3197 125.928L146.46 121.218C147.496 121.121 148.378 121.502 148.431 122.069L148.793 125.921C148.846 126.488 148.05 127.027 147.015 127.124L96.8745 131.834C95.8393 131.931 94.9569 131.55 94.9036 130.983L94.5418 127.132Z"
        fill="#CBD5E0"
      />
      <path
        d="M153.596 121.584C153.543 121.016 154.362 120.476 155.426 120.376L206.94 115.536C208.004 115.436 208.909 115.815 208.962 116.383L209.324 120.234C209.378 120.801 208.559 121.342 207.495 121.442L155.981 126.281C154.917 126.381 154.012 126.003 153.958 125.435L153.596 121.584Z"
        fill="#CBD5E0"
      />
      <path
        d="M212.655 116.036C212.602 115.469 212.938 114.973 213.406 114.929L236.072 112.8C236.54 112.756 236.963 113.18 237.016 113.747L237.378 117.599C237.431 118.166 237.095 118.662 236.627 118.706L213.961 120.835C213.493 120.879 213.07 120.455 213.017 119.887L212.655 116.036Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.4887 126.735L86.6371 127.097C86.2117 127.137 85.8992 127.514 85.9392 127.939L86.301 131.791C86.341 132.216 86.7183 132.529 87.1437 132.489L90.9952 132.127C91.4207 132.087 91.7331 131.71 91.6932 131.284L91.3314 127.433C91.2914 127.007 90.9141 126.695 90.4887 126.735ZM86.613 126.84C86.0458 126.893 85.6291 127.396 85.6824 127.964L86.0442 131.815C86.0975 132.382 86.6006 132.799 87.1678 132.746L91.0194 132.384C91.5866 132.331 92.0032 131.828 91.95 131.26L91.5881 127.409C91.5348 126.842 91.0318 126.425 90.4646 126.478L86.613 126.84Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.1843 128.01C85.1052 127.168 85.7237 126.421 86.5659 126.342L90.4175 125.98C91.2596 125.901 92.0065 126.52 92.0856 127.362L92.4474 131.213C92.5265 132.056 91.9079 132.802 91.0658 132.882L87.2142 133.243C86.3721 133.323 85.6252 132.704 85.5461 131.862L85.1843 128.01ZM86.6836 127.594C86.5331 127.609 86.4225 127.742 86.4367 127.893L86.7985 131.744C86.8126 131.895 86.9461 132.005 87.0966 131.991L90.9481 131.629C91.0986 131.615 91.2092 131.482 91.195 131.331L90.8332 127.48C90.8191 127.329 90.6856 127.219 90.5351 127.233L86.6836 127.594Z"
        fill="#CBD5E0"
      />
      <path
        d="M96.2176 144.984C96.1643 144.417 96.9603 143.878 97.9955 143.781L148.136 139.071C149.171 138.973 150.054 139.354 150.107 139.922L150.469 143.773C150.522 144.34 149.726 144.879 148.691 144.976L98.5503 149.687C97.5151 149.784 96.6327 149.403 96.5794 148.836L96.2176 144.984Z"
        fill="#CBD5E0"
      />
      <path
        d="M155.272 139.436C155.219 138.869 156.038 138.328 157.102 138.228L208.616 133.389C209.68 133.289 210.585 133.668 210.638 134.235L211 138.087C211.053 138.654 210.234 139.195 209.171 139.295L157.656 144.134C156.593 144.234 155.687 143.855 155.634 143.288L155.272 139.436Z"
        fill="#CBD5E0"
      />
      <path
        d="M214.331 133.888C214.278 133.321 214.614 132.826 215.082 132.782L237.748 130.652C238.216 130.608 238.639 131.033 238.692 131.6L239.054 135.451C239.107 136.019 238.771 136.514 238.303 136.558L215.637 138.687C215.169 138.731 214.746 138.307 214.693 137.74L214.331 133.888Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M92.1645 144.588L88.3129 144.949C87.8875 144.989 87.575 145.367 87.615 145.792L87.9768 149.644C88.0168 150.069 88.394 150.381 88.8195 150.342L92.671 149.98C93.0964 149.94 93.4089 149.562 93.369 149.137L93.0071 145.285C92.9672 144.86 92.5899 144.548 92.1645 144.588ZM88.2888 144.693C87.7216 144.746 87.3049 145.249 87.3582 145.816L87.72 149.668C87.7733 150.235 88.2764 150.652 88.8436 150.598L92.6951 150.236C93.2624 150.183 93.679 149.68 93.6257 149.113L93.2639 145.261C93.2106 144.694 92.7076 144.277 92.1403 144.331L88.2888 144.693Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.86 145.863C86.7809 145.021 87.3995 144.274 88.2417 144.195L92.0932 143.833C92.9354 143.754 93.6823 144.372 93.7614 145.214L94.1232 149.066C94.2023 149.908 93.5837 150.655 92.7416 150.734L88.89 151.096C88.0478 151.175 87.301 150.556 87.2219 149.714L86.86 145.863ZM88.3593 145.447C88.2088 145.461 88.0983 145.595 88.1124 145.745L88.4743 149.597C88.4884 149.747 88.6219 149.858 88.7724 149.844L92.6239 149.482C92.7744 149.468 92.8849 149.334 92.8708 149.184L92.509 145.332C92.4948 145.182 92.3614 145.071 92.2109 145.085L88.3593 145.447Z"
        fill="#CBD5E0"
      />
      <path
        d="M97.8953 162.838C97.842 162.27 98.638 161.732 99.6732 161.634L149.814 156.924C150.849 156.827 151.732 157.208 151.785 157.775L152.147 161.627C152.2 162.194 151.404 162.733 150.369 162.83L100.228 167.54C99.1928 167.637 98.3104 167.256 98.2571 166.689L97.8953 162.838Z"
        fill="#CBD5E0"
      />
      <path
        d="M156.95 157.29C156.897 156.723 157.716 156.182 158.779 156.082L210.294 151.242C211.357 151.142 212.263 151.521 212.316 152.089L212.678 155.94C212.731 156.507 211.912 157.048 210.849 157.148L159.334 161.987C158.271 162.087 157.365 161.709 157.312 161.141L156.95 157.29Z"
        fill="#CBD5E0"
      />
      <path
        d="M216.009 151.742C215.955 151.175 216.291 150.679 216.759 150.635L239.426 148.506C239.894 148.462 240.316 148.886 240.37 149.453L240.731 153.305C240.785 153.872 240.449 154.368 239.981 154.412L217.314 156.541C216.846 156.585 216.424 156.161 216.37 155.593L216.009 151.742Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M93.8422 162.441L89.9907 162.803C89.5652 162.843 89.2527 163.22 89.2927 163.646L89.6545 167.497C89.6945 167.923 90.0718 168.235 90.4972 168.195L94.3488 167.833C94.7742 167.793 95.0867 167.416 95.0467 166.991L94.6849 163.139C94.6449 162.714 94.2676 162.401 93.8422 162.441ZM89.9665 162.546C89.3993 162.599 88.9827 163.102 89.0359 163.67L89.3978 167.521C89.451 168.088 89.9541 168.505 90.5213 168.452L94.3729 168.09C94.9401 168.037 95.3568 167.534 95.3035 166.966L94.9416 163.115C94.8884 162.548 94.3853 162.131 93.8181 162.184L89.9665 162.546Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.5378 163.716C88.4587 162.874 89.0772 162.127 89.9194 162.048L93.771 161.686C94.6131 161.607 95.36 162.226 95.4391 163.068L95.8009 166.92C95.88 167.762 95.2615 168.509 94.4193 168.588L90.5677 168.949C89.7256 169.029 88.9787 168.41 88.8996 167.568L88.5378 163.716ZM90.0371 163.301C89.8866 163.315 89.776 163.448 89.7902 163.599L90.152 167.45C90.1661 167.601 90.2996 167.711 90.4501 167.697L94.3016 167.335C94.4521 167.321 94.5627 167.188 94.5485 167.037L94.1867 163.186C94.1726 163.035 94.0391 162.925 93.8886 162.939L90.0371 163.301Z"
        fill="#CBD5E0"
      />
      <defs>
        <filter
          id="filter0_dddd_398_70735"
          x="0.517334"
          y="31.3936"
          width="320.967"
          height="254.213"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.575" />
          <feGaussianBlur stdDeviation="1.87891" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_398_70735"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="5.28125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_398_70735"
            result="effect2_dropShadow_398_70735"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.225" />
          <feGaussianBlur stdDeviation="14.168" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_398_70735"
            result="effect3_dropShadow_398_70735"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="36" />
          <feGaussianBlur stdDeviation="32.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_398_70735"
            result="effect4_dropShadow_398_70735"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_398_70735"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_398_70735"
          x1="216.431"
          y1="293.4"
          x2="341.265"
          y2="29.6322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E1E8F2" />
          <stop offset="1" stop-color="#E2E8F0" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
