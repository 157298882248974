import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { FC } from "react";
import { ITableHeadProps } from "../../../../shared/interfaces/Table.interfaces";
import ChevronsSortIcon from "../../../../assets/icons/ChevronsSortIcon/ChevronsSortIcon";
import { TableHeadStyles } from "./TableHead.styles";
import { useTableHeadState } from "./state/useTableHeadState";
import { isAgent } from "../../../../shared/utils/role_utils";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "../../../../shared/enums/SecurityWrapperEnum";

const BackofficeTableHead: FC<ITableHeadProps> = (props: ITableHeadProps) => {
  const { columns } = props;
  const { handleChangeSelectAllRefundsTransactions, refunds, selectedRefunds } =
    useTableHeadState();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            padding={column.padding}
            sx={
              column.padding === "checkbox"
                ? TableHeadStyles.checkboxCell
                : TableHeadStyles.cell
            }
          >
            {column.type === "checkbox" ? (
              <>
                {column.label !== "" && <span>{column.label}</span>}
                <ComponentSecurityWrapper
                  componentId={SecurityWrapperEnum.APPROVE}
                >
                  <Checkbox
                    onChange={handleChangeSelectAllRefundsTransactions}
                    disabled={isAgent()}
                    sx={TableHeadStyles.checkbox}
                    checked={refunds.length === selectedRefunds.length}
                  />
                </ComponentSecurityWrapper>
              </>
            ) : (
              <>
                {column.sortable ? (
                  <TableSortLabel
                    onClick={column.onSortClick}
                    IconComponent={ChevronsSortIcon}
                    sx={TableHeadStyles.sortLabel}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  <span>{column.label}</span>
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BackofficeTableHead;
