import React from "react";
import { Button, Divider, Grid, Menu, Typography } from "@material-ui/core";
import { Filter } from "react-feather";
import { filterComponentStyle } from "./FilterComponent.style";
import { useFilterComponentState } from "./state/useFilterComponentState";
import { AccordionPopUp } from "../../AccordionPopUp/AccordionPopUp";
import { ChipComponent } from "../../AccordionPopUp/components/ChipComponent/ChipComponent";
import { WalletDashboardConstants } from "../../shared/infrastructure/constants/WalletDashboardConstants";
import { AmountComponent } from "../../AccordionPopUp/components/AmountComponent/AmountComponent";

export const FilterComponent = () => {
  const classes = filterComponentStyle();
  const {
    anchor,
    defaultExpanded,
    operationTypeItems,
    statusItems,
    handleCloseMenu,
    handleClickFilter,
    handleExpandedOrCollapsed,
    handleClickOnChip,
    handleApplyFilters,
    handleRestFilters,
    rangeAmountProps,
    isActiveAccordion,
    toggleAccordionItem,
  } = useFilterComponentState();

  return (
    <>
      <Button
        variant={"outlined"}
        className={classes.filterButton}
        startIcon={<Filter size={"20"} color={"#293036"} />}
        onClick={handleClickFilter}
        fullWidth
      >
        <Typography className={classes.buttonText}>
          {WalletDashboardConstants.FILTER}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        <Grid container direction={"column"} className={classes.mainGrid}>
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-around"}
            spacing={1}
          >
            <Grid item xs={6}>
              <Typography className={classes.filterByText}>
                {WalletDashboardConstants.FILTER_BY}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Button
                className={classes.expandedCollapsed}
                onClick={handleExpandedOrCollapsed}
              >
                {!defaultExpanded
                  ? WalletDashboardConstants.EXPANDED
                  : WalletDashboardConstants.COLLAPSED}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={12}>
              <AccordionPopUp
                title={WalletDashboardConstants.OPERATION_TYPE}
                defaultExpanded={isActiveAccordion[0]}
                handleExpandedOrCollapsed={() => toggleAccordionItem(0)}
                componentToRender={
                  <ChipComponent
                    dataToDraw={operationTypeItems}
                    handleClick={handleClickOnChip}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={12}>
              <AccordionPopUp
                title={WalletDashboardConstants.STATUS}
                defaultExpanded={isActiveAccordion[1]}
                handleExpandedOrCollapsed={() => toggleAccordionItem(1)}
                componentToRender={
                  <ChipComponent
                    dataToDraw={statusItems}
                    handleClick={handleClickOnChip}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={12}>
              <AccordionPopUp
                title={WalletDashboardConstants.AMOUNTS}
                defaultExpanded={isActiveAccordion[2]}
                handleExpandedOrCollapsed={() => toggleAccordionItem(2)}
                componentToRender={
                  <AmountComponent
                    handlers={rangeAmountProps.handlers}
                    errorRange={rangeAmountProps.values.errorRange}
                    rangeAmount={rangeAmountProps.values.rangeAmount}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={"outlined"}
                className={classes.applyButton}
                onClick={handleApplyFilters}
              >
                {WalletDashboardConstants.APPLY}
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Button
                className={classes.deleteButton}
                onClick={handleRestFilters}
              >
                {WalletDashboardConstants.RESET}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};
