import React from "react";
import { mainSubHeaderStyles as styles } from "./SubHeader.styles";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CountryEnum } from "../../shared/enum/CountryEnum";
import { Flag } from "../Flag/Flag";
import { FlagsNames } from "../../shared/enum/CountryEnumArray";

export interface SubHeaderProps {
  country: CountryEnum;
  id: string;
  redirectPath: string;
  title: string;
  subtitle: string;
  iconType: JSX.Element;
  isEdit?: boolean;
}

export const Subheader: React.FC<SubHeaderProps> = (props: SubHeaderProps) => {
  const idTitlte = "ID:";

  return (
    <Container disableGutters maxWidth={false}>
      <Box>
        <Grid container spacing={1} alignItems="center">
          {props.isEdit && (
            <IconButton
              style={{ color: "#4399ED" }}
              onClick={() => window.history.go(-1)}
            >
              <ArrowBackIosIcon />
              Regresar
            </IconButton>
          )}
        </Grid>
        <Grid container alignItems="center" sx={styles.gridTitle} spacing={1}>
          <Grid item>
            <Typography
              color="text.primary"
              variant="h1"
              fontWeight={600}
              sx={styles.title}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Grid item sx={styles.gridText}>
              <Typography variant="caption" color="text.primary">
                {props.subtitle}
              </Typography>
            </Grid>

            <Grid item>
              <Box sx={styles.flagPadding}>
                <Flag country={props.country} />
              </Box>
            </Grid>

            <Grid item sx={styles.gridCountry}>
              <Typography variant="body1" color="text.primary">
                {get(FlagsNames[props.country], "title", props.country)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Divider orientation="vertical" flexItem sx={styles.divider} />
            <Grid item sx={styles.gridId}>
              <Typography variant="caption" color="text.primary">
                {idTitlte}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="text.primary">
                {props.id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Subheader;
