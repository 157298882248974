import React, { FC } from "react";
import { IGreyCardContainer } from "@components/layout/GreyCardContainer/GreyCardContainer.interfaces";
import { Box, Typography } from "@mui/material";
import { greyCardContainerStyles as styles } from "@components/layout/GreyCardContainer/GreyCardContainer.styles";
const GreyCardContainer: FC<IGreyCardContainer> = ({
  title,
  children,
}: IGreyCardContainer) => {
  return (
    <Box sx={styles.container}>
      <Typography variant={"h6"}>{title}</Typography>
      {children}
    </Box>
  );
};

export default GreyCardContainer;
