import React from "react";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSearchFieldState } from "./state/useSearchFieldState";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormControl-root": {
        height: "auto",
      },
      "& > * + *": {
        marginTop: theme.spacing(0),
      },
      "& .MuiInputBase-root": {
        height: "auto",
        width: "auto",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInputBase-input": {
        width: 340,
        [theme.breakpoints.down(769)]: {
          maxWidth: 340,
        },
      },
      [theme.breakpoints.down(769)]: {
        minWidth: "100%",
        "& .MuiInputBase-input": {
          width: "100%",
        },
      },
    },
  })
);

export const SearchField = () => {
  const classes = useStyles();
  const {
    searchText,
    handleChangeSearchText,
    handleEnterSearchText,
    handleClickSearchText,
  } = useSearchFieldState();

  return (
    <TextField
      id="search-field"
      label={LabelsDispersion.LABEL_SEARCH_FIELD}
      onKeyDown={(event: any) => handleEnterSearchText(event.key)}
      type="search"
      onChange={(event: any) => handleChangeSearchText(event.target.value)}
      variant="outlined"
      size={"small"}
      value={searchText}
      className={classes.root}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search onClick={handleClickSearchText} />
          </InputAdornment>
        ),
      }}
    />
  );
};
