import { CountriesEnum } from "./Countries";

export const TIMEZONE_COUNTRY: Map<string, string> = new Map()
  .set(`${CountriesEnum.COLOMBIA}`, "America/Bogota")
  .set(`${CountriesEnum.ECUADOR}`, "America/Guayaquil")
  .set(`${CountriesEnum.PERU}`, "America/Lima")
  .set(`${CountriesEnum.CHILE}`, "America/Santiago")
  .set(`${CountriesEnum.MEXICO}`, "America/Mexico_City")
  .set(`${CountriesEnum.BRAZIL}`, "America/Sao_Paulo")
  .set(`${CountriesEnum.EL_SALVADOR}`, "America/El_Salvador")
  .set(`${CountriesEnum.PANAMA}`, "America/Panama")
  .set(`${CountriesEnum.COSTA_RICA}`, "America/Managua")
  .set("", "America/Guayaquil");
