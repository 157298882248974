import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";
import { environment } from "./environments/environment";
import { store } from "./store/store";
import { ChargebackContainer } from "./containers/ChargebackContainer/ChargebackContainer";
import { ChargebackManageFiles } from "./containers/ChargebackManageFiles/ChargebackManageFiles";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "./shared/enums/SecurityWrapperEnum";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <ModuleSecurityWrapper
        basePath={environment.kushkiUrl}
        componentId={SecurityWrapperEnum.M_TRANSACTIONS}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackBarProvider>
              <BrowserRouter basename={SPA_BASENAME}>
                <Routes>
                  <Route
                    path={ROUTES.INDEX}
                    element={<ChargebackContainer />}
                  />
                  <Route
                    path={ROUTES.MANAGE_FILES}
                    element={<ChargebackManageFiles />}
                  />
                </Routes>
              </BrowserRouter>
            </SnackBarProvider>
          </ThemeProvider>
        </Provider>
      </ModuleSecurityWrapper>
    </React.StrictMode>
  );
};

export default Root;
