import { FilterCOTypeEnum } from "../Enums/FilterTypeEnum";
import {
  MODEL,
  ModelCodeEnum,
  MUNICIPALITY,
  MunicipalityCodeTypeEnum,
  RetentionCatalogCO,
  RetentionTypeCO,
} from "../constants/RetentionConstantsCO";
import { IOptionFilter } from "@kushki/frontend-molecules/filter-container";

export const DefaultFilterCO: IOptionFilter[] = [
  {
    id: FilterCOTypeEnum.city,
    title: "Municipio",
    options: [
      {
        key: MunicipalityCodeTypeEnum.M01,
        label:
          RetentionCatalogCO[RetentionTypeCO.city][
            MunicipalityCodeTypeEnum.M01
          ],
        selected: false,
      },
      {
        key: MunicipalityCodeTypeEnum.M02,
        label:
          RetentionCatalogCO[RetentionTypeCO.city][
            MunicipalityCodeTypeEnum.M02
          ],
        selected: false,
      },
      {
        key: MunicipalityCodeTypeEnum.M03,
        label:
          RetentionCatalogCO[RetentionTypeCO.city][
            MunicipalityCodeTypeEnum.M03
          ],
        selected: false,
      },
      {
        key: MunicipalityCodeTypeEnum.M04,
        label:
          RetentionCatalogCO[RetentionTypeCO.city][
            MunicipalityCodeTypeEnum.M04
          ],
        selected: false,
      },
      {
        key: MunicipalityCodeTypeEnum.M05,
        label:
          RetentionCatalogCO[RetentionTypeCO.city][
            MunicipalityCodeTypeEnum.M05
          ],
        selected: false,
      },
      {
        key: MunicipalityCodeTypeEnum.M06,
        label:
          RetentionCatalogCO[RetentionTypeCO.city][
            MunicipalityCodeTypeEnum.M06
          ],
        selected: false,
      },
    ],
  },
  {
    id: FilterCOTypeEnum.model,
    title: "Modelo",
    options: [
      {
        key: ModelCodeEnum.MO01,
        label: RetentionCatalogCO[RetentionTypeCO.model][ModelCodeEnum.MO01],
        selected: false,
      },
      {
        key: ModelCodeEnum.MO05,
        label: RetentionCatalogCO[RetentionTypeCO.model][ModelCodeEnum.MO05],
        selected: false,
      },
    ],
  },
];

export const FILTERS_TO_BUILD_CO = [
  {
    key: FilterCOTypeEnum.city,
    data: MUNICIPALITY,
  },
  {
    key: FilterCOTypeEnum.model,
    data: MODEL,
  },
];
