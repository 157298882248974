import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";
import SelectMethod from "../../components/SelectMethod/SelectMethod";
import {
  BRANCH_OPTIONS_INITIAL_MESSAGE,
  CONTINUE_BUTTON,
} from "../../shared/constants/labels/branches_labels";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { branchCreationContainerStyles as styles } from "./BranchCreationContainerStyles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useBranchCreationContainerState } from "./state/useBranchCreationContainerState";

const BranchCreationContainer: FC<{}> = () => {
  const { disableContinueButton, handleSelectBranchOption, navigateTo } =
    useBranchCreationContainerState();

  return (
    <Box sx={styles.container}>
      <Typography color="text.primary" variant="h3">
        {BRANCH_OPTIONS_INITIAL_MESSAGE}
      </Typography>
      <Box sx={styles.options}>
        <SelectMethod onClick={handleSelectBranchOption} />
      </Box>
      <ComponentSecurityWrapper
        componentId={ComponentIdsEnum.M_CLIENTS_BRANCH_MANUAL}
      >
        <Button
          variant="contained"
          color={"primary"}
          disabled={disableContinueButton}
          onClick={navigateTo}
          endIcon={<ArrowForwardIcon />}
        >
          {CONTINUE_BUTTON}
        </Button>
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default BranchCreationContainer;
