import { AffiliationLead } from "../../../../types/affiliation_lead";
import { ReactNode } from "react";
import {
  SemaphoreData,
  StatusSteps,
} from "../../../../types/remote/semaphore_data";
import VisaIcon from "../../../assets/image/CardBrand/visa.svg";
import MastercardIcon from "../../../assets/image/CardBrand/mastercard.svg";
import DinersIcon from "../../../assets/image/CardBrand/diners.png";
import AmericanExpressIcon from "../../../assets/image/CardBrand/american_express.png";
import DiscoverIcon from "../../../assets/image/CardBrand/discover.png";
import Alia from "../../../assets/image/CardBrand/alia.png";
import JCB from "../../../assets/image/CardBrand/jcb.png";
import Amex from "../../../assets/image/CardBrand/amex.png";
import CarnetIcon from "../../../assets/image/CardBrand/carnet.png";
import {
  MerchantUsers,
  User,
  UserAttributes,
  UserGroups,
} from "../../../../types/merchant_users";
import { IUser } from "../interfaces/IUser";
import { BackofficeRolesEnum } from "../catalogs/RolesCatalog";
import { get } from "lodash";

export interface ICountry {
  value: string;
  name: string;
  flag: string;
}

export enum ItemsLocalStorageEnum {
  merchantBasicInformation = "merchantBasicInformation",
}

export const setMerchantInfoStorage = () => {
  const merchantInfoStorage: string | null = localStorage.getItem(
    ItemsLocalStorageEnum.merchantBasicInformation
  );
  const merchantInfoWithOrigin: object = merchantInfoStorage
    ? { ...JSON.parse(merchantInfoStorage), origin: "createMerchant" }
    : { origin: "createMerchant" };

  localStorage.setItem(
    ItemsLocalStorageEnum.merchantBasicInformation,
    JSON.stringify(merchantInfoWithOrigin)
  );
};

export const navigateWithMerchantInfoStorage = (URL: string) => {
  setMerchantInfoStorage();
  window.location.assign(URL);
};

export const COUNTRIES: ICountry[] = [
  {
    value: "Brazil",
    name: "Brasil",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
  },
  {
    value: "Chile",
    name: "Chile",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
  },
  {
    value: "Colombia",
    name: "Colombia",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
  },
  {
    value: "CostaRica",
    name: "Costa Rica",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
  },
  {
    value: "Ecuador",
    name: "Ecuador",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
  },
  {
    value: "ElSalvador",
    name: "El Salvador",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
  },
  {
    value: "Guatemala",
    name: "Guatemala",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag%402x.png",
  },
  {
    value: "Honduras",
    name: "Honduras",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag%402x.png",
  },
  {
    value: "Mexico",
    name: "México",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
  },
  {
    value: "Nicaragua",
    name: "Nicaragua",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag%402x.png",
  },
  {
    value: "Panama",
    name: "Panamá",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
  },
  {
    value: "Peru",
    name: "Perú",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
  },
  {
    value: "EEUU",
    name: "Estados Unidos",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
  },
];

export enum StepsEnum {
  stepBasicData = "stepBasicData",
  stepProcessor = "stepProcessor",
  stepServices = "stepServices",
  stepConfigRatesAndInvoice = "stepConfigRatesAndInvoice",
  stepUsers = "stepUsers",
}

export enum configType {
  configRate = "rate",
  configDiscount = "discount",
}

export type StepName = keyof typeof StepsEnum;

export type StepData = {
  content: (
    step: string,
    changeStepStatus: (step: string, status: StatusSteps) => void
  ) => ReactNode;
  label: string;
};

export enum AdditionalStepsEnum {
  developers = "developers",
  accountPreferences = "accountPreferences",
}

export type AdditionalStepName = keyof typeof AdditionalStepsEnum;

export const not_apply: string = "not_apply";

export const NotApplyOption: AffiliationLead = {
  id: not_apply,
  name: [],
  kind: "lead",
  merchantInfo: {
    name: "No aplica",
  },
};

export const navigateRoute = (path: string) => {
  window.location.assign(path);
};

export enum PaymentMethodEnum {
  CARD = "card",
  TRANSFER = "transfer",
  TRANSFER_PAYOUT = "payouts-transfer",
  ACH_TRANSFER = "ach transfer",
  CASH = "cash",
  CASH_PAYOUT = "payouts-cash",
  TRANSFER_SUBSCRIPTION = "transfer-subscriptions",
}

export enum RatesEnum {
  PAY_IN = "Pay in",
  PAY_OUT = "Pay out",
  ADDITIONAL_SERVICE = "Servicio Adicional",
}

export const RATES_OPTIONS: string[] = [
  RatesEnum.PAY_IN,
  RatesEnum.PAY_OUT,
  RatesEnum.ADDITIONAL_SERVICE,
];

export const RATES_ROUTES: Record<RatesEnum, string> = {
  [RatesEnum.PAY_IN]: "payIn",
  [RatesEnum.PAY_OUT]: "payOut",
  [RatesEnum.ADDITIONAL_SERVICE]: "additionalServices",
};

export enum StatusSemaphore {
  pending = "pending",
  inProcess = "inProcess",
  complete = "complete",
  omitted = "omitted",
}

export const CARD_BRANDS: { value: string; img: string }[] = [
  { value: "visa", img: VisaIcon },
  { value: "masterCard", img: MastercardIcon },
  { value: "americanexpress", img: AmericanExpressIcon },
  { value: "diners", img: DinersIcon },
  { value: "discover", img: DiscoverIcon },
  { value: "jcb", img: JCB },
  { value: "amex", img: Amex },
  { value: "alia", img: Alia },
  { value: "carnet", img: CarnetIcon },
];

export enum PayInOutPopupConstants {
  PAY_IN = "Pay in",
  PAY_OUT = "Pay out",
  CARD = "card",
  TRANSFER = "transfer",
  TRANSFER_PAY_OUT = "payouts-transfer",
  CASH_PAY_OUT = "payouts-cash",
  RECURRENT_DEBIT = "transfer-subscriptions",
  CASH = "cash",
  CARD_TITLE = "Tarjeta",
  TRANSFER_TITLE = "Transferencia",
  RECURRENT_DEBIT_TITLE = "Débito recurrente",
  CASH_TITLE = "Efectivo",
}

export const mapMerchantUser = (merchantUsers: MerchantUsers): IUser[] => {
  const users: User[] = get(merchantUsers, "Users", []);

  if (!users || users.length === 0) return [];

  return users.map((user: User) => {
    const email = user.Attributes.filter(
      (attribute: UserAttributes) => attribute.Name === "email"
    )
      .map((attributes: UserAttributes) => attributes.Value)
      .join("");
    const name = user.Attributes.filter(
      (attribute: UserAttributes) =>
        attribute.Name === "name" || attribute.Name === "family_name"
    )
      .map((attributes: UserAttributes) => attributes.Value)
      .join(" ");

    const roles: string[] = user.Groups.map(
      (group: UserGroups) => group.GroupName
    );

    return {
      name,
      email,
      roles,
    };
  });
};

export const getRoles = (roles: string[]) => {
  return roles.map((role: string) => BackofficeRolesEnum[role]).join(",");
};

const getStatusSemaphoreByProp = (
  semaphore: SemaphoreData,
  parentProp: string,
  childProp?: string
) => {
  if (childProp)
    return get(
      semaphore,
      `${parentProp}.${childProp}`,
      StatusSemaphore.pending
    );

  return get(semaphore, parentProp, StatusSemaphore.pending);
};

const isStatusCompleteOrOmitted = (status: string) => {
  return [StatusSemaphore.complete, StatusSemaphore.omitted].includes(
    status as StatusSemaphore
  );
};

export const getStepProcessorsStatus = (
  semaphore: SemaphoreData
): StatusSteps => {
  const statusProcessor: string = getStatusSemaphoreByProp(
    semaphore,
    "stepProcessor",
    "statusProcessor"
  );
  const statusMerchantRules: string = getStatusSemaphoreByProp(
    semaphore,
    "stepProcessor",
    "statusMerchantRules"
  );
  const statusDeferred: string = getStatusSemaphoreByProp(
    semaphore,
    "stepProcessor",
    "statusDeferred"
  );
  const statusRetryRules: string = getStatusSemaphoreByProp(
    semaphore,
    "stepProcessor",
    "statusRetryRules"
  );

  if (
    statusProcessor === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.inProcess;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    statusMerchantRules === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.inProcess;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    statusDeferred === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.inProcess;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    statusRetryRules === StatusSemaphore.pending
  )
    return StatusSemaphore.inProcess;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    statusMerchantRules === StatusSemaphore.pending &&
    statusDeferred === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.inProcess;

  if (
    statusProcessor === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    statusDeferred === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.inProcess;

  if (
    statusProcessor === StatusSemaphore.pending &&
    statusMerchantRules === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.inProcess;

  if (
    statusProcessor === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    statusRetryRules === StatusSemaphore.pending
  )
    return StatusSemaphore.inProcess;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    statusMerchantRules === StatusSemaphore.pending &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    statusRetryRules === StatusSemaphore.pending
  )
    return StatusSemaphore.inProcess;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    statusDeferred === StatusSemaphore.pending &&
    statusRetryRules === StatusSemaphore.pending
  )
    return StatusSemaphore.inProcess;

  if (
    statusProcessor === StatusSemaphore.pending &&
    statusMerchantRules === StatusSemaphore.pending &&
    statusDeferred === StatusSemaphore.pending &&
    statusRetryRules === StatusSemaphore.pending
  )
    return StatusSemaphore.pending;

  if (
    isStatusCompleteOrOmitted(statusProcessor) &&
    isStatusCompleteOrOmitted(statusMerchantRules) &&
    isStatusCompleteOrOmitted(statusDeferred) &&
    isStatusCompleteOrOmitted(statusRetryRules)
  )
    return StatusSemaphore.complete;

  return StatusSemaphore.inProcess;
};

export enum MerchantSuppliers {
  CYBERSOURCE = "Cybersource",
  KUSHKI = "Kushki",
}

export enum MerchantAuthenticationType {
  ThreeDS = "3DS",
  OTP = "OTP",
}

export interface ISupplier {
  name: string;
  authenticationType: string[];
}

export const suppliers: ISupplier[] = [
  {
    name: MerchantSuppliers.CYBERSOURCE,
    authenticationType: [MerchantAuthenticationType.ThreeDS],
  },
  {
    name: MerchantSuppliers.KUSHKI,
    authenticationType: [MerchantAuthenticationType.OTP],
  },
];

export interface CategoryItem {
  text: string;
  onSelect: () => void;
  id: string;
  variant: string;
}

export enum InitState {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
