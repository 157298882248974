import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";

export const searchMerchants = createAsyncThunk<
  SearchMerchantResponse,
  SearchMerchantRequest
>("customer/searchMerchant", async (request) => {
  const response = await axios.post<SearchMerchantResponse>(
    API_ROUTES.MERCHANT_NODE_SEARCH,
    request
  );

  return response.data;
});
