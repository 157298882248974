/* eslint-disable no-trailing-spaces */

import React, { SVGProps } from "react";

const SvgDiners = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="img"
    aria-label="diners card icon"
    width="1em"
    height="1em"
    viewBox="0 0 35 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clipDiners)">
      <path
        d="M34.678 22.572a2.258 2.258 0 01-.682 1.59c-.43.42-1.008.65-1.61.644H3.167c-.6.007-1.18-.225-1.61-.644a2.258 2.258 0 01-.681-1.59V2.428A2.256 2.256 0 012.297.355a2.27 
        2.27 0 01.87-.162h29.22a2.27 2.27 0 012.109 1.372c.117.273.179.566.182.863v20.144z"
        fill="#fff"
      />
      <path
        d="M1.069 2.428v20.144c.007.548.232 1.07.625 1.453.394.383.923.594 1.472.588h29.22a2.075 2.075 0 001.472-.587c.393-.383.618-.906.625-1.454V2.428a2.065 2.065 0 00-.625-1.453 
        2.075 2.075 0 00-1.472-.587H3.166a2.076 2.076 0 00-1.472.587 2.066 2.066 0 00-.625 1.453zM3.166 25a2.464 2.464 0 01-1.746-.701 2.453 2.453 0 
        01-.74-1.727V2.428c.008-.65.274-1.271.74-1.727A2.466 2.466 0 013.166 0h29.22a2.465 2.465 0 011.746.7c.467.456.733 1.077.74 1.728v20.144a2.454 2.454 0 01-.74 
        1.727 2.466 2.466 0 01-1.746.7H3.166z"
        fill="#004A98"
      />
      <path
        d="M4.584 17.966c0 .473.339.528.64.528 1.33 0 1.766-1 1.766-1.914 0-1.148-.738-1.978-1.925-1.978-.254 
        0-.37.02-.48.025v3.34zm-.608-2.878c0-.553-.289-.516-.565-.522v-.153c.24.011.485.011.726.011.24 0 .608-.011 1.065-.011 1.593 0 2.46 1.06 2.46 2.149 0 
        .608-.357 2.136-2.534 2.136-.313 0-.603-.012-.887-.012-.284 0-.547 0-.824.012v-.159c.369-.038.548-.05.565-.468l-.006-2.983zM8.538 14.94a.302.302 0 01-.213-.513.302.302 0 
        01.515.207.306.306 0 01-.302.306zm-.658 3.592h.117c.172 0 .295 0 .295-.204v-1.663c0-.27-.092-.308-.32-.429v-.099c.289-.085.633-.202.659-.22a.22.22 0 01.11-.031c.03 0 .043.037.043.086v2.36c0 
        .204.136.204.308.204h.104v.16c-.21 0-.425-.014-.645-.014-.22 0-.444 0-.67.013v-.163zM9.784 16.702c0-.227-.068-.288-.357-.405v-.114c.275-.084.547-.183.812-.295.019 0 
        .037.012.037.06v.4c.35-.252.652-.46 1.066-.46.522 0 .705.38.705.86v1.584c0 .204.137.204.308.204h.112v.159c-.217 0-.43-.013-.653-.013-.222 0-.443 0-.665.013v-.16h.112c.172 0 
        .295 0 .295-.203V16.74c0-.35-.214-.522-.565-.522-.198 0-.507.16-.715.295v1.82c0 .204.135.204.308.204h.109v.159c-.216 0-.43-.013-.653-.013-.223 0-.442 0-.664.013v-.16h.112c.172 
        0 .295 0 .295-.203l.001-1.63zM14.024 16.8c.154 0 .172-.08.172-.153 0-.312-.192-.565-.537-.565-.38 0-.633.275-.707.718h1.072zm-1.09.197a1.497 1.497 0 000 .355c.036.584.413 1.062.904 
        1.062.34 0 .605-.184.832-.41l.086.085c-.284.38-.634.695-1.14.695-.98 0-1.177-.946-1.177-1.34 0-1.203.812-1.558 1.243-1.558.498 0 1.034.312 1.04.963a.646.646 0 010 
        .111l-.055.037h-1.732zM14.866 18.532h.167c.171 0 .294 0 .294-.204v-1.724c0-.19-.226-.228-.319-.277v-.091c.449-.191.695-.35.752-.35.035 0 
        .054.017.054.08v.552h.013c.153-.24.412-.632.787-.632a.336.336 0 01.334.441.299.299 0 01-.272.197c-.191 0-.191-.148-.407-.148a.522.522 0 00-.449.506v1.45c0 
        .204.127.204.296.204h.343v.159c-.338-.006-.595-.013-.86-.013s-.507 0-.733.013v-.163zM17.235 17.838c.08.404.327.748.777.748.362 0 .496-.221.496-.435 
        0-.725-1.34-.491-1.34-1.479 0-.345.277-.786.954-.786.244.006.483.067.7.178l.044.626h-.142c-.061-.387-.276-.608-.67-.608-.246 0-.48.141-.48.405 0 .719 1.427.497 
        1.427 1.46 0 .407-.325.837-1.058.837a1.62 1.62 0 01-.75-.21l-.069-.706.11-.03zM24.557 15.517h-.153c-.118-.717-.634-1.012-1.317-1.012-.708 0-1.736.473-1.736 1.946 0 
        1.24.887 2.13 1.834 2.13a1.253 1.253 0 001.237-1.062l.142.037-.142.902c-.254.16-.953.327-1.36.327-1.44 0-2.35-.927-2.35-2.309 0-1.264 1.125-2.16 2.332-2.16.498 0 
        .978.159 1.451.326l.062.875zM24.78 18.532h.116c.172 0 .295 0 .295-.204v-3.413c0-.4-.091-.411-.326-.48v-.097c.219-.07.43-.158.634-.264a.67.67 0 01.134-.068c.038 0 
        .051.038.051.087v4.24c0 .203.136.203.308.203h.104v.16c-.21 0-.425-.014-.646-.014-.222 0-.443 0-.67.013v-.163zM28.73 18.354c0 .11.066.116.172.116.073 0 
        .166-.006.245-.006v.126c-.3.043-.596.107-.887.191l-.03-.019v-.497c-.369.301-.653.516-1.09.516-.332 0-.677-.215-.677-.73v-1.57c0-.16-.024-.313-.369-.343v-.117c.222 0 
        .714-.043.794-.043.08 0 .068.043.068.179v1.582c0 .185 0 .712.535.712.21 0 .487-.159.744-.373v-1.652c0-.127-.295-.19-.516-.253v-.11c.554-.038.899-.086.96-.086.06 0 
        .049.043.049.11l.001 2.267zM29.956 17.997a.66.66 0 00.634.632c.659 0 .935-.644.935-1.19 0-.663-.507-1.216-.983-1.216-.229 0-.42.148-.586.29v1.484zm0-1.67c.246-.208.58-.442.918-.442.713 
        0 1.14.621 1.14 1.29 0 .804-.59 1.608-1.471 1.608-.299 
        0-.592-.074-.855-.215l-.183.143-.127-.067c.057-.361.086-.726.086-1.092v-2.643c0-.399-.092-.41-.326-.479v-.097c.218-.07.43-.158.633-.264a.66.66 0 01.137-.069c.037 0 
        .048.038.048.088v2.239zM3.408 22.26h.047c.12 0 .254-.016.254-.19v-1.75c0-.174-.127-.191-.254-.191h-.047v-.1c.127 0 .332.014.497.014.165 0 
        .368-.014.525-.014v.1h-.047c-.12 0-.247.017-.247.19v1.75c0 .175.126.191.247.191h.047v.1c-.161 0-.362-.012-.529-.012-.167 0-.362.012-.493.012v-.1z"
        fill="#1D1D1B"
      />
      <path
        d="M4.416 22.346v-.07h-.033c-.12 0-.263-.022-.263-.207v-1.75c0-.184.144-.205.263-.205h.033v-.07c-.155 0-.349.012-.507.012-.159 0-.353-.012-.482-.012v.07h.032c.119
        0 .262.02.262.205v1.75c0 .185-.143.206-.262.206h-.032v.07c.127 0 .318-.013.478-.013.16 0 .356.013.515.014h-.004zm.017.028c-.162 0-.362-.013-.528-.013-.166
        0-.361.013-.493.013h-.014v-.127h.06c.128 0 .233-.012.234-.175v-1.753c0-.163-.111-.173-.233-.175h-.06v-.127h.013c.127 0 .332.013.497.013.165 0
        .366-.013.524-.013h.016v.127h-.062c-.127 0-.232.012-.234.175v1.75c0 .163.112.173.234.176h.062v.126l-.016.003zM6.73 21.703l.006-.008v-1.252a.275.275 0
        00-.291-.314H6.37v-.1c.159 0 .312.014.47.014.136 0 .274-.014.411-.014v.1h-.05c-.141 0-.298.026-.298.424v1.518c-.002.112.004.225.02.337h-.127L5.074 20.49v1.382c0
        .29.057.39.314.39h.057v.1c-.143 0-.288-.012-.431-.012-.143 0-.305.013-.456.013v-.1h.052c.232 0 .301-.157.301-.424v-1.41a.299.299 0 00-.304-.3h-.044v-.1c.127 0
        .253.014.38.014.1 0 .198-.014.298-.014l1.489 1.674z"
        fill="#1D1D1B"
      />
      <path
        d="M6.73 21.702l.01-.01-.01.01zm.066.693h.111a2.21 2.21 0 01-.017-.322v-1.517c0-.401.168-.439.313-.439h.034v-.07c-.127 0-.264.012-.397.012-.153
      0-.303-.013-.454-.013v.071h.059a.293.293 0 01.306.329v1.27l-.01.01-1.498-1.682c-.098 0-.196.012-.297.012-.127 0-.254-.012-.37-.012v.07h.03a.315.315 0
      01.318.315v1.407c0 .267-.075.438-.316.44h-.036v.071c.144 0 .294-.014.44-.014.145 0 .28.013.418.014v-.07h-.043c-.264 0-.329-.116-.329-.407v-1.414l1.738
      1.94zm.127.03h-.139L5.086 20.53v1.342c0 .29.048.38.3.38h.073v.126h-.015c-.145 0-.29-.013-.433-.013-.143 0-.302.013-.455.013h-.015v-.127h.069c.221 0
      .285-.143.287-.408v-1.414a.285.285 0 00-.29-.285h-.06v-.127h.016c.127 0 .253.013.38.013.099 0 .196-.013.31-.008l1.47
      1.653v-1.232c0-.265-.18-.297-.277-.3h-.087v-.126h.014c.158 0 .313.013.468.013.136 0 .274-.013.412-.013h.018v.127h-.065c-.138
      0-.28.015-.285.41v1.516c-.002.112.005.224.02.335v.017l-.018.002zM7.754 20.196c-.254
      0-.261.06-.312.304h-.1c.013-.093.03-.187.04-.284.013-.094.02-.188.02-.283h.08c.027.1.11.096.202.096H9.41c.091 0 .175 0
      .181-.104l.08.014c-.012.09-.026.18-.035.27-.01.092 0 .18 0 .271l-.102.037c0-.127-.022-.321-.253-.321h-.55v1.777c0 .253.118.287.279.287h.063v.1c-.127
      0-.365-.012-.546-.012-.181 0-.435.012-.565.012v-.1h.07c.184 0 .277-.016.277-.28v-1.784h-.555z"
        fill="#1D1D1B"
      />
      <path
        d="M9.073 22.378c-.127 0-.366-.013-.546-.013-.2 0-.435.013-.565.013h-.016v-.127h.085c.185 0 .254 0 .263-.265v-1.77h-.54v-.03h.562v1.799c0
      .268-.107.296-.291.296h-.05v.07c.127 0 .358-.014.552-.014.194 0 .398.013.53.014v-.07H9.01c-.163 0-.293-.04-.293-.303v-1.797h.564c.224 0
      .253.192.261.316l.072-.027c0-.087 0-.174.007-.263.006-.088.021-.17.034-.253l-.05-.007c-.016.096-.11.102-.195.1H7.666c-.078 0-.164
      0-.195-.096h-.054c-.001.09-.008.18-.02.27-.01.092-.026.18-.037.27h.07c.045-.235.073-.31.324-.307v.03c-.254
      0-.244.046-.298.293v.011h-.127v-.016c.014-.094.03-.187.04-.285.015-.093.021-.186.021-.28v-.016h.107v.01c.022.085.084.085.17.088H9.41c.094 0 .161 0
      .167-.091v-.015h.016l.095.015v.015c-.014.09-.027.18-.037.27-.01.089 0 .177 0 .268v.01h-.01l-.118.044v-.02c-.009-.126-.022-.307-.233-.307h-.544v1.765c0
      .253.104.27.264.272h.077v.127l-.014.006zM9.78 22.26h.046c.127 0 .253-.016.253-.19v-1.75c0-.174-.126-.191-.253-.191h-.047v-.1c.204 0 .553.014.833.014.28 0
      .633-.014.858-.014 0 .144 0 .364.006.506l-.1.026c-.017-.216-.057-.39-.408-.39h-.465v.874h.398c.2 0 .244-.112.264-.293h.1c0 .126-.01.26-.01.39s0
      .253.01.38l-.1.02c-.02-.2-.03-.33-.26-.33h-.402V22c0 .217.194.217.408.217.402 0 .579-.027.68-.407l.093.023a7.983 7.983 0 00-.114.53c-.214 0-.6-.012-.9-.012-.3
      0-.7.013-.887.013l-.004-.103z"
        fill="#1D1D1B"
      />
      <path
        d="M11.557 22.346c.03-.17.069-.338.11-.506l-.067-.016c-.1.373-.294.407-.69.404-.21 0-.421 0-.422-.232v-.793h.415c.235 0 .254.14.275.329l.072-.015a7.338 7.338 0
        01-.01-.37c0-.122 0-.252.01-.379h-.072c-.019.174-.073.297-.277.295h-.413v-.904h.48c.348 0 .405.177.42.387l.073-.02c0-.094-.01-.216-.01-.327v-.158c-.227
        0-.567.013-.843.013s-.614-.013-.819-.013v.07h.033c.12 0 .261.021.263.206v1.75c0 .184-.144.204-.263.206h-.029v.07c.195 0 .583-.014.877-.014.294 0
        .672.013.887.014v.003zm.013.025c-.214 0-.6-.012-.9-.012-.3 0-.7.012-.887.012h-.014v-.126h.06c.127 0
        .233-.013.234-.176v-1.75c0-.163-.112-.173-.233-.175h-.061v-.127h.014c.204 0 .552.013.833.013.28 0 .633-.013.857-.013h.014v.187c0 .115 0
        .242.01.338v.011h-.01l-.119.03v-.017c-.02-.216-.05-.38-.394-.38h-.457v.846h.38c.192 0 .227-.1.253-.28v-.014h.127v.015c0 .126-.01.26-.01.39s0
        .253.01.38v.011h-.013l-.116.023v-.015c-.023-.205-.024-.316-.254-.319h-.38v.759c0 .203.176.202.394.203.403 0 .564-.022.666-.397v-.014h.014l.106.028v.014a7.885
        7.885 0 00-.114.53v.011l-.01.014zM12.565 21.157h.163c.335 0 .516-.126.516-.52a.455.455 0 00-.49-.488 1.53 1.53 0
        00-.19.014v.994zm-.429-.774c0-.244-.134-.253-.238-.253h-.065v-.1c.108 0 .316.014.52.014.204 0 .361-.014.539-.014.42 0 .797.114.797.588 0
        .3-.202.484-.465.588l.571.854a.38.38 0 00.326.201v.1c-.112 0-.218-.012-.328-.012-.11 0-.212.012-.315.012a9.205 9.205 0 01-.697-1.082h-.216v.72c0
        .252.126.266.273.266h.061v.1c-.191 0-.38-.013-.576-.013-.16 0-.319.013-.486.013v-.1h.061c.127 0 .238-.057.238-.18v-1.703z"
        fill="#1D1D1B"
      />
      <path
        d="M12.728 21.144c.33 0 .498-.12.507-.506a.445.445 0 00-.475-.474c-.06
        0-.118.005-.176.012v.969l.144-.001zm-.163.027h-.016V20.15h.013c.064-.008.128-.012.193-.013a.474.474 0 01.506.506c0 .4-.192.534-.53.534l-.166-.005zm0
        .093h.228v.007c.206.372.435.73.685 1.075.102 0 .21-.014.313-.014.104 0 .21.013.315.014v-.073a.38.38 0 01-.322-.205l-.583-.87h.016c.262-.104.456-.282.456-.575
        0-.464-.36-.572-.781-.573-.177 0-.338.013-.54.013-.2 0-.395-.013-.506-.013v.07h.047c.104 0 .253.018.253.269v1.697c0 .135-.126.195-.253.195h-.047v.071c.16 0
        .314-.014.471-.014.188 0 .38.013.562.014v-.07h-.046c-.152 0-.289-.02-.289-.284v-.728l.02-.006zm1.56 1.114c-.113 0-.22-.013-.33-.013-.11 0-.209.013-.326.007a9.59
        9.59 0 01-.693-1.075h-.198v.701c0 .253.106.253.26.253h.075v.127h-.015c-.192 0-.38-.013-.576-.013-.16 0-.316.013-.485.013h-.014v-.127h.075c.12 0
        .222-.053.223-.166v-1.703c0-.236-.12-.235-.223-.239h-.075v-.126h.01c.11 0 .316.013.52.013.204 0 .36-.013.539-.013.421 0 .81.116.812.602 0
        .3-.2.49-.459.594l.563.84a.358.358 0 00.314.194h.013v.127l-.01.004zM16.396 21.703v-1.26a.276.276 0 00-.292-.314h-.072v-.1c.157 0 .31.014.468.014.157 0
        .273-.014.413-.014v.1h-.05c-.142 0-.299.026-.299.424v1.518c-.002.112.004.225.02.337h-.128l-1.723-1.918v1.382c0 .29.056.39.314.39h.057v.1c-.144
        0-.287-.012-.432-.012-.144 0-.305.013-.455.013v-.1h.046c.232 0 .303-.157.303-.424v-1.41a.3.3 0 00-.306-.3h-.043v-.1c.127 0 .254.014.38.014.1 0
        .197-.014.298-.014l1.5 1.674z"
        fill="#1D1D1B"
      />
      <path
        d="M16.403 21.702l.012-.01-.012.01zm.067.693h.11a2.199 2.199 0 01-.017-.322v-1.517c0-.401.17-.439.313-.439h.027v-.07c-.127 0-.265.012-.398.012-.154
        0-.303-.013-.454-.013v.071h.058a.294.294 0 01.307.329v1.27l-.01.01-1.49-1.679c-.1 0-.197.013-.297.013-.127 0-.254-.013-.371-.013v.071h.029a.315.315 0
        01.318.315v1.406c0 .268-.075.439-.316.44h-.038v.07c.145 0 .294-.013.441-.013.147 0 .279.012.417.014v-.071h-.042c-.263 0-.33-.115-.33-.406v-1.417l1.743
        1.94zm.127.03h-.137l-1.7-1.89v1.337c0 .29.049.38.301.38h.067v.126h-.015c-.144 0-.288-.013-.43-.013-.144 0-.305.013-.456.013h-.015v-.127h.06c.223 0
        .286-.143.289-.41V20.43a.286.286 0 00-.29-.285h-.059v-.127h.015c.127 0 .254.013.38.013.098 0 .196-.013.307-.01l1.482
        1.652v-1.229c0-.265-.18-.298-.278-.3h-.087v-.126h.014c.158 0 .313.013.468.013.156 0 .274-.013.412-.013h.016v.127h-.065c-.138
        0-.281.015-.284.41v1.516c-.002.112.004.224.02.335v.017l-.015.002zM17.947 20.403h-.008l-.31.946h.626l-.308-.946zm-.49 1.48a1.374 1.374 0 00-.073.253c0
        .101.14.118.254.118h.036v.1a7.186 7.186 0 00-.405-.013c-.136 0-.24 0-.361.013v-.1h.02a.304.304 0
        00.291-.218l.535-1.532c.053-.135.096-.273.127-.415.106-.038.208-.086.304-.144a.101.101 0 01.027-.006c.004-.001.01 0 .013.001a.023.023 0 01.01.009l.03.083.617
        1.748c.04.116.08.24.127.34a.212.212 0 00.222.134h.02v.1a9.147 9.147 0 00-.463-.013c-.16 0-.332 0-.506.013v-.1h.036c.078 0 .21-.014.21-.098a1.051 1.051 0
        00-.07-.24l-.126-.387h-.76l-.114.354z"
        fill="#1D1D1B"
      />
      <path
        d="M17.932 20.408h.009-.01zm-.284.925h.587l-.292-.895-.295.895zm-.04.03l.321-.976h.018v.016-.015h.01l.318.974h-.666zm.685.982c.166-.007.328-.013.488-.013.16 0
        .301.006.447.013v-.07a.23.23 0 01-.236-.143c-.043-.1-.083-.225-.126-.342l-.617-1.746-.028-.081a.07.07 0 00-.02 0 1.182 1.182 0 01-.299.133 2.726 2.726 0
        01-.126.417l-.535 1.533a.321.321 0 01-.305.228v.07c.114-.007.23-.013.347-.013.116 0 .26.006.389.012v-.07h-.022c-.109
        0-.26-.013-.266-.132.017-.09.041-.179.075-.264h.014-.014l.11-.357h.78l.135.396c.032.079.055.161.067.245 0
        .103-.145.11-.221.113h-.033l-.004.07zm.95.029c-.152-.007-.303-.013-.462-.013-.16 0-.331 0-.507.013h-.015v-.127h.05c.079 0 .193-.017.194-.082a1.056 1.056 0
        00-.067-.235l-.127-.38h-.739l-.104.338a1.293 1.293 0 00-.073.253c0 .082.127.103.237.103h.052v.126h-.015a8.582 8.582 0 00-.405-.013c-.133 0-.24
        0-.36.013h-.016v-.127h.034a.288.288 0 00.278-.207l.536-1.532c.052-.139.096-.28.133-.424.106-.037.208-.085.304-.142a.073.073 0 01.032-.008.038.038 0
        01.037.019l.03.083.616 1.747c.04.118.08.24.127.34a.195.195 0 00.207.126h.034v.126l-.012.003zM19.39 20.196c-.253
        0-.262.06-.311.304h-.102c.014-.093.03-.187.04-.284.015-.094.021-.188.021-.283h.081c.026.1.11.096.2.096h1.728c.09 0 .173 0
        .181-.104l.08.014c-.013.09-.027.18-.037.27-.01.092-.008.18-.008.271l-.1.037c0-.127-.022-.321-.253-.321h-.549v1.777c0 .253.117.287.278.287h.063v.1c-.127
        0-.364-.012-.545-.012-.201 0-.436.012-.566.012v-.1h.063c.185 0 .279-.016.279-.28v-1.784h-.543z"
        fill="#1D1D1B"
      />
      <path
        d="M20.705 22.378c-.127 0-.367-.013-.545-.013-.2 0-.435.013-.567.013h-.015v-.127h.079c.185 0 .26 0 .263-.265v-1.77h-.533v-.03h.562v1.799c0
      .268-.107.294-.292.296h-.05v.07c.127 0 .358-.014.553-.014.175 0 .398.013.53.014v-.07h-.049c-.16 0-.291-.04-.291-.303v-1.797h.563c.224 0
      .253.192.26.316l.073-.027c0-.087 0-.174.008-.262.007-.087.021-.171.034-.252l-.051-.01c-.015.097-.11.103-.194.102h-1.741c-.079 0-.164
      0-.194-.097h-.05c-.002.09-.009.18-.022.27-.01.092-.026.18-.038.27h.072c.044-.235.073-.31.324-.307v.03c-.253
      0-.243.046-.297.293v.011h-.127v-.016c.014-.094.03-.187.039-.283.014-.094.02-.188.02-.282v-.016h.107v.01c.024.085.085.085.17.088h1.74c.093 0 .162 0
      .167-.091v-.015h.015l.095.015v.015c-.014.09-.026.18-.037.27-.01.089-.006.177-.006.268v.01h-.01l-.118.044v-.02c-.009-.126-.021-.307-.233-.307h-.535v1.765c0
      .253.104.27.264.272h.077v.127l-.02.006zM21.43 22.26h.048c.127 0 .253-.016.253-.19v-1.75c0-.174-.127-.191-.253-.191h-.047v-.1c.127 0 .332.014.495.014.164 0
      .37-.014.528-.014v.1h-.049c-.12 0-.247.017-.247.19v1.75c0 .175.127.191.247.191h.049v.1c-.161 0-.363-.012-.53-.012-.167 0-.361.012-.493.012v-.1z"
        fill="#1D1D1B"
      />
      <path
        d="M22.438 22.346v-.07h-.031c-.127 0-.263-.022-.263-.207v-1.75c0-.184.142-.205.263-.205h.031v-.07c-.154
        0-.348.012-.506.012-.159 0-.352-.012-.48-.012v.07h.032c.118 0 .261.02.261.205v1.75c0 .185-.143.206-.26.206h-.034v.07c.127 0 .318-.013.478-.013.16 0
        .356.013.515.014h-.006zm.02.028c-.161 0-.362-.013-.53-.013-.166 0-.36.013-.492.013h-.014v-.127h.062c.12 0
        .232-.012.232-.175v-1.753c0-.163-.111-.173-.232-.175h-.062v-.127h.014c.127 0 .333.013.495.013.163 0 .368-.013.528-.013h.012v.127h-.06c-.126
        0-.232.012-.234.175v1.75c0 .163.112.175.235.176h.06v.126l-.013.003zM23.839 22.264c.649 0 .76-.572.76-1.06 0-.488-.264-1.078-.82-1.078-.585 0-.76.52-.76.968 0
        .598.275 1.17.817 1.17h.003zm-.053-2.285c.713 0 1.282.441 1.282 1.152a1.223 1.223 0 01-.772 1.193 1.232 1.232 0 01-.495.086c-.711 0-1.254-.481-1.254-1.2a1.211
        1.211 0 01.76-1.142c.152-.061.313-.091.476-.089"
        fill="#1D1D1B"
      />
      <path
        d="M23.008 21.095c0-.45.176-.984.774-.984.568 0
        .834.603.834 1.094 0 .49-.116 1.072-.777 1.072v-.026c.634 0 .746-.556.748-1.044 0-.488-.261-1.063-.805-1.064-.574 0-.744.505-.747.954 0 .594.274 1.153.804
        1.154v.028c-.556 0-.831-.581-.831-1.182v-.002zm-.47.119a1.23 1.23 0 011.25-1.247v.029a1.207 1.207 0 00-1.222 1.218c0 .71.537 1.184 1.24 1.184a1.217 1.217 0
        001.171-.776 1.21 1.21 0 00.08-.488c0-.703-.562-1.138-1.268-1.138v-.03c.719 0 1.296.447 1.297 1.168a1.238 1.238 0 01-.78 1.205 1.246 1.246 0 01-.499.088c-.717
        0-1.267-.487-1.267-1.213M27.326 21.703l.008-.008v-1.252a.276.276 0 00-.293-.314h-.072v-.1c.157 0 .312.014.468.014.156 0 .275-.014.413-.014v.1h-.048c-.14
        0-.298.026-.298.424v1.518a2.06 2.06 0 00.02.337h-.127l-1.724-1.918v1.382c0 .29.057.39.314.39h.058v.1c-.144 0-.288-.012-.432-.012-.144
        0-.304.013-.455.013v-.1h.047c.23 0 .3-.157.3-.424v-1.41a.299.299 0 00-.304-.3h-.043v-.1c.127 0 .253.014.38.014.102 0 .198-.014.298-.014l1.49 1.674z"
        fill="#1D1D1B"
      />
      <path
        d="M27.326 21.702l.012-.01-.012.01zm.068.693h.11a2.412 2.412 0
        01-.018-.322v-1.517c0-.402.169-.439.313-.439h.036v-.07c-.127 0-.264.012-.398.012-.135 0-.302-.013-.454-.013v.071h.058a.291.291 0
        01.306.329v1.27l-.012.01-1.49-1.679c-.099 0-.196.013-.298.013-.127 0-.253-.013-.37-.013v.071h.03a.316.316 0 01.319.315v1.407c0
        .267-.075.438-.316.439h-.033v.07c.145 0 .294-.013.441-.013.147 0 .279.012.417.014v-.071h-.043c-.262 0-.328-.115-.328-.406v-1.417l1.73
        1.94zm.126.03h-.139l-1.698-1.89v1.337c0 .29.048.38.299.38h.072v.126h-.014c-.144 0-.289-.013-.432-.013-.143 0-.304.013-.456.013h-.012v-.127h.063c.22 0
        .283-.143.286-.408v-1.414a.283.283 0 00-.29-.285h-.059v-.127h.014c.127 0 .26.013.38.013.1 0 .197-.013.31-.008l1.471
        1.653v-1.232c0-.265-.18-.297-.277-.3h-.088v-.126h.015c.157 0 .312.013.468.013.137 0 .274-.013.413-.013h.014v.127h-.058c-.138
        0-.28.016-.284.41v1.516c-.002.112.004.224.019.335v.017l-.017.002zM28.876 20.402l-.314.946h.626l-.312-.945zm-.489 1.481a1.373 1.373 0 00-.073.253c0
        .101.14.117.253.117h.038v.1a7.26 7.26 0 00-.405-.012c-.133 0-.241 0-.361.012v-.1h.019a.305.305 0
        00.291-.217l.54-1.526c.053-.135.096-.273.127-.415.106-.038.208-.086.305-.144a.102.102 0 01.027-.006c.01 0 .016 0 .023.01.006.01.02.057.03.083l.616
        1.748c.04.116.08.24.127.34a.212.212 0 00.221.134h.02v.1a9.064 9.064 0 00-.462-.013c-.16 0-.332 0-.507.013v-.1h.038c.076 0 .208-.014.208-.098a1.051 1.051 0
        00-.069-.24l-.126-.387h-.76l-.12.348z"
        fill="#1D1D1B"
      />
      <path
        d="M28.862
        20.408h.008-.008zm-.283.925h.585l-.291-.893-.294.893zm-.041.03l.322-.975h.016v.015-.015h.01l.317.974h-.665zm.684.982c.165-.007.329-.013.488-.013.16 0
        .302.006.448.013v-.07h-.007a.23.23 0 01-.234-.143c-.043-.1-.084-.225-.127-.341l-.617-1.747-.028-.08h-.006a.055.055 0 00-.02 0 1.277 1.277 0 01-.303.138 3.081
        3.081 0 01-.127.417l-.538 1.534a.318.318 0 01-.304.227v.07c.116-.007.231-.013.348-.013.116 0 .26.006.39.013v-.07h-.023c-.109
        0-.261-.014-.266-.133.017-.09.042-.178.075-.264h.014-.014l.11-.356h.78l.135.395c.032.08.055.162.067.246 0 .102-.146.11-.22.112h-.023l.002.065zm.95.029c-.151
        0-.302-.013-.462-.013-.16 0-.33.008-.507.013h-.007v-.127h.052c.077 0 .192-.017.192-.082a.992.992 0 00-.067-.235l-.127-.38h-.738l-.104.338a1.365 1.365 0
        00-.073.253c0 .082.127.103.236.103h.05v.126h-.013c-.134 0-.271-.013-.404-.013s-.241.008-.362.013h-.015v-.126h.035a.29.29 0
        00.277-.208l.536-1.532c.043-.127.103-.29.127-.424a1.81 1.81 0 00.304-.142.071.071 0 01.032-.008.037.037 0 01.036.019l.03.083.617
        1.748c.04.117.08.24.127.34a.195.195 0 00.206.126h.034v.127h-.012zM31.016 22.033c0 .134.093.174.2.188.147.011.295.009.442-.008a.48.48 0 00.322-.18.758.758 0
        00.104-.24h.096c-.036.19-.083.379-.126.567-.295 0-.59-.012-.888-.012-.297 0-.589.012-.887.012v-.1h.057c.127 0
        .254-.016.254-.224V20.32c0-.174-.127-.191-.254-.191h-.045v-.1c.176 0 .35.014.528.014.178 0 .339-.014.507-.014v.1h-.085c-.127 0-.227 0-.227.18l.002 1.724z"
        fill="#1D1D1B"
      />
      <path
        d="M32.045 22.346c.038-.182.082-.36.118-.54h-.069a.748.748 0 01-.102.235.508.508 0 01-.332.187 3 3 0
        01-.254.013c-.078 0-.127 0-.188-.008-.108-.011-.214-.058-.214-.2v-1.724c0-.186.118-.195.242-.195h.07v-.07c-.165 0-.328.012-.494.012-.166
        0-.344-.012-.515-.012v.07h.03c.119 0 .266.02.266.205v1.717c0 .216-.146.24-.267.24h-.031v.07c.289 0 .579-.014.87-.014.29 0 .58.013.871.014h-.001zm.012.028c-.295
        0-.589-.013-.887-.013-.298 0-.589.013-.887.013h-.014v-.127h.068c.126 0 .235-.011.238-.208v-1.72c0-.163-.117-.173-.238-.175h-.061v-.127h.014c.178 0
        .352.013.53.013.177 0 .336-.013.506-.013h.013v.127h-.098c-.126 0-.21 0-.213.165v1.723c0 .127.083.158.188.174.058 0 .127.006.185.006.085 0
        .17-.004.253-.013a.468.468 0 00.312-.174.749.749 0 00.102-.237v-.01h.126v.018c-.038.19-.085.38-.126.568v.01h-.01zM32.083 20.287h.041c.046 0
        .068-.034.068-.09a.065.065 0 00-.041-.071.065.065 0 00-.029-.005h-.04v.166zm-.118.186v-.018c.043 0 .05 0 .05-.032v-.26c0-.037 0-.05-.049-.047v-.014h.18c.062 0
        .12.03.12.094a.11.11 0 01-.084.106l.058.08c.021.031.047.06.077.083v.012h-.068c-.033 0-.062-.07-.127-.162h-.04v.116c0
        .023.01.021.053.028v.018l-.17-.004zm.175.1a.289.289 0 00.29-.34.286.286 0 00-.392-.213.287.287 0 00.102.553zm0-.639a.352.352 0 01.25.6.353.353 0
        11-.386-.574.348.348 0 01.136-.026z"
        fill="#1D1D1B"
      />
      <path
        d="M11.227 8.237c0-1.119.333-2.213.956-3.143a5.684 5.684 0
        018.725-.86A5.654 5.654 0 0122.14 10.4a5.661 5.661 0 01-2.088 2.54 5.681 5.681 0 01-8.393-2.536 5.646 5.646 0 01-.432-2.166z"
        fill="#fff"
      />
      <path
        d="M16.902 13.996c-3.249.015-5.924-2.612-5.924-5.808.004-3.495 2.679-5.912 5.925-5.912h1.52c3.212 0 6.143 2.415 6.143 5.91 0 3.197-2.931
        5.809-6.143 5.809h-1.52zm.013-11.235a5.386 5.386 0 00-2.987.904 5.352 5.352 0 00-.815 8.253 5.382 5.382 0 005.86 1.164 5.374 5.374 0 002.413-1.976 5.355 5.355 0
        00-.667-6.772 5.384 5.384 0 00-3.8-1.573h-.004zM15.7 11.298V4.954a3.404 3.404 0 00-2.187 3.172 3.392 3.392 0 002.187 3.172zm4.625-3.172a3.396 3.396 0
        00-2.188-3.174V11.3a3.407 3.407 0 002.188-3.173z"
        fill="#004A98"
      />
    </g>
    <defs>
      <clipPath id="clipDiners">
        <path fill="#fff" transform="translate(.68)" d="M0 0h34.191v25H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDiners;
