import ChevronLeftIcon from "../../../../assets/icons/ChevronLeftIcon/ChevronLeftIcon";
import ChevronRightIcon from "../../../../assets/icons/ChevronRightIcon/ChevronRightIcon";
import { Pagination, PaginationItem } from "@mui/material";
import { FC } from "react";
import { useTransactionTableFooterPaginationState } from "./state/useTransactionTableFooterPaginationState";
import { transactionTableFooterPaginationStyles } from "./TransactionTableFooterPagination.styles";

const TransactionTableFooterPagination: FC = () => {
  const {
    areTransactionsLoading,
    pageCount,
    handleSetTransactionsPage,
    resetPagination,
    currentPage,
  } = useTransactionTableFooterPaginationState();

  return (
    <Pagination
      color="primary"
      count={areTransactionsLoading && resetPagination ? 0 : pageCount}
      disabled={areTransactionsLoading}
      onChange={(_, page) => handleSetTransactionsPage(page)}
      page={resetPagination ? 1 : currentPage}
      renderItem={(item) => (
        <PaginationItem
          components={{
            next: ChevronRightIcon,
            previous: ChevronLeftIcon,
          }}
          sx={transactionTableFooterPaginationStyles.paginationItem}
          {...item}
        />
      )}
    />
  );
};

export default TransactionTableFooterPagination;
