import { OptionsEnum } from "../../shared/enums/OptionsEnum";

export enum ServiceOptionEnum {
  CARD = "card",
  CASH = "cash",
  TRANSFER = "transfer",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  PAYOUTS_CASH = "payouts-cash",
  PAYOUTS_TRANSFER = "payouts-transfer",
  CHARGEBACK = "card-chargeback",
  CHARGEBACK_VOID = "card-chargeback-void",
  WEBCHECKOUT = "webcheckout",
  VPOS = "vpos",
  SUBSCRIPTIONS = "subscriptions",
}

export const PRODUCTS: Record<string, string> = {
  subscriptions: OptionsEnum.SUBSCRIPTIONS,
  vpos: OptionsEnum.VPOS,
  webcheckout: OptionsEnum.WEBCHECKOUT,
};

export const PAYMENT_METHODS: Record<string, string> = {
  card: OptionsEnum.CARD,
  cash: OptionsEnum.CASH,
  "payouts-cash": OptionsEnum.PAYOUTS_CASH,
  "payouts-transfer": OptionsEnum.PAYOUTS_TRANSFER,
  transfer: OptionsEnum.TRANSFER,
  "transfer-subscriptions": OptionsEnum.TRANSFER_SUBSCRIPTIONS,
};

export const OTHERS: Record<string, string> = {
  "card-chargeback": OptionsEnum.CARD_CHARGEBACK_VOID,
  "card-chargeback-void": OptionsEnum.CARD_CHARGEBACK,
};
