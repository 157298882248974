import { ItemCategoryItem } from "../../components/Filters/ItemCategory/interfaces";
import { EntityNameEnum } from "../enums/EntityNameEnum";

export const DefaultColumns: string[] = [
  "ticket_number",
  "payment_method",
  "approved_transaction_amount",
  "transaction_type",
];

export const listChips: ItemCategoryItem[] = [
  {
    label: "Merchant",
    selected: false,
    value: EntityNameEnum.NA,
  },
  {
    label: "Customer",
    selected: false,
    value: EntityNameEnum.CUSTOMER,
  },
  {
    label: "Branch",
    selected: false,
    value: EntityNameEnum.BRANCH,
  },
];
