import { useForm } from "react-hook-form";
import { BillingForm } from "../../../store/interfaces/billing.interfaces";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect } from "react";
import { get, isEmpty, isNil, omit } from "lodash";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import { InvoiceModeEnum } from "../../../shared/enums/InvoiceModeEnum";
import {
  Branch,
  Request,
} from "../../../../types/update_massive_branch_request";
import {
  buildBillingValuesAndRates,
  getBranchesToUpdate,
  handleCloseModalAndSave,
} from "../../../shared/utils/massiveBranchUtils";
import {
  HANDLE_MODAL_LOADER_WS,
  SET_FOOTER_BUTTON_CLICKED,
  SET_TYPE_REQUEST,
  SHOW_SECTION,
} from "../../../store/reducers/app/app.slice";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { useDispatch } from "react-redux";
import { TypeRequestEnum } from "../../../shared/enums/typeRequestEnum";
import { ConfigIdEnum } from "../../../shared/constants/node_config_enums";
import {
  createNodePaths,
  projectPaths,
} from "../../../shared/enums/projects_path_enum";
import { editStatusEnum } from "../../../shared/constants/massiveEdit/massiveEditEnums";
import { getMerchantNodeInfo } from "../../../store/thunks/app/app.thunks";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";

export const useMassiveBillingContainer = () => {
  const dispatch = useDispatch();
  const {
    form,
    massiveMostCurrentBranches: { billing: nodeInfo },
    merchantNodeInfo,
    getBillingConfigInfo,
    footerButtonsClicked,
    typeRequest: footerButtonType,
    updateMassiveBranchStatus,
    valuesBillingState,
    valuesSubtractBillingState,
    valuesDeclinedBillingState,
    configsResponse,
  } = useAppSelector((state) => ({
    ...state.billing,
    ...state.app,
    ...state.massiveBranch,
  }));

  const {
    control,
    formState: { errors },
    reset,
  } = useForm<BillingForm>({
    defaultValues: form,
  });

  const saveMassiveBillingData = () => {
    const branches: Branch[] = getBranchesToUpdate();
    const dataForm = form;
    const constitutionalCountry = get(
      nodeInfo,
      "generalInfo.constitutionalCountry",
      ""
    );

    const reqUpdateMassiveBranchBilling: Request = {
      branches: branches,
      configId: ConfigIdEnum.CN003,
      data: {
        configId: ConfigIdEnum.CN003,
        constitutionalCountry,
        country: get(nodeInfo, "generalInfo.country", ""),
        ...(constitutionalCountry === CountriesEnum.MEXICO
          ? { ...dataForm }
          : { ...omit(dataForm, ["invoiceMode"]) }),
        valuesBilling: buildBillingValuesAndRates(
          valuesBillingState!,
          valuesSubtractBillingState!,
          valuesDeclinedBillingState!
        ).valuesBilling,
        valuesSubtractBilling: buildBillingValuesAndRates(
          valuesBillingState!,
          valuesSubtractBillingState!,
          valuesDeclinedBillingState!
        ).valuesSubtractBilling,
      },
      status: editStatusEnum.COMPLETE,
      userAlias: localStorage.getItem("username") ?? "backoffice",
      userName: localStorage.getItem("username") ?? "backoffice",
    };

    dispatch(
      updateMassiveBranch(
        reqUpdateMassiveBranchBilling,
        ConfigIdEnum.CN003,
        configsResponse!
      )
    );
    dispatch(HANDLE_MODAL_LOADER_WS(true));
  };

  useEffect(() => {
    if (!isNil(getBillingConfigInfo)) {
      dispatch(SHOW_SECTION(true));
    }
  }, [getBillingConfigInfo]);

  useEffect(() => {
    if (merchantNodeInfo) {
      reset({
        invoiceFrequency: get(
          merchantNodeInfo,
          "invoiceFrequency",
          FrequencyEnum.NONE
        ) as FrequencyEnum,
        invoiceMode: get(
          merchantNodeInfo,
          "invoiceMode",
          InvoiceModeEnum.WITH_IVA
        ) as InvoiceModeEnum,
      });
    }
  }, [merchantNodeInfo]);

  useEffect(() => {
    if (footerButtonsClicked && footerButtonType === TypeRequestEnum.SAVE) {
      saveMassiveBillingData();
      dispatch(HANDLE_MODAL_LOADER_WS(true));
    }

    if (footerButtonsClicked && footerButtonType === TypeRequestEnum.FINISHED) {
      saveMassiveBillingData();
    }
  }, [footerButtonsClicked]);

  useEffect(() => {
    handleCloseModalAndSave(updateMassiveBranchStatus, dispatch);
    if (!isNil(updateMassiveBranchStatus) && !updateMassiveBranchStatus) {
      dispatch(SET_FOOTER_BUTTON_CLICKED(false));
      if (footerButtonType === TypeRequestEnum.FINISHED) {
        window.location.href = projectPaths.CREATE_NODE.concat(
          createNodePaths.BRANCH_EDIT
        );
      }
      dispatch(SET_TYPE_REQUEST(""));
    }
    dispatch(SET_FOOTER_BUTTON_CLICKED(false));
  }, [updateMassiveBranchStatus]);

  useEffect(() => {
    if (isEmpty(nodeInfo)) return;

    dispatch(
      getMerchantNodeInfo({
        configId: ConfigIdEnum.CN003,
        publicMerchantId: nodeInfo!.merchantId,
      })
    );
  }, [nodeInfo]);

  return {
    form: {
      control,
      errors,
    },
  };
};
