import { TableHeaders } from "@kushki/frontend-molecules/backoffice-table";

export const defaultColumnsSelectableManualContingency: string[] = [
  "invoice_id",
  "cycle",
];
export const defaultHeadersManualContingencyHistory: TableHeaders<any>[] = [
  {
    label: "Tax ID",
    id: "tax_id",
    disabled: false,
    view: true,
    orderBy: "tax_id",
    filter: false,
  },
  {
    label: "Razón social",
    id: "social_reason",
    disabled: false,
    view: true,
    orderBy: "social_reason",
    filter: false,
  },
  {
    id: "merchant_name",
    label: "Comercio",
    disabled: false,
    view: false,
    orderBy: "merchant_name",
    filter: true,
  },
  {
    label: "Número de documento",
    id: "invoice_id",
    disabled: false,
    view: true,
    orderBy: "",
    filter: true,
  },
  {
    label: "Ciclo",
    id: "cycle",
    disabled: false,
    view: true,
    orderBy: "",
    filter: true,
  },
  {
    id: "transaction_type",
    label: "Tipo de Transacción",
    view: false,
    disabled: false,
    orderBy: "",
    filter: true,
    filterLabel: "Tipo de Transacción",
  },
  {
    id: "created",
    label: "Fecha de creación",
    view: false,
    disabled: false,
    orderBy: "",
    filter: true,
  },
  {
    label: "Monto total",
    id: "amount",
    disabled: false,
    view: true,
    orderBy: "amount",
    filter: false,
  },
  {
    label: "Tipo de documento",
    id: "kind",
    disabled: false,
    view: true,
    orderBy: "kind",
    filter: false,
  },
];
