import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const uploadActionButtonComponentEcuadorStyle = makeStyles(() =>
  createStyles({
    back: {
      color: "#677784",
    },
  })
);
