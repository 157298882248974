import {
  BasicDataLabels,
  ErrorMessageMX,
  TypeElement,
} from "../BasicStateMX/ErrorMessageMX";
import { RegularExp } from "../../constants/form_labels_legal_details";
import { get, isEmpty } from "lodash";
import { findDocTypeCountry } from "../../utils/findDocTypeCountry_utils";
import { PersonTypeCHIEnum } from "./PersonTypeCHIEnum";
import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "../BasicStateGeneral/FieldsBasicData";
import { ErrorMessageCHL } from "./ErrorMessageCHI";
import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { DocTypeNameEnum } from "../DocTypeNameEnum";
import { ErrorMessageGeneric } from "../BasicStateGeneral/ErrorMessage";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";
import { BasicDataRegularExp } from "../BasicDataRegularExpEnum";

export const PersonTypeCHIList: Array<PersonTypeObjectInterface> = [
  { name: "Jurídica", value: "02" },
  { name: "Natural", value: "01" },
];

export const docTypeChile: PersonTypeObjectInterface[] = [
  { name: DocTypeNameEnum.RUN, value: "0" },
  { name: DocTypeNameEnum.PASAPORTE, value: "1" },
  { default: true, name: DocTypeNameEnum.RUT, value: "2" },
];

export const fieldRenderCHI: Array<PersonTypeInterface> = [
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX150,
        value: 150,
      },
      minLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 1,
      },

      required: {
        message: ErrorMessageGeneric.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: isEmpty(findDocTypeCountry(docTypeChile))
      ? DocTypeNameEnum.RUT
      : get(findDocTypeCountry(docTypeChile), "name"),
    name: FieldsNamesRender.rfc,
    regExp: BasicDataRegularExp.ALFA_NUMERIC_NO_SPACES,
    rules: {
      maxLength: {
        message: ErrorMessageCHL.LIMIT_MAX_ID_CHL,
        value: 9,
      },
      minLength: {
        message: ErrorMessageCHL.LIMIT_MIN_ID_CHL,
        value: 8,
      },
      required: {
        message: ErrorMessageGeneric.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: BasicDataLabels.TIPO_EMPRESA,
    name: FieldsNamesRender.type_company,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    rules: {
      required: {
        message: ErrorMessageGeneric.DATE_PICKER,
        value: true,
      },
    },
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.JURIDICA,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageGeneric.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },

  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: BasicDataLabels.NOMBRE,
    name: FieldsNamesRender.names,
    regExp: RegularExp.DEFAULT,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageGeneric.LETRAS,
        value: new RegExp(RegularExp.DEFAULT),
      },
      required: {
        message: ErrorMessageGeneric.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: BasicDataLabels.APELLIDO,
    name: FieldsNamesRender.surname,
    regExp: RegularExp.DEFAULT,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageGeneric.LETRAS,
        value: new RegExp(RegularExp.DEFAULT),
      },
      required: {
        message: ErrorMessageGeneric.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: isEmpty(findDocTypeCountry(docTypeChile))
      ? DocTypeNameEnum.RUT
      : get(findDocTypeCountry(docTypeChile), "name"),
    name: FieldsNamesRender.rfc,
    regExp: BasicDataRegularExp.ALFA_NUMERIC_NO_SPACES,
    rules: {
      maxLength: {
        message: ErrorMessageCHL.LIMIT_MAX_ID_CHL,
        value: 9,
      },
      minLength: {
        message: ErrorMessageCHL.LIMIT_MIN_ID_CHL,
        value: 8,
      },
      required: {
        message: ErrorMessageGeneric.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 12,
  },
  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 12,
  },
  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    rules: {
      required: {
        message: ErrorMessageGeneric.DATE_PICKER,
        value: true,
      },
    },
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeCHIEnum.NATURAL,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageGeneric.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
];
