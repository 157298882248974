import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.grey[500],
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    paper: {
      backgroundColor: "#F7FAFC !important",
      border: "none",
      borderRadius: "0px !important",
      boxShadow: "none !important",
      padding: theme.spacing(2),
    },
    simpleDialog: {
      "& #buttonAction": {
        color: "#023365 !important",
        fontSize: "16px !important",
        fontWeight: "500 !important",
      },
      "& #dateSection": {
        marginBottom: "30px",
        paddingLeft: "13px",
      },
      "& #dateSection .second": {
        color: "#46525C !important",
        fontSize: "14px !important",
        fontWeight: "400 !important",
      },
      "& #dateSection .title": {
        fontSize: "16px !important",
        fontWeight: "500 !important",
        marginBottom: "15px !important",
        marginLeft: "-13px !important",
      },
      "& #reasonSection .reason": {
        color: "#46525C !important",
        fontSize: "14px !important",
        fontWeight: "400 !important",
        marginLeft: "13px !important",
      },
      "& #reasonSection .title": {
        fontSize: "16px !important",
        fontWeight: "500 !important",
        marginBottom: "15px !important",
      },
      "& .MuiDialogTitle-root": {
        "& h2": {
          display: "flex !important",
          justifyContent: "space-between !important",
        },
        "& h2 button": {
          marginLeft: "10px !important",
          marginTop: "-7px !important",
        },
        "& h2 p.MuiTypography-root.MuiTypography-body1": {
          fontSize: "20px !important",
          fontWeight: "500 !important",
        },
      },
    },
    styleText: {
      color: "#000000 !important",
      left: "0px !important",
      position: "static",
      top: "0px !important",
      width: "516.64px !important",
    },
    tlContainerIcon: {
      display: "flex !important",
      flexDirection: "row",
      justifyContent: "end !important",
      paddingRight: "35px !important",
    },
    tlDateTime: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    tlDetailsLink: {
      "& a": {
        "& p": {
          color: "#023365",
          fontSize: "12px",
          textDecorationLine: "underline",
        },
        "& span": {
          marginLeft: "20px !important",
          overflow: "visible !important",
          width: "auto !important",
        },
        cursor: "pointer",
        marginTop: "-22px",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    tlIcon: {
      display: "flex",
      flexDirection: "column",
      height: "auto!important",
      justifyContent: "center",
      width: "35px !important",
    },
    tlRow: {
      "& p": {
        color: "#46525C !important",
        fontSize: "14px !important",
      },
      "& p.second": {
        color: "#6D7781 !important",
        fontSize: "12px !important",
      },
    },
    tlTitleUser: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  })
);
