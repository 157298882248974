/* istanbul ignore file */

export enum ActionTypes {
  SET_IS_REQUESTING = "[RULES] SET IS REQUESTING",
  SET_REQUEST_ERROR = "[RULES] SET REQUEST ERROR",
  SET_MERCHANT_PROCESSORS = "[RULES] SET MERCHANT PROCESSORS",
  SET_MERCHANT_CREDENTIALS = "[RULES] SET MERCHANT CREDENTIALS",
  SET_BUSINESS_RULE = "[RULES] SET BUSINESS RULE",
  SET_BUSINESS_RULE_LIST = "[RULES] SET_BUSINESS_RULE_LIST",
  SET_IS_LOADING = "[RULES] SET_IS_LOADING",
  SET_IS_LOADING_GET_MERCHANTS = "[RULES] SET_IS_LOADING_GET_MERCHANTS",
  SET_IS_LOADING_GET_PROCESSORS = "[RULES] SET_IS_LOADING_GET_PROCESSORS",
  SET_IS_LOADING_GET_MERCHANT = "[RULES] SET_IS_LOADING_GET_MERCHANT",
  SET_IS_LOADING_GET_RULE = "[RULES] SET_IS_LOADING_GET_RULE",
  SET_IS_LOADING_RULE_BY_TYPE = "[RULES] SET_IS_LOADING_RULE_BY_TYPE",
  SET_IS_LOADING_GET_SUGGESTED_CREDENTIALS = "[RULES] SET_IS_LOADING_GET_SUGGESTED_CREDENTIALS",
  SET_IS_LOADING_WHITELIST = "[RULES] SET_IS_LOADING_WHITELIST",
  SET_NOTIFICATION = "[RULES] SET_NOTIFICATION",
  SET_MERCHANTS = "[RULES] SET_MERCHANTS",
  SET_PROCESSORS = "[RULES] SET_PROCESSORS",
  SET_CURRENT_SECURITY_RULE = "[RULES] SET_CURRENT_SECURITY_RULE",
  SET_CURRENT_MERCHANT = "[RULES] SET_CURRENT_MERCHANT",
  GET_DEFAULT_PROCESSOR = "[RULES] GET_DEFAULT_PROCESSOR",
  SET_RULES_BY_TYPE = "[RULES] SET_RULES_BY_TYPE",
  SET_COMMERCE_RULES = "[RULES] SET_COMMERCE_RULES",
  SET_WHITELIST_RULES = "[RULES] SET_WHITELIST_RULES",
  SET_CURRENT_WHITELIST_RULE = "[RULES] SET_CURRENT_WHITELIST_RULE",
  SET_SHOW_MIGRATION_RULE = "[RULES] SET_SHOW_MIGRATION_RULE",
  GET_SUGGESTED_CREDENTIAL = "[RULES] GET_SUGGESTED_CREDENTIAL",
  SET_SHOW_FINAL_MODAL = "[RULES] SET SHOW FINAL MODAL",
  SET_SHOW_FINAL_LOADING = "[RULES] SET SHOW FINAL LOADING",
}
