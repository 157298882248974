import React from "react";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import {
  ADDITIONAL_FILES_ADVICE_LIST,
  ChargebackAdvices,
  ChargebackMessages,
  LabelField,
  StepEnum,
  SubtitleSection,
} from "../../shared/enums/LabelsEnum";
import { chargebackTransactionUploadSectionStyles } from "./ChargebackTransactionUploadSection.styles";
import { IUploadFile } from "../../shared/interfaces/IChargebackForm";
import { defaultTo, get } from "lodash";
import { PaperFileUpload } from "../../components/PaperFileUpload/PaperFileUpload";
import { ChargebackTransactionUploadSectionDetailProps } from "./ChargebackTransactionUploadSection.interfaces";
import { useWatch } from "react-hook-form";
import { ErrorsEnum } from "../../shared/constants/ErrorMessage";

export const ChargebackTransactionUploadSection = (
  props: ChargebackTransactionUploadSectionDetailProps
) => {
  const letterFiles: IUploadFile[] = useWatch({
    name: "letterFiles",
  });
  const additionalFiles: IUploadFile[] = useWatch({
    name: "additionalFiles",
  });
  const allowedExtensions: string =
    ".jpg,.png,.pdf,.doc,.txt,.docx,.jpeg,.rtf,.txt,.csv,.xls,.xlsx";
  const max_megabytes_limit: number = 4;

  return (
    <Grid container spacing={2} style={{ padding: "4px 14px 10px 14px" }}>
      <Grid item xs={12}>
        <Typography
          variant={"h5"}
          sx={chargebackTransactionUploadSectionStyles.title}
        >
          {SubtitleSection.BACKUP_CHARGEBACK}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={chargebackTransactionUploadSectionStyles.textDocumentation}
        >
          {ChargebackAdvices.BACKUP_CHARGEBACK}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={chargebackTransactionUploadSectionStyles.textStep}>
          {StepEnum.BUSINESS_LETTER}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={chargebackTransactionUploadSectionStyles.textDocumentation}
        >
          {ChargebackAdvices.BUSINESS_LETTER}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={2} style={{ paddingRight: "0px !important" }}>
            <FormControl variant="outlined">
              <Button
                variant="outlined"
                component="label"
                color="info"
                disabled={false}
              >
                {LabelField.UPLOAD_FILE}
                <input
                  data-testid="file-input-letterFilesKey"
                  aria-labelledby={"letterFilesKey"}
                  onChange={(e) => {
                    props.uploadFilesHandler(e, "letterFiles");
                  }}
                  type="file"
                  accept={allowedExtensions}
                  style={{ display: "none" }}
                />
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={10} style={{ margin: "auto" }}>
            {letterFiles.length === 0 && (
              <span style={{ color: "#023365" }}>
                {ChargebackMessages.EMPTY_FILES}
              </span>
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography variant={"caption"} sx={{ color: "#6D7781" }}>
              {ChargebackAdvices.CHOOSE_FILE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {defaultTo(letterFiles, []).map(
            (item: IUploadFile, index: number) => (
              <Grid
                key={`gridChip${index}`}
                style={{ paddingBottom: "8px", paddingRight: "34px" }}
              >
                <PaperFileUpload
                  name={item.name}
                  value={item.value}
                  size={item.size}
                  handleRemoveFile={props.handleRemoveFile}
                  index={index}
                  type={"letterFiles"}
                />
                <FormHelperText error={true}>
                  {get(item, "size", 0) > max_megabytes_limit
                    ? ErrorsEnum.SIZE_FILES
                    : ""}
                </FormHelperText>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={chargebackTransactionUploadSectionStyles.containerDivider}
      >
        <Divider
          variant="middle"
          sx={chargebackTransactionUploadSectionStyles.divider}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          id={"additionalFilesKey"}
          sx={chargebackTransactionUploadSectionStyles.textStep}
        >
          {StepEnum.ADDITIONAL_FILES}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {ADDITIONAL_FILES_ADVICE_LIST.map((item: string, index: number) => (
          <Typography
            key={`adviceFile${index}`}
            sx={chargebackTransactionUploadSectionStyles.textDocumentationStep2}
          >
            • {item}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={2}>
            <FormControl variant="outlined">
              <Button
                variant="outlined"
                component="label"
                color="info"
                disabled={false}
              >
                {LabelField.UPLOAD_FILE}
                <input
                  data-testid="file-input-additionalFilesKey"
                  multiple
                  aria-labelledby={"additionalFilesKey"}
                  onChange={(e) => {
                    props.uploadFilesHandler(e, "additionalFiles");
                  }}
                  type="file"
                  accept={allowedExtensions}
                  style={{ display: "none" }}
                />
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={10} style={{ margin: "auto" }}>
            {additionalFiles.length === 0 && (
              <span style={{ color: "#023365" }}>
                {ChargebackMessages.EMPTY_FILES}
              </span>
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography variant={"caption"} sx={{ color: "#6D7781" }}>
              {ChargebackAdvices.CHOOSE_FILE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {defaultTo(additionalFiles, []).map(
            (item: IUploadFile, index: number) => (
              <Grid
                key={`gridChip${index}`}
                style={{ paddingBottom: "8px", paddingRight: "34px" }}
              >
                <PaperFileUpload
                  name={item.name}
                  value={item.value}
                  size={item.size}
                  handleRemoveFile={props.handleRemoveFile}
                  index={index}
                  type={"additionalFiles"}
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
