import { useEffect, useState } from "react";
import { slidesContent } from "../../../shared/constants/content_slides";

export const useSlider = () => {
  const [index, setIndex] = useState<number>(0);
  const [isDisableBtn, setIsDisableBtn] = useState<boolean>(true);

  const previousSlide = () => {
    if (index > 0) setIndex((currentState) => currentState - 1);
  };

  const nextSlide = () => {
    if (index < slidesContent.length - 1)
      setIndex((currentState) => currentState + 1);
  };

  useEffect(() => {
    setIsDisableBtn(index !== 2);
  }, [index]);

  return {
    index,
    isDisableBtn,
    nextSlide,
    previousSlide,
    slidesContent,
  };
};
