import React, { FC } from "react";
import { IGenericTabs } from "./GenericTabs.interfaces";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@kushki/connect-ui";
import { get } from "lodash";
import { GenericTabsStyles } from "./GenericTabs.styles";
import { useGenericTabs } from "./state/useGenericTabs";

const GenericTabs: FC<IGenericTabs> = (props: IGenericTabs) => {
  const { variant, orientation, tabList } = props;
  const { handleChange, tabNumber } = useGenericTabs(tabList);

  return (
    <Box data-testid={"containerTabs"} sx={GenericTabsStyles.genericTabContent}>
      <Box display="flex">
        <Box sx={GenericTabsStyles.genericTabContainer}>
          <Tabs
            data-testid={"tabs"}
            onChange={handleChange}
            orientation={orientation}
            variant={variant}
            valueTab={tabNumber}
          >
            {tabList.length > 0 &&
              tabList.map((tabInfo, index) => (
                <Tab
                  key={index}
                  text={tabInfo.label}
                  variant={tabInfo.variant}
                  defaultTab={tabInfo.defaultRender}
                />
              ))}
          </Tabs>
          {tabList.length > 0 && (
            <Box sx={get(tabList[tabNumber], "sx")}>
              {get(tabList[tabNumber], "content")}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GenericTabs;
