import { contactDataSlice } from "../reducers/contactData/contactData";

export const {
  contactDataStore,
  setIsLoadingContactData,
  setNotificationCD,
  setLoaderModalOpen,
  setDisableAddPersonBtn,
  setTypeButtonContactData,
} = contactDataSlice.actions;
