import { defaultTo, get, isEqual, map, set } from "lodash";
import {
  IFilterTableComponent,
  IUseFilterDataTableComponent,
} from "../../../shared/infrastructure/interfaces/IFilterTableProps";
import { ItemCategoryItem, ItemCategoryProps } from "@kushki/connect-ui";
import { SearchRuleRequest } from "../../../../types/search_rule_request";
import { useDispatch } from "react-redux";
import {
  FilterEnum,
  FiltersGroup,
  FiltersRequestName,
} from "../../../shared/infrastructure/enums/FilterEnum";
import { INITIAL_STATE } from "../../../store/reducer";
import { useEffect, useState } from "react";
import { getConfiguration } from "../../../store/actionCreators";
import { TOnChangeFilters } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import { useAppSelector } from "../../../store/hooks/store-hook";

export const useFilterTableComponentState = (
  props: IFilterTableComponent
): IUseFilterDataTableComponent => {
  const dispatch = useDispatch();
  const defaultFilters: SearchRuleRequest = {
    from: "",
    to: "",
    limit: 0,
    offset: 0,
  };

  const [categoryItems, setCategoryItems] = useState<ItemCategoryProps[]>();
  const configurationResponse = useAppSelector(
    (store) => store.layout.configList
  );

  const addFilters = (
    searchRuleRequest: SearchRuleRequest,
    itemCategoryProps: ItemCategoryProps
  ): void => {
    const filters: string[] = [];
    itemCategoryProps.items.forEach((item: ItemCategoryItem) => {
      filters.push(item.value);
    });
    set(
      searchRuleRequest,
      FiltersRequestName[itemCategoryProps.placeHolder].filterPath,
      filters.join("|")
    );
  };

  const getFilterItem = (filterEnum: FilterEnum): ItemCategoryProps => {
    let items: ConfigurationResponse = get(
      configurationResponse,
      FiltersRequestName[filterEnum].selectorType,
      []
    );

    if (filterEnum === FilterEnum.STATUS) {
      items = [];
      items.push({ name: "enabled", title: "Habilitado" });
      items.push({ name: "disable", title: "Deshabilitado" });
      items.push({ name: "draft", title: "Borrador" });
    }
    return {
      isMinimize: true,
      items: map(items, (element) => {
        return {
          label: element.title,
          selected: false,
          value:
            filterEnum === FilterEnum.STATUS ? element.name : element.field,
        };
      }),
      onItemsSelect: () => {},
      placeHolder: filterEnum,
      selectType: "multiple",
      type: FiltersRequestName[filterEnum].categoryItem,
    };
  };

  const getFilters = (): ItemCategoryProps[] => {
    return [
      getFilterItem(FilterEnum.STATUS),
      getFilterItem(FilterEnum.BUSINESS_CONDITIONS),
    ];
  };

  const setRequestFilter = (selected: ItemCategoryProps[]) => {
    if (selected.length > 0) {
      const search: SearchRuleRequest = {
        ...defaultTo(props.searchRuleRequest, defaultFilters),
      };
      selected.forEach((item: ItemCategoryProps) => {
        if (FiltersGroup.find((name: string) => name === item.placeHolder)) {
          addFilters(search, item);
        }
      });
      props.setSearchRuleRequest(search);
    }
  };

  const changeFilter = (change: TOnChangeFilters) => {
    const search: SearchRuleRequest = {
      ...defaultTo(props.searchRuleRequest, defaultFilters),
    };

    FiltersGroup.find((name: string) => {
      if (name === change.affected.title) {
        const filter: [] = get(search, FiltersRequestName[name].filterPath, [])
          .split("|")
          .filter((item: any) => item !== change.affected.itemDeleted.value);
        set(search, FiltersRequestName[name].filterPath, filter.join("|"));
      }
    });
    props.setSearchRuleRequest(search);
  };

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  useEffect(() => {
    if (!isEqual(configurationResponse, INITIAL_STATE.configList)) {
      setCategoryItems(getFilters());
    }
  }, [configurationResponse]);

  return {
    setRequestFilter,
    categoryItems,
    changeFilter,
  };
};
