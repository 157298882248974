import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import { MerchantResponse } from "../../types/merchant_response";
import { BillingConfig } from "../../types/billing_config";
import { Color } from "@material-ui/lab/Alert";
import { Webhook } from "../../types/webhook";
import { ProcessorFetch } from "../shared/infrastructure/interfaces/ProcessorFetch";
import { RateResponse } from "../../types/rate_response";
import { ResponseTrxRates } from "../../types/response_trx_rates";
import { UserForm } from "../../types/user_form";
import { MerchantForm } from "../../types/merchant_form";
import { UserResponse } from "../../types/user_response";
import { MerchantProcessorFetch } from "../shared/interfaces/MerchantProcessorFetch";
import { DeferredOption } from "../shared/infrastructure/interfaces/DeferredOptions";
import { IDiscountResponse } from "../../types/discount_response";
import { MerchantCredential } from "../shared/infrastructure/interfaces/MerchantCredential";
import { WebhookListFetch } from "../shared/interfaces/WebhookListFetch";
import { ImageInsert } from "@kushki/frontend-molecules/gallery/interfaces/ImageInsert";
import { MerchantProcessorsRules } from "../../types/merchant_processors_rules_response";
import { INotificationResponse } from "../shared/infrastructure/interfaces/INotificationResponse";
import { SmartlinkResponse } from "../../types/smartlink_get_response";
import { DispersionConfigurationResponse } from "../../types/dispersion_configuration";
import { GetAppConfigPaymentMethodsResponse } from "../../types/get_app_config_payment_method_response";

export interface IAppState {
  merchant?: MerchantResponse;
  authInfo?: IAuthInfo;
  cardOptions?: CardMerchantResponse;
  vposOptions?: VPOSResponse;
  comissionOptions?: CommissionResponse;
  subscriptionOptions?: SubscriptionsResponse;
  billingConfig?: BillingConfig;
  merchantResponse?: { publicMerchantId: string };
  billingConfigResponse?: boolean;
  notification?: INotification;
  webCheckout?: INotificationResponse;
  webhooks?: Webhook[];
  customization?: MerchantResponse;
  discountInfo?: IDiscountResponse;
  loading?: boolean;
  loadingConfig?: boolean;
  smartLinkInfo?: SmartlinkResponse;
  isWebcheckoutLoaded?: boolean;
  isLoading?: boolean;
  processors?: ProcessorFetch[];
  rate?: RateResponse;
  transactionRatesData?: ResponseTrxRates[];
  rules?: MerchantProcessorsRules;
  merchantProcessors?: MerchantProcessorFetch[];
  isLoadingGetMerchantProcessors?: boolean;
  defaultProcessor?: object;
  waitingForRequest?: boolean;
  isLoadingDefaultProcessor?: boolean;
  fetchUsers?: IUserListResponse;
  deferred?: DeferredOption[];
  isDeferredLoaded?: boolean;
  paymentCredentials?: MerchantCredential[];
  isLoadingPaymentCredentials?: boolean;
  webhookList?: WebhookListFetch;
  isLoadingWebhookList?: boolean;
  isRuleLoaded?: boolean;
  merchantRequest?: MerchantRequest;
  imageSelected?: ImageInsert;
  isImageSelected?: boolean;
  walletRechargeConfig?: DispersionConfigurationResponse;
  appConfigPaymentMethods?: GetAppConfigPaymentMethodsResponse;
}

export interface INotification {
  open: boolean;
  message: string;
  type: Color;
}

export interface IValidateDomain {
  validDomain: boolean;
  httpErrorMessage: string;
}

export interface MerchantRequest {
  hasBeenCalled: boolean;
  cardResponse?: CardMerchantResponse;
  vposResponse?: VPOSResponse;
  webcheckoutResponse?: WebcheckoutResponse;
  commissionResponse?: CommissionResponse;
  subscriptionsResponse?: SubscriptionsResponse;
}

export interface IMerchantPropertiesState {
  merchants: IMerchantsResponse;
  notification?: INotification;
  waitingForRequest?: boolean;
  merchantRequest?: MerchantRequest;
  showFinalModal?: boolean;
}

export interface CardMerchantResponse {
  country?: string;
  sift_science?: {
    SandboxApiKey?: string;
    ProdApiKey?: string;
    SandboxAccountId?: string;
    ProdAccountId?: string;
    BaconProdApiKey?: string;
    BaconSandboxApiKey?: string;
  };
  whiteList?: boolean;
  sandboxEnable?: boolean;
  acceptCreditCards?: string[];
}

export interface VPOSResponse {
  publicId: string;
  merchantName: string;
  active: boolean;
  country: string;
}

export interface WebcheckoutResponse {
  id: string;
  country: string;
  publicMerchantId: string;
  status: boolean;
  merchantName: string;
}

export interface CommissionResponse {
  commission: {
    fixAmount: number;
    variableAmount: number;
    iva: number;
    merchantId: string;
    merchantName: string;
    deferred: 0 | 1;
  };
  merchantName: string;
  publicId: string;
}

export interface SubscriptionsResponse {
  publicId: string;
  merchantName: string;
  subscriptionValidation: boolean;
  subscriptionsConfig: {
    customValidation: {
      enable: boolean;
      amount: number;
      currency: string;
    };
  };
}

export interface MerchantRequest {
  hasBeenCalled: boolean;
  cardResponse?: CardMerchantResponse;
  vposResponse?: VPOSResponse;
  webcheckoutResponse?: WebcheckoutResponse;
  commissionResponse?: CommissionResponse;
  subscriptionsResponse?: SubscriptionsResponse;
}

export interface WebhookSignatureResponse {
  webhookSignature: string;
}

export const INITIAL_STATE: IAppState = {
  notification: { open: false, message: "", type: "success" },
  billingConfigResponse: false,
  isLoading: false,
  waitingForRequest: false,
  billingConfig: {
    created: 0,
    country: "",
    publicMerchantId: "",
    valuesBilling: {
      elementsApproved: ["SALES", "DEFERRED"],
      elementsDeclined: ["SALES", "DEFERRED"],
      statusApproved: true,
      statusDeclined: false,
    },
    valuesSubtractBilling: {
      elementsApproved: ["CHARGEBACK", "REFUND", "VOID"],
      elementsDeclined: ["CHARGEBACK", "REFUND", "VOID"],
      statusApproved: true,
      statusDeclined: false,
    },
  },
  webhooks: [],
  loading: false,
  loadingConfig: true,
  isWebcheckoutLoaded: false,
  processors: [],
  transactionRatesData: undefined,
  merchant: undefined,
  fetchUsers: {
    Users: undefined,
  },
  customization: undefined,
  merchantProcessors: [],
  isLoadingGetMerchantProcessors: false,
  defaultProcessor: {},
  isLoadingDefaultProcessor: false,
  deferred: [],
  isDeferredLoaded: false,
  isRuleLoaded: true,
  paymentCredentials: [],
  isLoadingPaymentCredentials: false,
  webhookList: {
    items: [{ alias: "", events: [{ status: "", service: "" }] }],
  },
  isLoadingWebhookList: false,
  imageSelected: {
    added: "",
    height: "",
    name: "",
    url: "",
    width: "",
    thumbnailUrl: "",
  },
  isImageSelected: false,
  walletRechargeConfig: {},
  appConfigPaymentMethods: [],
};

export interface IUserListResponse {
  Users: UserResponse[] | undefined;
}

export type IMerchantsResponse = {
  data: { _source: MerchantForm }[];
  total: number;
};

export interface IUserState {
  user: UserForm;
  userTypeForm: "edit" | "create";
  fetchUsers: IUserListResponse;
  merchants: IMerchantsResponse;
  userValidate: boolean;
  counterChangeState: number;
  timeZoneResponse: boolean;
  waitingForRequest: boolean;
  pagination?: string;
  notification: INotification;
  isSuccessCreateEditUser?: boolean;
  statusResponse?: "FAIL" | "OK";
}

export interface IUserListQueryString {
  limit: string;
  username?: string;
  searchSupport?: boolean;
  merchantId?: string;
}

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_MERCHANT:
      return {
        ...state,
        merchant: action.merchant,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SET_RATE_MERCHANT:
      return {
        ...state,
        rate: action.rate,
      };
    case ActionTypes.SET_DISCOUNT_INFO:
      return {
        ...state,
        discountInfo: action.discountInfo,
      };
    case ActionTypes.SET_AUTH_INFO:
      return {
        ...state,
        authInfo: action.authInfo,
      };
    case ActionTypes.SET_BILLING_CONFIG:
      return {
        ...state,
        billingConfig: action.billingConfig,
      };
    case ActionTypes.SET_MERCHANT_RESPONSE:
      return {
        ...state,
        billingConfig: action.billingConfig,
      };
    case ActionTypes.SET_BILLING_CONFIG_RESPONSE:
      return {
        ...state,
        billingConfig: action.billingConfig,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification!,
      };
    case ActionTypes.SET_WEBHOOKS:
      return {
        ...state,
        webhooks: action.webhooks,
      };
    case ActionTypes.SET_CUSTOMIZATION:
      return {
        ...state,
        customization: action.customization,
      };
    case ActionTypes.SET_WEB_CHECKOUT:
      return {
        ...state,
        webCheckout: action.webCheckout,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.SET_LOADING_CONFIG:
      return {
        ...state,
        loadingConfig: action.loadingConfig,
      };
    case ActionTypes.SET_SMARTLINK_INFO:
      return {
        ...state,
        smartLinkInfo: action.smartLinkInfo,
      };
    case ActionTypes.SET_IS_WEBCHECKOOUT_LOADED:
      return {
        ...state,
        isWebcheckoutLoaded: action.isWebcheckoutLoaded,
      };
    case ActionTypes.SET_PROCESSORS:
      return {
        ...state,
        processors: action.processors,
      };
    case ActionTypes.SET_TRX_RATES:
      return {
        ...state,
        transactionRatesData: action.transactionRatesData,
      };
    case ActionTypes.SET_RULES:
      return {
        ...state,
        rules: action.rules,
      };
    case ActionTypes.SET_MERCHANT_PROCESSORS:
      return {
        ...state,
        merchantProcessors: action.merchantProcessors,
      };
    case ActionTypes.SET_IS_LOADING_GET_MERCHANT_PROCESSORS:
      return {
        ...state,
        isLoadingGetMerchantProcessors: action.isLoadingGetMerchantProcessors,
      };
    case ActionTypes.SET_DEFAULT_PROCESSOR:
      return {
        ...state,
        defaultProcessor: action.defaultProcessor,
      };
    case ActionTypes.SET_IS_LOADING_DEFAULT_PROCESSOR:
      return {
        ...state,
        isLoadingDefaultProcessor: action.isLoadingDefaultProcessor,
      };
    case ActionTypes.SET_PAYMENT_CREDENTIALS:
      return {
        ...state,
        paymentCredentials: action.paymentCredentials,
      };
    case ActionTypes.SET_IS_LOADING_PAYMENT_CREDENTIALS:
      return {
        ...state,
        isLoadingPaymentCredentials: action.isLoadingPaymentCredentials,
      };
    case ActionTypes.SET_WEBHOOK_LIST:
      return {
        ...state,
        webhookList: action.webhookList,
      };
    case ActionTypes.SET_IS_LOADING_WEBHOOK_LIST:
      return {
        ...state,
        isLoadingWebhookList: action.isLoadingWebhookList,
      };
    case ActionTypes.SET_USER_LIST:
      return {
        ...state,
        fetchUsers: action.fetchUsers,
      };
    case ActionTypes.SET_DEFERRED:
      return {
        ...state,
        deferred: action.deferred,
      };
    case ActionTypes.SET_IS_DEFERRED_LOADED:
      return {
        ...state,
        isDeferredLoaded: action.isDeferredLoaded,
      };
    case ActionTypes.SET_CARD_OPTIONS_REQUEST:
      return {
        ...state,
        cardOptions: action.cardOptions,
      };
    case ActionTypes.SET_VPOS_OPTIONS_REQUEST:
      return {
        ...state,
        vposOptions: action.vposOptions,
      };
    case ActionTypes.SET_COMISSION_OPTIONS_REQUEST:
      return {
        ...state,
        comissionOptions: action.comissionOptions,
      };
    case ActionTypes.SET_SUBSCRIPTION_OPTIONS_REQUEST:
      return {
        ...state,
        subscriptionOptions: action.subscriptionOptions,
      };
    case ActionTypes.SET_IMAGE_SELECTED:
      return {
        ...state,
        imageSelected: action.imageSelected,
      };
    case ActionTypes.SET_IS_IMAGE_SELECTED:
      return {
        ...state,
        isImageSelected: action.isImageSelected,
      };
    case ActionTypes.SET_WALLET_RECHARGE_CONFIGURATION:
      return {
        ...state,
        walletRechargeConfig: action.walletRechargeConfig,
      };
    case ActionTypes.SET_APPCONFIG_PAYMENT_METHODS:
      return {
        ...state,
        appConfigPaymentMethods: action.appConfigPaymentMethods,
      };
    default:
      return state;
  }
};
