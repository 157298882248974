import { makeStyles } from "@material-ui/core/styles";

export const paginationComponentStyles = makeStyles({
  root: {
    padding: "8px 27px 8px 20px",
    backgroundColor: "white",
  },
  buttonIconPagination: {
    textTransform: "none",
  },
  marginButton: {
    marginTop: -6,
    display: "flex",
  },
  itemTotalCount: {
    marginTop: -6,
    display: "flex",
    marginLeft: "30px",
    marginRight: "30px",
  },
  typographyLabelPagination: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 11,
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontWeight: 500,
    color: "#6D7781",
  },
  typographyLabelPages: {
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#6D7781",
  },
  select: {
    backgroundColor: "white",
    paddingLeft: "5px",
  },
});
