import React, { FC } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  IRadioGroupProps,
  RadioInfoItems,
} from "./RadioGroupComponent.interfaces";
import { useRadioButton } from "./useState/useRadioButton";
import { radioGroupComponentStyles } from "./RadioGroupComponent.styles";

const RadioGroupComponent: FC<IRadioGroupProps> = (props: IRadioGroupProps) => {
  const { onChange, items, selectedValue } = useRadioButton(props);

  return (
    <React.Fragment>
      <RadioGroup
        key={`rg_${Math.random()}`}
        row
        aria-label="gender"
        name={props.name}
        onChange={onChange}
        value={selectedValue}
      >
        {items.map((radio: RadioInfoItems, index: number) => (
          <>
            <FormControlLabel
              key={`form_${index}_${Math.random()}`}
              value={radio.value}
              label={radio.label}
              control={
                <Radio
                  key={`radio_${index}`}
                  sx={radioGroupComponentStyles.radio}
                  disabled={radio.disable}
                />
              }
            />
          </>
        ))}
      </RadioGroup>
    </React.Fragment>
  );
};

export default RadioGroupComponent;
