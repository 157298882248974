import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import icon from "../../../assets/images/icon.svg";
import React from "react";

const styles = () => ({
  acceptAction: {
    background: "#023365",
    color: "#FFFFFF",
    marginBottom: 30,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 9,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 8,
  },

  dialogContentText: {
    fontSize: 16,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center" as "center",
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 13,
    marginLeft: "auto",
    marginRight: "auto",
  },
  kushkiIcon: {
    height: 54,
    marginBottom: 30,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
    width: 54,
  },
  primaryColor: {
    color: "#023365",
  },
});

export interface ConfirmModalProps {
  open: boolean;
  titleText: string;
  contentText?: string;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  handleAccept: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const inlineStyles = styles();

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <img style={inlineStyles.kushkiIcon} src={icon}></img>
      <Box style={inlineStyles.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography
            variant="h6"
            color="textPrimary"
            style={inlineStyles.dialogTitle}
          >
            {props.titleText}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText style={inlineStyles.dialogContentText}>
            <Typography color="textSecondary">{props.contentText}</Typography>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleAccept} style={inlineStyles.acceptAction}>
          <Typography style={{ textTransform: "none" }} variant={"h6"}>
            {props.acceptText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
