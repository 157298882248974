import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ItemCategoryStyles } from "./styles";
import { useItemCategoryState } from "./state/useItemCategoryState";
import {
  ItemCategoryItemMassive,
  ItemCategoryMassiveProps,
} from "./interfaces";
import React, { FC } from "react";
import { FilterTextsEnum } from "../../../shared/enum/FilterTextsEnum";

const ItemCategory: FC<ItemCategoryMassiveProps> = ({
  groupSection,
  isMinimize,
  items,
  selectType,
  type,
}: ItemCategoryMassiveProps) => {
  const props: ItemCategoryMassiveProps = {
    groupSection,
    isMinimize,
    items,
    selectType,
    type,
  };
  const { expanded, toggleAcordion, listFromItems, handleOnByChipsChange } =
    useItemCategoryState(props);

  return (
    <Accordion expanded={expanded} sx={ItemCategoryStyles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={ItemCategoryStyles.acordionExpandIcon} />}
        sx={ItemCategoryStyles.summary}
        onClick={() => toggleAcordion()}
      >
        <Typography color="text.grey" variant={"h6"}>
          {groupSection || FilterTextsEnum.GROUP_NAME}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={ItemCategoryStyles.acordionDetail}>
        {type === "byChips" && (
          <Box sx={ItemCategoryStyles.formChip}>
            {listFromItems.map(
              (item: ItemCategoryItemMassive, index: number) => (
                <FormControlLabel
                  key={index + groupSection}
                  id={index + groupSection}
                  className="filterChipCheck"
                  sx={ItemCategoryStyles.formChip}
                  control={
                    <Checkbox
                      id={index + groupSection}
                      size="medium"
                      onChange={() => handleOnByChipsChange(item)}
                      checked={item.selected}
                      value={item.value}
                      color="primary"
                      disabled={item.isDisabled ?? false}
                    />
                  }
                  label={item.label}
                />
              )
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export { ItemCategory };
