import React from "react";
import { CardBlueProps, CardBlue } from "../CardBlue/CardBlue";
import { get, isEmpty } from "lodash";
import { LANGUAGE_CATALOG } from "../../../infrastructure/constants/LanguageCatalog";

export interface ProcessorsTabProps {
  defaultProcessor?: {
    [k: string]: string;
  };
  processors?: {
    [k: string]: any[];
  };
}

const getPaymentMethod = (method: string) => {
  const language = get(LANGUAGE_CATALOG, `paymentMethod.${method.trim()}`, "");
  if (language) return language;
  return method;
};

export const ProcessorsTab: React.FC<ProcessorsTabProps> = (
  props: ProcessorsTabProps
) => {
  const { defaultProcessor, processors } = props;

  const getProcessorCard = (
    processorData: {
      [k: string]: any;
    },
    isDefault: boolean
  ): CardBlueProps => {
    const props: CardBlueProps = {
      title: get(processorData, "alias", isDefault ? "Procesador" : ""),

      items: [
        {
          key: "Medio de pago",
          value: getPaymentMethod(get(processorData, "paymentMethod", "")),
        },
        {
          key: "Public ID",
          value: get(processorData, "publicProcessorId", ""),
        },
        {
          key: "Procesador",
          value: get(processorData, "processorName", ""),
        },
        {
          key: "Modelo",
          value: get(processorData, "processorType", ""),
        },
        {
          key: "Fail over",
          value: get(processorData, "failOverProcessor", ""),
        },
      ],
    };
    if (isDefault) props["subTitle"] = "Procesador por defecto";
    return props;
  };

  const defaultProccessors = Object.keys(defaultProcessor!)
    .map((typeProcessor) => {
      if (isEmpty(processors![typeProcessor])) return undefined;

      const foundProcessor = processors![typeProcessor].find(
        (v) => v.alias === defaultProcessor![typeProcessor]
      );

      if (!foundProcessor) return undefined;

      return getProcessorCard(foundProcessor, true);
    })
    .filter(Boolean);

  const leftProcessors = Object.keys(processors!)
    .map((key) =>
      processors![key].filter(
        (processor) => processor.alias !== defaultProcessor![key]
      )
    )
    .flat(1)
    .slice(0, 5 - defaultProccessors.length)
    .map((processor) => getProcessorCard(processor, false));

  return (
    <>
      {defaultProccessors.map((defaultProcessor, index) => (
        <CardBlue
          key={`${index} ${defaultProcessor!.title}`}
          {...defaultProcessor!}
        />
      ))}
      {leftProcessors.map((leftProcessor, index) => (
        <CardBlue
          key={`${index} ${leftProcessor!.title}`}
          {...leftProcessor!}
        />
      ))}
    </>
  );
};
