export enum CountryCodeEnum {
  ECUADOR = "ECU",
  CHILE = "CHL",
  COLOMBIA = "COL",
  GLOBAL = "GLB",
}

export enum CountryCodeCatalogEnum {
  CL = "CL",
}
