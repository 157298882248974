import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { SimpleDialogProps } from "./SimpleDialog.interfaces";
import { useStyles } from "../../DetailsCard.styles";
import { X } from "react-feather";

export const SimpleDialog = (props: SimpleDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.openDialog}
      onClose={props.onClose}
      className={classes.simpleDialog}
    >
      <DialogTitle>
        <Typography>{props.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            props.onClose();
          }}
        >
          <X size={14} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "605px" }}>
        <Grid item md={12} id={"dateSection"}>
          <Typography className={"title"}>{props.dateTitle}:</Typography>
          <Typography>{props.date}</Typography>
          <Typography className={"second"}>{props.time}</Typography>
        </Grid>
        <Grid item md={12} id={"reasonSection"}>
          <Typography className={"title"}>Motivo:</Typography>
          <Typography className={"reason"}>{props.reason}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          autoFocus
          id={"buttonAction"}
        >
          Entendido
        </Button>
      </DialogActions>
    </Dialog>
  );
};
