import {
  CompliancePeopleNodeResponse,
  LegalRepresentative,
  Shareholder,
} from "../../../../types/get_compliance_people_response";
import {
  IBoardMember,
  IBoardMemberRequest,
  ILegalRepresentative,
  ILegalRepresentativeRequest,
  IShareHolder,
  IShareHolderRequest,
  PersonCategoryEnum,
} from "../../../store/interfaces/LegalDetailsState.interfaces";
import { v4 } from "uuid";
import { defaultTo, get, isEmpty } from "lodash";
import {
  transformDateToTimestamp,
  transformUnixTimestampToFormatDate,
} from "../../../shared/utils/date_utils";
import { DateFormatEnum } from "../../../shared/enum/DateFormatEnum";
import { PersonTypeEnum } from "../../../shared/constants/initial_state_legal_details";
import { ShareholderFields } from "../../../shared/constants/shareholder_constants";
import { LegalRepresentativeTypeEnum } from "../../../shared/enum/LegalRepresentativeTypeEnum";
import { IAccordionValue } from "./LegalContainerState.interfaces";
import { dateFormatForMerchantPeople } from "../../../shared/utils/legalRepresentativeUtils";
import { handleCompliancePeopleBMemberUtil } from "../../../shared/utils/boardMemberUtils";

const validateDate = (obj: object, key: string, dateFormat: DateFormatEnum) => {
  let date = transformUnixTimestampToFormatDate(
    get(obj, key, new Date().getTime()),
    dateFormat
  );

  if (date === "Invalid Date") date = "";

  return date;
};

export const getLegalRepresentativeType = (
  isMain: boolean
): LegalRepresentativeTypeEnum => {
  return isMain
    ? LegalRepresentativeTypeEnum.main
    : LegalRepresentativeTypeEnum.secundary;
};

export const buildLegalRepresentative = (
  representative: LegalRepresentative
): ILegalRepresentative => {
  const auxId: string = v4();

  return {
    areaCode: get(representative, "areaCode"),
    birthdate: validateDate(
      representative,
      "birthdate",
      DateFormatEnum.DD_MMM_YYYY
    ),

    cellphoneNumber: get(representative, "cellphoneNumber", ""),
    constitutionDate: validateDate(
      representative,
      "constitutionDate",
      DateFormatEnum.DD_MMM_YYYY
    ),
    country: get(representative, "residencyCountry", ""),
    countryCode: get(representative, "countryCode"),
    documentNumber: get(representative, "documentNumber"),
    documentType: get(representative, "documentType", ""),
    email: get(representative, "email", ""),
    id: get(representative, "id", auxId),
    isMain: representative.isMain,
    isPubliclyExposed: get(representative, "isPubliclyExposed", false),
    isShareHolder: get(representative, "isShareHolder", false),
    lastName: get(representative, "lastNames", ""),
    merchantAdress: get(representative, "addressCountry"),
    merchantCountry: get(representative, "originCountry"),
    name: get(representative, "names", ""),
    nationality: get(representative, "nationality", ""),
    participationPercentage: get(representative, "participationPercentage"),
    personCategory: get(
      representative,
      "personCategory",
      PersonCategoryEnum.REPRESENTATIVE
    ),
    personType: get(representative, "personType"),
    socialReason: get(representative, "socialReason"),
    taxId: get(representative, "documentNumber"),
    type: getLegalRepresentativeType(representative.isMain),
    uid: get(representative, "uid", auxId),
  };
};

export const buildShareholder = (shareholder: Shareholder): IShareHolder => {
  return shareholder.personType === PersonTypeEnum.FISICA
    ? {
        birthdate: validateDate(
          shareholder,
          "birthdate",
          DateFormatEnum.DD_MMM_YYYY
        ),
        children: [],
        country: get(shareholder, ShareholderFields.RESIDENCY_COUNTRY, ""),
        documentNumber: get(shareholder, ShareholderFields.DOCUMENT_NUMBER),
        documentType: get(shareholder, ShareholderFields.DOCUMENT_TYPE),
        id: get(shareholder, ShareholderFields.ID),
        lastName: get(shareholder, `${ShareholderFields.LAST_NAME}s`),
        level: 0,
        name: get(shareholder, `${ShareholderFields.NAME}s`),
        parent: get(shareholder, ShareholderFields.PARENT),
        participationPercentage: get(
          shareholder,
          ShareholderFields.PARTICIPATION_PERCENTAGE,
          "0"
        ),
        personCategory: get(
          shareholder,
          ShareholderFields.PERSON_CATEGORY,
          "SHAREHOLDER"
        ),
        personType: PersonTypeEnum.FISICA,
        uid: get(shareholder, ShareholderFields.UID),
      }
    : {
        children: [],
        companyCountry: get(shareholder, ShareholderFields.ADDRESS_COUNTRY, ""),
        country: get(shareholder, ShareholderFields.ADDRESS_COUNTRY, ""),
        id: get(shareholder, ShareholderFields.ID),
        level: 0,
        merchantCountry: get(shareholder, ShareholderFields.ORIGIN_COUNTRY),
        parent: get(shareholder, ShareholderFields.PARENT),
        participationPercentage: get(
          shareholder,
          ShareholderFields.PARTICIPATION_PERCENTAGE,
          "0"
        ),
        personCategory: get(
          shareholder,
          ShareholderFields.PERSON_CATEGORY,
          "SHAREHOLDER"
        ),
        personType: PersonTypeEnum.MORAL,
        socialReason: get(shareholder, ShareholderFields.SOCIAL_REASON),
        taxId: get(shareholder, ShareholderFields.DOCUMENT_NUMBER),
        uid: get(shareholder, ShareholderFields.UID),
      };
};

export const makeTree = (data: IShareHolder[]) => {
  let i = -1;
  let keys: string[] = [];

  data.map((x: IShareHolder) => {
    x.children = [];
    keys.push(x["id"]);
  });
  let shareholderTree: IShareHolder[] = data.filter(
    (x: IShareHolder) => keys.indexOf(get(x, "parent", "")) == -1
  );
  let shareholders: IShareHolder[] = [];

  shareholderTree.map((x: IShareHolder) => shareholders.push(x));
  while (shareholders.length > 0) {
    let node: IShareHolder = shareholders[shareholders.length - 1];

    shareholders.pop();
    let children: IShareHolder[] = data.filter(
      (x: IShareHolder) => x["parent"] == get(node, "id", "")
    );

    children.map((x: IShareHolder) => {
      i += 1;
      x.level = i;
      node.children.push(x);
      shareholders.push(x);
    });
  }

  return shareholderTree;
};

export const transformCompliancePeopleIntoAccordion = (
  compliancePeople: CompliancePeopleNodeResponse
): IAccordionValue => {
  let shareholders: IShareHolder[] = [];

  const legalRepresentatives = compliancePeople.legalRepresentatives.map(
    (representative: LegalRepresentative) => {
      return buildLegalRepresentative(representative);
    }
  );

  if (!isEmpty(compliancePeople.shareholders)) {
    const noTreeShareholders: IShareHolder[] =
      compliancePeople.shareholders.map((shareholder: Shareholder) => {
        return buildShareholder(shareholder);
      });

    shareholders = makeTree(noTreeShareholders);
  }

  const boardMembers = handleCompliancePeopleBMemberUtil(compliancePeople);

  return { boardMembers, legalRepresentatives, shareholders };
};

export const compliancePeople: CompliancePeopleNodeResponse = {
  boardmembers: [],
  constitutionalCountry: "Mexico",
  country: "Ecuador",
  legalRepresentatives: [
    {
      areaCode: "",
      birthdate: 1661562000000,
      cellphoneNumber: "57 123123123",
      countryCode: "57",
      documentNumber: "123123123123",
      documentType: "0",
      email: "1@1.1",
      id: "848291fc-a89d-466c-866b-07136bca2e69",
      isMain: true,
      isShareHolder: false,
      lastNames: "asd",
      names: "asd",
      nationality: "Aruba",
      participationPercentage: "12",
      personCategory: "REPRESENTATIVE",
      personType: "01",
      residencyCountry: "Austria",
    },
    {
      addressCountry: "Brazil",
      areaCode: "",
      cellphoneNumber: "55 123123123",
      constitutionDate: 1661472000000,
      countryCode: "55",
      documentNumber: "123123123123",
      email: "1@1.1",
      id: "ac7914db-7991-402b-86a4-5c8b9c14dd4d",
      isMain: false,
      isShareHolder: false,
      originCountry: "Brazil",
      participationPercentage: "0",
      personCategory: "REPRESENTATIVE",
      personType: "02",
      socialReason: "asda",
    },
  ],
  merchantId: "20000000100149633000",
  merchantName: "test",
  shareholders: [
    {
      birthdate: 1661472000,
      documentNumber: "1231231",
      documentType: "1",
      id: "3606f9e7-7ae8-41b0-b3d5-5914b1b7252b",
      lastNames: "21eas",
      names: "fasdas",
      parent: "37fc07d9-3eac-4c3c-9a5f-0a428646bb16",
      participationPercentage: "24",
      personCategory: "SHAREHOLDER",
      personType: "01",
      residencyCountry: "Ecuador",
    },
    {
      addressCountry: "Bangladesh",
      constitutionDate: 1661472000,
      documentNumber: "12312312",
      id: "37fc07d9-3eac-4c3c-9a5f-0a428646bb16",
      originCountry: "Bielorrusia",
      parent: "",
      participationPercentage: "23",
      personCategory: "SHAREHOLDER",
      personType: "02",
      socialReason: "asdas",
    },
    {
      birthdate: 1661472000,
      documentNumber: "1231231",
      documentType: "1",
      id: "test",
      lastNames: "1312",
      names: "awfafwafwaw1",
      parent: "3606f9e7-7ae8-41b0-b3d5-5914b1b7252b",
      participationPercentage: "24",
      personCategory: "SHAREHOLDER",
      personType: "01",
      residencyCountry: "Ecuador",
    },
  ],
};

export const createILegalRequest = (
  item: ILegalRepresentative,
  isPubliclyExposed: boolean
): ILegalRepresentativeRequest => {
  // This is a reference a person type do not delete
  // NATURAL = "01",
  // JURIDICO = "02",
  if (item.personType === PersonTypeEnum.FISICA) {
    return {
      areaCode: defaultTo(item.areaCode, "").split("-")[0],
      birthdate: dateFormatForMerchantPeople(get(item, "birthdate", "")),
      cellphoneNumber: get(item, "cellphoneNumber", ""),
      countryCode: get(item, "countryCode", ""),
      documentNumber: get(item, "documentNumber", ""),
      documentType: get(item, "documentType", ""),
      email: get(item, "email", ""),
      id: get(item, "id", ""),
      isMain: item.isMain,
      isPubliclyExposed,
      isShareHolder:
        get(item, "isShareHolder", false) === ""
          ? false
          : (item.isShareHolder as boolean),
      lastNames: get(item, "lastName", ""),
      names: get(item, "name", ""),
      nationality: get(item, "nationality", ""),
      participationPercentage: get(item, "participationPercentage", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType") as PersonTypeEnum,
      residencyCountry: get(item, "country", ""),
      uid: get(item, "uid", ""),
    };
  } else {
    return {
      addressCountry: get(item, "merchantAdress", ""),
      areaCode: defaultTo(item.areaCode, "").split("-")[0],
      cellphoneNumber: get(item, "cellphoneNumber", ""),
      constitutionDate: transformDateToTimestamp(
        get(item, "constitutionDate", ""),
        DateFormatEnum.DD_MMM_YYYY
      ),
      countryCode: get(item, "countryCode", ""),
      documentNumber: get(item, "taxId", ""),
      email: get(item, "email", ""),
      id: get(item, "id", ""),
      isMain: item.isMain,
      isPubliclyExposed,
      isShareHolder:
        get(item, "isShareHolder", false) === ""
          ? false
          : (item.isShareHolder as boolean),
      originCountry: get(item, "merchantCountry", ""),
      participationPercentage: get(item, "participationPercentage", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType") as PersonTypeEnum,
      socialReason: get(item, "socialReason", ""),
      uid: get(item, "uid", ""),
    };
  }
};

export const createShRequest = (item: IShareHolder): IShareHolderRequest => {
  if (item.personType === PersonTypeEnum.FISICA) {
    return {
      birthdate: dateFormatForMerchantPeople(get(item, "birthdate", "")),
      documentNumber: get(item, "documentNumber", ""),
      documentType: get(item, "documentType", ""),
      id: get(item, "id", ""),
      lastNames: get(item, "lastName", ""),
      names: get(item, "name", ""),
      parent: get(item, "parent", ""),
      participationPercentage: get(item, "participationPercentage", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType"),
      residencyCountry: get(item, "country", ""),
      uid: get(item, "uid", ""),
    };
  } else {
    return {
      addressCountry: get(item, "companyCountry", ""),
      constitutionDate: transformDateToTimestamp(
        get(item, "constitutionDate", ""),
        DateFormatEnum.DD_MMM_YYYY
      ),
      documentNumber: get(item, "taxId", ""),
      id: get(item, "id", ""),
      parent: get(item, "parent", ""),
      participationPercentage: get(item, "participationPercentage", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType") as PersonTypeEnum,
      socialReason: get(item, "socialReason", ""),
      uid: get(item, "uid", ""),
    };
  }
};

export const createBoardMemberRequest = (
  item: IBoardMember
): IBoardMemberRequest => {
  const finalRequest: object = {
    [PersonTypeEnum.FISICA]: {
      birthdate: dateFormatForMerchantPeople(get(item, "birthdate", "")),
      documentNumber: get(item, "documentNumber", ""),
      documentType: get(item, "documentType", ""),
      id: get(item, "id", ""),
      lastNames: get(item, "lastNames", ""),
      names: get(item, "names", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType"),
      residencyCountry: get(item, "residencyCountry", ""),
      uid: get(item, "uid", ""),
    },
    [PersonTypeEnum.MORAL]: {
      addressCountry: get(item, "addressCountry", ""),
      constitutionDate: defaultTo(
        transformDateToTimestamp(
          get(item, "constitutionDate", "") as string,
          DateFormatEnum.DD_MMM_YYYY
        ),
        0
      ),
      documentNumber: get(item, "documentNumber", ""),
      id: get(item, "id", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType"),
      socialReason: get(item, "socialReason", ""),
      uid: get(item, "uid", ""),
    },
  };

  return finalRequest[item.personType];
};

export const testILegalRepresentative: ILegalRepresentative[] = [
  {
    areaCode: "",
    birthdate: "2022-06-01",
    cellphoneNumber: "222",
    country: "Argentina",
    countryCode: "234423234234",
    documentType: "0",
    email: "a@a.com",
    id: "1",
    isMain: true,
    isPubliclyExposed: false,
    isShareHolder: false,
    lastName: "doe",
    name: "joe",
    nationality: "Aruba",
    participationPercentage: "0",
    personType: "01",
    taxId: "21312312313",
    type: "main",
  },
  {
    areaCode: "",
    birthdate: "2022-06-01",
    cellphoneNumber: "234423234234",
    country: "Australia",
    countryCode: "234423234234",
    documentNumber: "21312312313",
    documentType: "0",
    email: "a@a.com",
    id: "11223",
    isMain: false,
    isPubliclyExposed: false,
    isShareHolder: "",
    lastName: "doe",
    name: "joe",
    nationality: "Aruba",
    participationPercentage: "0",
    personType: "01",
    type: "main",
  },
  {
    areaCode: "",
    birthdate: "2022-06-01",
    cellphoneNumber: "234423234234",
    country: "Australia",
    countryCode: "234423234234",
    documentNumber: "21312312313",
    documentType: "0",
    email: "a@a.com",
    id: "11223",
    isMain: false,
    isShareHolder: "",
    lastName: "doe",
    name: "joe",
    nationality: "Aruba",
    participationPercentage: "0",
    personType: "02",
    type: "main",
  },
  {
    areaCode: "",
    birthdate: "2022-06-01",
    cellphoneNumber: "234423234234",
    country: "Australia",
    countryCode: "234423234234",
    documentNumber: "21312312313",
    documentType: "0",
    email: "a@a.com",
    id: "11223",
    isMain: false,
    isShareHolder: true,
    lastName: "doe",
    name: "joe",
    nationality: "Aruba",
    participationPercentage: "0",
    personType: "02",
    type: "main",
  },
];
export const testISharedHolder: IShareHolder[] = [
  {
    birthdate: "",
    children: [],
    companyCountry: "Australia",
    constitutionDate: "2022-08-03",
    country: "Colombia",
    documentType: "",
    id: "12-347-66",
    lastName: "",
    level: 1,
    merchantCountry: "Belgica",
    name: "",
    participationPercentage: "20%",
    personCategory: "SHAREHOLDER",
    personType: "02",
    socialReason: "Razon social",
    taxId: "123654",
  },
  {
    birthdate: "2022-08-03",
    children: [
      {
        birthdate: "",
        children: [],
        companyCountry: "Australia",
        constitutionDate: "2022-08-03",
        country: "Colombia",
        documentType: "",
        id: "12-347-66",
        lastName: "",
        level: 1,
        merchantCountry: "Belgica",
        name: "",
        participationPercentage: "20%",
        personCategory: "SHAREHOLDER",
        personType: "02",
        socialReason: "Razon social",
        taxId: "123654",
      },
    ],
    companyCountry: "Australia",
    country: "Colombia",
    documentNumber: "21312312313",
    documentType: "",
    id: "12-347-66",
    lastName: "Test",
    level: 1,
    merchantCountry: "Belgica",
    name: "Testing",
    participationPercentage: "20%",
    personCategory: "SHAREHOLDER",
    personType: "01",
    taxId: "123654",
  },
  {
    birthdate: "",
    children: [],
    companyCountry: "Australia",
    constitutionDate: "2022-08-03",
    country: "Colombia",
    documentType: "",
    id: "12-347-66",
    lastName: "",
    level: 1,
    merchantCountry: "Belgica",
    name: "",
    participationPercentage: "20%",
    personCategory: "SHAREHOLDER",
    personType: "02",
    socialReason: "Razon social",
    taxId: "123654",
  },
];

export const testIBoardMember: IBoardMember[] = [
  {
    birthdate: "",
    constitutionDate: "2022-08-03",
    documentNumber: "123536",
    documentType: "",
    id: "12-347-66",
    personCategory: "BOARD_MEMBER",
    personType: PersonTypeEnum.MORAL,
    socialReason: "Razon social",
  },
  {
    birthdate: "2022-08-03",
    documentNumber: "21312312313",
    documentType: "",
    id: "12-347-66",
    lastNames: "Test",
    personCategory: PersonCategoryEnum.BOARD_MEMBER,
    personType: PersonTypeEnum.FISICA,
  },
  {
    birthdate: "2022-08-03",
    documentNumber: "789978678",
    documentType: "",
    id: "12-347-86",
    lastNames: "Test",
    personCategory: PersonCategoryEnum.BOARD_MEMBER,
    personType: PersonTypeEnum.FISICA,
  },
];
