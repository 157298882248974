import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./TextWithLabelStyle";

export const TextWithLabel = (props: {
  label: string;
  style?: React.CSSProperties | undefined;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} style={props.style}>
      <Box className={classes.circle} />
      <Typography className={classes.overline}>{props.label}</Typography>
    </Box>
  );
};
