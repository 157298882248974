import { IDataWebsockets } from "@store/interfaces/AlarmConfigState.interfaces";
import { get } from "lodash";
import { SocketEnum } from "@shared/enum/webSocketActionEnum";
import { setIsLoadingButton } from "@store/reducers/alarmConfig/alarmConfig.slice";
import { clearSelectedNodes } from "@shared/utils/clearSelectedNodes";
import { setNotification } from "@store/reducers/general/general.slice";
import { buildSnackbar } from "@shared/utils/snackBarUtils";
import { SnackBarMessages } from "@shared/enum/snackBarMessages";
import { redirectSaveButton } from "@shared/utils/redirectOptions";
import { setIsLoadingDownload } from "@store/reducers/alarms/alarms.slice";
import { StatusSnackbarEnum } from "@shared/enum/StatusSnackbarEnum";
import { IRuleAlarmRequest } from "@containers/AlarmConfig/AlarmConfig.interfaces";
import { KushkiErrorCodeEnum } from "@shared/enum/KushkiErrorCodeEnum";

export const handleOpenSocket =
  (websocket: WebSocket, params: object) => () => {
    websocket.send(JSON.stringify(params));
  };

export const handleErrorSocket =
  (websocket: WebSocket, dispatch: Function) => () => {
    dispatch(setIsLoadingButton(false));
    websocket.close();
  };

export const handleMessageSocket =
  (webSocket: WebSocket, dispatch: Function, payload: IRuleAlarmRequest) =>
  (event: MessageEvent) => {
    const response: IDataWebsockets = JSON.parse(event.data);
    const status: string = get(response, "status", "");

    if (status === SocketEnum.COMPLETE) {
      webSocket?.close();
      dispatch(
        setNotification(
          buildSnackbar("success", SnackBarMessages.CREATE_ALARM_SUCCESS)
        )
      );
      dispatch(setIsLoadingButton(false));
      clearSelectedNodes(dispatch);
      window.location.href = redirectSaveButton();

      return;
    }

    if (status === SocketEnum.ERROR) {
      get(response, "errorCode", "").includes(
        KushkiErrorCodeEnum.DUPLICATED_MONGO
      )
        ? dispatch(
            setNotification(
              buildSnackbar(
                StatusSnackbarEnum.DANGER,
                SnackBarMessages.ERROR_DUPLICATE_ALARM.replace(
                  "{alias}",
                  get(payload, "alias", "")
                )
              )
            )
          )
        : dispatch(
            setNotification(
              buildSnackbar(
                StatusSnackbarEnum.DANGER,
                SnackBarMessages.CREATE_ALARM_ERROR
              )
            )
          );

      dispatch(setIsLoadingButton(false));
      webSocket?.close();

      return;
    }
  };

export const handleFileDownloadErrorSocket =
  (websocket: WebSocket, dispatch: Function) => () => {
    dispatch(setIsLoadingDownload(false));
    dispatch(
      setNotification(
        buildSnackbar("danger", SnackBarMessages.ERROR_DOWNLOAD_FILE_ALARM)
      )
    );
    websocket.close();
  };

function downloadFile(url: string) {
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", "filename");
  document.body.appendChild(link);
  link.click();

  link!.parentNode!.removeChild(link);
}

export const handleDownloadFileMessageSocket =
  (webSocket: WebSocket, dispatch: Function) => (event: MessageEvent) => {
    const response: IDataWebsockets = JSON.parse(event.data);
    const status: string = get(response, "status", "");

    if (status === SocketEnum.COMPLETE) {
      webSocket?.close();

      dispatch(setIsLoadingDownload(false));
      downloadFile(get(response, "url", ""));

      dispatch(
        setNotification(
          buildSnackbar("success", SnackBarMessages.SUCCESS_DOWNLOAD_FILE_ALARM)
        )
      );

      return;
    }

    if (status === SocketEnum.ERROR) {
      dispatch(
        setNotification(
          buildSnackbar("danger", SnackBarMessages.ERROR_DOWNLOAD_FILE_ALARM)
        )
      );
      dispatch(setIsLoadingDownload(false));
      webSocket?.close();

      return;
    }
  };
