import { CellHeaderProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellHeader";
import { ISort } from "@kushki/connect-ui/dist/Shared/Utils/IconSortArrow/IconSortArrow.interfaces";
import { HeaderTableLabel } from "@shared/enum/HeaderTableLabel";

export enum AlarmFieldNameEnum {
  BRANCH_MERCHANT = "merchantName",
  ACTIVATION_DATE = "createdAt",
  CUSTOMER = "customerName",
  OWNER = "ownerName",
  COUNTRY = "country",
  STATE = "orderStatus",
  FREQUENCY = "orderFrequency",
  CATEGORY = "category",
  CLOSE_DATE = "closeDate",
  ALIAS = "alias",
}

export const alarmsTableCells = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): CellHeaderProps[] => [
  {
    actualSort,
    align: "left",
    field: AlarmFieldNameEnum.ACTIVATION_DATE,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableLabel.ACTIVATION_DATE,
    type: "default",
    width: 300,
  },
  {
    actualSort,
    align: "left",
    field: AlarmFieldNameEnum.BRANCH_MERCHANT,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableLabel.BRANCH_MERCHANT,
    type: "default",
    width: 300,
  },
  {
    actualSort,
    align: "left",
    field: AlarmFieldNameEnum.CUSTOMER,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableLabel.CUSTOMER,
    type: "default",
    width: 400,
  },
  {
    actualSort,
    align: "left",
    field: AlarmFieldNameEnum.COUNTRY,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableLabel.COUNTRY,
    type: "default",
    width: 300,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTableLabel.VARIABLES,
    type: "default",
    width: 300,
  },
  {
    actualSort,
    align: "left",
    field: AlarmFieldNameEnum.STATE,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableLabel.STATE,
    type: "default",
    width: 300,
  },

  {
    align: "left",
    spacing: 0,
    text: "",
    type: "default",
  },
];
