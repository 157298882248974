import React from "react";
import InformationRow from "../InformationRow/InformationRow";
import { IConciliationProcessorInfoProps } from "./ConciliationProcessorInfo.interfaces";

const ConciliationProcessorInfo = ({
  description,
  processorName,
  responseCode,
  transactionReference,
}: IConciliationProcessorInfoProps) => {
  return (
    <>
      <InformationRow label="Procesador" value={processorName} />
      <InformationRow label="Código de respuesta" value={responseCode} />
      <InformationRow
        label="Ref. de transacción"
        value={transactionReference}
      />
      <InformationRow label="Descripción" value={description} />
    </>
  );
};

export default ConciliationProcessorInfo;
