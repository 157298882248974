import React, { FC } from "react";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Pagination,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { TableNodesStyle } from "./TableTransactions.styles";
import {
  TABLE_HEAD_DISABLE_SX,
  TableHeadStyles,
} from "../TableHead/TableHead.style";
import { TableBodyStyles } from "../TableBody/TableBody.style";
import { TableFooterStyles } from "../TableFooter/TableFooter.style";
import { get, set } from "lodash";
import { useTableTransactions } from "./state/useTableTransactions";
import {
  EnhancedTableProps,
  ITableCellProps,
  ITableNodesProps,
  ITableRowProps,
} from "./TableTransactions.interfaces";
import EmptyResults from "../../EmptyResults/EmptyResults";
import { TableMessageEnum } from "../../../shared/enums/DashboardEnum";
import { TableSkeleton } from "../../Skeleton/TableSkeleton/TableSkeleton";
import { HeadCell } from "../TableHead/TableHead.interfaces";
import {
  CELL_COMPONENTS_BY_TABLE_ENUM,
  RowsPerPage,
  TableBodyCellEnum,
} from "../../../shared/constants/table_transactions";
import {
  allowedSortingHeaderEnum,
  HeaderColumnIdEnum,
} from "../../../shared/enums/HeaderColumnIdEnum";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  setClickCount,
  setWatchFieldName,
} from "../../../store/actions/app.actions";
import { InfoOutlined } from "@mui/icons-material";

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    order,
    isBranchTab,
    onSelectAllClick,
    indeterminate,
    allChecked,
    setSortQuery,
  } = props;
  const headCellActive: HeadCell[] = headCells.filter((cell) => cell.isActive);

  const { watchFieldName, clickCount } = useAppSelector((state) => ({
    ...state.app,
  }));
  const dispatchApp = useAppDispatch();

  const handleButtonClick = (fieldName: string) => {
    let clickClone = clickCount + 1;

    dispatchApp(setClickCount(clickClone));

    let sortingOrder = clickClone % 2 === 0 ? "desc" : "asc";

    if (fieldName !== watchFieldName) {
      dispatchApp(setWatchFieldName(fieldName));
      dispatchApp(setClickCount(1));
      clickClone = 1;
      sortingOrder = "asc";
    }

    const query = {
      field: fieldName,
      order: sortingOrder,
    };

    setSortQuery(query);
  };

  return (
    <TableHead
      sx={TABLE_HEAD_DISABLE_SX[`${!!false}`]}
      data-testid={"EnhancedTableHead"}
    >
      <TableRow sx={TableHeadStyles.row}>
        {!isBranchTab && (
          <TableCell padding="checkbox">
            <Checkbox
              onChange={onSelectAllClick}
              color="primary"
              checked={allChecked}
              indeterminate={indeterminate}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
        )}
        {headCellActive.map((headCell) => (
          <TableCell
            sx={TableHeadStyles.label}
            key={headCell.id}
            align={headCell.align ? headCell.align : "center"}
            sortDirection={order}
          >
            <div style={{ alignItems: "center", display: "flex" }}>
              {headCell.hasTooltip ? (
                <Tooltip title={`${headCell.tooltipLabel}`}>
                  <div
                    style={{ alignItems: "center", display: "flex", flex: 1 }}
                  >
                    {headCell.label} <InfoOutlined style={{ marginLeft: 10 }} />
                  </div>
                </Tooltip>
              ) : (
                <div style={{ flex: 1 }}>{headCell.label}</div>
              )}
              <div style={{ flex: 2, padding: 0 }}>
                {allowedSortingHeaderEnum.includes(headCell.id) && (
                  <IconButton
                    onClick={() => handleButtonClick(headCell.id)}
                    color={order === "desc" ? "primary" : "default"}
                    data-testid={"iconButton"}
                  >
                    <SvgIcon
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      fontSize={"small"}
                    >
                      <path
                        d="M0.666992 2.83366L4.00033 0.166992L7.33366 2.83366H0.666992Z"
                        fill={
                          watchFieldName !== headCell.id
                            ? "#B4B9BE"
                            : clickCount === 0
                            ? "#B4B9BE"
                            : clickCount % 2 === 0
                            ? "#B4B9BE"
                            : "#103365"
                        }
                      />
                      <path
                        d="M0.666992 8.16699L4.00033 10.8337L7.33366 8.16699H0.666992Z"
                        fill={
                          watchFieldName !== headCell.id
                            ? "#B4B9BE"
                            : clickCount !== 0 && clickCount % 2 === 0
                            ? "#103365"
                            : "#B4B9BE"
                        }
                      />
                    </SvgIcon>
                  </IconButton>
                )}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const TableTransactions: FC<ITableNodesProps> = ({
  headTable,
  labelRowsPerPage,
  rows,
  handleSelectedRows,
  handleSortQuery,
  paginationProps: {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    totalData,
  },
  isBranchTab,
  handleOpenDialog,
  setTransactionInfo,
  setIsOpenModalDetailInfo,
  setRowSelectChargeback,
  setSelectedChargebackData,
}: ITableNodesProps) => {
  const {
    handleGetRow,
    headerProps: { order, orderBy },
    totalPages,
    isLoadingTransaction,
    rowsProps: {
      check: {
        allChecked,
        handleClick,
        handleSelectAllClick,
        isSelected,
        selected,
        indeterminate,
      },
    },
    isUserRole,
  } = useTableTransactions({
    handleSelectedRows,
    rows,
    rowsPerPage,
    setIsOpenModalDetailInfo,
    setTransactionInfo,
    totalData,
  });

  return (
    <>
      {isLoadingTransaction ? (
        <TableSkeleton />
      ) : (
        <Box>
          <TableContainer>
            <Box>
              <Table sx={TableNodesStyle.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  onSelectAllClick={handleSelectAllClick}
                  numSelected={selected.length}
                  headCells={headTable}
                  sx={TableHeadStyles}
                  allChecked={allChecked}
                  indeterminate={indeterminate}
                  order={order}
                  orderBy={orderBy}
                  rowCount={rows.length}
                  isBranchTab={isBranchTab}
                  setSortQuery={handleSortQuery}
                />
                {totalData > 0 && (
                  <TableBody sx={TableBodyStyles.body}>
                    {rows.map((row: ITableRowProps, index: number) => {
                      const isItemSelected: boolean = isSelected(
                        get(row.info, "_source")
                      );

                      return (
                        <TableRow
                          aria-label={`table-row-${row.id}`}
                          hover
                          role="checkbox"
                          selected={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          sx={TableBodyStyles.row}
                        >
                          {row.cells.map(
                            (
                              { type, props }: ITableCellProps,
                              cellIndex: number
                            ) => {
                              const CellComponent =
                                CELL_COMPONENTS_BY_TABLE_ENUM[type];

                              if (type === TableBodyCellEnum.CHECK) {
                                set(props, "isChecked", isItemSelected);
                                set(props, "handleClick", handleClick);
                                set(props, "indeterminate", indeterminate);
                                set(props, "id", row.id);
                                set(props, "data", get(row.info, "_source"));
                              }

                              if (type === TableBodyCellEnum.ACTION_MENU) {
                                set(props, "rowId", row.id);
                              }

                              return (
                                <TableCell
                                  key={`row-${index}-cell-${cellIndex}`}
                                  scope="row"
                                  padding="none"
                                  align={
                                    get(props, "line1", "") ===
                                    HeaderColumnIdEnum.DEFAULT_VALUE
                                      ? "center"
                                      : get(props, "align", "center")
                                  }
                                  onClick={() => {
                                    if (type !== TableBodyCellEnum.CHECK)
                                      handleGetRow(
                                        row.id,
                                        type !== TableBodyCellEnum.ACTION_MENU
                                      );
                                    setRowSelectChargeback(
                                      get(row, "info._source.merchant_id", "")
                                    );
                                    setSelectedChargebackData(
                                      get(row, "info._source", "")
                                    );
                                  }}
                                >
                                  <CellComponent
                                    {...props}
                                    isUserRole={isUserRole}
                                    handleOpenDialog={handleOpenDialog}
                                    key={`row-${index}-cell-${cellIndex}-component-${type}`}
                                  />
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </TableContainer>
          {totalData > 0 ? (
            <Grid
              sx={TableNodesStyle.tableFooter}
              marginTop={-2}
              container
              spacing={1}
            >
              <Grid display="flex" justifyContent="left" item xs={6}>
                <TablePagination
                  rowsPerPageOptions={[RowsPerPage.DEFAULT]}
                  component="div"
                  count={totalData}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={labelRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  align="left"
                />
              </Grid>
              <Grid display="flex" justifyContent="right" item xs={6}>
                <Pagination
                  sx={TableFooterStyles.pagination}
                  count={totalPages}
                  page={page + 1}
                  onChange={(event: unknown, newPage: number) => {
                    handleChangePage(event, newPage - 1);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <EmptyResults
              addText={TableMessageEnum.EMPTY_DATA_BODY}
              title={TableMessageEnum.EMPTY_DATA_TITLE}
            />
          )}
        </Box>
      )}
    </>
  );
};
