import React from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { IFormControlProps } from "@components/FormControl/FormControl.interfaces";
import InputsForm from "@components/InputsForm/InputsForm";

const FormControl: React.FC<IFormControlProps> = ({
  options,
  name,
  label,
  rules,
  inputType,
  disabled,
  fieldName,
}: IFormControlProps) => {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      name={name}
      rules={{ ...rules }}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputsForm
            label={label}
            field={field}
            fieldName={fieldName}
            inputType={inputType}
            options={options}
            error={error}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default FormControl;
