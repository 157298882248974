import { ActionRefundEnum } from "../enums/ActionEnum";

export enum ProcessTransactionMessagesEnum {
  SUCCESS_MASSIVE_MESSAGE = "Transacciónes enviadas a autorizar",
  FAILED_MASSIVE_MESSAGE = "Ha ocurrido un error al enviar a autorizar. Inténtalo nuevamente",
  ERROR_OMIT_MESSAGE = "Internal server error",
}

export const ProcessTransactionMessagesSuccessEnum: Record<string, string> = {
  [ActionRefundEnum.REFUND]:
    ProcessTransactionMessagesEnum.SUCCESS_MASSIVE_MESSAGE,
  [ActionRefundEnum.GIVEBACK]:
    ProcessTransactionMessagesEnum.SUCCESS_MASSIVE_MESSAGE,
  [ActionRefundEnum.OMIT]:
    ProcessTransactionMessagesEnum.SUCCESS_MASSIVE_MESSAGE,
  [ActionRefundEnum.CANCEL]:
    ProcessTransactionMessagesEnum.SUCCESS_MASSIVE_MESSAGE,
  [ActionRefundEnum.APPROVE]: "Transacción Autorizada",
  [ActionRefundEnum.NOAPPROVE]: "Transacción no autorizada",
};

export const ProcessTransactionMessagesFailedEnum: Record<string, string> = {
  [ActionRefundEnum.REFUND]:
    ProcessTransactionMessagesEnum.FAILED_MASSIVE_MESSAGE,
  [ActionRefundEnum.GIVEBACK]:
    ProcessTransactionMessagesEnum.FAILED_MASSIVE_MESSAGE,
  [ActionRefundEnum.OMIT]:
    ProcessTransactionMessagesEnum.FAILED_MASSIVE_MESSAGE,
  [ActionRefundEnum.CANCEL]:
    ProcessTransactionMessagesEnum.FAILED_MASSIVE_MESSAGE,
  [ActionRefundEnum.APPROVE]:
    "Ha ocurrido un error en la autorización. Inténtalo nuevamente.",
  [ActionRefundEnum.NOAPPROVE]:
    "Ha ocurrido un error en la NO autorización. Inténtalo nuevamente.",
};
