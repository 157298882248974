import React from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { MerchantUsers } from "../../../../types/merchant_users";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  getRoles,
  mapMerchantUser,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { IUser } from "../../../shared/infrastructure/interfaces/IUser";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
  },
  containedButton: {
    fontSize: "16px",
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
export interface IUserSectionSummary {
  publicMerchantId: string;
  merchantUsers?: MerchantUsers;
  name: string;
}

export const UserSectionSummary: React.FC<Partial<IUserSectionSummary>> = (
  _: Partial<IUserSectionSummary>
) => {
  const history = useHistory();
  const classes = useStyles();
  const stateProps: Partial<IUserSectionSummary> = useSelector(
    (state: IAppState) => ({
      merchantUsers: state.merchantUsers,
      publicMerchantId: get(state, "merchantAffiliation.publicMerchantId", ""),
      name: get(state, "merchantAffiliation.name", ""),
    })
  );
  const users = mapMerchantUser(
    get(stateProps, "merchantUsers", { Users: [] })
  );

  return (
    <React.Fragment>
      <Grid
        container
        component={"div"}
        className={classes.container}
        spacing={2}
        justify="space-around"
      >
        <Grid item xs={12}>
          <Box
            mt={2}
            display="flex"
            flexDirection="column"
            bgcolor="background.default"
            borderRadius={4}
            px={3}
            pb={3}
          >
            <TableContainer className={classes.paper}>
              <Table aria-label="users table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="h6" color="textPrimary">
                        <Box fontWeight={500}>Usuarios agregados</Box>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        className={classes.containedButton}
                        color="primary"
                        size="medium"
                        onClick={() =>
                          history.push(
                            `/users/user?publicMerchantId=${stateProps.publicMerchantId}&merchantName=${stateProps.name}&hideSideBar=true`
                          )
                        }
                      >
                        Agregar Usuario
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row: IUser) => (
                    <TableRow key={row.name}>
                      <TableCell>
                        {<Box fontWeight={300}>{row.name}</Box>}
                      </TableCell>
                      <TableCell align="right">
                        {<Box fontWeight={300}>{row.email}</Box>}
                      </TableCell>
                      <TableCell>
                        <Box fontWeight={300}>{getRoles(row.roles)}</Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3} px={2}>
              <Link
                onClick={() =>
                  history.push(
                    `/users?publicMerchantId=${stateProps.publicMerchantId}&merchantName=${stateProps.name}&hideSideBar=true`
                  )
                }
                color="inherit"
                variant="h6"
                className={classes.link}
              >
                Edita y revisa todos los usuarios
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
