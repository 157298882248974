import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Filter, Search } from "react-feather";
import BoxFilter, { IChipSelect, IOptionFilter } from "./BoxFilter/BoxFilter";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IBillingDashboardState, INotification } from "../../store/reducer";
import {
  BillingActions,
  processBillingData,
  setBillingDataCounter,
  setNotification,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { TransactionTable } from "../../../types/transaction_table";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { StatusEnum } from "../../shared/infrastructure/StatusEnum";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { ButtonDownloadFile } from "../ButtonDownloadFile/ButtonDownloadFile";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { RangeAmount } from "../../../types/range_amount";
import { InvoiceModeEnum } from "../../shared/infrastructure/InvoiceModeEnum";
import imgSettings from "../../assets/images/⚙️settings.svg";
import { ColumnFilters } from "./ColumnFilters/ColumnFilters";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { get, includes, isEmpty } from "lodash";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import {
  columnsTableBilling,
  IColumnsTable,
} from "../../shared/infrastructure/table/IColumnsTable";
import { CutoffHours } from "../../shared/infrastructure/constants/FilterDashboardConstants";
import { DateRange } from "@material-ui/pickers";
import { useStyles } from "./FilterDashboard.style";
import { SmartlinkDatePicker } from "../DatePicker/SmartlinkDatePicker";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import {
  RolesExecutorEnum,
  RolesValidatorEnum,
} from "../../shared/infrastructure/enum/Roles/RolesEnum";

export interface FilterDashboardFunctionsProps {
  processBillingData: () => void;
  setNotification: (payload: INotification) => void;
  setBillingDataCounter: (payload: { contador: number; total: number }) => void;
}

export interface FilterDashboardStateProps {
  handleClose: () => void;
  filters: IOptionFilter[];
  isLoadingProcessBilling?: boolean;
  handleGetFilters: (optionsChip: IChipSelect[]) => void;
  handleChangeSearch: (value: string) => void;
  handleSearchBar: (key: string, valueSearch: string) => void;
  handleSetDate: (dateRange: DateRange) => void;
  merchantSearch: string;
  counterFilter: number;
  country?: string;
  statusFlow: StatusFlowEnum;
  rangeAmount?: RangeAmount;
  range: DateRange;
  downloadFileState: {
    isLoading: boolean;
    isDownloadingCheck: boolean;
    downloadFile: (
      format: string,
      isAll?: boolean,
      selectedTransactions?: InvoiceRecord | undefined
    ) => void;
  };
  settingsColumns: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    handleAppliedColumnsFilters: (restore?: boolean) => void;
  };
}

export interface FilterDashboardComponentProps {
  billingData: TransactionTable[];
  billingDataCounter: { contador: number; total: number };
}

export type FilterDashboardProps = FilterDashboardFunctionsProps &
  FilterDashboardStateProps &
  FilterDashboardComponentProps;

export const FilterDashboard: React.FC<FilterDashboardProps> = (
  props: FilterDashboardProps
) => {
  const { statusFlow, isLoadingProcessBilling } = props;
  const classes = useStyles();
  const [buttonLabel, setButtonLabel] = React.useState<string>(
    statusFlow === StatusFlowEnum.EXECUTOR ? "Ejecutar" : "Procesar"
  );
  const [searchValue, setSearchValue] = React.useState<string>(
    props.merchantSearch
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [
    anchorColumns,
    setAnchorColumns,
  ] = React.useState<HTMLButtonElement | null>(null);
  const openColumns = Boolean(anchorColumns);
  const idButtonColumns = openColumns ? "filter-popover" : undefined;
  const getSelectableColumns = (
    columnsTable: IColumnsTable[]
  ): IColumnsTable[] => {
    const isCountryPeru: boolean = props.country === CountryEnum.peru;
    const hasInterbankAccountCode = (column: IColumnsTable): boolean =>
      column.id === "interbankAccountCode";
    const columns: IColumnsTable[] = isCountryPeru
      ? columnsTable.filter(
          (column: IColumnsTable) =>
            column.filter || hasInterbankAccountCode(column)
        )
      : columnsTable.filter(
          (column: IColumnsTable) =>
            column.filter && !hasInterbankAccountCode(column)
        );

    return columns;
  };
  const [selectableColumns] = useState<IColumnsTable[]>(
    getSelectableColumns(columnsTableBilling)
  );

  const [
    openProcessTransactionsModal,
    setOpenProcessTransactionsModal,
  ] = React.useState<boolean>(false);

  const [
    openProcessLoaderModal,
    setOpenProcessLoaderModal,
  ] = React.useState<boolean>(false);

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    if (value === "") props.handleChangeSearch("");
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleProcessBillingData = () => () => {
    setOpenProcessTransactionsModal(true);
  };

  const isVisibleResume = (kind: string): boolean => {
    const filters: IOptionFilter[] = props.filters.filter(
      (filter: IOptionFilter) => filter.id === "kind"
    );
    const kind_filters: {
      key: string;
      label: string;
      selected: boolean;
    }[] = isEmpty(filters) ? [] : filters[0].options;

    return (
      kind_filters.filter(
        (kind_filter: { key: string; label: string; selected: boolean }) =>
          kind_filter.key === kind
      ).length > 0
    );
  };

  const obtainCutoffDate = (country: string | undefined) => {
    switch (country) {
      case CountryEnum.ecuador:
        return {
          dateStart: CutoffHours.ECUADOR,
          dateEnd: CutoffHours.ECUADOR,
        };
      case CountryEnum.colombia:
        return {
          dateStart: CutoffHours.COLOMBIA_START,
          dateEnd: CutoffHours.COLOMBIA_END,
        };
      case CountryEnum.chile:
        return {
          dateStart: CutoffHours.CHILE,
          dateEnd: CutoffHours.CHILE,
        };
      case CountryEnum.peru:
        return {
          dateStart: CutoffHours.PERU,
          dateEnd: CutoffHours.PERU,
        };
      default:
        return {
          dateStart: CutoffHours.DEFAULT,
          dateEnd: CutoffHours.DEFAULT,
        };
    }
  };

  const loadProcessResume = () => {
    const { billingData, country } = props;
    const invoice = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.INVOICE
    ).length;
    const invoice_with_iva = billingData.filter(
      (value) => value.transaction?.invoiceMode === InvoiceModeEnum.WITH_IVA
    ).length;
    const invoice_without_iva = billingData.filter(
      (value) => value.transaction?.invoiceMode === InvoiceModeEnum.WITHOUT_IVA
    ).length;
    const charge = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.CHARGE
    ).length;
    const to_charge = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.RECEIVABLE
    ).length;
    const dispersion = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.DISPERSION
    ).length;
    const retention = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.RETENTION
    ).length;
    const credit_notes = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.CREDIT_NOTE
    ).length;
    const debit_notes = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.DEBIT_NOTE
    ).length;
    const voucher = billingData.filter(
      (value) => value.transaction?.kind === KindEnum.VOUCHER
    ).length;
    const ommited = billingData.filter(
      (value) => value.status === StatusEnum.OMIT
    ).length;
    const pending = billingData.filter(
      (value) => value.status === StatusEnum.PENDING
    ).length;
    const process = billingData.filter(
      (value) =>
        value.status === StatusEnum.PROCESS &&
        value.statusRow !== StatusEnum.REPROCESS
    ).length;
    const reject = billingData.filter(
      (value) => value.status === StatusEnum.REJECT
    ).length;
    const reprocess = billingData.filter(
      (value) =>
        includes(
          [
            KindEnum.INVOICE,
            KindEnum.VOUCHER,
            KindEnum.CHARGE,
            KindEnum.DISPERSION,
            KindEnum.RECEIVABLE,
          ],
          value.transaction?.kind
        ) && value.statusRow === StatusEnum.REPROCESS
    ).length;
    const cutOffValues = obtainCutoffDate(country);
    return (
      <>
        <table>
          <>
            {isVisibleResume(InvoiceModeEnum.WITH_IVA) && (
              <tr>
                <td className={classes.td}>
                  <Typography variant="caption">Facturas con IVA</Typography>
                </td>
                <td>
                  <Typography variant="caption">{invoice_with_iva}</Typography>
                </td>
              </tr>
            )}
            {isVisibleResume(InvoiceModeEnum.WITHOUT_IVA) && (
              <tr>
                <td className={classes.td}>
                  <Typography variant="caption">Facturas sin IVA</Typography>
                </td>
                <td>
                  <Typography variant="caption">
                    {invoice_without_iva}
                  </Typography>
                </td>
              </tr>
            )}
          </>
          {isVisibleResume(KindEnum.INVOICE) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Facturación</Typography>
              </td>
              <td>
                <Typography variant="caption">{invoice}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.VOUCHER) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Boletas</Typography>
              </td>
              <td>
                <Typography variant="caption">{voucher}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.DISPERSION) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Dispersión</Typography>
              </td>
              <td>
                <Typography variant="caption">{dispersion}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.CHARGE) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Cobro</Typography>
              </td>
              <td>
                <Typography variant="caption">{charge}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.RECEIVABLE) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Por Cobrar</Typography>
              </td>
              <td>
                <Typography variant="caption">{to_charge}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.RETENTION) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Retención</Typography>
              </td>
              <td>
                <Typography variant="caption">{retention}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.CREDIT_NOTE) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Notas de Crédito</Typography>
              </td>
              <td>
                <Typography variant="caption">{credit_notes}</Typography>
              </td>
            </tr>
          )}
          {isVisibleResume(KindEnum.DEBIT_NOTE) && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Notas de Débito</Typography>
              </td>
              <td>
                <Typography variant="caption">{debit_notes}</Typography>
              </td>
            </tr>
          )}
        </table>
        {country === CountryEnum.chile &&
          isVisibleResume(KindEnum.CREDIT_NOTE) && (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.processModalInfo}
            >
              <InfoOutlinedIcon htmlColor="#FBC748" />
              <Typography
                variant="caption"
                align="justify"
                className={classes.credit_note_alert}
              >
                Recuerda que debes procesar las Notas de Crédito manualmente
              </Typography>
            </Grid>
          )}
        <Divider className={classes.divider} />
        <table>
          {statusFlow === StatusFlowEnum.VALIDATOR ? (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Total pendientes:</Typography>
              </td>
              <td>
                <Typography variant="caption">{pending}</Typography>
              </td>
            </tr>
          ) : (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Total omitidas:</Typography>
              </td>
              <td>
                <Typography variant="caption">{ommited}</Typography>
              </td>
            </tr>
          )}
          <tr>
            <td className={classes.td}>
              <Typography variant="caption">Total a procesar:</Typography>
            </td>
            <td>
              <Typography variant="caption">{process}</Typography>
            </td>
          </tr>
          {statusFlow === StatusFlowEnum.VALIDATOR && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Total a rechazar</Typography>
              </td>
              <td>
                <Typography variant="caption">{reject}</Typography>
              </td>
            </tr>
          )}
          {statusFlow === StatusFlowEnum.VALIDATOR && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Total a reprocesar:</Typography>
              </td>
              <td>
                <Typography variant="caption">{reprocess}</Typography>
              </td>
            </tr>
          )}
          {statusFlow === StatusFlowEnum.EXECUTOR && (
            <tr>
              <td className={classes.td}>
                <Typography variant="caption">Total a reproceso:</Typography>
              </td>
              <td>
                <Typography variant="caption">{reprocess}</Typography>
              </td>
            </tr>
          )}
        </table>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.processModalInfo}
        >
          <Typography variant={"overline"} color={"textSecondary"}>
            Te quedan {pending} operaciones pendiente por revisar.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.processModalInfo}>
          <Box display="flex" flexGrow={1} className={classes.itemCenter}>
            <InfoOutlinedIcon htmlColor="#FBC748" />
            <Typography
              variant="caption"
              align="justify"
              className={classes.marginLeft}
            >
              Recuerda que todos los registros enviados a procesar hasta{" "}
              {cutOffValues.dateStart} serán enviados en el archivo del día de
              hoy. Todos los registros enviados a procesar después de{" "}
              {cutOffValues.dateEnd} se enviarán en el archivo del día
              siguiente.
            </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  const handleCloseProcessTransactionsModal = () => () => {
    setOpenProcessTransactionsModal(false);
  };

  const handleCloseProcessLoaderModal = () => () => {
    setOpenProcessLoaderModal(false);
  };

  const handleAcceptProcessTransactions = () => () => {
    setOpenProcessTransactionsModal(false);
    props.processBillingData();
    setOpenProcessLoaderModal(true);
  };

  const handleClickColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColumns(event.currentTarget);
  };
  const handleCloseColumns = () => {
    setAnchorColumns(null);
  };
  const handleChangeColumnCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const column: string = event.target.name;
    let auxColumnsSelected: string[] = [
      ...props.settingsColumns.selectedColumns,
    ];
    if (!event.target.checked) {
      const index: number = auxColumnsSelected.findIndex(
        (col: string) => col === column
      );

      if (index !== -1) auxColumnsSelected.splice(index, 1);
    } else {
      if (props.settingsColumns.selectedColumns.length > 2) return;
      auxColumnsSelected = [...auxColumnsSelected, column];
    }
    props.settingsColumns.setSelectedColumns(auxColumnsSelected);
  };
  const handleRestoreColumns = () => {
    props.settingsColumns.handleAppliedColumnsFilters(true);
    handleCloseColumns();
  };
  const handleDisplayedColumnUser = () => {
    props.settingsColumns.handleAppliedColumnsFilters();
    handleCloseColumns();
  };
  const handleDate = (date: DateRange | MouseEvent): void => {
    if (get(date, "movementX") === 0) {
      props.handleSetDate([null, null]);

      return;
    }

    props.handleSetDate(date as DateRange);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;
  const countriesEnabledDownloadFileButton: boolean = [
    CountryEnum.colombia,
    CountryEnum.mexico,
    CountryEnum.peru,
    CountryEnum.ecuador,
    CountryEnum.chile,
  ].some((elem: string) => elem === props.country);

  const contextText =
    statusFlow === StatusFlowEnum.VALIDATOR
      ? "Estás a punto de generar el cobro de este período. Esta acción no se puede deshacer."
      : "Estás a punto de enviar a revisión, estos archivos. Esta acción no se puede deshacer.";

  useEffect(() => {
    if (isLoadingProcessBilling) {
      setButtonLabel(
        statusFlow === StatusFlowEnum.EXECUTOR ? "Ejecutando" : "Procesando"
      );
      return;
    }
    setButtonLabel(
      statusFlow === StatusFlowEnum.EXECUTOR ? "Ejecutar" : "Procesar"
    );
  }, [isLoadingProcessBilling]);
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        className={classes.filterDash}
        alignItems="center"
        direction="row"
      >
        <Grid item>
          <Button
            disabled={isLoadingProcessBilling}
            id="button_popover_filter"
            aria-describedby={idPopover}
            className={classes.kshBtnFilter}
            variant="outlined"
            size="small"
            startIcon={
              <Filter
                className={classes.filter}
                xlinkTitle={"Filtrar"}
                size={12}
              />
            }
            onClick={handleOpenPopover}
          >
            <Badge
              color="secondary"
              className={classes.filter}
              badgeContent={props.counterFilter}
            >
              <Typography className={classes.filter} variant="h6">
                Filtrar
              </Typography>
            </Badge>
          </Button>
          <BoxFilter
            idPopover={idPopover}
            openPopover={openPopover}
            anchorEl={anchorEl}
            filters={props.filters}
            rangeAmount={props.rangeAmount}
            handleApplyFilters={props.handleGetFilters}
            handleClosePopover={handleClosePopover}
          />
        </Grid>
        <Grid item xs={3}>
          <SmartlinkDatePicker
            value={props.range}
            disableFuture={false}
            handleDate={handleDate}
          />
        </Grid>
        <Grid item xs={3}>
          <Box display="flex">
            <TextField
              id="merchant-search"
              key="merchantSearch"
              value={searchValue}
              disabled={isLoadingProcessBilling}
              className={classes.textField}
              margin="dense"
              size="small"
              label="Tax ID, Comercio, Razón Social"
              type="search"
              variant="outlined"
              onKeyDown={(event: any) => {
                if (event.key !== "Enter") return;
                props.handleSearchBar(event.key, searchValue);
              }}
              onChange={(e) => handleChangeSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search size={16} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item justify={"flex-end"}>
          <Box className={classes.iconButton}>
            <IconButton
              aria-label="settings-columns"
              color="primary"
              onClick={handleClickColumns}
            >
              <img src={imgSettings} />
            </IconButton>
            <ColumnFilters
              openColumns={openColumns}
              idButtonColumns={idButtonColumns}
              anchorColumns={anchorColumns}
              handleCloseColumns={handleCloseColumns}
              selectedColumns={props.settingsColumns.selectedColumns}
              handleChangeColumnCheck={handleChangeColumnCheck}
              handleRestoreColumns={handleRestoreColumns}
              handleDisplayedColumnUser={handleDisplayedColumnUser}
              selectableColumns={selectableColumns}
              title={"Elige hasta 2 columnas para mostrar"}
              admittedColumns={2}
            />
          </Box>
        </Grid>
        <Grid item xs={4} justify={"flex-end"}>
          <Box
            display="flex"
            alignItems={"center"}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
          >
            {countriesEnabledDownloadFileButton && (
              <ButtonDownloadFile
                buttonV2={true}
                options={[
                  {
                    text: "Descargar en .csv",
                    value: "csv",
                  },
                ]}
                isLoading={props.downloadFileState.isLoading}
                downloadFile={props.downloadFileState.downloadFile}
                isAll={true}
                statusFlow={statusFlow}
                isHistoric={false}
                isRetention={false}
              />
            )}
            <ComponentSecurityWrapper
              componentId={
                props.statusFlow == StatusFlowEnum.EXECUTOR
                  ? RolesExecutorEnum.M_BILLING_EXECUTE
                  : RolesValidatorEnum.M_BILLING_EXECUTE
              }
            >
              <Button
                id="button_process_billing_data"
                variant="contained"
                color="primary"
                disabled={isLoadingProcessBilling}
                className={classes.kshBtnProcess}
                style={{
                  padding: "7px 30px",
                  marginLeft: countriesEnabledDownloadFileButton ? "20px" : "0",
                }}
                onClick={handleProcessBillingData()}
              >
                <Typography>{buttonLabel}</Typography>
                {isLoadingProcessBilling && (
                  <CircularProgress
                    color={"secondary"}
                    size={25}
                    style={{ marginLeft: "5px" }}
                  />
                )}
              </Button>
            </ComponentSecurityWrapper>
            <LoaderModal
              open={openProcessLoaderModal}
              message="Estamos procesando tus documentos."
              message1="No cierres esta ventana."
              billingDataCounter={props.billingDataCounter}
              handleClose={handleCloseProcessLoaderModal()}
            />
            <ConfirmModal
              open={openProcessTransactionsModal}
              titleText={"¿Deseas mandar a procesar todas las operaciones?"}
              contentText={contextText}
              acceptText={
                statusFlow === StatusFlowEnum.EXECUTOR
                  ? "Mandar a revisión"
                  : "Mandar a procesar"
              }
              handleClose={handleCloseProcessTransactionsModal()}
              handleAccept={handleAcceptProcessTransactions()}
              acceptColor="primary"
            >
              {loadProcessResume()}
            </ConfirmModal>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <CircularProgress />
      <CircularProgress color="secondary" />
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IBillingDashboardState
) => FilterDashboardComponentProps = (
  state: IBillingDashboardState
): FilterDashboardComponentProps => ({
  billingData: state.billingData,
  billingDataCounter: state.billingDataCounter,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => FilterDashboardFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): FilterDashboardFunctionsProps => ({
  processBillingData: (): void => dispatch(processBillingData()),
  setBillingDataCounter: (payload: {
    contador: number;
    total: number;
  }): void => {
    dispatch(setBillingDataCounter(payload));
  },
  setNotification: (payload: INotification) => {
    dispatch(setNotification(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterDashboard);
