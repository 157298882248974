import React, { FC } from "react";
import { useBranchList } from "./state/useBranchList";
import { Box } from "@mui/material";
import { displayUtils } from "../../shared/utils/DisplayUtils";
import { TableSkeleton } from "../Skeleton/TableSkeleton/TableSkeleton";
import {
  CATALOG_HEADS,
  TableTypeEnum,
} from "../../shared/catalogs/CatalogConfigTable";
import DashboardBranch from "../DashboardBranch/DashboardBranch";

const BranchList: FC = () => {
  const {
    showEmptyScreen,
    emptyAddText,
    branchList,
    entityName,
    pathCustomer,
    isLoadingMerchants,
  } = useBranchList();

  return (
    <>
      <Box display={displayUtils(!isLoadingMerchants)}>
        <TableSkeleton
          headTable={CATALOG_HEADS[TableTypeEnum.BRANCH]}
          rowsPerPage={5}
        />
      </Box>

      <Box
        display={displayUtils(isLoadingMerchants)}
        sx={{ overflowX: "scroll", overflowY: "hidden" }}
      >
        <DashboardBranch
          showEmptyScreen={showEmptyScreen}
          emptyAddText={emptyAddText}
          nodeData={branchList}
          basicNodeInfo={{
            entityName,
            path: pathCustomer,
          }}
          isBranchTab={true}
        />
      </Box>
    </>
  );
};

export default BranchList;
