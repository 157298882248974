import { Table, TableBody, TableContainer, TableHead } from "@mui/material";
import React from "react";
import { B2CRefundTransaction } from "../../../../../types/b2c_refund_transaction";
import { ITableColumn } from "../../../../shared/interfaces/Table.interfaces";
import { TableStyles } from "../../../common/Table/Table.styles";
import TableHistoricalBody from "../TableBody/TableHistoricalBody";
import TableHistoricalHead from "../TableHead/TableHistoricalHead";
import RefundsPagination from "../Pagination/RefundsPagination";
import { usePagination } from "./hooks/usePagination";

export interface HistoricalProps {
  headers: ITableColumn[];
  data: B2CRefundTransaction[];
  onRowClick?: (id: string) => void;
  total: number;
}

const HistoricalTable = ({
  headers,
  data,
  onRowClick,
  total,
}: HistoricalProps) => {
  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } =
    usePagination();

  return (
    <>
      <TableContainer sx={TableStyles.container}>
        <Table>
          <TableHead>
            <TableHistoricalHead columns={headers} />
          </TableHead>
          <TableBody>
            <TableHistoricalBody
              data={data}
              columns={headers}
              onRowClick={onRowClick}
            />
          </TableBody>
        </Table>
        <RefundsPagination
          total={total}
          changePage={handleChangePage}
          page={page}
          changeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default HistoricalTable;
