import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  labelTitleNotActive: {
    color: "#B4B9BE",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
    pointerEvents: "none",
    cursor: "default",
  },
  descriptionNotActive: {
    color: "#B4B9BE",
    fontSize: "13px",
    fontStyle: "normal",
    paddingTop: "4px",
    paddingRight: "18px",
  },
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "13px",
    fontStyle: "normal",
    paddingTop: "4px",
    paddingRight: "18px",
  },
  underLineLink: {
    textDecoration: "none",
  },
}));

interface IConfigSubsectionProps {
  link: string;
  title?: string;
  titleNotActive?: string;
  description: string;
  hidden?: boolean;
}

export const ConfigSubsection: React.FC<IConfigSubsectionProps> = (
  props: IConfigSubsectionProps
) => {
  const style = useStyles();

  if (props.titleNotActive) {
    return (
      <React.Fragment>
        {!props.hidden && (
          <Grid item xs={12} md={6}>
            <Box pt={1} pr={1} pb={2}>
              <Typography className={style.labelTitleNotActive}>
                {props.titleNotActive}
              </Typography>
              <Typography className={style.descriptionNotActive}>
                {props.description}
              </Typography>
            </Box>
          </Grid>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {!props.hidden && (
          <Grid item xs={12} md={6}>
            <Box pt={1} pr={1} pb={2}>
              <Link
                component={RouterLink}
                className={style.underLineLink}
                to={props.link}
              >
                <Typography className={style.labelTitle2}>
                  {props.title}
                </Typography>
                <Typography className={style.labelTitle3}>
                  {props.description}
                </Typography>
              </Link>
            </Box>
          </Grid>
        )}
      </React.Fragment>
    );
  }
};
