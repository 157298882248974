import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { get, isUndefined } from "lodash";
import { IInputsFormProps } from "../../../shared/interfaces/InputsFormProps.interfaces";
import { getRequiredErrorMessages } from "../../../shared/utils/errorMessages/error_messages";

const AllCharsInput: FC<IInputsFormProps> = ({
  name,
  control,
  errors,
  label,
  defaultValue,
  customErrorMessages,
  disabled,
  isRequired,
  sx = {},
}: IInputsFormProps): JSX.Element => {
  return (
    <Controller
      name={name}
      rules={{
        required: isRequired && getRequiredErrorMessages(customErrorMessages),
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          disabled={disabled}
          label={label}
          variant="outlined"
          fullWidth
          margin="normal"
          error={!isUndefined(errors[name])}
          helperText={get(errors, `${name}.message`, "")}
          sx={sx}
        />
      )}
    />
  );
};

export default AllCharsInput;
