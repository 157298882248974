import React, { FC } from "react";
import { Timepicker } from "@kushki/connect-ui";
import { FormControl, Grid } from "@mui/material";
import { useTimePickerTagState } from "./state/useTimePickerTagState";
import { get } from "lodash";

export interface TimePickerTagProps {
  placeHolder: string;
  onChange(value: string): void;
  value: string;
  isDisable: boolean;
}

export const TimePickerComponentSection: FC<TimePickerTagProps> = (
  props: TimePickerTagProps
) => {
  const { calculateTimeStamp, time, checkEmptyValue } = useTimePickerTagState({
    onChange: props.onChange,
    value: props.value,
  });

  return (
    <Grid item xs={12} sm={4}>
      <FormControl
        variant="outlined"
        fullWidth
        onChange={(value) => {
          checkEmptyValue(get(value, "target.value", ""));
        }}
      >
        <Timepicker
          placeholder={props.placeHolder}
          value={time}
          onTimeChange={(value) => {
            calculateTimeStamp(value);
          }}
          timeFormat={"hh:mm:ss"}
          isDisabled={props.isDisable}
          ampm={false}
          isOpen={false}
        />
      </FormControl>
    </Grid>
  );
};
