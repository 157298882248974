import { useEffect, useState } from "react";
import { IUseSelectedBranchInterfaces } from "./IUseSelectedBranch.interfaces";
import { getBranchEditListFromSessionStorage } from "../../../../shared/utils/massiveBranchUtils";
import { SessionStoragePaths } from "../../../../shared/constants/Session_storage_paths";
import { IBranchItem } from "../../../../shared/interfaces/massiveBranch/massiveBranch.interfaces";
import { INDEX_PATTERN_MATCHING } from "../../../../shared/constants/massiveEdit/massiveEditEnums";
import { get } from "lodash";

export const useSelectedBranch = (): IUseSelectedBranchInterfaces => {
  const branchItems: IBranchItem[] = getBranchEditListFromSessionStorage(
    SessionStoragePaths.BRANCH_EDIT_LIST
  ).filter((branchItems: IBranchItem) => get(branchItems, "edit", false));

  const [filterText, setFilter] = useState<string>("");
  const [filtered, setFiltered] = useState<IBranchItem[]>(branchItems);

  const handleFilter = (valueToEval: string) => setFilter(valueToEval);

  useEffect(() => {
    setFiltered(
      branchItems.filter((branch: IBranchItem) =>
        filterText.length > 0
          ? branch.name.toLowerCase().indexOf(filterText) >
            INDEX_PATTERN_MATCHING
          : true
      )
    );
  }, [filterText]);

  return {
    filtered: filtered,
    handleFilter,
    totalBranches: branchItems.length,
  };
};
