import { AnyAction, AsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { ItemCategoryItem } from "../../components/Filters/ItemCategory/interfaces";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

export const isPendingAction = (action: AnyAction): action is PendingAction =>
  get(action, "type", "").endsWith("/pending");

export const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  get(action, "type", "").endsWith("/rejected");

export const getEntityName = (value: string) => {
  if (value === EntityNameEnum.NA) return EntityNameEnum.MERCHANT;

  return value;
};

export const buildMerchantRequest = (
  merchantChips: ItemCategoryItem[],
  searchValue?: string
): {
  name?: string;
  merchantId?: string;
  entityName?: string;
} => {
  const entity_name_aux: string = merchantChips
    .filter((chip) => chip.selected)
    .map((chip) => chip.value)
    .join("|");

  return {
    entityName: entity_name_aux,
    name: searchValue,
  };
};
