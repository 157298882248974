import React from "react";
import { SvgIcon } from "@mui/material";

const KeyIcon: React.FC = () => {
  return (
    <SvgIcon
      viewBox="-4 -4 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0297 0.803654C13.7368 0.510761 13.2619 0.510761 12.969 0.803654L7.04691 6.72576C6.32723 6.2169 5.44785 5.91732 4.49935 5.91732C2.06009 5.91732 0.0826836 7.89473 0.0826836 10.334C0.0826836 12.7732 2.06009 14.7507 4.49935 14.7507C6.93861 14.7507 8.91602 12.7732 8.91602 10.334C8.91602 9.38549 8.61643 8.5061 8.10757 7.78642L9.83268 6.06131L11.3024 7.53098C11.443 7.67163 11.6338 7.75065 11.8327 7.75065C12.0316 7.75065 12.2224 7.67163 12.363 7.53098L14.6963 5.19764C14.9892 4.90475 14.9892 4.42988 14.6963 4.13698L13.2267 2.66732L14.0297 1.86431C14.3226 1.57142 14.3226 1.09655 14.0297 0.803654ZM12.166 3.72798L13.1054 4.66731L11.8327 5.93999L10.8933 5.00065L12.166 3.72798ZM4.49935 7.41732C5.30497 7.41732 6.03311 7.74296 6.56174 8.27159C7.09038 8.80022 7.41602 9.52836 7.41602 10.334C7.41602 11.9448 6.11018 13.2507 4.49935 13.2507C2.88852 13.2507 1.58268 11.9448 1.58268 10.334C1.58268 8.72315 2.88852 7.41732 4.49935 7.41732Z"
        fill="#023365"
      />
    </SvgIcon>
  );
};

export default KeyIcon;
