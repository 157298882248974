import { ItemCategoryType } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/enums";
import * as React from "react";
import { useEffect, useState } from "react";
import { FilterSideBarProps } from "../../Filters/FilterSideBar/FilterSideBar.interfaces";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../Filters/ItemCategory/interfaces";
import { defaultTo, get, set } from "lodash";
import { HeaderFiltersProps } from "../HeaderFilters.interfaces";
import { IUseHeaderFiltersState } from "./useHeaderFiltersState.interfaces";
import { FILTER_LABELS } from "../../../shared/constants/labels/filter_labels";
import {
  FILTER_SIDE_BAR_INITIAL_STATE,
  getItemsFilterDetailBar,
} from "../../../shared/utils/header_filters_utils";
import {
  convertDateCorrectFormat,
  getFinishDate,
  getMinDate,
  getStartDate,
} from "../../../shared/utils/timezone";
import Rollbar from "rollbar";
import { useRollbar } from "@rollbar/react";
import { TimeEnum } from "../../../shared/enums/TimeEnum";
import { useLocation } from "react-router";

export const useHeaderFiltersState = ({
  getItemsSelected,
}: HeaderFiltersProps): IUseHeaderFiltersState => {
  const rollbar: Rollbar = useRollbar();
  const query = new URLSearchParams(useLocation().search);
  const date_parameter: string = query.get("date") as string;
  const ticket_number: string = defaultTo(
    query.get("ticketNumber") as string,
    ""
  );

  const [filterSideBarProps, setFilterSideBarProps] =
    useState<FilterSideBarProps>(FILTER_SIDE_BAR_INITIAL_STATE);
  const [itemsDetailBar, setItemsDetailBar] = useState<TFilterDetailBar[]>([]);
  const [valueSearch, setValueSearch] = useState<string>(ticket_number);

  const onClickFilterSideBar = (itemCategory: ItemCategoryProps[]) => {
    const filterSelected = getItemsFilterDetailBar(itemCategory);

    filterSideBarProps.onClick(itemCategory);
    setItemsDetailBar(filterSelected);
    if (getItemsSelected) getItemsSelected(filterSelected);
  };

  const onEnterName = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const value = get(e, "target.value", "");

    let filtersAndName = [
      ...itemsDetailBar,
      {
        itemCategoryType: "byChips" as ItemCategoryType,
        items: [{ label: value, value: value }],
        title: FILTER_LABELS.TICKET_NUMBER,
      },
    ];

    if (e.key === "Enter" || (e.key === "Backspace" && value === "")) {
      getItemsSelected && getItemsSelected(filtersAndName);
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValueSearch(get(e, "target.value", ""));
  };

  const onDateSelected = (startDate: string, finishDate: string) => {
    let filtersAndName = [
      ...itemsDetailBar,
      {
        itemCategoryType: "byChips" as ItemCategoryType,
        items: [
          {
            label: "startDate",
            value: convertDateCorrectFormat(startDate, true, rollbar),
          },
        ],
        title: "RangeDateFrom",
      },
      {
        itemCategoryType: "byChips" as ItemCategoryType,
        items: [
          {
            label: "finishDate",
            value: convertDateCorrectFormat(finishDate, false, rollbar),
          },
        ],
        title: "RangeDateTo",
      },
    ];

    getItemsSelected && getItemsSelected(filtersAndName);
  };

  const changeCategoryItems = (newCategoryItems: ItemCategoryProps[]) => {
    setFilterSideBarProps({
      ...filterSideBarProps,
      categoryItems: newCategoryItems,
    });
  };

  const onChangeFiltersDetailBar = (changes: TOnChangeFilters) => {
    let itemsSideBar: ItemCategoryProps[] = get(
      filterSideBarProps,
      "categoryItems"
    );
    let itemsSideBarFinal: ItemCategoryProps[] = [];

    itemsSideBar.forEach((itemSideBar: ItemCategoryProps) => {
      let itemsFilterSideBar: ItemCategoryItem[] = [];

      itemSideBar.items.forEach((itemFilterSideBar: ItemCategoryItem) => {
        if (
          changes.affected.itemDeleted.label === itemFilterSideBar.label &&
          changes.affected.title === itemSideBar.groupSection
        )
          set(itemFilterSideBar, "selected", false);

        itemsFilterSideBar.push(itemFilterSideBar);
      });
      set(itemSideBar, "items", itemsFilterSideBar);
      itemsSideBarFinal.push(itemSideBar);
    });
    changeCategoryItems(itemsSideBarFinal);
    onClickFilterSideBar(itemsSideBarFinal);
  };

  useEffect(() => {
    onClickFilterSideBar([]);
  }, []);

  return {
    defaultFinishDate: getFinishDate(rollbar) + ` ${TimeEnum.defaultFinalTime}`,
    defaultStartDate:
      getStartDate(rollbar, date_parameter) + ` ${TimeEnum.defaultInitialTime}`,
    filterSideBarProps,
    itemsDetailBar,
    minDate: getMinDate(rollbar),
    onChange,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
    onDateSelected,
    onEnterName,
    valueSearch,
  };
};
