import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import {
  ColumnsFiltersProps,
  ColumnsFilterButtonProps,
  CheckProps,
} from "./ColumnsFilter.interfaces";
import React, { useEffect, useState } from "react";
import FilterColumnsIconSvg from "../../assets/images/filterColumnsIcon.svg";
import { IHeaderTable } from "../../../types/header_table";

export const Check = (props: CheckProps): JSX.Element => {
  return (
    <Checkbox
      onChange={() => {
        props.handleColumnCheckChange!(props.id!);
      }}
      name={props.id}
      id={props.id}
      color="primary"
      checked={props.selectedColumnsIds!.includes(props.id!)}
      disabled={props.disabled!}
    />
  );
};

export const ColumnsFilter = (props: ColumnsFiltersProps): JSX.Element => {
  const {
    handleSelectedColumns,
    showColumnsFilter,
    allColumns,
    handleClose,
    maxColumns,
    minColumns,
    anchorEl,
  } = props;
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(false);
  const emptyLabelsCount = allColumns.filter(
    (col) => !col.filterLabel || col.filterLabel.trim() === ""
  ).length;

  const getTitle = () => {
    if (minColumns === maxColumns)
      return `Seleccione ${maxColumns} columna${
        minColumns === 1 ? "para mostrar" : "s para mostrar"
      }`;

    return `Seleccione ${minColumns} columna${
      minColumns === 1 ? "" : "s"
    } como mínimo y ${maxColumns} columnas como máximo`;
  };

  const title = props.title || getTitle();

  const handleApplyBtnState = () => {
    if (
      props.selectedColumnsIds!.length >= minColumns + emptyLabelsCount &&
      props.selectedColumnsIds!.length <= maxColumns + emptyLabelsCount
    ) {
      setApplyBtnDisabled(false);

      return;
    }
    setApplyBtnDisabled(true);
  };
  const handleApplyBtnClick = () => {
    handleSelectedColumns(
      allColumns.filter((col: IHeaderTable) =>
        props.selectedColumnsIds!.includes(col.id)
      )
    );
    handleClose();
  };
  const handleRestoreBtnClick = () => {
    props.setSelectedColumnsIds!(
      props.defaultColumns.map((column: IHeaderTable) => column.id)
    );
  };

  useEffect(() => {
    handleApplyBtnState();
  }, [props.selectedColumnsIds!]);

  return (
    <React.Fragment>
      <Popover
        open={showColumnsFilter}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <Card>
          <CardContent style={{ padding: "20px" }}>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <FormControl component="fieldset" id={"form-group"}>
                  <FormLabel component="legend">
                    <Typography variant="h6" color="primary">
                      {title}
                    </Typography>
                  </FormLabel>
                  <FormGroup aria-label="position">
                    {allColumns
                      .filter((col) => col.filterLabel!.trim().length > 0)
                      .map((column) => (
                        <FormControlLabel
                          value={column.id}
                          key={column.id}
                          control={
                            <Check
                              id={column.id}
                              handleColumnCheckChange={
                                props.handleColumnCheckChange!
                              }
                              selectedColumnsIds={props.selectedColumnsIds!}
                              disabled={column.disabledChoice}
                            />
                          }
                          label={column.filterLabel!}
                          labelPlacement="end"
                          disabled={column.disabledChoice}
                        />
                      ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      id={"restoreBtn"}
                      variant="outlined"
                      fullWidth
                      onClick={handleRestoreBtnClick}
                    >
                      <Typography variant={"body1"}>Restablecer</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      id={"applyBtn"}
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={applyBtnDisabled}
                      onClick={handleApplyBtnClick}
                    >
                      <Typography variant={"body1"}>Aplicar</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Popover>
    </React.Fragment>
  );
};

export const ColumnsFilterButton = (
  props: ColumnsFilterButtonProps
): JSX.Element => {
  return (
    <IconButton
      aria-label="settings-columns"
      color="primary"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
        props.handleShowColumnsFilter(event.currentTarget)
      }
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        boxSizing: "border-box",
        marginLeft: "20px",
        marginTop: "12px",
      }}
    >
      <img alt={"Filter columns icon"} src={FilterColumnsIconSvg} />
    </IconButton>
  );
};
