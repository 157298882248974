import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../src/shared/axios-util";
import { MerchantComplianceData } from "../../../../types/merchant_compliance_data";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { prefixes } from "../../../shared/constants/prefixes";

export const getMerchantComplianceData = createAsyncThunk<
  MerchantComplianceData,
  { publicMerchantId: string }
>(
  prefixes.MERCHANT_COMPLIANCE_GET,
  async (payload: { publicMerchantId: string }) => {
    const response = await axios.get<MerchantComplianceData>(
      API_ROUTES.MERCHANT_COMPLIANCE_GET(payload.publicMerchantId)
    );

    return response.data;
  }
);

export const postMerchantComplianceData = createAsyncThunk<
  MerchantComplianceData,
  MerchantComplianceData
>(
  prefixes.MERCHANT_COMPLIANCE_POST,
  async (payload: MerchantComplianceData) => {
    const response = await axios.post<MerchantComplianceData>(
      API_ROUTES.MERCHANT_COMPLIANCE_POST,
      payload
    );

    return response.data;
  }
);
