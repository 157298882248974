import { createTheme } from "@mui/material/styles";

export const Palette = {
  alternate: {
    dark: "#BD62B4",
    dark2: "#8F4F8B",
    light: "#E6A6D3",
    light2: "#FDD9F2",
    light3: "#FEECF8",
    main: "#DD85C3",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FAFCFD",
  },
  error: {
    dark: "#D62C4B",
    dark2: "#AD0C2A",
    light: "#E26C81",
    light2: "#F6AEBB",
    light3: "#FFEAEE",
    main: "#E24763",
  },
  gradients: {
    gradientA: "linear-gradient(180deg, #00E6B2 0%, #023365 100%)",
    gradientB: "linear-gradient(180deg, #0093A2 0%, #00E6B2 100%)",
    gradientC: "linear-gradient(135deg, #023365 0%, #1E65AE 100%)",
    gradientD: "linear-gradient(180deg, #023365 100% , #00E6B2 0%)",
    gradientE: "linear-gradient(180deg, #FFFFFF 0%, #F7FAFC 100%)",
  },
  neutral: {
    100: "#FBFCFE",
    200: "#F7FAFC",
    300: "#F0F4F9",
    400: "#E2E8F0",
    50: "#FFFFFF",
    500: "#CBD5E0",
    600: "#677684",
    700: "#46525C",
    800: "#293036",
    components: {
      disabled: "#B4B9BE",
      shadow: "#0E1E2E",
    },
  },
  primary: {
    components: {
      light: {
        default: "#4499EE",
        disable: "#BFDFFA",
        focus: "#3F8BE0",
        hover: "#3879CD",
      },
    },
    dark: "#112B45",
    dark2: "#0E1E2E",
    light: "#1E65AE",
    light2: "#4498EE",
    light3: "#EEF6FF",
    main: "#023365",
  },
  secondary: {
    dark: "#0DC298",
    dark2: "#3B9D86",
    light: "#00FCC1",
    light2: "#B7FFEE",
    light3: "#E0FFF8",
    main: "#00E6B2",
  },
  text: {
    dark: "#293036",
    disabled: "#B4B9BE",
    grey: "#6D7781",
    lightGrey: "#B4B9BE",
    primary: "#023365",
    red: "#E24763",
    secondary: "#0DC298",
    white: "#FFFFFF",
  },
  warning: {
    dark: "#DFA720",
    dark2: "#CF711A",
    light: "#FFD879",
    light2: "#FEE19B",
    light3: "#FFF1D0",
    main: "#FBC748",
  },
};

const ThemeFonts = {
  screenlg: {
    avatarLetter: 18,
    body1: 18,
    body2: 16,
    button: 16,
    buttonlg: 18,
    buttonsm: 14,
    caption: 15,
    chip: 16,
    h1: 38,
    h2: 30,
    h3: 26,
    h4: 22,
    h5: 18,
    h6: 16,
    helperText: 12,
    inputLabel: 12,
    inputText: 16,
    numericText: 16,
    overline: 13,
    subtitle1: 20,
    subtitle2: 16,
    tag: 12,
    tooltip: 14,
  },
  screenmd: {
    avatarLetter: 16,
    body1: 17,
    body2: 16,
    button: 15,
    buttonlg: 18,
    buttonsm: 13,
    caption: 14,
    chip: 14,
    h1: 28,
    h2: 24,
    h3: 22,
    h4: 20,
    h5: 18,
    h6: 16,
    helperText: 11,
    inputLabel: 12,
    inputText: 15,
    numericText: 15,
    overline: 13,
    subtitle1: 18,
    subtitle2: 15,
    tag: 12,
    tooltip: 13,
  },
  screensm: {
    avatarLetter: 16,
    body1: 16,
    body2: 14,
    button: 14,
    buttonlg: 16,
    buttonsm: 13,
    caption: 12,
    chip: 14,
    h1: 23,
    h2: 21,
    h3: 20,
    h4: 19,
    h5: 18,
    h6: 16,
    helperText: 11,
    inputLabel: 12,
    inputText: 14,
    numericText: 14,
    overline: 12,
    subtitle1: 17,
    subtitle2: 14,
    tag: 12,
    tooltip: 12,
  },
  screenxl: {
    avatarLetter: 20,
    body1: 18,
    body2: 16,
    button: 16,
    buttonlg: 20,
    buttonsm: 14,
    caption: 15,
    chip: 16,
    h1: 36,
    h2: 32,
    h3: 26,
    h4: 24,
    h5: 20,
    h6: 16,
    helperText: 12,
    inputLabel: 12,
    inputText: 16,
    numericText: 16,
    overline: 13,
    subtitle1: 20,
    subtitle2: 16,
    tag: 12,
    tooltip: 14,
  },
};

const breakpoints = {
  values: {
    lg: 1280,
    md: 960,
    sm: 360,
    xl: 1600,
    xs: 0,
  },
};

const theme = createTheme({
  breakpoints,
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            ".MuiAccordionSummary-content": {
              display: "block",
            },
            padding: "8px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            ".MuiAccordionSummary-content": {
              display: "block",
            },
            padding: "8px",
          },
          "&:hover": {
            boxShadow:
              "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
          },
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            padding: "24px",
          },
          backgroundColor: Palette.background.default,
          borderRadius: "8px",
          borderTop: "none!important",
          boxShadow:
            "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
          marginBottom: "24px",
          padding: "24px",
          position: "unset",
          transition: "all .3s ease-out",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledError: {
          ".MuiAlert-icon": {
            color: Palette.error.dark2 + "!important",
          },
          backgroundColor: Palette.error.light3 + "!important",
          color: Palette.error.dark2 + "!important",
        },
        filledSuccess: {
          ".MuiAlert-icon": {
            color: Palette.text.primary + "!important",
          },
          backgroundColor:
            Palette.primary.components.light.disable + "!important",
          color: Palette.text.primary + "!important",
        },
        root: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            borderRadius: "16px",
            fontSize: ThemeFonts.screenmd.caption + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            borderRadius: "16px",
            fontSize: ThemeFonts.screensm.caption + "px",
          },
          ".MuiAlert-action": {
            alignItems: "center",
            marginRight: "0px",
            padding: "0px",
            svg: {
              fontSize: "0.9em",
              stroke: "currentColor",
            },
          },
          ".MuiAlert-icon": {
            alignItems: "center",
          },
          ".MuiAlert-message": {
            alignItems: "center",
            button: {
              margin: "0px 0px 0px 16px",
            },
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          },
          borderRadius: "4px",
          boxShadow: "none",
          fontSize: ThemeFonts.screenmd.caption + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.caption + "px",
          },
          fontWeight: "normal",
          lineHeight: "144%",
          minHeight: "54px",
          padding: "0px 16px",
        },
        standardError: {
          ".MuiAlert-icon": {
            color: Palette.error.dark2 + "!important",
          },
          backgroundColor: Palette.error.light3 + "!important",
          color: Palette.error.dark2 + "!important",
        },
        standardSuccess: {
          ".MuiAlert-icon": {
            color: Palette.text.primary + "!important",
          },
          backgroundColor:
            Palette.primary.components.light.disable + "!important",
          color: Palette.text.primary + "!important",
        },
        /*filledWarning: {
          backgroundColor: Palette.warning.light2
        },
        filledInfo: {
          backgroundColor: Palette.neutral[800]
        }*/
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "+ .MuiAutocomplete-popper": {
            "& .MuiAutocomplete-listbox, & .MuiAutocomplete-noOptions": {
              [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                fontSize: ThemeFonts.screenmd.inputText + "px",
              },
              [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                fontSize: ThemeFonts.screensm.inputText + "px",
              },
              ".MuiAutocomplete-option.Mui-focused": {
                backgroundColor: Palette.neutral[400],
              },
              color: Palette.text.dark,
              fontSize: ThemeFonts.screenxl.inputText + "px",
              [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
                fontSize: ThemeFonts.screenlg.inputText + "px",
              },
              img: {
                borderRadius: "100%",
                height: "20px",
                objectFit: "cover",
                width: "20px",
              },
            },
          },
          ".MuiAutocomplete-endAdornment": {
            ".MuiButtonBase-root": {
              height: "auto",
              width: "auto",
            },
            display: "flex",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          ".MuiSvgIcon-root": {
            fontSize: "40px!important",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          background: Palette.secondary.dark,
          color: Palette.neutral[50],
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.overline + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.overline + "px",
          },
          button: {
            "&:hover": {
              background: "transparent",
              color: Palette.primary.components.light.hover,
            },
            background: "transparent",
            margin: "0",
            padding: "0",
            position: "relative",
            top: "0.2em",
          },
          fontSize: ThemeFonts.screenxl.overline + "px",
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenlg.overline + "px",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        containedPrimary: {
          "&:disabled": {
            backgroundColor: Palette.primary.components.light.disable,
            border: "1px solid " + Palette.primary.components.light.disable,
            color: Palette.neutral[50],
          },
          "&:focus, &:active": {
            backgroundColor: Palette.primary.components.light.focus,
            border: "1px solid " + Palette.primary.components.light.focus,
            boxShadow:
              "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
            color: Palette.neutral[50],
          },
          "&:hover": {
            backgroundColor: Palette.primary.components.light.hover,
            border: "1px solid " + Palette.primary.components.light.hover,
            boxShadow:
              "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
            color: Palette.neutral[50],
          },
          backgroundColor: Palette.primary.components.light.default,
          border: "1px solid " + Palette.primary.components.light.default,
          boxShadow:
            "0px 51px 80px rgba(106, 167, 197, 0.07), 0px 33.0556px 46.8519px rgba(106, 167, 197, 0.0531481), 0px 19.6444px 25.4815px rgba(106, 167, 197, 0.0425185), 0px 10.2px 13px rgba(106, 167, 197, 0.035), 0px 4.15556px 6.51852px rgba(106, 167, 197, 0.0274815), 0px 0.944444px 3.14815px rgba(106, 167, 197, 0.0168519)",
        },
        containedSecondary: {
          "&:disabled": {
            backgroundColor: Palette.neutral[100],
            border: "1px solid " + Palette.neutral[100] + "!important",
            color: Palette.neutral[500],
          },
          "&:focus, &:active": {
            backgroundColor: Palette.neutral[100],
            border: "1px solid " + Palette.neutral[100] + "!important",
            color: Palette.text.primary,
          },
          "&:hover": {
            backgroundColor: Palette.neutral[100],
            border: "1px solid " + Palette.neutral[100],
            color: Palette.text.grey,
          },
          backgroundColor: "transparent",
          border: "1px solid transparent",
          color: Palette.text.grey,
        },
        outlinedSecondary: {
          "&:disabled": {
            backgroundColor: Palette.neutral[100],
            border: "1px solid " + Palette.neutral[100] + "!important",
            color: Palette.neutral[500],
          },
          "&:hover": {
            backgroundColor: Palette.neutral[100],
            border: "1px solid " + Palette.primary.main,
            color: Palette.primary.main,
          },
          backgroundColor: "transparent",
          border: "1px solid" + Palette.primary.light2,
          color: Palette.primary.light2,
        },
        root: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.button + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.button + "px",
          },
          borderRadius: "16px",
          fontFamily: "IBM Plex Sans",
          fontSize: ThemeFonts.screenxl.button + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.button + "px",
          },
          fontWeight: "400",
          height: "44px",
          letterSpacing: "0.0025em",
          lineHeight: "140%",
          padding: "12px 30px",
        },
        sizeLarge: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.buttonlg + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.buttonlg + "px",
          },
          fontSize: ThemeFonts.screenxl.buttonlg + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.buttonlg + "px",
          },
          height: 52,
          padding: "12px 30px",
        },
        sizeSmall: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.buttonsm + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.buttonsm + "px",
          },
          borderRadius: "12px",
          fontSize: ThemeFonts.screenxl.buttonsm + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.buttonsm + "px",
          },
          height: "36px",
          padding: "8px 30px",
        },
        textPrimary: {
          "&:disabled": {
            backgroundColor: Palette.neutral[50],
            border: "1px solid " + Palette.neutral[500],
            color: Palette.text.grey,
            opacity: "35%",
          },
          "&:focus, &:active": {
            backgroundColor: Palette.neutral[50],
            border: "1px solid " + Palette.neutral[50],
            boxShadow:
              "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
            color: Palette.text.grey,
          },
          "&:hover": {
            backgroundColor: Palette.neutral[50],
            border: "1px solid " + Palette.neutral[50],
            boxShadow:
              "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
            color: Palette.primary.light2,
          },
          backgroundColor: "transparent",
          border: "1px solid transparent",
          color: Palette.primary.light2,
        },
      },
      variants: [
        {
          props: { color: "error", variant: "contained" },
          style: {
            "&:disabled": {
              backgroundColor: Palette.neutral[50],
              border: "1px solid " + Palette.error.light2,
              color: Palette.error.light2,
              opacity: "35%",
            },
            "&:focus, &:active": {
              backgroundColor: Palette.error.light3,
              border: "1px solid " + Palette.error.light,
              boxShadow: "none",
              color: Palette.error.main,
            },
            "&:hover": {
              backgroundColor: Palette.error.light3,
              border: "1px solid " + Palette.error.light3,
              boxShadow:
                "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
              color: Palette.error.main,
            },
            backgroundColor: "transparent",
            border: "1px solid " + Palette.error.light3,
            color: Palette.error.main,
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: Palette.background.default,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: Palette.primary.light2,
          },
          color: Palette.primary.light2,
        },
        colorSecondary: {
          "&.Mui-checked": {
            color: Palette.secondary.dark,
          },
          color: Palette.secondary.dark,
        },
        root: {
          "&+.MuiFormControlLabel-label": {
            fontSize: ThemeFonts.screenxl.inputLabel + "px!important",
          },
          "&.Mui-disabled": {
            color: Palette.neutral[600] + "!important",
            opacity: "0.3",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.tag + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.tag + "px",
          },
          "&.sizeLarge": {
            height: "28px",
          },
          ".MuiChip-deleteIcon": {
            boxSizing: "initial",
            color: Palette.text.primary,
            fontSize: "1.25em!important",
            marginRight: "0px",
            paddingLeft: "10px",
          },
          ".MuiChip-label": {
            padding: "0px",
          },
          borderRadius: "4px!important",
          fontSize: ThemeFonts.screenxl.tag + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.tag + "px",
          },
          fontWeight: "500",
          letterSpacing: "0.02em",
          lineHeight: "170%",
          padding: "4px 16px",
          textTransform: "uppercase",
        },

        sizeMedium: {
          height: "26px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          border: "1px dashed #E2E8F0",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        //@ts-ignore
        colorDefault: {
          "&.MuiChip-filledDefault": {
            "&.filterChip": {
              [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
                fontSize: ThemeFonts.screenlg.tooltip + "px",
              },
              [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                fontSize: ThemeFonts.screenmd.tooltip + "px",
              },
              [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                fontSize: ThemeFonts.screensm.tooltip + "px",
              },
              "&.Mui-disabled": {
                ".MuiChip-deleteIcon": {
                  boxSizing: "initial",
                  color: Palette.neutral.components.disabled + "!important",
                },
                backgroundColor: Palette.neutral[300] + "!important",
                border:
                  "solid 1px" +
                  Palette.neutral.components.disabled +
                  "!important",
                color: Palette.neutral.components.disabled,
                opacity: 1,
              },
              "&.MuiChip-sizeSmall": {
                height: 24,
                [`@media screen and (max-width: ${breakpoints.values.xl}px)`]: {
                  fontSize: ThemeFonts.screenxl.inputLabel + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
                  fontSize: ThemeFonts.screenlg.inputLabel + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                  fontSize: ThemeFonts.screenmd.inputLabel + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                  fontSize: ThemeFonts.screensm.inputLabel + "px",
                },
              },
              "&.sizeLarge": {
                height: 30,
                [`@media screen and (max-width: ${breakpoints.values.xl}px)`]: {
                  fontSize: ThemeFonts.screenxl.inputText + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
                  fontSize: ThemeFonts.screenlg.inputText + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                  fontSize: ThemeFonts.screenmd.inputText + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                  fontSize: ThemeFonts.screensm.inputText + "px",
                },
              },
              ".MuiChip-deleteIcon": {
                boxSizing: "initial",
                color: Palette.primary.light,
                fontSize: "1.35em!important",
                paddingLeft: 5,
              },
              backgroundColor: Palette.primary.light3,
              border: "solid 1px" + Palette.primary.light,
              borderRadius: "16px!important",
              color: Palette.primary.light,
              height: 28,
              [`@media screen and (max-width: ${breakpoints.values.xl}px)`]: {
                fontSize: ThemeFonts.screenxl.tooltip + "px",
              },
              padding: "5px 10px",
              transition: "all 500ms ease",
            },
            backgroundColor: Palette.neutral[400],
            borderRadius: "8px!important",
            color: Palette.text.dark,
            fontSize: 14,
            //FilterChipCSS
            fontWeight: 400,
            letterSpacing: "normal",
            lineHeight: "140%",
            textTransform: "none",
          },
        },
        colorError: {
          "&.MuiChip-filledError": {
            backgroundColor: Palette.error.light3,
            color: Palette.error.dark2,
            fontSize: "9px",
          },
        },
        colorInfo: {
          "&.MuiChip-filledInfo": {
            backgroundColor: Palette.neutral[300],
            color: Palette.text.grey,
            fontSize: "9px",
          },
        },
        colorPrimary: {
          "&.MuiChip-filledPrimary": {
            backgroundColor: Palette.primary.light3,
            color: Palette.primary.main,
            fontSize: "9px",
          },
        },
        colorSecondary: {
          "&.MuiChip-filledSecondary": {
            backgroundColor: Palette.alternate.light3,
            color: Palette.alternate.dark2,
            fontSize: "9px",
          },
        },
        colorSuccess: {
          "&.MuiChip-filledSuccess": {
            backgroundColor: Palette.secondary.light3,
            color: Palette.secondary.dark2,
            fontSize: "9px",
          },
        },
        colorWarning: {
          "&.MuiChip-filledWarning": {
            backgroundColor: Palette.warning.light3,
            color: Palette.warning.dark2,
            fontSize: "9px",
          },
        },
        root: {
          "&.filterChipCheck": {
            "&.choiceButton": {
              "&.Mui-disabled": {
                ".iconChoice": {
                  circle: {
                    "&.circleFill": {
                      fill: Palette.neutral[400] + "!important",
                    },
                    stroke: Palette.neutral[400],
                  },
                  color: Palette.neutral[400],
                },
                ".MuiTypography-root": {
                  color: Palette.neutral[400] + "!important",
                },
                filter:
                  "drop-shadow(0px 8px 17px rgba(222, 239, 247, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(222, 239, 247, 0.075))",
                "img.iconChoice": {
                  filter: "grayscale(100%) opacity(0.3)",
                },
              },
              //
              "&.unSelect:not(:hover)": {
                ".iconChoice": {
                  circle: {
                    "&.circleFill": {
                      fill: Palette.text.lightGrey,
                    },
                    stroke: Palette.text.lightGrey,
                  },
                  color: Palette.text.lightGrey,
                  filter: "grayscale(100%)",
                },
                ".MuiTypography-root": {
                  color: Palette.text.lightGrey,
                },
                filter:
                  "drop-shadow(0px 8px 17px rgba(222, 239, 247, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(222, 239, 247, 0.075))",
              },
              //Status Disabled
              "&:hover:not(.Mui-disabled)": {
                border: "1px solid" + Palette.primary.light2,
                boxShadow:
                  "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
              },
              //Status Unselect
              ".choiceBox": {
                ".iconChoice": {
                  margin: "auto",
                },
                height: "100%",
                justifyContent: "space-around",
              },
              ".choiceRadio": {
                "&:hover": {
                  background: "none",
                },
                justifyContent: "end",
                padding: 0,
                svg: {
                  "&.MuiSvgIcon-colorAction": {
                    color: Palette.neutral[500],
                  },
                  fontSize: "20px!important",
                },
                transition: "all .2s ease-out",
                width: "100%",
              },
              ".MuiTypography-body1.MuiFormControlLabel-label": {
                height: "100%",
              },
              //Status Default and Activated
              background: Palette.background.default,
              border: "1px solid" + Palette.background.default,
              borderRadius: 8,
              boxSizing: "initial",
              display: "flex",
              filter:
                "drop-shadow(0px 8px 17px rgba(106, 167, 197, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(106, 167, 197, 0.075))",
              //Status Hover
              height: 96,
              justifyContent: "space-between",
              marginTop: 20,
              padding: 12,
              textAlign: "center",
              transition: "all .2s ease-out",
              width: 78,
            },
            "&.Mui-error": {
              ".MuiCheckbox-root": {
                "& + .MuiFormControlLabel-label": {
                  "&:before": {
                    filter:
                      "grayscale(100%) brightness(67%) sepia(100%) hue-rotate(302deg) saturate(600%) contrast(0.85)",
                  },
                  backgroundColor: Palette.error.light3 + "!important",
                  border: "solid 1px" + Palette.error.main + "!important",
                  color: Palette.error.main,
                  cursor: "default",
                },
              },
            },
            ".MuiCheckbox-root": {
              "& + .MuiFormControlLabel-label": {
                [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
                  fontSize: ThemeFonts.screenlg.inputText + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                  fontSize: ThemeFonts.screenmd.inputText + "px",
                },
                [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                  fontSize: ThemeFonts.screensm.inputText + "px",
                },
                "&:before": {
                  content:
                    "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzY0OF84OTQzKSI+CjxwYXRoIGQ9Ik01IDEwQzQuMDExMDkgMTAgMy4wNDQ0IDkuNzA2NzYgMi4yMjIxNSA5LjE1NzM1QzEuMzk5OSA4LjYwNzk0IDAuNzU5MDQzIDcuODI3MDUgMC4zODA2MDQgNi45MTM0MkMwLjAwMjE2NjQ1IDUuOTk5NzkgLTAuMDk2ODUwMiA0Ljk5NDQ2IDAuMDk2MDc1OCA0LjAyNDU1QzAuMjg5MDAyIDMuMDU0NjUgMC43NjUyMDYgMi4xNjM3MyAxLjQ2NDQ3IDEuNDY0NDdDMi4xNjM3MyAwLjc2NTIwNiAzLjA1NDY0IDAuMjg5MDAyIDQuMDI0NTUgMC4wOTYwNzU4QzQuOTk0NDUgLTAuMDk2ODUwMiA1Ljk5OTc5IDAuMDAyMTY2NDMgNi45MTM0MiAwLjM4MDYwNUM3LjgyNzA1IDAuNzU5MDQzIDguNjA3OTQgMS4zOTk5MSA5LjE1NzM1IDIuMjIyMTVDOS43MDY3NSAzLjA0NDQgMTAgNC4wMTEwOSAxMCA1QzEwIDYuMzI2MDggOS40NzMyMiA3LjU5Nzg1IDguNTM1NTMgOC41MzU1NEM3LjU5Nzg1IDkuNDczMjIgNi4zMjYwOCAxMCA1IDEwVjEwWk01IDAuOTA5MDkzQzQuMTkwOSAwLjkwOTA5MyAzLjM5OTk2IDEuMTQ5MDIgMi43MjcyMSAxLjU5ODU0QzIuMDU0NDcgMi4wNDgwNSAxLjUzMDEzIDIuNjg2OTYgMS4yMjA0OSAzLjQzNDQ4QzAuOTEwODY0IDQuMTgxOTkgMC44Mjk4NSA1LjAwNDU0IDAuOTg3Njk5IDUuNzk4MUMxLjE0NTU1IDYuNTkxNjYgMS41MzUxNyA3LjMyMDU5IDIuMTA3MjkgNy44OTI3MUMyLjY3OTQyIDguNDY0ODMgMy40MDgzNSA4Ljg1NDQ2IDQuMjAxOSA5LjAxMjMxQzQuOTk1NDYgOS4xNzAxNSA1LjgxODAxIDkuMDg5MTQgNi41NjU1MiA4Ljc3OTUxQzcuMzEzMDQgOC40Njk4OCA3Ljk1MTk1IDcuOTQ1NTQgOC40MDE0NyA3LjI3Mjc5QzguODUwOTggNi42MDAwNCA5LjA5MDkxIDUuODA5MTEgOS4wOTA5MSA1QzkuMDkwOTEgMy45MTUwMyA4LjY1OTkgMi44NzQ0OSA3Ljg5MjcxIDIuMTA3MjlDNy4xMjU1MiAxLjM0MDEgNi4wODQ5OCAwLjkwOTA5MyA1IDAuOTA5MDkzVjAuOTA5MDkzWiIgZmlsbD0iIzZENzc4MSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzY0OF84OTQzIj4KPHJlY3Qgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=)",
                  marginRight: 5,
                  transition: "all 500ms ease",
                  verticalAlign: "middle",
                },
                backgroundColor: Palette.background.default,
                border: "solid 1px" + Palette.primary.components.light.disable,
                borderRadius: 16,
                [`@media screen and (max-width: ${breakpoints.values.xl}px)`]: {
                  fontSize: ThemeFonts.screenxl.inputText + "px",
                },
                color: Palette.text.grey,
                cursor: "pointer",
                display: "inline-block",
                padding: "4px 10px",
                transition: "all 500ms ease",
                userSelect: "none",
              },
              "&.Mui-checked": {
                "& + .MuiFormControlLabel-label": {
                  "&:before": {
                    content:
                      "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzY0OF84OTQ0KSI+CjxwYXRoIGQ9Ik0zLjQ0ODQ1IDguNTg3NzlDMy4zNTc2OSA4LjU4ODMxIDMuMjY3NzMgOC41NzA5MiAzLjE4MzcxIDguNTM2NkMzLjA5OTY5IDguNTAyMjggMy4wMjMyOCA4LjQ1MTcyIDIuOTU4ODQgOC4zODc4MUwwLjIwMDUyNSA1LjYyOTQ5QzAuMDcyMDg5NyA1LjUwMDI5IDAgNS4zMjU1MSAwIDUuMTQzMzRDMCA0Ljk2MTE2IDAuMDcyMDg5NyA0Ljc4NjM4IDAuMjAwNTI1IDQuNjU3MThDMC4yNjQ2MyA0LjU5MjU1IDAuMzQwODk5IDQuNTQxMjUgMC40MjQ5MyA0LjUwNjI0QzAuNTA4OTYyIDQuNDcxMjMgMC41OTkwOTQgNC40NTMyMSAwLjY5MDEyNyA0LjQ1MzIxQzAuNzgxMTU5IDQuNDUzMjEgMC44NzEyOTEgNC40NzEyMyAwLjk1NTMyMiA0LjUwNjI0QzEuMDM5MzUgNC41NDEyNSAxLjExNTYyIDQuNTkyNTUgMS4xNzk3MyA0LjY1NzE4TDMuNDQ4NDUgNi45MjU5TDguODIwMjcgMS41NTQwN0M4Ljg4NDM4IDEuNDg5NDQgOC45NjA2NSAxLjQzODE0IDkuMDQ0NjggMS40MDMxM0M5LjEyODcxIDEuMzY4MTIgOS4yMTg4NCAxLjM1MDEgOS4zMDk4NyAxLjM1MDFDOS40MDA5MSAxLjM1MDEgOS40OTEwNCAxLjM2ODEyIDkuNTc1MDcgMS40MDMxM0M5LjY1OTEgMS40MzgxNCA5LjczNTM3IDEuNDg5NDQgOS43OTk0NyAxLjU1NDA3QzkuOTI3OTEgMS42ODMyOCAxMCAxLjg1ODA1IDEwIDIuMDQwMjNDMTAgMi4yMjI0IDkuOTI3OTEgMi4zOTcxOCA5Ljc5OTQ3IDIuNTI2MzhMMy45MzgwNSA4LjM4NzgxQzMuODczNjEgOC40NTE3MiAzLjc5NzIgOC41MDIyOCAzLjcxMzE4IDguNTM2NkMzLjYyOTE2IDguNTcwOTIgMy41MzkyIDguNTg4MzEgMy40NDg0NSA4LjU4Nzc5WiIgZmlsbD0iIzFFNjVBRSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzY0OF84OTQ0Ij4KPHJlY3Qgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=)",
                  },
                  backgroundColor: Palette.primary.light3,
                  border: "solid 1px" + Palette.primary.light,
                  color: Palette.primary.light,
                },
              },
              "&.Mui-disabled": {
                "& + .MuiFormControlLabel-label": {
                  "&:before": {
                    filter: "grayscale(100%) opacity(37%)",
                  },
                  backgroundColor: Palette.neutral[300] + "!important",
                  border:
                    "solid 1px" +
                    Palette.neutral.components.disabled +
                    "!important",
                  color: Palette.neutral.components.disabled,
                  cursor: "default",
                },
              },
              display: "none",
              marginRight: 25,
            },
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "&.groupChoice": {
            justifyContent: "center",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: Palette.text.grey,
          },
          color: Palette.text.grey,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: Palette.neutral.components.disabled + "!important",
          },
          "&.Mui-error": {
            color: Palette.error.dark2 + "!important",
          },
          "&.MuiInputLabel-shrink": {
            top: "0px!important",
          },
          color: Palette.text.grey + "!important",
          top: "-6px!important",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        //@ts-ignore
        colorPrimary: {
          "&:disabled": {
            backgroundColor: Palette.primary.components.light.disable,
            border: "1px solid " + Palette.primary.components.light.disable,
            boxShadow: "none",
            color: Palette.neutral[50],
          },
          "&:focus, &:active": {
            backgroundColor: Palette.primary.components.light.focus,
            border: "1px solid " + Palette.primary.components.light.focus,
            boxShadow:
              "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
            filter:
              "drop-shadow(0px 36px 65px rgba(106, 167, 197, 0.16)) drop-shadow(0px 18.225px 28.3359px rgba(106, 167, 197, 0.12)) drop-shadow(0px 7.2px 10.5625px rgba(106, 167, 197, 0.08)) drop-shadow(0px 1.575px 3.75781px rgba(106, 167, 197, 0.03))",
          },
          "&:hover": {
            backgroundColor: Palette.primary.components.light.hover,
            border: "1px solid " + Palette.primary.components.light.hover,
            boxShadow:
              "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
            color: Palette.neutral[50],
            filter:
              "drop-shadow(0px 36px 65px rgba(106, 167, 197, 0.16)) drop-shadow(0px 18.225px 28.3359px rgba(106, 167, 197, 0.12)) drop-shadow(0px 7.2px 10.5625px rgba(106, 167, 197, 0.08)) drop-shadow(0px 1.575px 3.75781px rgba(106, 167, 197, 0.03))",
          },
          backgroundColor: Palette.primary.components.light.default,
          border: "1px solid " + Palette.primary.components.light.default,
          boxShadow:
            "0px 51px 80px rgba(106, 167, 197, 0.07), 0px 33.0556px 46.8519px rgba(106, 167, 197, 0.0531481), 0px 19.6444px 25.4815px rgba(106, 167, 197, 0.0425185), 0px 10.2px 13px rgba(106, 167, 197, 0.035), 0px 4.15556px 6.51852px rgba(106, 167, 197, 0.0274815), 0px 0.944444px 3.14815px rgba(106, 167, 197, 0.0168519)",
          color: Palette.neutral[50],
          filter:
            "drop-shadow(0px 8px 17px rgba(106, 167, 197, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(106, 167, 197, 0.075))",
        },
        colorSecondary: {
          "&:active, &:focus": {
            color: Palette.text.primary,
          },
          "&:disabled": {
            color: Palette.text.primary,
            opacity: "35%",
          },
          "&:hover": {
            boxShadow:
              "0px 51px 80px rgba(106, 167, 197, 0.07), 0px 33.0556px 46.8519px rgba(106, 167, 197, 0.0531481), 0px 19.6444px 25.4815px rgba(106, 167, 197, 0.0425185), 0px 10.2px 13px rgba(106, 167, 197, 0.035), 0px 4.15556px 6.51852px rgba(106, 167, 197, 0.0274815), 0px 0.944444px 3.14815px rgba(106, 167, 197, 0.0168519)",
            color: Palette.primary.light2,
          },
          "&:hover, &:active, &:focus, &:disabled": {
            backgroundColor: Palette.neutral[50],
            border: "1px solid " + Palette.neutral[50],
          },
          backgroundColor: Palette.neutral[50],
          border: "1px solid " + Palette.neutral[50],
          boxShadow:
            "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
          color: Palette.text.primary,
          filter:
            "drop-shadow(0px 8px 17px rgba(106, 167, 197, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(106, 167, 197, 0.075))",
          root: {
            "&.iconButtonText": {
              alignContent: "center",
              display: "flex",
              paddingLeft: "28px",
              paddingRight: "28px",
              width: "auto",
            },
            //Autocomplete textfield
            "&.MuiAutocomplete-popupIndicator, &.MuiAutocomplete-clearIndicator":
              {
                height: "auto",
              },
            borderRadius: "48px",
            display: "flex",
            fontSize: ThemeFonts.screenxl.button + "px",
            height: "44px",
            justifyContent: "center",
            padding: "5px",
            width: "44px",
            [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
              fontSize: ThemeFonts.screenlg.button + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
              fontSize: ThemeFonts.screenmd.button + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: ThemeFonts.screensm.button + "px",
            },
          },
          sizeLarge: {
            [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
              fontSize: ThemeFonts.screenmd.buttonlg + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: ThemeFonts.screensm.buttonlg + "px",
            },
            fontSize: ThemeFonts.screenxl.buttonlg + "px",
            [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
              fontSize: ThemeFonts.screenlg.buttonlg + "px",
            },
            height: "52px",
            padding: "14px",
            svg: {
              fontSize: "24px!important",
            },
            width: "52px",
          },
          sizeSmall: {
            fontSize: ThemeFonts.screenxl.buttonsm + "px",
            height: "36px",
            padding: "6px",
            svg: {
              fontSize: "16px!important",
            },
            width: "36px",
            [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
              fontSize: ThemeFonts.screenlg.buttonsm + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
              fontSize: ThemeFonts.screenmd.buttonsm + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: ThemeFonts.screensm.buttonsm + "px",
            },
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          height: "44px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          height: "auto",
        },
        root: {
          "&.MuiAutocomplete-inputRoot": {
            padding: "0px 8px 0px 8px!important",
          },
          height: "44px",
          //Autocomplete textfield
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          borderRadius: "8px",
          top: "-5px",
        },
        root: {
          color: Palette.text.grey,
          fontSize: ThemeFonts.screenxl.inputText + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.inputText + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.inputText + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.inputText + "px",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "&.modalBoxContent": {
            ".modalContainer": {
              ".iconBox": {
                ".closeButton": {
                  float: "right",
                  marginBottom: 40,
                },
              },
              ".modalHeader": {
                padding: 0,
              },
              borderRadius: 0,
              img: {
                marginBottom: 72,
                marginTop: 32,
              },
              padding: "40px",
              textAlign: "center",
              width: "506px",
            },
          },
          ".modalContainer": {
            ".dividerHeader": {
              margin: "32px 0",
            },
            ".modalFooter": {
              backgroundColor: Palette.background.paper,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              button: {
                marginLeft: 16,
              },
              display: "flex",
              justifyContent: "end",
              padding: "32px 40px",
            },
            ".modalHeader": {
              ".closeButton": {
                position: "absolute",
                right: 25,
                top: 25,
              },
              padding: 40,
            },
            backgroundColor: Palette.background.default,
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            left: "50%",
            minHeight: "233px",
            minWidth: "775px",
            position: "absolute" as "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
          },
          ".MuiBackdrop-root:not(.MuiBackdrop-invisible)": {
            backgroundColor: Palette.primary.dark2 + "64!important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: Palette.neutral.components.disabled + "!important",
          },
          "&.Mui-error": {
            "input, .MuiSelect-select,&.MuiAutocomplete-inputRoot": {
              color: Palette.error.dark2,
            },
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: Palette.error.dark2 + "!important",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline , &:hover .MuiOutlinedInput-notchedOutline":
            {
              borderColor: Palette.primary.light + "!important",
              borderWidth: 1,
            },
          ".MuiInputAdornment-positionEnd, .MuiTypography-root": {
            color: Palette.neutral.components.disabled,
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: Palette.neutral[500],
          },
          borderRadius: "8px!important",
          "input, .MuiSelect-select,&.MuiAutocomplete-inputRoot": {
            [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
              fontSize: ThemeFonts.screenmd.inputText + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: ThemeFonts.screensm.inputText + "px",
            },
            alignContent: "center",
            alignItems: "center",
            color: Palette.text.dark,
            display: "flex",
            fontSize: ThemeFonts.screenxl.inputText + "px",
            [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
              fontSize: ThemeFonts.screenlg.inputText + "px",
            },
            img: {
              borderRadius: "100%",
              height: "20px",
              marginLeft: "5px",
              marginRight: "10px",
              objectFit: "cover",
              width: "20px",
            },
            padding: "10.5px 14px",
          },
          position: "relative",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: "none",
        },
        elevation1: {
          boxShadow:
            "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075);", //noPalletcolor
        },
        elevation2: {
          boxShadow:
            "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03);", //noPalletcolor
        },
        elevation3: {
          boxShadow:
            "0px 143px 100px rgba(106, 167, 197, 0.18), 0px 84.1984px 52.16px rgba(106, 167, 197, 0.15), 0px 43.2432px 24.48px rgba(106, 167, 197, 0.11), 0px 17.3888px 10.72px rgba(106, 167, 197, 0.07), 0px 3.8896px 4.64px rgba(106, 167, 197, 0.03);", //noPalletcolor
        },
        elevation4: {
          boxShadow:
            "20px 0px 80px rgba(106, 167, 197, 0.1), 7.7037px 0px 25.4815px rgba(106, 167, 197, 0.0607407), 1.62963px 0px 6.51852px rgba(106, 167, 197, 0.0392593)", //noPalletcolor
        },
        root: {
          "&.MuiMenu-paper": {
            "& .MuiMenu-list, & .MuiMenuItem-root": {
              ".Mui-selected": {
                backgroundColor: Palette.neutral[400],
              },
              color: Palette.text.dark,
              fontSize: ThemeFonts.screenxl.inputText + "px",
              [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
                fontSize: ThemeFonts.screenlg.inputText + "px",
              },
              [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                fontSize: ThemeFonts.screenmd.inputText + "px",
              },
              [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                fontSize: ThemeFonts.screensm.inputText + "px",
              },
              img: {
                borderRadius: "100%",
                height: "20px",
                marginRight: "10px",
                objectFit: "cover",
                width: "20px",
              },
            },
          },
          "&.MuiMenu-paper, &.MuiAutocomplete-paper": {
            backgroundColor: Palette.background.default + "!important",
            boxShadow:
              "0px 8px 17px rgb(106 167 197 / 15%), 0px 1.6px 2.7625px rgb(106 167 197 / 8%)",
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: Palette.primary.light2,
          },
          color: Palette.primary.light2,
        },
        colorSecondary: {
          "&.Mui-checked": {
            color: Palette.secondary.dark,
          },
          color: Palette.secondary.dark,
        },
        root: {
          "&+.MuiFormControlLabel-label": {
            alignItems: "center",
            display: "flex",
            fontSize: ThemeFonts.screenxl.inputLabel + "px!important",
          },
          "&.Mui-disabled": {
            color: Palette.neutral[600] + "!important",
            opacity: "0.3",
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          "&:not(.MuiSkeleton-circular)": {
            borderRadius: 2,
          },
          background: Palette.neutral[400],
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSecondary: {
          fill: Palette.text.lightGrey + "!important",
        },
        fontSizeLarge: {
          fontSize: "36px !important",
        },
        fontSizeSmall: {
          fontSize: "16px !important",
        },
        root: {
          "&.MuiSvgIcon-colorSuccess": {
            fill: Palette.secondary.dark + "!important",
          },
          fontSize: "24px!important",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: Palette.primary.light2 + "!important",
          },
        },
        colorSecondary: {
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: Palette.secondary.dark + "!important",
          },
        },
        root: {
          "&+.MuiFormControlLabel-label": {
            fontSize: ThemeFonts.screenxl.inputLabel + "px!important",
          },
          ".Mui-disabled+.MuiSwitch-track": {
            opacity: "0.3!important",
          },
          padding: "4px 5px",
        },
        sizeSmall: {
          "&+.MuiFormControlLabel-label": {
            marginLeft: 5,
          },
          padding: 0,
        },
        switchBase: {
          color: Palette.neutral[50] + "!important",
        },
        thumb: {
          boxShadow: "none",
        },
        track: {
          backgroundColor: Palette.neutral[600],
          borderRadius: "48px",
          opacity: "initial!important",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: Palette.background.default,
          textAlign: "left",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          ".MuiChip-root": {
            alignItems: "center",
            minWidth: "74px",
            textalign: "center",
          },
          borderBottom: "none",
          color: Palette.neutral[600],
          fontSize: "14px!important",
          fontStyle: "normal",
          fontWeight: "normal",
          height: "64px",
          letterSpacing: "0.0015em",
          lineHeight: "120%",
        },
        head: {
          borderBottom: "none",
          color: Palette.text.primary,
          height: "45px",
        },
        root: {
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "120%",
          paddingLeft: "24px",
          paddingRight: "24px",
          verticalAlign: "middle",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: Palette.neutral[400],
          fontSize: "14px",
          fontWeight: "500",
          header: Palette.primary.light,
          height: "45px!important",
          lineHeight: "120%",
          textAlign: "left",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.solidTextfield": {
            "&.disabled .Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: Palette.neutral[400] + "!important",
            },
            "&.disabled .Mui-disabled svg": {
              opacity: "50%",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderRadius: "16px!important",
              transition: "all .5s ease-out",
            },
            margin: "0px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: ThemeFonts.screenmd.tooltip + "px",
          },
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: ThemeFonts.screensm.tooltip + "px",
          },
          backgroundColor: Palette.primary.dark,
          borderRadius: "8px",
          fontSize: ThemeFonts.screenxl.tooltip + "px",
          [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
            fontSize: ThemeFonts.screenlg.tooltip + "px",
          },
          fontWeight: "400",
          lineHeight: "1.35",
          p: {
            [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
              fontSize: ThemeFonts.screenmd.tooltip + "px",
            },
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: ThemeFonts.screensm.tooltip + "px",
            },
            fontSize: ThemeFonts.screenxl.tooltip + "px",
            [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
              fontSize: ThemeFonts.screenlg.tooltip + "px",
            },
            fontWeight: "600",
          },
          padding: "12px 16px",
          span: {
            color: Palette.primary.dark,
          },
          textAlign: "center",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: "break-word",
        },
      },
    },
  },
  palette: {
    alternate: {
      dark: Palette.alternate.dark,
      //@ts-ignore
      dark2: Palette.alternate.dark2,
      light: Palette.alternate.light,
      light2: Palette.alternate.light2,
      light3: Palette.alternate.light3,
      main: Palette.alternate.main,
    },
    background: {
      default: Palette.background.default,
      paper: Palette.background.paper,
    },
    error: {
      dark: Palette.error.dark,
      //@ts-ignore
      dark2: Palette.error.dark2,
      light: Palette.error.light,
      light2: Palette.error.light2,
      light3: Palette.error.light3,
      main: Palette.error.main,
    },
    gradients: {
      gradientA: Palette.gradients.gradientA,
      gradientB: Palette.gradients.gradientB,
      gradientC: Palette.gradients.gradientC,
      gradientD: Palette.gradients.gradientD,
      gradientE: Palette.gradients.gradientE,
    },
    neutral: {
      //@ts-ignore
      components: {
        disabled: Palette.neutral.components.disabled,
        shadow: Palette.neutral.components.shadow,
      },
      grey1: Palette.neutral[100],
      grey2: Palette.neutral[200],
      grey3: Palette.neutral[300],
      grey4: Palette.neutral[400],
      grey5: Palette.neutral[500],
      grey6: Palette.neutral[600],
      grey7: Palette.neutral[700],
      grey8: Palette.neutral[800],
      white: Palette.neutral[50],
    },
    primary: {
      dark: Palette.primary.dark,
      //@ts-ignore
      dark2: Palette.primary.dark2,
      light: Palette.primary.light,
      light2: Palette.primary.light2,
      light3: Palette.primary.light3,
      main: Palette.primary.main,
      // eslint-disable-next-line sort-keys
      components: {
        light: {
          default: Palette.primary.components.light.default,
          disable: Palette.primary.components.light.disable,
          focus: Palette.primary.components.light.focus,
          hover: Palette.primary.components.light.hover,
          //@ts-ignore
        },
      },
    },
    secondary: {
      dark: Palette.secondary.dark,
      //@ts-ignore
      dark2: Palette.secondary.dark2,
      light: Palette.secondary.light,
      light2: Palette.secondary.light2,
      light3: Palette.secondary.light3,
      main: Palette.secondary.main,
    },
    spacing: 8,
    text: {
      //@ts-ignore
      dark: Palette.text.dark,
      disabled: Palette.text.disabled,
      grey: Palette.text.grey,
      lightGrey: Palette.text.lightGrey,
      primary: Palette.text.primary,
      red: Palette.text.red,
      secondary: Palette.text.secondary,
      white: Palette.text.white,
    },
    warning: {
      dark: Palette.warning.dark,
      //@ts-ignore
      dark2: Palette.warning.dark2,
      light: Palette.warning.light,
      light2: Palette.warning.light2,
      light3: Palette.warning.light3,
      main: Palette.warning.main,
    },
  },
  typography: {
    body1: {
      fontSize: ThemeFonts.screenxl.body1 + "px",
      fontWeight: "normal",
      lineHeight: "150%",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.body1 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.body1 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.body1 + "px",
      },
    },
    body2: {
      fontSize: ThemeFonts.screenxl.body2 + "px",
      fontWeight: "normal",
      lineHeight: "150%",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.body2 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.body2 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.body2 + "px",
      },
    },
    button: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.overline + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.overline + "px",
      },
      //Numeric Text Style
      fontFamily: "IBM Plex Mono",
      fontSize: ThemeFonts.screenxl.overline + "px",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.overline + "px",
      },
      fontWeight: "norxsmal",
      lineHeight: "120%",
      textTransform: "none",
    },
    caption: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.caption + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.caption + "px",
      },
      fontSize: ThemeFonts.screenmd.caption + "px",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.caption + "px",
      },
      lineHeight: "144%",
    },
    fontFamily: "IBM Plex Sans",
    h1: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.h1 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.h1 + "px",
      },
      fontSize: ThemeFonts.screenxl.h1 + "px",
      fontStyle: "normal",
      fontWeight: "500",
      letterSpacing: "-0.015em",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.h1 + "px",
      },
      lineHeight: "120%",
    },
    h2: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.h2 + "px",
        fontWeight: "500",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.h2 + "px",
        fontWeight: "500",
      },
      fontSize: ThemeFonts.screenxl.h2 + "px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "-0.015em",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.h2 + "px",
        fontWeight: "500",
      },
      lineHeight: "120%",
    },
    h3: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.h3 + "px",
        fontWeight: "500",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.h3 + "px",
        fontWeight: "500",
      },
      fontSize: ThemeFonts.screenxl.h3 + "px",
      fontStyle: "normal",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.h3 + "px",
        fontWeight: "500",
      },
      fontWeight: "600",
      lineHeight: "120%",
    },
    h4: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.h4 + "px",
        fontWeight: "500",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.h4 + "px",
        fontWeight: "500",
      },
      fontSize: ThemeFonts.screenxl.h4 + "px",
      fontStyle: "normal",
      fontWeight: "500",
      letterSpacing: "0.0025em",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.h4 + "px",
        fontWeight: "500",
      },
      lineHeight: "120%",
    },
    h5: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.h5 + "px",
        fontWeight: "500",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.h5 + "px",
        fontWeight: "500",
      },
      fontSize: ThemeFonts.screenxl.h5 + "px",
      fontStyle: "normal",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.h5 + "px",
        fontWeight: "500",
      },
      fontWeight: "500",
      lineHeight: "140%",
    },
    h6: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.h6 + "px",
        fontWeight: "500",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.h6 + "px",
        fontWeight: "500",
      },
      fontSize: ThemeFonts.screenxl.h6 + "px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "0.0015em",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.h6 + "px",
        fontWeight: "500",
      },
      lineHeight: "120%",
    },
    overline: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.overline + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.overline + "px",
      },
      fontSize: ThemeFonts.screenxl.overline + "px",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.overline + "px",
      },
      fontWeight: "normal",
      letterSpacing: "0.01em",
      lineHeight: "120%",
      textTransform: "inherit",
    },
    subtitle1: {
      fontSize: ThemeFonts.screenxl.subtitle1 + "px",
      fontWeight: "300",
      lineHeight: "120%",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.subtitle1 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.subtitle1 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.subtitle1 + "px",
      },
    },
    subtitle2: {
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: ThemeFonts.screenmd.subtitle2 + "px",
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: ThemeFonts.screensm.subtitle2 + "px",
      },
      fontSize: ThemeFonts.screenxl.subtitle2 + "px",
      fontWeight: "500",
      letterSpacing: "0.0025em",
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: ThemeFonts.screenlg.subtitle2 + "px",
      },
      lineHeight: "150%",
    },
  },
});

export default theme;
