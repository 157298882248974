import { isUndefined } from "lodash";
import { UseButtonCardState } from "./useButtonCard.interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItemMassivePathEnum } from "../../../shared/infrastructure/MenuItemPathsEnum";

export const useButtonCard = (
  url: string,
  checked: boolean | undefined
): UseButtonCardState => {
  const history = useNavigate();
  const location = useLocation();
  const isTextButton: boolean = isUndefined(checked);

  const handleOnClick = () => {
    if (
      location.pathname.includes("batch") ||
      location.pathname === MenuItemMassivePathEnum.RATES_DISCOUNTS_MASSIVE
    ) {
      history(url);

      return;
    }
    window.location.href = url;
  };

  return {
    handleOnClick,
    isTextButton,
  };
};
