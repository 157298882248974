import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import axios from "../../../../shared/axios-util";
import { TransactionResultEnum } from "../../../../shared/enums/transaction_result_enum";
import { IFiltersState } from "../../../interfaces/FiltersState.interfaces";
import { ITransaction } from "../../../interfaces/TransactionsState.interfaces";
import { ITransactionsFiltersBody } from "../../../../../types/transactions_filters_body";
import { ITransactionsElasticResponse } from "../../../../../types/transactions_elastic_response";
import {
  setTransactionsLimit,
  setTransactionsTotal,
} from "../../../actions/filters/filters";
import { TRANSACTIONS_PER_PAGE_LIMITS } from "../../../../shared/constants";
import {
  PAYMENT_METHOD_FROM_RESPONSE_LOOKUP,
  TRANSACTION_STATUS_FROM_RESPONSE_LOOKUP,
} from "../../../../shared/types";
import { API_ENDPOINTS } from "../../../../shared/constants/api_endpoints";
import {
  mapHistoricalTransaction,
  parseToEnUSDate,
  parseToEsDate,
} from "../../../../shared/utils";
import { formatCurrency } from "../../../../shared/utils/currency_utils";

const getTransactions = createAsyncThunk<
  ITransaction[],
  IFiltersState,
  { rejectValue: string }
>(
  "getTransactions",
  async (
    { date, general, pagination, search, sortOrder }: IFiltersState,
    { dispatch }
  ) => {
    const filtersBody: ITransactionsFiltersBody = {
      filter: {
        b2c_channel: "vehiscore",
        payment_method: general.paymentMethod.join(","),
        transaction_status: general.transactionStatus.join(",").toUpperCase(),
      },
      from: parseToEnUSDate(date.from),
      limit: pagination.limit,
      offset: pagination.limit * (pagination.page - 1),
      sort: {
        field: "created",
        order: sortOrder,
      },
      text: search.text,
      to: parseToEnUSDate(date.to),
    };

    const {
      data: { data, total },
    } = await axios.post<ITransactionsElasticResponse>(
      API_ENDPOINTS.GET_TRANSACTIONS,
      filtersBody
    );

    if (total < pagination.limit) {
      dispatch(setTransactionsLimit(TRANSACTIONS_PER_PAGE_LIMITS[0]));
    }

    dispatch(setTransactionsTotal(total));

    // TODO: change empty string and arrays values with the corresponding backend fields values when available.
    // Those backend fields should be declared in the schema ITransactionResponse.
    // Path to the schema: src/schema/transactions_elastic_response.json
    const transactions: ITransaction[] = data.map(
      ({ _source: dataSource }): ITransaction => ({
        acquirerBank: get(dataSource, "acquirer_bank", ""),
        answerCode: "",
        answerMessage: get(dataSource, "response_text", ""),
        approvalNumber: get(dataSource, "approval_code", ""),
        bank: "",
        bankID: get(dataSource, "bank_id", ""),
        cardNumber: `**** ${get(dataSource, "masked_credit_card", "").substring(
          get(dataSource, "masked_credit_card", "").length - 4
        )}`,
        cardType: get(dataSource, "payment_brand", "").toLowerCase(),
        certificateState: get(dataSource, "transaction_result", ""),
        certificateURL: get(dataSource, "pdf_url", ""),
        comments: get(dataSource, "comments", []),
        cus: get(dataSource, "trazability_code", ""),
        date: parseToEsDate(dataSource.created).date,
        document_number: get(dataSource, "document_number", ""),
        email: dataSource.email,
        fileStatusDownload: dataSource.file_status_download,
        historical: mapHistoricalTransaction(dataSource.historical),
        hour: parseToEsDate(dataSource.created).hour,
        id: dataSource.id,
        months: get(dataSource, "months", "0"),
        name: `${get(dataSource, "first_name", "")} ${get(
          dataSource,
          "last_name",
          ""
        )}`,
        paymentMethod:
          PAYMENT_METHOD_FROM_RESPONSE_LOOKUP[dataSource.payment_method],
        phoneNumber: get(dataSource, "phone", ""),
        processor: "",
        reattemps: get(dataSource, "download_attempts", "No"),
        refund:
          dataSource.transaction_result === TransactionResultEnum.REFUND
            ? "Si"
            : "No",
        refundState:
          dataSource.transaction_result === TransactionResultEnum.REFUND
            ? dataSource.transaction_result
            : "---",
        sendAnswer: "",
        sendStatus: get(dataSource, "file_status_download", ""),
        socialReason: get(dataSource, "social_reason", ""),
        status:
          TRANSACTION_STATUS_FROM_RESPONSE_LOOKUP[
            dataSource.transaction_status
          ],
        ticketNumber: dataSource.ticket_number ?? "",
        transactionAmount: `${formatCurrency(
          dataSource.total || dataSource.iva_value! + dataSource.subtotal_iva!
        )} ${dataSource.currency_code}`,
        transactionId: dataSource.id,
        transactionReference: dataSource.transaction_reference,
        vehiclePlate: dataSource.license_plate,
      })
    );

    return transactions;
  }
);

export default getTransactions;
