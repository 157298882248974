import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const ModalSliderStyles = createNamedStyles({
  container: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FAFCFD",
    borderRadius: "1rem",
    boxShadow: 24,
    boxSizing: "border-box",
    p: 4,
    width: {
      sm: "80%",
      md: "65%",
    },
    maxWidth: {
      sm: "80%",
      md: "1010px",
    },
    height: "542px",
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButton: {
    backgroundColor: "transparent",
  },
});
