import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { ConfirmModalProps } from "./ConfirmModal.interfaces";
import useStyles from "./ConfirmModal.styles";

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" color="primary">
            {props.titleText}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText
            className={classes.dialogContentText}
            id="alert-dialog-description"
          >
            <Typography variant="h6" color="textPrimary">
              {props.contentText}
            </Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={props.handleClose} className={classes.cancelAction}>
          {props.cancelText}
        </Button>
        {props.isAddConfig ? (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleAccept}
            disabled={props.isLoadingRequest}
          >
            {props.acceptText}
          </Button>
        ) : (
          <Button
            className={
              props.acceptColor === "error"
                ? classes.errorColor
                : classes.primaryColor
            }
            onClick={props.handleAccept}
            disabled={props.isLoadingRequest}
            autoFocus
          >
            {props.acceptText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  isAddConfig: false,
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
