import {
  DocumentRequestData,
  GetDownloadFileRequest,
} from "../../../../types/get_download_file_request";
import { RequestDownloadEnum } from "./StatusFlowDashboardConstants";

export const getDownloadTransactionRequest = ({
  origin,
  body,
  request,
}: {
  body: DocumentRequestData;
  origin: string;
  request: RequestDownloadEnum;
}): GetDownloadFileRequest => ({
  action: "document",
  data: {
    ...body,
    origin,
  },
  request,
});
