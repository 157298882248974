import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  editLegalRepresentative,
  setEdited,
  setGeneralPercentage,
  setLegalRepresentative,
  setOpenModal,
  setShareHolder,
} from "../../../../../store/reducers/legalDetails/legalDetails";
import {
  ILegalRepresentative,
  IShareHolder,
  PersonCategoryEnum,
} from "../../../../../store/interfaces/LegalDetailsState.interfaces";
import {
  physicalPersonRules,
  RegularExp,
} from "../../../../../shared/constants/form_labels_legal_details";
import { useEffect, useState } from "react";
import {
  insertShareholderByPosition,
  validateIsPossibleAddShareholder,
} from "../../../../../shared/utils/shareholderUtils";
import { DefaultValuesMoralPerson } from "../../../../../shared/enum/LegalRepresentativeEnum";
import { cloneDeep, get, isEmpty, replace, set } from "lodash";
import { CountryEnum } from "../../../../../shared/countryEnum";
import { IMultipleFormValues } from "../../../../../shared/constants/initial_state_legal_details";
import {
  DOCUMENT_TYPE_BY_COUNTRY_VALUES,
  isAvailableCountry,
} from "../../../../../shared/services/CatalogDocumentTypeByCountry";
import { v4 } from "uuid";
import { DEFAULT_PERCENTAGE } from "../../../../../shared/constants/shareholder_constants";
import {
  getAvailablePercentageLR,
  getPositionShareholder,
} from "../../../../../shared/utils/legalRepresentativeUtils";

export const useMoralPersonForm = () => {
  const {
    isEdited,
    legalRepresentative,
    generalPercentage,
    shareHolders,
    nodeInfo,
  } = useSelector((state: RootState) => ({
    ...state.legalDetails,
    ...state.initialData,
  }));

  const availablePercentageLR = getAvailablePercentageLR(
    shareHolders,
    get(isEdited, "uid", "")
  );

  const defaultValuesMoralForm = () => {
    if (isEdited.value) {
      const recordToEdit: ILegalRepresentative | undefined =
        legalRepresentative.find((item) => item.id === isEdited.id);
      let editSet: object;

      if (recordToEdit!.personType === "01") {
        editSet = {
          ...recordToEdit!,
          constitutionDate: "",
          merchantAdress: "",
          merchantCountry: "",
          socialReason: "",
          taxId: "",
        };
      } else {
        editSet = {
          ...recordToEdit!,
          birthdate: "",
          country: "",
          documentNumber: "",
          documentType: "",
          lastName: "",
          name: "",
          nationality: "",
        };
      }

      return editSet;
    }

    return {
      cellphoneNumber: DefaultValuesMoralPerson.EMPTY,
      personType: DefaultValuesMoralPerson.PERSON_TYPE,
    };
  };
  const dispatch = useDispatch();

  const { handleSubmit, control, watch, unregister, setValue } =
    useForm<FieldValues>({
      defaultValues: defaultValuesMoralForm(),
    });

  const [isFirstEdit, setIsFirstEdit] = useState(true);

  const [isPossibleAddShareholder, setIsPossibleAddShareholder] =
    useState(true);

  const closeModal = () => {
    dispatch(setEdited({ id: "", value: false }));
    dispatch(setOpenModal(false));
  };

  const validateShareHolder = (data: FieldValues) => {
    if (isEmpty(data.documentNumber))
      return (
        shareHolders.filter((item) => item.taxId === data.taxId).length === 0
      );

    return (
      shareHolders.filter((item) => item.documentNumber === data.documentNumber)
        .length === 0
    );
  };

  const personTypeWatch = watch("personType");
  const personNationalityCountryWatch = watch("nationality");
  const countryWatch = watch("country");
  const merchantAdressWatch = watch("merchantAdress");
  const shareHolderWatch = watch("isShareHolder");
  const documentTypeWatch = watch("documentType");
  const rulesDocumentNumber =
    documentTypeWatch === "0"
      ? physicalPersonRules.documentNumber
      : physicalPersonRules.documentNumberNumeric;
  const regExpDocumentNumber =
    documentTypeWatch === "0" ? RegularExp.DEFAULT : RegularExp.ONLY_NUMBERS;
  const country: CountryEnum = get(
    nodeInfo,
    "generalInfo.country",
    CountryEnum.generic
  );
  const constitutionalCountry: CountryEnum = get(
    nodeInfo,
    "generalInfo.constitutionalCountry",
    CountryEnum.generic
  );
  const [listDocTypesByCountry, setListDocTypesByCountry] = useState<
    IMultipleFormValues[]
  >([]);

  useEffect(() => {
    if (!shareHolderWatch || personTypeWatch === "01") {
      unregister("isShareFolder");
    }
  }, [shareHolderWatch, personTypeWatch]);
  useEffect(() => {
    isEdited.value && isFirstEdit
      ? setValue("documentType", documentTypeWatch)
      : setValue("documentType", "");
    isAvailableCountry(personNationalityCountryWatch)
      ? setListDocTypesByCountry(
          DOCUMENT_TYPE_BY_COUNTRY_VALUES[personNationalityCountryWatch]
        )
      : setListDocTypesByCountry(
          DOCUMENT_TYPE_BY_COUNTRY_VALUES[CountryEnum.generic]
        );
    setIsFirstEdit(false);
  }, [personNationalityCountryWatch]);

  useEffect(() => {
    setIsPossibleAddShareholder(
      !validateIsPossibleAddShareholder(shareHolders)
    );
  }, [shareHolders]);

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    let cellphoneSplitted = data.cellphoneNumber.split(" ");
    let uid = v4();

    const currentRepresentative: ILegalRepresentative | undefined =
      legalRepresentative.find(
        (legalRepresentative) => legalRepresentative.id === data.id
      );

    data.personCategory = PersonCategoryEnum.REPRESENTATIVE;
    data.countryCode = cellphoneSplitted[0];
    data.isMain =
      currentRepresentative?.isMain ?? legalRepresentative.length === 0;

    if (data.cellphoneNumber.startsWith("52")) {
      data.areaCode = cellphoneSplitted[1];
    } else {
      data.areaCode = "";
    }

    data.participationPercentage = !data.isShareHolder
      ? "0"
      : replace(data.participationPercentage, "%", "");

    const docTypeForShareholder: IMultipleFormValues[] =
      listDocTypesByCountry.filter((value) => value.value == data.documentType);
    let newShareHolder: IShareHolder = { ...data } as IShareHolder;

    set(
      newShareHolder,
      "documentType",
      get(docTypeForShareholder[0], "defaultShareholderDocType", "0")
    );
    if (isEdited.value) {
      const positionSH = getPositionShareholder(shareHolders, isEdited.uid!);

      dispatch(editLegalRepresentative(data as ILegalRepresentative));
      dispatch(
        setShareHolder(
          insertShareholderByPosition(
            String(positionSH),
            cloneDeep(shareHolders),
            newShareHolder,
            true
          )
        )
      );
      dispatch(setEdited({ id: "", value: false }));
    } else {
      set(data, "uid", uid);
      data.id = uid;
      dispatch(setLegalRepresentative(data as ILegalRepresentative));
    }
    const validate: boolean = validateShareHolder(data);

    if (data.isShareHolder && validate && isPossibleAddShareholder) {
      set(data, "uid", uid);
      set(newShareHolder, "uid", uid);
      dispatch(
        setShareHolder(
          insertShareholderByPosition(
            shareHolders.length.toString(),
            shareHolders,
            newShareHolder,
            true
          )
        )
      );
      dispatch(setGeneralPercentage(data.participationPercentage));
    }

    dispatch(setOpenModal(false));
  };

  return {
    availablePercentage: DEFAULT_PERCENTAGE - availablePercentageLR,
    closeModal,
    form: {
      control,
      handleSubmit,
      onSubmit,
    },
    generalPercentage,
    isEdited,
    isPossibleAddShareholder,
    legalRepresentative,
    values: {
      constitutionalCountry,
      country,
      countryWatch,
      listDocTypesByCountry,
      merchantAdressWatch,
      personTypeWatch,
      regExpDocumentNumber,
      rulesDocumentNumber,
      shareHolderWatch,
    },
  };
};
