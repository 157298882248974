import { IStyles } from "../../../shared/interfaces/create_named_styles";

export const textInputStyles: IStyles = {
  selectContainer: {
    width: 1,
    "& > img": { flexShrink: 0, mr: 2 },
  },
  error1: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A",
    },
  },
  error2: {
    "& .MuiFormHelperText-root": {
      color: "#AD0C2A !important",
    },
  },
};
