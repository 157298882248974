export enum TransactionRatesEnum {
  CREDIT = "credit",
  DEBIT = "debit",
  DEFERRED = "deferred",
  CURRENT = "current",
  AGREGATION = "AGGREGATOR",
  GATEWAY = "GATEWAY",
  FORMAL = "FORMAL",
  PASARELA = "PASARELA",
  DIRECT_COLLECT = "DIRECT_COLLECT",
  NATIONAL = "national",
  INTERNATIONAL = "international",
  CARD = "card",
  CASH = "cash",
  TRANSFER = "transfer",
  PAYIN = "payIn",
  PAYOUT = "payOut",
  SERVICES = "additionalServices",
  SIFTSCIENCE = "siftScience",
  TRANSUNION = "transUnion",
  THREEDS = "3ds",
  OTP = "otp",
}
