export const parseMonthsToEN = {
  abr: "Apr",
  ago: "Aug",
  dic: "Dec",
  ene: "Jan",
  feb: "Feb",
  jul: "Jul",
  jun: "Jun",
  mar: "Mar",
  may: "May",
  nov: "Nov",
  oct: "Oct",
  sep: "Sep",
};

export const parseMonthsToES = {
  Apr: "Abr",
  Aug: "Ago",
  Dec: "Dic",
  Feb: "Feb",
  Jan: "Ene",
  Jul: "Jul",
  Jun: "Jun",
  Mar: "Mar",
  May: "May",
  Nov: "Nov",
  Oct: "Oct",
  Sep: "Sep",
};

export enum DateFormatEnum {
  dd_MMM_yyyy = "dd/MMM/yyyy",
  DD_MMM_YYYY = "DD/MMM/YYYY",
  dd_MM_yyyy_HH_mm = "dd/MM/yyyy - HH:mm",
  yyyy_MM_dd = "yyyy-MM-dd",
  dd_MM_yyyy = "dd-MMM-yyyy",
  ddMMyyyy = "dd/MM/yyyy",
  ddmmyyyy = "dd-MM-yyyy HH:mm",
}

export const defaultDate = "14/Feb/2023";
