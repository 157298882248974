import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { ICredentialsWrapperProps } from "../../shared/infrastructure/interfaces/ICredentialsWrapperProps";

export const CredentialsWrapper: React.FC<ICredentialsWrapperProps> = ({
  children,
  isNodeLayout,
}) => {
  return (
    <>
      {isNodeLayout ? (
        <Container>
          <Grid container>
            <Grid container item xs={12} direction={"column"}>
              <Grid item style={{ backgroundColor: "white" }} paddingX={"1em"}>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Box padding={"48px"} paddingRight={"62px"}>
          {children}
        </Box>
      )}
    </>
  );
};
