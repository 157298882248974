import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";

import { RootState } from "../../../store/store";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { BranchEnum } from "../../../shared/enums/BranchEnum";
import { IUseBranchList } from "./IUseBranchList.interfaces";
import { resetBranchList } from "../../../store/actions/branch.actions";

export const useBranchList = (): IUseBranchList => {
  const dispatch = useAppDispatch();

  const [merchant, setMerchant] = useState<MerchantNodeData>({});
  const [nodeId, setNodeId] = useState<string>("");
  const [emptyAddText, setEmptyAddText] = useState<string>(
    BranchEnum.ADD_BRANCHES
  );

  const handleReturn = () => {
    window.history.go(-1);
  };

  const [entityName] = useState<EntityNameEnum>(EntityNameEnum.BRANCH);

  const { isLoadingMerchants, branchList, nodeInfo } = useAppSelector(
    (state: RootState) => ({
      ...state.app,
      ...state.branch,
    })
  );

  useEffect(() => {
    !isEmpty(nodeInfo) && setNodeId(get(nodeInfo, "path", ""));
    setEmptyAddText(BranchEnum.ADD_BRANCHES);
  }, [nodeInfo]);

  useEffect(() => {
    return () => {
      setMerchant({});
      dispatch(resetBranchList());
    };
  }, []);

  return {
    branchList,
    emptyAddText,
    entityName,
    handleReturn,
    isLoadingMerchants,
    merchantByNodeId: merchant,
    pathCustomer: nodeId,
    showEmptyScreen: isEmpty(get(branchList, "data", [])),
  };
};
