import { IAppState } from "../../../store/reducer";
import { FileValidationDetailsResponse } from "../../../../types/file_validation_details_response";
import { useDispatch, useSelector } from "react-redux";
import { IUseFileValidationDetailsState } from "../../../shared/infrastructure/interfaces/IUseFileValidationDetails.interface";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFileValidationDetails } from "../../../store/actionCreators";
import { Routes } from "../../../shared/infrastructure/routes";
import { IParams } from "../../../shared/infrastructure/interfaces/IParams";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

export const useFileValidationDetailsState =
  (): IUseFileValidationDetailsState => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: IParams = useParams<IParams>();

    const isLoadingDetails = useSelector((state: IAppState) =>
      get(state, "isLoadingDetails", false)
    );

    const fileValidationDetails: FileValidationDetailsResponse | undefined =
      useSelector((state: IAppState) => state.fileValidationDetails);

    const handleRequestOption = (): void => {
      history.push(`${Routes.BASE_PATH_DISPERSION}`);
    };

    useEffect(() => {
      dispatch(getFileValidationDetails(params.referenceNumber));
    }, []);

    return {
      fileValidationDetails,
      isLoadingDetails,
      handle: {
        handleRequestOption,
      },
    };
  };
