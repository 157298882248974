import React from "react";
import { MerchantBillingInfo } from "../../../../../types/merchant_billing_info";
import { useBillingInfoState } from "../../../BillingInfo/state/useBillingInfoState";
import {
  CURRENCY_COUNTRY,
  NOTCHARGE,
} from "../../../../shared/constants/MerchantInformationConstants";
import { defaultTo, get } from "lodash";
import {
  Info,
  InfoContentData,
} from "../../../../components/InfoContent/InfoContentData/InfoContentData";
import { MinAmountLabelEnum } from "../../../../shared/enums/MinAmountLabelEnum";
import { FrequencyNameEnum } from "../../../../shared/enums/FrequencyNameEnum";
import { CountriesEnum } from "../../../../shared/enums/CountriesEnum";
import { InfoContentCard } from "../../../../components/InfoContent/InfoContentCard/InfoContentCard";

export interface MinAmountProps {
  merchantInfo?: MerchantBillingInfo;
}

export const MinAmount: React.FC<MinAmountProps> = (props: MinAmountProps) => {
  const { handlers } = useBillingInfoState();
  const commissionData: Info[] = [];
  const country: string = get(props, "merchantInfo.country", "");
  const isChile: boolean = CountriesEnum.CHILE === country;
  const currencyCountry: string =
    isChile && get(props, "merchantInfo.minAmount.currency")
      ? get(props, "merchantInfo.minAmount.currency")
      : CURRENCY_COUNTRY.get(country);

  const minAmountType: string = defaultTo(
    get(props.merchantInfo, "chargeMin"),
    get(props.merchantInfo, "minAmount.type", "")
  );

  if (minAmountType !== NOTCHARGE) {
    commissionData.push({
      tag: MinAmountLabelEnum.FREQUENCY,
      tooltip: false,
      value: FrequencyNameEnum.MONTHLY,
    });
    commissionData.push({
      description: handlers.mapTypeMinCharge(
        get(props, "merchantInfo.minAmount.type", "")
      ).typeTooltip,
      tag: MinAmountLabelEnum.MINIMUM_CHARGE_RATE,
      tooltip: true,
      value: handlers.mapTypeMinCharge(
        get(props, "merchantInfo.minAmount.type", "")
      ).type,
    });
    commissionData.push({
      description: handlers.mapTypeMinCharge(
        get(props, "merchantInfo.minAmount.amount", "")
      ).minTooltip,
      tag: MinAmountLabelEnum.MINIMUM_CHARGE,
      tooltip: true,
      value: `${get(
        props,
        "merchantInfo.minAmount.amount",
        ""
      )} ${currencyCountry}`,
    });

    const gracePeriod =
      get(props, "merchantInfo.gracePeriod") ||
      get(props, "merchantInfo.minAmount.gracePeriod", 0);

    commissionData.push({
      description: MinAmountLabelEnum.GRACE_PERIOD_DESCRIPTION,
      tag: MinAmountLabelEnum.GRACE_PERIOD,
      tooltip: true,
      value: handlers.formatGracePeriod(gracePeriod),
    });
    commissionData.push({
      tag: MinAmountLabelEnum.END_GRACE_PERIOD,
      tooltip: false,
      value: handlers.formatDate(
        get(props, "merchantInfo.created", ""),
        get(props, "merchantInfo.country", ""),
        get(props, "merchantInfo.gracePeriod", 0)
      ),
    });
  }

  return (
    <>
      <InfoContentCard
        title={MinAmountLabelEnum.MINIMUM_COMMISSION}
        legend={
          commissionData.length === 0
            ? MinAmountLabelEnum.NO_MINIMUM_COMMISSION
            : MinAmountLabelEnum.MINIMUM_COMMISSION_LEGEND
        }
      >
        <InfoContentData
          data={commissionData.length > 0 ? commissionData : []}
        />
      </InfoContentCard>
    </>
  );
};
