import { IOptionFilter } from "@kushki/frontend-molecules/filter-container";
import { FilterTypeEnum, FilterTypeEsEnum } from "../Enums/FilterTypeEnum";
import { Catalog, Data } from "../../../../types/catalog";
import {
  catalogsDataToFilterOptions,
  codesFromCatalogData,
  getDataFromCatalogByName,
} from "../../../utils/functions/Catalog";
import { CatalogsNameEnum } from "../Enums/CatalogsNameEnum";

export const defaultFilterOptions = (
  catalogsList: Catalog[] | undefined,
  retentionType: number
): IOptionFilter[] => {
  if (!catalogsList || catalogsList.length === 0) {
    return [];
  }

  if (retentionType == 0) {
    const taxPayerTypes: Data[] = getDataFromCatalogByName(
      catalogsList,
      CatalogsNameEnum.TAX_PAYER_TYPE
    );
    const activityTypes: Data[] = getDataFromCatalogByName(
      catalogsList,
      CatalogsNameEnum.ACTIVITY
    );
    return [
      {
        id: FilterTypeEnum.taxpayerType,
        title: FilterTypeEsEnum.taxpayerType,
        options: catalogsDataToFilterOptions(taxPayerTypes),
      },
      {
        id: FilterTypeEnum.activity,
        title: FilterTypeEsEnum.activity,
        options: catalogsDataToFilterOptions(activityTypes),
      },
    ];
  }

  const incomeSourceConceptTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.INCOME_SOURCE_CONCEPT
  );

  return [
    {
      id: FilterTypeEnum.incomeSourceConcept,
      title: FilterTypeEsEnum.incomeSourceConcept,
      options: catalogsDataToFilterOptions(incomeSourceConceptTypes),
    },
  ];
};

export const filterOptionsEnabled = (
  catalogsList: Catalog[] | undefined
): { key: string; data: string[] }[] => {
  if (!catalogsList || catalogsList.length === 0) {
    return [];
  }
  const taxPayerTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.TAX_PAYER_TYPE
  );
  const incomeSourceConceptTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.INCOME_SOURCE_CONCEPT
  );
  const activityTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.ACTIVITY
  );

  return [
    {
      key: FilterTypeEnum.taxpayerType,
      data: codesFromCatalogData(taxPayerTypes),
    },
    {
      key: FilterTypeEnum.incomeSourceConcept,
      data: codesFromCatalogData(incomeSourceConceptTypes),
    },
    {
      key: FilterTypeEnum.activity,
      data: codesFromCatalogData(activityTypes),
    },
  ];
};
