import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app.slice";
import BalanceReducer from "./reducers/balanceData/balanceData.slice";
import WalletTransactionReducer from "./reducers/walletTransaction/walletTransaction.slice";
import WalletReportSlice from "./reducers/walletReport/walletReport.slice";
import { socketMiddleware } from "./thunks/walletReport/walletReport.thunk";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([socketMiddleware]),
  reducer: {
    app: appReducer,
    balanceReducer: BalanceReducer,
    walletReport: WalletReportSlice,
    walletTransaction: WalletTransactionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
