import React, { FC } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ISubHeaderProps } from "./SubHeader.interfaces";
import { subHeaderStyles as styles } from "./SubHeader.styles";
import { IconEdit } from "@kushki/connect-ui";
import * as subHeaderLabels from "../../shared/constants/labels/add_webhook_labels";
import { getCountryFlag } from "../../shared/constants/flags/countryFlag";

const SubHeader: FC<ISubHeaderProps> = ({
  name,
  country,
  merchantId,
}: ISubHeaderProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.primaryBox}>
        <Box sx={styles.boxTitle}>
          <Typography sx={styles.merchantNameLabel} variant={"h3"}>
            {name}
          </Typography>
          <IconEdit color={"primary"} />
        </Box>
      </Box>
      <Box sx={styles.secondaryBox}>
        <Typography sx={styles.secondaryLabels}>
          {subHeaderLabels.COUNTRY_LABEL}
        </Typography>
        {getCountryFlag[country]}
        <Divider orientation={"vertical"} flexItem sx={styles.divider} />
        <Typography sx={styles.secondaryLabels}>
          {subHeaderLabels.ID_LABEL}
        </Typography>
        <Typography variant={"body1"}>{merchantId}</Typography>
      </Box>
    </Box>
  );
};

export default SubHeader;
