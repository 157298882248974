import { createSlice } from "@reduxjs/toolkit";
import { IAppState } from "@store/interfaces/AppState.interfaces";

export const initialState: IAppState = {
  loading: false,
};

export const appSlice = createSlice({
  extraReducers: {},
  initialState,
  name: "app",
  reducers: {},
});

export default appSlice.reducer;
