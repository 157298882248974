export enum DeveloperConstants {
  TITLE = "Desarrolladores",
  KEY = "developers-section",
}

export enum DevelopersCredentialSectionConstants {
  TITLE = "Credenciales",
  BUTTON_TITLE = "Agregar credencial",
  FOOTER_TITLE = "Edita y revisa todas las credenciales",
  KEY = "credentials-grid-info",
}

export enum DevelopersWebhookSectionConstants {
  TITLE = "Webhooks",
  BUTTON_TITLE = "Agregar webhook",
  FOOTER_TITLE = "Edita y revisa todos los webhooks",
  KEY = "webhook-grid-info",
}
