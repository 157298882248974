import { FC } from "react";
import * as React from "react";
import { IOwnerHeader } from "./OwnerHeader.interfaces";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { IconPlus } from "@kushki/connect-ui";
import { OwnerEnum } from "../../shared/enums/OwnerEnum";
import { ownerHeaderStyles as styles } from "./OwnerHeader.styles";
import EmptyResults from "../EmptyResults/EmptyResults";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentsSecurity } from "../../shared/enums/SecurityWrapperEnum";
import HeaderSkeleton from "../HeaderSkeleton/HeaderSkeleton";
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";
import { CATALOG_HEADS_NODE } from "../../shared/catalogs/CatalogConfigTable";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { displayUtils } from "../../shared/utils/DisplayUtils";

export const OwnerHeader: FC<IOwnerHeader> = ({
  onClickButton,
  showEmptyScreen,
  emptyAddText,
  isLoadingFlow,
}: IOwnerHeader) => {
  return (
    <>
      <Box display={displayUtils(!isLoadingFlow)}>
        <HeaderSkeleton />
        <TableSkeleton
          headTable={CATALOG_HEADS_NODE[EntityNameEnum.CUSTOMER]}
          rowsPerPage={10}
        />
      </Box>
      <Box display={displayUtils(isLoadingFlow)}>
        <Grid container justifyContent="space-between">
          <Grid sx={styles.title} item xs={2}>
            <Typography variant={"h3"}>{OwnerEnum.CLIENTS}</Typography>
          </Grid>
          <Grid item xs={2}>
            <ComponentSecurityWrapper
              componentId={ComponentsSecurity.CREAR_OWNER}
            >
              <Button
                startIcon={<IconPlus />}
                variant="contained"
                color="primary"
                onClick={onClickButton}
              >
                {OwnerEnum.CREATE_OWNER}
              </Button>
            </ComponentSecurityWrapper>
          </Grid>
        </Grid>
        <br />
        <Divider light sx={styles.divider} />

        {showEmptyScreen && (
          <EmptyResults addText={emptyAddText} title={OwnerEnum.NO_DATA} />
        )}
      </Box>
    </>
  );
};

export default OwnerHeader;
