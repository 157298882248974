import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";
import { get } from "lodash";

const SvgIcon = styled(MuiSvgIcon, {
  name: "UploadIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 12 12",
  focusable: "false",
  "aria-hidden": "true",
};
interface IIconArrowsProps {
  colorArrowTop?: string;
  colorArrowBottom?: string;
}

const IconArrows: React.FunctionComponent<SvgIconProps & IIconArrowsProps> = (
  props
) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        fontSize={"small"}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666992 2.83366L4.00033 0.166992L7.33366 2.83366H0.666992Z"
          fill={get(props, "colorArrowTop", "#B4B9BE")}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666992 8.16699L4.00033 10.8337L7.33366 8.16699H0.666992Z"
          fill={get(props, "colorArrowBottom", "#B4B9BE")}
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconArrows;
