import { SwiftCountriesEnum } from "../../../shared/enums/swiftCountriesEnum";
import {
  IUseAddBankAccountDifferentCountry,
  IUseAddBankAccountDifferentCountryProps,
} from "./useAddBankAccountDifferentCountryState.interfaces";
import { useEffect, useState } from "react";
import { isNationCountry } from "../../../shared/utils/bankAccountUtils";
import { get, includes, isNil } from "lodash";
import { RoutingNumberCountriesEnum } from "../../../shared/enums/RoutingNumberCountriesEnum";
import { BankAccountFields } from "../../../shared/enums/dispersionsEnum";
import { CitibankAllowedCurrencyEnum } from "../../../shared/enums/CitibankAllowedCurrencyEnum";
import { accountRules } from "../../../shared/constants/form_account_labels";
import { ibanCountriesEnum } from "../../../shared/enums/ibanCountriesEnum";

export const useAddBankAccountDifferentCountryState = ({
  banksForm,
  country,
  changeIsInternationalTransfer,
  isEditingForm,
  isInternationalTransfer,
  isDispersionTab,
}: IUseAddBankAccountDifferentCountryProps): IUseAddBankAccountDifferentCountry => {
  const [isCountryWithSWIFT, setIsCountryWithSWIFT] = useState(false);
  const [isCountryWithIBAN, setCountryWithIBAN] = useState(false);
  const [isCountryWithRoutingNumber, setCountryWithRoutingNumber] =
    useState(false);
  const [isCountryInKushkiNation, setIsCountryInKushkiNation] = useState(false);
  const [isInvalidCurrency, setIsInvalidCurrency] = useState(
    !includes(
      CitibankAllowedCurrencyEnum,
      banksForm.getValues!(BankAccountFields.CURRENCY)
    )
  );
  const [isModeEdition, setIsModeEdition] = useState(isEditingForm);

  const [dynamicRules, setDynamicRules] = useState({
    beneficiaryName: accountRules.beneficiaryName,
    financialInstitutionAddress: accountRules.financialInstitutionAddress,
    financialInstitutionName: accountRules.financialInstitutionName,
    ibanCode: accountRules.ibanCode,
    swiftCode: accountRules.swiftCode,
  });

  useEffect(() => {
    if (isInternationalTransfer) {
      setDynamicRules({
        ...dynamicRules,
        beneficiaryName: accountRules.beneficiaryNameInternationalTransfer,
        financialInstitutionAddress:
          accountRules.financialInstitutionAddressInternationalTransfer,
        financialInstitutionName:
          accountRules.financialInstitutionNameInternationalTransfer,
        ibanCode: accountRules.ibanCodeInternationalTransfer,
        swiftCode: accountRules.swiftCodeInternationalTransfer,
      });
    } else {
      setDynamicRules({
        ...dynamicRules,
        beneficiaryName: accountRules.beneficiaryName,
        financialInstitutionAddress: accountRules.financialInstitutionAddress,
        financialInstitutionName: accountRules.financialInstitutionName,
        ibanCode: accountRules.ibanCode,
        swiftCode: accountRules.swiftCode,
      });
    }
  }, [isInternationalTransfer]);

  const countryHasProperty = (
    country: string,
    propertyEnum: object
  ): boolean => {
    for (const item of Object.values(propertyEnum)) {
      if (item.toUpperCase().trim() === country.toUpperCase().trim()) {
        return true;
      }
    }

    return false;
  };

  const validateCurrency = (
    currency: string,
    changeCurrency?: boolean
  ): void => {
    setIsInvalidCurrency(!includes(CitibankAllowedCurrencyEnum, currency));
    banksForm.setValue!(BankAccountFields.CURRENCY, currency as never);
    if (isDispersionTab)
      changeCurrency && changeIsInternationalTransfer!(false);
  };

  const onChangeCurrency = (selectEvent: object): void => {
    const currentCurrency: string = get(selectEvent, "target.value", "");

    validateCurrency(currentCurrency, true);
  };

  useEffect(() => {
    setIsCountryWithSWIFT(countryHasProperty(country, SwiftCountriesEnum));
    setCountryWithIBAN(countryHasProperty(country, ibanCountriesEnum));
    setCountryWithRoutingNumber(
      countryHasProperty(country, RoutingNumberCountriesEnum)
    );
    setIsCountryInKushkiNation(isNationCountry(country));
    if (!isNil(banksForm.clearErrors)) {
      banksForm.clearErrors();
      banksForm.resetField!(BankAccountFields.SWIFT_CODE);
    }
    if (isDispersionTab)
      !isModeEdition && changeIsInternationalTransfer!(false);
    setIsModeEdition(false);
  }, [country]);

  useEffect(() => {
    const currentCurrency: string = banksForm.getValues!(
      BankAccountFields.CURRENCY
    );

    validateCurrency(currentCurrency);
  }, [banksForm.getValues!(BankAccountFields.CURRENCY)]);

  return {
    dynamicRules,
    isCountryInKushkiNation,
    isCountryWithIBAN,
    isCountryWithRoutingNumber,
    isCountryWithSWIFT,
    isInvalidCurrency,
    onChangeCurrency,
  };
};
