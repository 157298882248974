import { environment } from "../../environments/environment";
import { get } from "lodash";
import { VoidBody } from "../../store/interfaces/AppState.interfaces";

export const API_ROUTES = {
  CARD_CHARGE: `${environment.kushkiUrl}/card/v1/admin/charges/`,
  CHARGEBACK: (
    data: VoidBody
  ): {
    method: string;
    message: string;
  } => {
    const typeTrx: string = get(data, "kind", "card");

    switch (typeTrx) {
      case "card":
        return {
          message: `Contracargo realizado con éxito del número de ticket ${data.id}\n`,
          method: `${environment.kushkiUrl}/card/v1/admin/chargeback/${data.id}`,
        };
      case "subscription":
        return {
          message: `Contracargo de Susbcripciones realizado con éxito del número de ticket ${data.id}\n`,
          method: `${environment.kushkiUrl}/v1/subscriptions/admin/chargeback/${data.id}`,
        };
      case "commission":
        return {
          message: `Contracargo de Comisiones realizado con éxito del numero de ticket ${data.id}\n`,
          method: `${environment.kushkiUrl}/commission/v1/admin/chargeback/${data.id}`,
        };
      default:
        return {
          message: `Contracargo realizado con éxito del número de ticket ${data.id}\n`,
          method: `${environment.kushkiUrl}/card/v1/admin/chargeback/${data.id}`,
        };
    }
  },
  DOWNLOAD_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/admin/download`,
  DOWNLOAD_FILE_LIST: `${environment.kushkiUrl}/analytics/v1/admin/admin/downloadFileByIdList`,
  DOWNLOAD_MERCHANT_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/merchant/download`,
  DOWNLOAD_TRANSACTIONS: `${environment.envName}/usrv-analytics/file/transaction/`,
  MERCHANT_INFO: `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
  MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  MERCHANT_NODE_SEARCH: `${environment.kushkiUrl}/billing-core-node/v1/childrenMerchants`,
  MERCHANTS_ANALYTICS: `${environment.kushkiUrl}/billing-core-node/v1/merchants`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  REFUND_CONCILIATION_CONFIGURATION: `${environment.kushkiUrl}/refund-conciliation/v1/processor/configuration`,
  RETRIEVE_CHILDREN: `${environment.kushkiUrl}/hierarchy/v1/node/retrieveChildren`,
  SEARCH_MERCHANT_ANALYTYCS: `${environment.kushkiUrl}/analytics/v1/admin/$param/search`,
  USER_PARAMS: `${environment.envName}/authorizer/v1/userParams`,
  VALIDATE_RECEIVABLE: `${environment.kushkiUrl}/dispersions/v1/dispersion/validateReceivable/`,
  WEBHOOK_RECEIPT: `${environment.kushkiUrl}/webhook/v1/receipt`,
};
