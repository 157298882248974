import React from "react";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import {
  createStyles,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { get, isEmpty, isEqual } from "lodash";
import inconsistenceIcon from "../../assets/images/icon-inconsistence.svg";
import inconsistencyRejectedIcon from "../../assets/images/icon-Rechazada.svg";
import modifyIcon from "../../assets/images/icon-information.svg";
import modifyDataActionIcon from "../../assets/images/icon-modify-data.svg";
import { makeStyles } from "@material-ui/core/styles";
import { format, utcToZonedTime } from "date-fns-tz";
import NumberFormat from "react-number-format";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import {
  HierarchyType,
  RetentionCatalog,
  RetentionType,
} from "../../shared/infrastructure/constants/RetentionConstants";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { UtcEnum } from "../../shared/infrastructure/utc-enum";
import { IKshTab, KshTab } from "../Common/KshTab/KshTab";
import {
  ChipStatusEnum,
  StatusEnum,
  TextChipStatusEnum,
} from "../../shared/infrastructure/StatusEnum";
import { getRowBackTextStatus } from "../Common/DataTableCustom/DataTableCustom";
import { ModalDialogInconsistences } from "../ModalDialogInconsistences/ModalDialogInconsistences";
import { TransactionFieldsEnum } from "../../shared/infrastructure/TransactionFieldsEnum";
import TimeLineTabComponent from "../TabComponent/TimeLineTabComponent/TimeLineTabComponent";
import { KindRetentionEnum } from "../../shared/infrastructure/KindRetentionEnum";
import { PdfViewer } from "../Common/PdfViewer/PdfViewer";
import { IStepRetentionTimeLine } from "../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { IDefault } from "../../shared/infrastructure/interfaces/IDefault";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";

export interface InfoTrxProps {
  trxInfo: InvoiceRecord | undefined;
  isHistoric?: boolean;
  statusFlow?: StatusFlowEnum;
  country?: string;
  hasFooter?: boolean;
  contentFooter?: string;
  handleModalModifyInconsistence?: (value: boolean) => void;
  openModifyInconsistence?: boolean;
  downloadFileState?: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
  pdfViewer?: {
    fileName: string;
    openModal: boolean;
    base64File: string;
    handleCloseModal: () => void;
    handleOpenModal: () => void;
    handlePdfViewer: (
      transactionId: string,
      kindRentenion: KindRetentionEnum
    ) => void;
  };
  handleOpenDialogDetail?: (data: IStepRetentionTimeLine) => void;
  handleCloseDialogDetail?: () => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
      marginTop: "30px",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    divider: {
      width: "100%",
      marginTop: 5,
      marginBottom: 3,
    },
    linearProgress: {
      marginTop: 3,
    },
    paddingLeft: {
      paddingLeft: "10px",
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    gridTopZero: {
      paddingTop: "0px !important",
    },
    gridTopBottonZero: {
      paddingBottom: "0px !important",
      paddingTop: "0px !important",
    },
    gridBottomZero: {
      paddingBottom: "0px !important",
    },
    gridCol: {
      paddingBottom: "6px",
    },
    margin: {
      marginTop: 10,
    },
    tabStyle: {
      textTransform: "none",
    },
    iconContainer: {
      margin: "0",
      padding: "0",
      height: "19px",
    },
  })
);
export const retentionText = (
  retentionType: RetentionType,
  value: string
): string => {
  if (value === "-") return value;
  return RetentionCatalog[retentionType][value];
};
const formatDate = (
  trxDate: any,
  formatDate: string,
  country?: string
): string => {
  const utc: string =
    country === CountryEnum.colombia ? UtcEnum.utc_05 : "Etc/UTC";

  return format(utcToZonedTime(trxDate, utc), formatDate);
};

const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

export const tooltipText = (
  text: string,
  status: StatusEnum
): [string, string] => {
  switch (text) {
    case ChipStatusEnum.MODIFY:
      return [TextChipStatusEnum.MODIFY, modifyIcon];
      break;
    case ChipStatusEnum.INCONSISTENCY:
      return [
        TextChipStatusEnum.INCONSISTENCY,
        status === StatusEnum.REJECTED
          ? inconsistencyRejectedIcon
          : inconsistenceIcon,
      ];
    default:
      return ["", ""];
  }
};

export const validateItem = (
  trxInfo: InvoiceRecord,
  status: string,
  field: string
): boolean => {
  switch (status) {
    case ChipStatusEnum.MODIFY:
      const modify = get(trxInfo, "modify.initialAmount", {});

      return (
        get(modify, field.replace("Davi", ""), 0) != get(trxInfo, field, 0)
      );

    case ChipStatusEnum.INCONSISTENCY:
      switch (field) {
        case TransactionFieldsEnum.amountRetIvaDavi:
          const amountRetIvaDavi: number = get(
            trxInfo,
            TransactionFieldsEnum.amountRetIvaDavi,
            0
          );
          const amountRetIva: number = get(
            trxInfo,
            TransactionFieldsEnum.amountRetIva,
            0
          );
          if (!isEqual(amountRetIva, amountRetIvaDavi)) return true;
          return false;
          break;
        case TransactionFieldsEnum.amountRetIcaDavi:
          const amountRetIcaDavi: number = get(
            trxInfo,
            TransactionFieldsEnum.amountRetIcaDavi,
            0
          );
          const amountRetIca: number = get(
            trxInfo,
            TransactionFieldsEnum.amountRetIca,
            0
          );

          if (!isEqual(amountRetIca, amountRetIcaDavi)) return true;
          return false;
          break;
        case TransactionFieldsEnum.amountRetSourceDavi:
          const amountRetSourceDavi: number = get(
            trxInfo,
            TransactionFieldsEnum.amountRetSourceDavi,
            0
          );
          const amountRetSource: number = get(
            trxInfo,
            TransactionFieldsEnum.amountRetSource,
            0
          );

          if (!isEqual(amountRetSource, amountRetSourceDavi)) return true;
          return false;
          break;
        case TransactionFieldsEnum.baseAmountIvaDavi:
          const baseAmountIvaDavi: number = get(
            trxInfo,
            TransactionFieldsEnum.baseAmountIvaDavi,
            0
          );
          const baseAmountIva: number = get(
            trxInfo,
            TransactionFieldsEnum.baseAmountIva,
            0
          );

          return !isEqual(baseAmountIvaDavi, baseAmountIva);

        case TransactionFieldsEnum.baseAmountIcaDavi:
          const baseAmountIcaDavi: number = get(
            trxInfo,
            TransactionFieldsEnum.baseAmountIcaDavi,
            0
          );
          const baseAmountIca: number = get(
            trxInfo,
            TransactionFieldsEnum.baseAmountIca,
            0
          );

          return !isEqual(baseAmountIcaDavi, baseAmountIca);

        default:
          return false;
      }
      return false;
      break;
    default:
      return false;
  }
};

export const renderMerchantInfoPaper = (props: InfoTrxProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Nombre comercial:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                <Typography variant="body2">
                  {get(props, "trxInfo.merchantName", "-")}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Ciclo:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {`${translate(get(props.trxInfo, "cycle"))}`}
              </Typography>
              <Typography variant="body2">
                {`${formatDate(
                  get(props.trxInfo, "startDate"),
                  "dd/MM/yyyy",
                  props.country
                )} - ${formatDate(
                  get(props.trxInfo, "endDate"),
                  "dd/MM/yyyy",
                  props.country
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Municipio:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                <Typography variant="body2">
                  {retentionText(
                    RetentionType.city,
                    get(props, "trxInfo.cityId", "-")
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Modelo:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                <Typography variant="body2">
                  {retentionText(
                    RetentionType.processorType,
                    get(props, "trxInfo.processorType", "-")
                  )}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const renderRetentionHierarchy = (props: InfoTrxProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="body2" color="textSecondary">
                  Owner ID:
                </Typography>
                <Typography variant="body2">
                  {get(
                    props.trxInfo,
                    getDynamoElasticRef(
                      DynamoReferenceEnum.OWNER_ID,
                      !!props.isHistoric
                    ),
                    "-"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Owner Name:
                </Typography>
                <Typography variant="body2">
                  {get(
                    props.trxInfo,
                    getDynamoElasticRef(
                      DynamoReferenceEnum.OWNER_NAME,
                      !!props.isHistoric
                    ),
                    "-"
                  )}
                </Typography>
              </Grid>
              {get(
                props.trxInfo,
                getDynamoElasticRef(
                  DynamoReferenceEnum.IS_OCB,
                  !!props.isHistoric
                ),
                false
              ) && (
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">
                    Jerarquia:
                  </Typography>{" "}
                  <Typography variant="body2">
                    {get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.CUSTOMER_ID,
                        !!props.isHistoric
                      ),
                      ""
                    ) ===
                    get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.MERCHANT_ID,
                        !!props.isHistoric
                      ),
                      ""
                    )
                      ? HierarchyType.centralized
                      : HierarchyType.decentralized}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={5}>
                <Typography variant="body2" color="textSecondary">
                  Customer ID:
                </Typography>
                <Typography variant="body2">
                  <Typography variant="body2">
                    {get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.CUSTOMER_ID_HIERARCHY,
                        !!props.isHistoric
                      ),
                      "-"
                    )}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" color="textSecondary">
                  Customer Name:
                </Typography>
                <Typography variant="body2">
                  {get(
                    props.trxInfo,
                    getDynamoElasticRef(
                      DynamoReferenceEnum.CUSTOMER_NAME,
                      !!props.isHistoric
                    ),
                    "-"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export const renderRetentionInfoPaper = (
  props: InfoTrxProps,
  isInconsistenceModify: boolean,
  isdetalleModify?: boolean
) => {
  const classes = useStyles();
  const textTransactionStatus: string = getRowBackTextStatus(
    get(props, "trxInfo", {})
  );

  const tooltipData: [string, string] = tooltipText(
    textTransactionStatus,
    get(props, "trxInfo.status", "")
  );

  const renderIconInformation = (props: InfoTrxProps, status: string) => {
    return (
      <Grid>
        {validateItem(
          get(props, "trxInfo", {}),
          textTransactionStatus,
          status
        ) && (
          <Tooltip title={tooltipData[0]} placement="top">
            <IconButton
              color="primary"
              component="span"
              size="small"
              edge={"end"}
            >
              <img src={tooltipData[1]} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    );
  };
  const renderIconModify = (props: InfoTrxProps, status: string) => {
    return (
      <Grid>
        {textTransactionStatus === ChipStatusEnum.INCONSISTENCY &&
          !isInconsistenceModify &&
          validateItem(
            get(props, "trxInfo", {}),
            textTransactionStatus,
            status
          ) && (
            <IconButton
              color="primary"
              component="span"
              size="small"
              edge={"end"}
              onClick={() => props.handleModalModifyInconsistence!(true)}
            >
              <img src={modifyDataActionIcon} />
            </IconButton>
          )}
      </Grid>
    );
  };
  const oldImage: InvoiceRecord[] = get(props.trxInfo, "oldTransactions", []);
  const findOldImage: InvoiceRecord = oldImage[oldImage.length - 1];
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {!isdetalleModify ? (
          <Grid
            container
            spacing={2}
            color="secondary"
            className={classes.grid}
          >
            <Grid container item xs={12}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Tipo de retención</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Cálculo Davivienda</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Cálculo Kushki</Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container item xs={12} className={classes.gridBottomZero}>
              <Grid item xs={1}>
                <Grid>
                  {renderIconInformation(
                    props,
                    TransactionFieldsEnum.amountRetIvaDavi
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Retención IVA</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(
                      props.trxInfo,
                      TransactionFieldsEnum.amountRetIvaDavi,
                      0
                    ),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {formatNumber(
                    get(props.trxInfo, "amountRetIva", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {renderIconModify(
                  props,
                  TransactionFieldsEnum.amountRetIvaDavi
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.gridTopZero}>
              <Grid item xs={1}>
                <Grid>
                  {renderIconInformation(
                    props,
                    TransactionFieldsEnum.baseAmountIvaDavi
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Base gravable
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "baseAmountIvaDavi", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "baseAmountIva", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {renderIconModify(
                  props,
                  TransactionFieldsEnum.baseAmountIvaDavi
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.gridBottomZero}>
              <Grid item xs={1}>
                {renderIconInformation(props, "amountRetIcaDavi")}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Retención ICA</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "amountRetIcaDavi", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {formatNumber(
                    get(props.trxInfo, "amountRetIca", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {renderIconModify(props, "amountRetIcaDavi")}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.gridTopBottonZero}>
              <Grid item xs={1}>
                <Grid>
                  {renderIconInformation(props, "amountRetSourceDavi")}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Retención Fuente</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "amountRetSourceDavi", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {formatNumber(
                    get(props.trxInfo, "amountRetSource", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {renderIconModify(props, "amountRetSourceDavi")}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.gridTopZero}>
              <Grid item xs={1}>
                <Grid>
                  {renderIconInformation(
                    props,
                    TransactionFieldsEnum.baseAmountIcaDavi
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  *Base gravable
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "baseAmountSourceDavi", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "baseAmountSource", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {renderIconModify(props, "baseAmountIcaDavi")}
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item container xs={12}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Retención Total</Typography>
              </Grid>{" "}
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "retTotalAmountDavi", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {formatNumber(
                    get(props.trxInfo, "retTotalAmount", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
            </Grid>
            {props.hasFooter && (
              <Grid item xs={12}>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {props.contentFooter}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <ModalDialogInconsistences
              trxInfo={props.trxInfo}
              open={props.openModifyInconsistence}
              handleModalDialogDetail={() =>
                props.handleModalModifyInconsistence!(false)
              }
              contentFooter={props.contentFooter}
            />
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            color="secondary"
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body2"> </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">Montos Iniciales</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">Montos Modificados</Typography>
                </Grid>
                <Divider className={classes.divider} />
                <Grid item xs={4}>
                  <Typography variant="body2">Retención IVA</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Base gravable
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "amountRetIvaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "baseAmountIvaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {formatNumber(
                      get(findOldImage, "amountRetIvaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(findOldImage, "baseAmountIvaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">Retención ICA</Typography>
                  <Typography variant="body2">Retención Fuente</Typography>
                  <Typography variant="body2" color="textSecondary">
                    *Base gravable
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "amountRetIcaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "amountRetSourceDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "baseAmountSourceDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    {formatNumber(
                      get(findOldImage, "amountRetIcaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2">
                    {formatNumber(
                      get(findOldImage, "amountRetSourceDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(findOldImage, "baseAmountSourceDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <ModalDialogInconsistences
              trxInfo={props.trxInfo}
              open={props.openModifyInconsistence}
              handleModalDialogDetail={() =>
                props.handleModalModifyInconsistence!(false)
              }
              contentFooter={props.contentFooter}
            />
          </Grid>
        )}
      </Paper>
      {renderRetentionHierarchy(props)}
    </React.Fragment>
  );
};

export const trxFields = [
  TransactionFieldsEnum.amountRetIva,
  TransactionFieldsEnum.amountRetIvaDavi,
  TransactionFieldsEnum.baseAmountIva,
  TransactionFieldsEnum.baseAmountIvaDavi,
  TransactionFieldsEnum.amountRetIca,
  TransactionFieldsEnum.amountRetIcaDavi,
  TransactionFieldsEnum.baseAmountIca,
  TransactionFieldsEnum.baseAmountIcaDavi,
  TransactionFieldsEnum.amountRetSource,
  TransactionFieldsEnum.amountRetSourceDavi,
  TransactionFieldsEnum.baseAmountSource,
  TransactionFieldsEnum.baseAmountSourceDavi,
];

export const getTrxFields = (fila: IDefault) => {
  const result: IDefault = {};

  for (const field of trxFields) {
    if (!(field in fila)) continue;
    result[field] = fila[field];
  }

  return result;
};

export const getTrxTextStatus = (fila: IDefault) => {
  const {
    baseAmountSourceDavi,
    baseAmountSource,
    amountRetSourceDavi,
    baseAmountIcaDavi,
    baseAmountIca,
    amountRetIca,
    amountRetIcaDavi,
    amountRetSource,
    baseAmountIvaDavi,
    amountRetIvaDavi,
    amountRetIva,
    baseAmountIva,
  } = getTrxFields(fila);
  const response = {
    ...getTrxModifiedFields(get(fila, "modify.modifyAmount", {})),
    inconsistencyAmountRetIva: !isEqual(amountRetIva, amountRetIvaDavi),
    inconsistencyBaseAmountIva: !isEqual(baseAmountIva, baseAmountIvaDavi),
    inconsistencyAmountRetIca: !isEqual(amountRetIca, amountRetIcaDavi),
    inconsistencyBaseAmountIca: !isEqual(baseAmountIca, baseAmountIcaDavi),
    inconsistencyAmountRetSource: !isEqual(
      amountRetSource,
      amountRetSourceDavi
    ),
    inconsistencyBaseAmountSource: !isEqual(
      baseAmountSource,
      baseAmountSourceDavi
    ),
    chipStatus: ChipStatusEnum.NONE,
  };
  const isInconsistency = Object.keys(response).some((key: string) => {
    if (!key.match(/^inconsistency/)) return false;
    return response[key];
  });
  const isModify = Object.keys(response).some((key: string) => {
    if (!key.match(/^modified/)) return false;
    return response[key];
  });
  response.chipStatus = isModify
    ? ChipStatusEnum.MODIFY
    : isInconsistency
    ? ChipStatusEnum.INCONSISTENCY
    : ChipStatusEnum.NONE;
  return response;
};

export const getTrxModifiedFields = (fila: IDefault) => {
  const {
    baseAmountSource,
    baseAmountIca,
    amountRetIca,
    amountRetSource,
    amountRetIva,
    baseAmountIva,
  } = getTrxFields(fila);
  return {
    modifiedAmountRetIva: !!amountRetIva,
    modifiedBaseAmountIva: !!baseAmountIva,
    modifiedAmountRetSource: !!amountRetSource,
    modifiedAmountRetIca: !!amountRetIca,
    modifiedBaseAmountIca: !!baseAmountIca || !!baseAmountSource,
    modifiedBaseAmountSource: !!baseAmountSource || !!baseAmountIca,
  };
};

export const renderRetentionInfoPaperValidatorPreProcessed = (
  props: InfoTrxProps
) => {
  const classes = useStyles();
  const {
    inconsistencyAmountRetIva,
    inconsistencyAmountRetIca,
    inconsistencyAmountRetSource,
    inconsistencyBaseAmountIva,
    inconsistencyBaseAmountIca,
    modifiedAmountRetIva,
    modifiedAmountRetIca,
    modifiedAmountRetSource,
    modifiedBaseAmountIva,
    modifiedBaseAmountIca,
    chipStatus,
  } = getTrxTextStatus(get(props, "trxInfo", {}));
  const tooltipData: [string, string] = tooltipText(
    chipStatus,
    get(props, "status", "")
  );
  const renderIconInformation = () => {
    return (
      <Grid>
        <Tooltip title={tooltipData[0]} placement="top">
          <IconButton
            color="primary"
            component="span"
            size="small"
            edge={"end"}
          >
            <img src={tooltipData[1]} />
          </IconButton>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {props.pdfViewer && (
          <PdfViewer
            fileName={props.pdfViewer!.fileName}
            base64File={props.pdfViewer!.base64File}
            handleCloseModal={props.pdfViewer!.handleCloseModal}
            openModal={props.pdfViewer!.openModal}
          />
        )}
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {chipStatus !== ChipStatusEnum.NONE && <Grid item xs={1}></Grid>}
              <Grid item xs={chipStatus === ChipStatusEnum.NONE ? 4 : 3}>
                <Typography variant="body2">Tipo de retención</Typography>
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: "13px" }}>
                <Typography variant="body2">Cálculo Davivienda</Typography>
              </Grid>
              <Grid item xs={5} style={{ paddingLeft: "0px" }}>
                <Typography variant="body2">Cálculo Kushki</Typography>
              </Grid>
              <Divider className={classes.divider} />

              <Grid item container xs={12}>
                {chipStatus !== ChipStatusEnum.NONE && (
                  <Grid item xs={1}>
                    <div className={classes.iconContainer}>
                      {chipStatus === ChipStatusEnum.MODIFY &&
                        modifiedAmountRetIva &&
                        renderIconInformation()}
                      {chipStatus === ChipStatusEnum.INCONSISTENCY &&
                        inconsistencyAmountRetIva &&
                        renderIconInformation()}
                    </div>
                    <div className={classes.iconContainer}>
                      {chipStatus === ChipStatusEnum.MODIFY &&
                        modifiedBaseAmountIva &&
                        renderIconInformation()}
                      {chipStatus === ChipStatusEnum.INCONSISTENCY &&
                        inconsistencyBaseAmountIva &&
                        renderIconInformation()}
                    </div>
                  </Grid>
                )}
                <Grid item xs={chipStatus === ChipStatusEnum.NONE ? 4 : 3}>
                  <Typography variant="body2">Retención IVA</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Base gravable
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.paddingLeft}>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "amountRetIvaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "baseAmountIvaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">
                    {formatNumber(
                      get(props.trxInfo, "amountRetIva", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "baseAmountIva", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                {chipStatus !== ChipStatusEnum.NONE && (
                  <Grid item xs={1}>
                    <div className={classes.iconContainer}>
                      {chipStatus === ChipStatusEnum.MODIFY &&
                        modifiedAmountRetIca &&
                        renderIconInformation()}
                      {chipStatus === ChipStatusEnum.INCONSISTENCY &&
                        inconsistencyAmountRetIca &&
                        renderIconInformation()}
                    </div>
                    <div
                      className={classes.iconContainer}
                      style={{ marginTop: "8px" }}
                    >
                      {chipStatus === ChipStatusEnum.MODIFY &&
                        modifiedAmountRetSource &&
                        renderIconInformation()}
                      {chipStatus === ChipStatusEnum.INCONSISTENCY &&
                        inconsistencyAmountRetSource &&
                        renderIconInformation()}
                    </div>
                    <div
                      className={classes.iconContainer}
                      style={{ marginTop: "8px" }}
                    >
                      {chipStatus === ChipStatusEnum.MODIFY &&
                        modifiedBaseAmountIca &&
                        renderIconInformation()}
                      {chipStatus === ChipStatusEnum.INCONSISTENCY &&
                        inconsistencyBaseAmountIca &&
                        renderIconInformation()}
                    </div>
                  </Grid>
                )}
                <Grid item xs={chipStatus === ChipStatusEnum.NONE ? 4 : 3}>
                  <Typography variant="body2" className={classes.gridCol}>
                    Retención ICA
                  </Typography>
                  <Typography variant="body2">Retención Fuente</Typography>
                  <Typography variant="body2" color="textSecondary">
                    *Base gravable
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.paddingLeft}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.gridCol}
                  >
                    {formatNumber(
                      get(props.trxInfo, "amountRetIcaDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "amountRetSourceDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "baseAmountSourceDavi", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={chipStatus === ChipStatusEnum.NONE ? 5 : 4}>
                  <Typography variant="body2" className={classes.gridCol}>
                    {formatNumber(
                      get(props.trxInfo, "amountRetIca", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2">
                    {formatNumber(
                      get(props.trxInfo, "amountRetSource", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatNumber(
                      get(props.trxInfo, "baseAmountSource", 0),
                      get(props.trxInfo, "currency")
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">Retención Total</Typography>
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: "13px" }}>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(
                    get(props.trxInfo, "retTotalAmountDavi", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: "0px" }}>
                <Typography variant="body2">
                  {formatNumber(
                    get(props.trxInfo, "retTotalAmount", 0),
                    get(props.trxInfo, "currency")
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          {props.hasFooter && (
            <Grid item xs={12}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {props.contentFooter}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
      {renderRetentionHierarchy(props)}
    </React.Fragment>
  );
};

export const ModalBodyRetentionColombia: React.FC<InfoTrxProps> = (
  props: InfoTrxProps
) => {
  const dataTabs: {
    title: string;
    content: JSX.Element;
  }[] = [];
  const isOldTransactions: boolean = !isEmpty(
    get(props, "trxInfo.oldTransactions", [])
  );
  const isColombia: boolean =
    CountryEnum.colombia === (props.country as CountryEnum);

  if (
    isColombia &&
    (props.statusFlow === StatusFlowEnum.VALIDATOR || props.isHistoric) &&
    (get(props, "trxInfo.status", "") === StatusEnum.PRE_PROCESSED ||
      get(props, "trxInfo.status", "") === StatusEnum.COMPLETED)
  ) {
    dataTabs.push({
      title: "Información",
      content: (
        <React.Fragment>
          {renderMerchantInfoPaper(props)}
          {renderRetentionInfoPaperValidatorPreProcessed(props)}
        </React.Fragment>
      ),
    });
    if (isOldTransactions)
      dataTabs.push({
        title: "Línea de tiempo",
        content: (
          <TimeLineTabComponent
            isRetention={true}
            isRetColombia={true}
            {...props}
          />
        ),
      });
  } else {
    dataTabs.push({
      title: "Información",
      content: (
        <React.Fragment>
          {renderMerchantInfoPaper(props)}
          {renderRetentionInfoPaper(props, false)}
        </React.Fragment>
      ),
    });

    if (
      (!isEmpty(get(props.trxInfo, "modify", {})) ||
        !isEmpty(get(props.trxInfo, "omit", {})) ||
        !isEmpty(get(props.trxInfo, "reject", {}))) &&
      isOldTransactions
    ) {
      dataTabs.push({
        title: "Línea de tiempo",
        content: (
          <TimeLineTabComponent
            {...props}
            isRetention={isColombia}
            isRetColombia={isColombia}
          />
        ),
      });
    }
  }

  const tabs: IKshTab = {
    tabs: [...dataTabs],
  };

  return <KshTab {...tabs} />;
};

export default ModalBodyRetentionColombia;
