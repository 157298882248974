import React from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { TransactionBody } from "./TransactionBody/TransactionBody";
import { TransactionHeader } from "./TransactionHeader/TransactionHeader";
import { TransactionOption } from "./TransactionOption/TransactionOption";
import { TransactionInfo } from "../../../types/transactions_data";

export interface IPayoutsTransactionTableProps {
  transaction: TransactionInfo;
  openModal: boolean;
  handleCloseModal(): void;
  handlerDownloadReceipt: (payload: string) => void;
}

export const TransactionDetail: React.FC<IPayoutsTransactionTableProps> = (
  props: IPayoutsTransactionTableProps
) => {
  const { transaction } = props;

  return (
    <React.Fragment>
      <ModalOverlay
        header={
          <TransactionHeader
            status={transaction.transaction_status}
            ticketNumber={transaction?.ticket_number}
            created={transaction.created!}
          />
        }
        footer={
          <TransactionOption
            handlerDownloadReceipt={props.handlerDownloadReceipt}
            transactionReference={transaction.transaction_reference}
            status={transaction.transaction_status}
            paymentMethod={transaction.payment_method}
          />
        }
        body={<TransactionBody transaction={transaction} />}
        onCloseModal={props.handleCloseModal}
        openModal={props.openModal}
        overlay={false}
        backdrop={false}
        transition={"left"}
      />
    </React.Fragment>
  );
};
