import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import mastercardLogo from "../../assets/images/logos/brands/Master.svg";
import visaLogo from "../../assets/images/logos/brands/Visa.svg";
import amex from "../../assets/images/logos/brands/Amex.svg";
import { VariantSection } from "../VariantSection/VariantSection";
import dinersLogo from "../../assets/images/logos/brands/Diners.svg";
import { StyledToggleButtonGroup } from "../commons/StyledToggleButtonGroup/StyledToggleButtonGroup";
import { ToggleButton } from "@material-ui/lab";
import { useEcuadorSection } from "./useEcuadorSection/useEcuadorSection";
import { Plus } from "react-feather";
import { VariantDeferredOption } from "../../../types/update_deferred_merchant_request";
import { StyledToggleButtonGroupGrace } from "../commons/StyledToggleButtonGroup/StyledToggleButtonGroupGrace";
import { useStateApp } from "../../shared/state/useStateApp";
import { BankModal } from "../commons/BankModal/BankModal";
import { ConfigListBank } from "../ConfigListBank/ConfigListBank";
import ConfirmationModal from "../commons/ConfirmationModal/ConfirmationModal";

const useStyles = makeStyles(() => ({
  bankListMobile: {
    background: "#F7FAFC",
    color: "#293036",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "100%",
    margin: "6px",
    overflow: "hidden",
    padding: "6px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
  cancelButton: {
    background: "#F7FAFC",
    border: "1px solid #023365",
    color: "#023365",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "10px",
    paddingBottom: "15px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "15px",
  },
  cardH2: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
  },
  cardH4: {
    color: "#293036",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "26px",
  },
  cardLogos: {
    height: "32px",
    paddingRight: "8px",
  },
  divider: {
    marginTop: "30px",
  },
  icon: {
    height: 17,
    width: 17,
  },
  label: {
    color: "#6D7781",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.08px",
  },
  labelH2: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "16px",
    paddingTop: "20px",
  },
  link: {
    color: "#023365",
    display: "flex",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  mainContent: {
    color: "#293036",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "28.2px",
    paddingTop: "24px",
  },
  padding: {
    paddingBottom: "15px",
  },
  toggleButtonGroup: {
    flexWrap: "wrap",
  },
  tooltip: {
    backgroundColor: "greenyellow",
    color: "red",
  },
}));

const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    backgroundColor: "#6D7781",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.32px",
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const EcuadorSection = () => {
  const classes = useStyles();
  const { isMobile } = useStateApp();
  const {
    bankListName,
    banks,
    banksModal,
    type,
    months,
    monthsOfGrace,
    merchantMonthsOfGrace,
    merchantMonths,
    handleSetVariant,
    handleDeleteVariant,
    handleBankWebModal,
    bankModalWebIsOpen,
    handleSetMonth,
    variants,
    handleSetMonthOfGrace,
    handleSetBank,
    handleDeleteBank,
    handleSave,
    handleCancel,
    handleCloseConfirmModal,
    handleOpenConfirmModal,
    canAddVariant,
    isOpenConfirmModal,
  } = useEcuadorSection();

  return (
    <>
      <Card>
        <CardContent>
          {!isMobile ? (
            <Toolbar disableGutters>
              <Box display="flex" flexGrow={1}>
                <Box>
                  <Typography className={classes.cardH4}>
                    Configuraciones
                  </Typography>
                  <Typography className={classes.mainContent}>
                    {type}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.label}>
                  Tarjetas a las que aplica la configuración
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    pt={"8px"}
                    width={"100%"}
                  >
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      flexWrap={"wrap"}
                    >
                      <img
                        src={visaLogo}
                        className={classes.cardLogos}
                        alt={"visaLogo"}
                      />
                      <img
                        src={mastercardLogo}
                        className={classes.cardLogos}
                        alt={"mastercardLogo"}
                      />
                      <img
                        src={dinersLogo}
                        className={classes.cardLogos}
                        alt={"dinersLogo"}
                      />
                      <img
                        src={amex}
                        className={classes.cardLogos}
                        alt={"amexLogo"}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          ) : (
            <>
              <Box display="flex" flexGrow={1}>
                <Box>
                  <Typography className={classes.cardH4}>
                    Configuraciones
                  </Typography>
                  <Typography className={classes.mainContent}>
                    {type}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.label}>
                  Tarjetas a las que aplica la configuración
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    pt={"8px"}
                    width={"100%"}
                  >
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      flexWrap={"wrap"}
                    >
                      <img
                        src={visaLogo}
                        className={classes.cardLogos}
                        alt={"visaLogo"}
                      />
                      <img
                        src={mastercardLogo}
                        className={classes.cardLogos}
                        alt={"mastercardLogo"}
                      />
                      <img
                        src={dinersLogo}
                        className={classes.cardLogos}
                        alt={"dinersLogo"}
                      />
                      <img
                        src={amex}
                        className={classes.cardLogos}
                        alt={"amexLogo"}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {!isMobile ? (
            <Box pt={"27px"} className={classes.labelH2}>
              <ConfigListBank
                bankList={bankListName}
                handleBankModal={handleBankWebModal}
                isMobile={false}
              />
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={12} className={classes.labelH2}>
                <ConfigListBank
                  bankList={bankListName}
                  handleBankModal={handleBankWebModal}
                  isMobile
                />
              </Grid>
            </Grid>
          )}

          <Box mb={"24px"}>
            <Grid container className={classes.padding}>
              <Grid item xs={12} md={6} container>
                <Box pl={"47px"} mt={"26px"}>
                  <Typography>
                    <Typography className={classes.cardH2}>Cuotas</Typography>
                  </Typography>
                  <Box pt={"10px"} display={"flex"} flexDirection={"row"}>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      width={"100%"}
                      justifyContent={"flex-start"}
                      flexWrap={"wrap"}
                    >
                      <StyledToggleButtonGroup
                        value={merchantMonths}
                        defaultValue={merchantMonths}
                        className={classes.toggleButtonGroup}
                      >
                        {months.map((month: string) => (
                          <ToggleButton key={month} value={month}>
                            {month}
                          </ToggleButton>
                        ))}
                      </StyledToggleButtonGroup>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {merchantMonthsOfGrace && merchantMonthsOfGrace.length > 0 && (
                <Grid item xs={12} md={6} container>
                  {!isMobile && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                    />
                  )}
                  <Box pl={"47px"} mt={"26px"}>
                    <Typography>
                      <Typography className={classes.cardH2}>
                        Meses de gracias
                      </Typography>
                    </Typography>
                    <Box pt={"10px"} display={"flex"} flexDirection={"row"}>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        width={"100%"}
                        justifyContent={"flex-start"}
                        flexWrap={"wrap"}
                      >
                        <StyledToggleButtonGroupGrace
                          value={merchantMonthsOfGrace}
                          defaultValue={merchantMonthsOfGrace}
                          className={classes.toggleButtonGroup}
                        >
                          {monthsOfGrace.map((month: string) => (
                            <ToggleButton key={month} value={month}>
                              {month}
                            </ToggleButton>
                          ))}
                        </StyledToggleButtonGroupGrace>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          {variants.map((variant: VariantDeferredOption, index: number) => (
            <VariantSection
              key={index}
              handleSetMonth={handleSetMonth}
              handleSetMonthOfGrace={handleSetMonthOfGrace}
              months={months}
              monthsOfGrace={monthsOfGrace}
              merchantMonths={variant.months}
              merchantMonthsOfGrace={variant.monthsOfGrace}
              banks={banks}
              index={index}
              handleDeleteVariant={handleDeleteVariant}
              handleSetBank={handleSetBank}
              selectedBanks={variant.bank!}
              handleDeleteBank={handleDeleteBank}
              isMobile={isMobile}
            />
          ))}
          <Box
            pt={3}
            display={"flex"}
            justifyContent={"flex-end"}
            width={"100%"}
          >
            <Box pl={2}>
              <BootstrapTooltip
                title="Para realizar el cambio de la configuración de algún banco en específico puedes utilizar las variantes, cambia las cuotas y meses de gracia de los bancos que elijas."
                aria-label="add"
              >
                <Button
                  className={classes.link}
                  onClick={handleSetVariant}
                  startIcon={<Plus />}
                  disabled={!canAddVariant}
                >
                  Crear variante
                </Button>
              </BootstrapTooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Box pt={3} display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Box pl={2}>
          <Button
            variant="outlined"
            size="large"
            onClick={handleCancel}
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
        </Box>
        <Box pl={2}>
          <Button
            size="large"
            variant="contained"
            color={"primary"}
            onClick={handleOpenConfirmModal}
          >
            Guardar Diferido
          </Button>
        </Box>
      </Box>
      <BankModal
        open={bankModalWebIsOpen}
        banks={banksModal}
        handleCloseDialog={handleBankWebModal}
      />
      <ConfirmationModal
        isOpen={isOpenConfirmModal}
        handleAccept={handleSave}
        handleCancel={handleCloseConfirmModal}
      />
    </>
  );
};
