import React from "react";
import { Grid, Typography } from "@mui/material";
import { MerchantBasicInfoComponent } from "../MerchantBasicInfoComponent/MerchantBasicInfoComponent";
import { globalInformationComponentStyles } from "./GlobalInformationComponent.styles";
import { GeneralInfoComponent } from "../GeneralInfoComponent/GeneralInfoComponent";
import { RuleConfigurationComponent } from "../RuleConfigurationComponent/RuleConfigurationComponent";
import { ConditionsComponent } from "../ConditionsComponent/ConditionsComponent";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { IGlobalInformationProps } from "../../shared/infrastructure/interfaces/IGlobalInformationProps";

export const GlobalInformationComponent = (props: IGlobalInformationProps) => {
  const classes = globalInformationComponentStyles();

  return (
    <Grid container item>
      <MerchantBasicInfoComponent />
      <Grid item container xs={12} className={classes.sectionThree}>
        <Grid item xs={12}>
          <Typography variant="h2" color="text.primary">
            {LabelEnum.ADD_BUSINESS_RULE}
          </Typography>
          <GeneralInfoComponent />
          <RuleConfigurationComponent
            handleSetSearchTextProcessors={
              props.actions.handleSetSearchTextProcessors
            }
            handleEnterSearchTextProcessors={
              props.actions.handleEnterSearchTextProcessors
            }
          />
          <ConditionsComponent {...props} />
        </Grid>
      </Grid>
    </Grid>
  );
};
