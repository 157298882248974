import { IAccordionProps } from "../../components/Accordion/Accordion.interfaces";
import {
  IconBank,
  IconCircleCheck,
  IconMore,
} from "../../components/Icons/index.export";
import React from "react";

export const AccordionDefault: IAccordionProps = {
  badgeColor: "secondary",
  badgeText: "100%",
  buttonAddSubShareholders: {
    handleClick: () => {},
    hidden: false,
    isDisabled: false,
  },
  divider: true,
  iconButton: <IconMore />,
  iconLeft: <IconBank />,
  iconRight: <IconCircleCheck />,
  isOpen: false,
  listDropdown: [],
  nesting: "",
  nextNesting: "",
  shareholderInfo: {
    country: "",
    date: "",
    id: "",
    isMoral: false,
  },
  text: "Accionista ",
  text2: "Accionista ",
};
