import { makeStyles } from "@mui/styles";

export const infoIconsProcessorAccordionStyles = makeStyles({
  mainBox: {
    marginTop: "5px !important",
  },
  defaultProcessorBox: {
    height: "25px",
    backgroundColor: "#EEF6FF",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "6px !important",
    paddingLeft: "8px !important",
    paddingRight: "8px !important",
  },
  defaultProcessorText: {
    fontSize: "9px !important",
    color: "#023365",
    textAlign: "center",
    fontWeight: 500,
    paddingTop: "6px !important",
  },
  emptyBox: {
    width: "15px",
    height: "5px",
  },
});
