import { ICellTextComponent } from "./CellTitle.interfaces";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";

export const CellTitleItem: FC<ICellTextComponent> = ({
  line1,
  line2,
  align,
}: ICellTextComponent) => {
  return (
    <Box alignItems={align}>
      <Typography sx={TableBodyStyles.title}>{line1}</Typography>
      <Typography sx={TableBodyStyles.subtitle}>{line2!}</Typography>
    </Box>
  );
};
