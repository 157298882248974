import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { FieldValues, useForm } from "react-hook-form";
import { ownerFormName } from "../../../shared/enums/FormEnum";
import {
  IUseClientsHeaderWrapper,
  IUseClientsHeaderWrapperProps,
} from "../ClientsHeaderWrapper.interfaces";

export const useClientsHeaderWrapper = ({
  handleCreateOwner,
}: IUseClientsHeaderWrapperProps): IUseClientsHeaderWrapper => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { control, formState, getValues, reset } = useForm<FieldValues>({
    defaultValues: {
      ownerName: "",
    },
    mode: "all",
  });

  useEffect(() => {
    setIsDisabled(
      !(isEmpty(formState.errors) && !isEmpty(getValues(ownerFormName)))
    );
  }, [getValues(ownerFormName)]);

  const handleModalAction = () => {
    handleCreateOwner(getValues(ownerFormName));
    reset();
  };

  return {
    control,
    handleModalAction,
    isDisabled,
  };
};
