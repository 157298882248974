export enum OptionsEnum {
  CANCEL = "Cancelar",
  CONFIRM = "Confirmar",
}

export const MODAL_DEBIT_DATA_TEXT = {
  DESCRIPTION: (currency: string, value: string) =>
    `Monto a debitar ${value} ${currency}`,
  TITLE: "Confirmar débito",
};

export const MODAL_CREDIT_DATA_TEXT = {
  DESCRIPTION: (currency: string, value: string) =>
    `Monto a acreditar ${value} ${currency}`,
  TITLE: "Confirmar recarga",
};
