import axios from "../shared/axios-util";
import { ActionTypes } from "./actionTypes";
import { AxiosError, AxiosResponse } from "axios";
import { IAppState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  IprocessorData,
  IProcessorResponse,
  ProcessorFetch,
} from "../shared/infrastructure/interfaces/ProcessorFetch";
import { environment } from "../environments/environment";
import { PAYMENT_METHODS_PATHS } from "../shared/infrastructure/constants/PaymentMethodEnum";
import { get, defaultTo, isEmpty } from "lodash";
import { IDefaultProcessorForm } from "../shared/infrastructure/interfaces/IDefaultProcessorForm";
import { UpdateMerchantRulesRequest } from "../../types/update_merchant_rules_request";
import { MerchantProperties, Merchants } from "../../types/merchants";
import { Filter } from "../shared/infrastructure/interfaces/IFilter";
import { IUpdateProcessor } from "../shared/infrastructure/interfaces/IUpdateProcessor";
import { NotificationsTextsEnum } from "../shared/constants/filter_component";
import { GetAppConfigPaymentMethodsResponse } from "../../types/get_app_config_payment_method_response";

const DEFAULT_MESSAGE_ERROR = "No fue posible realizar la petición";

export type IAppAction = { type: string } & IAppState;

export const setDefaultProcessor = (
  payload: IDefaultProcessorForm
): IAppAction => {
  return {
    type: ActionTypes.GET_DEFAULT_PROCESSOR,
    defaultProcessor: payload,
  };
};

export const setDefaultPayoutsTransferProcessor = (
  payload: IDefaultProcessorForm
): IAppAction => {
  return {
    type: ActionTypes.GET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR,
    defaultPayoutsTransferProcessor: payload,
  };
};

export const setNotification = (payload: INotification) => ({
  type: ActionTypes.SET_NOTIFICATION,
  notification: payload,
});

export const setProcessors = (
  payload: ProcessorFetch[] | undefined
): IAppAction => ({
  type: ActionTypes.SET_PROCESSORS,
  processors: payload,
});

export const setTotalProcessors = (payload: number) => ({
  type: ActionTypes.SET_TOTAL_PROCESSORS,
  totalProcessors: payload,
});

export const setLoadingDialog = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING_DIALOG,
  loadingDialog: payload,
});

export const setRefreshProcessor = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_REFRESH_PROCESSOR,
  refreshProcessors: payload,
});

export const setLoading = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING,
  loading: payload,
});
export const setCloseDialog = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_CLOSE_DIALOG,
  dialogClosed: payload,
});

export const setMerchant = (payload: MerchantProperties): IAppAction => ({
  type: ActionTypes.SET_MERCHANT,
  merchant: payload,
});

export const setAllProcessors = (
  payload: ProcessorFetch[] | undefined
): IAppAction => ({
  type: ActionTypes.GET_ALL_PROCESSORS,
  allProcessors: payload,
});
export const setAppConfigPaymentMethods = (
  payload: GetAppConfigPaymentMethodsResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_APPCONFIG_PAYMENT_METHODS,
    appConfigPaymentMethods: payload,
  };
};

export const getProcessors = (
  merchantId: string,
  filterToSend?: Filter
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const body = filterToSend ?? {
    offset: 0,
    limit: 10,
  };
  axios
    .post<IProcessorResponse>(
      `${environment.kushkiUrl}/analytics/v1/admin/${merchantId}/processors`,
      body
    )
    .then((axiosResponse: AxiosResponse<IProcessorResponse>) => {
      const data = axiosResponse.data.data.map((processors: IprocessorData) => {
        return processors._source;
      });

      if (isEmpty(filterToSend)) {
        dispatch(setAllProcessors(data));
      } else {
        const card_processors: number = data.filter(
          (processor: ProcessorFetch) => processor.paymentMethod === "card"
        ).length;
        window.localStorage.setItem(
          "cardProcessors",
          card_processors.toString()
        );
        dispatch(setTotalProcessors(axiosResponse.data.total.value));
        dispatch(setProcessors(data));
      }
    })
    .catch((e: AxiosError) => {
      if (isEmpty(filterToSend)) {
        dispatch(setAllProcessors(undefined));
        dispatch(
          setNotification({
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
      } else {
        dispatch(setProcessors(undefined));
        dispatch(
          setNotification({
            message: NotificationsTextsEnum.COULD_NOT_GET_PROCESSORS,
            open: true,
            type: "error",
          })
        );
      }
    });
};

export const updateProcessor = (
  body: IUpdateProcessor
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const {
    merchantId,
    publicProcessorId,
    paymentMethod,
    data,
    notificationMessage,
    notificationMessageError,
    isDisableProcessorAction,
  } = body;
  const path: string = defaultTo(
    PAYMENT_METHODS_PATHS[paymentMethod],
    ""
  ).replace("{merchantId}", merchantId);

  dispatch(
    isDisableProcessorAction ? setLoadingDialog(true) : setLoading(true) //TODO validar propiedad cuando se activa un procesador deshabilitado
  );
  dispatch(setCloseDialog(false));
  axios
    .patch(`${environment.kushkiUrl}${path}${publicProcessorId}`, { ...data })
    .then(() => {
      setTimeout(
        () => {
          isDisableProcessorAction && dispatch(setRefreshProcessor(true));
          dispatch(setLoadingDialog(false));
          dispatch(setLoading(false));
          dispatch(setCloseDialog(true));
          dispatch(
            setNotification({
              message: notificationMessage,
              open: true,
              type: "success",
            })
          );
        },
        isDisableProcessorAction ? 3000 : 0
      );
    })
    .catch((_e: AxiosError) => {
      dispatch(setLoadingDialog(false));
      dispatch(setLoading(false));
      dispatch(setCloseDialog(true));
      dispatch(
        setNotification({
          message: notificationMessageError,
          open: true,
          type: "error",
        })
      );
    });
};

export const deleteProcessor = (
  processorId: string,
  merchantId: string,
  paymentMethod: string,
  processors: ProcessorFetch[]
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const path: string = defaultTo(
    PAYMENT_METHODS_PATHS[paymentMethod],
    ""
  ).replace("{merchantId}", merchantId);

  dispatch(setLoading(true));
  axios
    .delete(`${environment.kushkiUrl}${path}${processorId}`, {
      data: { merchantId: merchantId },
    })
    .then(() => {
      const index: number = processors.findIndex(
        (processor) => processor.publicProcessorId === processorId
      );

      dispatch(setLoading(false));
      dispatch(setCloseDialog(true));
      processors.splice(index, 1);
      dispatch(
        setNotification({
          message: "Procesador eliminado con éxito",
          open: true,
          type: "success",
        })
      );
    })
    .catch((_e: AxiosError) => {
      dispatch(setLoading(false));
      dispatch(setCloseDialog(true));
      dispatch(
        setNotification({
          message:
            "Lo sentimos, no se pudo eliminar el procesador, inténtalo más tarde",
          open: true,
          type: "error",
        })
      );
    });
};

export const getDefaultProcessor = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${merchantId}`;
    axios
      .get(url)
      .then((axios_response: object) => {
        const response: IDefaultProcessorForm | undefined = get(
          axios_response,
          "data.defaultProcessor"
        );

        if (response) dispatch(setDefaultProcessor(response));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getDefaultPayoutsTransferProcessor = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/${merchantId}`;
    axios
      .get(url)
      .then((axios_response: object) => {
        const response: IDefaultProcessorForm | undefined = get(
          axios_response,
          "data.defaultProcessor"
        );

        if (response) dispatch(setDefaultPayoutsTransferProcessor(response));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const saveDefaultProcessor = (
  payload: UpdateMerchantRulesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${payload.publicMerchantId}`;
    axios
      .patch(url, payload)
      .then(() => {
        dispatch(
          setNotification({
            open: true,
            message: "Operación exitosa",
            type: "success",
          })
        );
        dispatch(getDefaultProcessor(payload.publicMerchantId));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const saveDefaultPayoutsTransferProcessor = (
  payload: UpdateMerchantRulesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/${payload.publicMerchantId}`;

    axios
      .post(url, payload)
      .then(() => {
        dispatch(
          setNotification({
            open: true,
            message: "Operación exitosa",
            type: "success",
          })
        );
        dispatch(getDefaultPayoutsTransferProcessor(payload.publicMerchantId));
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: defaultTo(
              get(error, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getMerchantInfo = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const body = {
        merchant_id: merchantId,
        limit: 1,
        offset: 0,
      };
      const response: AxiosResponse<Merchants> = await axios.post<Merchants>(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        body
      );
      if (get(response, "data.data", []).length > 0)
        dispatch(setMerchant(response.data.data[0]));
    } catch (e) {}
  };
};

export const getAppConfigPaymentMethods = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
  axios
    .get<GetAppConfigPaymentMethodsResponse>(
      `${environment.kushkiUrl}/config-manager/v1/admin/configProfile?appName=HiddenPaymentMethods&env=develop&profileName=generalConfig`
    )
    .then(
      (axiosResponse: AxiosResponse<GetAppConfigPaymentMethodsResponse>) => {
        if (axiosResponse.data.length > 0)
          dispatch(setAppConfigPaymentMethods(axiosResponse.data));
      }
    )
    .catch((e: AxiosError) => {
      dispatch(setAppConfigPaymentMethods([]));
      dispatch(
        setNotification({
          message: defaultTo(
            get(e, "response.data.message"),
            DEFAULT_MESSAGE_ERROR
          ),
          open: true,
          type: "error",
        })
      );
    });
};
