import React from "react";

import {
  Deferred,
  MexicoMerchantDeferredResponse,
} from "../../../types/merchant_deferred_response";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import MonthsList from "../MonthsList/MonthsList";
import BankListChip from "../BankListChip/BankListChip";
import { get } from "lodash";
import { DEFERRED_TYPE_MX } from "../../shared/constants/deferred-type-mx";
import { mexicoDeferredInfoStyles } from "./MexicoDeferredInfo.styles";
import { ICountryDeferredInfo } from "./MexicoDeferredInfo.interfaces";
import { DEFERRED_LABELS } from "../../shared/constants/labels/deferred_info_labels";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MexicoDeferredInfo = (props: ICountryDeferredInfo) => {
  return (
    <Grid container spacing={1} data-testid={"mex-def-info"}>
      {props.deferredInfo.length === 0 && (
        <Typography sx={mexicoDeferredInfoStyles.empty}>
          {DEFERRED_LABELS.MEX_INFO}
        </Typography>
      )}
      {props.deferredInfo.map(
        (deferredItem: MexicoMerchantDeferredResponse, index: number) => (
          <Grid item xs={12} key={index + "expansion-panel"}>
            <Accordion style={{ border: 12 }}>
              <AccordionSummary
                aria-controls="panel2bh-content"
                expandIcon={<ExpandMoreIcon />}
              >
                <Grid
                  container
                  direction={"row"}
                  spacing={3}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Typography sx={mexicoDeferredInfoStyles.expansionTitle}>
                      {DEFERRED_LABELS.CONSORTIUM}
                      {deferredItem.entity}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Box display={"flex"} flexDirection={"column"}>
                  {deferredItem.deferred.map(
                    (deferredInfo: Deferred, index: number) => (
                      <Box
                        key={index + "card-deferred"}
                        sx={mexicoDeferredInfoStyles.boxDetails}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Box pb={3}>
                              <Typography sx={mexicoDeferredInfoStyles.title}>
                                {get(DEFERRED_TYPE_MX, deferredInfo.type!, "")}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={mexicoDeferredInfoStyles.monthBox}>
                              <MonthsList
                                title={DEFERRED_LABELS.MONTHLY_FEES_NUMBER}
                                months={deferredInfo.months}
                              />
                              {deferredInfo.monthsOfGrace.length > 0 && (
                                <MonthsList
                                  title={DEFERRED_LABELS.MONTHS_OF_GRACE}
                                  months={deferredInfo.monthsOfGrace}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              sx={mexicoDeferredInfoStyles.subtitle}
                            >
                              {DEFERRED_LABELS.ISSUING_BANKS}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <BankListChip bankList={deferredInfo.banks} />
                          </Grid>
                          <Grid item xs={12}>
                            <Box pt={2}>
                              <Typography
                                variant="caption"
                                sx={mexicoDeferredInfoStyles.caption}
                              >
                                {DEFERRED_LABELS.MAX_FEE_INFO}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            {index + 1 !== props.deferredInfo.length && <Divider />}
          </Grid>
        )
      )}
    </Grid>
  );
};

export default MexicoDeferredInfo;
