import React from "react";
import { Box } from "@mui/material";
import { ChildrenProps } from "../../Timeline.interfaces";
import { styles } from "./TimeLineItemContent.styles";

const TimeLineItemContent = ({ children }: ChildrenProps) => (
  <Box sx={styles.container}>{children}</Box>
);

export default TimeLineItemContent;
