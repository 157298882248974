import { createStyles, Grid, Paper } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { KindEnum } from "../../../shared/infrastructure/KindEnum";
import {
  subSectionEnum,
  tabEnum,
} from "../../../shared/infrastructure/AdvanceConfigModal";
import { InfoTrxProps } from "../../ModalBodyInfo/ModalBodyTabs";
import { ModalBodyContentDetail } from "../../ModalTabs/ModalBodyContentDetail";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
    copyIcon: {
      marginLeft: 10,
      color: "#CBD5E0",
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
  })
);

const PaperContentDetail = (
  props: InfoTrxProps,
  subSection: subSectionEnum,
  isCharge: boolean = false
) => {
  const classes = useStyles();
  const trx = props["transaction"];

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        color="textSecondary"
        className={classes.grid}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ModalBodyContentDetail
              tabName={tabEnum.infoTab}
              trxProps={props}
              country={get(trx, "country", CountryEnum.peru)}
              subSection={subSection}
              isMinAmount={props.isMinAmount}
              isCharge={isCharge}
              isHistoric={props.isHistoric}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const renderGeneralInfo = (props: InfoTrxProps) => {
  const propsContent = get(props, "trxInfo.transaction", {});
  const contentDetailParam: InfoTrxProps = {
    trxInfo: propsContent,
    ...propsContent,
  };
  switch (get(propsContent, "kind", "")) {
    case KindEnum.VOUCHER:
      return (
        <>
          {PaperContentDetail(
            contentDetailParam,
            subSectionEnum.infoCardVoucherBilling1
          )}
          {PaperContentDetail(
            contentDetailParam,
            subSectionEnum.infoCardChargeBilling4,
            props.isMinAmount
          )}
          {PaperContentDetail(
            contentDetailParam,
            subSectionEnum.infoCardVoucherBilling2
          )}
        </>
      );
    default:
      return <></>;
  }
};

const ModalBodyTrxBillingPeru = (props: InfoTrxProps) => {
  return <ModalBody>{renderGeneralInfo(props)}</ModalBody>;
};

export default ModalBodyTrxBillingPeru;
