import { Dispatch } from "redux";
import axios from "../../../shared/axios-util";
import { API_ENDPOINTS } from "../../../shared/constants/api_endpoints";
import { MessageAlertEnum } from "../../../shared/enums/message_alert_enum";
import { hideLoading, showLoading } from "../../actions/actions";
import {
  setMessageAlert,
  setTypeAlert,
  showAlert,
} from "../../actions/app_actions";

export const resendCertificateEpic = async (
  data: Object,
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    const response = await axios.post(API_ENDPOINTS.RESEND_CERTIFICATE, data);

    dispatch(hideLoading());

    dispatch(setTypeAlert("success"));
    dispatch(setMessageAlert(MessageAlertEnum.RESEND_CERTIFICATE));
    dispatch(showAlert());

    return response;
  } catch (e) {
    dispatch(hideLoading());
    dispatch(setTypeAlert("error"));
    dispatch(setMessageAlert(MessageAlertEnum.ERROR));
    dispatch(showAlert());

    return false;
  }
};
