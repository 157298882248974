import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const NodeSelectionStyles = createNamedStyles({
  headerBox: {
    zIndex: "10",
    background: "linear-gradient(90deg, #E8F1F6 0%, #FBFDFE 3.22%)",
    height: "86px",
    position: "fixed",
    px: 5,
    pt: 1,
    pb: 1,
    top: 0,
    left: 0,
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid #EBF2FF",
  },
  containerTable: {
    marginBottom: "100px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    gap: "16px",
    margin: "32px 0px 24px 0px",
  },
  title: {
    color: "#023365",
    marginTop: "48px",
  },
});
