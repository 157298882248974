import { combineReducers, configureStore } from "@reduxjs/toolkit";
import generalDataReducer from "./reducers/general/general.slice";
import massiveUserReducer from "./reducers/massiveUserForm/massiveUserForm.slice";
import usersReducer from "./reducers/users/users.slice";
import userFormReducer from "./reducers/userForm/userForm.slice";

export const rootReducer = combineReducers({
  generalData: generalDataReducer,
  massiveUserForm: massiveUserReducer,
  userForm: userFormReducer,
  users: usersReducer,
});

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
