import { HistoricalTrx } from "../../../../types/transactions_elastic_response";
import {
  ITransaction,
  ITransactionsState,
} from "../../../store/interfaces/TransactionsState.interfaces";

export const getTransactionsIDs = (transactions: ITransaction[]): string[] =>
  transactions.map((transaction) => transaction.id);

export const getAreAllCurrentTransactionsSelected = (
  state: ITransactionsState
): boolean => {
  const selectedTransactionsIDs = state.selectedTransactions.map(
    (transaction) => transaction.id
  );
  const currentTransactionsIDs = state.transactions.map(
    (transaction) => transaction.id
  );
  const notSelectedTransactions = currentTransactionsIDs.filter(
    (id) => !selectedTransactionsIDs.includes(id)
  );

  return notSelectedTransactions.length === 0;
};

export const mapHistoricalTransaction = (
  historical?: HistoricalTrx[]
): HistoricalTrx[] => {
  return (historical ?? []).map((item) => {
    const newItem = {
      ...item,
    };

    if (item.status) {
      newItem.status = item.status.toUpperCase() as
        | "INITIALIZED"
        | "APPROVAL"
        | "DECLINED";
    }

    return newItem;
  });
};

export const getMonthsLabel = (months: string): string => {
  return months == "1" ? "mes" : "meses";
};
