import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Skeleton,
} from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { ICellInputProps } from "./CellInput.interfaces";
import { cellInputStyles } from "./CellInput.styles";
import useCellInput from "./useCellInput";
import { FLEX_JUSTIFY_BY_ALIGN } from "../../Table/TableBodySimple/constants";

export const CellInputItem: FC<ICellInputProps> = (
  props: PropsWithChildren<ICellInputProps>
) => {
  const { isDisabled, label, id, cellProps } = props;

  const {
    value,
    error,
    handleChange,
    handleBlur,
    isLoading,
    required,
    errorMessage,
  } = useCellInput(props);

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      alignItems={FLEX_JUSTIFY_BY_ALIGN[cellProps?.align || "center"]}
      justifyContent={"center"}
      sx={{
        minWidth: `${(cellProps?.minWidth ?? 1) * 3}em`,
      }}
    >
      {isLoading ? (
        <Skeleton
          data-testid={"skeleton"}
          variant={"rectangular"}
          sx={cellInputStyles.skeletonIcon}
        />
      ) : (
        <FormControl
          error={error}
          required={required}
          disabled={isDisabled}
          id={id}
          fullWidth={true}
          onBlur={handleBlur}
        >
          <InputLabel htmlFor="component-outlined" required={false}>
            {label}
          </InputLabel>
          <OutlinedInput value={value} onChange={handleChange} label={label} />
          {error && (
            <FormHelperText id="component-error-text">
              {errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Box>
  );
};
