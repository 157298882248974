import React from "react";
import { MainSkeleton } from "../../components/Skeletons/MainSkeleton/MainSkeleton";
import { Container } from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { routes } from "../../shared/infrastructure/constants/routes";
import ConfigHeader from "../../components/ConfigHeader/ConfigHeader";
import { AccordionContainer } from "../../components/AccordionContainer/AccordionContainer";
import basicInfo from "../../assets/images/basicInfo.svg";
import billing from "../../assets/images/billing.svg";
import noti from "../../assets/images/notifications.svg";
import users from "../../assets/images/users.svg";
import preferences from "../../assets/images/preferences.svg";
import serviceInfo from "../../assets/images/serviceInfo.svg";
import wallet from "../../assets/images/wallet.svg";
import { useAdminConfiguration } from "./state/useAdminConfiguration";
import TableRates from "../TableRates/TablesRates";
import { InvoiceChargeDispersionData } from "../InvoiceChargeDispersionData/InvoiceChargeDispersionData";
import { CardContainer } from "../../components/CardContainer/CardContainer";
import { InvoiceDiscount } from "../Discount/CardDiscount";
import { TitleSubtitle } from "../TitleSubtitleBox.tsx/TitleSubtitleBox";
import { UserRolesSection } from "../UsersRolesSection/UserRolesSection";
import { ProcessingSection } from "../../components/ProcessingSection/ProcessingSection";
import {
  ConfigIndexConstants,
  DeferredSectionConstants,
} from "../../shared/infrastructure/constants/MerchantConfigConstants";
import processors from "../../assets/images/processors.svg";
import { DeferredSection } from "../../components/DeferredSection/DeferredSection";
import { DeveloperSection } from "../../components/DevelopersSection/DeveloperSection";
import developers from "../../assets/images/developers.svg";
import { DeveloperConstants } from "../../shared/infrastructure/constants/DeveloperConstants";
import ServicesSection from "../ServicesSection/ServicesSection";
import AccountPreferencesSection from "../../components/AccountPreferencesSection/AccountPreferencesSection";
import { PanelContainer } from "../../components/WhitePanel/WhitePanel";
import { RulesTable } from "../RulesTable/RulesTable";
import BasicSection from "../../components/BasicSection/BasicSection";
import { WalletConfiguration } from "../WalletConfiguration/WalletConfiguration";

export const AdminConfiguration: React.FC = () => {
  const {
    merchant,
    cardProcessors,
    transferProcessors,
    cashProcessors,
    transferOutProcessors,
    transferSubscriptionProcessors,
    transferInternationalProcessors,
    cashOutProcessors,
    defaultProcessors,
    isLoadingGetMerchantProcessors,
    showDeferred,
    showButtonCreateDeferred,
    handleRedirectCreateDeferred,
    appConfigPaymentMethods,
  } = useAdminConfiguration();

  return !merchant ? (
    <MainSkeleton count={6} />
  ) : (
    <Container fixed>
      <Breadcrumb
        items={[{ label: "Comercios", url: routes.MERCHANT_LIST }]}
        lastItem={merchant.name || ""}
      />

      <ConfigHeader title="Configuración" />

      <AccordionContainer
        childrenArrayComponent={[<BasicSection key="basicSection" />]}
        icon={basicInfo}
        isBackgroundTransparent
        title={"Datos Básicos"}
      />

      <AccordionContainer
        icon={processors}
        title={ConfigIndexConstants.PROCESSING_TITLE}
        childrenArrayComponent={[
          <ProcessingSection
            key={ConfigIndexConstants.KEY_PROCESSING}
            merchant={merchant}
            isLoadingGetMerchantProcessors={isLoadingGetMerchantProcessors}
            cardProcessors={cardProcessors}
            transferProcessors={transferProcessors}
            cashProcessors={cashProcessors}
            cashOutProcessors={cashOutProcessors}
            transferOutProcessors={transferOutProcessors}
            transferSubscriptionProcessors={transferSubscriptionProcessors}
            transferInternationalProcessors={transferInternationalProcessors}
            defaultProcessors={defaultProcessors}
            appConfigPaymentMethods={appConfigPaymentMethods}
          />,
          <>
            <RulesTable merchantId={merchant?.publicMerchantId} key={3} />
          </>,
          showDeferred ? (
            <CardContainer
              key={DeferredSectionConstants.KEY_CONTAINER}
              headerCardTitle={DeferredSectionConstants.HEADER_CARD_TITLE}
              headerButtonTitle={DeferredSectionConstants.HEADER_BUTTON_TITLE}
              footerCardHrefLocation={"/deferred"}
              footerTitleHrefLocation={DeferredSectionConstants.FOOTER_TITLE}
              childrenArrayCardComponent={[<DeferredSection key={0} />]}
              buttonCreate={showButtonCreateDeferred()}
              headerButtonOnClick={handleRedirectCreateDeferred}
            />
          ) : (
            <div key={DeferredSectionConstants.KEY_CONTAINER} />
          ),
        ]}
      />

      <AccordionContainer
        childrenArrayComponent={[
          <ServicesSection key={"1"} title="Servicios activos" />,
        ]}
        icon={serviceInfo}
        title={"Servicios"}
      />

      <AccordionContainer
        childrenArrayComponent={[
          <InvoiceChargeDispersionData
            key={"6"}
            title={"Facturación, cobro y dispersión"}
          />,
          <TableRates key={2} merchantId={merchant?.publicMerchantId} />,
          <InvoiceDiscount
            key={"discount"}
            merchantId={`${routes.RATES}/${merchant.publicMerchantId}`}
          />,
          <WalletConfiguration
            key={"wallet"}
            merchantCountry={merchant.country}
            merchantId={merchant.publicMerchantId}
            cardProcessors={cardProcessors}
            cashProcessors={cashProcessors}
            transferOutProcessors={transferOutProcessors}
            transferSubscriptionProcessors={transferSubscriptionProcessors}
            transferProcessors={transferProcessors}
            cashOutProcessors={cashOutProcessors}
          />,
        ]}
        icon={billing}
        title={"Facturación, costos y tarifas"}
      />
      <AccordionContainer
        childrenArrayComponent={[
          <UserRolesSection key={"usersRolesSection"} />,
        ]}
        icon={users}
        title={"Usuarios y roles"}
      />

      <AccordionContainer
        icon={developers}
        title={DeveloperConstants.TITLE}
        isBackgroundTransparent={true}
        childrenArrayComponent={[
          <DeveloperSection key={DeveloperConstants.KEY} />,
        ]}
      />

      <AccordionContainer
        icon={preferences}
        title={"Preferencias de cuenta"}
        childrenArrayComponent={[
          <AccountPreferencesSection
            key={"1"}
            title="Personalización de la marca"
          />,
        ]}
      />

      <br />
      <br />

      <PanelContainer
        icon={noti}
        title={"Notificaciones"}
        childrenArrayComponent={[
          <TitleSubtitle
            key={"notification"}
            title={"Notificaciones"}
            subtitle={
              "Configura las notificaciones que quieres que Kushki envíe a tus clientes por cada transacción realizada."
            }
            IndexHref={`${routes.TRANSACTION_NOTIFICATION}/${merchant.publicMerchantId}`}
          />,
        ]}
      />

      <br />

      <PanelContainer
        icon={wallet}
        title={"Billetera"}
        childrenArrayComponent={[
          <TitleSubtitle
            key={"billetera"}
            title={"Movimientos de la billetera"}
            subtitle={
              "Consulta los movimientos de débitos del wallet del comercio."
            }
            IndexHref={`${routes.WALLET_DASHBOARD}${merchant.publicMerchantId}`}
          />,
        ]}
      />
    </Container>
  );
};

export default AdminConfiguration;
