import React from "react";
import { SvgIcon } from "@mui/material";

const EditIcon: React.FC = () => {
  return (
    <SvgIcon
      viewBox="-4 -4 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7279 15.1074H4.0612C3.35395 15.1074 2.67568 14.8265 2.17558 14.3264C1.67548 13.8263 1.39453 13.148 1.39453 12.4408V5.77409C1.39453 5.06684 1.67548 4.38857 2.17558 3.88847C2.67568 3.38837 3.35395 3.10742 4.0612 3.10742H6.0612C6.23801 3.10742 6.40758 3.17766 6.5326 3.30268C6.65763 3.42771 6.72786 3.59728 6.72786 3.77409C6.72786 3.9509 6.65763 4.12047 6.5326 4.24549C6.40758 4.37052 6.23801 4.44076 6.0612 4.44076H4.0612C3.70758 4.44076 3.36844 4.58123 3.11839 4.83128C2.86834 5.08133 2.72786 5.42047 2.72786 5.77409V12.4408C2.72786 12.7944 2.86834 13.1335 3.11839 13.3836C3.36844 13.6336 3.70758 13.7741 4.0612 13.7741H10.7279C11.0815 13.7741 11.4206 13.6336 11.6707 13.3836C11.9207 13.1335 12.0612 12.7944 12.0612 12.4408V11.1074C12.0612 10.9306 12.1314 10.761 12.2565 10.636C12.3815 10.511 12.5511 10.4408 12.7279 10.4408C12.9047 10.4408 13.0742 10.511 13.1993 10.636C13.3243 10.761 13.3945 10.9306 13.3945 11.1074V12.4408C13.3945 13.148 13.1136 13.8263 12.6135 14.3264C12.1134 14.8265 11.4351 15.1074 10.7279 15.1074Z"
        fill="#023365"
      />
      <path
        d="M12.7206 6.3326C12.5438 6.3326 12.3742 6.26237 12.2492 6.13734C12.1242 6.01232 12.054 5.84275 12.054 5.66594C12.0335 5.34201 11.8956 5.03666 11.6661 4.80715C11.4366 4.57763 11.1312 4.43972 10.8073 4.41927C10.6305 4.41927 10.4609 4.34903 10.3359 4.22401C10.2109 4.09898 10.1406 3.92942 10.1406 3.7526C10.1406 3.57579 10.2109 3.40622 10.3359 3.2812C10.4609 3.15618 10.6305 3.08594 10.8073 3.08594C11.2806 3.10288 11.7408 3.24555 12.1406 3.49927C12.4639 3.70258 12.7373 3.97599 12.9406 4.29927C13.1944 4.69913 13.337 5.15934 13.354 5.6326C13.354 5.80942 13.2837 5.97898 13.1587 6.10401C13.0337 6.22903 12.8641 6.29927 12.6873 6.29927L12.7206 6.3326Z"
        fill="#023365"
      />
      <path
        d="M9.2799 9.77316H7.39323C7.21642 9.77316 7.04685 9.70292 6.92182 9.5779C6.7968 9.45287 6.72656 9.2833 6.72656 9.10649V7.21983C6.72693 7.13162 6.74479 7.04437 6.77913 6.96312C6.81346 6.88187 6.86357 6.80824 6.92656 6.74649L10.6999 2.97316C10.8248 2.84899 10.9938 2.7793 11.1699 2.7793C11.346 2.7793 11.515 2.84899 11.6399 2.97316L12.3066 3.63983C12.4071 3.73663 12.4747 3.86255 12.4999 3.99983C12.6514 4.01664 12.7926 4.08488 12.8999 4.19316L13.5666 4.85983C13.6907 4.98473 13.7604 5.1537 13.7604 5.32983C13.7604 5.50595 13.6907 5.67492 13.5666 5.79983L9.79323 9.57316C9.72686 9.64115 9.64672 9.69417 9.55819 9.72866C9.46965 9.76316 9.37477 9.77833 9.2799 9.77316ZM8.0599 8.43983H9.00656L12.1132 5.33316L11.9399 5.15316C11.8316 5.04585 11.7634 4.90468 11.7466 4.75316C11.595 4.73634 11.4539 4.66811 11.3466 4.55983L11.1666 4.38649L8.0599 7.49316V8.43983Z"
        fill="#023365"
      />
      <path
        d="M13.0523 5.9999C12.8765 5.9974 12.7087 5.92551 12.5857 5.7999L11.919 5.13324C11.8107 5.02593 11.7425 4.88475 11.7257 4.73324C11.5741 4.71642 11.433 4.64819 11.3257 4.5399L10.659 3.87324C10.596 3.81149 10.5459 3.73786 10.5115 3.65661C10.4772 3.57536 10.4594 3.48811 10.459 3.3999C10.4615 3.22406 10.5334 3.05632 10.659 2.93324L12.5457 1.05324C12.6706 0.92907 12.8395 0.859375 13.0157 0.859375C13.1918 0.859375 13.3607 0.92907 13.4857 1.05324L15.3657 2.93324C15.4898 3.05814 15.5595 3.22711 15.5595 3.40324C15.5595 3.57936 15.4898 3.74833 15.3657 3.87324L13.5257 5.7999C13.4026 5.92551 13.2348 5.9974 13.059 5.9999H13.0523ZM12.499 3.9999C12.6505 4.01672 12.7917 4.08495 12.899 4.19324L13.0723 4.36657L14.0123 3.42657L13.0523 2.50657L12.1123 3.44657L12.2857 3.6199C12.3973 3.71909 12.4725 3.85291 12.499 3.9999Z"
        fill="#023365"
      />
    </SvgIcon>
  );
};

export default EditIcon;
