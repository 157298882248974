export enum StepperEnum {
  FORM = 0,
  SUMMARY = 1,
  CODE_QR = 2,
}

export enum LabelStepper {
  FORM = "Formulario",
  SUMMARY = "Resumen",
  CODE_QR = "Código QR",
}
