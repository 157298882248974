export enum BusinessRuleNames {
  INSTITUTION = "Institución Financiera",
  BRAND = "Marca",
  DEFERRED = "Diferido",
  TRANSACTION_TYPE = "Tipo de transacción",
  ACCOUNT_TYPE = "Tipo de cuenta",
  DOCUMENT_TYPE = "Tipo de documento",
  COUNTRY = "País",
  AMOUNT = "Monto",
  ENROLL = "Enrolamiento",
  IP = "IP",
  CREDENTIAL = "Credencial",
  BIN = "BIN",
  CARD_TYPE = "Tipo de tarjeta",
  MASKED_CARD = "Tarjeta enmascarada",
  CURRENCY = "Moneda",
}
