import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRight } from "react-feather";

export interface BreadcrumbItem {
  label: string;
  url: string;
}

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  lastItem?: string;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<ChevronRight fontSize="small" />}
        aria-label="breadcrumb"
      >
        {props.items.map((item: BreadcrumbItem) => (
          <Link
            key={item.label}
            style={{
              color: "#677784",
            }}
            component={RouterLink}
            to={item.url}
          >
            {item.label}
          </Link>
        ))}
        {props.lastItem && (
          <Typography
            style={{
              color: "#677784",
            }}
          >
            {props.lastItem}
          </Typography>
        )}
      </Breadcrumbs>
    </React.Fragment>
  );
}
