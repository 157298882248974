import { useEffect, useState } from "react";
import _, { get, isEmpty } from "lodash";
import {
  ALL_TABLE_COLUMNS_DISCOUNT,
  discountTypesEnum,
  PAYMENT_METHOD,
  RATES_TYPE,
  TABLE_ROW,
  TEXT_CELL,
} from "../../../shared/infrastructure/constants/RatesAndDiscountResumeConstants";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { IRowInteractiveProps } from "@kushki/connect-ui";
import axiosInstance from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { useSelector } from "react-redux";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { IPostRatesConfigsResponse } from "../../../../types/post_rates_configs_response";
import { IGetMerchantDiscountResponse } from "../../../../types/get_merchant_discount_response";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  IDiscountList,
  IProcessedResponse,
} from "../../../shared/infrastructure/interfaces/IDiscountList";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { currencyFormat } from "../../../shared/utils/currencyFormat";
import { getSectionInfo } from "../../../shared/utils/node_info";

const useRatesAndDiscountResume = () => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);

  const [showRatesAndDiscountResume, setShowRatesAndDiscountResume] =
    useState<boolean>(false);
  const [discountList, setDiscountList] = useState<IDiscountList[]>();

  const nodeInfoData = useSelector(selectNodeInfo);

  const onClickRow = () => {};

  const formatDateMMM = (date: number): string => {
    const formatted = format(new Date(date), "dd/MMM/yyyy", { locale: es });

    return formatted.split("/").map(_.capitalize).join("/");
  };

  const onRenderValue = (key: string, columnValue: string) => {
    const renderValues = {
      default: () => columnValue,
    };

    return get(renderValues, key, renderValues.default)();
  };

  const getColumns = (object: IProcessedResponse) => {
    return ALL_TABLE_COLUMNS_DISCOUNT.map((column) => {
      const value = onRenderValue(column.key, get(object, column.key, "0"));

      const tableCell = get(TABLE_ROW, column.cellType, TEXT_CELL);

      return tableCell(value) as ITableCellProps;
    });
  };

  const buildResponseRates = (data: any, country: string) => {
    const paymentsCollection = get(data, "paymentMethod", "");
    const paymentMethods = paymentsCollection.map((item: string) => {
      return PAYMENT_METHOD[item];
    });

    return {
      alias: get(data, "alias", ""),
      fixedAmount: currencyFormat(
        get(data, "configuration[0].fixedAmount", 0),
        country,
        get(data, "currency")
      ),
      maximumAmount: currencyFormat(
        get(data, "maximumAmount", 0),
        country,
        get(data, "currency")
      ),
      minimumAmount: currencyFormat(
        get(data, "minimumAmount", 0),
        country,
        get(data, "currency")
      ),
      paymentMethod: paymentMethods.join(","),
      rateType: RATES_TYPE[get(data, "paymentType", "")],
      variableAmount: `${get(data, "configuration[0].variableAmount", 0)} %`,
    };
  };

  const getData = async (
    CN005Value: string,
    CN006Value: string,
    country: string
  ) => {
    try {
      const [ratesConfigsData, merchantDiscountData] = await Promise.all([
        axiosInstance.post<IPostRatesConfigsResponse>(
          API_ROUTES.POST_RATES_CONFIGS,
          {
            publicMerchantId: CN005Value,
          }
        ),
        axiosInstance.get<IGetMerchantDiscountResponse>(
          `${API_ROUTES.GET_MERCHANT_DISCOUNT}/${CN006Value}`
        ),
      ]);

      const processedResponses: IProcessedResponse[] =
        ratesConfigsData?.data.map((item) => {
          return buildResponseRates(item, country);
        });
      const buildRows = (): ITableRowProps[] => {
        let data: ITableRowProps[] = [];

        processedResponses.map((item) => {
          data.push({
            cells: getColumns(item),
            id: "discount",
            rowProps: {
              color: "default",
              onClick: onClickRow,
            } as IRowInteractiveProps,
          });
        });

        return data;
      };

      setRows(buildRows());

      const isEmptyDiscounts: boolean =
        Object.keys(merchantDiscountData?.data).length === 0;

      if (!isEmptyDiscounts) {
        setDiscountList([
          {
            label: discountTypesEnum.PERCENTAGE,
            value: merchantDiscountData?.data?.discount + "%",
          },
          {
            label: discountTypesEnum.START_DATE,
            value: formatDateMMM(merchantDiscountData?.data?.start),
          },
          {
            label: discountTypesEnum.END_DATE,
            value: formatDateMMM(merchantDiscountData?.data?.end),
          },
        ]);
      }

      setShowRatesAndDiscountResume(true);
    } catch (error) {}
  };

  useEffect(() => {
    if (nodeInfoData) {
      if (
        nodeInfoData.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfoData.entityName === EntityNameEnum.BRANCH
      ) {
        const country = nodeInfoData?.generalInfo?.country;

        let configCN005 = getSectionInfo(
          ConfigurationIdEnum.CN005,
          nodeInfoData.configs
        );

        let configCN006 = getSectionInfo(
          ConfigurationIdEnum.CN006,
          nodeInfoData.configs
        );

        if (configCN005 && configCN006) {
          if (!isEmpty(configCN005.value) || !isEmpty(configCN006.value)) {
            getData(configCN005?.value, configCN006?.value, country);
          }
        }
      }
    }
  }, [nodeInfoData]);

  return { discountList, onClickRow, rows, showRatesAndDiscountResume };
};

export default useRatesAndDiscountResume;
