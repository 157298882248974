// @ts-ignore
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  Link,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RulesTable } from "./RulesTable";
import { MerchantProcessorsRules } from "../../../../types/merchant_processors_rules_response";
import { navigateWithMerchantInfoStorage } from "../../../shared/infrastructure/constants/CreateMerchantConstants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 2),
      marginBottom: 15,
    },
    font: {
      fontWeight: "initial",
    },
    link: {
      textDecoration: "underline",
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    bgWhite: {
      background: "white",
    },
    marginTopBottom: {
      marginTop: 5,
      marginBottom: 15,
    },
  })
);

export type IRulesSummarySection = {
  publicMerchantId: string;
  to: string;
  processorRules: MerchantProcessorsRules;
};

export const RulesSummarySection: React.FC<IRulesSummarySection> = (
  props: IRulesSummarySection
) => {
  const addRulesURL: string = `/rules/business/rule/${props.publicMerchantId}`;
  const editRulesURL: string = `/rules/business/${props.publicMerchantId}`;
  const redirectAddRulesPage = () => {
    if (!props.publicMerchantId) return;
    navigateWithMerchantInfoStorage(addRulesURL);
  };
  const classes = useStyles();

  return (
    <Grid container component={Paper} elevation={0} className={classes.paper}>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1} p={1}>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.font}
            >
              Reglas de negocio
            </Typography>
          </Box>
          <Box>
            <Button
              id={"btn-redirect"}
              variant="contained"
              color="primary"
              onClick={redirectAddRulesPage}
            >
              Agregar regla
            </Button>
          </Box>
        </Box>
        <Divider className={classes.marginTopBottom} />

        <RulesTable
          merchantId={props.publicMerchantId}
          rules={props.processorRules}
        />
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => navigateWithMerchantInfoStorage(editRulesURL)}
          color="inherit"
          className={classes.link}
        >
          Edita y revisa todas las reglas de negocio
        </Link>
      </Grid>
    </Grid>
  );
};
