import React from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get, isEqual } from "lodash";
import { SecurityWrapperEnum } from "../../shared/infrastructure/Enums/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface FileUploadActionButtonsComponentProps {
  isLoading?: boolean;
  onSubmit?: () => void;
  handleBack: () => void;
  buttonText?: string;
  onSave?: () => void;
  stateFile?: {
    isLoading: boolean;
    error: boolean;
  };
  editDisable?: boolean;
  onSubmitItem?: () => void;
  addRetentionDirectly?: boolean;
  onSaveItemDirectly?: () => void;
  isLoadingCreateItem?: boolean;
  hasErrorForm?: boolean;
  rolAzure?: SecurityWrapperEnum;
}

const useStyles = makeStyles(() => ({
  back: {
    color: "#677784",
  },
}));

const roles_render_button: SecurityWrapperEnum[] = [
  SecurityWrapperEnum.RETENCIONES_CARGAR_ARCHIVO_IVA_ICA,
  SecurityWrapperEnum.RETENCIONES_AGREGAR_EDITAR_IVA,
  SecurityWrapperEnum.RETENCIONES_CARGAR_ARCHIVO_RENTA,
  SecurityWrapperEnum.RETENCIONES_AGREGAR_EDITAR_RENTA,
];

function evaluateRoles(
  props: FileUploadActionButtonsComponentProps,
  rolAzure: SecurityWrapperEnum
): JSX.Element {
  return (
    <>
      {isEqual(get(props, "rolAzure"), rolAzure) &&
        generateButton(props, rolAzure)}
    </>
  );
}

function generateButton(
  props: FileUploadActionButtonsComponentProps,
  rolAzure: SecurityWrapperEnum
): JSX.Element {
  return (
    <React.Fragment>
      <ComponentSecurityWrapper componentId={rolAzure}>
        {props.onSave ? (
          <Grid item xs={12} md={4}>
            <Button
              id="saveLogicBtn"
              size="medium"
              fullWidth
              variant="contained"
              color="primary"
              onClick={
                props.editDisable
                  ? props.onSubmitItem
                  : props.addRetentionDirectly
                  ? props.onSaveItemDirectly
                  : props.onSave
              }
              disabled={
                props.isLoading ||
                props.isLoadingCreateItem ||
                props.hasErrorForm
              }
              endIcon={
                (get(props.stateFile, "isLoading") ||
                  props.isLoadingCreateItem) && (
                  <CircularProgress color={"inherit"} size={23} />
                )
              }
            >
              {props.buttonText}
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12} md={4}>
            <Button
              id="savebtn"
              size="medium"
              fullWidth
              variant="contained"
              color="primary"
              onClick={props.onSubmit}
              disabled={
                (props.stateFile ? props.stateFile.error : false) ||
                props.isLoading
              }
            >
              {props.buttonText}
            </Button>
          </Grid>
        )}
      </ComponentSecurityWrapper>
    </React.Fragment>
  );
}

export const UploadActionButtonsComponent: React.FC<FileUploadActionButtonsComponentProps> = (
  props: FileUploadActionButtonsComponentProps
) => {
  const style = useStyles();

  return (
    <Grid container justify="flex-end">
      <Grid item xs={12} md={2}>
        <Button
          id="backbtn"
          fullWidth
          onClick={props.handleBack}
          className={style.back}
        >
          Regresar
        </Button>
      </Grid>

      {roles_render_button.map((valueRol: SecurityWrapperEnum) =>
        evaluateRoles(props, valueRol)
      )}
    </Grid>
  );
};
