import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { X as IconClose } from "react-feather";
import {
  Backdrop,
  BackdropProps,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Fade,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: 534,
      padding: "1rem",
      paddingTop: "3rem",
      borderRadius: "15px !important",
      position: "relative",
    },
    media: {
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    src: {
      maxWidth: "64%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    actions: {
      padding: "0.5rem",
      paddingBottom: "1rem",
      display: "flex",
      justifyContent: "center",
    },
    closeBtn: {
      position: "absolute",
      top: "3%",
      right: "0%",
    },
  })
);

export interface RedirectModalProps {
  open: boolean;
  titleText: string;
  subTitleText?: string;
  contentText?: string;
  acceptText?: string;
  cancelText?: string;
  handleClose: () => void;
  handleAccept: () => void;
  backdropProps?: Partial<BackdropProps> | undefined;
  src?: string;
}

export const RedirectModal: React.FC<RedirectModalProps> = (
  props: React.PropsWithChildren<RedirectModalProps>
) => {
  type colorMUI =
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary";
  const classes = useStyles(props);

  const titlesRender = (
    fw: number,
    color: colorMUI,
    variant: Variant,
    title: string,
    id: string
  ) => {
    return (
      <Typography
        id={id}
        style={{
          fontWeight: fw,
        }}
        color={color}
        variant={variant}
      >
        {title}
      </Typography>
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={props.backdropProps}
      disableBackdropClick={true}
    >
      <Fade in={props.open}>
        <Card className={classes.root}>
          <Box className={classes.closeBtn}>
            <Button
              id={"closebtn"}
              variant={"text"}
              onClick={props.handleClose}
            >
              <IconClose />
            </Button>
          </Box>
          <Container>
            <CardMedia className={classes.media}>
              <img src={props.src} />
            </CardMedia>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    {titlesRender(
                      500,
                      "primary",
                      "h4",
                      props.titleText,
                      "titleText"
                    )}
                  </Box>
                </Grid>
                {props.subTitleText && (
                  <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"} mt={3}>
                      {titlesRender(
                        500,
                        "textPrimary",
                        "h5",
                        props.subTitleText!,
                        "subtitleText"
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box display={"flex"} justifyContent={"center"} mt={2}>
                    {props.contentText && (
                      <Typography
                        id={"textDescription"}
                        color={"textPrimary"}
                        variant="body1"
                      >
                        {props.contentText && props.contentText}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                id={"acceptbtn"}
                size="large"
                onClick={props.handleAccept}
              >
                {props.acceptText}
              </Button>
            </CardActions>
          </Container>
        </Card>
      </Fade>
    </Modal>
  );
};

RedirectModal.defaultProps = {
  acceptText: "Continuar",
  cancelText: "Cancelar",
  backdropProps: {
    timeout: 500,
  },
};
