import React, { PropsWithChildren } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { accordionPopUpStyle } from "./AccordionPopUp.style";
import { AccordionPopUpProps } from "../shared/infrastructure/interfaces/AccordionPopUp.interface";

export const AccordionPopUp = (
  props: PropsWithChildren<AccordionPopUpProps>
) => {
  const classes = accordionPopUpStyle();

  return (
    <Accordion
      defaultExpanded={props.defaultExpanded}
      expanded={props.defaultExpanded}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={props.handleExpandedOrCollapsed}
        className={classes.nonSpace}
      >
        <Typography className={classes.filterTitles}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.nonSpace}>
        {props.componentToRender}
      </AccordionDetails>
    </Accordion>
  );
};
