import { IDataSet } from "../../components/InputForms/InputsFormProps.interfaces";
import { LegalRepresentativesFormComponents } from "../../components/LegalRepresentativesForm/LegalRepresentativesForm.interfaces";
import { CountriesEnum } from "../infrastructure/constants/countries-enum";
import { COLOMBIA_CITIES } from "./colombia_cities";
import { GenderEnum } from "./GenderEnum";
import { MEXICO_CITIES } from "./mexico_cities";
import { MexicoDocumentTypeEnum } from "./MexicoDocumentTypeEnum";
import { ProfessionEnum } from "./ProfessionEnum";
import { QuestionAnswersEnum } from "./QuestionAnswersEnum";
import { ChileDocumentTypeEnum } from "./ChileDocumentTypeEnum";
import {
  COLOMBIA_DOCUMENT_TYPE_VALUES,
  ECUADOR_DOCUMENT_TYPE_VALUES,
  PERU_DOCUMENT_TYPE_VALUES,
} from "./DocumentTypesEnum";
import { nationalityCatalog } from "./NationalityCatalog";

const checkLegalRepresentantivesError = (error: any, isDisable: boolean) => {
  return (
    Object.keys(error).length !== 0 &&
    error.legalRepresentatives !== undefined &&
    error.legalRepresentatives.length > 0 &&
    isDisable
  );
};

export enum TaxPayerTypeEnum {
  NORMAL = "n",
  ESPECIAL = "e",
  RISE = "r",
}

const validateTaxPayerTypeFieldEmpty = (
  getValues: any,
  index: number,
  value: any
) => {
  return (
    !(
      getValues(`legalRepresentatives.${index}.taxPayerType`) ===
        TaxPayerTypeEnum.RISE && value.length === 0
    ) || "*Campo requerido"
  );
};

export const LEGAL_REPRESENTATIVE_KEY = "legalRepresentatives";

export const AFFILIATION_NO_APPLY = "not_apply";

export const SOCIAL_REASON_ROOT = "socialReason";
export const SOCIAL_REASON_MERCHANT_INFO_SOCIAL_REASON =
  "merchantLead.merchantInfo.socialReason";

export const GENDER_VALUES: IDataSet[] = [
  {
    text: "Femenino",
    value: GenderEnum.FEMALE,
  },
  { text: "Masculino", value: GenderEnum.MALE },
];

export const PROFESSION_VALUES: IDataSet[] = [
  {
    text: "Sr.",
    value: ProfessionEnum.SR,
  },
  {
    text: "Sra.",
    value: ProfessionEnum.SRA,
  },
  {
    text: "Lic.",
    value: ProfessionEnum.LIC,
  },
  {
    text: "Dr.",
    value: ProfessionEnum.DR,
  },
];

export enum TITLE_ENUM {
  PRINCIPAL = "Principal",
  ALTERNATIVE = "Alterno",
}

export enum LegalRepresentativesComponentType {
  ONLY_LETTERS = "ONLY_LETTERS",
  ONLY_NUMBERS = "ONLY_NUMBERS",
  TEXT = "TEXT",
  SELECT = "SELECT",
  EMAIL = "EMAIL",
  AUTOCOMPLETE = "AUTOCOMPLETE",
  QUESTION = "QUESTION",
  DATE = "DATE",
  BLANK = "BLANK",
  ONLY_DEFAULT_VALUE = "ONLY_DEFAULT_VALUE",
}

export const TAX_PAYER_TYPE_VALUES_EC: IDataSet[] = [
  {
    text: "Normal",
    value: TaxPayerTypeEnum.NORMAL,
  },
  {
    text: "Especial",
    value: TaxPayerTypeEnum.ESPECIAL,
  },
  {
    text: "RISE",
    value: TaxPayerTypeEnum.RISE,
  },
];

export const TAX_EXEMPT_VALUES_EC: IDataSet[] = [
  {
    text: "Si",
    value: "Si",
  },
  {
    text: "No",
    value: "No",
  },
];

export const COLOMBIA_FORM: LegalRepresentativesFormComponents[] = [
  {
    isRequired: true,
    label: "Primer nombre*",
    name: "firstName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo nombre (Opcional)",
    name: "secondName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Primer apellido*",
    name: "surName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo apellido (Opcional)",
    name: "lastName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    items: GENDER_VALUES,
    label: "Genero*",
    name: "gender",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Correo electrónico*",
    name: "email",
    type: LegalRepresentativesComponentType.EMAIL,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de teléfono*",
    name: "phoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de celular*",
    name: "cellphoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    items: COLOMBIA_DOCUMENT_TYPE_VALUES,
    label: "Tipo de identidad*",
    name: "documentType",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de identificación*",
    name: "documentNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    items: COLOMBIA_CITIES,
    label: "Dpto. y ciudad de nacimiento",
    name: "birthPlace",
    type: LegalRepresentativesComponentType.AUTOCOMPLETE,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Fecha de nacimiento*",
    name: "birthdate",
    type: LegalRepresentativesComponentType.DATE,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Dirección residencial actual*",
    name: "address",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    items: COLOMBIA_CITIES,
    label: "Dpto. y ciudad de residencia*",
    name: "location",
    type: LegalRepresentativesComponentType.AUTOCOMPLETE,
    xs: 6,
  },
  {
    isRequired: true,
    items: COLOMBIA_CITIES,
    label: "Dpto. y ciudad de expedición*",
    name: "expeditionLocation",
    type: LegalRepresentativesComponentType.AUTOCOMPLETE,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Fecha de expedición*",
    name: "expeditionDate",
    type: LegalRepresentativesComponentType.DATE,
    xs: 6,
  },
  {
    defaultValue: QuestionAnswersEnum.YES,
    label:
      "¿El representante legal es una persona\n" + "Expuesta públicamente?",
    name: "isPep",
    type: LegalRepresentativesComponentType.QUESTION,
    xs: 6,
  },
  {
    defaultValue: QuestionAnswersEnum.YES,
    label:
      "¿El representante legal tiene vínculos\n" +
      "familiares con una persona expuesta\n" +
      "públicamente?",
    name: "hasPepRelative",
    type: LegalRepresentativesComponentType.QUESTION,
    xs: 6,
  },
  {
    defaultValue: QuestionAnswersEnum.YES,
    label: "¿Existe un suplente del representante\n" + "legal?",
    name: "hasAlternate",
    type: LegalRepresentativesComponentType.QUESTION,
    xs: 12,
  },
];

export const MEXICO_FORM: LegalRepresentativesFormComponents[] = [
  {
    isRequired: true,
    items: PROFESSION_VALUES,
    label: "Título*",
    name: "profession",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    label: "",
    name: "",
    type: LegalRepresentativesComponentType.BLANK,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Primer nombre*",
    name: "firstName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo nombre (Opcional)",
    name: "secondName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Primer apellido*",
    name: "surName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo apellido (Opcional)",
    name: "lastName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Correo electrónico*",
    name: "email",
    type: LegalRepresentativesComponentType.EMAIL,
    xs: 6,
  },
  {
    isRequired: true,
    label: "RFC*",
    name: "rfc",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "CURP*",
    name: "documentNumber",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Código postal*",
    name: "zipCode",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Dirección*",
    name: "address",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    items: MEXICO_CITIES,
    label: "Estado y población*",
    name: "state",
    type: LegalRepresentativesComponentType.AUTOCOMPLETE,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Colonia*",
    name: "colony",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "País*",
    name: "country",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de teléfono*",
    name: "cellphoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    defaultValue: MexicoDocumentTypeEnum.CURP,
    label: "",
    name: "documentType",
    type: LegalRepresentativesComponentType.ONLY_DEFAULT_VALUE,
    xs: 6,
  },
];

export const ECUADOR_FORM: LegalRepresentativesFormComponents[] = [
  {
    isRequired: true,
    label: "Primer nombre*",
    name: "firstName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo nombre (Opcional)",
    name: "secondName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Primer apellido*",
    name: "surName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo apellido (Opcional)",
    name: "lastName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Correo electrónico*",
    name: "email",
    type: LegalRepresentativesComponentType.EMAIL,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Fecha de nacimiento*",
    name: "birthdate",
    type: LegalRepresentativesComponentType.DATE,
    xs: 6,
  },
  {
    isRequired: true,
    items: nationalityCatalog,
    label: "Nacionalidad*",
    name: "nationality",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    isRequired: true,
    items: ECUADOR_DOCUMENT_TYPE_VALUES,
    label: "Tipo de identidad*",
    name: "documentType",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de pasaporte*",
    name: "documentNumber",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    items: TAX_EXEMPT_VALUES_EC,
    label: "Exento al impuesto a la renta*",
    name: "taxExempt",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    isRequired: true,
    items: TAX_PAYER_TYPE_VALUES_EC,
    label: "Tipo de contribuyente*",
    name: "taxPayerType",
    onChange: (setValue: any, index: number) => {
      setValue(`legalRepresentatives.${index}.riseNumber`, "", {
        shouldValidate: true,
      });
    },
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    checkError: (
      error: any,
      name: string,
      index: number,
      isDisable: boolean
    ) => {
      if (checkLegalRepresentantivesError(error, isDisable)) {
        error.legalRepresentatives.forEach(
          (object: any, indexError: number) => {
            if (indexError === index) delete object[name];
          }
        );
      }

      return error;
    },
    defaultValue: "",
    isRequired: false,
    label: "Número de RISE*",
    name: "riseNumber",
    onDisable: (getValues: any, index: number) => {
      return (
        getValues(`legalRepresentatives.${index}.taxPayerType`) !==
        TaxPayerTypeEnum.RISE
      );
    },
    onValidate: (getValues: any, index: number) => (value: any) => {
      return validateTaxPayerTypeFieldEmpty(getValues, index, value);
    },
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Nombre del cónyuge*",
    name: "mateName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número cédula del cónyuge*",
    name: "mateDocumentNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de teléfono*",
    name: "cellphoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
];

export const CHILE_FORM: LegalRepresentativesFormComponents[] = [
  {
    isRequired: true,
    label: "Primer nombre*",
    name: "firstName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo nombre (Opcional)",
    name: "secondName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Primer apellido*",
    name: "surName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo apellido (Opcional)",
    name: "lastName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "RUT*",
    name: "documentNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Correo electrónico*",
    name: "email",
    type: LegalRepresentativesComponentType.EMAIL,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de teléfono*",
    name: "phoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    label: "",
    name: "",
    type: LegalRepresentativesComponentType.BLANK,
    xs: 6,
  },
  {
    defaultValue: ChileDocumentTypeEnum.RUT_DOCUMENT_TYPE,
    label: "",
    name: "documentType",
    type: LegalRepresentativesComponentType.ONLY_DEFAULT_VALUE,
    xs: 6,
  },
];

export const PERU_FORM: LegalRepresentativesFormComponents[] = [
  {
    isRequired: true,
    label: "Primer nombre*",
    name: "firstName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo nombre (Opcional)",
    name: "secondName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Primer apellido*",
    name: "surName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    label: "Segundo apellido (Opcional)",
    name: "lastName",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    items: PERU_DOCUMENT_TYPE_VALUES,
    label: "Tipo de identidad*",
    name: "documentType",
    type: LegalRepresentativesComponentType.SELECT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Número de identificación*",
    name: "documentNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Nacionalidad",
    name: "nationality",
    type: LegalRepresentativesComponentType.ONLY_LETTERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Fecha de nacimiento*",
    name: "birthdate",
    type: LegalRepresentativesComponentType.DATE,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Dirección residencial actual*",
    name: "address",
    type: LegalRepresentativesComponentType.TEXT,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Correo electrónico*",
    name: "email",
    type: LegalRepresentativesComponentType.EMAIL,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Teléfono*",
    name: "phoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
  {
    isRequired: true,
    label: "Celular*",
    name: "cellphoneNumber",
    type: LegalRepresentativesComponentType.ONLY_NUMBERS,
    xs: 6,
  },
];

export const FORM_BY_COUNTRY = {
  [CountriesEnum.COLOMBIA]: COLOMBIA_FORM,
  [CountriesEnum.MEXICO]: MEXICO_FORM,
  [CountriesEnum.CHILE]: CHILE_FORM,
  [CountriesEnum.PERU]: PERU_FORM,
  [CountriesEnum.ECUADOR]: ECUADOR_FORM,
};
