import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTelephoneTagState } from "./state/useTelephoneTagState";
import { telephoneTagStyle } from "./TelephoneTag.style";
import { get } from "lodash";
import { TelephoneEnum } from "../../shared/infrastructure/enums/TelephoneEnum";
import { TextfieldPhone } from "@kushki/connect-ui";
import { GenericAddButton } from "../GeneralConditionSection/components/GenericAddButton/GenericAddButton";
import { FabButtonsContainer } from "../GeneralConditionSection/components/FabButtonsContainer/FabButtonsContainer";
import { Controller } from "react-hook-form";

export interface ITelephoneTagProps {
  setTransactionPhoneNumber: React.Dispatch<React.SetStateAction<string[]>>;
  defaultList: string[];
  operator: string;
  isDisable: boolean;
}

export const TelephoneTag = (props: ITelephoneTagProps) => {
  const classes = telephoneTagStyle();
  const {
    fields,
    handleAddNumber,
    handleInputChange,
    btnDisable,
    handleDelete,
    handleEdit,
    control,
  } = useTelephoneTagState(props);

  return (
    <Grid item container>
      <Grid item xs={12}>
        {fields.map((item, index) => (
          <Grid className={classes.mainGrid} key={item.id}>
            <Grid container direction={"row"} spacing={1}>
              <Grid item xs={4}>
                <Typography
                  variant={"h6"}
                  sx={{ mb: 1 }}
                  hidden={fields.length - 1 !== index}
                >
                  {TelephoneEnum.ENTER_TELEPHONE_NUMBER +
                    get(item, "arrayPhone[0].value", "")}
                </Typography>
                <Controller
                  render={() => (
                    <TextfieldPhone
                      defaultValue={item.value}
                      placeholder={TelephoneEnum.PHONE_NUMBER}
                      isDisabled={!item.isEdited}
                      onChange={(phone: string) => {
                        handleInputChange(phone, index);
                      }}
                    />
                  )}
                  name={`arrayPhone[${index}]`}
                  control={control}
                />
              </Grid>
              {index !== fields.length - 1 && !props.isDisable && (
                <>
                  <FabButtonsContainer
                    handleEditFabButton={() => {
                      handleEdit(index);
                    }}
                    handleDeleteFabButton={() => {
                      handleDelete(index);
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        ))}
        {!btnDisable && !props.isDisable && (
          <Grid item sx={{ mt: 0, pt: 0 }}>
            <GenericAddButton
              handleOnClick={handleAddNumber}
              buttonTitle={TelephoneEnum.ADD_PHONE_NUMBER}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
