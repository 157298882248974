import checkIcon from "../../../assets/images/Icon-Aprobada.svg";
import { IconEnum } from "../IconEnum";
import pendingIcon from "../../../assets/images/Icon-Inicializada.svg";
import modifyIcon from "../../../assets/images/Icon-Modificada.svg";
import viewIcon from "../../../assets/images/Review.svg";
import rejectIcon from "../../../assets/images/Icon-Declinada.svg";
import * as React from "react";
import { KindEnum } from "../KindEnum";
import { StatusEnum } from "../StatusEnum";

export function GetCustomStepIcon(iconType: React.ReactNode) {
  let icon: string = checkIcon;

  switch (iconType) {
    case IconEnum.PENDING:
      icon = pendingIcon;
      break;
    case IconEnum.COMPLETE:
      icon = checkIcon;
      break;
    case IconEnum.MODIFY:
      icon = modifyIcon;
      break;
    case IconEnum.OMIT:
      icon = pendingIcon;
      break;
    case IconEnum.VIEW:
      icon = viewIcon;
      break;
    case IconEnum.REJECT:
      icon = rejectIcon;
      break;
  }
  return icon;
}

export function GetCustomStepBackground(iconType: React.ReactNode) {
  let backgroundColor = "#F7FAFC";

  switch (iconType) {
    case IconEnum.COMPLETE:
      backgroundColor = "#DEFFF7";
      break;
    case IconEnum.MODIFY:
      backgroundColor = "#FEF9EC";
      break;
    case IconEnum.PENDING:
    case IconEnum.OMIT:
      backgroundColor = "#F7FAFC";
      break;
    case IconEnum.VIEW:
      backgroundColor = "#FEF9EC";
      break;
    case IconEnum.REJECT:
      backgroundColor = "#FFE8EC";
      break;
  }
  return backgroundColor;
}

export const getIconEvidence = (kind: KindEnum, status: StatusEnum) => {
  switch (kind) {
    case KindEnum.CREDIT_NOTE:
    case KindEnum.DEBIT_NOTE:
      return IconEnum.VIEW;
    case KindEnum.INVOICE:
      return status === StatusEnum.ANNUL ? IconEnum.REJECT : IconEnum.COMPLETE;
    default:
      return IconEnum.COMPLETE;
  }
};
