import { CountryEnum } from "../../enums/CountryEnum";

export enum FILTER_GROUPS {
  STATE = "Estado",
  CONSTITUTIONAL_COUNTRY = "País de Constitución",
  OPERATIONAL_COUNTRY = "País de Operación",
  CLIENT_TYPE = "Tipo de cliente",
}
export enum STATE_LABEL_ITEM {
  ACTIVE = "Activo",
  PENDING = "Pendiente",
  INACTIVE = "Inactivo",
}

export const STATE_ITEM_VALUES: Record<STATE_LABEL_ITEM, string> = {
  [STATE_LABEL_ITEM.ACTIVE]: "active",
  [STATE_LABEL_ITEM.PENDING]: "pending",
  [STATE_LABEL_ITEM.INACTIVE]: "inactive",
};

export const OPERATIONAL_COUNTRY_LABELS: Record<CountryEnum, string> = {
  [CountryEnum.generic]: CountryEnum.generic,
  [CountryEnum.mexico]: "México",
  [CountryEnum.peru]: "Perú",
  [CountryEnum.chile]: "Chile",
  [CountryEnum.colombia]: "Colombia",
  [CountryEnum.el_salvador]: "El Salvador",
  [CountryEnum.nicaragua]: "Nicaragua",
  [CountryEnum.panama]: "Panamá",
  [CountryEnum.ecuador]: "Ecuador",
  [CountryEnum.costa_rica]: "Costa Rica",
  [CountryEnum.guatemala]: "Guatemala",
  [CountryEnum.honduras]: "Honduras",
  [CountryEnum.usa]: "Estados Unidos",
  [CountryEnum.brasil]: "Brasil",
};
