import axios from "../../../shared/axios-util";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../../shared/constants/routes/api_routes";
import { GetMerchantNodeRequest } from "../../../../types/get_merchant_node_request";
import { MerchantNodeResponse } from "../../../../types/merchant_node_response";

const getMerchantNodeInfo = createAsyncThunk<
  MerchantNodeResponse,
  GetMerchantNodeRequest
>("app/getMerchantNodeInfo", async (payload: GetMerchantNodeRequest) => {
  const response = await axios.post<MerchantNodeResponse>(
    API_ROUTES.GET_MERCHANT_NODE_INFO,
    {
      configId: payload.configId,
      publicMerchantId: payload.publicMerchantId,
    }
  );

  return response.data;
});

export { getMerchantNodeInfo };
