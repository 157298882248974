import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Container } from "@material-ui/core";
import { IAppState, INotification } from "../../store/reducer";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  connectSession,
  IAppAction,
  setNotification,
  verifyAccessCode,
} from "../../store/actionCreators";
import { Dispatch } from "redux";
import NotificationSnackbar from "../../components/Notification/NotificationSnackbar";
import { useAssistanceIndexState } from "./state/useAssistanceIndexState";
import { CardAssistance } from "../../components/CardAssistance/CardAssistance";
import { ConnectionRequest } from "../../../types/connection_request";

export interface IAssistanceIndexStateProps {
  state: IAppState;
}
export interface IAssistanceIndexFunctionProps {
  setNotification: (notify: INotification) => IAppAction;
  verifyAccessCode: (accessCode: string) => void;
  connectSession: (body: ConnectionRequest) => void;
}

export type AssistanceIndexProps = IAssistanceIndexStateProps &
  IAssistanceIndexFunctionProps;

export const AssistanceIndex: React.FC<AssistanceIndexProps> = (
  props: AssistanceIndexProps
) => {
  const { properties, handler } = useAssistanceIndexState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <NotificationSnackbar
          msg={props.state.notification!.message}
          open={props.state.notification!.show}
          type={props.state.notification!.type}
          handlerClose={handler.close}
        />
        <PageHeader title="Soporte" />
        <CardAssistance
          {...properties.assistanceProps}
          merchantAssistance={props.state.merchantAccessCode!}
          handlerVerifyAccessCode={handler.verifyAccessCode}
          handlerConnectSupport={handler.connectSession}
        />
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => IAssistanceIndexStateProps = (
  state: IAppState
): IAssistanceIndexStateProps => ({
  state: state,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IAssistanceIndexFunctionProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IAssistanceIndexFunctionProps => ({
  setNotification: (notify: INotification): IAppAction =>
    dispatch(setNotification(notify)),
  verifyAccessCode: (accessCode: string): void =>
    dispatch(verifyAccessCode(accessCode)),
  connectSession: (body: ConnectionRequest): void =>
    dispatch(connectSession(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssistanceIndex);
