import {
  FLAG_ICON,
  TEXT_FLAG,
} from "../../components/TableCells/CellFlag/CellFlag.constants";
import { defaultTo, get } from "lodash";
import IconEmpty from "../../assets/icons/IconEmpty";
import { IFlagValues } from "../../components/TableCells/CellFlag/CellFlag";

const getCountryByCode = (code_country: string): string => {
  const code_countries: string[] = Object.values(TEXT_FLAG);
  const traduction_countries: string[] = Object.keys(TEXT_FLAG);
  const position: number = code_countries.indexOf(code_country);

  return get(traduction_countries, `[${position}]`, "");
};

export const getFlagValues = (
  isCode: boolean,
  country_value: string
): IFlagValues => {
  let country_text: string = country_value;
  let code_country: string = country_value;

  if (isCode) {
    country_text = getCountryByCode(country_value);
  } else {
    code_country = TEXT_FLAG[country_text];
  }

  return {
    countryCode: code_country,
    countryText: country_text,
    IconFlag: defaultTo(FLAG_ICON[country_text], IconEmpty),
  };
};
