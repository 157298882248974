export interface ITransaction {
  id: string;
  date: string;
  ticketNumber: string;
  email: string;
  paymentMethod: string;
  vehiclePlate: string;
  transactionAmount: string;
  state: string;
}

export interface ITransactionsState {
  areTransactionsLoading: boolean;
  transactions: ITransaction[];
  selectedTransactions: ITransaction[];
}

export enum ObservationItemStatus {
  REVIEW_PROCESSOR = "review_processor",
  REVIEW_KUSHKI = "review_kushki",
  CONCILIATED = "conciliated",
  OMITTED = "omitted",
  PENDING_REFUND = "pending_refund",
  PENDING = "pending",
}
