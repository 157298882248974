import { createSlice } from "@reduxjs/toolkit";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import {
  addAditionalProperties,
  changeStatusNodes,
  searchMerchantByNode,
  searchMerchants,
} from "../../thunks/customer/searchMerchants.thunk";
import { ISnackBar, ISnackBarWithAction } from "@kushki/connect-ui";
import { get } from "lodash";
import {
  buildGenericNotification,
  buildMessage,
} from "../../../shared/utils/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { getFilteredBalanceFromCustomerFamily } from "../../thunks/balance/balance.thunk";
import { addBalanceValueText } from "../../../shared/utils/dataUtils";

export interface IChangeStatus {
  entityName: string;
  status: string;
}

export interface CustomerData {
  customerList: SearchMerchantResponse;
  changeStatus?: IChangeStatus;
  notifyChangeStatus?: ISnackBar | ISnackBarWithAction;
  merchantByNodeId: MerchantNodeData;
  loadEdit: boolean;
  isDisabledEditBtn: boolean;
  isDisabledActiveBtn: boolean;
  isLoadingMerchants: boolean;
  isLoadingMerchant: boolean;
}

export const initialState: CustomerData = {
  customerList: {
    data: [],
    total: 0,
  },
  isDisabledActiveBtn: false,
  isDisabledEditBtn: false,
  isLoadingMerchant: false,
  isLoadingMerchants: false,
  loadEdit: false,
  merchantByNodeId: {},
  notifyChangeStatus: undefined,
};

export const customerDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(searchMerchants.pending, (state) => {
        state.isLoadingMerchants = true;
      })
      .addCase(searchMerchants.fulfilled, (state, action) => {
        state.customerList = action.payload;
        state.loadEdit = !state.loadEdit;
        state.isLoadingMerchants = false;
      })
      .addCase(searchMerchants.rejected, (state) => {
        state.customerList = initialState.customerList;
        state.isLoadingMerchants = false;
      })
      .addCase(changeStatusNodes.fulfilled, (state) => {
        state.notifyChangeStatus = buildGenericNotification(
          NotificationTypeEnum.SUCCESS,
          {
            message: buildMessage(
              get(state, "changeStatus.entityName", ""),
              get(state, "changeStatus.status", "")
            ),
            variant: "simple",
            withIcon: true,
          }
        );
      })
      .addCase(searchMerchantByNode.pending, (state) => {
        state.isLoadingMerchant = true;
      })
      .addCase(searchMerchantByNode.fulfilled, (state, action) => {
        state.merchantByNodeId = action.payload;
        state.isLoadingMerchant = false;
      })
      .addCase(searchMerchantByNode.rejected, (state) => {
        state.isLoadingMerchant = false;
      })
      .addCase(changeStatusNodes.rejected, (state) => {
        state.notifyChangeStatus = buildGenericNotification(
          NotificationTypeEnum.FAILED,
          {
            message: buildMessage(
              get(state, "changeStatus.entityName", ""),
              get(state, "changeStatus.status", ""),
              "error"
            ),
          }
        );
      })
      .addCase(addAditionalProperties.pending, (state) => {
        state.isLoadingMerchants = true;
      })
      .addCase(addAditionalProperties.fulfilled, (state, action) => {
        state.customerList = {
          ...state.customerList,
          data: action.payload,
        };
        state.isLoadingMerchants = false;
      })
      .addCase(addAditionalProperties.rejected, (state) => {
        state.isLoadingMerchants = false;
      })
      .addCase(
        getFilteredBalanceFromCustomerFamily.fulfilled,
        (state, action) => {
          state.customerList = addBalanceValueText(
            state.customerList,
            action.payload
          );
        }
      );
  },
  initialState,
  name: "CustomerData",
  reducers: {
    resetCustomerList: (state) => {
      state.customerList = {
        data: [],
        total: 0,
      };
    },
    setChangeStatus: (state, action) => {
      state.changeStatus = action.payload;
    },
    setDisabledActiveBtn: (state, { payload }) => {
      state.isDisabledActiveBtn = payload;
    },
    setIsDisabledEditBtn: (state, action) => {
      state.isDisabledEditBtn = action.payload;
    },
  },
});

export default customerDataSlice.reducer;
