import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const balanceInfoStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 1,
    rowGap: (theme) => theme.spacing(4),
  },
  dividerHorizontal: {
    borderWidth: "thin 0px 0px 0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
    color: "text.grey",
  },
});
