import { environment } from "../../environments/environment";

export const API_ROUTES = {
  CREATE_PROCESSOR: `${environment.kushkiUrl}/payouts/card/v1/admin/processor`,
  GET_BILLING_CORE_NODE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  GET_MERCHANT_PROCESSORS: `${environment.kushkiUrl}/merchant/v1/admin/merchant/$merchantId/processors`,
  GET_PROCESSOR: `${environment.kushkiUrl}/payouts/card/v1/admin/processor/$processorId`,
  GET_PROCESSORS: `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/$merchantId`,
  SET_DEFAULT_PROCESSOR: `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/$merchantId`,
  UPDATE_PROCESSOR: `${environment.kushkiUrl}/payouts/card/v1/admin/processor/$processorId`,
};
