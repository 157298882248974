import { IShareHolder } from "../../store/interfaces/LegalDetailsState.interfaces";
import { DEFAULT_PERCENTAGE } from "../constants/shareholder_constants";
import { getUsedPercentage } from "./shareholderUtils";

export const getAvailablePercentageLR = (
  shareHolders: IShareHolder[],
  uid: string
) => {
  const excludeShareHolder = shareHolders.filter((item) => item.uid !== uid);

  return DEFAULT_PERCENTAGE - getUsedPercentage(excludeShareHolder);
};

export const getPositionShareholder = (
  shareHolders: IShareHolder[],
  uid: string
) => {
  return shareHolders.findIndex((sh) => sh.uid === uid);
};

const monthRecord: Record<string, string> = {
  Abr: "04",
  Ago: "08",
  Dic: "12",
  Ene: "01",
  Feb: "02",
  Jul: "07",
  Jun: "06",
  Mar: "03",
  May: "05",
  Nov: "11",
  Oct: "10",
  Sep: "09",
};

export const dateFormatForMerchantPeople = (date: string) => {
  const values = date.split("/");
  const numberMonth = monthRecord[values[1]];

  values[1] = numberMonth;

  return [...values].reverse().join("-");
};
