import React from "react";
import { Grid, Typography } from "@mui/material";
import { BreadCrumb, IBreadCrumbItemProps } from "@kushki/connect-ui";
import { headerCardStyles as styles } from "./HeaderCard.styles";

export interface IHeaderCardProps {
  title: string;
  navItems: IBreadCrumbItemProps[];
}
const HeaderCard = (props: IHeaderCardProps) => {
  return (
    <Grid container sx={styles.containerCard}>
      <Grid item md={12} sx={styles.breadCrumb}>
        <BreadCrumb items={props.navItems} />
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography
          variant={"subtitle1"}
          color={"primary"}
          sx={styles.containerTitle}
        >
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { HeaderCard };
