export enum ConfigIdEnum {
  CN001 = "cn001",
  CN002 = "cn002",
  CN003 = "cn003",
  CN004 = "cn004",
  CN005 = "cn005",
  CN006 = "cn006",
  CN007 = "cn007",
  CN008 = "cn008",
  CN010 = "cn010",
  CN011 = "cn011",
  CN015 = "cn015",
  CN016 = "cn016",
  CN017 = "cn017",
  CN018 = "cn018",
  CN019 = "cn019",
}
export const allConfigs: string[] = [
  ConfigIdEnum.CN001,
  ConfigIdEnum.CN002,
  ConfigIdEnum.CN003,
  ConfigIdEnum.CN004,
  ConfigIdEnum.CN005,
  ConfigIdEnum.CN006,
  ConfigIdEnum.CN007,
  ConfigIdEnum.CN008,
  ConfigIdEnum.CN010,
  ConfigIdEnum.CN011,
  ConfigIdEnum.CN015,
  ConfigIdEnum.CN016,
  ConfigIdEnum.CN017,
  ConfigIdEnum.CN018,
  ConfigIdEnum.CN019,
];
