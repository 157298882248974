import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ModalChangeUrlConstants } from "../../../../shared/infrastructure/constants/ConfigPluginConstants";

const useStyle = makeStyles({
  content: {
    padding: "0px 32px",
  },
  header: {
    padding: "24px 32px 16px 32px",
  },
  footer: {
    padding: "8px 32px",
  },
  paper: {
    maxWidth: "634px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
  },
  text: {
    fontSize: "15px",
    paddingTop: "0px",
    paddingBottom: "8px",
  },
});

export interface ModalChangeUrlProps {
  open: boolean;
  handleAccept: () => void;
  handleClose: () => void;
}

export const ModalChangeUrl: React.FC<ModalChangeUrlProps> = (
  props: ModalChangeUrlProps
) => {
  const classes = useStyle();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle classes={{ root: classes.header }}>
        <Typography color={"primary"} classes={{ body1: classes.title }}>
          {ModalChangeUrlConstants.MODAL_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Typography variant={"subtitle1"} classes={{ subtitle1: classes.text }}>
          {ModalChangeUrlConstants.MODAL_CONTENT}
          <a
            style={{ textDecoration: "underline" }}
            href="https://apps.shopify.com/kushki-1?locale=es"
            target="_blank"
            rel="noopener noreferrer"
          >
            {ModalChangeUrlConstants.LINK_CONTENT}
          </a>
        </Typography>
        <Typography variant={"subtitle1"} classes={{ subtitle1: classes.text }}>
          {ModalChangeUrlConstants.MODAL_QUESTION}
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.footer }}>
        <Button onClick={props.handleClose}>
          {ModalChangeUrlConstants.CANCEL_BUTTON}
        </Button>
        <Button onClick={props.handleAccept}>
          {ModalChangeUrlConstants.ACCEPT_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
