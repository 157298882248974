import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const FilterIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    fill={"none"}
    viewBox="0 0 24 24"
    sx={{ p: "1px", ...sx }}
    {...rest}
  >
    <path
      d="M20.2271 4.23473H3.97705L10.4771 12.323V17.9147L13.7271 19.6247V12.323L20.2271 4.23473Z"
      stroke="#293036"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default FilterIcon;
