import { LABELS_STATUS_HEADER } from "../constants/labels/branch_manual_creation_labels";

export enum EditStatusEnum {
  pending = "pending",
  in_process = "inProcess",
  complete = "complete",
  not_modified = "noModified",
}

export const EDIT_STATUS_TEXT = {
  [EditStatusEnum.pending]: {
    color: "warning",
    valueText: LABELS_STATUS_HEADER.INCOMPLETE,
  },
  [EditStatusEnum.complete]: {
    color: "success",
    valueText: LABELS_STATUS_HEADER.MODIFIED,
  },
};
