import React from "react";
import CreditCardIcon from "../../../assets/images/card.svg";
import VisaIcon from "../../../assets/images/card-brand/visa.png";
import MastercardIcon from "../../../assets/images/card-brand/mastercard.png";
import DinersIcon from "../../../assets/images/card-brand/diners.png";
import AmericanExpressIcon from "../../../assets/images/card-brand/american_express.png";
import DiscoverIcon from "../../../assets/images/card-brand/discover.png";
import AliaIcon from "../../../assets/images/card-brand/alia.png";
import JbcIcon from "../../../assets/images/card-brand/jcb.png";
import { get, isEqual, toString } from "lodash";
import NumberFormat from "react-number-format";
import { CardBrandEnum } from "../../../shared/enums/CardBrandEnum";
import { Chargeback } from "../../../../types/chargeback";
import { Grid, GridSize, Typography } from "@mui/material";
import { IconCopy } from "@kushki/connect-ui";
import { CardInformationEnum } from "../../../shared/enums/CardInformationEnum";
import { PaymentSubMethodTypeEnum } from "../../../shared/enums/PaymentMethodEnum";

interface IPaymentMethod {
  label: string;
  icon: string;
  height: number;
  width: number;
  paddingBottom: number;
}

export const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      fixedDecimalScale={true}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

export const mapCardBrands = (cardBrand: string): IPaymentMethod => {
  switch (cardBrand) {
    case CardBrandEnum.Visa:
      return {
        height: 20,
        icon: VisaIcon,
        label: CardBrandEnum.Visa,
        paddingBottom: 1,
        width: 25,
      };
    case CardBrandEnum.Mastercard:
      return {
        height: 15,
        icon: MastercardIcon,
        label: CardBrandEnum.Mastercard,
        paddingBottom: 1,
        width: 19,
      };
    case CardBrandEnum.Amex:
      return {
        height: 15,
        icon: AmericanExpressIcon,
        label: CardBrandEnum.Amex,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Discover:
      return {
        height: 15,
        icon: DiscoverIcon,
        label: CardBrandEnum.Discover,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Diners:
      return {
        height: 15,
        icon: DinersIcon,
        label: CardBrandEnum.Diners,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Jbc:
      return {
        height: 15,
        icon: JbcIcon,
        label: CardBrandEnum.Jbc,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Alia:
      return {
        height: 15,
        icon: AliaIcon,
        label: CardBrandEnum.Alia,
        paddingBottom: 2,
        width: 20,
      };
    default:
      return {
        height: 20,
        icon: CreditCardIcon,
        label: "Tarjeta",
        paddingBottom: 1,
        width: 20,
      };
  }
};

export interface TransactionSectionProps {
  data: Chargeback;
  widthGridItemXs?: GridSize;
  widthGridItemMd?: GridSize;
}

const TransactionSectionModal: React.FC<TransactionSectionProps> = (
  props: TransactionSectionProps
) => {
  const paymentMethod: IPaymentMethod = {
    height: 20,
    icon: CreditCardIcon,
    label: "Tarjeta",
    paddingBottom: 0,
    width: 20,
  };
  const cardBrand: IPaymentMethod = mapCardBrands(
    get(props, "data.payment_brand", "")
  );
  const subTotalIva: number = get(
    props.data,
    "converted_subtotal_iva",
    get(props.data, "subtotal_iva", 0)
  );
  const subTotalIavO: number = get(
    props.data,
    "converted_subtotal_iva0",
    get(props.data, "subtotal_iva0", 0)
  );
  const subTotal: number = subTotalIva + subTotalIavO;
  const ivaValue: number = get(
    props.data,
    "converted_iva",
    get(props.data, "iva_value", 0)
  );
  const iceValue: number = get(props.data, "ice_value", 0);
  const taxes: number = ivaValue + iceValue;

  const currency: string = get(
    props.data,
    "converted_currency",
    get(props.data, "currency_code", "USD")
  );
  const sendToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };
  const isCardPresent: boolean = isEqual(
    get(props, "data.payment_submethod_type", ""),
    PaymentSubMethodTypeEnum.CARD_PRESENT
  );

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.TICKET_NUMBER}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {get(props, "data.sale_ticket_number", "")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.PAYMENT_METHOD}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction={"row"} spacing={1}>
                <Grid item>
                  <img
                    height={paymentMethod.height}
                    width={paymentMethod.width}
                    style={{
                      paddingBottom: paymentMethod.paddingBottom + "px",
                    }}
                    src={paymentMethod.icon}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textPrimary">
                    {" "}
                    {paymentMethod.label}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.CARD}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction={"row"} spacing={1}>
                <Grid item>
                  <img
                    height={cardBrand.height}
                    width={cardBrand.width}
                    style={{
                      paddingBottom: cardBrand.paddingBottom + "px",
                    }}
                    src={cardBrand.icon}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textPrimary">
                    {" "}
                    {"****"}
                    {toString(get(props, "data.masked_credit_card", "")).slice(
                      -4
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.APPROVED_NUMBER}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {get(props.data, "approval_code", "N/A")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.SUBTOTAL}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {formatNumber(subTotal, currency)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.TAXES}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {formatNumber(taxes, currency)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.grey">
                {CardInformationEnum.TOTAL}
                {":"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {formatNumber(
                  get(props, "data.approved_transaction_amount", 0),
                  currency
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isCardPresent && (
          <Grid item xs={12}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" color="text.grey">
                  {CardInformationEnum.KUSHKI_ID}
                  {":"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container direction={"row"} spacing={1}>
                  <Grid item>{get(props, "data.transaction_card_id", "")}</Grid>
                  <Grid item>
                    <IconCopy
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        await sendToClipboard(
                          get(props, "data.transaction_card_id", "")
                        );
                      }}
                    ></IconCopy>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default TransactionSectionModal;
