/**
 * DynamoDB's Index enum
 */
export enum FilterTypeEnum {
  merchantTax = "merchanTax",
  transactionType = "transactionType",
  transactionTypeHistoric = "transaction_type",
  kind = "kind",
  currency = "currency",
  cycle = "cycle",
  invoiceMode = "invoiceMode",
  initialState = "initial_state",
  status = "status",
  processingType = "processingType",
  models = "models",
  cityId = "cityId",
  processorType = "processorType",
}
