import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const CloseIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    sx={sx}
    {...rest}
  >
    <path
      d="M18.6668 5.33301L5.3335 18.6663"
      stroke="#293036"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3335 5.33301L18.6668 18.6663"
      stroke="#293036"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
