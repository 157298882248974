import { useForm, ArrayField } from "react-hook-form";
import { useEffect, useState } from "react";
import { get } from "lodash";
import { ITelephoneTagProps } from "../TelephoneTag";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import {
  isFormEditMode,
  isOperatorEqualOrDifferent,
} from "../../../utils/utilsFile";
import { TelephoneRestrictions } from "../../../shared/infrastructure/enums/TelephoneEnum";

export interface IUseTelephoneTagState {
  fields: Partial<ArrayField<Record<string, any>, "id">>[];
  handleAddNumber: () => void;
  control: any;
  handleInputChange: (phone: string, index: number) => void;
  btnDisable: boolean;
  handleDelete: (index: number) => void;
  handleEdit: (index: number) => void;
}

export interface IPhoneNumber {
  arrayPhone: {
    value: string;
    isEdited: boolean;
  }[];
}

export const useTelephoneTagState = (
  props: ITelephoneTagProps
): IUseTelephoneTagState => {
  const { ruleId, ruleName, id } = useParams<IUseParams>();

  const basePathDefaultValues: string = get(
    props,
    "defaultList",
    []
  ).toString();

  const phoneNumberList = basePathDefaultValues
    .split(",")
    .map((item: string) => {
      return {
        value: item,
        isEdited: true,
      };
    });

  const isReadyToRenderDefaultValues = (): boolean => {
    return isFormEditMode(ruleId || ruleName) || isFormEditMode(id);
  };

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    isReadyToRenderDefaultValues()
      ? phoneNumberList.map(({ value }) => value)
      : []
  );

  phoneNumberList.push({
    value: "",
    isEdited: false,
  });

  const { control, setValue, getValues, watch, reset } = useForm<IPhoneNumber>({
    defaultValues: {
      arrayPhone: isReadyToRenderDefaultValues()
        ? phoneNumberList
        : [{ value: "", isEdited: true }],
    },
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });

  const fields = watch("arrayPhone");
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [initialOperator] = useState<string>(props.operator);

  const handleInputChange = (phone: string, index: number): void => {
    setValue(`arrayPhone[${index}].value`, phone);

    const array_phone: string[] = phone.split(" ");
    if (
      array_phone.length === 2 &&
      array_phone[1] !== "" &&
      phone.length >= TelephoneRestrictions.MIN_LENGTH &&
      index === fields.length - 1
    ) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  useEffect(() => {
    props.setTransactionPhoneNumber(phoneNumbers);
  }, [phoneNumbers]);

  useEffect(() => {
    if (initialOperator !== props.operator) {
      props.setTransactionPhoneNumber([]);

      reset();
      setValue("arrayPhone", [{ value: "", isEdited: true }]);
      setPhoneNumbers([]);
    }
  }, [props.operator]);

  useEffect(() => {
    let oldValues = getValues("arrayPhone");
    oldValues = oldValues.map((data, indexArray) => {
      if (indexArray !== oldValues.length - 1) {
        if (data.isEdited) {
          data.value = phoneNumbers[indexArray];
          data.isEdited = false;
        }
      } else {
        data.isEdited = !props.isDisable;
      }

      return data;
    });

    setValue("arrayPhone", oldValues);
  }, [props.isDisable]);

  const handleAddNumber = (): void => {
    const oldValues = getValues("arrayPhone");
    const phones = oldValues.map(({ value }) => value);

    oldValues[oldValues.length - 1].isEdited = false;

    const newArrayPhone = [...oldValues];

    if (!isOperatorEqualOrDifferent(props.operator)) {
      newArrayPhone.push({ value: "", isEdited: true });
    }
    setValue("arrayPhone", [...newArrayPhone]);

    setPhoneNumbers(phones);
    setBtnDisable(true);
  };

  const handleDelete = (index: number): void => {
    const oldValues = getValues("arrayPhone");
    oldValues.splice(index, 1);

    const phones = oldValues.map(({ value }) => value);
    setPhoneNumbers(phones);

    reset();
    setValue("arrayPhone", oldValues);
  };

  const handleEdit = (index: number): void => {
    let oldValues = getValues("arrayPhone");
    oldValues = oldValues.map((data, indexArray) => {
      if (indexArray === index) {
        if (
          data.isEdited &&
          data.value.length < TelephoneRestrictions.MIN_LENGTH
        ) {
          data.value = phoneNumbers[indexArray];
        }
        data.isEdited = !data.isEdited;
      } else {
        if (indexArray !== oldValues.length - 1 && data.isEdited) {
          data.value = phoneNumbers[indexArray];
          data.isEdited = false;
        }
      }

      return data;
    });

    const phones = oldValues
      .map(({ value }) => value)
      .splice(0, oldValues.length - 1);

    setValue("arrayPhone", oldValues);
    setPhoneNumbers(phones);
  };

  return {
    handleInputChange,
    fields,
    handleAddNumber,
    control,
    btnDisable,
    handleDelete,
    handleEdit,
  };
};
