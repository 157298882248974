import { IUseContentModalBranch } from "../ContentModal.interface";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SearchRuleRequest } from "../../../../types/search_rule_request";
import { SearchRuleResponse } from "../../../../types/search_rule_response";
import { getRequestRules } from "../../../store/actionCreators";
import { isUndefined } from "lodash";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";

export const useContentModalBranchComponentState =
  (): IUseContentModalBranch => {
    const dispatch = useDispatch();
    const [itemsPerPage] = useState<number>(10);
    const [offset] = useState<number>(0);
    const [searchRuleRequest, setSearchRuleRequest] = useState<
      SearchRuleRequest | undefined
    >();

    useEffect(() => {
      setSearchRuleRequest({
        from: "2022-07-13T00:00:00.000Z",
        to: "2022-07-13T23:59:59.000Z",
        limit: itemsPerPage,
        offset,
        filter: {
          status: "enable",
          type: "first_level",
        },
      });
    }, [itemsPerPage, offset]);

    useEffect(() => {
      if (!isUndefined(searchRuleRequest)) {
        dispatch(
          getRequestRules("rules-request", searchRuleRequest, undefined)
        );
      }
    }, [searchRuleRequest]);

    const searchRuleResponse: SearchRuleResponse | undefined = useSelector(
      (state: IAppState) => state.searchRequestRulesResponse
    );

    return {
      searchRuleResponse,
    };
  };
