import * as React from "react";
import { FC } from "react";
import {
  ISelectNewItemListExtend,
  ISelectNewItemProps,
} from "./SelectNewItem.interfaces";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useSelectNewItem } from "./hooks/useSelectNewItem";
import RenderInput from "./components/RenderInput/RenderInput";
import RenderOption from "./components/RenderOption/RenderOption";
import { selectNewItemStyles } from "./SelectNewItem.styles";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { get } from "lodash";

const SelectNewItem: FC<ISelectNewItemProps> = (props: ISelectNewItemProps) => {
  const propsHook = useSelectNewItem(props);

  return (
    <>
      <ComponentSecurityWrapper
        componentId={ComponentIdsEnum.M_CLIENTS_SEARCH_OWNER}
      >
        <Autocomplete
          id="autocomplete-select"
          data-testid={"autocompletePrincipal"}
          sx={selectNewItemStyles.autocompleteSelect}
          ListboxProps={{
            style: {
              margin: "0px",
              maxHeight: propsHook.addButtonText ? "252px" : "200px",
              padding: "0px",
            },
          }}
          options={propsHook.listOptions}
          getOptionLabel={(option) => option.label}
          filterOptions={propsHook.filterOptions}
          inputValue={propsHook.inputValueText}
          onInputChange={propsHook.handleInputChange}
          renderOption={(props, option) => {
            const optionLabel = get(option, "label", "");
            const matches = match(optionLabel, propsHook.inputValueText);
            const parts = parse(optionLabel, matches);

            return (
              <>
                <RenderOption
                  {...propsHook}
                  parts={parts}
                  props={props}
                  option={option}
                />
              </>
            );
          }}
          onChange={(e, value) =>
            propsHook.handleSelected(e, value as ISelectNewItemListExtend)
          }
          disabled={propsHook.isDisabled || propsHook.isLoading}
          renderInput={(params) => (
            <>
              <RenderInput {...propsHook} params={params} />
            </>
          )}
        />
      </ComponentSecurityWrapper>
    </>
  );
};

export default SelectNewItem;
