import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDateTime,
  IGeneralFilter,
  IHistoricalRefunds,
  IRefundsPagination,
  IRefundsState,
} from "../../interfaces/RefundsState.interfaces";
import { REFUND_APPROVED_TRANSACTION_SAMPLE } from "../../../shared/constants/test_constants/refund_transaction_sample";
import { B2CRefundTransaction } from "../../../../types/b2c_refund_transaction";

export const initialState: IRefundsState = {
  filters: {
    dateTime: {
      from: "",
      timezone: "",
      to: "",
    },
    sort: "asc",
    transactionReference: "",
  },
  historicalData: {
    historicalRefunds: [],
    totalHistoricalRefunds: 0,
  },
  historicalFilters: {
    dateTime: {
      from: "",
      timezone: "",
      to: "",
    },
    generalFilter: {
      status: [],
    },
    sort: {
      field: "created",
      order: "asc",
    },
  },
  historicalRefunds: [],
  isAllRefundSelected: false,
  isEmptyTable: false,
  isLoadingMoreItems: false,
  isLoadingTable: false,
  pagination: {
    page: 1,
    rowsPerPage: 10,
  },
  refunds: [],
  scrollId: "",
  selectedHistorical: REFUND_APPROVED_TRANSACTION_SAMPLE,
  selectedRefund: REFUND_APPROVED_TRANSACTION_SAMPLE,
  selectedRefunds: [],
  totalRemoteRefunds: 0,
};

export const refundsSlice = createSlice({
  initialState,
  name: "refunds",
  reducers: {
    addSelectedRefund: (state, action: PayloadAction<B2CRefundTransaction>) => {
      state.selectedRefunds.push(action.payload);
    },
    clearSelectedRefunds: (state) => {
      state.selectedRefunds = [];
    },

    selectAllRefunds: (state) => {
      state.selectedRefunds = [...state.refunds];
    },

    setDateTimeFilter: (state, action: PayloadAction<IDateTime>) => {
      state.filters.dateTime = action.payload;
    },
    setEmptyTable: (state, action: PayloadAction<boolean>) => {
      state.isEmptyTable = action.payload;
    },
    setHistoricalDateTimeFilter: (state, action: PayloadAction<IDateTime>) => {
      state.historicalFilters.dateTime = action.payload;
      state.pagination.page = 1;
    },
    setHistoricalGeneralFilter: (
      state,
      action: PayloadAction<IGeneralFilter>
    ) => {
      state.historicalFilters.generalFilter = action.payload;
      state.pagination.page = 1;
    },
    setHistoricalRefunds: (
      state,
      action: PayloadAction<IHistoricalRefunds>
    ) => {
      state.historicalData = action.payload;
    },
    setIsAllSelectedRefunds: (state, action: PayloadAction<boolean>) => {
      state.isAllRefundSelected = action.payload;
    },
    setLoadingMoreItems: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMoreItems = action.payload;
    },
    setLoadingTable: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTable = action.payload;
    },
    setPaginationRefunds: (
      state,
      action: PayloadAction<IRefundsPagination>
    ) => {
      state.pagination = action.payload;
    },
    setRefund: (state, action: PayloadAction<string>) => {
      state.selectedRefund =
        state.refunds.find(
          (item) => item.transaction_reference == action.payload
        ) || REFUND_APPROVED_TRANSACTION_SAMPLE;
    },
    setRefunds: (state, action: PayloadAction<B2CRefundTransaction[]>) => {
      state.refunds = action.payload;
    },
    setScrollId: (state, action: PayloadAction<string>) => {
      state.scrollId = action.payload;
    },
    setSelectedHistorical: (state, action: PayloadAction<string>) => {
      state.selectedHistorical =
        state.historicalData.historicalRefunds.find(
          (item) => item.transaction_reference == action.payload
        ) || REFUND_APPROVED_TRANSACTION_SAMPLE;
    },
    setTotalRemoteRefunds: (state, action: PayloadAction<number>) => {
      state.totalRemoteRefunds = action.payload;
    },
    setTransactionReferenceFilter: (state, action: PayloadAction<string>) => {
      state.filters.transactionReference = action.payload;
    },
    toggleSortFilter: (state) => {
      state.filters.sort = state.filters.sort === "asc" ? "desc" : "asc";
    },
    toggleSortHistoricalFilter: (state) => {
      state.historicalFilters.sort.order =
        state.historicalFilters.sort.order === "asc" ? "desc" : "asc";
    },
    unSelectRefund: (state, action: PayloadAction<B2CRefundTransaction>) => {
      state.selectedRefunds = state.selectedRefunds.filter(
        (refund) =>
          refund.transaction_reference !== action.payload.transaction_reference
      );
    },
  },
});

export const {
  addSelectedRefund,
  clearSelectedRefunds,
  selectAllRefunds,
  setDateTimeFilter,
  setEmptyTable,
  setHistoricalDateTimeFilter,
  setHistoricalGeneralFilter,
  setHistoricalRefunds,
  setIsAllSelectedRefunds,
  setLoadingMoreItems,
  setLoadingTable,
  setPaginationRefunds,
  setRefund,
  setRefunds,
  setSelectedHistorical,
  setScrollId,
  setTotalRemoteRefunds,
  setTransactionReferenceFilter,
  toggleSortFilter,
  toggleSortHistoricalFilter,
  unSelectRefund,
} = refundsSlice.actions;

export default refundsSlice.reducer;
