import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const massiveStatusActionsStyles = createNamedStyles({
  boldText: {
    fontWeight: "bold",
    color: "#023365",
    fontSize: "15px",
    display: "inline-block",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "40%",
    gap: 1,
  },
  buttonsEdit: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 1,
    color: "#4498EE",
    borderColor: "#4498EE !important",
  },
  buttonOutlined: {
    border: "1.3px solid #4498EE",
    color: "#4498EE",
    "&:hover": {
      color: "#023365",
    },
  },
  description: {
    color: "#023365",
    fontSize: "15px",
  },
  descriptionWrapper: {
    width: "60%",
  },
  divider: {
    border: "none",
  },
  container: {
    padding: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
