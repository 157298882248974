import {
  IWebhookForm,
  IWebhookStore,
} from "../../interfaces/webhook.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createWebhook,
  createWebhookConnection,
  editWebhook,
  getAdminMerchant,
  getNodeInfo,
  getProcessors,
  getSmartlinks,
  getWebhook,
  getWebhookSignature,
} from "../../thunks/webhook/webhook.thunks";
import { findIndex, get, isEmpty } from "lodash";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";
import { WebhookEvent } from "../../../../types/webhook_create_request";
import { GeneralWebhook } from "../../../../types/webhook_response_schema";

export const initialState: IWebhookStore = {
  addWebhookForm: {
    alias: "",
    events: [],
    headers: [],
    options: [],
    paymentUrls: [],
    urls: [],
  },
  getNodeInfo: FetchStateEnum.PENDING,
  nodeInfo: {
    configCoreId: "",
  },
  preauthEnabled: false,
  webhookActions: {
    data: {
      general: [],
    },
    fetch: false,
    modal: {
      open: false,
    },
    payMethod: {},
    snackbar: {
      error: false,
      open: false,
    },
    submitFetch: false,
    submitModal: {
      open: false,
    },
    temp: {},
    tempUrl: "",
    webhookSignature: "",
  },
};

export const webhookSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      createWebhookConnection.pending,
      ({ webhookActions }: IWebhookStore) => {
        webhookActions.fetch = true;
      }
    );
    builder.addCase(
      createWebhookConnection.fulfilled,
      ({ webhookActions }: IWebhookStore, { payload }) => {
        webhookActions.fetch = false;
        webhookActions.modal.open = true;
        webhookActions.temp = payload;
      }
    );
    builder.addCase(getNodeInfo.pending, (state: IWebhookStore) => {
      state.getNodeInfo = FetchStateEnum.PENDING;
    });
    builder.addCase(getNodeInfo.fulfilled, (state, { payload }) => {
      state.getNodeInfo = FetchStateEnum.SUCCESS;
      state.nodeInfo = payload;
    });
    builder.addCase(
      getAdminMerchant.fulfilled,
      ({ webhookActions }: IWebhookStore, { payload }) => {
        webhookActions.payMethod.vpos = payload;
      }
    );
    builder.addCase(
      getSmartlinks.fulfilled,
      ({ webhookActions }: IWebhookStore, { payload }) => {
        webhookActions.payMethod.smartlink = payload;
      }
    );
    builder.addCase(
      getProcessors.fulfilled,
      ({ webhookActions }: IWebhookStore, { payload }) => {
        webhookActions.payMethod.processors = payload;
      }
    );
    builder.addCase(createWebhook.fulfilled, (store: IWebhookStore) => {
      store.webhookActions.snackbar.open = true;
      store.webhookActions.submitFetch = false;

      window.history.back();
    });

    builder.addCase(createWebhook.rejected, (store: IWebhookStore) => {
      store.webhookActions.submitFetch = false;
      store.webhookActions.snackbar.error = true;
    });

    builder.addCase(createWebhook.pending, (store: IWebhookStore) => {
      store.webhookActions.submitFetch = true;
    });
    builder.addCase(editWebhook.fulfilled, (store: IWebhookStore) => {
      store.webhookActions.submitFetch = false;
      store.webhookActions.snackbar.open = true;

      window.history.back();
    });

    builder.addCase(editWebhook.rejected, (store: IWebhookStore) => {
      store.webhookActions.submitFetch = false;
      store.webhookActions.snackbar.error = true;
    });

    builder.addCase(editWebhook.pending, (store: IWebhookStore) => {
      store.webhookActions.submitFetch = true;
    });

    builder.addCase(
      getWebhook.fulfilled,
      (store: IWebhookStore, { payload }) => {
        store.addWebhookForm = {
          alias: get(payload, "alias", ""),
          events: get(payload, "events", []),
          headers: get(payload, "headers", []),
          options: [],
          paymentUrls: [],
          urls: get(payload, "urls", []),
        };

        store.webhookActions.data.general = get(
          payload,
          "status",
          []
        ) as GeneralWebhook[];
      }
    );
    builder.addCase(
      getWebhookSignature.fulfilled,
      (store: IWebhookStore, { payload }) => {
        store.webhookActions.webhookSignature = payload;
      }
    );
  },
  initialState,
  name: "webhook",
  reducers: {
    addEvent: (
      { addWebhookForm }: IWebhookStore,
      { payload }: PayloadAction<WebhookEvent>
    ) => {
      addWebhookForm.events.push({
        ...payload,
      });
    },
    addWebhook: ({ webhookActions, addWebhookForm }: IWebhookStore) => {
      let indexUrl = -1;

      if (!isEmpty(webhookActions.tempUrl)) {
        indexUrl = findIndex(
          addWebhookForm.urls,
          (url: string) => url === webhookActions.tempUrl
        );
        webhookActions.tempUrl = "";
      }
      if (indexUrl < 0) {
        addWebhookForm.urls.push(
          get(webhookActions, "temp.general[0].url", "")
        );
        webhookActions.data.general?.push(
          get(webhookActions, "temp.general[0]", {})
        );
      } else {
        webhookActions.data.general![indexUrl] = get(
          webhookActions,
          "temp.general[0]",
          {}
        );
        addWebhookForm.urls[indexUrl] = get(
          webhookActions,
          "temp.general[0].url",
          ""
        );
      }
      webhookActions.temp = {};
    },
    closeWebhookModal: (
      { webhookActions }: IWebhookStore,
      { payload }: PayloadAction<boolean>
    ) => {
      webhookActions.modal.open = payload;
    },
    closeWebhookSnackbar: (
      { webhookActions }: IWebhookStore,
      { payload }: PayloadAction<boolean>
    ) => {
      webhookActions.snackbar.error = payload;
      webhookActions.snackbar.open = payload;
    },
    deleteWebhook: (
      { webhookActions, addWebhookForm }: IWebhookStore,
      { payload }: PayloadAction<string>
    ) => {
      const indexUrl = findIndex(
        addWebhookForm.urls,
        (url: string) => url === payload
      );

      addWebhookForm.urls.splice(indexUrl, 1);
      webhookActions.data.general!.splice(indexUrl, 1);
    },
    openSubmitModal: (
      { webhookActions }: IWebhookStore,
      { payload }: PayloadAction<boolean>
    ) => {
      webhookActions.submitModal.open = payload;
    },
    resetTemp: (state) => {
      state.webhookActions.temp = {};
    },
    resetWebhook: () => initialState,
    setEditUrl: (
      { webhookActions }: IWebhookStore,
      { payload }: PayloadAction<string>
    ) => {
      webhookActions.tempUrl = payload;
    },
    setPreauthEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.preauthEnabled = payload;
    },
    updateAddWebhookForm: (state, { payload }: PayloadAction<IWebhookForm>) => {
      state.addWebhookForm = payload;
    },
  },
});

export default webhookSlice.reducer;
