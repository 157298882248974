import { useEffect, useState } from "react";
import { IverifiedUrls } from "../ShowVerifiedUrls";
import { StatusHookEnum } from "../../../../shared/infrastructure/constants/statusWebhookEnum";

export interface IShowVerifiedUrls {
  showVerifiedUrls: boolean;
}

export const useShowVerifiedUrls = (props: IverifiedUrls) => {
  const [showVerifiedUrls, setshowVerifiedUrls] = useState(false);
  const validate = (value: { status: StatusHookEnum }): boolean =>
    value.status === StatusHookEnum.FAILED;
  useEffect(() => {
    Object.keys(props.verifiedUrls).map((key) => {
      if (
        props.verifiedUrls[key].map(() =>
          props.verifiedUrls[key].every(validate)
        )[0]
      ) {
        setshowVerifiedUrls(true);
      }
    });
  }, [props.verifiedUrls]);
  return { showVerifiedUrls };
};
