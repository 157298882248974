/**
 * Billing Transaction Status Enum
 */
export enum StatusEnum {
  PROCESS = "process",
  REPROCESS = "reprocess",
  PENDING = "pending",
  PENDING_VERIFY = "pending_verify",
  INITIAL = "initial",
  VALIDATED = "validated",
  FAILED_VALIDATE = "failed_validate",
  OMIT = "omit",
  REJECT = "reject",
  REJECTED = "rejected",
  PRE_PROCESSED = "pre_processed",
  INITIALIZED = "initialized",
  COMPLETED = "completed",
  COMPLETED_CHARGE = "completed_charge",
  COMPLETED_DISPERSION = "completed_dispersion",
  COMPLETED_INVOICE = "completed_invoice",
  COMPLETED_RETENTION = "completed_retention",
  MANUAL = "manual",
  EXPIRED = "expired",
  IN_REVIEW = "in_review",
  IN_RECEIVABLE = "in_receivable",
  ANNUL = "annulled",
  ANNULLED = "annul",
  FAILED = "failed",
  DELETED = "deleted",
  APPROVED = "approved",
  FINISHED = "finished",
  SUCCESSFUL = "successful",
  PROCESSED = "processed",
  OMITTED = "omitted",
  EMITTED = "emitted",
  FAILED_PROCESS = "failed_process",
  PARTIALLY_COMPLETED = "partially_completed",
}

export enum RowStatusEnum {
  Process = "approved",
  Pending = "pending",
  Omit = "omitted",
  Reprocess = "reprocess",
}

export const CompletedStatus = [
  StatusEnum.COMPLETED_CHARGE,
  StatusEnum.COMPLETED_DISPERSION,
  StatusEnum.COMPLETED_INVOICE,
  StatusEnum.COMPLETED_RETENTION,
];

export type StatusType =
  | "process"
  | "pending"
  | "omit"
  | "reject"
  | "rejected"
  | "pre_processed"
  | "initialized"
  | "completed"
  | "manual"
  | "expired"
  | "in_review"
  | "in_receivable"
  | "annulled"
  | "annul"
  | "failed"
  | "deleted"
  | "finished"
  | "successful"
  | "processed"
  | "omitted"
  | "emitted"
  | "approved";

export enum FinalStatusEnum {
  OMITTED = "omitted",
}

export enum SelectedStatusEnum {
  EDITED = "edited",
}

export enum ChipStatusEnum {
  MODIFY = "MODIFICADA",
  REJECTED = "RECHAZADO",
  INCONSISTENCY = "INCONSISTENCIA",
  NONE = "",
}

export enum TextChipStatusEnum {
  MODIFY = "Monto modificado",
  INCONSISTENCY = "Existe inconsistencia en montos",
}

export enum ExtraStatusEnum {
  APPROVED = "APPROVED",
  DOCUMENT_EMITED = "DOCUMENT_EMITED",
  NEW_DOCUMENT = "NEW_DOCUMENT",
  AMOUNT_MODIFY = "AMOUNT_MODIFY",
  NEW_INVOICE = "NEW_INVOICE",
  GENERATE_NC = "GENERATE_NC",
  GENERATE_ND = "GENERATE_ND",
  ANNUL_PROCESS = "ANNUL_PROCESS",
}

export enum InitialStatusEnum {
  APPROVE = "approve",
  OMITTED = "omitted",
  OMIT = "omit",
  OMITTED_HIS = "omitted_is",
  EMITTED = "emitted",
  REJECT = "reject",
  PENDING = "pending",
  PENDING_VERIFY = "pending_verify",
  FAILED = "failed",
  EXPIRED = "expired",
  COMPLETED = "completed",
}

export enum StatusFlowEnum {
  VALIDATOR = "validator",
  EXECUTOR = "executor",
}

export enum StatusDispersion {
  PENDING_VERIFY = "PENDIENTE DE VERIFICACIÓN",
  INITIAL = "ENVIADO",
  FAILED_VALIDATE = "ERROR DE VALIDACIÓN",
  COMPLETED = "COMPLETADO",
  VALIDATED = "VALIDADO",
}

export const DispersionStatusValue: Record<string, string> = {
  [StatusEnum.PENDING_VERIFY]: StatusDispersion.PENDING_VERIFY,
  [StatusEnum.INITIAL]: StatusDispersion.INITIAL,
  [StatusEnum.FAILED_VALIDATE]: StatusDispersion.FAILED_VALIDATE,
  [StatusEnum.COMPLETED]: StatusDispersion.COMPLETED,
  [StatusEnum.VALIDATED]: StatusDispersion.VALIDATED,
};
