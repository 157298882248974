import React, { FC } from "react";
import { IFormFieldProps } from "../FormFieldProps.interfaces";
import { Controller } from "react-hook-form";
import { Checkbox, Typography } from "@mui/material";
import { useStyles as styles } from "../../../containers/BillingData/BillingDataContainer.styles";

const CheckBoxField: FC<IFormFieldProps> = ({
  name,
  control,
  rules,
  checkBoxValue,
  checkBoxText,
  disabled,
  hidden,
}: IFormFieldProps) => {
  const classes = styles();

  return (
    <>
      {!hidden && (
        <>
          <Controller
            name={name!}
            rules={rules}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={checkBoxValue}
                color={"primary"}
                disabled={disabled}
              />
            )}
          />

          <Typography className={classes.satMessage}>{checkBoxText}</Typography>
        </>
      )}
    </>
  );
};

export default CheckBoxField;
