import React from "react";
import { SkeletonTableContainer } from "../../components/Skeletons/SkeletonTableContainer/SkeletonTableContainer";
import { ContentTable } from "../../components/common/Table/Table";
import { useUsersRolesSectionState } from "./state/useUsersRolesSectionState";
import { CardContainer } from "../../components/CardContainer/CardContainer";
import { routes } from "../../shared/infrastructure/constants/routes";

export const UserRolesSection = () => {
  const { users, publicMerchantId, merchantName } = useUsersRolesSectionState();

  return (
    <CardContainer
      key={"usersRolesCardContainer"}
      headerCardTitle={"Usuarios agregados"}
      headerButtonTitle={"Agregar usuario"}
      footerCardHrefLocation={`/users?publicMerchantId=${publicMerchantId}&merchantName=${merchantName}`}
      headerButtonOnClick={() => {
        window.location.href = `${routes.ADD_USER}?publicMerchantId=${publicMerchantId}&merchantName=${merchantName}`;
      }}
      footerTitleHrefLocation={"Edita y revisa todos los usuarios"}
      buttonCreate={true}
      childrenArrayCardComponent={[
        users === undefined ? (
          <SkeletonTableContainer rows={5} cells={3} />
        ) : (
          <ContentTable key={0} dataRows={users} dataCell={[]} headerFlag />
        ),
      ]}
    />
  );
};
