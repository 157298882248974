import { Box, BoxProps } from "@mui/material";
import React, { FC } from "react";
import { layoutStyles } from "./LayoutActionBar.styles";

const LayoutActionBar: FC<BoxProps> = ({ sx, ...rest }: BoxProps) => {
  const containerStyles = { ...layoutStyles.container, ...sx };

  return <Box sx={containerStyles} {...rest} />;
};

export default LayoutActionBar;
