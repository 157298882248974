import React from "react";
import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import {
  ColumnsEnumEC,
  IColumnsTable,
} from "../../../../shared/infrastructure/Table/ColumnsTable";
import { get } from "lodash";
import { Edit, Trash2 } from "react-feather";
import {
  RetentionCatalog,
  RetentionType,
} from "../../../../shared/infrastructure/constants/RetentionConstants";
import moment from "moment";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { retentionCatalogsLabelText } from "../../../../utils/functions/Catalog";
import { CatalogsNameEnum } from "../../../../shared/infrastructure/Enums/CatalogsNameEnum";
import {
  useStyles,
  StyledTooltip,
  DateTooltip,
} from "./DashboardTableRowEC.styles";
import { IDashboardTableRowProps } from "./DashboardTableRowEC.interfaces";

export const DashboardTableRowEC = (props: IDashboardTableRowProps) => {
  const classes = useStyles();

  const retentionText = (
    retentionType: RetentionType,
    value: string
  ): string => {
    return RetentionCatalog[retentionType][value];
  };

  const buildText = (label: string) => {
    return (
      <Typography variant={"body2"} noWrap>
        {label}
      </Typography>
    );
  };

  return (
    <DateTooltip
      title={
        <p>
          Fecha de actualización
          <span className={classes.DateTooltip}>
            {moment(get(props.retentionData, "created")).format("DD/MM/YYYY")}
          </span>
        </p>
      }
      placement={"top"}
      arrow
    >
      <TableRow>
        {props.renderColumns
          .filter((column) => column.view)
          .map((column: IColumnsTable) => {
            switch (column.id) {
              case ColumnsEnumEC.taxpayerTypeId:
                return (
                  <TableCell
                    className={classes.TableCellTaxpayer}
                    key={column.id}
                  >
                    <StyledTooltip
                      title={retentionCatalogsLabelText(
                        CatalogsNameEnum.TAX_PAYER_TYPE,
                        get(props.retentionData, column.id, "-")
                      )}
                      placement={"top-start"}
                      arrow
                    >
                      {buildText(
                        retentionCatalogsLabelText(
                          CatalogsNameEnum.TAX_PAYER_TYPE,
                          get(props.retentionData, column.id, "-")
                        )
                      )}
                    </StyledTooltip>
                  </TableCell>
                );
              case ColumnsEnumEC.activityId:
                return (
                  <TableCell className={classes.TableCell} key={column.id}>
                    {buildText(
                      retentionCatalogsLabelText(
                        CatalogsNameEnum.ACTIVITY,
                        get(props.retentionData, column.id, "-")
                      )
                    )}
                  </TableCell>
                );
              case ColumnsEnumEC.incomeSourceConceptId:
                return (
                  <TableCell className={classes.TableCell} key={column.id}>
                    {buildText(
                      retentionCatalogsLabelText(
                        CatalogsNameEnum.INCOME_SOURCE_CONCEPT,
                        get(props.retentionData, column.id, "-")
                      )
                    )}
                  </TableCell>
                );
              case ColumnsEnumEC.retIva:
                return (
                  <TableCell className={classes.TableCell} key={column.id}>
                    {buildText(
                      retentionText(
                        RetentionType.codeDatilTranslate,
                        get(props.retentionData, "retentionPercentCode", "-")
                      )
                    )}
                  </TableCell>
                );
              case ColumnsEnumEC.retFue:
                return (
                  <TableCell className={classes.TableCell} key={column.id}>
                    {buildText(
                      get(props.retentionData, "retFue", "-").toString() + "%"
                    )}
                  </TableCell>
                );
              case ColumnsEnumEC.options:
                return (
                  <TableCell className={classes.TableCell} key={column.id}>
                    <IconButton
                      onClick={() =>
                        props.onHandleEditAction(props.retentionData, true)
                      }
                    >
                      <Edit className={classes.editIcon} />
                    </IconButton>
                    <ComponentSecurityWrapper
                      componentId={get(props.securityAzure, "rolDashboard", "")}
                    >
                      <IconButton
                        onClick={() => props.handleOpenDeleteModal(props.index)}
                      >
                        <Trash2 className={classes.trashIcon} />
                      </IconButton>
                    </ComponentSecurityWrapper>
                  </TableCell>
                );
              default:
                return (
                  <TableCell className={classes.TableCell} key={column.id}>
                    {buildText(get(props.retentionData, column.id, "-"))}
                  </TableCell>
                );
            }
          })}
      </TableRow>
    </DateTooltip>
  );
};
