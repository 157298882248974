import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dialogHeader: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
    color: "#000000",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 15,
    color: "gray",
  },
  dialogContent: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#000000",
  },
  cancelButton: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "140%",
    color: "#6D7781",
  },
  confirmButton: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "140%",
    color: "#AD0C2A",
  },
});

export interface IDeactivateMerchantDialogProps {
  handleClose: () => void;
  handleDeactivate: () => void;
  loading: boolean;
  merchantName?: string;
}

export const DeactivateMerchantDialog: React.FC<IDeactivateMerchantDialogProps> = (
  props: IDeactivateMerchantDialogProps
) => {
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingTop: 25 }}>
        <Typography className={classes.dialogHeader}>
          ¿Quieres desactivar este comercio?
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <X size={20} color={"black"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="void-dialog-description"
          className={classes.dialogContent}
        >
          Estás a punto de desactivar al comercio {props.merchantName}, esto va
          inhabilitar el procesamiento de pagos y el funcionamiento de los
          servicios conectados con ellos.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: 25, paddingRight: 25 }}>
        <Button
          color="primary"
          className={classes.cancelButton}
          onClick={props.handleClose}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          autoFocus
          className={classes.confirmButton}
          onClick={props.handleDeactivate}
          endIcon={
            props.loading && <CircularProgress size={20} color={"primary"} />
          }
        >
          Sí, desactivar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
