/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_GUATEMALA: IOptionSelection[] = [
  { value: "Agromercantil", name: "Agromercantil" },
  { value: "Banco Azteca", name: "Banco Azteca" },
  { value: "Banco de Antigua", name: "Banco de Antigua" },
  { value: "Banco BAC", name: "Banco BAC" },
  { value: "Banco Citi", name: "Banco Citi" },
  { value: "Banco Credito Hipotecario", name: "Banco Credito Hipotecario" },
  { value: "Banco Bantrab", name: "Banco Bantrab" },
  { value: "Banco G&T Continental", name: "Banco G&T Continental" },
  { value: "Banco Industrial", name: "Banco Industrial" },
  { value: "Banco Inmobiliario", name: "Banco Inmobiliario" },
  { value: "Banco Internacional", name: "Banco Internacional" },
  { value: "Banco Ficohsa", name: "Banco Ficohsa" },
  { value: "Banco Reformador", name: "Banco Reformador" },
  { value: "Banco Vivibanco", name: "Banco Vivibanco" },
];

export const CURRENCY_GUATEMALA: IOptionSelection[] = [
  { value: CurrencyEnum.GTQ, name: CurrencyEnum.GTQ },
  { value: CurrencyEnum.USD, name: CurrencyEnum.USD },
];
