import React from "react";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import ModalBodyInfo from "./ModalBodyInfo";
import ModalBodyConfiguration from "./ModalBodyConfiguration";
import { KshTab } from "../../components/common/KshTab/KshTab";
import { AuthenticationElastic } from "../../../types/remote/authentication_elastic";
import { AuthenticationRules } from "../../../types/response_search_rules_by_filters";
import { ProcessorInfoResponse } from "../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../types/credential_info_response";
import {
  RolesEnum,
  validateRole,
} from "../../shared/infrastructure/enums/RolesEnum";
import { StatusEnum } from "../../shared/infrastructure/enums/StatusEnum";
import { ModalBodyTimeline } from "./TimelineComponent/ModalBodyTimeline";

export interface InfoTrxProps {
  trxInfo: AuthenticationRules | AuthenticationElastic | undefined;
}

export interface InfoApisProps {
  processorData: ProcessorInfoResponse[];
  credentialData: CredentialInfoResponse[];
}

export type InfoConfigurationProps = InfoTrxProps & InfoApisProps;

export const ModalBodyTabs: React.FC<InfoConfigurationProps> = (
  props: InfoConfigurationProps
) => {
  const isAdmin = validateRole(RolesEnum.BackofficeAdmin);
  const tabs = [
    {
      title: "Información",
      content: <ModalBodyInfo trxInfo={props.trxInfo} />,
    },
  ];

  if (isAdmin || props.trxInfo?.status !== StatusEnum.PENDING) {
    let content = <></>;
    if (props.trxInfo?.auth_rules)
      content = <ModalBodyConfiguration {...props} />;
    tabs.push({
      title: "Configuración",
      content,
    });
  }

  tabs.push({
    title: "Línea de tiempo",
    content: <ModalBodyTimeline trxInfo={props.trxInfo} />,
  });

  return (
    <ModalBody>
      <React.Fragment>
        <KshTab tabs={tabs} />
      </React.Fragment>
    </ModalBody>
  );
};
