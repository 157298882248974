import { useSelector } from "react-redux";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { defaultTo } from "lodash";

export interface IUseCustomModalState {
  isOpenCustomModal: boolean;
}

export const useCustomModalState = (): IUseCustomModalState => {
  const isOpenCustomModal: boolean = useSelector((state: IAppState) =>
    defaultTo(state.isOpenCustomModal, false)
  );
  return {
    isOpenCustomModal,
  };
};
