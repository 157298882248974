import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../shared/axios-util";
import { API_ROUTES } from "../../../../shared/constants/api_routes";
import { isEmptyFilters } from "../../../../shared/utils/refunds/filters";
import {
  setDateTimeFilter,
  setEmptyTable,
  setLoadingTable,
  setRefund,
  setRefunds,
  setScrollId,
  setTotalRemoteRefunds,
  toggleSortFilter,
} from "../../../../store/reducers/refunds/refunds";
import { RootState } from "../../../../store/store";
import getCurrentDateTime from "../../utils/getCurrentDateTime";
import {
  B2CRefundTransactionElastic,
  GetRefundTransactionListResponse as Response,
} from "../../../../../types/get-refund-transaction-list-response";
import { buildBodyRequest } from "../../utils/buildRequest";
import { formatCurrency } from "../../../../shared/utils/currency_utils";
import { getFormattedDate } from "../../../../shared/utils/date_utils";
import { setCurrentTimeZone } from "../../../../shared/utils/timezone";

const mapRefundstoData = (refunds: B2CRefundTransactionElastic[]) =>
  refunds.map((e) => ({
    date: getFormattedDate(e.created),
    hour: setCurrentTimeZone(e.created.split("T")[1]),
    merchantName: `${e.merchant_name}`,
    originalTransactionReference: e.original_transactional_reference,
    refundId: e.transaction_reference,
    transactionAmount: `${formatCurrency(e.amount)} ${e.currency}`,
    transactionReference: e.transaction_reference,
  }));

const useRefundsData = () => {
  const filters = useSelector((state: RootState) => state.refunds.filters);
  const refunds = useSelector((state: RootState) =>
    mapRefundstoData(state.refunds.refunds)
  );
  const isLoadingTable = useSelector(
    (state: RootState) => state.refunds.isLoadingTable
  );
  const isEmptyTable = useSelector(
    (state: RootState) => state.refunds.isEmptyTable
  );
  const selectedRefund = useSelector(
    (state: RootState) => state.refunds.selectedRefund
  );
  const totalRemoteRefunds = useSelector(
    (state: RootState) => state.refunds.totalRemoteRefunds
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmptyFilters(filters)) {
      const dateTimeInfo = getCurrentDateTime();

      dispatch(setDateTimeFilter(dateTimeInfo));

      return;
    }

    (async () => {
      try {
        dispatch(setLoadingTable(true));

        const body = buildBodyRequest({
          dateTimeFilters: filters.dateTime,
          sort: filters.sort,
          transactionReference: filters.transactionReference,
        });

        const { data } = await axios.post<Response>(API_ROUTES.REFUNDS, body);

        const totalRemoteRefunds = data.total.value;

        const scrollId = data.scroll;
        const refunds = data.data;

        dispatch(setScrollId(scrollId));
        dispatch(setEmptyTable(refunds.length === 0));
        dispatch(setRefunds(refunds));
        dispatch(setTotalRemoteRefunds(totalRemoteRefunds!));
      } catch (error) {
      } finally {
        dispatch(setLoadingTable(false));
      }
    })();
  }, [filters]);

  const toggleSortState = () => dispatch(toggleSortFilter());

  const setRawRefund = (id: string) => dispatch(setRefund(id));

  return {
    isEmptyTable,
    isLoadingTable,
    refunds,
    selectedRefund,
    setRawRefund,
    toggleSortState,
    totalRemoteRefunds,
  };
};

export default useRefundsData;
