import { IBankAccount } from "../../store/interfaces/dispersion.interfaces";
import {
  defaultTo,
  get,
  identity,
  isUndefined,
  omit,
  pickBy,
  sortBy,
} from "lodash";
import { AccountOrder } from "../constants/dispersions/account_bank_constants";
import { useAppSelector } from "../../store/hooks/storeHook";
import { ADD_BANK_ACCOUNT_MODAL_LABELS } from "../constants/labels/add_bank_account_modal_labels";
import { CountriesEnum, NationCountriesEnum } from "../enums/countriesEnum";
import { getCatalog } from "../constants/dispersions/dispersions";
import { CatalogsEnum } from "../enums/CatalogsEnum";
import { ALL_COUNTRIES } from "../constants/countries_with_flags";
import { BankAccountFields } from "../enums/dispersionsEnum";
import { Category } from "../interfaces/category";

export const validateIfHasMainAccount = (
  banksAccounts: IBankAccount[]
): boolean => {
  const mainAccount = banksAccounts.find(
    (account) => account.accountOrder === AccountOrder.PRINCIPAL
  );

  return mainAccount !== undefined;
};

export const getBankAccountOrder = (bankAccounts: IBankAccount[]): string =>
  validateIfHasMainAccount(bankAccounts)
    ? AccountOrder.ALTERNATE
    : AccountOrder.PRINCIPAL;

export const sortBanksAccount = (
  banksAccounts: IBankAccount[]
): IBankAccount[] => {
  return sortBy(
    banksAccounts,
    (bankAccount) => bankAccount.accountOrder !== AccountOrder.PRINCIPAL
  );
};

export const isBankAccountDifferentOfConstitutionCountry = (
  bankAccountCountry: string
): boolean => {
  const { constitutionalCountry } = useAppSelector((state) => state.dispersion);

  return (
    bankAccountCountry.toUpperCase().trim() !==
    constitutionalCountry.toUpperCase().trim()
  );
};

export const getBankNameByCountryAndId = (
  country: string,
  bankId: string
): string => {
  const banks = getCatalog(country as CountriesEnum, CatalogsEnum.Banks);
  const bank = banks.find((bank) => bank.value === bankId);

  return get(bank, "name", "-");
};

export const getAccountTypeByCountry = (
  country: string,
  accountTypeId: string
): string => {
  const accountTypes = getCatalog(
    country as CountriesEnum,
    CatalogsEnum.BankAccountTypes
  );
  const accountType = accountTypes.find(
    (accountType) => accountType.value === accountTypeId
  );

  return get(accountType, "name", "-");
};

export const getInitialBankAccountValues = (data: {
  accountType?: string;
  bankAccounts: IBankAccount[];
  constitutionalCountry: string;
  beneficiaryNameFromBasicDetails: string;
}): IBankAccount => ({
  accountNumber: "",
  accountOrder: validateIfHasMainAccount(data.bankAccounts)
    ? AccountOrder.ALTERNATE
    : AccountOrder.PRINCIPAL,
  accountType: get(data, "accountType", "0"),
  address: "",
  bankId: "",
  bankName: "",
  country: data.constitutionalCountry,
  currency: getCatalog(data.constitutionalCountry, CatalogsEnum.Currency)[0]
    .value as string,
  interbankAccountCode: "",
  nameBeneficiary: data.beneficiaryNameFromBasicDetails,
});

export const getLabelsBankModal = (
  orderAccount: string,
  isEdition: boolean
): string => {
  const editCreateLabel = isEdition ? "edition" : "create";
  const labels = {
    [AccountOrder.PRINCIPAL]: {
      create: ADD_BANK_ACCOUNT_MODAL_LABELS.principal.titleAdd,
      edition: ADD_BANK_ACCOUNT_MODAL_LABELS.principal.titleEdit,
    },
    [AccountOrder.ALTERNATE]: {
      create: ADD_BANK_ACCOUNT_MODAL_LABELS.alternative.titleAdd,
      edition: ADD_BANK_ACCOUNT_MODAL_LABELS.alternative.titleEdit,
    },
  };

  return labels[orderAccount][editCreateLabel];
};

export const getLabelCountryFromValue = (value: string): string => {
  const country = ALL_COUNTRIES.find((country) => country.value === value);

  return country?.label ? country.label : "";
};

export const isNationCountry = (country: string): boolean =>
  !!(
    Object.keys(NationCountriesEnum) as Array<keyof typeof NationCountriesEnum>
  ).find(
    (nationCountry) =>
      nationCountry.toUpperCase().trim() === country.toUpperCase().trim()
  );

export const cleanBankAccountFields = (data: IBankAccount): IBankAccount => {
  let cleanData: IBankAccount;

  if (isUndefined(data.typeAccount)) {
    data = omit(data, [
      BankAccountFields.BANK_CITY_OF_ORIGIN,
      BankAccountFields.BANK_POSTAL_CODE_ORIGIN,
    ]);
    cleanData = isNationCountry(defaultTo(get(data, "country"), ""))
      ? omit(data, [BankAccountFields.BANK_NAME])
      : omit(data, [BankAccountFields.BANK_ID]);
  } else {
    if (data.bankId) {
      const test: Category[] = getCatalog(
        data.country,
        CatalogsEnum.Banks
      ).filter((category: Category) => category.value === data.bankId);

      data.bankName = test[0].name;
    }
    cleanData = data;
  }

  return <IBankAccount>pickBy(cleanData, identity);
};
