import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import {
  IStepSection,
  IStepsLayoutProps,
} from "@components/molecule/StepsLayout/StepsLayout.interfaces";
import { stepsLayoutStyles as styles } from "@components/molecule/StepsLayout/StepsLayout.styles";
const StepsLayout: FC<IStepsLayoutProps> = ({ steps }: IStepsLayoutProps) => {
  return (
    <Box sx={styles.container}>
      {steps.map(({ component, label }: IStepSection) => (
        <Box key={label} sx={styles.stepSection}>
          <Typography variant={"h5"} color={"text.primary"} sx={styles.label}>
            {label}
          </Typography>
          {component}
        </Box>
      ))}
    </Box>
  );
};

export default StepsLayout;
