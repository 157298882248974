import { IconCircleCheck, Tab, Tabs } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import React from "react";
import { tabNames } from "../../shared/enums/TabNamesEnum";
import { IStyles } from "../../shared/infrastructure/interfaces/create_named_styles";
import { TabInfo } from "./ProcessingTab.interfaces";
import { useProcessingTab } from "./state/useProcessingTab";
import { useDispatch } from "react-redux";
import { changeTab } from "../../store/reducers/layout/layout.slice";
import { useAppSelector } from "../../store/hooks/storeHook";
import { statusTabInfo } from "../../shared/enums/StatusStepEnum";
import { get } from "lodash";
import { isDeferredAllowed } from "../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import { NodeInfoPath } from "../../shared/enums/NodeInfoPath";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";

const classesSx: IStyles = {
  tabsWrapper: {
    width: "100%",
    paddingTop: "40px",
    paddingBottom: "32px",
  },
  tabSkeleton: {
    marginBottom: 1,
    width: 1230,
    height: 100,
  },
};

export const ProcessingTab: React.FC = () => {
  const [
    statusProcessor,
    statusBusinessRules,
    statusDeferred,
  ] = useProcessingTab();
  const {
    nodeInfo,
    tabSelected,
    loadingDeferred,
    loadingNodeInfo,
    isNotRendererTabs,
    isMassiveBranches,
    branchEditList,
  } = useAppSelector((store) => store.layout);
  const dispatch = useDispatch();
  const country: string = isMassiveBranches
    ? get(branchEditList, "data[0].country", "")
    : get(nodeInfo, NodeInfoPath.InfoCountry, "");
  const tabs: TabInfo[] = getTabInfo(
    statusProcessor,
    statusBusinessRules,
    statusDeferred,
    country
  );
  const isLoading: boolean | undefined =
    (loadingNodeInfo || loadingDeferred) && isNotRendererTabs;

  return (
    <MaskSkeleton
      sx={classesSx.tabSkeleton}
      isLoading={isLoading && !isMassiveBranches}
    >
      <Box sx={classesSx.tabsWrapper}>
        <Tabs
          onChange={(value) => {
            dispatch(changeTab(value));
          }}
          orientation="horizontal"
          variant="fullWidth"
          valueTab={tabSelected}
        >
          {tabs.map((tab: TabInfo, index: number) => {
            const statusTab: string = get(tab, "status", statusTabInfo.PENDING);

            return (
              <Tab
                key={index}
                variant={"secondary"}
                text={tab.tab}
                textSecondary={statusTab}
                colorTextSecondary={
                  statusTab == statusTabInfo.COMPLETE ? "success" : "grey"
                }
                iconSecondary={
                  statusTab == statusTabInfo.COMPLETE ? (
                    <IconCircleCheck color={"success"} />
                  ) : undefined
                }
                disabled={tab.status === statusTabInfo.NOT_AVAILABLE}
              />
            );
          })}
        </Tabs>
      </Box>
    </MaskSkeleton>
  );
};

const getTabInfo = (
  statusProcessor: string,
  statusBusinessRules: string,
  statusDeferred: string,
  merchantCountry: string
): TabInfo[] => {
  const basicTabs = [
    { tab: tabNames.processor, status: statusProcessor },
    { tab: tabNames.BusinessRules, status: statusBusinessRules },
  ];

  if (!isDeferredAllowed(merchantCountry)) return basicTabs;

  return [...basicTabs, { tab: tabNames.deferred, status: statusDeferred }];
};
