import React, { FC } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { tableHeaderStyles as styles } from "./TableHeader.styles";
import { ITableHeader } from "./TableHeader.interfaces";
import { Plus } from "react-feather";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { CompomentIdsEnum } from "../../shared/constants/labels/main_containter_labels";

const TableHeader: FC<ITableHeader> = ({
  title,
  buttonText,
  handleButtonClick,
  isModule,
}: ITableHeader) => {
  const classes = styles();

  return (
    <Box className={classes.boxContainer}>
      <Typography className={classes.title}>{title}</Typography>
      <ComponentSecurityWrapper
        componentId={
          isModule
            ? CompomentIdsEnum.M_ROLES_ADD_MODULE
            : CompomentIdsEnum.M_ROLES_ADD_COMPONENT
        }
      >
        <Button
          variant={"contained"}
          color={"primary"}
          startIcon={<Plus />}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default TableHeader;
