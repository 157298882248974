import { ICatalog, TimeZoneCatalog } from "../enums/TimeZoneEnum";
import { get } from "lodash";

export interface ITabHeader {
  index: number;
  label: string;
}
export enum TabsTitle {
  REGISTRO_APROVACION = "Registro de aprobación",
  SOLICITUD_REGLA = "Solicitud de reglas",
  HISTORICO_SOLICITUDES = "Histórico de solicitudes",
}

export const TabsTable: ITabHeader[] = [
  { index: 0, label: TabsTitle.REGISTRO_APROVACION },
  { index: 1, label: TabsTitle.SOLICITUD_REGLA },
  { index: 2, label: TabsTitle.HISTORICO_SOLICITUDES },
];

const _timeZoneDefault: string = "America/Guayaquil";

const timeZoneHour = (text: string | undefined, value: string): string => {
  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  const index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
};

export const tzRegion = timeZoneHour(
  localStorage.getItem("timeZone")!,
  "region"
);
export const tzUTC = timeZoneHour(localStorage.getItem("timeZone")!, "utc");

export enum TimeLineConstants {
  DISABLED_REQUEST = "Solicitud rechazada por",
  DATE_REQUEST = "Fecha de Solicitud:",
  REASON = "Motivo:",
  OK = "Entendido",
  VIEW_DETAIL = "Ver detalle",
}
