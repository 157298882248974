import { defaultTo, get } from "lodash";
import { LOCALSTORAGE } from "../enums/LocalStorage_Enum";
import { IPayload } from "../interfaces/IPayload";

const _getPayload = (): IPayload => {
  return JSON.parse(
    defaultTo(localStorage.getItem(LOCALSTORAGE.PAYLOAD), "{}")
  );
};

export const isMaster = () => {
  const groups: string[] = get(_getPayload(), "[cognito:groups]", [""]);

  return groups.includes("BackofficeUserMaster");
};
