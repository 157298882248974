import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const codeSnippetStyles = createNamedStyles({
  container: {
    backgroundColor: "black",
    borderRadius: "3px",
    fontFamily: '"IBM Plex Mono", "Fira code", "Fira Mono", monospace',
    fontSize: "1rem",
    display: "inline-grid",
    ".preSnippet": {
      span: {
        color: "white",
      },
    },
  },
});
