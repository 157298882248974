import React from "react";
import { ModalBody as ModalBodyMolecules } from "@kushki/frontend-molecules/modal-body";
import { Chip, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SecurityRule } from "../../../../types/security_rule";
import { defaultTo, get, omit, isEmpty } from "lodash";
import { splitString, translateRuleKeys } from "../../../shared/utils";
import {
  ACTION_VALUES,
  GeneralRuleDetailConstants,
  MAP_OPERATORS_VALUES,
  SecurityRulesLabelField,
  SecurityRulesSubtitleSection,
  SPEED_PERIODICITY_VALUES,
  SPEED_TRANSACTION_STATUS_VALUES,
  STRICT_CONDITION_CARD_COUNTRY_VALUES,
} from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { ModalContainerType } from "../../ModalContainer/ModalContainer";

export interface GeneralRuleDetailsProps {
  data: SecurityRule;
  type: ModalContainerType;
  isMobile: boolean;
}

interface IGroups {
  target: string;
  translation: string;
  targetCondition: string;
  targetValue: string;
}

const groupsAdditionalValues: IGroups[] = [
  {
    target: "transactionStatusSum",
    translation: "Suma",
    targetCondition: "periodicitySum",
    targetValue: "maxAmount",
  },
  {
    target: "transactionStatusCount",
    translation: "Conteo",
    targetCondition: "periodicityCount",
    targetValue: "limit",
  },
];

const GeneralRuleDetails: React.FC<GeneralRuleDetailsProps> = (
  props: GeneralRuleDetailsProps
) => {
  const useStyles = makeStyles(() =>
    createStyles({
      paper: {
        paddingBottom: 15,
        backgroundColor: "#F7FAFC !important",
        borderRadius: 3,
        boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
        width: "100%",
      },
      container: {
        paddingTop: 15,
        paddingLeft: 18,
        paddingRight: 18,
      },
      chip: {
        color: "#293036 !important",
        backgroundColor: "#F7FAFC !important",
        borderRadius: "4px !important",
        marginRight: "8px !important",
        marginBottom: "8px !important",
      },
      bodyContainer: {
        "& .modal-body-container": {
          width: props.isMobile ? "85vh" : "auto",
        },
      },
    })
  );
  const classes = useStyles();
  const mainRulesValues: object = omit(get(props.data, "rule", {}), [
    "name",
    "action",
    "code",
    "message",
    "partnerValidator",
    "limit",
    "maxAmount",
    "periodicitySum",
    "periodicityCount",
    "transactionStatusSum",
    "transactionStatusCount",
  ]);
  const additionalRulesValues: object = omit(get(props.data, "rule", {}), [
    "name",
    "action",
    "code",
    "message",
    "partnerValidator",
    "transactionBank",
    "transactionBrand",
    "transactionIsDeferred",
    "transactionProcessor",
    "transactionBIN",
    "transactionCountry",
    "transactionIsCreditCard",
    "transactionIP",
    "transactionMaskedCreditCard",
    "transactionTotalAmount",
    "transactionCurrency",
    "transactionCardIssuer",
    "transactionType",
  ]);

  return (
    <React.Fragment>
      <div className={classes.bodyContainer}>
        <ModalBodyMolecules>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              {props.type === "commerce" ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <Typography variant={"subtitle1"} color={"textSecondary"}>
                      {GeneralRuleDetailConstants.COMMERCE}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Typography style={{ paddingTop: 2 }} color={"primary"}>
                      {get(props.data, "merchantName", "N/A")}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle1"} color={"textSecondary"}>
                      {GeneralRuleDetailConstants.COUNTRY}
                    </Typography>
                  </Grid>
                  {defaultTo(props.data.country, []).map(
                    (item: string, index: number) => (
                      <Chip
                        key={`chip${index}`}
                        label={item}
                        className={classes.chip}
                      />
                    )
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className={classes.container}
            style={{ paddingBottom: 15 }}
          >
            <Grid item xs={12}>
              <Typography style={{ fontSize: 15 }} color={"textPrimary"}>
                {`${translateRuleKeys(
                  get(props.data, "rule.action", ""),
                  ACTION_VALUES
                )}: `}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Paper
              elevation={0}
              className={classes.paper}
              style={{ paddingRight: 0 }}
            >
              <Grid
                container
                xs={12}
                spacing={2}
                className={classes.container}
                style={{ paddingRight: 0 }}
              >
                <Grid item xs={12}>
                  <Typography variant={"subtitle2"} color={"primary"}>
                    {SecurityRulesSubtitleSection.STRICT_CONDITION_HEADER}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.LABEL}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.OPERATOR}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.VALUE}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <Divider />
                </Grid>
                {Object.keys(defaultTo(mainRulesValues, {})).map(
                  (key: string, index: number) => (
                    <Grid item xs={12} key={`container${index}`}>
                      <Grid
                        container
                        spacing={2}
                        key={`container${key}`}
                        wrap="nowrap"
                      >
                        <Grid item xs={4} key={`label${key}`}>
                          <Typography
                            variant={"subtitle2"}
                            color={"textPrimary"}
                          >
                            {translateRuleKeys(
                              key,
                              STRICT_CONDITION_CARD_COUNTRY_VALUES
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} key={`operator${key}`}>
                          <Typography
                            variant={"subtitle2"}
                            color={"textPrimary"}
                          >
                            {translateRuleKeys(
                              splitString(
                                "|",
                                get(props.data, `rule.${key}`, ""),
                                0
                              ),
                              MAP_OPERATORS_VALUES
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} key={`value${key}`} zeroMinWidth>
                          <Typography
                            variant={"subtitle2"}
                            color={"textPrimary"}
                            style={{ overflowWrap: "break-word" }}
                          >
                            {splitString(
                              "|",
                              get(props.data, `rule.${key}`, ""),
                              1
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid
            container
            spacing={2}
            className={classes.container}
            style={{ paddingLeft: "0px !important" }}
          >
            <Paper
              style={{ marginTop: "20px", paddingRight: 0 }}
              elevation={0}
              className={classes.paper}
            >
              <Grid
                container
                spacing={2}
                className={classes.container}
                style={{ paddingRight: 0 }}
              >
                <Grid item xs={12}>
                  <Typography variant={"subtitle2"} color={"primary"}>
                    Condiciones Adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.ACTION}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.PERIODICITY}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.TRANSACTION}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {SecurityRulesLabelField.VALUE}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <Divider />
                </Grid>
                {!isEmpty(additionalRulesValues) &&
                  groupsAdditionalValues.map(
                    (item: IGroups, index: number) =>
                      !isEmpty(get(additionalRulesValues, item.target)) && (
                        <Grid item xs={12} key={`container${index}`}>
                          <Grid
                            container
                            spacing={2}
                            key={`containerItem${index}`}
                          >
                            <Grid item xs={3} key={`label${index}`}>
                              <Typography
                                variant={"subtitle2"}
                                color={"textPrimary"}
                              >
                                {item.translation}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} key={`operator${index}`}>
                              <Typography
                                variant={"subtitle2"}
                                color={"textPrimary"}
                              >
                                {translateRuleKeys(
                                  get(
                                    additionalRulesValues,
                                    item.targetCondition
                                  ),
                                  SPEED_PERIODICITY_VALUES
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} key={`statusTrx${index}`}>
                              <Typography
                                variant={"subtitle2"}
                                color={"textPrimary"}
                              >
                                {translateRuleKeys(
                                  get(additionalRulesValues, item.target),
                                  SPEED_TRANSACTION_STATUS_VALUES
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={3} key={`value${index}`}>
                              <Typography
                                variant={"subtitle2"}
                                color={"textPrimary"}
                              >
                                {get(additionalRulesValues, item.targetValue)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                  )}
              </Grid>
            </Paper>
          </Grid>
        </ModalBodyMolecules>
      </div>
    </React.Fragment>
  );
};

export default GeneralRuleDetails;
