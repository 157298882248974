import React, { FC, useMemo } from "react";
import { useTableContext } from "../TableContext";
import { ITableHeaderProps } from "../TableSimple/interfaces";
import { ITableHeadSimpleProps } from "./interfaces";
import { CellHeaderProps } from "../../TableCell/CellHeader/interfaces";
import { CellHeader } from "../../TableCell/CellHeader/CellHeader";
import { TableTitle } from "../TableTitle/TableTitle";

const TableHeadSimple: FC<ITableHeadSimpleProps> = ({
  headers,
  setAllCheck,
}: ITableHeadSimpleProps) => {
  const {
    state: { allowDeletion, allowSelection, selectedRows, selectAllState },
    handler: { handleCheckMassive },
  } = useTableContext();

  const filteredHeaders: ITableHeaderProps = useMemo(() => {
    if (allowSelection && headers.cells.length > 0)
      headers = {
        ...headers,
        cells: [
          {
            align: "left",
            checkProps: {
              isDisabled: headers.headerProps.isDisable,
              label: headers.headerProps.labelCheck,
              onChecked: () => {
                if (setAllCheck) setAllCheck(true);
                handleCheckMassive();
              },
              state: selectAllState,
            },
            spacing: 0,
            type: "check",
          },
          ...headers.cells,
        ],
      };

    if (allowDeletion && headers.cells.length > 0)
      headers = {
        ...headers,
        cells: [
          ...headers.cells,
          {
            align: "center",
            spacing: 0,
            type: "default",
          },
        ],
      };

    return headers;
  }, [headers, allowSelection, selectedRows, allowDeletion]);

  return (
    <TableTitle {...headers.headerProps}>
      {filteredHeaders.cells.map((item: CellHeaderProps, index: number) => (
        <CellHeader {...item} key={`cell-header-${index}`} />
      ))}
    </TableTitle>
  );
};

export { TableHeadSimple };
