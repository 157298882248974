import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainSubHeaderStyles = createNamedStyles({
  divider: {
    border: "1px solid #98A8B8",
  },
  flagPadding: {
    paddingX: 2,
  },
  gridTitle: {
    paddingBottom: 1.5,
    paddingTop: 3,
  },
  gridSubTitle: {
    display: "flex",
  },
  gridSubTitleRight: {
    display: "flex",
    justifyContent: "end",
  },
  gridText: {
    paddingRight: 2,
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 3,
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.7,
  },
  title: {
    color: "text.primary",
    fontSize: 26,
    variant: "h1",
    fontWeight: 600,
    marginTop: "auto",
    marginBottom: "auto",
  },
  titleRight: {
    color: "text.grey",
    fontSize: 13,
    variant: "h1",
    fontWeight: 400,
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "15px",
    textAlign: "right",
  },
  titleRightValue: {
    color: "text.grey",
    fontSize: 12,
    variant: "h1",
    fontWeight: 400,
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "right",
  },
});
