import React, { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CatalogDetailProps } from "./CatalogDetail.interfaces";
import {
  CatalogPropertyEnum,
  CatalogsRecord,
} from "../../shared/infrastructure/Enums/CatalogEnum";
import { Data } from "../../../types/catalog";
import useStyles from "./CatalogDetail.styles";
import { CatalogsNameEnum } from "../../shared/infrastructure/Enums/CatalogsNameEnum";
import { Edit, Trash2 } from "react-feather";
import { useCatalogDetailState } from "./state/useCatalogDetailState";
import { CatalogsTextEnum } from "../../shared/infrastructure/Enums/CatalogsTextEnum";
import { get } from "lodash";

export const CatalogDetail: React.FC<CatalogDetailProps> = (
  props: CatalogDetailProps
) => {
  const {
    isLoading,
    handleDeleteItem,
    handleEditItem,
  } = useCatalogDetailState();
  const { catalog } = props;
  const validateTab = (): boolean =>
    get(catalog, CatalogPropertyEnum.NAME) === CatalogsNameEnum.TAX_PAYER_TYPE;
  const [openTab, setOpenTab] = useState<boolean>(validateTab());
  const classes = useStyles();
  const handleOpenTab = (value: boolean) => {
    setOpenTab(!value);
  };

  return (
    <ExpansionPanel className={classes.panel} expanded={openTab}>
      <ExpansionPanelSummary
        className={classes.head}
        expandIcon={<ExpandMoreIcon />}
        onClick={() => handleOpenTab(openTab)}
      >
        <Typography gutterBottom className={classes.titlePanel} variant="h6">
          {CatalogsRecord[catalog.name]}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        {catalog.data ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell colSpan={2}>
                    {CatalogsTextEnum.DESCRIPTION}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {catalog.data.map((data: Data) => (
                  <TableRow key={data.code}>
                    <TableCell>{data.code}</TableCell>
                    <TableCell>{data.value}</TableCell>
                    <TableCell className={classes.iconCol} align={"center"}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          handleEditItem(catalog.id, data.value, data.code)
                        }
                        disabled={isLoading}
                      >
                        <Edit className={classes.editIcon} />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          handleDeleteItem(
                            catalog.name,
                            catalog.id,
                            data.value,
                            data.code
                          )
                        }
                        disabled={isLoading}
                      >
                        <Trash2 className={classes.trashIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid className={classes.emptyContainer}>
            <Typography className={classes.emptyMessage} variant={"h6"}>
              No existen opciones configuradas. Asegúrate de cargar los
              catálogos de impuestos y <br />
              retenciones para {CatalogsRecord[catalog.name]}
            </Typography>
          </Grid>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
