import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import theme from "../../theme";

export const formWrapperStyles = createNamedStyles({
  buttonTitle: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
  },
  divider: {
    borderStyle: "solid",
    mb: 4,
    mt: 2,
  },
  formWrapper: {
    bgcolor: theme.palette.background.default,
    borderRadius: 4,
    minHeight: 349,
    px: 8,
    py: 6,
  },
});
