export enum BillingInfoEnums {
  ACCOUNT_BANK = "Cta. Bancaria",
  ACCOUNT_TYPE = "Tipo de Cuenta",
  ADDRESS = "Dirección",
  BANK = "Banco",
  CITY = "Ciudad",
  CURRENCY = "Moneda",
  DEPARTMENT = "Departamento",
  FREQUENCY = "Frecuencia",
  MAIL = "Email",
  MINIMUM_CHARGE = "Cobro Mínimo",
  PHONE = "Teléfono",
  PROVINCE = "Provincia",
  SOCIAL_REASON = "Razón Social",
  TAX_ID = "TAX ID",
}
