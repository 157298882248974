import { MerchantSettingsResponse } from "../../types/merchant_settings_response";
import { PayoutMerchantResponse } from "../../types/payout_merchant_response";
import { ErrorCodeEnum } from "../shared/infrastructure/enums/ErrorCodeEnum";
import { ActionTypes } from "./actionTypes";
import { IAppState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AxiosResponse } from "axios";
import { FilterWalletDashboardResponse } from "../../types/filter_wallet_dashboard_response";
import { FilterWalletDashboardRequest } from "../../types/filter_wallet_dashboard_request";
import { BalanceResponse } from "../../types/balance_response";
import axios from "../shared/axios-util";
import { defaultTo, get, isEmpty } from "lodash";
import { DownloadRequest } from "../../types/download_request";
import { IDownloadErrorType } from "../shared/infrastructure/interfaces/IDownloadError";
import { environment } from "../environments/environment";
import { SearchTypeEnum } from "../shared/infrastructure/enums/SearchTypeEnum";
import { CreateReportRequest } from "../../types/create_report_request";

export type IAppAction = { type: string } & Partial<IAppState>;

const basePathUsrv: string = "wallet/v1";

export const setNotification = (payload: INotification) => ({
  notification: payload,
  type: ActionTypes.SET_NOTIFICATION,
});

export const setIsPayoutMerchant = (
  payload: PayoutMerchantResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_PAYOUT_MERCHANT,
    merchantPayout: payload,
  };
};

export const setIsLoadingGetTransactions = (payload: boolean): IAppAction => ({
  isLoadingGetTransactions: payload,
  type: ActionTypes.SET_IS_LOADING_GET_TRANSACTIONS,
});

export const setIsDownloadingFile = (payload: boolean): IAppAction => ({
  isDownloadingFile: payload,
  type: ActionTypes.SET_IS_DOWNLOADING_FILE,
});

export const setSearchType = (payload: SearchTypeEnum): IAppAction => ({
  searchType: payload,
  type: ActionTypes.SET_SEARCH_TYPE,
});

export const setIsReportGenerated = (payload: boolean): IAppAction => ({
  isReportGenerated: payload,
  type: ActionTypes.SET_IS_REPORT_GENERATED,
});

export const setIsRangeDateError = (payload: boolean): IAppAction => ({
  isDateRangeError: payload,
  type: ActionTypes.SET_IS_DATE_RANGE_ERROR,
});

export const setGetTransactionsResponse = (
  payload: FilterWalletDashboardResponse
): IAppAction => ({
  getTransactionsResponse: payload,
  type: ActionTypes.SET_WALLET_DASHBOARD_RESPONSE,
});

export const setShowAlertOnGetTransactions = (
  payload: boolean
): IAppAction => ({
  showAlertOnGetTransactions: payload,
  type: ActionTypes.SET_SHOW_ALERT_ON_GET_TRANSACTIONS,
});

export const setBalance = (payload: BalanceResponse) => ({
  balanceResponse: payload,
  type: ActionTypes.SET_BALANCE,
});

export const setShowAlertOnDownloadFileSuccess = (
  payload: boolean
): IAppAction => ({
  showAlertOnDownloadFileSuccess: payload,
  type: ActionTypes.SET_SHOW_ALERT_ON_DOWNLOAD_SUCCESS,
});

export const setShowAlertOnDownloadFileError = (
  payload: boolean
): IAppAction => ({
  showAlertOnDownloadFileError: payload,
  type: ActionTypes.SET_SHOW_ALERT_ON_DOWNLOAD_ERROR,
});

export const setFilterWalletDashboardRequest = (
  payload: FilterWalletDashboardRequest
): IAppAction => ({
  filterWalletDashboardRequest: payload,
  type: ActionTypes.SET_FILTER_WALLET_DASHBOARD_REQUEST,
});

export const setGetSignedUrlResponse = (payload: string): IAppAction => ({
  getSignedUrlResponse: payload,
  type: ActionTypes.SET_SIGNED_URL_RESPONSE,
});

export const getTransactions = (
  request: FilterWalletDashboardRequest,
  publicMerchantId?: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingGetTransactions(true));
    const merchantId: string | undefined | null = isEmpty(publicMerchantId)
      ? localStorage.getItem("merchantId")
      : publicMerchantId;
    const method: string = `${basePathUsrv}/transactions/${merchantId}`;

    axios
      .post<FilterWalletDashboardResponse>(
        `${environment.kushkiUrl}/${method}`,
        request
      )
      .then((axios_response: AxiosResponse<FilterWalletDashboardResponse>) => {
        const response: FilterWalletDashboardResponse = axios_response.data;

        dispatch(setGetTransactionsResponse(response));
        dispatch(setTotalRecords(defaultTo(response.total, 0)));
        dispatch(setIsLoadingGetTransactions(false));
      })
      .catch(() => {
        dispatch(setShowAlertOnGetTransactions(true));
        dispatch(setIsLoadingGetTransactions(false));
      });
  };
};

export const getBalanceValues =
  (merchantId: string): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get<BalanceResponse>(
        `${environment.kushkiUrl}/wallet/v1/balance/${merchantId}`
      )
      .then((axios_response: AxiosResponse<BalanceResponse>) => {
        dispatch(setBalance(axios_response.data));
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "Ha ocurrido un error al obtener valores del balance.",
            open: true,
            type: "error",
          })
        );
      });
  };

export const getMerchantSettings = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get<{ enable: boolean; publicCredentialId: string }[]>(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`
      )
      .then(
        (
          credentials: AxiosResponse<
            { enable: boolean; publicCredentialId: string }[]
          >
        ) => {
          let credential = merchantId;

          credentials.data.forEach(
            (data: { enable: boolean; publicCredentialId: string }) => {
              if (data.enable) {
                credential = data.publicCredentialId;
              }
            }
          );
          axios
            .get<MerchantSettingsResponse>(
              `${environment.kushkiUrl}/merchant/v1/merchant/settings`,
              {
                headers: {
                  "Public-Merchant-Id": credential,
                  Authorization: localStorage.getItem("jwt"),
                },
              }
            )
            .then((response: AxiosResponse<MerchantSettingsResponse>) => {
              dispatch(
                setIsPayoutMerchant({
                  isPayout:
                    get(response.data, "processors[payouts-cash]", []).length >
                      0 ||
                    get(response.data, "processors[payouts-transfer]", [])
                      .length > 0,
                  country: response.data.country,
                  merchantName: get(response, "data.merchant_name"),
                })
              );
            })
            .catch((err: Error) => {
              console.log("err: ", err);
            });
        }
      )
      .catch((err: Error) => {
        console.log("err: ", err);
      });
  };
};

export const setDownloadError = (
  payload: IDownloadErrorType | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_DOWNLOAD_ERROR,
    downloadError: payload,
  };
};

export const downloadReport = (
  request: CreateReportRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsDownloadingFile(true));

    axios
      .post<FilterWalletDashboardResponse>(
        `${environment.kushkiUrl}/wallet/v1/createReport`,
        request
      )
      .then(() => {
        dispatch(setIsRangeDateError(false));
        dispatch(setIsReportGenerated(true));
        dispatch(setIsDownloadingFile(false));
      })
      .catch(
        ({
          response: {
            data: { code },
          },
        }) => {
          if (code === ErrorCodeEnum.WLT044) {
            dispatch(setIsReportGenerated(true));
            dispatch(setIsDownloadingFile(false));
            dispatch(setIsRangeDateError(true));

            return;
          }
          dispatch(setShowAlertOnDownloadFileError(true));
          dispatch(setShowAlertOnDownloadFileSuccess(false));
          dispatch(setIsReportGenerated(false));
          dispatch(setIsDownloadingFile(false));
        }
      );
  };
};

export const getSignedUrl = (
  request: DownloadRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    try {
      dispatch(setIsDownloadingFile(true));
      const jwt: string = defaultTo(localStorage.getItem("jwt"), "");
      const webSocket = new WebSocket(
        `${environment.wsUrl}?Authorization=${jwt}`
      );

      webSocket.onopen = () => {
        webSocket.send(JSON.stringify(request));
        dispatch(setShowAlertOnDownloadFileSuccess(false));
        dispatch(setShowAlertOnDownloadFileError(false));
      };

      webSocket.onerror = () => {
        dispatch(setShowAlertOnDownloadFileError(true));
        dispatch(setShowAlertOnDownloadFileSuccess(false));
        dispatch(
          setDownloadError({
            code: "500",
            message: "",
            type: "Cannot download file",
          })
        );
        dispatch(setIsDownloadingFile(false));
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response = JSON.parse(event.data);
        const isError: boolean = get(response, "payload.error", true);

        switch (isError) {
          case true:
            dispatch(setShowAlertOnDownloadFileError(true));
            dispatch(setShowAlertOnDownloadFileSuccess(false));
            dispatch(
              setDownloadError({
                code: "500",
                message: get(response, "payload.message", "Unexpected error"),
                type: "Cannot download file",
              })
            );
            dispatch(setIsDownloadingFile(false));
            break;
          case false:
            const status: string = get(response, "status", "ERROR");

            if (status === "COMPLETED_EMPTY") {
              dispatch(setShowAlertOnDownloadFileError(true));
              dispatch(setShowAlertOnDownloadFileSuccess(false));
              dispatch(
                setDownloadError({
                  code: "500",
                  message: get(response, "payload.message", "Unexpected error"),
                  type: "Cannot download file",
                })
              );
              dispatch(setIsDownloadingFile(false));
              break;
            }

            if (status === "PROCESSING") {
              dispatch(setIsDownloadingFile(true));
              break;
            } else {
              dispatch(
                setGetSignedUrlResponse(get(response, "payload.url", ""))
              );
              dispatch(setShowAlertOnDownloadFileError(false));
              dispatch(setShowAlertOnDownloadFileSuccess(true));
              dispatch(setIsDownloadingFile(false));
            }
            break;
        }
      };

      // webSocket.close();
    } catch (e) {
      dispatch(setShowAlertOnDownloadFileError(true));
      dispatch(setShowAlertOnDownloadFileSuccess(false));
      dispatch(setIsDownloadingFile(false));
      console.error(e);
    }
  };
};

export const setTableSize = (tableSize: number): IAppAction => {
  return {
    type: ActionTypes.SET_TABLE_SIZE,
    tableSize: tableSize,
  };
};

export const setTotalRecords = (totalRecords: number): IAppAction => {
  return {
    type: ActionTypes.SET_TOTAL_RECORDS,
    totalRecords: totalRecords,
  };
};

export const setTablePage = (currentPage: number): IAppAction => {
  return {
    type: ActionTypes.SET_CURRENT_PAGE,
    currentPage: currentPage,
  };
};
