import { DateRange } from "@material-ui/pickers";
import { format } from "date-fns-tz";

const formatDate = (trxDate: number, formatDate: string): string => {
  const date: string = new Date(
    trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
  ).toISOString();

  return format(new Date(date.replace("Z", "")), formatDate, {
    timeZone: "UTC",
  });
};

const formatDateFromString = (trxDate: string, formatDate: string): string => {
  return format(new Date(trxDate?.replace("Z", "")), formatDate, {
    timeZone: "UTC",
  });
};
const currentDate = new Date();

const getDateFixed = (date: Date | null) =>
  date ? date.toDateString().slice(0, 16) : "";

const isValidDates = (
  dateRange: DateRange<Date>,
  dayBefore: Date,
  nowDate: Date
) =>
  getDateFixed(dateRange[0]) === getDateFixed(dayBefore) &&
  getDateFixed(dateRange[1]) === getDateFixed(nowDate);

const getDayBefore = () => {
  const now = new Date();

  now.setDate(now.getDate() - 2);

  return now;
};
const getMinDate = (): Date => {
  const now = new Date();

  now.setDate(now.getDate() - 180);

  return now;
};

const getYesterday = (): Date => {
  const now = new Date();

  now.setDate(now.getDate() - 1);

  return now;
};

const getCurrentDay = (): Date => {
  const now = new Date();

  now.setDate(now.getDate());

  return now;
};

export {
  formatDate,
  isValidDates,
  getDayBefore,
  getMinDate,
  currentDate,
  getYesterday,
  getCurrentDay,
  formatDateFromString,
};
