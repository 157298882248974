import { Box, Card, CardContent, Typography } from "@mui/material";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";
import {
  CardListContainer,
  cardListStyles,
} from "../CardListContainer/CardListContainer";

import { useBillingData } from "./state/useBillingData";

const BillingData: React.FC = () => {
  const {
    billingInfo,
    isLoading,
    items,
    itemsDian,
    itemsSat,
    showBillingDetail,
  } = useBillingData();

  return (
    <>
      {isLoading ? null : (
        <>
          {!showBillingDetail ? (
            <MissingInfoAlert />
          ) : (
            <>
              <CardListContainer
                items={items}
                title="Información de facturación"
              />
              <>
                {billingInfo?.constitutionalCountry === "Mexico" ? (
                  billingInfo?.useSameInfoSat ? (
                    <>
                      <Box
                        bgcolor="neutral.grey3"
                        borderRadius="8px"
                        sx={cardListStyles.cardList}
                      >
                        <Card
                          elevation={0}
                          sx={{
                            backgroundColor: "transparent",
                            padding: "8px",
                          }}
                        >
                          <CardContent>
                            <Typography
                              component="h6"
                              color="primary.main"
                              fontWeight={500}
                              pb={2}
                              fontSize="1rem"
                            >
                              Información SAT
                            </Typography>
                            <Typography component="p" color="neutral.grey6">
                              Utiliza la misma información que facturación
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </>
                  ) : (
                    <>
                      <CardListContainer
                        items={itemsSat}
                        title="Información SAT"
                      />
                    </>
                  )
                ) : (
                  <></>
                )}
              </>
              <>
                {billingInfo?.constitutionalCountry === "Colombia" ? (
                  <CardListContainer
                    items={itemsDian}
                    title="Información DIAN"
                  />
                ) : (
                  <></>
                )}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BillingData;
