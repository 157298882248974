import React from "react";
import { Box, Button, Card, CardContent } from "@material-ui/core";
import { useStateApp } from "../../shared/state/useStateApp";
import { ConfigDeferredHeader } from "../ConfigDeferredHeader/ConfigDeferredHeader";
import { makeStyles } from "@material-ui/core/styles";
import { ConfigMonthSection } from "../ConfigMonthSection/ConfigMonthSection";
import { useCentralAmericaSection } from "./state/useCentralAmericaSectionState";

const useStyles = makeStyles(() => ({
  cancelButton: {
    background: "#F7FAFC",
    border: "1px solid #023365",
    color: "#023365",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "10px",
    paddingBottom: "15px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "15px",
  },
  link: {
    color: "#023365",
    display: "flex",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
  },
}));

export const CentralAmericaSection = () => {
  const classes = useStyles();

  const { isMobile } = useStateApp();
  const {
    brandList,
    type,
    months,
    merchantMonths,
    handleCancel,
    handleSave,
    handleSetMonth,
  } = useCentralAmericaSection();

  return (
    <>
      <Card>
        <CardContent>
          <ConfigDeferredHeader
            isMobile={isMobile}
            brandList={brandList}
            type={type}
          />
          <ConfigMonthSection
            disableToggleMonths={false}
            mainTittle={"Cuotas"}
            mainMonths={months}
            mainMerchantMonths={merchantMonths}
            isMobile={isMobile}
            handleSetMonth={handleSetMonth}
          />
        </CardContent>
      </Card>
      <Box pt={3} display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Box pl={2}>
          <Button
            variant="outlined"
            size="large"
            onClick={handleCancel}
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
        </Box>
        <Box pl={2}>
          <Button
            size="large"
            variant="contained"
            color={"primary"}
            onClick={handleSave}
            disabled={false}
          >
            Guardar Diferido
          </Button>
        </Box>
      </Box>
    </>
  );
};
