import { useLocation } from "react-router";
import { QueryEnum } from "../enums/QueryEnum";

export function searchQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function searchParams(): { isEdit: boolean; publicMerchantId: string } {
  const query: URLSearchParams = searchQuery();

  return {
    isEdit: (query.get(QueryEnum.OPERATION) as string) === QueryEnum.EDIT,
    publicMerchantId: query.get(QueryEnum.PUBLIC_MERCHANT_ID) as string,
  };
}
