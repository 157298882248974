export enum SessionStoragePathEnum {
  BRANCH_EDIT_LIST = "branchEditList",
  BRANCH_LIST = "branchList",
}

export enum BranchEditListPathEnum {
  DATA = "data",
}

export enum BranchPathEnum {
  CENTRALIZED_NODES_ID = "centralizedNodesId",
  VALUE = "value",
  STATUS = "status",
}
