export const PAYMENT_MONTHS: string[] = [
  "3",
  "6",
  "9",
  "12",
  "15",
  "18",
  "24",
  "36",
  "48",
];
export const BRAZIL_PAYMENT_MONTHS: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
export const GRACE_MONTHS: string[] = ["1", "2", "3", "4", "5", "6"];
