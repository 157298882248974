import { get, isEmpty } from "lodash";
import { IOptionFilter, OptionItem } from "../../../types/option_filter";
import { CountryEnum } from "../infrastructure/enums/CountryEnum";
import { FilterTypeEnum } from "../infrastructure/enums/FilterTypeEnum";
import { IFilter } from "../../containers/RefundIndex/state/useRefundIndexState.interfaces";
import { PaymentMethodEnum } from "../infrastructure/enums/PaymentMethodEnum";
import { RangeAmount } from "../../../types/range_amount";
import { TabConstants } from "../infrastructure/constants/TabConstants";

export const getDataRequestBody = (value: {
  country: CountryEnum;
  paymentMethod: PaymentMethodEnum;
  limit?: number;
  offset?: number;
  filter?: object;
  from?: string;
  generalField?: string;
  to?: string;
  stateRefund?: string;
}): object => {
  const filter: object = get(value, "filter", {});
  const stateRefund: string | undefined = get(value, "stateRefund", undefined);

  return {
    country: get(value, "country"),
    filter: {
      ...filter,
      stateRefund: get(filter, "stateRefund", stateRefund),
    },
    from: get(value, "from", undefined),
    generalField: !isEmpty(value.generalField)
      ? get(value, "generalField")
      : undefined,
    limit: get(value, "limit", undefined),
    offset: get(value, "offset", undefined),
    paymentMethod: get(value, "paymentMethod"),
    sort: {
      field: "updateAt",
      order: "asc",
    },
    to: get(value, "to", undefined),
  };
};

export const buildFilterByRequestBody = (
  filters: IOptionFilter[],
  rangeAmount?: RangeAmount
): object => {
  const response: object = {};

  filters.map((item) => {
    item.options.map((option: OptionItem) => {
      if (option.selected) {
        if (item.id === FilterTypeEnum.actionResponse) {
          response[item.primaryId!] = option.secondaryKey;
          response[FilterTypeEnum.action] = option.primaryKey;
        } else response[item.id] = option.key;
      }
    });
  });

  if (rangeAmount && rangeAmount.value) {
    response["rangeAmount"] = rangeAmount;
  }

  return response;
};

export const buildFilterObject = (filters: IOptionFilter[]): object => {
  const response: IFilter = {};

  filters.map((filter) => {
    filter.options.map((option) => {
      response[option.key] = false;
    });
  });

  return response;
};

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const dynamicSort = (property: string, activeTab: TabConstants) => {
  let sortOrder: number = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  if (activeTab === TabConstants.HISTORY) property = camelToSnakeCase(property);

  return function (a: object, b: object) {
    const result: number =
      get(a, property, 0) < get(b, property, 0)
        ? -1
        : get(a, property, 0) > get(b, property, 0)
        ? 1
        : 0;

    return result * sortOrder;
  };
};

export const emptyFunction = () => {};
