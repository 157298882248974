import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { ButtonConstants } from "../../shared/infrastructure/constants/ButtonConstants";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { ConfirmModal } from "../Modals/ConfirmModal/ConfirmModal";
import { ConfirmModalMessageEnums } from "../../shared/infrastructure/Enums/ConfirmModalMessageEnums";
import { environment } from "../../environments/environment";
import { FileChooser } from "../common/FileChooser/FileChooser";
import { get } from "lodash";
import { IFileUpload } from "../../containers/ConfigByFileIndex/state/useConfigByFileIndexState.interfaces";
import { Modal } from "../../shared/infrastructure/interfaces/Modal";
import React from "react";
import step1 from "../../assets/images/step1.svg";
import step2 from "../../assets/images/step2.svg";
import { UploadCardConstants } from "../../shared/infrastructure/constants/UploadCardConstants";
import { uploadCardStyles } from "./UploadCard.styles";

export interface UploadCardProps {
  fileUploader: IFileUpload;
  textReplace: string;
  country?: string;
  modal: Modal;
}

export const downloadTemplateExample = () => {
  const target: HTMLAnchorElement = document.createElement("a");
  const url: string = environment.s3UrlTemplate;

  target.hidden = true;
  target.href = url;

  document.body.appendChild<HTMLAnchorElement>(target);
  target.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(target);
};

export const UploadCard = (props: UploadCardProps) => {
  const { textReplace, fileUploader } = props;

  const classes = uploadCardStyles();

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader />
        <CardContent component={Box} className={classes.gridContainer}>
          <Grid container spacing={3} direction="row" justify="space-evenly">
            <Grid item xs container>
              <Grid item xs={12}>
                <Grid item xs={2} className={classes.spaceGrid}>
                  <Box>
                    <img src={step1} alt="csvImage" />
                  </Box>
                </Grid>
                <Typography
                  variant="h4"
                  align="left"
                  color="primary"
                  className={classes.spaceGrid}
                >
                  <b>{UploadCardConstants.CARD_FIRST_TITLE}</b>
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.spaceGrid}
                >
                  <span>
                    Descarga este <b>archivo .xlsx </b> Te recomendamos abrir el
                    archivo en
                    <strong> Google Sheets</strong> y exportarlo en formato
                    <strong> .csv</strong> para que el sistema lo reconozca.
                  </span>
                </Typography>
                <Button
                  variant="contained"
                  color={"primary"}
                  startIcon={<CloudDownloadOutlined />}
                  size={"medium"}
                  onClick={downloadTemplateExample}
                >
                  {ButtonConstants.BTN_DOWNLOAD}
                </Button>
              </Grid>
            </Grid>
            <ConfirmModal
              open={props.modal.openAlertConfirmModal}
              titleText={ConfirmModalMessageEnums.TITLE_TEXT}
              contentText={`${props.modal.dispersionRecords} ${ConfirmModalMessageEnums.CONTENT_TEXT}`}
              acceptText={ConfirmModalMessageEnums.ACCEPT_TEXT}
              handleAccept={props.modal.handelActionModal}
            />
            <Divider orientation="vertical" variant="middle" flexItem />

            <Grid container item xs spacing={2} justify={"center"}>
              <Grid item xs={12}>
                <Grid item xs={2} className={classes.spaceGrid}>
                  <Box>
                    <img src={step2} alt="csvImage" />
                  </Box>
                </Grid>
                <Typography
                  variant="h4"
                  align="left"
                  color="primary"
                  className={classes.spaceGrid}
                >
                  <b>{UploadCardConstants.CARD_SECOND_TITLE}</b>
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.spaceGrid}
                >
                  {textReplace}
                </Typography>
                <FileChooser
                  fileName={get(fileUploader, "file.name", "")}
                  onChange={fileUploader.handleCaptureFile}
                  disableUpload={fileUploader.handleDisableUpload}
                  onDelete={fileUploader.handleDelete}
                />
                <Typography variant="body1" color="textSecondary">
                  {UploadCardConstants.CARD_SECOND_DESC}
                </Typography>
              </Grid>
            </Grid>
            <br />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
