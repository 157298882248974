import React from "react";
import {
  IListDownloadButtonProps,
  ITransitionProps,
} from "../ListDownloadButton/ListDownloadButton.interfaces";
import ListDownloadButton from "../ListDownloadButton/ListDownloadButton";

export const callbackListDownload =
  (props: IListDownloadButtonProps) =>
  ({ TransitionProps }: { TransitionProps?: ITransitionProps }) => {
    const { handleClose, handleMenuItemClick, options, selectedIndex } = props;

    return (
      <ListDownloadButton
        handleClose={handleClose}
        handleMenuItemClick={handleMenuItemClick}
        id="split-button-menu"
        options={options}
        selectedIndex={selectedIndex}
        transitionProps={TransitionProps}
      />
    );
  };
