import { get, set } from "lodash";
import {
  MODAL_ACTIONS_ERROR_MESSAGE,
  MODAL_ACTIONS_SUCCESS_MESSAGE,
  ActionRefundEnum,
} from "../shared/infrastructure/enums/ActionEnum";
import {
  ProcessTransactionMessagesEnum,
  ProcessTransactionMessagesSuccessEnum,
  ProcessTransactionMessagesFailedEnum,
} from "../shared/infrastructure/interfaces/ProcessTransactionMessagesEnum";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "./actionTypes";
import { AnalysisStateEnum } from "../shared/infrastructure/constants/AnalysisStateEnum";
import { auth } from "../shared/utils/auth";
import axios from "../shared/utils/axios-util";
import { AxiosResponse } from "axios";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { dynamicSort } from "../shared/utils/buildFiltersObject";
import { environment } from "../environments/environment";
import { ErrorsWS } from "../shared/infrastructure/constants/RefundDashboardConstans";
import { IAppState } from "./reducer";
import { IDataWebsockets } from "../shared/infrastructure/interfaces/IDataWebSocket";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { OrderSort } from "../components/DashboardList/Table/DashboardPendingTable/DashboardTable.interfaces";
import { OrderSortEnum } from "../shared/infrastructure/constants/DashboardTableConstant";
import { ProcessTransactionRefundRequest } from "../../types/process_transaction_refund_request";
import { TabConstants } from "../shared/infrastructure/constants/TabConstants";
import { TransactionData } from "../../types/transaction_data";
import { TransactionHistoryTable } from "../../types/transaction_history_table";
import { TransactionTable } from "../../types/transaction_table";

export type IAppAction = {
  activeTab?: number;
  type: string;
  notification?: INotification;
  refundData?: TransactionTable;
  refundHistoryData?: TransactionTable;
  isLoadingRefundData?: boolean;
  dialogClosed?: boolean;
  updateData?: object;
  loadingMassive?: boolean;
  errorMassiveAsync?: boolean;
  errorMassiveAsyncResult?: boolean;
  actionMassiveTransaction?: string;
  wsResponse?: string;
  tab?: number;
};

export const setNotification = (payload: INotification) => ({
  notification: payload,
  type: ActionTypes.SET_NOTIFICATION,
});

export const setRefundData = (payload: TransactionTable) => {
  return {
    refundData: payload,
    type: ActionTypes.SET_REFUND_DATA,
  };
};

export const setRefundHistoryData = (payload: TransactionHistoryTable) => {
  return {
    refundHistoryData: payload,
    type: ActionTypes.SET_REFUND_HISTORY_DATA,
  };
};

export const setRefundTrxData = (payload: TransactionTable) => {
  return {
    refundData: payload,
    type: ActionTypes.SET_REFUND_TRX_DATA,
  };
};

export const setIsLoadingRefundsData = (payload: boolean): IAppAction => {
  return {
    isLoadingRefundData: payload,
    type: ActionTypes.SET_IS_LOADING_REFUND_DATA,
  };
};

export const setUpdateRefundTrx = (payload: object): IAppAction => ({
  type: ActionTypes.SET_UPDATE_TRX_STATUS,
  updateData: payload,
});

export const setActiveTabAction = (payload: number): IAppAction => {
  return {
    activeTab: payload,
    type: ActionTypes.SET_ACTIVE_TAB,
  };
};

export const setLoadingMassive = (payload: boolean): IAppAction => {
  return {
    loadingMassive: payload,
    type: ActionTypes.SET_LOADING_MASSIVE,
  };
};

export const setTab = (payload: number): IAppAction => {
  return {
    tab: payload,
    type: ActionTypes.SET_TAB,
  };
};

export const setErrorMassiveAsync = (payload: boolean): IAppAction => {
  return {
    errorMassiveAsync: payload,
    type: ActionTypes.SET_ERROR_MASSIVE_ASYNC,
  };
};

export const setErrorMassiveAsyncResult = (payload: boolean): IAppAction => {
  return {
    errorMassiveAsyncResult: payload,
    type: ActionTypes.SET_ERROR_MASSIVE_ASYNC_RESULT,
  };
};

export const setActionMassiveTransaction = (payload: string): IAppAction => {
  return {
    actionMassiveTransaction: payload,
    type: ActionTypes.SET_ACTION_MASSIVE_TRANSACTION,
  };
};

export const setWsResponse = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_WS_RESPONSE,
    wsResponse: payload,
  };
};

const showOpenErrorNotification =
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>) =>
  (errorMessage: string) => {
    dispatch(
      setNotification({
        horizontal: "right",
        icon: CloseIcon,
        message: errorMessage,
        open: true,
        type: "error",
        vertical: "top",
      })
    );
  };

export const generateDataFakeTransactions = (
  limit: number
): TransactionData[] => {
  const data_fake: TransactionData[] = [];

  for (let new_key: number = 0; new_key < limit; new_key++) {
    data_fake.push(
      {
        approvedTransactionAmount: 12,
        createAt: "2022-06-10T00:00:00Z",
        history: [
          {
            action: "OMT",
            analisisStatus: "PND",
            modifiedDate: "1610343466190",
            observation: "Modal description",
            stateRefund: "IVLDTN",
            user: "backoffice",
          },
          {
            action: "CNCL",
            analisisStatus: "PND",
            modifiedDate: "1610343466190",
            observation: "Modal description",
            stateRefund: "IVLDTN",
            user: "backoffice",
          },
        ],
        merchantId: "20000000108075730000",
        merchantName: "Merchant testDav",
        refundDashboardStatus: "test",
        request_amount: "12",
        requestAmount: 0,
        selected: true,
        stateRefund: AnalysisStateEnum.PNDRFN,
        transactionId: `6b09de77-bfaa-4d04-8999-fgsdg${new_key}-1`,
        transactionStatus: "declined",
        transactionType: "sale",
        updateAt: "2022-06-10T00:00:00Z",
      },
      {
        action: "APRRFN",
        approvedTransactionAmount: 13,
        createAt: "2022-06-10T00:00:00Z",
        history: [
          {
            action: "RPY",
            analisisStatus: "PND",
            modifiedDate: "1610343466190",
            observation: "Modal description",
            stateRefund: "IVLDTN",
            user: "backofficer",
          },
          {
            action: "GBCK",
            analisisStatus: "PND",
            modifiedDate: "16103434661903",
            observation: "Modal description",
            stateRefund: "IVLDTNe",
            user: "backoffice",
          },
        ],
        merchantId: "20000000108075730000",
        merchantName: "Merchant testDav",
        refundDashboardStatus: "test",
        requestAmount: 0,
        response: "Enviado al procesador",
        selected: true,
        stateRefund: AnalysisStateEnum.RTRY,
        transactionId: `6b09de77-bfaa-4d04-8999-fgsdg${new_key}-2`,
        transactionStatus: "approval",
        transactionType: "void",
        updateAt: "2022-06-10T00:00:00Z",
      },
      {
        approvedTransactionAmount: 14,
        createAt: "2022-06-10T00:00:00Z",
        expirationDate: "2022-06-10T00:00:00Z",
        history: [
          {
            action: "GBCK",
            analisisStatus: "PNDD",
            modifiedDate: "16103434661903",
            observation: "Modal descriptiondsd",
            stateRefund: "IVLDTNE",
            user: "backofficerere",
          },
          {
            action: "CNCL",
            analisisStatus: "PNDSAA",
            modifiedDate: "16103434661903232",
            observation: "Modal descriptionadwe",
            stateRefund: "IVLDTNDD",
            user: "backofficeewe",
          },
        ],
        merchantId: "20000000108075730000",
        merchantName: "Merchant testDav",
        refundDashboardStatus: "test",
        requestAmount: 0,
        selected: true,
        stateRefund: AnalysisStateEnum.NTHRZD,
        toExpireDate: "2022-06-10T00:00:00Z",
        transactionId: `6b09de77-bfaa-4d04-8999-fgsdg${new_key}-3`,
        transactionStatus: "initialized",
        transactionType: "deffered",
        updateAt: "2022-06-10T00:00:00Z",
      },
      {
        actionRefund: "Reembolso",
        approvedTransactionAmount: 15,
        createAt: "2022-06-10T00:00:00Z",
        history: [
          {
            action: "OMT",
            analisisStatus: "PNDD",
            modifiedDate: "16103434661903",
            observation: "Modal descriptiondsd",
            stateRefund: "IVLDTNE",
            user: "backofficerere",
          },
          {
            action: "CNCL",
            analisisStatus: "PNDSAA",
            modifiedDate: "16103434661903232",
            observation: "Modal descriptionadwe",
            stateRefund: "IVLDTNDD",
            user: "backofficeewe",
          },
        ],
        merchantId: "20000000108075730000",
        merchantName: "Merchant testDav",
        refundDashboardStatus: "test",
        requestAmount: 0,
        response_refund: "Aprobado por...",
        stateRefund: AnalysisStateEnum.AUTHORIZED,
        transactionId: `6b09de77-bfaa-4d04-8999-fgsdg${new_key}-3`,
        transactionStatus: "initialized",
        transactionType: "deffered",
        updateAt: "2022-06-10T00:00:00Z",
      },
      {
        action: "APRCNL",
        approvedTransactionAmount: 16,
        createAt: "2022-06-10T00:00:00Z",
        expirationDate: "2022-06-10T00:00:00Z",
        history: [
          {
            action: "RPY",
            analisisStatus: "PNDQW",
            modifiedDate: "16103434661902312",
            observation: "Modal descriptionres",
            stateRefund: "IVLDTNER",
            user: "backofficesr",
          },
          {
            action: "GBCK",
            analisisStatus: "PND",
            modifiedDate: "1610343466190",
            observation: "Modal description",
            stateRefund: "IVLDTN",
            user: "backoffice",
          },
        ],
        merchantId: "20000000108075730000",
        merchantName: "Merchant testDav",
        refundDashboardStatus: "test",
        requestAmount: 0,
        response: "Enviado por FinOps",
        selected: true,
        stateRefund: AnalysisStateEnum.NTHRZD,
        toExpireDate: "2022-06-10T00:00:00Z",
        transactionId: `6b09de77-bfaa-4d04-8999-fgsdg${new_key}-4`,
        transactionStatus: "initialized",
        transactionType: "refund",
        updateAt: "2022-06-10T00:00:00Z",
      },
      {
        action: "APRGBCK",
        approvedTransactionAmount: 17,
        createAt: "2022-06-10T00:00:00Z",
        expirationDate: "2022-06-10T00:00:00Z",
        history: [
          {
            action: "CNCL",
            analisisStatus: "PND",
            modifiedDate: "1610343466190",
            observation: "Modal description",
            stateRefund: "IVLDTN",
            user: "backoffice",
          },
          {
            action: "CNCL",
            analisisStatus: "PND",
            modifiedDate: "1610343466190",
            observation: "Modal description",
            stateRefund: "IVLDTN",
            user: "backoffice",
          },
        ],
        merchantId: "20000000108075730000",
        merchantName: "Merchant testDav",
        refundDashboardStatus: "test",
        requestAmount: 0,
        response: "Enviado por FinOps",
        selected: true,
        stateRefund: AnalysisStateEnum.NTHRZD,
        toExpireDate: "2022-06-10T00:00:00Z",
        transactionId: `6b09de77-bfaa-4d04-8999-fgsdg${new_key}-5`,
        transactionStatus: "initialized",
        transactionType: "refund",
        updateAt: "2022-06-10T00:00:00Z",
      }
    );
  }

  return data_fake;
};

export const setRefundDataRowTransaction =
  (
    conciliationDataRow: TransactionData
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>,
    getState: () => IAppState
  ): void => {
    const aux_data = { ...getState().refundData };
    const index: number = (aux_data.data as TransactionData[])!.findIndex(
      (item: TransactionData) =>
        get(item, "transactionId") === get(conciliationDataRow, "transactionId")
    );
    let new_data = [...aux_data.data!];

    if (index > -1) {
      new_data = Object.assign([], [...aux_data.data!], {
        [index]: conciliationDataRow,
      });
    }
    dispatch(
      setRefundTrxData({
        ...aux_data,
        data: new_data as TransactionData[],
      })
    );
  };

export const getRefundDataTransactions =
  (request: object) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const origin: string = "pending";
    const url: string = `${environment.kushkiUrl}/refund-conciliation/v1/refundTransaction/${origin}`;

    dispatch(setIsLoadingRefundsData(true));
    try {
      const resp: AxiosResponse = await axios.post(url, request);

      dispatch(
        setRefundData({
          data: get(resp, "data.data", []),
          total: get(resp, "data.total", 0),
        })
      );
      dispatch(setIsLoadingRefundsData(false));
    } catch (e) {
      dispatch(setIsLoadingRefundsData(false));
    }
  };

export const sortRefundData =
  (sort: OrderSort, refundData: TransactionTable, activeTab: TabConstants) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const sortOrder: string =
      get(sort, "order", OrderSortEnum.ASC) === OrderSortEnum.DESC ? "" : "-";

    const value = {
      data: get(refundData, "data", []).sort(
        dynamicSort(`${sortOrder}${sort.orderBy}`, activeTab)
      ),
      total: get(refundData, "total", 0),
    };

    const dispatchFunctions: object = {
      [TabConstants.PENDING]: () => dispatch(setRefundData(value)),
      [TabConstants.HISTORY]: () => dispatch(setRefundHistoryData(value)),
    };

    dispatchFunctions[activeTab]();
  };

export const processTransactions = (
  transactionObj: ProcessTransactionRefundRequest,
  actionRefundEnum: ActionRefundEnum
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setLoadingMassive(true));
    const showError = showOpenErrorNotification(dispatch);

    try {
      const webSocket = new WebSocket(
        `${
          environment.kushkiWSUrl
        }/refund-conciliation?Authorization=${auth.getJwtAuth()}`
      );

      webSocket.onopen = () => {
        webSocket.send(JSON.stringify(transactionObj));
      };

      webSocket.onerror = () => {
        dispatch(setLoadingMassive(false));
        showError(ProcessTransactionMessagesFailedEnum[actionRefundEnum]);
        webSocket?.close();
      };
      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const error: any = get(response, "error", []) as any;
        const errorData = get(response, "data.error", []);
        const messageResponse = get(response, "message", []);

        if (
          (Array.isArray(error) && error.length) ||
          errorData.length ||
          error === true ||
          messageResponse == ProcessTransactionMessagesEnum.ERROR_OMIT_MESSAGE
        ) {
          dispatch(setLoadingMassive(false));
          showError(ErrorsWS.SOCKET_ENDPOINT_ERROR);
        } else {
          dispatch(setLoadingMassive(false));
          dispatch(
            setNotification({
              horizontal: "right",
              icon: CheckIcon,
              message: ProcessTransactionMessagesSuccessEnum[actionRefundEnum],
              open: true,
              type: "success",
              vertical: "top",
            })
          );
        }
        webSocket?.close();
      };
    } catch (e) {
      dispatch(setLoadingMassive(false));
      showError(ErrorsWS.SOCKET_ENDPOINT_ERROR);
    }
  };
};

export const updateRefundTransaction =
  (request: object) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const url: string = `${environment.kushkiUrl}/refund-conciliation/v1/refundTransaction/action`;

    try {
      const resp = await axios.post(url, request);

      dispatch(
        setNotification({
          horizontal: "center",
          icon: CheckIcon,
          message: MODAL_ACTIONS_SUCCESS_MESSAGE[get(request, "action")],
          open: true,
          type: "success",
          vertical: "top",
        })
      );
      dispatch(setUpdateRefundTrx(resp.data));
    } catch (e) {
      dispatch(
        setNotification({
          horizontal: "center",
          icon: CloseIcon,
          message: `Ha ocurrido un error al enviar a ${
            MODAL_ACTIONS_ERROR_MESSAGE[get(request, "action")]
          }. Inténtalo nuevamente`,
          open: true,
          type: "error",
          vertical: "top",
        })
      );
    }
  };

export const getRefundHistoryData =
  (request: object) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const origin: string = "history";
    const url: string = `${environment.kushkiUrl}/refund-conciliation/v1/refundTransaction/${origin}`;

    set(request, "filter.stateRefund", AnalysisStateEnum.AUTHORIZED);
    dispatch(setIsLoadingRefundsData(true));
    try {
      const resp: AxiosResponse = await axios.post(url, request);

      dispatch(
        setRefundHistoryData({
          data: get(resp, "data.data", []),
          total: get(resp, "data.total", 0),
        })
      );
      dispatch(setIsLoadingRefundsData(false));
    } catch (e) {
      dispatch(setIsLoadingRefundsData(false));
    }
  };

export const updateAsyncResponseRefund = (
  params: object,
  isMassiveAsync?: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    let webSocket: WebSocket | undefined;

    const error_message: INotification = {
      horizontal: "right",
      icon: CloseIcon,
      message:
        "Ha ocurrido un error al ingresar la respuesta. Intentalo Nuevamente",
      open: true,
      type: "error",
      vertical: "top",
    };

    try {
      webSocket = new WebSocket(
        `${
          environment.kushkiWSUrl
        }/refund-conciliation?Authorization=${auth.getJwtAuth()}`
      );

      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = () => {
        dispatch(setNotification(error_message));
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response = JSON.parse(event.data);
        const parsedResponse = JSON.parse(response);

        const error: boolean = get(response, "error", false);

        if (error) {
          dispatch(setNotification(error_message));
          webSocket?.close();
        } else {
          dispatch(setWsResponse(get(parsedResponse, "message", "")));
          if (!isMassiveAsync)
            dispatch(
              setNotification({
                horizontal: "center",
                icon: CheckIcon,
                message: "Respuesta ingresada",
                open: true,
                type: "success",
                vertical: "top",
              })
            );
        }
      };
    } catch (e) {
      dispatch(setNotification(error_message));
      if (webSocket) webSocket.close();
    }
  };
};
