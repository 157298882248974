import { UseContinueModal } from "./UseContinueModal.interface";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  changeTab,
  dismissModalInfo,
  setBranchEditList,
} from "../../../store/reducers/layout/layout.slice";
import { StatusStepEnum } from "../../../shared/enums/StatusStepEnum";
import {
  createMassivePayoutRule,
  createMassiveRule,
  putHierarchyNodeConfig,
} from "../../../store/thunks/layout/layout.thunks";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import { useSearchParams } from "react-router-dom";
import { defaultTo, get, omit, set } from "lodash";
import {
  ConfigurationCodeEnum,
  tabIndexToProcessingStep,
} from "../../../shared/enums/ProcessingStepEnum";
import { UpdateConfigHierarchyRequest } from "../../../../types/update_config_hierarchy_request";
import { PathParamsEnum } from "../../../shared/enums/PathParamsEnum";
import {
  arePendingRules,
  containsEnabledRule,
  filterPendingRules,
  getBranches,
  getBusinessRulesStatusStep,
  getDeferredStatusStep,
  getMassiveRule,
  getProcessorsStatusStep,
} from "../../../shared/utils/get-business-rules-status-step";
import { TabIndex } from "../../../shared/enums/TabIndexEnum";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import { Configs } from "../../../../types/get_node_info_response";
import { filterNodeInfoByConfiguration } from "../../../shared/utils/filter-node-info-by-conf";
import {
  getMerchantId,
  isBatchPath,
  setStorageBranchEditList,
} from "../../../utils/utilsFile";
import { isDeferredAllowed } from "../../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import {
  getCustomerBatchInformation,
  getUserInformation,
} from "../../../shared/utils/local-storage/basic-merchant-information";
import { MassiveRulesRequest } from "../../../../types/massive_rules_request";
import { ACTION_TYPE_MODAL } from "../../../shared/constants/modal-info";
import { Data } from "../../../../types/branch_edit_list";
import { BranchEditListPathEnum } from "../../../shared/infrastructure/enums/SessionStoragePathEnum";
import { changeConfigBranchStatus } from "../../../utils/branch/branch-util";

export const useContinueModal = (): UseContinueModal => {
  const dispatch = useAppDispatch();
  const {
    nodeInfo,
    businessRules,
    payoutsBusinessRules,
    tabSelected,
    modalInfo,
    branchEditList,
  } = useAppSelector((store) => store.layout);
  const [searchParams] = useSearchParams();
  const publicMerchantId = defaultTo(
    searchParams.get(PathParamsEnum.PUBLIC_MERCHANT_ID),
    getMerchantId()
  );
  const { name } = getCustomerBatchInformation();
  const { username, email } = getUserInformation();

  const updateHierarchyConfig = (
    configurationCode: ConfigurationCodeEnum,
    stepSectionStatus: StatusStepEnum
  ) => {
    const config: Configs = filterNodeInfoByConfiguration(
      nodeInfo,
      configurationCode
    );
    const hierarchyRequest: UpdateConfigHierarchyRequest = {
      nodeId: get(nodeInfo, NodeInfoPath.NodeID, ""),
      configs: [
        {
          configuration: configurationCode!!,
          value: publicMerchantId!!,
          centralizedChildsNodeIds: get(config, "centralizedNodesID"),
          status: stepSectionStatus,
          updatedBy: "",
          updatedAt: 0,
        },
      ],
    };

    dispatch(putHierarchyNodeConfig(hierarchyRequest));
  };

  const updateBusinessRulesState = (
    actualProcessingStep: ConfigurationCodeEnum
  ) => {
    const stepSectionStatus = getBusinessRulesStatusStep(
      businessRules.items,
      payoutsBusinessRules
    );
    if (
      containsEnabledRule(businessRules.items) ||
      containsEnabledRule(payoutsBusinessRules)
    ) {
      updateHierarchyConfig(actualProcessingStep, stepSectionStatus);
    }
  };
  const updateProcessorState = (
    actualProcessingStep: ConfigurationCodeEnum
  ) => {
    const stepSectionStatus = getProcessorsStatusStep();
    updateHierarchyConfig(actualProcessingStep, stepSectionStatus);
  };

  const updateDeferredState = (actualProcessingStep: ConfigurationCodeEnum) => {
    const stepSectionStatus = getDeferredStatusStep();
    updateHierarchyConfig(actualProcessingStep, stepSectionStatus);
  };

  const verifyGoNextTab = () => {
    const actualProcessingStep = getActualProcessingStep(tabSelected);
    const merchantID = defaultTo(publicMerchantId, "");
    switch (tabSelected) {
      case TabIndex.PROCESSORS:
        if (isBatchPath()) {
          window.location.href = AppRoutes.CREATE_NODE_BRANCH_EDIT;
        } else {
          updateProcessorState(actualProcessingStep);
          dispatch(changeTab(TabIndex.BUSINESS_RULES));
        }
        break;
      case TabIndex.BUSINESS_RULES:
        updateBusinessRulesState(actualProcessingStep);
        const merchantCountry: string = get(
          nodeInfo,
          NodeInfoPath.InfoCountry,
          ""
        );
        if (!isDeferredAllowed(merchantCountry)) {
          window.location.href = AppRoutes.SERVICES(
            defaultTo(publicMerchantId, "")
          );
          break;
        }
        dispatch(changeTab(TabIndex.DEFERRED));
        break;
      case TabIndex.DEFERRED:
        updateDeferredState(actualProcessingStep);
        setTimeout(
          () => (window.location.href = AppRoutes.SERVICES(merchantID)),
          1000
        );
        break;
    }
    dispatch(dismissModalInfo());
  };

  const handleCloseModal = () => {
    dispatch(dismissModalInfo());
  };

  const handlePrimaryButtonModal = () => {
    switch (modalInfo.actionType) {
      case ACTION_TYPE_MODAL.BATCH:
        saveMassiveRule();
        break;
      case ACTION_TYPE_MODAL.FINISH:
        window.location.href = AppRoutes.BRANCH_EDIT;
        break;
      default:
        verifyGoNextTab();
    }
  };

  const saveMassiveRule = () => {
    const massiveRuleRequest: MassiveRulesRequest = {
      branches: getBranches(),
      customerId: publicMerchantId,
      customerName: defaultTo(name, ""),
      email,
      username,
    };

    const updateBranchEditList: Data[] = (get(
      branchEditList,
      BranchEditListPathEnum.DATA,
      []
    ) as Data[]).map((branch: Data) =>
      changeConfigBranchStatus(
        branch,
        ConfigurationCodeEnum.BUSINESS_RULES,
        StatusStepEnum.COMPLETE
      )
    );
    setStorageBranchEditList({ data: updateBranchEditList });
    dispatch(setBranchEditList({ data: updateBranchEditList }));
    if (arePendingRules(businessRules.items)) {
      set(massiveRuleRequest, "rules", getMassiveRule(businessRules.items));
      dispatch(createMassiveRule(massiveRuleRequest));
    }
    if (arePendingRules(payoutsBusinessRules)) {
      set(
        massiveRuleRequest,
        "payoutRules",
        filterPendingRules(payoutsBusinessRules)
      );
      dispatch(createMassivePayoutRule(omit(massiveRuleRequest, "rules")));
    }
    dispatch(dismissModalInfo());
  };

  return {
    modalInfo,
    handleCloseModal,
    handlePrimaryButtonModal,
  };
};

const getActualProcessingStep = (
  tabSelected: TabIndex
): ConfigurationCodeEnum => {
  return tabIndexToProcessingStep.get(tabSelected)!!;
};
