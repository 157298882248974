import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { MerchantResponse } from "../../../../types/merchant_response";
import { useMediaQuery } from "@material-ui/core";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { useHistory } from "react-router-dom";
import {
  Category,
  getCatalog,
} from "../../../shared/infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../../../shared/infrastructure/constants/catalogs-enum";
import { REGULATED_COUNTRIES } from "../../../shared/infrastructure/constants/countries-enum";
import { useEffect, useState } from "react";
import { concatObjectValuesFromObject } from "../../../shared/utils";
export interface IUseBasicInformation {
  loading?: boolean;
  handleEditMerchant: () => void;
  merchant: MerchantResponse;
  isMobile?: boolean;
  naturalPerson?: string;
  merchantType?: Category;
}

export const useBasicInformation = (): IUseBasicInformation => {
  const history = useHistory();
  const loading: boolean = useSelector((state: IAppState) => !!state.isLoading);
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );

  const [naturalPerson, setnaturalPerson] = useState<string>();
  const [merchantType, setMerchantType] = useState<Category>();

  const isMobile: boolean = useMediaQuery("(max-width:767px)");

  const handleEditMerchant = () => {
    localStorage.setItem(
      "merchantInfo",
      JSON.stringify({ publicMerchantId: merchant.publicMerchantId })
    );
    localStorage.setItem(
      "merchantPropertiesInfo",
      JSON.stringify({
        publicMerchantId: merchant.publicMerchantId,
        country: merchant.country,
        isEditing: true,
      })
    );
    localStorage.setItem("onEditMerchant", JSON.stringify({ edit: true }));
    localStorage.removeItem("merchantStore");
    history.push(
      `${routes.BASIC_INFO}?publicMerchantId=${merchant.publicMerchantId}`
    );
  };

  useEffect(() => {
    const isRegulatedCountry = REGULATED_COUNTRIES.includes(merchant.country);

    if (!isRegulatedCountry) {
      return;
    }

    const merchantType = getCatalog(
      merchant.country,
      CatalogsEnum.MerchantTypes
    )?.find((item) => item.value === merchant.merchantType);

    // Where 01 means merchant is either physical or natural entity
    const showNaturalPerson =
      isRegulatedCountry && merchantType?.value === "01";

    setMerchantType(merchantType);
    const { firstName, secondName, surName, lastName } = merchant;
    showNaturalPerson &&
      setnaturalPerson(
        concatObjectValuesFromObject({
          firstName,
          secondName,
          surName,
          lastName,
        })
      );
  }, []);

  return {
    merchant,
    handleEditMerchant,
    loading,
    isMobile,
    naturalPerson,
    merchantType,
  };
};
