import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { AuthenticationRulesLabelField } from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { MAP_TYPE_ISCREDIT } from "../../../shared/infrastructure/constants/BusinessRulesEnum";
import { ControllerRenderProps } from "react-hook-form";

export interface SelectFieldComponentProps {
  items: IOptionSelection[];
  disabled: boolean;
  label: String;
  properties: ControllerRenderProps;
  helperText: string | false | undefined;
  error: boolean;
}

export const SelectFieldComponent: React.FC<SelectFieldComponentProps> = (
  props: SelectFieldComponentProps
) => {
  const { helperText, error, properties } = props;
  return (
    <FormControl variant="outlined" error={error} fullWidth>
      <InputLabel>{AuthenticationRulesLabelField.VALUE}</InputLabel>
      <Select
        {...properties}
        value={
          props.label === "transactionIsCreditCard"
            ? MAP_TYPE_ISCREDIT[`${String(properties.value)}`]
            : properties.value
        }
        label={AuthenticationRulesLabelField.VALUE}
        disabled={props.disabled}
      >
        {props.items.map(
          (actionValue: { name: string; value: string }, index: number) => (
            <MenuItem key={index} value={actionValue.value}>
              {actionValue.name}
            </MenuItem>
          )
        )}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectFieldComponent;
