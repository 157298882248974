import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const BranchResumeStyles: CustomStyles = {
  subtitle: {
    alignItems: "center",
    color: "#6D7781",
    display: "flex",
    flexDirection: "row",
    fontSize: "13px",
    fontWeight: 400,
    height: "15.6px",
    lineHeight: "120%",
    marginBottom: "16px",
  },
};
