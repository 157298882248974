export const enum WebhookServiceEnum {
  card = "card",
  cash = "cash",
  "payouts-cash" = "payouts-cash",
  "transfer-subscriptions" = "transfer-subscriptions",
  transfer = "transfer",
  "payouts-transfer" = "payouts-transfer",
  subscriptions = "subscriptions",
  webcheckout = "webcheckout",
  vpos = "vpos",
  "card-chargeback" = "card-chargeback",
}

export const enum OptionsEnum {
  CARD = "Tarjeta",
  CASH = "Efectivo",
  PAYOUTS_CASH = "Dispersión de Efectivo",
  RECURRENT_DEBITS = "Débitos recurrentes",
  TRANSFER = "Transferencia",
  PAYOUTS_TRANSFER = "Dispersión de Transferencia",
  SUBSCRIPTIONS = "Suscripciones",
  SMARTLINKS = "Smartlinks",
  VIRTUAL_POS = "Virtual POS",
  CHARGEBACK = "Devoluciones",
}

export const enum WebhookEventsEnum {
  approvedTransaction = "approvedTransaction",
  chargeback = "chargeback",
  declinedTransaction = "declinedTransaction",
  failedRetry = "failedRetry",
  lastRetry = "lastRetry",
  subscriptionDelete = "subscriptionDelete",
  declinedSubscription = "declinedSubscription",
  subscriptionDeclined = "subscriptionDeclined",
  approvedSubscription = "approvedSubscription",
  subscriptionApproved = "subscriptionApproved",
  cashPreathorization = "cashPreathorization",
}

export const TRANSACTION_STATUS: Record<WebhookEventsEnum, string> = {
  approvedSubscription: "Suscripción aprobada",
  approvedTransaction: "Transacción aprobada",
  cashPreathorization: "Preautorización",
  chargeback: "Contracargos",
  declinedSubscription: "Suscripción declinada",
  declinedTransaction: "Transacción declinada",
  failedRetry: "Intento fallido",
  lastRetry: "Último intento",
  subscriptionApproved: "Suscripción aprobada",
  subscriptionDeclined: "Suscripción declinada",
  subscriptionDelete: "Suscripción borrada",
};
