export enum HeaderColumnIdEnum {
  START_DATE = "Fecha de inicio",
  DATE_MAX = "Fecha max. de resp",
  BRANCH = "Branch",
  CHARGEBACK_NUMBER = "No. de contracargo",
  TICKET_NUMBER = "ticket_number",
  CARD_NUMBER = "card_number",
  STATUS = "status",
  AMOUNT = "amount",
  CARD_BRAND = "card_brand",
  PAYMENT_SUB_METHOD_TYPE = "payment_submethod_type",
  ISSUING_BANK = "issuing_bank",
  SUBSCRIPTION_ID = "subscription",
  NAME_ON_CARD = "name_on_card",
  CREDENTIAL_ID = "credential_id",
  CREDENTIAL_ALIASES = "credential_aliases",
  PUBLIC_KEY = "public_credential_id",
  OWNER = "owner_name",
  CUSTOMER = "customer_name",
}
