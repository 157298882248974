import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import { FiltersTitleEnum } from "../../enums/FiltersTitleEnum";
import { PaymentTypeEnum } from "../../enums/PaymentTypeEnum";
import { PlatformValueEnum } from "../../enums/PlatformValueEnum";
import { StatusValueEnum } from "../../enums/StatusValueEnum";

export const ProductsItemsFilter: ItemCategoryItem[] = [
  {
    label: "API",
    selected: false,
    value: PlatformValueEnum.API,
  },
  {
    label: "Kushki.js",
    selected: false,
    value: PlatformValueEnum.KUSHKIJS,
  },
  {
    label: "Android",
    selected: false,
    value: PlatformValueEnum.ANDROID,
  },
  {
    label: "iOS",
    selected: false,
    value: PlatformValueEnum.IOS,
  },
  {
    label: "Smartlinks",
    selected: false,
    value: PlatformValueEnum.SMARTLINKS,
  },
  {
    label: "Kajita",
    selected: false,
    value: PlatformValueEnum.KAJITA,
  },
  {
    label: "Cajita",
    selected: false,
    value: PlatformValueEnum.CAJITA,
  },
  {
    label: "VTEX",
    selected: false,
    value: PlatformValueEnum.VTEX,
  },
  {
    label: "Magento",
    selected: false,
    value: PlatformValueEnum.MAGENTO,
  },
  {
    label: "PrestaShop",
    selected: false,
    value: PlatformValueEnum.PRESTASHOP,
  },
  {
    label: "WooCommerce",
    selected: false,
    value: PlatformValueEnum.WOOCOMERCE,
  },
  {
    label: "Shopify",
    selected: false,
    value: PlatformValueEnum.SHOPIFY,
  },
  {
    label: "WebCheckout",
    selected: false,
    value: PlatformValueEnum.WEBCHECKOUT,
  },
  {
    label: "Express Checkout",
    selected: false,
    value: PlatformValueEnum.WEBCHECKOUT_EXPRESS,
  },
];

export const PaymentTypeItemsFilter: ItemCategoryItem[] = [
  {
    label: "Card",
    selected: false,
    value: PaymentTypeEnum.CARD,
  },
  {
    label: "Cash in",
    selected: false,
    value: PaymentTypeEnum.CASH,
  },
  {
    label: "Cash out",
    selected: false,
    value: PaymentTypeEnum.CASH_OUT,
  },
  {
    label: "Transfer in",
    selected: false,
    value: PaymentTypeEnum.TRANSFER,
  },
  {
    label: "Transfer out",
    selected: false,
    value: PaymentTypeEnum.TRANSFER_OUT,
  },
];

export const StatusItemsFilter: ItemCategoryItem[] = [
  {
    label: "Aprobado",
    selected: false,
    value: StatusValueEnum.APPROVED,
  },
  {
    label: "Rechazado",
    selected: false,
    value: StatusValueEnum.DECLINED,
  },
];

export const CategoryFiltersItems: ItemCategoryProps[] = [
  {
    isMinimize: true,
    items: ProductsItemsFilter,
    onItemsSelect: () => {},
    placeHolder: FiltersTitleEnum.PRODUCT,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isMinimize: true,
    items: PaymentTypeItemsFilter,
    onItemsSelect: () => {},
    placeHolder: FiltersTitleEnum.PAYMENT_TYPE,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isMinimize: true,
    items: StatusItemsFilter,
    onItemsSelect: () => {},
    placeHolder: FiltersTitleEnum.STATUS,
    selectType: "multiple",
    type: "byChips",
  },
];
