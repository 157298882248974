import React, { FC } from "react";

export interface ICircleInformation {
  disabled: boolean;
}

export const CircleInformation: FC<ICircleInformation> = ({
  disabled,
}: ICircleInformation) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 15.3346C6.54993 15.3346 5.13211 14.9045 3.92615 14.0987C2.72019 13.293 1.78025 12.1476 1.22521 10.8077C0.67017 9.46766 0.524945 7.99317 0.807903 6.57064C1.09086 5.14812 1.78929 3.84144 2.81488 2.81586C3.84046 1.79027 5.14714 1.09184 6.56967 0.80888C7.99219 0.525922 9.46668 0.671146 10.8067 1.22619C12.1467 1.78123 13.292 2.72116 14.0978 3.92712C14.9036 5.13308 15.3337 6.55091 15.3337 8.0013C15.3337 9.94623 14.561 11.8115 13.1858 13.1868C11.8105 14.562 9.94525 15.3346 8.00033 15.3346ZM8.00033 2.00131C6.81364 2.00131 5.6536 2.3532 4.66691 3.01249C3.68021 3.67178 2.91118 4.60885 2.45705 5.7052C2.00293 6.80156 1.88411 8.00796 2.11562 9.17185C2.34713 10.3357 2.91857 11.4048 3.75769 12.2439C4.5968 13.0831 5.6659 13.6545 6.82979 13.886C7.99367 14.1175 9.20007 13.9987 10.2964 13.5446C11.3928 13.0905 12.3299 12.3214 12.9891 11.3347C13.6484 10.348 14.0003 9.18799 14.0003 8.0013C14.0003 6.41001 13.3682 4.88388 12.243 3.75866C11.1178 2.63345 9.59163 2.00131 8.00033 2.00131Z"
        fill={disabled ? "#B4B9BE" : "#023365"}
      />
      <path
        d="M8.00033 5.33464C8.17714 5.33464 8.34671 5.40487 8.47173 5.5299C8.59675 5.65492 8.66699 5.82449 8.66699 6.0013L8.66699 12.0013C8.66699 12.1781 8.59675 12.3477 8.47173 12.4727C8.34671 12.5977 8.17714 12.668 8.00033 12.668C7.82351 12.668 7.65394 12.5977 7.52892 12.4727C7.4039 12.3477 7.33366 12.1781 7.33366 12.0013L7.33366 6.0013C7.33366 5.82449 7.4039 5.65492 7.52892 5.5299C7.65395 5.40487 7.82351 5.33464 8.00033 5.33464Z"
        fill={disabled ? "#B4B9BE" : "#023365"}
      />
      <path
        d="M8.00014 3.33444C8.08788 3.33393 8.17485 3.35074 8.25608 3.38392C8.3373 3.4171 8.41118 3.46598 8.47347 3.52777C8.53236 3.59257 8.57971 3.66698 8.61347 3.74777C8.65079 3.82689 8.66906 3.91365 8.66681 4.0011C8.66435 4.17762 8.5953 4.34668 8.47347 4.47444C8.3965 4.55471 8.30067 4.61444 8.19471 4.64821C8.08875 4.68198 7.97603 4.6887 7.86681 4.66777L7.74681 4.62777C7.70432 4.61318 7.66397 4.593 7.6268 4.56777C7.59188 4.54316 7.55849 4.51644 7.52681 4.48777C7.40182 4.35667 7.33253 4.18223 7.33347 4.0011C7.33296 3.91337 7.34978 3.82639 7.38296 3.74516C7.41613 3.66394 7.46502 3.59006 7.52681 3.52777C7.59161 3.46888 7.66602 3.42153 7.74681 3.38777C7.82695 3.35361 7.91302 3.33549 8.00014 3.33444Z"
        fill={disabled ? "#B4B9BE" : "#023365"}
      />
    </svg>
  );
};
