import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/api_routes";
import { MerchantNodePreferencesRequest } from "../../../../types/merchant_node_preferences_request_schema";
import { MerchantNodeSemaphoreRequest } from "../../../../types/merchant_node_semaphore_request_schema";
import { MerchantNodeHierarchyRequest } from "../../../../types/merchant_node_hierarchy_request_schema";

export const patchMerchantPreferences = createAsyncThunk<
  any,
  MerchantNodePreferencesRequest
>("merchantNode/preferences", async (payload) => {
  const response = await axios.patch(
    API_ROUTES.PATCH_PREFERENCES(payload.merchantId),
    payload
  );

  return response.data;
});

export const patchMerchantSemaphore = createAsyncThunk<
  any,
  MerchantNodeSemaphoreRequest
>("merchantNode/semaphore", async (payload) => {
  const response = await axios.post(API_ROUTES.MERCHANT_SEMAPHORE, payload);

  return response.data;
});

export const putMerchantHierarchyConfig = createAsyncThunk<
  any,
  MerchantNodeHierarchyRequest
>("merchantNode/hierarchyConfig", async (payload) => {
  const response = await axios.put(
    API_ROUTES.MERCHANT_HIERARCHY_CONFIG,
    payload
  );

  return response.data;
});
