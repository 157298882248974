import React from "react";

export const EmptyResultImage: React.FC = () => {
  return (
    <svg
      width="270"
      height="269"
      viewBox="0 0 270 269"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.6508 179.155H32.5619V181.067H34.473V182.979H36.3841V181.067H38.2952V179.155H40.2064V177.242H38.2952V175.33H36.3841V173.418H34.473V175.33H32.5619V177.242H30.6508V179.155ZM34.473 177.242H36.3841V179.155H34.473V177.242Z"
        fill="#00DFA9"
      />
      <path
        d="M135.142 268.95C209.397 268.95 269.593 208.755 269.593 134.5C269.593 60.2452 209.397 0.0498047 135.142 0.0498047C60.8876 0.0498047 0.692261 60.2452 0.692261 134.5C0.692261 208.755 60.8876 268.95 135.142 268.95Z"
        fill="#FBFCFE"
      />
      <path
        d="M267.903 153.925C253.086 147.452 233.795 144.353 221.737 148.871C210.442 153.105 212.249 161.513 200.644 164.99C181.922 170.594 168.956 151.17 141.095 152.093C116.308 152.913 107.698 168.914 88.9915 164.99C77.3126 162.534 75.1025 155.168 66.7878 149.942C52.8595 141.215 26.1178 141.303 0.407471 146.298C2.67088 178.894 16.7637 209.54 40.0363 232.475C65.4323 237.171 93.0777 239.68 120.006 238.678C167.365 236.925 215.284 224.239 247.89 209.137C258.574 192.446 265.41 173.586 267.903 153.925Z"
        fill="url(#paint0_linear_1233_272248)"
        fillOpacity="0.5"
      />
      <g filter="url(#filter0_dd_1233_272248)">
        <rect
          width="150"
          height="103"
          transform="translate(56.9993 86.7646) rotate(-4.4982)"
          fill="white"
        />
        <path
          d="M76.6001 104.282L67.2955 105.014C66.8386 105.05 66.4353 104.705 66.3994 104.248L65.6674 94.9436C65.6315 94.4866 65.9759 94.0834 66.4328 94.0474L75.7374 93.3154C76.1943 93.2795 76.5976 93.6239 76.6335 94.0809L77.3655 103.385C77.4015 103.842 77.057 104.246 76.6001 104.282Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M108.47 90.7405L90.525 92.1522C89.4239 92.2389 88.6014 93.2018 88.688 94.3029L89.0802 99.2875C89.1668 100.389 90.1297 101.211 91.2309 101.125L109.175 99.7128C110.277 99.6262 111.099 98.6633 111.012 97.5621L110.62 92.5775C110.534 91.4763 109.571 90.6539 108.47 90.7405Z"
          fill="#D9E5EE"
        />
        <path
          d="M197.195 83.7605L124.42 89.4857C123.319 89.5724 122.497 90.5353 122.583 91.6364L122.975 96.621C123.062 97.7222 124.025 98.5446 125.126 98.458L197.901 92.7328C199.002 92.6461 199.825 91.6832 199.738 90.5821L199.346 85.5975C199.26 84.4963 198.297 83.6739 197.195 83.7605Z"
          fill="#D9E5EE"
        />
        <path
          d="M78.0902 123.223L68.7856 123.955C68.3287 123.991 67.9255 123.646 67.8895 123.19L67.1575 113.885C67.1216 113.428 67.466 113.025 67.9229 112.989L77.2275 112.257C77.6844 112.221 78.0877 112.565 78.1236 113.022L78.8556 122.327C78.8916 122.784 78.5471 123.187 78.0902 123.223Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M109.96 109.682L92.0151 111.094C90.914 111.18 90.0915 112.143 90.1782 113.244L90.5703 118.229C90.6569 119.33 91.6198 120.153 92.721 120.066L110.666 118.654C111.767 118.568 112.589 117.605 112.503 116.504L112.11 111.519C112.024 110.418 111.061 109.595 109.96 109.682Z"
          fill="#D9E5EE"
        />
        <path
          d="M198.686 102.702L125.91 108.427C124.809 108.514 123.987 109.477 124.073 110.578L124.466 115.562C124.552 116.664 125.515 117.486 126.616 117.399L199.391 111.674C200.493 111.588 201.315 110.625 201.228 109.523L200.836 104.539C200.75 103.438 199.787 102.615 198.686 102.702Z"
          fill="#D9E5EE"
        />
        <path
          d="M79.5803 142.164L70.2757 142.896C69.8188 142.932 69.4156 142.588 69.3796 142.131L68.6476 132.826C68.6117 132.369 68.9561 131.966 69.413 131.93L78.7176 131.198C79.1745 131.162 79.5778 131.507 79.6137 131.964L80.3457 141.268C80.3817 141.725 80.0373 142.128 79.5803 142.164Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M111.45 128.623L93.5053 130.035C92.4041 130.122 91.5816 131.085 91.6683 132.186L92.0604 137.17C92.147 138.272 93.1099 139.094 94.2111 139.007L112.156 137.596C113.257 137.509 114.079 136.546 113.993 135.445L113.601 130.46C113.514 129.359 112.551 128.537 111.45 128.623Z"
          fill="#D9E5EE"
        />
        <path
          d="M200.176 121.643L127.401 127.369C126.299 127.455 125.477 128.418 125.564 129.519L125.956 134.504C126.042 135.605 127.005 136.427 128.106 136.341L200.882 130.616C201.983 130.529 202.805 129.566 202.718 128.465L202.326 123.48C202.24 122.379 201.277 121.557 200.176 121.643Z"
          fill="#D9E5EE"
        />
        <path
          d="M81.0705 161.106L71.7659 161.838C71.309 161.874 70.9057 161.529 70.8698 161.072L70.1378 151.768C70.1019 151.311 70.4463 150.908 70.9032 150.872L80.2078 150.14C80.6647 150.104 81.068 150.448 81.1039 150.905L81.8359 160.21C81.8719 160.667 81.5274 161.07 81.0705 161.106Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M112.94 147.565L94.9954 148.976C93.8943 149.063 93.0718 150.026 93.1584 151.127L93.5506 156.112C93.6372 157.213 94.6001 158.035 95.7013 157.949L113.646 156.537C114.747 156.45 115.569 155.487 115.483 154.386L115.091 149.402C115.004 148.301 114.041 147.478 112.94 147.565Z"
          fill="#D9E5EE"
        />
        <path
          d="M201.666 140.585L128.891 146.31C127.79 146.397 126.967 147.359 127.054 148.461L127.446 153.445C127.532 154.546 128.495 155.369 129.597 155.282L202.372 149.557C203.473 149.47 204.295 148.507 204.209 147.406L203.817 142.422C203.73 141.321 202.767 140.498 201.666 140.585Z"
          fill="#D9E5EE"
        />
        <path
          d="M82.5606 180.047L73.256 180.779C72.7991 180.815 72.3959 180.471 72.3599 180.014L71.6279 170.709C71.592 170.252 71.9364 169.849 72.3933 169.813L81.6979 169.081C82.1548 169.045 82.5581 169.39 82.594 169.846L83.326 179.151C83.362 179.608 83.0175 180.011 82.5606 180.047Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M114.43 166.506L96.4855 167.918C95.3844 168.004 94.5619 168.967 94.6486 170.069L95.0407 175.053C95.1273 176.154 96.0902 176.977 97.1914 176.89L115.136 175.478C116.237 175.392 117.06 174.429 116.973 173.328L116.581 168.343C116.494 167.242 115.531 166.42 114.43 166.506Z"
          fill="#D9E5EE"
        />
        <path
          d="M203.156 159.526L130.381 165.251C129.28 165.338 128.457 166.301 128.544 167.402L128.936 172.387C129.023 173.488 129.985 174.31 131.087 174.224L203.862 168.498C204.963 168.412 205.785 167.449 205.699 166.348L205.307 161.363C205.22 160.262 204.257 159.439 203.156 159.526Z"
          fill="#D9E5EE"
        />
      </g>
      <path
        d="M235.153 36.6754H207.866C207.017 36.676 206.177 36.5094 205.394 36.1851C204.61 35.8608 203.897 35.3851 203.298 34.7852C202.698 34.1854 202.222 33.4732 201.898 32.6894C201.573 31.9055 201.407 31.0654 201.407 30.2171C201.406 29.3684 201.572 28.5278 201.896 27.7434C202.22 26.959 202.696 26.2462 203.296 25.6458C203.896 25.0455 204.608 24.5693 205.392 24.2447C206.177 23.9201 207.017 23.7533 207.866 23.7539H235.153C236.003 23.752 236.845 23.9177 237.631 24.2417C238.416 24.5656 239.131 25.0414 239.732 25.6417C240.334 26.2421 240.811 26.9552 241.137 27.7404C241.463 28.5255 241.631 29.3671 241.631 30.2171C241.629 31.9304 240.948 33.5731 239.736 34.7841C238.524 35.9951 236.881 36.6754 235.167 36.6754H235.153Z"
        fill="#D9E5EE"
      />
      <path
        d="M228.699 28.1152C229.952 22.8539 226.703 17.5727 221.442 16.3193C216.18 15.0659 210.899 18.3149 209.646 23.5762C208.392 28.8374 211.641 34.1186 216.903 35.3721C222.164 36.6255 227.445 33.3765 228.699 28.1152Z"
        fill="#D9E5EE"
      />
      <path
        d="M16.9819 185.999C14.2517 185.999 11.9993 188.252 11.9993 190.983C11.9993 193.715 14.2517 195.968 16.9819 195.968C19.712 195.968 21.9644 193.715 21.9644 190.983C21.9644 188.252 19.712 185.999 16.9819 185.999ZM16.9819 187.364C18.9612 187.364 20.5993 189.003 20.5993 190.983C20.5993 192.964 18.9612 194.603 16.9819 194.603C15.0025 194.603 13.3644 192.964 13.3644 190.983C13.3644 189.003 14.9342 187.364 16.9819 187.364Z"
        fill="#00D29C"
      />
      <defs>
        <filter
          id="filter0_dd_1233_272248"
          x="21.9993"
          y="55.0005"
          width="227.616"
          height="184.447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1233_272248"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="15" />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.235294 0 0 0 0 0.258824 0 0 0 0 0.341176 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1233_272248"
            result="effect2_dropShadow_1233_272248"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1233_272248"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1233_272248"
          x1="134.153"
          y1="226.122"
          x2="129.249"
          y2="142.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFEFD" />
          <stop offset="0.916375" stopColor="#D9E5EE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
