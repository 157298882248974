import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { get } from "lodash";

export interface IKshInput {
  label: string;
  value?: string | number;
  id: string;
  onBlur(value: { prop: string; value?: string | number }): void;
  type?: string;
  labelWith?: number;
  maxLength?: number;
  error?: boolean;
  labelError?: string;
}

export const KshInput: React.FC<IKshInput> = (props: IKshInput) => {
  const [value, setValue] = useState<string | number | undefined>(
    get(props, "value", "")
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(get(event, "target.value", "").slice(0, props.maxLength));

  useEffect(() => {
    setValue(get(props, "value", ""));
  }, [props.value]);

  return (
    <FormControl variant="outlined" style={{ width: "100%" }}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <OutlinedInput
        id={props.id}
        value={value}
        type={props.type}
        onChange={handleChange}
        labelWidth={
          +props.label.length * 7.4 + (props.labelWith ? props.labelWith : 0)
        }
        onBlur={() => props.onBlur({ prop: props.id, value: value })}
        error={props.error}
      />
      {props.error ? (
        <Typography
          id="typography_error"
          variant="caption"
          color="error"
          display="block"
          gutterBottom
          style={{ marginTop: 3 }}
        >
          {props.labelError}
        </Typography>
      ) : null}
    </FormControl>
  );
};
