import { FC } from "react";
import * as React from "react";
import { IMainTextFieldSelect } from "./MainTextFieldSelect.interfaces";
import { SelectNewItem } from "@kushki/connect-ui";

export const MainTextFieldSelect: FC<IMainTextFieldSelect> = ({
  addButtonText,
  placeholder,
  items,
  onItemSelected,
  onAddButtonChange,
  value,
}: IMainTextFieldSelect) => {
  return (
    <SelectNewItem
      addButtonText={addButtonText}
      placeholder={placeholder}
      items={items}
      onItemSelected={onItemSelected}
      onAddButtonChange={onAddButtonChange}
      specialCharacters={false}
      orderBy={"default"}
      isCustomAction={true}
      value={value}
    />
  );
};

export default MainTextFieldSelect;
