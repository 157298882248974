/* istanbul ignore file */

import { IOptionSelection } from "./RuleRequestManagerConstants";

export const BANKS_PERU: IOptionSelection[] = [
  { value: "0002", label: "Banco de Crédito del Perú" },
  { value: "0003", label: "Interbank" },
  { value: "0007", label: "Citibank del Perú S.A." },
  { value: "0009", label: "Scotiabank Perú S.A.A." },
  { value: "0011", label: "Banco Continental" },
  { value: "0018", label: "Banco de la Nación" },
  { value: "0023", label: "Banco de Comercio" },
  { value: "0035", label: "Banco Financiero del Perú" },
  { value: "0038", label: "Banco Interamericano de Finanzas (BIF)" },
  { value: "0053", label: "Banco Falabella" },
  { value: "0055", label: "Banco Ripley" },
  { value: "0056", label: "Banco Santander Perú" },
  { value: "0058", label: "Banco Azteca" },
  { value: "0054", label: "Banco GNB Perú S.A." },
  { value: "0802", label: "Caja Municipal de Ahorro y Crédito Trujillo" },
  { value: "0803", label: "Caja Municipal de Ahorro y Crédito Arequipa" },
  { value: "0805", label: "Caja Municipal de Ahorro y Crédito Sullana" },
  { value: "0808", label: "Caja Municipal de Ahorro y Crédito Huancayo" },
];
