import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { IFiltersDashboard } from "../../components/DashboardTransaction/state/useDashboardTransaction.interfaces";
import {
  cloneDeep,
  defaultTo,
  get,
  has,
  isEmpty,
  isNil,
  set as setLodash,
} from "lodash";
import { joinItemList } from "./joinItemList";
import { RetrieveChildrenResponse } from "../../../types/retrive_children_response";
import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import { TransactionType } from "../infraestructure/transaction-type";
import { TransactionStatus } from "../infraestructure/transaction-status";
import { ProcessorEnum } from "../infraestructure/ProcessorEnum";
import { HeaderColumnIdEnum } from "../enums/HeaderColumnIdEnum";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { DateEnum } from "../enums/DateEnum";

export function buildStringFilter(filterItem: IFilterItem[]): string {
  let value: string = "";

  filterItem.map((item: IFilterItem, index: number) => {
    value = value.concat(item.value);
    if (index + 1 < filterItem.length) {
      value = value.concat("|");
    }
  });

  return value;
}

export function buildBranchesIds(
  filterDashboard: IFiltersDashboard,
  selectedItems: ICategory[]
): string {
  return !isEmpty(defaultTo(selectedItems, []))
    ? joinItemList(selectedItems!, "|", "", true)
    : get(filterDashboard, "customerFilter.childrenIds", "");
}

const getFathersNameMongo = (children: object) => {
  return {
    customerId: get(children, "customer_code", ""),
    customerName: get(children, "customer_name", ""),
    ownerName: get(children, "owner_name", ""),
  };
};

const getFathersName = (
  children: RetrieveChildrenResponse[],
  transactionMerchantId: string
) => {
  for (let i = 0; i < children.length; i++) {
    const merchants_ids = children[i].publicMerchantId || [];

    if (
      merchants_ids.find((merchantId) => merchantId === transactionMerchantId)
    )
      return {
        customerId: children[i].customerId,
        customerName: children[i].customerName,
        ownerName: children[i].ownerName,
      };
  }

  return { customerId: "", customerName: "", ownerName: "" };
};

const getVoidDeclinedTransbankTransactionStatus = (
  trx: TransactionInfo
): string => {
  const trxsHistorical: TransactionInfo[] = get(trx, "historical", []);
  const historicalDeclinedTrx = trxsHistorical.filter(
    (trx: TransactionInfo) =>
      trx.transaction_status === TransactionStatus.DECLINED
  );
  const maxAllowedDeclinedTrx = 2;

  return historicalDeclinedTrx.length >= maxAllowedDeclinedTrx
    ? TransactionStatus.DECLINED
    : TransactionStatus.PROCESS;
};

const getVoidDeclinedTransactionStatus = (
  trx: TransactionInfo,
  isRefund: boolean,
  isVoid: boolean
): string => {
  if (trx.processor_name == ProcessorEnum.TransbankProcessor)
    return getVoidDeclinedTransbankTransactionStatus(trx);
  if (isRefund || isVoid) return TransactionStatus.DECLINED;

  return TransactionStatus.PROCESS;
};

function getElementAmount(transaction: Transaction): number {
  const isDeclined: boolean =
    transaction._source?.transaction_status === TransactionStatus.DECLINED;
  const key: string = isDeclined
    ? "request_amount"
    : "approved_transaction_amount";

  return get(transaction._source, key);
}

export function validateVoidTrx(
  element: Transaction,
  children: RetrieveChildrenResponse[]
): Transaction {
  let transactionElement: Transaction = cloneDeep(element);

  const transactionType: string = get(element._source, "transaction_type", "");

  if (
    get(transactionElement, "_source.transaction_status_type") ===
    TransactionStatusEnum.APPROVED
  )
    setLodash(
      transactionElement,
      "_source.owner_name",
      TransactionStatusEnum.APPROVAL
    );

  if (
    get(transactionElement, "_source.completed") === DateEnum.DEFAULT ||
    isNil(get(transactionElement, "_source.completed"))
  )
    setLodash(
      transactionElement,
      "_source.completed",
      get(transactionElement, "_source.created")
    );

  if (
    (transactionType === TransactionType.VOID ||
      transactionType === TransactionType.REFUND) &&
    element._source!.payment_method === "card"
  ) {
    const parent: Transaction[] = get(element, "_source.parent", []);
    let is_partial: boolean;
    let is_refund: boolean;
    let is_declined: boolean;

    const is_void: boolean = transactionType === TransactionType.VOID;

    is_refund = transactionType === TransactionType.REFUND;
    is_declined =
      element._source!.transaction_status === TransactionStatus.DECLINED;
    is_partial =
      is_refund && parent.length > 0
        ? getElementAmount(element) !==
          get(parent[0], "_source.approved_transaction_amount")
        : false;

    setLodash(
      transactionElement,
      "_source.transaction_type",
      is_refund
        ? is_partial
          ? TransactionType.REFUND_PARTIAL
          : TransactionType.REFUND_TOTAL
        : element._source!.transaction_type
    );

    if (is_void) {
      const is_partial_void: boolean =
        parent.length > 0
          ? getElementAmount(element) !==
            get(parent[0], "_source.approved_transaction_amount")
          : false;

      setLodash(
        transactionElement,
        "_source.transaction_type",
        is_partial_void ? TransactionType.VOID_PARTIAL : TransactionType.VOID
      );
    }

    setLodash(
      transactionElement,
      "_source.transaction_status",
      is_declined
        ? getVoidDeclinedTransactionStatus(element._source!, is_refund, is_void)
        : element._source!.transaction_status
    );
  }

  const transaction_merchant_id = element._source?.merchant_id || "";
  const is_mongo: boolean = has(
    get(transactionElement, "_source"),
    "create_timestamp"
  );
  const father_names = is_mongo
    ? getFathersNameMongo(
        get(transactionElement, "_source.family_names.[0]", [{}])
      )
    : getFathersName(children, transaction_merchant_id);

  setLodash(
    transactionElement,
    "_source.owner_name",
    isEmpty(father_names.ownerName)
      ? HeaderColumnIdEnum.DEFAULT_OWNER_CUSTOMER
      : father_names.ownerName
  );

  setLodash(
    transactionElement,
    "_source.customer_name",
    isEmpty(father_names.customerName)
      ? HeaderColumnIdEnum.DEFAULT_OWNER_CUSTOMER
      : father_names.customerName
  );

  setLodash(
    transactionElement,
    "_source.customer_id",
    isEmpty(father_names.customerName)
      ? HeaderColumnIdEnum.DEFAULT_VALUE
      : father_names.customerId
  );

  return transactionElement;
}
