import { useEffect, useState } from "react";
import { IMaskedCardConditionProps } from "../../../../../shared/infrastructure/interfaces/IMaskedCardConditionProps";
import { IUseMaskedCardConditionState } from "../../../../../shared/infrastructure/interfaces/IUseMaskedCardConditionState";
import { get } from "lodash";
import { isAmex } from "../../../../../utils/utilsFile";

export const useMaskedCardConditionState = (
  props: IMaskedCardConditionProps
): IUseMaskedCardConditionState => {
  const [value, setValue] = useState<string>("");
  const [cardList, setCardList] = useState<string[]>(
    props.defaultListCard || []
  );
  const [validCard, setValidCard] = useState<boolean>(false);

  useEffect(() => {
    props.setSelectedItems(cardList);
  }, [cardList]);

  useEffect(() => {
    const defaultListCard: string[] = get(props, "defaultListCard", []);
    setCardList(defaultListCard.length > 0 ? defaultListCard : []);
  }, [props.operator]);

  const handleAddCard = (): void => {
    setValue("");
    setCardList((array) => [...array, value]);
    setValidCard(false);
  };

  const handleOnChangeCard = (value: string): void => {
    setValidCard((isAmex(value) && value.length >= 17) || value.length >= 19);
  };

  const handleEditCard = (value: string, index: number): void => {
    const newCardList = [...cardList];

    newCardList[index] = value;
    setCardList(newCardList);
  };

  return {
    cardList,
    handleAddCard,
    handleEditCard,
    handleOnChangeCard,
    setCardList,
    setValue,
    validCard,
    value,
  };
};
