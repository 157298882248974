import { Catalog, Data } from "@local-types/catalog_response";
import { defaultTo, get } from "lodash";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { TypeCatalog } from "@shared/enum/TypeCatalog";

export const getCatalogByName = (
  listCatalogs: Catalog[],
  name: string
): Data[] => {
  const list: Catalog | undefined = listCatalogs.find(
    (catalog: Catalog) => catalog.name.toUpperCase() === name.toUpperCase()
  );

  return defaultTo(get(list, "data"), []);
};

export const getCatalogAsSelectOptions = (
  listCatalogs: Catalog[]
): ISelectOption[] => {
  const mccCatalog: Data[] = getCatalogByName(listCatalogs, TypeCatalog.MCC);

  return mccCatalog.map(
    (catalog: Data): ISelectOption => ({
      label: `${catalog.code} - ${catalog.value}`,
      value: catalog.code,
    })
  );
};
