import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const TextAreaInputStyles = createNamedStyles({
  textField: {
    "& .MuiOutlinedInput-input": {
      alignSelf: "start",
      color: "black",
      display: "flex",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "16px",
      height: 169,
    },
    minHeight: 169,
    minWidth: 300,
    width: "80%",
  },
  endAdorment: {
    alignSelf: "end",
    display: "flex",
    position: "absolute",
    right: 20,
    bottom: 20,
  },
  countLenght: { ml: "auto", fontSize: "13px" },
});
