/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_MEXICO: IOptionSelection[] = [
  { value: "37006", name: "BANCOMEXT" },
  { value: "37009", name: "BANOBRAS" },
  { value: "37019", name: "BANJERCITO" },
  { value: "37135", name: "NAFIN" },
  { value: "37166", name: "BANSEFI" },
  { value: "37168", name: "HIPOTECARIA FED" },
  { value: "40002", name: "BANAMEX" },
  { value: "40012", name: "BBVA BANCOMER" },
  { value: "40014", name: "SANTANDER" },
  { value: "40021", name: "HSBC" },
  { value: "40030", name: "BAJIO" },
  { value: "40036", name: "INBURSA" },
  { value: "40037", name: "INTERACCIONES" },
  { value: "40042", name: "MIFEL" },
  { value: "40044", name: "SCOTIABANK" },
  { value: "40058", name: "BANREGIO" },
  { value: "40059", name: "INVEX" },
  { value: "40060", name: "BANSI" },
  { value: "40062", name: "AFIRME" },
  { value: "40072", name: "BANORTE/IXE" },
  { value: "40072", name: "BANORTE" },
  { value: "40102", name: "INVESTA BANK" },
  { value: "40103", name: "AMERICAN EXPRES" },
  { value: "40106", name: "BAMSA" },
  { value: "40108", name: "TOKYO" },
  { value: "40110", name: "JP MORGAN" },
  { value: "40112", name: "BMONEX" },
  { value: "40113", name: "VE POR MAS" },
  { value: "40124", name: "DEUTSCHE" },
  { value: "40126", name: "CREDIT SUISSE" },
  { value: "40127", name: "AZTECA" },
  { value: "40128", name: "AUTOFIN" },
  { value: "40129", name: "BARCLAYS" },
  { value: "40130", name: "COMPARTAMOS" },
  { value: "40131", name: "BANCO FAMSA" },
  { value: "40132", name: "MULTIVA BANCO" },
  { value: "40133", name: "ACTINVER" },
  { value: "40136", name: "INTERCAM BANCO" },
  { value: "40137", name: "BANCOPPEL" },
  { value: "40138", name: "ABC CAPITAL" },
  { value: "40140", name: "CONSUBANCO" },
  { value: "40141", name: "VOLKSWAGEN" },
  { value: "40143", name: "CIBANCO" },
  { value: "40145", name: "BBASE" },
  { value: "40147", name: "BANKAOOL" },
  { value: "40148", name: "PAGATODO" },
  { value: "40150", name: "INMOBILIARIO" },
  { value: "40151", name: "DONDE" },
  { value: "40152", name: "BANCREA" },
  { value: "40154", name: "BANCO FINTERRA" },
  { value: "40155", name: "ICBC" },
  { value: "40156", name: "SABADELL" },
  { value: "40158", name: "MIZUHO BANK" },
  { value: "90600", name: "MONEXCB" },
  { value: "90601", name: "GBM" },
  { value: "90602", name: "MASARI" },
  { value: "90605", name: "VALUE" },
  { value: "90606", name: "ESTRUCTURADORES" },
  { value: "90608", name: "VECTOR" },
  { value: "90613", name: "MULTIVA CBOLSA" },
  { value: "90614", name: "ACCIVAL" },
  { value: "90616", name: "FINAMEX" },
  { value: "90617", name: "VALMEX" },
  { value: "90620", name: "PROFUTURO" },
  { value: "90621", name: "CB ACTINVER" },
  { value: "90623", name: "SKANDIA" },
  { value: "90626", name: "CBDEUTSCHE" },
  { value: "90627", name: "ZURICH" },
  { value: "90628", name: "ZURICHVI" },
  { value: "90630", name: "CB INTERCAM" },
  { value: "90631", name: "CI BOLSA" },
  { value: "90634", name: "FINCOMUN" },
  { value: "90636", name: "HDI SEGUROS" },
  { value: "90637", name: "ORDER" },
  { value: "90638", name: "AKALA" },
  { value: "90640", name: "CB JPMORGAN" },
  { value: "90642", name: "REFORMA" },
  { value: "90646", name: "STP" },
  { value: "90648", name: "EVERCORE" },
  { value: "90649", name: "OSKNDIA" },
  { value: "90652", name: "ASEA" },
  { value: "90653", name: "KUSPIT" },
  { value: "90655", name: "SOFIEXPRESS" },
  { value: "90656", name: "UNAGRA" },
  { value: "90659", name: "ASP INTEGRA OPC" },
  { value: "90670", name: "LIBERTAD" },
  { value: "90671", name: "HUASTECAS" },
  { value: "90674", name: "AXA" },
  { value: "90677", name: "CAJA POP MEXICA" },
  { value: "90678", name: "SURA" },
  { value: "90679", name: "FND" },
  { value: "90680", name: "CRISTOBAL COLON" },
  { value: "90681", name: "PRINCIPAL" },
  { value: "90683", name: "CAJA TELEFONIST" },
  { value: "90684", name: "TRANSFER" },
  { value: "90685", name: "FONDO (FIRA)" },
  { value: "90686", name: "INVERCAP" },
  { value: "90687", name: "INFONAVIT" },
  { value: "90689", name: "FOMPED" },
  { value: "90901", name: "CLS" },
  { value: "90902", name: "INDEVAL" },
];

export const CURRENCY_MEXICO: IOptionSelection[] = [
  { value: CurrencyEnum.MXN, name: CurrencyEnum.MXN },
];
