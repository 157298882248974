import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { CardBrandEnum } from "../../../shared/enums/CardBrandEnum";
import VisaIcon from "../../../assets/images/card-brand/visa.png";
import MastercardIcon from "../../../assets/images/card-brand/mastercard.png";
import AmericanExpressIcon from "../../../assets/images/card-brand/american_express.png";
import DiscoverIcon from "../../../assets/images/card-brand/discover.png";
import DinersIcon from "../../../assets/images/card-brand/diners.png";
import JbcIcon from "../../../assets/images/card-brand/jcb.png";
import AliaIcon from "../../../assets/images/card-brand/alia.png";
import CreditCardIcon from "../../../assets/images/creditcard.svg";
import { transactionSectionStyles } from "../TransactionSection/TransactionSection.styles";
import NumberFormat from "react-number-format";
import { LabelField } from "../../../shared/enums/LabelsEnum";
import { get } from "lodash";
import {
  IPaymentMethod,
  TransactionSectionProps,
} from "./TransactionSection.interfaces";

export const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      fixedDecimalScale={true}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

export const mapCardBrands = (cardBrand: string): IPaymentMethod => {
  switch (cardBrand) {
    case CardBrandEnum.Visa:
      return {
        height: 20,
        icon: VisaIcon,
        label: CardBrandEnum.Visa,
        paddingBottom: 1,
        width: 25,
      };
    case CardBrandEnum.Mastercard:
      return {
        height: 15,
        icon: MastercardIcon,
        label: CardBrandEnum.Mastercard,
        paddingBottom: 1,
        width: 19,
      };
    case CardBrandEnum.Amex:
      return {
        height: 15,
        icon: AmericanExpressIcon,
        label: CardBrandEnum.Amex,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Discover:
      return {
        height: 15,
        icon: DiscoverIcon,
        label: CardBrandEnum.Discover,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Diners:
      return {
        height: 15,
        icon: DinersIcon,
        label: CardBrandEnum.Diners,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Jbc:
      return {
        height: 15,
        icon: JbcIcon,
        label: CardBrandEnum.Jbc,
        paddingBottom: 2,
        width: 20,
      };
    case CardBrandEnum.Alia:
      return {
        height: 15,
        icon: AliaIcon,
        label: CardBrandEnum.Alia,
        paddingBottom: 2,
        width: 20,
      };
    default:
      return {
        height: 20,
        icon: CreditCardIcon,
        label: "Tarjeta",
        paddingBottom: 1,
        width: 20,
      };
  }
};

export const TransactionSection: FC<TransactionSectionProps> = (
  props: TransactionSectionProps
) => {
  const subTotalIva: number = get(
    props.transaction,
    "converted_subtotal_iva",
    get(props.transaction, "subtotal_iva", 0)
  );
  const subTotalIavO: number = get(
    props.transaction,
    "converted_subtotal_iva0",
    get(props.transaction, "subtotal_iva0", 0)
  );
  const subTotal: number = subTotalIva + subTotalIavO;
  const ivaValue: number = get(
    props.transaction,
    "converted_iva",
    get(props.transaction, "iva_value", 0)
  );
  const iceValue: number = get(props.transaction, "ice_value", 0);
  const taxes: number = ivaValue + iceValue;

  const currency: string = get(
    props.transaction,
    "converted_currency",
    get(props.transaction, "currency_code", "USD")
  );
  const paymentMethod: IPaymentMethod = {
    height: 20,
    icon: VisaIcon,
    label: "Tarjeta",
    paddingBottom: 1,
    width: 20,
  };
  const cardBrand: IPaymentMethod = mapCardBrands(
    get(props, "transaction.payment_brand", "")
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="body2"
          sx={transactionSectionStyles.textTransactionDetails}
        >
          {LabelField.PAY_METHOD}
        </Typography>
        <Typography component={"span"} variant="body2">
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <img
                height={paymentMethod.height}
                width={paymentMethod.width}
                style={{
                  paddingBottom: paymentMethod.paddingBottom + "px",
                }}
                src={paymentMethod.icon}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2"> {paymentMethod.label}</Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="body2"
          sx={transactionSectionStyles.textTransactionDetails}
        >
          {LabelField.CARD}
        </Typography>
        <Typography component={"span"} variant="body2">
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <img
                height={cardBrand.height}
                width={cardBrand.width}
                style={{
                  paddingBottom: cardBrand.paddingBottom + "px",
                }}
                src={cardBrand.icon}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {" "}
                {"****"}
                {get(props, "transaction.masked_credit_card", "").slice(-4)}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="body2"
          sx={transactionSectionStyles.textTransactionDetails}
        >
          {LabelField.APROVAL_NUMBER}
        </Typography>
        <Typography component={"span"} variant="body2">
          {props.transaction.approval_code}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="body2"
          sx={transactionSectionStyles.textTransactionDetails}
        >
          {LabelField.SUBTOTAL}
        </Typography>
        <Typography component={"span"} variant="body2">
          {formatNumber(subTotal, currency)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="body2"
          sx={transactionSectionStyles.textTransactionDetails}
        >
          {LabelField.TAXES}
        </Typography>
        <Typography component={"span"} variant="body2">
          {formatNumber(taxes, currency)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="body2"
          sx={transactionSectionStyles.textTransactionDetails}
        >
          {LabelField.TOTAL}
        </Typography>
        <Typography component={"span"} variant="body2">
          {formatNumber(
            props.transaction.approved_transaction_amount!,
            currency
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
