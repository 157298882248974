import { SourceEnum } from "@shared/utils/activeAlarmsEnum";

const mixed_columns: string[] = [
  "alias",
  "createdAt",
  "merchantId",
  "merchantName",
  "ownerName",
  "customerName",
  "category",
  "country",
  "frequency",
  "variables",
  "status",
];

export const columnsFileAlarmsBySource: Record<string, string[]> = {
  [SourceEnum.ALARMS]: mixed_columns,
  [SourceEnum.HISTORY]: [...mixed_columns, "deadline"],
};
