import React from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { get } from "lodash";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { FileStateDispersionResponse } from "../../../types/file_state_dispersion_response";
import { requestDispersionStyleError } from "./styles/RequestDispersionError.style";
import successValidation from "../../assets/images/successValidation.png";

export interface IComponentError {
  fileStateDispersionResponse: FileStateDispersionResponse | undefined;
  handleSetValue: (value: number) => void;
}

export const RequestDispersionError: React.FC<IComponentError> = (
  props: IComponentError
) => {
  const classes = requestDispersionStyleError();

  return (
    <React.Fragment>
      <Container>
        <Grid
          container
          direction={"row"}
          spacing={2}
          className={classes.mainGridStatusDispersion}
        >
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <img
                src={successValidation}
                alt={"success"}
                className={classes.resultImage}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.textValidationTitle}>
              {LabelsDispersion.TITLE_DISPERSION_VALIDATION}
            </Typography>
            <Typography className={classes.textValidationBody}>
              {LabelsDispersion.BODY_DISPERSION_VALIDATION}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.textValidationRequest}>
              {LabelsDispersion.NOTIFICATION_DISPERSION_VALIDATION}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.textValidationBody}>
              {get(props.fileStateDispersionResponse, "referenceNumber")}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                props.handleSetValue(1);
              }}
              className={classes.buttonValidation}
              fullWidth
            >
              {LabelsDispersion.GO_BACK_TO_TRANSACTIONS}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
