import React, { PropsWithChildren } from "react";
import { Grid } from "@mui/material";
import { GeneralTextField } from "../GeneralTextField/GeneralTextField";
import { get, isEqual } from "lodash";
import { AmountConditionComponent } from "../AmountConditionComponent/AmountConditionComponent";
import { RuleEnum } from "../../../../shared/enums/RuleEnum";
import { ITextFieldContainerProps } from "../../../../shared/interfaces/text_field_container_props";
import {
  getAccountTypesByCountry,
  getBanksByCountry,
  getDocumentTypesByCountry,
  Labels,
} from "../../../../shared/enums/BusinessRuleEnum";
import { DefaultConditionComponent } from "../DefaultConditionComponent/DefaultConditionComponent";

export const TextFieldContainer: React.FC<ITextFieldContainerProps> = (
  props: PropsWithChildren<ITextFieldContainerProps>
): JSX.Element => {
  const basePath: string = "mainProps.conditionsItemComponentProps";
  const fieldValue: string = get(props, `${basePath}.item.field`, "");
  const itemValueSelected: string[] = isEqual(fieldValue, props.rule)
    ? get(props, `${basePath}.item.value`, "")
    : "";

  switch (props.rule) {
    case RuleEnum.ACCOUNT_TYPE:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            conditionProperty={RuleEnum.ACCOUNT_TYPE}
            className={props.className}
            placeHolder={Labels.ACCOUNT_TYPES}
            items={getAccountTypesByCountry(get(props, "country", []))}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.AMOUNT:
      return (
        <Grid item xs={4}>
          <AmountConditionComponent
            index={get(props, `${basePath}.index`)}
            operator={props.operatorValue}
            setSelectedItems={props.setSelectedItems}
            defaultValueList={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.BANK:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            conditionProperty={RuleEnum.BANK}
            className={props.className}
            placeHolder={Labels.BANKS}
            items={getBanksByCountry(get(props, "country", []))}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.DOCUMENT_TYPE:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            conditionProperty={RuleEnum.DOCUMENT_TYPE}
            className={props.className}
            placeHolder={Labels.DOCUMENT_TYPES}
            items={getDocumentTypesByCountry(get(props, "country", []))}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.VALIDATE_ENROLL:
      return <></>;
    default:
      return (
        <Grid item xs={4}>
          <DefaultConditionComponent
            ruleType={props.rule}
            index={get(props, `${basePath}.index`)}
            setSelectedItems={props.setSelectedItems}
            defaultValueList={itemValueSelected}
          />
        </Grid>
      );
  }
};
