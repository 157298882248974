import { useEffect, useRef, useState } from "react";
import { IUseSubHeaderState } from "./subHeaderState.interfaces";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { IConfigsLastUpdated } from "../../../shared/interfaces/BasicData.interface";
import { INITIAL_LAST_UPDATED } from "../../../shared/constants/InitialBasicData";
import { isEmpty, isEqual, isNil } from "lodash";
import { Routes } from "../../../shared/constants/routes";

export const useSubHeaderState = (title?: string): IUseSubHeaderState => {
  const [openSearchBranchModal, setOpenSearchBranchModal] =
    useState<boolean>(false);
  const [openModalReturn, setOpenModalReturn] = useState<boolean>(false);
  const [hasToGoBack, setHasToGoBack] = useState<boolean>(false);
  const { configsInfo } = useAppSelector(
    (state: RootState) => state.initialData
  );
  const configsLastUpdated = useRef<IConfigsLastUpdated>(INITIAL_LAST_UPDATED);

  const handleOpenModal = (isOpen: boolean) => {
    if (hasToGoBack) {
      window.location.href = Routes.CREATE_NODE_BRANCH_SELECTION;
    } else {
      setOpenModalReturn(isOpen);
    }
  };

  const handleOpenSearchBranchModal = (isOpen: boolean) => {
    setOpenSearchBranchModal(isOpen);
  };

  const haveThreeOrMoreBranches = (branches_name: string) =>
    branches_name.split(",").length > 2;

  const syncLastUpdated = () => {
    configsLastUpdated.current = {
      cn001LastUpdated: configsInfo.cn001.updatedAt!,
      cn002LastUpdated: configsInfo.cn002.updatedAt!,
      cn015LastUpdated: configsInfo.cn015.updatedAt!,
      cn016LastUpdated: configsInfo.cn016.updatedAt!,
    };
  };

  const isNotEmptyConfigsInfo = () =>
    !isEmpty(configsInfo.cn015) &&
    !isEmpty(configsInfo.cn001) &&
    !isEmpty(configsInfo.cn016) &&
    !isEmpty(configsInfo.cn002);

  const someUpdatedValueIsUndefined = () =>
    isNil(configsLastUpdated.current.cn001LastUpdated) ||
    isNil(configsLastUpdated.current.cn002LastUpdated) ||
    isNil(configsLastUpdated.current.cn015LastUpdated) ||
    isNil(configsLastUpdated.current.cn016LastUpdated);

  useEffect(() => {
    if (
      isNotEmptyConfigsInfo() &&
      (isEqual(configsLastUpdated.current, INITIAL_LAST_UPDATED) ||
        someUpdatedValueIsUndefined())
    ) {
      syncLastUpdated();

      return;
    }

    if (
      !someUpdatedValueIsUndefined() &&
      !isEqual(configsLastUpdated.current, INITIAL_LAST_UPDATED)
    ) {
      if (
        configsInfo.cn001.updatedAt !==
        configsLastUpdated.current.cn001LastUpdated
      ) {
        setHasToGoBack(true);
        syncLastUpdated();
      }

      if (
        configsInfo.cn002.updatedAt !==
        configsLastUpdated.current.cn002LastUpdated
      ) {
        setHasToGoBack(true);
        syncLastUpdated();
      }

      if (
        configsInfo.cn015.updatedAt !==
        configsLastUpdated.current.cn015LastUpdated
      ) {
        setHasToGoBack(true);
        syncLastUpdated();
      }

      if (
        configsInfo.cn016.updatedAt !==
        configsLastUpdated.current.cn016LastUpdated
      ) {
        setHasToGoBack(true);
        syncLastUpdated();
      }
    }
  }, [
    configsInfo.cn016.updatedAt,
    configsInfo.cn015.updatedAt,
    configsInfo.cn001.updatedAt,
    configsInfo.cn002.updatedAt,
  ]);

  return {
    handleOpenModal,
    handleOpenSearchBranchModal,
    openModalReturn,
    openSearchBranchModal,
    title: title
      ? title.concat(haveThreeOrMoreBranches(title) ? "..." : "")
      : "",
  };
};
