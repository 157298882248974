import React, { PropsWithChildren } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { cardSectionStyles } from "./CardSection.styles";

export interface CardSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  sectionComponent: JSX.Element;
  isSelectedRule?: boolean;
}

export const CardSection: React.FC<CardSectionProps> = (
  props: PropsWithChildren<CardSectionProps>
) => {
  const classes = cardSectionStyles();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Card className={classes.rootCard} elevation={1}>
          <CardContent>
            <Grid container padding={2} direction="column">
              <Grid item xs={12}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <Typography variant={"h5"}>{props.sectionTitle}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={
                      props.isSelectedRule
                        ? { pointerEvents: "none", opacity: "0.4" }
                        : {}
                    }
                  >
                    <Typography className={classes.description}>
                      {`${props.sectionDescription.split("SPLIT")[0]}`}
                    </Typography>
                    {props.sectionDescription.split("SPLIT")[1] && (
                      <Typography className={classes.description}>
                        {`${props.sectionDescription.split("SPLIT")[1]}`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={
                      props.isSelectedRule
                        ? { pointerEvents: "none", opacity: "0.4" }
                        : {}
                    }
                  >
                    {props.sectionComponent}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
};
