import { CustomStyles } from "../../../shared/infrastructure/interfaces/CustomStyles";

export const emptyBoardMembersStyles: CustomStyles = {
  container: {
    backgroundColor: "#f0f4f9",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3rem",
    marginTop: "2rem",
  },
  text: {
    color: "#6D7781",
    fontSize: "15px",
  },
};
