import React from "react";
import { EventList, ISelectedProduct } from "../interfaces";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

export interface IEventCheckList {
  title: string;
  items: EventList;
  option: string;
  handleStatus: (
    event: React.ChangeEvent<HTMLInputElement>,
    product: ISelectedProduct
  ) => void;
  handleCheck: (product: ISelectedProduct) => boolean;
}

export const EventCheckList: React.FC<IEventCheckList> = ({
  title,
  items,
  handleStatus,
  handleCheck,
  option,
}: IEventCheckList) => {
  return (
    <FormControl variant="outlined" fullWidth margin="none">
      <FormLabel
        sx={{
          fontSize: "16px !important",
          fontWeight: "400 !important",
          lineHeight: "150% !important",
        }}
      >
        {title}
      </FormLabel>
      <Box pt={2}>
        <FormGroup row={false}>
          {Object.entries(items[option]).map(([value, label], index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  color="primary"
                  onChange={(e) => {
                    handleStatus(e, { service: option, status: value });
                  }}
                  checked={handleCheck({ service: option, status: value })}
                />
              }
              label={`${label}`}
            />
          ))}
        </FormGroup>
      </Box>
    </FormControl>
  );
};
