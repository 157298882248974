import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { formSummaryStyles as styles } from "./FormSummaryStyles";
import {
  FormSummaryLabels,
  LabelButton,
} from "../../shared/labels/CheckoutContainer.labels";
import { formatAmount } from "../../shared/utils/utils";
import { useFromSummaryState } from "./state/useFromSummaryState";

export const FormSummary = () => {
  const { checkoutData, currency, iva, isMobile, handleBack, handleCreateQR } =
    useFromSummaryState();

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Typography variant={"h6"} color={"primary"} sx={styles.titleLabel}>
          {FormSummaryLabels.TITLE}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"}>
          {FormSummaryLabels.SUBTOTAL_IVA}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"} sx={styles.amountLabel}>
          {formatAmount(checkoutData.subtotalIva, currency)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"}>
          {FormSummaryLabels.SUBTOTAL_IVA0}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"} sx={styles.amountLabel}>
          {formatAmount(checkoutData.subtotalIva0, currency)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"}>
          {`IVA ${iva}%`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"} sx={styles.amountLabel}>
          {formatAmount(checkoutData.ivaAmount, currency)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"}>
          {FormSummaryLabels.TOTAL}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"h6"} color={"primary"} sx={styles.amountLabel}>
          {formatAmount(checkoutData.totalAmount, currency)}
        </Typography>
      </Grid>
      <Grid item xs={5} md={3}>
        <Typography variant={"h6"} color={"primary"}>
          {FormSummaryLabels.DESCRIPTION}
        </Typography>
      </Grid>
      <Grid item xs={7} md={9}>
        <Typography variant={"h6"} color={"primary"} sx={styles.amountLabel}>
          {checkoutData.description}
        </Typography>
      </Grid>
      <Grid
        data-testid={"buttons-content"}
        item
        md={12}
        sm={12}
        sx={isMobile ? styles.groupButtonMobile : styles.groupButtonWeb}
      >
        <Button
          variant={"text"}
          color="primary"
          onClick={handleBack}
          className={"action-button"}
        >
          {LabelButton.BUTTON_RETURN}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateQR}
          className={"action-button"}
        >
          {LabelButton.BUTTON_QR}
        </Button>
      </Grid>
    </Grid>
  );
};
