import { find } from "lodash";
import { PersonTypeObjectInterface } from "../enum/BasicStateGeneral/FieldsBasicData";

export const findDocTypeCountry = (
  docTypes: PersonTypeObjectInterface[]
): PersonTypeObjectInterface | object => {
  return find(docTypes, { default: true }) ?? {};
};

export const findDocTypeCode = (
  docTypes: PersonTypeObjectInterface[],
  DocumentName: string | undefined
): PersonTypeObjectInterface | object => {
  return find(docTypes, { name: DocumentName }) ?? {};
};

export const findDocTypeValue = (
  docTypes: PersonTypeObjectInterface[],
  DocumentName: string | undefined
): PersonTypeObjectInterface => {
  return (
    find(docTypes, { value: DocumentName }) ?? {
      name: "Error al recuperar TAX ID",
      value: "00",
    }
  );
};
