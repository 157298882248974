import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  hideAlert,
  setValueTab as setValueTabRedux,
} from "../actions/app_actions";
import { AppDispatch, RootState } from "../store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useKshRedux = () => {
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);

  const setValueTab = (valueTab: string) => {
    dispatch(setValueTabRedux(valueTab));
  };

  const handleCloseAlert = () => {
    dispatch(hideAlert());
  };

  return {
    actions: {
      handleCloseAlert,
      setValueTab,
    },
    store,
  };
};
