import React from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "../../assets/images/Edit";
import { routes } from "../../shared/infrastructure/constants/routes";
import { ItemWalletConfigInterface } from "../../../types/dispersion_configuration";
import { WALLET_TYPE_MAPPED } from "../../shared/infrastructure/constants/wallet-type-enum";

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: "25.4px",
  },
  typo: {
    color: "#6D7781",
    marginBottom: 6,
    fontSize: "14px",
  },
  typo2: {
    color: "#293036",
    marginBottom: 6,
    fontSize: "14px",
  },
  gridItem: {
    padding: "0px 12px !important",
    width: "300px",
  },
  containerPadding: {
    paddingTop: "10px",
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#F7FAFC",
  },
  headerTitle: {
    fontWeight: "bold",
    marginRight: "2",
  },
  headerGrid: {
    textAlign: "left",
  },
  chip: {
    borderRadius: 4,
    background: "#F0F4F9",
    fontSize: "13px",
  },
  button: {
    backgroundColor: "#F0F4F9",
    borderRadius: 4,
  },
  link: {
    color: "#1E65AE",
    lineHeight: "170%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    cursor: "pointer",
  },
}));

export interface IRechargeWallet {
  merchantId: string;
  itemWalletConfig: ItemWalletConfigInterface;
}

export const RechargeWallet: React.FC<IRechargeWallet> = (
  props: React.PropsWithChildren<IRechargeWallet>
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Card className={style.cardContainer} elevation={0}>
        <CardContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Grid item>
              <Typography className={style.headerTitle}>
                Configuración de recarga automática de Wallet
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={style.button}
                href={`${routes.WALLET}/${props.merchantId}`}
              >
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className={style.containerPadding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
            spacing={3}
          >
            <Grid item>
              <Typography className={style.typo2}>
                Configura la recarga automática de wallet parcial o total del
                comercio. Aplica solo si el comercio tiene configurados
                procesadores Payins y Payouts.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={style.containerPadding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
            spacing={3}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>Tipo de recarga:</Typography>
            </Grid>
            <Grid item className={style.gridItem}>
              <Typography className={style.typo2}>
                {WALLET_TYPE_MAPPED[props.itemWalletConfig.type!]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={style.containerPadding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
            spacing={3}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>
                Porcentaje (%) seleccionado:
              </Typography>
            </Grid>
            <Grid item className={style.gridItem}>
              <Chip
                label={`${props.itemWalletConfig.rechargePercentage}%`}
                className={style.chip}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
