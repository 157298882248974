import React from "react";
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { balanceDataStyles as styles } from "./BalanceData.styles";
import { InfoOutlined } from "@mui/icons-material";
import {
  WalletDetailTitleEnum,
  WalletDetailTooltipTextEnum,
} from "../../shared/infrastructure/constants/WalletResumeConstants";
import { CURRENCY_COUNTRY } from "../../shared/utils/currencyFormat";
import { defaultTo } from "lodash";
import { IBalanceData, IBalanceDetail } from "./BalanceData.interfaces";
import { formatCurrencyWallet } from "../../shared/utils/walletUtils";

const BalanceDataDetail: React.FC<IBalanceDetail> = ({
  title,
  tooltipText,
  value,
  currency,
}: IBalanceDetail) => {
  return (
    <Grid container item spacing={1} sx={styles.containerGrid}>
      <Grid item sx={styles.itemContainer}>
        <Tooltip title={tooltipText} placement={"top"} arrow>
          <InfoOutlined fontSize="small" color={"primary"}></InfoOutlined>
        </Tooltip>
      </Grid>
      <Grid item sx={styles.itemContainer}>
        <Typography
          variant="h6"
          display={"flex"}
          flexDirection={"row"}
          color="text.primary"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="text.primary" variant="subtitle1" noWrap>
          {value} {currency}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BalanceData: React.FC<IBalanceData> = ({
  balanceData,
  country,
}: IBalanceData) => {
  return (
    <Box sx={styles.container}>
      <BalanceDataDetail
        title={WalletDetailTitleEnum.BALANCE}
        tooltipText={WalletDetailTooltipTextEnum.BALANCE}
        value={formatCurrencyWallet(defaultTo(balanceData.balance, 0), country)}
        currency={defaultTo(CURRENCY_COUNTRY.get(country), "")}
      />
      <Divider orientation="vertical" flexItem sx={styles.dividerVertical} />
      <BalanceDataDetail
        title={WalletDetailTitleEnum.COUNTABLE_BALANCE}
        tooltipText={WalletDetailTooltipTextEnum.COUNTABLE_BALANCE}
        value={formatCurrencyWallet(
          defaultTo(balanceData.countableBalance, 0),
          country
        )}
        currency={defaultTo(CURRENCY_COUNTRY.get(country), "")}
      />
      <Divider orientation="vertical" flexItem sx={styles.dividerVertical} />
      <BalanceDataDetail
        title={WalletDetailTitleEnum.UNPROCESSED_BALANCE}
        tooltipText={WalletDetailTooltipTextEnum.UNPROCESSED_BALANCE}
        value={formatCurrencyWallet(
          defaultTo(balanceData.unprocessedBalance, 0),
          country
        )}
        currency={defaultTo(CURRENCY_COUNTRY.get(country), "")}
      />
    </Box>
  );
};

export default BalanceData;
