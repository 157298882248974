import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const fieldValueDetailCardStyles = createNamedStyles({
  informationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "24px",
  },
  information: {
    display: " flex",
    justifyContent: "space-between",
    gap: "1rem",
  },
  textLight: {
    color: "#6D7781",
  },
  textDark: {
    color: "#293036",
  },
  textValues: {
    color: "#293036",
    wordWrap: "break-word",
    width: "50%",
    textAlign: "end",
  },
});
