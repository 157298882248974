import { MerchantsData } from "../../store/actionCreators";
import { MerchantInfo } from "../../../types/merchant-info";

export const filterMerchants = (
  merchantDataObject: MerchantsData
): MerchantsData => {
  const tempMerchantData: MerchantsData = { ...merchantDataObject };
  tempMerchantData.data = merchantDataObject.data.filter(
    (merchantElastic: { _source: MerchantInfo }) =>
      merchantElastic._source.name && merchantElastic._source.country
  );
  tempMerchantData.total = tempMerchantData.data.length;

  return tempMerchantData;
};
