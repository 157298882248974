import { IStyles } from "../../../shared/interfaces/Styles.interfaces";
import theme from "../../../theme";

export const GenericModalStyles: IStyles = {
  BoxContent: {
    padding: "24px 32px",
    display: "flex",
    flexGrow: 1,
  },
  CloseButton: {
    alignItems: "flex-start",
    display: "flex",
    color: theme.palette.primary.dark,
  },
  dialogMessage: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "120%",
  },
  dialogPadding: { padding: "24px 32px" },
  dialogTitle: {
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
  },
  paddingB3: { paddingBottom: "3px" },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: 16,
    margin: 0,
    padding: 1,
    textAlign: "left",
  },
  textTitle: {
    fontSize: "24px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
};
