import { Box, Skeleton } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { ICellSelectItemProps } from "./CellSelectItem.interfaces";
import { cellSelectItemStyles } from "./CellSelectItemStyles";
import useCellSelectItem from "./useCellSelectItem";
import SelectItemComplete from "../../SelectItemComplete/SelectItemComplete";
import { FLEX_JUSTIFY_BY_ALIGN } from "../../Table/TableBodySimple/constants";

export const CellSelectItem: FC<ICellSelectItemProps> = (
  props: PropsWithChildren<ICellSelectItemProps>
) => {
  const { label, id, items, cellProps, isCountry } = props;

  const { disable, value, error, handleChange, isLoading, required } =
    useCellSelectItem(props);

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      alignItems={FLEX_JUSTIFY_BY_ALIGN[cellProps?.align || "center"]}
      justifyContent={"center"}
      sx={{
        minWidth: `${(cellProps?.minWidth ?? 1) * 3}em`,
      }}
    >
      {isLoading ? (
        <Skeleton
          data-testid={"skeleton"}
          variant={"rectangular"}
          sx={cellSelectItemStyles.skeletonIcon}
        />
      ) : (
        <SelectItemComplete
          hasError={error}
          required={required}
          id={id}
          label={label}
          items={items}
          value={value}
          disable={disable}
          handleOnChange={handleChange}
          isCountry={!!isCountry}
        />
      )}
    </Box>
  );
};
