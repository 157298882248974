import React, { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { RegExpEnum } from "../../../../../shared/infrastructure/enums/RegExpEnum";
import { ITransactionBinProps } from "../../../../../shared/infrastructure/interfaces/ITransactionBinProps";
import { RuleEnum } from "../../../../../shared/infrastructure/enums/RuleEnum";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export interface IUseBinTextFieldState {
  handleOnChangeTextField: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  isValidFiledTextField: boolean;
  value: any;
}

export const useBinTextFieldState = (
  props: ITransactionBinProps
): IUseBinTextFieldState => {
  const [isValidFiledTextField, setIsValidFiledTextField] =
    useState<boolean>(false);

  const [binValue, setBinValue] = useState<string>("");
  const [textFieldList] = useState<string[]>(props.defaultListTextField || []);

  useEffect(() => {
    const dataToRetrieve: ICategory[] = textFieldList
      .filter((data: string) => !isEmpty(data))
      .map((data) => {
        setBinValue(data);
        return {
          label: RuleEnum.BIN,
          value: data,
          selected: true,
        };
      });

    props.setSelectedItems(dataToRetrieve);
  }, [textFieldList]);

  const handleOnChangeTextField = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const value: string = get(event, "target.value", "");
    if (RegExpEnum.REGEXP_ONLY_NUMBERS.test(value)) {
      setBinValue(value);
    }

    if (value.length < 6) {
      setIsValidFiledTextField(false);
      return;
    }
    setIsValidFiledTextField(true);
    setBinValue(value);
    props.setSelectedItems([
      {
        label: RuleEnum.BIN,
        value: value,
        selected: true,
      },
    ]);
  };

  return {
    handleOnChangeTextField,
    isValidFiledTextField,
    value: binValue,
  };
};
