/**
 * Catalog Panama
 */
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { Category } from "../constants/information_constants";
import { CurrencyEnum } from "../interfaces/currency-enum";

export const CatalogPan: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", value: "0", name: "CIP" },
    { defaultShareholderDocType: "0", value: "1", name: "NIT" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "1100001", name: "AUSTROBANK (OVERSEAS) PANAMA S.A." },
    { value: "1100002", name: "BANCAFE (BANCO CAFETERO)" },
    { value: "1100003", name: "BANCO ALIADO S.A." },
    { value: "1100004", name: "BANCO BILBAO VIZCAYA ARGENTARIA (PANAMA) S.A." },
    { value: "1100005", name: "BANCO CUSCATLAN DE PANAMA S.A." },
    { value: "1100006", name: "BANCO DE BOGOTA S.A." },
    { value: "1100007", name: "BANCO DE GUAYAQUIL (PANAMA) S.A." },
    { value: "1100008", name: "BANCO DE LA NACION ARGENTINA" },
    { value: "1100009", name: "BANCO DE OCCIDENTE (PANAMA) S.A." },
    { value: "1100010", name: "BANCO DEL PACIFICO (PANAMA) S.A." },
    { value: "1100011", name: "BANCO GENERAL S.A." },
    { value: "1100012", name: "BANCO INTERNACIONAL DE COSTA RICA S.A." },
    { value: "1100013", name: "BANCO LAFISE PANAMA" },
    {
      value: "1100014",
      name: "BANCO LATINOAMERICANO DE COMERCIO EXTERIOR S.A.",
    },
    { value: "1100015", name: "BANCO NACIONAL DE PANAMA" },
    { value: "1100016", name: "BANCO PICHINCHA PANAMA" },
    { value: "1100017", name: "BANCO TRASATLANTICO S.A." },
    { value: "1100018", name: "BANCO UNION SUCURSAL PANAMA" },
    { value: "1100019", name: "BANCO UNIVERSAL S.A." },
    { value: "1100020", name: "BANCOLOMBIA PANAMA S.A." },
    { value: "1100021", name: "BANESCO INTERNATIONAL BANK. INC." },
    { value: "1100022", name: "BANK LEUMI LE ISRAEL B.M" },
    { value: "1100023", name: "BANK OF CHINA PANAMA BRANCH" },
    { value: "1100024", name: "BANK OF NOVA SCOTIA" },
    { value: "1100025", name: "BCT BANK INTERNATIONAL S.A." },
    { value: "1100026", name: "BLUBANK LTD." },
    { value: "1100027", name: "BNP PARIBAS WEALTH MANAGEMENT SUCURSAL PANAMA" },
    { value: "1100028", name: "BNP PARIBAS-PANAMA BRANCH" },
    { value: "1100029", name: "CAPITAL BANK INC." },
    { value: "1100030", name: "CITIBANK N.A. SUCURSAL PANAMA" },
    { value: "1100031", name: "CREDICORP BANK S.A" },
    { value: "1100032", name: "ES BANK (PANAMA) S.A." },
    { value: "1100033", name: "FIRST CENTRAL INTERNATIONAL BANK INC." },
    { value: "1100034", name: "FPB INTERNATIONAL BANK INC." },
    { value: "1100035", name: "GLOBAL BANK CORPORATION" },
    { value: "1100036", name: "GNB SUDAMERIS BANK S.A." },
    { value: "1100037", name: "HELM BANK (PANAMA)" },
    { value: "1100038", name: "HSBC BANK USA PANAMA" },
    { value: "1100039", name: "INTERNATIONAL UNION BANK S.A." },
    { value: "1100040", name: "KOREA EXCHANGE BANK" },
    { value: "1100041", name: "MEGA INTERNATIONAL COMMERCIAL BANK CO. LTD." },
    { value: "1100042", name: "MERCANTIL BANK (PANAMA) S.A." },
    { value: "1100043", name: "METROBANK S.A." },
    { value: "1100044", name: "MMG BANK CORPORATION" },
    { value: "1100045", name: "MULTIBANK INC." },
    { value: "1100046", name: "POPULAR BANK LTD. INC." },
    { value: "1100047", name: "PRODUBANK PANAMA S.A." },
    { value: "1100048", name: "STANFORD BANK (PANAMA) S.A." },
    { value: "1100049", name: "TOWERBANK INTERNATIONAL INC." },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: CurrencyEnum.USD, name: "Dólares" }],
  [CatalogsEnum.Provinces]: [
    { value: "01", name: "Bocas del Toro" },
    { value: "02", name: "Coclé" },
    { value: "03", name: "Colón" },
    { value: "04", name: "Chiriquí" },
    { value: "05", name: "Herrera" },
    { value: "06", name: "Los Santos" },
    { value: "07", name: "Panamá" },
    { value: "07", name: "Panamá Oeste" },
    { value: "08", name: "Veraguas" },
    { value: "09", name: "Guna Yala" },
    { value: "10", name: "Ngäbe-Buglé" },
    { value: "11", name: "Wargandi" },
    { value: "12", name: "Darién" },
    { value: "12", name: "Emberá-Wounaan" },
  ],
  [CatalogsEnum.Cities]: [
    { value: "01001", name: "Bocas del Toro", parent: "01" },
    { value: "01006", name: "Changuinola", parent: "01" },
    { value: "01013", name: "Chiriquí Grande", parent: "01" },
    { value: "02001", name: "Antón", parent: "02" },
    { value: "02011", name: "Penonomé", parent: "02" },
    { value: "02021", name: "La Pintada", parent: "02" },
    { value: "02027", name: "Natá", parent: "02" },
    { value: "02034", name: "Olá", parent: "02" },
    { value: "02038", name: "Aguadulce", parent: "02" },
    { value: "03001", name: "Colón", parent: "03" },
    { value: "03015", name: "Chagres", parent: "03" },
    { value: "03022", name: "Donoso", parent: "03" },
    { value: "03028", name: "Portobelo", parent: "03" },
    { value: "03033", name: "Santa Isabel", parent: "03" },
    { value: "04001", name: "David", parent: "04" },
    { value: "04011", name: "Barú", parent: "04" },
    { value: "04016", name: "Bugaba", parent: "04" },
    { value: "04030", name: "Alanje", parent: "04" },
    { value: "04037", name: "Boquerón", parent: "04" },
    { value: "04046", name: "Renacimiento", parent: "04" },
    { value: "04053", name: "Boquete", parent: "04" },
    { value: "04059", name: "Dolega", parent: "04" },
    { value: "04067", name: "Gualaca", parent: "04" },
    { value: "04072", name: "Remedios", parent: "04" },
    { value: "04077", name: "San Lorenzo", parent: "04" },
    { value: "04082", name: "Tolé", parent: "04" },
    { value: "04091", name: "San Félix", parent: "04" },
    { value: "05001", name: "Chitré", parent: "05" },
    { value: "05007", name: "Los Pozos", parent: "05" },
    { value: "05015", name: "Parita", parent: "05" },
    { value: "05022", name: "Pesé", parent: "05" },
    { value: "05031", name: "Las Minas", parent: "05" },
    { value: "05038", name: "Ocú", parent: "05" },
    { value: "05045", name: "Santa María", parent: "05" },
    { value: "06001", name: "Las Tablas", parent: "06" },
    { value: "06025", name: "Pocrí", parent: "06" },
    { value: "06031", name: "Guararé", parent: "06" },
    { value: "06040", name: "Los Santos", parent: "06" },
    { value: "06054", name: "Macaracas", parent: "06" },
    { value: "06065", name: "Pedasí", parent: "06" },
    { value: "06070", name: "Tonosí", parent: "06" },
    { value: "07001", name: "Arraiján", parent: "07" },
    { value: "07009", name: "Capira", parent: "07" },
    { value: "07023", name: "Chame", parent: "07" },
    { value: "07033", name: "San Carlos", parent: "07" },
    { value: "07042", name: "Balboa", parent: "07" },
    { value: "07048", name: "Chepo", parent: "07" },
    { value: "07055", name: "Chimán", parent: "07" },
    { value: "07060", name: "Taboga", parent: "07" },
    { value: "07063", name: "Panamá", parent: "07" },
    { value: "07064", name: "La Chorrera", parent: "07" },
    { value: "07081", name: "San Miguelito", parent: "07" },
    { value: "08001", name: "Santiago", parent: "08" },
    { value: "08013", name: "La Mesa", parent: "08" },
    { value: "08019", name: "Soná", parent: "08" },
    { value: "08029", name: "Calobre", parent: "08" },
    { value: "08041", name: "San Francisco", parent: "08" },
    { value: "08047", name: "Santa Fé", parent: "08" },
    { value: "08054", name: "Atalaya", parent: "08" },
    { value: "08059", name: "Montijo", parent: "08" },
    { value: "08067", name: "Río de Jesús", parent: "08" },
    { value: "08072", name: "Mariato", parent: "08" },
    { value: "08077", name: "Cañazas", parent: "08" },
    { value: "08084", name: "Las Palmas", parent: "08" },
    { value: "09001", name: "Guna de Wargandí", parent: "09" },
    { value: "10001", name: "Kakintú", parent: "10" },
    { value: "10010", name: "Kusapín", parent: "10" },
    { value: "10017", name: "Besiko", parent: "10" },
    { value: "10025", name: "Mironó", parent: "10" },
    { value: "10033", name: "Nole Duima", parent: "10" },
    { value: "10039", name: "Müna", parent: "10" },
    { value: "10051", name: "Ñürüm", parent: "10" },
    { value: "11001", name: "Wargandi", parent: "11" },
    { value: "12001", name: "Chepigana", parent: "12" },
    { value: "12017", name: "Sambú", parent: "12" },
    { value: "12019", name: "Pinogana", parent: "12" },
    { value: "12028", name: "Cémaco", parent: "12" },
  ],
};
