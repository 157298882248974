import {
  CompliancePeopleNodeResponse,
  LegalRepresentative,
  Shareholder,
} from "../../../types/get_compliance_people_response";
import {
  ILegalRepresentative,
  IShareHolder,
  IShareHolderRequest,
  PersonCategoryEnum,
} from "../../store/interfaces/LegalDetailsState.interfaces";
import { v4 } from "uuid";
import { get, isEmpty } from "lodash";
import {
  transformDateToTimestamp,
  transformUnixTimestampToFormatDate,
  verifyDate,
} from "../../shared/utils/date_utils";
import { DateFormatEnum } from "../../shared/enums/DateFormatEnum";
import { PersonTypeEnum } from "../../shared/constants/initial_state_legal_details";
import { ShareholderFields } from "../../shared/constants/shareholder_constants";
import { LegalRepresentativeTypeEnum } from "../../shared/enums/LegalRepresentativeTypeEnum";
import { ILegalRepresentatives } from "./LegalRepresentatives.interface";
import { getCountryName } from "../../shared/constants/Countries";

export const getLegalRepresentativeType = (
  isMain: boolean
): LegalRepresentativeTypeEnum => {
  return isMain
    ? LegalRepresentativeTypeEnum.main
    : LegalRepresentativeTypeEnum.secundary;
};

export const buildLegalRepresentative = (
  representative: LegalRepresentative
): ILegalRepresentative => {
  const auxId: string = v4();

  const getBirthdate = (): number | string => {
    const dateNumber = get(representative, ShareholderFields.BIRTHDATE);

    const isNumberDate: boolean = typeof dateNumber === "number";

    return isNumberDate
      ? verifyDate(isNumberDate ? dateNumber : undefined)
      : get(representative, ShareholderFields.BIRTHDATE, "");
  };

  return {
    areaCode: get(representative, "areaCode"),
    birthdate: getBirthdate(),
    cellphoneNumber: get(representative, "cellphoneNumber", ""),
    constitutionDate: transformUnixTimestampToFormatDate(
      get(representative, "constitutionDate", new Date().getTime()),
      DateFormatEnum.DD_MMM_YYYY
    ),
    country: get(representative, "residencyCountry", ""),
    countryCode: get(representative, "countryCode"),
    documentNumber: get(representative, "documentNumber"),
    documentType: get(representative, "documentType", ""),
    email: get(representative, "email", ""),
    id: get(representative, "id", auxId),
    isMain: representative.isMain,
    isShareHolder: get(representative, "isShareHolder", false),
    lastName: get(representative, "lastNames", ""),
    merchantAdress: getCountryName(get(representative, "addressCountry")),
    merchantCountry: get(representative, "originCountry"),
    name: get(representative, "names", ""),
    nationality: getCountryName(get(representative, "nationality", "")),
    participationPercentage: get(representative, "participationPercentage"),
    personCategory: get(
      representative,
      "personCategory",
      PersonCategoryEnum.REPRESENTATIVE
    ),
    personType: get(representative, "personType"),
    residencyCountry: getCountryName(
      get(representative, "residencyCountry", "")
    ),
    socialReason: get(representative, "socialReason"),
    taxId: get(representative, "documentNumber"),
    type: getLegalRepresentativeType(representative.isMain),
    uid: get(representative, "uid", auxId),
  };
};

export const buildShareholder = (shareholder: Shareholder): IShareHolder => {
  return shareholder.personType === PersonTypeEnum.FISICA
    ? {
        birthdate: verifyDate(get(shareholder, "birthDate")),
        children: [],
        country: get(shareholder, ShareholderFields.RESIDENCY_COUNTRY, ""),
        documentNumber: get(shareholder, ShareholderFields.DOCUMENT_NUMBER),
        documentType: get(shareholder, ShareholderFields.DOCUMENT_TYPE),
        id: get(shareholder, ShareholderFields.ID),
        lastName: get(shareholder, `${ShareholderFields.LAST_NAME}s`),
        level: 0,
        name: get(shareholder, `${ShareholderFields.NAME}s`),
        parent: get(shareholder, ShareholderFields.PARENT),
        participationPercentage: get(
          shareholder,
          ShareholderFields.PARTICIPATION_PERCENTAGE,
          "0"
        ),
        personCategory: get(
          shareholder,
          ShareholderFields.PERSON_CATEGORY,
          "SHAREHOLDER"
        ),
        personType: PersonTypeEnum.FISICA,
        uid: get(shareholder, ShareholderFields.UID),
      }
    : {
        children: [],
        companyCountry: get(shareholder, ShareholderFields.ADDRESS_COUNTRY, ""),
        constitutionDate: transformUnixTimestampToFormatDate(
          get(shareholder, "constitutionDate", new Date().getTime()),
          DateFormatEnum.DD_MMM_YYYY
        ),
        country: get(shareholder, ShareholderFields.ADDRESS_COUNTRY, ""),
        id: get(shareholder, ShareholderFields.ID),
        level: 0,
        merchantCountry: get(shareholder, ShareholderFields.ORIGIN_COUNTRY),
        parent: get(shareholder, ShareholderFields.PARENT),
        participationPercentage: get(
          shareholder,
          ShareholderFields.PARTICIPATION_PERCENTAGE,
          "0"
        ),
        personCategory: get(
          shareholder,
          ShareholderFields.PERSON_CATEGORY,
          "SHAREHOLDER"
        ),
        personType: PersonTypeEnum.MORAL,
        socialReason: get(shareholder, ShareholderFields.SOCIAL_REASON),
        taxId: get(shareholder, ShareholderFields.DOCUMENT_NUMBER),
        uid: get(shareholder, ShareholderFields.UID),
      };
};

export const makeTree = (data: IShareHolder[]) => {
  let i = -1;
  const tree: object = data
    .sort((shareholder, shareholder2) => {
      if (isEmpty(shareholder.parent)) return -1;
      if (isEmpty(shareholder2.parent)) return 1;

      return 0;
    })
    .reduce((unflatten: object, shareholder: IShareHolder) => {
      const parentValue = get(shareholder, "parent", "");
      const idValue = get(shareholder, "id", "");

      unflatten[idValue] = unflatten[idValue] || shareholder;

      unflatten[parentValue] = unflatten[parentValue] || {};
      const parent = unflatten[parentValue];

      parent.children = parent.children || [];

      i += 1;
      shareholder.level = i;
      shareholder.children = [];

      parent.children.push(shareholder);

      return unflatten;
    }, {});

  return Object.values(tree).find((e) => e.id === undefined).children;
};

export const transformCompliancePeople = (
  compliancePeople: CompliancePeopleNodeResponse
): ILegalRepresentatives => {
  let shareholders: IShareHolder[] = [];

  const legalRepresentatives = compliancePeople.legalRepresentatives.map(
    (representative: LegalRepresentative) => {
      return buildLegalRepresentative(representative);
    }
  );

  if (!isEmpty(compliancePeople.shareholders)) {
    const noTreeShareholders: IShareHolder[] =
      compliancePeople.shareholders.map((shareholder: Shareholder) => {
        return buildShareholder(shareholder);
      });

    shareholders = makeTree(noTreeShareholders);
  }

  return { legalRepresentatives, shareholders };
};

export const compliancePeople: CompliancePeopleNodeResponse = {
  boardmembers: [],
  constitutionalCountry: "Mexico",
  country: "Ecuador",
  legalRepresentatives: [
    {
      areaCode: "",
      birthDate: 1661562000000,
      cellphoneNumber: "57 123123123",
      countryCode: "57",
      documentNumber: "123123123123",
      documentType: "0",
      email: "1@1.1",
      id: "848291fc-a89d-466c-866b-07136bca2e69",
      isMain: true,
      isShareHolder: false,
      lastNames: "asd",
      names: "asd",
      nationality: "Aruba",
      participationPercentage: "12",
      personCategory: "REPRESENTATIVE",
      personType: "01",
      residencyCountry: "Austria",
    },
    {
      addressCountry: "Brazil",
      areaCode: "",
      cellphoneNumber: "55 123123123",
      constitutionDate: 1661472000000,
      countryCode: "55",
      documentNumber: "123123123123",
      email: "1@1.1",
      id: "ac7914db-7991-402b-86a4-5c8b9c14dd4d",
      isMain: false,
      isShareHolder: false,
      originCountry: "Brazil",
      participationPercentage: "0",
      personCategory: "REPRESENTATIVE",
      personType: "02",
      socialReason: "asda",
    },
  ],
  merchantId: "20000000100149633000",
  merchantName: "test",
  shareholders: [
    {
      birthDate: 1661472000,
      documentNumber: "1231231",
      documentType: "1",
      id: "3606f9e7-7ae8-41b0-b3d5-5914b1b7252b",
      lastNames: "21eas",
      names: "fasdas",
      parent: "37fc07d9-3eac-4c3c-9a5f-0a428646bb16",
      participationPercentage: "24",
      personCategory: "SHAREHOLDER",
      personType: "01",
      residencyCountry: "Ecuador",
    },
    {
      addressCountry: "Bangladesh",
      constitutionDate: 1661472000,
      documentNumber: "12312312",
      id: "37fc07d9-3eac-4c3c-9a5f-0a428646bb16",
      originCountry: "Bielorrusia",
      parent: "",
      participationPercentage: "23",
      personCategory: "SHAREHOLDER",
      personType: "02",
      socialReason: "asdas",
    },
    {
      birthDate: 1661472000,
      documentNumber: "1231231",
      documentType: "1",
      id: "test",
      lastNames: "1312",
      names: "awfafwafwaw1",
      parent: "3606f9e7-7ae8-41b0-b3d5-5914b1b7252b",
      participationPercentage: "24",
      personCategory: "SHAREHOLDER",
      personType: "01",
      residencyCountry: "Ecuador",
    },
  ],
};

export const createShRequest = (item: IShareHolder): IShareHolderRequest => {
  if (item.personType === PersonTypeEnum.FISICA) {
    return {
      birthDate: transformDateToTimestamp(
        get(item, "birthdate", ""),
        DateFormatEnum.DD_MMM_YYYY
      ),
      documentNumber: get(item, "documentNumber", ""),
      documentType: get(item, "documentType", ""),
      id: get(item, "id", ""),
      lastNames: get(item, "lastName", ""),
      names: get(item, "name", ""),
      parent: get(item, "parent", ""),
      participationPercentage: get(item, "participationPercentage", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType"),
      residencyCountry: get(item, "country", ""),
      uid: get(item, "uid", ""),
    };
  } else {
    return {
      addressCountry: get(item, "companyCountry", ""),
      constitutionDate: transformDateToTimestamp(
        get(item, "constitutionDate", ""),
        DateFormatEnum.DD_MMM_YYYY
      ),
      documentNumber: get(item, "taxId", ""),
      id: get(item, "id", ""),
      originCountry: get(item, "merchantCountry", ""),
      parent: get(item, "parent", ""),
      participationPercentage: get(item, "participationPercentage", ""),
      personCategory: get(item, "personCategory") as PersonCategoryEnum,
      personType: get(item, "personType") as PersonTypeEnum,
      socialReason: get(item, "socialReason", ""),
      uid: get(item, "uid", ""),
    };
  }
};
