export enum HeaderColumnIdEnum {
  AMOUNT = "approved_transaction_amount",
  CREATED = "created",
  COMPLETED = "completed",
  METHOD = "method",
  TICKET_NUMBER = "ticket_number",
  CARD_NUMBER = "card_number",
  CARD_BRAND = "payment_brand",
  ISSUING_BANK = "issuing_bank.keyword",
  SUBSCRIPTION_ID = "subscription",
  NAME_ON_CARD = "name_on_card",
  EMAIL = "email",
  CREDENTIAL_ALIASES = "credential_aliases",
  CREDENTIAL_ID = "credential_id",
  PUBLIC_KEY = "public_key",
  STATUS = "status",
  TYPE = "type",
  DEFAULT_VALUE = "-",
  DEFAULT_OWNER_CUSTOMER = "Kushki 2.0",
  OWNER = "owner_name",
  CUSTOMER = "customer_name",
  BRANCH = "merchant_name",
}

export enum HeaderTooltipLabelEnum {
  CREATED = "Fecha de inicio de la transacción",
  COMPLETED = "Fecha final de la transacción (estado final)",
}

export const headTooltip: string[] = [
  HeaderColumnIdEnum.CREATED,
  HeaderColumnIdEnum.COMPLETED,
];

export const allowedSortingHeaderEnum = [
  "created",
  "merchant_name",
  "payment_brand",
  "issuing_bank.keyword",
  "approved_transaction_amount",
  "transaction_status",
];

export const TOOLTIP_LABELS = {
  [HeaderColumnIdEnum.CREATED]: HeaderTooltipLabelEnum.CREATED,
  [HeaderColumnIdEnum.COMPLETED]: HeaderTooltipLabelEnum.COMPLETED,
};
