/**
 * CatalogCl
 */
import { CatalogsEnum } from "../enums/CatalogsEnum";
import { Category } from "../constants/MerchantInformationConstants";

export const CatalogCl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "Run", value: "0" },
    { name: "Pasaporte", value: "1" },
    { name: "Rut", value: "2" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "IQUIQUE", value: "01101" },
    { name: "ALTO HOSPICIO", value: "01107" },
    { name: "POZO ALMONTE", value: "01401" },
    { name: "CAMIÑA", value: "01402" },
    { name: "COLCHANE", value: "01403" },
    { name: "HUARA", value: "01404" },
    { name: "PICA", value: "01405" },
    { name: "ANTOFAGASTA", value: "02101" },
    { name: "MEJILLONES", value: "02102" },
    { name: "SIERRA GORDA", value: "02103" },
    { name: "TALTAL", value: "02104" },
    { name: "CALAMA", value: "02201" },
    { name: "OLLAGÜE", value: "02202" },
    { name: "SAN PEDRO DE ATACAMA", value: "02203" },
    { name: "TOCOPILLA", value: "02301" },
    { name: "MARÍA ELENA", value: "02302" },
    { name: "COPIAPÓ", value: "03101" },
    { name: "CALDERA", value: "03102" },
    { name: "TIERRA AMARILLA", value: "03103" },
    { name: "CHAÑARAL", value: "03201" },
    { name: "DIEGO DE ALMAGRO", value: "03202" },
    { name: "VALLENAR", value: "03301" },
    { name: "ALTO DEL CARMEN", value: "03302" },
    { name: "FREIRINA", value: "03303" },
    { name: "HUASCO", value: "03304" },
    { name: "LA SERENA", value: "04101" },
    { name: "COQUIMBO", value: "04102" },
    { name: "ANDACOLLO", value: "04103" },
    { name: "LA HIGUERA", value: "04104" },
    { name: "PAIHUANO", value: "04105" },
    { name: "VICUÑA", value: "04106" },
    { name: "ILLAPEL", value: "04201" },
    { name: "CANELA", value: "04202" },
    { name: "LOS VILOS", value: "04203" },
    { name: "SALAMANCA", value: "04204" },
    { name: "OVALLE", value: "04301" },
    { name: "COMBARBALÁ", value: "04302" },
    { name: "MONTE PATRIA", value: "04303" },
    { name: "PUNITAQUI", value: "04304" },
    { name: "RÍO HURTADO", value: "04305" },
    { name: "VALPARAÍSO", value: "05101" },
    { name: "CASABLANCA", value: "05102" },
    { name: "CONCÓN", value: "05103" },
    { name: "JUAN FERNÁNDEZ", value: "05104" },
    { name: "PUCHUNCAVÍ", value: "05105" },
    { name: "QUINTERO", value: "05107" },
    { name: "VIÑA DEL MAR", value: "05109" },
    { name: "ISLA DE PASCUA", value: "05201" },
    { name: "LOS ANDES", value: "05301" },
    { name: "CALLE LARGA", value: "05302" },
    { name: "RINCONADA", value: "05303" },
    { name: "SAN ESTEBAN", value: "05304" },
    { name: "LA LIGUA", value: "05401" },
    { name: "CABILDO", value: "05402" },
    { name: "PAPUDO", value: "05403" },
    { name: "PETORCA", value: "05404" },
    { name: "ZAPALLAR", value: "05405" },
    { name: "QUILLOTA", value: "05501" },
    { name: "LA CALERA", value: "05502" },
    { name: "HIJUELAS", value: "05503" },
    { name: "LA CRUZ", value: "05504" },
    { name: "NOGALES", value: "05506" },
    { name: "SAN ANTONIO", value: "05601" },
    { name: "ALGARROBO", value: "05602" },
    { name: "CARTAGENA", value: "05603" },
    { name: "EL QUISCO", value: "05604" },
    { name: "EL TABO", value: "05605" },
    { name: "SANTO DOMINGO", value: "05606" },
    { name: "SAN FELIPE", value: "05701" },
    { name: "CATEMU", value: "05702" },
    { name: "LLAY-LLAY", value: "05703" },
    { name: "PANQUEHUE", value: "05704" },
    { name: "PUTAENDO", value: "05705" },
    { name: "SANTA MARÍA", value: "05706" },
    { name: "QUILPUÉ", value: "05801" },
    { name: "LIMACHE", value: "05802" },
    { name: "OLMUÉ", value: "05803" },
    { name: "VILLA ALEMANA", value: "05804" },
    { name: "RANCAGUA", value: "06101" },
    { name: "CODEGUA", value: "06102" },
    { name: "COINCO", value: "06103" },
    { name: "COLTAUCO", value: "06104" },
    { name: "DOÑIHUE", value: "06105" },
    { name: "GRANEROS", value: "06106" },
    { name: "LAS CABRAS", value: "06107" },
    { name: "MACHALÍ", value: "06108" },
    { name: "MALLOA", value: "06109" },
    { name: "MOSTAZAL", value: "06110" },
    { name: "OLIVAR", value: "06111" },
    { name: "PEUMO", value: "06112" },
    { name: "PICHIDEGUA", value: "06113" },
    { name: "QUINTA DE TILCOCO", value: "06114" },
    { name: "RENGO", value: "06115" },
    { name: "REQUÍNOA", value: "06116" },
    { name: "SAN VICENTE", value: "06117" },
    { name: "PICHILEMU", value: "06201" },
    { name: "LA ESTRELLA", value: "06202" },
    { name: "LITUECHE", value: "06203" },
    { name: "MARCHIHUE", value: "06204" },
    { name: "NAVIDAD", value: "06205" },
    { name: "PAREDONES", value: "06206" },
    { name: "SAN FERNANDO", value: "06301" },
    { name: "CHÉPICA", value: "06302" },
    { name: "CHIMBARONGO", value: "06303" },
    { name: "LOLOL", value: "06304" },
    { name: "NANCAGUA", value: "06305" },
    { name: "PALMILLA", value: "06306" },
    { name: "PERALILLO", value: "06307" },
    { name: "PLACILLA", value: "06308" },
    { name: "PUMANQUE", value: "06309" },
    { name: "SANTA CRUZ", value: "06310" },
    { name: "TALCA", value: "07101" },
    { name: "CONSTITUCIÓN", value: "07102" },
    { name: "CUREPTO", value: "07103" },
    { name: "EMPEDRADO", value: "07104" },
    { name: "MAULE", value: "07105" },
    { name: "PELARCO", value: "07106" },
    { name: "PENCAHUE", value: "07107" },
    { name: "RÍO CLARO", value: "07108" },
    { name: "SAN CLEMENTE", value: "07109" },
    { name: "SAN RAFAEL", value: "07110" },
    { name: "CAUQUENES", value: "07201" },
    { name: "CHANCO", value: "07202" },
    { name: "PELLUHUE", value: "07203" },
    { name: "CURICÓ", value: "07301" },
    { name: "HUALAÑÉ", value: "07302" },
    { name: "LICANTÉN", value: "07303" },
    { name: "MOLINA", value: "07304" },
    { name: "RAUCO", value: "07305" },
    { name: "ROMERAL", value: "07306" },
    { name: "SAGRADA FAMILIA", value: "07307" },
    { name: "TENO", value: "07308" },
    { name: "VICHUQUÉN", value: "07309" },
    { name: "LINARES", value: "07401" },
    { name: "COLBÚN", value: "07402" },
    { name: "LONGAVÍ", value: "07403" },
    { name: "PARRAL", value: "07404" },
    { name: "RETIRO", value: "07405" },
    { name: "SAN JAVIER", value: "07406" },
    { name: "VILLA ALEGRE", value: "07407" },
    { name: "YERBAS BUENAS", value: "07408" },
    { name: "CONCEPCIÓN", value: "08101" },
    { name: "CORONEL", value: "08102" },
    { name: "CHIGUAYANTE", value: "08103" },
    { name: "FLORIDA", value: "08104" },
    { name: "HUALQUI", value: "08105" },
    { name: "LOTA", value: "08106" },
    { name: "PENCO", value: "08107" },
    { name: "SAN PEDRO DE LA PAZ", value: "08108" },
    { name: "SANTA JUANA", value: "08109" },
    { name: "TALCAHUANO", value: "08110" },
    { name: "TOMÉ", value: "08111" },
    { name: "HUALPÉN", value: "08112" },
    { name: "LEBU", value: "08201" },
    { name: "ARAUCO", value: "08202" },
    { name: "CAÑETE", value: "08203" },
    { name: "CONTULMO", value: "08204" },
    { name: "CURANILAHUE", value: "08205" },
    { name: "LOS ÁLAMOS", value: "08206" },
    { name: "TIRÚA", value: "08207" },
    { name: "LOS ÁNGELES", value: "08301" },
    { name: "ANTUCO", value: "08302" },
    { name: "CABRERO", value: "08303" },
    { name: "LAJA", value: "08304" },
    { name: "MULCHÉN", value: "08305" },
    { name: "NACIMIENTO", value: "08306" },
    { name: "NEGRETE", value: "08307" },
    { name: "QUILACO", value: "08308" },
    { name: "QUILLECO", value: "08309" },
    { name: "SAN ROSENDO", value: "08310" },
    { name: "SANTA BÁRBARA", value: "08311" },
    { name: "TUCAPEL", value: "08312" },
    { name: "YUMBEL", value: "08313" },
    { name: "ALTO BIOBÍO", value: "08314" },
    { name: "TEMUCO", value: "09101" },
    { name: "CARAHUE", value: "09102" },
    { name: "CUNCO", value: "09103" },
    { name: "CURARREHUE", value: "09104" },
    { name: "FREIRE", value: "09105" },
    { name: "GALVARINO", value: "09106" },
    { name: "GORBEA", value: "09107" },
    { name: "LAUTARO", value: "09108" },
    { name: "LONCOCHE", value: "09109" },
    { name: "MELIPEUCO", value: "09110" },
    { name: "NUEVA IMPERIAL", value: "09111" },
    { name: "PADRE LAS CASAS", value: "09112" },
    { name: "PERQUENCO", value: "09113" },
    { name: "PITRUFQUÉN", value: "09114" },
    { name: "PUCÓN", value: "09115" },
    { name: "SAAVEDRA", value: "09116" },
    { name: "TEODORO SCHMIDT", value: "09117" },
    { name: "TOLTÉN", value: "09118" },
    { name: "VILCÚN", value: "09119" },
    { name: "VILLARRICA", value: "09120" },
    { name: "CHOLCHOL", value: "09121" },
    { name: "ANGOL", value: "09201" },
    { name: "COLLIPULLI", value: "09202" },
    { name: "CURACAUTÍN", value: "09203" },
    { name: "ERCILLA", value: "09204" },
    { name: "LONQUIMAY", value: "09205" },
    { name: "LOS SAUCES", value: "09206" },
    { name: "LUMACO", value: "09207" },
    { name: "PURÉN", value: "09208" },
    { name: "RENAICO", value: "09209" },
    { name: "TRAIGUÉN", value: "09210" },
    { name: "VICTORIA", value: "09211" },
    { name: "PUERTO MONTT", value: "10101" },
    { name: "CALBUCO", value: "10102" },
    { name: "COCHAMÓ", value: "10103" },
    { name: "FRESIA", value: "10104" },
    { name: "FRUTILLAR", value: "10105" },
    { name: "LOS MUERMOS", value: "10106" },
    { name: "LLANQUIHUE", value: "10107" },
    { name: "MAULLÍN", value: "10108" },
    { name: "PUERTO VARAS", value: "10109" },
    { name: "CASTRO", value: "10201" },
    { name: "ANCUD", value: "10202" },
    { name: "CHONCHI", value: "10203" },
    { name: "CURACO DE VÉLEZ", value: "10204" },
    { name: "DALCAHUE", value: "10205" },
    { name: "PUQUELDÓN", value: "10206" },
    { name: "QUEILÉN", value: "10207" },
    { name: "QUELLÓN", value: "10208" },
    { name: "QUEMCHI", value: "10209" },
    { name: "QUINCHAO", value: "10210" },
    { name: "OSORNO", value: "10301" },
    { name: "PUERTO OCTAY", value: "10302" },
    { name: "PURRANQUE", value: "10303" },
    { name: "PUYEHUE", value: "10304" },
    { name: "RÍO NEGRO", value: "10305" },
    { name: "SAN JUAN DE LA COSTA", value: "10306" },
    { name: "SAN PABLO", value: "10307" },
    { name: "CHAITÉN", value: "10401" },
    { name: "FUTALEUFÚ", value: "10402" },
    { name: "HUALAIHUÉ", value: "10403" },
    { name: "PALENA", value: "10404" },
    { name: "COYHAIQUE", value: "11101" },
    { name: "LAGO VERDE", value: "11102" },
    { name: "AYSÉN", value: "11201" },
    { name: "CISNES", value: "11202" },
    { name: "GUAITECAS", value: "11203" },
    { name: "COCHRANE", value: "11301" },
    { name: "O'HIGGINS", value: "11302" },
    { name: "TORTEL", value: "11303" },
    { name: "CHILE CHICO", value: "11401" },
    { name: "RÍO IBÁÑEZ", value: "11402" },
    { name: "PUNTA ARENAS", value: "12101" },
    { name: "LAGUNA BLANCA", value: "12102" },
    { name: "RÍO VERDE", value: "12103" },
    { name: "SAN GREGORIO", value: "12104" },
    { name: "CABO DE HORNOS", value: "12201" },
    { name: "ANTÁRTICA", value: "12202" },
    { name: "PORVENIR", value: "12301" },
    { name: "PRIMAVERA", value: "12302" },
    { name: "TIMAUKEL", value: "12303" },
    { name: "NATALES", value: "12401" },
    { name: "TORRES DEL PAINE", value: "12402" },
    { name: "SANTIAGO", value: "13101" },
    { name: "CERRILLOS", value: "13102" },
    { name: "CERRO NAVIA", value: "13103" },
    { name: "CONCHALÍ", value: "13104" },
    { name: "EL BOSQUE", value: "13105" },
    { name: "ESTACIÓN CENTRAL", value: "13106" },
    { name: "HUECHURABA", value: "13107" },
    { name: "INDEPENDENCIA", value: "13108" },
    { name: "LA CISTERNA", value: "13109" },
    { name: "LA FLORIDA", value: "13110" },
    { name: "LA GRANJA", value: "13111" },
    { name: "LA PINTANA", value: "13112" },
    { name: "LA REINA", value: "13113" },
    { name: "LAS CONDES", value: "13114" },
    { name: "LO BARNECHEA", value: "13115" },
    { name: "LO ESPEJO", value: "13116" },
    { name: "LO PRADO", value: "13117" },
    { name: "MACUL", value: "13118" },
    { name: "MAIPÚ", value: "13119" },
    { name: "ÑUÑOA", value: "13120" },
    { name: "PEDRO AGUIRRE CERDA", value: "13121" },
    { name: "PEÑALOLÉN", value: "13122" },
    { name: "PROVIDENCIA", value: "13123" },
    { name: "PUDAHUEL", value: "13124" },
    { name: "QUILICURA", value: "13125" },
    { name: "QUINTA NORMAL", value: "13126" },
    { name: "RECOLETA", value: "13127" },
    { name: "RENCA", value: "13128" },
    { name: "SAN JOAQUÍN", value: "13129" },
    { name: "SAN MIGUEL", value: "13130" },
    { name: "SAN RAMÓN", value: "13131" },
    { name: "VITACURA", value: "13132" },
    { name: "PUENTE ALTO", value: "13201" },
    { name: "PIRQUE", value: "13202" },
    { name: "SAN JOSÉ DE MAIPO", value: "13203" },
    { name: "COLINA", value: "13301" },
    { name: "LAMPA", value: "13302" },
    { name: "TIL TIL", value: "13303" },
    { name: "SAN BERNARDO", value: "13401" },
    { name: "BUIN", value: "13402" },
    { name: "CALERA DE TANGO", value: "13403" },
    { name: "PAINE", value: "13404" },
    { name: "MELIPILLA", value: "13501" },
    { name: "ALHUÉ", value: "13502" },
    { name: "CURACAVÍ", value: "13503" },
    { name: "MARÍA PINTO", value: "13504" },
    { name: "SAN PEDRO", value: "13505" },
    { name: "TALAGANTE", value: "13601" },
    { name: "EL MONTE", value: "13602" },
    { name: "ISLA DE MAIPO", value: "13603" },
    { name: "PADRE HURTADO", value: "13604" },
    { name: "PEÑAFLOR", value: "13605" },
    { name: "VALDIVIA", value: "14101" },
    { name: "CORRAL", value: "14102" },
    { name: "LANCO", value: "14103" },
    { name: "LOS LAGOS", value: "14104" },
    { name: "MÁFIL", value: "14105" },
    { name: "MARIQUINA", value: "14106" },
    { name: "PAILLACO", value: "14107" },
    { name: "PANGUIPULLI", value: "14108" },
    { name: "LA UNIÓN", value: "14201" },
    { name: "FUTRONO", value: "14202" },
    { name: "LAGO RANCO", value: "14203" },
    { name: "RÍO BUENO", value: "14204" },
    { name: "ARICA", value: "15101" },
    { name: "CAMARONES", value: "15102" },
    { name: "PUTRE", value: "15201" },
    { name: "GENERAL LAGOS", value: "15202" },
    { name: "CHILLÁN", value: "16101" },
    { name: "BULNES", value: "16102" },
    { name: "CHILLÁN VIEJO", value: "16103" },
    { name: "EL CARMEN", value: "16104" },
    { name: "PEMUCO", value: "16105" },
    { name: "PINTO", value: "16106" },
    { name: "QUILLÓN", value: "16107" },
    { name: "SAN IGNACIO", value: "16108" },
    { name: "YUNGAY", value: "16109" },
    { name: "QUIRIHUE", value: "16201" },
    { name: "COBQUECURA", value: "16202" },
    { name: "COELEMU", value: "16203" },
    { name: "NINHUE", value: "16204" },
    { name: "PORTEZUELO", value: "16205" },
    { name: "RÁNQUIL", value: "16206" },
    { name: "TREGUACO", value: "16207" },
    { name: "SAN CARLOS", value: "16301" },
    { name: "COIHUECO", value: "16302" },
    { name: "ÑIQUÉN", value: "16303" },
    { name: "SAN FABIÁN", value: "16304" },
    { name: "SAN NICOLÁS", value: "16305" },
  ],
  //bancos anadidos
  //borar lugo desde name: "BANCO DE CHILE", value: "001" hasta  name: "PREPAGO LOS HÉROES", value: "729"
  [CatalogsEnum.Banks]: [
    { name: "BANCO DE CHILE", value: "001" },
    { name: "BANCO INTERNACIONAL", value: "009" },
    { name: "BANCO DEL ESTADO DE CHILE", value: "012" },
    { name: "SCOTIABANK CHILE", value: "014" },
    { name: "BANCO DE CREDITO E INVERSIONES", value: "016" },
    { name: "BANCO BICE", value: "028" },
    { name: "HSBC BANK CHILE", value: "031" },
    { name: "BANCO SANTANDER-CHILE", value: "037" },
    { name: "ITAÚ CORPBANCA", value: "039" },
    { name: "BANCO SECURITY", value: "049" },
    { name: "BANCO FALABELLA", value: "051" },
    { name: "BANCO RIPLEY", value: "053" },
    { name: "BANCO CONSORCIO", value: "055" },
    { name: "SCOTIABANK AZUL (ex BBVA)", value: "504" },
    { name: "COOPEUCH", value: "672" },
    { name: "PREPAGO LOS HÉROES", value: "729" },
    { name: "Banco BBVA", value: "970320008" },
    { name: "Banco de Chile", value: "970040005" },
    { name: "Banco BCI", value: "970060006" },
    { name: "Banco Edwards", value: "970040005" },
    { name: "Banco Desarrollo", value: "970510001" },
    { name: "Banco Estado", value: "970300007" },
    { name: "Banco Falabella", value: "965096604" },
    { name: "Banco Itaú", value: "76645030K" },
    { name: "Banco Internacional", value: "970110003" },
    { name: "Banco Ripley", value: "979470002" },
    { name: "Banco Santander-Santiago", value: "97036000K" },
    { name: "Banco Security", value: "970530002" },
    { name: "Banco Bice", value: "97080000K" },
    { name: "Banco Consorcio", value: "995004100" },
    { name: "Banco Coopeuch", value: "828789007" },
    { name: "Corpbanca", value: "970230009" },
    { name: "Banco HSBC", value: "979510004" },
    { name: "Scotiabank", value: "970180001" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Vista", value: "1" },
  ],
};
