// istanbul ignore file

import { get, isEmpty } from "lodash";
import { TransactionsRequest } from "../../../types/transactions_request";
import {
  WebsocketActionsEnum,
  WebsocketStatusEnum,
} from "../enums/websocket-enum";
import { AppDispatch } from "../../store/interfaces/store.interfaces";
import { user_profile } from "../constants/PayoutsListConstants";
import { environment } from "../../environments/environment";
import { setIsLoadingDownload } from "../../store/actions/app.actions";

export const downloadTransactions = (
  dispatchApp: AppDispatch,
  filters: TransactionsRequest,
  isCustomer: boolean,
  downloadById: boolean,
  customerFilter?: {
    path: string;
    childrenIds: string;
  }
) => {
  let webSocket: WebSocket;

  try {
    const action = downloadById
      ? WebsocketActionsEnum.DOWNLOAD_TRANSACTIONS_BY_ID
      : WebsocketActionsEnum.DOWNLOAD_TRANSACTIONS_ALL;

    let data = { ...filters };

    if (isCustomer)
      data = {
        ...filters,
        branchesId: get(customerFilter, "childrenIds", ""),
        customerPath: get(customerFilter, "path", ""),
        publicMerchantId: get(user_profile, "prefferedUserName", ""),
      };

    webSocket = new WebSocket(environment.kushkiWsUrl);
    webSocket.onopen = () => {
      webSocket.send(
        JSON.stringify({
          action,
          data,
        })
      );
    };

    webSocket.onerror = () => dispatchApp(setIsLoadingDownload(false));

    webSocket.onmessage = (event: MessageEvent) => {
      const response = JSON.parse(event.data);

      switch (response.status) {
        case WebsocketStatusEnum.COMPLETED_SUCCESS:
          const fileUrl = get(response, "payload.url", "");

          if (!isEmpty(fileUrl)) {
            const downloadLink: HTMLAnchorElement = document.createElement("a");

            downloadLink.href = fileUrl;
            downloadLink.click();
            window.URL.revokeObjectURL(fileUrl);
          }
          dispatchApp(setIsLoadingDownload(false));
          webSocket?.close();
          break;
        case WebsocketStatusEnum.COMPLETED_EMPTY:
          webSocket?.close();
          dispatchApp(setIsLoadingDownload(false));
          break;
        case WebsocketStatusEnum.ERROR:
          webSocket?.close();
          dispatchApp(setIsLoadingDownload(false));
          break;
      }
    };
  } catch (error) {
    dispatchApp(setIsLoadingDownload(false));
  }
};
