import { useState } from "react";
import { isEmpty, isUndefined } from "lodash";
import { FilterDispersionRequest } from "../../../../types/filter_dispersion_request";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  getProcessFileDispersion,
  setFilters,
} from "../../../store/actionCreators";

export interface IUseSearchFieldState {
  searchText: string;
  handleChangeSearchText: (value: string) => void;
  handleEnterSearchText: (key: string) => void;
  handleClickSearchText: () => void;
}

export const useSearchFieldState = (): IUseSearchFieldState => {
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useDispatch();
  const filterDispersionRequestState: FilterDispersionRequest | undefined =
    useSelector((state: IAppState) => state.filterDispersionRequest);

  const handleChangeSearchText = (value: string): void => {
    setSearchText(value);
  };

  const handleEnterSearchText = (key: string): void => {
    if (key !== "Enter") return;

    if (!isUndefined(filterDispersionRequestState)) {
      if (isEmpty(searchText)) {
        cleanSearchText(filterDispersionRequestState);
        return;
      }
      addReferenceNumberFilter(filterDispersionRequestState);
    }
  };

  const handleClickSearchText = (): void => {
    if (!isUndefined(filterDispersionRequestState)) {
      if (isEmpty(searchText)) {
        cleanSearchText(filterDispersionRequestState);
        return;
      }
      addReferenceNumberFilter(filterDispersionRequestState);
    }
  };

  const cleanSearchText = (filters: FilterDispersionRequest): void => {
    dispatch(
      setFilters({
        ...filters,
        referenceNumber: undefined,
      })
    );
    dispatch(
      getProcessFileDispersion({
        ...filters,
        referenceNumber: undefined,
      })
    );
  };

  const addReferenceNumberFilter = (filters: FilterDispersionRequest): void => {
    dispatch(setFilters({ ...filters, referenceNumber: searchText }));
    dispatch(
      getProcessFileDispersion({ ...filters, referenceNumber: searchText })
    );
  };

  return {
    searchText,
    handleChangeSearchText,
    handleEnterSearchText,
    handleClickSearchText,
  };
};
