import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  getDeferredMerchant,
  setCanceledChangeStatus,
  updateDeferredStatusMerchant,
} from "../../../store/actionCreators";
import { DEFERRED_TYPE } from "../../../shared/infrastructure/constants/DeferredTypeEnum";
import {
  ACTIVE_TEXTS,
  StatusDialogTexts,
} from "../../../shared/infrastructure/constants/CreateDeferredConstants";
import { StatusEnum } from "../../../shared/infrastructure/constants/statusEnum";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { defaultTo, filter, find, get, isArray, map, uniq } from "lodash";
import { DeferredOption } from "../../../../types/deferred_options";
import { TableDeferredData } from "../Table/TableDeferred";
import { TDeferred } from "../../../shared/infrastructure/interfaces/TDeferred";
import { auth } from "../../../shared/auth";
import {
  DEFAULT_DEFERRED_TYPE,
  DEFERRED_TYPE_MEXICO,
} from "../../../shared/infrastructure/constants/DeferredTypeToMexico";
import { IDeferredType } from "../../../shared/infrastructure/interfaces/IDeferredType";
import { PROCESSOR_MAPPED } from "../../../shared/infrastructure/constants/ProcessorMapped";
import {
  DEFAULT_DEFERRED_TYPE_CENTRAL,
  DEFERRED_TYPE_CENTRAL_AMERICA,
} from "../../../shared/infrastructure/constants/DeferredTypeToCentralAmerica";

export interface IDeferredSection {
  country: string;
  dataHeadersTable: string[];
  bankModalIsOpen: boolean;
  updateStatusModalIsOpen: boolean;
  dialogTexts: StatusDialogTexts;
  banks: string[];
  handleBankModal: (banks: string[]) => void;
  tableMerchantDeferredData: TableDeferredData[];
  getCodeToTextDeferred: (deferred: TDeferred) => string;
  handleOpenUpdateStatusModal: (id: string, status: StatusEnum) => void;
  handleCloseStatusModal: () => void;
  updateDeferredMerchantState: () => void;
  changeDialogText: (text: StatusDialogTexts) => void;
}

export const useMerchantDeferredState = (
  merchantDeferredParam: DeferredOption[] | undefined,
  isDeferredLoadedParam: boolean
): IDeferredSection => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState<string>("");
  const [bankModalIsOpen, setBankModalIsOpen] = useState<boolean>(false);
  const [deferredId, setDeferredId] = useState<string>("");

  const [deferredStatus, setDeferredStatus] = useState<StatusEnum>(
    StatusEnum.DISABLED
  );
  const [dialogTexts, setDialogTexts] =
    useState<StatusDialogTexts>(ACTIVE_TEXTS);
  const [updateStatusModalIsOpen, setUpdateStatusModalIsOpen] =
    useState<boolean>(false);
  const [banks, setBanks] = useState<string[]>(["bank1", "bank2"]);
  const dataHeadersTable: string[] = [
    "Tipo de diferidos",
    "Número de cuotas (meses)",
    "Meses de gracia",
    "Red de bancos asociados",
    "Activar/Desactivar",
    "",
  ];
  const merchantId = useSelector(
    (state: IAppState) => state.merchant?.publicMerchantId!
  );
  const [tableMerchantDeferredData, setTableMerchantDeferredData] = useState<
    TableDeferredData[]
  >([]);

  const merchantBasicInformation = auth.getAuthMerchant();

  const getCodeToTextDeferredEcuador = (code: string) => {
    return DEFERRED_TYPE.filter((el) => el.code === code)[0].text;
  };

  const getCodeToTextDeferredMexico = (deferred: TDeferred) =>
    defaultTo(
      get(
        find(DEFERRED_TYPE_MEXICO, {
          code: get(deferred, "deferredType[0]"),
        }),
        "text"
      ),
      DEFAULT_DEFERRED_TYPE
    );

  const getCodeToTextDeferredCentralAmerica = (deferred: TDeferred) =>
    defaultTo(
      get(
        find(DEFERRED_TYPE_CENTRAL_AMERICA, {
          code: get(deferred, "deferredType[0]"),
        }),
        "text"
      ),
      DEFAULT_DEFERRED_TYPE_CENTRAL
    );

  const getCodeToTextDeferred = (deferred: TDeferred): string => {
    switch (merchantBasicInformation.country) {
      case CountriesEnum.ECUADOR:
        return getCodeToTextDeferredEcuador(get(deferred, "deferredType[0]"));
      case CountriesEnum.MEXICO:
        return getCodeToTextDeferredMexico(deferred);
      default:
        return getCodeToTextDeferredCentralAmerica(deferred);
    }
  };

  const getProcessor = (deferred: DeferredOption): string => {
    const entity = get(deferred, "entity");
    const processorName = get(deferred, "processorName");
    const processor = get(PROCESSOR_MAPPED, processorName, "Processor");

    return entity ?? processor;
  };

  const handleBankModal = (deferredBanks: string[]) => {
    setBanks(deferredBanks);
    bankModalIsOpen ? setBankModalIsOpen(false) : setBankModalIsOpen(true);
  };

  const handleOpenUpdateStatusModal = (id: string, status: StatusEnum) => {
    setUpdateStatusModalIsOpen(true);
    setDeferredId(id);
    setDeferredStatus(status);
  };

  const changeDialogText = (text: StatusDialogTexts) => {
    setDialogTexts(text);
  };

  const handleCloseStatusModal = () => {
    setUpdateStatusModalIsOpen(false);
    dispatch(setCanceledChangeStatus({ id: deferredId, status: true }));
  };

  const updateDeferredMerchantState = () => {
    setUpdateStatusModalIsOpen(false);
    dispatch(
      updateDeferredStatusMerchant(
        country as CountriesEnum,
        deferredId,
        deferredStatus,
        merchantId
      )
    );
  };

  const getHasMonthOfGrace = (
    deferredItem: TDeferred,
    deferredType: IDeferredType[]
  ): boolean =>
    deferredType.find(
      (deferredCountry) => deferredCountry.code === deferredItem.deferredType[0]
    )!.hasMonthsOfGrace;

  const getHideMonthOfGraceForCountry = (
    deferred: TDeferred[],
    deferredType: IDeferredType[]
  ): boolean =>
    !deferred.some((deferredItem) =>
      getHasMonthOfGrace(deferredItem, deferredType)
    );

  const getHasMonthOfGraceByDeferredItem = (deferred: TDeferred): boolean => {
    switch (merchantBasicInformation.country) {
      case CountriesEnum.ECUADOR:
        return getHasMonthOfGrace(deferred, DEFERRED_TYPE);
      case CountriesEnum.MEXICO:
        return getHasMonthOfGrace(deferred, DEFERRED_TYPE_MEXICO);
      default:
        return false;
    }
  };

  const getHideMonthOfGrace = (deferred: TDeferred[]): boolean => {
    switch (merchantBasicInformation.country) {
      case CountriesEnum.ECUADOR:
        return getHideMonthOfGraceForCountry(deferred, DEFERRED_TYPE);
      case CountriesEnum.MEXICO:
        return getHideMonthOfGraceForCountry(deferred, DEFERRED_TYPE_MEXICO);
      default:
        return false;
    }
  };

  useEffect(() => {
    if (isArray(merchantDeferredParam) && merchantDeferredParam.length > 0) {
      const entities: string[] = uniq(
        map(merchantDeferredParam, (deferred) => getProcessor(deferred))
      );

      const deferredByEntity = map(entities, (entity) => {
        const deferredFiltered = filter(
          merchantDeferredParam,
          (deferred) => getProcessor(deferred) === entity
        ) as TDeferred[];

        const deferredMapped = map(deferredFiltered, (deferred) => ({
          ...deferred,
          hideMonthOfGrace: !getHasMonthOfGraceByDeferredItem(deferred),
        }));

        return {
          deferred: deferredMapped,
          entity,
          hideMonthOfGrace: getHideMonthOfGrace(deferredMapped),
        };
      });

      setTableMerchantDeferredData(deferredByEntity);
    }
  }, [merchantDeferredParam]);

  useEffect(() => {
    dispatch(getDeferredMerchant());
    setCountry(merchantBasicInformation.country);
  }, [isDeferredLoadedParam]);

  return {
    bankModalIsOpen,
    banks,
    changeDialogText,
    country,
    dataHeadersTable,
    dialogTexts,
    getCodeToTextDeferred,
    handleBankModal,
    handleCloseStatusModal,
    handleOpenUpdateStatusModal,
    tableMerchantDeferredData,
    updateDeferredMerchantState,
    updateStatusModalIsOpen,
  };
};
