import { SyntheticEvent, useState } from "react";

export const useSelectedTransactionModalState = () => {
  const [actualTab, setActualTab] = useState(0);

  const handleChangeTab = (
    _event: SyntheticEvent | undefined,
    newValue: number
  ) => {
    setActualTab(newValue);
  };

  return {
    actualTab,
    handleChangeTab,
  };
};
