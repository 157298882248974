import { createSlice } from "@reduxjs/toolkit";
import { searchBranchMerchants } from "../../thunks/branch/searchMerchants.thunks";
import { IBranch } from "../../interfaces/BranchState.interfaces";
import { get } from "lodash";

export const initialState: IBranch = {
  branchList: {
    data: [],
    total: 0,
  },
  isLoadingMerchants: false,
  merchantByNodeId: {},
};

export const branchSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(searchBranchMerchants.pending, (state) => {
        state.isLoadingMerchants = true;
      })
      .addCase(searchBranchMerchants.fulfilled, (state, action) => {
        if (
          get(action, "meta.arg.strictSearch", true) &&
          action.payload!.total === 0
        ) {
          return;
        }

        state.branchList = action.payload!;
        state.isLoadingMerchants = false;
      })
      .addCase(searchBranchMerchants.rejected, (state) => {
        state.branchList = initialState.branchList;
        state.isLoadingMerchants = false;
      });
  },
  initialState,
  name: "Branch",
  reducers: {
    resetBranchList: (state) => {
      state.branchList = {
        data: [],
        total: 0,
      };
    },
  },
});

export default branchSlice.reducer;
