export enum Snackbar {
  REQUEST_ERROR = "Ha ocurrido un error. Inténtalo nuevamente",
  REQUEST_SUCCESS = "Se ha guardado la información con éxito.",
  REQUEST_DEBIT_SUCCESS = "Se realizó el débito con éxito..",
  REQUEST_CREDIT_SUCCESS = "Se realizó la recarga con éxito.",
  REQUEST_DEBIT_FAILED = "No se pudo realizar el débito. Inténtalo nuevamente.",
  REQUEST_CREDIT_FAILED = "No se pudo realizar la recarga. Inténtalo nuevamente.",
}

export enum NotificationTypeEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export enum ColorSnackBarEnum {
  INFO = "info",
  DANGER = "danger",
  SUCCESS = "success",
}
