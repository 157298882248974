import { createNamedStyles } from "../../../../shared/utils";

export const transactionsTableLimitSelectStyles = createNamedStyles({
  container: {
    alignItems: "center",
    display: "flex",
  },
  label: {
    color: "text.grey",
    fontSize: 14,
    fontWeight: 400,
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-select": {
      py: 0,
    },
    color: "text.grey",
    fontSize: 14,
    mr: 4,
    p: 0,
  },
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "neutral.white",
    },
  },
  menuItem: {
    fontSize: 14,
    justifyContent: "center",
  },
});
