export enum SocketEnum {
  COMPLETE = "complete",
  ERROR = "error",
}

export enum StatusSocketEnum {
  NONE = "NONE",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const NONE = "NONE";
