import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const headerAlarmsTableStyles = createNamedStyles({
  container: {
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "2rem 0px 1rem 0px",
    flexWrap: {
      xs: "wrap",
      sm: "nowrap",
    },
  },
  search: {
    width: {
      sm: "200px",
      md: "380px",
    },
  },
  rangeDatePicker: {
    marginRight: "15px",
    width: "260px",
    "&#rangeDateAlarm  button": {
      width: "100%",
    },
    "&#rangeDateAlarm  button h6": {
      color: "#78828c !important",
    },
  },
});
