import { ICellRow, ICellText } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  HeaderCellProps,
  ITableCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";

enum CELL_TYPES {
  TEXT = "text",
  TAG = "tag",
}

type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

enum tableProperties {
  NAME = "name",
  ID = "credentialId",
  STATUS = "status",
}

export const ALL_TABLE_COLUMNS: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.NAME,
    spacing: 0,
    text: "Nombre",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.ID,
    spacing: 0,
    text: "ID Credencial",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TAG,
    key: tableProperties.STATUS,
    spacing: 0,
    text: "Estado",
  },
];

export const statusSelector = (
  status: boolean
): { color: string; label: string } => {
  if (status) {
    return { color: "success", label: "ACTIVO" };
  } else return { color: "error", label: "INACTIVO" };
};

export const TEXT_CELL = (line1: string = "", line2?: string) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      line2,
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

export const TAG_CELL = (status: boolean) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      } as ICellRow,
      color: statusSelector(status).color,
      text: statusSelector(status).label,
    },
    type: "TAG" as TableBodyCellEnum,
  } as ITableCellProps);
