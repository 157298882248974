import { createStyles, makeStyles } from "@material-ui/core";
import { themeOptions } from "../../themeui";
import { get } from "lodash";
import { ChipStatusProps } from "./ChipStatus";

export const useStatusFinalStyles = makeStyles(() =>
  createStyles({
    cycleDate: {
      color: `${themeOptions.palette.text.secondary}`,
    },
    chipStyle: {
      backgroundColor: "#F7FAFC",
      borderRadius: "4px",
    },
    chipStateStyle: {
      borderRadius: "4px",
      minWidth: "74px",
      height: "21px",
      fontSize: "12px",
      backgroundColor: ({ statusInfo }: ChipStatusProps) => {
        return get(statusInfo, "backgroundColor", "inherit");
      },
      color: ({ statusInfo }: ChipStatusProps) => {
        return get(statusInfo, "color", "inherit");
      },
    },
  })
);
