import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../components/Filters/ItemCategory/interfaces";

export const applyClick = (
  list: ItemCategoryProps[],
  onClick: (selected: ItemCategoryProps[]) => void,
  toggleDrawer: (newOpen: boolean) => void,
  setList: Function,
  setFilterCount: Function
) => {
  let count: number = 0;

  const filterList: ItemCategoryProps[] = JSON.parse(JSON.stringify(list));

  filterList.map((item) => {
    item.items = item.items.filter((subItem) => subItem.selected);
  });
  onClick(filterList);
  toggleDrawer(false);
  list.map((item: ItemCategoryProps) => {
    item.items.map((subItem: ItemCategoryItem) => {
      subItem.selected && count++;
    });

    return item;
  });

  setList(list);
  setFilterCount(count);
};
