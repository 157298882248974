import { ErrorMessageContactDataMX } from "../enum/ContactDataStateMX/ErrorMessageContactDataMX";
import { FieldsEnum } from "../enums/FieldsEnum";
import { RegularExp } from "./form_labels_legal_details";

export const getEmailRules = () => ({
  pattern: {
    message: ErrorMessageContactDataMX.EMAIL_VALIDATION,
    value: new RegExp(RegularExp.EMAIL_NO_ACCENT),
  },
  required: ErrorMessageContactDataMX.EMAIL_REQUIRED,
});

export const getContactDataRules = () => ({
  [`${FieldsEnum.name}`]: {
    maxLength: {
      message: ErrorMessageContactDataMX.NAME_MESSAGE,
      value: 50,
    },
    minLength: {
      message: ErrorMessageContactDataMX.NAME_MESSAGE,
      value: 1,
    },
    pattern: {
      message: ErrorMessageContactDataMX.NAME_CORRECT,
      value: new RegExp(RegularExp.LETTERS_SPACES_ACCENTS),
    },
    required: ErrorMessageContactDataMX.NAME_REQUIRED,
  },
  [`${FieldsEnum.commercialExecutive}`]: {
    required: ErrorMessageContactDataMX.COMMERCIAL_EXECUTIVE,
  },
  [`${FieldsEnum.merchantSize}`]: {
    required: ErrorMessageContactDataMX.MERCHANT_SIZE,
  },
});
