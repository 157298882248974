import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";
import { validateDate } from "../../../containers/HistoryIndex/state/useHistoryIndexState";

export interface InformationSecondSectionProps {
  supportInfo: object | undefined;
  isAdmin: boolean;
  timeZoneRegion: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
  })
);

const InformationSecondSection: React.FC<InformationSecondSectionProps> = (
  props: InformationSecondSectionProps
) => {
  const classes = useStyles();
  const status: string = get(props.supportInfo, "_source.status", "EXPIRED");
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Inicio de sesión:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Duración de sesión:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Fin de sesión:
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant={"body2"} color={"textPrimary"}>
                  {
                    validateDate(
                      status === "COMPLETED"
                        ? get(props.supportInfo, "_source.connected_in")
                        : get(props.supportInfo, "_source.created"),
                      props.timeZoneRegion
                    ).split("T")[0]
                  }
                </Typography>
                <Typography variant="subtitle2" color={"textSecondary"}>
                  {
                    validateDate(
                      status === "COMPLETED"
                        ? get(props.supportInfo, "_source.connected_in")
                        : get(props.supportInfo, "_source.created"),
                      props.timeZoneRegion
                    ).split("T")[1]
                  }
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"body2"} color={"textPrimary"}>
                  {get(
                    props.supportInfo,
                    "_source.time_duration_connect",
                    "0:00:00"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"body2"} color={"textPrimary"}>
                  {
                    validateDate(
                      status === "COMPLETED"
                        ? get(props.supportInfo, "_source.connected_out")
                        : get(props.supportInfo, "_source.created"),
                      props.timeZoneRegion
                    ).split("T")[0]
                  }
                </Typography>
                <Typography variant="subtitle2" color={"textSecondary"}>
                  {
                    validateDate(
                      status === "COMPLETED"
                        ? get(props.supportInfo, "_source.connected_out")
                        : get(props.supportInfo, "_source.created"),
                      props.timeZoneRegion
                    ).split("T")[1]
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default InformationSecondSection;
