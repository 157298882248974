import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const radioGroupComponentStyles = createNamedStyles({
  radio: {
    color: "#293036",
    "&.Mui-checked": {
      color: "#023365",
    },
    marginLeft: "22px",
  },
  label: {
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#677784",
  },
});
