export interface IColumnsTable {
  id: string;
  label: string;
  view: boolean;
  filter: boolean;
  filterLabel?: string;
  checkboxHeader?: ICheckboxHeader;
  align?: string;
  orderBy?: boolean;
}

export interface ICheckboxHeader {
  checked: boolean;
  handleChange: (name: string, value: boolean) => void;
  name: string;
}

export enum ColumnsEnumEC {
  taxpayerTypeId = "taxpayerTypeId",
  activityId = "activityId",
  incomeSourceConceptId = "incomeSourceConceptId",
  retIva = "retIva",
  retFue = "retFue",
  options = "options",
}
export enum ColumnsEnumCO {
  cityId = "cityId",
  activityId = "activityId",
  model = "model",
  retIca = "retIca",
}

export const columnsRetentionIVATable: IColumnsTable[] = [
  {
    id: ColumnsEnumEC.taxpayerTypeId,
    label: "Tipo de contribuyente",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumEC.activityId,
    label: "Actividad",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumEC.retIva,
    label: "Retención IVA",
    view: true,
    filter: true,
  },

  {
    id: ColumnsEnumEC.options,
    label: "",
    view: true,
    filter: false,
  },
];
export const columnsRetentionRENTATable: IColumnsTable[] = [
  {
    id: ColumnsEnumEC.incomeSourceConceptId,
    label: "Concepto de retención en la renta",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumEC.retFue,
    label: "Retención Renta",
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnumEC.options,
    label: "",
    view: true,
    filter: false,
  },
];
export const columnsRetentionTableEC: IColumnsTable[] = [
  {
    id: ColumnsEnumEC.taxpayerTypeId,
    label: "Tipo de contribuyente",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumEC.activityId,
    label: "Actividad",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumEC.incomeSourceConceptId,
    label: "Concepto de retención en la renta",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumEC.retIva,
    label: "Retención IVA",
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnumEC.retFue,
    label: "Retención Renta",
    view: true,
    filter: true,
  },
  {
    id: ColumnsEnumEC.options,
    label: "",
    view: true,
    filter: false,
  },
];

export const columnsRetentionTableCO: IColumnsTable[] = [
  {
    id: ColumnsEnumCO.cityId,
    label: "Municipio",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumCO.activityId,
    label: "Actividad",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumCO.model,
    label: "Modelo",
    view: true,
    filter: false,
  },
  {
    id: ColumnsEnumCO.retIca,
    label: "ICA",
    view: true,
    filter: true,
  },
];
