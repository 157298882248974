import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedTimezoneOffset } from "../../../shared/utils/timezone";
import {
  setDateTimeFilter,
  setTransactionReferenceFilter,
} from "../../../store/reducers/refunds/refunds";
import { RootState } from "../../../store/store";
import { IDateTime } from "../../common/DateTimeRangePicker/DateTimeRangePicker.interfaces";
import { UseRefundsActionBarState } from "./useRefundsActionBarState.interfaces";

const useRefundsActionBarState: UseRefundsActionBarState = () => {
  const filters = useSelector((state: RootState) => state.refunds.filters);
  const dispatch = useDispatch();

  const setFromTo = ({ from, to }: IDateTime) => {
    const timezone = getFormattedTimezoneOffset(new Date().getTimezoneOffset());

    dispatch(setDateTimeFilter({ from, timezone, to }));
  };

  const handleSetSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setTransactionReferenceFilter(event.target.value));
  };
  const setDebounceSearchFilter = debounce(handleSetSearchFilter, 1000);

  return {
    dateTimeFrom: filters.dateTime.from,
    dateTimeTo: filters.dateTime.to,
    setDebounceSearchFilter,
    setFromTo,
    transactionReference: filters.transactionReference,
  };
};

export default useRefundsActionBarState;
