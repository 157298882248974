import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#F7FAFC",
  },
});

interface ISkeleton {
  columns: number;
  rows: number;
}

export const SkeletonTable: React.FC<ISkeleton> = (props: ISkeleton) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={0}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {[...Array(props.columns)].map((_: string, index: number) => (
                  <TableCell key={index}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(props.rows)].map((_: string, index: number) => (
                <TableRow key={index}>
                  {[...Array(props.columns + 1)].map(
                    (_: string, innerIndex: number) => (
                      <TableCell key={innerIndex}>
                        <Skeleton variant="text" />
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
};
