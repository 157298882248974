import { CatalogCo } from "../services/catalog-co";
import { CatalogCl } from "../services/catalog-cl";
import { CatalogPe } from "../services/catalog-pe";
import { CountryEnum } from "../countryEnum";
import { CatalogEc } from "../services/catalog-ec";
import { Category } from "../constants/information_constants";
import { defaultTo, isNil } from "lodash";
import { CatalogEeuu } from "../services/catalog-eeuu";
import { CatalogCr } from "../services/catalog-cr";
import { CatalogPan } from "../services/catalog-pan";
import { CatalogEl } from "../services/catalog-el";
import { CatalogGt } from "../services/catalog-gt";
import { CatalogHn } from "../services/catalog-hn";
import { CatalogNi } from "../services/catalog-ni";
import { CatalogBr } from "../services/catalog-br";

const filterCatalogByParent = (catalog: Category[], parent: string) =>
  defaultTo(catalog, []).filter((el) => el.parent! === parent);

export const CatalogByCountry: Record<
  string,
  (catalog: string, parent?: string) => Category[]
> = {
  [CountryEnum.generic]: () => [],
  [CountryEnum.mexico]: () => [],
  [CountryEnum.ecuador]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogEc[catalog], parent)
      : CatalogEc[catalog],
  [CountryEnum.brasil]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogBr[catalog], parent)
      : CatalogBr[catalog],
  [CountryEnum.usa]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogEeuu[catalog], parent)
      : CatalogEeuu[catalog],
  [CountryEnum.chile]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogCl[catalog], parent)
      : CatalogCl[catalog],
  [CountryEnum.panama]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogPan[catalog], parent)
      : CatalogPan[catalog],
  [CountryEnum.peru]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogPe[catalog], parent)
      : CatalogPe[catalog],
  [CountryEnum.el_salvador]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogEl[catalog], parent)
      : CatalogEl[catalog],
  [CountryEnum.honduras]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogHn[catalog], parent)
      : CatalogHn[catalog],
  [CountryEnum.guatemala]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogGt[catalog], parent)
      : CatalogGt[catalog],
  [CountryEnum.nicaragua]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogNi[catalog], parent)
      : CatalogNi[catalog],
  [CountryEnum.costa_rica]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogCr[catalog], parent)
      : CatalogCr[catalog],
  [CountryEnum.colombia]: (catalog: string, parent?: string) =>
    !isNil(parent)
      ? filterCatalogByParent(CatalogCo[catalog], parent)
      : CatalogCo[catalog],
};
