export enum DEFERRED_LABELS {
  SEE_MORE = "Ver todos",
  SEE_LESS = "Ver menos",

  EC_INFO = "En esta sección puedes ver los diferidos por marca de tarjeta que\n" +
    "            están habilitados para tu comercio.",
  MEX_INFO = "En esta sección puedes ver las marcas de tarjeta de crédito y meses\n" +
    "          sin intereses que tendrán disponibles tus clientes para realizar pagos\n" +
    "          en cuotas",
  PERU_INFO = "En esta sección puedes ver las marcas de tarjeta de crédito que\n" +
    "            tendrán disponibles tus clientes para realizar pagos en cuotas.",
  BRAZIL_INFO = "En esta sección puedes ver las cuotas que están habilitadas para tu comercio. Para más detalle consulta la configuración de diferidos.",
  BRAZIL_CARDS = "Tarjetas activas del diferido",
  BRAZIL_ACTIVE_MONTHS = "Cuotas activas",
  MONTHS_OF_GRACE = "Meses de gracia:",
  ISSUING_BANKS = "Bancos Emisores:",
  NO_MONTHS_OF_GRACE = "No tiene meses de gracia",
  MONTHS = "Meses - ",
  CONSORTIUM = "Consorcio -",
  MONTHLY_FEES_NUMBER = "Números de cuota (meses):",
  MAX_FEE_INFO = "* El máximo de cuotas dependerá del banco\n" +
    "                                emisor.",
  DEFERRED_FEES_INFO = "Las cuotas diferidas dependerá del banco emisor de la tarjeta.\n",
}
