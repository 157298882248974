import { LabelEnum } from "./LabelEnum";
import { ErrorsEnum } from "./ErrorEnum";
import { Labels } from "../constants/RuleRequestManagerConstants";
import {
  CardTypeValueEnum,
  CardTypeValueRequestEnum,
  IssuerCardLabelEnum,
} from "./CreateRuleConstans";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export interface IUseLabelConditionSectionState {
  labelsItem: ILabelItemProps[];
  inputValue: string;
  isError: boolean;
  addNewItem: () => void;
  editItem: (index: number) => void;
  deleteItem: (index: number) => void;
  checkValue: (value: string, index: number) => void;
  setIsError: (value: boolean) => void;
}
export interface ILabelItemProps {
  name: string;
  errorMessage: string;
  label: string;
  tag: string;
  disable: boolean;
  pattern: RegExp;
  placeholder: string;
  isError: boolean;
  value?: string;
  isSaved: boolean;
  isRequiredEdit: boolean;
  duplicatedMessage?: string;
}

export interface ILabelConditionItem {
  item: ILabelItemProps;
  inputValue: string;
  index: number;
  editItem: (index: number) => void;
  deleteItem: (index: number) => void;
  checkValue: (value: string, index: number) => void;
  setIsError: (value: boolean) => void;
}

export interface IUseLabelConditionSectionStateProps {
  templateLabelItem: ILabelItemProps;
  setSelectedItems: (value: ICategory[]) => void;
}

export interface ValuesConditionsProps {
  tag: string;
  setSelectedItems: (value: ICategory[]) => void;
  defaultValueList: string[];
  isDisable: boolean;
}

export const DEFAULT_ITEM_BY_TAG: Record<string, ILabelItemProps> = {
  [LabelEnum.IP]: {
    name: LabelEnum.IP,
    errorMessage: ErrorsEnum.ONLY_VALID_IPS,
    duplicatedMessage: "IP duplicada",
    placeholder: Labels.IP,
    label: "Ingresa una IP:",
    tag: LabelEnum.IP,
    disable: false,
    pattern:
      /^((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))$/,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.SHIPPING_ADDRESS]: {
    name: LabelEnum.SHIPPING_ADDRESS,
    duplicatedMessage: "Dirección duplicada",
    errorMessage: ErrorsEnum.NUMBERS_AND_LETTERS,
    placeholder: Labels.ADDRESS,
    label: "Ingresa una dirección:",
    tag: LabelEnum.SHIPPING_ADDRESS,
    disable: false,
    pattern: /^[a-z\d\-_]+([\s]?[a-z\d\-_]+)+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.BILLING_ADDRESS]: {
    name: LabelEnum.BILLING_ADDRESS,
    duplicatedMessage: "Dirección de facturación duplicada",
    errorMessage: ErrorsEnum.NUMBERS_AND_LETTERS,
    placeholder: Labels.INPUT_ADDRESS,
    label: "Ingresa un valor de dirección de facturación:",
    tag: LabelEnum.BILLING_ADDRESS,
    disable: false,
    pattern: /^[a-z\d\-_]+([\s]?[a-z\d\-_]+)+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.DOCUMENT_NUMBER]: {
    name: LabelEnum.DOCUMENT_NUMBER,
    duplicatedMessage: "Número de documento duplicado",
    errorMessage: ErrorsEnum.NUMBERS_AND_LETTERS,
    placeholder: Labels.DOCUMENT_NUMBER,
    label: "Ingresa un número de documento:",
    tag: LabelEnum.DOCUMENT_NUMBER,
    disable: false,
    pattern: /^[a-z\d\-_\s]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.CARD_HOLDER]: {
    name: LabelEnum.CARD_HOLDER,
    duplicatedMessage: "Nombre del propietario de la tarjeta duplicado",
    errorMessage: ErrorsEnum.NOT_NUMBERS,
    placeholder: Labels.INPUT_NAME,
    label: "Ingrese el nombre del propietario de la tarjeta:",
    tag: LabelEnum.CARD_HOLDER,
    disable: false,
    pattern:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.METADATA]: {
    name: LabelEnum.METADATA,
    duplicatedMessage: "Metadata duplicada",
    errorMessage: "",
    placeholder: Labels.METADATA,
    label: "Ingresa una metadata:",
    tag: LabelEnum.METADATA,
    disable: false,
    pattern: /^[a-z\d\-_\s]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.CARD_ID]: {
    name: LabelEnum.CARD_ID,
    errorMessage: "El formato ingresado es incorrecto",
    placeholder: Labels.CARD_ID,
    label: "Ingresa el Kushki ID:",
    tag: LabelEnum.CARD_ID,
    disable: false,
    pattern:
      /^(?:[a-zA-Z\d]{8})(?:-[a-zA-Z\d]{4}){3}(?:-[a-zA-Z\d]{12})(?:,[ ]*(?:[a-zA-Z\d]{8})(?:-[a-zA-Z\d]{4}){3}(?:-[a-zA-Z\d]{12}))*$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.BANK]: {
    name: LabelEnum.BANK,
    duplicatedMessage: "Banco duplicado",
    errorMessage: "",
    placeholder: Labels.INPUT_BANK,
    label: "Ingrese nombre del banco:",
    tag: LabelEnum.BANK,
    disable: false,
    pattern:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\d& -]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\d&-]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\d& .()'"/\\-]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.PROCESSOR_CODE]: {
    name: LabelEnum.PROCESSOR_CODE,
    errorMessage: "",
    duplicatedMessage: "Código duplicado",
    placeholder: Labels.INPUT_PROCESSOR_CODE,
    label: "Ingresa un código:",
    tag: LabelEnum.PROCESSOR_CODE,
    disable: false,
    pattern:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\d& -]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\d&-]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\d& .()'"/\\-]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.BRAND]: {
    name: LabelEnum.BRAND,
    duplicatedMessage: "Marca duplicada",
    errorMessage: "",
    placeholder: Labels.INPUT_BRAND,
    label: "Ingrese nombre de la marca:",
    tag: LabelEnum.BRAND,
    disable: false,
    pattern:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\d& -]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\d&-]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\d& .()'"/\-]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
  [LabelEnum.COUNTRY]: {
    name: LabelEnum.COUNTRY,
    duplicatedMessage: "País duplicado",
    errorMessage: "",
    placeholder: Labels.INPUT_COUNTRY,
    label: "Ingrese el país:",
    tag: LabelEnum.COUNTRY,
    disable: false,
    pattern:
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\d& -]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\d&-]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\d& .()'"/\-]+$/i,
    isError: true,
    isSaved: false,
    isRequiredEdit: true,
  },
};

export const ITEM_SIMPLE_CONDITION: Record<string, object> = {
  [LabelEnum.CARD_ISSUER]: {
    name: LabelEnum.CARD_ISSUER,
    label: "Selecciona el tipo de emisión:",
    firstValue: "national",
    secondValue: "international",
    firstValueLabel: IssuerCardLabelEnum.NATIONAL,
    secondValueLabel: IssuerCardLabelEnum.INTERNATIONAL,
  },
  [LabelEnum.CARD_TYPE]: {
    name: LabelEnum.CARD_TYPE,
    label: "Selecciona el tipo de tarjeta:",
    firstValue: CardTypeValueRequestEnum.DEBIT,
    secondValue: CardTypeValueRequestEnum.CREDIT,
    firstValueLabel: CardTypeValueEnum.DEBIT,
    secondValueLabel: CardTypeValueEnum.CREDIT,
  },
  [LabelEnum.DEFERRED]: {
    name: LabelEnum.DEFERRED,
    label: "Aplica diferido",
    firstValue: "true",
    secondValue: "false",
    firstValueLabel: "Sí",
    secondValueLabel: "No",
  },
};
