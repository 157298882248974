import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import { SkipTransactionsStyles as styles } from "./SkipTransactions.styles";
import { useSkipTransactions } from "./state/useSkipTransactions";

interface ISkipTransactionsProps {
  counterTransactions: number;
  open: boolean;
  reason: string;
  handleClose: () => void;
  handleChangeReason: (reason: string) => void;
  handleConfirm: () => void;
}

const SkipTransactions: FC<ISkipTransactionsProps> = ({
  counterTransactions,
  open,
  reason,
  handleClose,
  handleChangeReason,
  handleConfirm,
}: ISkipTransactionsProps) => {
  const { isDisabledSkipButton } = useSkipTransactions(reason);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: styles.paperProps }}
    >
      <DialogTitle sx={styles.dialogTitle}>
        <Typography sx={styles.title}>
          Omitir transacciones
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Typography sx={styles.subtitle} variant="h6">
          <b>Se omitirán un total de {counterTransactions} transacciones.</b>
        </Typography>
        <Typography sx={styles.label}>
          Escribe el motivo del por qué se omite la transacción:
        </Typography>
        <TextField
          placeholder="Motivo..."
          multiline
          rows={3}
          fullWidth
          sx={styles.reasonContainer}
          inputProps={{ "data-testid": "reason-input", maxLength: 200 }}
          InputProps={{ sx: styles.reasonInput }}
          value={reason}
          onChange={(e) => handleChangeReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button sx={styles.cancelButton} variant="text" onClick={handleClose}>
          <Typography variant="caption">Cancelar</Typography>
        </Button>
        <Button
          data-testid="skip-button"
          sx={styles.skipButton}
          variant="contained"
          disabled={isDisabledSkipButton}
          onClick={handleConfirm}
        >
          <Typography variant="caption">Omitir</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkipTransactions;
