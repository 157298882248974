import React, { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { IRenderOptionsProps } from "../../SelectNewItem.interfaces";
import { renderOption, selectNewItemStyles } from "../../SelectNewItem.styles";
import IconPlus from "../../../../assets/icons/IconPlus";
import { map } from "lodash";

const RenderOption: FC<IRenderOptionsProps> = ({
  option,
  addButtonText,
  handleClick,
  listOptions,
  inputValueText,
  props,
  parts,
}: IRenderOptionsProps) => {
  return (
    <>
      {option.label === "DefaultOption" ? (
        addButtonText && (
          <Box sx={selectNewItemStyles.addButtonTextBox}>
            <Button
              sx={selectNewItemStyles.addButtonTextButton}
              onClick={handleClick}
              color={"secondary"}
              variant={"outlined"}
              size={"small"}
              disabled={
                listOptions.filter((item) =>
                  item.label
                    .toLowerCase()
                    .includes(inputValueText.toLowerCase())
                ).length > 0
              }
              endIcon={<IconPlus />}
            >
              {addButtonText}
            </Button>
          </Box>
        )
      ) : (
        <Box component="li" {...props} sx={selectNewItemStyles.boxOptionList}>
          <li key={option.index}>
            <div>
              {map(parts, (part: any, index: number) => (
                <Typography
                  key={index}
                  component={"span"}
                  color="text.dark"
                  variant="body2"
                  sx={renderOption(part.highlight)}
                >
                  {part.text}
                </Typography>
              ))}
            </div>
          </li>
        </Box>
      )}
    </>
  );
};

export default RenderOption;
