import { useDispatch, useSelector } from "react-redux";
import { IUseDownloadHistoricRefunds } from "./useDownloadHistoricRefunds.interfaces";
import { downloadHistoricRefunds } from "../../store/thunks/downloadHistoricRefunds/download_historic_refunds";
import {
  AdminSearchDownloadRequest,
  FormatDownloadRequest,
} from "../../store/thunks/downloadHistoricRefunds/download_historic_refunds.interfaces";
import { mapDateTimeToRequest } from "../../components/RefundsTable/utils/buildRequest";
import { RootState } from "../../store/store";
import { RefundStatusEnum } from "../../shared/enums/RefundStatusEnum";

const useDownloadHistoricRefunds = (): IUseDownloadHistoricRefunds => {
  const dispatch = useDispatch();
  const refunds = useSelector((state: RootState) => state.refunds);

  const handleDownloadTransactions = (downloadType: string) => {
    const request: AdminSearchDownloadRequest = {
      filter: {
        status:
          refunds.historicalFilters.generalFilter.status.length === 0
            ? [
                RefundStatusEnum.APPROVED,
                RefundStatusEnum.EXPIRED,
                RefundStatusEnum.MANUAL,
                RefundStatusEnum.INITIALIZED,
              ].join(",")
            : refunds.historicalFilters.generalFilter.status.join(","),
      },
      format: downloadType.toLowerCase() as FormatDownloadRequest,
      from: mapDateTimeToRequest(refunds.historicalFilters.dateTime.from),
      limit: refunds.pagination.rowsPerPage,
      offset: refunds.pagination.rowsPerPage * (refunds.pagination.page - 1),
      to: mapDateTimeToRequest(refunds.historicalFilters.dateTime.to),
    };

    dispatch(downloadHistoricRefunds({ data: request }));
  };

  return {
    actions: {
      handleDownloadHistoricRefunds: handleDownloadTransactions,
    },
  };
};

export default useDownloadHistoricRefunds;
