import { IUseFilterSideBarState } from "./useFilterSideBarState.interfaces";
import React, { useEffect } from "react";
import { FilterSideBarProps } from "../FilterSideBar.interfaces";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../ItemCategory/interfaces";
import { defaultTo, set } from "lodash";
import { applyClick } from "../../../../shared/utils/filterSideBarUtils";

const useFilterSideBarState = ({
  categoryItems,
  filtersCount,
  isMinimized,
  isOpen,
  onClick,
  setRangeAmount,
  setIsApplyFilter,
}: FilterSideBarProps): IUseFilterSideBarState => {
  const removeDuplicatesArrayObjects = (
    array: any[],
    uniqueKey: string
  ): any[] => {
    return array.filter(
      (value, index, self) =>
        index === self.findIndex((item) => item[uniqueKey] === value[uniqueKey])
    );
  };

  const [open, setOpen] = React.useState(isOpen);
  const [isResetFilter, setIsResetFilter] = React.useState<boolean>(false);

  const [minimizeAll, setMinimizeAll] = React.useState(isMinimized);

  const [list, setList] = React.useState<ItemCategoryProps[]>(categoryItems);

  const [reservedList, setReservedList] = React.useState<ItemCategoryProps[]>(
    []
  );

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [filterCount, setFilterCount] = React.useState<number>(filtersCount);

  const handleOnOpenSideBar = () => {
    setReservedList(JSON.parse(JSON.stringify(list)));
    toggleDrawer(true);
  };

  const handleOpenSideBar = () => {
    handleOnOpenSideBar();
  };
  const loopAndSet = () => {
    for (let i = 0; i < categoryItems.length; i++) {
      for (let k = 0; k < categoryItems[i].items.length; k++) {
        if (reservedList.length > 0) {
          categoryItems[i].items[k].selected = defaultTo(
            reservedList[i].items[k].selected,
            false
          );
        }
      }
    }
    setList(categoryItems);
  };
  const handleOnCloseSideBar = () => {
    toggleDrawer(false);
    loopAndSet();
  };

  const handleApplyClick = () => {
    applyClick(list, onClick, toggleDrawer, setList, setFilterCount);
    setIsApplyFilter(true);
    setIsResetFilter(false);
  };

  const handleDeleteFilters = () => {
    const listCopy: ItemCategoryProps[] = JSON.parse(
      JSON.stringify(categoryItems)
    );

    listCopy.map((item: ItemCategoryProps) => {
      item.items.map((subItem: ItemCategoryItem) => {
        set(subItem, "selected", false);

        return subItem;
      });

      return item;
    });
    setList(listCopy);
    setFilterCount(0);
    setRangeAmount({});
    setIsApplyFilter(false);
    setIsResetFilter(true);
  };

  const handleMinimizeEverything = () => {
    setMinimizeAll(!minimizeAll);
    setList(
      list.map((item) => {
        item.isMinimize = minimizeAll;

        return item;
      })
    );
  };

  useEffect(() => {
    categoryItems = categoryItems.map((data: ItemCategoryProps) => {
      data.items = removeDuplicatesArrayObjects(data.items, "value");

      return data;
    });
  }, [categoryItems]);

  useEffect(() => {
    let count: number = 0;

    toggleDrawer(false);
    list.map((item: ItemCategoryProps) => {
      item.items.map((subItem: ItemCategoryItem) => {
        if (subItem.selected) {
          count++;
        }
      });

      return item;
    });
    setFilterCount(count);
  }, []);

  useEffect(() => {
    if (open) {
      loopAndSet();
    }
  }, [open]);

  return {
    filterCount,
    handleApplyClick,
    handleDeleteFilters,
    handleMinimizeEverything,
    handleOnCloseSideBar,
    handleOnOpenSideBar,
    handleOpenSideBar,
    isResetFilter,
    list,
    minimizeAll,
    open,
    toggleDrawer,
  };
};

export { useFilterSideBarState };
