import React, { FC, PropsWithChildren } from "react";
import { Box, Button, Grid } from "@mui/material";
import { FilterDetailBar, IconDownload } from "@kushki/connect-ui";
import { headerFiltersStyles as styles } from "./HeaderFilters.styles";
import { FilterSideBar } from "../Filters/FilterSideBar/FilterSideBar";
import { HeaderFiltersProps } from "./HeaderFilters.interfaces";
import { useHeaderFiltersState } from "./state/useHeaderFiltersState";
import SearchText from "../SearchText/SearchText";
import { BranchEnum } from "../../shared/enums/BranchEnum";
import Lottie from "lottie-react";
import animationData from "../../assets/animation/loader.json";

export const HeaderFilters: FC<HeaderFiltersProps> = (
  props: PropsWithChildren<HeaderFiltersProps>
) => {
  const {
    filterSideBarProps,
    itemsDetailBar,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
    onEnterName,
    onDateSelected,
    valueSearch,
    onChange,
    showRangePicker,
  } = useHeaderFiltersState(props);

  return (
    <Grid container>
      <Grid item container xs={12}>
        <SearchText
          onEnter={onEnterName}
          onDateSelected={onDateSelected}
          searchMessage={BranchEnum.SEARCH_MESSAGE}
          value={valueSearch}
          onChange={onChange}
          entityName={props.entityNameType}
          showRangePicker={showRangePicker}
        />
        <Grid item xs={2} sx={styles.gridFilter}>
          <Box mt={2} mb={2}>
            <FilterSideBar
              data-testid={"filterBar"}
              {...filterSideBarProps}
              categoryItems={filterSideBarProps.categoryItems}
              onClick={onClickFilterSideBar}
              resetFilters={props.resetFilters}
            />
          </Box>
        </Grid>
        <Grid item xs={2} sx={styles.button}>
          {!props.isLoadingDownloadFile ? (
            <Button
              color="secondary"
              variant="outlined"
              sx={styles.buttonItem}
              onClick={props.handleDownload}
              startIcon={<IconDownload />}
            >
              {BranchEnum.BUTTON_DOWNLOAD_TITLE}
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="outlined"
              sx={styles.buttonItem}
              startIcon={
                <Lottie
                  animationData={animationData}
                  autoPlay={true}
                  loop={true}
                  style={{
                    height: "25px",
                    margin: "0",
                    padding: "0",
                    width: "25px",
                  }}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                  }}
                />
              }
            >
              {BranchEnum.BUTTON_DOWNLOAD_TITLE}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={styles.gridFilterCard}>
        <FilterDetailBar
          filters={itemsDetailBar}
          onChangeFilters={onChangeFiltersDetailBar}
        />
      </Grid>
    </Grid>
  );
};
