import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const formWrapperStyles = createNamedStyles({
  divider: {
    borderStyle: "solid",
    borderWidth: "0px 0px thin",
    mb: 4,
    mt: 2,
  },
  formWrapper: {
    backgroundColor: "neutral.white",
    borderRadius: 4,
    marginRight: "150px",
    mb: 15,
    minHeight: 349,
    px: 8,
    py: 6,
  },
});
