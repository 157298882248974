import { BoxFilterProps } from "./BoxFilter.interfaces";
import { emptyFunction } from "../../shared/utils/buildFiltersObject";
import { Filter } from "@kushki/frontend-molecules/filter";
import { get } from "lodash";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import React from "react";
import { useStyles } from "./BoxFilter.styles";

export const BoxFilter = (props: BoxFilterProps): JSX.Element => {
  const classes = useStyles();
  const {
    idPopover,
    openPopover,
    anchorEl,
    handleClosePopover,
    rangeAmount,
    handleAction,
  } = props;

  return (
    <Filter
      className={classes.filterPopover}
      idPopover={idPopover}
      openPopover={openPopover}
      isMobile={false}
      openModal={false}
      anchorPopover={anchorEl}
      filters={{
        listFilters: {
          filters: props.filters,
          multiple: false,
        },
        rangeFilter: {
          maxAmount: 100000000,
          rangeAmount: {
            eq: `${get(rangeAmount, "eq", "")}`,
            max: `${get(rangeAmount, "max", "")}`,
            min: `${get(rangeAmount, "min", "")}`,
            type: get(rangeAmount, "type", "min"),
            value: `${get(rangeAmount, "value", "")}`,
          },
        },
      }}
      styles={{
        applyButtonStyle: "outlined",
        removeButtonText: "Restablecer",
      }}
      handleClearFilters={props.handleClearFilters}
      handleClose={handleClosePopover}
      handleApplyFilters={(filterResponse: IFilterResponse) => {
        const actionResponse = filterResponse.listFilter[3].options.filter(
          (item) => item.selected
        );

        if (actionResponse.length > 0) {
          handleAction(actionResponse[0].key, actionResponse[0].label);
        } else {
          handleAction("", "");
        }
        props.populateOptionChips(
          filterResponse.listFilter,
          emptyFunction,
          filterResponse.rangeFilter?.filter
        );
      }}
    />
  );
};
