export const LANGUAGE_CATALOG: Record<string, any> = {
  frequency: {
    none: "Ninguno",
    daily: "Diaria",
    weekly: "Semanal",
    twoWeekly: "Mar-Vie",
    biWeekly: "Quincenal",
    threeWeekly: "Lun-Mie-Vie",
    monthly: "Mensual",
  },
};
