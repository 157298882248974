import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const skeletonFormStyles: IStyles = {
  boxRight: {
    width: 1,
    bgcolor: "white",
  },
  boxLeft: {
    display: "flex",
    gridRowGap: (theme) => theme.spacing(1),
    flexDirection: "column",
    minWidth: 275,
  },
  boxColums: {
    display: "flex",
    width: 1,
  },
  boxItem: {
    width: 0.5,
  },
  boxContainer: { display: "flex", columnGap: 7.5 },
};
