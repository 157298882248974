import { FLEX_JUSTIFY_BY_ALIGN } from "../../Table/TableBodySimple/constants";
import { ICellFlag } from "./CellFlag.interfaces";
import { Box, Skeleton, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { FLAG_ICON, TEXT_FLAG } from "./CellFlag.constants";
import { cellFlagStyles } from "./CellFlag.styles";
import { useMobileState } from "../../../shared/state/useMobileState";
import { useTableContext } from "../../Table/TableContext";
import { pushOrReplaceObjectInValueCells } from "../../Table/TableContext/tableUtils";

export const CellFlagItem: FC<ICellFlag> = ({
  id,
  rowIndex,
  type,
  country,
  cellProps,
}: ICellFlag) => {
  const {
    state: { isLoading, valueCells },
    handler: { handleSetCellValues },
  } = useTableContext();

  const twoLines: boolean = type === "twolines";
  const IconFlag: FC = FLAG_ICON[country];
  const { isMobile } = useMobileState();

  useEffect(() => {
    handleSetCellValues(
      pushOrReplaceObjectInValueCells(valueCells, {
        error: false,
        id: `${rowIndex}-${id}`,
        rowIndex,
        value: country,
      })
    );
  }, [country]);

  return (
    <Box
      sx={cellFlagStyles.container}
      alignItems={
        isMobile ? "left" : FLEX_JUSTIFY_BY_ALIGN[cellProps.align || "center"]
      }
    >
      {isLoading ? (
        <>
          <Skeleton
            data-testid="skeleton"
            variant={"circular"}
            animation="wave"
            sx={cellFlagStyles.skeletonIcon}
          />
          {twoLines && (
            <Skeleton
              variant={"rectangular"}
              animation="wave"
              sx={cellFlagStyles.skeletonText}
            />
          )}
        </>
      ) : (
        <>
          <IconFlag />
          {twoLines && (
            <Typography
              color="text.grey"
              variant="overline"
              component="div"
              sx={cellFlagStyles.textFlag}
            >
              {TEXT_FLAG[country]}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
