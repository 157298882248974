import { SvgIconProps, SvgIcon } from "@mui/material";
import React, { FC } from "react";

const TransactionWaitingIcon: FC<SvgIconProps> = ({ sx, ...rest }) => (
  <SvgIcon
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ ...sx }}
    {...rest}
  >
    <rect y="0.25" width="32" height="32" rx="4" fill="#F7FAFC" />
    <path
      d="M16 23.75C20.1421 23.75 23.5 20.3921 23.5 16.25C23.5 12.1079 20.1421 8.75 16 8.75C11.8579 8.75 8.5 12.1079 8.5 16.25C8.5 20.3921 11.8579 23.75 16 23.75Z"
      stroke="#6D7781"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16 11.75V16.25L19 17.75"
      stroke="#6D7781"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default TransactionWaitingIcon;
