import { Box, Skeleton, SxProps, Theme, Typography } from "@mui/material";

type TypographyVariant =
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "overline"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | undefined;

export const CustomSkeleton = (props: {
  sx?: SxProps<Theme> | undefined;
  showContainer?: boolean;
  variant?: TypographyVariant;
  "data-testid"?: string;
}) => (
  <Box
    data-testid={props["data-testid"] ?? "custom-skeleton"}
    sx={{
      padding: "1rem",
      ...(props.showContainer && { background: "#F7FAFC" }),
      ...props.sx,
    }}
  >
    <Typography variant={props.variant}>
      <Skeleton />
    </Typography>
  </Box>
);
