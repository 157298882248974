import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainContainerStyles = createNamedStyles({
  boxContainer: {
    padding: "2rem 3rem",
  },
  root: { background: "transparent", boxShadow: "none" },
  select: {
    width: "328px",
  },
  emptyCenter: {
    textAlign: "center",
  },
  error1: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A",
    },
  },
});
