/* istanbul ignore file */

export enum ErrorsEnum {
  REQUIRED_FIELD = "Campo requerido",
  SELECT_SERVICE = "No se encuentra activado ningun medio de pago",
  SELECT_MERCHANT = "Seleccione un comercio",
  SELECT_CONDITION = "Seleccione una condición",
  REQUIRED_MIN_RULE = "Se requiere al menos una regla",
  MIN_4_CHARACTER = "Mínimo se requiere 4 caracteres",
  MIN_12_CHARACTER = "Mínimo se requiere 12 caracteres",
  MAX_10_CHARACTER = "Máximo se requiere 10 caracteres",
  MAX_12_CHARACTER = "Máximo se requiere 12 caracteres",
  MAX_22_CHARACTER = "Máximo se requiere 22 caracteres",
  MAX_32_CHARACTER = "Máximo se requiere 32 caracteres",
  ONLY_NUMBERS_WITH_FRACTION = "Solo se permiten números enteros y máximo 2 decimales",
  ONLY_NUMBERS = "Solo se permiten números enteros",
  INVALID_IP = "No es una dirección IP válida",
  INVALID_BIN = "No es un bin válido",
  INVALID_PHONE_NUMBER = "No es un número de teléfono válido",
  INVALID_EMAIL = "No es un correo válido",
  INVALID_CARD_HOLDER_NAME = "No es un valor válido",
  /* eslint-disable */
  INVALID_CARD_ID = 'No se permiten ingresar caracteres especiales, excepto el "-".',
  INVALID_REGEX = "El formato ingresado es incorrecto",
}
