import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { AutocompleteTextFieldComponent } from "../AutocompleteTextField/AutocompleteTextFieldComponent";
import { get, isEmpty, isUndefined } from "lodash";
import { MerchantsData } from "../../store/actionCreator";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import { IWhitelistRuleForm } from "../../shared/infrastructure/interfaces/IWhitelistRulesForm";
import {
  WHITELIST_CONDITION_ELEMENTS,
  WhitelistRulesSubtitleSection,
  WhitelistRulesTitleButton,
} from "../../shared/infrastructure/constants/WhitelistRulesConstants";
import { WhitelistActionButtons } from "../WhitelistActionButtons/WhitelistActionButtons";
import { useWatch, ValidationValueMessage } from "react-hook-form";
import { RegexExpressionEnum } from "../../shared/infrastructure/constants/RegexExpressionEnum";
import { Stack } from "@mui/material";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "17px",
  },
  subtitle: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
  },
  helperText: {
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  autocompleteTextField: {
    marginTop: 10,
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
}));

export interface PatternValidation {
  pattern: string;
  message: string;
}

export const getRulesByField = (
  required: ValidationValueMessage<boolean>,
  pattern?: PatternValidation
) => {
  if (pattern) {
    return {
      required: required,
      pattern: {
        value: new RegExp(pattern.pattern),
        message: pattern.message,
      },
    };
  }

  return {
    required: required,
  };
};

export interface GeneralInformationSectionProps {
  isLoading: boolean | undefined;
  isLoadingMerchantList: boolean;
  isEdit: boolean;
  textButton: string;
  merchantList: MerchantsData;
  handleSetSearchTextMerchantList: (searchText: string) => void;
  handleEnterSearchTextMerchantList: (key: string) => void;
  setFormValue: (name: string, value: string) => void;
  setMerchantInformation: (value: object) => void;
  onCancel: () => void;
  onAdd: () => void;
}

export const GeneralInformationSection: React.FC<GeneralInformationSectionProps> =
  (props: GeneralInformationSectionProps) => {
    const classes = useStyles();
    const maskedCards = useWatch({
      name: "maskedCards",
    }) as string;
    const cardIDs = useWatch({
      name: "cardIDs",
    }) as string;

    return (
      <ConnectForm<IWhitelistRuleForm>>
        {({ control, errors }) => {
          const TypedController = useTypedController<IWhitelistRuleForm>({
            control: control,
          });

          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.title} color="primary">
                      {WhitelistRulesSubtitleSection.WHITELIST_HEADER}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.subtitle}>
                      {WhitelistRulesSubtitleSection.WHITELIST_SUBTITLE}
                    </Typography>
                  </Grid>
                  {!props.isEdit ? (
                    <Grid item xs={4}>
                      <TypedController
                        name={"merchantId"}
                        rules={{ required: true }}
                        render={({ value }) => (
                          <AutocompleteTextFieldComponent
                            property={"merchantId"}
                            label={
                              WhitelistRulesSubtitleSection.MERCHANT_HEADER
                            }
                            value={value}
                            options={get(props, "merchantList.data", [])}
                            pathValue={"_source.publicMerchantId"}
                            pathCountry={"_source.country"}
                            pathName={"_source.name"}
                            isLoading={props.isLoadingMerchantList}
                            handleEnterSearchText={
                              props.handleEnterSearchTextMerchantList
                            }
                            handleSetSearchText={
                              props.handleSetSearchTextMerchantList
                            }
                            setValue={props.setMerchantInformation}
                            disabled={false}
                            error={!!errors.merchantId}
                            errorMessage={ErrorsEnum.SELECT_MERCHANT}
                            className={classes.autocompleteTextField}
                            showCountry={true}
                          />
                        )}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} />
                  <Grid container xs={12} md={12} lg={12} sm={12} spacing={1}>
                    <Grid item xs={6}>
                      <Stack direction={"column"} spacing={1}>
                        <Typography className={classes.title} color="primary">
                          {WhitelistRulesSubtitleSection.MASKEDCARDS_LABEL}
                        </Typography>

                        <TypedController
                          name={"maskedCards"}
                          rules={getRulesByField({
                            value: isEmpty(cardIDs) || isUndefined(cardIDs),
                            message:
                              "Tarjeta enmascarada y/o Kushki ID deben tener un valor",
                          })}
                          render={(properties) => (
                            <TextField
                              {...properties}
                              fullWidth
                              multiline
                              className={classes.helperText}
                              inputProps={{ "data-testid": "maskedCards" }}
                              id="maskedCards"
                              variant="outlined"
                              label={WHITELIST_CONDITION_ELEMENTS[0].name}
                              rows={4}
                              rowsMax={4}
                              error={!!errors.maskedCards}
                              helperText={errors.maskedCards?.message}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction={{ sm: "column", xs: "column" }}
                        spacing={1}
                      >
                        <Typography className={classes.title} color="primary">
                          {WhitelistRulesSubtitleSection.KUSHKI_ID_LABEL}
                        </Typography>
                        <TypedController
                          name={"cardIDs"}
                          rules={getRulesByField(
                            {
                              value:
                                isEmpty(maskedCards) ||
                                isUndefined(maskedCards),
                              message:
                                "Tarjeta enmascarada y/o Kushki ID deben tener un valor",
                            },
                            {
                              pattern: RegexExpressionEnum.MORE_THAN_ONE_UUID,
                              message: ErrorsEnum.INVALID_REGEX,
                            }
                          )}
                          render={(properties) => (
                            <TextField
                              {...properties}
                              fullWidth
                              multiline
                              className={classes.helperText}
                              inputProps={{ "data-testid": "cardIDs" }}
                              id="cardIDs"
                              variant="outlined"
                              label={WHITELIST_CONDITION_ELEMENTS[1].name}
                              rows={4}
                              rowsMax={4}
                              error={!!errors.cardIDs}
                              helperText={
                                !errors.cardIDs
                                  ? WhitelistRulesSubtitleSection.WHITELIST_MULTIPLE_VALUES_LABEL
                                  : errors.cardIDs.message
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <WhitelistActionButtons
                    mainActionText={props.textButton}
                    secondaryActionText={WhitelistRulesTitleButton.RETURN}
                    onCancel={props.onCancel}
                    onAdd={props.onAdd}
                    disabled={props.isLoading!}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </ConnectForm>
    );
  };

export default GeneralInformationSection;
