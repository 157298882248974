/**
 * DynamoDB's Index enum
 */
export enum AuthenticatorNameEnum {
  D3S = "3DS",
  OTP = "OTP",
}
export enum AuthenticatorValueEnum {
  D3S = "3DS",
  OTP = "OTP",
}
export const AUTHENTICATOR_VALUES = [
  AuthenticatorValueEnum.OTP,
  AuthenticatorValueEnum.D3S,
];
