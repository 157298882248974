import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui";
import { Chip, Typography } from "@mui/material";
import {
  BRAZIL_PAYMENT_MONTHS,
  GRACE_MONTHS,
  PAYMENT_MONTHS,
} from "../../shared/constants/DeferredListConstants";
import { ModalEnum } from "../../shared/enums/ModalEnum";
import React from "react";
import { CountriesEnum } from "../../shared/infrastructure/countries-enum";
import { isEmpty } from "lodash";

export type DeferredModalType = "payments" | "grace";

export interface Props {
  country: string;
  type: DeferredModalType;
  isOpen: boolean;
  onClose: () => void;
  modalItems: string[];
}

export default function DeferredListModal({
  country,
  type,
  isOpen,
  onClose,
  modalItems,
}: Props) {
  const title: Record<Props["type"], string> = {
    grace: ModalEnum.GRACE,
    payments: ModalEnum.PAYMENTS,
  };
  const chips: Record<Props["type"], string[]> = {
    grace: GRACE_MONTHS,
    payments:
      country == CountriesEnum.BRAZIL ? BRAZIL_PAYMENT_MONTHS : PAYMENT_MONTHS,
  };
  let custom: string[] = [];

  modalItems.map((items: string) => {
    if (parseInt(items) > 12) {
      custom.push(items);
    }
  });

  return (
    <ModalContent
      buttonAction={onClose}
      buttonText={"Aceptar"}
      buttonType={ModalContentButtonTypeEnum.PRIMARY}
      descriptionText={""}
      isOpen={isOpen}
      onClose={onClose}
      titleText={title[type]}
      type={ModalContentTypeEnum.DEFAULT}
    >
      {country == CountriesEnum.BRAZIL ? (
        <>
          <Typography
            id={"brazil-subtitle"}
            color={"#6f7780"}
            variant="h5"
            marginBottom={"20px"}
          >
            {ModalEnum.DEFINED_MONTHS}
          </Typography>
          {chips[type]?.map((item: string) => (
            <Chip
              key={item}
              className="tagInfo"
              label={item}
              color={modalItems.includes(item) ? "success" : "secondary"}
              sx={{ marginBottom: "20px", marginRight: "4px" }}
            />
          ))}
          {!isEmpty(custom) && (
            <Typography
              id={"brazil-subtitle-2"}
              color={"#6f7780"}
              variant="h5"
              marginBottom={"20px"}
            >
              {ModalEnum.CUSTOM_MONTHS}
            </Typography>
          )}
          {custom.map((item: string) => (
            <Chip
              key={item}
              className="tagInfo"
              label={item}
              color={"success"}
              sx={{ marginRight: "4px" }}
            />
          ))}
        </>
      ) : (
        chips[type]?.map((item: string) => (
          <Chip
            key={item}
            className="tagInfo"
            label={item}
            color={modalItems.includes(item) ? "success" : "secondary"}
            sx={{ marginRight: "4px" }}
          />
        ))
      )}
    </ModalContent>
  );
}
