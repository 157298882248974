import { useEffect, useState } from "react";
import {
  IAlarmComments,
  IFileCommentsProps,
  IInformationSection,
  IUseDetailCardBodyAlarms,
  IUseDetailCardBodyAlarmsProps,
} from "@components/organism/DetailCardBodyAlarms/state/IUseDetailCardBodyAlarms.interfaces";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { RootState } from "@store/store";
import {
  InformationSectionAlarm,
  TimelineSectionLabel,
} from "@shared/enum/AlarmDetailCardEnum";
import { get, isEmpty, isEqual, set } from "lodash";
import scientificToDecimal from "scientific-to-decimal";
import {
  renderAmountValue,
  translateFrequency,
} from "@shared/utils/detailCardUtils";
import { Timeline } from "../../../../../types/alarm";
import {
  getFileComments,
  setAlarmAction,
} from "@store/thunks/alarms/alarms.thunk";
import { ActionAlarmEnum } from "@shared/enum/ActionAlarmEnum";
import { TimelineTrxData } from "@kushki/connect-ui/dist/Components/Molecules/TimeLine/TimeLine.interfaces";
import { ActiveAlarmStatus } from "@shared/enum/ActiveAlarmStatusEnum";
import {
  buildTimelineItems,
  scrollToElement,
} from "@shared/utils/timelineTabUtils";
import { formatValidDate } from "@shared/utils/parseDatesUtils";
import { DateFormatEnum } from "@shared/constants/parseMonths";
import { useParams } from "react-router-dom";
import { SourceEnum } from "@shared/utils/activeAlarmsEnum";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { getUserName } from "@shared/utils/userUtils";
import { getListCatalogs } from "@store/thunks/catalogs/catalogs.thunk";
import { ConfigCodeEnum } from "@shared/enum/ConfigCodeEnum";
import { CountryCode } from "@shared/enum/CountryCodeEnum";
import { ProductLineEnum } from "@shared/enum/ProductLineEnum";
import { TypeCatalog } from "@shared/enum/TypeCatalog";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { getCatalogAsSelectOptions } from "@shared/utils/listCatalogUtils";

export const useDetailCardBodyAlarms = ({
  searchAlarmsRequest,
}: IUseDetailCardBodyAlarmsProps): IUseDetailCardBodyAlarms => {
  const { detailCardAlarms, isLoadingSetAlarmAction, isLoadingFilesComment } =
    useAppSelector((state: RootState) => state.alarms);
  const { listMccCatalog, isLoading } = useAppSelector(
    (state: RootState) => state.catalogs
  );
  const dispatch = useAppDispatch();
  const { type } = useParams();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [inputValue, setInputValue] = useState("");
  const [editTimelineId, setEditTimelineId] = useState<string>("");
  const [isEditComment, setIsEditComment] = useState<boolean>(false);
  const [showModalFiles, setShowModalFiles] = useState<boolean>(false);
  const [detailFilesComment, setDetailFilesComment] = useState<
    IFileCommentsProps[]
  >([]);
  const [mccCatalog, setMccCatalog] = useState<ISelectOption[]>([]);

  const handleChangeTab = (index: number) => {
    setTabIndex(index);
  };

  const informationSection: IInformationSection[] = [
    {
      label: InformationSectionAlarm.ID_MERCHANT,
      value: get(detailCardAlarms, "selectedAlarm.merchantId", ""),
    },
    {
      label: InformationSectionAlarm.FREQUENCY,
      value:
        translateFrequency[
          get(detailCardAlarms, "selectedAlarm.frequency", "")
        ],
    },
    {
      label: InformationSectionAlarm.COMERCIAL_EXECUTIVE,
      value: get(detailCardAlarms, "selectedAlarm.commercialExecutive", ""),
    },
    {
      label: InformationSectionAlarm.CATEGORY,
      value: get(detailCardAlarms, "selectedAlarm.category", ""),
    },
    {
      label: InformationSectionAlarm.OPERATIONAL_COUNTRY,
      value: get(detailCardAlarms, "selectedAlarm.country", ""),
    },
    {
      label: InformationSectionAlarm.TPV,
      value: renderAmountValue(get(detailCardAlarms, "selectedAlarm.tpv", "")),
    },
  ];

  const familySection: IInformationSection[] = [
    {
      label: InformationSectionAlarm.OWNER,
      value: get(detailCardAlarms, "selectedAlarm.ownerName", ""),
    },
    {
      label: InformationSectionAlarm.CUSTOMER,
      value: get(detailCardAlarms, "selectedAlarm.customerName", ""),
    },
    {
      label: InformationSectionAlarm.BRANCH,
      value: get(detailCardAlarms, "selectedAlarm.merchantName", ""),
    },
  ];

  const amountDeviationSection: IInformationSection[] = [
    {
      label: InformationSectionAlarm.AVERAGE_AMOUNT.replace(
        "#frequency",
        translateFrequency[get(detailCardAlarms, "selectedAlarm.frequency", "")]
      ),
      value: renderAmountValue(
        get(detailCardAlarms, "selectedAlarm.averageAmountPrevious", 0)
      ),
    },
    {
      label: InformationSectionAlarm.DEVIATION,
      value: renderAmountValue(
        get(detailCardAlarms, "selectedAlarm.standardDeviationAmount", 0)
      ),
    },
    {
      label: InformationSectionAlarm.UPPER_LIMIT,
      value: renderAmountValue(
        get(detailCardAlarms, "selectedAlarm.upperLimitAmount", 0)
      ),
    },
    {
      label: InformationSectionAlarm.TOTAL_AMOUNT_TRANSACTIONS_DAY,
      value: renderAmountValue(
        get(detailCardAlarms, "selectedAlarm.sumYesterdayAmount", 0)
      ),
    },
    {
      label: InformationSectionAlarm.TOTAL_AMOUNT_PRECESSED,
      value: renderAmountValue(
        scientificToDecimal(
          +get(detailCardAlarms, "selectedAlarm.sumPreviousAmount", 0) +
            +get(detailCardAlarms, "selectedAlarm.sumYesterdayAmount", 0)
        )
      ),
    },
  ];

  const transactionDeviationSection: IInformationSection[] = [
    {
      label: InformationSectionAlarm.NO_TRANSACTIONS_AVERAGE.replace(
        "#frequency",
        translateFrequency[get(detailCardAlarms, "selectedAlarm.frequency", "")]
      ),
      value: renderAmountValue(
        get(
          detailCardAlarms,
          "selectedAlarm.averageTransactionCountPrevious",
          0
        ),
        ""
      ),
    },
    {
      label: InformationSectionAlarm.DEVIATION,
      value: renderAmountValue(
        get(
          detailCardAlarms,
          "selectedAlarm.standardDeviationTransactionCount",
          0
        ),
        ""
      ),
    },
    {
      label: InformationSectionAlarm.NO_MAX_TRANSACTIONS,
      value: renderAmountValue(
        get(detailCardAlarms, "selectedAlarm.upperLimitTransactionCount", 0),
        ""
      ),
    },
    {
      label: InformationSectionAlarm.NO_TRANS_PREVIOUS_DAY,
      value: renderAmountValue(
        get(detailCardAlarms, "selectedAlarm.sumYesterdayTransactionCount", 0),
        ""
      ),
    },
    {
      label: InformationSectionAlarm.NO_TRANSACTIONS,
      value: renderAmountValue(
        +get(detailCardAlarms, "selectedAlarm.sumPreviousTransactionCount", 0) +
          +get(
            detailCardAlarms,
            "selectedAlarm.sumYesterdayTransactionCount",
            0
          ),
        ""
      ),
    },
  ];

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const handleAddEditComment = () => {
    if (isEditComment) {
      dispatch(
        setAlarmAction({
          alarmsActionRequest: {
            action: ActionAlarmEnum.UPDATE_COMMENT,
            alarmId: get(detailCardAlarms, "selectedAlarm._id", ""),
            comment: inputValue,
            timelineId: editTimelineId,
          },
          searchAlarmsRequest,
        })
      );
    } else {
      dispatch(
        setAlarmAction({
          alarmsActionRequest: {
            action: ActionAlarmEnum.ADD_COMMENT,
            alarmId: get(detailCardAlarms, "selectedAlarm._id", ""),
            comment: inputValue,
          },
          searchAlarmsRequest,
        })
      );
    }
    setIsEditComment(false);
  };

  const handleDeletedComment = (timelineId: string) => {
    dispatch(
      setAlarmAction({
        alarmsActionRequest: {
          action: ActionAlarmEnum.DELETE_COMMENT,
          alarmId: get(detailCardAlarms, "selectedAlarm._id", ""),
          timelineId,
        },
        searchAlarmsRequest,
      })
    );
  };

  const handleEditComment = (timelineId: string, comment: string) => {
    setEditTimelineId(timelineId);
    setInputValue(comment);
    setIsEditComment(true);
  };

  const verifyCommentIsUpdate = (
    createdAt: number,
    updatedAt: number
  ): boolean => {
    return !isEqual(updatedAt, 0) && updatedAt > createdAt;
  };
  const alarmCommentsList = (): IAlarmComments[] => {
    const userNameLocalStorage = getUserName();

    const comments: IAlarmComments[] = get(
      detailCardAlarms,
      "selectedAlarm.timeline",
      []
    )
      .filter(
        (item: Timeline) =>
          item.isComment ||
          isEqual(item.status, ActiveAlarmStatus.ROS) ||
          isEqual(item.status, ActiveAlarmStatus.NORMAL)
      )
      .sort(
        (previous: Timeline, current: Timeline) =>
          previous.createdAt - current.createdAt
      )
      .map((item: Timeline) => ({
        comment: get(item, "comment", ""),
        createdAt: formatValidDate(
          get(item, "createdAt", 0),
          DateFormatEnum.dd_MM_yyyy,
          "-"
        ),
        fullName: get(item, "fullName", ""),
        isUpdated: verifyCommentIsUpdate(
          item.createdAt,
          get(item, "updatedAt", 0)
        ),
        timelineId: get(item, "_id", ""),
        userName: get(item, "userName", ""),
        userNameLocalStorage,
      }));

    set(comments[comments.length - 1], "handleDeleted", handleDeletedComment);
    set(comments[comments.length - 1], "handleEdit", handleEditComment);

    return comments;
  };

  const buildTimelineSection = (): TimelineTrxData[] => {
    const timelineItems: TimelineTrxData[] = [];
    let pendingCount: number = 0;

    get(detailCardAlarms, "selectedAlarm.timeline", []).forEach(
      (item: Timeline) => {
        if (!item.isComment) {
          if (item.status === ActiveAlarmStatus.PENDING) {
            pendingCount++;
            timelineItems.push(
              buildTimelineItems(
                item,
                pendingCount < 2
                  ? TimelineSectionLabel.ACTIVE_ALARM
                  : TimelineSectionLabel.RE_ACTIVE,
                false
              )
            );
          } else if (item.status === ActiveAlarmStatus.INVESTIGATION) {
            timelineItems.push(
              buildTimelineItems(
                item,
                TimelineSectionLabel.INIT_INVESTIGATION,
                false
              )
            );
          } else if (item.status === ActiveAlarmStatus.NORMAL) {
            timelineItems.push(
              buildTimelineItems(
                item,
                TimelineSectionLabel.NORMAL,
                !isEmpty(get(item, "files", []))
              )
            );
          } else if (item.status === ActiveAlarmStatus.ROS) {
            timelineItems.push(
              buildTimelineItems(
                item,
                TimelineSectionLabel.ROS,
                !isEmpty(get(item, "files", []))
              )
            );
          } else if (item.status === ActiveAlarmStatus.BLOCKED) {
            timelineItems.push(
              buildTimelineItems(item, TimelineSectionLabel.BLOCKED, true)
            );
          } else if (item.status === ActiveAlarmStatus.IN_PROCESS) {
            timelineItems.push(
              buildTimelineItems(item, TimelineSectionLabel.IN_PROCESS, true)
            );
          } else if (item.status === ActiveAlarmStatus.UNLOCK) {
            timelineItems.push(
              buildTimelineItems(item, TimelineSectionLabel.UNLOCK, true)
            );
          }
        } else {
          timelineItems.push(
            buildTimelineItems(item, TimelineSectionLabel.COMMENT, false)
          );
        }
      }
    );

    return timelineItems.reverse();
  };

  const handleRedirectComment = (timelineId: string) => {
    setTabIndex(2);
    setTimeout(() => {
      scrollToElement(timelineId);
    }, 300);
  };

  const componentIdAddCommentBtn = (): string => {
    return type === SourceEnum.HISTORY
      ? SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_ADD_COMMENT_HISTORIC
      : SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_ADD_COMMENT;
  };

  const handleShowModalFiles = () => {
    setShowModalFiles(!showModalFiles);
  };

  const handleButtonLink = (selected: TimelineTrxData) => {
    const time_line: IAlarmComments[] = get(
      detailCardAlarms,
      "selectedAlarm.timeline",
      []
    );

    const comment: IAlarmComments = time_line.find((comment: IAlarmComments) =>
      isEqual(get(comment, "_id"), get(selected, "ticketNumber", ""))
    ) as IAlarmComments;

    const files_comments: IFileCommentsProps[] = get(
      comment,
      "files",
      []
    ).reduce((acc: IFileCommentsProps[], file: string) => {
      acc.push({
        fileName: file.replace("v2/", ""),
        key: get(detailCardAlarms, "selectedAlarm.isMigrated", false)
          ? file.split(".")[0]
          : file,
      });

      return acc;
    }, []);

    setDetailFilesComment(files_comments);
    handleShowModalFiles();
  };

  const handleDownloadFileComment = (payload: IFileCommentsProps) => {
    dispatch(getFileComments(payload));
  };

  useEffect(() => {
    if (listMccCatalog.length === 0 && !isLoading) {
      dispatch(
        getListCatalogs({
          configCode: ConfigCodeEnum.BASIC_DATA,
          countryCode: CountryCode.GLB,
          productLine: ProductLineEnum.PLA,
          typeCatalog: TypeCatalog.MCC,
        })
      );
    }
  });

  useEffect(() => {
    if (listMccCatalog.length !== 0 && mccCatalog.length === 0) {
      setMccCatalog(getCatalogAsSelectOptions(listMccCatalog));
    }
  }, [listMccCatalog]);

  return {
    alarm: detailCardAlarms.selectedAlarm,
    commentsTab: {
      comments: alarmCommentsList(),
      componentIdAddCommentBtn: componentIdAddCommentBtn(),
      handleAddEditComment,
      handleInputChange,
      textAreaValue: inputValue,
    },
    handleChangeTab,
    informationTab: {
      amountDeviationSection,
      familySection,
      informationSection,
      mccCatalog,
      rowData: get(detailCardAlarms, "selectedAlarm"),
      showAmount:
        get(detailCardAlarms, "selectedAlarm.isAboveUpperLimitAmount") === 1,
      showFamily: !isEmpty(get(detailCardAlarms, "selectedAlarm.entityName")),
      showTransactions:
        get(detailCardAlarms, "selectedAlarm.isAboveUpperLimitCount") === 1,
      transactionDeviationSection,
      variables: get(detailCardAlarms, "selectedAlarm.variables", []),
    },
    isLoadingSetAlarmAction,
    modalFileProps: {
      detailFilesComment,
      handleDownloadFileComment,
      handleShowModalFiles,
      isLoadingDownload: isLoadingFilesComment,
      showModalFiles,
    },
    tabIndex,
    timelineTab: {
      handleButtonLink,
      handleRedirectComment,
      timelineItems: buildTimelineSection(),
    },
  };
};
