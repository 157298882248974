import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { WebHookListResponse } from "../../../../types/webhook_list_response";
import { WebHookListRequest } from "../../../../types/webhook_list_request";
import { IUserListResponse } from "../../../shared/infrastructure/interfaces/UserListResponse";
import { IBalanceResponse } from "../../../../types/balance_response";
import { PayoutRulesResponse } from "../../../../types/payout_rules";
import { RequestPayoutRules } from "../../../../types/request_payout_rules";
import { AxiosResponse } from "axios";

const getWebhookList = createAsyncThunk<
  WebHookListResponse,
  WebHookListRequest
>("app/getWebhookList", async (payload) => {
  const response = await axios.post<WebHookListResponse>(
    API_ROUTES.WEBHOOK_LIST,
    payload
  );

  return response.data;
});

export { getWebhookList };

export const getUsers = createAsyncThunk(
  "resume/getUsers",
  async (value: { publicMerchantId: string; limitNumber: number }) => {
    const response = await axios.get<IUserListResponse>(
      `${API_ROUTES.USERS}${value.limitNumber}${API_ROUTES.USERS_MERCHANT}${value.publicMerchantId}`
    );

    return response.data;
  }
);

export const getBalance = createAsyncThunk<
  IBalanceResponse,
  { merchantId: string }
>("app/getBalance", async (value: { merchantId: string }) => {
  const response = await axios.get<IBalanceResponse>(
    `/wallet/v1/balance/${value.merchantId}`
  );

  return response.data;
});

export const getPayoutsRules = createAsyncThunk<
  PayoutRulesResponse,
  RequestPayoutRules
>("app/getPayoutsRules", async (payload: RequestPayoutRules) => {
  const response: AxiosResponse<PayoutRulesResponse> =
    await axios.post<PayoutRulesResponse>(API_ROUTES.PAYOUTS_RULES, payload);

  return response.data;
});
