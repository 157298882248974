import { IRowInteractiveProps } from "@kushki/connect-ui";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useEffect, useState } from "react";
import { CatalogsEnum } from "../../../shared/infrastructure/catalogs-enum";
import {
  ALL_TABLE_COLUMNS,
  ALL_TABLE_COLUMNS_EEUU,
  ALL_TABLE_COLUMNS_MX,
  CELL_TYPES,
  TABLE_ROW,
  TEXT_CELL,
} from "../../../shared/infrastructure/constants/DispersionBlockConstants";
import {
  getCatalog,
  INVOICE_FRECUENCY,
} from "../../../shared/constants/MerchantInformationConstants";
import { ITextBlock } from "../../../shared/infrastructure/interfaces/ITextBlock";
import { IDispersionBlock } from "./useDispersionBlock.interface";
import { defaultTo, get } from "lodash";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import axiosInstance from "../../../shared/axios-util";
import { ConfigurationIdEnum } from "../../../shared/enums";
import {
  listCatalog,
  selectConfigurationRatesInfo,
  selectMerchantDispersionInfo,
  selectNodeInfo,
} from "../../../store/selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigurationRatesInfoResponse,
  setMerchantDispersionInfoResponse,
} from "../../../store/actions/actions";
import { IAccountInfoItem } from "../../../../types/merchant_dispersion_info_response";
import { searchBykey } from "../../../shared/utils/search";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import {
  getSectionInfo,
  statusNodeInfo,
} from "../../../shared/utils/node_info";
import { AccountPropertiesValueEnum } from "../../../shared/enums/AccountPropertiesEnum";
import { CountryValueEnum } from "../../../shared/enums/CountryEnum";
import { Category } from "../../../shared/constants/information_constants";
import { CountriesEnum } from "../../../shared/infrastructure/countries-enum";
import { Catalog } from "../../../../types/catalog_response";
import { ProductLineEnum } from "../../../shared/enums/ProductLineEnum";
import { CountryCodeCatalogEnum } from "../../../shared/enums/CountryCodeEnum";
import { getListCatalogs } from "../../../store/thunks/catalogs/catalogs.thunk";

type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

const getCatalogBankId = (
  country: string,
  listCatalog: Catalog[]
): Category[] => {
  if (country === CountriesEnum.CHILE) {
    const list: Catalog = listCatalog.find(
      (catalog: Catalog) => catalog.name === "bankListDispersion"
    )!;

    const categories: Category[] = [];

    defaultTo(get(list, "data"), []).forEach((item) => {
      categories.push({
        name: item.value,
        value: item.code,
      });
    });

    return categories;
  }

  return getCatalog(country, CatalogsEnum.Banks);
};

export const onRenderValue = (
  key: string,
  columnValue: string,
  country: string,
  listCatalog: Catalog[]
) => {
  const renderValues = {
    [AccountPropertiesValueEnum.BANK_ID]: () => {
      const result = getCatalogBankId(country, listCatalog)?.find(
        (bank) => bank.value === columnValue
      );

      return result ? result.name : "";
    },

    [AccountPropertiesValueEnum.ACCOUNT_TYPE]: () => {
      const result = getCatalog(country, CatalogsEnum.BankAccountTypes)?.find(
        (accountType) => accountType.value === columnValue
      );

      return result ? result.name : "";
    },

    default: () => columnValue,
  };

  return get(renderValues, key, renderValues.default)();
};

const getColumns = (
  object: IAccountInfoItem,
  country: string,
  columns: CustomHeaderCellProps[],
  listCatalog: Catalog[]
): ITableCellProps[] => {
  return columns.map((column) => {
    let value = onRenderValue(
      column.key,
      get(object, column.key),
      country,
      listCatalog
    );

    if (column.key === AccountPropertiesValueEnum.BANK_ID && !value) {
      value = get(object, AccountPropertiesValueEnum.BANK_NAME, "");
    }

    const tableCell = get(TABLE_ROW, column.cellType, TEXT_CELL);

    return tableCell(value) as ITableCellProps;
  });
};

export const useDispersionBlock = (): IDispersionBlock => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [dispersionBlock, setDispersionBlock] = useState<ITextBlock>({
    subtitle: "",
    title: "",
  });
  const [fraudBlock, setFraudBlock] = useState<ITextBlock>();
  const [isComplete, setIsComplete] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showColumns, setShowColumns] =
    useState<CustomHeaderCellProps[]>(ALL_TABLE_COLUMNS);
  const [principalAccountState, setPrincipalAcccountState] = useState<
    IAccountInfoItem | undefined
  >(undefined);
  const dispatch = useDispatch();
  const [existRoutingNumber, setExistRoutingNumber] = useState<
    boolean | undefined
  >(false);

  const onClickRow = () => {};
  const nodeInfoData = useSelector(selectNodeInfo);
  const catalogs = useSelector(listCatalog);

  const getColumnsByCountry = (country: string): CustomHeaderCellProps[] => {
    switch (country) {
      case CountryValueEnum.MEXICO:
        return ALL_TABLE_COLUMNS_MX;
      case CountryValueEnum.EEUU:
        if (existRoutingNumber) return ALL_TABLE_COLUMNS_EEUU;
        else return ALL_TABLE_COLUMNS;
      default:
        return ALL_TABLE_COLUMNS;
    }
  };

  const getData = async (publicMerchantId: string) => {
    setIsLoading(true);
    try {
      const [merchantDispersionInfoResponse, configRatesResponse] =
        await Promise.all([
          axiosInstance.post(API_ROUTES.BILLING_CORE_MERCHANT_NODE_INFO, {
            configId: ConfigurationIdEnum.CN004,
            publicMerchantId: publicMerchantId,
          }),
          axiosInstance.post(API_ROUTES.GET_CONFIG_RATES, {
            merchantId: publicMerchantId,
          }),
        ]);

      dispatch(
        setMerchantDispersionInfoResponse(merchantDispersionInfoResponse.data)
      );

      dispatch(setConfigurationRatesInfoResponse(configRatesResponse.data));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (nodeInfoData) {
      if (
        nodeInfoData.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfoData.entityName === EntityNameEnum.BRANCH
      ) {
        let sectionInfo = getSectionInfo(
          ConfigurationIdEnum.CN004,
          nodeInfoData.configs
        );

        if (sectionInfo) {
          if (sectionInfo.status === statusNodeInfo.COMPLETE) {
            setIsComplete(true);
            getData(sectionInfo.value);
          } else {
            setIsLoading(false);
            setIsComplete(false);
          }
        } else {
          setIsLoading(false);
          setIsComplete(false);
        }
      } else {
        setIsLoading(false);
        setIsComplete(false);
      }
    } else {
      setIsLoading(false);
      setIsComplete(false);
    }
  }, [nodeInfoData]);

  const configRatesResponse = useSelector(selectConfigurationRatesInfo);
  const merchantDispersionInfoResponse = useSelector(
    selectMerchantDispersionInfo
  );

  useEffect(() => {
    const principalAccount: IAccountInfoItem | undefined =
      merchantDispersionInfoResponse?.dispersion?.accountInfo?.find(
        (account: IAccountInfoItem) => account.accountOrder === "principal"
      );

    setPrincipalAcccountState(principalAccount);
    if (merchantDispersionInfoResponse) {
      setExistRoutingNumber(
        get(
          merchantDispersionInfoResponse?.dispersion,
          "accountInfo[0]routingNumber",
          ""
        ).length > 0
      );
    }

    const columns: CustomHeaderCellProps[] = getColumnsByCountry(
      principalAccount?.bankOfOrigin!
    );

    setShowColumns(columns);

    principalAccount &&
      setRows([
        {
          cells: getColumns(
            principalAccount,
            principalAccount?.bankOfOrigin!,
            columns,
            []
          ),
          id: "dispersion",
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        },
      ]);

    setDispersionBlock({
      subtitle: merchantDispersionInfoResponse?.dispersion?.keepCommission
        ? "Con retenciones de comisiones antes de dispersar"
        : "Sin retenciones de comisiones antes de dispersar",
      title: merchantDispersionInfoResponse?.dispersionFrequency
        ? `Dispersión ${searchBykey(
            merchantDispersionInfoResponse.dispersionFrequency,
            INVOICE_FRECUENCY
          )}`
        : "",
    });

    configRatesResponse?.keepFraud &&
      setFraudBlock({
        subtitle: `Con ${configRatesResponse?.fraudPercentage} % de retenciones por fraude a ${configRatesResponse?.retentionPeriod} días laborables`,
        title: "Prevención de fraude",
      });

    setIsLoading(false);
  }, [configRatesResponse, merchantDispersionInfoResponse]);

  useEffect(() => {
    if (
      principalAccountState?.bankOfOrigin! === CountriesEnum.CHILE &&
      catalogs.length === 0
    ) {
      dispatch(
        getListCatalogs({
          configCode: ConfigurationIdEnum.CN004,
          countryCode: CountryCodeCatalogEnum.CL,
          productLine: ProductLineEnum.PLA,
        })
      );
    }
  }, [principalAccountState]);

  useEffect(() => {
    if (principalAccountState) {
      const columns: CustomHeaderCellProps[] = getColumnsByCountry(
        principalAccountState?.bankOfOrigin!
      );

      setRows([
        {
          cells: getColumns(
            principalAccountState!,
            principalAccountState?.bankOfOrigin!,
            columns,
            catalogs
          ),
          id: "dispersion",
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        },
      ]);
    }
  }, [catalogs]);

  return {
    dispersionBlock,
    fraudBlock,
    isComplete,
    isLoading,
    onClickRow,
    table: {
      columns: showColumns,
      rows: rows,
    },
  };
};
