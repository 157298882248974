import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";
import { makeStyles } from "@material-ui/core/styles";
import { SecurityRule } from "../../../types/security_rule";
import { SecurityRuleActionValue } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";

export interface SnackBarAlertProps {
  msg: string;
  open: boolean;
  type: Color;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
  data?: SecurityRule;
}

const useStyles = makeStyles({
  topSnackBar: {
    marginTop: "70px",
  },
  snackBarError: {
    background: "#E24763",
    color: "#FFFFFF",
    opacity: "0.9",
  },
  snackBarSuccess: {
    background: "#293036",
    color: "#FFFFFF",
    opacity: "0.9",
  },
});

export const SnackbarAlert: React.FC<SnackBarAlertProps> = (
  props: SnackBarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);
  const classes = useStyles();
  const action = get(props, "data.rule.action", "");
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={props.handlerClose}
      >
        {action === SecurityRuleActionValue["3DS"] ||
        action === SecurityRuleActionValue.OTP ? (
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={props.handlerClose}
            severity={props.type}
            className={
              props.type != "error"
                ? classes.snackBarSuccess
                : classes.snackBarError
            }
          >
            {props.msg}
          </MuiAlert>
        ) : (
          <MuiAlert
            className={classes.snackBarSuccess}
            elevation={6}
            variant="filled"
            onClose={props.handlerClose}
            severity={props.type}
          >
            {props.msg}
          </MuiAlert>
        )}
      </Snackbar>
    </React.Fragment>
  );
};
