import { makeStyles } from "@material-ui/core/styles";

export const useDeferredSettingsStyles = makeStyles({
  descriptionAlert: {
    color: "#4498EE",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
  },
  descriptionContent: {
    color: "#6D7781",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "120%",
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  paddingContent: {
    padding: "20px 22px",
  },
  titleContent: {
    color: "#023365",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
  },
});
