import { useDispatch, useSelector } from "react-redux";
import {
  setCommerceType,
  setRuleDetails,
} from "../../../../store/actionCreators";
import { RuleDetail } from "../../../../../types/rule_detail";
import { useFormContext } from "react-hook-form";
import { NodesState } from "../../../../shared/infrastructure/interfaces/NodesState";
import { IUseSubtypeState } from "../../../../shared/infrastructure/interfaces/IUseSubtypeState";
import { IAppState } from "../../../../store/reducer";
import { SearchRequestRuleByIdResponse } from "../../../../../types/search_request_rule_by_id_response";
import { SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../../shared/infrastructure/interfaces/IUseParams";
import { isFormEditMode } from "../../../../utils/utilsFile";
import {
  ServiceLabelEnum,
  SubTypeEnum,
  TypeToSubtypeRules,
} from "../../../../shared/infrastructure/enums/CreateRuleConstans";
import { get, isEmpty, isEqual, isUndefined } from "lodash";
import { GetRuleByIdResponse } from "../../../../../types/get_rule_by_id";
import {
  TableValueEnum,
  TEXT_TRANSFORM_COLUMNS,
} from "../../../../shared/infrastructure/enums/DataTableEnum";
import { ConfigurationTypeEnum } from "../../../../shared/infrastructure/enums/ConfigurationTypeEnum";

export const useSubtypeState = (props: NodesState): IUseSubtypeState => {
  const { control, errors, setValue, clearErrors, watch } = useFormContext();
  const isUpdatedRule: boolean | undefined = watch("isUpdatedRule");
  const subtype: string | undefined = watch("subType");
  const commerceType: string | undefined = watch("commerceType");
  const [commerceTypeDefaultValue, setCommerceTypeDefaultValue] = useState<
    string | undefined
  >(undefined);
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );

  const dispatch = useDispatch();
  const { id, ruleId } = useParams<IUseParams>();
  const idValue: string = id || ruleId;

  const selectSubType = (): void => {
    setValue("hierarchy", undefined);
    clearErrors("hierarchy");

    setValue("countries", []);
    clearErrors("countries");

    setValue("withoutHierarchy", undefined);
    clearErrors("withoutHierarchy");

    props.setNodesChange(undefined);
    dispatch(setRuleDetails({} as RuleDetail));
  };

  const selectCommerceType = (event: SyntheticEvent): void => {
    setValue("commerceType", get(event, "target.value", ""));
    dispatch(setCommerceType(get(event, "target.value", "")));
  };

  const searchRequestRuleById: SearchRequestRuleByIdResponse | undefined =
    useSelector((state: IAppState) => state.searchRequestRuleById);

  const searchRuleByIdData: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );

  useEffect(() => {
    if (searchRequestRuleById && isFormEditMode(idValue)) {
      setValue("subType", searchRequestRuleById.subType);
    }
  }, [searchRequestRuleById, idValue]);

  useEffect(() => {
    if (searchRequestRuleById && isFormEditMode(idValue)) {
      switch (searchRequestRuleById.rule.name) {
        case SubTypeEnum.RULE_NAME_WHITELIST_WITHOUT_HIERARCHY: {
          setCommerceTypeDefaultValue(SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY);

          return setValue(
            "commerceType",
            SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY
          );
        }

        case SubTypeEnum.RULE_NAME_WHITELIST_WITH_HIERARCHY: {
          setCommerceTypeDefaultValue(SubTypeEnum.COMMERCE_WITH_HIERARCHY);

          return setValue("commerceType", SubTypeEnum.COMMERCE_WITH_HIERARCHY);
        }

        default:
          return setValue(
            "commerceType",
            get(searchRequestRuleById, "nodeId")
              ? SubTypeEnum.COMMERCE_WITH_HIERARCHY
              : SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY
          );
      }
    }
  }, [searchRequestRuleById, idValue]);

  useEffect(() => {
    if (searchRuleByIdData && isFormEditMode(idValue)) {
      if (
        isEqual(
          get(searchRuleByIdData, "rule.type", ""),
          ConfigurationTypeEnum.WHITELIST
        )
      ) {
        const sub_type: string =
          TEXT_TRANSFORM_COLUMNS[get(searchRuleByIdData, "rule.rule.name", "")];
        const is_commerce: boolean = isEqual(
          sub_type,
          ServiceLabelEnum.COMMERCE
        );
        setValue(
          "subType",
          is_commerce ? TableValueEnum.COMMERCE : TableValueEnum.COUNTRY
        );
      } else {
        setValue(
          "subType",
          TypeToSubtypeRules[get(searchRuleByIdData, "rule.type", "")]
        );
      }
    }
  }, [searchRuleByIdData, idValue]);

  useEffect(() => {
    if (searchRuleByIdData && isFormEditMode(idValue)) {
      const commerceTypeValue: string = !isEmpty(
        get(searchRuleByIdData, "rule.entityName")
      )
        ? SubTypeEnum.COMMERCE_WITH_HIERARCHY
        : SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY;

      setCommerceTypeDefaultValue(commerceTypeValue);
      setValue("commerceType", commerceTypeValue);
    }
  }, [searchRuleByIdData, idValue]);

  useEffect(() => {
    if (subtype === SubTypeEnum.COUNTRY) {
      setValue("commerceType", "");
      setValue("hierarchy", undefined);
    }
  }, [subtype]);

  useEffect(() => {
    if (!isFormEditMode(idValue)) {
      setValue("commerceType", SubTypeEnum.COMMERCE_WITH_HIERARCHY);
      if (!isUndefined(ruleDetails) && Object.keys(ruleDetails).length !== 0) {
        setValue("subType", get(ruleDetails, "subType", ""));
        if (isEqual(ruleDetails.subType, SubTypeEnum.COMMERCE)) {
          setValue(
            "commerceType",
            !isEmpty(get(ruleDetails, "entityName", ""))
              ? SubTypeEnum.COMMERCE_WITH_HIERARCHY
              : SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY
          );
        }
      }
    }
  }, []);

  return {
    control,
    errors,
    actions: {
      selectSubType,
      selectCommerceType,
    },
    subtype,
    commerceType,
    isUpdatedRule,
    id: idValue,
    commerceTypeDefaultValue,
  };
};
