import { DispersionFields } from "../../../shared/enums/dispersionsEnum";
import {
  IUseDispersionFormState,
  IUseDispersionFormStateProps,
} from "./useDispersionFormState.interfaces";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { updateFrequencyAndFraudForm } from "../../../store/actions/dispersion/dispersion.actions";
import { IDispersionForm } from "../../../store/interfaces/dispersion.interfaces";
import { useWatch } from "react-hook-form";
import { getBooleanValue } from "../../../shared/utils/parseData/parse_data";
import { get } from "lodash";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";

export const useDispersionFormState = ({
  control,
  resetField,
  loading,
}: IUseDispersionFormStateProps): IUseDispersionFormState => {
  const { nodeInfo } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const fieldsWatch = useWatch({
    control,
  });

  const handleOnChangeFraudOptions = (event: { target: { value: string } }) => {
    const isOptionNOSelected = !getBooleanValue(event.target.value);

    if (isOptionNOSelected) {
      resetField(DispersionFields.FRAUD_PERCENTAGE, { defaultValue: "" });
      resetField(DispersionFields.DAYS_RETENTION, { defaultValue: "" });
    } else {
      resetField(DispersionFields.KEEP_FRAUD, { defaultValue: "true" });
    }
  };

  const getFormattedFields = (fields: IDispersionForm): IDispersionForm => ({
    ...fields,
    ...(fields.fraudPercentage && {
      fraudPercentage: +fields.fraudPercentage,
    }),
    ...(fields.retentionPeriod && {
      retentionPeriod: +fields.retentionPeriod,
    }),
    enable4x1000: getBooleanValue(fields.enable4x1000),
    keepCommission: getBooleanValue(fields.keepCommission),
    keepFraud: getBooleanValue(fields.keepFraud),
  });

  useEffect(() => {
    if (!loading.loadingForm) {
      dispatch(
        updateFrequencyAndFraudForm(
          getFormattedFields(fieldsWatch as IDispersionForm)
        )
      );
    }
  }, [fieldsWatch]);

  return {
    constitutionalCountry: get(
      nodeInfo,
      "generalInfo.constitutionalCountry"
    ) as CountriesEnum,
    handleOnChangeFraudOptions,
    keepFraudWatch: getBooleanValue(fieldsWatch.keepFraud as string | boolean),
  };
};
