import { get, has, isEmpty } from "lodash";
import { INodeInfoResponse } from "../../../types/node_info_response";
import { ConfigurationIdEnum } from "../enums";

export const isCentralizedNode = (nodeInfo?: INodeInfoResponse): boolean => {
  if (
    has(nodeInfo, "configs.centralizedNodesId") &&
    isEmpty(get(nodeInfo, "centralizedNodesId"))
  ) {
    return false;
  }

  const cn007 = get(nodeInfo, "configs", []).find(
    (item) => get(item, "configuration", "") === ConfigurationIdEnum.CN007
  );

  if (isEmpty(get(cn007, "centralizedNodesId", {}))) {
    return false;
  }

  return true;
};
