import React from "react";
import { CardTable } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { IElementModalTableComponentProps } from "../../../shared/infrastructure/interfaces/IModalContainer";

export const ElementModalTableComponent: React.FC<
  IElementModalTableComponentProps
> = (props: IElementModalTableComponentProps) => {
  return (
    <React.Fragment>
      {(props.isVisible || props.isLoading) && (
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            marginRight="8px"
            width="100%"
          >
            <Box
              style={{ whiteSpace: "break-spaces" }}
              bgcolor="neutral.grey3"
              borderRadius="4px"
              marginBottom="16px"
              paddingBottom="24px"
            >
              <CardTable
                headers={props.headers}
                rows={props.rows}
                title={props.title}
                isLoading={props.isLoading}
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
