import { makeStyles } from "@material-ui/core";

export const useMerchantInformationStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3.0625)}px`,
  },
  description: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 300,
    maxWidth: "130px",
    minHeight: "21px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  descriptonContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  editButton: {
    marginLeft: theme.spacing(1.25),
    minHeight: "40px",
    minWidth: "40px",
    backgroundColor: "#F0F4F9",
  },
  infoIcon: {
    color: "#293036",
    height: "18px",
    marginBottom: "3.2px",
    marginTop: "1px",
    width: "18px",
  },
  information: {
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  label: {
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: 300,
    whiteSpace: "nowrap",
  },
  listContainer: {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  title: {
    color: "#293036",
    display: "inline-block",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  tooltipContainer: {
    height: "21px",
  },
}));
