import React, { FC } from "react";
import { IColumnConfigurationContent } from "./ColumnConfigurationContent.interfaces";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { columnConfigurationContentStyles } from "./ColumnConfigurationContent.styles";

const ColumnConfigurationContent: FC<IColumnConfigurationContent> = (props) => {
  const {
    columns,
    columnConfiguration,
    handleChangeColumnCheck,
    fixedColumns,
    maxColumns,
  } = props;

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup aria-label="position">
          {columns.map(({ key, value }) => (
            <FormControlLabel
              value={key}
              key={key}
              control={
                <Checkbox
                  sx={columnConfigurationContentStyles.checkButton}
                  onChange={handleChangeColumnCheck}
                  name={key}
                  checked={columnConfiguration.includes(key)}
                />
              }
              label={value}
              labelPlacement="end"
              disabled={
                (columnConfiguration.length >= maxColumns! &&
                  !columnConfiguration.includes(key)) ||
                fixedColumns.includes(key)
              }
              sx={columnConfigurationContentStyles.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
};

export { ColumnConfigurationContent };
