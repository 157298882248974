import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const accordionSkeletonStyles = createNamedStyles({
  boxPrincipal: {
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "10px",
    display: "flex",
    flexWrap: "nowrap",
    height: "100px",
    justifyContent: "flex-start",
    marginBottom: "20px",
    padding: "10px 0px 16px 16px",
  },

  circularSkeleton: { marginRight: "15px" },
  boxLines: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    width: "80%",
  },
});
