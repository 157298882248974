import * as React from "react";
import { TableSimple } from "@kushki/connect-ui";
import { useConditionsBranch } from "./state/useConditionsBranch";

export const ConditionsBranch: React.FC = () => {
  const { rows, cells } = useConditionsBranch();
  return (
    <TableSimple
      header={{
        cells,
        headerProps: {
          isDisable: false,
        },
      }}
      rows={rows}
      showPagination={false}
    />
  );
};
