import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { useEffect, useRef } from "react";
import { getNodeInfoInitialData } from "../../../store/thunks/general/general.thunk";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { get, isEmpty, isEqual } from "lodash";
import { initialState } from "../../../store/reducers/initialData/initialData.slice";
import {
  createSemaphoreBranches,
  listBranchesSelected,
} from "../../../shared/utils/massiveBranchEdit";
import {
  setBranchBatch,
  setListBranchMassiveTitle,
} from "../../../store/actions/massiveBranch.actions";

export const useLayoutStepContainerBranch = () => {
  const dispatch = useAppDispatch();

  const initialData = useAppSelector((state: RootState) => state.initialData);
  const { branchBatch } = useAppSelector((state: RootState) => ({
    ...state.branchBatch,
    ...state.initialData,
  }));

  const isFirstRunDate2 = useRef(true);

  useEffect(() => {
    const branches = createSemaphoreBranches();

    if (isFirstRunDate2.current) {
      isFirstRunDate2.current = false;
      if (isEmpty(branchBatch)) {
        dispatch(setBranchBatch(branches));
        dispatch(setListBranchMassiveTitle(listBranchesSelected()));
      }
    }

    if (isEqual(initialData, initialState)) {
      dispatch(
        getNodeInfoInitialData({
          configIds: `${ConfigIdEnum.CN001}`,
          publicMerchantId: get(branches[0], "publicMerchantId", ""),
        })
      );
      dispatch(
        getNodeInfoInitialData({
          configIds: `${ConfigIdEnum.CN002}`,
          publicMerchantId: get(branches[2], "publicMerchantId", ""),
        })
      );
      dispatch(
        getNodeInfoInitialData({
          configIds: `${ConfigIdEnum.CN016}`,
          publicMerchantId: get(branches[1], "publicMerchantId", ""),
        })
      );
      dispatch(
        getNodeInfoInitialData({
          configIds: `${ConfigIdEnum.CN015}`,
          publicMerchantId: get(branches[3], "publicMerchantId", ""),
        })
      );
    }
  }, []);
};
