export const searchBykey = (
  item: string,
  array: Array<{ value: string; name: string }>
) => {
  const toSearch = array.find((nameKey) => {
    if (nameKey.value === item) {
      return nameKey;
    } else return "";
  });

  return toSearch?.name || "";
};
