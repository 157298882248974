import {
  ItemCategoryItemMassive,
  ItemCategoryMassiveProps,
} from "../../components/Filters/ItemCategory/interfaces";

export const applyClick = (
  list: ItemCategoryMassiveProps[],
  onClick: (selected: ItemCategoryMassiveProps[]) => void,
  toggleDrawer: (newOpen: boolean) => void,
  setList: Function,
  setFilterCount: Function
) => {
  let count: number = 0;

  const filterList: ItemCategoryMassiveProps[] = JSON.parse(
    JSON.stringify(list)
  );

  filterList.map((item) => {
    item.items = item.items.filter((subItem) => subItem.selected);
  });
  onClick(filterList);
  toggleDrawer(false);
  list.map((item: ItemCategoryMassiveProps) => {
    item.items.map((subItem: ItemCategoryItemMassive) => {
      subItem.selected && count++;
    });

    return item;
  });

  setList(list);
  setFilterCount(count);
};
