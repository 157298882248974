import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { IMainContainerState } from "../interfaces/mainContainerState.interfaces";
import { SearchMerchantResponse } from "../../../types/search_merchant_response";

export const searchMerchantNodeInfoAction = (
  state: Draft<IMainContainerState>,
  { payload }: PayloadAction<SearchMerchantResponse>
) => {
  state.searchData = payload;
  state.isLoadingMerchantNodeInfo = false;
};
