import React from "react";
import { Grid, Typography, RadioGroup, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LogoItem } from "../LogoItem/LogoItem";
import { AccountPreference } from "../../containers/AccountPreferencesIndex/state/useAccountPreferencesState";
import { SmartlinksLogos } from "../../../types/smartlink_customization";

const useStyles = makeStyles({
  sectionTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#6D7781",
  },
});

export interface LogoSelectProps {
  accountPreference: AccountPreference;
  removeHandler: (item: SmartlinksLogos) => void;
  setValueHandler: (value: string, kind: string) => void;
  editLogoNameHandler: (id: string, value: string) => void;
}

export const LogoSelect: React.FC<LogoSelectProps> = (
  props: LogoSelectProps
) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Typography className={styles.sectionTitle}>
          Logotipos del comercio
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={1} xs={1}></Grid>
        <Grid item sm={7} xs={7}>
          <Typography className={styles.sectionTitle}>Alias</Typography>
        </Grid>
        <Grid item sm={3} xs={3}>
          <Typography className={styles.sectionTitle}>Tipo</Typography>
        </Grid>
      </Grid>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup
          name="logoRadioGroup"
          value={props.accountPreference.selectedLogo?.alias || ""}
          onChange={(e) => {
            props.setValueHandler(e.target.value, "selectedLogo");
          }}
        >
          {props.accountPreference.logos.map(
            (logo, index) =>
              !logo.delete && (
                <LogoItem
                  key={index}
                  logo={logo}
                  remove={props.removeHandler}
                  editName={(value) =>
                    props.editLogoNameHandler(logo.alias!, value)
                  }
                />
              )
          )}
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};
