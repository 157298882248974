/* eslint-disable sort-keys */
//TODO: Remove disable sort-keys after review
import { ICellRow, ICellText } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  HeaderCellProps,
  ITableCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { colorSelector } from "./BusinessRulesConstants";
import {
  AccountPropertiesLabelEnum,
  AccountPropertiesValueEnum,
} from "../../enums/AccountPropertiesEnum";

export enum CELL_TYPES {
  TEXT = "text",
  FLAG = "flag",
  TITLE = "title",
}

export type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

export const ALL_TABLE_COLUMNS_MX: CustomHeaderCellProps[] = [
  {
    align: "center",
    cellType: CELL_TYPES.FLAG,
    key: AccountPropertiesValueEnum.BANK_OF_ORIGIN,
    spacing: 0,
    text: AccountPropertiesLabelEnum.BANK_OF_ORIGIN,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TITLE,
    key: AccountPropertiesValueEnum.NAME_BENEFICIARY,
    spacing: 0,
    text: AccountPropertiesLabelEnum.NAME_BENEFICIARY,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.BANK_ID,
    spacing: 0,
    text: AccountPropertiesLabelEnum.BANK_ID,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ACCOUNT_TYPE,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ACCOUNT_TYPE,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ACCOUNT_NUMBER,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ACCOUNT_NUMBER,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.INTERBANK_ACCOUNT_CODE,
    spacing: 0,
    text: AccountPropertiesLabelEnum.INTERBANK_ACCOUNT_CODE,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.CURRENCY,
    spacing: 0,
    text: AccountPropertiesLabelEnum.CURRENCY,
  },
];
export const ALL_TABLE_COLUMNS: CustomHeaderCellProps[] = [
  {
    align: "center",
    cellType: CELL_TYPES.FLAG,
    key: AccountPropertiesValueEnum.BANK_OF_ORIGIN,
    spacing: 0,
    text: AccountPropertiesLabelEnum.BANK_OF_ORIGIN,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TITLE,
    key: AccountPropertiesValueEnum.NAME_BENEFICIARY,
    spacing: 0,
    text: AccountPropertiesLabelEnum.NAME_BENEFICIARY,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.BANK_ID,
    spacing: 0,
    text: AccountPropertiesLabelEnum.BANK_ID,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ACCOUNT_TYPE,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ACCOUNT_TYPE,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ACCOUNT_NUMBER,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ACCOUNT_NUMBER,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.CURRENCY,
    spacing: 0,
    text: AccountPropertiesLabelEnum.CURRENCY,
  },
];

export const ALL_TABLE_COLUMNS_EEUU: CustomHeaderCellProps[] = [
  {
    align: "center",
    cellType: CELL_TYPES.FLAG,
    key: AccountPropertiesValueEnum.BANK_OF_ORIGIN,
    spacing: 0,
    text: AccountPropertiesLabelEnum.BANK_OF_ORIGIN,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TITLE,
    key: AccountPropertiesValueEnum.NAME_BENEFICIARY,
    spacing: 0,
    text: AccountPropertiesLabelEnum.NAME_BENEFICIARY,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.BANK_ID,
    spacing: 0,
    text: AccountPropertiesLabelEnum.BANK_ID,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ROUTING_NUMBER,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ROUTING_NUMBER,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ACCOUNT_TYPE,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ACCOUNT_TYPE,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.ACCOUNT_NUMBER,
    spacing: 0,
    text: AccountPropertiesLabelEnum.ACCOUNT_NUMBER,
  },
  {
    align: "center",
    cellType: CELL_TYPES.TEXT,
    key: AccountPropertiesValueEnum.CURRENCY,
    spacing: 0,
    text: AccountPropertiesLabelEnum.CURRENCY,
  },
];

export const FLAG_CELL = (country: string) => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 1,
    },
    country,
    type: "twolines",
  },
  type: "FLAG",
});

export const TITLE_CELL = (line1: string) => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 1,
    },
    line1,
    type: "oneLine",
  },
  type: "TITLE",
});

export const TEXT_CELL = (line1: string = "", line2?: string) =>
  ({
    props: {
      cellProps: {
        align: "center",
        spacing: 1,
      },
      line1,
      line2,
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

export const TAG_CELL = (status: string) =>
  ({
    props: {
      cellProps: {
        align: "center",
        spacing: 1,
      } as ICellRow,
      color: colorSelector(status).color,
      text: colorSelector(status).label,
    },
    type: "TAG" as TableBodyCellEnum,
  } as ITableCellProps);

export const TABLE_ROW = {
  [CELL_TYPES.FLAG]: FLAG_CELL,
  [CELL_TYPES.TITLE]: TITLE_CELL,
  [CELL_TYPES.TEXT]: TEXT_CELL,
};

export const INFO_RESPONSE = {
  clientType: "B2B",
  country: "Mexico",
  created: 1649369905835,
  dispersion: {
    accountInfo: [
      {
        accountNumber: "123456789987654321",
        accountOrder: "principal",
        accountType: "0",
        bankId: "02001",
        bankOfOrigin: "Mexico",
        currency: "MXN",
        interbankAccountCode: "123423123123123123",
        nameBeneficiary: "Juan Perez",
      },
      {
        accountNumber: "123456789987654321",
        accountOrder: "alternate",
        accountType: "0",
        bankId: "02001",
        bankOfOrigin: "Mexico",
        currency: "MXN",
        interbankAccountCode: "123423123123123123",
        nameBeneficiary: "Juan Perez",
      },
    ],
    keepCommission: true,
  },
  dispersionFrequency: "Mensual",
  name: "Jhonatan Rojas Torrejonn",
  constitutionalCountry: "Mexico",
  publicMerchantId: "20000000103353696000",
  status: "pending",
  updatedAt: 1650334517318,
  userAlias: "backoffice",
  userName: "backoffice Admin",
};

export const CONFIG_RATES_RESPONSE = {
  country: "Mexico",
  created: 1650633877889,
  fraudPercentage: 20,
  isB2C: true,
  keepFraud: true,
  merchantId: "20000000102323850000",
  retentionPeriod: 10,
};
