import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  save: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    border: `solid 1px ${theme.palette.primary.main}`,
    color: theme.palette.background.paper,
    padding: theme.spacing(1, 3),
    marginLeft: theme.spacing(3),
  },
  bold: {
    fontWeight: "bold",
  },
  close: {
    cursor: "pointer",
    marginLeft: theme.spacing(6),
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));
