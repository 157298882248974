import React, { FC } from "react";
import HeadFiltersDashboardComponent from "../HeadFiltersDashboardComponent/HeadFiltersDashboardComponent";
import { useGlobalTableComponent } from "./state/useGlobalTableComponent";
import { IGlobalTableComponent } from "./GlobalTableComponent.interfaces";
import TableComponent from "../TableComponent/TableComponent";

const GlobalTableComponent: FC<IGlobalTableComponent> = ({
  country,
  merchantId,
}: IGlobalTableComponent) => {
  const { transactions, limit, page, actions } =
    useGlobalTableComponent(merchantId);

  return (
    <React.Fragment>
      <HeadFiltersDashboardComponent setSelectedPage={actions.setPage} />
      <TableComponent
        transactions={transactions}
        country={country}
        itemsPerPage={limit}
        page={page}
        merchantId={merchantId}
        action={actions}
      />
    </React.Fragment>
  );
};

export default GlobalTableComponent;
