import React from "react";
import { ModalSimple } from "@kushki/connect-ui";
import { DeleteModalInfo } from "../../../../BusinessRules/BusinessRules.interfaces";

export interface DeleteDeferredProps {
  deleteModalInfo: DeleteModalInfo;
}

export const DeleteModal: React.FC<DeleteDeferredProps> = ({
  deleteModalInfo,
}: DeleteDeferredProps) => {
  return (
    <ModalSimple
      buttonPrimary={deleteModalInfo.deleteModal.buttonPrimaryText}
      buttonPrimaryDisabled={false}
      isOpen={deleteModalInfo.deleteModal.isOpen}
      buttonSecondary={deleteModalInfo.deleteModal.buttonSecondaryText}
      descriptionText={deleteModalInfo.deleteModal.descriptionText}
      onClickSecondary={() => {
        deleteModalInfo.closeDeleteRuleModal();
      }}
      onClose={() => {
        deleteModalInfo.closeDeleteRuleModal();
      }}
      titleText={deleteModalInfo.deleteModal.titleText}
      typeModal={1}
      onClickPrimary={() => {
        deleteModalInfo.deleteDeferredByID(deleteModalInfo.deferredID);
      }}
    />
  );
};
