import React from "react";

const EmptyTableIcon = () => (
  <svg
    width="99"
    height="95"
    viewBox="0 0 99 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.10449 47.5003C4.10449 45.3142 5.94234 43.542 8.20944 43.542H32.8391C34.2116 43.542 35.4933 44.2034 36.2546 45.3046L43.2459 55.417H55.2719L62.2631 45.3046C63.0245 44.2034 64.3061 43.542 65.6786 43.542H90.3083C92.5754 43.542 94.4133 45.3142 94.4133 47.5003C94.4133 49.6865 92.5754 51.4587 90.3083 51.4587H67.8755L60.8843 61.571C60.123 62.6722 58.8413 63.3337 57.4688 63.3337H41.049C39.6765 63.3337 38.3948 62.6722 37.6335 61.571L30.6422 51.4587H8.20944C5.94234 51.4587 4.10449 49.6865 4.10449 47.5003Z"
      fill="#C4C4C4"
    />
    <path
      d="M29.7193 11.875L68.7984 11.875C71.0895 11.8762 73.337 12.4936 75.2841 13.6579C77.2295 14.8212 78.8001 16.4842 79.8197 18.4604C79.8206 18.4621 79.8215 18.4639 79.8224 18.4656L93.9788 45.7277C94.2645 46.2779 94.4133 46.8847 94.4133 47.5V71.25C94.4133 74.3994 93.1158 77.4199 90.8063 79.6469C88.4968 81.8739 85.3645 83.125 82.0984 83.125H16.4193C13.1532 83.125 10.0209 81.8739 7.71142 79.6469C5.40194 77.4199 4.10449 74.3994 4.10449 71.25V47.5C4.10449 46.8847 4.25324 46.2779 4.53893 45.7277L18.6954 18.4656C18.6964 18.4637 18.6974 18.4618 18.6984 18.4598C19.718 16.4839 21.2885 14.8211 23.2337 13.6579C25.1808 12.4936 27.4283 11.8762 29.7193 11.875ZM22.3715 20.2271L26.042 21.9994L12.3144 48.4356V71.25C12.3144 72.2998 12.7469 73.3066 13.5167 74.049C14.2865 74.7913 15.3306 75.2083 16.4193 75.2083H82.0984C83.1871 75.2083 84.2312 74.7913 85.0011 74.049C85.7709 73.3066 86.2034 72.2998 86.2034 71.25V48.4356L72.4757 21.9994L72.4701 21.9885C72.1303 21.3291 71.6064 20.7741 70.9574 20.386C70.3086 19.998 69.5604 19.7922 68.797 19.7917H29.7207C28.9573 19.7922 28.2092 19.998 27.5604 20.386C26.9113 20.7741 26.3874 21.3291 26.0476 21.9885L22.3715 20.2271Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default EmptyTableIcon;
