/* istanbul ignore file */
import React, { Suspense } from "react";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IAppState } from "./store/reducer";
import { IAppAction, setNotification } from "./store/actionCreators";
import { SnackBarAlert } from "./components/common/SnackBar/SnackBarAlert";
import { INotification } from "./shared/infrastructure/interfaces/INotification";

// @ts-ignore
import Normalize from "react-normalize";
import { routes } from "./shared/infrastructure/routes";
import { ConciliationDashboard } from "./containers/ConciliationDashboard/ConciliationDashboard";
import { DepositDashboard } from "./containers/DepositDashboard/DepositDashboard";
interface INavigationFunctionsProps {
  setNotification(payload: INotification): void;
}

interface INavigationStateProps {
  notification: INotification;
}

type TNavigationProps = INavigationFunctionsProps & INavigationStateProps;
const Navigation: React.FC<TNavigationProps> = (props: TNavigationProps) => {
  const handleCloseSnackbar = () => {
    props.setNotification({
      open: false,
      vertical: props.notification.vertical,
      horizontal: props.notification.horizontal,
      icon: props.notification.icon,
      message: props.notification.message,
      type: props.notification.type,
    });
  };

  return (
    <>
      <Normalize />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              path={`${routes.BASE_PATH_BANK_CONCILIATION}${routes.DEPOSITS_DASHBOARD}`}
              exact
              component={DepositDashboard}
            />
            <Route
              path={`${routes.BASE_PATH_BANK_CONCILIATION}${routes.CONCILIATION_DASHBOARD}`}
              exact
              component={ConciliationDashboard}
            />
          </Switch>
        </Suspense>
        <SnackBarAlert
          type={props.notification.type}
          message={props.notification.message}
          vertical={props.notification.vertical!}
          icon={props.notification.icon}
          horizontal={props.notification.horizontal!}
          open={props.notification.open}
          handlerClose={handleCloseSnackbar}
          hasOnclose={true}
          autoHideDuration={6000}
          transitionDuration={500}
        />
      </BrowserRouter>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  setNotification: (payload: INotification) => {
    dispatch(setNotification(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
