export enum StatusEnum {
  PENDING = "pending",
  COMPLETE = "complete",
  OMITTED = "omitted",
}

export const CatalogTranslateStatus: Record<StatusEnum, string> = {
  [StatusEnum.COMPLETE]: "COMPLETADO",
  [StatusEnum.PENDING]: "PENDIENTE",
  [StatusEnum.OMITTED]: "OMITIDO",
};
