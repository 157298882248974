import { BodyTabField } from "../../../components/DetailsCard/ModalBodyTab/ModalBodyTab.interfaces";
import { get } from "lodash";
import { TransactionData } from "../../../../types/transaction_data";
import { translate } from "../LanguageCatatog";

export const sharedContentLineInfo = (trx: TransactionData): BodyTabField[] => [
  {
    label: "Cliente",
    value: get(trx, "cardHolderName", ""),
  },
  {
    label: "Medio de pago",
    value: translate(get(trx, "paymentMethod", "")),
  },
  {
    label: "Banco Emisor",
    value: get(trx, "issuingBank", ""),
  },
  {
    label: "Marca",
    value: get(trx, "cardBrand", ""),
  },
];

export const sharedContentLineTrxInfo = (
  trx: TransactionData
): BodyTabField[] => [
  {
    label: "Tipo de transacción",
    value: translate(get(trx, "transactionType", "")),
  },
  {
    label: "Monto de transacción",
    value: get(trx, "requestAmount", ""),
  },
];

export const contentLineApproval = (trx: TransactionData): BodyTabField[] => [
  ...sharedContentLineInfo(trx),
  {
    label: "No. de aprobación",
    value: get(trx, "approvalCode", ""),
  },
];

export const contentLineCard = (trx: TransactionData): BodyTabField[] => [
  ...sharedContentLineInfo(trx),
  {
    label: "Número de tarjeta",
    value: get(trx, "maskedCreditCard", ""),
  },
];
