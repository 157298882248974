import { createAsyncThunk } from "@reduxjs/toolkit";
import { NodeInfoResponse } from "../../../../types/node_info_response";
import { NodeInfoRequest } from "../../../../types/node_info_request";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/api_routes";
import { GetSemaphoreResponse } from "../../../../types/get_semaphore_response";
import { GetMerchantPreferencesResponse } from "../../../../types/get_merchant_preferences_response";

const getNodeInfo = createAsyncThunk<NodeInfoResponse, NodeInfoRequest>(
  "app/getNodeInfo",
  async (payload) => {
    const response = await axios.post<NodeInfoResponse>(
      API_ROUTES.GET_NODE_INFO,
      payload
    );

    return response.data;
  }
);

const getSemaphoreData = createAsyncThunk<
  GetSemaphoreResponse,
  { publicMerchantId: string }
>("app/getSemaphoreData", async ({ publicMerchantId }) => {
  const response = await axios.get<GetSemaphoreResponse>(
    API_ROUTES.GET_SEMAPHORE(publicMerchantId)
  );

  return response.data;
});

const getMerchantPreferences = createAsyncThunk<
  GetMerchantPreferencesResponse,
  { publicMerchantId: string }
>("app/getMerchantPreferences", async ({ publicMerchantId }) => {
  const response = await axios.get<GetMerchantPreferencesResponse>(
    API_ROUTES.GET_MERCHANT_PREFERENCES(publicMerchantId)
  );

  return response.data;
});

export { getNodeInfo, getSemaphoreData, getMerchantPreferences };
