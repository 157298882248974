import React, { FC } from "react";
import { Box, Button, Grow } from "@mui/material";
import { FileUploadExecutivesStyles as styles } from "./FileUploadExecutives.styles";
import { useFileUploadExecutives } from "./state/useFileUploadExecutives";
import FileUpload from "../../molecule/FileUpload/FileUpload";
import LoadingFiles from "../../molecule/LoadingFile/LoadingFile";
import FileName from "../../molecule/FileName/FileName";
import { Alert, IconMarketing } from "@kushki/connect-ui";
import {
  FileExecutiveStatusEnum,
  FileUploadExecutiveLabel,
  MessageErrorExecutive,
} from "@shared/enum/FileUploadExecutivesEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";

const FileUploadExecutives: React.FC = () => {
  const {
    handlers: {
      handleChange,
      handleDrag,
      handleDrop,
      handleCloseFile,
      handleValidateFile,
    },
    state: {
      fileSelected,
      dragActive,
      errorUploadingFile,
      fileStatus,
      showValidateBtn,
    },
  } = useFileUploadExecutives();

  return (
    <Box sx={styles.wrapper}>
      {errorUploadingFile.errorFile && (
        <Grow in={errorUploadingFile.errorFile as boolean}>
          <Box sx={styles.alertWrapper}>
            <Alert
              description={errorUploadingFile.message}
              title={MessageErrorExecutive.ERROR_TITLE}
              type="error"
              icon={(<IconMarketing />) as unknown as FC}
            />
          </Box>
        </Grow>
      )}
      <Box component="div" sx={styles.container}>
        {fileStatus === FileExecutiveStatusEnum.DEFAULT && (
          <FileUpload
            dragActive={dragActive}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            handleChange={handleChange}
          />
        )}
        {fileStatus === FileExecutiveStatusEnum.UPLOADING && (
          <LoadingFiles infoText={FileUploadExecutiveLabel.LOADING_FILE} />
        )}
        {fileStatus === FileExecutiveStatusEnum.WITH_FILE && (
          <FileName
            name={fileSelected.name}
            size={fileSelected.size}
            handleCloseFile={handleCloseFile}
          />
        )}
        {fileStatus === FileExecutiveStatusEnum.VALIDATING && (
          <LoadingFiles infoText={FileUploadExecutiveLabel.VALIDATING_FILE} />
        )}
      </Box>
      <Box>
        {showValidateBtn && (
          <ComponentSecurityWrapper
            componentId={
              SecurityWrapperRoles.M_COMPLIANCE_EXECUTIVES_VALIDATE_FILE
            }
          >
            <Button
              variant="contained"
              sx={styles.validateButton}
              onClick={handleValidateFile}
            >
              {FileUploadExecutiveLabel.VALIDATE_BUTTON}
            </Button>
          </ComponentSecurityWrapper>
        )}
      </Box>
    </Box>
  );
};

export default FileUploadExecutives;
