import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface ICard {
  bgColor?: string;
  source: string;
  width: number;
  height: number;
}

export interface ICardsComponentProps {
  cards: ICard[];
  height: number;
  justifyContent?: string;
  marginLeft?: number;
  marginRight?: number;
  width: number;
}

const useStyles = makeStyles(() => ({
  boxCountry: {
    alignItems: "center",
    border: "1.4px solid #CBD5E0",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
  },
}));

const CardsComponent: React.FC<ICardsComponentProps> = ({
  cards,
  height,
  justifyContent,
  marginLeft,
  marginRight,
  width,
}: ICardsComponentProps) => {
  const classes = useStyles();

  return (
    <Box key={"box-country"} display={"flex"} justifyContent={justifyContent}>
      {cards.map((item, index) => (
        <Box
          key={"contry-" + index}
          className={classes.boxCountry}
          bgcolor={item.bgColor}
          height={height}
          width={width}
          mr={marginRight}
          ml={marginLeft}
        >
          <img
            src={item.source}
            alt={item.source}
            width={item.width}
            height={item.height}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CardsComponent;
