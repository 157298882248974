export enum FILTER_GROUPS {
  STATE = "Estado",
  BRANCHES_FILTER = "Branch",
}
export enum STATE_LABEL_ITEM {
  ACTIVE = "Activa",
  INACTIVE = "Inactiva",
}

export const STATE_ITEM_VALUES: Record<STATE_LABEL_ITEM, string> = {
  [STATE_LABEL_ITEM.ACTIVE]: "true",
  [STATE_LABEL_ITEM.INACTIVE]: "false",
};
