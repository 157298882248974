import * as React from "react";
import { FC } from "react";
import { Chip } from "@mui/material";
import { IChip } from "./Chip.interface";
import { useStyles } from "./Chip.styles";

const ChipTag: FC<IChip> = ({ text, type }: IChip) => {
  const styles = useStyles();

  return (
    <Chip label={text} size="small" className={styles.chip} color={type} />
  );
};

export { ChipTag };
