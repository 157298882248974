import { ICountry } from "./Countries";

export const ALL_COUNTRIES: ICountry[] = [
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
    name: "Brasil",
    value: "Brazil",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
    name: "Chile",
    value: "Chile",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
    name: "Colombia",
    value: "Colombia",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
    name: "Costa Rica",
    value: "CostaRica",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
    name: "Ecuador",
    value: "Ecuador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag%402x.png",
    name: "Guatemala",
    value: "Guatemala",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag%402x.png",
    name: "Honduras",
    value: "Honduras",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
    name: "México",
    value: "Mexico",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag%402x.png",
    name: "Nicaragua",
    value: "Nicaragua",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
    name: "Panamá",
    value: "Panama",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
    name: "Perú",
    value: "Peru",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
    name: "Estados Unidos de América",
    value: "EEUU",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
    name: "El Salvador",
    value: "ElSalvador",
  },
  { flag: "", name: "Andorra", value: "Andorra" },
  { flag: "", name: "Emiratos Árabes Unidos", value: "EmiratosArabesUnidos" },
  { flag: "", name: "Afganistán", value: "Afganistan" },
  { flag: "", name: "Antigua y Barbuda", value: "AntiguayBarbuda" },
  { flag: "", name: "Anguila", value: "Anguila" },
  { flag: "", name: "Albania", value: "Albania" },
  { flag: "", name: "Armenia", value: "Armenia" },
  { flag: "", name: "Antillas Neerlandesas", value: "AntillasNeerlandesas" },
  { flag: "", name: "Angola", value: "Angola" },
  { flag: "", name: "Antártida", value: "Antartida" },
  { flag: "", name: "Argentina", value: "Argentina" },
  { flag: "", name: "Samoa Americana", value: "SamoaAmericana" },
  { flag: "", name: "Austria", value: "Austria" },
  { flag: "", name: "Australia", value: "Australia" },
  { flag: "", name: "Aruba", value: "Aruba" },
  { flag: "", name: "Islas Áland", value: "IslasAland" },
  { flag: "", name: "Azerbaiyán", value: "Azerbaiyan" },
  { flag: "", name: "Bosnia y Herzegovina", value: "BosniayHerzegovina" },
  { flag: "", name: "Barbados", value: "Barbados" },
  { flag: "", name: "Bangladesh", value: "Bangladesh" },
  { flag: "", name: "Bélgica", value: "Belgica" },
  { flag: "", name: "Burkina Faso", value: "BurkinaFaso" },
  { flag: "", name: "Bulgaria", value: "Bulgaria" },
  { flag: "", name: "Bahréin", value: "Bahrein" },
  { flag: "", name: "Burundi", value: "Burundi" },
  { flag: "", name: "Benin", value: "Benin" },
  { flag: "", name: "San Bartolomé", value: "SanBartolome" },
  { flag: "", name: "Bermudas", value: "Bermudas" },
  { flag: "", name: "Brunéi", value: "Brunei" },
  { flag: "", name: "Bolivia", value: "Bolivia" },
  { flag: "", name: "Bahamas", value: "Bahamas" },
  { flag: "", name: "Bhután", value: "Bhutan" },
  { flag: "", name: "Isla Bouvet", value: "IslaBouvet" },
  { flag: "", name: "Botsuana", value: "Botsuana" },
  { flag: "", name: "Belarús", value: "Belarus" },
  { flag: "", name: "Belice", value: "Belice" },
  { flag: "", name: "Canadá", value: "Canada" },
  { flag: "", name: "Islas Cocos", value: "IslasCocos" },
  {
    flag: "",
    name: "República Centro-Africana",
    value: "RepublicaCentroafricana",
  },
  { flag: "", name: "Congo", value: "Congo" },
  { flag: "", name: "Suiza", value: "Suiza" },
  { flag: "", name: "Costa de Marfil", value: "CostadeMarfil" },
  { flag: "", name: "Islas Cook", value: "IslasCook" },

  { flag: "", name: "Camerún", value: "Camerun" },
  { flag: "", name: "China", value: "China" },

  { flag: "", name: "Cuba", value: "Cuba" },
  { flag: "", name: "Cabo Verde", value: "CaboVerde" },
  { flag: "", name: "Islas Christmas", value: "IslasChristmas" },
  { flag: "", name: "Chipre", value: "Chipre" },
  { flag: "", name: "República Checa", value: "RepublicaCheca" },
  { flag: "", name: "Alemania", value: "Alemania" },
  { flag: "", name: "Yibuti", value: "Yibuti" },
  { flag: "", name: "Dinamarca", value: "Dinamarca" },
  { flag: "", name: "Domínica", value: "Dominica" },
  { flag: "", name: "República Dominicana", value: "RepublicaDominicana" },
  { flag: "", name: "Argel", value: "Argel" },
  { flag: "", name: "Estonia", value: "Estonia" },
  { flag: "", name: "Egipto", value: "Egipto" },
  { flag: "", name: "Sahara Occidental", value: "SaharaOccidental" },
  { flag: "", name: "Eritrea", value: "Eritrea" },
  { flag: "", name: "España", value: "Espania" },
  { flag: "", name: "Etiopía", value: "Etiopia" },
  { flag: "", name: "Finlandia", value: "Finlandia" },
  { flag: "", name: "Fiji", value: "Fiji" },
  { flag: "", name: "Islas Malvinas", value: "IslasMalvinas" },
  { flag: "", name: "Micronesia", value: "Micronesia" },
  { flag: "", name: "Islas Faroe", value: "IslasFaroe" },
  { flag: "", name: "Francia", value: "Francia" },
  { flag: "", name: "Gabón", value: "Gabon" },
  { flag: "", name: "Reino Unido", value: "ReinoUnido" },
  { flag: "", name: "Granada", value: "Granada" },
  { flag: "", name: "Georgia", value: "Georgia" },
  { flag: "", name: "Guayana Francesa", value: "GuayanaFrancesa" },
  { flag: "", name: "Guernsey", value: "Guernsey" },
  { flag: "", name: "Ghana", value: "Ghana" },
  { flag: "", name: "Gibraltar", value: "Gibraltar" },
  { flag: "", name: "Groenlandia", value: "Groenlandia" },
  { flag: "", name: "Gambia", value: "Gambia" },
  { flag: "", name: "Guinea", value: "Guinea" },
  { flag: "", name: "Guadalupe", value: "Guadalupe" },
  { flag: "", name: "Guinea Ecuatorial", value: "GuineaEcuatorial" },
  { flag: "", name: "Grecia", value: "Grecia" },
  {
    flag: "",
    name: "Georgia del Sur e Islas Sandwich del Sur",
    value: "GeorgiadelSureIslasSandwichdelSur",
  },

  { flag: "", name: "Guam", value: "Guam" },
  { flag: "", name: "Guinea-Bissau", value: "GuineaBissau" },
  { flag: "", name: "Guayana", value: "Guayana" },
  { flag: "", name: "Hong Kong", value: "HongKong" },
  { flag: "", name: "Islas Heard y McDonald", value: "IslasHeardyMcDonald" },

  { flag: "", name: "Croacia", value: "Croacia" },
  { flag: "", name: "Haití", value: "Haiti" },
  { flag: "", name: "Hungría", value: "Hungria" },
  { flag: "", name: "Indonesia", value: "Indonesia" },
  { flag: "", name: "Irlanda", value: "Irlanda" },
  { flag: "", name: "Israel", value: "Israel" },
  { flag: "", name: "Isla de Man", value: "IsladeMan" },
  { flag: "", name: "India", value: "India" },
  {
    flag: "",
    name: "Territorio Británico del Océano Índico",
    value: "TerritorioBritanicodelOceanoIndico",
  },
  { flag: "", name: "Irak", value: "Irak" },
  { flag: "", name: "Irán", value: "Iran" },
  { flag: "", name: "Islandia", value: "Islandia" },
  { flag: "", name: "Italia", value: "Italia" },
  { flag: "", name: "Jersey", value: "Jersey" },
  { flag: "", name: "Jamaica", value: "Jamaica" },
  { flag: "", name: "Jordania", value: "Jordania" },
  { flag: "", name: "Japón", value: "Japon" },
  { flag: "", name: "Kenia", value: "Kenia" },
  { flag: "", name: "Kirguistán", value: "Kirguistan" },
  { flag: "", name: "Camboya", value: "Camboya" },
  { flag: "", name: "Kiribati", value: "Kiribati" },
  { flag: "", name: "Comoros", value: "Comoros" },
  { flag: "", name: "San Cristóbal y Nieves", value: "SanCristobalyNieves" },
  { flag: "", name: "Corea del Norte", value: "CoreadelNorte" },
  { flag: "", name: "Corea del Sur", value: "CoreadelSur" },
  { flag: "", name: "Kuwait", value: "Kuwait" },
  { flag: "", name: "Islas Caimán", value: "IslasCaiman" },
  { flag: "", name: "Kazajstán", value: "Kazajstan" },
  { flag: "", name: "Laos", value: "Laos" },
  { flag: "", name: "Líbano", value: "Libano" },
  { flag: "", name: "Santa Lucía", value: "SantaLucia" },
  { flag: "", name: "Liechtenstein", value: "Liechtenstein" },
  { flag: "", name: "Sri Lanka", value: "SriLanka" },
  { flag: "", name: "Liberia", value: "Liberia" },
  { flag: "", name: "Lesotho", value: "Lesotho" },
  { flag: "", name: "Lituania", value: "Lituania" },
  { flag: "", name: "Luxemburgo", value: "Luxemburgo" },
  { flag: "", name: "Letonia", value: "Letonia" },
  { flag: "", name: "Libia", value: "Libia" },
  { flag: "", name: "Marruecos", value: "Marruecos" },
  { flag: "", name: "Mónaco", value: "Monaco" },
  { flag: "", name: "Moldova", value: "Moldova" },
  { flag: "", name: "Montenegro", value: "Montenegro" },
  { flag: "", name: "Madagascar", value: "Madagascar" },
  { flag: "", name: "Islas Marshall", value: "IslasMarshall" },
  { flag: "", name: "Macedonia", value: "Macedonia" },
  { flag: "", name: "Mali", value: "Mali" },
  { flag: "", name: "Myanmar", value: "Myanmar" },
  { flag: "", name: "Mongolia", value: "Mongolia" },
  { flag: "", name: "Macao", value: "Macao" },
  { flag: "", name: "Martinica", value: "Martinica" },
  { flag: "", name: "Mauritania", value: "Mauritania" },
  { flag: "", name: "Montserrat", value: "Montserrat" },
  { flag: "", name: "Malta", value: "Malta" },
  { flag: "", name: "Mauricio", value: "Mauricio" },
  { flag: "", name: "Maldivas", value: "Maldivas" },
  { flag: "", name: "Malawi", value: "Malawi" },

  { flag: "", name: "Malasia", value: "Malasia" },
  { flag: "", name: "Mozambique", value: "Mozambique" },
  { flag: "", name: "Namibia", value: "Namibia" },
  { flag: "", name: "Nueva Caledonia", value: "NuevaCaledonia" },
  { flag: "", name: "Níger", value: "Niger" },
  { flag: "", name: "Islas Norkfolk", value: "IslasNorkfolk" },
  { flag: "", name: "Nigeria", value: "Nigeria" },

  { flag: "", name: "Países Bajos", value: "PaisesBajos" },
  { flag: "", name: "Noruega", value: "Noruega" },
  { flag: "", name: "Nepal", value: "Nepal" },
  { flag: "", name: "Nauru", value: "Nauru" },
  { flag: "", name: "Niue", value: "Niue" },
  { flag: "", name: "Nueva Zelanda", value: "NuevaZelanda" },
  { flag: "", name: "Omán", value: "Oman" },

  { flag: "", name: "Polinesia Francesa", value: "PolinesiaFrancesa" },
  { flag: "", name: "Papúa Nueva Guinea", value: "PapuaNuevaGuinea" },
  { flag: "", name: "Filipinas", value: "Filipinas" },
  { flag: "", name: "Pakistán", value: "Pakistan" },
  { flag: "", name: "Polonia", value: "Polonia" },
  { flag: "", name: "San Pedro y Miquelón", value: "SanPedroyMiquelon" },
  { flag: "", name: "Islas Pitcairn", value: "IslasPitcairn" },
  { flag: "", name: "Puerto Rico", value: "PuertoRico" },
  { flag: "", name: "Palestina", value: "Palestina" },
  { flag: "", name: "Portugal", value: "Portugal" },
  { flag: "", name: "Islas Palaos", value: "IslasPalaos" },
  { flag: "", name: "Paraguay", value: "Paraguay" },
  { flag: "", name: "Qatar", value: "Qatar" },
  { flag: "", name: "Reunión", value: "Reunion" },
  { flag: "", name: "Rumanía", value: "Rumania" },
  { flag: "", name: "Serbia y Montenegro", value: "SerbiayMontenegro" },
  { flag: "", name: "Rusia", value: "Rusia" },
  { flag: "", name: "Ruanda", value: "Ruanda" },
  { flag: "", name: "Arabia Saudita", value: "ArabiaSaudita" },
  { flag: "", name: "Islas Solomón", value: "IslasSolomon" },
  { flag: "", name: "Seychelles", value: "Seychelles" },
  { flag: "", name: "Sudán", value: "Sudan" },
  { flag: "", name: "Suecia", value: "Suecia" },
  { flag: "", name: "Singapur", value: "Singapur" },
  { flag: "", name: "Santa Elena", value: "SantaElena" },
  { flag: "", name: "Eslovenia", value: "Eslovenia" },
  {
    flag: "",
    name: "Islas Svalbard y Jan Mayen",
    value: "IslasSvalbardyJanMayen",
  },
  { flag: "", name: "Eslovaquia", value: "Eslovaquia" },
  { flag: "", name: "Sierra Leona", value: "SierraLeona" },
  { flag: "", name: "San Marino", value: "SanMarino" },
  { flag: "", name: "Senegal", value: "Senegal" },
  { flag: "", name: "Somalia", value: "Somalia" },
  { flag: "", name: "Surinam", value: "Surinam" },
  { flag: "", name: "Santo Tomé y Príncipe", value: "SantoTomeyPrincipe" },

  { flag: "", name: "Siria", value: "Siria" },
  { flag: "", name: "Suazilandia", value: "Suazilandia" },
  { flag: "", name: "Islas Turcas y Caicos", value: "IslasTurcasyCaicos" },
  { flag: "", name: "Chad", value: "Chad" },
  {
    flag: "",
    name: "Territorios Australes Franceses",
    value: "TerritoriosAustralesFranceses",
  },
  { flag: "", name: "Togo", value: "Togo" },
  { flag: "", name: "Tailandia", value: "Tailandia" },
  { flag: "", name: "Tanzania", value: "Tanzania" },
  { flag: "", name: "Tayikistán", value: "Tayikistan" },
  { flag: "", name: "Tokelau", value: "Tokelau" },
  { flag: "", name: "Timor-Leste", value: "TimorLeste" },
  { flag: "", name: "Turkmenistán", value: "Turkmenistan" },
  { flag: "", name: "Túnez", value: "Tunez" },
  { flag: "", name: "Tonga", value: "Tonga" },
  { flag: "", name: "Turquía", value: "Turquia" },
  { flag: "", name: "Trinidad y Tobago", value: "TrinidadyTobago" },
  { flag: "", name: "Tuvalu", value: "Tuvalu" },
  { flag: "", name: "Taiwán", value: "Taiwan" },
  { flag: "", name: "Ucrania", value: "Ucrania" },
  { flag: "", name: "Uganda", value: "Uganda" },

  { flag: "", name: "Uruguay", value: "Uruguay" },
  { flag: "", name: "Uzbekistán", value: "Uzbekistan" },
  { flag: "", name: "Ciudad del Vaticano", value: "CiudaddelVaticano" },
  {
    flag: "",
    name: "San Vicente y las Granadinas",
    value: "SanVicenteylasGranadinas",
  },
  { flag: "", name: "Venezuela", value: "Venezuela" },
  {
    flag: "",
    name: "Islas Vírgenes Británicas",
    value: "IslasVirgenesBritanicas",
  },
  {
    flag: "",
    name: "Islas Vírgenes de los Estados Unidos de América",
    value: "IslasVirgenesdelosEstadosUnidosdeAmerica",
  },
  { flag: "", name: "Vietnam", value: "Vietnam" },
  { flag: "", name: "Vanuatu", value: "Vanuatu" },
  { flag: "", name: "Wallis y Futuna", value: "WallisyFutuna" },
  { flag: "", name: "Samoa", value: "Samoa" },
  { flag: "", name: "Yemen", value: "Yemen" },
  { flag: "", name: "Mayotte", value: "Mayotte" },
  { flag: "", name: "Sudáfrica", value: "Sudafrica" },
];
