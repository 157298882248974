import React from "react";
import { SnackbarAlert } from "@kushki/connect-ui";
import { ITransactionSnackBar } from "./TransactionSnackBar.interfaces";
import Snackbar from "@mui/material/Snackbar";
import { useTransactionSnackBar } from "./state/useTransactionSnackBar";

const TransactionSnackBar = ({ message, open }: ITransactionSnackBar) => {
  const { isOpen, handleClose } = useTransactionSnackBar(open);

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarAlert variant={"simple"} message={message} color={"success"} />
    </Snackbar>
  );
};

export default TransactionSnackBar;
