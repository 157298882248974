export enum WebsocketStatusEnum {
  COMPLETED_SUCCESS = "COMPLETED_SUCCESS",
  COMPLETED_EMPTY = "COMPLETED_EMPTY",
  PROCESSING = "PROCESSING",
  ERROR = "ERROR",
}

export enum WebsocketActionsEnum {
  DOWNLOAD_TRANSACTIONS_ALL = "downloadTransactions",
  DOWNLOAD_TRANSACTIONS_BY_ID = "downloadTransactionsByIdList",
}
