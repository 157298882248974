export enum FieldsNamesRender {
  social_reason = "socialReason",
  rfc = "rfc",
  type_company = "typeCompany",
  type_industry = "typeIndustry",
  mcc = "mcc",
  economy_activity = "economyActivity",
  date = "date",
  web = "web",
  names = "names",
  surname = "surname",
  retention_ICA = "retentionICA",
  type_company_generic = "typeCompanyGeneric",
  economic_activity_generic = "economicActivityGeneric",
  company_registration_number = "companyRegistrationNumber",
}
