export const API_ROUTES = {
  BILLING_CORE_MERCHANT_NODE_INFO: "/billing-core-node/v1/merchant-node/info",
  CATALOGS: "/catalog/v1/list-catalog",
  COMPLIANCE_PEOPLE: "/compliance/v1/node/compliance-people/",
  CREATE_MERCHANT: "/billing-core-node/v1/merchant-node/create",
  CREDENTIALS: "/payment-credentials/v1/admin/credentials",
  GET_BILLING_CONFIG: "/rates/billingConfig",
  GET_CONFIG_RATES: "/costs/costs/configRates",
  GET_DEFERRED_CONFIG: "/deferred/v1/config",
  GET_DEFERRED_LIST: "/deferred/v1/admin/deferred",
  GET_MERCHANT_DISCOUNT: "/rates/merchantDiscount",
  HIERARCHY_GET_FIRST_PARENT: "/hierarchy/v1/node/first-parent",
  MERCHANT_CARD_INFO: "/card/v1/admin/merchant",
  MERCHANT_COMMISSION: "/commission/v1/admin/merchant",
  MERCHANT_COMPLIANCE: "/compliance/v1/merchantCompliance",
  MERCHANT_DEFAULT_PROCESSORS: "/rules/v1/admin/merchant",
  MERCHANT_PAYOUTS_PROCESSORS: "/payouts-rules/v1/admin/merchant/",
  MERCHANT_PROCESSORS: "merchant/v1/admin/merchant",
  MERCHANT_SETTINGS: "/merchant/v1/merchant/settings",
  MERCHANT_SETTINGS_ADMIN: "/merchant/v1/admin/merchant/settings",
  NODE_INFO: "/billing-core-node/v1/node/info",
  PAYMENT_CREDENTIALS: "/payment-credentials/v1/admin/credentials",
  PAYOUTS_RULES: "payouts-rules/v1/admin/rule/list",
  POST_RATES_CONFIGS: "/rates/ratesConfigs",
  RULES: "/rules/v1/admin/business-type/rules",
  SEARCH_MERCHANT_NODE: "/billing-core-node/v1/merchant-node/search",
  SMARTLINK: "/smartlink/v1/admin/merchant",
  SUBSCRIPTIONS: "/v1/subscriptions/admin/merchant",
  TEST_CONNECTION: "/webhook/v1/testConnection",
  USERS: "/authorizer/v1/user?limit=",
  USERS_MERCHANT: "&searchSupport=true&merchantId=",
  VPOS_MERCHANT: "/vpos/v1/admin/merchant",
  WEBHOOK_LIST: "/webhook/v1/list",
  WEBHOOK_NOTIFICATION: "/webhook/v1/webhooks/listNotifications",
};
