import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerTableStyles = createNamedStyles({
  boxContentAddBranch: {
    display: "flex",
  },
  boxContent: {
    display: "flex",
    height: "45%",
    alignItems: "center",
    mt: (theme) => theme.spacing(12),
    width: "40%",
  },
  gridFilter: {
    display: "flex",
    justifyContent: "right",
  },
  labelTitle: {
    fontSize: "26px !important",
    fontWeight: 600,
    color: "#023365",
  },
  labelSubtitle: {
    fontSize: "16px !important",
    p: (theme) => theme.spacing(0, 0.5, 0.5, 0),
    color: "#023365",
  },
  totalBranches: {
    mt: "10px",
  },
  totalSelectedEdit: {
    display: "flex",
    justifyContent: "flex-end",
  },
  gridFilterCard: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .css-79elbk": {
      flexWrap: "wrap",
      width: "100%",
    },
  },
});
