import { RefundState } from "../../../../shared/enums/refund_states";
import {
  toggleClickTransaction,
  toggleTransactionSelection,
} from "../../../../store/actions/transactions/transactions";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { ITransaction } from "../../../../store/interfaces/TransactionsState.interfaces";
import { IUseTransactionTableItemState } from "./useTransactionTableItemState.interfaces";
import { useEffect, useState } from "react";
import { TOOLTIP_MESSAGES } from "../../../../shared/constants/tooltip_messages";
import { FileStatusDownload } from "../../../../shared/enums/file_status_download_enum";
import { TRANSACTION_STATUS_FROM_RESPONSE_LOOKUP } from "../../../../shared/types";

export const useTransactionTableItemState = (
  transaction: ITransaction
): IUseTransactionTableItemState => {
  const dispatch = useAppDispatch();
  const [tooltipTitle, setTooltipTitle] = useState<string>("");

  useEffect(() => {
    if (transaction.refundState === RefundState.REFUND) {
      setTooltipTitle(TOOLTIP_MESSAGES.REFUND_PENDING);
    }
    if (transaction.status === "declined") {
      setTooltipTitle(TOOLTIP_MESSAGES.REFUND_DECLINED);
    }
    if (
      transaction.refundState === RefundState.COMPLETED &&
      transaction.fileStatusDownload === FileStatusDownload.COMPLETED
    ) {
      setTooltipTitle(TOOLTIP_MESSAGES.REFUND_COMPLETED);
    }
    if (
      transaction.status === TRANSACTION_STATUS_FROM_RESPONSE_LOOKUP.APPROVED &&
      (transaction.fileStatusDownload === FileStatusDownload.COMPLETED ||
        transaction.certificateState === FileStatusDownload.COMPLETED)
    ) {
      setTooltipTitle(TOOLTIP_MESSAGES.TRANSACTION_COMPLETED);
    }
  }, [
    transaction.refundState,
    transaction.fileStatusDownload,
    transaction.paymentMethod,
  ]);

  const toggleSelection = () => {
    dispatch(toggleTransactionSelection(transaction));
  };

  const toggleClick = () => {
    dispatch(toggleClickTransaction(transaction));
  };

  return {
    handlers: {
      toggleClick,
      toggleSelection,
    },
    tooltipTitle,
  };
};
