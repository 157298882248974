import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const userActionsStyles = createNamedStyles({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    "&:hover": {
      backgroundColor: "#EEF6FF !important",
      transition: "all 300ms linear",
    },
  },
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.4,
  },
});
