import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const useSectionStyles = createNamedStyles({
  mainMassiveTitle: {
    marginBottom: "10px",
    fontSize: "26px",
    fontWeight: 600,
  },
  alertCard: {
    borderRadius: "8px",
    background: "#FBFCFE",
  },
  alertMassiveTitle: {
    margin: "24px",
    fontSize: "16px",
    fontWeight: 400,
  },
  alertContainer: {
    paddingBottom: "24px",
  },
  alertMassiveMessage: {
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
});
