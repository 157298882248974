import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const detailCardBodyStyles = createNamedStyles({
  container: {
    overflowY: "scroll",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    scrollbarWidth: "thin",
    gap: "16px",
  },
  informationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "24px",
  },
  information: {
    display: " flex",
    justifyContent: "space-between",
  },
  textLight: {
    color: "#6D7781",
  },
  textDark: {
    color: "#293036",
  },
  textBoldGray: {
    fontWeight: "600",
    color: "#6D7781",
  },
  nodesSection: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    marginTop: "36px",
    marginLeft: "16px",
  },
  nodesName: {
    color: "#6D7781",
    lineHeight: "21.6px",
  },
  redirectText: {
    color: "#4498EE",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "700",
    marginLeft: "7px",
  },
  tableContainer: {
    marginTop: "24px",
    backgroundColor: "transparent",
    minWidth: "100%",
    "& thead th": {
      paddingLeft: "0px",
    },
    "& tbody td": {
      paddingLeft: "0px",
    },
  },
  tableHeader: {
    backgroundColor: "transparent",
    borderBottom: "1px solid #cbd5e0",
  },
  tableBody: {
    backgroundColor: "transparent",
  },
  textValues: {
    color: "#293036",
    overflowWrap: "anywhere",
  },
});
