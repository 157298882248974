import { ProcessorTypeChipInfo } from "../../components/PaymentProcessorComponent/PaymentProcessorComponent.interfaces";
import { RadioInfoItems } from "../../components/RadioGroupComponent/RadioGroupComponent.interfaces";

export const PROCESSOR_PAYMENT_METHOD: ProcessorTypeChipInfo[] = [
  { name: "Tarjeta" },
  { name: "Pay Out" },
];

export const PROCESSOR_OPTIONS_RADIO_ITEMS: RadioInfoItems[] = [
  {
    label: "Kushki Payout Processor",
    value: "Kushki Payout Processor",
  },
];

export const MODEL_OPTIONS_RADIO_ITEMS: RadioInfoItems[] = [
  {
    label: "Agregador",
    value: "AGGREGATOR",
  },
];

export const CATEGORY_OPTIONS_RADIO_ITEMS: RadioInfoItems[] = [
  {
    label: "Formal",
    value: "FORMAL",
  },
];
