import { IStyles } from "../../../shared/interfaces/create_named_styles";

export const rechargeWalletFormStyles: IStyles = {
  checkBoxControl: {
    marginBottom: "18px",
    ".label-control": {
      ".MuiCheckbox-root": {
        color: "text.lightGrey",
      },
      ".MuiTypography-root": {
        fontSize: "16px!important",
      },
    },
  },
  checkBoxControlError: {
    ".MuiCheckbox-root": {
      color: "#AD0C2A",
    },
    ".MuiTypography-root": {
      fontSize: "16px!important",
      color: "#AD0C2A",
    },
  },
  checkBoxErrorText: {
    marginLeft: "14px",
    marginBottom: "25px",
  },
  descriptionTextArea: {
    ".MuiFormControl-root ": {
      width: "100%",
      ".MuiFormHelperText-root": {
        color: "#AD0C2A",
      },
      "p:last-child": {
        display: "none",
      },
    },
  },
  headerContent: {
    display: "flex",
    width: "100%",
    marginBottom: "40px",
    ".title-box": {
      display: "flex",
      alignItems: "center",
      ".title-text": {
        paddingLeft: "10px",
        color: "text.dark",
        fontSize: "20px",
        fontWeight: "500",
      },
    },
    ".img-box": {
      marginLeft: "auto",
    },
  },
  dataInputGrid: {
    marginBottom: "30px",
    ".data-input-title": {
      color: "text.dark",
      marginBottom: "25px",
    },
  },
  rechargeValueBox: {
    display: "flex",
    alignItems: "center",
    ".recharge-value-input": {
      marginLeft: "auto",
      width: "55%",
    },
  },
  cancelButton: {
    borderRadius: "4px",
    border: "none",
    fontWeight: "500",
    color: "text.dark",
    "&:hover": {
      background: "transparent",
      borderRadius: "4px",
      border: "none",
      color: "text.dark",
    },
  },
  rechargeButton: {
    background: "#023365",
    borderRadius: "4px",
    border: "none",
    fontWeight: "500",
    marginLeft: "20px",
    color: "background.default",
    "&:hover": {
      background: "#112B45",
      color: "background.default",
      borderRadius: "4px",
      border: "none",
    },
  },
};
