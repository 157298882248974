import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const searchTextStyles = createNamedStyles({
  rangeDate: {
    "& > button": {
      backgroundColor: "transparent",
      width: "90%",
    },
    "& > button:hover": {
      backgroundColor: "transparent",
      borderColor: "#015ecc",
    },
    "& > span": {
      color: "red !important",
      borderColor: "#015ecc",
    },
  },
  searchText: {
    m: 1,
    marginRight: "1rem",
    width: "90%",
    "& fieldset": {
      borderRadius: "1rem !important",
    },
  },
});
