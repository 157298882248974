import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { IConfirmationModalProps } from "./ConfirmationModal.interfaces";
import { confirmationModalStyles } from "./ConfirmationModal.styles";

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  handleAccept,
  handleCancel,
  isOpen,
}: IConfirmationModalProps) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={"sm"}
      fullWidth
      sx={confirmationModalStyles.dialogPadding}
    >
      <DialogTitle>
        <Typography sx={confirmationModalStyles.title}>
          ¿Desea eliminar este socio?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={confirmationModalStyles.subtitle}>
          Se borrarán por completo los datos que hayas ingresado.
        </Typography>
      </DialogContent>
      <DialogActions sx={confirmationModalStyles.actionsDialogPadding}>
        <Button
          onClick={handleCancel}
          variant={"text"}
          id={"cancel-button"}
          sx={confirmationModalStyles.backButton}
        >
          Regresar
        </Button>
        <Button
          onClick={handleAccept}
          variant={"text"}
          id={"accept-button"}
          sx={confirmationModalStyles.deleteButton}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
