import React from "react";

export const AddIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.7402C17.5228 22.7402 22 18.2631 22 12.7402C22 7.21739 17.5228 2.74023 12 2.74023C6.47715 2.74023 2 7.21739 2 12.7402C2 18.2631 6.47715 22.7402 12 22.7402Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.74023V16.7402"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12.7402H16"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
