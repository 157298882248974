import { FormHelperText, Grid, Typography } from "@mui/material";
import { SubTitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Controller } from "react-hook-form";
import { SelectSearchBy } from "@kushki/connect-ui";
import { isFormEditMode, retrieveFormError } from "../../../utils/utilsFile";
import React from "react";
import { useMerchantHierarchyState } from "./state/useMerchantHierarchyState";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { NodesState } from "../../../shared/infrastructure/interfaces/NodesState";
import { generalStyles } from "../../../shared/styles/generalStyles.styles";

export const MerchantHierarchyComponent: React.FC<NodesState> = (
  props: NodesState
) => {
  const {
    control,
    errors,
    listMerchantsItems,
    isWhiteList,
    actions: { onItemSelectHierarchy, searchHierarchy },
    defaultValueSelect,
    id,
    isCreationRule,
    showSearchingTextMessage,
  } = useMerchantHierarchyState(props);
  const classes = generalStyles();

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <Typography className={classes.subtitleTypography}>
          {isWhiteList
            ? SubTitleSection.RULES_CREATE_MERCHANT_WHITELIST_SUBTITLE
            : SubTitleSection.RULES_CREATE_MERCHANT_SUBTITLE}
        </Typography>
      </Grid>
      <Grid item xs={8.5}>
        {(isFormEditMode(id) || isCreationRule) && defaultValueSelect ? (
          <>
            <Controller
              name={"hierarchy"}
              control={control}
              rules={{
                required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
              }}
              defaultValue={defaultValueSelect}
              as={
                <Grid item xs={12} md={8.5} lg={5}>
                  <SelectSearchBy
                    defaultValue={defaultValueSelect}
                    isDisabled={!isCreationRule}
                    items={listMerchantsItems}
                    placeholder={
                      SubTitleSection.RULES_SEARCH_MERCHANT_PLACEHOLDER
                    }
                    onItemSelected={onItemSelectHierarchy}
                    onInputChange={searchHierarchy}
                    isEmptyListMessage={
                      showSearchingTextMessage
                        ? SubTitleSection.SEARCHING_MERCHANT
                        : undefined
                    }
                  />
                </Grid>
              }
            />
          </>
        ) : (
          <Controller
            name={"hierarchy"}
            control={control}
            rules={{
              required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
            }}
            as={
              <Grid item xs={12} md={8.5} lg={5}>
                <SelectSearchBy
                  items={listMerchantsItems}
                  placeholder={
                    SubTitleSection.RULES_SEARCH_MERCHANT_PLACEHOLDER
                  }
                  onItemSelected={onItemSelectHierarchy}
                  onInputChange={searchHierarchy}
                  isEmptyListMessage={
                    showSearchingTextMessage
                      ? SubTitleSection.SEARCHING_MERCHANT
                      : undefined
                  }
                />
              </Grid>
            }
          />
        )}

        <FormHelperText error={retrieveFormError(errors, "hierarchy")[0]}>
          {retrieveFormError(errors, "hierarchy")[1]}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
