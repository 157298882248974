import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";
import theme from "../../theme";
export const headerPageStyles: IStyles = {
  main: {
    marginBottom: "32px",
  },
  title: {
    typography: "h1",
    fontSize: "40px",
    fontWeight: "300",
    lineHeight: "120%",
    letterSpacing: "-0.015em",
    color: theme.palette.primary.main,
    fontStyle: "normal",
  },
  box: {
    borderBottom: 1,
    borderColor: "divider",
    marginTop: "28px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
      height: "2px",
    },
    "& .MuiTabs-indicatorSpan": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tab: {
    textTransform: "none",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "15.6px",
    letterSpacing: "0.01em",
    fontFamily: "",
    color: theme.palette.grey[800],
    "&.Mui-selected": {
      color: theme.palette.grey[800],
    },
  },
};
