import { CountryEnum } from "./CountryEnum";
import speiImage from "../../assets/images/SPEI.png";
import etpayImage from "../../assets/images/ETPAY.png";

export const CountryTitleEnumMap: Record<CountryEnum, string> = {
  [CountryEnum.MEXICO]: "Transferencia electrónica SPEI",
  [CountryEnum.CHILE]: "Transferencia electrónica ETPAY",
};

export const IconEnumMap: Record<CountryEnum, string> = {
  [CountryEnum.MEXICO]: speiImage,
  [CountryEnum.CHILE]: etpayImage,
};

export const IconHeightEnumMap: Record<CountryEnum, number> = {
  [CountryEnum.MEXICO]: 46,
  [CountryEnum.CHILE]: 36,
};
