import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/routes/api_routes";
import { WalletTransactionsResponse } from "../../../../types/wallet_transactions_response";
import { AxiosResponse } from "axios";
import { setOpenSnackbar } from "../../actions/app.action";
import { WalletSnackBarMessageEnum } from "../../../shared/infrastructure/constants/WalletResumeConstants";

export const getTransactionsByMerchantId = createAsyncThunk<
  WalletTransactionsResponse,
  { merchantId: string; filter?: object }
>(
  "walletTransaction/getTransactions",
  async ({ merchantId, filter }, { dispatch }) => {
    try {
      const response: AxiosResponse =
        await axios.post<WalletTransactionsResponse>(
          API_ROUTES.GET_WALLET_TRANSACTIONS(merchantId),
          filter
        );

      return response.data;
    } catch (e) {
      dispatch(
        setOpenSnackbar({
          isError: true,
          message: WalletSnackBarMessageEnum.ERROR_SEARCH_WALLET_TRX,
          withIcon: true,
        })
      );
    }
  }
);
