export const setItemSessionStorage = <T>(key: string, data: T) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getItemSessionStorage = <T>(
  key: string,
  defaultValue: string
): T => {
  const json = sessionStorage.getItem(key) || defaultValue;

  return JSON.parse(json);
};
