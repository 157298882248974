import { useMemo } from "react";
import { dateTimePattern } from "../utils/patterns";

const useRawDateTime = (rawDateTime: string) => {
  return useMemo(() => {
    if (dateTimePattern.test(rawDateTime)) {
      const [rawDate, rawTime] = rawDateTime.split(" ");

      return [rawDate, rawTime];
    }

    return ["", ""];
  }, [rawDateTime]);
};

export default useRawDateTime;
