import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  findUser,
  getMerchants,
  getSearchMerchantsInfo,
  validateUsername,
} from "../../thunks/userForm/userForm.thunk";
import { each, get, remove } from "lodash";
import { MerchantNodeData } from "../../../../types/search_merchant_node_response";
import { CountryEnum } from "../../../shared/enum/CountryEnum";
import { ConfigTypeEnum } from "../../../shared/enum/configEnum";
import { ISetLoading, IUserForm } from "../../interfaces/userForm.interfaces";
import { UserForm } from "../../../../types/user_form";
import { StatusTypeEnum } from "../../../shared/enum/InputTypeEnum";

export const INITIAL_USER_STORE = {
  email: "",
  familyName: "",
  group: [],
  name: "",
  publicMerchantId: "",
  userName: "",
};

export const initialState: IUserForm = {
  counterChangeState: 0,
  fetchUsers: {
    PaginationToken: "",
    Users: [],
  },
  isLoadingValidateUsername: false,
  merchants: { data: [], total: 0 },
  statusResponse: StatusTypeEnum.FAIL,
  user: INITIAL_USER_STORE,
  userValidate: true,
};

export const userFormSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(validateUsername.fulfilled, (state, { payload }) => {
        state.userValidate = payload;
        state.isLoadingValidateUsername = false;
      })
      .addCase(validateUsername.rejected, (state, {}) => {
        state.userValidate = false;
        state.isLoadingValidateUsername = false;
      })
      .addCase(validateUsername.pending, (state, {}) => {
        state.isLoadingValidateUsername = true;
      })
      .addCase(getMerchants.fulfilled, (state, { payload }) => {
        state.merchants = payload;
      })
      .addCase(getSearchMerchantsInfo.fulfilled, (state, { payload }) => {
        each(payload.data, (merchant: MerchantNodeData) => {
          if (get(merchant, "country", "") !== CountryEnum.ecuador) {
            remove(
              get(merchant, "configs", []),
              (c) => c.configuration === ConfigTypeEnum.cn017
            );
          }
        });
        state.merchantsInfo = payload;
      })
      .addCase(getSearchMerchantsInfo.rejected, (state, {}) => {
        state.merchantsInfo = { data: [] };
      })
      .addCase(findUser.fulfilled, (state, { payload }) => {
        state.user = payload;
      });
  },
  initialState,
  name: "userForm",
  reducers: {
    setStateLoading: (
      state: Draft<IUserForm>,
      action: PayloadAction<ISetLoading>
    ) => {
      state.isLoadingCreateEditUser = action.payload.isLoading;
      state.statusResponse = action.payload.status;
    },
    setUser: (state: Draft<IUserForm>, action: PayloadAction<UserForm>) => {
      state.user = action.payload;
      state.counterChangeState = 0;
    },
    setValidateUserName: (
      state: Draft<IUserForm>,
      action: PayloadAction<boolean>
    ) => {
      state.userValidate = action.payload;
    },
  },
});

export default userFormSlice.reducer;

export const { setUser, setStateLoading, setValidateUserName } =
  userFormSlice.actions;
