import React from "react";
import { Box, Typography } from "@material-ui/core";
import { emptyTableStyle } from "./EmptyTable.style";
import { EmptyResultImage } from "../../assets/EmptyResultImage";
import { IEmptyTableProps } from "../../shared/infrastructure/interfaces/EmptyTable.interface";

export const EmptyTable = ({
  title,
  description,
  operationId,
}: IEmptyTableProps) => {
  const classes = emptyTableStyle();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
      {operationId && (
        <Typography className={classes.description}>
          {`"${operationId}"`}
        </Typography>
      )}
      <Box className={classes.image}>
        <EmptyResultImage />
      </Box>
    </Box>
  );
};
