import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const SkeletonTable: React.FC = () => {
  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {[...Array(5)].map((_: string, index: number) => (
                <TableCell key={index}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(5)].map((_: string, index: number) => (
              <TableRow key={index}>
                {[...Array(6)].map((_: string, innerIndex: number) => (
                  <TableCell key={innerIndex}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
