import { useSelector } from "react-redux";
import {
  CardMerchantResponse,
  IAppState,
  SubscriptionsResponse,
  VPOSResponse,
} from "../../../store/reducer";
import { MerchantResponse } from "../../../../types/merchant_response";
import { useMediaQuery } from "@material-ui/core";
import alia from "../../../assets/images/alia.png";
import amex from "../../../assets/images/amex.png";
import diners from "../../../assets/images/diners.png";
import discover from "../../../assets/images/discover.png";
import jcb from "../../../assets/images/jcb.png";
import mastercard from "../../../assets/images/mastercard.svg";
import Visa from "../../../assets/images/visa.svg";
import carnet from "../../../assets/images/carnet.png";
import unionPay from "../../../assets/images/unionpay.png";
import magna from "../../../assets/images/magna.png";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { get } from "lodash";
import { INotificationResponse } from "../../../shared/infrastructure/interfaces/INotificationResponse";

export interface IUseBasicInformation {
  loading?: boolean;
  merchant: MerchantResponse;
  isMobile?: boolean;
  handleEditServices: (merchant: MerchantResponse) => void;
  acceptedCardImage: (card: string) => string;
  activeService: string;
  conditions: string;
  subscriptionValidation: string;
  acceptedCards: string[];
}

export const useServicesSection = (): IUseBasicInformation => {
  let activeService: string = "";
  let conditions: string = "";
  let subscriptionValidation: string = "";

  const loading: boolean = useSelector((state: IAppState) => !!state.isLoading);
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );

  const cardOptions: CardMerchantResponse = useSelector(
    (state: IAppState) => state.cardOptions!
  );

  const vposOptions: VPOSResponse = useSelector(
    (state: IAppState) => state.vposOptions!
  );

  const webcheckoutOptions: INotificationResponse | undefined = useSelector(
    (state: IAppState) => state.webCheckout
  );

  const subscriptionOptions: SubscriptionsResponse = useSelector(
    (state: IAppState) => state.subscriptionOptions!
  );

  const getActiveServices = (vpos: boolean, smartlinks: boolean) => {
    vpos && smartlinks
      ? (activeService += "Vpos, ")
      : vpos
      ? (activeService += "Vpos")
      : (activeService += "");
    smartlinks ? (activeService += "Smartlinks") : (activeService += "");
    return activeService;
  };

  const getSubscriptionValidation = (subsValidation: boolean) => {
    subsValidation
      ? (subscriptionValidation = "Si")
      : (subscriptionValidation = "No");
    return subscriptionValidation;
  };

  const getConditions = (sandbox: boolean, whitelist?: boolean) => {
    sandbox && whitelist
      ? (conditions += "Sandbox, ")
      : sandbox
      ? (conditions += "Sandbox")
      : (conditions += "");
    whitelist ? (conditions += "Lista Blanca") : (conditions += "");
    return conditions;
  };

  const acceptedCards: string[] = get(cardOptions, "acceptCreditCards", []);

  getActiveServices(
    get(vposOptions, "active", false),
    get(webcheckoutOptions, "status", false)
  );

  getConditions(
    get(cardOptions, "sandboxEnable", false),
    get(cardOptions, "whiteList", false)
  );

  getSubscriptionValidation(
    get(subscriptionOptions, "subscriptionValidation", false)
  );

  const isMobile: boolean = useMediaQuery("(max-width:767px)");

  const acceptedCardImage = (card: string) => {
    switch (card) {
      case "visa":
        return Visa;
      case "alia":
        return alia;
      case "amex":
        return amex;
      case "diners":
        return diners;
      case "discover":
        return discover;
      case "jcb":
        return jcb;
      case "masterCard":
        return mastercard;
      case "carnet":
        return carnet;
      case "union pay":
        return unionPay;
      case "magna":
        return magna;
      default:
        break;
    }
  };

  const handleEditServices = (merchant: MerchantResponse) => {
    localStorage.setItem(
      "merchantInfo",
      JSON.stringify({ publicMerchantId: merchant.publicMerchantId })
    );
    localStorage.setItem(
      "merchantPropertiesInfo",
      JSON.stringify({
        publicMerchantId: merchant.publicMerchantId,
        country: merchant.country,
        isEditing: true,
      })
    );
    localStorage.setItem("onEditMerchant", JSON.stringify({ edit: true }));
    localStorage.removeItem("merchantStore");
    window.location.href = routes.MERCHANT_PROPERTIES;
  };

  return {
    merchant,
    subscriptionValidation,
    loading,
    isMobile,
    handleEditServices,
    activeService,
    conditions,
    acceptedCards,
    acceptedCardImage,
  };
};
