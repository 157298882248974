import React, { FC } from "react";
import { IFormValues } from "../../containers/ContainerIndex/state/useContainerIndexState.interfaces";
import { Box, Grid, Typography } from "@mui/material";
import SelectAutocompleteItemsInput from "../InputForms/SelectAutocompleteItemsInput/SelectAutocompleteItemsInput";
import { mccCatalog } from "../../shared/constants/MccEnum";
import { ciiuCatalog } from "../../shared/constants/CiiuEnum";
import { companyInformationFormStyles } from "./CompanyInformation.styles";
import { useCompanyInformationFormState } from "./state/useCompanyInformationFormState";

export interface ICompanyInformationFormProps {
  form: Partial<IFormValues>;
}

const CompanyInformationForm: FC<ICompanyInformationFormProps> = ({
  form: { control, errors, setValue },
}: ICompanyInformationFormProps) => {
  useCompanyInformationFormState({ setValue: setValue! });

  return (
    <Box sx={companyInformationFormStyles.boxContainer}>
      <Box sx={companyInformationFormStyles.boxForm}>
        <Typography sx={companyInformationFormStyles.subtitle}>
          * Estos datos son obligatorios
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item md={6} xs={12}>
            <SelectAutocompleteItemsInput
              name={"ciiu"}
              defaultValue={""}
              control={control!}
              errors={errors!}
              isRequired={true}
              label={"CIIU*"}
              items={ciiuCatalog}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <SelectAutocompleteItemsInput
              name={"mcc"}
              defaultValue={""}
              control={control!}
              errors={errors!}
              isRequired={true}
              label={"MCC*"}
              items={mccCatalog}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanyInformationForm;
