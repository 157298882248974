import { GetProcessorsResponse } from "../../../types/get_processors_response";
import { get, has, isEmpty, remove, uniqBy } from "lodash";
import {
  EnabledPaymentMethods,
  PaymentMethodEnum,
  ProcessorNameEnum,
} from "../enums/PaymentMethodEnum";
import { ProcessorPathEnum } from "../enums/ProcessorPathEnum";
import { CountryEnum } from "../enums/CountryEnum";

const hasCompleteTransactionCertificates = (
  response: GetProcessorsResponse[]
): boolean => {
  return response.some((value: GetProcessorsResponse) => {
    const hasCertificateCompleteTransaction: boolean = !!get(
      value,
      ProcessorPathEnum.COMPLETE_TRX,
      false
    );

    const isEnabledRest: boolean = !!get(
      value,
      ProcessorPathEnum.ENABLE_COMPLETE_TRX
    );

    const saleWithCvv: object = get(
      value,
      ProcessorPathEnum.COMPLETE_TRX_SALE_WITH_CVV,
      {}
    );
    const saleWithoutCvv: object = get(
      value,
      ProcessorPathEnum.COMPLETE_TRX_WITHOUT_CVV,
      {}
    );

    const emptyWithCvv: boolean = isEmpty(saleWithCvv);
    const emptyWithoutCvv: boolean = isEmpty(saleWithoutCvv);

    return (
      hasCertificateCompleteTransaction ||
      isEnabledRest ||
      !emptyWithCvv ||
      !emptyWithoutCvv
    );
  });
};

const hasWebPayCertificates = (response: GetProcessorsResponse[]): boolean => {
  return (
    response.filter((value: GetProcessorsResponse) => {
      const hasWebPay: boolean = get(
        value,
        ProcessorPathEnum.WEB_PAY_CERTIFICATE,
        false
      );
      const webPayPlus: object = get(
        value,
        ProcessorPathEnum.WEB_PAY_PLUS_CREDENTIAL,
        {}
      );
      const webPayPlusMall: object = get(
        value,
        ProcessorPathEnum.WEB_PAY_PLUS_MALL_CREDENTIAL,
        {}
      );
      const hasWebPayPlus: boolean = !isEmpty(webPayPlus);
      const hasWebPayPlusMall: boolean = !isEmpty(webPayPlusMall);

      return hasWebPay || hasWebPayPlus || hasWebPayPlusMall;
    }).length > 0
  );
};

const checkWebPayPaymentMethods = (
  paymentMethods: PaymentMethodEnum[],
  response: GetProcessorsResponse[]
): void => {
  const enabledCompleteTransaction: boolean =
    hasCompleteTransactionCertificates(response);

  if (hasWebPayCertificates(response) && enabledCompleteTransaction) {
    remove(
      paymentMethods,
      (paymentMethod: string) => paymentMethod === PaymentMethodEnum.CREDIT_CARD
    );
    paymentMethods.push(PaymentMethodEnum.CARD_DYNAMIC);
  } else if (hasWebPayCertificates(response) && !enabledCompleteTransaction) {
    remove(
      paymentMethods,
      (paymentMethod: string) => paymentMethod === PaymentMethodEnum.CREDIT_CARD
    );
    paymentMethods.push(PaymentMethodEnum.CARD_ASYNC);
  } else if (!hasWebPayCertificates(response) && !enabledCompleteTransaction) {
    remove(
      paymentMethods,
      (paymentMethod: string) => paymentMethod === PaymentMethodEnum.CREDIT_CARD
    );
  }
};

const checkChileProcessorsValidation = (
  country: string,
  paymentMethods: PaymentMethodEnum[]
) =>
  country === CountryEnum.CHILE &&
  paymentMethods.includes(PaymentMethodEnum.CREDIT_CARD);

const checkIfCardProcessor = (processor: GetProcessorsResponse) =>
  processor.paymentMethod === PaymentMethodEnum.CARD &&
  processor.processorName != ProcessorNameEnum.TRANS_BANK;

export const filterPaymentMethods = (data: GetProcessorsResponse[]) =>
  uniqBy(
    data.filter(
      (processor) =>
        !has(processor, "deletedAt") &&
        EnabledPaymentMethods.includes(
          processor.paymentMethod as PaymentMethodEnum
        )
    ),
    "paymentMethod"
  ).map((processor) =>
    processor.paymentMethod === PaymentMethodEnum.CARD
      ? PaymentMethodEnum.CREDIT_CARD
      : (processor.paymentMethod as PaymentMethodEnum)
  );

export const validateChileProcessors = (
  country: string,
  paymentMethods: PaymentMethodEnum[],
  data: GetProcessorsResponse[]
) => {
  if (checkChileProcessorsValidation(country, paymentMethods)) {
    const hasCardProcessor = data.some((processor) =>
      checkIfCardProcessor(processor)
    );

    if (!hasCardProcessor) checkWebPayPaymentMethods(paymentMethods, data);
  }
};
