import React from "react";
import {
  Button,
  Divider,
  Grid,
  Switch,
  Typography,
  withStyles,
} from "@material-ui/core";
import { IAppState } from "../../store/reducer";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { useRuleAuthenticationIndexState } from "./state/useRuleAuthenticationIndexState";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getMerchantInfo,
  getMerchantCredential,
  getMerchants,
  getProcessorsauthenticationRules,
  IAppAction,
  MerchantsData,
  RulesAction,
  setIsLoading,
  setMerchants,
  setNotification,
  setProcessors,
  updateAuthenticationRequest,
} from "../../store/actionCreators";
import { FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import { CardHeader } from "../../components/common/CardHeader/CardHeader";
import { GeneralData } from "../../components/RuleAuthenticationConfiguration/GeneralDataComponent/GeneralData";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";
import { ProcessorInformation } from "../../../types/processor_information";
import { Category } from "../../../types/category";
import { AuthenticationRule } from "../../../types/authentication_rule";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { routes } from "../../shared/infrastructure/routes";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { StatusEnum } from "../../shared/infrastructure/enums/StatusEnum";
import { Loader } from "@kushki/frontend-molecules/loader";
import { AuthenticationRequestDynamo } from "../../../types/remote/authentication_request_dynamo";
import { AuthenticationSettings } from "./AuthenticationSettings";
import { SkeletonForm } from "../../components/Skeletons/SkeletonForm";
import { kindEnum } from "../../shared/infrastructure/enums/KindEnum";

export interface IRuleAuthenticationStateProps {
  state: IAppState;
  isLoading: boolean | undefined;
  isLoadingGetMerchants: boolean | undefined;
  isLoadingGetProcessors: boolean | undefined;
  isLoadingAuthenticationRules: boolean | undefined;
  isLoadingGetRule: boolean | undefined;
  notification: INotification | undefined;
  merchants: MerchantsData | undefined;
  processors: ProcessorInformation[] | undefined;
  currentAuthenticationRule: AuthenticationRule | undefined;
  authenticationCredentials: Category[] | undefined;
}

const KushkiDeepBlueSwitch = withStyles({
  switchBase: {
    color: "#023365",
    "&$checked": {
      color: "#023365",
    },
    "&$checked + $track": {
      background: "#b2d6ff",
    },
  },
  checked: {},
  track: {
    background: "#444",
  },
})(Switch);

const useStyles = makeStyles((theme: Theme) => ({
  sectionButton: {
    marginTop: theme.spacing(20),
  },
  sectionCard: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(20),
  },
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    backgroundColor: "#F0F4F9",
    zIndex: 10,

    [theme.breakpoints.up("md")]: {
      width: "57%",
      height: " 94px",
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      width: "63%",
      height: " 94px",
      marginRight: theme.spacing(2),
    },
  },
  menuButton: {
    backgroundColor: " #023365",
    width: "101px",
    height: "36px",
    color: "#fff",
    "&:hover": {
      backgroundColor: " #023365",
    },
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  saveButton: {
    width: "101px",
    height: "36px",
    backgroundColor: "#F0F4F9",
    borderRadius: "8px 8px 0px 0px",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  closeButton: {
    width: "101px",
    height: "36px",
    backgroundColor: "#F0F4F9",
    borderRadius: "8px 8px 0px 0px",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },

  labelTitle: {
    font: "IBM Plex Sans",
    color: "#023365",
    fontStyle: "normal",
    lineHeight: "52px",
    fontSize: "40px",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "14px",
    marginBottom: theme.spacing(1),
  },
  defaultPd: {
    padding: "15px",
  },
  authRules: {
    height: "fit-content",
    width: "220",
  },
  txtNormal: {
    fontWeight: 500,
  },
  txtLighter: {
    fontWeight: 400,
    color: "#293036",
  },
}));

export interface IRuleAuthenticationFunctionsProps {
  setIsLoading: (payload: boolean) => RulesAction;
  setNotification: (payload: INotification) => RulesAction;
  getProcessors: (payload: { merchantId: string }) => void;
  getCredentials: (payload: string) => void;
  setProcessors: (payload: ProcessorInformation[]) => RulesAction;
  setMerchants: (payload: MerchantsData) => RulesAction;
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }) => void;
  getMerchantInfo: (payload: string) => void;
  updateAuthenticationRequest: (body: AuthenticationRequestDynamo) => void;
}

export type TRuleAuthenticationIndexProps = IRuleAuthenticationStateProps &
  IRuleAuthenticationFunctionsProps;

export const RuleAuthenticationIndex: React.FC<TRuleAuthenticationIndexProps> =
  (props: TRuleAuthenticationIndexProps) => {
    const classes = useStyles();
    const {
      breadCrumbs,
      form,
      actions,
      processors,
      credentials,
      ignore3DS,
      authorizationKind,
      setIgnore3DS,
      isLoadingAuthenticationRules,
      setKind,
    } = useRuleAuthenticationIndexState(props);

    const history = useHistory();

    return (
      <React.Fragment>
        <Breadcrumb items={breadCrumbs.items} lastItem={breadCrumbs.lastItem} />
        <Typography className={classes.labelTitle} variant="h1" color="primary">
          Configuración de regla de autenticación
        </Typography>
        <FormProvider {...form}>
          <Grid className={classes.sectionCard}>
            <CardHeader
              subTitle={"Datos generales"}
              textTitle={
                "En esta sección podrás añadir un alias a la regla, visualizar los datos del comercio y del servicio contratado."
              }
              marginBottom={40}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {isLoadingAuthenticationRules ? (
                  <SkeletonForm items={1} type={"v1"} />
                ) : (
                  <GeneralData
                    merchantData={props.state.authenticationData}
                    setKind={setKind}
                  />
                )}
              </div>
            </CardHeader>
            <CardHeader
              subTitle={"Configuración de autenticación"}
              textTitle={"Configura las condiciones de las reglas a crear."}
              marginBottom={20}
            >
              {isLoadingAuthenticationRules ? (
                <SkeletonForm items={2} type={"v2"} />
              ) : (
                <AuthenticationSettings
                  credentials={credentials}
                  processors={processors}
                />
              )}
              {authorizationKind.toUpperCase().includes(kindEnum["3DS"]) && (
                <Grid
                  style={{
                    padding: "32px",
                    font: "IBM Plex Sans",
                    color: "#293036",
                    fontStyle: "normal",
                    lineHeight: "18px",
                    fontSize: "15px",
                  }}
                >
                  <Divider />
                  <Typography
                    variant={"h5"}
                    color={"primary"}
                    style={{ marginTop: "32px" }}
                  >
                    Configuración adicional
                  </Typography>
                  <p id={"description"}>
                    Active los parámetros que deben cumplirse para este servicio
                    de autenticación. Recuerda que puedes activar más de un
                    parámetro.
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ marginLeft: "-8px", marginRight: "12px" }}>
                      <KushkiDeepBlueSwitch
                        id={"switch_kushki"}
                        value={ignore3DS}
                        checked={ignore3DS}
                        onClick={() => setIgnore3DS(!ignore3DS)}
                      />
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <div>Asumir riesgo transaccional</div>
                      <div
                        style={{
                          paddingTop: "8px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <InfoIcon
                          width={20}
                          height={20}
                          style={{ marginRight: "10px" }}
                        />
                        <div style={{ marginTop: "5px" }}>
                          Todas las transacciones que se rechacen por 3DS con el
                          campo veresEnrolled con valor N y con ECI 07 o 0,
                          consultará si el switch está prendido para mandarlas a
                          procesar.
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              )}
            </CardHeader>
          </Grid>

          <Grid className={classes.sectionButton}>
            <Grid item xs={12} md={3} lg={3} />
            <Grid
              container
              xs={12}
              md={9}
              lg={9}
              justify={"flex-end"}
              className={classes.footer}
            >
              <Button
                id={"button-close"}
                onClick={() => history.push(routes.PATH_RULE_REQUEST_DASHBOARD)}
                className={classes.closeButton}
                color={"primary"}
              >
                Salir
              </Button>
              <Button
                id={"button-save"}
                type="submit"
                variant="contained"
                className={classes.saveButton}
                onClick={() =>
                  actions.handleSubmitForm(
                    form.getValues(),
                    StatusEnum.IN_PROCESS
                  )
                }
              >
                Guardar
              </Button>
              <Button
                id={"button-send"}
                className={classes.menuButton}
                type="submit"
                color={"primary"}
                onClick={() =>
                  actions.handleSubmitForm(form.getValues(), StatusEnum.SENT)
                }
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
        <Loader
          open={props.state.isLoadingUpdateAuthenticationRules}
          headerText={"Estamos guardando la regla de autenticación"}
          bodyText={"Este proceso puede tardar un poco..."}
        />
      </React.Fragment>
    );
  };

export const mapStateToProps: (
  state: IAppState
) => IRuleAuthenticationStateProps = (
  state: IAppState
): IRuleAuthenticationStateProps => ({
  state,
  isLoading: state.isLoading,
  isLoadingGetMerchants: state.isLoadingGetMerchants,
  isLoadingGetRule: state.isLoadingGetRule,
  isLoadingGetProcessors: state.isLoadingGetProcessors,
  notification: state.notification,
  merchants: state.merchants,
  processors: state.processors,
  currentAuthenticationRule: state.currentAuthenticationRule,
  authenticationCredentials: state.credentials,
  isLoadingAuthenticationRules: state.isLoadingAuthenticationRules,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IRuleAuthenticationFunctionsProps = (
  _dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IRuleAuthenticationFunctionsProps => ({
  getProcessors: (payload: { merchantId: string }): void =>
    _dispatch(getProcessorsauthenticationRules(payload)),
  getCredentials: (payload: string): void =>
    _dispatch(getMerchantCredential(payload)),
  setProcessors: (payload: ProcessorInformation[]): RulesAction =>
    _dispatch(setProcessors(payload)),
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }): void => _dispatch(getMerchants(payload)),
  setMerchants: (payload: MerchantsData): RulesAction =>
    _dispatch(setMerchants(payload)),
  setIsLoading: (payload: boolean): RulesAction =>
    _dispatch(setIsLoading(payload)),
  setNotification: (payload: INotification): RulesAction =>
    _dispatch(setNotification(payload)),
  getMerchantInfo: (payload: string): void =>
    _dispatch(getMerchantInfo(payload)),
  updateAuthenticationRequest: (body: AuthenticationRequestDynamo): void =>
    _dispatch(updateAuthenticationRequest(body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleAuthenticationIndex);
