export const TEXTS = {
  validUrls:
    "Hemos realizado con éxito las pruebas de las URLs que fueron configuradas para este webhook. ",
  invalidUrls:
    "Hemos realizado las pruebas de los datos ingresados y hemos encontrado un error en la conexión. Puedes guardar ahora y reintentar en otro momento.",
  preauthConfirmationTitle: "Confirmar activación de preautorización",
  preauthConfirmationMainText:
    "Ten en cuenta que al utilizar el evento de preautorización se\n" +
    "                desactivan otros eventos y medios de pago seleccionados, debido\n" +
    "                a que tiene una configuración diferente.",
  preauthConfirmationQuestion: "¿Deseas configurar preautorización?",
  preauthHelpText:
    " Solo debe agregar una URL para el webhook de\n" +
    "                                Preautorización.",
};
