/* istanbul ignore file */
import React from "react";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { reducer } from "./store/reducer";
import { environment } from "./environments/environment";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Navigation from "./navigation.component";
// @ts-ignore
import Normalize from "react-normalize";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { RuleRequestRoles } from "./shared/infrastructure/constants/RuleRequestConstants";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <React.StrictMode>
      <Normalize />
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ModuleSecurityWrapper
              basePath={`${environment.kushkiUrl}`}
              componentId={`${RuleRequestRoles.M_FRAUD_PREVENTION}`}
            >
              <Navigation />
            </ModuleSecurityWrapper>
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </React.StrictMode>
  );
};

export default Root;
