export enum ErrorMessageMX {
  MAX_1_50_CHAR = "El máximo permitido de caracteres es 1-50",
  MAX_50_CHAR = "El máximo permitido de caracteres es 50",
  PF_RAZON_SOCIAL = "Por favor, ingresa la razón social",
  PF_TIPO_TAX_ID = "Por favor, ingresa el número de TAX ID",
  PF_TIPO_TAX_ID2 = "Por favor, selecciona el tipo de tax Id",
  LIMIT_ID = "El número de identificación debe tener entre 12 y 13 caracteres",
  LIM_EIN = "El número de EIAN debe tener entre 12 y 13 caracteres",
  PF_NUM_ID = "Por favor, ingresa el número de identificación",
  PF_NOMBRE = "Por favor, ingresa el nombre",
  PF_APELLIDO = "Por favor, ingresa el apellido",
  LETRAS = "Solo se aceptan letras",
  PF_MCC = "Por favor, selecciona un sector comercial",
  PF_INDUSTRIA = "Por favor, selecciona el tipo de industria",
  PF_COMPANY = "Por favor, selecciona el tipo de empresa o sociedad",
  PF_ACT_COMER = "Por favor, selecciona la actividad comercial",
  PF_TIPO_PERSONA = "Por favor, elige un tipo de persona",
  EC_AGREGATE_MODEL = "Por favor elige una respuesta",
  PF_WEB = "Por favor, escribe el sitio web",
  REG_EXP_WEB = "Por favor, ingrese un sitio web válido",
  PF_DATE = "Por favor, ingresa la fecha de constitución",
}

export const getCharAllowedMessage = (value: string) =>
  `El máximo permitido de caracteres es ${value}`;

export enum ErrorBillingData {
  CITY = "Seleccione la ciudad",
  DISTRIC = "Seleccione el distrito",
  TAX_REGIME = "Selecciona un régimen fiscal",
  COLOGNE = "Selecciona una colonia",
  CITY_ES = "Por favor selecciona una ciudad",
  STATE = "Selecciona el estado",
  DEPARTAMENT = "Por favor, selecciona el departamento",
  PROVINCE = "Por favor, selecciona la provincia",
  CANTON = "Por favor, selecciona el canton",
  ZIPCODE = "Ingresa un código postal válido",
  ZIPCODE_REQUIRED = "Ingresa un código postal",
  ADD_ZIPCODE = "Por favor, ingresa el código postal",
  MAX_ZIPCODE = "El máximo permitido de caracteres es",
  MIN_ZIPCODE = "El mínimo permitido de caracteres es",
  ADDRESS = "Ingresa una dirección válida",
  PHONE_NUMBER_EMPTY = "Ingresa un número válido",
  PHONE_NUMBER = "Ingresa el teléfono de la empresa",
  INPUT_CITY = "Por favor, ingresa una Ciudad",
  MUNICIPALITYBR = "Por favor, ingresa una Ciudad/Municipio",
  PROVINCE_ECUADOR = "Por favor, selecciona una provincia",
  TAX_PAYER_ID = "Por favor, selecciona un Tipo de Contribuyente",
  ACTIVITY = "Por favor, selecciona una Actividad",
  INCOME_SOURCE_CONCEPT_ID = "Por favor, selecciona un Concepto de retención de la renta",
  VALID_PHONE_NUMBER = "Ingresa un teléfono válido.",
  ADD_PHONE_NUMBER = "Por favor, ingresa un teléfono.",
}

export enum RegularExp {
  CAPITAL_LETTER = "^[A-Z\\s]+$",
  CAPITAL_LETTER_NUMBERS = "[A-Z0-9ÁÉÍÓÚÜ ]+$",
  ONLY_LETTERS = "^[a-zA-ZñÑÁÉÍÓÚáéíóúÜü\\s]*$",
  ONLY_NUMBERS = "^[0-9]+$",
  PHONE_NUMBER = "000 000 000 ",
  WEB = "[^]",
  LETTER_NUMBERS = "[a-zA-Z0-9]+$",
}

export enum BasicDataLabels {
  RAZON_SOCIAL = "Razón Social",
  RFC = "Clave del RFC",
  TIPO_EMPRESA = "Tipo de empresa o sociedad mercantil",
  TIPO_EMPRESA_USA = "Tipo de empresa",
  TIPO_INDUSTRIA = "Tipo de industria",
  TIPO_TAXID = "Tipo TAX ID",
  NUMBER_TAXID = "Número de TAX ID",
  EIN = "Número de identificación del empleador (EIN)",
  MCC = "MCC",
  FECHA_CONST = "Fecha de constitución",
  WEB = "Sitio web",
  NOMBRE = "Nombre(s)",
  APELLIDO = "Apellido(s)",
  DATOS = "Datos básicos",
  INFO_PRINCIPAL = "Información principal",
  TIPO_PERSONA = "Tipo de persona",
  INFO_BASICA = "Información básica",
  INFO_NEGOCIO = "Información del negocio",
  PAIS_CONST = "País de constitución:",
  TIPO_EMPRESA_GENERIC = "Indique el tipo de empresa o sociedad mercantil",
  ECONOMIC_ACTIVITY_GENERIC = "Indique la actividad económica o giro comercial",
  COMPANY_REGISTRATION_NUMBER = "Número de Registro de compañía",
}

export enum TypeElement {
  TEXT = "text",
  TEXT_CAPITAL = "textCapital",
  TEXT_COMBO = "textCombo",
  WEB = "web",
  DATE_PICKER = "datePicker",
  COMBO = "combo",
  COMBO_ARRAY = "comboArray",
  GENERIC = "generic",
  GENERIC_ACTIVITY = "genericActivity",
}

export enum TypeListMX {
  MCC = "mcc",
  ECONOMY_ACTIVITY = "economyActivity",
  INDUSTRY = "typeIndustry",
  COMPANY = "typeCompany",
  TAX_ID_EC = "typeOfTaxId",
  ICA_RETENTIONS = "retentionICA",
}

export enum BillingLabelsEC {
  aggregateModel = "¿Este customer tendrá activo el medio de pago tarjeta bajo el modelo agregador?",
}
