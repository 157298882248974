import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetNodeResponse } from "../../../../types/getNodeResponse";
import { GetParentNodeByMerchantIdRequest } from "../../../../types/getParentNodeByMerchantIdRequest";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";

const getParentNodeByMerchantId = createAsyncThunk<
  GetNodeResponse,
  GetParentNodeByMerchantIdRequest
>("layout/getParentNodeByMerchantId", async (payload) => {
  const response = await axios.post<GetNodeResponse>(
    API_ROUTES.HIERARCHY_GET_FIRST_PARENT,
    payload
  );

  return response.data;
});

const findNodeByNodeId = createAsyncThunk<GetNodeResponse, { nodeId: string }>(
  "layout/findNodeByNodeId",
  async ({ nodeId }) => {
    const response = await axios.get<GetNodeResponse>(
      API_ROUTES.HIERARCHY_FIND_NODE_BY_NODE_ID(nodeId)
    );

    return response.data;
  }
);

const getNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  { publicMerchantId: string }
>("general/nodeInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<GetNodeInfoResponse>(API_ROUTES.NODE_INFO, {
    publicMerchantId,
  });

  return response.data;
});

export { findNodeByNodeId, getParentNodeByMerchantId, getNodeInfo };
