import { IDefaultProcessorOption } from "../interfaces/IDefaultProcessorOption";

export enum DEFAULT_PROCESSOR_LABELS {
  CARD = "Tarjeta Pay In",
  TRANSFER_OUT = "Transferencia Pay Out",
  TRANSFER_IN = "Transferencia Pay In",
  ACH = "Débito recurrente",
}

export enum PROCESSOR_TYPE_ID {
  CARD = "card",
  TRANSFER_OUT = "transferPayout",
  TRANSFER_IN = "transfer",
  ACH = "ach",
}
export const CONFIGURATION_LABELS: IDefaultProcessorOption[] = [
  {
    label: DEFAULT_PROCESSOR_LABELS.CARD,
    id: PROCESSOR_TYPE_ID.CARD,
  },
  {
    label: DEFAULT_PROCESSOR_LABELS.TRANSFER_OUT,
    id: PROCESSOR_TYPE_ID.TRANSFER_OUT,
  },
  {
    label: DEFAULT_PROCESSOR_LABELS.TRANSFER_IN,
    id: PROCESSOR_TYPE_ID.TRANSFER_IN,
  },
  {
    label: DEFAULT_PROCESSOR_LABELS.ACH,
    id: PROCESSOR_TYPE_ID.ACH,
  },
];

export const DISABLED_OPTION: string = "Seleccionar procesador";

export enum CONFIG_DEFAULT_PROCESSOR {
  HEADER = " Configuración de procesador por defecto",
  CANCEL = "Cancelar",
  CONFIGURE = "Configurar",
}
