import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";
export const infoHelperStyles: IStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  infoText: {
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "-0.015em",
    color: "text.dark",
    fontStyle: "normal",
    fontFamily: "typography.fontFamily",
  },
};
