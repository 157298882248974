/**
 * Catalog Iban Countries
 */
export enum IbanCountriesEnum {
  ANDORRA = "Andorra",
  AUSTRIA = "Austria",
  BIELORRUSIA = "Bielorrusia",
  BELGICA = "Belgica",
  BULGARIA = "Bulgaria",
  CHIPRE = "Chipre",
  REPUBLICA_CHECA = "Republica Checa",
  DINAMARCA = "Dinamarca",
  ESTONIA = "Estonia",
  FINLANDIA = "Finlandia",
  FRANCIA = "Francia",
  ALEMANIA = "Alemania",
  GRECIA = "Grecia",
  HUNGRIA = "Hungria",
  ISLANDIA = "Islandia",
  IRLANDA = "Irlanda",
  ITALIA = "Italia",
  KOSOVO = "Kosovo",
  LETONIA = "Letonia",
  LITUANIA = "Lituania",
  LIECHTENSTEIN = "Liechtenstein",
  LUXEMBURGO = "Luxemburgo",
  MALTA = "Malta",
  MONACO = "Monaco",
  PAISES_BAJOS = "Paises Bajos",
  NORUEGA = "Noruega",
  POLONIA = "Polonia",
  PORTUGAL = "Portugal",
  SAN_MARINO = "San Marino",
  ESLOVAQUIA = "Eslovaquia",
  ESLOVENIA = "Eslovenia",
  ESPANIA = "España",
  SUECIA = "Suecia",
  SUIZA = "Suiza",
  REINO_UNIDO = "Reino Unido",
  CIUDAD_DEL_VATICANO = "Ciudad del Vaticano",
  COSTARICA = "CostaRica",
  GIBRALTAR = "Gibraltar",
  LIBANO = "Libano",
  EMIRATOS_ARABES_UNIDOS = "Emiratos Arabes Unidos",
}
export const IBAN_COUNTRIES: string[] = [...Object.values(IbanCountriesEnum)];
