import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const formSummaryStyles = createNamedStyles({
  amountLabel: {
    display: "flex",
    justifyContent: "end",
  },
  groupButtonMobile: {
    ".action-button": {
      alignSelf: "center",
      marginTop: "10px",
    },
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    marginTop: "20px",
  },
  groupButtonWeb: {
    ".action-button": {
      marginLeft: "10px",
    },
    display: "flex",
    justifyContent: "end",
    marginTop: "30px",
  },
  titleLabel: {
    paddingBottom: 1,
  },
});
