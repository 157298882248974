import { useSearchParams } from "react-router-dom";
import { TransactionType } from "../../../shared/enums/transaction_types";
import { IUseMainContainer } from "./useMainContainer.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getMerchantNodeInfo } from "../../../store/thunks/app/app.thunks";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { ConfigIdEnum } from "../../../shared/enums/config_id_nodes";
import { ISubheaderProps } from "../../../components/Subheader/Subheader.interfaces";
import {
  buildSubheaderInfo,
  defaultSubheaderValues,
  MERCHANT_QUERY_PARAM,
  TRANSACTION_TYPE_QUERY_PARAM,
} from "../../../shared/constants/main_container_constants";
import { getCurrencyByCountry } from "../../../shared/enums/currencies";

export const useMainContainer = (): IUseMainContainer => {
  const [searchParams] = useSearchParams();
  const { merchantNodeInfo } = useAppSelector(({ app }) => ({
    ...app,
  }));
  const dispatch = useAppDispatch();
  const publicMerchantId = searchParams.get(MERCHANT_QUERY_PARAM) || "";
  const transactionType =
    searchParams.get(TRANSACTION_TYPE_QUERY_PARAM) || TransactionType.CREDIT;
  const [subheaderInfo, setSubheaderInfo] = useState<ISubheaderProps>(
    defaultSubheaderValues
  );
  const [currency, setCurrency] = useState<string>();

  const requestMerchantNodeInfo = (publicMerchantId: string) => {
    dispatch(
      getMerchantNodeInfo({
        configId: ConfigIdEnum.CN001,
        publicMerchantId,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(publicMerchantId)) return;

    requestMerchantNodeInfo(publicMerchantId!);
  }, [publicMerchantId]);

  useEffect(() => {
    if (isEmpty(merchantNodeInfo)) return;

    setSubheaderInfo(buildSubheaderInfo(merchantNodeInfo!));
    setCurrency(getCurrencyByCountry[merchantNodeInfo!.country]);
  }, [merchantNodeInfo]);

  return {
    currency,
    publicMerchantId,
    subheaderInfo,
    transactionType,
  };
};
