import { useSelector } from "react-redux";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { useEffect, useState } from "react";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { IGetMerchantBillingDataResponse } from "../../../../types/get_merchant_billing_data_response";
import { CountriesEnum } from "../../../shared/infrastructure/countries-enum";
import { CatalogsEnum } from "../../../shared/infrastructure/catalogs-enum";
import {
  Category,
  getCatalog,
} from "../../../shared/constants/MerchantInformationConstants";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import {
  getSectionInfo,
  statusNodeInfo,
} from "../../../shared/utils/node_info";

export enum LabelTexts {
  TAX_REGIME = "Régimen fiscal",
  POSTAL_CODE = "Código Postal",
  ADDRESS = "Dirección",
  PHONE = "Celular",
  POPULATION_KEY = "Clave de población",
  STATE_KEY = "Clave de estado",
  FISCAL_POSTAL_CODE = "Código postal fiscal",
  DEPARTMENT = "Departamento",
  PROVINCE = "Provincia",
  CITY = "Ciudad",
  DISTRICT = "Distrito",
  COMPANY_PHONE = "Teléfono de la empresa",
  MUNICIPALITY = "Municipio",
  REGIME = "Régimen",
  OBLIGATIONS = "Obligaciones",
  REGION = "Región",
  COMMUNE = "Comuna",
  STATE = "Estado",
  CANTON = "Cantón",
}

interface Items {
  label: string;
  text: string;
  secondaryText: string;
}

export const useBillingData = () => {
  const nodeInfo = useSelector(selectNodeInfo);
  const [billingInfo, setBillingInfo] =
    useState<IGetMerchantBillingDataResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Array<Items>>([]);
  const [itemsSat, setItemsSat] = useState<Array<Items>>([]);
  const [itemsDian, setItemsDian] = useState<Array<Items>>([]);
  const [showBillingDetail, setShowBillingDetail] = useState<boolean>(false);

  useEffect(() => {
    const callApi = async (publicMerchantId: string) => {
      let result = await axios.post<IGetMerchantBillingDataResponse>(
        API_ROUTES.BILLING_CORE_MERCHANT_NODE_INFO,
        {
          configId: ConfigurationIdEnum.CN016,
          publicMerchantId: publicMerchantId,
        }
      );

      setBillingInfo(result.data);
      setIsLoading(false);
    };

    if (nodeInfo) {
      if (
        nodeInfo.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfo.entityName === EntityNameEnum.BRANCH
      ) {
        let sectionInfo = getSectionInfo(
          ConfigurationIdEnum.CN016,
          nodeInfo.configs
        );

        if (sectionInfo) {
          if (sectionInfo.status === statusNodeInfo.COMPLETE) {
            setShowBillingDetail(true);
            callApi(sectionInfo.value);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [nodeInfo]);

  const getDataMEXICO = () => {
    setItems([
      {
        label: LabelTexts.TAX_REGIME,
        secondaryText: "",
        text: billingInfo?.taxRegime ? billingInfo?.taxRegime : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.POPULATION_KEY,
        secondaryText: "",
        text: billingInfo?.populationCode ? billingInfo?.populationCode : "",
      },
      {
        label: LabelTexts.STATE_KEY,
        secondaryText: "",
        text: billingInfo?.stateCode ? billingInfo?.stateCode : "",
      },
    ]);
  };
  const getDataECUADOR = () => {
    let citiesArr: Category[] = getCatalog(
      CountriesEnum.ECUADOR,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    let provincesArr: Category[] = getCatalog(
      CountriesEnum.ECUADOR,
      CatalogsEnum.Provinces
    );
    let province: string =
      provincesArr.find((prov) => prov.value === billingInfo?.province)?.name ||
      "";

    setItems([
      {
        label: LabelTexts.PROVINCE,
        secondaryText: "",
        text: province,
      },
      {
        label: LabelTexts.CITY,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataBRAZIL = () => {
    let statesArr: Category[] = getCatalog(
      CountriesEnum.BRAZIL,
      CatalogsEnum.Provinces
    );
    let state: string =
      statesArr.find((prov) => prov.value === billingInfo?.province)?.name ||
      "";

    setItems([
      {
        label: LabelTexts.STATE,
        secondaryText: "",
        text: state,
      },
      {
        label: LabelTexts.MUNICIPALITY,
        secondaryText: "",
        text: billingInfo?.city ? billingInfo?.city : "",
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataCHILE = () => {
    let regionsArr: Category[] = getCatalog(
      CountriesEnum.CHILE,
      CatalogsEnum.Departments
    );
    let region: string =
      regionsArr.find((dep) => dep.value === billingInfo?.department)?.name ||
      "";

    let provincesArr: Category[] = getCatalog(
      CountriesEnum.CHILE,
      CatalogsEnum.Provinces
    );
    let province: string =
      provincesArr.find((prov) => prov.value === billingInfo?.province)?.name ||
      "";

    let communesArr: Category[] = getCatalog(
      CountriesEnum.CHILE,
      CatalogsEnum.Cities
    );
    let commune: string =
      communesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    setItems([
      {
        label: LabelTexts.REGION,
        secondaryText: "",
        text: region,
      },
      {
        label: LabelTexts.PROVINCE,
        secondaryText: "",
        text: province,
      },
      {
        label: LabelTexts.COMMUNE,
        secondaryText: "",
        text: commune,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataCOLOMBIA = () => {
    let departmentsArr: Category[] = getCatalog(
      CountriesEnum.COLOMBIA,
      CatalogsEnum.Provinces
    );
    let department: string =
      departmentsArr.find((dep) => dep.value === billingInfo?.province)?.name ||
      "";

    let citiesArr: Category[] = getCatalog(
      CountriesEnum.COLOMBIA,
      CatalogsEnum.Cities
    );
    let municipality: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    setItems([
      {
        label: LabelTexts.DEPARTMENT,
        secondaryText: "",
        text: department,
      },
      {
        label: LabelTexts.MUNICIPALITY,
        secondaryText: "",
        text: municipality,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataCOSTA_RICA = () => {
    let provincesArr: Category[] = getCatalog(
      CountriesEnum.COSTA_RICA,
      CatalogsEnum.Departments
    );
    let province: string =
      provincesArr.find((prov) => prov.value === billingInfo?.department)
        ?.name || "";

    let cantonsArr: Category[] = getCatalog(
      CountriesEnum.COSTA_RICA,
      CatalogsEnum.Provinces
    );
    let canton: string =
      cantonsArr.find((cant) => cant.value === billingInfo?.province)?.name ||
      "";

    let districtsArr: Category[] = getCatalog(
      CountriesEnum.COSTA_RICA,
      CatalogsEnum.Cities
    );
    let district: string =
      districtsArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    setItems([
      {
        label: LabelTexts.PROVINCE,
        secondaryText: "",
        text: province,
      },
      {
        label: LabelTexts.CANTON,
        secondaryText: "",
        text: canton,
      },
      {
        label: LabelTexts.DISTRICT,
        secondaryText: "",
        text: district,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataEL_SALVADOR = () => {
    let departmentsArr: Category[] = getCatalog(
      CountriesEnum.EL_SALVADOR,
      CatalogsEnum.Provinces
    );
    let department: string =
      departmentsArr.find((dep) => dep.value === billingInfo?.province)?.name ||
      "";

    let municipalitiesArr: Category[] = getCatalog(
      CountriesEnum.EL_SALVADOR,
      CatalogsEnum.Cities
    );
    let municipality: string =
      municipalitiesArr.find((cit) => cit.value === billingInfo?.city)?.name ||
      "";

    setItems([
      {
        label: LabelTexts.DEPARTMENT,
        secondaryText: "",
        text: department,
      },
      {
        label: LabelTexts.MUNICIPALITY,
        secondaryText: "",
        text: municipality,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataPANAMA = () => {
    let citiesArr: Category[] = getCatalog(
      CountriesEnum.PANAMA,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    let provincesArr: Category[] = getCatalog(
      CountriesEnum.PANAMA,
      CatalogsEnum.Provinces
    );
    let province: string =
      provincesArr.find((prov) => prov.value === billingInfo?.province)?.name ||
      "";

    setItems([
      {
        label: LabelTexts.PROVINCE,
        secondaryText: "",
        text: province,
      },
      {
        label: LabelTexts.DISTRICT,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataPERU = () => {
    let departmentsArr: Category[] = getCatalog(
      CountriesEnum.PERU,
      CatalogsEnum.Departments
    );
    let department: string =
      departmentsArr.find((dep) => dep.value === billingInfo?.department)
        ?.name || "";

    let citiesArr: Category[] = getCatalog(
      CountriesEnum.PERU,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    let provincesArr: Category[] = getCatalog(
      CountriesEnum.PERU,
      CatalogsEnum.Provinces
    );
    let province: string =
      provincesArr.find((prov) => prov.value === billingInfo?.province)?.name ||
      "";

    setItems([
      {
        label: LabelTexts.DEPARTMENT,
        secondaryText: "",
        text: department,
      },
      {
        label: LabelTexts.DISTRICT,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.PROVINCE,
        secondaryText: "",
        text: province,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataGUATEMALA = () => {
    let provincesArr: Category[] = getCatalog(
      CountriesEnum.GUATEMALA,
      CatalogsEnum.Provinces
    );
    let department: string =
      provincesArr.find((dep) => dep.value === billingInfo?.province)?.name ||
      "";

    let citiesArr: Category[] = getCatalog(
      CountriesEnum.GUATEMALA,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    setItems([
      {
        label: LabelTexts.DEPARTMENT,
        secondaryText: "",
        text: department,
      },
      {
        label: LabelTexts.CITY,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataNICARAGUA = () => {
    let departmentsArr: Category[] = getCatalog(
      CountriesEnum.NICARAGUA,
      CatalogsEnum.Provinces
    );
    let department: string =
      departmentsArr.find((dep) => dep.value === billingInfo?.province)?.name ||
      "";

    let citiesArr: Category[] = getCatalog(
      CountriesEnum.NICARAGUA,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    setItems([
      {
        label: LabelTexts.DEPARTMENT,
        secondaryText: "",
        text: department,
      },
      {
        label: LabelTexts.MUNICIPALITY,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataHONDURAS = () => {
    let departmentsArr: Category[] = getCatalog(
      CountriesEnum.HONDURAS,
      CatalogsEnum.Provinces
    );
    let department: string =
      departmentsArr.find((dep) => dep.value === billingInfo?.province)?.name ||
      "";

    let citiesArr: Category[] = getCatalog(
      CountriesEnum.HONDURAS,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    setItems([
      {
        label: LabelTexts.DEPARTMENT,
        secondaryText: "",
        text: department,
      },
      {
        label: LabelTexts.MUNICIPALITY,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };
  const getDataESTADOS_UNIDOS = () => {
    let citiesArr: Category[] = getCatalog(
      CountriesEnum.ESTADOS_UNIDOS,
      CatalogsEnum.Cities
    );
    let city: string =
      citiesArr.find((cit) => cit.value === billingInfo?.city)?.name || "";

    let statesArr: Category[] = getCatalog(
      CountriesEnum.ESTADOS_UNIDOS,
      CatalogsEnum.Provinces
    );
    let state: string =
      statesArr.find((prov) => prov.value === billingInfo?.province)?.name ||
      "";

    setItems([
      {
        label: LabelTexts.STATE,
        secondaryText: "",
        text: state,
      },
      {
        label: LabelTexts.CITY,
        secondaryText: "",
        text: city,
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };

  const getDataGenericCountry = () => {
    setItems([
      {
        label: LabelTexts.CITY,
        secondaryText: "",
        text: billingInfo?.city ? `${billingInfo?.city}` : "",
      },
      {
        label: LabelTexts.COMPANY_PHONE,
        secondaryText: "",
        text: billingInfo?.phoneNumber ? `+${billingInfo?.phoneNumber}` : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.address ? billingInfo?.address : "",
      },
      {
        label: LabelTexts.POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.zipCode ? billingInfo?.zipCode : "",
      },
    ]);
  };

  const getItemsSat = () => {
    setItemsSat([
      {
        label: LabelTexts.FISCAL_POSTAL_CODE,
        secondaryText: "",
        text: billingInfo?.sat?.zipCode ? billingInfo?.sat?.zipCode : "",
      },
      {
        label: LabelTexts.ADDRESS,
        secondaryText: "",
        text: billingInfo?.sat?.address ? billingInfo?.sat?.address : "",
      },
      {
        label: LabelTexts.PHONE,
        secondaryText: "",
        text: billingInfo?.sat?.phoneNumber
          ? billingInfo?.sat?.phoneNumber
          : "",
      },
      {
        label: LabelTexts.POPULATION_KEY,
        secondaryText: "",
        text: billingInfo?.sat?.populationCode
          ? billingInfo?.sat?.populationCode
          : "",
      },
      {
        label: LabelTexts.STATE_KEY,
        secondaryText: "",
        text: billingInfo?.sat?.stateCode ? billingInfo?.sat?.stateCode : "",
      },
    ]);
  };

  const getItemsDian = () => {
    let regimesArr: Category[] = getCatalog(
      CountriesEnum.COLOMBIA,
      CatalogsEnum.Regime
    );
    let regime: string =
      regimesArr.find((reg) => reg.value === billingInfo?.taxRegimeId)?.name ||
      "";

    let obligationsArr: Category[] = getCatalog(
      CountriesEnum.COLOMBIA,
      CatalogsEnum.Obligations
    );
    let obligation: string =
      obligationsArr.find((reg) => reg.value === billingInfo?.obligation)
        ?.name || "";

    setItemsDian([
      {
        label: LabelTexts.REGIME,
        secondaryText: "",
        text: regime,
      },
      {
        label: LabelTexts.OBLIGATIONS,
        secondaryText: "",
        text: obligation,
      },
    ]);
  };

  useEffect(() => {
    if (billingInfo) {
      switch (billingInfo.constitutionalCountry) {
        case CountriesEnum.ECUADOR:
          getDataECUADOR();
          break;
        case CountriesEnum.BRAZIL:
          getDataBRAZIL();
          break;
        case CountriesEnum.CHILE:
          getDataCHILE();
          break;
        case CountriesEnum.COLOMBIA:
          getItemsDian();
          getDataCOLOMBIA();
          break;
        case CountriesEnum.COSTA_RICA:
          getDataCOSTA_RICA();
          break;
        case CountriesEnum.EL_SALVADOR:
          getDataEL_SALVADOR();
          break;
        case CountriesEnum.MEXICO:
          getItemsSat();
          getDataMEXICO();
          break;
        case CountriesEnum.PANAMA:
          getDataPANAMA();
          break;
        case CountriesEnum.PERU:
          getDataPERU();
          break;
        case CountriesEnum.GUATEMALA:
          getDataGUATEMALA();
          break;
        case CountriesEnum.NICARAGUA:
          getDataNICARAGUA();
          break;
        case CountriesEnum.HONDURAS:
          getDataHONDURAS();
          break;
        case CountriesEnum.ESTADOS_UNIDOS:
          getDataESTADOS_UNIDOS();
          break;
        default:
          getDataGenericCountry();
          break;
      }
    }
  }, [billingInfo]);

  return {
    billingInfo,
    isLoading,
    items,
    itemsDian,
    itemsSat,
    showBillingDetail,
  };
};
