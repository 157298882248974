import { PayoutMerchantResponse } from "../../types/payout_merchant_response";
import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { FilterWalletDashboardResponse } from "../../types/filter_wallet_dashboard_response";
import { FilterWalletDashboardRequest } from "../../types/filter_wallet_dashboard_request";
import { BalanceResponse } from "../../types/balance_response";
import { IDownloadErrorType } from "../shared/infrastructure/interfaces/IDownloadError";
import { SearchTypeEnum } from "../shared/infrastructure/enums/SearchTypeEnum";

export interface IAppState {
  notification: INotification;
  loading: boolean;
  isLoadingGetTransactions?: boolean;
  isDownloadingFile?: boolean;
  searchType?: SearchTypeEnum;
  getTransactionsResponse?: FilterWalletDashboardResponse;
  showAlertOnGetTransactions?: boolean;
  filterWalletDashboardRequest?: FilterWalletDashboardRequest;
  balanceResponse: BalanceResponse;
  getSignedUrlResponse?: string;
  downloadError?: IDownloadErrorType;
  isReportGenerated?: boolean;
  isDateRangeError?: boolean;
  showAlertOnDownloadFileError?: boolean;
  showAlertOnDownloadFileSuccess?: boolean;
  id?: string;
  tableSize?: number;
  currentPage?: number;
  totalRecords?: number;
  merchantPayout: PayoutMerchantResponse;
}

export const INITIAL_STATE: IAppState = {
  getTransactionsResponse: {
    data: [],
    total: 0,
  },
  isLoadingGetTransactions: false,
  balanceResponse: {
    balance: 0,
    countableBalance: 0,
    unprocessedBalance: 0,
  },
  loading: false,
  notification: {
    message: "",
    open: false,
    type: "success",
  },
  showAlertOnGetTransactions: false,
  filterWalletDashboardRequest: undefined,
  getSignedUrlResponse: undefined,
  isDownloadingFile: false,
  merchantPayout: { isPayout: false, country: "" },
  searchType: SearchTypeEnum.DATE_RANGE,
  isReportGenerated: false,
  showAlertOnDownloadFileError: false,
  showAlertOnDownloadFileSuccess: false,
  downloadError: {
    code: "",
    type: "",
    message: "",
  },
  id: "",
  tableSize: 10,
  currentPage: 1,
  totalRecords: 0,
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return <IAppState>{
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_IS_LOADING_GET_TRANSACTIONS:
      return {
        ...state,
        isLoadingGetTransactions: action.isLoadingGetTransactions,
      };
    case ActionTypes.SET_WALLET_DASHBOARD_RESPONSE:
      return {
        ...state,
        getTransactionsResponse: action.getTransactionsResponse,
      };
    case ActionTypes.SET_SHOW_ALERT_ON_GET_TRANSACTIONS:
      return {
        ...state,
        showAlertOnGetTransactions: action.showAlertOnGetTransactions,
      };
    case ActionTypes.SET_FILTER_WALLET_DASHBOARD_REQUEST:
      return {
        ...state,
        filterWalletDashboardRequest: action.filterWalletDashboardRequest,
      };

    case ActionTypes.SET_BALANCE:
      return <IAppState>{
        ...state,
        balanceResponse: action.balanceResponse,
      };
    case ActionTypes.SET_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadError: action.downloadError,
      };
    case ActionTypes.SET_IS_DOWNLOADING_FILE:
      return {
        ...state,
        isDownloadingFile: action.isDownloadingFile,
      };
    case ActionTypes.SET_SIGNED_URL_RESPONSE:
      return {
        ...state,
        getSignedUrlResponse: action.getSignedUrlResponse,
      };
    case ActionTypes.SET_SHOW_ALERT_ON_DOWNLOAD_ERROR:
      return {
        ...state,
        showAlertOnDownloadFileError: action.showAlertOnDownloadFileError,
      };
    case ActionTypes.SET_SHOW_ALERT_ON_DOWNLOAD_SUCCESS:
      return {
        ...state,
        showAlertOnDownloadFileSuccess: action.showAlertOnDownloadFileSuccess,
      };
    case ActionTypes.SET_TABLE_SIZE:
      return {
        ...state,
        tableSize: action.tableSize,
      };
    case ActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case ActionTypes.SET_TOTAL_RECORDS:
      return {
        ...state,
        totalRecords: action.totalRecords,
      };
    case ActionTypes.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      };
    case ActionTypes.SET_IS_REPORT_GENERATED:
      return {
        ...state,
        isReportGenerated: action.isReportGenerated,
      };
    case ActionTypes.SET_IS_DATE_RANGE_ERROR:
      return {
        ...state,
        isDateRangeError: action.isDateRangeError,
      };
    case ActionTypes.SET_PAYOUT_MERCHANT:
      return <IAppState>{
        ...state,
        merchantPayout: action.merchantPayout,
      };
    default:
      return state;
  }
};
