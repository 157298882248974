import { createNamedStyles } from "../../../shared/infrastructure/interfaces/create_named_styles";

const ModalSimpleStyles = createNamedStyles({
  modalContainer: {
    alignItems: "center",
    backgroundColor: "background.default",
    borderRadius: "8px",
    flexDirection: "row",
    left: "50%",
    margin: "0px 0px",
    minHeight: "auto",
    minWidth: { md: "775px", sm: "370px", xs: "280px" },
    position: "absolute" as "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    ".MuiTypography-h3": {
      width: "80%",
    },
    ".modalHeader": {
      padding: { md: "40px", xs: "24px" },
      paddingBottom: { md: "45px" },
      ".itemsTop": {
        alignItems: "center",
        direction: "row",
        justifyContent: "space-between",
      },
      ".itemsBottom": {
        paddingTop: { md: "18px", xs: "16px" },
      },
      ".headerIconsContainer": {
        alignItems: "center",
        display: "flex",
        ".titleTag": {
          marginLeft: "10px",
        },
        ".iconWrapper": {
          height: "100%",
          alignItems: "center",
          display: "flex",
        },
        ".headerRightIcon": {
          marginLeft: "14px",
        },
        ".headerLeftIcon": {
          marginRight: "14px",
        },
      },
    },
    ".modalFooter": {
      backgroundColor: "background.paper",
      borderRadius: "8px",
      padding: "16px",
      ".itemsButtons": {
        alignItems: "center",
        direction: "row",
        justifyContent: "flex-end",
      },
    },
  },
});

export { ModalSimpleStyles };
