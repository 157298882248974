import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IUseCreateRuleState } from "../../../shared/infrastructure/interfaces/IUseCreateRuleState";
import {
  createRuleGeneral,
  getCountries,
  getGeographyList,
  getListCatalogs,
  searchRequestRuleById,
  searchRuleById,
  sendRuleRequest,
  setAvailableConditions,
  setGeneralRule,
  setIsEditedRule,
  setIsLoading,
  setOpenAlert,
  setOpenConfirmGeneralRuleModal,
  setOpenConfirmModal,
  setOpenModalInfo,
  setRuleDetails,
  setSearchFilter,
  setSecurityRule,
  setSelectedTabIndex,
  setSuccessfulEndpointCall,
  updateRuleRequest,
} from "../../../store/actionCreators";
import {
  Conditions,
  ConfigurationResponse,
  TransactionType,
} from "../../../../types/configuration_response";
import { IAppState } from "../../../store/reducer";
import { Category } from "../../../shared/infrastructure/interfaces/Category";
import {
  IConditionForm,
  ISecurityRuleForm,
} from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  ArrayField,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useHistory } from "react-router";
import { GeographyList } from "../../../../types/geography_list";
import { RuleDetail } from "../../../../types/rule_detail";
import { defaultTo, find, get, isEmpty, isUndefined, set } from "lodash";
import { RuleRequest } from "../../../../types/rule_request";
import { FieldsRule } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import {
  Condition,
  SecurityRuleData,
} from "../../../../types/security_rule_data";
import { Routes } from "../../../shared/infrastructure/routes";
import { HierachyNodesResponse } from "../../../../types/hierachy_nodes_response";
import {
  buildConditionsForEditedRule,
  buildGenerateConditions,
  buildObjectToResetForm,
  buildObjectToResetFormForEditedRule,
  checkIsGeneralRule,
  getAvailableConditions,
  isFormEditMode,
  mapRules,
  mapRuleValues,
  transformCardName,
} from "../../../utils/utilsFile";
import { SearchRequestRuleByIdResponse } from "../../../../types/search_request_rule_by_id_response";
import {
  ACTION_VALUE,
  ActionEnum,
  GeneralActionEnum,
  OPERATORS,
  VALID_GENERAL_ACTIONS,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import {
  GroupEnum,
  RulesSchema,
  ServiceEnum,
  SubTypeEnum,
  TypeToSubtypeRules,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { UpdateRuleRequest } from "../../../../types/update_rule_request";
import { useLocation, useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import { CategoryData, useSnackbar } from "@kushki/connect-ui";
import { Country } from "../../../../types/country_response";
import { TabIndexEnum } from "../../../shared/infrastructure/enums/TabIndexEnum";
import { RuleGeneralRequest } from "../../../../types/rule_general_request";
import { SearchRuleCatalogResponse } from "../../../../types/search_rule_catalog_response";
import {
  IModalConfirmTemplate,
  LabelEnum,
  TextModalConfirm,
} from "../../../shared/infrastructure/enums/LabelEnum";
import { GetRuleByIdResponse } from "../../../../types/get_rule_by_id";
import {
  HierarchyPathsEnum,
  PathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  AlertEnum,
  ERROR_MESSAGE_ALERT,
  SUCCESS_MESSAGE_ALERT,
} from "../../../shared/infrastructure/enums/AlertEnum";
import { ErrorOutline } from "@mui/icons-material";
import { StylesEnum } from "../../../shared/infrastructure/enums/StylesEnum";
import CheckIcon from "@mui/icons-material/Check";
import {
  TransactionTypeEnum,
  ValueEnum,
} from "../../../shared/infrastructure/enums/ModalEnum";
import { ConfigurationTypeEnum } from "../../../shared/infrastructure/enums/ConfigurationTypeEnum";
import { RequestTypeEnum } from "../../../shared/infrastructure/enums/RequestTypeEnum";
import { ProcessorsInformation } from "../../../../types/processors_list";
import { merchantCategoryCatalog } from "../../../shared/infrastructure/constants/MerchantCategory";
import { CatalogData } from "../../../../types/catalog_data";
import { ConfigCodeEnum } from "../../../shared/infrastructure/enums/ConfigCodeEnum";
import { ProductLineEnum } from "../../../shared/infrastructure/enums/ProductLineEnum";
import { CountryCode } from "../../../shared/infrastructure/enums/CountryCodeEnum";
import { Data } from "../../../../types/catalog_response";
import { getCatalogByName } from "../../../shared/utils/listCatalogUtils";
import { TypeCatalog } from "../../../shared/infrastructure/enums/TypeCatalog";

export const useCreateRuleState = (): IUseCreateRuleState => {
  const dispatch = useDispatch();
  const [isDisabledSpeed, setIsDisabledSpeed] = useState(false);
  const [isDisabledSpecial, setIsDisabledSpecial] = useState(true);
  const [branchValue, setBranchValue] = useState(0);
  const [customerValue, setCustomerValue] = useState(0);
  const { ruleId, status, ruleName } = useParams<IUseParams>();
  const urlPath = useLocation();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const [defaultHierarchy, setDefaultHierarchy] = useState<
    CategoryData | object
  >({});
  const [defaultCountries, setDefaultCountries] = useState<string[]>([]);
  const [defaultService, setDefaultService] = useState<string>("");
  const [isUpdatedRule, setIsUpdatedRule] = useState<boolean>(false);
  const [isGeneralRule, setIsGeneralRule] = useState<boolean>(false);
  const [isSelectedGeneralRule, setIsSelectedGeneralRule] =
    useState<boolean>(false);
  const configurationValue: ConfigurationResponse | undefined = useSelector(
    (state: IAppState) => state.configuration
  );
  const geographyList: GeographyList | undefined = useSelector(
    (state: IAppState) => state.geographyList
  );
  const indexTab: number = useSelector((state: IAppState) =>
    defaultTo(state.selectedTabIndex, TabIndexEnum.PENDING_REVIEW_INDEX)
  );
  const processorsList: ProcessorsInformation[] | undefined = useSelector(
    (state: IAppState) => state.processorsList
  );
  const merchantCredentials: Category[] | undefined = useSelector(
    (state: IAppState) => state.credentials
  );
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const hierarchyNodesResponse: HierachyNodesResponse | undefined = useSelector(
    (state: IAppState) => state.affectedNodes
  );
  const rule: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.rule
  );
  const fetchSearchRuleById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );
  const fetchSearchRequestRuleById: SearchRequestRuleByIdResponse | undefined =
    useSelector((state: IAppState) => state.searchRequestRuleById);

  const selectedRule: RuleGeneralRequest | undefined = useSelector(
    (state: IAppState) => state.detailCatalogGeneralRules
  );
  const hierarchyNodesValues: HierachyNodesResponse | undefined = useSelector(
    (state: IAppState) => state.affectedNodes
  );
  const availableConditions: Conditions[] | undefined = useSelector(
    (state: IAppState) => state.availableConditions
  );
  const isLoading: boolean = useSelector((state: IAppState) =>
    defaultTo(state.isLoading, false)
  );
  const savingRule: boolean = useSelector((state: IAppState) =>
    defaultTo(state.savingRule, false)
  );
  const countries_array: Country[] | undefined = useSelector(
    (state: IAppState) => state.countries
  );
  const commerceType: string | undefined = useSelector(
    (state: IAppState) => state.commerceType
  );
  const [openAlert, isError, originAlert] = useSelector((state: IAppState) => {
    return [
      get(state, "openAlert.open", false),
      get(state, "openAlert.isError", false),
      get(state, "openAlert.origin", AlertEnum.ORIGIN_DEFAULT),
    ];
  });

  const successfulEndpointCall: boolean = useSelector((state: IAppState) =>
    defaultTo(state.successfulEndpointCall, false)
  );

  const actionType: string | undefined = useSelector(
    (state: IAppState) => state.actionType
  );

  const { isLoadingCatalog, listMccCatalog } = useSelector(
    (state: IAppState) => state
  );
  const sortConditions = (conditions: IConditionForm[]) => {
    return [
      ...conditions.filter((condition) => condition.label === LabelEnum.AMOUNT),
      ...conditions.filter((condition) => condition.label !== LabelEnum.AMOUNT),
    ];
  };

  useEffect(() => {
    if (checkIsGeneralRule(urlPath.pathname)) dispatch(getCountries());
  }, []);

  useEffect(() => {
    const isHierarchy: string =
      commerceType == SubTypeEnum.COMMERCE_WITH_HIERARCHY
        ? "HIERARCHY"
        : "NOT_HIERARCHY";

    if (openAlert) {
      showSnackbar({
        message: isError
          ? defaultTo(
              ERROR_MESSAGE_ALERT[`${originAlert}_${isHierarchy}`],
              ERROR_MESSAGE_ALERT[originAlert]
            )
          : SUCCESS_MESSAGE_ALERT[originAlert],
        variant: "simple",
        color: isError ? "danger" : "info",
        icon: isError ? (
          <ErrorOutline
            fontSize={StylesEnum.INHERIT}
            color={StylesEnum.INHERIT}
          />
        ) : (
          <CheckIcon fontSize={StylesEnum.INHERIT} color={StylesEnum.PRIMARY} />
        ),
        withIcon: true,
      });
      dispatch(setOpenAlert({ open: false, isError: false }));
    }
  }, [openAlert]);

  useEffect(() => {
    if (successfulEndpointCall)
      history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);

    dispatch(setSuccessfulEndpointCall(false));
  }, [successfulEndpointCall]);

  const form = useForm<ISecurityRuleForm>({
    mode: "all",
    defaultValues: {
      condition: [
        {
          value: "",
          label: "",
          operator: "",
        },
      ],
      specialCondition: [
        {
          value: "",
          label: "",
          operator: "",
        },
      ],
    },
  });
  const { watch } = form;
  const action = defaultTo(watch("generalAction"), "");
  const provider = defaultTo(watch("provider"), "");
  const concatenateCardHolderName = (cardHolderName: string): string => {
    const cardHolderNames: string[] = cardHolderName.split(",");

    return cardHolderNames
      .map((name: string) => transformCardName(name))
      .join(",");
  };

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "condition",
  });
  const handleSendToReview = (): void => {
    dispatch(setIsLoading(true));
    dispatch(setSearchFilter(undefined));
    const request: RuleRequest = {
      status: FieldsRule.STATUS_PENDING_CREATED,
      requestType: FieldsRule.REQUEST_TYPE_CREATION,
      entityName: get(ruleDetails, "entityName", "").toLowerCase(),
      configType: FieldsRule.CONFIG_TYPE,
      merchantName: get(ruleDetails, "merchantName", ""),
      merchantId: get(ruleDetails, "merchantId", ""),
      country: get(ruleDetails, "country", []),
      regions: [],
      continents: [],
      subType: get(ruleDetails, "subType", ""),
      service: ServiceEnum.CARD,
      rule: {
        hasSpecialCond: get(rule, "hasSpecialCond", false),
        action:
          ACTION_VALUE[
            `${defaultTo(get(rule, "generalAction"), "")}${
              defaultTo(get(rule, "generalAction"), "") ===
              GeneralActionEnum.AUTHENTICATE
                ? `_${defaultTo(get(rule, "provider"), "")}`
                : ""
            }`
          ],
        generalAction: defaultTo(get(rule, "generalAction"), ""),
        code: defaultTo(get(rule, "code"), ""),
        message: defaultTo(get(rule, "message"), ""),
        name: defaultTo(get(rule, "name"), ""),
        kind: defaultTo(get(rule, "kind"), Values.RULES_KIND_STRICT),
        maxAmountCurrency: defaultTo(get(rule, "speedCondition.currency"), ""),
        periodicitySum: defaultTo(
          get(rule, "hasSpecialCond", false)
            ? get(rule, "speedCondition.periodicity")
            : get(rule, "periodicity"),
          ""
        ),
        periodicityCount: defaultTo(
          get(rule, "hasSpecialCond", false)
            ? get(rule, "speedCondition.periodicity")
            : get(rule, "periodicity"),
          ""
        ),
        transactionStatus: defaultTo(get(rule, "speedCondition.stateTrx"), ""),
        transactionStatusCount: defaultTo(
          get(rule, "speedCondition.stateTrx"),
          ""
        ),
        transactionStatusSum: defaultTo(
          get(rule, "speedCondition.stateTrx"),
          ""
        ),
        ignore3DS: Boolean(get(rule, "ignore3DS")),
      },
    };

    if (!isUndefined(get(rule, PathsEnum.SPEED_CONDITION_TRX_COUNT)))
      set(
        request,
        "rule.limit",
        `${get(rule, PathsEnum.SPEED_CONDITION_TRX_COUNT)}`
      );

    if (!isUndefined(get(rule, PathsEnum.SPEED_CONDITION_AMOUNT_SUM)))
      set(
        request,
        "rule.maxAmount",
        `${get(rule, PathsEnum.SPEED_CONDITION_AMOUNT_SUM)}`
      );

    const generalAction: string = defaultTo(get(rule, "generalAction"), "");

    if (VALID_GENERAL_ACTIONS.includes(generalAction as GeneralActionEnum))
      set(
        request,
        "rule.partnerValidator",
        defaultTo(get(rule, "provider"), "")
      );

    if (!isUndefined(get(ruleDetails, "entityName")))
      request.entityName = get(ruleDetails, "entityName", "").toLowerCase();

    if (!isUndefined(get(ruleDetails, "nodeId")))
      request.nodeId = get(ruleDetails, "nodeId");

    if (!isUndefined(get(ruleDetails, "rootId")))
      request.rootId = get(ruleDetails, "rootId");

    if (get(request, PathsEnum.RULE_KIND) != Values.RULES_KIND_SPEED) {
      delete request[PathsEnum.LIMIT];
      delete request[PathsEnum.MAX_AMOUNT];
    }

    const conditions: Condition[] = get(rule, "condition", []);

    if (get(rule, "specialCondition", []).length > 0) {
      conditions.push(...get(rule, "specialCondition", []));
    }

    const ruleRequestToSend: RuleRequest = mapRules(conditions, request);

    const cardHolderName: string = get(
      ruleRequestToSend,
      PathsEnum.TRANSACTION_CARD_HOLDER_NAME
    );
    if (!isEmpty(cardHolderName)) {
      const splitCardHolderName: string[] = cardHolderName.split("|");
      set(
        ruleRequestToSend,
        PathsEnum.TRANSACTION_CARD_HOLDER_NAME,
        splitCardHolderName[0] +
          "|" +
          concatenateCardHolderName(splitCardHolderName[1])
      );
    }

    if (isFormEditMode(ruleId || ruleName)) {
      switch (status) {
        case StatusEnum.RULES_ENABLED:
        case FieldsRule.STATUS_ENABLE:
          ruleRequestToSend.requestType = FieldsRule.REQUEST_TYPE_EDITION;
          set(ruleRequestToSend, "id", ruleId);
          break;
        case StatusEnum.RULES_DISABLED:
        case FieldsRule.STATUS_DISABLE:
          ruleRequestToSend.requestType = FieldsRule.REQUEST_TYPE_ACTIVATION;
          set(ruleRequestToSend, "id", ruleId);
          break;
        case FieldsRule.STATUS_REJECTED:
          ruleRequestToSend.status = FieldsRule.STATUS_PENDING_EDITED;
          const update_request: UpdateRuleRequest = {
            status: ruleRequestToSend.status,
            observationInfo: ruleDetails?.observationInfo,
            isFixed: true,
            rule: ruleRequestToSend.rule,
          };

          dispatch(
            updateRuleRequest(
              update_request,
              defaultTo(ruleId, ""),
              TabIndexEnum.REJECTED_APPLICATIONS_INDEX
            )
          );
          return;
      }
    }

    dispatch(sendRuleRequest(ruleRequestToSend));
  };

  const handleCancelModal = (): void => {
    dispatch(setOpenConfirmModal(false));
  };

  const handleCloseAlert = (isError: boolean): void => {
    dispatch(setOpenAlert({ open: false, isError }));
  };

  useEffect(() => {
    dispatch(getGeographyList());

    if (
      isUndefined(fetchSearchRuleById) &&
      isFormEditMode(ruleId) &&
      !isEmpty(status) &&
      !status.includes(StatusEnum.REJECTED)
    )
      dispatch(searchRuleById(ruleId));
  }, []);

  useEffect(() => {
    setBranchValue(get(hierarchyNodesValues, "branchNodesCount", 0));
    setCustomerValue(get(hierarchyNodesValues, "customerNodesCount", 0));
    if (get(ruleDetails, "entityName") === GroupEnum.BRANCH) setBranchValue(1);
  }, [hierarchyNodesValues]);

  function buildSecurityRuleData(
    formValues: SubmitHandler<ISecurityRuleForm> | ISecurityRuleForm
  ): SecurityRuleData {
    const data: ISecurityRuleForm | SubmitHandler<ISecurityRuleForm> =
      defaultTo(formValues, {} as ISecurityRuleForm);

    set(data, "condition", fields);

    return data as SecurityRuleData;
  }

  const handleCancelAction = (): void => {
    const data: SecurityRuleData = buildSecurityRuleData(form.getValues());

    dispatch(setIsEditedRule(true));
    if (checkIsGeneralRule(urlPath.pathname)) {
      dispatch(setGeneralRule(data));
      history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);
    } else {
      dispatch(setSecurityRule(data));
      if (isFormEditMode(ruleId || ruleName)) {
        history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);
        dispatch(setOpenModalInfo(false));
        dispatch(setSelectedTabIndex(indexTab));
      } else {
        history.push(Routes.CREATE_RULE_REQUEST);
      }
    }
  };

  useEffect(() => {
    if (rule && ruleDetails) {
      const generatedCondition: IConditionForm[] = [];
      const generatedSpecialCondition: IConditionForm[] = [];

      buildConditionsForEditedRule(
        get(rule, "condition", []),
        generatedCondition
      );

      buildConditionsForEditedRule(
        get(rule, "specialCondition", []),
        generatedSpecialCondition
      );

      const formObjectReset: ISecurityRuleForm =
        buildObjectToResetFormForEditedRule(
          ruleDetails,
          rule,
          generatedCondition,
          generatedSpecialCondition,
          setDefaultCountries,
          setDefaultHierarchy,
          setDefaultService
        );
      form.reset({
        ...formObjectReset,
      });
    }
  }, []);

  const isRender = (): boolean => {
    return (
      ((!isUndefined(fetchSearchRuleById) ||
        !isUndefined(fetchSearchRequestRuleById)) &&
        !isEmpty(defaultService) &&
        (!isEmpty(defaultCountries) || !isEmpty(defaultHierarchy)) &&
        !isUndefined(countries_array) &&
        !isEmpty(countries_array)) ||
      !isUndefined(selectedRule)
    );
  };

  useEffect(() => {
    setIsGeneralRule(checkIsGeneralRule(urlPath.pathname));
    setIsSelectedGeneralRule(!isUndefined(selectedRule));
  }, []);

  useEffect(() => {
    if (listMccCatalog!.length === 0 && !isLoadingCatalog) {
      dispatch(
        getListCatalogs({
          configCode: ConfigCodeEnum.BASIC_DATA,
          countryCode: CountryCode.GLB,
          productLine: ProductLineEnum.PLA,
        })
      );
    }
  }, []);

  useEffect(() => {
    const all_countries: string[] = defaultTo(
      defaultTo(countries_array, []).map((el) => el.name),
      []
    );
    if (countries_array && checkIsGeneralRule(urlPath.pathname))
      dispatch(setRuleDetails({ service: "", country: [...all_countries] }));
  }, [countries_array]);

  useEffect(() => {
    if (
      !isEmpty(status) &&
      isFormEditMode(ruleId) &&
      (status.includes(StatusEnum.RULES_ENABLED) ||
        status.includes(StatusEnum.RULES_DISABLED))
    ) {
      setIsUpdatedRule(true);
    }
  }, [ruleId]);

  useEffect(() => {
    if (!isUndefined(ruleId) && !isUndefined(status)) {
      if (isFormEditMode(ruleId) && status.includes(StatusEnum.REJECTED)) {
        dispatch(searchRequestRuleById(ruleId));
        setIsUpdatedRule(true);
      }
    }
  }, [ruleId]);

  useEffect(() => {
    if (selectedRule) {
      setIsUpdatedRule(true);

      const generatedCondition: IConditionForm[] = [];
      const generatedSpecialCondition: IConditionForm[] = [];
      const ruleSchema: RulesSchema = RulesSchema.RULEGENERAL;

      buildGenerateConditions(
        selectedRule,
        generatedCondition,
        ruleSchema,
        generatedSpecialCondition
      );
      const formObjectReset: ISecurityRuleForm = buildObjectToResetForm(
        selectedRule,
        generatedCondition,
        generatedSpecialCondition,
        ruleName,
        ruleSchema,
        setDefaultCountries,
        setDefaultHierarchy,
        setDefaultService,
        HierarchyPathsEnum.SECURITY_RULE
      );

      form.reset({ ...formObjectReset });
    }
  }, [selectedRule]);

  const buildRuleDetails = (
    fetchSearchRuleById: GetRuleByIdResponse
  ): RuleDetail => {
    return {
      id: get(fetchSearchRuleById, PathsEnum.RULE_ID, ""),
      country: get(fetchSearchRuleById, PathsEnum.RULE_COUNTRY, []),
      service: get(fetchSearchRuleById, PathsEnum.RULE_SERVICE, ""),
      subType:
        TypeToSubtypeRules[get(fetchSearchRuleById, PathsEnum.RULE_TYPE, "")],
      merchantName: get(fetchSearchRuleById, PathsEnum.RULE_MERCHANT_NAME, ""),
      merchantId: get(fetchSearchRuleById, PathsEnum.RULE_MERCHANT_ID, ""),
      configType: ConfigurationTypeEnum.FIRST_LEVEL,
      requestType: RequestTypeEnum.EDITION,
      status: get(fetchSearchRuleById, PathsEnum.R_RULE_STATUS, ""),
      kind: get(fetchSearchRuleById, PathsEnum.R_RULE_KIND, ""),
    };
  };

  useEffect(() => {
    if (fetchSearchRuleById) {
      const payload: RuleDetail = buildRuleDetails(fetchSearchRuleById);

      dispatch(setRuleDetails(payload));

      const generatedCondition: IConditionForm[] = [];
      const generatedSpecialCondition: IConditionForm[] = [];
      const ruleSchema: RulesSchema = RulesSchema.RULEMONITOR;
      buildGenerateConditions(
        fetchSearchRuleById,
        generatedCondition,
        ruleSchema,
        generatedSpecialCondition
      );

      const formObjectReset: ISecurityRuleForm = buildObjectToResetForm(
        fetchSearchRuleById,
        generatedCondition,
        generatedSpecialCondition,
        ruleId,
        ruleSchema,
        setDefaultCountries,
        setDefaultHierarchy,
        setDefaultService,
        HierarchyPathsEnum.SECURITY_RULE
      );

      if (
        !isEmpty(get(configurationValue, "conditions", [])) &&
        !isEmpty(
          get(
            fetchSearchRuleById.rule,
            "entityName",
            GroupEnum.WITHOUT_HIERARCHY
          )
        )
      ) {
        const props = {
          action: get(formObjectReset, "generalAction", ""),
          conditions: get(configurationValue, "conditions", []),
          countries: get(formObjectReset, "countries", []),
          entityName: get(
            fetchSearchRuleById.rule,
            "entityName",
            ""
          ).toUpperCase(),
          provider: get(formObjectReset, "provider", ""),
          subType: get(formObjectReset, "subType", ""),
        };
        const availableConditions = getAvailableConditions(props);

        formObjectReset.condition.forEach((item) => {
          const isInAvailableConditions = find(availableConditions, {
            field: item.label,
          });

          if (!Boolean(isInAvailableConditions)) item.label = "";
        });
      }
      const resetForm = {
        ...formObjectReset,
        condition: sortConditions(formObjectReset.condition),
      };

      form.reset(resetForm);
    }
  }, [fetchSearchRuleById, configurationValue]);

  useEffect(() => {
    if (fetchSearchRequestRuleById) {
      const generatedCondition: IConditionForm[] = [];
      const generatedSpecialCondition: IConditionForm[] = [];
      const ruleSchema: RulesSchema = RulesSchema.RULEGENERAL;

      buildGenerateConditions(
        fetchSearchRequestRuleById,
        generatedCondition,
        ruleSchema,
        generatedSpecialCondition
      );

      const formObjectReset: ISecurityRuleForm = buildObjectToResetForm(
        fetchSearchRequestRuleById,
        generatedCondition,
        generatedSpecialCondition,
        ruleId,
        ruleSchema,
        setDefaultCountries,
        setDefaultHierarchy,
        setDefaultService,
        HierarchyPathsEnum.REJECTED_RULE
      );

      form.reset({
        ...formObjectReset,
        condition: sortConditions(formObjectReset.condition),
      });
    }
  }, [fetchSearchRequestRuleById]);

  const ruleCatalog: SearchRuleCatalogResponse | undefined = useSelector(
    (state: IAppState) => state.ruleCatalog
  );

  const ruleGeneral: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.generalRule
  );

  const mapGeneralRules = (
    rules: Condition[],
    specialRules: Condition[],
    request: RuleGeneralRequest
  ): RuleGeneralRequest => {
    rules.map((rule: Conditions): void => {
      request.rule[rule.label] =
        rule.operator === OPERATORS.ALL
          ? OPERATORS.EQUAL_PIPE_ALL
          : `${rule.operator}|${mapRuleValues(get(rule, "value", []))}`;
    });

    specialRules.map((rule: Conditions): void => {
      if (
        !isEmpty(get(rule, "operator", "")) &&
        !isEmpty(get(rule, "value", []))
      )
        request.rule[rule.label] =
          rule.operator === OPERATORS.ALL
            ? OPERATORS.EQUAL_PIPE_ALL
            : `${rule.operator}|${mapRuleValues(get(rule, "value", []))}`;
    });

    return request;
  };

  const handleCreateRuleGeneral = (): void => {
    const request: RuleGeneralRequest = {
      name: get(ruleGeneral, "name", ""),
      regions: [],
      continents: [],
      rule: {
        generalAction: get(ruleGeneral, "generalAction", ""),
        code: get(ruleGeneral, "code", ""),
        partnerValidator: get(ruleGeneral, "provider", ""),
        kind: get(ruleGeneral, "kind", ""),
        message: get(ruleGeneral, "message", ""),
        name: get(ruleGeneral, "name", ""),
        hasSpecialCond: get(ruleGeneral, "hasSpecialCond", false),
      },
    };
    if (get(ruleGeneral, PathsEnum.KIND, "")) {
      if (get(ruleGeneral, PathsEnum.SPEED_CONDITION_CURRENCY, "") === "") {
        set(
          request,
          PathsEnum.TRANSACTION_STATUS_COUNT,
          get(ruleGeneral, PathsEnum.SPEED_CONDITION_STATE_TRX, "")
        );

        set(
          request,
          PathsEnum.PERIODICITY_COUNT,
          get(ruleGeneral, PathsEnum.SPEED_CONDITION_PERIODICITY, "")
        );
        set(
          request,
          PathsEnum.LIMIT,
          Number(get(ruleGeneral, PathsEnum.SPEED_CONDITION_TRX_COUNT, 0))
        );
      } else {
        set(
          request,
          PathsEnum.PERIODICITY_SUM,
          get(ruleGeneral, PathsEnum.SPEED_CONDITION_PERIODICITY, "")
        );
        set(
          request,
          PathsEnum.TRANSACTION_STATUS_SUM,
          get(ruleGeneral, PathsEnum.SPEED_CONDITION_STATE_TRX, "")
        );
        set(
          request,
          PathsEnum.MAX_AMOUNT,
          Number(get(ruleGeneral, PathsEnum.SPEED_CONDITION_AMOUNT_SUM, 0))
        );
        set(
          request,
          PathsEnum.MAX_AMOUNT_CURRENCY,
          get(ruleGeneral, PathsEnum.SPEED_CONDITION_CURRENCY, "")
        );
      }
    }

    const ruleGeneralRequestToSend: RuleGeneralRequest = mapGeneralRules(
      get(ruleGeneral, "condition", []),
      get(ruleGeneral, "specialCondition", []),
      request
    );

    dispatch(createRuleGeneral(ruleGeneralRequestToSend));
  };

  const buildPropsForModalConfirm = (
    duplicateRule: SearchRuleCatalogResponse | undefined
  ): IModalConfirmTemplate => {
    const isDuplicate: boolean = get(duplicateRule, "total", 0) > 0;

    if (isDuplicate) {
      return {
        titleText: TextModalConfirm.TITLE_RULE_DUPLICATE,
        descriptionText: `${TextModalConfirm.DESCRIPTION_RULE_DUPLICATE}${get(
          duplicateRule,
          "data[0].name",
          ""
        )}${TextModalConfirm.DESCRIPTION_RULE_DUPLICATE_QUESTION}`,
        buttonPrimary: TextModalConfirm.EDIT,
        onClickPrimary: () => {
          dispatch(setOpenConfirmGeneralRuleModal(false));
        },
        onClickSecondary: () => {},
      };
    }

    return {
      titleText: TextModalConfirm.TITLE_DEFAULT,
      descriptionText: TextModalConfirm.DESCRIPTION_DEFAULT,
      buttonPrimary: TextModalConfirm.ACCEPT,
      buttonSecondary: TextModalConfirm.EDIT_RULE,
      onClickPrimary: handleCreateRuleGeneral,
      onClickSecondary: () => {
        dispatch(setOpenConfirmGeneralRuleModal(false));
      },
    };
  };

  const propsModalConfirm: IModalConfirmTemplate =
    buildPropsForModalConfirm(ruleCatalog);

  const openConfirmGeneralRuleModal: boolean = useSelector((state: IAppState) =>
    defaultTo(state.openConfirmGeneralRuleModal, false)
  );
  const handleCancelModalConfirmGeneralRule = (): void => {
    dispatch(setOpenConfirmGeneralRuleModal(false));
  };

  const filterConditions = (): Object[] => {
    const field_new: Partial<ArrayField<Record<string, any>, "id">>[] =
      fields.filter(
        (field: Partial<ArrayField<Record<string, any>, "id">>) =>
          !isUndefined(field.id)
      );
    const result: Object[] = [];
    field_new.map((f: Partial<ArrayField<Record<string, any>, "id">>) => {
      if (isEmpty(f.label)) {
        if (
          result.findIndex((data: Object) => isEmpty(get(data, "label", ""))) <
          0
        )
          result.push(f);
      } else {
        result.push(f);
      }
    });

    return result;
  };

  const getCountryValues = (): string[] => {
    const fetch_country: string[] = get(
      fetchSearchRuleById,
      PathsEnum.RULE_COUNTRY,
      []
    );

    return fetchSearchRuleById
      ? defaultTo(fetch_country, [])
      : get(ruleDetails, PathsEnum.COUNTRY, []);
  };

  const getTransactionTypeList = (): TransactionType[] => {
    return actionType === ValueEnum.VALIDATE
      ? get(configurationValue, "transactionType", []).filter(
          (field) =>
            field.name !== TransactionTypeEnum.TOKEN &&
            field.name !== TransactionTypeEnum.SUBSCRIPTION_TOKEN &&
            field.name !== TransactionTypeEnum.SUBSCRIPTION_ON_DEMAND_TOKEN
        )
      : provider === ActionEnum.THREEDS
      ? get(configurationValue, "transactionType", []).filter(
          (field) =>
            field.name !== TransactionTypeEnum.CHARGE &&
            field.name !== TransactionTypeEnum.DEFERRED &&
            field.name !== TransactionTypeEnum.PRE_AUTHORIZATION &&
            field.name !== TransactionTypeEnum.SUBSCRIPTION_CHARGE &&
            field.name !== TransactionTypeEnum.SUBSCRIPTION_PRE_AUTHORIZATION
        )
      : get(configurationValue, "transactionType", []).filter(
          (field) =>
            field.name !== TransactionTypeEnum.SUBSCRIPTION_ON_DEMAND_TOKEN
        );
  };

  const getMCCList = (): CatalogData[] => {
    const mccCatalog: Data[] = getCatalogByName(
      listMccCatalog!,
      TypeCatalog.MCC
    );

    return mccCatalog.map(
      (mcc: Data): CatalogData => ({
        name: mcc.value,
        value: mcc.code,
      })
    );
  };

  const getAvailableGeneralConditions = (): Conditions[] | undefined => {
    return defaultTo(availableConditions, []).filter(
      (condition: Conditions) => condition.field !== LabelEnum.PROCESSOR_CODE
    );
  };

  useEffect(() => {
    const emptyObject: object = {
      value: "",
      label: "",
      operator: "",
    };
    const conditionsLength: number = get(
      form.getValues(),
      "condition.length",
      0
    );

    if (
      (!isEmpty(provider) || !isEmpty(action)) &&
      !isFormEditMode(ruleId || ruleName)
    ) {
      form.setValue("specialCondition", [
        {
          value: "",
          label: "",
          operator: "",
        },
      ]);
      form.setValue(PathsEnum.SUB_KIND, Values.RULES_KIND_STRICT);
      remove(Array.from(Array(conditionsLength).keys()));
      append({ ...emptyObject });
    }
    const filter: Conditions[] = getAvailableConditions({
      action,
      conditions: get(configurationValue, "conditions", []),
      countries: getCountryValues(),
      entityName: get(ruleDetails, "entityName", ""),
      provider,
      subType: get(ruleDetails, "subType", ""),
    });

    dispatch(setAvailableConditions(filter));
  }, [ruleDetails, action, provider, configurationValue]);

  useEffect(() => {
    setIsDisabledSpeed(false);
  }, [actionType]);

  return {
    availableConditions: getAvailableGeneralConditions(),
    actionType,
    isRender,
    isGeneralRule,
    isSelectedGeneralRule,
    isUpdatedRule,
    geographyList,
    configuration: configurationValue,
    credentials: merchantCredentials,
    ruleCountries: getCountryValues(),
    form,
    actions: {
      append,
      remove,
      handleCancelAction,
    },
    handle: {
      handleCloseAlert,
      handleCancelModal,
      handleSendToReview,
      handleCancelModalConfirmGeneralRule,
      setIsDisabledSpeed,
      setIsDisabledSpecial,
    },
    branchValue,
    customerValue,
    fields: filterConditions(),
    processors: processorsList,
    hierarchyNodesResponse,
    openAlert,
    ruleId,
    ruleName,
    savingRule,
    isLoading,
    openConfirmGeneralRuleModal,
    propsModalConfirm,
    selectedRule,
    ruleDetails,
    transactionTypeList: getTransactionTypeList(),
    mccList: getMCCList(),
    merchantCategoryList: merchantCategoryCatalog,
    isDisabledSpeed,
    isDisabledSpecial,
  };
};
