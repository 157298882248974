import { IUseFileValidationResultState } from "../../../../shared/infrastructure/interfaces/useFileValidationResultState.interface";
import { ValidateFilesResponse } from "../../../../../types/validate_files_response";
import { defaultTo, get, isEmpty } from "lodash";
import {
  LabelsDispersion,
  StatusEnum,
} from "../../../../shared/infrastructure/constants/RequestDispersionConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  setValidateFilesResponse,
  setShowAlertOnProcessFileRequest,
  setSelectedIndexDispersionTab,
} from "../../../../store/actionCreators";
import { SuccessValidationResultProps } from "../../../../shared/infrastructure/interfaces/SuccessValidationResult.interface";
import { useEffect, useState } from "react";
import { IAppState } from "../../../../store/reducer";
import { ProcessFileResponse } from "../../../../../types/process_file_response";
import { useHistory } from "react-router";
import { DispersionTabEnum } from "../../../../shared/infrastructure/constants/DispersionTabEnum";

export const useFileValidationResultState = (
  props: SuccessValidationResultProps
): IUseFileValidationResultState => {
  const dispatch = useDispatch();
  const [showFileResultComponent, setShowFileResultComponent] =
    useState<boolean>(true);
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);
  const [isClickedToProcessFile, setIsClickedToProcessFile] =
    useState<boolean>(false);
  const validateFileResponse: ValidateFilesResponse | undefined = useSelector(
    (state: IAppState) => state.validateFilesResponse
  );
  const processFileDispersionsResponse: ProcessFileResponse | undefined =
    useSelector((state: IAppState) => state.processFileDispersionsResponse);
  const showAlertOnProcessFileRequest: boolean | undefined = useSelector(
    (state: IAppState) => state.showAlertOnProcessFileRequest
  );

  const history = useHistory();

  const validateTitle = (
    resValidation: ValidateFilesResponse | undefined
  ): string => {
    if (
      get(resValidation, "summary[0].rows_error", 0) > 0 &&
      get(resValidation, "summary[1].rows_error", 0) > 0
    ) {
      return LabelsDispersion.VALIDATION_ERROR_TITLE_BOTH_FILES;
    }
    if (get(resValidation, "summary[0].rows_error", 0) > 0) {
      return LabelsDispersion.VALIDATION_ERROR_TITLE_INPUT;
    }
    if (get(resValidation, "summary[1].rows_error", 0) > 0) {
      return LabelsDispersion.VALIDATION_ERROR_TITLE_UAF;
    }
    return LabelsDispersion.VALIDATION_SUCCESS_TITLE;
  };

  const showError: boolean =
    get(props, "validationResults.summary[0].rows_error", 0) > 0 ||
    get(props, "validationResults.summary[1].rows_error", 0) > 0;

  const cleanValidationResult = (): void => {
    dispatch(setValidateFilesResponse(undefined));
  };

  const handleProcessRequest = (): void => {
    if (validateFileResponse) {
      setIsClickedToProcessFile(true);
      dispatch(
        setSelectedIndexDispersionTab(
          DispersionTabEnum.PROCESSOR_DISPERSION_INDEX
        )
      );
      history.push("/payouts-dispersion");
    }
  };

  useEffect(() => {
    if (
      !isEmpty(get(processFileDispersionsResponse, "status", "")) &&
      get(processFileDispersionsResponse, "status", "") ===
        StatusEnum.COMPLETE &&
      isClickedToProcessFile
    ) {
      setTimeout(() => {
        setOpenLoadingModal(false);
        setShowFileResultComponent(false);
      }, 1500);
    } else if (
      get(processFileDispersionsResponse, "status", "") ===
      StatusEnum.INCOMPLETE
    ) {
      setTimeout(() => {
        setOpenLoadingModal(false);
        dispatch(setShowAlertOnProcessFileRequest(true));
      }, 1500);
    }
  }, [processFileDispersionsResponse, isClickedToProcessFile]);

  useEffect(() => {
    if (defaultTo(showAlertOnProcessFileRequest, false)) {
      setOpenLoadingModal(false);
    }
  }, [showAlertOnProcessFileRequest]);

  return {
    validateTitle,
    showError,
    cleanValidationResult,
    showFileResultComponent,
    handleProcessRequest,
    openLoadingModal,
    processFileDispersionsResponse,
    referenceNumber: defaultTo(validateFileResponse?.referenceNumber, ""),
  };
};
