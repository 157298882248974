import React, { useState } from "react";
import { useStyles } from "../EntityStyles/EntityStyles";
import { Box, Grid, Typography } from "@mui/material";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { StatusEntity } from "../StatusEntity/StatusEntity";
import { getSimplifiedFormatLocal } from "../../../shared/utils/date_utils";
import { getStyleByStringLength } from "../../../shared/utils/string_utils";
import { get } from "lodash";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import { API_ROUTES } from "../../../shared/constants/api_routes";

export interface IBranchItem {
  branch: MerchantNodeData;
}

export const BranchItem: React.FC<IBranchItem> = ({ branch }: IBranchItem) => {
  const styles = useStyles();
  const [nameStyle, setNameStyle] = useState(styles.title);

  return (
    <Box
      sx={{
        background: "transparent",
        borderLeft: "1px solid #E2E8F0",
        height: getStyleByStringLength(
          get(branch, "name", ""),
          ["56px", "75px"],
          27
        ),
        padding: "15px",
        width: "100%",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columns={5}
      >
        <Grid item xs={4}>
          <Typography
            onClick={() => {
              window.location.href = API_ROUTES.MERCHANT_RESUME(
                get(branch, "merchant_id", ""),
                branch.merchant_status === StatusEnum.ACTIVE
              );
            }}
            onMouseEnter={() => setNameStyle(styles.titleBranch)}
            onMouseLeave={() => setNameStyle(styles.title)}
            className={nameStyle}
            variant="inherit"
          >
            {branch.name}
          </Typography>
        </Grid>
        <Grid item xs={1} justifyContent={"flex-end"}>
          <StatusEntity status={get(branch, "merchant_status", "")} />
        </Grid>
      </Grid>
      <Typography className={styles.text} variant="inherit">
        Creado: {branch.created && getSimplifiedFormatLocal(branch.created)}
      </Typography>
    </Box>
  );
};
