import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { detailCardBodyStyles as styles } from "@components/DetailCardBody/DetailCardBody.styles";
import { IGeneralConditionContainer } from "@components/DetailCardBody/GeneralConditionContainer/IGeneralConditionContainer.interfaces";
import { VariableRule } from "../../../../types/rule_alarm";
import {
  FieldOptionValue,
  VARIABLE_RECORD,
} from "@shared/constants/AlarmConfigConstants";
import {
  parseVariablesValue,
  translateCatalogOptions,
  translateOperationOption,
} from "@shared/utils/detailCardUtils";
import { GeneralConditionEnum } from "@shared/enum/GeneralConditionEnum";
import { TriggerVariablesCatalog } from "@shared/constants/TriggerVariablesCatalog";

const GeneralConditionContainer: FC<IGeneralConditionContainer> = ({
  variables,
  isAlarmVariable,
  rowData,
  mccCatalog,
}: IGeneralConditionContainer) => {
  return (
    <Table sx={styles.tableContainer}>
      <TableHead sx={styles.tableHeader}>
        <TableRow>
          <TableCell>
            <Typography variant={"caption"} sx={styles.textLight}>
              {GeneralConditionEnum.LABEl}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant={"caption"} sx={styles.textLight}>
              {GeneralConditionEnum.OPERATOR}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant={"caption"} sx={styles.textLight}>
              {GeneralConditionEnum.VALUE}
            </Typography>
          </TableCell>
          {isAlarmVariable &&
            variables.some((value) =>
              TriggerVariablesCatalog.includes(value.field as FieldOptionValue)
            ) && (
              <TableCell>
                <Typography variant={"caption"} sx={styles.textLight}>
                  {GeneralConditionEnum.TRIGGER_VALUE}
                </Typography>
              </TableCell>
            )}
        </TableRow>
      </TableHead>
      <TableBody sx={styles.tableBody}>
        {variables.map((variable: VariableRule, index: number) => (
          <TableRow key={index}>
            <TableCell>
              <Typography variant={"caption"} sx={styles.textDark}>
                {VARIABLE_RECORD[variable.field]}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant={"caption"} sx={styles.textDark}>
                {translateOperationOption[variable.operator]}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant={"caption"} sx={styles.textValues}>
                {variable.field === FieldOptionValue.MCC
                  ? translateCatalogOptions(
                      variable.value.toString(),
                      mccCatalog
                    )
                  : parseVariablesValue(variable)}
              </Typography>
            </TableCell>
            {isAlarmVariable &&
              TriggerVariablesCatalog.includes(variable.field) && (
                <TableCell>
                  <Typography variant={"caption"} sx={styles.textDark}>
                    {parseVariablesValue(variable, rowData)}
                  </Typography>
                </TableCell>
              )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GeneralConditionContainer;
