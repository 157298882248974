import React, { FC } from "react";
import { Box } from "@mui/material";
import { EmptyStateImg } from "../../assets/images/EmptyStateImg";
import { IEmptyState } from "./EmptyState.interfaces";
import { MessageBlock } from "@kushki/connect-ui";
import { emptyStateStyles as styles } from "./EmptyState.styles";

const EmptyState: FC<IEmptyState> = ({ bodyText, titleText }: IEmptyState) => {
  return (
    <Box sx={styles.emptyBox}>
      <MessageBlock
        image={<EmptyStateImg />}
        title={titleText}
        typeMessage={1}
        body={bodyText}
      />
    </Box>
  );
};

export { EmptyState };
