import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const textLineStyles = createNamedStyles({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    color: "black",
    maxWidth: "400px",
  },
});
