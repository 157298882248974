import { Alert, Snackbar } from "@mui/material";
import React, { FC } from "react";
import { ISnackbarAlert } from "./SnackbarAlert.interfaces";
import { snackbarAlertStyle } from "./SnackbarAlert.style";

export const SnackbarAlert: FC<ISnackbarAlert> = ({
  open,
  type,
  message,
  onClose,
}: ISnackbarAlert) => {
  return (
    <Snackbar
      id="snackbar"
      autoHideDuration={4000}
      open={open}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      onClose={onClose}
      sx={snackbarAlertStyle.mainContainer}
    >
      <Alert
        id="alert"
        variant="filled"
        elevation={6}
        color={type}
        severity={type}
        onClose={onClose}
        sx={snackbarAlertStyle.colorFont}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
