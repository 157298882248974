/* istanbul ignore file */

export enum RuleEnum {
  AMOUNT = "transactionTotalAmount",
  BANK = "transactionBank",
  BRAND = "transactionBrand",
  BIN = "transactionBIN",
  CARD_TYPE = "transactionIsCreditCard",
  COUNTRY = "transactionCountry",
  CURRENCY = "transactionCurrency",
  CREDENTIAL = "transactionCredential",
  DEFERRED = "transactionIsDeferred",
  IP = "transactionIP",
  MASKED_CARD = "transactionMaskedCreditCard",
  TRANSACTION_TYPE = "transactionType",
}

export enum RuleTitle {
  AMOUNT = "Monto",
  BANK = "Banco",
  BRAND = "Marca",
  BIN = "BIN",
  CARD_TYPE = "Tipo de tarjeta",
  COUNTRY = "País",
  CURRENCY = "Moneda",
  CREDENTIAL = "Credencial",
  DEFERRED = "Diferido",
  IP = "IP",
  MASKED_CARD = "Número de tarjeta enmascarada",
  TRANSACTION_TYPE = "Tipo de transacción",
}

export const fromRuleKeyToTitleMap = new Map<RuleEnum, RuleTitle>([
  [RuleEnum.AMOUNT, RuleTitle.AMOUNT],
  [RuleEnum.BANK, RuleTitle.BANK],
  [RuleEnum.BRAND, RuleTitle.BRAND],
  [RuleEnum.BIN, RuleTitle.BIN],
  [RuleEnum.CARD_TYPE, RuleTitle.CARD_TYPE],
  [RuleEnum.COUNTRY, RuleTitle.COUNTRY],
  [RuleEnum.CURRENCY, RuleTitle.CURRENCY],
  [RuleEnum.CREDENTIAL, RuleTitle.CREDENTIAL],
  [RuleEnum.DEFERRED, RuleTitle.DEFERRED],
  [RuleEnum.IP, RuleTitle.IP],
  [RuleEnum.MASKED_CARD, RuleTitle.MASKED_CARD],
  [RuleEnum.TRANSACTION_TYPE, RuleTitle.TRANSACTION_TYPE],
]);
