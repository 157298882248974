import React from "react";
import { CardSection } from "../CardSection/CardSection";
import { GenericRectSkeleton } from "../Skeletons/GenericRectSkeleton";
import { BusinessRulesGeneralInformation } from "../BusinessRulesGeneralInformation/BusinessRulesGeneralInformation";
import { BusinessRulesConfiguration } from "../BusinessRulesConfiguration/BusinessRulesConfiguration";
import { Category } from "../../../types/category";
import {
  DescriptionSection,
  TitleSection,
} from "../../shared/infrastructure/constants/RulesConstants";
import { Grid } from "@material-ui/core";
import { ProcessorsInformation } from "../../../types/processors_list";

export interface BusinessRulesSectionProps {
  isLoading: boolean;
  processors: ProcessorsInformation[];
  credentials: Category[];
  handleAddRule: (
    rule: string,
    condition: string,
    value: string,
    rules: object
  ) => void;
  handleRemoveRule: (rule: string, rules: object) => void;
  isRequest: boolean;
  country: string;
  handleEnterSearchTextProcessors: (key: string) => void;
  handleSetSearchTextProcessors: (text: string) => void;
}

const renderPaddingCardSection = (element: JSX.Element): JSX.Element => {
  return (
    <Grid container style={{ padding: "20px 22px" }}>
      <Grid item xs={12}>
        {element}
      </Grid>
    </Grid>
  );
};

export const BusinessRulesSection: React.FC<BusinessRulesSectionProps> = (
  props: BusinessRulesSectionProps
) => {
  return (
    <React.Fragment>
      <CardSection
        isLoading={props.isLoading!}
        sectionTitle={TitleSection.BUSINESS_RULES_INFORMATION}
        sectionDescription={DescriptionSection.BUSINESS_RULES_INFORMATION}
        sectionComponent={renderPaddingCardSection(
          <BusinessRulesGeneralInformation />
        )}
        sectionSkeletonComponent={renderPaddingCardSection(
          <GenericRectSkeleton
            width={"100%"}
            name={"BusinessRulesGeneralInformation"}
          />
        )}
      />
      <CardSection
        isLoading={props.isLoading!}
        sectionTitle={TitleSection.BUSINESS_RULES_CONFIGURATION}
        sectionDescription={DescriptionSection.BUSINESS_RULES_CONFIGURATION}
        sectionComponent={renderPaddingCardSection(
          <BusinessRulesConfiguration
            isRequest={props.isRequest}
            processors={props.processors}
            credentials={props.credentials}
            handleAddRule={props.handleAddRule}
            handleRemoveRule={props.handleRemoveRule}
            country={props.country}
            handleEnterSearchTextProcessors={
              props.handleEnterSearchTextProcessors
            }
            handleSetSearchTextProcessors={props.handleSetSearchTextProcessors}
          />
        )}
        sectionSkeletonComponent={renderPaddingCardSection(
          <GenericRectSkeleton
            width={"100%"}
            name={"BusinessRulesConfiguration"}
          />
        )}
      />
    </React.Fragment>
  );
};
