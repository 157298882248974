import { get, has, includes, isEmpty } from "lodash";
import { isComplianceUser } from "../complianceUser";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../infraestructure/time-zone-catalogues";
import {
  HOUR_MINUS_5,
  TimeZoneDefault,
  UTC,
} from "../constants/TimeZoneDefault";
import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import { TransactionType } from "../infraestructure/transaction-type";
import { ProcessorEnum } from "../infraestructure/ProcessorEnum";
import { CountryEnum } from "../infraestructure/CountryEnum";
import { IUserData } from "../../components/DashboardTransaction/state/useDashboardTransaction.interfaces";
import { isApproval } from "./general_utils";

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  let index: number;

  if (text === undefined) return value === UTC ? HOUR_MINUS_5 : TimeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === UTC
      ? HOUR_MINUS_5
      : TimeZoneDefault;
  }

  return value === UTC ? HOUR_MINUS_5 : TimeZoneDefault;
};

export const getColumnsUser = (): IUserData => {
  const payload = JSON.parse(localStorage.getItem("payload")!);

  return {
    admin:
      isComplianceUser() ||
      get(JSON.parse(localStorage.getItem("roles")!), "BackofficeAdmin", false),
    prefferedUserName: get(payload, "preferred_username", ""),
    publicMerchantId: localStorage.getItem("merchantId")!,
    timeZone: timeZoneHour(localStorage.getItem("timeZone")!, UTC),
  };
};

export function validateChild(trx: Transaction): {
  hasChild: boolean;
  typeChild: string;
  amount: number;
} {
  let type_trx: string = "void";
  let amount_refund: number = 0;
  let amount_void: number = get(trx, "_source.approved_transaction_amount", 0);
  const has_child: boolean = !isEmpty(get(trx._source, "child", []));

  if (has_child) {
    trx._source!.child!.forEach((element: Transaction) => {
      const is_approval: boolean = isApproval(
        element._source!.transaction_status!
      );

      if (is_approval) {
        if (element._source!.transaction_type === TransactionType.REFUND) {
          amount_refund += element._source!.approved_transaction_amount!;
          type_trx = "refund";
        } else if (
          element._source!.transaction_type === TransactionType.VOID ||
          element._source!.transaction_type === TransactionType.VOID_PARTIAL
        ) {
          amount_void = element._source!.approved_transaction_amount!;
          type_trx = "void";
        }
      }
    });
  }

  return {
    amount: type_trx === "refund" ? amount_refund : amount_void,
    hasChild: has_child,
    typeChild: type_trx,
  };
}

type TRefundAmount =
  | {
      amount: {
        currency: string;
        ice: number;
        iva: number;
        subtotalIva: number;
        subtotalIva0: number;
      };
    }
  | {};

const getRefundAmountUF = (
  amount: number,
  transaction: TransactionInfo
): number => {
  const transactionAmountCLP = get(
    transaction,
    "approved_transaction_amount",
    0
  );

  const totalAmountUF =
    get(transaction, "iva_value", 0) +
    get(transaction, "subtotal_iva", 0) +
    get(transaction, "subtotal_iva0", 0);

  return (amount / transactionAmountCLP) * totalAmountUF;
};

export const getRefundAmount = (
  currencyCode: string,
  transaction: TransactionInfo,
  amount?: number
): TRefundAmount => {
  if (amount !== undefined) {
    return {
      amount: {
        currency: currencyCode,
        ice: 0,
        iva: 0,
        subtotalIva:
          currencyCode === "UF"
            ? getRefundAmountUF(amount, transaction)
            : amount,
        subtotalIva0: 0,
      },
    };
  }

  return {};
};

export function getKindTrx(trx: Transaction): string {
  if (
    has(trx, "_source.parent_ticket_number") &&
    get(trx, "_source.parent_ticket_number") !== null
  )
    return "commission";
  if (
    has(trx, "_source.subscription_id") &&
    get(trx, "_source.subscription_id") !== null
  )
    return "subscription";

  return "card";
}

export const countriesWithPartialRefund = [
  "Colombia",
  "Chile",
  "Peru",
  "Mexico",
];

export const validationMXProcessorKushki = (trxInfo: Transaction) => {
  if (
    get(trxInfo, "_source.processor_name") ===
      ProcessorEnum.KushkiAcquirerProcessor &&
    get(trxInfo, "_source.country") === CountryEnum.MEXICO
  ) {
    return false;
  } else {
    if (get(trxInfo, "_source.country") != CountryEnum.MEXICO) {
      return !includes(
        countriesWithPartialRefund,
        get(trxInfo, "_source.country")
      );
    }
  }

  return true;
};

export function formatNumber(value: string): string {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(value));
}
