import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { get, isEmpty } from "lodash";
import { ITextFieldComponentProps } from "../../../../../shared/interfaces/text_field_component";
import { HelperText } from "../../../../../shared/enums/BusinessRuleEnum";
import { ErrorEnum } from "../../../../../shared/enums/ErrorEnum";
import { retrieveFormError } from "../../../../../shared/utils/utilsFile";

export const TextFieldComponent: React.FC<{
  value: ITextFieldComponentProps;
  defaultValueList: string[];
}> = (props: {
  value: ITextFieldComponentProps;
  defaultValueList: string[];
}) => {
  const {
    placeholder,
    actions,
    name,
    id,
    errors,
    control,
    register,
    setValue,
    value,
  } = props.value;

  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        name={id}
        control={control}
        defaultValue={value}
        rules={{
          pattern: {
            message: ErrorEnum.NOT_ALLOWED_VALUE,
            value: /^([0-9]{1,12})$|(^[0-9]{1,12}[.][0-9]{1,2})$/,
          },
          required: {
            message: ErrorEnum.REQUIRED_FIELD,
            value: false,
          },
        }}
        render={() => (
          <TextField
            key={name}
            id={name}
            name={name}
            label={placeholder}
            variant="outlined"
            value={value}
            fullWidth
            inputRef={register}
            error={get(errors, id)}
            helperText={retrieveFormError(errors, id)[1]}
            onChange={(event): void => {
              actions.getOtherPlaceholder();
              if (!isEmpty(value))
                setValue(name, actions.getValues(event.target.value));
            }}
            onKeyDown={(e: any) => actions.keyDownHandler(e)}
          />
        )}
      />
      {isEmpty(value) && (
        <FormHelperText>{HelperText.AMOUNT_HELPER}</FormHelperText>
      )}
    </FormControl>
  );
};
