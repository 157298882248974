import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { store } from "@store/store";
import Normalize from "react-normalize";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import Navigation from "./navigation";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "@environments/environment";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "@shared/constants/GetRollbarConfig";

const Root = () => {
  return (
    <StyledEngineProvider>
      <React.StrictMode>
        <Normalize />
        <style>
          {
            "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
          }
        </style>
        <style>
          {
            "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
          }
        </style>

        <RollbarProvider config={getRollbarConfig}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={SecurityWrapperRoles.M_COMPLIANCE_ROOT}
              >
                {
                  // @ts-ignore
                  <SnackBarProvider>
                    <Navigation />
                  </SnackBarProvider>
                }
              </ModuleSecurityWrapper>
            </ThemeProvider>
          </Provider>
        </RollbarProvider>
      </React.StrictMode>
    </StyledEngineProvider>
  );
};

export default Root;
