export enum ActionTypes {
  SET_NOTIFICATION = "[RETENTIONS] SET_NOTIFICATION",
  SET_CONCILIATION_DATA = "[CONCILIATION_DASHBOARD] SET_CONCILIATION_DATA",
  SET_CONCILIATION_TRX_DATA = "[CONCILIATION_DASHBOARD] SET_CONCILIATION_TRX_DATA",
  SET_IS_UPLOADING_CONCILIATION = "[RETENTIONS] SET_IS_UPLOADING_CONCILIATION",
  SET_IS_UPLOADED_CONCILIATION = "[RETENTIONS] SET_IS_UPLOADED_CONCILIATION",
  SET_UPLOAD_CONCILIATION_ERROR = "[RETENTIONS] SET_UPLOAD_CONCILIATION_ERROR",
  SET_CONCILIATION_FILE_KEY = "[RETENTIONS] SET_CONCILIATION_KEY",
  SET_MODAL_CONCILIATION = "[RETENTIONS] SET_MODAL_CONCILIATION",
  SET_MODAL_OMIT = "[RETENTIONS] SET_MODAL_OMIT",
  SET_MODAL_CONCILIATION_PROPS = "[RETENTIONS] SET_MODAL_CONCILIATION_PROPS",
  SET_ACTUAL_HEADERS = "[ACTUAL_HEADERS] SET_ACTUAL_HEADERS",
  SET_LOAD_STATUS = "[CONCILIATION] SET_LOAD_STATUS",
  SET_LOADING_DOWNLOAD_PROCESS = "[BANK_CONCILIATION] SET_LOADING_DOWNLOAD_PROCESS",
  SET_INDEX_TAB_TRANSACTION_SUMMARY = "[CONCILIATION] SET_INDEX_TAB_TRANSACTION_SUMMARY",
  SET_OPEN_MODAL_DETAIL = "[CONCILIATION_DASHBOARD] SET_OPEN_MODAL_DETAIL",
  SET_IS_LOADING_SUMMARY_DATA = "[CONCILIATION_DASHBOARD] SET_IS_LOADING_SUMMARY_DATA",
  SET_IS_LOADING_TRANSACTION_DATA = "[CONCILIATION_DASHBOARD] SET_IS_LOADING_TRANSACTION_DATA",
  SET_LOADING_MASSIVE = "[CONCILIATION_DASHBOARD] SET_LOADING_MASSIVE",
}
