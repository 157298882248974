import { makeStyles } from "@mui/styles";

export const generalStyles = makeStyles({
  subtitleTypography: {
    fontSize: "16px !important",
    color: "#293036 !important",
  },
  generalText: {
    fontSize: "14px !important",
    color: "#6D7781 !important",
  },
  titleCountry: {
    fontWeight: "bold !important",
    fontSize: "14px !important",
    color: "#6D7781 !important",
  },
});
