import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const alarmConfigStyles = createNamedStyles({
  cardContainer: {
    borderRadius: "16px",
    width: "100%",
    padding: "40px",
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: "16px",
  },
  divider: {
    border: "none",
    margin: "32px 0px 22px 0px",
  },
  container: {
    marginBottom: {
      xs: "150px",
      sm: "110px",
    },
  },
});
