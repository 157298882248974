/* istanbul ignore file */
import { defaultTo, get, has } from "lodash";
import { ISessionSupport } from "../infrastructure/interfaces/ISessionSupport";

export class SessionService {
  public static setSessionSupport(sessionSupport: ISessionSupport | undefined) {
    const cache = JSON.parse(defaultTo(localStorage.getItem("cache"), "{}"));
    let _cache = { ...cache, sessionSupport };

    localStorage.setItem("cache", JSON.stringify(_cache));
  }

  public static getSessionSupport() {
    const cache = JSON.parse(defaultTo(localStorage.getItem("cache"), "{}"));
    return get(cache, "sessionSupport", undefined);
  }

  public static removeSessionSupport() {
    this.setSessionSupport(undefined);
  }

  public static includeMerchant(includedMerchantId: string[]): boolean {
    const merchant_id: string = defaultTo(
      localStorage.getItem("merchantId"),
      ""
    );
    return includedMerchantId.includes(merchant_id);
  }

  public static getExpiredDateSession(payload: object): void {
    debugger;
    if (!has(payload, "timeConnect")) return;

    let expiredDate = SessionService._getExpiredDateSession(
      get(payload, "timeConnect")
    );
    const sessionSupport = SessionService.getSessionSupport();
    SessionService.setSessionSupport({
      ...sessionSupport,
      expired: `${expiredDate.getTime()}`,
      timeConnect: get(payload, "timeConnect"),
    });
  }

  private static _getExpiredDateSession(timeConnect: string): Date {
    const number = Number(timeConnect.substr(0, timeConnect.length - 1));
    const timeType = timeConnect.substr(-1);
    let expiredDate = new Date();
    switch (timeType) {
      case "h":
        expiredDate.setHours(expiredDate.getHours() + number);
        break;
      case "m":
      default:
        expiredDate.setMinutes(expiredDate.getMinutes() + number);
        break;
    }
    return expiredDate;
  }
}
