import React, { ReactNode } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  createStyles,
  MenuItem,
  MenuList,
  Popover,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormatEnum } from "../../shared/infrastructure/FormatEnum";
import { makeStyles } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import {
  RolesExecutorEnum,
  RolesHistoricEnum,
  RolesRetentionEnum,
  RolesValidatorEnum,
} from "../../shared/infrastructure/enum/Roles/RolesEnum";

export interface ButtonDownloadFileProps {
  isLoading: boolean;
  options?: { text: string; value: string }[];
  downloadFile: (format: string, isAll?: boolean) => void;
  isAll?: boolean;
  buttonV2?: boolean;
  icon?: ReactNode;
  statusFlow?: string;
  stateApprove?: boolean;
  small?: boolean;
  isHistoric?: boolean;
  isRetention?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonStyled: {
      border: "0px",
      backgroundColor: "#fff",
      "&:hover": {
        border: "0px",
      },
    },
    buttonIconStyledRetention: {
      border: "0px",
      padding: "0px",
      "&:hover": {
        border: "0px",
      },
    },
    container: {
      display: "inline-block",
    },
    pd0: {
      padding: "0px",
    },
    buttonIconStyled: {
      border: "0px",
      padding: "0px",
      borderLeft: "1px solid gray",
      backgroundColor: "#fff",
      "&:hover": {
        border: "0px",
      },
    },
    smallH: {
      maxHeight: "0.1rem",
    },
  })
);
export const ButtonDownloadFile: React.FC<ButtonDownloadFileProps> = (
  props: ButtonDownloadFileProps
) => {
  const classes = useStyles();
  const options: { text: string; value: string }[] = props.options || [
    {
      text: "Descargar en .csv",
      value: FormatEnum.CSV,
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ComponentSecurityWrapper
        componentId={
          props.isHistoric
            ? RolesHistoricEnum.M_BILLING_HISTORIC_DOWNLOAD
            : props.isRetention
            ? RolesRetentionEnum.M_BILLING_RETENTION_DOWNLOAD
            : props.statusFlow == StatusFlowEnum.EXECUTOR
            ? RolesExecutorEnum.M_BILLING_DOWNLOAD
            : RolesValidatorEnum.M_BILLING_DOWNLOAD
        }
      >
        <Box onClick={handleClick} className={classes.container}>
          <ButtonGroup
            variant="contained"
            color="secondary"
            size={"medium"}
            disabled={props.isLoading}
          >
            {!props.stateApprove && (
              <Button
                id={"btnDownload"}
                className={`${props.buttonV2 ? classes.buttonStyled : ""}`}
                startIcon={props.icon ? props.icon : <GetAppIcon />}
              >
                Descargar
              </Button>
            )}
            <Button
              id="button_arrow"
              color="secondary"
              size={"medium"}
              onClick={handleClick}
              className={`splitBtn splitArrow ${
                props.small ? classes.smallH : ""
              }`}
            >
              {props.isLoading ? (
                <CircularProgress size={20} color={"primary"} />
              ) : props.stateApprove ? (
                <MoreHoriz />
              ) : (
                <ArrowDropDownIcon />
              )}
            </Button>
          </ButtonGroup>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuList id="split-button-menu">
            {options.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  setAnchorEl(null);
                  props.downloadFile(option.value, props.isAll);
                }}
              >
                {option.text}
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      </ComponentSecurityWrapper>
    </React.Fragment>
  );
};
