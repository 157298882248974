import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useBillingInvoiceBodyStyles = makeStyles(() => ({
  buttonToggle: {
    borderColor: theme.palette.primary.main,
    height: "fit-content",
    padding: "3px 15px",
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
      },
    },
  },
  textFieldStyle: {
    backgroundColor: "#FFF",
    color: "#293036",
  },
  invoiceTitle: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#2f2f2f",
    margin: "25px 0px 16px 0px",
  },
  invoiceSubtitle: {
    letterSpacing: "-0.045em",
    fontSize: "16px",
    color: "#293036",
  },
  invoiceNumber: {
    fontSize: "16px",
    color: "#677684",
  },
  invoiceCurrency: {
    color: "#B4B9BE",
  },
  invoiceTotal: {
    letterSpacing: "-0.045em",
    fontSize: "16px",
    color: "#293036",
    fontWeight: 600,
  },
}));
