import { get, sortBy as sortbyLodash } from "lodash";
import { SortEnum } from "../enums/SortEnum";

export const sortObjectArray = <T>(
  array: T[],
  path: string,
  sortBy: SortEnum
) => {
  if (sortBy === SortEnum.DESC) {
    return sortbyLodash(array, (a: T) =>
      get(a, path, "").toLowerCase()
    ).reverse();
  } else {
    return sortbyLodash(array, (a: T) => get(a, path, "").toLowerCase());
  }
};
