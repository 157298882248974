import { createSlice } from "@reduxjs/toolkit";
import { defaultGetBalanceValues } from "../../../shared/constants/main_container_constants";
import { BalanceDataState } from "../../interfaces/balanceData.interfaces";
import { getBalanceByMerchantId } from "../../thunks/balance/balance.thunk";

export const initialState: BalanceDataState = {
  balanceData: defaultGetBalanceValues,
};

export const balanceDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getBalanceByMerchantId.fulfilled, (state, { payload }) => {
        state.balanceData = payload;
      })
      .addCase(getBalanceByMerchantId.rejected, (state) => {
        state.balanceData = defaultGetBalanceValues;
      });
  },
  initialState,
  name: "BalanceData",
  reducers: {},
});

export default balanceDataSlice.reducer;
