export enum BoardMemberLabel {
  COUNTRY_NATURAL = "País de residencia:",
  COUNTRY_LEGAL = "País de domicilio:",
  DATE_NATURAL = "Nacimiento:",
  BIRTHDATE_NATURAL = "Fecha de nacimiento:",
  DATE_LEGAL = "Fecha de constitución de la empresa:",
  DOCUMENT = "Identificación:",
  NAME = "Nombre:",
  SOCIAL_REASON = "Razón social:",
  ID_TYPE = "Tipo de Id:",
  ID_NUMBER = "Número de Id:",
}
