import {
  getFormattedDateTime,
  subOneDay,
} from "../../../shared/utils/date_utils";
import { getFormattedTimezoneOffset } from "../../../shared/utils/timezone";

const changeTime = (formattedDateTime: string, time: string) => {
  const date = formattedDateTime.split(" ")[0];

  return `${date} ${time}`;
};

const getCurrentDateTime = () => {
  const today = new Date();
  const yesterday = subOneDay(today);

  const from = changeTime(getFormattedDateTime(yesterday), "00:00");
  const to = changeTime(getFormattedDateTime(today), "23:59");
  const timezone = getFormattedTimezoneOffset(today.getTimezoneOffset());

  return { from, timezone, to };
};

export default getCurrentDateTime;
