import React, { useEffect } from "react";
import { get, isEmpty } from "lodash";
import { InfoTrxProps } from "./ModalBodyTabs";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import ModalBodyMexico from "./ModalBodyMexico";
import ModalBodyPeruInfo from "./ModalBodyPeruInfo";
import ModalBodyColombia from "./ModalBodyColombia";
import { getAnnulledFile } from "../../store/actionCreators";
import { useDispatch } from "react-redux";
import ModalBodyRetentionHistoric from "./ModalBodyRetentionHistoric";
import ModalBodyChile from "./ModalBodyChile";
import ModalBodyEcuador from "./ModalBodyEcuador";

const ModalBodyInfo = (props: InfoTrxProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !isEmpty(props.trxInfo!.evidence) &&
      get(props.trxInfo, "evidence.fileEvidence") !== "" &&
      get(props.trxInfo, "evidence.fileEvidence") !== null
    ) {
      dispatch(getAnnulledFile(props.trxInfo!.evidence));
    }
  }, []);

  const renderCountryComponent = () => {
    switch (props.country) {
      case CountryEnum.peru:
        return <ModalBodyPeruInfo {...props} />;
      case CountryEnum.ecuador:
        return <ModalBodyEcuador {...props} />;
      case CountryEnum.mexico:
        return <ModalBodyMexico {...props} />;
      case CountryEnum.colombia:
        return <ModalBodyColombia {...props} />;
      case CountryEnum.chile:
        return <ModalBodyChile {...props} />;
      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      {[KindEnum.RETENTION_EC].includes(get(props.trxInfo, "kind", "")) ? (
        <ModalBodyRetentionHistoric
          trxInfo={props.trxInfo}
          handleOpenDialogDetail={props.handleOpenDialogDetail}
          handleCloseDialogDetail={props.handleCloseDialogDetail}
          handleChangeTab={props.handleChangeTab}
          pdfViewer={props.pdfViewer}
          downloadFileState={props.downloadFileState}
        />
      ) : (
        renderCountryComponent()
      )}
    </React.Fragment>
  );
};

export default ModalBodyInfo;
