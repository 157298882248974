export enum StatusFlowEnum {
  EXECUTOR = "executor",
  VALIDATOR = "validator",
}

export enum StatusFlowPath {
  EXECUTOR = "executor",
  VALIDATOR = "validator",
  RELOAD = "reload",
  DEFAULT = "",
}

export enum ProcessTransactionActionsEnum {
  PROCESS_TRANSACTION = "processTransaction",
  FINISH_PROCESS_TRANSACTION = "finishProcessTransaction",
  FINISH_PROCESS_RETENTION_TRANSACTION = "finishProcessRetention",
  LIST_RETENTIONS = "listRetentions",
}
