import { defaultTo, get, isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import {
  Configs,
  NodeInfoResponse,
} from "../../../../types/node_info_response";
import { environment } from "../../../environments/environment";
import {
  AllConfigs,
  ConfigIdEnum,
} from "../../../shared/constants/node_config_enums";
import { ROUTES } from "../../../shared/constants/routes";
import {
  buildLastModifyProps,
  buildSubHeaderTexts,
  generateChargeTabs,
  generateDefaultTabs,
  mapConfigurationByTabRoute,
  SUB_HEADER_DEFAULT_STATE,
} from "../../../shared/constants/wrapper_container_constants";
import { TypeRequestEnum } from "../../../shared/enums/typeRequestEnum";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { getStatesResume } from "../../../shared/utils/dispersionUtils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  CLEAN_VALUES_AND_SHOW_SNACK_ERROR,
  ENABLE_SAVE_BUTTON,
  HIDE_SNACKBAR,
  RESET_SHARED_VALUES,
  SET_FOOTER_BUTTON_CLICKED,
  SET_TYPE_REQUEST,
  UPDATE_ROUTE,
  updateLocalConfigResponse,
} from "../../../store/reducers/app/app.slice";
import {
  getBillingConfig,
  getMerchantNodeInfo,
  getNodeInfo,
} from "../../../store/thunks/app/app.thunks";
import { ILastModify } from "../../LastModify/LastModify.interfaces";
import { ISubheaderProps } from "../../Subheader/Subheader.interfaces";
import { ITabItem } from "../../WrappedTabs/TabItem/TabItem.interfaces";
import {
  IFooterButtons,
  IUseWrapperContainerProps,
  UseWrapperContainer,
} from "./useWrapperContainer.interfaces";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { SecurityWrapperByCountry } from "../SecurityWrapper";
import { useEffect, useState } from "react";
import { TypeOperationEnum } from "../../../shared/enums/FooterOptionsEnum";
import { handleTimeoutDispatch } from "../../../shared/utils/timeoutUtils";
import { EntityName } from "../../../shared/enums/entityName";
import {
  ConfigIdCharge,
  ConfigIdDispersion,
} from "../../../shared/enums/dispersionsEnum";
import { getTabsValue2 } from "../../../shared/utils/massiveBranchUtils";
import { CLEAN_NODE_INITIAL_INFO } from "../../../store/reducers/initialData/initialData.slice";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ConfigTypeEnum } from "../../../shared/enums/ConfigTypeEnum";

export const useWrapperContainer = ({
  currentRoute,
  type,
}: IUseWrapperContainerProps): UseWrapperContainer => {
  const {
    appState: {
      postHierarchyNode: statePostHierarchyNode,
      patchMerchantNodeInfo: statePatchMerchantNodeInfo,
      postRatesBilling: statePostRatesBilling,
    },
    stateCharge: { postChargeRates: statePostChargeRates },
    state: {
      postDispersionConfig: statePostDispersionConfig,
      postDispersionRates: statePostDispersionRates,
    },
    typeRequest: footerButtonType,
    openModalLoader,
    showSnackbarError,
    showSnackbarSuccess,
    currentRoute: currentRouteStore,
    buttons: { disabledSaveButton, disabledContinueButton },
    nodeInfo,
    configsResponse,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.charge,
    ...state.dispersion,
  }));

  const [country, setCountry] = useState<string>("");
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { navigate } = useAppNavigate();
  const publicMerchantId = searchParams.get("publicMerchantId");

  const [subHeaderTexts, setSubHeaderTexts] = useState<ISubheaderProps>(
    SUB_HEADER_DEFAULT_STATE
  );

  function isEcuadorCountry(): boolean {
    return get(nodeInfo, "generalInfo.country") === CountriesEnum.ECUADOR;
  }

  const [tabs, setTabs] = useState<ITabItem[]>(generateDefaultTabs());
  const [footerLabels, setFooterLabels] = useState<ILastModify | null>(null);
  const [footerButtons, setFooterButtons] = useState<IFooterButtons>({});
  const [isCurrentRouteUpdated, setIsCurrentRouteUpdated] = useState(
    currentRouteStore === currentRoute
  );
  const isEditOperationParam: boolean =
    searchParams.get("operation") === TypeOperationEnum.EDIT;

  const setSubheaderTexts = () => {
    setSubHeaderTexts(
      buildSubHeaderTexts(searchParams.get("publicMerchantId"), nodeInfo!)
    );
  };
  const requestMerchantNodeInfo = (value: string) => {
    const configId: string = environment.configIdBilling;

    dispatch(
      getMerchantNodeInfo({
        configId,
        publicMerchantId: value,
      })
    );
  };

  const requestBillingConfig = (value: string) => {
    dispatch(
      getBillingConfig({
        merchantId: value,
      })
    );
  };
  const updateLastModify = (configsResponse: Configs[]) => {
    const data = configsResponse.find(
      (config) =>
        get(config, "configuration") ===
        mapConfigurationByTabRoute.get(currentRoute)
    );

    const userName = get(data, "updatedBy");
    const updatedAt = get(data, "updatedAt");

    if (userName && updatedAt) {
      setFooterLabels(buildLastModifyProps(updatedAt, userName));
    }
  };
  const requestNodeInfo = () => {
    if (publicMerchantId && isEmpty(defaultTo(nodeInfo, {})))
      dispatch(
        getNodeInfo({
          configIds: AllConfigs,
          publicMerchantId,
        })
      );
  };

  const activeFooterButton = (type: string) => {
    dispatch(SET_TYPE_REQUEST(type));
    dispatch(SET_FOOTER_BUTTON_CLICKED(true));
  };

  const processConfigResponse = () => {
    let configs = undefined;

    if (!configsResponse && publicMerchantId) {
      configs = get(nodeInfo, "configs", []);
      dispatch(updateLocalConfigResponse(configs));
    }
  };

  const updateTabs = (tabsTemp: ITabItem[], configsResponse: Configs[]) => {
    setTabs(getTabsValue2(tabsTemp, configsResponse));
  };

  const hideSnackbarAfterTime = () => {
    handleTimeoutDispatch(dispatch, HIDE_SNACKBAR);
  };

  const handleValidCentralizedBranch = (
    configId: string,
    dataNode?: NodeInfoResponse
  ): boolean => {
    const config = get(dataNode, "configs", []).find(
      (c) => c.configuration === configId
    );

    return (
      get(dataNode, "entityName", "") === EntityName.BRANCH &&
      !isEmpty(get(config, "centralizedNodesId"))
    );
  };

  const getFooterButtonsDisabledValues = (nodeInfo?: NodeInfoResponse) => {
    return {
      [`${ROUTES.BILLING}`]: {
        disableContinue: handleValidCentralizedBranch(
          ConfigTypeEnum.CN003,
          nodeInfo
        ),
        disableSave: handleValidCentralizedBranch(
          ConfigTypeEnum.CN003,
          nodeInfo
        ),
        isHidden: isEditOperationParam,
      },
      [`${ROUTES.DISPERSION}`]: {
        disableContinue:
          disabledContinueButton ||
          handleValidCentralizedBranch(ConfigTypeEnum.CN004, nodeInfo),
        disableSave:
          handleValidCentralizedBranch(ConfigIdDispersion.CN004, nodeInfo) ||
          disabledSaveButton,
        isHidden: isEditOperationParam,
      },
      [`${ROUTES.CHARGE}`]: {
        disableContinue:
          disabledContinueButton ||
          handleValidCentralizedBranch(ConfigTypeEnum.CN017, nodeInfo),
        disableSave:
          handleValidCentralizedBranch(ConfigIdCharge.CN017, nodeInfo) ||
          disabledSaveButton,
        isHidden: isEditOperationParam,
      },
    }[currentRoute];
  };

  const securityIdPrimary = (): string => {
    const path = `${type}.footer.primary`;

    return get(SecurityWrapperByCountry[country!], path, "");
  };

  const securityIdSecondary = (): string => {
    const path = `${type}.footer.secondary`;

    return get(SecurityWrapperByCountry[country!], path, "");
  };

  useEffect(() => {
    dispatch(HIDE_SNACKBAR());
    dispatch(ENABLE_SAVE_BUTTON());
    dispatch(RESET_SHARED_VALUES());
    dispatch(UPDATE_ROUTE(currentRoute));

    return () => {
      dispatch(CLEAN_NODE_INITIAL_INFO());
    };
  }, []);

  useEffect(() => {
    setIsCurrentRouteUpdated(currentRouteStore === currentRoute);
  }, [currentRouteStore]);

  useEffect(() => {
    const isNodeInfoFromDifferentCurrentRoute =
      isEmpty(nodeInfo) || currentRouteStore !== currentRoute;

    if (isNodeInfoFromDifferentCurrentRoute) return;

    const configs: Configs[] = get(nodeInfo, "configs", []);
    const billingConfig: Configs | undefined = configs.find(
      (config) => get(config, "configuration") === ConfigIdEnum.CN003
    );
    const valueFilter: string = get(billingConfig, "value", "");

    if (get(nodeInfo, "entityName") !== EntityName.OWNER) {
      processConfigResponse();
      setSubheaderTexts();
    }
    if (!isEmpty(valueFilter) && currentRoute === ROUTES.BILLING) {
      requestMerchantNodeInfo(valueFilter);
      requestBillingConfig(valueFilter);
    }
  }, [nodeInfo, currentRouteStore]);

  useEffect(() => {
    if (showSnackbarError || showSnackbarSuccess) hideSnackbarAfterTime();
  }, [showSnackbarError, showSnackbarSuccess]);

  useEffect(() => {
    if (configsResponse) {
      updateTabs(tabs, configsResponse);
      updateLastModify(configsResponse);
    }
  }, [configsResponse]);

  useEffect(() => {
    if (!nodeInfo) return;
    if (isEcuadorCountry()) {
      const configsResponseTemp = get(nodeInfo, "configs", []);

      setTabs(generateChargeTabs());
      if (configsResponseTemp)
        updateTabs(generateChargeTabs(), configsResponseTemp);
    } else if (!isEcuadorCountry() && currentRoute == ROUTES.CHARGE) {
      navigate(ROUTES.DISPERSION);
    }
    setCountry(get(nodeInfo, "generalInfo.country", ""));
    securityIdSecondary();
    securityIdPrimary();
  }, [nodeInfo]);

  useEffect(() => {
    setFooterButtons(getFooterButtonsDisabledValues(nodeInfo));
  }, [disabledSaveButton, disabledContinueButton, nodeInfo]);

  useEffect(() => {
    const { isFailed: failSomeSaveDataFetch } = getStatesResume([
      statePatchMerchantNodeInfo,
      statePostRatesBilling,
      statePostDispersionRates,
      statePostChargeRates,
      statePostDispersionConfig,
      statePostHierarchyNode,
    ]);

    if (failSomeSaveDataFetch) dispatch(CLEAN_VALUES_AND_SHOW_SNACK_ERROR());
  }, [
    statePatchMerchantNodeInfo,
    statePostRatesBilling,
    statePostDispersionRates,
    statePostChargeRates,
    statePostDispersionConfig,
    statePostHierarchyNode,
  ]);

  useEffect(() => {
    if (isEmpty(nodeInfo) && publicMerchantId) {
      requestNodeInfo();
    }
  }, [publicMerchantId]);

  useEffect(() => {
    if (!statePostHierarchyNode) return;

    const { isSuccess: fetchesEndsSuccessful } = getStatesResume([
      statePostHierarchyNode,
    ]);

    if (fetchesEndsSuccessful) {
      dispatch(RESET_SHARED_VALUES());
      if (footerButtonType === TypeRequestEnum.CONTINUE) {
        const route = isEcuadorCountry()
          ? {
              [`${ROUTES.BILLING}`]: ROUTES.DISPERSION,
              [`${ROUTES.DISPERSION}`]: ROUTES.CHARGE,
              [`${ROUTES.CHARGE}`]: ROUTES.PROCESSING,
            }
          : {
              [`${ROUTES.BILLING}`]: ROUTES.DISPERSION,
              [`${ROUTES.DISPERSION}`]: ROUTES.PROCESSING,
            };

        if (route[currentRoute] === ROUTES.PROCESSING)
          window.location.href = API_ROUTES.PROCESSING(publicMerchantId!);

        navigate(route[currentRoute]);
      }

      if (footerButtonType === TypeRequestEnum.SAVE) {
        handleTimeoutDispatch(dispatch, ENABLE_SAVE_BUTTON);
      }
    }
  }, [statePostHierarchyNode]);

  return {
    activeFooterButton,
    footerButtons,
    footerLabels,
    isEditOperationParam,
    openModalLoader: openModalLoader && isCurrentRouteUpdated,
    operationCountry: country,
    securityIdPrimary,
    securityIdSecondary,
    showSavedButtonText: statePostHierarchyNode === FetchStateEnum.SUCCESS,
    snackbar: {
      showError: showSnackbarError && isCurrentRouteUpdated,
      showSuccess: showSnackbarSuccess && isCurrentRouteUpdated,
    },
    subHeaderTexts,
    tabs,
  };
};
