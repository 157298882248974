import { makeStyles } from "@mui/styles";

export const snackbarSmartlinkActiveStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#F0F4F9",
    borderRadius: "4px",
    gap: "8px",
    flex: "none",
    order: 10,
    flexGrow: 0,
  },
  iconButton: {
    paddingRight: "8px",
    paddingLeft: "16px",
  },
  text: {
    fontSize: "16px !important",
    fontFamily: "IBM Plex Sans !important",
    fontStyle: "normal !important",
    color: "#6D7781",
  },
});
