import { FC } from "react";
import { CellCheckItem } from "../../components/TableCells/CellCheck/CellCheck";

import {
  ITableCellPropsMassive,
  ITableRowPropsMassive,
} from "../../components/Table/TableNodes/interfaces";
import { CellTextCopy } from "../../components/TableCells/CellTextCopy/CellTextCopy";
import { CellTitleItem } from "../../components/TableCells/CellTitle/CellTitle";
import { CellChipStatus } from "../../components/TableCells/CellChip/CellChipStatus";
import { defaultTo, get, isEmpty } from "lodash";
import { MerchantNodeData } from "../../../types/search_merchant_response";
import { format, parseISO } from "date-fns";
import { StatusEnum } from "../enum/StatusEnum";

enum TableBodyCellMassiveEnum {
  CHECK = "CHECK",
  TITLE = "TITLE",
  TEXT_COPY = "TEXT_COPY",
  TAG = "TAG",
}

interface IConfig {
  //Valor por defecto en caso que no se obtenga un valor del objeto con el path.
  defaultValue?: any;
  //Path para obtener el valor del objeto.
  path: string;
  //Text que se mostrara en la tabla, se agrega $var para mostrar la posición donde estara el valor obtenido del objeto.
  text: string;
  isDate?: boolean;
  hasUrl?: boolean;
}

export interface IConfigCellBasic {
  configMain?: IConfig;
  configSecondary?: IConfig;
  type: TableBodyCellMassiveEnum;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

interface IConfigCellCheck extends IConfigCellBasic {
  isChecked: boolean;
}
interface IConfigCellFlag extends IConfigCellBasic {
  isCodeCountry?: boolean;
}

export type IConfigCells =
  | IConfigCellBasic
  | IConfigCellCheck
  | IConfigCellFlag;

export interface IAdditionalProps {
  handleGetRowData?: () => MerchantNodeData;
}

const CELL_COMPONENTS_BY_TABLE_ENUM: Record<
  TableBodyCellMassiveEnum,
  FC<any>
> = {
  [TableBodyCellMassiveEnum.CHECK]: CellCheckItem,
  [TableBodyCellMassiveEnum.TITLE]: CellTitleItem,
  [TableBodyCellMassiveEnum.TEXT_COPY]: CellTextCopy,
  [TableBodyCellMassiveEnum.TAG]: CellChipStatus,
};

const formatDate = (
  dateString: string,
  formatString: string = "dd/MM/yyyy"
): string => {
  let formattedDate: string = "";

  try {
    formattedDate = format(parseISO(dateString), formatString);
  } catch (_) {}

  return formattedDate;
};

function generateText<T = object>(
  obj: T,
  text: string = "",
  path: string,
  defaultValue?: string,
  isDate: boolean = false
): string {
  const required_value = get(obj, path, defaultTo(defaultValue, ""));

  if (isDate) {
    const formattedDate: string = formatDate(required_value);

    return text.replace("$var", formattedDate);
  }

  return text.replace("$var", required_value);
}

export const truncateText = (label: string, length: number) => {
  if (label.length < length) {
    return label;
  }

  const sliceLabel: string = label.split("").slice(0, length).join("");

  return `${sliceLabel}...`;
};

function generateUrl<T>(obj: T, hasUrl: boolean = false): string | undefined {
  const merchant_id: string | undefined = get(obj, "merchant_id");

  if (hasUrl && merchant_id) {
    return get(obj, "merchant_status") === StatusEnum.PENDING
      ? `/merchant/basic-details?publicMerchantId=${merchant_id}&menuItem=true`
      : `/merchant-resume?publicMerchantId=${merchant_id}`;
  }

  return undefined;
}

export function builderRows<T = object>(
  data: T[],
  configRows: IConfigCells[],
  page: string = ""
): ITableRowPropsMassive<T>[] {
  const rows_props: ITableRowPropsMassive<T>[] = [];
  let table_cells: ITableCellPropsMassive[];
  let cell: ITableCellPropsMassive;

  data.map((obj: T, index: number) => {
    table_cells = [];
    configRows.map((config: IConfigCells) => {
      switch (config.type) {
        case TableBodyCellMassiveEnum.CHECK:
          cell = {
            props: {
              align: get(config, "align"),
              configsComplete: false,
              id: { merchant_id: "" },
              isChecked: false,
            },
            type: TableBodyCellMassiveEnum.CHECK,
          };
          break;
        case TableBodyCellMassiveEnum.TITLE:
          cell = {
            props: {
              align: get(config, "align"),
              line1: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false)
              ),
              line2: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                get(config, "configSecondary.isDate", false)
              ),
              type: !isEmpty(get(config, "configSecondary.path"))
                ? "twoLines"
                : "oneLine",
              url: generateUrl(obj, get(config, "configMain.hasUrl")),
            },
            type: TableBodyCellMassiveEnum.TITLE,
          };
          break;
        case TableBodyCellMassiveEnum.TEXT_COPY:
          const info = generateText<T>(
            obj,
            get(config, "configMain.text"),
            get(config, "configMain.path"),
            get(config, "configMain.defaultValue")
          );

          cell = {
            props: {
              align: get(config, "align"),
              textToCopy: info,
              title: truncateText(info, 8),
            },
            type: TableBodyCellMassiveEnum.TEXT_COPY,
          };
          break;

        case TableBodyCellMassiveEnum.TAG:
          cell = {
            props: {
              align: get(config, "align"),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
            },
            type: TableBodyCellMassiveEnum.TAG,
          };
          break;
      }
      table_cells.push(cell);
    });
    rows_props.push({
      cells: table_cells,
      id: index.toString().concat(`.${page}`),
      info: obj,
    });
  });

  return rows_props;
}

export { TableBodyCellMassiveEnum, CELL_COMPONENTS_BY_TABLE_ENUM };
