import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from "react-hook-form";
import { useStyles as styles } from "./BillingDataFormCountries.styles";
import React, { ChangeEvent } from "react";
import { Divider, Grid, TextField } from "@mui/material";

import { get } from "lodash";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { ErrorBillingData } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import AddressInputField from "../commonInputs/AddressInputField/AddressInputField";
import { CountryEnum } from "../../shared/countryEnum";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";
import { ZipCodeComponent } from "../ZipCodeComponent/ZipCodeComponent";
import { RegularExp } from "../../shared/constants/form_labels_legal_details";

export type formProps = {
  country: CountryEnum;
  form: UseFormReturn<MerchantCompanyRequest>;
  infoBilling: {
    city: string;
    constitutionalCountry: string;
    country: string;
    municipality: string;
    populationCode: string;
    province: string;
    satCity: string;
    satMunicipality: string;
    satPopulationCode: string;
    satProvince: string;
    satStateCode: string;
    stateCode: string;
    useSameInfoSat: boolean;
  };
  isCentralizedNode: boolean;
};

const BillingDataFormCountriesForm: React.FC<formProps> = (
  props: formProps
) => {
  const classes = styles();

  const onChangeRegExp = (
    field: ControllerRenderProps<MerchantCompanyRequest, FieldsEnum.city>,
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      e.target.value === "" ||
      new RegExp(RegularExp.ONLY_LETTERS_CITY).test(
        e.target.value.toUpperCase()
      )
    )
      field.onChange(e.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name={FieldsEnum.city}
            control={props.form.control}
            rules={{
              required: ErrorBillingData.INPUT_CITY,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-basic"
                fullWidth
                label={BillingDataFormLabelsEnum.city}
                variant="outlined"
                defaultValue={props.form.watch(FieldsEnum.city)}
                error={!!get(props.form.formState.errors, FieldsEnum.city)}
                helperText={
                  get(props.form.formState.errors, FieldsEnum.city)?.message ??
                  ""
                }
                onChange={(e) => {
                  onChangeRegExp(field, e);
                }}
                disabled={props.isCentralizedNode}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <ZipCodeComponent
            {...props.form}
            watch={props.form.watch}
            country={CountryEnum.generic}
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInputField
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            control={props.form.control}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            rules={{
              required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY },
            }}
            country={props.infoBilling.constitutionalCountry as CountryEnum}
            countryCustomer={props.infoBilling.country as CountryEnum}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

export default BillingDataFormCountriesForm;
