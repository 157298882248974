import { ISubheaderProps } from "../../components/Subheader/Subheader.interfaces";
import { MerchantNodeResponse } from "../../../types/merchant_node_response";
import { Routes } from "./routes/routes";
import { GetBalanceResponse } from "../../../types/get_balance_response";

export const MERCHANT_QUERY_PARAM = "publicMerchantId";
export const defaultSubheaderValues: ISubheaderProps = {
  country: "",
  id: "",
  redirectPath: "",
  title: "",
};

export const defaultMerchantNodeValues: MerchantNodeResponse = {
  constitutionalCountry: "",
  country: "",
  name: "",
  publicMerchantId: "",
};

export const defaultGetBalanceValues: GetBalanceResponse = {
  balance: 0,
  countableBalance: 0,
  unprocessedBalance: 0,
};

export const buildSubheaderInfo = (merchantNode: MerchantNodeResponse) => ({
  country: merchantNode.country,
  id: merchantNode.publicMerchantId,
  redirectPath: `${Routes.CREATE_CUSTOMER}${merchantNode.publicMerchantId}`,
  title: "Billetera",
});
