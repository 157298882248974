import React from "react";
import { Box, Typography } from "@mui/material";
import { IInfoTextProps } from "./InfoText.interfaces";

const InfoText = ({ text }: IInfoTextProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      sx={{ pb: 1, pl: 1 }}
      alignItems={"center"}
    >
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <svg
          role={"svg-test-id"}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00033 15.3327C6.54993 15.3327 5.13211 14.9026 3.92615 14.0968C2.72019 13.291 1.78025 12.1457 1.22521 10.8057C0.67017 9.46571 0.524945 7.99122 0.807903 6.56869C1.09086 5.14616 1.78929 3.83949 2.81488 2.8139C3.84046 1.78832 5.14714 1.08989 6.56967 0.806927C7.99219 0.523969 9.46668 0.669193 10.8067 1.22424C12.1467 1.77928 13.292 2.71921 14.0978 3.92517C14.9036 5.13113 15.3337 6.54896 15.3337 7.99935C15.3337 9.94427 14.561 11.8095 13.1858 13.1848C11.8105 14.5601 9.94525 15.3327 8.00033 15.3327ZM8.00033 1.99935C6.81364 1.99935 5.6536 2.35125 4.66691 3.01053C3.68021 3.66982 2.91118 4.60689 2.45705 5.70325C2.00293 6.79961 1.88411 8.00601 2.11562 9.16989C2.34713 10.3338 2.91857 11.4029 3.75769 12.242C4.5968 13.0811 5.6659 13.6526 6.82979 13.8841C7.99367 14.1156 9.20007 13.9968 10.2964 13.5426C11.3928 13.0885 12.3299 12.3195 12.9891 11.3328C13.6484 10.3461 14.0003 9.18604 14.0003 7.99935C14.0003 6.40805 13.3682 4.88193 12.243 3.75671C11.1178 2.63149 9.59163 1.99935 8.00033 1.99935Z"
            fill="#023365"
          />
          <path
            d="M8.00033 5.33268C8.17714 5.33268 8.34671 5.40292 8.47173 5.52794C8.59675 5.65297 8.66699 5.82254 8.66699 5.99935L8.66699 11.9993C8.66699 12.1762 8.59675 12.3457 8.47173 12.4708C8.34671 12.5958 8.17714 12.666 8.00033 12.666C7.82351 12.666 7.65394 12.5958 7.52892 12.4708C7.4039 12.3457 7.33366 12.1762 7.33366 11.9993L7.33366 5.99935C7.33366 5.82254 7.4039 5.65297 7.52892 5.52794C7.65395 5.40292 7.82351 5.33268 8.00033 5.33268Z"
            fill="#023365"
          />
          <path
            d="M8.00014 3.33248C8.08788 3.33198 8.17485 3.34879 8.25608 3.38197C8.3373 3.41514 8.41118 3.46403 8.47347 3.52582C8.53236 3.59062 8.57971 3.66503 8.61347 3.74582C8.65079 3.82494 8.66906 3.9117 8.66681 3.99915C8.66435 4.17566 8.5953 4.34473 8.47347 4.47248C8.3965 4.55275 8.30067 4.61249 8.19471 4.64626C8.08875 4.68003 7.97603 4.68675 7.86681 4.66582L7.74681 4.62582C7.70432 4.61123 7.66397 4.59105 7.6268 4.56582C7.59188 4.5412 7.55849 4.51449 7.52681 4.48582C7.40182 4.35472 7.33253 4.18028 7.33347 3.99915C7.33296 3.91141 7.34978 3.82444 7.38296 3.74321C7.41613 3.66199 7.46502 3.58811 7.52681 3.52582C7.59161 3.46693 7.66602 3.41958 7.74681 3.38582C7.82695 3.35165 7.91302 3.33354 8.00014 3.33248Z"
            fill="#023365"
          />
        </svg>
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Typography
          variant={"overline"}
          color={"text.grey"}
          aria-label={"info-text-test-id"}
          sx={{
            marginTop: "0px !important",
            px: 1,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoText;
