import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../src/shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { prefixes } from "../../../shared/constants/prefixes";

export const getMerchantNodes = createAsyncThunk(
  prefixes.MERCHANT_NODE,
  async (value: { value: string; limit: number; entityName: string }) => {
    const requestObject: SearchMerchantRequest = {
      entityName: value.entityName,
      isActive: true,
      limit: value.limit,
      name: value.value,
      offset: 0,
    };
    const responseMerchantNodes = await axios.post<SearchMerchantResponse>(
      API_ROUTES.MERCHANT_NODES,
      requestObject
    );

    return responseMerchantNodes.data;
  }
);

export default getMerchantNodes;
