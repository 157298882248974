import { IRootState } from "../../interfaces/RootState.interfaces";

export const selectLoading = (state: IRootState) => state.app.loading;
export const openSuccessSnackbar = (state: IRootState) =>
  state.app.openSuccessSnackbar;
export const openErrorSnackbar = (state: IRootState) =>
  state.app.openErrorSnackbar;

export const selectTotalConciliation = (state: IRootState) =>
  state.app.totalConciliations;

export const selectConciliationAction = (state: IRootState) =>
  state.app.conciliationAction;
