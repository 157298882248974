/**
 * Catalog Panama
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogGt: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    {
      name: "AGROMERCANTIL",
      value: "3001",
    },
    {
      name: "BANCO AZTECA",
      value: "3002",
    },
    {
      name: "BANCO DE ANTIGUA",
      value: "3003",
    },
    {
      name: "BANCO BAC",
      value: "3004",
    },
    {
      name: "BANCO CITI",
      value: "3005",
    },
    {
      name: "BANCO CREDITO HIPOTECARIO",
      value: "3006",
    },
    {
      name: "BANCO BANTRAB",
      value: "3007",
    },
    {
      name: "BANCO G&T CONTINENTAL",
      value: "3008",
    },
    {
      name: "BANCO INDUSTRIAL",
      value: "3009",
    },
    {
      name: "BANCO INMOBILIARIO",
      value: "3010",
    },
    {
      name: "BANCO INTERNACIONAL",
      value: "3011",
    },
    {
      name: "BANCO FICOHSA",
      value: "3012",
    },
    {
      name: "BANCO REFORMADOR",
      value: "3013",
    },
    {
      name: "BANCO VIVIBANCO",
      value: "3014",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.GTQ} - Quetzales`,
      value: CurrencyEnum.GTQ,
    },
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
};
