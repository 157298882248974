import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { IUseTransactionContainer } from "./useTransactionContainer.interfaces";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  getMerchantInfo,
  getMerchantsRequest,
  getNodeInfo,
  getRetrieveChildren,
  notifySelectCheckChipsChange,
} from "../../../store/thunks/app/app.thunks";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { get, isEmpty } from "lodash";
import { findConfigurationValue } from "../../../shared/utils/utils";
import { ConfigEnum } from "../../../shared/enums/ConfigEnum";
import { searchMerchants } from "../../../store/thunks/merchantNode/merchantNode.thunks";
import { ListButtons } from "../../../store/reducers/merchantNode/merchantNode.slice";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { setSelectItems } from "../../../store/actions/merchantNode.actios";
import { joinItemList } from "../../../shared/utils/joinItemList";
import { isCustomerRol } from "../../../shared/utils";
import { auth } from "../../../shared/auth";
import { ItemCategoryItem } from "../../../components/Filters/ItemCategory/interfaces";
import { IAllFilters } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheckChips/SelectCheckChips.interfaces";
import { listChips } from "../../../shared/constants/DefaultColumns";
import {
  buildMerchantRequest,
  getEntityName,
} from "../../../shared/utils/transaction_container_utils";
import { RetrieveChildrenRequest } from "../../../../types/retrive_children_request";

export const useTransactionContainer = (): IUseTransactionContainer => {
  const { merchantInfo, nodeInfo, listButton, loadingMerchants, merchants } =
    useAppSelector((state) => ({
      ...state.app,
      ...state.customerReducer,
    }));

  const dispatch = useAppDispatch();
  const publicMerchantId = localStorage.getItem("merchantId")!;
  const isMasterCustomer: boolean = useMemo(() => isCustomerRol(), []);

  const isCustomer: boolean = useMemo(() => {
    return EntityNameEnum.CUSTOMER === nodeInfo.entityName;
  }, [nodeInfo]);

  const [searchValue, setSearchValue] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<ICategory[]>([]);
  const [merchantsName, setMerchantsName] = useState<ICategory[]>([]);
  const [merchantNameTimeOut, setMerchantNameTimeOut] = useState<any>(0);
  const [merchantChips, setMerchantChips] =
    useState<ItemCategoryItem[]>(listChips);
  const [mechantFilter, setMechantFilter] = useState<IAllFilters>({
    chipList: [],
    itemsList: [],
  });

  const [subTitle, setSubTitle] = useState<string>("");
  const firstUpdate = useRef(true);

  const setBranches = (data: ListButtons[] | ICategory[]) => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }
    dispatch(setSelectItems(data));
    if (data.length > 0) {
      setSubTitle(joinItemList(data, ", ", "."));
    }
  };

  const handleSearchText = (value: string) => {
    setSearchValue(value);
    if (merchantNameTimeOut) clearTimeout(merchantNameTimeOut);

    setMerchantNameTimeOut(
      setTimeout(() => {
        dispatch(
          getMerchantsRequest(buildMerchantRequest(merchantChips, value))
        );
      }, 1500)
    );
  };

  const setMerchantNames = () => {
    const names_aux: ICategory[] = [...selectedItems];

    merchants.data.forEach((item) => {
      names_aux.push({
        description: `${get(item, "publicMerchantId")} - ${getEntityName(
          get(item, "entityName", EntityNameEnum.MERCHANT)
        )}`,
        label: get(item, "name"),
        selected: false,
        value: get(item, "publicMerchantId", ""),
      });
    });

    setMerchantsName(names_aux);
  };

  const handleChipChange = (chips: ItemCategoryItem[]) => {
    setMerchantChips(chips);
    dispatch(getMerchantsRequest(buildMerchantRequest(chips, searchValue)));
  };

  const handleItemSelected = (selectedItems: ICategory[]) => {
    setSelectedItems(selectedItems);
  };

  const handleTotalFilters = (items: IAllFilters) => {
    setMechantFilter(items);
    let selected_request: RetrieveChildrenRequest[] = [];

    selectedItems.forEach((item) => {
      selected_request.push({
        entityName: get(item, "description", "").split("-")[1].trim(),
        publicMerchantId: item.value,
      });
    });
    dispatch(
      notifySelectCheckChipsChange({
        hasChange: true,
      })
    );
    dispatch(
      getRetrieveChildren({
        body: selected_request,
        isTransactionSearch: !(selectedItems.length > 0),
      })
    );
  };

  useEffect(() => {
    if (!auth.isAdmin())
      dispatch(
        getNodeInfo({
          publicMerchantId,
        })
      );
    else dispatch(getMerchantsRequest({}));
  }, []);

  useEffect(() => setMerchantNames(), [merchants]);

  useEffect(() => {
    if (!isEmpty(nodeInfo.generalInfo?.name)) {
      const configurations = nodeInfo.configs || [];

      const basicDataValue: string = findConfigurationValue(
        configurations,
        ConfigEnum.BASIC_DATA,
        publicMerchantId
      );

      dispatch(getMerchantInfo({ publicMerchantId: basicDataValue }));

      if (isCustomer) {
        dispatch(
          searchMerchants({
            filter: {
              entityName: [EntityNameEnum.BRANCH],
            },
            limit: 5000,
            offset: 1,
            sort: {
              field: "name",
              order: "asc",
            },
          })
        );
      }
    }
  }, [nodeInfo]);

  return {
    isCustomer,
    isMasterCustomer,
    listBranches: listButton.length > 0 ? listButton : [],
    merchantInfo,
    selectCheckChips: {
      handleChipChange,
      handleItemSelected,
      handleSearchText,
      handleTotalFilters,
      loadingMerchants,
      mechantFilter,
      merchantsName,
    },
    setBranches,
    subTitle,
  };
};
