import { IStatus } from "../../components/ButtonCard/ButtonCard.interfaces";

export enum StatusEnum {
  COMPLETE = "complete",
  PENDING = "pending",
  DISABLED = "disabled",
  SELECTED = "selected",
  NOT_SELECTED = "notSelected",
  OMITTED = "omitted",
}

const COMPLETE: IStatus = {
  checked: true,
  isDisabled: false,
  isSelected: false,
};

const PENDING: IStatus = {
  checked: false,
  isDisabled: false,
  isSelected: false,
};

const DISABLED: IStatus = {
  checked: false,
  isDisabled: true,
  isSelected: false,
};

const SELECTED: IStatus = {
  checked: false,
  isDisabled: false,
  isSelected: true,
};

const NOT_SELECTED: IStatus = {
  checked: false,
  isDisabled: false,
  isSelected: false,
};

export const BUTTON_CARD_STATUS_PROPS = {
  [StatusEnum.COMPLETE]: COMPLETE,
  [StatusEnum.DISABLED]: DISABLED,
  [StatusEnum.PENDING]: PENDING,
  [StatusEnum.SELECTED]: SELECTED,
  [StatusEnum.NOT_SELECTED]: NOT_SELECTED,
};

export const LIST_STATUS_SIDEBAR_COMPLETE: string[] = [
  StatusEnum.COMPLETE,
  StatusEnum.OMITTED,
];
