import React, { FC } from "react";
import { Skeleton, Typography } from "@mui/material";
import { ITextBlockProps } from "./TextBlock.interface";

export const TextBlock: FC<ITextBlockProps> = (props: ITextBlockProps) => (
  <>
    <Typography color="text.dark" variant="subtitle2">
      {props.isLoading ? (
        <Skeleton
          data-testid="title-skeleton"
          sx={{
            width: {
              md: "50%",
              xs: "100%",
            },
          }}
        />
      ) : (
        props.title
      )}
    </Typography>
    <Typography color="text.primary" variant="overline">
      {props.isLoading ? (
        <Skeleton
          data-testid="subtitle-skeleton"
          sx={{
            width: {
              md: "25%",
              xs: "75%",
            },
          }}
        />
      ) : (
        props.subtitle
      )}
    </Typography>
  </>
);
