import { Controller, UseFormReturn } from "react-hook-form";
import { useStyles as styles } from "./BillingDataFormCountries.styles";
import React, { useEffect } from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import { LabelsEnum } from "../../shared/enum/LabelsEnum";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import {
  BillingLabelsEC,
  ErrorBillingData,
  ErrorMessageMX,
} from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import {
  getActivityRules,
  getCitiesRules,
  getIncomeSourceConceptRules,
  getProvinceRules,
  getTaxPayerIdRules,
} from "../../shared/constants/billing_form_constants";
import AddressInputField from "../commonInputs/AddressInputField/AddressInputField";
import { CountryEnum } from "../../shared/countryEnum";
import SelectInputField from "../commonInputs/SelectInputField/SelectInputField";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";
import { Category } from "../../shared/constants/information_constants";
import {
  AgregateModelOptions,
  CatalogEc,
} from "../../shared/services/catalog-ec";
import { CatalogsEnum } from "../../shared/interfaces/catalogs-enum";
import { ZipCodeComponent } from "../ZipCodeComponent/ZipCodeComponent";
import { radioButtonRowStyles } from "../RadioButtonRow/RadioButtonRow.style";
import {
  CatalogEC,
  YES_NO_VALUES,
} from "../../shared/enum/BasicStateEC/CatalogBasicDataEC";
import { TypeCatalog } from "../../shared/enum/BasicStateGeneral/ErrorMessage";
import { Category as CategoryIdLabel } from "../../shared/enum/BasicStateGeneral/CatalogBasicData";
import CheckBoxField from "../commonInputs/CheckBoxField/CheckBoxField";
import { useAppDispatch, useAppSelector } from "../../store/hooks/storeHook";
import { RootState } from "../../store/store";
import { getListCatalogs } from "../../store/thunks/catalogs/catalogs.thunk";
import { ConfigCodeEnum } from "../../shared/enums/ConfigCodeEnum";
import { CountryCodeEnum } from "../../shared/enums/CountryCodeEnum";
import { ProductLineEnum } from "../../shared/enums/ProductLineEnum";
import { Data } from "../../../types/catalog_response";
import { CatalogsNameEnum } from "../../shared/enums/CatalogsNameEnum";
import { FormSkeleton } from "../Skeletons/FormSkeleton/FormSkeleton";
import { getCatalogByName } from "../../shared/utils/listCatalogUtils";

export type formPropsEcu = {
  cityEcuadorCatalog: Category[];
  form: UseFormReturn<MerchantCompanyRequest>;
  economicActivityId: string;
  isDisableEconomicActivity: boolean;
  isCentralizedNode: boolean;
  constitutionalCountry: CountryEnum;
  valueEconomicActivitySRI: string;
  country?: CountryEnum;
};

export const BillingDataFormCountriesEC: React.FC<formPropsEcu> = (
  props: formPropsEcu
) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { listCatalogs, isLoading } = useAppSelector(
    (state: RootState) => state.catalogs
  );
  let conceptRetentionRentList: Data[] = [];
  let activityList: Data[] = [];
  let taxPayerList: Data[] = [];

  const isAggregatorModel = (): boolean =>
    props.form.getValues("isPaymentAggregator") === YES_NO_VALUES.YES;

  const isRenderAggregatorModel = (loading: boolean): boolean =>
    isAggregatorModel() && loading;

  if (isAggregatorModel()) {
    conceptRetentionRentList = getCatalogByName(
      listCatalogs,
      CatalogsNameEnum.INCOME_SOURCE_CONCEPT
    );
    activityList = getCatalogByName(listCatalogs, CatalogsNameEnum.ACTIVITY);
    taxPayerList = getCatalogByName(
      listCatalogs,
      CatalogsNameEnum.TAX_PAYER_TYPE
    );
  }

  useEffect(() => {
    if (isAggregatorModel() && listCatalogs.length === 0) {
      dispatch(
        getListCatalogs({
          configCode: ConfigCodeEnum.BILLING,
          countryCode: CountryCodeEnum.EC,
          productLine: ProductLineEnum.PLA,
        })
      );
    }
  }, [props.form.getValues(FieldsEnum.isPaymentAggregator)]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.province}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.province)}
            label={BillingDataFormLabelsEnum.province}
            rules={getProvinceRules()[CountryEnum.ecuador]}
            itemsSelect={get(CatalogEc, `${CatalogsEnum.Provinces}`, []).map(
              (item: Category) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              )
            )}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.city}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.city)}
            label={BillingDataFormLabelsEnum.city}
            rules={getCitiesRules()[CountryEnum.ecuador]}
            itemsSelect={props.cityEcuadorCatalog.map((item: Category) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <ZipCodeComponent
            {...props.form}
            watch={props.form.watch}
            country={CountryEnum.ecuador}
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInputField
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            {...props.form}
            control={props.form.control}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            rules={{
              required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY },
            }}
            country={props.constitutionalCountry}
            countryCustomer={props.country}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.dark" fontSize={16}>
            {BillingLabelsEC.aggregateModel}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={FieldsEnum.isPaymentAggregator}
            rules={{
              required: {
                message: ErrorMessageMX.EC_AGREGATE_MODEL,
                value: true,
              },
            }}
            control={props.form.control}
            defaultValue={props.form.getValues(FieldsEnum.isPaymentAggregator)}
            render={({ field }: any) => {
              return (
                <RadioGroup {...field} aria-label="gender" row>
                  {AgregateModelOptions.map((user) => (
                    <FormControlLabel
                      sx={radioButtonRowStyles.buttonRow}
                      value={user.value}
                      label={user.name}
                      key={user.name}
                      control={<Radio color="primary" />}
                      disabled={props.isCentralizedNode}
                    />
                  ))}
                </RadioGroup>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.satDivider} />
        </Grid>
        {isRenderAggregatorModel(!isLoading) && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                {LabelsEnum.RETENTION_DATA}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <SelectInputField
                name={FieldsEnum.economicActivitySRI}
                control={props.form.control}
                errors={props.form.formState.errors}
                defaultValue={props.valueEconomicActivitySRI}
                label={BillingDataFormLabelsEnum.activitySRI}
                rules={getActivityRules()[CountryEnum.ecuador]}
                itemsSelect={CatalogEC[TypeCatalog.CIUU].map(
                  (item: CategoryIdLabel) => (
                    <MenuItem key={item.id} value={item.id}>
                      {`${item.id} - ${item.label}`}
                    </MenuItem>
                  )
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInputField
                disabled={props.isCentralizedNode}
                name={FieldsEnum.taxpayerTypeId}
                control={props.form.control}
                errors={props.form.formState.errors}
                defaultValue={props.form.watch(FieldsEnum.taxpayerTypeId)}
                label={BillingDataFormLabelsEnum.taxpayerTypeId}
                rules={getTaxPayerIdRules()[CountryEnum.ecuador]}
                itemsSelect={taxPayerList.map(
                  (item: { code: string; value: string }) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.value}
                    </MenuItem>
                  )
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInputField
                disabled={props.isCentralizedNode}
                name={FieldsEnum.activityId}
                control={props.form.control}
                errors={props.form.formState.errors}
                defaultValue={props.form.watch(FieldsEnum.activityId)}
                label={BillingDataFormLabelsEnum.activityEC}
                rules={getActivityRules()[CountryEnum.ecuador]}
                itemsSelect={activityList.map(
                  (item: { code: string; value: string }) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.value}
                    </MenuItem>
                  )
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInputField
                disabled={props.isCentralizedNode}
                name={FieldsEnum.incomeSourceConceptId}
                control={props.form.control}
                errors={props.form.formState.errors}
                defaultValue={props.form.watch(
                  FieldsEnum.incomeSourceConceptId
                )}
                label={BillingDataFormLabelsEnum.conceptRetentionRent}
                rules={getIncomeSourceConceptRules()[CountryEnum.ecuador]}
                itemsSelect={conceptRetentionRentList.map(
                  (item: { code: string; value: string }) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.value}
                    </MenuItem>
                  )
                )}
              />
            </Grid>
            <Grid item display={"flex"} xs={12} flexDirection={"row"}>
              <CheckBoxField
                hidden={true}
                disabled={props.isCentralizedNode}
                name={FieldsEnum.useSameInfoSat}
                control={props.form.control}
                rules={{}}
                errors={props.form.formState.errors}
                checkBoxValue={false}
                checkBoxText={BillingDataFormLabelsEnum.checkUseBasicData}
              />
            </Grid>
          </>
        )}
        {isRenderAggregatorModel(isLoading) && <FormSkeleton rows={4} />}
      </Grid>
    </>
  );
};
export default BillingDataFormCountriesEC;
