import { BankConciliationStatusEnum } from "../BankConciliationStatusEnum";

export interface ITabHeader {
  index: number;
  label: string;
}

export enum TabsTitle {
  PENDING = "Pendientes",
  HISTORIC = "Histórico",
}

export enum ErrorsWS {
  ERROR_WHEN_DOWNLOADING_FILE = "Ha ocurrido un error al descargar el archivo",
  NO_FILE_FOR_DOWNLOADING = "No existen registros a descargar",
  SOCKET_ENDPOINT_ERROR = "Se presentó un error inesperado. Inténtalo nuevamente.",
}

export const TabsTable: ITabHeader[] = [
  { index: 0, label: TabsTitle.PENDING },
  { index: 1, label: TabsTitle.HISTORIC },
];

export const clearEmptyValues = (
  amountFilter: object,
  includesValues?: string[]
) => {
  if (!includesValues) {
    return Object.entries(amountFilter).reduce(
      (a, [key, value]) => (value ? ((a[key] = value), a) : a),
      {}
    );
  }
  return Object.entries(amountFilter).reduce(
    (a, [key, value]) =>
      value && !includesValues.includes(key) ? ((a[key] = value), a) : a,
    {}
  );
};

export const STATUS_LABEL: Record<string, string> = {
  [BankConciliationStatusEnum.CONCILIATION]: "Conciliado",
  [BankConciliationStatusEnum.NO_CONCILIATION]: "No conciliado",
  [BankConciliationStatusEnum.PENDING]: "Pendiente",
  [BankConciliationStatusEnum.OMIT]: "Omitido",
};
