import React from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { ConciliationDownloadRequest } from "../../../types/conciliation_download_request";
import { useDownloadFile } from "./state/useDownloadFile";
import { SnackBarAlert } from "../common/SnackBar/SnackBarAlert";
import CheckIcon from "@material-ui/icons/Check";
import { Error } from "@material-ui/icons";
import { Download, FileText } from "react-feather";
import { LoadStatusEnum } from "../../shared/infrastructure/LoadStatus";
import { makeStyles, Theme } from "@material-ui/core/styles";

export interface DownloadFileProps extends ConciliationDownloadRequest {
  fileSize: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    // backgroundColor: "#f60",
    backgroundColor: theme.palette.background.default,
    padding: "15px",
  },
  fileIcon: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingRight: "5px",
  },
  buttonAndLoading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  fileName: {
    fontSize: "large",
  },
}));

export const DownloadFile: React.FC<DownloadFileProps> = (
  props: React.PropsWithChildren<DownloadFileProps>
) => {
  const classes = useStyles();
  const { fileSize, origin, path } = props;
  const { loadStatus, handleClick, handleCloseSnackbar } = useDownloadFile();

  return (
    <Grid container justify={"space-around"} className={classes.container}>
      {loadStatus !== LoadStatusEnum.LOADING && (
        <Grid item className={classes.fileIcon}>
          <FileText size={20} alignmentBaseline={"central"} />
        </Grid>
      )}
      <Grid item xs={7} md={7}>
        <Grid item sm>
          <Typography noWrap color={"textPrimary"} className={classes.fileName}>
            {path}
          </Typography>
        </Grid>
        <Grid item md>
          {loadStatus === LoadStatusEnum.LOADING ? (
            <Typography noWrap color={"primary"}>
              {"Esto puede tomar unos minutos"}
            </Typography>
          ) : (
            <Typography noWrap color={"textSecondary"}>
              {fileSize}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.buttonAndLoading} xs={4} md={4}>
        {loadStatus === LoadStatusEnum.LOADING ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            component="span"
            size={"large"}
            color="primary"
            startIcon={<Download size={20} />}
            onClick={() => handleClick({ origin, path })}
          >
            Descargar archivo
          </Button>
        )}
      </Grid>
      <SnackBarAlert
        type={"dark"}
        open={loadStatus === LoadStatusEnum.READY}
        autoHideDuration={3e3}
        handlerClose={handleCloseSnackbar}
        hasOnclose={true}
        horizontal={"right"}
        icon={<CheckIcon />}
        transitionDuration={1e3}
        vertical={"bottom"}
        message={`Ya se encuentra disponible el archivo de ${path}`}
      />
      <SnackBarAlert
        type={"error"}
        open={loadStatus === LoadStatusEnum.ERROR}
        autoHideDuration={3e3}
        handlerClose={handleCloseSnackbar}
        hasOnclose={true}
        horizontal={"center"}
        transitionDuration={0}
        icon={<Error />}
        vertical={"top"}
        message={"Ocurrió un error al descargar el archivo"}
      />
    </Grid>
  );
};
