import { InfoTrxProps } from "../ModalBodyTabs";
import { defaultTo, get, isEmpty, isString, set, size } from "lodash";
import { useEffect, useState } from "react";
import { RULE_STATUS } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  AuthenticationElastic,
  Historical,
} from "../../../../types/remote/authentication_elastic";
import { AuthenticationRules } from "../../../../types/response_search_rules_by_filters";
import { format } from "date-fns";
import { ampm } from "../../../shared/infrastructure/Utils";

export interface HistoricalData {
  dateFormatter: string;
  hourFormatter: string;
  reason: string;
  descriptionStatus: string;
  userName: string;
}

export interface ModalBodyTimeLineState {
  actions: {
    handleShowDialogTimeline: () => void;
    handleFinish: () => void;
    handleSetModalData: (data: HistoricalData) => void;
  };
  historicalData: HistoricalData[];
  modalData: object;
  openDialogTimeLine: boolean;
}

export const useModalBodyTimelineState = (
  props: InfoTrxProps
): ModalBodyTimeLineState => {
  const trxInfo: AuthenticationRules | AuthenticationElastic | undefined = get(
    props,
    "trxInfo"
  );
  let historical: Historical[] = get(props, "trxInfo.historical", []);

  const [openDialogTimeLine, setOpenDialogTimeLine] = useState<boolean>(false);
  const [historicalData, setHistoricalData] = useState<HistoricalData[]>([]);
  const [modalData, setModalData] = useState<object>({});

  const getFormatHistoricalData = (): void => {
    historical = [getFirstHistoricalData(), ...historical];
    const data: HistoricalData[] = historical
      .sort(
        (next: Historical, preview: Historical) =>
          getDate(get(preview, "updated", preview.created)).getTime() -
          getDate(get(next, "updated", next.created)).getTime()
      )
      .map((data: Historical) => {
        const formatDate: Date = getDate(get(data, "updated", data.created));

        return {
          descriptionStatus: getDescriptionStatus(data),
          dateFormatter: getFormatDate(formatDate),
          hourFormatter: getFormatHour(formatDate),
          reason: get(data, "observations.description", ""),
          userName: get(data, "user_name", ""),
        };
      })
      .filter((data: HistoricalData) => !isEmpty(data.descriptionStatus));

    setHistoricalData([...data]);
  };

  const getFirstHistoricalData = (): Historical => {
    const firstHistorical: Historical = {
      observations: get(props, "trxInfo.observations", {}),
      user_name: get(props, "trxInfo.user_name", ""),
      status: get(props, "trxInfo.status", ""),
      updated: get(props, "trxInfo.updated", ""),
      created: get(props, "trxInfo.created", ""),
    };

    if (size(historical) === 0)
      set(firstHistorical, "updated", get(props, "trxInfo.created", ""));

    return firstHistorical;
  };

  const getDescriptionStatus = (data: Historical): string => {
    const requestType: string = defaultTo(
      get(props, "trxInfo.request_type"),
      ""
    );
    const ruleStatus: string | Record<string, string> = defaultTo(
      RULE_STATUS[data.status],
      ""
    );

    return isString(ruleStatus)
      ? defaultTo(ruleStatus, "")
      : defaultTo(ruleStatus[requestType], "");
  };

  const getDate = (date: string): Date => {
    return new Date(date);
  };

  const getFormatDate = (formatDate: Date): string => {
    return format(formatDate, "yyyy-MM-dd");
  };

  const getFormatHour = (formatDate: Date): string => {
    const time: string = format(formatDate, "hh:mm:ss");
    return `${time} ${ampm(Number(format(formatDate, "HH")))}`;
  };

  const handleShowDialogTimeline = (): void => {
    setOpenDialogTimeLine(true);
  };

  const handleFinish = (): void => {
    setModalData({});
    setOpenDialogTimeLine(false);
  };

  const handleSetModalData = (data: HistoricalData): void => {
    setModalData({ ...data });
  };

  useEffect(() => {
    getFormatHistoricalData();
  }, [trxInfo]);

  return {
    actions: {
      handleFinish,
      handleShowDialogTimeline,
      handleSetModalData,
    },
    historicalData,
    modalData,
    openDialogTimeLine,
  };
};
