/**
 * Catalog Lenguage
 */
// tslint:disable-next-line:ext-variable-name
export const lenguage: ICategory[] = [
  {
    leng: "card",
    es: "Tarjeta",
  },
  {
    leng: "cash",
    es: "Efectivo",
  },
  {
    leng: "transfer",
    es: "Transferencia",
  },
  {
    leng: "automatic",
    es: "Automática",
  },
  {
    leng: "manual",
    es: "Manual",
  },
  {
    leng: "approval",
    es: "Aprobada",
  },
  {
    leng: "aproved",
    es: "Aprobada",
  },
  {
    leng: "declined",
    es: "Declinada",
  },
  {
    leng: "initialized",
    es: "Inicializada",
  },
  {
    leng: "sale",
    es: "Venta",
  },
  {
    leng: "deffered",
    es: "Diferido",
  },
  {
    leng: "refund",
    es: "Reembolso",
  },
  {
    leng: "debit",
    es: "Débito de balance",
  },
  {
    leng: "credit",
    es: "Crédito de balance",
  },
  {
    leng: "chargeback",
    es: "Contracargo",
  },
  {
    leng: "capture",
    es: "Captura",
  },
  {
    leng: "pay in",
    es: "Ventas",
  },
  {
    leng: "payin",
    es: "Ventas",
  },
  {
    leng: "payout",
    es: "Pagos",
  },
  {
    leng: "pay out",
    es: "Pagos",
  },
  {
    leng: "void",
    es: "Devolución",
  },
  {
    leng: "preauthorization",
    es: "Preautorización",
  },
  {
    leng: "refund total",
    es: "Reembolso total",
  },
  {
    leng: "refund partial",
    es: "Reembolso parcial",
  },
  {
    leng: "in process",
    es: "En proceso",
  },
];

export interface ICategory {
  leng: string;
  es?: string;
}
