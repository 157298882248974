import { MerchantResponse } from "../../../types/get_merchant_response";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { CompliancePeopleNodeResponse } from "../../../types/get_compliance_people_response";
import { PersonTypeEnum } from "../../shared/constants/initial_state_legal_details";

export interface ILegalDetailsState {
  openModal: boolean;
  openModalSh: boolean;
  compliancePeopleNode: CompliancePeopleNodeResponse;
  disabledInputs: boolean;
  legalRepresentative: ILegalRepresentative[];
  boardMembers: IBoardMember[];
  shareHolders: IShareHolder[];
  merchantNodeInfo: MerchantResponse;
  personType: string;
  isEdited: IIsEdited;
  isEditedBoardMember: IIsEdited;
  isLoadingCompliancePeople: boolean;
  isPubliclyExposed: boolean | null;
  generalPercentage: string;
  notification: ISnackBar | undefined;
  hierarchyNodeResponse?: boolean | undefined;
  saveLegalDetailsError?: boolean;
}

export interface IIsEdited {
  value: boolean;
  id: string;
  uid?: string;
}

export interface IBoardMember {
  addressCountry?: string;
  birthdate?: string;
  constitutionDate?: string | number;
  documentNumber: string;
  documentType?: string;
  id?: string;
  lastNames?: string;
  names?: string;
  originCountry?: string;
  personCategory?:
    | "SHAREHOLDER"
    | "REPRESENTATIVE"
    | "SOCIAL_REASON"
    | "BOARD_MEMBER";
  personType: "01" | "02";
  residencyCountry?: string;
  socialReason?: string;
  merchantId?: string;
}

export interface ILegalRepresentative {
  id: string;
  name: string;
  lastName: string;
  birthdate: string;
  type?: string;
  nationality: string;
  country: string;
  documentType: string;
  documentNumber?: string;
  email: string;
  countryCode?: string;
  areaCode?: string;
  cellphoneNumber: string;
  personType?: string;
  personCategory?: string;
  isPubliclyExposed?: boolean;
  isShareHolder?: boolean | string;
  merchantAdress?: string;
  merchantCountry?: string;
  socialReason?: string;
  taxId?: string;
  participationPercentage?: string;
  constitutionDate?: string;
  uid?: string; // Omitir al guardar en la base
  isMain: boolean;
}

export interface IShareHolder {
  id: string;
  personType: string;
  socialReason?: string;
  taxId?: string;
  name?: string;
  lastName?: string;
  documentType?: string;
  documentNumber?: string;
  birthdate?: string; //Pasar a numerico al guardar en la base
  constitutionDate?: string; //Pasar a numerico al guardar en la base
  country: string;
  companyCountry?: string;
  personCategory: string;
  participationPercentage: string; //Pasar a numerico al guardar en la base
  parent?: string;
  riskStatus?: boolean;
  level: number;
  merchantCountry?: string;
  children: IShareHolder[];
  uid?: string; // Omitir al guardar en la base
}

export interface IMerchantPeopleNodeRequest {
  merchantId: string;
  merchantName: string;
  merchantStatus?: string;
  country: string;
  constitutionalCountry?: string;
  legalRepresentatives: ILegalRepresentativeRequest[];
  shareholders: IShareHolderRequest[];
  boardmembers: IBoardMemberRequest[];
  socialReason: ISocialReasonRequest;
}

export interface ISocialReasonRequest {
  id?: string;
  documentType?: string;
  personCategory?: PersonCategoryEnum;
  personType?: PersonTypeEnum;
  names?: string;
  lastNames?: string;
  socialReason?: string;
  documentNumber?: string;
}

export enum PersonCategoryEnum {
  REPRESENTATIVE = "REPRESENTATIVE",
  SHAREHOLDER = "SHAREHOLDER",
  SOCIAL_REASON = "SOCIAL_REASON",
  BOARD_MEMBER = "BOARD_MEMBER",
}

export interface ILegalRepresentativeRequest {
  id?: string;
  uid?: string;
  personCategory: PersonCategoryEnum;
  personType: PersonTypeEnum;
  isMain?: boolean;
  isShareHolder?: boolean;
  participationPercentage?: string;
  names?: string;
  lastNames?: string;
  nationality?: string;
  birthdate?: string;
  residencyCountry?: string;
  documentType?: string;
  documentNumber: string;
  email: string;
  cellphoneNumber: string;
  areaCode?: string;
  countryCode?: string;
  isPubliclyExposed?: boolean;
  socialReason?: string;
  addressCountry?: string;
  originCountry?: string;
  constitutionDate?: number;
}

export interface IShareHolderRequest {
  id?: string;
  personCategory: string;
  personType: string;
  participationPercentage: string;
  names?: string;
  lastNames?: string;
  documentType?: string;
  documentNumber?: string;
  residencyCountry?: string;
  birthdate?: string;
  parent?: string;

  socialReason?: string;
  addressCountry?: string;
  originCountry?: string;
  constitutionDate?: number;
  uid?: string;
}

export interface IBoardMemberRequest {
  id?: string;
  birthdate?: string;
  documentNumber?: string;
  documentType?: string;
  names?: string;
  lastNames?: string;
  personCategory?: string;
  personType?: string;
  residencyCountry?: string;
  addressCountry?: string;
  constitutionDate?: string;
  socialReason?: string;
  uid?: string;
}
