import React, { useEffect, useState } from "react";
import {
  IUseInformation,
  IUseInformationValues,
  IUseInformativeModal,
} from "./useInformativeModal.interfaces";
import {
  Conciliation,
  Summary,
} from "../../../types/get_conciliation_transactions_response";
import { concat, filter, get, includes, sumBy, toString } from "lodash";
import { ConciliationActionEnum } from "../../shared/enums/concilation_action_enum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { OptionsDownloadEnum } from "../../shared/enums/options_download_enum";
import { SingleInformativeModalProps } from "../../components/modals/SingleInformativeModalDownloads/SingleInformativeModalDownloads.interfaces";
import { TabSectionEnum } from "../../shared/infrastructure/enums/tab_section_enum";

export const useInformativeModal = (props: {
  handleDownloadTransactions: (downloadType: string) => void;
}): IUseInformativeModal => {
  const summary: Summary = useSelector((state: RootState) => state.app.summary);
  const conciliationList: Conciliation[] = useSelector(
    (state: RootState) => state.app.conciliationList
  );

  const currentTab: string = useSelector(
    (state: RootState) => state.app.valueTab
  );

  const [conciliationListAux, setConciliationListAux] = React.useState<
    Conciliation[]
  >([]);

  const [value, setValue] = React.useState(false);

  const [downloadType, setDownloadType] = React.useState<string>("");

  const [conciliationSelectModal, setConciliationSelectModal] = React.useState<
    Conciliation | undefined
  >();

  const [currencyCode, setCurrencyCode] = React.useState(
    get(summary, "currency", "COP")
  );

  const [changeInfo, setChangeInfo] = useState<boolean>(false);

  const merchanInfo: SingleInformativeModalProps = {
    approvedTransactionAmount: get(
      conciliationListAux[0],
      "approvedTransactionAmount",
      0
    ),
    currency: currencyCode,
    merchantId: get(conciliationListAux[0], "merchantId", ""),
    merchantName: get(conciliationListAux[0], "merchantName", ""),
  };

  const TOTAL_VALUE_TAB: Record<string, IUseInformationValues> = {
    [TabSectionEnum.HISTORICAL_TAB]: {
      amount: `${
        get(summary, "statusOmittedAmount", 0) +
        get(summary, "statusDeclinedRefundAmount", 0) +
        get(summary, "statusConciliatedAmount", 0) +
        get(summary, "statusApprovedRefundAmount", 0) +
        get(summary, "statusPendingRefundAmount", 0)
      } ${currencyCode}`,
      quantity:
        get(summary, "statusConciliated", 0) +
        get(summary, "statusOmitted", 0) +
        get(summary, "statusApprovedRefund", 0) +
        get(summary, "statusPendingRefund", 0) +
        get(summary, "statusDeclinedRefund", 0),
    },
    [TabSectionEnum.PENDING_TAB]: {
      amount: `${
        get(summary, "statusReviewProcessorAmount", 0) +
        get(summary, "statusPendingAmount", 0) +
        get(summary, "statusReviewKushkiAmount", 0)
      } ${currencyCode}`,
      quantity:
        get(summary, "statusReviewProcessor", 0) +
        get(summary, "statusPending", 0) +
        get(summary, "statusReviewKushki", 0),
    },
  };

  const startValues = (): IUseInformation => {
    return {
      acquirer: {
        amount: `${get(
          summary,
          "statusReviewProcessorAmount",
          0
        )} ${currencyCode}`,
        quantity: get(summary, "statusReviewProcessor", 0),
      },
      approvedRefund: {
        amount: `${get(
          summary,
          "statusApprovedRefundAmount",
          0
        )} ${currencyCode}`,
        quantity: get(summary, "statusApprovedRefund", 0),
      },
      conciliated: {
        amount: `${get(summary, "statusConciliatedAmount", 0)} ${currencyCode}`,
        quantity: get(summary, "statusConciliated", 0),
      },
      declinedRefund: {
        amount: `${get(
          summary,
          "statusDeclinedRefundAmount",
          0
        )} ${currencyCode}`,
        quantity: get(summary, "statusDeclinedRefund", 0),
      },
      merchanInfo,
      omitted: {
        amount: `${get(summary, "statusOmittedAmount", 0)} ${currencyCode}`,
        quantity: get(summary, "statusOmitted", 0),
      },
      pendingRefund: {
        amount: `${get(
          summary,
          "statusPendingRefundAmount",
          0
        )} ${currencyCode}`,
        quantity: get(summary, "statusPendingRefund", 0),
      },
      slope: {
        amount: `${get(summary, "statusPendingAmount", 0)} ${currencyCode}`,
        quantity: get(summary, "statusPending", 0),
      },
      total: TOTAL_VALUE_TAB[currentTab],
      underInternalReview: {
        amount: `${get(
          summary,
          "statusReviewKushkiAmount",
          0
        )} ${currencyCode}`,
        quantity: get(summary, "statusReviewKushki", 0),
      },
    };
  };

  const startValuesRenderFront = (): IUseInformation => {
    return {
      acquirer: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction ===
              ConciliationActionEnum.REVIEW_PROCESSOR
          ),
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.REVIEW_PROCESSOR
        ).length,
      },
      approvedRefund: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.APRGBCK_FNPS ||
              item.conciliationAction === ConciliationActionEnum.APRRFN_PRCS
          ),
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.APRGBCK_FNPS ||
            item.conciliationAction === ConciliationActionEnum.APRRFN_PRCS
        ).length,
      },
      conciliated: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.CONCILIATED
          ),
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.CONCILIATED
        ).length,
      },
      declinedRefund: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.DCLGBCK_FNPS ||
              item.conciliationAction === ConciliationActionEnum.DCLRFN_PRCS
          ),
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.DCLGBCK_FNPS ||
            item.conciliationAction === ConciliationActionEnum.DCLRFN_PRCS
        ).length,
      },
      merchanInfo,
      omitted: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.OMITTED
          ),
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.OMITTED
        ).length,
      },
      pendingRefund: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.PENDING_REFUND
          ),
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.PENDING_REFUND
        ).length,
      },
      slope: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.PENDING
          ),
          (item) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.PENDING
        ).length,
      },
      total: {
        amount: `${sumBy(
          conciliationListAux,
          (item: Conciliation) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: conciliationListAux.length,
      },
      underInternalReview: {
        amount: `${sumBy(
          filter(
            conciliationListAux,
            (item: Conciliation) =>
              item.conciliationAction === ConciliationActionEnum.REVIEW_KUSHKI
          ),
          (item) => item.approvedTransactionAmount
        )} ${currencyCode}`,
        quantity: filter(
          conciliationListAux,
          (item: Conciliation) =>
            item.conciliationAction === ConciliationActionEnum.REVIEW_KUSHKI
        ).length,
      },
    };
  };

  const [information, setInformation] = React.useState<IUseInformation>(
    startValues()
  );

  useEffect(() => {
    let aux: Conciliation[] = [...conciliationListAux];

    if (conciliationSelectModal === undefined) return;

    const dataFilter = filter(
      conciliationListAux,
      (aux) => aux.conciliationId === conciliationSelectModal.conciliationId
    );

    if (dataFilter.length === 0) {
      aux = concat(conciliationListAux, conciliationSelectModal);
      setConciliationListAux(aux);
      setInformation(startValuesRenderFront());
      setConciliationSelectModal(undefined);

      return;
    }

    if (dataFilter.length !== 0) {
      aux = filter(
        conciliationListAux,
        (item) =>
          item.conciliationId !== conciliationSelectModal?.conciliationId
      );
      setConciliationListAux(aux);

      setConciliationSelectModal(undefined);

      return;
    }
  }, [conciliationSelectModal]);
  useEffect(() => {
    if (conciliationListAux.length === 0)
      setCurrencyCode(get(summary, "currency", "COP"));
    else setCurrencyCode(get(conciliationListAux[0], "currencyCode", "COP"));

    setChangeInfo(!changeInfo);
  }, [conciliationListAux, conciliationList]);

  useEffect(() => {
    if (conciliationListAux.length === 0) setInformation(startValues());
    else setInformation(startValuesRenderFront());
  }, [changeInfo]);

  const handleOpenCloseModal = (downloadType: string | undefined) => {
    setDownloadType(toString(downloadType));
    if (includes([OptionsDownloadEnum.JSON], downloadType)) {
      props.handleDownloadTransactions(toString(downloadType));

      return;
    }
    setValue(!value);
  };

  return {
    downloadType: downloadType,
    handlersInformation: {
      handleOpenCloseModal: handleOpenCloseModal,
    },
    information: information,
    openModalInformation: value,
    setConciliationListAux: setConciliationListAux,
    setConciliationSelectModal: setConciliationSelectModal,
  };
};
