import React, { FC } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  Switch,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import SelectItemsField from "../FormInputFields/SelectItemsField/SelectItemsField";
import { useDispersionFormState } from "./state/useDispersionFormState";
import {
  DISPERSION_FREQUENCY_LIST,
  MAX_PERCENTAGE_VALUE,
  YES_NO_OPTIONS,
} from "../../shared/constants/dispersions/dispersions";
import { dispersionFormStyles as styles } from "./DispersionForm.styles";
import {
  COL_GMF_4_X_1000,
  COL_GMF_4_X_1000_LABEL,
  CONFIGURATION_LABEL,
  DAYS_HELPER_TEXT,
  DAYS_LABEL_FORM,
  DISPERSION_FIRST_LABEL,
  DISPERSION_FRAUD_SUBTITLE,
  DISPERSION_FREQUENCY_LABEL,
  DISPERSION_SWITCH_LABEL,
  FRAUD_DESC_LABEL,
  PERCENTAGE_DAYS_LABEL,
  PERCENTAGE_HELPER_TEXT,
  PERCENTAGE_LABEL_FORM,
  RETENTION_COMMISSION_LABEL,
} from "../../shared/constants/labels/dispersion_container_labels";
import RadioButtonField from "../FormInputFields/RadioButtonField/RadioButtonField";
import { FrequencyEnum } from "../../shared/enums/frequencyEnum";
import { DispersionFields } from "../../shared/enums/dispersionsEnum";
import { IDispersionFormProps } from "./DispersionForm.interfaces";
import NumericInputField from "../FormInputFields/NumericInputField/NumericInputField";
import { getDispErrorMessages } from "../../shared/utils/errorMessages/error_messages";
import { CountriesEnum } from "../../shared/enums/countriesEnum";

const DispersionForm: FC<IDispersionFormProps> = ({
  form: { control, errors, resetField },
  isBranchCentralized,
  switchState,
  setSwitchValue,
  loading,
}: IDispersionFormProps) => {
  const { constitutionalCountry, keepFraudWatch, handleOnChangeFraudOptions } =
    useDispersionFormState({
      control,
      loading,
      resetField: resetField!,
    });

  return (
    <Box sx={styles.container}>
      <form>
        <Grid container>
          {constitutionalCountry === CountriesEnum.COLOMBIA && (
            <>
              <Grid item xs={12}>
                <Typography
                  color="text.dark"
                  variant="subtitle2"
                  sx={styles.label}
                >
                  {COL_GMF_4_X_1000}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="text.dark"
                  variant="body2"
                  sx={styles.gridLabel}
                >
                  {COL_GMF_4_X_1000_LABEL}
                </Typography>
              </Grid>
              <Grid item xs={8} sx={styles.gridRadios}>
                <RadioButtonField
                  name={DispersionFields.GMF_4_X_1000}
                  control={control}
                  errors={errors}
                  defaultValue={false}
                  items={YES_NO_OPTIONS.map((option) => (
                    <FormControlLabel
                      key={`rb-${option.value}`}
                      value={option.value}
                      control={<Radio />}
                      disabled={isBranchCentralized}
                      onChange={function () {}}
                      label={
                        <Typography color="text.dark" variant="caption">
                          {option.label}
                        </Typography>
                      }
                    />
                  ))}
                />
              </Grid>
            </>
          )}
          {constitutionalCountry == CountriesEnum.CHILE && (
            <>
              <Grid item container xs={12}>
                <Grid item xs={12} md={3}>
                  <Typography color="text.primary" variant={"h6"}>
                    {DISPERSION_SWITCH_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Switch
                    size={"small"}
                    checked={switchState}
                    disabled={isBranchCentralized}
                    onChange={(event) =>
                      setSwitchValue
                        ? setSwitchValue(event.target.checked)
                        : false
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography color="text.dark" variant="subtitle2" sx={styles.label}>
              {DISPERSION_FIRST_LABEL}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sx={styles.gridSelect}>
            <SelectItemsField
              name={DispersionFields.DISPERSION_FREQUENCY}
              control={control}
              errors={errors}
              disabled={isBranchCentralized || switchState}
              label={DISPERSION_FREQUENCY_LABEL}
              defaultValue={FrequencyEnum.NONE}
              items={DISPERSION_FREQUENCY_LIST.map((item, index) => (
                <MenuItem key={`df-${index}`} value={item.value}>
                  <Typography color="text.grey" variant="subtitle2">
                    {item.label}
                  </Typography>
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.grey" variant="body2" sx={styles.label}>
              {RETENTION_COMMISSION_LABEL}
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.gridRadios}>
            <RadioButtonField
              name={DispersionFields.KEEP_COMMISSION}
              control={control}
              errors={errors}
              defaultValue={true}
              items={YES_NO_OPTIONS.map((option) => (
                <FormControlLabel
                  key={`rb-${option.value}`}
                  value={option.value}
                  control={<Radio />}
                  disabled={isBranchCentralized}
                  label={
                    <Typography color="text.dark" variant="caption">
                      {option.label}
                    </Typography>
                  }
                />
              ))}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.primary" variant="h5" sx={styles.label}>
              {DISPERSION_FRAUD_SUBTITLE}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="text.grey" variant="body2" sx={styles.label}>
              {FRAUD_DESC_LABEL}
            </Typography>
          </Grid>
          <Grid item xs={8} sx={styles.gridRadios}>
            <RadioButtonField
              name={DispersionFields.KEEP_FRAUD}
              control={control}
              errors={errors}
              defaultValue={true}
              items={YES_NO_OPTIONS.map((option) => (
                <FormControlLabel
                  key={`rb-${option.value}`}
                  value={option.value}
                  control={<Radio />}
                  disabled={isBranchCentralized}
                  onChange={handleOnChangeFraudOptions}
                  label={
                    <Typography color="text.dark" variant="caption">
                      {option.label}
                    </Typography>
                  }
                />
              ))}
            />
          </Grid>
          {keepFraudWatch && (
            <>
              <Grid item xs={12}>
                <Typography color="text.grey" variant="body2" sx={styles.label}>
                  {CONFIGURATION_LABEL}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="text.dark"
                  variant="body2"
                  sx={styles.gridLabel}
                >
                  {PERCENTAGE_DAYS_LABEL}
                </Typography>
              </Grid>
              <Grid item container xs={12} sx={styles.gridNumericInputs}>
                <Grid item xs={12} md={6} sx={styles.paddingInputFraud}>
                  <NumericInputField
                    name={DispersionFields.FRAUD_PERCENTAGE}
                    control={control}
                    disabled={isBranchCentralized}
                    errors={errors}
                    label={PERCENTAGE_LABEL_FORM}
                    helperText={PERCENTAGE_HELPER_TEXT}
                    customErrorMessages={getDispErrorMessages("porcentaje")}
                    endIcon={
                      <InputAdornment position="end">{"%"}</InputAdornment>
                    }
                    decimalScale={2}
                    thousandSeparator={","}
                    validate={(value) =>
                      +value > 0 && +value <= MAX_PERCENTAGE_VALUE
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumericInputField
                    name={DispersionFields.DAYS_RETENTION}
                    control={control}
                    disabled={isBranchCentralized}
                    errors={errors}
                    label={DAYS_LABEL_FORM}
                    helperText={DAYS_HELPER_TEXT}
                    customErrorMessages={getDispErrorMessages("período")}
                    decimalScale={0}
                    validate={(value) => +value > 0}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default DispersionForm;
