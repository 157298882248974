import React from "react";
import { Box } from "@mui/material";
import { FilterTableComponent } from "../FilterTableComponent/FilterTableComponent";
import { DataTableComponent } from "../DataTableComponent/DataTableComponent";
import { useGlobalTableComponentState } from "./state/useGlobalTableComponentState";
import { IGlobalTableComponent } from "../../shared/infrastructure/interfaces/IGlobalTableComponent";
import { useFilterSearch } from "../../shared/hooks/filter-search/useFilterSearch";

export const GlobalTableComponent: React.FC<IGlobalTableComponent> = (
  props: IGlobalTableComponent
) => {
  const { searchRuleResponse, actions, itemsPerPage, filterSelectList } =
    useGlobalTableComponentState(props);
  const { readyToRender } = useFilterSearch();

  return (
    <React.Fragment>
      {readyToRender && (
        <Box mt={5}>
          <FilterTableComponent
            searchRuleRequest={undefined}
            tabName={props.tabName}
            handleSelectItem={actions.handleSelectItem}
            handleDataChange={actions.handleDataChange}
            filterSelectList={filterSelectList}
            emptyFilterSelectList={actions.emptyFilterSelectList}
          />
          <DataTableComponent
            tabName={props.tabName}
            searchRuleResponse={searchRuleResponse}
            itemsPerPage={itemsPerPage}
            actions={actions}
          />
        </Box>
      )}
    </React.Fragment>
  );
};
