import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks/storeHook";
import { useWatch } from "react-hook-form";
import { getBooleanValue } from "../../../shared/utils/parseData/parse_data";
import { IUseChargeFormStateProps } from "./useChargeFormState.interfaces";
import { IChargeForm } from "../../../store/interfaces/charge.interfaces";
import { updateFrequencyAndFraudForm } from "../../../store/actions/charge/charge.actions";
import { disableSaveButton } from "../../../store/actions/dispersion/dispersion.actions";

export const useChargeFormState = ({
  control,
  loading,
}: IUseChargeFormStateProps) => {
  const dispatch = useAppDispatch();

  const fieldsWatch = useWatch({
    control,
  });

  const getFormattedFields = (fields: IChargeForm): IChargeForm => ({
    ...fields,
    ...(fields.fraudPercentage && {
      fraudPercentage: +fields.fraudPercentage,
    }),
    ...(fields.retentionPeriod && {
      retentionPeriod: +fields.retentionPeriod,
    }),
    frequency: fields.frequency,
    keepFraud: getBooleanValue(fields.keepFraud),
  });

  useEffect(() => {
    if (!loading.loadingForm && !isEmpty(fieldsWatch.frequency)) {
      dispatch(
        updateFrequencyAndFraudForm(
          getFormattedFields(fieldsWatch as IChargeForm)
        )
      );
      dispatch(disableSaveButton(false));
    }
  }, [fieldsWatch]);
};
