import React from "react";
export const SearchImage = () => {
  return (
    <svg
      width="306"
      height="271"
      viewBox="0 0 306 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M281.36 47.7707C242.391 17.9241 204.593 42.2486 162.433 33.6482C119.969 24.9856 103.051 -3.85809 54.6851 9.45606C11.841 21.2498 -14.1555 68.5351 9.39741 108.123C16.1092 119.404 27.5431 127.914 32.2158 140.537C39.7532 160.899 28.28 176.531 30.5181 196.542C39.8182 279.686 167.276 247.481 214.175 221.613C287.726 181.044 337.484 90.7576 281.36 47.7707Z"
        fill="url(#paint0_linear_5569_234395)"
        fillOpacity="0.3"
      />
      <path
        opacity="0.24"
        d="M245 17C242.822 17 240.645 16.1707 238.987 14.5133C235.671 11.198 235.671 5.80293 238.987 2.48697C242.302 -0.828989 247.698 -0.828989 251.013 2.48697C254.329 5.80293 254.329 11.1974 251.013 14.5133C249.356 16.1707 247.178 17 245 17ZM245.001 5.86216C244.324 5.86216 243.649 6.11904 243.134 6.63397C242.106 7.66265 242.106 9.33706 243.134 10.3663C244.163 11.395 245.838 11.3956 246.867 10.3663C247.896 9.33764 247.896 7.66324 246.867 6.63397C246.352 6.11904 245.676 5.86216 245.001 5.86216Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.24"
        d="M282.5 60C280.194 60 277.888 59.1707 276.133 57.5132C272.622 54.1971 272.622 48.8019 276.133 45.4864C279.643 42.1715 285.357 42.1709 288.867 45.4864C292.378 48.8025 292.378 54.1971 288.867 57.5132C287.112 59.1707 284.806 60 282.5 60ZM282.5 48.8617C281.785 48.8617 281.069 49.1186 280.524 49.6336C279.434 50.6623 279.434 52.3368 280.524 53.3661C281.613 54.3954 283.387 54.3948 284.476 53.3661C285.566 52.3373 285.566 50.6629 284.476 49.6336C283.931 49.1186 283.216 48.8617 282.5 48.8617Z"
        fill="#4498EE"
      />
      <path
        opacity="0.48"
        d="M24.0003 140C21.8236 140.001 19.647 139.171 17.9897 137.513C16.384 135.907 15.5 133.772 15.5 131.5C15.5 129.229 16.384 127.093 17.9897 125.487C21.3031 122.171 26.6958 122.171 30.0103 125.487C31.616 127.093 32.5 129.229 32.5 131.5C32.5 133.772 31.616 135.907 30.0097 137.513C28.3542 139.171 26.1769 140 24.0003 140ZM24.0003 128.862C23.325 128.862 22.649 129.119 22.1349 129.634C21.636 130.132 21.3623 130.795 21.3623 131.5C21.3623 132.205 21.6366 132.868 22.1349 133.366C23.1638 134.396 24.8374 134.396 25.8657 133.366C26.3645 132.868 26.6383 132.205 26.6383 131.5C26.6383 130.795 26.364 130.133 25.8657 129.634C25.3515 129.119 24.6762 128.862 24.0003 128.862Z"
        fill="#CBD5E0"
      />
      <path
        opacity="0.48"
        d="M40.6861 165.97C40.4219 166.672 39.8767 167.268 39.1128 167.593L34.4463 169.572L36.6501 174.137C37.3712 175.631 36.7178 177.368 35.1902 178.016C33.6621 178.664 31.8386 177.978 31.1175 176.484L27.6071 169.212C26.886 167.718 27.5394 165.981 29.067 165.333L36.5002 162.18C38.0283 161.532 39.8519 162.218 40.573 163.713C40.9328 164.46 40.9504 165.268 40.6861 165.97Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.48"
        d="M83.9586 244.703C83.3713 245.17 82.5975 245.402 81.7754 245.288L76.7547 244.59L76.3017 249.639C76.153 251.291 74.7006 252.446 73.0569 252.218C71.4128 251.989 70.2004 250.464 70.3492 248.811L71.0708 240.769C71.2195 239.117 72.6719 237.962 74.3156 238.19L82.3129 239.302C83.957 239.53 85.1694 241.056 85.0207 242.708C84.9457 243.534 84.546 244.237 83.9586 244.703Z"
        fill="#1E65AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.54 122.586L145.029 123.833C143.279 124.062 141.672 122.828 141.443 121.078C141.214 119.328 142.448 117.722 144.198 117.492L153.708 116.245C155.458 116.016 157.065 117.25 157.294 119C157.524 120.75 156.289 122.357 154.54 122.586ZM145.86 130.174L164.881 127.68C166.634 127.45 168.238 128.685 168.467 130.435C168.696 132.185 167.466 133.791 165.712 134.021L146.691 136.514C144.941 136.744 143.334 135.51 143.105 133.76C142.876 132.01 144.11 130.403 145.86 130.174ZM174.293 139.345L139.772 143.871C138.802 143.998 137.92 143.391 137.805 142.516L131.571 94.9616C131.456 94.0866 132.153 93.2729 133.123 93.1458L150.385 90.8829L151.694 100.869C152.347 105.853 156.737 109.399 161.482 108.777L171.9 107.411L175.848 137.529C175.963 138.404 175.266 139.218 174.293 139.345ZM157.133 93.1525L167.082 101.594L160.652 102.436C159.403 102.6 158.23 101.525 158.035 100.038L157.133 93.1525ZM176.721 101.388L156.969 84.6282C156.279 84.0446 155.38 83.7787 154.483 83.8963L132.291 86.8054C127.825 87.3909 124.658 91.4243 125.23 95.793L131.464 143.348C132.037 147.716 136.136 150.797 140.603 150.212L175.124 145.686C179.591 145.101 182.761 141.067 182.188 136.698L177.825 103.41C177.721 102.621 177.327 101.902 176.721 101.388Z"
        fill="#CBD5E0"
      />
      <g filter="url(#filter0_dddd_5569_234395)">
        <rect
          x="97.5"
          y="65"
          width="104.081"
          height="104.08"
          rx="52.04"
          fill="#FBFCFE"
        />
      </g>
      <path
        d="M144 131C139.945 131 135.982 129.798 132.611 127.545C129.24 125.293 126.612 122.091 125.06 118.345C123.509 114.599 123.103 110.477 123.894 106.501C124.685 102.524 126.637 98.8713 129.504 96.0043C132.371 93.1373 136.024 91.1849 140.001 90.3939C143.977 89.6029 148.099 90.0089 151.845 91.5605C155.591 93.1121 158.793 95.7396 161.045 99.1108C163.298 102.482 164.5 106.445 164.5 110.5C164.493 115.935 162.331 121.145 158.488 124.988C154.645 128.831 149.435 130.993 144 131ZM144 95.2903C140.992 95.2903 138.051 96.1824 135.55 97.8536C133.049 99.5249 131.099 101.9 129.948 104.68C128.797 107.459 128.496 110.517 129.083 113.467C129.669 116.418 131.118 119.128 133.245 121.255C135.372 123.382 138.082 124.831 141.033 125.417C143.983 126.004 147.041 125.703 149.82 124.552C152.6 123.401 154.975 121.451 156.646 118.95C158.318 116.449 159.21 113.508 159.21 110.5C159.203 106.468 157.598 102.604 154.747 99.7529C151.896 96.902 148.032 95.2973 144 95.2903Z"
        fill="#CBD5E0"
      />
      <path
        d="M172.775 145C172.419 145.002 172.065 144.934 171.735 144.799C171.405 144.664 171.105 144.465 170.852 144.214L153.297 126.647C153.044 126.394 152.844 126.094 152.707 125.764C152.57 125.433 152.5 125.08 152.5 124.722C152.5 124.365 152.57 124.011 152.707 123.68C152.844 123.35 153.044 123.05 153.297 122.797C153.549 122.545 153.849 122.344 154.179 122.207C154.509 122.07 154.863 122 155.22 122C155.577 122 155.931 122.07 156.261 122.207C156.591 122.344 156.891 122.545 157.144 122.797L174.699 140.364C174.953 140.616 175.154 140.916 175.292 141.246C175.429 141.577 175.5 141.931 175.5 142.289C175.5 142.647 175.429 143.001 175.292 143.332C175.154 143.662 174.953 143.962 174.699 144.214C174.446 144.465 174.145 144.664 173.815 144.799C173.485 144.934 173.132 145.002 172.775 145Z"
        fill="#CBD5E0"
      />
      <rect x="178" y="141.5" width="19" height="19" rx="9.5" fill="white" />
      <path
        d="M183.811 156C183.64 156.001 183.47 155.968 183.311 155.903C183.152 155.838 183.007 155.743 182.886 155.622C182.763 155.5 182.666 155.356 182.6 155.197C182.534 155.038 182.5 154.867 182.5 154.695C182.5 154.523 182.534 154.352 182.6 154.193C182.666 154.034 182.763 153.89 182.886 153.769L190.265 146.384C190.511 146.138 190.844 146 191.191 146C191.538 146 191.871 146.138 192.117 146.384C192.362 146.629 192.5 146.963 192.5 147.31C192.5 147.658 192.362 147.991 192.117 148.237L184.737 155.622C184.615 155.743 184.471 155.838 184.312 155.903C184.153 155.968 183.983 156.001 183.811 156Z"
        fill="#CBD5E0"
      />
      <path
        d="M191.189 156C191.017 156.001 190.847 155.968 190.688 155.903C190.529 155.838 190.385 155.743 190.263 155.622L182.883 148.237C182.638 147.991 182.5 147.658 182.5 147.31C182.5 146.963 182.638 146.629 182.883 146.384C183.129 146.138 183.462 146 183.809 146C184.156 146 184.489 146.138 184.735 146.384L192.114 153.769C192.237 153.89 192.334 154.034 192.4 154.193C192.466 154.352 192.5 154.523 192.5 154.695C192.5 154.867 192.466 155.038 192.4 155.197C192.334 155.356 192.237 155.5 192.114 155.622C191.993 155.743 191.848 155.838 191.689 155.903C191.53 155.968 191.36 156.001 191.189 156Z"
        fill="#CBD5E0"
      />
      <rect
        x="178"
        y="141.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="#CBD5E0"
        strokeWidth="3"
      />
      <defs>
        <filter
          id="filter0_dddd_5569_234395"
          x="32.5"
          y="36"
          width="234.081"
          height="234.08"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.575" />
          <feGaussianBlur stdDeviation="1.87891" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5569_234395"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="5.28125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5569_234395"
            result="effect2_dropShadow_5569_234395"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.225" />
          <feGaussianBlur stdDeviation="14.168" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_5569_234395"
            result="effect3_dropShadow_5569_234395"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="36" />
          <feGaussianBlur stdDeviation="32.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_5569_234395"
            result="effect4_dropShadow_5569_234395"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_5569_234395"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5569_234395"
          x1="209.431"
          y1="293.4"
          x2="334.265"
          y2="29.6322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E8F2" />
          <stop offset="1" stopColor="#E2E8F0" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
