import { ItemCategoryType } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/enums";
import { FilterPathsEnum } from "./PathsEnum";
import { IOptionSelection } from "../constants/RuleRequestManagerConstants";

export enum FilterEnum {
  COUNTRY = "País",
  STATUS = "Estado",
  ENTITY_NAME = "Tipo de comercio",
  CONFIGURATION_TYPE = "Tipo de configuración",
  GENERAL_ACTION = "Acción",
  SUB_TYPE = "Tipo",
  TYPE = "Tipo ",
  LABEL = "Etiquetas",
  RULE_NAME = "Regla",
}

export enum DateFormatStringEnum {
  PATTERN_yyyyMMdd = "yyyy-MM-dd",
  PATTERN_ddMMMyyyy = "dd/MMM/yyyy",
  PATTERN_T000000 = "T00:00:00",
  PATTERN_T235959 = "T23:59:59",
}

export enum CategoryTypeEnum {
  BY_CHIPS = "byChips",
  BY_SELECT_CHECK = "bySelectCheck",
  BY_SEARCH_TEXT = "bySearchText",
  ENTITY_NAME = "entityName",
}

export enum SelectorTypeEnum {
  CONFIGURATION_TYPE = "configurationType",
  SUB_TYPE = "subType",
  TYPE = "type",
  ENTITY_NAME = "entityName",
  GENERAL_ACTION = "generalActions",
  COUNTRY = "country",
  RULE_STATUS = "ruleStatus",
  CONDITIONS = "conditions",
  RULE_NAME = "ruleName",
}

export const FiltersGroup: string[] = [
  FilterEnum.COUNTRY,
  FilterEnum.STATUS,
  FilterEnum.ENTITY_NAME,
  FilterEnum.GENERAL_ACTION,
  FilterEnum.SUB_TYPE,
  FilterEnum.TYPE,
  FilterEnum.CONFIGURATION_TYPE,
  FilterEnum.LABEL,
  FilterEnum.RULE_NAME,
];

export interface IFiltersInterface {
  filterPath: string;
  selectorType: string;
  categoryItem: ItemCategoryType;
}

export const FiltersRequestName: Record<string, IFiltersInterface> = {
  [FilterEnum.COUNTRY]: {
    filterPath: FilterPathsEnum.COUNTRY,
    selectorType: SelectorTypeEnum.COUNTRY,
    categoryItem: CategoryTypeEnum.BY_SELECT_CHECK,
  },
  [FilterEnum.STATUS]: {
    filterPath: FilterPathsEnum.STATUS,
    selectorType: SelectorTypeEnum.RULE_STATUS,
    categoryItem: CategoryTypeEnum.BY_CHIPS,
  },
  [FilterEnum.ENTITY_NAME]: {
    filterPath: FilterPathsEnum.ENTITY_NAME,
    selectorType: SelectorTypeEnum.ENTITY_NAME,
    categoryItem: CategoryTypeEnum.BY_CHIPS,
  },
  [FilterEnum.GENERAL_ACTION]: {
    filterPath: FilterPathsEnum.GENERAL_ACTION,
    selectorType: SelectorTypeEnum.GENERAL_ACTION,
    categoryItem: CategoryTypeEnum.BY_CHIPS,
  },
  [FilterEnum.SUB_TYPE]: {
    filterPath: FilterPathsEnum.SUB_TYPE,
    selectorType: SelectorTypeEnum.SUB_TYPE,
    categoryItem: CategoryTypeEnum.BY_CHIPS,
  },
  [FilterEnum.TYPE]: {
    filterPath: FilterPathsEnum.TYPE,
    selectorType: SelectorTypeEnum.TYPE,
    categoryItem: CategoryTypeEnum.BY_CHIPS,
  },
  [FilterEnum.CONFIGURATION_TYPE]: {
    filterPath: FilterPathsEnum.CONFIG_TYPE,
    selectorType: SelectorTypeEnum.CONFIGURATION_TYPE,
    categoryItem: CategoryTypeEnum.BY_CHIPS,
  },
  [FilterEnum.LABEL]: {
    filterPath: FilterPathsEnum.RULE_LABEL,
    selectorType: SelectorTypeEnum.CONDITIONS,
    categoryItem: CategoryTypeEnum.BY_SELECT_CHECK,
  },
  [FilterEnum.RULE_NAME]: {
    filterPath: FilterPathsEnum.RULE_NAME,
    selectorType: SelectorTypeEnum.RULE_NAME,
    categoryItem: CategoryTypeEnum.BY_SEARCH_TEXT,
  },
};

export enum SearchCatalogEnum {
  CREATED = "created",
  ORDER_ASC = "asc",
}

export enum TextSearchModeEnum {
  WITH_HIERARCHY = "withHierarchy",
  WITHOUT_HIERARCHY = "withoutHierarchy",
}

export const commerceSearchModes: IOptionSelection[] = [
  {
    value: TextSearchModeEnum.WITHOUT_HIERARCHY,
    label: "Comercio sin jerarquía",
  },
  { value: TextSearchModeEnum.WITH_HIERARCHY, label: "Comercio con jerarquía" },
];
