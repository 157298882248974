import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import {
  CANCEL_BUTTON_TEXT,
  CANCEL_BUTTON_TEXT_BRANCH,
  EXIT_BUTTON_TEXT,
  EXIT_BUTTON_TEXT_BRANCH,
  INIT_SUBTITLE,
  INIT_TITLE,
  lEAVE_BUTTON_TEXT,
  MODAL_TITLE,
  SUBTITLE_BRANCHES,
  SUBTITLE_SECTION_CREATE_NOTE,
  TITLE_BRANCHES,
  TITLE_MERCHANT,
} from "../../../shared/constants/labels/modal_exit_labels";
import { HEADER_SECTIONS } from "../../../shared/constants/header";
import { Sections } from "../../../shared/enums/sections";
import { closeModal } from "../../../store/actions/layout/layout.actions";
import {
  BRANCH_LIST_OF_CUSTOMER,
  ROUTES,
} from "../../../shared/constants/routes";
import { useLocation } from "react-router-dom";
import { get } from "lodash";

export interface ModalExitState {
  handlerCancelModalState: () => void;
  handlerChangeOpenModalState: () => void;
  isButtonPrimaryDisabled: boolean;
  isOpenExitModal: boolean;
  modalButtonCancelText: string;
  modalButtonText: string;
  modalSubtitle: string;
  modalTitle: string;
}

export const useModalExit = (): ModalExitState => {
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");
  const [modalButtonCancelText, setModalButtonCancelText] = useState("");
  const [isButtonPrimaryDisabled, setIsButtonPrimaryDisabled] = useState(false);
  const location = useLocation();
  const { section, isOpenExitModal, nodeInfo } = useAppSelector(
    (store) => store.layout
  );
  const sectionName = HEADER_SECTIONS.get(section as Sections) ?? "";
  const dispatch = useAppDispatch();

  const handlerCancelModalState = () => {
    dispatch(closeModal());
  };

  const isBranchUrl = (path: string): boolean => {
    return [
      ROUTES.CREATE_NODE_BRANCH_OPTIONS,
      ROUTES.CREATE_NODE_BRANCH_BATCH,
      ROUTES.CREATE_NODE_BRANCH_EDIT,
      ROUTES.CREATE_NODE_BRANCH_FILE_UPLOAD,
      ROUTES.CREATE_NODE_BRANCH_SELECT_EDITION,
    ].includes(path as ROUTES);
  };

  const isResumeUrl = (path: string): boolean => {
    return [ROUTES.MERCHANT_RESUME].includes(path as ROUTES);
  };

  const isBranchCreation = (path: string): boolean => {
    return [ROUTES.CREATE_NODE_BRANCH_BATCH].includes(path as ROUTES);
  };

  const handlerChangeOpenModalState = () => {
    const currentUrl: string = location.pathname;

    handlerCancelModalState();

    if (isBranchCreation(currentUrl)) {
      window.location.href = BRANCH_LIST_OF_CUSTOMER(
        get(nodeInfo, "rootId", ""),
        get(nodeInfo, "nodeId", "")
      );
    } else window.location.href = ROUTES.CLIENTS;
  };

  useEffect(() => {
    const currentUrl = location.pathname;

    setIsButtonPrimaryDisabled(false);
    if (sectionName !== "") {
      setModalTitle(MODAL_TITLE(sectionName));
      setModalSubtitle(SUBTITLE_SECTION_CREATE_NOTE);
      setModalButtonText(lEAVE_BUTTON_TEXT);
      setModalButtonCancelText(CANCEL_BUTTON_TEXT);
    } else if (isBranchUrl(currentUrl)) {
      setModalTitle(TITLE_BRANCHES);
      setModalSubtitle(SUBTITLE_BRANCHES);
      setModalButtonText(EXIT_BUTTON_TEXT_BRANCH);
      setModalButtonCancelText(CANCEL_BUTTON_TEXT_BRANCH);
    } else if (isResumeUrl(currentUrl)) {
      setModalTitle(TITLE_MERCHANT);
      setModalButtonText(EXIT_BUTTON_TEXT);
      setModalButtonCancelText(CANCEL_BUTTON_TEXT);
    } else {
      setModalTitle(INIT_TITLE);
      setModalSubtitle(INIT_SUBTITLE);
      setModalButtonText(EXIT_BUTTON_TEXT);
      setModalButtonCancelText(CANCEL_BUTTON_TEXT);
    }
  }, [section]);

  return {
    handlerCancelModalState,
    handlerChangeOpenModalState,
    isButtonPrimaryDisabled,
    isOpenExitModal,
    modalButtonCancelText,
    modalButtonText,
    modalSubtitle,
    modalTitle,
  };
};
