import React from "react";
import { useRuleRequestDashboardState } from "./state/useRuleRequestDashboardState";
import { DashboardTable } from "../../components/Table/DashboardTable/DashboardTable";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { columnsTableRequest } from "../../shared/infrastructure/Table/ColumnsTable";
import { IUtilDashboard } from "../Utils/useUtilDashboard";
import { LoaderModal } from "../../components/common/LoaderModal/LoaderModal";
import { ConfirmModal } from "../../components/common/ConfirmModal/ConfirmModal";
import {
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { SnackBarAlert } from "../../components/common/SnackBar/SnackBarAlert";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { UserBalancerResponse } from "../../../types/user_balancer_response";
import { DataTableFilters } from "../../components/Filters/DataTableFilters";
import { get } from "lodash";
import { ResponseSearchRulesByFilters } from "../../../types/response_search_rules_by_filters";
import { SelectedRuleRequestTable } from "../../components/Table/SelectedRuleRequestTable/SelectedRuleRequestTable";
import { TRuleDashBoardIndexProps } from "../RuleDashboardIndex/RuleDashboardIndex";
import { RuleRequestRoles } from "../../shared/infrastructure/constants/RuleRequestConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface IUtilDashboardHookProps {
  stateUtilTab: IUtilDashboard;
}
export type TRuleRequestDashboardProps = IUtilDashboardHookProps &
  TRuleDashBoardIndexProps;
const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
    marginTop: "20px",
  },
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderRadius: 4,
    },
  },
}));
export const RuleRequestDashboard: React.FC<TRuleRequestDashboardProps> = (
  props: TRuleRequestDashboardProps
) => {
  const {
    pagination,
    filterProps,
    data,
    checkboxSelection,
    openAnalystAssignmentModal,
    handleOpenAnalystAssignmentModal,
    handleCloseAnalystAssignmentModal,
    handleAcceptAnalystAssignmentModal,
    handleCloseAnalystAssignmentLoader,
    notification,
    modalOver,
  } = useRuleRequestDashboardState(props);

  const classes = useStyles();
  const {
    userAnalystData,
    isLoadingRuleDashboardRequest,
    isSnackbarRuleDashboardRequest,
  } = useSelector((state: IAppState) => ({
    userAnalystData: state.userAnalystData,
    isLoadingRuleDashboardRequest: state.isLoadingRuleDashboardRequest,
    isSnackbarRuleDashboardRequest: state.isSnackbarRuleDashboardRequest,
  }));
  const [analystSelected, setAnalystSelected] =
    React.useState<UserBalancerResponse>({
      balanceProfile: [],
      balanceRules: [],
      config: {},
      created: 0,
      dashboardProfile: "",
      email: "",
      familyName: "",
      group: [],
      inserted: 0,
      name: "",
      publicMerchantId: "",
      userName: "",
    });

  const handleAnalystChange = (event: object) => {
    setAnalystSelected(userAnalystData[get(event, "target.value")]);
  };

  const trxSelectData: ResponseSearchRulesByFilters = {
    records: get(data, "trxSelected", []),
    total: get(data, "trxSelected.length", 0),
  };
  return (
    <React.Fragment>
      <DataTableFilters {...filterProps} />
      {get(data, "trxSelected.length", 0) > 0 ? (
        <SelectedRuleRequestTable
          tableProps={{
            mainTable: false,
            hideFilters: true,
            isAnalyst: false,
            country: CountryEnum.colombia,
            historicRulesData: trxSelectData,
            trxSelected: [],
            isLoading: false,
            page: 0,
            pageSize: 0,
            paginationV2: true,
            handleChangePage: () => "void",
            handleChangePageSize: () => "void",
            hideNext: true,
            hidePrevious: true,
            filterProps: filterProps,
            tableColumns: {
              renderColumns: [
                {
                  id: "selectable_column",
                  label: "",
                  view: true,
                  filter: false,
                  checkboxHeader: {
                    handleChange: checkboxSelection.handleSelectTrxHeaderChange,
                    checked: checkboxSelection.checkedHearderSelects,
                    name: "selectable_column",
                  },
                },
                ...columnsTableRequest,
              ],
            },
            handleSelectTrx: checkboxSelection.handleSelectTrx,
            validateExistTrxs: checkboxSelection.validateExistTrxs,
            handleOpenAnalystAssignmentModal: handleOpenAnalystAssignmentModal,
          }}
        />
      ) : (
        ""
      )}
      <DashboardTable
        mainTable={true}
        hideFilters={true}
        isAnalyst={false}
        country={CountryEnum.colombia}
        historicRulesData={data.authenticationRulesRequestData}
        trxSelected={data.trxSelected}
        isLoading={data.isLoading}
        page={pagination.page}
        pageSize={pagination.pageSize}
        paginationV2={true}
        handleChangePage={pagination.handleChangePage}
        handleChangePageSize={pagination.handleChangePageSize}
        hideNext={false}
        hidePrevious={false}
        modalOver={modalOver}
        filterProps={filterProps}
        orderDataDashboard={data.orderDataDashboard}
        handleOpenAnalystAssignmentModal={handleOpenAnalystAssignmentModal}
        tableColumns={{
          renderColumns: [
            {
              id: "selectable_column",
              label: "",
              view: true,
              filter: false,
              checkboxHeader: {
                handleChange: checkboxSelection.handleTrxHeaderChange,
                checked: checkboxSelection.checkedHearder,
                name: "selectable_column",
              },
            },
            ...columnsTableRequest,
          ],
        }}
        handleSelectTrx={checkboxSelection.handleSelectTrx}
        validateExistTrxs={checkboxSelection.validateExistTrxs}
      />
      <LoaderModal
        open={isLoadingRuleDashboardRequest}
        message="Estamos reasignando las reglas"
        message1="Este proceso puede tardar un poco..."
        handleClose={handleCloseAnalystAssignmentLoader}
      />

      <ConfirmModal
        open={openAnalystAssignmentModal}
        titleText={"Reasignar analista de riesgo"}
        acceptText="Reasignar analista"
        acceptColor="primary"
        handleClose={handleCloseAnalystAssignmentModal}
        handleAccept={() => handleAcceptAnalystAssignmentModal(analystSelected)}
      >
        <Grid item md={12}>
          {get(data, "trxSelected.length", 0) > 0 ? (
            <Typography>
              Selecciona el analista de riesgo que desees asignar a las reglas
              seleccionadas:
            </Typography>
          ) : (
            <Typography>
              Selecciona el analista de riesgo que desees asignar a la regla
              seleccionada:
            </Typography>
          )}
        </Grid>
        <ComponentSecurityWrapper
          componentId={`${RuleRequestRoles.M_FRAUD_PREVENTION}.${RuleRequestRoles.RULE_REQUEST_AUTH_RULES_REASSIGN_ANALYST}`}
        >
          <Grid item md={6}>
            <FormControl className={classes.formControl}>
              <Select
                id="simple-select-outlined"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                input={
                  <InputBase
                    classes={{ input: classes.input, root: classes.root }}
                  />
                }
                onChange={handleAnalystChange}
              >
                <MenuItem>Selecciona...</MenuItem>
                {userAnalystData.map((userAnalyst, index) => (
                  <MenuItem value={index} key={index}>
                    {`${userAnalyst.name} ${userAnalyst.familyName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </ComponentSecurityWrapper>
      </ConfirmModal>

      <SnackBarAlert
        type={notification.type}
        msg={notification.msg}
        open={isSnackbarRuleDashboardRequest}
        handlerClose={notification.handlerClose}
      />
    </React.Fragment>
  );
};
