import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const wrapperContainerStyles = createNamedStyles({
  containerBody: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(5),
    pt: 5,
    pl: 11.75,
    backgroundColor: "background.paper",
  },
  containerFooter: {
    bottom: 0,
    position: "fixed",
    width: 1,
    zIndex: 1,
  },
  containerForm: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "150px",
  },
  wrappedTabs: {
    paddingRight: "150px",
  },
  returnButton: {
    padding: 0,
  },
});
