import BrazilFlag from "../../../assets/images/flags/brazil.png";
import ChileFlag from "../../../assets/images/flags/chile.png";
import ColombiaFlag from "../../../assets/images/flags/colombia.png";
import EcuadorFlag from "../../../assets/images/flags/ecuador.png";
import MexicoFlag from "../../../assets/images/flags/mexico.png";
import PeruFlag from "../../../assets/images/flags/peru.png";
import CostaricaFlag from "../../../assets/images/flags/costarica.svg";
import ElSalvadorFlag from "../../../assets/images/flags/elsalvador.svg";
import GuatemalaFlag from "../../../assets/images/flags/guatemala.svg";
import HondurasFlag from "../../../assets/images/flags/honduras.svg";
import NicaraguaFlag from "../../../assets/images/flags/nicagarua.svg";
import PanamaFlag from "../../../assets/images/flags/panama.svg";

export const flags: { [key: string]: string } = {
  brazil: BrazilFlag,
  chile: ChileFlag,
  colombia: ColombiaFlag,
  costarica: CostaricaFlag,
  ecuador: EcuadorFlag,
  elsalvador: ElSalvadorFlag,
  guatemala: GuatemalaFlag,
  honduras: HondurasFlag,
  mexico: MexicoFlag,
  nicaragua: NicaraguaFlag,
  panama: PanamaFlag,
  peru: PeruFlag,
};
