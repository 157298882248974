import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const numericInputFieldFormStyles = createNamedStyles({
  root: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "error.dark2",
    },
  },
  error: {},
  texField: { zIndex: "0" },
});
