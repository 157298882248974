import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import "../../styles/CodeEditor.css";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import { isEmpty, uniqBy } from "lodash";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import { Webhook } from "../../../types/webhook_response";
import { useModalTestConnection } from "./state/useModalTestConnection";

export interface ModalTestConnectionProps {
  open: boolean;
  value: string;
  handleChangeKind: (kind: string, value: string) => void;
  handleCancel?: (kind: string) => void;
  handleTestConnection?: () => void;
  loadingTestConnection: boolean;
  content: { body: string; service: string; kindTransaction: string };
  webhook: Webhook | undefined;
  handleChargeback: (state: boolean) => void;
  chargeback: boolean;
}

export const ModalTestConnection: React.FC<ModalTestConnectionProps> = (
  props: ModalTestConnectionProps
) => {
  const {
    translateCatalogPayments,
    translateCatalogProducts,
    translateCatalogOthers,
  } = useModalTestConnection();
  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          <Typography color={"primary"}>Simulación de conexión</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"body1"}>
                Selecciona el medio de pago o producto y el evento.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant={"outlined"}
                style={{ width: "75%" }}
                error={isEmpty(props.content.service)}
              >
                <InputLabel>Medio de Pago</InputLabel>
                <Select
                  onChange={(evt) => {
                    props.handleChangeKind(
                      "service",
                      evt.target.value as string
                    );
                    props.handleChargeback(
                      evt.target.value === "card-chargeback"
                    );
                  }}
                  label="Medio de Pago"
                  variant={"outlined"}
                >
                  <ListSubheader>Medio de pago</ListSubheader>
                  {uniqBy(props.webhook?.events, "service").map(
                    (item, index: number) =>
                      !isEmpty(translateCatalogPayments(item.service!)) && (
                        <MenuItem
                          key={`${index}-payments`}
                          value={item.service}
                        >
                          {translateCatalogPayments(item.service!)}
                        </MenuItem>
                      )
                  )}
                  <ListSubheader>Producto o servicio</ListSubheader>
                  {uniqBy(props.webhook?.events, "service").map(
                    (item, index: number) =>
                      !isEmpty(translateCatalogProducts(item.service!)) && (
                        <MenuItem
                          key={`${index}-products`}
                          value={item.service}
                        >
                          {translateCatalogProducts(item.service!)}
                        </MenuItem>
                      )
                  )}
                  <ListSubheader>Otros</ListSubheader>
                  {uniqBy(props.webhook?.events, "service").map(
                    (item, index: number) =>
                      !isEmpty(translateCatalogOthers(item.service!)) && (
                        <MenuItem key={`${index}-others`} value={item.service}>
                          {translateCatalogOthers(item.service!)}
                        </MenuItem>
                      )
                  )}
                </Select>
                {isEmpty(props.content.service) && (
                  <FormHelperText>
                    Seleccione un producto o medio de pago
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                error={isEmpty(props.content.kindTransaction)}
              >
                <FormLabel component={"legend"}>
                  Detalla el código correspondiente según el medio que deseas
                  simular.
                </FormLabel>
                <RadioGroup
                  row
                  arial-label="kindTransaction"
                  name="kindTransaction"
                  defaultValue={props.content.kindTransaction}
                  onChange={(evt) =>
                    props.handleChangeKind(
                      "kindTransaction",
                      evt.target.value as string
                    )
                  }
                >
                  <FormControlLabel
                    value="approvedTransaction"
                    control={<Radio color={"primary"} />}
                    label="Transacción aprobada"
                    labelPlacement="end"
                  />
                  {props.chargeback && (
                    <FormControlLabel
                      value="chargeback"
                      control={<Radio color={"primary"} />}
                      label="Contracargo"
                      labelPlacement="end"
                    />
                  )}
                  <FormControlLabel
                    value="declinedTransaction"
                    control={<Radio color={"primary"} />}
                    label="Transacción declinada"
                    labelPlacement="end"
                  />
                </RadioGroup>
                {isEmpty(props.content.kindTransaction) && (
                  <FormHelperText>
                    Seleccione un producto o medio de pago
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={isEmpty(props.content.body)}>
                <Editor
                  style={{
                    fontFamily: "'Fira code', 'Fira Mono', monospace",
                  }}
                  value={props.content.body}
                  onValueChange={(code: string) =>
                    props.handleChangeKind("body", code)
                  }
                  highlight={(code: any) =>
                    highlight(
                      code,
                      languages.extend("javascript", {
                        color: /\b(?:red|brown|blue)\b/,
                      }),
                      "javascript"
                    )
                  }
                  padding={10}
                  className="container__editor"
                  name="body"
                />
                {isEmpty(props.content.body) && (
                  <FormHelperText>
                    Ingrese el texto para probar el webhook
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {props.loadingTestConnection ? (
            <Box py={1}>
              <Typography>
                Estamos realizando el proceso de simulación de tu webhook
              </Typography>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleCancel!("testConnection");
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={props.loadingTestConnection}
            onClick={props.handleTestConnection}
            color={"primary"}
          >
            Simular conexión
          </Button>
          {props.loadingTestConnection && (
            <CircularProgress size={24} color={"primary"} />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
