/**
 * CatalogCl
 */
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { Category } from "../constants/MerchantInformationConstants";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogCl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "Run", value: "0" },
    { name: "Pasaporte", value: "1" },
    { name: "Rut", value: "2" },
  ],
  [CatalogsEnum.Departments]: [
    { name: "TARAPACA", value: "01" },
    { name: "ANTOFAGASTA", value: "02" },
    { name: "ATACAMA", value: "03" },
    { name: "COQUIMBO", value: "04" },
    { name: "VALPARAÍSO", value: "05" },
    { name: "LIB. GRAL. BERNARDO O'HIGGINS", value: "06" },
    { name: "MAULE", value: "07" },
    { name: "BIOBÍO", value: "08" },
    { name: "LA ARAUCANÍA", value: "09" },
    { name: "LOS LAGOS", value: "10" },
    { name: "AYSÉN DEL GRAL. C. IBÁÑEZ DEL CAMPO", value: "11" },
    { name: "MAGALLANES Y ANTÁRTICA CHILENA", value: "12" },
    { name: "METROPOLITANA DE SANTIAGO", value: "13" },
    { name: "LOS RÍOS", value: "14" },
    { name: "ARICA Y PARINACOTA", value: "15" },
    { name: "ÑUBLE", value: "16" },
  ],
  [CatalogsEnum.Provinces]: [
    {
      name: "Iquique",
      parent: "01",
      value: "0110",
    },
    {
      name: "Tamarugal",
      parent: "01",
      value: "0140",
    },
    {
      name: "Antofagasta",
      parent: "02",
      value: "0210",
    },
    {
      name: "El Loa",
      parent: "02",
      value: "0220",
    },
    {
      name: "Tocopilla",
      parent: "02",
      value: "0230",
    },
    {
      name: "Copiapó",
      parent: "03",
      value: "0310",
    },
    {
      name: "Chañaral",
      parent: "03",
      value: "0320",
    },
    {
      name: "Huasco",
      parent: "03",
      value: "0330",
    },
    {
      name: "Elqui",
      parent: "04",
      value: "0410",
    },
    {
      name: "Choapa",
      parent: "04",
      value: "0420",
    },
    {
      name: "Limarí",
      parent: "04",
      value: "0430",
    },
    {
      name: "Valparaíso",
      parent: "05",
      value: "0510",
    },
    {
      name: "Isla de Pascua",
      parent: "05",
      value: "0520",
    },
    {
      name: "Los Andes",
      parent: "05",
      value: "0530",
    },
    {
      name: "Petorca",
      parent: "05",
      value: "0540",
    },
    {
      name: "Quillota",
      parent: "05",
      value: "0550",
    },
    {
      name: "San Antonio",
      parent: "05",
      value: "0560",
    },
    {
      name: "San Felipe de Aconcagua",
      parent: "05",
      value: "0570",
    },
    {
      name: "Marga Marga",
      parent: "05",
      value: "0580",
    },
    {
      name: "Cachapoal",
      parent: "06",
      value: "0610",
    },
    {
      name: "Cardenal Caro",
      parent: "06",
      value: "0620",
    },
    {
      name: "Colchagua",
      parent: "06",
      value: "0630",
    },
    {
      name: "Talca",
      parent: "07",
      value: "0710",
    },
    {
      name: "Cauquenes",
      parent: "07",
      value: "0720",
    },
    {
      name: "Curicó",
      parent: "07",
      value: "0730",
    },
    {
      name: "Linares",
      parent: "07",
      value: "0740",
    },
    {
      name: "Concepción",
      parent: "08",
      value: "0810",
    },
    {
      name: "Arauco",
      parent: "08",
      value: "0820",
    },
    {
      name: "Biobío",
      parent: "08",
      value: "0830",
    },
    {
      name: "Cautín",
      parent: "09",
      value: "0910",
    },
    {
      name: "Malleco",
      parent: "09",
      value: "0920",
    },
    {
      name: "Llanquihue",
      parent: "10",
      value: "1010",
    },
    {
      name: "Chiloé",
      parent: "10",
      value: "1020",
    },
    {
      name: "Osorno",
      parent: "10",
      value: "1030",
    },
    {
      name: "Palena",
      parent: "10",
      value: "1040",
    },
    {
      name: "Coyhaique",
      parent: "11",
      value: "1110",
    },
    {
      name: "Aysén",
      parent: "11",
      value: "1120",
    },
    {
      name: "Capitán Prat",
      parent: "11",
      value: "1130",
    },
    {
      name: "General Carrera",
      parent: "11",
      value: "1140",
    },
    {
      name: "Magallanes",
      parent: "12",
      value: "1210",
    },
    {
      name: "Antártica Chilena",
      parent: "12",
      value: "1220",
    },
    {
      name: "Tierra del Fuego",
      parent: "12",
      value: "1230",
    },
    {
      name: "Ultima Esperanza",
      parent: "12",
      value: "1240",
    },
    {
      name: "Santiago",
      parent: "13",
      value: "1310",
    },
    {
      name: "CORDILLERA",
      parent: "13",
      value: "1320",
    },
    {
      name: "Chacabuco",
      parent: "13",
      value: "1330",
    },
    {
      name: "Maipo",
      parent: "13",
      value: "1340",
    },
    {
      name: "Melipilla",
      parent: "13",
      value: "1350",
    },
    {
      name: "Talagante",
      parent: "13",
      value: "1360",
    },
    {
      name: "Valdivia",
      parent: "14",
      value: "1410",
    },
    {
      name: "Ranco",
      parent: "14",
      value: "1420",
    },
    {
      name: "Arica",
      parent: "15",
      value: "1510",
    },
    {
      name: "Parinacota",
      parent: "15",
      value: "1520",
    },
    {
      name: "Ñuble",
      parent: "16",
      value: "1610",
    },
  ],
  [CatalogsEnum.Cities]: [
    {
      name: "IQUIQUE",
      parent: "0110",
      value: "01101",
    },
    {
      name: "ALTO HOSPICIO",
      parent: "0110",
      value: "01107",
    },
    {
      name: "POZO ALMONTE",
      parent: "0140",
      value: "01401",
    },
    {
      name: "CAMIÑA",
      parent: "0140",
      value: "01402",
    },
    {
      name: "COLCHANE",
      parent: "0140",
      value: "01403",
    },
    {
      name: "HUARA",
      parent: "0140",
      value: "01404",
    },
    {
      name: "PICA",
      parent: "0140",
      value: "01405",
    },
    {
      name: "ANTOFAGASTA",
      parent: "0210",
      value: "02101",
    },
    {
      name: "MEJILLONES",
      parent: "0210",
      value: "02102",
    },
    {
      name: "SIERRA GORDA",
      parent: "0210",
      value: "02103",
    },
    {
      name: "TALTAL",
      parent: "0210",
      value: "02104",
    },
    {
      name: "CALAMA",
      parent: "0220",
      value: "02201",
    },
    {
      name: "OLLAGÜE",
      parent: "0220",
      value: "02202",
    },
    {
      name: "SAN PEDRO DE ATACAMA",
      parent: "0220",
      value: "02203",
    },
    {
      name: "TOCOPILLA",
      parent: "0230",
      value: "02301",
    },
    {
      name: "MARÍA ELENA",
      parent: "0230",
      value: "02302",
    },
    {
      name: "COPIAPÓ",
      parent: "0310",
      value: "03101",
    },
    {
      name: "CALDERA",
      parent: "0310",
      value: "03102",
    },
    {
      name: "TIERRA AMARILLA",
      parent: "0310",
      value: "03103",
    },
    {
      name: "CHAÑARAL",
      parent: "0320",
      value: "03201",
    },
    {
      name: "DIEGO DE ALMAGRO",
      parent: "0320",
      value: "03202",
    },
    {
      name: "VALLENAR",
      parent: "0330",
      value: "03301",
    },
    {
      name: "ALTO DEL CARMEN",
      parent: "0330",
      value: "03302",
    },
    {
      name: "FREIRINA",
      parent: "0330",
      value: "03303",
    },
    {
      name: "HUASCO",
      parent: "0330",
      value: "03304",
    },
    {
      name: "LA SERENA",
      parent: "0410",
      value: "04101",
    },
    {
      name: "COQUIMBO",
      parent: "0410",
      value: "04102",
    },
    {
      name: "ANDACOLLO",
      parent: "0410",
      value: "04103",
    },
    {
      name: "LA HIGUERA",
      parent: "0410",
      value: "04104",
    },
    {
      name: "PAIHUANO",
      parent: "0410",
      value: "04105",
    },
    {
      name: "VICUÑA",
      parent: "0410",
      value: "04106",
    },
    {
      name: "ILLAPEL",
      parent: "0420",
      value: "04201",
    },
    {
      name: "CANELA",
      parent: "0420",
      value: "04202",
    },
    {
      name: "LOS VILOS",
      parent: "0420",
      value: "04203",
    },
    {
      name: "SALAMANCA",
      parent: "0420",
      value: "04204",
    },
    {
      name: "OVALLE",
      parent: "0430",
      value: "04301",
    },
    {
      name: "COMBARBALÁ",
      parent: "0430",
      value: "04302",
    },
    {
      name: "MONTE PATRIA",
      parent: "0430",
      value: "04303",
    },
    {
      name: "PUNITAQUI",
      parent: "0430",
      value: "04304",
    },
    {
      name: "RÍO HURTADO",
      parent: "0430",
      value: "04305",
    },
    {
      name: "VALPARAÍSO",
      parent: "0510",
      value: "05101",
    },
    {
      name: "CASABLANCA",
      parent: "0510",
      value: "05102",
    },
    {
      name: "CONCÓN",
      parent: "0510",
      value: "05103",
    },
    {
      name: "JUAN FERNÁNDEZ",
      parent: "0510",
      value: "05104",
    },
    {
      name: "PUCHUNCAVÍ",
      parent: "0510",
      value: "05105",
    },
    {
      name: "QUINTERO",
      parent: "0510",
      value: "05107",
    },
    {
      name: "VIÑA DEL MAR",
      parent: "0510",
      value: "05109",
    },
    {
      name: "ISLA DE PASCUA",
      parent: "0520",
      value: "05201",
    },
    {
      name: "LOS ANDES",
      parent: "0530",
      value: "05301",
    },
    {
      name: "CALLE LARGA",
      parent: "0530",
      value: "05302",
    },
    {
      name: "RINCONADA",
      parent: "0530",
      value: "05303",
    },
    {
      name: "SAN ESTEBAN",
      parent: "0530",
      value: "05304",
    },
    {
      name: "LA LIGUA",
      parent: "0540",
      value: "05401",
    },
    {
      name: "CABILDO",
      parent: "0540",
      value: "05402",
    },
    {
      name: "PAPUDO",
      parent: "0540",
      value: "05403",
    },
    {
      name: "PETORCA",
      parent: "0540",
      value: "05404",
    },
    {
      name: "ZAPALLAR",
      parent: "0540",
      value: "05405",
    },
    {
      name: "QUILLOTA",
      parent: "0550",
      value: "05501",
    },
    {
      name: "LA CALERA",
      parent: "0550",
      value: "05502",
    },
    {
      name: "HIJUELAS",
      parent: "0550",
      value: "05503",
    },
    {
      name: "LA CRUZ",
      parent: "0550",
      value: "05504",
    },
    {
      name: "NOGALES",
      parent: "0550",
      value: "05506",
    },
    {
      name: "SAN ANTONIO",
      parent: "0560",
      value: "05601",
    },
    {
      name: "ALGARROBO",
      parent: "0560",
      value: "05602",
    },
    {
      name: "CARTAGENA",
      parent: "0560",
      value: "05603",
    },
    {
      name: "EL QUISCO",
      parent: "0560",
      value: "05604",
    },
    {
      name: "EL TABO",
      parent: "0560",
      value: "05605",
    },
    {
      name: "SANTO DOMINGO",
      parent: "0560",
      value: "05606",
    },
    {
      name: "SAN FELIPE",
      parent: "0570",
      value: "05701",
    },
    {
      name: "CATEMU",
      parent: "0570",
      value: "05702",
    },
    {
      name: "LLAY-LLAY",
      parent: "0570",
      value: "05703",
    },
    {
      name: "PANQUEHUE",
      parent: "0570",
      value: "05704",
    },
    {
      name: "PUTAENDO",
      parent: "0570",
      value: "05705",
    },
    {
      name: "SANTA MARÍA",
      parent: "0570",
      value: "05706",
    },
    {
      name: "QUILPUÉ",
      parent: "0580",
      value: "05801",
    },
    {
      name: "LIMACHE",
      parent: "0580",
      value: "05802",
    },
    {
      name: "OLMUÉ",
      parent: "0580",
      value: "05803",
    },
    {
      name: "VILLA ALEMANA",
      parent: "0580",
      value: "05804",
    },
    {
      name: "RANCAGUA",
      parent: "0610",
      value: "06101",
    },
    {
      name: "CODEGUA",
      parent: "0610",
      value: "06102",
    },
    {
      name: "COINCO",
      parent: "0610",
      value: "06103",
    },
    {
      name: "COLTAUCO",
      parent: "0610",
      value: "06104",
    },
    {
      name: "DOÑIHUE",
      parent: "0610",
      value: "06105",
    },
    {
      name: "GRANEROS",
      parent: "0610",
      value: "06106",
    },
    {
      name: "LAS CABRAS",
      parent: "0610",
      value: "06107",
    },
    {
      name: "MACHALÍ",
      parent: "0610",
      value: "06108",
    },
    {
      name: "MALLOA",
      parent: "0610",
      value: "06109",
    },
    {
      name: "MOSTAZAL",
      parent: "0610",
      value: "06110",
    },
    {
      name: "OLIVAR",
      parent: "0610",
      value: "06111",
    },
    {
      name: "PEUMO",
      parent: "0610",
      value: "06112",
    },
    {
      name: "PICHIDEGUA",
      parent: "0610",
      value: "06113",
    },
    {
      name: "QUINTA DE TILCOCO",
      parent: "0610",
      value: "06114",
    },
    {
      name: "RENGO",
      parent: "0610",
      value: "06115",
    },
    {
      name: "REQUÍNOA",
      parent: "0610",
      value: "06116",
    },
    {
      name: "SAN VICENTE",
      parent: "0610",
      value: "06117",
    },
    {
      name: "PICHILEMU",
      parent: "0620",
      value: "06201",
    },
    {
      name: "LA ESTRELLA",
      parent: "0620",
      value: "06202",
    },
    {
      name: "LITUECHE",
      parent: "0620",
      value: "06203",
    },
    {
      name: "MARCHIHUE",
      parent: "0620",
      value: "06204",
    },
    {
      name: "NAVIDAD",
      parent: "0620",
      value: "06205",
    },
    {
      name: "PAREDONES",
      parent: "0620",
      value: "06206",
    },
    {
      name: "SAN FERNANDO",
      parent: "0630",
      value: "06301",
    },
    {
      name: "CHÉPICA",
      parent: "0630",
      value: "06302",
    },
    {
      name: "CHIMBARONGO",
      parent: "0630",
      value: "06303",
    },
    {
      name: "LOLOL",
      parent: "0630",
      value: "06304",
    },
    {
      name: "NANCAGUA",
      parent: "0630",
      value: "06305",
    },
    {
      name: "PALMILLA",
      parent: "0630",
      value: "06306",
    },
    {
      name: "PERALILLO",
      parent: "0630",
      value: "06307",
    },
    {
      name: "PLACILLA",
      parent: "0630",
      value: "06308",
    },
    {
      name: "PUMANQUE",
      parent: "0630",
      value: "06309",
    },
    {
      name: "SANTA CRUZ",
      parent: "0630",
      value: "06310",
    },
    {
      name: "TALCA",
      parent: "0710",
      value: "07101",
    },
    {
      name: "CONSTITUCIÓN",
      parent: "0710",
      value: "07102",
    },
    {
      name: "CUREPTO",
      parent: "0710",
      value: "07103",
    },
    {
      name: "EMPEDRADO",
      parent: "0710",
      value: "07104",
    },
    {
      name: "MAULE",
      parent: "0710",
      value: "07105",
    },
    {
      name: "PELARCO",
      parent: "0710",
      value: "07106",
    },
    {
      name: "PENCAHUE",
      parent: "0710",
      value: "07107",
    },
    {
      name: "RÍO CLARO",
      parent: "0710",
      value: "07108",
    },
    {
      name: "SAN CLEMENTE",
      parent: "0710",
      value: "07109",
    },
    {
      name: "SAN RAFAEL",
      parent: "0710",
      value: "07110",
    },
    {
      name: "CAUQUENES",
      parent: "0720",
      value: "07201",
    },
    {
      name: "CHANCO",
      parent: "0720",
      value: "07202",
    },
    {
      name: "PELLUHUE",
      parent: "0720",
      value: "07203",
    },
    {
      name: "CURICÓ",
      parent: "0730",
      value: "07301",
    },
    {
      name: "HUALAÑÉ",
      parent: "0730",
      value: "07302",
    },
    {
      name: "LICANTÉN",
      parent: "0730",
      value: "07303",
    },
    {
      name: "MOLINA",
      parent: "0730",
      value: "07304",
    },
    {
      name: "RAUCO",
      parent: "0730",
      value: "07305",
    },
    {
      name: "ROMERAL",
      parent: "0730",
      value: "07306",
    },
    {
      name: "SAGRADA FAMILIA",
      parent: "0730",
      value: "07307",
    },
    {
      name: "TENO",
      parent: "0730",
      value: "07308",
    },
    {
      name: "VICHUQUÉN",
      parent: "0730",
      value: "07309",
    },
    {
      name: "LINARES",
      parent: "0740",
      value: "07401",
    },
    {
      name: "COLBÚN",
      parent: "0740",
      value: "07402",
    },
    {
      name: "LONGAVÍ",
      parent: "0740",
      value: "07403",
    },
    {
      name: "PARRAL",
      parent: "0740",
      value: "07404",
    },
    {
      name: "RETIRO",
      parent: "0740",
      value: "07405",
    },
    {
      name: "SAN JAVIER",
      parent: "0740",
      value: "07406",
    },
    {
      name: "VILLA ALEGRE",
      parent: "0740",
      value: "07407",
    },
    {
      name: "YERBAS BUENAS",
      parent: "0740",
      value: "07408",
    },
    {
      name: "CONCEPCIÓN",
      parent: "0810",
      value: "08101",
    },
    {
      name: "CORONEL",
      parent: "0810",
      value: "08102",
    },
    {
      name: "CHIGUAYANTE",
      parent: "0810",
      value: "08103",
    },
    {
      name: "FLORIDA",
      parent: "0810",
      value: "08104",
    },
    {
      name: "HUALQUI",
      parent: "0810",
      value: "08105",
    },
    {
      name: "LOTA",
      parent: "0810",
      value: "08106",
    },
    {
      name: "PENCO",
      parent: "0810",
      value: "08107",
    },
    {
      name: "SAN PEDRO DE LA PAZ",
      parent: "0810",
      value: "08108",
    },
    {
      name: "SANTA JUANA",
      parent: "0810",
      value: "08109",
    },
    {
      name: "TALCAHUANO",
      parent: "0810",
      value: "08110",
    },
    {
      name: "TOMÉ",
      parent: "0810",
      value: "08111",
    },
    {
      name: "HUALPÉN",
      parent: "0810",
      value: "08112",
    },
    {
      name: "LEBU",
      parent: "0820",
      value: "08201",
    },
    {
      name: "ARAUCO",
      parent: "0820",
      value: "08202",
    },
    {
      name: "CAÑETE",
      parent: "0820",
      value: "08203",
    },
    {
      name: "CONTULMO",
      parent: "0820",
      value: "08204",
    },
    {
      name: "CURANILAHUE",
      parent: "0820",
      value: "08205",
    },
    {
      name: "LOS ÁLAMOS",
      parent: "0820",
      value: "08206",
    },
    {
      name: "TIRÚA",
      parent: "0820",
      value: "08207",
    },
    {
      name: "LOS ÁNGELES",
      parent: "0830",
      value: "08301",
    },
    {
      name: "ANTUCO",
      parent: "0830",
      value: "08302",
    },
    {
      name: "CABRERO",
      parent: "0830",
      value: "08303",
    },
    {
      name: "LAJA",
      parent: "0830",
      value: "08304",
    },
    {
      name: "MULCHÉN",
      parent: "0830",
      value: "08305",
    },
    {
      name: "NACIMIENTO",
      parent: "0830",
      value: "08306",
    },
    {
      name: "NEGRETE",
      parent: "0830",
      value: "08307",
    },
    {
      name: "QUILACO",
      parent: "0830",
      value: "08308",
    },
    {
      name: "QUILLECO",
      parent: "0830",
      value: "08309",
    },
    {
      name: "SAN ROSENDO",
      parent: "0830",
      value: "08310",
    },
    {
      name: "SANTA BÁRBARA",
      parent: "0830",
      value: "08311",
    },
    {
      name: "TUCAPEL",
      parent: "0830",
      value: "08312",
    },
    {
      name: "YUMBEL",
      parent: "0830",
      value: "08313",
    },
    {
      name: "ALTO BIOBÍO",
      parent: "0830",
      value: "08314",
    },
    {
      name: "TEMUCO",
      parent: "0910",
      value: "09101",
    },
    {
      name: "CARAHUE",
      parent: "0910",
      value: "09102",
    },
    {
      name: "CUNCO",
      parent: "0910",
      value: "09103",
    },
    {
      name: "CURARREHUE",
      parent: "0910",
      value: "09104",
    },
    {
      name: "FREIRE",
      parent: "0910",
      value: "09105",
    },
    {
      name: "GALVARINO",
      parent: "0910",
      value: "09106",
    },
    {
      name: "GORBEA",
      parent: "0910",
      value: "09107",
    },
    {
      name: "LAUTARO",
      parent: "0910",
      value: "09108",
    },
    {
      name: "LONCOCHE",
      parent: "0910",
      value: "09109",
    },
    {
      name: "MELIPEUCO",
      parent: "0910",
      value: "09110",
    },
    {
      name: "NUEVA IMPERIAL",
      parent: "0910",
      value: "09111",
    },
    {
      name: "PADRE LAS CASAS",
      parent: "0910",
      value: "09112",
    },
    {
      name: "PERQUENCO",
      parent: "0910",
      value: "09113",
    },
    {
      name: "PITRUFQUÉN",
      parent: "0910",
      value: "09114",
    },
    {
      name: "PUCÓN",
      parent: "0910",
      value: "09115",
    },
    {
      name: "SAAVEDRA",
      parent: "0910",
      value: "09116",
    },
    {
      name: "TEODORO SCHMIDT",
      parent: "0910",
      value: "09117",
    },
    {
      name: "TOLTÉN",
      parent: "0910",
      value: "09118",
    },
    {
      name: "VILCÚN",
      parent: "0910",
      value: "09119",
    },
    {
      name: "VILLARRICA",
      parent: "0910",
      value: "09120",
    },
    {
      name: "CHOLCHOL",
      parent: "0910",
      value: "09121",
    },
    {
      name: "ANGOL",
      parent: "0920",
      value: "09201",
    },
    {
      name: "COLLIPULLI",
      parent: "0920",
      value: "09202",
    },
    {
      name: "CURACAUTÍN",
      parent: "0920",
      value: "09203",
    },
    {
      name: "ERCILLA",
      parent: "0920",
      value: "09204",
    },
    {
      name: "LONQUIMAY",
      parent: "0920",
      value: "09205",
    },
    {
      name: "LOS SAUCES",
      parent: "0920",
      value: "09206",
    },
    {
      name: "LUMACO",
      parent: "0920",
      value: "09207",
    },
    {
      name: "PURÉN",
      parent: "0920",
      value: "09208",
    },
    {
      name: "RENAICO",
      parent: "0920",
      value: "09209",
    },
    {
      name: "TRAIGUÉN",
      parent: "0920",
      value: "09210",
    },
    {
      name: "VICTORIA",
      parent: "0920",
      value: "09211",
    },
    {
      name: "PUERTO MONTT",
      parent: "1010",
      value: "10101",
    },
    {
      name: "CALBUCO",
      parent: "1010",
      value: "10102",
    },
    {
      name: "COCHAMÓ",
      parent: "1010",
      value: "10103",
    },
    {
      name: "FRESIA",
      parent: "1010",
      value: "10104",
    },
    {
      name: "FRUTILLAR",
      parent: "1010",
      value: "10105",
    },
    {
      name: "LOS MUERMOS",
      parent: "1010",
      value: "10106",
    },
    {
      name: "LLANQUIHUE",
      parent: "1010",
      value: "10107",
    },
    {
      name: "MAULLÍN",
      parent: "1010",
      value: "10108",
    },
    {
      name: "PUERTO VARAS",
      parent: "1010",
      value: "10109",
    },
    {
      name: "CASTRO",
      parent: "1020",
      value: "10201",
    },
    {
      name: "ANCUD",
      parent: "1020",
      value: "10202",
    },
    {
      name: "CHONCHI",
      parent: "1020",
      value: "10203",
    },
    {
      name: "CURACO DE VÉLEZ",
      parent: "1020",
      value: "10204",
    },
    {
      name: "DALCAHUE",
      parent: "1020",
      value: "10205",
    },
    {
      name: "PUQUELDÓN",
      parent: "1020",
      value: "10206",
    },
    {
      name: "QUEILÉN",
      parent: "1020",
      value: "10207",
    },
    {
      name: "QUELLÓN",
      parent: "1020",
      value: "10208",
    },
    {
      name: "QUEMCHI",
      parent: "1020",
      value: "10209",
    },
    {
      name: "QUINCHAO",
      parent: "1020",
      value: "10210",
    },
    {
      name: "OSORNO",
      parent: "1030",
      value: "10301",
    },
    {
      name: "PUERTO OCTAY",
      parent: "1030",
      value: "10302",
    },
    {
      name: "PURRANQUE",
      parent: "1030",
      value: "10303",
    },
    {
      name: "PUYEHUE",
      parent: "1030",
      value: "10304",
    },
    {
      name: "RÍO NEGRO",
      parent: "1030",
      value: "10305",
    },
    {
      name: "SAN JUAN DE LA COSTA",
      parent: "1030",
      value: "10306",
    },
    {
      name: "SAN PABLO",
      parent: "1030",
      value: "10307",
    },
    {
      name: "CHAITÉN",
      parent: "1040",
      value: "10401",
    },
    {
      name: "FUTALEUFÚ",
      parent: "1040",
      value: "10402",
    },
    {
      name: "HUALAIHUÉ",
      parent: "1040",
      value: "10403",
    },
    {
      name: "PALENA",
      parent: "1040",
      value: "10404",
    },
    {
      name: "COYHAIQUE",
      parent: "1110",
      value: "11101",
    },
    {
      name: "LAGO VERDE",
      parent: "1110",
      value: "11102",
    },
    {
      name: "AYSÉN",
      parent: "1120",
      value: "11201",
    },
    {
      name: "CISNES",
      parent: "1120",
      value: "11202",
    },
    {
      name: "GUAITECAS",
      parent: "1120",
      value: "11203",
    },
    {
      name: "COCHRANE",
      parent: "1130",
      value: "11301",
    },
    {
      name: "O'HIGGINS",
      parent: "1130",
      value: "11302",
    },
    {
      name: "TORTEL",
      parent: "1130",
      value: "11303",
    },
    {
      name: "CHILE CHICO",
      parent: "1140",
      value: "11401",
    },
    {
      name: "RÍO IBÁÑEZ",
      parent: "1140",
      value: "11402",
    },
    {
      name: "PUNTA ARENAS",
      parent: "1210",
      value: "12101",
    },
    {
      name: "LAGUNA BLANCA",
      parent: "1210",
      value: "12102",
    },
    {
      name: "RÍO VERDE",
      parent: "1210",
      value: "12103",
    },
    {
      name: "SAN GREGORIO",
      parent: "1210",
      value: "12104",
    },
    {
      name: "CABO DE HORNOS",
      parent: "1220",
      value: "12201",
    },
    {
      name: "ANTÁRTICA",
      parent: "1220",
      value: "12202",
    },
    {
      name: "PORVENIR",
      parent: "1230",
      value: "12301",
    },
    {
      name: "PRIMAVERA",
      parent: "1230",
      value: "12302",
    },
    {
      name: "TIMAUKEL",
      parent: "1230",
      value: "12303",
    },
    {
      name: "NATALES",
      parent: "1240",
      value: "12401",
    },
    {
      name: "TORRES DEL PAINE",
      parent: "1240",
      value: "12402",
    },
    {
      name: "SANTIAGO",
      parent: "1310",
      value: "13101",
    },
    {
      name: "CERRILLOS",
      parent: "1310",
      value: "13102",
    },
    {
      name: "CERRO NAVIA",
      parent: "1310",
      value: "13103",
    },
    {
      name: "CONCHALÍ",
      parent: "1310",
      value: "13104",
    },
    {
      name: "EL BOSQUE",
      parent: "1310",
      value: "13105",
    },
    {
      name: "ESTACIÓN CENTRAL",
      parent: "1310",
      value: "13106",
    },
    {
      name: "HUECHURABA",
      parent: "1310",
      value: "13107",
    },
    {
      name: "INDEPENDENCIA",
      parent: "1310",
      value: "13108",
    },
    {
      name: "LA CISTERNA",
      parent: "1310",
      value: "13109",
    },
    {
      name: "LA FLORIDA",
      parent: "1310",
      value: "13110",
    },
    {
      name: "LA GRANJA",
      parent: "1310",
      value: "13111",
    },
    {
      name: "LA PINTANA",
      parent: "1310",
      value: "13112",
    },
    {
      name: "LA REINA",
      parent: "1310",
      value: "13113",
    },
    {
      name: "LAS CONDES",
      parent: "1310",
      value: "13114",
    },
    {
      name: "LO BARNECHEA",
      parent: "1310",
      value: "13115",
    },
    {
      name: "LO ESPEJO",
      parent: "1310",
      value: "13116",
    },
    {
      name: "LO PRADO",
      parent: "1310",
      value: "13117",
    },
    {
      name: "MACUL",
      parent: "1310",
      value: "13118",
    },
    {
      name: "MAIPÚ",
      parent: "1310",
      value: "13119",
    },
    {
      name: "ÑUÑOA",
      parent: "1310",
      value: "13120",
    },
    {
      name: "PEDRO AGUIRRE CERDA",
      parent: "1310",
      value: "13121",
    },
    {
      name: "PEÑALOLÉN",
      parent: "1310",
      value: "13122",
    },
    {
      name: "PROVIDENCIA",
      parent: "1310",
      value: "13123",
    },
    {
      name: "PUDAHUEL",
      parent: "1310",
      value: "13124",
    },
    {
      name: "QUILICURA",
      parent: "1310",
      value: "13125",
    },
    {
      name: "QUINTA NORMAL",
      parent: "1310",
      value: "13126",
    },
    {
      name: "RECOLETA",
      parent: "1310",
      value: "13127",
    },
    {
      name: "RENCA",
      parent: "1310",
      value: "13128",
    },
    {
      name: "SAN JOAQUÍN",
      parent: "1310",
      value: "13129",
    },
    {
      name: "SAN MIGUEL",
      parent: "1310",
      value: "13130",
    },
    {
      name: "SAN RAMÓN",
      parent: "1310",
      value: "13131",
    },
    {
      name: "VITACURA",
      parent: "1310",
      value: "13132",
    },
    {
      name: "PUENTE ALTO",
      parent: "1320",
      value: "13201",
    },
    {
      name: "PIRQUE",
      parent: "1320",
      value: "13202",
    },
    {
      name: "SAN JOSÉ DE MAIPO",
      parent: "1320",
      value: "13203",
    },
    {
      name: "COLINA",
      parent: "1330",
      value: "13301",
    },
    {
      name: "LAMPA",
      parent: "1330",
      value: "13302",
    },
    {
      name: "TIL TIL",
      parent: "1330",
      value: "13303",
    },
    {
      name: "SAN BERNARDO",
      parent: "1340",
      value: "13401",
    },
    {
      name: "BUIN",
      parent: "1340",
      value: "13402",
    },
    {
      name: "CALERA DE TANGO",
      parent: "1340",
      value: "13403",
    },
    {
      name: "PAINE",
      parent: "1340",
      value: "13404",
    },
    {
      name: "MELIPILLA",
      parent: "1350",
      value: "13501",
    },
    {
      name: "ALHUÉ",
      parent: "1350",
      value: "13502",
    },
    {
      name: "CURACAVÍ",
      parent: "1350",
      value: "13503",
    },
    {
      name: "MARÍA PINTO",
      parent: "1350",
      value: "13504",
    },
    {
      name: "SAN PEDRO",
      parent: "1350",
      value: "13505",
    },
    {
      name: "TALAGANTE",
      parent: "1360",
      value: "13601",
    },
    {
      name: "EL MONTE",
      parent: "1360",
      value: "13602",
    },
    {
      name: "ISLA DE MAIPO",
      parent: "1360",
      value: "13603",
    },
    {
      name: "PADRE HURTADO",
      parent: "1360",
      value: "13604",
    },
    {
      name: "PEÑAFLOR",
      parent: "1360",
      value: "13605",
    },
    {
      name: "VALDIVIA",
      parent: "1410",
      value: "14101",
    },
    {
      name: "CORRAL",
      parent: "1410",
      value: "14102",
    },
    {
      name: "LANCO",
      parent: "1410",
      value: "14103",
    },
    {
      name: "LOS LAGOS",
      parent: "1410",
      value: "14104",
    },
    {
      name: "MÁFIL",
      parent: "1410",
      value: "14105",
    },
    {
      name: "MARIQUINA",
      parent: "1410",
      value: "14106",
    },
    {
      name: "PAILLACO",
      parent: "1410",
      value: "14107",
    },
    {
      name: "PANGUIPULLI",
      parent: "1410",
      value: "14108",
    },
    {
      name: "LA UNIÓN",
      parent: "1420",
      value: "14201",
    },
    {
      name: "FUTRONO",
      parent: "1420",
      value: "14202",
    },
    {
      name: "LAGO RANCO",
      parent: "1420",
      value: "14203",
    },
    {
      name: "RÍO BUENO",
      parent: "1420",
      value: "14204",
    },
    {
      name: "ARICA",
      parent: "1510",
      value: "15101",
    },
    {
      name: "CAMARONES",
      parent: "1510",
      value: "15102",
    },
    {
      name: "PUTRE",
      parent: "1520",
      value: "15201",
    },
    {
      name: "GENERAL LAGOS",
      parent: "1520",
      value: "15202",
    },
    {
      name: "CHILLÁN",
      parent: "1610",
      value: "16101",
    },
    {
      name: "BULNES",
      parent: "1610",
      value: "16102",
    },
    {
      name: "CHILLÁN VIEJO",
      parent: "1610",
      value: "16103",
    },
    {
      name: "EL CARMEN",
      parent: "1610",
      value: "16104",
    },
    {
      name: "PEMUCO",
      parent: "1610",
      value: "16105",
    },
    {
      name: "PINTO",
      parent: "1610",
      value: "16106",
    },
    {
      name: "QUILLÓN",
      parent: "1610",
      value: "16107",
    },
    {
      name: "SAN IGNACIO",
      parent: "1610",
      value: "16108",
    },
    {
      name: "YUNGAY",
      parent: "1610",
      value: "16109",
    },
    {
      name: "QUIRIHUE",
      parent: "1610",
      value: "16201",
    },
    {
      name: "COBQUECURA",
      parent: "1610",
      value: "16202",
    },
    {
      name: "COELEMU",
      parent: "1610",
      value: "16203",
    },
    {
      name: "NINHUE",
      parent: "1610",
      value: "16204",
    },
    {
      name: "PORTEZUELO",
      parent: "1610",
      value: "16205",
    },
    {
      name: "RÁNQUIL",
      parent: "1610",
      value: "16206",
    },
    {
      name: "TREGUACO",
      parent: "1610",
      value: "16207",
    },
    {
      name: "SAN CARLOS",
      parent: "1610",
      value: "16301",
    },
    {
      name: "COIHUECO",
      parent: "1610",
      value: "16302",
    },
    {
      name: "ÑIQUÉN",
      parent: "1610",
      value: "16303",
    },
    {
      name: "SAN FABIÁN",
      parent: "1610",
      value: "16304",
    },
    {
      name: "SAN NICOLÁS",
      parent: "1610",
      value: "16305",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Pesos", value: CurrencyEnum.CLP },
    { name: "Unidad de Fomento", value: CurrencyEnum.UF },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Natural", value: "01" },
    { name: "Jurídica", value: "02" },
  ],
};
