export enum PathsEnum {
  SEARCH_RULE_DATA = "searchRuleResponse.data",
  ID = "id",
  STATUS = "rule.status",
  CREATED = "rule.createdAt",
  RULE_NAME = "rule.rule.name",
  SEARCH_RULE_TOTAL = "total",
  RULE_ID = "rule_id",
}

export enum EndpointsPathsEnum {
  RULE_REQUEST_BY_ID = "rules",
}

export enum FilterPathsEnum {
  BUSINESS_CONDITIONS = "filter.business_conditions",
  STATUS = "filter.status",
}
