import { ActionTypes } from "./actionTypes";
import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import { Color } from "@material-ui/lab";
import { IAppAction } from "./actionCreators";
import { MerchantForm } from "../../types/merchant_form";
import { SessionHistoryData } from "../../types/session_history_data";
import { VerificationResponse } from "../../types/verification_code_response";

export interface IAppState {
  merchants?: IMerchantsResponse;
  authInfo?: IAuthInfo;
  notification?: INotification;
  accessCode?: string;
  merchantAccessCode?: VerificationResponse;
  isConnectionSuccessful?: boolean;
  redirectModal?: {
    show: boolean;
    url: string;
  };
  historyData?: SessionHistoryData;
  isLoading?: boolean;
}

export interface INotification {
  message: string;
  show: boolean;
  type: Color;
}

export type IMerchantsResponse = {
  data: { _source: MerchantForm }[];
  total: number;
};

export const INITIAL_STATE: IAppState = {
  notification: { message: "", show: false, type: "success" },
  accessCode: "",
  merchantAccessCode: {
    isVerify: false,
    merchantName: "",
    username: "",
    timeConnect: "",
    status: "",
    created: 0,
    accessCode: "",
    merchantId: "",
    message: "",
    rol: [],
    email: "",
  },
  historyData: {
    data: [],
    total: 0,
  },
  merchants: {
    data: [],
    total: 0,
  },
  isConnectionSuccessful: false,
  redirectModal: {
    show: false,
    url: "",
  },
  isLoading: false,
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_AUTH_INFO:
      return {
        ...state,
        authInfo: action.authInfo,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification!,
      };
    case ActionTypes.SET_ACCESS_CODE:
      return {
        ...state,
        accessCode: action.accessCode!,
      };
    case ActionTypes.SET_VERIFY_ACCESS_CODE_MERCHANT:
      return {
        ...state,
        merchantAccessCode: action.merchantAccessCode!,
      };
    case ActionTypes.SET_CONNECTION_STATUS:
      return {
        ...state,
        isConnectionSuccessful: action.isConnectionSuccessful!,
      };
    case ActionTypes.SET_HISTORY_DATA:
      return {
        ...state,
        historyData: action.historyData!,
      };
    case ActionTypes.SET_MERCHANTS:
      return {
        ...state,
        merchants: action.merchants!,
      };
    case ActionTypes.SET_REDIRECT_MODAL:
      return {
        ...state,
        redirectModal: action.redirectModal!,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};
