import { Sections } from "../enums/sections";
import { ROUTES } from "./routes";

export const HEADER_SECTIONS = new Map([
  [Sections.CREATE_NODE, "Creación de Customer"],
  [Sections.CREATE_BRANCH, "Creación de Branch"],
  [Sections.EDIT_BRANCH, "Edición de Branch"],
  [Sections.CREATE_BRANCHES, "Creación de Branches"],
  [Sections.EDIT_BRANCHES, "Edición de Branches"],
  [Sections.EDIT_CUSTOMER, "Edición de Customer"],
  [Sections.CREATE_OWNER, "Creación de Owner"],
  [Sections.CREATE_CUSTOMER, "Creación de Customer"],
  [Sections.SELECT_EDITION_BRANCH, "Edición de branches descentralizados"],
  [Sections.MASSIVE_EDITION, "Edición masiva"],
]);

export const RESET_LOCALSTORAGE_ROUTES = [ROUTES.CREATE_NODE_OWNER];
