import { createStyles, makeStyles } from "@material-ui/core/styles";

const confirmColor: string = "#023365";
const linkColor: string = "#4498EE";

const useStyles = makeStyles(() =>
  createStyles({
    alertInfo: { color: "#293036", marginTop: "24px" },
    alertLink: { color: linkColor, fontColor: linkColor },
  })
);

export { useStyles, confirmColor };
