import React, { FC } from "react";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { IAlarmComments } from "@components/organism/DetailCardBodyAlarms/state/IUseDetailCardBodyAlarms.interfaces";
import { commentSectionStyles as styles } from "@components/molecule/CommentSection/CommentSection.styles";
import { IconEdit, IconTrash } from "@kushki/connect-ui";
const CommentSection: FC<IAlarmComments> = ({
  comment,
  createdAt,
  fullName,
  handleEdit,
  handleDeleted,
  timelineId,
  userName,
  userNameLocalStorage,
  isUpdated,
}: IAlarmComments) => {
  return (
    <Box sx={styles.container} id={timelineId}>
      <Box sx={styles.commentInfo}>
        <Typography variant={"body2"} sx={styles.textLightGray}>
          {fullName}
        </Typography>
        <Typography variant={"body2"} sx={styles.textLightGray}>
          {createdAt}
          {isUpdated && (
            <Chip
              color={"primary"}
              label={"Editado"}
              style={{ borderRadius: 4, marginLeft: "0px" }}
            />
          )}
        </Typography>
      </Box>
      <Box>
        <Typography variant={"body2"} sx={styles.comment}>
          {comment}
        </Typography>
      </Box>
      {handleDeleted && handleEdit && userNameLocalStorage === userName && (
        <Box sx={styles.actionButtons}>
          <IconButton onClick={() => handleEdit(timelineId, comment)}>
            <IconEdit fontSize={"small"} color={"primary"} />
          </IconButton>
          <IconButton
            disabled={userNameLocalStorage !== userName}
            onClick={() => handleDeleted(timelineId)}
          >
            <IconTrash fontSize={"small"} color={"primary"} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CommentSection;
