import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import userImage from "../../assets/images/userImage.svg";
import { Container, useMediaQuery, useTheme } from "@material-ui/core";
import {
  CardSupportUser,
  ICardSupportUser,
} from "../../components/CardSupportUser/CardSupportUser";
import { routes } from "../../shared/infrastructure/constants/routes";
import { SupportWrapperIdEmun } from "../../shared/infrastructure/catalogues/SupportWrapperIdEmun";

const AdminIndex = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const cardProps: ICardSupportUser = {
    title: "Usuarios",
    elements: [
      {
        idComponent: SupportWrapperIdEmun.ASISTENCIA_REMOTA,
        subtitle: "Asistencia remota",
        description:
          "Asesora al comercio visualizando la Consola de Cliente como el usuario. Esta opción es solo lectura.",
        redirect: routes.SUPPORT_ASSISTANCE,
      },
      {
        idComponent: SupportWrapperIdEmun.HISTORIRAL_ASISTENCIA_REMOTA,
        subtitle: "Historial de asistencia remota",
        description:
          "Visualiza el historial de asistencia remota del comercio.",
        redirect: routes.SUPPORT_HISTORY,
      },
    ],
    image: userImage,
    isMedium: isMedium,
  };

  return (
    <React.Fragment>
      <Container fixed>
        <PageHeader title="Soporte" />
        <CardSupportUser {...cardProps} />
      </Container>
    </React.Fragment>
  );
};

export default AdminIndex;
