import { appSlice } from "../../reducers/app/app";

export const {
  setAccumulatedAmount,
  hideLoading,
  hideSnackbar,
  setActiveColumns,
  setConciliationAction,
  setConciliationList,
  setSummary,
  setLoadingText,
  setNotification,
  setRowsSelected,
  setTotalConciliations,
  showLoading,
  showErrorSnackbar,
  showSuccessSnackbar,
  setSuccessUpdate,
} = appSlice.actions;
