import { ActionTypes } from "./actionTypes";
import { DispersionResponse } from "../../types/dispersion_response";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";

export interface IAppState {
  notification?: INotification;
  dispersionResponse?: DispersionResponse;
  isLoadingDispersion?: boolean;
}

export const INITIAL_STATE: IAppState = {
  dispersionResponse: {
    errors: [],
    records: 0,
    status: "",
  },
  isLoadingDispersion: false,
  notification: {
    message: "",
    open: false,
    type: "success",
  },
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_IS_LOADING_DISPERSION:
      return {
        ...state,
        isLoadingDispersion: action.isLoadingDispersion!,
      };
    case ActionTypes.SET_DISPERSION_RESPONSE:
      return {
        ...state,
        dispersionResponse: action.dispersionResponse!,
      };
    default:
      return state;
  }
};
