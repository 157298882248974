import React from "react";
import {
  createStyles,
  Grid,
  InputAdornment,
  TextField,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PopoverFilter } from "../PopoverFilters/PopoverFilter";
import { IOptionFilter } from "../common/BoxFilter/BoxFilter";
import { Search } from "react-feather";
import { includes, capitalize } from "lodash";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { Autocomplete } from "@material-ui/lab";
import { ActivityEconomicCol } from "../../shared/infrastructure/constants/RetentionConstantsCO";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      backgroundColor: "#F7FAFC",
    },
    textField: {
      marginRight: theme.spacing(1),
      marginTop: "2px",
      height: "36px",
      width: "35ch",
      padding: 0,
      textAlign: "initial",
    },
    containerSearch: {
      marginRight: theme.spacing(5),
    },
  })
);

export interface FilterDashboardFunctionProps {
  handleSetFilters?: (filters: { [key: string]: boolean }) => void;
  filterChips: { [key: string]: boolean };
}

export interface FilterDashboardStateProps {
  filters?: IOptionFilter[];
  counter: number;
  country?: CountryValueEnum;
  handleSetFiltersInput?: (name: string) => void;
}

export type DataTableFiltersProps = FilterDashboardStateProps &
  FilterDashboardFunctionProps;

export const DataTableFilters: React.FC<DataTableFiltersProps> = (
  props: DataTableFiltersProps
) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<null | string>(null);
  const searchInput = (
    option: string,
    state: {
      selected: boolean;
      inputValue: string;
    }
  ) => {
    const matches = match(option, state.inputValue);
    const parts = parse(option, matches);
    return (
      <li {...props}>
        <div>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        className={classes.root}
      >
        <Grid item xs={12}>
          <Grid container>
            {includes(
              [CountryValueEnum.Colombia],
              capitalize(props.country)
            ) && (
              <Grid item className={classes.containerSearch}>
                <Autocomplete
                  id="free"
                  freeSolo
                  options={ActivityEconomicCol.map(
                    (option: { value: string; name: string }) => option.name
                  )}
                  onKeyDown={(event: any) => {
                    if (event.key !== "Enter") return;
                    props.handleSetFiltersInput!(value!);
                  }}
                  onChange={(_: any, newValue: string | null) => {
                    setValue(newValue);
                  }}
                  value={value}
                  renderOption={(
                    option,
                    state: {
                      selected: boolean;
                      inputValue: string;
                    }
                  ) => searchInput(option, state)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="search"
                      key="Search"
                      className={classes.textField}
                      margin="dense"
                      size="small"
                      label="Buscar "
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search size={16} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <PopoverFilter
                handleSetFilters={props.handleSetFilters!}
                filterChips={props.filterChips}
                counterFilter={props.counter}
                filters={props.filters!}
                country={props.country}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
