import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { IMyMerchantInterface } from "./useMyMerchant.interfaces";
import { useEffect, useMemo, useState } from "react";
import { get, isEmpty } from "lodash";
import {
  getMerchantInfo,
  getMerchantNodeInfo,
  getNodeInfo,
} from "../../../store/thunks/app/app.thunks";
import {
  ConfigEnum,
  CONFIGURATION_CATALOG,
} from "../../../shared/enums/ConfigEnum";
import {
  findConfigurationValue,
  validateMerchantId,
} from "../../../shared/utils/utils";
import { isCustomerRol, isRol } from "../../../shared/utils";
import { getRatesInfo } from "../../../store/thunks/rates/rates.thunks";
import {
  getBrazilMerchantDeferred,
  getMerchantDeferred,
} from "../../../store/thunks/deferred/deferred.thunk";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { getServices } from "../../../store/thunks/processors/processors.thunks";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";
import { CountriesEnum } from "../../../shared/enums/CountriesEnum";
import { getRetentions } from "../../../store/thunks/retention/retention.thunks";
import { builderRows } from "../../../components/Table/TableNodes/constants";
import {
  CATALOG_ROWS,
  TableTypeEnum,
} from "../../../shared/catalogs/CatalogConfigTable";
import { ITableRowProps } from "../../../components/Table/TableNodes/interfaces";
import {
  b64toBlob,
  downloadBlob,
} from "../../../shared/infrastructure/PdfViewerDownload";
import { EnumCellOptionMenu } from "../../../components/TableCells/CellActionOptionMenu/constantsCellOptionMenu";
import { RetentionSnackBarMsg } from "../../../shared/constants/snackbar";
import { environment } from "../../../environments/environment";

export const useMyMerchant = (): IMyMerchantInterface => {
  const {
    merchantInfo,
    merchantDeferred,
    nodeInfo,
    ratesInfo,
    isLoadingRates,
    isLoadingRetention,
    retentionInfo,
    processors,
    isLoadingServices,
    cn001,
    cn002,
    cn003,
    cn004,
    cn016,
    cn017,
    cn018,
    notificationPDF,
    kindOption,
    pdfRetention,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.deferredApp,
    ...state.ratesApp,
    ...state.processors,
    ...state.retentionApp,
    ...state.downloadFileApp,
  }));

  const dispatch = useAppDispatch();
  const publicMerchantId = localStorage.getItem("merchantId")!;
  const isMasterCustomer: boolean = useMemo(() => isCustomerRol(), []);
  const isUserMaster: boolean = useMemo(
    () => isRol("BackofficeUserMaster"),
    []
  );
  const [isCustomerNode, setIsCustomerNode] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [isBranchNode, setIsBranchNode] = useState<boolean>(false);
  const [retentionData, setRetentionData] = useState<ITableRowProps[]>([]);
  const [totalRetentionData, setTotalRetentionData] = useState<number>(0);
  const [fileData, setfileData] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const getConfigurationValues = () => {
    const configurations = nodeInfo.configs || [];
    let configurationValuesList: { [key: string]: string } = {};

    CONFIGURATION_CATALOG.forEach((item) => {
      configurationValuesList[item] = findConfigurationValue(
        configurations,
        item,
        publicMerchantId
      );
    });

    return configurationValuesList;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getMerchantInfoMerged = (): MerchantBillingInfo => {
    let mergedMerchantInfo = { ...merchantInfo };

    if (isBranchNode)
      mergedMerchantInfo = {
        ...mergedMerchantInfo,
        ...cn001,
        ...cn002,
        ...cn003,
        ...cn004,
        ...cn016,
        ...cn018,
        ...cn017,
      } as MerchantBillingInfo;

    return mergedMerchantInfo;
  };

  useEffect(() => {
    const from: Date = new Date();
    const to: Date = new Date();

    from.setFullYear(to.getFullYear() - 1);
    dispatch(
      getNodeInfo({
        configurations: CONFIGURATION_CATALOG.join(","),
        publicMerchantId,
      })
    );
  }, []);

  useEffect(() => {
    const from: Date = new Date();
    const to: Date = new Date();

    from.setFullYear(to.getFullYear() - 1);
    setTimeout(() => {
      if (
        get(merchantInfo, "country", "") === CountriesEnum.COLOMBIA &&
        environment.retentionSwitch &&
        isUserMaster
      ) {
        dispatch(
          getRetentions({
            from: from.toISOString().slice(0, 10),
            merchantId: publicMerchantId,
            to: to.toISOString().slice(0, 10),
          })
        );
      }
    }, 1000);
  }, [merchantInfo]);

  useEffect(() => {
    if (isEmpty(retentionData)) {
      setTotalRetentionData(get(retentionInfo, "total", 0));
      setRetentionData(
        builderRows<object>(
          get(retentionInfo, "records", []),
          CATALOG_ROWS[TableTypeEnum.RETENTION],
          "",
          []
        )
      );
    }
  }, [retentionInfo]);

  useEffect(() => {
    if (!isEmpty(nodeInfo.generalInfo?.name)) {
      const configurationsValues = getConfigurationValues();
      let isBranch = false;

      if (nodeInfo.entityName === EntityNameEnum.CUSTOMER)
        setIsCustomerNode(true);
      else if (nodeInfo.entityName === EntityNameEnum.BRANCH) {
        isBranch = true;
        setIsBranchNode(isBranch);
      }
      dispatch(
        getMerchantInfo({
          publicMerchantId: configurationsValues[ConfigEnum.BASIC_DATA],
        })
      );
      dispatch(
        getRatesInfo({
          publicMerchantId: configurationsValues[ConfigEnum.RATES],
        })
      );
      dispatch(getServices(configurationsValues[ConfigEnum.PROCESSORS]));
      dispatch(getMerchantDeferred());

      if (isBranch) {
        const dispatch_config = [
          ConfigEnum.BASIC_DATA,
          ConfigEnum.BILLING,
          ConfigEnum.BILLING_DATA,
          ConfigEnum.CONTACT_DATA,
          ConfigEnum.MIN_AMOUNT,
          ConfigEnum.CHARGES,
          ConfigEnum.DISPERSION,
        ];
        const basicDataMerchantId: string =
          configurationsValues[ConfigEnum.BASIC_DATA];
        const billingMerchantId: string =
          configurationsValues[ConfigEnum.BILLING];

        dispatch_config.forEach((config) => {
          const merchantId: string = validateMerchantId(
            configurationsValues,
            config,
            publicMerchantId,
            basicDataMerchantId,
            billingMerchantId
          );

          dispatch(
            getMerchantNodeInfo({
              configuration: config,
              publicMerchantId: merchantId,
            })
          );
        });
      }
    }
  }, [nodeInfo]);
  useEffect(() => {
    setCountry(merchantInfo.country);
    if (merchantInfo.country === CountriesEnum.BRAZIL)
      dispatch(getBrazilMerchantDeferred());
  }, [merchantInfo]);
  const makeIteration = (): void => {
    setShowSnackBar(false);
  };

  useEffect(() => {
    if (
      kindOption !== undefined &&
      [EnumCellOptionMenu.VISUALIZAR, EnumCellOptionMenu.DESCARGAR].includes(
        kindOption!.type as EnumCellOptionMenu
      )
    ) {
      setShowSnackBar(notificationPDF!);
      setTimeout(makeIteration, 5000); // 1 second waiting
      setSnackBarMessage(
        RetentionSnackBarMsg[kindOption!.type as EnumCellOptionMenu]
      );
    }
  }, [notificationPDF]);

  useEffect(() => {
    const data = get(pdfRetention, "payload.data");

    if (kindOption !== undefined) {
      const name: string = kindOption!.id_registro + kindOption!.kind + ".pdf";

      if (kindOption!.type === EnumCellOptionMenu.DESCARGAR && !isEmpty(data)) {
        const file: Blob = b64toBlob(data!);

        downloadBlob(file, name);
      } else if (
        kindOption!.type === EnumCellOptionMenu.VISUALIZAR &&
        !isEmpty(data)
      ) {
        setfileData(data!);
        setFileName(name);
        setOpenModal(true);
      }
    }
  }, [pdfRetention]);

  return {
    country,
    isCustomerNode,
    isLoadingServices: isLoadingServices!,
    isMasterCustomer,
    isUserMaster,
    merchantDeferred,
    merchantInfo: getMerchantInfoMerged(),
    processors: processors!,
    ratesInfo: { isLoadingRates, ratesInfo },
    retentionInfo: {
      downloadFile: {
        fileData,
        fileName,
        handleCloseModal,
        openModal,
      },
      isLoadingRetention,
      rows: retentionData,
      totalRows: totalRetentionData,
    },
    snackBar: {
      showSnackBar,
      snackBarMessage,
    },
  };
};
