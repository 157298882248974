import { IUseGeneralSectionStateState } from "../../../shared/infrastructure/interfaces/IUseGeneralSectionStateState";
import { useFormContext } from "react-hook-form";
import { defaultTo, get, isArray, isEmpty, isEqual, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { RuleDetail } from "../../../../types/rule_detail";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  GENERAL_CONDITION_EXCLUDED_LABEL,
  LabelEnum,
} from "../../../shared/infrastructure/enums/LabelEnum";
import {
  Conditions,
  Operators,
} from "../../../../types/configuration_response";
import { IUseGeneralSectionStateStateProps } from "../../../shared/infrastructure/interfaces/IUseGeneralSectionStateStateProps";
import { TextFieldContainer } from "../components/TextFieldContainer/TextFieldContainer";
import { IGeneralConditionSectionProps } from "../../../shared/infrastructure/interfaces/IGeneralConditionSectionProps";
import { IConditionForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { useLocation, useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import {
  checkIsGeneralRule,
  isAmex,
  isFormEditMode,
} from "../../../utils/utilsFile";
import {
  IOptionSelection,
  OPERATORS,
  RULE_SMARTLINKS_ACTIVE,
  WHITELIST_SMARTLINKS_ACTIVE,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { AttachFile } from "../../AttachFile/AttachFile";

export const useGeneralSectionStateState = (
  props: IUseGeneralSectionStateStateProps
): IUseGeneralSectionStateState => {
  const basePathProps: IGeneralConditionSectionProps = get(
    props,
    "generalConditionSectionProps"
  );
  const { errors, control, watch, setValue, getValues, register } =
    useFormContext();
  const urlPath = useLocation();

  const { ruleId, id, ruleName } = useParams<IUseParams>();
  const isEditedRule: boolean | undefined = useSelector(
    (state: IAppState) => state.isEditedRule
  );
  const actionType: string | undefined = useSelector(
    (state: IAppState) => state.actionType
  );

  const [selectedItems, setSelectedItems] = useState<ICategory[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [bins, setBins] = useState<string[]>([]);
  const [textValue, setTextValue] = useState<string[]>([]);
  const [transactionSchedule, setTransactionSchedule] = useState<string[]>([]);
  const [transactionDate, setTransactionDate] = useState<string[]>([]);
  const [transactionCommerceSchedule, setTransactionCommerceSchedule] =
    useState<string[]>([]);
  const [transactionPhoneNumber, setTransactionPhoneNumber] = useState<
    string[]
  >([]);
  const [maskedCardList, setMaskedCardList] = useState<string[]>([]);
  const [disableConditionAlreadySelected, setDisableConditionAlreadySelected] =
    useState<boolean>(false);
  const [disableUnSelected, setDisableUnSelected] = useState<boolean>(true);
  const [isEditCondition, setIsEditCondition] = useState<boolean>(false);
  const [disableAdd, setDisableAdd] = useState<boolean>(false);

  const ruleDetail: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const currencyByCountry: Record<string, IOptionSelection[]> | undefined =
    useSelector((state: IAppState) => state.currencyByCountry);

  const conditionName: string = props.generalConditionSectionProps.isSpecialCond
    ? "specialCondition"
    : "condition";

  const rule: string = defaultTo(
    watch(`${conditionName}.${basePathProps.index}.label`),
    ""
  );

  const operator: string = defaultTo(
    watch(`${conditionName}.${basePathProps.index}.operator`),
    get(basePathProps, "item.condition", "")
  );

  let initialStateSelectConditions: Conditions[] = get(
    basePathProps,
    "generalConditions",
    []
  ).sort((a, b) => {
    return get(a, "title", "").localeCompare(get(b, "title", ""));
  });
  const [selectConditions, setSelectConditions] = useState<Conditions[]>(
    initialStateSelectConditions
  );
  const [isSmartlinkActive, setIsSmartlinkActive] = useState<boolean>(false);

  const filterSelectConditions = (conditions: Conditions[]): void => {
    const conditionValuesForm: string[] = defaultTo(
      get(getValues(), "condition"),
      []
    ).map(({ label }: IConditionForm) => (rule !== label ? label : ""));
    const filterConditions: Conditions[] = defaultTo(conditions, []).filter(
      (val: Conditions) =>
        !conditionValuesForm.includes(defaultTo(val.field, ""))
    );

    setSelectConditions(filterConditions);
  };

  useEffect(() => {
    if (checkIsGeneralRule(urlPath.pathname))
      initialStateSelectConditions = initialStateSelectConditions.filter(
        (el) => !GENERAL_CONDITION_EXCLUDED_LABEL.includes(el.field!)
      );
    filterSelectConditions(initialStateSelectConditions);
  }, [initialStateSelectConditions, get(getValues(), "condition", []).length]);
  const retrieveTextFieldsByRuleAndOperator = (index?: number): JSX.Element => {
    return (
      <TextFieldContainer
        operatorValue={operator}
        className={props.classes.subTitleConditions}
        mainProps={props}
        setSelectedItems={setSelectedItems}
        emails={emails}
        bins={bins}
        setEmails={setEmails}
        setBins={setBins}
        setTransactionSchedule={setTransactionSchedule}
        setTransactionPhoneNumber={setTransactionPhoneNumber}
        setMaskedCardList={setMaskedCardList}
        setDate={setTransactionDate}
        setTransactionCommerceSchedule={setTransactionCommerceSchedule}
        rule={rule}
        index={index}
        ruleDetail={ruleDetail}
        currencies={currencyByCountry}
        textValue={textValue}
        setTextValue={setTextValue}
        disableAdd={disableAdd}
        isEditCondition={isEditCondition}
        isSpecialCond={basePathProps.isSpecialCond}
        isFinalCond={basePathProps.total.length - 1 === basePathProps.index}
      />
    );
  };
  const renderAttachFile = (): JSX.Element => {
    return (
      <AttachFile label={rule} operator={operator} disabled={disableAdd} />
    );
  };

  const getMaskedCardList = (maskedCardList: string[]): string[] => {
    return isArray(maskedCardList)
      ? maskedCardList
          .map((el: string) => el.replace(/ /g, ""))
          .map((el: string) => {
            const cardNumber: string = isAmex(el) ? el.substring(0, 15) : el;
            if (isAmex(cardNumber))
              return cardNumber.replace(/(\d{6})\d{5}/, "$1XXXXX");
            return cardNumber.replace(/(\d{6})\d{6}/, "$1XXXXXX");
          })
      : [];
  };

  const saveInArray = (): string[] => {
    switch (rule) {
      case LabelEnum.EMAIL:
        return emails;
      case LabelEnum.BIN:
        return bins;
      case LabelEnum.TRANSACTION_SCHEDULE:
        return transactionSchedule;
      case LabelEnum.TRANSACTION_DATE:
        return transactionDate;
      case LabelEnum.TRANSACTION_COMMERCE_SCHEDULE:
        return transactionCommerceSchedule;
      case LabelEnum.MASKED_CARD:
        return getMaskedCardList(maskedCardList);
      case LabelEnum.PHONE_NUMBER:
        return transactionPhoneNumber;
      case LabelEnum.CURRENCY:
      case LabelEnum.PROCESSOR:
      case LabelEnum.CREDENTIAL:
        return textValue;
      default:
        return selectedItems
          ? selectedItems.map((x: ICategory) => x.value)
          : selectedItems;
    }
  };
  const value: string[] = saveInArray();

  useEffect(() => {
    register(`${conditionName}.${basePathProps.index}.value`);
  }, [basePathProps.index]);

  const setValueByRuleStrategy: any = {
    [LabelEnum.AMOUNT]: () => {
      if (!value.length) {
        return;
      }
      setValue(`${conditionName}.${basePathProps.index}.value`, value);
    },
  };

  useEffect(() => {
    if (
      !isEqual(
        getValues(`${conditionName}.${basePathProps.index}.value`),
        value
      )
    ) {
      if (setValueByRuleStrategy[rule]) {
        setValueByRuleStrategy[rule]();
      } else {
        setValue(`${conditionName}.${basePathProps.index}.value`, value);
      }

      const isFinalCond: boolean =
        basePathProps.total.length - 1 === basePathProps.index;

      if (
        !isFinalCond &&
        !isEditCondition &&
        !props.generalConditionSectionProps.isWhiteList
      )
        setDisableAdd(true);
    }
  }, [value]);

  useEffect(() => {
    const fields: string[] = props.generalConditionSectionProps.isWhiteList
      ? WHITELIST_SMARTLINKS_ACTIVE
      : RULE_SMARTLINKS_ACTIVE;

    setIsSmartlinkActive(fields.includes(rule));
  }, [rule]);

  useEffect(() => {
    if (isEditCondition)
      setValue(`${conditionName}.${basePathProps.index}.operator`, "");

    if (basePathProps.total.length - 1 === basePathProps.index) {
      if (!isFormEditMode(ruleId || ruleName)) {
        setValue(`${conditionName}.${basePathProps.index}.operator`, "");
      } else {
        if (
          isEmpty(basePathProps.total[basePathProps.index].value) &&
          operator !== "all"
        )
          setValue(`${conditionName}.${basePathProps.index}.operator`, "");
      }
    }
  }, [rule]);

  const getOperatorsByConditions = (): Operators[] => {
    const action: Conditions | undefined = get(
      basePathProps,
      "generalConditions",
      []
    ).find((conditions: Conditions) => conditions.field === rule);
    const newInfo: Operators[] = [];

    if (action !== undefined) {
      get(action, "operators", "")
        .split(",")
        .forEach((itemOperator: string) => {
          const operator = get(basePathProps, "generalOperators", []).find(
            (item: Operators) => item.value === itemOperator
          );
          if (operator) newInfo.push(operator);
        });
    }

    return newInfo;
  };

  const isFilledTypeOperator = (): boolean => {
    return operator === "all"
      ? false
      : (operator != "all" && operator != "") ||
          rule === LabelEnum.COUNTRY ||
          !isEmpty(get(basePathProps, "item.operator", ""));
  };

  const registerConditionsAppendFlow = (): void => {
    const conditions = basePathProps.total;

    for (let i: number = 0; i < conditions.length; i++) {
      if (!conditions[i].label || conditions[i].label === rule) continue;
      const isCondEdited: boolean =
        isEditCondition && isEqual(basePathProps.index, i);

      register(`${conditionName}.${i}.value`);
      setValue(
        `${conditionName}.${i}.value`,
        isCondEdited ? value : basePathProps.total[i].value
      );
    }
  };

  const handleAddTextField = (): void => {
    registerConditionsAppendFlow();
    setDisableAdd(true);
    const conditionValuesForm: IConditionForm[] = defaultTo(
      get(getValues(), "condition"),
      []
    );

    const emptyObject: object = {
      value: "",
      label: "",
      operator: "",
    };

    if (
      get(conditionValuesForm, "length", 0) > 1 &&
      !isEmpty(get(conditionValuesForm, `${basePathProps.index}.label`, ""))
    ) {
      const currentValuesConditionForm: IConditionForm[] =
        conditionValuesForm.filter(
          (data: IConditionForm) => data.label === rule
        );

      if (get(currentValuesConditionForm, "length", 0) >= 2) {
        setDisableConditionAlreadySelected(true);
      } else {
        if (basePathProps.index === basePathProps.total.length - 1) {
          basePathProps.append({
            ...emptyObject,
          });
        } else {
          basePathProps.append({ ...emptyObject, id: undefined });
        }

        setDisableConditionAlreadySelected(false);
      }
    } else {
      basePathProps.append({
        ...emptyObject,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDisableConditionAlreadySelected(false);
    }, 3500);
  }, [disableConditionAlreadySelected]);

  const handleRemoveTextField = (): void => {
    basePathProps.remove(basePathProps.index);
    registerConditionsRemoveFlow();
  };

  const registerConditionsRemoveFlow = (): void => {
    const auxData = basePathProps.total.filter(
      (c: any) => c.id !== basePathProps.total[basePathProps.index!].id
    );
    setTimeout(() => {
      for (let i = 0; i < auxData.length; i++) {
        register(`${conditionName}.${i}.value`);
        setValue(`${conditionName}.${i}.value`, auxData[i].value);
      }
    }, 500);
  };

  const handleEditCondition = (): void => {
    setIsEditCondition(true);
    setDisableAdd(false);
  };

  const isRenderAddButton = (): boolean => {
    return (
      (basePathProps.index === basePathProps.total.length - 1 &&
        !props.generalConditionSectionProps.isWhiteList) ||
      isEditCondition
    );
  };

  const isRenderRemoveButton = (): boolean => {
    return (
      basePathProps.index != basePathProps.total.length - 1 &&
      !props.generalConditionSectionProps.isWhiteList &&
      !isEditCondition
    );
  };

  useEffect(() => {
    const formOperator: string = getValues(
      `${conditionName}.${basePathProps.index}.operator`
    );
    const operatorByCondition: Operators[] = getOperatorsByConditions().filter(
      (item: Operators) => item.value === formOperator
    );

    if (
      operatorByCondition.length > 0 &&
      !isFormEditMode(ruleId || ruleName) &&
      !isEditedRule &&
      !isUndefined(formOperator)
    ) {
      setValue(
        `${conditionName}.${basePathProps.index}.operator`,
        formOperator
      );
    }
  }, [rule, ruleId, ruleName]);

  useEffect(() => {
    const isFinalCond: boolean =
      basePathProps.total.length - 1 === basePathProps.index;

    if (rule === LabelEnum.EMAIL && (isEditedRule || isFinalCond)) {
      setEmails([]);
    }

    if (isEqual(operator, OPERATORS.ALL)) setDisableUnSelected(false);

    setSelectedItems([]);
  }, [operator]);

  useEffect(() => {
    if (isFormEditMode(ruleId || ruleName) && !basePathProps.isSpecialCond) {
      const total = basePathProps.total;

      if (
        total[total.length - 1].label != "" &&
        basePathProps.index === total.length - 1
      )
        setTimeout(() => {
          handleAddTextField();
        }, 500);
    }

    if (isFormEditMode(id) && !props.generalConditionSectionProps.isWhiteList)
      setDisableAdd(true);
  }, []);

  useEffect(() => {
    const labelField: string = get(basePathProps, "item.label");
    const value: string[] = get(basePathProps, "item.value");

    if (
      isFormEditMode(ruleId || ruleName) ||
      isFormEditMode(id) ||
      isEditCondition
    ) {
      if (labelField === LabelEnum.EMAIL) {
        setEmails(value);
      } else if (labelField === LabelEnum.PHONE_NUMBER) {
        setTransactionPhoneNumber(value);
      } else if (labelField === LabelEnum.BIN) {
        setBins(value);
      } else if (
        [
          LabelEnum.CURRENCY,
          LabelEnum.CREDENTIAL,
          LabelEnum.PROCESSOR,
        ].includes(labelField as LabelEnum)
      ) {
        setTextValue(value);
      } else {
        const newValue: ICategory[] = [];
        if (value && value.length > 0)
          value.map((value: string) => {
            newValue.push({
              label: labelField,
              value: value,
              selected: true,
            });
          });
        setSelectedItems(newValue);
      }
    }
    filterSelectConditions(initialStateSelectConditions);
    setIsEditCondition(false);
  }, [basePathProps.item, ruleId, ruleName]);

  useEffect(() => {
    setDisableUnSelected(get(bins, "length", 0) === 0);
  }, [bins]);

  useEffect(() => {
    setDisableUnSelected(
      isEmpty(get(selectedItems[0], "value", "")) &&
        !isEqual(operator, OPERATORS.ALL)
    );
  }, [selectedItems]);

  useEffect(() => {
    setDisableUnSelected(get(transactionPhoneNumber, "length", 0) === 0);
  }, [transactionPhoneNumber]);

  useEffect(() => {
    setDisableUnSelected(
      get(emails, "length", 0) === 0 && !isEqual(operator, OPERATORS.ALL)
    );
  }, [emails]);

  useEffect(() => {
    const isDisabled: boolean =
      transactionSchedule.length === 0 ||
      isEmpty(defaultTo(transactionSchedule[0], "")) ||
      isEmpty(defaultTo(transactionSchedule[1], ""));
    setDisableUnSelected(isDisabled);
  }, [transactionSchedule]);

  useEffect(() => {
    setDisableUnSelected(get(maskedCardList, "length", 0) === 0);
  }, [maskedCardList]);

  useEffect(() => {
    setDisableUnSelected(get(transactionDate, "length", 0) === 0);
  }, [transactionDate]);

  useEffect(() => {
    setDisableUnSelected(get(transactionCommerceSchedule, "length", 0) === 0);
  }, [transactionCommerceSchedule]);

  useEffect(() => {
    setDisableUnSelected(
      get(textValue, "length", 0) === 0 || isEmpty(defaultTo(textValue[0], ""))
    );
  }, [textValue]);

  return {
    control,
    actionType,
    disableAdd,
    disableConditionAlreadySelected,
    disableUnSelected,
    errors,
    getOperatorsByConditions,
    handleAddTextField,
    handleEditCondition,
    handleRemoveTextField,
    isEditCondition,
    isFilledTypeOperator,
    isRenderAddButton,
    isRenderRemoveButton,
    isSmartlinkActive,
    renderAttachFile,
    retrieveTextFieldsByRuleAndOperator,
    selectConditions,
    rule,
  };
};
