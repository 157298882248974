import React from "react";
import {
  AppBar,
  Box,
  Container,
  makeStyles,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { TitlePage } from "../../components/common/TitlePage/TitlePage";
import { FileUploadCardComponent } from "../../components/FileUploadCardComponent/FileUploadCardComponent";
import { useFileUploadFormIndexState } from "./state/useFileUploadFormIndexState";
import { ErrorFileUploadComponent } from "../../components/FileUploadCardComponent/ErrorUploadFileComponent/ErrorFileUploadComponent";
import { FileUploadSuccessComponent } from "../../components/FileUploadSuccessComponent/FileUploadSuccessComponent";
import { Loader } from "@kushki/frontend-molecules/loader";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { capitalize, get } from "lodash";

import { createStyles, Theme } from "@material-ui/core/styles";
import { RetentionEnum } from "../../shared/infrastructure/Enums/RetentionEnum";
import { ModalDialog } from "../../components/ModalDialog/ModalDialog";
import { UploadActionButtonsComponent } from "../../components/UploadActionButtonsComponent/UploadActionButtonsComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontWeight: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "75%",
      height: "10%",
    },
    root: { background: "transparent", boxShadow: "none" },
    tabStyle: {
      textTransform: "none",
    },
    paper: {
      backgroundColor: "#F7FAFC",
      marginTop: 0,
    },
  })
);
export const FileUploadFormIndex: React.FC = () => {
  const {
    breadCrumbs,
    country,
    onSubmit,
    handleOpenError,
    isErrorCsv,
    handleCloseError,
    openError,
    errorCsvMessage,
    fileUploader,
    stateFile,
    isFileSent,
    handleSuccessRedirection,
    downloadTemplateCsv,
    downloadTemplateExcel,
    isLoadingDownloadFile,
    tab,
    modalDetailProps,
    securityAzure,
  } = useFileUploadFormIndexState();
  const classes = useStyles(0);
  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  function tabProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        {[CountryValueEnum.Ecuador].includes(
          capitalize(country!) as CountryValueEnum
        ) && <TitlePage title={"Carga con Archivo"} country={country!} />}
        {[CountryValueEnum.Colombia].includes(
          capitalize(country!) as CountryValueEnum
        ) && <TitlePage title={"Retenciones ICA"} country={country!} />}
        {isErrorCsv ? (
          <ErrorFileUploadComponent
            handleOpenError={handleOpenError}
            messageErrorCsv={errorCsvMessage}
          />
        ) : isFileSent ? (
          <FileUploadSuccessComponent
            handleSuccessRedirection={handleSuccessRedirection}
          />
        ) : (
          <form>
            {[CountryValueEnum.Ecuador].includes(
              capitalize(country!) as CountryValueEnum
            ) && (
              <>
                <AppBar position="static" className={classes.root}>
                  <Tabs
                    value={tab.value}
                    onChange={tab.handleTabsChange}
                    aria-label="billing tabs"
                    variant="fullWidth"
                    indicatorColor={"primary"}
                  >
                    <Tab
                      label={
                        <Typography variant={"h6"} color={"textPrimary"}>
                          Retención {RetentionEnum.IVA.toUpperCase()}
                        </Typography>
                      }
                      {...tabProps(0)}
                      className={classes.tabStyle}
                    />
                    <Tab
                      label={
                        <Typography variant={"h6"} color={"textPrimary"}>
                          Retención Renta
                        </Typography>
                      }
                      {...tabProps(1)}
                      className={classes.tabStyle}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={tab.value} index={tab.value}>
                  <FileUploadCardComponent
                    isLoadingDownloadFile={isLoadingDownloadFile}
                    fileUploader={fileUploader}
                    downloadTemplateCsv={downloadTemplateCsv}
                    downloadTemplateExcel={downloadTemplateExcel}
                    tab={tab.value}
                    country={country}
                    securityAzure={securityAzure}
                  />
                </TabPanel>
              </>
            )}
            {[CountryValueEnum.Colombia].includes(
              capitalize(country!) as CountryValueEnum
            ) && (
              <FileUploadCardComponent
                isLoadingDownloadFile={isLoadingDownloadFile}
                fileUploader={fileUploader}
                tab={tab.value}
                downloadTemplateCsv={downloadTemplateCsv}
                downloadTemplateExcel={downloadTemplateExcel}
                country={country}
                securityAzure={securityAzure}
              />
            )}
            <Box mb={2} mt={2}>
              <UploadActionButtonsComponent
                onSubmit={onSubmit}
                handleBack={modalDetailProps.setOpenModalDetail}
                stateFile={stateFile}
                buttonText={"Cargar archivo"}
                rolAzure={get(securityAzure, "rolUploadFile")}
              />
            </Box>
          </form>
        )}
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openError}
        message="No se realizaron cambios en la configuración."
        onClose={handleCloseError}
        autoHideDuration={3000}
      />
      <Loader
        open={stateFile.isLoading}
        headerText={"Estamos enviando la solicitud."}
        bodyText={"Por favor, espere..."}
      />
      <ModalDialog
        open={modalDetailProps.openModal}
        handleOk={modalDetailProps.returnWithoutSave}
        handleClose={modalDetailProps.setCloseModalDetail}
        title={`¿Deseas salir de Retención ${
          tab.value == 0
            ? RetentionEnum.IVA.toUpperCase()
            : RetentionEnum.RENTA.toUpperCase()
        } sin cargar el archivo con las configuraciones?`}
        labelBtnAccept={"Salir sin cargar"}
      />
    </React.Fragment>
  );
};
