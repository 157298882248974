import { DeepMap, FieldError } from "react-hook-form";
import { defaultTo, get, isArray, isUndefined } from "lodash";
import { getBasicMerchantInformation } from "./localStorage";
import { MerchantProcessors } from "../../../types/merchant_processors";
import {
  Condition,
  GetPayoutsRulesResponse,
  Rule,
} from "../../../types/get_payouts_rules_response";
import { IConditionItem } from "../interfaces/condition_item";
import { PriorityEnum, RuleEnum } from "../enums/RuleEnum";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/constants";
import { ICellRow } from "@kushki/connect-ui";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { StatusEnum } from "../enums/StatusEnum";
import { ColorEnum } from "../enums/ColorEnum";
import { EntityNameEnum } from "../enums/NodeInfoEnum";

export const retrieveFormError = (
  errors: DeepMap<Record<string, any>, FieldError>,
  pathProperty: string
): [boolean, string] => {
  const isFormError: boolean = !isUndefined(get(errors, pathProperty));

  return [
    isFormError,
    isFormError ? get(errors, `${pathProperty}.message`) : "",
  ];
};

export const existPathBusinessRuleList = (): boolean => {
  const redirectPath: string = defaultTo(
    getBasicMerchantInformation().redirectPath,
    ""
  );

  return redirectPath.search("/payout-business-rules/list/") >= 0;
};

export const getProcessorById = (
  value: string,
  processors: MerchantProcessors[]
): MerchantProcessors => {
  const processor = processors.find(
    (processor: MerchantProcessors) => processor.publicProcessorId === value
  );

  return defaultTo(processor, {
    alias: "",
    merchantId: "",
    publicProcessorId: "",
  });
};

export function deleteDuplicatedElements<T>(array: T[]): T[] {
  return array.reduce((acc: T[], cur: T) => {
    if (!acc.includes(cur)) {
      acc.push(cur);
    }

    return acc;
  }, []);
}

export const formatConditions = (conditions: IConditionItem[]): Condition[] => {
  return conditions.map((condition: IConditionItem) => {
    const value: string = isArray(condition.value)
      ? get(condition, "value", []).join(",")
      : defaultTo(condition.value, "");

    const newCondition: Condition = {
      ...condition,
      value: value,
    };

    if (condition.field === RuleEnum.VALIDATE_ENROLL)
      return {
        ...newCondition,
        priority: PriorityEnum.LAST,
      };

    return newCondition;
  });
};

export const formatConditionsToEdit = (
  conditions: Condition[]
): IConditionItem[] => {
  return conditions.map((condition: Condition) => ({
    ...condition,
    value: isArray(condition.value)
      ? condition.value
      : condition.value.split(","),
  }));
};

export const setTagColor = (status?: string) => {
  switch (status?.toUpperCase()) {
    case StatusEnum.DISABLE:
      return ColorEnum.WARNING;
    case StatusEnum.ENABLE:
      return ColorEnum.SUCCESS;
    case StatusEnum.DRAFT:
      return ColorEnum.ERROR;
    default:
      return ColorEnum.INFO;
  }
};

export const buildOneCell = (
  typeCell: TableBodyCellEnum,
  value1: string,
  value2?: string,
  style: ICellRow = { align: "left", spacing: 0 },
  status?: string
): ITableCellProps => {
  switch (typeCell) {
    case TableBodyCellEnum.TAG:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          color: setTagColor(status),
          text: value1,
        },
        type: TableBodyCellEnum.TAG,
      };
    case TableBodyCellEnum.TITLE:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          line1: value1,
          line2: value2,
          type: "twoLines",
        },
        type: TableBodyCellEnum.TITLE,
      };
    default:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          line1: value1,
          type: "oneLine",
        },
        type: TableBodyCellEnum.TEXT,
      };
  }
};

export function isCentralizedBranch() {
  const basicMerchantInformation = JSON.parse(
    defaultTo(localStorage.getItem("basicMerchantInformation"), "{}")
  );
  const isCentralizedNode = defaultTo(
    basicMerchantInformation.isCentralized,
    false
  );
  const isNotCustomerNode =
    defaultTo(basicMerchantInformation.entityName, "") !==
    EntityNameEnum.CUSTOMER;

  return isCentralizedNode && isNotCustomerNode;
}

export function updateRuleInList(
  id: string,
  searchRuleResponse: GetPayoutsRulesResponse
): GetPayoutsRulesResponse {
  const data: Rule[] = [...searchRuleResponse.data];
  const indexOfObject = data.findIndex((object: Rule) => object.id === id);

  data.splice(indexOfObject, 1);

  return { data, total: data.length };
}
