import React, { PropsWithChildren } from "react";
import { LabelEnum } from "../../../../shared/infrastructure/enums/LabelEnum";
import { Grid, Typography } from "@mui/material";
import { GeneralTextField } from "../GeneralTextField/GeneralTextField";
import {
  getCurrencyByCountry,
  HelperText,
  IOptionSelection,
  Labels,
  PlaceHolders,
  SubTitleSection,
} from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { get } from "lodash";
import {
  checkValidCurrencies,
  isOperatorEqualOrDifferent,
} from "../../../../utils/utilsFile";
import { TextFieldEmail } from "@kushki/connect-ui";
import { ItemEmail } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldEmail/TextFieldEmail.interfaces";
import { TransactionType } from "../../../../../types/configuration_response";
import { TimeComponentSection } from "../TimeComponentSection/TimeComponentSection";
import { AmountConditionComponent } from "../AmountConditionComponent/AmountConditionComponent";
import { TelephoneTag } from "../../../TelephoneTag/TelephoneTag";
import MaskedCardCondition from "../MaskedCardCondition/MaskedCardCondition";
import { ConditionSimpleLabel } from "../ConditionSimpleLabel/ConditionSimpleLabel";
import { SelectComponentSection } from "../SelectComponentSection/SelectComponentSection";
import { MerchantProcessors } from "../../../../../types/merchant_processors";
import { ITextFieldContainerProps } from "../../../../shared/infrastructure/interfaces/ITextFieldContainerProps";
import { GenericLabelConditionSection } from "../GenericLabelConditionSection/GenericLabelConditionSection";
import { DateComponentSection } from "../DateComponentSection/DateComponentSection";
import { ItemChip } from "../../../TextFieldChip/TextFieldChip.interfaces";
import { TextFieldChip } from "../../../TextFieldChip/TextFieldChip";
import { CredentialComponentSection } from "../CredentialComponentSection/CredentialComponentSection";
import { TextFieldCardId } from "../TextFieldCardId/TextFieldCardId";
import { channelCatalog } from "../../../../shared/infrastructure/constants/ChannelCatalog";
import { MerchantCategoryData } from "../../../../../types/merchant_category";
import { CatalogData } from "../../../../../types/catalog_data";
import { secureServiceCatalog } from "../../../../shared/infrastructure/constants/SecureServiceCatalog";

export const TextFieldContainer: React.FC<ITextFieldContainerProps> = (
  props: PropsWithChildren<ITextFieldContainerProps>
) => {
  const basePath: string = "mainProps.generalConditionSectionProps";
  const itemValueSelected: string[] = get(props, `${basePath}.item.value`, "");
  const maxSelectedItems: number | undefined = isOperatorEqualOrDifferent(
    props.operatorValue
  )
    ? 1
    : undefined;

  switch (props.rule) {
    case LabelEnum.BIN: {
      return (
        <Grid item xs={4}>
          <TextFieldChip
            items={props.bins.map((bin: string) => ({ value: bin }))}
            onItemsChange={(items: ItemChip[]) => {
              props.setBins(items.map((bin) => bin.value));
            }}
            placeholder={Labels.BIN}
            isDisabled={props.disableAdd}
            operator={props.operatorValue}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
            setBins={props.setBins}
          />
        </Grid>
      );
    }
    case LabelEnum.EMAIL:
      return (
        <Grid item xs={4}>
          <Typography variant={"subtitle2"} className={props.className}>
            {SubTitleSection.RULES_MAIL_SUBTITLE}
          </Typography>
          <TextFieldEmail
            mailLimit={maxSelectedItems}
            items={props.emails.map((email: string) => ({ mail: email }))}
            onItemsChange={(items: ItemEmail[]) => {
              props.setEmails(items.map((email: ItemEmail) => email.mail));
            }}
            placeholder={PlaceHolders.EMAIL}
            helperText={
              isOperatorEqualOrDifferent(props.operatorValue)
                ? HelperText.EMPTY
                : undefined
            }
            isDisabled={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.TRANSACTION_TYPE:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            maxSelectedItems={maxSelectedItems}
            className={props.className}
            title={SubTitleSection.RULES_TRANSACTION_TYPE_SUBTITLE}
            placeHolder={PlaceHolders.TRANSACTION_TYPE}
            items={get(props, `${basePath}.generalTransactionsType`, []).map(
              (transaction: TransactionType) => ({
                label: get(transaction, "title", ""),
                value: get(transaction, "name", ""),
              })
            )}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
            isDisable={props.disableAdd}
            operator={props.operatorValue}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    case LabelEnum.TRANSACTION_SCHEDULE:
      return (
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} className={props.className}>
            {SubTitleSection.RULES_GENERAL_CONDITIONS_LOCAL_TIME_SUBTITLE}
          </Typography>
          <TimeComponentSection
            selectedSchedule={itemValueSelected}
            setSchedule={(value: string[]) =>
              props.setTransactionSchedule(value)
            }
            isDisable={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.TRANSACTION_COMMERCE_SCHEDULE:
      return (
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} className={props.className}>
            {SubTitleSection.RULES_GENERAL_CONDITIONS_LOCAL_TIME_SUBTITLE}
          </Typography>
          <TimeComponentSection
            selectedSchedule={itemValueSelected}
            setSchedule={(value: string[]) =>
              props.setTransactionCommerceSchedule(value)
            }
            isDisable={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.TRANSACTION_DATE:
      return (
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} className={props.className}>
            {SubTitleSection.RULES_GENERAL_CONDITIONS_DATE_SUBTITLE}
          </Typography>
          <DateComponentSection
            setDate={(value: string[]) => {
              props.setDate(value.filter((e) => e));
            }}
            value={itemValueSelected}
            operator={props.operatorValue}
            isDisable={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.AMOUNT:
      return (
        <Grid item xs={12}>
          <AmountConditionComponent
            index={get(props, `${basePath}.index`)}
            operator={props.operatorValue}
            setSelectedItems={props.setSelectedItems}
            defaultValueList={itemValueSelected}
            isDisable={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.PHONE_NUMBER:
      return (
        <Grid item xs={12}>
          <TelephoneTag
            operator={props.operatorValue}
            setTransactionPhoneNumber={props.setTransactionPhoneNumber}
            defaultList={itemValueSelected}
            isDisable={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.MASKED_CARD:
      return (
        <Grid item xs={12}>
          <MaskedCardCondition
            operator={props.operatorValue}
            setSelectedItems={props.setMaskedCardList}
            defaultListCard={itemValueSelected}
            isDisabled={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.IP:
    case LabelEnum.SHIPPING_ADDRESS:
    case LabelEnum.BILLING_ADDRESS:
    case LabelEnum.DOCUMENT_NUMBER:
    case LabelEnum.CARD_HOLDER:
    case LabelEnum.METADATA:
    case LabelEnum.BANK:
    case LabelEnum.PROCESSOR_CODE:
    case LabelEnum.BRAND:
    case LabelEnum.COUNTRY:
      return (
        <Grid item xs={12}>
          <GenericLabelConditionSection
            tag={props.rule}
            operator={props.operatorValue}
            setSelectedItems={props.setSelectedItems}
            defaultListTextField={itemValueSelected}
            isDisable={props.disableAdd}
            isEditCondition={props.isEditCondition}
            isSpecialCond={props.isSpecialCond}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    case LabelEnum.CARD_ISSUER:
    case LabelEnum.CARD_TYPE:
    case LabelEnum.DEFERRED:
      return (
        <Grid item xs={12}>
          <ConditionSimpleLabel
            tag={props.rule}
            setSelectedItems={props.setSelectedItems}
            defaultValueList={itemValueSelected}
            isDisable={props.disableAdd}
          />
        </Grid>
      );
    case LabelEnum.CURRENCY:
      return (
        <SelectComponentSection
          placeHolder={Labels.CURRENCY_LIST}
          items={getCurrencyByCountry(
            get(props, `${basePath}.country`, []),
            props.currencies
          )}
          message={PlaceHolders.CURRENCY}
          selectedValue={checkValidCurrencies(
            itemValueSelected[0],
            get(props, `${basePath}.country`, []),
            props.currencies
          )}
          setTextValue={props.setTextValue}
          isDisable={props.disableAdd}
        />
      );
    case LabelEnum.CREDENTIAL:
      return (
        <CredentialComponentSection
          placeHolder={Labels.CREDENTIAL}
          selectedValue={
            itemValueSelected.length > 0 ? itemValueSelected[0] : ""
          }
          setTextValue={props.setTextValue}
        />
      );
    case LabelEnum.MCC:
      return (
        <Grid item xs={8}>
          <GeneralTextField
            maxSelectedItems={maxSelectedItems}
            className={props.className}
            title={SubTitleSection.RULES_MCC_SUBTITLE}
            placeHolder={PlaceHolders.MCC}
            items={get(props, `${basePath}.mccList`, []).map(
              (mcc: CatalogData): IOptionSelection => ({
                label: `${mcc.value} - ${mcc.name}`,
                value: mcc.value,
              })
            )}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
            isDisable={props.disableAdd}
            operator={props.operatorValue}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    case LabelEnum.CHANNEL:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            maxSelectedItems={maxSelectedItems}
            className={props.className}
            title={SubTitleSection.RULES_CHANNEL_SUBTITLE}
            placeHolder={PlaceHolders.CHANNEL}
            items={channelCatalog.map(
              (channel: CatalogData): IOptionSelection => ({
                label: channel.name,
                value: channel.value,
              })
            )}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
            isDisable={props.disableAdd}
            operator={props.operatorValue}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    case LabelEnum.SECURE_SERVICE:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            maxSelectedItems={maxSelectedItems}
            className={props.className}
            title={SubTitleSection.RULES_SECURE_SERVICE_SUBTITLE}
            placeHolder={PlaceHolders.SECURE_SERVICE}
            items={secureServiceCatalog.map(
              (service: CatalogData): IOptionSelection => ({
                label: service.name,
                value: service.value,
              })
            )}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
            isDisable={props.disableAdd}
            operator={props.operatorValue}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    case LabelEnum.PROCESSOR:
      return (
        <SelectComponentSection
          placeHolder={Labels.PROCESSOR}
          items={
            get(props, `${basePath}.processors`)
              ? get(props, `${basePath}.processors`, []).map(
                  (merchantProcessors: MerchantProcessors) => {
                    return {
                      label: get(merchantProcessors, "processorName", ""),
                      value: get(
                        merchantProcessors,
                        "publicProcessorId",
                        ""
                      ).toString(),
                    };
                  }
                )
              : [{ label: HelperText.PROCESSORS, value: "null" }]
          }
          message={PlaceHolders.PROCESSOR}
          selectedValue={
            itemValueSelected.length > 0 ? itemValueSelected[0] : ""
          }
          setTextValue={props.setTextValue}
          isDisable={props.disableAdd}
        />
      );
    case LabelEnum.CARD_ID:
      return (
        <Grid item xs={12}>
          <TextFieldCardId
            tag={props.rule}
            operator={props.operatorValue}
            setSelectedItems={props.setSelectedItems}
            defaultListTextField={itemValueSelected}
            isDisable={props.disableAdd}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    case LabelEnum.MERCHANT_CATEGORY:
      return (
        <Grid item xs={8}>
          <GeneralTextField
            maxSelectedItems={maxSelectedItems}
            className={props.className}
            title={SubTitleSection.MERCHANT_CATEGORY}
            placeHolder={PlaceHolders.MERCHANT_CATEGORY}
            items={get(props, `${basePath}.merchantCategoryList`, []).map(
              (category: MerchantCategoryData): IOptionSelection => ({
                label: `${category.name}`,
                value: category.value,
              })
            )}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
            isDisable={props.disableAdd}
            operator={props.operatorValue}
            isActiveSelectAll={true}
            isEditCondition={props.isEditCondition}
            isFinalCond={props.isFinalCond}
          />
        </Grid>
      );
    default:
      return <></>;
  }
};
