import React, { FC } from "react";
import { IconButton, Typography } from "@mui/material";
import { columnConfigurationTitleStyles } from "./ColumnConfigurationTitle.styles";
import CloseIcon from "@mui/icons-material/Close";
import { IColumnConfigurationTitle } from "./ColumnConfigurationTitle.interfaces";

const ColumnConfigurationTitle: FC<IColumnConfigurationTitle> = (props) => {
  const { columnConfiguration, maxColumns, handleClose } = props;

  return (
    <>
      <Typography sx={columnConfigurationTitleStyles.title}>
        Elige {columnConfiguration.length} columnas para mostrar
      </Typography>
      <Typography sx={columnConfigurationTitleStyles.subtitle}>
        Columnas seleccionadas {columnConfiguration.length} de {maxColumns}
      </Typography>
      {handleClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={columnConfigurationTitleStyles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      )}
    </>
  );
};

export { ColumnConfigurationTitle };
