import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { ICardListProps, ICardTableProps } from "@kushki/connect-ui";
import {
  generateSectionCondition,
  generateSectionResume,
  generateSectionSpeed,
} from "../../../utils/utilsFile";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import { RuleGeneralRequest } from "../../../../types/search_rule_catalog_response";
import { NewRuleSectionProps } from "../NewRuleSection";
import {
  ALLOWED_GENERAL_CONDITIONS_MODAL,
  ALLOWED_SPECIAL_CONDITIONS_MODAL,
  SubTitleSection,
  TitleSection,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { get } from "lodash";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";

export interface IUseNewRuleSectionState {
  handle: {
    handleDeleteSelectedRule: () => void;
    handleOpenCloseDeleteModal: () => void;
  };
  openModal: boolean;
  sectionResume: ICardListProps;
  sectionConditionGeneral: ICardTableProps;
  sectionSpecialConditions: ICardTableProps;
  sectionSpeed: ICardTableProps;
  showSectionSpeed: boolean;
  showSpecialConditionsSection: boolean;
  title: string;
}

export const useNewRuleSectionState = (
  props: NewRuleSectionProps
): IUseNewRuleSectionState => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const selectedRule: RuleGeneralRequest | undefined = useSelector(
    (state: IAppState) => state.detailCatalogGeneralRules
  );

  const configurationValue: ConfigurationResponse | undefined = useSelector(
    (state: IAppState) => state.configuration
  );
  const [title, setTitle] = useState<string>("");
  const [showSectionSpeed, setShowSectionSpeed] = useState<boolean>(false);
  const [showSpecialConditionsSection, setShowSpecialConditionsSection] =
    useState<boolean>(false);

  const [sectionResume, setSectionResume] = useState<ICardListProps>({
    title: TitleSection.CONFIGURATION_SECTION,
    orientation: "horizontal",
    separator: false,
    isLoading: true,
    items: [],
  });
  const [sectionConditionGeneral, setSectionCondicionGeneral] =
    useState<ICardTableProps>({
      title: TitleSection.RULES_GENERAL_CONDITIONS,
      isLoading: true,
      headers: [],
      rows: [],
    });
  const [sectionSpecialConditions, setSectionSpecialConditions] =
    useState<ICardTableProps>({
      title: LabelEnum.SPECIAL_CONDITION_RULES,
      isLoading: true,
      headers: [],
      rows: [],
    });

  const [sectionSpeed, setSeccionSpeed] = useState<ICardTableProps>({
    title: SubTitleSection.RULES_SPEED_CONDITION,
    isLoading: true,
    headers: [],
    rows: [],
  });

  const handleDeleteSelectedRule = () => {
    setOpenModal(false);
    props.handleDeleteSelectedRule();
  };

  const handleOpenCloseDeleteModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (selectedRule) {
      const hasSpecialCond: boolean = get(
        selectedRule,
        "rule.hasSpecialCond",
        false
      );

      setTitle(get(selectedRule, "name", ""));
      if (selectedRule.rule.kind === "speed" && !hasSpecialCond) {
        setShowSectionSpeed(true);
      }

      if (hasSpecialCond) {
        setShowSpecialConditionsSection(true);
        setSectionSpecialConditions(
          generateSectionCondition(
            selectedRule,
            configurationValue,
            ALLOWED_SPECIAL_CONDITIONS_MODAL,
            LabelEnum.SPECIAL_CONDITION_RULES
          )
        );
      }

      setSectionResume(generateSectionResume(selectedRule, configurationValue));

      setSectionCondicionGeneral(
        generateSectionCondition(
          selectedRule,
          configurationValue,
          ALLOWED_GENERAL_CONDITIONS_MODAL,
          TitleSection.RULES_GENERAL_CONDITIONS
        )
      );
      setSeccionSpeed(generateSectionSpeed(selectedRule, configurationValue));
    }
  }, [selectedRule]);

  return {
    handle: {
      handleOpenCloseDeleteModal,
      handleDeleteSelectedRule,
    },
    openModal,
    showSectionSpeed,
    showSpecialConditionsSection,
    sectionResume,
    sectionConditionGeneral,
    sectionSpecialConditions,
    sectionSpeed,
    title,
  };
};
