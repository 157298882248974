import { useEffect, useState } from "react";
import { cloneDeep, defaultTo, get, isEmpty, isNil } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { TransactionEnum } from "../../../shared/enums/TransactionEnum";
import { IUseTransactionList } from "./IUseTransactionList.interfaces";
import { resetTransactionList } from "../../../store/actions/app.actions";
import { IAllFilters } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheckChips/SelectCheckChips.interfaces";
import {
  Transaction,
  TransactionData,
} from "../../../../types/transactions_data";
import { validateVoidTrx } from "../../../shared/utils/dashboard_transaction_utils";
import {
  getRetrieveChildren,
  getRetrieveChildrenMongo,
} from "../../../store/thunks/app/app.thunks";
import { IUserData } from "../../DashboardTransaction/state/useDashboardTransaction.interfaces";
import { getColumnsUser } from "../../../shared/utils/transaction_list_utils";

export const useTransactionList = (
  merchantFilter: IAllFilters
): IUseTransactionList => {
  const dispatch = useAppDispatch();

  const {
    isLoadingTransaction,
    isMongo,
    transactionData,
    nodeInfo,
    merchantInfo,
    retrieveChildren,
  } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));

  const user_profile: IUserData = getColumnsUser();

  const [nodeId, setNodeId] = useState<string>("");
  const [transactionList, setTransactionList] = useState<TransactionData>({
    data: [],
  });
  const [emptyAddText, setEmptyAddText] = useState<string>(
    TransactionEnum.ADD_TRANSACTIONS
  );
  const [entityName, setEntityName] = useState<string>("");

  const validateEntityName = () => {
    if (isEmpty(get(nodeInfo, "entityName", "")))
      setEntityName(get(merchantInfo, "entityName"));
  };

  const handleValidateTrx = (transaction: TransactionData) => {
    if (!isNil(transaction)) {
      if (defaultTo(transaction.data, []).length > 0) {
        const data_aux: TransactionData = cloneDeep(transaction);
        const transactions_aux = data_aux.data!.map((element: Transaction) => {
          return validateVoidTrx(element, defaultTo(retrieveChildren, []));
        });

        setTransactionList({ ...transaction, data: transactions_aux });
      } else setTransactionList({ data: [] });
    }
  };

  useEffect(() => {
    !isEmpty(nodeInfo) && setNodeId(get(nodeInfo, "path", ""));
    setEmptyAddText(TransactionEnum.ADD_TRANSACTIONS);
    setEntityName(get(nodeInfo, "entityName", ""));
  }, [nodeInfo]);

  useEffect(() => validateEntityName(), [merchantInfo]);

  useEffect(() => {
    dispatch(resetTransactionList());
  }, []);

  useEffect(() => {
    if (merchantFilter.itemsList.length === 0 && user_profile.admin) {
      if (defaultTo(get(transactionData, "data", []), []).length > 0) {
        let selected_request: { publicMerchantId: string }[] = [];

        defaultTo(transactionData!.data, []).forEach((item: Transaction) => {
          selected_request.push({
            publicMerchantId: get(item, "_source.merchant_id", ""),
          });
        });

        if (isMongo)
          dispatch(
            getRetrieveChildrenMongo({
              body: [
                {
                  entityName: "",
                  publicMerchantId: "",
                },
              ],
              isTransactionSearch: true,
            })
          );
        else
          dispatch(
            getRetrieveChildren({
              body: selected_request,
              isTransactionSearch: true,
            })
          );
      } else setTransactionList({ data: [] });
    } else handleValidateTrx(transactionData!);
  }, [transactionData]);

  useEffect(() => handleValidateTrx(transactionData!), [retrieveChildren]);

  return {
    emptyAddText,
    entityName,
    isLoadingTransactions: isLoadingTransaction,
    pathCustomer: nodeId,
    showEmptyScreen: isEmpty(get(transactionData, "data", [])),
    transactionList,
  };
};
