export enum FieldsEnum {
  areaCode = "areaCode",
  taxRegime = "taxRegime",
  taxRegimeId = "taxRegimeId",
  zipCode = "zipCode",
  cologne = "cologne",
  department = "department",
  municipality = "municipality",
  province = "province",
  city = "city",
  cityId = "cityId",
  stateCode = "stateCode",
  populationCode = "populationCode",
  address = "address",
  countryCode = "countryCode",
  phoneNumber = "phoneNumber",
  cellphoneNumber = "cellphoneNumber",
  useSameInfoSat = "useSameInfoSat",
  satZipCode = "satZipCode",
  satCologne = "satCologne",
  satStateCode = "satStateCode",
  satPopulationCode = "satPopulationCode",
  satAddress = "satAddress",
  satPhoneNumber = "satPhoneNumber",
  satAreaCode = "satAreaCode",
  obligation = "obligation",
  isPaymentAggregator = "isPaymentAggregator",
  activityId = "activityId",
  economicActivityId = "economicActivityId",
  economicActivitySRI = "economicActivitySRI",
  taxpayerTypeId = "taxpayerTypeId",
  incomeSourceConceptId = "incomeSourceConceptId",
  email = "email",
  name = "name",
  merchantSize = "merchantSize",
  merchantRank = "merchantRank",
  commercialExecutive = "commercialExecutive",
}

export const FIELDS_BILLING: string[] = [
  FieldsEnum.areaCode,
  FieldsEnum.taxRegime,
  FieldsEnum.zipCode,
  FieldsEnum.cologne,
  FieldsEnum.department,
  FieldsEnum.municipality,
  FieldsEnum.province,
  FieldsEnum.city,
  FieldsEnum.stateCode,
  FieldsEnum.populationCode,
  FieldsEnum.address,
  FieldsEnum.countryCode,
  FieldsEnum.phoneNumber,
  FieldsEnum.useSameInfoSat,
  FieldsEnum.satZipCode,
  FieldsEnum.satCologne,
  FieldsEnum.satStateCode,
  FieldsEnum.satPopulationCode,
  FieldsEnum.satAddress,
  FieldsEnum.satPhoneNumber,
  FieldsEnum.satAreaCode,
  FieldsEnum.obligation,
  FieldsEnum.isPaymentAggregator,
  FieldsEnum.activityId,
  FieldsEnum.economicActivityId,
  FieldsEnum.taxpayerTypeId,
  FieldsEnum.incomeSourceConceptId,
];
