import React from "react";
import Shareholders from "../../components/Shareholders/Shareholders";
import { SkeletonForm } from "../../components/SkeletonForm/SkeletonForm";
import { routes } from "../../shared/infrastructure/constants/routes";
import { MerchantPeopleHeader } from "../../components/MerchantPeopleHeader/MerchantPeopleHeader";
import { Box, Button, CircularProgress, Container } from "@mui/material";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { useContainerIndexState } from "./state/useContainerIndexState";
import LegalRepresentatives from "../../components/LegalRepresentatives/LegalRepresentatives";
import { containerIndexStyles } from "./ContainerIndex.styles";
import CompanyInformation from "../../components/CompanyInformation/CompanyInformation";
import { defaultTo } from "lodash";
import { SecurityWrapperEnum } from "../../shared/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export const ContainerIndex: React.FC = () => {
  const {
    form,
    merchantName,
    loading,
    showCompanyInformation,
    showShareholders,
    handleGoBack,
    showForm,
  } = useContainerIndexState();

  return (
    <Container fixed>
      <Breadcrumb
        items={[{ label: "Comercios", url: routes.MERCHANT_LIST }]}
        lastItem={defaultTo(merchantName, "") + " /  Editar datos básicos"}
      />
      <MerchantPeopleHeader title="Editar información de la empresa" />
      {!showForm ? (
        <SkeletonForm />
      ) : (
        <form>
          <Box sx={containerIndexStyles.form}>
            {showCompanyInformation && (
              <CompanyInformation
                control={form.control}
                errors={form.errors}
                setValue={form.setValue}
              />
            )}
            <LegalRepresentatives
              control={form.control}
              errors={form.errors}
              setValue={form.setValue}
              getValues={form.getValues}
            />
            {showShareholders && (
              <Shareholders
                clearErrors={form.clearErrors}
                control={form.control}
                errors={form.errors}
                getValues={form.getValues}
                watch={form.watch}
              />
            )}
          </Box>
          <Box sx={containerIndexStyles.buttons}>
            <Button
              variant={"outlined"}
              size={"medium"}
              sx={containerIndexStyles.button}
              onClick={handleGoBack}
            >
              Salir
            </Button>
            <ComponentSecurityWrapper componentId={SecurityWrapperEnum.SAVE}>
              <Button
                variant={"contained"}
                size={"medium"}
                disableElevation
                sx={containerIndexStyles.button}
                disabled={loading}
                endIcon={loading && <CircularProgress size={16} />}
                onClick={form.handleSubmit}
              >
                Guardar
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </form>
      )}
    </Container>
  );
};
