import { SvgIcon } from "@mui/material";
import React from "react";

const IconCopyPaste: React.FunctionComponent = () => {
  return (
    <SvgIcon
      width={"30"}
      height={"30"}
      viewBox="-3 10 30 30"
      fontSize={"medium"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: "30px", height: "30px" }}
    >
      <path
        d="M12.25 28.5C11.0633 28.5 9.90328 28.1481 8.91658 27.4888C7.92989 26.8295 7.16085 25.8925 6.70673 24.7961C6.2526 23.6997 6.13378 22.4933 6.36529 21.3295C6.5968 20.1656 7.16825 19.0965 8.00736 18.2574C8.84648 17.4182 9.91557 16.8468 11.0795 16.6153C12.2433 16.3838 13.4497 16.5026 14.5461 16.9567C15.6425 17.4109 16.5795 18.1799 17.2388 19.1666C17.8981 20.1533 18.25 21.3133 18.25 22.5C18.25 24.0913 17.6179 25.6174 16.4926 26.7426C15.3674 27.8679 13.8413 28.5 12.25 28.5ZM12.25 18.5C11.4589 18.5 10.6855 18.7346 10.0277 19.1741C9.36993 19.6136 8.85723 20.2384 8.55448 20.9693C8.25173 21.7002 8.17252 22.5044 8.32686 23.2804C8.4812 24.0563 8.86216 24.769 9.42158 25.3284C9.98098 25.8878 10.6937 26.2688 11.4696 26.4231C12.2456 26.5775 13.0498 26.4983 13.7807 26.1955C14.5116 25.8928 15.1364 25.3801 15.5759 24.7223C16.0154 24.0645 16.25 23.2911 16.25 22.5C16.25 21.4391 15.8286 20.4217 15.0784 19.6716C14.3283 18.9214 13.3109 18.5 12.25 18.5Z"
        fill="#6D7781"
      />
      <path
        d="M3.25 36.5C2.98478 36.5 2.73043 36.3946 2.54289 36.2071C2.35536 36.0196 2.25 35.7652 2.25 35.5C2.25 33.1131 3.19821 30.8239 4.88604 29.136C6.57387 27.4482 8.86305 26.5 11.25 26.5H13.25C13.5152 26.5 13.7696 26.6054 13.9571 26.7929C14.1446 26.9804 14.25 27.2348 14.25 27.5C14.25 27.7652 14.1446 28.0196 13.9571 28.2071C13.7696 28.3946 13.5152 28.5 13.25 28.5H11.25C9.39349 28.5 7.61301 29.2375 6.30025 30.5503C4.9875 31.863 4.25 33.6435 4.25 35.5C4.25 35.7652 4.14464 36.0196 3.95711 36.2071C3.76957 36.3946 3.51522 36.5 3.25 36.5Z"
        fill="#6D7781"
      />
      <path
        d="M16.75 39.5C16.4848 39.5 16.2304 39.3946 16.0429 39.2071C15.8554 39.0196 15.75 38.7652 15.75 38.5V30.5C15.75 30.2348 15.8554 29.9804 16.0429 29.7929C16.2304 29.6054 16.4848 29.5 16.75 29.5C17.0152 29.5 17.2696 29.6054 17.4571 29.7929C17.6446 29.9804 17.75 30.2348 17.75 30.5V38.5C17.75 38.7652 17.6446 39.0196 17.4571 39.2071C17.2696 39.3946 17.0152 39.5 16.75 39.5Z"
        fill="#6D7781"
      />
      <path
        d="M20.75 35.5H12.75C12.4848 35.5 12.2304 35.3946 12.0429 35.2071C11.8554 35.0196 11.75 34.7652 11.75 34.5C11.75 34.2348 11.8554 33.9804 12.0429 33.7929C12.2304 33.6054 12.4848 33.5 12.75 33.5H20.75C21.0152 33.5 21.2696 33.6054 21.4571 33.7929C21.6446 33.9804 21.75 34.2348 21.75 34.5C21.75 34.7652 21.6446 35.0196 21.4571 35.2071C21.2696 35.3946 21.0152 35.5 20.75 35.5Z"
        fill="#6D7781"
      />
    </SvgIcon>
  );
};

export default IconCopyPaste;
