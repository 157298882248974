import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { modalFormStyle as styles } from "../../ModalForm/ModalForm.styles";
import CloseIcon from "@mui/icons-material/Close";
import PhysicalForm from "../Generics/PhysicalForm";
import MoralForm from "../Generics/MoralForm";
import FormControl from "../../../FormControl/FormControl";
import InfoBox from "../../InfoBox/InfoBox";
import { useMoralPersonForm } from "./state/useMoralPersonForm";
import PercentageInput from "../../PercentageInput/PercentageInput";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../../../shared/constants/labels/main_containter_labels";
import { PersonTypeEnum } from "../../../../shared/enum/personTypeEnum";
import { orderBy } from "lodash";

const MoralPersonForm: React.FC = () => {
  const {
    availablePercentage,
    values: {
      country,
      countryWatch,
      listDocTypesByCountry,
      merchantAdressWatch,
      personTypeWatch,
      rulesDocumentNumber,
      shareHolderWatch,
      constitutionalCountry,
    },
    form: { handleSubmit, control, onSubmit },
    closeModal,
    legalRepresentative,
    generalPercentage,
    isPossibleAddShareholder,
    isEdited,
  } = useMoralPersonForm();

  function getHeaderTitle() {
    if (isEdited.value) {
      const legalRepresentatives = orderBy(
        legalRepresentative,
        ["isMain"],
        ["desc"]
      );

      return legalRepresentatives[0].id === isEdited.id
        ? " Principal"
        : " Alterno";
    }

    return legalRepresentative.length > 0 ? " Alterno" : " Principal";
  }

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant={"h3"}>
            {isEdited.value ? "Editar" : "Agregar "} representante legal -
            {getHeaderTitle()}
          </Typography>
          <IconButton color="secondary" onClick={closeModal} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container rowSpacing={2} columnSpacing={1}>
            {legalRepresentative.length === 0 && <InfoBox />}
            <Grid item xs={12}>
              <Typography variant={"body2"} color={"text.dark"}>
                Tipo de persona
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                control={control}
                inputComponent="radio"
                label=""
                name="personType"
                country={constitutionalCountry}
              />
            </Grid>
          </Grid>
          {personTypeWatch === PersonTypeEnum.PHYSICAL ? (
            <PhysicalForm
              control={control}
              rules={rulesDocumentNumber}
              country={countryWatch}
              options={listDocTypesByCountry}
              constitutionalCountry={country}
            />
          ) : (
            <MoralForm
              control={control}
              country={merchantAdressWatch}
              constitutionalCountry={country}
            />
          )}
          {(parseInt(generalPercentage) < 100 && isPossibleAddShareholder) ||
          isEdited.value ? (
            <>
              <Grid item xs={12}>
                <FormControl
                  control={control}
                  inputComponent="checkbox"
                  label="El representate legal también es un accionista."
                  name="isShareHolder"
                />
              </Grid>
              {shareHolderWatch === true && (
                <Grid>
                  <PercentageInput
                    control={control}
                    name={"participationPercentage"}
                    label={"Porcentaje de participación"}
                    percentage={availablePercentage.toString()}
                  />
                </Grid>
              )}
            </>
          ) : null}
        </DialogContent>
      </Box>
      <DialogActions sx={styles.footer}>
        <Button onClick={closeModal}>Cancelar</Button>
        <ComponentSecurityWrapper
          componentId={
            ComponentIdsEnum.M_CLIENTS_ADD_LEGAL_REPRESENTATIVE_MODAL_BUTTON
          }
        >
          <Button
            data-testid={"ButtonSubmit"}
            variant={"contained"}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdited.value ? "Guardar" : "Agregar"}
          </Button>
        </ComponentSecurityWrapper>
      </DialogActions>
    </Box>
  );
};

export default MoralPersonForm;
