import React from "react";
import { MenuItem, TextField } from "@mui/material";
import {
  IInputSelectProps,
  ISelectOption,
} from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { inputSelectStyles as styles } from "@components/InputsForm/Inputs/InputSelect/InputSelect.styles";
const InputSelect: React.FC<IInputSelectProps> = ({
  field,
  options,
  label,
  error,
}: IInputSelectProps) => {
  return (
    <TextField
      {...field}
      select
      value={field.value}
      helperText={error ? error.message : ""}
      error={!!error}
      label={label}
      onChange={(e) => {
        field.onChange(e.target.value);
      }}
      sx={styles.input}
    >
      {options.map((opt: ISelectOption) => {
        return (
          <MenuItem key={opt.label} value={opt.value} disabled={opt.isDisabled}>
            {opt.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default InputSelect;
