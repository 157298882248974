import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const addWebhookFormContainerStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "64px 64px 128px 64px",
    rowGap: (theme) => theme.spacing(5),
  },
  containerFooter: {
    bottom: 0,
    position: "fixed",
    width: "-webkit-fill-available",
  },
});
