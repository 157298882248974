import { appSlice } from "../reducers/app/app";

export const {
  hideLoading,
  setLoadingText,
  showLoading,
  hideAlert,
  setMessageAlert,
  setTypeAlert,
  showAlert,
} = appSlice.actions;
