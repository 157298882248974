import { dispersionSlice } from "../../reducers/dispersion/dispersion.slice";

export const {
  updateFrequencyAndFraudForm,
  addBankAccount,
  editBankAccount,
  updateBankAccountIndexOnFocus,
  editBeneficiaryName,
  updateConstitutionalCountry,
  disableContinueButton,
  disableSaveButton,
  setIsLoadingDispersionInformation,
  updateLoadingForm,
} = dispersionSlice.actions;
