import React from "react";
import { GridPaper } from "../../common/GridPaper/GridPaper";
import {
  Chip,
  createStyles,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Edit } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import {
  configType,
  navigateRoute,
  StatusSemaphore,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { DiscountResponse } from "../../../../types/discount_response";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { format } from "date-fns";
import { StatusSteps } from "../../../../types/remote/semaphore_data";

export interface DiscountResumeProps {
  discount: DiscountResponse;
  publicMerchantId: string;
  status: StatusSteps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      backgroundColor: "rgba(240, 244, 249, 1)",
      color: theme.palette.text.primary,
      borderRadius: "5px",
    },
    button: {
      backgroundColor: "rgba(240, 244, 249, 1)",
      marginLeft: theme.spacing(1),
      borderRadius: "5px",
      color: theme.palette.text.primary,
    },
  })
);

export const DiscountResume: React.FC<Partial<DiscountResumeProps>> = (
  _: Partial<DiscountResumeProps>
) => {
  const classes = useStyles();
  const {
    discount,
    publicMerchantId,
    status,
  }: Partial<DiscountResumeProps> = useSelector((state: IAppState) => ({
    discount: state.discountInfo,
    publicMerchantId: get(state, "merchantAffiliation.publicMerchantId", ""),
    status: get(
      state,
      "semaphore.stepConfigRatesAndInvoice.statusDiscount",
      ""
    ),
  }));

  const shouldDisplayResume = (status: StatusSteps) =>
    [StatusSemaphore.inProcess, StatusSemaphore.complete].includes(
      get(StatusSemaphore, status, StatusSemaphore.pending)
    );

  if (!shouldDisplayResume(status!)) return null;

  const DATE_FORMAT = "dd/MM/yyyy";

  const CustomLabel = ({ label }: Record<string, any>) => (
    <Grid item xs={3}>
      <Typography variant={"body2"} color={"textSecondary"}>
        {label}
      </Typography>
    </Grid>
  );

  const CustomChip = ({ label }: Record<string, any>) => (
    <Grid item xs={9}>
      <Chip label={label} className={classes.chip} size={"small"} />
    </Grid>
  );

  const CustomText = ({ text }: Record<string, any>) => (
    <Grid item xs={9}>
      <Typography variant={"body2"} color={"textPrimary"}>
        {text}
      </Typography>
    </Grid>
  );

  const redirectConfiguration = () => {
    if (publicMerchantId)
      navigateRoute(
        `/rate/${publicMerchantId}?configType=${configType.configDiscount}&hideSideBar=true`
      );
  };

  const formatDate: (date?: number) => string = (date?: number) => {
    if (!date) return "";

    return format(date, DATE_FORMAT);
  };

  return (
    <GridPaper direction={"row"}>
      <Grid item xs={12}>
        <Typography
          variant={"subtitle1"}
          color={"textPrimary"}
          display={"inline"}
        >
          Descuentos
        </Typography>
        <Tooltip title={"Editar"}>
          <IconButton
            onClick={redirectConfiguration}
            size={"small"}
            className={classes.button}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      <CustomLabel label={"Porcentaje:"} />
      <CustomChip label={`${get(discount, "discount", "")}%`} />
      <CustomLabel label={"Inicio:"} />
      <CustomText text={formatDate(get(discount, "start"))} />
      <CustomLabel label={"Fin:"} />
      <CustomText text={formatDate(get(discount, "end"))} />
    </GridPaper>
  );
};
