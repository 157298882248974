import React, { FC } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { TableNodesStyle } from "./TableNodes.styles";
import {
  TABLE_HEAD_DISABLE_SX,
  TableHeadStyles,
} from "../TableHead/TableHead.style";
import { TableBodyStyles } from "../TableBody/TableBody.style";
import { TableFooterStyles } from "../TableFooter/TableFooter.style";
import { CELL_COMPONENTS_BY_TABLE_ENUM, TableBodyCellEnum } from "./constants";
import {
  EnhancedTableProps,
  ITableCellProps,
  ITableRowProps,
} from "./interfaces";
import { get, set } from "lodash";
import { useTableNodes } from "./state/useTableNodes";
import { ITableNodesProps } from "./TableNodes.interfaces";
import EmptyResults from "../../EmptyResults/EmptyResults";
import { TableMessageEnum } from "../../../shared/enums/DashboardEnum";
import { TableSkeleton } from "../../TableSkeleton/TableSkeleton";

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    onSelectAllClick,
    order,
    numSelected,
    rowCount,
    isBranchTab,
  } = props;

  return (
    <TableHead sx={TABLE_HEAD_DISABLE_SX[`${!!false}`]}>
      <TableRow sx={TableHeadStyles.row}>
        {!isBranchTab && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            sx={TableHeadStyles.label}
            key={headCell.id}
            align={headCell.align ? headCell.align : "center"}
            sortDirection={order}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const TableNodes: FC<ITableNodesProps> = ({
  handleSelectedRows,
  headTable,
  labelRowsPerPage,
  rows,
  paginationProps: {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    totalData,
  },
  setNodeDataSelected,
  resetItemChecked,
  isBranchTab,
}: ITableNodesProps) => {
  const {
    handleGetRowByMenuAction,
    headerProps: { order, orderBy },
    rowsProps: {
      check: { handleClick, handleSelectAllClick, isSelected, selected },
    },
    totalPages,
    isLoadingMerchants,
  } = useTableNodes({
    handleSelectedRows,
    resetItemChecked,
    rows,
    rowsPerPage,
    setNodeDataSelected,
    totalData,
  });

  return (
    <>
      {isLoadingMerchants ? (
        <TableSkeleton headTable={headTable} rowsPerPage={rowsPerPage} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <TableContainer>
            <Box>
              <Table sx={TableNodesStyle.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  headCells={headTable}
                  sx={TableHeadStyles}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                  isBranchTab={isBranchTab}
                />
                {totalData > 0 && (
                  <TableBody sx={TableBodyStyles.body}>
                    {rows.map((row: ITableRowProps, index: number) => {
                      const isItemSelected = isSelected(row.id);

                      return (
                        <TableRow
                          aria-label={`table-row-${row.id}`}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          sx={TableBodyStyles.row}
                          selected={isItemSelected}
                        >
                          {row.cells.map(
                            (
                              { type, props }: ITableCellProps,
                              cellIndex: number
                            ) => {
                              const CellComponent =
                                CELL_COMPONENTS_BY_TABLE_ENUM[type];

                              if (type === TableBodyCellEnum.CHECK) {
                                set(props, "isChecked", isItemSelected);
                                set(props, "handleClick", handleClick);
                                set(props, "id", row.id);
                              }

                              if (type === TableBodyCellEnum.ACTION_MENU) {
                                set(props, "rowId", row.id);
                                set(
                                  props,
                                  "handleGetRowByMenuAction",
                                  handleGetRowByMenuAction
                                );
                              }

                              return (
                                <TableCell
                                  key={`row-${index}-cell-${cellIndex}`}
                                  scope="row"
                                  padding="none"
                                  align={get(props, "align", "center")}
                                >
                                  <CellComponent
                                    {...props}
                                    key={`row-${index}-cell-${cellIndex}-component-${type}`}
                                  />
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </TableContainer>
          {totalData > 0 ? (
            <Grid
              sx={TableNodesStyle.tableFooter}
              marginTop={-2}
              container
              spacing={1}
            >
              <Grid display="flex" justifyContent="left" item xs={6}>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={totalData}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={labelRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  align="left"
                />
              </Grid>
              <Grid display="flex" justifyContent="right" item xs={6}>
                <Pagination
                  sx={TableFooterStyles.pagination}
                  count={totalPages}
                  page={page + 1}
                  onChange={(event: unknown, newPage: number) => {
                    handleChangePage(event, newPage - 1);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <EmptyResults
              addText={TableMessageEnum.EMPTY_DATA_BODY}
              title={TableMessageEnum.EMPTY_DATA_TITLE}
            />
          )}
        </Box>
      )}
    </>
  );
};
