import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";

const useStyle = makeStyles({
  title: {
    fontWeight: "bold",
  },
});

interface TooltipProps {
  open: boolean;
  handleClose: () => void;
  banks: string[];
}

export const ModalPartnerBanks: React.FC<TooltipProps> = (
  props: TooltipProps
) => {
  const classes = useStyle();
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={1}
        >
          <Typography variant={"subtitle1"} className={classes.title}>
            Bancos asociados
          </Typography>
          <X color={"#46525C"} size={24} onClick={props.handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.banks.map((data, index) => (
          <Typography key={index} variant={"subtitle1"}>
            {data}
          </Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
};
export default ModalPartnerBanks;
