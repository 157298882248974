import React, { PropsWithChildren } from "react";
import { Grid, Box } from "@material-ui/core";
import { processedDispersionsHeaderStyle } from "./ProcessedDispersionsHeader.style";
import { ProcessedDispersionsHeaderProps } from "../../shared/infrastructure/interfaces/ProcessedDispersionsHeader.interface";

export const ProcessedDispersionsHeader = (
  props: PropsWithChildren<ProcessedDispersionsHeaderProps>
) => {
  const classes = processedDispersionsHeaderStyle();

  return (
    <Grid
      container
      direction={"row"}
      className={classes.mainGrid}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid item xs={1}>
        {props.filterComponent}
      </Grid>
      <Grid item xs={4} className={classes.picker}>
        {props.pickerComponent}
      </Grid>
      <Grid item xs={5}>
        <Box className={classes.searchBox}>{props.searchComponent}</Box>
      </Grid>
    </Grid>
  );
};
