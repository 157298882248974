export const CONTINUE_TEXT = "Continuar";
export const CANCEL_TEXT = "Cancelar";
export const YES_CONTINUE_TEXT = "Sí, finalizar";

export const MODAL_CONFIRM = {
  TITLE_MODAL_CONFIRM: (total: string) =>
    `Se crearán ${total} branch(es), ¿deseas continuar?`,
  TOTAL_BRANCH: (total: string) => `Total de Branches: ${total}`,
};

export const MODAL_EDIT_CONFIRM = {
  TITLE_MODAL_CONFIRM: (total: string) =>
    `Se guardará la información para ${total} branches, ¿Deseas finalizar?`,
  TOTAL_BRANCH: (total: string) => `Total de Branches: ${total}`,
};

export const MODAL_ACTIVE_FILTER = {
  DESCRIPTION: "Te recomendamos revisar todos los branches antes de continuar",
  TITLE: "Tienes filtros activos, ¿Estás seguro de continuar?",
};

export const MODAL_VALIDATE = {
  TITLE_MODAL_VALIDATE: "Validación del archivo",
  TOTAL_BRANCH: (total: string) => `Total de Branches: ${total}`,
};
export const DESCRIPTION_MODAL_CONFIRM =
  "Ten en cuenta que en cada uno de los branches descentralizados deberás modificar su información.";
export const DESCRIPTION_EDIT_MODAL_CONFIRM =
  "Al ejecutar esta acción, se guardara la edición realizada y los branches incompletos permaneceran inactivos y deberas completarlos desde el listado de branches";
export const DESCRIPTION_MODAL_VALIDATE =
  "Existen errores en el archivo. Los branches con errores no se crearán, sin embargo puedes continuar.";

export const ADD_BRANCH = "Agregar Branch";
export const EDIT_BRANCH = "Editar información";
