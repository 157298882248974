import { MouseEvent, useContext, useState } from "react";
import { ItemMerchantProps } from "../MenuOption.interfaces";
import { menuOptionsStyles } from "../MenuOption.styles";
import { IUseMenuOptionsState } from "./useMenuOptionsState.interfaces";
import { Conciliation } from "../../../../types/get_conciliation_transactions_response";
import { ItemTitleEnum } from "../../../shared/infrastructure/enums/ItemTitleEnum";
import {
  SecurityContext,
  VerifyIfComponentEnable,
} from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../../shared/constants/security_labels";
import {
  ALLOWED_FRANCHISES,
  FranchiseEnum,
} from "../../../shared/enums/franchise_enum";
import { ProcessorsEnum } from "../../../shared/enums/processors_enum";
import { TransactionStatus } from "../../../shared/infrastructure/constants/TransactionStatus";
import { get } from "lodash";

const useMenuOptionsState = (): IUseMenuOptionsState => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const securityContext = useContext(SecurityContext);
  const isDischargeEnabled: boolean = VerifyIfComponentEnable(
    ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_DISCHARGE,
    securityContext
  );
  const isReviewEnabled: boolean = VerifyIfComponentEnable(
    ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_REVIEW,
    securityContext
  );
  const isRefundEnabled: boolean = VerifyIfComponentEnable(
    ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_REFUND,
    securityContext
  );
  const isOmitEnabled: boolean = VerifyIfComponentEnable(
    ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_OMIT,
    securityContext
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (item: ItemMerchantProps) => {
    handleClose();
    item.handleClick();
  };

  const handleLiquidateValidation = (): boolean => {
    const disabled: boolean = true;

    return !isDischargeEnabled || disabled;
  };

  const handleReviewValidation = (): boolean => {
    return !isReviewEnabled;
  };

  const handleOmittedValidation = (): boolean => {
    return !isOmitEnabled;
  };

  const handleRefundValidation = (trx: Conciliation): boolean => {
    const isDiffCode10: boolean = trx.inconsistencyCode !== "10";

    const isNotAllowedFranchises: boolean = !ALLOWED_FRANCHISES.includes(
      get(trx, "franchise", "") as FranchiseEnum
    );

    const isNotStatusDeclinedAndApproval: boolean =
      trx.transactionStatusKushki !== TransactionStatus.DECLINED &&
      trx.transactionStatusProcessor !== TransactionStatus.APPROVAL;

    const isDiffKushkiAcquirer: boolean =
      trx.processorName !== ProcessorsEnum.KUSHKI_ACQUIRER;

    return (
      !isRefundEnabled ||
      isDiffCode10 ||
      isNotAllowedFranchises ||
      isNotStatusDeclinedAndApproval ||
      isDiffKushkiAcquirer
    );
  };

  const handleDisabled = (
    item: ItemMerchantProps,
    transaction: Conciliation
  ) => {
    const validationByTitle: object = {
      [ItemTitleEnum.LIQUIDATE]: handleLiquidateValidation(),
      [ItemTitleEnum.REFUND]: handleRefundValidation(transaction),
      [ItemTitleEnum.REVIEW]: handleReviewValidation(),
      [ItemTitleEnum.OMITTED]: handleOmittedValidation(),
    };

    return validationByTitle[item.title];
  };

  return {
    anchorEl,
    handleClick,
    handleClickItem,
    handleClose,
    handleDisabled,
    menuOptionsStyles,
    open,
  };
};

export { useMenuOptionsState };
