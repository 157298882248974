import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { ISearchInputProps } from "@components/SearchInput/SearchInput.interfaces";
import { IconSearch } from "@kushki/connect-ui";
import { searchInputStyles as styles } from "@components/SearchInput/SearchInput.styles";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
const SearchInput: React.FC<ISearchInputProps> = ({
  label,
  searchValue,
  handleSubmitSearchInput,
  handleChangeSearchValue,
}: ISearchInputProps) => {
  return (
    <Box component={"form"} onSubmit={handleSubmitSearchInput} sx={styles.form}>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperRoles.M_COMPLIANCE_SEARCH}
      >
        <TextField
          sx={styles.input}
          value={searchValue}
          placeholder={label}
          onChange={handleChangeSearchValue}
          InputProps={{
            endAdornment: (
              <IconButton type={"submit"}>
                <IconSearch color={"primary"} />
              </IconButton>
            ),
          }}
        />
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default SearchInput;
