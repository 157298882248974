import React, { FC } from "react";
import { mainSubHeaderStyles as styles } from "./Subheader.styles";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { getCountryFlag } from "../../shared/constants/flags/country_flag";
import { ISubheaderProps } from "./Subheader.interfaces";
import { isEmpty } from "lodash";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { SubheaderSkeleton } from "../SubheaderSkeleton/SubheaderSkeleton";
import { CATALOG_COUNTRY_TEXT_VALUE } from "../../shared/catalogs/CatalogCountryTextValue";
import { CountriesEnum } from "../../shared/enums/countries";
import { SUBHEADER_LABELS } from "../../shared/constants/labels/subheader_labels";

export const Subheader: FC<ISubheaderProps> = ({
  title,
  country,
  id,
}: ISubheaderProps) => {
  return (
    <Box>
      {!isEmpty(title) ? (
        <>
          <Grid container alignItems="center" sx={styles.gridTitle}>
            <Grid item>
              <Typography color="text.primary" variant="h3">
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Grid item sx={styles.gridText}>
                <Typography variant="caption" color="text.primary">
                  {SUBHEADER_LABELS.operationCountry}
                </Typography>
              </Grid>
              <Grid item>
                <Box sx={styles.flagContainer}>{getCountryFlag[country]}</Box>
              </Grid>
              <Grid item sx={styles.gridCountry}>
                <Typography variant="body1" color="text.primary">
                  {CATALOG_COUNTRY_TEXT_VALUE[country as CountriesEnum]}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={styles.divider}
              />
              <Grid item sx={styles.gridId}>
                <Typography variant="caption" color="text.primary">
                  {SUBHEADER_LABELS.id}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="text.primary">
                  {id}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  sx={styles.iconEffect}
                  data-testid={"IconCopy"}
                  onClick={(e) => {
                    clipboard(id);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Copy />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <SubheaderSkeleton />
      )}
    </Box>
  );
};

export default Subheader;
