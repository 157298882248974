import React from "react";
import {
  Grid,
  Typography,
  Theme,
  createStyles,
  Paper,
  Divider,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { SearchResponse } from "../../../../types/search_response";
import { get } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "120%",
    },

    linkContainer: {
      marginTop: theme.spacing(4),
    },
    link: {
      textDecoration: "underline",
      fontFamily: "IBM Plex Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "170%",
    },
    text: {
      position: "static",
      marginRight: theme.spacing(10),
      fontFamily: "IBM Plex Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "140%",
      marginTop: theme.spacing(2),
    },
    textSub: {
      position: "static",
      marginRight: theme.spacing(12),
      fontFamily: "IBM Plex Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "140%",
    },
  })
);

export const MerchantSectionRetries = ({
  retries,
  retriesURL,
}: {
  retries: SearchResponse;
  retriesURL: string;
}) => {
  const history = useHistory();

  const classes = useStyles();
  return (
    <Grid component={Paper} className={classes.paper} elevation={0}>
      <Typography className={classes.title} variant={"h5"}>
        Reglas de reintento agregadas
      </Typography>
      <Divider className={classes.divider} />
      <Grid>
        <Typography className={classes.text} variant={"caption"}>
          Tipo de regla
        </Typography>
        <Typography className={classes.text} variant={"caption"}>
          Reglas de denegación
        </Typography>
      </Grid>
      <Grid>
        <Typography className={classes.textSub} variant={"caption"}>
          Comercio
        </Typography>
        <Typography className={classes.textSub} variant={"caption"}>
          {get(retries, "total.value")}
        </Typography>
      </Grid>
      <Grid className={classes.linkContainer}>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => history.push(retriesURL)}
          color="inherit"
          className={classes.link}
        >
          Edita y revisa todas las reglas de reintentos
        </Link>
      </Grid>
    </Grid>
  );
};
