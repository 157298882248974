import React, { FC } from "react";
import { useTransactionList } from "./state/useTransactionList";
import { Box } from "@mui/material";
import DashboardTransaction from "../DashboardTransaction/DashboardTransaction";
import { ITransactionList } from "./TransactionList.interfaces";

const TransactionList: FC<ITransactionList> = ({
  mechantFilter,
}: ITransactionList) => {
  const {
    showEmptyScreen,
    emptyAddText,
    transactionList,
    entityName,
    pathCustomer,
  } = useTransactionList(mechantFilter);

  return (
    <Box width={"100%"}>
      <DashboardTransaction
        showEmptyScreen={showEmptyScreen}
        emptyAddText={emptyAddText}
        transactionData={transactionList}
        basicNodeInfo={{
          entityName,
          path: pathCustomer,
        }}
        isBranchTab={false}
        mechantFilter={mechantFilter}
      />
    </Box>
  );
};

export default TransactionList;
