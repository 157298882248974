import React, { useState } from "react";
import { Grid } from "@mui/material";
import { TextFieldCard } from "@kushki/connect-ui";
import { PlaceHolders } from "../../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { FabButtonsContainer } from "../../FabButtonsContainer/FabButtonsContainer";

export interface IMaskedCardFieldProps {
  value: string;
  onDelete: () => void;
  onEdit: (newValue: string) => void;
  isDisabled: boolean;
  isMaskedCard?: boolean;
}

const MaskedCardField = (props: IMaskedCardFieldProps) => {
  const [editCard, setEditCard] = useState<boolean>(false);
  const { isMaskedCard, value, isDisabled, onEdit, onDelete } = props;

  return (
    <Grid container direction={"row"} sx={{ mt: 1 }} spacing={1}>
      <Grid item xs={4} sx={{ mb: 2 }}>
        <TextFieldCard
          maskedCard="middle"
          value={value}
          isDisabled={!editCard}
          onBlur={(cardNumber) => {
            setEditCard(false);
            onEdit(cardNumber);
          }}
          placeholder={PlaceHolders.NUMBER}
          enableMaskedInput={isMaskedCard}
        />
      </Grid>
      {!isDisabled && (
        <FabButtonsContainer
          handleEditFabButton={() => {
            setEditCard(true);
          }}
          handleDeleteFabButton={onDelete}
        />
      )}
    </Grid>
  );
};

export default MaskedCardField;
