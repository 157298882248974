import { defaultTo, isEmpty, set, get, omit } from "lodash";
import { MerchantInfo } from "../../../../types/merchant_info";
import { useParams } from "react-router-dom";
import {
  SubmitHandler,
  useForm,
  UseFormMethods,
  useWatch,
} from "react-hook-form";
import { IBusinessRulesForm } from "../../../shared/infrastructure/interfaces/IBusinessRulesForm";
import { BusinessRulesEditProps } from "../BusinessRulesEdit";
import {
  RulesNotificationResultEnum,
  getMerchantInfo,
  getRulesListInfo,
} from "../../../shared/infrastructure/constants/RulesConstants";
import { useEffect, useState } from "react";
import { BusinessRulesRequest } from "../../../../types/business_rules_request";
import { BUSINESS_RULES_MAP_TARGETS } from "../../../shared/infrastructure/constants/BusinessRulesEnum";
import { SecurityRule } from "../../../../types/security_rule";

export interface EditBusinessRulesState {
  form: UseFormMethods<IBusinessRulesForm>;
  merchantInfo: MerchantInfo;
  actions: {
    handleSubmitForm: SubmitHandler<IBusinessRulesForm>;
    handleCancelAction: () => void;
    handleCloseNotification: () => void;
    handleAddRule: (
      rule: string,
      condition: string,
      value: string,
      rules: object
    ) => void;
    handleRemoveRule: (rule: string, rules: object) => void;
    handleCloseFinalModal: (url: string) => void;
    handleSetSearchTextProcessors: (text: string) => void;
    handleEnterSearchTextProcessors: (key: string) => void;
  };
}

const checkAdmin = () => {
  const roles: object = JSON.parse(
    defaultTo(localStorage.getItem("roles"), "{}")
  );

  if (get(roles, "BackofficeAdmin") !== true)
    window.location.href = "/dashboard";
};

const buildRulesEditMode = (rules: object, targets: string[]): object => {
  const newRules: object = {};
  targets.forEach((key: string) => {
    if (!isEmpty(get(rules, key))) set(newRules, key, rules[key]);
  });

  return newRules;
};
export const useEditBusinessRulesState = (
  props: BusinessRulesEditProps
): EditBusinessRulesState => {
  const { merchantId, ruleId } = useParams();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [searchTextProcessor, setSearchTextProcessor] = useState<string>("");

  const form = useForm<IBusinessRulesForm>({
    mode: "all",
    defaultValues: {},
  });

  const ruleSelectValue: string = useWatch({
    control: form.control,
    name: "ruleSelectValue",
    defaultValue: "",
  });

  const action: string = useWatch({
    control: form.control,
    name: "action",
    defaultValue: "",
  });

  const merchantInfo: MerchantInfo = defaultTo(
    JSON.parse(`${window.localStorage.getItem("merchantBasicInformation")}`),
    {}
  );

  useEffect(() => {
    if (merchantId) {
      checkAdmin();
      if (!isEmpty(ruleId)) {
        setEditMode(true);
        props.getBusinessRule(ruleId);
      }
    }
  }, []);

  useEffect(() => {
    if (ruleSelectValue === "transactionProcessor" && isEmpty(props.processors))
      props.getProcessors(merchantId);
    if (
      ruleSelectValue === "transactionCredential" &&
      isEmpty(props.credentials)
    )
      props.getCredentials(merchantId);
  }, [ruleSelectValue]);

  useEffect(() => {
    if (action === "route" && isEmpty(props.processors))
      props.getProcessors(merchantId);
  }, [action]);

  useEffect(() => {
    if (get(props, "businessRule.action", "") == "route")
      props.getProcessors(merchantId);

    if (get(props, "businessRule.id", "") !== "") {
      form.reset({
        ...props.businessRule,
        rules: {
          ...buildRulesEditMode(
            get(props, "businessRule", {}),
            BUSINESS_RULES_MAP_TARGETS
          ),
        },
      });
    }
  }, [props.businessRule]);

  const handleCloseFinalModal = (url: string): void => {
    window.location.href = url;
  };

  const handleCancelAction = (): void => {
    const redirectUrl: string = defaultTo(
      get(
        getMerchantInfo(merchantId, merchantInfo.name)[
          `${merchantInfo.origin}`
        ],
        "redirectPath"
      ),
      get(getRulesListInfo(merchantId, merchantInfo.name), "redirectPath")
    );
    window.location.href = redirectUrl;
  };

  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.NO_ACTION,
    });
  };
  const handleSubmitForm: SubmitHandler<IBusinessRulesForm> = async (
    formData
  ) => {
    const requestRule: BusinessRulesRequest = {
      ...formData,
      ...formData.rules,
      kind: "strict",
    };

    if (get(requestRule, "cardType", "")) {
      requestRule.transactionIsCreditCard = requestRule.cardType;
      delete requestRule.cardType;
      delete requestRule.rules.cardType;

      const type =
        get(requestRule, "transactionIsCreditCard", "")[0] === "=" ? 2 : 3;

      const whichReplace = get(
        requestRule,
        "transactionIsCreditCard",
        ""
      ).substr(type);

      const replacer = whichReplace === "Credit" ? "true" : "false";

      requestRule.transactionIsCreditCard =
        requestRule.transactionIsCreditCard?.replace(whichReplace, replacer);

      requestRule.rules.transactionIsCreditCard =
        requestRule.transactionIsCreditCard;
    }

    const omittedPathsRequest: BusinessRulesRequest = omit(requestRule, [
      "ruleSelectValue",
      "ruleSelectConditionValue",
      "rulesInputValue",
      "rules",
    ]) as BusinessRulesRequest;
    const businessRule: SecurityRule = {
      merchantId,
      merchantName: get(
        merchantInfo,
        "name",
        get(merchantInfo, "merchantName", "")
      ),
      service: "card",
      rule: omittedPathsRequest,
      type: "business",
    };

    const redirectUrl: string = defaultTo(
      get(
        getMerchantInfo(merchantId, merchantInfo.name)[
          `${merchantInfo.origin}`
        ],
        "redirectPath"
      ),
      get(getRulesListInfo(merchantId, merchantInfo.name), "redirectPath")
    );

    set(
      omittedPathsRequest,
      "transactionBank",
      get(omittedPathsRequest, "transactionBank", "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );

    if (editMode) {
      props.updateBusinessRule(ruleId, businessRule, merchantId);
    } else {
      props.createBusinessRule(businessRule, merchantInfo, redirectUrl);
    }
  };
  const handleAddRule = (
    rule: string,
    condition: string,
    value: string,
    rules: object
  ): void => {
    let putRule: boolean = true;

    if (rule === "transactionIsCreditCard") {
      rule = "cardType";

      switch (condition) {
        case "!=":
          value = value === "false" ? "Debit" : "Credit";
          break;
        case "=":
          value = value === "false" ? "Debit" : "Credit";
          break;
      }
    }

    if (isEmpty(rule)) {
      putRule = false;
      form.setError("ruleSelectValue", { type: "required" });
    }
    if (isEmpty(condition)) {
      putRule = false;
      form.setError("ruleSelectConditionValue", { type: "required" });
    }
    if (isEmpty(value)) {
      putRule = false;
      form.setError("rulesInputValue", { type: "required" });
    }
    if (putRule) {
      const ruleObject: object = defaultTo(rules, {});
      const valueKey: string = `${condition}|${value}`;
      const actuallyValue: string = get(rules, rule, "");
      if (isEmpty(actuallyValue) || valueKey !== actuallyValue) {
        set(ruleObject, rule, `${condition}|${value}`);
        form.setValue("rules", ruleObject);
        form.setValue("ruleSelectValue", "");
        form.setValue("ruleSelectConditionValue", "");
        form.setValue("rulesInputValue", "");
      }
    }
  };

  const handleRemoveRule = (rule: string, rules: object): void => {
    const newRules: object = omit(rules, rule);
    if (isEmpty(newRules)) form.setValue("rules", undefined);
    else form.setValue("rules", newRules);
  };

  const handleSetSearchTextProcessors = (searchText: string): void => {
    setSearchTextProcessor(searchText);
  };

  const handleEnterSearchTextProcessors = (key: string): void => {
    if (key !== "Enter") return;
    props.getProcessors(merchantId, searchTextProcessor);
    setSearchTextProcessor("");
  };

  return {
    form,
    merchantInfo,
    actions: {
      handleCancelAction,
      handleCloseNotification,
      handleSubmitForm,
      handleAddRule,
      handleRemoveRule,
      handleCloseFinalModal,
      handleSetSearchTextProcessors,
      handleEnterSearchTextProcessors,
    },
  };
};
