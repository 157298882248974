import React from "react";
import Link from "@mui/material/Link";
import { Link as LinkRouter } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IBreadcrumbsProps } from "@components/Breadcrumbs/Breadcrumbs.interfaces";
import { breadcrumbsStyles as styles } from "./Breadcrumbs.styles";
import { Box } from "@mui/material";
import { BreadcrumbPathEnum } from "@shared/enum/breadcrumbPathEnum";
const BreadcrumbsComponent: React.FC<IBreadcrumbsProps> = ({
  items,
}: IBreadcrumbsProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={styles.breadcrumb}>
      <Link
        underline={"none"}
        href={BreadcrumbPathEnum.DASHBOARD}
        sx={styles.label}
      >
        Inicio
      </Link>

      {items.map((item, index) => {
        const current = items.length - 1 === index;

        return (
          <LinkRouter key={index} to={item.path}>
            <Box sx={current ? styles.current : styles.label}>{item.label}</Box>
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
