import SimpleChip from "../commons/SimpleChip/SimpleChip";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  bankListMobile: {
    background: "#F7FAFC",
    color: "#293036",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "100%",
    margin: "6px",
    overflow: "hidden",
    padding: "6px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
  labelH2: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "16px",
    paddingTop: "20px",
  },
  link: {
    color: "#023365",
  },
}));

export interface ConfigListBankProps {
  bankList: string[];
  isMobile: boolean;
  handleBankModal: (banks: string[]) => void;
}

export const ConfigListBank: React.FC<ConfigListBankProps> = (
  props: ConfigListBankProps
) => {
  const classes = useStyles();

  return (
    <>
      {props.bankList.length > 0 &&
        (!props.isMobile ? (
          <Box pt={"27px"} className={classes.labelH2}>
            Red de Bancos:
            {props.bankList
              .sort()
              .map(
                (bank: string, index: number) =>
                  index < 10 && <SimpleChip key={index} label={bank} />
              )}
            {props.bankList.length > 10 && (
              <Link
                className={classes.link}
                component="button"
                underline={"always"}
                onClick={() => {
                  props.handleBankModal(props.bankList!);
                }}
              >
                Ver más
              </Link>
            )}
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12} className={classes.labelH2}>
              Red de Bancos:
              {props.bankList
                .sort()
                .map(
                  (bank: string, index: number) =>
                    index < 5 && (
                      <Typography className={classes.bankListMobile}>
                        {bank}
                      </Typography>
                    )
                )}
              {props.bankList.length > 5 && (
                <Link
                  className={classes.link}
                  component="button"
                  underline={"always"}
                  onClick={() => {
                    props.handleBankModal(props.bankList!);
                  }}
                >
                  Ver más
                </Link>
              )}
            </Grid>
          </Grid>
        ))}
    </>
  );
};
