export enum CurrencyNameEnum {
  COP = "Peso colombiano",
  USD = "Dólar estadounidense",
  PEN = "Sol",
  CLP = "Peso chileno",
  MXN = "Peso mexicano",
  BRL = "Real",
  PAB = "Balboa",
  CRC = "Colón",
}
