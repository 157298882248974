import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const branchEditContainerStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  boxContentTable: {
    display: "flex",
    height: "72vh",
    alignItems: "center",
    mt: "20px",
    width: "80%",
  },
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
  boxTable: {
    mt: (theme) => theme.spacing(3),
    mb: "1vh",
    display: "flex",
    height: "30vh",
    width: "100%",
  },
  boxConfirmModal: {
    display: "flex",
    justifyContent: "center",
  },
});
