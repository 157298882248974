import React from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Edit, Trash2 } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { get, omit, defaultTo } from "lodash";
import { EmptyList } from "../../commons/EmptyList/EmptyList";
import PaginationTable, {
  PaginationTableProps,
} from "./Pagination/PaginationTable";
import { SkeletonTable } from "../../Skeletons/SkeletonTable";
import {
  BUSINESS_RULES_ACTIONS,
  BUSINESS_RULES_RULES_TABLE,
} from "../../../shared/infrastructure/constants/BusinessRulesEnum";
import { StatusChip } from "../../commons/StatusChip/StatusChip";
import { DialogMessage } from "../../commons/Dialog/DialogMessage";
import { translateRuleKeys } from "../../../shared/utils";
import { LabelField } from "../../../shared/infrastructure/constants/RulesConstants";
import * as moment from "moment";
import { RuleItem } from "../../../../types/get_business_rules_response";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    paddingTop: "0px",
    borderRadius: 0,
    boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  header: {
    backgroundColor: "#F7FAFC",
  },
});

export interface BusinessRulesTableProps {
  businessRules: RuleItem[];
  isLoading: boolean;
  editRule: (ruleId: string) => void;
  pagination: PaginationTableProps;
  dialog: {
    openDialog: boolean;
    handleCloseDialog: () => void;
    handleOpenDialog: (ruleId: string) => void;
    handleClickDialog: () => void;
  };
}

const getDate = (date: number, formatDate: string) => {
  return moment.unix(date).format(formatDate);
};

const getRulesByRule = (businessRule: object) => {
  const mainRulesValues: object = omit(get(businessRule, "rule", {}), [
    "name",
    "action",
    "code",
    "message",
    "partnerValidator",
    "limit",
    "maxAmount",
    "periodicitySum",
    "periodicityCount",
    "transactionStatusSum",
    "transactionStatusCount",
    "kind",
    "processorName",
    "metric",
    "transactionStatus",
    "periodicity",
  ]);
  return mainRulesValues;
};

export const BusinessRulesTable: React.FC<BusinessRulesTableProps> = (
  props: BusinessRulesTableProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {props.isLoading ? (
          <SkeletonTable columns={5} rows={props.pagination.limit} />
        ) : (
          <TableContainer>
            {props.businessRules.length > 0 ? (
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.header}>
                  <TableRow key="header">
                    {["Creado", "Alias", "Acción", "Etiquetas", ""].map(
                      (column) => (
                        <TableCell
                          key={`headercell_${column}`}
                          style={{
                            color: "#6D7781",
                            padding: "10px 16px",
                            minWidth: 150,
                          }}
                        >
                          {column}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.businessRules.map((rule: RuleItem, index: number) => (
                    <TableRow
                      key={index}
                      className={classes.cursorPointer}
                      onClick={() => {}}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">
                          {getDate(get(rule, "createdAt"), "yyyy-MM-DD")}
                        </Typography>
                        <Typography variant="subtitle2" color={"textSecondary"}>
                          {getDate(get(rule, "createdAt"), "HH:mm:ss")}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {get(rule, "rule.name")}
                      </TableCell>
                      <TableCell align="left">
                        {translateRuleKeys(
                          get(rule, "rule.action"),
                          BUSINESS_RULES_ACTIONS
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Grid container>
                          {Object.keys(defaultTo(getRulesByRule(rule), {})).map(
                            (key: string, index: number) => (
                              <Grid
                                key={`grid_chip_${index}`}
                                item
                                md={4}
                                sm={8}
                                xs={12}
                                style={{ marginBottom: 5 }}
                              >
                                <StatusChip
                                  key={`chip_${index}`}
                                  text={translateRuleKeys(
                                    key,
                                    BUSINESS_RULES_RULES_TABLE
                                  )}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "10px 16px", width: "125px" }}
                      >
                        <React.Fragment>
                          <IconButton
                            id={`${index}_edit_rates`}
                            aria-label="Editar tarifa"
                            onClick={(e) => {
                              props.editRule(get(rule, "id", ""));
                              e.stopPropagation();
                            }}
                          >
                            <Edit xlinkTitle={"editar"} size={16} />
                          </IconButton>
                          <IconButton
                            id={`${index}_delete_rates`}
                            aria-label="Eliminar tarifa"
                            onClick={(e) => {
                              props.dialog.handleOpenDialog(rule.id);
                              e.stopPropagation();
                            }}
                          >
                            <Trash2 xlinkTitle={"eliminar"} size={16} />
                          </IconButton>
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box marginTop={2}>
                <Card style={{ paddingRight: 30, paddingLeft: 30 }}>
                  <EmptyList
                    title={"No se ha encontrado ninguna regla."}
                    subtitle={"Aún no tienes reglas."}
                  />
                </Card>
              </Box>
            )}
            <PaginationTable {...props.pagination} />
          </TableContainer>
        )}
        <DialogMessage
          title={LabelField.DELETE_RULE_TITLE}
          message={LabelField.DELETE_RULE_MESSAGE}
          onCancel={props.dialog.handleCloseDialog}
          onOk={props.dialog.handleClickDialog}
          openDialog={props.dialog.openDialog}
        />
      </Paper>
    </React.Fragment>
  );
};
