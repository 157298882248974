import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";
import { SvgIcon } from "@mui/material";

const SquareCrossIcon: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{ fontSize: "24px !important" }}
      >
        <path
          d="M15.0013 18H8.33464C7.62739 18 6.94911 17.719 6.44902 17.219C5.94892 16.7189 5.66797 16.0406 5.66797 15.3333V8.66667C5.66797 7.95942 5.94892 7.28115 6.44902 6.78105C6.94911 6.28095 7.62739 6 8.33464 6H13.668C13.8448 6 14.0143 6.07024 14.1394 6.19526C14.2644 6.32029 14.3346 6.48986 14.3346 6.66667C14.3346 6.84348 14.2644 7.01305 14.1394 7.13807C14.0143 7.2631 13.8448 7.33333 13.668 7.33333H8.33464C7.98101 7.33333 7.64187 7.47381 7.39183 7.72386C7.14178 7.97391 7.0013 8.31304 7.0013 8.66667V15.3333C7.0013 15.687 7.14178 16.0261 7.39183 16.2761C7.64187 16.5262 7.98101 16.6667 8.33464 16.6667H15.0013C15.3549 16.6667 15.6941 16.5262 15.9441 16.2761C16.1942 16.0261 16.3346 15.687 16.3346 15.3333V14C16.3346 13.8232 16.4049 13.6536 16.5299 13.5286C16.6549 13.4036 16.8245 13.3333 17.0013 13.3333C17.1781 13.3333 17.3477 13.4036 17.4727 13.5286C17.5977 13.6536 17.668 13.8232 17.668 14V15.3333C17.668 16.0406 17.387 16.7189 16.8869 17.219C16.3868 17.719 15.7085 18 15.0013 18Z"
          fill="#0DC298"
        />
        <path
          d="M12.0014 14.3333C11.9137 14.3338 11.8267 14.317 11.7455 14.2838C11.6643 14.2506 11.5904 14.2017 11.5281 14.14L8.52809 11.14C8.46593 11.0778 8.41663 11.004 8.38299 10.9228C8.34935 10.8416 8.33203 10.7545 8.33203 10.6666C8.33203 10.4891 8.40256 10.3188 8.52809 10.1933C8.65363 10.0678 8.82389 9.99723 9.00143 9.99723C9.17896 9.99723 9.34922 10.0678 9.47476 10.1933L12.0014 12.7266L17.1948 7.52663C17.3203 7.40109 17.4906 7.33057 17.6681 7.33057C17.8456 7.33057 18.0159 7.40109 18.1414 7.52663C18.267 7.65216 18.3375 7.82243 18.3375 7.99996C18.3375 8.1775 18.267 8.34776 18.1414 8.47329L12.4748 14.14C12.4125 14.2017 12.3386 14.2506 12.2574 14.2838C12.1761 14.317 12.0892 14.3338 12.0014 14.3333Z"
          fill="#0DC298"
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default SquareCrossIcon;
