import { FC } from "react";
import { CellCheckItem } from "../../TableCells/CellCheck/CellCheck";

import { ITableCellProps, ITableRowProps } from "./interfaces";
import { CellTextCopy } from "../../TableCells/CellTextCopy/CellTextCopy";
import { CellFlagItem } from "../../TableCells/CellFlag/CellFlag";
import { CellTitleItem } from "../../TableCells/CellTitle/CellTitle";
import { CellChipStatus } from "../../TableCells/CellChip/CellChipStatus";
import { defaultTo, get, isEmpty } from "lodash";
import { CellActionMenu } from "../../TableCells/CellActionMenu/CellActionMenu";
import { IItemList } from "../../TableCells/CellActionMenu/CellActionMenu.interfaces";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { format, parseISO } from "date-fns";
import { generateUrl } from "../../../shared/utils/urlUtils";
import { CellDateRange } from "../../TableCells/CellDateRange/CellDateRange";
import { CellTextAction } from "../../TableCells/CellTextAction/CellTextAction";
import { NamedStyles } from "../../../shared/interfaces/create_named_styles";
import { CellTextItem } from "../../TableCells/CellText/CellText";
import { TableBodyStyles } from "../TableBody/TableBody.style";
import { CellActionOptionMenu } from "../../TableCells/CellActionOptionMenu/CellActionOptionMenu";
import { EnumCellOptionMenu } from "../../TableCells/CellActionOptionMenu/constantsCellOptionMenu";

enum TableBodyCellEnum {
  CHECK = "CHECK",
  TITLE = "TITLE",
  TEXT_COPY = "TEXT_COPY",
  TAG = "TAG",
  FLAG = "FLAG",
  ACTION_MENU = "ACTION_MENU",
  OPTION_MENU = "OPTION_MENU",
  DATE_RANGE = "DATE_RANGE",
  TEXT_ACTION = "TEXT_ACTION",
  TEXT = "TEXT",
}

interface IConfig {
  //Valor por defecto en caso que no se obtenga un valor del objeto con el path.
  defaultValue?: any;
  //Path para obtener el valor del objeto.
  path: string;
  //Text que se mostrara en la tabla, se agrega $var para mostrar la posición donde estara el valor obtenido del objeto.
  text: string;
  isDate?: boolean;
  hasUrl?: boolean;
  formatDate?: string;
  catalog?: object;
  styles?: NamedStyles<any>;
}

export interface IConfigCellBasic {
  configMain?: IConfig;
  configSecondary?: IConfig;
  type: TableBodyCellEnum;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

interface IConfigCellCheck extends IConfigCellBasic {
  isChecked: boolean;
}
interface IConfigCellFlag extends IConfigCellBasic {
  isCodeCountry?: boolean;
}

export type IConfigCells =
  | IConfigCellBasic
  | IConfigCellCheck
  | IConfigCellFlag;

export interface IAdditionalProps {
  handleGetRowData?: () => MerchantNodeData;
}

const CELL_COMPONENTS_BY_TABLE_ENUM: Record<TableBodyCellEnum, FC<any>> = {
  [TableBodyCellEnum.CHECK]: CellCheckItem,
  [TableBodyCellEnum.TITLE]: CellTitleItem,
  [TableBodyCellEnum.TEXT_COPY]: CellTextCopy,
  [TableBodyCellEnum.FLAG]: CellFlagItem,
  [TableBodyCellEnum.TAG]: CellChipStatus,
  [TableBodyCellEnum.ACTION_MENU]: CellActionMenu,
  [TableBodyCellEnum.DATE_RANGE]: CellDateRange,
  [TableBodyCellEnum.TEXT_ACTION]: CellTextAction,
  [TableBodyCellEnum.TEXT]: CellTextItem,
  [TableBodyCellEnum.OPTION_MENU]: CellActionOptionMenu,
};

const formatDate = (
  dateString: string,
  formatString: string = "dd/MM/yyyy"
): string => {
  let formattedDate: string = "";

  try {
    formattedDate = format(parseISO(dateString), formatString);
  } catch (_) {}

  return formattedDate;
};

function generateText<T = object>(
  obj: T,
  text: string = "",
  path: string,
  defaultValue?: string,
  isDate: boolean = false,
  formatDateStr?: string,
  catalog?: object
): string {
  let required_value = get(obj, path, defaultTo(defaultValue, ""));

  if (isDate) {
    const formattedDate: string = formatDate(required_value, formatDateStr);

    return text.replace("$var", formattedDate);
  }

  if (!isEmpty(catalog)) {
    required_value = catalog![required_value];
  }

  return text.replace("$var", required_value);
}

export function builderRows<T = object>(
  data: T[],
  configRows: IConfigCells[],
  entityName: string,
  menuAction?: IItemList[],
  page: string = ""
): ITableRowProps<T>[] {
  const rows_props: ITableRowProps<T>[] = [];
  let table_cells: ITableCellProps[];
  let cell: ITableCellProps;

  data.map((obj: T, index: number) => {
    table_cells = [];
    configRows.map((config: IConfigCells) => {
      switch (config.type) {
        case TableBodyCellEnum.TEXT:
          cell = {
            props: {
              align: get(config, "align"),
              styles: get(config, "configMain.styles", TableBodyStyles),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false),
                get(config, "configMain.formatDate"),
                get(config, "configMain.catalog")
              ),
            },
            type: TableBodyCellEnum.TEXT,
          };
          break;
        case TableBodyCellEnum.TEXT_ACTION:
          const value: number = get(obj, get(config, "configMain.path"), 0);

          cell = {
            props: {
              align: get(config, "align"),
              text: get(config, "configMain.defaultValue"),
              value,
            },
            type: TableBodyCellEnum.TEXT_ACTION,
          };
          break;
        case TableBodyCellEnum.DATE_RANGE:
          cell = {
            props: {
              align: get(config, "align"),
              fromDate: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false),
                get(config, "configMain.formatDate")
              ),
              toDate: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                get(config, "configSecondary.isDate", false),
                get(config, "configSecondary.formatDate")
              ),
            },
            type: TableBodyCellEnum.DATE_RANGE,
          };
          break;
        case TableBodyCellEnum.CHECK:
          cell = {
            props: {
              align: get(config, "align"),
              id: new Date().getTime().toString(),
              isChecked: false,
            },
            type: TableBodyCellEnum.CHECK,
          };
          break;
        case TableBodyCellEnum.TITLE:
          cell = {
            props: {
              align: get(config, "align"),
              line1: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false),
                get(config, "configMain.formatDate"),
                get(config, "configMain.catalog")
              ),
              line2: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                get(config, "configSecondary.isDate", false),
                get(config, "configSecondary.formatDate"),
                get(config, "configSecondary.catalog")
              ),
              type: !isEmpty(get(config, "configSecondary.path"))
                ? "twoLines"
                : "oneLine",
              url: generateUrl(obj, get(config, "configMain.hasUrl")),
            },
            type: TableBodyCellEnum.TITLE,
          };
          break;
        case TableBodyCellEnum.TEXT_COPY:
          const info = generateText<T>(
            obj,
            get(config, "configMain.text"),
            get(config, "configMain.path"),
            get(config, "configMain.defaultValue")
          );

          cell = {
            props: {
              align: get(config, "align"),
              textToCopy: info,
              title: info,
            },
            type: TableBodyCellEnum.TEXT_COPY,
          };
          break;
        case TableBodyCellEnum.FLAG:
          cell = {
            props: {
              align: get(config, "align"),
              country: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              isCodeCountry: get(config, "isCodeCountry", false),
              type: "twolines",
            },
            type: TableBodyCellEnum.FLAG,
          };
          break;
        case TableBodyCellEnum.TAG:
          cell = {
            props: {
              align: get(config, "align"),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              textTooltipMain: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue")
              ),
            },
            type: TableBodyCellEnum.TAG,
          };
          break;
        case TableBodyCellEnum.ACTION_MENU:
          cell = {
            props: {
              actions: defaultTo(menuAction, []),
              configsCompleted: get(obj, "configs_completed", false),
              entityName,
              status: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
            },
            type: TableBodyCellEnum.ACTION_MENU,
          };
          break;
        case TableBodyCellEnum.OPTION_MENU:
          const valueText: number = get(obj, get(config, "configMain.path"), 0);

          cell = {
            props: {
              actions: [
                {
                  data: {
                    id_registro: get(obj, "transaction_id", ""),
                    kind: get(config, "configMain.defaultValue"),
                    type: EnumCellOptionMenu.VISUALIZAR,
                  },
                  optionText: EnumCellOptionMenu.VISUALIZAR,
                },
                {
                  data: {
                    id_registro: get(obj, "transaction_id", ""),
                    kind: get(config, "configMain.defaultValue"),
                    type: EnumCellOptionMenu.DESCARGAR,
                  },
                  optionText: EnumCellOptionMenu.DESCARGAR,
                },
              ],
              text: get(config, "configMain.defaultValue"),
              value: valueText,
            },
            type: TableBodyCellEnum.OPTION_MENU,
          };
          break;
      }
      table_cells.push(cell);
    });
    rows_props.push({
      cells: table_cells,
      id: index.toString().concat(`.${page}`),
      info: obj,
    });
  });

  return rows_props;
}

export { TableBodyCellEnum, CELL_COMPONENTS_BY_TABLE_ENUM };
