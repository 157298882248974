import { DataTableHeader } from "../../../../types/data_table_header";

export const dataTableHeader: DataTableHeader = [
  { alias: "Creado", label: "Creado" },
  { alias: "Alias", label: "Alias" },
  { alias: "Medio de pago o producto", label: "Medio de pago o producto" },
  { alias: "Estado de conexión", label: "Estado de conexión" },
  { alias: "Connection-Buttons", label: "" },
];

export enum WebhookStatusEnum {
  FAILED = "FAILED",
  OK = "OK",
}

export enum LabelWebhookStatusEnum {
  FAILED = "Fallido",
  SUCCESS = "Exitoso",
}
export enum LabelConstantsEnum {
  TEST_CONNECTION = "Probar Conexión",
  SIMULATE_CONNECTION = "Simular Conexión",
}
