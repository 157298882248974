import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const headerStyles: IStyles = {
  typography: {
    color: "#023365",
    fontSize: "40px",
    fontWeight: 300,
  },
  typographyMobile: {
    color: "#023365",
    fontSize: "32px",
    fontWeight: 200,
  },
};
