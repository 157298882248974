import React from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import { ContactDataEditDelete } from "./ContactDataAreas.interfaces";
import { contactDataAreasStyles } from "./ContactDataAreas.styles";
import {
  IconEdit,
  IconMore,
  IconTrash,
  ModalSimple,
  OptionList,
} from "@kushki/connect-ui";
import { InfoOutlined } from "@mui/icons-material";
import { ContactInfoBody } from "../../../types/contact_data";
import { ContactAreaEnum } from "../../shared/constants/contact_area";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { ContactDataTextsEnum } from "../../shared/enum/ContactDataTextsEnum";
import { VARIABLE_ARRAY_CONTACT_DATA } from "../../shared/constants/array_contact_data";
import { useAppSelector } from "../../store/hooks/storeHook";
import { sortContactDataSections } from "../../shared/utils/contact_data_utils";

export interface IContactDataAreas {
  categoryAreas: ICategory[];
  nameValidation: {
    isNameValid: boolean;
    handleIsNameValid: (isNameValid: boolean) => void;
  };
  openModal: boolean;
  isAreasSelected: boolean;
  dataCompleted: {
    isDataCompleted: boolean;
    handleIsDataCompleted: (validateDataCompleted: boolean) => void;
  };
  isCentralizedBranch: boolean;
  dataNoErrors: {
    isDataNoErrors: boolean;
    handleIsDataNoErrors: (validateDataNoErrors: boolean) => void;
  };
  contactDataModal: {
    name: string;
    email: string;
  };
  onSubmit: () => void;
  handleFormChange(
    name: string,
    value: string,
    selectedItems?: ICategory[]
  ): void;
  contactDataAreasProps: {
    anchorEl: HTMLButtonElement | null;
    openModalDelete: boolean;
    openPopover: boolean;
    valuesItem: {};
    handleOpenEdit: () => void;
    handleOpenModalDelete: (isOpen: boolean) => void;
    handleOpenPopover: (
      event: React.MouseEvent<HTMLButtonElement>,
      info: ContactDataEditDelete
    ) => void;
    handleClosePopover: () => void;
    handlerDeleteContactPerson: () => void;
    handlerOnCancelEdit: () => void;
  };
}

const translateArea = (area: string): string => {
  const element: ICategory = ContactAreaEnum.find(
    (item: ICategory) => item.value === area
  )!;

  return element.label;
};

export const ContactDataAreas = (props: IContactDataAreas) => {
  const { contactData } = useAppSelector((state) => state.contactData);

  const areasWithoutData: number[] = [0, 1];

  const idPopover = props.contactDataAreasProps.openPopover
    ? "simple-popover"
    : undefined;

  return (
    <>
      <Box sx={contactDataAreasStyles.cardTesting}>
        <Card sx={contactDataAreasStyles.cardTesting} elevation={0}>
          {Object.keys(sortContactDataSections(contactData)).map(
            (item: string) =>
              VARIABLE_ARRAY_CONTACT_DATA.includes(item) && (
                <Grid
                  key={item}
                  container
                  item
                  xs={12}
                  md={12}
                  sx={
                    areasWithoutData.includes(contactData[item].length)
                      ? contactDataAreasStyles.containerWithoutData
                      : contactDataAreasStyles.container
                  }
                >
                  <Grid item xs={4}>
                    <Typography color="text.primary" variant="h6">
                      {translateArea(item)}
                    </Typography>
                  </Grid>
                  {contactData[item].length > 0 ? (
                    <>
                      <Grid item xs={8}>
                        {contactData[item].map((data: ContactInfoBody) => (
                          <Box
                            sx={contactDataAreasStyles.boxInternal}
                            key={data.name}
                          >
                            <Box sx={contactDataAreasStyles.dataContainer}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={contactDataAreasStyles.nameContent}
                                  >
                                    {get(data, "name", "")}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  sx={contactDataAreasStyles.emailGrid}
                                >
                                  <Typography
                                    sx={contactDataAreasStyles.emailContent}
                                  >
                                    {get(data, "email", "")}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sx={contactDataAreasStyles.buttonGrid}
                                >
                                  <IconButton
                                    sx={contactDataAreasStyles.buttonEdit}
                                    aria-label="deleteEdit"
                                    size="small"
                                    onClick={(event) => {
                                      props.contactDataAreasProps.handleOpenPopover(
                                        event,
                                        {
                                          area: item,
                                          data: contactData[item],
                                          item: data,
                                        }
                                      );
                                    }}
                                    aria-describedby={idPopover}
                                  >
                                    <IconMore />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        ))}
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={8}>
                      <Box sx={contactDataAreasStyles.boxInternal}>
                        <Box sx={contactDataAreasStyles.boxSample}>
                          <InfoOutlined sx={contactDataAreasStyles.icon} />
                          <Typography sx={contactDataAreasStyles.nameContent}>
                            No tienes personas agregadas en esta área.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )
          )}
        </Card>
      </Box>
      <Popover
        id={idPopover}
        open={props.contactDataAreasProps.openPopover}
        anchorEl={props.contactDataAreasProps.anchorEl}
        onClose={props.contactDataAreasProps.handleClosePopover}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <OptionList
          handleOptionClick={() => {}}
          list={[
            {
              first: true,
              handleOptionClick: () => {
                props.contactDataAreasProps.handleOpenEdit();
                props.contactDataAreasProps.handleClosePopover();
              },
              Icon: IconEdit,
              onClick: () => {
                props.contactDataAreasProps.handleOpenEdit();
                props.contactDataAreasProps.handleClosePopover();
              },
              optionText: "Editar",
              type: "type1",
            },
            {
              disable: props.isCentralizedBranch,
              handleOptionClick: () => {
                props.contactDataAreasProps.handleOpenModalDelete(true);
              },
              Icon: IconTrash,
              last: true,
              onClick: () => {
                props.contactDataAreasProps.handleOpenModalDelete(true);
              },
              optionText: "Eliminar",
              type: "type2",
            },
          ]}
        />
        <ModalSimple
          onClickPrimary={() => {
            props.contactDataAreasProps.handlerDeleteContactPerson();
            props.contactDataAreasProps.handleOpenModalDelete(false);
            props.contactDataAreasProps.handleClosePopover();
          }}
          onClickSecondary={() => {
            props.contactDataAreasProps.handleOpenModalDelete(false);
            props.contactDataAreasProps.handleClosePopover();
          }}
          buttonPrimary={ContactDataTextsEnum.CONFIRM}
          buttonSecondary={ContactDataTextsEnum.CANCEL}
          descriptionText={ContactDataTextsEnum.CLEAR_PERSON_DATA}
          isOpen={props.contactDataAreasProps.openModalDelete}
          onClose={() => {
            props.contactDataAreasProps.handleOpenModalDelete(false);
            props.contactDataAreasProps.handleClosePopover();
          }}
          titleText={
            ContactDataTextsEnum.TITLE +
            get(props.contactDataAreasProps.valuesItem, "item.name", "") +
            " ?"
          }
          typeModal={1}
          buttonPrimaryDisabled={false}
        />
      </Popover>
    </>
  );
};
