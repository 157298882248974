export enum FilterTypeEnum {
  taxpayerType = "taxpayerTypeId",
  activity = "activityId",
  incomeSourceConcept = "incomeSourceConceptId",
  ivaRetention = "ivaRetention",
}

export enum FilterTypeEsEnum {
  taxpayerType = "Tipo de contribuyente",
  activity = "Actividad",
  incomeSourceConcept = "Concepto de retención en la renta",
}

export enum FilterCOTypeEnum {
  city = "cityId",
  model = "model",
}
