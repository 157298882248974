import React, { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { addWebhookFormStyles as styles } from "./AddWebhookForm.styles";
import * as formLabels from "../../shared/constants/labels/add_webhook_labels";
import { IAddWebhookFormProps } from "./AddWebhookForm.interfaces";
import AllCharsInput from "../InputsForm/AllCharsInput/AllCharsInput";
import { useAddWebhookFormState } from "./state/useAddWebhookFormState";
import { Add, Close, InfoOutlined } from "@mui/icons-material";
import ListUrls from "../../components/ListUrls/ListUrls";
import FormActions from "../FormActions/FormActions";
import {
  IconCheck,
  IconCircleWarn,
  IconEdit,
  IconPlus,
  IconTrash,
  ModalContent,
  ModalLoader,
  SnackbarAlert,
} from "@kushki/connect-ui";
import { WebhookGeneral } from "../WebhookGeneral/WebhookGeneral";
import {
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "../../shared/enums/ModalContentTypeEnum";
import { get } from "lodash";
import { GeneralWebhook } from "../../../types/webhook_response_schema";
import { IconColorsEnum } from "../../shared/constants/enums/IconColorsEnum";
import { PaymentMethodCheckList } from "../PaymentMethodCheckList/PaymentMethodCheckList";
import { WebhookEventsCheckList } from "../WebhookEventsCheckList/WebhookEventsCheckList";
import { namesFormEnum } from "../../shared/constants/enums/NamesFormEnum";
import { INPUT_ERROR_MESSAGES } from "../../shared/constants/labels/input_form_errors";
import Snackbar from "@mui/material/Snackbar";
import { isMaster } from "../../shared/utils/cognito";

const AddWebhookForm: FC<IAddWebhookFormProps> = ({
  form: { control, errors, reset, resetField, setError },
}: IAddWebhookFormProps) => {
  const {
    headersList,
    headersLength,
    handleOnCancel,
    handleOnAccept,
    handleWebhook,
    handleWebhookClose,
    handleWebhookSave,
    webhookActions,
    handleEdit,
    handleCancel,
    handleDelete,
    handleAddHeaderConfig,
    handleSaveChanges,
    handleOnChangeValueHeaders,
    handleOnChangeLabelHeaders,
    isEdition,
    submitModal,
  } = useAddWebhookFormState({
    control,
    reset,
    resetField,
    setError,
  });

  return (
    <Box>
      <ModalLoader
        isOpen={webhookActions.fetch}
        titleText={formLabels.MODAL_WEBHOOK_LOADING_LABELS.title}
        descriptionText={formLabels.MODAL_WEBHOOK_LOADING_LABELS.description}
      />
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={webhookActions.snackbar.open || webhookActions.snackbar.error}
        autoHideDuration={3000}
      >
        <SnackbarAlert
          variant={"simple"}
          message={
            webhookActions.snackbar.open
              ? formLabels.SNACKBAR.success
              : webhookActions.snackbar.error
              ? formLabels.SNACKBAR.error
              : ""
          }
          color={
            webhookActions.snackbar.error
              ? "danger"
              : webhookActions.snackbar.open
              ? "success"
              : "info"
          }
          withIcon={
            webhookActions.snackbar.error
              ? true
              : webhookActions.snackbar.open
              ? undefined
              : undefined
          }
          icon={
            webhookActions.snackbar.error ? (
              <IconCircleWarn />
            ) : webhookActions.snackbar.open ? undefined : undefined
          }
        />
      </Snackbar>
      <ModalLoader
        isOpen={!!webhookActions.submitFetch}
        titleText="Estamos guardando la configuración del webhook"
        descriptionText="Este proceso puede tardar unos minutos..."
      />
      <ModalContent
        buttonSecondaryText={formLabels.MODAL_WEBHOOK_MODAL_LABELS.buttonClose}
        buttonText={formLabels.MODAL_WEBHOOK_MODAL_LABELS.buttonSave}
        descriptionText=""
        onClose={handleWebhookClose}
        titleText={formLabels.MODAL_WEBHOOK_MODAL_LABELS.title}
        buttonAction={handleWebhookSave}
        isOpen={webhookActions.modal.open}
        type={ModalContentTypeEnum.DEFAULT}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="text.dark" variant="body2">
              {formLabels.MODAL_WEBHOOK_MODAL_LABELS.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <WebhookGeneral general={webhookActions.temp} />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalContent
        buttonSecondaryText={formLabels.MODAL_WEBHOOK_SUBMIT_LABELS.buttonClose}
        buttonText={formLabels.MODAL_WEBHOOK_SUBMIT_LABELS.buttonSave}
        descriptionText=""
        onClose={submitModal.handleClose}
        titleText={formLabels.MODAL_WEBHOOK_SUBMIT_LABELS.title}
        buttonAction={submitModal.handleCreateWebhook}
        isOpen={submitModal.open}
        type={ModalContentTypeEnum.DEFAULT}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
      >
        <Typography color="text.dark" variant="body2">
          {formLabels.MODAL_WEBHOOK_SUBMIT_LABELS.description}
        </Typography>
        <WebhookGeneral general={webhookActions.data} />
      </ModalContent>
      <Box sx={styles.container}>
        <form>
          <Box sx={styles.container}>
            {!isMaster() && (
              <Paper elevation={2} sx={styles.paper}>
                <Typography color="text.primary" variant="h3">
                  {isEdition
                    ? formLabels.EDIT_WEBHOOK_TITLE
                    : formLabels.ADD_WEBHOOK_TITLE}
                </Typography>
                <Typography sx={styles.subtitle}>
                  {formLabels.ADD_WEBHOOK_SUBTITLE}
                </Typography>
                <Divider sx={styles.divider} />
                <Typography variant={"body1"}>
                  {formLabels.IDENTIFICATION_LABEL}
                </Typography>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <AllCharsInput
                      name={namesFormEnum.ALIAS}
                      control={control}
                      errors={errors}
                      customErrorMessages={{
                        required: INPUT_ERROR_MESSAGES.aliasError,
                      }}
                      isRequired
                      label={formLabels.TEXT_FIELD_LABEL}
                    />
                  </Grid>
                </Grid>
                <PaymentMethodCheckList />
                <WebhookEventsCheckList />
                <Box sx={styles.urlBoxContainer}>
                  <Typography variant={"body1"}>
                    {formLabels.ADD_URLS_LABEL}
                  </Typography>
                  <Box sx={styles.verticalContainer}>
                    <Box sx={styles.subtitleBox}>
                      <InfoOutlined fontSize="small" color={"disabled"} />
                      <Typography sx={styles.subtitleUrl}>
                        {formLabels.ADD_URL_SUBTITLE}
                      </Typography>
                    </Box>
                    <Box sx={styles.inputUrlBox}>
                      <AllCharsInput
                        name={namesFormEnum.URL}
                        control={control}
                        errors={errors}
                        label={formLabels.ADD_URLS_INPUT_LABEL}
                      />
                      <Button
                        sx={styles.addBoxButton}
                        variant="outlined"
                        color="secondary"
                        startIcon={<Add />}
                        onClick={handleWebhook}
                      >
                        {formLabels.ADD_BUTTON_LABEL}
                      </Button>
                    </Box>
                    {get(webhookActions, "data.general", []).length > 0 &&
                      get(webhookActions, "data.general", []).map(
                        (webhook: GeneralWebhook, index: number) => (
                          <ListUrls
                            url={get(webhook, "url", "")}
                            state={get(webhook, "status", "")}
                            key={index}
                          />
                        )
                      )}
                  </Box>
                  <Box sx={styles.inputConfigurationContent}>
                    <InfoOutlined fontSize="small" sx={styles.infoIconStyle} />
                    <Typography sx={styles.infoUrl}>
                      {formLabels.ADD_URL_INFO}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            )}
            <Paper elevation={2} sx={styles.paperAdvancedConfig}>
              <Typography color="text.primary" variant="h3">
                {formLabels.ADVANCED_CONFIGURATION_TITLE}
              </Typography>
              <Typography sx={styles.subtitle}>
                {formLabels.ADVANCED_CONFIGURATION_SUBTITLE}
              </Typography>
              <Divider sx={styles.divider} />
              <Box sx={styles.configurationContent}>
                <Typography variant={"body1"}>
                  {formLabels.DESCRIPTION_TITLE}
                </Typography>
                <Typography sx={styles.descriptionText}>
                  {formLabels.DESCRIPTION}
                </Typography>
                <Box sx={styles.inputConfigurationContent}>
                  <AllCharsInput
                    name={namesFormEnum.LABEL}
                    control={control}
                    errors={errors}
                    label={formLabels.LABEL_INPUT_LBL}
                    disabled={headersLength === 4}
                  />
                  <AllCharsInput
                    name={namesFormEnum.VALUE}
                    control={control}
                    errors={errors}
                    label={formLabels.VALUE_INPUT_LBL}
                    disabled={headersLength === 4}
                  />
                  <Button
                    onClick={handleAddHeaderConfig}
                    disabled={headersLength === 4}
                    sx={styles.addBoxButton}
                    startIcon={<IconPlus />}
                    variant="outlined"
                    color={"secondary"}
                  >
                    {formLabels.ADD_BUTTON_LABEL}
                  </Button>
                </Box>
              </Box>
              {headersList.map(({ headerItem, isEditable, variant }, index) => (
                <Box sx={styles.verticalContainer} key={index}>
                  <Box
                    sx={
                      isEditable ? styles.headerEditableItem : styles.headerItem
                    }
                  >
                    <TextField
                      InputProps={{
                        disableUnderline: !isEditable,
                        readOnly: !isEditable,
                      }}
                      fullWidth
                      label={formLabels.LABEL_INPUT_LBL}
                      defaultValue={headerItem.label}
                      variant={variant}
                      value={headerItem.label}
                      onChange={(item) =>
                        handleOnChangeLabelHeaders(item.target.value, index)
                      }
                    />
                    <TextField
                      InputProps={{
                        disableUnderline: !isEditable,
                        readOnly: !isEditable,
                      }}
                      fullWidth
                      label={formLabels.VALUE_INPUT_LBL}
                      defaultValue={headerItem.value}
                      variant={variant}
                      value={headerItem.value}
                      onChange={(item) =>
                        handleOnChangeValueHeaders(item.target.value, index)
                      }
                    />
                    <Box sx={styles.editButtons}>
                      <IconButton
                        onClick={() =>
                          isEditable
                            ? handleSaveChanges(index)
                            : handleEdit(index)
                        }
                      >
                        {isEditable ? (
                          <IconCheck
                            fontSize="large"
                            color={IconColorsEnum.SUCCESS}
                          />
                        ) : (
                          <IconEdit fontSize="medium" />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          isEditable ? handleCancel(index) : handleDelete(index)
                        }
                      >
                        {isEditable ? (
                          <Close
                            fontSize={"medium"}
                            color={IconColorsEnum.ERROR}
                          />
                        ) : (
                          <IconTrash fontSize="medium" />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider sx={styles.dividerHeaders} />
                </Box>
              ))}
            </Paper>
          </Box>
        </form>
        {!isEdition && (
          <FormActions
            handleOnCancel={handleOnCancel}
            handleOnAccept={handleOnAccept}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddWebhookForm;
