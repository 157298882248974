export enum ActionTypes {
  SET_NOTIFICATION = "[PAYOUTS-DISPERSION] SET_NOTIFICATION",
  SET_DOWNLOAD_FILES_RESPONSE = "[PAYOUTS-DISPERSION] SET_DOWNLOAD_FILES_RESPONSE",
  SET_VALIDATE_FILES_RESPONSE = "[PAYOUTS-DISPERSION] SET_VALIDATE_FILES_RESPONSE",
  SET_SHOW_VALIDATION_ALERT = "[PAYOUTS-DISPERSION] SET_SHOW_VALIDATION_ALERT",
  SET_UPLOAD_FILES = "[PAYOUTS-DISPERSION] SET_UPLOAD_FILES",
  SET_PROCESS_FILE_DISPERSIONS_RESPONSE = "[PAYOUTS-DISPERSION] SET_PROCESS_FILE_DISPERSIONS_RESPONSE",
  SET_SHOW_ALERT_ON_PROCESS_FILE_REQUEST = "[PAYOUTS-DISPERSION] SET_SHOW_ALERT_ON_PROCESS_FILE_REQUEST",
  SET_SHOW_ALERT_ON_GET_DISPERSION_FILE = "[PAYOUTS-DISPERSION] SET_SHOW_ALERT_ON_GET_DISPERSION_FILE",
  SET_DISPERSION_FILE_RESPONSE = "[PAYOUTS-DISPERSION] SET_DISPERSION_FILE_RESPONSE",
  SET_IS_LOADING_GET_DISPERSION_FILE = "[PAYOUTS-DISPERSION] SET_IS_LOADING_GET_DISPERSION_FILE",
  SET_FILTERS = "[PAYOUTS-DISPERSION] SET_FILTERS",
  SET_FILE_VALIDATION_DETAILS = "[PAYOUTS-DISPERSION] SET_FILE_VALIDATION_DETAILS",
  SET_IS_LOADING_DETAILS = "[PAYOUTS-DISPERSION] SET_IS_LOADING_DETAILS",
  SET_FILE_DISPERSION_DETAILS = "[PAYOUTS-DISPERSION] SET_FILE_DISPERSION_DETAILS",
  SET_SHOW_ALERT_ON_DISPERSIONS_VALIDATION = "[PAYOUTS-DISPERSION] SET_SHOW_ALERT_ON_DISPERSIONS_VALIDATION",
  SET_SELECTED_DISPERSIONS_TAB = "[PAYOUTS-DISPERSION] SET_SELECTED_DISPERSIONS_TAB",
}
