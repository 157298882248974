import {
  LOADING_MODAL_MESSAGES,
  MASSIVE_SERVICE_ACTIONS,
} from "../constants/websocket_actions";
import { SNACKBAR_MESSAGES_ENUM } from "../enums/SnackbarMessagesEnum";

export const getErrorMessage = (type: string) =>
  type === MASSIVE_SERVICE_ACTIONS.decentralize
    ? SNACKBAR_MESSAGES_ENUM.decentralization_fail
    : SNACKBAR_MESSAGES_ENUM.centralization_fail;

export const getSuccessMessage = (type: string) =>
  type === MASSIVE_SERVICE_ACTIONS.decentralize
    ? SNACKBAR_MESSAGES_ENUM.decentralization_success
    : SNACKBAR_MESSAGES_ENUM.centralization_success;

export const getLoadingMessage = (type: string) =>
  type === MASSIVE_SERVICE_ACTIONS.decentralize
    ? LOADING_MODAL_MESSAGES.decentralize
    : LOADING_MODAL_MESSAGES.centralize;

export const MASSIVE_SERVICES_LENGTH = 1;
