import React from "react";
import { Button, Divider, Grid, Menu, Typography } from "@material-ui/core";
import { Filter } from "react-feather";
import { filterComponentStyle } from "./FilterComponent.style";
import {
  chipStatus,
  LabelsDeferred,
} from "../../shared/constants/RequestDispersionConstants";
import { useFilterComponentState } from "./state/useFilterComponentState";
import { AccordionPopUp } from "../AccordionPopUp/AccordionPopUp";
import { ChipComponent } from "../AccordionPopUp/components/ChipComponent/ChipComponent";
import ProcessorsRadioButtons from "../ProcessorsRadioButtons/ProcessorsRadioButtons";
import { IFilterComponent } from "../../shared/infrastructure/interfaces/IFilterComponent.interface";
import DateRangePicker from "../DateRangePicker/DateRangePicker";

export const FilterComponent = (props: IFilterComponent) => {
  const classes = filterComponentStyle();
  const {
    anchor,
    defaultExpanded,
    defaultExpandedProcessor,
    defaultExpandedStatus,
    defaultExpandedFee,
    processorNameSelected,
    handle,
    dateRange,
  } = useFilterComponentState(props);

  return (
    <Grid container direction={"row"} className={classes.rootGrid}>
      <Button
        variant={"outlined"}
        className={classes.filterButton}
        startIcon={<Filter size={"20"} color={"#293036"} />}
        onClick={handle.handleClickFilter}
      >
        <Typography className={classes.buttonText}>
          {LabelsDeferred.FILTER}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handle.handleCloseMenu}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        <Grid container direction={"column"} className={classes.mainGrid}>
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-around"}
            spacing={1}
          >
            <Grid item xs={6}>
              <Typography className={classes.filterByText}>
                {LabelsDeferred.FILTER_BY}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Button
                className={classes.expandedCollapsed}
                onClick={() =>
                  handle.handleExpandedOrCollapsed(!defaultExpanded)
                }
              >
                {!defaultExpanded
                  ? LabelsDeferred.EXPANDED
                  : LabelsDeferred.COLLAPSED}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
              <AccordionPopUp
                title={LabelsDeferred.PROCESSORS}
                defaultExpanded={defaultExpandedProcessor}
                handleExpandedOrCollapsed={
                  handle.handleExpandedOrCollapsedProcessor
                }
                componentToRender={
                  <ProcessorsRadioButtons
                    processor={props.processorNames}
                    processorNameSelected={processorNameSelected}
                    change={handle.handleChangeRadio}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={12}>
              <AccordionPopUp
                title={LabelsDeferred.STATUS}
                defaultExpanded={defaultExpandedStatus}
                handleExpandedOrCollapsed={
                  handle.handleExpandedOrCollapsedStatus
                }
                componentToRender={
                  <ChipComponent
                    isChipSelected={handle.isChipSelected}
                    handleClick={handle.handleClick}
                    type={LabelsDeferred.STATUS}
                    chip={chipStatus}
                  />
                }
              />
            </Grid>
            {/*Cuotas a diferir*/}
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider className={classes.firstDivider} />
              </Grid>
              <Grid item xs={12}>
                <AccordionPopUp
                  title={LabelsDeferred.FEE}
                  defaultExpanded={defaultExpandedFee}
                  handleExpandedOrCollapsed={
                    handle.handleExpandedOrCollapsedFee
                  }
                  componentToRender={
                    <ChipComponent
                      isChipSelected={handle.isChipFeeSelected}
                      handleClick={handle.handleClickFee}
                      type={LabelsDeferred.FEE}
                      chip={props.fee}
                    />
                  }
                />
              </Grid>
            </Grid>
            {/*Botones Filtrar y limpiar*/}
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.deleteButton}
                onClick={handle.handleDeleteFilters}
                variant={"outlined"}
              >
                {LabelsDeferred.CLEAN_FILTER}
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.applyButton}
                onClick={handle.handleApplyFilters}
              >
                {LabelsDeferred.APPLY_FILTER}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
      <DateRangePicker
        {...{
          clickDateFilter: handle.handleDateFilter,
          clickResetDateFilter: handle.handleResetDateFilter,
          dateRange: dateRange,
        }}
      />
    </Grid>
  );
};
