import { IStyles } from "../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  container: {
    flexDirection: "row",
  },
  text: {
    color: "#023365",
    paddingBottom: "4px",
    typography: "caption",
    fontSize: "16px",
  },
  value: {
    color: "#6D7781",
    paddingBottom: "4px",
    fontSize: "15px",
  },
};
