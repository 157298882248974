import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import { cloneDeep, get, has, isEmpty, isNil, set as setLodash } from "lodash";
import { ProcessorEnum } from "../infraestructure/ProcessorEnum";
import { TransactionType } from "../infraestructure/transaction-type";
import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import { databaseRef } from "../firebase";
import { API_ROUTES } from "../constants/api_routes";
import { ITransactionFile } from "../../../types/transaction_file";
import { RetrieveChildrenResponse } from "../../../types/retrive_children_response";
import { HeaderColumnIdEnum } from "../enums/HeaderColumnIdEnum";
import { setLoadingDownload } from "../../store/actions/app.actions";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { DateEnum } from "../enums/DateEnum";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { IFiltersDashboard } from "../../components/DashboardTransaction/state/useDashboardTransaction.interfaces";
import { PaymentSubMethodsEnum } from "../infraestructure/payment-method";

const getVoidDeclinedTransbankTransactionStatus = (
  trx: TransactionInfo
): string => {
  const trxsHistorical: TransactionInfo[] = get(trx, "historical", []);
  const finalStatus: string = get(trx, "transaction_status", "");
  const historicalDeclinedTrx = trxsHistorical.filter(
    (trx: TransactionInfo) =>
      trx.transaction_status === TransactionStatusEnum.DECLINED
  );
  const maxAllowedDeclinedTrx = 2;

  return historicalDeclinedTrx.length >= maxAllowedDeclinedTrx ||
    finalStatus === TransactionStatusEnum.DECLINED
    ? TransactionStatusEnum.DECLINED
    : TransactionStatusEnum.PROCESS;
};

const getVoidDeclinedTransactionStatus = (
  trx: TransactionInfo,
  isRefund: boolean,
  isVoid: boolean
): string => {
  if (trx.processor_name == ProcessorEnum.TransbankProcessor)
    return getVoidDeclinedTransbankTransactionStatus(trx);
  if (isRefund || isVoid) return TransactionStatusEnum.DECLINED;

  return TransactionStatusEnum.PROCESS;
};

function getElementAmount(transaction: Transaction): number {
  const isDeclined: boolean =
    transaction._source?.transaction_status === "DECLINED";
  const key: string = isDeclined
    ? "request_amount"
    : "approved_transaction_amount";

  return get(transaction._source, key);
}

const getFathersNameMongo = (children: object) => {
  return {
    customerId: get(children, "customer_code", ""),
    customerName: get(children, "customer_name", ""),
    ownerName: get(children, "owner_name", ""),
  };
};

const getFathersName = (
  children: RetrieveChildrenResponse[],
  transactionMerchantId: string
) => {
  for (let i = 0; i < children.length; i++) {
    const merchants_ids = children[i].publicMerchantId || [];

    if (
      merchants_ids.find((merchantId) => merchantId === transactionMerchantId)
    )
      return {
        customerId: children[i].customerId,
        customerName: children[i].customerName,
        ownerName: children[i].ownerName,
      };
  }

  return { customerId: "", customerName: "", ownerName: "" };
};

export function validateVoidTrx(
  element: Transaction,
  children: RetrieveChildrenResponse[]
): Transaction {
  let transactionElement: Transaction = cloneDeep(element);

  const transactionType: string = get(element._source, "transaction_type", "");

  if (
    get(transactionElement, "_source.transaction_status_type") ===
    TransactionStatusEnum.APPROVED
  )
    setLodash(
      transactionElement,
      "_source.owner_name",
      TransactionStatusEnum.APPROVAL
    );

  if (
    get(transactionElement, "_source.transaction_type") ===
    TransactionTypeEnum.DEFERRED
  )
    setLodash(
      transactionElement,
      "_source.transaction_type",
      TransactionTypeEnum.DEFFERRED
    );

  if (
    get(transactionElement, "_source.completed") === DateEnum.DEFAULT ||
    isNil(get(transactionElement, "_source.completed"))
  )
    if (get(transactionElement, "_source.expired") === DateEnum.DEFAULT)
      setLodash(
        transactionElement,
        "_source.completed",
        get(transactionElement, "_source.created")
      );
    else
      setLodash(
        transactionElement,
        "_source.completed",
        get(transactionElement, "_source.expired")
      );

  if (
    (transactionType === TransactionType.VOID ||
      transactionType === TransactionType.REFUND) &&
    element._source!.payment_method === "card"
  ) {
    const parent: Transaction[] = get(element, "_source.parent", []);
    let is_partial: boolean;
    let is_refund: boolean;
    let is_declined: boolean;

    const is_void: boolean = transactionType === TransactionType.VOID;

    is_refund = transactionType === TransactionType.REFUND;
    is_declined =
      element._source!.transaction_status === TransactionStatusEnum.DECLINED;
    is_partial =
      is_refund && parent.length > 0
        ? getElementAmount(element) !==
          get(parent[0], "_source.approved_transaction_amount")
        : false;

    setLodash(
      transactionElement,
      "_source.transaction_type",
      is_refund
        ? is_partial
          ? TransactionType.REFUND_PARTIAL
          : TransactionType.REFUND_TOTAL
        : element._source!.transaction_type
    );

    if (is_void) {
      const is_partial_void: boolean =
        parent.length > 0
          ? getElementAmount(element) !==
            get(parent[0], "_source.approved_transaction_amount")
          : false;

      setLodash(
        transactionElement,
        "_source.transaction_type",
        is_partial_void ? TransactionType.VOID_PARTIAL : TransactionType.VOID
      );
    }

    setLodash(
      transactionElement,
      "_source.transaction_status",
      is_declined
        ? getVoidDeclinedTransactionStatus(element._source!, is_refund, is_void)
        : element._source!.transaction_status
    );
  }
  const transaction_merchant_id = element._source?.merchant_id || "";
  const is_mongo: boolean = has(
    get(transactionElement, "_source"),
    "create_timestamp"
  );
  const father_names = is_mongo
    ? getFathersNameMongo(
        get(transactionElement, "_source.family_names.[0]", [{}])
      )
    : getFathersName(children, transaction_merchant_id);

  setLodash(
    transactionElement,
    "_source.owner_name",
    isEmpty(father_names.ownerName)
      ? HeaderColumnIdEnum.DEFAULT_OWNER_CUSTOMER
      : father_names.ownerName
  );

  setLodash(
    transactionElement,
    "_source.customer_name",
    isEmpty(father_names.customerName)
      ? HeaderColumnIdEnum.DEFAULT_OWNER_CUSTOMER
      : father_names.customerName
  );

  setLodash(
    transactionElement,
    "_source.customer_id",
    isEmpty(father_names.customerId)
      ? HeaderColumnIdEnum.DEFAULT_VALUE
      : father_names.customerId
  );

  return transactionElement;
}

export function buildStringFilter(filterItem: IFilterItem[]): string {
  let value: string = "";

  filterItem.map((item: IFilterItem, index: number) => {
    value = value.concat(item.value);
    if (index + 1 < filterItem.length) {
      value = value.concat("|");
    }
  });

  return value;
}

export function downloadTransactions(firebaseId: string, dispatch: any) {
  databaseRef
    .child(`${API_ROUTES.DOWNLOAD_TRANSACTIONS}${firebaseId}`)
    .on("value", (snapshot) => {
      const file: ITransactionFile = snapshot.val();

      if (!isNil(file.url)) {
        const a: HTMLElementTagNameMap["a"] = document.createElement("a");

        a.setAttribute("style", "display: none");
        a.href = `${file.url}`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        dispatch(setLoadingDownload(false));
      }
    });
}

export function verifySubMethod(filters: IFiltersDashboard) {
  const filter_aux = cloneDeep(filters.filter!);

  if (
    get(filter_aux, "paymentMethod", "")
      .split("|")
      .filter((item) => item === PaymentMethodEnum.CARD_PRESENT).length > 0
  ) {
    setLodash(filter_aux, "channel", PaymentSubMethodsEnum.POS);
    setLodash(
      filter_aux,
      "paymentMethod",
      get(filter_aux, "paymentMethod", "").replace(
        `|${PaymentMethodEnum.CARD_PRESENT}`,
        ""
      )
    );
    setLodash(
      filter_aux,
      "paymentMethod",
      get(filter_aux, "paymentMethod", "").replace(
        `${PaymentMethodEnum.CARD_PRESENT}|`,
        ""
      )
    );
    if (get(filter_aux, "paymentMethod", "") === PaymentMethodEnum.CARD_PRESENT)
      delete filter_aux.paymentMethod;
  }

  return filter_aux;
}
