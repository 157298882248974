import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "../../themeui";

export const tableHeaderStyles = makeStyles({
  boxContainer: {
    alignContent: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  title: {
    color: Palette.primary.main,
    fontFamily: "IBM Plex Sans",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "120%",
  },
});
