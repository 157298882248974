import theme from "../../../theme";
import { createNamedStyles } from "../../../shared/utils/create_named_styles";

export const ResendRefundModalStyles = createNamedStyles({
  buttonResend: {
    marginLeft: "11px",
    borderRadius: "4px",
    backgroundColor: "primary.main",
    color: "neutral.white",
    borderColor: "primary.main",
    minWidth: "140px",
    fontSize: "16px",
    lineHeight: "22.4px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "neutral.white",
      borderColor: "primary.main",
    },
    "&:focus": {
      backgroundColor: "primary.main",
      color: "neutral.white",
      borderColor: "primary.main",
    },
    "&:disabled": {
      backgroundColor: "primary.main",
      color: "neutral.white",
      opacity: 1,
    },
    "&:startIcon": {
      ml: 1,
    },
  },
  buttonCancel: {
    backgroundColor: "transparent",
    color: "error.dark",
    "&:hover": {
      background: "none",
      boxShadow: "none",
      color: "error.dark",
    },
  },
  emailInput: {
    backgroundColor: "neutral.white",
    "& fieldset": {
      borderRadius: "4px",
    },
  },
  main: {
    maxWidth: "537px",
    padding: "10px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  message: {
    lineHeight: "150%",
    color: "text.grey",
    typography: { md: "body2", sm: "inputText", xs: "inputText" },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "140%",
    },
  },
  title: {
    color: "text.dark",
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
