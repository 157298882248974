import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { IconPlus } from "@kushki/connect-ui";
import { AssignRuleLabels } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { useAssignRuleState } from "./state/useAssignRuleState";

export interface AssignRuleSectionProps {
  redirectCreateRule: () => void;
}

export const AssignRuleSection = (props: AssignRuleSectionProps) => {
  const { isDisabledSelectRuleBtn, handle } = useAssignRuleState();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction={"row"}
        >
          <Box mr={3}>
            <Button
              disabled={isDisabledSelectRuleBtn}
              variant="contained"
              color="primary"
              onClick={handle.handleSelectRule}
            >
              {AssignRuleLabels.SELECT_RULE}
            </Button>
          </Box>
          <Box>
            <Button
              disabled={isDisabledSelectRuleBtn}
              startIcon={<IconPlus />}
              variant="contained"
              color="primary"
              onClick={props.redirectCreateRule}
            >
              {AssignRuleLabels.CREATE_RULE}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
