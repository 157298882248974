import React from "react";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { get } from "lodash";
import { createStyles, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { InfoTrxProps } from "./ModalBodyTabs";
import { ModalBodyContentDetail } from "../ModalTabs/ModalBodyContentDetail";
import {
  subSectionEnum,
  tabEnum,
} from "../../shared/infrastructure/AdvanceConfigModal";
import { TextArea } from "./ModalBodyColombia";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    linearProgress: {
      marginTop: 3,
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    margin: {
      marginTop: 10,
    },
  })
);

const PaperContentDetail = (
  props: InfoTrxProps,
  subSection: subSectionEnum,
  isCharge: boolean = false
) => {
  const classes = useStyles();
  const trx = props.trxInfo;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ModalBodyContentDetail
              tabName={tabEnum.infoTab}
              trxProps={props}
              country={get(trx, "country", CountryEnum.mexico)}
              subSection={subSection}
              isMinAmount={props.isMinAmount}
              isCharge={isCharge}
              isHistoric={props.isHistoric}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const renderGeneralInfo = (props: InfoTrxProps) => {
  const trx = props.trxInfo;
  const validation: boolean =
    props.statusFlow == StatusFlowEnum.VALIDATOR &&
    get(props.trxInfo, "status", "") === "manual";

  switch (get(trx, "kind", "")) {
    case KindEnum.ANNUL_INVOICE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCard1)}
          {PaperContentDetail(props, subSectionEnum.infoCard2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
        </>
      );
    case KindEnum.INVOICE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCard1)}
          {PaperContentDetail(props, subSectionEnum.infoCard2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
        </>
      );
    case KindEnum.DISPERSION:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion1)}
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
        </>
      );
    case KindEnum.CREDIT_NOTE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.creditNoteDate)}
          {PaperContentDetail(props, subSectionEnum.infoCreditNoteBank)}
          {PaperContentDetail(props, subSectionEnum.addressCreditNote3)}
        </>
      );
    case KindEnum.CHARGE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardCharge1, true)}
          {PaperContentDetail(props, subSectionEnum.infoCardCharge2, true)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCardCharge3, true)}
        </>
      );
    case KindEnum.RECEIVABLE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardReceivable1)}
          {PaperContentDetail(props, subSectionEnum.infoCardReceivable2)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
          {validation && <TextArea {...props} />}
        </>
      );
    default:
      return <></>;
  }
};

const ModalBodyMexico = (props: InfoTrxProps) => {
  return (
    <ModalBody>
      <React.Fragment>{renderGeneralInfo(props)}</React.Fragment>
    </ModalBody>
  );
};

export default ModalBodyMexico;
