import {
  TransactionStatusEnum,
  TransactionStatusLabelEnum,
} from "../../enums/TransactionStatusEnum";
import {
  PaymentMethodEnum,
  PaymentMethodLabelEnum,
} from "../../enums/PaymentMethodEnum";

export enum FILTER_GROUPS {
  PAYMENT_METHODS = "Medios de pago",
  TYPES = "Tipos",
  STATE = "Estados",
  AMOUNTS = "Montos",
}

export const STATE_ITEM_VALUES: Record<
  TransactionStatusLabelEnum,
  TransactionStatusEnum
> = {
  [TransactionStatusLabelEnum.APPROVAL]: TransactionStatusEnum.APPROVAL,
  [TransactionStatusLabelEnum.DECLINED]: TransactionStatusEnum.DECLINED,
  [TransactionStatusLabelEnum.INITIALIZED]: TransactionStatusEnum.INITIALIZED,
  [TransactionStatusLabelEnum.APPROVAL]: TransactionStatusEnum.APPROVED,
};

export const PAYMENT_METHOD_ITEMS: Record<
  PaymentMethodEnum,
  PaymentMethodLabelEnum
> = {
  [PaymentMethodEnum.CARD]: PaymentMethodLabelEnum.CARD,
  [PaymentMethodEnum.TRANSFER]: PaymentMethodLabelEnum.TRANSFER,
  [PaymentMethodEnum.CASH]: PaymentMethodLabelEnum.CASH,
};
