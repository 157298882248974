export enum ROUTES {
  CHARGE = "/charge",
  MASSIVE_CHARGE = "/batch/charge",
  BILLING = "/billing",
  MASSIVE_BILLING = "/batch/billing",
  DISPERSION = "/dispersion",
  MASSIVE_DISPERSION = "/batch/dispersion",
  PROCESSING = "/processing",
  MASSIVE_RATES = "/batch/rates/",
  DISCOUNTS = "/discounts",
  MASSIVE_DISCOUNTS = "/batch/discounts/",
  RESUME = "/merchant-resume",
}

export enum OperationEnum {
  MASSIVE_EDITION = "massive-edition",
}

export const SPA_BASENAME: string = "/financial-configuration";
