import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const useSectionStyles = createNamedStyles({
  mainCard: {
    borderRadius: "16px",
    paddingX: "54px",
    paddingY: "38px",
    marginBottom: "8rem",
  },
  mainTitle: {
    marginBottom: "10px",
  },
  gridList: {
    paddingTop: "12px",
  },
});
