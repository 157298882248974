import { Box, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import React, { FC } from "react";
import { skeletonFormStyles } from "./SkeletonForm.styles";

export const SkeletonForm: FC = () => {
  const list = Array.from(Array(4).keys());

  return (
    <Box sx={skeletonFormStyles.boxContainer}>
      <Box sx={skeletonFormStyles.boxLeft}>
        <Skeleton height={25} />
        <Skeleton height={25} width="50%" />
      </Box>
      <Box sx={skeletonFormStyles.boxRight}>
        <List>
          {list.map((item: number) => (
            <ListItem key={item}>
              <ListItemText>
                <Box sx={skeletonFormStyles.boxColums}>
                  <Box sx={skeletonFormStyles.boxItem}>
                    <Skeleton height={25} width="55%" />
                    <Skeleton height={25} width="85%" />
                  </Box>
                  <Box sx={skeletonFormStyles.boxItem}>
                    <Skeleton height={25} width="55%" />
                    <Skeleton height={25} width="85%" />
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
