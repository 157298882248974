import React, { useEffect, useState } from "react";
import { DashboardTable } from "../DashboardTable/DashboardTable";
import { Box, Grid, Typography } from "@material-ui/core";
import { IColumnsTable } from "../../../shared/infrastructure/Table/ColumnsTable";
import { AuthenticationRules } from "../../../../types/response_search_rules_by_filters";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonDownloadFile } from "../../ButtonDownloadFile/ButtonDownloadFile";
import { get } from "lodash";

export interface DashboardTableSelectionProps {
  renderColumns: IColumnsTable[];
  data: AuthenticationRules[];
  handleCheckboxChange: (row: AuthenticationRules) => void;
  downloadFileState?: {
    isLoading: boolean;
    isLoadingList: boolean;
    downloadFile: (format: string) => void;
  };
}
const useStyles = makeStyles({
  boxFooter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  rootFooter: {
    width: "100%",
    margin: "0 !important",
    padding: "16px 24px !important",
    background: "white",
    borderRadius: "10px",
  },
  selecteds: {
    color: "#6D7781",
  },
});
export const DashboardTableSelection: React.FC<DashboardTableSelectionProps> = (
  props: DashboardTableSelectionProps
) => {
  const classes = useStyles();

  const [data, setData] = useState<AuthenticationRules[]>([]);

  const handleCheckboxChange = (index: number) => {
    props.handleCheckboxChange(props.data[index]);
  };

  const orderDataDashboard = (data: AuthenticationRules[]) => {
    setData(data);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <React.Fragment>
      <Box marginBottom={"50px"}>
        <DashboardTable
          mainTable={false}
          selectionMode={true}
          hideFilters={true}
          orderDataDashboard={orderDataDashboard}
          historicRulesData={{ records: data, total: data.length }}
          page={0}
          pageSize={0}
          handleChangePage={() => "void"}
          handleChangePageSize={() => "void"}
          hideNext={true}
          hidePrevious={true}
          tableColumns={{ renderColumns: props.renderColumns }}
          isAnalyst={false}
          checkboxActions={{ handleCheckboxChange: handleCheckboxChange }}
        />
        <Grid container spacing={3} className={classes.rootFooter}>
          <Grid xs={6}>
            <Box className={classes.boxFooter}>
              <Typography variant={"body2"} className={classes.selecteds}>
                {`Solicitudes seleccionadas: ${props.data.length}`}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box className={classes.boxFooter} justifyContent={"flex-end"}>
              <ButtonDownloadFile
                isLoading={get(props, "downloadFileState.isLoadingList", false)}
                downloadFile={get(props, "downloadFileState.downloadFileList")}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
