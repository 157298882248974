/* istanbul ignore file */

export enum LabelEnum {
  BANK = "transactionBank",
  SCHEDULE = "transactionCreated",
  BRAND = "transactionBrand",
  PROCESSOR = "transactionProcessor",
  CARD_TYPE = "transactionIsCreditCard",
  DEFERRED = "transactionIsDeferred",
  CARD_ISSUER = "transactionCardIssuer",
  CURRENCY = "transactionCurrency",
  COUNTRY = "transactionCountry",
  BIN = "transactionBIN",
  IP = "transactionIP",
  MASKED_CARD = "transactionMaskedCreditCard",
  KUSHKI_ID = "transactionCardId",
  AMOUNT = "transactionTotalAmount",
  CREDENTIAL = "transactionCredential",
  TRANSACTION_TYPE = "transactionType",
}
