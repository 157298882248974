import { Category } from "../BasicStateGeneral/CatalogBasicData";

export const companyTypeCo: string[] = [
  "Sociedad Limitada (Ltda)",
  "Sociedad Anónima (S.A.)",
  "Sociedad por Acciones Simplificada (S.A.S.)",
  "Sociedad en Comandita Simple (& cia S. en C.)",
  "Sociedad en Comandita por Acciones (& cia S.C.A.)",
  "Sociedad Colectiva (y compañía; hermanos; e hijos)",
  "Asociaciones o Corporaciones",
  "Fundaciones",
  "Entidades de Economía Solidaria",
  "Otro",
];

export enum documentTypeValuesEnumCO {
  NIT = "NIT",
  PASSPORT = "Pasaporte",
  CEDULA = "Cédula",
}

export const ICACatalogCo: Category[] = [
  {
    id: "ACO01",
    label: "Actividades financieras.",
  },
  {
    id: "ACO02",
    label: "Demás actividades comerciales.",
  },
  {
    id: "ACO03",
    label: "Demás actividades de servicios.",
  },
  {
    id: "ACO04",
    label: "Demás actividades industriales.",
  },
  {
    id: "ACO05",
    label: "Edición de libros.",
  },
  {
    id: "ACO06",
    label:
      "Fabricación de productos primarios de hierro y acero; fabricación de material de transporte.",
  },
  {
    id: "ACO07",
    label:
      "Producción de alimentos, excepto bebidas; producción de calzado y prendas de vestir.",
  },
  {
    id: "ACO08",
    label:
      "Servicio de restaurante, cafetería, grill, discoteca y similares; servicios de hotel, motel, hospedaje, amoblado y similares; servicio de casas de empeño y servicios de vigilancia.",
  },
  {
    id: "ACO09",
    label:
      "Transporte, publicación de revistas, libros y periódicos, radiodifusión y programación de televisión.",
  },
  {
    id: "ACO10",
    label:
      "Venta de alimentos y productos agrícolas en bruto; venta de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos.",
  },
  {
    id: "ACO11",
    label:
      "Venta de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas.",
  },
  {
    id: "ACO12",
    label:
      "Venta de maderas y materiales para construcción; venta de automotores (incluidas motocicletas).",
  },
];

export const documentTypeCO = [
  {
    name: documentTypeValuesEnumCO.CEDULA,
    value: "0",
  },
  {
    name: documentTypeValuesEnumCO.PASSPORT,
    value: "1",
  },
  {
    default: true,
    name: documentTypeValuesEnumCO.NIT,
    value: "3",
  },
];
