import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  createStyles,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Download } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import {
  b64toBlob,
  downloadBlob,
} from "../../../shared/infrastructure/constants/PdfViewerDownload";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() =>
  createStyles({
    centerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    stickyBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "sticky",
      top: "0px",
      zIndex: 3,
      backgroundColor: "rgb(90,90,90)",
    },
    backButton: {
      marginLeft: "10px",
      left: "4rem",
      top: "2rem",
    },
    alignLeft: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      color: "white",
    },
    alignRight: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "white",
    },
    whiteText: {
      color: "white",
    },
    toolTip: {
      backgroundColor: "rgb(222,226,231)",
      color: "#64798D",
      fontSize: "15px",
      fontWeight: 250,
      borderRadius: "3px",
      borderColor: "transparent",
      padding: "10px",
    },
  })
);

export interface PdfViewerProps {
  fileName: string;
  openModal: boolean;
  base64File: string;
  handleCloseModal: () => void;
}

export const PdfViewer: React.FC<PdfViewerProps> = (props: PdfViewerProps) => {
  const { handleCloseModal, openModal, fileName, base64File } = props;
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const hasNextPage = pageNumber < numPages;
  const hasPreviousPage = pageNumber - 1 !== 0;
  const onDocumentLoadSuccess = (obj: { numPages: number }) => {
    setNumPages(obj.numPages);
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };
  const handlePreviousPage = () => {
    setPageNumber(pageNumber - 1);
  };
  const handleDownloadFile = (file: Blob) => {
    const fileNameLength = props.fileName.length;
    const isValidExtension =
      fileName.slice(fileNameLength - 3, fileNameLength) === "pdf";

    if (isValidExtension) downloadBlob(file, fileName);
  };
  const file = b64toBlob(base64File);

  const classes = useStyles();
  return (
    <Modal
      open={openModal}
      style={{ overflow: "scroll" }}
      BackdropProps={{ style: { backgroundColor: "rgb(90,90,90)" } }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          className={classes.stickyBar}
          style={{ paddingTop: "71px" }}
        >
          <IconButton
            onClick={handleCloseModal}
            className={classes.backButton}
            style={{ position: "absolute" }}
          >
            <ArrowBackIosIcon className={classes.whiteText} />
          </IconButton>
          <Typography variant="body2" className={classes.whiteText}>
            {fileName}
          </Typography>
          <Tooltip
            classes={{ tooltip: classes.toolTip }}
            arrow
            title="Descargar"
            placement="top-start"
          >
            <IconButton
              onClick={() => handleDownloadFile(file)}
              style={{ marginLeft: "10px" }}
            >
              <Download className={classes.whiteText} size={18} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} className={classes.centerStyle}>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              scale={1.5}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
            />
          </Document>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={5} className={classes.alignLeft}>
            <IconButton hidden={!hasPreviousPage} onClick={handlePreviousPage}>
              <ArrowLeftIcon className={classes.whiteText} />
            </IconButton>
          </Grid>
          <Grid item xs={2} className={classes.centerStyle}>
            <Typography variant="body2" className={classes.whiteText}>
              Page {pageNumber} of {numPages}
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.alignRight}>
            <IconButton onClick={handleNextPage} hidden={!hasNextPage}>
              <ArrowRightIcon className={classes.whiteText} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
