import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Switch,
  Typography,
  FormGroup,
  FormControlLabel,
  Divider,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  SubTitleSection,
  Values,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { useFormContext, UseFormMethods } from "react-hook-form";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { securityRuleDetailStyles } from "../../containers/SecurityRuleDetail/SecurityRuleDetail.styles";
import { defaultTo, get, isUndefined } from "lodash";
import { SecurityRuleData } from "../../../types/security_rule_data";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";

const primaryColor = "#023365";

export type AdditionalConfigSectionProps = {
  form?: UseFormMethods<ISecurityRuleForm>;
};

export const AdditionalConfigSection = (_: AdditionalConfigSectionProps) => {
  const { watch, setValue, getValues, register } = useFormContext();
  const classes = securityRuleDetailStyles();
  register("ignore3DS");
  const [isIgnore3DS, setIsIgnore3DS] = useState<boolean>(
    Boolean(getValues("ignore3DS"))
  );
  const rule: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.rule
  );
  const provider = defaultTo(watch("provider"), "");

  useEffect(() => {
    if (isUndefined(rule)) {
      if (provider !== Values.IGNORE_3DS) setIsIgnore3DS(false);
      setValue("ignore3DS", false);
    }
  }, [provider]);

  useEffect(() => {
    setValue("ignore3DS", isIgnore3DS);
    setIsIgnore3DS(get(rule, "ignore3DS", false));
  }, []);

  return (
    <>
      {provider === Values.IGNORE_3DS && (
        <Grid container>
          <Grid container sx={{ mt: 3, mb: 1 }}>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Box>
              <Typography variant={"h6"}>
                {SubTitleSection.ADDITIONAL_INFO}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant={"body2"} color={"text.dark"}>
                {SubTitleSection.ADDITIONAL_INFO_SUBTITLE}
              </Typography>
            </Box>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={"auto"}>
              <Box display={"flex"}>
                <FormGroup>
                  <FormControlLabel
                    style={{
                      marginLeft: "0px",
                    }}
                    defaultChecked={false}
                    control={
                      <Switch
                        name={"ignore3DS"}
                        color="primary"
                        size="small"
                        checked={isIgnore3DS}
                        value={isIgnore3DS}
                        onChange={(e) => {
                          setIsIgnore3DS(e.target.checked);
                          setValue("ignore3DS", !isIgnore3DS);
                        }}
                      />
                    }
                    label={""}
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={"auto"}>
              <Box pl={2}>
                <Typography variant={"overline"} color={"text.dark"}>
                  {SubTitleSection.ASSUME_TRANSACTIONAL_RISK}
                </Typography>
                <Box display="flex" mt={1}>
                  <Box>
                    <InfoOutlinedIcon
                      style={{ color: primaryColor, marginRight: "3px" }}
                    />
                  </Box>
                  <Box>
                    <Typography variant={"overline"} color={"text.dark"}>
                      {SubTitleSection.ASSUME_TRANSACTIONAL_RISK_DESCRIPTION}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
