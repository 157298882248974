import {
  HeaderCellProps,
  ITableCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { CELL_TYPES } from "./DispersionBlockConstants";
import { ICellText } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";

export const TEXT_CELL = (line1: string = "", line2?: string) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      line2,
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

export const FLAG_CELL = (country: string) => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 1,
    },
    country,
    type: "twolines",
  },
  type: "FLAG",
});

export const TITLE_CELL = (line1: string) => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 1,
    },
    line1,
    type: "oneLine",
  },
  type: "TITLE",
});

export const TABLE_ROW = {
  [CELL_TYPES.FLAG]: FLAG_CELL,
  [CELL_TYPES.TITLE]: TITLE_CELL,
  [CELL_TYPES.TEXT]: TEXT_CELL,
};

export enum DiscountProperties {
  ALIAS = "alias",
  RATE_TYPE = "rateType",
  PAYMENT_METHOD = "paymentMethod",
  FIXED_AMOUNT = "fixedAmount",
  VARIABLE_AMOUNT = "variableAmount",
  MINIMUM_AMOUNT = "minimumAmount",
  MAXIMUM_AMOUNT = "maximumAmount",
}

export const ALL_TABLE_COLUMNS_DISCOUNT: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.ALIAS,
    spacing: 0,
    text: "Alias",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.RATE_TYPE,
    spacing: 0,
    text: "Tipo tarifa",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.PAYMENT_METHOD,
    spacing: 0,
    text: "M. de pago/Serv.",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.FIXED_AMOUNT,
    spacing: 0,
    text: "Monto fijo",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.VARIABLE_AMOUNT,
    spacing: 0,
    text: "Monto variable",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.MINIMUM_AMOUNT,
    spacing: 0,
    text: "Monto mínimo",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: DiscountProperties.MAXIMUM_AMOUNT,
    spacing: 0,
    text: "Monto máximo",
  },
];

export enum ratesTypesEnum {
  PAY_IN = "Pay In",
  PAY_OUT = "Pay Out",
  ADDITIONAL_SERVICES = "Servicio adicional",
}

export const RATES_TYPE = {
  additionalServices: ratesTypesEnum.ADDITIONAL_SERVICES,
  payIn: ratesTypesEnum.PAY_IN,
  payOut: ratesTypesEnum.PAY_OUT,
};

export enum paymentMethodEnum {
  CARD = "Tarjeta",
  CASH = "Efectivo",
  TRANSFER = "Transferencia",
  "3ds" = "3DS",
  SIFTSCIENCE = "SiftScience",
  OTP = "OTP",
  TRANS_UNION = "TransUnion",
}

export const PAYMENT_METHOD = {
  "3ds": paymentMethodEnum["3ds"],
  card: paymentMethodEnum.CARD,
  cash: paymentMethodEnum.CASH,
  otp: paymentMethodEnum.OTP,
  siftScience: paymentMethodEnum.SIFTSCIENCE,
  transfer: paymentMethodEnum.TRANSFER,
  transUnion: paymentMethodEnum.TRANS_UNION,
};

export enum discountTypesEnum {
  PERCENTAGE = "Porcentaje",
  START_DATE = "Fecha de inicio",
  END_DATE = "Fecha de finalización",
}
