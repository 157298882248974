import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";
import { Link } from "react-router-dom";

export interface SnackbarAlertProps {
  msg: string;
  open: boolean;
  type: Color;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
  hasLink?: boolean;
}

export const SnackBarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { open, handleClose } = useSnackBarAlertState(props);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={props.handlerClose}
          severity={props.type}
          style={props.type === "info" ? { background: "#293036" } : undefined}
          action={
            props.hasLink ? (
              <Link
                style={{ color: "#FFFFFF", textDecoration: "underline" }}
                to="/webhook/notification"
              />
            ) : null
          }
        >
          {props.msg}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};
