import React from "react";
import { Chip } from "@material-ui/core";
import { deferredTableStyle } from "./DeferredTable.style";

export interface ChipMonthsProps {
  months: string[];
  listType: string;
  rowIndex: number;
  handleEvents: {
    handleOpenMonthsModal: (months: string[]) => void;
  };
}

export const ChipMonths: React.FC<ChipMonthsProps> = (
  props: ChipMonthsProps
) => {
  const { months, listType, rowIndex, handleEvents } = props;
  const classes = deferredTableStyle();

  return (
    <>
      {months
        .sort(function (a: string, b: string) {
          return Number(a) - Number(b);
        })
        .slice(0, 4)
        .map((month: string, index: number) => (
          <Chip
            key={`${index}_.${month}`}
            className={classes.merchantMonthsStyle}
            label={month}
          />
        ))}
      {months.length > 4 && (
        <Chip
          id={`key_more_${listType}_${rowIndex}`}
          key={`key_more_${listType}_${rowIndex}`}
          onClick={() => {
            handleEvents.handleOpenMonthsModal(months);
          }}
          className={classes.merchantMonthsStyle}
          label="..."
        />
      )}
    </>
  );
};
