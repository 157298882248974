import { Box, Button } from "@mui/material";
import React, { FC } from "react";
import { DetailFooterStyles as style } from "./DetailFooter.styles";
import AddIcon from "@mui/icons-material/Add";
import CreateCommentModal from "../../CreateCommentModal/CreateCommentModal";
import { COMMENTS_TAB_LABELS } from "../../../../shared/constants/comments_tab_labels";
import { ITransactionTab } from "../../../../shared/interfaces/TransactionModal.interfaces";
import { useDetailFooterState } from "./state/DetailFooter.state";

const DetailFooter: FC<ITransactionTab> = ({
  transaction,
}: ITransactionTab) => {
  const {
    isCoordinator,
    isSaving,
    openModal,
    actions: { handleClose, handleOpen, handleSave },
  } = useDetailFooterState(transaction.transactionReference!);

  return (
    <>
      <Box sx={style.box}>
        {isCoordinator && (
          <Button
            sx={style.addCommentBtn}
            startIcon={<AddIcon />}
            onClick={handleOpen}
            disabled={isSaving}
          >
            {COMMENTS_TAB_LABELS.ADD_COMMENT}
          </Button>
        )}
      </Box>
      <CreateCommentModal
        handleClose={handleClose}
        handleSave={handleSave}
        isSaving={isSaving}
        open={openModal}
      />
    </>
  );
};

export default DetailFooter;
