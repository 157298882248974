/* istanbul ignore file */
import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";

export enum LabelEnum {
  BANK = "transactionBank",
  BRAND = "transactionBrand",
  PROCESSOR = "transactionProcessor",
  CARD_ID = "transactionCardId",
  CARD_TYPE = "transactionIsCreditCard",
  DEFERRED = "transactionIsDeferred",
  CARD_ISSUER = "transactionCardIssuer",
  CURRENCY = "transactionCurrency",
  COUNTRY = "transactionCountry",
  BIN = "transactionBIN",
  EMAIL = "transactionEmail",
  CHANNEL = "transactionChannel",
  MCC = "transactionMCC",
  IP = "transactionIP",
  COMMERCE_SCHEDULE = "transactionCommerceSchedule",
  MASKED_CARD = "transactionMaskedCreditCard",
  AMOUNT = "transactionTotalAmount",
  CREDENTIAL = "transactionCredential",
  AFFILIATION_NUMBER = "transactionAfiliationNumber",
  TRANSACTION_TYPE = "transactionType",
  TRANSACTION_SCHEDULE = "transactionSchedule",
  TRANSACTION_COMMERCE_SCHEDULE = "transactionCommerceSchedule",
  TRANSACTION_DATE = "transactionDate",
  PHONE_NUMBER = "transactionPhoneNumber",
  MERCHANT_CATEGORY = "transactionMerchantCategory",
  PROCESSOR_CODE = "transactionProcessorCode",
  SECURE_SERVICE = "transactionSecureService",
  PENDING_REVIEW = "Pendientes de revisión",
  FIRST_LEVEL_RULES = "Reglas de primer nivel",
  WHITE_LISTS = "Listas blancas",
  CONDITION_TAG = "Etiqueta",
  CONDITION_OPERATOR = "Operador",
  CONDITION_VALUE = "Valor",
  REJECTED_APPLICATIONS = "Solicitudes Rechazadas",
  REJECTED_RULES = "Regla rechazada",
  GENERAL_CONDITION_RULES = "Condición general",
  SPECIAL_CONDITION_RULES = "Condiciones especiales",
  SPEED_CONDITION_RULES = "Condición de velocidad",
  GENERAL_INFO_RULES = "Información general",
  GENERAL_INFO_RULES_COUNTRY = "Pais(es)",
  GENERAL_INFO_RULES_TYPE_MERCHANT = "Tipo de Comercio",
  GENERAL_INFO_RULES_PAYMENT_METHOD = "Medio de pago",
  GENERAL_INFO_RULES_NAME_MERCHANT = "Nombre del comercio",
  GENERAL_INFO_RULES_MERCHANT_ID = "ID del comercio",
  GENERAL_INFO_RULES_APPROVAL_DATE = "Fecha de aprobación",
  GENERAL_INFO_RULES_CREATED_BY = "Creada por",
  GENERAL_INFO_RULES_LAST_EDITION = "Última edición",
  GENERAL_INFO_WHITELIST_APPROVED_BY = "Ultima edición por",
  APPROVED_BY = "Aprobada por",
  CREATED_BY = "Creada por",
  CONFIG_INFO_TITLE = "Configuración",
  CONFIG_INFO_ACTION = "Acción",
  CONFIG_INFO_PROVIDER = "Proveedor",
  CONFIG_INFO_CODE = "Código",
  CONFIG_INFO_MESSAGE = "Mensaje",
  GENERAL_ADDITIONAL_CONFIG = "Configuración adicional",
  GENERAL_ADDITIONAL_TAKE_RISK = "Asumir riesgo transaccional",
  REJECTED_BY = "Rechazada por",
  REJECTED_LIST_DATE = "Fecha de desactivación",
  REJECTED_REASON = "Motivo de rechazo",
  REJECTED_CHIP = "RECHAZADA",
  ENABLE_CHIP = "HABILITADA",
  DISABLE_CHIP = "DESHABILITADA",
  SHIPPING_ADDRESS = "transactionShippingAddress",
  BILLING_ADDRESS = "transactionBillingAddress",
  DOCUMENT_NUMBER = "transactionDocumentNumber",
  CARD_HOLDER = "transactionCardHolderName",
  METADATA = "transactionMetadata",
  NO_APPLY = "No aplica",
  APPROVE_RULE = "Aprobar",
  REJECT_RULE = "Rechazar",
  EMPTY_TABLE = "No se encontró ningún resultado",
  DISABLE_RULE = "Regla deshabilitada",
  DISABLED_LIST = "Lista deshabilitada",
  DISABLE_REASON = "Motivo de deshabilitación",
  DISABLE_BY = "Deshabilitada por",
  DISABLE_DATE = "Fecha de deshabilitación",
  DATE_CREATED = "Fecha y hora de creación",
  FILTER_MODAL_TITLE = "Filtrar por",
  FILTER_DATA_PICKER = "Buscar por Fecha",
  BUTTON_DISABLE_RULE = "Deshabilitar",
  BUTTON_APPROVE_RULE = "Aprobar",
  BUTTON_CANCEL = "Cancelar",
  BUTTON_EDIT_RULE = "Editar regla",
  DELETE_RULE_BUTTON = "Eliminar regla",
  BUTTON_EDIT_LIST = "Editar lista",
  RIGHT_BUTTON = "Right button",
  LEFT_BUTTON = "Left button",
  IS_FIXED = "CORRECCIÓN DE SOLICITUD",
  NEW_REQUEST = "NUEVA SOLICITUD",
  EDITION = "EDICIÓN",
  REACTIVATION = "REACTIVACIÓN",
  EMPTY_TABLE_BODY = "Intente con otros valores de filtro",
  LIST_TYPE = "Tipo de lista",
  GENERAL_CONDITION = "Condición General",
  PLACEHOLDER_FILTER_COUNTRY = "Seleccione uno o más países",
  PLACEHOLDER_RULE_NAME = "Nombre de la regla",
  FILTER_COUNTRY_LABEL = "Pais",
  FILTER_COMMERCE_LABEL = "Comercio",
}

export const GENERAL_CONDITION_EXCLUDED_LABEL: string[] = [
  LabelEnum.CREDENTIAL,
  LabelEnum.PROCESSOR,
  LabelEnum.AFFILIATION_NUMBER,
  LabelEnum.TRANSACTION_DATE,
  LabelEnum.COMMERCE_SCHEDULE,
];

export const GENERAL_CONDITIONS_WITH_COMMA_SEPARATOR: string[] = [
  LabelEnum.COUNTRY,
];

const enum HeaderTextColumn {
  CREATED_DATE = "Fecha de creación",
  MERCHANT_TYPE = "Tipo de comercio",
  HIERARCHY_LEVEL = "Nivel Jerarquía",
  CONFIGURATION_TYPE = "Tipo de configuración",
  TYPE = "Tipo",
  MERCHANT_NAME = "Nombre del Comercio",
  COUNTRY = "País",
  RULE_NAME = "Nombre de la regla",
  ACTION = "Acción",
  STATUS = "Estado",
  EDITION = "Editar",
  CONDITION = "Condición",
  QUANTITY = "Cantidad",
}

export const TABLE_COLUMNS: Record<string, HeaderCellProps[]> = {
  [LabelEnum.PENDING_REVIEW]: [
    {
      align: "left",
      spacing: 1,
      text: HeaderTextColumn.CREATED_DATE,
      type: "default",
      width: 700,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.HIERARCHY_LEVEL,
      type: "default",
      width: 500,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.CONFIGURATION_TYPE,
      type: "default",
      width: 700,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.TYPE,
      type: "default",
      width: 500,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.MERCHANT_NAME,
      type: "default",
      width: 1200,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.COUNTRY,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: "",
      type: "default",
    },
  ],
  [LabelEnum.FIRST_LEVEL_RULES]: [
    {
      align: "left",
      spacing: 1,
      text: HeaderTextColumn.CREATED_DATE,
      type: "default",
      width: 300,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.TYPE,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.MERCHANT_NAME,
      type: "default",
      width: 400,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.RULE_NAME,
      type: "default",
      width: 400,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.ACTION,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.COUNTRY,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.STATUS,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.EDITION,
      type: "default",
    },
  ],
  [LabelEnum.REJECTED_APPLICATIONS]: [
    {
      align: "left",
      spacing: 1,
      text: HeaderTextColumn.CREATED_DATE,
      type: "default",
      width: 700,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.HIERARCHY_LEVEL,
      type: "default",
      width: 500,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.CONFIGURATION_TYPE,
      type: "default",
      width: 700,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.TYPE,
      type: "default",
      width: 500,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.MERCHANT_NAME,
      type: "default",
      width: 1200,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.COUNTRY,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.EDITION,
      type: "default",
    },
  ],
  [LabelEnum.WHITE_LISTS]: [
    {
      align: "left",
      spacing: 1,
      text: HeaderTextColumn.CREATED_DATE,
      type: "default",
      width: 700,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.TYPE,
      type: "default",
      width: 500,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.MERCHANT_NAME,
      type: "default",
      width: 700,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.COUNTRY,
      type: "default",
    },
    {
      align: "center",
      spacing: 0,
      text: HeaderTextColumn.CONDITION,
      type: "default",
    },
    {
      align: "center",
      spacing: 0,
      text: HeaderTextColumn.QUANTITY,
      type: "default",
    },
    {
      align: "center",
      spacing: 0,
      text: HeaderTextColumn.STATUS,
      type: "default",
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.EDITION,
      type: "default",
    },
  ],
};

export const enum TextModalConfirm {
  TITLE_DEFAULT = "¿Deseas guardar esta regla?",
  TITLE_RULE_DUPLICATE = "Regla duplicada",
  DESCRIPTION_DEFAULT = "Los datos de esta regla quedarán registrados.",
  DESCRIPTION_RULE_DUPLICATE = "Los datos de la regla que estás intentando crear coinciden con los datos de la regla ",
  DESCRIPTION_RULE_DUPLICATE_QUESTION = ". ¿Qué deseas hacer con esta regla duplicada?",
  EDIT_RULE = "Editar regla",
  ACCEPT = "Aceptar",
  EDIT = "Editar",
}

export interface IModalConfirmTemplate {
  titleText: string;
  descriptionText: string;
  buttonPrimary: string;
  buttonSecondary?: string;
  onClickPrimary: () => void;
  onClickSecondary: () => void;
}

export enum ListCatalogEnum {
  GENERAL_ACTION = "Acción general",
  PROVIDER = "Proveedor",
  CODE = "Código",
  MESSAGE = "Mensaje",
}
