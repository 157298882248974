export enum KindEnum {
  INVOICE = "invoice",
  CHARGE = "charge",
  RECEIVABLE = "receivable",
  DISPERSION = "dispersion",
  RETENTION = "retention_dispersion",
  RETENTION_TRX = "retention",
  CREDIT_NOTE = "credit_note",
  DEBIT_NOTE = "debit_note",
  VOUCHER = "voucher",
  ANNUL_INVOICE = "annul_invoice",
}
