import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useAppSelector } from "../../../store/hooks/storeHook";

import { RootState } from "../../../store/store";
import { IUseBranchContainer } from "./IUseBranchListContainer.interfaces";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { useLocation, useNavigate } from "react-router-dom";
import { SessionStorageEnum } from "../../../shared/enum/SessionStorageEnum";
import { setItem } from "../../../shared/utils/SessionStorageUtils";
import { QueryParamEnum } from "../../../shared/enum/QueryParamEnum";

export const useBranchListContainer = (): IUseBranchContainer => {
  let location = useLocation();
  let navigate = useNavigate();

  const [customerList, setCustomerList] = useState<SearchMerchantResponse>({
    data: [],
    total: 0,
  });

  const { merchantInfo, paginationData, selectedData } = useAppSelector(
    (state: RootState) => ({
      ...state.generalData,
    })
  );

  const handleContinue = () => {
    setItem(SessionStorageEnum.BRANCH_LIST, selectedData);

    navigate(`/form${location.search}`);
  };

  const handleReturn = () => {
    window.location.href = `merchant-resume${location.search}&${QueryParamEnum.MODE}=${QueryParamEnum.EDITION}`;
    sessionStorage.removeItem(SessionStorageEnum.BRANCH_LIST);
  };

  useEffect(() => {
    const isBranchData: boolean = get(merchantInfo, "data", []).every(
      (merchant) => merchant.entity_name === EntityEnum.BRANCH
    );

    if (isBranchData) setCustomerList(merchantInfo);
  }, [merchantInfo]);

  return {
    customerList,
    primaryButton: {
      isDisabled: isEmpty(selectedData),
      isHidden: false,
      onAction: handleContinue,
    },
    rowsPerPage: get(paginationData, "rowsPerPage", 0),
    secondaryButton: {
      isDisabled: false,
      isHidden: false,
      onAction: handleReturn,
    },
    showEmptyScreen: isEmpty(get(merchantInfo, "data", [])),
  };
};
