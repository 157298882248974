import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { searchMerchantNodeInfoDefaultResponse } from "../../../shared/constants/initial_state_wrapper_container";
import { THUNK_PREFIXES } from "../../../shared/constants/thunk_prefixes";
import { INodeInfoResponse } from "../../../../types/node_info_response";
import { GetNodeInfoRequest } from "../../interfaces/generalData.interfaces";
import { get } from "lodash";

import { verifyCofigsComplete } from "../../../shared/utils/usersUtils";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import { AxiosResponse } from "axios";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";

export const searchMerchantNodeInfo = createAsyncThunk(
  THUNK_PREFIXES.SEARCH_MERCHANT_NODE_INFO,
  async (query: SearchMerchantRequest) => {
    try {
      const response: AxiosResponse<SearchMerchantResponse> = await axios.post(
        API_ROUTES.SEARCH_MERCHANT_NODE_INFO,
        query,
        {
          headers: {
            Authorization: getJwtAuth(),
            "Content-Type": "application/json",
          },
        }
      );

      if (query.entityName === EntityEnum.CUSTOMER) {
        return response.data;
      }

      const completedBranches = await verifyCofigsComplete(
        get(response, "data.data")
      );

      return {
        ...response.data,
        data: completedBranches,
      };
    } catch (e) {
      return searchMerchantNodeInfoDefaultResponse;
    }
  }
);

export const getNodeInfo = createAsyncThunk<
  INodeInfoResponse,
  GetNodeInfoRequest
>(THUNK_PREFIXES.GET_NODE_INFO, async (request) => {
  const response = await axios.post<INodeInfoResponse>(
    API_ROUTES.GET_NODE_INFO,
    request,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const getHierarchyNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  { merchantId: string }
>(THUNK_PREFIXES.HIERARCHY_GET_NODE_INFO, async ({ merchantId }) => {
  return (
    await axios.get<GetNodeInfoResponse>(
      `${API_ROUTES.HIERARCHY_GET_NODE_INFO}${merchantId}`
    )
  ).data;
});
