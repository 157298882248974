import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const CardListStyles: IStyles = {
  border: {
    "&.MuiDivider-root": {
      border: 0,
      borderBottom: "1px solid #CBD5E0",
      margin: "24px",
    },
  },
  boxContainerSkeleton: {
    pl: 3,
    pr: 3,
    pt: 3,
  },
  boxContainerSkeletonMobile: {
    pl: 2,
    pr: 2,
    pt: 2,
  },
  description: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionLabel: {
    pl: 3,
  },
  descriptionLabelMobile: {
    color: "text.grey",
    pl: 2,
    typography: "caption",
  },
  descriptionMobile: {
    display: "block",
    pb: 1,
  },
  descriptionMobileSkeleton: {
    display: "flex",
  },
  descriptionLabelSkeleton: {
    height: "20px",
    width: { md: "93px", sm: "79px", xs: "55px" },
  },
  descriptionLabelVertical: {
    pl: 3,
    typography: "caption",
  },
  descriptionSecondaryText: {
    pb: { md: 1, xs: 0 },
  },
  descriptionSecondaryTextMobile: {
    pb: 0,
    pl: 2,
    pt: 0,
    typography: "overline",
  },
  descriptionSecondaryTextVertical: {
    pb: { md: 1, xs: 0 },
    pl: 3,
    typography: "overline",
  },
  descriptionText: {
    pr: 3,
  },
  descriptionTextMobile: {
    pb: 1,
    pl: 2,
  },
  descriptionTextSkeleton: {
    height: "20px",
    width: { md: "298px", sm: "180px", xs: "120px" },
  },
  descriptionTextVertical: {
    pb: "20px",
    pl: 3,
    pt: "20px",
  },
  descriptionVertical: {
    display: "block",
    pb: 3,
  },
  mainBox: {
    justifyContent: "flex-start",
    minWidth: "500px",
    width: "100%",
  },
  mainBoxMobile: {
    justifyContent: "flex-start",
    minWidth: "342px",
    width: "100%",
  },
  mainBoxMobileSkeleton: {
    display: "block",
    width: "100%",
  },
  skeletonBody: {
    width: { md: "107px", sm: "98px", xs: "65px" },
  },
  skeletonFooter: {
    width: { md: "138px", sm: "90px", xs: "55px" },
  },
  skeletonMobile: {
    paddingRight: { md: 10, sm: 8, xs: 5 },
  },
  skeletonMobileRectangle: {
    paddingRight: { md: 19, sm: 8, xs: 8 },
  },
  skeletonRectangle: {
    paddingRight: { md: 10, sm: 4, xs: 2 },
  },
  skeletonRectangleMobile: {
    paddingRight: { md: 20, sm: 10, xs: 10 },
  },
  title: {
    pb: "24px",
    pl: "24px",
    pt: "24px",
    textAlign: "start",
  },
  titleMobile: {
    pb: "16px",
    pl: "16px",
    pt: "16px",
    textAlign: "start",
  },
  titleSkeletonMobile: {
    height: "20px",
    width: { md: "115px", sm: "112px", xs: "80px" },
  },
};

export { CardListStyles };
