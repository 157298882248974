import React from "react";
import Box from "@mui/material/Box";
import BranchesResumeContainer from "./BranchesResumeContainer/BranchesResumeContainer";
import { Button, Grid, Typography } from "@mui/material";
import { BranchResumeStyles } from "./BranchesResume.styles";
import useBranchesResume from "./state/useBranchesResume";
import { routes } from "../../shared/infrastructure/constants/routes";

const BranchesResume = () => {
  const { branchesResponse, onClickRedirect } = useBranchesResume();

  return (
    <Box>
      <Typography sx={BranchResumeStyles.subtitle}>Últimos Creados:</Typography>
      <Grid container>
        <Grid item xs={9}>
          {branchesResponse && branchesResponse.total > 0 && (
            <Box>
              <BranchesResumeContainer branches={branchesResponse?.data} />
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          paddingRight={5}
        >
          <Button
            data-testid="redirectButton"
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => onClickRedirect(routes.BRANCHES_CUSTOMER)}
          >
            Ver todo ({branchesResponse?.total})
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BranchesResume;
