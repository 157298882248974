import React from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";
import { AlertInformation } from "../AlertInformation/AlertInformation";
import { useDeferredScheduledState } from "./state/useDeferredScheduledState";
import {
  DeferredAdvancedSettingsSectionConstants,
  DeferredAlertInformation,
} from "../../shared/infrastructure/constants/DeferredConstants";
import { useDeferredScheduleStyles } from "./DeferredScheduled.style";
import { IDeferredScheduled } from "./DeferredScheduled.interface";

export const DeferredScheduled: React.FC<IDeferredScheduled> = (
  props: IDeferredScheduled
) => {
  const { showMessage, actions } = useDeferredScheduledState({
    handleRangeDate: props.handleRangeDate,
  });
  const classes = useDeferredScheduleStyles();

  return (
    <div className={classes.paddingContent}>
      <Grid container>
        <Grid container spacing={1} style={{ alignItems: "center" }}>
          <Grid item>
            <FormControlLabel
              defaultChecked={false}
              className={classes.formControlContent}
              control={
                <Switch
                  color="primary"
                  size="medium"
                  value={props.stateSwitch}
                  checked={props.stateSwitch}
                  onChange={(evt) =>
                    props.handleChangeSwitch(evt.target.checked)
                  }
                />
              }
              label={""}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.titleContent}>
              {DeferredAdvancedSettingsSectionConstants.DEFERRED_SCHEDULE_TITLE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {props.stateSwitch && (
        <Grid container xs={12} style={{ paddingTop: "16px" }}>
          <AlertInformation
            message={
              <Grid container style={{ alignItems: "center" }}>
                <Typography className={classes.descriptionAlert}>
                  {
                    DeferredAlertInformation.DEFERRED_SCHEDULE_INFORMATION_DESCRIPTION
                  }
                </Typography>
                <Typography>&nbsp;</Typography>
                <Typography className={classes.descriptionAlertBold}>
                  {
                    DeferredAlertInformation.DEFERRED_SCHEDULE_INFORMATION_DESCRIPTION_BOLD
                  }
                </Typography>
              </Grid>
            }
            messageSecondLine={
              <Grid container style={{ alignItems: "center" }}>
                <Typography className={classes.descriptionAlertBold}>
                  {
                    DeferredAlertInformation.DEFERRED_DEFERRED_SCHEDULE_INFORMATION_ALERT
                  }
                </Typography>
              </Grid>
            }
            showMessage={showMessage}
          />
          <Grid className={classes.gridContainer} item xs={6}>
            <FormControlLabel
              defaultChecked={false}
              className={classes.formControlContent}
              control={
                <KshDateRangePicker
                  handleDate={actions.handleRangeDate}
                  disableFuture={false}
                  disablePast={true}
                  value={props.rangeSchedule}
                />
              }
              label={""}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
