import { useEffect, useState } from "react";
import { MONTHS_RECORD } from "../../../../../shared/infrastructure/constants/RuleRequestManagerConstants";

export interface OnChangeTimePickerTagState {
  onChange(value: string[]): void;
}

export interface DateState {
  value: string;
  type: string;
}

export interface IUseDateComponentSection {
  concatenateTimeStamp(value: DateState): void;
}

let startDate = "";
let endDate = "";

export const useDateComponentSection = (
  props: OnChangeTimePickerTagState
): IUseDateComponentSection => {
  const [transactionDate, setTransactionDate] = useState<string[]>([]);

  useEffect(() => {
    props.onChange(transactionDate);
  }, [transactionDate]);

  const concatenateTimeStamp = (props: DateState): void => {
    switch (props.type) {
      case "start":
        startDate = calculateTimeStamp(props);
        break;
      default:
        endDate = calculateTimeStamp(props);
        break;
    }

    setTransactionDate([startDate, endDate]);
  };

  const calculateTimeStamp = (props: DateState): string => {
    const dateList: string[] = props.value.split("/");
    let formatDate: string = `${dateList[2]}-${MONTHS_RECORD[dateList[1]]}-${
      dateList[0]
    }`;
    if (props.type === "start") {
      formatDate = `${formatDate}T23:59:59.000Z`;
    } else {
      formatDate = `${formatDate}T23:59:59.000Z`;
    }
    const date = new Date(formatDate);

    return date.getTime().toString();
  };

  return {
    concatenateTimeStamp,
  };
};
