export enum ConciliationActionEnum {
  OMITTED = "omitted",
  PENDING_REFUND = "pending_refund",
  REVIEW_PROCESSOR = "review_processor",
  REVIEW_KUSHKI = "review_kushki",
  PENDING = "pending",
  CONCILIATED = "conciliated",
  DCLGBCK_FNPS = "DCLGBCK_FNPS",
  DCLRFN_PRCS = "DCLRFN_PRCS",
  APRGBCK_FNPS = "APRGBCK_FNPS",
  APRRFN_PRCS = "APRRFN_PRCS",
}
