import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import {
  getMerchantCredentials,
  getMerchantWebhooks,
} from "../../../store/actionCreators";
import { SectionContent } from "./SectionContent";

const useStyles = makeStyles({
  root: {
    padding: "32px",
    flexGrow: 1,
  },
  section: {
    backgroundColor: "#F7FAFC",
  },
  divider: {
    width: "16px",
  },
});

export function DevelopersSection() {
  const classes = useStyles();
  const { publicMerchantId, credentials = [], webhooks = [] } = useSelector(
    (state: IAppState) => ({
      publicMerchantId: get(state, "merchantAffiliation.publicMerchantId"),
      credentials: get(state, "merchantCredentials", []),
      webhooks: get(state, "merchantWebhooks", []),
    })
  );

  if (!publicMerchantId) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs className={classes.section}>
        <SectionContent
          publicMerchantID={publicMerchantId}
          items={credentials}
          fetchItems={(merchantId) => getMerchantCredentials(merchantId)}
          labelPlural={"Credenciales"}
          newItemURL={`/credentials/${publicMerchantId}?hideSideBar=true`}
          allItemsURL={`/credentials/${publicMerchantId}?hideSideBar=true`}
          allTheItemsMessage={"todas las credenciales"}
        />
      </Grid>
      <Grid item className={classes.divider} />
      <Grid item xs className={classes.section}>
        <SectionContent
          publicMerchantID={publicMerchantId}
          items={webhooks}
          fetchItems={(merchantId) => getMerchantWebhooks(merchantId)}
          labelPlural={"Webhooks"}
          newItemURL={`/webhook/${publicMerchantId}/create?hideSideBar=true`}
          allItemsURL={`/webhook/${publicMerchantId}?hideSideBar=true`}
          allTheItemsMessage={"todos los webhooks"}
        />
      </Grid>
    </Grid>
  );
}
