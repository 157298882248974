import { environment } from "../../environments/environment";

export const API_ROUTES = {
  MASSIVE_SERVICES_CONFIG: `${environment.kushkiUrl}/card-massive-rule-management/v1/massive/services`,
  MERCHANT_CARD: `${environment.kushkiUrl}/card/v1/admin/merchant/`,
  MERCHANT_COMMISSION: `${environment.kushkiUrl}/commission/v1/admin/merchant/`,
  MERCHANT_PROCESSORS: (merchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}/processors`,
  MERCHANT_SMARTLINKS: `${environment.kushkiUrl}/smartlink/v1/admin/merchant/`,
  MERCHANT_SUBSCRIPTION: `${environment.kushkiUrl}/v1/subscriptions/admin/merchant/`,
  MERCHANT_VPOS: `${environment.kushkiUrl}/vpos/v1/admin/merchant/`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  PAYMENT_CREDENTIALS: `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/`,
  SERVICES_DEFAULT: `${environment.kushkiUrl}/card/v1/admin/merchant/services/default`,
  UPDATE_HIERARCHY_NODE_CONFIG: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
};
