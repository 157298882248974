import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ILegalRepresentativesProps } from "./LegalRepresentatives.interfaces";
import { legalRepresentativesStyles } from "./LegalRepresentatives.styles";
import LegalRepresentativesForm from "../LegalRepresentativesForm/LegalRepresentativesForm";

const LegalRepresentatives: FC<ILegalRepresentativesProps> = ({
  control,
  errors,
  setValue,
  getValues,
}) => {
  return (
    <Box sx={legalRepresentativesStyles.boxContainer}>
      <Box sx={legalRepresentativesStyles.boxTitle}>
        <Typography sx={legalRepresentativesStyles.title}>
          Representantes legales
        </Typography>
        <Typography sx={legalRepresentativesStyles.subtitle}>
          Ingresa los datos de los representantes legales
        </Typography>
      </Box>
      <Box sx={legalRepresentativesStyles.boxContainerForm}>
        <LegalRepresentativesForm
          form={{
            control,
            errors,
            getValues,
            setValue,
          }}
        />
      </Box>
    </Box>
  );
};

export default LegalRepresentatives;
