import { filter, get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Conciliation } from "../../../../types/get_conciliation_transactions_response";
import { KeyValue } from "../../../../types/key_value";
import { TransactionsTableConstants } from "../../../shared/infrastructure/constants/TransactionsTableConstants";
import {
  TRANSACTION_STATUS_TRANSLATE,
  TransactionStatus,
} from "../../../shared/infrastructure/constants/TransactionStatus";
import {
  setAccumulatedAmount,
  setRowsSelected,
} from "../../../store/actions/app/app";
import {
  IState,
  ITransactionTableState,
  ITransactionTableStateProps,
} from "./useTransactionTableState.interfaces";
import {
  ALLOWED_FRANCHISES,
  FranchiseEnum,
} from "../../../shared/enums/franchise_enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store.interfaces";
import { useKshRedux } from "../../../store/hooks/hooks";

export type IKeyValueOrder = { order: number } & KeyValue;

export const defaultSelectedColumns = [
  "created",
  "merchantDescription",
  "transactionType",
  "approvedTransactionAmount",
  "transactionStatusKushki",
  "transactionStatusProcessor",
];

export const useTransactionTableState = (
  props: ITransactionTableStateProps
): ITransactionTableState => {
  const { isSuccessUpdate } = useSelector((state: RootState) => state.app);
  const { bodyFilter } = useSelector((state: RootState) => state.filters);
  const {
    actions: { getConciliationList },
  } = useKshRedux();
  const dispatch = useDispatch();
  const [state, setState] = useState<IState>({
    headerColumns: TransactionsTableConstants.sortValuesSelectedColumns(
      defaultSelectedColumns
    ),
    selectedColumns: TransactionsTableConstants.sortKeysSelectedColumns(
      defaultSelectedColumns
    ),
  });

  const [selectedRows, setSelected] = React.useState<string[]>([]);
  const [accumulatedAmount, SetAccumulatedAmount] = React.useState<number>(0);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let totalAmount: number = 0;
      const newSelectedRows = props.conciliationList.map(
        (row: Conciliation) => {
          const amount: number = get(row, "approvedTransactionAmount");

          totalAmount += amount;

          return get(row, "conciliationId");
        }
      );

      SetAccumulatedAmount(totalAmount);
      setSelected(newSelectedRows);
      props.setConciliationListAux!(props.conciliationList);

      return;
    }
    setSelected([]);
    SetAccumulatedAmount(0);
    props.setConciliationListAux!([]);
  };

  const handleClickRow = (name: string, amount: number) => {
    const conciliationAuxData: Conciliation = filter(
      props.conciliationList,
      (item: Conciliation) => item.conciliationId === name
    )[0] as Conciliation;

    props.setConciliationSelectModal!(conciliationAuxData);
    const selectedIndex = selectedRows.indexOf(name);
    let newSelected: string[] = [];
    let newTotalAmount: number = accumulatedAmount - amount;

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
      newTotalAmount = accumulatedAmount + amount;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    SetAccumulatedAmount(newTotalAmount);
    setSelected(newSelected);
  };

  const handleOpenSelectedTransactionModal = (
    conciliationTrx: Conciliation
  ) => {
    props.setOpenSelectedTransactionModal(true);
    props.setSelectedTransactionModal(conciliationTrx);
  };

  const isSelected = (name: string) => selectedRows.indexOf(name) !== -1;

  useEffect(() => {
    if (!isEmpty(props.conciliationList))
      setState({
        ...state,
        conciliationTransactions: props.conciliationList,
      });
  }, [props.conciliationList]);

  const handleSetIsDisabledByInconsistencyCode = () => {
    const acceptedRows: string[] = [];
    let selectedObject: object | undefined;

    selectedRows.map((row) => {
      selectedObject = props.conciliationList.find(
        (trx) =>
          get(trx, "conciliationId") === row &&
          get(trx, "inconsistencyCode") === "10"
      );

      if (selectedObject) acceptedRows.push(row);
    });

    return selectedRows.length !== acceptedRows.length;
  };

  const handleSetIsDisabledTooltipByStatus = () => {
    const acceptedRows: string[] = [];
    let selectedObject: object | undefined;

    selectedRows.map((row) => {
      selectedObject = props.conciliationList.find(
        (trx) =>
          get(trx, "conciliationId") === row &&
          get(trx, "transactionStatusKushki") === TransactionStatus.DECLINED &&
          TRANSACTION_STATUS_TRANSLATE[
            get(trx, "transactionStatusProcessor")
          ] === TRANSACTION_STATUS_TRANSLATE[TransactionStatus.APPROVAL] &&
          ALLOWED_FRANCHISES.includes(
            get(trx, "franchise", "") as FranchiseEnum
          )
      );

      if (selectedObject) acceptedRows.push(row);
    });

    return !(selectedRows.length !== acceptedRows.length);
  };

  const handleSetSlopesCounter = () => {
    const slopesRows: string[] = [];
    let selectedObject: object | undefined;

    selectedRows.map((row) => {
      selectedObject = props.conciliationList.find(
        (trx) =>
          get(trx, "conciliationId") !== row &&
          get(trx, "transactionStatusKushki") === TransactionStatus.DECLINED &&
          get(trx, "transactionStatusProcessor") ===
            TransactionStatus.APPROVAL &&
          get(trx, "inconsistencyCode") === "10"
      );

      if (selectedObject) slopesRows.push(row);
    });

    return slopesRows.length;
  };

  useEffect(() => {
    if (
      selectedRows.length == 0 ||
      handleSetIsDisabledByInconsistencyCode() ||
      !handleSetIsDisabledTooltipByStatus()
    ) {
      props.setIsRefundButtonDisabled(true);
      props.setIsTooltipDisabled(handleSetIsDisabledTooltipByStatus());
    } else {
      props.setIsRefundButtonDisabled(false);
      props.setSelectedRows(selectedRows);
      props.setSlopesCounter(handleSetSlopesCounter());
    }
    dispatch(setAccumulatedAmount(accumulatedAmount));
    dispatch(setRowsSelected(selectedRows));
  }, [selectedRows, accumulatedAmount]);

  useEffect(() => {
    if (selectedRows.length === 0 && !!props.setIsSkipButtonDisabled) {
      props.setIsSkipButtonDisabled(true);
    } else if (
      !!props.setIsSkipButtonDisabled &&
      !!props.setSelectRowsSkipModal
    ) {
      props.setIsSkipButtonDisabled(false);
      props.setSelectRowsSkipModal(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (isSuccessUpdate)
      setTimeout(async () => {
        await dispatch(getConciliationList(bodyFilter));
      }, 2100);
  }, [isSuccessUpdate]);

  return {
    handleClickRow,
    handleOpenSelectedTransactionModal,
    handleSelectAll,
    isSelected,
    selectableColumns: TransactionsTableConstants.selectColumns,
    selectedRows,
    SetAccumulatedAmount,
    setSelected,
    state,
  };
};
