import { PlatformValueEnum } from "../enums/PlatformValueEnum";

export const ChannelNames: Record<PlatformValueEnum, string | string[]> = {
  [PlatformValueEnum.API]: "API",
  [PlatformValueEnum.KUSHKIJS]: "Kushki JS",
  [PlatformValueEnum.ANDROID]: "Android",
  [PlatformValueEnum.SMARTLINKS]: "Smartlinks",
  [PlatformValueEnum.WEBCHECKOUT]: "Webcheckout",
  [PlatformValueEnum.KAJITA]: "Kajita",
  [PlatformValueEnum.CAJITA]: "Cajita",
  [PlatformValueEnum.VTEX]: "VTEX",
  [PlatformValueEnum.MAGENTO]: "Magento",
  [PlatformValueEnum.WOOCOMERCE]: "WooCommerce",
  [PlatformValueEnum.IOS]: "IOS",
  [PlatformValueEnum.PRESTASHOP]: "Prestashop",
  [PlatformValueEnum.SHOPIFY]: "Shopify",
  [PlatformValueEnum.WEBCHECKOUT_EXPRESS]: ["Express", "Checkout"],
};
