/* istanbul ignore file */
import { format, set, sub } from "date-fns";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";
import { payload } from "../interfaces/IPayload";
import { auth } from "../../auth";
import { TransactionsRequest } from "../../../../types/transactions_request";

const user_profile: payload = auth.getColumnsUser();

export const PAY_OUTS_LIST_FILTERS_INITIAL_STATE: TransactionsRequest = {
  offset: 0,
  from: format(
    set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
    "yyyy-MM-dd'T'HH:mm:ss"
  ),
  to: format(
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
    "yyyy-MM-dd'T'HH:mm:ss"
  ),
  timeZone: user_profile.timeZone,
  text: "",
  publicMerchantId: user_profile.publicMerchantId,
  userType: user_profile.admin ? "admin" : "merchant",
  filter: {
    paymentMethod: "",
    transactionStatus: "",
    transactionType: "",
  },
  limit: 10,
};

export const INIT_RANGE_FILTER: IRangeAmount = {
  type: "min",
  value: "",
  min: "",
  max: "",
  eq: "",
};

export const FILTER_MAX_VALUE = 9999999999;
