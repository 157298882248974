import { ICatalog, TimeZoneCatalog } from "../time-zone-catalog";
import { get } from "lodash";

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  const _timeZoneDefault: string = "America/Guayaquil";
  let index: number;

  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
};
