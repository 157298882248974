import { CountryNameEnum } from "./CountryEnum";

export enum CurrencyEnum {
  CLP = "CLP",
  PEN = "PEN",
  USD = "USD",
  COP = "COP",
  MXN = "MXN",
}

export const CURRENCY_TABLE: Record<CountryNameEnum, CurrencyEnum> = {
  [CountryNameEnum.chile]: CurrencyEnum.CLP,
  [CountryNameEnum.ecuador]: CurrencyEnum.USD,
  [CountryNameEnum.peru]: CurrencyEnum.PEN,
  [CountryNameEnum.colombia]: CurrencyEnum.COP,
  [CountryNameEnum.mexico]: CurrencyEnum.MXN,
};
