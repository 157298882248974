import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const GeneralInformationSkeleton: React.FC = () => {
  return (
    <React.Fragment>
      <div>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={30} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={30} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={30} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Grid container direction={"row"} spacing={3}>
          {[...Array(2)].map((i: number, index: number) => {
            return (
              <Grid item xs={2} key={`grid${index}${i}`}>
                <Skeleton
                  key={`skeleton${index}${i}`}
                  animation="wave"
                  variant="rect"
                  width={"100%"}
                  height={70}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </React.Fragment>
  );
};
