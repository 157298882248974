import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTableContext } from "../../Table/TableContext";
import { pushOrReplaceObjectInValueCells } from "../../Table/TableContext/tableUtils";
import { IValueCell } from "../CellHeader/interfaces";
import { ValidationTypeEnum } from "./CellInput.enums";
import { ICellInputProps, IValidation } from "./CellInput.interfaces";

interface IUseCellRow {
  value: unknown;
  error: boolean;
  isLoading: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: () => void;
  required: boolean;
  errorMessage: string;
}

const useCellInput = ({
  defaultValue,
  validations,
  id,
  rowIndex,
  upperCase,
}: ICellInputProps): IUseCellRow => {
  const [valueI, setValueI] = useState(defaultValue);
  const [required, setRequired] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    state: { isLoading, valueCells },
    handler: { handleSetCellValues },
  } = useTableContext();

  const validate = (
    inputValue: string,
    validations: IValidation[]
  ): boolean => {
    return validations.some((validation: IValidation) => {
      let notValid: boolean = false;

      if (validation.type === ValidationTypeEnum.required) {
        notValid = inputValue.length <= 0;
      }

      if (validation.type === ValidationTypeEnum.regex) {
        notValid = new RegExp(validation.regex ?? /^$/).test(inputValue);
        if (validation.regex) notValid = !notValid;
      }
      setErrorMessage(validation.errorMessage);

      return notValid;
    });
  };

  const validateUpdateStore = (inputValue: string) => {
    let notValid: boolean = error;

    if (validations) {
      notValid = validate(inputValue, validations);
      setError(notValid);
    }

    handleSetCellValues(
      pushOrReplaceObjectInValueCells(valueCells, {
        error: notValid,
        id: `${rowIndex}-${id}`,
        rowIndex,
        value: inputValue,
      })
    );
  };

  const handleBlur = () => {
    validateUpdateStore(valueI);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string =
      event.target.value[upperCase ? "toUpperCase" : "toString"]();

    setValueI(inputValue);
  };

  useEffect(() => {
    if (validations) {
      setRequired(
        validations.some(
          (validation) => validation.type === ValidationTypeEnum.required
        )
      );
    }
  }, [validations]);

  const valueStore: IValueCell | undefined = useMemo(
    () =>
      valueCells.find((cell: IValueCell) => cell.id === `${rowIndex}-${id}`),

    []
  );

  useEffect(() => {
    if (valueStore) {
      setValueI(get(valueStore, "value", valueI) as string);
      setError(get(valueStore, "error", error));
    } else {
      handleSetCellValues(
        pushOrReplaceObjectInValueCells(valueCells, {
          error: error,
          id: `${rowIndex}-${id}`,
          rowIndex,
          value: defaultValue,
        })
      );
      setValueI(defaultValue);
    }
  }, [valueStore, defaultValue]);

  return {
    error,
    errorMessage,
    handleBlur,
    handleChange,
    isLoading,
    required,
    value: valueI,
  };
};

export default useCellInput;
