import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Paper, Tooltip } from "@material-ui/core";
import { DeferredOption } from "../../../shared/infrastructure/interfaces/DeferredOptions";
import { get } from "lodash";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";
import { format } from "date-fns";
import { Calendar } from "react-feather";
import { calculateDateUtc } from "../../../shared/utils";

export interface TableProps {
  dataRows: DeferredOption[];
  dataHeaders: string[];
  handleSetBank: (data: string[]) => void;
  setCodeToProcessor: (code: string[]) => string;
  setCodeToTextDeferred: (code: DeferredOption) => string;
  merchantCountry: CountriesEnum;
}

export const StyledTableCell = withStyles(
  createStyles({
    head: {
      backgroundColor: "#F7FAFC",
      color: "#293036",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      "font-weight": 500,
    },
  })
)(TableCell);
const useStyles = makeStyles(() =>
  createStyles({
    TableContainer: {
      backgroundColor: "#F7FAFC",
      boxShadow: "none",
    },
    button: {
      fontWeight: "bold",
    },
    calendarStyle: {
      margin: "0 5px",
      display: "inline-flex",
    },
  })
);
export const TableDeferred = (props: TableProps) => {
  const classes = useStyles();
  const formatDeferredDate = (dateTimestamp: number, isStart: boolean) => {
    return format(
      new Date(calculateDateUtc(dateTimestamp * 1000, isStart)),
      "dd/MM/yyyy"
    );
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.TableContainer}>
        {props.merchantCountry === CountriesEnum.MEXICO ? (
          <Table aria-label="merchant-configuration-table">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={4}>
                  {props.dataHeaders[0]}
                </StyledTableCell>
                <StyledTableCell>{props.dataHeaders[1]}</StyledTableCell>
                <StyledTableCell>{props.dataHeaders[2]}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.dataRows.map((data: DeferredOption, index: number) => (
                <TableRow key={index}>
                  <TableCell colSpan={4}>
                    {get(data, "processorName", "")}
                  </TableCell>
                  <TableCell>{props.setCodeToTextDeferred(data)}</TableCell>
                  <TableCell>{get(data, "months", []).join(", ")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {props.merchantCountry === CountriesEnum.BRAZIL ? (
              <Table aria-label="merchant-configuration-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={4}>
                      {props.dataHeaders[0]}
                    </StyledTableCell>
                    <StyledTableCell>{props.dataHeaders[1]}</StyledTableCell>
                    <StyledTableCell>{props.dataHeaders[2]}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dataRows.map((data: DeferredOption, index: number) => (
                    <TableRow key={index}>
                      <TableCell colSpan={4}>
                        {get(data, "processorName", "")}
                        <>
                          {get(data, "startDate", 0) > 0 &&
                            get(data, "endDate", 0) > 0 && (
                              <div className={classes.calendarStyle}>
                                <Tooltip title="Diferido programado">
                                  <Calendar size={16} color="#677784" />
                                </Tooltip>
                              </div>
                            )}
                        </>
                      </TableCell>
                      {get(data, "startDate", 0) == 0 ? (
                        <TableCell>
                          <> - </>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <>
                            {formatDeferredDate(
                              get(data, "startDate", 0),
                              true
                            )}{" "}
                            -{" "}
                            {formatDeferredDate(get(data, "endDate", 0), false)}
                          </>
                        </TableCell>
                      )}
                      <TableCell>
                        {get(data, "months", []).join(", ")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Table aria-label="merchant-configuration-table">
                <TableHead>
                  <TableRow>
                    {props.dataHeaders.map((val: string, index: number) => {
                      return (
                        <StyledTableCell key={index}>{val}</StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dataRows.map((data: DeferredOption, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{get(data, "processorName", "")}</TableCell>
                      <TableCell>{props.setCodeToTextDeferred(data)}</TableCell>
                      <TableCell>
                        {get(data, "months", []).join(", ")}
                      </TableCell>
                      <TableCell align="right">
                        {data.bank && (
                          <Button
                            className={classes.button}
                            variant={"outlined"}
                            onClick={() => props.handleSetBank(data.bank!)}
                          >
                            Ver bancos asociados
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </TableContainer>
    </React.Fragment>
  );
};
