export enum ActionTitleEnum {
  ACTIVATE = "Activar",
  CLOSE_ALARM = "Cerrar alarma",
  DELETE = "Eliminar",
  EDIT = "Editar",
  INACTIVATE = "Inactivar",
  INIT_INVESTIGATION = "Iniciar investigación",
  REACTIVATE_ALARM = "Reactivar alarma",
  DOWNLOAD = "Descargar",
}
