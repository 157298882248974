import React from "react";
import CommentsTab from "../../components/modals/SelectedTransactionModal/DetailTabContent/CommentsTab/CommentsTab";
import InformationTab from "../../components/modals/SelectedTransactionModal/DetailTabContent/InformationTab/InformationTab";
import { ITransactionTab } from "../interfaces/TransactionModal.interfaces";
import TimeLineTab from "../../components/modals/SelectedTransactionModal/DetailTabContent/TimeLineTab/TimeLineTab";

export const TRANSATION_MODAL_STEPS: React.FC<ITransactionTab>[] = [
  InformationTab,
  TimeLineTab,
  CommentsTab,
];
