import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { isEmpty, isEqual } from "lodash";
import React, { FC, memo, useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Conciliation } from "../../../types/get_conciliation_transactions_response";
import { conciliationTabsStyles } from "../ConciliationTabs/ConciliationTabs.styles";
import FilterComponent from "../FilterComponent/FilterComponent";
import { ColumnConfiguration } from "../ColumnConfiguration/ColumnConfiguration";
import {
  Columns,
  DefaultColumns,
  FixedColumns,
} from "../../shared/infrastructure/constants/ColumnConstants";
import SkipTransactions from "../modals/SkipTransactions/SkipTransactions";
import { EmptyList } from "../TransactionTable/EmptyList/EmptyList";
import AmountTotalDetail from "./AmountTotalDetail";
import { usePendingConciliationTransactionsState } from "./state/usePendingConciliationTransactionsState";
import { TableHeaderFilter } from "../TableHeaderFilter/TableHeaderFilter";
import { RefundModal } from "../modals/RefundModal/RefundModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TransactionTableFooter } from "../TransactionTable/TransactionTableFooter/TransactionTableFooter";
import DownloadButton from "../common/DownloadButton/DownloadButton";
import { ARRAY_OPTIONS_DOWNLOAD } from "../../shared/constants/options_download";
import useDownloadTransactions from "../../hooks/useDownloadTransactions/useDownloadTransactions";
import { SnackBarAlert } from "../common/Snackbar/SnackBarAlert";
import TransactionConciliationList from "../../containers/TransactionConciliationList/TransactionConciliationList";
import { useSkipButton } from "../../hooks/useSkipButton/useSkipButton";
import TransactionDetailModal from "../modals/SelectedTransactionModal/TransactionDetailModal";
import { TabsEnum } from "../../shared/enums/tabs_enum";
import { ComponentIdsEnum } from "../../shared/constants/security_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { InformativeModalDownloads } from "../modals/InformativeModalDownloads/InformativeModalDownloads";
import { useInformativeModal } from "../../hooks/useInformativeModal/useInformativeModal";
import { TabSectionEnum } from "../../shared/infrastructure/enums/tab_section_enum";
import { ModalTitleEnum } from "../../shared/infrastructure/enums/ModalTitleEnum";
import { FilterTitlesEnum } from "../../shared/infrastructure/enums/FilterTitlesEnum";
import { NoFiltersMessageEnum } from "../../shared/infrastructure/enums/NoFiltersMessageEnum";
import { NoRecordsMessageEnum } from "../../shared/infrastructure/enums/NoRecordsMessageEnum";
import { useAppSelector } from "../../store/hooks/hooks";
import {
  setAccumulatedAmount,
  setRowsSelected,
} from "../../store/actions/app/app";

const PendingConciliationTransactions: FC = () => {
  const maxColumns = 6;
  const columns = [...Columns];

  const {
    actions: { handleDownloadTransactions },
    handleCloseSnackBar,
    message,
    openSnackBar,
    position,
    type,
  } = useDownloadTransactions("pending");

  const {
    dialogActions,
    handlers,
    isRefundButtonDisabled,
    isTooltipDisabled,
    openModal,
    openSelectedTransactionModal,
    setIsRefundButtonDisabled,
    setIsTooltipDisabled,
    selectedRowsCounter,
    setSelectedRows,
    setTransaction,
    setSlopesCounter,
    slopesCounter,
    transaction,
  } = usePendingConciliationTransactionsState();

  const {
    counterTransactions,
    handleChangeInputReason,
    handleCloseSkipModal,
    handleConfirm,
    handleOpenSkipModal,
    isSkipButtonDisabled,
    openSkipModal,
    reason,
    setIsSkipButtonDisabled,
    setSelectRowsSkipModal,
  } = useSkipButton();

  const {
    handlersInformation,
    openModalInformation,
    information,
    downloadType,
    setConciliationSelectModal,
    setConciliationListAux,
  } = useInformativeModal({ handleDownloadTransactions });

  let activeColumns = useSelector(
    (state: RootState) => state.app.activeColumns
  );
  let conciliationList: Conciliation[] = useSelector(
    (state: RootState) => state.app.conciliationList
  );

  let country = useAppSelector(
    (state: RootState) => state.filters.headerFilter.country
  );
  let paymentMethod = useAppSelector(
    (state: RootState) => state.filters.headerFilter.paymentMethod
  );
  let processorName = useAppSelector(
    (state: RootState) => state.filters.headerFilter.processorName
  );
  let accumulatedAmount = useSelector(
    (state: RootState) => state.app.accumulatedAmount
  );
  let rowsSelected = useSelector((state: RootState) => state.app.rowsSelected);

  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(DefaultColumns);

  const dispatch = useDispatch();

  const isFiltersSelected = () => {
    return (
      !isEqual(country, FilterTitlesEnum.country) &&
      !isEqual(paymentMethod, FilterTitlesEnum.paymentMethod) &&
      !isEqual(processorName, FilterTitlesEnum.processor)
    );
  };

  useEffect(() => {
    dispatch(setRowsSelected([]));
    dispatch(setAccumulatedAmount(0));
    setConciliationListAux([]);
  }, [country]);

  useEffect(() => {
    setSelectedColumns(activeColumns);
  }, [activeColumns]);

  return (
    <>
      {!isFiltersSelected() ? (
        <Box>
          <EmptyList
            title={NoFiltersMessageEnum.TITLE}
            firstSubtitle={NoFiltersMessageEnum.FIRST_SUBTITLE}
            secondSubtitle={NoFiltersMessageEnum.SECOND_SUBTITLE}
          />
        </Box>
      ) : (
        <Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            sx={conciliationTabsStyles.main}
          >
            <Grid item>
              <Stack direction="row" alignItems="center" gap={1}>
                <FiberManualRecordIcon
                  sx={conciliationTabsStyles.dotIconNotRefundable}
                />
                <Typography
                  sx={conciliationTabsStyles.notRefundableTransaction}
                >
                  Transacción no reembolsable
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center" gap={1}>
                <FiberManualRecordIcon
                  sx={conciliationTabsStyles.dotIconReview}
                />
                <Typography
                  sx={conciliationTabsStyles.notRefundableTransaction}
                >
                  En revisión
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mb={4} spacing={2}>
            <Grid item xs={6}>
              <TableHeaderFilter />
            </Grid>
            <Grid item xs={2}>
              <FilterComponent currentTab={TabsEnum.PENDING} />
            </Grid>
            <Grid item xs={2}>
              <ColumnConfiguration
                fixedColumns={FixedColumns}
                columns={columns}
                maxColumns={maxColumns}
                starterColumns={DefaultColumns}
              />
            </Grid>
            <Grid item xs={2}>
              <ComponentSecurityWrapper
                componentId={
                  ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_DOWNLOAD
                }
              >
                <DownloadButton
                  options={ARRAY_OPTIONS_DOWNLOAD}
                  handleClick={handlersInformation.handleOpenCloseModal}
                />
              </ComponentSecurityWrapper>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={conciliationTabsStyles.main}>
            <AmountTotalDetail
              country={country}
              rowsSelected={rowsSelected}
              accumulatedAmount={accumulatedAmount}
            />
            <Grid item xs={6}>
              <Box display="flex" justifyContent="end" gap={2}>
                <ComponentSecurityWrapper
                  componentId={
                    ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_OMIT
                  }
                >
                  <Button
                    sx={conciliationTabsStyles.skipButton}
                    size="medium"
                    variant="outlined"
                    onClick={handleOpenSkipModal}
                    disabled={isSkipButtonDisabled}
                  >
                    <Typography variant="subtitle2">Omitir</Typography>
                  </Button>
                </ComponentSecurityWrapper>
                <Tooltip
                  arrow
                  disableHoverListener={isTooltipDisabled}
                  placement="top"
                  title={
                    "Hay una o más transacciones no reembolsables seleccionadas."
                  }
                >
                  <span>
                    <ComponentSecurityWrapper
                      componentId={
                        ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_REFUND
                      }
                    >
                      <Button
                        id="refundBtn"
                        variant={"contained"}
                        size="medium"
                        sx={conciliationTabsStyles.refundButton}
                        onClick={handlers.handleOpenCloseModal}
                        disabled={isRefundButtonDisabled}
                      >
                        <Typography sx={conciliationTabsStyles.buttonsText}>
                          {"Reembolsar"}
                        </Typography>
                      </Button>
                    </ComponentSecurityWrapper>
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          {isEmpty(conciliationList) ? (
            <Box>
              <EmptyList
                title={NoRecordsMessageEnum.TITLE}
                firstSubtitle={NoRecordsMessageEnum.FIRST_SUBTITLE}
              />
            </Box>
          ) : (
            <Grid>
              <TransactionConciliationList
                selectedColumns={selectedColumns}
                country={country}
                currentTab={TabsEnum.PENDING}
                dialogActions={dialogActions}
                setConciliationSelectModal={setConciliationSelectModal}
                setConciliationListAux={setConciliationListAux}
                setIsRefundButtonDisabled={setIsRefundButtonDisabled}
                setIsSkipButtonDisabled={setIsSkipButtonDisabled}
                setIsTooltipDisabled={setIsTooltipDisabled}
                setSelectedRows={setSelectedRows}
                setSelectRowsSkipModal={setSelectRowsSkipModal}
                setSlopesCounter={setSlopesCounter}
                setOpenSelectedTransactionModal={
                  handlers.handleOpenCloseSelectedTransaction
                }
                setSelectedTransactionModal={setTransaction}
              />
            </Grid>
          )}

          <TransactionTableFooter />
        </Box>
      )}
      <Grid hidden={true}>
        <FilterComponent currentTab={TabsEnum.PENDING} />
      </Grid>
      <TransactionDetailModal
        conciliationTransaction={transaction}
        dialogActions={dialogActions}
        isOpen={openSelectedTransactionModal}
        onCloseModal={handlers.handleOpenCloseSelectedTransaction}
      />
      <SnackBarAlert
        type={type}
        msg={message}
        position={position}
        open={openSnackBar}
        handlerClose={handleCloseSnackBar}
      />

      <SkipTransactions
        counterTransactions={counterTransactions}
        open={openSkipModal}
        reason={reason}
        handleClose={handleCloseSkipModal}
        handleChangeReason={handleChangeInputReason}
        handleConfirm={handleConfirm}
      />

      <RefundModal
        openModal={openModal}
        handlers={handlers}
        selectedRowsCounter={selectedRowsCounter}
        slopesCounter={slopesCounter}
      />

      <InformativeModalDownloads
        openModal={openModalInformation}
        title={ModalTitleEnum.DOWNLOAD_MULTIPLE}
        handlers={handlersInformation}
        handleDownloadTransactions={handleDownloadTransactions}
        information={information}
        downloadType={downloadType}
        currentTab={TabSectionEnum.PENDING_TAB}
        rowSelected={rowsSelected.length}
      />
    </>
  );
};

export default memo(PendingConciliationTransactions);
