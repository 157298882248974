import React from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface ISkeleton {
  columns: number;
  rows: number;
}

export const SkeletonTable: React.FC<ISkeleton> = (props: ISkeleton) => {
  return (
    <React.Fragment>
      <TableBody>
        {[...Array(props.rows)].map((_: string, index: number) => (
          <TableRow key={index}>
            {[...Array(props.columns + 1)].map(
              (_: string, innerIndex: number) => (
                <TableCell key={innerIndex}>
                  <Skeleton variant="text" />
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    </React.Fragment>
  );
};
