import {
  createNamedStyles,
  IStyles,
  NamedStyles,
} from "../../shared/interfaces/create_named_styles";

export const resumeChartBarStyles = (
  isScrollX: boolean
): NamedStyles<IStyles> => {
  return createNamedStyles({
    container: {
      width: "100%",
      overflowX: "auto",
    },
    containerMobile: {
      width: "100%",
      overflowX: "scroll",
    },
    containerChartBar: {
      height: "400px",
      minWidth: isScrollX ? "1500px" : "auto",
      width: "100%",
    },
    containerChartBarMobile: {
      height: "300px",
      minWidth: isScrollX ? "1200px" : "auto",
      width: "100%",
    },
  });
};
