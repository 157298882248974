export type PaymentMethodType = (
  | "credit-card"
  | "transfer"
  | "card-dynamic"
  | "card-async"
)[];

export enum PaymentMethodEnum {
  CREDIT_CARD = "credit-card",
  TRANSFER = "transfer",
  CARD = "card",
  CARD_ASYNC = "card-async",
  CARD_DYNAMIC = "card-dynamic",
}

export enum ProcessorNameEnum {
  TRANS_BANK = "Transbank Processor",
}

export const EnabledPaymentMethods = [
  PaymentMethodEnum.CARD,
  PaymentMethodEnum.TRANSFER,
];
