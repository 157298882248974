import React from "react";
import { get } from "lodash";
import { translate } from "../../../shared/infrastructure/LanguageCatatog";
import { format, utcToZonedTime } from "date-fns-tz";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { UtcEnum } from "../../../shared/infrastructure/utc-enum";
import { CellWithDescription } from "../CellWithDescription/CellWithDescription";

const FORMAT_DATE: string = "dd/MM/yyyy";
export interface ICycleProps {
  transaction: object;
  country?: CountryEnum;
}
export const Cycle: React.FC<ICycleProps> = (props: ICycleProps) => {
  const { transaction, country } = props;

  const formatDate = (trxDate: string, formatDate: string): string => {
    const utc: string =
      country === CountryEnum.colombia ? UtcEnum.utc_05 : "Etc/UTC";

    return format(utcToZonedTime(trxDate, utc), formatDate);
  };
  const description: string = `${formatDate(
    get(transaction, "startDate", 1),
    FORMAT_DATE
  )} - ${formatDate(get(transaction, "endDate", 1), FORMAT_DATE)}`;

  return (
    <CellWithDescription
      title={translate(get(transaction, "cycle", ""))}
      description={description}
    />
  );
};
