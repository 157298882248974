import { environment } from "../../environments/environment";

export const API_ROUTES = {
  GET_MOVIES: "https://kform.getsandbox.com:443/movies",
  GET_SEMAPHORE_DATA: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/billing-core/v1/getSemaphore/${publicMerchantId}`,
  HIERARCHY_FIND_NODE_BY_NODE_ID: (nodeId: string) =>
    `${environment.kushkiUrl}/hierarchy/v1/node/${nodeId}`,
  HIERARCHY_GET_FIRST_PARENT: `${environment.kushkiUrl}/hierarchy/v1/node/first-parent`,
  HISTORICAL_REFUNDS: `${environment.kushkiUrl}/b2c-analytics/v1/refund-transaction-list`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
};
