import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { usePaginationStyles } from "./TablePagination.styles";

export interface TablePaginationProps {
  total: number;
  page: number;
  rowsPerPage: number;
  changePage: (_event: React.ChangeEvent<unknown>, value: number) => void;
  changeRowsPerPage: (rowsPerPage: number) => void;
}

export default function TablePagination(props: TablePaginationProps) {
  const classes = usePaginationStyles();

  const lastRowCalc = (props.page - 1) * props.rowsPerPage + props.rowsPerPage;
  const lastRowShowed = lastRowCalc > props.total ? props.total : lastRowCalc;

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item className={classes.marginButton}>
          <Typography
            variant={"body2"}
            className={classes.typographyLabelPagination}
          >
            Procesadores por página
          </Typography>

          <Typography
            variant={"body2"}
            className={classes.typographyLabelPagination}
          >
            {`${(props.page - 1) * props.rowsPerPage + 1}-${lastRowShowed} of ${
              props.total
            }`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container className={classes.toEnd}>
        <Pagination
          count={Math.ceil(Number(props.total / props.rowsPerPage))}
          onChange={props.changePage}
          color="primary"
          page={props.page}
          className={classes.pagination}
          data-testid="pagination"
        />
      </Grid>
    </Box>
  );
}
