import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagDefault,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
  FlagUsa,
} from "@kushki/connect-ui";
import React from "react";

import { CellFlagCountryType } from "./CellFlag.enum";

const TEXT_FLAG: Record<CellFlagCountryType | string, string> = {
  Afganistan: "AFG",
  Albania: "ALB",
  Alemania: "DEU",
  Andorra: "AND",
  Angola: "AGO",
  Anguila: "AIA",
  Antartida: "ATA",
  AntiguayBarbuda: "ATG",
  AntillasNeerlandesas: "ANT",
  ArabiaSaudita: "SAU",
  Argel: "DZA",
  Argentina: "ARG",
  Armenia: "ARM",
  Aruba: "ABW",
  Australia: "AUS",
  Austria: "AUT",
  Azerbaiyan: "AZE",
  Bahamas: "BHS",
  Bahrein: "BHR",
  Bangladesh: "BGD",
  Barbados: "BRB",
  Belarus: "BLR",
  Belgica: "BEL",
  Belice: "BLZ",
  Benin: "BEN",
  Bermudas: "BMU",
  Bhutan: "BTN",
  Bolivia: "BOL",
  BosniayHerzegovina: "BIH",
  Botsuana: "BWA",
  Brasil: "BRA",
  Brazil: "BRA",
  Brunei: "BRN",
  Bulgaria: "BGR",
  BurkinaFaso: "BFA",
  Burundi: "BDI",
  CaboVerde: "CPV",
  Camboya: "KHM",
  Camerun: "CMR",
  Canada: "CAN",
  Chad: "TCD",
  Chile: "CHL",
  China: "CHN",
  Chipre: "CYP",
  CiudaddelVaticano: "VAT",
  Colombia: "COL",
  Comoros: "COM",
  Congo: "COG",
  CoreadelNorte: "PRK",
  CoreadelSur: "KOR",
  CostadeMarfil: "CIV",
  CostaRica: "CRI",
  Croacia: "HRV",
  Cuba: "CUB",
  Dinamarca: "DNK",
  Dominica: "DMA",
  Ecuador: "ECU",
  EEUU: "USA",
  Egipto: "EGY",
  ElSalvador: "SLV",
  EmiratosArabesUnidos: "ARE",
  Eritrea: "ERI",
  Eslovaquia: "SVK",
  Eslovenia: "SVN",
  Espania: "ESP",
  EstadosUnidosdeAmerica: "USA",
  Estonia: "EST",
  Etiopia: "ETH",
  Fiji: "FJI",
  Filipinas: "PHL",
  Finlandia: "FIN",
  Fiyi: "FJI",
  Francia: "FRA",
  Gabon: "GAB",
  Gambia: "GMB",
  Georgia: "GEO",
  GeorgiadelSureIslasSandwichdelSur: "SGS",
  Ghana: "GHA",
  Gibraltar: "GIB",
  Granada: "GRD",
  Grecia: "GRC",
  Groenlandia: "GRL",
  Guadalupe: "GLP",
  Guam: "GUM",
  Guatemala: "GTM",
  Guayana: "GUY",
  GuayanaFrancesa: "GUF",
  Guernsey: "GGY",
  Guinea: "GIN",
  GuineaBissau: "GNB",
  GuineaEcuatorial: "GNQ",
  Haiti: "HTI",
  Honduras: "HND",
  HongKong: "HKG",
  Hungria: "HUN",
  India: "IND",
  Indonesia: "IDN",
  Irak: "IRQ",
  Iran: "IRN",
  Irlanda: "IRL",
  IslaBouvet: "BVT",
  IsladeMan: "IMN",
  Islandia: "ISL",
  IslasAland: "ALA",
  IslasCaiman: "CYM",
  IslasChristmas: "CXR",
  IslasCocos: "CCK",
  IslasCook: "COK",
  IslasFaroe: "FRO",
  IslasHeardyMcDonald: "HMD",
  IslasMalvinas: "KLK",
  IslasMarshall: "MHL",
  IslasNorkfolk: "NFK",
  IslasPalaos: "PLW",
  IslasPitcairn: "PCN",
  IslasSolomon: "SLB",
  IslasSvalbardyJanMayen: "SJM",
  IslasTurcasyCaicos: "TCA",
  IslasVirgenesBritanicas: "VGB",
  IslasVirgenesdelosEstadosUnidosdeAmerica: "VIR",
  Israel: "ISR",
  Italia: "ITA",
  Jamaica: "JAM",
  Japon: "JPN",
  Jersey: "JEY",
  Jordania: "JOR",
  Kazajstan: "KAZ",
  Kenia: "KEN",
  Kirguistan: "KGZ",
  Kiribati: "KIR",
  Kuwait: "KWT",
  Laos: "LAO",
  Lesotho: "LSO",
  Letonia: "LVA",
  Libano: "LBN",
  Liberia: "LBR",
  Libia: "LBY",
  Liechtenstein: "LIE",
  Lituania: "LTU",
  Luxemburgo: "LUX",
  Macao: "MAC",
  Macedonia: "MKD",
  Madagascar: "MDG",
  Malasia: "MYS",
  Malawi: "MWI",
  Maldivas: "MDV",
  Mali: "MLI",
  Malta: "MLT",
  Marruecos: "MAR",
  Martinica: "MTQ",
  Mauricio: "MUS",
  Mauritania: "MRT",
  Mayotte: "MYT",
  Mexico: "MEX",
  Micronesia: "FSM",
  Moldova: "MDA",
  Monaco: "MCO",
  Mongolia: "MNG",
  Montenegro: "MNE",
  Montserrat: "MSR",
  Mozambique: "MOZ",
  Myanmar: "MMR",
  Namibia: "NAM",
  Nauru: "NRU",
  Nepal: "NPL",
  Nicaragua: "NIC",
  Niger: "NER",
  Nigeria: "NGA",
  Niue: "NIU",
  Noruega: "NOR",
  NuevaCaledonia: "NCL",
  NuevaZelanda: "NZL",
  Oman: "OMN",
  PaisesBajos: "NLD",
  Pakistan: "PAK",
  Palestina: "PSE",
  Panama: "PAN",
  PapuaNuevaGuinea: "PNG",
  Paraguay: "PRY",
  Peru: "PER",
  PolinesiaFrancesa: "PYF",
  Polonia: "POL",
  Portugal: "PRT",
  PuertoRico: "PRI",
  Qatar: "QAT",
  ReinoUnido: "GBR",
  RepublicaCentroAfricana: "CAF",
  RepublicaCheca: "CZE",
  RepublicaDominicana: "DOM",
  Reunion: "REU",
  Ruanda: "RWA",
  Rumania: "ROU",
  Rusia: "RUS",
  SaharaOccidental: "ESH",
  Samoa: "WSM",
  SamoaAmericana: "ASM",
  SanBartolome: "BLM",
  SanCristobalyNieves: "KNA",
  SanMarino: "SMR",
  SanPedroyMiquelon: "SPM",
  SantaElena: "SHN",
  SantaLucia: "LCA",
  SantoTomeyPrincipe: "STP",
  SanVicenteylasGranadinas: "VCT",
  Senegal: "SEN",
  SerbiayMontenegro: "SRB",
  Seychelles: "SYC",
  SierraLeona: "SLE",
  Singapur: "SGP",
  Siria: "SYR",
  Somalia: "SOM",
  SriLanka: "LKA",
  Suazilandia: "SWZ",
  Sudafrica: "ZAF",
  Sudan: "SDN",
  Suecia: "SWE",
  Suiza: "CHE",
  Surinam: "SUR",
  Tailandia: "THA",
  Taiwan: "TWN",
  Tanzania: "TZA",
  Tayikistan: "TJK",
  TerritorioBritanicodelOceanoIndico: "IOT",
  TerritoriosAustralesFranceses: "ATF",
  TimorLeste: "TLS",
  Togo: "TGO",
  Tokelau: "TKL",
  Tonga: "TON",
  TrinidadyTobago: "TTO",
  Tunez: "TUN",
  Turkmenistan: "TKM",
  Turquia: "TUR",
  Tuvalu: "TUV",
  Ucrania: "UKR",
  Uganda: "UGA",
  Uruguay: "URY",
  Uzbekistan: "UZB",
  Vanuatu: "VUT",
  Venezuela: "VEN",
  Vietnam: "VNM",
  WallisyFutuna: "WLF",
  Yemen: "YEM",
  Yibuti: "DJI",
};

const FLAG_ICON: Record<CellFlagCountryType, React.FC> = {
  Afganistan: FlagDefault,
  Albania: FlagDefault,
  Alemania: FlagDefault,
  Andorra: FlagDefault,
  Angola: FlagDefault,
  Anguila: FlagDefault,
  Antartida: FlagDefault,
  AntiguayBarbuda: FlagDefault,
  AntillasNeerlandesas: FlagDefault,
  ArabiaSaudita: FlagDefault,
  Argel: FlagDefault,
  Argentina: FlagDefault,
  Armenia: FlagDefault,
  Aruba: FlagDefault,
  Australia: FlagDefault,
  Austria: FlagDefault,
  Azerbaiyan: FlagDefault,
  Bahamas: FlagDefault,
  Bahrein: FlagDefault,
  Bangladesh: FlagDefault,
  Barbados: FlagDefault,
  Belarus: FlagDefault,
  Belgica: FlagDefault,
  Belice: FlagDefault,
  Benin: FlagDefault,
  Bermudas: FlagDefault,
  Bhutan: FlagDefault,
  Bolivia: FlagDefault,
  BosniayHerzegovina: FlagDefault,
  Botsuana: FlagDefault,
  Brasil: FlagBrasil,
  Brazil: FlagBrasil,
  Brunei: FlagDefault,
  Bulgaria: FlagDefault,
  BurkinaFaso: FlagDefault,
  Burundi: FlagDefault,
  CaboVerde: FlagDefault,
  Camboya: FlagDefault,
  Camerun: FlagDefault,
  Canada: FlagDefault,
  Chad: FlagDefault,
  Chile: FlagChile,
  China: FlagDefault,
  Chipre: FlagDefault,
  CiudaddelVaticano: FlagDefault,
  Colombia: FlagColombia,
  Comoros: FlagDefault,
  Congo: FlagDefault,
  CoreadelNorte: FlagDefault,
  CoreadelSur: FlagDefault,
  CostadeMarfil: FlagDefault,
  CostaRica: FlagCostaRica,
  Croacia: FlagDefault,
  Cuba: FlagDefault,
  Dinamarca: FlagDefault,
  Dominica: FlagDefault,
  Ecuador: FlagEcuador,
  EEUU: FlagUsa,
  Egipto: FlagDefault,
  ElSalvador: FlagElSalvador,
  EmiratosArabesUnidos: FlagDefault,
  Eritrea: FlagDefault,
  Eslovaquia: FlagDefault,
  Eslovenia: FlagDefault,
  Espania: FlagDefault,
  EstadosUnidosdeAmerica: FlagUsa,
  Estonia: FlagDefault,
  Etiopia: FlagDefault,
  Fiji: FlagDefault,
  Filipinas: FlagDefault,
  Finlandia: FlagDefault,
  Fiyi: FlagDefault,
  Francia: FlagDefault,
  Gabon: FlagDefault,
  Gambia: FlagDefault,
  Georgia: FlagDefault,
  GeorgiadelSureIslasSandwichdelSur: FlagDefault,
  Ghana: FlagDefault,
  Gibraltar: FlagDefault,
  Granada: FlagDefault,
  Grecia: FlagDefault,
  Groenlandia: FlagDefault,
  Guadalupe: FlagDefault,
  Guam: FlagDefault,
  Guatemala: FlagGuatemala,
  Guayana: FlagDefault,
  GuayanaFrancesa: FlagDefault,
  Guernsey: FlagDefault,
  Guinea: FlagDefault,
  GuineaBissau: FlagDefault,
  GuineaEcuatorial: FlagDefault,
  Haiti: FlagDefault,
  Honduras: FlagHonduras,
  HongKong: FlagDefault,
  Hungria: FlagDefault,
  India: FlagDefault,
  Indonesia: FlagDefault,
  Irak: FlagDefault,
  Iran: FlagDefault,
  Irlanda: FlagDefault,
  IslaBouvet: FlagDefault,
  IsladeMan: FlagDefault,
  Islandia: FlagDefault,
  IslasAland: FlagDefault,
  IslasCaiman: FlagDefault,
  IslasChristmas: FlagDefault,
  IslasCocos: FlagDefault,
  IslasCook: FlagDefault,
  IslasFaroe: FlagDefault,
  IslasHeardyMcDonald: FlagDefault,
  IslasMalvinas: FlagDefault,
  IslasMarshall: FlagDefault,
  IslasNorkfolk: FlagDefault,
  IslasPalaos: FlagDefault,
  IslasPitcairn: FlagDefault,
  IslasSolomon: FlagDefault,
  IslasSvalbardyJanMayen: FlagDefault,
  IslasTurcasyCaicos: FlagDefault,
  IslasVirgenesBritanicas: FlagDefault,
  IslasVirgenesdelosEstadosUnidosdeAmerica: FlagDefault,
  Israel: FlagDefault,
  Italia: FlagDefault,
  Jamaica: FlagDefault,
  Japon: FlagDefault,
  Jersey: FlagDefault,
  Jordania: FlagDefault,
  Kazajstan: FlagDefault,
  Kenia: FlagDefault,
  Kirguistan: FlagDefault,
  Kiribati: FlagDefault,
  Kuwait: FlagDefault,
  Laos: FlagDefault,
  Lesotho: FlagDefault,
  Letonia: FlagDefault,
  Libano: FlagDefault,
  Liberia: FlagDefault,
  Libia: FlagDefault,
  Liechtenstein: FlagDefault,
  Lituania: FlagDefault,
  Luxemburgo: FlagDefault,
  Macao: FlagDefault,
  Macedonia: FlagDefault,
  Madagascar: FlagDefault,
  Malasia: FlagDefault,
  Malawi: FlagDefault,
  Maldivas: FlagDefault,
  Mali: FlagDefault,
  Malta: FlagDefault,
  Marruecos: FlagDefault,
  Martinica: FlagDefault,
  Mauricio: FlagDefault,
  Mauritania: FlagDefault,
  Mayotte: FlagDefault,
  Mexico: FlagMexico,
  Micronesia: FlagDefault,
  Moldova: FlagDefault,
  Monaco: FlagDefault,
  Mongolia: FlagDefault,
  Montenegro: FlagDefault,
  Montserrat: FlagDefault,
  Mozambique: FlagDefault,
  Myanmar: FlagDefault,
  Namibia: FlagDefault,
  Nauru: FlagDefault,
  Nepal: FlagDefault,
  Nicaragua: FlagNicaragua,
  Niger: FlagDefault,
  Nigeria: FlagDefault,
  Niue: FlagDefault,
  Noruega: FlagDefault,
  NuevaCaledonia: FlagDefault,
  NuevaZelanda: FlagDefault,
  Oman: FlagDefault,
  PaisesBajos: FlagDefault,
  Pakistan: FlagDefault,
  Palestina: FlagDefault,
  Panama: FlagPanama,
  PapuaNuevaGuinea: FlagDefault,
  Paraguay: FlagDefault,
  Peru: FlagPeru,
  PolinesiaFrancesa: FlagDefault,
  Polonia: FlagDefault,
  Portugal: FlagDefault,
  PuertoRico: FlagDefault,
  Qatar: FlagDefault,
  ReinoUnido: FlagDefault,
  RepublicaCentroAfricana: FlagDefault,
  RepublicaCheca: FlagDefault,
  RepublicaDominicana: FlagDefault,
  Reunion: FlagDefault,
  Ruanda: FlagDefault,
  Rumania: FlagDefault,
  Rusia: FlagDefault,
  SaharaOccidental: FlagDefault,
  Samoa: FlagDefault,
  SamoaAmericana: FlagDefault,
  SanBartolome: FlagDefault,
  SanCristobalyNieves: FlagDefault,
  SanMarino: FlagDefault,
  SanPedroyMiquelon: FlagDefault,
  SantaElena: FlagDefault,
  SantaLucia: FlagDefault,
  SantoTomeyPrincipe: FlagDefault,
  SanVicenteylasGranadinas: FlagDefault,
  Senegal: FlagDefault,
  SerbiayMontenegro: FlagDefault,
  Seychelles: FlagDefault,
  SierraLeona: FlagDefault,
  Singapur: FlagDefault,
  Siria: FlagDefault,
  Somalia: FlagDefault,
  SriLanka: FlagDefault,
  Suazilandia: FlagDefault,
  Sudafrica: FlagDefault,
  Sudan: FlagDefault,
  Suecia: FlagDefault,
  Suiza: FlagDefault,
  Surinam: FlagDefault,
  Tailandia: FlagDefault,
  Taiwan: FlagDefault,
  Tanzania: FlagDefault,
  Tayikistan: FlagDefault,
  TerritorioBritanicodelOceanoIndico: FlagDefault,
  TerritoriosAustralesFranceses: FlagDefault,
  TimorLeste: FlagDefault,
  Togo: FlagDefault,
  Tokelau: FlagDefault,
  Tonga: FlagDefault,
  TrinidadyTobago: FlagDefault,
  Tunez: FlagDefault,
  Turkmenistan: FlagDefault,
  Turquia: FlagDefault,
  Tuvalu: FlagDefault,
  Ucrania: FlagDefault,
  Uganda: FlagDefault,
  Uruguay: FlagDefault,
  Uzbekistan: FlagDefault,
  Vanuatu: FlagDefault,
  Venezuela: FlagDefault,
  Vietnam: FlagDefault,
  WallisyFutuna: FlagDefault,
  Yemen: FlagDefault,
  Yibuti: FlagDefault,
};

export { FLAG_ICON, TEXT_FLAG };
