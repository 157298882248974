import React, { FC } from "react";
import { useStyles } from "../../containers/BillingData/BillingDataContainer.styles";
import { getZipCodeInputRules } from "../../shared/constants/billing_form_constants";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import {
  getOnlyNumbers,
  sanitizeInput,
} from "../../shared/utils/billing_data_utils";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { get } from "lodash";
import { IZipCode } from "./ZipCodeComponent.interfaces";

export const ZipCodeComponent: FC<IZipCode> = ({
  errors,
  control,
  country,
  watch,
  disabled,
  label = BillingDataFormLabelsEnum.zipCode,
}: IZipCode): JSX.Element => {
  const classes = useStyles();

  return (
    <Controller
      name={FieldsEnum.zipCode}
      rules={getZipCodeInputRules()[country]}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          onChange={(e) => {
            field.onChange(getOnlyNumbers(sanitizeInput(e.target.value)));
          }}
          id="zip-code-input"
          label={label}
          error={!!get(errors, FieldsEnum.zipCode)}
          className={classes.errorMessage}
          defaultValue={watch!(FieldsEnum.zipCode)}
          helperText={get(errors, FieldsEnum.zipCode)?.message ?? ""}
          variant="outlined"
          disabled={disabled}
        />
      )}
    />
  );
};
