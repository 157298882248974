import { PersonTypeInterface } from "../BasicStateGeneral/FieldsBasicData";
import {
  PersonType_EC_PE_Enum,
  PersonType_Generic_Enum,
} from "../BasicStateGeneral/PersonType";
import {
  BasicDataLabels,
  ErrorMessageMX,
  RegularExp,
  TypeElement,
} from "../BasicStateMX/ErrorMessageMX";
import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { ErrorMessageGeneric } from "./ErrorMessageGeneric";
import { regExpFormGenericEnum } from "./CatalogDataGeneric";
import { ErrorMessageGeneric as ErrorMessageGenericBasic } from "../BasicStateGeneral/ErrorMessage";

export const fieldsRenderGenericPSP: Array<PersonTypeInterface> = [
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGenericBasic.MAX150,
        value: 150,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageMX.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.NUMBER_TAXID,
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageGeneric.PF_NUM_TAX_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.TIPO_EMPRESA,
    name: FieldsNamesRender.type_company,
    rules: {
      required: {
        message: ErrorMessageMX.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.TIPO_EMPRESA_GENERIC,
    name: FieldsNamesRender.type_company_generic,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.GENERIC,
    value: 12,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.ECONOMIC_ACTIVITY_GENERIC,
    name: FieldsNamesRender.economic_activity_generic,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_ECONOMIC_ACTIVITY,
        value: true,
      },
    },
    type: TypeElement.GENERIC_ACTIVITY,
    value: 12,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.COMPANY_REGISTRATION_NUMBER,
    name: FieldsNamesRender.company_registration_number,
    regExp: RegularExp.LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 1,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },

  {
    filter: PersonType_Generic_Enum.JURIDICA,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },

  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.NOMBRE,
    name: FieldsNamesRender.names,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.APELLIDO,
    name: FieldsNamesRender.surname,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.NUMBER_TAXID,
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageGeneric.PF_NUM_TAX_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 12,
  },
  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 12,
  },
  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.ECONOMIC_ACTIVITY_GENERIC,
    name: FieldsNamesRender.economic_activity_generic,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_ECONOMIC_ACTIVITY,
        value: true,
      },
    },
    type: TypeElement.GENERIC_ACTIVITY,
    value: 12,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.COMPANY_REGISTRATION_NUMBER,
    name: FieldsNamesRender.company_registration_number,
    regExp: RegularExp.LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 1,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },

  {
    filter: PersonType_Generic_Enum.NATURAL,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: regExpFormGenericEnum.regExp,
    rules: {
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
];
