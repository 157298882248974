import { IGenericBreadcrumbProps } from "@components/GenericBreadcrumb/GenericBreadcrumbs.interfaces";
import {
  breadcrumbLabelEnum,
  pathEnum,
} from "@shared/constants/CatalogBreadcrumb";
import {
  IDefaultItemSelectedEvent,
  IUseRulesAlarm,
} from "@containers/RulesAlarm/state/useRulesAlarm.interfaces";
import { AlarmLabels } from "@shared/constants/labels/alarmLabels";
import { useEffect, useState } from "react";
import { IDropdownItemProps } from "@kushki/connect-ui";
import {
  RuleAlarmsItemsEnum,
  RuleAlarmsLabelsEnum,
} from "@shared/enum/RuleAlarmsOptionsEnum";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { redirectOptions } from "@shared/utils/redirectOptions";
import { defaultTo, get } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { TypeEnum } from "@shared/constants/AlarmConfigConstants";

export const useRulesAlarm = (): IUseRulesAlarm => {
  const navigate = useNavigate();
  const { type } = useParams();

  const verifyComponentAlarm: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM_CLIENT
  );
  const verifyGeneralComponentAlarm: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_CREATE_GENERAL_ALARM_CLIENT
  );

  const defaultAlarmOptions: IDropdownItemProps[] = [
    {
      id: RuleAlarmsItemsEnum.ALARM_GENERAL,
      isDisable: !verifyGeneralComponentAlarm,
      text: RuleAlarmsLabelsEnum.ALARM_GENERAL,
      variant: "text",
    },
    {
      id: RuleAlarmsItemsEnum.ALARM_USER,
      isDisable: !verifyComponentAlarm,
      text: RuleAlarmsLabelsEnum.ALARM_USER,
      variant: "text",
    },
  ];

  const [alarmOptions, setAlarmOptions] =
    useState<IDropdownItemProps[]>(defaultAlarmOptions);

  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    defaultAlarmOptions.map((item) => {
      if (item.id === RuleAlarmsItemsEnum.ALARM_USER)
        item.isDisable = !verifyComponentAlarm;

      if (item.id === RuleAlarmsItemsEnum.ALARM_GENERAL)
        item.isDisable = !verifyGeneralComponentAlarm;
    });

    setAlarmOptions(defaultAlarmOptions);
  }, [verifyComponentAlarm]);

  const handleBreadcrumbItem = (): IGenericBreadcrumbProps => {
    return {
      breadcrumbItems: [
        { label: breadcrumbLabelEnum.DASHBOARD_LABEL, url: pathEnum.DASHBOARD },
      ],
      lastItem: AlarmLabels.TITLE,
    };
  };

  const onAlarmOptionSelect = (valueSelected: IDefaultItemSelectedEvent) =>
    redirectOptions(navigate)[get(valueSelected, "id", "")]();

  const onTabChange = (index: number) => {
    setTabIndex(index);
  };

  const tabOptions = {
    [TypeEnum.GENERAL]: 0,
    [TypeEnum.INDIVIDUAL]: 1,
  };

  useEffect(
    () =>
      onTabChange(tabOptions[defaultTo(type, TypeEnum.GENERAL).toUpperCase()]),
    [type]
  );

  return {
    alarmOptions,
    handleBreadcrumbItem,
    onAlarmOptionSelect,
    tabs: {
      onTabChange,
      tabIndex,
    },
  };
};
