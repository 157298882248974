import React from "react";
import { CustomBreadcrumb } from "../../components/common/CustomBreadcrumb/CustomBreadcrumb";
import {
  breadcrumbTitleValues,
  dropdownSplitButtonAdminValues,
  dropdownSplitButtonValues,
  IDropdownSplitButtonProps,
  TitleSection,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { SplitButtonComponent } from "../../components/common/SplitButtonComponent/SplitButtonComponent";
import { Box, Container, Grid } from "@mui/material";
import SecurityRulesTabs from "../../components/Tabs/SecurityRulesTabs";
import { useRuleRequestManagerIndexState } from "./state/useRuleRequestManagerIndexState";
import { IconOpenBook } from "@kushki/connect-ui";
import { Routes } from "../../shared/infrastructure/routes";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import { ComponentIdEnum } from "../../shared/infrastructure/enums/ComponentIdEnum";
import { ISecurityContextValue } from "@kushki/security-wrapper/dist/cjs/types/components/ModuleSecurityWrapper/ModuleSecurityWrapper.interfaces";
import { ModalCatalogGeneralRules } from "../ModalCatalogGeneralRules/ModalCatalogGeneralRules";
import { setOpenModalCatalogGeneralRules } from "../../store/actionCreators";
import { useDispatch } from "react-redux";

export const RuleRequestManagerIndex: React.FC = () => {
  const { dropdownSplitButtonObjectValues, securityContextValue } =
    useRuleRequestManagerIndexState();
  const dispatch = useDispatch();

  const getTitleRol = (
    dropdownSplitButtonObjectValues: IDropdownSplitButtonProps,
    securityContextValue: ISecurityContextValue
  ): JSX.Element => {
    if (
      VerifyIfComponentEnable(
        ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATE_GENERAL_RULE_ADMIN,
        securityContextValue
      )
    )
      return (
        <CustomBreadcrumb
          title={TitleSection.RULES_CREATE}
          type={"withButtonIconSplitButton"}
          items={breadcrumbTitleValues}
          splitButton={
            <SplitButtonComponent
              dropdownSplitButtonObjectValues={dropdownSplitButtonAdminValues(
                dropdownSplitButtonObjectValues
              )}
            />
          }
          onButtonClick={() =>
            dispatch(setOpenModalCatalogGeneralRules({ openModal: true }))
          }
          iconButton={<IconOpenBook fontSize={"small"} />}
          textButton={"Catálogo de reglas generales"}
        />
      );

    if (
      VerifyIfComponentEnable(
        ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATE_GENERAL_RULE_APPROVER,
        securityContextValue
      )
    )
      return (
        <CustomBreadcrumb
          title={TitleSection.RULES_CREATE}
          type={"withTwoButtonsIcon"}
          items={breadcrumbTitleValues}
          textButton="Crear regla general"
          textButtonSecondary="Catálogo de reglas generales"
          iconButtonSecondary={<IconOpenBook fontSize={"small"} />}
          onButtonClick={() =>
            (window.location.href = Routes.CREATE_GENERAL_RULE)
          }
          onButtonClickSecondary={() =>
            dispatch(setOpenModalCatalogGeneralRules({ openModal: true }))
          }
        />
      );

    // Operator
    if (
      VerifyIfComponentEnable(
        ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATE_SECURITY_RULE,
        securityContextValue
      )
    )
      return (
        <CustomBreadcrumb
          title={TitleSection.RULES_CREATE}
          type={"withButtonIconSplitButton"}
          items={breadcrumbTitleValues}
          splitButton={
            <SplitButtonComponent
              dropdownSplitButtonObjectValues={dropdownSplitButtonValues(
                dropdownSplitButtonObjectValues
              )}
            />
          }
          onButtonClick={() => alert("test operator catalog")}
          iconButton={<IconOpenBook fontSize={"small"} />}
          onButtonClickSecondary={() =>
            dispatch(setOpenModalCatalogGeneralRules({ openModal: true }))
          }
          textButton={"Catálogo de reglas generales"}
        />
      );

    if (
      VerifyIfComponentEnable(
        ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_LIST_CATALOGS,
        securityContextValue
      )
    )
      return (
        <CustomBreadcrumb
          title={TitleSection.RULES_CREATE}
          type={"withButtonIconSplitButton"}
          items={breadcrumbTitleValues}
          iconButton={<IconOpenBook fontSize={"small"} />}
          onButtonClick={() =>
            dispatch(setOpenModalCatalogGeneralRules({ openModal: true }))
          }
          textButton={"Catálogo de reglas generales"}
        />
      );

    return (
      <CustomBreadcrumb
        items={breadcrumbTitleValues}
        type={"titleBase"}
        title={TitleSection.RULES_CREATE}
      />
    );
  };

  return (
    <>
      <Container fixed>
        <Box display="flex" height="90vh">
          <Box position={"relative"}>
            {getTitleRol(dropdownSplitButtonObjectValues, securityContextValue)}
            <Grid container direction={"column"}>
              <Grid item xs={12}>
                <SecurityRulesTabs />
                <ModalCatalogGeneralRules />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};
