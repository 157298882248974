import { useState } from "react";
import { IAccordionProps } from "../Accordion.interfaces";
import { IUseAccordion } from "./useAccordion.interfaces";

const useAccordion = ({ isOpen }: IAccordionProps): IUseAccordion => {
  const [openAccordion, setOpenAccordion] = useState<boolean>(isOpen);

  const handleClickAccordion = (): void => setOpenAccordion(!openAccordion);

  return {
    handleClickAccordion,
    openAccordion,
  };
};

export { useAccordion };
