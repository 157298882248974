import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const sideBarStyles = createNamedStyles({
  sideBar: {
    width: "275px",
    maxHeight: "100%",
    display: "flex",
    position: "fixed",
    left: 0,
    padding: "130px 84px 78px 64px",
    borderRight: "1px solid #CBD5E0",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "neutral.grey1",
      borderRadius: 3,
    },
    scrollbarWidth: {
      md: "auto",
      xs: "none",
    },
  },
});
