import React, { FC } from "react";
import { Box } from "@mui/material";
import { LabelsEnum } from "../../shared/enums/LabelsEnum";
import { chargebackContainerStyles as styles } from "./ChargebackContainerStyles";
import { Header } from "../../components/Header/Header";
import { useChargebackContainer } from "./state/useChargebackContainer";
import { SelectCheck, SelectCheckChips } from "@kushki/connect-ui";
import { SelectCheckTypeEnum } from "../../shared/enums/SelectCheckTypeEnum";
import TransactionList from "../../components/TransactionList/TransactionList";
import { PlaceholderEnum } from "../../shared/enums/PlaceholderEnum";
import { itemCategoryItems } from "../../shared/utils/chips_selected_merchants";

export const ChargebackContainer: FC = () => {
  const {
    isAdmin,
    isCustomer,
    isRol,
    listBranches,
    listMerchants,
    loadingMerchants,
    onChangeTextField,
    onChipChange,
    setBranches,
    onItemSelect,
    totalFilters,
    searchLabel,
  } = useChargebackContainer();

  return (
    <Box sx={styles.main}>
      <Header
        label={LabelsEnum.CHARGEBACKS}
        component={
          isRol && isCustomer ? (
            <SelectCheck
              isActiveSelectAll={true}
              type={SelectCheckTypeEnum.NO_CATEGORY}
              placeholder={PlaceholderEnum.LABEL_BRANCHES}
              items={listBranches}
              onItemSelect={setBranches}
              onChangeTextField={onChangeTextField}
              hasDescription={true}
            />
          ) : (
            isAdmin && (
              <SelectCheckChips
                placeholder={searchLabel}
                isActiveSelectAll={true}
                isLoading={loadingMerchants}
                hasDescription={true}
                items={listMerchants}
                listChips={itemCategoryItems}
                onChangeTextField={onChangeTextField}
                onItemSelect={onItemSelect}
                onChipChange={onChipChange}
                totalFilters={totalFilters}
                disableFilterSearch={true}
              />
            )
          )
        }
      />
      <TransactionList />
    </Box>
  );
};
