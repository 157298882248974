/* istanbul ignore file */
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ContainerIndex } from "./containers/ContainerIndex/ContainerIndex";

const Navigation: React.FC = () => {
  return (
    <BrowserRouter basename={"merchant-people"}>
      <Switch>
        <Route path="/:merchantId" exact component={ContainerIndex} />
      </Switch>
    </BrowserRouter>
  );
};

export default Navigation;
