import { CardList } from "@kushki/connect-ui";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useServiceConfiguration } from "./state/useServiceConfiguration";
import BaseDataContainer from "../commons/BaseDataContainer/BaseDataContainer";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export const enum AlertTitle {
  TITLE = "Personaliza los servicios que deseas añadir.",
}

const ServiceConfiguration: FC = () => {
  const { activeServices, isSettingsAvailable, items, handleConfigure } =
    useServiceConfiguration();

  if (!isSettingsAvailable) {
    return (
      <Box width="100%">
        <Alert
          severity="success"
          icon={false}
          action={
            <ComponentSecurityWrapper>
              <Button
                color="secondary"
                onClick={handleConfigure}
                variant="outlined"
                size="small"
                sx={{ fontWeight: 500 }}
              >
                Configurar
              </Button>
            </ComponentSecurityWrapper>
          }
          sx={{ borderRadius: "8px", padding: "8px 16px" }}
        >
          {AlertTitle.TITLE}
        </Alert>
      </Box>
    );
  }

  return (
    // @ts-ignore
    <BaseDataContainer
      title="Configuración de servicios"
      onClickEdit={handleConfigure}
    >
      <Box
        bgcolor="neutral.grey3"
        borderRadius="8px"
        sx={{ minWidth: "0px" }}
        marginBottom="16px"
        py={3}
      >
        <Box mx={3}>
          <Typography variant="subtitle2" color="text.dark" mb={2}>
            Servicios activos
          </Typography>
          <Typography variant="caption" color="text.dark">
            {activeServices?.join(", ") || ""}
          </Typography>
        </Box>
        <Grid item md={6} xs={12}>
          <CardList
            title="Configuraciones avanzadas:"
            orientation="horizontal"
            // @ts-ignore
            items={items}
          />
        </Grid>
      </Box>
    </BaseDataContainer>
  );
};

export default ServiceConfiguration;
