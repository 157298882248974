import { makeStyles } from "@material-ui/core";

export const useDeferredScheduleStyles = makeStyles(() => ({
  descriptionAlert: {
    color: "#4498EE",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
  },
  descriptionAlertBold: {
    color: "#4498EE",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "140%",
  },
  formControlContent: {
    display: "contents",
    marginLeft: "0px",
    marginRight: "0px",
  },
  gridContainer: {
    "& .button-date-range-picker-wrap": {
      float: "left",
      margin: "auto",
      marginBottom: "8px",
      marginLeft: "-9px",
    },
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    height: "39px",
    marginTop: "16px",
  },
  paddingContent: {
    padding: "20px 22px",
  },
  titleContent: {
    color: "#023365",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
  },
}));
