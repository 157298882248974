export enum TabsTextOptionsEnum {
  observationEmptyDispersionTitle = "Esta dispersión no tiene referencias",
  observationEmptyInvoiceTitle = "Esta factura no tiene referencias",
  observationEmptyVoucherTitle = "Esta boleta no tiene referencias",
  observationEmptyChargeTitle = "Este cobro no tiene referencias",
}

export enum TabsTextConfigOptionsEnum {
  configEmptyInvoiceTitle = "Esta factura no tiene Configuración Avanzada",
  configEmptyVoucherTitle = "Esta boleta no tiene Configuración Avanzada",
  configEmptyChargeTitle = "Este cobro no tiene Configuración Avanzada",
}
