import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const InboxIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon
    {...rest}
    height="95"
    viewBox="0 0 99 95"
    width="99"
    xmlns="http://www.w3.org/2000/svg"
    sx={sx}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.10474 47.5002C4.10474 45.3141 5.94258 43.5419 8.20968 43.5419H32.8393C34.2118 43.5419
      35.4935 44.2033 36.2549 45.3045L43.2461 55.4169H55.2721L62.2634 45.3045C63.0247 44.2033 64.3064
      43.5419 65.6789 43.5419H90.3086C92.5757 43.5419 94.4135 45.3141 94.4135 47.5002C94.4135 49.6863
      92.5757 51.4585 90.3086 51.4585H67.8758L60.8845 61.5709C60.1232 62.6721 58.8415 63.3335 57.469
      63.3335H41.0492C39.6767 63.3335 38.395 62.6721 37.6337 61.5709L30.6424 51.4585H8.20968C5.94258
      51.4585 4.10474 49.6863 4.10474 47.5002Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7196 11.875L68.7987 11.875C71.0897 11.8762 73.3372 12.4936 75.2843 13.6579C77.2297 14.8212
      78.8003 16.4842 79.8199 18.4604C79.8208 18.4621 79.8217 18.4639 79.8226 18.4656L93.9791 45.7277C94.2648
      46.2779 94.4135 46.8847 94.4135 47.5V71.25C94.4135 74.3994 93.1161 77.4199 90.8066 79.6469C88.4971 81.8739
      85.3648 83.125 82.0987 83.125H16.4196C13.1535 83.125 10.0211 81.8739 7.71167 79.6469C5.40219 77.4199
      4.10474 74.3994 4.10474 71.25V47.5C4.10474 46.8847 4.25348 46.2779 4.53917 45.7277L18.6956 18.4656C18.6966
      18.4637 18.6976 18.4618 18.6986 18.4598C19.7182 16.4839 21.2887 14.8211 23.2339 13.6579C25.181 12.4936 27.4285
      11.8762 29.7196 11.875ZM22.3717 20.2271L26.0422 21.9994L12.3146 48.4356V71.25C12.3146 72.2998 12.7471 73.3066
      13.5169 74.049C14.2868 74.7913 15.3309 75.2083 16.4196 75.2083H82.0987C83.1874 75.2083 84.2315 74.7913 85.0013
      74.049C85.7711 73.3066 86.2036 72.2998 86.2036 71.25V48.4356L72.476 21.9994L72.4704 21.9885C72.1305 21.3291 71.6067
      20.7741 70.9576 20.386C70.3088 19.998 69.5607 19.7922 68.7973 19.7917H29.721C28.9576 19.7922 28.2094 19.998 27.5606
      20.386C26.9116 20.7741 26.3877 21.3291 26.0478 21.9885L22.3717 20.2271Z"
    />
  </SvgIcon>
);

export default InboxIcon;
