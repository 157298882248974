import { setBase64File } from "../../store/reducers/massiveUserForm/massiveUserForm.slice";

export const getBase64File = (file: File, dispatch: Function): void => {
  const reader: FileReader = new FileReader();

  reader.readAsDataURL(file);
  reader.onloadend = () => {
    if (reader.result) {
      const base64File = reader.result.toString().split(",")[1];

      if (base64File) dispatch(setBase64File(base64File));
    }
  };
};
