export enum StatusButtonEnum {
  ACTIVE = "Activar",
  INACTIVE = "Desactivar",
  EDIT = "Editar",
}
export const DESCRIPTION = {
  BRANCH:
    "Para realizar una acción masiva (activar o desactivar) selecciona branches con un mismo estado, ",
  CUSTOMER:
    "Para realizar una acción masiva (activar o desactivar) selecciona customers con un mismo estado, ",
};

export enum STATUS_DESCRIPTION {
  ACTIVE = "Activo",
  INACTIVE = "Inactivo",
}

export const SELECTED_COUNT = " Customers seleccionados: ";

export const SELECTED_COUNT_BRANCH = " Branches seleccionados: ";

export const ModalStatusTextCustomers = {
  DESCRIPTION:
    "Puedes volver a activarlos nuevamente, desde el listado de Customers. Recuerda que los Branches asociados a estos Customers tambíen se inactivarán.",
  TITLE: (total: number) =>
    `¿Estás seguro de que deseas desactivar ${total} Customers?`,
};

export const ModalStatusTextCustomer = {
  DESCRIPTION: (name: string) =>
    `Al desactivar ${name} se inhabilitará el procesamiento de pagos y el funcionamiento de los servicios conectados con el. Recuerda que los Branches asociados a este Customer también se inactivarán.`,
  TITLE: "¿Estás seguro de que deseas desactivar este Customer?",
};

export const ModalStatusTextBranch = {
  DESCRIPTION: (name: string) =>
    `Al desactivar ${name} se inhabilitará el procesamiento de pagos y el funcionamiento de los servicios conectados con el.`,
  TITLE: "¿Estás seguro de que deseas desactivar este branch?",
};

export const ModalStatusTextBranches = {
  DESCRIPTION:
    "Puedes volver a activarlos nuevamente, desde el listado de Branches.",
  TITLE: (total: number) =>
    `¿Estás seguro de que deseas desactivar ${total} Branches?`,
};

export enum ModalButtonEnum {
  DESACTIVAR = "Desactivar",
  CANCELAR = "Cancelar",
}

export enum TableMessageEnum {
  EMPTY_DATA_TITLE = "No se encontró ninguna coincidencia",
  EMPTY_DATA_BODY = "Modifica la búsqueda para encontrar nuevos resultados",
}

export enum ShowUserParamValue {
  TRUE = "true",
}
