import React from "react";
import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  OutlinedInput,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Filter, Search, Plus } from "react-feather";
import { IMerchantsState, MerchantsFetch } from "../../store/reducer";
import {
  getMerchantsRequest,
  MerchantActions,
  updateMerchantState,
} from "../../store/actionCreators";
import {
  Merchant as TableMerchant,
  MerchantsTable,
} from "@kushki/frontend-molecules/merchants-table";
import { Filter as MerchantFilter } from "@kushki/frontend-molecules/filter";
import { useMerchantState } from "./state/useMerchantState";
import { DeactivateMerchantDialog } from "../../components/DeactivateMerchantDialog";
import { MerchantStatusEnum } from "../../infrastructure/MerchantStatusEnum";
import { SecurityWrapperEnum } from "../../infrastructure/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface MerchantStateProps {
  merchants: MerchantsFetch;
  isLoading: boolean;
}

export interface MerchantFunctionsProps {
  getMerchants: (payload: {
    limit: number;
    offset: number;
    text: string;
    country?: string;
    merchant_id?: string;
  }) => void;
  updateMerchantStatus: (payload: {
    merchants: MerchantsFetch;
    merchantId: string;
    state: "inactive" | "active";
  }) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tittle: {
    fontStyle: "normal",
    fontWeight: 200,
    fontSize: "40px",
    lineHeight: "52px",
    paddingBottom: "25px",
    color: "#023365",
  },
  button: {
    textTransform: "none",
  },
  secondaryHeading: {
    height: 200,
    color: theme.palette.text.disabled,
    backgroundColor: "#ffffff",
  },
  toolbar: {
    backgroundColor: "#F7FAFC",
    marginTop: 10,
  },
  searchInput: {
    backgroundColor: "#ffffff",
  },
  badgedFilter: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(30%, -20%)",
    },
    "& .MuiBadge-colorSecondary": {
      color: "rgba(240, 244, 248, 1)",
      backgroundColor: "rgba(13,194,152,1)",
    },
  },
}));

export type MerchantProps = MerchantFunctionsProps & MerchantStateProps;

export const Merchant = (props: MerchantProps): JSX.Element => {
  const classes = useStyles();
  const {
    merchantFilters,
    merchantsTable,
    actions,
    searchFilter,
    deactivateMerchant,
    openDialog,
    menuList,
    isComplianceUser,
  } = useMerchantState(props);

  const open = Boolean(merchantFilters.anchorEl);
  const id = open ? "button-filter" : undefined;

  return (
    <React.Fragment>
      {openDialog && <DeactivateMerchantDialog {...deactivateMerchant} />}
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.tittle}>Comercios</Typography>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Box display={"flex"}>
                <Box width={{ xs: "100%" }}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <InputLabel htmlFor="search-input">
                      Buscar un comercio...
                    </InputLabel>
                    <OutlinedInput
                      className={classes.searchInput}
                      label={"Buscar un comercio..."}
                      id="search-input"
                      value={searchFilter.searchText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        searchFilter.handleSearchChange(event.target.value)
                      }
                      onKeyUp={searchFilter.handleKeyUp}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={searchFilter.handleSearchClick}
                            edge="end"
                          >
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                <Box width={{ xs: "0%", md: "20%" }}>{""}</Box>
              </Box>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button
                fullWidth={true}
                variant="outlined"
                className={classes.button}
                size="large"
                onClick={actions.handleMerchants}
              >
                {" "}
                Ver todos los comercios
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button
                disabled={isComplianceUser()}
                className={classes.button}
                onClick={actions.handleCreateMerchant}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                startIcon={<Plus />}
              >
                {" "}
                Crear Comercio
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {actions.totalMerchants > 0 || actions.loading ? (
              <div>
                <Toolbar className={classes.toolbar}>
                  <Badge
                    color="secondary"
                    badgeContent={actions.counterFilters}
                    className={classes.badgedFilter}
                  >
                    <Button
                      aria-describedby={id}
                      variant="outlined"
                      onClick={actions.handleClick}
                      startIcon={<Filter xlinkTitle={"Filtrar"} size={16} />}
                    >
                      Filtrar
                    </Button>
                  </Badge>
                  <MerchantFilter
                    anchorPopover={merchantFilters.anchorEl}
                    filters={merchantFilters.filters}
                    handleApplyFilters={merchantFilters.handleAppliedFilters}
                    handleClose={merchantFilters.handleClose}
                    idPopover={id}
                    isMobile={merchantFilters.isMobile}
                    openModal={merchantFilters.expanded}
                    openPopover={open}
                    handleClearFilters={merchantFilters.handleClearFilters}
                  />
                </Toolbar>
                <MerchantsTable
                  merchants={merchantsTable.merchantArr}
                  skeleton={actions.loading || props.isLoading}
                  pagination={merchantsTable.pag}
                  handleOnRowSelection={merchantsTable.handleSelectMerchant}
                  handleOnEdit={merchantsTable.handleEditMerchant}
                  handleOnMoreOptions={(merchant: TableMerchant) => (
                    <MenuList
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <ComponentSecurityWrapper
                        componentId={SecurityWrapperEnum.RECHARGE_BALANCE}
                      >
                        <MenuItem
                          onClick={() => menuList.handleCredit(merchant)}
                        >
                          Recargar Saldo
                        </MenuItem>
                      </ComponentSecurityWrapper>
                      <ComponentSecurityWrapper
                        componentId={SecurityWrapperEnum.DEBIT_BALANCE}
                      >
                        <MenuItem
                          onClick={() => menuList.handleDebit(merchant)}
                        >
                          Debitar Saldo
                        </MenuItem>
                      </ComponentSecurityWrapper>
                      <ComponentSecurityWrapper
                        componentId={
                          merchant.chipClass === MerchantStatusEnum.ACTIVE
                            ? SecurityWrapperEnum.DEACTIVATE_MERCHANTS
                            : SecurityWrapperEnum.ACTIVATE_MERCHANTS
                        }
                      >
                        <MenuItem
                          onClick={() => menuList.handleActivate(merchant)}
                          style={{ borderTop: "1px solid #CBD5E0" }}
                        >
                          <Typography
                            style={{
                              color:
                                merchant.chipClass === MerchantStatusEnum.ACTIVE
                                  ? "#AD0C2A"
                                  : "#023365",
                            }}
                          >
                            {merchant.chipClass === MerchantStatusEnum.ACTIVE
                              ? "Desactivar comercio"
                              : "Activar comercio"}
                          </Typography>
                        </MenuItem>
                      </ComponentSecurityWrapper>
                      <ComponentSecurityWrapper
                        componentId={SecurityWrapperEnum.COMPLIANCE}
                      >
                        <MenuItem
                          onClick={() =>
                            (window.location.href = `/compliance/merchant/${merchant.merchantId}`)
                          }
                          style={{ borderTop: "1px solid #CBD5E0" }}
                        >
                          Cumplimiento
                        </MenuItem>
                      </ComponentSecurityWrapper>
                    </MenuList>
                  )}
                  hideOnMoreOptions={(merchant: TableMerchant) =>
                    merchant.chipClass === MerchantStatusEnum.PENDING ||
                    isComplianceUser()
                  }
                />
              </div>
            ) : (
              <Box mt={3} pt={10} className={classes.secondaryHeading}>
                <Typography align={"center"} variant={"h4"}>
                  Busca un Comercio para comenzar...
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IMerchantsState) => MerchantStateProps = (
  state: IMerchantsState
): MerchantStateProps => ({
  merchants: state.merchants,
  isLoading: state.isLoading,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => MerchantFunctionsProps = (
  dispatch: ThunkDispatch<IMerchantsState, undefined, MerchantActions>
): MerchantFunctionsProps => ({
  getMerchants: (payload: {
    limit: number;
    offset: number;
    text: string;
    merchant_id?: string;
  }): void => dispatch(getMerchantsRequest(payload)),
  updateMerchantStatus: (payload: {
    merchants: MerchantsFetch;
    merchantId: string;
    state: "inactive" | "active";
  }): void => dispatch(updateMerchantState(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
