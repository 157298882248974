/**
 * TransactionStatus
 */

export enum TransactionStatus {
  APPROVAL = "APPROVAL",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}

export const TRANSACTION_STATUS_TRANSLATE: Record<TransactionStatus, string> = {
  [TransactionStatus.APPROVAL]: "APROBADA",
  [TransactionStatus.APPROVED]: "APROBADA",
  [TransactionStatus.DECLINED]: "DECLINADA",
};
