import { createNamedStyles } from "../../../shared/infrastructure/interfaces/create_named_styles";

export const MassiveHeaderStyles = createNamedStyles({
  massiveReturnButton: {
    paddingBottom: 5,
    paddingLeft: 3,
    paddingTop: 4,
  },
  massiveBranchesTitle: {
    width: "80%",
    display: "flex",
  },
  massiveBranchesButton: {
    width: "20%",
    justifyContent: "right",
    float: "right",
    display: "flex",
  },
  massiveBranchesTitleTypography: {
    color: "text.primary",
    fontSize: 20,
    variant: "h1",
    fontWeight: "700",
  },
  massiveGridTitle: {
    paddingBottom: 0,
  },
  massiveButtonBorderNone: {
    border: "none",
    paddingRight: "0px",
    ":hover": {
      border: "none",
      backgroundColor: "transparent",
    },
    "& .MuiButtonBase-root-MuiButton-root": {
      border: "none",
    },
  },
});
