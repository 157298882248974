import { useEffect, useState } from "react";
import { defaultTo, get, isEmpty } from "lodash";
import { IUseMainContainer } from "./IUseMainContainer.interfaces";
import { OwnerEnum } from "../../../shared/enums/OwnerEnum";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  createOwner,
  listOwners,
} from "../../../store/thunks/owner/owner.thunk";
import { CreateNodeRequest } from "../../../../types/create_node_request";
import { CustomerEnum } from "../../../shared/enums/CustomerEnum";
import { useSnackbar } from "@kushki/connect-ui";
import { setNotification } from "../../../store/actions/ownerData.actions";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { RootState } from "../../../store/store";

export const useMainContainer = (): IUseMainContainer => {
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    notification,
    ownerDetail,
    customerList,
    listOwner,
    isLoadingFlow,
  } = useAppSelector((state: RootState) => ({
    ...state.ownerReducer,
    ...state.customerReducer,
  }));

  const [emptyAddText, setEmptyAddText] = useState<string>(
    OwnerEnum.ADD_OWNERS
  );
  const [isOwnerCreated, setIsOwnerCreated] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const handleCreateOwner = (ownerName: string) => {
    const nodeRequest: CreateNodeRequest = {
      entityName: "OWNER",
      name: ownerName,
      userAlias: defaultTo(localStorage.getItem("fullName"), ""),
      userName: defaultTo(localStorage.getItem("username"), ""),
    };

    dispatch(createOwner(nodeRequest));
    setIsOpenModal(false);
  };

  const handleSearchNode = () => {
    const nodeRequest: SearchMerchantRequest = {
      entityName: "OWNER",
      isActive: true,
      limit: 1000,
      offset: 0,
    };

    dispatch(listOwners(nodeRequest));
  };

  useEffect(() => {
    handleSearchNode();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(get(ownerDetail, "name", "")) ||
      !isEmpty(get(listOwner, "data", []))
    ) {
      setEmptyAddText(CustomerEnum.ADD_CUSTOMERS);
      setIsOwnerCreated(true);
    }
  }, [ownerDetail, listOwner]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  return {
    emptyAddText,
    handleCreateOwner,
    isLoading,
    isLoadingFlow,
    isOpenModal,
    isOwnerCreated,
    onCloseModal,
    openModal,
    showEmptyScreen:
      isEmpty(get(customerList, "data", [])) ||
      isEmpty(get(ownerDetail, "name", "")),
  };
};
