export const getItemLocalStorage = (item: string) => {
  const itemLocalStorage = localStorage.getItem(item) ?? "";

  return itemLocalStorage === "" || !itemLocalStorage
    ? undefined
    : itemLocalStorage;
};

export const isB2CCoordinator = () => {
  const value = getItemLocalStorage("roles");

  if (value === undefined) return false;
  const parsedValue = JSON.parse(value);

  return parsedValue["B2CCoordinator"];
};

export const isB2CAgent = () => {
  const value = getItemLocalStorage("roles");

  if (value === undefined) return false;
  const parsedValue = JSON.parse(value);

  return parsedValue["B2CAgent"];
};
