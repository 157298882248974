import { IAppAction, MerchantsData } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ActionTypes } from "./actionTypes";
import { ResponseSearchRulesByFilters } from "../../types/response_search_rules_by_filters";
import { AuthenticationRequestDynamo } from "../../types/remote/authentication_request_dynamo";
import { ProcessorInfoResponse } from "../../types/processor_info_response";
import { CredentialInfoResponse } from "../../types/credential_info_response";
import { AuthenticationRule } from "../../types/authentication_rule";
import { ProcessorInformation } from "../../types/processor_information";
import { BusinessRulesResponse } from "../../types/business_rules_response";
import { MerchantProcessors } from "../../types/merchant_processors";
import { Category } from "../../types/category";
import { WhitelistRules } from "../../types/whitelist_rules";
import { AuthenticationRulesByType } from "../../types/authentication_rules_by_type";
import { UserBalancerResponse } from "../../types/user_balancer_response";

export interface IAppState {
  notification: INotification;
  rulesDataCounter: { contador: number; total: number };
  isLoadingAuthenticationRules: boolean;
  authenticationRulesData: ResponseSearchRulesByFilters;
  isLoadingUpdateAuthenticationRules: boolean;
  isLoadingAuthenticationRulesHistoric: boolean;
  authenticationRulesDataHistoric: ResponseSearchRulesByFilters;
  authenticationRulesRequestData: ResponseSearchRulesByFilters;
  isLoadingDashboardApproval: boolean;
  isReloadDashboardRuleRequest: string;
  isLoadingDashboardRequest: boolean;
  isLoadingDownloadFile: boolean;
  isLoadingDownloadListFile: boolean;
  authenticationRulesApprovalData: ResponseSearchRulesByFilters;
  isLoadingAnalyst: boolean;
  ruleAnalystData: ResponseSearchRulesByFilters;
  isLoadingProcessor: boolean;
  processorData: ProcessorInfoResponse[];
  isLoadingCredential: boolean;
  credentialData: CredentialInfoResponse[];
  tableColumns: object;
  authenticationData: AuthenticationRequestDynamo;
  pathLoadingProcessRates: boolean;
  isLoading?: boolean;
  isLoadingGetMerchants?: boolean;
  isLoadingGetProcessors?: boolean;
  isLoadingGetRule?: boolean;
  isLoadingRulesByType?: boolean;
  isLoadingWhiteList?: boolean;
  merchants?: MerchantsData;
  processors?: ProcessorInformation[];
  currentAuthenticationRule?: AuthenticationRule;
  isRequest?: boolean;
  requestError?: boolean;
  businessRule?: BusinessRulesResponse;
  processorsMerchant?: MerchantProcessors[];
  credentials?: Category[];
  defaultProcessor?: object;
  rulesByType?: AuthenticationRulesByType;
  commerceRules?: AuthenticationRulesByType;
  whitelistRules?: WhitelistRules;
  currentWhitelistRule?: WhitelistRules;
  showMigrationRule?: boolean;
  isSnackbarRuleDashboardRequest: boolean;
  isLoadingRuleDashboardRequest: boolean;
  userAnalystData: UserBalancerResponse[];
}

export const INITIAL_STATE: IAppState = {
  pathLoadingProcessRates: false,
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  rulesDataCounter: { contador: 0, total: 0 },
  isLoadingAuthenticationRules: true,
  authenticationRulesData: { records: [], total: 0 },
  isLoadingUpdateAuthenticationRules: false,
  isLoadingAuthenticationRulesHistoric: false,
  authenticationRulesDataHistoric: { records: [], total: 0 },
  isLoadingDownloadFile: false,
  isLoadingDownloadListFile: false,
  isLoadingDashboardApproval: false,
  isLoadingDashboardRequest: false,
  isLoadingRuleDashboardRequest: false,
  isSnackbarRuleDashboardRequest: false,
  isReloadDashboardRuleRequest: "",
  isLoadingAnalyst: true,
  ruleAnalystData: {
    records: [],
    total: 0,
  },
  tableColumns: {},
  authenticationRulesApprovalData: {
    records: [],
    total: 1000,
  },
  authenticationRulesRequestData: {
    records: [],
    total: 1000,
  },
  userAnalystData: [],
  authenticationData: {
    id: "",
    userName: "",
    merchantName: "",
    userAlias: "",
    publicMerchantId: "",
    provider: "cybersource",
    kind: "OTP",
    country: "",
    userAliasRequest: "",
    userNameRequest: "",
    status: "pending",
  },
  isLoadingProcessor: true,
  processorData: [],
  isLoadingCredential: true,
  credentialData: [],
};

export interface IRulesRequestState {
  pagination: object[];
}

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_PATH_LOADING_PROCESS_RATES:
      return {
        ...state,
        pathLoadingProcessRates: action.pathLoadingProcessRates!,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification!,
      };
    case ActionTypes.SET_RULES_DATA_COUNTER:
      return {
        ...state,
        rulesDataCounter: action.rulesDataCounter!,
      };
    case ActionTypes.SET_LOADING_AUTHENTICATION_RULES:
      return {
        ...state,
        isLoadingAuthenticationRules: action.setLoadingAuthenticationRules!,
      };
    case ActionTypes.SET_AUTHENTICATION_RULES_DATA:
      return {
        ...state,
        authenticationRulesData: action.authenticationRulesData!,
      };
    case ActionTypes.SET_LOADING_AUTHENTICATION_RULES_REQUEST:
      return {
        ...state,
        isLoadingDashboardRequest: action.isLoadingDashboardRequest!,
      };
    case ActionTypes.SET_LOADING_UPDATE_AUTHENTICATION_RULES:
      return {
        ...state,
        isLoadingUpdateAuthenticationRules:
          action.isLoadingUpdateAuthenticationRules!,
      };
    case ActionTypes.SET_LOADING_AUTHENTICATION_RULES_HISTORIC:
      return {
        ...state,
        isLoadingAuthenticationRulesHistoric: action.setLoadingHistoric!,
      };
    case ActionTypes.SET_LOADING_DOWNLOAD_FILE:
      return {
        ...state,
        isLoadingDownloadFile: action.setLoadingDownloadFile!,
      };
    case ActionTypes.SET_LOADING_DOWNLOAD_LIST_FILE:
      return {
        ...state,
        isLoadingDownloadListFile: action.setLoadingDownloadListFile!,
      };
    case ActionTypes.SET_AUTHENTICATION_RULES_DATA_HISTORIC:
      return {
        ...state,
        authenticationRulesDataHistoric:
          action.authenticationRulesDataHistoric!,
      };
    case ActionTypes.SET_LOADING_AUTHENTICATION_RULES_APPROVAL:
      return {
        ...state,
        isLoadingDashboardApproval: action.isLoadingDashboardApproval!,
      };
    case ActionTypes.SET_AUTHENTICATION_RULES_APPROVAL_DATA:
      return {
        ...state,
        authenticationRulesApprovalData:
          action.authenticationRulesApprovalData!,
      };
    case ActionTypes.SET_AUTHENTICATION_DATA:
      return {
        ...state,
        authenticationData: action.authenticationData!,
      };
    case ActionTypes.SET_LOADING_ANALYST:
      return {
        ...state,
        isLoadingAnalyst: action.setLoadingAnalyst!,
      };
    case ActionTypes.SET_ANALYST_DATA:
      return {
        ...state,
        ruleAnalystData: action.ruleAnalystData!,
      };
    case ActionTypes.SET_LOADING_PROCESSOR:
      return {
        ...state,
        isLoadingProcessor: action.setLoadingProcessor!,
      };
    case ActionTypes.SET_PROCESSOR_DATA:
      return {
        ...state,
        processorData: action.processorData!,
      };
    case ActionTypes.SET_LOADING_CREDENTIAL:
      return {
        ...state,
        isLoadingCredential: action.setLoadingCredential!,
      };
    case ActionTypes.SET_CREDENTIAL_DATA:
      return {
        ...state,
        credentialData: action.credentialData!,
      };
    case ActionTypes.SET_AUTHENTICATION_RULES_REQUEST_DATA:
      return {
        ...state,
        authenticationRulesRequestData: action.authenticationRulesRequestData!,
      };
    case ActionTypes.SET_ANALYST_USER:
      return {
        ...state,
        userAnalystData: action.userAnalystData!,
      };
    case ActionTypes.SET_LOADING_RULES_REQUEST:
      return {
        ...state,
        isLoadingRuleDashboardRequest: action.isLoadingRuleDashboardRequest!,
      };
    case ActionTypes.SET_SNACKBAR_RULES_REQUEST:
      return {
        ...state,
        isSnackbarRuleDashboardRequest: action.isSnackbarRuleDashboardRequest!,
      };
    case ActionTypes.SET_RELOAD_DASHBOARD_RULE_REQUEST:
      return {
        ...state,
        isReloadDashboardRuleRequest: action.isReloadDashboardRuleRequest!,
      };
    default:
      return state;
  }
};
