import { makeStyles } from "@material-ui/core/styles";

export const fileValidationDetailsStyles = makeStyles({
  container: {
    marginBottom: "13%",
    marginTop: "8%",
  },
  mainGrid: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    padding: "9px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  content: {
    marginLeft: 350,
  },
  title: {
    fontWeight: 500,
    color: "#023365",
    marginBottom: "4px",
    textAlign: "center",
  },
  partialTitle: {
    fontWeight: 500,
    color: "#023365",
    marginBottom: "4px",
    textAlign: "center",
    fontSize: 20,
    lineHeight: "140%",
    marginTop: 48,
  },
  partialDescription: {
    color: "#023365",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "120%",
  },
  partialSubtitleOne: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "150%",
    color: "#023365",
  },
  partialSubtitleTwo: {
    marginTop: 8,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "150%",
    color: "#023365",
  },
  buttonsContainer: {
    width: 400,
    marginTop: 20,
  },
  button: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  resultImage: {
    width: "300px",
    marginTop: 25,
    marginBottom: 20,
  },
});
