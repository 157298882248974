export enum routes {
  MERCHANTS = "/merchant-list",
  BASE_PATH_CREATE_MERCHANT = "/create-merchant",
  DETAILS = "/details",
  WALLET = "/wallet",
  INDEX = "/",
  PROCESSOR_CARD = "/processor-card",
  PROCESSOR_TRANSFER = "/processor-transfer",
  PROCESSOR_TRANSFER_SUBSCRIPTION = "/processor-transfer-subscriptions",
  PROCESSOR_CASH = "/processor-cash",
  PROCESSOR_PAYOUT_TRANSFER = "/processor-payouts-transfer",
  PROCESSOR_PAYOUT_CASH = "/processor-payouts-cash",
  BILLING_MERCHANT = "/billing-merchant",
}
