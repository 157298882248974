import { createNamedStyles } from "../../../../shared/utils";

export const TableBodyStyles = createNamedStyles({
  cell: {
    color: "neutral.grey8",
  },
  checkbox: {
    color: "primary.main",
    padding: 0,
    "&.Mui-checked": {
      color: "primary.main",
    },
  },
  checkboxCell: {
    color: "neutral.grey7",
    textAlign: "center",
    padding: "0px 80px",
  },
  label: {
    fontSize: "13px",
    fontWeight: 400,
  },
  subLabel: {
    color: "text.grey",
    fontSize: "12px",
    fontWeight: 400,
  },
});
