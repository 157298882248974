import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((_theme) => ({
  dialogActions: {
    padding: "0px 24px 16px 24px",
  },
  dialogContainer: {
    color: "#293036",
    fontSize: "15px",
    lineHeight: "120%",
  },
  dialogDomain: {
    minHeight: "170px",
    padding: "8px",
    width: "635px",
  },
  dialogTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "140%",
  },
}));
