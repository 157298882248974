import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  DeferredOptionMerchant,
  UpdateDeferredMerchantRequest,
} from "../../../../types/update_deferred_merchant_request";
import {
  setIsDeferredLoaded,
  updateDeferredMerchantCentralAmerica,
} from "../../../store/actionCreators";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../shared/auth";
import { defaultTo, get } from "lodash";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { IAppState } from "../../../store/reducer";

export interface ICentralAmericaSectionState {
  type: string;
  merchantMonths: string[];
  months: string[];
  brandList: string[];
  handleSetMonth(event: React.MouseEvent<HTMLElement>, value: string[]): void;
  handleCancel(): void;
  handleSave(): void;
}

export interface ILocationCentralAmericaSection {
  processorName?: string;
  deferredType: string[];
  id: string;
  months: string[];
  merchantMonths: string[];
  type: string;
  terminalId?: string[];
}

export const useCentralAmericaSection = (): ICentralAmericaSectionState => {
  const history = useHistory();
  const allDeferred = useSelector((state: IAppState) => state.merchantDeferred);
  const merchant = auth.getAuthMerchant();
  const country = get(merchant, "country", CountriesEnum.ECUADOR);
  const [brandList, setBrandList] = useState<string[]>([]);
  const dispatch = useDispatch();
  const location = useLocation<ILocationCentralAmericaSection>();

  const {
    type,
    deferredType,
    id,
    processorName,
    months,
    merchantMonths,
    terminalId,
  } = location.state;

  const [selectedMonths, setSelectedMonths] = React.useState(merchantMonths);

  const handleSetMonth = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    setSelectedMonths(value);
  };

  const handleCancel = () => {
    dispatch(setIsDeferredLoaded(false));
    history.goBack();
  };

  const getBrand = (country: CountriesEnum): string[] => {
    switch (country) {
      case CountriesEnum.COSTA_RICA:
        return ["visa", "masterCard", "discover", "amex", "diners", "jcb"];
      case CountriesEnum.PANAMA:
        return ["visa", "masterCard", "amex"];
      default:
        return ["visa", "masterCard", "amex", "diners"];
    }
  };

  useEffect(() => {
    setBrandList(getBrand(country as CountriesEnum));
  }, []);

  const handleSave = () => {
    const merchantDeferred: UpdateDeferredMerchantRequest = {
      country,
      deferredOptions: [
        {
          deferredType,
          id,
          merchantMonths: defaultTo(selectedMonths, []),
          months,
          processorName,
          terminalId: terminalId,
        },
      ],
    };

    merchantDeferred.deferredOptions = allDeferred!.map((deferred) =>
      deferred.id === id ? merchantDeferred.deferredOptions[0] : deferred
    ) as DeferredOptionMerchant[];

    dispatch(
      updateDeferredMerchantCentralAmerica(
        merchant.publicMerchantId,
        merchantDeferred
      )
    );
    handleCancel();
  };

  return {
    brandList,
    handleCancel,
    handleSave,
    handleSetMonth,
    merchantMonths: selectedMonths,
    months,
    type,
  };
};
