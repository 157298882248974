import React, { FC, memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { get } from "lodash";
import { tableComponentStyles as styles } from "../../components/TableComponent/TableComponent.styles";
import { IRoles } from "./Roles.interfaces";

const Roles: FC<IRoles> = ({ row }: IRoles) => {
  const classes = styles();

  return (
    <Grid>
      <Typography className={classes.bodyCell}>
        {get(row, "roles", []).join(", ")}
      </Typography>
    </Grid>
  );
};

export default memo(Roles);
