import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerStyles = createNamedStyles({
  boxContent: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 3,
  },
  boxChild: {
    width: "20vw",
  },
  label: {
    color: "#023365",
    lineHeight: " 120%",
    letterSpacing: "-0.015em",
  },
});
