import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { WalletTransactionPath } from "../enums/wallet_transaction_path";
import { FilterEnum } from "../enums/filter";
import { TABLE_LABEL } from "./labels/table_labels";

export const TABLE_COLUMNS: HeaderCellProps[] = [
  {
    align: "left",
    spacing: 0,
    text: "Creación",
    type: "default",
  },
  {
    align: "left",
    spacing: 0,
    text: "Tipo de operación",
    type: "default",
  },
  {
    align: "left",
    spacing: 0,
    text: "ID de operación",
    type: "default",
  },
  {
    align: "left",
    spacing: 0,
    text: "Balance",
    type: "default",
  },
  {
    align: "left",
    spacing: 0,
    text: "Monto",
    type: "default",
  },
  {
    align: "center",
    spacing: 0,
    text: "Estado",
    type: "default",
  },
];

export const ItemsPerPage: number[] = [10, 20, 50];

export const TEXT_TWO_LINES: string = "TEXT_TWO_LINES";

export const WALLET_TRANSACTION_COLUMNS: string[] = [
  WalletTransactionPath.CREATED,
  WalletTransactionPath.TRX_TYPE,
  WalletTransactionPath.TRX_ID,
  WalletTransactionPath.BALANCE,
  WalletTransactionPath.TOTAL_AMOUNT,
  WalletTransactionPath.TRX_STATUS,
];

export const TEXT_TRANSFORM_COLUMNS: Record<string, string> = {
  [FilterEnum.APPROVAL]: TABLE_LABEL.approval,
  [FilterEnum.DECLINED]: TABLE_LABEL.declined,
};
