import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";

const styles = () => ({
  closeButton: {
    color: "#0E1E2E",
    "&.MuiIconButton-root": {
      padding: 3,
      marginBottom: 12,
      marginTop: 0,
      marginRight: 0,
      marginLeft: 24,
    },
  },
  dialogTitle: {
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 16,
    paddingLeft: 24,
  },
  dialogContentText: {
    marginBottom: 24,
    fontSize: 16,
  },
  dialogActions: {
    paddingRight: 24,
    paddingBottom: 12,
  },
  primaryColor: {
    color: "#023365",
  },
  errorColor: {
    color: "#E24763",
  },
  cancelAction: {
    color: "#0E1E2E",
  },
});

export interface ConfirmModalProps {
  open: boolean;
  titleText: string;
  contentText?: string;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  handleClose: () => void;
  handleAccept: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const inlineStyles = styles();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box style={inlineStyles.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" color="textPrimary">
            {props.titleText}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <IconButton
            aria-label="close"
            style={inlineStyles.closeButton}
            onClick={props.handleClose}
          >
            <X size={18} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText
            style={inlineStyles.dialogContentText}
            id="alert-dialog-description"
          >
            <Typography color="textPrimary">{props.contentText}</Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions style={inlineStyles.dialogActions}>
        <Button onClick={props.handleClose} style={inlineStyles.cancelAction}>
          <Typography style={{ textTransform: "none" }}>
            {props.cancelText}
          </Typography>
        </Button>
        <Button
          onClick={props.handleAccept}
          style={
            props.acceptColor === "error"
              ? inlineStyles.errorColor
              : inlineStyles.primaryColor
          }
          autoFocus
        >
          <Typography style={{ textTransform: "none" }}>
            {props.acceptText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
