import React, { useState } from "react";
import { Chip, Grid, Typography } from "@mui/material";
import { bankListChipStyles } from "./BankListChip.styles";
import { BankListChipProps } from "./BankListChip.interfaces";
import { DEFERRED_LABELS } from "../../shared/constants/labels/deferred_info_labels";

const BankListChip = (props: BankListChipProps) => {
  const [viewAll, setViewAll] = useState<boolean>(false);

  return (
    <>
      {!viewAll ? (
        <Grid
          container
          spacing={1}
          direction={"row"}
          alignItems={"center"}
          data-testid={"bank-list"}
        >
          {props.bankList.slice(0, 3).map((value: string, index: number) => (
            <Grid item key={index}>
              <Chip label={value} size="small" sx={bankListChipStyles.chip} />
            </Grid>
          ))}
          {props.bankList.length < 4 && props.bankList.length > 2 && (
            <Grid item>
              <Typography
                data-testid={"see-more-button"}
                variant="body2"
                sx={bankListChipStyles.link}
                onClick={() => setViewAll(true)}
              >
                <u data-testid={"see-more-options"}>
                  {DEFERRED_LABELS.SEE_MORE}
                </u>
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={1} direction={"row"} alignItems={"center"}>
          {props.bankList.map((value: string, index: number) => (
            <Grid item key={index}>
              <Chip label={value} size="small" sx={bankListChipStyles.chip} />
            </Grid>
          ))}
          {props.bankList.length < 4 && props.bankList.length > 2 && (
            <Grid item>
              <Typography
                variant="body2"
                sx={bankListChipStyles.link}
                data-testid={"see-less-button"}
                onClick={() => setViewAll(false)}
              >
                <u data-testid={"see-less-options"}>
                  {DEFERRED_LABELS.SEE_LESS}
                </u>
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default BankListChip;
