import { ConnectIndexProps } from "../ConnectIndex";
import { IPageCard } from "../../../components/CardConnect/CardConnect";
import developersImage from "../../../assets/images/developersImage.svg";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { auth } from "../../../shared/auth";
import { get } from "lodash";
import { SessionSupport } from "../../../../types/session_support";

export interface IConnectState {
  properties: {
    cardProps: IPageCard;
  };
  handler: {
    codeGenerate: (timeConnect: string) => void;
    close: (_event?: React.SyntheticEvent, reason?: string) => void;
  };
}

export const useConnectIndexState = (
  props: ConnectIndexProps
): IConnectState => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [showConnectOptions, setShowConnectOptions] = useState<boolean>(false);
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const changeShowConnectOptions = (value: boolean) => {
    setShowConnectOptions(value);
  };
  const onGetAccessCode = (timeConnect: string) => {
    setLoading(true);
    const payload: object = auth.getPayload();
    const roles: string[] = get(payload, "cognito:groups", []);
    const merchantName: string = get(auth.getAuthMerchant(), "name", "");
    const userFullName: string = auth.getFullName();
    props.getAccessCode({
      merchantName,
      timeConnect,
      userFullName,
      username: localStorage.getItem("username")!,
      merchantId: localStorage.getItem("merchantId")!,
      rol: roles,
      email: localStorage.getItem("email")!,
    });
  };
  const close = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    props.setNotification({ show: false, message: "", type: "success" });
  };

  const pageCardProps: IPageCard = {
    loading,
    changeShowConnectOptions,
    accessCode: props.state.accessCode!,
    title: "Asistencia Remota",
    optionsRadio: [
      { key: "30m", value: "30 minutos" },
      { key: "1h", value: "1 hora" },
      { key: "2h", value: "2 horas" },
      { key: "4h", value: "4 horas" },
    ],
    defaultRadioValue: "30m",
    image: developersImage,
    isMedium: isMedium,
    showBoxAccessCode: false,
    showConnectOptions,
  };

  const isValidSessionSupport = (sessionSupport: SessionSupport) => {
    const expirationDate = new Date(Number(sessionSupport.expired));
    const now = new Date();
    const isUserSupport = get(sessionSupport, "userSupport", "").length > 0;
    const userValidation = isUserSupport
      ? get(sessionSupport, "merchantPayload", "").length > 0
      : auth.getUserName() === sessionSupport.userMaster;

    return now < expirationDate && userValidation;
  };
  useEffect(() => {
    setLoading(false);
    if (props.state.accessCode) {
      changeShowConnectOptions(true);
      const eventAccessCode = new Event("build");
      localStorage.setItem("accessCodeSupport", props.state.accessCode);
      window.dispatchEvent(eventAccessCode);
      props.statusCodeSupport(props.state.accessCode);
    } else {
      const sessionSupport: SessionSupport = auth.getSessionSupport();
      if (sessionSupport) {
        if (isValidSessionSupport(sessionSupport))
          props.saveAccessCode(get(sessionSupport, "accessCode", ""));
        else {
          auth.removeSessionSupport();
          props.saveAccessCode("");
        }
      } else changeShowConnectOptions(false);
    }
  }, [props.state.accessCode]);
  useEffect(() => {
    setLoading(false);
  }, [props.state.notification]);
  return {
    properties: {
      cardProps: pageCardProps,
    },
    handler: {
      codeGenerate: onGetAccessCode,
      close,
    },
  };
};
