import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import {
  headerTableItem,
  resultLabelsBySearchType,
} from "../../shared/infrastructure/constants/WalletDashboardConstants";
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";
import { defaultTo } from "lodash";
import { Data } from "../../../types/filter_wallet_dashboard_response";
import { walletTableStyle } from "./WalletTable.style";
import { useWalletTableState } from "./state/useWalletTableState";
import { EmptyTable } from "../EmptyTable/EmptyTable";
import { FilterEnum } from "../../shared/infrastructure/enums/FilterEnum";
import { LabelsWallet } from "../../shared/infrastructure/enums/LabelWalletEnum";
import WalletPagination from "../WalletPagination/WalletPagination";
import { formatNumber, renderSymbol } from "../../shared/utils/utils";

export interface IWalletTableComponent {
  country: string;
  handleTransactionSelected(transactionSelected: Data): void;
}

export const WalletTable = (props: IWalletTableComponent) => {
  const classes = walletTableStyle();

  const {
    buildDate,
    getTransactionsResponse,
    isLoadingGetTransactions,
    searchType,
    renderOriginText,
    renderStatusText,
    renderTransactionTypeText,
    retrieveWithTable,
    sort,
    sortColumn,
  } = useWalletTableState();

  return !isLoadingGetTransactions &&
    defaultTo(getTransactionsResponse!.data, []).length === 0 ? (
    <Box className={classes.emptyResult}>
      <EmptyTable
        title={resultLabelsBySearchType[searchType!].title}
        description={resultLabelsBySearchType[searchType!].description}
      />
    </Box>
  ) : (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headerTableItem.map((data: string, index: number) => (
              <TableCell
                key={`${data}-${index}`}
                className={classes.title}
                style={{ width: retrieveWithTable(index) }}
              >
                {data !== LabelsWallet.DATE ? (
                  data
                ) : (
                  <TableSortLabel
                    direction={sort.direction}
                    onClick={() => {
                      sortColumn();
                    }}
                  >
                    {data}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingGetTransactions ? (
            <TableSkeleton />
          ) : (
            defaultTo(getTransactionsResponse!.data, []).map(
              (item: Data, index: number) => (
                <TableRow
                  key={`${item.created}-${index}`}
                  onClick={() => props.handleTransactionSelected(item)}
                >
                  <TableCell component="th" scope="row">
                    <Box className={classes.date}>
                      {buildDate(item.created, true)}
                      <Typography
                        variant={"subtitle2"}
                        className={classes.date}
                      >
                        {buildDate(item.created, false)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.textItem}>
                    <Box className={classes.textItem}>
                      {renderTransactionTypeText(item.transaction_type)}
                      <Typography
                        variant={"subtitle2"}
                        className={classes.textItem}
                      >
                        {renderOriginText(item)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.textItem}>
                    {item.origin_ticket_number === ""
                      ? item.transaction_id
                      : item.origin_ticket_number}
                  </TableCell>
                  <TableCell className={classes.textItem}>
                    {`${renderSymbol(item.transaction_type)} `}
                    {formatNumber(
                      item.total_amount,
                      item.currency,
                      props.country
                    )}
                  </TableCell>
                  <TableCell>
                    <Box
                      className={
                        item.transaction_status === FilterEnum.APPROVAL
                          ? classes.greenChip
                          : classes.redChip
                      }
                    >
                      <Typography
                        className={
                          item.transaction_status === FilterEnum.APPROVAL
                            ? classes.greenChipText
                            : classes.redChipText
                        }
                      >
                        {renderStatusText(item.transaction_status)}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )
          )}
        </TableBody>
      </Table>
      {!isLoadingGetTransactions &&
        defaultTo(getTransactionsResponse!.data, []).length != 0 && (
          <WalletPagination />
        )}
    </TableContainer>
  );
};
