import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MerchantProcessorFetch } from "../../shared/interfaces/MerchantProcessorFetch";
import { defaultTo, get } from "lodash";
import {
  ChipProcessorData,
  dataTableHeader,
  ProcessorStatusEnum,
  ProcessorTableConstants,
} from "../../shared/infrastructure/constants/MerchantConfigConstants";
import SimpleChip from "../SimpleChip/SimpleChip";
import { HasDefaultProcessor } from "../../shared/infrastructure/constants/ProcessorsEnum";

const useStyles = makeStyles(() => ({
  captionTitle: {
    backgroundColor: "#eff6fe",
    color: "#023365 !important",
    font: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    padding: "12px",
  },
  dataTableHeader: {
    fontWeight: 550,
    fontStyle: "normal",
    fontSize: "14px",
    color: "#293036",
  },
  tableContainer: {
    backgroundColor: "transparent",
  },
  chipContainer: {
    flex: "auto",
    display: "flex",
    justifyContent: "flex-start",
  },
}));

export interface IProcessorsTableProps {
  isHiddenTableHead: boolean;
  captionTableTitle: string;
  processorList: MerchantProcessorFetch[] | undefined;
  defaultProcessors?: object | undefined;
}

export const ProcessorsTable: React.FC<IProcessorsTableProps> = (
  props: React.PropsWithChildren<IProcessorsTableProps>
) => {
  const style = useStyles();

  const isDefaultProcessor = (alias: string) => {
    const defaultProcessors = get(
      props,
      "defaultProcessors.defaultProcessor",
      {}
    );
    return Object.keys(defaultProcessors).some(
      (key) => HasDefaultProcessor[key] && defaultProcessors[key] === alias
    );
  };

  return (
    <TableContainer className={style.tableContainer}>
      <Table>
        {!props.isHiddenTableHead && (
          <TableHead>
            <TableRow>
              {dataTableHeader.map((data, index) => (
                <TableCell
                  key={`${index}-${data.label}`}
                  align={"left"}
                  className={style.dataTableHeader}
                  style={{
                    width:
                      index === 0
                        ? "26%"
                        : index === 1
                        ? "15%"
                        : index === 2
                        ? "30%"
                        : index === 3
                        ? "12%"
                        : "25%",
                  }}
                >
                  {data.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        {get(props, "processorList", []).length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell className={style.captionTitle}>
                {props.captionTableTitle}
              </TableCell>
              <TableCell className={style.captionTitle} />
              <TableCell className={style.captionTitle} />
              <TableCell className={style.captionTitle} />
              <TableCell className={style.captionTitle} />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {get(props, "processorList", [])
            .slice(0, 5)
            .map((data: MerchantProcessorFetch) => (
              <TableRow key={`${data.alias}-${data.created}`}>
                <TableCell align={"left"} style={{ width: "26%" }}>
                  {data.publicProcessorId}
                </TableCell>
                <TableCell align={"left"} style={{ width: "15%" }}>
                  {data.alias}
                </TableCell>
                <TableCell align={"left"} style={{ width: "30%" }}>
                  <Grid container direction={"row"} spacing={3}>
                    <Grid item style={{ width: "40%" }}>
                      <Typography align={"left"} style={{ fontSize: "14px" }}>
                        {data.processorName}
                      </Typography>
                    </Grid>
                    <Grid item className={style.chipContainer}>
                      {isDefaultProcessor(data.alias) && (
                        <SimpleChip
                          type={"info"}
                          justifyContent={"center"}
                          label={ProcessorTableConstants.BY_DEFAULT}
                        />
                      )}
                      <SimpleChip
                        type={
                          defaultTo(
                            ChipProcessorData[data.status!],
                            ChipProcessorData[ProcessorStatusEnum.ENABLED]
                          ).type
                        }
                        justifyContent={"center"}
                        label={
                          defaultTo(
                            ChipProcessorData[data.status!],
                            ChipProcessorData[ProcessorStatusEnum.ENABLED]
                          ).label
                        }
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align={"left"} style={{ width: "12%" }}>
                  {`${defaultTo(
                    data.processorType?.slice(0, 1).toUpperCase(),
                    ""
                  )}${defaultTo(
                    data.processorType?.slice(1).toLowerCase(),
                    ""
                  )}`}
                </TableCell>
                <TableCell align={"left"} style={{ width: "25%" }}>
                  {data.failOverProcessor}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
