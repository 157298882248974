import { createAction } from "@reduxjs/toolkit";
import { ignoreElements, Observable, Subscriber, switchMap, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { EpicCustom } from "../epics.interfaces";
import { QueryChannelTransactionMetricsRequest } from "../../../../types/query_channel_transaction_metrics_request";
import { createWebsocket } from "../../../shared/utils/ws-util";
import { QueryChannelTransactionMetricsResponse } from "../../../../types/query_channel_transaction_metrics_response";
import { WebsocketNotifyEnum } from "../../../shared/enums/WebsocketNotifyEnum";
import {
  setQueryMetricsResponse,
  setQueryMetricsStatus,
} from "../../actions/app.actions";

const evaluateWebSocketMessage = (
  webSocket: WebSocket,
  subscriber: Subscriber<QueryChannelTransactionMetricsResponse>,
  event: MessageEvent
) => {
  const response: QueryChannelTransactionMetricsResponse = JSON.parse(
    event.data
  );

  if (
    response.status === WebsocketNotifyEnum.COMPLETED_EMPTY ||
    response.status === WebsocketNotifyEnum.COMPLETED_SUCCESS
  ) {
    subscriber.next(response);
    webSocket.close();
  }

  if (response.status === WebsocketNotifyEnum.ERROR) {
    subscriber.next();
    webSocket.close();
  }
};

export const getChannelMetrics =
  createAction<QueryChannelTransactionMetricsRequest>("GET_CHANNEL_METRICS");

export const getChannelMetricsEpic: EpicCustom = ({ action$, dispatch }) =>
  action$.pipe(
    filter(getChannelMetrics.match),
    switchMap(({ payload }) => {
      return new Observable<QueryChannelTransactionMetricsResponse>(
        (subscriber) => {
          const webSocket = createWebsocket();

          webSocket.addEventListener("open", () => {
            webSocket.send(JSON.stringify(payload));
          });
          webSocket.addEventListener("error", () => {
            subscriber.next();
            webSocket.close();
          });
          webSocket.addEventListener("message", (event: MessageEvent) => {
            evaluateWebSocketMessage(webSocket, subscriber, event);
          });
          webSocket.addEventListener("close", () => {
            subscriber.complete();
          });
        }
      );
    }),
    tap((response: QueryChannelTransactionMetricsResponse | undefined) => {
      if (response) {
        dispatch(setQueryMetricsStatus(response.status as WebsocketNotifyEnum));
        dispatch(setQueryMetricsResponse(response.payload.data));
      } else dispatch(setQueryMetricsStatus(WebsocketNotifyEnum.ERROR));
    }),
    ignoreElements()
  );
