import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNewRuleSectionState } from "./state/useNewRuleSectionState";
import {
  CardList,
  CardTable,
  IconEdit,
  IconTrash,
  ModalSimple,
} from "@kushki/connect-ui";

export interface NewRuleSectionProps {
  redirectEditRule: () => void;
  handleDeleteSelectedRule: () => void;
}

export const NewRuleSection = (props: NewRuleSectionProps) => {
  const {
    handle,
    openModal,
    showSectionSpeed,
    showSpecialConditionsSection,
    sectionSpeed,
    sectionConditionGeneral,
    sectionSpecialConditions,
    sectionResume,
    title,
  } = useNewRuleSectionState(props);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1.5}
        style={{
          border: "1px solid #E2E8F0",
          borderRadius: "8px",
          margin: 0,
          paddingLeft: "24px",
          paddingTop: "36px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid>
            <Typography variant="h5" component="h2" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            display={"flex"}
          >
            <IconButton
              sx={{
                margin: "0 30px",
              }}
              onClick={handle.handleOpenCloseDeleteModal}
            >
              <IconTrash color="primary" fontSize="medium" />
            </IconButton>
            <IconButton
              sx={{
                marginRight: "30px",
              }}
              onClick={props.redirectEditRule}
            >
              <IconEdit color="primary" fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} style={{ marginLeft: "-24px" }}>
          <CardList {...sectionResume} />
          <CardTable
            key={"sectionConditionGeneral"}
            {...sectionConditionGeneral}
          />
          {showSpecialConditionsSection && (
            <CardTable
              key={"sectionSpecialConditions"}
              {...sectionSpecialConditions}
            />
          )}
          {showSectionSpeed && (
            <CardTable key={"sectionSpeed"} {...sectionSpeed} />
          )}
        </Grid>
      </Grid>
      <ModalSimple
        descriptionText={"Una vez que se elimine no la podrá recuperar."}
        buttonPrimary={"Borrar regla"}
        buttonPrimaryDisabled={false}
        buttonSecondary={"Cancelar"}
        isOpen={openModal}
        onClickPrimary={handle.handleDeleteSelectedRule}
        onClickSecondary={handle.handleOpenCloseDeleteModal}
        onClose={handle.handleOpenCloseDeleteModal}
        titleText={"¿Esta seguro que desea borrar esta regla?"}
        typeModal={1}
      />
    </React.Fragment>
  );
};
