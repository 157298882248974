import { ISelectNewItemList } from "../../components/SelectNewItem/SelectNewItem.interfaces";

export const CLIENT_TYPE: ISelectNewItemList[] = [
  {
    label: "B2B",
    value: "B2B",
  },
  {
    label: "B2C",
    value: "B2C",
  },
  {
    label: "PSP",
    value: "PSP",
  },
];
