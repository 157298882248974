import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { capitalize, includes } from "lodash";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { useLocation } from "react-router-dom";
import { routes } from "../../../shared/infrastructure/routes";
import moment from "moment";
import {
  getCountry,
  ICountry,
} from "../../../shared/infrastructure/constants/MerchantContactConstants";
import useStyles from "./TitlePage.styles";
import { ITitlePageProps } from "./TitlePage.interfaces";

export const TitlePage: React.FC<ITitlePageProps> = (
  props: ITitlePageProps
) => {
  const clasess = useStyles();
  const { title, country } = props;
  const countryData: ICountry = getCountry(country);
  const param = useLocation();
  return (
    <Grid container className={clasess.root}>
      <Grid item xs={12}>
        <Typography variant="h1" color="primary">
          {title}
        </Typography>
      </Grid>
      {includes([CountryValueEnum.Colombia], capitalize(country)) &&
        param.pathname ===
          `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}` &&
        props.dateMax !== 0 && (
          <Grid item xs={12}>
            <Typography variant="caption" color="primary">
              {`Última actualización:${moment(props.dateMax).format(
                "DD-MM-YYYY "
              )}/${moment(props.dateMax).format("HH:mm")}hrs`}
            </Typography>
          </Grid>
        )}
      {includes([CountryValueEnum.Ecuador], capitalize(country)) &&
        param.pathname ===
          `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}` && (
          <Grid item xs={12}>
            <Box display="flex" alignItems={"center"}>
              <Typography variant="h5" color={"textSecondary"}>
                {countryData.name}
              </Typography>
              <img
                src={countryData.flag}
                className={clasess.flag}
                alt={countryData.flag}
              />
            </Box>
          </Grid>
        )}
      {param.pathname ===
        `${routes.BASE_PATH_RETENTIONS}${routes.CATALOG_FILE}` && (
        <Grid item xs={12}>
          <Box display="flex" alignItems={"center"}>
            <Typography variant="h5" color={"textSecondary"}>
              {countryData.name}
            </Typography>
            <img
              src={countryData.flag}
              className={clasess.flag}
              alt={countryData.flag}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
