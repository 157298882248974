import { ContactData } from "../../../types/contact_data";
import { assign, get, isEmpty } from "lodash";
import { ContactDataRequest } from "../../../types/contact_data_request";
import { RegularExp } from "../constants/form_labels_legal_details";
import { DynamoName } from "../enum/ContactDataStateMX/MerchantCompanyInfo";
import { FieldsEnum } from "../enums/FieldsEnum";
import { MerchantComplianceData } from "../../../types/merchant_compliance_data";
import { CommercialExecutiveList } from "../../../types/commercial_executive_list";
import { UseFormReturn } from "react-hook-form";

export const sortContactDataSections = (contactData: ContactData) => {
  return assign(
    { contactCommercialArea: contactData.contactCommercialArea! },
    { contactBillingArea: contactData.contactBillingArea! },
    { contactDispersionArea: contactData.contactDispersionArea! },
    { contactTechnicalArea: contactData.contactTechnicalArea! },
    { contactRiskArea: contactData.contactRiskArea! }
  );
};

export const getContactDataInitialValue = (
  initData: Partial<ContactDataRequest>
): Partial<ContactDataRequest> => {
  const request: Partial<ContactDataRequest> = {
    commercialExecutive: get(initData, FieldsEnum.commercialExecutive, ""),
    email: get(initData, FieldsEnum.email, ""),
    merchantRank: get(initData, FieldsEnum.merchantRank, ""),
    merchantSize: get(initData, FieldsEnum.merchantSize, ""),
    name: get(initData, FieldsEnum.name, ""),
  };

  return request;
};

export const validateCompleteData = (
  dataCompleted: boolean,
  nameComponent: string,
  nameValue: string
): boolean => {
  let validation: boolean = false;

  switch (nameComponent) {
    case DynamoName.NAME:
      if (isEmpty(nameValue) && !dataCompleted) {
        validation = true;
      }
      break;
    case DynamoName.EMAIL:
      if (isEmpty(nameValue) && !dataCompleted) {
        validation = true;
      }
      break;
  }

  return validation;
};

export const validateEmail = (value: string | undefined): boolean => {
  let valid: boolean = false;
  const regex: RegExp = new RegExp(RegularExp.EMAIL_NO_ACCENT);

  if (!isEmpty(value)) valid = regex.test(value!);

  return valid;
};

export const defaultValuesForm = (
  merchantComplianceDataObj: MerchantComplianceData,
  getEmails: object[]
) => {
  return {
    commercialExecutive: get(getEmails, "[0].id", ""),
    merchantSize: get(merchantComplianceDataObj, "categoryMerchant", ""),
  };
};

export const addDefaultCommercialExecutive = (
  commercialExecutiveList: CommercialExecutiveList[],
  setExecutiveList: (data: CommercialExecutiveList[]) => void
) => {
  const defaultData: CommercialExecutiveList = {
    commercialEmail: "soporte@kushkipagos.com",
    commercialName: "SOPORTE",
    id: "support",
  };

  const newCommercialExecutive: CommercialExecutiveList[] = [
    ...commercialExecutiveList,
  ];

  newCommercialExecutive.push(defaultData);

  setExecutiveList(newCommercialExecutive);
};

export const getError = (
  value: string,
  form: UseFormReturn<ContactDataRequest, object>
): boolean => {
  return !!form.formState.errors[value];
};
