import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

const SvgIcon = styled(MuiSvgIcon, {
  name: "UploadIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconUpload: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        fontSize={"large"}
      >
        <path
          d="M10.5 29.6852C8.9087 29.6852 7.38258 29.0531 6.25736 27.9278C5.13214 26.8026 4.5 25.2765 4.5 23.6852V19.9502C4.5 19.5524 4.65804 19.1708 4.93934 18.8895C5.22064 18.6082 5.60218 18.4502 6 18.4502C6.39782 18.4502 6.77936 18.6082 7.06066 18.8895C7.34196 19.1708 7.5 19.5524 7.5 19.9502V23.7002C7.5 24.4958 7.81607 25.2589 8.37868 25.8215C8.94129 26.3841 9.70435 26.7002 10.5 26.7002H25.5C26.2956 26.7002 27.0587 26.3841 27.6213 25.8215C28.1839 25.2589 28.5 24.4958 28.5 23.7002V19.9502C28.5 19.5524 28.658 19.1708 28.9393 18.8895C29.2206 18.6082 29.6022 18.4502 30 18.4502C30.3978 18.4502 30.7794 18.6082 31.0607 18.8895C31.342 19.1708 31.5 19.5524 31.5 19.9502V23.7002C31.5 25.2915 30.8679 26.8176 29.7426 27.9428C28.6174 29.0681 27.0913 29.7002 25.5 29.7002H10.5V29.6852Z"
          fill="#023365"
        />
        <path
          d="M18.0006 24.4199C17.6028 24.4199 17.2213 24.2619 16.94 23.9806C16.6586 23.6993 16.5006 23.3177 16.5006 22.9199V9.41992C16.5006 9.0221 16.6586 8.64057 16.94 8.35926C17.2213 8.07796 17.6028 7.91992 18.0006 7.91992C18.3984 7.91992 18.78 8.07796 19.0613 8.35926C19.3426 8.64057 19.5006 9.0221 19.5006 9.41992V22.9199C19.5006 23.3177 19.3426 23.6993 19.0613 23.9806C18.78 24.2619 18.3984 24.4199 18.0006 24.4199Z"
          fill="#023365"
        />
        <path
          d="M11.6272 15.4355C11.2337 15.4338 10.8566 15.2776 10.5772 15.0005C10.2876 14.7294 10.1172 14.3548 10.1031 13.9584C10.0891 13.5621 10.2325 13.1763 10.5022 12.8855L16.8472 6.49546C16.9866 6.35486 17.1525 6.24327 17.3353 6.16712C17.5181 6.09097 17.7142 6.05176 17.9122 6.05176C18.1102 6.05176 18.3063 6.09097 18.4891 6.16712C18.6718 6.24327 18.8377 6.35486 18.9772 6.49546L25.3522 12.8405C25.5203 12.968 25.6597 13.1296 25.7611 13.3147C25.8625 13.4998 25.9237 13.7043 25.9407 13.9146C25.9577 14.125 25.9301 14.3366 25.8598 14.5356C25.7894 14.7346 25.6778 14.9165 25.5323 15.0694C25.3868 15.2223 25.2107 15.3428 25.0155 15.423C24.8203 15.5032 24.6103 15.5413 24.3993 15.5348C24.1884 15.5283 23.9812 15.4773 23.7912 15.3852C23.6013 15.2932 23.433 15.162 23.2972 15.0005L18.0022 9.67545L12.6922 15.0005C12.552 15.1395 12.3858 15.2495 12.2031 15.3241C12.0203 15.3988 11.8246 15.4366 11.6272 15.4355Z"
          fill="#023365"
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconUpload;
