import { Alert, IconPlus, SplitButton } from "@kushki/connect-ui";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { RuleListProps } from "./BusinessRules.interfaces";
import { BusinessRulesData } from "./BusinessRules.data";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../shared/infrastructure/routes";
import { SectionContainer } from "../SectionContainer/SectionContainer";
import { ButtonVariantEnum } from "../../shared/enums/ButtonStylesEnum";
import { Configs, Data } from "../../../types/branch_edit_list";
import { emptyHandle, getStorageBranchEditList } from "../../utils/utilsFile";
import { ConfigurationCodeEnum } from "../../shared/enums/ProcessingStepEnum";
import { isEmpty } from "lodash";
import { ProcessorConstants } from "../../shared/constants/ProcessorConstants";
import { AlertPanelType } from "../AlertPanel/AlertPanel.data";
import { RuleContainer } from "./RuleContainer/RuleContainer";
import {
  isRulePendingStep,
  existPendingRulesSent,
} from "../../shared/utils/get-business-rules-status-step";
import { AddRuleOptionsEnum } from "../../shared/enums/RuleOptionsEnum";

const EmptyList = (isBatch?: boolean) => {
  const rulesMessage = isBatch
    ? BusinessRulesData.EmptyRulesBatchMessage
    : BusinessRulesData.EmptyRulesMessage;
  return (
    <Typography color="text.grey" variant={"body1"}>
      {rulesMessage}
    </Typography>
  );
};

const flagEditMassiveRules = (data: Data[] | undefined): boolean => {
  if (isEmpty(data)) {
    return false;
  }

  let haveCn007: boolean;
  let haveCn008: boolean;
  const arrFlagConfigRule: boolean[] = [];

  data!.forEach((dataElement: Data) => {
    haveCn007 = false;
    haveCn008 = true;
    if (isEmpty(dataElement.configs)) {
      return;
    }
    haveCn007 = dataElement
      .configs!.filter(
        (config: Configs) =>
          config.configuration === ConfigurationCodeEnum.PROCESSOR
      )
      .some((config: Configs) => !isEmpty(config.centralizedNodesId));
    haveCn008 = !dataElement
      .configs!.filter(
        (config: Configs) =>
          config.configuration === ConfigurationCodeEnum.BUSINESS_RULES
      )
      .some((config: Configs) => isEmpty(config.centralizedNodesId));

    if (!(haveCn007 && !haveCn008)) {
      arrFlagConfigRule.push(false);
    }
  });

  return arrFlagConfigRule.length === 0;
};

export const BusinessRules: React.FC<RuleListProps> = ({
  rules,
  payoutRules,
  deleteModalInfo,
  isBatch,
}: RuleListProps) => {
  const navigate = useNavigate();
  const flagEditMasiveRules: boolean = flagEditMassiveRules(
    getStorageBranchEditList().data
  );
  const isInvalidBatch: boolean = isBatch && !flagEditMasiveRules;

  return (
    <SectionContainer
      titleSection={isInvalidBatch ? "" : BusinessRulesData.HeaderBusinessRules}
    >
      {isInvalidBatch ? (
        <Alert
          title={ProcessorConstants.ALERT_DECENTRALIZE_PROCESSORS_EDIT_RULES}
          type={AlertPanelType.warning}
        />
      ) : (
        <Grid container>
          <Grid
            paddingBottom={"2rem"}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <SplitButton
              onItemSelected={emptyHandle}
              items={[
                {
                  text: AddRuleOptionsEnum.CardRule,
                  onSelect: () => {
                    navigate(AppRoutes.CREATE_RULE);
                  },
                  id: AddRuleOptionsEnum.CardRule,
                  variant: "text",
                },
                {
                  text: AddRuleOptionsEnum.PayoutRule,
                  onSelect: () => {
                    navigate(AppRoutes.CREATE_PAYOUT_RULE);
                  },
                  id: AddRuleOptionsEnum.PayoutRule,
                  variant: "text",
                },
              ]}
              type={"default"}
              variant={ButtonVariantEnum.SecondaryOutlined}
              size={"medium"}
              text={BusinessRulesData.AddNewRulesMessage}
              icon={<IconPlus />}
            />
          </Grid>
          <Grid item xs={6}>
            {isEmpty(rules) && isEmpty(payoutRules) && EmptyList(isBatch)}
          </Grid>
          {rules && rules.length > 0 && (
            <RuleContainer
              rules={rules}
              deleteModalInfo={deleteModalInfo}
              isBatch={isBatch}
              containerTitle={BusinessRulesData.CardRuleSection}
              existPendingRulesSent={existPendingRulesSent(rules)}
              existMoreThanFiveRules={rules.length > 5}
              isPendingDraft={isRulePendingStep(rules)}
            />
          )}
          {payoutRules && payoutRules.length > 0 && (
            <RuleContainer
              payoutRules={payoutRules}
              deleteModalInfo={deleteModalInfo}
              isBatch={isBatch}
              containerTitle={BusinessRulesData.PayoutRuleSection}
              existMoreThanFiveRules={payoutRules.length > 5}
              isPendingDraft={isRulePendingStep(payoutRules)}
              existPendingRulesSent={existPendingRulesSent(payoutRules)}
            />
          )}
        </Grid>
      )}
    </SectionContainer>
  );
};
