import React from "react";
import { TextField } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get, isEmpty } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { useWatch } from "react-hook-form";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { IBusinessRulesForm } from "../../../shared/infrastructure/interfaces/IBusinessRulesForm";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { findName } from "../../../shared/utils";

export interface AutocompleteMultipleFieldComponentProps {
  disabled: boolean;
  items: string[];
  options: IOptionSelection[];
  compare: "name" | "value";
  path: "name" | "value";
}

export const AutocompleteMultipleFieldComponent: React.FC<AutocompleteMultipleFieldComponentProps> =
  (props: AutocompleteMultipleFieldComponentProps) => {
    return (
      <ConnectForm<IBusinessRulesForm>>
        {({ control, errors }) => {
          const TypedController = useTypedController<IBusinessRulesForm>({
            control: control,
          });

          const value = useWatch({
            name: "rulesInputValue",
          }) as string[];

          return (
            <React.Fragment>
              <TypedController
                name={"rulesInputValue"}
                render={({ onChange }) => (
                  <Autocomplete
                    data-testid="rulesInputValueWrapper"
                    value={isEmpty(value) ? [] : value}
                    options={props.items}
                    onChange={(_event: any, value: any) => {
                      onChange(value);
                    }}
                    multiple={true}
                    defaultValue={value}
                    getOptionLabel={(option) =>
                      findName(props.options, option, props.compare, props.path)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!get(errors, "rulesInputValue")}
                        label={SecurityRulesLabelField.VALUE}
                        variant="outlined"
                        disabled={props.disabled}
                        fullWidth
                        helperText={
                          !!get(errors, "rulesInputValue") &&
                          ErrorsEnum.REQUIRED_FIELD
                        }
                      />
                    )}
                  />
                )}
              />
            </React.Fragment>
          );
        }}
      </ConnectForm>
    );
  };

export default AutocompleteMultipleFieldComponent;
