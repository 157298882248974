import { IconEdit } from "@kushki/connect-ui";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { Box, Button, Chip, Typography } from "@mui/material";
import { IBaseDataContainer } from "./BaseDataContainer.interface";
import { styles } from "./BaseDataContainer.styles";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { TEXT } from "../../../shared/constants/resume_contrainer";
import { defaultTo } from "lodash";
import AddUserButton from "../../AddUserButton/AddUserButton";
import { WalletButtonsTitle } from "../../../shared/infrastructure/constants/WalletResumeConstants";

const BaseDataContainer: React.FC<IBaseDataContainer> = (props) => {
  const {
    isCentralized,
    isUserTab,
    entityName,
    title,
    onClickEdit,
    children,
    withoutEdit,
    textEditButton,
    redirectCustomerButton,
    isWallet,
    onClickRedirectWallet,
  } = props;
  const isCentralizedAndIsNotCustomer = defaultTo(
    isCentralized && entityName !== EntityNameEnum.CUSTOMER,
    false
  );

  const getContainerClass = (isWallet?: boolean, isUserTab?: boolean) => {
    switch (true) {
      case isUserTab:
        return styles.boxContainerUser;
      case isWallet:
        return styles.boxContainerWalletButton;
      default:
        return styles.boxContainer;
    }
  };
  const getTypographyFontWeight = (isWallet?: boolean) => {
    switch (true) {
      case isWallet:
        return styles.typographyWallet;
      default:
        return null;
    }
  };

  return (
    <Box sx={styles.mainDataContainer}>
      <Box sx={styles.headerContainer}>
        <Box sx={getContainerClass(isWallet, isUserTab)}>
          <Typography
            color="primary.main"
            variant="h5"
            sx={getTypographyFontWeight(isWallet)}
          >
            {title}
          </Typography>
          {entityName === EntityNameEnum.BRANCH && (
            <Chip
              label={isCentralized ? TEXT.centralized : TEXT.decentralized}
              color={isCentralized ? "primary" : "default"}
            />
          )}
          {entityName === EntityNameEnum.CUSTOMER && isUserTab && (
            <AddUserButton redirectLink={redirectCustomerButton!} />
          )}
          {isWallet && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(event) => {
                onClickRedirectWallet && onClickRedirectWallet();
                event.stopPropagation();
              }}
            >
              {WalletButtonsTitle.CHECK_WALLET}
            </Button>
          )}
        </Box>
        <Box flexShrink={1} justifyContent="center">
          <ComponentSecurityWrapper>
            {!withoutEdit && (
              <Button
                className={textEditButton ? "buttonLink" : ""}
                color="primary"
                onClick={(event) => {
                  onClickEdit && onClickEdit();
                  event.stopPropagation();
                }}
                disabled={isCentralizedAndIsNotCustomer}
                variant={textEditButton ? "text" : "outlined"}
                sx={textEditButton ? styles.linkButton : styles.editButton}
                data-testid={"BaseDataContainerTestId"}
              >
                {textEditButton ? (
                  textEditButton
                ) : (
                  <IconEdit sx={styles.iconEdit} />
                )}
              </Button>
            )}
          </ComponentSecurityWrapper>
        </Box>
      </Box>
      <Box sx={styles.childrenContainer}>{children}</Box>
    </Box>
  );
};

export default BaseDataContainer;
