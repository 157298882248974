import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "../BasicStateGeneral/FieldsBasicData";

import {
  BasicDataLabels,
  ErrorMessageMX,
  TypeElement,
} from "../BasicStateMX/ErrorMessageMX";
import { RegularExp } from "../../constants/form_labels_legal_details";
import { get, isEmpty } from "lodash";
import { findDocTypeCountry } from "../../utils/findDocTypeCountry_utils";
import { PersonTypePANEnum } from "./PersonTypePANEnum";
import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { ErrorMessagePAN } from "./ErrorMessagePAN";
import { DocTypeNameEnum } from "../DocTypeNameEnum";
import { ErrorMessageGeneric } from "../BasicStateGeneral/ErrorMessage";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";

export const PersonTypePANList: Array<PersonTypeObjectInterface> = [
  { name: "Jurídica", value: "02" },
  { name: "Natural", value: "01" },
];

export const docTypePanama: PersonTypeObjectInterface[] = [
  { name: DocTypeNameEnum.CIP, value: "0" },
  { default: true, name: DocTypeNameEnum.NIT, value: "1" },
];

export const fieldRenderPAN: Array<PersonTypeInterface> = [
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX150,
        value: 150,
      },
      minLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 1,
      },

      required: {
        message: ErrorMessageGeneric.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: isEmpty(findDocTypeCountry(docTypePanama))
      ? DocTypeNameEnum.NIT
      : get(findDocTypeCountry(docTypePanama), "name"),
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessagePAN.LIMIT_ID,
        value: 13,
      },
      minLength: {
        message: ErrorMessagePAN.LIMIT_ID,
        value: 7,
      },
      required: {
        message: ErrorMessageGeneric.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: BasicDataLabels.TIPO_EMPRESA,
    name: FieldsNamesRender.type_company,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.JURIDICA,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageGeneric.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.NATURAL,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 150,
      },
      minLength: {
        message: ErrorMessageGeneric.MAX_1_50_CHAR,
        value: 1,
      },

      required: {
        message: ErrorMessageGeneric.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.NATURAL,
    label: isEmpty(findDocTypeCountry(docTypePanama))
      ? DocTypeNameEnum.NIT
      : get(findDocTypeCountry(docTypePanama), "name"),
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessagePAN.LIMIT_ID,
        value: 13,
      },
      minLength: {
        message: ErrorMessagePAN.LIMIT_ID,
        value: 7,
      },
      required: {
        message: ErrorMessageGeneric.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.NATURAL,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 12,
  },
  {
    filter: PersonTypePANEnum.NATURAL,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageGeneric.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.NATURAL,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypePANEnum.NATURAL,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageGeneric.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
];
