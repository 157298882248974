import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { useStyles } from "../../ModalFamily/EntityStyles/EntityStyles";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { IModalFamilySkeletonProps } from "./ModalFamilySkeleton.interfaces";

export const ModalFamilySkeleton: React.FC<IModalFamilySkeletonProps> = ({
  entityName,
}: IModalFamilySkeletonProps) => {
  const styles = useStyles();

  const getClassName = () => {
    switch (entityName) {
      case EntityNameEnum.OWNER:
        return styles.skeletonOwner;
      case EntityNameEnum.CUSTOMER:
        return styles.skeletonCustomer;
      default:
        return styles.skeletonBranch;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-testid={"modal-owner-family-skeleton"}
    >
      <Skeleton variant="rectangular" className={getClassName()} />
    </Grid>
  );
};
