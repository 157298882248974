import { useForm } from "react-hook-form";
import { updateLoadingForm } from "../../../store/actions/dispersion/dispersion.actions";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import { MerchantResponse } from "../../../../types/merchant_response";
import {
  getCommonPayloadData,
  getConfigsDispersion,
  getDispersionConfigPayload,
  getEnable4x1000,
  getIsExclusiveValue,
  getRatesPayload,
  handleDispatchBasicDataResponse,
  handleDispatchDispersionResponse,
  handleUseEffectDispersionFormLoaded,
  handleUseEffectIsAllDataLoaded,
  handleUseEffectMerchantNodeInfo,
  handleUseEffectNodeInfo,
  requestMerchantNodeInfo,
} from "../../../shared/utils/dispersionUtils";
import { getDispersionRatesConfig } from "../../../store/thunks/app/app.thunks";
import { NodeInfoResponse } from "../../../../types/node_info_response";
import {
  getBranchesToUpdate,
  handleCloseModalAndSave,
} from "../../../shared/utils/massiveBranchUtils";
import {
  Branch,
  Request,
} from "../../../../types/update_massive_branch_request";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { useDispatch } from "react-redux";
import { defaultTo, get, isEqual, isNil, omit } from "lodash";
import { ConfigIdDispersion } from "../../../shared/enums/dispersionsEnum";
import {
  HANDLE_MODAL_LOADER_WS,
  SET_FOOTER_BUTTON_CLICKED,
  SET_TYPE_REQUEST,
} from "../../../store/reducers/app/app.slice";
import { ConfigIdEnum } from "../../../shared/constants/node_config_enums";
import { TypeRequestEnum } from "../../../shared/enums/typeRequestEnum";
import {
  createNodePaths,
  projectPaths,
} from "../../../shared/enums/projects_path_enum";
import { editStatusEnum } from "../../../shared/constants/massiveEdit/massiveEditEnums";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { DispersionForm } from "../../../shared/interfaces/dispersion_form";

export const useMassiveDispersionContainer = () => {
  const dispatch = useDispatch();
  const {
    banksAccounts: bankAccounts,
    frequencyAndFraudForm,
    merchantNodeInfo,
    documentTypeConfig,
    loading: { loadingForm, loadingRatesConfig },
    footerButtonsClicked,
    updateMassiveBranchStatus,
    typeRequest: footerButtonType,
    buttons: { disabledContinueButton },
    configsResponse,
    massiveMostCurrentBranches: { dispersion: nodeInfo },
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.dispersion,
    ...state.massiveBranch,
  }));

  const [isBankDataUpdated, setBankDataUpdated] = useState<boolean>(false);
  const [isBeneficiaryNameUpdated, setIsBeneficiaryNameUpdated] =
    useState(false);
  const [merchantBasicData, setMerchantBasicData] =
    useState<MerchantResponse>();

  const [isExclusiveMassive, setIsExclusiveMassive] = useState<boolean>(false);
  const [constitutionalCountry] = useState<string>(
    get(nodeInfo, "generalInfo.constitutionalCountry", "")
  );

  const {
    control,
    resetField,
    reset,
    formState: { errors, isValid },
  } = useForm<DispersionForm>({
    defaultValues: {
      enable4x1000: frequencyAndFraudForm.enable4x1000.toString(),
      frequency: FrequencyEnum.NONE,
      keepCommission: frequencyAndFraudForm.keepCommission.toString(),
      keepFraud: frequencyAndFraudForm.keepFraud.toString(),
    },
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const requestBasicData = (configId: string, merchantId: string) => {
    requestMerchantNodeInfo(configId, merchantId, dispatch);
  };

  const requestDispersionData = (configId: string, merchantId: string) => {
    requestMerchantNodeInfo(configId, merchantId, dispatch);
  };

  const requestDispersionRatesConfig = (value: string) => {
    dispatch(getDispersionRatesConfig({ merchantId: value }));
  };

  const requestDataFromNodeInfo = (nodeInfo: NodeInfoResponse) => {
    const { configCn001, configCn004 } = getConfigsDispersion(nodeInfo);

    if (configCn001)
      requestBasicData(configCn001.configuration, configCn001.value);

    if (!configCn004) {
      setBankDataUpdated(true);
      dispatch(updateLoadingForm(false));

      return;
    }

    requestDispersionData(configCn004.configuration, configCn004.value);
    requestDispersionRatesConfig(configCn004.value);
  };

  const handleBasicDataResponse = async (response: MerchantResponse) => {
    await handleDispatchBasicDataResponse(response, dispatch);
    setIsBeneficiaryNameUpdated(true);
  };

  const handleDispersionDataResponse = async (response: MerchantResponse) => {
    await handleDispatchDispersionResponse(
      response,
      frequencyAndFraudForm,
      dispatch
    );
    setBankDataUpdated(true);
  };

  const saveMassiveDispersionData = () => {
    const branches: Branch[] = getBranchesToUpdate();
    const dispersionConfig = getDispersionConfigPayload({
      ...getCommonPayloadData(
        bankAccounts,
        nodeInfo,
        frequencyAndFraudForm,
        nodeInfo!.merchantId
      ),
      documentType: defaultTo(documentTypeConfig, "0"),
      isExclusive: isExclusiveMassive,
      taxId: defaultTo(merchantBasicData?.taxId, ""),
    });
    const ratesPayload = getRatesPayload({
      ...getCommonPayloadData(
        bankAccounts,
        nodeInfo,
        frequencyAndFraudForm,
        nodeInfo!.merchantId
      ),
      clientType: get(merchantBasicData, "clientType", ""),
    });

    const dispersionConfigInfo = get(dispersionConfig, "dispersionConfigInfo");
    const country = get(dispersionConfig, "country");
    const constitutionalCountry = get(
      dispersionConfig,
      "constitutionalCountry"
    );

    const requestSaveDispersionMassive: Request = {
      branches: branches,
      configId: ConfigIdDispersion.CN004,
      data: {
        constitutionalCountry,
        country,
        dispersionConfigPayload: omit(dispersionConfig, ["publicMerchantId"]),
        dispersionMerchantPayload: {
          configId: ConfigIdDispersion.CN004,
          dispersion: {
            accountInfo: get(dispersionConfigInfo, "accountInfo"),
            enable4x1000: getEnable4x1000(
              constitutionalCountry,
              get(dispersionConfig, "dispersionConfigInfo.enable4x1000", false)
            ),
            isExclusive: getIsExclusiveValue(
              constitutionalCountry,
              get(dispersionConfig, "dispersionConfigInfo.isExclusive")
            ),
            keepCommission: get(
              dispersionConfig,
              "dispersionConfigInfo.keepCommission"
            ),
          },
          dispersionFrequency: get(
            dispersionConfig,
            "dispersionConfigInfo.frequency"
          ),
        },
        ratesPayload: {
          ...ratesPayload,
        },
      },
      status: disabledContinueButton
        ? editStatusEnum.PENDING
        : editStatusEnum.COMPLETE,
      userAlias: localStorage.getItem("username") ?? "backoffice",
      userName: localStorage.getItem("username") ?? "backoffice",
    };

    dispatch(
      updateMassiveBranch(
        requestSaveDispersionMassive,
        ConfigIdEnum.CN004,
        configsResponse!
      )
    );
    dispatch(HANDLE_MODAL_LOADER_WS(true));
  };

  useEffect(() => {
    handleUseEffectMerchantNodeInfo(
      merchantNodeInfo,
      setMerchantBasicData,
      handleBasicDataResponse,
      handleDispersionDataResponse,
      isEqual(constitutionalCountry, CountriesEnum.CHILE)
        ? setIsExclusiveMassive
        : undefined
    );
  }, [merchantNodeInfo]);

  useEffect(() => {
    handleUseEffectNodeInfo(nodeInfo, requestDataFromNodeInfo, dispatch);
  }, [nodeInfo]);

  useEffect(() => {
    if (
      isExclusiveMassive &&
      isEqual(constitutionalCountry, CountriesEnum.CHILE)
    ) {
      const values: DispersionForm = {
        enable4x1000: getEnable4x1000(
          constitutionalCountry,
          get(frequencyAndFraudForm, "enable4x1000", false)
        ).toString(),
        fraudPercentage: get(
          frequencyAndFraudForm,
          "fraudPercentage",
          ""
        ).toString(),
        frequency: FrequencyEnum.NONE,
        isExclusive: get(frequencyAndFraudForm, "isExclusive", "").toString(),
        keepCommission: frequencyAndFraudForm.keepCommission.toString(),
        keepFraud: frequencyAndFraudForm.keepFraud.toString(),
        retentionPeriod: get(
          frequencyAndFraudForm,
          "retentionPeriod",
          ""
        ).toString(),
      };

      reset(values);
    }
  }, [isExclusiveMassive]);

  useEffect(() => {
    handleUseEffectIsAllDataLoaded(
      loadingForm,
      loadingRatesConfig,
      isBankDataUpdated,
      isBeneficiaryNameUpdated,
      isValid,
      bankAccounts,
      dispatch
    );
  }, [
    isValid,
    bankAccounts,
    loadingForm,
    loadingRatesConfig,
    isBankDataUpdated,
    isBeneficiaryNameUpdated,
  ]);

  useEffect(() => {
    if (footerButtonsClicked && nodeInfo && nodeInfo.merchantId) {
      if (footerButtonType === TypeRequestEnum.SAVE) {
        saveMassiveDispersionData();
        dispatch(HANDLE_MODAL_LOADER_WS(true));
      }

      if (footerButtonType === TypeRequestEnum.FINISHED) {
        saveMassiveDispersionData();
      }
    }
  }, [footerButtonsClicked]);

  useEffect(() => {
    handleUseEffectDispersionFormLoaded(
      loadingForm,
      loadingRatesConfig,
      frequencyAndFraudForm,
      reset
    );
  }, [loadingForm, loadingRatesConfig]);

  useEffect(() => {
    handleCloseModalAndSave(updateMassiveBranchStatus, dispatch);
    if (!isNil(updateMassiveBranchStatus) && !updateMassiveBranchStatus) {
      dispatch(SET_FOOTER_BUTTON_CLICKED(false));
      if (footerButtonType === TypeRequestEnum.FINISHED) {
        window.location.href = projectPaths.CREATE_NODE.concat(
          createNodePaths.BRANCH_EDIT
        );
      }
      dispatch(SET_TYPE_REQUEST(""));
    }
    dispatch(SET_FOOTER_BUTTON_CLICKED(false));
  }, [updateMassiveBranchStatus]);

  return {
    form: {
      control,
      errors,
      reset,
      resetField,
    },
    isExclusiveMassive,
    loading: { loadingForm, loadingRatesConfig },
    setIsExclusiveMassive,
    showBankData: isBankDataUpdated && isBeneficiaryNameUpdated,
  };
};
