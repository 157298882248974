import { CatalogsNameEnum } from "./CatalogsNameEnum";

export const CatalogsRecord: Record<CatalogsNameEnum, string> = {
  [CatalogsNameEnum.ACTIVITY]: "Actividad",
  [CatalogsNameEnum.INCOME_SOURCE_CONCEPT]: "Concepto de retención en la renta",
  [CatalogsNameEnum.TAX_PAYER_TYPE]: "Tipo de contribuyente",
};

export const RetentionCatalogId: Record<CatalogsNameEnum, string> = {
  [CatalogsNameEnum.ACTIVITY]: "activityId",
  [CatalogsNameEnum.INCOME_SOURCE_CONCEPT]: "incomeSourceConceptId",
  [CatalogsNameEnum.TAX_PAYER_TYPE]: "taxpayerTypeId",
};

export const RegExpressionCatalogs: Record<CatalogsNameEnum, string> = {
  [CatalogsNameEnum.ACTIVITY]: "^A0[1-9]|^A[1-9][0-9]{1,2}$",
  [CatalogsNameEnum.INCOME_SOURCE_CONCEPT]: "^CR0[1-9]|^CR[1-9][0-9]{1,2}$",
  [CatalogsNameEnum.TAX_PAYER_TYPE]: "^C0[1-9]|^C[1-9][0-9]{1,2}$",
};

export const CatalogsArray: CatalogsNameEnum[] = [
  CatalogsNameEnum.ACTIVITY,
  CatalogsNameEnum.INCOME_SOURCE_CONCEPT,
  CatalogsNameEnum.TAX_PAYER_TYPE,
];

export enum CatalogPropertyEnum {
  ACTION = "action",
  CODE = "code",
  DATA = "data",
  ID = "id",
  NAME = "name",
  VALUE = "value",
}
