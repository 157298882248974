import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: { color: "#023365" },
    kshBtnFilter: {
      marginTop: "4px",
      padding: "7px 30px",
      backgroundColor: "#FFFFFF",
    },
    kshBtnProcess: {
      marginTop: "0px",
      padding: "7px 30px",
    },
    filterDash: {
      marginTop: "4px",
      padding: "7px 15px",
      backgroundColor: "#f7fafc",
      color: "#293036",
      width: "100%",
      marginLeft: "0px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginTop: "4px",
      height: "36px",
      minWidth: "28ch",
      padding: 0,
      textAlign: "initial",
      "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
        fontSize: 14,
      },
    },
    td: {
      paddingTop: 0,
      paddingBottom: 0,
      width: 225,
    },
    td_large: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    credit_note_alert: {
      marginLeft: 18,
    },
    divider: {
      width: 250,
      marginTop: 5,
      marginBottom: 3,
    },
    itemEnd: {
      alignItems: "center",
    },
    itemCenter: {
      alignItems: "center",
    },
    marginLeft: {
      marginLeft: "18px",
    },
    processModalInfo: {
      marginTop: theme.spacing(2),
      "&.MuiGrid-root .MuiSvgIcon-root": {
        margin: `0px ${theme.spacing(1)}px`,
      },
    },
    iconButton: {
      "& .MuiIconButton-root": {
        height: "41px",
        width: "39px",
        marginTop: "2px",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: "white",
      },
    },
  })
);
