import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import { ICheckboxItem } from "../PaymentMethodCheckList/usePaymentMethodCheckList/usePaymentMethodCheckList";
import { ServiceOptionEnum } from "../../shared/infrastructure/constants/OptionsEnum";

export interface CheckboxListProps {
  title: string;
  items: ICheckboxItem[];
  selectedItems: string[];
  onChange: (value: string) => void;
}

const useStyles = makeStyles({
  title: {
    color: "#6D7781",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
  },
});

const CheckboxList: React.FC<CheckboxListProps> = (
  props: CheckboxListProps
) => {
  const { title, items, selectedItems, onChange } = props;
  const classes = useStyles();

  const optionsPaymentList = items.filter(
    (item) => item.value !== ServiceOptionEnum.CHARGEBACK_VOID
  );

  return (
    <FormControl variant="outlined" fullWidth margin="none">
      <FormLabel>
        <span className={classes.title}>{title}</span>
      </FormLabel>
      <Box>
        <FormGroup row>
          {optionsPaymentList.map(
            ({ label, value }, index) =>
              !!selectedItems && (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={() => onChange(value)}
                      checked={selectedItems.indexOf(value) > -1}
                    />
                  }
                  label={`${label}`}
                />
              )
          )}
        </FormGroup>
      </Box>
    </FormControl>
  );
};

export { CheckboxList };
