import { createTheme } from "@material-ui/core/styles";
import { themeOptions } from "@epas1117/kushki-mui-theme";

const theme = createTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
  },
  typography: {
    ...themeOptions.typography,
  },
  overrides: {
    ...themeOptions.overrides,
  },
});

export default theme;
