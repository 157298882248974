export enum SemaphoreItemsEnum {
  basicDetail = "statusBasicDetail",
  billingDetail = "statusBillingDetail",
  contactDetail = "statusContactDetail",
  legalDetail = "statusLegalDetail",
}

export const ArraySemaphoreSteps: SemaphoreItemsEnum[] = [
  SemaphoreItemsEnum.basicDetail,
  SemaphoreItemsEnum.billingDetail,
  SemaphoreItemsEnum.legalDetail,
  SemaphoreItemsEnum.contactDetail,
];
