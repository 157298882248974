import { ICellTagProps, ICellText } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  HeaderCellProps,
  ITableCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";

export enum CELL_TYPES {
  TEXT = "text",
  TITLE = "title",
  TAG = "tag",
}
type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

export type Role = {
  id: string;
  value: string;
  parentMerchantEnable?: boolean;
};

export enum UsersProperties {
  NAME = "name",
  USERNAME = "userName",
  MAIL = "mail",
  ROLES = "roles",
}

export const TEXT_CELL = (line1: string = "", line2?: string) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      line2,
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);
export const TEXT_TAG = (text: string = "") =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      color: "primary",
      text: text,
    } as ICellTagProps,
    type: "TAG" as TableBodyCellEnum,
  } as ITableCellProps);
export const ALL_TABLE_COLUMNS_USERS: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: UsersProperties.NAME,
    spacing: 0,
    text: "Nombre",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: UsersProperties.USERNAME,
    spacing: 0,
    text: "Nombre de usuario",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: UsersProperties.MAIL,
    spacing: 0,
    text: "Email",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TAG,
    key: UsersProperties.ROLES,
    spacing: 0,
    text: "Roles",
  },
];

export const TABLE_ROW_USERS = {
  [CELL_TYPES.TEXT]: TEXT_CELL,
  [CELL_TYPES.TAG]: TEXT_TAG,
};

export enum usersTextEnum {
  TITLE = "Usuarios agregados",
  CUSTOMER_TITLE = "Usuarios del Customer",
  LINK = "Edita y revisa todos los usuarios",
}

export const ROLES_MERCHANT_BACKOFFICE: Role[] = [
  {
    id: "BackofficeUserMaster",
    value: "Usuario Maestro",
  },
  {
    id: "BackofficeMasterCustomer",
    value: "Usuario Maestro Customer",
  },

  { id: "VirtualPOS", value: "Virtual POS" },
  {
    id: "BackofficeUserMaker",
    parentMerchantEnable: true,
    value: "Creador de usuarios",
  },
  { id: "BackofficeMasterCredentials", value: "Maestro de Credenciales" },
  { id: "WebcheckoutMaster", value: "Maestro Smartlink" },
  {
    id: "BackofficeUserReader",
    parentMerchantEnable: true,
    value: "Usuario de lectura",
  },
  { id: "WebcheckoutBasic", value: "Smartlink" },
  { id: "ExpressCheckoutMaster", value: "Express Checkout" },
];
export const ROLES: Role[] = [...ROLES_MERCHANT_BACKOFFICE];
