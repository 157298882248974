import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { configsIds } from "../../../shared/constants/configIds";
import { merchantNodeInfoDefaultState } from "../../../shared/constants/initial_state_legal_details";
import { MerchantResponse } from "../../../../types/get_merchant_response";
import { CompliancePeopleNodeResponse } from "../../../../types/get_compliance_people_response";
import { IMerchantPeopleNodeRequest } from "../../interfaces/LegalDetailsState.interfaces";

export const getMerchantNodeInfo = createAsyncThunk(
  "legalDetails/merchantNodeIfo",
  (merchantId: string) => {
    return axios
      .post(
        API_ROUTES.MERCHANT_NODE_INFO,
        { configId: configsIds, publicMerchantId: merchantId },
        {
          headers: {
            Authorization: getJwtAuth(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: AxiosResponse<MerchantResponse>) => response.data)
      .catch(() => merchantNodeInfoDefaultState);
  }
);

export const getCompliancePeople = createAsyncThunk(
  "legalDetails/getCompliancePeople",
  async (merchantId: string) => {
    const response = await axios.get<CompliancePeopleNodeResponse>(
      API_ROUTES.COMPLIANCE_PEOPLE_GET(merchantId),
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);

export const saveLegalDetails = createAsyncThunk<
  boolean,
  IMerchantPeopleNodeRequest
>(
  "legalDetails/saveLegalDetails",
  async (merchantPeopleNode: IMerchantPeopleNodeRequest) => {
    const response = await axios.post<boolean>(
      API_ROUTES.SAVE_MERCHANT_PEOPLE_NODE,
      merchantPeopleNode,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);
