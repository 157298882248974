import React from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, Edit, Trash2 } from "react-feather";
import { SecurityRulesByType } from "../../../types/security_rules_by_type";
import { isEmpty, get, defaultTo } from "lodash";
import {
  AlertDialog,
  ButtonText,
  ButtonTitles,
  MessageDisabledIconDelete,
  TableTitle,
  TabsTitle,
} from "../../shared/infrastructure/constants/RulesConstants";
import { makeStyles } from "@material-ui/core/styles";
import { RuleItemsObject } from "../../../types/rule_items_object";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityRulesRoles } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import {
  formatDate,
  getTooltipEdit,
  is3dsOtp,
  isDisabledOrPending,
} from "../../shared/utils";
import { PAYMENT_METHOD } from "../../shared/infrastructure/enums/PaymentMethodEnum";

export interface DataTableRulesProps {
  isLoading: boolean | undefined;
  securityRuleItems: SecurityRulesByType | undefined;
  nextAction: () => void;
  backAction: () => void;
  deleteRule: (ruleId: string) => void;
  handlerShowModal: () => void;
  handlerShowModalRuleByType: (ruleId: string, ruleType: string) => void;
}

const useStyles = makeStyles((theme) => ({
  tableHover: {
    "&:hover": {
      opacity: 0.9,
      cursor: "pointer",
    },
  },
  tableHeaderStyle: {
    fontWeight: "bold",
  },
  tooltipInfo: {
    maxWidth: "200px",
    backgroundColor: "#E9EDF1",
    color: "#64798D",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  tooltipArrow: {
    color: "#E9EDF1",
  },
  dialogTitle: {
    fontWeight: "bold",
    color: "#023365",
    marginTop: "10px",
  },
  dialogBody: {
    color: "#293036",
  },
  dialogActions: {
    paddingBottom: "20px",
    paddingRight: "20px",
  },
  cancelButton: {
    fontSize: "15px",
    color: "black",
    paddingRight: "10px",
  },
  deleteRuleButton: {
    fontSize: "15px",
    color: "red",
  },
  iconsTable: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      margin: 1,
      width: 24,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 1,
      width: 18,
    },
  },
}));

export const DataTableRules: React.FC<DataTableRulesProps> = (
  props: DataTableRulesProps
) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ruleId, setRuleId] = React.useState("");

  const handleOpenDialog = (ruleId: string) => {
    setOpen(true);
    setRuleId(ruleId);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Paper>
        <TableContainer style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.CREADO}
                </TableCell>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.ALIAS}
                </TableCell>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.ACCION}
                </TableCell>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TabsTitle.PAISES}
                </TableCell>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.MEDIO_PAGO}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(get(props, "securityRuleItems.items", []) == null
                ? []
                : get(props, "securityRuleItems.items", [])
              ).map((rule: RuleItemsObject) => (
                <TableRow key={rule.id}>
                  <TableCell
                    align={"left"}
                    className={classes.tableHover}
                    onClick={() =>
                      props.handlerShowModalRuleByType(rule.id, rule.type)
                    }
                  >
                    <div>
                      <Typography
                        align={"left"}
                        color={"textPrimary"}
                        variant={"body2"}
                      >
                        {formatDate(get(rule, "createdAt", 0)).split("/")[0]}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        align={"left"}
                        variant={"caption"}
                        color={"textSecondary"}
                      >
                        {formatDate(get(rule, "createdAt", 0)).split("/")[1]}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    align={"left"}
                    className={classes.tableHover}
                    onClick={() =>
                      props.handlerShowModalRuleByType(rule.id, rule.type)
                    }
                  >
                    <Typography
                      align={"left"}
                      color={"textPrimary"}
                      variant={"body2"}
                    >
                      {get(rule, "rule.name")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={"left"}
                    className={classes.tableHover}
                    onClick={() =>
                      props.handlerShowModalRuleByType(rule.id, rule.type)
                    }
                  >
                    <Typography
                      align={"left"}
                      color={"textPrimary"}
                      variant={"body2"}
                    >
                      {get(rule, "rule.action")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={"left"}
                    className={classes.tableHover}
                    onClick={() =>
                      props.handlerShowModalRuleByType(rule.id, rule.type)
                    }
                  >
                    {(get(rule, "country", []) == null
                      ? []
                      : get(rule, "country", [])
                    ).map((country: string, index: number) => (
                      <Chip
                        key={index}
                        label={country}
                        style={{ marginLeft: "4px", marginTop: "3px" }}
                      />
                    ))}
                  </TableCell>
                  <TableCell align={"left"}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box mt={2}>
                          <Typography
                            align={"left"}
                            color={"textPrimary"}
                            variant={"body2"}
                          >
                            {defaultTo(
                              PAYMENT_METHOD.get(get(rule, "service")),
                              get(rule, "service", "")
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          title={getTooltipEdit(rule)}
                          classes={{
                            tooltip: classes.tooltipInfo,
                            arrow: classes.tooltipArrow,
                          }}
                          placement={"top-start"}
                          arrow={true}
                        >
                          <span>
                            <IconButton
                              className={classes.iconsTable}
                              aria-label="edit"
                              onClick={() => {
                                window.location.href = `/rules/security/rule/${rule.id}`;
                              }}
                              disabled={
                                isDisabledOrPending(rule) && is3dsOtp(rule)
                              }
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={
                            is3dsOtp(rule)
                              ? MessageDisabledIconDelete.DELETE
                              : ""
                          }
                          classes={{
                            tooltip: classes.tooltipInfo,
                            arrow: classes.tooltipArrow,
                          }}
                          placement={"top-start"}
                          arrow={true}
                        >
                          <span>
                            <ComponentSecurityWrapper
                              componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${SecurityRulesRoles.COUNTRY_DELETE}`}
                            >
                              <IconButton
                                className={classes.iconsTable}
                                aria-label="trash"
                                onClick={() => {
                                  handleOpenDialog(get(rule, "id", ""));
                                }}
                                disabled={is3dsOtp(rule)}
                              >
                                <Trash2 fontSize="small" />
                              </IconButton>
                            </ComponentSecurityWrapper>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" style={{ width: "100%" }}>
          <Box flexGrow={1} />
          <Box p={1}>
            <Button
              id="button_back"
              onClick={props.backAction}
              style={{
                textTransform: "capitalize",
              }}
              startIcon={<ChevronLeft xlinkTitle="back" size={22} />}
            >
              {ButtonTitles.BACK}
            </Button>
            <Button
              id="button_next"
              disabled={isEmpty(get(props, "securityRuleItems.items"))}
              onClick={props.nextAction}
              style={{
                textTransform: "capitalize",
              }}
              endIcon={<ChevronRight xlinkTitle="next" size={22} />}
            >
              {ButtonTitles.NEXT}
            </Button>
          </Box>
        </Box>
      </Paper>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle className={classes.dialogTitle}>
          {AlertDialog.TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogBody}>
            {AlertDialog.BODY}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.cancelButton} onClick={handleCloseDialog}>
            {ButtonText.CANCEL}
          </Button>
          <Button
            className={classes.deleteRuleButton}
            onClick={() => {
              props.deleteRule(ruleId);
              handleCloseDialog();
            }}
          >
            {ButtonText.DELETE_RULE}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
