import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store.interfaces";
import {
  hideLoading as hideLoadingRedux,
  hideRefundModal as hideRefundModalRedux,
  setLoadingText as setLoadingTextRedux,
  setRefundModalData as setRefundModalDataRedux,
  showLoading as showLoadingRedux,
  showRefundModal as showRefundModalRedux,
} from "../actions/actions";
import { generateCertificateEpic as generateCertificateEpicRedux } from "../epics";
import { IResendRefundData } from "../interfaces/ResendRefundData.interfaces";
import {
  resendCertificateEpic as resendCertificateEpicRedux,
  resendRefundEpic as resendRefundEpicRedux,
} from "../epics/";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useKshRedux = () => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state: RootState) => state.app);

  const showRefundModal = () => {
    dispatch(showRefundModalRedux());
  };

  const hideRefundModal = () => {
    dispatch(hideRefundModalRedux());
  };

  const setRefundModalData = (resendRefundData: IResendRefundData) => {
    dispatch(setRefundModalDataRedux(resendRefundData));
  };

  const hideLoading = () => {
    dispatch(hideLoadingRedux());
  };

  const setLoadingText = (loadingText: string) => {
    dispatch(setLoadingTextRedux(loadingText));
  };

  const showLoading = () => {
    dispatch(showLoadingRedux());
  };

  const generateCertificateEpic = async (data: Object) => {
    const response = await generateCertificateEpicRedux(data, dispatch);

    return response;
  };

  const resendCertificateEpic = async (data: Object) => {
    const response = await resendCertificateEpicRedux(data, dispatch);

    return response;
  };

  const resendRefundEpic = async (data: Object) => {
    const response = await resendRefundEpicRedux(data, dispatch);

    return response;
  };

  return {
    actions: {
      generateCertificateEpic,
      hideLoading,
      hideRefundModal,
      resendCertificateEpic,
      resendRefundEpic,
      setLoadingText,
      setRefundModalData,
      showLoading,
      showRefundModal,
    },
    store,
  };
};
