import React from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Edit from "../../assets/images/Edit";
import { useMerchantInformationStyles } from "./MerchantInformation.styles";
import { useMerchantInformationState } from "./state/useMerchantInformationState";
import MerchantInfoSkeleton from "../../components/Skeletons/MerchantInfoSkeleton/MerchantInfoSkeleton";
import { useBasicInformation } from "../BasicInformation/state/useBasicInformation";

const MerchantInformation = () => {
  const {
    ciiu,
    isLoading,
    legalShareholders,
    mainLegalRepresentative,
    naturalShareholders,
    mcc,
    showPartnerInformation,
  } = useMerchantInformationState();

  const { handleEditMerchant } = useBasicInformation();
  const styles = useMerchantInformationStyles();

  const showNaturalShareholders =
    !isLoading && naturalShareholders && naturalShareholders.length > 0;
  const showLegalShareholders =
    !isLoading && legalShareholders && legalShareholders.length > 0;

  return (
    <Box className={styles.container}>
      <Grid container justifyContent="flex-end">
        <Button
          className={styles.editButton}
          onClick={() => handleEditMerchant()}
        >
          <Edit />
        </Button>
      </Grid>
      <Grid className={styles.information}>
        {isLoading ? (
          <MerchantInfoSkeleton />
        ) : (
          <Grid>
            <Typography className={styles.label}>CIIU:</Typography>
            <Typography className={styles.label}>MCC:</Typography>
            <Typography className={styles.label}>
              Representante Legal Principal:
            </Typography>
            {showPartnerInformation && (
              <>
                {showNaturalShareholders && (
                  <Typography className={styles.label}>
                    Socios naturales:
                  </Typography>
                )}
                {showLegalShareholders && (
                  <Typography className={styles.label}>
                    Socios juridicos:
                  </Typography>
                )}
              </>
            )}
          </Grid>
        )}
        {isLoading ? (
          <MerchantInfoSkeleton />
        ) : (
          <Grid className={styles.descriptonContainer}>
            <Grid>
              <Typography className={styles.description}>{ciiu}</Typography>
              <Typography className={styles.description}>{mcc}</Typography>
              <Typography className={styles.description}>
                {mainLegalRepresentative}
              </Typography>
              {showPartnerInformation && (
                <>
                  {showNaturalShareholders && (
                    <Typography className={styles.description}>
                      {naturalShareholders?.length} socios
                    </Typography>
                  )}
                  {showLegalShareholders && (
                    <Typography className={styles.description}>
                      {legalShareholders?.length} socios
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid>
              <Box className={styles.tooltipContainer}>
                {mcc && (
                  <Tooltip arrow placement="top-start" title={mcc}>
                    <InfoOutlinedIcon className={styles.infoIcon} />
                  </Tooltip>
                )}
              </Box>
              <Box className={styles.tooltipContainer}>
                {ciiu && (
                  <Tooltip arrow placement="top-start" title={ciiu}>
                    <InfoOutlinedIcon className={styles.infoIcon} />
                  </Tooltip>
                )}
              </Box>
              <Box className={styles.tooltipContainer}>
                <Tooltip
                  arrow
                  placement="top-start"
                  title={`${mainLegalRepresentative}`}
                >
                  <InfoOutlinedIcon className={styles.infoIcon} />
                </Tooltip>
              </Box>
              {showNaturalShareholders && (
                <Box className={styles.tooltipContainer}>
                  {naturalShareholders && naturalShareholders.length > 0 && (
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <ul className={styles.listContainer}>
                          {naturalShareholders.map((shareholder) => (
                            <li key={shareholder.documentNumber}>
                              {shareholder.firstName} {shareholder.secondName}{" "}
                              {shareholder.surName} {shareholder.lastName}
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <InfoOutlinedIcon className={styles.infoIcon} />
                    </Tooltip>
                  )}
                </Box>
              )}
              {showLegalShareholders && (
                <Box className={styles.tooltipContainer}>
                  {legalShareholders && legalShareholders.length > 0 && (
                    <Tooltip
                      arrow
                      placement="top-start"
                      title={
                        <ul className={styles.listContainer}>
                          {legalShareholders.map((shareholder) => (
                            <li key={shareholder.documentNumber}>
                              {shareholder.socialReason}
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <InfoOutlinedIcon className={styles.infoIcon} />
                    </Tooltip>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MerchantInformation;
