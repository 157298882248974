import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import theme from "../../../theme";
import Select from "@material-ui/core/Select";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import { get } from "lodash";
import { connect } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { ProcessorStatusEnum } from "../../../shared/infrastructure/constants/ProcessorsEnum";

export interface IModalDialog {
  open: boolean;
  buttonOkLabel: string;
  title: string;
  contentText: string;
  buttonCancelLabel: string;
  handleCloseFailOver: () => void;
  handleOnChangeFailOver(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void;
  handleAssignDialogFailOver(): void;
  processors?: ProcessorFetch[];
  processorSelected: string;
  processor: ProcessorFetch;
}
export interface IModalDialogStatePros {
  loading?: boolean;
}
export type TModalDialog = IModalDialog & IModalDialogStatePros;

export const ModalDialog: React.FC<TModalDialog> = (props: TModalDialog) => {
  const getProcessors = (
    processors: ProcessorFetch[],
    processor: ProcessorFetch
  ): ProcessorFetch[] => {
    return processors.filter(
      (item: ProcessorFetch) =>
        get(item, "processorName", "") !==
          get(processor, "processorName", "") &&
        get(item, "paymentMethod", "-") === "card" &&
        get(item, "status", "") !== ProcessorStatusEnum.DISABLED
    );
  };
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleCloseFailOver}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: 10 }}>
          <Typography
            variant="h6"
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: "#000000",
              padding: 12,
            }}
          >
            {props.title}
          </Typography>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
            onClick={props.handleCloseFailOver}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ padding: "8px 15px" }}>
            <DialogContentText id="alert-dialog-description">
              {props.contentText}
            </DialogContentText>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-processor-native-simple">
                Procesador
              </InputLabel>
              <Select
                autoWidth={false}
                native
                value={props.processorSelected}
                label="Procesador"
                onChange={props.handleOnChangeFailOver}
                inputProps={{
                  name: "Procesador",
                  id: "outlined-processor-native-simple",
                }}
              >
                <option value="None">Ninguno</option>
                {props.processors &&
                  getProcessors(props.processors!, props.processor).map(
                    (processor: ProcessorFetch) => (
                      <option
                        key={processor.publicProcessorId}
                        value={get(processor, "alias", "")}
                      >
                        {`${get(processor, "processorName", "")} - ${get(
                          processor,
                          "alias",
                          ""
                        )} `}
                      </option>
                    )
                  )}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ padding: "8px 25px" }}>
            <Button
              onClick={props.handleCloseFailOver}
              style={{ color: "#293036", fontSize: 16 }}
            >
              {props.buttonCancelLabel}
            </Button>
            <Button
              id="ok_modal"
              color="primary"
              onClick={props.handleAssignDialogFailOver}
              style={{ fontSize: 16 }}
              disabled={props.loading}
            >
              {props.buttonOkLabel}
              {props.loading && (
                <Box ml={1} display="flex" alignItems="center">
                  <CircularProgress color="inherit" size={20} />
                </Box>
              )}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => IModalDialogStatePros = (
  state: IAppState
): IModalDialogStatePros => ({
  loading: state.loading,
});

export default connect(mapStateToProps, null)(ModalDialog);
