import React from "react";
const BackRegisterIcon: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#F0F4F9" />
      <path
        d="M14 17.3333L10.6667 14L14 10.6666"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 21.3333V16.6667C21.3333 15.9594 21.0524 15.2811 20.5523 14.781C20.0522 14.281 19.3739 14 18.6667 14H10.6667"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackRegisterIcon;
