import React, { FC } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import * as BILLING_LABELS from "../../shared/constants/labels/billing_form_labels";
import { ITransactionSectionProps } from "./TransactionSection.interfaces";
import { transactionSectionStyles as styles } from "./TransactionSection.styles";
import { useTransactionSection } from "./state/useTransactionSection";
import EditSection from "../EditSection/EditSection";
import { ModalLoader } from "@kushki/connect-ui";
import TransactionSnackBar from "../TransactionSnackBar/TransactionSnackBar";
import { YesNoEnum } from "../../shared/enums/dispersionsEnum";
import {
  DECLINED_DESCRIPTION_TOOLTIP_LBL,
  TOOLTIP_LBL,
} from "../../shared/constants/labels/billing_form_labels";

const TransactionSection: FC<ITransactionSectionProps> = ({
  country,
  isCentralized,
}: ITransactionSectionProps) => {
  const {
    showSection,
    handleOnChange,
    checkBillItems,
    checkDeclinedItems,
    checkSubtractItems,
    handleOnChangeCheckBox,
    openLoader,
    showSnackBar,
    switchBilling,
    switchDeclined,
    switchSubtract,
    setSwitchSubtract,
    setSwitchDeclined,
    setSwitchBilling,
  } = useTransactionSection({
    country,
  });

  return (
    <Box pt={4}>
      <Typography variant={"body1"} sx={styles.title}>
        {BILLING_LABELS.TRANSACTION_TYPE_TITTLE}
      </Typography>
      <Typography variant={"body2"} sx={styles.subtitle}>
        {BILLING_LABELS.MODIFY_QUESTION}
      </Typography>
      <FormControl>
        <RadioGroup
          defaultValue={YesNoEnum.NO}
          value={showSection ? YesNoEnum.YES : YesNoEnum.NO}
          onChange={(item) => handleOnChange(item.target.value)}
          row
        >
          <FormControlLabel
            disabled={isCentralized}
            value={YesNoEnum.NO}
            control={<Radio />}
            label={YesNoEnum.NO}
          />
          <FormControlLabel
            disabled={isCentralized}
            value={YesNoEnum.YES}
            control={<Radio />}
            label={YesNoEnum.YES}
          />
        </RadioGroup>
      </FormControl>
      {showSection && (
        <Box sx={styles.optionsContainer}>
          <EditSection
            isCentralized={isCentralized}
            title={BILLING_LABELS.TRANSACTION_TO_BILLING}
            description={BILLING_LABELS.BILLING_DESCRIPTION}
            checkBoxLabels={checkBillItems}
            handleCheckOnChange={handleOnChangeCheckBox}
            switchState={switchBilling}
            setSwitchValue={setSwitchBilling}
            setTooltip={TOOLTIP_LBL}
          />
          <Box sx={styles.optionsContainer}>
            <EditSection
              isCentralized={isCentralized}
              title={BILLING_LABELS.TRANSACTION_TO_DECLINED}
              description={BILLING_LABELS.DECLINED_DESCRIPTION}
              checkBoxLabels={checkDeclinedItems}
              handleCheckOnChange={handleOnChangeCheckBox}
              switchState={switchDeclined}
              setSwitchValue={setSwitchDeclined}
              setTooltip={DECLINED_DESCRIPTION_TOOLTIP_LBL}
            />
          </Box>
          <Box sx={styles.optionsContainer}>
            <EditSection
              isCentralized={isCentralized}
              title={BILLING_LABELS.TRANSACTION_TO_SUBTRACT}
              description={BILLING_LABELS.SUBTRACT_DESCRIPTION}
              checkBoxLabels={checkSubtractItems}
              handleCheckOnChange={handleOnChangeCheckBox}
              switchState={switchSubtract}
              setSwitchValue={setSwitchSubtract}
              setTooltip={TOOLTIP_LBL}
            />
          </Box>
        </Box>
      )}
      <ModalLoader
        descriptionText={BILLING_LABELS.DESCRIPTION_LOADER}
        isOpen={openLoader}
        titleText={BILLING_LABELS.LOADER_TITLE}
      />
      <TransactionSnackBar
        message={BILLING_LABELS.SNACKBAR_LBL}
        open={showSnackBar}
      />
    </Box>
  );
};

export default TransactionSection;
