import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const editSectionStyles = createNamedStyles({
  container: {
    pb: 1.625,
  },
  title: {
    pr: 2.5,
    color: "text.dark",
  },
  checkBoxArea: {
    maxWidth: (theme) => theme.spacing(74.125),
  },
  label: {
    ".MuiTypography-root": {
      fontSize: "14px",
    },
    fontWeight: "400",
  },
});
