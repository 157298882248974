import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import LanguageIcon from "@material-ui/icons/Language";
import Box from "@material-ui/core/Box";
import { useLanguageSelector } from "./state/useLanguageSelector";
import { ILanguageSelectorProps } from "./LanguageSelector.interface";

const useStyles = makeStyles(() =>
  createStyles({
    languageButton: {
      fontSize: "1rem",
    },
    textItem: {
      padding: ".25rem 1.5rem",
      fontSize: "1rem",
    },
    wrapper: {
      textDecoration: "none",
    },
  })
);

export const LanguageSelector: React.FC<ILanguageSelectorProps> = (props) => {
  const classes = useStyles();
  const {
    handlers: { showLanguages, handleClose, handleChange },
    anchorEl,
    currentLanguage,
    displayedLanguages,
  } = useLanguageSelector();

  return (
    <Box style={props?.style}>
      <Button
        className={classes.languageButton}
        startIcon={<LanguageIcon />}
        onClick={showLanguages}
      >
        {/*@ts-ignore */}
        <div data-notranslate>{currentLanguage.shortName}</div>
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {displayedLanguages.map(({ code, fullName, shortName }) => (
          <MenuItem
            value={code}
            key={code}
            onClick={() => {
              handleChange({ code, fullName, shortName });
              handleClose();
            }}
          >
            {/*@ts-ignore */}
            <div data-notranslate>
              <ListItemText className={classes.textItem} primary={fullName} />
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
