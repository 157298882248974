import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";

export const DetailFooterStyles: IStyles = {
  box: {
    height: "68px",
    width: "100%",
    backgroundColor: "#F0F4F9",
    display: "flex",
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  addCommentBtn: {
    margin: "auto",
    backgroundColor: "#023365",
    color: "white",
    typography: "body2",
    borderRadius: "4px",
    fontWeight: 500,
    marginLeft: "26px",
    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.24)",
    border: "none",
  },
};
