import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const messageBoxStyles = createNamedStyles({
  container: {
    padding: "12px 17px",
    backgroundColor: "#F0F4F8",
    border: "1px solid #4498EE",
    borderRadius: "4px",
    color: "#4498EE",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 500,
    fontSize: "16px",
  },
  description: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "14px",
    paddingTop: "5px",
  },
});
