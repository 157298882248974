import React from "react";
import { Container, Divider, Paper, Typography } from "@mui/material";
import { alarmConfigStyles as styles } from "@containers/AlarmConfig/AlarmConfig.styles";
import Footer from "@components/Footer/Footer";
import { IconMarketing, ModalSimple } from "@kushki/connect-ui";
import { useAlarmConfig } from "@containers/AlarmConfig/state/useAlarmConfig";
import { FormProvider } from "react-hook-form";
import HelperCard from "@components/HelperCard/HelperCard";
import SelectedCustomers from "@components/SelectedCustomers/SelectedCustomers";
import AlarmConfigForm from "@components/AlarmConfigForm/AlarmConfigForm";
import { defaultTo } from "lodash";
import {
  AlarmConfigLabel,
  AlarmConfigModalLabel,
} from "@shared/constants/labels/alarmConfigLabels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import GenericHeader from "@components/GenericHeader/GenericHeader";
import { WrapperContainer } from "@containers/WrapperContainer/WrapperContainer";
import { AlarmTypeParse } from "@shared/constants/AlarmConfigConstants";
import { labelByAlarmType } from "@shared/constants/labels/alarmLabels";

const AlarmConfig: React.FC = () => {
  const {
    form,
    pathParam,
    openCancelModal,
    selectedNodes,
    handleOpenCancelModal,
    handleCloseCancelModal,
    handlePrimaryButtonModal,
    handleSaveRules,
    isDisabledButton,
    isEdit,
    showSelectedNodes,
    isLoadingEditForm,
  } = useAlarmConfig();

  return (
    <WrapperContainer>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM_INDIVIDUAL}
      >
        <GenericHeader
          hasBoldText={true}
          title={`${defaultTo(
            labelByAlarmType[
              defaultTo(AlarmTypeParse[defaultTo(pathParam, "")], "")
            ][`${isEdit}`].header,
            ""
          )}`}
        />
        <Container sx={styles.container}>
          <Paper elevation={2} sx={styles.cardContainer}>
            <Typography variant={"h3"} sx={styles.title}>
              {`${defaultTo(
                labelByAlarmType[
                  defaultTo(AlarmTypeParse[defaultTo(pathParam, "")], "")
                ][`${isEdit}`].title,
                ""
              )}`}
            </Typography>
            <Divider sx={styles.divider} />
            <HelperCard
              text={AlarmConfigLabel.HELPER_CARD}
              icon={<IconMarketing />}
            />
            {showSelectedNodes && (
              <SelectedCustomers
                pathParam={defaultTo(pathParam, "")}
                subtitle={AlarmConfigLabel.NODES_TITLE}
                buttonLabel={AlarmConfigLabel.NODES_EDIT}
                selectedNodes={selectedNodes}
                isDisabled={isEdit}
              />
            )}
            <Divider sx={styles.divider} />
            <FormProvider {...form}>
              <AlarmConfigForm
                isDisabledAlias={isEdit}
                isLoadingEditForm={isLoadingEditForm}
              />
            </FormProvider>
          </Paper>
        </Container>
        <Footer
          primaryButtonLoading={isDisabledButton}
          primaryButtonDisabled={false}
          handleSecondaryButton={handleOpenCancelModal}
          handlePrimaryButton={handleSaveRules}
          primaryButtonText={"Guardar"}
          secondaryButtonText={"Cancelar"}
          componentIdPrimaryButton={
            SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM_SAVE
          }
          componentIdSecondaryButton={
            SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM_CANCEL
          }
          componentIdAltPrimaryButton={
            SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_EDIT
          }
          isEdit={isEdit}
        />
        <ModalSimple
          onClickPrimary={handlePrimaryButtonModal}
          onClickSecondary={handleCloseCancelModal}
          buttonPrimary={AlarmConfigModalLabel.PRIMARY_BUTTON}
          buttonSecondary={AlarmConfigModalLabel.SECONDARY_BUTTON}
          buttonPrimaryColor={"error"}
          descriptionText={AlarmConfigModalLabel.DESCRIPTION}
          isOpen={openCancelModal}
          onClose={handleCloseCancelModal}
          titleText={AlarmConfigModalLabel.TITLE}
          typeModal={1}
        />
      </ComponentSecurityWrapper>
    </WrapperContainer>
  );
};

export default AlarmConfig;
