export enum RouteEnum {
  DASHBOARD = "dashboard",
  SNR = "snr",
  SMARTLINKS = "smartlinks",
  TRANSACTIONS = "transactions",
  USERS = "users",
  REPORTS = "reports",
  BANK_CONCILIATION_SUMMARY = "bank-conciliation/summary",
  BANK_CONCILIATION_TRANSACTION = "bank-conciliation/transactions",
}
