import { TransactionType } from "../../enums/transaction_types";

const CANCEL_BUTTON_TEXT = "Cancelar";
const RETURN_BUTTON = "Regresar";
const CREDIT_BUTTON_TEXT = "Acreditar";
const DEBIT_BUTTON_TEXT = "Debitar";

const ACCEPT_BUTTON_TEXT: Record<string, string> = {
  [TransactionType.CREDIT]: CREDIT_BUTTON_TEXT,
  [TransactionType.DEBIT]: DEBIT_BUTTON_TEXT,
};

export default {
  ACCEPT_BUTTON_TEXT,
  CANCEL_BUTTON_TEXT,
  RETURN_BUTTON,
};
