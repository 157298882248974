export enum BranchEnum {
  HEADER_TITLE = "Listado de Branches",
  CREATE_BRANCH = "Crear Branch",
  ADD_BRANCHES = "Agrega Branches",
  SEARCH_MESSAGE = "Buscar por nombre de Branch o Branch ID",
  MESSAGE_TOOLTIP = "Las acciones masivas están deshabilitadas para branches con estado (Pendiente)",
  NO_DATA = "Aún no tienes información registrada",
  BUTTON_DOWNLOAD_TITLE = "Descargar listado",
}

export enum StatusEnumMonth {
  Enero = "ene",
  Febrero = "feb",
  Marzo = "mar",
  Abril = "abr",
  Mayo = "may",
  Junio = "jun",
  Julio = "jul",
  Agosto = "ago",
  Septiembre = "sep",
  Octubre = "oct",
  Noviembre = "nov",
  Diciembre = "dic",
}

export const CatalogTranslateMonth: Record<StatusEnumMonth, string> = {
  [StatusEnumMonth.Enero]: "01",
  [StatusEnumMonth.Febrero]: "02",
  [StatusEnumMonth.Marzo]: "03",
  [StatusEnumMonth.Abril]: "04",
  [StatusEnumMonth.Mayo]: "05",
  [StatusEnumMonth.Junio]: "06",
  [StatusEnumMonth.Julio]: "07",
  [StatusEnumMonth.Agosto]: "08",
  [StatusEnumMonth.Septiembre]: "09",
  [StatusEnumMonth.Octubre]: "10",
  [StatusEnumMonth.Noviembre]: "11",
  [StatusEnumMonth.Diciembre]: "12",
};

export enum FormatDateEnum {
  D_MMM_YYY = "D/MMM/YYYY",
}

export enum ConfigUserModalEnum {
  TITLE = "¿Deseas configurar usuarios para tus branches?",
  DESCRIPTION = "Es necesario configurar un Usuario para cada Branch, puedes configurarlos de forma masiva desde el resumen del Customer.",
  PRIMARY_BUTTON = "Configurar usuarios",
  SECONDARY_BUTTON = "En otro momento",
}

export enum VariantButtonEnum {
  CONTAINED = "contained",
}
