import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import { ColumnSelectEnum } from "../enums/ColumnSelectEnum";
import {
  IConfigCells,
  TableBodyCellEnum,
} from "../constants/table_transactions";
import { isCustomerRol } from "../utils";
import {
  HeaderColumnIdEnum,
  TOOLTIP_LABELS,
} from "../enums/HeaderColumnIdEnum";

export const headCellsCharge: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    hasTooltip: true,
    id: "created",
    isActive: true,
    label: "Fecha inicio",
    tooltipLabel: TOOLTIP_LABELS[HeaderColumnIdEnum.CREATED],
  },
  {
    align: "left",
    disablePadding: false,
    id: "merchant_name",
    isActive: isCustomerRol(),
    label: "Branch",
  },
  {
    align: "left",
    disablePadding: false,
    id: "type",
    isActive: true,
    label: "Tipo",
  },
  {
    align: "left",
    disablePadding: false,
    id: "approved_transaction_amount",
    isActive: true,
    label: "Monto",
  },
  {
    align: "left",
    disablePadding: false,
    id: "method",
    isActive: false,
    label: "Medio",
  },
  {
    align: "left",
    disablePadding: false,
    id: "subscription",
    isActive: true,
    label: "Id suscripción",
  },
  {
    align: "left",
    disablePadding: false,
    id: "transaction_status",
    isActive: true,
    label: "Estado",
  },
  {
    align: "left",
    disablePadding: false,
    id: "refund",
    isActive: true,
    label: "",
  },
];

export const CatalogConfigTableCharge: IConfigCells[] = [
  {
    align: "center",
    isActive: true,
    isChecked: false,
    type: TableBodyCellEnum.CHECK,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "created",
      isDate: true,
      path: "created",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created",
      isDate: true,
      path: "created",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "merchantName",
      hasUrl: false,
      path: "merchant_name",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "merchantId",
      isDate: false,
      path: "merchant_id",
      text: "$var",
    },
    isActive: isCustomerRol(),
    type: TableBodyCellEnum.TITLE_COPY,
  },
  {
    align: "left",
    configSecondary: {
      defaultValue: "transactionType",
      isType: true,
      path: "transaction_type",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "left",
    configSecondary: {
      defaultValue: "subtotal_iva0",
      isAmount: true,
      path: "subtotal_iva0",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "left",
    configSecondary: {
      defaultValue: "paymentMethod",
      isPaymentMethod: true,
      path: "payment_method",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "TicketNumber",
      path: "ticket_number",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TEXT_COPY,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "inactive",
      path: "transaction_status",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "",
      path: "pending_section",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TAG,
  },
  {
    align: "center",
    configMain: {
      path: "refund",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.ACTION_MENU,
  },
];

export const LeftRowFixed: IConfigCells[] = [
  {
    align: "center",
    isActive: true,
    isChecked: false,
    type: TableBodyCellEnum.CHECK,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "created",
      isDate: true,
      path: "created",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created",
      isDate: true,
      path: "created",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
];
export const AdminLeftRowFixed: IConfigCells[] = [
  {
    align: "center",
    isActive: true,
    isChecked: false,
    type: TableBodyCellEnum.CHECK,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "created",
      isDate: true,
      path: "created",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created",
      isDate: true,
      path: "created",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
];
export const StatusRow: IConfigCells = {
  align: "left",
  configMain: {
    defaultValue: "inactive",
    path: "transaction_status",
    text: "$var",
  },
  configSecondary: {
    defaultValue: "",
    path: "pending_section",
    text: "$var",
  },
  isActive: true,
  type: TableBodyCellEnum.TAG,
};
export const ActionRow: IConfigCells = {
  align: "center",
  configMain: {
    path: "refund",
    text: "$var",
  },
  isActive: true,
  type: TableBodyCellEnum.ACTION_MENU,
};
export const HeaderTableFixed: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    hasTooltip: true,
    id: "created",
    isActive: true,
    label: "Fecha inicio",
    tooltipLabel: TOOLTIP_LABELS[HeaderColumnIdEnum.CREATED],
  },
];
export const AdminHeaderTableFixed: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    hasTooltip: true,
    id: "created",
    isActive: true,
    label: "Fecha inicio",
    tooltipLabel: TOOLTIP_LABELS[HeaderColumnIdEnum.CREATED],
  },
];

export const StatusHeader: HeadCell = {
  align: "left",
  disablePadding: false,
  id: "transaction_status",
  isActive: true,
  label: "Estado",
};
export const ActionHeader: HeadCell = {
  align: "left",
  disablePadding: false,
  id: "refund",
  isActive: true,
  label: "",
};

export const CustomRowTable = {
  [ColumnSelectEnum.TYPE]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: true,
      path: "transaction_type",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.AMOUNT]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isAmount: true,
      path: "subtotal_iva0",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.METHOD]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isPaymentMethod: true,
      path: "payment_method",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.TICKET_NUMBER]: {
    align: "left",
    configMain: {
      defaultValue: "",
      path: "ticket_number",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TEXT_COPY,
  },
  [ColumnSelectEnum.CARD_NUMBER]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "masked_credit_card",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.CARD_BRAND]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "payment_brand",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.ISSUING_BANK]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "issuing_bank",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.SUBSCRIPTION_ID]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "subscription_id",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.NAME_ON_CARD]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "card_holder_name",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.EMAIL]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "contact_details.email",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.CREDENTIAL_ALIASES]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "credential_alias",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.CREDENTIAL_ID]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "credential_id",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.PUBLIC_KEY]: {
    align: "left",
    configSecondary: {
      defaultValue: "",
      isType: false,
      path: "public_credential_id",
      text: "$var",
    },
    isActive: true,
    isCodeCountry: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.OWNER]: {
    align: "left",
    configMain: {
      defaultValue: HeaderColumnIdEnum.DEFAULT_VALUE,
      isDate: false,
      path: "owner_name",
      text: "$var",
    },
    isActive: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.CUSTOMER]: {
    align: "left",
    configMain: {
      defaultValue: HeaderColumnIdEnum.DEFAULT_VALUE,
      isDate: false,
      path: "customer_name",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "customerId",
      isDate: false,
      path: "customer_id",
      text: "$var",
    },
    isActive: false,
    type: TableBodyCellEnum.TITLE,
  },
  [ColumnSelectEnum.BRANCH]: {
    align: "left",
    configMain: {
      defaultValue: "merchantName",
      hasUrl: false,
      path: "merchant_name",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "merchantId",
      isDate: false,
      path: "merchant_id",
      text: "$var",
    },
    isActive: false,
    type: TableBodyCellEnum.TITLE_COPY,
  },
  [ColumnSelectEnum.COMPLETED]: {
    align: "left",
    configMain: {
      defaultValue: "completed",
      isDate: true,
      path: "completed",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "completed",
      isDate: true,
      path: "completed",
      text: "$var",
    },
    isActive: true,
    type: TableBodyCellEnum.TITLE,
  },
};
