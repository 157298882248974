import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMerchantNodes } from "../../interfaces/nodes/nodes.interfaces";
import getMerchantNodes from "../../thunks/nodes/nodes.thunks";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";

export const initialState: IMerchantNodes = {
  nodes: {
    data: [],
    total: 0,
  },
};

export const merchantNodesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getMerchantNodes.fulfilled,
      (state, { payload }: PayloadAction<SearchMerchantResponse>) => {
        state.nodes = payload;
      }
    );
  },
  initialState,
  name: "merchantNodes",
  reducers: {},
});

export default merchantNodesSlice.reducer;
