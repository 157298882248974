import React from "react";
import {
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  ColumnsEnumCO,
  IColumnsTable,
} from "../../../../shared/infrastructure/Table/ColumnsTable";
import { get } from "lodash";
import { RetentionMerchantConfigDynamo } from "../../../../../types/retention_merchant_configuration";
import { CountryValueEnum } from "../../../../shared/infrastructure/Enums/CountryEnum";
import {
  RetentionCatalogCO,
  RetentionTypeCO,
} from "../../../../shared/infrastructure/constants/RetentionConstantsCO";

export interface IDashboardTableRowProps {
  renderColumns: IColumnsTable[];
  retentionData: RetentionMerchantConfigDynamo;
  handleOpenEditForm?: (index: number) => void;
  handleOpenDeleteModal: (index: number) => void;
  onHandleEditAction: (
    row: RetentionMerchantConfigDynamo,
    edit: boolean
  ) => void;
  index: number;
  country: CountryValueEnum;
}

export const StyledTooltip = withStyles(
  createStyles({
    tooltip: {
      backgroundColor: "rgb(240, 244, 249)",
      padding: "10px 15px",
      fontSize: "14px",
      maxWidth: "375px",
      color: "#023365",
    },
  })
)(Tooltip);

const useStyles = makeStyles({
  TableCell: {
    padding: "16px",
    maxWidth: "200px",
  },
  TableCellTaxpayer: {
    padding: "16px",
    maxWidth: "250px",
  },
});

const retentionText = (
  retentionType: RetentionTypeCO,
  value: string | number
): string => {
  return RetentionCatalogCO[retentionType][value];
};

const buildText = (label: string): JSX.Element => {
  return (
    <Typography variant={"body2"} noWrap>
      {label}
    </Typography>
  );
};

export const DashboardTableRowCO = (
  props: IDashboardTableRowProps
): JSX.Element => {
  const classes = useStyles();

  return (
    <TableRow>
      {props.renderColumns
        .filter((column) => column.view)
        .map((column: IColumnsTable) => {
          switch (column.id) {
            case ColumnsEnumCO.cityId:
              return (
                <TableCell className={classes.TableCell} key={column.id}>
                  {buildText(
                    retentionText(
                      RetentionTypeCO.city,
                      get(props.retentionData, column.id, "-")
                    )
                  )}
                </TableCell>
              );
            case ColumnsEnumCO.activityId:
              return (
                <TableCell
                  className={classes.TableCellTaxpayer}
                  key={column.id}
                >
                  <StyledTooltip
                    title={retentionText(
                      RetentionTypeCO.activityId,
                      get(props.retentionData, column.id, "-")
                    )}
                    placement={"top-start"}
                  >
                    {buildText(
                      retentionText(
                        RetentionTypeCO.activityId,
                        get(props.retentionData, column.id, "-")
                      )
                    )}
                  </StyledTooltip>
                </TableCell>
              );
            case ColumnsEnumCO.model:
              return (
                <TableCell className={classes.TableCell} key={column.id}>
                  {buildText(
                    retentionText(
                      RetentionTypeCO.model,
                      get(props.retentionData, column.id, "-")
                    )
                  )}
                </TableCell>
              );
            case ColumnsEnumCO.retIca:
              return (
                <TableCell className={classes.TableCell} key={column.id}>
                  {buildText(`${get(props.retentionData, column.id, "-")}%`)}
                </TableCell>
              );
            default:
              return (
                <TableCell className={classes.TableCell} key={column.id}>
                  {buildText(get(props.retentionData, column.id, "-"))}
                </TableCell>
              );
          }
        })}
    </TableRow>
  );
};
