export enum FieldsRule {
  STATUS_PENDING_CREATED = "pending",
  STATUS_PENDING_EDITED = "pending",
  STATUS_DISABLE = "disable",
  STATUS_ENABLE = "enable",
  STATUS_REJECTED = "rejected",
  REQUEST_TYPE_CREATION = "creation",
  REQUEST_TYPE_EDITION = "edition",
  REQUEST_TYPE_ACTIVATION = "activation",
  CONFIG_TYPE = "first_level",
  WHITE_LIST = "whitelist",
}
