export enum MinAmountLabelEnum {
  END_GRACE_PERIOD = "Fin de periodo de gracia",
  FREQUENCY = "Frecuencia",
  GRACE_PERIOD = "Periodo de gracia",
  GRACE_PERIOD_DESCRIPTION = "Durante este plazo no se cobrará el mínimo comisional.",
  MINIMUM_CHARGE = "Cobro Mínimo",
  MINIMUM_CHARGE_RATE = "Tipo de cobro mínimo",
  MINIMUM_COMMISSION = "Mínimo comisional",
  MINIMUM_COMMISSION_LEGEND = "Revisa la tarifa mínima que se cobrará luego del periodo de gracia.",
  NO_MINIMUM_COMMISSION = "No tiene configurado el mínimo comisional",
}
