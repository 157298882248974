import { useState } from "react";
import * as React from "react";
import { FilterActionTypeEnum } from "../../../shared/enum/FilterActionTypeEnum";
import { searchTextProps } from "../SearchText";

export interface IUseSearchText {
  formValue: string;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useSearchText = (props: searchTextProps) => {
  const [formValue, setFormValue] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.handleResetPage();
    props.handleFilterMerchants(FilterActionTypeEnum.SEARCH, formValue.trim());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue(e.target.value);
  };

  return {
    formValue,
    handleChange,
    handleSubmit,
  };
};
