import {
  IUseWrapperContainerProps,
  UseWrapperContainer,
} from "./useWrapperContainer.interfaces";
import { useEffect, useState } from "react";
import { ITabItem } from "../../WrappedTabs/TabItem/TabItem.interfaces";
import {
  buildLastModifyProps,
  TABS_DEFAULT_STATE,
} from "../../../shared/constants/wrapper_container_constants";
import { defaultTo, get } from "lodash";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { ROUTES } from "../../../shared/constants/routes_tab";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { ILastModify } from "../../../shared/interfaces/LastModify.interfaces";
import { TabItemStatusEnum } from "../../../shared/constants/enums/tabItemStatusEnum";
import { IConfigurationItem } from "../../../../types/node_info_response";
import { ConfigurationIdEnum } from "../../../shared/enums/ConfigurationIdEnum";
import useModal from "../../../hooks/useModal/useModal";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { editHierarchyWebhookConfig } from "../../../store/thunks/developers/developers.thunks";
import { useSearchParams } from "react-router-dom";
import { getTime } from "date-fns";

export const useWrapperContainer = ({
  isCentralized,
}: IUseWrapperContainerProps): UseWrapperContainer => {
  const [tabs, setTabs] = useState<ITabItem[]>(TABS_DEFAULT_STATE);
  const { nodeInfo } = useAppSelector((state) => ({ ...state.app }));
  const [footerLabels, setFooterLabels] = useState<ILastModify | null>(null);
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get("publicMerchantId");
  const { navigate } = useAppNavigate();
  const dispatch = useAppDispatch();
  const [isSectionWebhookComplete, setIsSectionWebhookComplete] =
    useState(false);

  const webhookModalActions = useModal();

  const setStatusTab = (config: IConfigurationItem) => {
    let tabsInfo: ITabItem[] = TABS_DEFAULT_STATE;

    tabsInfo = tabsInfo.filter((tabInfo) => {
      if (tabInfo.title === "Webhooks")
        tabInfo.status = get(config, "status", TabItemStatusEnum.IN_PROCESS);

      return true;
    });

    setTabs(tabsInfo);

    if (get(config, "status", "") === TabItemStatusEnum.COMPLETE)
      setIsSectionWebhookComplete(true);
  };

  const updateLastModify = (config: IConfigurationItem) => {
    setFooterLabels(
      buildLastModifyProps(
        get(config, "updatedAt", 0),
        get(config, "updatedBy", "")
      )
    );
  };

  const openModalRedirect = () => {
    if (isSectionWebhookComplete || isCentralized) {
      navigate(`/..${ROUTES.RESUME}`);

      return;
    }

    webhookModalActions.open();
  };

  const continueToConfigWebhooks = () => {
    navigate(`/..${ROUTES.WEBHOOK_CREATE}`);
  };

  const continueToResumeMerchant = async () => {
    await dispatch(
      editHierarchyWebhookConfig({
        configs: [
          {
            configuration: ConfigurationIdEnum.CN014,
            status: TabItemStatusEnum.COMPLETE,
            updatedAt: getTime(Date.now()),
            updatedBy: defaultTo(localStorage.getItem("username"), ""),
            value: defaultTo(merchantId, ""),
          },
        ],
        nodeId: get(nodeInfo, "nodeId", ""),
      })
    );

    window.location.href = `${ROUTES.RESUME}${location.search}`;
  };

  useEffect(() => {
    if (nodeInfo) {
      nodeInfo.configs
        .filter(
          (config: IConfigurationItem) =>
            config.configuration === ConfigurationIdEnum.CN014
        )
        .map((config: IConfigurationItem) => {
          updateLastModify(config);
          setStatusTab(config);
        });
    }
  }, [nodeInfo]);

  return {
    continueToConfigWebhooks,
    continueToResumeMerchant,
    footerLabels,
    modals: {
      webhookModalActions,
    },
    openModalRedirect,
    tabs,
  };
};
