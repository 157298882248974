import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { Webhook, WebhookResponse } from "../../types/webhook_response";
import { IValidUrl } from "../shared/infrastructure/interfaces/IValidUrl";
import { NotificationsData } from "../../types/notifications_data";
import { MerchantSettingsResponse } from "../shared/infrastructure/interfaces/IMerchantSettingsResponse";
import { GetNodeInfoResponse } from "../../types/get_node_info_response";

export interface IAppState {
  merchantId?: string;
  notification?: INotification;
  webhookList?: WebhookResponse;
  webhookSelected?: Webhook;
  verifiedUrls?: IValidUrl;
  loading?: boolean;
  notificationsData?: NotificationsData;
  webhookSignature?: string;
  merchantStatus?: string;
  merchantSettings?: MerchantSettingsResponse;
  isVposActive?: boolean;
  isSmartlinkActive?: boolean;
  isSubscriptionActive?: boolean;
  isShowError?: boolean;
  isPreauthEnable?: boolean;
  isLoadingResendNotification?: boolean;
  nodeInfo?: GetNodeInfoResponse;
}

export const INITIAL_STATE: IAppState = {
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  notificationsData: {
    total: 0,
    notifications: [],
  },
  webhookSignature: "",
  merchantStatus: "",
  verifiedUrls: {
    general: [{ url: "", status: "" }],
  },
  merchantSettings: {
    processors: {},
    processor_name: "",
    country: "",
    sandboxBaconKey: "",
    prodBaconKey: "",
    merchant_name: "",
  },
  isVposActive: false,
  isSmartlinkActive: false,
  isSubscriptionActive: false,
  isShowError: false,
  isPreauthEnable: false,
  isLoadingResendNotification: false,
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_WEBHOOK:
      return {
        ...state,
        webhookList: action.webhookList,
      };
    case ActionTypes.SET_WEBHOOK_SELECTED:
      return {
        ...state,
        webhookSelected: action.webhookSelected,
      };
    case ActionTypes.SET_WEBHOOK_NOTIFICATIONS:
      return {
        ...state,
        notificationsData: action.notificationsData,
      };
    case ActionTypes.SET_VERIFIED_URLS:
      return {
        ...state,
        verifiedUrls: action.verifiedUrls,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.SET_WEBHOOK_SIGNATURE:
      return {
        ...state,
        webhookSignature: action.webhookSignature,
      };
    case ActionTypes.SET_MERCHANT_STATUS:
      return {
        ...state,
        merchantStatus: action.merchantStatus,
      };
    case ActionTypes.SET_MERCHANT_SETTINGS:
      return {
        ...state,
        merchantSettings: action.merchantSettings,
      };
    case ActionTypes.SET_VPOS_ACTIVE:
      return {
        ...state,
        isVposActive: action.isVposActive,
      };
    case ActionTypes.SET_SMARTLINK_ACTIVE:
      return {
        ...state,
        isSmartlinkActive: action.isSmartlinkActive,
      };
    case ActionTypes.SET_SUBSCRIPTION_ACTIVE:
      return {
        ...state,
        isSubscriptionActive: action.isSubscriptionActive,
      };
    case ActionTypes.SET_SHOW_ERROR:
      return {
        ...state,
        isShowError: action.isShowError,
      };
    case ActionTypes.SET_PREAUTH_ENABLE:
      return {
        ...state,
        isPreauthEnable: action.isPreauthEnable,
      };
    case ActionTypes.SET_LOADING_RESEND_NOTIFICATION:
      return {
        ...state,
        isLoadingResendNotification: action.isLoadingResendNotification,
      };
    case ActionTypes.SET_NODE_INFO:
      return {
        ...state,
        nodeInfo: action.nodeInfo,
      };
    default:
      return state;
  }
};
