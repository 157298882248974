/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_NICARAGUA: IOptionSelection[] = [
  {
    value: "Banco de la Producción (BANPRO)",
    name: "Banco de la Producción (BANPRO)",
  },
  {
    value: "Banco de Crédito Centroamericano (BANCENTRO)",
    name: "Banco de Crédito Centroamericano (BANCENTRO)",
  },
  {
    value: "Banco de América Central (BAC)",
    name: "Banco de América Central (BAC)",
  },
  { value: "Banco de Finanzas (BDF)", name: "Banco de Finanzas (BDF)" },
  { value: "Banco Ficohsa de Nicaragua", name: "Banco Ficohsa de Nicaragua" },
  { value: "Banco Avanz", name: "Banco Avanz" },
  { value: "Financiera FAMA", name: "Financiera FAMA" },
];

export const CURRENCY_NICARAGUA: IOptionSelection[] = [
  { value: CurrencyEnum.NIO, name: CurrencyEnum.NIO },
  { value: CurrencyEnum.USD, name: CurrencyEnum.USD },
];
