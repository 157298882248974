import { IColumnConfiguration } from "./ColumnConfiguration.interfaces";
import React, { FC } from "react";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import { columnConfigurationStyles } from "./ColumnConfiguration.styles";
import ConfigurationIcon from "../../assets/ConfigurationIcon";
import { useColumnConfiguration } from "./useColumnConfiguration/useColumnConfiguration";
import { ColumnConfigurationTitle } from "./ColumnConfigurationTitle/ColumnConfigurationTitle";
import { ColumnConfigurationContent } from "./ColumnConfigurationContent/ColumnConfigurationContent";

const ColumnConfiguration: FC<IColumnConfiguration> = (props) => {
  const { maxColumns, columns, fixedColumns, starterColumns } = props;
  const {
    anchorEl,
    columnConfiguration,
    handler: {
      handleApplyColumns,
      handleChangeColumnCheck,
      handleClose,
      handleRestoreColumns,
      handleOpen,
    },
    open,
  } = useColumnConfiguration({ starterColumns });

  return (
    <>
      <Button
        id={"button_columns_config"}
        onClick={(event) => handleOpen(event.currentTarget)}
        sx={columnConfigurationStyles.buttonConfig}
        size={"large"}
        variant="outlined"
      >
        <ConfigurationIcon sx={columnConfigurationStyles.defaultColor} />
      </Button>
      <Popover
        id={"columns_config"}
        open={open}
        sx={columnConfigurationStyles.popover}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        PaperProps={{ style: { width: 500 } }}
      >
        <Paper>
          <Box sx={columnConfigurationStyles.title}>
            <ColumnConfigurationTitle
              columnConfiguration={columnConfiguration}
              handleClose={handleClose}
              maxColumns={maxColumns}
            />
          </Box>
          <Box sx={columnConfigurationStyles.content}>
            <ColumnConfigurationContent
              columns={columns}
              columnConfiguration={columnConfiguration}
              handleChangeColumnCheck={handleChangeColumnCheck}
              fixedColumns={fixedColumns}
              maxColumns={maxColumns}
            />
          </Box>
          <Divider sx={columnConfigurationStyles.divider} />
          <DialogActions sx={columnConfigurationStyles.actionButton}>
            <Button
              id="button_restore_columns"
              sx={columnConfigurationStyles.cancelButton}
              onClick={handleRestoreColumns}
              size={"medium"}
              variant="outlined"
            >
              <Typography sx={columnConfigurationStyles.buttonText}>
                Restablecer
              </Typography>
            </Button>
            <Button
              id="button_apply_columns"
              sx={columnConfigurationStyles.acceptButton}
              onClick={handleApplyColumns}
              size={"medium"}
              variant={"contained"}
              disabled={columnConfiguration.length !== maxColumns}
            >
              <Typography sx={columnConfigurationStyles.buttonText}>
                Aplicar
              </Typography>
            </Button>
          </DialogActions>
        </Paper>
      </Popover>
    </>
  );
};

export { ColumnConfiguration };
