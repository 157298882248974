import { StatusStyle } from "./NotificationSectionModal.interfaces";
import { EmailStatusEnum } from "../../../shared/enums/EmailStatusEnum";

export const getStatusTheme = (status: string): StatusStyle => {
  switch (status) {
    case EmailStatusEnum.SENT:
      return {
        backgroundColor: "rgba(2,51,101,1)",
        color: "#F7FAFC",
      };
    case EmailStatusEnum.BOUNCED:
      return {
        backgroundColor: "#D58C4E",
        color: "#F7FAFC",
      };
    default:
      return { backgroundColor: "", color: "" };
  }
};
