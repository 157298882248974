import React from "react";
import { SuccessValidationResultProps } from "../../shared/infrastructure/interfaces/SuccessValidationResult.interface";
import { SuccessValidationResult } from "./SuccessValidationResult/SuccessValidationResult";
import { get, isEmpty } from "lodash";

export const FileValidationResult: React.FC<SuccessValidationResultProps> = (
  props: SuccessValidationResultProps | undefined
) => {
  if (isEmpty(get(props, "validationResults"))) return <></>;

  return (
    <SuccessValidationResult
      validationResults={get(props, "validationResults")}
    />
  );
};
