import React from "react";
import { Typography } from "@mui/material";
import { IDialogAlertTimeRefundProps } from "./DialogAlertTimeRefund.interfaces";
import { DIALOG_MESSAGE } from "../../../shared/constants/labels/dialog_message_labels";
import { ModalContent } from "@kushki/connect-ui";

export const DialogAlertTimeRefund: React.FC<IDialogAlertTimeRefundProps> = (
  props: IDialogAlertTimeRefundProps
) => {
  return (
    <ModalContent
      titleText={"Solicitud de Reembolso"}
      descriptionText={""}
      isOpen={props.openDialog}
      onClose={() => {
        props.onCancel(false);
      }}
      buttonAction={() => {
        props.onCancel(false);
      }}
      buttonText={"Entendido"}
      buttonType={DIALOG_MESSAGE.PRIMARY}
      type={DIALOG_MESSAGE.DEFAULT}
    >
      <Typography>
        Reembolso no aceptado por superar el período límite para recepción de
        estas solicitudes, por favor ponte en contacto con tu asesor comercial.
      </Typography>
    </ModalContent>
  );
};
