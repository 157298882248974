/* istanbul ignore file */
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface ICardSupportUser {
  title: string;
  image: string;
  elements: {
    idComponent?: string;
    subtitle: string;
    description: string;
    redirect: string;
    tooltip?: string;
    onClick?: () => void;
  }[];
  isMedium: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  grayColor: {
    color: "#6D7781",
  },
  subtitleFont: {
    fontWeight: 500,
  },
}));

export const CardSupportUser = (props: ICardSupportUser) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Paper>
        <Box display="flex" alignItems="center" p={1}>
          <Box p={1}>
            <Avatar className={classes.large} src={props.image} />
          </Box>
          <Box p={1} flexGrow={1}>
            <Typography variant="h5" color={"primary"}>
              <b>{props.title}</b>
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          spacing={4}
          style={{ paddingLeft: props.isMedium ? 80 : 10, paddingBottom: 15 }}
        >
          {props.elements.map(
            (element: {
              subtitle: string;
              description: string;
              redirect: string;
              tooltip?: string;
              onClick?: () => void;
              idComponent?: string;
            }) => (
              <Grid item xs={12} md={6} key={element.subtitle}>
                <ComponentSecurityWrapper componentId={element.idComponent}>
                  <Box
                    onClick={() => {
                      element.onClick
                        ? element.onClick()
                        : history.push(element.redirect);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography variant="subtitle1" color={"primary"}>
                      <b className={classes.subtitleFont}>{element.subtitle}</b>
                    </Typography>
                    {element.tooltip && (
                      <Tooltip title={element.tooltip} placement="top-start">
                        <Typography
                          variant="body2"
                          className={classes.grayColor}
                        >
                          {element.description}
                        </Typography>
                      </Tooltip>
                    )}
                    {!element.tooltip && (
                      <Typography variant="body2" className={classes.grayColor}>
                        {element.description}
                      </Typography>
                    )}
                  </Box>
                </ComponentSecurityWrapper>
              </Grid>
            )
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
