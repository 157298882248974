/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_HONDURAS: IOptionSelection[] = [
  { value: "Banco Atlántida S.A.", name: "Banco Atlántida S.A." },
  { value: "Banco de Occidente S.A.", name: "Banco de Occidente S.A." },
  { value: "Banco de Los Trabajadores", name: "Banco de Los Trabajadores" },
  {
    value: "Banco Financiera Centroamericana S.A. (FICENSA)",
    name: "Banco Financiera Centroamericana S.A. (FICENSA)",
  },
  {
    value: "Banco Hondureño del Café S.A. (BANHCAFE)",
    name: "Banco Hondureño del Café S.A. (BANHCAFE)",
  },
  { value: "Banco del País S.A.", name: "Banco del País S.A." },
  {
    value: "Banco Financiera Comercial Hondureña S.A. (FICOHSA)",
    name: "Banco Financiera Comercial Hondureña S.A. (FICOHSA)",
  },
  { value: "Banco Lafise Honduras S.A.", name: "Banco Lafise Honduras S.A." },
  { value: "Banco Azteca Hoduras", name: "Banco Azteca Hoduras" },
];

export const CURRENCY_HONDURAS: IOptionSelection[] = [
  { value: CurrencyEnum.HNL, name: CurrencyEnum.HNL },
  { value: CurrencyEnum.USD, name: CurrencyEnum.USD },
];
