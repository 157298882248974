import { IAlarmConfigForm } from "@containers/AlarmConfig/AlarmConfig.interfaces";
import { AlarmType } from "@shared/enum/AlarmTypeEnum";
import { IValue } from "@components/molecule/DropDown/DropDown.interfaces";

const initialValuesForm: IAlarmConfigForm = {
  alias: "",
  frequency: "",
  variables: [{ field: "", operator: "", value: "" }],
};

export enum InputNameForm {
  ALIAS = "alias",
  FREQUENCY = "frequency",
  VARIABLES = "variables",
  FIELD = "field",
  OPERATOR = "operator",
  VALUE = "value",
}

export enum InputLabelForm {
  FREQUENCY = "Selecciona la frecuencia",
  ALIAS = "Escribe un alias",
  FIELD = "Selecciona una variable",
  OPERATOR = "Selecciona un operador",
  VALUE = "Selecciona del catálogo",
  AMOUNT = "Selecciona un monto",
  DATE = "Selecciona una fecha",
  DEVIATION = "Número de Desviaciones Estándar",
}

const inputFieldName = (index: number): string =>
  `${InputNameForm.VARIABLES}.${index}.${InputNameForm.FIELD}`;
const inputOperatorName = (index: number): string =>
  `${InputNameForm.VARIABLES}.${index}.${InputNameForm.OPERATOR}`;
const inputValueName = (index: number): string =>
  `${InputNameForm.VARIABLES}.${index}.${InputNameForm.VALUE}`;

export enum FieldOptionValue {
  PEP = "pep",
  AVERAGE_TICKET = "average_ticket",
  TPV = "tpv",
  MODEL = "model",
  ECONOMIC_ACTIVITY = "economic_activity",
  MCC = "mcc",
  CONSTITUTIONAL_COUNTRY = "constitutional_country",
  CATEGORY = "merchant_category",
  CONSTITUTION_DATE = "constitution_date",
  STANDARD_DEVIATION = "standard_deviation",
}

export enum OperatorOptionValue {
  EQUAL = "equal",
  DIFFERENT = "different",
  LESS_THAN = "less_than",
  GREATER_THAN = "greaten_than",
}

export enum OperatorOptionLabel {
  IGUAL = "Igual",
  DIFERENTE = "Diferente",
  MENOR_QUE = "Menor que",
  MAYOR_QUE = "Mayor que",
}

export enum YesNoEnum {
  YES = "yes",
  NO = "no",
}

export enum SiNoEnum {
  SI = "Si",
  No = "No",
}

export enum TypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  GENERAL = "GENERAL",
}

const AlarmTypeParse = {
  [TypeEnum.INDIVIDUAL]: AlarmType.INDIVIDUAL,
  [TypeEnum.GENERAL]: AlarmType.GENERAL,
};

const AlarmTypeByIndex = {
  [0]: AlarmType.GENERAL,
  [1]: AlarmType.INDIVIDUAL,
};

export enum VariableLabels {
  AVERAGE_TICKET = "Ticket promedio",
  PEP = "PEP",
  TPV = "TPV",
  MODEL = "Modelo",
  ECONOMIC_ACTIVITY = "Actividad Económica",
  MCC = "MCC",
  CONSTITUTION_COUNTRY = "País de constitución",
  CATEGORY = "Categoría del comercio",
  CONSTITUTIONAL_DATE = "Fecha de constitución",
  STANDARD_DEVIATION = "Desviación estándar",
}

const VARIABLE_RECORD: Record<string, string> = {
  [FieldOptionValue.PEP]: VariableLabels.PEP,
  [FieldOptionValue.AVERAGE_TICKET]: VariableLabels.AVERAGE_TICKET,
  [FieldOptionValue.TPV]: VariableLabels.TPV,
  [FieldOptionValue.MODEL]: VariableLabels.MODEL,
  [FieldOptionValue.STANDARD_DEVIATION]: VariableLabels.STANDARD_DEVIATION,
  [FieldOptionValue.MCC]: VariableLabels.MCC,
  [FieldOptionValue.CATEGORY]: VariableLabels.CATEGORY,
  [FieldOptionValue.CONSTITUTIONAL_COUNTRY]:
    VariableLabels.CONSTITUTION_COUNTRY,
  [FieldOptionValue.CONSTITUTION_DATE]: VariableLabels.CONSTITUTIONAL_DATE,
};

const VARIABLES_VALUES: IValue[] = Object.keys(VARIABLE_RECORD).map((key) => ({
  id: key,
  value: VARIABLE_RECORD[key],
}));

export {
  AlarmTypeByIndex,
  AlarmTypeParse,
  initialValuesForm,
  inputFieldName,
  inputOperatorName,
  inputValueName,
  VARIABLE_RECORD,
  VARIABLES_VALUES,
};
