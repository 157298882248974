import { ChangeEvent, useState } from "react";
import { IDropdownListProps } from "../DropdownList.interfaces";
import { IDropdownItemProps } from "../../DropDownItem/DropdownItem.interfaces";

export interface IUseStateDropdownList {
  list: IDropdownItemProps[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const useStateDropdownList = ({
  items,
}: IDropdownListProps): IUseStateDropdownList => {
  const [list, setList] = useState(items);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const listItems = items.filter((item) =>
      item.text.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setList(listItems);
  };

  return { handleChange, list };
};

export { useStateDropdownList };
