import { FormControlLabel, Checkbox } from "@material-ui/core";
import React from "react";
import { get } from "lodash";

export interface IKshCheckbox {
  checked?: boolean;
  label: string | React.ReactNode;
  value?: string;
  id: string;
  onChange(value: { prop: string; value: boolean }): void;
}

export const KshCheckbox: React.FC<IKshCheckbox> = (props: IKshCheckbox) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={props.id}
          value={get(props, "checked", false)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange({
              prop: props.id,
              value: event.target.checked,
            })
          }
          checked={props.checked}
          name={props.id}
          color="primary"
        />
      }
      label={props.label}
    />
  );
};
