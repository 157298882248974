import { defaultTo, get } from "lodash";
import { Catalog, Data } from "../../../types/catalog_response";

/** Retrieves the catalog data by its name. */
export function getCatalogByName(
  listCatalogs: Catalog[],
  name: string
): Data[] {
  const list: Catalog | undefined = listCatalogs.find(
    (catalog: Catalog) => catalog.name.toUpperCase() === name.toUpperCase()
  );

  return defaultTo(get(list, "data") as Data[], []);
}
