import React, { PropsWithChildren } from "react";
import { Grid, TextField } from "@mui/material";
import { Labels } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { useFormContext } from "react-hook-form";
import { ConfigurationResponse } from "../../../types/configuration_response";
import { get } from "lodash";
import { retrieveFormError } from "../../utils/utilsFile";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";

export type GeneralInformationSectionProps = {
  configuration?: ConfigurationResponse;
};

export const GeneralInformationSection: React.FC<
  GeneralInformationSectionProps
> = (_: PropsWithChildren<GeneralInformationSectionProps>) => {
  const { register, errors } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              type={"string"}
              name={"name"}
              label={Labels.NAME}
              variant="outlined"
              fullWidth
              inputRef={register({
                required: ErrorsEnum.REQUIRED_FIELD,
              })}
              error={get(errors, "name")}
              helperText={retrieveFormError(errors, "name")[1]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
