import { getEmailPattern } from "../utils/patterns";

export const RULES_INPUTS = {
  email: {
    pattern: {
      message: "Correo electrónico inválido",
      value: getEmailPattern(),
    },
    required: "Se requiere este campo",
  },
};
