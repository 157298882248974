import {
  MENU_BACKOFFICE_ADMIN,
  MENU_BACKOFFICE_BANK,
  MENU_BACKOFFICE_COMPLIANCE,
  MENU_BACKOFFICE_MONITORING,
  MENU_BACKOFFICE_USER_MAKER,
  MENU_BACKOFFICE_USER_MASTER,
  MENU_BACKOFFICE_MASTER_CREDENTIALS,
  MENU_BACKOFFICE_USER_READER,
  MENU_MGUAYAQUIL,
  MENU_SNR,
  MENU_WEBCHECKOUT_BASIC,
  MENU_GLOBAL_REPORTS,
  MENU_WEBCHECKOUT_MASTER,
  MENU_BILLING_DASHBOARD_VALIDATOR,
  MENU_BILLING_DASHBOARD_EXECUTOR,
  MENU_CORE_ACCOUNTING_DASHBOARD_ANALYST,
  MENU_CORE_ACCOUNTING_DASHBOARD_BOSS,
  MENU_CORE_ACCOUNTING_DASHBOARD_EXECUTOR,
  MENU_CORE_ACCOUNTING_DASHBOARD_VALIDATOR,
  MENU_BACKOFFICE_USER_PARENT,
  MENU_BACKOFFICE_RISK_MANAGER,
  MENU_BACKOFFICE_RISK_ANALYST,
  MENU_CONCILIATION_EXECUTOR,
  MENU_CONCILIATION_VALIDATOR,
  MENU_BANK_CONCILIATION,
  MENU_REFUND_DASHBOARD_VALIDATOR,
  MENU_REFUND_DASHBOARD_EXECUTOR,
  MENU_BACKOFFICE_MASTER_CUSTOMER,
  MENU_CONSOLE_EXPRESS_CHECKOUT,
  MENU_CHARGEBACK_MANAGER,
} from "./KushkiMenu";
import {
  PERMISSIONS_BACKOFFICE_ADMIN,
  PERMISSIONS_BACKOFFICE_BANK,
  PERMISSIONS_GLOBAL_REPORTS,
  PERMISSIONS_BACKOFFICE_COMPLIANCE,
  PERMISSIONS_BACKOFFICE_USER_MASTER,
  PERMISSIONS_BACKOFFICE_MASTER_CREDENTIALS,
  PERMISSIONS_BACKOFFICE_USER_READER,
  PERMISSIONS_BACKOFFICE_USERMAKER,
  PERMISSIONS_MGUAYAQUIL_REVIEWER,
  PERMISSIONS_SNR_AGENT,
  PERMISSIONS_SNR_COORDINATOR,
  PERMISSIONS_WEBCHECKOUT_BASIC,
  PERMISSIONS_WEBCHECKOUT_MASTER,
  PERMISSIONS_BACKOFFICE_MONITORING,
  PERMISSIONS_BACKOFFICE_BILLING_VALIDATOR,
  PERMISSIONS_BACKOFFICE_BILLING_EXECUTOR,
  PERMISSIONS_BACKOFFICE_USER_PARENT,
  PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_ANALYST,
  PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_BOSS,
  PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_EXECUTOR,
  PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_VALIDATOR,
  PERMISSIONS_BACKOFFICE_RISK_MANAGER,
  PERMISSIONS_BACKOFFICE_RISK_ANALYST,
  PERMISSIONS_CONCILIATION_EXECUTOR,
  PERMISSIONS_CONCILIATION_VALIDATOR,
  PERMISSIONS_BANK_CONCILIATION,
  PERMISSIONS_BACKOFFICE_REFUND_VALIDATOR,
  PERMISSIONS_BACKOFFICE_REFUND_EXECUTOR,
  PERMISSIONS_BACKOFFICE_MASTER_CUSTOMER,
  PERMISSIONS_BACKOFFICE_EXPRESS_CHECKOUT,
  PERMISSIONS_CHARGEBACK_MANAGER,
} from "./Permissions";
import { IKushkiRol } from "../interfaces/IKushkiRol";

export enum ROLES_NAME_ENUM {
  BACKOFFICE_ADMIN = "BackofficeAdmin",
}

export const ROL_CONSOLE_EXPRESS_CHECKOUT: IKushkiRol = {
  name: "KSH_EXPRESS_CHECKOUT",
  cognito_group: "ExpressCheckoutMaster",
  permissions: PERMISSIONS_BACKOFFICE_EXPRESS_CHECKOUT,
  menu: MENU_CONSOLE_EXPRESS_CHECKOUT,
};

export const ROL_BACKOFFICE_ADMIN: IKushkiRol = {
  name: "KSH_ADMIN",
  cognito_group: ROLES_NAME_ENUM.BACKOFFICE_ADMIN,
  permissions: PERMISSIONS_BACKOFFICE_ADMIN,
  menu: MENU_BACKOFFICE_ADMIN,
};

export const ROL_GLOBAL_REPORTS: IKushkiRol = {
  name: "KSH_GLOBAL_REPORTS",
  cognito_group: "BackofficeGlobalReports",
  permissions: PERMISSIONS_GLOBAL_REPORTS,
  menu: MENU_GLOBAL_REPORTS,
};

export const ROL_BACKOFFICE_USER_MASTER: IKushkiRol = {
  name: "KSH_USER_MASTER",
  cognito_group: "BackofficeUserMaster",
  permissions: PERMISSIONS_BACKOFFICE_USER_MASTER,
  menu: MENU_BACKOFFICE_USER_MASTER,
};

export const ROL_BACKOFFICE_MASTER_CUSTOMER: IKushkiRol = {
  name: "KSH_MASTER_CUSTOMER",
  cognito_group: "BackofficeMasterCustomer",
  permissions: PERMISSIONS_BACKOFFICE_MASTER_CUSTOMER,
  menu: MENU_BACKOFFICE_MASTER_CUSTOMER,
};

export const ROL_BACKOFFICE_MASTER_CREDENTIALS: IKushkiRol = {
  name: "KSH_MASTER_CREDENTIALS",
  cognito_group: "BackofficeMasterCredentials",
  permissions: PERMISSIONS_BACKOFFICE_MASTER_CREDENTIALS,
  menu: MENU_BACKOFFICE_MASTER_CREDENTIALS,
};

export const ROL_BACKOFFICE_MONITORING: IKushkiRol = {
  name: "KSH_MONITORING",
  cognito_group: "BackofficeMonitoring",
  permissions: PERMISSIONS_BACKOFFICE_MONITORING,
  menu: MENU_BACKOFFICE_MONITORING,
};

export const ROL_BACKOFFICE_USER_MAKER: IKushkiRol = {
  name: "KSH_USER_MAKER",
  cognito_group: "BackofficeUserMaker",
  permissions: PERMISSIONS_BACKOFFICE_USERMAKER,
  menu: MENU_BACKOFFICE_USER_MAKER,
};

export const ROL_BACKOFFICE_USER_READER: IKushkiRol = {
  name: "KSH_USER_READER",
  cognito_group: "BackofficeUserReader",
  permissions: PERMISSIONS_BACKOFFICE_USER_READER,
  menu: MENU_BACKOFFICE_USER_READER,
};

export const ROL_WEBCHECKOUT_BASIC: IKushkiRol = {
  name: "KSH_WEBCHECKOUT",
  cognito_group: "WebcheckoutBasic",
  permissions: PERMISSIONS_WEBCHECKOUT_BASIC,
  menu: MENU_WEBCHECKOUT_BASIC,
};

export const ROL_WEBCHECKOUT_MASTER: IKushkiRol = {
  name: "KSH_WEBCHECKOUT_MASTER",
  cognito_group: "WebcheckoutMaster",
  permissions: PERMISSIONS_WEBCHECKOUT_MASTER,
  menu: MENU_WEBCHECKOUT_MASTER,
};

export const ROL_SNR_AGENT: IKushkiRol = {
  name: "KSH_SNR_AGENT",
  cognito_group: "SnrAgent",
  permissions: PERMISSIONS_SNR_AGENT,
  menu: MENU_SNR,
};

export const ROL_SNR_COORDINATOR: IKushkiRol = {
  name: "KSH_SNR_COORDINATOR",
  cognito_group: "SnrCoordinator",
  permissions: PERMISSIONS_SNR_COORDINATOR,
  menu: MENU_SNR,
};

export const ROL_MGUAYAQUIL_REVIEWER: IKushkiRol = {
  name: "KSH_MGUAYAQUIL_REVIEWER",
  cognito_group: "MguayaquilReviewer",
  permissions: PERMISSIONS_MGUAYAQUIL_REVIEWER,
  menu: MENU_MGUAYAQUIL,
};

export const ROL_BACKOFFICE_BANK: IKushkiRol = {
  name: "KSH_BANK",
  cognito_group: "BackofficeBank",
  permissions: PERMISSIONS_BACKOFFICE_BANK,
  menu: MENU_BACKOFFICE_BANK,
};

export const ROL_BILLING_VALIDATOR: IKushkiRol = {
  name: "KSH_BILLING_ADMIN",
  cognito_group: "BackofficeFinValidator",
  permissions: PERMISSIONS_BACKOFFICE_BILLING_VALIDATOR,
  menu: MENU_BILLING_DASHBOARD_VALIDATOR,
};

export const ROL_BILLING_CORE_ACCOUNTING_VALIDATOR: IKushkiRol = {
  name: "KSH_BILLING_ADMIN",
  cognito_group: "BackofficeCoreAccountingValidator",
  permissions: PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_VALIDATOR,
  menu: MENU_CORE_ACCOUNTING_DASHBOARD_VALIDATOR,
};

export const ROL_BILLING_CORE_ACCOUNTING_EXECUTOR: IKushkiRol = {
  name: "KSH_BILLING_EXECUTOR",
  cognito_group: "BackofficeCoreAccountingExecutor",
  permissions: PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_EXECUTOR,
  menu: MENU_CORE_ACCOUNTING_DASHBOARD_EXECUTOR,
};
export const ROL_BILLING_EXECUTOR: IKushkiRol = {
  name: "KSH_BILLING_EXECUTOR",
  cognito_group: "BackofficeFinExecutor",
  permissions: PERMISSIONS_BACKOFFICE_BILLING_EXECUTOR,
  menu: MENU_BILLING_DASHBOARD_EXECUTOR,
};

export const ROL_BACKOFFICE_RISK_MANAGER: IKushkiRol = {
  name: "KSH__BACKOFFICE_RISK_MANAGER",
  cognito_group: "RiskManager",
  permissions: PERMISSIONS_BACKOFFICE_RISK_MANAGER,
  menu: MENU_BACKOFFICE_RISK_MANAGER,
};

export const ROL_BACKOFFICE_RISK_ANALYST: IKushkiRol = {
  name: "KSH_BACKOFFICE_RISK_ANALYST",
  cognito_group: "RiskAnalyst",
  permissions: PERMISSIONS_BACKOFFICE_RISK_ANALYST,
  menu: MENU_BACKOFFICE_RISK_ANALYST,
};

export const ROL_BACKOFFICE_COMPLIANCE: IKushkiRol = {
  name: "KSH_COMPLIANCE",
  cognito_group: "BackofficeCompliance",
  permissions: PERMISSIONS_BACKOFFICE_COMPLIANCE,
  menu: MENU_BACKOFFICE_COMPLIANCE,
};
export const ROL_BACKOFFICE_USER_PARENT: IKushkiRol = {
  name: "KSH_USER_PARENT",
  cognito_group: "BackofficeUserParent",
  permissions: PERMISSIONS_BACKOFFICE_USER_PARENT,
  menu: MENU_BACKOFFICE_USER_PARENT,
};
export const ROL_CONCILIATIONE_VALIDATOR: IKushkiRol = {
  name: "KSH_CONCILIATIONE_VALIDATOR",
  cognito_group: "BackofficeConciliationValidator",
  permissions: PERMISSIONS_CONCILIATION_VALIDATOR,
  menu: MENU_CONCILIATION_VALIDATOR,
};

export const ROL_CONCILIATION_EXECUTOR: IKushkiRol = {
  name: "KSH_BILLING_EXECUTOR",
  cognito_group: "BackofficeConciliationExecutor",
  permissions: PERMISSIONS_CONCILIATION_EXECUTOR,
  menu: MENU_CONCILIATION_EXECUTOR,
};
export const ROL_B2C_AGENT: IKushkiRol = {
  name: "KSH_B2C_AGENT",
  cognito_group: "B2CAgent",
  permissions: PERMISSIONS_SNR_AGENT,
  menu: MENU_SNR,
};
export const ROL_B2C_COORDINATOR: IKushkiRol = {
  name: "KSH_B2C_COORDINATOR",
  cognito_group: "B2CCoordinator",
  permissions: PERMISSIONS_SNR_COORDINATOR,
  menu: MENU_SNR,
};
export const ROL_BANK_CONCILIATION_TRANSACTIONS: IKushkiRol = {
  name: "KSH_BANK_CONCILIATION_TRANSACTIONS",
  cognito_group: "FinOpsBackOffice",
  permissions: PERMISSIONS_BANK_CONCILIATION,
  menu: MENU_BANK_CONCILIATION,
};
export const ROL_BANK_CONCILIATION_SUMMARY: IKushkiRol = {
  name: "KSH_BANK_CONCILIATION_SUMMARY",
  cognito_group: "TreasuryBackOffice",
  permissions: PERMISSIONS_BANK_CONCILIATION,
  menu: MENU_BANK_CONCILIATION,
};
export const ROL_CONCILIATION_CORE_ACCOUNTING_ANALYST: IKushkiRol = {
  name: "KSH_CONCILIATION_TRANSACTION_ANALYST",
  cognito_group: "BackofficeCoreAccountingAnalyst",
  permissions: PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_ANALYST,
  menu: MENU_CORE_ACCOUNTING_DASHBOARD_ANALYST,
};
export const ROL_CONCILIATION_CORE_ACCOUNTING_BOSS: IKushkiRol = {
  name: "KSH_CONCILIATION_TRANSACTION_BOSS",
  cognito_group: "BackofficeCoreAccountingBoss",
  permissions: PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_BOSS,
  menu: MENU_CORE_ACCOUNTING_DASHBOARD_BOSS,
};

export const ROL_REFUND_VALIDATOR: IKushkiRol = {
  name: "KSH_REFUND_ADMIN",
  cognito_group: "BackOfficeRefundApprover",
  permissions: PERMISSIONS_BACKOFFICE_REFUND_VALIDATOR,
  menu: MENU_REFUND_DASHBOARD_VALIDATOR,
};

export const ROL_REFUND_EXECUTOR: IKushkiRol = {
  name: "KSH_REFUND_ADMIN",
  cognito_group: "BackOfficeRefundExecutor",
  permissions: PERMISSIONS_BACKOFFICE_REFUND_EXECUTOR,
  menu: MENU_REFUND_DASHBOARD_EXECUTOR,
};

export const ROL_CHARGEBACK_MANAGER: IKushkiRol = {
  name: "KSH_CHARGEBACK_MANAGER",
  cognito_group: "ChargebackManager",
  permissions: PERMISSIONS_CHARGEBACK_MANAGER,
  menu: MENU_CHARGEBACK_MANAGER,
};

export const SYSTEM_ROLES: IKushkiRol[] = [
  ROL_BACKOFFICE_ADMIN,
  ROL_GLOBAL_REPORTS,
  ROL_BACKOFFICE_USER_MASTER,
  ROL_BACKOFFICE_MASTER_CUSTOMER,
  ROL_BACKOFFICE_MASTER_CREDENTIALS,
  ROL_BACKOFFICE_USER_READER,
  ROL_BACKOFFICE_USER_MAKER,
  ROL_BACKOFFICE_BANK,
  ROL_SNR_AGENT,
  ROL_SNR_COORDINATOR,
  ROL_MGUAYAQUIL_REVIEWER,
  ROL_WEBCHECKOUT_BASIC,
  ROL_WEBCHECKOUT_MASTER,
  ROL_BACKOFFICE_MONITORING,
  ROL_BACKOFFICE_COMPLIANCE,
  ROL_BILLING_EXECUTOR,
  ROL_BILLING_VALIDATOR,
  ROL_BACKOFFICE_USER_PARENT,
  ROL_BACKOFFICE_RISK_MANAGER,
  ROL_BACKOFFICE_RISK_ANALYST,
  ROL_BILLING_CORE_ACCOUNTING_VALIDATOR,
  ROL_BILLING_CORE_ACCOUNTING_EXECUTOR,
  ROL_CONCILIATIONE_VALIDATOR,
  ROL_CONCILIATION_EXECUTOR,
  ROL_B2C_COORDINATOR,
  ROL_B2C_AGENT,
  ROL_BANK_CONCILIATION_TRANSACTIONS,
  ROL_BANK_CONCILIATION_SUMMARY,
  ROL_CONCILIATION_CORE_ACCOUNTING_ANALYST,
  ROL_CONCILIATION_CORE_ACCOUNTING_BOSS,
  ROL_REFUND_EXECUTOR,
  ROL_REFUND_VALIDATOR,
  ROL_CONSOLE_EXPRESS_CHECKOUT,
  ROL_CHARGEBACK_MANAGER,
];

export const ROLES: string[] = [
  "BackofficeAdmin",
  "BackofficeGlobalReports",
  "BackofficeUserMaster",
  "BackofficeMasterCredentials",
  "BackofficeUserMaker",
  "BackofficeUserReader",
  "WebcheckoutBasic",
  "WebcheckoutMaster",
  "SnrAgent",
  "SnrCoordinator",
  "MguayaquilReviewer",
  "BackofficeBank",
  "BackofficeCompliance",
  "BackofficeMonitoring",
  "BackofficeFinValidator",
  "BackofficeFinExecutor",
  "BackofficeUserParent",
  "BackofficeCoreAccountingAnalyst",
  "BackofficeCoreAccountingBoss",
  "BackofficeCoreAccountingValidator",
  "BackofficeCoreAccountingExecutor",
  "BackofficeConciliationValidator",
  "BackofficeConciliationExecutor",
  "RiskManager",
  "RiskAnalyst",
  "B2CAgent",
  "B2CCoordinator",
  "TreasuryBackOffice",
  "FinOpsBackOffice",
  "BackofficeRuleOperator",
  "BackofficeRuleAdmin",
  "BackOfficeRefundApprover",
  "BackOfficeRefundExecutor",
  "BackofficeMasterCustomer",
  "ExpressCheckoutMaster",
  "ChargebackManager",
];
