export enum WEBSOCKET_ACTIONS {
  massive_service_edittion = "massiveServicesEdition",
  decentralizeMassiveMerchantNodes = "decentralizeMassiveMerchantNodes",
}

export enum MASSIVE_SERVICE_ACTIONS {
  centralize = "centralize",
  decentralize = "decentralize",
}

export enum LOADING_MODAL_MESSAGES {
  centralize = "Estamos centralizando las branches",
  decentralize = "Estamos descentralizando las branches",
}
