import {
  IBankAccount,
  IChargeForm,
  IChargeStore,
} from "../../interfaces/charge.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { sortChargeBanksAccount } from "../../../shared/utils/chargeBankAccountUtils";
import {
  getDispersionRatesConfig,
  getMerchantNodeInfo,
  postRates,
} from "../../thunks/app/app.thunks";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";
import { GetDispersionRatesConfigResponse } from "../../../../types/get_dispersion_rates_config_response";
import { defaultTo, get, has } from "lodash";

export const initialState: IChargeStore = {
  banksAccounts: [],
  beneficiaryNameFromBasicDetails: "",
  constitutionalCountry: CountriesEnum.ECUADOR,
  frequencyAndFraudForm: {
    fraudPercentage: undefined,
    frequency: FrequencyEnum.NONE,
    keepFraud: false,
    retentionPeriod: undefined,
  },
  isLoadingChargeInformation: true,
  loading: {
    loadingForm: false,
    loadingRatesConfig: true,
  },
  stateCharge: {},
};

export const chargeSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getDispersionRatesConfig.pending, (state) => {
        state.loading.loadingRatesConfig = true;
        state.isLoadingChargeInformation = true;
      })
      .addCase(
        getDispersionRatesConfig.fulfilled,
        (
          state,
          { payload }: PayloadAction<GetDispersionRatesConfigResponse>
        ) => {
          state.frequencyAndFraudForm = {
            ...state.frequencyAndFraudForm,
            fraudPercentage: payload.fraudPercentage,
            keepFraud: defaultTo(
              payload.keepFraud,
              initialState.frequencyAndFraudForm.keepFraud
            ),
            retentionPeriod: payload.retentionPeriod,
          };
          state.loading.loadingRatesConfig = false;
          state.isLoadingChargeInformation = false;
        }
      )
      .addCase(getDispersionRatesConfig.rejected, (state) => {
        state.loading.loadingRatesConfig = false;
        state.isLoadingChargeInformation = false;
      })
      .addCase(getMerchantNodeInfo.fulfilled, (state, { payload }) => {
        if (has(payload, "charge")) {
          state.frequencyAndFraudForm = {
            ...state.frequencyAndFraudForm,
            frequency: get(
              payload,
              "chargeFrequency",
              initialState.frequencyAndFraudForm.frequency
            ),
          };
        }
      })
      .addCase(postRates.pending, (state) => {
        state.stateCharge.postChargeRates = FetchStateEnum.PENDING;
      })
      .addCase(postRates.fulfilled, (state) => {
        state.stateCharge.postChargeRates = FetchStateEnum.SUCCESS;
      })
      .addCase(postRates.rejected, (state) => {
        state.stateCharge.postChargeRates = FetchStateEnum.FAILED;
      });
  },
  initialState,
  name: "charge",
  reducers: {
    addBankAccount: (state, { payload }: PayloadAction<IBankAccount>) => {
      const bankAccounts: IBankAccount[] = [payload];

      state.banksAccounts = sortChargeBanksAccount(bankAccounts);
    },
    editBankAccount: (state, { payload }: PayloadAction<IBankAccount[]>) => {
      state.banksAccounts = payload;
    },
    editBeneficiaryName: (state, { payload }: PayloadAction<string>) => {
      state.beneficiaryNameFromBasicDetails = payload;
    },
    setIsLoadingChargeInformation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingChargeInformation = payload;
    },
    updateBankAccountIndexOnFocus: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.bankAccountIndexOnFocus = payload;
    },
    updateFrequencyAndFraudForm: (
      state,
      { payload }: PayloadAction<IChargeForm>
    ) => {
      state.frequencyAndFraudForm = payload;
      state.loading.loadingForm = false;
    },
  },
});

export default chargeSlice.reducer;
