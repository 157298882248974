import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const ownerHeaderStyles = createNamedStyles({
  divider: {
    marginLeft: "30px",
    marginRight: "90px",
  },
  title: {
    color: "#023365",
    marginLeft: "30px",
  },
});
