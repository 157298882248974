import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { LabelsDispersion } from "../../../shared/infrastructure/constants/RequestDispersionConstants";
import { get } from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownSharpIcon from "@material-ui/icons/ArrowDropDownSharp";
import React from "react";
import { sucessValidationResultStyles } from "../../FileValidationResult/SuccessValidationResult/SucessValidationResult.styles";
import { useCountRowsValidation } from "./state/useCountRowsValidation";
import { HEADERS_NAME_INPUT_FILE } from "../../../shared/infrastructure/constants/HeadersInputFileEnum";
import { Details } from "../../../../types/file_validation_details_response";

export interface ICountRowsValidationProps {
  typeFile: string;
  url: string;
  correctRowsTotal: number;
  errorRowsTotal: number;
  rowsMessage: Details[];
}

export const CountRowsValidation: React.FC<ICountRowsValidationProps> = (
  props: ICountRowsValidationProps
) => {
  const classes = sucessValidationResultStyles();
  const { expandedDetailRows, handleExpandedDetailRows } =
    useCountRowsValidation();

  return (
    <Grid item xs={12}>
      <Grid container direction={"column"}>
        <Grid item xs={12} className={classes.countRowsContainer}>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            direction={"column"}
          >
            <Grid item xs={12}>
              <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={10}>
                  <Typography className={classes.textPrimary} align={"left"}>
                    {`${LabelsDispersion.VALIDATION_TEXT_TOTAL_FILES} ${props.typeFile}`}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.textPrimary} align={"right"}>
                    {props.correctRowsTotal + props.errorRowsTotal}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={10}>
                  <CheckIcon
                    className={`${classes.rowIcon} ${classes.checkIcon}`}
                  />
                  <Typography className={classes.textSecondary}>
                    {LabelsDispersion.VALID_ROWS_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.textSecondary} align={"right"}>
                    {props.correctRowsTotal}
                  </Typography>
                </Grid>
                {props.errorRowsTotal > 0 && (
                  <>
                    <Grid item xs={10}>
                      <CloseIcon
                        className={`${classes.rowIcon} ${classes.closeIcon}`}
                      />
                      <Typography className={classes.textSecondary}>
                        {LabelsDispersion.INVALID_ROWS_LABEL}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className={classes.textSecondary}
                        align={"right"}
                      >
                        {props.errorRowsTotal}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {props.errorRowsTotal > 0 && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={"center"}
              direction={"column"}
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid container direction={"column"}>
                  <Grid item xs={12}>
                    <Box
                      className={classes.boxButtonExpansive}
                      onClick={handleExpandedDetailRows}
                    >
                      <Button
                        className={classes.detailsButton}
                        style={{ backgroundColor: "transparent" }}
                        disableRipple
                      >
                        {LabelsDispersion.VALIDATION_ERROR_DETAILS_LABEL}
                      </Button>{" "}
                      {expandedDetailRows ? (
                        <ArrowDropUpIcon
                          fill={"#293036"}
                          className={classes.arrowIcon}
                        />
                      ) : (
                        <ArrowDropDownSharpIcon
                          fill={"#293036"}
                          className={classes.arrowIcon}
                        />
                      )}
                    </Box>
                  </Grid>
                  {expandedDetailRows && (
                    <>
                      <Grid item xs={12}>
                        {props.rowsMessage.map(
                          (row: Details, index: number) => (
                            <Box className={classes.boxRowDetail} key={index}>
                              <CloseIcon
                                className={`${classes.rowIcon} ${classes.closeIcon}`}
                              />
                              <Typography className={classes.textSecondary}>
                                {`Fila ${row.field
                                  .split(":")[0]
                                  .slice(
                                    1,
                                    row.field.split(":")[0].length
                                  )} - ${
                                  HEADERS_NAME_INPUT_FILE[row.field[0]]
                                }: "${get(row, "message", "")}"`}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.textDownloadFiles}>
                          <Link href={props.url} target={"_blank"}>
                            {LabelsDispersion.VALIDATION_DOWNLOAD_FILES}
                          </Link>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
