/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_NICARAGUA: IOptionSelection[] = [
  {
    value: "Banco de la Producción (BANPRO)",
    label: "Banco de la Producción (BANPRO)",
  },
  {
    value: "Banco de Crédito Centroamericano (BANCENTRO)",
    label: "Banco de Crédito Centroamericano (BANCENTRO)",
  },
  {
    value: "Banco de América Central (BAC)",
    label: "Banco de América Central (BAC)",
  },
  { value: "Banco de Finanzas (BDF)", label: "Banco de Finanzas (BDF)" },
  { value: "Banco Ficohsa de Nicaragua", label: "Banco Ficohsa de Nicaragua" },
  { value: "Banco Avanz", label: "Banco Avanz" },
  { value: "Financiera FAMA", label: "Financiera FAMA" },
];
