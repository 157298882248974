export const ADD_TEXT = "Agregar sub-accionista";
export const BIRTH_DATE_TEXT = "Fecha de nacimiento:";
export const CONSTITUTION_DATE_TEXT = "Fecha de constitución:";
export const NAME_TEXT = "Nombres y Apellidos:";
export const NID_TEXT = "No. de ID:";
export const PARTICIPATION_SHAREHOLDERS_TEXT =
  "Total de participación de accionistas:";
export const RECIDENCE_COUNTRY_TEXT = "País de residencia:";
export const REGISTERED_SHAREHOLDERS_TEXT = "Accionistas registrados:";
export const SOCIAL_REASON_TEXT = "Razón social:";
