import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";

export interface SnackProps {
  msg: string;
  open: boolean;
  type: Color;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
}

const styles = () => ({
  snackbar: {
    color: "#FFFFFF",
    backgroundColor: "#E24763",
    fontWeight: 500,
  },
});

export function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NotificationSnackbar = (props: SnackProps) => {
  const [open, setOpen] = React.useState(props.open);
  const inlineStyles = styles();
  useEffect(() => setOpen(props.open), [props.open]);

  return (
    <React.Fragment>
      <Snackbar
        id="snackbar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={props.handlerClose}
      >
        <Alert
          onClose={props.handlerClose}
          severity={props.type}
          style={inlineStyles.snackbar}
        >
          {props.msg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
export default NotificationSnackbar;
