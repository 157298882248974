import React, { PropsWithChildren } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Info } from "react-feather";
import { infoIconsProcessorAccordionStyles } from "./InfoIconsProcessorAccordion.styles";
import { ProcessorConstants } from "../../shared/constants/ProcessorConstants";
import { get } from "lodash";
import { useInfoIconsProcessorAccordionState } from "./state/useInfoIconsProcessorAccordionState";
import { IInfoIconsProcessorAccordionProps } from "./IInfoIconsProcessorAccordionProps.interfaces";

export const InfoIconsProcessorAccordion: React.FC<IInfoIconsProcessorAccordionProps> = (
  props: PropsWithChildren<IInfoIconsProcessorAccordionProps>
) => {
  const classes = infoIconsProcessorAccordionStyles();
  const {
    isDefaultProcessor,
    showTooltipFailover,
  } = useInfoIconsProcessorAccordionState(props);

  return (
    <>
      <Box className={classes.mainBox}>
        {showTooltipFailover && (
          <Tooltip
            title={`${ProcessorConstants.FAIL_OVER_TEXT} ${get(
              props,
              "processor.failOverProcessor"
            )}`}
            placement={"top"}
          >
            <Info size={19} />
          </Tooltip>
        )}
      </Box>

      {isDefaultProcessor(
        props.processor.alias,
        props.processor.publicProcessorId
      ) && (
        <>
          <Box className={classes.emptyBox} />
          <Box className={classes.defaultProcessorBox}>
            <Typography className={classes.defaultProcessorText}>
              {ProcessorConstants.DEFAULT.toUpperCase()}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};
