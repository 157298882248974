import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { FC } from "react";
import {
  ItemMerchantProps,
  MenuOptionsTableProps,
} from "./MenuOption.interfaces";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useMenuOptionsState } from "./hooks/useMenuOptionsState";

export const MenuOptions: FC<MenuOptionsTableProps> = ({
  items,
  transaction,
}) => {
  const {
    anchorEl,
    handleClick,
    handleClickItem,
    handleClose,
    handleDisabled,
    menuOptionsStyles,
    open,
  } = useMenuOptionsState();

  return (
    <>
      <Button
        sx={menuOptionsStyles.btn}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        id={"btnMenuOptions"}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "btnMenuOptions",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {items.map((item: ItemMerchantProps) => (
          <MenuItem
            disabled={handleDisabled(item, transaction)}
            key={`item_${item.title}`}
            onClick={() => handleClickItem(item)}
          >
            <Typography>{item.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
