import React from "react";
import { Grid, Link, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  footerTitle: {
    link: {
      textDecoration: "underline",
    },
  },
  underline: {
    textDecoration: "underline",
  },
  paddingFooter: {
    padding: theme.spacing(2, 2),
  },
  paddingBottom: {
    paddingBottom: "32px!important",
  },
}));

export interface ICardContainerFooterProps {
  href?: string;
  footerTitle?: string;
  onClickHandler?: () => void;
}

export const CardContainerFooter: React.FC<ICardContainerFooterProps> = (
  props: React.PropsWithChildren<ICardContainerFooterProps>
) => {
  const style = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={style.paddingBottom}>
        <Link
          component={RouterLink}
          to={props.href!}
          color="inherit"
          className={`${style.paddingFooter} ${style.footerTitle} ${style.underline}`}
          onClick={props.onClickHandler}
        >
          {props.footerTitle}
        </Link>
      </Grid>
    </Grid>
  );
};
