import { EntityName } from "../../enums/entityName";
import _ from "lodash";

const validateIsCentralizeNode = (obj: string): boolean => {
  return !_.isEmpty(obj);
};

export const validIsCentralized = (
  entityName: string,
  centralizedNodesId: string
): boolean => {
  return entityName === EntityName.BRANCH
    ? validateIsCentralizeNode(centralizedNodesId)
    : false;
};
