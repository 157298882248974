import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { SkeletonListContainer } from "../Skeleton/SkeletonListContainer";
import { Indicators } from "../Indicators/Indicators";
import DashboardTable from "../DashboardList/Table/DashboardBillingTable/DashboardTable";
import { DashboardTabsProps, TabPanel } from "../DashboardTabs/DashboardTabs";
import DashboardHistoricTable from "../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";
import { get } from "lodash";
import { RetentionCountry } from "../../shared/infrastructure/RetentionCountry";
import { RetentionDashboard } from "../RetentionDashboard/RetentionDashboard";
import { BillingTabTitleEnum } from "../../shared/infrastructure/BillingTabTitleEnum";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontWeight: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "75%",
      height: "10%",
    },
    root: { background: "transparent", boxShadow: "none" },
    tabStyle: {
      textTransform: "none",
    },
    paper: {
      backgroundColor: "#F7FAFC",
      marginTop: 0,
    },
  })
);
function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ValidatorDashboardTab: React.FC<DashboardTabsProps> = (
  props: DashboardTabsProps
) => {
  const {
    statusFlow,
    country,
    isLoadingHistoric,
    historicFilterProps,
    handleChangePageSize,
    handleChangePage,
    modalOver,
    modalBackRetention,
    reprocess,
    tableColumns,
    textArea,
    sortProps,
    stateRentetionDashboard,
    downloadFileState,
  } = props;
  const classes = useStyles(0);
  const [value, setValue] = useState<number>(0);
  const validateRetentionCountry = (): boolean => {
    return RetentionCountry[props.country].value;
  };

  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!validateRetentionCountry() && value === 2) setValue(1);
    if (validateRetentionCountry() && value === 1) setValue(2);
  }, [props.country]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AppBar position="static" className={classes.root}>
          <Tabs
            value={value}
            onChange={handleTabsChange}
            aria-label="billing tabs"
            variant="fullWidth"
            indicatorColor={"primary"}
          >
            <Tab
              label={
                <Typography variant={"h6"} color={"primary"}>
                  {BillingTabTitleEnum.BILLING_VALIDATOR}
                </Typography>
              }
              {...tabProps(0)}
              className={classes.tabStyle}
            />
            {validateRetentionCountry() && (
              <Tab
                label={
                  <Typography variant={"h6"} color={"primary"}>
                    Retención
                  </Typography>
                }
                {...tabProps(1)}
                className={classes.tabStyle}
              />
            )}
            <Tab
              label={
                <Typography variant={"h6"} color={"primary"}>
                  Histórico
                </Typography>
              }
              {...tabProps(validateRetentionCountry() ? 2 : 1)}
              className={classes.tabStyle}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Indicators statusFlow={statusFlow} />
          {props.isLoading ? (
            <SkeletonListContainer />
          ) : (
            <Paper className={classes.paper} elevation={0}>
              <DashboardTable
                country={props.country}
                handleCheckboxChange={props.handleCheckboxChange}
                checked={props.checked}
                filterProps={props.filterProps}
                modalOverBilling={props.modalOverBilling}
                statusFlow={statusFlow}
                isLoadingProcessBilling={props.isLoadingProcessBilling}
                isLoading={props.isLoading}
                tab={0}
              />
            </Paper>
          )}
        </TabPanel>
        {validateRetentionCountry() && (
          <TabPanel value={value} index={1}>
            <RetentionDashboard
              country={country}
              stateRentetionDashboard={stateRentetionDashboard!}
              indicatorsFlow={StatusFlowEnum.VALIDATOR}
              isRetentionCountry={true}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={validateRetentionCountry() ? 2 : 1}>
          <React.Fragment>
            <Indicators statusFlow={statusFlow} isHistoric={true} />
            {isLoadingHistoric ? (
              <SkeletonListContainer />
            ) : (
              <Paper className={classes.paper} elevation={0}>
                <DashboardHistoricTable
                  statusFlow={statusFlow}
                  historicFilterProps={historicFilterProps}
                  pageSize={get(props, "pageSize", 0)}
                  page={get(props, "page", 0)}
                  handleChangePageSize={handleChangePageSize}
                  handleChangePage={handleChangePage}
                  hideNext={get(props, "hideNext", false)}
                  hidePrevious={get(props, "hidePrevious", false)}
                  modalOver={modalOver}
                  modalBackRetention={modalBackRetention}
                  reprocess={reprocess}
                  country={country}
                  tableColumns={tableColumns}
                  textArea={textArea}
                  sortProps={sortProps}
                  downloadFileState={downloadFileState}
                />
              </Paper>
            )}
          </React.Fragment>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
