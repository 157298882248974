import React from "react";
import InformationRow from "../InformationRow/InformationRow";
import { IRefundProcessorInfoProps } from "./RefundProcessorInfo.interfaces";

const RefundProcessorInfo = ({
  message,
  name,
  retries,
}: IRefundProcessorInfoProps) => {
  return (
    <>
      <InformationRow label="Procesador" value={name} />
      <InformationRow label="Mensaje respuesta" value={message} />
      <InformationRow label="Reintentos" value={retries} />
    </>
  );
};

export default RefundProcessorInfo;
