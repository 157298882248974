import { RuleAlarmsItemsEnum } from "@shared/enum/RuleAlarmsOptionsEnum";
import { BASE_ROUTES, ROUTES } from "@shared/constants/routes";
import { TypeEnum } from "@shared/constants/AlarmConfigConstants";

export const redirectOptions = (redirect: Function): object => ({
  [RuleAlarmsItemsEnum.ALARM_USER]: () =>
    redirect(
      `${BASE_ROUTES.NODE_SELECTION}/${TypeEnum.INDIVIDUAL}?hideSideBar=true&hideTopBar=true`
    ),
  [RuleAlarmsItemsEnum.ALARM_GENERAL]: () =>
    redirect(
      `${BASE_ROUTES.CONFIG}/${TypeEnum.GENERAL}?hideSideBar=true&hideTopBar=true`
    ),
});

export const redirectSaveButton = () => {
  return window.location.pathname.includes(TypeEnum.INDIVIDUAL)
    ? `${ROUTES.SPA_BASENAME}/${TypeEnum.INDIVIDUAL}`
    : `${ROUTES.SPA_BASENAME}/${TypeEnum.GENERAL}`;
};
