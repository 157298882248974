/**
 * Catalog Colombia
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogMx: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    { name: "BANCOMEXT", value: "37006" },
    { name: "BANOBRAS", value: "37009" },
    { name: "BANJERCITO", value: "37019" },
    { name: "NACIONAL FINANCIERA", value: "37135" },
    { name: "BANSEFI", value: "37166" },
    { name: "HIPOTECARIA FEDERAL", value: "37168" },
    { name: "BANAMEX", value: "40002" },
    { name: "BBVA BANCOMER", value: "40012" },
    { name: "BANCO SANTANDER", value: "40014" },
    { name: "HSBC", value: "40021" },
    { name: "BAJIO", value: "40030" },
    { name: "IXE", value: "40032" },
    { name: "INBURSA", value: "40036" },
    { name: "INTERACCIONES", value: "40037" },
    { name: "MIFEL", value: "40042" },
    { name: "SCOTIA BANK INVERLAT", value: "40044" },
    { name: "BANREGIO", value: "40058" },
    { name: "INVEX", value: "40059" },
    { name: "BANSI", value: "40060" },
    { name: "AFIRME", value: "40062" },
    { name: "BANORTE", value: "40072" },
    { name: "ROYAL BANK OF SCOTLAND", value: "40102" },
    { name: "AMERICAN EXPRESS", value: "40103" },
    { name: "BANK OF AMERICA", value: "40106" },
    { name: "TOKYO", value: "40108" },
    { name: "JP MORGAN", value: "40110" },
    { name: "BANCO MONEX", value: "40112" },
    { name: "VE POR MAS", value: "40113" },
    { name: "ING", value: "40116" },
    { name: "DEUTCHE", value: "40124" },
    { name: "CREDIT SUISSE", value: "40126" },
    { name: "AZTECA", value: "40127" },
    { name: "BANCO AUTOFIN", value: "40128" },
    { name: "BARCLAYS BANK", value: "40129" },
    { name: "BANCO COMPARTAMOS", value: "40130" },
    { name: "BANCO DE AHORRO FAMSA", value: "40131" },
    { name: "BANCO MULTIVA", value: "40132" },
    { name: "PRUDENTIAL BANK", value: "40133" },
    { name: "BANCO WAL MART", value: "40134" },
    { name: "BANCO REGIONAL SA", value: "40136" },
    { name: "BANCOPPEL", value: "40137" },
    { name: "BANCO AMIGO", value: "40138" },
    { name: "UBS BANK MEXICO", value: "40139" },
    { name: "BANCO FACIL", value: "40140" },
    { name: "VOLKSWAGEN BANK", value: "40141" },
    { name: "CI BANCO", value: "40143" },
    { name: "ASP INTEGRA OPC ", value: "90659" },
    { name: "BBASE", value: "40145" },
    { name: "BANKAOOL", value: "40147" },
    { name: "PAGATODO", value: "40148" },
    { name: "INMOBILIARIO", value: "40150" },
    { name: "BANCREA", value: "40152" },
    { name: "BANCO FINTERRA", value: "40154" },
    { name: "ICBC", value: "40155" },
    { name: "SABADELL", value: "40156" },
    { name: "MIZUHO BANK", value: "40158" },
    { name: "MONEXCB", value: "90600" },
    { name: "GBM", value: "90601" },
    { name: "MASARI", value: "90602" },
    { name: "VALUE", value: "90605" },
    { name: "ESTRUCTURADORES", value: "90606" },
    { name: "VECTOR", value: "90608" },
    { name: "MULTIVA CBOLSA", value: "90613" },
    { name: "ACCIVAL", value: "90614" },
    { name: "FINAMEX", value: "90616" },
    { name: "VALMEX", value: "90617" },
    { name: "PROFUTURO", value: "90620" },
    { name: "CB ACTINVER", value: "90621" },
    { name: "SKANDIA", value: "90623" },
    { name: "CBDEUTSCHE", value: "90626" },
    { name: "ZURICH", value: "90627" },
    { name: "ZURICHVI", value: "90628" },
    { name: "CB INTERCAM", value: "90630" },
    { name: "CI BOLSA", value: "90631" },
    { name: "FINCOMUN", value: "90634" },
    { name: "HDI SEGUROS", value: "90636" },
    { name: "ORDER", value: "90637" },
    { name: "AKALA", value: "90638" },
    { name: "CB JPMORGAN", value: "90640" },
    { name: "REFORMA", value: "90642" },
    { name: "STP", value: "90646" },
    { name: "EVERCORE", value: "90648" },
    { name: "OSKNDIA", value: "90649" },
    { name: "ASEA", value: "90652" },
    { name: "KUSPIT", value: "90653" },
    { name: "SOFIEXPRESS", value: "90655" },
    { name: "UNAGRA", value: "90656" },
    { name: "LIBERTAD", value: "90670" },
    { name: "HUASTECAS", value: "90671" },
    { name: "CAJA POP MEXICA", value: "90677" },
    { name: "CRISTOBAL COLON", value: "90680" },
    { name: "PRINCIPAL", value: "90681" },
    { name: "CAJA TELEFONIST", value: "90683" },
    { name: "CLS", value: "90901" },
    { name: "INDEVAL", value: "90902" },
    { name: "ALQUIMIAPAY", value: "659" },
    { name: "FONDEADORA", value: "90699" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.MXN} - Pesos`,
      value: CurrencyEnum.MXN,
    },
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
};
