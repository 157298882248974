import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { modalFormStyle as styles } from "../../ModalForm/ModalForm.styles";
import CloseIcon from "@mui/icons-material/Close";
import PhysicalForm from "../Generics/PhysicalForm";
import InfoBox from "../../InfoBox/InfoBox";
import { usePhysicalPersonForm } from "./state/usePhysicalPersonForm";
import { CountryEnum } from "../../../../shared/countryEnum";

const PhysicalPersonForm: React.FC = () => {
  const {
    values: {
      country,
      countryWatch,
      constitutionalCountry,
      rulesDocumentNumber,
      listDocTypesByCountry,
    },
    form,
    closeModal,
    onSubmit,
    legalRepresentative,
    isEdited,
  } = usePhysicalPersonForm();

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant={"h3"}>
            {isEdited.value ? "Editar" : "Agregar "} representante legal -
            Principal
          </Typography>
          <IconButton
            data-testid="close-button"
            color="secondary"
            onClick={closeModal}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container sx={{ marginBottom: "20px" }}>
            {legalRepresentative.length === 0 && <InfoBox />}
          </Grid>
          <PhysicalForm
            isDisable={constitutionalCountry !== CountryEnum.panama}
            control={form.control}
            rules={rulesDocumentNumber}
            country={countryWatch}
            options={listDocTypesByCountry}
            constitutionalCountry={country}
          />
        </DialogContent>
      </Box>
      <DialogActions sx={styles.footer}>
        <Button onClick={closeModal}>Cancelar</Button>
        <Button
          data-testid="button-form"
          variant={"contained"}
          onClick={form.handleSubmit(onSubmit)}
        >
          {isEdited.value ? "Guardar" : "Agregar"}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default PhysicalPersonForm;
