import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  body: {
    padding: "10px 32px 10px 0px",
  },
  labelTitle: {
    color: "#023365",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "10.5px",
  },
  helperText: {
    marginLeft: "-14px",
    color: "#6D7781",
    display: "flex",
    alignItems: "center",
    marginBottom: "17px",
    marginTop: "16.5px",
    fontSize: "12px",
  },
  errorText: {
    marginLeft: "-14px",
    color: "#AD0C2A",
    display: "flex",
    alignItems: "center",
    marginBottom: "6.5px",
    marginTop: "4px",
    fontSize: "14px",
  },
  inputTextCredential: {
    marginRight: "10px",
  },
  credentialsSection: {
    marginTop: "36px",
  },
  helperTextIcon: {
    color: "transparent",
    marginRight: "8px",
  },
  labelInfo: {
    color: "#6D7781",
    fontSize: "12px",
    lineHeight: "136%",
    marginLeft: "8px",
    flex: 1,
  },
  configButton: {
    height: "48px",
    "&.Mui-disabled": {
      backgroundColor: "#B4B9BE",
      color: "#ffffff",
    },
  },
  containerInfo: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
  labelDescription: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: "12px",
    marginBottom: "10.5px",
  },
  alertLabelDescription: {
    color: "#023365",
    fontWeight: 400,
    fontSize: "12px",
    alignItems: "center",
    marginBottom: "0px",
    display: "flex",
  },
  saveButton: {
    borderRadius: 0,
    backgroundColor: "white",
    height: 48,
    marginLeft: 5,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      borderRadius: 5,
      marginTop: 7,
      marginBottom: 7,
    },
  },
  inputLabel: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
}));
