import { IStyles } from "../../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  container: {
    "&:before": {
      content: "none",
    },
    paddingBottom: "3px",
  },
};
