export enum CountryEnum {
  BRAZIL = "Brazil",
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  ECUADOR = "Ecuador",
  MEXICO = "Mexico",
  PERU = "Peru",
  GUATEMALA = "Guatemala",
  PANAMA = "Panama",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  COSTA_RICA_FLAG = "CostaRica",
  SALVADOR_FLAG = "ElSalvador",
  BRASIL = "Brasil",
}

export const COUNTRY_TRANSLATOR: Record<string, string> = {
  [CountryEnum.BRAZIL]: CountryEnum.BRASIL,
};
