import { useEffect, useState } from "react";
import { DataTableFiltersProps } from "../../../components/Filters/DataTableFilters";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../../types/response_search_rules_by_filters";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  getAuthenticationRulesDataTabs,
  setAuthenticationRulesApprovalData,
  processRulesData,
  setPathLoadingProcessRates,
  setRuleDataCounter,
} from "../../../store/actionCreators";
import { cloneDeep } from "lodash";
import { DefaultFilter } from "../../../shared/infrastructure/filter/DefaultFilter";
import { SearchAuthenticationRulesRequest } from "../../../../types/search_authentication_rules_request";
import { AuthenticationElastic } from "../../../../types/remote/authentication_elastic";
import { TRuleApprovalDashboardProps } from "../RuleApprovalDashboard";
import { ProcessorInfoResponse } from "../../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../../types/credential_info_response";

export interface IRuleApprovalState {
  state: IState;
  dataModal: {
    handleViewModal: (open: boolean, action: StatusEnum) => void;
    openModal: boolean;
    actionModal: StatusEnum;
    handleAccept: (action: StatusEnum) => void;
    handleClose: (action: StatusEnum) => void;
    title: string;
    description: string;
    setDescription: (value: string) => void;
  };
  pagination: IPagination;
  filterProps: DataTableFiltersProps;
  checkboxActions: {
    handleChangeCheckboxHeader: (name: string, value: any) => void;
    handleCheckboxChange: (
      type: StatusEnum,
      value: boolean,
      index: number
    ) => void;
    checkedApprove: boolean;
    checkedReject: boolean;
  };
  data: {
    authenticationRulesApprovalData: ResponseSearchRulesByFilters;
    isLoading: boolean;
    orderDataDashboard: (records: AuthenticationElastic[]) => void;
  };
  modalOver: {
    openModalCard: boolean;
    handlerCloseModal: () => void;
    handlerSelectTrx: (
      row: AuthenticationRules | AuthenticationElastic | undefined,
      type: string
    ) => void;
    trx: AuthenticationRules | AuthenticationElastic;
    processorData: ProcessorInfoResponse[];
    credentialData: CredentialInfoResponse[];
  };
}
export interface IPagination {
  page: number;
  pageSize: number;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
}

export interface IState {}
export const approveTitle: string =
  "Se aprobarán todas las reglas seleccionadas.";
export const rejectTitle: string = "Ingrese el motivo del rechazo.";

export const useRuleApprovalDashboardState = (
  props: TRuleApprovalDashboardProps
): IRuleApprovalState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const dispatch = useDispatch();
  const {
    pagination: { page, pageSize, handleChangePage, handleChangePageSize },
    filterData: {
      queryInput,
      handleInputChange,
      handleSearchBar,
      range,
      handleSetDate,
      filterChips,
      handleSetFilters,
      counter,
    },
    stateUtil,
  } = props.stateUtilTab;
  const [state] = useState<IState>({});
  const [trx, SetTrx] = useState<AuthenticationRules | AuthenticationElastic>(
    {}
  );
  const [checkboxHeaderState, setCheckboxHeaderState] = useState<{
    approval: boolean;
    rejected: boolean;
  }>({
    approval: false,
    rejected: false,
  });

  const [description, setDescription] = useState<string>("");

  const [indexTrx, setIndexTrx] = useState<number | null>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalCard, setOpenModalCard] = useState<boolean>(false);
  const [actionModal, setActionModal] = useState<StatusEnum>(
    StatusEnum.APPROVE
  );
  const [processModal, setProcessModal] = useState<boolean>(false);
  const [processLoader, setProcessLoader] = useState<boolean>(false);

  const setDescriptionData = (value: string) => {
    setDescription(value);
  };
  const handleViewModal = (open: boolean, action: StatusEnum) => {
    setOpenModal(open);
    setActionModal(action);
  };
  const handleAccept = (action: StatusEnum) => {
    handleViewModal(false, action);
    if (indexTrx !== null && action === StatusEnum.REJECT) {
      updateRowTrx(action, indexTrx, true);
      return;
    }
    handleUpdateAllRows(action, { [action]: true });
  };

  const handleClose = (action: StatusEnum) => {
    handleViewModal(false, action);
  };

  const handleChangeCheckboxHeader = (name: string, value: any) => {
    if (name === StatusEnum.APPROVE && value === true) {
      handleViewModal(true, StatusEnum.APPROVE);
      return;
    }
    if (name === StatusEnum.REJECT && value === true) {
      handleViewModal(true, StatusEnum.REJECT);
      return;
    }
    handleUpdateAllRows(StatusEnum.OMIT, {
      [StatusEnum.APPROVE]: false,
      [StatusEnum.REJECT]: false,
    });
  };

  const handleCheckboxChange = (
    type: StatusEnum,
    value: boolean,
    index: number
  ) => {
    if (type === StatusEnum.APPROVE) {
      updateRowTrx(type, index, value);
      return;
    }
    if (type === StatusEnum.REJECT && value) {
      handleViewModal(true, StatusEnum.REJECT);
      setIndexTrx(index);
    } else {
      updateRowTrx(type, index, value);
      return;
    }
  };
  const updateRowTrx = (type: StatusEnum, index: number, checked: boolean) => {
    let aux_data: ResponseSearchRulesByFilters = cloneDeep(
      stateStore.authenticationRulesApprovalData
    );
    let aux_item = aux_data.records![index];
    aux_item.description =
      type === StatusEnum.REJECT && checked ? description : "";
    aux_item.status = checked ? type : StatusEnum.OMIT;
    setIndexTrx(null);
    setDescription("");
    setCheckboxHeaderState({
      ...checkboxHeaderState,
      approval: false,
      rejected: false,
    });
    updateAuthenticationRulesApprovalData(aux_data);
  };
  const updateAuthenticationRulesApprovalData = (
    data: ResponseSearchRulesByFilters
  ) => {
    dispatch(setAuthenticationRulesApprovalData(data));
  };

  const orderDataDashboard = (records: AuthenticationElastic[]) => {
    updateAuthenticationRulesApprovalData({
      records: [...records],
      total: stateStore.authenticationRulesApprovalData.total,
    });
  };
  const executeReloadData = (
    params: Partial<SearchAuthenticationRulesRequest>
  ) => {
    dispatch(
      getAuthenticationRulesDataTabs(
        0,
        params as SearchAuthenticationRulesRequest
      )
    );
  };

  const processRules = () => {
    dispatch(processRulesData());
  };
  const handleProcessModalClose = () => {
    setProcessModal(false);
  };
  const handleProcessModalOpen = () => {
    setProcessModal(true);
  };
  const handleCloseProcessLoader = () => {
    setProcessLoader(false);
  };

  const handleProcessTransaction = () => {
    setProcessModal(false);
    setProcessLoader(true);
    processRules();
  };

  const handlerSelectTrx = (
    row: AuthenticationRules | AuthenticationElastic | undefined,
    type: string
  ) => {
    if (row !== undefined && type !== "checkbox") {
      setOpenModalCard(true);
      props.getProcessorData(row.public_merchant_id!);
      props.getCredentialData(row.public_merchant_id!);
      SetTrx(row);
    }
  };

  const handlerCloseModal = () => setOpenModalCard(false);

  const handleUpdateAllRows = (
    statusRecord: StatusEnum,
    checkBoxHeaders: {
      [StatusEnum.APPROVE]?: boolean;
      [StatusEnum.REJECT]?: boolean;
    }
  ) => {
    let aux_data: ResponseSearchRulesByFilters = cloneDeep(
      stateStore.authenticationRulesApprovalData
    );
    aux_data.records?.forEach((record) => {
      record.status = statusRecord;
      record.description =
        checkBoxHeaders[StatusEnum.REJECT] &&
        checkBoxHeaders[StatusEnum.REJECT] === true
          ? description
          : "";
    });
    updateAuthenticationRulesApprovalData(aux_data);
    setCheckboxHeaderState({
      ...checkboxHeaderState,
      ...checkBoxHeaders,
    });
    setDescription("");
    setIndexTrx(null);
  };

  useEffect(() => {
    if (stateStore.pathLoadingProcessRates) {
      executeReloadData(stateUtil);
      setCheckboxHeaderState({
        ...checkboxHeaderState,
        rejected: false,
        approval: false,
      });
      dispatch(setPathLoadingProcessRates(false));
      dispatch(
        setRuleDataCounter({
          contador: 0,
          total: 0,
        })
      );
      handleCloseProcessLoader();
    }
  }, [stateStore.pathLoadingProcessRates]);

  return {
    state,
    dataModal: {
      handleViewModal,
      openModal,
      actionModal,
      handleAccept,
      handleClose,
      description,
      setDescription: setDescriptionData,
      title: actionModal === StatusEnum.APPROVE ? approveTitle : rejectTitle,
    },
    pagination: {
      page,
      pageSize,
      handleChangePage,
      handleChangePageSize,
    },
    filterProps: {
      handleSetFilters,
      handleInputChange,
      handleSearchBar,
      handleSetDate,
      filters: cloneDeep(DefaultFilter),
      filterChips,
      range,
      counter,
      optionTabValue: 0,
      query: queryInput,
      selectedColumns: [],
      setSelectedColumns: () => "erty",
      handleAppliedColumnsFilters: () => "erty",
      rulesDataCounter: stateStore.rulesDataCounter,
      rulesData: stateStore.authenticationRulesApprovalData,
      processModal,
      processLoader,
      handleProcessTransaction,
      handleProcessModalClose,
      handleProcessModalOpen,
      handleCloseProcessLoader,
    },
    checkboxActions: {
      handleChangeCheckboxHeader,
      checkedApprove: checkboxHeaderState.approval,
      checkedReject: checkboxHeaderState.rejected,
      handleCheckboxChange,
    },
    data: {
      authenticationRulesApprovalData:
        stateStore.authenticationRulesApprovalData,
      isLoading: stateStore.isLoadingDashboardApproval,
      orderDataDashboard,
    },
    modalOver: {
      openModalCard: openModalCard,
      handlerCloseModal: handlerCloseModal,
      handlerSelectTrx: handlerSelectTrx,
      trx,
      processorData: props.processorData,
      credentialData: props.credentialData,
    },
  };
};
