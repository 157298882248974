import React, { FC } from "react";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { DetailHeaderStyles } from "./DetailHeader.styles";
import { DetailHeaderProps } from "../../../../shared/interfaces/TransactionModal.interfaces";
import TransactionStatusChip from "../../../common/TransactionStatusChip/TransactionStatusChip";

const DetailHeader: FC<DetailHeaderProps> = (props: DetailHeaderProps) => {
  const { actualTab, handleChangeTab, date, hour, id, status, url } = props;

  const handleCopyUrl = async () => {
    return await navigator.clipboard.writeText(url!);
  };

  return (
    <>
      <Box p="16px 24px">
        <Typography sx={DetailHeaderStyles.modalTitle}>
          Detalle de transacción
        </Typography>
        <Typography sx={DetailHeaderStyles.transactionId}>{id}</Typography>
        <Typography sx={DetailHeaderStyles.transactionDate}>
          {date} {hour}
        </Typography>
        <Grid container direction="row" alignContent="center">
          <Grid item>
            <TransactionStatusChip status={status} />
          </Grid>
          {url && url !== "" && (
            <Grid item sx={DetailHeaderStyles.clipboardGrid}>
              <Button
                size="small"
                startIcon={<ContentCopy fontSize="small" />}
                onClick={handleCopyUrl}
                sx={DetailHeaderStyles.copyURLButton}
              >
                Copiar URL del Certificado
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={DetailHeaderStyles.tabsContainer}>
        <Tabs value={actualTab} onChange={handleChangeTab}>
          <Tab label="Información" value={0} sx={DetailHeaderStyles.tab} />
          <Tab label="Línea de tiempo" value={1} sx={DetailHeaderStyles.tab} />
          <Tab label="Comentarios" value={2} sx={DetailHeaderStyles.tab} />
        </Tabs>
      </Box>
    </>
  );
};

export default DetailHeader;
