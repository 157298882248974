import { ActionKeyEnum, ActionRefundEnum } from "../enums/ActionEnum";
import {
  TransactionRefundMassiveRequest,
  Transactions,
} from "../../../../types/process_transaction_refund_request";
import { get } from "lodash";
import { TransactionData } from "../../../../types/transaction_data";

export const filterTrx = (
  transactionSelectedData: TransactionData[],
  actionKey: ActionKeyEnum
) => {
  return transactionSelectedData.filter(
    (item: TransactionData) => get(item, "action", "") === actionKey
  );
};

export const switchTrxRefund = (
  actionRefundEnum: ActionRefundEnum,
  transactions: Transactions[],
  trxSelectedCheckCancel: TransactionRefundMassiveRequest[],
  trxSelectedCheckRefund: TransactionRefundMassiveRequest[],
  trxSelectedCheckGiveBack: TransactionRefundMassiveRequest[],
  trxSelectedCheckOmit: TransactionRefundMassiveRequest[]
) => {
  switch (actionRefundEnum) {
    case ActionRefundEnum.APPROVE:
      if (trxSelectedCheckCancel.length > 0)
        transactions.push({ APRCNL: trxSelectedCheckCancel });
      if (trxSelectedCheckRefund.length > 0)
        transactions.push({ APRRFN: trxSelectedCheckRefund });
      if (trxSelectedCheckGiveBack.length > 0)
        transactions.push({ APRGBCK: trxSelectedCheckGiveBack });
      if (trxSelectedCheckOmit.length > 0)
        transactions.push({ APROMT: trxSelectedCheckOmit });
      break;
    case ActionRefundEnum.NOAPPROVE:
      if (trxSelectedCheckCancel.length > 0)
        transactions.push({ DCLCNL: trxSelectedCheckCancel });
      if (trxSelectedCheckRefund.length > 0)
        transactions.push({ DCLRFN: trxSelectedCheckRefund });
      if (trxSelectedCheckGiveBack.length > 0)
        transactions.push({ DCLGBCK: trxSelectedCheckGiveBack });
      if (trxSelectedCheckOmit.length > 0)
        transactions.push({ DCLOMT: trxSelectedCheckOmit });
      break;
  }
};
