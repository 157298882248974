import React, { useEffect } from "react";
import { cloneDeep, get, isEmpty, isEqual, set } from "lodash";
import { format } from "date-fns";
import { GetConciliationTransactionsRequest } from "../../../../types/get_conciliation_transactions_request";
import {
  useAppDispatch,
  useAppSelector,
  useKshRedux,
} from "../../../store/hooks/hooks";
import {
  FilterComponentProps,
  IChipSelect,
  IFilterComponentState,
  IOptionFilter,
  IRangeAmount,
} from "../FilterComponent.interfaces";
import {
  DeclinedStatus,
  KeyStatusEnum,
  NotExistStatus,
} from "../../../shared/infrastructure/KeyStatusEnum";
import { selectFiltersState } from "../../../store/selectors/filters/filters";
import { DATE_FORMAT_ELASTIC } from "../../common/DateTimeRangePicker/utils/constants";
import { setGeneralFilter } from "../../../store/actions/filters/filters";
import { FilterTitlesEnum } from "../../../shared/infrastructure/enums/FilterTitlesEnum";
import { IdFilterEnum } from "../../../shared/infrastructure/IdFilterEnum";
import { FilterLabelEnum } from "../../../shared/infrastructure/FilterLabelEnum";
import { FilterTitleEnum } from "../../../shared/infrastructure/FilterTitleEnum";
import { CurrencyCountry } from "../../../shared/infrastructure/CurrencyCatalog";

const defaultRangeAmount: IRangeAmount = {
  eq: "",
  max: "",
  min: "",
  type: "min",
};

export const useFilterComponentState = (
  props: FilterComponentProps
): IFilterComponentState => {
  const filtersState = useAppSelector(selectFiltersState);
  const { page, limit } = filtersState.pagination;

  const calculateOffset = () => (page > 0 ? (page - 1) * limit : 0);

  const dispatch = useAppDispatch();
  const defaultRequest: GetConciliationTransactionsRequest = {
    country: filtersState.headerFilter.country,
    filter: {},
    from: format(new Date(filtersState.date.from), DATE_FORMAT_ELASTIC),
    limit: filtersState.pagination.limit,
    offset: 0,
    paymentMethod: filtersState.headerFilter.paymentMethod,
    processorName: filtersState.headerFilter.processorName,
    sort: {
      field: "created",
      order: "asc",
    },
    source: "pending",
    to: format(new Date(filtersState.date.to), DATE_FORMAT_ELASTIC),
  };

  const [search, setSearch] =
    React.useState<GetConciliationTransactionsRequest>(defaultRequest);

  const DefaultFilter: IOptionFilter[] = [
    {
      id: IdFilterEnum.transactionStatusKushki,
      options: [
        {
          key: KeyStatusEnum.APPROVAL_KUSHKI_STATUS,
          label: FilterLabelEnum.APPROVED,
          selected: false,
        },
        {
          key: KeyStatusEnum.DECLINED_KUSHKI_STATUS,
          label: FilterLabelEnum.DECLINED,
          selected: false,
        },
        {
          key: KeyStatusEnum.NOT_EXIST_KUSHKI_STATUS,
          label: FilterLabelEnum.NOT_EXIST,
          selected: false,
        },
      ],
      title: FilterTitleEnum.kushkiStatus,
    },
    {
      id: IdFilterEnum.transactionStatusProcessor,
      options: [
        {
          key: KeyStatusEnum.APPROVED_PROCESSOR_STATUS,
          label: FilterLabelEnum.APPROVED,
          selected: false,
        },
        {
          key: KeyStatusEnum.DECLINED_PROCESSOR_STATUS,
          label: FilterLabelEnum.DECLINED,
          selected: false,
        },
        {
          key: KeyStatusEnum.NOT_EXIST_PROCESSOR_STATUS,
          label: FilterLabelEnum.NOT_EXIST,
          selected: false,
        },
      ],
      title: FilterTitleEnum.processorStatus,
    },
    {
      id: IdFilterEnum.transactionType,
      options: [
        {
          key: KeyStatusEnum.SALE_TRX_TYPE,
          label: FilterLabelEnum.SALE,
          selected: false,
        },
        {
          key: KeyStatusEnum.REFUND_TRX_TYPE,
          label: FilterLabelEnum.REFUND,
          selected: false,
        },
        {
          key: KeyStatusEnum.VOID_TRX_TYPE,
          label: FilterLabelEnum.VOID,
          selected: false,
        },
      ],
      title: FilterTitleEnum.transactionType,
    },
    {
      id: IdFilterEnum.conciliationAction,
      options: [
        {
          key: KeyStatusEnum.CONCILIATED_STATUS,
          label: FilterLabelEnum.CONCILIATED,
          selected: false,
        },
        {
          key: KeyStatusEnum.OMITTED_STATUS,
          label: FilterLabelEnum.OMITTED,
          selected: false,
        },
        {
          key: KeyStatusEnum.REFUSED_REFUND_STATUS,
          label: FilterLabelEnum.REFUSED_REFUND,
          selected: false,
        },
        {
          key: KeyStatusEnum.PENDING_REFUND_STATUS,
          label: FilterLabelEnum.PENDING_REFUND,
          selected: false,
        },
        {
          key: KeyStatusEnum.APPROVED_REFUND_STATUS,
          label: FilterLabelEnum.APPROVED_REFUND,
          selected: false,
        },
      ],
      title: FilterTitleEnum.conciliationAction,
    },
    {
      id: IdFilterEnum.currencyCode,
      options: CurrencyCountry[filtersState.headerFilter.country],
      title: FilterTitleEnum.currencyCode,
    },
  ];
  const [filters, setFilters] = React.useState<IOptionFilter[]>(
    cloneDeep(DefaultFilter)
  );
  const [rangeAmount, setRangeAmount] =
    React.useState<IRangeAmount>(defaultRangeAmount);
  const [counterFilters, setCounterFilters] = React.useState<number>(0);
  const [filterButtonsDisabled, setFilterButtonsDisabled] =
    React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (currentTarget: HTMLButtonElement | null) => {
    setAnchorEl(currentTarget);
  };

  const getCounterFilter = (filters: IOptionFilter[]): number => {
    let options: { key: string; label: string; selected: boolean }[] = [];

    filters.forEach((filter: IOptionFilter) => {
      options = options.concat(filter.options);
    });

    return options.filter(
      (option: { key: string; label: string; selected: boolean }) =>
        option.selected
    ).length;
  };

  const getCounterRange = (rangeAmount: IRangeAmount) => {
    if (
      rangeAmount.min !== "" ||
      rangeAmount.max !== "" ||
      rangeAmount.eq !== ""
    )
      return 1;

    return 0;
  };

  const handleChangeRangeAmount = (rangeAmount: IRangeAmount) => {
    setRangeAmount(rangeAmount);
  };

  const handleClose = (isSearchFilter: boolean) => {
    if (isSearchFilter) {
      setCounterFilters(
        getCounterFilter(filters) + getCounterRange(rangeAmount)
      );
    } else if (!isSearchFilter && isEmpty(get(search, "filter.rangeAmount")))
      handleChangeRangeAmount({ eq: "", max: "", min: "", type: "min" });
    setAnchorEl(null);
  };

  const handleSearchFilter = (
    filters: { filter: object; rangeAmount?: object },
    filtersChips: IOptionFilter[],
    amount: IRangeAmount
  ) => {
    setSearch({
      ...search,
      filter: { ...filters.filter },
      offset: 0,
    });
    dispatch(setGeneralFilter({ ...filters.filter }));
    setFilters(filtersChips);
    setRangeAmount(amount);
  };

  const handleGetFilters = (
    optionsChip: { [key: string]: IChipSelect },
    rangeAmount: IRangeAmount
  ) => {
    setRangeAmount(rangeAmount);
    const chips_selected: IChipSelect[] = Object.values(optionsChip).filter(
      (chip: IChipSelect) => chip.selected
    );

    let export_filter_selected: {
      filter: object;
      rangeAmount?: { min?: number; max?: number; eq?: number };
    } = { filter: {} };

    filters.forEach((filter: IOptionFilter) => {
      const filter_parent: IChipSelect[] = chips_selected.filter(
        (f: IChipSelect) => f.filter === filter.id
      );
      const filter_keys: string[] = filter_parent.map(
        (f: IChipSelect) => f.key
      );

      filter.options.forEach(
        (option: { key: string; label: string; selected: boolean }) => {
          option.selected = filter_keys.includes(option.key);
        }
      );

      filter_keys.forEach((opt: string, index: number) => {
        if (DeclinedStatus.includes(opt)) {
          filter_keys[index] = "declined";
        } else if (NotExistStatus.includes(opt)) {
          filter_keys[index] = "";
        }
      });
      if (filter_keys.length > 0)
        export_filter_selected.filter[filter.id] = filter_keys.join("|");
    });

    if (
      rangeAmount.min !== "" ||
      rangeAmount.max !== "" ||
      rangeAmount.eq !== ""
    ) {
      export_filter_selected.filter["rangeAmount"] = rangeAmount;
    }
    handleClose(true);
    handleSearchFilter(export_filter_selected, filters, rangeAmount);
  };

  const {
    actions: { getConciliationList },
  } = useKshRedux();

  const handleConciliationData = (): void => {
    const request: GetConciliationTransactionsRequest = Object.assign(
      {},
      search
    );

    getConciliationList({ ...request, source: props.currentTab });
  };

  const handleRestoreFilter = () => {
    setFilters(cloneDeep(DefaultFilter));
    setRangeAmount({ eq: "", max: "", min: "", type: "min" });
    setSearch(defaultRequest);
    setCounterFilters(0);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      !isEqual(search.country, FilterTitlesEnum.country) &&
      !isEqual(search.paymentMethod, FilterTitlesEnum.paymentMethod) &&
      !isEqual(search.processorName, FilterTitlesEnum.processor)
    )
      handleConciliationData();
  }, [search, filters]);

  useEffect(() => {
    handleRestoreFilter();
  }, [filtersState.headerFilter.country]);

  useEffect(() => {
    const requestState: GetConciliationTransactionsRequest = {
      ...search,
      country: filtersState.headerFilter.country,
      from: format(new Date(filtersState.date.from), DATE_FORMAT_ELASTIC),
      limit: filtersState.pagination.limit,
      offset: calculateOffset(),
      paymentMethod: filtersState.headerFilter.paymentMethod,
      processorName: filtersState.headerFilter.processorName,
      sort: {
        field: "created",
        order: "asc",
      },
      to: format(new Date(filtersState.date.to), DATE_FORMAT_ELASTIC),
    };

    delete requestState.filter!.merchantId;
    delete requestState.generalField;

    if (filtersState.search.text.length > 3) {
      const isNumber = /^\d+$/.test(filtersState.search.text);

      if (!isEmpty(filtersState.search.text))
        isNumber
          ? set(requestState, "filter.merchantId", filtersState.search.text)
          : set(requestState, "generalField", filtersState.search.text);

      setSearch(requestState);
    } else if (filtersState.search.text.length === 0) {
      setSearch(requestState);
    }
  }, [
    filtersState.date,
    isEmpty(filtersState.search.text),
    filtersState.search.status,
    filtersState.pagination.page,
    filtersState.headerFilter.country,
  ]);

  return {
    anchorEl,
    counterFilters,
    filterButtonsDisabled,
    filters,
    handlers: {
      handleChangeRangeAmount,
      handleClick,
      handleClose,
      handleGetFilters,
      handleRestoreFilter,
      setFilterButtonsDisabled,
    },
    rangeAmount,
  };
};
