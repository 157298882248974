import { useFormContext, useWatch } from "react-hook-form";
import { IWebhookForm } from "../../../store/interfaces/webhook.interfaces";
import { useAppDispatch } from "../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import { WebhookEventsEnum } from "../enums";
import { findIndex } from "lodash";
import { setPreauthEnabled } from "../../../store/actions/webhook/webhook.actions";
import {
  EventList,
  ISelectedProduct,
  IUseWebhookEventsCheckList,
  PaymentUrlWithStatus,
} from "../interfaces";
import { WebhookEvent } from "../../../../types/webhook_create_request";
import { get } from "lodash";
import { PAYMENT_METHODS_EVENTS } from "../constants";
import { WebhookService } from "../../../shared/constants/enums/WebhookServiceEnum";
import { ServiceOptionEnum } from "../../PaymentMethodCheckList/enums";

export const useWebhookEventsCheckList = (): IUseWebhookEventsCheckList => {
  const [openModalPreauth, setOpenModalPreauth] = useState<boolean>(false);
  const {
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<IWebhookForm>();
  const options: string[] = useWatch({
    name: "options",
  }) as string[];

  const events: string[] = useWatch({
    name: "events",
  }) as string[];

  const [isFirst, setIsFirst] = useState(false);

  const isApprovedVoidChargeback = (event: WebhookEvent) => {
    if (
      event.service === ServiceOptionEnum.CHARGEBACK &&
      event.status === WebhookEventsEnum.approvedTransaction
    )
      return true;

    return false;
  };

  const isDeclinedVoidChargeback = (event: WebhookEvent) => {
    if (
      event.service === ServiceOptionEnum.CHARGEBACK &&
      event.status === WebhookEventsEnum.declinedTransaction
    )
      return true;

    return false;
  };

  const checkEventChargeBack = (): void => {
    let currentEvents = getValues("events");

    const updatedEvents: WebhookEvent[] = [];

    const hasChargebackApprovedTrx: boolean =
      currentEvents.findIndex((event) => isApprovedVoidChargeback(event)) > -1;

    const hasChargebackDeclinedTrx: boolean =
      currentEvents.findIndex((event) => isDeclinedVoidChargeback(event)) > -1;

    const hasChargeback: boolean =
      currentEvents.findIndex(
        (event) => event.status === WebhookEventsEnum.chargeback
      ) > -1;

    if (hasChargeback) {
      currentEvents = currentEvents.filter(
        (event) => event.status !== WebhookEventsEnum.chargeback
      );
      Object.entries(
        PAYMENT_METHODS_EVENTS[ServiceOptionEnum.CHARGEBACK]
      ).forEach(([status]) => {
        updatedEvents.push({
          service: ServiceOptionEnum.CHARGEBACK,
          status: status,
        });
      });
    }

    if (hasChargebackApprovedTrx) {
      currentEvents.splice(
        currentEvents.findIndex(
          (event) =>
            event.status == WebhookEventsEnum.approvedTransaction &&
            event.service == ServiceOptionEnum.CHARGEBACK
        ),
        1
      );

      updatedEvents.push({
        service: ServiceOptionEnum.CHARGEBACK_VOID,
        status: WebhookEventsEnum.approvedTransaction,
      });
    }

    if (hasChargebackDeclinedTrx) {
      currentEvents.splice(
        currentEvents.findIndex(
          (event) =>
            event.status == WebhookEventsEnum.declinedTransaction &&
            event.service == ServiceOptionEnum.CHARGEBACK
        ),
        1
      );
      updatedEvents.push({
        service: ServiceOptionEnum.CHARGEBACK_VOID,
        status: WebhookEventsEnum.declinedTransaction,
      });
    }

    setIsFirst(true);

    setValue("events", [...currentEvents, ...updatedEvents]);
  };

  const dispatch = useAppDispatch();

  const handleOpenModal = (open: boolean) => {
    setOpenModalPreauth(open);
  };

  const handleCloseModal = (): void => {
    const eventsWithOutPreauth = [...getValues("events")];

    const index = findIndex(eventsWithOutPreauth, {
      service: WebhookService.CASH,
      status: WebhookEventsEnum.cashPreathorization,
    });

    if (index > -1) {
      eventsWithOutPreauth.splice(index, 1);
      setValue("events", eventsWithOutPreauth);
      dispatch(setPreauthEnabled(false));
    }

    handleOpenModal(false);
  };

  const clearEventsExceptPreauth = (): void => {
    const emplyPaymnetUrl: PaymentUrlWithStatus[] = [];
    const events = getValues("events");
    const hasPreauth: boolean =
      events.findIndex(
        (event) => event.status === WebhookEventsEnum.cashPreathorization
      ) > -1;

    if (hasPreauth) {
      setValue("events", [
        {
          service: WebhookService.CASH,
          status: WebhookEventsEnum.cashPreathorization,
        },
      ]);
      setValue("options", ["cash"]);
      setValue("urls", ["test"]);
      setValue("paymentUrls", emplyPaymnetUrl);
    }

    dispatch(setPreauthEnabled(hasPreauth));
    handleOpenModal(false);
  };

  const findPreauthEvent = (open: boolean): void => {
    if (open) {
      handleOpenModal(true);
    } else {
      dispatch(setPreauthEnabled(false));
    }
  };

  useEffect(() => {
    clearErrors("events");

    if (events?.length > 0 && !isFirst) checkEventChargeBack();
  }, [events]);

  const handleStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: ISelectedProduct
  ): void => {
    const currEvents = getValues("events");

    const hasPreauthEvent: boolean = currEvents.some(
      ({ service, status }) =>
        service === WebhookService.CASH &&
        status === WebhookEventsEnum.cashPreathorization
    );

    const targetIsPreauth: boolean =
      product.service === WebhookService.CASH &&
      product.status === WebhookEventsEnum.cashPreathorization;

    if (event.target.checked) {
      findPreauthEvent(targetIsPreauth);
      const updated = hasPreauthEvent ? [product] : [...currEvents, product];

      setValue("events", updated);
    } else {
      findPreauthEvent(false);
      setValue("events", [
        ...currEvents.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(product)
        ),
      ]);
    }
  };

  const handleCheck = (product: ISelectedProduct): boolean => {
    if (!events) return false;

    return events.some((event) => {
      if (get(event, "status", "") === WebhookEventsEnum.cashPreathorization) {
        dispatch(setPreauthEnabled(true));
      }

      return JSON.stringify(event) === JSON.stringify(product);
    });
  };

  const hasEvents = (record: object): boolean => {
    const keys = Object.keys(record);
    const totalItems: number = keys.length + options.length;

    return new Set([...keys, ...options]).size < totalItems;
  };

  const handleAreAllEventsSelected = (): boolean => {
    const { events = [], options: currentOptions = [] } = getValues();
    let totalEventCount: number = 0;
    const totalEvents: EventList[] = currentOptions.map(
      (option: string) => PAYMENT_METHODS_EVENTS[option]
    );

    const countPreauth: number = currentOptions.includes("cash") ? 1 : 0;

    totalEvents.forEach(
      (eList: EventList) => (totalEventCount += Object.values(eList).length)
    );

    return events.length === totalEventCount - countPreauth;
  };

  const handleSelectAllEvents = (): void => {
    const areAllSelected: boolean = handleAreAllEventsSelected();

    if (areAllSelected) setValue("events", []);
    else {
      const updatedEvents: WebhookEvent[] = [];

      options.forEach((option: string) => {
        Object.entries(PAYMENT_METHODS_EVENTS[option]).forEach(([status]) => {
          if (
            option === WebhookService.CASH &&
            status === WebhookEventsEnum.cashPreathorization
          ) {
            return;
          }
          updatedEvents.push({ service: option, status: status });
        });
      });

      setValue("events", updatedEvents);
    }
  };

  return {
    errors,
    handler: {
      handleAreAllEventsSelected,
      handleCheck,
      handleSelectAllEvents,
      handleStatus,
      hasEvents,
    },
    modalPreath: {
      clearEventsExceptPreauth,
      handleCloseModal,
      handleOpenModal,
      openModalPreauth,
    },
    options,
  };
};
