import React, { FC } from "react";
import { Box } from "@mui/material";
import { FilterBar, ModalSimple } from "@kushki/connect-ui";
import { RangeDatePickerProps } from "@kushki/connect-ui/dist/Components/Molecules/Form/RangeDatePicker/RangeDatePicker.interfaces";
import { FilterSideBarProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterSideBar/FilterSideBar.interfaces";
import { IFilterDetailBarProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { useHeadFiltersDashboardState } from "./state/useHeadFiltersDashboardState";
import { useDownloadButtonComponent } from "./state/useDownloadButtonComponent";
import { ITextFieldSimpleSearch } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldSimpleSearch/TextFieldSimpleSearch.interfaces";
import { IHeadFiltersDashboardComponent } from "./HeadFiltersDashboardComponent.interfaces";

const HeadFiltersDashboardComponent: FC<IHeadFiltersDashboardComponent> = ({
  setSelectedPage,
}: IHeadFiltersDashboardComponent) => {
  const { filterProps } = useHeadFiltersDashboardState(setSelectedPage);
  const { additionalActions, modalData } = useDownloadButtonComponent();

  return (
    <Box>
      <FilterBar
        additionalActions={additionalActions}
        filterDetailBar={filterProps.filterDetailBar as IFilterDetailBarProps}
        filterSideBar={filterProps.filterSideBar as FilterSideBarProps}
        rangeDatePicker={filterProps.rangeDatePicker as RangeDatePickerProps}
        textFieldSimpleSearch={
          filterProps.textFieldSimpleSearch as ITextFieldSimpleSearch
        }
      />
      {<ModalSimple {...modalData} />}
    </Box>
  );
};

export default HeadFiltersDashboardComponent;
