import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import {
  CatalogConfigTableBranch,
  headCells,
} from "./CatalogConfigTableBranch";
import {
  CatalogConfigTableCustomer,
  headCellsCustomer,
} from "./CatalogConfigTableCustomer";
import { IConfigCells } from "../../components/Table/TableNodes/constants";
import {
  CatalogConfigTableBranchesOwner,
  headCellsBranchesOwner,
} from "./CatalogConfigTableBranchesOwner";

export enum NodeTypeEnum {
  CUSTOMER = "CUSTOMER",
  BRANCH = "BRANCH",
  BRANCH_OWNER = "BRANCH_OWNER",
}

export const CATALOG_HEADS_NODE: Record<NodeTypeEnum, HeadCell[]> = {
  [NodeTypeEnum.CUSTOMER]: headCellsCustomer,
  [NodeTypeEnum.BRANCH]: headCells,
  [NodeTypeEnum.BRANCH_OWNER]: headCellsBranchesOwner,
};

export const CATALOG_ROWS_NODE: Record<NodeTypeEnum, IConfigCells[]> = {
  [NodeTypeEnum.CUSTOMER]: CatalogConfigTableCustomer,
  [NodeTypeEnum.BRANCH]: CatalogConfigTableBranch,
  [NodeTypeEnum.BRANCH_OWNER]: CatalogConfigTableBranchesOwner,
};
