import React, { Fragment } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router";
import { Box, Chip, Link, Paper, Tooltip, Typography } from "@material-ui/core";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { Edit } from "react-feather";
import { SwitchTable } from "../../commons/switchTable/SwitchTable";
import { Variants } from "../../../../types/deferred_response";
import { DeferredMonths } from "../../DeferredMonths/DeferredMonths";
import { DeferredTypeMonthEnum } from "../../../shared/infrastructure/constants/DeferredTypeMonthEnum";
import { LabelChip } from "../../commons/ChipNotificationStatus/LabelChip";
import { ChipTypeEnum } from "../../../shared/infrastructure/constants/ChipTypeEnum";
import { Variant } from "../../../../types/deferred_options";
import { defaultTo, isArray } from "lodash";
import { TDeferred } from "../../../shared/infrastructure/interfaces/TDeferred";
import {
  CentralCountries,
  getHeaders,
} from "../../../shared/infrastructure/constants/DeferredTableConstants";
import { StatusDialogTexts } from "../../../shared/infrastructure/constants/CreateDeferredConstants";
import { StatusEnum } from "../../../shared/infrastructure/constants/statusEnum";
import {
  CARDS_BY_COUNTRIES_DEFAULT,
  CARDS_BY_ENTITY,
  DEFAULT_CARDS,
} from "../../../shared/infrastructure/constants/CardsConstants";
import { BankEntityEnum } from "../../../shared/infrastructure/constants/BankEntityEnum";
import CardsComponent, {
  ICard,
} from "../../commons/CardsComponent/CardsComponent";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";

export interface TableDeferredData {
  entity: string;
  deferred: TDeferred[];
  hideMonthOfGrace: boolean;
}

export interface ITableProps {
  country: string;
  dataRows: TableDeferredData[] | undefined;
  dataHeaders: string[];
  getCodeToTextDeferred: (deferred: TDeferred) => string;
  handleBankModal: (banks: string[]) => void;
  handleUpdateStatusModal: (id: string, status: StatusEnum) => void;
  changeDialogText: (text: StatusDialogTexts) => void;
  title: string;
}
export const StyledTableCell = withStyles(
  createStyles({
    body: {
      "font-weight": 500,
      fontSize: 14,
    },
    head: {
      backgroundColor: "#F7FAFC",
      color: "#6D7781",
      fontWeight: 400,
      padding: 10,
    },
  })
)(TableCell);

const useStyles = makeStyles(() =>
  createStyles({
    cardTypeHeader: {
      backgroundColor: "#F7FAFC",
      color: "#6D7781",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "center",
      width: "27%",
    },
    cardTypeImg: {
      backgroundColor: "#FFFFFF",
    },
    cell: {
      borderBottom: "none",
      borderTop: "1px solid #E2E8F0",
      padding: "11px 3px 11px 16px",
    },
    chipBank: {
      backgroundColor: "#F7FAFC",
      border: 0,
      borderRadius: "4px",
      color: "#293036",
    },
    editBtnBox: {
      backgroundColor: "#F7FAFC",
      borderRadius: 5,
      marginRight: "15px",
      padding: "4px 0 1px 0",
    },
    editBtnCell: {
      borderBottom: "none",
      margin: 3,
      width: "24%",
    },
    rowWidth: {
      width: "22.5%",
    },
    TableContainer: {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      marginBottom: 16,
      marginTop: 8,
    },
    tableHeader: {
      backgroundColor: "#F7FAFC",
      color: "#6D7781",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "center",
      width: 170,
    },
  })
);

export const TableDeferred = (props: ITableProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const getCardLogos = (
    entity: BankEntityEnum,
    country: CountriesEnum
  ): ICard[] =>
    defaultTo(
      CARDS_BY_ENTITY.get(entity),
      defaultTo(CARDS_BY_COUNTRIES_DEFAULT.get(country), DEFAULT_CARDS)
    );

  return isArray(props.dataRows) && props.dataRows.length > 0 ? (
    <>
      {props.dataRows!.map((deferredItem, index) => (
        <TableContainer
          key={`0_${index}`}
          component={Paper}
          className={classes.TableContainer}
        >
          <Table aria-label="merchant-configuration-table">
            <TableBody>
              <TableRow>
                {!CentralCountries.includes(props.country as CountriesEnum) && (
                  <>
                    <StyledTableCell className={classes.tableHeader}>
                      {props.title}
                    </StyledTableCell>
                    <TableCell align={"center"} width={170}>
                      {deferredItem.entity}
                    </TableCell>
                  </>
                )}
                <TableCell className={classes.cardTypeHeader} variant="head">
                  Tarjetas a las que aplica la configuración
                </TableCell>
                <TableCell
                  key={"country_0_0"}
                  align={"center"}
                  className={classes.cardTypeImg}
                >
                  <CardsComponent
                    cards={getCardLogos(
                      deferredItem.entity as BankEntityEnum,
                      props.country as CountriesEnum
                    )}
                    height={30}
                    justifyContent={
                      CentralCountries.includes(props.country as CountriesEnum)
                        ? "center"
                        : "flex-end"
                    }
                    width={50}
                    marginLeft={1}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table aria-label="merchant-configuration-table">
            <TableHead>
              <TableRow>
                {getHeaders(
                  deferredItem.hideMonthOfGrace,
                  props.country as CountriesEnum
                ).map((header, index: number) => (
                  <StyledTableCell
                    key={`${header.label}_${index}`}
                    className={classes.rowWidth}
                    align={header.align}
                  >
                    {header.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key={`table_${index}`} className={classes.cardTypeImg}>
              {deferredItem.deferred.map((deferred) => (
                <Fragment key={`0_${index}`}>
                  <TableRow>
                    <TableCell key={`cell_${index}`} className={classes.cell}>
                      {props.getCodeToTextDeferred(deferred)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <DeferredMonths
                        merchantMonths={
                          CentralCountries.includes(
                            props.country as CountriesEnum
                          )
                            ? deferred.months
                            : deferred.merchantMonths
                        }
                        months={
                          CentralCountries.includes(
                            props.country as CountriesEnum
                          )
                            ? deferred.merchantMonths
                            : deferred.months
                        }
                        monthsType={DeferredTypeMonthEnum.MONTHS}
                      />
                    </TableCell>
                    {!deferredItem.hideMonthOfGrace && (
                      <TableCell
                        className={classes.cell}
                        id={"months-of-grace"}
                      >
                        {!CentralCountries.includes(
                          props.country as CountriesEnum
                        ) && (
                          <DeferredMonths
                            merchantMonths={deferred.merchantMonthsOfGrace}
                            months={deferred.monthsOfGrace}
                            monthsType={DeferredTypeMonthEnum.MONTHS_OF_GRACE}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      align={deferredItem.hideMonthOfGrace ? "left" : "center"}
                      className={classes.cell}
                      colSpan={deferredItem.hideMonthOfGrace ? 2 : 1}
                      id={"banks"}
                    >
                      {!CentralCountries.includes(
                        props.country as CountriesEnum
                      ) &&
                        (defaultTo(deferred.bank, []).length === 1 &&
                        deferredItem.hideMonthOfGrace ? (
                          <Chip
                            label={deferred.bank![0]}
                            className={classes.chipBank}
                          />
                        ) : (
                          <Typography>
                            <Link
                              component="button"
                              onClick={() => {
                                props.handleBankModal(deferred.bank!);
                              }}
                            >
                              Ver bancos
                            </Link>
                          </Typography>
                        ))}
                    </TableCell>
                    <TableCell align="center" className={classes.editBtnCell}>
                      <SwitchTable
                        deferredItem={deferred}
                        handleModal={props.handleUpdateStatusModal}
                        changeDialogText={props.changeDialogText}
                      />
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Box className={classes.editBtnBox}>
                        <Tooltip title="Editar" aria-label="add">
                          <Edit
                            onClick={() => {
                              history.push(routes.CONFIG_DEFERRED, {
                                ...deferred,
                                type: props.getCodeToTextDeferred(deferred),
                              });
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {isArray(deferred.variants) &&
                    (deferred.variants as Variant[]).map(
                      (variant: Variants, index: number) => (
                        <TableRow
                          key={`table_row_${index}`}
                          id={`variant${index}`}
                        >
                          <TableCell
                            key={`table_cell_${index}`}
                            align={"center"}
                            className={classes.cell}
                          >
                            <LabelChip
                              key={`label_chip_${index}`}
                              type={ChipTypeEnum.VARIANT}
                              text={`VARIANTE ${index + 1}`}
                            />
                          </TableCell>
                          <TableCell
                            key={`table_cell_2_${index}`}
                            className={classes.cell}
                          >
                            <DeferredMonths
                              merchantMonths={deferred.merchantMonths}
                              months={variant.months}
                              monthsType={DeferredTypeMonthEnum.MONTHS}
                            />
                          </TableCell>
                          <TableCell
                            key={`table_cell_3_${index}`}
                            className={classes.cell}
                          >
                            <DeferredMonths
                              merchantMonths={deferred.merchantMonthsOfGrace}
                              months={variant.monthsOfGrace}
                              monthsType={DeferredTypeMonthEnum.MONTHS_OF_GRACE}
                            />
                          </TableCell>
                          <TableCell
                            key={`table_cell_4_${index}`}
                            align="center"
                            className={classes.cell}
                          >
                            {variant.bank!.length > 1 ? (
                              <Typography key={`typography_${index}`}>
                                <Link
                                  key={`label_1_${index}`}
                                  component="button"
                                  onClick={() => {
                                    props.handleBankModal(variant.bank!);
                                  }}
                                >
                                  Ver bancos
                                </Link>
                              </Typography>
                            ) : (
                              <LabelChip
                                key={`label_2_${index}`}
                                type={ChipTypeEnum.BANK}
                                text={variant.bank![0]}
                              />
                            )}
                          </TableCell>
                          <TableCell className={classes.cell} />
                          <TableCell className={classes.cell} />
                        </TableRow>
                      )
                    )}
                  <TableRow key={`table_row_margin_${index}`}>
                    <TableCell
                      colSpan={props.dataHeaders.length}
                      style={{
                        backgroundColor: "#F0F4F9",
                        border: "none",
                        padding: 0,
                        paddingTop: "8px",
                      }}
                    />
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  ) : (
    <TableContainer component={Paper} className={classes.TableContainer}>
      <Table aria-label="merchant-configuration-table">
        <TableBody>
          <TableRow>
            <StyledTableCell className={classes.tableHeader}>
              {props.title}
            </StyledTableCell>
            <TableCell align={"center"}>Procesador de pago</TableCell>
            <TableCell className={classes.cardTypeHeader} variant="head">
              Tarjetas a las que aplica la configuración
            </TableCell>
            <TableCell key={"country_0_0"} className={classes.cardTypeImg}>
              <CardsComponent
                cards={DEFAULT_CARDS}
                height={30}
                justifyContent={"flex-end"}
                width={50}
                marginLeft={1}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table aria-label="merchant-configuration-table">
        <TableHead>
          <TableRow>
            {props.dataHeaders.map((title: string, index: number) => (
              <StyledTableCell
                key={`${title}_${index}`}
                className={classes.rowWidth}
              >
                {title}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};
