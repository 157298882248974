import { CountriesEnum } from "../enums/countries";
import { CurrencyEnum } from "../enums/currencies";

export const CURRENCY_COUNTRY: Map<string, string> = new Map()
  .set(`${CountriesEnum.COLOMBIA}`, CurrencyEnum.COP)
  .set(`${CountriesEnum.ECUADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PERU}`, CurrencyEnum.PEN)
  .set(`${CountriesEnum.CHILE}`, CurrencyEnum.CLP)
  .set(`${CountriesEnum.MEXICO}`, CurrencyEnum.MXN)
  .set(`${CountriesEnum.BRAZIL}`, CurrencyEnum.BRL)
  .set(`${CountriesEnum.EL_SALVADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PANAMA}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.COSTA_RICA}`, CurrencyEnum.CRC);

export const currencyFormat = (number: number, country: string): string => {
  return Intl.NumberFormat("de-DE", {
    currency: CURRENCY_COUNTRY.get(country),
    currencyDisplay: "code",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: "currency",
  }).format(number);
};
