/**
 *
 */
export enum TransactionTypeEnum {
  DEFFERRED = "DEFFERED",
  DEFERRED = "DEFERRED",
  CHARGEBACK = "CHARGEBACK",
  SALE = "SALE",
  VOID = "VOID",
  REFUND = "REFUND",
  CAPTURE = "CAPTURE",
  CREDIT = "CREDIT",
  PAYOUT = "PAYOUT",
  DEBIT = "DEBIT",
  PREAUTHORIZATION = "PREAUTHORIZATION",
  REFUND_TOTAL = "REFUND TOTAL",
  REFUND_PARTIAL = "REFUND PARTIAL",
  VOID_PARTIAL = "VOID PARTIAL",
  REVERSE = "REVERSE",
}

export enum transactionTypeEnum {
  refund = "refund",
}

export enum TransactionTypeLabelEnum {
  APPROVAL_TRANSFER = "Transacción Aprobada",
  DEFERRED = "Diferido",
  CHARGEBACK = "Contracargo",
  SALE = "Venta",
  VOID = "Devolución",
  REFUND = "Reembolso",
  CAPTURE = "Captura",
  CREDIT = "Crédito de Balance",
  PAYOUT = "Pagos",
  DEBIT = "Débito de Balance",
  PREAUTHORIZATION = "Preautorización",
  REFUND_TOTAL = "Reembolso total",
  REFUND_PARTIAL = "Reembolso parcial",
  VOID_PARTIAL = "Devolución parcial",
  REVERSE = "Reverso",
}

export const CATALOG_TRANSACTION_TYPES: Record<
  TransactionTypeEnum,
  TransactionTypeLabelEnum
> = {
  [TransactionTypeEnum.PAYOUT]: TransactionTypeLabelEnum.PAYOUT,
  [TransactionTypeEnum.SALE]: TransactionTypeLabelEnum.SALE,
  [TransactionTypeEnum.CREDIT]: TransactionTypeLabelEnum.CREDIT,
  [TransactionTypeEnum.DEBIT]: TransactionTypeLabelEnum.DEBIT,
  [TransactionTypeEnum.REFUND]: TransactionTypeLabelEnum.REFUND,
  [TransactionTypeEnum.CAPTURE]: TransactionTypeLabelEnum.CAPTURE,
  [TransactionTypeEnum.CHARGEBACK]: TransactionTypeLabelEnum.CHARGEBACK,
  [TransactionTypeEnum.VOID]: TransactionTypeLabelEnum.VOID,
  [TransactionTypeEnum.VOID_PARTIAL]: TransactionTypeLabelEnum.VOID_PARTIAL,
  [TransactionTypeEnum.REFUND_PARTIAL]: TransactionTypeLabelEnum.REFUND_PARTIAL,
  [TransactionTypeEnum.REFUND_TOTAL]: TransactionTypeLabelEnum.REFUND_TOTAL,
  [TransactionTypeEnum.PREAUTHORIZATION]:
    TransactionTypeLabelEnum.PREAUTHORIZATION,
  [TransactionTypeEnum.DEFFERRED]: TransactionTypeLabelEnum.DEFERRED,
  [TransactionTypeEnum.DEFERRED]: TransactionTypeLabelEnum.DEFERRED,
  [TransactionTypeEnum.REVERSE]: TransactionTypeLabelEnum.REVERSE,
};
