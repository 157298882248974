import React from "react";
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { StepsEnum } from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { useSelector } from "react-redux";
import { IAppState, StatusStepsState } from "../../../store/reducer";
import { useHistory } from "react-router-dom";
import { MerchantCreateUpdateResponse } from "../../../../types/merchant_create_update_response";
import { get } from "lodash";
import Step from "@material-ui/core/Step";
import EditIcon from "../../../assets/image/edit.svg";
import CopyIcon from "../../../assets/image/copy.svg";
import { clipboard } from "../../../shared/clipboard";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      margin: theme.spacing(1, 1),
      paddingTop: 35,
      paddingBottom: 35,
    },
    fontWeight: {
      fontWeight: "lighter",
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    copyIcon: {
      marginLeft: "4px",
      cursor: "pointer",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    fixGrid: {
      flexBasis: "inherit",
    },
  })
);

export const BasicDataPendingSection: React.FC<
  Partial<IBasicDataPendingSection>
> = (_: Partial<IBasicDataPendingSection>) => {
  const classes = useStyles();
  const history = useHistory();
  const stateProps: Partial<IBasicDataPendingSection> = useSelector(
    (state: IAppState) => ({
      publicMerchantId: state.merchantAffiliation?.publicMerchantId,
      merchantData: get(state, "merchantAffiliation", undefined),
      status: state.steps?.find(
        (step: StatusStepsState) => step.name === StepsEnum.stepBasicData
      )?.status,
      isLoading: state.loading,
    })
  );
  const basicDataURL: string = `/billing-merchant/invoice?publicMerchantId=${stateProps.publicMerchantId}&hideSideBar=true`;

  const redirectBasicDataPage = () => {
    if (!stateProps.publicMerchantId) return;

    history.push(basicDataURL);
  };

  const webSite = get(
    stateProps,
    "merchantData.webSite",
    get(stateProps.merchantData, "merchantLead.merchantInfo.webSite", "")
  );

  if (stateProps.isLoading)
    return <Skeleton variant="rect" width="100%" height={150} />;

  return stateProps.status === "pending" ? (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} className={classes.fixGrid}>
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          className={classes.fontWeight}
        >
          Aún no has agregado <strong>datos del comercio</strong>.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Button
          id="btn-redirect"
          variant="contained"
          color="primary"
          onClick={redirectBasicDataPage}
        >
          Agregar datos
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item container xs={12} spacing={1} direction={"column"}>
        <Grid container item spacing={6}>
          <Grid item xs={2}>
            <Typography variant="subtitle1" color="initial">
              Datos del comercio
            </Typography>
          </Grid>
          <Grid item xs={"auto"} style={{ paddingLeft: "14px" }}>
            <Step className={classes.stepIcon}>
              <IconButton
                onClick={redirectBasicDataPage}
                id="copy_btn"
                size="small"
                aria-label="copy"
              >
                <img width={26} src={EditIcon} alt={EditIcon.toString()} />
              </IconButton>
            </Step>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Nombre:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {get(stateProps.merchantData, "name", "")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Razón social:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {get(
                stateProps.merchantData,
                "socialReason",
                get(
                  stateProps.merchantData,
                  "merchantLead.merchantInfo.socialReason",
                  ""
                )
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Tax ID:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {get(stateProps.merchantData, "taxId", "")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              URL:
            </Typography>
          </Grid>
          <Grid item xs={"auto"}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {webSite}
              <IconButton
                onClick={() => clipboard(webSite)}
                id="copy_btn"
                size="small"
                aria-label="copy"
              >
                <img
                  id={"copyIcon"}
                  alt={CopyIcon.toString()}
                  className={classes.copyIcon}
                  width={23}
                  src={CopyIcon}
                />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export type IBasicDataPendingSection = {
  publicMerchantId: string;
  merchantData: MerchantCreateUpdateResponse | undefined;
  status: StatusSteps;
  changeStepStatus: (step: string, status: StatusSteps) => void;
  isLoading: boolean;
};
