import { useEffect, useState } from "react";
import { DataTableFiltersProps } from "../../../components/Filters/DataTableFilters";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../../types/response_search_rules_by_filters";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  editAnalystUser,
  getAnalystUsers,
  getAuthenticationRulesDataTabs,
  setAuthenticationRulesRequestData,
  setReloadDashboard,
  setSnackbarRulesDashboardRequest,
} from "../../../store/actionCreators";
import { DefaultFilter } from "../../../shared/infrastructure/filter/DefaultFilter";
import { AuthenticationElastic } from "../../../../types/remote/authentication_elastic";
import { SnackbarAlertProps } from "../../../components/common/SnackBar/SnackBarAlert";
import { UserBalancerResponse } from "../../../../types/user_balancer_response";
import { cloneDeep, defaultTo, get } from "lodash";
import { SearchAuthenticationRulesRequest } from "../../../../types/search_authentication_rules_request";
import { ProcessorInfoResponse } from "../../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../../types/credential_info_response";
import { TRuleRequestDashboardProps } from "../RuleRequestDashboard";

export interface IRuleRequestState {
  state: IState;
  pagination: IPagination;
  filterProps: DataTableFiltersProps;
  data: {
    authenticationRulesRequestData: ResponseSearchRulesByFilters;
    trxSelected: AuthenticationRules[];
    isLoading: boolean;
    orderDataDashboard: (records: AuthenticationElastic[]) => void;
  };
  modalOver?: {
    openModalCard: boolean;
    handlerCloseModal: () => void;
    handlerSelectTrx: (
      row: AuthenticationRules | AuthenticationElastic | undefined,
      type: string
    ) => void;
    trx: AuthenticationRules | AuthenticationElastic;
    processorData: ProcessorInfoResponse[];
    credentialData: CredentialInfoResponse[];
  };
  checkboxSelection: {
    checkedHearder: boolean;
    checkedHearderSelects: boolean;
    handleSelectTrx: (
      _event: unknown,
      row: AuthenticationRules | undefined
    ) => void;
    validateExistTrxs: (row: AuthenticationRules | undefined) => boolean;
    handleTrxHeaderChange: (name: string, value: boolean) => void;
    handleSelectTrxHeaderChange: () => void;
  };
  openAnalystAssignmentModal: boolean;
  handleOpenAnalystAssignmentModal: (
    historicRulesRequestData?: AuthenticationRules[]
  ) => void;
  handleCloseAnalystAssignmentModal: () => void;
  handleAcceptAnalystAssignmentModal: (
    analystSelected?: UserBalancerResponse
  ) => void;
  openAnalystAssignmentLoader: boolean;
  handleCloseAnalystAssignmentLoader: () => void;
  notification: SnackbarAlertProps;
}
interface IPagination {
  page: number;
  pageSize: number;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
}

export interface IState {}
export const useRuleRequestDashboardState = (
  props: TRuleRequestDashboardProps
): IRuleRequestState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const [trxSelected, setTrxSelected] = useState<AuthenticationRules[]>([]);
  const [checkedHearder, setCheckedHearder] = useState<boolean>(false);
  const [checkedHearderSelects, setCheckedHearderSelects] =
    useState<boolean>(false);

  const [openAnalystAssignmentModal, setOpenAnalystAssignmentModal] =
    useState(false);
  const [openAnalystAssignmentSnack, setOpenAnalystAssignmentSnack] =
    useState(false);
  const [openAnalystAssignmentLoader, setOpenAnalystAssignmentLoader] =
    useState(false);
  const [openModalCard, setOpenModalCard] = useState<boolean>(false);
  const [trx, SetTrx] = useState<AuthenticationRules | AuthenticationElastic>(
    {}
  );
  const [historicRules, setHistoricRules] = useState<
    AuthenticationRules[] | undefined
  >(undefined);
  const dispatch = useDispatch();
  const executePetition = (
    params: Partial<SearchAuthenticationRulesRequest>,
    tab: number
  ) => {
    dispatch(
      getAuthenticationRulesDataTabs(
        tab,
        params as SearchAuthenticationRulesRequest
      )
    );
  };
  const {
    pagination: { page, pageSize, handleChangePage, handleChangePageSize },
    filterData: {
      queryInput,
      handleInputChange,
      handleSearchBar,
      range,
      handleSetDate,
      filterChips,
      handleSetFilters,
      counter,
    },
    stateUtil,
  } = props.stateUtilTab;

  const [state] = useState<IState>({});

  const updateAuthenticationRulesRequestData = (
    data: ResponseSearchRulesByFilters
  ) => {
    dispatch(setAuthenticationRulesRequestData(data));
  };

  const handlerSelectTrx = (
    row: AuthenticationRules | AuthenticationElastic | undefined,
    type: string
  ) => {
    if (row !== undefined && type !== "checkbox") {
      setOpenModalCard(true);
      props.getProcessorData(row.public_merchant_id!);
      props.getCredentialData(row.public_merchant_id!);
      SetTrx(row);
    }
  };
  const handlerCloseModal = () => setOpenModalCard(false);
  const orderDataDashboard = (records: AuthenticationElastic[]) => {
    updateAuthenticationRulesRequestData({
      records: [...records],
      total: stateStore.authenticationRulesRequestData.total,
    });
  };
  const handleOpenAnalystAssignmentModal = (
    historicRulesRequestData?: AuthenticationRules[]
  ) => {
    setOpenAnalystAssignmentModal(true);
    dispatch(getAnalystUsers({ balanceProfile: "risk" }, true));
    setHistoricRules(historicRulesRequestData);
  };

  const handleCloseAnalystAssignmentModal = () => {
    setOpenAnalystAssignmentModal(false);
  };

  const handleCloseAnalystAssignmentLoader = () => {
    setOpenAnalystAssignmentLoader(false);
    setOpenAnalystAssignmentSnack(true);
  };

  const handleAcceptAnalystAssignmentModal = (
    analystSelected?: UserBalancerResponse
  ) => {
    setOpenAnalystAssignmentModal(false);
    const userAlias: string = `${get(analystSelected, "name")} ${get(
      analystSelected,
      "familyName"
    )}`;
    dispatch(
      editAnalystUser({
        authenticationRulesIds: defaultTo(historicRules, []).map(
          (historicRule) => get(historicRule, "id")!
        ),
        riskAnalystUserAlias: userAlias,
        riskAnalystUserName: get(analystSelected!, "userName"),
      })
    );
  };

  const handleCloseNotification = () => {
    dispatch(setSnackbarRulesDashboardRequest(false));
  };
  useEffect(() => {
    if (stateStore.isReloadDashboardRuleRequest == "reload") {
      setTrxSelected([]);
      executePetition(stateUtil, 1);
      dispatch(setReloadDashboard(""));
    }
  }, [stateStore.isReloadDashboardRuleRequest]);

  const handleSelectTrxHeaderChange = (): void => {
    setTrxSelected([]);
    setCheckedHearderSelects(false);
  };

  const handleTrxHeaderChange = (_: string, value: boolean): void => {
    let arrayTrx: AuthenticationRules[] = [...trxSelected];
    let indexToRemove: number;

    const arrayTrxPrincipal: AuthenticationRules[] = get(
      stateStore.authenticationRulesRequestData,
      "records",
      []
    );

    if (value) {
      arrayTrxPrincipal.forEach((item: AuthenticationRules) => {
        if (arrayTrx.findIndex((i) => i.id === item.id) == -1)
          arrayTrx.push(item);
      });

      setCheckedHearderSelects(true);
      setTrxSelected(arrayTrx);
    } else {
      arrayTrxPrincipal.forEach((item: AuthenticationRules) => {
        indexToRemove = arrayTrx.findIndex((i) => i.id === item.id);
        if (indexToRemove > -1) arrayTrx.splice(indexToRemove, 1);
      });

      setTrxSelected(arrayTrx);
    }
    setCheckedHearder(value);
  };

  const validateExistSelect = (
    arrayTrx: AuthenticationRules[],
    item: AuthenticationRules
  ): boolean => {
    const tempArrayTrx = arrayTrx.filter(
      (i: AuthenticationRules) => i.id === get(item, "id", 0)
    );
    return get(tempArrayTrx, "length", 0) > 0 ? true : false;
  };

  const handleSelectTrx = (
    _event: any,
    row: AuthenticationRules | undefined
  ): void => {
    let arrayTrx: AuthenticationRules[] = [...trxSelected];
    if (_event.target.checked && row != undefined) {
      arrayTrx.push(row);
      setTrxSelected(arrayTrx);
      setCheckedHearderSelects(true);

      return;
    } else {
      arrayTrx = arrayTrx.filter(
        (i: AuthenticationRules) => i.id !== get(row, "id", 0)
      );
      setTrxSelected(arrayTrx);
    }
  };

  const validateExistTrxs = (row: AuthenticationRules | undefined): boolean => {
    let arrayTrx: AuthenticationRules[] = [...trxSelected];
    let check: boolean = false;

    if (arrayTrx.length != 0)
      arrayTrx.forEach((a: AuthenticationRules) => {
        if (get(row, "id", 0) != get(a, "id", 0)) return check;

        return (check = true);
      });

    return check;
  };

  useEffect(() => {
    const arrayTrxPrincipal: AuthenticationRules[] = get(
      stateStore.authenticationRulesRequestData,
      "records",
      []
    );
    if (
      arrayTrxPrincipal.every((item) => validateExistSelect(trxSelected, item))
    )
      setCheckedHearder(true);
    else setCheckedHearder(false);
  }, [trxSelected, stateStore.authenticationRulesRequestData]);

  return {
    state,
    pagination: {
      page,
      pageSize,
      handleChangePage,
      handleChangePageSize,
    },
    filterProps: {
      handleSetFilters,
      handleInputChange,
      handleSearchBar,
      handleSetDate,
      filters: cloneDeep(DefaultFilter),
      filterChips,
      range,
      counter,
      optionTabValue: 1,
      query: queryInput,
    },
    data: {
      authenticationRulesRequestData: stateStore.authenticationRulesRequestData,
      trxSelected,
      isLoading: stateStore.isLoadingDashboardRequest,
      orderDataDashboard,
    },
    modalOver: {
      openModalCard: openModalCard,
      handlerCloseModal: handlerCloseModal,
      handlerSelectTrx: handlerSelectTrx,
      trx,
      processorData: props.processorData,
      credentialData: props.credentialData,
    },
    checkboxSelection: {
      checkedHearder,
      checkedHearderSelects,
      handleSelectTrx,
      validateExistTrxs,
      handleTrxHeaderChange,
      handleSelectTrxHeaderChange,
    },
    openAnalystAssignmentModal,
    openAnalystAssignmentLoader,
    handleOpenAnalystAssignmentModal,
    handleCloseAnalystAssignmentModal,
    handleAcceptAnalystAssignmentModal,
    handleCloseAnalystAssignmentLoader,
    notification: {
      open: openAnalystAssignmentSnack,
      type: "success",
      msg: "Se realizaron las reasignaciones de las solicitudes con éxito",
      handlerClose: handleCloseNotification,
    },
  };
};
