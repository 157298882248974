import { memo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import BaseDataContainer from "../commons/BaseDataContainer/BaseDataContainer";
import { useDeferredList } from "./state/useDeferredList";
import { Alert, TableInfo } from "@kushki/connect-ui";
import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";
import { CountriesEnum } from "../../shared/infrastructure/countries-enum";
import { getBrandIcon } from "../../shared/constants/utilities";
import { CardBrandsEnum } from "../../shared/enums/CardBrandsEnum";
import DeferredListModal from "../DeferredListModal/DeferredListModal";
import { get, isEmpty } from "lodash";
import { EntityNameEnum } from "../../shared/enums/entityName";
import { DeferredRedirectTitleEnum } from "../../shared/infrastructure/constants/DeferredListConstants";
import { IMerchantInfo } from "./state/useDeferredList.interface";
import { AccordionLabelsEnum } from "../../shared/enums/AccordionLabelsEnum";

const styles: CustomStyles = {
  heading: {
    paddingBottom: "1.3rem",
  },
};

const getLabelEditOrReviewDeferred = (
  isCustomer: boolean,
  isBranchNotCentralized: boolean
) => {
  return isCustomer || isBranchNotCentralized
    ? DeferredRedirectTitleEnum.LABEL_EDIT_DEFERRED
    : DeferredRedirectTitleEnum.LABEL_REVIEW_DEFERRED;
};

const getLabelEditDeferred = (
  isCountryPeru: boolean,
  isDeferredOptions: boolean,
  isCustomer: boolean,
  isBranchNotCentralized: boolean
) => {
  if (isCountryPeru) return DeferredRedirectTitleEnum.LABEL_CONFIG_DEFERRED;
  else if (isDeferredOptions)
    return getLabelEditOrReviewDeferred(isCustomer, isBranchNotCentralized);
  else return DeferredRedirectTitleEnum.LABEL_DEFAULT;
};

const getTextEditButton = (
  merchantInfo: IMerchantInfo,
  isDeferredOptions: boolean,
  isCentralized: boolean
) => {
  const isCountryPeru = get(merchantInfo, "country") === CountriesEnum.PERU;
  const isBranchNotCentralized =
    merchantInfo?.entityName === EntityNameEnum.BRANCH && !isCentralized;
  const isCustomer = merchantInfo?.entityName === EntityNameEnum.CUSTOMER;

  return getLabelEditDeferred(
    isCountryPeru,
    isDeferredOptions,
    isCustomer,
    isBranchNotCentralized
  );
};

const isShowButtonConfig = (
  country: string,
  deferredOptions: boolean,
  tableIsEmpty: boolean,
  showConfigDeferred: boolean
): boolean => {
  if (country === CountriesEnum.PERU) return false;
  if ((!deferredOptions || tableIsEmpty) && showConfigDeferred) return true;

  return false;
};

const validBranchCentralized = (
  entityName: string,
  isCentralized: boolean,
  deferredOptions: boolean,
  tableIsEmpty: boolean
): boolean => {
  const tableHasInfo: boolean = deferredOptions && !tableIsEmpty;

  if (
    tableHasInfo ||
    entityName === EntityNameEnum.CUSTOMER ||
    (entityName === EntityNameEnum.BRANCH && !isCentralized)
  )
    return false;

  return true;
};

const validShowLabel = (
  showConfigDeferred: boolean,
  entityName: string,
  isCentralized: boolean,
  deferredOptions: boolean,
  tableIsEmpty: boolean,
  country: string
): boolean => {
  const showButtonConfig = isShowButtonConfig(
    country,
    deferredOptions,
    tableIsEmpty,
    showConfigDeferred
  );

  if (showButtonConfig) return true;

  return validBranchCentralized(
    entityName,
    isCentralized,
    deferredOptions,
    tableIsEmpty
  );
};

const DeferredList = () => {
  const {
    handleEdit,
    table,
    merchantInfo,
    merchantCards,
    modal,
    deferredOptions,
    showConfigDeferred,
    isCentralized,
    getEntityName,
    hasCardProcessor,
  } = useDeferredList();

  return (
    <>
      {merchantInfo?.country &&
        merchantInfo.country !== CountriesEnum.COLOMBIA &&
        merchantInfo.country !== CountriesEnum.CHILE && (
          <BaseDataContainer
            isCentralized={isCentralized}
            entityName={getEntityName}
            title={AccordionLabelsEnum.DEFERRED}
            textEditButton={getTextEditButton(
              merchantInfo,
              deferredOptions,
              isCentralized
            )}
            onClickEdit={handleEdit}
            withoutEdit={validShowLabel(
              showConfigDeferred,
              get(merchantInfo, "entityName", ""),
              isCentralized,
              deferredOptions,
              isEmpty(table.rows),
              merchantInfo.country
            )}
          >
            <>
              {merchantInfo.country !== CountriesEnum.PERU ? (
                <Box bgcolor="neutral.grey3" borderRadius="8px" p={3}>
                  {deferredOptions && !isEmpty(table.rows) ? (
                    <>
                      <Typography
                        color="text.dark"
                        variant="subtitle2"
                        sx={styles.heading}
                      >
                        Diferidos creados
                      </Typography>

                      <Box overflow={"auto"}>
                        <TableInfo
                          isLoading={false}
                          header={{
                            cells: table.columns,
                            headerProps: {
                              isDisable: false,
                            },
                          }}
                          rows={table.rows}
                        />
                      </Box>

                      <DeferredListModal
                        country={merchantInfo.country}
                        type={modal.type!}
                        isOpen={modal.type !== null}
                        onClose={modal.close}
                        modalItems={modal.data}
                      />
                    </>
                  ) : (
                    <Alert
                      description="Al contar con un procesador de card podrás configurar diferidos de manera opcional."
                      title="Configurar diferidos"
                      type="informative"
                      onButtonClick={() => handleEdit(true)}
                      buttonText={
                        showConfigDeferred && hasCardProcessor
                          ? "Configurar"
                          : ""
                      }
                    />
                  )}
                </Box>
              ) : (
                <Box bgcolor="neutral.grey3" borderRadius="8px" p={3}>
                  {deferredOptions ? (
                    <>
                      <Typography
                        color="text.dark"
                        variant="subtitle2"
                        sx={styles.heading}
                      >
                        Información de la configuración
                      </Typography>
                      <Grid container>
                        <Grid item md={6} xs={12}>
                          <Grid item md={12} xs={12}>
                            <Typography
                              color="text.grey"
                              variant="caption"
                              sx={styles.heading}
                            >
                              Marcas de tarjetas
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12} display={"flex"}>
                            {merchantCards?.acceptCreditCards.map(
                              (creditCard) => {
                                return (
                                  <Box marginRight={"24px"}>
                                    {getBrandIcon(creditCard as CardBrandsEnum)}
                                  </Box>
                                );
                              }
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Grid item md={12} xs={12}>
                            <Typography
                              color="text.grey"
                              variant="caption"
                              sx={styles.heading}
                            >
                              Configuración para los comercios
                            </Typography>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <Typography
                              color="text.primary"
                              variant="overline"
                              sx={styles.heading}
                            >
                              Cada branch podrá activar o desactivar la
                              configuración.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Alert
                      description="La sección de diferidos actualmente se encuentra <strong style='color: rgb(2, 51, 101);'>inhabilitada</strong>. Para cambiar su estado, podrás hacerlo desde la configuración."
                      title="Información"
                      type="informative"
                    />
                  )}
                </Box>
              )}
            </>
          </BaseDataContainer>
        )}
    </>
  );
};

export const DeferredListMemo = memo(DeferredList);
