import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { genericSkeletonStyles } from "./GenericSkeleton.styles";

export const GenericSkeleton: FC = () => {
  return (
    <>
      <Grid sx={genericSkeletonStyles.grid} id={"grid-container"}>
        <Box pb={4}>
          <Skeleton width={"25%"} height={25} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"20%"} height={20} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"40%"} height={20} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"15%"} height={20} variant="rectangular" />
        </Box>
        <Grid container spacing={3}>
          {[...Array(3)].map((_element: any, index: number) => {
            return (
              <Grid item xs={3} md={3} key={`grid${index}`}>
                <Skeleton
                  key={`skeleton${index}`}
                  animation="wave"
                  variant="rectangular"
                  width={"50%"}
                  height={70}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box pb={2} pt={2}>
          <Skeleton width={"40%"} height={20} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"60%"} height={20} variant="rectangular" />
        </Box>
        <Grid container spacing={4}>
          {[...Array(6)].map((_element: any, index: number) => {
            return (
              <Grid item xs={3} md={2} key={`grid${index}`}>
                <Skeleton
                  key={`skeleton${index}`}
                  animation="wave"
                  variant="rectangular"
                  width={"50%"}
                  height={70}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box pb={3} pt={3}>
          <Skeleton width={"100%"} height={20} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"20%"} height={20} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"100%"} height={40} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"20%"} height={20} variant="rectangular" />
        </Box>
        <Box pb={2}>
          <Skeleton width={"100%"} height={40} variant="rectangular" />
        </Box>
      </Grid>
    </>
  );
};
