/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_COLOMBIA: IOptionSelection[] = [
  { value: "000048", label: "Aliadas" },
  { value: "000086", label: "ASOCAJAS" },
  { value: "000005", label: "Bancafe" },
  { value: "000050", label: "Banco Agrario" },
  { value: "000037", label: "Banco Boston" },
  { value: "000032", label: "Banco Caja Social" },
  { value: "000067", label: "BANCO COMPARTIR" },
  { value: "000061", label: "BANCO COOMEVA" },
  { value: "000066", label: "BANCO COOPERATIVO COOPCENTRAL" },
  { value: "000052", label: "Banco de AVVILLAS" },
  { value: "000001", label: "Banco de Bogotá" },
  { value: "000000", label: "Banco de la Republica" },
  { value: "000023", label: "Banco de Occidente" },
  { value: "000555", label: "Banco de Prueba" },
  { value: "000998", label: "BANCO DE PRUEBAS" },
  { value: "000062", label: "Banco Falabella S.A." },
  { value: "000063", label: "Banco Finandina S.A." },
  { value: "000016", label: "Banco Gasparin" },
  { value: "000012", label: "Banco GNB Sudameris" },
  { value: "000999", label: "Banco Modula Facturador" },
  { value: "000064", label: "BANCO MULTIBANK" },
  { value: "000002", label: "Banco Popular" },
  { value: "000058", label: "Banco PROCREDIT Colombia" },
  { value: "000065", label: "Banco Santander de Neg Col SA" },
  { value: "000034", label: "Banco Superior" },
  { value: "000029", label: "Banco Tequendema" },
  { value: "000022", label: "Banco Union" },
  { value: "000031", label: "BANCOLDEX" },
  { value: "000007", label: "Bancolombia" },
  { value: "000013", label: "BBVA" },
  { value: "000042", label: "BNP PARIBAS" },
  { value: "000888", label: "CHEQUES" },
  { value: "000009", label: "Citibank" },
  { value: "000057", label: "Colmena" },
  { value: "000019", label: "Colpatria" },
  { value: "000370", label: "COLTEFINANCIERA" },
  { value: "000121", label: "COMPANIA FIN JURISCOOP" },
  { value: "000083", label: "COMPENSAR" },
  { value: "000055", label: "Conavi" },
  { value: "000292", label: "CONFIAR" },
  { value: "000810", label: "COOMEVA" },
  { value: "000283", label: "Coop. Financiera de Antioquia" },
  { value: "000076", label: "COOPCENTRAL" },
  { value: "000090", label: "Corficolombiana" },
  { value: "000006", label: "Corpbanca" },
  { value: "000289", label: "COTRAFA COOPERATIVA FINANCIERA" },
  { value: "000051", label: "Davivienda" },
  { value: "000550", label: "DECEVAL S.A." },
  { value: "000089", label: "ENLACE OPERATIVO" },
  { value: "000087", label: "FEDECAJAS" },
  { value: "000296", label: "Financiera Juriscoop" },
  { value: "000054", label: "Granahorrar" },
  { value: "000050", label: "GRANBANCO" },
  { value: "000014", label: "Helm Bank S.A." },
  { value: "000010", label: "HSBC" },
  { value: "000060", label: "Inversora Pichincha S.A." },
  { value: "000041", label: "JPMORGAN CORPORACION FINANCIERA" },
  { value: "000036", label: "MEGABANCO" },
  { value: "000685", label: "MINHACIENDA REGALIAS" },
  { value: "000507", label: "Nequi" },
  { value: "000201", label: "prueba creación" },
  { value: "000101", label: "PSE" },
  { value: "000008", label: "Scotian" },
  { value: "000342", label: "SERFINANSA" },
  { value: "000088", label: "SIMPLE S.A." },
  { value: "000502", label: "SKANDIA" },
  { value: "000024", label: "Standard Chartered Colombia" },
  { value: "000683", label: "Tesoro NAL" },
];
