import { ActionTypes } from "./actionTypes";
import { IAppState } from "../shared/infrastructure/interfaces/IAppState";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AxiosResponse } from "axios";
import axios from "../shared/utils/axios-util";
import { environment } from "../environments/environment";
import { ConfigurationResponse } from "../../types/configuration_response";
import { get, isEmpty, set, uniqBy } from "lodash";
import { MerchantProcessors } from "../../types/merchant_processors";
import { GetRuleResponse } from "../../types/get_rule_response";
import { CreateRuleRequest } from "../../types/create_rule_request";
import { GeographyList } from "../../types/geography_list";
import { Country } from "../../types/country_response";
import { IOptionSelection } from "../shared/infrastructure/constants/BusinessRulesConstants";
import { CreateRuleResponse } from "../../types/create_rule_response";
import { SuggestCredentialsResponse } from "../../types/suggest_credentials_response";
import { SearchRuleRequest } from "../../types/search_rule_request";
import { SearchRuleResponse } from "../../types/search_rule_response";
import { ILoadingModal } from "../shared/infrastructure/interfaces/ILoadingModal";
import { LoadingModalMessages } from "../shared/infrastructure/enums/LoadingModalMessages";
import {
  GetBusinessRulesResponse,
  RuleItem,
} from "../../types/get_business_rules_response";
import { GetBusinessRulesRequest } from "../../types/get_business_rules_request";
import { ISaveRuleModal } from "../shared/infrastructure/interfaces/ISimpleModal";
import { DEFAULT_LOADING_MODAL } from "./defaultStates";
import { ItemSearchRuleResponse } from "../../types/item_search_rule_response";
import { IAlert } from "../shared/infrastructure/interfaces/IAlert";
import { AlertEnum } from "../shared/infrastructure/enums/AlertEnum";
import { GetNodeInfoRequest } from "../../types/get_node_info_request";
import { GetNodeInfoResponse } from "../../types/get_node_info_response";
import { SuggestCredentialsRequest } from "../../types/suggest_credentials_request";
import { IParentNodeInfo } from "../shared/infrastructure/interfaces/IParentNodeInfo";
import { PaymentMethodEnum } from "../shared/infrastructure/enums/PaymentMethodEnum";
import {
  camelize,
  getCustomerId,
  isCentralizedBranch,
} from "../utils/utilsFile";
import { ItemBusinessRule } from "../../types/item_business-rule";
import { IAtomicId } from "../shared/infrastructure/interfaces/IAtomicId";
import { CustomerCredentialsRequest } from "../../types/customer_credentials_request";
import { CustomerCredentialsResponse } from "../../types/customer_credentials_response";

export type IAppAction = { type: string } & IAppState;

export const setRequestError = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_REQUEST_ERROR,
    requestError: payload,
  };
};

export const setParentMerchantId = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_PARENT_MERCHANT_ID,
    parentMerchantId: payload,
  };
};

export const setPrefixAtomicCounter = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_PREFIX_ATOMIC_COUNTER,
    prefixAtomicCounter: payload,
  };
};

export const setAtomicId = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_ATOMIC_ID,
    atomicId: payload,
  };
};

export const setIsRequest = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_REQUESTING,
    isRequest: payload,
  };
};

export const setLoadingModal = (payload: ILoadingModal): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_MODAL,
    loadingModal: payload,
  };
};

export const setSaveRuleModal = (payload: ISaveRuleModal): IAppAction => {
  return {
    type: ActionTypes.SET_SAVE_RULE_MODAL,
    saveRuleModal: payload,
  };
};

export const getListProcessors = (payload: MerchantProcessors[]) => {
  return {
    type: ActionTypes.GET_LIST_PROCESSORS,
    processors: payload,
  };
};

export const getApiConfigList = (payload: ConfigurationResponse) => {
  return {
    type: ActionTypes.GET_CONFIG_LIST,
    configList: payload,
  };
};

export const getApiGeographyList = (payload: GeographyList) => {
  return {
    type: ActionTypes.GET_GEOGRAPHY_LIST,
    geographyList: payload,
  };
};

export const setCurrencyByCountry = (
  payload: Record<string, IOptionSelection[]>
) => {
  return {
    type: ActionTypes.SET_CURRENCY,
    currencyByCountry: payload,
  };
};

export const getApiCountries = (payload: Country[]) => {
  return {
    type: ActionTypes.SET_COUNTRY_RULES,
    countries: payload,
  };
};

export const createNewRule = (payload: CreateRuleResponse) => {
  return {
    type: ActionTypes.SET_NEW_RULES,
    newRule: payload,
  };
};

export const setBusinessRules = (payload: RuleItem[]) => {
  return {
    type: ActionTypes.SET_BUSINESS_RULES,
    businessRules: payload,
  };
};

export const getGetRuleById = (payload: GetRuleResponse) => {
  return {
    type: ActionTypes.GET_DETAIL_RULES,
    getRuleById: payload,
  };
};

export const getApiSuggestCredentials = (
  payload: SuggestCredentialsResponse[]
) => {
  return {
    type: ActionTypes.GET_SUGGEST_CREDENTIALS,
    suggestCredentials: payload,
  };
};

export const getApiCustomerCredentials = (
  payload: CustomerCredentialsResponse[]
) => {
  return {
    type: ActionTypes.GET_CUSTOMER_CREDENTIALS,
    customerCredentials: payload,
  };
};

export const setOpenAlert = (payload: IAlert): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_ALERT,
    openAlert: payload,
  };
};

export const setSuccessEnableRule = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_SUCCESS_ENABLE_RULE,
    successEnableRule: payload,
  };
};

export const setNodeInfo = (payload: GetNodeInfoResponse): IAppAction => {
  return {
    type: ActionTypes.SET_NODE_INFO,
    nodeInfoMerchant: payload,
  };
};

function getCurrencyByCountryResponse(
  response: Country[]
): Record<string, IOptionSelection[]> {
  return response.reduce((previousValue, current) => {
    const currencies = get(current, "currency", "").split(",");
    if (!currencies || currencies[0] === "") return previousValue;
    const finalCurrencies: IOptionSelection[] = [];
    currencies.forEach((el) => finalCurrencies.push({ value: el, label: el }));
    previousValue["CURRENCY_" + current.name.toUpperCase()] = finalCurrencies;
    return previousValue;
  }, {});
}

export const getProcessors = (
  merchantId: string,
  isCentralized?: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    if (!isCentralized)
      dispatch(
        setLoadingModal({
          isLoading: true,
          message: LoadingModalMessages.PROCESSORS,
        })
      );
    dispatch(setRequestError(false));
    axios
      .get(
        `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}/processors`
      )
      .then((axios_response: AxiosResponse<MerchantProcessors[]>) => {
        const response: MerchantProcessors[] = get(axios_response, "data");
        dispatch(
          getListProcessors(
            response.filter((p) => p.paymentMethod === PaymentMethodEnum.CARD)
          )
        );
        dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(getListProcessors([]));
        dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
  };
};

export const getActiveProcessors = (
  merchantId: string,
  text?: string,
  filterCardProcessor: boolean = true
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  const processorsList: MerchantProcessors[] = [];
  const request: object = {
    limit: 10,
    offset: 0,
    filter: {
      status: "ENABLED|",
      nonExistingFields: ["status"],
    },
  };

  if (filterCardProcessor)
    set(request, "filter.paymentMethod", PaymentMethodEnum.CARD);

  if (text) set(request, "filter.text", text);

  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .post<MerchantProcessors[]>(
        `${environment.kushkiUrl}/analytics/v1/admin/${merchantId}/processors`,
        request
      )
      .then((axios_response) => {
        get(axios_response, "data.data", []).forEach(
          (processor: { _source: any; [k: string]: any }) =>
            processorsList.push({ ...processor._source })
        );
        dispatch(getListProcessors(processorsList));
      })
      .catch(() => {
        dispatch(setIsRequest(false));
        dispatch(setRequestError(true));
        dispatch(getListProcessors([]));
      });
  };
};

export const getConfiguration = (
  useLoadingModal: boolean = true
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setIsRequest(true));
    if (useLoadingModal)
      dispatch(
        setLoadingModal({
          isLoading: true,
          message: LoadingModalMessages.CONFIGURATIONS,
        })
      );
    dispatch(setRequestError(false));
    return axios
      .get(`${environment.kushkiUrl}/request-manager/v1/configuration`)
      .then((axios_response: AxiosResponse<ConfigurationResponse>) => {
        const response: ConfigurationResponse = get(axios_response, "data");
        dispatch(getApiConfigList(response));
        if (useLoadingModal) dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(getApiConfigList({}));
        if (useLoadingModal) dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
  };
};

export const createRule = (
  request: CreateRuleRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(
      setLoadingModal({
        isLoading: true,
        message: LoadingModalMessages.CREATE_RULE,
      })
    );
    dispatch(setRequestError(false));
    axios
      .post(`${environment.kushkiUrl}/rules/v1/admin/business/rules`, {
        ...request,
      })
      .then((axios_response: AxiosResponse<CreateRuleResponse>) => {
        const response: CreateRuleResponse = get(axios_response, "data");
        const createRuleResponse: CreateRuleResponse = {
          ruleId: get(response, "ruleId", ""),
          message: get(response, "message", ""),
        };
        dispatch(createNewRule(createRuleResponse));
        setTimeout(() => {
          dispatch(setRequestError(true));
          dispatch(createNewRule({}));
          dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
        }, 2000);
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(createNewRule({}));
        dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
  };
};

export const getBusinessRules = (
  request: GetBusinessRulesRequest,
  useLoadingModal: boolean = true
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setIsRequest(true));
    if (useLoadingModal)
      dispatch(
        setLoadingModal({
          isLoading: true,
          message: LoadingModalMessages.DEFAULT_MESSAGE,
        })
      );
    dispatch(setRequestError(false));
    return axios
      .post(`${environment.kushkiUrl}/rules/v1/admin/business-type/rules`, {
        ...request,
      })
      .then((axios_response: AxiosResponse<GetBusinessRulesResponse>) => {
        const response: RuleItem[] = get(axios_response, "data.items");
        dispatch(setBusinessRules(response));
        if (useLoadingModal) dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(setBusinessRules([]));
        if (useLoadingModal) dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
  };
};

export const getRule = (
  id: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .get(`${environment.kushkiUrl}/rules/v1/admin/business/rules/${id}`)
      .then((axios_response: AxiosResponse<GetRuleResponse>) => {
        const response: GetRuleResponse = get(axios_response, "data");
        dispatch(getGetRuleById({ ...response }));
        dispatch(
          getBusinessRules(
            { limit: 10000, merchantId: response.merchantId },
            false
          )
        );
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(
          getGetRuleById({
            type: "",
            updatedAt: 0,
            service: "",
            rule: {
              kind: "",
              name: "",
              periodicityCountHour: "",
              periodicityCountDay: "",
              action: "",
              metric: "",
              periodicity: "",
              transactionStatus: "",
            },
            historyVersion: "",
            country: [],
            createdAt: 0,
            deletedAt: 0,
            id: "",
            merchantId: "",
            merchantName: "",
          })
        );
      });
  };
};

export const getGeographyList = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    return axios
      .get(`${environment.kushkiUrl}/request-manager/v1/geography`)
      .then((axios_response: AxiosResponse<GetRuleResponse>) => {
        const response: GeographyList = get(axios_response, "data");
        dispatch(getApiGeographyList(response));
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(
          getApiGeographyList({ continents: [], countries: [], regions: [] })
        );
      });
  };
};

export const getCountries = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    return axios
      .get(`${environment.kushkiUrl}/request-manager/v1/geography/country`)
      .then((axios_response: AxiosResponse) => {
        const response: Country[] = get(axios_response, "data.countries");
        dispatch(getApiCountries(response));
        dispatch(setCurrencyByCountry(getCurrencyByCountryResponse(response)));
      })
      .catch(() => {
        dispatch(getApiCountries([]));
        dispatch(setCurrencyByCountry({}));
      });
  };
};

export const getSuggestCredentials = (
  suggestCredentialsRequest: SuggestCredentialsRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credential/hierarchyCredentials`,
        {
          ...suggestCredentialsRequest,
        }
      )
      .then((axios_response: AxiosResponse<SuggestCredentialsResponse[]>) => {
        const response: SuggestCredentialsResponse[] = get(
          axios_response,
          "data.data"
        );

        dispatch(
          getApiSuggestCredentials(uniqBy(response, "_source.credentialId"))
        );
      })
      .catch(() => {
        dispatch(getApiSuggestCredentials([]));
      });
  };
};

export const getCustomerCredentials = (
  customerCredentialsRequest: CustomerCredentialsRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(
        `${environment.kushkiUrl}/payment-credentials/v1/business/rules/credentials`,
        {
          ...customerCredentialsRequest,
        }
      )
      .then((axios_response: AxiosResponse<CustomerCredentialsResponse[]>) => {
        const response: CustomerCredentialsResponse[] = get(
          axios_response,
          "data"
        );

        dispatch(getApiCustomerCredentials(get(response, "nodes", "")));
      })
      .catch(() => {
        dispatch(getApiCustomerCredentials([]));
      });
  };
};

export const setOpenModalInfo = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_INFO,
    openModalInfo: payload,
  };
};

export const setIsLoadingRules = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_RULES,
    isLoadingRules: payload,
  };
};

export const setSearchRequestRulesResponse = (
  payload: SearchRuleResponse
): IAppAction => ({
  searchRequestRulesResponse: payload,
  type: ActionTypes.SET_SEARCH_REQUEST_RULES_RESPONSE,
});

export const setIsLoadingOneRule = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_ONE_RULE,
    isLoadingOneRule: payload,
  };
};

export const setRequestRuleById = (payload: ItemBusinessRule): IAppAction => ({
  type: ActionTypes.SET_SEARCH_REQUEST_BY_ID,
  requestRule: payload,
});

export const setOpenConfirmModal = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_CONFIRM_MODAL,
    openConfirmModal: payload,
  };
};

export const setSearchFilterRulesResponse = (
  payload: SearchRuleResponse
): IAppAction => ({
  searchFilterRulesResponse: payload,
  type: ActionTypes.SET_SEARCH_FILTER_RULES_RESPONSE,
});

export const getRequestRules = (
  path: string,
  body: SearchRuleRequest,
  nodeInfoMerchant: GetNodeInfoResponse | undefined,
  isFilterSearch?: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    !isFilterSearch && dispatch(setIsLoadingRules(true));
    dispatch(setRequestError(false));
    axios
      .post(`${environment.kushkiUrl}/request-manager/v1/${path}`, body)
      //.post(`https://accendo.getsandbox.com:443/${path}`, body)
      .then((axios_response: AxiosResponse<SearchRuleResponse>) => {
        const response: SearchRuleResponse = axios_response.data;
        //response.data.reverse();
        if (
          isCentralizedBranch() &&
          nodeInfoMerchant &&
          !isEmpty(getCustomerId(nodeInfoMerchant))
        ) {
          dispatch(
            getRequestRulesCustomer(
              "rules",
              body,
              response,
              getCustomerId(nodeInfoMerchant)
            )
          );
        } else {
          isFilterSearch
            ? dispatch(setSearchFilterRulesResponse(response))
            : dispatch(setSearchRequestRulesResponse(response));
          dispatch(setIsLoadingRules(false));
        }
      })
      .catch(() => {
        dispatch(setIsLoadingRules(false));
        dispatch(setRequestError(true));
      });
  };
};

export const getRequestRulesCustomer = (
  path: string,
  body: SearchRuleRequest,
  customer_response: SearchRuleResponse,
  customerId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    let response: SearchRuleResponse = customer_response;
    body.filter = {
      ...body.filter,
      merchant: customerId,
    };
    axios
      .post(`${environment.kushkiUrl}/request-manager/v1/${path}`, body)
      //.post(`https://accendo.getsandbox.com:443/${path}`, body)
      .then((axios_response: AxiosResponse<SearchRuleResponse>) => {
        response = {
          data: [...axios_response.data.data, ...response.data],
          total: axios_response.data.total + response.total,
        };
        //response.data.reverse();
        dispatch(setSearchRequestRulesResponse(response));
        dispatch(setIsLoadingRules(false));
      })
      .catch(() => {
        dispatch(setIsLoadingRules(false));
        dispatch(setRequestError(true));
      });
  };
};

export const getRequestById = (
  path: string,
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setRequestError(false));
    dispatch(setIsLoadingOneRule(true));
    axios
      .get(`${environment.kushkiUrl}/rules/v1/admin/business/${path}/${ruleId}`)
      .then((axios_response: AxiosResponse<ItemBusinessRule>) => {
        const response: ItemBusinessRule = axios_response.data;
        dispatch(setRequestRuleById(response));
        dispatch(setIsLoadingOneRule(false));
      })
      .catch(() => {
        dispatch(setRequestError(true));
      });
  };
};

export const deleteRule = (
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .delete(
        `${environment.kushkiUrl}/rules/v1/admin/business/rules/${ruleId}`
      )
      .then((_: AxiosResponse<{ status: string }>) => {
        dispatch(
          setOpenAlert({
            open: true,
            isError: false,
            originAlert: AlertEnum.ORIGIN_DELETE,
          })
        );
        dispatch(updateOrDeleteRuleInList(ruleId));
      })
      .catch((_) => {
        dispatch(
          setOpenAlert({
            open: true,
            isError: true,
            originAlert: AlertEnum.ORIGIN_DELETE,
          })
        );
      });
  };
};

export const updateOrDeleteRuleInList =
  (
    id: string,
    item?: ItemSearchRuleResponse
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>,
    getState: () => IAppState
  ): void => {
    const searchRuleResponse: SearchRuleResponse = get(
      getState(),
      "layout.searchRequestRulesResponse",
      { data: [], total: 0 }
    );
    const data: ItemSearchRuleResponse[] = [...searchRuleResponse.data];
    let total: number = searchRuleResponse.total;
    const indexOfObject = data.findIndex((object) => object.id === id);
    data.splice(indexOfObject, 1);
    total--;

    if (item) {
      data.push(item);
      total++;
    }

    dispatch(
      setSearchRequestRulesResponse({
        data,
        total,
      })
    );
  };

export function isAnItemSearchRule(
  object: any
): object is ItemSearchRuleResponse {
  return "id" in object;
}

export const updateRule = (
  ruleId: string,
  request: ItemSearchRuleResponse | CreateRuleRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .patch(
        `${environment.kushkiUrl}/rules/v1/admin/business/rules/${ruleId}`,
        camelize(request)
      )
      .then((_: AxiosResponse<{ message: string }>) => {
        dispatch(
          setOpenAlert({
            open: true,
            isError: false,
            originAlert: AlertEnum.ORIGIN_DISABLE,
          })
        );

        if (isAnItemSearchRule(request)) {
          dispatch(updateOrDeleteRuleInList(ruleId, request));
        }
      })
      .catch((_) => {
        dispatch(
          setOpenAlert({
            open: true,
            isError: true,
            originAlert: AlertEnum.ORIGIN_DISABLE,
          })
        );
        dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
  };
};

export const getNodeInfo = (
  payload: GetNodeInfoRequest,
  useLoadingModal: boolean = true
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .post<GetNodeInfoResponse>(
        `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
        payload
      )
      .then((axios_response: AxiosResponse<GetNodeInfoResponse>) => {
        const response: GetNodeInfoResponse = axios_response.data;
        dispatch(setNodeInfo(response));
      })
      .catch((_e) => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        if (useLoadingModal) dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
  };
};

export const getParentMerchantId = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .post(`${environment.kushkiUrl}/hierarchy/v1/node/first-parent`, {
        publicMerchantId,
      })
      .then((response: AxiosResponse<IParentNodeInfo>) => {
        dispatch(setParentMerchantId(response.data.merchantId));
      })
      .catch(() => {
        dispatch(setRequestError(true));
      });
  };
};

export const getAtomicId = (
  atomic_id: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .post(
        `${environment.kushkiUrl}/card-massive-rule-management/v1/atomic/counter`,
        {
          atomic_id,
        }
      )
      .then((response: AxiosResponse<IAtomicId>) => {
        dispatch(setAtomicId(response.data.quantity));
      })
      .catch(() => {
        dispatch(setRequestError(true));
      });
  };
};
