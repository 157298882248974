import { useDispatch } from "react-redux";
import { IUseDataTableComponentBranch } from "../../../shared/infrastructure/interfaces/IUseDataTableComponentBranch";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useEffect, useState } from "react";
import {
  LabelEnum,
  TABLE_COLUMNS,
} from "../../../shared/infrastructure/enums/LabelEnum";
import { ItemSearchRuleResponse } from "../../../../types/item_search_rule_response";
import {
  EndpointsPathsEnum,
  PathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import {
  getConfiguration,
  getRequestById,
  setOpenModalInfo,
} from "../../../store/actionCreators";
import {
  COLUMNS_BY_TABS,
  TableBodyCellEnum,
  TableFieldsEnum,
} from "../../../shared/infrastructure/enums/DataTableEnum";
import { get, isEmpty } from "lodash";
import { IDataTableBranch } from "../../ContentModalBranch/ContentModal.interface";
import { buildOneCell } from "../../../shared/utils/utilsFile";

export const useDataTableComponentStateBranch = (
  props: IDataTableBranch
): IUseDataTableComponentBranch => {
  // TODO : ESTO DEBE SER CAMBIADO CUANDO ESTE LA HISTORIA DE LISTADO DE REGLAS
  const dispatch = useDispatch();

  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [cells] = useState<HeaderCellProps[]>(TABLE_COLUMNS[props.tabName]);

  const transformDataToSchemaRowTable = (
    data: ItemSearchRuleResponse[]
  ): void => {
    let tableRowsProps: ITableRowProps[] = [];

    data.forEach((item: ItemSearchRuleResponse, index: number) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          rowProps: {
            color: "default",
            onClick: () => {
              dispatch(setOpenModalInfo(true));
              handleRequestRuleByIdBranch(get(item, PathsEnum.ID, ""));
            },
          },
          cells: buildCells(item),
          id: index.toString(),
        },
      ];
    });

    setRows(tableRowsProps);
  };

  const buildCells = (item: ItemSearchRuleResponse): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const itemsByTabs: string[] = COLUMNS_BY_TABS[LabelEnum.BUSINESS_RULES];

    itemsByTabs.forEach((key: string) => {
      const itemText: string = get(item, key, "");

      switch (key) {
        case TableFieldsEnum.CREATED:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
          }
          break;
        case TableFieldsEnum.ALIAS:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
          }
          break;
        case TableFieldsEnum.CATEGORY:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
          }
          break;
        case TableFieldsEnum.STATUS:
          cells.push(
            buildOneCell(TableBodyCellEnum.TAG, itemText, undefined, {
              align: "center",
              spacing: 0,
            })
          );
          break;
        default:
          cells.push(
            buildOneCell(TableBodyCellEnum.TEXT, itemText, LabelEnum.NO_APPLY)
          );
          break;
      }
    });

    return cells;
  };

  useEffect(() => {
    transformDataToSchemaRowTable(get(props, PathsEnum.SEARCH_RULE_DATA, []));
  }, [props.searchRuleResponse]);

  const handleRequestRuleByIdBranch = (id: string) => {
    dispatch(getRequestById(EndpointsPathsEnum.RULE_REQUEST_BY_ID, id));
  };

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  return {
    rows,
    cells,
  };
};
