import { Checkbox } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { ICellCheckProps } from "./CellCheck.interfaces";

export const CellCheckItem: FC<ICellCheckProps> = (
  props: PropsWithChildren<ICellCheckProps>
) => {
  const { isChecked, id, configsComplete } = props;

  return (
    <Checkbox
      aria-label={`checkbox-row-${id.merchant_id!}`}
      color="primary"
      checked={isChecked}
      onClick={(event) =>
        props.handleClick && configsComplete
          ? props.handleClick(event, id)
          : null
      }
      inputProps={{
        "aria-labelledby": id.merchant_id!,
      }}
    />
  );
};
