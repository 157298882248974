import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableSortLabel,
  Checkbox,
  Box,
  Typography,
} from "@material-ui/core";
import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import { get, find, toLower, toString } from "lodash";
import { formatDateWithTimezone } from "../../shared/date-util";
import { lenguage } from "../../shared/infrastructure/constants/language";
import { TransactionStatusEnum } from "../../shared/infrastructure/constants/transaction-status-enum";
import { ChipTransactionsStatus } from "../ChipTransactionsStatus/ChipTransactionsStatus";
import { useSelector } from "react-redux";
import { SkeletonTable } from "../commons/Skeleton/SkeletonTable";
import { IColumns } from "../../shared/infrastructure/interfaces/IColumns";
import ChevronSort from "../../assets/images/ChevronSort";
import Pagination, { PaginationProps } from "../Pagination/Pagination";
import { staticColumns } from "../../shared/infrastructure/constants/PayoutsTransactionsConstants";
import { IAppState } from "../../store/reducer";
import { CurrencyEnum } from "../../shared/infrastructure/constants/currency-enum";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    paddingTop: "0px",
    borderRadius: 4,
    boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  tableHeaderCell: {
    padding: "10px 16px",
    lineHeight: "normal",
  },
});

export interface ITableComponent {
  payoutsTransactions?: Transaction[];
  selectedColumns: IColumns[];
  isSelectedTable?: boolean;
  sortBy?: {
    sortByKey?: string;
    direction?: "asc" | "desc";
    handleSortByKey(key: string): void;
  };
  pagination?: PaginationProps;
  handleSelectPayoutsTransaction(checked: boolean, row: Transaction): void;
  handleCheckSelectedPayoutsTransaction(
    payoutsTransactionToCheck: Transaction
  ): boolean;
  handleSetPayuotTransactionSelected(
    payoutTransactionSelected: TransactionInfo
  ): void;
}

const getAmount = (payoutsTransactionInfo: TransactionInfo) => {
  const currency = get(
    payoutsTransactionInfo,
    "converted_currency",
    get(payoutsTransactionInfo, "currency_code", "USD")
  );

  return `${
    payoutsTransactionInfo.transaction_status ===
      TransactionStatusEnum.DECLINED ||
    payoutsTransactionInfo.transaction_status ===
      TransactionStatusEnum.INITIALIZED
      ? formatNumber(
          get(
            payoutsTransactionInfo,
            "converted_amount",
            get(payoutsTransactionInfo, "request_amount", 0)
          ),
          currency
        )
      : formatNumber(
          get(payoutsTransactionInfo, "approved_transaction_amount", 0),
          currency
        )
  } ${currency}`;
};

const formatNumber = (value: string | number, currency: string): string => {
  if (currency === CurrencyEnum.CLP)
    return new Intl.NumberFormat("es-CL", {
      currency: "CLP",
    }).format(Number(value));
  else {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }
};

const getTranslate = (word: string) => {
  return get(find(lenguage, { leng: word }), "es", "");
};

const getTypeCell = (
  description: string | number,
  payoutsTransactionInfo: TransactionInfo,
  type?: string
): React.ReactNode => {
  switch (type) {
    case "created":
      return (
        <Box display="block">
          {formatDateWithTimezone(
            get(payoutsTransactionInfo, type, ""),
            "yyyy-MM-dd"
          )}
          <Typography variant="subtitle2">
            {formatDateWithTimezone(
              get(payoutsTransactionInfo, type, ""),
              "HH:mm:ss"
            )}
          </Typography>
        </Box>
      );
    case "approved_transaction_amount":
      return (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`${getAmount(payoutsTransactionInfo)}`}
        </div>
      );
    case "payment_method":
      return getTranslate(get(payoutsTransactionInfo, type, ""));
    case "transaction_status":
      return (
        <ChipTransactionsStatus
          size="small"
          status={get(payoutsTransactionInfo, type, "")}
          message={
            get(payoutsTransactionInfo, "response_code", "") +
            "-" +
            get(payoutsTransactionInfo, "response_text", "")
          }
          disableHover={
            get(payoutsTransactionInfo, type, "") !==
            TransactionStatusEnum.DECLINED
          }
          text={getTranslate(toLower(get(payoutsTransactionInfo, type, "")))}
        />
      );
    default:
      return description;
  }
};

const getColumns = (
  columns: IColumns[],
  payoutTransactionInfo: TransactionInfo
): {
  description: string;
  type: string;
}[] =>
  columns.map((col: IColumns) => ({
    description: toString(get(payoutTransactionInfo, col.key)),
    type: col.key,
  }));

const TableComponent = (props: ITableComponent) => {
  const classes = useStyles();
  const isLoading = useSelector((state: IAppState) => state.loading);

  const getSelectedColumns = () => {
    return [staticColumns[0], ...props.selectedColumns, staticColumns[1]];
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            {getSelectedColumns().map((column: IColumns, index: number) => (
              <TableCell
                key={`head_${column.key}_${index}`}
                className={classes.tableHeaderCell}
              >
                {column.value &&
                  (props.sortBy ? (
                    <TableSortLabel
                      active={props.sortBy.sortByKey === column.key}
                      direction={
                        props.sortBy.sortByKey === column.key
                          ? props.sortBy.direction
                          : "asc"
                      }
                      onClick={() => {
                        props.sortBy!.handleSortByKey(column.key);
                      }}
                      IconComponent={ChevronSort}
                    >
                      {column.value}
                    </TableSortLabel>
                  ) : (
                    <>{column.value}</>
                  ))}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {!props.isSelectedTable && isLoading ? (
          <SkeletonTable columns={7} rows={10} />
        ) : (
          <TableBody>
            {props.payoutsTransactions!.map(
              (payoutTransaction: Transaction) => (
                <TableRow
                  id={`row_${payoutTransaction._id}`}
                  key={`row_${payoutTransaction._id}`}
                  hover
                  selected={
                    !props.isSelectedTable &&
                    props.handleCheckSelectedPayoutsTransaction(
                      payoutTransaction
                    )
                  }
                  onClick={() =>
                    props.handleSetPayuotTransactionSelected(
                      payoutTransaction._source!
                    )
                  }
                >
                  <TableCell component="th" scope="row" style={{ width: 10 }}>
                    <Checkbox
                      color="primary"
                      checked={props.handleCheckSelectedPayoutsTransaction(
                        payoutTransaction
                      )}
                      onChange={(_event: any) => {
                        props.handleSelectPayoutsTransaction(
                          _event.target.checked,
                          payoutTransaction
                        );
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </TableCell>
                  {getColumns(
                    getSelectedColumns(),
                    payoutTransaction._source!
                  ).map(({ description, type }, index: number) => (
                    <TableCell key={`column_${index}`}>
                      {getTypeCell(
                        description,
                        payoutTransaction._source!,
                        type
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        )}
      </Table>
      {props.pagination && !isLoading && (
        <Pagination {...props.pagination} title="Transacciones por página" />
      )}
    </TableContainer>
  );
};

export default TableComponent;
