import React from "react";

export function convertStringBolds(
  input: string,
  isBoldText: boolean | undefined
): React.ReactNode[] {
  if (!isBoldText) return [input];

  const regex = /\*(.*?)\*/g;
  const matches = input.match(regex);

  if (!matches) {
    return [input];
  }

  const parts = input.split(regex);

  return parts.map((part, index) => {
    if (matches.includes(`*${part}*`)) {
      const content = part.replace(regex, "$1");

      return <strong key={index}>{content}</strong>;
    } else {
      return <span key={index}>{part}</span>;
    }
  });
}
