import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../shared/constants/routes";
import { IUseBranchCreationContainerState } from "./useBranchCreationContainerState.interfaces";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import { defaultTo } from "lodash";

export const useBranchCreationContainerState =
  (): IUseBranchCreationContainerState => {
    const location = useLocation();
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const [branchRoute, setBranchRoute] = useState("");
    const [disableContinueButton, setDisableContinueButton] = useState(true);

    const handleSelectBranchOption = (route: string) => {
      if (Object.values(ROUTES).includes(route as ROUTES)) {
        setDisableContinueButton(false);
        setBranchRoute(route);
      }
    };

    const navigateTo = () => {
      navigate(`${ROUTES.BRANCH}/${branchRoute}${location.search}`);
      sessionStorage.setItem(
        SessionStoragePaths.BRANCH_LIST,
        JSON.stringify([])
      );
    };

    useEffect(() => {
      sessionStorage.setItem(
        SessionStoragePaths.BRANCH_LIST,
        JSON.stringify([])
      );
      sessionStorage.setItem(
        SessionStoragePaths.BRANCH_EDIT_LIST,
        JSON.stringify([])
      );
      sessionStorage.setItem(
        SessionStoragePaths.CUSTOMER_MERCHANT_ID,
        defaultTo(params.get("publicMerchantId"), "")
      );
    }, []);

    return {
      disableContinueButton,
      handleSelectBranchOption,
      navigateTo,
    };
  };
