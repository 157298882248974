/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
devTools: boolean;
customerRoles: string;
rollbarToken: string;
retentionSwitch: boolean;
showPayoutMethod: boolean;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
customerRoles:"BackofficeMasterCustomer",
rollbarToken: '331a63908a834769a1931a26c5d5b2a9',
retentionSwitch:true,
showPayoutMethod:false,
};
