import { CountriesEnum } from "./enums/countriesEnum";
import { NodeInfoResponse } from "../../../types/node_info_response";
import { get } from "lodash";
import { getDate } from "../utils/date_utils";

export const SUB_HEADER_DEFAULT_STATE = {
  constitutionalCountry: CountriesEnum.MEXICO as CountriesEnum,
  customerId: "123456789",
  id: "123456789",
  redirectPath: "/",
  title: "Walmart Acueducto",
};

export const buildSubHeaderTexts = (
  _publicMerchantId: string | null,
  nodeInfo: NodeInfoResponse
) => ({
  constitutionalCountry: get(
    nodeInfo,
    "generalInfo.country",
    CountriesEnum.MEXICO
  ),
  customerId: get(nodeInfo, "merchantId", ""),
  id: get(nodeInfo, "merchantId", ""),
  redirectPath: "",
  title: get(nodeInfo, "generalInfo.name", ""),
});

export const buildLastModifyProps = (
  updatedAt: number,
  userName: string
): { author: string; date: string } => ({
  author: userName,
  date: getDate(updatedAt, "dd/MM/yyyy - HH:mm"),
});
