import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const footerStyles = createNamedStyles({
  wrapper: {
    position: "fixed",
    bottom: "0px",
    left: "0",
    width: "100%",
    height: "108px",
    zIndex: "100",
    marginBottom: {
      xs: "85px",
      sm: "0px",
    },
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    gap: "10px",
  },
  button: {
    fontWeight: "500 !important",
  },
  textPrimary: {
    ml: 1,
  },
});
