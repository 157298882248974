import { SxProps, Theme } from "@mui/material";
import { createNamedStyles } from "../../../../shared/utils";

export const filterStyles = createNamedStyles({
  chip: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    height: 20,
    lineHeight: "100% !important",
    mb: 2,
    mr: 1,
    "&.MuiChip-filledDefault": {
      borderRadius: "4px !important",
      textTransform: "none",
    },
    "&.MuiChip-filledSuccess": {
      bgcolor: "secondary.dark",
      borderRadius: "4px !important",
      textTransform: "none",
      color: "text.white",
    },
  },
  container: {
    bgcolor: "background.default",
    width: (theme) => theme.spacing(54),
    px: 2.5,
    py: 2,
  },
  expandable: {
    mb: 1,
  },
  footer: {
    mt: 2,
  },
  space: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    color: "neutral.grey8",
    fontSize: "14px",
  },
});

export const textButtonStyle = (color: string): SxProps<Theme> => ({
  bgcolor: "transparent",
  border: "none",
  borderRadius: "4px",
  color,
  fontSize: "12px !important",
  height: 36,
  px: 1,
  py: 0,
  "&:active, &:focus": {
    bgcolor: "initial",
    border: "none",
    borderRadius: "4px",
    boxShadow: "none",
    color,
  },
  "&:hover": {
    bgcolor: "rgba(41, 48, 54, 0.04)",
    border: "none",
    borderRadius: "4px",
    boxShadow: "none",
    color,
  },
});
