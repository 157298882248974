import { CustomStyles } from "@shared/interfaces/create_named_styles";

export const developersContainerStyles: CustomStyles = {
  title: {
    color: "#023365",
  },
  avatar: {
    height: "48px",
    width: "48px",
  },
  card: {
    borderRadius: "8px",
    marginTop: "26px",
    padding: "24px",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.08)",
  },
  cardSpacing: {
    padding: "0px !important",
  },
};
