export enum MonitoringConstantIndex {
  AUTHENTICATION_RULES_SUBTITLE = "Configura las reglas de autenticación que aplicarán a las transacciones de este comercio.",
  BALANCER_RULES_SUBTITLE = "Organiza y distribuye la carga de trabajo entre los integrantes del equipo.",
  RULES_3_SUBTITLE = "Aquí podrás editar o crear nuevas reglas de seguridad y listas blancas.",
}

export enum MonitoringRoles {
  M_FRAUD_PREVENTION = "M_FRAUD_PREVENTION",
  MONITORING_SIFTSCIENCE = "Siftscience",
  MONITORING_SIFTSCIENCE_SAVE = "Siftscience.Guardar",
  MONITORING_BALANCER_SAVE = "Balanceador.Guardar",
  MONITORING_SECURITY_RULES = "Reglasseguridad",
  MONITORING_AUTH_RULES = "Reglasautenticacion",
}
