import { IUseAmountFilter, RangeAmount } from "./useAmountFilter.interfaces";
import * as React from "react";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { FilterAmountValueEnum } from "../../../shared/enums/TransactionEnum";
import { IAmountFilter } from "../AmountFilter.interfaces";

export const useAmountFilter = ({
  setRangeAmount,
  setIsApplyFilter,
  isResetFilter,
}: IAmountFilter): IUseAmountFilter => {
  const [valueAmount, setValueAmount] = useState<string>("");
  const [valueAdditionalAmount, setValueAdditionalAmount] =
    useState<string>("");
  const [valueRange, setValueRange] = useState<string>(
    FilterAmountValueEnum.MIN
  );
  const [isBetween, setIsBetween] = useState<boolean>(false);
  const [errorRange, setErrorRange] = useState<boolean>(false);
  const regex = new RegExp("^$|^[0-9]\\d*(\\.\\d+)?$");

  const onChangeRange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValueRange(get(e, "target.value", ""));
  };

  const onChangeAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (regex.test(e.target.value)) {
      setValueAmount(get(e, "target.value", ""));
    }
  };

  const onChangeAdditionalAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (regex.test(e.target.value)) {
      setValueAdditionalAmount(get(e, "target.value", ""));
    }
  };

  useEffect(() => {
    setIsBetween(valueRange === FilterAmountValueEnum.BET);
  }, [valueRange]);

  const buildRangeAmount = (): RangeAmount | undefined => {
    switch (valueRange) {
      case FilterAmountValueEnum.MAX:
        return { max: parseInt(valueAmount) };
      case FilterAmountValueEnum.MIN:
        return { min: parseInt(valueAmount) };
      case FilterAmountValueEnum.EQ:
        return { eq: parseInt(valueAmount) };
      case FilterAmountValueEnum.BET:
        return {
          max: parseInt(valueAdditionalAmount),
          min: parseInt(valueAmount),
        };
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (valueAmount != "" && valueAdditionalAmount != "")
      isBetween &&
        setErrorRange(Number(valueAmount) >= Number(valueAdditionalAmount));
  }, [valueAmount, valueAdditionalAmount]);

  useEffect(() => {
    setRangeAmount(buildRangeAmount()!);
    setIsApplyFilter(false);
  }, [valueRange, valueAmount, valueAdditionalAmount]);

  useEffect(() => {
    if (isResetFilter) {
      setValueRange(FilterAmountValueEnum.MIN);
      setValueAmount("");
      setValueAdditionalAmount("");
    }
  }, [isResetFilter]);

  return {
    errorRange,
    isBetween,
    onChangeAdditionalAmount,
    onChangeAmount,
    onChangeRange,
    valueAdditionalAmount,
    valueAmount,
    valueRange,
  };
};
