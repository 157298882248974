/**
 * Merchant Form Errors Enum
 */

export enum FormErrorsEnum {
  alfanum1to200 = "Debe tener entre 1 y 200 caracteres alfanuméricos",
  maxLength200 = "Debe tener entre 1 y 200 caracteres",
  email = "Correo electrónico inválido",
  phoneNumber = "Debe tener entre 9 y 12 dígitos",
  folio = "Debe tener entre 36 caracteres",
  required = "Campo requerido",
  numeric = "Debe ingresar solo números",
  genericValidation = "Ingresa caracteres válidos",
}
