import { IAppState, IModalConciliation, IModalOmit } from "./reducer";
import { ActionTypes } from "./actionTypes";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import {
  TransactionData,
  TransactionTable,
} from "../../types/transaction_table";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { CountryEnum } from "../shared/infrastructure/CountryEnum";
import { BankConciliationStatusEnum } from "../shared/infrastructure/BankConciliationStatusEnum";
import { TransactionSummaryData } from "../../types/transaction_summary_data";
import axios from "../shared/axios-util";
import { PreSignedUrlResponse } from "../../types/getPresignedUrlResponse";
import { OriginEnum } from "../shared/infrastructure/OriginEnum";
import { environment } from "../environments/environment";
import { PreSignedUrlRequest } from "../../types/getPresignedUrlRequest";
import { get } from "lodash";
import { ConciliationRequest } from "../../types/conciliation_request";
import { IHeaderTable } from "../shared/infrastructure/headers/defaultHeaders";
import { TransactionTableSummary } from "../../types/transaction_table_summary";
import { ConciliationDownloadRequest } from "../../types/conciliation_download_request";
import { downloadUrl } from "../shared/downloadUrl";
import { LoadStatusEnum } from "../shared/infrastructure/LoadStatus";
import { ConciliationDownloadResponse } from "../../types/conciliation_download_response";
import { GetDownloadFileRequest } from "../../types/get_download_file_request";
import { ErrorsWS } from "../shared/infrastructure/constants/BankConciliationConstans";
import CloseIcon from "@material-ui/icons/Close";
import { IDataWebsockets } from "../shared/infrastructure/interfaces/IDataWebSocket";
import { auth } from "../shared/auth";
import { MessagesDownloadEnum } from "../shared/infrastructure/MessagesDownloadEnum";
import { UpdateTransactionSummaryRequest } from "../../types/process_transaction_summary_request";
import { UpdateTransactionRequest } from "../../types/process_transaction_request";
import { UpdateTransactionMessagesEnum } from "../shared/infrastructure/interfaces/UpdateTransactionMessagesEnum";
import { StateEnum } from "../shared/infrastructure/StateEnum";
import CheckIcon from "@material-ui/icons/Check";

export type IAppAction = {
  type: string;
  notification?: INotification;
  conciliationData?: TransactionTable | TransactionTableSummary;
  actualHeaders?: IHeaderTable[];
  isUploadingConciliation?: boolean;
  isUploadedConciliation?: boolean;
  conciliationUploadError?: boolean;
  conciliationFileKey?: string;
  modalConciliation?: IModalConciliation;
  modalOmit?: IModalOmit;
  modalProps?: ConciliationRequest;
  loadStatus?: LoadStatusEnum;
  indexTransactionSummaryTab?: number;
  loadingFileDownload?: boolean;
  openModalDetail?: boolean;
  isLoadingSummaryData?: boolean;
  isLoadingTransactionData?: boolean;
  loadingMassive?: boolean;
};

export const setOpenModalDetail = (payload: boolean) => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_DETAIL,
    openModalDetail: payload,
  };
};

export const setActualHeaders = (payload: IHeaderTable[]) => ({
  type: ActionTypes.SET_ACTUAL_HEADERS,
  actualHeaders: payload,
});

export const setNotification = (payload: INotification) => ({
  type: ActionTypes.SET_NOTIFICATION,
  notification: payload,
});
export const setIsUploadingConciliation = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_UPLOADING_CONCILIATION,
    isUploadingConciliation: payload,
  };
};

export const setIsUploadedConciliation = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_UPLOADED_CONCILIATION,
    isUploadedConciliation: payload,
  };
};
export const setConciliationFileKey = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_CONCILIATION_FILE_KEY,
    conciliationFileKey: payload,
  };
};
export const setConciliationUploadError = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_UPLOAD_CONCILIATION_ERROR,
    conciliationUploadError: payload,
  };
};

export const setModalOmit = (payload: IModalOmit) => ({
  type: ActionTypes.SET_MODAL_OMIT,
  modalOmit: payload,
});

export const setModalConciliation = (payload: IModalConciliation) => ({
  type: ActionTypes.SET_MODAL_CONCILIATION,
  modalConciliation: payload,
});

export const setModalConciliationProps = (payload: ConciliationRequest) => ({
  type: ActionTypes.SET_MODAL_CONCILIATION_PROPS,
  modalProps: payload,
});

export const uploadFileSignedUrl = (
  blob: Blob,
  origin: OriginEnum
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(setIsUploadingConciliation(true));
      dispatch(setIsUploadedConciliation(false));

      const { uploadURL, key } = await getPreSignedUrl(
        origin,
        get(blob, "name", "")
      );
      dispatch(setConciliationFileKey(key));
      const myHeaders = new Headers();

      myHeaders.set("Content-Type", "");

      await fetch(uploadURL, {
        headers: myHeaders,
        method: "PUT",
        mode: "cors",
        body: blob,
      });

      dispatch(setIsUploadingConciliation(false));
      dispatch(setConciliationUploadError(false));
      dispatch(setIsUploadedConciliation(true));
    } catch (e) {
      dispatch(setConciliationUploadError(true));
      dispatch(setIsUploadingConciliation(false));
      dispatch(setIsUploadedConciliation(false));
    }
  };
};
const getPreSignedUrl = async (
  origin: OriginEnum,
  fileName: string
): Promise<PreSignedUrlResponse> => {
  const url: string = `${environment.kushkiUrl}/bank-conciliation/v1/conciliation/document/upload`;
  const uploadRequest: PreSignedUrlRequest = {
    origin,
    fileName,
  };
  const response = await axios.post<PreSignedUrlResponse>(url, uploadRequest);
  return response.data;
};

export const setConciliationData = (
  payload: TransactionTableSummary | TransactionTable
) => {
  return {
    type: ActionTypes.SET_CONCILIATION_DATA,
    conciliationData: payload,
  };
};

export const setConciliationTrxData = (
  payload: TransactionTableSummary | TransactionTable
) => {
  return {
    type: ActionTypes.SET_CONCILIATION_TRX_DATA,
    conciliationData: payload,
  };
};

export const setConciliationDataByOrder = (
  payload: TransactionTable | TransactionTable
) => {
  return {
    type: ActionTypes.SET_CONCILIATION_DATA,
    conciliationData: payload,
  };
};

export const setLoadingMassive = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_MASSIVE,
    loadingMassive: payload,
  };
};

export const orderConciliationData = (
  payload: TransactionTable
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setConciliationDataByOrder(payload));
  };
};

export const generateDataFakeSummary = (
  limit: number,
  page: number
): TransactionSummaryData[] => {
  let data_fake: TransactionSummaryData[] = [];

  for (let new_key: number = 0; new_key < limit; new_key++) {
    data_fake.push({
      id: `16466909167242688${new_key}${page}`,
      conciliationCreated: 1641004196090,
      netAmountTotal: 14.75,
      currencyCode: "MXN",
      liquidationAmount: 2450.0,
      userName: "Planificadora 'La Central' S.A.",
      userId: "83975443897",
      bankConciliationStatus: BankConciliationStatusEnum.CONCILIATION,
      bankConciliationDate: 1641004196090,
      paymentMethod: "card",
      processorName: "Kushki Acquirer Processor",
      country: CountryEnum.mexico,
    });
  }
  return data_fake;
};

export const generateDataFakeTransactions = (
  limit: number
): TransactionData[] => {
  let data_fake: TransactionData[] = [];

  for (let new_key: number = 0; new_key < limit; new_key++) {
    data_fake.push({
      conciliationCreated: 1646690916726,
      netAmount: 14.75,
      currencyCode: "MXN",
      userName: "Planificadora 'La Central' S.A.",
      userId: "83975443897",
      bankConciliationStatus: BankConciliationStatusEnum.CONCILIATION,
      paymentMethod: "card",
      processorName: "Kushki Acquirer Processor",
      country: CountryEnum.mexico,
      approvedTransactionAmount: 16,
      cardType: "AMEX",
      cardBrand: "VISA",
      approvalCode: "ABC123",
      ivaKushkiComission: 1.25,
      bankConciliationDate: 1646690916726,
      kushkiComission: 3.5,
      taxId: "testIDTax",
      transactionId: "TesttRAansactionId" + new_key,
      maskedCardNumber: "123XXXXXXX99",
      merchantName: "Planificadora 'Ejemplo' S.A.",
      merchantId: "merchant123example",
      retention: 5,
      subtotal: 25,
      netLiquidateKushki: 3.33,
      transactionType: "example",
      movementType: "DEBITO",
    });
  }
  return data_fake;
};

export const getConciliationDataSummary =
  (body: object) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const url: string = `${environment.kushkiUrl}/bank-conciliation/v1/conciliation/summary/list`;
    dispatch(
      setConciliationData({
        total: 0,
        data: [],
      })
    );
    try {
      dispatch(setIsLoadingSummaryData(true));
      const response = await axios.post(url, body);
      const data: TransactionSummaryData[] | undefined = response.data.data;
      const total: number | undefined = response.data.total;

      dispatch(
        setConciliationData({
          total: total,
          data: data,
        })
      );
      dispatch(setIsLoadingSummaryData(false));
    } catch (e) {
      dispatch(setIsLoadingSummaryData(false));
    }
  };

export const getConciliationDataTransactions =
  (request: object) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const url: string = `${environment.kushkiUrl}/bank-conciliation/v1/conciliation/transactions/list`;
    dispatch(setIsLoadingTransactionsData(true));
    dispatch(
      setConciliationData({
        total: 0,
        data: [],
      })
    );
    try {
      const resp = await axios.post(url, request);
      dispatch(setConciliationData(resp.data));
      dispatch(setIsLoadingTransactionsData(false));
    } catch (e) {
      dispatch(setIsLoadingTransactionsData(false));
    }
  };
export const setConciliationDataRow =
  (
    conciliationDataRow: TransactionSummaryData
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>,
    getState: () => IAppState
  ): void => {
    const aux_data = { ...getState().conciliationData };
    const index: number =
      (aux_data.data as TransactionSummaryData[])!.findIndex(
        (item: TransactionSummaryData) => item.id === conciliationDataRow.id
      );
    let new_data = [...aux_data.data!];
    if (index > -1) {
      new_data = Object.assign([], [...aux_data.data!], {
        [index]: conciliationDataRow,
      });
    }
    dispatch(
      setConciliationTrxData({
        ...aux_data,
        data: new_data as TransactionSummaryData[],
      })
    );
  };

export const setConciliationDataRowTransaction =
  (
    conciliationDataRow: TransactionData
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>,
    getState: () => IAppState
  ): void => {
    const aux_data = { ...getState().conciliationData };
    const index: number = (aux_data.data as TransactionData[])!.findIndex(
      (item: TransactionData) =>
        get(item, "transaction_id") ===
        get(conciliationDataRow, "transaction_id")
    );
    let new_data = [...aux_data.data!];
    if (index > -1) {
      new_data = Object.assign([], [...aux_data.data!], {
        [index]: conciliationDataRow,
      });
    }
    dispatch(
      setConciliationTrxData({
        ...aux_data,
        data: new_data as TransactionData[],
      })
    );
  };

export const setLoadStatus = (payload: LoadStatusEnum) => ({
  type: ActionTypes.SET_LOAD_STATUS,
  loadStatus: payload,
});

export const getDownloadLink = (
  request: ConciliationDownloadRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(setLoadStatus(LoadStatusEnum.LOADING));

      const response = await axios.post<ConciliationDownloadResponse>(
        `${environment.kushkiUrl}/bank-conciliation/v1/conciliation/document/download`,
        request
      );
      downloadUrl(response.data.url);

      dispatch(setLoadStatus(LoadStatusEnum.READY));
    } catch (e) {
      dispatch(setLoadStatus(LoadStatusEnum.ERROR));
    }
  };
};

export const setLoadingDownloadingProcess = (
  loadingState: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_DOWNLOAD_PROCESS,
    loadingFileDownload: loadingState,
  };
};

export const downloadProcessFileWS = (
  params: GetDownloadFileRequest
): ThunkAction<any, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const showError = showOpenErrorNotification(dispatch);

    let webSocket: WebSocket | undefined;
    try {
      dispatch(setLoadingDownloadingProcess(true));
      webSocket = new WebSocket(
        `${
          environment.kushkiWSUrl
        }/bankconciliationws?Authorization=${auth.getJwtAuth()}`
      );
      webSocket.onopen = () => {
        dispatch(setLoadingDownloadingProcess(true));
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = (_event) => {
        dispatch(setLoadingDownloadingProcess(false));
        showError(ErrorsWS.ERROR_WHEN_DOWNLOADING_FILE);
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const message: string = get(response, "response");
        const messageError = get(response, "message");
        const error = get(response, "error");
        if (!message || messageError || error) {
          dispatch(setLoadingDownloadingProcess(false));
          showError(ErrorsWS.ERROR_WHEN_DOWNLOADING_FILE);
        } else {
          dispatch(setLoadingDownloadingProcess(false));
          downloadUrl(get(response, "response", ""));
          dispatch(
            setNotification({
              message: MessagesDownloadEnum.MESSAGE_DOWNLOAD,
              open: true,
              horizontal: "right",
              vertical: "bottom",
              type: "dark",
              icon: CheckIcon,
            })
          );
        }

        webSocket?.close();
      };
    } catch (e) {
      dispatch(setLoadingDownloadingProcess(false));
      showError(ErrorsWS.ERROR_WHEN_DOWNLOADING_FILE);
      if (webSocket) webSocket.close();
    }
  };
};

const showOpenErrorNotification =
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>) =>
  (errorMessage: string) => {
    dispatch(
      setNotification({
        message: errorMessage,
        open: true,
        horizontal: "right",
        vertical: "top",
        type: "error",
        icon: CloseIcon,
      })
    );
  };

export const indexTransactionSummaryTab =
  (payload: number): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIndexTabEditRetention(payload));
  };
export const setIndexTabEditRetention = (payload: number): IAppAction => {
  return {
    type: ActionTypes.SET_INDEX_TAB_TRANSACTION_SUMMARY,
    indexTransactionSummaryTab: payload,
  };
};

export const setIsLoadingSummaryData = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_SUMMARY_DATA,
    isLoadingSummaryData: payload,
  };
};
export const setIsLoadingTransactionsData = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_TRANSACTION_DATA,
    isLoadingTransactionData: payload,
  };
};

export const processTransactions = (
  transactionObj: UpdateTransactionSummaryRequest | UpdateTransactionRequest,
  isMassive: boolean,
  origin: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    if (isMassive) dispatch(setLoadingMassive(true));
    const showError = showOpenErrorNotification(dispatch);
    try {
      const webSocket = new WebSocket(
        `${
          environment.kushkiWSUrl
        }/bankconciliationws?Authorization=${auth.getJwtAuth()}`
      );
      webSocket.onopen = () => {
        webSocket.send(JSON.stringify(transactionObj));
      };

      webSocket.onerror = (_event) => {
        dispatch(setLoadingMassive(false));
        showError(ErrorsWS.SOCKET_ENDPOINT_ERROR);
        webSocket?.close();
      };
      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const error: any = get(response, "error", []) as any;
        const errorData = get(response, "data.error", []);
        const messageResponse = get(response, "message", []);
        if (
          (Array.isArray(error) && error.length) ||
          errorData.length ||
          error === true ||
          messageResponse == UpdateTransactionMessagesEnum.ERROR_OMIT_MESSAGE
        ) {
          dispatch(setLoadingMassive(false));
          showError(ErrorsWS.SOCKET_ENDPOINT_ERROR);
        } else {
          dispatch(setLoadingMassive(false));
          dispatch(
            setNotification({
              message:
                transactionObj.data.state === StateEnum.CONCILIATION
                  ? origin === OriginEnum.SUMMARY
                    ? UpdateTransactionMessagesEnum.SUCCESS_DEPOSIT_CONCILIATION_MODAL
                    : isMassive
                    ? UpdateTransactionMessagesEnum.SUCCESS_MASSIVE_CONCILIATION_MESSAGE
                    : UpdateTransactionMessagesEnum.SUCCESS_INDIVIDUAL_CONCILIATION_MESSAGE
                  : origin === OriginEnum.SUMMARY
                  ? UpdateTransactionMessagesEnum.SUCCESS_DEPOSIT_OMIT_MESSAGE
                  : isMassive
                  ? UpdateTransactionMessagesEnum.SUCCESS_MASSIVE_OMIT_MESSAGE
                  : UpdateTransactionMessagesEnum.SUCCESS_INDIVIDUAL_OMIT_MESSAGE,
              open: true,
              horizontal: "right",
              vertical: "top",
              type: "success",
              icon: CheckIcon,
            })
          );
        }
        webSocket?.close();
      };
    } catch (e) {
      dispatch(setLoadingMassive(false));
      showError(ErrorsWS.SOCKET_ENDPOINT_ERROR);
    }
  };
};
