import React, { FC } from "react";
import { Alert, Box, Chip, Grid, Typography } from "@mui/material";
import { chargebackDetailStyles } from "./ChargebackDetail.styles";
import { TransactionSection } from "../Sections/TransactionSection/TransactionSection";
import { ChargebackTransactionUploadSection } from "../ChargebackTransactionUploadSection/ChargebackTransactionUploadSection";
import { LabelField, SubtitleSection } from "../../shared/enums/LabelsEnum";
import { get } from "lodash";
import { format } from "date-fns";
import {
  CATALOG_CHARGEBACK_STATUS,
  ChargebackStatusEnum,
} from "../../shared/enums/ChargebackStatusEnum";
import { createStyles, makeStyles } from "@mui/styles";
import { GenericSkeleton } from "../GenericSkeleton/GenericSkeleton";
import { ChargeBackTransactionDetailProps } from "./ChargebackDetail.interfaces";

export const formatDate = (
  trxDate: number | string,
  formatDate: string
): string => {
  try {
    return format(new Date(trxDate), formatDate);
  } catch (e) {
    return "";
  }
};

export const ChargebackDetail: FC<ChargeBackTransactionDetailProps> = (
  props: ChargeBackTransactionDetailProps
) => {
  const styles = makeStyles(() =>
    createStyles({
      chipStateStyle: {
        backgroundColor: (props: ChargeBackTransactionDetailProps) => {
          switch (props.transactionDetails.transaction_status) {
            case ChargebackStatusEnum.INITIALIZED:
              return "#F0F4F8";
            case ChargebackStatusEnum.FAILED:
            case ChargebackStatusEnum.DECLINED:
              return "#e57373";
            default:
              return "inherit";
          }
        },
        borderRadius: 4,
        color: (props: ChargeBackTransactionDetailProps) => {
          switch (props.transactionDetails.transaction_status) {
            case ChargebackStatusEnum.INITIALIZED:
              return "#6D7781";
            case ChargebackStatusEnum.FAILED:
            case ChargebackStatusEnum.DECLINED:
              return "#d32f2f";
            default:
              return "inherit";
          }
        },
      },
    })
  );
  const classes = styles(props);
  const createdDate: string | number = props.transactionDetails.created;

  return (
    <>
      {props.isLoading ? (
        <GenericSkeleton />
      ) : (
        <Grid container key={"grid-container"}>
          <Grid item xs={12} sx={chargebackDetailStyles.chargebackDetail}>
            <Typography variant="h5" sx={chargebackDetailStyles.label}>
              {SubtitleSection.CHARGEBACK_DETAILS}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={chargebackDetailStyles.merchantInfoContainer}>
            <Typography
              variant="h5"
              sx={chargebackDetailStyles.textInformationMerchant}
            >
              {props.transactionDetails.merchant_name} |{" "}
              {props.transactionDetails.merchant_id}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={chargebackDetailStyles.chipContainer}>
            <Chip
              label={
                CATALOG_CHARGEBACK_STATUS[
                  props.transactionDetails.transaction_status!
                ]
              }
              size={"small"}
              className={classes.chipStateStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant={"body2"}
                      sx={chargebackDetailStyles.textDetailChargebackTitle}
                    >
                      {LabelField.CHARGEBACK_NUMBER}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={1}>
                    <Typography
                      sx={chargebackDetailStyles.textDetailChargeback}
                    >
                      {props.transactionDetails.transaction_id}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant={"body2"}
                      sx={chargebackDetailStyles.textDetailChargebackTitle}
                    >
                      {LabelField.START_DATE}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={1}>
                    <Typography variant="body2">
                      {formatDate(createdDate, "dd-MM-yyyy")} |{" "}
                      {formatDate(createdDate, "HH:mm:ss")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} paddingBottom={1} paddingTop={3}>
            <Typography
              variant={"body2"}
              sx={chargebackDetailStyles.titleTransactionData}
            >
              {SubtitleSection.DATA_TRANSACTION}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={chargebackDetailStyles.gridDetailChargeback}>
            <Grid container direction={"row"} spacing={1}>
              <Grid item>
                <Typography
                  variant={"body2"}
                  sx={chargebackDetailStyles.textDetailChargebackTitle}
                >
                  {SubtitleSection.REFERENCE_TRANSACTION}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={chargebackDetailStyles.textTicketNumber}>
                  {get(props.transactionDetails, "transaction_reference", "")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TransactionSection transaction={props.transactionDetails} />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={chargebackDetailStyles.containerSnackbarInformation}
          >
            <Box sx={chargebackDetailStyles.containerSnackbar}>
              <Alert severity="warning">
                {LabelField.SNACKBAR_INFORMATION}
              </Alert>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid>
              <ChargebackTransactionUploadSection
                disabled={false}
                handleRemoveFile={props.handleRemoveFile}
                uploadFilesHandler={props.uploadFilesHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
