import React, { FC } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { ICellActionMenu, IItemList } from "./CellActionMenu.interfaces";
import { defaultTo, get } from "lodash";
import {
  CatalogTranslateTextAction,
  StatusEnum,
} from "../../../shared/enums/StatusEnum";
import { upperFirstLetter } from "../../../shared/utils/string_utils";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { TooltipRefund } from "../../Tooltip/TooltipRefund/TooltipRefund";
import { timeZoneHour } from "../../../shared/utils/transaction_list_utils";
import { auth } from "../../../shared/auth";
import { hiddenOptionTrx } from "../../../shared/utils/transaction_table_utils";

export const CellActionMenu: FC<ICellActionMenu> = ({
  actions,
  status,
  entityName,
  configsCompleted,
  trx,
  handleOpenDialog,
}: ICellActionMenu) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuActions = () => {
    if (
      entityName === EntityNameEnum.CUSTOMER &&
      status === StatusEnum.PENDING
    ) {
      return actions.slice(0, 2);
    } else return actions;
  };

  const getColorByStatus = () => {
    if (entityName === EntityNameEnum.CUSTOMER) {
      return configsCompleted ? "primary" : "secondary";
    } else {
      switch (status) {
        case StatusEnum.PENDING:
          return "secondary";
        default:
          return "primary";
      }
    }
  };

  const builderText = (text: string): string => {
    if (text === "build_by_status") {
      return CatalogTranslateTextAction[defaultTo(status, "")];
    }

    return text;
  };
  const color = getColorByStatus();

  const builderMessageToolTip = () =>
    `Para habilitar las opciones, completa la configuracion de este ${upperFirstLetter(
      entityName
    )}`;

  const enableMenuActionButton = () => {
    if (entityName === EntityNameEnum.CUSTOMER) {
      return configsCompleted;
    } else {
      return status !== StatusEnum.PENDING;
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!auth.isAdmin() && (
        <TooltipRefund
          transaction={trx}
          handleOpenDialogVoid={handleOpenDialog}
          timeZone={timeZoneHour(localStorage.getItem("timeZone")!, "region")}
        />
      )}
      {!hiddenOptionTrx(auth.isInternalKushkiUser(), trx) && (
        <Tooltip
          title={enableMenuActionButton() ? "" : builderMessageToolTip()}
          placement={"left"}
        >
          <IconButton
            data-testid={"btnIconMenuAction"}
            id="btn-action-menu"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => {
              if (enableMenuActionButton()) handleClick(e);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MoreHoriz data-testid={"MoreHorizIcon"} color={color} />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="list-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "btn-action-menu",
        }}
      >
        {menuActions().map((item: IItemList, index: number) => {
          return (
            <MenuItem
              key={`menu-item-${index}`}
              onClick={() => {
                item.action!();
                handleClose();
              }}
            >
              {builderText(get(item, "text", ""))}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
