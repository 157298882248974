import { ICountry } from "../interfaces/ICountry";

export enum CountriesEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
  EL_SALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  EEUU = "EEUU",
}

export enum SpanishCountriesEnum {
  BRASIL = "Brasil",
}

export const COUNTRIES: ICountry[] = [
  {
    value: "Ecuador",
    name: "Ecuador",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
  },
  {
    value: "Colombia",
    name: "Colombia",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
  },
  {
    value: "Peru",
    name: "Perú",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
  },
  {
    value: "Chile",
    name: "Chile",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
  },
  {
    value: "Mexico",
    name: "México",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
  },
  {
    value: "Brazil",
    name: "Brasil",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
  },
  {
    value: "ElSalvador",
    name: "El Salvador",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
  },
  {
    value: "Panama",
    name: "Panamá",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
  },
  {
    value: "CostaRica",
    name: "Costa Rica",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
  },
  {
    value: "Honduras",
    name: "Honduras",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag@2x.png",
  },
  {
    value: "Nicaragua",
    name: "Nicaragua",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag@2x.png",
  },
  {
    value: "Guatemala",
    name: "Guatemala",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag@2x.png",
  },
  {
    value: "EEUU",
    name: "Estados Unidos",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
  },
];
