import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/utils/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { BillingCoreNodeRequest } from "../../../../types/billing_core_node_request";
import { BillingCoreNodeResponse } from "../../../../types/billing_core_node_response";
import { CreateProcessorRequest } from "../../../../types/create_processor_request";
import { CreateProcessorResponse } from "../../../../types/create_processor_response";
import { MerchantProcessor } from "../../../../types/merchant_processor";
import { SetDefaultProcessorRequest } from "../../../../types/set_default_processor_request";
import { SetDefaultProcessorResponse } from "../../../../types/set_default_processor_response";
import { GetProcessorResponse } from "../../../../types/get_processor_respose";
import { GetMerchantProcessorInfoRequest } from "../../../../types/get_merchant_processor_info_request";
import { AxiosResponse } from "axios";

export const getProcessor = createAsyncThunk<
  GetProcessorResponse,
  { processorId: string }
>("app/getProcessor", async ({ processorId }) => {
  const response = await axios.get<GetProcessorResponse>(
    API_ROUTES.GET_PROCESSOR.replace("$processorId", processorId)
  );

  return response.data;
});

export const getMerchantProcessors = createAsyncThunk<
  MerchantProcessor[],
  { merchantId: string }
>("app/getMerchantProcessors", async ({ merchantId }) => {
  const response = await axios.get<MerchantProcessor[]>(
    API_ROUTES.GET_MERCHANT_PROCESSORS.replace("$merchantId", merchantId)
  );

  return response.data;
});

export const getMerchantProcessorInfo = createAsyncThunk<
  GetMerchantProcessorInfoRequest,
  { merchantId: string }
>("app/getMerchantProcessorInfo", async ({ merchantId }) => {
  const response = await axios.get<GetMerchantProcessorInfoRequest>(
    API_ROUTES.GET_PROCESSORS.replace("$merchantId", merchantId)
  );

  return response.data;
});

export const getBillingCoreNodeResponse: AsyncThunk<
  BillingCoreNodeResponse,
  BillingCoreNodeRequest,
  {}
> = createAsyncThunk<BillingCoreNodeResponse, BillingCoreNodeRequest>(
  "app/getBillingCoreResponse",
  async (payload: BillingCoreNodeRequest) => {
    const response = await axios.post<BillingCoreNodeResponse>(
      API_ROUTES.GET_BILLING_CORE_NODE,
      payload
    );

    return response.data;
  }
);

export const createProcessor: AsyncThunk<
  CreateProcessorResponse,
  CreateProcessorRequest,
  {}
> = createAsyncThunk<CreateProcessorResponse, CreateProcessorRequest>(
  "app/createProcessor",
  async (payload: CreateProcessorRequest) => {
    const response = await axios.post<CreateProcessorResponse>(
      API_ROUTES.CREATE_PROCESSOR,
      payload
    );

    return response.data;
  }
);

export const updateProcessor = createAsyncThunk<
  CreateProcessorResponse,
  { processorId: string; payload: CreateProcessorRequest }
>("app/updateProcessor", async ({ processorId, payload }) => {
  const response: AxiosResponse<CreateProcessorResponse> =
    await axios.patch<CreateProcessorResponse>(
      API_ROUTES.UPDATE_PROCESSOR.replace("$processorId", processorId),
      payload
    );

  return response.data;
});

export const setAsDefaultProcessor: AsyncThunk<
  SetDefaultProcessorResponse,
  SetDefaultProcessorRequest,
  {}
> = createAsyncThunk<SetDefaultProcessorResponse, SetDefaultProcessorRequest>(
  "app/setDefaultProcessor",
  async (payload: SetDefaultProcessorRequest) => {
    const merchantId = payload.defaultProcessor.merchantId!;

    delete payload.defaultProcessor.merchantId;

    const response = await axios.post<SetDefaultProcessorResponse>(
      API_ROUTES.SET_DEFAULT_PROCESSOR.replace("$merchantId", merchantId),
      payload
    );

    return response.data;
  }
);
