import React from "react";
import { useStylesConfirmModal } from "./GenericConfirmModal.styles";
import { IGenericConfirmModalProps } from "./GenericConfirmModal.interfaces";
import { Box, Button, Modal, Typography } from "@material-ui/core";

export const GenericConfirmModal: React.FC<IGenericConfirmModalProps> = ({
  handleCloseModal,
  openModal,
  title,
  subTitle,
  buttonText,
}: IGenericConfirmModalProps) => {
  const styles = useStylesConfirmModal();

  return (
    <>
      <Box>
        <Modal
          open={openModal}
          onClose={() => handleCloseModal(false)}
          className={styles.confirmModal}
          data-testid={"modalConfirm"}
        >
          <Box className={styles.confirmModalContainer}>
            <Typography variant={"h5"} className={styles.textStrong}>
              {title}
            </Typography>
            <Typography className={styles.confirmModalBody}>
              {subTitle}
            </Typography>
            <Box className={styles.confirmModalButtonGroup}>
              <Button
                onClick={() => handleCloseModal(false)}
                className={styles.downloadButton}
                variant="contained"
                size="large"
                color="primary"
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
