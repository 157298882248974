import { ChangeEvent, useEffect, useState } from "react";
import {
  IUseAccountPreferences,
  rechargeWalletFormSettings,
} from "./useRechargeWalletFormState.interfaces";
import { IRechargeWalletForm } from "../RechargeWalletForm";
import { IRechargeWalletData } from "../../../../shared/interfaces/RechargeWalletData.interfaces";
import { useGetMerchantCountry } from "../../../../store/hooks/hooks";
import { environment } from "../../../../environments/environment";
import { get } from "lodash";
import {
  CurrencyByCountry,
  CurrencyEnum,
} from "../../../../shared/enums/CurrencyEnum";
import {
  CountryTitleEnumMap,
  IconEnumMap,
  IconHeightEnumMap,
} from "../../../../shared/enums/LabelEnum";

export const useRechargeWalletState = (
  props: IRechargeWalletForm
): IUseAccountPreferences => {
  const [amount, setAmount] = useState<number>(0.0);
  const description = "";
  const [dataFormSettings, setDataFormSettings] =
    useState<rechargeWalletFormSettings>({
      country: useGetMerchantCountry(),
      currency: CurrencyEnum.MXN,
      icon: "",
      iconHeight: 46,
      minimalAmount: 123,
      transferTitle: "",
      walletMinAmounts: environment.walletMinAmount,
    });
  const [name, setName] = useState<string>(
    localStorage.getItem("fullName") || ""
  );
  const [email, setEmail] = useState<string>(
    localStorage.getItem("email") || ""
  );
  const [checkBox, setCheckBox] = useState<boolean>(false);

  const [amountError, setAmountError] = useState<boolean>(false);
  const [minimalAmountError, setMinimalAmountError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailValueError, setEmailValueError] = useState<boolean>(false);
  const [checkBoxError, setCheckBoxError] = useState<boolean>(false);

  const isValidEmail = (value: string) =>
    new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i).test(value);

  const isValidMinimalAmount = (value: number) =>
    value >= dataFormSettings.minimalAmount;

  const setAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    isValidMinimalAmount(+event.target.value) && setMinimalAmountError(false);
    +event.target.value > 0 && setAmountError(false);
    setAmount(+event.target.value);
  };

  const setNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value !== "" && setNameError(false);
    setName(event.target.value);
  };

  const setEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value !== "" && setEmailError(false);
    isValidEmail(event.target.value) && setEmailValueError(false);
    setEmail(event.target.value);
  };

  const setCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked && setCheckBoxError(false);
    setCheckBox(event.target.checked);
  };

  const checkInputsAndRules = () => {
    amount === 0
      ? setAmountError(true)
      : !isValidMinimalAmount(amount) && setMinimalAmountError(true);
    email === ""
      ? setEmailError(true)
      : !isValidEmail(email) && setEmailValueError(true);
    name === "" && setNameError(true);
    !checkBox && setCheckBoxError(true);
  };

  const isValidInputs = (): boolean => {
    return (
      isValidMinimalAmount(amount) &&
      name !== "" &&
      isValidEmail(email) &&
      checkBox
    );
  };

  const saveRechargeWallet = () => {
    checkInputsAndRules();
    const rechargeData: IRechargeWalletData = {
      amount,
      description,
      email,
      name,
    };

    isValidInputs() && props.onRecharge(rechargeData, email);
  };

  useEffect(() => {
    setDataFormSettings((prevState) => {
      return {
        ...prevState,
        currency: CurrencyByCountry[prevState.country],
        icon: IconEnumMap[prevState.country],
        iconHeight: IconHeightEnumMap[prevState.country],
        minimalAmount: get(prevState.walletMinAmounts, prevState.country, 6),
        transferTitle: CountryTitleEnumMap[prevState.country],
      };
    });
    setAmount(
      get(dataFormSettings.walletMinAmounts, dataFormSettings.country, 6)
    );
  }, [dataFormSettings.country]);

  return {
    rechargeWalletData: {
      amount,
      amountError,
      checkBox,
      checkBoxError,
      description,
      email,
      emailError,
      emailValueError,
      minimalAmountError,
      name,
      nameError,
    },
    rechargeWalletDataFormSettings: dataFormSettings,
    saveRechargeWallet,
    setAmountChange,
    setCheckBoxChange,
    setEmailChange,
    setNameChange,
  };
};
