/* istanbul ignore file */

export enum PathsEnum {
  SEARCH_RULE_TOTAL = "searchRuleResponse.total",
  SEARCH_RULE_DATA = "searchRuleResponse.data",
  ID = "id",
  RULE_ID = "rule.id",
  TYPE_RULE = "type",
  RULE_TYPE = "rule.type",
  MERCHANT_NAME = "merchant_name",
  RULE_MERCHANT_NAME = "rule.merchantName",
  RULE_MERCHANT_ID = "rule.merchantId",
  COUNTRY = "country",
  RULE_COUNTRY = "rule.country",
  RULE_SERVICE = "rule.service",
  STATUS = "status",
  R_RULE_STATUS = "rule.rule.status",
  KIND = "kind",
  SUB_KIND = "subKind",
  R_RULE_KIND = "rule.rule.kind",
  ACTIONS = "actions",
  RULE_NAME = "rule.name",
  GENERAL_ACTION = "general_action",
  SUB_TYPE_ELASTIC = "sub_type",
  CREATED = "created",
  IS_FIXED = "isFixed",
  REQUEST_TYPE = "requestType",
  CONFIGURATION_TYPE = "configType",
  SUB_TYPE = "subType",
  ENTITY_NAME = "entityName",
  ENTITY_NAME_ELASTIC = "entity_name",
  GENERAL_ACTION_DYNAMO = "rule.generalAction",
  PROVIDER = "rule.partnerValidator",
  RULE_ACTION = "rule.action",
  PERIODICITY_COUNT = "rule.periodicityCount",
  PERIODICITY_SUM = "rule.periodicitySum",
  LIMIT = "rule.limit",
  HAS_SPECIAL_COND = "rule.hasSpecialCond",
  RULE_HAS_SPECIAL_COND = "rule.rule.hasSpecialCond",
  RULE_LIMIT = "rule.rule.limit",
  MAX_AMOUNT = "rule.maxAmount",
  RULE_KIND = "rule.kind",
  RULE_STATUS = "rule.status",
  MAX_AMOUNT_CURRENCY = "rule.maxAmountCurrency",
  TRANSACTION_STATUS_COUNT = "rule.transactionStatusCount",
  TRANSACTION_STATUS_SUM = "rule.transactionStatusSum",
  SPEED_CONDITION_TRX_COUNT = "speedCondition.trxCount",
  SPEED_CONDITION_AMOUNT_SUM = "speedCondition.amountSum",
  SPEED_CONDITION_STATE_TRX = "speedCondition.stateTrx",
  SPEED_CONDITION_PERIODICITY = "speedCondition.periodicity",
  SPEED_CONDITION_CURRENCY = "speedCondition.currency",
  TRANSACTION_CARD_HOLDER_NAME = "rule.transactionCardHolderName",
}

export enum EndpointsPathsEnum {
  RULES_REQUEST = "rules-request",
  RULES = "rules",
  RULE_REQUEST_BY_ID = "rule-request",
  RULE_BY_ID = "rule",
  RULES_COMPONENTES = "/rule-request-manager/rules",
  RULES_EDIT = "/rule-request-manager/rules/update",
  CATALOG_GENERAL_RULES_REQUEST = "rule-request/search-catalog",
  WHITE_LIST = "/rule-request-manager/rules/whitelist",
}

export enum FilterPathsEnum {
  COUNTRY = "filter.country",
  STATUS = "filter.status",
  ENTITY_NAME = "filter.entity_name",
  GENERAL_ACTION = "filter.general_action",
  SUB_TYPE = "filter.sub_type",
  TYPE = "filter.type",
  CONFIG_TYPE = "filter.config_type",
  RULE_LABEL = "filter.rule_labels",
  RULE_NAME = "filter.rule_name",
}

export enum ModalPathsEnum {
  DISABLE_REJECTED_INFO = "generalRuleInfo",
  GENERAL_COUNTRY_INFO = "generalCountryInfo",
  GENERAL_MERCHANTS_INFO = "generalMerchantInfo",
  GENERAL_MERCHANTS_OWNER_INFO = "generalMerchantOwnerInfo",
  GENERAL_MERCHANTS_HIERARCHY_INFO = "generalMerchantHierarchyInfo",
  GENERAL_MERCHANTS_CUSTOMER_INFO = "generalMerchantCustomerInfo",
  GENERAL_MERCHANTS_BRANCH_INFO = "generalMerchantBranchInfo",
  GENERAL_CONFIG_INFO = "generalConfigInfo",
  GENERAL_CONFIG_INFO_WARNING = "generalConfigInfoWarning",
  CREATED_BY_INFO = "columns.userNameRequest",
  UPDATED_BY_INFO = "columns.userName",
  COUNTRY_INFO_USER_NAME = "generalCountryInfo.columns.userName",
  COMMERCE_OWNER_INFO_USER_NAME = "generalMerchantOwnerInfo.columns.userName",
  COMMERCE_CUSTOMER_INFO_USER_NAME = "generalMerchantCustomerInfo.columns.userName",
  COMMERCE_BRANCH_INFO_USER_NAME = "generalMerchantBranchInfo.columns.userName",
}

export enum WhiteListConditionPathsEnum {
  TOTAL = "total",
  LABEL = "label",
}

export enum ItemCategoryPathsEnum {
  IS_DISABLED = "isDisabled",
  SELECTED = "selected",
}

export enum HierarchyPathsEnum {
  REJECTED_RULE = "entityName",
  SECURITY_RULE = "rule.entityName",
}
