import React from "react";

export const DisableBillingConfigurationIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.374 31.5H9.37402C8.18055 31.5 7.03596 31.0259 6.19204 30.182C5.34813 29.3381 4.87402 28.1935 4.87402 27V9C4.87402 7.80653 5.34813 6.66193 6.19204 5.81802C7.03596 4.97411 8.18055 4.5 9.37402 4.5H12.374C13.5675 4.5 14.7121 4.97411 15.556 5.81802C16.3999 6.66193 16.874 7.80653 16.874 9V27C16.874 28.1935 16.3999 29.3381 15.556 30.182C14.7121 31.0259 13.5675 31.5 12.374 31.5ZM9.37402 7.5C8.9762 7.5 8.59467 7.65804 8.31336 7.93934C8.03206 8.22064 7.87402 8.60218 7.87402 9V27C7.87402 27.3978 8.03206 27.7794 8.31336 28.0607C8.59467 28.342 8.9762 28.5 9.37402 28.5H12.374C12.7718 28.5 13.1534 28.342 13.4347 28.0607C13.716 27.7794 13.874 27.3978 13.874 27V9C13.874 8.60218 13.716 8.22064 13.4347 7.93934C13.1534 7.65804 12.7718 7.5 12.374 7.5H9.37402Z"
        fill="#E2E8F0"
      />
      <path
        d="M27.375 31.5H22.875C21.8804 31.5 20.9266 31.1049 20.2233 30.4017C19.5201 29.6984 19.125 28.7446 19.125 27.75V18.75C19.125 17.7554 19.5201 16.8016 20.2233 16.0983C20.9266 15.3951 21.8804 15 22.875 15H27.375C28.3696 15 29.3234 15.3951 30.0267 16.0983C30.7299 16.8016 31.125 17.7554 31.125 18.75V27.75C31.125 28.7446 30.7299 29.6984 30.0267 30.4017C29.3234 31.1049 28.3696 31.5 27.375 31.5ZM22.875 18C22.6761 18 22.4853 18.079 22.3447 18.2197C22.204 18.3603 22.125 18.5511 22.125 18.75V27.75C22.125 27.9489 22.204 28.1397 22.3447 28.2803C22.4853 28.421 22.6761 28.5 22.875 28.5H27.375C27.5739 28.5 27.7647 28.421 27.9053 28.2803C28.046 28.1397 28.125 27.9489 28.125 27.75V18.75C28.125 18.5511 28.046 18.3603 27.9053 18.2197C27.7647 18.079 27.5739 18 27.375 18H22.875Z"
        fill="#E2E8F0"
      />
    </svg>
  );
};
