import React from "react";
import { Typography } from "@mui/material";
import Layout from "../Layout/Layout";
import ActionBar from "../../components/ActionBar/ActionBar";
import SelectedTransactionsTable from "../../components/SelectedTransactionsTable/SelectedTransactionsTable";
import TransactionsTable from "../../components/TransactionsTable/TransactionsTable";
import { useVehiscoreState } from "./state/useVehiscoreState";
import { vehiscoreStyles } from "./Vehiscore.styles";
import { GenericLoader } from "../../components/common/GenericLoader/GenericLoader";
import { SnackbarAlert } from "../../components/common/SnackbarAlert/SnackbarAlert";
import SelectedTransactionModal from "../../components/modals/SelectedTransactionModal/SelectedTransactionModal";
import ResendRefundModal from "../../components/modals/ResendRefundModal/ResendRefundModal";

export const Vehiscore: React.FC = () => {
  const {
    actions: { onCloseAlert, onCloseSelectedModal },
    areThereSelectedTransactions,
    currentTransaction,
    isSelectedModalOpen,
    messageAlert,
    openRefundModal,
    showAlert,
    typeAlert,
  } = useVehiscoreState();

  return (
    <Layout>
      <Typography variant="h1" color="primary.main">
        Transacciones VehiScore
      </Typography>
      <ActionBar sx={vehiscoreStyles.actionBar} />
      {areThereSelectedTransactions && <SelectedTransactionsTable />}
      <TransactionsTable />
      <GenericLoader />
      <SnackbarAlert
        message={messageAlert}
        type={typeAlert}
        open={showAlert}
        onClose={onCloseAlert}
      />
      <SelectedTransactionModal
        isOpen={isSelectedModalOpen}
        onCloseModal={onCloseSelectedModal}
        transaction={currentTransaction}
      />
      <ResendRefundModal isOpen={openRefundModal} />
    </Layout>
  );
};

export default Vehiscore;
