export const EM_TITLE = "¿Estás seguro de que deseas finalizar esta edición?";
export const EM_DESCRIPTION =
  "Al ejecutar esta acción, todos los datos ingresados quedarán almacenados correctamente.";
export const EM_CONTINUE_BUTTON = "Continuar";
export const EM_FINALIZAR_BUTTON = "Finalizar";
export const LOADER_DESCRIPTION = "Este proceso puede tardar un poco";
export const LOADER_TITLE = "Guardando información";
export const CANCELAR = "Cancelar";

export const BR_TITLE =
  "¿Estás seguro que deseas salir sin guardar los cambios?";
export const BR_DESCRIPTION =
  "Al ejecutar esta acción la información que has ingresado hasta ahora se perderá y se mantendrá la información anterior.";
export const BR_SALIR = "Sí, salir";
