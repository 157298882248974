/**
 * HeaderEnum
 */
export enum HeaderEnum {
  PROCESSOR = "Procesador",
  CARD_BRAND = "Tarjetas",
  PROGRAMMED_DATE = "Fecha programada",
  CONSORTIUM = "Consorcio",
  DEFERRED_TYPE = "Tipo de diferido",
  BANKS = "Bancos",
  MONTHS = "Cuotas",
  MONTHS_OF_GRACE = "Meses de gracia",
  STATE = "Estado",
}

export enum PaginationEnum {
  PAGE_TRANSACTION = " Transacciones por página",
}
