/**
 * MenuItemSteps enum
 */
export enum MenuItemStepsEnum {
  BASIC_DATA = "stepBasicData",
  CONFIG_RATES_INVOICE = "stepConfigRatesAndInvoice",
  RATES_AND_DISCOUNTS = "stepRates",
  PROCESSING = "stepProcessing",
  SERVICES = "stepServices",
  DEVELOPERS = "stepDevelopers",
}
