import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  linkTitle: {
    font: "IBM Plex Sans !important",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
    color: "#677784",
  },
});

interface BreadcrumItem {
  label: string;
  url: string;
}

export interface BreadcrumProps {
  items: BreadcrumItem[];
  lastItem: string;
}

const Breadcrumb = (props: BreadcrumProps) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<ChevronRight size={14} />}
        aria-label="breadcrumb"
      >
        {props.items.map((item: BreadcrumItem) => (
          <Link
            className={styles.linkTitle}
            key={item.label}
            component={RouterLink}
            to={item.url}
          >
            {item.label}
          </Link>
        ))}
        <Typography className={styles.linkTitle}>{props.lastItem}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default Breadcrumb;
