import { ChangeEvent } from "react";
import { get } from "lodash";

export enum FieldNames {
  TOTAL_AMOUNT = "totalAmount",
  DESCRIPTION = "description",
  FILE = "file",
}

export const handleInputValidation = (event: ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;

  if (get(event, "nativeEvent.data", "") !== ".") {
    event.target.value = value.replace(/[^0-9.]/g, "");
  }
};
