import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export interface ListButtons {
  label: string;
  description: string;
  value: string;
  selected: boolean;
}

export interface MerchantNodeState {
  customerList: SearchMerchantResponse;
  searchingMerchants: boolean;
  listButton: ListButtons[];
  selectItems?: ICategory[];
}

export const merchantNodeInitialState: MerchantNodeState = {
  customerList: {
    data: [],
    total: 0,
  },
  listButton: [],
  searchingMerchants: false,
};

const transformData = (data: SearchMerchantResponse): ListButtons[] => {
  return data!.data!.map((x: MerchantNodeData) => {
    return {
      description: x.publicMerchantId!,
      label: x.name!,
      selected: false,
      value: x.nodeId!,
    };
  });
};

export const merchantNodeSlice = createSlice({
  initialState: merchantNodeInitialState,
  name: "merchantNode",
  reducers: {
    resetCustomerList: (state) => {
      state.customerList = {
        data: [],
        total: 0,
      };
      state.listButton = [];
      state.selectItems = [];
    },
    setCustomerList(state, action) {
      state.customerList = action.payload;
      state.listButton = transformData(action.payload);
    },
    setSearchingMerchants(state, action) {
      state.searchingMerchants = action.payload;
    },
    setSelectItems: (state, { payload }: PayloadAction<ICategory[]>) => {
      state.selectItems = payload;
    },
  },
});

export default merchantNodeSlice.reducer;
