import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { ISort } from "@kushki/connect-ui/dist/Shared/Utils/IconSortArrow/IconSortArrow.interfaces";
import { isBackofficeMasterCustomer } from "../utils/getRolesUtils";

export enum HeaderLabel {
  NAME = "Nombre",
  USERNAME = "Nombre de usuario",
  EMAIL = "E-mail",
  ROLE = "Roles",
  BRANCH_ID = "Branch ID",
  MERCHANT_NAME = "Branch Name",
  STATE = "Estado",
}

export enum FieldNameEnum {
  NAME = "name",
  USERNAME = "username",
  EMAIL = "email",
  ROLE = "role",
  BRANCH_ID = "branchId",
  Merchant_NAME = "merchantName",
  ENABLED = "enabled",
}

export enum PaginationLabelEnum {
  USERS_PER_PAGE = "Usuarios por página",
}
export const ROWS_PER_PAGE_OPTIONS: number[] = [10, 20, 50];
export enum TableBodyCellEnum {
  TEXT = "TEXT",
  BASE = "BASE",
  TAG = "TAG",
}

export enum RowsPerPageLabel {
  TWENTY_LIMIT = 20,
  FIFTY_LABEL = 50,
}

export enum ActionsUserTypeEnum {
  PASSWORD = "password",
  MASSIVE_PASSWORD = "massive_password",
  DELETE = "delete",
  MASSIVE_DELETE = "massive_delete",
  EMPTY = "",
}

export enum ModalActionLabels {
  TITLE_PASSWORD = "¿Desea restablecer la contraseña?",
  TITLE_PASSWORD_MASSIVE = "¿Deseas restablecer la contraseña para estos __TOTAL__ usuarios?",
  DESCRIPTION_PASSWORD = "Se enviará un mail a __MAIL__ con la información para realizar el restablecimiento de contraseña del usuario __USER__.",
  DESCRIPTION_PASSWORD_MASSIVE = "Se enviará un mail al correo registrado con la información para realizar el restablecimiento de contraseña de los usuarios seleccionados",
  BUTTON_PASSWORD = "Restablecer contraseña",
  TITLE_DELETE = "¿Desea eliminar este usuario?",
  TITLE_DELETE_MASSIVE = "¿Estas seguro que deseas eliminar estos __TOTAL__ usuarios?",
  DESCRIPTION_DELETE = "Esta operación es irreversible, si eliminas este usuario no podrás recuperar su información.",
  DESCRIPTION_DELETE_MASSIVE = "Esta acción no se podrá deshacer.",
  BUTTON_DELETE = "Eliminar",
  BUTTON_DELETE_MASSIVE = "Eliminar (__TOTAL__)",
  CANCEL_BUTTON = "Cancelar",
}

export const CREDENTIALS_TABLE_COLUMNS_CUSTOMER = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): HeaderCellProps[] => {
  let headerColumns: HeaderCellProps[] = [
    {
      actualSort,
      align: "left",
      field: FieldNameEnum.NAME,
      isSort: true,
      numClick,
      onClickSort,
      spacing: 0,
      text: HeaderLabel.NAME,
      type: "default",
      width: 600,
    },
    {
      actualSort,
      align: "left",
      field: FieldNameEnum.USERNAME,
      isSort: true,
      numClick,
      onClickSort,
      spacing: 0,
      text: HeaderLabel.USERNAME,
      type: "default",
      width: 600,
    },
    {
      actualSort,
      align: "left",
      field: FieldNameEnum.EMAIL,
      isSort: true,
      numClick,
      onClickSort,
      spacing: 0,
      text: HeaderLabel.EMAIL,
      type: "default",
      width: 700,
    },
    {
      actualSort,
      align: "left",
      field: FieldNameEnum.ROLE,
      isSort: true,
      numClick,
      onClickSort,
      spacing: 0,
      text: HeaderLabel.ROLE,
      type: "default",
      width: 500,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderLabel.STATE,
      type: "default",
      width: 300,
    },
    {
      align: "left",
      spacing: 0,
      text: "",
      type: "default",
      width: 0,
    },
  ];

  if (isBackofficeMasterCustomer()) {
    headerColumns = [
      {
        actualSort,
        align: "left",
        field: FieldNameEnum.BRANCH_ID,
        isSort: true,
        numClick,
        onClickSort,
        spacing: 0,
        text: HeaderLabel.BRANCH_ID,
        type: "default",
        width: 600,
      },
      {
        actualSort,
        align: "left",
        field: FieldNameEnum.Merchant_NAME,
        isSort: true,
        numClick,
        onClickSort,
        spacing: 0,
        text: HeaderLabel.MERCHANT_NAME,
        type: "default",
        width: 600,
      },
      ...headerColumns,
    ];
  }

  return headerColumns;
};

export const FIELD_SORT_CATALOGUE = {
  [FieldNameEnum.NAME]: "cells[0].props.line1",
  [FieldNameEnum.USERNAME]: "cells[1].props.line1",
  [FieldNameEnum.EMAIL]: "cells[2].props.line1",
  [FieldNameEnum.ROLE]: "cells[3].props.child.props.items[0]",
};

export const FIELD_SORT_CATALOGUE_CUSTOMER = {
  [FieldNameEnum.BRANCH_ID]: "cell[0].props.line1",
  [FieldNameEnum.Merchant_NAME]: "cell[1].props.line1",
  [FieldNameEnum.NAME]: "cells[2].props.line1",
  [FieldNameEnum.USERNAME]: "cells[3].props.line1",
  [FieldNameEnum.EMAIL]: "cells[4].props.line1",
  [FieldNameEnum.ROLE]: "cells[5].props.child.props.items[0]",
};
