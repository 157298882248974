import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const FormSkeletonStyles = createNamedStyles({
  skeletonText: {
    height: "8vh",
  },
  skeleton: {
    height: "100%",
  },
});
