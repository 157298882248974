import { GetAllModulesResponse } from "../../../types/get_all_modules_response";
import { GetComponentsResponse } from "../../../types/get_components_response";

export const handlePagination = (
  newPage: number,
  newLimitPage: number,
  page: number,
  limit: number,
  list: GetComponentsResponse[] | GetAllModulesResponse[]
) => {
  let auxComponents: GetComponentsResponse[] | GetAllModulesResponse[];

  if (newPage !== page) {
    if (newPage === 1) auxComponents = list.slice(0, limit);
    else auxComponents = list.slice(limit * (newPage - 1), limit * newPage);
  } else {
    if (newLimitPage > list.length)
      auxComponents = list.slice(0, list.length - 1);
    else auxComponents = list.slice(0, newLimitPage);
  }

  return auxComponents;
};

export const handleActionPagination = (
  page: number,
  limit: number,
  list: GetComponentsResponse[] | GetAllModulesResponse[]
) => {
  let auxComponents: GetComponentsResponse[] | GetAllModulesResponse[];

  if (page === 1) auxComponents = list.slice(0, limit);
  else auxComponents = list.slice(limit * (page - 1), limit * page);

  return auxComponents;
};
