import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  setOpenConfirmModal,
  setOpenModalInfo,
  setOpenModalRejected,
  setRequestRuleById,
  setSuccessfulEndpointCall,
  updateRuleRequest,
  updateStatusRule,
} from "../../../store/actionCreators";
import {
  IModalUpdateStatusComponents,
  IModalUpdateStatusProps,
  IUseModalUpdateStatus,
} from "../../../shared/infrastructure/interfaces/IModalRejectComponent";
import {
  defaultTo,
  get,
  isBoolean,
  isString,
  isUndefined,
  set,
  mapValues,
} from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { SearchRequestRuleByIdResponse } from "../../../../types/search_request_rule_by_id_response";
import { Rule, UpdateRuleRequest } from "../../../../types/update_rule_request";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  ConfirmModalEnum,
  MODAL_UPDATE_STATUS_COMPONENTS,
} from "../../../shared/infrastructure/enums/ConfirmModalEnum";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { PathsEnum } from "../../../shared/infrastructure/enums/PathsEnum";
import { TabIndexEnum } from "../../../shared/infrastructure/enums/TabIndexEnum";
import { GetRuleByIdResponse } from "../../../../types/get_rule_by_id";
import { Routes } from "../../../shared/infrastructure/routes";
import { useHistory } from "react-router";

export const UseModalRejectRuleComponentState = (
  props: IModalUpdateStatusProps
): IUseModalUpdateStatus => {
  const modalComponents: IModalUpdateStatusComponents =
    MODAL_UPDATE_STATUS_COMPONENTS[props.tabName as LabelEnum];
  const dispatch = useDispatch();
  const history = useHistory();
  const openModal: boolean | undefined = useSelector(
    (state: IAppState) => state.openModalUpdateStatus
  );
  const openModalInfo: boolean | undefined = useSelector(
    (state: IAppState) => state.openModalInfo
  );
  const [reason, setReason] = useState("");
  const [isDisableState, setDisable] = useState(true);
  const isLoading = useSelector((state: IAppState) => state.isLoadingModal);
  const request: SearchRequestRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.requestRule
  );
  const requestRuleMonitorById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );
  const successfulEndpointCall: boolean = useSelector((state: IAppState) =>
    defaultTo(state.successfulEndpointCall, false)
  );

  useEffect(() => {
    if (successfulEndpointCall) {
      history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);
    }
    dispatch(setSuccessfulEndpointCall(false));
  }, [successfulEndpointCall]);

  const onChangeModal = (): void => {
    if (!openModalInfo) {
      dispatch(setRequestRuleById(undefined));
    }
    dispatch(setOpenModalRejected(false));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setReason(event.target.value);
    setDisable(isDisable());
  };

  const isDisable = (): boolean => {
    return !(
      !isUndefined(get(requestRuleMonitorById, "rule.id", "")) &&
      reason.length > 3
    );
  };
  const handleActionButton = (): void => {
    switch (props.tabName) {
      case LabelEnum.PENDING_REVIEW:
        handleRejectedRule();
        break;
      case LabelEnum.FIRST_LEVEL_RULES:
        handleDisableRule();
        break;
      case LabelEnum.WHITE_LISTS:
        handleDisableRule(TabIndexEnum.WHITE_LISTS_INDEX);
        break;
    }
  };

  const handleRejectedRule = (): void => {
    const payload: UpdateRuleRequest = {
      status: StatusEnum.REJECTED,
      observationInfo: [{ reason, eventName: StatusEnum.REJECTED }],
    };

    props.setAlertSuccessMsg(ConfirmModalEnum.SUCCESS_REJECTED_RULE);
    props.setAlertErrorMsg(ConfirmModalEnum.REJECT_RULE_ERROR);
    dispatch(setOpenModalRejected(false));
    dispatch(setRequestRuleById(undefined));
    dispatch(setOpenModalInfo(false));
    dispatch(
      updateRuleRequest(
        payload,
        get(request, PathsEnum.ID, ""),
        TabIndexEnum.PENDING_REVIEW_INDEX
      )
    );
  };

  const changeValueTypeToString = (rule: Rule): Rule => {
    return mapValues(rule, (value: any) =>
      isString(value) || isBoolean(value)
        ? value
        : defaultTo(value, "").toString()
    );
  };

  const handleDisableRule = (tabIndex?: number): void => {
    const ruleId = get(requestRuleMonitorById, "rule.id", "");
    const rule: Rule = defaultTo(get(requestRuleMonitorById, "rule.rule"), {
      generalAction: "",
      code: "",
      message: "",
      name: "",
    });
    set(rule, "status", StatusEnum.RULES_DISABLED);
    set(rule, "userNameRequest", localStorage.getItem("email")!);

    const payload: UpdateRuleRequest = {
      rule: { ...changeValueTypeToString(rule) },
      status: StatusEnum.RULES_DISABLED,
      observationInfo: [
        {
          reason: reason,
          eventName: StatusEnum.DESACTIVATION,
          updatedAt: Date.now(),
          userName: localStorage.getItem("email")!,
        },
      ],
    };

    props.setAlertSuccessMsg(ConfirmModalEnum.SUCCESS_DISABLE_RULE);
    props.setAlertErrorMsg(ConfirmModalEnum.DISABLE_RULE_ERROR);
    dispatch(updateStatusRule(payload, ruleId, tabIndex));
    dispatch(setOpenModalRejected(false));
    dispatch(setOpenConfirmModal(false));
    dispatch(setOpenModalInfo(false));
  };

  return {
    open: openModal,
    onCloseModal: onChangeModal,
    handleAction: handleActionButton,
    isLoading,
    modalComponents,
    isDisableState,
    handleInputChange,
  };
};
