import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetDispersionRatesConfigResponse } from "../../../../types/get_dispersion_rates_config_response";
import { MerchantData } from "../../../../types/merchant_data";
import { NodeInfoRequest } from "../../../../types/node_info_request";
import { NodeInfoResponse } from "../../../../types/node_info_response";
import { PostDispersionConfigRequest } from "../../../../types/post_dispersion_config_request";
import { PostRatesRequest } from "../../../../types/post_rates_request";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { BillingCoreInfoRequest } from "../../../../types/billing_core_info_request_schema";
import { BillingCoreSemaphoreRequest } from "../../../../types/billing_core_semaphore_request";
import { BillingCoreSemaphoreResponse } from "../../../../types/billing_core_semaphore_response_schema";
import { MerchantResponse } from "../../../../types/merchant_response";
import { GetMerchantRequest } from "../../../../types/get_merchant_request";
import { BillingRatesRequest } from "../../../../types/billing_rates_request_schema";
import { CreateBillingConfig } from "../../../../types/create_billing_config";

const getNodeInfo = createAsyncThunk<NodeInfoResponse, NodeInfoRequest>(
  "app/getNodeInfo",
  async (payload) => {
    const response = await axios.post<NodeInfoResponse>(
      API_ROUTES.GET_NODE_INFO,
      payload
    );

    return response.data;
  }
);

const getNodeInfoInitialData = createAsyncThunk<
  NodeInfoResponse,
  NodeInfoRequest
>("app/getNodeInfoInitialData", async ({ configIds, publicMerchantId }) => {
  const response = await axios.post<NodeInfoResponse>(
    API_ROUTES.GET_NODE_INFO,
    { configIds, publicMerchantId }
  );

  return response.data;
});

const patchMerchantNodeInfo = createAsyncThunk<null, MerchantData>(
  "app/merchantNodeDetails",
  async (payload) => {
    const response = await axios.patch(
      API_ROUTES.PATCH_MERCHANT_NODE_INFO,
      payload
    );

    return response.data;
  }
);

const postHierarchyNodeConfig = createAsyncThunk<
  { payload: BillingCoreInfoRequest; response: boolean },
  BillingCoreInfoRequest
>("app/patchHierarchyNode", async (payload) => {
  const response = await axios.put(API_ROUTES.NODE_BILLING_INFO, payload);

  return { payload, response: response.data };
});

const invokeSemaphoreData = createAsyncThunk<
  BillingCoreSemaphoreResponse,
  BillingCoreSemaphoreRequest
>("app/postSemaphoreData", async (payload) => {
  const response = await axios.post(API_ROUTES.POST_SEMAPHORE, payload);

  return response.data;
});

const ratesBillingConfig = createAsyncThunk<null, BillingRatesRequest>(
  "app/rates/billingConfig",
  async (payload) => {
    const response = await axios.post(API_ROUTES.RATES_BILLING, payload);

    return response.data;
  }
);
const getMerchantNodeInfo = createAsyncThunk<
  MerchantResponse,
  GetMerchantRequest
>("app/getMerchantNodeInfo", async (payload: GetMerchantRequest) => {
  const response = await axios.post<MerchantResponse>(
    API_ROUTES.GET_MERCHANT_NODE_INFO,
    {
      configId: payload.configId,
      publicMerchantId: payload.publicMerchantId,
    }
  );

  return response.data;
});
const getBillingConfig = createAsyncThunk<
  CreateBillingConfig,
  { merchantId: string }
>("billing/getBillingConfig", async (payload: { merchantId: string }) => {
  const response = await axios.get<CreateBillingConfig>(
    API_ROUTES.GET_RATES_BILLING(payload.merchantId)
  );

  return response.data;
});

const getDispersionRatesConfig = createAsyncThunk<
  GetDispersionRatesConfigResponse,
  { merchantId: string }
>(
  "dispersion/getDispersionRatesConfig",
  async (payload: { merchantId: string }) => {
    const response = await axios.post<GetDispersionRatesConfigResponse>(
      API_ROUTES.RATES_DISPERSION,
      { merchantId: payload.merchantId }
    );

    return response.data;
  }
);

const postDispersionConfig = createAsyncThunk<
  null,
  PostDispersionConfigRequest
>(
  "dispersion/postDispersionConfig",
  async (payload: PostDispersionConfigRequest) => {
    const response = await axios.post(
      API_ROUTES.POST_DISPERSION_CONFIG,
      payload
    );

    return response.data;
  }
);

const postRates = createAsyncThunk<null, PostRatesRequest>(
  "rates/postDispersionRates",
  async (payload: PostRatesRequest) => {
    const response = await axios.post(API_ROUTES.POST_RATES, payload);

    return response.data;
  }
);

export {
  getBillingConfig,
  getDispersionRatesConfig,
  getMerchantNodeInfo,
  getNodeInfo,
  getNodeInfoInitialData,
  invokeSemaphoreData,
  patchMerchantNodeInfo,
  postDispersionConfig,
  postHierarchyNodeConfig,
  postRates,
  ratesBillingConfig,
};
