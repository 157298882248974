import { ITabInfo } from "../../components/GenericTabs/GenericTabs.interfaces";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";

export const catalogTabListByTransaction = (
  tabList: ITabInfo[]
): Record<string, object> => {
  const tabsByType: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: {
      [PaymentMethodsEnum.CASH]: tabList,
      [PaymentMethodsEnum.TRANSFER]: tabList,
    },
    [TransactionStatusEnum.APPROVED]: {
      [PaymentMethodsEnum.CASH]: tabList,
      [PaymentMethodsEnum.TRANSFER]: tabList,
    },
    [TransactionStatusEnum.INITIALIZED]: {
      [PaymentMethodsEnum.CASH]: tabList,
      [PaymentMethodsEnum.TRANSFER]: tabList,
    },
    [TransactionStatusEnum.DECLINED]: {
      [PaymentMethodsEnum.CASH]: tabList,
      [PaymentMethodsEnum.TRANSFER]: tabList,
    },
  };

  return tabsByType;
};
