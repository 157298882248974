import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const FileUploadContainerStyles = createNamedStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    height: "fit-content",
    width: "695px",
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CBD5E0FF' stroke-width='1' stroke-dasharray='9%2c 9' stroke-dashoffset='73' stroke-linecap='round'/%3e%3c/svg%3e\");\n" +
      "border-radius: 8px",
  },
  iconFile: {
    color: "rgb(68, 152, 238)",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "rgb(238, 246, 255)",
    borderRadius: "28px",
    gap: "10px",
    padding: "8px",
  },
  fileUploadContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    marginBottom: "16px",
  },
  dragContainer: {
    backgroundColor: "rgba(161,193,236,0.1)",
    height: "100%",
    position: "absolute",
    top: "0px",
    width: "100%",
    borderRadius: "8px",
    transition: "all 300ms linear",
  },
  filesContainer: {
    display: "block",
    width: "80%",
  },
  fileDescription: {
    display: "flex",
    flexDirection: "column",
  },
  uploadContainer: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "36px",
    paddingBottom: "36px",
    width: "80%",
    height: "45%",
  },
  dragUpload: {
    alignItems: "center",
  },
  textUpload: {
    color: "#B4B9BE",
    fontSize: { sm: "14px", md: "16px" },
  },
  circularProgress: {
    color: "#4499EE !important",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "80%",
    height: "45%",
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "95%",
    height: "80%",
    marginTop: "10px",
  },
  name: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "8px",
    height: "56px",
    padding: "0px 10px",
    marginTop: "10px",
    width: "100%",
    boxShadow:
      "rgba(106, 167, 197, 0.15) 0px 8px 17px, rgba(106, 167, 197, 0.075) 0px 1.6px 2.7625px",
  },
  nameText: {
    fontSize: { sm: "14px" },
  },
  crossIcon: {
    padding: "0px",
    margin: "0px",
    width: "25px",
  },
  validateButton: {
    marginTop: "15px",
  },
  alertContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  wrapper: {
    width: "100%",
  },
  validFormat: {
    fontSize: { sm: "13px" },
    color: "#293036",
    textAlign: "left",
    fontWeight: "400",
    width: "250px",
  },
});
