import { CountriesEnum } from "../infrastructure/countries-enum";

export enum ProcessorsCategoryEnum {
  FORMAL = "FORMAL",
}

export enum ProcessorTypeEnum {
  AGGREGATOR = "AGGREGATOR",
  GATEWAY = "GATEWAY",
}

export enum ProcessorsEnum {
  FIS = "Fis Processor",
}

export enum ProcessorAliasEnum {
  FIS = "WorldPay (by FIS)",
}
export const translateProcessor: Record<string, string> = {
  [ProcessorsEnum.FIS]: ProcessorAliasEnum.FIS,
};

export const ProcessorNameEnum: Record<ProcessorTypeEnum, string> = {
  [ProcessorTypeEnum.AGGREGATOR]: "Agregador",
  [ProcessorTypeEnum.GATEWAY]: "Gateway",
};
export const ProcessorCategoryNameEnum: Record<ProcessorsCategoryEnum, string> =
  {
    [ProcessorsCategoryEnum.FORMAL]: "FORMAL",
  };

export const ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY: string[] = [
  CountriesEnum.ECUADOR,
  CountriesEnum.COLOMBIA,
  CountriesEnum.PERU,
  CountriesEnum.MEXICO,
];
