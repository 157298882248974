import React from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { FieldError } from "react-hook-form";
import { IMultipleFormValues } from "../../../shared/constants/initial_state_legal_details";
import { textInputStyles as styles } from "../PercentageInput/PercentageInput.styles";

export type ISelectItemProps = {
  options: IMultipleFormValues[];
  onChange: (value: string) => void;
  disable: boolean;
  label: string;
  value: string;
  error: FieldError | undefined;
  helperText: string | null | undefined;
};
const SelectInput: React.FC<ISelectItemProps> = ({
  onChange,
  disable,
  error,
  label,
  value,
  options,
  helperText,
}: ISelectItemProps) => {
  return (
    <FormControl fullWidth>
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        label={label}
        variant="outlined"
        disabled={disable}
        error={!!error}
        sx={styles.error1}
        select
        helperText={helperText}
        inputProps={{ "data-testid": "selectTest" }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            data-testid={"item-select" + item.value}
          >
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectInput;
