import { Accordion, IconChevronRight, SplitButton } from "@kushki/connect-ui";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import { CustomSkeleton } from "../commons/Skeleton/Skeleton";
import { styles } from "./ResumeContainer.styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import useResumeContainer from "./state/useResumeContainer";
import { IResumeContainer } from "../../containers/MerchantResume/MerchantResume.interface";
import { EntityNameEnum } from "../../shared/enums/entityName";
import { TEXT } from "../../shared/constants/resume_contrainer";
import { Section } from "../../shared/enums";
import { IDefaultItemSelectedEvent } from "./state/useResumeContainer.interface";
import { AccordionLabelsEnum } from "../../shared/enums/AccordionLabelsEnum";
import theme from "../../theme";
import { isUndefined } from "lodash";

const TooltipContainer = ({
  tooltipText,
  component,
}: {
  tooltipText: string;
  component: JSX.Element;
}) => {
  return (
    <Tooltip title={tooltipText} placement="top" arrow>
      <div>{component}</div>
    </Tooltip>
  );
};

const ButtonWithTooltip = ({
  tooltipText,
  ...other
}: {
  tooltipText: string;
  [k: string]: any;
}) => {
  return (
    <TooltipContainer
      tooltipText={tooltipText}
      component={<Button {...other} />}
    />
  );
};

export const ResumeContainer: React.FC<IResumeContainer> = ({
  collapsed = false,
  children,
  title,
  isFromUsers = false,
  isLoading = false,
  iconLeft,
  onClick,
  mode,
  section,
  walletConditions,
}) => {
  const skeleton = (
    <Box data-testid="skeleton-container" sx={styles.skeletonContainer}>
      <IconChevronRight sx={{ color: theme.palette.text["lightGrey"] }} />
      <CustomSkeleton
        sx={{
          width: "95%",
        }}
        variant="h3"
      />
    </Box>
  );
  const {
    canShowWallet,
    isNodeBranch,
    isNodeCustomer,
    isWalletLoading,
    isActiveBranch,
  } = walletConditions || {};
  const sectionsToOmitCentralizeTag = [
    AccordionLabelsEnum.PROSECUTION,
    AccordionLabelsEnum.USERS,
    AccordionLabelsEnum.WALLET,
  ];

  if (isLoading) {
    return skeleton;
  }

  switch (true) {
    case isNodeCustomer:
    case !isUndefined(walletConditions) && !isActiveBranch:
    case !canShowWallet && isWalletLoading && isNodeBranch:
      return <></>;
    case !isWalletLoading && isNodeBranch && !canShowWallet:
      return skeleton;
  }

  const {
    onClickControlCentralization,
    isCentralized,
    entityName,
    configurationItems,
  } = useResumeContainer(section);

  const isEditing = mode === "edition";

  return (
    <Grid sx={styles.accordionTitle} id={"users"}>
      <Box>
        <Accordion
          button={
            isEditing && !isFromUsers ? (
              <ComponentSecurityWrapper>
                {section === Section.PROCESSING ? (
                  <TooltipContainer
                    data-testid="split-button-with-tooltip"
                    tooltipText={
                      entityName === EntityNameEnum.BRANCH
                        ? TEXT.tooltip(isCentralized)
                        : ""
                    }
                    component={
                      <SplitButton
                        items={configurationItems}
                        onItemSelected={(
                          valueSelected: IDefaultItemSelectedEvent
                        ) => onClickControlCentralization(valueSelected)}
                        size="small"
                        text={TEXT.centralize}
                        type="default"
                        variant="primary"
                        disabled={entityName !== EntityNameEnum.CUSTOMER}
                      />
                    }
                  />
                ) : (
                  <ButtonWithTooltip
                    tooltipText={
                      entityName === EntityNameEnum.BRANCH
                        ? TEXT.tooltip(isCentralized)
                        : ""
                    }
                    color={"primary"}
                    variant={"contained"}
                    size="small"
                    data-testid="button-with-tooltip"
                    sx={{
                      "&:Mui-disabled": {
                        pointerEvents: "auto",
                      },
                    }}
                    onClick={onClickControlCentralization}
                    disabled={entityName !== EntityNameEnum.CUSTOMER}
                  >
                    {TEXT.centralize}
                  </ButtonWithTooltip>
                )}
              </ComponentSecurityWrapper>
            ) : null
          }
          divider
          nesting={1}
          nestingLabel=""
          iconLeft={iconLeft}
          text={title}
          isOpen={collapsed}
          onClick={onClick}
          tagText={
            entityName !== EntityNameEnum.BRANCH ||
            sectionsToOmitCentralizeTag.includes(title as AccordionLabelsEnum)
              ? undefined
              : isCentralized
              ? TEXT.centralized
              : TEXT.decentralized
          }
          tagColor={isCentralized ? "primary" : "default"}
        >
          <Grid container sx={styles.container}>
            {children}
          </Grid>
        </Accordion>
      </Box>
    </Grid>
  );
};
