import { snakeCase } from "lodash";

export const camelToSnake = (element: object) => {
  let newObject: object = {};

  for (let i in element) {
    let newKey = snakeCase(i);

    newObject[newKey] = element[i];
  }

  return newObject;
};
