/**
 * Catalog Colombia
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../catalogs-enum";
import { CurrencyEnum } from "../CurrencyEnum";

export const CatalogCo: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    { value: "086", name: "Asocajas" },
    { value: "040", name: "Ban Agrario" },
    { value: "052", name: "Ban AVVillas" },
    { value: "013", name: "Ban BBVA" },
    { value: "057", name: "Ban Colmena" },
    { value: "012", name: "Ban GNB Sudameris" },
    { value: "002", name: "Ban Popular" },
    { value: "000", name: "Ban Republica" },
    { value: "019", name: "Ban Scotiabank Colpatria" },
    { value: "023", name: "Ban Occidente" },
    { value: "805", name: "BANCO BTG PACTUAL" },
    { value: "032", name: "BanCajaSocial" },
    { value: "061", name: "Banco Coomeva" },
    { value: "066", name: "Banco Cooperativo Coopcentral" },
    { value: "558", name: "BANCO CREDIFINANCIERA SA." },
    { value: "001", name: "BANCO DE BOGOTA" },
    { value: "059", name: "Banco de las Microfinanzas-Bancamia S.A." },
    { value: "062", name: "Banco Falabella S.A." },
    { value: "063", name: "Banco Finandina S.A." },
    { value: "071", name: "BANCO J.P. MORGAN COLOMBIA S.A." },
    { value: "047", name: "Banco Mundo Mujer S.A" },
    { value: "060", name: "Banco Pichincha" },
    { value: "065", name: "Banco Santander de Negocios" },
    { value: "069", name: "Banco Serfinanza S.A." },
    { value: "053", name: "Banco W S.A." },
    { value: "007", name: "BanColombia" },
    { value: "042", name: "BNP Paribas Colombia Corp.finan.S.AS" },
    { value: "009", name: "Citibank" },
    { value: "370", name: "COLTEColtefinanciera S.A.FINANCIERA" },
    {
      value: "121",
      name: "FINANCIERA JURISCOOP S.A. COMPANIA DE FINANCIAMIENTO",
    },
    { value: "083", name: "Compensar" },
    { value: "292", name: "Confiar" },
    { value: "082", name: "Coomeva" },
    { value: "283", name: "Coop. Financiera de Antioquia" },
    { value: "291", name: "COOFINEP COOPERATIVA FINANCIERA" },
    { value: "006", name: "Banco Corpbanca Colombia" },
    { value: "289", name: "Cotrafa Cooperativa Financiera" },
    { value: "051", name: "BanDavivienda" },
    { value: "097", name: "DALE" },
    { value: "551", name: "DAVIPLATA" },
    { value: "550", name: "Deceval-DepV" },
    { value: "802", name: "DING Tecnipagos S.A." },
    { value: "683", name: "Dir.TesoroNal" },
    { value: "685", name: "DGCPTN Sistema General de Regalias" },
    { value: "089", name: "Enlace Operativo" },
    { value: "087", name: "Fedecajas" },
    { value: "999", name: "Final" },
    { value: "684", name: "FOGAFIN" },
    { value: "084", name: "Gestion y Contacto S.A." },
    { value: "303", name: "GIROS Y FINANZAS S.A." },
    { value: "014", name: "ITAU" },
    { value: "637", name: "IRIS FINANCIERA DANN REGIONAL" },
    { value: "070", name: "LULO BANK S.A." },
    { value: "067", name: "MIBANCO S.A." },
    { value: "685", name: "MINHACIENDA REGALIAS" },
    { value: "801", name: "MOVII" },
    { value: "507", name: "NEQUI" },
    { value: "101", name: "PSE ProSerEle" },
    { value: "151", name: "Rappipay" },
    { value: "342", name: "Servicios Financieros S.A. Serfinansa" },
    { value: "088", name: "Simple S.A." },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: CurrencyEnum.COP, name: "Pesos" }],
  [CatalogsEnum.Taxes]: [{ value: 0.19, name: "19%" }],
};
