import { IDateTime } from "../../common/DateTimeRangePicker/DateTimeRangePicker.interfaces";
import { selectFiltersState } from "../../../store/selectors/filters/filters";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  setDateFilter,
  setSearchClick,
  setSearchFilter,
} from "../../../store/actions/filters/filters";
import { IDateFilter } from "../../../store/interfaces/FiltersState.interfaces";

export interface ITableHeaderFilterState {
  from: string;
  handlers: {
    onDatePickerChange: (rangeDate: IDateTime) => void;
    onSearchInputChange: (data: string) => void;
    onSearchInputClick: (status: number) => void;
  };
  text: string;
  to: string;
}
export const useTableHeaderFilterState = (): ITableHeaderFilterState => {
  const dispatch = useAppDispatch();
  const {
    date: { from, to },
    search: { text },
  } = useAppSelector(selectFiltersState);

  const onSearchInputClick = (status: number) => {
    dispatch(setSearchClick(status));
  };

  const onSearchInputChange = (inputText: string) => {
    dispatch(setSearchFilter(inputText));
  };

  const onDatePickerChange = ({ from, to }: IDateTime) => {
    const dateFilter: IDateFilter = {
      from,
      timeZone: "",
      to,
    };

    dispatch(setDateFilter(dateFilter));
  };

  return {
    from,
    handlers: {
      onDatePickerChange,
      onSearchInputChange,
      onSearchInputClick,
    },
    text,
    to,
  };
};
