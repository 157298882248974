export enum FinishModalValues {
  TITLE = "No has guardado los diferidos masivos ¿Estas seguro de finalizar la edición?",
  DESCRIPTION = "Si finalizas la edición sin guardar, la información que has ingresado se perderán y no se verá reflejada en los branches. Es necesario que guardes los cambios antes de finalizar la edición.",
  CANCEL_TEXT_BUTTON = "Cancelar",
  FINISH_EDITION_TEXT_BUTTON = "Finalizar edición",
}

export enum SaveDeferredModalValues {
  TITLE = "Se realizará el proceso de guardado de los diferidos.",
  DESCRIPTION = "Este proceso puede tomar más tiempo de lo esperado por lo que se llevará a cabo de forma automática. Una vez concluido el guardado, te enviaremos un correo electrónico a {correo@mail.com} con el detalle de los diferidos que configuraste.",
  UNDERSTOOD_BUTTON = "Entendido",
  REPLACE_EMAIL = "{correo@mail.com}",
}

export const SaveDeferredModalDescription = (mail: string) => {
  return `Esto puede demorar mucho tiempo por lo que el proceso se realizara internamente. Se enviará un correo ${mail} con el detalle  de las reglas de negocio cuando el guardado haya concluido.`;
};

export enum MassiveDeferredAlert {
  ERROR_MSG = "Se presentó un error al momento de guardar. Inténtalo nuevamente.",
}
