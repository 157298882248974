import React from "react";
import { Grid } from "@mui/material";
import { FilterDetailBar, FilterSideBar } from "@kushki/connect-ui";
import { headerFilterUserStyles as styles } from "./HeaderFilterUser.styles";
import { IHeaderFiltersUserProps } from "./HeaderFilterUser.interfaces";
import { useHeaderFilterUser } from "./state/useHeaderFilterUser";
const HeaderFiltersUser: React.FC<IHeaderFiltersUserProps> = (
  props: IHeaderFiltersUserProps
) => {
  const { onChangeFilterDetailBar, filterSideBar, filterDetailBar } =
    useHeaderFilterUser(props);

  return (
    <>
      <Grid item xs={6} sx={styles.buttonContainer}>
        <FilterSideBar {...filterSideBar} />
      </Grid>
      <Grid item xs={12} sx={styles.detailBar}>
        <FilterDetailBar
          filters={filterDetailBar}
          onChangeFilters={onChangeFilterDetailBar}
        />
      </Grid>
    </>
  );
};

export default HeaderFiltersUser;
