export enum CardInformationEnum {
  AMOUNT_REQUESTED = "Monto solicitado",
  APPROVAL_AMOUNT = "Monto Aprobado",
  APPROVED_NUMBER = "No. de Aprobación",
  APPROVAL_NO = "No. de Aprobación",
  AUTHORIZATION_DATA = "Datos de la autorización",
  AWAIT_BANK_CONFIRMATION = "A la espera de la confirmación del banco",
  BANK = "Banco",
  BANK_LEGEND = "El banco se encuentra trabajando en el reembolso. Esto puede tardar un máximo de 45 días",
  BILLING_DATA = "Datos de facturación",
  CARD = "Tarjeta",
  CREDENTIAL = "Credencial",
  CREDENTIAL_ID = "ID de credencial",
  CLIENT = "Cliente",
  CLIENT_ID = "ID del cliente",
  DEFAULT_VALUE = "0.00",
  DESCRIPTION = "Descripción",
  DOC_NUMBER = "Número de Documento",
  FULL_REFUND_PROCESSED = "Reembolso total procesado",
  FULL_REFUND_REQUESTED = "Reembolso Total Solicitado",
  IVA_AMOUNT = "Monto del IVA",
  ICE_AMOUNT = "Monto del ICE",
  KUSHKI_ID = "Kushki ID",
  PARTIAL_REFUND_PROCESSED = "Reembolso parcial procesado",
  PARTIAL_REFUND_REQUESTED = "Reembolso Parcial Solicitado",
  PAYMENT_CREDENTIAL_DATA = "Datos de credencial de pago",
  PAYMENT_METHOD = "Método de pago",
  PROCESSOR = "Procesador",
  PUBLIC_KEY = "Llave pública",
  REQUEST_IN_PROGRESS = "Solicitud en proceso",
  REFUND_DECLINED = "Devolución declinada",
  REQUEST_RECEIVED = "Solicitud recibida",
  REFUND_MADE = "Devolución realizada",
  REFUND_REQUESTED = "Devolución Solicitada",
  RETURNED_AMOUNT = "Monto devuelto",
  RESPONSE_CODE = "Código respuesta",
  RESPONSE_MESSAGE = "Mensaje respuesta",
  SEE = "Ver",
  SUBTOTAL = "Subtotal",
  SUBTOTAL_IVA = "Subtotal IVA",
  SUBTOTAL_IVA_0 = "Subtotal IVA 0",
  TAXES = "Impuestos",
  TRANSACTION_DATA = "Datos de la Transacción",
  TICKET_NUMBER = "Ticket Number",
  TOTAL = "Total",
  TOTAL_REFUND = "Total devuelto",
  TOTAL_TRANSACTION = "Total de la transacción",
  TYPE = "Tipo",
}
