export const enum WebhookEventsEnum {
  approvedTransaction = "approvedTransaction",
  chargeback = "chargeback",
  declinedTransaction = "declinedTransaction",
  failedRetry = "failedRetry",
  lastRetry = "lastRetry",
  subscriptionDelete = "subscriptionDelete",
  declinedSubscription = "declinedSubscription",
  subscriptionDeclined = "subscriptionDeclined",
  approvedSubscription = "approvedSubscription",
  subscriptionApproved = "subscriptionApproved",
  cashPreathorization = "cashPreathorization",
  initialized = "initialized",
  pending = "pending",
  review = "review",
  approval = "approval",
  declined = "declined",
  failed = "failed",
  expired = "expired",
}
