import { Grid, Typography } from "@mui/material";
import React from "react";
import { COUNTRY_TO_CURRENCY } from "../../shared/infrastructure/enums/Currency";
import { formatNumber } from "../../shared/utils/amount";
import { conciliationTabsStyles } from "../ConciliationTabs/ConciliationTabs.styles";

export interface IAmountTotalDetailProps {
  country: string;
  accumulatedAmount: number;
  rowsSelected: string[];
}

const AmountTotalDetail: React.FC<IAmountTotalDetailProps> = (props) => {
  return (
    <>
      <Grid item xs={6}>
        {props.rowsSelected.length > 0 ? (
          <Typography color="primary">
            <span style={{ fontWeight: "bolder" }}>
              {props.rowsSelected.length} Elemento(s) seleccionado(s)
            </span>{" "}
            ¿Qué acción deseas realizar?
            <br />
            Monto total: {formatNumber(props.accumulatedAmount)}{" "}
            {COUNTRY_TO_CURRENCY[props.country]}
          </Typography>
        ) : (
          <Typography sx={conciliationTabsStyles.multipleRefundText}>
            Para <span style={{ fontWeight: "bolder" }}>reembolsar</span> varias
            transacciones (multiselección), selecciona únicamente las de estado
            ‘’pendiente’’ y ‘’en revisión’’.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default AmountTotalDetail;
