import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import {
  getNodeInfo,
  searchMerchantNodeInfo,
} from "../../../store/thunks/general/general.thunk";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import { defaultTo, get, isEmpty, isNumber } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import {
  setNeedRedirect,
  setNotification,
  setPathCustomer,
} from "../../../store/actions/generalData.actions";
import {
  ConfigsEnum,
  ConfigStatusEnum,
} from "../../../shared/enum/ConfigsEnum";
import { IUseWrapperContainerProps } from "../WrapperContainer.interfaces";
import { useSnackbar } from "@kushki/connect-ui";
export const useWrapperContainer = ({ single }: IUseWrapperContainerProps) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const {
    merchantInfo,
    paginationData,
    pathCustomer,
    needRedirect,
    notification,
  } = useAppSelector((state: RootState) => state.generalData);
  const [publicMerchantId] = useState(
    defaultTo(searchParams.get("publicMerchantId"), "")
  );

  useEffect(() => {
    if (single) return;
    dispatch(
      getNodeInfo({ configIds: "cn001", publicMerchantId: publicMerchantId })
    );
  }, []);

  useEffect(() => {
    if (single) return;
    if (isEmpty(pathCustomer))
      dispatch(
        searchMerchantNodeInfo({
          entityName: EntityEnum.CUSTOMER,
          filter: {
            merchantId: publicMerchantId,
          },
          limit: 1,
          offset: 0,
          strictSearch: false,
        })
      );
  }, []);

  useEffect(() => {
    if (single) return;
    const merchantsData: MerchantNodeData[] = get(merchantInfo, "data", []);
    const merchantEntityName: string = get(merchantsData, "[0].entity_name");
    const merchantPath: string = get(merchantsData, "[0].path");
    const rowsPerPage: number = get(paginationData, "rowsPerPage", 0);
    const page: number = get(paginationData, "page", 0);

    if (merchantEntityName === EntityEnum.CUSTOMER) {
      dispatch(
        searchMerchantNodeInfo({
          config: {
            configId: ConfigsEnum.USER_CONFIG,
            status: ConfigStatusEnum.ALL,
          },
          entityName: EntityEnum.BRANCH,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          path: merchantPath,
          strictSearch: false,
        })
      );
      dispatch(setPathCustomer(merchantPath));
    }
  }, [merchantInfo]);

  useEffect(() => {
    if (!isEmpty(needRedirect.path) || isNumber(needRedirect.path)) {
      if (needRedirect.type === "inside") {
        navigate(
          // @ts-ignore
          needRedirect.withQuery
            ? `${needRedirect.path}${
                needRedirect.withQuery ? location.search : ""
              }`
            : needRedirect.path
        );
      } else if (typeof needRedirect.path === "string") {
        window.location.href = needRedirect.path;
      }

      dispatch(setNeedRedirect({ path: "", type: "inside" }));

      return;
    }
  }, [needRedirect]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(null));
    }
  }, [notification]);

  return {};
};
