import { Box, Grid, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash";
import { KeyValue } from "../../../types/key_value";
import React from "react";
import { ICardUploadComponentProps } from "./CardFormUpdloadComponent";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { Catalog, Data } from "../../../types/catalog";
import {
  catalogsDataToKeyValues,
  getDataFromCatalogByName,
} from "../../utils/functions/Catalog";
import { CatalogsNameEnum } from "../../shared/infrastructure/Enums/CatalogsNameEnum";

const useStyles = makeStyles((theme: Theme) => ({
  labelDescription: {
    marginTop: theme.spacing(1),
    fontSize: "18px",
  },
  labelTitle: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  labelDescriptionButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  labelTitleStep: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  contentWidth: {
    maxWidth: "fit-content",
  },
  cardStyle: { backgroundColor: "#F7FAFC", border: "none" },
  subtitle2: {
    ...theme.typography.body1,
    fontSize: "16px",
    fontWeight: 400,
    color: "#6D7781",
  },
  content: {
    marginTop: 10,
    padding: 10,
  },
}));

export const FormIvaComponent: React.FC<ICardUploadComponentProps> = (
  props: ICardUploadComponentProps
) => {
  const classes = useStyles();
  const catalogsList: Catalog[] | undefined = useSelector(
    (state: IAppState) => state.catalogsList
  );
  const taxPayerTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.TAX_PAYER_TYPE
  );
  const activityTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.ACTIVITY
  );
  const taxPayerTypesKeyValues: KeyValue[] = catalogsDataToKeyValues(
    taxPayerTypes
  );
  const activityTypesKeyValues: KeyValue[] = catalogsDataToKeyValues(
    activityTypes
  );
  return (
    <React.Fragment>
      <Grid item xs={10} style={{ margin: "0.5em 0" }}>
        <Box pr={2}>
          <Autocomplete
            disabled={props.editDisable}
            id="size-small-outlined"
            size="small"
            options={taxPayerTypesKeyValues}
            getOptionSelected={(option: KeyValue, value: KeyValue) =>
              option.key === value.key
            }
            getOptionLabel={(option: KeyValue) =>
              `${option.key} - ${option.value}`
            }
            value={get(props, "values.taxpayerValue", {
              key: 0,
              value: "Elegir",
            })}
            onChange={(_event: any, newValue: KeyValue | null) => {
              props.methods.handleOnChangeSelection(newValue, "0");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de contribuyente"
                name="taxpayerType"
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar el tipo de contribuyente.",
                })}
                error={
                  !!props.errors.taxpayerType &&
                  !(get(props, "values.taxpayerValue.key", "").length > 0)
                }
                helperText={
                  props.errors.taxpayerType
                    ? props.errors.taxpayerType.message
                    : ""
                }
                disabled={props.editDisable}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid container spacing={2} className={classes.content} xs={10}>
        <Grid item xs={6}>
          <Autocomplete
            disabled={props.editDisable}
            id="size-small-outlined"
            size="small"
            options={activityTypesKeyValues}
            getOptionLabel={(option: KeyValue) =>
              `${option.key} - ${option.value}`
            }
            getOptionSelected={(option: KeyValue, value: KeyValue) =>
              option.key === value.key
            }
            value={get(props, "values.activityValue", {
              key: 0,
              value: "Elegir",
            })}
            onChange={(_event: any, newValue: KeyValue | null) => {
              props.methods.handleOnChangeSelection(newValue, "1");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Actividad"
                name="activityType"
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar la actividad.",
                })}
                error={
                  !!props.errors.activityType &&
                  !(get(props, "values.activityType.key", "").length > 0)
                }
                helperText={
                  props.errors.activityType
                    ? props.errors.activityType.message
                    : ""
                }
                disabled={props.editDisable}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="autocomplete-iva-porcente"
            size="small"
            options={get(props, "ivaRetention", [])}
            getOptionLabel={(option: any) => option.value}
            getOptionSelected={(option: KeyValue, value: KeyValue) =>
              option.key === value.key
            }
            value={get(props, "values.ivaRetentionValues", {
              key: 0,
              value: "Elegir",
            })}
            onChange={(_event: any, newValue: KeyValue | null) => {
              props.methods.handleOnChangeSelection(newValue, "2");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="text-retentionsiva"
                label="Retención IVA (%)"
                name="ivaRetention"
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar el porcentaje de retención de iva.",
                })}
                error={
                  !!props.errors.ivaRetention &&
                  !(get(props, "values.ivaRetention.key", "").length > 0)
                }
                helperText={
                  props.errors.ivaRetention
                    ? props.errors.ivaRetention.message
                    : ""
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
