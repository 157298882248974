/**
 * CatalogsEnum
 */
export enum ParameterizationBillingEnum {
  SALE = "SALE",
  DEFFERED = "DEFFERED",
  DEFERRED = "DEFERRED",
  CAPTURE = "CAPTURE",
  PREAUTHORIZATION = "PREAUTHORIZATION",
  CHARGEBACK = "CHARGEBACK",
  REFUND = "REFUND",
  VOID = "VOID",
  ADDTIONAL = "additionalServices",
  ADDITIONAL = "ADDITIONAL",
  PAYOUT = "PAYOUT",
}

export const ParameterizationCatalog = [
  {
    name: "Ventas",
    value: ParameterizationBillingEnum.SALE,
  },
  {
    name: "Diferidos",
    value: ParameterizationBillingEnum.DEFFERED,
  },
  {
    name: "Diferidos",
    value: ParameterizationBillingEnum.DEFERRED,
  },
  {
    name: "Captura",
    value: ParameterizationBillingEnum.CAPTURE,
  },
  {
    name: "Pre autorización",
    value: ParameterizationBillingEnum.PREAUTHORIZATION,
  },
  {
    name: "Contracargos",
    value: ParameterizationBillingEnum.CHARGEBACK,
  },
  {
    name: "Reembolsos",
    value: ParameterizationBillingEnum.REFUND,
  },
  {
    name: "Devoluciones",
    value: ParameterizationBillingEnum.VOID,
  },
  {
    name: "Servicios Adicionales",
    value: ParameterizationBillingEnum.ADDTIONAL,
  },
  {
    name: "Servicios Adicionales",
    value: ParameterizationBillingEnum.ADDITIONAL,
  },
  {
    name: "Payouts",
    value: ParameterizationBillingEnum.PAYOUT,
  },
];
