import { setTransactionsPage } from "../../../../../store/actions/filters/filters";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import { selectPaginationFilter } from "../../../../../store/selectors/filters/filters";
import { selectTransactionsLoading } from "../../../../../store/selectors/transactions/transactions";
import { IUseTransactionsTablePaginationState } from "./useTransactionsTablePaginationState.interfaces";

export const useTransactionsTablePaginationState =
  (): IUseTransactionsTablePaginationState => {
    const dispatch = useAppDispatch();
    const areTransactionsLoading = useAppSelector(selectTransactionsLoading);
    const {
      limit,
      page: currentPage,
      resetPagination,
      total,
    } = useAppSelector(selectPaginationFilter);

    const pageCount = Math.ceil(total / limit);

    const handleSetTransactionsPage = (page: number) => {
      if (page !== currentPage) {
        dispatch(setTransactionsPage(page));
      }
    };

    return {
      areTransactionsLoading,
      currentPage,
      handleSetTransactionsPage,
      pageCount,
      resetPagination,
    };
  };
