import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ProcessorBasicInfo } from "../../../../types/processor_basic_info";
import { IProcessorBasicInfo } from "../../../components/InternationalTransferComponent/InternationalTransferComponent.interfaces";
import { ConfigProcessorInfo } from "../../../../types/config_proceesor_info";

import { get } from "lodash";
const getProcessorInfo = createAsyncThunk<ProcessorBasicInfo, string>(
  "layout/getProcessorInfo",
  async (processorId: string) => {
    const response = await axios.get<ProcessorBasicInfo>(
      API_ROUTES.GET_PROCESSOR(processorId)
    );

    return response.data;
  }
);

const getAllProcessorInfo = createAsyncThunk<ProcessorBasicInfo[], string>(
  "layout/getAllProcessorInfo",
  async (merchantId: string) => {
    const response = await axios.get<ProcessorBasicInfo[]>(
      API_ROUTES.GET_ALL_PROCESSOR(merchantId)
    );

    return response.data;
  }
);

const getProcessorDefaults = createAsyncThunk<ConfigProcessorInfo, string>(
  "layout/getProcessorDefaults",
  async (merchantId: string) => {
    const response = await axios.get<ConfigProcessorInfo>(
      API_ROUTES.DEFAULT_PROCESSOR(merchantId)
    );

    return response.data;
  }
);

const createProcessor = createAsyncThunk<boolean, ProcessorBasicInfo>(
  "general/createProcessor",
  async (payload: ProcessorBasicInfo | object) => {
    const response = await axios.post<boolean>(API_ROUTES.CREATE_PROCESSOR, {
      ...payload,
    });

    return response.data;
  }
);

const updateProcessor = createAsyncThunk<boolean, IProcessorBasicInfo>(
  "general/updateProcessor",
  async (data: IProcessorBasicInfo) => {
    const response = await axios.patch<boolean>(
      API_ROUTES.UPDATE_PROCESSOR(data.processorId),
      {
        ...data.payload,
      }
    );

    return response.data;
  }
);

const updateDefaultProcessor = createAsyncThunk<boolean, object>(
  "general/updateDefaultProcessor",
  async (data: object) => {
    const response = await axios.patch<boolean>(
      API_ROUTES.DEFAULT_PROCESSOR(get(data, "merchantId", {})),
      {
        ...get(data, "payload.defaultProcessor", {}),
        publicMerchantId: get(data, "merchantId", {}),
      }
    );

    return response.data;
  }
);

export {
  createProcessor,
  getProcessorInfo,
  updateProcessor,
  getProcessorDefaults,
  updateDefaultProcessor,
  getAllProcessorInfo,
};
