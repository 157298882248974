import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { IEmptyComment } from "@components/atom/EmptyComment/IEmptyComment.interfaces";
import { emptyCommentStyles as styles } from "@components/atom/EmptyComment/EmptyComment.styles";
const EmptyComment: FC<IEmptyComment> = ({ message }: IEmptyComment) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Typography variant={"h6"}>{message}</Typography>
      </Box>
    </Box>
  );
};

export default EmptyComment;
