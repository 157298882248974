import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { BreadcrumProps } from "./Breadcrum.interfaces";

export const useBreadcrumStyles = makeStyles((theme: Theme) => ({
  lastLabel: {
    color: (props: BreadcrumProps) =>
      props.lastItemColor ? props.lastItemColor : "textPrimary",
    fontSize: (props: BreadcrumProps) =>
      props.fontSize ? props.fontSize : theme.spacing(2),
  },
  linkLabel: {
    color: (props: BreadcrumProps) =>
      props.linkColor ? props.linkColor : "primary",
    fontSize: (props: BreadcrumProps) =>
      props.fontSize ? props.fontSize : theme.spacing(2),
  },
}));
