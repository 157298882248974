export enum routes {
  MERCHANT_RESUME = "/merchant-resume",
  CONSOLE_WALLET = "/console-wallet",
  CONSOLE_WALLET_DASHBOARD = "/console-wallet-dashboard",
  DASHBOARD = "/dashboard",
  MERCHANTS = "/merchant-list",
  TRANSACTIONS = "/transactions",
  PAYOUTS_TRANSACTION = "/payouts-transaction",
  CONSOLE_PAYOUTS_TRANSACTION = "/console-payouts-transaction",
  CHARGEBACK = "/chargeback",
  CONSOLE_CHARGEBACK = "/console-chargeback",
  DISPERSION = "/dispersion",
  PAYOUTS_DISPERSION = "/payouts-dispersion",
  USERS = "/users",
  RESELLERS = "/resellers",
  SUBSCRIPTIONS = "/subscriptions-legacy",
  SUBSCRIPTIONS_V2 = "/subscriptions",
  SUBSCRIPTIONS_TRANSFER_OUT = "/subscriptions-v2/transfer-out",
  SMARTLINKS = "/smartlinks",
  BILLING_DASHBOARD = "/billing-dashboard",
  CREATE_MERCHANT = "/create-merchant",
  SNR = "/snr",
  MGUAYAQUIL = "/municipio-guayaquil",
  REPORTS = "/reports",
  AUTH = "/auth",
  PROFILE = "/users/profile",
  GLOBAL_CONFIG = "/global-config",
  MERCHANT_CONFIG = "/config-merchant",
  DEVELOPER = "/merchant-config/developers",
  SUPPORT = "/support",
  MY_MERCHANT = "/my-merchant",
  RULES = "/rules",
  RULE_REQUEST = "/rule-request",
  MONITORING = "/monitoring",
  COMPLIANCE_BETA = "/console-compliance",
  COMPLIANCE_BETA_ACTIVE_ALARMS = "/console-compliance/alarms/alarms",
  COMPLIANCE_BETA_HISTORY_ALARMS = "/console-compliance/alarms/history",
  COMPLIANCE_BETA_EXECUTIVES = "/console-compliance/executives",
  EXECUTOR = "/billing-dashboard/executor",
  VALIDATOR = "/billing-dashboard/validator",
  MANUAL_CONTINGENCY = "/billing-dashboard/manual-contingency",
  CONCILIATION = "/conciliation",
  CONCILIATIONDASHBOARD = "/conciliation-dashboard",
  CONCILIATIONVALIDATOR = "/conciliation-dashboard/validator",
  CONCILIATIONEXECUTOR = "/conciliation-dashboard/executor",
  FRAUD_PREVENTION = "/monitoring",
  VEHISCORE = "/vehiscore",
  REFUNDS_CONTROL = "/b2c-refunds",
  MERCHANT_PEOPLE = "/merchant-people",
  BANK_CONCILIATION_DASHBOARD = "/bank-conciliation",
  BANK_CONCILIATION_TRANSACTIONS = "/bank-conciliation/transactions",
  BANK_CONCILIATION_SUMMARY = "/bank-conciliation/summary",
  RETRY_RULES = "/retry-rules",
  TRANSACTIONAL_CONCILIATION = "/transactional-conciliation",
  WALLET = "/wallet",
  CHECKOUT = "/checkout",
  COSTS = "/costs",
  BILLING_MERCHANT = "/billing-merchant",
  DEFERRED = "/deferred",
  MERCHANT_RATES = "/merchant-rates",
  RATES = "/rate",
  MERCHANT_PROPERTIES = "/merchant-properties",
  HOLIDAYS = "/holidays",
  ETPAY_CUSTOMIZATION = "/etpay-customization",
  WEBHOOK = "/webhook",
  MERCHANT_WEBHOOK = "/merchant-webhook",
  WEBCHECKOUT = "/webcheckout",
  CREDENTIALS = "/credentials",
  PROCESSOR_LIST = "/processor-list",
  PROCESSOR_CARD = "/processor-card",
  PROCESSOR_PAYOUTS_CASH = "/processor-payouts-cash",
  PROCESSOR_PAYOUTS_TRANSFER = "/processor-payouts-transfer",
  PROCESSOR_TRANSFER_SUBSCRIPTION = "/processor-transfer-subscriptions",
  PROCESSOR_CASH = "/processor-cash",
  PROCESSOR_TRANSFER = "/processor-transfer",
  KFORM = "/kform",
  KFORM_LIST = "/kforms-list",
  MERCHANT_DEFERRED = "/merchant-deferred",
  RETENTIONS = "/retentions",
  NODE = "/node",
  CREATE_NODE = "/create-node",
  MERCHANT = "/merchant",
  FINANCIAL_CONFIGURATION = "/financial-configuration",
  LOGIN_INTERNAL = "/login-internal",
  CONFIG_BY_FILE = "/config-file",
  REFUND_DASHBOARD = "/refund-dashboard",
  REFUND_DASHBOARD_EXECUTOR = "/refund-dashboard/executor",
  REFUND_DASHBOARD_VALIDATOR = "/refund-dashboard/validator",
  REFUND_DASHBOARD_VIEWER = "/refund-dashboard/viewer",
  CONTROL_ROLES_DASHBOARD = "/roles-control/modules",
  WALLET_DASHBOARD = "/wallet-dashboard",
  CENTRALIZATION = "/centralization",
  DEVELOPERS = "/developers",
  SERVICE_CONFIG = "/service-configuration",
  CLIENTS = "/clients",
  CONSOLE_RATES = "/console-rates",
  MIGRATION = "/merchant-migration",
  MERCHANT_PREFERENCES = "/merchant-preferences",
  PROCESSING = "/processing",
  BUSINESS_RULES = "/business-rules",
  PAYOUT_BUSINESS_RULES = "/payout-business-rules",
  CONSOLE_USER = "/console-user",
  CONSOLE_TRANSACTIONS = "/console-transactions",
  MERCHANT_PROPERTIES_BATCH = "/merchant-properties/batch",
  CONSOLE_USER_USERS = "/console-user/users",
  CONSOLE_EXPRESS_CHECKOUT = "/express-checkout",
  DASHBOARD_METRICS = "/dashboard-metrics",
  PROCESSOR_PAYOUTS_CARD = "/processor-payouts-card",
}
