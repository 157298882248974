import React from "react";
import { useDateRangePickerState } from "./state/useDateRangePickerState";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";

const DateRangePickerComponent = () => {
  const { value, handleDate } = useDateRangePickerState();
  return (
    <>
      <KshDateRangePicker
        handleDate={handleDate}
        disableFuture={true}
        value={value}
        enableTimePicker
      />
    </>
  );
};
export default DateRangePickerComponent;
