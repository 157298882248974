import {
  TransactionStatusEnum,
  TransactionStatusLabelEnum,
} from "../../enums/TransactionStatusEnum";

export enum FILTER_GROUPS {
  PAYMENT_METHODS = "Medios de pago",
  TYPES = "Tipos",
  STATE = "Estados",
  AMOUNTS = "Montos",
}

export const STATE_ITEM_VALUES: Record<
  TransactionStatusLabelEnum,
  TransactionStatusEnum
> = {
  [TransactionStatusLabelEnum.INITIALIZED]: TransactionStatusEnum.INITIALIZED,
  [TransactionStatusLabelEnum.ON_REVISION]: TransactionStatusEnum.ON_REVISION,
  [TransactionStatusLabelEnum.WON]: TransactionStatusEnum.WON,
  [TransactionStatusLabelEnum.DOCUMENTATION_SENT]:
    TransactionStatusEnum.DOCUMENTATION_SENT,
  [TransactionStatusLabelEnum.LOST]: TransactionStatusEnum.LOST,
  [TransactionStatusLabelEnum.EXPIRED]: TransactionStatusEnum.EXPIRED,
  [TransactionStatusLabelEnum.FAILED]: TransactionStatusEnum.FAILED,
  [TransactionStatusLabelEnum.APPROVAL]: TransactionStatusEnum.APPROVAL,
  [TransactionStatusLabelEnum.DECLINED]: TransactionStatusEnum.DECLINED,
  [TransactionStatusLabelEnum.PENDING]: TransactionStatusEnum.PENDING,
};
