import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { B2CRefunds } from "./containers/B2CRefunds/B2CRefunds";
import { routes } from "./shared/infrastructure/constants/routes";
import { store } from "./store/store";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/enums/SecurityWrapperEnum";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModuleSecurityWrapper
            basePath={environment.kushkiUrl}
            componentId={SecurityWrapperEnum.MODULE_ID}
          >
            <BrowserRouter>
              <Switch>
                <Route path={routes.B2C_REFUNDS} exact component={B2CRefunds} />
              </Switch>
            </BrowserRouter>
          </ModuleSecurityWrapper>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
