import { Box, Chip, Typography } from "@mui/material";
import { IKushkiCardProps } from "./KushkiCard.interfaces";
import { kushkiCardStyles as styles } from "./KushkiCard.styles";
import theme from "../../theme";

export const KushkiCard: React.FC<IKushkiCardProps> = (props) => {
  const { name, role } = props;

  return (
    <Box sx={styles.container}>
      <Box>
        <Typography variant="subtitle2" sx={{ paddingBottom: "10px" }}>
          {name}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text["grey"] }}
        >
          {role}
        </Typography>
      </Box>
      <Box>
        <Chip label="(Comercial Kushki)" color="primary"></Chip>
      </Box>
    </Box>
  );
};
