/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_ECUADOR: IOptionSelection[] = [
  { value: "BANCO DEL AUSTRO S.A.", name: "BANCO DEL AUSTRO S.A." },
  {
    value: "FILIAL LATINOAMERICANA DE TARJETAS DE CREDITO FILA",
    name: "FILIAL LATINOAMERICANA DE TARJETAS DE CREDITO FILA",
  },
  { value: "BANCO DE MACHALA S.A.", name: "BANCO DE MACHALA S.A." },
  { value: "BANCO DE MACHALA", name: "BANCO DE MACHALA" },
  {
    value: "DINERS CLUB DEL ECUADOR S.A. SOCIEDAD FINANCIERA",
    name: "DINERS CLUB DEL ECUADOR S.A. SOCIEDAD FINANCIERA",
  },
  {
    value: "DINERS CLUB DEL ECUADOR",
    name: "DINERS CLUB DEL ECUADOR",
  },
  {
    value: "DINERS CLUB",
    name: "DINERS CLUB",
  },
  { value: "BANCO PICHINCHA C.A.", name: "BANCO PICHINCHA C.A." },
  { value: "BANCO PICHINCHA", name: "BANCO PICHINCHA" },
  {
    value: "COOPERATIVA DE AHORRO Y CREDITO COOPROGRESO LTDA",
    name: "COOPERATIVA DE AHORRO Y CREDITO COOPROGRESO LTDA",
  },
  {
    value: "Cooperativa de Ahorro y Crédito Policía Nacional",
    name: "Cooperativa de Ahorro y Crédito Policía Nacional",
  },
  { value: "BANCO CAPITAL / MANEJA BI", name: "BANCO CAPITAL / MANEJA BI" },
  { value: "Banco Amazonas S.A.", name: "Banco Amazonas S.A." },
  { value: "Banco Amazonas", name: "Banco Amazonas" },
  { value: "BANCO DE MACHALA, S.A.", name: "BANCO DE MACHALA, S.A." },
  { value: "BANCO DE MACHALA", name: "BANCO DE MACHALA" },
  { value: "BANCO DEL AUSTRO, S.A.", name: "BANCO DEL AUSTRO, S.A." },
  { value: "BANCO DEL AUSTRO", name: "BANCO DEL AUSTRO" },
  {
    value: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progresista",
    name: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progresista",
  },
  { value: "BANCO DE GUAYAQUIL", name: "BANCO DE GUAYAQUIL" },
  {
    value: "BANCO DE LA PRODUCCION S.A. (PRODUBANCO)",
    name: "BANCO DE LA PRODUCCION S.A. (PRODUBANCO)",
  },
  {
    value: "BANCO DE LA PRODUCCION",
    name: "BANCO DE LA PRODUCCION",
  },
  {
    value: "ASOCIACION MUTUALISTA DE A/C PARA LA VIVIENDA PICHINCHA",
    name: "ASOCIACION MUTUALISTA DE A/C PARA LA VIVIENDA PICHINCHA",
  },
  { value: "BANCO DEL PACIFICO, S.A.", name: "BANCO DEL PACIFICO, S.A." },
  { value: "BANCO DEL PACIFICO", name: "BANCO DEL PACIFICO" },
  { value: "FILANBANCO, S.A.", name: "FILANBANCO, S.A." },
  { value: "FILANBANCO", name: "FILANBANCO" },
  {
    value: "MASTERCARD - MDS FOR EUROPE DE",
    name: "MASTERCARD - MDS FOR EUROPE DE",
  },
  {
    value: "COOPERATIVA DE AHORRO Y CREDITO POLICIA NACIONAL",
    name: "COOPERATIVA DE AHORRO Y CREDITO POLICIA NACIONAL",
  },
  { value: "BCO TERRITORIAL", name: "BCO TERRITORIAL" },
  {
    value: "COOPERATIVA DE AHORRO Y CRÉDITO OSCUS LTDA",
    name: "COOPERATIVA DE AHORRO Y CRÉDITO OSCUS LTDA",
  },
  { value: "BANCO LA PREVISORA", name: "BANCO LA PREVISORA" },
  { value: "BANCO INTERNACIONAL, S.A.", name: "BANCO INTERNACIONAL, S.A." },
  { value: "BANCO INTERNACIONAL", name: "BANCO INTERNACIONAL" },
  { value: "DINERS CLUB INTERNATIONAL", name: "DINERS CLUB INTERNATIONAL" },
  { value: "PRODUBANCO", name: "PRODUBANCO" },
  { value: "CREDIT AGRICOLE, S.A.", name: "CREDIT AGRICOLE, S.A." },
  { value: "CREDIT AGRICOLE", name: "CREDIT AGRICOLE" },
  { value: "CITIBANK USA, N.A.", name: "CITIBANK USA, N.A." },
  { value: "CITIBANK USA", name: "CITIBANK USA" },
  {
    value: "CARD SERVICES FOR CREDIT UNIONS, INC.",
    name: "CARD SERVICES FOR CREDIT UNIONS, INC.",
  },
  {
    value: "CARD SERVICES FOR CREDIT UNIONS",
    name: "CARD SERVICES FOR CREDIT UNIONS",
  },
  { value: "BANCO SOLIDARIO S.A.", name: "BANCO SOLIDARIO S.A." },
  { value: "BANCO SOLIDARIO", name: "BANCO SOLIDARIO" },
  { value: "DISCOVER DINERS CLUB", name: "DISCOVER DINERS CLUB" },
  {
    value: "BANCO FINCA/ COOP AHORRO Y CRÉDITO RIOBAMBA / MANEJA BI",
    name: "BANCO FINCA/ COOP AHORRO Y CRÉDITO RIOBAMBA / MANEJA BI",
  },
  { value: "BANCO DEL AZUAY", name: "BANCO DEL AZUAY" },
  { value: "ASSOCIATED BANK, N.A.", name: "ASSOCIATED BANK, N.A." },
  { value: "ASSOCIATED BANK", name: "ASSOCIATED BANK" },
  { value: "BANCO AMAZONAS, S.A.", name: "BANCO AMAZONAS, S.A." },
  { value: "BANCO AMAZONAS", name: "BANCO AMAZONAS" },
  { value: "BANCO DE GUAYAQUIL S.A.", name: "BANCO DE GUAYAQUIL S.A." },
  { value: "BANCO DE LOJA S.A.", name: "BANCO DE LOJA S.A." },
  { value: "BANCO DE LOJA", name: "BANCO DE LOJA" },
  { value: "BANCO DEL PICHINCHA, C.A.", name: "BANCO DEL PICHINCHA, C.A." },
  { value: "BANCO DEL PICHINCHA", name: "BANCO DEL PICHINCHA" },
  { value: "SERVICIOS BANKCARD, S.A.", name: "SERVICIOS BANKCARD, S.A." },
  { value: "SERVICIOS BANKCARD", name: "SERVICIOS BANKCARD" },
  {
    value: "CHINA MINSHENG BANKING CORP., LTD.",
    name: "CHINA MINSHENG BANKING CORP., LTD.",
  },
  {
    value: "CHINA MINSHENG BANKING CORP",
    name: "CHINA MINSHENG BANKING CORP",
  },
  { value: "MASTERCARD ECUADOR", name: "MASTERCARD ECUADOR" },
  {
    value: "BANCO PROCREDIT ECUADOR S.A.",
    name: "BANCO PROCREDIT ECUADOR S.A.",
  },
  {
    value: "BANCO PROCREDIT ECUADOR",
    name: "BANCO PROCREDIT ECUADOR",
  },
  { value: "BANK OF AMERICA", name: "BANK OF AMERICA" },
  { value: "ICUL SERVICE CORPORATION", name: "ICUL SERVICE CORPORATION" },
  {
    value: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progres",
    name: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progres",
  },
  {
    value: "ASOCIACION MUTUALISTA DE AHORRO Y CREDITO PARA LA VIVIENDA A",
    name: "ASOCIACION MUTUALISTA DE AHORRO Y CREDITO PARA LA VIVIENDA A",
  },
  {
    value: "CAPITAL BANK OF JORDAN PLC. CO.",
    name: "CAPITAL BANK OF JORDAN PLC. CO.",
  },
  {
    value: "CAPITAL BANK OF JORDAN",
    name: "CAPITAL BANK OF JORDAN",
  },
  { value: "FISERV SOLUTIONS, INC.", name: "FISERV SOLUTIONS, INC." },
  { value: "FISERV SOLUTIONS", name: "FISERV SOLUTIONS" },
  { value: "WESTPAC BANKING CORPORATION", name: "WESTPAC BANKING CORPORATION" },
  {
    value: "BANCO GENERAL RUMINAHUI, S.A.",
    name: "BANCO GENERAL RUMINAHUI, S.A.",
  },
  {
    value: "BANCO GENERAL RUMINAHUI",
    name: "BANCO GENERAL RUMINAHUI",
  },
  { value: "Banco Solidario S.A.", name: "Banco Solidario S.A." },
  {
    value: "BANCO DE LA PRODUCCION,S.A. (PRODUBANCO)",
    name: "BANCO DE LA PRODUCCION,S.A. (PRODUBANCO)",
  },
  { value: "BANCO PROMERICA, S.A.", name: "BANCO PROMERICA, S.A." },
  { value: "BANCO PROMERICA", name: "BANCO PROMERICA" },
  {
    value: "COOP 29 DE OCTUBRE/ PATROCINIO VAZCORP",
    name: "COOP 29 DE OCTUBRE/ PATROCINIO VAZCORP",
  },
  { value: "BANCO DE GUAYAQUIL, S.A.", name: "BANCO DE GUAYAQUIL, S.A." },
  { value: "BANCO DE GUAYAQUIL", name: "BANCO DE GUAYAQUIL" },
  {
    value: "Banco Comercial de Manabi S.A.",
    name: "Banco Comercial de Manabi S.A.",
  },
  {
    value: "Banco Comercial de Manabi",
    name: "Banco Comercial de Manabi",
  },
  { value: "BANCO BOLIVARIANO C.A.", name: "BANCO BOLIVARIANO C.A." },
  {
    value: "COOPERATIVA DE AHORRO Y CREDIT O OSCUS LTDA",
    name: "COOPERATIVA DE AHORRO Y CREDIT O OSCUS LTDA",
  },
  {
    value: "BANCO GENERAL RUMINAHUI S.A.",
    name: "BANCO GENERAL RUMINAHUI S.A.",
  },
  { value: "COOP ATUNTAQUI / MANEJA BI", name: "COOP ATUNTAQUI / MANEJA BI" },
  { value: "BANCO DE CREDITO, S.A.", name: "BANCO DE CREDITO, S.A." },
  { value: "BANCO DE CREDITO", name: "BANCO DE CREDITO" },
  { value: "BANCO INTERNACIONAL S.A.", name: "BANCO INTERNACIONAL S.A." },
  { value: "BCO MACHALA", name: "BCO MACHALA" },
  { value: "COOP ANDALUCÍA / MANEJA BI", name: "COOP ANDALUCÍA / MANEJA BI" },
  {
    value: "BANCO DE LA PRODUCCIONS.A. (PRODUBANCO)",
    name: "BANCO DE LA PRODUCCIONS.A. (PRODUBANCO)",
  },
  { value: "BANCO TERRITORIAL, S.A.", name: "BANCO TERRITORIAL, S.A." },
  { value: "BANCO TERRITORIAL", name: "BANCO TERRITORIAL" },
  {
    value: "BANCO DE CREDITO E HIPOTECARIO, S.A.",
    name: "BANCO DE CREDITO E HIPOTECARIO, S.A.",
  },
  {
    value: "BANCO DE CREDITO E HIPOTECARIO",
    name: "BANCO DE CREDITO E HIPOTECARIO",
  },
  { value: "BANCO BOLIVARIANO, C.A.", name: "BANCO BOLIVARIANO, C.A." },
  { value: "BANCO BOLIVARIANO", name: "BANCO BOLIVARIANO" },
  {
    value: "ASOCIACION PARA LA VIVIENDA MUTUALISTA PICHINCHA",
    name: "ASOCIACION PARA LA VIVIENDA MUTUALISTA PICHINCHA",
  },
  { value: "MONEY ACCESS SERVICE, INC.", name: "MONEY ACCESS SERVICE, INC." },
  { value: "MONEY ACCESS SERVICE", name: "MONEY ACCESS SERVICE" },
  { value: "MUTUALISTA PICHINCHA (MC)", name: "MUTUALISTA PICHINCHA (MC)" },
  { value: "MUTUALISTA PICHINCHA", name: "MUTUALISTA PICHINCHA" },
];

export const CURRENCY_ECUADOR: IOptionSelection[] = [
  { value: CurrencyEnum.USD, name: CurrencyEnum.USD },
];
