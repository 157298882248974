import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const modalComponentStyles = createNamedStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 1,
  },
  divider: {
    border: "1px solid #E2E8F0 !important",
  },
});
