import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid, Typography, withStyles } from "@material-ui/core";

const LabelText = withStyles({
  root: {
    fontWeight: 500,
    fontSize: "14px",
  },
})(Typography);

export const SkeletonHeader: React.FC = () => (
  <React.Fragment>
    <Skeleton variant="rect" width="50%" height={50} />
    <Grid container>
      <Grid item xs={12} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid container>
          <Grid item>
            <Skeleton variant="rect" width={120} height={20} />
          </Grid>
          <Grid item>
            <Skeleton
              style={{ marginLeft: 5 }}
              variant="rect"
              width={70}
              height={20}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item>
            <Skeleton variant="circle" width={30} height={30} />
          </Grid>
          <Grid item>
            <Skeleton
              variant="rect"
              width={120}
              height={20}
              style={{ marginTop: 5, marginLeft: 5 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        alignItems="flex-end"
        direction="column"
      >
        <div>
          <LabelText>ID de Comercio</LabelText>
          <Grid container direction="row" justify="flex-end">
            <Skeleton variant="rect" width={140} height={20} />
          </Grid>
        </div>
      </Grid>
    </Grid>
  </React.Fragment>
);
