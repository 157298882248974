import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../src/shared/axios-util";
import { API_ROUTES } from "../../../../shared/constants/api_routes";
import { ITransaction } from "../../../interfaces/TransactionsState.interfaces";

const getTransactionsPendingReview = createAsyncThunk(
  "transactions/getTransactionsPendingReview",
  async () => {
    const responseTransactionsPending = await axios.post<ITransaction[]>(
      API_ROUTES.TRANSACTIONS_PENDING_REVIEW
    );

    return responseTransactionsPending.data;
  }
);

export default getTransactionsPendingReview;
