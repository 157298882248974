import React, { FC } from "react";
import DispersionForm from "../../components/DispersionForm/DispersionForm";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import { ROUTES } from "../../shared/constants/routes";
import { SnackBarProvider } from "@kushki/connect-ui";
import BankAccountSection from "../../components/BankAccountSection/BankAccountSection";
import WrapperMassiveContainer from "../../components/WrapperMassiveContanier/WrapperMassiveContainer";
import { useMassiveDispersionContainer } from "./state/useMassiveDispersionContainer";

const MassiveDispersionContainer: FC = () => {
  const {
    form,
    showBankData,
    isExclusiveMassive,
    setIsExclusiveMassive,
    loading,
  } = useMassiveDispersionContainer();

  return (
    <WrapperMassiveContainer currentRoute={ROUTES.MASSIVE_DISPERSION}>
      <FormWrapper title={"Dispersión"}>
        <SnackBarProvider>
          <DispersionForm
            form={form}
            isBranchCentralized={false}
            massive={true}
            loading={loading}
            setSwitchValue={setIsExclusiveMassive}
            switchState={isExclusiveMassive}
          />
          {showBankData && (
            <BankAccountSection isCentralizedBranch={false} massive={true} />
          )}
        </SnackBarProvider>
      </FormWrapper>
    </WrapperMassiveContainer>
  );
};

export default MassiveDispersionContainer;
