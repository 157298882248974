import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const alarmFiltersStyles = createNamedStyles({
  root: {
    display: "flex",
    gap: "30px",
    margin: "30px 30px 20px 0px",
  },
  autocomplete: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "16px",
    },
    width: {
      sm: "200px",
      md: "380px",
    },
  },
  rangeDatePicker: {
    marginRight: "15px",
    "&#rangeDate  button": {
      width: "100%",
      backgroundColor: "transparent",
    },
    "&#rangeDate  button h6": {
      color: "#78828c !important",
    },
  },
});
