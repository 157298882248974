export const INFORMATION_CUSTOMER_LABELS = {
  BACK_BUTTON_LBL: "Regresar",
  BUTTON_CONTINUE: "Continuar",
  BUTTON_VALIDATE: "Validar",
  CHECKBOX_COUNTRY_CONSTITUTION_LABEL:
    "El país donde opera el customer es el mismo donde está constituido.",
  CLIENT_TYPE: "Tipo de cliente",
  COUNTRY_CHANGE_INFORMATION:
    "El país de constitución no puede ser modificado, ya que al hacerlo afectarías toda su configuración.",
  COUNTRY_CONSTITUTION: "País de constitución",
  COUNTRY_OPERATION: "País de operación",
  CUSTOMER_CHANGE_INFORMATION:
    "Si cambias el país de operación se perderá la información registrada en Dispersión o Cobro, según sea el caso.",
  CUSTOMER_COMMERCIAL_TEXTBOX: "Nombre comercial",
  CUSTOMER_INFORMATION:
    "Valida que la información sea correcta pues el país de constitución y el país operación no podrán ser modificado posteriormente ya que al hacerlo se afectará toda la configuración del Customer.",
  CUSTOMER_SUBTITLE_CREATE: "Ingresa los datos para crear el customer.",
  CUSTOMER_TITLE: "Datos del customer",
  DESCRIPTION:
    "El customer concentra toda la información global del cliente, así como los datos de sus branches",
  EDIT_BRANCH_TITLE: "Edita los datos del Branch",
  EDIT_CUSTOMER_TITLE: "Edita los datos del Customer",
  FINISH_EDIT: "Finalizar edición",
  LOADER_MODAL_DESCRIPTION: "Este proceso puede tardar un poco...",
  LOADER_MODAL_TITLE: "Guardando información",
  MODAL_EDIT_BODY:
    "Al realizar la modificación, la información principal del customer se ajustará de forma inmediata.",
  MODAL_EDIT_TITLE: "¿Estás seguro de actualizar los datos del customer?",
};
