import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const massiveUserContainerStyles = createNamedStyles({
  cardStyle: {
    boxShadow:
      "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
    borderRadius: "16px",
  },
  contentStyle: {
    margin: "2em",
  },
  headerStyle: {
    height: "100%",
    width: "100%",
    paddingTop: "48px",
    alignContent: "flex-start",
    marginBottom: "35px",
  },
  boxTitle: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: { sm: "26px" },
    lineHeight: "120%",
    color: "#023365",
    paddingBottom: "50px",
    paddingTop: "1em",
  },
  stepContainer: {
    display: "flex",
    width: "100%",
  },
  stepStyle: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: { sm: "20px" },
    alignContent: "flex-start",
    color: "#023365",
    width: "80px",
  },
  download: {
    display: "flex",
  },
  textStyle: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: { sm: "16px" },
    letterSpacing: "0.0025em",
    color: "#023365",
    paddingTop: "10px",
    marginBottom: "10px",
    marginRight: "20px",
  },
  secondText: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: { sm: "16px" },
    lineHeight: "150%",
    color: "#023365",
  },

  gridButtonBack: {
    width: "100%",
    display: "flex",
    marginTop: "12px",
  },
  contentBack: {
    marginBottom: "10px",
    paddingLeft: "40px !important",
  },
  iconArrow: {
    color: "#4399ED",
    fontSize: "17px",
    paddingLeft: 0,
    "&:hover": {
      background: "transparent",
    },
    "&& .MuiTouchRipple-rippleVisible": {
      animationDuration: "20000ms",
    },
  },
  doubtsText: {
    color: "#6D7781",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
  },
  promptText: {
    display: "initial",
    fontWeight: "500 !important",
    textDecoration: "underline",
    cursor: "pointer",
  },
});
