export enum CountriesEnum {
  EL_SALVADOR = "ElSalvador",
  GUATEMALA = "Guatemala",
  HONDURAS = "Honduras",
  NICARAGUA = "Nicaragua",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  CENTRO_AMERICA = "CentroAmerica",
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  MEXICO = "Mexico",
}
