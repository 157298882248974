import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import * as labels from "../../shared/constants/labels/add_webhook_labels";
import { formActionsStyles as styles } from "./FormActions.styles";
import { IFormActionsProps } from "./FormAction.interfaces";

const FormActions: FC<IFormActionsProps> = ({
  handleOnCancel,
  handleOnAccept,
}: IFormActionsProps) => {
  return (
    <Box sx={styles.buttonContainer}>
      <Button
        sx={styles.acceptButton}
        variant={"contained"}
        onClick={handleOnAccept}
      >
        {labels.SAVE_BUTTON_LABEL}
      </Button>
      <Button
        sx={styles.cancelButton}
        variant={"text"}
        color={"primary"}
        onClick={handleOnCancel}
      >
        {labels.CANCEL_BUTTON_LABEL}
      </Button>
    </Box>
  );
};

export default FormActions;
