import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IPostWebhooksListRequest } from "../../../../types/post_webhooks_list_request";
import { IPostWebhooksListResponse } from "../../../../types/post_webhooks_list_response";

const getWebhookList = createAsyncThunk<
  IPostWebhooksListResponse,
  IPostWebhooksListRequest
>("developers/getWebhookList", async (payload) => {
  const response = await axios.post<IPostWebhooksListResponse>(
    API_ROUTES.LIST_WEBHOOK,
    payload
  );

  return response.data;
});

export { getWebhookList };
