export enum StylesEnum {
  TOP = "top",
  CENTER = "center",
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  FILLED = "filled",
  INHERIT = "inherit",
  PRIMARY = "primary",
}
