import { CountryEnum } from "../countryEnum";
import { numberTypePerson } from "../enums/RepresentativeContainerEnum";
import {
  countriesKushki,
  countriesNaturalJuridic,
  countriesPhysicMoral,
  CountryTypePerson,
} from "./typePersonCountryEnum";

export const setPersonType = (
  country: string = CountryEnum.generic,
  numberPerson: string = numberTypePerson.natural
): string => {
  const isGenericCountry: boolean = !countriesKushki.includes(country);

  const countryPerson: string = isGenericCountry
    ? CountryEnum.generic
    : country;

  if (numberPerson === numberTypePerson.natural) {
    if (countriesNaturalJuridic.includes(countryPerson)) {
      return CountryTypePerson.natural;
    }

    return CountryTypePerson.physic;
  }

  if (countriesPhysicMoral.includes(countryPerson)) {
    return CountryTypePerson.moral;
  }

  return CountryTypePerson.juridic;
};
