import React, { FC } from "react";
import BankAccountFormsModal from "../Modals/BankAccountFormsModal/BankAccountFormsModal";
import AccordionBanksAccounts from "../AccordionBanksAccounts/AccordionBanksAccounts";
import ModalDialog from "../Modals/ModalDialog/ModalDialog";
import { useBankAccountSectionState } from "./state/useBankAccountSectionState";
import { BankAccountSectionProps } from "./BankAccountSection.interfaces";

const BankAccountSection: FC<BankAccountSectionProps> = ({
  isCentralizedBranch,
  massive,
}: BankAccountSectionProps) => {
  const { accordionBanksAccountsProps, modalDialogProperties } =
    useBankAccountSectionState();

  accordionBanksAccountsProps.disabledActions = isCentralizedBranch;
  accordionBanksAccountsProps.massive = massive;

  return (
    <>
      <BankAccountFormsModal
        isCentralizedBranch={isCentralizedBranch}
        canAddAccounts={accordionBanksAccountsProps.canAddAccounts}
      />
      <AccordionBanksAccounts {...accordionBanksAccountsProps} />
      <ModalDialog {...modalDialogProperties} />
    </>
  );
};

export default BankAccountSection;
