import { TableBodyCellEnum } from "../infrastructure/enums/DataTableEnum";
import { ICellRow } from "@kushki/connect-ui";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { StatusEnum } from "../infrastructure/enums/StatusEnum";
import { ColorEnum } from "../infrastructure/enums/ColorEnum";
import { getBasicMerchantInformation } from "./localStorage";
import { defaultTo } from "lodash";

export const buildOneCell = (
  typeCell: TableBodyCellEnum,
  value1: string,
  value2?: string,
  style: ICellRow = { align: "left", spacing: 0 },
  status?: string
): ITableCellProps => {
  switch (typeCell) {
    case TableBodyCellEnum.TAG:
      return {
        type: TableBodyCellEnum.TAG,
        props: {
          text: value1,
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          color: setTagColor(status),
        },
      };

    case TableBodyCellEnum.TITLE:
      return {
        type: TableBodyCellEnum.TITLE,
        props: {
          type: "twoLines",
          line1: value1,
          line2: value2,
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
        },
      };
    default:
      return {
        type: TableBodyCellEnum.TEXT,
        props: {
          type: "oneLine",
          line1: value1,
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
        },
      };
  }
};

export const setTagColor = (status?: string) => {
  switch (status?.toLowerCase()) {
    case StatusEnum.DISABLE:
      return ColorEnum.WARNING;
    case StatusEnum.ENABLE:
      return ColorEnum.SUCCESS;
    case StatusEnum.DRAFT:
      return ColorEnum.ERROR;
    default:
      return ColorEnum.INFO;
  }
};

export const existPathBusinessRuleList = (): boolean => {
  const redirectPath: string = defaultTo(
    getBasicMerchantInformation().redirectPath,
    ""
  );
  return redirectPath.search("/business-rules/list/") >= 0;
};
