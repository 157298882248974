import { useDispatch, useSelector } from "react-redux";
import {
  REFUND_TRANSACTION_STATUS_FILTER_OPTIONS,
  REFUNDS_STATUS_LABEL_LOOKUP,
} from "../../../shared/constants/filters_options";
import { getFormattedTimezoneOffset } from "../../../shared/utils/timezone";
import {
  IGeneralFilter,
  TRefundTransactionStatus,
} from "../../../store/interfaces/RefundsState.interfaces";
import {
  setHistoricalDateTimeFilter,
  setHistoricalGeneralFilter,
} from "../../../store/reducers/refunds/refunds";
import { RootState } from "../../../store/store";
import { IDateTime } from "../../common/DateTimeRangePicker/DateTimeRangePicker.interfaces";
import { IFilters } from "../../common/FilterBox/FilterBox.interfaces";
import { UseHistoricalRefundsActionBarState } from "./useHistoricalRefundsActionBarState.interfaces";

const useHistoricalRefundsActionBarState: UseHistoricalRefundsActionBarState =
  () => {
    const filters = useSelector(
      (state: RootState) => state.refunds.historicalFilters
    );

    const dispatch = useDispatch();

    const setFromTo = ({ from, to }: IDateTime) => {
      const timezone = getFormattedTimezoneOffset(
        new Date().getTimezoneOffset()
      );

      dispatch(setHistoricalDateTimeFilter({ from, timezone, to }));
    };

    const currentFilters: IFilters[] = [
      {
        id: "status",
        options: REFUND_TRANSACTION_STATUS_FILTER_OPTIONS.map((status) => ({
          id: status,
          label: REFUNDS_STATUS_LABEL_LOOKUP[status],
          selected: filters.generalFilter.status.includes(status),
        })),
        title: "Estado Final",
      },
    ];

    const handleSetGeneralFilter = (filters: IFilters[]) => {
      const refundStatus = filters.find((filter) => filter.id === "status");

      const generalFilters: IGeneralFilter = {
        status: (refundStatus?.options
          .filter((option) => option.selected)
          .map((option) => option.id) ?? []) as TRefundTransactionStatus[],
      };

      dispatch(setHistoricalGeneralFilter(generalFilters));
    };

    return {
      currentFilters,
      dateTimeFrom: filters.dateTime.from,
      dateTimeTo: filters.dateTime.to,
      handleSetGeneralFilter,
      setFromTo,
    };
  };

export default useHistoricalRefundsActionBarState;
