import React from "react";
import { Box, MenuItem, Select, TextField } from "@material-ui/core";
import { RangeAmountTypeEnum } from "../../../shared/infrastructure/enums/RangeAmountTypesEnum";
import { IRangeAmount } from "../../../shared/infrastructure/interfaces/IRangeAmount";
import { MENU_ITEMS } from "../../../shared/infrastructure/constants/RangeMenuItems";
import { amountComponentStyle } from "./AmountComponent.style";

enum ErrorsMessageEnum {
  RANGE_AMOUNT_ERROR = "El valor monto mínimo no puede ser mayor que el máximo",
}
export interface IAmountComponent {
  rangeAmount: IRangeAmount;
  errorRange: boolean;
  handlers: {
    handleChangeSelectRange: (
      event: React.ChangeEvent<{ value: unknown }>
    ) => void;
    handleChangeAmount: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type: RangeAmountTypeEnum
    ) => void;
  };
}

export const AmountComponent = (props: IAmountComponent) => {
  const classes = amountComponentStyle();

  const { handlers, rangeAmount, errorRange } = props;

  return (
    <Box className={classes.mainContainer}>
      <Box pr={2}>
        <Select
          value={rangeAmount.type}
          onChange={handlers.handleChangeSelectRange}
          displayEmpty
          defaultValue={RangeAmountTypeEnum.MIN}
          inputProps={{ "aria-label": "Without label" }}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <Box className={classes.mainContainer}>
          {rangeAmount.type === RangeAmountTypeEnum.RANGE ? (
            <React.Fragment>
              <Box>
                <TextField
                  error={errorRange}
                  margin="dense"
                  size="small"
                  helperText={
                    errorRange
                      ? ErrorsMessageEnum.RANGE_AMOUNT_ERROR
                      : RangeAmountTypeEnum.MIN
                  }
                  type="number"
                  value={rangeAmount.min}
                  onChange={(event) =>
                    handlers.handleChangeAmount(event, RangeAmountTypeEnum.MIN)
                  }
                />
              </Box>
              <Box>
                <p style={{ marginTop: 30, padding: "0 10px" }}>y</p>
              </Box>
              <Box>
                <TextField
                  error={errorRange}
                  id="margin-dense"
                  margin="dense"
                  size="small"
                  helperText={errorRange ? "" : RangeAmountTypeEnum.MAX}
                  type="number"
                  value={rangeAmount.max}
                  onChange={(event) =>
                    handlers.handleChangeAmount(event, RangeAmountTypeEnum.MAX)
                  }
                />
              </Box>
            </React.Fragment>
          ) : (
            <Box>
              <TextField
                margin="dense"
                size="small"
                type="number"
                value={rangeAmount.value}
                onChange={(event) =>
                  handlers.handleChangeAmount(event, rangeAmount.type)
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
