import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const wrapperContainerStyles = createNamedStyles({
  containerBody: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(5),
    padding: "64px",
  },
  containerFooter: {
    bottom: 0,
    position: "fixed",
    width: "-webkit-fill-available",
  },
  containerForm: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "150px",
  },
});
