import { TransactionInfo } from "../../../types/transactions_data";
import { get, isEqual } from "lodash";
import { ITableRowProps } from "../../components/Table/TableTransactions/TableTransactions.interfaces";

export const verifySelectedRows = (
  selectedRows: TransactionInfo[],
  data: TransactionInfo
) => {
  let isSelected: boolean = false;

  selectedRows.forEach((item: TransactionInfo) => {
    if (isEqual(item, data)) isSelected = true;
  });

  return isSelected;
};

export const verifyAllSelectedRows = (
  selectedRows: TransactionInfo[],
  rows: TransactionInfo[]
) => {
  let count: number = 0;

  selectedRows.map((item: TransactionInfo) => {
    rows.map((row) => {
      if (isEqual(item, row)) count++;
    });
  });

  return count === rows.length;
};

export const getFields = (
  itemsSelected: TransactionInfo[],
  rows: ITableRowProps[]
) => {
  const getFields: string[] = [];

  rows.map((row) => {
    itemsSelected.map((item) => {
      if (isEqual(item, get(row.info, "_source")))
        getFields.push(get(row.info, "_id"));
    });
  });

  return getFields;
};

export function formatNumber(value: string): string {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(value));
}
