import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

export interface StatusChipProps extends ChipProps {
  text: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    borderRadius: "4px",
    background: theme.palette.grey["100"],
    color: theme.palette.grey.A700,
  },
}));

export const StatusChip: React.FC<StatusChipProps> = (
  props: StatusChipProps
) => {
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <Chip label={props.text} className={classes.chip} {...props} />
    </React.Fragment>
  );
};
