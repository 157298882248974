import { ICredentialDemoComponent } from "../../../shared/infrastructure/interfaces/ICredentialDemoComponent";
import { GetCredentialsResponse } from "../../../../types/get_credentials_response";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { useEffect, useState } from "react";
import {
  getCredentialsList,
  getNodeInfo,
  getParentMerchantId,
  getSearchKeyCredentials,
  searchMerchants,
  setBranchesData,
} from "../../../store/actionCreators";
import { useParams } from "react-router-dom";
import { GetCredentialsRequest } from "../../../../types/get_credentials_request";
import { IParams } from "../../../shared/infrastructure/interfaces/IParams";
import { defaultTo, get, isEmpty, isUndefined } from "lodash";
import { useSnackbar } from "@kushki/connect-ui";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorsEnum";
import {
  ColorsEnum,
  SnackBarVariantsEnum,
} from "../../../shared/infrastructure/enums/StylesEnum";
import { ICustomModalProps } from "../../../shared/infrastructure/interfaces/ICustomModalProps";
import { IAddCredentialModalProps } from "../../../shared/infrastructure/interfaces/IAddCredentialModalProps";
import { IBasicMerchantInformation } from "../../../shared/infrastructure/interfaces/IBasicMerchantInformation";
import {
  defaultBasicMerchantInformation,
  getBasicMerchantInformationStorage,
  getRolesLocalStorage,
} from "../../../shared/utils/local-storage/basic-merchant-information";
import { IBreadcrumbProps } from "../../../shared/infrastructure/interfaces/IBreadcrumbProps";
import { BreadcrumbLabels } from "../../../shared/infrastructure/enums/BreadcrumbLabels";
import { EntityNameEnum } from "../../../shared/infrastructure/enums/EntityNameEnum";
import { MerchantStatusEnum } from "../../../shared/infrastructure/enums/MerchantStatusEnum";
import { RedirectEnum } from "../../../shared/infrastructure/enums/RedirectEnum";
import { ColorConstants } from "../../../shared/infrastructure/constants/ColorConstants";
import { RolesEnum } from "../../../shared/constants/RolesEnum";
import { ICredentialsProps } from "../../../shared/infrastructure/interfaces/ICredentialsProps";

export const useCredentialsState = (
  props: ICredentialsProps
): ICredentialDemoComponent => {
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const { merchantId } = useParams<IParams>();
  const roles = getRolesLocalStorage();
  const isBackofficeMasterCustomer: boolean =
    roles[RolesEnum.BackofficeMasterCustomer];
  const isBackofficeAdmin: boolean = roles[RolesEnum.BackofficeAdmin];
  const isBackofficeUserMaster: boolean = roles[RolesEnum.BackofficeUserMaster];
  const isBackofficeMasterCredential: boolean =
    roles[RolesEnum.BackofficeMasterCredential];

  const getCredentialsResponse: GetCredentialsResponse | undefined =
    useSelector((state: IAppState) => state.credentialsList);

  const { nodeInfo } = useSelector((state: IAppState) => state);

  const [getCredentialsRequest, setGetCredentialsRequest] =
    useState<GetCredentialsRequest>({
      offset: 0,
      limit: 5,
      merchantId: merchantId,
    });

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [basicMerchantInformation, setBasicMerchantInformation] =
    useState<IBasicMerchantInformation>(defaultBasicMerchantInformation);
  const requestError: boolean | undefined = useSelector((state: IAppState) =>
    defaultTo(state.requestError, false)
  );
  const [isModalButtonDisabled, setIsModalButtonDisabled] =
    useState<boolean>(false);

  const [customModalProps, setCustomModalProps] = useState<ICustomModalProps>({
    isError: false,
    descriptionText: "",
    leftButtonText: "",
    rightButtonText: "",
    titleText: "",
    isRightButtonDisabled: false,
    actions: {
      handleLeftButtonAction: () => {},
      handleRightButtonAction: () => {},
    },
    onClose: () => {},
  });

  const [credentialModalProps, setCredentialModalProps] =
    useState<IAddCredentialModalProps>({
      titleText: "",
      leftButtonText: "",
      rightButtonText: "",
      isEdit: false,
      parentMerchantID: "",
    });

  const breadcrumbItems: IBreadcrumbProps[] = [
    {
      label: BreadcrumbLabels.HOME,
      url: RedirectEnum.DASHBOARD,
      underline: "hover",
    },
    {
      label: BreadcrumbLabels.DEVELOPERS,
      url: RedirectEnum.DEVELOPERS,
      underline: "hover",
    },
    {
      label: BreadcrumbLabels.CREDENTIALS,
      underline: "none",
      color: ColorConstants.BLUE,
    },
  ];

  useEffect(() => {
    switch (props.type) {
      case EntityNameEnum.CUSTOMER:
        dispatch(getCredentialsList(getCredentialsRequest, false));
        break;

      case EntityNameEnum.BRANCH:
        if (
          (!isUndefined(getCredentialsRequest) &&
            !isBackofficeMasterCustomer) ||
          (!isUndefined(getCredentialsRequest) &&
            isBackofficeMasterCustomer &&
            getCredentialsRequest.merchantId !== merchantId)
        ) {
          dispatch(getCredentialsList(getCredentialsRequest, false));
          if (
            isBackofficeMasterCustomer &&
            getCredentialsRequest.merchantId.length > 0
          ) {
            getCredentials(
              getCredentialsRequest?.merchantId,
              "",
              get(nodeInfo, "path", "")
            );
          }
        }
        break;

      default:
    }
  }, [getCredentialsRequest]);

  useEffect(() => {
    if (requestError) {
      showSnackbar({
        message: ErrorsEnum.REQUEST_ERROR,
        variant: SnackBarVariantsEnum.SIMPLE,
        color: ColorsEnum.DANGER,
        withIcon: false,
      });
    }
  }, [requestError]);

  const getCredentials = (
    merchantId: string,
    searchTerm: string,
    path?: string
  ): void => {
    dispatch(getSearchKeyCredentials(merchantId, searchTerm, path));
  };

  useEffect(() => {
    setBasicMerchantInformation(getBasicMerchantInformationStorage());
    if (!isBackofficeMasterCustomer) {
      getCredentials(merchantId, "");
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(merchantId) && isBackofficeMasterCustomer)
      dispatch(getNodeInfo(merchantId));
  }, [merchantId]);

  useEffect(() => {
    const customerPath: string = get(nodeInfo, "path", "");
    if (isBackofficeMasterCustomer && !isEmpty(customerPath))
      dispatch(
        searchMerchants(
          {
            entityName: EntityNameEnum.BRANCH,
            limit: 100,
            filter: {
              merchantStatus: MerchantStatusEnum.ACTIVE,
            },
            offset: 0,
            path: customerPath,
            strictSearch: false,
          },
          setBranchesData
        )
      );
  }, [nodeInfo]);

  const useCustomerMerchantID = (): string | undefined => {
    const parentMerchantID: string | undefined = useSelector(
      (state: IAppState) => state.parentMerchantId
    );

    useEffect(() => {
      if (
        isBackofficeAdmin ||
        isBackofficeUserMaster ||
        isBackofficeMasterCredential
      ) {
        dispatch(getParentMerchantId(merchantId));
      }
    }, []);

    return parentMerchantID;
  };

  const calculateOffset = (page: number, itemsPerPage: number): void => {
    setGetCredentialsRequest({
      ...getCredentialsRequest!,
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage,
    });
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    calculateOffset(1, value);
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    calculateOffset(value, defaultTo(getCredentialsRequest?.limit, 5));
  };

  const handleChangeModalProps = (modalProps: ICustomModalProps): void => {
    setCustomModalProps(modalProps);
  };

  const handleModalButton = (flag: boolean): void => {
    setIsModalButtonDisabled(flag);
  };

  const handleCredentialModalProps = (
    credentialProps: IAddCredentialModalProps
  ): void => {
    setCredentialModalProps(credentialProps);
  };

  const onChangeFilters = (request: Partial<GetCredentialsRequest>) => {
    const request_search: GetCredentialsRequest = {
      ...getCredentialsRequest,
      ...(request as GetCredentialsRequest),
      filter: {
        ...request.filter,
      },
      offset: 0,
      ...(isBackofficeMasterCustomer
        ? {
            path: get(nodeInfo, "path"),
            merchantId: defaultTo(request.merchantId, ""),
          }
        : { merchantId: merchantId! }),
    };
    setGetCredentialsRequest(request_search);
  };

  credentialModalProps.parentMerchantID = useCustomerMerchantID();
  return {
    basicMerchantInformation,
    credentialModalProps,
    customModalProps,
    getCredentialsRequest,
    getCredentialsResponse,
    itemsPerPage: defaultTo(getCredentialsRequest?.limit, 5),
    selectedPage,
    actions: {
      handleSelectedPage,
      handleItemsPerPage,
      handleModalButton,
    },
    handleChangeModalProps,
    handleCredentialModalProps,
    getCredentials,
    merchantId,
    isModalButtonDisabled,
    breadcrumbItems,
    onChangeFilters,
  };
};
