import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Container } from "@material-ui/core";
import { CardConnect } from "../../components/CardConnect/CardConnect";
import { IAppState, INotification } from "../../store/reducer";
import { connect } from "react-redux";
import { useConnectIndexState } from "./state/useConnectIndexState";
import { ThunkDispatch } from "redux-thunk";
import {
  getAccessCode,
  IAppAction,
  setNotification,
  saveAccessCode,
  statusCodeSupport,
} from "../../store/actionCreators";
import { Dispatch } from "redux";
import NotificationSnackbar from "../../components/Notification/NotificationSnackbar";
import { SessionRequest } from "../../../types/session_request";

export interface IConnectIndexStateProps {
  state: IAppState;
}
export interface IConnectIndexFunctionProps {
  getAccessCode: (timeConnect: SessionRequest) => void;
  saveAccessCode: (accessCode: string) => void;
  statusCodeSupport: (accessCode: string) => void;
  setNotification: (notify: INotification) => IAppAction;
}

export type ConnectIndexProps = IConnectIndexStateProps &
  IConnectIndexFunctionProps;

export const ConnectIndex: React.FC<ConnectIndexProps> = (
  props: ConnectIndexProps
) => {
  const { properties, handler } = useConnectIndexState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <NotificationSnackbar
          msg={props.state.notification!.message}
          open={props.state.notification!.show}
          type={props.state.notification!.type}
          handlerClose={handler.close}
        />
        <PageHeader title="Soporte" />
        <CardConnect
          {...properties.cardProps}
          accessCode={props.state.accessCode!}
          handleCodeGenerate={handler.codeGenerate}
        />
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => IConnectIndexStateProps = (
  state: IAppState
): IConnectIndexStateProps => ({
  state: state,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IConnectIndexFunctionProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IConnectIndexFunctionProps => ({
  getAccessCode: (sessionRequest: SessionRequest): void =>
    dispatch(getAccessCode(sessionRequest)),
  saveAccessCode: (accessCode: string): void =>
    dispatch(saveAccessCode(accessCode)),
  setNotification: (notify: INotification): IAppAction =>
    dispatch(setNotification(notify)),
  statusCodeSupport: (accessCode: string): void =>
    dispatch(statusCodeSupport(accessCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectIndex);
