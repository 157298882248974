import React from "react";
import { FilterBar } from "@kushki/connect-ui";
import { IFilterTableComponent } from "../../shared/infrastructure/interfaces/IGlobalTableComponent";
import { get, isEmpty, isEqual } from "lodash";
import { useFilterTableComponentState } from "./state/useFilterTableComponentState";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { isUndefined } from "lodash";
import { TextFieldSearchByEnum } from "../../shared/infrastructure/enums/TextFieldSearchByEnum";
import { Grid } from "@mui/material";
import { TextSearchModeEnum } from "../../shared/infrastructure/enums/FilterEnum";

export const FilterTableComponent: React.FC<IFilterTableComponent> = (
  props: IFilterTableComponent
) => {
  const {
    categoryItems,
    from,
    to,
    hierarchyMode,
    setRequestFilter,
    setCommerceSearchText,
    changeFilter,
    handleDateChange,
  } = useFilterTableComponentState(props);

  if (!isUndefined(categoryItems)) {
    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <FilterBar
            filterDetailBar={{
              filters: [],
              onChangeFilters: changeFilter,
            }}
            filterSideBar={{
              categoryItems,
              filtersCount: 0,
              isMinimized: false,
              isOpen: true,
              onClick: setRequestFilter,
              title: LabelEnum.FILTER_MODAL_TITLE,
            }}
            rangeDatePicker={{
              dateFormat: "dd/MMM/yyyy",
              defaultFinishDate: to,
              defaultStartDate: from,
              disabledFutureDate: true,
              disabledOldDate: false,
              isFilled: false,
              isOpen: false,
              onDateSelect: handleDateChange,
              placeholder: LabelEnum.FILTER_DATA_PICKER,
            }}
            textFieldSearchBy={{
              onChange: (event) => {
                setCommerceSearchText(event.target.value);
                if (!isEmpty(get(event, "target.value", "")))
                  props.handleDataChange(
                    event.target.value,
                    isEqual(hierarchyMode, TextSearchModeEnum.WITH_HIERARCHY)
                  );
                else props.emptyFilterSelectList();
              },
              items: props.filterSelectList,
              onItemSelected: (selected) =>
                props.handleSelectItem(selected, props.tabName),
              placeholder: TextFieldSearchByEnum.HOLDER,
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
