import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "./store/reducer";
import { IAppAction, setNotification } from "./store/actionCreators";
import { ThunkDispatch } from "redux-thunk";
import { INotification } from "./shared/infrastructure/interfaces/INotification";
import NotificationsIndex from "./containers/NotificationsIndex/NotificationsIndex";
import WebhookFormIndex from "./containers/WebhookFormIndex/WebhookFormIndex";
import WebhookIndex from "./containers/WebhookIndex/WebhookIndex";
import NotificationDetail from "./containers/NotificationDetail/NotificationDetail";
import { auth } from "./shared/auth";

interface INavigationStateProps {
  notification: INotification;
}
interface INavigationDispatchProps {
  setNotification(payload: INotification): void;
}

type TNavigationProps = INavigationDispatchProps & INavigationStateProps;
export const Navigation: React.FC<TNavigationProps> = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            path="/webhook/notifications/detail"
            component={NotificationDetail}
          />
          {auth.isAdmin() ? (
            <Route
              path="/webhook/notifications/:merchantId"
              component={NotificationsIndex}
            />
          ) : (
            <Route
              path="/webhook/notifications"
              component={NotificationsIndex}
            />
          )}
          {auth.isAdmin() && (
            <Route
              exact
              path="/webhook/:merchantId/create"
              component={WebhookFormIndex}
            />
          )}
          <Route
            exact
            path="/webhook/:merchantId/edit"
            component={WebhookFormIndex}
          />
          <Route exact path="/webhook/:merchantId" component={WebhookIndex} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationDispatchProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationDispatchProps => ({
  setNotification: (payload: INotification) => {
    dispatch(setNotification(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
