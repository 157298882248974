import React, { FC } from "react";
import { intersection } from "lodash";
import { Grid, Typography } from "@mui/material";
import { EventCheckList } from "../EventCheckList/EventCheckList";
import { OPTIONS } from "../constants";
import { EventCheckListRendererProps } from "./interfaces";

export const EventCheckListRenderer: FC<EventCheckListRendererProps> = ({
  title,
  optionsList,
  events,
  options,
  handleCheck,
  handleStatus,
}: EventCheckListRendererProps) => {
  const filteredOptions = intersection(optionsList, options);

  if (options.length === 0) return null;

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: "400 !important", lineHeight: "150%" }}
        >
          {title}
        </Typography>
      </Grid>
      {filteredOptions.map((option: string, index: number) => (
        <Grid key={index} item xs={4} lg={3}>
          <EventCheckList
            title={OPTIONS[option]}
            handleStatus={handleStatus}
            handleCheck={handleCheck}
            option={option}
            items={events}
          />
        </Grid>
      ))}
    </>
  );
};
