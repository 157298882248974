import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

export interface IKshRadioInput {
  id: string;
  value: string | undefined;
  options: { value: string; label: string }[];
  onChange(value: { prop: string; value: string }): void;
  isCommonReferences?: boolean;
}

export const KshRadioInput: React.FC<IKshRadioInput> = (
  props: IKshRadioInput
) => {
  return (
    <RadioGroup
      row
      name={props.id}
      value={props.value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange({
          prop: props.id,
          value: event.target.value,
        })
      }
      style={{
        display: "flex",
        justifyContent: props.options.length > 2 ? "space-between" : "start",
        width: "100%",
      }}
    >
      {props.options.map((option: { value: string; label: string }) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          checked={props.value === option.value}
          control={<Radio color="primary" />}
          label={option.label}
          style={{
            paddingRight: props.isCommonReferences ? "15%" : undefined,
          }}
        />
      ))}
    </RadioGroup>
  );
};
