export enum StatusEnumMonth {
  Enero = "ene",
  Febrero = "feb",
  Marzo = "mar",
  Abril = "abr",
  Mayo = "may",
  Junio = "jun",
  Julio = "jul",
  Agosto = "ago",
  Septiembre = "sep",
  Sept = "sept",
  Octubre = "oct",
  Noviembre = "nov",
  Diciembre = "dic",
}
