import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ColorType } from "../../shared/infrastructure/enums/ColorEnum";
import { Variant } from "@material-ui/core/styles/createTypography";

const useStyles = makeStyles({
  txtnormal: {
    fontWeight: 500,
  },
  txtlighter: {
    fontWeight: "lighter",
    color: "#293036",
  },
  txt400: {
    fontWeight: 400,
    color: "#293036",
  },
});

interface IParamaTY {
  color?: ColorType;
  variant?: Variant | "inherit";
}
export interface IComponentTextProps {
  label: string;
  type: "normal" | "400" | "lighter";
  params?: IParamaTY;
}
export const ComponentText: React.FC<IComponentTextProps> = (
  props: React.PropsWithChildren<IComponentTextProps>
) => {
  const { params, label, type } = props;
  const classes = useStyles();
  let styles: IParamaTY = {
    color: "inherit",
    variant: "inherit",
  };
  if (params) {
    styles = {
      ...styles,
      ...params,
    };
  }
  return (
    <Typography
      variant={styles.variant}
      className={classes[`txt${type}`]}
      color={styles.color}
    >
      {label}
    </Typography>
  );
};
