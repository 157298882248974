import { FC } from "react";
import * as React from "react";
import { IEmptyResults } from "./EmptyResults.interfaces";
import { Box } from "@mui/material";
import { SearchImage } from "../../assets/icons/SearchImage";
import { mainContainerStyles as styles } from "../../containers/MainContainer/MainContainer.styles";
import { MessageBlock } from "@kushki/connect-ui";

export const EmptyResults: FC<IEmptyResults> = ({
  addText,
  title,
}: IEmptyResults) => {
  return (
    <Box sx={styles.emptyCenter}>
      <MessageBlock
        image={<SearchImage />}
        title={title}
        typeMessage={1}
        body={addText}
      />
    </Box>
  );
};

export default EmptyResults;
