import { makeStyles, Theme } from "@material-ui/core/styles";
import { FilterEnum } from "../../shared/infrastructure/enums/FilterEnum";
import { ChipTransactionsStatusProps } from "./ChipTransactionStatus";

export const chipTransactionStatusStyle = makeStyles((theme: Theme) => ({
  chip: {
    background: (props: ChipTransactionsStatusProps) => {
      switch (props.status) {
        case FilterEnum.DECLINED:
          return theme.palette.error.light + " !important";
        case FilterEnum.APPROVAL:
          return theme.palette.success.light + " !important";
        default:
          return theme.palette.grey["100"] + " !important";
      }
    },
    borderRadius: "4px !important",
    color: (props: ChipTransactionsStatusProps) => {
      switch (props.status) {
        case FilterEnum.DECLINED:
          return theme.palette.error.dark + " !important";
        case FilterEnum.APPROVAL:
          return theme.palette.success.dark + " !important";
        default:
          return theme.palette.grey.A700 + " !important";
      }
    },
    padding: "2px !important",
  },
}));
