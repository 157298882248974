import { IMerchantMigrationState } from "../MerchantMigrationIndex.interfaces";
import { useEffect, useState } from "react";
import {
  MerchantMigrationEnum,
  MODAL_TEXT,
} from "../../../shared/constants/infrastructure/MerchantMigrationEnum";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { exportMerchants } from "../../../store/thunks/merchantMigration/merchantMigration.thunks";
import { defaultTo } from "lodash";

export const useMerchantMigrationIndexState = (): IMerchantMigrationState => {
  const { responseState } = useAppSelector((state) => state.merchantMigration);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    title: string;
    subTitle: string;
  }>({ subTitle: "", title: "" });
  const emailAdmin: string | null = localStorage.getItem("email");
  const userAdmin: string | null = localStorage.getItem("username");
  const dispatch = useAppDispatch();

  const handleCloseModal = (open: boolean) => {
    setOpenModal(open);
  };

  const setValuesForModal = () => {
    if (responseState!.code) {
      switch (responseState!.code) {
        case "BCN020":
          setModalData({
            subTitle: MODAL_TEXT.WAIT_MODAL_DETAIL,
            title: MerchantMigrationEnum.WAIT_MODAL_TITLE,
          });
          break;
        default:
          setModalData({
            subTitle: MODAL_TEXT.ERROR_MODAL_DETAIL,
            title: MerchantMigrationEnum.ERROR_MODAL_TITLE,
          });
          break;
      }
    } else {
      setModalData({
        subTitle: MODAL_TEXT.SUCCESS_MODAL_DETAIL(emailAdmin!),
        title: MerchantMigrationEnum.SUCCESS_MODAL_TITLE,
      });
    }
  };
  const handleCallExport = (operation: string) => {
    if (emailAdmin)
      dispatch(
        exportMerchants({
          operation,
          userMail: emailAdmin,
          userName: defaultTo(userAdmin, "admin"),
        })
      );
  };

  useEffect(() => {
    if (responseState) {
      setValuesForModal();
      handleCloseModal(true);
    }
  }, [responseState]);

  return {
    handleCallExport,
    handleCloseModal,
    modalData,
    openModal,
  };
};
