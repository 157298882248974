import axios from "../../../../../shared/axios-util";
import { API_ENDPOINTS } from "../../../../../shared/constants/api_endpoints";
import { IAddCommentParams } from "../../../CreateCommentModal/CreateCommentModal.interfaces";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { COMMENTS_TAB_LABELS } from "../../../../../shared/constants/comments_tab_labels";
import {
  hideLoading,
  setLoadingText,
  setMessageAlert,
  setTypeAlert,
  showAlert,
  showLoading,
} from "../../../../../store/actions/app_actions";
import { addComment } from "../../../../../store/actions/transactions/transactions";

type setCommentType = (c: string) => void;
export interface IDetailFooterState {
  actions: {
    handleClose: (setComment: setCommentType) => void;
    handleOpen: () => void;
    handleSave: (comment: string, setComment: setCommentType) => void;
  };
  isCoordinator: boolean;
  isSaving: boolean;
  openModal: boolean;
}
export type IUseDetailFooterState = (
  transactionID: string
) => IDetailFooterState;

interface IKushkiError {
  code: string;
  message: string;
}

export const useDetailFooterState: IUseDetailFooterState = (transactionID) => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const roles = JSON.parse(localStorage.getItem("roles")!);
  const isCoordinator = roles.B2CCoordinator;

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = (setComment: setCommentType) => {
    setOpenModal(false);
    setComment("");
  };
  const handleSave = async (
    comment: string,
    setComment: (c: string) => void
  ) => {
    setIsSaving(true);
    dispatch(setLoadingText("Cargando..."));
    dispatch(showLoading());

    const url = API_ENDPOINTS.ADD_COMMENT;
    const user = localStorage.getItem("username")!;
    const data: IAddCommentParams = {
      comment,
      transactionReference: transactionID,
      user,
    };

    try {
      await axios.post(url, data);
      dispatch(
        addComment({
          comment: comment,
          created: new Date(Date.now()).getTime(),
          user: user,
        })
      );
      setOpenModal(false);
      setComment("");
      dispatch(setTypeAlert("success"));
      dispatch(setMessageAlert(COMMENTS_TAB_LABELS.SUCCESS));
      dispatch(showAlert());
    } catch (error: any) {
      const message: IKushkiError = error.response.data;

      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert(`Error ${message.code}: ${message.message}`));
      dispatch(showAlert());
    } finally {
      setIsSaving(false);
      dispatch(hideLoading());
    }
  };

  return {
    actions: { handleClose, handleOpen, handleSave },
    isCoordinator,
    isSaving,
    openModal,
  };
};
