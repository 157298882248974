import React, { useState } from "react";
import { History, SecurityRule } from "../../../../types/security_rule";
import { SecurityRuleStatusValue } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { defaultTo } from "lodash";
import { RulesNotificationResultEnum } from "../../../shared/infrastructure/constants/RulesConstants";
import { IDialogDisableRuleProps } from "../DialogDisableRule";

export interface DialogDisabledRuleState {
  actions: {
    handleChangeReason: (
      reason: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    disableRule: (
      reason: string,
      username: string,
      rule: SecurityRule
    ) => SecurityRule;
    setUserName: () => string;
    handleCloseNotification: () => void;
    enableRule: (username: string, rule: SecurityRule) => SecurityRule;
    handleDialogDisableRule: () => void;
    handleFinish?: () => void;
  };
  error: boolean;
  reason: string;
  count: number;
  openDialogDisableRule: boolean;
}

export const useDialogDisabledRuleState = (
  props: IDialogDisableRuleProps
): DialogDisabledRuleState => {
  const [reason, setReason] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [count, setCount] = React.useState(0);
  const [openDialogDisableRule, setOpenDialogDisableRule] =
    useState<boolean>(false);

  const handleChangeReason = (
    reason: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setError(false);
    setReason(reason.target.value);
    setCount(reason.target.value.length);
  };

  const disableRule = (
    reason: string,
    username: string,
    rule: SecurityRule
  ): SecurityRule => {
    const historicalItem: History = {
      userName: username,
      created: new Date().getTime(),
      reason: reason,
      eventName: SecurityRuleStatusValue.DISABLED,
    };

    defaultTo(rule.historical, []).push(historicalItem);

    return {
      ...rule,
      type: rule.type,
      service: rule.service,
      status: SecurityRuleStatusValue.DISABLED,
    };
  };

  const setUserName = () => {
    return defaultTo(localStorage.getItem("username"), "");
  };

  const handleCloseNotification = (): void => {
    props.setNotification!({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.NO_ACTION,
    });
  };

  const handleDialogDisableRule = (): void => {
    setOpenDialogDisableRule(true);
  };

  const handleFinish = (): void => {
    setOpenDialogDisableRule(false);
  };

  const enableRule = (username: string, rule: SecurityRule): SecurityRule => {
    const historicalItem: History = {
      userName: username,
      created: new Date().getTime(),
      reason: "",
      eventName: SecurityRuleStatusValue.ENABLED,
    };

    rule.historical!.push(historicalItem);

    return {
      ...rule,
      type: rule.type,
      service: rule.service,
      status: SecurityRuleStatusValue.ENABLED,
    };
  };

  return {
    actions: {
      disableRule,
      handleChangeReason,
      setUserName,
      handleCloseNotification,
      enableRule,
      handleDialogDisableRule,
      handleFinish,
    },
    error,
    reason,
    count,
    openDialogDisableRule,
  };
};
