import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { ReactNode, useEffect, useState } from "react";
import { RootState } from "../../../store/store";
import { IUseGlobalTableComponent } from "./useGlobalTableComponent.interfaces";
import {
  setLimit,
  setOffset,
  setPage,
  setTrxRequest,
} from "../../../store/actions/walletTransaction.action";
import { getTransactionsByMerchantId } from "../../../store/thunks/walletTransaction/walletTransaction";
import { GetTransactionsRequest } from "../../../../types/get_transactions_request";
import { DateFormatStringEnum, FilterEnum } from "../../../shared/enums/filter";
import format from "date-fns/format";
import { startOfMonth } from "date-fns";
import { Dispatch } from "redux";
import { get, isEmpty, isUndefined } from "lodash";
import { WalletTransactionPath } from "../../../shared/enums/wallet_transaction_path";
import { setOpenSnackbar } from "../../../store/actions/app.action";
import { IconCircleInformation, useSnackbar } from "@kushki/connect-ui";
import React from "react";
import { SnackBarColorEnum } from "../../../shared/enums/snackbar_props";
import { ISnackbarInfo } from "../../../store/interfaces/app.interfaces";

export const useGlobalTableComponent = (
  merchantId: string
): IUseGlobalTableComponent => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const { transactions, limit, walletTrxRequest, page } = useAppSelector(
    (state: RootState) => ({
      ...state.walletTransaction,
    })
  );
  const { snackBarInfo } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(page!);
  const defaultStartDate: string = `${format(
    startOfMonth(new Date()),
    DateFormatStringEnum.PATTERN_yyyyMMdd
  )}${DateFormatStringEnum.PATTERN_T000000}`;
  const defaultEndDate: string = `${format(
    new Date(),
    DateFormatStringEnum.PATTERN_yyyyMMdd
  )}${DateFormatStringEnum.PATTERN_T235959}`;
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const initialFilterTransactionsRequest: GetTransactionsRequest = {
      from: defaultStartDate,
      limit: 10,
      offset: 0,
      sort: {
        field: WalletTransactionPath.CREATED,
        order: FilterEnum.DESC,
      },
      to: defaultEndDate,
    };

    dispatch(setTrxRequest(initialFilterTransactionsRequest));
  }, []);

  useEffect(() => {
    if (!isUndefined(walletTrxRequest))
      dispatch(
        getTransactionsByMerchantId({
          filter: walletTrxRequest,
          merchantId: merchantId,
        })
      );
  }, [walletTrxRequest]);

  const setIconByStatus = (
    snackbarInfo: ISnackbarInfo
  ): ReactNode | undefined => {
    if (snackbarInfo.isError) return <IconCircleInformation />;

    return undefined;
  };

  useEffect(() => {
    if (!isEmpty(snackBarInfo)) {
      showSnackbar({
        color: get(snackBarInfo, "isError", false)
          ? SnackBarColorEnum.DANGER
          : SnackBarColorEnum.SUCCESS,
        icon: snackBarInfo!.withIcon
          ? setIconByStatus(snackBarInfo!)
          : undefined,
        message: get(snackBarInfo, "message", ""),
        variant: "simple",
      });
      dispatch(setOpenSnackbar({}));
    }
  }, [snackBarInfo]);

  const handleChangeItemsPerPage = (value: number): void => {
    const filterRequest: GetTransactionsRequest = {
      ...walletTrxRequest!,
      limit: value,
      offset: 0,
    };

    setItemsPerPage(value);
    setSelectedPage(1);
    dispatch(setLimit(value));
    dispatch(setPage(1));
    dispatch(setOffset(0));
    dispatch(setTrxRequest(filterRequest));
  };

  const calculateOffset = (page: number, limit: number): number => {
    return (page - 1) * limit;
  };

  const handleSelectedPage = (value: number): void => {
    const offset: number = calculateOffset(value, limit);
    const filterRequest: GetTransactionsRequest = {
      ...walletTrxRequest!,
      limit: limit,
      offset: offset,
    };

    setSelectedPage(value);
    dispatch(setPage(value));
    dispatch(setTrxRequest(filterRequest));
    dispatch(setOffset(offset));
  };

  return {
    actions: {
      handleChangeLimit: handleChangeItemsPerPage,
      handleChangePage: handleSelectedPage,
      setPage: setSelectedPage,
    },
    limit: itemsPerPage,
    page: selectedPage,
    transactions,
  };
};
