/* istanbul ignore file */
// @ts-nocheck
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(2,51,101,1)",
      dark: "rgba(17,43,69,1)",
      light: "rgba(30,101,174,1)",
    },
    secondary: {
      main: "rgba(0,230,178,1)",
      dark: "rgba(13,194,152,1)",
      light: "rgba(222,255,247,1)",
    },
    info: {
      main: "rgba(68,152,238,1)",
      dark: "rgba(2,51,101,1)",
      light: "rgba(212,234,255,0.4)",
    },
    error: {
      main: "rgba(226,71,99,1)",
      dark: "rgba(173,12,42,1)",
      light: "rgba(255,223,229,0.3)",
    },
    warning: {
      main: "rgba(255,215,64,1)",
      dark: "rgba(207,113,26,1)",
      light: "rgba(254,249,236,1)",
    },
    success: {
      main: "rgba(0,230,178,1)",
      dark: "rgba(6,169,131,1)",
      light: "rgba(210,255,244,0.3)",
    },
    background: {
      paper: "#fff",
      default: "rgba(240,244,249,1)",
    },
    text: {
      primary: "rgba(41,48,54,1)",
      secondary: "rgba(180, 185, 190, 1)",
      disabled: "rgba(46, 61, 74, 0.36)",
    },
    grey: {
      A400: "rgba(41,48,54,1)",
      A700: "rgba(70,82,92,1)",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans,sans-serif",
    h1: {
      fontSize: "40px",
      fontWeight: 200,
      lineHeight: "52px",
      fontStyle: "normal",
      color: "#023365",
    },
    h2: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h4: {
      fontSize: "12px",
      fontWeight: 350,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 500,
      color: "#293036",
      fontStyle: "normal",
      lineHeight: "26px",
    },
  },
  overrides: {
    MuiTableContainer: {
      root: {
        backgroundColor: "#FFF",
      },
    },
    MuiTableRow: {
      hover: {
        "&:hover": {
          backgroundColor: "#F7FAFC !important",
        },
        "&$selected": {
          backgroundColor: "rgba(213, 234, 255, 0.4) !important",
        },
      },
    },
    MuiTableCell: {
      head: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "140%",
        color: "#677784",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
      sizeLarge: {
        fontSize: 17,
      },
      containedPrimary: {
        textTransform: "none",
        "&:hover": {
          color: "#FFF",
        },
      },
      outlinedPrimary: {
        border: "unset",
        borderRadius: "4px",
        backgroundColor: "#F7FAFC",
        textTransform: "none",
        color: "#023365",
        "&:hover": {
          border: "unset",
          backgroundColor: "rgba(213, 234, 255, 0.4)",
        },
      },
      outlined: {
        textTransform: "none",
      },
    },
    MuiAlert: {
      filledSuccess: {
        backgroundColor: "rgba(6,169,131,1)",
      },
    },
  },
});

export default theme;
