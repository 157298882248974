export enum SnackBarMessages {
  CREATE_ALARM_ERROR = "Hubo un error al crear la alarma",
  CREATE_ALARM_SUCCESS = "Alarma creada con éxito",
  INITIALIZE_INVESTIGATION = "Se inició investigación de forma correcta",
  ERROR_INITIALIZE_INVESTIGATION = "Ocurrió un error al intentar iniciar investigación, por favor inténtalo nuevamente.",
  SUCCESS_CANCEL_ALARM = "Alarma cerrada de forma correcta.",
  ERROR_CANCEL_ALARM = "Ocurrió un error al intentar cerrar la alarma, por favor inténtalo nuevamente.",
  UPDATE_ALARM = "Alarma actualizada con éxito",
  ERROR_UPDATE_ALARM = "Hubo un error al actualizar la alarma",
  ERROR_DOWNLOAD_FILE_ALARM = "Ocurrió un error al descargar las alarmas. Por favor inténtalo nuevamente.",
  SUCCESS_DOWNLOAD_FILE_ALARM = "Descarga completada.",
  SUCCESS_REACTIVATE_ALARM = "La alarma ha sido reactivada de forma correcta",
  ERROR_REACTIVATE_ALARM = "Ocurrio un problema al reactivar la alarma. Por favor inténtalo de nuevo",
  ERROR_DOWNLOAD_FILES_COMMENTS = "Se ha producido un error en la descarga.",
  ERROR_DUPLICATE_ALARM = "La alarma {alias} no se pudo guardar porque ya existe otra alarma con el mismo nombre. Por favor cambia el nombre",
  ERROR_DOWNLOAD_FILES = "Se ha producido un error en la descarga.",
  ERROR_UPLOAD_COMMERCIAL_EXECUTIVES = "Ocurrió un error en el proceso. Por favor inténtalo de nuevo",
  SUCCESS_UPLOAD_COMMERCIAL_EXECUTIVES = "La lista de ejecutivos fué actualizada de forma correcta",
}
