import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { IUseAlarmState } from "@containers/Alarms/Alarms.interfaces";
import { IGenericBreadcrumbProps } from "@components/GenericBreadcrumb/GenericBreadcrumbs.interfaces";
import {
  breadcrumbLabelEnum,
  pathEnum,
} from "@shared/constants/CatalogBreadcrumb";
import {
  AlarmLabels,
  MassiveAlarmLabels,
} from "@shared/constants/labels/alarmLabels";
import {
  IFilterAlarm,
  SearchAlarmByFilterRequest,
} from "../../../../types/search_alarm_by_filter_request";
import { SourceEnum } from "@shared/utils/activeAlarmsEnum";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import {
  downloadFileAlarms,
  saveActionAlarm,
  saveMassiveAlarm,
  searchAlarmByFilters,
} from "@store/thunks/alarms/alarms.thunk";
import { RootState } from "@store/store";
import {
  ITableCellProps,
  ITableRowProps,
  TableSelectionActionsProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { SearchAlarmByFilterResponse } from "../../../../types/search_alarm_by_filter_response";
import { cloneDeep, defaultTo, get, isEmpty, isEqual, unset } from "lodash";
import { Alarm } from "../../../../types/alarm";
import { ColumnsItem, IOptionListItemProps } from "@kushki/connect-ui";
import { ActionTitleEnum } from "@shared/enum/ActionTitleEnum";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  buildFlagCell,
  buildTagCell,
  buildTextCell,
  getLastCloseDate,
  getTagOptions,
  getVariablesRule,
  parseDateTable,
  verifyHierarchy,
} from "@shared/utils/ActiveAlarmsTableUtils";
import TableCellSkeleton from "@components/TableCellSkeleton/TableCellSkeleton";
import { useForm, UseFormReturn } from "react-hook-form";
import { ItemEmail } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldEmail/TextFieldEmail.interfaces";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { ActiveAlarmStatus } from "@shared/enum/ActiveAlarmStatusEnum";
import {
  setDetailCardAlarms,
  setUploadedFiles,
} from "@store/reducers/alarms/alarms.slice";
import { DEFAULT_ALARM } from "@shared/constants/AlarmConstants";
import { HeaderTableLabel } from "@shared/enum/HeaderTableLabel";
import { buildHeaderTable } from "@shared/utils/alarmsTableUtils";
import { columnsBySource } from "@shared/constants/SelectedColumns";
import { CellHeaderProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellHeader";
import { alarmsTableCells } from "@shared/constants/AlarmsTableCells";
import { useParams } from "react-router-dom";
import {
  descriptionDetailCard,
  getCloseDateDescriptionHistory,
  translateFrequency,
} from "@shared/utils/detailCardUtils";
import {
  AlarmDetailCardEnum,
  TitleType,
} from "@shared/enum/AlarmDetailCardEnum";
import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules";
import { ModalInvestigationLabels } from "@shared/constants/labels/modalInvestigationLabels";
import { CircularProgress } from "@mui/material";
import { ISaveFileRequestThunk } from "@store/interfaces/Alarms.interfaces";
import { columnsFileAlarmsBySource } from "@shared/catalogs/columnsFileAlarmsBySource";
import { ModalReactivateLabels } from "@shared/constants/labels/modalReactivateLabels";
import { IDetailCardRule } from "@components/AlarmTable/state/useAlarmTableState.interfaces";
import { ButtonsRowStyles } from "@shared/enum/buttonsRowDetailCard";
import DropDown from "@components/molecule/DropDown/DropDown";
import { VARIABLES_VALUES } from "@shared/constants/AlarmConfigConstants";
import { timeZoneHour } from "@shared/utils/parseDatesUtils";
import { ISortClick } from "@shared/interfaces/ISortClick";
import { NumberMapping } from "@components/GenericTable/state/useGenericTable.interfaces";

export const useAlarms = (): IUseAlarmState => {
  const dispatch = useAppDispatch();
  const {
    alarms,
    uploadedFiles,
    isLoadingTable,
    isLoadingUpdateAlarm,
    detailCardAlarms,
    isLoadingDownload,
    timezoneProps,
  } = useAppSelector((state: RootState) => ({
    ...state.alarms,
    ...state.general,
  }));
  const { type } = useParams();
  const pathParamType: SourceEnum = defaultTo(
    type,
    SourceEnum.ALARMS
  ) as SourceEnum;
  const titleComponent: string = isEqual(pathParamType, SourceEnum.HISTORY)
    ? AlarmLabels.HISTORIC_ALARMS
    : AlarmLabels.ACTIVE_ALARMS;

  const timeZoneStorage = timeZoneHour(
    localStorage.getItem("timeZone")!,
    "utc"
  );

  const [searchAlarmsRequest, setSearchAlarmsRequest] =
    useState<SearchAlarmByFilterRequest>({
      filter: {
        timeZone: timeZoneStorage,
      } as IFilterAlarm,
      limit: 10,
      offset: 0,
      source: defaultTo(pathParamType, SourceEnum.ALARMS),
    });
  const [isOpenCloseAlarmModal, setIsOpenCloseAlarmModal] =
    useState<boolean>(false);
  const [isDisabledDownload, setIsDisabledDownload] = useState<boolean>(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const [selectedColumns, setSelectedColumns] = useState<ColumnsItem[]>(
    columnsBySource[defaultTo(pathParamType, SourceEnum.ALARMS)]
  );

  const [rows, setRows] = useState<ITableRowProps[]>([]);

  const [sortClick, setSortClick] = useState<ISortClick>({
    field: "",
    numClick: 0,
  });

  const handleCountClick = (field: string): void => {
    const sortField: string = get(sortClick, "field", "");
    const numClick: number = get(sortClick, "numClick", 0);

    if (sortField !== field && sortField !== "") {
      setSortClick({ field, numClick: 1 });

      return;
    }

    setSortClick({ field, numClick: numClick >= 2 ? 0 : numClick + 1 });
  };

  const [headerTableCells, setHeaderTableCells] = useState<CellHeaderProps[]>(
    alarmsTableCells(
      handleCountClick,
      { field: sortClick.field },
      sortClick.numClick
    )
  );

  const sortOptions: NumberMapping<number> = {
    [0]: 1,
    [1]: 1,
    [2]: -1,
  };

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [merchantData, setMerchantData] = useState<Alarm>(DEFAULT_ALARM);
  const [isOpenInvestigation, setIsOpenInvestigation] =
    useState<boolean>(false);
  const [disabledButtonInvestigation, setDisabledButtonInvestigation] =
    useState<boolean>(false);
  const [emailsArray, setEmailsArray] = useState<ItemEmail[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState<string>(
    ModalInvestigationLabels.ADDITIONAL_INFO_PLACEHOLDER
  );
  const [alarmInfo, setAlarmInfo] = useState<string>("");
  const [canInitMassiveInvestigation, setCanInitMassiveInvestigation] =
    useState<boolean>(true);
  const [canReactivateMassive, setCanReactivateMassive] =
    useState<boolean>(true);
  const [isMassiveCloseAlarm, setIsMassiveCloseAlarm] =
    useState<boolean>(false);
  const [isOpenReactivateAlarmModal, setIsOpenReactivateAlarmModal] =
    useState<boolean>(false);
  const [reactivateAlarmId, setReactivateAlarmId] = useState<string>("");
  const [isMassiveReactivateAlarm, setIsMassiveReactivateAlarm] =
    useState<boolean>(false);

  const handleEmails = (newEmails: ItemEmail[]) => {
    setEmailsArray(newEmails);
  };

  const verifyInvestigationComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_ACTIVATE_INITIALIZE_INVESTIGATION
  );

  const verifyBtnInvestigationComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_BTN_INIT_INVESTIGATION
  );

  const verifyCloseAlarm: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_ACTIVATE_CLOSE_ALARM
  );

  const verifyReactivateAlarm: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_REACTIVATE_ALARM
  );

  const verifyBtnReactivateComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_MASSIVE_REACTIVATE_ALARM
  );

  const verifyInvestigationDetailCard: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_ACTIVATE_INITIALIZE_INVESTIGATION_CARD
  );

  const verifyHistoryDownload: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_DOWNLOAD_FILE
  );

  const verifyActiveDownload: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_DOWNLOAD_FILE
  );

  const verifyCloseAlarmDetailCard: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_ACTIVATE_CLOSE_ALARM_CARD
  );

  const verifyBtnCloseAlarm: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_BTN_CLOSE_ALARM
  );
  const handleCloseModalInvestigation = () => {
    setIsOpenInvestigation(false);
  };

  const [selectedAlarms, setSelectedAlarms] = useState<string[]>([]);

  const [selectedAlarm, setSelectedAlarm] = useState<Alarm>({} as Alarm);

  const handleOpenInvestigation = (alarm: Alarm) => {
    setMerchantData(alarm);
    setSelectedAlarm(alarm);
    setIsOpenInvestigation(true);
  };

  const isFirstRender = useRef(true);
  const isFiltersRender = useRef(true);

  const {
    setValue,
    control,
    watch,
    getValues,
    reset,
  }: UseFormReturn<ISaveFileRequestThunk> = useForm<ISaveFileRequestThunk>({
    defaultValues: {
      alarmId: "",
      comment: "",
    },
    mode: "onChange",
  });

  const handleHideCloseAlarmModal = () => {
    setIsOpenCloseAlarmModal(false);
    setIsMassiveCloseAlarm(false);
    reset();
    dispatch(setUploadedFiles([]));
  };

  const handleOpenCloseAlarmModal = (isMassive: boolean) => {
    setIsOpenCloseAlarmModal(true);
    setIsMassiveCloseAlarm(isMassive);
  };

  const handleOpenReactivateAlarmModal = (alarmId?: string) => {
    setReactivateAlarmId(defaultTo(alarmId, ""));
    setIsOpenReactivateAlarmModal(true);
    setIsMassiveReactivateAlarm(!alarmId);
  };

  const handleClickAction = (title: string, alarm: Alarm) => {
    const alarmId: string = get(alarm, "_id", "");

    switch (title) {
      case ActionTitleEnum.INIT_INVESTIGATION:
        setEmailsArray([]);
        handleOpenInvestigation(alarm);
        break;
      case ActionTitleEnum.CLOSE_ALARM:
        setValue("alarmId", alarmId);
        handleOpenCloseAlarmModal(false);
        break;
      case ActionTitleEnum.REACTIVATE_ALARM:
        handleOpenReactivateAlarmModal(alarmId);
        break;
    }
  };

  const handleSaveCloseAlarm = async () => {
    if (isMassiveCloseAlarm) {
      const alarmActionMassive: ISaveFileRequestThunk[] = selectedAlarms.map(
        (alarmId) => {
          const actAlarm: Alarm | undefined = get(alarms, "results", []).find(
            (alarm) => get(alarm, "_id", "") === alarmId
          );

          return {
            ...getValues(),
            alarmId: get(actAlarm, "_id", ""),
            merchantId: get(actAlarm, "merchantId", ""),
            notifyEmail: false,
            notifySlack: false,
          };
        }
      );

      await dispatch(saveMassiveAlarm(alarmActionMassive));

      return;
    }

    dispatch(saveActionAlarm(getValues()));
  };

  const handleInvestigation = async (isMassiveInvestigation?: boolean) => {
    setDisabledButtonInvestigation(true);

    const alarmActionRequest: ISaveFileRequestThunk = {
      action: ActiveAlarmStatus.INVESTIGATION,
      additionalInfo,
      alarmId: get(selectedAlarm, "_id", ""),
      alarmInfo,
      emails: emailsArray.map((itemMail: ItemEmail) => itemMail.mail),
      files: uploadedFiles,
      notifyEmail: true,
      notifySlack: true,
    };

    if (isMassiveInvestigation === true) {
      const alarmActionMassive: ISaveFileRequestThunk[] = selectedAlarms.map(
        (alarmId) => {
          const actAlarm: Alarm | undefined = get(alarms, "results", []).find(
            (alarm) => get(alarm, "_id", "") === alarmId
          );

          return {
            ...alarmActionRequest,
            additionalInfo: "",
            alarmId: get(actAlarm, "_id", ""),
            merchantId: get(actAlarm, "merchantId", ""),
            notifyEmail: false,
            notifySlack: false,
          };
        }
      );

      await dispatch(saveMassiveAlarm(alarmActionMassive));

      return;
    }

    await dispatch(saveActionAlarm(alarmActionRequest));
  };

  const handleOpenMassiveInvestigation = async () => {
    await handleInvestigation(true);
  };

  const reactivateModalLabels: Record<string, object> = {
    ["false"]: {
      description: ModalReactivateLabels.DESCRIPTION,
      title: ModalReactivateLabels.TITLE,
    },
    ["true"]: {
      description: ModalReactivateLabels.MASSIVE_DESCRIPTION,
      title: ModalReactivateLabels.MASSIVE_TITLE,
    },
  };

  const handleReactivateAlarm = () => {
    const reactivateAlarmRequest = {
      action: ActiveAlarmStatus.PENDING,
      alarmId: reactivateAlarmId,
    };

    if (isMassiveReactivateAlarm) {
      const alarmActionMassive: ISaveFileRequestThunk[] = selectedAlarms.map(
        (alarmId) => {
          const actAlarm: Alarm | undefined = get(alarms, "results", []).find(
            (alarm) => get(alarm, "_id", "") === alarmId
          );

          return {
            ...reactivateAlarmRequest,
            alarmId: get(actAlarm, "_id", ""),
            merchantId: get(actAlarm, "merchantId", ""),
          };
        }
      );

      dispatch(saveMassiveAlarm(alarmActionMassive));

      return;
    }

    dispatch(saveActionAlarm(reactivateAlarmRequest));
  };

  const closeReactivateAlarmModal = () => {
    if (isLoadingUpdateAlarm) return;

    setReactivateAlarmId("");
    setIsOpenReactivateAlarmModal(false);
    setIsMassiveCloseAlarm(false);
  };

  const handleBreadcrumbItem = (): IGenericBreadcrumbProps => {
    return {
      breadcrumbItems: [
        { label: breadcrumbLabelEnum.DASHBOARD_LABEL, url: pathEnum.DASHBOARD },
      ],
      lastItem: titleComponent,
    };
  };

  const handleFilterChange = (filterValues: IFilterAlarm) => {
    setSearchAlarmsRequest((prevState: SearchAlarmByFilterRequest) => ({
      ...prevState,
      filter: filterValues,
      offset: 0,
    }));
    setSelectedPage(1);
  };

  const verifySecurityComponentActions = (optionText: string): boolean => {
    switch (optionText) {
      case ActionTitleEnum.INIT_INVESTIGATION:
        return !verifyInvestigationComponent;
      case ActionTitleEnum.CLOSE_ALARM:
        return !verifyCloseAlarm;
      case ActionTitleEnum.REACTIVATE_ALARM:
      default:
        return !verifyReactivateAlarm;
    }
  };

  const verifySecurityComponentDetailCard = (optionText: string): boolean => {
    switch (optionText) {
      case ActionTitleEnum.INIT_INVESTIGATION:
        return !verifyInvestigationDetailCard;
      case ActionTitleEnum.CLOSE_ALARM:
        return !verifyCloseAlarmDetailCard;
      case ActionTitleEnum.REACTIVATE_ALARM:
      default:
        return !verifyReactivateAlarm;
    }
  };

  const verifySecurityComponentDownloadButton = (
    optionText: string
  ): boolean => {
    switch (optionText) {
      case SourceEnum.HISTORY:
        return !verifyHistoryDownload;
      case SourceEnum.ALARMS:
      default:
        return !verifyActiveDownload;
    }
  };

  const buildOptionSection = (
    optionText: string,
    ruleAlarm: Alarm
  ): IOptionListItemProps => {
    const isAlreadyOnInvestigation: boolean =
      optionText === ActionTitleEnum.INIT_INVESTIGATION &&
      get(ruleAlarm, "status", "") === ActiveAlarmStatus.INVESTIGATION;

    const isMigratedCloseAlarm: boolean =
      optionText === ActionTitleEnum.CLOSE_ALARM &&
      get(ruleAlarm, "isMigrated", false);

    return {
      disable:
        verifySecurityComponentActions(optionText) ||
        isAlreadyOnInvestigation ||
        isMigratedCloseAlarm,
      handleOptionClick: (event) => event.stopPropagation(),
      onClick: () => handleClickAction(optionText, ruleAlarm),
      optionText,
      type: "type1",
    };
  };

  const buildCellCustom = (
    skeleton: ReactElement,
    alarm: Alarm
  ): ITableCellProps => ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      child: (
        <DropDown
          values={VARIABLES_VALUES}
          items={getVariablesRule(alarm.variables)}
        />
      ),
      childSkeleton: skeleton,
    },
    type: TableBodyCellEnum.BASE,
  });

  const optionsListBySource = (
    alarm: Alarm
  ): Map<SourceEnum, IOptionListItemProps[]> =>
    new Map<SourceEnum, IOptionListItemProps[]>([
      [
        SourceEnum.ALARMS,
        [
          buildOptionSection(ActionTitleEnum.INIT_INVESTIGATION, alarm),
          buildOptionSection(ActionTitleEnum.CLOSE_ALARM, alarm),
        ],
      ],
      [
        SourceEnum.HISTORY,
        [
          buildOptionSection(ActionTitleEnum.REACTIVATE_ALARM, alarm),
          {} as unknown as IOptionListItemProps,
        ],
      ],
    ]);

  const isDisabledOptions: Record<ActiveAlarmStatus, boolean> = {
    [ActiveAlarmStatus.ROS]: false,
    [ActiveAlarmStatus.NORMAL]: false,
    [ActiveAlarmStatus.UNLOCK]: false,
    [ActiveAlarmStatus.BLOCKED]: false,
    [ActiveAlarmStatus.IN_PROCESS]: false,
    [ActiveAlarmStatus.INVESTIGATION]: true,
    [ActiveAlarmStatus.PENDING]: true,
  };

  const isDisabledBySource = (alarm: Alarm): Record<SourceEnum, boolean> => ({
    [SourceEnum.ALARMS]: false,
    [SourceEnum.HISTORY]: alarm.isMigrated
      ? true
      : isDisabledOptions[alarm.status],
  });

  const buildOptionsCells = (alarm: Alarm): ITableCellProps => {
    const paramSource: SourceEnum = defaultTo(
      pathParamType as SourceEnum,
      SourceEnum.ALARMS
    );

    return {
      props: {
        cellProps: {
          align: "center",
          spacing: 1,
        },
        disablePropagationOnClick: true,
        isDisabled: isDisabledBySource(alarm)[paramSource],
        optionListItems: optionsListBySource(alarm).get(paramSource)!,
      },
      type: TableBodyCellEnum.OPTIONS,
    };
  };

  const builderCellsCatalog = (
    row: Alarm,
    regionTz: string
  ): Record<string, ITableCellProps> => ({
    [HeaderTableLabel.ACTIVATION_DATE]: buildTextCell(
      parseDateTable(row.createdAt, regionTz),
      "oneLine"
    ),
    [HeaderTableLabel.ALIAS]: buildTextCell(get(row, "alias", ""), "twoLines"),
    [HeaderTableLabel.BRANCH_MERCHANT]: buildTextCell(
      row.merchantName,
      "twoLines",
      row.merchantId
    ),
    [HeaderTableLabel.OWNER]: verifyHierarchy(row.ownerName),
    [HeaderTableLabel.CUSTOMER]: verifyHierarchy(row.customerName),
    [HeaderTableLabel.CATEGORY]: buildTextCell(row.category, "oneLine"),
    [HeaderTableLabel.COUNTRY]: buildFlagCell(row.country),
    [HeaderTableLabel.FREQUENCY]: buildTextCell(
      translateFrequency[get(row, "frequency", "")],
      "oneLine"
    ),
    [HeaderTableLabel.VARIABLES]: buildCellCustom(<TableCellSkeleton />, row),
    [HeaderTableLabel.STATE]: buildTagCell(getTagOptions(row)),
    [HeaderTableLabel.CLOSE_DATE]: buildTextCell(
      parseDateTable(getLastCloseDate(get(row, "timeline", [])), regionTz),
      "oneLine"
    ),
  });

  const buildCells = (
    row: Alarm,
    selectedColumns: ColumnsItem[]
  ): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];

    selectedColumns.forEach((column: ColumnsItem) => {
      if (column.isSelected) {
        cells.push(
          builderCellsCatalog(row, get(timezoneProps, "regionTz")!)[
            column.label
          ]
        );
      }
    });
    cells.push(buildOptionsCells(row));

    return cells;
  };

  const buildRows = (
    data: SearchAlarmByFilterResponse,
    selectedColumns: ColumnsItem[]
  ): ITableRowProps[] => {
    return get(data, "results", []).map((row: Alarm) => ({
      cells: buildCells(row, selectedColumns),
      id: get(row, "_id", ""),
      rowProps: {
        color: "default",
        onClick: () =>
          dispatch(setDetailCardAlarms({ isOpen: true, selectedAlarm: row })),
      },
    }));
  };

  const calculateOffset = (page: number, itemsPerPage: number) => {
    setHeaderTableCells(
      cloneDeep(
        buildHeaderTable(
          selectedColumns,
          handleCountClick,
          { field: sortClick.field },
          sortClick.numClick
        )
      )
    );
    setSearchAlarmsRequest((prevState) => ({
      ...prevState,
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      source: pathParamType,
    }));
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    calculateOffset(1, value);
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    calculateOffset(value, searchAlarmsRequest.limit);
  };

  const handleSelectedRow = (selectedRows: string[]) => {
    setSelectedAlarms(selectedRows);
  };

  const verifyAllSelectedAlarmsStatus = (status: ActiveAlarmStatus[]) => {
    const selectedCoincidence: (Alarm | undefined)[] = selectedAlarms.map(
      (alarmId) =>
        get(alarms, "results", []).find(
          (result) => get(result, "_id", "") === alarmId
        )
    );

    return selectedCoincidence.every((alarm) =>
      status.includes(get(alarm, "status", "") as ActiveAlarmStatus)
    );
  };

  useEffect(() => {
    if (isEmpty(sortClick.field)) return;
    setHeaderTableCells(
      cloneDeep(
        buildHeaderTable(
          selectedColumns,
          handleCountClick,
          { field: sortClick.field },
          sortClick.numClick
        )
      )
    );
    setSearchAlarmsRequest((prevState: SearchAlarmByFilterRequest) => {
      prevState = {
        ...prevState,
        sort: {
          field: sortClick.field,
          order: sortOptions[sortClick.numClick],
        },
      };

      if (sortClick.numClick === 0) unset(prevState, "sort");

      return prevState;
    });
  }, [sortClick]);

  useEffect(() => {
    setCanInitMassiveInvestigation(
      verifyAllSelectedAlarmsStatus([ActiveAlarmStatus.PENDING])
    );
    setCanReactivateMassive(
      verifyAllSelectedAlarmsStatus([
        ActiveAlarmStatus.NORMAL,
        ActiveAlarmStatus.ROS,
      ])
    );
  }, [selectedAlarms]);

  const selectionActionsBySource = (
    icon: JSX.Element
  ): Record<SourceEnum, TableSelectionActionsProps | undefined> => {
    const massiveAlarmsLabels: object = MassiveAlarmLabels(
      selectedAlarms.length
    );

    return {
      [SourceEnum.ALARMS]: {
        counterText: get(massiveAlarmsLabels, "SELECTED_ALARMS"),
        description: get(
          massiveAlarmsLabels,
          "INIT_INVESTIGATION_DESCRIPTION",
          ""
        ) as unknown as JSX.Element,
        icon,
        onPrimaryButtonClick: handleOpenMassiveInvestigation,
        onSecondaryButtonClick: () => handleOpenCloseAlarmModal(true),
        primaryButtonColor: "primary",
        primaryButtonDisabled:
          !canInitMassiveInvestigation ||
          !verifyBtnInvestigationComponent ||
          isLoadingUpdateAlarm,
        primaryButtonIcon: !isMassiveCloseAlarm && isLoadingUpdateAlarm && (
          <CircularProgress size={20} />
        ),
        primaryButtonText: get(massiveAlarmsLabels, "INIT_INVESTIGATION", ""),
        secondaryButtonColor: "error",
        secondaryButtonDisabled: !verifyBtnCloseAlarm || isLoadingUpdateAlarm,
        secondaryButtonIcon: isMassiveCloseAlarm && isLoadingUpdateAlarm && (
          <CircularProgress size={20} />
        ),
        secondaryButtonText: get(massiveAlarmsLabels, "CLOSE_ALARM", ""),
        tooltipText: get(massiveAlarmsLabels, "INIT_INVESTIGATION_HELPER", ""),
      },
      [SourceEnum.HISTORY]: {
        counterText: get(massiveAlarmsLabels, "SELECTED_ALARMS"),
        description: get(
          massiveAlarmsLabels,
          "REACTIVATE_ALARM_DESCRIPTION",
          ""
        ) as unknown as JSX.Element,
        icon,
        onPrimaryButtonClick: () => handleOpenReactivateAlarmModal(),
        primaryButtonColor: "primary",
        primaryButtonDisabled:
          !canReactivateMassive ||
          !verifyBtnReactivateComponent ||
          isLoadingUpdateAlarm,
        primaryButtonIcon: isLoadingUpdateAlarm && (
          <CircularProgress size={20} />
        ),
        primaryButtonText: get(massiveAlarmsLabels, "REACTIVATE_ALARMS", ""),
        secondaryButtonColor: "primary",
        secondaryButtonIcon: "",
        tooltipText: get(massiveAlarmsLabels, "REACTIVATE_ALARM_HELPER", ""),
      },
    };
  };

  const getCountSelectedAlarms = (): string => {
    return selectedAlarms.length > 0
      ? `(${selectedAlarms.length.toString()})`
      : "";
  };

  const handleDownloadFileAlarms = () => {
    const columns: string[] =
      columnsFileAlarmsBySource[searchAlarmsRequest.source];

    dispatch(
      downloadFileAlarms({
        ...searchAlarmsRequest,
        alarmsId: selectedAlarms,
        columns,
        limit: get(alarms, "total", searchAlarmsRequest.limit),
        offset: 0,
      })
    );
  };

  useEffect(() => {
    dispatch(searchAlarmByFilters(searchAlarmsRequest));
  }, [searchAlarmsRequest]);

  useEffect(() => {
    setHeaderTableCells(
      cloneDeep(
        buildHeaderTable(
          selectedColumns,
          handleCountClick,
          { field: sortClick.field },
          sortClick.numClick
        )
      )
    );
    setRows(cloneDeep(buildRows(alarms, selectedColumns)));
  }, [alarms, selectedColumns]);

  useEffect(() => {
    if (pathParamType === searchAlarmsRequest.source) return;
    setSearchAlarmsRequest(() => ({
      filter: {
        timeZone: timeZoneStorage,
      } as IFilterAlarm,
      limit: 10,
      offset: 0,
      source: defaultTo(pathParamType, SourceEnum.ALARMS),
    }));
    setSelectedColumns(
      cloneDeep(columnsBySource[defaultTo(pathParamType, SourceEnum.ALARMS)])
    );
    handleItemsPerPage(10);
    isFiltersRender.current = true;
    setIsLoadingFilters(true);
  }, [pathParamType]);

  const handleCommentsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("comment", get(event, "target.value", ""));
  };

  const handleAdditionalInfoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalInfo(get(event, "target.value", ""));
  };

  const handleAlarmInfoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAlarmInfo(get(event, "target.value", ""));
  };

  useEffect(() => {
    if (isFiltersRender.current) {
      setIsLoadingFilters(false);
      isFiltersRender.current = false;

      return;
    }
  }, [isLoadingTable]);

  useEffect(() => {
    setValue("files", defaultTo(uploadedFiles, []));
  }, [uploadedFiles]);

  useEffect(() => {
    if (!verifySecurityComponentDownloadButton(pathParamType)) {
      if (isEqual(pathParamType, SourceEnum.HISTORY)) {
        if (
          get(selectedAlarms, "length", 0) > 0 ||
          get(searchAlarmsRequest, "filter.from", 0) > 0
        )
          setIsDisabledDownload(false);
        else setIsDisabledDownload(true);
      } else setIsDisabledDownload(false);
    }
  }, [selectedAlarms, searchAlarmsRequest]);

  useEffect(() => {
    if (isLoadingUpdateAlarm || isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    setAdditionalInfo(ModalInvestigationLabels.ADDITIONAL_INFO_PLACEHOLDER);
    setAlarmInfo("");
    closeReactivateAlarmModal();
    handleHideCloseAlarmModal();
    setIsOpenInvestigation(false);
    setDisabledButtonInvestigation(false);
    dispatch(searchAlarmByFilters(searchAlarmsRequest));
    dispatch(setDetailCardAlarms({ isOpen: false, selectedAlarm: null }));
  }, [isLoadingUpdateAlarm]);

  const handleChangeColumns = (columns: ColumnsItem[]) => {
    setSelectedColumns(columns);
  };

  const handleCloseDetailCardAlarms = () => {
    dispatch(setDetailCardAlarms({ isOpen: false, selectedAlarm: null }));
  };

  const buttonsRowDetailCard: IModalHeaderProps["buttonRow"] = [
    {
      color: "secondary",
      isDisabled: verifySecurityComponentDetailCard(
        ActionTitleEnum.CLOSE_ALARM
      ),
      onClick: () =>
        handleClickAction(
          ActionTitleEnum.CLOSE_ALARM,
          detailCardAlarms.selectedAlarm!
        ),
      text: AlarmDetailCardEnum.CLOSE_ALARM,
      variant: "outlined",
    },
    {
      color: "primary",
      isDisabled:
        verifySecurityComponentDetailCard(ActionTitleEnum.INIT_INVESTIGATION) ||
        get(
          detailCardAlarms,
          "selectedAlarm.status",
          "" as ActiveAlarmStatus
        ) === ActiveAlarmStatus.INVESTIGATION,
      onClick: () =>
        handleClickAction(
          ActionTitleEnum.INIT_INVESTIGATION,
          detailCardAlarms.selectedAlarm!
        ),
      text: AlarmDetailCardEnum.INIT_INVESTIGATION,
      variant: "contained",
    },
  ];

  const buttonsRowDetailCardHistory: IModalHeaderProps["buttonRow"] = [
    {
      color: ButtonsRowStyles.COLOR,
      isDisabled:
        verifySecurityComponentDetailCard(ActionTitleEnum.REACTIVATE_ALARM) ||
        isLoadingUpdateAlarm,
      onClick: () =>
        handleClickAction(
          ActionTitleEnum.REACTIVATE_ALARM,
          detailCardAlarms.selectedAlarm!
        ),
      text: ActionTitleEnum.REACTIVATE_ALARM,
      variant: ButtonsRowStyles.VARIANT,
      width: ButtonsRowStyles.WIDTH,
    },
  ];

  const showActionButtonsDetailCardHistory = () => {
    return [ActiveAlarmStatus.ROS, ActiveAlarmStatus.NORMAL].includes(
      get(detailCardAlarms, "selectedAlarm.status", "") as ActiveAlarmStatus
    ) && !get(detailCardAlarms, "selectedAlarm.isMigrated")
      ? buttonsRowDetailCardHistory
      : [];
  };

  const detailCardPropsAlarms = (): IDetailCardRule => {
    return {
      buttonsRow: buttonsRowDetailCard,
      createdBy: "",
      handleClose: handleCloseDetailCardAlarms,
      isOpen: detailCardAlarms.isOpen,
      rowData: detailCardAlarms.selectedAlarm,
      tag: { color: ButtonsRowStyles.EMPTY, text: ButtonsRowStyles.EMPTY },
      updatedBy: descriptionDetailCard<Alarm>(
        AlarmDetailCardEnum.LAST_UPDATED_BY,
        detailCardAlarms.selectedAlarm,
        TitleType.UPDATE
      ),
    };
  };

  const detailCardPropsHistory = (): IDetailCardRule => {
    return {
      buttonsRow: showActionButtonsDetailCardHistory(),
      createdBy: descriptionDetailCard<Alarm>(
        AlarmDetailCardEnum.LAST_UPDATED_BY,
        detailCardAlarms.selectedAlarm,
        TitleType.UPDATE
      ),
      handleClose: handleCloseDetailCardAlarms,
      isOpen: detailCardAlarms.isOpen,
      rowData: detailCardAlarms.selectedAlarm,
      tag: { color: ButtonsRowStyles.EMPTY, text: ButtonsRowStyles.EMPTY },
      updatedBy: getCloseDateDescriptionHistory(
        detailCardAlarms.selectedAlarm!
      ),
    };
  };

  const getDetailCardBySource: Record<SourceEnum, IDetailCardRule> = {
    [SourceEnum.ALARMS]: detailCardPropsAlarms(),
    [SourceEnum.HISTORY]: detailCardPropsHistory(),
  };

  return {
    closeAlarmModal: {
      control,
      handleCommentsChange,
      handleHideCloseAlarmModal,
      isDisabledCloseAlarm:
        isEmpty(watch("comment")) ||
        isEmpty(watch("action")) ||
        isLoadingUpdateAlarm,
      isOpenCloseAlarmModal,
    },
    countSelectedAlarms: getCountSelectedAlarms(),
    detailCard: getDetailCardBySource[pathParamType],
    disabledButtonInvestigation,
    downloadAlarmProps: {
      handleDownloadFileAlarms,
      isLoadingDownload,
    },
    emailsArray,
    footer: {
      handleItemsPerPage,
      handleSelectedPage,
      itemsPerPage: defaultTo(searchAlarmsRequest.limit, 10),
      selectedPage,
      total: get(alarms, "total", 0),
    },
    handleAdditionalInfoChange,
    handleAlarmInfoChange,
    handleBreadcrumbItem,
    handleChangeColumns,
    handleCloseModalInvestigation,
    handleEmails,
    handleFilterChange,
    handleInvestigation,
    handleSaveCloseAlarm,
    handleSelectedRow,
    headerTableCells,
    isDisabledDownload,
    isLoadingFilters,
    isLoadingTable,
    isOpenInvestigation,
    merchantData,
    pathParamType,
    reactivateAlarmModal: {
      closeReactivateAlarmModal: closeReactivateAlarmModal,
      description: get(
        reactivateModalLabels[`${isMassiveReactivateAlarm}`],
        "description",
        ""
      ),
      isDisabledReactivateAlarm: isLoadingUpdateAlarm,
      isOpenReactivateAlarmModal,
      reactivateAlarm: handleReactivateAlarm,
      title: get(
        reactivateModalLabels[`${isMassiveReactivateAlarm}`],
        "title",
        ""
      ),
    },
    rows,
    searchAlarmsRequest,
    selectedColumns,
    selectionActions: (icon) => selectionActionsBySource(icon)[pathParamType],
    title: titleComponent,
  };
};
