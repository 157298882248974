import { makeStyles } from "@material-ui/core/styles";

export const validationDetailSkeletonStyles = makeStyles({
  mainGrid: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    padding: "9px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
});
