import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { AxiosResponse } from "axios";
import { GetBalanceResponse } from "../../../../types/get_balance_response";
import { API_ROUTES } from "../../../shared/constants/routes/api_routes";

export const getBalanceByMerchantId = createAsyncThunk<
  GetBalanceResponse,
  string
>("balance/getFilteredBalanceFromBranchList", async (merchantId) => {
  let response: GetBalanceResponse = {};

  try {
    const getBalanceResponse: AxiosResponse<GetBalanceResponse> =
      await axios.get<GetBalanceResponse>(API_ROUTES.GET_BALANCE(merchantId));

    response = getBalanceResponse.data;
  } finally {
  }

  return response;
});
