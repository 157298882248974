import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionInfo } from "../../../types/transactions_data";
import { ITabInfo } from "../../components/GenericTabs/GenericTabs.interfaces";
import { get, isEmpty } from "lodash";
import { catalogTabListByTransaction } from "../catalogs/CatalogTabListByTransaction";
import {
  CardTypeLabelEnum,
  CardTypeValueEnum,
  PayTypeLabelEnum,
} from "../enums/CardInformationEnum";
import { catalogSectionListByTransaction } from "../catalogs/CatalogSectionListByTransaction";
import {
  ContentCard,
  ContentTaxes,
} from "../../components/CardInformation/CardInformation.interfaces";
import { catalogTaxListByTransaction } from "../catalogs/CatalogTaxListByTransaction";
import { catalogSectionFieldsByTransaction } from "../catalogs/CatalogSectionFieldListByTransaction";

export const getColorByStatus = (status: string) => {
  switch (status) {
    case TransactionStatusEnum.APPROVED:
    case TransactionStatusEnum.APPROVAL:
      return "success";
    case TransactionStatusEnum.DECLINED:
      return "error";
    default:
      return "info";
  }
};

export const isApproval = (status: string): boolean => {
  return [
    TransactionStatusEnum.APPROVED,
    TransactionStatusEnum.APPROVAL,
  ].includes(status as TransactionStatusEnum);
};

export const base64ToBlob = (base64: string): Blob => {
  const byteArray: Uint8Array = Uint8Array.from(
    atob(base64)
      .split("")
      .map((char) => char.charCodeAt(0))
  );

  return new Blob([byteArray], { type: "application/pdf" });
};

export const getCardType = (
  transactionType: string | null | undefined
): string => {
  if (!transactionType) return CardTypeLabelEnum.DEFAULT;

  const normalizedType: string = transactionType.toUpperCase();

  switch (normalizedType) {
    case CardTypeValueEnum.CREDIT.toUpperCase():
      return CardTypeLabelEnum.CREDIT;
    case CardTypeValueEnum.DEBIT.toUpperCase():
      return CardTypeLabelEnum.DEBIT;
    default:
      return CardTypeLabelEnum.DEFAULT;
  }
};
export const getPayType = (numberMonths: number): string => {
  return numberMonths <= 1
    ? PayTypeLabelEnum.CURRENT
    : PayTypeLabelEnum.DEFERRED;
};

const validateTrxCatalog = (section: object, transaction: TransactionInfo) => {
  if (
    !isEmpty(
      get(
        section,
        `${transaction.transaction_status}.${[transaction.transaction_type]}.${[
          transaction.payment_method,
        ]}`,
        ""
      )
    )
  )
    return section[get(transaction, "transaction_status", "")][
      get(transaction, "transaction_type", "")
    ][get(transaction, "payment_method", "")];

  return [];
};

export const renderTabsByTransaction = (
  transaction: TransactionInfo,
  tabList: ITabInfo[]
): ITabInfo[] => {
  const tabsByType: Record<string, object> =
    catalogTabListByTransaction(tabList);

  return validateTrxCatalog(tabsByType, transaction);
};

export const renderSectionsByTransaction = (
  transaction: TransactionInfo,
  sectionList: ContentCard[]
): ContentCard[] => {
  const sectionByType: Record<string, object> =
    catalogSectionListByTransaction(sectionList);

  return validateTrxCatalog(sectionByType, transaction);
};

export const renderSectionsTaxesByTransaction = (
  transaction: TransactionInfo,
  taxList: ContentTaxes[]
): ContentTaxes[] => {
  const sectionTaxesByType: Record<string, object> =
    catalogTaxListByTransaction(taxList);

  return validateTrxCatalog(sectionTaxesByType, transaction);
};

export const renderFieldsSectionByTransaction = (
  transaction: TransactionInfo,
  contentCard: ContentCard
): ContentCard => {
  const sectionFieldsByType: Record<string, object> =
    catalogSectionFieldsByTransaction(contentCard.body);

  return {
    ...contentCard,
    body: sectionFieldsByType[get(transaction, "transaction_status", "")][
      get(transaction, "transaction_type", "")
    ][get(transaction, "payment_method", "")][get(contentCard, "type", "")],
  };
};
