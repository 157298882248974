import React from "react";
import {
  mainSubHeaderStyles,
  mainSubHeaderStyles as styles,
} from "./SubHeader.styles";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CountryEnum } from "../../shared/countryEnum";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconEye, ModalSimple } from "@kushki/connect-ui";
import { buttonWithoutBorder } from "../../containers/BasicFormStateContainerMasive/BasicStateContainerMassive.styles";
import SelectedBranch from "../SelectedBranch/SelectedBranch";
import {
  BR_DESCRIPTION,
  BR_SALIR,
  BR_TITLE,
  CANCELAR,
} from "../../shared/constants/labels/ModalConstants";
import { redirectPath } from "../../shared/utils/company_data_utils";
import { useSubHeaderState } from "./state/useSubHeaderState";
import {
  SubHeaderMerchantMassiveSkeleton,
  SubHeaderMerchantMassiveTitleSkeleton,
} from "../Skeletons/SubHeaderMerchantSkeleton/SubHeaderMerchantMassiveSkeleton";

export interface SubHeaderBranchMassiveProps {
  country: CountryEnum;
  title?: string;
  numberBranches?: string;
  isEdit?: boolean;
}

export const SubheaderBranchMassive: React.FC<SubHeaderBranchMassiveProps> = (
  props: SubHeaderBranchMassiveProps
) => {
  const {
    openSearchBranchModal,
    openModalReturn,
    handleOpenModal,
    handleOpenSearchBranchModal,
    title,
  } = useSubHeaderState(props.title);

  return (
    <Container disableGutters maxWidth={false}>
      <Box>
        <Grid
          container
          spacing={1}
          sx={styles.gridButtonBack}
          alignItems="center"
        >
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <IconButton
              data-testid={"iconButton"}
              style={{ color: "#4399ED" }}
              onClick={() => {
                handleOpenModal(true);
              }}
            >
              <ArrowBackIosIcon />
              Regresar
            </IconButton>
          </Grid>
        </Grid>

        {props.numberBranches ? (
          <Grid container alignItems="center">
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Typography
                variant="body1"
                color="text.primary"
                fontSize={16}
                fontWeight={500}
              >
                {`${props.numberBranches} branches a editar:`}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <SubHeaderMerchantMassiveSkeleton />
        )}
        {props.title ? (
          <Grid container alignItems="center" sx={styles.gridTitle} spacing={1}>
            <Grid item sx={mainSubHeaderStyles.branchesTitle}>
              <Typography sx={mainSubHeaderStyles.branchesTitleTypography}>
                {title}
              </Typography>
            </Grid>
            <Grid item sx={mainSubHeaderStyles.branchesButton}>
              <Button
                data-testid={"buttonBack"}
                color="secondary"
                variant="outlined"
                startIcon={<IconEye fontSize="small" />}
                sx={buttonWithoutBorder.borderNone}
                onClick={() => {
                  handleOpenSearchBranchModal(true);
                }}
                size="small"
              >
                Ver más
              </Button>
              {openSearchBranchModal && (
                <SelectedBranch
                  isOpen={true}
                  openBranchModal={handleOpenSearchBranchModal}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <SubHeaderMerchantMassiveTitleSkeleton />
        )}
      </Box>
      <ModalSimple
        onClickPrimary={() => {
          redirectPath("/create-node/branch/selection");
        }}
        onClickSecondary={() => {
          handleOpenModal(false);
        }}
        buttonPrimary={BR_SALIR}
        buttonSecondary={CANCELAR}
        descriptionText={BR_DESCRIPTION}
        isOpen={openModalReturn}
        onClose={() => {
          handleOpenModal(false);
        }}
        titleText={BR_TITLE}
        typeModal={1}
      />
    </Container>
  );
};

export default SubheaderBranchMassive;
