import React from "react";
import {
  createStyles,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@material-ui/core";
import ModifiedIcon from "../../assets/images/modified-icon.png";
import InconsistIcon from "../../assets/images/inconsist.png";
import ModifiedIconColombia from "../../assets/images/modified.png";
import RejectIcon from "../../assets/images/reject.png";
import PendingIcon from "../../assets/images/pending-icon.png";
import OmittedIcon from "../../assets/images/omited-icon.png";
import ManualIcon from "../../assets/images/manual_icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusEnum";
import { CountryList } from "../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { get } from "lodash";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { IndicatorsMessageEnum } from "../../shared/infrastructure/IndicatorsMessageEnum";
import { StatusValuesEnum } from "../../shared/infrastructure/enum/StatusValuesEnum";

export interface IIndicatorProps {
  statusFlow: StatusFlowEnum;
  isRetentionCountry?: boolean;
  isHistoric?: boolean;
  country?: CountryList;
}
const styles = makeStyles((theme: Theme) =>
  createStyles({
    indicatorStyles: {
      minWidth: `${theme.spacing(2)}px`,
      paddingRight: theme.spacing(1),
    },
    root: {
      marginTop: theme.spacing(3),
    },
  })
);

export const Indicators: React.FC<IIndicatorProps> = (
  props: IIndicatorProps
) => {
  const classes = styles();
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={6} className={classes.root}>
        <Grid item>
          <ListItem disableGutters>
            <ListItemIcon className={classes.indicatorStyles}>
              <img
                src={
                  get(props, "country", "") === CountryEnum.colombia
                    ? InconsistIcon
                    : ModifiedIcon
                }
                alt={
                  get(props, "country", "") === CountryEnum.colombia
                    ? "inconsist"
                    : "modified-icon"
                }
              />
            </ListItemIcon>
            <ListItemText
              primary={
                get(props, "country", "") === CountryEnum.colombia
                  ? IndicatorsMessageEnum.INCONSISTENT_AMOUNT
                  : IndicatorsMessageEnum.MODIFIED_VALIDATOR
              }
            />
          </ListItem>
        </Grid>
        {props.country !== CountryEnum.colombia && (
          <Grid item>
            <ListItem>
              <ListItemIcon className={classes.indicatorStyles}>
                <img
                  src={
                    get(props, "country", "") === CountryEnum.colombia
                      ? RejectIcon
                      : PendingIcon
                  }
                  alt={
                    get(props, "country", "") === CountryEnum.colombia
                      ? "reject icon"
                      : "pending icon"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  get(props, "country", "") === CountryEnum.colombia
                    ? IndicatorsMessageEnum.REJECT_VALIDATOR
                    : IndicatorsMessageEnum.PENDING
                }
              />
            </ListItem>
          </Grid>
        )}
        <Grid item>
          <ListItem disableGutters>
            <ListItemIcon className={classes.indicatorStyles}>
              <img
                src={
                  get(props, "country", "") === CountryEnum.colombia
                    ? ModifiedIconColombia
                    : OmittedIcon
                }
                alt={
                  get(props, "country", "") === CountryEnum.colombia
                    ? "modified Icon Colombia"
                    : "omited icon"
                }
              />
            </ListItemIcon>
            {/*VALIDAR*/}
            <ListItemText
              id={"omit"}
              primary={
                props.statusFlow === StatusFlowEnum.EXECUTOR
                  ? get(props, "country", "") === CountryEnum.colombia
                    ? IndicatorsMessageEnum.MODIFIED_VALIDATOR
                    : IndicatorsMessageEnum.OMITTED
                  : props.statusFlow === StatusFlowEnum.VALIDATOR &&
                    props.isRetentionCountry
                  ? IndicatorsMessageEnum.MODIFIED_VALIDATOR
                  : IndicatorsMessageEnum.REJECTED
              }
            />
          </ListItem>
        </Grid>
        <Grid item>
          <ListItem>
            <ListItemIcon className={classes.indicatorStyles}>
              <img src={ManualIcon} alt="manual icon" />
            </ListItemIcon>
            <ListItemText primary={StatusValuesEnum.MANUAL} />
          </ListItem>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
