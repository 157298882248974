import React, { useEffect, useState } from "react";

import { RotateCcw } from "react-feather";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { WebhookNotification } from "../../../types/webhook_notification";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";

export interface IResendNotificationButtonProps {
  webhookNotification: WebhookNotification;
  handleReprocessWebhookNotification: (payload: WebhookNotification) => void;
}

const ResendNotificationButton: React.FC<IResendNotificationButtonProps> = ({
  handleReprocessWebhookNotification,
  webhookNotification,
}: IResendNotificationButtonProps) => {
  const isLoading = useSelector(
    (state: IAppState) => state.isLoadingResendNotification
  );
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const handleClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSelected(true);
    handleReprocessWebhookNotification(webhookNotification);
  };

  useEffect(() => {
    if (!isLoading && isSelected) setIsSelected(false);
  }, [isLoading]);

  return (
    <Tooltip title={"Reenvio de notificación"}>
      <IconButton
        color="primary"
        component="span"
        onClick={handleClick}
        disabled={isLoading && isSelected}
      >
        {isLoading && isSelected ? (
          <CircularProgress size={20} />
        ) : (
          <RotateCcw size={20} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ResendNotificationButton;
