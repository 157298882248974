/**
 * Processor's Country Name
 */
export enum CountryNameEnum {
  ecuador = "Ecuador",
  peru = "Peru",
  colombia = "Colombia",
  chile = "Chile",
  mexico = "Mexico",
}
