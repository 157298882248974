import { billingSlice } from "../../reducers/billing/billing.slice";

export const {
  updateForm,
  updateValuesBilling,
  updateValuesSubtractBilling,
  updateValuesDeclinedBilling,
  clearAllValuesBilling,
  setIsLoadingBillingInformation,
} = billingSlice.actions;
