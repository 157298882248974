import { navigateToUrl } from "single-spa";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { EXTERNAL_ROUTES } from "../../../shared/constants/routes";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { getWebhookList } from "../../../store/thunks/developers/developers.thunks";
import { defaultTo, get } from "lodash";
import { IUseFormWrapper } from "./useFormWrapper.interfaces";
import { useSelector } from "react-redux";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { DEFAULT_LIMIT_WEBHOOK_LIST } from "../../CustomerWebhooks/state/useCustomerWebhooks";
import { validIsCentralized } from "../../../shared/utils/centralized/validIsCentralized";
import { ConfigPropName } from "../../../shared/enums/ConfigurationIdEnum";

export const useFormWrapper = (): IUseFormWrapper => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const merchantId = defaultTo(
    searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID),
    ""
  );
  const [isCentralized, setIsCentralized] = useState<boolean>(false);
  const { webhookList } = useAppSelector((state) => state.app);
  const nodeInfo = useSelector(selectNodeInfo);

  const [isThereWebhooks, setIsThereWebhooks] = useState<boolean>(
    webhookList.items.length !== 0
  );

  const getWebhooksListByMerchant = () => {
    dispatch(
      getWebhookList({
        limit: DEFAULT_LIMIT_WEBHOOK_LIST + 1,
        merchantId: merchantId,
      })
    );
  };

  const handleRedirectOnClick = () => {
    navigateToUrl(
      `${EXTERNAL_ROUTES.MERCHANT_WEBHOOK_CREATE}?${QueryParamsEnum.PUBLIC_MERCHANT_ID}=${merchantId}&menuItem=true`
    );
  };

  useEffect(() => {
    if (nodeInfo) {
      const entityName: string = nodeInfo.entityName;
      let isCentralizedMerchant: boolean = false;

      nodeInfo?.configs.forEach((obj: any) => {
        if (obj.configuration === "cn014") {
          isCentralizedMerchant = validIsCentralized(
            entityName,
            get(obj, ConfigPropName.CentralizedNodeId, "")
          );
          setIsCentralized(isCentralizedMerchant);
        }
      });
    }
  }, [nodeInfo]);

  useEffect(() => {
    getWebhooksListByMerchant();
  }, []);

  useEffect(() => {
    if (webhookList.items.length !== 0) setIsThereWebhooks(true);
    else setIsThereWebhooks(false);
  }, [webhookList]);

  return {
    handleRedirectOnClick,
    isCentralized,
    isThereWebhooks,
    webhookList,
  };
};
