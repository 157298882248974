import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { ICheckListGroup } from "./interfaces";
import { ServiceOptionEnum } from "../PaymentMethodCheckList/enums";

const CheckboxList = ({
  title,
  items,
  selectedItems,
  onChange,
}: ICheckListGroup) => {
  items = items.filter(
    (item) => item.value !== ServiceOptionEnum.CHARGEBACK_VOID
  );

  return (
    <FormControl variant="outlined" fullWidth margin="none">
      <FormLabel>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: "400 !important", lineHeight: "150%" }}
        >
          {title}
        </Typography>
      </FormLabel>
      <Box>
        <FormGroup row={false}>
          {items.map(
            ({ label, value }, index) =>
              !!selectedItems && (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      color="primary"
                      onChange={() => onChange(value)}
                      checked={selectedItems.indexOf(value) > -1}
                    />
                  }
                  label={`${label}`}
                />
              )
          )}
        </FormGroup>
      </Box>
    </FormControl>
  );
};

export default CheckboxList;
