import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const ItemCardStyles = createNamedStyles({
  cardContainer: {
    borderRadius: "8px",
    display: "inline-block",
  },
  cardHeader: {
    "& .MuiTypography-root": {
      fontSize: "15px",
    },
    color: "#6D7781",
    p: 2,
  },
});
