import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { ButtonConstants } from "../../shared/infrastructure/constants/ButtonConstants";
import { ConfigByFileConstants } from "../../shared/infrastructure/constants/ConfigByFileConstants";
import { configByFileIndexStyles } from "./ConfigByFileIndex.styles";
import { LoaderModal } from "../../components/common/LoaderModal/LoaderModal";
import { ModalError } from "../../components/Modals/ModalError/ModalError";
import { QueryParamsEnums } from "../../shared/infrastructure/Enums/QueryParamsEnums";
import React from "react";
import { UploadCard } from "../../components/UploadCard/UploadCard";
import { useConfigByFileIndexState } from "./state/useConfigByFileIndexState";

export const ConfigByFileIndex: React.FC = () => {
  const classes = configByFileIndexStyles();
  const {
    breadCrumbs,
    handlers,
    params,
    fileUploader,
    handleCloseAlertModal,
    state,
    errorModal,
    modal,
  } = useConfigByFileIndexState();

  return (
    <Container fixed>
      <Grid container>
        <Grid container item direction={"column"}>
          <Grid item>
            <Breadcrumb
              items={breadCrumbs.items}
              lastItem={breadCrumbs.lastItem}
            />
          </Grid>
          <Grid item>
            <Typography
              variant={"h1"}
              color={"primary"}
              className={classes.marginGrid}
            >
              {ConfigByFileConstants.ITEM_TITLE}
            </Typography>
            <ModalError
              open={errorModal.openModal}
              errors={errorModal.errors}
              handleClose={errorModal.actionHandlerClose}
            />
          </Grid>
          {params.typeParam === QueryParamsEnums.DISPERSION_CYCLE_TYPE && (
            <Container>
              <Grid item>
                <UploadCard
                  fileUploader={fileUploader}
                  country={params.countryParam}
                  textReplace={ConfigByFileConstants.TEXT_REPLACE}
                  modal={modal}
                />
              </Grid>
              <Grid item>
                <Box display="flex" pt={5} justifyContent={"flex-end"}>
                  <Button
                    variant="outlined"
                    size={"large"}
                    onClick={handlers.handleClickReturnGlobalConfig}
                    className={classes.buttonBack}
                  >
                    {ButtonConstants.BTN_COME_BACK}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size={"large"}
                    disabled={fileUploader.disableUploadButton}
                    onClick={fileUploader.handleUploadFile}
                  >
                    {ButtonConstants.BTN_UPLOAD_FILE}
                  </Button>
                </Box>
              </Grid>
            </Container>
          )}
          <LoaderModal
            open={state.isLoadingDispersion!}
            handleClose={handleCloseAlertModal}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
