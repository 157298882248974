import React from "react";
import { Badge, Button, Paper, Popover, Typography } from "@mui/material";
import { FilterBox } from "./FilterBox/FilterBox";
import { useFilterComponentState } from "./state/useFilterComponentState";
import { FilterComponentProps } from "./FilterComponent.interfaces";
import { filterComponentStyles } from "./FilterComponent.styles";
import FilterIcon from "../../assets/icons/FilterIcon";

export const FilterComponent: React.FC<FilterComponentProps> = (props) => {
  const {
    anchorEl,
    counterFilters,
    filters,
    handlers,
    filterButtonsDisabled,
    rangeAmount,
  } = useFilterComponentState(props);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <Button
        id={"buttonFilter"}
        sx={filterComponentStyles.filterButton}
        aria-describedby={id}
        size={"medium"}
        startIcon={<FilterIcon sx={filterComponentStyles.defaultColor} />}
        variant="outlined"
        onClick={(event) => handlers.handleClick(event.currentTarget)}
      >
        <Badge color="secondary" badgeContent={counterFilters}>
          <Typography variant="body1">Filtro</Typography>
        </Badge>
      </Button>
      <Popover
        sx={filterComponentStyles.filterPopover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlers.handleClose(false)}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <Paper sx={filterComponentStyles.paperStyle}>
          <FilterBox
            filters={filters}
            rangeAmount={rangeAmount}
            onApplyFilter={handlers.handleGetFilters}
            onRangeAmount={handlers.handleChangeRangeAmount}
            onRestoreFilters={handlers.handleRestoreFilter}
            filterButtonsDisabled={filterButtonsDisabled}
            setFilterButtonsDisabled={handlers.setFilterButtonsDisabled}
            currentTab={props.currentTab}
          />
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

export default FilterComponent;
