import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const ItemCategoryStyles: IStyles = {
  accordion: {
    "& .Mui-expanded": {
      height: "19px",
      margin: "0px 0px !important",
      minHeight: "19px !important",
    },
    "& .Mui-expanded:first-of-type": {
      margin: "0px 0px !important",
    },
    "& .Mui-expanded:last-of-type": {
      margin: "0px 0px !important",
    },
    ":hover": {
      boxShadow: "none",
    },
    boxShadow: "none",
    margin: "0px 0px !important",
    maxWidth: "447px",
    padding: "0px 0px 0px 0px !important",
    width: "447px",
  },
  acordionDetail: {
    padding: "8px 0px 0px 0px",
  },
  acordionExpandIcon: {
    height: "16px",
    width: "16px",
  },
  containerChip: {
    marginLeft: "12px",
    marginTop: "-8px",
  },
  formChip: {
    marginTop: "8px",
  },
  placeholder: {
    color: "#6D7781",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "0.0015em",
    lineHeight: "120%",
  },
  selectFormControl: {
    "& .MuiInputLabel-shrink": {
      backgroundColor: "transparent",
      display: "none",
    },
    "& .MuiSelect-outlined": {
      padding: "9px 10px 10px 10px",
    },
    margin: "0px",
    width: "387px",
  },
  summary: {
    height: "19px",
    margin: "0px 0px",
    minHeight: "19px",
    padding: "0px",
  },
};

export { ItemCategoryStyles };
