export enum PatternEnum {
  alfa1To50 = "^[a-zA-Z0-9 ]{1,50}$",
  alfa12To13 = "^[a-zA-Z0-9]{12,13}$",
  alfa12To18 = "^[a-zA-Z0-9]{12,18}$",
  alfa18 = "^[a-zA-Z0-9]{18,18}$",
  email = "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$",
  phone = "^(\\+)?[0-9]{1,50}$",
  numeric9to12 = "^[0-9]{9,12}$",
  zipCode = "^[a-zA-Z0-9]{1,10}$",
  numeric7to13 = "^[0-9]{7,13}$",
  numeric10to13 = "^[0-9]{10,13}$",
  numeric3to20 = "^[0-9]{3,20}$",
  numeric5to20 = "^[0-9]{5,20}$",
  numeric5to10 = "^[0-9]{5,10}$",
  numeric11to15 = "[0-9]{11,15}",
  numeric8to9 = "[0-9]{8,9}",
  numeric9 = "[0-9]{9,9}",
  numeric10 = "[0-9]{10,10}",
  numeric13 = "[0-9]{13,13}",
  numeric18 = "[0-9]{18,18}",
  numeric12to13 = "[0-9]{12,13}",
  numeric11 = "[0-9]{11,11}",
  numeric14 = "[0-9]{14,14}",
  numeric7to9 = "[0-9]{7,9}",
  url = "^((http(s)?:\\/\\/)?)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/]+$",
  genericValidation = "^[a-zA-Z0-9]{5,20}$",
}
