import React, { useEffect } from "react";
import { DeferredStatusEnum } from "../../../shared/infrastructure/constants/DeferredStatusEnum";
import { IUseRowMenuTableState } from "../RowMenuTable";
import { ButtonTextEnum } from "../../../shared/infrastructure/constants/DialogEnum";
import {
  getNodeInfo,
  updateDeferredStatusMerchant,
} from "../../../store/actionCreators";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { useDispatch, useSelector } from "react-redux";
import { StatusEnum } from "../../../shared/infrastructure/constants/statusEnum";
import { IAppState } from "../../../store/reducer";
import { auth } from "../../../shared/auth";
import { get, isEmpty } from "lodash";
import { ConfirmModalProps } from "../../ConfirmModal/ConfirmModal";
import { StatusModalEnum } from "../../../shared/infrastructure/constants/StatusModalEnum";
import { UPDATE_DEFERRED_SUCCESS_BRAZIL } from "../../../shared/infrastructure/constants/CreateDeferredConstants";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";

export interface IUseRowMenuTableResponseState {
  anchorEl: (EventTarget & HTMLButtonElement) | null;
  deferredId: string | undefined;
  handleEvents: {
    handleMenuClick: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    handleClose: () => void;
    handleChangeStatus: () => void;
    handleOpenStatusModal: () => void;
  };
  modalProps: ConfirmModalProps;
  isLoadingAcceptAction: boolean;
  status: string;
  statusModalOpen: boolean;
  isCentralizeNodeBrasil: boolean;
}

export const useRowMenuTableState = (
  props: IUseRowMenuTableState
): IUseRowMenuTableResponseState => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [status, setStatus] = React.useState<string>("");
  const [deferredId, setDeferredId] = React.useState<string>(props.id || "");
  const [statusModal, setStatusModal] = React.useState<boolean>(false);
  const [isCentralizeNodeBrasil, setIsCentralizeNodeBrasil] =
    React.useState<boolean>(false);
  const [modalProps, setModalProps] = React.useState({});
  const dispatch = useDispatch();
  const publicMerchantId = useSelector(
    (state: IAppState) => state.merchant?.publicMerchantId || ""
  );
  const isLoading = useSelector((state: IAppState) => state.loading || false);
  const nodeInfo = useSelector((state: IAppState) => state.nodeInfoMerchant);

  const merchantInformation = auth.getAuthMerchant();
  const country = get(merchantInformation, "country");

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = () => {
    setAnchorEl(null);
  };
  const updateDeferredMerchantState = () => {
    if (!isEmpty(deferredId)) {
      const notificationTheme: INotification = {
        customColor: "rgb(88,191,154)",
        icon: true,
        message: UPDATE_DEFERRED_SUCCESS_BRAZIL,
        open: true,
        type: "success",
      };

      const newState =
        props.status === StatusEnum.ENABLED
          ? StatusEnum.DISABLED
          : StatusEnum.ENABLED;

      dispatch(
        updateDeferredStatusMerchant(
          country as CountriesEnum,
          deferredId,
          newState as StatusEnum,
          publicMerchantId,
          notificationTheme
        )
      );
    }
  };

  /* Status Modal Props */

  const handleOpenStatusModal = () => {
    setStatusModal(true);
  };
  const handleAcceptModal = () => {
    updateDeferredMerchantState();
  };
  const handleCloseStatusModal = () => {
    setStatusModal(false);
  };

  /* -- -- - -- - -- */
  useEffect(() => {
    let modalStatusData: Partial<ConfirmModalProps> = {
      acceptColor: "primary",
      acceptText: StatusModalEnum.AcceptActiveTextBtn,
      contentText: StatusModalEnum.ActivatedContentText,
      handleAccept: handleAcceptModal,
      handleClose: handleCloseStatusModal,
      titleText: StatusModalEnum.ActivateDeferred,
    };

    if (statusModal) {
      if (props.status === DeferredStatusEnum.ENABLED) {
        modalStatusData.acceptColor = "error";
        modalStatusData.acceptText = StatusModalEnum.AcceptDeactivateTextBtn;
        modalStatusData.contentText = StatusModalEnum.DeactivatedContentText;
        modalStatusData.titleText = StatusModalEnum.DeactivateDeferred;
      }
      setStatus(
        props.status === DeferredStatusEnum.DISABLED
          ? ButtonTextEnum.ENABLED
          : ButtonTextEnum.DISABLED
      );
      setModalProps(modalStatusData as ConfirmModalProps);
    }
  }, [statusModal]);

  useEffect(() => {
    if (!isEmpty(props.id)) setDeferredId(props.id!);
  }, [props.id]);

  useEffect(() => {
    if (status !== props.status) {
      setStatusModal(false);
    }
  }, [props.status]);
  useEffect(() => {
    if (publicMerchantId) {
      const nodeInfoRequest: GetNodeInfoRequest = {
        configIds: "cn010",
        publicMerchantId,
      };

      dispatch(getNodeInfo(nodeInfoRequest));
    }
  }, [publicMerchantId]);

  useEffect(() => {
    if (
      !isEmpty(get(nodeInfo, "configs[0].centralizedNodesId", "")) &&
      country === CountriesEnum.BRAZIL
    ) {
      setIsCentralizeNodeBrasil(true);
    }
  }, [nodeInfo]);

  return {
    anchorEl,
    deferredId,
    handleEvents: {
      handleChangeStatus,
      handleClose: handleCloseMenu,
      handleMenuClick,
      handleOpenStatusModal,
    },
    isCentralizeNodeBrasil,
    isLoadingAcceptAction: isLoading,
    modalProps: modalProps as ConfirmModalProps,
    status,
    statusModalOpen: statusModal,
  };
};
