import React, { FC } from "react";
import { Box, BoxProps, Button } from "@mui/material";
import FilterIcon from "../../../assets/icons/FilterIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import DateTimeButton from "../../common/DateTimeButton/DateTimeButton";
import DateTimeRangePicker from "../../common/DateTimeRangePicker/DateTimeRangePicker";
import FilterBox from "../../common/FilterBox/FilterBox";
import SearchInput from "../../common/SearchInput/SearchInput";
import { useTableFiltersState } from "./state/useTableFiltersState/useTableFiltersState";
import { tableFiltersStyles as styles } from "./TableFilters.styles";

const TableFilters: FC<BoxProps> = ({ sx }: BoxProps) => {
  const {
    areTransactionsLoading,
    dateState: { handleSetDateFilter, rangeDate },
    generalState: { currentFilters, handleSetGeneralFilter },
    searchState: { debouncedHandleSetSearchFilter },
  } = useTableFiltersState();

  const containerStyle = { ...styles.container, ...sx };
  const filterStyle = { ...styles.baseBtn, ...styles.btnFilter };
  const timeStyle = { ...styles.baseBtn, ...styles.btnTime };

  return (
    <Box sx={containerStyle}>
      <FilterBox
        filters={currentFilters}
        renderComponent={({ setAnchor }) => (
          <Button
            disabled={areTransactionsLoading}
            onClick={(e) => setAnchor(e.currentTarget as Element)}
            startIcon={<FilterIcon />}
            sx={filterStyle}
          >
            Filtrar
          </Button>
        )}
        onChange={handleSetGeneralFilter}
      />

      <DateTimeRangePicker
        disableFuture
        from={rangeDate.from}
        to={rangeDate.to}
        onChange={handleSetDateFilter}
        renderComponent={(props) => (
          <DateTimeButton
            {...props}
            disabled={areTransactionsLoading}
            sx={timeStyle}
          />
        )}
      />

      <SearchInput
        disabled={areTransactionsLoading}
        endAdornment={<SearchIcon sx={styles.iconSearch} />}
        onChange={debouncedHandleSetSearchFilter}
        sx={styles.input}
      />
    </Box>
  );
};

export default TableFilters;
