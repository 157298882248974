import { useSelector } from "react-redux";
import { get } from "lodash";
import { DataTableBody } from "../../../../types/data_table_body";
import { IAppState } from "../../../store/reducer";

export interface IUserRolesSection {
  users?: DataTableBody | undefined;
  publicMerchantId?: string;
  merchantName?: string;
}

export const useUsersRolesSectionState = (): IUserRolesSection => {
  const fetchUsers = useSelector((state: IAppState) => state.fetchUsers);
  const publicMerchantId = useSelector(
    (state: IAppState) => state.merchant?.publicMerchantId!
  );
  const merchantName = useSelector((state: IAppState) => state.merchant?.name!);
  const joinsRoles = (groups: { Description: string }[]): string => {
    return groups.map((group) => group.Description).join(", ");
  };

  const getValueOfAttributes = (
    attributes: {
      Name: string;
      Value: string;
    }[],
    name: string
  ) => {
    let respAtribute = attributes.find((atribute) => atribute.Name == name);
    return get(respAtribute, "Value", "");
  };
  const users =
    fetchUsers?.Users === undefined
      ? undefined
      : fetchUsers?.Users.map((user) => ({
          alias: `${getValueOfAttributes(
            get(user, "Attributes", []),
            "name"
          )} ${getValueOfAttributes(
            get(user, "Attributes", []),
            "family_name"
          )}`,
          label: getValueOfAttributes(get(user, "Attributes", []), "email"),
          test: joinsRoles(get(user, "Groups", [])),
        }));
  return { users, publicMerchantId, merchantName };
};
