import React from "react";
import { BarChart } from "@kushki/connect-ui/dist/Components/Molecules/DataVisualization/BarChart";
import { Box } from "@mui/material";
import { useResumeChartBar } from "./state/useResumeChartBar";
import { resumeChartBarStyles } from "./ResumeChartBar.styles";
import { BarChartPropertiesEnum } from "../../shared/enums/BarChartPropertiesEnum";

const ResumeChartBar = () => {
  const { data, isMobile, isScrollX } = useResumeChartBar();
  const resumeBarChartStyles = resumeChartBarStyles(isScrollX);

  return (
    <>
      <Box
        sx={
          isMobile
            ? resumeBarChartStyles.containerMobile
            : resumeBarChartStyles.container
        }
      >
        <Box
          data-testid={"barchart-container"}
          sx={
            isMobile
              ? resumeBarChartStyles.containerChartBarMobile
              : resumeBarChartStyles.containerChartBar
          }
        >
          <BarChart
            data-testid={"barchart-connect"}
            data={data}
            optionalProperties={{
              titleAxisY: BarChartPropertiesEnum.TITLE_AXIS_Y,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export { ResumeChartBar };
