import { makeStyles } from "@mui/styles";

export const accordionBodyStyles = makeStyles({
  title: {
    fontWeight: 400,
    fontSize: "15px",
    color: "#6D7781",
  },
  description: {
    fontWeight: 400,
    fontSize: "15px",
    color: "#293036",
  },
});
