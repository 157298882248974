import React from "react";
import { globalInformationComponentStyles } from "../GlobalInformationComponent/GlobalInformationComponent.styles";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { useFormContext } from "react-hook-form";
import { retrieveFormError } from "../../utils/utilsFile";
import { useAppSelector } from "../../store/hooks/store-hook";
import { AliasComponent } from "./AliasComponent/AliasComponent";

export const GeneralInfoComponent = () => {
  const classes = globalInformationComponentStyles();
  const { errors, control } = useFormContext();
  const { businessRules, getRuleById, prefixAtomicCounter } = useAppSelector(
    (store) => store.layout
  );
  const formErrors = retrieveFormError(errors, "rule.name");

  return (
    <Grid item xs={12} className={classes.paddingTop35}>
      <Card elevation={2} className={classes.rootCard}>
        <CardContent className={classes.cardContent}>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="h3" color="text.primary">
                {LabelEnum.GENERAL_DATA}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingTop16}>
              <Typography variant="body2" color="text.dark">
                {LabelEnum.DESCRIPTION_GENERAL_DATA}
              </Typography>
            </Grid>
            <Grid item xs={5} className={classes.noInputPadding}>
              <AliasComponent
                control={control}
                errors={errors}
                businessRules={businessRules}
                getRuleById={getRuleById}
                formErrors={formErrors}
                prefixAtomicCounter={prefixAtomicCounter}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
