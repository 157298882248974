/**
 * Origin Enum
 */
export enum OriginEnum {
  MERCHANT_RESUME = "merchantResume",
  CREATE_MERCHANT = "createMerchant",
  CREATE_MERCHANT_V3 = "createMerchantV3",
  BATCH_PROCESSING = "batchProcessing",
  CONFIG_MERCHANT = "configMerchant",
}
