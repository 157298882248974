import { makeStyles } from "@material-ui/core/styles";

export const useButtonOptionRetentionsStyles = makeStyles({
  btnContain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  btnPopover: {
    display: "flex",
    maxWidth: "320px",
  },
  btnChild: {
    padding: "10px 25px",
  },
  btnArrow: {
    padding: "0px",
    borderLeft: "1px solid #FFFFFF",
  },
  menuItem: {
    minWidth: "320px",
  },
});
