export enum walletTextEnum {
  TITLE = "Consulta los movimientos de débito de la billetera de tu comercio",
}

export enum WalletDetailTitleEnum {
  BALANCE = "Saldo disponible",
  COUNTABLE_BALANCE = "Histórico de recargas",
  UNPROCESSED_BALANCE = "Monto en proceso",
}

export enum WalletDetailTooltipTextEnum {
  BALANCE = "Dinero disponible para dispersión hasta hoy",
  COUNTABLE_BALANCE = "Monto total acumulado de recargas realizadas a tu billetera",
  UNPROCESSED_BALANCE = "Pagos en proceso de dispersión",
}

export enum WalletButtonsTitle {
  CHECK_WALLET = "Consultar billetera",
}
