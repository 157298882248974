import { useAppSelector } from "../../../store/hooks/storeHook";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import { FormFieldEnum } from "../../../shared/enums/FormFieldEnum";

import { isEmpty } from "lodash";
import { getProcessorById } from "../../../shared/utils/utilsFile";
import { IUseRuleConfigState } from "../RuleConfigurationComponent.interfaces";

export const useRuleConfigurationState = (): IUseRuleConfigState => {
  const { processorList } = useAppSelector((state) => ({
    ...state.app,
  }));
  const { control, watch } = useFormContext();
  const [selectedProcessor, setSelectedProcessor] =
    useState<MerchantProcessors>({
      alias: "",
      merchantId: "",
      publicProcessorId: "",
    });
  const watchProcessorId = watch(FormFieldEnum.RULE_PROCESSOR_ID);

  useEffect(() => {
    if (!isEmpty(processorList)) {
      const processor = getProcessorById(watchProcessorId, processorList!);

      setSelectedProcessor(processor!);
    }
  }, [watchProcessorId, processorList]);

  return {
    control,
    processorList,
    selectedProcessor,
  };
};
