import React, { useEffect, useState } from "react";
import { SpeedConditionSectionProps } from "../SpeedConditionSection";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import { isFormEditMode } from "../../../utils/utilsFile";
import {
  VALID_GENERAL_ACTIONS,
  getCurrencyByCountry,
  IOptionSelection,
  Labels,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { defaultTo, get, isUndefined } from "lodash";
import { useFormContext } from "react-hook-form";
import { IUseSpeedConditionState } from "../../../shared/infrastructure/interfaces/IUseSpeedConditionState";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { SearchRequestRuleByIdResponse } from "../../../../types/search_request_rule_by_id_response";
import { GetRuleByIdResponse } from "../../../../types/get_rule_by_id";
import { SecurityRuleData } from "../../../../types/security_rule_data";
import { PathsEnum } from "../../../shared/infrastructure/enums/PathsEnum";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { RuleGeneralRequest } from "../../../../types/rule_general_request";

export const useSpeedConditionSectionState = (
  props: SpeedConditionSectionProps
): IUseSpeedConditionState => {
  const currencies: Record<string, IOptionSelection[]> | undefined =
    useSelector((state: IAppState) => state.currencyByCountry);
  const fetchSearchRuleById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );
  const fetchSearchRequestRuleById: SearchRequestRuleByIdResponse | undefined =
    useSelector((state: IAppState) => state.searchRequestRuleById);
  const selectedRule: RuleGeneralRequest | undefined = useSelector(
    (state: IAppState) => state.detailCatalogGeneralRules
  );
  const { getValues, unregister, control, setValue, register } =
    useFormContext();
  const { ruleId, ruleName } = useParams<IUseParams>();
  const [currencyLabel, setCurrencyLabel] = useState<string>("");
  const [isCountTransaction, setIsCountTransaction] = useState<boolean>(true);
  const [speedTypeLabel, setSpeedTypeLabel] = useState<string>("");
  const [stateTrx, setStateTrx] = useState<string>(Labels.APPROVAL);
  const [isSpeed, enableSpeed] = useState<boolean>(
    getValues(PathsEnum.KIND) === Values.RULES_KIND_SPEED &&
      getValues(PathsEnum.SUB_KIND) !== Values.RULES_KIND_SPECIAL
  );

  const [currency, setCurrency] = useState<IOptionSelection[]>([
    { label: getValues("currency"), value: getValues("currency") },
  ]);

  const rule: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.rule
  );

  const handleSpeedType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCountTransaction(event.target.value === Values.RULES_SPEED_COUNT);
    unregister("amountSum");
    unregister("trxCount");
  };

  useEffect(() => {
    if (props.form && isFormEditMode(ruleId)) {
      const trxCount =
        get(fetchSearchRequestRuleById, "rule.limit") ||
        get(fetchSearchRuleById, PathsEnum.RULE_LIMIT);

      enableSpeed(
        getValues(PathsEnum.KIND) === Values.RULES_KIND_SPEED &&
          getValues(PathsEnum.SUB_KIND) !== Values.RULES_KIND_SPECIAL
      );
      setStateTrx(defaultTo(getValues("stateTrx"), Labels.APPROVAL));
      setIsCountTransaction(trxCount != undefined);
    }

    if (props.form && isFormEditMode(ruleName)) {
      if (
        getValues(PathsEnum.KIND) === Values.RULES_KIND_SPEED &&
        getValues(PathsEnum.SUB_KIND) !== Values.RULES_KIND_SPECIAL
      ) {
        enableSpeed(true);
        setStateTrx(defaultTo(getValues("stateTrx"), Labels.APPROVAL));
        setIsCountTransaction(true);
      }
    }

    if (props.form && !isUndefined(rule) && isUndefined(ruleName)) {
      const trxCount: string = get(rule, "trxCount");

      enableSpeed(
        get(rule, PathsEnum.KIND, "") === Values.RULES_KIND_SPEED &&
          !get(rule, "hasSpecialCond", false)
      );
      setStateTrx(defaultTo(getValues("stateTrx"), Labels.APPROVAL));
      setIsCountTransaction(!isUndefined(trxCount));
    }
  }, [control]);

  useEffect(() => {
    enableSpeed(
      getValues(PathsEnum.KIND) === Values.RULES_KIND_SPEED &&
        getValues(PathsEnum.SUB_KIND) !== Values.RULES_KIND_SPECIAL
    );
  }, [getValues(PathsEnum.SUB_KIND)]);

  useEffect(() => {
    const trxCount: string = getValues("trxCount");

    if (isUndefined(trxCount) && !isFormEditMode(ruleId))
      setIsCountTransaction(false);

    if (!isUndefined(selectedRule)) {
      const trx_count: string = get(selectedRule, "rule.limit");

      setIsCountTransaction(!isUndefined(trx_count));
    }

    if (isFormEditMode(ruleId)) {
      const trx_count: string =
        get(fetchSearchRequestRuleById, "rule.limit") ||
        get(fetchSearchRuleById, PathsEnum.RULE_LIMIT);

      setIsCountTransaction(!isUndefined(trx_count));
    }
  }, [isSpeed]);

  useEffect(() => {
    const hasEnabledSpecialCond: boolean =
      props.fieldsCond.findIndex((field: Object) =>
        [LabelEnum.CARD_ID, LabelEnum.MASKED_CARD].includes(
          get(field, "label", "")
        )
      ) !== -1;

    props.setIsDisabledSpecial(
      !hasEnabledSpecialCond ||
        isSpeed ||
        !VALID_GENERAL_ACTIONS.includes(getValues("generalAction"))
    );
  }, [isSpeed, props.fieldsCond]);

  useEffect(() => {
    setCurrency(getCurrencyByCountry(get(props, "country", []), currencies));
  }, [props.country, props.form]);

  useEffect(() => {
    setCurrencyLabel(
      currency[0]?.value || defaultTo(getValues("currency"), "")
    );
    if (currency.length === 1) {
      register("currency");
      setValue(
        "currency",
        currency[0].value || defaultTo(getValues("currency"), "")
      );
    }
  }, [currency]);

  useEffect(() => {
    setSpeedTypeLabel(
      isCountTransaction ? Values.RULES_SPEED_COUNT : Values.RULES_SPEED_SUM
    );
  }, [isCountTransaction]);

  return {
    handle: {
      enableSpeed,
      handleSpeedType,
      setStateTrx,
    },
    currency,
    currencyLabel,
    isCountTransaction,
    isSpeed,
    speedTypeLabel,
    stateTrx,
  };
};
