import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontSize: "18px",
      textAlign: "center",
      color: "#787f88",
    },
    grid: {
      background: "#FFF",
      height: 400,
      justifyContent: "center",
    },
  })
);

export default useStyles;
