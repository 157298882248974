import React, { FC, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { skeletonTableStyles as styles } from "./SkeletonTable.styles";
import { ISkeletonTable } from "./SkeletonTable.interfaces";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonTable: FC<ISkeletonTable> = ({
  columns,
  rows,
}: ISkeletonTable) => {
  const classes = styles();

  return (
    <Table className={classes.container}>
      <TableHead>
        <TableRow>
          {new Array(columns).fill("").map((_, index) => (
            <TableCell key={`skeleton-header-${index}`}>
              <Skeleton variant={"text"} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {new Array(rows).fill("").map((_, indexRow) => (
          <TableRow key={`skeleton-body-row-${indexRow}`}>
            {new Array(columns).fill("").map((_, indexCol) => (
              <TableCell key={`skeleton-body-cell-${indexCol}`}>
                <Skeleton variant={"text"} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(SkeletonTable);
