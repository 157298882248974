import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const CellDateRangeStyles = createNamedStyles({
  dateText: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.6px",
  },
});

export { CellDateRangeStyles };
