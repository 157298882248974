import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import expandImage from "../../../assets/images/expand.svg";
import { History } from "../../../../types/security_rule";
import { useTimeLineState } from "./state/useTimeLineState";
import { get } from "lodash";
import { ModalBodyProps } from "../ModalBody";
import {
  RULE_STATUS,
  TimeLineStatusEnum,
} from "../../../shared/infrastructure/enums/TimeLineStatusEnum";
import { DialogTimeLine } from "../../DialogTimeLine/DialogTimeLine";
import { TimeLineConstants } from "../../../shared/infrastructure/constants/SecurityRulesConstants";

const useStyles = makeStyles(() => ({
  lineDashed: {
    borderRight: "1px dashed #CBD5E0",
    backgroundColor: "#f5f5f5",
    height: 28,
  },
  styleDot: {
    width: 16,
    height: 16,
    margin: 0,
    background: "#E2E8F0",
  },
  styleDotActive: {
    width: 16,
    height: 16,
    margin: 0,
    background: "#4498EE",
  },
  styleOppositeText: {
    paddingTop: 32,
    paddingBottom: 0,
  },
  styleTimeline: {
    paddingTop: 28,
    paddingBottom: 28,
    paddingLeft: 34,
  },
  styleTimelineOpposite: {
    paddingTop: 28,
    paddingBottom: 28,
    padding: 0,
    flex: 0.4,
    paddingRight: 14,
  },
  principalText: {
    fontSize: "15px !important",
    lineHeight: "120% !important",
    color: "#46525C !important",
  },
  secondText: {
    fontSize: "12px !important",
    lineHeight: "136% !important",
    color: "#6D7781 !important",
  },
  expand: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecorationLine: "underline",
    padding: "3px",
    borderRadius: "50%",
  },
  paddingButton: {
    padding: "0px !important",
  },
}));

const TimeLineComponent = (props: ModalBodyProps): JSX.Element => {
  const classes = useStyles();
  const { actions, openDialogTimeLine, dataModal } = useTimeLineState();

  return (
    <Box px={3} py={2}>
      <Timeline>
        {get(props.data, "historical", [])
          .sort((a: History, b: History) => b.created - a.created)
          .map((history: History, index: number) => {
            const dateFormat: string = actions.getFormatDate(history.created);
            const hourFormat: string = actions.getFormatHour(history.created);
            return (
              <React.Fragment key={index}>
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    className={classes.styleTimelineOpposite}
                  >
                    <Typography
                      variant="h6"
                      component="span"
                      className={classes.principalText}
                    >
                      {dateFormat}
                    </Typography>
                    <Typography className={classes.secondText}>
                      {hourFormat}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector className={classes.lineDashed} />
                    <TimelineDot
                      className={
                        index === 0 ? classes.styleDotActive : classes.styleDot
                      }
                    />
                    <TimelineConnector className={classes.lineDashed} />
                  </TimelineSeparator>
                  <TimelineContent className={classes.styleTimeline}>
                    <Grid container xs={12}>
                      <Grid container item xs={8}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            component="span"
                            className={classes.principalText}
                          >
                            {RULE_STATUS[history.eventName]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.secondText}>
                            {history.userName}
                          </Typography>
                        </Grid>
                      </Grid>
                      {history.eventName === TimeLineStatusEnum.DISABLED && (
                        <Grid item xs={4}>
                          <Button
                            className={classes.paddingButton}
                            onClick={() =>
                              actions.handleShowDialogTimeline(history)
                            }
                          >
                            <Grid className={classes.expand}>
                              <img alt="" src={expandImage} />
                              <Typography variant="caption" color="primary">
                                {`${TimeLineConstants.VIEW_DETAIL}`}
                              </Typography>
                            </Grid>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </TimelineContent>
                </TimelineItem>
              </React.Fragment>
            );
          })}
      </Timeline>
      <DialogTimeLine
        open={openDialogTimeLine}
        data={dataModal}
        handleFinish={actions.handleFinish}
      />
    </Box>
  );
};

export default TimeLineComponent;
