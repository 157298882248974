import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { get, includes, isEmpty, isEqual } from "lodash";
import { IUseDeferredState } from "./IUseDeferredrState.interfaces";
import { useLocation } from "react-router-dom";
import { DeferredProps } from "../Deferred";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import { useEffect } from "react";
import { BranchEditListPathEnum } from "../../../shared/infrastructure/enums/SessionStoragePathEnum";
import {
  findConfigById,
  isDeferredAllowed,
} from "../../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import { ConfigurationCodeEnum } from "../../../shared/enums/ProcessingStepEnum";
import { Configs } from "../../../../types/get_node_info_response";
import { changeLoadingValue } from "../../../store/reducers/layout/layout.slice";
import {
  getCardAdminMerchant,
  getDeferredList,
} from "../../../store/thunks/layout/layout.thunks";
import { CountriesEnum } from "../../../shared/infrastructure/enums/CountriesEnum";
import { setBasicMerchantInformation } from "../../../shared/utils/local-storage/basic-merchant-information";
import { TabIndexNameEnum } from "../../../shared/enums/TabIndexEnum";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import { Data } from "../../../../types/branch_edit_list";
import { isDecentralizedBranch } from "../../../utils/branch/branch-util";
import { GetProcessorResponse } from "../../../../types/get_processor_response";
import { MerchantProcessorsEnum } from "../../../shared/enums/MerchantProcessorsEnum";
import { PaymentMethodEnum } from "../../../shared/enums/PaymentMethodEnum";
import { getProcessors } from "../../../shared/enums/ProcessorEnum";

export const useDeferredState = ({
  deferredList,
}: Partial<DeferredProps>): IUseDeferredState => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const showMoreDeferred: boolean = get(deferredList, "length", 0) > 5;
  const {
    nodeInfo,
    cardMerchant,
    processor,
    deferredConfig,
    isMassiveBranches,
    branchEditList,
  } = useAppSelector((store) => store.layout);
  const country: string = isMassiveBranches
    ? get(branchEditList, "data[0].country", "")
    : get(nodeInfo, NodeInfoPath.InfoCountry, "");
  const availableCards: string[] = get(cardMerchant, "acceptCreditCards", []);

  const verifyAreAllDecentralizedDeferred = (branchData: Data[]): boolean => {
    const branch_edit_list: Data[] = branchData.filter(
      (branch: Data) => branch.edit
    );

    const areAllDecentralized: boolean = branch_edit_list
      .map((branch: Data) =>
        isDecentralizedBranch(ConfigurationCodeEnum.DEFERRED, branch)
      )
      .every((v: boolean) => isEqual(v, true));

    return areAllDecentralized;
  };

  const areAllDecentralized: boolean = verifyAreAllDecentralizedDeferred(
    get(branchEditList, BranchEditListPathEnum.DATA, [])
  );

  useEffect(() => {
    if (
      !isEmpty(nodeInfo) &&
      !isEmpty(nodeInfo.merchantId) &&
      !isMassiveBranches
    ) {
      const country: string = get(nodeInfo, NodeInfoPath.InfoCountry, "");

      if (isDeferredAllowed(country)) {
        const merchantId: string = get(nodeInfo, NodeInfoPath.MerchantID, "");
        const deferredConfig: Configs = findConfigById(
          ConfigurationCodeEnum.DEFERRED,
          get(nodeInfo, NodeInfoPath.Configs, [])
        );
        const deferredValue: string = get(deferredConfig, "value", "");

        dispatch(
          changeLoadingValue({ property: "loadingDeferred", value: true })
        );
        dispatch(getDeferredList({ merchantId: deferredValue || merchantId }));

        if (country === CountriesEnum.PERU) {
          const selectMerchantId: string = !isEmpty(deferredValue)
            ? deferredValue
            : merchantId;

          if (isEmpty(deferredValue))
            dispatch(
              getDeferredList({
                merchantId: selectMerchantId,
              })
            );
          dispatch(
            getCardAdminMerchant({
              merchantId: selectMerchantId,
            })
          );
        }
      }
    }
  }, [nodeInfo]);

  const handleNavigate = () => {
    setBasicMerchantInformation({
      redirectPath: location.pathname.concat(location.search),
      redirectTab: TabIndexNameEnum.DEFERRED,
    });

    window.location.href = AppRoutes.CREATE_DEFERRED;
  };

  const haveCardPaymetMethodProcessors = (
    merchantProcessors: GetProcessorResponse[],
    country: string
  ): boolean => {
    if (!isEmpty(merchantProcessors) && merchantProcessors.length > 0) {
      const processors: string[] = [];
      for (let i = 0; i < merchantProcessors.length; i++) {
        if (
          get(
            merchantProcessors[i],
            MerchantProcessorsEnum.PAYMENT_METHOD,
            ""
          ) === PaymentMethodEnum.CARD
        ) {
          processors.push(
            get(
              merchantProcessors[i],
              MerchantProcessorsEnum.PROCESSOR_NAME,
              ""
            )
          );
        }
      }
      for (let i = 0; i < processors.length; i++) {
        if (includes(getProcessors(country), processors[i])) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    setBasicMerchantInformation({
      redirectTab: undefined,
    });
  }, []);

  return {
    areAllDecentralized,
    availableCards,
    country,
    deferredConfig,
    handleNavigate,
    haveCardPaymetMethodProcessors,
    isMassiveBranches,
    nodeInfo,
    processor,
    showMoreDeferred,
  };
};
