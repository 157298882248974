import { makeStyles } from "@material-ui/core/styles";

export const filterComponentStyle = makeStyles({
  applyButton: {
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "8px",
    width: "80px",
  },
  buttonText: {
    color: "#293036",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "140%",
  },
  chip: {
    backgroundColor: "#F7FAFC",
    borderRadius: "4px",
    color: "#677684",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "30px",
    lineHeight: "17px",
    padding: "8px",
    width: "auto",
  },
  chipSelected: {
    backgroundColor: "#0DC298",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "30px",
    lineHeight: "17px",
    padding: "8px",
    width: "auto",
  },
  deleteButton: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "8px",
    width: "100px",
  },
  expandedCollapsed: {
    color: "#023365",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "134%",
  },
  filterButton: {
    backgroundColor: "#FFFFFF",
    height: "36px",
  },
  filterByText: {
    color: "#293036",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "140%",
  },
  firstDivider: {
    color: "#F0F4F9",
  },
  gridRight: {
    textAlign: "right",
  },
  mainGrid: {
    maxWidth: "440px",
    minWidth: "430px",
    padding: "12px",
  },
  nonSpace: {
    margin: "0px",
    padding: "0px",
  },
  secondDivider: {
    color: "#CBD5E0",
  },
});
