import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const TableNodesStyle = createNamedStyles({
  tableFooter: {
    ".MuiTablePagination-actions": {
      display: "none",
    },
  },
  contentTable: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  headerTable: {
    display: "table-header-group",
    background: "rgb(226, 232, 240)",
    fontWeight: "500",
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "120%",
    opacity: "1",
    height: "45px !important",
  },
  table: {
    borderColor: "grey",
    "&.MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0px 12px",
      display: "table",
    },
  },
});

export { TableNodesStyle };
