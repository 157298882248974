import { IPaymentMethodObject } from "../infrastructure/interface/IPaymentMethodObject";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { StatusTypeEnum } from "../enums/StatusTypeEnum";
import { PaymentMethodTypeEnum } from "../enums/PaymentMethodTypeEnum";
import { environment } from "../../environments/environment";
import { get } from "lodash";

const showPayoutMethod: boolean = get(environment, "showPayoutMethod", false);
const basePaymentMethodOrdered: string[] = [
  "card",
  "transfer",
  "cash",
  "payouts_transfer",
  "payouts_cash",
];

export const PaymentMethodKeysOrdered: string[] = !showPayoutMethod
  ? basePaymentMethodOrdered
  : [...basePaymentMethodOrdered, "payouts_card"];

export const CARD_TRANSLATE = "Tarjeta";
export const CARD_NAME_TRANSLATE = "Tarjeta de Crédito";
export const CASH_NAME_TRANSLATE = "Efectivo";
export const TRANSFER_NAME_TRANSLATE = "Transferencia";
export const NOTCHARGE = "notCharge";
export const TYPE = "Tipo";

export const PaymentMethodObject: IPaymentMethodObject = {
  card: {
    icon: "iconCard",
    name: PaymentMethodEnum.CARD,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CARD_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.FUNDRAISING,
  },
  cash: {
    icon: "iconCash",
    name: PaymentMethodEnum.CASH,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CASH_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.FUNDRAISING,
  },
  payouts_card: {
    icon: "iconCard",
    name: PaymentMethodEnum.PAYOUTS_CARD,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CARD_TRANSLATE,
    type: PaymentMethodTypeEnum.DISPERSION,
  },
  payouts_cash: {
    icon: "iconCash",
    name: PaymentMethodEnum.PAYOUTS_CASH,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CASH_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.DISPERSION,
  },

  payouts_transfer: {
    icon: "iconTransfer",
    name: PaymentMethodEnum.PAYOUTS_TRANSFER,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: TRANSFER_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.DISPERSION,
  },
  transfer: {
    icon: "iconTransfer",
    name: PaymentMethodEnum.TRANSFER,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: TRANSFER_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.FUNDRAISING,
  },
};
