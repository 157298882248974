import React from "react";
import { IOptionSelection } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  TextField,
  Typography,
} from "@mui/material";
import { SelectCheckStyles } from "./SelectCheck.styles";
import { useSelectCheck } from "./state/useSelectCheck";
import OptionRender from "../OptionRender/OptionRender";
import {
  IRenderTagProps,
  renderTag,
} from "@kushki/connect-ui/dist/Shared/Utils/autocompleteRenderTag";
import ListBoxComponent from "../ListBoxComponent/ListBoxComponent";

export interface SelectCheckProps {
  isLoading: boolean;
  isActiveSelectAll: boolean;
  placeHolder: string;
  items: IOptionSelection[];
  selected: string[];
  setSelectedItems: (value: ICategory[]) => void;
  changeTextField?: (value: string) => void;
  maxSelectedItems?: number;
  isDisable: boolean;
  error?: boolean;
  helperText?: string;
  operator: string;
  isEditCondition: boolean;
  isFinalCond: boolean;
}

export const SelectCheck: React.FC<SelectCheckProps> = (
  props: SelectCheckProps
) => {
  const {
    error,
    helperText,
    isActiveSelectAll,
    maxSelectedItems,
    placeHolder,
  } = props;
  const {
    check,
    handleOpen,
    handleChange,
    handleChangeInputValue,
    handleCheck,
    handleClose,
    items,
    open,
    value,
  } = useSelectCheck(props);

  const filterOption = createFilterOptions({
    matchFrom: "any",
    stringify: (option: ICategory) => option.label,
  });

  return (
    <Box>
      <Autocomplete
        disabled={props.isDisable}
        sx={SelectCheckStyles.selectCategory}
        multiple
        disableCloseOnSelect
        options={items}
        value={value}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        filterOptions={(e, v) => filterOption(e, v)}
        ListboxComponent={(param) => (
          <ListBoxComponent
            boxParams={param}
            checkAll={check}
            checkAllChange={handleCheck}
            isActiveSelectAll={isActiveSelectAll}
            indeterminate={false}
            isDisabled={false}
          />
        )}
        noOptionsText={
          <Typography sx={SelectCheckStyles.noOptions}>
            No se encontraron coincidencias
          </Typography>
        }
        getOptionLabel={(option) => option.label}
        getOptionDisabled={() =>
          !!maxSelectedItems && value.length >= maxSelectedItems
        }
        renderTags={(value, getTagProps) => {
          const renderTagProps: IRenderTagProps = {
            maxWidthDesk: 120,
            maxWidthMob: 70,
            minChipItem: 20,
            pathLabel: "label",
          };

          return renderTag(renderTagProps, value, getTagProps);
        }}
        renderOption={(props, option: ICategory) => {
          const isSelected = value.some(
            (selectedOption) => selectedOption.value === option.value
          );

          return (
            <OptionRender
              listProps={props}
              selected={isSelected}
              label={option.label}
              isDisabled={
                !!maxSelectedItems && value.length >= maxSelectedItems
              }
              description={option.description ?? ""}
              hasDescription={false}
            />
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...{ label: placeHolder }}
            helperText={helperText}
            error={error}
            onChange={(e) => handleChangeInputValue(e.target.value)}
            InputProps={{
              ...params.InputProps,
              sx: SelectCheckStyles.optionTextField,
            }}
          />
        )}
      />
    </Box>
  );
};
