import { Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  IBoardMember,
  IIsEdited,
  ILegalDetailsState,
  ILegalRepresentative,
  IShareHolder,
} from "../../interfaces/LegalDetailsState.interfaces";

import { MerchantResponse } from "../../../../types/get_merchant_response";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { CompliancePeopleNodeResponse } from "../../../../types/get_compliance_people_response";

export const setLegalRepresentativeAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<ILegalRepresentative>
) => {
  state.legalRepresentative.push(payload);
};

export const setBoardMemberAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IBoardMember>
) => {
  state.boardMembers.push(payload);
};

export const setLegalRepresentativesArrayAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<ILegalRepresentative[]>
) => {
  state.legalRepresentative = payload;
};

export const setBoardMembersArrayAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IBoardMember[]>
) => {
  state.boardMembers = payload;
};

export const setShareHolderAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IShareHolder[]>
) => {
  state.shareHolders = payload;
};
export const setNaturalPersonNameAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<string>
) => {
  state.merchantNodeInfo.naturalPerson!.name = payload;
};
export const setNaturalPersonLastNameAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<string>
) => {
  state.merchantNodeInfo.naturalPerson!.lastname = payload;
};

export const removeLegalRepresentativeAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<ILegalRepresentative[]>
) => {
  state.legalRepresentative = payload;
};

export const removeBoardMemberAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IBoardMember[]>
) => {
  state.boardMembers = payload;
};

export const editLegalRepresentativeAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<ILegalRepresentative>
) => {
  state.legalRepresentative = state.legalRepresentative.map((item) => {
    if (item.id === payload.id) return payload;
    else return item;
  });
};

export const editBoardMemberAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IBoardMember>
) => {
  state.boardMembers = state.boardMembers.map((item) => {
    if (item.id === payload.id) return payload;
    else return item;
  });
};

export const setOpenModalAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean>
) => {
  state.openModal = payload;
};

export const setOpenModalActionSh = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean>
) => {
  state.openModalSh = payload;
};

export const getCompliancePeopleNodeAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<CompliancePeopleNodeResponse>
) => {
  state.compliancePeopleNode = payload;
};

export const getMerchantNodeInfoAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<MerchantResponse>
) => {
  state.merchantNodeInfo = payload;
};

export const setEditedAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IIsEdited>
) => {
  state.isEdited = payload;
};

export const setEditedBoardMemberAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<IIsEdited>
) => {
  state.isEditedBoardMember = payload;
};

export const setPersonTypeAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<string>
) => {
  state.personType = payload;
};
export const setGeneralPercentageAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<string>
) => {
  state.generalPercentage = payload;
};
export const setIsLoadingCompliancePeopleAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean>
) => {
  state.isLoadingCompliancePeople = payload;
};
export const setIsPubliclyExposedAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean | null>
) => {
  state.isPubliclyExposed = payload;
};
export const setNotificationSnackbarLD = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<ISnackBar | undefined>
) => {
  state.notification = payload;
};
export const setHierarchyNodeInfoResponseAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean | undefined>
) => {
  state.hierarchyNodeResponse = payload;
};
export const setSaveLegalDetailsErrorAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean | undefined>
) => {
  state.saveLegalDetailsError = payload;
};

export const setDisabledInputsAction = (
  state: Draft<ILegalDetailsState>,
  { payload }: PayloadAction<boolean>
) => {
  state.disabledInputs = payload;
};
