import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { ControllerRenderProps } from "react-hook-form";
import { isEmpty } from "lodash";
import { OperatorsEnum } from "../../../shared/infrastructure/constants/OperatorsEnum";
import { AuthenticationRulesLabelField } from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { RegexExpressionEnum } from "../../../shared/infrastructure/constants/RegexExpressionEnum";

export interface TextFieldCardIdProps {
  disabled: boolean;
  type?: string;
  mask?: string;
  properties: ControllerRenderProps;
  helperText: string | false | undefined;
  error: boolean;
  label?: string;
  operator?: string;
  index?: number;
}

export const TextFieldCardId: React.FC<TextFieldCardIdProps> = (
  props: TextFieldCardIdProps
) => {
  let { properties, error, operator, helperText } = props;

  const [value, setValue] = useState("");
  const [valid, setValid] = useState(false);
  const handleValidation = (e: any) => {
    setValue(e.target.value);
    properties.value = "";
    const reg = new RegExp(
      operator === OperatorsEnum.CONTAIN ||
      operator === OperatorsEnum.NOT_CONTAIN
        ? RegexExpressionEnum.MORE_THAN_ONE_UUID
        : RegexExpressionEnum.ONLY_ONE_UUID
    );
    setValid(reg.test(e.target.value));
  };

  return (
    <>
      {isEmpty(properties.value) ? (
        <TextField
          {...properties}
          id="value"
          value={value}
          onChange={(e) => handleValidation(e)}
          label={AuthenticationRulesLabelField.VALUE}
          type={props.type}
          variant="outlined"
          error={!valid}
          fullWidth
          inputProps={{ pattern: "^[0-9a-zA-Z\\-, ]+$" }}
          InputProps={{
            inputComponent: props.mask as any,
          }}
          helperText={
            isEmpty(value)
              ? "Campo vacío"
              : !valid
              ? "El formato ingresado es incorrecto"
              : ""
          }
        />
      ) : (
        <TextField
          {...properties}
          id="value"
          label={AuthenticationRulesLabelField.VALUE}
          type={props.type}
          variant="outlined"
          error={error}
          fullWidth
          inputProps={{ pattern: "^[0-9a-zA-Z\\-, ]+$" }}
          InputProps={{
            inputComponent: props.mask as any,
          }}
          helperText={helperText}
        />
      )}
    </>
  );
};

export default TextFieldCardId;
