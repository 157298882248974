import { useRef } from "react";
import { getNotificationDate } from "../../../shared/utils/date_utils";
import {
  INotificationDetailData,
  INotificationState,
  IStatusChip,
  NOTIFICATION_CONFIG_DEFAULT,
} from "./useNotificationDetailState.interfaces";
import { useParams } from "react-router-dom";
import { IBreadCrumbItemProps } from "@kushki/connect-ui/dist/Components/Atoms/BreadCrumbItem/BreadCrumbItem.interfaces";
import { IconColorsEnum } from "../../../shared/constants/enums/IconColorsEnum";
import { NotificationDetailsBCEnum } from "../../../shared/constants/enums/BreadCrumbEnum";
import { ROUTES } from "../../../shared/constants/routes_tab";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import { statusColor } from "../../../shared/constants/notifications_container";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";

const buildStatusTag = (status: string): IStatusChip => {
  switch (status) {
    case statusColor.FAILED:
      return { color: IconColorsEnum.ERROR, statusText: "FALLIDO" };
    case statusColor.SUCCESS:
      return { color: IconColorsEnum.SUCCESS, statusText: "EXITOSO" };
    default:
      return { color: IconColorsEnum.INFO, statusText: status };
  }
};

const buildNotificationData = (
  notification: object
): INotificationDetailData => ({
  alias: get(notification, "alias", "-"),
  bodyRequest: get(notification, "requestBody", "{}"),
  bodyResponse: get(notification, "responseBody", "{}"),
  config: get(notification, "source", NOTIFICATION_CONFIG_DEFAULT),
  date: getNotificationDate(get(notification, "timestamp", 0)),
  destiny: get(notification, "url"),
  event: get(notification, "event"),
  origin: get(notification, "origin"),
  paymentMethod: get(notification, "paymentMethod[0]"),
  statusChip: buildStatusTag(get(notification, "status")),
});

const buildBackButtonURL = (publicMerchantId?: string): string =>
  `${ROUTES.MERCHANT_WEBHOOK}${ROUTES.NOTIFICATIONS}/${publicMerchantId}?entityName=customer`;

const buildBreadCrumbItems = (
  publicMerchantId?: string,
  pathQueryUrl?: string,
  entityNameParam?: string
): IBreadCrumbItemProps[] => [
  {
    id: NotificationDetailsBCEnum.LEVEL0,
    locationRef: true,
    text: `${NotificationDetailsBCEnum.LEVEL0}`,
    url: `${ROUTES.MERCHANT_LIST}`,
  },
  {
    id: NotificationDetailsBCEnum.LEVEL1,
    text: NotificationDetailsBCEnum.LEVEL1,
    url: `/..${ROUTES.RESUME}/?publicMerchantId=${publicMerchantId}`,
  },
  {
    id: NotificationDetailsBCEnum.CURRENT,
    text: NotificationDetailsBCEnum.CURRENT,
    url: `/${ROUTES.NOTIFICATIONS}/${publicMerchantId}?entityName=${entityNameParam}${pathQueryUrl}`,
  },
  {
    id: NotificationDetailsBCEnum.DETAILS,
    text: NotificationDetailsBCEnum.DETAILS,
    url: `${publicMerchantId}/notifications/detail`,
  },
];

export const useNotificationDetailState = (): INotificationState => {
  const locate = useLocation();

  const { publicMerchantId } = useParams();
  const notificationDetail = useRef<INotificationDetailData>(
    buildNotificationData(get(locate, "state.item") as object)
  );
  const pathParam: string = get(locate, "state.pathParam", "");
  const entityNameParam: string = get(locate, "state.entityNameParam", "");

  const breadCrumbItems = useRef<IBreadCrumbItemProps[]>(
    buildBreadCrumbItems(
      publicMerchantId,
      `&${QueryParamsEnum.PATH}=${pathParam}`,
      `${entityNameParam}`
    )
  );
  const backButtonURL = useRef<string>(buildBackButtonURL(publicMerchantId));

  return {
    backButtonURL: backButtonURL.current,
    breadCrumbItems: breadCrumbItems.current,
    notificationDetail: notificationDetail.current,
  };
};
