import React, { useEffect } from "react";
import { ItemCategoryItem, ItemCategoryProps } from "../interfaces";
import { IUseItemCategoryState } from "./useItemCategoryState.interfaces";

const useItemCategoryState = ({
  isMinimize,
  items,
  selectType,
}: ItemCategoryProps): IUseItemCategoryState => {
  const [expanded, setExpanded] = React.useState(isMinimize);
  const toggleAcordion = () => {
    setExpanded((expanded) => !expanded);
  };

  const [listFromItems, setListFromItems] = React.useState(items);

  const handleOnByChipsChange = (item: ItemCategoryItem) => {
    item.selected = !item.selected;
    if (selectType === "single") {
      items.map((value) =>
        value.value !== item.value ? (value.selected = false) : item
      );
    } else {
      items.map((value) =>
        value.value === item.value
          ? (value.selected = item.selected)
          : (value.selected = value.selected)
      );
    }
    setListFromItems([...items]);
  };

  useEffect(() => {
    setExpanded(isMinimize);
  }, [isMinimize]);

  useEffect(() => {
    setListFromItems([...items]);
  }, [JSON.stringify(items)]);

  return {
    expanded,
    handleOnByChipsChange,
    listFromItems,
    toggleAcordion,
  };
};

export { useItemCategoryState };
