import { Theme } from "@mui/material";
import { IStyles } from "../../shared/interfaces/Styles.interfaces";
import theme from "../../Themes/themekushki";

const AccordionStyles: IStyles = {
  nestedLine: {
    borderLeft: "2px solid",
    borderColor: "primary.main",
    height: "32px",
  },
  nestedLineUp: {
    borderLeft: "2px solid",
    borderColor: "primary.light2",
    height: "32px",
  },
  nestedLineDown: {
    borderLeft: "2px solid",
    borderColor: "primary.light2",
    height: "12px",
  },
  nestedBadgeComponent: {
    "& .MuiBadge-badge": {
      backgroundColor: "primary.light2",
      color: theme.palette.common.white,
      minWidth: "32px",
      height: "21px",
    },
  },
  nestedBadgeComponentLvl1: {
    "& .MuiBadge-badge": {
      backgroundColor: "primary.main",
      color: theme.palette.common.white,
      minWidth: "32px",
      height: "21px",
    },
  },
  accordion: {
    marginBottom: "0px",
    "&.MuiAccordion-root": {
      "&.Mui-expanded": {
        backgroundColor: "neutral.grey2",
        marginTop: "0px",
      },
    },
  },
  summaryAccordionUp: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(-90deg)",
    },
  },
  summaryAccordionDown: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  },
  divider: {
    border: "1px solid",
    color: "neutral.grey4",
  },
  boxIcon: {
    alignSelf: "center",
    padding: "0px 16px",
  },
  addButton: {
    ":hover": { borderColor: "#4498EE" },
    color: "#4498EE",
    fontWeight: theme.typography.fontWeightMedium,
    borderColor: "#4498EE",
  },
  typographyLabel: {
    color: "#868686",
    fontSize: "14px",
  },
  typographyLabelMain: {
    color: "#000000",
    fontSize: "14px",
  },
  gridFlex: {
    display: "flex",
    flexWrap: "wrap",
  },
  gridFlexDivider: {
    maxWidth: "1%",
  },
  iconAccordionUp: {
    transform: "rotate(90deg)",
  },
  iconAccordionDown: {
    transform: "rotate(-90deg)",
  },
  normalBox: {
    padding: "0px 16px",
    marginLeft: "10px",
  },
  normalValue: {
    marginLeft: "5px",
  },
  optionList: (theme: Theme) => ({
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(1),
      boxShadow:
        "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
      padding: "0px 0px 0px 0px",
    },
  }),
};

export default AccordionStyles;
