import axiosRetry, { IAxiosRetryConfig } from "axios-retry";

const defaultRetriesNum = 2;

export const defaultRetryConfig: IAxiosRetryConfig = {
  retries: defaultRetriesNum,
  retryCondition: (error: any) =>
    !error.response ||
    error.response.status < 200 ||
    error.response.status >= 300,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
};
