import React, { useState } from "react";
import { Button, Menu } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { AccordionPopupProcessorMenuPayIn } from "./AccordionPopupProcessorMenuPayIn/AccordionPopupProcessorMenuPayIn";
import { AccordionPopupProcessorMenuPayOut } from "./AccordionPopupProcessorMenuPayOut/AccordionPopupProcessorMenuPayOut";

export interface IHeaderMenu {
  id: string | null;
  country?: string;
  buttonTitle: string | undefined;
}

export const PopupProcessorMenu: React.FC<IHeaderMenu> = (
  props: IHeaderMenu
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [typeAcordion, setTypeAcordion] = useState<string>("");

  const handleCloseEl = (): void => {
    setTypeAcordion("");
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeActiveAcordion = (type: string): void => {
    setTypeAcordion(type);
  };

  return (
    <React.Fragment>
      <Button startIcon={<Add />} onClick={handleClick}>
        {props.buttonTitle}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        <AccordionPopupProcessorMenuPayIn
          id={props.id}
          country={props.country}
          setActivePayIn={(type: string) => handleChangeActiveAcordion(type)}
          expanded={typeAcordion === "payin"}
        />
        <AccordionPopupProcessorMenuPayOut
          id={props.id}
          country={props.country}
          setActivePayOut={(type: string) => handleChangeActiveAcordion(type)}
          expanded={typeAcordion === "payout"}
        />
      </Menu>
    </React.Fragment>
  );
};
