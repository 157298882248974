import React from "react";
import { Box, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { AlertComponentStyles as styles } from "./AlertComponent.styles";
interface AlertComponentProps {
  title: string;
  description: string;
}
const AlertComponent: React.FC<AlertComponentProps> = ({
  title,
  description,
}: AlertComponentProps) => {
  return (
    <Box sx={styles.alertContainer}>
      <Box sx={styles.titleContainer}>
        <ErrorOutlineRoundedIcon
          data-testid={"error-icon-test"}
          sx={styles.warningIcon}
        />
        <Typography variant="body1" sx={styles.title}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography sx={styles.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default AlertComponent;
