import { ActionTypes } from "./actionTypes";
import { MerchantActions } from "./actionCreators";
import { Merchant } from "../../types/merchant";

export interface IMerchantsState {
  merchants: MerchantsFetch;
  isLoading: boolean;
}

export type MerchantsFetch = {
  data: { _source: Merchant }[];
  total: { value: number };
};

export const MERCHANT_INITIAL_STATE: IMerchantsState = {
  merchants: {
    data: [],
    total: { value: -1 },
  },
  isLoading: false,
};

export const merchantReducer = (
  state: IMerchantsState = MERCHANT_INITIAL_STATE,
  action: MerchantActions
): IMerchantsState => {
  switch (action.type) {
    case ActionTypes.SET_MERCHANTS:
      return {
        ...state,
        merchants: action.merchants!,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading!,
      };
    default:
      return state;
  }
};
