import {
  AppBar,
  Box,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  IDashboardTabsProps,
  ITabPanelProps,
} from "./DashboardTabs.interfaces";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ButtonDownloadFile } from "../ButtonDownloadFile/ButtonDownloadFile";
import { ColumnsFilterButton } from "../ColumnsFilter/ColumnsFilter";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { DashboardTable } from "../DashboardList/Table/DashboardPendingTable/DashboardTable";
import { dashboardTabsStyles } from "./DashboardTabs.styles";
import { DatePicker } from "../DatePicker/DatePicker";
import { DateRange } from "@material-ui/pickers";
import { ExecutorNavbar } from "../ExecutorNavbar/ExecutorNavbar";
import { FilterOptions } from "../FilterOptions/FilterOptions";
import { get } from "lodash";
import { Indicators } from "../Indicators/Indicators";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { MassiveTransactionModal } from "../MassiveProcessTransactionsModal/MassiveTransactionModal/MassiveTransactionModal";
import { MassiveTransactionModalResult } from "../MassiveProcessTransactionsModal/MassiveTransactionModalResult/MassiveTransactionModalResult";
import React from "react";
import { RefundBox } from "../common/RefundBox/RefundBox";
import { routes } from "../../shared/infrastructure/routes";
import { Search } from "react-feather";
import { StatusFlowEnum } from "../../shared/infrastructure/enums/StatusFlowEnum";
import { TabConstants } from "../../shared/infrastructure/constants/TabConstants";
import { useLocation } from "react-router-dom";

function tabProps(index: number) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`,
  };
}

const useStylesTextField = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingRight: theme.spacing(2),
  },
  textField: {
    height: "36px",
    marginTop: "20px",
    padding: 0,
  },
}));

export interface modalData {
  actionApproval: string;
  title: string;
  openDialog: boolean;
  hideContext: boolean;
  context: string;
  description: string;
  trxData?: transactionData;
}

export interface transactionData {
  merchantName?: string;
  merchantId?: string;
  transactionId: string;
  amount?: number;
  currency?: string;
  socialReason?: string;
}

export function TabPanel(props: ITabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function isViewer(): boolean {
  const sampleLocation = useLocation().pathname;

  return sampleLocation === routes.BASE_PATH_REFUND + routes.PATH_VIEWER;
}

export function isExecutor(): boolean {
  const sampleLocation = useLocation().pathname;

  return sampleLocation === routes.BASE_PATH_REFUND + routes.PATH_EXECUTOR;
}

export const DashboardTabs: React.FC<IDashboardTabsProps> = (
  props: IDashboardTabsProps
) => {
  const classes = dashboardTabsStyles(0);

  const classesTextField = useStylesTextField();

  const loadProcessResume = (context: boolean) => {
    !context && (props.detailsCard!.showCard = false);

    return (
      <>
        {context && (
          <Grid>
            <Box id={"merchantName"} fontSize={15} sx={{ fontWeight: "bold" }}>
              {get(props.modal, "modalRefundData.trxData.merchantName", "")}
            </Box>
            <Typography variant="body2" color="textPrimary">
              <Box fontSize={15} id={"merchantId"}>
                {"Merchant ID: " +
                  get(props.modal, "modalRefundData.trxData.merchantId", "")}
              </Box>
            </Typography>
          </Grid>
        )}
        <br />
        <Box id={"reason"} fontSize={15}>
          {props.modal.modalRefundData.description}
        </Box>
        <Grid item xs={12} xl={12} className={classes.textField}>
          <TextField
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            error={
              String(props.modal.value).length >= 200 ||
              props.modal.errorCondition
            }
            className={classes.ksh_multiline}
            autoComplete="off"
            variant="outlined"
            fullWidth
            label={"Motivo"}
            value={props.modal.value}
            id="alias"
            multiline
            helperText={
              props.modal.errorCondition
                ? "campo requerido"
                : `${String(props.modal.value).length}/200`
            }
            onChange={props.modal.handleChange}
            rows={3}
          />
        </Grid>
      </>
    );
  };

  const handleDate = (date: DateRange<Date> | MouseEvent): void => {
    if (get(date, "movementX") === 0) {
      props.filterProps.handleSetDate([null, null]);

      return;
    }
    props.filterProps.handleSetDate(date as DateRange<Date>);
  };

  let pendingTab = (
    <Tab
      label={
        <Typography variant={"h6"} color={"textPrimary"}>
          Pendientes
        </Typography>
      }
      {...tabProps(0)}
      className={classes.tabStyle}
    />
  );
  let historyTab = (
    <Tab
      label={
        <Typography variant={"h6"} color={"textPrimary"}>
          Histórico
        </Typography>
      }
      {...tabProps(1)}
      className={classes.tabStyle}
    />
  );
  let pendingGrid = (
    <Grid item xs={12}>
      <TabPanel value={props.value} index={0}>
        <Grid container item>
          <Grid item xs={12}>
            <Indicators statusFlow={props.statusFlow} />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={2} className={classesTextField.gridContainer}>
            <TextField
              value={props.filterProps.query}
              id="outlined-search"
              key={"outlinedSearch"}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search size={16} />
                  </InputAdornment>
                ),
              }}
              className={classesTextField.textField}
              variant={"outlined"}
              type="search"
              size={"small"}
              margin={"none"}
              placeholder={"Buscar ..."}
              fullWidth
              onKeyDown={(event: any) => {
                props.filterProps.handleSearchBar(event.key);
              }}
              onChange={(event: any) => {
                props.filterProps.handleInputChange(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              value={props.filterProps.range}
              disableFuture={false}
              handleDate={handleDate}
            />
          </Grid>
          <Grid item xs={4}>
            <FilterOptions
              filterProps={props.filterProps}
              setActionFilter={props.setActionFilter}
            />
            <ColumnsFilterButton
              handleShowColumnsFilter={
                props.filterColumns.handleShowColumnsFilter!
              }
            />
          </Grid>
          <Grid item xs={3} className={classes.alignRight}>
            <ButtonDownloadFile refundData={props.data.refundData} />
          </Grid>
        </Grid>
        {[StatusFlowEnum.VALIDATOR, StatusFlowEnum.EXECUTOR].includes(
          props.refundBox.statusFlow
        ) &&
          props.refundBox.trxSelectedCheck.length > 0 && (
            <RefundBox
              numberItems={props.refundBox.trxSelectedCheck.length}
              statusFlow={props.refundBox.statusFlow}
              actionHandler={props.refundBox.actionHandler}
              modalRefundData={props.refundBox.modalRefundData}
              disabledButtonsAction={
                props.actionNavBarExecutor.disabledButtonsAction
              }
            />
          )}
        <DashboardTable
          activeTab={TabConstants.PENDING}
          isLoading={props.state.isLoadingRefund!}
          pagination={props.pagination}
          handleCheckboxChange={props.actions.handleCheckboxChange}
          handleCheckboxChangeAll={props.actions.handleCheckboxChangeAll}
          check={props.check}
          orderSort={props.orderSort}
          refundData={props.data.refundData}
          filterColumns={props.filterColumns}
          menuOptions={props.menuOptions}
          setModalRefundData={props.modal.setModalRefundData}
          tabValue={props.value}
          statusFlow={props.statusFlow}
          detailsCard={props.detailsCard}
          tableOptions={props.tableOptions}
          numberItemSelect={props.refundBox.trxSelectedCheck.length}
        />
      </TabPanel>
      <LoaderModal
        open={props.state.loadingMassive!}
        handleClose={props.handleCloseAlertModal}
      />
    </Grid>
  );

  if (isViewer()) {
    pendingTab = <div />;
    historyTab = <div />;
    pendingGrid = <div />;
  }
  if (isViewer() && props.value != 1) {
    props.handleTabsChange({}, 1);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="static" className={classes.root}>
          <Tabs
            value={props.value}
            onChange={props.handleTabsChange}
            aria-label="billing tabs"
            variant="fullWidth"
            indicatorColor={"primary"}
          >
            {pendingTab}
            {historyTab}
          </Tabs>
        </AppBar>
      </Grid>
      <Grid container item>
        {pendingGrid}
        <Grid item xs={12}>
          <TabPanel value={props.value} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  value={props.filterProps.query}
                  id="outlined-search"
                  key={"outlinedSearch"}
                  autoFocus
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search size={16} />
                      </InputAdornment>
                    ),
                  }}
                  className={classesTextField.textField}
                  variant={"outlined"}
                  type="search"
                  size={"small"}
                  margin={"none"}
                  placeholder={"Buscar ..."}
                  fullWidth
                  onKeyDown={(event: any) => {
                    props.filterProps.handleSearchBar(event.key);
                  }}
                  onChange={(event: any) => {
                    props.filterProps.handleInputChange(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  value={props.filterProps.range}
                  disableFuture={false}
                  handleDate={handleDate}
                  minDate={props.minDate}
                />
              </Grid>
              <Grid item xs={4}>
                <FilterOptions
                  filterProps={props.filterProps}
                  setActionFilter={props.setActionFilter}
                />
                <ColumnsFilterButton
                  handleShowColumnsFilter={
                    props.filterColumns.handleShowColumnsFilter!
                  }
                />
              </Grid>
              <Grid item xs={3} className={classes.alignRight}>
                <ButtonDownloadFile refundData={props.data.refundHistoryData} />
              </Grid>
            </Grid>
            {isExecutor() ? (
              <ExecutorNavbar
                handleApproved={props.actionNavBarExecutor.handleApproved}
                handleDeclined={props.actionNavBarExecutor.handleDeclined}
                disabledButtonsAction={
                  props.actionNavBarExecutor.disabledButtonsAction
                }
              />
            ) : (
              ""
            )}
            <DashboardTable
              activeTab={TabConstants.HISTORY}
              isLoading={props.state.isLoadingRefund!}
              pagination={props.pagination}
              handleCheckboxChange={props.actions.handleCheckboxChangeHistoric}
              handleCheckboxChangeAll={
                props.actions.handleCheckboxChangeAllHistory
              }
              check={props.checkHistory}
              orderSort={props.orderSort}
              refundData={props.data.refundHistoryData}
              filterColumns={props.filterColumns}
              setModalRefundData={props.modal.setModalRefundData}
              tabValue={props.value}
              statusFlow={props.statusFlow}
              menuOptions={props.menuOptions}
              detailsCard={props.detailsCard}
              tableOptions={props.tableOptions}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <Grid container item>
        <ConfirmModal
          open={props.modal.modalRefundData.openDialog}
          titleText={props.modal.modalRefundData.title}
          contentText={get(props.modal, "modalRefundData.context", "")}
          acceptText={props.value === 0 ? "Enviar" : "Guardar"}
          handleClose={props.modal.handleCloseProcessTransactionsModal}
          handleAccept={
            props.value === 0 || !props.modal.modalRefundData.hideContext
              ? props.modal.handleAcceptProcessTransactions
              : props.modal.handleHistoricAcceptProcessTransactions
          }
          acceptColor="primary"
        >
          {loadProcessResume(props.modal.modalRefundData.hideContext)}
        </ConfirmModal>
      </Grid>
      <MassiveTransactionModal
        open={props.massiveTransactionModal.open}
        handleClose={props.massiveTransactionModal.handleClose}
        handleAccept={props.massiveTransactionModal.handleAccept}
        handleChange={props.massiveTransactionModal.handleChange}
        value={props.massiveTransactionModal.value}
        responseParameter={props.massiveTransactionModal.responseParameter}
        refundTransactions={props.massiveTransactionModal.refundTransactions}
      />
      <MassiveTransactionModalResult
        open={props.massiveTransactionModalResponse.open}
        handleClose={props.massiveTransactionModalResponse.handleClose}
        wsResponseFailed={
          props.massiveTransactionModalResponse.wsResponseFailed
        }
        wsResponseSuccess={
          props.massiveTransactionModalResponse.wsResponseSuccess
        }
      />
    </Grid>
  );
};
