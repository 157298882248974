import React, { useState } from "react";
import {
  AppBar,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ITabHeader } from "../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";

export interface DashboardTabsProps {
  /*isLoading: boolean;
  page: number;
  pageSize: number;
  filterProps: DataTableFiltersProps;
  historicRulesData: ResponseSearchRulesByFilters;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  hideNext: boolean;
  hidePrevious: boolean;
  tableColumns: {
    renderColumns: IColumnsTable[];
  };*/
  tabsHeader: ITabHeader[];
  tabsContent: ITabContent[];
}

export interface ITabContent {
  index: number;
  children: React.ReactNode;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: 0, paddingBottom: 0, padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontWeight: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "75%",
      height: "10%",
    },
    root: { background: "transparent", boxShadow: "none" },
    tabStyle: {
      textTransform: "none",
    },
    tabs: {
      textTransform: "none",
      padding: "0 24px",
    },
    paper: {
      backgroundColor: "#F7FAFC",
      marginTop: "15px",
      padding: "0px",
    },
  })
);
export const DashboardTabs: React.FC<DashboardTabsProps> = (
  props: DashboardTabsProps
) => {
  const [value, setValue] = useState<number>(0);
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppBar position="static" className={classes.root}>
            <Tabs
              value={value}
              onChange={handleTabsChange}
              aria-label="billing tabs"
              variant="fullWidth"
              indicatorColor={"primary"}
              className={classes.tabs}
            >
              {props.tabsHeader.map((ItemTabHeader: ITabHeader) => (
                <Tab
                  key={`tabheader_${ItemTabHeader.index}`}
                  label={
                    <Typography variant={"h6"} color={"textPrimary"}>
                      {ItemTabHeader.label}
                    </Typography>
                  }
                  {...tabProps(ItemTabHeader.index)}
                  className={classes.tabStyle}
                />
              ))}
            </Tabs>
          </AppBar>
          {props.tabsContent.map((itemTabContent: ITabContent) => (
            <TabPanel
              key={`tabpanel_${itemTabContent.index}`}
              value={value}
              index={itemTabContent.index}
            >
              <Paper className={classes.paper}>{itemTabContent.children}</Paper>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
