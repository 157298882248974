import { ActionTypes } from "./actionTypes";
import { IAppState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { Webhook, WebhookResponse } from "../../types/webhook_response";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../shared/axios-util";
import { environment } from "../environments/environment";
import { AxiosError, AxiosResponse } from "axios";
import { WebhookListRequest } from "../../types/webhook_list_request";
import { NotificationsSearchRequest } from "../../types/query";
import { NotificationsData } from "../../types/notifications_data";
import { WebhookCreateRequest } from "../../types/webhook_create_request";
import { PaymentUrl } from "../shared/infrastructure/interfaces/IWebhookForm";
import { IValidUrl } from "../shared/infrastructure/interfaces/IValidUrl";
import { get } from "lodash";
import { IMerchantCredential } from "../shared/infrastructure/interfaces/IMerchantCredential";
import { VposMerchant } from "../../types/vpos_merchant";
import { SmartlinkMerchant } from "../../types/smartlink_merchant";
import { SubscriptionMerchant } from "../../types/subscription_merchant";
import { MerchantSettingsResponse } from "../shared/infrastructure/interfaces/IMerchantSettingsResponse";
import { WebhookNotification } from "../../types/webhook_notification";
import { GetNodeInfoResponse } from "../../types/get_node_info_response";
import { GetNodeInfoRequest } from "../../types/get_node_info_request";

export type IAppAction = { type: string } & IAppState;

export interface WebhookSignatureResponse {
  webhookSignature: string;
}

export interface PreauthSaveRequest {
  preAuthorizationUrl: string;
  sandboxEnable: boolean;
  webhookSignature: string;
}

export interface CardMerchantResponse {
  country?: string;
  sift_science?: {
    SandboxApiKey?: string;
    ProdApiKey?: string;
    SandboxAccountId?: string;
    ProdAccountId?: string;
    BaconProdApiKey?: string;
    BaconSandboxApiKey?: string;
  };
  whiteList?: boolean;
  sandboxEnable?: boolean;
  acceptCreditCards?: string[];
}

export const setPreauthEnable = (enbale: boolean) => ({
  type: ActionTypes.SET_PREAUTH_ENABLE,
  isPreauthEnable: enbale,
});

export const setNotification = (payload: INotification): IAppAction => ({
  type: ActionTypes.SET_NOTIFICATION,
  notification: payload,
});

export const setWebhookResponse = (payload: WebhookResponse): IAppAction => ({
  type: ActionTypes.SET_WEBHOOK,
  webhookList: payload,
});

export const setWebhookSelected = (payload: Webhook): IAppAction => ({
  type: ActionTypes.SET_WEBHOOK_SELECTED,
  webhookSelected: payload,
});
export const setVerifiedUrls = (payload: IValidUrl): IAppAction => ({
  type: ActionTypes.SET_VERIFIED_URLS,
  verifiedUrls: payload,
});

export const setLoading = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING,
  loading: payload,
});

export const setWebhookSignature = (payload: string): IAppAction => ({
  type: ActionTypes.SET_WEBHOOK_SIGNATURE,
  webhookSignature: payload,
});

export const setWebhookNotifications = (
  payload: NotificationsData
): IAppAction => ({
  type: ActionTypes.SET_WEBHOOK_NOTIFICATIONS,
  notificationsData: payload,
});

export const setMerchantStatus = (payload: string): IAppAction => ({
  type: ActionTypes.SET_MERCHANT_STATUS,
  merchantStatus: payload,
});

export const setMerchantSettings = (
  payload: MerchantSettingsResponse
): IAppAction => ({
  type: ActionTypes.SET_MERCHANT_SETTINGS,
  merchantSettings: payload,
});

export const setVposActive = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_VPOS_ACTIVE,
  isVposActive: payload,
});

export const setSmartlinkActive = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_SMARTLINK_ACTIVE,
  isSmartlinkActive: payload,
});

export const setSubscriptionActive = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_SUBSCRIPTION_ACTIVE,
  isSubscriptionActive: payload,
});

export const setShowError = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_SHOW_ERROR,
  isShowError: payload,
});

export const setLoadingResendNotification = (
  isLoadingResendNotification: boolean
): IAppAction => ({
  type: ActionTypes.SET_LOADING_RESEND_NOTIFICATION,
  isLoadingResendNotification,
});

export const setNodeInfo = (payload: GetNodeInfoResponse): IAppAction => ({
  type: ActionTypes.SET_NODE_INFO,
  nodeInfo: payload,
});

export const getWebhookList = (
  listRequest: WebhookListRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post<WebhookResponse>(
        `${environment.kushkiUrl}/webhook/v1/list`,
        listRequest
      )
      .then((response: AxiosResponse) => {
        dispatch(setWebhookResponse(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const validateUrl = (
  request: PaymentUrl[]
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/webhook/v1/testConnection`, {
        webhookData: request,
      })
      .then((response: AxiosResponse<IValidUrl>) => {
        dispatch(setVerifiedUrls(response.data));
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: "Hubo un error al validar las urls",
            open: true,
          })
        );
      });
  };
};

export const createWebhook = (
  createRequest: WebhookCreateRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoading(true));
    axios
      .post(`${environment.kushkiUrl}/webhook/v1/webhooks`, createRequest)
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch((error: AxiosError) => {
        const code: string = get(error.response!.data, "code");
        let message: string = "";
        if (code === "AUT006") {
          message =
            "No es posible realizar ninguna acción en modo Asistencia Remota";
        } else {
          message = "Algo salió mal! No se ha creado el webhook.";
        }
        dispatch(
          setNotification({
            type: "error",
            message: message,
            open: true,
          })
        );
        dispatch(setLoading(false));
      });
  };
};
export const getMerchantInfo = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoading(true));
    axios
      .post(`${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`, {
        publicMerchantId,
      })
      .then((response: AxiosResponse) => {
        localStorage.setItem(
          "merchantInformation",
          JSON.stringify(response.data)
        );
      })
      .catch(() => {
        localStorage.setItem("merchantInformation", "{}");
      });
  };
};

export const updateWebhook = (
  updateRequest: WebhookCreateRequest,
  webhookId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoading(true));
    axios
      .patch<WebhookResponse>(
        `${environment.kushkiUrl}/webhook/v1/webhooks/${webhookId}`,
        updateRequest
      )
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch((error: AxiosError) => {
        const code: string = get(error.response!.data, "code");
        let message: string = "";
        if (code === "AUT006") {
          message =
            "No es posible realizar ninguna acción en modo Asistencia Remota";
        } else {
          message = "Algo salió mal! No se pudo actualizar el webhook.";
        }
        dispatch(
          setNotification({
            type: "error",
            message: message,
            open: true,
          })
        );
        dispatch(setLoading(true));
      });
  };
};

export const deleteWebhook = (
  webhookId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .delete(`${environment.kushkiUrl}/webhook/v1/admin/webhooks/${webhookId}`)
      .then((response: AxiosResponse) => {
        dispatch(setWebhookResponse(response.data));
        dispatch(
          setNotification({
            type: "info",
            message: "Se ha eliminado el webhook con éxito",
            open: true,
          })
        );
      })
      .catch((error: AxiosError) => {
        const code: string = get(error.response!.data, "code");
        let message: string = "";

        if (code === "AUT006") {
          message =
            "No es posible realizar ninguna acción en modo Asistencia Remota";
        } else {
          message = "Algo salió mal! No se ha eliminado el webhook.";
        }
        dispatch(
          setNotification({
            type: "error",
            message: message,
            open: true,
          })
        );
      });
  };
};

export const getWebhookNotifications = (
  body: NotificationsSearchRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    delete body.timeZone;
    dispatch(setLoading(true));
    axios
      .post(`${environment.kushkiUrl}/webhook/v1/webhooks/notifications`, body)
      .then((response: AxiosResponse) => {
        dispatch(setLoading(false));
        dispatch(
          setWebhookNotifications({
            total: response.data.total,
            notifications: response.data.hits,
          })
        );
      })
      .catch((e) => console.log(e));
  };
};

export const testConnectionWebhook = (bodyTestConn: {
  service: string;
  kindTransaction: string;
  body: string;
  urls: string[];
}): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/webhook/v1/testConnection`, {
        webhookData: [bodyTestConn],
      })
      .then((response: AxiosResponse) => {
        console.log(response);
        dispatch(
          setNotification({
            type: "info",
            message: "Se ha simulado la notificación con éxito.",
            open: true,
          })
        );
      })
      .catch((error: AxiosError) => {
        const code: string = get(error.response!.data, "code");
        let message: string = "";

        if (code === "AUT006") {
          message =
            "No es posible realizar ninguna acción en modo Asistencia Remota";
        } else {
          message = "Algo salió mal! No se ha simulado con éxito";
        }
        dispatch(
          setNotification({
            type: "error",
            message: message,
            open: true,
          })
        );
      });
  };
};

export const getWebhookSignature = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(
        `${environment.kushkiUrl}/webhook/v1/admin/signature/${publicMerchantId}`
      )
      .then((response: AxiosResponse<{ webhookSignature: string }>) => {
        dispatch(setWebhookSignature(response.data.webhookSignature));
      })
      .catch(() => {
        dispatch(setWebhookSignature(""));
      });
  };
};

export const saveUrlPreauth = (
  preauth: PreauthSaveRequest,
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      await axios
        .get(
          `${environment.kushkiUrl}/webhook/v1/admin/signature/${merchantId}`
        )
        .then((response: AxiosResponse<{ webhookSignature: string }>) => {
          preauth.webhookSignature = response.data.webhookSignature;
        });
    } catch (e) {
      setNotification({
        type: "error",
        message: "Hubo un problema al obterner la firma del web hook",
        open: true,
      });
    }
    try {
      await axios
        .get<CardMerchantResponse>(
          `${environment.kushkiUrl}/card/v1/admin/merchant/${merchantId}`
        )
        .then((response: AxiosResponse<CardMerchantResponse>) => {
          preauth.sandboxEnable = !!get(response.data, "sandboxEnable", false);
        });
    } catch (e) {
      dispatch(
        setNotification({
          type: "error",
          message:
            "Hubo un error al obtener la información del sandbox, valor por default false",
          open: true,
        })
      );
      preauth.sandboxEnable = false;
    }
    try {
      await axios.patch(
        `${environment.kushkiUrl}/cash/v1/admin/merchant/${merchantId}`,
        {
          preAuthorizationUrl: preauth.preAuthorizationUrl,
          sandboxEnable: preauth.sandboxEnable,
          webhookSignature: preauth.webhookSignature,
        }
      );
    } catch (e) {
      dispatch(
        setNotification({
          type: "error",
          message: "Hubo un error al guardar el url de preautorización",
          open: true,
        })
      );
    }
  };
};

export const getMerchantSettings = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const response: AxiosResponse<IMerchantCredential[]> = await axios.get(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${publicMerchantId}`
      );
      const credential: IMerchantCredential = response.data.find(
        (credential) => credential.enable
      )!;
      const { data }: AxiosResponse<MerchantSettingsResponse> = await axios.get(
        `${environment.kushkiUrl}/merchant/v1/merchant/settings`,
        {
          headers: {
            ["Public-Merchant-Id"]: credential.publicCredentialId,
          },
        }
      );

      dispatch(setMerchantSettings(data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getVposMerchant = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const {
        data: { active = false },
      }: AxiosResponse<VposMerchant> = await axios.get(
        `${environment.kushkiUrl}/vpos/v1/admin/merchant/${publicMerchantId}`
      );
      dispatch(setVposActive(active));
    } catch (e) {
      dispatch(setVposActive(false));
    }
  };
};

export const getSmartlinkMerchant = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const {
        data: { status = false },
      }: AxiosResponse<SmartlinkMerchant> = await axios.get(
        `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${publicMerchantId}`
      );
      dispatch(setSmartlinkActive(status));
    } catch (e) {
      dispatch(setSmartlinkActive(false));
    }
  };
};

export const getSubscriptionMerchant = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const {
        data: { enable = false },
      }: AxiosResponse<SubscriptionMerchant> = await axios.get(
        `${environment.kushkiUrl}/v1/subscriptions/admin/merchant/${publicMerchantId}`
      );
      dispatch(setSubscriptionActive(enable));
    } catch (e) {
      dispatch(setSubscriptionActive(false));
    }
  };
};

export const resendWebhookNotification = (
  payload: WebhookNotification
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(setLoadingResendNotification(true));
      await axios.post(
        `${environment.kushkiUrl}/webhook/v1/webhooks/notification`,
        payload
      );
      dispatch(
        setNotification({
          type: "success",
          message: "Se ha reenviado la notificación con éxito.",
          open: true,
        })
      );
    } catch (e) {
    } finally {
      dispatch(setLoadingResendNotification(false));
    }
  };
};

export const getMerchantStatus = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const body = {
      merchant_id: merchantId,
      limit: 1,
      offset: 0,
    };
    try {
      const response: AxiosResponse = await axios.post(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        body
      );
      const data = get(response, "data.data", []);
      const status = data.length ? get(data[0], "_source.status", "") : "";
      dispatch(setMerchantStatus(status));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNodeInfo = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const body: GetNodeInfoRequest = {
      publicMerchantId,
    };
    try {
      const { data }: AxiosResponse<GetNodeInfoResponse> = await axios.post(
        `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
        body
      );
      dispatch(setNodeInfo(data));
    } catch (e) {
      console.log(e);
    }
  };
};
