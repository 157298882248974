import { useEffect, useState } from "react";
import { ConfigIdEnum } from "../../../shared/constants/node_config_enums";
import { IBranchBatchItem } from "../../../shared/interfaces/massiveBranch/massiveBranch.interfaces";
import { ITabItem } from "../../WrappedTabs/TabItem/TabItem.interfaces";
import {
  buildLastModifyProps,
  generateChargeTabs,
  generateDefaultTabs,
  getRoutesByBranchBatch,
  mapConfigurationByTabRouteMassive,
} from "../../../shared/constants/wrapper_container_constants";
import { defaultTo, get, isEmpty } from "lodash";
import { SecurityWrapperByCountry } from "../../WrapperContainer/SecurityWrapper";
import {
  IFooterButtons,
  IUseWrapperContainerProps,
} from "../../WrapperContainer/state/useWrapperContainer.interfaces";
import {
  ENABLE_SAVE_BUTTON,
  HANDLE_MASSIVE_MODAL_LOADER,
  HIDE_SNACKBAR,
  RESET_SHARED_VALUES,
  SET_FOOTER_BUTTON_CLICKED,
  SET_TYPE_REQUEST,
  UPDATE_MASSIVE_ROUTE,
  updateLocalConfigResponse,
} from "../../../store/reducers/app/app.slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { ILastModify } from "../../LastModify/LastModify.interfaces";
import {
  getBillingConfig,
  getNodeInfo,
} from "../../../store/thunks/app/app.thunks";
import {
  buildBranchesBatchMostCurrent,
  getAllConfigsUpdated,
  getTabsValueMassive,
} from "../../../shared/utils/massiveBranchUtils";
import {
  Configs,
  NodeInfoResponse,
} from "../../../../types/node_info_response";
import { EntityName } from "../../../shared/enums/entityName";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { handleTimeoutDispatch } from "../../../shared/utils/timeoutUtils";
import { TypeRequestEnum } from "../../../shared/enums/typeRequestEnum";
import { ROUTES } from "../../../shared/constants/routes";
import { IUseMassiveWrapperContainer } from "./useWrapperMassive.interfaces";
import {
  createNodePaths,
  projectPaths,
} from "../../../shared/enums/projects_path_enum";

export const useWrapperMassiveContainer = ({
  currentRoute,
  type,
}: IUseWrapperContainerProps): IUseMassiveWrapperContainer => {
  const {
    showSnackbarError,
    showSnackbarSuccess,
    massiveCurrentRoute: currentRouteStore,
    buttons: { disabledSaveButton, disabledContinueButton },
    openModalLoaderWS,
    configsResponse,
    massiveMostCurrentBranches,
    massiveMostCurrentBranches: { billing, dispersion, charge },
    nodeInfo: nodeInfoGeneric,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.dispersion,
  }));

  const [country, setCountry] = useState<string>("");
  const getNodeInfoByRoute = (route: string): NodeInfoResponse | undefined =>
    ({
      [`${ROUTES.MASSIVE_BILLING}`]: billing,
      [`${ROUTES.MASSIVE_DISPERSION}`]: dispersion,
      [`${ROUTES.MASSIVE_CHARGE}`]: charge,
    }[route]);
  const nodeInfo = getNodeInfoByRoute(currentRoute);
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigate();
  const branchesBatch = buildBranchesBatchMostCurrent();
  const [tabs, setTabs] = useState<ITabItem[]>(
    getRoutesByBranchBatch(branchesBatch, true, currentRoute)
  );
  const [footerLabels, setFooterLabels] = useState<ILastModify | null>(null);
  const [footerButtons, setFooterButtons] = useState<IFooterButtons>({});
  const [isCurrentRouteUpdated, setIsCurrentRouteUpdated] = useState(
    currentRouteStore === currentRoute
  );
  const [openSelectedBranch, setOpenSelectedBranch] = useState<boolean>(false);
  const [firstModifyDate, setFirstCurrentModifyDate] = useState<number | null>(
    null
  );
  const [currentModifyDate, setCurrentModifyDate] = useState<number | null>(
    null
  );

  const isEcuadorCountry = (): boolean =>
    get(nodeInfo, "generalInfo.country") === CountriesEnum.ECUADOR;

  const requestBillingConfig = (value: string) => {
    dispatch(
      getBillingConfig({
        merchantId: value,
      })
    );
  };
  const updateLastModify = (configsResponse: Configs[]) => {
    const data = configsResponse.find(
      (config) =>
        get(config, "configuration") ===
        mapConfigurationByTabRouteMassive.get(currentRoute)
    );

    const userName = get(data, "updatedBy");
    const updatedAt = get(data, "updatedAt");

    if (userName && updatedAt) {
      if (!firstModifyDate) setFirstCurrentModifyDate(updatedAt);
      setCurrentModifyDate(updatedAt);
      setFooterLabels(buildLastModifyProps(updatedAt, userName));
    }
  };
  const requestNodeInfo = (publicMerchantId: string, configId: string) => {
    dispatch(
      getNodeInfo({
        configIds: configId,
        publicMerchantId,
      })
    );
  };
  const activeFooterButton = (type: string) => {
    dispatch(SET_TYPE_REQUEST(type));
    dispatch(
      type !== TypeRequestEnum.FINISHED
        ? SET_FOOTER_BUTTON_CLICKED(true)
        : HANDLE_MASSIVE_MODAL_LOADER(true)
    );
  };

  const updateTabs = (tabs: ITabItem[], configsResponse: Configs[]) => {
    setTabs(getTabsValueMassive(tabs, configsResponse));
  };

  const hideSnackbarAfterTime = () => {
    handleTimeoutDispatch(dispatch, HIDE_SNACKBAR);
  };

  const handleValidCentralizedBranch = (
    configId: string,
    dataNode?: NodeInfoResponse
  ): boolean => {
    const config = get(dataNode, "configs", []).find(
      (c) => c.configuration === configId
    );

    return (
      get(dataNode, "entityName", "") === EntityName.BRANCH &&
      !isEmpty(get(config, "centralizedNodesId"))
    );
  };

  const getFooterButtonsDisabledValues = (nodeInfo?: NodeInfoResponse) => {
    return {
      [`${ROUTES.MASSIVE_BILLING}`]: {
        disableContinue: false,
        disableSave: handleValidCentralizedBranch(ConfigIdEnum.CN003, nodeInfo),
        isHidden: false,
      },
      [`${ROUTES.MASSIVE_DISPERSION}`]: {
        disableContinue: disabledContinueButton,
        disableSave:
          handleValidCentralizedBranch(ConfigIdEnum.CN004, nodeInfo) ||
          disabledSaveButton,
        isHidden: false,
      },
      [`${ROUTES.MASSIVE_CHARGE}`]: {
        disableContinue: disabledContinueButton,
        disableSave:
          handleValidCentralizedBranch(ConfigIdEnum.CN017, nodeInfo) ||
          disabledSaveButton,
        isHidden: false,
      },
    }[currentRoute];
  };

  const securityIdPrimary = (): string => {
    const path = `${type}.footer.primary`;

    return get(SecurityWrapperByCountry[country!], path, "");
  };

  const securityIdSecondary = (): string => {
    const path = `${type}.footer.secondary`;

    return get(SecurityWrapperByCountry[country!], path, "");
  };

  const handleModalDialog = (isOpen: boolean) => {
    if (firstModifyDate !== currentModifyDate) {
      dispatch(SET_FOOTER_BUTTON_CLICKED(false));
      window.location.href = projectPaths.CREATE_NODE.concat(
        createNodePaths.BRANCH_SELECTION
      );
    } else {
      dispatch(HANDLE_MASSIVE_MODAL_LOADER(isOpen));
    }
  };

  const handleAcceptReturnButton = () => {
    dispatch(HANDLE_MASSIVE_MODAL_LOADER(false));
  };

  const handleOpenSelectedBranch = (isOpen: boolean) => {
    setOpenSelectedBranch(isOpen);
  };

  const updateNodeConfigsInfo = (nodeInfo: NodeInfoResponse) => {
    if (nodeInfo.merchantId) {
      dispatch(
        updateLocalConfigResponse(
          getAllConfigsUpdated(defaultTo(configsResponse, []), nodeInfo)
        )
      );
    }
  };

  const requestNodesInfoMostCurrent = () => {
    branchesBatch.forEach((branch: IBranchBatchItem) => {
      requestNodeInfo(branch.publicMerchantId, branch.section);
    });
  };

  useEffect(() => {
    if (isEmpty(massiveMostCurrentBranches)) requestNodesInfoMostCurrent();

    dispatch(HIDE_SNACKBAR());
    dispatch(ENABLE_SAVE_BUTTON());
    dispatch(RESET_SHARED_VALUES());
    dispatch(UPDATE_MASSIVE_ROUTE(currentRoute));
  }, []);

  useEffect(() => {
    setIsCurrentRouteUpdated(currentRouteStore === currentRoute);
  }, [currentRouteStore]);

  useEffect(() => {
    const isNodeInfoFromDifferentCurrentRoute =
      isEmpty(nodeInfo) || currentRouteStore !== currentRoute;

    if (isNodeInfoFromDifferentCurrentRoute) return;

    const configs: Configs[] = get(nodeInfo, "configs", []);
    const valueFilter: string = get(configs, "[0].value", "");

    if (!isEmpty(valueFilter) && currentRoute === ROUTES.MASSIVE_BILLING) {
      requestBillingConfig(valueFilter);
    }
  }, [nodeInfo, currentRouteStore]);

  useEffect(() => {
    if (!nodeInfoGeneric) return;

    if (get(nodeInfoGeneric, "entityName") !== EntityName.OWNER) {
      updateNodeConfigsInfo(nodeInfoGeneric);
    }
  }, [nodeInfoGeneric]);

  useEffect(() => {
    if (showSnackbarError || showSnackbarSuccess) hideSnackbarAfterTime();
  }, [showSnackbarError, showSnackbarSuccess]);

  useEffect(() => {
    if (configsResponse) {
      updateTabs(
        isEcuadorCountry()
          ? generateChargeTabs(true)
          : generateDefaultTabs(true),
        configsResponse
      );
      updateLastModify(configsResponse);
    }
  }, [configsResponse]);

  useEffect(() => {
    if (!nodeInfo) return;
    if (!isEcuadorCountry() && currentRoute == ROUTES.MASSIVE_CHARGE) {
      navigate(ROUTES.MASSIVE_DISPERSION);
    }
    setCountry(get(nodeInfo, "generalInfo.country", ""));
    securityIdSecondary();
    securityIdPrimary();
  }, [nodeInfo]);

  useEffect(() => {
    setFooterButtons(getFooterButtonsDisabledValues(nodeInfo));
  }, [disabledSaveButton, disabledContinueButton, nodeInfo]);

  return {
    activeFooterButton,
    footerButtons,
    footerLabels,
    handleAcceptReturnButton,
    handleModalDialog,
    handleOpenSelectedBranch,
    openModalLoader: openModalLoaderWS,
    openSelectedBranch,
    operationCountry: country,
    securityIdPrimary,
    securityIdSecondary,
    snackbar: {
      showError: showSnackbarError && isCurrentRouteUpdated,
      showSuccess: showSnackbarSuccess && isCurrentRouteUpdated,
    },
    tabs,
  };
};
