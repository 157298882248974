/* istanbul ignore file */
export enum ActionTypes {
  SET_LOADING = "[PAYOUTS-TRANSACTION] SET_LOADING",
  SET_NOTIFICATION = "[PAYOUTS-TRANSACTION] SET_NOTIFICATION",
  SET_TRANSACTIONS = "[PAYOUTS-TRANSACTION] SET_TRANSACTIONS",
  SET_MERCHANTS_LIST = "[PAYOUTS-TRANSACTION] SET_MERCHANTS_LIST",
  SET_MERCHANTS = "[PAYOUTS-TRANSACTION] SET_MERCHANTS",
  SET_LOADING_DOWNLOAD = "[PAYOUTS-TRANSACTION] SET_LOADING_DOWNLOAD",
  SET_LOADING_DOWNLOAD_BY_ID = "[PAYOUTS-TRANSACTION] SET_LOADING_DOWNLOAD_BY_ID",
  SET_RECEIPT = "[Transactions] SET_RECEIPT",
  SET_LOADING_RECEIPT = "[PAYOUTS-TRANSACTION] SET_LOADING_RECEIPT",
}
