/**
 * CatalogEc
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogEeuu: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "Seguro Social SSN", value: "0" },
    { name: "Identificación del Empleador EIN", value: "1" },
    {
      name: "Identificación Personal del Contribuyente ITIN",
      value: "2",
    },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "Nueva York", value: "NY" },
    { name: "California", value: "CA" },
    { name: "Illinois", value: "IL" },
    { name: "Texas", value: "TX" },
    { name: "Arizona", value: "AZ" },
    { name: "Pensilvania", value: "PA" },
    { name: "Carolina del Norte", value: "NC" },
    { name: "Ohio", value: "OH" },
    { name: "Florida", value: "FL" },
    { name: "Indiana", value: "IN" },
    { name: "Colorado", value: "CO" },
    { name: "Washington", value: "WA" },
    { name: "Distrito de Columbia", value: "DC" },
    { name: "Massachusetts", value: "MA" },
    { name: "Tennessee", value: "TN" },
    { name: "Oklahoma", value: "OK" },
    { name: "Oregón", value: "OR" },
    { name: "Nevada", value: "NV" },
    { name: "Kentucky", value: "KY" },
    { name: "Míchigan", value: "MI" },
    { name: "Maryland", value: "MD" },
    { name: "Wisconsin", value: "WI" },
    { name: "Nuevo México", value: "NM" },
    { name: "Georgia", value: "GA" },
    { name: "Misuri", value: "MO" },
    { name: "Nebraska", value: "NE" },
    { name: "Virginia", value: "VA" },
    { name: "Minnesota", value: "MN" },
    { name: "Kansas", value: "KS" },
    { name: "Luisiana", value: "LA" },
    { name: "Hawái", value: "HI" },
    { name: "Alaska", value: "AK" },
    { name: "Nueva Jersey", value: "NJ" },
    { name: "Alabama", value: "AL" },
    { name: "Idaho", value: "ID" },
    { name: "Iowa", value: "IA" },
    { name: "Arkansas", value: "AR" },
    { name: "Rhode Island", value: "RI" },
    { name: "Misisipi", value: "MS" },
    { name: "Dakota del Sur", value: "SD" },
    { name: "Connecticut", value: "CT" },
    { name: "Utah", value: "UT" },
    { name: "Nuevo Hampshire", value: "NH" },
    { name: "Dakota del Norte", value: "ND" },
    { name: "Montana", value: "MT" },
    { name: "Puerto Rico", value: "PR" },
    { name: "Otras Ciudades", value: "OC" },
  ],
  [CatalogsEnum.Cities]: [
    {
      name: "Lima",
      parent: "OC",
      value: "Lima",
    },
    {
      name: "Bogota",
      parent: "OC",
      value: "Bogota",
    },
    {
      name: "Quito",
      parent: "OC",
      value: "Quito",
    },
    {
      name: "Santiago",
      parent: "OC",
      value: "Santiago",
    },
    {
      name: "Ciudad de Mexico",
      parent: "OC",
      value: "Ciudad de Mexico",
    },
    { name: "Nueva York", parent: "Nueva York", value: "Nueva York" },
    { name: "Los Angeles", parent: "California", value: "Los Angeles" },
    { name: "Chicago", parent: "Illinois", value: "Chicago" },
    { name: "Houston", parent: "Texas", value: "Houston" },
    { name: "Phoenix", parent: "Arizona", value: "Phoenix" },
    { name: "San Antonio", parent: "Texas", value: "San Antonio" },
    { name: "Filadelfia", parent: "Pensilvania", value: "Filadelfia" },
    { name: "San Diego", parent: "California", value: "San Diego" },
    { name: "Dallas", parent: "Texas", value: "Dallas" },
    { name: "San José", parent: "California", value: "San José" },
    { name: "Austin", parent: "Texas", value: "Austin" },
    { name: "Charlotte", parent: "Carolina del Norte", value: "Charlotte" },
    { name: "Fort Worth", parent: "Texas", value: "Fort Worth" },
    { name: "San Francisco", parent: "California", value: "San Francisco" },
    { name: "Columbus", parent: "Ohio", value: "Columbus" },
    { name: "Jacksonville", parent: "Florida", value: "Jacksonville" },
    { name: "Indianápolis", parent: "Indiana", value: "Indianápolis" },
    { name: "Dénver", parent: "Colorado", value: "Dénver" },
    { name: "Seattle", parent: "Washington", value: "Seattle" },
    {
      name: "Washington D. C.",
      parent: "Distrito de Columbia",
      value: "Washington D. C.",
    },
    { name: "El Paso", parent: "Texas", value: "El Paso" },
    { name: "Boston", parent: "Massachusetts", value: "Boston" },
    { name: "Nashville", parent: "Tennessee", value: "Nashville" },
    { name: "Oklahoma City", parent: "Oklahoma", value: "Oklahoma City" },
    { name: "Portland", parent: "Oregón", value: "Portland" },
    { name: "Memphis", parent: "Tennessee", value: "Memphis" },
    { name: "Las Vegas", parent: "Nevada", value: "Las Vegas" },
    { name: "Louisville", parent: "Kentucky", value: "Louisville" },
    { name: "Detroit", parent: "Míchigan", value: "Detroit" },
    { name: "Baltimore", parent: "Maryland", value: "Baltimore" },
    { name: "Milwaukee", parent: "Wisconsin", value: "Milwaukee" },
    { name: "Albuquerque", parent: "Nuevo México", value: "Albuquerque" },
    { name: "Fresno", parent: "California", value: "Fresno" },
    { name: "Tucson", parent: "Arizona", value: "Tucson" },
    { name: "Sacramento", parent: "California", value: "Sacramento" },
    { name: "Mesa", parent: "Arizona", value: "Mesa" },
    { name: "Raleigh", parent: "Carolina del Norte", value: "Raleigh" },
    { name: "Atlanta", parent: "Georgia", value: "Atlanta" },
    { name: "Colorado Springs", parent: "Colorado", value: "Colorado Springs" },
    {
      name: "Kansas City (Misuri)",
      parent: "Misuri",
      value: "Kansas City (Misuri)",
    },
    { name: "Long Beach", parent: "California", value: "Long Beach" },
    { name: "Omaha", parent: "Nebraska", value: "Omaha" },
    { name: "Miami", parent: "Florida", value: "Miami" },
    { name: "Virginia Beach", parent: "Virginia", value: "Virginia Beach" },
    { name: "Oakland", parent: "California", value: "Oakland" },
    { name: "Mineápolis", parent: "Minnesota", value: "Mineápolis" },
    { name: "Arlington", parent: "Texas", value: "Arlington" },
    { name: "Tulsa", parent: "Oklahoma", value: "Tulsa" },
    { name: "Wichita", parent: "Kansas", value: "Wichita" },
    { name: "Cleveland", parent: "Ohio", value: "Cleveland" },
    { name: "Bakersfield", parent: "California", value: "Bakersfield" },
    { name: "Nueva Orleans", parent: "Luisiana", value: "Nueva Orleans" },
    { name: "Honolulú", parent: "Hawái", value: "Honolulú" },
    { name: "Anaheim", parent: "California", value: "Anaheim" },
    { name: "Tampa", parent: "Florida", value: "Tampa" },
    { name: "Aurora", parent: "Colorado", value: "Aurora" },
    { name: "Santa Ana", parent: "California", value: "Santa Ana" },
    { name: "San Luis", parent: "Misuri", value: "San Luis" },
    { name: "Pittsburgh", parent: "Pensilvania", value: "Pittsburgh" },
    { name: "Corpus Christi", parent: "Texas", value: "Corpus Christi" },
    { name: "Riverside", parent: "California", value: "Riverside" },
    { name: "Cincinnati", parent: "Ohio", value: "Cincinnati" },
    { name: "Lexington", parent: "Kentucky", value: "Lexington" },
    { name: "Anchorage", parent: "Alaska", value: "Anchorage" },
    { name: "Stockton", parent: "California", value: "Stockton" },
    { name: "Toledo", parent: "Ohio", value: "Toledo" },
    { name: "Saint Paul", parent: "Minnesota", value: "Saint Paul" },
    { name: "Newark", parent: "Nueva Jersey", value: "Newark" },
    { name: "Greensboro", parent: "Carolina del Norte", value: "Greensboro" },
    { name: "Búfalo", parent: "Nueva York", value: "Búfalo" },
    { name: "Plano", parent: "Texas", value: "Plano" },
    { name: "Lincoln", parent: "Nebraska", value: "Lincoln" },
    { name: "Henderson", parent: "Nevada", value: "Henderson" },
    { name: "Fort Wayne", parent: "Indiana", value: "Fort Wayne" },
    { name: "Jersey City", parent: "Nueva Jersey", value: "Jersey City" },
    { name: "San Petersburgo", parent: "Florida", value: "San Petersburgo" },
    { name: "Chula Vista", parent: "California", value: "Chula Vista" },
    { name: "Norfolk", parent: "Virginia", value: "Norfolk" },
    { name: "Orlando", parent: "Florida", value: "Orlando" },
    { name: "Chandler", parent: "Arizona", value: "Chandler" },
    { name: "Laredo", parent: "Texas", value: "Laredo" },
    { name: "Madison", parent: "Wisconsin", value: "Madison" },
    {
      name: "Winston-Salem",
      parent: "Carolina del Norte",
      value: "Winston-Salem",
    },
    { name: "Lubbock", parent: "Texas", value: "Lubbock" },
    { name: "Baton Rouge", parent: "Luisiana", value: "Baton Rouge" },
    { name: "Durham", parent: "Carolina del Norte", value: "Durham" },
    { name: "Garland", parent: "Texas", value: "Garland" },
    { name: "Glendale", parent: "Arizona", value: "Glendale" },
    { name: "Reno", parent: "Nevada", value: "Reno" },
    { name: "Hialeah", parent: "Florida", value: "Hialeah" },
    { name: "Paradise", parent: "Nevada", value: "Paradise" },
    { name: "Chesapeake", parent: "Virginia", value: "Chesapeake" },
    { name: "Scottsdale", parent: "Arizona", value: "Scottsdale" },
    { name: "North Las Vegas", parent: "Nevada", value: "North Las Vegas" },
    { name: "Irving", parent: "Texas", value: "Irving" },
    { name: "Fremont", parent: "California", value: "Fremont" },
    { name: "Irvine", parent: "California", value: "Irvine" },
    { name: "Birmingham", parent: "Alabama", value: "Birmingham" },
    { name: "Rochester", parent: "Nueva York", value: "Rochester" },
    { name: "San Bernardino", parent: "California", value: "San Bernardino" },
    { name: "Spokane", parent: "Washington", value: "Spokane" },
    { name: "Gilbert", parent: "Arizona", value: "Gilbert" },
    { name: "Arlington", parent: "Virginia", value: "Arlington" },
    { name: "Montgomery", parent: "Alabama", value: "Montgomery" },
    { name: "Boise", parent: "Idaho", value: "Boise" },
    { name: "Richmond", parent: "Virginia", value: "Richmond" },
    { name: "Des Moines", parent: "Iowa", value: "Des Moines" },
    { name: "Modesto", parent: "California", value: "Modesto" },
    {
      name: "Fayetteville",
      parent: "Carolina del Norte",
      value: "Fayetteville",
    },
    { name: "Shreveport", parent: "Luisiana", value: "Shreveport" },
    { name: "Akron", parent: "Ohio", value: "Akron" },
    { name: "Tacoma", parent: "Washington", value: "Tacoma" },
    { name: "Aurora", parent: "Illinois", value: "Aurora" },
    { name: "Oxnard", parent: "California", value: "Oxnard" },
    { name: "Fontana", parent: "California", value: "Fontana" },
    { name: "Yonkers", parent: "Nueva York", value: "Yonkers" },
    { name: "Augusta", parent: "Georgia", value: "Augusta" },
    { name: "Mobile", parent: "Alabama", value: "Mobile" },
    { name: "Little Rock", parent: "Arkansas", value: "Little Rock" },
    { name: "Moreno Valley", parent: "California", value: "Moreno Valley" },
    { name: "Glendale", parent: "California", value: "Glendale" },
    { name: "Amarillo", parent: "Texas", value: "Amarillo" },
    {
      name: "Huntington Beach",
      parent: "California",
      value: "Huntington Beach",
    },
    { name: "Columbus", parent: "Georgia", value: "Columbus" },
    { name: "Grand Rapids", parent: "Míchigan", value: "Grand Rapids" },
    { name: "Salt Lake City", parent: "Utah", value: "Salt Lake City" },
    { name: "Tallahassee", parent: "Florida", value: "Tallahassee" },
    { name: "Worcester", parent: "Massachusetts", value: "Worcester" },
    { name: "Newport News", parent: "Virginia", value: "Newport News" },
    { name: "Huntsville", parent: "Alabama", value: "Huntsville" },
    { name: "Knoxville", parent: "Tennessee", value: "Knoxville" },
    { name: "Providence", parent: "Rhode Island", value: "Providence" },
    { name: "Santa Clarita", parent: "California", value: "Santa Clarita" },
    { name: "Grand Prairie", parent: "Texas", value: "Grand Prairie" },
    { name: "Brownsville", parent: "Texas", value: "Brownsville" },
    { name: "Jackson", parent: "Misisipi", value: "Jackson" },
    { name: "Overland Park ", parent: "Kansas", value: "Overland Park " },
    { name: "Garden Grove", parent: "California", value: "Garden Grove" },
    { name: "Santa Rosa", parent: "California", value: "Santa Rosa" },
    { name: "Chattanooga", parent: "Tennessee", value: "Chattanooga" },
    { name: "Oceanside", parent: "California", value: "Oceanside" },
    { name: "Fort Lauderdale", parent: "Florida", value: "Fort Lauderdale" },
    {
      name: "Rancho Cucamonga",
      parent: "California",
      value: "Rancho Cucamonga",
    },
    { name: "Saint Lucie", parent: "Florida", value: "Saint Lucie" },
    { name: "Ontario", parent: "California", value: "Ontario" },
    { name: "Vancouver", parent: "Washington", value: "Vancouver" },
    { name: "Tempe", parent: "Arizona", value: "Tempe" },
    { name: "Springfield", parent: "Misuri", value: "Springfield" },
    { name: "Lancaster", parent: "California", value: "Lancaster" },
    { name: "Eugene", parent: "Oregón", value: "Eugene" },
    { name: "Pembroke Pines", parent: "Florida", value: "Pembroke Pines" },
    { name: "Salem", parent: "Oregón", value: "Salem" },
    { name: "Cabo Coral", parent: "Florida", value: "Cabo Coral" },
    { name: "Peoria", parent: "Arizona", value: "Peoria" },
    { name: "Sioux Falls", parent: "Dakota del Sur", value: "Sioux Falls" },
    { name: "Springfield", parent: "Massachusetts", value: "Springfield" },
    { name: "Elk Grove", parent: "California", value: "Elk Grove" },
    { name: "Rockford", parent: "Illinois", value: "Rockford" },
    { name: "Palmdale", parent: "California", value: "Palmdale" },
    { name: "Corona", parent: "California", value: "Corona" },
    { name: "Salinas", parent: "California", value: "Salinas" },
    { name: "Pomona", parent: "California", value: "Pomona" },
    { name: "Pasadena", parent: "Texas", value: "Pasadena" },
    { name: "Joliet", parent: "Illinois", value: "Joliet" },
    { name: "Paterson", parent: "Nueva Jersey", value: "Paterson" },
    { name: "Kansas City", parent: "Kansas", value: "Kansas City" },
    { name: "Torrance", parent: "California", value: "Torrance" },
    { name: "Siracusa", parent: "Nueva York", value: "Siracusa" },
    { name: "Bridgeport", parent: "Connecticut", value: "Bridgeport" },
    { name: "Hayward", parent: "California", value: "Hayward" },
    { name: "Fort Collins", parent: "Colorado", value: "Fort Collins" },
    { name: "Escondido", parent: "California", value: "Escondido" },
    { name: "Lakewood", parent: "Colorado", value: "Lakewood" },
    { name: "Naperville", parent: "Illinois", value: "Naperville" },
    { name: "Dayton", parent: "Ohio", value: "Dayton" },
    { name: "Hollywoo", parent: "Florida", value: "Hollywoo" },
    { name: "Sunnyvale", parent: "California", value: "Sunnyvale" },
    { name: "Alexandría", parent: "Virginia", value: "Alexandría" },
    { name: "Mesquite", parent: "Texas", value: "Mesquite" },
    { name: "Metairie", parent: "Luisiana", value: "Metairie" },
    { name: "Hampton", parent: "Virginia", value: "Hampton" },
    { name: "Pasadena", parent: "California", value: "Pasadena" },
    { name: "Orange", parent: "California", value: "Orange" },
    { name: "Savannah", parent: "Georgia", value: "Savannah" },
    { name: "Cary", parent: "Carolina del Norte", value: "Cary" },
    { name: "Fullerton", parent: "California", value: "Fullerton" },
    { name: "Warren", parent: "Míchigan", value: "Warren" },
    { name: "Clarksville", parent: "Tennessee", value: "Clarksville" },
    { name: "McKinney", parent: "Texas", value: "McKinney" },
    { name: "McAllen", parent: "Texas", value: "McAllen" },
    { name: "New Haven", parent: "Connecticut", value: "New Haven" },
    { name: "Sterling Heights", parent: "Míchigan", value: "Sterling Heights" },
    { name: "West Valley City", parent: "Utah", value: "West Valley City" },
    { name: "Columbia", parent: "Carolina del Sur", value: "Columbia" },
    { name: "Killeen", parent: "Texas", value: "Killeen" },
    { name: "Topeka", parent: "Kansas", value: "Topeka" },
    { name: "Thousand Oaks", parent: "California", value: "Thousand Oaks" },
    { name: "Cedar Rapids", parent: "Iowa", value: "Cedar Rapids" },
    { name: "Olathe", parent: "Kansas", value: "Olathe" },
    { name: "Elizabeth", parent: "Nueva Jersey", value: "Elizabeth" },
    { name: "Waco", parent: "Texas", value: "Waco" },
    { name: "Hartfor", parent: "Connecticut", value: "Hartfor" },
    { name: "Visalia", parent: "California", value: "Visalia" },
    { name: "Gainesville", parent: "Florida", value: "Gainesville" },
    { name: "Simi Valley", parent: "California", value: "Simi Valley" },
    { name: "Stamford", parent: "Connecticut", value: "Stamford" },
    { name: "Bellevu", parent: "Washington", value: "Bellevu" },
    { name: "Concord", parent: "California", value: "Concord" },
    { name: "Miramar", parent: "Florida", value: "Miramar" },
    { name: "Coral Springs", parent: "Florida", value: "Coral Springs" },
    { name: "Lafayett", parent: "Luisiana", value: "Lafayett" },
    { name: "Charleston", parent: "Carolina del Sur", value: "Charleston" },
    { name: "Carrollton", parent: "Texas", value: "Carrollton" },
    { name: "Roseville", parent: "California", value: "Roseville" },
    { name: "Thornton", parent: "Colorado", value: "Thornton" },
    { name: "Beaumont", parent: "Texas", value: "Beaumont" },
    { name: "Allentown", parent: "Pensilvania", value: "Allentown" },
    { name: "Surprise", parent: "Arizona", value: "Surprise" },
    { name: "Evansville", parent: "Indiana", value: "Evansville" },
    { name: "Abilene", parent: "Texas", value: "Abilene" },
    { name: "Frisco", parent: "Texas", value: "Frisco" },
    { name: "Independence", parent: "Misuri", value: "Independence" },
    { name: "Santa Clara", parent: "California", value: "Santa Clara" },
    { name: "Springfield", parent: "Illinois", value: "Springfield" },
    { name: "Vallejo", parent: "California", value: "Vallejo" },
    { name: "Victorville", parent: "California", value: "Victorville" },
    { name: "Athens", parent: "Georgia", value: "Athens" },
    { name: "Peoria", parent: "Illinois", value: "Peoria" },
    { name: "Ann Arbor", parent: "Míchigan", value: "Ann Arbor" },
    { name: "El Monte", parent: "California", value: "El Monte" },
    { name: "Denton", parent: "Texas", value: "Denton" },
    { name: "Berkeley", parent: "California", value: "Berkeley" },
    { name: "Provo", parent: "Utah", value: "Provo" },
    { name: "Downey", parent: "California", value: "Downey" },
    { name: "Midland", parent: "Texas", value: "Midland" },
    { name: "Norman", parent: "Oklahoma", value: "Norman" },
    { name: "Waterbury", parent: "Connecticut", value: "Waterbury" },
    { name: "Costa Mesa", parent: "California", value: "Costa Mesa" },
    { name: "Inglewood", parent: "California", value: "Inglewood" },
    { name: "Mánchester", parent: "Nuevo Hampshire", value: "Mánchester" },
    { name: "Lansing", parent: "Míchigan", value: "Lansing" },
    { name: "Murfreesboro", parent: "Tennessee", value: "Murfreesboro" },
    { name: "Columbia", parent: "Misuri", value: "Columbia" },
    { name: "Elgin", parent: "Illinois", value: "Elgin" },
    { name: "Clearwater", parent: "Florida", value: "Clearwater" },
    { name: "Miami Gardens", parent: "Florida", value: "Miami Gardens" },
    { name: "Rochester", parent: "Minnesota", value: "Rochester" },
    { name: "Pueblo", parent: "Colorado", value: "Pueblo" },
    { name: "Lowell", parent: "Massachusetts", value: "Lowell" },
    { name: "Wilmington", parent: "Carolina del Norte", value: "Wilmington" },
    { name: "Arvada", parent: "Colorado", value: "Arvada" },
    { name: "Ventura", parent: "California", value: "Ventura" },
    { name: "Westminster", parent: "Colorado", value: "Westminster" },
    { name: "West Covina", parent: "California", value: "West Covina" },
    { name: "Gresham", parent: "Oregón", value: "Gresham" },
    { name: "Fargo", parent: "Dakota del Norte", value: "Fargo" },
    { name: "Norwalk", parent: "California", value: "Norwalk" },
    { name: "Carlsbad", parent: "California", value: "Carlsbad" },
    { name: "Fairfield", parent: "California", value: "Fairfield" },
    { name: "Cambridge", parent: "Massachusetts", value: "Cambridge" },
    { name: "Wichita Falls", parent: "Texas", value: "Wichita Falls" },
    { name: "High Point", parent: "Carolina del Norte", value: "High Point" },
    { name: "Billings", parent: "Montana", value: "Billings" },
    { name: "Green Bay", parent: "Wisconsin", value: "Green Bay" },
    { name: "West Jordan", parent: "Utah", value: "West Jordan" },
    { name: "Richmond", parent: "California", value: "Richmond" },
    { name: "Murrieta", parent: "California", value: "Murrieta" },
    { name: "Burbank", parent: "California", value: "Burbank" },
    { name: "Palm Bay", parent: "Florida", value: "Palm Bay" },
    { name: "Everett", parent: "Washington", value: "Everett" },
    { name: "Flint", parent: "Míchigan", value: "Flint" },
    { name: "Antioch", parent: "California", value: "Antioch" },
    { name: "Erie", parent: "Pensilvania", value: "Erie" },
    { name: "South Bend", parent: "Indiana", value: "South Bend" },
    { name: "Daly City", parent: "California", value: "Daly City" },
    { name: "Centennial", parent: "Colorado", value: "Centennial" },
    { name: "Temecula", parent: "California", value: "Temecula" },
    { name: "San Juan", parent: "Puerto Rico", value: "San Juan" },
    { name: "Bayamón", parent: "Puerto Rico", value: "Bayamón" },
    { name: "Carolina", parent: "Puerto Rico", value: "Carolina" },
    { name: "Ponce", parent: "Puerto Rico", value: "Ponce" },
    { name: "Caguas", parent: "Puerto Rico", value: "Caguas" },
    { name: "Guaynabo", parent: "Puerto Rico", value: "Guaynabo" },
    { name: "Arecibo", parent: "Puerto Rico", value: "Arecibo" },
  ],
  [CatalogsEnum.Banks]: [
    {
      name: "Banco Azteca",
      value: "11000",
    },
    {
      name: "Bancomer",
      value: "11001",
    },
    {
      name: "Capital One",
      value: "11002",
    },
    {
      name: "Bancolombia En Nueva York",
      value: "11003",
    },
    {
      name: "Agricola",
      value: "11004",
    },
    {
      name: "Banorte",
      value: "11005",
    },
    {
      name: "Ocean Bank Of Miami",
      value: "11006",
    },
    {
      name: "Hsbc",
      value: "11007",
    },
    {
      name: "Scotiabank",
      value: "11008",
    },
    {
      name: "Banco Del Pichinca Del Ecuador",
      value: "11009",
    },
    {
      name: "Citibank",
      value: "11010",
    },
    {
      name: "Banco Del Austro",
      value: "11011",
    },
    {
      name: "Scotia Bank",
      value: "11012",
    },
    {
      name: "Wells Fargo Bank",
      value: "11013",
    },
    {
      name: "Banco Cuscatlan",
      value: "11014",
    },
    {
      name: "Ficohsa",
      value: "11015",
    },
    {
      name: "Banco De Occidente",
      value: "11016",
    },
    {
      name: "Bb&t",
      value: "11017",
    },
    {
      name: "Banco Ficohsa",
      value: "11018",
    },
    {
      name: "Chase",
      value: "11019",
    },
    {
      name: "Bancolombia",
      value: "11020",
    },
    {
      name: "Banco Guayaquil",
      value: "11021",
    },
    {
      name: "Fidelity Bank Plc",
      value: "11022",
    },
    {
      name: "Wachovia Bank",
      value: "11023",
    },
    {
      name: "Santander",
      value: "11024",
    },
    {
      name: "First Bank",
      value: "11025",
    },
    {
      name: "Boi Bank Corp",
      value: "11026",
    },
    {
      name: "Chase Bank",
      value: "11027",
    },
    {
      name: "Ibc Commerce Bank",
      value: "11028",
    },
    {
      name: "State Trust Life And Annuities",
      value: "11029",
    },
    {
      name: "Bbva",
      value: "11030",
    },
    {
      name: "American Premier Trust",
      value: "11031",
    },
    {
      name: "Td Bank",
      value: "11032",
    },
    {
      name: "Banco Pichincha",
      value: "11033",
    },
    {
      name: "Banamex",
      value: "11034",
    },
    {
      name: "Citibank (banamex Usa)",
      value: "11035",
    },
    {
      name: "Banco De Venezuela",
      value: "11036",
    },
    {
      name: "Banco Pacifico Ecuador",
      value: "11037",
    },
    {
      name: "Pnc Bank N.a. Citizens Plaza",
      value: "11038",
    },
    {
      name: "Banco De America Central",
      value: "11039",
    },
    {
      name: "Banco Popular De Puerto Rico",
      value: "11040",
    },
    {
      name: "Money Gram",
      value: "11041",
    },
    {
      name: "Scotiabank Inverlat",
      value: "11042",
    },
    {
      name: "Banco Continental",
      value: "11043",
    },
    {
      name: "Credit Union",
      value: "11044",
    },
    {
      name: "Royal Bank De Plc Londres De Escocia",
      value: "11045",
    },
    {
      name: "Credit One",
      value: "11046",
    },
    {
      name: "Chase Manhattan Bank",
      value: "11047",
    },
    {
      name: "Apex Credit Bank",
      value: "11048",
    },
    {
      name: "Banco Santander Hispano",
      value: "11049",
    },
    {
      name: "Shevi",
      value: "11050",
    },
    {
      name: "Banco Del Austro Miami",
      value: "11051",
    },
    {
      name: "Banco Popular",
      value: "11052",
    },
    {
      name: "Compass Bank",
      value: "11053",
    },
    {
      name: "Statetrust Bank & Trust Ltd.",
      value: "11054",
    },
    {
      name: "Union Bank",
      value: "11055",
    },
    {
      name: "Discover",
      value: "11056",
    },
    {
      name: "Banco Del Pacifico",
      value: "11057",
    },
    {
      name: "Banco De Credito",
      value: "11058",
    },
    {
      name: "Ocean Bank",
      value: "11059",
    },
    {
      name: "Av Villas",
      value: "11060",
    },
    {
      name: "Us Bank",
      value: "11061",
    },
    {
      name: "Banco Pnc",
      value: "11062",
    },
    {
      name: "Internacional Bank",
      value: "11063",
    },
    {
      name: "State Trust Bank",
      value: "11064",
    },
    {
      name: "First National Bank",
      value: "11065",
    },
    {
      name: "Gmac",
      value: "11066",
    },
    {
      name: "Banco Ficosa",
      value: "11067",
    },
    {
      name: "Chebi Chafe Bank",
      value: "11068",
    },
    {
      name: "Bank Of America",
      value: "11069",
    },
    {
      name: "Bic",
      value: "11070",
    },
    {
      name: "Banca Intesa Spa New York",
      value: "11071",
    },
    {
      name: "Citybank",
      value: "11072",
    },
    {
      name: "Metabank",
      value: "11073",
    },
    {
      name: "Idb Bank",
      value: "11074",
    },
    {
      name: "Banco Region",
      value: "11075",
    },
    {
      name: "Hell Bank",
      value: "11076",
    },
    {
      name: "Bolivariano",
      value: "11077",
    },
    {
      name: "Banco Provincial",
      value: "11078",
    },
    {
      name: "Banco De Bogota",
      value: "11079",
    },
    {
      name: "Wachovia",
      value: "11080",
    },
    {
      name: "Wells Fargo",
      value: "11081",
    },
    {
      name: "Banco De Desarrollo Rural",
      value: "11082",
    },
    {
      name: "First National Bank De South Africa",
      value: "11083",
    },
    {
      name: "Atlantic Bank",
      value: "11084",
    },
    {
      name: "Rabobank",
      value: "11085",
    },
    {
      name: "Lome-togo",
      value: "11086",
    },
    {
      name: "Jp Morgan",
      value: "11087",
    },
    {
      name: "Bank Alliance Financier",
      value: "11088",
    },
    {
      name: "Societe Generale Bank & Trust",
      value: "11089",
    },
    {
      name: "Banco Santander",
      value: "11090",
    },
    {
      name: "Barclays",
      value: "11091",
    },
    {
      name: "Lnb",
      value: "11092",
    },
    {
      name: "Alpine Bank",
      value: "11093",
    },
    {
      name: "Chase Bank Plc",
      value: "11094",
    },
    {
      name: "Royal Bank Of Scotland Plc",
      value: "11095",
    },
    {
      name: "Bbst",
      value: "11096",
    },
    {
      name: "Stanford Bank",
      value: "11097",
    },
    {
      name: "Banco Agricola",
      value: "11098",
    },
    {
      name: "Mbna",
      value: "11099",
    },
    {
      name: "Deutsch Bank",
      value: "11100",
    },
    {
      name: "Banesto",
      value: "11101",
    },
    {
      name: "Citigroup Inc C.n",
      value: "11102",
    },
    {
      name: "Suntrack",
      value: "11103",
    },
    {
      name: "Great Western Bank",
      value: "11104",
    },
    {
      name: "Chafe",
      value: "11105",
    },
    {
      name: "Zions Bank",
      value: "11106",
    },
    {
      name: "Capital One En Miami",
      value: "11107",
    },
    {
      name: "Barclays Banc",
      value: "11108",
    },
    {
      name: "Mercantil",
      value: "11109",
    },
    {
      name: "Schlumberger Credit Union",
      value: "11110",
    },
    {
      name: "Zenith Bank",
      value: "11111",
    },
    {
      name: "Merryl Lynch",
      value: "11112",
    },
    {
      name: "Royal Bank Of Scotland",
      value: "11113",
    },
    {
      name: "Standard Chartered Bsnk",
      value: "11114",
    },
    {
      name: "Bank Of China",
      value: "11115",
    },
    {
      name: "Ibc",
      value: "11116",
    },
    {
      name: "Banco Salvadoreño",
      value: "11117",
    },
    {
      name: "Standard Chartered Bank Plc Del Reino Unido",
      value: "11118",
    },
    {
      name: "Columbia River Bank",
      value: "11119",
    },
    {
      name: "Security Service Federal Credit Union",
      value: "11120",
    },
    {
      name: "Scotian Bank",
      value: "11121",
    },
    {
      name: "Ge Money Bank",
      value: "11122",
    },
    {
      name: "Banco Bcp",
      value: "11123",
    },
    {
      name: "East West Bank",
      value: "11124",
    },
    {
      name: "Coamerica",
      value: "11125",
    },
    {
      name: "M&t",
      value: "11126",
    },
    {
      name: "Bbva En Estados Unidos",
      value: "11127",
    },
    {
      name: "Ocean Bak",
      value: "11128",
    },
    {
      name: "State Bank Of Fargo",
      value: "11129",
    },
    {
      name: "Banco Avevillas",
      value: "11130",
    },
    {
      name: "F.b.t Bank Lome-togo",
      value: "11131",
    },
    {
      name: "First Financial Bank Plc",
      value: "11132",
    },
    {
      name: "Lantian Li",
      value: "11133",
    },
    {
      name: "United Trust Bank",
      value: "11134",
    },
    {
      name: "Stanley Smith Barney",
      value: "11135",
    },
    {
      name: "Bank Wachovya",
      value: "11136",
    },
    {
      name: "Broadway Bank",
      value: "11137",
    },
    {
      name: "Banque Atlantique",
      value: "11138",
    },
    {
      name: "Sopra North Bank",
      value: "11139",
    },
    {
      name: "Bank Ok America",
      value: "11140",
    },
    {
      name: "Brou",
      value: "11141",
    },
    {
      name: "Saint Germain",
      value: "11142",
    },
    {
      name: "Charter One",
      value: "11143",
    },
    {
      name: "Moneda Usa Inc",
      value: "11144",
    },
    {
      name: "Bankia",
      value: "11145",
    },
    {
      name: "Strasbourg Sovereign Trust",
      value: "11146",
    },
    {
      name: "Gordwady Finance Cuit",
      value: "11147",
    },
    {
      name: "Austro",
      value: "11148",
    },
    {
      name: "Bank Banco De Venezuela Usa",
      value: "11149",
    },
    {
      name: "Bank Pic Del Reino Unido",
      value: "11150",
    },
    {
      name: "Pnc",
      value: "11151",
    },
    {
      name: "Sundtrack",
      value: "11152",
    },
    {
      name: "Solvay Bank",
      value: "11153",
    },
    {
      name: "Banamex Usa",
      value: "11154",
    },
    {
      name: "Federal",
      value: "11155",
    },
    {
      name: "Banco Colmena",
      value: "11156",
    },
    {
      name: "Fifth Third Bank",
      value: "11157",
    },
    {
      name: "Banco Chase",
      value: "11158",
    },
    {
      name: "Consolidated Bank Plc",
      value: "11159",
    },
    {
      name: "Fifth District Bank",
      value: "11160",
    },
    {
      name: "Commerce Bank",
      value: "11161",
    },
    {
      name: "Banco Helm",
      value: "11162",
    },
    {
      name: "Commerzbank",
      value: "11163",
    },
    {
      name: "Us Bank National Association",
      value: "11164",
    },
    {
      name: "Banco Mercanti",
      value: "11165",
    },
    {
      name: "Trasatlantic Bank",
      value: "11166",
    },
    {
      name: "Stelling Bank",
      value: "11167",
    },
    {
      name: "Banco Regions",
      value: "11168",
    },
    {
      name: "Hbc",
      value: "11169",
    },
    {
      name: "M&p",
      value: "11170",
    },
    {
      name: "Standard Bank",
      value: "11171",
    },
    {
      name: "International Bank Of Commerce",
      value: "11172",
    },
    {
      name: "Bananex",
      value: "11173",
    },
    {
      name: "Mercantil Bank & Trust Limited",
      value: "11174",
    },
    {
      name: "United Comercial Bank",
      value: "11175",
    },
    {
      name: "Wachobia",
      value: "11176",
    },
    {
      name: "Bcp",
      value: "11177",
    },
    {
      name: "City Banck",
      value: "11178",
    },
    {
      name: "American Express",
      value: "11179",
    },
    {
      name: "Banco De Credito Continental",
      value: "11180",
    },
    {
      name: "Bancoagricola",
      value: "11181",
    },
    {
      name: "Metro Bank",
      value: "11182",
    },
    {
      name: "Scotia",
      value: "11183",
    },
    {
      name: "Cash Bank",
      value: "11184",
    },
    {
      name: "Ficosa",
      value: "11185",
    },
    {
      name: "Chase American Bank Association",
      value: "11186",
    },
    {
      name: "Banco Central De Florida",
      value: "11187",
    },
    {
      name: "Premier Bank International",
      value: "11188",
    },
    {
      name: "Conciban",
      value: "11189",
    },
    {
      name: "Banco De Cibao",
      value: "11190",
    },
    {
      name: "Grand Pacific Corp",
      value: "11191",
    },
    {
      name: "Mercantio Commercebank",
      value: "11192",
    },
    {
      name: "Quantum",
      value: "11193",
    },
    {
      name: "Consultant Bankers",
      value: "11194",
    },
    {
      name: "Usbank",
      value: "11195",
    },
    {
      name: "Banco Del Gobierno",
      value: "11196",
    },
    {
      name: "Royal Bank Plc. Londres Escocia.",
      value: "11197",
    },
    {
      name: "Globe Bank",
      value: "11198",
    },
    {
      name: "Oceanic Bank Plc",
      value: "11199",
    },
    {
      name: "Rbs",
      value: "11200",
    },
    {
      name: "Chfe",
      value: "11201",
    },
    {
      name: "Salt Lake City",
      value: "11202",
    },
    {
      name: "Bank Of Kansas",
      value: "11203",
    },
    {
      name: "Strasbourg",
      value: "11204",
    },
    {
      name: "Wachovia Bank En Estados Unidos",
      value: "11205",
    },
    {
      name: "Coconaut Globe Bank",
      value: "11206",
    },
    {
      name: "Royal Trust Bank",
      value: "11207",
    },
    {
      name: "Banco Comercial Internacional",
      value: "11208",
    },
    {
      name: "Bank Darlet",
      value: "11209",
    },
    {
      name: "Fym",
      value: "11210",
    },
    {
      name: "Bancoficosa",
      value: "11211",
    },
    {
      name: "Royalbank",
      value: "11212",
    },
    {
      name: "Consolidated Bank",
      value: "11213",
    },
    {
      name: "First National Bank Plc London",
      value: "11214",
    },
    {
      name: "Banco Nova",
      value: "11215",
    },
    {
      name: "Sorben Bank",
      value: "11216",
    },
    {
      name: "Strasbourg Sobereign Trust",
      value: "11217",
    },
    {
      name: "Banco Real De Escocia Plc",
      value: "11218",
    },
    {
      name: "Compassbank",
      value: "11219",
    },
    {
      name: "Banrural",
      value: "11220",
    },
    {
      name: "Bbva Bancomer",
      value: "11221",
    },
    {
      name: "Western Union",
      value: "11222",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Cheques", value: "0" },
    { name: "Ahorros", value: "1" },
    { name: "Inversión", value: "2" },
  ],
  [CatalogsEnum.Currency]: [{ name: "Dólares", value: CurrencyEnum.USD }],
  [CatalogsEnum.PersonTypes]: [
    { name: "Natural", value: "01" },
    { name: "Jurídica", value: "02" },
  ],
};
