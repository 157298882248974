export enum ColumnSelectEnum {
  START_DATE = "Fecha de inicio",
  DATE_MAX = "Fecha max. de resp",
  BRANCH = "Branch/Merchant",
  CHARGEBACK_NUMBER = "No. de contracargo",
  TICKET_NUMBER = "No. de ticket",
  CARD_NUMBER = "No. de tarjeta",
  STATUS = "Estado",
  AMOUNT = "Monto",
  CARD_BRAND = "Marca de tarjeta",
  PAYMENT_SUB_METHOD_TYPE = "Medio",
  ISSUING_BANK = "Banco emisor",
  SUBSCRIPTION_ID = "ID de suscripción",
  NAME_ON_CARD = "Nombre de tarjeta",
  CREDENTIAL_ID = "ID de credencial",
  CREDENTIAL_ALIASES = "Alias de credencial",
  PUBLIC_KEY = "Llave pública",
  OWNER = "Owner",
  CUSTOMER = "Customer",
}

export enum OcbEnum {
  OCB_2_0 = "Kushki 2.0",
}
