export enum CardInformationEnum {
  AMOUNT_REQUESTED = "Monto solicitado",
  MERCHANT_NAME = "Nombre del comercio",
  MERCHANT_ID = "ID del comercio",
  APPROVAL = "Aprobada",
  APPROVAL_AMOUNT = "Monto Aprobado",
  APPROVAL_NO = "No. de Aprobación",
  AUTHORIZATION_DATA = "Datos de la autorización",
  AUTHENTICATION_DATA = "Datos de Autenticación",
  AWAIT_BANK_CONFIRMATION = "A la espera de la confirmación del banco",
  BANK = "Banco",
  BANK_LEGEND = "El banco se encuentra trabajando en el reembolso. Esto puede tardar un máximo de 45 días",
  BILLING_DATA = "Datos de facturación",
  CARD_DATA = "Datos de la tarjeta",
  CREDENTIAL = "Credencial",
  CREDENTIAL_ID = "ID de credencial",
  CLIENT = "Cliente",
  CLIENT_ID = "ID del cliente",
  DECLINED = "Declinada",
  DEFAULT_VALUE = "0.00",
  DESCRIPTION = "Descripción",
  PROCESSOR_PAYMENT_POINT = "processorPaymentPoint",
  DOC_NUMBER = "Número de Documento",
  FULL_REFUND_PROCESSED = "Reembolso total procesado",
  FULL_REFUND_REQUESTED = "Reembolso Total Solicitado",
  IVA_AMOUNT = "Monto del IVA",
  ICE_AMOUNT = "Monto del ICE",
  INITIALIZED = "Inicializada",
  PARTIAL_REFUND_PROCESSED = "Reembolso parcial procesado",
  PARTIAL_REFUND_REQUESTED = "Reembolso Parcial Solicitado",
  PAYMENT_CREDENTIAL_DATA = "Datos de credencial de pago",
  MERCHANT_DATA = "Datos del comercio",
  PAYMENT_METHOD = "Método de pago",
  PROCESSOR = "Procesador",
  PUBLIC_KEY = "Llave pública",
  REQUEST_IN_PROGRESS = "Solicitud en proceso",
  REFUND_DECLINED = "Devolución declinada",
  REQUEST_RECEIVED = "Solicitud recibida",
  REFUND_MADE = "Devolución realizada",
  REFUND_REQUESTED = "Devolución Solicitada",
  RETURNED_AMOUNT = "Monto devuelto",
  RESPONSE_CODE = "Código respuesta",
  RESPONSE_MESSAGE = "Mensaje respuesta",
  SEE = "Ver",
  SUBTOTAL = "Subtotal",
  SUBTOTAL_IVA = "Subtotal IVA",
  CARD_TYPE = "Tipo de tarjeta",
  PAY_TYPE = "Tipo de pago",
  ISSUING_BANK = "Banco emisor",
  SUBTOTAL_IVA_0 = "Subtotal IVA 0",
  TAXES = "Impuestos",
  TRANSACTION_DATA = "Datos de la Transacción",
  TOTAL = "Total",
  KUSHKI_ID = "Kushki ID",
  TOTAL_REFUND = "Total devuelto",
  TOTAL_TRANSACTION = "Total de la transacción",
  TYPE = "Tipo",
}

export enum CardSectionValueEnum {
  MERCHANT_DATA = "MERCHANT_DATA",
  TRANSACTION_DATA = "TRANSACTION_DATA",
  PAYMENT_CREDENTIAL_DATA = "PAYMENT_CREDENTIAL_DATA",
  BILLING_DATA = "BILLING_DATA",
  CARD_DATA = "CARD_DATA",
  AUTHORIZATION_DATA = "AUTHORIZATION_DATA",
  AUTHENTICATION_DATA = "AUTHENTICATION_DATA",
}

export enum MerchantDataFieldValueEnum {
  MERCHANT_NAME = "MERCHANT_NAME",
  MERCHANT_ID = "MERCHANT_ID",
}
export enum TransactionDataDataFieldValueEnum {
  CLIENT_DOC_NUMBER = "CLIENT_DOC_NUMBER",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  TYPE = "TYPE",
  APPROVAL_NO = "APPROVAL_NO",
  SUBTOTAL = "SUBTOTAL",
  TAXES = "TAXES",
  TOTAL = "TOTAL",
  KUSHKI_ID = "KUSHKI_ID",
  TOTAL_TRANSACTION = "TOTAL_TRANSACTION",
  TOTAL_REFUND = "TOTAL_REFUND",
}

export enum PaymentCredentialDataFieldValueEnum {
  CREDENTIAL = "CREDENTIAL",
  CREDENTIAL_ID = "CREDENTIAL_ID",
  PUBLIC_KEY = "PUBLIC_KEY",
}

export enum BillingDataFieldValueEnum {
  SUBTOTAL_IVA = "SUBTOTAL_IVA",
  SUBTOTAL_IVA_0 = "SUBTOTAL_IVA_0",
  IVA_AMOUNT = "IVA_AMOUNT",
  ICE_AMOUNT = "ICE_AMOUNT",
  APPROVAL_AMOUNT = "APPROVAL_AMOUNT",
}

export enum CardDataFieldValueEnum {
  CARD_TYPE = "CARD_TYPE",
  PAY_TYPE = "PAY_TYPE",
  ISSUING_BANK = "ISSUING_BANK",
}

export enum AuthorizationDataFieldValueEnum {
  PROCESSOR = "PROCESSOR",
  RESPONSE_CODE = "RESPONSE_CODE",
  RESPONSE_MESSAGE = "RESPONSE_MESSAGE",
  DESCRIPTION = "DESCRIPTION",
  PROCESSOR_PAYMENT_POINT = "PROCESSOR_PAYMENT_POINT",
}
export enum AuthenticationDataFieldValueEnum {
  PROCESSOR = "PROCESSOR_AUTHEN",
  RESPONSE_CODE = "RESPONSE_CODE_AUTHEN",
  RESPONSE_MESSAGE = "RESPONSE_MESSAGE_AUTHEN",
  DESCRIPTION = "DESCRIPTION_AUTHEN",
}

export enum CardAlignEnum {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center",
  JUSTIFY = "justify",
  INHERIT = "inherit",
}

export enum CardTypeValueEnum {
  CREDIT = "Credit",
  DEBIT = "Debit",
}

export enum CardTypeLabelEnum {
  CREDIT = "Crédito",
  DEBIT = "Débito",
  DEFAULT = "N/A",
}
export enum PayTypeLabelEnum {
  CURRENT = "Corriente",
  DEFERRED = "Diferido",
}
