import { InputAdornment, TextField, Typography } from "@mui/material";
import { LabelEnum } from "../../shared/constants/LabelEnum";
import { IconCheck, ModalContent } from "@kushki/connect-ui";
import {
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui/dist/Components/Molecules/Modal/Content/enums";
import { getCredentialsList } from "../../store/actionCreators";
import { Controller, useForm } from "react-hook-form";
import { SearchCredential } from "../../../types/search_credential";
import { Grid } from "@mui/material";
import { useAddCredentialModalState } from "./state/useAddCredentialModalState";
import { useDispatch } from "react-redux";
import React from "react";
import { IAddCredentialModalProps } from "../../shared/infrastructure/interfaces/IAddCredentialModalProps";

export const AddCredentialModal: React.FC<IAddCredentialModalProps> = (
  props: IAddCredentialModalProps
) => {
  const { control, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const {
    isOpenCredentialModal,
    handleInsertAlias,
    isDuplicate,
    setValueAlias,
    merchantId,
    valueAlias,
    handleCloseModal,
    isButtonDisabled,
  } = useAddCredentialModalState(props);
  return (
    <React.Fragment>
      <ModalContent
        buttonSecondaryText={props.leftButtonText}
        buttonText={props.rightButtonText}
        descriptionText={LabelEnum.EMPTY_STR}
        isOpen={isOpenCredentialModal}
        onClose={handleCloseModal}
        titleText={props.titleText}
        buttonAction={handleSubmit(handleInsertAlias)}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        buttonPrimaryDisabled={isDuplicate || isButtonDisabled}
        type={ModalContentTypeEnum.DEFAULT}
      >
        <Grid pb={2} paddingTop={3}>
          <form>
            <Controller
              name="cAlias"
              control={control}
              render={() => (
                <TextField
                  placeholder={LabelEnum.ALIAS}
                  margin="normal"
                  fullWidth
                  className="solidTextfield"
                  name="cAlias"
                  variant="outlined"
                  error={isDuplicate}
                  label={"Nombre"}
                  defaultValue={props.editCredentialRequest?.alias}
                  onChange={(evt) => {
                    setValue("cAlias", evt.target.value);
                    setValueAlias(evt.target.value);
                    const limitSearch =
                      localStorage.getItem("totalCredentials");
                    // TODO: this search should be executed only when the modal is opened, not every time the input itself changes
                    if (limitSearch) {
                      const searchRequest: SearchCredential = {
                        offset: 0,
                        limit: parseInt(limitSearch),
                        merchantId: merchantId,
                      };
                      dispatch(getCredentialsList(searchRequest, true));
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {!isDuplicate && (
                          <IconCheck color="primary" fontSize="medium" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
          {valueAlias !== LabelEnum.EMPTY_STR && (
            <Typography variant={"body2"} color="text.primary" mt={2} ml={2}>
              {isDuplicate ? LabelEnum.ALIAS_DUPLICATE : LabelEnum.ALIAS_OK}
            </Typography>
          )}
        </Grid>
      </ModalContent>
    </React.Fragment>
  );
};
