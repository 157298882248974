import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";
import { DateRange } from "@material-ui/pickers";
import { Skeleton } from "@material-ui/lab";

export interface SmartlinkDatePickerProps {
  handleDate?: (dateRange: DateRange) => void;
  disableFuture?: boolean;
  value?: DateRange;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    [theme.breakpoints.down(768)]: {
      marginLeft: "inherit",
      marginRight: "inherit",

      border: "none",
    },
  },
  gridContainer: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #CBD5E0",
    borderRadius: 4,
    paddingBottom: "3px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      border: "none",
      borderRadius: 0,
    },
  },
}));

export const SmartlinkDatePicker: React.FC<SmartlinkDatePickerProps> = (
  props: SmartlinkDatePickerProps
) => {
  const classes = useStyles();

  return (
    <div className={classes.datePicker}>
      <Grid container>
        <Grid className={classes.gridContainer} item xs={12}>
          {props.isLoading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={35}
            />
          ) : (
            <KshDateRangePicker
              handleDate={props.handleDate!}
              disableFuture={props.disableFuture!}
              value={props.value!}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
