export const TAX_REGIME = [
  { key: "601", value: "General de Ley Personas Morales" },
  { key: "603", value: "Personas Morales con Fines no Lucrativos" },
  {
    key: "605",
    value: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  { key: "606", value: "Arrendamiento" },
  { key: "608", value: "Demás ingresos" },
  { key: "609", value: "Consolidación" },
  {
    key: "610",
    value:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    key: "611",
    value: "Ingresos por Dividendos (socios y accionistas)",
  },
  {
    key: "612",
    value: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  { key: "614", value: "Ingresos por intereses" },
  { key: "616", value: "Sin obligaciones fiscales" },
  {
    key: "620",
    value:
      "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  { key: "621", value: "Incorporación Fiscal" },
  {
    key: "622",
    value: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  { key: "623", value: "Opcional para Grupos de Sociedades" },
  { key: "624", value: "Coordinados" },
  { key: "628", value: "Hidrocarburos" },
  {
    key: "607",
    value: "Régimen de Enajenación o Adquisición de Bienes",
  },
  {
    key: "629",
    value:
      "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  {
    key: "630",
    value: "Enajenación de acciones en bolsa de valores",
  },
  {
    key: "615",
    value: "Rogimen de los ingresos por obtencion de premios",
  },
  {
    key: "625",
    value:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  { key: "626", value: "Régimen Simplificado de Confianza" },
];
