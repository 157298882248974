import React from "react";
import { AddCredentialModal } from "../../components/AddCredentials/AddCredentialModal";
import { DataTableComponent } from "../../components/DataTableComponent/DataTableComponent";
import { useCredentialsState } from "./state/useCredentialsState";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import {
  CredentialHeader,
  ICredentialHeaderProps,
} from "../../components/CredentialHeader/CredentialHeader";
import { GenericBreadcrumb } from "../../components/GenericBreadcrumb/GenericBreadcrumb";
import { isNodeLayout as nodeLayout } from "../../shared/utils/session-storage/getLayoutOriginUtils";
import { CredentialsWrapper } from "../../components/CredentialsWrapper/CredentialsWrapper";
import { EntityNameEnum } from "../../shared/infrastructure/enums/EntityNameEnum";
import { ICredentialsProps } from "../../shared/infrastructure/interfaces/ICredentialsProps";
import { CredentialHeaderCustomer } from "../../components/CredentialHeader/CredentialHeaderCustomer";

export const Credentials = (props: ICredentialsProps) => {
  const {
    basicMerchantInformation,
    credentialModalProps,
    actions,
    customModalProps,
    getCredentialsResponse,
    handleChangeModalProps,
    handleCredentialModalProps,
    itemsPerPage,
    selectedPage,
    merchantId,
    isModalButtonDisabled,
    breadcrumbItems,
    onChangeFilters,
  } = useCredentialsState(props);

  const isNodeLayout: boolean = nodeLayout();
  const value: ICredentialHeaderProps = {
    isNodeLayout,
    merchantId: merchantId,
    entityName: "customer|branch",
    merchantName: basicMerchantInformation.name,
    country: basicMerchantInformation.country,
    changeCredentialModalProps: handleCredentialModalProps,
    isLoadingGetSuggestedCredentials: false,
    onChangeFilters,
  };

  return (
    <CredentialsWrapper isNodeLayout={isNodeLayout}>
      {!isNodeLayout && <GenericBreadcrumb breadcrumbItems={breadcrumbItems} />}
      {props.type !== EntityNameEnum.BRANCH ? (
        <CredentialHeaderCustomer />
      ) : (
        <CredentialHeader {...value} />
      )}
      <DataTableComponent
        type={props.type}
        itemsPerPage={itemsPerPage}
        selectedPage={selectedPage}
        credentialsResponse={getCredentialsResponse}
        actions={actions}
        changeModalProps={handleChangeModalProps}
        changeCredentialModalProps={handleCredentialModalProps}
      />
      <CustomModal
        {...customModalProps}
        isRightButtonDisabled={isModalButtonDisabled}
      />
      <AddCredentialModal {...credentialModalProps} />
    </CredentialsWrapper>
  );
};
