import React from "react";
import { Grid, TextField } from "@mui/material";
import { DEFAULT_BUTTON_TITTLE } from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { GenericAddButton } from "../GenericAddButton/GenericAddButton";
import { GenericLabelConditionSectionList } from "./components/GenericLabelConditionSectionList/GenericLabelConditionSectionList";
import { IGenericLabelConditionSectionProps } from "../../../../shared/infrastructure/interfaces/IGenericLabelConditionSection";

import { useGenericLabelConditionSectionState } from "./state/useGenericLabelConditionSectionState";
import { DEFAULT_ITEM_BY_TAG } from "../../../../shared/infrastructure/enums/ItemByTagEnum";

export const GenericLabelConditionSection = (
  props: IGenericLabelConditionSectionProps
) => {
  const {
    value,
    register,
    handleOnBlur,
    textFieldList,
    setTextFieldList,
    handleAddTextField,
    handleEditTextField,
    isValidFiledTextField,
    handleOnChangeTextField,
  } = useGenericLabelConditionSectionState(props);

  return (
    <React.Fragment>
      {textFieldList.map((fieldValue: string, index: number) => (
        <React.Fragment
          key={`genericLabelConditionSectionList-${fieldValue}-${index}`}
        >
          <GenericLabelConditionSectionList
            handleEdit={(value: string) => handleEditTextField(value, index)}
            handleDelete={() =>
              setTextFieldList(
                textFieldList.filter(
                  (item: string) => item != fieldValue.toString()
                )
              )
            }
            value={fieldValue.toString()}
            tag={props.tag}
          />
        </React.Fragment>
      ))}

      <Grid item xs={4}>
        <TextField
          name={DEFAULT_ITEM_BY_TAG[props.tag].name}
          inputRef={register({
            required: false,
          })}
          onChange={handleOnChangeTextField}
          label={DEFAULT_ITEM_BY_TAG[props.tag].label}
          placeholder={DEFAULT_ITEM_BY_TAG[props.tag].placeholder}
          helperText={
            !isValidFiledTextField &&
            DEFAULT_ITEM_BY_TAG[props.tag].errorMessage
          }
          variant="outlined"
          fullWidth
          onBlur={handleOnBlur}
          value={value}
        />
      </Grid>
      {isValidFiledTextField && (
        <GenericAddButton
          handleOnClick={handleAddTextField}
          buttonTitle={DEFAULT_BUTTON_TITTLE[props.tag]}
        />
      )}
    </React.Fragment>
  );
};
