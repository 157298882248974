import BaseDataContainer from "../commons/BaseDataContainer/BaseDataContainer";
import { useWebhookNotifications } from "./state/useWebhookNotification";

export const webhookNotificationsTexts = {
  BUTTON_TEXT: "Ver todas las notificaciones",
  EMPTY: "Aún no tienes ninguna notificacion de webhooks",
  SUBTITLE:
    "Haz seguimiento de tus URLs de notificaciones de tus webhooks y los datos de envío y respuesta.",
  TITLE: "Notificaciones de webhooks",
};

const WebhookNotifications = () => {
  const { showNotifications, table } = useWebhookNotifications();

  return (
    <BaseDataContainer
      title=""
      onClickEdit={showNotifications}
      withoutEdit={!table?.rows?.length}
      textEditButton={
        table?.rows?.length ? webhookNotificationsTexts.BUTTON_TEXT : ""
      }
    />
  );
};

export default WebhookNotifications;
