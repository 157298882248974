import { IAppState } from "../../store/reducer";
import React from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useInvoiceChargeDispersionData } from ".//state/useInvoiceChargeDispersionData";
import { get } from "lodash";
import { INVOICE_FRECUENCY } from "../../shared/infrastructure/constants/MerchantInformationConstant";
import Edit from "../../assets/images/Edit";
import { routes } from "../../shared/infrastructure/constants/routes";

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: "6px",
  },
  typo: {
    color: "#6D7781",
  },
  containerPadding: {
    paddingTop: "24px",
  },
  chip: {
    borderRadius: 4,
    background: "#F0F4F9",
    height: "24px",
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#F7FAFC",
  },
  button: {
    backgroundColor: "#F0F4F9",
    borderRadius: 4,
  },
  headerTitle: {
    fontWeight: "bold",
  },
  gridItem: {
    width: "300px",
  },
}));

export interface IInvoiceChargeDispersionData extends IAppState {
  title: string;
}

export const InvoiceChargeDispersionData: React.FC<IInvoiceChargeDispersionData> = (
  props: IInvoiceChargeDispersionData
) => {
  const style = useStyles();
  const { merchant, rate } = useInvoiceChargeDispersionData();
  const invoiceFrequency: string = get(merchant, "invoiceFrequency", "none");
  const chargeFrequency: string = get(merchant, "chargeFrequency", "none");
  const dispersionFrequency: string = get(
    merchant,
    "dispersionFrequency",
    "none"
  );
  const InvoiceFrequencyResponse = INVOICE_FRECUENCY.find(
    (obj) => obj.value === invoiceFrequency
  );
  const ChargeFrequencyResponse = INVOICE_FRECUENCY.find(
    (obj) => obj.value === chargeFrequency
  );
  const DispersionFrequencyResponse = INVOICE_FRECUENCY.find(
    (obj) => obj.value === dispersionFrequency
  );

  return (
    <React.Fragment>
      <Card className={style.cardContainer} elevation={0}>
        <CardContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Grid item>
              <Typography className={style.headerTitle}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={style.button}
                href={`${routes.CHARGE}/${merchant!.publicMerchantId}`}
              >
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className={style.containerPadding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
            spacing={3}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>
                Frecuencia de facturación:
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={InvoiceFrequencyResponse!.name}
                className={style.chip}
              />
            </Grid>
          </Grid>
          <Grid
            container
            className={style.padding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>
                Frecuencia de cobro:
              </Typography>
            </Grid>
            <Grid item xs={3} alignItems={"center"}>
              <Chip
                label={ChargeFrequencyResponse!.name}
                className={style.chip}
              />
            </Grid>
          </Grid>
          {DispersionFrequencyResponse != null ? (
            <Grid
              container
              className={style.padding}
              direction={"row"}
              justify={"flex-start"}
              alignItems={"flex-start"}
            >
              <Grid item className={style.gridItem}>
                <Typography className={style.typo}>
                  Frecuencia de dispersión:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Chip
                  label={DispersionFrequencyResponse!.name}
                  className={style.chip}
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid
            container
            className={style.padding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>
                Retención de fraude:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Chip label={rate ? "Si" : "No"} className={style.chip} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
