import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonText } from "../../../shared/infrastructure/constants/SiftScienceConstants";

export interface DialogMessageProps {
  id: string;
  title: string;
  message: string;
  openDialog: boolean;
  onCancel: () => void;
  onOk: () => void;
  titleButton: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    "&.MuiDialogTitle-root": {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.palette.primary.dark,
    },
  },
  content: {
    "&.MuiDialogContentText-root": {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "120%",
      color: "#293036",
    },
  },
}));

export const DialogMessage: React.FC<DialogMessageProps> = (
  props: DialogMessageProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        id={props.id}
        open={props.openDialog}
        onClose={props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.content}
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={props.onCancel}>
            {ButtonText.CANCEL}
          </Button>
          <Button
            id="ok_modal"
            onClick={props.onOk}
            color="secondary"
            style={{ color: "#E24763" }}
            autoFocus
          >
            {props.titleButton}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
