import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { StatusChip } from "../../common/StatusChip/StatusChip";
import { makeStyles } from "@material-ui/core/styles";
import { MerchantProcessorsRules } from "../../../../types/merchant_processors_rules_response";
import { get, omit, defaultTo } from "lodash";
import { translateRuleKeys } from "../../../shared/utils";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";

const useStyles = makeStyles(() => ({
  tableContainer: { background: "transparent", paddingBottom: "25px" },
  tableCell: { paddingTop: "10px", paddingBottom: "10px" },
  width200: {
    width: "200px",
  },
}));
const getRulesByRule = (businessRule: object) => {
  const mainRulesValues: object = omit(get(businessRule, "rule", {}), [
    "name",
    "action",
    "code",
    "message",
    "partnerValidator",
    "limit",
    "maxAmount",
    "periodicitySum",
    "periodicityCount",
    "transactionStatusSum",
    "transactionStatusCount",
    "kind",
    "processorName",
    "metric",
    "transactionStatus",
    "periodicity",
  ]);
  return mainRulesValues;
};
export const BUSINESS_RULES_RULES: IOptionSelection[] = [
  { name: "Banco", value: "transactionBank" },
  { name: "Marca", value: "transactionBrand" },
  { name: "Diferido", value: "transactionIsDeferred" },
  { name: "Procesador", value: "transactionProcessor" },
  { name: "País", value: "transactionCountry" },
  { name: "Monto", value: "transactionTotalAmount" },
  { name: "Ip", value: "transactionIP" },
  { name: "Tipo de transacción", value: "transactionType" },
  { name: "Credencial", value: "transactionCredential" },
  { name: "Bin", value: "transactionBIN" },
  { name: "Tipo de tarjeta", value: "transactionIsCreditCard" },
  { name: "Tarjeta enmascarada", value: "transactionMaskedCreditCard" },
];
export const RULES_ACTIONS: IOptionSelection[] = [
  { name: "Enviar a", value: "route" },
];

export type IRulesTable = {
  merchantId: string;
  rules: MerchantProcessorsRules;
};

export const RulesTable: React.FC<IRulesTable> = (props: IRulesTable) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {["Alias", "Acción", "Etiquetas"].map((header: string) => (
              <TableCell
                key={header}
                align="left"
                className={
                  header !== "Failover"
                    ? classes.tableCell
                    : `${classes.tableCell} ${classes.width200}`
                }
              >
                <strong>{header}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {get(props, "rules.items", []).length > 0 && (
            <>
              {props.rules.items.map((rule) => (
                <TableRow key={rule.id}>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {get(rule, "rule.name")}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    {translateRuleKeys(
                      get(rule, "rule.action", ""),
                      RULES_ACTIONS
                    )}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Grid container>
                      {Object.keys(defaultTo(getRulesByRule(rule), {})).map(
                        (key: string, index: number) => (
                          <Grid
                            key={`grid_chip_${index}`}
                            item
                            md={4}
                            sm={8}
                            xs={12}
                            style={{ marginBottom: 5 }}
                          >
                            <StatusChip
                              key={`chip_${index}`}
                              text={translateRuleKeys(
                                key,
                                BUSINESS_RULES_RULES
                              )}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
