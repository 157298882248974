import { createSlice } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";

export const initialState: IAppState = {
  loading: false,
  loadingText: "",
  showAlert: false,
  typeAlert: "success",
  valueTab: "1",
};

export const appSlice = createSlice({
  extraReducers: {},
  initialState,
  name: "app",
  reducers: {},
});

export default appSlice.reducer;
