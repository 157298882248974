export enum ProviderOptionsEnum {
  OTP = "OTP",
  OTP_DINNERS = "OTP Diners",
  DS = "3DS",
}

export enum ProviderEnum {
  CYBERSOURCE = "cybersource",
  KUSHKI = "kushki",
}
