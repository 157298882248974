import { CountryEnum } from "./CountryEnum";

export enum CountryCodeEnum {
  EC = "EC",
  MX = "MX",
  CO = "CO",
  BR = "BR",
  CL = "CL",
  PE = "PE",
}

export const CountryCode: Record<string, CountryCodeEnum> = {
  [CountryEnum.ecuador]: CountryCodeEnum.EC,
  [CountryEnum.mexico]: CountryCodeEnum.MX,
  [CountryEnum.chile]: CountryCodeEnum.CL,
  [CountryEnum.peru]: CountryCodeEnum.PE,
  [CountryEnum.colombia]: CountryCodeEnum.CO,
};
