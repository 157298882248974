import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const wrapperContainerStyles = createNamedStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "10px",
    rowGap: (theme) => theme.spacing(5),
    padding: {
      md: "4.5rem 8.5rem",
      xs: "2rem",
    },
  },
  returnBox: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 960,
  },
  returnText: {
    color: (theme) => theme.palette.info.main,
    display: "flex",
  },
  label: {
    fontSize: "14px",
    verticalAlign: "center",
    marginLeft: "10px",
  },
  containerBody: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(5),
    mt: 5,
    mb: 20,
  },
  containerForm: {
    display: "flex",
    flexDirection: "column",
  },
  wrappedTabs: {
    width: 900,
  },
});
