import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { THUNK_PREFIXES } from "../../../shared/constants/prefixes";
import { getJwtAuth } from "../../../shared/utils/getJwtAuthUtils";
import { PaymentCredential } from "../../../../types/payment_credential";
import { SmartlinkData } from "../../../../types/smartlink_response";
import { VPOSData } from "../../../../types/vpos_response";
import { CardData } from "../../../../types/card_response";
import { SubscriptionData } from "../../../../types/subscription_response";
import { CommissionData } from "../../../../types/commission_response";
import { INodeInfoResponse } from "../../../../types/node_info_response";
import { INodeInfoRequest } from "../../../../types/node_info_request";
import { ServiceDefaultResponse } from "../../../../types/service_default_response";
import { UpdateConfigHierarchyRequest } from "../../../../types/update_config_hierarchy_request";
import { MerchantIdPathParameter } from "../../../../types/merchant_id_path_parameter";
import { GetProcessorsResponse } from "../../../../types/get_processors_response";
import { MerchantSettingsResponse } from "../../../../types/merchant_settings_response";
import { mutateProcessorsSettings } from "../../../shared/utils/mutationUtils";
import { MassiveServiceRequest } from "../../../../types/massive_service_request";

export const getNodeInfo = createAsyncThunk<
  INodeInfoResponse,
  INodeInfoRequest
>(THUNK_PREFIXES.NODE_INFO, async (payload) => {
  const response = await axios.post<INodeInfoResponse>(
    API_ROUTES.NODE_INFO,
    payload
  );

  return response.data;
});

export const getProcessors = createAsyncThunk<
  MerchantSettingsResponse,
  MerchantIdPathParameter
>(THUNK_PREFIXES.MERCHANT_SETTINGS, async ({ merchantId }) => {
  const response = await axios.get<GetProcessorsResponse[]>(
    API_ROUTES.MERCHANT_PROCESSORS(merchantId),
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return mutateProcessorsSettings(response.data);
});

export const getPaymentCredentials = createAsyncThunk<
  PaymentCredential[],
  {
    publicMerchantId: string;
  }
>(THUNK_PREFIXES.PAYMENT_CREDENTIALS, async ({ publicMerchantId }) => {
  const response = await axios.get<PaymentCredential[]>(
    `${API_ROUTES.PAYMENT_CREDENTIALS}${publicMerchantId}`,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const getMerchantSmartlinkData = createAsyncThunk<
  SmartlinkData,
  {
    publicMerchantId: string;
  }
>(THUNK_PREFIXES.MERCHANT_SMARTLINKS, async ({ publicMerchantId }) => {
  const response = await axios.get<SmartlinkData>(
    `${API_ROUTES.MERCHANT_SMARTLINKS}${publicMerchantId}`,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const getMerchantVPOSData = createAsyncThunk<
  VPOSData,
  {
    publicMerchantId: string;
  }
>(THUNK_PREFIXES.MERCHANT_VPOS, async ({ publicMerchantId }) => {
  const response = await axios.get<VPOSData>(
    `${API_ROUTES.MERCHANT_VPOS}${publicMerchantId}`,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const getMerchantCardData = createAsyncThunk<
  CardData,
  {
    publicMerchantId: string;
  }
>(THUNK_PREFIXES.MERCHANT_CARD, async ({ publicMerchantId }) => {
  const response = await axios.get<CardData>(
    `${API_ROUTES.MERCHANT_CARD}${publicMerchantId}`,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const getMerchantSubscriptionData = createAsyncThunk<
  SubscriptionData,
  {
    publicMerchantId: string;
  }
>(THUNK_PREFIXES.MERCHANT_SUBSCRIPTION, async ({ publicMerchantId }) => {
  const response = await axios.get<SubscriptionData>(
    `${API_ROUTES.MERCHANT_SUBSCRIPTION}${publicMerchantId}`,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});
export const getMerchantSubscriptionDataSandbox = createAsyncThunk<
  SubscriptionData,
  {
    publicMerchantId: string;
  }
>(
  THUNK_PREFIXES.MERCHANT_SUBSCRIPTION_SANDBOX,
  async ({ publicMerchantId }) => {
    const response = await axios.get<SubscriptionData>(
      `${API_ROUTES.MERCHANT_SUBSCRIPTION}${publicMerchantId}`,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);

export const getMerchantCommissionData = createAsyncThunk<
  CommissionData,
  {
    publicMerchantId: string;
  }
>(THUNK_PREFIXES.MERCHANT_COMMISSION, async ({ publicMerchantId }) => {
  const response = await axios.get<CommissionData>(
    `${API_ROUTES.MERCHANT_COMMISSION}${publicMerchantId}`,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const updateHierarchyNodeConfig = createAsyncThunk<
  Boolean,
  UpdateConfigHierarchyRequest
>("layout/updateHierarchyNodeConfig", async (payload) => {
  const response = await axios.put<Boolean>(
    API_ROUTES.UPDATE_HIERARCHY_NODE_CONFIG,
    payload
  );

  return response.data;
});

export const setDefaultServiceConfig = createAsyncThunk<
  ServiceDefaultResponse,
  {
    merchantId: string;
    privateMerchantId: string;
  }
>(
  THUNK_PREFIXES.SERVICES_DEFAULT,
  async ({ merchantId, privateMerchantId }) => {
    const response = await axios.post<ServiceDefaultResponse>(
      `${API_ROUTES.SERVICES_DEFAULT}`,
      {
        merchantId,
      },
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
          "Private-Merchant-Id": privateMerchantId,
        },
      }
    );

    return response.data;
  }
);

export const setMassiveServiceConfig = createAsyncThunk<
  Boolean,
  MassiveServiceRequest
>(
  THUNK_PREFIXES.MASSIVE_SERVICES_CONFIG,
  async (payload: MassiveServiceRequest) => {
    const response = await axios.post<Boolean>(
      `${API_ROUTES.MASSIVE_SERVICES_CONFIG}`,
      payload,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);
