import { Typography } from "@mui/material";
import { useStateApp } from "../../shared/state/useStateApp";
import { ITransactionalConciliationProps } from "./TransactionalConciliationHeader.interfaces";
import { headerStyles } from "./TransactionalConciliationHeader.styles";
import { FC } from "react";

const TransactionalConciliationHeader: FC<ITransactionalConciliationProps> = ({
  title,
}) => {
  const { isMobile } = useStateApp();

  return (
    <Typography
      sx={isMobile ? headerStyles.typographyMobile : headerStyles.typography}
      color={"primary"}
      variant={isMobile ? "h3" : "h2"}
    >
      {title}
    </Typography>
  );
};

export default TransactionalConciliationHeader;
