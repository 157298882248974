import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const confirmationModalStyles: IStyles = {
  deleteButton: {
    color: "#D62C4B",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    px: 3.75,
    py: 1,
  },
  backButton: {
    color: "#293036",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    border: "1px solid #CBD5E0",
    px: 3.75,
    py: 1,
  },
  dialogPadding: {
    px: 4,
    py: 3,
  },
  actionsDialogPadding: {
    px: 4,
    pb: 3,
  },
  title: {
    color: "#023365",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "140%",
  },
  subtitle: {
    color: "#6D7781",
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "120%",
  },
};
