import { Container, Grid } from "@mui/material";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useStateApp } from "../../shared/state/useStateApp";
import TransactionalConciliationHeader from "../../components/TransactionalConciliationHeader/TransactionalConciliationHeader";
import { HeaderFilter } from "../../components/HeaderFilter/HeaderFilter";
import ConciliationTabs from "../../components/ConciliationTabs/ConciliationTabs";
import GenericLoader from "../../components/common/GenericLoader/GenericLoader";
import { useKshRedux } from "../../store/hooks/hooks";
import { FC } from "react";
import { TAB_SECTIONS } from "../../shared/infrastructure/constants/TabSections";

export const TransactionalConciliation: FC = () => {
  const { isMobile } = useStateApp();
  const {
    store: { valueTab },
  } = useKshRedux();
  const ConciliationTabsContent: FC = TAB_SECTIONS[valueTab];

  return (
    <Container fixed>
      {!isMobile && (
        <Breadcrumb
          items={[{ label: "Inicio", url: "/dashboard" }]}
          lastItem={"Conciliación Transaccional"}
        />
      )}

      <TransactionalConciliationHeader title={"Conciliación Transaccional"} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderFilter />
          <ConciliationTabs />
        </Grid>
        <Grid item xs={12}>
          <ConciliationTabsContent />
        </Grid>
      </Grid>
      <GenericLoader />
    </Container>
  );
};
export default TransactionalConciliation;
