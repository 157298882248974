import React from "react";
import { Grid } from "@mui/material";
import { TimePickerComponentSection } from "./TimePickerComponentSection";
import { PlaceHolders } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { useTimeTagSate } from "./state/useTimeTagSate";

export interface TimeTagProps {
  selectedSchedule: string[];
  setSchedule(value: string[]): void;
  isDisable: boolean;
}

export const TimeComponentSection = (props: TimeTagProps) => {
  const { concatenateTimeStamp } = useTimeTagSate({
    onChange: props.setSchedule,
  });

  return (
    <Grid pt={1.5} container spacing={2}>
      <TimePickerComponentSection
        placeHolder={PlaceHolders.START_TIME}
        onChange={(value) => {
          concatenateTimeStamp({ value, type: "start" });
        }}
        value={props.selectedSchedule[0]}
        isDisable={props.isDisable}
      />
      <TimePickerComponentSection
        placeHolder={PlaceHolders.END_TIME}
        onChange={(value) => {
          concatenateTimeStamp({ value, type: "end" });
        }}
        value={props.selectedSchedule[1]}
        isDisable={props.isDisable}
      />
    </Grid>
  );
};
