import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { theme } from "@kushki/connect-ui";
import { MerchantResume } from "./containers/MerchantResume/MerchantResume";
import { routes } from "./shared/infrastructure/constants/routes";
import { store } from "./store/store";

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route
                path={routes.MERCHANT_RESUME}
                exact
                component={MerchantResume}
              />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
