import React from "react";
import { FormHelperText } from "@material-ui/core";
import { get, has } from "lodash";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { ErrorEnum } from "../../shared/enums/ErrorEnum";
import { IDefault } from "../../shared/interfaces/IDefault";

export interface IFormHelperTextCustomProps {
  errors: FieldErrors<IDefault>;
  msgCustom?: {
    [ErrorEnum.required]: string;
  };
  name: string;
}

const buildTextError = (errors: any, name: string, msgCustom: any) => {
  const error: ErrorEnum = get(errors, `${name}.type`, "");
  const defaultError: string = "*Campo obligatorio.";

  switch (error) {
    case ErrorEnum.required:
      return has(msgCustom, ErrorEnum.required)
        ? msgCustom[ErrorEnum.required]
        : defaultError;
  }
};

export const FormHelperTextCustom: React.FC<IFormHelperTextCustomProps> = (
  props: IFormHelperTextCustomProps
) => {
  const { errors, name, msgCustom } = props;

  return (
    <FormHelperText
      style={{ paddingLeft: "20px" }}
      error={!!get(errors, name, null)}
    >
      {!!get(errors, name, null) && buildTextError(errors, name, msgCustom)}
    </FormHelperText>
  );
};
