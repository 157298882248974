import { Box, Skeleton, TableCell, Theme } from "@mui/material";
import React, { FC } from "react";
import { useTableContext } from "../../Table/TableContext";
import { CellHeaderAlignEnum, CellHeaderTypeEnum } from "./enums";
import { CellHeaderProps } from "./interfaces";
import { CellHeaderStyles } from "./styles";
import { CellHeaderCheck } from "../CellHeaderCheck/CellHeaderCheck";
import { cellTitleStyles } from "../CellTitle/CellTitle.styles";

export const CellHeader: FC<CellHeaderProps> = ({
  align = CellHeaderAlignEnum.CENTER,
  spacing = 0,
  text,
  type,
  checkProps,
  component,
}: CellHeaderProps) => {
  const {
    state: { isLoading },
  } = useTableContext();

  const typeCheck: boolean = type === CellHeaderTypeEnum.CHECK;

  return (
    <TableCell
      sx={(theme: Theme) => ({
        backgroundColor: isLoading ? "neutral.grey2" : "inherit",
        height: "49px",
        margin: `0px ${theme.spacing(Number(spacing))}`,
        minWidth: checkProps?.label ? "12rem" : "4rem",
        padding: `0px ${theme.spacing(Number(spacing))}`,
        textAlign: align,
      })}
    >
      <Box px={3}>
        {isLoading ? (
          <Skeleton
            data-testid="skeleton"
            variant={"text"}
            sx={CellHeaderStyles.skeleton}
          />
        ) : (
          <>
            {typeCheck ? (
              <CellHeaderCheck
                state={checkProps?.state}
                isDisabled={checkProps?.isDisabled}
                onChecked={checkProps?.onChecked}
                label={checkProps?.label}
              />
            ) : (
              <Box sx={cellTitleStyles.cellTitles}>
                <Box sx={cellTitleStyles.marginRText}>{text}</Box>
                {component}
              </Box>
            )}
          </>
        )}
      </Box>
    </TableCell>
  );
};
