import { IRechargeWalletData } from "../../../../shared/interfaces/RechargeWalletData.interfaces";
import { IUseRechargeWalletCardState } from "./useRechargeWalletCardState.interfaces";
import { useEffect, useState } from "react";
import { get } from "lodash";
import { CheckOutTokenRequest } from "../../../../../types/checkout_token_request";
import {
  useAppDispatch,
  useAppSelector,
  useGetMerchantCountry,
  useGetMerchantInfo,
} from "../../../../store/hooks/hooks";
import {
  postCheckOutInitRequest,
  postCheckOutTokenRequest,
} from "../../../../store/thunks/RechargeWallet/rechargeWallet.thunks";
import { TransferTokenRequest } from "@kushki/js/lib/types/remote/transfer_token_request";
import { TokenResponse } from "@kushki/js/lib/types/remote/token_response";
import { ErrorResponse } from "@kushki/js/lib/types/error_response";
import { Kushki } from "@kushki/js";
import { environment } from "../../../../environments/environment";
import {
  CurrencyByCountry,
  CurrencyEnum,
} from "../../../../shared/enums/CurrencyEnum";
import { InitRequest } from "../../../../../types/checkout_init_request";
import {
  clearCheckoutInitResponse,
  clearCheckoutToken,
  setCheckoutInitError,
  setCheckoutTokenError,
} from "../../../../store/actions/rechargeWallet/rechargeWallet.actions";
import { PaymentMethodEnum } from "../../../../shared/enums/PaymentMethodEnum";
import { addTicketOrder } from "../../../../store/actions/ticketOrder/ticketOrder.actions";
import { useNavigate } from "react-router";
import { TransactionTypeEnum } from "../../../../shared/enums/TransactionTypeEnum";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../store/actions/app/actions";
import { LocalStoragePathsEnum } from "../../../../shared/enums/LocalStoragePathsEnum";
import { CountryEnum } from "../../../../shared/enums/CountryEnum";
import {
  createCallbackUrl,
  NavigateToRedirectURL,
} from "../../../../shared/utils/utils";

export const useRechargeWalletCardState = (): IUseRechargeWalletCardState => {
  const {
    checkoutToken,
    checkoutInitResponse,
    checkoutTokenError,
    checkoutInitError,
  } = useAppSelector((state) => state.rechargeWallet);
  const [rechargeWalletData, setRechargeWalletData] =
    useState<IRechargeWalletData>();
  const [isLoaderOpen, setIsLoaderOpen] = useState<boolean>(false);
  const [transferTokenError, setTransferTokenError] = useState<boolean>(false);
  const [transferToken, setTransferToken] = useState<TokenResponse>();
  const [formEmail, setFormEmail] = useState<string>("");
  const userEmail = get(localStorage, LocalStoragePathsEnum.email);
  const [formUserName, setformUserName] = useState<string>("");
  const country = useGetMerchantCountry();

  const getMerchantId = (country: CountryEnum) =>
    environment.publicCredentialId[country];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const merchantInfo = useGetMerchantInfo();
  const kushkiJs: Kushki = new Kushki({
    inTestEnvironment: !environment.production,
    merchantId: getMerchantId(country),
  });

  const onRechargeEvent = (data: IRechargeWalletData, email: string) => {
    setIsLoaderOpen(true);
    setRechargeWalletData(data);
    dispatch(setCheckoutInitError(false));
    dispatch(setCheckoutTokenError(false));
    setTransferTokenError(false);
    setFormEmail(email);
    setformUserName(get(data, "name", ""));

    const checkoutTokenRequest: CheckOutTokenRequest = {
      currency: get(merchantInfo, "dispersion.accountInfo[0].currency", ""),
      description: data.description,
      merchantId: get(merchantInfo, "publicMerchantId", ""),
      name: data.name,
      totalAmount: data.amount,
    };

    dispatch(postCheckOutTokenRequest(checkoutTokenRequest));
  };

  const requestTransferToken = () => {
    const request: TransferTokenRequest = {
      amount: {
        iva: 0,
        subtotalIva: get(rechargeWalletData, "amount", 0),
        subtotalIva0: 0,
      },
      callbackUrl: createCallbackUrl(country, merchantInfo),
      currency: CurrencyByCountry[country],
      documentNumber: get(merchantInfo, "taxId", undefined),
      documentType: "RUT",
      email: get(rechargeWalletData, "email", ""),
    };
    const requestTransferToken = new Promise((resolve, reject) => {
      kushkiJs.requestTransferToken(
        request,
        (response: TokenResponse | ErrorResponse) => {
          if ("code" in response || "message" in response) {
            return reject(response);
          }
          resolve(response);
        }
      );
    });

    requestTransferToken
      .then((value) => {
        return setTransferToken(<TokenResponse>value);
      })
      .catch(() => setTransferTokenError(true));
  };
  const requestInitCheckout = () => {
    const initCheckoutRequest: InitRequest = {
      amount: {
        iva: 0,
        subtotalIva: get(rechargeWalletData, "amount", 0),
        subtotalIva0: 0,
      },
      metadata: {
        backofficeUserEmail: userEmail,
        checkoutEmail: formEmail,
        username: formUserName,
      },
      paymentMethod: PaymentMethodEnum.TRANSFER,
      token: get(transferToken, "token", ""),
      tokenCheckout: get(checkoutToken, "token", ""),
    };

    dispatch(postCheckOutInitRequest(initCheckoutRequest));
  };

  useEffect(() => {
    if (get(checkoutToken, "token", "")) {
      requestTransferToken();
    }
  }, [checkoutToken]);

  useEffect(() => {
    if (get(transferToken, "token", "")) {
      requestInitCheckout();
    }
  }, [transferToken]);

  useEffect(() => {
    if (get(checkoutInitResponse, "transactionReference", "")) {
      dispatch(
        addTicketOrder({
          amount: `$ ${get(rechargeWalletData, "amount", "")} ${
            CurrencyEnum.MXN
          }`,
          beneficiaryName: get(rechargeWalletData, "name", ""),
          keyAccount: get(checkoutInitResponse, "referenceProcessor", ""),
          merchantName: get(merchantInfo, "name", ""),
          paymentDetails: get(rechargeWalletData, "description", ""),
          referenceNumber: get(
            checkoutInitResponse,
            "transactionReference",
            ""
          ),
          transactionType: TransactionTypeEnum.RECHARGE_WALLET,
        })
      );
      setIsLoaderOpen(false);
      dispatch(showSuccessAlert());
      dispatch(clearCheckoutToken());
      dispatch(clearCheckoutInitResponse());
      NavigateToRedirectURL(country, checkoutInitResponse, navigate);
    }
  }, [checkoutInitResponse]);

  useEffect(() => {
    if (checkoutTokenError || transferTokenError || checkoutInitError) {
      dispatch(showErrorAlert());
      setIsLoaderOpen(false);
    }
  }, [checkoutTokenError, transferTokenError, checkoutInitError]);

  return { isLoaderOpen, onRechargeEvent };
};
