import { InfoTrxProps } from "./ModalBodyTabs";
import {
  Box,
  Button,
  createStyles,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { get, isEmpty } from "lodash";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { ModalBodyContentDetail } from "../ModalTabs/ModalBodyContentDetail";
import {
  subSectionEnum,
  tabEnum,
} from "../../shared/infrastructure/AdvanceConfigModal";
import { IKshTab, KshTab } from "../Common/KshTab/KshTab";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";
import { EmptyStateTab } from "../Common/EmptyStateTab/EmptyStateTab";
import { StatusEnum } from "../../shared/infrastructure/StatusEnum";
import { ModalBodyObservations } from "../ModalTabs/ModalBodyObservations";
import { AdvanceConfigTabDetail } from "../ModalTabs/AdvanceConfigTabDetail/AdvanceConfigTabDetail";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import {
  EmptyAdvanceConfigurationMessage,
  EmptyObservationMessage,
} from "../../shared/infrastructure/constants/MessageEmptyDetailModalInvoice";
import { TimeLineTabHistoricComponent } from "../TabComponent/TimeLineTabHistoricComponent/TimeLineTabHistoricComponent";
import {
  ConfirmModal,
  ConfirmModalProps,
} from "../ConfirmCommentaryModal/ConfirmModal";
import { LoaderModal } from "../LoaderCommentary/LoaderModal";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
    copyIcon: {
      marginLeft: 10,
      color: "#CBD5E0",
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    btnSave: {
      fontSize: "17px",
      marginLeft: "10px",
      border: "0px",
      backgroundColor: "#023365 !important",
      "&:hover": {
        color: "#023365 !important",
      },
      color: "white !important",
    },
    btnSaveDisable: {
      fontSize: "17px",
      marginLeft: "10px",
      border: "0px",
      color: "rgb(109, 119, 129) !important",
    },
  })
);

const PaperContentDetail = (
  props: InfoTrxProps,
  subSection: subSectionEnum,
  isCharge: boolean = false
) => {
  const classes = useStyles();
  const trx = props.trxInfo;

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        color="textSecondary"
        className={classes.grid}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ModalBodyContentDetail
              tabName={tabEnum.infoTab}
              trxProps={props}
              country={get(trx, "country", CountryEnum.colombia)}
              subSection={subSection}
              isMinAmount={props.isMinAmount}
              isCharge={isCharge}
              isHistoric={props.isHistoric}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ConfirmModalComentary = (props: ConfirmModalProps) => {
  const { open, handleClose, handleAccept, trx } = props;
  return (
    <ConfirmModal
      open={open}
      titleText={
        <>
          <span
            style={{
              display: "block",
              marginBlockStart: "1em",
              marginBlockEnd: "1em",
              color: "black",
              fontWeight: 500,
            }}
          >
            ¿Deseas registrar comentario?
          </span>
        </>
      }
      maxWidth={"md"}
      contentText={
        <>
          <Typography>
            Estás a punto de registrar tu comentario, recuerda que esta acción
          </Typography>
          <Typography>una vez publicada no se podrá editar</Typography>
        </>
      }
      acceptText="Registrar"
      acceptColor="primary"
      cancelText={"Cancelar"}
      handleClose={handleClose}
      handleAccept={handleAccept}
      disableAccept={props.disableAccept}
      trx={trx}
    />
  );
};
export const TextArea = (props: InfoTrxProps) => {
  const classes = useStyles();
  const setTextAreaValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.textArea!.handleChange(get(e, "target.value", "").slice(0, 256));
  };
  const handleDisableButton: (value: boolean) => void = get(
    props,
    "textArea.handleDisableButton",
    () => {}
  );
  const valueText = get(props, "textArea.value", "");

  if (get(props, "trxInfo.reason", "") !== isEmpty()) handleDisableButton(true);
  if (get(props, "textArea.value", "") === "") handleDisableButton(true);
  else handleDisableButton(false);

  useEffect(() => {
    props.textArea!.handleChange(get(props, "trxInfo.reason", ""));
  }, []);

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <TextField
                autoComplete="off"
                disabled={get(props, "trxInfo.reason", "") !== ""}
                variant="outlined"
                fullWidth
                error={valueText.length >= 256}
                label={"Comentario y/o Observación (Contingencia Manual) "}
                id="alias"
                multiline
                value={valueText}
                helperText={
                  isEmpty(get(props, "textArea.value"))
                    ? "campo requerido"
                    : `${valueText.length}/256`
                }
                onChange={setTextAreaValue}
                rows={3}
              />
            </Grid>
            <ConfirmModalComentary
              open={get(props, "textArea.modal.openClose", false)}
              titleText={""}
              handleClose={get(props, "textArea.modal.handleOpenClose")}
              handleAccept={get(props, "textArea.modal.handleAccept")}
              trx={get(props, "trxInfo.transaction_id")}
            />
            <LoaderModal
              open={get(props, "textArea.loader.openClose", false)}
              message={"Estamos registrando tu comentario"}
              message1={"Este proceso puede tardar un poco..."}
              billingDataCounter={{ contador: 1, total: 2 }}
              handleClose={() => {}}
            />
          </Grid>
        </Grid>
      </Paper>
      {get(props, "trxInfo.manual.reason", "") === "" && (
        <Box style={{ float: "right" }}>
          <Button
            disabled={
              valueText !== "" && get(props, "trxInfo.reason", "") !== ""
            }
            color="primary"
            style={{
              marginRight: "10px",
            }}
            className={
              valueText !== "" && get(props, "trxInfo.reason", "") === ""
                ? classes.btnSave
                : classes.btnSaveDisable
            }
            onClick={get(props, "textArea.handleEraseText", () => {})}
          >
            Borrar
          </Button>
          <Button
            color="primary"
            disabled={
              valueText !== "" && get(props, "trxInfo.reason", "") !== ""
            }
            className={
              valueText !== "" && get(props, "trxInfo.reason", "") === ""
                ? classes.btnSave
                : classes.btnSaveDisable
            }
            onClick={get(props, "textArea.modal.handleOpenClose", () => {})}
          >
            Guardar
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

const renderGeneralInfo = (props: InfoTrxProps) => {
  const trx = props.trxInfo;
  const validation: boolean =
    props.statusFlow == StatusFlowEnum.VALIDATOR &&
    get(props.trxInfo, "status", "") === "manual";
  switch (get(trx, "kind", "")) {
    case KindEnum.INVOICE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCard1)}
          {PaperContentDetail(props, subSectionEnum.infoCard2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}

          {validation && <TextArea {...props} />}
        </>
      );
    case KindEnum.CHARGE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardCharge1, true)}
          {PaperContentDetail(props, subSectionEnum.infoCardCharge2, true)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCardCharge3, true)}

          {validation && <TextArea {...props} />}
        </>
      );
    case KindEnum.CREDIT_NOTE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCreditNoteInfo)}
          {PaperContentDetail(props, subSectionEnum.infoCreditNoteBank)}
          {PaperContentDetail(props, subSectionEnum.addressCreditNote3)}
          {validation && <TextArea {...props} />}
        </>
      );
    case KindEnum.DISPERSION:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion1)}
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}

          {validation && <TextArea {...props} />}
        </>
      );
    case KindEnum.RECEIVABLE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion1)}
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion2)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
          {validation && <TextArea {...props} />}
        </>
      );
    case KindEnum.DEBIT_NOTE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardDebitNote1)}
          {PaperContentDetail(props, subSectionEnum.infoCardDebitNote2)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}

          {validation && <TextArea {...props} />}
        </>
      );
    default:
      return <></>;
  }
};

const renderListTabsHistoric = (props: InfoTrxProps): IKshTab => {
  const kind: KindEnum = get(
    props.trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.KIND, props.isHistoric),
    ""
  ) as KindEnum;
  const dataTabs: { title: string; content: JSX.Element }[] = [];
  [KindEnum.INVOICE].includes(kind)
    ? dataTabs.push({
        title: "Config. avanzada",
        content:
          !isEmpty(get(props.trxInfo, "advance_settings_reference")) ||
          !isEmpty(get(props.trxInfo, "advance_settings_parameters")) ? (
            <AdvanceConfigTabDetail
              InfoTrxProps={props}
              country={props.country as CountryEnum}
              isHistoric={props.isHistoric}
            />
          ) : (
            <EmptyStateTab
              title={EmptyAdvanceConfigurationMessage[kind]}
              subtitle='Para agregarlas, haz click en "Configuración Avanzada"'
            />
          ),
      })
    : null;

  [KindEnum.INVOICE, KindEnum.CHARGE, KindEnum.DISPERSION].includes(kind)
    ? dataTabs.push({
        title: "Observaciones",
        content:
          get(props.trxInfo, "status") === StatusEnum.REJECTED ? (
            <ModalBodyObservations
              InfoTrxProps={props}
              country={props.country as CountryEnum}
            />
          ) : (
            <EmptyStateTab
              title={EmptyObservationMessage[kind]}
              isProcessData={true}
            />
          ),
      })
    : null;

  dataTabs.push({
    title: "Línea de tiempo",
    content:
      get(props.trxInfo, DynamoReferenceEnum.OLD_TRANSACTIONS, []).length !==
      0 ? (
        <TimeLineTabHistoricComponent {...props} />
      ) : (
        <EmptyStateTab title="Esta dispersión no tiene Línea de Tiempo" />
      ),
  });

  const tabs: IKshTab = {
    tabs: [
      {
        title: "Información",
        content: renderGeneralInfo(props),
      },
      ...dataTabs,
    ],
  };

  return tabs;
};

const ModalBodyColombia = (props: InfoTrxProps) => {
  return (
    <ModalBody>
      <React.Fragment>
        {(props.isHistoric ||
          get(
            props.trxInfo,
            getDynamoElasticRef(
              DynamoReferenceEnum.STATUS_FLOW,
              props.isHistoric
            ),
            ""
          ) === StatusFlowEnum.VALIDATOR) &&
        isEmpty(props.tabDefault) ? (
          <KshTab {...renderListTabsHistoric(props)} />
        ) : (
          renderGeneralInfo(props)
        )}
      </React.Fragment>
    </ModalBody>
  );
};

export default ModalBodyColombia;
