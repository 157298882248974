import { LocalStorageKeyEnum } from "../../infrastructure/enums/LocalStorageKeyEnum";
import { IBasicMerchantInformation } from "../../infrastructure/interfaces/IBasicMerchantInformation";
import { defaultTo } from "lodash";

export const defaultBasicMerchantInformation: IBasicMerchantInformation = {
  name: "",
  country: "",
  redirectPath: "",
};

export const getBasicMerchantInformationStorage =
  (): IBasicMerchantInformation => {
    const storageMerchant = window.localStorage.getItem(
      LocalStorageKeyEnum.BASIC_MERCHANT_INFORMATION
    );

    return JSON.parse(
      defaultTo(
        storageMerchant,
        JSON.stringify(defaultBasicMerchantInformation)
      )
    );
  };

export const updateBasicMerchantInformationStorage = (
  newValue: Partial<IBasicMerchantInformation>
) => {
  const oldValue = getBasicMerchantInformationStorage();
  const newItem: IBasicMerchantInformation = { ...oldValue, ...newValue };
  window.localStorage.setItem(
    LocalStorageKeyEnum.BASIC_MERCHANT_INFORMATION,
    JSON.stringify(newItem)
  );
};

export const getRolesLocalStorage = () =>
  JSON.parse(localStorage.getItem("roles") || "{}");
