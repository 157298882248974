export const DISPERSION_FIRST_LABEL =
  "Configura la frecuencia con la que el comercio recibirá el abono de sus fondos.";
export const DISPERSION_FRAUD_SUBTITLE = "Prevención de Fraude";
export const RETENTION_COMMISSION_LABEL =
  "Retención de comisiones antes de dispersar:";
export const FRAUD_DESC_LABEL = "Retención de porcentaje por fraudes:";
export const CONFIGURATION_LABEL = "Configuración de retención";
export const PERCENTAGE_DAYS_LABEL =
  "Configura el porcentaje de retención y su vigencia en días.";
export const PERCENTAGE_LABEL_FORM = "Porcentaje a retener";
export const DAYS_LABEL_FORM = "Número de días";
export const PERCENTAGE_HELPER_TEXT =
  "Porcentaje retenido por Kushki temporalmente";
export const DAYS_HELPER_TEXT = "Se contabilizarán los días laborables";
export const ACCOUNT_TYPE_LABEL = "Tipo de cuenta bancaria";
export const BANK_SECTION_LABEL = "Cuentas Bancarias:";
export const BANK_BUTTON_LABEL = "Agregar cuenta bancaria";
export const BANK_BUTTON_LABEL_ALTERNATE = "Agregar cuenta alterna";
export const BANK_DISCLAIM_LABEL =
  "Para que el customer pueda recibir sus fondos, es necesario que siempre tenga registrada una cuenta bancaria principal.";
export const MODAL_LOADER_DESCRIPTION =
  "Este proceso puede tardar unos minutos...";
export const MODAL_LOADER_TITLE = "Estamos creando tu solicitud";
export const DISPERSION_FREQUENCY_LABEL = "Frecuencia de Dispersión";
export const COL_GMF_4_X_1000 = "Impuesto 4 x 1.000 (GMF)";
export const COL_GMF_4_X_1000_LABEL =
  "¿El comercio aplica al traspaso del cobro del Gravamen de Movimientos Financieros?";
export const DISPERSION_SWITCH_LABEL = "Proceso exclusivo de liquidación";
