import React from "react";
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Modal,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Save } from "react-feather";
import { SecurityRulesTitleModal } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { makeStyles } from "@material-ui/core/styles";
import { SecurityRulesRoles } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface ActionButtonsProps {
  onCancel: () => void;
  onSave: () => void;
  disabled: boolean;
  mainActionText: string;
  secondaryActionText: string;
  withIcon: boolean;
}

const useStyles = makeStyles({
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 250,
    padding: "3rem",
    background: "white",
    boxShadow: "24",
    borderRadius: "8px",
  },
});

export const ActionButtons: React.FC<ActionButtonsProps> = (
  props: ActionButtonsProps
) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction={"row"}
        >
          <Box mr={3} pt={4}>
            <Button
              size="large"
              color="default"
              onClick={handleOpenModal}
              disabled={props.disabled}
              variant="text"
            >
              {props.secondaryActionText}
            </Button>
          </Box>
          <Box pt={4}>
            <ComponentSecurityWrapper
              componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${SecurityRulesRoles.COUNTRY_MERCHANT_SAVE_RULE}`}
            >
              <Box component={"span"}>
                <Button
                  disabled={props.disabled}
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={props.onSave}
                  startIcon={props.withIcon ? <Save /> : null}
                >
                  {props.mainActionText}
                  {props.disabled && (
                    <Box ml={1} display="flex" alignItems="center">
                      <CircularProgress color="inherit" size={20} />
                    </Box>
                  )}
                </Button>
              </Box>
            </ComponentSecurityWrapper>
          </Box>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleCloseModal}>
        <>
          <Box className={classes.modalBox}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography
                  variant="h3"
                  component="h3"
                  style={{ color: "#023365" }}
                >
                  {SecurityRulesTitleModal.CONFIRM_RETURN}
                </Typography>{" "}
              </Box>
              <Box>
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon style={{ color: "#023365", fontSize: "10" }} />
                </IconButton>
              </Box>
            </Box>
            <Typography style={{ color: "#293036", marginTop: "2rem" }}>
              {SecurityRulesTitleModal.ACTION_WARNING}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "3rem",
              }}
            >
              <Button style={{ color: "#E24763" }} onClick={handleCloseModal}>
                {SecurityRulesTitleModal.CANCEL}
              </Button>
              <Button
                onClick={props.onCancel}
                style={{
                  background: "#64798D",
                  color: "white",
                  padding: "0px 30px",
                  marginLeft: "30px",
                  borderRadius: "12px",
                }}
              >
                {SecurityRulesTitleModal.ACCEPT}
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    </React.Fragment>
  );
};
