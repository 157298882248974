import {
  IConfigCells,
  TableBodyCellEnum,
} from "../../components/Table/TableNodes/constants";
import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import { ModelConstants } from "../constants/ModelConstants";
import { CellTextActionStyles } from "../../components/TableCells/CellText/styles/RetentionCellText.styles";

export const headCellsBranches: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    id: "line1",
    label: "Nombre",
  },
  {
    align: "left",
    disablePadding: false,
    id: "title",
    label: "Branch ID",
  },
  {
    align: "left",
    disablePadding: false,
    id: "country",
    label: "País de Constitución",
  },
  {
    align: "left",
    disablePadding: false,
    id: "line123121231",
    label: "Fecha de creación",
  },
  {
    align: "left",
    disablePadding: false,
    id: "text",
    label: "Estado",
  },
];

export const headCellsRetention: HeadCell[] = [
  {
    disablePadding: false,
    id: "cycle",
    isSort: true,
    label: "Ciclo",
  },
  {
    disablePadding: false,
    id: "processorType",
    label: "Modelo",
  },
  {
    disablePadding: false,
    id: "retIva",
    label: "Tipo de documento",
  },
  {
    disablePadding: false,
    id: "retIca",
    label: "",
  },
  {
    disablePadding: false,
    id: "retFue",
    label: "",
  },
];

export const CatalogConfigTableBranches: IConfigCells[] = [
  {
    align: "left",
    configMain: {
      defaultValue: "Name Default",
      hasUrl: true,
      path: "name",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created default",
      isDate: true,
      path: "created",
      text: "Creado: $var",
    },
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "MerchantId Default",
      path: "merchant_id",
      text: "$var",
    },
    type: TableBodyCellEnum.TEXT_COPY,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "Colombia",
      path: "constitutional_country",
      text: "$var",
    },
    isCodeCountry: false,
    type: TableBodyCellEnum.FLAG,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "updated default",
      isDate: true,
      path: "updated_at",
      text: "$var",
    },
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "inactive",
      path: "merchant_status",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "",
      path: "pending_section",
      text: "$var",
    },
    type: TableBodyCellEnum.TAG,
  },
];

export const CatalogConfigTableRetention: IConfigCells[] = [
  {
    align: "left",
    configMain: {
      defaultValue: "Name Default",
      formatDate: "dd.MM.yyyy",
      hasUrl: false,
      isDate: true,
      path: "start_date",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "Name Default",
      formatDate: "dd.MM.yyyy",
      hasUrl: false,
      isDate: true,
      path: "end_date",
      text: "$var",
    },
    type: TableBodyCellEnum.DATE_RANGE,
  },
  {
    align: "left",
    configMain: {
      catalog: ModelConstants,
      defaultValue: "-",
      path: "processor_type",
      styles: CellTextActionStyles,
      text: "$var",
    },
    type: TableBodyCellEnum.TEXT,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "IVA",
      path: "ret_iva",
      text: "$var",
    },
    type: TableBodyCellEnum.OPTION_MENU,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "ICA",
      path: "ret_ica",
      text: "$var",
    },
    type: TableBodyCellEnum.OPTION_MENU,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "FUENTE",
      path: "ret_fue",
      text: "$var",
    },
    type: TableBodyCellEnum.OPTION_MENU,
  },
];
