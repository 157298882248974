import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const infoContentCardStyles = createNamedStyles({
  cardContainer: {
    paddingLeft: 2,
  },
  cardContainerTable: {
    padding: "16px 16px 16px 0",
  },
  gridContainer: { width: "100%", marginLeft: 0, marginTop: 0 },
  cardInfo: {
    backgroundColor: "#F7FAFC",
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    padding: 2,
  },
  cardInfoTable: {
    backgroundColor: "#F7FAFC",
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 16px 16px 0",
  },
  infoTitle: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "19.2px",
  },
  infoLegend: {
    color: "#46525C",
    fontSize: "13px",
    fontStyle: "normal",
    paddingTop: "10px",
    paddingRight: "18px",
    lineHeight: "15.6px",
  },
});
