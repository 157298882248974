import React from "react";
import {
  FieldOptionValue,
  InputLabelForm,
  inputOperatorName,
  inputValueName,
} from "@shared/constants/AlarmConfigConstants";
import FormControl from "@components/FormControl/FormControl";
import { InputTypeEnum } from "@shared/enum/InputTypeEnum";
import { OPTION } from "@shared/constants/selectOptions";
import { Box } from "@mui/material";
import { dynamicInputStyles as styles } from "@components/DynamicInput/DynamicInput.styles";
import { RULES } from "@shared/constants/formRules";
import { useDynamicInput } from "@components/DynamicInput/state/useDynamicInput";
import { IDynamicInputProps } from "@components/DynamicInput/state/DynamicInput.interfaces";
import { countriesOption } from "@shared/constants/countriesOption";

const DynamicInput: React.FC<IDynamicInputProps> = (
  props: IDynamicInputProps
) => {
  const { renderOptions, fieldInput, mccCatalog } = useDynamicInput(props);
  const { index } = props;

  switch (fieldInput) {
    case FieldOptionValue.PEP:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputValueName(index)}
            label={InputLabelForm.VALUE}
            options={OPTION.yes_no}
            rules={RULES.value}
          />
        </Box>
      );
    case FieldOptionValue.AVERAGE_TICKET:
    case FieldOptionValue.TPV:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.NUMBER}
            name={inputValueName(index)}
            label={""}
            rules={RULES.value}
            fieldName={fieldInput}
          />
        </Box>
      );
    case FieldOptionValue.STANDARD_DEVIATION:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.NUMBER}
            name={inputValueName(index)}
            label={InputLabelForm.DEVIATION}
            rules={RULES.value}
            fieldName={fieldInput}
          />
        </Box>
      );
    case FieldOptionValue.MODEL:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputValueName(index)}
            label={InputLabelForm.VALUE}
            options={OPTION.model}
            rules={RULES.value}
          />
        </Box>
      );
    case FieldOptionValue.MCC:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.AUTOCOMPLETE}
            name={inputValueName(index)}
            label={InputLabelForm.VALUE}
            options={mccCatalog()}
            rules={RULES.value}
          />
        </Box>
      );
    case FieldOptionValue.CONSTITUTIONAL_COUNTRY:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.AUTOCOMPLETE}
            name={inputValueName(index)}
            label={InputLabelForm.VALUE}
            options={countriesOption}
            rules={RULES.value}
          />
        </Box>
      );
    case FieldOptionValue.CATEGORY:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputValueName(index)}
            label={InputLabelForm.VALUE}
            options={OPTION.merchantCategory}
            rules={RULES.value}
          />
        </Box>
      );
    case FieldOptionValue.CONSTITUTION_DATE:
      return (
        <Box sx={styles.wrapper}>
          <FormControl
            inputType={InputTypeEnum.SELECT}
            name={inputOperatorName(index)}
            label={InputLabelForm.OPERATOR}
            options={renderOptions(fieldInput)}
            rules={RULES.operator}
          />
          <FormControl
            inputType={InputTypeEnum.DATE}
            name={inputValueName(index)}
            label={InputLabelForm.DATE}
            rules={RULES.value}
          />
        </Box>
      );
    default:
      return <></>;
  }
};

export default DynamicInput;
