export enum ErrorMessageBRA {
  CNPJ_LENGTH = "Este campo debe tener entre 11 y 20 caracteres.",
  CPF_LENGTH = "Este campo debe tener 11 caracteres.",
  MAX_1_50_CHAR = "El máximo permitido de caracteres es 1-50",
  PF_RAZON_SOCIAL = "Por favor, ingresa la razón social",
  LIM_IDEN = "El número de identificación debe tener entre 12 y 13 caracteres",
  PF_NUM_ID = "Por favor, ingresa el número de identificación",
  PF_NOMBRE = "Por favor, ingresa el nombre",
  PF_APELLIDO = "Por favor, ingresa el apellido",
  LETRAS = "Solo se aceptan letras",
  PF_MCC = "Por favor, selecciona un sector comercial",
  PF_COMPANY = "Por favor, selecciona el tipo de empresa o sociedad",
  PF_INDUSTRIA = "Por favor, selecciona el tipo de industria",
  PF_ACT_COMER = "Por favor, selecciona la actividad comercial",
  PF_TIPO_PERSONA = "Por favor, elige un tipo de persona",
  PF_WEB = "Por favor, escribe el sitio web",
  MAX_ADDRESS = "El máximo permitido de caracteres es 50",
}

export enum ErrorBillingData {
  ZIPCODE = "Ingresa un código postal válido",
  ADDRESS = "Ingresa una dirección válida",
  PHONE_NUMBER_EMPTY = "Ingresa un número válido",
  PHONE_NUMBER = "Ingresa el teléfono de la empresa",
}

export enum RegularExp {
  CAPITAL_LETTER = "^[A-Z\\s]+$",
  CAPITAL_LETTER_NUMBERS = "[A-Z0-9 ]+$",
  ONLY_LETTERS = "^[a-zA-ZñÑÁÉÍÓÚáéíóúÜü\\s]*$",
  ONLY_NUMBERS = "^[0-9]+$",
  PHONE_NUMBER = "000 000 000 ",
  WEB = "[^]",
}

export enum BasicDataLabelsBRA {
  RAZON_SOCIAL = "Razón Social",
  CNPJ = "CNPJ",
  CPF = "CPF",
  TIPO_EMPRESA = "Tipo de empresa o sociedad mercantil",
  TIPO_INDUSTRIA = "Tipo de industria",
  MCC = "MCC",
  FECHA_CONST = "Fecha de constitución",
  INDUSTRIA = "Tipo de industria",
  WEB = "Sitio web",
  NOMBRE = "Nombre(s)",
  APELLIDO = "Apellido(s)",
  DATOS = "Datos básicos",
  INFO_PRINCIPAL = "Información principal",
  TIPO_PERSONA = "Tipo de persona",
  INFO_BASICA = "Información básica",
  INFO_NEGOCIO = "Información del negocio",
  PAIS_CONST = "País de constitución:",
}

export enum TypeElement {
  TEXT = "text",
  TEXT_CAPITAL = "textCapital",
  WEB = "web",
  DATE_PICKER = "datePicker",
  COMBO = "combo",
  COMBO_ARRAY = "comboArray",
}

export enum TypeListBRA {
  MCC = "mcc",
  ECONOMY_ACTIVITY = "economyActivity",
  INDUSTRY = "typeIndustry",
  COMPANY = "typeCompany",
}

export enum TypeCatalogBRA {
  CIUU = "CIUU",
  MCC = "MCC",
}
