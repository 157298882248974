import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const chipConciliationActionStyles: IStyles = {
  title: {
    fontFamily: "IBM Plex Sans,sans-serif",
    color: "text.dark",
    fontSize: "15px",
  },
  subtitle: {
    fontFamily: "IBM Plex Sans,sans-serif",
    color: "text.grey",
    fontSize: "15px",
  },
};
