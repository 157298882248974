import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";

export interface SnackBarAlertProps {
  msg: string;
  open: boolean;
  type: Color;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
}

export const SnackbarAlert: React.FC<SnackBarAlertProps> = (
  props: SnackBarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2500}
        onClose={props.handlerClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={props.handlerClose}
          severity={props.type}
        >
          {props.msg}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};
