import React from "react";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IAppState } from "../../store/reducer";
import { get } from "lodash";
import { useBasicInformation } from "./state/useBasicInformation";
import { Copy } from "react-feather";
import { BasicInfoSkeleton } from "../../components/Skeletons/BasicInfoSkeleton/BasicInfoSkeleton";

const useStyles = makeStyles(() => ({
  typography: {
    fontWeight: 500,
    padding: "8px",
    paddingLeft: "0px",
    fontSize: "16px",
    color: "black",
    fontStyle: "bold",
  },
  typographyMobile: {
    fontWeight: 500,
    padding: "8px",
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "bold",
    color: "black",
  },
  descriptionLabel: {
    fontWeight: 200,
    fontSize: "14px",
    color: "#6D7781",
  },
  informationLabel: {
    fontWeight: 200,
    fontSize: "14px",
    color: "#293036",
  },
  copyIcon: {
    marginLeft: 10,
    padding: "0px",
    color: "#CBD5E0",
  },
  padding: {
    paddingBottom: "1px",
  },
  statusBadge: {
    padding: "4px 8px",
    borderRadius: "4px",
    width: 75,
    textAlign: "center",
    marginBottom: 5,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    width: "100%",
  },
  statusLabel: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
  },
  editInfo: {
    padding: "0px",
    minWidth: "40px",
    backgroundColor: "#F0F4F9",
  },
  gridContainer: {
    paddingBottom: "0px",
  },
}));

export interface IConfigHeaderProps extends IAppState {
  title: string;
}

export const BasicInformation: React.FC<IConfigHeaderProps> = ({
  title,
}: IConfigHeaderProps) => {
  const classes = useStyles();

  const {
    merchant,
    loading,
    isMobile,
    naturalPerson,
    merchantType,
  } = useBasicInformation();

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.padding}>
        {loading ? (
          <BasicInfoSkeleton></BasicInfoSkeleton>
        ) : (
          <Grid
            item
            xs={12}
            md={9}
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Box
              display={"flex"}
              alignContent={"center"}
              flexDirection={"row"}
              m={2}
            >
              <Typography
                className={
                  isMobile ? classes.typographyMobile : classes.typography
                }
                color={"primary"}
                variant={isMobile ? "h3" : "h1"}
              >
                {title}
              </Typography>
            </Box>
            <Box display={"flex"} m={2}>
              <Grid container spacing={0} xs={12}>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Nombre:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {get(merchant, "name")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    ID del comercio:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {get(merchant, "publicMerchantId")}
                    <IconButton className={classes.copyIcon}>
                      <Copy
                        size={16}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            merchant!.publicMerchantId
                          );
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>
                {merchantType && (
                  <>
                    <Grid item xs={6}>
                      <Typography className={classes.descriptionLabel}>
                        Tipo de persona:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.informationLabel}>
                        {merchantType?.name}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    {naturalPerson ? "Nombres y apellidos:" : "Razón Social:"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {naturalPerson
                      ? naturalPerson
                      : get(merchant, "socialReason")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Número de identificación:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {get(merchant, "taxId")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    URL:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {get(merchant, "webSite")}
                    <IconButton className={classes.copyIcon}>
                      <Copy
                        size={16}
                        onClick={() => {
                          navigator.clipboard.writeText(merchant!.webSite);
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Estado:
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.gridContainer}>
                  <Box
                    className={classes.statusBadge}
                    style={{
                      background: !!merchant.isActive
                        ? "rgba(210, 255, 244, 0.3)"
                        : "#B4B9BE",
                    }}
                  >
                    <Typography
                      className={classes.statusLabel}
                      style={{
                        color: !!merchant.isActive ? "#0DC298" : "#FFFFFF",
                      }}
                    >
                      {merchant.status === "active" ? "Activo" : "Inactivo"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default BasicInformation;
