import { PersonTypeEnum } from "../constants/initial_state_legal_details";
import { BoardMemberPhysical } from "../../components/LegalDetailsBody/BoardMembersSection/BoardMemberPhysicalForm/BoardMemberPhysicalForm";
import { BoardMemberMoral } from "../../components/LegalDetailsBody/BoardMembersSection/BoardMemberMoralForm/BoardMemberMoralForm";
import React from "react";
import { FormItem } from "../../components/LegalDetailsBody/Forms/Generics/BuildableForm";
import { Control, FieldValues } from "react-hook-form";

export const formComponentBoardMember = (
  closeModal: () => void,
  handleSubmit: Function,
  identityInformationPhysical: FormItem[],
  personalInformationMoral: FormItem[],
  personalInformationPhysical: FormItem[],
  reset: Function,
  control?: Control<FieldValues, Object>
) => ({
  [PersonTypeEnum.FISICA]: (
    <BoardMemberPhysical
      handleSubmit={handleSubmit}
      personalInformationPhysical={personalInformationPhysical}
      identityInformationPhysical={identityInformationPhysical}
      reset={reset}
      closeModal={closeModal}
      control={control}
    />
  ),
  [PersonTypeEnum.MORAL]: (
    <BoardMemberMoral
      handleSubmit={handleSubmit}
      personalInformationMoral={personalInformationMoral}
      reset={reset}
      closeModal={closeModal}
      control={control}
    />
  ),
});
