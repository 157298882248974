import { makeStyles } from "@material-ui/core";

export const useBasicSectionStyles = makeStyles((theme) => ({
  container: {
    gap: theme.spacing(2),
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  item: {
    backgroundColor: "#F7FAFC",
    borderRadius: theme.spacing(0.5),
  },
}));
