export const TITLE_LABEL = "Detalle para la recarga";
export const DESCRIPTION_LABEL =
  "Estarás recibiendo los siguientes datos en tu correo, los cuales deberás ingresar al momento de hacer la transferencia en tu banco en línea.";
export const AMOUNT_LABEL = "MONTO DE OPERACIÓN";
export const SERVICE_LABEL = "TIPO DE OPERACIÓN";
export const ORIGIN_LABEL = "ORIGEN";
export const DETAILS_TITLE_LABEL = "Detalle para transferencia";
export const BENEFICIARY_LABEL = "NOMBRE DEL BENEFICIARIO";
export const KEY_ACCOUNT_LABEL = "CUENTA CLABE";
export const REFERENCE_NUMBER_LABEL = "N° DE REFERENCIA";
export const DISCLAIMER_LABEL =
  "Es indispensable que al hacer tu recarga se indique el Concepto de pago para identificar la transacción.";

export const EXPIRATION_LABEL =
  "Recuerda que a los 7 días vence tu proceso de pago y debes iniciar uno nuevo desde consola.";
export const VERIFY_BY = "VERIFIED BY";
export const BENEFICIARY_NAME = "KUSHKI S DE RL DE CV";
