import {
  ICellTextComponent,
  IUseCellTextComponent,
} from "../CellTitle.interfaces";
import { useTableContext } from "../../../Table/TableContext";
import { MouseEvent, useEffect, useState } from "react";
import { pushOrReplaceObjectInValueCells } from "../../../Table/TableContext/tableUtils";

export const useCellTitle = ({
  id,
  rowIndex,
  line1,
  nodeId,
}: ICellTextComponent): IUseCellTextComponent => {
  const {
    state: { isLoading, valueCells },
    handler: { handleSetCellValues },
  } = useTableContext();
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [error] = useState(false);
  const handleShouldShow = ({ currentTarget }: MouseEvent) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const hideTooltip = () => setTooltipEnabled(false);

  const getStyle = (align: string) => {
    switch (align) {
      case "center":
        return {
          background: "neutral.400",
          borderRadius: "4px",
          marginLeft: "20%",
          marginTop: "4px",
          width: "62%",
        };
      case "right":
        return {
          background: "neutral.400",
          borderRadius: "4px",
          marginLeft: "30%",
          marginTop: "4px",
          width: "70%",
        };
      case "left":
      default:
        return {
          background: "neutral.400",
          borderRadius: "4px",
          marginTop: "4px",
          width: "70%",
        };
    }
  };

  useEffect(() => {
    handleSetCellValues(
      pushOrReplaceObjectInValueCells(valueCells, {
        error: error,
        id: `${rowIndex}-${id}`,
        nodeId,
        rowIndex,
        value: line1,
      })
    );
  }, [line1]);

  return {
    handlers: { getStyle, handleShouldShow, hideTooltip },
    isLoading,
    tooltipEnabled,
  };
};
