import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { ISort } from "@kushki/connect-ui/dist/Shared/Utils/IconSortArrow/IconSortArrow.interfaces";

export enum LabelEnum {
  ACTION_REQUEST_ERROR = "Hubo un error al enviar la solicitud por favor vuelva a intentarlo.",
  ACTIVATE = "Activar",
  ADD = "Agregar",
  CANCEL = "Cancelar",
  DEACTIVATE = "Desactivar",
  DELETE = "Eliminar",
  DESCRIPTION_MODAL_ADD_CREDENTIAL = "Agregar credencial",
  DESCRIPTION_MODAL_DELETE_CREDENTIAL = "Si eliminas esta credencial se perderá la conexión con las plataformas en donde registraste tus llaves.",
  DESCRIPTION_MODAL_DEACTIVATED_CREDENTIAL = "Si desactivas esta credencial se perderá la conexión con las plataformas en donde registraste tus llaves y ya no se podrá transaccionar con estas.",
  DESCRIPTION_MODAL_EDIT_CREDENTIAL = "Editar credencial",
  DESCRIPTION_MODAL_RENEW_CREDENTIAL = "Al aceptar se restablecerá la credencial. Esta modificación no afectará al alias o ninguna regla de seguridad que aplique.",
  EDIT = "Editar",
  EDIT_ALIAS = "Editar alias",
  RENEW_KEY = "Renovar llave",
  RENEW_KEY_MODAL = "Regenerar llave",
  TITLE_MODAL_DELETE_CREDENTIAL = "¿Deseas eliminar esta credencial?",
  TITLE_MODAL_DEACTIVATED_CREDENTIAL = "¿Deseas desactivar esta credencial?",
  TITLE_MODAL_RENEW_CREDENTIAL = "¿Deseas renovar esta credencial?",
}

export const enum HeaderTableTextColumn {
  CREDENTIAL_ID = "ID credencial",
  PUBLIC_KEY = "Llave pública",
  PRIVATE_KEY = "Llave privada",
  STATE = "Estado",
  BRANCH_NAME = "Branch Name",
  ALIAS = "Alias",
}

export const enum SortCellPropertyColumn {
  BRANCH_NAME = "branchName",
  CREDENTIAL_ID = "credentialId",
  PUBLIC_KEY = "publicCredentialId",
  PRIVATE_KEY = "privateCredentialId",
  ALIAS = "alias",
}

export enum TagLabelsEnum {
  ENABLED = "HABILITADO",
  DISABLED = "DESHABILITADO",
}

export const CREDENTIALS_TABLE_CUSTOMER: HeaderCellProps[] = [
  {
    align: "left",
    spacing: 1,
    text: HeaderTableTextColumn.CREDENTIAL_ID,
    type: "default",
    width: 700,
  },
  {
    align: "left",
    spacing: 1,
    text: HeaderTableTextColumn.PUBLIC_KEY,
    type: "default",
    width: 700,
  },
  {
    align: "left",
    spacing: 1,
    text: HeaderTableTextColumn.PRIVATE_KEY,
    type: "default",
    width: 700,
  },
];

export const CREDENTIALS_TABLE_COLUMNS: HeaderCellProps[] = [
  {
    align: "left",
    spacing: 1,
    text: HeaderTableTextColumn.CREDENTIAL_ID,
    type: "default",
    width: 700,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTableTextColumn.PUBLIC_KEY,
    type: "default",
    width: 700,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTableTextColumn.PRIVATE_KEY,
    type: "default",
    width: 700,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTableTextColumn.STATE,
    type: "default",
    width: 500,
  },
  {
    align: "left",
    spacing: 0,
    text: "", // Used for item options, it does not need any column title
    type: "default",
    width: 0,
  },
];

export const CREDENTIALS_TABLE_COLUMNS_CUSTOMER = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): HeaderCellProps[] => [
  {
    actualSort,
    align: "left",
    field: SortCellPropertyColumn.BRANCH_NAME,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 1,
    text: HeaderTableTextColumn.BRANCH_NAME,
    type: "default",
    width: 700,
  },
  {
    actualSort,
    align: "left",
    field: SortCellPropertyColumn.ALIAS,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 1,
    text: HeaderTableTextColumn.ALIAS,
    type: "default",
    width: 700,
  },
  {
    actualSort,
    align: "left",
    field: SortCellPropertyColumn.CREDENTIAL_ID,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 1,
    text: HeaderTableTextColumn.CREDENTIAL_ID,
    type: "default",
    width: 700,
  },
  {
    actualSort,
    align: "left",
    field: SortCellPropertyColumn.PUBLIC_KEY,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableTextColumn.PUBLIC_KEY,
    type: "default",
    width: 700,
  },
  {
    actualSort,
    align: "left",
    field: SortCellPropertyColumn.PRIVATE_KEY,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableTextColumn.PRIVATE_KEY,
    type: "default",
    width: 700,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTableTextColumn.STATE,
    type: "default",
    width: 500,
  },
];

export const enum SortCellProp {
  BRANCH_NAME = "cells[0].props.line1",
  ALIAS = "cells[1].props.line1",
  CREDENTIAL_ID = "id",
  PUBLIC_KEY = "cells[2].props.line2",
  PRIVATE_KEY = "cells[3].props.line2",
}

export const CREDENTIALS_SORT_CATALOG = {
  [SortCellPropertyColumn.BRANCH_NAME]: SortCellProp.BRANCH_NAME,
  [SortCellPropertyColumn.ALIAS]: SortCellProp.ALIAS,
  [SortCellPropertyColumn.PUBLIC_KEY]: SortCellProp.PUBLIC_KEY,
  [SortCellPropertyColumn.PRIVATE_KEY]: SortCellProp.PRIVATE_KEY,
  [SortCellPropertyColumn.CREDENTIAL_ID]: SortCellProp.CREDENTIAL_ID,
};
