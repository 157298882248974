import { WritableDraft } from "immer/dist/internal";
import { IAppState } from "../interfaces/AppState.interfaces";
import { DEFAULT_LOADING_MODAL } from "../../shared/utils/initialStates";
import { LoadingModalMessages } from "../../shared/constants/labels/loading_modal_messages";

export const dismissLoader = (state: WritableDraft<IAppState>) => {
  state.pendingRequest -= 1;
  if (state.pendingRequest <= 0) state.loadingModal = DEFAULT_LOADING_MODAL;
};

export const showLoader = (
  state: WritableDraft<IAppState>,
  message: string = LoadingModalMessages.DEFAULT_MESSAGE
) => {
  state.loadingModal = { isLoading: true, message };
  state.pendingRequest += 1;
};
