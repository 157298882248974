/**
 * CatalogsEnum
 */
export enum ParameterizationBillingEnum {
  SALE = "SALE",
  DEFFERED = "DEFFERED",
  CHARGEBACK = "CHARGEBACK",
  REFUND = "REFUND",
  VOID = "VOID",
}
