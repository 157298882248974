import { format, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import EsLocale from "date-fns/locale/es";

const MONTHS_ABREVIATIONS_REGEX =
  /ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic/;

export const getDateFromString = (
  date: string
): { date: string; hour: string } => {
  const valuesDate: string[] = date.split("T");

  return {
    date: valuesDate[0],
    hour: valuesDate[1].replace("Z", ""),
  };
};

export const getDateFromNumber = (
  timeStamp: number
): { date: string; hour: string } => {
  return {
    date: format(timeStamp, "yyyy-MM-dd"),
    hour: format(timeStamp, "HH:mm:ss"),
  };
};

export const parseToEnUSDate = (date: string): string => {
  const parsedDateTime = parse(date, "MM/dd/yyyy HH:mm", new Date());

  return format(parsedDateTime, "yyyy-MM-dd'T'HH:mm");
};

export const parseToEsDate = (date: string) => {
  const parsedDateTime = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());

  return {
    date: format(parsedDateTime, "MM/dd/yyyy"),
    hour: format(parsedDateTime, "HH:mm"),
  };
};

export const formatTableDate = (
  date: string,
  timeZoneRegion: string
): string => {
  return format(utcToZonedTime(date, timeZoneRegion), "dd/MM/yyyy'T'HH:mm:ss");
};

export const getSimplifiedFormatedDate = (date: string): string => {
  return format(new Date(date), "dd MMM. yyyy HH:mm", {
    locale: EsLocale,
  }).replace(
    MONTHS_ABREVIATIONS_REGEX,
    (value) => `${value[0].toUpperCase()}${value.substring(1)}`
  );
};
