export enum CountryCodeEnum {
  EC = "EC",
  MX = "MX",
  CO = "CO",
  BR = "BR",
  AS = "AS",
  CL = "CL",
  PA = "PA",
  PE = "PE",
  SV = "SV",
  HN = "HN",
  GT = "GT",
  NI = "NI",
  CR = "CR",
}
