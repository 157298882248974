import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MerchantProcessorFetch } from "../../../shared/interfaces/MerchantProcessorFetch";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  DispersionConfigurationResponse,
  ItemWalletConfigInterface,
} from "../../../../types/dispersion_configuration";
import { ProcessorsTypeEnum } from "../../../shared/infrastructure/constants/ProcessorsEnum";
import { routes } from "../../../shared/infrastructure/constants/routes";

export interface IWalletConfigurationStateProps {
  merchantCountry: string;
  merchantId: string;
  cardProcessors?: MerchantProcessorFetch[];
  cashProcessors?: MerchantProcessorFetch[];
  transferProcessors?: MerchantProcessorFetch[];
  cashOutProcessors?: MerchantProcessorFetch[];
  transferOutProcessors?: MerchantProcessorFetch[];
  transferSubscriptionProcessors?: MerchantProcessorFetch[];
}

export interface IuseWalletConfiguration {
  enableConfiguration: boolean;
  itemWalletConfig: ItemWalletConfigInterface | undefined;
  walletRechargeConfig: DispersionConfigurationResponse | undefined;
  handlers: {
    goToConfiguration: () => void;
  };
}

export const useWalletConfiguration = ({
  merchantCountry,
  merchantId,
  cardProcessors = [],
  cashProcessors = [],
  transferProcessors = [],
  cashOutProcessors = [],
  transferOutProcessors = [],
  transferSubscriptionProcessors = [],
}: IWalletConfigurationStateProps): IuseWalletConfiguration => {
  const history = useHistory();
  const [enableConfiguration, setEnableConfiguration] = useState<boolean>(
    false
  );
  const walletRechargeConfig:
    | DispersionConfigurationResponse
    | undefined = useSelector((state: IAppState) => {
    return state.walletRechargeConfig;
  });

  const goToConfiguration = () => {
    history.push(`${routes.WALLET}/${merchantId}`);
  };

  const checkPayinAggregator = (
    processors: MerchantProcessorFetch[]
  ): boolean => {
    let hasPayinAggregator: boolean = false;
    processors.forEach((value: MerchantProcessorFetch) => {
      hasPayinAggregator =
        value.processorType === ProcessorsTypeEnum.AGGREGATOR;
    });
    return hasPayinAggregator;
  };

  useEffect(() => {
    if (
      [CountriesEnum.COLOMBIA, CountriesEnum.PERU].includes(
        merchantCountry as CountriesEnum
      )
    ) {
      const hasPayInAggregator: boolean =
        checkPayinAggregator(cardProcessors) ||
        checkPayinAggregator(cashProcessors) ||
        checkPayinAggregator(transferProcessors) ||
        checkPayinAggregator(transferSubscriptionProcessors);
      const hasPayOut: boolean =
        cashOutProcessors!.length + transferOutProcessors!.length > 0;

      setEnableConfiguration(hasPayInAggregator && hasPayOut);
    }
  });

  let itemWalletConfig: ItemWalletConfigInterface | undefined = undefined;
  if (walletRechargeConfig && walletRechargeConfig.walletConfig)
    walletRechargeConfig.walletConfig.forEach((item) => {
      if (item.merchantId === merchantId) itemWalletConfig = item;
    });

  return {
    enableConfiguration,
    walletRechargeConfig,
    itemWalletConfig,
    handlers: {
      goToConfiguration,
    },
  };
};
