import { createSlice } from "@reduxjs/toolkit";
import { WalletTransactionState } from "../../interfaces/walletTransaction.interfaces";
import { getTransactionsByMerchantId } from "../../thunks/walletTransaction/walletTransaction";

export const initialState: WalletTransactionState = {
  isLoadingTrx: false,
  limit: 10,
  offset: 0,
  page: 1,
  showDetailModal: false,
  transactions: { data: [], total: 0 },
};

export const walletTransactionSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsByMerchantId.fulfilled, (state, { payload }) => {
        state.transactions = payload;
        state.isLoadingTrx = false;
      })
      .addCase(getTransactionsByMerchantId.pending, (state) => {
        state.isLoadingTrx = true;
      })
      .addCase(getTransactionsByMerchantId.rejected, (state) => {
        state.isLoadingTrx = false;
      });
  },
  initialState,
  name: "walletTransaction",
  reducers: {
    setCurrentTransaction: (state, action) => {
      state.currentTransaction = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setOffset: (state, action) => {
      state.offset = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setShowDetailModal: (state, action) => {
      state.showDetailModal = action.payload;
    },
    setTrxRequest: (state, action) => {
      state.walletTrxRequest = action.payload;
    },
  },
});

export default walletTransactionSlice.reducer;
