import { B2CRefundTransaction } from "../../../../types/b2c_refund_transaction";

export const REFUND_PENDING_TRANSACTION_SAMPLE: B2CRefundTransaction = {
  amount: 29900.0,
  bank_name: "Santander",
  country: "Colombia",
  created: "2020-10-17T15:10:25.226Z",
  currency: "COP",
  customer_name: "Rogelio Perez",
  document_number: "721894631",
  id: "",
  merchant_name: "VehiScore",
  metadata: {
    accountNumber: "479469999458XXX",
    accountType: "ahorros",
    amount: 121.12,
    authorizationCode: 955202,
    currency: "COP",
    cusNumber: 721894631,
    date: "2020-06-05|12:46:05",
    documentNumber: 123456789,
    documentType: "CC",
    id: "",
    issuingBank: "banco-de-bogota",
    lastName: "gutierrez-villacorta",
    name: "fernando-sebastian",
    paymentMethod: "transferencia",
    plateNumber: "ABC-123",
    status: "Approval",
    ticketNumber: 1591379173365534,
    transaccionReference: "51922485cead412795c7cc67ca5d2221",
  },
  original_transactional_reference: "51922485cead412795c7cc67ca5d2232",
  payment_method: "transfer",
  processor_name: "",
  refund_retries: 0,
  status: "PENDING",
  transaction_reference: "51922485cead412795c7cc67ca5d2221",
};

export const REFUND_APPROVED_TRANSACTION_SAMPLE: B2CRefundTransaction = {
  amount: 29900.0,
  bank_name: "Santander",
  completedAt: "2022-02-15T11:56:22.226Z",
  country: "Colombia",
  created: "2020-10-17T15:10:25.226Z",
  currency: "COP",
  customer_name: "Rogelio Perez",
  document_number: "721894631",
  id: "",
  merchant_name: "VehiScore",
  metadata: {
    accountNumber: "479469999458XXX",
    accountType: "ahorros",
    amount: 121.12,
    authorizationCode: 955202,
    currency: "COP",
    cusNumber: 721894631,
    date: "2020-06-05|12:46:05",
    documentNumber: 123456789,
    documentType: "CC",
    issuingBank: "banco-de-bogota",
    lastName: "gutierrez-villacorta",
    name: "fernando-sebastian",
    paymentMethod: "transferencia",
    plateNumber: "ABC-123",
    status: "Approval",
    ticketNumber: 1591379173365534,
    transaccionReference: "51922485cead412795c7cc67ca5d2221",
  },
  original_transactional_reference: "51922485cead412795c7cc67ca5d2232",
  payment_method: "transfer",
  processor_name: "PSE",
  refund_retries: 0,
  status: "APPROVED",
  transaction_reference: "51922485cead412795c7cc67ca5d2221",
};

export const REFUND_SAMPLE_TRANSACTIONS: B2CRefundTransaction[] = [
  {
    amount: 29900.0,
    bank_name: "Santander",
    completedAt: "2022-02-15T11:56:22.226Z",
    country: "Colombia",
    created: "2020-10-17T15:10:25.226Z",
    currency: "COP",
    customer_name: "Rogelio Perez",
    document_number: "721894631",
    id: "",
    merchant_name: "VehiScore",
    metadata: {
      accountNumber: "479469999458XXX",
      accountType: "ahorros",
      amount: 121.12,
      authorizationCode: 955202,
      currency: "COP",
      cusNumber: 721894631,
      date: "2020-06-05|12:46:05",
      documentNumber: 123456789,
      documentType: "CC",
      issuingBank: "banco-de-bogota",
      lastName: "gutierrez-villacorta",
      name: "fernando-sebastian",
      paymentMethod: "transferencia",
      plateNumber: "ABC-123",
      status: "Approval",
      ticketNumber: 1591379173365534,
      transaccionReference: "51922485cead412795c7cc67ca5d2233",
    },
    original_transactional_reference: "51922485cead412795c7cc67ca5d2232",
    payment_method: "transfer",
    processor_name: "PSE",
    refund_retries: 0,
    status: "APPROVED",
    transaction_reference: "51922485cead412795c7cc67ca5d2233",
  },
  {
    amount: 29900.0,
    bank_name: "Santander",
    completedAt: "2022-02-15T11:56:22.226Z",
    country: "Colombia",
    created: "2020-10-17T15:10:25.226Z",
    currency: "COP",
    customer_name: "Rogelio Perez",
    document_number: "721894631",
    id: "",
    merchant_name: "VehiScore",
    metadata: {
      accountNumber: "479469999458XXX",
      accountType: "ahorros",
      amount: 121.12,
      authorizationCode: 955202,
      currency: "COP",
      cusNumber: 721894631,
      date: "2020-06-05|12:46:05",
      documentNumber: 123456789,
      documentType: "CC",
      issuingBank: "banco-de-bogota",
      lastName: "gutierrez-villacorta",
      name: "fernando-sebastian",
      paymentMethod: "transferencia",
      plateNumber: "ABC-123",
      status: "Approval",
      ticketNumber: 1591379173365534,
      transaccionReference: "51922485cead412795c7cc67ca5d2200",
    },
    original_transactional_reference: "51922485cead412795c7cc67ca5d2232",
    payment_method: "transfer",
    processor_name: "PSE",
    refund_retries: 0,
    status: "APPROVED",
    transaction_reference: "51922485cead412795c7cc67ca5d2200",
  },
  {
    amount: 29900.0,
    bank_name: "Santander",
    completedAt: "2022-02-15T11:56:22.226Z",
    country: "Colombia",
    created: "2020-10-17T15:10:25.226Z",
    currency: "COP",
    customer_name: "Rogelio Perez",
    document_number: "721894631",
    id: "",
    merchant_name: "VehiScore",
    metadata: {
      accountNumber: "479469999458XXX",
      accountType: "ahorros",
      amount: 121.12,
      authorizationCode: 955202,
      currency: "COP",
      cusNumber: 721894631,
      date: "2020-06-05|12:46:05",
      documentNumber: 123456789,
      documentType: "CC",
      issuingBank: "banco-de-bogota",
      lastName: "gutierrez-villacorta",
      name: "fernando-sebastian",
      paymentMethod: "transferencia",
      plateNumber: "ABC-123",
      status: "Approval",
      ticketNumber: 1591379173365534,
      transaccionReference: "51922485cead412795c7cc67ca5d2211",
    },
    original_transactional_reference: "51922485cead412795c7cc67ca5d2232",
    payment_method: "transfer",
    processor_name: "PSE",
    refund_retries: 0,
    status: "APPROVED",
    transaction_reference: "51922485cead412795c7cc67ca5d2211",
  },
];
