import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

interface BreadcrumItem {
  label: string;
  url: string;
}

export interface BreadcrumProps {
  items: BreadcrumItem[];
  lastItem: string;
}

const Breadcrumb = (props: BreadcrumProps) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        separator={
          <Typography color="textPrimary" style={{ fontSize: 18 }}>
            /
          </Typography>
        }
        aria-label="breadcrumb"
      >
        {props.items.map((item: BreadcrumItem) => (
          <Link key={item.label} component={RouterLink} to={item.url}>
            {item.label}
          </Link>
        ))}
        <Typography color="textPrimary">{props.lastItem}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default Breadcrumb;
