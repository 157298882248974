import { listBranchesSelected } from "../../../shared/utils/massiveBranchEdit";
import { useEffect, useState } from "react";
import { IBranchItem } from "../SelectedBranch.interfaces";
import { IUseSelectedBranchInterfaces } from "./IUseSelectedBranch.interfaces";
import { INDEX_PATTERN_MATCHING } from "../../../shared/constants/selected_branches";

export const useSelectedBranch = (): IUseSelectedBranchInterfaces => {
  const { listBranches } = listBranchesSelected();

  const [filterText, setFilter] = useState("");
  const [filtered, setFiltered] = useState(listBranches);

  const handleFilter = (valueToEval: string) => {
    setFilter(valueToEval);
  };

  useEffect(() => {
    setFiltered(
      listBranches.filter((branch: IBranchItem) => {
        return filterText.length > 0
          ? branch.name.toLowerCase().indexOf(filterText) >
              INDEX_PATTERN_MATCHING
          : true;
      })
    );
  }, [filterText]);

  return {
    filtered: filtered,
    handleFilter,
    totalBranches: listBranches.length,
  };
};
