import { BreadCrumsItems } from "../../../shared/infrastructure/constants/BreadCrums";
import {
  CountryRetentionEnum,
  CountryValueEnum,
} from "../../../shared/infrastructure/Enums/CountryEnum";
import { routes } from "../../../shared/infrastructure/routes";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect, useState } from "react";
import { KshFile } from "../../../components/common/FileUpload/FileUpload";
import { IFileUploadFormIndexState } from "./useFileUploadFormIndexEcuadorState.interfaces";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { RetentionEnum } from "../../../shared/infrastructure/Enums/RetentionEnum";
import {
  downloadCatalogTemplateWithData,
  downloadTemplateExampleCsv,
  setNotification,
} from "../../../store/actionCreators";
import {
  setErrorCsv,
  uploadRetentionCatalog,
} from "../../../store/actionCreators";
import { get } from "lodash";
import { UploadRetentionCatalogRequest } from "../../../../types/upload_retention_catalog_request";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const useFileUploadFormIndexEcuadorState = (): IFileUploadFormIndexState => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, templateXslxData } = useSelector(
    (state: IAppState) => state
  );
  const [files, setFiles] = useState<KshFile[] | undefined>(undefined);

  const breadCrumbs: BreadcrumProps = {
    items: [
      BreadCrumsItems.GlobalConfig,
      {
        ...BreadCrumsItems.Country,
        label: CountryValueEnum.Ecuador,
      },
      {
        label:
          BreadCrumsItems.RetentionsMerchant.label[CountryValueEnum.Ecuador],
        url: `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${CountryRetentionEnum.ecuador}`,
      },
    ],
    lastItem: BreadCrumsItems.UploadFileTextEcuador[CountryValueEnum.Ecuador],
  };
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const country: CountryValueEnum = query.get("country") as CountryValueEnum;

  const setFile = (file: KshFile, property: string): void => {
    const newFile = {
      id: property,
      name: file.name,
      base64: file.base64,
      extension: file.extension,
    };
    setFiles([newFile]);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
  const isErrorCsv: boolean =
    useSelector((state: IAppState) => state.errorCsv) || false;
  const [error, setError] = useState<boolean>(true);
  const errorCsvMessage: string =
    useSelector((state: IAppState) => state.errorCsvMessage) || "";
  const isFileSentCatalog: boolean =
    useSelector((state: IAppState) => state.fileSentCatalog) || false;
  const setOpenModalDetail = (): void => {
    returnWithoutSave();
  };

  const returnTo = (route: string): void => {
    history.push(route);
  };

  const setCloseModalDetail = (): void => {
    setOpenModal(false);
  };
  const returnWithoutSave = (): void => {
    setOpenModal(false);
    window.location.href = `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=ecuador`;
  };
  const handlerCaptureFile = (value: {
    prop: string;
    files?: KshFile;
  }): void => {
    if (value.files) {
      setFile(value.files, value.prop);
      setIsFileLoaded(true);
    }
  };
  const handlerDelete = (): void => {
    setFiles(undefined);
    setIsFileLoaded(false);
  };

  const onSubmit = (): void => {
    const retentionCatalogRequest: UploadRetentionCatalogRequest = {
      country: "EC",
      file: get(files![0], "base64", ""),
    };

    dispatch(uploadRetentionCatalog(retentionCatalogRequest));
  };
  const handleSetError = (payload: boolean): void => {
    setError(payload);
  };

  const downloadTemplateCsv = (): void => {
    dispatch(
      downloadTemplateExampleCsv(
        CountryRetentionEnum.ecuador,
        RetentionEnum.CATALOG.toUpperCase()
      )
    );
  };

  const downloadTemplateExcel = async (): Promise<void> => {
    dispatch(downloadCatalogTemplateWithData());
  };

  const handleOpenError = (): void => {
    setOpenError(true);
    setFiles(undefined);
    dispatch(setErrorCsv(false, ""));
  };

  const handleCloseError = (): void => {
    dispatch(setErrorCsv(false, ""));
    setOpenError(false);
  };

  // USE EFFECT
  useEffect(() => {
    if (isFileSentCatalog) {
      returnTo(
        `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=ecuador`
      );
      dispatch(
        setNotification({
          type: "success",
          message: "Archivo cargado exitosamente.",
          open: true,
        })
      );
    }
  }, [isFileSentCatalog]);

  useEffect(() => {
    if (isErrorCsv) setOpenError(true);
  }, [isErrorCsv]);

  useEffect(() => {
    if (country !== CountryValueEnum.Ecuador.toLocaleLowerCase())
      dispatch(
        setNotification({
          type: "error",
          message: "No disponible actualmente",
          open: true,
        })
      );
  }, []);

  return {
    openError,
    country,
    breadCrumbs,
    downloadTemplateCsv,
    downloadTemplateExcel,
    isFileSentCatalog,
    onSubmit,
    handleOpenError,
    errorCsvMessage,
    handleCloseError,
    stateFile: {
      isLoading: isLoading as boolean,
      error,
    },
    isFileLoaded,
    fileUploader: {
      files,
      handlerCaptureFile,
      handlerDelete,
      handleSetError,
    },
    isLoadingDownloadFile: templateXslxData!.isLoading,
    isLoadingDownloadFileData: templateXslxData!.isLoadingFileData,
    modalDetailProps: {
      openModal,
      setOpenModalDetail,
      setCloseModalDetail,
      returnWithoutSave: returnWithoutSave,
    },
  };
};
