import { RangeAmountTypeEnum } from "../enums/RangeAmountTypesEnum";

interface IRangeMenuItem {
  key: RangeAmountTypeEnum;
  label: string;
}

export const MENU_ITEMS: IRangeMenuItem[] = [
  {
    key: RangeAmountTypeEnum.MIN,
    label: "Mayor que",
  },
  {
    key: RangeAmountTypeEnum.MAX,
    label: "Menor que",
  },
  {
    key: RangeAmountTypeEnum.EQ,
    label: "Igual a",
  },
  {
    key: RangeAmountTypeEnum.RANGE,
    label: "Entre",
  },
];
