export enum routes {
  MERCHANTS = "/merchants",
  MERCHANT = "/merchant",
  MERCHANT_LIST = "/merchant-list",
  INDEX = "/config-merchant",
  BILLING = "/billing-merchant/invoice-configuration",
  CHARGE = "/billing-merchant/charge",
  CREATE_MERCHANT = "/create-merchant",
  RATES = "/rate",
  WEBCHECKOUT = "/webcheckout",
  BASIC_INFO = "/billing-merchant/invoice",
  WALLET = "/billing-merchant/wallet",
  MERCHANT_PROPERTIES = "/merchant-properties",
  PERSONALIZATION = "/config-merchant/personalization",
  SUPPORT_INFO = "/config-merchant/support-info",
  BANK_TRANSFER = "/config-merchant/bank-transfer",
  WEBHOOKS_NOTIFICATIONS = "/config-merchant/webhooks-notifications",
  LOCALIZATION = "/config-merchant/localization",
  PROCESSORS = "/config-merchant/processors",
  DEFERRED = "/deferred",
  MERCHANT_DEFERRED = "/merchant-deferred",
  SECURITY_RULES = "/config-merchant/security_rules",
  CREDENTIALS = "/config-merchant/credentials",
  DEVELOPERS_CREDENTIALS = "/credentials",
  WEBHOOKS = "/webhook",
  USERS_LIST = "/config-merchant/users-list",
  CREATE_USER = "/config-merchant/users-list",
  NOTIFICATIONS = "/webhook/notifications",
  TRANSACTION_NOTIFICATION = "/config-merchant/transaction-notification",
  ETPAY_CUSTOMIZATION = "/etpay-customization",
  PROCESSOR_LIST = "/processor-list?merchantId",
  PROCESSOR_CARD = "/processor-card",
  PROCESSOR_TRANSFER = "/processor-transfer",
  PROCESSOR_TRANSFER_SUBSCRIPTION = "/processor-transfer-subscriptions",
  PROCESSOR_CASH = "/processor-cash",
  PROCESSOR_PAYOUT_TRANSFER = "/processor-payouts-transfer",
  PROCESSOR_PAYOUT_CASH = "/processor-payouts-cash",
  ADD_USER = "/users/user",
  MERCHANT_CREDENTIALS = "/credentials",
  DEFERRED_CREATE = "/deferred/create",
  KFORMS = "/kforms-list",
  KFORM_EDIT = "/kform/edit",
  CONFIG_DEFERRED = "/merchant-deferred/create-deferred",
  MERCHANT_PEOPLE = "/merchant-people",
  WALLET_DASHBOARD = "/wallet-dashboard?publicMerchantId=",
  PROCESSOR_INTERNATIONAL_TRANSFER = "/processor-international-transfer",
  CONFIG_MERCHANT = "/config-merchant/merchant/",
}
