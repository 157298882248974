import { SvgIconProps, SvgIcon } from "@mui/material";
import React, { FC } from "react";

const FailedTransactionIcon: FC<SvgIconProps> = ({ sx, ...rest }) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ ...sx }}
    {...rest}
  >
    <rect width="32" height="32" rx="4" fill="#F6AEBB" fillOpacity="0.2" />
    <path
      d="M16 23.5C20.1421 23.5 23.5 20.1421 23.5 16C23.5 11.8579 20.1421 8.5 16 8.5C11.8579 8.5 8.5 11.8579 8.5 16C8.5 20.1421 11.8579 23.5 16 23.5Z"
      stroke="#AD0C2A"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M19 13L13 19"
      stroke="#AD0C2A"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 13L19 19"
      stroke="#AD0C2A"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default FailedTransactionIcon;
