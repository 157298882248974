import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { RedirectObject } from "../../shared/constants/processorConstants";
import { get, isUndefined } from "lodash";
import { LabelsEnum } from "../../shared/constants/LabelsEnum";
import { dialogStyles } from "./Dialog.styles";

export interface ProcessorDialogProps {
  open: boolean;
  onClose: () => void;
  handleRedirectNewProcessor: () => void;
  redirectObject: RedirectObject;
}

export const ProcessorDialog: React.FC<ProcessorDialogProps> = (
  props: ProcessorDialogProps
) => {
  return (
    <Dialog
      onClose={props.onClose}
      sx={dialogStyles.dialogProcessor}
      open={props.open}
    >
      <DialogContent>
        <Box mb={2}>
          <Grid container direction="row">
            <Grid>
              <Typography sx={dialogStyles.title}>
                {LabelsEnum.successLabel}
              </Typography>
            </Grid>

            {!isUndefined(get(props, "redirectObject.secondaryBodyDialog")) && (
              <Grid>
                <Box mb={2}>
                  <Typography sx={dialogStyles.subtitle}>
                    {props.redirectObject.secondaryBodyDialog}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} sx={dialogStyles.finishButton}>
          Finalizar
        </Button>
        <Button
          onClick={props.handleRedirectNewProcessor}
          sx={dialogStyles.actionButton}
          variant="contained"
          color="primary"
        >
          <Typography sx={dialogStyles.newProcessor}>
            {LabelsEnum.newProcessorLabel}
          </Typography>
        </Button>

        {!isUndefined(get(props, "redirectObject.nextStepButtonLabel")) && (
          <Button sx={dialogStyles.buttonPrincipal} onClick={props.onClose}>
            {props.redirectObject.nextStepButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
