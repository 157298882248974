/**
 * Catalog Peru
 * Catalog Peru
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogPe: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "DNI", value: "0" },
    { name: "Pasaporte", value: "1" },
    { name: "Ruc", value: "2" },
    { name: "Carnet Ext", value: "3" },
  ],
  [CatalogsEnum.Departments]: [
    { name: "DEPARTAMENTO AMAZONAS", value: "01" },
    { name: "DEPARTAMENTO ANCASH", value: "02" },
    { name: "DEPARTAMENTO APURIMAC", value: "03" },
    { name: "DEPARTAMENTO AREQUIPA", value: "04" },
    { name: "DEPARTAMENTO AYACUCHO", value: "05" },
    { name: "DEPARTAMENTO CAJAMARCA", value: "06" },
    { name: "PROV. CONST. DEL CALLAO", value: "07" },
    { name: "DEPARTAMENTO CUSCO", value: "08" },
    { name: "DEPARTAMENTO HUANCAVELICA", value: "09" },
    { name: "DEPARTAMENTO HUANUCO", value: "10" },
    { name: "DEPARTAMENTO ICA", value: "11" },
    { name: "DEPARTAMENTO JUNIN", value: "12" },
    { name: "DEPARTAMENTO LA LIBERTAD", value: "13" },
    { name: "DEPARTAMENTO LAMBAYEQUE", value: "14" },
    { name: "DEPARTAMENTO LIMA", value: "15" },
    { name: "DEPARTAMENTO LORETO", value: "16" },
    { name: "DEPARTAMENTO MADRE DE DIOS", value: "17" },
    { name: "DEPARTAMENTO MOQUEGUA", value: "18" },
    { name: "DEPARTAMENTO PASCO", value: "19" },
    { name: "DEPARTAMENTO PIURA", value: "20" },
    { name: "DEPARTAMENTO PUNO", value: "21" },
    { name: "DEPARTAMENTO SAN MARTIN", value: "22" },
    { name: "DEPARTAMENTO TACNA", value: "23" },
    { name: "DEPARTAMENTO TUMBES", value: "24" },
    { name: "DEPARTAMENTO UCAYALI", value: "25" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "CHACHAPOYAS", parent: "01", value: "0101" },
    { name: "BAGUA", parent: "01", value: "0102" },
    { name: "BONGARA", parent: "01", value: "0103" },
    { name: "CONDORCANQUI", parent: "01", value: "0104" },
    { name: "LUYA", parent: "01", value: "0105" },
    { name: "RODRIGUEZ DE MENDOZA", parent: "01", value: "0106" },
    { name: "UTCUBAMBA", parent: "01", value: "0107" },
    { name: "HUARAZ", parent: "02", value: "0201" },
    { name: "AIJA", parent: "02", value: "0202" },
    { name: "ANTONIO RAYMONDI", parent: "02", value: "0203" },
    { name: "ASUNCION", parent: "02", value: "0204" },
    { name: "BOLOGNESI", parent: "02", value: "0205" },
    { name: "CARHUAZ", parent: "02", value: "0206" },
    { name: "CARLOS FERMIN FITZCARRALD", parent: "02", value: "0207" },
    { name: "CASMA", parent: "02", value: "0208" },
    { name: "CORONGO", parent: "02", value: "0209" },
    { name: "HUARI", parent: "02", value: "0210" },
    { name: "HUARMEY", parent: "02", value: "0211" },
    { name: "HUAYLAS", parent: "02", value: "0212" },
    { name: "MARISCAL LUZURIAGA", parent: "02", value: "0213" },
    { name: "OCROS", parent: "02", value: "0214" },
    { name: "PALLASCA", parent: "02", value: "0215" },
    { name: "POMABAMBA", parent: "02", value: "0216" },
    { name: "RECUAY", parent: "02", value: "0217" },
    { name: "SANTA", parent: "02", value: "0218" },
    { name: "SIHUAS", parent: "02", value: "0219" },
    { name: "YUNGAY", parent: "02", value: "0220" },
    { name: "ABANCAY", parent: "03", value: "0301" },
    { name: "ANDAHUAYLAS", parent: "03", value: "0302" },
    { name: "ANTABAMBA", parent: "03", value: "0303" },
    { name: "AYMARAES", parent: "03", value: "0304" },
    { name: "COTABAMBAS", parent: "03", value: "0305" },
    { name: "CHINCHEROS", parent: "03", value: "0306" },
    { name: "GRAU", parent: "03", value: "0307" },
    { name: "AREQUIPA", parent: "04", value: "0401" },
    { name: "CAMANA", parent: "04", value: "0402" },
    { name: "CARAVELI", parent: "04", value: "0403" },
    { name: "CASTILLA", parent: "04", value: "0404" },
    { name: "CAYLLOMA", parent: "04", value: "0405" },
    { name: "CONDESUYOS", parent: "04", value: "0406" },
    { name: "ISLAY", parent: "04", value: "0407" },
    { name: "LA UNION", parent: "04", value: "0408" },
    { name: "HUAMANGA", parent: "05", value: "0501" },
    { name: "CANGALLO", parent: "05", value: "0502" },
    { name: "HUANCA SANCOS", parent: "05", value: "0503" },
    { name: "HUANTA", parent: "05", value: "0504" },
    { name: "LA MAR", parent: "05", value: "0505" },
    { name: "LUCANAS", parent: "05", value: "0506" },
    { name: "PARINACOCHAS", parent: "05", value: "0507" },
    { name: "PAUCAR DEL SARA SARA", parent: "05", value: "0508" },
    { name: "SUCRE", parent: "05", value: "0509" },
    { name: "VICTOR FAJARDO", parent: "05", value: "0510" },
    { name: "VILCAS HUAMAN", parent: "05", value: "0511" },
    { name: "CAJAMARCA", parent: "06", value: "0601" },
    { name: "CAJABAMBA", parent: "06", value: "0602" },
    { name: "CELENDIN", parent: "06", value: "0603" },
    { name: "CHOTA", parent: "06", value: "0604" },
    { name: "CONTUMAZA", parent: "06", value: "0605" },
    { name: "CUTERVO", parent: "06", value: "0606" },
    { name: "HUALGAYOC", parent: "06", value: "0607" },
    { name: "JAEN", parent: "06", value: "0608" },
    { name: "SAN IGNACIO", parent: "06", value: "0609" },
    { name: "SAN MARCOS", parent: "06", value: "0610" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SAN MIGUEL", parent: "06", value: "0611" },
    { name: "SAN PABLO", parent: "06", value: "0612" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SANTA CRUZ", parent: "06", value: "0613" },
    { name: "PROV. CONST. DEL CALLAO", parent: "07", value: "0701" },
    { name: "CUSCO", parent: "08", value: "0801" },
    { name: "ACOMAYO", parent: "08", value: "0802" },
    { name: "ANTA", parent: "08", value: "0803" },
    { name: "CALCA", parent: "08", value: "0804" },
    { name: "CANAS", parent: "08", value: "0805" },
    { name: "CANCHIS", parent: "08", value: "0806" },
    { name: "CHUMBIVILCAS", parent: "08", value: "0807" },
    { name: "ESPINAR", parent: "08", value: "0808" },
    { name: "LA CONVENCION", parent: "08", value: "0809" },
    { name: "PARURO", parent: "08", value: "0810" },
    { name: "PAUCARTAMBO", parent: "08", value: "0811" },
    { name: "QUISPICANCHI", parent: "08", value: "0812" },
    { name: "URUBAMBA", parent: "08", value: "0813" },
    { name: "HUANCAVELICA", parent: "09", value: "0901" },
    { name: "ACOBAMBA", parent: "09", value: "0902" },
    { name: "ANGARAES", parent: "09", value: "0903" },
    { name: "CASTROVIRREYNA", parent: "09", value: "0904" },
    { name: "CHURCAMPA", parent: "09", value: "0905" },
    { name: "HUAYTARA", parent: "09", value: "0906" },
    { name: "TAYACAJA", parent: "09", value: "0907" },
    { name: "HUANUCO", parent: "10", value: "1001" },
    { name: "AMBO", parent: "10", value: "1002" },
    { name: "DOS DE MAYO", parent: "10", value: "1003" },
    { name: "HUACAYBAMBA", parent: "10", value: "1004" },
    { name: "HUAMALIES", parent: "10", value: "1005" },
    { name: "LEONCIO PRADO", parent: "10", value: "1006" },
    { name: "MARAÑON", parent: "10", value: "1007" },
    { name: "PACHITEA", parent: "10", value: "1008" },
    { name: "PUERTO INCA", parent: "10", value: "1009" },
    { name: "LAURICOCHA", parent: "10", value: "1010" },
    { name: "YAROWILCA", parent: "10", value: "1011" },
    { name: "ICA", parent: "11", value: "1101" },
    { name: "CHINCHA", parent: "11", value: "1102" },
    { name: "NAZCA", parent: "11", value: "1103" },
    { name: "PALPA", parent: "11", value: "1104" },
    { name: "PISCO", parent: "11", value: "1105" },
    { name: "HUANCAYO", parent: "12", value: "1201" },
    { name: "CONCEPCION", parent: "12", value: "1202" },
    { name: "CHANCHAMAYO", parent: "12", value: "1203" },
    { name: "JAUJA", parent: "12", value: "1204" },
    { name: "JUNIN", parent: "12", value: "1205" },
    { name: "SATIPO", parent: "12", value: "1206" },
    { name: "TARMA", parent: "12", value: "1207" },
    { name: "YAULI", parent: "12", value: "1208" },
    { name: "CHUPACA", parent: "12", value: "1209" },
    { name: "TRUJILLO", parent: "13", value: "1301" },
    { name: "ASCOPE", parent: "13", value: "1302" },
    { name: "BOLIVAR", parent: "13", value: "1303" },
    { name: "CHEPEN", parent: "13", value: "1304" },
    { name: "JULCAN", parent: "13", value: "1305" },
    { name: "OTUZCO", parent: "13", value: "1306" },
    { name: "PACASMAYO", parent: "13", value: "1307" },
    { name: "PATAZ", parent: "13", value: "1308" },
    { name: "SANCHEZ CARRION", parent: "13", value: "1309" },
    { name: "SANTIAGO DE CHUCO", parent: "13", value: "1310" },
    { name: "GRAN CHIMU", parent: "13", value: "1311" },
    { name: "VIRU", parent: "13", value: "1312" },
    { name: "CHICLAYO", parent: "14", value: "1401" },
    { name: "FERREÑAFE", parent: "14", value: "1402" },
    { name: "LAMBAYEQUE", parent: "14", value: "1403" },
    { name: "LIMA", parent: "15", value: "1501" },
    { name: "BARRANCA", parent: "15", value: "1502" },
    { name: "CAJATAMBO", parent: "15", value: "1503" },
    { name: "CANTA", parent: "15", value: "1504" },
    { name: "CAÑETE", parent: "15", value: "1505" },
    { name: "HUARAL", parent: "15", value: "1506" },
    { name: "HUAROCHIRI", parent: "15", value: "1507" },
    { name: "HUAURA", parent: "15", value: "1508" },
    { name: "OYON", parent: "15", value: "1509" },
    { name: "YAUYOS", parent: "15", value: "1510" },
    { name: "MAYNAS", parent: "16", value: "1601" },
    { name: "ALTO AMAZONAS", parent: "16", value: "1602" },
    { name: "LORETO", parent: "16", value: "1603" },
    { name: "MARISCAL RAMON CASTILLA", parent: "16", value: "1604" },
    { name: "REQUENA", parent: "16", value: "1605" },
    { name: "UCAYALI", parent: "16", value: "1606" },
    { name: "DATEM DEL MARAÑON", parent: "16", value: "1607" },
    { name: "TAMBOPATA", parent: "17", value: "1701" },
    { name: "MANU", parent: "17", value: "1702" },
    { name: "TAHUAMANU", parent: "17", value: "1703" },
    { name: "MARISCAL NIETO", parent: "18", value: "1801" },
    { name: "GENERAL SANCHEZ CERRO", parent: "18", value: "1802" },
    { name: "ILO", parent: "18", value: "1803" },
    { name: "PASCO", parent: "19", value: "1901" },
    { name: "DANIEL ALCIDES CARRION", parent: "19", value: "1902" },
    { name: "OXAPAMPA", parent: "19", value: "1903" },
    { name: "PIURA", parent: "20", value: "2001" },
    { name: "AYABACA", parent: "20", value: "2002" },
    { name: "HUANCABAMBA", parent: "20", value: "2003" },
    { name: "MORROPON", parent: "20", value: "2004" },
    { name: "PAITA", parent: "20", value: "2005" },
    { name: "SULLANA", parent: "20", value: "2006" },
    { name: "TALARA", parent: "20", value: "2007" },
    { name: "SECHURA", parent: "20", value: "2008" },
    { name: "PUNO", parent: "21", value: "2101" },
    { name: "AZANGARO", parent: "21", value: "2102" },
    { name: "CARABAYA", parent: "21", value: "2103" },
    { name: "CHUCUITO", parent: "21", value: "2104" },
    { name: "EL COLLAO", parent: "21", value: "2105" },
    { name: "HUANCANE", parent: "21", value: "2106" },
    { name: "LAMPA", parent: "21", value: "2107" },
    { name: "MELGAR", parent: "21", value: "2108" },
    { name: "MOHO", parent: "21", value: "2109" },
    { name: "SAN ANTONIO DE PUTINA", parent: "21", value: "2110" },
    { name: "SAN ROMAN", parent: "21", value: "2111" },
    { name: "SANDIA", parent: "21", value: "2112" },
    { name: "YUNGUYO", parent: "21", value: "2113" },
    { name: "MOYOBAMBA", parent: "22", value: "2201" },
    { name: "BELLAVISTA", parent: "22", value: "2202" },
    { name: "EL DORADO", parent: "22", value: "2203" },
    { name: "HUALLAGA", parent: "22", value: "2204" },
    { name: "LAMAS", parent: "22", value: "2205" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "MARISCAL CACERES", parent: "22", value: "2206" },
    { name: "PICOTA", parent: "22", value: "2207" },
    { name: "RIOJA", parent: "22", value: "2208" },
    { name: "SAN MARTIN", parent: "22", value: "2209" },
    { name: "TOCACHE", parent: "22", value: "2210" },
    { name: "TACNA", parent: "23", value: "2301" },
    { name: "CANDARAVE", parent: "23", value: "2302" },
    { name: "JORGE BASADRE", parent: "23", value: "2303" },
    { name: "TARATA", parent: "23", value: "2304" },
    { name: "TUMBES", parent: "24", value: "2401" },
    { name: "CONTRALMIRANTE VILLAR", parent: "24", value: "2402" },
    { name: "ZARUMILLA", parent: "24", value: "2403" },
    { name: "CORONEL PORTILLO", parent: "25", value: "2501" },
    { name: "ATALAYA", parent: "25", value: "2502" },
    { name: "PADRE ABAD", parent: "25", value: "2503" },
    { name: "PURUS", parent: "25", value: "2504" },
  ],
  [CatalogsEnum.Cities]: [
    { name: "CHACHAPOYAS", parent: "0101", value: "010101" },
    { name: "ASUNCION", parent: "0101", value: "010102" },
    { name: "BALSAS", parent: "0101", value: "010103" },
    { name: "CHETO", parent: "0101", value: "010104" },
    { name: "CHILIQUIN", parent: "0101", value: "010105" },
    { name: "CHUQUIBAMBA", parent: "0101", value: "010106" },
    { name: "GRANADA", parent: "0101", value: "010107" },
    { name: "HUANCAS", parent: "0101", value: "010108" },
    { name: "LA JALCA", parent: "0101", value: "010109" },
    { name: "LEIMEBAMBA", parent: "0101", value: "010110" },
    { name: "LEVANTO", parent: "0101", value: "010111" },
    { name: "MAGDALENA", parent: "0101", value: "010112" },
    { name: "MARISCAL CASTILLA", parent: "0101", value: "010113" },
    { name: "MOLINOPAMPA", parent: "0101", value: "010114" },
    { name: "MONTEVIDEO", parent: "0101", value: "010115" },
    { name: "OLLEROS", parent: "0101", value: "010116" },
    { name: "QUINJALCA", parent: "0101", value: "010117" },
    { name: "SAN FRANCISCO DE DAGUAS", parent: "0101", value: "010118" },
    { name: "SAN ISIDRO DE MAINO", parent: "0101", value: "010119" },
    { name: "SOLOCO", parent: "0101", value: "010120" },
    { name: "SONCHE", parent: "0101", value: "010121" },
    { name: "BAGUA", parent: "0102", value: "010201" },
    { name: "ARAMANGO", parent: "0102", value: "010202" },
    { name: "COPALLIN", parent: "0102", value: "010203" },
    { name: "EL PARCO", parent: "0102", value: "010204" },
    { name: "IMAZA", parent: "0102", value: "010205" },
    { name: "LA PECA", parent: "0102", value: "010206" },
    { name: "JUMBILLA", parent: "0103", value: "010301" },
    { name: "CHISQUILLA", parent: "0103", value: "010302" },
    { name: "CHURUJA", parent: "0103", value: "010303" },
    { name: "COROSHA", parent: "0103", value: "010304" },
    { name: "CUISPES", parent: "0103", value: "010305" },
    { name: "FLORIDA", parent: "0103", value: "010306" },
    { name: "JAZAN", parent: "0103", value: "010307" },
    { name: "RECTA", parent: "0103", value: "010308" },
    { name: "SAN CARLOS", parent: "0103", value: "010309" },
    { name: "SHIPASBAMBA", parent: "0103", value: "010310" },
    { name: "VALERA", parent: "0103", value: "010311" },
    { name: "YAMBRASBAMBA", parent: "0103", value: "010312" },
    { name: "NIEVA", parent: "0104", value: "010401" },
    { name: "EL CENEPA", parent: "0104", value: "010402" },
    { name: "RIO SANTIAGO", parent: "0104", value: "010403" },
    { name: "LAMUD", parent: "0105", value: "010501" },
    { name: "CAMPORREDONDO", parent: "0105", value: "010502" },
    { name: "COCABAMBA", parent: "0105", value: "010503" },
    { name: "COLCAMAR", parent: "0105", value: "010504" },
    { name: "CONILA", parent: "0105", value: "010505" },
    { name: "INGUILPATA", parent: "0105", value: "010506" },
    { name: "LONGUITA", parent: "0105", value: "010507" },
    { name: "LONYA CHICO", parent: "0105", value: "010508" },
    { name: "LUYA", parent: "0105", value: "010509" },
    { name: "LUYA VIEJO", parent: "0105", value: "010510" },
    { name: "MARIA", parent: "0105", value: "010511" },
    { name: "OCALLI", parent: "0105", value: "010512" },
    { name: "OCUMAL", parent: "0105", value: "010513" },
    { name: "PISUQUIA", parent: "0105", value: "010514" },
    { name: "PROVIDENCIA", parent: "0105", value: "010515" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "SAN CRISTOBAL", parent: "0105", value: "010516" },
    { name: "SAN FRANCISCO DEL YESO", parent: "0105", value: "010517" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "SAN JERONIMO", parent: "0105", value: "010518" },
    { name: "SAN JUAN DE LOPECANCHA", parent: "0105", value: "010519" },
    { name: "SANTA CATALINA", parent: "0105", value: "010520" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "SANTO TOMAS", parent: "0105", value: "010521" },
    { name: "TINGO", parent: "0105", value: "010522" },
    { name: "TRITA", parent: "0105", value: "010523" },
    { name: "SAN NICOLAS", parent: "0106", value: "010601" },
    { name: "CHIRIMOTO", parent: "0106", value: "010602" },
    { name: "COCHAMAL", parent: "0106", value: "010603" },
    { name: "HUAMBO", parent: "0106", value: "010604" },
    { name: "LIMABAMBA", parent: "0106", value: "010605" },
    { name: "LONGAR", parent: "0106", value: "010606" },
    { name: "MARISCAL BENAVIDES", parent: "0106", value: "010607" },
    { name: "MILPUC", parent: "0106", value: "010608" },
    { name: "OMIA", parent: "0106", value: "010609" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "SANTA ROSA", parent: "0106", value: "010610" },
    { name: "TOTORA", parent: "0106", value: "010611" },
    { name: "VISTA ALEGRE", parent: "0106", value: "010612" },
    { name: "BAGUA GRANDE", parent: "0107", value: "010701" },
    { name: "CAJARURO", parent: "0107", value: "010702" },
    { name: "CUMBA", parent: "0107", value: "010703" },
    { name: "EL MILAGRO", parent: "0107", value: "010704" },
    { name: "JAMALCA", parent: "0107", value: "010705" },
    { name: "LONYA GRANDE", parent: "0107", value: "010706" },
    { name: "YAMON", parent: "0107", value: "010707" },
    { name: "HUARAZ", parent: "0201", value: "020101" },
    { name: "COCHABAMBA", parent: "0201", value: "020102" },
    { name: "COLCABAMBA", parent: "0201", value: "020103" },
    { name: "HUANCHAY", parent: "0201", value: "020104" },
    { name: "INDEPENDENCIA", parent: "0201", value: "020105" },
    { name: "JANGAS", parent: "0201", value: "020106" },
    { name: "LA LIBERTAD", parent: "0201", value: "020107" },
    { name: "OLLEROS", parent: "0201", value: "020108" },
    { name: "PAMPAS", parent: "0201", value: "020109" },
    { name: "PARIACOTO", parent: "0201", value: "020110" },
    { name: "PIRA", parent: "0201", value: "020111" },
    { name: "TARICA", parent: "0201", value: "020112" },
    { name: "AIJA", parent: "0202", value: "020201" },
    { name: "CORIS", parent: "0202", value: "020202" },
    { name: "HUACLLAN", parent: "0202", value: "020203" },
    { name: "LA MERCED", parent: "0202", value: "020204" },
    { name: "SUCCHA", parent: "0202", value: "020205" },
    { name: "LLAMELLIN", parent: "0203", value: "020301" },
    { name: "ACZO", parent: "0203", value: "020302" },
    { name: "CHACCHO", parent: "0203", value: "020303" },
    { name: "CHINGAS", parent: "0203", value: "020304" },
    { name: "MIRGAS", parent: "0203", value: "020305" },
    { name: "SAN JUAN DE RONTOY", parent: "0203", value: "020306" },
    { name: "CHACAS", parent: "0204", value: "020401" },
    { name: "ACOCHACA", parent: "0204", value: "020402" },
    { name: "CHIQUIAN", parent: "0205", value: "020501" },
    { name: "ABELARDO PARDO LEZAMETA", parent: "0205", value: "020502" },
    { name: "ANTONIO RAYMONDI", parent: "0205", value: "020503" },
    { name: "AQUIA", parent: "0205", value: "020504" },
    { name: "CAJACAY", parent: "0205", value: "020505" },
    { name: "CANIS", parent: "0205", value: "020506" },
    { name: "COLQUIOC", parent: "0205", value: "020507" },
    { name: "HUALLANCA", parent: "0205", value: "020508" },
    { name: "HUASTA", parent: "0205", value: "020509" },
    { name: "HUAYLLACAYAN", parent: "0205", value: "020510" },
    { name: "LA PRIMAVERA", parent: "0205", value: "020511" },
    { name: "MANGAS", parent: "0205", value: "020512" },
    { name: "PACLLON", parent: "0205", value: "020513" },
    { name: "SAN MIGUEL DE CORPANQUI", parent: "0205", value: "020514" },
    { name: "TICLLOS", parent: "0205", value: "020515" },
    { name: "CARHUAZ", parent: "0206", value: "020601" },
    { name: "ACOPAMPA", parent: "0206", value: "020602" },
    { name: "AMASHCA", parent: "0206", value: "020603" },
    { name: "ANTA", parent: "0206", value: "020604" },
    { name: "ATAQUERO", parent: "0206", value: "020605" },
    { name: "MARCARA", parent: "0206", value: "020606" },
    { name: "PARIAHUANCA", parent: "0206", value: "020607" },
    { name: "SAN MIGUEL DE ACO", parent: "0206", value: "020608" },
    { name: "SHILLA", parent: "0206", value: "020609" },
    { name: "TINCO", parent: "0206", value: "020610" },
    { name: "YUNGAR", parent: "0206", value: "020611" },
    { name: "SAN LUIS", parent: "0207", value: "020701" },
    { name: "SAN NICOLAS", parent: "0207", value: "020702" },
    { name: "YAUYA", parent: "0207", value: "020703" },
    { name: "CASMA", parent: "0208", value: "020801" },
    { name: "BUENA VISTA ALTA", parent: "0208", value: "020802" },
    { name: "COMANDANTE NOEL", parent: "0208", value: "020803" },
    { name: "YAUTAN", parent: "0208", value: "020804" },
    { name: "CORONGO", parent: "0209", value: "020901" },
    { name: "ACO", parent: "0209", value: "020902" },
    { name: "BAMBAS", parent: "0209", value: "020903" },
    { name: "CUSCA", parent: "0209", value: "020904" },
    { name: "LA PAMPA", parent: "0209", value: "020905" },
    { name: "YANAC", parent: "0209", value: "020906" },
    { name: "YUPAN", parent: "0209", value: "020907" },
    { name: "UARI", parent: "0210", value: "021001" },
    { name: "ANRA", parent: "0210", value: "021002" },
    { name: "CAJAY", parent: "0210", value: "021003" },
    { name: "CHAVIN DE HUANTAR", parent: "0210", value: "021004" },
    { name: "HUACACHI", parent: "0210", value: "021005" },
    { name: "HUACCHIS", parent: "0210", value: "021006" },
    { name: "HUACHIS", parent: "0210", value: "021007" },
    { name: "HUANTAR", parent: "0210", value: "021008" },
    { name: "MASIN", parent: "0210", value: "021009" },
    { name: "PAUCAS", parent: "0210", value: "021010" },
    { name: "PONTO", parent: "0210", value: "021011" },
    { name: "RAHUAPAMPA", parent: "0210", value: "021012" },
    { name: "RAPAYAN", parent: "0210", value: "021013" },
    { name: "SAN MARCOS", parent: "0210", value: "021014" },
    { name: "SAN PEDRO DE CHANA", parent: "0210", value: "021015" },
    { name: "UCO", parent: "0210", value: "021016" },
    { name: "HUARMEY", parent: "0211", value: "021101" },
    { name: "COCHAPETI", parent: "0211", value: "021102" },
    { name: "CULEBRAS", parent: "0211", value: "021103" },
    { name: "HUAYAN", parent: "0211", value: "021104" },
    { name: "MALVAS", parent: "0211", value: "021105" },
    { name: "CARAZ", parent: "0212", value: "021201" },
    { name: "HUALLANCA", parent: "0212", value: "021202" },
    { name: "HUATA", parent: "0212", value: "021203" },
    { name: "HUAYLAS", parent: "0212", value: "021204" },
    { name: "MATO", parent: "0212", value: "021205" },
    { name: "PAMPAROMAS", parent: "0212", value: "021206" },
    { name: "PUEBLO LIBRE / 1", parent: "0212", value: "021207" },
    { name: "SANTA CRUZ", parent: "0212", value: "021208" },
    { name: "SANTO TORIBIO", parent: "0212", value: "021209" },
    { name: "YURACMARCA", parent: "0212", value: "021210" },
    { name: "PISCOBAMBA", parent: "0213", value: "021301" },
    { name: "CASCA", parent: "0213", value: "021302" },
    { name: "ELEAZAR GUZMAN BARRON", parent: "0213", value: "021303" },
    { name: "FIDEL OLIVAS ESCUDERO", parent: "0213", value: "021304" },
    { name: "LLAMA", parent: "0213", value: "021305" },
    { name: "LLUMPA", parent: "0213", value: "021306" },
    { name: "LUCMA", parent: "0213", value: "021307" },
    { name: "MUSGA", parent: "0213", value: "021308" },
    { name: "OCROS", parent: "0214", value: "021401" },
    { name: "ACAS", parent: "0214", value: "021402" },
    { name: "CAJAMARQUILLA", parent: "0214", value: "021403" },
    { name: "CARHUAPAMPA", parent: "0214", value: "021404" },
    { name: "COCHAS", parent: "0214", value: "021405" },
    { name: "CONGAS", parent: "0214", value: "021406" },
    { name: "LLIPA", parent: "0214", value: "021407" },
    { name: "SAN CRISTOBAL DE RAJAN", parent: "0214", value: "021408" },
    { name: "SAN PEDRO", parent: "0214", value: "021409" },
    { name: "SANTIAGO DE CHILCAS", parent: "0214", value: "021410" },
    { name: "CABANA", parent: "0215", value: "021501" },
    { name: "BOLOGNESI", parent: "0215", value: "021502" },
    { name: "CONCHUCOS", parent: "0215", value: "021503" },
    { name: "HUACASCHUQUE", parent: "0215", value: "021504" },
    { name: "HUANDOVAL", parent: "0215", value: "021505" },
    { name: "LACABAMBA", parent: "0215", value: "021506" },
    { name: "LLAPO", parent: "0215", value: "021507" },
    { name: "PALLASCA", parent: "0215", value: "021508" },
    { name: "PAMPAS", parent: "0215", value: "021509" },
    { name: "SANTA ROSA", parent: "0215", value: "021510" },
    { name: "TAUCA", parent: "0215", value: "021511" },
    { name: "POMABAMBA", parent: "0216", value: "021601" },
    { name: "HUAYLLAN", parent: "0216", value: "021602" },
    { name: "PAROBAMBA", parent: "0216", value: "021603" },
    { name: "QUINUABAMBA", parent: "0216", value: "021604" },
    { name: "RECUAY", parent: "0217", value: "021701" },
    { name: "CATAC", parent: "0217", value: "021702" },
    { name: "COTAPARACO", parent: "0217", value: "021703" },
    { name: "HUAYLLAPAMPA", parent: "0217", value: "021704" },
    { name: "LLACLLIN", parent: "0217", value: "021705" },
    { name: "MARCA", parent: "0217", value: "021706" },
    { name: "PAMPAS CHICO", parent: "0217", value: "021707" },
    { name: "PARARIN", parent: "0217", value: "021708" },
    { name: "TAPACOCHA", parent: "0217", value: "021709" },
    { name: "TICAPAMPA", parent: "0217", value: "021710" },
    { name: "CHIMBOTE", parent: "0218", value: "021801" },
    { name: "CACERES DEL PERU", parent: "0218", value: "021802" },
    { name: "COISHCO", parent: "0218", value: "021803" },
    { name: "MACATE", parent: "0218", value: "021804" },
    { name: "MORO", parent: "0218", value: "021805" },
    { name: "NEPEÑA", parent: "0218", value: "021806" },
    { name: "SAMANCO", parent: "0218", value: "021807" },
    { name: "SANTA", parent: "0218", value: "021808" },
    { name: "NUEVO CHIMBOTE", parent: "0218", value: "021809" },
    { name: "SIHUAS", parent: "0219", value: "021901" },
    { name: "ACOBAMBA", parent: "0219", value: "021902" },
    { name: "ALFONSO UGARTE", parent: "0219", value: "021903" },
    { name: "CASHAPAMPA", parent: "0219", value: "021904" },
    { name: "CHINGALPO", parent: "0219", value: "021905" },
    { name: "HUAYLLABAMBA", parent: "0219", value: "021906" },
    { name: "QUICHES", parent: "0219", value: "021907" },
    { name: "RAGASH", parent: "0219", value: "021908" },
    { name: "SAN JUAN", parent: "0219", value: "021909" },
    { name: "SICSIBAMBA", parent: "0219", value: "021910" },
    { name: "YUNGAY", parent: "0220", value: "022001" },
    { name: "CASCAPARA", parent: "0220", value: "022002" },
    { name: "MANCOS", parent: "0220", value: "022003" },
    { name: "MATACOTO", parent: "0220", value: "022004" },
    { name: "QUILLO", parent: "0220", value: "022005" },
    { name: "RANRAHIRCA", parent: "0220", value: "022006" },
    { name: "SHUPLUY", parent: "0220", value: "022007" },
    { name: "YANAMA", parent: "0220", value: "022008" },
    { name: "ABANCAY", parent: "0301", value: "030101" },
    { name: "CHACOCHE", parent: "0301", value: "030102" },
    { name: "CIRCA", parent: "0301", value: "030103" },
    { name: "CURAHUASI", parent: "0301", value: "030104" },
    { name: "HUANIPACA", parent: "0301", value: "030105" },
    { name: "LAMBRAMA", parent: "0301", value: "030106" },
    { name: "PICHIRHUA", parent: "0301", value: "030107" },
    { name: "SAN PEDRO DE CACHORA", parent: "0301", value: "030108" },
    { name: "TAMBURCO", parent: "0301", value: "030109" },
    { name: "ANDAHUAYLAS", parent: "0302", value: "030201" },
    { name: "ANDARAPA", parent: "0302", value: "030202" },
    { name: "CHIARA", parent: "0302", value: "030203" },
    { name: "HUANCARAMA", parent: "0302", value: "030204" },
    { name: "HUANCARAY", parent: "0302", value: "030205" },
    { name: "HUAYANA", parent: "0302", value: "030206" },
    { name: "KISHUARA", parent: "0302", value: "030207" },
    { name: "PACOBAMBA", parent: "0302", value: "030208" },
    { name: "PACUCHA", parent: "0302", value: "030209" },
    { name: "PAMPACHIRI", parent: "0302", value: "030210" },
    { name: "POMACOCHA", parent: "0302", value: "030211" },
    { name: "SAN ANTONIO DE CACHI", parent: "0302", value: "030212" },
    { name: "SAN JERONIMO", parent: "0302", value: "030213" },
    { name: "SAN MIGUEL DE CHACCRAMPA", parent: "0302", value: "030214" },
    { name: "SANTA MARIA DE CHICMO", parent: "0302", value: "030215" },
    { name: "TALAVERA", parent: "0302", value: "030216" },
    { name: "TUMAY HUARACA", parent: "0302", value: "030217" },
    { name: "TURPO", parent: "0302", value: "030218" },
    { name: "KAQUIABAMBA", parent: "0302", value: "030219" },
    { name: "ANTABAMBA", parent: "0303", value: "030301" },
    { name: "EL ORO", parent: "0303", value: "030302" },
    { name: "HUAQUIRCA", parent: "0303", value: "030303" },
    { name: "JUAN ESPINOZA MEDRANO", parent: "0303", value: "030304" },
    { name: "OROPESA", parent: "0303", value: "030305" },
    { name: "PACHACONAS", parent: "0303", value: "030306" },
    { name: "SABAINO", parent: "0303", value: "030307" },
    { name: "CHALHUANCA", parent: "0304", value: "030401" },
    { name: "CAPAYA", parent: "0304", value: "030402" },
    { name: "CARAYBAMBA", parent: "0304", value: "030403" },
    { name: "CHAPIMARCA", parent: "0304", value: "030404" },
    { name: "COLCABAMBA", parent: "0304", value: "030405" },
    { name: "COTARUSE", parent: "0304", value: "030406" },
    { name: "HUAYLLO", parent: "0304", value: "030407" },
    { name: "JUSTO APU SAHUARAURA", parent: "0304", value: "030408" },
    { name: "LUCRE", parent: "0304", value: "030409" },
    { name: "POCOHUANCA", parent: "0304", value: "030410" },
    { name: "SAN JUAN DE CHACÑA", parent: "0304", value: "030411" },
    { name: "SAÑAYCA", parent: "0304", value: "030412" },
    { name: "SORAYA", parent: "0304", value: "030413" },
    { name: "TAPAIRIHUA", parent: "0304", value: "030414" },
    { name: "TINTAY", parent: "0304", value: "030415" },
    { name: "TORAYA", parent: "0304", value: "030416" },
    { name: "YANACA", parent: "0304", value: "030417" },
    { name: "TAMBOBAMBA", parent: "0305", value: "030501" },
    { name: "COTABAMBAS", parent: "0305", value: "030502" },
    { name: "COYLLURQUI", parent: "0305", value: "030503" },
    { name: "HAQUIRA", parent: "0305", value: "030504" },
    { name: "MARA", parent: "0305", value: "030505" },
    { name: "CHALLHUAHUACHO", parent: "0305", value: "030506" },
    { name: "CHINCHEROS", parent: "0306", value: "030601" },
    { name: "ANCO-HUALLO", parent: "0306", value: "030602" },
    { name: "COCHARCAS", parent: "0306", value: "030603" },
    { name: "HUACCANA", parent: "0306", value: "030604" },
    { name: "OCOBAMBA", parent: "0306", value: "030605" },
    { name: "ONGOY", parent: "0306", value: "030606" },
    { name: "URANMARCA", parent: "0306", value: "030607" },
    { name: "RANRACANCHA", parent: "0306", value: "030608" },
    { name: "CHUQUIBAMBILLA", parent: "0307", value: "030701" },
    { name: "CURPAHUASI", parent: "0307", value: "030702" },
    { name: "GAMARRA", parent: "0307", value: "030703" },
    { name: "HUAYLLATI", parent: "0307", value: "030704" },
    { name: "MAMARA", parent: "0307", value: "030705" },
    { name: "MICAELA BASTIDAS", parent: "0307", value: "030706" },
    { name: "PATAYPAMPA", parent: "0307", value: "030707" },
    { name: "PROGRESO", parent: "0307", value: "030708" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "SAN ANTONIO", parent: "0307", value: "030709" },
    { name: "SANTA ROSA", parent: "0307", value: "030710" },
    { name: "TURPAY", parent: "0307", value: "030711" },
    { name: "VILCABAMBA", parent: "0307", value: "030712" },
    { name: "VIRUNDO", parent: "0307", value: "030713" },
    { name: "CURASCO", parent: "0307", value: "030714" },
    { name: "AREQUIPA", parent: "0401", value: "040101" },
    { name: "ALTO SELVA ALEGRE", parent: "0401", value: "040102" },
    { name: "CAYMA", parent: "0401", value: "040103" },
    { name: "CERRO COLORADO", parent: "0401", value: "040104" },
    { name: "CHARACATO", parent: "0401", value: "040105" },
    { name: "CHIGUATA", parent: "0401", value: "040106" },
    { name: "JACOBO HUNTER", parent: "0401", value: "040107" },
    { name: "LA JOYA", parent: "0401", value: "040108" },
    { name: "MARIANO MELGAR", parent: "0401", value: "040109" },
    { name: "MIRAFLORES", parent: "0401", value: "040110" },
    { name: "MOLLEBAYA", parent: "0401", value: "040111" },
    { name: "PAUCARPATA", parent: "0401", value: "040112" },
    { name: "POCSI", parent: "0401", value: "040113" },
    { name: "POLOBAYA", parent: "0401", value: "040114" },
    { name: "QUEQUEÑA", parent: "0401", value: "040115" },
    { name: "SABANDIA", parent: "0401", value: "040116" },
    { name: "SACHACA", parent: "0401", value: "040117" },
    { name: "SAN JUAN DE SIGUAS /1", parent: "0401", value: "040118" },
    { name: "SAN JUAN DE TARUCANI", parent: "0401", value: "040119" },
    { name: "SANTA ISABEL DE SIGUAS", parent: "0401", value: "040120" },
    { name: "SANTA RITA DE SIGUAS", parent: "0401", value: "040121" },
    { name: "SOCABAYA", parent: "0401", value: "040122" },
    { name: "TIABAYA", parent: "0401", value: "040123" },
    { name: "UCHUMAYO", parent: "0401", value: "040124" },
    { name: "VITOR", parent: "0401", value: "040125" },
    { name: "YANAHUARA", parent: "0401", value: "040126" },
    { name: "YARABAMBA", parent: "0401", value: "040127" },
    { name: "YURA", parent: "0401", value: "040128" },
    {
      name: "JOSE LUIS BUSTAMANTE Y RIVERO",
      parent: "0401",
      value: "040129",
    },
    { name: "CAMANA", parent: "0402", value: "040201" },
    { name: "JOSE MARIA QUIMPER", parent: "0402", value: "040202" },
    { name: "MARIANO NICOLAS VALCARCEL", parent: "0402", value: "040203" },
    { name: "MARISCAL CACERES", parent: "0402", value: "040204" },
    { name: "NICOLAS DE PIEROLA", parent: "0402", value: "040205" },
    { name: "OCOÑA", parent: "0402", value: "040206" },
    { name: "QUILCA", parent: "0402", value: "040207" },
    { name: "SAMUEL PASTOR", parent: "0402", value: "040208" },
    { name: "CARAVELI", parent: "0403", value: "040301" },
    { name: "ACARI", parent: "0403", value: "040302" },
    { name: "ATICO", parent: "0403", value: "040303" },
    { name: "ATIQUIPA", parent: "0403", value: "040304" },
    { name: "BELLA UNION", parent: "0403", value: "040305" },
    { name: "CAHUACHO", parent: "0403", value: "040306" },
    { name: "CHALA", parent: "0403", value: "040307" },
    { name: "CHAPARRA", parent: "0403", value: "040308" },
    { name: "HUANUHUANU", parent: "0403", value: "040309" },
    { name: "JAQUI", parent: "0403", value: "040310" },
    { name: "LOMAS", parent: "0403", value: "040311" },
    { name: "QUICACHA", parent: "0403", value: "040312" },
    { name: "YAUCA", parent: "0403", value: "040313" },
    { name: "APLAO", parent: "0404", value: "040401" },
    { name: "ANDAGUA", parent: "0404", value: "040402" },
    { name: "AYO", parent: "0404", value: "040403" },
    { name: "CHACHAS", parent: "0404", value: "040404" },
    { name: "CHILCAYMARCA", parent: "0404", value: "040405" },
    { name: "CHOCO", parent: "0404", value: "040406" },
    { name: "HUANCARQUI", parent: "0404", value: "040407" },
    { name: "MACHAGUAY", parent: "0404", value: "040408" },
    { name: "ORCOPAMPA", parent: "0404", value: "040409" },
    { name: "PAMPACOLCA", parent: "0404", value: "040410" },
    { name: "TIPAN", parent: "0404", value: "040411" },
    { name: "UÑON", parent: "0404", value: "040412" },
    { name: "URACA", parent: "0404", value: "040413" },
    { name: "VIRACO", parent: "0404", value: "040414" },
    { name: "CHIVAY", parent: "0405", value: "040501" },
    { name: "ACHOMA", parent: "0405", value: "040502" },
    { name: "CABANACONDE", parent: "0405", value: "040503" },
    { name: "CALLALLI", parent: "0405", value: "040504" },
    { name: "CAYLLOMA", parent: "0405", value: "040505" },
    { name: "COPORAQUE", parent: "0405", value: "040506" },
    { name: "HUAMBO", parent: "0405", value: "040507" },
    { name: "HUANCA", parent: "0405", value: "040508" },
    { name: "ICHUPAMPA", parent: "0405", value: "040509" },
    { name: "LARI", parent: "0405", value: "040510" },
    { name: "LLUTA", parent: "0405", value: "040511" },
    { name: "MACA", parent: "0405", value: "040512" },
    { name: "MADRIGAL", parent: "0405", value: "040513" },
    { name: "SAN ANTONIO DE CHUCA 2/", parent: "0405", value: "040514" },
    { name: "SIBAYO", parent: "0405", value: "040515" },
    { name: "TAPAY", parent: "0405", value: "040516" },
    { name: "TISCO", parent: "0405", value: "040517" },
    { name: "TUTI", parent: "0405", value: "040518" },
    { name: "YANQUE", parent: "0405", value: "040519" },
    { name: "MAJES", parent: "0405", value: "040520" },
    { name: "CHUQUIBAMBA", parent: "0406", value: "040601" },
    { name: "ANDARAY", parent: "0406", value: "040602" },
    { name: "CAYARANI", parent: "0406", value: "040603" },
    { name: "CHICHAS", parent: "0406", value: "040604" },
    { name: "IRAY", parent: "0406", value: "040605" },
    { name: "RIO GRANDE", parent: "0406", value: "040606" },
    { name: "SALAMANCA", parent: "0406", value: "040607" },
    { name: "YANAQUIHUA", parent: "0406", value: "040608" },
    { name: "MOLLENDO", parent: "0407", value: "040701" },
    { name: "COCACHACRA", parent: "0407", value: "040702" },
    { name: "DEAN VALDIVIA", parent: "0407", value: "040703" },
    { name: "ISLAY", parent: "0407", value: "040704" },
    { name: "MEJIA", parent: "0407", value: "040705" },
    { name: "PUNTA DE BOMBON", parent: "0407", value: "040706" },
    { name: "COTAHUASI", parent: "0408", value: "040801" },
    { name: "ALCA", parent: "0408", value: "040802" },
    { name: "CHARCANA", parent: "0408", value: "040803" },
    { name: "HUAYNACOTAS", parent: "0408", value: "040804" },
    { name: "PAMPAMARCA", parent: "0408", value: "040805" },
    { name: "PUYCA", parent: "0408", value: "040806" },
    { name: "QUECHUALLA", parent: "0408", value: "040807" },
    { name: "SAYLA", parent: "0408", value: "040808" },
    { name: "TAURIA", parent: "0408", value: "040809" },
    { name: "TOMEPAMPA", parent: "0408", value: "040810" },
    { name: "TORO", parent: "0408", value: "040811" },
    { name: "AYACUCHO", parent: "0501", value: "050101" },
    { name: "ACOCRO", parent: "0501", value: "050102" },
    { name: "ACOS VINCHOS", parent: "0501", value: "050103" },
    { name: "CARMEN ALTO", parent: "0501", value: "050104" },
    { name: "CHIARA", parent: "0501", value: "050105" },
    { name: "OCROS", parent: "0501", value: "050106" },
    { name: "PACAYCASA", parent: "0501", value: "050107" },
    { name: "QUINUA", parent: "0501", value: "050108" },
    { name: "SAN JOSE DE TICLLAS", parent: "0501", value: "050109" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "SAN JUAN BAUTISTA", parent: "0501", value: "050110" },
    { name: "SANTIAGO DE PISCHA", parent: "0501", value: "050111" },
    { name: "SOCOS", parent: "0501", value: "050112" },
    { name: "TAMBILLO", parent: "0501", value: "050113" },
    { name: "VINCHOS", parent: "0501", value: "050114" },
    { name: "JESUS NAZARENO", parent: "0501", value: "050115" },
    { name: "CANGALLO", parent: "0502", value: "050201" },
    { name: "CHUSCHI", parent: "0502", value: "050202" },
    { name: "LOS MOROCHUCOS", parent: "0502", value: "050203" },
    { name: "MARIA PARADO DE BELLIDO", parent: "0502", value: "050204" },
    { name: "PARAS", parent: "0502", value: "050205" },
    { name: "TOTOS", parent: "0502", value: "050206" },
    { name: "SANCOS", parent: "0503", value: "050301" },
    { name: "CARAPO", parent: "0503", value: "050302" },
    { name: "SACSAMARCA", parent: "0503", value: "050303" },
    { name: "SANTIAGO DE LUCANAMARCA", parent: "0503", value: "050304" },
    { name: "HUANTA", parent: "0504", value: "050401" },
    { name: "SAN JOSE DE USHUA", parent: "0508", value: "050809" },
    { name: "SARA SARA", parent: "0508", value: "050810" },
    { name: "QUEROBAMBA", parent: "0509", value: "050901" },
    { name: "BELEN", parent: "0509", value: "050902" },
    { name: "CHALCOS", parent: "0509", value: "050903" },
    { name: "CHILCAYOC", parent: "0509", value: "050904" },
    { name: "HUACAÑA", parent: "0509", value: "050905" },
    { name: "MORCOLLA", parent: "0509", value: "050906" },
    { name: "PAICO", parent: "0509", value: "050907" },
    { name: "SAN PEDRO DE LARCAY", parent: "0509", value: "050908" },
    { name: "SAN SALVADOR DE QUIJE", parent: "0509", value: "050909" },
    { name: "SANTIAGO DE PAUCARAY", parent: "0509", value: "050910" },
    { name: "SORAS", parent: "0509", value: "050911" },
    { name: "HUANCAPI", parent: "0510", value: "051001" },
    { name: "ALCAMENCA", parent: "0510", value: "051002" },
    { name: "APONGO", parent: "0510", value: "051003" },
    { name: "ASQUIPATA", parent: "0510", value: "051004" },
    { name: "CANARIA", parent: "0510", value: "051005" },
    { name: "CAYARA", parent: "0510", value: "051006" },
    { name: "COLCA", parent: "0510", value: "051007" },
    { name: "HUAMANQUIQUIA", parent: "0510", value: "051008" },
    { name: "HUANCARAYLLA", parent: "0510", value: "051009" },
    { name: "HUAYA", parent: "0510", value: "051010" },
    { name: "SARHUA", parent: "0510", value: "051011" },
    { name: "VILCANCHOS", parent: "0510", value: "051012" },
    { name: "VILCAS HUAMAN", parent: "0511", value: "051101" },
    { name: "ACCOMARCA", parent: "0511", value: "051102" },
    { name: "CARHUANCA", parent: "0511", value: "051103" },
    { name: "CONCEPCION", parent: "0511", value: "051104" },
    { name: "HUAMBALPA", parent: "0511", value: "051105" },
    { name: "INDEPENDENCIA /1", parent: "0511", value: "051106" },
    { name: "SAURAMA", parent: "0511", value: "051107" },
    { name: "VISCHONGO", parent: "0511", value: "051108" },
    { name: "CAJAMARCA", parent: "0601", value: "060101" },
    { name: "ASUNCION", parent: "0601", value: "060102" },
    { name: "CHETILLA", parent: "0601", value: "060103" },
    { name: "COSPAN", parent: "0601", value: "060104" },
    { name: "ENCAÑADA", parent: "0601", value: "060105" },
    { name: "JESUS", parent: "0601", value: "060106" },
    { name: "LLACANORA", parent: "0601", value: "060107" },
    { name: "LOS BAÑOS DEL INCA", parent: "0601", value: "060108" },
    { name: "MAGDALENA", parent: "0601", value: "060109" },
    { name: "MATARA", parent: "0601", value: "060110" },
    { name: "NAMORA", parent: "0601", value: "060111" },
    { name: "SAN JUAN", parent: "0601", value: "060112" },
    { name: "CAJABAMBA", parent: "0602", value: "060201" },
    { name: "CACHACHI", parent: "0602", value: "060202" },
    { name: "CONDEBAMBA", parent: "0602", value: "060203" },
    { name: "SITACOCHA", parent: "0602", value: "060204" },
    { name: "CELENDIN", parent: "0603", value: "060301" },
    { name: "CHUMUCH", parent: "0603", value: "060302" },
    { name: "CORTEGANA", parent: "0603", value: "060303" },
    { name: "HUASMIN", parent: "0603", value: "060304" },
    { name: "JORGE CHAVEZ", parent: "0603", value: "060305" },
    { name: "JOSE GALVEZ", parent: "0603", value: "060306" },
    { name: "MIGUEL IGLESIAS", parent: "0603", value: "060307" },
    { name: "OXAMARCA", parent: "0603", value: "060308" },
    { name: "SOROCHUCO", parent: "0603", value: "060309" },
    { name: "SUCRE", parent: "0603", value: "060310" },
    { name: "UTCO", parent: "0603", value: "060311" },
    { name: "LA LIBERTAD DE PALLAN", parent: "0603", value: "060312" },
    { name: "CHOTA", parent: "0604", value: "060401" },
    { name: "ANGUIA", parent: "0604", value: "060402" },
    { name: "CHADIN", parent: "0604", value: "060403" },
    { name: "CHIGUIRIP", parent: "0604", value: "060404" },
    { name: "CHIMBAN", parent: "0604", value: "060405" },
    { name: "CHOROPAMPA", parent: "0604", value: "060406" },
    { name: "COCHABAMBA", parent: "0604", value: "060407" },
    { name: "CONCHAN", parent: "0604", value: "060408" },
    { name: "HUAMBOS", parent: "0604", value: "060409" },
    { name: "LAJAS", parent: "0604", value: "060410" },
    { name: "LLAMA", parent: "0604", value: "060411" },
    { name: "MIRACOSTA", parent: "0604", value: "060412" },
    { name: "PACCHA", parent: "0604", value: "060413" },
    { name: "PION", parent: "0604", value: "060414" },
    { name: "QUEROCOTO", parent: "0604", value: "060415" },
    { name: "SAN JUAN DE LICUPIS", parent: "0604", value: "060416" },
    { name: "TACABAMBA", parent: "0604", value: "060417" },
    { name: "TOCMOCHE", parent: "0604", value: "060418" },
    { name: "CHALAMARCA", parent: "0604", value: "060419" },
    { name: "CONTUMAZA", parent: "0605", value: "060501" },
    { name: "CHILETE", parent: "0605", value: "060502" },
    { name: "CUPISNIQUE", parent: "0605", value: "060503" },
    { name: "GUZMANGO", parent: "0605", value: "060504" },
    { name: "SAN BENITO", parent: "0605", value: "060505" },
    { name: "SANTA CRUZ DE TOLED", parent: "0605", value: "060506" },
    { name: "TANTARICA", parent: "0605", value: "060507" },
    { name: "YONAN", parent: "0605", value: "060508" },
    { name: "CUTERVO", parent: "0606", value: "060601" },
    { name: "CALLAYUC", parent: "0606", value: "060602" },
    { name: "CHOROS", parent: "0606", value: "060603" },
    { name: "CUJILLO", parent: "0606", value: "060604" },
    { name: "LA RAMADA", parent: "0606", value: "060605" },
    { name: "PIMPINGOS", parent: "0606", value: "060606" },
    { name: "QUEROCOTILLO", parent: "0606", value: "060607" },
    { name: "SAN ANDRES DE CUTERVO", parent: "0606", value: "060608" },
    { name: "SAN JUAN DE CUTERVO", parent: "0606", value: "060609" },
    { name: "SAN LUIS DE LUCMA", parent: "0606", value: "060610" },
    { name: "SANTA CRUZ", parent: "0606", value: "060611" },
    {
      name: "SANTO DOMINGO DE LA CAPILLA",
      parent: "0606",
      value: "060612",
    },
    { name: "SANTO TOMAS", parent: "0606", value: "060613" },
    { name: "SOCOTA", parent: "0606", value: "060614" },
    { name: "TORIBIO CASANOVA", parent: "0606", value: "060615" },
    { name: "BAMBAMARCA", parent: "0607", value: "060701" },
    { name: "CHUGUR", parent: "0607", value: "060702" },
    { name: "HUALGAYOC", parent: "0607", value: "060703" },
    { name: "JAEN", parent: "0608", value: "060801" },
    { name: "BELLAVISTA", parent: "0608", value: "060802" },
    { name: "CHONTALI", parent: "0608", value: "060803" },
    { name: "COLASAY", parent: "0608", value: "060804" },
    { name: "HUABAL", parent: "0608", value: "060805" },
    { name: "LAS PIRIAS", parent: "0608", value: "060806" },
    { name: "POMAHUACA", parent: "0608", value: "060807" },
    { name: "PUCARA", parent: "0608", value: "060808" },
    { name: "SALLIQUE", parent: "0608", value: "060809" },
    { name: "SAN FELIPE", parent: "0608", value: "060810" },
    { name: "SAN JOSE DEL ALTO", parent: "0608", value: "060811" },
    { name: "SANTA ROSA", parent: "0608", value: "060812" },
    { name: "SAN IGNACIO", parent: "0609", value: "060901" },
    { name: "CHIRINOS", parent: "0609", value: "060902" },
    { name: "HUARANGO", parent: "0609", value: "060903" },
    { name: "LA COIPA", parent: "0609", value: "060904" },
    { name: "NAMBALLE", parent: "0609", value: "060905" },
    { name: "SAN JOSE DE LOURDES", parent: "0609", value: "060906" },
    { name: "TABACONAS", parent: "0609", value: "060907" },
    { name: "PEDRO GALVEZ", parent: "0610", value: "061001" },
    { name: "CHANCAY", parent: "0610", value: "061002" },
    { name: "EDUARDO VILLANUEVA", parent: "0610", value: "061003" },
    { name: "GREGORIO PITA", parent: "0610", value: "061004" },
    { name: "ICHOCAN", parent: "0610", value: "061005" },
    { name: "JOSE MANUEL QUIROZ", parent: "0610", value: "061006" },
    { name: "JOSE SABOGAL", parent: "0610", value: "061007" },
    { name: "SAN MIGUEL", parent: "0611", value: "061101" },
    { name: "BOLIVAR", parent: "0611", value: "061102" },
    { name: "CALQUIS", parent: "0611", value: "061103" },
    { name: "CATILLUC", parent: "0611", value: "061104" },
    { name: "EL PRADO", parent: "0611", value: "061105" },
    { name: "LA FLORIDA", parent: "0611", value: "061106" },
    { name: "LLAPA", parent: "0611", value: "061107" },
    { name: "NANCHOC", parent: "0611", value: "061108" },
    { name: "NIEPOS", parent: "0611", value: "061109" },
    { name: "SAN GREGORIO", parent: "0611", value: "061110" },
    { name: "SAN SILVESTRE DE COCHAN", parent: "0611", value: "061111" },
    { name: "TONGOD", parent: "0611", value: "061112" },
    { name: "UNION AGUA BLANCA", parent: "0611", value: "061113" },
    { name: "SAN PABLO", parent: "0612", value: "061201" },
    { name: "SAN BERNARDINO", parent: "0612", value: "061202" },
    { name: "SAN LUIS", parent: "0612", value: "061203" },
    { name: "TUMBADEN", parent: "0612", value: "061204" },
    { name: "SANTA CRUZ", parent: "0613", value: "061301" },
    { name: "ANDABAMBA", parent: "0613", value: "061302" },
    { name: "CATACHE", parent: "0613", value: "061303" },
    { name: "CHANCAYBAÑOS", parent: "0613", value: "061304" },
    { name: "LA ESPERANZA", parent: "0613", value: "061305" },
    { name: "NINABAMBA", parent: "0613", value: "061306" },
    { name: "PULAN", parent: "0613", value: "061307" },
    { name: "SAUCEPAMPA", parent: "0613", value: "061308" },
    { name: "SEXI", parent: "0613", value: "061309" },
    { name: "UTICYACU", parent: "0613", value: "061310" },
    { name: "YAUYUCAN", parent: "0613", value: "061311" },
    { name: "CALLAO", parent: "0701", value: "070101" },
    { name: "BELLAVISTA", parent: "0701", value: "070102" },
    { name: "CARMEN DE LA LEGUA REYNOSO", parent: "0701", value: "070103" },
    { name: "LA PERLA", parent: "0701", value: "070104" },
    { name: "LA PUNTA", parent: "0701", value: "070105" },
    { name: "VENTANILLA", parent: "0701", value: "070106" },
    { name: "CUSCO", parent: "0801", value: "080101" },
    { name: "CCORCA", parent: "0801", value: "080102" },
    { name: "POROY", parent: "0801", value: "080103" },
    { name: "SAN JERONIMO", parent: "0801", value: "080104" },
    { name: "SAN SEBASTIAN", parent: "0801", value: "080105" },
    { name: "SANTIAGO", parent: "0801", value: "080106" },
    { name: "SAYLLA", parent: "0801", value: "080107" },
    { name: "WANCHAQ", parent: "0801", value: "080108" },
    { name: "ACOMAYO", parent: "0802", value: "080201" },
    { name: "ACOPIA", parent: "0802", value: "080202" },
    { name: "ACOS", parent: "0802", value: "080203" },
    { name: "MOSOC LLACTA", parent: "0802", value: "080204" },
    { name: "POMACANCHI", parent: "0802", value: "080205" },
    { name: "RONDOCAN", parent: "0802", value: "080206" },
    { name: "SANGARARA", parent: "0802", value: "080207" },
    { name: "ANTA", parent: "0803", value: "080301" },
    { name: "ANCAHUASI", parent: "0803", value: "080302" },
    { name: "CACHIMAYO", parent: "0803", value: "080303" },
    { name: "CHINCHAYPUJIO", parent: "0803", value: "080304" },
    { name: "HUAROCONDO", parent: "0803", value: "080305" },
    { name: "LIMATAMBO", parent: "0803", value: "080306" },
    { name: "MOLLEPATA", parent: "0803", value: "080307" },
    { name: "PUCYURA", parent: "0803", value: "080308" },
    { name: "ZURITE", parent: "0803", value: "080309" },
    { name: "CALCA", parent: "0804", value: "080401" },
    { name: "COYA", parent: "0804", value: "080402" },
    { name: "LAMAY", parent: "0804", value: "080403" },
    { name: "LARES", parent: "0804", value: "080404" },
    { name: "PISAC", parent: "0804", value: "080405" },
    { name: "SAN SALVADOR", parent: "0804", value: "080406" },
    { name: "TARAY", parent: "0804", value: "080407" },
    { name: "YANATILE", parent: "0804", value: "080408" },
    { name: "YANAOCA", parent: "0805", value: "080501" },
    { name: "CHECCA", parent: "0805", value: "080502" },
    { name: "KUNTURKANKI", parent: "0805", value: "080503" },
    { name: "LANGUI", parent: "0805", value: "080504" },
    { name: "LAYO", parent: "0805", value: "080505" },
    { name: "PAMPAMARCA", parent: "0805", value: "080506" },
    { name: "QUEHUE", parent: "0805", value: "080507" },
    { name: "TUPAC AMARU", parent: "0805", value: "080508" },
    { name: "SICUANI", parent: "0806", value: "080601" },
    { name: "CHECACUPE", parent: "0806", value: "080602" },
    { name: "COMBAPATA", parent: "0806", value: "080603" },
    { name: "MARANGANI", parent: "0806", value: "080604" },
    { name: "PITUMARCA", parent: "0806", value: "080605" },
    { name: "SAN PABLO", parent: "0806", value: "080606" },
    { name: "SAN PEDRO", parent: "0806", value: "080607" },
    { name: "TINTA", parent: "0806", value: "080608" },
    { name: "SANTO TOMAS", parent: "0807", value: "080701" },
    { name: "CAPACMARCA", parent: "0807", value: "080702" },
    { name: "CHAMACA", parent: "0807", value: "080703" },
    { name: "COLQUEMARCA", parent: "0807", value: "080704" },
    { name: "LIVITACA", parent: "0807", value: "080705" },
    { name: "LLUSCO", parent: "0807", value: "080706" },
    { name: "QUIÑOTA", parent: "0807", value: "080707" },
    { name: "VELILLE", parent: "0807", value: "080708" },
    { name: "ESPINAR", parent: "0808", value: "080801" },
    { name: "CONDOROMA", parent: "0808", value: "080802" },
    { name: "COPORAQUE", parent: "0808", value: "080803" },
    { name: "OCORURO", parent: "0808", value: "080804" },
    { name: "PALLPATA", parent: "0808", value: "080805" },
    { name: "PICHIGUA", parent: "0808", value: "080806" },
    { name: "SUYCKUTAMBO 3/", parent: "0808", value: "080807" },
    { name: "ALTO PICHIGUA", parent: "0808", value: "080808" },
    { name: "SANTA ANA", parent: "0809", value: "080901" },
    { name: "ECHARATE", parent: "0809", value: "080902" },
    { name: "HUAYOPATA /1", parent: "0809", value: "080903" },
    { name: "MARANURA", parent: "0809", value: "080904" },
    { name: "OCOBAMBA /2", parent: "0809", value: "080905" },
    { name: "QUELLOUNO", parent: "0809", value: "080906" },
    { name: "KIMBIRI", parent: "0809", value: "080907" },
    { name: "SANTA TERESA", parent: "0809", value: "080908" },
    { name: "VILCABAMBA", parent: "0809", value: "080909" },
    { name: "PICHARI", parent: "0809", value: "080910" },
    { name: "PARURO", parent: "0810", value: "081001" },
    { name: "ACCHA", parent: "0810", value: "081002" },
    { name: "CCAPI", parent: "0810", value: "081003" },
    { name: "COLCHA", parent: "0810", value: "081004" },
    { name: "HUANOQUITE", parent: "0810", value: "081005" },
    { name: "OMACHA", parent: "0810", value: "081006" },
    { name: "PACCARITAMBO", parent: "0810", value: "081007" },
    { name: "PILLPINTO", parent: "0810", value: "081008" },
    { name: "YAURISQUE", parent: "0810", value: "081009" },
    { name: "PAUCARTAMBO", parent: "0811", value: "081101" },
    { name: "CAICAY", parent: "0811", value: "081102" },
    { name: "CHALLABAMBA", parent: "0811", value: "081103" },
    { name: "COLQUEPATA", parent: "0811", value: "081104" },
    { name: "HUANCARANI", parent: "0811", value: "081105" },
    { name: "KOSÑIPATA", parent: "0811", value: "081106" },
    { name: "URCOS", parent: "0812", value: "081201" },
    { name: "ANDAHUAYLILLAS", parent: "0812", value: "081202" },
    { name: "CAMANTI", parent: "0812", value: "081203" },
    { name: "CCARHUAYO", parent: "0812", value: "081204" },
    { name: "CCATCA", parent: "0812", value: "081205" },
    { name: "CUSIPATA", parent: "0812", value: "081206" },
    { name: "HUARO", parent: "0812", value: "081207" },
    { name: "LUCRE", parent: "0812", value: "081208" },
    { name: "MARCAPATA", parent: "0812", value: "081209" },
    { name: "OCONGATE", parent: "0812", value: "081210" },
    { name: "OROPESA", parent: "0812", value: "081211" },
    { name: "QUIQUIJANA", parent: "0812", value: "081212" },
    { name: "URUBAMBA", parent: "0813", value: "081301" },
    { name: "CHINCHERO", parent: "0813", value: "081302" },
    { name: "HUAYLLABAMBA", parent: "0813", value: "081303" },
    { name: "MACHUPICCHU", parent: "0813", value: "081304" },
    { name: "MARAS", parent: "0813", value: "081305" },
    { name: "OLLANTAYTAMBO", parent: "0813", value: "081306" },
    { name: "YUCAY", parent: "0813", value: "081307" },
    { name: "HUANCAVELICA", parent: "0901", value: "090101" },
    { name: "ACOBAMBILLA", parent: "0901", value: "090102" },
    { name: "ACORIA", parent: "0901", value: "090103" },
    { name: "CONAYCA", parent: "0901", value: "090104" },
    { name: "CUENCA", parent: "0901", value: "090105" },
    { name: "HUACHOCOLPA", parent: "0901", value: "090106" },
    { name: "HUAYLLAHUARA", parent: "0901", value: "090107" },
    { name: "IZCUCHACA", parent: "0901", value: "090108" },
    { name: "LARIA", parent: "0901", value: "090109" },
    { name: "MANTA", parent: "0901", value: "090110" },
    { name: "MARISCAL CACERES", parent: "0901", value: "090111" },
    { name: "MOYA", parent: "0901", value: "090112" },
    { name: "NUEVO OCCORO", parent: "0901", value: "090113" },
    { name: "PALCA", parent: "0901", value: "090114" },
    { name: "PILCHACA", parent: "0901", value: "090115" },
    { name: "VILCA", parent: "0901", value: "090116" },
    { name: "YAULI", parent: "0901", value: "090117" },
    { name: "ASCENSION", parent: "0901", value: "090118" },
    { name: "HUANDO", parent: "0901", value: "090119" },
    { name: "ACOBAMBA", parent: "0902", value: "090201" },
    { name: "ANDABAMBA", parent: "0902", value: "090202" },
    { name: "ANTA", parent: "0902", value: "090203" },
    { name: "CAJA", parent: "0902", value: "090204" },
    { name: "MARCAS", parent: "0902", value: "090205" },
    { name: "PAUCARA", parent: "0902", value: "090206" },
    { name: "POMACOCHA", parent: "0902", value: "090207" },
    { name: "ROSARIO", parent: "0902", value: "090208" },
    { name: "LIRCAY", parent: "0903", value: "090301" },
    { name: "ANCHONGA", parent: "0903", value: "090302" },
    { name: "CALLANMARCA", parent: "0903", value: "090303" },
    { name: "CCOCHACCASA", parent: "0903", value: "090304" },
    { name: "CHINCHO", parent: "0903", value: "090305" },
    { name: "CONGALLA", parent: "0903", value: "090306" },
    { name: "HUANCA-HUANCA", parent: "0903", value: "090307" },
    { name: "HUAYLLAY GRANDE", parent: "0903", value: "090308" },
    { name: "JULCAMARCA", parent: "0903", value: "090309" },
    { name: "SAN ANTONIO DE ANTAPARCO", parent: "0903", value: "090310" },
    { name: "SANTO TOMAS DE PATA", parent: "0903", value: "090311" },
    { name: "SECCLLA", parent: "0903", value: "090312" },
    { name: "CASTROVIRREYNA", parent: "0904", value: "090401" },
    { name: "ARMA", parent: "0904", value: "090402" },
    { name: "AURAHUA", parent: "0904", value: "090403" },
    { name: "CAPILLAS", parent: "0904", value: "090404" },
    { name: "CHUPAMARCA", parent: "0904", value: "090405" },
    { name: "COCAS", parent: "0904", value: "090406" },
    { name: "HUACHOS", parent: "0904", value: "090407" },
    { name: "HUAMATAMBO", parent: "0904", value: "090408" },
    { name: "MOLLEPAMPA", parent: "0904", value: "090409" },
    { name: "SAN JUAN", parent: "0904", value: "090410" },
    { name: "SANTA ANA", parent: "0904", value: "090411" },
    { name: "TANTARA", parent: "0904", value: "090412" },
    { name: "TICRAPO", parent: "0904", value: "090413" },
    { name: "CHURCAMPA", parent: "0905", value: "090501" },
    { name: "ANCO", parent: "0905", value: "090502" },
    { name: "CHINCHIHUASI", parent: "0905", value: "090503" },
    { name: "EL CARMEN", parent: "0905", value: "090504" },
    { name: "LA MERCED", parent: "0905", value: "090505" },
    { name: "LOCROJA", parent: "0905", value: "090506" },
    { name: "PAUCARBAMBA", parent: "0905", value: "090507" },
    { name: "SAN MIGUEL DE MAYOCC", parent: "0905", value: "090508" },
    { name: "SAN PEDRO DE CORIS", parent: "0905", value: "090509" },
    { name: "PACHAMARCA", parent: "0905", value: "090510" },
    { name: "HUAYTARA", parent: "0906", value: "090601" },
    { name: "AYAVI", parent: "0906", value: "090602" },
    { name: "CORDOVA", parent: "0906", value: "090603" },
    { name: "HUAYACUNDO ARMA", parent: "0906", value: "090604" },
    { name: "LARAMARCA", parent: "0906", value: "090605" },
    { name: "OCOYO", parent: "0906", value: "090606" },
    { name: "PILPICHACA", parent: "0906", value: "090607" },
    { name: "QUERCO", parent: "0906", value: "090608" },
    { name: "QUITO-ARMA", parent: "0906", value: "090609" },
    { name: "SAN ANTONIO DE CUSICANCHA", parent: "0906", value: "090610" },
    {
      name: "SAN FRANCISCO DE SANGAYAICO",
      parent: "0906",
      value: "090611",
    },
    { name: "SAN ISIDRO", parent: "0906", value: "090612" },
    { name: "SANTIAGO DE CHOCORVOS", parent: "0906", value: "090613" },
    { name: "SANTIAGO DE QUIRAHUARA", parent: "0906", value: "090614" },
    { name: "SANTO DOMINGO DE CAPILLAS", parent: "0906", value: "090615" },
    { name: "TAMBO", parent: "0906", value: "090616" },
    { name: "PAMPAS", parent: "0907", value: "090701" },
    { name: "ACOSTAMBO", parent: "0907", value: "090702" },
    { name: "ACRAQUIA", parent: "0907", value: "090703" },
    { name: "AHUAYCHA", parent: "0907", value: "090704" },
    { name: "COLCABAMBA", parent: "0907", value: "090705" },
    { name: "DANIEL HERNANDEZ", parent: "0907", value: "090706" },
    { name: "HUACHOCOLPA", parent: "0907", value: "090707" },
    { name: "HUARIBAMBA", parent: "0907", value: "090709" },
    { name: "ÑAHUIMPUQUIO", parent: "0907", value: "090710" },
    { name: "PAZOS", parent: "0907", value: "090711" },
    { name: "QUISHUAR", parent: "0907", value: "090713" },
    { name: "SALCABAMBA", parent: "0907", value: "090714" },
    { name: "SALCAHUASI", parent: "0907", value: "090715" },
    { name: "SAN MARCOS DE ROCCHAC", parent: "0907", value: "090716" },
    { name: "SURCUBAMBA", parent: "0907", value: "090717" },
    { name: "TINTAY PUNCU", parent: "0907", value: "090718" },
    { name: "HUANUCO", parent: "1001", value: "100101" },
    { name: "AMARILIS", parent: "1001", value: "100102" },
    { name: "CHINCHAO", parent: "1001", value: "100103" },
    { name: "CHURUBAMBA", parent: "1001", value: "100104" },
    { name: "MARGOS", parent: "1001", value: "100105" },
    { name: "QUISQUI", parent: "1001", value: "100106" },
    { name: "SAN FRANCISCO DE CAYRAN", parent: "1001", value: "100107" },
    { name: "SAN PEDRO DE CHAULAN", parent: "1001", value: "100108" },
    { name: "SANTA MARIA DEL VALLE", parent: "1001", value: "100109" },
    { name: "YARUMAYO", parent: "1001", value: "100110" },
    { name: "PILLCO MARCA", parent: "1001", value: "100111" },
    { name: "AMBO", parent: "1002", value: "100201" },
    { name: "CAYNA", parent: "1002", value: "100202" },
    { name: "COLPAS", parent: "1002", value: "100203" },
    { name: "CONCHAMARCA", parent: "1002", value: "100204" },
    { name: "HUACAR", parent: "1002", value: "100205" },
    { name: "SAN FRANCISCO", parent: "1002", value: "100206" },
    { name: "SAN RAFAEL", parent: "1002", value: "100207" },
    { name: "TOMAY KICHWA", parent: "1002", value: "100208" },
    { name: "LA UNION", parent: "1003", value: "100301" },
    { name: "CHUQUIS", parent: "1003", value: "100307" },
    { name: "MARIAS", parent: "1003", value: "100311" },
    { name: "PACHAS", parent: "1003", value: "100313" },
    { name: "QUIVILLA", parent: "1003", value: "100316" },
    { name: "RIPAN", parent: "1003", value: "100317" },
    { name: "SHUNQUI", parent: "1003", value: "100321" },
    { name: "SILLAPATA", parent: "1003", value: "100322" },
    { name: "YANAS", parent: "1003", value: "100323" },
    { name: "HUACAYBAMBA", parent: "1004", value: "100401" },
    { name: "CANCHABAMBA", parent: "1004", value: "100402" },
    { name: "COCHABAMBA", parent: "1004", value: "100403" },
    { name: "PINRA", parent: "1004", value: "100404" },
    { name: "LLATA", parent: "1005", value: "100501" },
    { name: "ARANCAY", parent: "1005", value: "100502" },
    { name: "CHAVIN DE PARIARCA", parent: "1005", value: "100503" },
    { name: "JACAS GRANDE", parent: "1005", value: "100504" },
    { name: "JIRCAN", parent: "1005", value: "100505" },
    { name: "MIRAFLORES", parent: "1005", value: "100506" },
    { name: "MONZON", parent: "1005", value: "100507" },
    { name: "PUNCHAO", parent: "1005", value: "100508" },
    { name: "PUÑOS", parent: "1005", value: "100509" },
    { name: "SINGA", parent: "1005", value: "100510" },
    { name: "TANTAMAYO", parent: "1005", value: "100511" },
    { name: "RUPA-RUPA", parent: "1006", value: "100601" },
    { name: "DANIEL ALOMIA ROBLES", parent: "1006", value: "100602" },
    { name: "HERMILIO VALDIZAN", parent: "1006", value: "100603" },
    { name: "JOSE CRESPO Y CASTILLO", parent: "1006", value: "100604" },
    { name: "LUYANDO 1/", parent: "1006", value: "100605" },
    { name: "MARIANO DAMASO BERAUN", parent: "1006", value: "100606" },
    { name: "HUACRACHUCO", parent: "1007", value: "100701" },
    { name: "CHOLON", parent: "1007", value: "100702" },
    { name: "SAN BUENAVENTURA", parent: "1007", value: "100703" },
    { name: "PANAO", parent: "1008", value: "100801" },
    { name: "CHAGLLA", parent: "1008", value: "100802" },
    { name: "MOLINO", parent: "1008", value: "100803" },
    { name: "UMARI", parent: "1008", value: "100804" },
    { name: "PUERTO INCA", parent: "1009", value: "100901" },
    { name: "CODO DEL POZUZO", parent: "1009", value: "100902" },
    { name: "HONORIA", parent: "1009", value: "100903" },
    { name: "TOURNAVISTA", parent: "1009", value: "100904" },
    { name: "YUYAPICHIS", parent: "1009", value: "100905" },
    { name: "JESUS", parent: "1010", value: "101001" },
    { name: "BAÑOS", parent: "1010", value: "101002" },
    { name: "JIVIA", parent: "1010", value: "101003" },
    { name: "QUEROPALCA", parent: "1010", value: "101004" },
    { name: "RONDOS", parent: "1010", value: "101005" },
    { name: "SAN FRANCISCO DE ASIS", parent: "1010", value: "101006" },
    { name: "SAN MIGUEL DE CAURI", parent: "1010", value: "101007" },
    { name: "CHAVINILLO", parent: "1011", value: "101101" },
    { name: "CAHUAC", parent: "1011", value: "101102" },
    { name: "CHACABAMBA", parent: "1011", value: "101103" },
    { name: "APARICIO POMARES", parent: "1011", value: "101104" },
    { name: "JACAS CHICO", parent: "1011", value: "101105" },
    { name: "OBAS", parent: "1011", value: "101106" },
    { name: "PAMPAMARCA", parent: "1011", value: "101107" },
    { name: "CHORAS", parent: "1011", value: "101108" },
    { name: "ICA", parent: "1101", value: "110101" },
    { name: "LA TINGUIÑA", parent: "1101", value: "110102" },
    { name: "LOS AQUIJES", parent: "1101", value: "110103" },
    { name: "OCUCAJE", parent: "1101", value: "110104" },
    { name: "PACHACUTEC", parent: "1101", value: "110105" },
    { name: "PARCONA", parent: "1101", value: "110106" },
    // tslint:disable-next-line:no-duplicate-str, parent: "ext-"ing
    { name: "PUEBLO NUEVO", parent: "1101", value: "110107" },
    { name: "SALAS", parent: "1101", value: "110108" },
    { name: "SAN JOSE DE LOS MOLINOS", parent: "1101", value: "110109" },
    { name: "SAN JUAN BAUTISTA", parent: "1101", value: "110110" },
    { name: "SANTIAGO", parent: "1101", value: "110111" },
    { name: "SUBTANJALLA", parent: "1101", value: "110112" },
    { name: "TATE", parent: "1101", value: "110113" },
    { name: "YAUCA DEL ROSARIO 1/", parent: "1101", value: "110114" },
    { name: "CHINCHA ALTA", parent: "1102", value: "110201" },
    { name: "ALTO LARAN", parent: "1102", value: "110202" },
    { name: "CHAVIN", parent: "1102", value: "110203" },
    { name: "CHINCHA BAJA", parent: "1102", value: "110204" },
    { name: "EL CARMEN", parent: "1102", value: "110205" },
    { name: "GROCIO PRADO", parent: "1102", value: "110206" },
    { name: "PUEBLO NUEVO", parent: "1102", value: "110207" },
    { name: "SAN JUAN DE YANAC", parent: "1102", value: "110208" },
    { name: "SAN PEDRO DE HUACARPANA", parent: "1102", value: "110209" },
    { name: "SUNAMPE", parent: "1102", value: "110210" },
    { name: "TAMBO DE MORA", parent: "1102", value: "110211" },
    { name: "NAZCA", parent: "1103", value: "110301" },
    { name: "CHANGUILLO", parent: "1103", value: "110302" },
    { name: "EL INGENIO", parent: "1103", value: "110303" },
    { name: "MARCONA", parent: "1103", value: "110304" },
    { name: "VISTA ALEGRE", parent: "1103", value: "110305" },
    { name: "PALPA", parent: "1104", value: "110401" },
    { name: "LLIPATA", parent: "1104", value: "110402" },
    { name: "RIO GRANDE", parent: "1104", value: "110403" },
    { name: "SANTA CRUZ", parent: "1104", value: "110404" },
    { name: "TIBILLO", parent: "1104", value: "110405" },
    { name: "PISCO", parent: "1105", value: "110501" },
    { name: "HUANCANO", parent: "1105", value: "110502" },
    { name: "HUMAY", parent: "1105", value: "110503" },
    { name: "INDEPENDENCIA", parent: "1105", value: "110504" },
    { name: "PARACAS", parent: "1105", value: "110505" },
    { name: "SAN ANDRES", parent: "1105", value: "110506" },
    { name: "SAN CLEMENTE", parent: "1105", value: "110507" },
    { name: "TUPAC AMARU INCA", parent: "1105", value: "110508" },
    { name: "HUANCAYO", parent: "1201", value: "120101" },
    { name: "CARHUACALLANGA", parent: "1201", value: "120104" },
    { name: "CHACAPAMPA", parent: "1201", value: "120105" },
    { name: "CHICCHE", parent: "1201", value: "120106" },
    { name: "CHILCA", parent: "1201", value: "120107" },
    { name: "CHUPURO", parent: "1201", value: "120111" },
    { name: "CHONGOS ALTO", parent: "1201", value: "120108" },
    { name: "COLCA", parent: "1201", value: "120112" },
    { name: "CULLHUAS", parent: "1201", value: "120113" },
    { name: "EL TAMBO", parent: "1201", value: "120114" },
    { name: "HUACRAPUQUIO", parent: "1201", value: "120116" },
    { name: "HUALHUAS", parent: "1201", value: "120117" },
    { name: "HUANCAN", parent: "1201", value: "120119" },
    { name: "HUASICANCHA", parent: "1201", value: "120120" },
    { name: "HUAYUCACHI", parent: "1201", value: "120121" },
    { name: "INGENIO", parent: "1201", value: "120122" },
    { name: "PARIAHUANCA 1/", parent: "1201", value: "120124" },
    { name: "PILCOMAYO", parent: "1201", value: "120125" },
    { name: "PUCARA", parent: "1201", value: "120126" },
    { name: "QUICHUAY", parent: "1201", value: "120127" },
    { name: "QUILCAS", parent: "1201", value: "120128" },
    { name: "SAN AGUSTIN", parent: "1201", value: "120129" },
    { name: "SAN JERONIMO DE TUNAN", parent: "1201", value: "120130" },
    { name: "SAÑO", parent: "1201", value: "120132" },
    { name: "SAPALLANGA", parent: "1201", value: "120133" },
    { name: "SICAYA", parent: "1201", value: "120134" },
    { name: "SANTO DOMINGO DE ACOBAMBA", parent: "1201", value: "120135" },
    { name: "VIQUES", parent: "1201", value: "120136" },
    { name: "CONCEPCION", parent: "1202", value: "120201" },
    { name: "ACO", parent: "1202", value: "120202" },
    { name: "ANDAMARCA", parent: "1202", value: "120203" },
    { name: "CHAMBARA", parent: "1202", value: "120204" },
    { name: "COCHAS", parent: "1202", value: "120205" },
    { name: "COMAS", parent: "1202", value: "120206" },
    { name: "HEROINAS TOLEDO", parent: "1202", value: "120207" },
    { name: "MANZANARES", parent: "1202", value: "120208" },
    { name: "MARISCAL CASTILLA", parent: "1202", value: "120209" },
    { name: "MATAHUASI", parent: "1202", value: "120210" },
    { name: "MITO", parent: "1202", value: "120211" },
    { name: "NUEVE DE JULIO", parent: "1202", value: "120212" },
    { name: "ORCOTUNA", parent: "1202", value: "120213" },
    { name: "SAN JOSE DE QUERO", parent: "1202", value: "120214" },
    { name: "SANTA ROSA DE OCOPA", parent: "1202", value: "120215" },
    { name: "CHANCHAMAYO", parent: "1203", value: "120301" },
    { name: "PERENE", parent: "1203", value: "120302" },
    { name: "PICHANAQUI", parent: "1203", value: "120303" },
    { name: "SAN LUIS DE SHUARO", parent: "1203", value: "120304" },
    { name: "SAN RAMON", parent: "1203", value: "120305" },
    { name: "VITOC", parent: "1203", value: "120306" },
    { name: "JAUJA", parent: "1204", value: "120401" },
    { name: "ACOLLA", parent: "1204", value: "120402" },
    { name: "APATA", parent: "1204", value: "120403" },
    { name: "ATAURA", parent: "1204", value: "120404" },
    { name: "CANCHAYLLO", parent: "1204", value: "120405" },
    { name: "CURICACA", parent: "1204", value: "120406" },
    { name: "EL MANTARO", parent: "1204", value: "120407" },
    { name: "HUAMALI", parent: "1204", value: "120408" },
    { name: "HUARIPAMPA", parent: "1204", value: "120409" },
    { name: "HUERTAS", parent: "1204", value: "120410" },
    { name: "JANJAILLO", parent: "1204", value: "120411" },
    { name: "JULCAN", parent: "1204", value: "120412" },
    { name: "LEONOR ORDOÑEZ", parent: "1204", value: "120413" },
    { name: "LLOCLLAPAMPA", parent: "1204", value: "120414" },
    { name: "MARCO", parent: "1204", value: "120415" },
    { name: "MASMA", parent: "1204", value: "120416" },
    { name: "MASMA CHICCHE", parent: "1204", value: "120417" },
    { name: "MOLINOS", parent: "1204", value: "120418" },
    { name: "MONOBAMBA", parent: "1204", value: "120419" },
    { name: "MUQUI", parent: "1204", value: "120420" },
    { name: "MUQUIYAUYO", parent: "1204", value: "120421" },
    { name: "PACA", parent: "1204", value: "120422" },
    { name: "PACCHA", parent: "1204", value: "120423" },
    { name: "PANCAN", parent: "1204", value: "120424" },
    { name: "PARCO", parent: "1204", value: "120425" },
    { name: "POMACANCHA", parent: "1204", value: "120426" },
    { name: "RICRAN", parent: "1204", value: "120427" },
    { name: "SAN LORENZO", parent: "1204", value: "120428" },
    { name: "SAN PEDRO DE CHUNAN", parent: "1204", value: "120429" },
    { name: "SAUSA", parent: "1204", value: "120430" },
    { name: "SINCOS", parent: "1204", value: "120431" },
    { name: "TUNAN MARCA", parent: "1204", value: "120432" },
    { name: "YAULI", parent: "1204", value: "120433" },
    { name: "YAUYOS", parent: "1204", value: "120434" },
    { name: "JUNIN", parent: "1205", value: "120501" },
    { name: "CARHUAMAYO", parent: "1205", value: "120502" },
    { name: "ONDORES", parent: "1205", value: "120503" },
    { name: "ULCUMAYO", parent: "1205", value: "120504" },
    { name: "SATIPO", parent: "1206", value: "120601" },
    { name: "COVIRIALI", parent: "1206", value: "120602" },
    { name: "LLAYLLA", parent: "1206", value: "120603" },
    { name: "MAZAMARI", parent: "1206", value: "120604" },
    { name: "PAMPA HERMOSA", parent: "1206", value: "120605" },
    { name: "PANGOA", parent: "1206", value: "120606" },
    { name: "RIO NEGRO", parent: "1206", value: "120607" },
    { name: "RIO TAMBO", parent: "1206", value: "120608" },
    { name: "TARMA", parent: "1207", value: "120701" },
    { name: "ACOBAMBA", parent: "1207", value: "120702" },
    { name: "HUARICOLCA", parent: "1207", value: "120703" },
    { name: "HUASAHUASI", parent: "1207", value: "120704" },
    { name: "LA UNION", parent: "1207", value: "120705" },
    { name: "PALCA", parent: "1207", value: "120706" },
    { name: "PALCAMAYO", parent: "1207", value: "120707" },
    { name: "SAN PEDRO DE CAJAS", parent: "1207", value: "120708" },
    { name: "TAPO", parent: "1207", value: "120709" },
    { name: "LA OROYA", parent: "1208", value: "120801" },
    { name: "CHACAPALPA", parent: "1208", value: "120802" },
    { name: "HUAY-HUAY", parent: "1208", value: "120803" },
    { name: "MARCAPOMACOCHA", parent: "1208", value: "120804" },
    { name: "MOROCOCHA", parent: "1208", value: "120805" },
    { name: "PACCHA", parent: "1208", value: "120806" },
    {
      name: "SANTA BARBARA DE CARHUACAYAN",
      parent: "1208",
      value: "120807",
    },
    { name: "SANTA ROSA DE SACCO", parent: "1208", value: "120808" },
    { name: "SUITUCANCHA", parent: "1208", value: "120809" },
    { name: "YAULI", parent: "1208", value: "120810" },
    { name: "CHUPACA", parent: "1209", value: "120901" },
    { name: "AHUAC", parent: "1209", value: "120902" },
    { name: "CHONGOS BAJO", parent: "1209", value: "120903" },
    { name: "HUACHAC", parent: "1209", value: "120904" },
    { name: "HUAMANCACA CHICO", parent: "1209", value: "120905" },
    { name: "SAN JUAN DE ISCOS", parent: "1209", value: "120906" },
    { name: "SAN JUAN DE JARPA", parent: "1209", value: "120907" },
    { name: "TRES DE DICIEMBRE", parent: "1209", value: "120908" },
    { name: "YANACANCHA", parent: "1209", value: "120909" },
    { name: "TRUJILLO", parent: "1301", value: "130101" },
    { name: "EL PORVENIR", parent: "1301", value: "130102" },
    { name: "FLORENCIA DE MORA", parent: "1301", value: "130103" },
    { name: "HUANCHACO", parent: "1301", value: "130104" },
    { name: "LA ESPERANZA", parent: "1301", value: "130105" },
    { name: "LAREDO", parent: "1301", value: "130106" },
    { name: "MOCHE", parent: "1301", value: "130107" },
    { name: "POROTO", parent: "1301", value: "130108" },
    { name: "SALAVERRY", parent: "1301", value: "130109" },
    { name: "SIMBAL", parent: "1301", value: "130110" },
    { name: "VICTOR LARCO HERRERA", parent: "1301", value: "130111" },
    { name: "ASCOPE", parent: "1302", value: "130201" },
    { name: "CHICAMA", parent: "1302", value: "130202" },
    { name: "CHOCOPE", parent: "1302", value: "130203" },
    { name: "MAGDALENA DE CAO", parent: "1302", value: "130204" },
    { name: "PAIJAN", parent: "1302", value: "130205" },
    { name: "RAZURI", parent: "1302", value: "130206" },
    { name: "SANTIAGO DE CAO", parent: "1302", value: "130207" },
    { name: "CASA GRANDE", parent: "1302", value: "130208" },
    { name: "BOLIVAR", parent: "1303", value: "130301" },
    { name: "BAMBAMARCA", parent: "1303", value: "130302" },
    { name: "CONDORMARCA /1", parent: "1303", value: "130303" },
    { name: "LONGOTEA", parent: "1303", value: "130304" },
    { name: "UCHUMARCA", parent: "1303", value: "130305" },
    { name: "UCUNCHA", parent: "1303", value: "130306" },
    { name: "CHEPEN", parent: "1304", value: "130401" },
    { name: "PACANGA", parent: "1304", value: "130402" },
    { name: "PUEBLO NUEVO", parent: "1304", value: "130403" },
    { name: "JULCAN", parent: "1305", value: "130501" },
    { name: "CALAMARCA", parent: "1305", value: "130502" },
    { name: "CARABAMBA", parent: "1305", value: "130503" },
    { name: "HUASO", parent: "1305", value: "130504" },
    { name: "OTUZCO", parent: "1306", value: "130601" },
    { name: "AGALLPAMPA", parent: "1306", value: "130602" },
    { name: "CHARAT", parent: "1306", value: "130604" },
    { name: "HUARANCHAL", parent: "1306", value: "130605" },
    { name: "LA CUESTA", parent: "1306", value: "130606" },
    { name: "ACHE", parent: "1306", value: "130608" },
    { name: "PARANDAY", parent: "1306", value: "130610" },
    { name: "SALPO", parent: "1306", value: "130611" },
    { name: "SINSICAP", parent: "1306", value: "130613" },
    { name: "USQUIL", parent: "1306", value: "130614" },
    { name: "SAN PEDRO DE LLOC", parent: "1307", value: "130701" },
    { name: "GUADALUPE", parent: "1307", value: "130702" },
    { name: "JEQUETEPEQUE", parent: "1307", value: "130703" },
    { name: "PACASMAYO", parent: "1307", value: "130704" },
    { name: "SAN JOSE", parent: "1307", value: "130705" },
    { name: "TAYABAMBA", parent: "1308", value: "130801" },
    { name: "BULDIBUYO", parent: "1308", value: "130802" },
    { name: "CHILLIA", parent: "1308", value: "130803" },
    { name: "HUANCASPATA", parent: "1308", value: "130804" },
    { name: "HUAYLILLAS", parent: "1308", value: "130805" },
    { name: "HUAYO", parent: "1308", value: "130806" },
    { name: "ONGON", parent: "1308", value: "130807" },
    { name: "PARCOY", parent: "1308", value: "130808" },
    { name: "PATAZ", parent: "1308", value: "130809" },
    { name: "PIAS", parent: "1308", value: "130810" },
    { name: "SANTIAGO DE CHALLAS", parent: "1308", value: "130811" },
    { name: "TAURIJA", parent: "1308", value: "130812" },
    { name: "URPAY", parent: "1308", value: "130813" },
    { name: "HUAMACHUCO", parent: "1309", value: "130901" },
    { name: "CHUGAY", parent: "1309", value: "130902" },
    { name: "COCHORCO", parent: "1309", value: "130903" },
    { name: "CURGOS", parent: "1309", value: "130904" },
    { name: "MARCABAL", parent: "1309", value: "130905" },
    { name: "SANAGORAN", parent: "1309", value: "130906" },
    { name: "SARIN", parent: "1309", value: "130907" },
    { name: "SARTIMBAMBA", parent: "1309", value: "130908" },
    { name: "SANTIAGO DE CHUCO", parent: "1310", value: "131001" },
    { name: "ANGASMARCA", parent: "1310", value: "131002" },
    { name: "CACHICADAN", parent: "1310", value: "131003" },
    { name: "MOLLEBAMBA", parent: "1310", value: "131004" },
    { name: "MOLLEPATA", parent: "1310", value: "131005" },
    { name: "QUIRUVILCA", parent: "1310", value: "131006" },
    { name: "SANTA CRUZ DE CHUCA", parent: "1310", value: "131007" },
    { name: "SITABAMBA", parent: "1310", value: "131008" },
    { name: "CASCAS", parent: "1311", value: "131101" },
    { name: "LUCMA", parent: "1311", value: "131102" },
    { name: "COMPIN", parent: "1311", value: "131103" },
    { name: "SAYAPULLO", parent: "1311", value: "131104" },
    { name: "VIRU", parent: "1312", value: "131201" },
    { name: "CHAO", parent: "1312", value: "131202" },
    { name: "GUADALUPITO", parent: "1312", value: "131203" },
    { name: "CHICLAYO", parent: "1401", value: "140101" },
    { name: "CHONGOYAPE", parent: "1401", value: "140102" },
    { name: "ETEN", parent: "1401", value: "140103" },
    { name: "ETEN PUERTO", parent: "1401", value: "140104" },
    { name: "JOSE LEONARDO ORTIZ", parent: "1401", value: "140105" },
    { name: "LA VICTORIA", parent: "1401", value: "140106" },
    { name: "LAGUNAS", parent: "1401", value: "140107" },
    { name: "MONSEFU", parent: "1401", value: "140108" },
    { name: "NUEVA ARICA", parent: "1401", value: "140109" },
    { name: "OYOTUN", parent: "1401", value: "140110" },
    { name: "PICSI", parent: "1401", value: "140111" },
    { name: "PIMENTEL", parent: "1401", value: "140112" },
    { name: "REQUE", parent: "1401", value: "140113" },
    { name: "SANTA ROSA", parent: "1401", value: "140114" },
    { name: "SAÑA", parent: "1401", value: "140115" },
    { name: "CAYALTI", parent: "1401", value: "140116" },
    { name: "PATAPO", parent: "1401", value: "140117" },
    { name: "POMALCA", parent: "1401", value: "140118" },
    { name: "PUCALA", parent: "1401", value: "140119" },
    { name: "TUMAN", parent: "1401", value: "140120" },
    { name: "FERREÑAFE", parent: "1402", value: "140201" },
    { name: "CAÑARIS", parent: "1402", value: "140202" },
    { name: "INCAHUASI", parent: "1402", value: "140203" },
    {
      name: "MANUEL ANTONIO MESONES MURO",
      parent: "1402",
      value: "140204",
    },
    { name: "PITIPO", parent: "1402", value: "140205" },
    { name: "PUEBLO NUEVO", parent: "1402", value: "140206" },
    { name: "LAMBAYEQUE", parent: "1403", value: "140301" },
    { name: "CHOCHOPE", parent: "1403", value: "140302" },
    { name: "ILLIMO", parent: "1403", value: "140303" },
    { name: "JAYANCA", parent: "1403", value: "140304" },
    { name: "MOCHUMI", parent: "1403", value: "140305" },
    { name: "MORROPE", parent: "1403", value: "140306" },
    { name: "MOTUPE", parent: "1403", value: "140307" },
    { name: "OLMOS", parent: "1403", value: "140308" },
    { name: "PACORA", parent: "1403", value: "140309" },
    { name: "SALAS", parent: "1403", value: "140310" },
    { name: "SAN JOSE", parent: "1403", value: "140311" },
    { name: "TUCUME", parent: "1403", value: "140312" },
    { name: "LIMA", parent: "1501", value: "150101" },
    { name: "ANCON", parent: "1501", value: "150102" },
    { name: "ATE", parent: "1501", value: "150103" },
    { name: "BARRANCO", parent: "1501", value: "150104" },
    { name: "BREÑA", parent: "1501", value: "150105" },
    { name: "CARABAYLLO", parent: "1501", value: "150106" },
    { name: "CHACLACAYO", parent: "1501", value: "150107" },
    { name: "CHORRILLOS", parent: "1501", value: "150108" },
    { name: "CIENEGUILLA", parent: "1501", value: "150109" },
    { name: "COMAS", parent: "1501", value: "150110" },
    { name: "EL AGUSTINO", parent: "1501", value: "150111" },
    { name: "INDEPENDENCIA", parent: "1501", value: "150112" },
    { name: "JESUS MARIA", parent: "1501", value: "150113" },
    { name: "LA MOLINA", parent: "1501", value: "150114" },
    { name: "LA VICTORIA", parent: "1501", value: "150115" },
    { name: "LINCE", parent: "1501", value: "150116" },
    { name: "LOS OLIVOS", parent: "1501", value: "150117" },
    { name: "LURIGANCHO", parent: "1501", value: "150118" },
    { name: "LURIN", parent: "1501", value: "150119" },
    { name: "MAGDALENA DEL MAR", parent: "1501", value: "150120" },
    { name: "PUEBLO LIBRE", parent: "1501", value: "150121" },
    { name: "MIRAFLORES", parent: "1501", value: "150122" },
    { name: "PACHACAMAC", parent: "1501", value: "150123" },
    { name: "PUCUSANA", parent: "1501", value: "150124" },
    { name: "PUENTE PIEDRA", parent: "1501", value: "150125" },
    { name: "PUNTA HERMOSA", parent: "1501", value: "150126" },
    { name: "PUNTA NEGRA", parent: "1501", value: "150127" },
    { name: "RIMAC", parent: "1501", value: "150128" },
    { name: "SAN BARTOLO", parent: "1501", value: "150129" },
    { name: "SAN BORJA", parent: "1501", value: "150130" },
    { name: "SAN ISIDRO", parent: "1501", value: "150131" },
    { name: "SAN JUAN DE LURIGANCHO", parent: "1501", value: "150132" },
    { name: "SAN JUAN DE MIRAFLORES", parent: "1501", value: "150133" },
    { name: "SAN LUIS", parent: "1501", value: "150134" },
    { name: "SAN MARTIN DE PORRES", parent: "1501", value: "150135" },
    { name: "SAN MIGUEL", parent: "1501", value: "150136" },
    { name: "SANTA ANITA", parent: "1501", value: "150137" },
    { name: "SANTA MARIA DEL MAR", parent: "1501", value: "150138" },
    { name: "SANTA ROSA", parent: "1501", value: "150139" },
    { name: "SANTIAGO DE SURCO", parent: "1501", value: "150140" },
    { name: "SURQUILLO", parent: "1501", value: "150141" },
    { name: "VILLA EL SALVADOR", parent: "1501", value: "150142" },
    { name: "VILLA MARIA DEL TRIUNFO", parent: "1501", value: "150143" },
    { name: "BARRANCA", parent: "1502", value: "150201" },
    { name: "PARAMONGA", parent: "1502", value: "150202" },
    { name: "PATIVILCA", parent: "1502", value: "150203" },
    { name: "SUPE", parent: "1502", value: "150204" },
    { name: "SUPE PUERTO", parent: "1502", value: "150205" },
    { name: "CAJATAMBO", parent: "1503", value: "150301" },
    { name: "COPA", parent: "1503", value: "150302" },
    { name: "GORGOR", parent: "1503", value: "150303" },
    { name: "HUANCAPON", parent: "1503", value: "150304" },
    { name: "MANAS", parent: "1503", value: "150305" },
    { name: "CANTA", parent: "1504", value: "150401" },
    { name: "ARAHUAY", parent: "1504", value: "150402" },
    { name: "HUAMANTANGA", parent: "1504", value: "150403" },
    { name: "HUAROS", parent: "1504", value: "150404" },
    { name: "LACHAQUI", parent: "1504", value: "150405" },
    { name: "SAN BUENAVENTURA", parent: "1504", value: "150406" },
    { name: "SANTA ROSA DE QUIVES", parent: "1504", value: "150407" },
    { name: "SAN VICENTE DE CAÑETE", parent: "1505", value: "150501" },
    { name: "ASIA", parent: "1505", value: "150502" },
    { name: "CALANGO", parent: "1505", value: "150503" },
    { name: "CERRO AZUL", parent: "1505", value: "150504" },
    { name: "CHILCA", parent: "1505", value: "150505" },
    { name: "COAYLLO", parent: "1505", value: "150506" },
    { name: "IMPERIAL", parent: "1505", value: "150507" },
    { name: "LUNAHUANA", parent: "1505", value: "150508" },
    { name: "MALA", parent: "1505", value: "150509" },
    { name: "NUEVO IMPERIAL", parent: "1505", value: "150510" },
    { name: "PACARAN", parent: "1505", value: "150511" },
    { name: "QUILMANA", parent: "1505", value: "150512" },
    { name: "SAN ANTONIO", parent: "1505", value: "150513" },
    { name: "SAN LUIS", parent: "1505", value: "150514" },
    { name: "SANTA CRUZ DE FLORES", parent: "1505", value: "150515" },
    { name: "ZUÑIGA", parent: "1505", value: "150516" },
    { name: "HUARAL", parent: "1506", value: "150601" },
    { name: "ATAVILLOS ALTO", parent: "1506", value: "150602" },
    { name: "ATAVILLOS BAJO", parent: "1506", value: "150603" },
    { name: "AUCALLAMA", parent: "1506", value: "150604" },
    { name: "CHANCAY", parent: "1506", value: "150605" },
    { name: "IHUARI", parent: "1506", value: "150606" },
    { name: "LAMPIAN", parent: "1506", value: "150607" },
    { name: "PACARAOS", parent: "1506", value: "150608" },
    { name: "SAN MIGUEL DE ACOS", parent: "1506", value: "150609" },
    { name: "SANTA CRUZ DE ANDAMARCA", parent: "1506", value: "150610" },
    { name: "SUMBILCA", parent: "1506", value: "150611" },
    { name: "VEINTISIETE DE NOVIEMBRE", parent: "1506", value: "150612" },
    { name: "MATUCANA", parent: "1507", value: "150701" },
    { name: "ANTIOQUIA", parent: "1507", value: "150702" },
    { name: "CALLAHUANCA", parent: "1507", value: "150703" },
    { name: "CARAMPOMA", parent: "1507", value: "150704" },
    { name: "CHICLA", parent: "1507", value: "150705" },
    { name: "CUENCA", parent: "1507", value: "150706" },
    { name: "HUACHUPAMPA", parent: "1507", value: "150707" },
    { name: "HUANZA", parent: "1507", value: "150708" },
    { name: "HUAROCHIRI", parent: "1507", value: "150709" },
    { name: "LAHUAYTAMBO", parent: "1507", value: "150710" },
    { name: "LANGA", parent: "1507", value: "150711" },
    { name: "LARAOS", parent: "1507", value: "150712" },
    { name: "MARIATANA", parent: "1507", value: "150713" },
    { name: "RICARDO PALMA", parent: "1507", value: "150714" },
    { name: "SAN ANDRES DE TUPICOCHA", parent: "1507", value: "150715" },
    { name: "SAN ANTONIO", parent: "1507", value: "150716" },
    { name: "SAN BARTOLOME", parent: "1507", value: "150717" },
    { name: "SAN DAMIAN", parent: "1507", value: "150718" },
    { name: "SAN JUAN DE IRIS", parent: "1507", value: "150719" },
    { name: "SAN JUAN DE TANTARANCHE", parent: "1507", value: "150720" },
    { name: "SAN LORENZO DE QUINTI", parent: "1507", value: "150721" },
    { name: "SAN MATEO", parent: "1507", value: "150722" },
    { name: "SAN MATEO DE OTAO", parent: "1507", value: "150723" },
    { name: "SAN PEDRO DE CASTA", parent: "1507", value: "150724" },
    { name: "SAN PEDRO DE HUANCAYRE", parent: "1507", value: "150725" },
    { name: "SANGALLAYA", parent: "1507", value: "150726" },
    { name: "SANTA CRUZ DE COCACHACRA", parent: "1507", value: "150727" },
    { name: "SANTA EULALIA", parent: "1507", value: "150728" },
    { name: "SANTIAGO DE ANCHUCAYA", parent: "1507", value: "150729" },
    { name: "SANTIAGO DE TUNA", parent: "1507", value: "150730" },
    {
      name: "SANTO DOMINGO DE LOS OLLEROS",
      parent: "1507",
      value: "150731",
    },
    { name: "SURCO", parent: "1507", value: "150732" },
    { name: "HUACHO", parent: "1508", value: "150801" },
    { name: "AMBAR", parent: "1508", value: "150802" },
    { name: "CALETA DE CARQUIN", parent: "1508", value: "150803" },
    { name: "CHECRAS", parent: "1508", value: "150804" },
    { name: "HUALMAY", parent: "1508", value: "150805" },
    { name: "HUAURA", parent: "1508", value: "150806" },
    { name: "LEONCIO PRADO", parent: "1508", value: "150807" },
    { name: "PACCHO", parent: "1508", value: "150808" },
    { name: "SANTA LEONOR", parent: "1508", value: "150809" },
    { name: "SANTA MARIA", parent: "1508", value: "150810" },
    { name: "SAYAN", parent: "1508", value: "150811" },
    { name: "VEGUETA", parent: "1508", value: "150812" },
    { name: "OYON", parent: "1509", value: "150901" },
    { name: "ANDAJES", parent: "1509", value: "150902" },
    { name: "CAUJUL", parent: "1509", value: "150903" },
    { name: "COCHAMARCA", parent: "1509", value: "150904" },
    { name: "NAVAN", parent: "1509", value: "150905" },
    { name: "PACHANGARA", parent: "1509", value: "150906" },
    { name: "YAUYOS", parent: "1510", value: "151001" },
    { name: "ALIS", parent: "1510", value: "151002" },
    { name: "ALLAUCA", parent: "1510", value: "151003" },
    { name: "AYAVIRI", parent: "1510", value: "151004" },
    { name: "AZANGARO", parent: "1510", value: "151005" },
    { name: "CACRA", parent: "1510", value: "151006" },
    { name: "CARANIA", parent: "1510", value: "151007" },
    { name: "CATAHUASI", parent: "1510", value: "151008" },
    { name: "CHOCOS", parent: "1510", value: "151009" },
    { name: "COCHAS", parent: "1510", value: "151010" },
    { name: "COLONIA", parent: "1510", value: "151011" },
    { name: "HONGOS", parent: "1510", value: "151012" },
    { name: "HUAMPARA", parent: "1510", value: "151013" },
    { name: "HUANCAYA", parent: "1510", value: "151014" },
    { name: "HUANGASCAR", parent: "1510", value: "151015" },
    { name: "HUANTAN", parent: "1510", value: "151016" },
    { name: "HUAÑEC", parent: "1510", value: "151017" },
    { name: "LARAOS", parent: "1510", value: "151018" },
    { name: "LINCHA", parent: "1510", value: "151019" },
    { name: "MADEAN", parent: "1510", value: "151020" },
    { name: "MIRAFLORES", parent: "1510", value: "151021" },
    { name: "OMAS", parent: "1510", value: "151022" },
    { name: "PUTINZA", parent: "1510", value: "151023" },
    { name: "QUINCHES", parent: "1510", value: "151024" },
    { name: "QUINOCAY", parent: "1510", value: "151025" },
    { name: "SAN JOAQUIN", parent: "1510", value: "151026" },
    { name: "SAN PEDRO DE PILAS", parent: "1510", value: "151027" },
    { name: "TANTA", parent: "1510", value: "151028" },
    { name: "TAURIPAMPA", parent: "1510", value: "151029" },
    { name: "TOMAS", parent: "1510", value: "151030" },
    { name: "TUPE", parent: "1510", value: "151031" },
    { name: "VIÑAC", parent: "1510", value: "151032" },
    { name: "VITIS", parent: "1510", value: "151033" },
    { name: "IQUITOS", parent: "1601", value: "160101" },
    { name: "ALTO NANAY", parent: "1601", value: "160102" },
    { name: "FERNANDO LORES", parent: "1601", value: "160103" },
    { name: "INDIANA", parent: "1601", value: "160104" },
    { name: "LAS AMAZONAS", parent: "1601", value: "160105" },
    { name: "MAZAN", parent: "1601", value: "160106" },
    { name: "NAPO", parent: "1601", value: "160107" },
    { name: "PUNCHANA", parent: "1601", value: "160108" },
    { name: "PUTUMAYO", parent: "1601", value: "160109" },
    { name: "TORRES CAUSANA", parent: "1601", value: "160110" },
    { name: "BELEN", parent: "1601", value: "160112" },
    { name: "SAN JUAN BAUTISTA", parent: "1601", value: "160113" },
    { name: "TENIENTE MANUEL CLAVERO", parent: "1601", value: "160114" },
    { name: "YURIMAGUAS", parent: "1602", value: "160201" },
    { name: "BALSAPUERTO", parent: "1602", value: "160202" },
    { name: "JEBEROS", parent: "1602", value: "160205" },
    { name: "LAGUNAS", parent: "1602", value: "160206" },
    { name: "SANTA CRUZ", parent: "1602", value: "160210" },
    { name: "TENIENTE CESAR LOPEZ ROJAS", parent: "1602", value: "160211" },
    { name: "NAUTA", parent: "1603", value: "160301" },
    { name: "PARINARI", parent: "1603", value: "160302" },
    { name: "TIGRE", parent: "1603", value: "160303" },
    { name: "TROMPETEROS", parent: "1603", value: "160304" },
    { name: "URARINAS", parent: "1603", value: "160305" },
    { name: "RAMON CASTILLA", parent: "1604", value: "160401" },
    { name: "PEBAS", parent: "1604", value: "160402" },
    { name: "YAVARI /1", parent: "1604", value: "160403" },
    { name: "SAN PABLO", parent: "1604", value: "160404" },
    { name: "REQUENA", parent: "1605", value: "160501" },
    { name: "ALTO TAPICHE", parent: "1605", value: "160502" },
    { name: "CAPELO", parent: "1605", value: "160503" },
    { name: "EMILIO SAN MARTIN", parent: "1605", value: "160504" },
    { name: "MAQUIA", parent: "1605", value: "160505" },
    { name: "PUINAHUA", parent: "1605", value: "160506" },
    { name: "SAQUENA", parent: "1605", value: "160507" },
    { name: "SOPLIN", parent: "1605", value: "160508" },
    { name: "TAPICHE", parent: "1605", value: "160509" },
    { name: "JENARO HERRERA", parent: "1605", value: "160510" },
    { name: "YAQUERANA", parent: "1605", value: "160511" },
    { name: "CONTAMANA", parent: "1606", value: "160601" },
    { name: "INAHUAYA", parent: "1606", value: "160602" },
    { name: "PADRE MARQUEZ", parent: "1606", value: "160603" },
    { name: "PAMPA HERMOSA", parent: "1606", value: "160604" },
    { name: "SARAYACU", parent: "1606", value: "160605" },
    { name: "VARGAS GUERRA", parent: "1606", value: "160606" },
    { name: "BARRANCA", parent: "1607", value: "160701" },
    { name: "CAHUAPANAS", parent: "1607", value: "160702" },
    { name: "MANSERICHE", parent: "1607", value: "160703" },
    { name: "MORONA", parent: "1607", value: "160704" },
    { name: "PASTAZA", parent: "1607", value: "160705" },
    { name: "ANDOAS", parent: "1607", value: "160706" },
    { name: "TAMBOPATA", parent: "1701", value: "170101" },
    { name: "INAMBARI", parent: "1701", value: "170102" },
    { name: "LAS PIEDRAS", parent: "1701", value: "170103" },
    { name: "LABERINTO", parent: "1701", value: "170104" },
    { name: "MANU", parent: "1702", value: "170201" },
    { name: "FITZCARRALD", parent: "1702", value: "170202" },
    { name: "MADRE DE DIOS", parent: "1702", value: "170203" },
    { name: "HUEPETUHE", parent: "1702", value: "170204" },
    { name: "IÑAPARI", parent: "1703", value: "170301" },
    { name: "IBERIA", parent: "1703", value: "170302" },
    { name: "TAHUAMANU", parent: "1703", value: "170303" },
    { name: "MOQUEGUA", parent: "1801", value: "180101" },
    { name: "CARUMAS", parent: "1801", value: "180102" },
    { name: "CUCHUMBAYA", parent: "1801", value: "180103" },
    { name: "SAMEGUA", parent: "1801", value: "180104" },
    { name: "SAN CRISTOBAL", parent: "1801", value: "180105" },
    { name: "TORATA", parent: "1801", value: "180106" },
    { name: "OMATE", parent: "1802", value: "180201" },
    { name: "CHOJATA", parent: "1802", value: "180202" },
    { name: "COALAQUE", parent: "1802", value: "180203" },
    { name: "ICHUÑA", parent: "1802", value: "180204" },
    { name: "LA CAPILLA", parent: "1802", value: "180205" },
    { name: "LLOQUE", parent: "1802", value: "180206" },
    { name: "MATALAQUE", parent: "1802", value: "180207" },
    { name: "PUQUINA", parent: "1802", value: "180208" },
    { name: "QUINISTAQUILLAS", parent: "1802", value: "180209" },
    { name: "UBINAS", parent: "1802", value: "180210" },
    { name: "YUNGA", parent: "1802", value: "180211" },
    { name: "ILO", parent: "1803", value: "180301" },
    { name: "EL ALGARROBAL", parent: "1803", value: "180302" },
    { name: "PACOCHA", parent: "1803", value: "180303" },
    { name: "CHAUPIMARCA", parent: "1901", value: "190101" },
    { name: "HUACHON", parent: "1901", value: "190102" },
    { name: "HUARIACA", parent: "1901", value: "190103" },
    { name: "HUAYLLAY", parent: "1901", value: "190104" },
    { name: "NINACACA", parent: "1901", value: "190105" },
    { name: "PALLANCHACRA", parent: "1901", value: "190106" },
    { name: "PAUCARTAMBO", parent: "1901", value: "190107" },
    {
      name: "SAN FRANCISCO DE ASIS DE YARUSYACAN",
      parent: "1901",
      value: "190108",
    },
    { name: "SIMON BOLIVAR", parent: "1901", value: "190109" },
    { name: "TICLACAYAN", parent: "1901", value: "190110" },
    { name: "TINYAHUARCO", parent: "1901", value: "190111" },
    { name: "VICCO", parent: "1901", value: "190112" },
    { name: "YANACANCHA", parent: "1901", value: "190113" },
    { name: "ANAHUANCA", parent: "1902", value: "190201" },
    { name: "CHACAYAN", parent: "1902", value: "190202" },
    { name: "GOYLLARISQUIZGA", parent: "1902", value: "190203" },
    { name: "PAUCAR", parent: "1902", value: "190204" },
    { name: "SAN PEDRO DE PILLAO", parent: "1902", value: "190205" },
    { name: "SANTA ANA DE TUSI", parent: "1902", value: "190206" },
    { name: "TAPUC", parent: "1902", value: "190207" },
    { name: "VILCABAMBA", parent: "1902", value: "190208" },
    { name: "OXAPAMPA", parent: "1903", value: "190301" },
    { name: "CHONTABAMBA", parent: "1903", value: "190302" },
    { name: "HUANCABAMBA", parent: "1903", value: "190303" },
    { name: "PALCAZU", parent: "1903", value: "190304" },
    { name: "POZUZO", parent: "1903", value: "190305" },
    { name: "PUERTO BERMUDEZ", parent: "1903", value: "190306" },
    { name: "VILLA RICA", parent: "1903", value: "190307" },
    { name: "PIURA", parent: "2001", value: "200101" },
    { name: "CASTILLA", parent: "2001", value: "200104" },
    { name: "CATACAOS", parent: "2001", value: "200105" },
    { name: "CURA MORI", parent: "2001", value: "200107" },
    { name: "EL TALLAN", parent: "2001", value: "200108" },
    { name: "LA ARENA", parent: "2001", value: "200109" },
    { name: "LA UNION", parent: "2001", value: "200110" },
    { name: "LAS LOMAS", parent: "2001", value: "200111" },
    { name: "TAMBO GRANDE", parent: "2001", value: "200114" },
    { name: "AYABACA", parent: "2002", value: "200201" },
    { name: "FRIAS", parent: "2002", value: "200202" },
    { name: "JILILI", parent: "2002", value: "200203" },
    { name: "LAGUNAS", parent: "2002", value: "200204" },
    { name: "MONTERO", parent: "2002", value: "200205" },
    { name: "PACAIPAMPA", parent: "2002", value: "200206" },
    { name: "PAIMAS", parent: "2002", value: "200207" },
    { name: "SAPILLICA", parent: "2002", value: "200208" },
    { name: "SICCHEZ", parent: "2002", value: "200209" },
    { name: "SUYO", parent: "2002", value: "200210" },
    { name: "HUANCABAMBA", parent: "2003", value: "200301" },
    { name: "CANCHAQUE", parent: "2003", value: "200302" },
    { name: "EL CARMEN DE LA FRONTERA", parent: "2003", value: "200303" },
    { name: "HUARMACA", parent: "2003", value: "200304" },
    { name: "LALAQUIZ", parent: "2003", value: "200305" },
    { name: "SAN MIGUEL DE EL FAIQUE", parent: "2003", value: "200306" },
    { name: "SONDOR", parent: "2003", value: "200307" },
    { name: "SONDORILLO", parent: "2003", value: "200308" },
    { name: "CHULUCANAS", parent: "2004", value: "200401" },
    { name: "BUENOS AIRES", parent: "2004", value: "200402" },
    { name: "CHALACO", parent: "2004", value: "200403" },
    { name: "LA MATANZA", parent: "2004", value: "200404" },
    { name: "MORROPON", parent: "2004", value: "200405" },
    { name: "SALITRAL", parent: "2004", value: "200406" },
    { name: "SAN JUAN DE BIGOTE", parent: "2004", value: "200407" },
    { name: "SANTA CATALINA DE MOSSA", parent: "2004", value: "200408" },
    { name: "SANTO DOMINGO", parent: "2004", value: "200409" },
    { name: "YAMANGO", parent: "2004", value: "200410" },
    { name: "PAITA", parent: "2005", value: "200501" },
    { name: "AMOTAPE", parent: "2005", value: "200502" },
    { name: "ARENAL", parent: "2005", value: "200503" },
    { name: "COLAN", parent: "2005", value: "200504" },
    { name: "LA HUACA", parent: "2005", value: "200505" },
    { name: "TAMARINDO", parent: "2005", value: "200506" },
    { name: "VICHAYAL", parent: "2005", value: "200507" },
    { name: "SULLANA", parent: "2006", value: "200601" },
    { name: "BELLAVISTA", parent: "2006", value: "200602" },
    { name: "IGNACIO ESCUDERO", parent: "2006", value: "200603" },
    { name: "LANCONES", parent: "2006", value: "200604" },
    { name: "MARCAVELICA", parent: "2006", value: "200605" },
    { name: "MIGUEL CHECA", parent: "2006", value: "200606" },
    { name: "QUERECOTILLO", parent: "2006", value: "200607" },
    { name: "SALITRAL", parent: "2006", value: "200608" },
    { name: "PARIÑAS", parent: "2007", value: "200701" },
    { name: "EL ALTO", parent: "2007", value: "200702" },
    { name: "LA BREA", parent: "2007", value: "200703" },
    { name: "LOBITOS", parent: "2007", value: "200704" },
    { name: "LOS ORGANOS", parent: "2007", value: "200705" },
    { name: "MANCORA", parent: "2007", value: "200706" },
    { name: "SECHURA", parent: "2008", value: "200801" },
    { name: "BELLAVISTA DE LA UNION", parent: "2008", value: "200802" },
    { name: "BERNAL", parent: "2008", value: "200803" },
    { name: "CRISTO NOS VALGA", parent: "2008", value: "200804" },
    { name: "VICE", parent: "2008", value: "200805" },
    { name: "RINCONADA LLICUAR", parent: "2008", value: "200806" },
    { name: "PUNO", parent: "2101", value: "210101" },
    { name: "ACORA", parent: "2101", value: "210102" },
    { name: "AMANTANI", parent: "2101", value: "210103" },
    { name: "ATUNCOLLA", parent: "2101", value: "210104" },
    { name: "CAPACHICA", parent: "2101", value: "210105" },
    { name: "CHUCUITO", parent: "2101", value: "210106" },
    { name: "COATA", parent: "2101", value: "210107" },
    { name: "HUATA", parent: "2101", value: "210108" },
    { name: "MAÑAZO", parent: "2101", value: "210109" },
    { name: "PAUCARCOLLA", parent: "2101", value: "210110" },
    { name: "PICHACANI", parent: "2101", value: "210111" },
    { name: "PLATERIA", parent: "2101", value: "210112" },
    { name: "SAN ANTONIO /1", parent: "2101", value: "210113" },
    { name: "TIQUILLACA", parent: "2101", value: "210114" },
    { name: "VILQUE", parent: "2101", value: "210115" },
    { name: "AZANGARO", parent: "2102", value: "210201" },
    { name: "ACHAYA", parent: "2102", value: "210202" },
    { name: "ARAPA", parent: "2102", value: "210203" },
    { name: "ASILLO", parent: "2102", value: "210204" },
    { name: "CAMINACA", parent: "2102", value: "210205" },
    { name: "CHUPA", parent: "2102", value: "210206" },
    { name: "JOSE DOMINGO CHOQUEHUANCA", parent: "2102", value: "210207" },
    { name: "MUÑANI", parent: "2102", value: "210208" },
    { name: "POTONI", parent: "2102", value: "210209" },
    { name: "SAMAN", parent: "2102", value: "210210" },
    { name: "SAN ANTON", parent: "2102", value: "210211" },
    { name: "SAN JOSE", parent: "2102", value: "210212" },
    { name: "SAN JUAN DE SALINAS", parent: "2102", value: "210213" },
    { name: "SANTIAGO DE PUPUJA", parent: "2102", value: "210214" },
    { name: "TIRAPATA", parent: "2102", value: "210215" },
    { name: "MACUSANI", parent: "2103", value: "210301" },
    { name: "AJOYANI", parent: "2103", value: "210302" },
    { name: "COASA", parent: "2103", value: "210304" },
    { name: "AYAPATA", parent: "2103", value: "210303" },
    { name: "CORANI", parent: "2103", value: "210305" },
    { name: "CRUCERO", parent: "2103", value: "210306" },
    { name: "ITUATA 2/", parent: "2103", value: "210307" },
    { name: "OLLACHEA", parent: "2103", value: "210308" },
    { name: "SAN GABAN", parent: "2103", value: "210309" },
    { name: "USICAYOS", parent: "2103", value: "210310" },
    { name: "JULI", parent: "2104", value: "210401" },
    { name: "DESAGUADERO", parent: "2104", value: "210402" },
    { name: "HUACULLANI", parent: "2104", value: "210403" },
    { name: "KELLUYO", parent: "2104", value: "210404" },
    { name: "PISACOMA", parent: "2104", value: "210405" },
    { name: "POMATA", parent: "2104", value: "210406" },
    { name: "ZEPITA", parent: "2104", value: "210407" },
    { name: "ILAVE", parent: "2105", value: "210501" },
    { name: "CAPAZO", parent: "2105", value: "210502" },
    { name: "PILCUYO", parent: "2105", value: "210503" },
    { name: "SANTA ROSA", parent: "2105", value: "210504" },
    { name: "CONDURIRI", parent: "2105", value: "210505" },
    { name: "HUANCANE", parent: "2106", value: "210601" },
    { name: "COJATA", parent: "2106", value: "210602" },
    { name: "HUATASANI", parent: "2106", value: "210603" },
    { name: "INCHUPALLA", parent: "2106", value: "210604" },
    { name: "PUSI", parent: "2106", value: "210605" },
    { name: "ROSASPATA", parent: "2106", value: "210606" },
    { name: "TARACO", parent: "2106", value: "210607" },
    { name: "VILQUE CHICO", parent: "2106", value: "210608" },
    { name: "LAMPA", parent: "2107", value: "210701" },
    { name: "CABANILLA", parent: "2107", value: "210702" },
    { name: "CALAPUJA", parent: "2107", value: "210703" },
    { name: "NICASIO", parent: "2107", value: "210704" },
    { name: "OCUVIRI", parent: "2107", value: "210705" },
    { name: "PALCA", parent: "2107", value: "210706" },
    { name: "PARATIA", parent: "2107", value: "210707" },
    { name: "PUCARA", parent: "2107", value: "210708" },
    { name: "SANTA LUCIA", parent: "2107", value: "210709" },
    { name: "VILAVILA", parent: "2107", value: "210710" },
    { name: "AYAVIRI", parent: "2108", value: "210801" },
    { name: "ANTAUTA", parent: "2108", value: "210802" },
    { name: "CUPI", parent: "2108", value: "210803" },
    { name: "LLALLI", parent: "2108", value: "210804" },
    { name: "MACARI", parent: "2108", value: "210805" },
    { name: "NUÑOA", parent: "2108", value: "210806" },
    { name: "ORURILLO", parent: "2108", value: "210807" },
    { name: "SANTA ROSA", parent: "2108", value: "210808" },
    { name: "UMACHIRI", parent: "2108", value: "210809" },
    { name: "MOHO", parent: "2109", value: "210901" },
    { name: "CONIMA", parent: "2109", value: "210902" },
    { name: "HUAYRAPATA", parent: "2109", value: "210903" },
    { name: "TILALI", parent: "2109", value: "210904" },
    { name: "PUTINA", parent: "2110", value: "211001" },
    { name: "ANANEA", parent: "2110", value: "211002" },
    { name: "PEDRO VILCA APAZA", parent: "2110", value: "211003" },
    { name: "QUILCAPUNCU", parent: "2110", value: "211004" },
    { name: "SINA", parent: "2110", value: "211005" },
    { name: "JULIACA", parent: "2111", value: "211101" },
    { name: "CABANA", parent: "2111", value: "211102" },
    { name: "CABANILLAS", parent: "2111", value: "211103" },
    { name: "CARACOTO", parent: "2111", value: "211104" },
    { name: "SANDIA", parent: "2112", value: "211201" },
    { name: "CUYOCUYO", parent: "2112", value: "211202" },
    { name: "LIMBANI", parent: "2112", value: "211203" },
    { name: "PATAMBUCO", parent: "2112", value: "211204" },
    { name: "PHARA", parent: "2112", value: "211205" },
    { name: "QUIACA", parent: "2112", value: "211206" },
    { name: "SAN JUAN DEL ORO", parent: "2112", value: "211207" },
    { name: "YANAHUAYA", parent: "2112", value: "211208" },
    { name: "ALTO INAMBARI", parent: "2112", value: "211209" },
    { name: "SAN PEDRO DE PUTINA PUNCO", parent: "2112", value: "211210" },
    { name: "YUNGUYO", parent: "2113", value: "211301" },
    { name: "ANAPIA", parent: "2113", value: "211302" },
    { name: "COPANI", parent: "2113", value: "211303" },
    { name: "CUTURAPI", parent: "2113", value: "211304" },
    { name: "OLLARAYA", parent: "2113", value: "211305" },
    { name: "TINICACHI", parent: "2113", value: "211306" },
    { name: "UNICACHI", parent: "2113", value: "211307" },
    { name: "MOYOBAMBA", parent: "2201", value: "220101" },
    { name: "CALZADA", parent: "2201", value: "220102" },
    { name: "HABANA", parent: "2201", value: "220103" },
    { name: "JEPELACIO", parent: "2201", value: "220104" },
    { name: "SORITOR", parent: "2201", value: "220105" },
    { name: "YANTALO", parent: "2201", value: "220106" },
    { name: "BELLAVISTA", parent: "2202", value: "220201" },
    { name: "ALTO BIAVO", parent: "2202", value: "220202" },
    { name: "BAJO BIAVO", parent: "2202", value: "220203" },
    { name: "HUALLAGA", parent: "2202", value: "220204" },
    { name: "SAN PABLO", parent: "2202", value: "220205" },
    { name: "SAN RAFAEL", parent: "2202", value: "220206" },
    { name: "SAN JOSE DE SISA", parent: "2203", value: "220301" },
    { name: "AGUA BLANCA", parent: "2203", value: "220302" },
    { name: "SAN MARTIN", parent: "2203", value: "220303" },
    { name: "SANTA ROSA", parent: "2203", value: "220304" },
    { name: "SHATOJA", parent: "2203", value: "220305" },
    { name: "SAPOSOA", parent: "2204", value: "220401" },
    { name: "ALTO SAPOSOA", parent: "2204", value: "220402" },
    { name: "EL ESLABON", parent: "2204", value: "220403" },
    { name: "PISCOYACU", parent: "2204", value: "220404" },
    { name: "SACANCHE", parent: "2204", value: "220405" },
    { name: "TINGO DE SAPOSOA", parent: "2204", value: "220406" },
    { name: "LAMAS", parent: "2205", value: "220501" },
    { name: "ALONSO DE ALVARADO", parent: "2205", value: "220502" },
    { name: "BARRANQUITA", parent: "2205", value: "220503" },
    { name: "CAYNARACHI 1/", parent: "2205", value: "220504" },
    { name: "CUÑUMBUQUI", parent: "2205", value: "220505" },
    { name: "PINTO RECODO", parent: "2205", value: "220506" },
    { name: "RUMISAPA", parent: "2205", value: "220507" },
    { name: "SAN ROQUE DE CUMBAZA", parent: "2205", value: "220508" },
    { name: "SHANAO", parent: "2205", value: "220509" },
    { name: "TABALOSOS", parent: "2205", value: "220510" },
    { name: "ZAPATERO", parent: "2205", value: "220511" },
    { name: "JUANJUI", parent: "2206", value: "220601" },
    { name: "CAMPANILLA", parent: "2206", value: "220602" },
    { name: "HUICUNGO", parent: "2206", value: "220603" },
    { name: "PACHIZA", parent: "2206", value: "220604" },
    { name: "PAJARILLO", parent: "2206", value: "220605" },
    { name: "PICOTA", parent: "2207", value: "220701" },
    { name: "BUENOS AIRES", parent: "2207", value: "220702" },
    { name: "CASPISAPA", parent: "2207", value: "220703" },
    { name: "PILLUANA", parent: "2207", value: "220704" },
    { name: "PUCACACA", parent: "2207", value: "220705" },
    { name: "SAN CRISTOBAL", parent: "2207", value: "220706" },
    { name: "SAN HILARION", parent: "2207", value: "220707" },
    { name: "SHAMBOYACU", parent: "2207", value: "220708" },
    { name: "TINGO DE PONASA", parent: "2207", value: "220709" },
    { name: "TRES UNIDOS", parent: "2207", value: "220710" },
    { name: "RIOJA", parent: "2208", value: "220801" },
    { name: "AWAJUN", parent: "2208", value: "220802" },
    { name: "ELIAS SOPLIN VARGAS", parent: "2208", value: "220803" },
    { name: "NUEVA CAJAMARCA", parent: "2208", value: "220804" },
    { name: "PARDO MIGUEL", parent: "2208", value: "220805" },
    { name: "POSIC", parent: "2208", value: "220806" },
    { name: "SAN FERNANDO", parent: "2208", value: "220807" },
    { name: "YORONGOS", parent: "2208", value: "220808" },
    { name: "YURACYACU", parent: "2208", value: "220809" },
    { name: "TARAPOTO", parent: "2209", value: "220901" },
    { name: "ALBERTO LEVEAU", parent: "2209", value: "220902" },
    { name: "CACATACHI", parent: "2209", value: "220903" },
    { name: "CHAZUTA", parent: "2209", value: "220904" },
    { name: "CHIPURANA", parent: "2209", value: "220905" },
    { name: "EL PORVENIR", parent: "2209", value: "220906" },
    { name: "HUIMBAYOC", parent: "2209", value: "220907" },
    { name: "JUAN GUERRA", parent: "2209", value: "220908" },
    { name: "LA BANDA DE SHILCAYO", parent: "2209", value: "220909" },
    { name: "MORALES", parent: "2209", value: "220910" },
    { name: "PAPAPLAYA", parent: "2209", value: "220911" },
    { name: "SAN ANTONIO", parent: "2209", value: "220912" },
    { name: "SAUCE", parent: "2209", value: "220913" },
    { name: "SHAPAJA", parent: "2209", value: "220914" },
    { name: "TOCACHE", parent: "2210", value: "221001" },
    { name: "NUEVO PROGRESO", parent: "2210", value: "221002" },
    { name: "POLVORA", parent: "2210", value: "221003" },
    { name: "SHUNTE 2/", parent: "2210", value: "221004" },
    { name: "UCHIZA", parent: "2210", value: "221005" },
    { name: "TACNA", parent: "2301", value: "230101" },
    { name: "ALTO DE LA ALIANZA", parent: "2301", value: "230102" },
    { name: "CALANA", parent: "2301", value: "230103" },
    { name: "CIUDAD NUEVA", parent: "2301", value: "230104" },
    { name: "INCLAN", parent: "2301", value: "230105" },
    { name: "PACHIA", parent: "2301", value: "230106" },
    { name: "PALCA", parent: "2301", value: "230107" },
    { name: "POCOLLAY", parent: "2301", value: "230108" },
    { name: "SAMA", parent: "2301", value: "230109" },
    {
      name: "CORONEL GREGORIO ALBARRACIN LANCHIPA",
      parent: "2301",
      value: "230110",
    },
    { name: "CANDARAVE", parent: "2302", value: "230201" },
    { name: "CAIRANI", parent: "2302", value: "230202" },
    { name: "CAMILACA", parent: "2302", value: "230203" },
    { name: "CURIBAYA", parent: "2302", value: "230204" },
    { name: "HUANUARA", parent: "2302", value: "230205" },
    { name: "QUILAHUANI", parent: "2302", value: "230206" },
    { name: "LOCUMBA", parent: "2303", value: "230301" },
    { name: "ILABAYA", parent: "2303", value: "230302" },
    { name: "ITE", parent: "2303", value: "230303" },
    { name: "TARATA", parent: "2304", value: "230401" },
    { name: "HEROES ALBARRACIN", parent: "2304", value: "230402" },
    { name: "ESTIQUE", parent: "2304", value: "230403" },
    { name: "ESTIQUE-PAMPA", parent: "2304", value: "230404" },
    { name: "SITAJARA", parent: "2304", value: "230405" },
    { name: "SUSAPAYA", parent: "2304", value: "230406" },
    { name: "TARUCACHI", parent: "2304", value: "230407" },
    { name: "TICACO", parent: "2304", value: "230408" },
    { name: "TUMBES", parent: "2401", value: "240101" },
    { name: "CORRALES", parent: "2401", value: "240102" },
    { name: "LA CRUZ", parent: "2401", value: "240103" },
    { name: "PAMPAS DE HOSPITAL", parent: "2401", value: "240104" },
    { name: "SAN JACINTO", parent: "2401", value: "240105" },
    { name: "SAN JUAN DE LA VIRGEN", parent: "2401", value: "240106" },
    { name: "ZORRITOS", parent: "2402", value: "240201" },
    { name: "CASITAS", parent: "2402", value: "240202" },
    { name: "CANOAS DE PUNTA SAL", parent: "2402", value: "240203" },
    { name: "ZARUMILLA", parent: "2403", value: "240301" },
    { name: "AGUAS VERDES", parent: "2403", value: "240302" },
    { name: "MATAPALO", parent: "2403", value: "240303" },
    { name: "PAPAYAL", parent: "2403", value: "240304" },
    { name: "CALLERIA", parent: "2501", value: "250101" },
    { name: "CAMPOVERDE", parent: "2501", value: "250102" },
    { name: "IPARIA", parent: "2501", value: "250103" },
    { name: "MASISEA", parent: "2501", value: "250104" },
    { name: "YARINACOCHA", parent: "2501", value: "250105" },
    { name: "NUEVA REQUENA", parent: "2501", value: "250106" },
    { name: "MANANTAY", parent: "2501", value: "250107" },
    { name: "RAYMONDI", parent: "2502", value: "250201" },
    { name: "SEPAHUA", parent: "2502", value: "250202" },
    { name: "TAHUANIA", parent: "2502", value: "250203" },
    { name: "YURUA", parent: "2502", value: "250204" },
    { name: "PADRE ABAD", parent: "2503", value: "250301" },
    { name: "IRAZOLA", parent: "2503", value: "250302" },
    { name: "CURIMANA", parent: "2503", value: "250303" },
    { name: "PURUS", parent: "2504", value: "250401" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "Banco de Crédito del Perú", value: "0002" },
    { name: "Interbank", value: "0003" },
    { name: "Citibank del Perú S.A.", value: "0007" },
    { name: "Scotiabank Perú S.A.A.", value: "0009" },
    { name: "Banco Continental", value: "0011" },
    { name: "Banco de la Nación", value: "0018" },
    { name: "Banco de Comercio", value: "0023" },
    { name: "Banco Financiero del Perú", value: "0035" },
    { name: "Banco Interamericano de Finanzas (BIF)", value: "0038" },
    { name: "Banco Falabella", value: "0053" },
    { name: "Banco Ripley", value: "0055" },
    { name: "Banco Santander Perú", value: "0056" },
    { name: "Banco Azteca", value: "0058" },
    { name: "Banco GNB Perú S.A.", value: "0054" },
    { name: "Caja Municipal de Ahorro y Crédito Trujillo", value: "0802" },
    { name: "Caja Municipal de Ahorro y Crédito Arequipa", value: "0803" },
    { name: "Caja Municipal de Ahorro y Crédito Sullana", value: "0805" },
    { name: "Caja Municipal de Ahorro y Crédito Huancayo", value: "0808" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Soles", value: CurrencyEnum.PEN },
    { name: "Dólares", value: CurrencyEnum.USD },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Natural", value: "01" },
    { name: "Jurídica", value: "02" },
  ],
};
