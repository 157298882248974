/* eslint-disable no-trailing-spaces */

import React, { SVGProps } from "react";

const SvgTransfer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px" }}
    {...props}
  >
    <path
      d="M9.40704 0.77832L11.854 3.22526L9.40704 5.67221"
      stroke="#1E65AE"
      strokeWidth="1.13351"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.842651 6.89576V5.67229C0.842651 5.02332 1.10045 4.40093 1.55934 3.94204C2.01824 3.48314 2.64063 3.22534 3.2896 3.22534H11.8539"
      stroke="#1E65AE"
      strokeWidth="1.13351"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.74419 13.3729L4.29724 10.9259L6.74419 8.479"
      stroke="#1E65AE"
      strokeWidth="1.13351"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3085 7.25537V8.47884C15.3085 9.12781 15.0507 9.7502 14.5918 10.2091C14.1329 10.668 13.5105 10.9258 12.8615 10.9258H4.29724"
      stroke="#1E65AE"
      strokeWidth="1.13351"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTransfer;
