import { CountriesEnum } from "../enums/CountriesEnum";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const SET_CURRENCY: Record<CountriesEnum, string> = {
  [CountriesEnum.ECUADOR]: CurrencyEnum.USD,
  [CountriesEnum.COLOMBIA]: CurrencyEnum.COP,
  [CountriesEnum.PERU]: CurrencyEnum.PEN,
  [CountriesEnum.CHILE]: CurrencyEnum.CLP,
  [CountriesEnum.MEXICO]: CurrencyEnum.MXN,
  [CountriesEnum.BRAZIL]: CurrencyEnum.BRL,
  [CountriesEnum.PANAMA]: CurrencyEnum.PAB,
  [CountriesEnum.COSTA_RICA]: CurrencyEnum.CRC,
  [CountriesEnum.EL_SALVADOR]: CurrencyEnum.USD,
};
