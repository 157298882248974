import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { API_ROUTES } from "../../shared/constants/api_routes";
import { getJwtAuth } from "../../shared/utils/getJwtAuth_utils";
import { SearchMerchantResponse } from "../../../types/search_merchant_response";
import { SearchMerchantNodeRequest } from "../../shared/interfaces/searchMerchantNodeRequest.interface";
import { searchMerchantNodeInfoDefaultResponse } from "../../shared/constants/initial_state_main_container";

export const searchMerchantNodeInfo = createAsyncThunk(
  "mainContainer/searchMerchantNodeIfo",
  (query: SearchMerchantNodeRequest) => {
    return axios
      .post(API_ROUTES.SEARCH_MERCHANT_NODE_INFO, query, {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      })
      .then((response: AxiosResponse<SearchMerchantResponse>) => response.data)
      .catch(() => searchMerchantNodeInfoDefaultResponse);
  }
);
