/* istanbul ignore file */
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IAppAction, setNotification } from "./store/actionCreators";
import React, { Suspense } from "react";
import { ConfigByFileIndex } from "./containers/ConfigByFileIndex/ConfigByFileIndex";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "./store/reducer";
import { INotification } from "./shared/infrastructure/interfaces/INotification";
// @ts-ignore
import Normalize from "react-normalize";
import { routes } from "./shared/infrastructure/routes";
import { SnackBarAlert } from "./components/common/SnackBar/SnackBarAlert";
import { ThunkDispatch } from "redux-thunk";

interface INavigationFunctionsProps {
  setNotification(payload: INotification): void;
}

interface INavigationStateProps {
  notification: INotification;
}

type TNavigationProps = INavigationFunctionsProps & INavigationStateProps;
const Navigation: React.FC<TNavigationProps> = (props: TNavigationProps) => {
  const handleCloseSnackbar = () => {
    props.setNotification({
      message: props.notification.message,
      open: false,
      type: props.notification.type,
    });
  };

  return (
    <>
      <Normalize />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              path={`${routes.BASE_PATH_CONFIG_FILE}`}
              exact
              component={ConfigByFileIndex}
            />
          </Switch>
        </Suspense>
        <SnackBarAlert
          type={props.notification.type}
          msg={props.notification.message}
          open={props.notification.open}
          handlerClose={handleCloseSnackbar}
        />
      </BrowserRouter>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  setNotification: (payload: INotification) =>
    dispatch(setNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
