/**
 * Catalog Default
 * Catalog Default
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";

export const CatalogDefault: Record<string, Category[]> = {
  [CatalogsEnum.PersonTypes]: [
    { name: "Natural", value: "01" },
    { name: "Jurídica", value: "02" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
