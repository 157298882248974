import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import branchReducer from "./reducers/branch/branch";
import ratesReducer from "./reducers/rates/rates.slice";
import deferredReducer from "./reducers/deferred/deferred.slice";
import brazilDeferredReducer from "./reducers/brazilDeferred/deferred.slice";
import processReducer from "./reducers/processors/processors.slice";
import servicesReducer from "./reducers/services/services.slice";
import retentionReducer from "./reducers/retention/retention.slice";
import downloadFileReducer from "./reducers/downloadFile/downloadFile.slice";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    branch: branchReducer,
    brazilDeferredApp: brazilDeferredReducer,
    deferredApp: deferredReducer,
    downloadFileApp: downloadFileReducer,
    processors: processReducer,
    ratesApp: ratesReducer,
    retentionApp: retentionReducer,
    servicesApp: servicesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
