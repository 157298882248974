import React from "react";
import { CatalogPopupProps } from "./CatalogPopup.interfaces";
import { CatalogFormDialog } from "../CatalogFormDialog/CatalogFormDialog";
import { defaultTo } from "lodash";
import { CatalogConfirmDialog } from "../CatalogConfirmDialog/CatalogConfirmDialog";
import { CatalogInfoPopup } from "../CatalogInfoPopup/CatalogInfoPopup";

export const CatalogPopup: React.FC<CatalogPopupProps> = (
  props: CatalogPopupProps
) => {
  const {
    isOpenCatalogConfirmPopup,
    isOpenCatalogFormPopup,
    isOpenCatalogInfoPopup,
    isLoading,
    catalogsList,
    catalogRequest,
  } = props;

  return (
    <React.Fragment>
      {isOpenCatalogFormPopup && (
        <CatalogFormDialog
          isOpenPopup={defaultTo(isOpenCatalogFormPopup, false)}
          isLoading={defaultTo(isLoading, false)}
        />
      )}
      {isOpenCatalogConfirmPopup && (
        <CatalogConfirmDialog
          catalogsList={catalogsList!}
          catalogRequest={catalogRequest!}
          isOpenPopup={defaultTo(isOpenCatalogConfirmPopup, false)}
          isLoading={defaultTo(isLoading, false)}
        />
      )}
      {isOpenCatalogInfoPopup && (
        <CatalogInfoPopup
          isOpenPopup={defaultTo(isOpenCatalogInfoPopup, false)}
        />
      )}
    </React.Fragment>
  );
};
