import { TRADUCE_TYPE, TransactionTypeEnum } from "./TransactionTypeEnum";
import { getTransactionTypeObject } from "./TransactionTypeObject";
import { BillingTransactionTypeEnum } from "./BillingTransactionTypeEnum";

export const getTransactionTypeTraduce = (type: string) => {
  return type === TransactionTypeEnum.MINIMAL_COMISSION ||
    type === BillingTransactionTypeEnum.MANUAL_FROM_FILE
    ? getTransactionTypeObject(type).transactionType
    : TRADUCE_TYPE[getTransactionTypeObject(type).transactionType];
};
