export enum SwiftCountriesEnum {
  AFGANISTAN = "Afganistan",
  ALBANIA = "Albania",
  ALEMANIA = "Alemania",
  ANDORRA = "Andorra",
  ANGOLA = "Angola",
  ANGUILA = "Anguila",
  ANTARTIDA = "Antartida",
  ANTIGUA_BARBUDA = "Antigua y Barbuda",
  ARABIA_SAUDITA = "Arabia Saudita",
  ARGELIA = "Argelia",
  ARGENTINA = "Argentina",
  ARMENIA = "Armenia",
  ARUBA = "Aruba",
  AUSTRALIA = "Australia",
  AUSTRIA = "Austria",
  AZERBAIYAN = "Azerbaiyan",
  BELGICA = "Belgica",
  BAHAMAS = "Bahamas",
  BAHREIN = "Bahrein",
  BANGLADESH = "Bangladesh",
  BARBADOS = "Barbados",
  BELICE = "Belice",
  BENIN = "Benin",
  BHUTAN = "Bhutan",
  BIELORRUSIA = "Bielorrusia",
  BIRMANIA = "Birmania",
  BOLIVIA = "Bolivia",
  BOSNIA_HERZEGOVINA = "Bosnia y Herzegovina",
  BOTSUANA = "Botsuana",
  BRUNEI = "Brunei",
  BULGARIA = "Bulgaria",
  BURKINA_FASO = "Burkina Faso",
  BURUNDI = "Burundi",
  CABO_VERDE = "Cabo Verde",
  CAMBOYA = "Camboya",
  CAMERUN = "Camerun",
  CANADA = "Canada",
  CHAD = "Chad",
  CHINA = "China",
  CHIPRE = "Chipre",
  CIUDAD_DEL_VATICANO = "Ciudad del Vaticano",
  COMORAS = "Comoras",
  REPUBLICA_DEL_CONGO = "Republica del Congo",
  REPUBLICA_DEMOCRATICA_DEL_CONGO = "Republica Democratica del Congo",
  COREA_DEL_NORTE = "Corea del Norte",
  COREA_DEL_SUR = "Corea del Sur",
  COSTA_DE_MARFIL = "Costa de Marfil",
  CROACIA = "Croacia",
  CUBA = "Cuba",
  CURAZAO = "Curazao",
  DINAMARCA = "Dinamarca",
  DOMINICA = "Dominica",
  EGIPTO = "Egipto",
  EMIRATOS_ARABES_UNIDOS = "Emiratos Arabes Unidos",
  ERITREA = "Eritrea",
  ESLOVAQUIA = "Eslovaquia",
  ESLOVENIA = "Eslovenia",
  ESPANIA = "España",
  ESTONIA = "Estonia",
  ETIOPIA = "Etiopia",
  FILIPINAS = "Filipinas",
  FINLANDIA = "Finlandia",
  FIYI = "Fiyi",
  FRANCIA = "Francia",
  GABON = "Gabon",
  GAMBIA = "Gambia",
  GEORGIA = "Georgia",
  GHANA = "Ghana",
  GIBRALTAR = "Gibraltar",
  GRANADA = "Granada",
  GRECIA = "Grecia",
  GROENLANDIA = "Groenlandia",
  GUADALUPE = "Guadalupe",
  GUAM = "Guam",
  GUAYANA_FRANCESA = "Guayana Francesa",
  GUERNSEY = "Guernsey",
  GUINEA = "Guinea",
  GUINEA_ECUATORIAL = "Guinea Ecuatorial",
  GUINEA_BISSAU = "Guinea-Bissau",
  GUYANA = "Guyana",
  HAITI = "Haiti",
  HONG_KONG = "Hong kong",
  HUNGRIA = "Hungria",
  INDIA = "India",
  INDONESIA = "Indonesia",
  IRAN = "Iran",
  IRAK = "Irak",
  IRLANDA = "Irlanda",
  ISLA_BOUVET = "Isla Bouvet",
  ISLA_DE_MAN = "Isla de Man",
  ISLA_DE_NAVIDAD = "Isla de Navidad",
  ISLA_NORFOLK = "Isla Norfolk",
  ISLANDIA = "Islandia",
  ISLAS_BERMUDAS = "Islas Bermudas",
  ISLAS_CAIMAN = "Islas Caiman",
  ISLAS_COCOS_KEELING = "Islas Cocos (Keeling)",
  ISLAS_COOK = "Islas Cook",
  ISLAS_DE_ALAND = "Islas de Aland",
  ISLAS_FEROE = "Islas Feroe",
  ISLAS_GEORGIAS_DEL_SUR_SANDWICH_DEL_SUR = "Islas Georgias del Sur y Sandwich del Sur",
  ISLAS_HEARD_MCDONALD = "Islas Heard y McDonald",
  ISLAS_MALDIVAS = "Islas Maldivas",
  ISLAS_MARIANAS_DEL_NORTE = "Islas Marianas del Norte",
  ISLAS_MARSHALL = "Islas Marshall",
  ISLAS_PITCAIRN = "Islas Pitcairn",
  ISLAS_SALOMON = "Islas Salomon",
  ISLAS_TURCAS_CAICOS = "Islas Turcas y Caicos",
  ISLAS_ULTRAMARINAS_MENORES_DE_ESTADOS_UNIDOS = "Islas Ultramarinas Menores de Estados Unidos",
  ISLAS_VIRGENES_BRITANICAS = "Islas Virgenes Britanicas",
  ISLAS_VIRGENES_DE_LOS_ESTADOS_UNIDOS = "Islas Vírgenes de los Estados Unidos",
  ISRAEL = "Israel",
  ITALIA = "Italia",
  JAMAICA = "Jamaica",
  JAPON = "Japon",
  JERSEY = "Jersey",
  JORDANIA = "Jordania",
  KAZAJISTAN = "Kazajistan",
  KENIA = "Kenia",
  KIRGUISTAN = "Kirguistan",
  KIRIBATI = "Kiribati",
  KUWAIT = "Kuwait",
  LIBANO = "Libano",
  LAOS = "Laos",
  LESOTO = "Lesoto",
  LETONIA = "Letonia",
  LIBERIA = "Liberia",
  LIBIA = "Libia",
  LIECHTENSTEIN = "Liechtenstein",
  LITUANIA = "Lituania",
  LUXEMBURGO = "Luxemburgo",
  MEXICO = "Mexico",
  MONACO = "Monaco",
  MACAO = "Macao",
  MACEDONIA = "Macedonia",
  MADAGASCAR = "Madagascar",
  MALASIA = "Malasia",
  MALAWI = "Malawi",
  MALI = "Mali",
  MALTA = "Malta",
  MARRUECOS = "Marruecos",
  MARTINICA = "Martinica",
  MAURICIO = "Mauricio",
  MAURITANIA = "Mauritania",
  MAYOTTE = "Mayotte",
  MICRONESIA = "Micronesia",
  MOLDAVIA = "Moldavia",
  MONGOLIA = "Mongolia",
  MONTENEGRO = "Montenegro",
  MONTSERRAT = "Montserrat",
  MOZAMBIQUE = "Mozambique",
  NAMIBIA = "Namibia",
  NAURU = "Nauru",
  NEPAL = "Nepal",
  NIGER = "Niger",
  NIGERIA = "Nigeria",
  NIUE = "Niue",
  NORUEGA = "Noruega",
  NUEVA_CALEDONIA = "Nueva Caledonia",
  NUEVA_ZELANDA = "Nueva Zelanda",
  OMAN = "Oman",
  PAISES_BAJOS = "Paises Bajos",
  PAKISTAN = "Pakistan",
  PALAU = "Palau",
  PALESTINA = "Palestina",
  PAPUA_NUEVA_GUINEA = "Papua Nueva Guinea",
  PARAGUAY = "Paraguay",
  PERU = "Peru",
  POLINESIA_FRANCESA = "Polinesia Francesa",
  POLONIA = "Polonia",
  PORTUGAL = "Portugal",
  PUERTO_RICO = "Puerto Rico",
  QATAR = "Qatar",
  REINO_UNIDO = "Reino Unido",
  REPUBLICA_CENTROAFRICANA = "Republica Centroafricana",
  REPUBLICA_CHECA = "Republica Checa",
  REPUBLICA_DOMINICANA = "Republica Dominicana",
  REPUBLICA_DE_SUDAN_DEL_SUR = "Republica de Sudan del Sur",
  REUNION = "Reunion",
  RUANDA = "Ruanda",
  RUMANIA = "Rumania",
  RUSIA = "Rusia",
  SAHARA_OCCIDENTAL = "Sahara Occidental",
  SAMOA = "Samoa",
  SAMOA_AMERICANA = "Samoa Americana",
  SAN_BARTOLOME = "San Bartolome",
  SAN_CRISTOBAL_NIEVES = "San Cristobal y Nieves",
  SAN_MARINO = "San Marino",
  SAN_MARTIN_FRANCIA = "San Martin (Francia)",
  SAN_PEDRO_MIQUELON = "San Pedro y Miquelon",
  SAN_VICENTE_LAS_GRANADINAS = "San Vicente y las Granadinas",
  SANTA_ELENA = "Santa Elena",
  SANTA_LUCIA = "Santa Lucia",
  SANTO_TOME_PRINCIPE = "Santo Tome y Principe",
  SENEGAL = "Senegal",
  SERBIA = "Serbia",
  SEYCHELLES = "Seychelles",
  SIERRA_LEONA = "Sierra Leona",
  SINGAPUR = "Singapur",
  SINT_MAARTEN = "Sint Maarten",
  SIRIA = "Siria",
  SOMALIA = "Somalia",
  SRI_LANKA = "Sri lanka",
  SUDAFRICA = "Sudafrica",
  SUDAN = "Sudan",
  SUECIA = "Suecia",
  SUIZA = "Suiza",
  SURINÁM = "Surinam",
  SVALBARD_JAN_MAYEN = "Svalbard y Jan Mayen",
  SWAZILANDIA = "Swazilandia",
  TAYIKISTAN = "Tayikistan",
  TAILANDIA = "Tailandia",
  TAIWAN = "Taiwan",
  TANZANIA = "Tanzania",
  TERRITORIO_BRITANICO_DEL_OCEANO_INDICO = "Territorio Britanico del Oceano Indico",
  TERRITORIOS_AUSTRALES_ANTARTICAS_FRANCESES = "Territorios Australes y Antarticas Franceses",
  TIMOR_ORIENTAL = "Timor Oriental",
  TOGO = "Togo",
  TOKELAU = "Tokelau",
  TONGA = "Tonga",
  TRINIDAD_TOBAGO = "Trinidad y Tobago",
  TUNEZ = "Tunez",
  TURKMENISTAN = "Turkmenistan",
  TURQUIA = "Turquia",
  TUVALU = "Tuvalu",
  UCRANIA = "Ucrania",
  UGANDA = "Uganda",
  URUGUAY = "Uruguay",
  UZBEKISTAN = "Uzbekistan",
  VANUATU = "Vanuatu",
  VENEZUELA = "Venezuela",
  VIETNAM = "Vietnam",
  WALLIS_FUTUNA = "Wallis y Futuna",
  YEMEN = "Yemen",
  YIBUTI = "Yibuti",
  ZAMBIA = "Zambia",
  ZIMBABUE = "Zimbabue",
}
