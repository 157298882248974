export enum ServiceConfigEnum {
  CANCEL = "Cancelar",
  CENTRALIZED_INFO = "Al estar centralizado el branch, solo es posible activar o desactivar Sandbox y modificar la información de credenciales Sift Science.",
  CONFIGURE = "Configurar",
  CONTINUE = "Continuar",
  EMPTY_SERVICE_CONFIG = "Configura los servicios adicionales que tendrá el customer",
  EMPTY_SERVICE_CONFIG_BRANCH = "Configura los servicios adicionales para los branches.",
  FINISH = "Finalizar",
  MASSIVE_DEFAULT_MODAL_DESCRIPTION = "Al ejecutar esta acción sin configurar servicios, se guardará una configuración por defecto.",
  MASSIVE_MODAL_DESCRIPTION = "Al ejecutar esta acción, todos los datos ingresados quedarán almacenados correctamente.",
  MASSIVE_MODAL_TITLE = "¿Estás seguro de que deseas finalizar esta edición?",
  MASSIVE_SERVICES_DESCRIPTION = "Al ejecutar esta acción, todos los servicios ingresados quedarán guardados correctamente.",
  MASSIVE_SERVICES_TITLE = "¿Deseas guardar los servicios?",
  MODAL_DESCRIPTION = "Ten en cuenta que después puedes regresar a la edición de esta sección",
  MODAL_LOADER_DESCRIPTION = "Este proceso puede tardar un poco...",
  MODAL_LOADER_TITLE = "Estamos realizando la configuración por defecto",
  MODAL_TITLE = "Se hará la configuración de servicios por default, ¿deseas continuar?",
  SAVE = "Guardar",
  SERVICES = "Servicios",
  SERVICE_PAYOUTS_PROCESSOR = "Al contar con un procesador de payouts, solo es posible tener activado/desactivado el Sandbox. Puedes continuar sin modificar la información.",
  SMARTLINK = "Smartlinks",
  SMARTLINKS = "smartlinks",
  VPOS = "Virtual POS",
  WEBCHECKOUT = "Webcheckout",
}
