import { defaultTo, isEmpty, get } from "lodash";
import { MerchantInfo } from "../../../../types/merchant_info";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BusinessRulesIndexProps } from "../BusinessRulesIndex";
import { PaginationTableProps } from "../../../components/BusinessRulesSection/BusinessRulesTable/Pagination/PaginationTable";
import { SubmitHandler, useForm, UseFormMethods } from "react-hook-form";
import { IDefaultProcessorForm } from "../../../shared/infrastructure/interfaces/IDefaultProcessorForm";
import { RulesNotificationResultEnum } from "../../../shared/infrastructure/constants/RulesConstants";
import { PaymentMethodEnum } from "../../../shared/infrastructure/enums/PaymentMethodEnum";
import { UpdateMerchantRulesRequest } from "../../../../types/update_merchant_rules_request";
import { ProcessorsInformation } from "../../../../types/processors_list";
import { RuleItem } from "../../../../types/get_business_rules_response";

export interface BusinessRulesState {
  form: UseFormMethods<IDefaultProcessorForm>;
  merchantInfo: MerchantInfo;
  merchantId: string;
  pagination: PaginationTableProps;
  handler: {
    editRule: (ruleId: string) => void;
    changeOpenPopover: (open: boolean) => void;
    handleSubmitDefaultProcessor: SubmitHandler<IDefaultProcessorForm>;
    handleCloseNotification: () => void;
    handleClickReturnCreateCommerce: () => void;
  };
  dialog: {
    openDialog: boolean;
    handleCloseDialog: () => void;
    handleOpenDialog: (ruleId: string) => void;
    handleClickDialog: () => void;
  };
  openPopover: boolean;
  processors: {
    card: ProcessorsInformation[];
    transfer: ProcessorsInformation[];
    ach: ProcessorsInformation[];
  };
}

interface BusinessRuleState {
  rules: RuleItem[];
  limit: number;
  previousLastEvaluatedKey: (string | undefined)[];
  merchantId: string;
  pages: number;
  lastEvaluatedKey?: any;
  disableBack: boolean;
  disableContinue: boolean;
}

const checkAdmin = () => {
  const roles: object = JSON.parse(
    defaultTo(localStorage.getItem("roles"), "{}")
  );

  if (get(roles, "BackofficeAdmin") !== true)
    window.location.href = "/dashboard";
};

export const useBusinessRulesState = (
  props: BusinessRulesIndexProps
): BusinessRulesState => {
  const { merchantId } = useParams();
  const [state, setState] = useState<BusinessRuleState>({
    rules: [],
    merchantId: merchantId,
    limit: 10,
    previousLastEvaluatedKey: [undefined],
    pages: 0,
    disableBack: true,
    disableContinue: true,
  });
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const history = useHistory();
  const [defaultProcessors, setDefaultProcessors] = useState<{
    card: string;
    transfer: string;
    ach: string;
  }>({
    card: "",
    transfer: "",
    ach: "",
  });
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const merchantInfo: MerchantInfo = defaultTo(
    JSON.parse(`${window.localStorage.getItem("merchantBasicInformation")}`),
    {}
  );
  const [ruleDelete, setRuleDelete] = useState<string>("");
  const form = useForm<IDefaultProcessorForm>({
    mode: "all",
    defaultValues: {},
  });
  const [merchantProcessors, setMerchantProcessors] = useState<{
    card: ProcessorsInformation[];
    transfer: ProcessorsInformation[];
    ach: ProcessorsInformation[];
  }>({
    card: [],
    transfer: [],
    ach: [],
  });

  useEffect(() => {
    if (merchantId) checkAdmin();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.businessRuleList))
      setState({
        ...state,
        rules: get(props.businessRuleList, "items", []),
        lastEvaluatedKey: !isEmpty(
          get(props.businessRuleList, "lastEvaluatedKey")
        )
          ? get(props.businessRuleList, "lastEvaluatedKey")
          : undefined,
        disableContinue: isEmpty(
          get(props.businessRuleList, "lastEvaluatedKey")
        ),
        disableBack: state.pages === 0,
      });
  }, [props.businessRuleList]);

  useEffect(() => {
    setState({ ...state, previousLastEvaluatedKey: [undefined] });
    getRules(state.limit, state.merchantId);
  }, [state.limit]);

  useEffect(() => {
    if (isEmpty(props.defaultProcessor)) return;
    setDefaultProcessors({
      card: get(props.defaultProcessor, "defaultProcessor.card", ""),
      transfer: get(props.defaultProcessor, "defaultProcessor.transfer", ""),
      ach: get(props.defaultProcessor, "defaultProcessor.achTransfer", ""),
    });
  }, [props.defaultProcessor]);

  useEffect(() => {
    if (isEmpty(props.processors)) return;
    const processorMerchant: ProcessorsInformation[] = defaultTo(
      props.processors,
      []
    );
    const cardProcessorList: ProcessorsInformation[] = processorMerchant.filter(
      (x: ProcessorsInformation) =>
        get(x, "paymentMethod", "") === PaymentMethodEnum.CARD
    );
    const transferProcessorList: ProcessorsInformation[] =
      processorMerchant.filter(
        (x: ProcessorsInformation) =>
          get(x, "paymentMethod", "") === PaymentMethodEnum.TRANSFER
      );
    const achProcessorList: ProcessorsInformation[] = processorMerchant.filter(
      (x: ProcessorsInformation) =>
        get(x, "paymentMethod", "") === PaymentMethodEnum.ACH_TRANSFER ||
        get(x, "paymentMethod", "") === PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS
    );
    setMerchantProcessors({
      card: cardProcessorList,
      transfer: transferProcessorList,
      ach: achProcessorList,
    });
    form.setValue("cardProcessor", defaultProcessors.card);
    form.setValue("transferProcessor", defaultProcessors.transfer);
    form.setValue("achProcessor", defaultProcessors.ach);
  }, [props.processors]);

  const nextPage = (): void => {
    const previous_last_evaluated_keys: (string | undefined)[] =
      state.previousLastEvaluatedKey;
    const pageNew: number = state.pages + 1;
    if (!isEmpty(state.lastEvaluatedKey))
      previous_last_evaluated_keys.push(state.lastEvaluatedKey);
    getRules(state.limit, state.merchantId, state.lastEvaluatedKey);
    setState({
      ...state,
      pages: pageNew,
      disableBack: pageNew === 0,
      previousLastEvaluatedKey: previous_last_evaluated_keys,
    });
  };

  const previousPage = (): void => {
    const page: number = state.pages - 1;
    const previous_last_evaluated_keys: (string | undefined)[] =
      state.previousLastEvaluatedKey;
    getRules(
      state.limit,
      state.merchantId,
      state.previousLastEvaluatedKey[page]
    );
    if (page > 0) previous_last_evaluated_keys.splice(state.pages);
    setState({
      ...state,
      pages: page,
      disableBack: page === 0,
      previousLastEvaluatedKey: previous_last_evaluated_keys,
    });
  };

  const changeRowsPerPage = (newLimit: string) => {
    setState({
      ...state,
      limit: parseInt(newLimit, 10),
      pages: 0,
      previousLastEvaluatedKey: [undefined],
    });
  };

  const editRule = (ruleId: string) => {
    history.push(`rule/${merchantId}/${ruleId}`);
  };

  const getRules = (
    limit: number,
    merchantId: string,
    lastEvaluatedKey?: string
  ): void => {
    const request: {
      merchantId: string;
      limit: number;
      lastEvaluatedKey?: any;
    } = {
      limit: limit,
      merchantId: merchantId,
    };
    if (!isEmpty(lastEvaluatedKey)) request.lastEvaluatedKey = lastEvaluatedKey;
    props.getBusinessRuleList(request);
  };

  const changeOpenPopover = (open: boolean) => {
    setOpenPopover(open);
    if (open) props.getProcessors(state.merchantId, false);
  };

  const handleSubmitDefaultProcessor: SubmitHandler<IDefaultProcessorForm> =
    async (formData) => {
      const requestProcessor: UpdateMerchantRulesRequest = {
        defaultProcessor: {
          card: formData.cardProcessor,
          transfer: formData.transferProcessor,
          achTransfer: formData.achProcessor,
        },
        publicMerchantId: merchantId,
      };
      props.saveDefaultProcessor(requestProcessor);
      setDefaultProcessors({
        card: formData.cardProcessor,
        transfer: formData.transferProcessor,
        ach: formData.achProcessor,
      });

      changeOpenPopover(false);
    };

  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.NO_ACTION,
    });
  };

  const handleOpenDialog = (ruleId: string) => {
    setOpenDialog(true);
    setRuleDelete(ruleId);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleClickDialog = () => {
    handleCloseDialog();
    setState({
      ...state,
      pages: 0,
      previousLastEvaluatedKey: [undefined],
    });
    props.deleteBusinessRule(ruleDelete, merchantId, state.limit);
  };
  const handleClickReturnCreateCommerce = (): void => {
    window.location.href = `/create-merchant/details?publicMerchantId=${merchantId}&hideSideBar=true`;
    return;
  };

  return {
    form,
    merchantInfo,
    merchantId,
    pagination: {
      limit: state.limit,
      isMobile: false,
      disabledBack: state.disableBack,
      disabledNext: state.disableContinue,
      onNext: nextPage,
      onBack: previousPage,
      handleChangeLimit: changeRowsPerPage,
    },
    handler: {
      editRule,
      changeOpenPopover,
      handleSubmitDefaultProcessor,
      handleCloseNotification,
      handleClickReturnCreateCommerce,
    },
    openPopover,
    dialog: {
      handleClickDialog,
      handleCloseDialog,
      handleOpenDialog,
      openDialog,
    },
    processors: merchantProcessors,
  };
};
