import { ObservationItemStatus } from "../../store/interfaces/TransactionsState.interfaces";

export const transactionTitleMap = {
  [ObservationItemStatus.CONCILIATED]: "Conciliado",
  [ObservationItemStatus.OMITTED]: "Omitido",
  [ObservationItemStatus.PENDING_REFUND]: "Reembolso pendiente",
  [ObservationItemStatus.PENDING]: "Pendiente",
  [ObservationItemStatus.REVIEW_KUSHKI]: "En Revisión",
  [ObservationItemStatus.REVIEW_PROCESSOR]: "En Revisión",
};
