import { makeStyles } from "@mui/styles";

export const generalConditionSectionStyles = makeStyles({
  root: {
    paddingTop: "3px !important",
  },
  errorColor: {
    color: "rgba(226,71,99,1)",
  },
  buttons: {
    paddingTop: "2px !important",
  },
  conditionTitle: {
    width: 87,
    height: 19,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "120%",
    letterSpacing: "0.0015em",
    color: "#023365 !important",
    marginBottom: 9,
  },
  detail: {
    paddingTop: "0px !important",
  },
  subTitleConditions: {
    fontSize: "16px",
    paddingBottom: "14px",
  },
  containerSnackbarSmartlinkActive: {
    paddingLeft: "16px",
    paddingBottom: "16px !important",
    paddingTop: "16px",
  },
});
