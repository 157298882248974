import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAppState } from "./store/reducer";
import { IAppAction, setNotification } from "./store/actionCreators";
import { SnackBarAlert } from "./components/commons/Snackbar/SnackBarAlert";
import { ThunkDispatch } from "redux-thunk";
import { INotification } from "./shared/infrastructure/interfaces/INotification";
import ProcessorIndex from "./containers/ProcessorIndex/ProcessorIndex";

interface INavigationStateProps {
  notification: INotification;
}
interface INavigationDispatchProps {
  setNotification(payload: INotification): void;
}

type TNavigationProps = INavigationDispatchProps & INavigationStateProps;
export const Navigation: React.FC<TNavigationProps> = (
  props: TNavigationProps
) => {
  const handleCloseSnackbar = () => {
    props.setNotification({
      open: false,
      message: props.notification.message,
      type: props.notification.type,
    });
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="" component={ProcessorIndex} />
        </Switch>
      </BrowserRouter>
      <SnackBarAlert
        type={props.notification.type}
        msg={props.notification.message}
        open={props.notification.open}
        handlerClose={handleCloseSnackbar}
      />
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationDispatchProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationDispatchProps => ({
  setNotification: (payload: INotification) => {
    dispatch(setNotification(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
