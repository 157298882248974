import React from "react";
import { Skeleton } from "@mui/material";
import { defaultTo } from "lodash";
import { IMaskContent } from "./MaskSkeleton.interfaces";

export const MaskSkeleton: React.FC<IMaskContent> = (props: IMaskContent) => {
  return (
    <>
      {props.isLoading
        ? defaultTo(
            props.skeleton,
            <Skeleton
              variant={defaultTo(props.variant, "text")}
              sx={props.sx}
            />
          )
        : defaultTo(props.children!, <></>)}
    </>
  );
};
