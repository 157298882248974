/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiOwner: string;
kushkiUrl: string;
envName: string;
devTools: boolean;
rollbarToken: string;
} = {
kushkiOwner: "Kushki",
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
rollbarToken: 'f2f92c837d544e099f2a1a760cb8a874',
};