import { Grid, Typography } from "@material-ui/core";
import { FileUpload, KshFile } from "../common/FileUpload/FileUpload";
import React from "react";
import { OriginEnum } from "../../shared/infrastructure/OriginEnum";

export interface UploadCardProps {
  headingText: string;
  fileUploader: IFileUpload;
  isUploadingConciliation: boolean;
  uploadError: boolean;
  isUploadedConciliation: boolean;
  origin: OriginEnum;
}
export interface IFileUpload {
  files: KshFile[] | undefined;
  handlerCaptureFile: (value: {
    prop: string;
    files?: KshFile;
    origin: OriginEnum;
  }) => void;
  handlerDelete: (resultFiles: { prop: string; files?: KshFile }) => void;
  invalidFile: boolean;
}

export const UploadCard = (props: UploadCardProps) => {
  const {
    isUploadingConciliation,
    isUploadedConciliation,
    uploadError,
    origin,
  } = props;
  return (
    <Grid container>
      <Grid item xs={12} container spacing={2} justify={"center"}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color={"textSecondary"}>
            {props.headingText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FileUpload
            origin={origin}
            uploadError={uploadError}
            isUploaded={isUploadedConciliation}
            isUploading={isUploadingConciliation}
            prop={"conciliationFile"}
            files={
              props.fileUploader?.files !== undefined
                ? [props.fileUploader?.files![0]]
                : undefined
            }
            multiple={false}
            maxSizeMb={20}
            onChange={props.fileUploader?.handlerCaptureFile}
            onDelete={props.fileUploader?.handlerDelete}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Tipo de archivos aceptados: .pdf, .jpg, .png, .csv, .xls, .xlsx, .doc,
          .docx
        </Typography>
        <Typography variant="subtitle2" color={"textSecondary"}>
          Peso máximo del archivo hasta 20 mb.
        </Typography>
      </Grid>
    </Grid>
  );
};
