import { LabelEnum, ModalSimpleData } from "../enums/LabelEnum";
import { ISaveRuleModal } from "../interfaces/ISimpleModal";
import { TypeModalEnum } from "../enums/TypeModalEnum";

export const saveEditDraftRule: ISaveRuleModal = {
  action: ModalSimpleData.CREATE_DRAFT,
  buttonPrimaryText: ModalSimpleData.ACCEPT_BUTTON,
  buttonSecondary: ModalSimpleData.CANCEL_BTN,
  descriptionText: ModalSimpleData.DRAFT_DESCRIPTION,
  isOpen: true,
  titleText: ModalSimpleData.DRAFT_RULE_TITLE,
};

export const enableEditRule: ISaveRuleModal = {
  action: ModalSimpleData.CREATE,
  buttonPrimaryText: ModalSimpleData.ACCEPT_BUTTON,
  buttonSecondary: ModalSimpleData.CANCEL_BTN,
  descriptionText: ModalSimpleData.ENABLE_EDIT_RULE_DESCRIPTION,
  isOpen: true,
  titleText: ModalSimpleData.ENABLE_EDIT_RULE_TITLE,
};

export const editRuleFromCustomer: ISaveRuleModal = {
  action: ModalSimpleData.UPDATE,
  buttonPrimaryText: ModalSimpleData.ACCEPT_BUTTON,
  buttonSecondary: ModalSimpleData.CANCEL_BTN,
  descriptionText: ModalSimpleData.EDIT_RULE_CUSTOMER_DESCRIPTION,
  isOpen: true,
  titleText: ModalSimpleData.EDIT_RULE_CUSTOMER_TITLE,
};

export const saveRepeatedRule = (conditions: string, fromRule: string) => {
  const modal: ISaveRuleModal = {
    action: ModalSimpleData.CREATE,
    buttonPrimaryText: ModalSimpleData.CONTINUE_BTN,
    buttonSecondary: ModalSimpleData.CANCEL_BTN,
    descriptionText: ModalSimpleData.DESCRIPTION_REPEATED(conditions, fromRule),
    isOpen: true,
    titleText: LabelEnum.ADD_BUSINESS_RULE,
  };
  return modal;
};

export const ruleAdded: ISaveRuleModal = {
  action: "",
  buttonPrimaryText: ModalSimpleData.MODAL_BTN_ADD_NEW_RULE,
  buttonSecondary: ModalSimpleData.MODAL_BTN_END,
  descriptionText: " ",
  isOpen: true,
  titleText: ModalSimpleData.ENABLE_RULE_TITLE,
  typeModal: TypeModalEnum.THREE,
};
