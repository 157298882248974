import React, { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { IListBoxComponentProps } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { SelectCheckStyles } from "../SelectCheck/SelectCheck.styles";
import {
  checkedIcon,
  icon,
  indeterminateIcon,
} from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/icons/icons";

const ListBoxComponent: FC<IListBoxComponentProps> = ({
  boxParams,
  checkAll,
  checkAllMouse,
  checkAllChange,
  indeterminate,
  isActiveSelectAll,
  isDisabled,
}) => (
  <Box {...boxParams} sx={SelectCheckStyles.list} component={"ul"}>
    {isActiveSelectAll && (
      <Box sx={SelectCheckStyles.listFirstItem} component={"li"}>
        <Checkbox
          checked={checkAll || indeterminate}
          icon={icon}
          checkedIcon={indeterminate ? indeterminateIcon : checkedIcon}
          onClick={checkAllMouse}
          onChange={checkAllChange}
          id="check-all"
          disabled={isDisabled}
        />
        Seleccionar todo
      </Box>
    )}
    {boxParams.children}
  </Box>
);

export default ListBoxComponent;
