import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const monthsListStyles = createNamedStyles({
  monthContainer: {
    backgroundColor: "#F0F4F9",
    border: "0.3px solid #677684",
    borderRadius: 1,
    height: 20,
    width: 27,
  },
  brazilMonthContainer: {
    backgroundColor: "#59BF9A",
    border: "0.3px solid #59BF9A",
    borderRadius: 1,
    height: 20,
    width: 35,
  },
  brazilMonthLabel: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "140%",
    color: "white",
    textAlign: "center",
  },
  monthLabel: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "140%",
    color: "#293036",
    textAlign: "center",
  },
  title: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "140%",
    color: "#6D7781",
  },
});
