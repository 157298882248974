import React from "react";
import { TextField } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get, isEmpty } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { useWatch } from "react-hook-form";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { findName } from "../../../shared/utils";

export interface AutocompleteFieldComponentProps {
  index: number;
  disabled: boolean;
  items: string[];
  options: IOptionSelection[];
  compare: "name" | "value";
  path: "name" | "value";
}

export const AutocompleteFieldComponent: React.FC<AutocompleteFieldComponentProps> =
  (props: AutocompleteFieldComponentProps) => {
    return (
      <ConnectForm<ISecurityRuleForm>>
        {({ control, errors, getErrorMessage }) => {
          const TypedController = useTypedController<ISecurityRuleForm>({
            control: control,
          });

          const value = useWatch({
            name: `strictCondition[${props.index}].value`,
          }) as string;

          return (
            <React.Fragment>
              <TypedController
                name={["strictCondition", props.index, "value"]}
                rules={{ required: true }}
                render={({ onChange }) => (
                  <Autocomplete
                    data-testid="strictConditionWrapper"
                    value={isEmpty(value) ? "" : value}
                    options={props.items}
                    onChange={(_event: any, value: any) => {
                      onChange(value);
                    }}
                    defaultValue={value}
                    getOptionLabel={(option) =>
                      findName(props.options, option, props.compare, props.path)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-testid="autocomplete"
                        error={
                          !!get(errors, `strictCondition.${props.index}.value`)
                        }
                        label={SecurityRulesLabelField.VALUE}
                        variant="outlined"
                        disabled={props.disabled}
                        fullWidth
                        helperText={
                          !!get(
                            errors,
                            `strictCondition.${props.index}.value`
                          ) &&
                          getErrorMessage(
                            get(errors, `strictCondition.${props.index}.value`)
                          )
                        }
                      />
                    )}
                  />
                )}
              />
            </React.Fragment>
          );
        }}
      </ConnectForm>
    );
  };

export default AutocompleteFieldComponent;
