/* istanbul ignore file */
import React from "react";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { environment } from "./environments/environment";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { reducer } from "./store/reducer";
import Navigation from "./navigation.component";
import { SecurityWrapperEnum } from "./shared/infrastructure/constants/SecurityWrapperEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModuleSecurityWrapper
            basePath={environment.kushkiUrl}
            componentId={SecurityWrapperEnum.MODULE_ID}
          >
            <Navigation />
          </ModuleSecurityWrapper>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
