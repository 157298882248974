/**
 * Catalog Honduras
 */
import { Category } from "../constants/information_constants";
import { CatalogsEnum } from "../interfaces/catalogs-enum";

export const CatalogHn: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", value: "0", name: "RNP" },
    { defaultShareholderDocType: "0", value: "1", name: "RTN" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "01", name: "Atlántida" },
    { value: "02", name: "Colón" },
    { value: "03", name: "Comayagua" },
    { value: "04", name: "Copán" },
    { value: "05", name: "Cortés" },
    { value: "06", name: "Choluteca" },
    { value: "07", name: "El Paraíso" },
    { value: "08", name: "Francisco Morazán" },
    { value: "09", name: "Gracias a Dios" },
    { value: "10", name: "Intibucá" },
    { value: "11", name: "Islas de la Bahía" },
    { value: "12", name: "La Paz" },
    { value: "13", name: "Lempira" },
    { value: "14", name: "Ocotepeque" },
    { value: "15", name: "Olancho" },
    { value: "16", name: "Santa Bárbara" },
    { value: "17", name: "Valle" },
    { value: "18", name: "Yoro" },
  ],

  [CatalogsEnum.Cities]: [
    { value: "0101", name: "La Ceiba", parent: "01" },
    { value: "0102", name: "El Porvenir", parent: "01" },
    { value: "0103", name: "Esparta", parent: "01" },
    { value: "0104", name: "Jutiapa", parent: "01" },
    { value: "0105", name: "La Masica", parent: "01" },
    { value: "0106", name: "San Francisco", parent: "01" },
    { value: "0107", name: "Tela", parent: "01" },
    { value: "0108", name: "Arizona", parent: "01" },
    { value: "0201", name: "Trujillo", parent: "02" },
    { value: "0202", name: "Balfate", parent: "02" },
    { value: "0203", name: "Iriona", parent: "02" },
    { value: "0204", name: "Limón", parent: "02" },
    { value: "0205", name: "Sabá", parent: "02" },
    { value: "0206", name: "Santa Fe", parent: "02" },
    { value: "0207", name: "Santa Rosa de Aguán", parent: "02" },
    { value: "0208", name: "Sonaguera", parent: "02" },
    { value: "0209", name: "Tocoa", parent: "02" },
    { value: "0210", name: "Bonito Oriental", parent: "02" },
    { value: "0301", name: "Comayagua", parent: "03" },
    { value: "0302", name: "Ajuterique", parent: "03" },
    { value: "0303", name: "El Rosario", parent: "03" },
    { value: "0304", name: "Esquías", parent: "03" },
    { value: "0305", name: "Humuya", parent: "03" },
    { value: "0306", name: "La libertad", parent: "03" },
    { value: "0307", name: "Lamaní", parent: "03" },
    { value: "0308", name: "La Trinidad", parent: "03" },
    { value: "0309", name: "Lejamani", parent: "03" },
    { value: "0310", name: "Meambar", parent: "03" },
    { value: "0311", name: "Minas de Oro", parent: "03" },
    { value: "0312", name: "Ojos de Agua", parent: "03" },
    { value: "0313", name: "San Jerónimo", parent: "03" },
    { value: "0314", name: "San José de Comayagua", parent: "03" },
    { value: "0315", name: "San José del Potrero", parent: "03" },
    { value: "0316", name: "San Luis", parent: "03" },
    { value: "0317", name: "San Sebastián", parent: "03" },
    { value: "0318", name: "Siguatepeque", parent: "03" },
    { value: "0319", name: "Villa de San Antonio", parent: "03" },
    { value: "0320", name: "Las Lajas", parent: "03" },
    { value: "0321", name: "Taulabé", parent: "03" },
    { value: "0401", name: "Santa Rosa de Copán", parent: "04" },
    { value: "0402", name: "Cabañas", parent: "04" },
    { value: "0403", name: "Concepción", parent: "04" },
    { value: "0404", name: "Copán Ruinas", parent: "04" },
    { value: "0405", name: "Corquín", parent: "04" },
    { value: "0406", name: "Cucuyagua", parent: "04" },
    { value: "0407", name: "Dolores", parent: "04" },
    { value: "0408", name: "Dulce Nombre", parent: "04" },
    { value: "0409", name: "El Paraíso", parent: "04" },
    { value: "0410", name: "Florida", parent: "04" },
    { value: "0411", name: "La Jigua", parent: "04" },
    { value: "0412", name: "La Unión", parent: "04" },
    { value: "0413", name: "Nueva Arcadia", parent: "04" },
    { value: "0414", name: "San Agustín", parent: "04" },
    { value: "0415", name: "San Antonio", parent: "04" },
    { value: "0416", name: "San Jerónimo", parent: "04" },
    { value: "0417", name: "San José", parent: "04" },
    { value: "0418", name: "San Juan de Opoa", parent: "04" },
    { value: "0419", name: "San Nicolás", parent: "04" },
    { value: "0420", name: "San Pedro", parent: "04" },
    { value: "0421", name: "Santa Rita", parent: "04" },
    { value: "0422", name: "Trinidad de Copán", parent: "04" },
    { value: "0423", name: "Veracruz", parent: "04" },
    { value: "0501", name: "San Pedro Sula", parent: "05" },
    { value: "0502", name: "Choloma", parent: "05" },
    { value: "0503", name: "Omoa", parent: "05" },
    { value: "0504", name: "Pimienta", parent: "05" },
    { value: "0505", name: "Potrerillos", parent: "05" },
    { value: "0506", name: "Puerto Cortés", parent: "05" },
    { value: "0507", name: "San Antonio de Cortés", parent: "05" },
    { value: "0508", name: "San Francisco de Yojoa", parent: "05" },
    { value: "0509", name: "San Manuel", parent: "05" },
    { value: "0510", name: "Santa Cruz de Yojoa", parent: "05" },
    { value: "0511", name: "Villanueva", parent: "05" },
    { value: "0512", name: "La Lima", parent: "05" },
    { value: "0601", name: "Choluteca", parent: "06" },
    { value: "0602", name: "Apacilagua", parent: "06" },
    { value: "0603", name: "Concepción de María", parent: "06" },
    { value: "0604", name: "Duyure", parent: "06" },
    { value: "0605", name: "El Corpus", parent: "06" },
    { value: "0606", name: "El Triunfo", parent: "06" },
    { value: "0607", name: "Marcovia", parent: "06" },
    { value: "0608", name: "Morolica", parent: "06" },
    { value: "0609", name: "Namasigüe", parent: "06" },
    { value: "0610", name: "Orocuina", parent: "06" },
    { value: "0611", name: "Pespire", parent: "06" },
    { value: "0612", name: "San Antonio de Flores", parent: "06" },
    { value: "0613", name: "San Isidro", parent: "06" },
    { value: "0614", name: "San José", parent: "06" },
    { value: "0615", name: "San Marcos de Colón", parent: "06" },
    { value: "0616", name: "Santa Ana de Yusguare", parent: "06" },
    { value: "0701", name: "Yuscarán", parent: "07" },
    { value: "0702", name: "Alauca", parent: "07" },
    { value: "0703", name: "Danlí", parent: "07" },
    { value: "0704", name: "El Paraíso", parent: "07" },
    { value: "0705", name: "Güinope", parent: "07" },
    { value: "0706", name: "Jacaleapa", parent: "07" },
    { value: "0707", name: "Liure", parent: "07" },
    { value: "0708", name: "Morocelí", parent: "07" },
    { value: "0709", name: "Oropolí", parent: "07" },
    { value: "0710", name: "Potrerillos", parent: "07" },
    { value: "0711", name: "San Antonio de Flores", parent: "07" },
    { value: "0712", name: "San Lucas", parent: "07" },
    { value: "0713", name: "San Matías", parent: "07" },
    { value: "0714", name: "Soledad", parent: "07" },
    { value: "0715", name: "Teupasenti", parent: "07" },
    { value: "0716", name: "Texiguat", parent: "07" },
    { value: "0717", name: "Vado Ancho", parent: "07" },
    { value: "0718", name: "Yauyupe", parent: "07" },
    { value: "0719", name: "Trojes", parent: "07" },
    { value: "0801", name: "Tegucigalpa", parent: "08" },
    { value: "0802", name: "Alubarén", parent: "08" },
    { value: "0803", name: "Cedros", parent: "08" },
    { value: "0804", name: "Curarén", parent: "08" },
    { value: "0805", name: "El Porvenir", parent: "08" },
    { value: "0806", name: "Guaimaca", parent: "08" },
    { value: "0807", name: "La Libertad", parent: "08" },
    { value: "0808", name: "La Venta", parent: "08" },
    { value: "0809", name: "Lepaterique", parent: "08" },
    { value: "0810", name: "Maraita", parent: "08" },
    { value: "0811", name: "Marale", parent: "08" },
    { value: "0812", name: "Nueva Armenia", parent: "08" },
    { value: "0813", name: "Ojojona", parent: "08" },
    { value: "0814", name: "Orica", parent: "08" },
    { value: "0815", name: "Reitoca", parent: "08" },
    { value: "0816", name: "Sabanagrande", parent: "08" },
    { value: "0817", name: "San Antonio de Oriente", parent: "08" },
    { value: "0818", name: "San Buenaventura", parent: "08" },
    { value: "0819", name: "San Ignacio", parent: "08" },
    { value: "0820", name: "San Juan de Flores", parent: "08" },
    { value: "0821", name: "San Miguelito", parent: "08" },
    { value: "0822", name: "Santa Ana", parent: "08" },
    { value: "0823", name: "Santa Lucía", parent: "08" },
    { value: "0824", name: "Talanga", parent: "08" },
    { value: "0826", name: "Tatumbla", parent: "08" },
    { value: "0826", name: "Valle de Ángeles", parent: "08" },
    { value: "0827", name: "Villa de San Francisco", parent: "08" },
    { value: "0828", name: "Vallecillo ", parent: "08" },
    { value: "0901", name: "Puerto Lempira", parent: "09" },
    { value: "0902", name: "Brus Laguna", parent: "09" },
    { value: "0903", name: "Ahuas", parent: "09" },
    { value: "0904", name: "Juan Francisco Bulnes", parent: "09" },
    { value: "0905", name: "Ramón Villeda Morales", parent: "09" },
    { value: "0906", name: "Wampusirpe", parent: "09" },
    { value: "1001", name: "La Esperanza", parent: "10" },
    { value: "1002", name: "Camasca", parent: "10" },
    { value: "1003", name: "Colomoncagua", parent: "10" },
    { value: "1004", name: "Concepción", parent: "10" },
    { value: "1005", name: "Dolores", parent: "10" },
    { value: "1006", name: "Intibucá", parent: "10" },
    { value: "1007", name: "Jesús de Otoro", parent: "10" },
    { value: "1008", name: "Magdalena", parent: "10" },
    { value: "1009", name: "Masaguara", parent: "10" },
    { value: "1010", name: "San Antonio", parent: "10" },
    { value: "1011", name: "San Isidro", parent: "10" },
    { value: "1012", name: "San Juan", parent: "10" },
    { value: "1013", name: "San Marcos de la Sierra", parent: "10" },
    { value: "1014", name: "San Miguel Guancapla", parent: "10" },
    { value: "1015", name: "Santa Lucía", parent: "10" },
    { value: "1016", name: "Yamaranguila", parent: "10" },
    { value: "1017", name: "San Francisco de Opalaca", parent: "10" },
    { value: "1101", name: "Roatán", parent: "11" },
    { value: "1102", name: "Guanaja", parent: "11" },
    { value: "1103", name: "José Santos Guardiola", parent: "11" },
    { value: "1104", name: "Utila", parent: "11" },
    { value: "1201", name: "La Paz", parent: "12" },
    { value: "1202", name: "Aguanqueterique", parent: "12" },
    { value: "1203", name: "Cabañas", parent: "12" },
    { value: "1204", name: "Cane", parent: "12" },
    { value: "1205", name: "Chinacla", parent: "12" },
    { value: "1206", name: "Guajiquiro", parent: "12" },
    { value: "1207", name: "Lauterique", parent: "12" },
    { value: "1208", name: "Marcala", parent: "12" },
    { value: "1209", name: "Mercedes de Oriente", parent: "12" },
    { value: "1210", name: "Opatoro", parent: "12" },
    { value: "1211", name: "San Antonio del Norte", parent: "12" },
    { value: "1212", name: "San José", parent: "12" },
    { value: "1213", name: "San Juan", parent: "12" },
    { value: "1214", name: "San Pedro de Tutule", parent: "12" },
    { value: "1215", name: "Santa Ana", parent: "12" },
    { value: "1216", name: "Santa Elena", parent: "12" },
    { value: "1217", name: "Santa María", parent: "12" },
    { value: "1218", name: "Santiago de Puringla", parent: "12" },
    { value: "1219", name: "Yarula", parent: "12" },
    { value: "1301", name: "Gracias", parent: "13" },
    { value: "1302", name: "Belén", parent: "13" },
    { value: "1303", name: "Candelaria", parent: "13" },
    { value: "1304", name: "Cololaca", parent: "13" },
    { value: "1305", name: "Erandique", parent: "13" },
    { value: "1306", name: "Gualcince", parent: "13" },
    { value: "1307", name: "Guarita", parent: "13" },
    { value: "1308", name: "La Campa", parent: "13" },
    { value: "1309", name: "La Iguala", parent: "13" },
    { value: "1310", name: "Las Flores", parent: "13" },
    { value: "1311", name: "La Unión", parent: "13" },
    { value: "1312", name: "La Virtud", parent: "13" },
    { value: "1313", name: "Lepaera", parent: "13" },
    { value: "1314", name: "Mapulaca", parent: "13" },
    { value: "1315", name: "Piraera", parent: "13" },
    { value: "1316", name: "San Andrés", parent: "13" },
    { value: "1317", name: "San Francisco", parent: "13" },
    { value: "1318", name: "San Juan Guarita", parent: "13" },
    { value: "1319", name: "San Manuel Colohete", parent: "13" },
    { value: "1320", name: "San Rafael", parent: "13" },
    { value: "1321", name: "San Sebastián", parent: "13" },
    { value: "1322", name: "Santa Cruz", parent: "13" },
    { value: "1323", name: "Talgua", parent: "13" },
    { value: "1324", name: "Tambla", parent: "13" },
    { value: "1325", name: "Tomalá", parent: "13" },
    { value: "1326", name: "Valladolid", parent: "13" },
    { value: "1327", name: "Virginia", parent: "13" },
    { value: "1328", name: "San Marcos de Caiquín", parent: "13" },
    { value: "1401", name: "Ocotepeque", parent: "14" },
    { value: "1402", name: "Belén Gualcho", parent: "14" },
    { value: "1403", name: "Concepción", parent: "14" },
    { value: "1404", name: "Dolores Merendón", parent: "14" },
    { value: "1405", name: "Fraternidad", parent: "14" },
    { value: "1406", name: "La Encarnación", parent: "14" },
    { value: "1407", name: "La Labor", parent: "14" },
    { value: "1408", name: "Lucerna", parent: "14" },
    { value: "1409", name: "Mercedes", parent: "14" },
    { value: "1410", name: "San Fernando", parent: "14" },
    { value: "1411", name: "San Francisco del Valle", parent: "14" },
    { value: "1412", name: "San Jorge", parent: "14" },
    { value: "1413", name: "San Marcos", parent: "14" },
    { value: "1414", name: "Santa Fe", parent: "14" },
    { value: "1415", name: "Sensenti", parent: "14" },
    { value: "1416", name: "Sinuapa", parent: "14" },
    { value: "1501", name: "Juticalpa", parent: "15" },
    { value: "1502", name: "Campamento", parent: "15" },
    { value: "1503", name: "Catacamas ", parent: "15" },
    { value: "1504", name: "Concordia", parent: "15" },
    { value: "1505", name: "Dulce Nombre de Culmí", parent: "15" },
    { value: "1506", name: "El Rosario", parent: "15" },
    { value: "1507", name: "Esquipulas del Norte", parent: "15" },
    { value: "1508", name: "Gualaco", parent: "15" },
    { value: "1509", name: "Guarizama", parent: "15" },
    { value: "1510", name: "Guata", parent: "15" },
    { value: "1511", name: "Guayape", parent: "15" },
    { value: "1512", name: "Jano", parent: "15" },
    { value: "1513", name: "La Unión", parent: "15" },
    { value: "1514", name: "Mangulile", parent: "15" },
    { value: "1515", name: "Manto", parent: "15" },
    { value: "1516", name: "Salamá", parent: "15" },
    { value: "1517", name: "San Esteban", parent: "15" },
    { value: "1518", name: "San Francisco de Becerra", parent: "15" },
    { value: "1519", name: "San Francisco de la Paz", parent: "15" },
    { value: "1520", name: "Santa María del Real", parent: "15" },
    { value: "1521", name: "Silca", parent: "15" },
    { value: "1522", name: "Yocón", parent: "15" },
    { value: "1523", name: "Patuca", parent: "15" },
    { value: "1601", name: "Santa Bárbara", parent: "16" },
    { value: "1602", name: "Arada", parent: "16" },
    { value: "1603", name: "Atima", parent: "16" },
    { value: "1604", name: "Azacualpa", parent: "16" },
    { value: "1605", name: "Ceguaca", parent: "16" },
    { value: "1606", name: "San José de las Colinas", parent: "16" },
    { value: "1607", name: "Concepción del Norte", parent: "16" },
    { value: "1608", name: "Concepción del Sur", parent: "16" },
    { value: "1609", name: "Chinda", parent: "16" },
    { value: "1610", name: "El Níspero", parent: "16" },
    { value: "1611", name: "Gualala", parent: "16" },
    { value: "1612", name: "Ilama", parent: "16" },
    { value: "1613", name: "Macuelizo", parent: "16" },
    { value: "1614", name: "Naranjito", parent: "16" },
    { value: "1615", name: "Nuevo Celilac", parent: "16" },
    { value: "1616", name: "Petoa", parent: "16" },
    { value: "1617", name: "Protección", parent: "16" },
    { value: "1618", name: "Quimistán", parent: "16" },
    { value: "1619", name: "San Francisco de Ojuera", parent: "16" },
    { value: "1620", name: "San Luis", parent: "16" },
    { value: "1621", name: "San Marcos", parent: "16" },
    { value: "1622", name: "San Nicolás", parent: "16" },
    { value: "1623", name: "San Pedro Zacapa", parent: "16" },
    { value: "1624", name: "Santa Rita", parent: "16" },
    { value: "1625", name: "San Vicente Centenario", parent: "16" },
    { value: "1626", name: "Trinidad", parent: "16" },
    { value: "1627", name: "Las Vegas", parent: "16" },
    { value: "1628", name: "Nueva Frontera", parent: "16" },
    { value: "1701", name: "Nacaome", parent: "17" },
    { value: "1702", name: "Alianza", parent: "17" },
    { value: "1703", name: "Amapala", parent: "17" },
    { value: "1704", name: "Aramecina", parent: "17" },
    { value: "1705", name: "Caridad", parent: "17" },
    { value: "1706", name: "Goascorán", parent: "17" },
    { value: "1707", name: "Langue", parent: "17" },
    { value: "1708", name: "San Francisco de Coray", parent: "17" },
    { value: "1709", name: "San Lorenzo", parent: "17" },
    { value: "1801", name: "Yoro", parent: "18" },
    { value: "1802", name: "Arenal", parent: "18" },
    { value: "1803", name: "El Negrito", parent: "18" },
    { value: "1804", name: "El Progreso", parent: "18" },
    { value: "1805", name: "Jocón", parent: "18" },
    { value: "1806", name: "Morazán", parent: "18" },
    { value: "1807", name: "Olanchito", parent: "18" },
    { value: "1808", name: "Santa Rita", parent: "18" },
    { value: "1809", name: "Sulaco", parent: "18" },
    { value: "1810", name: "Victoria", parent: "18" },
    { value: "1811", name: "Yorito", parent: "18" },
  ],
};
