import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchantComplianceData } from "../../../../types/merchant_compliance_data";
import {
  NotificationTypeEnum,
  SnackbarEnum,
} from "../../../shared/enums/SnackbarEnum";
import { buildNotification } from "../../../shared/constants/snackbar";
import { IMerchantComplianceData } from "../../interfaces/merchantComplianceData/merchantComplianceData.interfaces";
import {
  getMerchantComplianceData,
  postMerchantComplianceData,
} from "../../thunks/merchantComplianceData/merchantComplianceData.thunks";

export const initialState: IMerchantComplianceData = {
  isUpdate: false,
  merchantComplianceData: {
    categoryMerchant: "",
    commercialExecutive: "",
    commercialExecutiveEmail: "",
    country: "",
    kind: "",
    merchantName: "",
    publicMerchantId: "",
    status: "",
  },
  notification: {
    color: "success",
    message: SnackbarEnum.REQUEST_SUCCESS,
    variant: "simple",
    withIcon: false,
  },
};

export const merchantComplianceDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        getMerchantComplianceData.fulfilled,
        (state, { payload }: PayloadAction<MerchantComplianceData>) => {
          state.merchantComplianceData = payload;
          state.isUpdate = false;
        }
      )
      .addCase(
        postMerchantComplianceData.fulfilled,
        (state, { payload }: PayloadAction<MerchantComplianceData>) => {
          state.merchantComplianceData = payload;
          state.isUpdate = true;
        }
      )
      .addCase(postMerchantComplianceData.rejected, (state) => {
        state.isUpdate = false;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          state.notification
        );
      });
  },
  initialState,
  name: "merchantComplianceData",
  reducers: {},
});

export default merchantComplianceDataSlice.reducer;
