import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { setIsLoadingFlow } from "../../actions/owner.actions";
import {
  generateRequiredConfigsLabel,
  nodeInfo,
  validateRequiredConfigs,
  validateUserConfig,
} from "../../../shared/utils/verifyStatus.utils";
import { each, find, get } from "lodash";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";

export const searchBranchMerchants = createAsyncThunk<
  SearchMerchantResponse,
  SearchMerchantRequest
>("branch/searchMerchant", async (request, { dispatch }) => {
  const response = await axios.post<SearchMerchantResponse>(
    API_ROUTES.SEARCH_MERCHANT_NODE,
    request,
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  dispatch(setIsLoadingFlow(false));

  return response.data;
});

export const addBranchAditionalProperties = createAsyncThunk<
  MerchantNodeData[],
  MerchantNodeData[]
>(
  "branch/addCentralized",
  async (request: MerchantNodeData[], { dispatch }) => {
    const nodeInfoResponse = await Promise.all(
      request.map((branch) => nodeInfo(get(branch, "merchant_id", "")))
    );
    const nodes: MerchantNodeData[] = [];

    each(nodeInfoResponse, (branch) => {
      const matchedNode = find(
        request,
        (item) => item.merchant_id === branch.data.merchantId
      );

      if (get(branch, "data.entityName") === EntityNameEnum.CUSTOMER) {
        nodes.push({
          ...matchedNode,
          configs_completed: validateRequiredConfigs(branch.data),
          pending_section: generateRequiredConfigsLabel(branch.data),
          user_config_completed: validateUserConfig(branch.data),
        });
      } else {
        nodes.push({
          ...matchedNode,
          configs_completed: validateRequiredConfigs(branch.data),
          pending_section: generateRequiredConfigsLabel(branch.data),
          user_config_completed: validateUserConfig(branch.data),
        });
      }
    });

    dispatch(setIsLoadingFlow(false));

    return nodes;
  }
);
