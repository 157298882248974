import React from "react";
import { ModalBody as ModalBodyMolecules } from "@kushki/frontend-molecules/modal-body";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SecurityRule } from "../../../types/security_rule";
import { ModalContainerType } from "../ModalContainer/ModalContainer";
import TabComponent from "./TabComponent/TabComponent";
import { MerchantsData } from "../../store/actionCreator";
import { SecurityRuleActionValue } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import GeneralRuleDetails from "./GeneralRuleDetails/GeneralRuleDetails";

export interface ModalBodyProps {
  data: SecurityRule;
  merchant: MerchantsData;
  type: ModalContainerType;
  isMobile: boolean;
}

const ModalBody: React.FC<ModalBodyProps> = (props: ModalBodyProps) => {
  const useStyles = makeStyles(() =>
    createStyles({
      bodyContainer: {
        "& .modal-body-container": {
          width: props.isMobile ? "85vh" : "auto",
        },
      },
    })
  );
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.bodyContainer}>
        {props.data.rule?.action === SecurityRuleActionValue["3DS"] ||
        props.data.rule?.action === SecurityRuleActionValue.OTP ? (
          <ModalBodyMolecules>
            <TabComponent {...props} />
          </ModalBodyMolecules>
        ) : (
          <ModalBodyMolecules>
            <GeneralRuleDetails {...props} />
          </ModalBodyMolecules>
        )}
      </div>
    </React.Fragment>
  );
};

export default ModalBody;
