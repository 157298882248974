import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC } from "react";
import { ProcessingConfirmationModalStyles as styles } from "./ProcessingConfirmationModal.styles";
import { IProcessingConfirmationProps } from "../../../shared/interfaces/IProcessRefunds";
import {
  CANCEL,
  CONFIRM,
  DISCLAIMER,
  HEADER,
  PROCESS_TOTAL,
  REFUND_TOTAL,
} from "../../../shared/constants/labels/processing_modal";

const ProcessingConfirmationModal: FC<IProcessingConfirmationProps> = ({
  handleClose,
  handleConfirm,
  open,
  totalRefunds,
  numRefundsForProcessing,
}: IProcessingConfirmationProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={styles.dialog}
      // @ts-ignore
      maxWidth="mm"
    >
      <DialogTitle>
        <Typography sx={styles.header}>{HEADER}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={styles.disclaimer}>
          {DISCLAIMER}
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={5} sx={styles.label}>
            <Typography>{REFUND_TOTAL}</Typography>
          </Grid>
          <Grid item xs={7} sx={styles.label}>
            <Typography>{totalRefunds}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={styles.divider} />
          </Grid>
          <Grid item xs={5} sx={styles.label}>
            <Typography>{PROCESS_TOTAL}</Typography>
          </Grid>
          <Grid item xs={7} sx={styles.label}>
            <Typography>{numRefundsForProcessing}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mr: 3 }}>
        <Button onClick={handleClose} sx={styles.button}>
          <Typography sx={styles.cancelTxt}>{CANCEL}</Typography>
        </Button>
        <Button onClick={handleConfirm} sx={styles.button}>
          <Typography sx={styles.confirmTxt}>{CONFIRM}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessingConfirmationModal;
