import React, { FC } from "react";
import { Box } from "@mui/material";
import ButtonCard from "../ButtonCard/ButtonCard";

import { useMenuItem } from "./useState/useMenuItem";
import { menuItemStyles } from "./MenuItem.styles";

const MenuItem: FC = () => {
  const { btnResume, buttons, isMassiveOrBatch } = useMenuItem();

  const renderButtons = () => {
    return buttons.map((btn) => {
      return (
        <Box pb={1} key={`btn-${btn.title}`}>
          <ButtonCard
            title={btn.title}
            enableIcon={btn.enableIcon}
            disabledIcon={btn.disabledIcon}
            isSelected={btn.isSelected}
            isDisabled={btn.isDisabled}
            checked={btn.checked}
            url={btn.url}
            id={btn.id}
          />
        </Box>
      );
    });
  };

  return (
    <React.Fragment>
      <Box sx={menuItemStyles.menuItemContent}>
        {renderButtons()}

        {!isMassiveOrBatch && (
          <>
            <Box sx={menuItemStyles.menuItemDivider} />
            <Box pb={3} pt={3}>
              <ButtonCard
                title={btnResume.title}
                isSelected={btnResume.isSelected}
                isDisabled={btnResume.isDisabled}
                url={btnResume.url}
                id={btnResume.id}
              />
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default MenuItem;
