import { FilterWalletDashboardRequest } from "../../../../types/filter_wallet_dashboard_request";
import { useDispatch, useSelector } from "react-redux";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AlertModalConstants } from "../../../shared/infrastructure/constants/WalletDashboardConstants";
import { IAppState } from "../../../store/reducer";
import { DownloadRequest } from "../../../../types/download_request";
import { defaultTo, get, isEmpty } from "lodash";
import { ConsoleValuesEnum } from "../../../shared/infrastructure/enums/ConsoleValuesEnum";
import { RolesEnum } from "../../../shared/infrastructure/enums/RolesEnum";
import {
  downloadReport,
  getSignedUrl,
  setGetSignedUrlResponse,
  setIsDownloadingFile,
  setIsReportGenerated,
  setShowAlertOnDownloadFileError,
  setShowAlertOnDownloadFileSuccess,
} from "../../../store/actionCreators";
import { IDownloadErrorType } from "../../../shared/infrastructure/interfaces/IDownloadError";
import { LocalStoragePathsEnum } from "../../../shared/infrastructure/enums/LocalStoragePathsEnum";
import { CreateReportRequest } from "../../../../types/create_report_request";
import { getLocalStorageValueByPath } from "../../../shared/utils/localStorage-utils";
import { CountryNameEnum } from "../../../shared/infrastructure/enums/CountryEnum";

export interface IUseDownloadButtonComponentState {
  alertModalMessage: string;
  alertModalTitle: string;
  handleDownloadRequest: (option: string) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  isDownloadingFile: boolean;
  downloadError: IDownloadErrorType | null;
  open: boolean;
  id: string | undefined;
  anchor: HTMLElement | null;
  setAnchor: Dispatch<SetStateAction<HTMLElement | null>>;
  showAlertOnDownloadFileSuccess: boolean | undefined;
  showAlertOnDownloadFileError: boolean | undefined;
  handleSetShowAlertDownloadFileError: () => void;
  handleSetShowAlertDownloadFileSuccess: () => void;
  handleOpenModal: () => void;
  emailUser: string;
  openModal: boolean;
}

export const getConsoleValue = (): string => {
  const roles: object = JSON.parse(localStorage.getItem("roles")!);

  return roles[RolesEnum.BackofficeAdmin]
    ? ConsoleValuesEnum.INTERNAL
    : ConsoleValuesEnum.EXTERNAL;
};

export interface IDownloadButtonProps {
  timeoutCloseAlert?: number;
  timeoutSignedUrl?: number;
}

export const useDownloadButtonComponentState = (
  props?: IDownloadButtonProps
): IUseDownloadButtonComponentState => {
  const dispatch = useDispatch();
  const filterWalletDashboardRequest: FilterWalletDashboardRequest | undefined =
    useSelector((state: IAppState) => state.filterWalletDashboardRequest);
  const signedUrlResponse: string | undefined = useSelector(
    (state: IAppState) => state.getSignedUrlResponse
  );
  const isDownloadingFile: boolean | undefined = useSelector(
    (state: IAppState) => defaultTo(state.isDownloadingFile, true)
  );
  const downloadError: IDownloadErrorType | null = useSelector(
    (state: IAppState) => defaultTo(state.downloadError, null)
  );
  const isReportGenerated: boolean | undefined = useSelector(
    (state: IAppState) => state.isReportGenerated
  );
  const isDateRangeError: boolean | undefined = useSelector(
    (state: IAppState) => state.isDateRangeError
  );
  const [timeoutCloseAlert] = useState(get(props, "timeoutCloseAlert", 5000));
  const [timeoutSignedUrl] = useState(get(props, "timeoutSignedUrl", 300));
  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const showAlertOnDownloadFileError: boolean | undefined = useSelector(
    (state: IAppState) => state.showAlertOnDownloadFileError
  );
  const showAlertOnDownloadFileSuccess: boolean | undefined = useSelector(
    (state: IAppState) => state.showAlertOnDownloadFileSuccess
  );
  const emailUser = defaultTo(
    localStorage.getItem(LocalStoragePathsEnum.USER_MAIL),
    ""
  );
  const merchantData = getLocalStorageValueByPath(
    LocalStoragePathsEnum.BASIC_MERCHANT_INFORMATION
  );
  const merchantId = get(
    merchantData,
    "publicMerchantId",
    localStorage.getItem("merchantId") as string
  );
  const walletReportCountries: CountryNameEnum[] = [
    CountryNameEnum.mexico,
    CountryNameEnum.chile,
  ];

  const downloadFile = (signedUrl: string) => {
    const a = document.createElement("a");

    a.href = signedUrl;
    a.click();
    a.remove();
  };

  useEffect(() => {
    if (isEmpty(signedUrlResponse)) return;

    downloadFile(signedUrlResponse!);
    setTimeout(() => {
      dispatch(setGetSignedUrlResponse(""));
    }, timeoutSignedUrl);
  }, [signedUrlResponse]);

  useEffect(() => {
    if (showAlertOnDownloadFileError) {
      setTimeout(() => {
        dispatch(setShowAlertOnDownloadFileError(false));
      }, timeoutCloseAlert);
    }
  }, [showAlertOnDownloadFileError]);

  useEffect(() => {
    if (showAlertOnDownloadFileSuccess) {
      setTimeout(() => {
        dispatch(setShowAlertOnDownloadFileSuccess(false));
      }, timeoutCloseAlert);
    }
  }, [showAlertOnDownloadFileSuccess]);

  const handleDownloadRequest = (option: string) => {
    const country = get(merchantData, "country", "");

    walletReportCountries.includes(country)
      ? handleGenerateUserMasterReport(option)
      : handleDownloadFile(option);
  };

  const handleDownloadFile = (option: string): void => {
    dispatch(setIsDownloadingFile(true));
    const downloadRequest: DownloadRequest = {
      action: "downloadWalletTransactions",
      data: {
        filter: {
          operationType: get(
            filterWalletDashboardRequest,
            "filter.operationType"
          ),
          origin_ticket_number: get(
            filterWalletDashboardRequest,
            "filter.origin_ticket_number"
          ),
          transaction_status: get(
            filterWalletDashboardRequest,
            "filter.transaction_status"
          ),
        },
        document_format: option.toLowerCase(),
        document_type: getConsoleValue() as ConsoleValuesEnum,
        to: get(filterWalletDashboardRequest, "to", ""),
        from: get(filterWalletDashboardRequest, "from", ""),
        public_merchant_id: defaultTo(merchantId, ""),
      },
    };

    dispatch(getSignedUrl(downloadRequest));
  };

  const handleGenerateUserMasterReport = (option: string) => {
    const reportRequest: CreateReportRequest = {
      fileType: option,
      endDate: get(filterWalletDashboardRequest, "to", ""),
      startDate: get(filterWalletDashboardRequest, "from", ""),
      publicMerchantId: merchantId,
      masterUserEmail: emailUser,
    };

    dispatch(downloadReport(reportRequest));
  };

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!isDownloadingFile) setAnchor(event.currentTarget);
  };

  const handleSetShowAlertDownloadFileError = (): void => {
    dispatch(setShowAlertOnDownloadFileError(false));
  };

  const handleSetShowAlertDownloadFileSuccess = (): void => {
    dispatch(setShowAlertOnDownloadFileSuccess(false));
  };

  const open: boolean = Boolean(anchor);
  const id: string | undefined = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchor(null);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
    dispatch(setIsReportGenerated(false));
  };

  useEffect(() => {
    setOpenModal(isReportGenerated!);
  }, [isReportGenerated]);

  useEffect(() => {
    if (isDateRangeError) {
      setTitleModal(AlertModalConstants.errorDateRangeTitle);
      setMessageModal(AlertModalConstants.errorDateRangeLbl);

      return;
    }
    setTitleModal(AlertModalConstants.titleLbl);
    setMessageModal(`${AlertModalConstants.modalDescriptionLbl}${emailUser}`);
  }, [isDateRangeError]);

  return {
    alertModalMessage: messageModal,
    alertModalTitle: titleModal,
    anchor: anchor,
    downloadError: downloadError,
    id: id,
    isDownloadingFile: isDownloadingFile,
    open: open,
    showAlertOnDownloadFileError: showAlertOnDownloadFileError,
    showAlertOnDownloadFileSuccess: showAlertOnDownloadFileSuccess,
    emailUser,
    handleClick: handleClick,
    handleClose: handleClose,
    handleDownloadRequest,
    handleSetShowAlertDownloadFileError: handleSetShowAlertDownloadFileError,
    handleSetShowAlertDownloadFileSuccess:
      handleSetShowAlertDownloadFileSuccess,
    setAnchor: setAnchor,
    handleOpenModal,
    openModal,
  };
};
