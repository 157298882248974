export const CIIU_CATALOG = {
  "1010": "1010 - ELABORACIÓN Y CONSERVACIÓN DE CARNE",
  "1020":
    "1020 - ELABORACIÓN Y CONSERVACIÓN DE PESCADOS, CRUSTÁCEOS Y MOLUSCOS",
  "1030": "1030 - ELABORACIÓN Y CONSERVACIÓN DE FRUTAS,LEGUMBRES Y HORTALIZAS",
  "1040": "1040 - ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL",
  "1050": "1050 - ELABORACIÓN DE PRODUCTOS LÁCTEOS",
  "1061": "1061 - ELABORACIÓN DE PRODUCTOS DE MOLINERÍA",
  "1062": "1062 - ELABORACIÓN DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDÓN",
  "1071": "1071 - ELABORACIÓN DE PRODUCTOS DE PANADERÍA",
  "1072": "1072 - ELABORACIÓN DE AZÚCAR",
  "1073":
    "1073 - ELABORACIÓN DE CACAO Y CHOCOLATE Y DE PRODUCTOS DE CONFITERÍA",
  "1074":
    "1074 - ELABORACIÓN DE MACARRONES, FIDEOS, ALCUZCUS Y PRODUCTOS FARINÁCEOS SIMILARES",
  "1075": "1075 - ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS",
  "1079": "1079 - ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS N.C.P.",
  "1080": "1080 - ELABORACIÓN DE PIENSOS PREPARADOS PARA ANIMALES",
  "1084": "1084 - ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS",
  "1089": "1089 - ELABORACIÓN DE TÉ, CAFÉ, MATE E INFUSIONES DE HIERBAS",
  "1101": "1101 - DESTILACIÓN, RECTIFICACIÓN Y MEZCLA DE BEBIDAS ALCOHÓLICAS",
  "1102": "1102 - ELABORACIÓN DE VINOS",
  "1103": "1103 - ELABORACIÓN DE BEBIDAS MALTEADAS Y DE MALTA",
  "1104":
    "1104 - ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS; PRODUCCIÓN DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS",
  "1200": "1200 - ELABORACIÓN DE PRODUCTOS DE TABACO",
  "1311": "1311 - PREPARACIÓN E HILATURA DE FIBRAS TEXTILES",
  "1312": "1312 - TEJEDURA DE PRODUCTOS TEXTILES",
  "1313": "1313 - ACABADO DE PRODUCTOS TEXTILES",
  "1391": "1391 - FABRICACIÓN DE TEJIDOS DE PUNTO Y GANCHILLO",
  "1392":
    "1392 - FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR",
  "1393": "1393 - FABRICACIÓN DE TAPICES Y ALFOMBRAS",
  "1394": "1394 - FABRICACIÓN DE CUERDAS, CORDELES, BRAMANTES Y REDES",
  "1399": "1399 - FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.P.",
  "1410": "1410 - FABRICACIÓN DE PRENDAS DE VESTIR, EXCEPTO PRENDAS DE PIEL",
  "1420": "1420 - FABRICACIÓN DE ARTÍCULOS DE PIEL",
  "1430": "1430 - FABRICACIÓN DE ARTICULOS DE PUNTO Y GANCHILLO",
  "1511": "1511 - CURTIDO Y ADOBO DE CUEROS; ADOBO Y TEÑIDO DE PIELES",
  "1512":
    "1512 - FABRICACIÓN DE MALETAS, BOLSOS DE MANO, Y ARTÍCULOS SIMILARES,Y DE ARTICULOS DE TALABARTERÍA Y GUARNICIONERÍA",
  "1520": "1520 - FABRICACIÓN DE CALZADO",
  "1521":
    "1521 - FABRICACIÓN DE CALZADO DE CUERO Y PIEL, CON CUALQUIER TIPO DE SUELA",
  "1610": "1610 - ASERRADOS Y ACEPILLADURA DE MADERA",
  "1621":
    "1621 - FABRICACIÓN DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA",
  "1622":
    "1622 - FABRICACIÓN DE PARTES Y PIEZAS DE CARPINTERÍA PARA EDIFICIOS Y CONSTRUCCIONES",
  "1623": "1623 - FABRICACIÓN DE RECIPIENTES DE MADERA",
  "1629":
    "1629 - FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; FABRICACIÓN DE ARTÍCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES.",
  "1690":
    "1690 - FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; FABRICACIÓN DE ARTÍCULOS DE CORCHO, CESTERÍA Y ESPARTERÍA",
  "1701": "1701 - FABRICACIÓN DE PASTA DE MADERA, PAPEL Y CARTÓN",
  "1702":
    "1702 - FABRICACIÓN DEL PAPEL Y CARTÓN ONDULADO Y DE ENVASES DE PAPEL Y CARTÓN",
  "1709": "1709 - FABRICACIÓN DE OTROS ARTÍCULOS DEL PAPEL Y CARTÓN",
  "1811": "1811 - IMPRESIÓN",
  "1812": "1812 - ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESIÓN",
  "1820": "1820 - REPRODUCCIÓN DE GRABACIONES",
  "1910": "1910 - FABRICACIÓN DE PRODUCTOS DE HORNOS DE COQUE",
  "1920": "1920 - FABRICACIÓN DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO",
  "2011": "2011 - FABRICACIÓN DE SUSTANCIAS QUÍMICAS BÁSICAS",
  "2012": "2012 - FABRICACIÓN ABONOS Y COMPUESTOS DE NITRÓGENO",
  "2013":
    "2013 - FABRICACIÓN DE PLÁSTICOS Y DE CAUCHO SINTÉTICO EN FORMAS PRIMARIAS",
  "2021":
    "2021 - FABRICACIÓN DE PLAGUICIDAS Y OTROS PRODUCTOS QUÍMICOS DE USO AGROPECUARIO",
  "2022":
    "2022 - FABRICACIÓN DE PINTURAS, BARNICES Y PRODUCTOS DE REVESTIMIENTO SIMILARES, TINTAS DE IMPRENTA Y MASILLAS",
  "2023":
    "2023 - FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR Y PULIR, PERFUMES Y PREPARADOS DE TOCADOR.",
  "2029": "2029 - FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.P.",
  "2030": "2030 - FABRICACIÓN DE FIBRAS ARTIFICIALES",
  "2100":
    "2100 - FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO",
  "2211":
    "2211 - FABRICACIÓN DE CUBIERTAS Y CÁMARAS DE CAUCHO; RECAUCHUTADO Y RENOVACIÓN DE CUBIERTAS DE CAUCHO",
  "2219": "2219 - FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO",
  "2220": "2220 - FABRICACIÓN DE PRODUCTOS DE PLÁSTICO",
  "2229": "2229 - FABRICACIÓN DE ARTÍCULOS DE PLÁSTICO N.C.P.",
  "2310": "2310 - FABRICACIÓN DE VIDRIO Y DE PRODUCTOS DE VIDRIO",
  "2391": "2391 - FABRICACIÓN DE PRODUCTOS REFRACTARIOS",
  "2392": "2392 - FABRICACIÓN DE MATERIALES DE CONSTRUCCIÓN DE ARCILLA",
  "2393": "2393 - FABRICACIÓN DE OTROS PRODUCTOS DE PORCELANA Y DE CERÁMICA",
  "2394": "2394 - FABRICACIÓN DE CEMENTO, CAL Y YESO",
  "2395": "2395 - FABRICACIÓN DE ARTÍCULOS DE HORMIGÓN, DE CEMENTO Y DE YESO",
  "2396": "2396 - CORTE, TALLA Y ACABADO DE LA PIEDRA",
  "2399": "2399 - FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.P.",
  "2410": "2410 - INDUSTRIAS BÁSICAS DE HIERRO Y ACERO ",
  "2420":
    "2420 - FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y OTROS METALES NO FERROSOS",
  "2421": "2421 - INDUSTRIAS BÁSICAS DE METALES PRECIOSOS",
  "2429": "2429 - INDUSTRIAS BÁSICAS DE OTROS METALES NO FERROSOS",
  "2431": "2431 - FUNDICIÓN DE HIERRO Y ACERO",
  "2432": "2432 - FUNDICIÓN DE METALES NO FERROSOS",
  "2511": "2511 - FABRICACIÓN DE PRODUCTOS METÁLICOS PARA USO ESTRUCTURAL",
  "2512": "2512 - FABRICACIÓN DE TANQUES, DEPÓSITOS Y RECIPIENTES DE METAL",
  "2513":
    "2513 - FABRICACIÓN DE LOS GENERADORES DEL VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCIÓN CENTRAL",
  "2520": "2520 - FABRICACIÓN DE ARMAS Y MUNICIONES",
  "2591":
    "2591 - FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES; PULVIMETALURGIA",
  "2592": "2592 - TRATAMIENTO Y REVESTIMIENTO DE METALES; MAQUINADO",
  "2593":
    "2593 - FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA, HERRAMIENTAS DE MANO Y ARTÍCULOS DE FERRETERÍA",
  "2599": "2599 - FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.",
  "2610": "2610 - FABRICACIÓN DE COMPONENTES Y TABLEROS ELECTRÓNICOS",
  "2620": "2620 - FABRICACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO",
  "2630": "2630 - FABRICACIÓN DE EQUIPOS DE COMUNICACIONES",
  "2640": "2640 - FABRICACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO",
  "2651":
    "2651 - FABRICACIÓN DE EQUIPO DE MEDICIÓN, PRUEBA, NAVEGACIÓN Y CONTROL",
  "2652": "2652 - FABRICACIÓN DE RELOJES",
  "2660":
    "2660 - FABRICACIÓN DE EQUIPO DE IRRADIACIÓN Y EQUIPO ELECTRÓNICO DE USO MÉDICO Y TERAPÉUTICO",
  "2670": "2670 - FABRICACIÓN DE INSTRUMENTOS ÓPTICOS Y EQUIPO FOTOGRÁFICO",
  "2680": "2680 - FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS",
  "2710":
    "2710 - FABRICACIÓN DE MOTORES, GENERADORES Y TRANSFORMADORES ELÉCTRICOS Y APARATOS DE DISTRIBUCIÓN Y CONTROL DE LA ENERGÍA ELÉCTRICA",
  "2720": "2720 - FABRICACIÓN DE PILAS, BATERÍAS Y ACUMULADORES",
  "2731": "2731 - FABRICACIÓN DE CABLES DE FIBRA ÓPTICA",
  "2732": "2732 - FABRICACIÓN DE OTROS HILOS Y CABLES ELÉCTRICOS",
  "2733": "2733 - FABRICACIÓN DE DISPOSITIVOS DE CABLEADO",
  "2740": "2740 - FABRICACIÓN DE EQUIPO ELÉCTRICO DE ILUMINACIÓN",
  "2750": "2750 - FABRICACIÓN DE APARATOS DE USO DOMÉSTICO",
  "2790": "2790 - FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO",
  "2811":
    "2811 - FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO MOTORES PARA AERONAVES, VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS",
  "2812": "2812 - FABRICACIÓN DE EQUIPO DE PROPULSIÓN DE FLUIDOS",
  "2813": "2813 - FABRICACIÓN DE BOMBAS, COMPRESORES, GRIFOS Y VÁLVULAS",
  "2814":
    "2814 - FABRICACIÓN DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISIÓN",
  "2815": "2815 - FABRICACIÓN DE HORNOS, HOGARES Y QUEMADORES",
  "2816": "2816 - FABRICACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN",
  "2817":
    "2817 - FABRICACIÓN DE MAQUINARIA Y EQUIPO DE OFICINA (EXCEPTO ORDENADORES Y EQUIPO PERIFÉRICO)",
  "2818": "2818 - FABRICACIÓN DE HERRAMIENTAS DE MANO MOTORIZADAS",
  "2819": "2819 - FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO GENERAL",
  "2821": "2821 - FABRICACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL",
  "2822":
    "2822 - FABRICACIÓN DE MAQUINARIA PARA LA CONFORMACIÓN DE METALES Y DE MÁQUINAS HERRAMIENTA",
  "2823": "2823 - FABRICACIÓN DE MAQUINARIA METALÚRGICA",
  "2824":
    "2824 - FABRICACIÓN DE MAQUINARIA PARA EXPLOTACIÓN DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCIÓN",
  "2825":
    "2825 - FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO",
  "2826":
    "2826 - FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CUEROS",
  "2829": "2829 - FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL",
  "2910": "2910 - FABRICACIÓN DE VEHÍCULOS AUTOMOTORES",
  "2920":
    "2920 - FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES",
  "2930":
    "2930 - FABRICACIÓN DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS DE AUTOMOTORES",
  "3011": "3011 - CONSTRUCCIÓN DE BUQUES Y ESTRUCTURAS FLOTANTES",
  "3012": "3012 - CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DEPORTE",
  "3020": "3020 - FABRICACIÓN DE LOCOMOTORAS Y DE MATERIAL RODANTE",
  "3030":
    "3030 - FABRICACIÓN DE AERONAVES Y  NAVES ESPACIALES Y MAQUINARIA CONEXA",
  "3040": "3040 - FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE",
  "3091": "3091 - FABRICACIÓN DE MOTOCICLETAS",
  "3092":
    "3092 - FABRICACIÓN DE BICICLETAS Y DE SILLONES DE RUEDAS PARA INVÁLIDOS",
  "3099": "3099 - FABRICACIÓN DE OTROS TIPOS DE EQUIPO DE TRANSPORTE N.C.P.",
  "3100": "3100 - FABRICACIÓN DE MUEBLES",
  "3210": "3210 - FABRICACIÓN DE JOYAS, BISUTERÍA Y ARTÍCULOS CONEXOS",
  "3211": "3211 - FABRICACIÓN DE JOYAS Y ARTÍCULOS CONEXOS",
  "3212": "3212 - FABRICACIÓN DE BISUTERÍA Y ARTÍCULOS CONEXOS",
  "3220": "3220 - FABRICACIÓN DE INSTRUMENTOS DE MÚSICA",
  "3230": "3230 - FABRICACIÓN DE ARTÍCULOS DE DEPORTE",
  "3240": "3240 - FABRICACIÓN DE JUEGOS Y JUGUETES",
  "3250":
    "3250 - FABRICACIÓN DE INSTRUMENTOS Y MATERIALES MÉDICOS Y ODONTOLÓGICOS",
  "3290": "3290 - OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.",
  "3311": "3311 - REPARACIÓN DE PRODUCTOS ELABORADOS DE METAL",
  "3312": "3312 - REPARACIÓN DE MAQUINARIA",
  "3313": "3313 - REPARACIÓN DE EQUIPO ELECTRÓNICO Y ÓPTICO",
  "3314": "3314 - REPARACIÓN DE EQUIPO ELÉCTRICO",
  "3315":
    "3315 - REPARACIÓN DE EQUIPO DE TRANSPORTE, EXCEPTO VEHÍCULOS AUTOMOTORES",
  "3319": "3319 - REPARACIÓN DE OTROS TIPOS DE EQUIPO",
  "3320": "3320 - INSTALACIÓN DE MAQUINARIA Y EQUIPO INDUSTRIALES",
  "3510": "3510 - GENERACIÓN, TRANSMISIÓN Y DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA",
  "3511": "3511 - GENERACIÓN DE ENERGÍA ELÉCTRICA EN OTRAS CENTRALES N.C.P",
  "3520":
    "3520 - FABRICACIÓN DEL GAS; DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍAS",
  "3530": "3530 - SUMINISTRO DE VAPOR Y AIRE ACONDICIONADO",
  "3600": "3600 - CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA",
  "3700": "3700 - EVACUACIÓN DE AGUAS RESIDUALES",
  "3811": "3811 - RECOGIDA DE DESECHOS NO PELIGROSOS",
  "3812": "3812 - RECOGIDA DE DESECHOS PELIGROSOS",
  "3821": "3821 - TRATAMIENTO Y ELIMINACIÓN DE DESECHOS NO PELIGROSOS",
  "3822": "3822 - TRATAMIENTO Y ELIMINACIÓN DE DESECHOS PELIGROSOS",
  "3830": "3830 - RECUPERACIÓN DE MATERIALES",
  "3900":
    "3900 - ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS",
  "4100": "4100 - CONSTRUCCIÓN DE EDIFICIOS",
  "4112": "4112 - CONSTRUCCIÓN DE EDIFICIOS NO RESIDENCIALES",
  "4210": "4210 - CONSTRUCCIÓN DE CARRETERAS Y LÍNEAS DE FERROCARRIL",
  "4220": "4220 - CONSTRUCCIÓN DE PROYECTOS DE SERVICIO PÚBLICO",
  "4290": "4290 - CONSTRUCCIÓN DE OTRAS OBRAS DE INGENIERÍA CIVIL",
  "4311": "4311 - DEMOLICIÓN",
  "4312": "4312 - PREPARACIÓN DEL TERRENO",
  "4321": "4321 - INSTALACIONES ELÉCTRICAS",
  "4322":
    "4322 - INSTALACIONES DE FONTANERÍA, CALEFACCIÓN Y AIRE ACONDICIONADO",
  "4329": "4329 - OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCIÓN",
  "4330": "4330 - TERMINACIÓN Y ACABADO DE EDIFICIOS",
  "4390": "4390 - OTRAS ACTIVIDADES ESPECIALIZADAS DE LA CONSTRUCCIÓN",
  "4510": "4510 - VENTA DE VEHÍCULOS AUTOMOTORES",
  "4511": "4511 - AEROLINEAS",
  "4512": "4512 - COMERCIO DE VEHÍCULOS AUTOMOTORES USADOS",
  "4520": "4520 - MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES",
  "4530":
    "4530 - VENTAS DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES",
  "4540":
    "4540 - VENTA, MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS.",
  "4541":
    "4541 - COMERCIO DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS",
  "4610":
    "4610 - VENTA AL POR MAYOR A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA",
  "4620":
    "4620 - VENTA AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y ANIMALES VIVOS.",
  "4630": "4630 - VENTA AL POR MAYOR DE ALIMENTOS, BEBIDAS Y TABACO.",
  "4631": "4631 - COMERCIO AL POR MAYOR DE PRODUCTOS ALIMENTICIOS",
  "4632": "4632 - COMERCIO AL POR MAYOR DE BEBIDAS Y TABACO",
  "4641":
    "4641 - VENTA AL POR MAYOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO",
  "4649": "4649 - VENTA AL POR MAYOR DE OTROS ENSERES DOMÉSTICOS",
  "4651":
    "4651 - VENTA AL POR MAYOR DE ORDENADORES, EQUIPO PERIFÉRICO Y PROGRAMAS DE INFORMÁTICA",
  "4652":
    "4652 - VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTRÓNICOS Y DE TELECOMUNICACIONES",
  "4653":
    "4653 - VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS ",
  "4659": "4659 - VENTA AL POR MAYOR DE OTROS TIPOS DE MAQUINARIA Y EQUIPO",
  "4661":
    "4661 - VENTA AL POR MAYOR DE COMBUSTIBLES SÓLIDOS, LÍQUIDOS Y GASEOSOS Y  PRODUCTOS CONEXOS",
  "4662": "4662 - VENTA AL POR MAYOR DE METALES Y MINERALES METALÍFEROS",
  "4663":
    "4663 - VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCIÓN, ARTÍCULOS DE FERRETERÍA Y EQUIPO Y MATERIALES DE FONTANERÍA Y CALEFACCIÓN.",
  "4669":
    "4669 - VENTA AL POR MAYOR DE DESPERDICIOS, DESECHOS, CHATARRA Y OTROS PRODUCTOS N.C.P ",
  "4690": "4690 - VENTA AL POR MAYOR NO ESPECIALIZADA ",
  "4711":
    "4711 - VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS CON PREDOMINIO DE LA VENTA DE ALIMENTOS, BEBIDAS O TABACO",
  "4719":
    "4719 - OTRAS ACTIVIDADES DE VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS",
  "4721": "4721 - VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS",
  "4722": "4722 - VENTA AL POR MENOR DE BEBIDAS EN COMERCIOS ESPECIALIZADOS",
  "4723":
    "4723 - VENTA AL POR MENOR DE PRODUCTOS DE TABACO EN COMERCIOS ESPECIALIZADOS",
  "4729":
    "4729 - COMERCIO AL POR MENOR DE OTROS PRODUCTOS ALIMENTICIOS N.C.P., EN ESTABLECIMIENTOS ESPECIALIZADOS",
  "4730":
    "4730 - VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHÍCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS",
  "4741":
    "4741 - VENTA AL POR MENOR DE ORDENADORES, EQUIPO PERIFÉRICO, PROGRAMAS INFORMÁTICOS Y EQUIPO DE TELECOMUNICACIONES EN COMERCIOS ESPECIALIZADOS",
  "4742":
    "4742 - VENTA AL POR MENOR DE EQUIPO DE SONIDO Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS",
  "4751":
    "4751 - VENTA AL POR MENOR DE PRODUCTOS TEXTILES EN COMERCIOS ESPECIALIZADOS",
  "4752":
    "4752 - VENTA AL POR MENOR DE ARTÍCULOS DE FERRETERÍA, PINTURAS Y PRODUCTOS DE VIDRIO EN COMERCIOS ESPECIALIZADOS",
  "4753":
    "4753 - VENTA AL POR MENOR DE TAPICES, ALFOMBRAS Y CUBRIMIENTOS PARA PAREDES Y PISOS EN COMERCIOS ESPECIALIZADOS",
  "4759":
    "4759 - VENTA AL POR MENOR DE APARATOS ELÉCTRICOS DE USO DOMÉSTICO,  MUEBLES, EQUIPO DE ILUMINACIÓN Y OTROS ENSERES DOMÉSTICOS EN COMERCIOS ESPECIALIZADOS",
  "4761":
    "4761 - VENTA AL POR MENOR DE LIBROS, PERIÓDICOS Y ARTÍCULOS DE PAPELERÍA EN COMERCIOS ESPECIALIZADOS",
  "4762":
    "4762 - VENTA AL POR MENOR DE GRABACIONES DE MÚSICA Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS",
  "4763":
    "4763 - VENTA AL POR MENOR DE EQUIPO DE DEPORTE EN COMERCIOS ESPECIALIZADOS",
  "4764":
    "4764 - VENTA AL POR MENOR DE JUEGOS Y  JUGUETES EN COMERCIOS ESPECIALIZADOS",
  "4771":
    "4771 - VENTA AL POR MENOR DE PRENDAS DE VESTIR, CALZADO Y ARTÍCULOS DE CUERO EN COMERCIOS ESPECIALIZADOS",
  "4772":
    "4772 - VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN COMERCIOS ESPECIALIZADOS   ",
  "4773":
    "4773 - VENTA AL POR MENOR DE OTROS PRODUCTOS NUEVOS EN COMERCIOS ESPECIALIZADOS",
  "4774":
    "4774 - COMERCIO AL POR MENOR DE OTROS PRODUCTOS NUEVOS EN ESTABLECIMIENTOS ESPECIALIZADOS",
  "4775": "4775 - VENTA AL POR MENOR DE ARTÍCULOS DE SEGUNDA MANO",
  "4781":
    "4781 - VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS ",
  "4782":
    "4782 - VENTA AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y MERCADOS",
  "4789":
    "4789 - VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS",
  "4791": "4791 - VENTA AL POR MENOR POR CORREO Y POR INTERNET",
  "4792":
    "4792 - COMERCIO AL POR MENOR REALIZADO A TRAVÉS DE CASAS DE VENTA O POR CORREO",
  "4799":
    "4799 - OTRAS ACTIVIDADES DE VENTA AL POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS",
  "4812": "4812 - SERVICIO TELEFONIA Y EQUIPOS",
  "4900":
    "4900 - SERVICIOS PUBLICOS (LUZ, AGUA, TELEFONO, ALCANTARILLADO, GAS)",
  "4911": "4911 - TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL",
  "4912": "4912 - TRANSPORTE DE CARGA POR FERROCARRIL",
  "4921":
    "4921 - TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR VÍA TERRESTRE ",
  "4922": "4922 - OTRAS ACTIVIDADES DE TRANSPORTE POR VÍA TERRESTRE",
  "4923": "4923 - TRANSPORTE DE CARGA POR CARRETERA",
  "4930": "4930 - TRANSPORTE POR TUBERÍAS",
  "5011": "5011 - TRANSPORTE DE PASAJEROS MARÍTIMO Y DE CABOTAJE",
  "5012": "5012 - TRANSPORTE DE CARGA MARÍTIMO Y DE CABOTAJE",
  "5021": "5021 - TRANSPORTE DE PASAJEROS POR VÍAS DE NAVEGACIÓN INTERIORES",
  "5022": "5022 - TRANSPORTE DE CARGA, POR VÍAS DE NAVEGACIÓN INTERIORES",
  "5110": "5110 - TRANSPORTE DE PASAJEROS POR VÍA AÉREA",
  "5120": "5120 - TRANSPORTE DE CARGA POR VÍA AÉREA",
  "5210": "5210 - ALMACENAMIENTO Y DEPÓSITO",
  "5221": "5221 - ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE",
  "5222": "5222 - ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACUÁTICO",
  "5223": "5223 - ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE AÉREO",
  "5224": "5224 - MANIPULACIÓN DE CARGA",
  "5229": "5229 - OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE",
  "5310": "5310 - ACTIVIDADES POSTALES",
  "5311": "5311 - TIENDAS DEPARTAMENTALES",
  "5320": "5320 - ACTIVIDADES DE MENSAJERÍA",
  "5510": "5510 - ACTIVIDADES DE ALOJAMIENTO PARA ESTANCIAS CORTAS",
  "5511": "5511 - ALOJAMIENTO EN HOTELES",
  "5512": "5512 - ALOJAMIENTO EN APARTAHOTELES",
  "5519": "5519 - OTROS TIPOS DE ALOJAMIENTOS PARA VISITANTES",
  "5520":
    "5520 - ACTIVIDADES DE CAMPAMENTOS, PARQUES DE VEHÍCULOS RECREATIVOS Y PARQUES DE CARAVANAS",
  "5530": "5530 - SERVICIO DE ESTANCIA POR HORAS",
  "5590": "5590 - OTRAS ACTIVIDADES DE ALOJAMIENTO",
  "5610": "5610 - ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MÓVIL DE COMIDAS",
  "5619": "5619 - OTROS TIPOS DE EXPENDIO DE COMIDAS PREPARADAS N.C.P.",
  "5621": "5621 - SUMINISTRO DE COMIDAS POR ENCARGO",
  "5629": "5629 - OTRAS ACTIVIDADES DE SERVICIO DE COMIDAS",
  "5630": "5630 - ACTIVIDADES DE SERVICIO DE BEBIDAS",
  "5811": "5811 - EDICIÓN DE LIBROS",
  "5812": "5812 - EDICIÓN DE DIRECTORIOS Y LISTAS DE CORREO",
  "5813":
    "5813 - EDICIÓN DE PERIÓDICOS, REVISTAS Y OTRAS PUBLICACIONES PERIÓDICAS",
  "5819": "5819 - OTRAS ACTIVIDADES DE EDICIÓN",
  "5820": "5820 - EDICIÓN DE PROGRAMAS DE INFORMÁTICOS",
  "5911":
    "5911 - ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN",
  "5912":
    "5912 - ACTIVIDADES DE POSTPRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN",
  "5913":
    "5913 - ACTIVIDADES DE DISTRIBUCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN",
  "5914":
    "5914 - ACTIVIDADES DE EXHIBICIÓN DE PELÍCULAS CINEMATOGRÁFICAS Y CINTAS DE VÍDEO ",
  "5920": "5920 - ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA",
  "5964": "5964 - VENTA POR CATALOGO (BOTON DE PAGOS)",
  "6010": "6010 - TRANSMISIONES DE RADIO",
  "6110": "6110 - ACTIVIDADES DE TELECOMUNICACIONES ALÁMBRICAS",
  "6120": "6120 - ACTIVIDADES DE TELECOMUNICACIONES INALÁMBRICAS",
  "6130": "6130 - ACTIVIDADES DE TELECOMUNICACIONES POR SATÉLITE.",
  "6190": "6190 - OTRAS ACTIVIDADES DE TELECOMUNICACIÓN.",
  "6201": "6201 - PROGRAMACIÓN INFORMÁTICA",
  "6202":
    "6202 - CONSULTORÍA DE INFORMÁTICA Y DE GESTIÓN DE INSTALACIONES INFORMÁTICAS",
  "6209":
    "6209 - OTRAS ACTIVIDADES DE TECNOLOGÍA DE LA INFORMACIÓN Y DE SERVICIOS INFORMÁTICOS",
  "6300": "6300 - VENTA DE SEGUROS",
  "6311": "6311 - PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS",
  "6312": "6312 - PORTALES WEB",
  "6391": "6391 - ACTIVIDADES DE AGENCIAS DE NOTICIAS",
  "6399": "6399 - OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACIÓN N.C.P.",
  "6411": "6411 - BANCA CENTRAL",
  "6419": "6419 - OTROS TIPOS DE INTERMEDIACIÓN MONETARIA.",
  "6420": "6420 - ACTIVIDADES DE SOCIEDADES DE CARTERA",
  "6430":
    "6430 - FONDOS Y SOCIEDADES DE INVERSIÓN Y ENTIDADES FINANCIERAS SIMILARES",
  "6431": "6431 - FIDEICOMISOS, FONDOS Y ENTIDADES FINANCIERAS SIMILARES",
  "6491": "6491 - ARRENDAMIENTO FINANCIERO",
  "6492": "6492 - OTRAS ACTIVIDADES DE CONCESIÓN DE CRÉDITO",
  "6494": "6494 - OTRAS ACTIVIDADES DE DISTRIBUCIÓN DE FONDOS",
  "6499":
    "6499 - OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES, N.C.P.",
  "6511": "6511 - SEGUROS DE VIDA",
  "6512": "6512 - SEGUROS GENERALES",
  "6520": "6520 - REASEGUROS",
  "6530": "6530 - FONDOS DE PENSIONES",
  "6611": "6611 - ADMINISTRACIÓN DE MERCADOS FINANCIEROS",
  "6612": "6612 - CORRETAJE DE VALORES Y DE CONTRATOS DE PRODUCTOS BÁSICOS",
  "6614":
    "6614 - ACTIVIDADES DE LAS SOCIEDADES DE INTERMEDIACIÓN CAMBIARIA Y DE SERVICIOS FINANCIEROS ESPECIALES",
  "6615":
    "6615 - ACTIVIDADES DE LOS PROFESIONALES DE COMPRA Y VENTA DE DIVISAS",
  "6619":
    "6619 - OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS",
  "6621": "6621 - EVALUACIÓN DE RIESGOS Y DAÑOS",
  "6622": "6622 - ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS",
  "6629":
    "6629 - OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES",
  "6630": "6630 - ACTIVIDADES DE GESTIÓN DE FONDOS",
  "6810":
    "6810 - ACTIVIDADES INMOBILIARIAS REALIZADAS CON BIENES PROPIOS O ARRENDADOS",
  "6820":
    "6820 - ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA",
  "6910": "6910 - ACTIVIDADES JURÍDICAS",
  "6920":
    "6920 - ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORÍA; CONSULTORÍA FISCAL",
  "7010": "7010 - ACTIVIDADES DE OFICINAS CENTRALES",
  "7020": "7020 - ACTIVIDADES DE CONSULTORÍA DE GESTIÓN",
  "7110":
    "7110 - ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA",
  "7120": "7120 - ENSAYOS Y ANÁLISIS TÉCNICOS",
  "7210":
    "7210 - INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA",
  "7220":
    "7220 - INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES",
  "7298": "7298 - GIMNASIOS - CLUB DE SALUD Y ADELGAZAMIENTO",
  "7310": "7310 - PUBLICIDAD",
  "7320": "7320 - ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINIÓN PÚBLICA",
  "7410": "7410 - ACTIVIDADES ESPECIALIZADAS DE DISEÑO",
  "7420": "7420 - ACTIVIDADES DE FOTOGRAFÍA",
  "7490":
    "7490 - OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.P.",
  "7500": "7500 - ACTIVIDADES VETERINARIAS",
  "7710": "7710 - ALQUILER Y ARRENDAMIENTO DE VEHÍCULOS AUTOMOTORES",
  "7721": "7721 - ALQUILER Y ARRENDAMIENTO DE EQUIPO RECREATIVO Y DEPORTIVO",
  "7722": "7722 - ALQUILER DE CINTAS DE VÍDEO Y DISCOS",
  "7729":
    "7729 - ALQUILER Y ARRENDAMIENTO DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS ",
  "7730":
    "7730 - ALQUILER Y ARRENDAMIENTO DE OTROS TIPOS DE MAQUINARIA, EQUIPO Y BIENES TANGIBLES",
  "7740":
    "7740 - ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y PRODUCTOS SIMILARES, EXCEPTO OBRAS PROTEGIDAS  POR DERECHOS DE AUTOR",
  "7810": "7810 - ACTIVIDADES DE AGENCIAS DE EMPLEO",
  "7820": "7820 - ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL",
  "7830": "7830 - OTRAS ACTIVIDADES DE DOTACIÓN DE RECURSOS HUMANOS",
  "7911": "7911 - ACTIVIDADES DE AGENCIAS DE VIAJES",
  "7912": "7912 - ACTIVIDADES DE OPERADORES TURÍSTICOS",
  "7990": "7990 - OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS",
  "8010": "8010 - ACTIVIDADES DE SEGURIDAD PRIVADA",
  "8020": "8020 - ACTIVIDADES DE SERVICIO DE SISTEMAS DE SEGURIDAD",
  "8030": "8030 - ACTIVIDADES DE INVESTIGACIÓN",
  "8110": "8110 - ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES",
  "8121": "8121 - LIMPIEZA GENERAL DE EDIFICIOS",
  "8129":
    "8129 - OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES",
  "8130":
    "8130 - ACTIVIDADES DE PAISAJISMO Y SERVICIOS DE MANTENIMIENTO CONEXOS",
  "8211":
    "8211 - ACTIVIDADES COMBINADAS DE SERVICIOS ADMINISTRATIVOS DE OFICINA",
  "8219":
    "8219 - FOTOCOPIADO, PREPARACIÓN DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE APOYO DE OFICINA",
  "8220": "8220 - ACTIVIDADES DE CENTROS DE LLAMADAS",
  "8230": "8230 - ORGANIZACIÓN DE CONVENCIONES Y EXPOSICIONES COMERCIALES",
  "8291":
    "8291 - ACTIVIDADES DE AGENCIAS DE COBRO Y AGENCIAS DE CALIFICACIÓN CREDITICIA",
  "8292": "8292 - ACTIVIDADES DE ENVASADO Y EMPAQUETADO",
  "8299": "8299 - OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P",
  "8411": "8411 - ACTIVIDADES DE LA ADMINISTRACIÓN PÚBLICA EN GENERAL",
  "8412":
    "8412 - REGULACIÓN DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES Y OTROS SERVICIOS SOCIALES, EXCEPTO SERVICIOS DE SEGURIDAD SOCIAL",
  "8413": "8413 - REGULACIÓN Y FACILITACIÓN DE LA ACTIVIDAD ECONÓMICA",
  "8414":
    "8414 - ACTIVIDADES REGULADORAS Y FACILITADORAS DE LA ACTIVIDAD ECONÓMICA",
  "8421": "8421 - RELACIONES EXTERIORES",
  "8422": "8422 - ACTIVIDADES DE DEFENSA",
  "8423":
    "8423 - ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PÚBLICO Y DE SEGURIDAD",
  "8430":
    "8430 - ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
  "8510": "8510 - ENSEÑANZA PREESCOLAR Y PRIMARIA",
  "8521": "8521 - ENSEÑANZA SECUNDARIA DE FORMACIÓN GENERAL",
  "8522": "8522 - ENSEÑANZA SECUNDARIA DE FORMACIÓN TÉCNICA Y PROFESIONAL",
  "8530": "8530 - ENSEÑANZA SUPERIOR",
  "8541": "8541 - EDUCACIÓN DEPORTIVA Y RECREATIVA",
  "8542": "8542 - ENSEÑANZA CULTURAL",
  "8543": "8543 - ENSEÑANZA SUPERIOR EN UNIVERSIDADES PRIVADAS",
  "8544": "8544 - EDUCACIÓN DE UNIVERSIDADES10",
  "8549": "8549 - OTROS TIPOS DE ENSEÑANZA N.C.P.",
  "8550": "8550 - ACTIVIDADES DE APOYO A LA ENSEÑANZA",
  "8551": "8551 - FORMACIÓN PARA EL TRABAJO",
  "8552": "8552 - ENSEÑANZA DEPORTIVA Y RECREATIVA",
  "8559": "8559 - OTROS TIPOS DE EDUCACIÓN N.C.P.",
  "8610": "8610 - ACTIVIDADES DE HOSPITALES",
  "8620": "8620 - ACTIVIDADES DE MÉDICOS Y ODONTÓLOGOS",
  "8621": "8621 - ACTIVIDADES DE LA PRÁCTICA MÉDICA, SIN INTERNACIÓN",
  "8690": "8690 - OTRAS ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA",
  "8692": "8692 - ACTIVIDADES DE APOYO TERAPÉUTICO",
  "8699": "8699 - OTRAS ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA",
  "8710": "8710 - ACTIVIDADES DE ATENCIÓN DE ENFERMERÍA EN INSTITUCIONES",
  "8720":
    "8720 - ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS CON RETRASO MENTAL, ENFERMOS MENTALES Y TOXICÓMANOS",
  "8730":
    "8730 - ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS DE EDAD PERSONAS CON DISCAPACIDAD",
  "8790": "8790 - OTRAS ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES",
  "8810":
    "8810 - ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD",
  "8890": "8890 - OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO",
  "8999": "8999 - SERVICIOS PROFESIONALES",
  "9000": "9000 - ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO",
  "9101": "9101 - ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS",
  "9102":
    "9102 - ACTIVIDADES DE MUSEOS Y GESTIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS",
  "9103":
    "9103 - ACTIVIDADES DE JARDINES BOTÁNICOS Y ZOOLÓGICOS Y RESERVAS NATURALES",
  "9200": "9200 - ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS",
  "9311": "9311 - GESTIÓN DE INSTALACIONES DEPORTIVAS",
  "9312": "9312 - ACTIVIDADES DE CLUBES DEPORTIVOS",
  "9319": "9319 - OTRAS ACTIVIDADES DEPORTIVAS",
  "9321": "9321 - ACTIVIDADES DE PARQUES DE ATRACCIONES Y PARQUES TEMÁTICOS",
  "9329": "9329 -  Otras actividades recreativas y de esparcimiento n.c.p.",
  "9411": "9411 - ACTIVIDADES DE ASOCIACIONES EMPRESARIALES Y DE EMPLEADORES",
  "9412": "9412 - ACTIVIDADES DE ASOCIACIONES PROFESIONALES",
  "9420": "9420 - ACTIVIDADES DE SINDICATOS",
  "9491": "9491 - ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS",
  "9492": "9492 - ACTIVIDADES DE ORGANIZACIONES POLÍTICAS",
  "9499": "9499 - ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P.",
  "9511": "9511 - REPARACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO",
  "9512": "9512 - REPARACIÓN DE EQUIPOS COMUNICACIONALES",
  "9521": "9521 - REPARACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO",
  "9522":
    "9522 - REPARACIÓN DE APARATOS DE USO DOMÉSTICO Y EQUIPO DOMÉSTICO Y DE JARDINERÍA",
  "9523": "9523 - REPARACIÓN DE CALZADO Y ARTÍCULOS DE CUERO",
  "9524": "9524 - REPARACIÓN DE MUEBLES Y ACCESORIOS DOMÉSTICOS",
  "9529": "9529 - REPARACIÓN DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS",
  "9601":
    "9601 - LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL",
  "9602": "9602 - PELUQUERÍA Y OTROS TRATAMIENTOS DE BELLEZA",
  "9603": "9603 - POMPAS FÚNEBRES Y ACTIVIDADES CONEXAS",
  "9609": "9609 - OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P.",
  "9700":
    "9700 - ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO",
  "9900": "9900 - ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES",
  "0111":
    "0111 - CULTIVO DE CEREALES (EXCEPTO ARROZ), LEGUMBRES Y SEMILLAS OLEAGINOSAS",
  "0112": "0112 - CULTIVO DE ARROZ",
  "0113": "0113 - CULTIVO DE HORTALIZAS Y MELONES, RAÍCES Y TUBÉRCULOS.",
  "0114": "0114 - CULTIVO DE CAÑA DE AZÚCAR",
  "0115": "0115 - CULTIVO DE TABACO",
  "0116": "0116 - CULTIVO DE PLANTAS DE FIBRA",
  "0119": "0119 - CULTIVO DE OTRAS PLANTAS NO PERENNES",
  "0121": "0121 - CULTIVO DE UVA",
  "0122": "0122 - CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES",
  "0123": "0123 - CULTIVO DE  CÍTRICOS",
  "0124": "0124 - CULTIVO DE FRUTAS DE PEPITA Y DE HUESO",
  "0125": "0125 - CULTIVO DE OTROS FRUTOS Y NUECES DE ÁRBOLES Y ARBUSTOS ",
  "0126": "0126 - CULTIVO DE FRUTOS OLEAGINOSOS",
  "0127": "0127 - CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS",
  "0128":
    "0128 - CULTIVO DE ESPECIAS Y DE PLANTAS AROMÁTICAS, MEDICINALES Y FARMACEUTICAS",
  "0129": "0129 - CULTIVO DE OTRAS PLANTAS NO PERENNES",
  "0130": "0130 - PROPAGACIÓN DE PLANTAS",
  "0141": "0141 - CRÍA DE GANADO BOVINO Y BÚFALOS",
  "0142": "0142 - CRÍA DE CABALLOS Y OTROS EQUINOS",
  "0143": "0143 - CRÍA DE CAMELLOS Y OTROS CAMÉLIDOS",
  "0144": "0144 - CRÍA DE OVEJAS Y CABRAS",
  "0145": "0145 - CRÍA DE CERDOS",
  "0146": "0146 - CRÍA DE AVES DE CORRAL",
  "0149": "0149 - CRÍA DE OTROS ANIMALES",
  "0150":
    "0150 - CULTIVO DE PRODUCTOS AGRÍCOLAS EN COMBINACIÓN CON LA CRÍA DE ANIMALES",
  "0161": "0161 - ACTIVIDADES DE APOYO A LA AGRICULTURA",
  "0162": "0162 - ACTIVIDADES DE APOYO A LA GANADERÍA",
  "0163": "0163 - ACTIVIDADES POSCOSECHA",
  "0164": "0164 - TRATAMIENTO DE SEMILLAS PARA PROPAGACIÓN",
  "0170":
    "0170 - CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS",
  "0210": "0210 - SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES",
  "0220": "0220 - EXTRACCIÓN DE MADERA",
  "0230": "0230 - RECOLECCIÓN DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA",
  "0240": "0240 - SERVICIOS DE APOYO A LA SILVICULTURA",
  "0311": "0311 - PESCA MARÍTIMA",
  "0312": "0312 - PESCA DE AGUA DULCE",
  "0321": "0321 - ACUICULTURA MARÍTIMA",
  "0322": "0322 - ACUICULTURA DE AGUA DULCE",
  "0510": "0510 - EXTRACCIÓN DE CARBÓN DE PIEDRA",
  "0520": "0520 - EXTRACCIÓN DE LIGNITO",
  "0610": "0610 - EXTRACCIÓN DE PETRÓLEO CRUDO",
  "0620": "0620 - EXTRACCIÓN DE GAS NATURAL",
  "0710": "0710 - EXTRACCIÓN DE MINERALES DE HIERRO",
  "0721": "0721 - EXTRACCIÓN DE MINERALES DE URANIO Y TORIO",
  "0729": "0729 - EXTRACCIÓN DE OTROS MINERALES METALÍFEROS NO FERROSOS",
  "0810": "0810 - EXTRACCIÓN DE PIEDRA, ARENA Y ARCILLA",
  "0891":
    "0891 - EXTRACCIÓN DE MINERALES PARA LA FABRICACIÓN DE ABONOS Y PRODUCTOS QUÍMICOS",
  "0892": "0892 - EXTRACCIÓN DE TURBA",
  "0893": "0893 - EXTRACCIÓN DE SAL",
  "0899": "0899 - EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS N.C.P.",
  "0910":
    "0910 - ACTIVIDADES DE APOYO PARA LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL",
  "0990":
    "0990 - ACTIVIDADES DE APOYO PARA OTRAS ACTIVIDADES DE EXPLOTACIÓN DE MINAS Y CANTERAS",
  "0722 ": "0722  - EXTRACCIÓN DE ORO Y OTROS METALES PRECIOSOS",
  "0723 ": "0723  - EXTRACCIÓN DE MINERALES DE NÍQUEL",
  "0811 ":
    "0811  - EXTRACCIÓN DE PIEDRA, ARENA, ARCILLAS COMUNES, YESO Y ANHIDRITA",
};
