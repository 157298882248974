import React, { FC, useEffect } from "react";
import { IUseSecurityRulesTabsState } from "../../../shared/infrastructure/interfaces/useSecurityRulesTabsState.interface";
import { getCountries } from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { defaultTo } from "lodash";
import { setSelectedTabIndex } from "../../../store/actionCreators";
import { TabIndexEnum } from "../../../shared/infrastructure/enums/TabIndexEnum";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { GlobalTableComponent } from "../../GlobalTableComponent/GlobalTableComponent";

export const useSecurityRulesTabsState = (): IUseSecurityRulesTabsState => {
  const indexTab: number = useSelector((state: IAppState) =>
    defaultTo(state.selectedTabIndex, TabIndexEnum.PENDING_REVIEW_INDEX)
  );
  const dispatch = useDispatch();

  const handleTabsChange = (newIndex: number): void => {
    dispatch(setSelectedTabIndex(newIndex));
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const renderPendingReview = () => (
    <GlobalTableComponent tabName={LabelEnum.PENDING_REVIEW} />
  );
  const renderFirstLevelRules = () => (
    <GlobalTableComponent tabName={LabelEnum.FIRST_LEVEL_RULES} />
  );
  const renderWhiteLists = () => (
    <GlobalTableComponent tabName={LabelEnum.WHITE_LISTS} />
  );
  const renderRejectedApplications = () => (
    <GlobalTableComponent tabName={LabelEnum.REJECTED_APPLICATIONS} />
  );

  const componentByTab: Record<number, FC> = {
    0: renderPendingReview,
    1: renderFirstLevelRules,
    2: renderWhiteLists,
    3: renderRejectedApplications,
  };

  return {
    indexTab,
    handleTabsChange,
    componentByTab,
  };
};
