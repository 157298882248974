import { makeStyles } from "@material-ui/core/styles";

export const useStylesEmptyTab = makeStyles({
  imageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: 1,
    m: 1,
    background: "#F7FAFC",
    height: 300,
  },
  textBox: {
    height: 400,
    textAlign: "center",
  },
  paperBackground: {
    background: "#F7FAFC",
  },
  blueImageTitle: {
    color: "#023365 !important",
  },
  textTitleImage: {
    "& h6": {
      fontWeight: "bold",
    },
  },
  textBoxTitles: {
    "& h5": {
      fontWeight: 100,
      fontSize: 23,
      lineHeight: 3,
    },
    "& h6": {
      fontWeight: 400,
      fontSize: 18,
      color: "gray",
    },
  },
});
