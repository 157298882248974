import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import merchantNodeReducer from "./reducers/merchantNode/merchantNode.slice";
import chargebackReducer from "./reducers/chargeback/chargeback.slice";
import retrieveChildReducer from "./reducers/retrieveChild/retrieveChild.slice";
export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    chargeback: chargebackReducer,
    merchantNode: merchantNodeReducer,
    retriveChild: retrieveChildReducer,
  },
});
const { dispatch } = store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export { dispatch };
