import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";

enum CELL_TYPES {
  TEXT = "text",
  TAG = "tag",
}

type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

enum tableProperties {
  ACTIVE_PROCESSOR = "activeProcessor",
  MODIFY = "modify",
  ALIAS = "alias",
  PUBLIC_ID = "publicId",
  PAYMENTH_METHOD = "paymentMethod",
  MODEL = "model",
  STATUS = "status",
}

export const ALL_TABLE_COLUMNS: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.ACTIVE_PROCESSOR,
    spacing: 0,
    text: "",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.MODIFY,
    spacing: 0,
    text: "Modificación",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.ALIAS,
    spacing: 0,
    text: "Alias",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.PUBLIC_ID,
    spacing: 0,
    text: "Public ID",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.PAYMENTH_METHOD,
    spacing: 0,
    text: "Medio de pago",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.MODEL,
    spacing: 0,
    text: "Modelo",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: tableProperties.STATUS,
    spacing: 0,
    text: "Estado",
  },
];
