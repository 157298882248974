import React, { FC } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { selectedTransactionsTableStyles } from "./SelectedTransactionsTable.styles";
import TransactionTableItem from "../common/TransactionTableItem/TransactionTableItem";
import TransactionsTableHead from "../common/TransactionsTableHead/TransactionsTableHead";
import { useSelectedTransactionState } from "./state/useSelectedTransactionState";
import DownloadButton from "../common/DownloadButton/DownloadButton";
import { ARRAY_OPTIONS_DOWNLOAD } from "../../shared/constants/options_download";
import useDownloadTransactions from "../../hooks/useDownloadTransactions/useDownloadTransactions";

const SelectedTransactionsTable: FC = () => {
  const {
    handleclearSelectedTable,
    handleOrderSelectedTable,
    selectedTransactions,
    selectedTransactionsIDs,
  } = useSelectedTransactionState();

  const {
    actions: { handleDownloadSelectedTransactions },
  } = useDownloadTransactions();

  return (
    <TableContainer sx={selectedTransactionsTableStyles.container}>
      <Table>
        <TransactionsTableHead
          areAllItemsSelected={true}
          onClickSortDate={handleOrderSelectedTable}
          onChangeSelection={handleclearSelectedTable}
        />
        <TableBody>
          {selectedTransactions.map((transaction) => (
            <TransactionTableItem
              key={transaction.id}
              transaction={transaction}
              isSelected={selectedTransactionsIDs.includes(transaction.id)}
            />
          ))}
        </TableBody>
      </Table>
      <Grid
        container
        sx={selectedTransactionsTableStyles.containerCountTransactions}
      >
        <Typography
          variant="overline"
          color="text.grey"
        >{`Transacciones seleccionadas: ${selectedTransactions.length} `}</Typography>
        <DownloadButton
          options={ARRAY_OPTIONS_DOWNLOAD}
          handleClick={handleDownloadSelectedTransactions}
        />
      </Grid>
    </TableContainer>
  );
};

export default SelectedTransactionsTable;
