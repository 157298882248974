import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { SkeletonTable } from "../../Skeletons/SkeletonTable";
import { IColumnsTable } from "../../../shared/infrastructure/Table/ColumnsTable";
import { defaultTo } from "lodash";
import { EmptyList } from "../../EmptyList/EmptyList";
import { get } from "lodash";
import { PaginationTableV2 } from "../../Pagination/PaginationTableV2";
import { DataTableFilters } from "../../Filters/DataTableFilters";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { DashboardTableRow } from "./DashboardTableRow";
import { IDashboardTableProps } from "./DashboardTable.interfaces";
import { StyledTableCell, useStyles } from "./DashboardTable.styles";

export const DashboardTable: React.FC<IDashboardTableProps> = (
  props: IDashboardTableProps
) => {
  const classes = useStyles();
  const titleTextModalDelete =
    "¿Deseas eliminar la configuración de impuestos y retenciones actual de la fila seleccionada?";
  const {
    modalDeleteProps: {
      openDeleteModal,
      handleAcceptModal,
      handleOpenModal,
      handleCloseModal,
      isLoadingDelete,
    },
  } = props;
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        {props.isLoading ? (
          <SkeletonTable />
        ) : (
          <React.Fragment>
            {props.isFilterProps ? (
              <DataTableFilters
                {...props.filterProps}
                country={props.country as CountryValueEnum}
                handleSetFiltersInput={props.handleSetFiltersInput}
              />
            ) : null}
            {get(props, "retentionData.length", 0) > 0 ? (
              <React.Fragment>
                <Table>
                  <TableHead>
                    <TableRow>
                      {props.tableColumns.map((column: IColumnsTable) => (
                        <StyledTableCell key={column.id}>
                          <Typography
                            variant={"body2"}
                            className={classes.titlesHeader}
                          >
                            {column.label}
                          </Typography>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {defaultTo(props.retentionData, []).map(
                      (item: any, index) => (
                        <DashboardTableRow
                          onHandleEditAction={props.onHandleEditAction}
                          handleOpenDeleteModal={handleOpenModal}
                          renderColumns={props.tableColumns}
                          retentionData={item}
                          key={item.id}
                          index={index}
                          country={props.country as CountryValueEnum}
                          securityAzure={get(props, "securityAzure")}
                        />
                      )
                    )}
                  </TableBody>
                </Table>
              </React.Fragment>
            ) : (
              <EmptyList retentionType={get(props, "retentionType", 1)} />
            )}
            <ConfirmModal
              open={openDeleteModal}
              handleClose={handleCloseModal}
              titleText={titleTextModalDelete}
              handleAccept={handleAcceptModal}
              acceptText={"Eliminar"}
              isLoadingRequest={isLoadingDelete}
              contentText={
                <Typography variant="body2" color="textSecondary">
                  Se eliminará definitivamente. Este paso no se puede revertir.
                </Typography>
              }
            />
            {props.isPaginatorProps &&
            get(props, "retentionData.length", 0) > 0 ? (
              <React.Fragment>
                <PaginationTableV2
                  total={get(props, "pagination.pageTotal", 0)}
                  limit={get(props, "pagination.pageSize", 0)}
                  page={get(props, "pagination.page", 0)}
                  maxPage={get(props, "pagination.maxPage", 0)}
                  isMobile={false}
                  trxPerPage={get(props, "retentionData.length", 0)}
                  size={"small"}
                  handleChangePage={get(
                    props,
                    "pagination.handleChangePage",
                    0
                  )}
                  handleChangePageSize={get(
                    props,
                    "pagination.handleChangePageSize",
                    0
                  )}
                  siblingCount={1}
                />
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </TableContainer>
    </React.Fragment>
  );
};
