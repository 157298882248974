import { useSearchParams } from "react-router-dom";
import { IUseMainContainer } from "./useMainContainer.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getMerchantNodeInfo } from "../../../store/thunks/app/app.thunks";
import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { ConfigIdEnum } from "../../../shared/enums/config_id_nodes";
import { ISubheaderProps } from "../../../components/Subheader/Subheader.interfaces";
import {
  buildSubheaderInfo,
  defaultSubheaderValues,
  MERCHANT_QUERY_PARAM,
} from "../../../shared/constants/main_container_constants";
import { getLocalStorageValueByPath } from "../../../shared/utils/localStorageUtils";
import { LocalStoragePath } from "../../../shared/enums/local_storage_path";

export const useMainContainer = (): IUseMainContainer => {
  const [searchParams] = useSearchParams();
  const { merchantNodeInfo } = useAppSelector(({ app }) => ({
    ...app,
  }));
  const dispatch = useAppDispatch();
  const [country, setCountry] = useState<string>("");
  const publicMerchantId = searchParams.get(MERCHANT_QUERY_PARAM) || "";
  const [subheaderInfo, setSubheaderInfo] = useState<ISubheaderProps>(
    defaultSubheaderValues
  );

  const requestMerchantNodeInfo = (publicMerchantId: string) => {
    dispatch(
      getMerchantNodeInfo({
        configId: ConfigIdEnum.CN001,
        publicMerchantId,
      })
    );
  };

  const redirectPath = () => {
    const basicMerchantInfo: object = getLocalStorageValueByPath(
      LocalStoragePath.BASIC_MERCHANT_INFORMATION
    );

    window.location.href = get(basicMerchantInfo, "layout.lastRoute", "/");
  };

  useEffect(() => {
    if (isEmpty(publicMerchantId)) return;

    requestMerchantNodeInfo(publicMerchantId!);
  }, [publicMerchantId]);

  useEffect(() => {
    if (isEmpty(merchantNodeInfo)) return;

    setSubheaderInfo(buildSubheaderInfo(merchantNodeInfo!));
    setCountry(merchantNodeInfo!.country);
  }, [merchantNodeInfo]);

  return {
    country,
    publicMerchantId,
    redirectPath,
    subheaderInfo,
  };
};
