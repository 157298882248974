import React from "react";
import { Box, Container, Grid } from "@mui/material";
import {
  breadcrumbRuleCreationValues,
  SubTitleSection,
  TitleSection,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { CardSection } from "../../components/CardSection/CardSection";
import { CreateRuleSection } from "../../components/CreateRuleSection/CreateRuleSection";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { Routes } from "../../shared/infrastructure/routes";
import { useHistory } from "react-router";
import { CustomBreadcrumb } from "../../components/common/CustomBreadcrumb/CustomBreadcrumb";
import { FormProvider, useForm } from "react-hook-form";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  ServiceEnum,
  SubTypeEnum,
} from "../../shared/infrastructure/enums/CreateRuleConstans";
import { NewRuleSection } from "../../components/NewRuleSection/NewRuleSection";
import { useCreateRuleIndexState } from "./state/useCreateRuleIndexState";
import { AssignRuleSection } from "../../components/AssignRule/AssignRule";
import { ModalCatalogGeneralRules } from "../ModalCatalogGeneralRules/ModalCatalogGeneralRules";
import { get, isEmpty } from "lodash";
import { useCreateRuleState } from "../SecurityRuleDetail/state/useCreateRuleState";
import { ConfirmModalEnum } from "../../shared/infrastructure/enums/ConfirmModalEnum";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";

export const CreateRulesIndex: React.FC = () => {
  const history = useHistory();
  const form = useForm<ISecurityRuleForm>({
    mode: "all",
    defaultValues: {
      subType: SubTypeEnum.COMMERCE,
      service: ServiceEnum.TRANSFER,
    },
    shouldUnregister: false,
  });

  const { isSelectedRule, handle, selectedRule, fields } =
    useCreateRuleIndexState();
  const {
    branchValue,
    customerValue,
    isLoading,
    handle: { handleCancelModal },
  } = useCreateRuleState();

  return (
    <React.Fragment>
      <Container>
        <Box pb={2}>
          <CustomBreadcrumb
            title={TitleSection.RULES_CREATE}
            items={breadcrumbRuleCreationValues}
            splitButton={undefined}
            type={"titleBase"}
          />
        </Box>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <CardSection
              sectionComponent={<CreateRuleSection />}
              sectionTitle={TitleSection.RULES_DATA_GENERAL}
              sectionDescription={SubTitleSection.RULES_CREATE_SUBTITLE}
              isSelectedRule={isSelectedRule}
            />
            {!isSelectedRule ? (
              <CardSection
                sectionComponent={
                  <AssignRuleSection
                    redirectCreateRule={() =>
                      history.push(Routes.CREATE_RULE_DETAIL)
                    }
                  />
                }
                sectionTitle={TitleSection.RULES_ASSIGN}
                sectionDescription={SubTitleSection.RULES_ASSIGN_SUBTITLE}
              />
            ) : (
              <CardSection
                sectionComponent={
                  <NewRuleSection
                    redirectEditRule={() =>
                      history.push(
                        Routes.EDIT_RULE_DETAIL.replace(
                          ":ruleName",
                          get(selectedRule, "name", "editRule").replace(
                            / /g,
                            ""
                          )
                        )
                      )
                    }
                    handleDeleteSelectedRule={handle.handleDeleteSelectedRule}
                  />
                }
                sectionTitle={TitleSection.NEW_RULE}
                sectionDescription={SubTitleSection.RULES_PARAMETERS}
              />
            )}
            <ActionButtons
              onCancel={handle.handleCancel}
              form={!isEmpty(selectedRule) ? form : undefined}
              fields={fields}
              handleSendToReview={handle.handleSendToReview}
            />
            <ConfirmModal
              modalTitle={ConfirmModalEnum.TITLE}
              modalContentText={ConfirmModalEnum.BODY}
              branch={branchValue}
              customer={customerValue}
              isFormEditMode={false}
              onClose={handleCancelModal}
              onSubmit={handle.handleSendToReview}
              isLoading={isLoading}
            />
          </Grid>
          <ModalCatalogGeneralRules />
        </FormProvider>
      </Container>
    </React.Fragment>
  );
};
