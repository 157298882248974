import React from "react";
import {
  Grid,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {
  AmountFormatEnum,
  HelperText,
  Labels,
  Operators,
  TextFieldsNames,
} from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import {
  Control,
  Controller,
  FieldErrors,
  useFormContext,
} from "react-hook-form";
import {
  ActionsInterface,
  useAmountConditionState,
} from "./useAmountConditionState/useAmountConditionState";
import { defaultTo, get, isEmpty } from "lodash";
import { NumericFormat } from "react-number-format";
import { ErrorsEnum } from "../../../../shared/infrastructure/enums/ErrorEnum";
import {
  getRawNumber,
  isFormEditMode,
  retrieveFormError,
} from "../../../../utils/utilsFile";
import { createStyles, makeStyles } from "@mui/styles";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../../shared/infrastructure/interfaces/IUseParams";

export interface TextFieldComponentProps {
  actions: ActionsInterface;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  errors: FieldErrors;
  control: Control;
  register: any;
  setValue: any;
  compareValue: string;
}

export interface AmountConditionProps {
  operator: string;
  index: number;
  setSelectedItems: (value: ICategory[]) => void;
  defaultValueList: string[];
  isDisable: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      paddingTop: 16,
    },
  })
);

export const TextFieldComponent: React.FC<{
  value: TextFieldComponentProps;
  defaultValueList: string[];
  isRequired: boolean;
  isDisable: boolean;
}> = (props: {
  value: TextFieldComponentProps;
  defaultValueList: string[];
  isRequired: boolean;
  isDisable: boolean;
}) => {
  const { ruleId, ruleName } = useParams<IUseParams>();
  const {
    placeholder,
    actions,
    value,
    id,
    errors,
    control,
    register,
    compareValue,
  } = props.value;
  const isEditionAndEmpty: boolean =
    isFormEditMode(ruleId || ruleName) && isEmpty(value);

  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        defaultValue={undefined}
        name={id}
        control={control}
        rules={{
          required: {
            message: ErrorsEnum.REQUIRED_FIELD,
            value: props.isRequired,
          },
          pattern: {
            message: ErrorsEnum.NOT_ALLOWED_VALUE,
            value: /^(\d|[.])+([,]\d{1,2})?$/,
          },
          validate: (val: string) => {
            const currentValue: string = getRawNumber(val);
            const valueToCompare: string = getRawNumber(compareValue);

            if (id === TextFieldsNames.FirstAmount)
              return actions.validateAmounts(currentValue, valueToCompare, id);
            return actions.validateAmounts(valueToCompare, currentValue, id);
          },
        }}
        render={({ ...customProps }) => (
          <NumericFormat
            {...customProps}
            key={id}
            id={id}
            name={id}
            value={
              isEditionAndEmpty && props.isDisable
                ? get(props, "defaultValueList[0]", "").replace(".", ",")
                : value
            }
            label={placeholder}
            variant="outlined"
            fullWidth
            inputRef={isEditionAndEmpty && register}
            error={!!get(errors, id)}
            helperText={retrieveFormError(errors, id)[1]}
            onValueChange={() => {
              actions.getOtherPlaceholder(value);
            }}
            disabled={props.isDisable}
            customInput={TextField}
            thousandSeparator={AmountFormatEnum.THOUSAND_SEPARATOR}
            decimalSeparator={AmountFormatEnum.DECIMAL_SEPARATOR}
            displayType={"input"}
          />
        )}
      />
      {value.length === 0 && (
        <FormHelperText>{HelperText.AMOUNT_HELPER}</FormHelperText>
      )}
    </FormControl>
  );
};

export const AmountConditionComponent: React.FC<AmountConditionProps> = (
  props: AmountConditionProps
) => {
  const classes = useStyles();
  const { operator, index } = props;
  const { watch, setValue, errors, control, register, clearErrors } =
    useFormContext();
  const name: string = `condition.${index}.value`;
  const defaultValues = get(props, "defaultValueList[0]", "")
    .toString()
    .split(",");
  const defaultFirstValue = get(defaultValues, "[0]", "");
  const defaultSecondValue =
    get(defaultValues, "[1]", "") || get(props, "defaultValueList[1]", "");

  const firstValue: string = defaultTo(
    watch(TextFieldsNames.FirstAmount, defaultFirstValue),
    ""
  );
  const secondValue: string = defaultTo(
    watch(TextFieldsNames.SecondAmount, defaultSecondValue),
    ""
  );
  const { firstPlaceHolder, secondPlaceHolder, actions } =
    useAmountConditionState({
      operator,
      firstValue,
      secondValue,
      setValue,
      name,
      clearErrors,
      setSelectedItems: props.setSelectedItems,
      defaultValueList: props.defaultValueList,
      isDisable: props.isDisable,
    });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>{Labels.AMOUNT_TEXT}</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextFieldComponent
            value={{
              actions,
              id: TextFieldsNames.FirstAmount,
              name,
              placeholder: firstPlaceHolder,
              value: firstValue,
              setValue,
              errors,
              control,
              register,
              compareValue: secondValue,
            }}
            defaultValueList={[defaultFirstValue]}
            isRequired={true}
            isDisable={props.isDisable}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.textField}>
        {operator === Operators.BETWEEN && (
          <Grid item xs={4}>
            <TextFieldComponent
              value={{
                actions,
                id: TextFieldsNames.SecondAmount,
                name,
                placeholder: secondPlaceHolder,
                value: secondValue,
                setValue,
                errors,
                control,
                register,
                compareValue: firstValue,
              }}
              defaultValueList={[defaultSecondValue]}
              isRequired={true}
              isDisable={props.isDisable}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
