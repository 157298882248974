import React, { ChangeEvent } from "react";
import {
  Box,
  Checkbox,
  Chip,
  createStyles,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TransactionTable } from "../../../../../../types/transaction_table";
import { defaultTo, get, includes } from "lodash";
import { translate } from "../../../../../shared/infrastructure/LanguageCatatog";
import { format } from "date-fns-tz";
import NumberFormat from "react-number-format";
import { RowElementEdit } from "../../../../RowElementEdit/RowElementEdit";
import { ConfirmModal } from "../../../../ConfirmModal/ConfirmModal";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IBillingDashboardState } from "../../../../../store/reducer";
import {
  BillingActions,
  setBillingDataRow,
  transactionIdFilter,
} from "../../../../../store/actionCreators";
import { connect } from "react-redux";
import { useDashboardTableRowState } from "./state/useDashboardTableRowState";
import { KindEnum } from "../../../../../shared/infrastructure/KindEnum";
import {
  RowStatusEnum,
  StatusEnum,
} from "../../../../../shared/infrastructure/StatusEnum";
import { CountryEnum } from "../../../../../shared/infrastructure/CountryEnum";
import { TransactionValidators } from "../../../../../shared/infrastructure/TransactionValidators";
import { StatusFlowEnum } from "../../../../../shared/infrastructure/StatusFlowEnum";
import { InvoiceModeEnum } from "../../../../../shared/infrastructure/InvoiceModeEnum";
import { themeOptions } from "../../../../../themeui";
import {
  ColumnsEnum,
  IColumnsTable,
} from "../../../../../shared/infrastructure/table/IColumnsTable";
import { OmitConfirmModal } from "../../../../ConfirmModal/OmitConfirmModal/OmitConfirmModal";
import {
  TransactionTypeChipLabelEnum,
  TransactionTypeLowerCaseEnum,
} from "../../../../../shared/infrastructure/BillingTransactionTypeEnum";
import { isOcbValidate } from "../../../../../shared/utils/utils";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    chipStyle: {
      backgroundColor: "#F7FAFC",
      color: themeOptions.palette.text.dark,
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "4px",
      width: "100px",
      height: "26px",
    },
    input: {
      margin: theme.spacing(1),
      "&.MuiFormControl-root": { marginLeft: 0 },
    },
    warningText: {
      marginBottom: 24,
    },
    rowRoot: {
      backgroundColor: (props: DashboardTableRowProps) =>
        getRowBackgroundColor(props),
    },
    toogle: {
      color: theme.palette.primary.main,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: "rgba(2,51,101,1) !important",
        },
      },
      "&:hover": {
        backgroundColor: "rgba(212,234,255,0.2) !important",
      },
      borderColor: "#023365",
      fontSize: "10px",
      lineHeight: "140%",
      fontWeight: 500,
      maxHeight: "30px",
      minWidth: "70px",
      fontStyle: "normal",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    check: {
      marginLeft: theme.spacing(1),
    },
    cycleDate: {
      color: `${themeOptions.palette.text.secondary}`,
    },
  })
);

export const getRowBackgroundColor = (props: DashboardTableRowProps) => {
  if (props.billingData?.modify?.value) return "rgba(252, 235, 193, 0.3)";
  else if (props.billingData?.status === StatusEnum.PROCESS) return "inherit";
  else if (
    props.billingData?.status === StatusEnum.PENDING &&
    props.billingData?.statusRow !== StatusEnum.REPROCESS
  )
    return "rgba(255, 223, 229, 0.3)";
  else if (
    props.billingData?.status === StatusEnum.OMIT ||
    props.billingData?.status === StatusEnum.REJECT
  )
    return "rgb(240, 244, 249, 1)";
  else return "";
};

export interface DashboardTableRowFunctionsProps {
  setBillingDataRow: (billingDataRow: TransactionTable) => void;
  transactionIdFilter: () => void;
}

export type CountryList =
  | CountryEnum.ecuador
  | CountryEnum.chile
  | CountryEnum.peru
  | CountryEnum.colombia
  | CountryEnum.mexico;

export interface DashboardTableRowStateProps {
  isComplementaryTable?: boolean;
  billingData: TransactionTable | undefined;
  country: CountryList;
  statusFlow: StatusFlowEnum;
  handleCheckboxChange: (
    rowValue: TransactionTable
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  index: number;

  handlerOpenCardRow(row: TransactionTable | undefined, index: number): void;

  statusRows: string;
  reasonCheckBox: string;

  handleDefaultValuesRows(): void;

  validateCheckboxByTaxId(
    checkedRow: TransactionTable,
    status: StatusEnum
  ): void;

  renderColumns: IColumnsTable[];
  tab?: number | undefined;
}

export type DashboardTableRowProps = DashboardTableRowStateProps &
  DashboardTableRowFunctionsProps;

const formatDate = (trxDate: number, formatDate: string): string => {
  const date: string = new Date(
    trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
  ).toISOString();

  return format(new Date(date.replace("Z", "")), formatDate, {
    timeZone: "UTC",
  });
};

const formatNumber = (value: number, country: string, currency: string) => {
  let suffixValue: string = "";
  if (currency === "") {
    switch (country) {
      case "Ecuador":
        suffixValue = " USD";
        break;
      case "Chile":
        suffixValue = " CLP";
        break;
      case "Peru":
        suffixValue = " PEN";
        break;
      case "Mexico":
        suffixValue = " MXN";
        break;
    }
  } else suffixValue = ` ${currency}`;
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={suffixValue}
    />
  );
};

export const setChipLabel = (
  kind: string,
  country: string,
  invoiceType: string,
  tab?: number | undefined
) => {
  if (
    country === CountryEnum.mexico &&
    [KindEnum.INVOICE, KindEnum.ANNUL_INVOICE].includes(kind as KindEnum)
  ) {
    if (tab === 0 && kind === KindEnum.ANNUL_INVOICE) return "Anulación";
    return invoiceType === InvoiceModeEnum.WITH_IVA
      ? "Factura con IVA"
      : "Factura sin IVA";
  }

  switch (kind) {
    case KindEnum.ANNUL_INVOICE:
    case KindEnum.INVOICE:
      return "Factura";
    case KindEnum.CHARGE:
      return "Cobro";
    case KindEnum.DISPERSION:
      return "Dispersión";
    case KindEnum.CREDIT_NOTE:
      return "N. de Crédito";
    case KindEnum.VOUCHER:
      return "Boleta";
    case KindEnum.DEBIT_NOTE:
      return "N. de débito";
    case KindEnum.RETENTION:
      return "Retención";
    case KindEnum.RECEIVABLE:
      return "Por cobrar";
    case KindEnum.RETENTION_EC:
      return "Retención";
    case KindEnum.TO_CHARGE:
      return "Por cobrar";
    default:
      return "";
  }
};
export const setChipLabelKind = (transactionType: string) => {
  if (transactionType.includes(TransactionTypeLowerCaseEnum.payout)) {
    return TransactionTypeChipLabelEnum.payout;
  } else if (transactionType.includes(TransactionTypeLowerCaseEnum.payin)) {
    return TransactionTypeChipLabelEnum.payin;
  } else if (
    transactionType.includes(TransactionTypeLowerCaseEnum.minimalCommission)
  ) {
    return TransactionTypeChipLabelEnum.minimalCommission;
  } else if (transactionType.includes(TransactionTypeLowerCaseEnum.threeDS)) {
    return TransactionTypeChipLabelEnum.threeDS;
  } else {
    return "";
  }
};

const getCursorStyle = (row: TransactionTable) => {
  if (!TransactionValidators.isBillingModalDisable(row)) return "pointer";

  return "default";
};

const cursorPointerStyle = (row: TransactionTable) => ({
  cursor: getCursorStyle(row),
});

export const DashboardTableRow: React.FC<DashboardTableRowProps> = (
  props: DashboardTableRowProps
) => {
  const { statusFlow } = props;
  const { selectProps, actions } = useDashboardTableRowState(props);
  const stillProsessing = get(
    props,
    "billingData.transaction.stillReprocessing",
    false
  );
  const classes = styles(props);

  const handleClickTableCell = (
    e: React.MouseEvent<HTMLTableCellElement | HTMLTableDataCellElement>
  ) => {
    props.handlerOpenCardRow(props.billingData, props.index);
    e.preventDefault();
    e.stopPropagation();
  };

  const disableAction = () => {};

  const propsTableCell = (row: TransactionTable) => ({
    onClick: get(row, "transaction.stillReprocessing", false)
      ? disableAction
      : handleClickTableCell,
    style: cursorPointerStyle(row),
  });
  return (
    <>
      <TableRow
        classes={{ root: classes.rowRoot }}
        style={
          get(props, "billingData.advanceSettingsParameters") ||
          get(props, "billingData.advanceSettingsReference") ||
          get(props, "billingData.invoiceInfo")
            ? { background: "rgba(174,14,110,0.3)" }
            : {}
        }
      >
        {props.renderColumns.map((column: IColumnsTable, index) => {
          switch (column.id) {
            case "statusAll":
              return (
                <TableCell>
                  <Checkbox
                    id={"checkFieldStatusAll"}
                    color={"primary"}
                    inputProps={{ "aria-label": "statusAll" }}
                    checked={get(props.billingData, "statusAll", false)}
                    onChange={(e) =>
                      actions.handleChangeCheckboxAll(
                        e,
                        props.billingData,
                        false
                      )
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
              );
            case "taxId":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  {get(props.billingData, "transaction.taxId", "")}
                </TableCell>
              );
            case "merchantName":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  <Box>
                    {get(props.billingData, "transaction.merchantName", "")}
                  </Box>
                  <Typography variant={"body2"} className={classes.cycleDate}>
                    <Box fontSize={12}>
                      {get(props.billingData, "transaction.merchantId", "-")}
                    </Box>
                  </Typography>
                </TableCell>
              );
            case ColumnsEnum.OWNER_NAME_DYNAMO:
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  <Box fontSize={14}>
                    {get(props.billingData, "transaction.ownerName", "")}
                  </Box>
                  <Box fontSize={14}>
                    {get(props.billingData, "transaction.ownerId", "")}
                  </Box>
                </TableCell>
              );
            case ColumnsEnum.CUSTOMER_NAME_DYNAMO:
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  <Box fontSize={14}>
                    {get(props.billingData, "transaction.customerName", "")}
                  </Box>
                  <Box fontSize={14}>
                    {get(
                      props.billingData,
                      "transaction.customerIdHierarchy",
                      ""
                    )}
                  </Box>
                </TableCell>
              );
            case "socialReason":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  {get(props.billingData, "transaction.socialReason", "")}
                </TableCell>
              );

            case "created":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  {formatDate(
                    get(props.billingData, "transaction.created", 1),
                    "dd/MM/yyyy"
                  )}
                </TableCell>
              );
            case "interbankAccountCode":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  {get(
                    props.billingData,
                    "transaction.interbankAccountCode",
                    ""
                  )}
                </TableCell>
              );
            case "invoiceId":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  {[CountryEnum.colombia, CountryEnum.chile].includes(
                    get(props.billingData, "transaction.country", " ")
                  )
                    ? get(props.billingData, "transaction.invoiceId", " ")
                    : get(props.billingData, "transaction.invoiceNumber", " ")}
                </TableCell>
              );
            case "cycle":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  <Box>
                    {defaultTo(
                      get(props.billingData, "transaction.isExclusive"),
                      false
                    )
                      ? translate("exclusive")
                      : translate(
                          get(props.billingData, "transaction.cycle", "")
                        )}
                  </Box>
                  <Typography variant="body2" className={classes.cycleDate}>
                    <Box fontSize={12}>
                      {formatDate(
                        get(props.billingData, "transaction.startDate", 1),
                        "dd/MM/yyyy"
                      )}{" "}
                      -{" "}
                      {formatDate(
                        get(props.billingData, "transaction.endDate", 1),
                        "dd/MM/yyyy"
                      )}
                    </Box>
                  </Typography>
                </TableCell>
              );

            case "transactionType":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  <Chip
                    style={cursorPointerStyle(props.billingData!)}
                    className={classes.chipStyle}
                    label={setChipLabelKind(
                      get(
                        props.billingData,
                        "transaction.transactionType",
                        ""
                      ).toLowerCase()
                    )}
                    size="medium"
                    disabled={stillProsessing}
                  />
                </TableCell>
              );
            case "amount":
              return (
                <TableCell style={{ width: 250 }} key={column.id + index}>
                  <RowElementEdit
                    stillProsessing={stillProsessing}
                    value={Number(
                      get(props.billingData, "transaction.amount", 0)
                    ).toFixed(2)}
                    handleEditValueChange={actions.handleTransactionAmountChange()}
                    number
                    statusFlow={statusFlow}
                  >
                    {formatNumber(
                      get(props.billingData, "transaction.amount", 0),
                      props.country,
                      get(props.billingData, "transaction.currency", "")
                    )}
                  </RowElementEdit>
                </TableCell>
              );
            case "kind":
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  <Chip
                    style={cursorPointerStyle(props.billingData!)}
                    disabled={stillProsessing}
                    className={classes.chipStyle}
                    label={setChipLabel(
                      get(props.billingData, "transaction.kind", ""),
                      get(props, "country"),
                      get(props.billingData, "transaction.invoiceMode", ""),
                      get(props, "tab", undefined)
                    )}
                    size="medium"
                  />
                </TableCell>
              );
            case ColumnsEnum.IS_OCB_DYNAMO:
              return (
                <TableCell
                  {...propsTableCell(props.billingData!)}
                  key={column.id + index}
                >
                  {isOcbValidate(
                    get(props.billingData, "transaction.isOcb", ""),
                    get(props.billingData, "transaction.customerId", ""),
                    get(props.billingData, "transaction.merchantId", "")
                  )}
                </TableCell>
              );

            default:
              return null;
          }
        })}
        {props.isComplementaryTable ? (
          <>
            <TableCell padding="checkbox" style={{ textAlign: "center" }}>
              <Checkbox
                className={classes.check}
                color={"primary"}
                inputProps={{ "aria-label": "procesar" }}
                disabled={stillProsessing}
              />
            </TableCell>
            {statusFlow === StatusFlowEnum.EXECUTOR && (
              <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                <Checkbox
                  className={classes.check}
                  color={"primary"}
                  disabled={stillProsessing}
                  inputProps={{ "aria-label": "pendiente" }}
                />
              </TableCell>
            )}
            {statusFlow === StatusFlowEnum.VALIDATOR && (
              <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                <Checkbox
                  className={classes.check}
                  color={"primary"}
                  disabled={stillProsessing}
                  inputProps={{
                    "aria-label": "rechazado",
                  }}
                />
              </TableCell>
            )}
          </>
        ) : (
          <>
            <TableCell padding="checkbox" style={{ textAlign: "center" }}>
              <Checkbox
                color={"primary"}
                inputProps={{ "aria-label": "procesar" }}
                disabled={stillProsessing}
                checked={
                  (get(props.billingData, "status", "") ===
                    StatusEnum.PROCESS ||
                    get(props.billingData, "status", "") ===
                      StatusEnum.PRE_PROCESSED) &&
                  get(props.billingData, "statusRow", "") !==
                    RowStatusEnum.Reprocess
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  actions.handleChangeCheckbox(
                    event,
                    StatusEnum.PROCESS,
                    statusFlow
                  )
                }
              />
            </TableCell>
            {statusFlow === StatusFlowEnum.EXECUTOR && (
              <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                <Checkbox
                  color={"primary"}
                  inputProps={{ "aria-label": "pendiente" }}
                  disabled={stillProsessing}
                  checked={
                    get(props.billingData, "status", "") ===
                      StatusEnum.PENDING &&
                    get(props.billingData, "statusRow", "") !==
                      StatusEnum.REPROCESS
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    actions.handleChangeCheckbox(
                      event,
                      StatusEnum.PENDING,
                      statusFlow
                    )
                  }
                />
              </TableCell>
            )}
            {statusFlow === StatusFlowEnum.VALIDATOR && (
              <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                <Checkbox
                  color={"primary"}
                  disabled={stillProsessing}
                  inputProps={{ "aria-label": "rechazado" }}
                  checked={
                    get(props.billingData, "status", "") === StatusEnum.REJECT
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    actions.handleChangeCheckbox(
                      event,
                      StatusEnum.REJECT,
                      statusFlow
                    )
                  }
                />
              </TableCell>
            )}
            <TableCell padding="checkbox" style={{ textAlign: "center" }}>
              <Checkbox
                color={"primary"}
                inputProps={{ "aria-label": "reprocesar" }}
                disabled={
                  !includes(
                    [
                      KindEnum.INVOICE,
                      KindEnum.VOUCHER,
                      KindEnum.CHARGE,
                      KindEnum.DISPERSION,
                      KindEnum.RECEIVABLE,
                    ],
                    get(props.billingData, "transaction.kind", "")
                  )
                }
                checked={
                  ((get(props.billingData, "status", "") ===
                    StatusEnum.PROCESS ||
                    get(props.billingData, "status", "") ===
                      StatusEnum.PRE_PROCESSED) &&
                    get(props.billingData, "statusRow", "") ===
                      RowStatusEnum.Reprocess &&
                    includes(
                      [
                        KindEnum.INVOICE,
                        KindEnum.VOUCHER,
                        KindEnum.CHARGE,
                        KindEnum.DISPERSION,
                        KindEnum.RECEIVABLE,
                      ],
                      get(props.billingData, "transaction.kind", "")
                    )) ||
                  (get(props.billingData, "status", "") ===
                    StatusEnum.PENDING &&
                    get(props.billingData, "statusRow", "") ===
                      RowStatusEnum.Reprocess)
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  actions.handleChangeCheckbox(
                    event,
                    StatusEnum.REPROCESS,
                    statusFlow
                  )
                }
              />
            </TableCell>
          </>
        )}
      </TableRow>
      <ConfirmModal
        open={selectProps.openAmountChangeModal}
        titleText={"¿Deseas modificar el monto total?"}
        contentText={`Estas a punto de modificar el monto inicial de esta operación, por el importe de ${Number(
          get(selectProps, "amountEditValue", 0)
        ).toFixed(
          2
        )}. Al modificar este campo se alterará el monto de emisión de la factura del comercio ${get(
          props.billingData,
          "transaction.merchantName",
          ""
        )}.`}
        acceptText="Modificar monto"
        handleClose={actions.handleClose()}
        handleAccept={actions.handleAccept()}
      >
        <Typography className={classes.warningText} variant="h6">
          Indique el motivo del cambio de monto, para guardar la modificación.
        </Typography>
        <TextField
          className={classes.input}
          id="input-edit"
          fullWidth
          label="Indica el motivo del cambio de monto"
          variant="outlined"
          value={selectProps.reasonChangeAmountValue}
          onChange={actions.handleInputReasonChangeAmount()}
        />
      </ConfirmModal>
      <OmitConfirmModal
        open={selectProps.openOmit}
        handleCloseOmit={actions.handleCloseOmit}
        handleAcceptOmit={actions.handleAcceptOmit}
        isIndividual={true}
        rowData={props.billingData}
        externalProps={{
          handleChangeExternal: actions.handleChangeExternal,
          externalType: selectProps.externalType,
          isExternal: selectProps.external,
          handleChangeExternalType: actions.handleChangeExternalType,
        }}
      />

      <ConfirmModal
        open={selectProps.rejectModalOpen}
        titleText={"¿Deseas rechazar esta operación?"}
        contentText={
          <>
            <span
              style={{
                display: "block",
                marginBlockStart: "1em",
                marginBlockEnd: "1em",
              }}
            >
              Estas a punto de rechazar esta operación, al dar click en
              “Aceptar” esta operación regresará a la bandeja del Usuario
              Ejecutor en su apartado de “Facturación y dispersión“.
            </span>
            <span
              style={{
                display: "block",
                marginBlockStart: "1em",
                marginBlockEnd: "1em",
              }}
            >
              Indique el motivo del rechazo, para guardar la modificación.
            </span>
          </>
        }
        acceptText="Aceptar"
        acceptColor="primary"
        handleClose={actions.handleCloseReject()}
        handleAccept={actions.handleAcceptReject()}
      >
        <TextField
          className={classes.input}
          id="input-edit"
          fullWidth
          label={"Indique el motivo de rechazo"}
          variant="outlined"
          value={selectProps.rejectReason}
          onChange={actions.handleChangeRejectReason()}
        />
      </ConfirmModal>
    </>
  );
};

export const mapDispatchToProps: (
  dispatch: Dispatch
) => DashboardTableRowFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): DashboardTableRowFunctionsProps => ({
  setBillingDataRow: (billingDataRow: TransactionTable): void =>
    dispatch(setBillingDataRow(billingDataRow)),
  transactionIdFilter: (): void => {
    dispatch(transactionIdFilter());
  },
});

export default connect(null, mapDispatchToProps)(DashboardTableRow);
