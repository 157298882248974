import React from "react";
import useNotificationDetailState from "./state/useNotificationDetailState";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import Breadcrumb from "../../components/commons/Breadcrumb/Breadcrumb";
import { Header } from "../../components/commons/Header/Header";
import Editor from "react-simple-code-editor";
import "../../styles/CodeEditor.css";
import SimpleChip from "../../components/commons/SimpleChip/SimpleChip";
import { PAYMENT_METHOD_LABEL } from "../../shared/infrastructure/constants/PaymentMethodLabel";
import { TRANSACTION_STATUS } from "../../shared/infrastructure/constants/OptionsEnum";
import {
  NOTIFICATION_CONFIG_DEFAULT,
  NOTIFICATION_CONFIG_TYPE,
  NOTIFICATION_CONFIG_TYPE_TEXT,
} from "./state/useNotificationDetailState.interfaces";
import { useNotificationDetailStyles as useStyles } from "./NotificationDetail.styles";

const NotificationDetail = () => {
  const classes = useStyles();
  const { actions, select, isMobile, onHighLight, dateFormat } =
    useNotificationDetailState();

  const emptyFn = () => {};
  const isStatusCorrect = select.webhookNotification.status[0] === "2";

  return (
    <Container fixed>
      <Breadcrumb
        items={[
          {
            label: "Notificaciones",
            url: `/webhook/notifications/${select.merchantId}`,
          },
        ]}
        lastItem={"Notificaciones de Webhooks"}
      />
      <Header isMobile={isMobile} title="Notificaciones de Webhooks" />
      <Grid container>
        <Grid item xs={12} className={classes.cardSection}>
          <Box>
            <Card>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant={"h5"} color="textPrimary">
                      Hora:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant={"h6"} color="textSecondary">
                      {dateFormat}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={"h5"} color="textPrimary">
                      Relacionados:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant={"h6"} color="textSecondary">
                      {select.webhookNotification.url}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={"h5"} color="textPrimary">
                      Origen:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant={"h6"} color="textSecondary">
                      {select.webhookNotification.origin}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={"h5"} color="textPrimary">
                      Tipo:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box display="flex">
                      {select.webhookNotification.paymentMethod.map(
                        (val: string, index: number) => (
                          <Box mr={1} key={index}>
                            <SimpleChip
                              type={"default"}
                              label={PAYMENT_METHOD_LABEL[val]}
                              key={index}
                            />
                          </Box>
                        )
                      )}
                      {select?.webhookNotification?.event &&
                        TRANSACTION_STATUS[
                          select.webhookNotification.event
                        ] && (
                          <Box>
                            <SimpleChip
                              type={"default"}
                              label={
                                TRANSACTION_STATUS[
                                  select.webhookNotification.event
                                ]
                              }
                            />
                          </Box>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={"h5"} color="textPrimary">
                      Estado:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box mr={3}>
                      <SimpleChip
                        type={isStatusCorrect ? "default" : "error"}
                        label={`${select.webhookNotification.status} ${
                          isStatusCorrect ? "OK" : "ERR"
                        }`}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant={"h5"} color="textPrimary">
                      Configuración:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box mr={3}>
                      <SimpleChip
                        type="default"
                        label={
                          NOTIFICATION_CONFIG_TYPE_TEXT[
                            select.webhookNotification.config ||
                              NOTIFICATION_CONFIG_DEFAULT
                          ]
                        }
                      />
                    </Box>
                  </Grid>
                  {select.webhookNotification.config !==
                    NOTIFICATION_CONFIG_TYPE.API && (
                    <>
                      <Grid item xs={3}>
                        <Typography variant={"h5"} color="textPrimary">
                          Alias:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant={"h6"} color="textSecondary">
                          {select.webhookNotification.alias}{" "}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.cardSection}>
          <Box boxShadow={4}>
            <Card>
              <CardContent classes={{ root: classes.cardContentRoot }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box className={classes.boxTitle}>
                      <Typography variant={"h5"} color={"textPrimary"}>
                        {" "}
                        Cuerpo de envío
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      value={JSON.stringify(
                        select.webhookNotification.requestBody,
                        undefined,
                        2
                      )}
                      onValueChange={emptyFn}
                      highlight={onHighLight}
                      padding={20}
                      className="container__editor"
                      readOnly
                      id="editor1"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.cardSection}>
          <Box boxShadow={4}>
            <Card>
              <CardContent classes={{ root: classes.cardContentRoot }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box className={classes.boxTitle}>
                      <Typography variant={"h5"} color={"textPrimary"}>
                        {" "}
                        Cuerpo de la respuesta{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      value={JSON.stringify(
                        select.webhookNotification.responseBody,
                        undefined,
                        2
                      )}
                      onValueChange={emptyFn}
                      highlight={onHighLight}
                      tabSize={3}
                      padding={20}
                      className="container__editor"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Button onClick={actions.handleGoBack} color={"primary"}>
        Regresar
      </Button>
    </Container>
  );
};

export default NotificationDetail;
