import { SxProps, Theme } from "@mui/material";

export const cardIconStyles = (
  background: string,
  border: string
): SxProps<Theme> => {
  return {
    alignItems: "center",
    background: background,
    border: border,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "3px 12px",
    width: "42px",
    height: "28px",
  };
};
