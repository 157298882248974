import React from "react";
import { Checkbox } from "@material-ui/core";
import { get } from "lodash";
import { TransactionData } from "../../../types/transaction_data";
import { TypeCheckBox } from "../../shared/infrastructure/TypeCheckBox";

export interface CheckboxDashboardProps {
  typeCheckBox: TypeCheckBox;
  handleCheckboxChange?: (rowValue: TransactionData, selected: boolean) => void;
  handleCheckboxChangeAll?: (value: TransactionData[]) => void;
  registerData?: TransactionData;
  registerAllData?: TransactionData[];
  check?: {
    selectedCheck: boolean;
    noSelectedCheck: boolean;
  };
}

export const CheckboxDashboard: React.FC<CheckboxDashboardProps> = (
  props: CheckboxDashboardProps
) => {
  const stopPropagationDefault = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <React.Fragment>
      {props.typeCheckBox === TypeCheckBox.CHECK_APPROVED && (
        <Checkbox
          color={"primary"}
          inputProps={{ "aria-label": "seleccionar" }}
          checked={get(props.registerData, "selected", false)}
          onClick={(e) => {
            props.handleCheckboxChange!(
              props.registerData!,
              !get(props.registerData, "selected", false)
            );
            stopPropagationDefault(e);
          }}
          id={"checkApproved"}
        />
      )}
      {props.typeCheckBox === TypeCheckBox.CHECKBOX_ALL_APPROVED && (
        <Checkbox
          color={"primary"}
          value={props.check?.selectedCheck}
          checked={props.check?.selectedCheck}
          inputProps={{ "aria-label": "seleccionar todo" }}
          onClick={() => props.handleCheckboxChangeAll!(props.registerAllData!)}
          id={"CheckboxAllApproved"}
        />
      )}
    </React.Fragment>
  );
};
