import { createNamedStyles } from "../../../shared/utils";

export const transactionsTableHeadStyles = createNamedStyles({
  cell: {
    color: "neutral.grey7",
    fontSize: (theme) => theme.spacing(1.625),
    fontWeight: 500,
  },
  sortLabel: {
    color: "neutral.grey7",
    "&:hover": {
      color: "neutral.grey7",
    },
    "&:focus": {
      color: "neutral.grey7",
    },
    "& .MuiTableSortLabel-icon": {
      color: "text.grey",
      fill: "transparent",
      opacity: 1,
    },
  },
});
