import React, { FC, PropsWithChildren } from "react";
import { FormHelperText, Grid, Typography } from "@mui/material";
import { SubTitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Controller } from "react-hook-form";
import { retrieveFormError } from "../../../utils/utilsFile";
import { defaultTo } from "lodash";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { useCountrySelectedState } from "./state/useCountrySelectedState";
import { ICountrySelectedComponentProps } from "../../../shared/infrastructure/interfaces/ICountrySelectedComponentProps";
import { generalStyles } from "../../../shared/styles/generalStyles.styles";
import { SelectCheck } from "../../SelectCheck/SelectCheck";

export const CountrySelectedComponent: FC<ICountrySelectedComponentProps> = (
  props: PropsWithChildren<ICountrySelectedComponentProps>
) => {
  const {
    countries,
    isUpdatedRule,
    listSelect,
    control,
    errors,
    isWhiteList,
    action: { countrySelect },
  } = useCountrySelectedState(props);
  const classes = generalStyles();
  const selectedCountries: string[] | undefined = countries || [];

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <Typography className={classes.subtitleTypography}>
          {isWhiteList
            ? SubTitleSection.RULES_CREATE_COUNTRY_WHITELIST_SUBTITLE
            : SubTitleSection.RULES_CREATE_COUNTRY_SUBTITLE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"countries"}
          control={control}
          rules={{
            required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
          }}
          defaultValue={defaultTo(countries, undefined)}
          as={
            <Grid item xs={12} md={7}>
              <SelectCheck
                isDisable={isUpdatedRule!}
                isActiveSelectAll
                isLoading={false}
                items={listSelect}
                setSelectedItems={countrySelect}
                placeHolder={""}
                selected={selectedCountries}
                operator={""}
                isEditCondition={false}
                isFinalCond={false}
              />
            </Grid>
          }
        />
        <FormHelperText error={retrieveFormError(errors, "countries")[0]}>
          {retrieveFormError(errors, "countries")[1]}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
