import { ActionTypes } from "./actionTypes";
import { IAppState } from "./reducer";
import {
  Conditions,
  ConfigurationResponse,
} from "../../types/configuration_response";
import { Category } from "../shared/infrastructure/interfaces/Category";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AxiosResponse } from "axios";
import axios from "../shared/utils/axios-util";
import { environment } from "../environments/environment";
import { defaultTo, flatMap, get, includes, isNil, map, unset } from "lodash";
import { MerchantCredential } from "../../types/merchant_credential";
import { GeographyList } from "../../types/geography_list";
import { HierachyNodesRequest } from "../../types/hierachy_nodes_request";
import { HierachyNodesResponse } from "../../types/hierachy_nodes_response";
import { Country } from "../../types/country_response";
import { MerchantHierarchyResponse } from "../../types/merchant_hierarchy_response";
import { RuleDetail } from "../../types/rule_detail";
import { RuleRequest } from "../../types/rule_request";
import { IAlert } from "../shared/infrastructure/interfaces/IAlert";
import { SecurityRuleData } from "../../types/security_rule_data";
import { SearchRuleRequest } from "../../types/search_rule_request";
import { SearchRuleResponse } from "../../types/search_rule_response";
import { SearchRequestRuleByIdResponse } from "../../types/search_request_rule_by_id_response";
import { SearchRuleByIdResponse } from "../../types/search_rule_by_id_response";
import { IOptionSelection } from "../shared/infrastructure/constants/RuleRequestManagerConstants";
import { FilterRequest } from "../../types/filter_request";
import { UpdateRuleRequest } from "../../types/update_rule_request";
import { TabIndexEnum } from "../shared/infrastructure/enums/TabIndexEnum";
import { RequestTypeEnum } from "../shared/infrastructure/enums/RequestTypeEnum";
import { StatusEnum } from "../shared/infrastructure/enums/StatusEnum";
import { GetWhiteListByCountryRequest } from "../../types/get_whitle_list_by_country_request";
import { GetWhiteListByCountryResponse } from "../../types/get_whitle_list_by_country_response";
import { GetSecurityRulesByTypeRequest } from "../../types/get_security_rules_by_type_request";
import { GetSecurityRulesByTypeResponse } from "../../types/get_security_rules_by_type_response";
import { GetMerchantRequest } from "../../types/get_merchant_request";
import { GetMerchantResponse } from "../../types/get_merchant_response";
import { SearchRuleCatalogRequest } from "../../types/search_rule_catalog_request";
import { SearchRuleCatalogResponse } from "../../types/search_rule_catalog_response";
import { AlertEnum } from "../shared/infrastructure/enums/AlertEnum";
import { RuleGeneralRequest } from "../../types/rule_general_request";
import { IModalOption } from "../shared/infrastructure/interfaces/IModalCatalogGeneralRule";
import { CategoryData, ItemCategoryProps } from "@kushki/connect-ui";
import { GetRuleByIdResponse } from "../../types/get_rule_by_id";
import { HierarchyMerchantCredential } from "../../types/hierarchy_merchant_credential";
import { HierarchyCredentialRequest } from "../../types/hierarchy_credential_request";
import { HierarchyCredentials } from "../../types/hierarchy_credentials";
import { groupHierarchies } from "../shared/infrastructure/constants/HierarchiesGroup";
import {
  GroupEnum,
  ServiceEnum,
} from "../shared/infrastructure/enums/CreateRuleConstans";
import {
  Processors,
  ProcessorsInformation,
  ProcessorsList,
} from "../../types/processors_list";
import { VerifyMassiveConditionRequest } from "../../types/verify_massive_condition_request";
import { VerifyMassiveConditionResponse } from "../../types/verify_massive_condition_response";
import { Catalog } from "../../types/catalog_response";
import { CatalogRequest } from "../../types/catalog_request";

export type IAppAction = { type: string } & IAppState;

export const setConfiguration = (payload: ConfigurationResponse) => {
  return {
    type: ActionTypes.SET_CONFIGURATION,
    configuration: payload,
  };
};

export const setProcessorsList = (
  payload: ProcessorsInformation[]
): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT_PROCESSORS,
    processorsList: payload,
  };
};

export const setGeographyList = (payload: GeographyList) => {
  return {
    type: ActionTypes.SET_GEOGRAPHY_LIST,
    geographyList: payload,
  };
};

export const setSecurityRule = (payload: SecurityRuleData | {}) => {
  return {
    type: ActionTypes.SET_RULE_DATA,
    rule: payload,
  };
};

export const setCountriesRule = (payload: Country[]) => {
  return {
    type: ActionTypes.SET_COUNTRY_RULES,
    countries: payload,
  };
};

export const setCurrencyByCountry = (
  payload: Record<string, IOptionSelection[]>
) => {
  return {
    type: ActionTypes.SET_CURRENCY,
    currencyByCountry: payload,
  };
};

export const setRuleDetails = (payload: RuleDetail) => {
  return {
    type: ActionTypes.SET_RULE_DETAILS,
    ruleDetails: payload,
  };
};

export const setIsEditedRule = (payload: boolean) => {
  return {
    type: ActionTypes.SET_IS_EDITED_RULE,
    isEditedRule: payload,
  };
};

export const setGeneralRule = (payload: SecurityRuleData | {}) => {
  return {
    type: ActionTypes.SET_GENERAL_RULE,
    generalRule: payload,
  };
};

export const setRuleCatalog = (payload: SearchRuleCatalogResponse) => {
  return {
    type: ActionTypes.SET_RULE_CATALOG,
    ruleCatalog: payload,
  };
};

export const setMerchantHierarchy = (
  payload: MerchantHierarchyResponse | undefined
) => {
  return {
    type: ActionTypes.SET_MERCHANT_HIERARCHY,
    merchantHierarchy: payload,
  };
};

export const setAvailableConditions = (payload: Conditions[]) => {
  return {
    type: ActionTypes.SET_AVAILABLE_CONDITIONS,
    availableConditions: payload,
  };
};
export const setIsRequest = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_REQUESTING,
    isRequest: payload,
  };
};

export const setIsPaymentMethod = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_PAYMENT_METHOD,
    isPaymentMethod: payload,
  };
};

export const setRequestError = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_REQUEST_ERROR,
    requestError: payload,
  };
};

export const setIsLoadingConfiguration = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_CONFIGURATION,
    isLoadingConfiguration: payload,
  };
};

export const setIsLoading = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    isLoading: payload,
  };
};
export const setIsLoadingProcessor = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_PROCESSOR,
    isLoadingProcessor: payload,
  };
};

export const setIsLoadingRequest = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_REQUEST,
    isLoadingRequest: payload,
  };
};

export const setRefreshTable = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_REFRESH_TABLE,
    refreshTable: payload,
  };
};

export const setIsLoadingModal = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_MODAL_CHANGE,
    isLoadingModal: payload,
  };
};

export const setIsLoadingRules = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_RULES,
    isLoadingRules: payload,
  };
};
export const setValidatingFile = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_VALIDATING_FILE,
    validatingFile: payload,
  };
};
export const setVerifyMassiveConditionResponse = (
  payload: VerifyMassiveConditionResponse | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_VERIFY_MASSIVE_CONDITION_RESPONSE,
    verifyMassiveConditionResponse: payload,
  };
};
export const setIsLoadingOneRule = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_ONE_RULE,
    isLoadingOneRule: payload,
  };
};

export const setOpenAlert = (payload: IAlert): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_ALERT,
    openAlert: payload,
  };
};
export const setSavingRule = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_SAVING_RULE,
    savingRule: payload,
  };
};
export const setOpenConfirmModal = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_CONFIRM_MODAL,
    openConfirmModal: payload,
  };
};

export const setOpenConfirmGeneralRuleModal = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_CONFIRM_GENERAL_RULE_MODAL,
    openConfirmGeneralRuleModal: payload,
  };
};

export const setOpenModalInfo = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_INFO,
    openModalInfo: payload,
  };
};

export const setOpenModalCatalogGeneralRules = (
  payload: IModalOption
): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_CATALOG_GENERAL_RULES,
    openModalCatalogGeneralRules: payload,
  };
};
export const setSelectedFilter = (payload: ItemCategoryProps[]): IAppAction => {
  return {
    type: ActionTypes.SET_SELECTED_FILTER,
    selectedFilter: payload,
  };
};
export const setSearchFilter = (
  payload: SearchRuleRequest | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_SEARCH_FILTER,
    searchFilter: payload,
  };
};
export const setDetailCatalogGeneralRules = (
  payload: RuleGeneralRequest | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_DETAIL_CATALOG_GENERAL_RULES,
    detailCatalogGeneralRules: payload,
  };
};

export const setOpenModalRejected = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_INFO_REJECTED,
    openModalUpdateStatus: payload,
  };
};

export const setMerchantCredentials = (payload: Category[]): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT_CREDENTIALS,
    credentials: payload,
  };
};

export const setSearchRequestRulesResponse = (
  payload: SearchRuleResponse
): IAppAction => ({
  searchRequestRulesResponse: payload,
  type: ActionTypes.SET_SEARCH_REQUEST_RULES_RESPONSE,
});

export const setRequestRuleById = (
  payload: SearchRequestRuleByIdResponse | SearchRuleByIdResponse | undefined
): IAppAction => ({
  requestRule: payload,
  type: ActionTypes.SET_SEARCH_REQUEST_BY_ID,
});

export const setSearchRuleById = (
  payload: GetRuleByIdResponse | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_SEARCH_RULE_BY_ID,
    searchRuleById: payload,
  };
};

export const setSearchRequestRuleById = (
  payload: SearchRequestRuleByIdResponse | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_SEARCH_REQUEST_RULE_BY_ID,
    searchRequestRuleById: payload,
  };
};

export const setListCommerceWithoutHierachy = (
  payload: CategoryData[]
): IAppAction => {
  return {
    type: ActionTypes.SET_LIST_COMMERCE_WITHOUT_HIERACHY,
    listCommerceWithoutHierachy: payload,
  };
};

export const setActionType = (payload: string) => {
  return {
    type: ActionTypes.SET_ACTION_TYPE,
    actionType: payload,
  };
};
export const setTextFieldComponentActions = (
  payload: { label: string; value: string } | undefined
) => {
  return {
    type: ActionTypes.SET_TXT_FIELD_ACTIONS,
    textFieldComponentActions: payload,
  };
};
export const setCommerceType = (payload: string) => {
  return {
    type: ActionTypes.SET_COMMERCE_TYPE,
    commerceType: payload,
  };
};

function getAllCurrencyByCountryResponse(
  response: Country[]
): Record<string, IOptionSelection[]> {
  return response.reduce((previousValue, current) => {
    const currencies: string[] | undefined = current.currency?.split(",");
    if (!currencies || currencies[0] === "") return previousValue;
    const finalCurrencies: IOptionSelection[] = [];
    currencies.forEach((el) => finalCurrencies.push({ value: el, label: el }));
    previousValue["CURRENCY_" + current.name.toUpperCase().replace(" ", "_")] =
      finalCurrencies;
    return previousValue;
  }, {});
}

export const setFilterResponse = (payload: object) => ({
  filterList: payload,
  type: ActionTypes.SET_FILTER_RESPONSE,
});

export const getFilterResponse =
  (
    dataSend: FilterRequest
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        dataSend
      )
      .then((axios_response: AxiosResponse<object>) => {
        dispatch(
          setFilterResponse(
            map(get(axios_response, "data.data"), (item: object) => ({
              name: get(item, "_source.name", ""),
              merchantId: get(item, "_source.merchantId", ""),
            }))
          )
        );
      })
      .catch((_) => {});
  };

export const getFilterHierarchyResponse =
  (name: string): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(
        `${environment.kushkiUrl}/request-manager/v1/hierarchy/nodes/${name}`
      )
      .then((axios_response: AxiosResponse<object>) => {
        dispatch(
          setFilterResponse(
            flatMap(
              get(axios_response, "data"),
              (item: object, key: string) => {
                if (!includes(groupHierarchies[GroupEnum.OWNER], key))
                  return get(item, "nodes");
              }
            )
          )
        );
      })
      .catch((_) => {});
  };

export const setSelectedTabIndex = (payload: number): IAppAction => {
  return {
    type: ActionTypes.SET_SELECTED_TAB_INDEX,
    selectedTabIndex: payload,
  };
};

export const setSuccessfulEndpointCall = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_SUCCESSFUL_ENDPOINT_CALL,
    successfulEndpointCall: payload,
  };
};

export const setWhiteListByCountry = (
  payload: GetWhiteListByCountryResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_WHITE_LIST_BY_COUNTRY,
    whiteListByCountry: payload,
  };
};

export const setSecurityRuleByType = (
  payload: GetSecurityRulesByTypeResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_SECURITY_RULE_BY_TYPE,
    securityRuleByType: payload,
  };
};

export const setMerchantInformation = (
  payload: GetMerchantResponse | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT_INFORMATION,
    merchantInformation: payload,
  };
};

export const setIsLoadingCatalog = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_CATALOG,
    isLoadingCatalog: payload,
  };
};

export const setListMccCatalog = (
  payload: Catalog[] | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_LIST_MCC_CATALOG,
    listMccCatalog: payload,
  };
};

export const getCountries = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoading(true));
    axios
      .get(`${environment.kushkiUrl}/request-manager/v1/geography/country`)
      .then((axios_response: AxiosResponse) => {
        const response: Country[] = get(axios_response, "data.countries");
        dispatch(setCountriesRule(response));
        dispatch(
          setCurrencyByCountry(getAllCurrencyByCountryResponse(response))
        );
        dispatch(setIsLoading(false));
      })
      .catch(() => {
        dispatch(setCountriesRule([]));
        dispatch(setCurrencyByCountry({}));
        dispatch(setIsLoading(true));
      });
  };
};

export const getMerchantHierarchy =
  (
    name: string | undefined
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setProcessorsList([]));
    const url: string = `${environment.kushkiUrl}/request-manager/v1/hierarchy/nodes/${name}`;
    try {
      const axios_response: AxiosResponse = await axios.get(url);

      const response: MerchantHierarchyResponse = get(axios_response, "data");
      dispatch(setMerchantHierarchy(response));
    } catch {
      dispatch(setMerchantHierarchy({} as MerchantHierarchyResponse));
      dispatch(setIsLoadingModal(false));
    }
  };

export const setHierarchyNodes = (
  payload: HierachyNodesResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_HIERARCHY_NODES,
    affectedNodes: payload,
  };
};
export const setLastLimit = (payload: number): IAppAction => {
  return {
    type: ActionTypes.SET_LAST_LIMIT,
    lastLimit: payload,
  };
};
export const getConfiguration = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setIsLoadingConfiguration(true));
    dispatch(setRequestError(false));
    axios
      .get(`${environment.kushkiUrl}/request-manager/v1/configuration`)
      .then((axios_response: AxiosResponse) => {
        const response: ConfigurationResponse = get(axios_response, "data");
        dispatch(setConfiguration(response));
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(
          setConfiguration({
            generalActions: [],
            messages: [],
            codes: [],
            providers: [],
            operators: [],
            conditions: [],
            periodicity: [],
            transactionType: [],
          })
        );
      });
  };
};

export const getGeographyList = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setIsLoadingConfiguration(true));
    dispatch(setRequestError(false));
    axios
      .get(`${environment.kushkiUrl}/request-manager/v1/geography`)
      .then((axios_response: AxiosResponse) => {
        const response: GeographyList = get(axios_response, "data");
        dispatch(setGeographyList(response));
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(
          setGeographyList({ continents: [], countries: [], regions: [] })
        );
      });
  };
};

export const getMerchantCredential = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .get<MerchantCredential[]>(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`
      )
      .then((axios_response: AxiosResponse<MerchantCredential[]>) => {
        const response: MerchantCredential[] = get(axios_response, "data");

        buildCredentials(response, dispatch);
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(setMerchantCredentials([]));
      });
  };
};

export const getHierarchyMerchantCredential = (
  merchantId: string,
  entityName: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const req: HierarchyCredentialRequest = {
      searchTerm: "ssd",
      merchantId,
      entityName,
    };
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .post<HierarchyMerchantCredential>(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credential/hierarchyCredentials`,
        req
      )
      .then((axios_response: AxiosResponse<HierarchyMerchantCredential>) => {
        const response: HierarchyCredentials[] = get(
          axios_response,
          "data.data"
        );

        buildHierarchyCredentials(response, dispatch);
      })
      .catch(() => {
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(setMerchantCredentials([]));
      });
  };
};

export const getHierarchyNodes = (
  request: HierachyNodesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    dispatch(setIsLoadingModal(true));
    axios
      .post<HierachyNodesRequest>(
        `${environment.kushkiUrl}/request-manager/v1/hierarchy/nodes`,
        { ...request }
      )
      .then((axios_response: AxiosResponse) => {
        const response: HierachyNodesResponse = get(axios_response, "data");
        dispatch(setHierarchyNodes(response));
        dispatch(setIsLoadingModal(false));
      })
      .catch(() => {
        dispatch(setIsRequest(false));
        dispatch(setRequestError(true));
        dispatch(setIsLoadingModal(false));
      });
  };
};

export const getRuleRequestCatalog = (
  request: SearchRuleCatalogRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setRequestError(false));
    axios
      .post<SearchRuleCatalogRequest>(
        `${environment.kushkiUrl}/request-manager/v1/rule-request/search-catalog`,
        { ...request }
      )
      .then((axios_response: AxiosResponse) => {
        const response: SearchRuleCatalogResponse = get(axios_response, "data");
        dispatch(setRuleCatalog(response));
      })
      .catch(() => {
        dispatch(setRequestError(true));
      });
  };
};

export const createRuleGeneral = (
  request: RuleGeneralRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setSavingRule(true));
    axios
      .post<RuleGeneralRequest>(
        `${environment.kushkiUrl}/request-manager/v1/rules-request/catalog`,
        { ...request }
      )
      .then(() => {
        dispatch(
          setOpenAlert({
            open: true,
            isError: false,
            origin: AlertEnum.ORIGIN_GENERAL_RULE,
          })
        );
        dispatch(setSavingRule(false));
        dispatch(setOpenConfirmGeneralRuleModal(false));
        setTimeout(() => {
          dispatch(setSuccessfulEndpointCall(true));
        }, 1500);
      })
      .catch(() => {
        dispatch(
          setOpenAlert({
            open: true,
            isError: true,
            origin: AlertEnum.ORIGIN_GENERAL_RULE,
          })
        );
        dispatch(setSavingRule(false));
      });
  };
};

const buildCredentials = (
  merchantCredentials: MerchantCredential[],
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const credentials: Category[] = [];
  merchantCredentials.forEach((credential: MerchantCredential) => {
    const alias: string | undefined | null = get(credential, "alias");
    let name: string = "";

    if (isNil(alias))
      name = `${credential.publicCredentialId.substr(0, 6)}XXXXXX`;
    else
      name = `${
        alias === "" ? "Alias" : alias
      }_${credential.publicCredentialId.substr(0, 6)}XXXXXX`;

    credentials.push({ name, value: credential.credentialId });
  });

  dispatch(setMerchantCredentials(credentials));
  dispatch(setIsRequest(false));
  dispatch(setRequestError(false));
};

const buildHierarchyCredentials = (
  merchantCredentials: HierarchyCredentials[],
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const credentials: Category[] = [];
  merchantCredentials.forEach((credential: HierarchyCredentials) => {
    credentials.push({
      name: get(credential, "_source.alias", ""),
      value: credential._source.credentialId,
    });
  });

  dispatch(setMerchantCredentials(credentials));
  dispatch(setIsRequest(false));
  dispatch(setRequestError(false));
};

export const getProcessors = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    dispatch(setIsLoadingProcessor(true));
    const processorsList: ProcessorsInformation[] = [];
    const card: void | AxiosResponse<ProcessorsList> =
      await getProcessorsByPaymentMethod(
        merchantId,
        ServiceEnum.CARD,
        dispatch
      );

    get(card, "data.data", []).forEach((processor: Processors) =>
      processorsList.push({ ...processor._source })
    );
    if (processorsList.length === 0) {
      dispatch(setIsRequest(false));
      dispatch(setIsPaymentMethod(false));
      dispatch(setRequestError(true));
      dispatch(
        setOpenAlert({ open: true, isError: true, origin: "PROCESSOR" })
      );
    } else {
      dispatch(setProcessorsList(processorsList));
      dispatch(setIsPaymentMethod(true));
      dispatch(setIsRequest(false));
      dispatch(setRequestError(false));
      dispatch(setIsLoadingProcessor(false));
    }
  };
};

const getProcessorsByPaymentMethod = (
  merchantId: string,
  paymentMethod: string,
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void | AxiosResponse<ProcessorsList>> => {
  return axios
    .post<ProcessorsList>(
      `${environment.kushkiUrl}/analytics/v1/admin/${merchantId}/processors`,
      {
        limit: 10,
        offset: 0,
        filter: {
          paymentMethod,
        },
      }
    )
    .catch(() => {
      dispatch(setIsRequest(false));
      dispatch(setRequestError(true));
    });
};

export const sendRuleRequest = (
  rule: RuleRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setRequestError(false));
    const url: string = `${environment.kushkiUrl}/request-manager/v1/rule-request`;

    axios
      .post<RuleRequest>(url, {
        ...rule,
      })
      .then(() => {
        const isActivationEditionRequest: boolean =
          rule.requestType == RequestTypeEnum.EDITION ||
          rule.requestType == RequestTypeEnum.ACTIVATION;
        const tabIndex: number = isActivationEditionRequest
          ? TabIndexEnum.FIRST_LEVEL_RULES_INDEX
          : TabIndexEnum.PENDING_REVIEW_INDEX;

        dispatch(setSelectedTabIndex(tabIndex));
        dispatch(setOpenAlert({ open: true, isError: false }));
        dispatch(setOpenConfirmModal(false));
        setTimeout(() => {
          dispatch(setSuccessfulEndpointCall(true));
          dispatch(setIsLoading(false));
        }, 3000);
      })
      .catch(() => {
        dispatch(setSuccessfulEndpointCall(false));
        dispatch(setOpenAlert({ open: true, isError: true }));
        dispatch(setOpenConfirmModal(false));
        dispatch(setIsLoading(false));
      });
  };
};

export const updateRuleRequest = (
  updateRule: UpdateRuleRequest,
  ruleId: string,
  tabRedirect: number
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingRequest(true));
    dispatch(setIsLoadingModal(true));
    dispatch(setRequestError(false));

    const url: string = `${environment.kushkiUrl}/request-manager/v1/rule-request/${ruleId}`;
    const isApprovedRequest: boolean =
      updateRule.status == StatusEnum.APPROVED ||
      updateRule.status == StatusEnum.PENDING;

    axios
      .patch<UpdateRuleRequest>(url, updateRule)
      .then(() => {
        dispatch(setOpenAlert({ open: true, isError: false }));
        dispatch(setOpenConfirmModal(false));
        if (isApprovedRequest) dispatch(setOpenModalInfo(false));
        setTimeout(() => {
          dispatch(setIsLoadingModal(false));
          dispatch(setSuccessfulEndpointCall(true));
          dispatch(setIsLoading(false));
          dispatch(setIsLoadingRequest(false));
          dispatch(setRefreshTable(true));
          dispatch(setSelectedTabIndex(tabRedirect));
        }, 1000);
      })
      .catch(() => {
        if (isApprovedRequest) dispatch(setOpenModalInfo(false));
        dispatch(setSuccessfulEndpointCall(false));
        dispatch(setOpenAlert({ open: true, isError: true }));
        dispatch(setOpenConfirmModal(false));
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingModal(false));
        dispatch(setIsLoadingRequest(false));
      });
  };
};

export const deleteRuleRequest = (
  updateRule: UpdateRuleRequest,
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setRequestError(false));
    dispatch(setIsLoadingModal(true));

    const url: string = `${environment.kushkiUrl}/request-manager/v1/rule-request/${ruleId}`;

    axios
      .post<UpdateRuleRequest>(url, updateRule)
      .then(() => {
        dispatch(setOpenAlert({ open: true, isError: false }));
        setTimeout(() => {
          dispatch(setIsLoadingModal(false));
          dispatch(setOpenModalInfo(false));
          dispatch(setRefreshTable(true));
          dispatch(setSelectedTabIndex(0));
        }, 3000);
      })
      .catch(() => {
        dispatch(setIsLoadingModal(false));
        dispatch(setOpenAlert({ open: true, isError: true }));
      });
  };
};

export const deleteRule = (
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingModal(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/monitor/security/rules/${ruleId}`;

    axios
      .delete(url)
      .then(() => {
        dispatch(setOpenAlert({ open: true, isError: false }));
        setTimeout(() => {
          dispatch(setIsLoadingModal(false));
          dispatch(setOpenModalInfo(false));
          dispatch(setRefreshTable(true));
          dispatch(setSelectedTabIndex(0));
        }, 3000);
      })
      .catch(() => {
        dispatch(setIsLoadingModal(false));
        dispatch(setOpenAlert({ open: true, isError: true }));
      });
  };
};

export const getRequestRules = (
  path: string,
  body: SearchRuleRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingRules(true));
    dispatch(setRequestError(false));
    axios
      .post(`${environment.kushkiUrl}/request-manager/v1/${path}`, body)
      .then((axios_response: AxiosResponse<SearchRuleResponse>) => {
        const response: SearchRuleResponse = axios_response.data;
        dispatch(setSearchRequestRulesResponse(response));
        dispatch(setIsLoadingRules(false));
      })
      .catch(() => {
        dispatch(setIsLoadingRules(false));
        dispatch(setRequestError(true));
      });
  };
};
export const verifyMassiveConditionValueFile = (
  body: VerifyMassiveConditionRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setValidatingFile(true));
    axios
      .post(
        `${environment.kushkiUrl}/request-manager/v1/rule-request/verifyMassiveConditionFile`,
        body
      )
      .then((axios_response: AxiosResponse<VerifyMassiveConditionResponse>) => {
        const response: VerifyMassiveConditionResponse = axios_response.data;
        dispatch(
          setVerifyMassiveConditionResponse({ ...response, label: body.label })
        );
        dispatch(setValidatingFile(false));
      })
      .catch(() => {
        dispatch(setValidatingFile(false));
      });
  };
};

export const getRequestById = (
  path: string,
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setRequestError(false));
    dispatch(setIsLoadingOneRule(true));
    axios
      .get(`${environment.kushkiUrl}/request-manager/v1/${path}/${ruleId}`)
      .then(
        (
          axios_response: AxiosResponse<
            SearchRequestRuleByIdResponse | SearchRuleByIdResponse
          >
        ) => {
          const response:
            | SearchRequestRuleByIdResponse
            | SearchRuleByIdResponse = axios_response.data;
          dispatch(setRequestRuleById(response));
          dispatch(setIsLoadingOneRule(false));
        }
      )
      .catch(() => {
        dispatch(setRequestError(true));
      });
  };
};

export const searchRuleById = (
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingOneRule(true));
    axios
      .get<GetRuleByIdResponse>(
        `${environment.kushkiUrl}/rules/v1/monitor/security/rules/${ruleId}`
      )
      .then((axios_response: AxiosResponse<GetRuleByIdResponse>) => {
        const response: GetRuleByIdResponse = get(axios_response, "data");
        dispatch(setSearchRuleById(response));
        dispatch(setIsLoadingOneRule(false));
      })
      .catch((_) => {});
  };
};

export const searchRequestRuleById = (
  ruleId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get<SearchRequestRuleByIdResponse>(
        `${environment.kushkiUrl}/request-manager/v1/rule-request/${ruleId}`
      )
      .then((axios_response: AxiosResponse<SearchRequestRuleByIdResponse>) => {
        const response: SearchRequestRuleByIdResponse = get(
          axios_response,
          "data"
        );
        dispatch(setSearchRequestRuleById(response));
      })
      .catch((_) => {});
  };
};

export const updateStatusRule = (
  payload: UpdateRuleRequest,
  ruleId: string,
  tabIndex: number = TabIndexEnum.FIRST_LEVEL_RULES_INDEX
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingModal(true));
    axios
      .patch(
        `${environment.kushkiUrl}/rules/v1/monitor/security/rules/${ruleId}`,
        { ...payload }
      )
      .then((_: AxiosResponse<object>) => {
        dispatch(setOpenAlert({ open: true, isError: false }));
        setTimeout(() => {
          dispatch(setIsLoadingModal(false));
          dispatch(setRefreshTable(true));
          dispatch(setSelectedTabIndex(tabIndex));
        }, 1000);
      })
      .catch(() => {
        dispatch(setIsLoadingModal(false));
        dispatch(setOpenAlert({ open: true, isError: true }));
      });
  };
};

export const getWhiteListByCountry = (
  body: GetWhiteListByCountryRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/rules/v1/whitelist/countries`, body)
      .then((axios_response: AxiosResponse<GetWhiteListByCountryResponse>) => {
        const response: GetWhiteListByCountryResponse = axios_response.data;

        dispatch(setWhiteListByCountry(response));
      })
      .catch((_) => {});
  };
};

export const getSecurityRulesByType = (
  type: string,
  body: GetSecurityRulesByTypeRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(
        `${environment.kushkiUrl}/rules/v1/monitor/security-type/rules/${type}`,
        body
      )
      .then((axios_response: AxiosResponse<GetSecurityRulesByTypeResponse>) => {
        const response: GetSecurityRulesByTypeResponse = axios_response.data;

        dispatch(setSecurityRuleByType(response));
      })
      .catch((_) => {});
  };
};

export const getMerchantInformation = (
  body: GetMerchantRequest,
  path: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post<GetMerchantResponse>(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/${path}`,
        body
      )
      .then((axios_response: AxiosResponse<GetMerchantResponse>) => {
        const response: GetMerchantResponse = axios_response.data;

        dispatch(setMerchantInformation(response));
      })
      .catch((_) => {});
  };
};

export const getListCatalogs = (
  payload: CatalogRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    unset(payload, "typeCatalog");

    dispatch(setIsLoadingCatalog(true));
    axios
      .post<Catalog[]>(
        `${environment.kushkiUrl}/catalog/v1/list-catalog`,
        payload
      )
      .then((axios_response: AxiosResponse<Catalog[]> | void) => {
        dispatch(setIsLoadingCatalog(false));

        dispatch(setListMccCatalog(defaultTo(get(axios_response, "data"), [])));
      })
      .catch((_) => {
        dispatch(setIsLoadingCatalog(false));
        dispatch(setListMccCatalog([]));
      });
  };
};
