import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const RulesAlarmStyles = createNamedStyles({
  container: { paddingTop: "48px", overflowX: "scroll" },
  headerContainer: {
    paddingBottom: "2rem",
    paddingTop: "1rem",
  },
  tableContainer: {
    overflowX: "scroll",
  },
});
