import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getGeographyList,
  searchRequestRuleById,
  searchRuleById,
  sendRuleRequest,
  setIsLoading,
  setOpenAlert,
  setOpenConfirmModal,
  setOpenModalInfo,
  setSelectedTabIndex,
  setSuccessfulEndpointCall,
  updateRuleRequest,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import {
  IConditionForm,
  ISecurityRuleForm,
} from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { RuleDetail } from "../../../../types/rule_detail";
import { defaultTo, get, isEmpty, isEqual, isUndefined, set } from "lodash";
import { Routes } from "../../../shared/infrastructure/routes";
import {
  buildGenerateConditions,
  buildObjectToResetForm,
  isFormEditMode,
  mapRules,
} from "../../../utils/utilsFile";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import { IUseWhiteListState } from "../../../shared/infrastructure/interfaces/IUseWhiteListState";
import { HierachyNodesResponse } from "../../../../types/hierachy_nodes_response";
import {
  GroupEnum,
  RulesSchema,
  ServiceEnum,
  ServiceLabelEnum,
  SubTypeEnum,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { SecurityRuleData } from "../../../../types/security_rule_data";
import { RuleRequest } from "../../../../types/rule_request";
import { FieldsRule } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { SearchRequestRuleByIdResponse } from "../../../../types/search_request_rule_by_id_response";
import { GetRuleByIdResponse } from "../../../../types/get_rule_by_id";
import {
  TableValueEnum,
  TEXT_TRANSFORM_COLUMNS,
} from "../../../shared/infrastructure/enums/DataTableEnum";
import { ConfigurationTypeEnum } from "../../../shared/infrastructure/enums/ConfigurationTypeEnum";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import {
  AlertEnum,
  ERROR_MESSAGE_ALERT,
  SUCCESS_MESSAGE_ALERT,
} from "../../../shared/infrastructure/enums/AlertEnum";
import { ErrorOutline } from "@mui/icons-material";
import { StylesEnum } from "../../../shared/infrastructure/enums/StylesEnum";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "@kushki/connect-ui";
import { RequestTypeEnum } from "../../../shared/infrastructure/enums/RequestTypeEnum";
import { UpdateRuleRequest } from "../../../../types/update_rule_request";
import { TabIndexEnum } from "../../../shared/infrastructure/enums/TabIndexEnum";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import { WHITELIST_CONDITIONS } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { HierarchyPathsEnum } from "../../../shared/infrastructure/enums/PathsEnum";

export const useWhiteListState = (): IUseWhiteListState => {
  const dispatch = useDispatch();
  const { id, requestType } = useParams<IUseParams>();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const [branchValue, setBranchValue] = useState(0);
  const [customerValue, setCustomerValue] = useState(0);

  const successfulEndpointCall: boolean = useSelector((state: IAppState) =>
    defaultTo(state.successfulEndpointCall, false)
  );
  const isLoading: boolean = useSelector((state: IAppState) =>
    defaultTo(state.isLoading, false)
  );
  const hierarchyNodesValues: HierachyNodesResponse | undefined = useSelector(
    (state: IAppState) => state.affectedNodes
  );
  const indexTab: number = useSelector((state: IAppState) =>
    defaultTo(state.selectedTabIndex, TabIndexEnum.PENDING_REVIEW_INDEX)
  );
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const rule: SecurityRuleData | undefined = useSelector(
    (state: IAppState) => state.rule
  );
  const searchRequestRuleByIdResponse:
    | SearchRequestRuleByIdResponse
    | undefined = useSelector(
    (state: IAppState) => state.searchRequestRuleById
  );
  const searchRuleByIdData: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );
  const configurationValue: ConfigurationResponse | undefined = useSelector(
    (state: IAppState) => state.configuration
  );
  const [openAlert, isError] = useSelector((state: IAppState) => {
    return [
      get(state, "openAlert.open", false),
      get(state, "openAlert.isError", false),
    ];
  });

  useEffect(() => {
    if (successfulEndpointCall) {
      history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);
    }
    dispatch(setSuccessfulEndpointCall(false));
  }, [successfulEndpointCall]);

  const form = useForm<ISecurityRuleForm>({
    mode: "all",
    defaultValues: {
      condition: [
        {
          value: "",
          label: "",
          operator: "",
        },
      ],
    },
    shouldUnregister: false,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "condition",
  });

  useEffect(() => {
    if (openAlert) {
      showSnackbar({
        message: isError
          ? ERROR_MESSAGE_ALERT[AlertEnum.ORIGIN_WHITE_LIST]
          : SUCCESS_MESSAGE_ALERT[AlertEnum.ORIGIN_WHITE_LIST],
        variant: "simple",
        color: isError ? "danger" : "info",
        icon: isError ? (
          <ErrorOutline
            fontSize={StylesEnum.INHERIT}
            color={StylesEnum.INHERIT}
          />
        ) : (
          <CheckIcon fontSize={StylesEnum.INHERIT} color={StylesEnum.PRIMARY} />
        ),
        withIcon: true,
      });
      dispatch(setOpenAlert({ open: false, isError: false }));
    }
  }, [openAlert]);

  const commerceType: string = defaultTo(
    form.watch("commerceType"),
    SubTypeEnum.COMMERCE_WITH_HIERARCHY
  );

  const handleSendToReview = (): void => {
    dispatch(setIsLoading(true));
    const request: RuleRequest = {
      status: FieldsRule.STATUS_PENDING_CREATED,
      requestType: FieldsRule.REQUEST_TYPE_CREATION,
      entityName: get(ruleDetails, "entityName", "").toLowerCase(),
      configType: FieldsRule.WHITE_LIST,
      merchantName: get(ruleDetails, "merchantName", ""),
      merchantId: get(ruleDetails, "merchantId", ""),
      subType: get(ruleDetails, "subType", ""),
      service: ServiceEnum.CARD,
      country: get(ruleDetails, "country", []),
      regions: [],
      continents: [],
      rule: {
        generalAction: defaultTo(get(rule, "generalAction"), "warning"),
        code: defaultTo(get(rule, "code"), ""),
        partnerValidator: defaultTo(get(rule, "provider"), ""),
        message: defaultTo(get(rule, "message"), ""),
        name: generateWhitelistRuleName(commerceType),
      },
    };

    if (!isUndefined(get(ruleDetails, "nodeId")))
      request.nodeId = get(ruleDetails, "nodeId");

    if (!isUndefined(get(ruleDetails, "rootId")))
      request.rootId = get(ruleDetails, "rootId");

    if (!isUndefined(get(ruleDetails, "entityName")))
      request.entityName = get(ruleDetails, "entityName", "").toLowerCase();

    const ruleRequestToSend: RuleRequest = mapRules(
      get(rule, "condition", []),
      request
    );

    if (isFormEditMode(id)) {
      const sub_type: string =
        TEXT_TRANSFORM_COLUMNS[get(searchRuleByIdData, "rule.rule.name", "")];
      const is_commerce: boolean = isEqual(sub_type, ServiceLabelEnum.COMMERCE);
      set(ruleRequestToSend, "status", FieldsRule.STATUS_PENDING_EDITED);
      set(
        ruleRequestToSend,
        "subType",
        is_commerce ? TableValueEnum.COMMERCE : TableValueEnum.COUNTRY
      );
      set(
        ruleRequestToSend,
        "requestType",
        isEqual(requestType, RequestTypeEnum.DEACTIVATION)
          ? RequestTypeEnum.ACTIVATION
          : requestType
      );
      set(ruleRequestToSend, "id", id);
      if (
        get(searchRequestRuleByIdResponse, "status", "") ===
        FieldsRule.STATUS_REJECTED
      ) {
        const update_request: UpdateRuleRequest = {
          status: get(ruleRequestToSend, "status"),
          observationInfo: get(ruleDetails, "observationInfo"),
          isFixed: true,
          rule: get(ruleRequestToSend, "rule"),
        };

        dispatch(
          updateRuleRequest(
            update_request,
            get(ruleRequestToSend, "id", ""),
            TabIndexEnum.REJECTED_APPLICATIONS_INDEX
          )
        );
        return;
      }
    }

    dispatch(sendRuleRequest(ruleRequestToSend));
  };

  const generateWhitelistRuleName = (commerceType: string): string => {
    switch (commerceType) {
      case SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY:
        return SubTypeEnum.RULE_NAME_WHITELIST_WITHOUT_HIERARCHY;
      case SubTypeEnum.COMMERCE_WITH_HIERARCHY:
        return SubTypeEnum.RULE_NAME_WHITELIST_WITH_HIERARCHY;
      default:
        return SubTypeEnum.RULE_NAME_WHITELIST_COUNTRY;
    }
  };

  const handleCancelModal = (): void => {
    dispatch(setOpenConfirmModal(false));
  };

  const handleCloseAlert = (isError: boolean): void => {
    dispatch(setOpenAlert({ open: false, isError }));
  };

  useEffect(() => {
    if (!isFormEditMode(id)) {
      dispatch(getCountries());
    }
    dispatch(getGeographyList());
  }, []);

  const handleCancelAction = (): void => {
    history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);
    if (isFormEditMode(id)) {
      dispatch(setOpenModalInfo(false));
      dispatch(setSelectedTabIndex(indexTab));
    }
  };

  useEffect(() => {
    setBranchValue(get(hierarchyNodesValues, "branchNodesCount", 0));
    setCustomerValue(get(hierarchyNodesValues, "customerNodesCount", 0));
    if (get(ruleDetails, "entityName") === GroupEnum.BRANCH) setBranchValue(1);
  }, [hierarchyNodesValues, ruleDetails]);

  useEffect(() => {
    if (isFormEditMode(id)) {
      get(window.history.state, "tabName", "") ===
        LabelEnum.REJECTED_APPLICATIONS ||
      indexTab === TabIndexEnum.REJECTED_APPLICATIONS_INDEX
        ? dispatch(searchRequestRuleById(id))
        : dispatch(searchRuleById(id));
    }
  }, [id]);

  useEffect(() => {
    if (searchRequestRuleByIdResponse) {
      const generatedCondition: IConditionForm[] = [];
      const ruleSchema: RulesSchema = RulesSchema.RULEGENERAL;

      buildGenerateConditions(
        searchRequestRuleByIdResponse,
        generatedCondition,
        ruleSchema
      );

      const formObjectReset: ISecurityRuleForm = buildObjectToResetForm(
        searchRequestRuleByIdResponse,
        generatedCondition,
        [],
        id,
        ruleSchema,
        undefined,
        undefined,
        undefined,
        HierarchyPathsEnum.SECURITY_RULE
      );

      form.reset({
        ...formObjectReset,
      });
    }
  }, [searchRequestRuleByIdResponse]);

  useEffect(() => {
    if (
      searchRuleByIdData &&
      isEqual(
        get(searchRuleByIdData, "rule.type", ""),
        ConfigurationTypeEnum.WHITELIST
      )
    ) {
      const generatedCondition: IConditionForm[] = [];
      const ruleSchema: RulesSchema = RulesSchema.RULEMONITOR;

      buildGenerateConditions(
        searchRuleByIdData,
        generatedCondition,
        ruleSchema
      );

      const formObjectReset: ISecurityRuleForm = buildObjectToResetForm(
        searchRuleByIdData,
        generatedCondition,
        [],
        id,
        ruleSchema,
        undefined,
        undefined,
        undefined,
        HierarchyPathsEnum.SECURITY_RULE
      );

      const sub_type: string =
        TEXT_TRANSFORM_COLUMNS[get(searchRuleByIdData, "rule.rule.name", "")];
      const is_commerce: boolean = isEqual(sub_type, ServiceLabelEnum.COMMERCE);
      formObjectReset.subType = is_commerce
        ? TableValueEnum.COMMERCE
        : TableValueEnum.COUNTRY;

      if (
        !isEmpty(get(configurationValue, "conditions", [])) &&
        !isEmpty(
          get(
            searchRuleByIdData.rule,
            "entityName",
            GroupEnum.WITHOUT_HIERARCHY
          )
        )
      ) {
        formObjectReset.condition.forEach((item) => {
          const bool: boolean = WHITELIST_CONDITIONS.includes(item.label);

          if (!Boolean(bool)) item.label = "";
        });
      }

      form.reset({ ...formObjectReset });
    }
  }, [searchRuleByIdData, configurationValue]);

  return {
    form,
    actions: {
      handleCancelAction,
    },
    handle: {
      handleCloseAlert,
      handleCancelModal,
      handleSendToReview,
    },
    fields,
    id,
    branchValue,
    customerValue,
    isLoading,
  };
};
