import { createNamedStyles } from "../../shared/utils/create_named_styles";

export const merchantBasicInfoStyles = createNamedStyles({
  iconEffect: {
    color: "#023365",
    "&:hover": {
      backgroundColor: "#ECF0F1",
    },
    "&:active": {
      backgroundColor: "#F7F9F9",
    },
  },
});
