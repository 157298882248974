import { Boardmembers } from "../../../../../../types/get_compliance_people_response";
import { personTypeEnum } from "../../../../../shared/enums/personType";
import {
  BoardMemberLabel,
  FormatDateEnum,
} from "../../../../../shared/enums/BoardMermbersEnum";
import { get } from "lodash";
import { format, parseISO } from "date-fns";
import { IUseBoardMemberContent } from "./useBoardmembersContent.interfaces";
import { DOCUMENT_TYPE_CATALOG } from "../../../../../shared/catalogs/documentTypeCatalog";

export const useBoardMemberContent = (
  boardMember: Boardmembers
): IUseBoardMemberContent => {
  const isPersonTypeNatural = boardMember.personType === personTypeEnum.NATURAL;

  const formatDateBirthdate = (date: string): string => {
    const parseDate = parseISO(date);

    return format(new Date(parseDate), FormatDateEnum.DD_MM_YYYY);
  };

  const formatDate = (timestamp: number) => {
    return format(new Date(timestamp), FormatDateEnum.DD_MM_YYYY);
  };

  const getDocumentType = (): string =>
    DOCUMENT_TYPE_CATALOG[get(boardMember, "documentType", "")] ?? "";

  return {
    country: isPersonTypeNatural
      ? get(boardMember, "residencyCountry", "")
      : get(boardMember, "addressCountry", ""),
    countryLabel: isPersonTypeNatural
      ? BoardMemberLabel.COUNTRY_NATURAL
      : BoardMemberLabel.COUNTRY_LEGAL,
    date: isPersonTypeNatural
      ? formatDateBirthdate(get(boardMember, "birthdate", ""))
      : formatDate(get(boardMember, "constitutionDate", 0)),
    dateLabel: isPersonTypeNatural
      ? BoardMemberLabel.DATE_NATURAL
      : BoardMemberLabel.DATE_LEGAL,
    document: isPersonTypeNatural
      ? `${getDocumentType()} ${get(boardMember, "documentNumber", "")}`
      : get(boardMember, "documentNumber", ""),
    documentLabel: BoardMemberLabel.DOCUMENT,
  };
};
