import React from "react";
import { WrapperLabelEnum } from "../../shared/enums/WrapperLabelEnum";
import { TableInfo } from "../commons/TableInfo/TableInfo";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useCustomerWebhooks } from "./state/useCustomerWebhooks";
import { customerWebhooksStyles as styles } from "./CustomerWebhooks.styles";
import { WebhookConfig } from "../WebhookConfig/WebhookConfig";
import { ICustomerWebHooks } from "./CustomerWebhooks.interface";
import { ALL_WEBHOOKS_BUTTON_LABEL } from "../../shared/constants/labels/webhook_form_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ICustomerWebhooksTable } from "./state/useCustomerWebhooks.interfaces";

export const CustomerWebhooks: React.FC<ICustomerWebHooks> = ({
  mode,
  section,
}) => {
  const {
    handleRedirectOnClick,
    isEditing,
    table: { columns, rows, onRenderValue },
  } = useCustomerWebhooks(mode);

  return (
    <>
      <Box sx={styles.boxEdit}>
        <Box sx={styles.boxAddWebhook}>
          <Box sx={styles.box}>
            <Typography variant="body1" sx={styles.titleMain}>
              {WrapperLabelEnum.TITLE}
            </Typography>
          </Box>
          {rows.length > 0 && (
            <Box>
              <ComponentSecurityWrapper>
                <Button
                  className="buttonLink"
                  color="primary"
                  onClick={handleRedirectOnClick}
                  sx={styles.buttonTitle}
                  data-testid={"add-webhook"}
                >
                  {ALL_WEBHOOKS_BUTTON_LABEL}
                </Button>
              </ComponentSecurityWrapper>
            </Box>
          )}
        </Box>
        <Divider sx={styles.divider} />
      </Box>
      <Box sx={styles.boxMain}>
        <Typography variant="subtitle2" sx={styles.title}>
          {WrapperLabelEnum.SUBTITLE}
        </Typography>
        {rows.length > 0 ? (
          <Box overflow={"auto"}>
            <TableInfo<ICustomerWebhooksTable>
              columns={columns}
              rows={rows}
              onRenderValue={onRenderValue}
            />
          </Box>
        ) : (
          <WebhookConfig
            title={WrapperLabelEnum.WEBHOOK_TITLE}
            rows={rows.length}
            isEditing={isEditing}
            section={section}
          />
        )}
      </Box>
    </>
  );
};
