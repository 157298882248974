import { ErrorMessageEnum } from "../enum/error_messages";
import { validatePhoneNumber } from "../utils/billing_data_utils";

export enum RegularExp {
  CAPITAL_LETTER = "^[A-Z\\s]+$",
  CAPITAL_LETTER_NUMBERS = "[A-Z0-9 ]+$",
  ONLY_LETTERS = "^[a-zA-ZñÑÁÉÍÓÚáéíóúÜü]*$",
  ONLY_NUMBERS = "^[0-9]*$",
  DEFAULT = "^[a-zA-Z0-9ñÑÁÉÍÓÚáéíóúÜü ]*$",
  ALFANUMERIC = "^[a-zA-Z0-9 ]*$",
  EMAIL = "^[a-zA-z0-9@._]*$",
  PERCENTAGE = "^([1-9][0-9]?){1,2}([.][0-9]{1,2})?$|^100$",
  WEB = "[^]",
  LETTERS_SPACES_ACCENTS = "^[a-zA-Z0-9ñÑÁÉÍÓÚáéíóúÜü ]*$",
  EMAIL_NO_ACCENT = "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$",
  ONLY_LETTERS_CITY = "^[a-zA-ZñÑÁÉÍÓÚáéíóúÜü ]*$",
}

export const physicalPersonRules = {
  birthdate: {
    required: {
      message: ErrorMessageEnum.BIRTHDATE,
      value: true,
    },
  },
  constitutionDate: {
    required: {
      message: ErrorMessageEnum.CONSTITUTION_DATE,
      value: true,
    },
  },
  country: {
    required: {
      message: ErrorMessageEnum.COUNTRY_TWO,
      value: true,
    },
  },
  country_moral: {
    required: {
      message: ErrorMessageEnum.COUNTRY_MORAL,
      value: true,
    },
  },
  countryOrigin: {
    required: {
      message: ErrorMessageEnum.COUNTRY,
      value: true,
    },
  },
  countryResidence: {
    required: {
      message: ErrorMessageEnum.RESIDENCE,
      value: true,
    },
  },
  date: {
    required: {
      message: ErrorMessageEnum.CONSTITUTION_DATE,
      value: true,
    },
  },
  documentNumber: {
    maxLength: {
      message: `${ErrorMessageEnum.DOCUMENT_NUMBER_MAX_MIN} 7 y 18 caracteres`,
      value: 18,
    },
    minLength: {
      message: `${ErrorMessageEnum.DOCUMENT_NUMBER_MAX_MIN} 7 y 18 caracteres`,
      value: 7,
    },
    pattern: {
      message: ErrorMessageEnum.CHARCTERS,
      value: new RegExp(RegularExp.DEFAULT),
    },
    required: {
      message: ErrorMessageEnum.DOCUMENT_NUMBER_REQUIRED,
      value: true,
    },
  },
  documentNumberMoral: {
    pattern: {
      message: ErrorMessageEnum.TAX_ID_VALID,
      value: new RegExp(RegularExp.ALFANUMERIC),
    },
    required: {
      message: ErrorMessageEnum.DOCUMENT_NUMBER_MORAL,
      value: true,
    },
  },
  documentNumberNumeric: {
    maxLength: {
      message: `${ErrorMessageEnum.DOCUMENT_NUMBER_MAX_MIN} 7 y 10 caracteres`,
      value: 10,
    },
    minLength: {
      message: `${ErrorMessageEnum.DOCUMENT_NUMBER_MAX_MIN} 7 y 10 caracteres`,
      value: 7,
    },
    pattern: {
      message: ErrorMessageEnum.NUMBERS,
      value: new RegExp(RegularExp.ONLY_NUMBERS),
    },
    required: {
      message: ErrorMessageEnum.DOCUMENT_NUMBER_REQUIRED,
      value: true,
    },
  },
  documentNumberPhysical: {
    pattern: {
      message: ErrorMessageEnum.DOCUMENT_NUMBER_VALID,
      value: new RegExp(RegularExp.ALFANUMERIC),
    },
    required: {
      message: ErrorMessageEnum.DOCUMENT_NUMBER_REQUIRED,
      value: true,
    },
  },
  documentType: {
    required: {
      message: ErrorMessageEnum.DOCUMENT_TYPE,
      value: true,
    },
  },
  email: {
    pattern: {
      message: ErrorMessageEnum.EMAIL,
      value: new RegExp(RegularExp.EMAIL_NO_ACCENT),
    },
    required: {
      message: ErrorMessageEnum.EMAIL_REQUIRED,
      value: true,
    },
  },
  lastName: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 50`,
      value: 50,
    },
    minLength: {
      message: `${ErrorMessageEnum.MIN_CHARACTERS} 1`,
      value: 1,
    },
    pattern: {
      message: ErrorMessageEnum.CHARCTERS,
      value: new RegExp(RegularExp.DEFAULT),
    },
    required: {
      message: ErrorMessageEnum.LAST_NAME_REQUIRED,
      value: true,
    },
  },
  nacionality: {
    required: {
      message: ErrorMessageEnum.NACIONALITY,
      value: false,
    },
  },
  nameField: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 50`,
      value: 50,
    },
    minLength: {
      message: `${ErrorMessageEnum.MIN_CHARACTERS} 1`,
      value: 1,
    },
    pattern: {
      message: ErrorMessageEnum.CHARCTERS,
      value: new RegExp(RegularExp.DEFAULT),
    },
    required: {
      message: ErrorMessageEnum.NAME_REQUIRED,
      value: true,
    },
  },
  percentage: {
    pattern: {
      message: ErrorMessageEnum.PERCENTAGE,
      value: new RegExp(RegularExp.PERCENTAGE),
    },
  },
  phone: {
    maxLength: {
      message: `${ErrorMessageEnum.PHONE} 13`,
      value: 17,
    },
    minLength: {
      message: `${ErrorMessageEnum.PHONE} 1`,
      value: 1,
    },
    required: {
      message: ErrorMessageEnum.PHONE,
      value: true,
    },
    validate: validatePhoneNumber.bind(null),
  },
  razonSocial: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 50`,
      value: 50,
    },
    pattern: {
      message: ErrorMessageEnum.CHARCTERS,
      value: new RegExp(RegularExp.DEFAULT),
    },
    required: {
      message: ErrorMessageEnum.RAZON_SOCIAL,
      value: true,
    },
  },
  requiredLastNameRule: {
    required: {
      message: ErrorMessageEnum.LAST_NAME_REQUIRED,
      value: true,
    },
  },
  requiredNameRule: {
    required: {
      message: ErrorMessageEnum.NAME_REQUIRED,
      value: true,
    },
  },
  requiredSocialReason: {
    required: {
      message: ErrorMessageEnum.SOCIAL_REASON_REQUIRED,
      value: true,
    },
  },
  taxNumberPhysical: {
    pattern: {
      message: ErrorMessageEnum.TAX_ID_VALIDATE,
      value: new RegExp(RegularExp.ALFANUMERIC),
    },
    required: {
      message: ErrorMessageEnum.TAX_ID_REQUIRED,
      value: true,
    },
  },
};
