import { DeleteModalInfo } from "../BusinessRules.interfaces";
import { ModalSimple } from "@kushki/connect-ui";
import React from "react";

export interface DeleteRuleProps {
  deleteModalInfo: DeleteModalInfo;
  isBatch?: boolean;
  payoutRule?: boolean;
}

export const DeleteRuleModal: React.FC<DeleteRuleProps> = ({
  deleteModalInfo,
  isBatch,
  payoutRule,
}: DeleteRuleProps) => {
  return (
    <ModalSimple
      buttonPrimary={deleteModalInfo.deleteModal.buttonPrimaryText}
      buttonPrimaryDisabled={false}
      isOpen={deleteModalInfo.deleteModal.isOpen}
      buttonSecondary={deleteModalInfo.deleteModal.buttonSecondaryText}
      descriptionText={deleteModalInfo.deleteModal.descriptionText}
      onClickSecondary={() => {
        deleteModalInfo.closeDeleteRuleModal();
      }}
      onClose={() => {
        deleteModalInfo.closeDeleteRuleModal();
      }}
      titleText={deleteModalInfo.deleteModal.titleText}
      typeModal={1}
      onClickPrimary={() => {
        isBatch
          ? deleteModalInfo.deleteRuleByIDStorage(
              deleteModalInfo.ruleID,
              payoutRule
            )
          : deleteModalInfo.deleteRuleByID(deleteModalInfo.ruleID, payoutRule);
      }}
    />
  );
};
