import { IConfigurationItem } from "../../../types/node_info_response";
import { ConfigurationIdEnum } from "../enums";

export const getSectionInfo = (
  configId: ConfigurationIdEnum,
  configs: IConfigurationItem[]
): IConfigurationItem | undefined => {
  return configs.length != 0
    ? configs?.find((config) => {
        return config.configuration === configId;
      })
    : undefined;
};

export enum statusNodeInfo {
  COMPLETE = "complete",
  PENDING = "pending",
  OMITTED = "omitted",
}
