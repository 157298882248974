import React, { FC } from "react";
import Header from "../../components/Header/Header";
import ModalExit from "../../components/ModalExit/ModalExit";
import SideBar from "../../components/SideBar/SideBar";
import { validateRouteRender } from "../../shared/utils/routeRenderUtils";

const MainContainer: FC = () => {
  const renderCondition: boolean = validateRouteRender();

  return (
    <>
      {renderCondition && (
        <>
          <Header />
          <SideBar />
          <ModalExit />
        </>
      )}
    </>
  );
};

export default MainContainer;
