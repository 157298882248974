/* istanbul ignore file */
import { defaultTo, get } from "lodash";

export class auth {
  public static isAdmin(): boolean {
    return get(auth.getPayload(), "[cognito:groups]", []).includes(
      "BackofficeAdmin"
    );
  }
  public static getPayload(): object {
    return JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));
  }
}
