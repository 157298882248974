import { Typography } from "@mui/material";
import React from "react";
import { ITransactionHistoryDateProps } from "./TransactionHistoryDate.interfaces";
import { styles } from "./TransactionHistoryDate.styles";

const TransactionHistoryDate = ({
  entryDate,
  entryTime,
}: ITransactionHistoryDateProps) => {
  return (
    <>
      <Typography variant="h6" sx={styles.entryDate}>
        {entryDate}
      </Typography>
      <Typography variant="h6" sx={styles.entryTime}>
        {entryTime}
      </Typography>
    </>
  );
};

export default TransactionHistoryDate;
