export enum AccountPropertiesValueEnum {
  BANK_OF_ORIGIN = "bankOfOrigin",
  NAME_BENEFICIARY = "nameBeneficiary",
  BANK_ID = "bankId",
  ACCOUNT_TYPE = "accountType",
  ACCOUNT_NUMBER = "accountNumber",
  INTERBANK_ACCOUNT_CODE = "interbankAccountCode",
  CURRENCY = "currency",
  BANK_NAME = "bankName",
  ROUTING_NUMBER = "routingNumber",
}

export enum AccountPropertiesLabelEnum {
  BANK_OF_ORIGIN = "País origen",
  NAME_BENEFICIARY = "Beneficiario",
  BANK_ID = "Inst. Financiera",
  ACCOUNT_TYPE = "Tipo de cuenta",
  ACCOUNT_NUMBER = "Cuenta bancaria",
  INTERBANK_ACCOUNT_CODE = "CLABE",
  CURRENCY = "Tipo de moneda",
  BANK_NAME = "bankName",
  ROUTING_NUMBER = "Núm. Enrutamiento",
}
