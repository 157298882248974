import React from "react";
import { Filter } from "react-feather";
import { Badge, Button, Paper, Popover, Typography } from "@material-ui/core";
import { Filter as FilterKushki } from "@kushki/frontend-molecules/filter";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import { IOptionFilter } from "../common/BoxFilter/BoxFilter";
import BoxFilter from "./BoxFilter/BoxFilter";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { capitalize, includes } from "lodash";
import useStyles from "./PopoverFilter.styles";
import { PopoverFilterProps } from "./PopoverFilter.interfaces";

export const PopoverFilter: React.FC<PopoverFilterProps> = (
  props: PopoverFilterProps
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilterResponse = (response: IFilterResponse): void => {
    props.handleSetFilters(mapFilterResponse(response.listFilter));
    handleClose();
  };

  const mapFilterResponse = (
    filters: IOptionFilter[]
  ): { [key: string]: boolean } => {
    let result: { [key: string]: boolean } = {};

    filters.map((option) => {
      option.options.map((item) => {
        result[item.key] = item.selected;
      });
    });
    return result;
  };

  const handleClearFilters = (): void => {
    props.filters.map((filter) => {
      filter.options.map((option) => {
        option.selected = false;
      });
    });
    const filters = mapFilterResponse(props.filters);
    props.handleSetFilters(filters);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        className={classes.filterButton}
        aria-describedby={id}
        size={"medium"}
        startIcon={<Filter className={classes.defaultColor} size={20} />}
        variant="outlined"
        onClick={handleClick}
      >
        <Badge
          color="secondary"
          className={classes.defaultColor}
          badgeContent={props.counterFilter}
        >
          <Typography variant="body1">Filtrar</Typography>
        </Badge>
      </Button>

      {includes([CountryValueEnum.Colombia], capitalize(props.country)) ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper style={{ padding: 20 }}>
            <BoxFilter
              filters={props.filters}
              onApplyFilter={handleFilterResponse}
              handleClearFilters={handleClearFilters}
            />
          </Paper>
        </Popover>
      ) : (
        <FilterKushki
          idPopover={id}
          openPopover={open}
          isMobile={false}
          openModal={false}
          anchorPopover={anchorEl}
          handleClearFilters={handleClearFilters}
          className={classes.filterPopover}
          styles={{
            applyButtonStyle: "outlined",
            removeButtonText: "Restablecer",
          }}
          filters={{
            listFilters: {
              filters: props.filters,
              multiple: true,
            },
          }}
          handleApplyFilters={handleFilterResponse}
          handleClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};
