import React, { FC } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { accountPreferencesFormStyles as styles } from "./AccountPreferencesForm.styles";
import {
  ACCOUNT_PREFERENCE_IMAGE_LABEL,
  COLOR_LABEL,
  ERROR_EMPTY_COLOR,
  ERROR_EMPTY_EMAIL,
  ERROR_EMPTY_IMAGE,
  ERROR_EMPTY_PHONE,
  ERROR_EMPTY_TERMS,
  PHONE_PLACE_HOLDER,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SUPPORT_LABEL,
  TERMS_CONDITIONS_LABEL,
} from "../../shared/constants/labels/account_preference_container_labels";
import {
  ColorPicker,
  IconCircleWarn,
  ModalLoader,
  SnackbarAlert,
  TextArea,
  TextFieldEmail,
  TextfieldPhone,
  UploadImage,
} from "@kushki/connect-ui";
import { IAccountPreferenceFormProps } from "./AccountPreferencesForm.interfaces";
import { useAccountPreferences } from "./state/useAccountPreferences";
import { defaultTo, get } from "lodash";
import FooterOptions from "../FooterOptions/FooterOptions";
import { FetchStateEnum } from "../../shared/constants/enums/fetchStateEnum";
import * as MODAL_LABELS from "../../shared/constants/labels/modal_loader_labels";
import { MESSAGE_LABEL } from "../../shared/constants/labels/snackbar_labels";

const AccountPreferencesForm: FC<IAccountPreferenceFormProps> = ({
  title,
}: IAccountPreferenceFormProps) => {
  const {
    disabled,
    onDeleteImage,
    error,
    isValid,
    footerLabels,
    merchantData,
    onCloseSnackBar,
    patchMerchantPreferencesState,
    setEmailChange,
    setImageChange,
    setPhoneChange,
    setTermsChange,
    setColorPrimaryChange,
    setColorSecondaryChange,
    saveMerchantPreference,
    nodeInfo,
  } = useAccountPreferences();

  return (
    <>
      <ModalLoader
        isOpen={patchMerchantPreferencesState === FetchStateEnum.PENDING}
        descriptionText={MODAL_LABELS.DESCRIPTION_LABEL}
        titleText={MODAL_LABELS.TITLE_LABEL(get(nodeInfo, "entityName", ""))}
      />
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={error}
        autoHideDuration={3000}
        onClose={onCloseSnackBar}
      >
        <SnackbarAlert
          variant={"simple"}
          withIcon={true}
          message={MESSAGE_LABEL}
          color={"danger"}
          icon={<IconCircleWarn />}
        />
      </Snackbar>
      <Paper elevation={2} sx={styles.formWrapper}>
        <Box sx={styles.boxContainer}>
          <Typography color="text.primary" variant="h3">
            {title}
          </Typography>
          <Chip label="OPCIONAL" color={"primary"} sx={styles.chip} />
        </Box>
        <Box sx={styles.container}>
          <form>
            <Grid container>
              <Grid item xs={6} md={12} sx={styles.gridImage}>
                <Typography variant="body2" sx={styles.labelSecondary}>
                  {ACCOUNT_PREFERENCE_IMAGE_LABEL}
                </Typography>
                <UploadImage
                  maxHeight={120}
                  maxSize={10}
                  maxWidth={120}
                  onSelectedImage={setImageChange}
                  onDeleteImage={onDeleteImage}
                  isLoading={false}
                  defaultImage={merchantData.image}
                  isDisabled={disabled}
                  error={merchantData.imageError}
                  messageError={
                    merchantData.imageError ? ERROR_EMPTY_IMAGE : undefined
                  }
                />
                <Divider sx={styles.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" sx={styles.labelSecondary}>
                  {COLOR_LABEL}
                </Typography>

                <Grid item container xs={12}>
                  <Grid item xs={6} md={6} sx={styles.gridColor}>
                    <Typography variant="body2" sx={styles.labelColor}>
                      {PRIMARY_COLOR}
                    </Typography>
                    <ColorPicker
                      defaultColorHex={merchantData.colorPrimary}
                      onPickColor={setColorPrimaryChange}
                      isOpen={false}
                      isDisabled={disabled}
                      helperText={
                        merchantData.colorValidation?.primary
                          ? ERROR_EMPTY_COLOR
                          : ""
                      }
                      error={merchantData.colorValidation?.primary}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} sx={styles.gridColor}>
                    <Typography variant="body2" sx={styles.labelColor}>
                      {SECONDARY_COLOR}
                    </Typography>
                    <ColorPicker
                      defaultColorHex={merchantData.colorSecondary}
                      onPickColor={setColorSecondaryChange}
                      isOpen={false}
                      isDisabled={disabled}
                      helperText={
                        merchantData.colorValidation.secondary
                          ? ERROR_EMPTY_COLOR
                          : ""
                      }
                      error={merchantData.colorValidation.secondary}
                    />
                  </Grid>
                </Grid>
                <Divider sx={styles.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={styles.labelSecondary}>
                  {SUPPORT_LABEL}
                </Typography>

                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextFieldEmail
                      items={
                        defaultTo(merchantData.email, []).length > 0
                          ? merchantData.email
                          : []
                      }
                      onItemsChange={setEmailChange}
                      placeholder="Correo Electrónico"
                      helperText={""}
                      errorHelperText={
                        merchantData.emailError ? ERROR_EMPTY_EMAIL : undefined
                      }
                      mailLimit={1}
                      isDisabled={disabled}
                      error={merchantData.emailError}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextfieldPhone
                      placeholder={"Número de teléfono"}
                      onChange={setPhoneChange}
                      isDisabled={disabled}
                      defaultValue={merchantData.phone}
                      error={merchantData.phoneError}
                      helperText={
                        merchantData.phoneError
                          ? ERROR_EMPTY_PHONE
                          : PHONE_PLACE_HOLDER
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={styles.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" sx={styles.labelSecondary}>
                  {TERMS_CONDITIONS_LABEL}
                </Typography>
                <Box sx={styles.boxTextArea}>
                  <TextArea
                    ref={{
                      current: null,
                    }}
                    height={220}
                    maxCounter={500}
                    name="TextArea"
                    onChange={setTermsChange}
                    placeholder="Ingresa los términos y condiciones de la empresa"
                    value={merchantData.terms}
                    error={merchantData.termsError}
                    isDisabled={disabled}
                    helperText={ERROR_EMPTY_TERMS}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
      <Box sx={styles.containerFooter}>
        <FooterOptions
          primaryButton={{
            isDisabled: disabled || !isValid,
            onAction: saveMerchantPreference,
          }}
          label={{
            editDetail: false,
            isHidden: footerLabels === null,
            modify: get(footerLabels, "date", ""),
            text: get(footerLabels, "author", ""),
          }}
        />
      </Box>
    </>
  );
};

export default AccountPreferencesForm;
