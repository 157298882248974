import React from "react";
import {
  Button,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import {
  StatusSemaphore,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { useSelector } from "react-redux";
import { IAppState, StatusStepsState } from "../../../store/reducer";
import { MerchantUsers, User } from "../../../../types/merchant_users";
import { UserSectionSummary } from "../UserSectionSummary/UserSectionSummary";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      margin: theme.spacing(1, 1),
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    copyIcon: {
      marginLeft: "4px",
      cursor: "pointer",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    containedButton: {
      fontSize: "16px",
    },
    container: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    link: {
      cursor: "pointer",
    },
    fixGrid: {
      flexBasis: "inherit",
    },
  })
);

export type IUsersPendingSection = {
  publicMerchantId: string;
  merchantName: string;
  merchantUsers?: MerchantUsers;
  status: StatusSteps;
  changeStepStatus: (step: string, status: StatusSteps) => void;
};

export const UserPendingSection: React.FC<Partial<IUsersPendingSection>> = (
  _: Partial<IUsersPendingSection>
) => {
  const stateProps: Partial<IUsersPendingSection> = useSelector(
    (state: IAppState) => ({
      publicMerchantId: state.merchantAffiliation?.publicMerchantId,
      merchantName: state.merchantAffiliation?.name,
      merchantUsers: state.merchantUsers,
      status: state.steps?.find(
        (step: StatusStepsState) => step.name === StepsEnum.stepUsers
      )?.status,
    })
  );
  const usersMerchantURL: string = `/users/user/?publicMerchantId=${stateProps.publicMerchantId}&merchantName=${stateProps.merchantName}&hideSideBar=true`;
  const classes = useStyles();
  const history = useHistory();
  const shouldShowPending = (
    status?: string,
    merchantUsers?: MerchantUsers
  ) => {
    const users: User[] = get(merchantUsers, "Users", []);
    const isCompleted: boolean = status === StatusSemaphore.complete;
    const isPending: boolean = status === StatusSemaphore.pending;

    return isPending || (isCompleted && isEmpty(users));
  };

  return shouldShowPending(stateProps.status, stateProps.merchantUsers) ? (
    <Grid
      id={"pending-grid"}
      container
      direction={"column"}
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify={"center"}
      alignItems={"center"}
      spacing={1}
    >
      <Grid item xs={12} className={classes.fixGrid}>
        <Typography variant={"h5"} align={"center"} color={"textPrimary"}>
          Aún no has agregado ningún usuario
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Button
          id={"btn-redirect"}
          variant={"contained"}
          color={"primary"}
          onClick={() => history.push(usersMerchantURL)}
        >
          Agregar usuario
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Typography
          variant={"subtitle2"}
          align={"center"}
          color={"textPrimary"}
        >
          Si ingresaste un procesador con tarjeta, debes esperar la confirmación
          del Monitoreo para agregar un usuario.
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <UserSectionSummary />
  );
};
