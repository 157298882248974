import { docTypeUSA } from "./FieldsBasicDataUSA";

export const tipoEmpresaUSA: Array<string> = [
  "Corporation",
  "General Partnership",
  "Limited Partnership",
  "Limited Liability Company",
  "Sub-Chapter S Corporation",
];

export const TypeOfTaxIdListUsa = docTypeUSA.map((item) => item.name);

export enum TypeOfTaxIdUSA {
  SSN = "0",
  EIN = "1",
  ITIN = "2",
}
