import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const branchContainerStyles = createNamedStyles({
  boxContainer: {
    padding: "2rem 3rem",
  },
  gridTitle: {
    paddingBottom: 1.5,
  },
  select: {
    width: "328px",
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 2,
    justifyContent: "space-between",
  },
  divider: {
    borderWidth: "0px thin 0px 0px",
    borderStyle: "solid",
    color: "text.lightGrey",
    marginTop: "2px",
    marginBottom: "2px",
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.7,
  },
  emptyCenter: {
    textAlign: "center",
  },
  gridText: {
    paddingRight: 2,
  },
  error1: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A",
    },
  },
  boxIcon: {
    display: "flex",
    alignItems: "center",
  },
  createCustomerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: "3rem",
  },
  goBackBtn: {
    color: "#4399ED",
  },
});
