import React from "react";
import { Card, CardContent, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: "25.4px",
  },
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  large: {
    width: 36,
    height: 36,
  },
  typo: {
    color: "#6D7781",
    marginBottom: 6,
  },
  headerTitle: {
    marginRight: "2",
    color: "#023365",
    fontSize: "16px",
    marginTop: 2,
  },
  headerSubTitle: {
    marginRight: "2",
    color: "#293036",
    fontSize: "12px",
  },
  headerGrid: {
    textAlign: "left",
  },
  chip: {
    borderRadius: 4,
    background: "#F0F4F9",
  },
  button: {
    backgroundColor: "#F0F4F9",
    borderRadius: 4,
    marginLeft: "20px",
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#F7FAFC",
  },
  link: {
    color: "#1E65AE",
    lineHeight: "170%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    cursor: "pointer",
  },
}));

export interface ITitleSubtitle {
  IndexHref: string;
  title: string;
  subtitle: string;
}

export const TitleSubtitle: React.FC<ITitleSubtitle> = (
  props: React.PropsWithChildren<ITitleSubtitle>
) => {
  const style = useStyles();
  return (
    <React.Fragment>
      <Card className={style.cardContainer} elevation={0}>
        <CardContent>
          <Grid
            container
            className={style.cardContainer}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid container direction={"row"} alignItems={"center"}>
              <Grid item xs={12} style={{ textAlign: "start" }}>
                <Link href={props.IndexHref} color="inherit">
                  <Typography className={style.headerTitle} variant="body1">
                    {props.title}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "12px" }}>
                <Typography className={style.headerSubTitle} variant="body1">
                  {props.subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
