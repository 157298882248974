import React from "react";
import {
  IconDownload,
  IconFileText,
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { modalDownloadFilesStyles as styles } from "@components/molecule/ModalDownloadFiles/ModalDownloadFiles.styles";
import { IModalDownloadFiles } from "@components/molecule/ModalDownloadFiles/ModalDownloadFiles.interfaces";
import { IFileCommentsProps } from "@components/organism/DetailCardBodyAlarms/state/IUseDetailCardBodyAlarms.interfaces";
import { ModalDownloadFilesLabels } from "@shared/constants/labels/modalDownloadFilesLabels";

const ModalDownloadFiles = ({
  showModalFiles,
  handleShowModalFiles,
  detailFilesComment,
  handleDownloadFileComment,
  isLoadingDownload,
}: IModalDownloadFiles) => {
  return (
    <ModalContent
      onClose={handleShowModalFiles}
      isOpen={showModalFiles}
      type={ModalContentTypeEnum.DEFAULT}
      buttonType={ModalContentButtonTypeEnum.PRIMARY}
      buttonAction={handleShowModalFiles}
      descriptionText={""}
      titleText={ModalDownloadFilesLabels.TITLE}
      buttonText={ModalDownloadFilesLabels.BUTTON_TEXT}
    >
      <Box>
        <Typography>{ModalDownloadFilesLabels.DESCRIPTION}</Typography>
        <List>
          {detailFilesComment.map(
            (details: IFileCommentsProps, index: number) => {
              return (
                <>
                  <ListItem key={index}>
                    <ListItemIcon>
                      <IconFileText />
                    </ListItemIcon>
                    <ListItemText
                      sx={styles.listItemText}
                      primary={details.fileName}
                      primaryTypographyProps={{
                        sx: styles.textStyles,
                      }}
                    />
                    <ListItemIcon>
                      <IconButton
                        onClick={() => handleDownloadFileComment(details)}
                        disabled={isLoadingDownload}
                      >
                        <IconDownload />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                </>
              );
            }
          )}
        </List>
      </Box>
    </ModalContent>
  );
};

export default ModalDownloadFiles;
