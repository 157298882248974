import { IUseMassiveStatusEmailsModal } from "../../../shared/interfaces/IUseModalMassiveStatus";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { selectApp } from "../../../store/selectors/selectors";
import {
  setIsOpenMassiveStatusEmailsModal,
  setIsOpenMassiveStatusModal,
} from "../../../store/actions/app.actions";
import { useEffect, useState } from "react";
import { defaultTo, isEqual, isUndefined } from "lodash";
import { ItemEmail } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldEmail/TextFieldEmail.interfaces";
import { updateChargebackStatusMassive } from "../../../store/thunks/chargeback/chargeback.thunks";
import {
  FileMassiveData,
  UpdateChargebackStatusMassiveRequest,
} from "../../../../types/update_chargeback_status_massive_request";

export const useMassiveStatusEmailsModal = (props: {
  massiveStatusFile: FileMassiveData | undefined;
}): IUseMassiveStatusEmailsModal => {
  const dispatch = useAppDispatch();
  const { isOpenMassiveStatusEmailsModal } = useAppSelector(selectApp);
  const [emails, setEmails] = useState<string[]>([]);
  const [isDisabledUploadBtn, setIsDisabledUploadBtn] = useState<boolean>(true);
  const [isDomainEmailErr, setIsDomainEmailErr] = useState<boolean>(false);

  const onCloseModal = (): void => {
    setEmails([]);
    dispatch(setIsOpenMassiveStatusEmailsModal(false));
  };

  const handleReturn = (): void => {
    setEmails([]);
    dispatch(setIsOpenMassiveStatusModal(true));
    dispatch(setIsOpenMassiveStatusEmailsModal(false));
  };

  const onItemsChange = (items: ItemEmail[]): void => {
    setEmails(items.map((email: ItemEmail) => email.mail));
  };

  const handleUpdateChargebackStatusMassive = (): void => {
    if (!isUndefined(props.massiveStatusFile)) {
      const req: UpdateChargebackStatusMassiveRequest = {
        emails,
        file: props.massiveStatusFile,
        userName: defaultTo(localStorage.getItem("username"), ""),
      };

      dispatch(updateChargebackStatusMassive(req));
    }
  };

  useEffect(() => {
    setIsDomainEmailErr(false);
    emails.map((email: string) => {
      const emailParts: string[] = email.split("@");

      if (
        !["kushkipagos.com", "billpocket.com"].includes(
          emailParts[1].toLowerCase()
        )
      )
        setIsDomainEmailErr(true);
    });
  }, [emails]);

  useEffect(() => {
    setIsDisabledUploadBtn(isEqual(emails.length, 0) || isDomainEmailErr);
  }, [isDomainEmailErr, emails]);

  return {
    emails,
    handleReturn,
    handleUpdateChargebackStatusMassive,
    isDisabledUploadBtn,
    isDomainEmailErr,
    isOpen: isOpenMassiveStatusEmailsModal,
    onCloseModal,
    onItemsChange,
    setEmails,
  };
};
