export const MCC_CATALOG = {
  "000": "000 - No Aplica",
  "1520": "1520 - Contratistas Generales - Residenciales y Comerciales",
  "1711": "1711 - Servicios de información de redes informáticas",
  "1740":
    "1740 - Contratistas de albañilería, mampostería, colocación de baldosas, enlucido y aislamiento",
  "1750": "1750 - Contratistas de carpintería",
  "1761": "1761 - Contratistas de techos, revestimientos y trabajos de chapa",
  "1799":
    "1799 - Contratistas comerciales especiales (no clasificados bajo otros epígrafes)",
  "2741": "2741 - Publicaciones e impresiones diversas",
  "2842": "2842 - Preparaciones especiales de limpieza",
  "4011": "4011 - Ferrocarriles",
  "4111":
    "4111 - Tiendas de alimentos misceláneas: tiendas de conveniencia y mercados especializados",
  "4112": "4112 - Ferrocarriles de pasajeros",
  "4119": "4119 - Servicios de ambulancia",
  "4121": "4121 - Taxis y limusinas",
  "4131": "4131 - Líneas de bus",
  "4214":
    "4214 - Autotransportistas y camiones: locales y de larga distancia, empresas de mudanzas y almacenamiento, y servicios de entrega local",
  "4215": "4215 - Servicios de mensajería - Aéreos, terrestres y transitarios.",
  "4225": "4225 - Almacenamiento y depósito públicos: productos agrícolas",
  "4411": "4411 - Buques de vapor y cruceros",
  "4457": "4457 - Alquiler y arrendamiento de barcos",
  "4468": "4468 - Puertos deportivos, servicio marítimo y suministros",
  "4511":
    "4511 - Aerolíneas y transportistas aéreos (no clasificados en otra parte)",
  "4582": "4582 - Aeropuertos, campos de vuelo y terminales aeroportuarias",
  "4722": "4722 - Agencias de viajes y operadores turísticos",
  "4723": "4723 - Operadores turísticos de paquetes: solo en Alemania",
  "4784": "4784 - Peajes y tarifas de puentes",
  "4789": "4789 - Servicios de transporte (no clasificados en otra parte)",
  "4812": "4812 - Venta de equipos de telecomunicaciones y telefonía",
  "4814":
    "4814 - Servicios de telecomunicaciones, incluidas llamadas locales y de larga distancia, llamadas con tarjeta de crédito, llamadas mediante el uso de teléfonos con lectura de banda magnética y servicio de fax",
  "4816": "4816 - Servicios de información de redes informáticas",
  "4821": "4821 - Servicios de telégrafo",
  "4829": "4829 - Transferencia de dinero",
  "4899":
    "4899 - Cable, satélite y otros servicios de televisión / radio / transmisión de pago",
  "4900": "4900 - Servicios públicos: electricidad, gas, agua y sanitarios",
  "5013": "5013 - Suministros de vehículos de motor y piezas nuevas",
  "5021": "5021 - Mobiliario de oficina y comercial ...",
  "5039": "5039 - Materiales de construcción (no clasificados en otra parte) ",
  "5044":
    "5044 - Equipo y suministros fotográficos, de fotocopias y microfilmes",
  "5045": "5045 - Computadoras y equipos y software periféricos de computadora",
  "5046": "5046 - Equipo comercial (no clasificado en otra parte)",
  "5047":
    "5047 - Equipos y suministros médicos, dentales, oftálmicos y hospitalarios Medical, Dental, Ophthalmic and Hospital Equipment and Supplies",
  "5051": "5051 - Centros y oficinas de servicios metálicos",
  "5065": "5065 - Piezas y equipos eléctricos",
  "5072": "5072 - Hardware, equipos y suministros",
  "5074": "5074 - Equipos y suministros de fontanería y calefacción",
  "5085": "5085 - Suministros industriales",
  "5094": "5094 - Piedras preciosas y metales, relojería y joyería",
  "5099": "5099 - Tiendas de ropa para deportes y equitación",
  "5111":
    "5111 - Papelería, Material de oficina, Papel de impresión y escritura",
  "5122":
    "5122 - (Drogas, propiedad de medicamentos, artículos diversos de boticario)",
  "5131": "5131 - Productos por pieza, nociones y otros productos secos",
  "5137": "5137 - Uniformes y ropa comercial para hombres, mujeres y niños",
  "5139": "5139 - Calzado comercial ........",
  "5169":
    "5169 - Químicos y productos afines (no clasificados en otra parte) Chemicals and Allied Products (Not Elsewhere Classified)",
  "5172": "5172 - Petróleo y productos derivados del petróleo",
  "5189":
    "5189 - Productos químicos y productos afines (no clasificados en otra parte)",
  "5192": "5192 - Books, Periodicals and Newspapers",
  "5193": "5193 - Suministros de floristería, material de vivero y flores",
  "5198": "5198 - Pinturas, barnices y suministros",
  "5199": "5199 - Bienes no duraderos (no clasificados en otra parte)",
  "5200": "5200 - Almacén de suministros para el hogar",
  "5211": "5211 - Tiendas de madera y materiales de construcción",
  "5231": "5231 - Tiendas de vidrio",
  "5251": "5251 - Ferreterías",
  "5261": "5261 - Viveros y tiendas de suministros para césped y jardín",
  "5262": "5262 - Mercados",
  "5271": "5271 - Distribuidores de casas móviles",
  "5300": "5300 - Clubes al por mayor",
  "5309": "5309 - Tiendas libres de impuestos",
  "5310": "5310 - Tiendas de descuento",
  "5311": "5311 - Grandes almacenes",
  "5331": "5331 - Tiendas de variedades",
  "5399": "5399 - Mercancía general miscelánea",
  "5411": "5411 - Tiendas de abarrotes y supermercados",
  "5422": "5422 - Suministradores de carne para congeladores y casilleros",
  "5441": "5441 - Tiendas de dulces, nueces y confitería",
  "5451": "5451 - Tiendas de productos lácteos",
  "5462": "5462 - Panaderías",
  "5499":
    "5499 - Tiendas de alimentos misceláneas: tiendas de conveniencia y mercados especializados",
  "5511":
    "5511 - Concesionarios de automóviles y camiones (nuevos y usados) Ventas, servicio, reparaciones, repuestos y arrendamiento",
  "5521":
    "5521 - Concesionarios de automóviles y camiones (solo usados) Ventas, servicio, reparaciones, repuestos y arrendamiento",
  "5532": "5532 - Tiendas de neumáticos para automóviles",
  "5533": "5533 - Tiendas de repuestos y accesorios automotrices",
  "5541": "5541 - Estaciones de servicio (con o sin servicios auxiliares",
  "5542": "5542 - Dispensadores de combustible automatizados",
  "5551": "5551 - Concesionarios de barcos",
  "5561":
    "5561 - Concesionarios de caravanas, remolques recreativos y utilitarios",
  "5571": "5571 - Tiendas y concesionarios de motocicletas",
  "5592": "5592 - Concesionarios de casas rodantes",
  "5598": "5598 - Concesionarios de motos de nieve",
  "5599":
    "5599 - Distribuidores diversos de maquinaria automotriz, aeronáutica y agrícola (no clasificados en otra parte)",
  "5611": "5611 - Tiendas de ropa y accesorios para hombres y niños",
  "5621": "5621 - Tiendas de prêt-à-porter para mujeres",
  "5631": "5631 - Tiendas especializadas y de accesorios para mujeres",
  "5641": "5641 - Tiendas de ropa para niños y bebés",
  "5651": "5651 - Tiendas de ropa familiar",
  "5655": "5655 - Tiendas de ropa para deportes y equitación",
  "5661": "5661 - Zapatería",
  "5681": "5681 - Peleteros y peleterías",
  "5691": "5691 - Tiendas de ropa para hombres y mujeres",
  "5697": "5697 - Sastres, costureras, arreglos y reformas",
  "5698": "5698 - Tiendas de pelucas y peluquines",
  "5699": "5699 - Tiendas diversas de ropa y accesorios",
  "5712":
    "5712 - Tiendas de muebles, muebles para el hogar y equipos, excepto electrodomésticos",
  "5713": "5713 - Tiendas de revestimientos de suelos",
  "5714": "5714 - Tiendas de cortinas, cortinas y tapicerías",
  "5718": "5718 - Tiendas de Chimeneas, Pantallas para Chimeneas y Accesorios",
  "5719": "5719 - Varios muebles para el hogar",
  "5722": "5722 - Tiendas de electrodomésticos",
  "5732": "5732 - Tiendas de equipos electricos",
  "5733":
    "5733 - Tiendas de música: instrumentos musicales, pianos y partituras Music Stores ",
  "5734": "5734 - Tiendas de software informático",
  "5735": "5735 - Tiendas de discos",
  "5811": "5811 - Catering",
  "5812": "5812 - Lugares para comer y restaurantes",
  "5813":
    "5813 - Lugares para beber (bebidas alcohólicas): bares, tabernas, clubes nocturnos, salones de cócteles y discotecas",
  "5814": "5814 - Restaurantes de comida rápida",
  "5815": "5815 - Medios de productos digitales: libros, películas, música",
  "5816": "5816 - Bienes digitales - Juegos",
  "5817": "5817 - Productos digitales: aplicaciones (no incluye juegos)",
  "5818": "5818 - Bienes digitales: gran comerciante de bienes digitales",
  "5912": "5912 - Droguerías y farmacias",
  "5921": "5921 - Tiendas de paquetes: cerveza",
  "5931": "5931 - Mercancía usada y tiendas de segunda mano",
  "5932":
    "5932 - Tiendas de antigüedades: servicios de venta, reparación y restauración",
  "5933": "5933 - Casas de Empeño",
  "5935": "5935 - Astilleros de demolición y salvamento",
  "5937": "5937 - Reproducciones antiguas",
  "5940": "5940 - Tiendas de bicicletas: venta y servicio",
  "5941": "5941 - Tiendas de artículos deportivos",
  "5942": "5942 - Librerias",
  "5943": "5943 - Papelerías",
  "5944": "5944 - joyerías, relojería, relojería y platería ",
  "5945":
    "5945 - Clubes de membresía (deportes, Recreación, Atlético), Clubes de campo, y campos de golf privados",
  "5946": "5946 - Tiendas de suministros fotográficos y de cámaras",
  "5947": "5947 - Tiendas de regalos",
  "5948": "5948 - Tiendas de equipaje y marroquinería",
  "5949": "5949 - Tiendas de costura, costura, telas y piezas.",
  "5950": "5950 - Cristalería / Tiendas de cristal ...",
  "5960": "5960 - Marketing directo - Servicios de seguros",
  "5962": "5962 - Servicios de organización relacionados con viajes",
  "5963": "5963 - Ventas puerta a puerta",
  "5964": "5964 - Marketing directo: comerciante de catálogos",
  "5965":
    "5965 - Marketing directo: catálogo combinado y comerciante minorista",
  "5966": "5966 - Marketing directo - Merchan de telemarketing saliente",
  "5967": "5967 - Marketing directo: comerciante de teleservicios entrantes",
  "5968": "5968 - Marketing directo: comerciante de continuidad / suscripción",
  "5969":
    "5969 - Marketing directo: otros comercializadores directos (no clasificados en otras categorías)",
  "5970": "5970 - Tiendas de artesanía y suministros para artistas",
  "5971": "5971 - Galerías y marchantes de arte",
  "5972": "5972 - Tiendas de sellos y monedas",
  "5973": "5973 - Tiendas de artículos religiosos",
  "5975": "5975 - Audífonos: ventas, servicio y suministro",
  "5976": "5976 - Artículos ortopédicos: dispositivos protésicos",
  "5977": "5977 - Tiendas de cosméticos",
  "5978":
    "5978 - Tiendas de máquinas de escribir: ventas, alquileres y servicio",
  "5983":
    "5983 - Distribuidores de combustible: fueloil, madera, carbón y petróleo licuado",
  "5992": "5992 - Floristas",
  "5993": "5993 - Tiendas de cigarros y puestos",
  "5994": "5994 - Distribuidores de noticias y quioscos",
  "5995": "5995 - Tiendas de mascotas",
  "5996": "5996 - Piscinas: venta y servicio",
  "5997":
    "5997 - Tiendas de maquinillas de afeitar eléctricas: ventas y servicio",
  "5998": "5998 - Tiendas de carpas y toldos",
  "5999": "5999 - Tiendas minoristas diversas y especializadas",
  "6010": "6010 - Instituciones financieras: desembolsos de efectivo manuales",
  "6011":
    "6011 - Instituciones financieras: desembolsos de efectivo automatizados",
  "6012":
    "6012 - Instituciones financieras: mercadería, servicios y pago de deudas",
  "6051":
    "6051 - Instituciones no financieras - Moneda extranjera, no fiat   Moneda (por ejemplo: criptomoneda), giros postales (no   Transferencia de dinero), Financiamiento de la cuenta (no carga de valor almacenado), Cheques de viajero y Pago de deudas",
  "6211": "6211 - Corredores / distribuidores de seguridad (",
  "6300": "6300 - Ventas, suscripción y primas de seguros",
  "6513": "6513 - Agentes y administradores inmobiliarios",
  "6540":
    "6540 - Instituciones no financieras: compra / carga con tarjeta de valor almacenado",
  "7011":
    "7011 - Alojamiento: hoteles, moteles, centros turísticos, sistemas centrales de reserva (no clasificados en otra parte)",
  "7012": "7012 - Tiempos compartidos",
  "7032": "7032 - Campamentos deportivos y recreativos",
  "7033": "7033 - Parques de remolques y campamentos",
  "7210": "7210 - Servicios de lavandería, limpieza y confección",
  "7211": "7211 - Lavanderías - Familiares y Comerciales",
  "7216": "7216 - Tintorerías",
  "7217": "7217 - Limpieza de alfombras y tapizados",
  "7221": "7221 - Estudios Fotográficos",
  "7230": "7230 - Peluquerías y peluquerías",
  "7251":
    "7251 - Talleres de reparación de calzado, lustrabotas y tiendas de limpieza de sombreros",
  "7261": "7261 - Servicios Funerarios",
  "7273": "7273 - Servicios de citas",
  "7276": "7276 - Servicios de preparación de impuestos",
  "7277":
    "7277 - Servicios de asesoramiento: deudas, matrimoniales y personales",
  "7278": "7278 - Compras y servicios y clubes de compras",
  "7296": "7296 - Alquiler de ropa: disfraces, uniformes, ropa formal",
  "7297": "7297 - Salones de masaje",
  "7298": "7298 - Spas de salud y belleza",
  "7299": "7299 - Servicios: filtración de agua, purificación, ablandamiento",
  "7311": "7311 - Publicidad",
  "7321": "7321 - Agencias de informes crediticios del consumidor",
  "7333": "7333 - Fotografía comercial, arte y gráficos",
  "7338": "7338 - Servicios de copia rápida, reproducción y planos",
  "7339": "7339 - Apoyo taquigráfico y de secretaría",
  "7342": "7342 - Servicios de exterminio y desinfección",
  "7349": "7349 - Servicios de limpieza, mantenimiento y limpieza",
  "7361": "7361 - Agencias de empleo y servicios de ayuda temporal",
  "7372":
    "7372 - Servicios de programación informática, procesamiento de datos y diseño de sistemas integrados",
  "7375": "7375 - Servicios de recuperación de información",
  "7379":
    "7379 - Servicios, reparación y mantenimiento de computadoras (no clasificados en otra parte)",
  "7392": "7392 - Servicios de gestión, consultoría y relaciones públicas",
  "7393":
    "7393 - Agencias de detectives, servicios de protección y servicios de seguridad, incluidos vehículos blindados y perros guardianes",
  "7394":
    "7394 - Alquiler y arrendamiento de equipos, herramientas, muebles y electrodomésticos",
  "7395": "7395 - Laboratorios de fotoacabado y revelado fotográfico",
  "7399": "7399 - Servicios comerciales (no clasificados en otra parte)",
  "7512": "7512 - Agencia de alquiler de automóviles",
  "7513": "7513 - Alquiler de camiones y remolques utilitarios",
  "7519": "7519 - Alquiler de casas rodantes y vehículos recreativos",
  "7523": "7523 - Estacionamientos, parquímetros y garajes",
  "7531": "7531 - Talleres de reparación de carrocerías de automóviles",
  "7534":
    "7534 - Talleres de reparación y recauchutado de neumáticos - Tire Retreading and Repair Shops",
  "7535": "7535 - Talleres de pintura automotriz .....",
  "7538": "7538 - Talleres de servicio automotriz (no distribuidor)",
  "7542": "7542 - Lavados de autos",
  "7549": "7549 - Servicios de remolque",
  "7622": "7622 - Talleres de reparación de electrónica",
  "7623": "7623 - Talleres de reparación de aire acondicionado y refrigeración",
  "7629":
    "7629 - Talleres de reparación de electrodomésticos pequeños y eléctricos",
  "7631": "7631 - Reparación de relojería, relojería y joyería",
  "7641": "7641 - Mobiliario: tapicería, reparación y acabado",
  "7692": "7692 - Servicios de soldadura",
  "7699": "7699 - Talleres de reparación diversos y servicios relacionados",
  "7800": "7800 - Juegos de apuestas: loterías propiedad del gobierno",
  "7801":
    "7801 - Casinos en línea con licencia del gobierno (juegos de apuestas en línea)",
  "7802":
    "7802 - Carreras de caballos / perros con licencia del gobierno (solo en la región de EE. UU.)",
  "7829": "7829 - Producción y distribución de películas y cintas de video",
  "7832": "7832 - Teatros cinematográficos",
  "7841": "7841 - Tiendas de alquiler de cintas de vídeo / DVD",
  "7911": "7911 - Salones de baile, estudios y escuelas",
  "7922":
    "7922 - Productores teatrales (excepto películas cinematográficas) y agencias de venta de entradas",
  "7929":
    "7929 - Bandas, orquestas y animadores diversos (no clasificados bajo otros epígrafes)",
  "7932": "7932 - Establecimientos de billar y piscina",
  "7933": "7933 - Bolos",
  "7941":
    "7941 - Deportes comerciales, clubes deportivos profesionales, campos deportivos y promotores deportivos",
  "7991": "7991 - Atracciones turísticas y exhibiciones",
  "7992": "7992 - Campos de golf públicos",
  "7993": "7993 - Suministros de videojuegos de entretenimiento",
  "7994": "7994 - Salones / Establecimientos de videojuegos",
  "7995":
    "7995 - Apuestas, incluidos billetes de lotería, fichas de juegos de casino, apuestas fuera de pista, apuestas en hipódromos y juegos de azar para ganar premios de valor monetario",
  "7996": "7996 - Parques de atracciones, circos, carnavales y adivinos",
  "7997":
    "7997 - Clubes de membresía (deportes, recreación, atletismo), clubes de campo y campos de golf privados",
  "7998": "7998 - Acuarios, acuarios marinos, delfinarios y zoológicos",
  "7999": "7999 - Servicios de recreación (no clasificados en otra parte)",
  "8011": "8011 - Médicos y médicos (no clasificados bajo otros epígrafes)",
  "8021": "8021 - Dentistas y ortodoncistas",
  "8031": "8031 - Osteópatas",
  "8041": "8041 - Quiroprácticos",
  "8042": "8042 - Optometristas y oftalmólogos",
  "8043": "8043 - Ópticas, artículos ópticos y anteojos",
  "8049": "8049 - Podólogos y podólogos",
  "8050": "8050 - Centros de enfermería y cuidado personal",
  "8062": "8062 - Hospitales",
  "8071": "8071 - Laboratorios médicos y dentales",
  "8099":
    "8099 - Servicios médicos y profesionales de la salud (no clasificados en otra parte)",
  "8111": "8111 - Servicios legales y abogados",
  "8211": "8211 - Escuelas primarias y secundarias",
  "8220":
    "8220 - Colegios, universidades, escuelas profesionales y colegios universitarios",
  "8241": "8241 - Escuelas por correspondencia",
  "8244": "8244 - Escuelas de negocios y secretariado",
  "8249": "8249 - Escuelas vocacionales y comerciales",
  "8299":
    "8299 - Escuelas y servicios educativos (no clasificados en otra parte)",
  "8351": "8351 - Servicios de cuidado infantil",
  "8398": "8398 - Organizaciones benéficas de servicios sociales",
  "8641": "8641 - Asociaciones cívicas, sociales y fraternales",
  "8651": "8651 - Orgamizaciones Politicas",
  "8661": "8661 - Organizaciones Religiosas",
  "8675": "8675 - Asociaciones automovilísticas",
  "8699": "8699 - Otras organizaciones",
  "8734": "8734 - Laboratorios de pruebas (pruebas no médicas)",
  "8911": "8911 - Architectural, Engineering, and Surveying Services",
  "8931": "8931 - Servicios de contabilidad, auditoría y teneduría de libros",
  "8999": "8999 - Servicios profesionales (no clasificados en otra parte)",
  "9211":
    "9211 - Costos judiciales, incluida la pensión alimenticia y la manutención infantil",
  "9222": "9222 - Propósitos",
  "9223": "9223 - Pagos de fianzas y fianzas",
  "9311": "9311 - Pago de impuestos",
  "9399": "9399 - Servicios gubernamentales (no clasificados en otra parte)",
  "9402": "9402 - Servicios postales: solo para el gobierno",
  "9405": "9405 - Agencias o departamentos del gobierno federal de EE. UU.",
  "9406": "9406 - Loterías propiedad del gobierno (región fuera de EE. UU.)",
  "9702": "9702 - Servicios de emergencia (GCAS) (solo para uso de Visa)",
  "9950": "9950 - Compras dentro de la empresa",
  "0742": "0742 - Veterinarias",
  "0780": "0780 - Servicios de jardinería y horticultura",
  "3000-3350": "3000-3350 - MCC de aerolíneas individuales",
};
