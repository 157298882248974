import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { IMassiveStatusActions } from "./massiveStatusActions.interfaces";
import { massiveStatusActionsStyles as styles } from "./massiveStatusActions.styles";
import {
  DESCRIPTION,
  SELECTED_COUNT,
  SELECTED_COUNT_BRANCH,
  STATUS_DESCRIPTION,
  StatusButtonEnum,
} from "../../shared/enums/DashboardEnum";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { ComponentsSecurity } from "../../shared/enums/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const MassiveStatusActions: React.FC<IMassiveStatusActions> = ({
  isActivateStatus,
  entityName,
  selectedItems,
  secondaryButton,
  activeStatusAction,
  inactiveStatusAction,
  showBtnMassiveAction,
  redirectEditBranch,
  isEditBranch,
  isDisabledEditBtn,
  isDisabledActiveBtn,
}: IMassiveStatusActions) => {
  return (
    <Box>
      <Divider sx={styles.divider} />
      <Box sx={styles.container}>
        <Box sx={styles.descriptionWrapper}>
          <Typography variant="caption" sx={styles.description}>
            {DESCRIPTION[entityName]}
            <Box component="span" sx={styles.boldText}>
              {STATUS_DESCRIPTION.ACTIVE}
            </Box>
            {" o "}
            <Box component="span" sx={styles.boldText}>
              {STATUS_DESCRIPTION.INACTIVE}
            </Box>
          </Typography>
          <Typography sx={styles.description} mt={1}>
            {entityName === EntityNameEnum.BRANCH
              ? SELECTED_COUNT_BRANCH
              : SELECTED_COUNT}
            <Box
              component="span"
              sx={styles.boldText}
              data-testid="selectedItemsTotal"
            >
              {selectedItems.length}
            </Box>
          </Typography>
        </Box>
        <Box sx={styles.buttonsWrapper}>
          {isEditBranch && (
            <Box>
              <Button
                disabled={isDisabledEditBtn}
                sx={styles.buttonOutlined}
                variant="outlined"
                onClick={redirectEditBranch}
              >
                {StatusButtonEnum.EDIT}
              </Button>
            </Box>
          )}
          {secondaryButton && (
            <Button
              variant={secondaryButton.variant}
              onClick={secondaryButton.onClick}
            >
              {secondaryButton.text}
            </Button>
          )}
          {showBtnMassiveAction &&
            (isActivateStatus ? (
              <ComponentSecurityWrapper
                componentId={ComponentsSecurity.CUSTOMERS_DESACTIVAR}
              >
                <Button variant="contained" onClick={inactiveStatusAction}>
                  {StatusButtonEnum.INACTIVE}
                </Button>
              </ComponentSecurityWrapper>
            ) : (
              <ComponentSecurityWrapper
                componentId={ComponentsSecurity.CUSTOMERS_ACTIVAR}
              >
                <Button
                  variant="contained"
                  onClick={activeStatusAction}
                  disabled={isDisabledActiveBtn}
                >
                  {StatusButtonEnum.ACTIVE}
                </Button>
              </ComponentSecurityWrapper>
            ))}
        </Box>
      </Box>
      <Divider sx={styles.divider} />
    </Box>
  );
};

export default MassiveStatusActions;
