import { ColorConstants } from "./ColorConstants";
import { BreadcrumbLabelsEnum } from "../enum/BreadcrumbLabelsEnum";
import { IBreadcrumbProps } from "../interfaces/IBreadcrumbProps";
import { CreateMerchantURL, RedirectEnum } from "../enum/RedirectEnum";
import { defaultTo } from "lodash";
import { IMerchantInfo } from "../interfaces/IMerchantInfo";
import { UnderlineEnum } from "../enum/UnderlineEnum";
import { ROUTES } from "./routes";

export const UsersBreadcrumb: IBreadcrumbProps = {
  color: ColorConstants.BLUE,
  label: BreadcrumbLabelsEnum.USERS,
  underline: UnderlineEnum.NONE,
};

export const HomeBreadcrumb: IBreadcrumbProps = {
  label: BreadcrumbLabelsEnum.HOME,
  underline: UnderlineEnum.HOVER,
  url: RedirectEnum.HOME,
};

export const MerchantsBreadcrumb: IBreadcrumbProps = {
  label: BreadcrumbLabelsEnum.MERCHANTS,
  underline: UnderlineEnum.HOVER,
  url: RedirectEnum.MERCHANT_LIST,
};
export const CreateMerchantsListBreadcrumb = (
  isConsole3: boolean
): IBreadcrumbProps => {
  return {
    ...MerchantsBreadcrumb,
    url: isConsole3 ? ROUTES.CLIENTS : MerchantsBreadcrumb.url,
  };
};
export const CreateMerchantBreadcrumb = (
  merchantInfo: IMerchantInfo,
  isConsole3: boolean
): IBreadcrumbProps => {
  const breadcrumbMerchantName: string = isConsole3
    ? merchantInfo.name
    : merchantInfo?.isHideSideBar
    ? BreadcrumbLabelsEnum.CREATE_MERCHANT
    : defaultTo(merchantInfo?.name, "");

  return {
    label: breadcrumbMerchantName,
    underline: UnderlineEnum.HOVER,
    url: CreateMerchantURL(defaultTo(merchantInfo?.merchantId, ""), isConsole3),
  };
};
