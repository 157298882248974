import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
export interface LoaderProps {
  open: boolean;
  message: string;
  message1: string;
  rulesDataCounter: { contador: number; total: number };
  handleClose: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,
    },
    dialogText: {
      padding: 16,
    },
    dialogContentText: {
      marginBottom: 10,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: 16,
      padding: 1,
      textAlign: "center",
    },
    cancelAction: {
      color: theme.palette.error.dark,
    },
  })
);

export const LoaderModal: React.FC<LoaderProps> = (
  props: React.PropsWithChildren<LoaderProps>
) => {
  const classes = useStyles(props);

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <CircularProgress disableShrink size={60} thickness={5} />
          <Typography
            className={classes.dialogText}
            variant="h5"
            color={"primary"}
          >
            {props.message}
            <br />
            {props.message1}
            <p className={classes.text}>
              {props.rulesDataCounter.contador} {" / "}
              {props.rulesDataCounter.total} {" documentos procesados"}{" "}
            </p>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
