import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const UserListResumeStyles = (): CustomStyles => {
  return {
    contentBox: {
      width: "100%",
    },
    paper: {
      borderRadius: "8px",
      padding: "16px 24px",
    },
    paperBox: {
      overflowX: "auto",
    },
    link: {
      color: "#4498EE",
      height: "20px",
      padding: "5px 0px 35px 20px",
      textDecoration: "none",
    },
  };
};
