import { CatalogsEnum } from "../catalogs-enum";
import { Category } from "../constants/MerchantInformationConstants";
import { CurrencyEnum } from "../CurrencyEnum";

export const CatalogMe: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    { value: "37006", name: "BANCOMEXT" },
    { value: "37009", name: "BANOBRAS" },
    { value: "37019", name: "BANJERCITO" },
    { value: "37135", name: "NACIONAL FINANCIERA" },
    { value: "37166", name: "BANSEFI" },
    { value: "37168", name: "HIPOTECARIA FEDERAL" },
    { value: "40002", name: "BANAMEX" },
    { value: "40012", name: "BBVA BANCOMER" },
    { value: "40014", name: "BANCO SANTANDER" },
    { value: "40021", name: "HSBC" },
    { value: "40030", name: "BAJIO" },
    { value: "40032", name: "IXE" },
    { value: "40036", name: "INBURSA" },
    { value: "40037", name: "INTERACCIONES" },
    { value: "40042", name: "MIFEL" },
    { value: "40044", name: "SCOTIA BANK INVERLAT" },
    { value: "40058", name: "BANREGIO" },
    { value: "40059", name: "INVEX" },
    { value: "40060", name: "BANSI" },
    { value: "40062", name: "AFIRME" },
    { value: "40072", name: "BANORTE" },
    { value: "40102", name: "ROYAL BANK OF SCOTLAND" },
    { value: "40103", name: "AMERICAN EXPRESS" },
    { value: "40106", name: "BANK OF AMERICA" },
    { value: "40108", name: "TOKYO" },
    { value: "40110", name: "JP MORGAN" },
    { value: "40112", name: "BANCO MONEX" },
    { value: "40113", name: "VE POR MAS" },
    { value: "40116", name: "ING" },
    { value: "40124", name: "DEUTCHE" },
    { value: "40126", name: "CREDIT SUISSE" },
    { value: "40127", name: "AZTECA" },
    { value: "40128", name: "BANCO AUTOFIN" },
    { value: "40129", name: "BARCLAYS BANK" },
    { value: "40130", name: "BANCO COMPARTAMOS" },
    { value: "40131", name: "BANCO DE AHORRO FAMSA" },
    { value: "40132", name: "BANCO MULTIVA" },
    { value: "40133", name: "PRUDENTIAL BANK" },
    { value: "40134", name: "BANCO WAL MART" },
    { value: "40136", name: "BANCO REGIONAL SA" },
    { value: "40137", name: "BANCOPPEL" },
    { value: "40138", name: "BANCO AMIGO" },
    { value: "40139", name: "UBS BANK MEXICO" },
    { value: "40140", name: "BANCO FACIL" },
    { value: "40141", name: "VOLKSWAGEN BANK" },
    { value: "40143", name: "BANCO CONSULTORIA" },
    { value: "90659", name: "ASP INTEGRA OPC" },
    { value: "40145", name: "BBASE" },
    { value: "40147", name: "BANKAOOL" },
    { value: "40148", name: "PAGATODO" },
    { value: "40150", name: "INMOBILIARIO" },
    { value: "40152", name: "BANCREA" },
    { value: "40154", name: "BANCO FINTERRA" },
    { value: "40155", name: "ICBC" },
    { value: "40156", name: "SABADELL" },
    { value: "40158", name: "MIZUHO BANK" },
    { value: "90600", name: "MONEXCB" },
    { value: "90601", name: "GBM" },
    { value: "90602", name: "MASARI" },
    { value: "90605", name: "VALUE" },
    { value: "90606", name: "ESTRUCTURADORES" },
    { value: "90608", name: "VECTOR" },
    { value: "90613", name: "MULTIVA CBOLSA" },
    { value: "90614", name: "ACCIVAL" },
    { value: "90616", name: "FINAMEX" },
    { value: "90617", name: "VALMEX" },
    { value: "90620", name: "PROFUTURO" },
    { value: "90621", name: "CB ACTINVER" },
    { value: "90623", name: "SKANDIA" },
    { value: "90626", name: "CBDEUTSCHE" },
    { value: "90627", name: "ZURICH" },
    { value: "90628", name: "ZURICHVI" },
    { value: "90630", name: "CB INTERCAM" },
    { value: "90631", name: "CI BOLSA" },
    { value: "90634", name: "FINCOMUN" },
    { value: "90636", name: "HDI SEGUROS" },
    { value: "90637", name: "ORDER" },
    { value: "90638", name: "AKALA" },
    { value: "90640", name: "CB JPMORGAN" },
    { value: "90642", name: "REFORMA" },
    { value: "90646", name: "STP" },
    { value: "90648", name: "EVERCORE" },
    { value: "90649", name: "OSKNDIA" },
    { value: "90652", name: "ASEA" },
    { value: "90653", name: "KUSPIT" },
    { value: "90655", name: "SOFIEXPRESS" },
    { value: "90656", name: "UNAGRA" },
    { value: "90670", name: "LIBERTAD" },
    { value: "90671", name: "HUASTECAS" },
    { value: "90677", name: "CAJA POP MEXICA" },
    { value: "90680", name: "CRISTOBAL COLON" },
    { value: "90681", name: "PRINCIPAL" },
    { value: "90683", name: "CAJA TELEFONIST" },
    { value: "90901", name: "CLS" },
    { value: "90902", name: "INDEVAL" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: CurrencyEnum.MXN, name: "Pesos" }],
  [CatalogsEnum.Taxes]: [{ value: 0.16, name: "16%" }],
};
