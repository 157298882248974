import React from "react";
import { Box } from "@mui/material";
import { DataTable } from "../DataTables/DataTable";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { useContentModalBranchComponentState } from "./state/useContentModalBranchComponentState";

export const ContentModalBranch: React.FC = () => {
  const { searchRuleResponse } = useContentModalBranchComponentState();
  // TODO ESTE COMPONENTE DEBE SER CAMBIADO CUANDO ESTE LA HISTORIA DE LISTADO DE REGLAS

  return (
    <React.Fragment>
      <Box mt={5}>
        <DataTable
          tabName={LabelEnum.BUSINESS_RULES}
          searchRuleResponse={searchRuleResponse}
        />
      </Box>
    </React.Fragment>
  );
};
