export enum NodeInfoPathEnum {
  CONFIGS = "configs",
  INFO_COUNTRY = "generalInfo.country",
  INFO_CONSTITUTIONAL_COUNTRY = "generalInfo.constitutionalCountry",
  INFO_NAME = "generalInfo.name",
  INFO_PUBLIC_MERCHANT_ID = "generalInfo.publicMerchantId",
  MERCHANT_ID = "merchantId",
  NODE_ID = "nodeId",
  COUNTRY_CODE = "countryCode",
}
