import React, { useContext, useEffect, useState } from "react";
import {
  IDataTableComponent,
  IUseDataTableComponent,
} from "../../../shared/infrastructure/interfaces/IGlobalTableComponent";
import { ItemSearchRuleResponse } from "../../../../types/search_rule_response";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import {
  LabelEnum,
  TABLE_COLUMNS,
} from "../../../shared/infrastructure/enums/LabelEnum";
import { TableBodyCellEnum } from "../DataTableComponent";
import { get, isEmpty, pick, set, defaultTo, isEqual, upperCase } from "lodash";
import {
  COLUMNS_BY_TABS,
  COMPONENT_ID_BY_TABS,
  TableFieldsEnum,
  TableValueEnum,
} from "../../../shared/infrastructure/enums/DataTableEnum";
import { IconEdit, useSnackbar } from "@kushki/connect-ui";
import {
  buildOneCell,
  convertTableValues,
  getWhiteListCondition,
} from "../../../utils/utilsFile";
import {
  SecurityContext,
  VerifyIfComponentEnable,
} from "@kushki/security-wrapper";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  getHierarchyNodes,
  getProcessors,
  getRequestById,
  searchRuleById,
  setHierarchyNodes,
  setMerchantHierarchy,
  setMerchantInformation,
  setOpenAlert,
  setOpenConfirmModal,
  setOpenModalInfo,
  setOpenModalRejected,
  setRequestRuleById,
  setSearchRequestRuleById,
  setSearchRuleById,
  updateRuleRequest,
} from "../../../store/actionCreators";
import {
  EndpointsPathsEnum,
  PathsEnum,
  WhiteListConditionPathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import { Country } from "../../../../types/country_response";
import { StylesEnum } from "../../../shared/infrastructure/enums/StylesEnum";
import { IWhiteListCondition } from "../../../shared/infrastructure/interfaces/IWhiteListCondition";
import { IDENTIFIER } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { ConfirmModalProps } from "../../ConfirmModal/ConfirmModal";
import { ConfirmModalEnum } from "../../../shared/infrastructure/enums/ConfirmModalEnum";
import { UpdateRuleRequest } from "../../../../types/update_rule_request";
import { SearchRequestRuleByIdResponse } from "../../../../types/search_request_rule_by_id_response";
import { TabIndexEnum } from "../../../shared/infrastructure/enums/TabIndexEnum";
import { ErrorOutline } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  GroupEnum,
  SubTypeEnum,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { useHistory } from "react-router";
import { HierachyNodesResponse } from "../../../../types/hierachy_nodes_response";
import { MerchantHierarchyResponse } from "../../../../types/merchant_hierarchy_response";
import { useFilterSearch } from "../../../shared/hooks/filter-search/useFilterSearch";

export const useDataTableComponentState = (
  props: IDataTableComponent
): IUseDataTableComponent => {
  const { updatePage, filterSearch } = useFilterSearch();
  const securityContextValue = useContext(SecurityContext);
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const [verifyIfComponentEnable, setVerifyIfComponentEnable] =
    useState<boolean>(
      VerifyIfComponentEnable(
        COMPONENT_ID_BY_TABS[props.tabName],
        securityContextValue
      )
    );

  const isLoading: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoading
  );

  const isLoadingModal: boolean = defaultTo(
    useSelector((state: IAppState) => state.isLoadingModal),
    false
  );

  const isLoadingRules: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingRules
  );

  const countries: Country[] | undefined = useSelector(
    (state: IAppState) => state.countries
  );

  const request: SearchRequestRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.requestRule
  );

  const merchantHierarchy: MerchantHierarchyResponse | undefined = useSelector(
    (state: IAppState) => state.merchantHierarchy
  );

  const hierarchyNodesValues: HierachyNodesResponse | undefined = useSelector(
    (state: IAppState) => state.affectedNodes
  );

  const [openAlert, isError] = useSelector((state: IAppState) => {
    return [
      get(state, "openAlert.open", false),
      get(state, "openAlert.isError", false),
    ];
  });

  const [confirmModalProps, setConfirmModalProps] = useState<ConfirmModalProps>(
    {} as ConfirmModalProps
  );

  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [cells, setCells] = useState<HeaderCellProps[]>(
    TABLE_COLUMNS[props.tabName]
  );
  const [alertSuccessMsg, setAlertSuccessMsg] = useState<string>("");
  const [alertErrorMsg, setAlertErrorMsg] = useState<string>("");

  useEffect(() => {
    handleCellsVisibility();
    transformDataToSchemaRowTable(get(props, PathsEnum.SEARCH_RULE_DATA, []));
  }, [props.searchRuleResponse]);

  useEffect(() => {
    setVerifyIfComponentEnable(
      VerifyIfComponentEnable(
        COMPONENT_ID_BY_TABS[props.tabName],
        securityContextValue
      )
    );
    dispatch(setHierarchyNodes({ branchNodesCount: 0, customerNodesCount: 0 }));
    setConfirmModalData();
  }, [props.tabName, isLoadingModal]);

  useEffect(() => {
    if (openAlert) {
      showSnackbar({
        message: isError ? alertErrorMsg : alertSuccessMsg,
        variant: "simple",
        color: isError ? "danger" : "info",
        icon: isError ? (
          <ErrorOutline
            fontSize={StylesEnum.INHERIT}
            color={StylesEnum.INHERIT}
          />
        ) : (
          <CheckIcon fontSize={StylesEnum.INHERIT} color={StylesEnum.PRIMARY} />
        ),
        withIcon: true,
      });
      dispatch(setOpenAlert({ open: false, isError: false }));
    }
  }, [openAlert]);

  const setConfirmModalData = (): void => {
    switch (props.tabName) {
      case LabelEnum.PENDING_REVIEW:
        setConfirmModalProps({
          setAlertSuccessMsg,
          setAlertErrorMsg,
          modalContentText: ConfirmModalEnum.BODY_APPROVE,
          modalTitle: ConfirmModalEnum.TITLE_APPROVAL_REQUEST,
          acceptButtonText: LabelEnum.BUTTON_APPROVE_RULE,
          cancelButtonText: LabelEnum.BUTTON_CANCEL,
          branch: get(hierarchyNodesValues, "branchNodesCount", 0),
          customer: get(hierarchyNodesValues, "customerNodesCount", 0),
          onClose: () => dispatch(setOpenConfirmModal(false)),
          onSubmit: handleConfirmModalApproval,
          isFormEditMode: false,
          isLoading: isLoadingModal,
          tabName: props.tabName,
        });
        return;
      case LabelEnum.FIRST_LEVEL_RULES:
        setConfirmModalProps({
          setAlertSuccessMsg,
          setAlertErrorMsg,
          modalContentText: ConfirmModalEnum.BODY_DISABLE,
          modalTitle: ConfirmModalEnum.TITLE_DISABLE_REQUEST,
          acceptButtonText: LabelEnum.BUTTON_DISABLE_RULE,
          cancelButtonText: LabelEnum.BUTTON_CANCEL,
          branch: get(merchantHierarchy, "dataBranch.count", 0),
          customer: get(merchantHierarchy, "dataCustomer.count", 0),
          onClose: () => dispatch(setOpenConfirmModal(false)),
          onSubmit: () => dispatch(setOpenModalRejected(true)),
          isFormEditMode: false,
          isLoading: isLoadingModal,
        });
        return;
      case LabelEnum.WHITE_LISTS:
        setConfirmModalProps({
          setAlertSuccessMsg,
          setAlertErrorMsg,
          modalContentText: ConfirmModalEnum.BODY_DISABLE,
          modalTitle: ConfirmModalEnum.TITLE_DISABLE_REQUEST,
          acceptButtonText: LabelEnum.BUTTON_DISABLE_RULE,
          cancelButtonText: LabelEnum.BUTTON_CANCEL,
          branch: get(merchantHierarchy, "dataBranch.count", 0),
          customer: get(merchantHierarchy, "dataCustomer.count", 0),
          onClose: () => dispatch(setOpenConfirmModal(false)),
          onSubmit: () => dispatch(setOpenModalRejected(true)),
          isFormEditMode: false,
          isLoading: isLoadingModal,
        });
        return;
      default:
        return;
    }
  };

  const transformDataToSchemaRowTable = (
    data: ItemSearchRuleResponse[]
  ): void => {
    let tableRowsProps: ITableRowProps[] = [];

    data.forEach((item: ItemSearchRuleResponse) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          id: item.id!,
          rowProps: {
            color: "default",
            onClick: () => {
              dispatch(setOpenModalInfo(true));
              if (
                !isEmpty(get(item, "merchant_id", "")) &&
                !(get(item, "merchant_id", "") === "all")
              )
                dispatch(getProcessors(get(item, "merchant_id", "")));
              handleRequestRuleById(get(item, PathsEnum.ID, ""));
            },
          },
          cells: buildCells(item),
        },
      ];
    });

    setRows(tableRowsProps);
  };

  const buildCells = (item: ItemSearchRuleResponse): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const itemsByTabs: object = pick(item, COLUMNS_BY_TABS[props.tabName]);
    const status: string = defaultTo(
      get(itemsByTabs, PathsEnum.STATUS),
      item.status
    );
    const id: string = get(item, PathsEnum.ID, "");

    set(itemsByTabs, PathsEnum.ACTIONS, getActionsTable(id, item, status));

    Object.keys(itemsByTabs).forEach((key: string) => {
      const itemText: string = get(item, key, "");

      switch (key) {
        case TableFieldsEnum.MERCHANT_NAME:
          const merchantId = get(item, TableFieldsEnum.MERCHANT_ID, "");
          const idMerchant = !isEmpty(merchantId) ? `ID ${merchantId}` : "";
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TITLE, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(
              buildOneCell(TableBodyCellEnum.TITLE, itemText, idMerchant)
            );
          }
          break;
        case TableFieldsEnum.ACTIONS:
          cells.push(get(itemsByTabs, TableFieldsEnum.ACTIONS));
          break;
        case TableFieldsEnum.RULE:
          if (props.tabName === LabelEnum.FIRST_LEVEL_RULES) {
            cells.push(
              buildOneCell(
                TableBodyCellEnum.TEXT,
                get(itemsByTabs, PathsEnum.RULE_NAME)
              )
            );
            cells.push(
              buildOneCell(
                TableBodyCellEnum.TEXT,
                convertTableValues(
                  item,
                  get(itemText, PathsEnum.GENERAL_ACTION)
                )[key]
              )
            );
            return;
          }

          const generalCondition: IWhiteListCondition[] = getWhiteListCondition(
            item,
            IDENTIFIER.TABLE
          );

          cells.push(
            buildOneCell(
              TableBodyCellEnum.TEXT,
              get(generalCondition[0], WhiteListConditionPathsEnum.LABEL, ""),
              undefined,
              {
                align: "center",
                spacing: 0,
              }
            )
          );
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TEXT,
              get(
                generalCondition[0],
                WhiteListConditionPathsEnum.TOTAL,
                0
              ).toString(),
              undefined,
              {
                align: "center",
                spacing: 0,
              }
            )
          );
          break;
        case TableFieldsEnum.STATUS:
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TAG,
              convertTableValues(item, itemText)[key],
              undefined,
              { align: "center", spacing: 0 },
              status,
              props.tabName
            )
          );
          break;
        default:
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TEXT,
              convertTableValues(item, itemText, countries)[key]
            )
          );
          break;
      }
    });

    return cells;
  };

  const getActionsTable = (
    ruleId: string,
    item: ItemSearchRuleResponse,
    status?: string
  ): ITableCellProps => {
    if (verifyIfComponentEnable) {
      switch (props.tabName) {
        case LabelEnum.PENDING_REVIEW:
          return {
            props: {
              cellProps: {
                spacing: 0,
              },
              isDisabled: false,
              optionListItems: [
                {
                  disable: false,
                  onClick: () => {
                    handleApproveRule(ruleId, item);
                  },
                  optionText: LabelEnum.APPROVE_RULE,
                  type: "type1",
                  handleOptionClick: () => {},
                },
                {
                  disable: false,
                  onClick: () => {
                    handleRejectRule(ruleId);
                  },
                  optionText: LabelEnum.REJECT_RULE,
                  type: "type1",
                  handleOptionClick: () => {},
                },
              ],
            },
            type: TableBodyCellEnum.OPTIONS,
          };
        default:
          return {
            props: {
              cellProps: {
                align: "center",
                spacing: 0,
              },
              icon: <IconEdit color={StylesEnum.PRIMARY} />,
              id: ruleId,
              isDisabled: handleDisableEdit(status),
              onSelected: () => {
                if (!handleDisableEdit(status)) {
                  handleEditRule(ruleId, status, item);
                }
              },
              line1: "",
              line2: "",
            },
            type: TableBodyCellEnum.TEXT_ACTION,
          };
      }
    } else {
      return buildOneCell(TableBodyCellEnum.TEXT, "");
    }
  };

  const handleCellsVisibility = (): void => {
    if (!verifyIfComponentEnable) {
      cells[cells.length - 1].text = "";
      setCells(cells);
    }
  };

  const handleSelectedPage = (value: number) => {
    updatePage(value);
  };

  const handleDisableEdit = (status?: string): boolean => {
    return props.tabName === LabelEnum.WHITE_LISTS
      ? false
      : status === StatusEnum.DISABLE;
  };

  const handleRequestRuleById = (id: string) => {
    switch (props.tabName) {
      case LabelEnum.FIRST_LEVEL_RULES:
        dispatch(searchRuleById(id));
        break;
      case LabelEnum.WHITE_LISTS:
        dispatch(searchRuleById(id));
        break;
      default:
        dispatch(getRequestById(EndpointsPathsEnum.RULE_REQUEST_BY_ID, id));
        break;
    }
  };

  const handleEditRule = (
    ruleId: string,
    status: string | undefined,
    item: ItemSearchRuleResponse
  ): void => {
    const route: string =
      get(item, TableFieldsEnum.CONFIG_TYPE, "") ===
        TableValueEnum.WHITE_LIST ||
      get(item, TableFieldsEnum.TYPE, "") === TableValueEnum.WHITE_LIST
        ? handleRouteWhiteList(ruleId)
        : `/rule-request-manager/rules/update/${ruleId}/${status}`;
    dispatch(setSearchRequestRuleById(undefined));
    dispatch(setSearchRuleById(undefined));
    dispatch(setMerchantHierarchy(undefined));
    dispatch(setMerchantInformation(undefined));

    history.push(route);
  };

  const handleRouteWhiteList = (ruleId: string) => {
    return `/rule-request-manager/rules/whitelist/${ruleId}/edition`;
  };

  const handleApproveRule = (
    id: string,
    item: ItemSearchRuleResponse
  ): void => {
    const entityName: string = upperCase(
      get(item, PathsEnum.ENTITY_NAME_ELASTIC, "")
    );
    const subType: string = get(item, PathsEnum.SUB_TYPE_ELASTIC, "");
    if (
      isEqual(subType, SubTypeEnum.COMMERCE) &&
      (isEqual(entityName, GroupEnum.OWNER) ||
        isEqual(entityName, GroupEnum.CUSTOMER))
    ) {
      dispatch(getRequestById(EndpointsPathsEnum.RULE_REQUEST_BY_ID, id));
      dispatch(
        getHierarchyNodes({
          entityName: get(item, "entity_name", "").toUpperCase(),
          nodeId: get(item, "node_id", ""),
          rootId: get(item, "root_id", ""),
        })
      );
      dispatch(setOpenConfirmModal(true));
    } else {
      sendApproveRuleRequest(id);
    }
  };

  const handleConfirmModalApproval = (): void => {
    const ruleId: string = get(request, PathsEnum.ID, "");

    sendApproveRuleRequest(ruleId);
  };

  const sendApproveRuleRequest = (ruleId: string): void => {
    const updateRequest: UpdateRuleRequest = {
      status: StatusEnum.APPROVED,
    };

    setAlertSuccessMsg(ConfirmModalEnum.SUCCESS_APPROVED_RULE);
    setAlertErrorMsg(ConfirmModalEnum.ERROR_APPROVED_RULE);
    dispatch(setRequestRuleById(undefined));
    dispatch(setOpenModalInfo(false));
    dispatch(
      updateRuleRequest(
        updateRequest,
        ruleId,
        TabIndexEnum.PENDING_REVIEW_INDEX
      )
    );
  };

  const handleRejectRule = (id: string): void => {
    dispatch(getRequestById(EndpointsPathsEnum.RULE_REQUEST_BY_ID, id));
    dispatch(setOpenModalRejected(true));
  };

  return {
    rows,
    cells,
    isLoading,
    isLoadingRules,
    confirmModalProps,
    setAlertSuccessMsg,
    setAlertErrorMsg,
    handleSelectedPage,
    selectedPage: filterSearch.page,
  };
};
