import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkPrefix } from "@shared/constants/thunkPrefixes";
import { default as axiosLib, AxiosResponse, CancelTokenSource } from "axios";
import { API_ROUTES } from "@shared/constants/api_routes";
import axios from "@shared/utils/axios-util";
import { SearchMerchantsRequest } from "../../../../types/search_merchants_request";
import {
  Merchant,
  SearchMerchantsResponse,
} from "../../../../types/search_merchants_response";
import { get, isEmpty } from "lodash";

export interface ISearchMerchantCoreRequestThunk {
  body: SearchMerchantsRequest;
  nameVariable: string;
}

export interface ISearchMerchantCoreResponseThunk {
  data: SearchMerchantsResponse;
  nameVariable: string;
}

let cancelToken: CancelTokenSource | null = null;

const buildAxiosGetMerchants = (
  request: SearchMerchantsRequest,
  limit: number,
  cancel: CancelTokenSource,
  entity?: string
) => {
  return axios.post(
    API_ROUTES.GET_MERCHANTS,
    {
      ...request,
      filter: {
        ...get(request, "filter", {}),
        ...(!isEmpty(entity) && { entityName: [entity] }),
      },
      limit: limit,
    },
    {
      cancelToken: cancel.token,
    }
  );
};

const buildPromiseGetMerchants = (
  request: SearchMerchantsRequest,
  cancel: CancelTokenSource
): Promise<AxiosResponse<SearchMerchantsResponse>>[] => {
  const promises: Promise<AxiosResponse<SearchMerchantsResponse>>[] = [];
  const listEntity: string[] = get(request, "filter.entityName", []);

  if (listEntity.length > 0) {
    const newLimit: number = Math.ceil(request.limit / listEntity.length);

    listEntity.forEach((entity: string) =>
      promises.push(buildAxiosGetMerchants(request, newLimit, cancel, entity))
    );
  } else promises.push(buildAxiosGetMerchants(request, request.limit, cancel));

  return promises;
};

export const getMerchantsCore = createAsyncThunk<
  ISearchMerchantCoreResponseThunk,
  ISearchMerchantCoreRequestThunk
>(
  thunkPrefix.getMerchantsCore,
  async (request: ISearchMerchantCoreRequestThunk) => {
    if (cancelToken) {
      cancelToken.cancel("Nueva petición, cancelando la antigua");
    }
    cancelToken = axiosLib.CancelToken.source();

    const response: AxiosResponse<SearchMerchantsResponse>[] =
      await Promise.all(buildPromiseGetMerchants(request.body, cancelToken));

    let total = 0;
    const concatData: Merchant[] = response.reduce((acc: Merchant[], cur) => {
      if (cur.data.data) {
        total += get(cur, "data.total", 0);

        return [...acc, ...cur.data.data];
      }

      return acc;
    }, []);

    return {
      data: { data: concatData, total },
      nameVariable: request.nameVariable,
    };
  }
);
