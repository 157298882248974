import React, { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import {
  createSecurityRule,
  getMerchantCredential,
  getMerchants,
  getProcessorsSecurityRules,
  getSearchKeyCredentials,
  getSecurityRule,
  IRulesState,
  MerchantsData,
  RulesAction,
  setIsLoading,
  setMerchants,
  setNotification,
  setProcessors,
  updateSecurityRule,
} from "../../store/actionCreator";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";
import { get } from "lodash";
import {
  INotification,
  RulesNotificationResultEnum,
} from "../../shared/infrastructure/constants/RulesConstants";
import { useSecurityRulesState } from "./state/useSecurityRulesState";
import { FormProvider } from "react-hook-form";
import { CardSection } from "../../components/CardSection/CardSection";
import {
  SecurityRulesDescriptionSection,
  SecurityRulesTitleButton,
  SecurityRulesTitleSection,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import GeneralInformationSection from "../../components/SecurityRulesSection/GeneralInformationSection";
import ConfigurationInformationSectionProps from "../../components/SecurityRulesSection/ConfigurationInformationSection";
import { defaultTo } from "lodash";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { ProcessorInformation } from "../../../types/processor_information";
import { SecurityRule } from "../../../types/security_rule";
import { MerchantInformation } from "../../../types/merchant_information";
import { GeneralInformationSkeleton } from "../../components/SecurityRulesSection/Skeletons/GeneralInformationSkeleton";
import { ConfigurationInformationSkeleton } from "../../components/SecurityRulesSection/Skeletons/ConfigurationInformationSkeleton";
import { Category } from "../../../types/category";
import { MerchantCredential } from "../../../types/merchant_credential";
import { GeneralActionsEnum } from "../../shared/infrastructure/enums/GeneralActionsEnum";
import { PayloadSearch } from "../../../types/payload_search";
import { ProcessorsInformation } from "../../../types/processors_list";

export interface SecurityRulesEditStateProps {
  isLoading: boolean | undefined;
  isLoadingGetMerchants: boolean | undefined;
  isLoadingGetProcessors: boolean | undefined;
  isLoadingGetRule: boolean | undefined;
  isLoadingGetMerchant: boolean | undefined;
  notification: INotification | undefined;
  merchants: MerchantsData | undefined;
  processors: ProcessorsInformation[] | undefined;
  currentSecurityRule: SecurityRule | undefined;
  currentMerchant: MerchantInformation | undefined;
  securityCredentials: Category[] | undefined;
  credentialsSuggested: MerchantCredential[] | undefined;
  isLoadingGetSuggestedCredentials: boolean | undefined;
}

export interface SecurityRulesEditFunctionsProps {
  setIsLoading: (payload: boolean) => RulesAction;
  setNotification: (payload: INotification) => RulesAction;
  getProcessors: (merchantId: string, payload: PayloadSearch) => void;
  getCredentials: (payload: string) => void;
  setProcessors: (payload: ProcessorInformation[]) => RulesAction;
  setMerchants: (payload: MerchantsData) => RulesAction;
  getSecurityRule: (payload: { ruleId: string }) => void;
  createSecurityRule: (payload: { rule: SecurityRule; role: string }) => void;
  updateSecurityRule: (payload: { rule: SecurityRule; ruleId: string }) => void;
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }) => void;
  getSearchKeyCredentials: (merchantId: string, searchTerm: string) => void;
}

const renderPaddingCardSection = (element: JSX.Element): JSX.Element => {
  return (
    <Grid container style={{ padding: "20px 22px" }}>
      <Grid item xs={12}>
        {element}
      </Grid>
    </Grid>
  );
};

export type SecurityRulesEditProps = SecurityRulesEditStateProps &
  SecurityRulesEditFunctionsProps;

export const SecurityRulesEdit: React.FC<SecurityRulesEditProps> = (
  props: SecurityRulesEditProps
) => {
  const {
    form,
    actions,
    checkedAllCountries,
    isValidTransfer,
    isValidCard,
    indexesStrictCondition,
    indexesSpeedCondition,
    countryGeneral,
    isEdit,
    terminateFillFields,
    credentials,
    generalAction,
  } = useSecurityRulesState(props);

  useEffect(() => {
    actions.handleSetCurrentSecurityRule(props.currentSecurityRule);
  }, [props.currentSecurityRule]);

  const isLoadingEditing: boolean =
    defaultTo(props.isLoadingGetRule, false) ||
    defaultTo(props.isLoadingGetProcessors, false) ||
    defaultTo(props.isLoadingGetMerchant, false);

  return (
    <React.Fragment>
      <Container fixed>
        <Box py={2}>
          <Typography variant={"h1"}>
            {SecurityRulesTitleSection.SECURITY_RULES}
          </Typography>
        </Box>
        <FormProvider {...form}>
          <Grid container spacing={4}>
            <CardSection
              isLoading={isEdit ? isLoadingEditing : false}
              sectionTitle={SecurityRulesTitleSection.GENERAL_INFORMATION}
              sectionDescription={
                SecurityRulesDescriptionSection.GENERAL_INFORMATION
              }
              sectionComponent={renderPaddingCardSection(
                <GeneralInformationSection
                  isValidCard={isValidCard}
                  isValidTransfer={isValidTransfer}
                  isEdit={isEdit}
                  checkedAllCountries={checkedAllCountries}
                  handleCheckedAllCountries={actions.handleCheckedAllCountries}
                  merchantList={props.merchants!}
                  isLoadingMerchantList={defaultTo(
                    props.isLoadingGetMerchants,
                    true
                  )}
                  handleSetSearchTextMerchantList={
                    actions.handleSetSearchTextMerchantList
                  }
                  handleEnterSearchTextMerchantList={
                    actions.handleEnterSearchTextMerchantList
                  }
                  setFormValue={actions.setFormValue}
                  setMerchantInformation={actions.setMerchantInformation}
                />
              )}
              sectionSkeletonComponent={renderPaddingCardSection(
                <GeneralInformationSkeleton />
              )}
            />
            <CardSection
              isLoading={isEdit ? isLoadingEditing : false}
              sectionTitle={SecurityRulesTitleSection.CONFIGURATION_INFORMATION}
              sectionDescription={
                SecurityRulesDescriptionSection.CONFIGURATION_INFORMATION
              }
              sectionComponent={renderPaddingCardSection(
                <ConfigurationInformationSectionProps
                  indexesStrictCondition={indexesStrictCondition}
                  addStrictCondition={actions.addStrictCondition}
                  removeStrictCondition={actions.removeStrictCondition}
                  indexesSpeedCondition={indexesSpeedCondition}
                  addSpeedCondition={actions.addSpeedCondition}
                  removeSpeedCondition={actions.removeSpeedCondition}
                  credentials={credentials}
                  generalAction={generalAction}
                  isLoadingGetSuggestedCredentials={
                    props.isLoadingGetSuggestedCredentials!
                  }
                  credentialsSuggested={props.credentialsSuggested!}
                  countryGeneral={countryGeneral}
                  terminateFillFields={terminateFillFields}
                  handleEnterSearchTextCredentialList={
                    actions.handleEnterSearchTextCredentialList
                  }
                  handleSetSearchTextCredentialList={
                    actions.handleSetSearchTextCredentialList
                  }
                  setCredentialInformation={actions.setCredentialInformation}
                  handleSetGeneralAction={actions.handleSetGeneralAction}
                />
              )}
              sectionSkeletonComponent={renderPaddingCardSection(
                <ConfigurationInformationSkeleton />
              )}
            />
          </Grid>
          <ActionButtons
            mainActionText={
              generalAction === GeneralActionsEnum.OTP ||
              generalAction === GeneralActionsEnum.THREE_DS
                ? SecurityRulesTitleButton.SEND_REQUEST
                : SecurityRulesTitleButton.SAVE
            }
            secondaryActionText={SecurityRulesTitleButton.RETURN}
            onCancel={actions.handleCancelAction}
            onSave={form.handleSubmit(actions.handleSubmitForm)}
            disabled={props.isLoading!}
            withIcon={true}
          />
        </FormProvider>
        {get(props, "notification.action") !==
          RulesNotificationResultEnum.NO_ACTION && (
          <SnackbarAlert
            type={get(props, "notification.type")}
            msg={get(props, "notification.message")}
            open={get(props, "notification.open")}
            handlerClose={actions.handleCloseNotification}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IRulesState
) => SecurityRulesEditStateProps = (
  state: IRulesState
): SecurityRulesEditStateProps => ({
  isLoading: state.isLoading,
  isLoadingGetMerchants: state.isLoadingGetMerchants,
  isLoadingGetRule: state.isLoadingGetRule,
  isLoadingGetProcessors: state.isLoadingGetProcessors,
  isLoadingGetMerchant: state.isLoadingGetMerchant,
  notification: state.notification,
  merchants: state.merchants,
  processors: state.processors,
  currentSecurityRule: state.currentSecurityRule,
  currentMerchant: state.currentMerchant,
  securityCredentials: state.credentials,
  credentialsSuggested: state.credentialsSuggested,
  isLoadingGetSuggestedCredentials: state.isLoadingGetSuggestedCredentials,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => SecurityRulesEditFunctionsProps = (
  dispatch: ThunkDispatch<IRulesState, undefined, RulesAction>
): SecurityRulesEditFunctionsProps => ({
  getProcessors: (merchantId: string, payload: PayloadSearch): void =>
    dispatch(getProcessorsSecurityRules(merchantId, payload)),
  getCredentials: (payload: string): void =>
    dispatch(getMerchantCredential(payload)),
  setProcessors: (payload: ProcessorInformation[]): RulesAction =>
    dispatch(setProcessors(payload)),
  getSecurityRule: (payload: { ruleId: string }): void =>
    dispatch(getSecurityRule(payload)),
  createSecurityRule: (payload: { rule: SecurityRule; role: string }): void =>
    dispatch(createSecurityRule(payload)),
  updateSecurityRule: (payload: { rule: SecurityRule; ruleId: string }): void =>
    dispatch(updateSecurityRule(payload)),
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }): void => dispatch(getMerchants(payload)),
  setMerchants: (payload: MerchantsData): RulesAction =>
    dispatch(setMerchants(payload)),
  setIsLoading: (payload: boolean): RulesAction =>
    dispatch(setIsLoading(payload)),
  setNotification: (payload: INotification): RulesAction =>
    dispatch(setNotification(payload)),
  getSearchKeyCredentials: (merchantId: string, searchTerm: string) =>
    dispatch(getSearchKeyCredentials(merchantId, searchTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityRulesEdit);
