import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconHome: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.3337 15.6667H5.66699C4.87188 15.665 4.10984 15.3484 3.54762 14.7861C2.98539 14.2239 2.66875 13.4619 2.66699 12.6667V8.33341C2.66699 8.1566 2.73723 7.98703 2.86225 7.86201C2.98728 7.73699 3.15685 7.66675 3.33366 7.66675C3.51047 7.66675 3.68004 7.73699 3.80506 7.86201C3.93009 7.98703 4.00033 8.1566 4.00033 8.33341V12.6667C4.00033 13.1088 4.17592 13.5327 4.48848 13.8453C4.80104 14.1578 5.22496 14.3334 5.66699 14.3334H10.3337C10.7757 14.3334 11.1996 14.1578 11.5122 13.8453C11.8247 13.5327 12.0003 13.1088 12.0003 12.6667V8.46008C12.0003 8.28327 12.0706 8.1137 12.1956 7.98868C12.3206 7.86365 12.4902 7.79341 12.667 7.79341C12.8438 7.79341 13.0134 7.86365 13.1384 7.98868C13.2634 8.1137 13.3337 8.28327 13.3337 8.46008V12.6667C13.3319 13.4619 13.0153 14.2239 12.453 14.7861C11.8908 15.3484 11.1288 15.665 10.3337 15.6667V15.6667Z" />
        <path d="M12.6664 9.12668C12.4896 9.13198 12.3179 9.06683 12.1891 8.94556C12.0604 8.82428 11.985 8.65682 11.9797 8.48001C11.9744 8.3032 12.0396 8.13152 12.1608 8.00275C12.2821 7.87397 12.4496 7.79865 12.6264 7.79334L13.2397 7.75334L7.99973 2.00001L2.83973 7.66668H3.33306C3.50987 7.66668 3.67944 7.73692 3.80447 7.86194C3.92949 7.98696 3.99973 8.15653 3.99973 8.33334C3.99973 8.51015 3.92949 8.67972 3.80447 8.80475C3.67944 8.92977 3.50987 9.00001 3.33306 9.00001H1.33306C1.20425 8.99971 1.07828 8.96209 0.970391 8.89171C0.862502 8.82133 0.777314 8.7212 0.725128 8.60343C0.672941 8.48566 0.655993 8.35529 0.676331 8.22809C0.696669 8.10089 0.753423 7.9823 0.83973 7.88668L7.5064 0.553345C7.56888 0.484599 7.64504 0.429672 7.73 0.392085C7.81496 0.354499 7.90683 0.335083 7.99973 0.335083C8.09263 0.335083 8.1845 0.354499 8.26946 0.392085C8.35442 0.429672 8.43058 0.484599 8.49306 0.553345L15.1597 7.88668C15.2448 7.97949 15.3018 8.09449 15.3242 8.21837C15.3466 8.34225 15.3335 8.46994 15.2864 8.58668C15.2387 8.70336 15.1589 8.80413 15.0562 8.87729C14.9536 8.95045 14.8323 8.993 14.7064 9.00001L12.7064 9.12668H12.6664Z" />
        <path d="M9.99968 15.6667C9.82286 15.6667 9.65329 15.5965 9.52827 15.4714C9.40325 15.3464 9.33301 15.1769 9.33301 15V12C9.33301 11.6464 9.19253 11.3073 8.94248 11.0572C8.69244 10.8072 8.3533 10.6667 7.99967 10.6667C7.64605 10.6667 7.30691 10.8072 7.05687 11.0572C6.80682 11.3073 6.66634 11.6464 6.66634 12V15C6.66634 15.1769 6.5961 15.3464 6.47108 15.4714C6.34605 15.5965 6.17649 15.6667 5.99967 15.6667C5.82286 15.6667 5.65329 15.5965 5.52827 15.4714C5.40325 15.3464 5.33301 15.1769 5.33301 15V12C5.33301 11.2928 5.61396 10.6145 6.11406 10.1144C6.61415 9.61433 7.29243 9.33337 7.99967 9.33337C8.70692 9.33337 9.3852 9.61433 9.88529 10.1144C10.3854 10.6145 10.6663 11.2928 10.6663 12V15C10.6663 15.1769 10.5961 15.3464 10.4711 15.4714C10.3461 15.5965 10.1765 15.6667 9.99968 15.6667Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconHome;
