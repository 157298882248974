import React from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ErrorsMessageEnum } from "../../../shared/infrastructure/ErrorMessageEnum";
import { IOptionFilter } from "../FilterComponent.interfaces";
import { FilterBoxProps } from "./FilterBox.interfaces";
import { IdFilterEnum } from "../../../shared/infrastructure/IdFilterEnum";
import { useFilterBoxState } from "./state/useFilterBoxState";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  filterBoxStyles,
} from "./FilterBox.styles";
import { TabsEnum } from "../../../shared/enums/tabs_enum";
import { ColorsEnum } from "../../../shared/enums/colors_enum";

export const FilterBox = (props: FilterBoxProps): JSX.Element => {
  const {
    expandId,
    optionsChip,
    rangeAmount,
    rangeAmountFilter,
    handlers,
    textAllExpanded,
    errors,
  } = useFilterBoxState(props);

  const applyButton = (
    <Button
      id="buttonOnApplyFilter"
      variant={"contained"}
      size={"small"}
      sx={filterBoxStyles.applyFilterBtn}
      disabled={props.filterButtonsDisabled}
      onClick={() => {
        props.onApplyFilter(optionsChip, rangeAmount);
      }}
    >
      <Typography sx={filterBoxStyles.buttonsText}>Aplicar Filtros</Typography>
    </Button>
  );

  const clearButton = (
    <Button
      id="buttonOnRestoreFilter"
      size={"small"}
      variant={"outlined"}
      sx={filterBoxStyles.restoreBtn}
      disabled={props.filterButtonsDisabled}
      onClick={() => handlers.handleRestoreFilters()}
    >
      <Typography
        sx={filterBoxStyles.buttonsText}
        style={{ color: ColorsEnum.DARK }}
      >
        Restablecer
      </Typography>
    </Button>
  );

  const getCurrencyCode = (filters: IOptionFilter[]) =>
    filters.map((filter: IOptionFilter) =>
      filter.id === IdFilterEnum.currencyCode ? filter.options[0].label : ""
    );

  return (
    <React.Fragment>
      <Box display="flex" flexWrap="wrap" marginBottom={1}>
        <Box flexGrow={1}>
          <Typography sx={filterBoxStyles.filterTitle}>Filtrar por</Typography>
        </Box>
        <Box>
          <Button
            id="buttonOnExpandAccordion"
            sx={filterBoxStyles.kshExpandBtn}
            onClick={handlers.handleExpandAll}
          >
            <Typography color="inherit" variant={"overline"}>
              {textAllExpanded}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Divider style={{ border: 0 }} />
      {props.filters.map((filter: IOptionFilter) =>
        props.currentTab === TabsEnum.PENDING &&
        filter.id === IdFilterEnum.conciliationAction ? (
          ""
        ) : (
          <>
            <ExpansionPanel
              sx={filterBoxStyles.kshExpand}
              key={filter.id}
              variant="outlined"
              expanded={expandId[filter.id]}
              onChange={() => handlers.handleExpansionPanelChange(filter.id)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={filterBoxStyles.kshTittle}>
                  {filter.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails sx={filterBoxStyles.panelAmountDetails}>
                <Grid sx={filterBoxStyles.root}>
                  {filter.options.map(
                    (option: {
                      key: string;
                      label: string;
                      selected: boolean;
                      img?: string;
                    }) => (
                      <React.Fragment key={option.key}>
                        <Chip
                          size="small"
                          label={option.label}
                          onClick={() => {
                            handlers.handleClickChip(option.key);
                          }}
                          sx={filterBoxStyles.filterChip}
                          style={{
                            border:
                              optionsChip[option.key] === undefined
                                ? "none"
                                : optionsChip[option.key].borderColor,
                          }}
                        />
                      </React.Fragment>
                    )
                  )}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </>
        )
      )}

      <ExpansionPanel
        sx={filterBoxStyles.kshExpand}
        variant="outlined"
        expanded={expandId[rangeAmountFilter.id]}
        onChange={() =>
          handlers.handleExpansionPanelChange(rangeAmountFilter.id)
        }
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={filterBoxStyles.kshTittle}>
            {rangeAmountFilter.title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails sx={filterBoxStyles.panelAmountDetails}>
          <Box display={"grid"}>
            <Box display="flex" justifyContent="flex-start">
              <Box pr={2}>
                <Select
                  id="selectRangeAmount"
                  sx={filterBoxStyles.filterSelect}
                  onChange={(event) =>
                    handlers.handleChangeSelectRange(event.target.value)
                  }
                  value={rangeAmount.type}
                  defaultValue="min"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem
                    id="option_max"
                    value={"max"}
                    sx={filterBoxStyles.menuItem}
                  >
                    Menor que
                  </MenuItem>
                  <MenuItem
                    id="option_min"
                    value={"min"}
                    sx={filterBoxStyles.menuItem}
                  >
                    Mayor que
                  </MenuItem>
                  <MenuItem
                    id="option_eq"
                    value={"eq"}
                    sx={filterBoxStyles.menuItem}
                  >
                    Igual a
                  </MenuItem>
                  <MenuItem
                    id="option_range"
                    value={"range"}
                    sx={filterBoxStyles.menuItem}
                  >
                    Entre
                  </MenuItem>
                </Select>
              </Box>
              <Box>
                <Box display="flex" justifyContent="flex-start">
                  {rangeAmount.type === "range" ? (
                    <React.Fragment>
                      <Box>
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={filterBoxStyles.filterTextField}
                          type="number"
                          error={errors.rangeAmountError}
                          helperText={
                            errors.rangeAmountError
                              ? ErrorsMessageEnum.RANGE_AMOUNT_ERROR
                              : "min"
                          }
                          value={rangeAmount.min}
                          onChange={(event) =>
                            handlers.handleChangeAmount(
                              event.target.value,
                              "min"
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={filterBoxStyles.adornmentText}
                              >
                                {getCurrencyCode(props.filters)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box>
                        <p style={{ marginTop: 30, padding: "0 10px" }}>y</p>
                      </Box>
                      <Box>
                        <TextField
                          error={errors.rangeAmountError}
                          helperText={errors.rangeAmountError ? "" : "max"}
                          sx={filterBoxStyles.filterTextField}
                          type="number"
                          value={rangeAmount.max}
                          onChange={(event) =>
                            handlers.handleChangeAmount(
                              event.target.value,
                              "max"
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={filterBoxStyles.adornmentText}
                              >
                                {getCurrencyCode(props.filters)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </React.Fragment>
                  ) : (
                    <Box>
                      <TextField
                        size="small"
                        type="number"
                        value={
                          rangeAmount.min || rangeAmount.max || rangeAmount.eq
                        }
                        onChange={(event) =>
                          handlers.handleChangeAmount(
                            event.target.value,
                            rangeAmount.type
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={filterBoxStyles.adornmentText}
                            >
                              {props.filters.map((filter: IOptionFilter) =>
                                filter.id === IdFilterEnum.currencyCode
                                  ? filter.options[0].label
                                  : ""
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              {errors.amountError && (
                <FormHelperText
                  sx={filterBoxStyles.formHelperText}
                  error={errors.amountError}
                >
                  {ErrorsMessageEnum.NEGATIVE_AMOUNT_ERROR}
                </FormHelperText>
              )}
            </Box>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Box sx={filterBoxStyles.buttonsBox}>
        <Box flexGrow={1}>{clearButton}</Box>
        <Box>{applyButton}</Box>
      </Box>
    </React.Fragment>
  );
};
