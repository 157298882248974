import { IStyles } from "../../shared/utils";
import theme from "../../theme";

export const webhookStyles: IStyles = {
  boxWebhookSignature: {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(6),
    pl: "3px",
    mb: theme.spacing(7),
  },
  skeletonSignature: {
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(37.5),
  },
  textWebhookSignature: {
    display: "flex",
    alignItems: "center",
    marginLeft: "4px",
  },
};
