import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const ProcessingConfirmationModalStyles: IStyles = {
  button: {
    backgroundColor: "#fafcfd",
    border: "none",
    opacity: 1,
    "&:hover": {
      backgroundColor: "#fafcfd",
      border: "none",
    },
  },
  cancelTxt: {
    color: "#6D7781",
    fontWeight: 500,
    p: 1,
    textTransform: "none",
    typography: "body",
  },
  closeButton: {
    color: "#293036",
    position: "absolute",
    right: 8,
    top: 8,
  },
  confirmTxt: {
    color: "#023365",
    fontWeight: 500,
    p: 1,
    textTransform: "none",
    typography: "body",
  },
  dialog: {},
  disclaimer: {
    color: "black",
    mb: "24px",
    typography: "body",
  },
  divider: {
    width: "390px",
  },
  header: {
    color: "black",
    fontWeight: 500,
    mb: "10px",
    typography: "h5",
  },
  label: {
    color: "black",
  },
};
