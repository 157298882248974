import { CountriesEnum } from "../enums/countriesEnum";

const names = {
  BRASIL: "Brasil",
  COSTA_RICA: "Costa Rica",
  EL_SALVADOR: "El Salvador",
};

export const convertCountryName = (country: CountriesEnum) => {
  switch (country) {
    case CountriesEnum.COSTA_RICA:
      return names.COSTA_RICA;
    case CountriesEnum.EL_SALVADOR:
      return names.EL_SALVADOR;
    case CountriesEnum.BRAZIL:
      return names.BRASIL;
    default:
      return country;
  }
};
