import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LifeCycles, registerApplication, start } from "single-spa";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import "zone.js";
import { environment } from "./environments/environment";
import { BrowserRouter } from "react-router-dom";
import { CatalogLayout } from "./infrastructure/constants/CatalogLayout";
import { isArray } from "lodash";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (environment.envName === "primary") {
  // tslint:disable-next-line:typedef
  const SCRIPT = document.createElement("SCRIPT");

  SCRIPT.appendChild(
    document.createTextNode(
      // eslint-disable-next-line
      'window.DD_RUM && window.DD_RUM.init({applicationId: "5099c5da-63c5-4496-a5f5-018d5ca84014",  clientToken: "pub2589b6fe92782cd7a267cd3452b238db", site: "datadoghq.com", service: "Consola de Cliente", env: "production", sampleRate: 100, trackInteractions: true});'
    )
  );
  document.body.appendChild(SCRIPT);
}

const registerApp = (name: string, path: string) => {
  const domain: string =
    `${environment.envName}` !== "po"
      ? "console.kushkipagos.com"
      : "console.kushkipagos.ninja";
  registerApplication(
    `spa-backoffice-${name}`,
    (): Promise<LifeCycles> =>
      (window as any).System.import(
        `https://${name}-${environment.envName}-${domain}/main.js`
      ),
    (location: Location) => `/${location.pathname.split("/")[1]}` === path
  );
};
const registerAngularApp = (name: string, path: string) => {
  const env: string =
    `${environment.envName}` !== "primary" ? `${environment.envName}-` : "";
  const domain: string =
    `${environment.envName}` !== "po"
      ? "console.kushkipagos.com"
      : "console.kushkipagos.ninja";
  registerApplication(
    `spa-backoffice-${name}`,
    (): Promise<LifeCycles> =>
      (window as any).System.import(`https://${env}${domain}/${name}/main.js`),
    (location) => location.pathname.startsWith(path)
  );
};

function registerReactApp(
  name: string,
  path: string | string[],
  isConsole?: boolean,
  isCredentials: boolean = false
): void {
  registerApplication(
    isConsole ? `spa-console-${name}` : `spa-backoffice-${name}`,
    (): Promise<LifeCycles> =>
      (window as any).System.import(
        isConsole
          ? `@kushki/spa-console-${name}`
          : `@kushki/spa-backoffice-${name}`
      ),
    (location) => {
      // When 'path' is casted to an array, the console-credentials spa does not get rendered
      // This is why we've included this 'isCredentials' flag to replicate the old behavior so
      // That the console-credentials spa gets properly rendered
      if (isCredentials)
        return path.includes(`/${location.pathname.split("/")[1]}`);

      const final_path = isArray(path) ? path : [path];

      return final_path.includes(`/${location.pathname.split("/")[1]}`);
    }
  );
}

/*function registerAppLocal(
  name: string,
  path: string | string[],
  port?: number,
  isOldBackoffice?: boolean,
  isConsole?: boolean
): void {
  const ksh_path: string = isOldBackoffice
    ? "main.js"
    : isConsole
    ? `kushki-spa-console-${name}.js`
    : `kushki-spa-backoffice-${name}.js`;
  const domain: string = `http://localhost:${port}/${ksh_path}`;
  _registerApplication(name, path, domain, isConsole);
}

function _registerApplication(
  name: string,
  path: string | string[],
  domain: string,
  isConsole?: boolean
) {
  registerApplication({
    name: isConsole ? `spa-console-${name}` : `spa-backoffice-${name}`,
    app: (): Promise<LifeCycles> => (window as any).System.import(domain),
    activeWhen: path,
  });
}*/

registerReactApp("transaction", "/transactions");
registerReactApp("login", "/auth");
registerReactApp("smartlink", "/smartlinks");
registerReactApp("user", "/users");
registerReactApp("merchant-list", "/merchant-list");
registerReactApp("global-configuration", "/global-config");
registerReactApp("costs", "/costs");
registerReactApp("billing-merchant", "/billing-merchant");
registerReactApp("merchant-rates", "/merchant-rates");
registerReactApp("merchant-deferred", "/merchant-deferred");
registerReactApp("transaction-rates", "/rate");
registerReactApp("merchant-properties", "/merchant-properties");
registerReactApp("merchant-configuration", "/config-merchant");
registerReactApp("billing-dashboard", "/billing-dashboard");
registerReactApp("dashboard", "/dashboard");
registerReactApp("support", "/support");
registerReactApp("holidays", "/holidays");
registerReactApp("subscription", "/subscriptions");
registerReactApp("etpay-customization", "/etpay-customization");
registerReactApp("webhook", "/webhook");
registerReactApp("webcheckout", "/webcheckout");
registerReactApp("processor-list", "/processor-list");
registerReactApp("deferred", "/deferred");
registerReactApp("payouts-cash-processor", "/processor-payouts-cash");
registerReactApp("payouts-transfer-processor", "/processor-payouts-transfer");
registerReactApp(
  "transfer-subscriptions-processor",
  "/processor-transfer-subscriptions"
);
registerReactApp("transfer-processor", "/processor-transfer");
registerReactApp("cash-processor", "/processor-cash");
registerReactApp("conciliation", "/reports");
registerReactApp("conciliation-dashboard", "/conciliation-dashboard");
registerReactApp("credentials", "/credentials");
registerReactApp("card-processor", "/processor-card");
registerReactApp("chargeback", "/chargeback");
registerReactApp("rules", "/rules");
registerReactApp("create-merchant", "/create-merchant");
registerReactApp("kform", "/kform");
registerReactApp("kforms-list", "/kforms-list");
registerReactApp("monitoring", "/monitoring");
registerReactApp("retentions", "/retentions");
registerReactApp("rule-request", "/rule-request");
registerReactApp("rule-request-manager", "/rule-request-manager");
registerReactApp("payouts-transaction", "/payouts-transaction");
registerReactApp("vehiscore", "/vehiscore");
registerReactApp("b2c-refunds", "/b2c-refunds");
registerReactApp("merchant-people", "/merchant-people");
registerReactApp("bank-conciliation", "/bank-conciliation");
registerReactApp("retry-rules", "/retry-rules");
registerReactApp("login-internal", "/login-internal");
registerReactApp("config-by-file", "/config-file");
registerReactApp("refund-dashboard", "/refund-dashboard");
registerReactApp("transactional-conciliation", "/transactional-conciliation");
registerReactApp("merchant-resume", "/merchant-resume");
registerReactApp("wallet-dashboard", "/wallet-dashboard");
registerReactApp("payouts-dispersion", "/payouts-dispersion");
registerApp("checkout", "/checkout");
registerReactApp("checkout-v2", "/checkout-v2");

registerAngularApp("merchant", "/merchants");
registerApp("reseller", "/resellers");
registerAngularApp("wallet", "/wallet");
registerApp("dispersion", "/dispersion");
registerAngularApp("subscription-legacy", "/subscriptions-legacy");
registerAngularApp("snr", "/snr");
registerAngularApp("mguayaquil", "/municipio-guayaquil");
registerReactApp("roles-control", "/roles-control");

registerReactApp("layout", CatalogLayout, true);
registerReactApp("create-node", "/create-node", true);
registerReactApp("merchant-company-data", "/merchant", true);
registerReactApp("service-config", "/service-configuration", true);
registerReactApp("transaction-rates", "/console-rates", true);
registerReactApp("processing-config", "/processing", true);

///Add register to new SPA's
registerReactApp("financial-configuration", "/financial-configuration", true);
registerReactApp("centralization", "/centralization", true);
registerReactApp("merchant-preferences", "/merchant-preferences", true);
registerReactApp("credentials", "/v3/credentials", true, true);
registerReactApp("clients", "/clients", true);
registerReactApp("wallet", "/console-wallet", true);
registerReactApp("wallet-dashboard", "/console-wallet-dashboard", true);
registerReactApp("user", "/console-user", true);
registerReactApp("merchant-configuration", "/merchant-config", true);
registerReactApp("developers", "/developers", true);
registerReactApp("merchant-webhook", "/merchant-webhook", true);
registerReactApp("migration", "/merchant-migration");
registerReactApp("business-rules", "/business-rules", true);
registerReactApp("payout-business-rules", "/payout-business-rules", true);
registerReactApp("my-merchant", "/my-merchant", true);
registerReactApp("transaction", "/console-transactions", true);
registerReactApp("payouts-transaction", "/console-payouts-transaction", true);
registerReactApp("chargeback", "/console-chargeback", true);
registerReactApp(
  "international-processor",
  "/processor-international-transfer",
  true
);
registerReactApp("compliance", "/console-compliance", true);
registerReactApp("express-checkout", "/express-checkout", true);
registerReactApp("dashboard-metrics", "/dashboard-metrics");
registerReactApp("payouts-card-processor", "/processor-payouts-card", true);

start();
