import React from "react";
import { ModalContent } from "@kushki/connect-ui";
import { Button, FormControl, Grid, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { useMassiveStatusModal } from "./state/useMassiveStatusModal";
import {
  MassiveStatusModalButtons,
  MassiveStatusModalConstants,
} from "../../shared/infraestructure/MassiveStatusModalConstants";
import { ChargebackMessages } from "../../shared/enums/LabelsEnum";
import { FileMassiveData } from "../../../types/update_chargeback_status_massive_request";
import { isUndefined } from "lodash";
import { paperFileUploadStyles } from "../PaperFileUpload/PaperFileUpload.styles";
import DeleteFileIcon from "../../assets/images/deletefile.svg";

export enum ModalContentButtonTypeEnum {
  PRIMARY = "primary",
}

export enum ModalContentTypeEnum {
  DEFAULT = "Default",
}

export const MassiveStatusModal = (props: {
  massiveStatusFile: FileMassiveData | undefined;
  setMassiveStatusFile: React.Dispatch<
    React.SetStateAction<FileMassiveData | undefined>
  >;
}) => {
  const {
    onCloseModal,
    allowedExtensions,
    isOpen,
    handleContinue,
    handleFilesUploaded,
    handleCheckHereLink,
    isDisabledContinueBtn,
    handleRemoveFile,
  } = useMassiveStatusModal(props);

  return (
    <>
      <ModalContent
        buttonText={MassiveStatusModalButtons.CONTINUE_BUTTON}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        descriptionText={""}
        onClose={onCloseModal}
        titleText={MassiveStatusModalConstants.TITLE}
        type={ModalContentTypeEnum.DEFAULT}
        buttonAction={handleContinue}
        buttonPrimaryDisabled={isDisabledContinueBtn}
        isOpen={isOpen}
        buttonSecondaryText={MassiveStatusModalButtons.CANCEL_BUTTON}
      >
        <Grid container spacing={2}>
          <Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "8px", padding: "4px 14px 10px 14px" }}
            >
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Typography
                  variant={"body2"}
                  align={"justify"}
                  sx={{ color: "#293036" }}
                >
                  {MassiveStatusModalConstants.DETAIL_MESSAGE}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "20px", paddingTop: "40px" }}
              >
                <Grid container spacing={1}>
                  <Grid style={{ paddingRight: "20px" }}>
                    <FormControl variant="outlined">
                      <Button
                        variant="outlined"
                        component="label"
                        color="info"
                        disabled={false}
                      >
                        {isUndefined(props.massiveStatusFile)
                          ? MassiveStatusModalButtons.UPLOAD_FILE_BUTTON_TITLE
                          : MassiveStatusModalButtons.CHANGE_FILE_BUTTON_TITLE}
                        <input
                          data-testid="file-input-letterFilesKey"
                          aria-labelledby={"letterFilesKey"}
                          onChange={(e) => {
                            handleFilesUploaded(e);
                          }}
                          type="file"
                          accept={allowedExtensions}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid style={{ paddingTop: "10px" }}>
                    <span style={{ color: "#293036" }}>
                      {isUndefined(props.massiveStatusFile)
                        ? ChargebackMessages.EMPTY_FILES
                        : props.massiveStatusFile.fileName}
                    </span>
                  </Grid>
                  {!isUndefined(props.massiveStatusFile) && (
                    <Grid sx={paperFileUploadStyles.gridImg}>
                      <img
                        src={DeleteFileIcon}
                        onClick={handleRemoveFile}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                <Typography variant={"caption"} sx={{ color: "#293036" }}>
                  {MassiveStatusModalConstants.UPLOAD_FILE_INSTRUCTION}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Typography variant={"caption"} sx={{ color: "#293036" }}>
                  {MassiveStatusModalConstants.UPLOAD_FILE_QUESTION}
                </Typography>
                <Typography
                  variant={"caption"}
                  sx={{ color: "#293036", "font-weight": "bold" }}
                >
                  <Link underline={"always"} onClick={handleCheckHereLink}>
                    {MassiveStatusModalButtons.CHECK_HERE_BUTTON}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
    </>
  );
};
