export enum ConfigTypeEnum {
  cn001 = "cn001",
  cn002 = "cn002",
  cn003 = "cn003",
  cn004 = "cn004",
  cn005 = "cn005",
  cn006 = "cn006",
  cn007 = "cn007",
  cn009 = "cn009",
  cn012 = "cn012",
  cn015 = "cn015",
  cn016 = "cn016",
  cn017 = "cn017",
  cn018 = "cn018",
  cn019 = "cn019",
}
