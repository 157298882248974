/**
 * Payment Methods Enum
 */
export enum PaymentMethodEnum {
  CARD = "card",
  CASH = "cash",
  PAYOUTS_CASH = "payouts-cash",
  TRANSFER = "transfer",
  TRANSFER_SUBSCRIPTION = "transfer-subscriptions",
  PAYOUTS_CARD = "payouts-card",
  PAYOUTS_TRANSFER = "payouts-transfer",
  ACH_TRANSFER = "ach-transfer",
  INTERNATIONAL_TRANSFER = "internationalTransfer",
}
export enum PayInOutPopupConstants {
  PAY_IN = "Pay in",
  PAY_OUT = "Pay out",
  CARD = "card",
  TRANSFER = "transfer",
  TRANSFER_PAY_OUT = "payouts-transfer",
  CARD_PAY_OUT = "payouts-card",
  CASH_PAY_OUT = "payouts-cash",
  RECURRENT_DEBIT = "transfer-subscriptions",
  CASH = "cash",
  CARD_TITLE = "Tarjeta",
  TRANSFER_TITLE = "Transferencia",
  RECURRENT_DEBIT_TITLE = "Débito recurrente",
  CASH_TITLE = "Efectivo",
  INTERNATIONAL_TRANSFER = "international-transfer",
}

export const PAYMENT_METHODS_PATHS: Record<string, string> = {
  [PaymentMethodEnum.CARD]: "/rules/v1/admin/processor/{merchantId}/",
  [PaymentMethodEnum.TRANSFER]: "/transfer/v1/admin/processor/",
  [PaymentMethodEnum.CASH]: "/cash/v1/admin/processor/",
  [PaymentMethodEnum.PAYOUTS_TRANSFER]: "/payouts/transfer/v1/admin/processor/",
  [PaymentMethodEnum.PAYOUTS_CARD]: "/payouts/card/v1/admin/processor/",
  [PaymentMethodEnum.PAYOUTS_CASH]: "/payouts/cash/v1/admin/processor/",
  [PaymentMethodEnum.TRANSFER_SUBSCRIPTION]:
    "/transfer-subscriptions/v1/admin/processor/",
  [PaymentMethodEnum.INTERNATIONAL_TRANSFER]:
    "/international-transfer/v1/admin/processor/",
};
