export enum BasicPropertiesValueEnum {
  MERCHANT_TYPE = "merchantType",
  CONSTITUTION_DATE = "constitutionDate",
  TAX_ID = "documentType",
  TAX_ID_NUMBER = "taxId",
  INDUSTRY_TYPE = "industryType",
  WEB_SITE = "webSite",
  TAX_ID_WITH_ID_NUMBER = "taxIdWithIdNumber",
  TAX_ID_USA = "documentTypeUsa",
  TAX_ID_NUMBER_USA = "taxIdUsa",
  COMPANY_REGISTRATION_NUMBER = "companyRegistrationNumber",
  IDENTIFICATION_EIN = "taxId",
  COUNTRY_CODE = "countryCode",
  ENTITY_NAME = "entityName",
}

export enum BasicPropertiesLabelEnum {
  MERCHANT_TYPE = "Tipo de persona",
  CONSTITUTION_DATE = "Fecha de constitución",
  TAX_ID = "Tipo de TAX ID",
  TAX_ID_NUMBER = "Número de TAX ID",

  IDENTIFICATION_EIN = "Identificación del Empleador EIN",
  INDUSTRY_TYPE = "Tipo de industria",
  WEB_SITE = "Web",
  TAX_ID_WITH_ID_NUMBER = "taxIdWithIdNumber",
  TAX_ID_USA = "documentTypeUsa",
  TAX_ID_NUMBER_USA = "taxIdUsa",
  COMPANY_REGISTRATION_NUMBER = "Número de Registro de compañía",
}
