import { createNamedStyles } from "../../../shared/utils";

const EmptyTableStyles = createNamedStyles({
  container: {
    backgroundColor: "#FFFFFF",
    minHeight: "495px",
  },
  mainText: {
    fontSize: "19px",
    color: "neutral.grey7",
  },
  subText: {
    fontSize: "12px",
    color: "neutral.grey7",
  },
});

export default EmptyTableStyles;
