import React from "react";
import { Alert } from "@kushki/connect-ui";
import { QRCode } from "./QRCode/QRCode";
import { Box, Button, Typography } from "@mui/material";
import { useCheckoutContext } from "../../containers/CheckoutContainer/context/useCheckoutContext";
import { qrInformationStyles } from "./QRInformationStyles";
import { QRLabels } from "../../shared/labels/QR.labels";
import useClipboard from "react-use-clipboard";

const QRInformation = () => {
  const { isMobile, webcheckoutUrl } = useCheckoutContext();
  const qrInformationStyle = qrInformationStyles(isMobile);
  const [, clipboard] = useClipboard(webcheckoutUrl);

  const AlertComponent = (): JSX.Element => {
    return (
      <Box>
        <Alert
          description={QRLabels.DESCRIPTION}
          title={QRLabels.TITLE_ALERT}
          type="informative"
        />
      </Box>
    );
  };

  return (
    <>
      <Box sx={qrInformationStyle.boxQrInformationContainer}>
        <Box sx={qrInformationStyle.contentText}>
          <Typography
            color="text.primary"
            variant="h3"
            sx={qrInformationStyle.title}
          >
            {QRLabels.TITLE}
          </Typography>
          <Typography
            color="text.primary"
            variant="body2"
            sx={qrInformationStyle.subTitle}
          >
            {QRLabels.SUBTITLE}
          </Typography>
          {!isMobile && <AlertComponent />}
        </Box>
        <Box sx={qrInformationStyle.containerQR}>
          <Box sx={qrInformationStyle.qrBox}>
            <QRCode url={webcheckoutUrl} size={isMobile ? 230 : 200} />
          </Box>
          <Button
            size="small"
            variant="text"
            data-testid={"buttonClipboard"}
            onClick={clipboard}
            sx={qrInformationStyle.buttonLink}
          >
            {QRLabels.LINK_QR}
          </Button>
        </Box>

        {isMobile && <AlertComponent />}
      </Box>
    </>
  );
};

export { QRInformation };
