export enum TableOptionsEnum {
  EDIT = "Editar",
  ENABLE = "Habilitar",
  DISABLE = "Deshabilitar",
  DELETE = "Eliminar",
}

export enum OptionsTypeEnum {
  TYPE1 = "type1",
}
