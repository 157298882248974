import { defaultTo, get, map, maxBy } from "lodash";
import { SemaphoreData } from "../../../types/semaphore_data";
import { MerchantData } from "../../../types/merchant-data";
import { ConfigIdEnum } from "../constants/initial_state_legal_details";
import { MerchantBillingData } from "../../../types/merchant_billing_data";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../types/search_merchant_response";
import { getBranchEditListFromSessionStorage } from "./company_data_utils";
import { TabItemStatusEnum } from "../enum/tabItemStatusEnum";
import { Branch, Request } from "../../../types/update_massive_branch_request";
import { IContactDataMassive } from "../interfaces/IContactDataMassive.interface";
import { IMerchantPeopleNodeRequest } from "../../store/interfaces/LegalDetailsState.interfaces";

interface stepBasicDataSemaphore {
  basicDetail?: {};
  billingDetail?: {};
  contactDetail?: {};
  legalDetail?: {};
}

export interface branchesSelectedList {
  clientType: string;
  name: string;
  nodeId: string;
  publicMerchantId: string;
}

interface configsBranchSelected {
  centralizedNodesId: string;
  configuration: string;
  status: string;
  updatedAt: number;
  updatedBy: string;
  value: string;
}

export interface listBranchesTitle {
  listBranches: branchesSelectedList[];
  nodeIds: string[];
  title: string;
  publicMerchantsIds: string;
}

export const listBranchesSelected = (): listBranchesTitle => {
  const branchesList: branchesSelectedList[] = [];

  let lista: SearchMerchantResponse =
    getBranchEditListFromSessionStorage("branchEditList");

  const listaEditBranches: MerchantNodeData[] = lista.data.filter(
    (branch) => branch.edit === true
  );

  listaEditBranches.map((branch) => {
    branchesList.push({
      clientType: branch.client_type,
      name: branch.name!,
      nodeId: branch.node_id!,
      publicMerchantId: `${branch.public_merchant_id}`,
    });
  });

  const title: string = map(branchesList.slice(0, 3), "name").join(", ");

  const listMerchants: string = map(branchesList, "publicMerchantId").join(",");
  const listNodeIds: string[] = map(branchesList, "nodeId");

  return {
    listBranches: branchesList,
    nodeIds: listNodeIds,
    publicMerchantsIds: listMerchants,
    title,
  };
};

export const getSemaphoreBranches = (): stepBasicDataSemaphore => {
  let listStorageBranchEdit: SearchMerchantResponse =
    getBranchEditListFromSessionStorage("branchEditList");

  const semaphoreBranches: Partial<MerchantNodeData>[] =
    listStorageBranchEdit.data
      .filter((branch) => branch.edit === true)
      .map((filter) => {
        return {
          publicMerchantId: get(filter, "merchant_id"),
          stepCN001: filter.configs?.filter(
            (a: configsBranchSelected) => a.configuration === ConfigIdEnum.CN001
          )[0],
          stepCN002: filter.configs?.filter(
            (a: configsBranchSelected) => a.configuration === ConfigIdEnum.CN002
          )[0],
          stepCN015: filter.configs?.filter(
            (a: configsBranchSelected) => a.configuration === ConfigIdEnum.CN015
          )[0],
          stepCN016: filter.configs?.filter(
            (a: configsBranchSelected) => a.configuration === ConfigIdEnum.CN001
          )[0],
        };
      });

  return {
    basicDetail: maxBy(semaphoreBranches, "stepCN001.updatedAt"),
    billingDetail: maxBy(semaphoreBranches, "stepCN016.updatedAt"),
    contactDetail: maxBy(semaphoreBranches, "stepCN002.updatedAt"),
    legalDetail: maxBy(semaphoreBranches, "stepCN015.updatedAt"),
  };
};

export const createSemaphoreBranches = (): object[] => {
  const hierarchyCore: object = getSemaphoreBranches();

  return [
    {
      publicMerchantId: get(hierarchyCore, "basicDetail.publicMerchantId"),
      section: ConfigIdEnum.CN001,
      status: get(hierarchyCore, "basicDetail.stepCN001.status"),
      updatedAt: get(hierarchyCore, "basicDetail.stepCN001.updatedAt"),
    },
    {
      publicMerchantId: get(hierarchyCore, "billingDetail.publicMerchantId"),
      section: ConfigIdEnum.CN016,
      status: get(hierarchyCore, "billingDetail.stepCN016.status"),
      updatedAt: get(hierarchyCore, "billingDetail.stepCN016.updatedAt"),
    },
    {
      publicMerchantId: get(hierarchyCore, "contactDetail.publicMerchantId"),
      section: ConfigIdEnum.CN002,
      status: get(hierarchyCore, "contactDetail.stepCN002.status"),
      updatedAt: get(hierarchyCore, "contactDetail.stepCN002.updatedAt"),
    },
    {
      publicMerchantId: get(hierarchyCore, "legalDetail.publicMerchantId"),
      section: ConfigIdEnum.CN015,
      status: get(hierarchyCore, "billingDetail.stepCN015.status"),
      updatedAt: get(hierarchyCore, "legalDetail.stepCN015.updatedAt"),
    },
  ];
};

export const semaphoreBranches = (): SemaphoreData => {
  const semaphore: object = getSemaphoreBranches();

  return {
    publicMerchantId: get(semaphore, "basicDetail.publicMerchantId", ""),
    stepBasicData: {
      statusBasicDetail: get(semaphore, "stepCN001.status"),
      statusBillingDetail: get(semaphore, "stepCN016.status"),
      statusContactDetail: get(semaphore, "stepCN002.status"),
      statusLegalDetail: get(semaphore, "stepCN015.status"),
    },
  };
};

export const objectSaveMassiveBranch = (
  data:
    | MerchantData
    | MerchantBillingData
    | Partial<MerchantData>
    | IContactDataMassive
    | IMerchantPeopleNodeRequest,
  configId: ConfigIdEnum,
  listBranchesMassive: branchesSelectedList[],
  status: TabItemStatusEnum
): Request => {
  const listConfigBranchesNames: ConfigIdEnum[] = [
    ConfigIdEnum.CN001,
    ConfigIdEnum.CN002,
    ConfigIdEnum.CN015,
  ];
  const branchesUpdate: Branch[] = listBranchesMassive.map(
    (branch: branchesSelectedList) => {
      return {
        merchantId: branch.publicMerchantId,
        nodeId: branch.nodeId,
        ...(listConfigBranchesNames.includes(configId) && {
          merchantName: branch.name,
        }),
      };
    }
  );

  return {
    branches: branchesUpdate,
    configId,
    data,
    status,
    userAlias: "backoffice",
    userName: defaultTo(localStorage.getItem("username"), "backoffice"),
  };
};
