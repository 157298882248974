import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const paperFileUploadStyles = createNamedStyles({
  grid: {
    direction: "row !important",
    paddingRight: "0px !important",
  },
  gridImg: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  nameFile: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "15px",
      md: "15",
    },
    lineHeight: "144%",
    color: "#6D7781",
  },
  containerPaper: {
    alignItems: "center",
    borderRadius: "8px",
    color: "#6D7781",
    display: "flex",
    height: "72px",
    textAlign: "center",
    width: "292px",
  },
  container: {
    alignItems: "center",
  },
  containerImg: {
    paddingLeft: "16px",
    paddingRight: "8px",
  },
});
