import React, { FC } from "react";
import { notificationDetailStyles as styles } from "./NotificationDetail.styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { BreadCrumb, IconChevronLeft } from "@kushki/connect-ui";
import { CodeSnippet } from "../../components/common/CodeSnippet/CodeSnippet";
import * as formLabels from "../../shared/constants/labels/notification_details_labels";
import { useNotificationDetailState } from "./state/useNotificationDetailState";
import { RETURN_BUTTON_LABEL } from "../../shared/constants/labels/add_webhook_labels";
import { useNavigate } from "react-router-dom";
import {
  NOTIFICATION_CONFIG_TYPE,
  NOTIFICATION_CONFIG_TYPE_TEXT,
} from "./state/useNotificationDetailState.interfaces";

export const NotificationDetail: FC = () => {
  const { notificationDetail, breadCrumbItems } = useNotificationDetailState();
  const navigate = useNavigate();

  return (
    <Grid container sx={styles.container}>
      <Box sx={styles.header}>
        <Button
          className={"buttonLink"}
          startIcon={<IconChevronLeft />}
          onClick={() => navigate(-1)}
        >
          {RETURN_BUTTON_LABEL}
        </Button>
        <BreadCrumb items={breadCrumbItems} />
        <Typography variant={"h2"}>
          {formLabels.NOTIFICATION_DETAIL_TITLE}
        </Typography>
      </Box>
      <Card elevation={1}>
        <CardContent sx={styles.cardContent}>
          <Grid container>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_DATE}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography color="text.dark" variant="body2">
                {notificationDetail.date}
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_ORIGIN}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography color="text.dark" variant="body2">
                {notificationDetail.origin}
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_DESTINY}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography color="text.dark" variant="body2">
                {notificationDetail.destiny}
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_PAYMENT_METHOD}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Chip label={notificationDetail.paymentMethod} color="info" />
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_EVENT}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Chip label={notificationDetail.event} color="info" />
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_STATUS}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Chip
                label={notificationDetail.statusChip.statusText}
                color={notificationDetail.statusChip.color}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography color="text.primary" variant="body2">
                {formLabels.NOTIFICATION_CONFIG}
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <Chip
                label={NOTIFICATION_CONFIG_TYPE_TEXT[notificationDetail.config]}
                color="info"
              />
            </Grid>
            {notificationDetail.config !== NOTIFICATION_CONFIG_TYPE.API && (
              <>
                <Grid item md={2} xs={12}>
                  <Typography color="text.primary" variant="body2">
                    {formLabels.NOTIFICATION_ALIAS}
                  </Typography>
                </Grid>
                <Grid item md={10} xs={12}>
                  <Typography color="text.dark" variant="body2">
                    {notificationDetail.alias}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Box sx={styles.codeBlock}>
        <Typography variant={"h5"}>
          {formLabels.NOTIFICATION_BODY_REQUEST}
        </Typography>
        <CodeSnippet
          value={notificationDetail.bodyRequest}
          data-testid={"code-1"}
        />
      </Box>
      <Box sx={styles.codeBlock}>
        <Typography variant={"h5"}>
          {formLabels.NOTIFICATION_BODY_RESPONSE}
        </Typography>
        <CodeSnippet value={notificationDetail.bodyResponse} />
      </Box>
    </Grid>
  );
};
