import React, { PropsWithChildren } from "react";
import { UploadSectionProps } from "../../shared/infrastructure/interfaces/UploadSection.interface";
import { Button, Grid, Typography } from "@material-ui/core";
import { uploadSectionStyles } from "./UploadSection.styles";
import { CustomChip } from "../CustomChip/CustomChip";

export const UploadSection = (props: PropsWithChildren<UploadSectionProps>) => {
  const classes = uploadSectionStyles();

  return (
    <>
      <Grid container direction={"row"}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            <strong>{props.title}</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <input
            type="file"
            id={props.buttonId}
            accept={".xlsx, .xls"}
            style={{ display: "none" }}
            onChange={props.handleOnChange}
          />
          <label htmlFor={props.buttonId}>
            <Button
              className={classes.buttonText}
              variant={"outlined"}
              component="span"
              fullWidth
            >
              {props.buttonText}
            </Button>
          </label>
        </Grid>
        <Grid item xs={9}>
          {!props.isChooseFile ? (
            <Typography className={classes.fileStatusNormal}>
              {props.fileStatus}
            </Typography>
          ) : (
            <CustomChip
              isSuccessful={props.isFileLoadSuccessful}
              fileName={props.fileName}
              isSuccessFormatFile={props.isSuccessFormatFile}
              isSuccessFileSize={props.isSuccessFileSize}
            />
          )}
        </Grid>
      </Grid>
      {!props.isChooseFile && (
        <Typography className={classes.description}>
          {props.buttonDescription}
        </Typography>
      )}
    </>
  );
};
