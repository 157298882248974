import React from "react";
import { Indicators } from "../Indicators/Indicators";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { DataTableCustom } from "../Common/DataTableCustom/DataTableCustom";
import { IRetentionDashboardState } from "./state/useRetentionDashboardState";
import { defaultTo, get, isEmpty, sum } from "lodash";
import { Edit } from "react-feather";
import { Box, Chip, IconButton, Typography } from "@material-ui/core";
import { Cycle } from "../Common/Cycle/Cycle";
import {
  InitialStatusEnum,
  StatusEnum,
} from "../../shared/infrastructure/StatusEnum";
import NumberFormat from "react-number-format";
import { IColumnDefinition } from "../../shared/infrastructure/interfaces/IColumnDefinition";
import { TransactionDynamo } from "../../../types/remote/transaction_dynamo";
import { EditRetentionModal } from "../ConfirmModal/EditRetentionModal/EditRetentionModal";
import { FormProvider } from "react-hook-form";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IDefault } from "../../shared/infrastructure/interfaces/IDefault";
import { RejectConfirmModal } from "../ConfirmModal/RejectConfirmModal/RejectConfirmModal";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import { stylesChip } from "../../shared/infrastructure/constants/StylesChip";
import { ColumnsRetentionEnum } from "../../shared/infrastructure/table/IColumnsTable";
import { CountryList } from "../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import {
  RetentionCatalog,
  RetentionType,
} from "../../shared/infrastructure/constants/RetentionConstants";
import { CellWithDescription } from "../Common/CellWithDescription/CellWithDescription";
import { BranchHierarchyLabel } from "../../shared/infrastructure/BillingTabTitleEnum";

export interface IRetentionDashboardProps {
  country: CountryList;
  stateRentetionDashboard: IRetentionDashboardState;
  indicatorsFlow: StatusFlowEnum;
  isRetentionCountry?: boolean;
}
export const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};
const styles = makeStyles(() =>
  createStyles({
    chips: {
      textTransform: "uppercase",
      fontWeight: 500,
      borderRadius: "4px !important",
    },
  })
);
export const RetentionDashboard: React.FC<IRetentionDashboardProps> = (
  props: IRetentionDashboardProps
) => {
  const classes = styles();
  const {
    stateRentetionDashboard: {
      retentionData,
      handleInputChange,
      handleSearchBar,
      handleSetDate,
      rangeDate,
      queryInput,
      handleChangeChecbox,
      sort,
      onEdit,
      modalOverReject: {
        openRejectModal,
        handleAcceptRejectModal,
        handleCloseRejectModal,
        modalInfo,
      },
      modalHandlers,
      onSubmit,
      editForm,
      columnsSettings,
      filter,
      modalOverRetention,
      modalOverDetail,
      modalOverProcessRetention,
      isProcessData,
      downloadFileRetention,
      openModalModifyInconsistence,
      handleModalModifyInconsistence,
    },
    indicatorsFlow,
    isRetentionCountry,
  } = props;
  const validateFailedStatus = (row: IDefault) => {
    if (get(row, ColumnsRetentionEnum.STATUS, "") === InitialStatusEnum.FAILED)
      return "FALLIDO";

    return translate(get(row, ColumnsRetentionEnum.STATUS, ""));
  };

  const buildText = (label: string): JSX.Element => {
    return (
      <Typography variant={"body2"} noWrap>
        {label}
      </Typography>
    );
  };

  const retentionText = (
    retentionType: RetentionType,
    value: string | number
  ): string => {
    return RetentionCatalog[retentionType][value];
  };

  const getColumnInitialStatus = () => {
    return {
      field: ColumnsRetentionEnum.INITIAL_STATUS,
      headerName: "E. Inicial",
      view: columnsSettings.selectedColumns.includes("initialStatus"),
      filter: true,
      disabledFilter: false,
      renderCell: function status(row: IDefault) {
        return (
          <Chip
            style={stylesChip(get(row, ColumnsRetentionEnum.STATUS, ""))}
            className={classes.chips}
            label={validateFailedStatus(row)}
            size="small"
          />
        );
      },
    };
  };

  function getHierarchyLabel(row: IDefault): string {
    return !defaultTo(get(row, "isOcb"), false)
      ? "-"
      : row.customerId === row.merchantId
      ? BranchHierarchyLabel.DECENTRALIZED
      : BranchHierarchyLabel.CENTRALIZED;
  }

  function getHierarchyCols(): IColumnDefinition[] {
    const ownerColumn: IColumnDefinition = {
      field: ColumnsRetentionEnum.OWNER,
      headerName: "Owner",
      renderCell: function owner(row) {
        return (
          <CellWithDescription
            title={row.ownerName}
            description={row.ownerId}
          />
        );
      },
      sortable: {
        ...sort,
        orderBy: ColumnsRetentionEnum.OWNER,
      },
      view: columnsSettings.selectedColumns.includes(
        ColumnsRetentionEnum.OWNER
      ),
      filter: true,
    };
    const customerColumn: IColumnDefinition = {
      field: ColumnsRetentionEnum.CUSTOMER,
      headerName: "Customer",
      renderCell: function customer(row) {
        return (
          <CellWithDescription
            title={row.customerName}
            description={row.customerIdHierarchy}
          />
        );
      },
      sortable: {
        ...sort,
        orderBy: ColumnsRetentionEnum.CUSTOMER,
      },
      view: columnsSettings.selectedColumns.includes(
        ColumnsRetentionEnum.CUSTOMER
      ),
      filter: true,
    };
    const hierarchyColumn: IColumnDefinition = {
      field: ColumnsRetentionEnum.HIERARCHY,
      headerName: "Jerarquía",
      view: columnsSettings.selectedColumns.includes(
        ColumnsRetentionEnum.HIERARCHY
      ),
      filter: true,
      renderCell: function hierarchy(row) {
        return <>{getHierarchyLabel(row)}</>;
      },
    };

    return [ownerColumn, customerColumn, hierarchyColumn];
  }

  function renderColumnsByStatusFlow(
    country: CountryList
  ): IColumnDefinition[] {
    let columns: IColumnDefinition[];
    if (country === CountryEnum.colombia) {
      columns = [
        {
          field: ColumnsRetentionEnum.MERCHANT_NAME,
          headerName: "Branch/Merchant",
          renderCell: function descriptionName(row) {
            return (
              <CellWithDescription
                title={get(row, ColumnsRetentionEnum.MERCHANT_NAME, "")}
                description={get(row, ColumnsRetentionEnum.MERCHANT_ID, "")}
              />
            );
          },
          sortable: {
            ...sort,
            orderBy: ColumnsRetentionEnum.MERCHANT_NAME,
          },
          view: columnsSettings.selectedColumns.includes(
            ColumnsRetentionEnum.MERCHANT_NAME
          ),
          filter: true,
          disabledFilter: false,
        },
        ...getHierarchyCols(),
        {
          field: ColumnsRetentionEnum.CYCLE,
          headerName: "Ciclo",
          renderCell: function cycle(row) {
            return <Cycle transaction={row} country={CountryEnum.colombia} />;
          },
          view: columnsSettings.selectedColumns.includes("cycle"),
          filter: true,
          disabledFilter: false,
        },
        {
          field: ColumnsRetentionEnum.CITY_ID,
          headerName: "Municipio",
          view: columnsSettings.selectedColumns.includes(
            ColumnsRetentionEnum.CITY_ID
          ),
          filter: true,
          renderCell: function (row) {
            return buildText(
              retentionText(RetentionType.city, get(row, "cityId", "-"))
            );
          },
          disabledFilter: false,
        },
        {
          field: ColumnsRetentionEnum.MODEL,
          headerName: "Modelo",
          renderCell: function model(row) {
            const modelLabel =
              RetentionCatalog[RetentionType.processorType][
                get(row, "processorType", "")
              ];
            let splitted = ["", ""];
            if (!isEmpty(modelLabel)) splitted = modelLabel.split(" ");
            return (
              <>
                <Typography>{splitted[0]}</Typography>
                <Typography>{splitted[1]}</Typography>
              </>
            );
          },
          view: columnsSettings.selectedColumns.includes(
            ColumnsRetentionEnum.MODEL
          ),
          filter: true,
          disabledFilter: false,
        },
        {
          field: ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
          headerName: "Retención total Kushki",
          view: true,
          filter: true,
          disabledFilter: true,
          renderCell: function (row) {
            return formatNumber(
              sum([
                get(row, "amountRetIva", 0),
                get(row, "amountRetIca", 0),
                get(row, "amountRetSource", 0),
              ]),
              get(row, "currency", "USD")
            );
          },
        },
        {
          field: ColumnsRetentionEnum.RET_TOTAL_AMOUNT_DAVI,
          headerName: "Retención total Davivienda",
          view: true,
          filter: true,
          disabledFilter: true,
          renderCell: function (row) {
            return formatNumber(
              get(row, ColumnsRetentionEnum.RET_TOTAL_AMOUNT_DAVI, 0),
              get(row, "currency", "USD")
            );
          },
        },
        {
          field: ColumnsRetentionEnum.MERCHANT_ID,
          headerName: "Merchant ID",
          view: columnsSettings.selectedColumns.includes(
            ColumnsRetentionEnum.MERCHANT_ID
          ),
          filter: true,
          disabledFilter: false,
        },
        {
          field: ColumnsRetentionEnum.SOCIAL_REASON,
          headerName: "Razón Social",
          view: columnsSettings.selectedColumns.includes(
            ColumnsRetentionEnum.SOCIAL_REASON
          ),
          filter: true,
          disabledFilter: false,
        },
      ];
      StatusFlowEnum.EXECUTOR === indicatorsFlow
        ? (columns = columns.concat([
            {
              field: ColumnsRetentionEnum.STATUS_PROCESS,
              headerName: "Procesar",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.PROCESS,
              },
              view: true,
            },
            {
              field: ColumnsRetentionEnum.STATUS_OMIT,
              headerName: "Omitir",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.OMIT,
              },
              view: true,
            },
          ]))
        : (columns = columns.concat([
            {
              field: ColumnsRetentionEnum.STATUS_INITIALIZED,
              headerName: "Aprobar",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.PROCESS,
              },
              view: true,
            },
            {
              field: ColumnsRetentionEnum.STATUS_REJECTED,
              headerName: "Rechazar",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.REJECT,
              },
              view: true,
            },
          ]));
    } else {
      columns = [
        {
          field: ColumnsRetentionEnum.TAX_ID,
          headerName: "Tax ID",
          view: true,
          filter: true,
          disabledFilter: true,
        },
        {
          field: ColumnsRetentionEnum.MERCHANT_NAME,
          headerName: "Branch/Merchant",
          renderCell: function descriptionName(row) {
            return (
              <CellWithDescription
                title={get(row, ColumnsRetentionEnum.MERCHANT_NAME, "")}
                description={get(row, ColumnsRetentionEnum.MERCHANT_ID, "")}
              />
            );
          },
          sortable: {
            ...sort,
            orderBy: ColumnsRetentionEnum.MERCHANT_NAME,
          },
          view: true,
          filter: true,
          disabledFilter: true,
        },
        ...getHierarchyCols(),
        {
          field: ColumnsRetentionEnum.CYCLE,
          headerName: "Ciclo",
          renderCell: function cycle(row) {
            return <Cycle transaction={row} />;
          },
          view: columnsSettings.selectedColumns.includes("cycle"),
          filter: true,
        },
        {
          field: ColumnsRetentionEnum.RET_IVA,
          headerName: "Monto ret. IVA",
          sortable: {
            ...sort,
            orderBy: ColumnsRetentionEnum.RET_IVA,
          },
          view: true,
          filter: true,
          disabledFilter: true,
          renderCell: function (row) {
            return formatNumber(
              get(row, ColumnsRetentionEnum.RET_IVA, 0),
              get(row, "currency", "USD")
            );
          },
        },
        {
          field: ColumnsRetentionEnum.RET_FUE,
          headerName: "Monto ret. Renta",
          sortable: {
            ...sort,
            orderBy: ColumnsRetentionEnum.RET_FUE,
          },
          view: true,
          filter: true,
          disabledFilter: true,
          renderCell: function (row) {
            return formatNumber(
              get(row, ColumnsRetentionEnum.RET_FUE, 0),
              get(row, "currency", "USD")
            );
          },
        },
        {
          field: ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
          headerName: "Monto Total",
          sortable: {
            ...sort,
            orderBy: ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
          },
          view: true,
          filter: true,
          disabledFilter: true,
          renderCell: function (row) {
            return formatNumber(
              get(row, ColumnsRetentionEnum.RET_TOTAL_AMOUNT, 0),
              get(row, "currency", "USD")
            );
          },
        },
      ];
      StatusFlowEnum.EXECUTOR === indicatorsFlow
        ? (columns = columns.concat([
            {
              field: ColumnsRetentionEnum.EDIT,
              headerName: "Editar",
              view: true,
              renderCell: function edit(row) {
                return (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onEdit(row as TransactionDynamo);
                      }}
                    >
                      <Edit size={18} style={{ color: "#b3c2c7" }} />
                    </IconButton>
                  </Box>
                );
              },
            },
            getColumnInitialStatus(),
            {
              field: ColumnsRetentionEnum.STATUS_PROCESS,
              headerName: "Procesar",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.PROCESS,
              },
              view: true,
            },
            {
              field: ColumnsRetentionEnum.STATUS_PENDING,
              headerName: "Pendiente",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.PENDING,
              },
              view: true,
            },
          ]))
        : (columns = columns.concat([
            getColumnInitialStatus(),
            {
              field: ColumnsRetentionEnum.STATUS_INITIALIZED,
              headerName: "Aprobar",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.PROCESS,
              },
              view: true,
            },
            {
              field: ColumnsRetentionEnum.STATUS_REJECTED,
              headerName: "Rechazar",
              checkeable: {
                field: "selectedStatus",
                compare: StatusEnum.REJECT,
              },
              view: true,
            },
          ]));
    }

    return columns;
  }
  return (
    <>
      {get(retentionData, "records", []).length > 0 && (
        <Indicators
          statusFlow={indicatorsFlow}
          isRetentionCountry={isRetentionCountry}
          country={props.country}
        />
      )}
      <DataTableCustom
        handleInputChange={handleInputChange}
        handleSearchBar={handleSearchBar}
        queryInput={queryInput}
        columns={renderColumnsByStatusFlow(props.country)}
        records={get(retentionData, "records", [])}
        skeleton={retentionData.isLoading}
        handleChangeCheckbox={handleChangeChecbox}
        statusFlow={indicatorsFlow}
        filter={filter}
        modalOverRetention={modalOverRetention}
        columnsSettings={columnsSettings}
        modalOverProcessRetention={modalOverProcessRetention}
        isProcessData={isProcessData}
        downloadFileRetention={downloadFileRetention}
        country={props.country}
        handleSetDate={handleSetDate}
        rangeDate={rangeDate}
        openModalModifyInconsistence={openModalModifyInconsistence}
        handleModalModifyInconsistence={handleModalModifyInconsistence}
        modalOverDetail={modalOverDetail}
      />
      <RejectConfirmModal
        open={openRejectModal}
        handleCloseReject={handleCloseRejectModal}
        handleAcceptReject={handleAcceptRejectModal}
        title={modalInfo.title}
        acceptText={modalInfo.accept}
        label={modalInfo.label}
        maxCharacter={200}
        content={
          <Box fontSize={16}>
            <p>{modalInfo.body}</p>
            <p>{modalInfo.confirm}</p>
          </Box>
        }
      />

      <FormProvider {...editForm}>
        <EditRetentionModal
          open={modalHandlers.openEditRetentionModal}
          handleCloseEditRetention={() =>
            modalHandlers.handleOpenEditRetention(false)
          }
          handleAcceptEditRetention={editForm.handleSubmit(onSubmit)}
          retention={modalHandlers.retentionRowToEdit}
          disableAccept={
            !isEmpty(editForm.formState.errors) || !editForm.formState.isValid
          }
        />
      </FormProvider>
    </>
  );
};
