import React from "react";

import { DateRange } from "@material-ui/pickers";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";
import { makeStyles } from "@material-ui/core/styles";

export interface DateRangeProps {
  handleDate: (dateRange: DateRange<Date>) => void;
  disableFuture: boolean;
  value: DateRange<Date>;
  enableTimePicker?: boolean;
  minDate?: Date;
}
const useStyles = makeStyles(() => ({
  rangePicker: {
    "& .MuiInputBase-root": {
      height: "42px !important",
    },
    width: "260px",
  },
}));

export const DateRangePicker = (props: DateRangeProps): JSX.Element => {
  const { handleDate, minDate, enableTimePicker, disableFuture, value } = props;
  const classes = useStyles();
  return (
    <div className={classes.rangePicker}>
      <KshDateRangePicker
        handleDate={handleDate}
        disableFuture={disableFuture}
        value={value}
        enableTimePicker={enableTimePicker}
        minDate={minDate}
      />
    </div>
  );
};
