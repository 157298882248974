import { useEffect, useMemo, useRef, useState } from "react";
import { FilterSideBarProps } from "../../Filters/FilterSideBar/FilterSideBar.interfaces";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../Filters/ItemCategory/interfaces";
import { defaultTo, findIndex, get, set } from "lodash";
import { FILTER_GROUPS } from "../../../shared/constants/labels/header_filters_labels";
import { HeaderFiltersProps } from "../HeaderFilters.interfaces";
import {
  DEFAULT_OPERATIONAL_COUNTRIES,
  FILTER_SIDE_BAR_INITIAL_STATE,
  FILTER_SIDE_BAR_INITIAL_STATE_MASTER_CUSTOMER,
  getItemsFilterDetailBar,
} from "../HeaderFilters.constants";
import { IUseHeaderFiltersState } from "./useHeaderFiltersState.interfaces";
import * as React from "react";
import moment from "moment";
import { CatalogTranslateMonth } from "../../../shared/enums/BranchEnum";
import { isCustomerRol } from "../../../shared/utils";

export const useHeaderFiltersState = ({
  additionalCountries,
  getItemsSelected,
  resetFilters,
}: HeaderFiltersProps): IUseHeaderFiltersState => {
  const [filterSideBarProps, setFilterSideBarProps] =
    useState<FilterSideBarProps>(
      isCustomerRol()
        ? FILTER_SIDE_BAR_INITIAL_STATE_MASTER_CUSTOMER
        : FILTER_SIDE_BAR_INITIAL_STATE
    );
  const [itemsDetailBar, setItemsDetailBar] = useState<TFilterDetailBar[]>([]);
  const [showRangePicker, setShowRangePicker] = useState<boolean>(true);

  const [valueSearch, setValueSearch] = useState<string>("");

  const onClickFilterSideBar = (itemCategory: ItemCategoryProps[]) => {
    const filterSelected = getItemsFilterDetailBar(itemCategory);

    filterSideBarProps.onClick(itemCategory);
    setItemsDetailBar(filterSelected);
    if (getItemsSelected) getItemsSelected(filterSelected);
  };

  const onEnterName = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const value = get(e, "target.value", "");

    let filtersAndName: TFilterDetailBar[] = [
      ...itemsDetailBar,
      {
        itemCategoryType: "byChips",
        items: [{ label: value, value: value }],
        title: "Nombre",
      },
    ];

    if (e.key === "Enter" || (e.key === "Backspace" && value === "")) {
      getItemsSelected && getItemsSelected(filtersAndName);
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValueSearch(get(e, "target.value", ""));
  };

  const resetSearchText = () => {
    let filtersAndName: TFilterDetailBar[] = [
      ...itemsDetailBar,
      {
        itemCategoryType: "byChips",
        items: [{ label: "", value: "" }],
        title: "Nombre",
      },
    ];

    getItemsSelected && getItemsSelected(filtersAndName);
  };

  const convertDateCorrectFormat = (date: string): string => {
    const day = date.split("/")[0];
    const month = CatalogTranslateMonth[date.split("/")[1]];
    const year = date.split("/")[2];

    return `${year}/${month}/${day}`;
  };

  const onDateSelected = (startDate: string, finishDate: string) => {
    let filtersAndName: TFilterDetailBar[] = [
      ...itemsDetailBar,
      {
        itemCategoryType: "byChips",
        items: [
          {
            label: "startDate",
            value: moment(convertDateCorrectFormat(startDate))
              .format("x")
              .toString(),
          },
        ],
        title: "RangeDateFrom",
      },
      {
        itemCategoryType: "byChips",
        items: [
          {
            label: "finishDate",
            value: moment(convertDateCorrectFormat(finishDate))
              .format("x")
              .toString(),
          },
        ],
        title: "RangeDateTo",
      },
    ];

    getItemsSelected && getItemsSelected(filtersAndName);
  };

  const changeCategoryItems = (newCategoryItems: ItemCategoryProps[]) => {
    setFilterSideBarProps({
      ...filterSideBarProps,
      categoryItems: newCategoryItems,
    });
  };

  const onChangeFiltersDetailBar = (changes: TOnChangeFilters) => {
    let itemsSideBar: ItemCategoryProps[] = get(
      filterSideBarProps,
      "categoryItems"
    );
    let itemsSideBarFinal: ItemCategoryProps[] = [];

    itemsSideBar.forEach((itemSideBar: ItemCategoryProps) => {
      let itemsFilterSideBar: ItemCategoryItem[] = [];

      itemSideBar.items.forEach((itemFilterSideBar: ItemCategoryItem) => {
        if (
          changes.affected.itemDeleted.label === itemFilterSideBar.label &&
          changes.affected.title === itemSideBar.groupSection
        )
          set(itemFilterSideBar, "selected", false);

        itemsFilterSideBar.push(itemFilterSideBar);
      });
      set(itemSideBar, "items", itemsFilterSideBar);
      itemsSideBarFinal.push(itemSideBar);
    });
    changeCategoryItems(itemsSideBarFinal);
    onClickFilterSideBar(itemsSideBarFinal);
  };

  const countriesWithAdditional: ItemCategoryItem[] = useMemo(() => {
    return [
      ...DEFAULT_OPERATIONAL_COUNTRIES,
      ...defaultTo(additionalCountries, []),
    ];
  }, [additionalCountries]);

  useEffect(() => {
    const indexConstitutional: number = findIndex(
      filterSideBarProps.categoryItems,
      { groupSection: FILTER_GROUPS.CONSTITUTIONAL_COUNTRY }
    );

    const newCategoryItems: ItemCategoryProps[] = [
      ...filterSideBarProps.categoryItems,
    ];

    newCategoryItems[indexConstitutional].items = countriesWithAdditional;

    changeCategoryItems(newCategoryItems);
  }, [countriesWithAdditional]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }
    onClickFilterSideBar([]);
    resetSearchText();
    setValueSearch("");
    setItemsDetailBar([]);
    setShowRangePicker(false);
    setTimeout(() => {
      setShowRangePicker(true);
    }, 0.1);
  }, [resetFilters]);

  return {
    filterSideBarProps,
    itemsDetailBar,
    onChange,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
    onDateSelected,
    onEnterName,
    showRangePicker,
    valueSearch,
  };
};
