import React from "react";
import { ITableConfiguration } from "../../components/TableComponent/TableComponent.interfaces";
import { GetAllModulesResponse } from "../../../types/get_all_modules_response";
import ChipStatus from "../../components/ChipStatus/ChipStatus";
import { get } from "lodash";
import { StatusEnum } from "../enums/statusEnum";

export const HEADERS_MODULES: ITableConfiguration<GetAllModulesResponse>[] = [
  {
    label: "Descripción",
    value: "description",
  },
  {
    label: "Id del módulo",
    value: "id",
  },
  {
    label: "Rol",
    value: "roles",
  },
  {
    customRender: (row) => (
      <ChipStatus status={get(row, "status") as StatusEnum} />
    ),
    label: "Estado",
    value: "status",
  },
];
