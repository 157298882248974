export enum FileFormatTitleEnum {
  CSV_UPLOAD = "Cargar archivo .csv",
  JSON_DOWNLOAD = "Descargar .json",
  EXCEL_DOWNLOAD = "Descargar .xlsx",
  CSV_DOWNLOAD = "Descargar .csv",
}

export enum FileFormatIDsEnum {
  CSV_UPLOAD = "csv-upload",
  JSON_DOWNLOAD = "json",
  EXCEL_DOWNLOAD = "xlsx",
  CSV_DOWNLOAD = "csv",
}
