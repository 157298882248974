import React from "react";
import { Badge, Box, Button, createStyles } from "@material-ui/core";
import { Filter as FilterIcon } from "react-feather";
import {
  IFilterResponse,
  IOptionFilter,
} from "@kushki/frontend-molecules/filter-container";
import { Filter } from "@kushki/frontend-molecules/filter";
import { makeStyles } from "@material-ui/core/styles";
import { cloneDeep } from "lodash";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";

export interface IRangeFilter {
  currency?: string;
  id: string;
  maxAmount: number;
  rangeAmount: IRangeAmount;
  title: string;
}

export interface FilterComponentProps {
  rangeFilters?: IRangeFilter[];
  filters: IOptionFilter[];
  onApplyFilter: (response: IFilterResponse) => void;
  onClearFilter: () => void;
  filterCount: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    badgedColor: {
      backgroundColor: "rgba(13,194,152,1)",
      color: "rgba(240, 244, 248, 1)",
    },
    buttonBadge: {
      backgroundColor: "#FFFFFF",
      height: 40,
      textTransform: "none",
    },
    box: {
      margin: "10px 0 30px 0",
    },
  })
);

const FilterComponent = (props: FilterComponentProps): JSX.Element => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenModal(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };
  const handleApply = (response: IFilterResponse) => {
    props.onApplyFilter(response);
    handleClose();
  };
  const handleClear = () => {
    handleClose();
    props.onClearFilter();
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={classes.box}>
      <Badge
        color="secondary"
        badgeContent={props.filterCount}
        classes={{
          colorSecondary: classes.badgedColor,
        }}
      >
        <Button
          className={classes.buttonBadge}
          variant="outlined"
          startIcon={<FilterIcon size={16} />}
          onClick={handleClick}
        >
          Filtros
        </Button>
      </Badge>
      <Filter
        idPopover={"simple-popover"}
        filters={{
          listFilters: {
            filters: cloneDeep(props.filters),
            multiple: true,
          },
        }}
        openPopover={open}
        openModal={openModal}
        anchorPopover={anchorEl}
        handleApplyFilters={handleApply}
        handleClose={handleClose}
        handleClearFilters={handleClear}
        isMobile={false}
      />
    </Box>
  );
};

export default FilterComponent;
