import React, { RefObject } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  getCatalog,
  INVOICE_FRECUENCY,
} from "../../shared/infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../../shared/infrastructure/constants/catalogs-enum";
import { MerchantResponse } from "../../../types/merchant_response";
import {
  Control,
  Controller,
  FieldError,
  NestDataObject,
} from "react-hook-form";
import { CountriesEnum } from "../../shared/infrastructure/constants/countries-enum";
import { PatternEnum } from "../../shared/infrastructure/constants/pattern-enum";

export interface DispersionEditSectionProps {
  merchant: MerchantResponse;
  control: Control<Record<string, any>>;
  handleFormChange: (
    state: string,
    name: string,
    value: string | boolean
  ) => void;
  register: (
    obj: object
  ) => ((instance: any) => void) | RefObject<any> | null | undefined;
  errors: NestDataObject<Record<string, any>, FieldError>;
  onAddAccount: () => void;
  addAccount: boolean;
}

const useStyles = makeStyles(() => ({
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelColumns: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelValues: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelMessage: {
    color: "rgba(46, 61, 74, 0.7)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  styleSelect: {
    width: "60%",
  },
}));

export const DispersionEditSection: React.FC<DispersionEditSectionProps> = (
  props: DispersionEditSectionProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>
                  Dispersión
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelTitle3}>
              Indica la frecuencia y datos de cuenta para la dispersión.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item container xs={12} md={12} spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography className={style.labelColumns}>
                  Frecuencia
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className={style.styleSelect} variant={"outlined"}>
                  <InputLabel htmlFor="dispersionFrequency">
                    Frecuencia
                  </InputLabel>
                  <Controller
                    control={props.control}
                    id="dispersionFrequency"
                    name="dispersionFrequency"
                    as={
                      <Select
                        label="Frecuencia"
                        value={props.merchant.dispersionFrequency}
                      >
                        {INVOICE_FRECUENCY.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    defaultValue={props.merchant.dispersionFrequency}
                    valueName="dispersionFrequencySel"
                    onChange={([dispersionFrequencySel]) =>
                      props.handleFormChange(
                        "merchant",
                        "dispersionFrequency",
                        dispersionFrequencySel.target.value as string
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            {props.merchant.dispersion!.accountInfo!.map((info, index) => (
              <Grid
                item
                container
                xs={12}
                md={12}
                key={index}
                spacing={2}
                hidden={
                  index !== 0 &&
                  info.accountNumber === undefined &&
                  !props.addAccount
                }
              >
                <Grid item xs={12} md={2}>
                  <Typography className={style.labelColumns}>Banco</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    className={style.styleSelect}
                    variant={"outlined"}
                  >
                    <InputLabel htmlFor={`bankId${index}`}>Banco</InputLabel>
                    <Controller
                      control={props.control}
                      id={`bankId${index}`}
                      name={`bankId${index}`}
                      as={
                        <Select label="Banco" value={info.bankId}>
                          {getCatalog(
                            props.merchant.country,
                            CatalogsEnum.Banks
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      defaultValue={info.bankId}
                      valueName="bankIdSel"
                      onChange={([bankIdSel]) =>
                        props.handleFormChange(
                          "merchant",
                          `dispersion.accountInfo[${index}].bankId`,
                          bankIdSel.target.value as string
                        )
                      }
                    />
                    {!!props.errors[`bankId${index}`] && (
                      <FormHelperText>
                        {props.errors[`bankId${index}`].message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className={style.labelColumns}>
                    Cta. Bancaria
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id={`accountNumber${index}`}
                    name={`accountNumber${index}`}
                    className={style.styleSelect}
                    label="N. de cuenta"
                    variant="outlined"
                    defaultValue={info.accountNumber}
                    onChange={(e) =>
                      props.handleFormChange(
                        "merchant",
                        `dispersion.accountInfo[${index}].accountNumber`,
                        e.target.value
                      )
                    }
                    inputRef={props.register({
                      required:
                        index === 0
                          ? "Debe ingresar una cuenta bancaria."
                          : false,
                      pattern: {
                        value:
                          props.merchant!.country === CountriesEnum.COLOMBIA
                            ? new RegExp(PatternEnum.numeric5to20)
                            : "",
                        message:
                          props.merchant!.country === CountriesEnum.COLOMBIA
                            ? "Deber tener entre 5 y 20 dígitos"
                            : "",
                      },
                    })}
                    helperText={
                      props.errors[`accountNumber${index}`]
                        ? props.errors[`accountNumber${index}`].message
                        : ""
                    }
                    error={!!props.errors[`accountNumber${index}`]}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className={style.labelColumns}>Moneda</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    className={style.styleSelect}
                    variant={"outlined"}
                  >
                    <InputLabel htmlFor={`currency${index}`}>Moneda</InputLabel>
                    <Controller
                      control={props.control}
                      id={`currency${index}`}
                      name={`currency${index}`}
                      as={
                        <Select label="Moneda" value={info.currency}>
                          {getCatalog(
                            props.merchant.country,
                            CatalogsEnum.Currency
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      defaultValue={info.currency}
                      valueName="currencySel"
                      onChange={([currencySel]) =>
                        props.handleFormChange(
                          "merchant",
                          `dispersion.accountInfo[${index}].currency`,
                          currencySel.target.value as string
                        )
                      }
                    />
                    {!!props.errors[`currency${index}`] && (
                      <FormHelperText>
                        {props.errors[`currency${index}`].message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography className={style.labelColumns}>
                    Tipo de cuenta
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    className={style.styleSelect}
                    variant={"outlined"}
                  >
                    <InputLabel htmlFor={`accountType${index}`}>
                      Tipo de cuenta
                    </InputLabel>
                    <Controller
                      control={props.control}
                      id={`accountType${index}`}
                      name={`accountType${index}`}
                      as={
                        <Select label="Tipo de cuenta" value={info.accountType}>
                          {getCatalog(
                            props.merchant.country,
                            CatalogsEnum.BankAccountTypes
                          ).map((accountType) => (
                            <MenuItem
                              key={accountType.value}
                              value={accountType.value}
                            >
                              {accountType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      defaultValue={info.accountType}
                      valueName="accountTypeSel"
                      onChange={([accountTypeSel]) =>
                        props.handleFormChange(
                          "merchant",
                          `dispersion.accountInfo[${index}].accountType`,
                          accountTypeSel.target.value as string
                        )
                      }
                    />
                    {!!props.errors[`accountType${index}`] && (
                      <FormHelperText>
                        {props.errors[`accountType${index}`].message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            ))}
            {props.merchant.country === CountriesEnum.PERU ? (
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={12}
                  hidden={
                    (props.merchant.dispersion!.accountInfo!.length > 1 &&
                      props.merchant.dispersion!.accountInfo![1]
                        .accountNumber !== undefined) ||
                    props.merchant.dispersion!.accountInfo!.length > 1
                  }
                >
                  <Button
                    variant={"outlined"}
                    color={"default"}
                    onClick={props.onAddAccount}
                  >
                    Agregar
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>
                  Valores de dispersión
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.merchant.dispersion!.keepCommission}
                  onChange={(e) =>
                    props.handleFormChange(
                      "merchant",
                      "dispersion.keepCommission",
                      e.target.checked
                    )
                  }
                  name="keepCommission"
                  color="primary"
                />
              }
              label="Desactivar"
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Typography className={style.labelMessage}>
              Se desactiva debe asegurarse de contar con una cuenta de
              dispersión.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelMessage}>
              *Todo cambio realizado será aplicado a partir del siguiente mes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
