import { RootState } from "../store";

export const selectPaymentMethods = (state: RootState) =>
  state.app.paymentMethods;

export const selectPaymentMethodsStatus = (state: RootState) =>
  state.app.paymentMethodsStatus;

export const selectCreateExpressCheckoutStatus = (state: RootState) =>
  state.app.createExpressCheckoutStatus;

export const selectExpressCheckout = (state: RootState) =>
  state.app.expressCheckoutResponse;

export const selectIvaValues = (state: RootState) => state.app.ivaValues;
