import { get } from "lodash";

const sortCatalog = {
  [0]: 1,
  [1]: 1,
  [2]: -1,
};

function dynamicSort<T>(item1: T, item2: T, property: string, sort: number) {
  const sortOrder = sortCatalog[sort];

  const result =
    get(item1, property, "") < get(item2, property, "")
      ? -1
      : get(item1, property, "") > get(item2, property, "")
      ? 1
      : 0;
  return result * sortOrder;
}
export const sortObjectArray = <T>(
  array: T[],
  property: string,
  click: number
) => array.sort((item1, item2) => dynamicSort(item1, item2, property, click));
