import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  SecurityRulesLabelField,
  SPEED_ACTION_VALUES,
  SPEED_PERIODICITY_VALUES,
  SPEED_TRANSACTION_STATUS_VALUES,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";
import { useWatch } from "react-hook-form";
import { NumberMaskCustom } from "../MaskCustom/MaskCustom";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import { RegexExpressionEnum } from "../../shared/infrastructure/constants/RegexExpressionEnum";

export interface SpeedConditionsSectionProps {
  index: number;
  total: string[];
  addSpeedCondition: (index: number) => void;
  removeSpeedCondition: (item: number) => void;
}

export const SpeedConditionsSection: React.FC<SpeedConditionsSectionProps> = (
  props: SpeedConditionsSectionProps
) => {
  return (
    <ConnectForm<ISecurityRuleForm>>
      {({ control, errors, getErrorMessage }) => {
        const TypedController = useTypedController<ISecurityRuleForm>({
          control: control,
        });

        const controlSpeed = useWatch({
          name: `speedCondition[${props.index}].control`,
        }) as string;

        const getRules = () => {
          return controlSpeed === "sum"
            ? {
                required: true,
                pattern: {
                  value: new RegExp(RegexExpressionEnum.CURRENCY_WITH_FRACTION),
                  message: ErrorsEnum.ONLY_NUMBERS_WITH_FRACTION,
                },
              }
            : { required: true };
        };

        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <FormControl
                variant="outlined"
                error={!!get(errors, `speedCondition.${props.index}.control`)}
                fullWidth
              >
                <InputLabel>{SecurityRulesLabelField.ACTION}</InputLabel>
                <TypedController
                  name={["speedCondition", props.index, "control"]}
                  rules={{ required: true }}
                  render={(props) => (
                    <Select {...props} label={SecurityRulesLabelField.ACTION}>
                      {SPEED_ACTION_VALUES.map(
                        (
                          actionValue: { name: string; value: string },
                          index: number
                        ) => (
                          <MenuItem key={index} value={actionValue.value}>
                            {actionValue.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
                <FormHelperText
                  error={!!get(errors, `speedCondition.${props.index}.control`)}
                >
                  {!!get(errors, `speedCondition.${props.index}.control`) &&
                    getErrorMessage(
                      get(errors, `speedCondition.${props.index}.control`)
                    )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl
                variant="outlined"
                error={
                  !!get(errors, `speedCondition.${props.index}.periodicity`)
                }
                fullWidth
              >
                <InputLabel>{SecurityRulesLabelField.PERIODICITY}</InputLabel>
                <TypedController
                  name={["speedCondition", props.index, "periodicity"]}
                  rules={{ required: true }}
                  render={(props) => (
                    <Select
                      {...props}
                      label={SecurityRulesLabelField.PERIODICITY}
                    >
                      {SPEED_PERIODICITY_VALUES.map(
                        (
                          actionValue: { name: string; value: string },
                          index: number
                        ) => (
                          <MenuItem key={index} value={actionValue.value}>
                            {actionValue.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
                <FormHelperText
                  error={
                    !!get(errors, `speedCondition.${props.index}.periodicity`)
                  }
                >
                  {!!get(errors, `speedCondition.${props.index}.periodicity`) &&
                    getErrorMessage(
                      get(errors, `speedCondition.${props.index}.periodicity`)
                    )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl
                variant="outlined"
                error={
                  !!get(
                    errors,
                    `speedCondition.${props.index}.transactionStatus`
                  )
                }
                fullWidth
              >
                <InputLabel>{SecurityRulesLabelField.TRANSACTION}</InputLabel>
                <TypedController
                  name={["speedCondition", props.index, "transactionStatus"]}
                  rules={{ required: true }}
                  render={(props) => (
                    <Select
                      {...props}
                      label={SecurityRulesLabelField.TRANSACTION}
                    >
                      {SPEED_TRANSACTION_STATUS_VALUES.map(
                        (
                          actionValue: { name: string; value: string },
                          index: number
                        ) => (
                          <MenuItem key={index} value={actionValue.value}>
                            {actionValue.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
                <FormHelperText
                  error={
                    !!get(
                      errors,
                      `speedCondition.${props.index}.transactionStatus`
                    )
                  }
                >
                  {!!get(
                    errors,
                    `speedCondition.${props.index}.transactionStatus`
                  ) &&
                    getErrorMessage(
                      get(
                        errors,
                        `speedCondition.${props.index}.transactionStatus`
                      )
                    )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <TypedController
                name={["speedCondition", props.index, "value"]}
                rules={getRules()}
                render={(properties) => (
                  <TextField
                    {...properties}
                    id="value"
                    label={SecurityRulesLabelField.VALUE}
                    type={controlSpeed === "sum" ? "number" : "text"}
                    InputProps={{
                      inputComponent:
                        controlSpeed === "count"
                          ? (NumberMaskCustom as any)
                          : undefined,
                    }}
                    variant="outlined"
                    error={!!get(errors, `speedCondition.${props.index}.value`)}
                    fullWidth
                    helperText={
                      !!get(errors, `speedCondition.${props.index}.value`) &&
                      getErrorMessage(
                        get(errors, `speedCondition.${props.index}.value`)
                      )
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};

export default SpeedConditionsSection;
