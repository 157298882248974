import { IDataSet } from "../../components/InputForms/InputsFormProps.interfaces";
import { ColombiaDocumentTypeEnum } from "./ColombiaDocumentTypeEnum";
import { PeruDocumentTypeEnum } from "./PeruDocumentTypeEnum";

export const COLOMBIA_DOCUMENT_TYPE_VALUES: IDataSet[] = [
  {
    text: "Cédula",
    value: ColombiaDocumentTypeEnum.CEDULA,
  },
  {
    text: "Cédula de extranjería",
    value: ColombiaDocumentTypeEnum.CEDULA_EXTRANJERIA,
  },
  {
    text: "Pasaporte",
    value: ColombiaDocumentTypeEnum.PASAPORTE,
  },
  {
    text: "NUIP",
    value: ColombiaDocumentTypeEnum.NUIP,
  },
];

export const PERU_DOCUMENT_TYPE_VALUES: IDataSet[] = [
  {
    text: "Cédula",
    value: PeruDocumentTypeEnum.CEDULA,
  },
  {
    text: "Carnet de extranjería",
    value: PeruDocumentTypeEnum.CARNET_EXTRANJERIA,
  },
  {
    text: "Pasaporte",
    value: PeruDocumentTypeEnum.PASAPORTE,
  },
];

export const ECUADOR_DOCUMENT_TYPE_VALUES: IDataSet[] = [
  {
    text: "Cédula",
    value: ColombiaDocumentTypeEnum.CEDULA,
  },
  {
    text: "Pasaporte",
    value: ColombiaDocumentTypeEnum.PASAPORTE,
  },
];
