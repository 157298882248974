import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { ICompanyInformationFormStateProps } from "./useCompanyInformationFormState.interfaces";
import { get } from "lodash";
import { CompanyInformationEnum } from "../../../shared/constants/CompanyInformationEnum";

export const useCompanyInformationFormState = ({
  setValue,
}: ICompanyInformationFormStateProps) => {
  const merchantInformation = useSelector(
    (store: IAppState) => store.merchantInformation
  );

  useEffect(() => {
    if (merchantInformation) {
      const mcc = get(merchantInformation, CompanyInformationEnum.MCC, "");
      const ciiu = get(merchantInformation, CompanyInformationEnum.CIIU, "");
      const socialReason = get(
        merchantInformation,
        CompanyInformationEnum.SOCIAL_REASON,
        ""
      );

      setValue(CompanyInformationEnum.MCC, mcc);
      setValue(CompanyInformationEnum.CIIU, ciiu);
      setValue(CompanyInformationEnum.SOCIAL_REASON, socialReason);
    }
  }, [merchantInformation]);
};
