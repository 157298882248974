/* eslint-disable no-trailing-spaces */

import React, { SVGProps } from "react";

const SvgVisa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="img"
    aria-label="visa card icon"
    width="1em"
    height="1em"
    viewBox="0 0 37 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clipVisa)">
      <path
        d="M36.312 22.857A2.14 2.14 0 0134.173 25H2.267a2.14 2.14 0 01-2.14-2.143V2.143A2.14 2.14 0 012.268 0h31.906a2.14 2.14 0 012.14 2.143v20.714z"
        fill="#F7FAFC"
      />
      <path
        d="M14.41 17.379l1.607-9.042h2.512l-1.557 9.042H14.41z"
        fill="#3C58BF"
      />
      <path
        d="M14.41 17.379l2.06-9.042h2.06l-1.558 9.042H14.41z"
        fill="#293688"
      />
      <path
        d="M26.064 8.438c-.502-.201-1.306-.402-2.31-.402-2.512 0-4.32 1.255-4.32 3.064 0 1.356 1.255 2.06 2.26 2.511 1.004.452 1.306.754 1.306 1.156 0 
        .603-.804.904-1.507.904-1.005 0-1.557-.15-2.411-.502l-.352-.151-.352 2.06c.603.25 1.708.502 2.864.502 2.662 0 4.42-1.256 4.42-3.165 
        0-1.055-.653-1.858-2.16-2.511-.904-.453-1.457-.704-1.457-1.156 0-.402.452-.804 1.457-.804.854 0 1.457.151 1.909.352l.251.1.402-1.959z"
        fill="#3C58BF"
      />
      <path
        d="M26.064 8.438c-.502-.201-1.306-.402-2.31-.402-2.512 0-3.869 1.255-3.869 3.064 0 1.356.804 2.06 1.809 2.511 1.004.452 1.306.754 1.306 1.156 0 
        .603-.804.904-1.507.904-1.005 0-1.557-.15-2.411-.502l-.352-.151-.352 2.06c.603.25 1.708.502 2.864.502 2.662 0 4.42-1.256 4.42-3.165 
        0-1.055-.653-1.858-2.16-2.511-.904-.453-1.457-.704-1.457-1.156 0-.402.452-.804 1.457-.804.854 0 1.457.151 1.909.352l.251.1.402-1.959z"
        fill="#293688"
      />
      <path
        d="M30.384 8.337c-.603 0-1.055.05-1.306.653l-3.767 8.389h2.712l.502-1.507h3.215l.302 1.507h2.41l-2.109-9.042h-1.96zm-1.155 6.028c.15-.452 
        1.004-2.662 1.004-2.662s.201-.553.352-.905l.15.854s.503 2.26.603 2.763h-2.11v-.05z"
        fill="#3C58BF"
      />
      <path
        d="M30.987 8.337c-.603 0-1.055.05-1.306.653l-4.37 8.389h2.712l.502-1.507h3.215l.302 1.507h2.41l-2.109-9.042h-1.356zm-1.758 6.028c.2-.503 1.004-2.662 
        1.004-2.662s.201-.553.352-.905l.15.854s.503 2.26.603 2.763h-2.11v-.05z"
        fill="#293688"
      />
      <path
        d="M9.789 14.666l-.251-1.306c-.452-1.507-1.91-3.164-3.517-3.968l2.26 8.037h2.713l4.07-9.042H12.35l-2.562 6.28z"
        fill="#3C58BF"
      />
      <path
        d="M9.789 14.666l-.251-1.306c-.452-1.507-1.91-3.164-3.517-3.968l2.26 8.037h2.713l4.07-9.042h-2.211l-3.064 6.28z"
        fill="#293688"
      />
      <path
        d="M2.807 8.337l.452.1c3.214.754 5.425 2.663 6.279 4.923l-.905-4.27c-.15-.602-.602-.753-1.155-.753H2.807z"
        fill="#FFBC00"
      />
      <path
        d="M2.807 8.337c3.214.753 5.877 2.712 6.73 4.973l-.853-3.567a1.226 1.226 0 00-1.206-.954l-4.671-.452z"
        fill="#F7981D"
      />
      <path
        d="M2.807 8.337c3.214.753 5.877 2.712 6.73 4.973l-.602-1.96c-.15-.602-.352-1.205-1.055-1.456L2.807 8.337z"
        fill="#ED7C00"
      />
      <path
        d="M12.3 14.365l-1.707-1.708-.804 1.909-.201-1.256c-.452-1.507-1.909-3.165-3.516-3.968l2.26 8.037h2.713l1.255-3.014zM16.972 17.38l-2.16-2.211-.402 2.21h2.562zM22.749 
        14.265c.2.2.301.351.25.552 0 .603-.803.904-1.506.904-1.005 0-1.557-.15-2.411-.502l-.352-.15-.352 2.059c.603.251 1.708.502 2.864.502 1.607 0 2.913-.452 3.667-1.256l-2.16-2.11zM25.662 
        17.379h2.361l.502-1.507h3.215l.302 1.507h2.41l-.853-3.667-3.014-2.914.15.804s.503 2.26.603 2.763h-2.11c.201-.502 1.005-2.662 1.005-2.662s.201-.553.352-.905"
        fill="#051244"
      />
    </g>
    <defs>
      <clipPath id="clipVisa">
        <path fill="#fff" transform="translate(.128)" d="M0 0h36.184v25H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVisa;
