import { Typography } from "@mui/material";
import React from "react";
import { ITransactionHistoryDateProps } from "./TransactionHistoryDate.interfaces";
import { styles } from "./TransactionHistoryDate.styles";

const TransactionHistoryDate = ({
  entryDate,
  entryHour,
}: ITransactionHistoryDateProps) => {
  return (
    <>
      <Typography sx={styles.entryDate}>{entryDate}</Typography>
      <Typography sx={styles.entryHour}>{entryHour}</Typography>
    </>
  );
};

export default TransactionHistoryDate;
