export const CARD_BRAND_INFO: object = {
  "american express": {
    name: "American Express",
  },
  amex: {
    name: "American Express",
  },
  carnet: {
    name: "Carnet",
  },
  diners: {
    name: "Diners Club",
  },
  "master card": {
    name: "MasterCard",
  },
  mastercard: {
    name: "MasterCard",
  },
  privada: {
    name: "Privada",
  },
  visa: {
    name: "Visa",
  },
};
