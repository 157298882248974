import React from "react";
import {
  Box,
  Button,
  createStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "react-feather";
import { LabelField } from "../../../../shared/infrastructure/constants/RulesConstants";

export interface PaginationTableProps {
  limit: number;
  isMobile: boolean;
  disabledBack?: boolean;
  disabledNext?: boolean;
  handleChangeLimit: (newLimit: string) => void;
  onNext(): void;
  onBack(): void;
}

const PaginationTable: React.FC<PaginationTableProps> = (
  props: PaginationTableProps
) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: `${props.isMobile ? "2px 0px 4px 4px" : "8px 27px 8px 20px"}`,
        backgroundColor: "#FFFFFF",
      },
      buttonIconPagination: {
        textTransform: "none",
      },
      marginButton: {
        marginTop: -6,
        display: "flex",
      },
      typographyLabelPagination: {
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: 11,
      },
      select: {
        "&:before": {
          borderColor: "red",
        },
        fontSize: "14px",
        color: theme.palette.text.secondary,
      },
    })
  );
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Box display="flex" style={{ width: "100%" }}>
          <Box flexGrow={1} className={classes.marginButton}>
            <Box display="flex" alignItems="center">
              <Typography
                color={"textSecondary"}
                variant={"body2"}
                className={classes.typographyLabelPagination}
              >
                {LabelField.TRANSACTION_PER_PAGE}
              </Typography>
              <Select
                labelId="limitsel-lbl"
                id="limitsel"
                value={props.limit}
                onChange={(e) =>
                  props.handleChangeLimit(e.target.value as string)
                }
                className={classes.select}
                disableUnderline
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box p={1}>
            <Button
              id="button_back"
              disabled={props.disabledBack}
              onClick={props.onBack}
              style={{
                textTransform: "capitalize",
              }}
              startIcon={<ChevronLeft xlinkTitle="back" size={22} />}
            >
              Anterior
            </Button>
            <Button
              id="button_next"
              disabled={props.disabledNext}
              onClick={props.onNext}
              style={{
                textTransform: "capitalize",
              }}
              endIcon={<ChevronRight xlinkTitle="next" size={22} />}
            >
              Siguiente
            </Button>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default PaginationTable;
