import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { AddBalanceRequest } from "../../../../types/remote/add_balance_request";
import { AxiosError, AxiosResponse } from "axios";
import { defaultTo } from "lodash";
import { API_ROUTES } from "../../../shared/constants/routes/api_routes";

export const addBalance = createAsyncThunk<object, AddBalanceRequest>(
  "balance/addBalance",
  (request) => {
    return axios
      .post(API_ROUTES.ADD_BALANCE, request)
      .then((response: AxiosResponse<object>) => response.data)
      .catch((e: AxiosError) => {
        throw defaultTo(e.response?.data, e);
      });
  }
);
