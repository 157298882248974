import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import theme from "./theme";
import { TransactionalConciliation } from "./containers/TransactionalConciliation/TransactionalConciliation";
import { routes } from "./shared/infrastructure/constants/routes";
import { store } from "./store/store";
import { ThemeProvider } from "@mui/material";
import { StrictMode } from "react";
import { environment } from "./environments/environment";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { M_BANK_CONCILIATION_TRANSACTIONAL } from "./shared/constants/security_labels";

const Root = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ModuleSecurityWrapper
              basePath={environment.kushkiUrl}
              componentId={M_BANK_CONCILIATION_TRANSACTIONAL}
            >
              <Switch>
                <Route
                  path={routes.TRANSACTIONAL_CONCILIATION}
                  exact
                  component={TransactionalConciliation}
                />
              </Switch>
            </ModuleSecurityWrapper>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </StrictMode>
  );
};

export default Root;
