import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  chip: {
    fontSize: "9px",
    fontWeight: "500 !important",
    lineHeight: "170%",
    textTransform: "uppercase",
    height: "24px",
    padding: "3px 11px",
  },
}));
