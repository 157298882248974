import { MerchantCredential } from "../../../../shared/infrastructure/interfaces/MerchantCredential";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MerchantResponse } from "../../../../../types/merchant_response";
import { IAppState } from "../../../../store/reducer";
import { useEffect } from "react";
import { getPaymentCredentials } from "../../../../store/actionCreators";
import { get } from "lodash";

export interface IUseCredentialsGrid {
  paymentCredentials: MerchantCredential[] | undefined;
  isLoadingPaymentCredentials: boolean | undefined;
}

export const useCredentialsGrid = (): IUseCredentialsGrid => {
  const { merchantId } = useParams();
  const dispatch = useDispatch();
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );
  const paymentCredentials = useSelector(
    (state: IAppState) => state.paymentCredentials
  );
  const isLoadingPaymentCredentials: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingPaymentCredentials
  );

  useEffect(() => {
    dispatch(
      getPaymentCredentials(get(merchant, "publicMerchantId", merchantId))
    );
  }, [merchant]);

  return {
    paymentCredentials,
    isLoadingPaymentCredentials,
  };
};
