import { KindEnum } from "./KindEnum";
import { BillingTransactionTypeEnum } from "./BillingTransactionTypeEnum";
import { TransactionTable } from "../../../types/transaction_table";
import { CountryEnum } from "./CountryEnum";
import { InvoiceCountryCatalog } from "./InvoiceCountryCatalog";
import { get } from "lodash";
import { TransactionTypeEnum } from "./TransactionTypeEnum";

export class TransactionValidators {
  public static isMinAmountTrx(kind: KindEnum, transactionType: string) {
    return (
      [
        KindEnum.INVOICE,
        KindEnum.CHARGE,
        KindEnum.VOUCHER,
        KindEnum.DISPERSION,
        KindEnum.RECEIVABLE,
      ].includes(kind) &&
      [
        <string>TransactionTypeEnum.MINIMAL_COMISSION,
        TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE,
        TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
      ].includes(transactionType)
    );
  }

  public static isTrxSelectionDisabled(
    row: TransactionTable | undefined,
    isMinAmount: boolean
  ) {
    if (!row) return true;
    if (row.transaction?.country === CountryEnum.ecuador && isMinAmount)
      return false;
    if (!InvoiceCountryCatalog.includes(row.transaction?.country)) return true;
    return ![
      KindEnum.INVOICE,
      KindEnum.CREDIT_NOTE,
      KindEnum.DEBIT_NOTE,
      KindEnum.VOUCHER,
      KindEnum.CHARGE,
      KindEnum.RECEIVABLE,
      KindEnum.DISPERSION,
    ].includes(row.transaction?.kind as KindEnum);
  }

  public static isBillingModalDisable(row?: TransactionTable) {
    const isMinAmount = TransactionValidators.isMinAmountTrx(
      get(row, "transaction.kind", KindEnum.DEBIT_NOTE),
      get(
        row,
        "transaction.transactionType",
        BillingTransactionTypeEnum.AUTOMATIC_COMMISSION
      )
    );

    return TransactionValidators.isTrxSelectionDisabled(row, isMinAmount);
  }
}
