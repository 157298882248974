import {
  IconBank,
  IconCardSwipe,
  IconChartSmall,
  IconMixer,
  IconStickyFilled,
} from "@kushki/connect-ui";
import { SectionValuesEnum } from "../enums/SecctionValuesEnum";
import React from "react";
import { ISectionValueInterface } from "../interfaces/ISectionValue.interface";
import { IButtonCardProps } from "../interfaces/ButtonCardProps.interface";
import { ConfigIdEnum } from "../enums/configIdEnums";
import { validateConfigIsDecentralize } from "../utils/select_section_utils";
import { BranchEditItem } from "../../../types/branch_edit_item";
import { get, isEmpty } from "lodash";
import { DisableDevelopersIcon } from "../../assets/icons/DisableDevelopersIcon";

const primaryColor = "primary";

export const ALMOST_ONE_SELECTION = 1;

export enum clientTypeEnum {
  PSP = "PSP",
  B2B = "B2B",
  B2C = "B2C",
}

export const getIconBatchEdit = (
  title: SectionValuesEnum,
  canEdit: boolean = true
): React.ReactNode => {
  const colorIcon = canEdit ? primaryColor : undefined;

  switch (title) {
    case SectionValuesEnum.MERCHANT_DATA:
      return <IconBank fontSize="large" color={colorIcon} />;
    case SectionValuesEnum.BILLING_CONFIGURATION:
      return <IconChartSmall fontSize="large" color={colorIcon} />;
    case SectionValuesEnum.RATES_AND_DISCOUNTS:
      return <IconStickyFilled fontSize="large" color={colorIcon} />;
    case SectionValuesEnum.PROCESSING:
      return <IconCardSwipe fontSize="large" color={colorIcon} />;
    case SectionValuesEnum.SERVICES:
      return <IconMixer fontSize="large" color={colorIcon} />;
    case SectionValuesEnum.DEVELOPERS:
      return <DisableDevelopersIcon />;
    default:
      return <> </>;
  }
};

export const selectSectionsDefault: ISectionValueInterface[] = [
  {
    catalogConfigsPerScreen: [ConfigIdEnum.CN001],
    checked: false,
    icon: <IconBank fontSize="large" color={`${primaryColor}`} />,
    isDisabled: false,
    title: SectionValuesEnum.MERCHANT_DATA,
  },
  {
    catalogConfigsPerScreen: [ConfigIdEnum.CN003],
    checked: false,
    icon: <IconChartSmall fontSize="large" color={`${primaryColor}`} />,
    isDisabled: false,
    title: SectionValuesEnum.BILLING_CONFIGURATION,
  },
  {
    catalogConfigsPerScreen: [ConfigIdEnum.CN007],
    checked: false,
    icon: <IconCardSwipe fontSize="large" color={`${primaryColor}`} />,
    isDisabled: false,
    title: SectionValuesEnum.PROCESSING,
  },
  {
    catalogConfigsPerScreen: [ConfigIdEnum.CN011],
    checked: false,
    icon: <IconMixer fontSize="large" color={`${primaryColor}`} />,
    isDisabled: false,
    title: SectionValuesEnum.SERVICES,
  },
  {
    catalogConfigsPerScreen: [
      ConfigIdEnum.CN005,
      ConfigIdEnum.CN006,
      ConfigIdEnum.CN018,
    ],
    checked: false,
    icon: <IconStickyFilled fontSize="large" color={`${primaryColor}`} />,
    isDisabled: false,
    title: SectionValuesEnum.RATES_AND_DISCOUNTS,
  },
  // {
  //   catalogConfigsPerScreen: [],
  //   checked: false,
  //   icon: <DevelopersIcon />,
  //   isDisabled: true,
  //   title: SectionValuesEnum.DEVELOPERS,
  // },
];

const validateDecentralizedBySection = (
  batchEditList: BranchEditItem[],
  catalogConfigs: ConfigIdEnum[],
  title: SectionValuesEnum
): boolean => {
  if (!isEmpty(batchEditList) && title === SectionValuesEnum.PROCESSING)
    return true;

  return catalogConfigs.every((configId: ConfigIdEnum) =>
    validateConfigIsDecentralize(batchEditList, configId)
  );
};

export let validatedBranchesDescentralized: boolean[] = [];

export const validateEditForCompanyAndFinancial = (
  isEditable: boolean,
  editList: BranchEditItem[]
): ISectionValueInterface[] => {
  validatedBranchesDescentralized = [];
  const sectionsWithValidation = [
    SectionValuesEnum.MERCHANT_DATA,
    SectionValuesEnum.BILLING_CONFIGURATION,
    SectionValuesEnum.RATES_AND_DISCOUNTS,
  ];
  const sectionsAlwaysDisabled = [SectionValuesEnum.DEVELOPERS];

  return selectSectionsDefault.map((section: ISectionValueInterface) => {
    let isDisabled: boolean = !validateDecentralizedBySection(
      editList,
      get(section, "catalogConfigsPerScreen", []),
      section.title
    );

    validatedBranchesDescentralized.push(isDisabled);

    if (sectionsWithValidation.includes(section.title)) {
      isDisabled = !isEditable || isDisabled;
    }

    if (sectionsAlwaysDisabled.includes(section.title)) {
      isDisabled = true;
    }

    return {
      ...section,
      icon: getIconBatchEdit(section.title, !isDisabled),
      isDisabled: isDisabled,
    };
  });
};

export const setChecked = (
  title: SectionValuesEnum,
  sections: ISectionValueInterface[]
) => {
  return sections.map((section) => {
    if (section.title == title) {
      section.checked = !section.checked;
    }

    return section;
  });
};

export const MENU_ID_PROPS = {
  [SectionValuesEnum.MERCHANT_DATA]: "MERCHANT_DATA",
  [SectionValuesEnum.BILLING_CONFIGURATION]: "BILLING_CONFIGURATION",
  [SectionValuesEnum.RATES_AND_DISCOUNTS]: "RATES_AND_DISCOUNTS",
  [SectionValuesEnum.PROCESSING]: "PROCESSING",
  [SectionValuesEnum.SERVICES]: "SERVICES",
  [SectionValuesEnum.RESUME]: "RESUME",
};

export enum MenuItemMassivePathEnum {
  MERCHANT_DATA = "/merchant/batch/basic-details?menuItem=true",
  BILLING_CONFIGURATION = "/financial-configuration/batch/billing?menuItem=true",
  RATES_AND_DISCOUNTS = "/console-rates?mode=massive-edition&menuItem=true",
  PROCESSING = "/processing/batch/?menuItem=true",
  SERVICES = "/service-configuration/batch/?menuItem=true",
}

export const getMassiveUrlByTitle = new Map([
  [SectionValuesEnum.MERCHANT_DATA, MenuItemMassivePathEnum.MERCHANT_DATA],
  [
    SectionValuesEnum.BILLING_CONFIGURATION,
    MenuItemMassivePathEnum.BILLING_CONFIGURATION,
  ],
  [
    SectionValuesEnum.RATES_AND_DISCOUNTS,
    MenuItemMassivePathEnum.RATES_AND_DISCOUNTS,
  ],
  [SectionValuesEnum.PROCESSING, MenuItemMassivePathEnum.PROCESSING],
  [SectionValuesEnum.SERVICES, MenuItemMassivePathEnum.SERVICES],
]);

export const buildItemsForSideBar = (
  selectedSections: ISectionValueInterface[]
): IButtonCardProps[] => {
  // TODO para que funcione en las otras secciones solo aumentar en el filer
  return selectedSections
    .filter(
      (selectedBranch) =>
        selectedBranch.title === SectionValuesEnum.MERCHANT_DATA ||
        selectedBranch.title === SectionValuesEnum.BILLING_CONFIGURATION ||
        selectedBranch.title === SectionValuesEnum.RATES_AND_DISCOUNTS ||
        selectedBranch.title === SectionValuesEnum.PROCESSING ||
        selectedBranch.title === SectionValuesEnum.SERVICES
    )
    .map((selectedBranch) => {
      return {
        checked: true,
        id: MENU_ID_PROPS[selectedBranch.title],
        isDisabled: false,
        isSelected: true,
        title: selectedBranch.title,
        url: getMassiveUrlByTitle.get(selectedBranch.title),
      };
    });
};
