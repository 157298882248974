import { ISnackBar } from "@kushki/connect-ui";

export type ColorSnackbar = "info" | "danger" | "success";
export const buildSnackbar = (
  color: ColorSnackbar,
  message: string
): ISnackBar => ({
  color,
  message,
  variant: "simple",
});
