import { makeStyles, TableCell, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";

export const StyledTableCell = withStyles(
  createStyles({
    head: {
      backgroundColor: "#FFF",
      color: "#787f88",
      padding: "16px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const useStyles = makeStyles({
  titlesHeader: {
    fontWeight: 600,
    fontSize: 15,
  },
});
