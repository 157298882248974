import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { IValidUrl } from "../../../shared/infrastructure/interfaces/IValidUrl";
import { ValidatorUrl } from "../ValidatorUrl/ValidatorUrl";
import { ShowVerifiedUrls } from "../ShowVerifiedUrls/ShowVerifiedUrls";

export interface ModalTestURLProps {
  open: boolean;
  loading: boolean;
  loadingModal: boolean;
  verifiedUrls: IValidUrl;
  handleCloseDialog: () => void;
  handleSave: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalTestUrl: React.FC<ModalTestURLProps> = (
  props: ModalTestURLProps
) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography color={"primary"}>Pruebas de URLs - Webhooks</Typography>
        </DialogTitle>
        <DialogContent>
          {props.loading ? (
            <ValidatorUrl />
          ) : (
            <ShowVerifiedUrls verifiedUrls={props.verifiedUrls} />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={props.handleCloseDialog}>
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={props.loading || props.loadingModal}
            onClick={props.handleSave}
            endIcon={props.loadingModal && <CircularProgress size={16} />}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
