import React from "react";
import { WrapperLabelEnum } from "../../shared/enums/WrapperLabelEnum";
import { TableInfo } from "../common/TableInfo/TableInfo";
import { Box, Typography } from "@mui/material";
import {
  ICustomerWebhooksTable,
  useCustomerWebhooks,
} from "./state/useCustomerWebhooks";
import { ModalLoader, ModalSimple, SnackBarProvider } from "@kushki/connect-ui";
import { SnackBarAlert } from "../common/Snackbar/SnackBarAlert";
import {
  WebhookModalDelete,
  WebhookModalLoader,
} from "../../shared/enums/WebhookModals";
import { PaginationSimple } from "../common/PaginationSimple/PaginationSimple";
import { WebhookSimulationModal } from "../WebhookSimulationModal/WebhookSimulationModal";

export const CustomerWebhooks = () => {
  const {
    selectedWebhook: webhook,
    table: { columns, rows, onRenderValue, isLoading, pagination },
    modals: {
      deleteWebhookModalLoader,
      deleteWebhookModalSimple,
      simulateWebhookModal,
    },
    snackbars: { deleteWebhookSnackbar },
    handlers,
  } = useCustomerWebhooks();

  return (
    <>
      <Box
        sx={{
          backgroundColor: "neutral.grey2",
          borderRadius: "8px",
          padding: "16px 24px",
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.dark"
          sx={{
            marginBottom: "16px",
          }}
        >
          {WrapperLabelEnum.SUBTITLE}
        </Typography>
        <Box overflow={"auto"}>
          <TableInfo<ICustomerWebhooksTable>
            columns={columns}
            rows={rows}
            onRenderValue={onRenderValue}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <ModalSimple
        onClickPrimary={handlers.delete}
        onClickSecondary={deleteWebhookModalSimple.close}
        titleText={WebhookModalDelete.TITLE}
        descriptionText={WebhookModalDelete.DESCRIPTION}
        buttonPrimary={WebhookModalDelete.BTN_PRIMARY}
        buttonSecondary={WebhookModalDelete.BTN_SECONDARY}
        isOpen={deleteWebhookModalSimple.isOpen}
        onClose={deleteWebhookModalSimple.close}
        typeModal={1}
      />
      <ModalLoader
        descriptionText={WebhookModalLoader.DESCRIPTION}
        isOpen={deleteWebhookModalLoader.isOpen}
        titleText={WebhookModalLoader.TITLE}
      />
      <SnackBarAlert
        msg={deleteWebhookSnackbar.message}
        open={deleteWebhookSnackbar.isOpen}
        type={deleteWebhookSnackbar.type}
        handlerClose={deleteWebhookSnackbar.close}
      />
      <SnackBarProvider>
        <WebhookSimulationModal
          open={simulateWebhookModal.isOpen}
          webhook={webhook}
          handleClose={simulateWebhookModal.close}
        />
      </SnackBarProvider>
      <PaginationSimple
        handleNextPage={pagination.handleNextPage}
        handlePreviousPage={pagination.handlePrevious}
        isNextDisabled={pagination.disableNext}
      />
    </>
  );
};
