import React from "react";
import { defaultTo, get } from "lodash";
import { FlagsNames } from "../../shared/enum/CountryEnumArray";
import { CountryEnum } from "../../shared/enum/CountryEnum";

export interface FlagProps {
  country: CountryEnum;
}

export const Flag: React.FC<FlagProps> = (props: FlagProps) => {
  return defaultTo(
    get(FlagsNames[props.country], "flag"),
    get(FlagsNames[CountryEnum.generic], "flag")
  );
};
