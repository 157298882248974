import { makeStyles } from "@material-ui/core/styles";

export const useSubTitlePageStyles = makeStyles({
  root: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  rootCo: {
    marginTop: "40px",
    marginBottom: "40px",
    padding: "10px",
    backgroundColor: "#F7FAFC",
  },
  btnContain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  btnChildCol: {
    marginTop: "10px",
    padding: "10px 25px",
    backgroundColor: "#023365",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#023365",
      color: "#fff",
    },
  },
  text: {
    fontSize: "18px",
    textAlign: "center",
  },
});
