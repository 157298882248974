import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
  FlagUsa,
  IconFlag,
} from "@kushki/connect-ui";
import React from "react";

import { CellFlagCountryType } from "./CellFlag.enum";

const TEXT_FLAG: Record<CellFlagCountryType | string, string> = {
  Afganistan: "AFG",
  Albania: "ALB",
  Alemania: "DEU",
  Andorra: "AND",
  Angola: "AGO",
  Anguila: "AIA",
  Antartida: "ATA",
  AntiguayBarbuda: "ATG",
  AntillasNeerlandesas: "ANT",
  ArabiaSaudita: "SAU",
  Argel: "DZA",
  Argentina: "ARG",
  Armenia: "ARM",
  Aruba: "ABW",
  Australia: "AUS",
  Austria: "AUT",
  Azerbaiyan: "AZE",
  Bahamas: "BHS",
  Bahrein: "BHR",
  Bangladesh: "BGD",
  Barbados: "BRB",
  Belarus: "BLR",
  Belgica: "BEL",
  Belice: "BLZ",
  Benin: "BEN",
  Bermudas: "BMU",
  Bhutan: "BTN",
  Bolivia: "BOL",
  BosniayHerzegovina: "BIH",
  Botsuana: "BWA",
  Brasil: "BRA",
  Brazil: "BRA",
  Brunei: "BRN",
  Bulgaria: "BGR",
  BurkinaFaso: "BFA",
  Burundi: "BDI",
  CaboVerde: "CPV",
  Camboya: "KHM",
  Camerun: "CMR",
  Canada: "CAN",
  Chad: "TCD",
  Chile: "CHL",
  China: "CHN",
  Chipre: "CYP",
  CiudaddelVaticano: "VAT",
  Colombia: "COL",
  Comoros: "COM",
  Congo: "COG",
  CoreadelNorte: "PRK",
  CoreadelSur: "KOR",
  CostadeMarfil: "CIV",
  CostaRica: "CRI",
  Croacia: "HRV",
  Cuba: "CUB",
  Dinamarca: "DNK",
  Dominica: "DMA",
  Ecuador: "ECU",
  EEUU: "USA",
  Egipto: "EGY",
  ElSalvador: "SLV",
  EmiratosArabesUnidos: "ARE",
  Eritrea: "ERI",
  Eslovaquia: "SVK",
  Eslovenia: "SVN",
  España: "ESP",
  EstadosUnidosdeAmerica: "USA",
  Estonia: "EST",
  Etiopia: "ETH",
  Fiji: "FJI",
  Filipinas: "PHL",
  Finlandia: "FIN",
  Francia: "FRA",
  Gabon: "GAB",
  Gambia: "GMB",
  Georgia: "GEO",
  GeorgiadelSureIslasSandwichdelSur: "SGS",
  Ghana: "GHA",
  Gibraltar: "GIB",
  Granada: "GRD",
  Grecia: "GRC",
  Groenlandia: "GRL",
  Guadalupe: "GLP",
  Guam: "GUM",
  Guatemala: "GTM",
  Guayana: "GUY",
  GuayanaFrancesa: "GUF",
  Guernsey: "GGY",
  Guinea: "GIN",
  GuineaBissau: "GNB",
  GuineaEcuatorial: "GNQ",
  Haiti: "HTI",
  Honduras: "HND",
  HongKong: "HKG",
  Hungria: "HUN",
  India: "IND",
  Indonesia: "IDN",
  Irak: "IRQ",
  Iran: "IRN",
  Irlanda: "IRL",
  IslaBouvet: "BVT",
  IsladeMan: "IMN",
  Islandia: "ISL",
  IslasAland: "ALA",
  IslasCaiman: "CYM",
  IslasChristmas: "CXR",
  IslasCocos: "CCK",
  IslasCook: "COK",
  IslasFaroe: "FRO",
  IslasHeardyMcDonald: "HMD",
  IslasMalvinas: "KLK",
  IslasMarshall: "MHL",
  IslasNorkfolk: "NFK",
  IslasPalaos: "PLW",
  IslasPitcairn: "PCN",
  IslasSolomon: "SLB",
  IslasSvalbardyJanMayen: "SJM",
  IslasTurcasyCaicos: "TCA",
  IslasVirgenesBritanicas: "VGB",
  IslasVirgenesdelosEstadosUnidosdeAmerica: "VIR",
  Israel: "ISR",
  Italia: "ITA",
  Jamaica: "JAM",
  Japon: "JPN",
  Jersey: "JEY",
  Jordania: "JOR",
  Kazajstan: "KAZ",
  Kenia: "KEN",
  Kirguistan: "KGZ",
  Kiribati: "KIR",
  Kuwait: "KWT",
  Laos: "LAO",
  Lesotho: "LSO",
  Letonia: "LVA",
  Libano: "LBN",
  Liberia: "LBR",
  Libia: "LBY",
  Liechtenstein: "LIE",
  Lituania: "LTU",
  Luxemburgo: "LUX",
  Macao: "MAC",
  Macedonia: "MKD",
  Madagascar: "MDG",
  Malasia: "MYS",
  Malawi: "MWI",
  Maldivas: "MDV",
  Mali: "MLI",
  Malta: "MLT",
  Marruecos: "MAR",
  Martinica: "MTQ",
  Mauricio: "MUS",
  Mauritania: "MRT",
  Mayotte: "MYT",
  Mexico: "MEX",
  Micronesia: "FSM",
  Moldova: "MDA",
  Monaco: "MCO",
  Mongolia: "MNG",
  Montenegro: "MNE",
  Montserrat: "MSR",
  Mozambique: "MOZ",
  Myanmar: "MMR",
  Namibia: "NAM",
  Nauru: "NRU",
  Nepal: "NPL",
  Nicaragua: "NIC",
  Niger: "NER",
  Nigeria: "NGA",
  Niue: "NIU",
  Noruega: "NOR",
  NuevaCaledonia: "NCL",
  NuevaZelanda: "NZL",
  Oman: "OMN",
  PaisesBajos: "NLD",
  Pakistan: "PAK",
  Palestina: "PSE",
  Panama: "PAN",
  PapuaNuevaGuinea: "PNG",
  Paraguay: "PRY",
  Peru: "PER",
  PolinesiaFrancesa: "PYF",
  Polonia: "POL",
  Portugal: "PRT",
  PuertoRico: "PRI",
  Qatar: "QAT",
  ReinoUnido: "GBR",
  RepublicaCentroAfricana: "CAF",
  RepublicaCheca: "CZE",
  RepublicaDominicana: "DOM",
  Reunion: "REU",
  Ruanda: "RWA",
  Rumania: "ROU",
  Rusia: "RUS",
  SaharaOccidental: "ESH",
  Samoa: "WSM",
  SamoaAmericana: "ASM",
  SanBartolome: "BLM",
  SanCristobalyNieves: "KNA",
  SanMarino: "SMR",
  SanPedroyMiquelon: "SPM",
  SantaElena: "SHN",
  SantaLucia: "LCA",
  SantoTomeyPrincipe: "STP",
  SanVicenteylasGranadinas: "VCT",
  Senegal: "SEN",
  SerbiayMontenegro: "SRB",
  Seychelles: "SYC",
  SierraLeona: "SLE",
  Singapur: "SGP",
  Siria: "SYR",
  Somalia: "SOM",
  SriLanka: "LKA",
  Suazilandia: "SWZ",
  Sudafrica: "ZAF",
  Sudan: "SDN",
  Suecia: "SWE",
  Suiza: "CHE",
  Surinam: "SUR",
  Tailandia: "THA",
  Taiwan: "TWN",
  Tanzania: "TZA",
  Tayikistan: "TJK",
  TerritorioBritanicodelOceanoIndico: "IOT",
  TerritoriosAustralesFranceses: "ATF",
  TimorLeste: "TLS",
  Togo: "TGO",
  Tokelau: "TKL",
  Tonga: "TON",
  TrinidadyTobago: "TTO",
  Tunez: "TUN",
  Turkmenistan: "TKM",
  Turquia: "TUR",
  Tuvalu: "TUV",
  Ucrania: "UKR",
  Uganda: "UGA",
  Uruguay: "URY",
  Uzbekistan: "UZB",
  Vanuatu: "VUT",
  Venezuela: "VEN",
  Vietnam: "VNM",
  WallisyFutuna: "WLF",
  Yemen: "YEM",
  Yibuti: "DJI",
};

const FLAG_ICON: Record<CellFlagCountryType, React.FC> = {
  Afganistan: IconFlag,
  Albania: IconFlag,
  Alemania: IconFlag,
  Andorra: IconFlag,
  Angola: IconFlag,
  Anguila: IconFlag,
  Antartida: IconFlag,
  AntiguayBarbuda: IconFlag,
  AntillasNeerlandesas: IconFlag,
  ArabiaSaudita: IconFlag,
  Argel: IconFlag,
  Argentina: IconFlag,
  Armenia: IconFlag,
  Aruba: IconFlag,
  Australia: IconFlag,
  Austria: IconFlag,
  Azerbaiyan: IconFlag,
  Bahamas: IconFlag,
  Bahrein: IconFlag,
  Bangladesh: IconFlag,
  Barbados: IconFlag,
  Belarus: IconFlag,
  Belgica: IconFlag,
  Belice: IconFlag,
  Benin: IconFlag,
  Bermudas: IconFlag,
  Bhutan: IconFlag,
  Bolivia: IconFlag,
  BosniayHerzegovina: IconFlag,
  Botsuana: IconFlag,
  Brasil: FlagBrasil,
  Brazil: FlagBrasil,
  Brunei: IconFlag,
  Bulgaria: IconFlag,
  BurkinaFaso: IconFlag,
  Burundi: IconFlag,
  CaboVerde: IconFlag,
  Camboya: IconFlag,
  Camerun: IconFlag,
  Canada: IconFlag,
  Chad: IconFlag,
  Chile: FlagChile,
  China: IconFlag,
  Chipre: IconFlag,
  CiudaddelVaticano: IconFlag,
  Colombia: FlagColombia,
  Comoros: IconFlag,
  Congo: IconFlag,
  CoreadelNorte: IconFlag,
  CoreadelSur: IconFlag,
  CostadeMarfil: IconFlag,
  CostaRica: FlagCostaRica,
  Croacia: IconFlag,
  Cuba: IconFlag,
  Dinamarca: IconFlag,
  Dominica: IconFlag,
  Ecuador: FlagEcuador,
  EEUU: FlagUsa,
  Egipto: IconFlag,
  ElSalvador: FlagElSalvador,
  EmiratosArabesUnidos: IconFlag,
  Eritrea: IconFlag,
  Eslovaquia: IconFlag,
  Eslovenia: IconFlag,
  España: IconFlag,
  EstadosUnidosdeAmerica: FlagUsa,
  Estonia: IconFlag,
  Etiopia: IconFlag,
  Fiji: IconFlag,
  Filipinas: IconFlag,
  Finlandia: IconFlag,
  Francia: IconFlag,
  Gabon: IconFlag,
  Gambia: IconFlag,
  Georgia: IconFlag,
  GeorgiadelSureIslasSandwichdelSur: IconFlag,
  Ghana: IconFlag,
  Gibraltar: IconFlag,
  Granada: IconFlag,
  Grecia: IconFlag,
  Groenlandia: IconFlag,
  Guadalupe: IconFlag,
  Guam: IconFlag,
  Guatemala: FlagGuatemala,
  Guayana: IconFlag,
  GuayanaFrancesa: IconFlag,
  Guernsey: IconFlag,
  Guinea: IconFlag,
  GuineaBissau: IconFlag,
  GuineaEcuatorial: IconFlag,
  Haiti: IconFlag,
  Honduras: FlagHonduras,
  HongKong: IconFlag,
  Hungria: IconFlag,
  India: IconFlag,
  Indonesia: IconFlag,
  Irak: IconFlag,
  Iran: IconFlag,
  Irlanda: IconFlag,
  IslaBouvet: IconFlag,
  IsladeMan: IconFlag,
  Islandia: IconFlag,
  IslasAland: IconFlag,
  IslasCaiman: IconFlag,
  IslasChristmas: IconFlag,
  IslasCocos: IconFlag,
  IslasCook: IconFlag,
  IslasFaroe: IconFlag,
  IslasHeardyMcDonald: IconFlag,
  IslasMalvinas: IconFlag,
  IslasMarshall: IconFlag,
  IslasNorkfolk: IconFlag,
  IslasPalaos: IconFlag,
  IslasPitcairn: IconFlag,
  IslasSolomon: IconFlag,
  IslasSvalbardyJanMayen: IconFlag,
  IslasTurcasyCaicos: IconFlag,
  IslasVirgenesBritanicas: IconFlag,
  IslasVirgenesdelosEstadosUnidosdeAmerica: IconFlag,
  Israel: IconFlag,
  Italia: IconFlag,
  Jamaica: IconFlag,
  Japon: IconFlag,
  Jersey: IconFlag,
  Jordania: IconFlag,
  Kazajstan: IconFlag,
  Kenia: IconFlag,
  Kirguistan: IconFlag,
  Kiribati: IconFlag,
  Kuwait: IconFlag,
  Laos: IconFlag,
  Lesotho: IconFlag,
  Letonia: IconFlag,
  Libano: IconFlag,
  Liberia: IconFlag,
  Libia: IconFlag,
  Liechtenstein: IconFlag,
  Lituania: IconFlag,
  Luxemburgo: IconFlag,
  Macao: IconFlag,
  Macedonia: IconFlag,
  Madagascar: IconFlag,
  Malasia: IconFlag,
  Malawi: IconFlag,
  Maldivas: IconFlag,
  Mali: IconFlag,
  Malta: IconFlag,
  Marruecos: IconFlag,
  Martinica: IconFlag,
  Mauricio: IconFlag,
  Mauritania: IconFlag,
  Mayotte: IconFlag,
  Mexico: FlagMexico,
  Micronesia: IconFlag,
  Moldova: IconFlag,
  Monaco: IconFlag,
  Mongolia: IconFlag,
  Montenegro: IconFlag,
  Montserrat: IconFlag,
  Mozambique: IconFlag,
  Myanmar: IconFlag,
  Namibia: IconFlag,
  Nauru: IconFlag,
  Nepal: IconFlag,
  Nicaragua: FlagNicaragua,
  Niger: IconFlag,
  Nigeria: IconFlag,
  Niue: IconFlag,
  Noruega: IconFlag,
  NuevaCaledonia: IconFlag,
  NuevaZelanda: IconFlag,
  Oman: IconFlag,
  PaisesBajos: IconFlag,
  Pakistan: IconFlag,
  Palestina: IconFlag,
  Panama: FlagPanama,
  PapuaNuevaGuinea: IconFlag,
  Paraguay: IconFlag,
  Peru: FlagPeru,
  PolinesiaFrancesa: IconFlag,
  Polonia: IconFlag,
  Portugal: IconFlag,
  PuertoRico: IconFlag,
  Qatar: IconFlag,
  ReinoUnido: IconFlag,
  RepublicaCentroAfricana: IconFlag,
  RepublicaCheca: IconFlag,
  RepublicaDominicana: IconFlag,
  Reunion: IconFlag,
  Ruanda: IconFlag,
  Rumania: IconFlag,
  Rusia: IconFlag,
  SaharaOccidental: IconFlag,
  Samoa: IconFlag,
  SamoaAmericana: IconFlag,
  SanBartolome: IconFlag,
  SanCristobalyNieves: IconFlag,
  SanMarino: IconFlag,
  SanPedroyMiquelon: IconFlag,
  SantaElena: IconFlag,
  SantaLucia: IconFlag,
  SantoTomeyPrincipe: IconFlag,
  SanVicenteylasGranadinas: IconFlag,
  Senegal: IconFlag,
  SerbiayMontenegro: IconFlag,
  Seychelles: IconFlag,
  SierraLeona: IconFlag,
  Singapur: IconFlag,
  Siria: IconFlag,
  Somalia: IconFlag,
  SriLanka: IconFlag,
  Suazilandia: IconFlag,
  Sudafrica: IconFlag,
  Sudan: IconFlag,
  Suecia: IconFlag,
  Suiza: IconFlag,
  Surinam: IconFlag,
  Tailandia: IconFlag,
  Taiwan: IconFlag,
  Tanzania: IconFlag,
  Tayikistan: IconFlag,
  TerritorioBritanicodelOceanoIndico: IconFlag,
  TerritoriosAustralesFranceses: IconFlag,
  TimorLeste: IconFlag,
  Togo: IconFlag,
  Tokelau: IconFlag,
  Tonga: IconFlag,
  TrinidadyTobago: IconFlag,
  Tunez: IconFlag,
  Turkmenistan: IconFlag,
  Turquia: IconFlag,
  Tuvalu: IconFlag,
  Ucrania: IconFlag,
  Uganda: IconFlag,
  Uruguay: IconFlag,
  Uzbekistan: IconFlag,
  Vanuatu: IconFlag,
  Venezuela: IconFlag,
  Vietnam: IconFlag,
  WallisyFutuna: IconFlag,
  Yemen: IconFlag,
  Yibuti: IconFlag,
};

export { FLAG_ICON, TEXT_FLAG };
