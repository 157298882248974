import { appSlice } from "../reducers/app/app";

export const {
  resetOriginalTrx,
  resetTransactionList,
  setNotification,
  setClearTrxResult,
  setFirebaseId,
  setAllBranchColumn,
  setBranchColumn,
  deleteBranchColumn,
  setLoadingDownload,
  setWatchFieldName,
  setClickCount,
} = appSlice.actions;
