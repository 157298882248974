import { Box, Card, Container, Typography } from "@mui/material";
import React, { FC } from "react";
import { WrapperContainer } from "@containers/WrapperContainer/WrapperContainer";
import { useExecutives } from "@containers/Executives/state/useExecutives";
import { GenericBreadcrumb } from "@components/GenericBreadcrumb/GenericBreadcrumb";
import {
  ExecutiveTextEnum,
  ModalValidFileLabelEnum,
} from "@shared/enum/ExecutiveTextEnum";
import { executivesStyles as styles } from "@containers/Executives/Executives.styles";
import StepsLayout from "@components/molecule/StepsLayout/StepsLayout";
import ModalInfoExecutives from "@components/molecule/ModalInfoExecutives/ModalInfoExecutives";
import ExecutivesTemplateFile from "@components/organism/ExecutivesTemplateFile/ExecutivesTemplateFile";
import { ModalSimple } from "@kushki/connect-ui";
import FileUploadExecutives from "@components/organism/FileUploadExecutives/FileUploadExecutives";

const Executives: FC = () => {
  const {
    getBreadcrumbItems,
    isOpenModalInfo,
    handleOpenCloseModal,
    templateExecutivesProps,
    lastUpdatedExecutive,
    usernameExecutive,
    validFileModal,
  } = useExecutives();

  const {
    isContinueDisabled,
    continueUploadExecutives,
    closeValidFileModal,
    isLoadingUploadCommercialExecutives,
    isUploadedSuccessfully,
    isOpenValidFileModal,
  } = validFileModal;

  return (
    <WrapperContainer>
      <Container>
        <GenericBreadcrumb {...getBreadcrumbItems()} />
        <Typography variant={"h1"} color={"text.primary"}>
          {ExecutiveTextEnum.HEADER_TITLE}
        </Typography>
        <Card sx={styles.card}>
          <Typography variant={"body2"} color={"text.primary"}>
            {ExecutiveTextEnum.CARD_DESCRIPTION}
          </Typography>
          <StepsLayout
            steps={[
              {
                component: (
                  <ExecutivesTemplateFile {...templateExecutivesProps} />
                ),
                label: ExecutiveTextEnum.STEP_1,
              },
              {
                component: (
                  <FileUploadExecutives key={`${isUploadedSuccessfully}`} />
                ),
                label: ExecutiveTextEnum.STEP_2,
              },
            ]}
          />
          <Typography variant={"body2"} color={"text.grey"}>
            {ExecutiveTextEnum.CONSULT_HERE_LABEL}
            <Box
              component={"span"}
              sx={styles.textButton}
              onClick={handleOpenCloseModal}
            >
              {ExecutiveTextEnum.CONSULT_HERE_BUTTON}
            </Box>
          </Typography>
          <Typography variant={"body2"} color={"text.grey"}>
            {ExecutiveTextEnum.LAST_UPDATE}
            {lastUpdatedExecutive} hrs por {usernameExecutive}
          </Typography>
        </Card>
      </Container>
      <ModalInfoExecutives
        isOpen={isOpenModalInfo}
        onOpenCloseModal={handleOpenCloseModal}
      />
      <ModalSimple
        onClickPrimary={continueUploadExecutives}
        onClickSecondary={closeValidFileModal}
        buttonPrimaryDisabled={isContinueDisabled}
        buttonSecondaryDisabled={isLoadingUploadCommercialExecutives}
        buttonPrimary={ModalValidFileLabelEnum.CONTINUE}
        buttonSecondary={ModalValidFileLabelEnum.CANCEL}
        descriptionText={ModalValidFileLabelEnum.DESCRIPTION}
        isOpen={isOpenValidFileModal}
        onClose={closeValidFileModal}
        titleText={ModalValidFileLabelEnum.TITLE}
        typeModal={1}
      />
    </WrapperContainer>
  );
};

export default Executives;
