import React from "react";
import { Grid, Typography } from "@material-ui/core";
export interface propsDefaultMessage {
  message: string;
}

export const DefaultMessage = (props: propsDefaultMessage) => {
  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        direction="column"
        justify={"center"}
        style={{
          marginTop: "24px",
          backgroundColor: "#FFEAEE",
          height: "56px",
        }}
      >
        <Typography style={{ color: "#D62C4B" }}>{props.message}</Typography>
      </Grid>
    </React.Fragment>
  );
};
