import { IModalTemplateInfo } from "../interfaces/ModalContainer";
import { LabelEnum } from "./LabelEnum";
import { StatusEnum } from "./StatusEnum";

export const MODAL_TEMPLATE_BRANCH: Record<string, IModalTemplateInfo> = {
  [LabelEnum.BUSINESS_RULES]: {
    ruleSetting: {
      columns: {
        id: LabelEnum.PUBLIC_ID,
        processorName: LabelEnum.PROCESSOR_NAME,
      },
      status: StatusEnum.ENABLE,
      title: LabelEnum.RULES_SETTINGS_TITLE,
    },
  },
};
