import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { Category } from "../shared/infrastructure/interfaces/Category";
import {
  Conditions,
  ConfigurationResponse,
} from "../../types/configuration_response";
import { GeographyList } from "../../types/geography_list";
import { Country } from "../../types/country_response";
import { MerchantHierarchyResponse } from "../../types/merchant_hierarchy_response";
import { RuleDetail } from "../../types/rule_detail";
import { SecurityRuleData } from "../../types/security_rule_data";
import { HierachyNodesResponse } from "../../types/hierachy_nodes_response";
import { IAlert } from "../shared/infrastructure/interfaces/IAlert";
import { SearchRuleResponse } from "../../types/search_rule_response";
import { SearchRuleByIdResponse } from "../../types/search_rule_by_id_response";
import { SearchRequestRuleByIdResponse } from "../../types/search_request_rule_by_id_response";
import { IOptionSelection } from "../shared/infrastructure/constants/RuleRequestManagerConstants";
import { TabIndexEnum } from "../shared/infrastructure/enums/TabIndexEnum";
import { GetWhiteListByCountryResponse } from "../../types/get_whitle_list_by_country_response";
import { GetSecurityRulesByTypeResponse } from "../../types/get_security_rules_by_type_response";
import { GetMerchantResponse } from "../../types/get_merchant_response";
import { IModalOption } from "../shared/infrastructure/interfaces/IModalCatalogGeneralRule";
import {
  RuleGeneralRequest,
  SearchRuleCatalogResponse,
} from "../../types/search_rule_catalog_response";
import { CategoryData, ItemCategoryProps } from "@kushki/connect-ui";
import { GetRuleByIdResponse } from "../../types/get_rule_by_id";
import { ProcessorsInformation } from "../../types/processors_list";
import { SearchRuleRequest } from "../../types/search_rule_request";
import { VerifyMassiveConditionResponse } from "../../types/verify_massive_condition_response";
import { TextContainerActions } from "../shared/infrastructure/interfaces/TextContainerActions";
import { Catalog } from "../../types/catalog_response";

export interface IAppState {
  isLoadingConfiguration?: boolean;
  configuration?: ConfigurationResponse;
  countries?: Country[];
  merchantHierarchy?: MerchantHierarchyResponse;
  ruleDetails?: RuleDetail;
  isEditedRule?: boolean;
  credentials?: Category[];
  geographyList?: GeographyList;
  isRequest?: boolean;
  isPaymentMethod?: boolean;
  requestError?: boolean;
  notification?: INotification;
  rule?: SecurityRuleData;
  generalRule?: SecurityRuleData;
  ruleCatalog?: SearchRuleCatalogResponse;
  currencyByCountry?: Record<string, IOptionSelection[]>;
  affectedNodes?: HierachyNodesResponse;
  openAlert?: IAlert;
  savingRule?: boolean;
  openConfirmModal?: boolean;
  openConfirmGeneralRuleModal?: boolean;
  processorsList?: ProcessorsInformation[];
  filterList?: object;
  searchRequestRulesResponse?: SearchRuleResponse;
  isLoading?: boolean;
  isLoadingRequest?: boolean;
  isLoadingRules?: boolean;
  validatingFile?: boolean;
  verifyMassiveConditionResponse?: VerifyMassiveConditionResponse;
  isLoadingOneRule?: boolean;
  openModalInfo?: boolean;
  requestRule?: SearchRuleByIdResponse | SearchRequestRuleByIdResponse;
  searchRuleById?: GetRuleByIdResponse;
  searchRequestRuleById?: SearchRequestRuleByIdResponse;
  openModalUpdateStatus?: boolean;
  selectedTabIndex?: number;
  successfulEndpointCall?: boolean;
  isLoadingModal?: boolean;
  refreshTable?: boolean;
  whiteListByCountry?: GetWhiteListByCountryResponse;
  securityRuleByType?: GetSecurityRulesByTypeResponse;
  merchantInformation?: GetMerchantResponse;
  listCommerceWithoutHierachy?: CategoryData[];
  searchCatalogGeneralRulesResponse?: SearchRuleResponse;
  openModalCatalogGeneralRules?: IModalOption;
  detailCatalogGeneralRules?: RuleGeneralRequest;
  actionType?: string;
  availableConditions?: Conditions[];
  textFieldComponentActions?: TextContainerActions;
  lastLimit?: number;
  selectedFilter?: ItemCategoryProps[];
  searchFilter?: SearchRuleRequest;
  goBack?: boolean;
  isLoadingProcessor?: boolean;
  commerceType?: string;
  isLoadingCatalog?: boolean;
  listMccCatalog?: Catalog[];
}

export const INITIAL_STATE: IAppState = {
  availableConditions: [],
  configuration: {
    generalActions: [],
    messages: [],
    codes: [],
    providers: [],
    operators: [],
    conditions: [],
    periodicity: [],
    transactionType: [],
  },
  countries: [],
  credentials: [],
  isLoadingConfiguration: false,
  isRequest: false,
  isPaymentMethod: false,
  isEditedRule: false,
  requestError: false,
  merchantHierarchy: {},
  notification: {
    message: "",
    open: false,
    type: "success",
  },
  filterList: {},
  openAlert: { open: false, isError: false },
  openConfirmModal: false,
  processorsList: [],
  searchRequestRulesResponse: { data: [], total: 0 },
  isLoading: false,
  isLoadingRequest: false,
  isLoadingRules: false,
  isLoadingOneRule: true,
  openModalInfo: false,
  searchRuleById: undefined,
  searchRequestRuleById: undefined,
  savingRule: false,
  openModalUpdateStatus: false,
  selectedTabIndex: TabIndexEnum.PENDING_REVIEW_INDEX,
  successfulEndpointCall: false,
  validatingFile: false,
  verifyMassiveConditionResponse: undefined,
  whiteListByCountry: [],
  securityRuleByType: undefined,
  merchantInformation: undefined,
  listCommerceWithoutHierachy: [],
  lastLimit: 5,
  selectedFilter: [],
  goBack: false,
  searchFilter: undefined,
  textFieldComponentActions: undefined,
  isLoadingProcessor: false,
  commerceType: "",
  isLoadingCatalog: false,
  listMccCatalog: [],
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_HIERARCHY_NODES:
      return {
        ...state,
        affectedNodes: action.affectedNodes,
      };

    case ActionTypes.SET_AVAILABLE_CONDITIONS:
      return {
        ...state,
        availableConditions: action.availableConditions,
      };

    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };

    case ActionTypes.SET_FILTER_RESPONSE:
      return {
        ...state,
        filterList: action.filterList,
      };

    case ActionTypes.SET_LOADING_CONFIGURATION:
      return {
        ...state,
        isLoadingConfiguration: action.isLoadingConfiguration,
      };

    case ActionTypes.SET_OPEN_ALERT:
      return {
        ...state,
        openAlert: action.openAlert,
      };

    case ActionTypes.SET_OPEN_CONFIRM_MODAL:
      return {
        ...state,
        openConfirmModal: action.openConfirmModal,
      };

    case ActionTypes.SET_CONFIGURATION:
      return {
        ...state,
        configuration: action.configuration,
      };

    case ActionTypes.SET_GEOGRAPHY_LIST:
      return {
        ...state,
        geographyList: action.geographyList,
      };

    case ActionTypes.SET_COUNTRY_RULES:
      return {
        ...state,
        countries: action.countries,
      };

    case ActionTypes.SET_CURRENCY:
      return {
        ...state,
        currencyByCountry: action.currencyByCountry,
      };

    case ActionTypes.SET_MERCHANT_HIERARCHY:
      return {
        ...state,
        merchantHierarchy: action.merchantHierarchy,
      };

    case ActionTypes.SET_RULE_DETAILS:
      return {
        ...state,
        ruleDetails: action.ruleDetails,
      };

    case ActionTypes.SET_IS_EDITED_RULE:
      return {
        ...state,
        isEditedRule: action.isEditedRule,
      };

    case ActionTypes.SET_GENERAL_RULE:
      return {
        ...state,
        generalRule: action.generalRule,
      };

    case ActionTypes.SET_RULE_CATALOG:
      return {
        ...state,
        ruleCatalog: action.ruleCatalog,
      };

    case ActionTypes.SET_OPEN_CONFIRM_GENERAL_RULE_MODAL:
      return {
        ...state,
        openConfirmGeneralRuleModal: action.openConfirmGeneralRuleModal,
      };

    case ActionTypes.SET_RULE_DATA:
      return {
        ...state,
        rule: action.rule,
      };

    case ActionTypes.SET_IS_REQUESTING:
      return {
        ...state,
        isRequest: action.isRequest,
      };

    case ActionTypes.SET_IS_PAYMENT_METHOD:
      return {
        ...state,
        isPaymentMethod: action.isPaymentMethod,
      };

    case ActionTypes.SET_REQUEST_ERROR:
      return {
        ...state,
        requestError: action.requestError,
      };
    case ActionTypes.SET_MERCHANT_CREDENTIALS:
      return {
        ...state,
        credentials: action.credentials,
      };
    case ActionTypes.SET_MERCHANT_PROCESSORS:
      return {
        ...state,
        processorsList: action.processorsList,
      };
    case ActionTypes.SET_SEARCH_REQUEST_RULES_RESPONSE:
      return {
        ...state,
        searchRequestRulesResponse: action.searchRequestRulesResponse,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SET_SAVING_RULE:
      return {
        ...state,
        savingRule: action.savingRule,
      };
    case ActionTypes.SET_IS_LOADING_REQUEST:
      return {
        ...state,
        isLoadingRequest: action.isLoadingRequest,
      };
    case ActionTypes.SET_IS_LOADING_RULES:
      return {
        ...state,
        isLoadingRules: action.isLoadingRules,
      };
    case ActionTypes.SET_SEARCH_REQUEST_BY_ID:
      return {
        ...state,
        requestRule: action.requestRule,
      };

    case ActionTypes.SET_IS_LOADING_ONE_RULE:
      return {
        ...state,
        isLoadingOneRule: action.isLoadingOneRule,
      };
    case ActionTypes.SET_OPEN_MODAL_INFO:
      return {
        ...state,
        openModalInfo: action.openModalInfo,
      };
    case ActionTypes.SET_OPEN_MODAL_CATALOG_GENERAL_RULES:
      return {
        ...state,
        openModalCatalogGeneralRules: action.openModalCatalogGeneralRules,
      };
    case ActionTypes.SET_DETAIL_CATALOG_GENERAL_RULES:
      return {
        ...state,
        detailCatalogGeneralRules: action.detailCatalogGeneralRules,
      };
    case ActionTypes.SET_SEARCH_RULE_BY_ID:
      return {
        ...state,
        searchRuleById: action.searchRuleById,
      };
    case ActionTypes.SET_SEARCH_REQUEST_RULE_BY_ID:
      return {
        ...state,
        searchRequestRuleById: action.searchRequestRuleById,
      };
    case ActionTypes.SET_OPEN_MODAL_INFO_REJECTED:
      return {
        ...state,
        openModalUpdateStatus: action.openModalUpdateStatus,
      };
    case ActionTypes.SET_SELECTED_TAB_INDEX:
      return {
        ...state,
        selectedTabIndex: action.selectedTabIndex,
      };
    case ActionTypes.SET_SUCCESSFUL_ENDPOINT_CALL:
      return {
        ...state,
        successfulEndpointCall: action.successfulEndpointCall,
      };
    case ActionTypes.SET_IS_LOADING_MODAL_CHANGE:
      return {
        ...state,
        isLoadingModal: action.isLoadingModal,
      };
    case ActionTypes.SET_REFRESH_TABLE:
      return {
        ...state,
        refreshTable: action.refreshTable,
      };
    case ActionTypes.SET_WHITE_LIST_BY_COUNTRY:
      return {
        ...state,
        whiteListByCountry: action.whiteListByCountry,
      };
    case ActionTypes.SET_SECURITY_RULE_BY_TYPE:
      return {
        ...state,
        securityRuleByType: action.securityRuleByType,
      };
    case ActionTypes.SET_MERCHANT_INFORMATION:
      return {
        ...state,
        merchantInformation: action.merchantInformation,
      };
    case ActionTypes.SET_LIST_COMMERCE_WITHOUT_HIERACHY:
      return {
        ...state,
        listCommerceWithoutHierachy: action.listCommerceWithoutHierachy,
      };
    case ActionTypes.SET_ACTION_TYPE:
      return {
        ...state,
        actionType: action.actionType,
      };
    case ActionTypes.SET_VERIFY_MASSIVE_CONDITION_RESPONSE:
      return {
        ...state,
        verifyMassiveConditionResponse: action.verifyMassiveConditionResponse,
      };
    case ActionTypes.SET_VALIDATING_FILE:
      return {
        ...state,
        validatingFile: action.validatingFile,
      };
    case ActionTypes.SET_TXT_FIELD_ACTIONS:
      return {
        ...state,
        textFieldComponentActions: action.textFieldComponentActions,
      };
    case ActionTypes.SET_LAST_LIMIT:
      return {
        ...state,
        lastLimit: action.lastLimit,
      };
    case ActionTypes.SET_GO_BACK:
      return {
        ...state,
        goBack: action.goBack,
      };
    case ActionTypes.SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.selectedFilter,
      };
    case ActionTypes.SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter,
      };
    case ActionTypes.SET_IS_LOADING_PROCESSOR:
      return {
        ...state,
        isLoadingProcessor: action.isLoadingProcessor,
      };
    case ActionTypes.SET_COMMERCE_TYPE:
      return {
        ...state,
        commerceType: action.commerceType,
      };
    case ActionTypes.SET_IS_LOADING_CATALOG:
      return {
        ...state,
        isLoadingCatalog: action.isLoadingCatalog,
      };
    case ActionTypes.SET_LIST_MCC_CATALOG:
      return {
        ...state,
        listMccCatalog: action.listMccCatalog,
      };
    default:
      return state;
  }
};
