import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { get } from "lodash";
import { ListHistoryProps } from "../ListHistoryContainer";
import PaginationTable from "../Pagination/PaginationTable";
import { validateDate } from "../../../containers/HistoryIndex/state/useHistoryIndexState";
import { RESPONSE_CODE_TYPES } from "../../../shared/infrastructure/catalogues/DurationCodeEnum";
import ModalHeaderInfo from "../../Detail/ModalHeaderInfo/ModalHeaderInfo";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import InformationFirstSection from "../../Detail/InformationSections/InformationFirstSection";
import InformationSecondSection from "../../Detail/InformationSections/InformationSecondSection";
import InformationModulesVisited from "../../Detail/InformationSections/InformationModulesVisited";
import InformationAgentSupport from "../../Detail/InformationSections/InformationAgentSupport";
import InformationCommentsSupport from "../../Detail/InformationSections/InformationCommentsSupport";
import { HelpCircle } from "react-feather";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    paddingTop: "0px",
    borderRadius: 4,
    boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
  },
  capitalize: {
    textTransform: "capitalize",
  },
});
const HistorySessionTable = (props: ListHistoryProps) => {
  const classes = useStyles();
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [supportInfo, setSupportInfo] = useState<object | undefined>(undefined);
  const handleRowSelection = (row: object) => () => {
    setSupportInfo(row);
    setOpenInfoModal(true);
  };
  const handleCloseModal = (): void => {
    setOpenInfoModal(false);
  };

  return (
    <React.Fragment>
      {props.historyDashboard == undefined ? (
        ""
      ) : (
        <Paper className={classes.paper}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow key="header">
                  <TableCell>Fecha</TableCell>
                  <TableCell hidden={!props.isAdmin}>Comercio</TableCell>
                  <TableCell>
                    {props.isAdmin ? "Usuario Soporte" : "Agente Soporte"}
                  </TableCell>
                  <TableCell>
                    {props.isAdmin ? "Usuario Comercio" : "Usuario Solicitante"}
                  </TableCell>
                  <TableCell>Duración Conexión</TableCell>
                  <TableCell>Duración Código</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.historyDashboard!.map((row: object, index: number) => (
                  <TableRow
                    id={`row_${index}`}
                    key={index}
                    onClick={handleRowSelection(row)}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {
                          validateDate(
                            props.isAdmin
                              ? get(row, "_source.connected_in")
                              : get(row, "_source.created"),
                            props.timeZoneRegion
                          ).split("T")[0]
                        }
                      </Typography>
                      <Typography variant="subtitle2" color={"textSecondary"}>
                        {
                          validateDate(
                            props.isAdmin
                              ? get(row, "_source.connected_in")
                              : get(row, "_source.created"),
                            props.timeZoneRegion
                          ).split("T")[1]
                        }
                      </Typography>
                    </TableCell>
                    <TableCell align="left" hidden={!props.isAdmin}>
                      {get(row, "_source.merchant_name")}
                    </TableCell>
                    <TableCell align="left">
                      {props.isAdmin ? (
                        get(row, "_source.user_agent")
                      ) : get(row, "_source.status") === "COMPLETED" ? (
                        get(row, "_source.user_agent_full_name", "Sin conexión")
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <Typography>Sin conexión</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip
                              placement="top"
                              title="El código de acceso fue generado pero ningún agente se conectó a su comercio"
                            >
                              <Typography style={{ color: "#6D7781" }}>
                                <HelpCircle size={18} />
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {props.isAdmin
                        ? get(row, "_source.user_name")
                        : get(row, "_source.user_full_name")}
                    </TableCell>
                    <TableCell align="left">
                      {get(row, "_source.time_duration_connect", "0:00:00")}
                    </TableCell>
                    <TableCell align="left">
                      {
                        RESPONSE_CODE_TYPES[
                          get(row, "_source.time_connect", "30m")
                        ]
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationTable {...props.pagination} />
          <ModalOverlay
            header={
              <ModalHeaderInfo
                supportInfo={supportInfo}
                isAdmin={props.isAdmin}
                timeZoneRegion={props.timeZoneRegion}
              />
            }
            body={
              <Grid>
                <InformationFirstSection
                  supportAdmin={supportInfo}
                  isAdmin={props.isAdmin}
                />

                <InformationSecondSection
                  supportInfo={supportInfo}
                  isAdmin={props.isAdmin}
                  timeZoneRegion={props.timeZoneRegion}
                />

                {props.isAdmin ? (
                  <InformationModulesVisited supportInfo={supportInfo} />
                ) : (
                  <InformationAgentSupport supportInfo={supportInfo} />
                )}

                {props.isAdmin ? (
                  <InformationCommentsSupport supportInfo={supportInfo} />
                ) : get(supportInfo, "_source.status", "EXPIRED") ===
                  "COMPLETED" ? (
                  <InformationModulesVisited supportInfo={supportInfo} />
                ) : null}
              </Grid>
            }
            openModal={openInfoModal}
            onCloseModal={handleCloseModal}
            overlay={false}
            backdrop={false}
            transition={"left"}
          />
        </Paper>
      )}
    </React.Fragment>
  );
};
export default HistorySessionTable;
