import { IHeaderTable } from "../../../../types/header_table";

export const defaultRefundsHistoryColumns: IHeaderTable[] = [
  {
    disabled: false,
    filterLabel: "Fecha de solicitud",
    id: "updateAt",
    label: "F. de solici. Reembolso",
    orderBy: "updateAt",
  },
  {
    disabled: false,
    filterLabel: "Nombre / ID del comercio",
    id: "merchantName",
    label: "Nombre y ID del Comercio",
    orderBy: "merchantName",
  },
  {
    disabled: false,
    filterLabel: "Monto",
    id: "requestAmount",
    label: "Monto",
    orderBy: "requestAmount",
  },
  {
    disabled: true,
    filterLabel: "Tipo y Estado de la transacción original",
    id: "transactionType",
    label: "Tipo y Estado de transacción original",
    orderBy: "transactionType",
  },
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "stateRefund",
  },
  {
    disabled: true,
    filterLabel: "Acción y respuesta",
    id: "action",
    label: "Acción y Respuesta",
    orderBy: "action",
  },
];
