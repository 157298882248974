import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { COMMENTS_TAB_LABELS } from "../../../../../shared/constants/comments_tab_labels";
import { ITransactionTab } from "../../../../../shared/interfaces/TransactionModal.interfaces";
import { IComment } from "../../../../../store/interfaces/TransactionsState.interfaces";
import { CommentsTabStyles as style } from "./CommentsTab.styles";

const CommentsTab: FC<ITransactionTab> = (props) => {
  const comments = props?.transaction.comments
    ? [...props?.transaction.comments!]
    : [];

  const formatDate = (date: Date) => {
    const [year, month, day] = [
      date.getFullYear(),
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + (date.getDay() - 1)).slice(-2),
    ];

    return `${year}-${month}-${day}`;
  };

  const formatTime = (date: Date) => {
    const [hours, minutes, seconds] = [
      ("0" + date.getHours()).slice(-2),
      ("0" + date.getMinutes()).slice(-2),
      ("0" + date.getSeconds()).slice(-2),
    ];

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <Grid container spacing={4} sx={style.container} direction="column">
      {comments
        .sort((a: IComment, b: IComment) => a.created! - b.created!)
        .map((comment: IComment, index: number) => (
          <Box key={"com_" + index} sx={style.box}>
            <Grid item container direction="row" spacing={2}>
              <Grid item sx={style.label} xs={5}>
                <Typography sx={style.labelText}>
                  {COMMENTS_TAB_LABELS.USER}
                </Typography>
              </Grid>
              <Grid item sx={style.value} xs={7}>
                <Typography sx={style.username}>{comment.user}</Typography>
              </Grid>
              <Grid item sx={style.label} xs={5}>
                <Typography sx={style.labelText}>
                  {COMMENTS_TAB_LABELS.CREATION_DATE}
                </Typography>
              </Grid>
              <Grid item sx={style.value} xs={7}>
                <Typography sx={style.date}>
                  {formatDate(new Date(comment.created!))}
                </Typography>
                <Typography sx={style.time}>
                  {formatTime(new Date(comment.created!))}
                </Typography>
              </Grid>
              <Grid item sx={style.label} xs={5}>
                <Typography sx={style.labelText}>
                  {COMMENTS_TAB_LABELS.COMMENT}
                </Typography>
              </Grid>
              <Grid item sx={style.value} xs={7}>
                <Typography sx={style.text}>{comment.comment}</Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
    </Grid>
  );
};

export default CommentsTab;
