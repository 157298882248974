import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useMemo } from "react";
import { TableProps } from "./interfaces";
import { IStyles } from "../../../shared/interfaces/Styles.interfaces";
import { useMobileState } from "../../../shared/state/useMobileState";

const TableSimpleStyles: IStyles = {
  contentTable: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  footer: {
    border: "none",
    margin: 0,
    padding: 0,
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0px 8px",
  },
};

const useTableSimpleStyles = (props: TableProps): { styles: IStyles } => {
  const { isMobile } = useMobileState();
  const styles: IStyles = useMemo<IStyles>(() => {
    const columnSize: (string | number)[] = props.header.cells.map(
      ({ width = "auto" }) => width
    );

    const currentStyles: IStyles = { ...TableSimpleStyles };

    currentStyles.table = columnSize.reduce(
      (
        current: SxProps<Theme>,
        width: string | number,
        index: number
      ): SxProps<Theme> => {
        const thChild: string = `td:nth-child(${index + 1})`;

        return {
          ...current,
          [thChild]: {
            width,
          },
        };
      },
      {
        ...currentStyles.table,
      }
    );

    return isMobile ? TableSimpleStyles : currentStyles;
  }, [props.header.cells, isMobile]);

  return { styles };
};

export { useTableSimpleStyles };
