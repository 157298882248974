import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import { getNodeInfo, getProcessors } from "../../../store/actionCreators";
import { ConfigurationCodeEnum } from "../../../shared/infrastructure/enums/NodeInfoEnum";
import { useDispatch, useSelector } from "react-redux";
import { IUseBusinessRulesListIndexState } from "../../../shared/infrastructure/interfaces/IUseBusinessRulesListIndexState";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { get } from "lodash";
import {
  getCustomerBatchInformation,
  getIsMassiveRules,
  setBasicMerchantInformation,
} from "../../../shared/utils/localStorage";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import { ICustomerBatchInformation } from "../../../shared/infrastructure/interfaces/ICustomerBatchInformation";
import { useAppSelector } from "../../../store/hooks/store-hook";

export const useBusinessRulesListIndexState =
  (): IUseBusinessRulesListIndexState => {
    const { merchantId } = useParams();

    const customerBatchInformation: ICustomerBatchInformation =
      getCustomerBatchInformation();

    const merchantIdStorage = getIsMassiveRules()
      ? customerBatchInformation.publicMerchantId
      : merchantId;

    const nodeInfoMerchant: GetNodeInfoResponse | undefined = useSelector(
      (state: IAppState) => get(state, "layout.nodeInfoMerchant", undefined)
    );

    const isOpenModal: boolean = useAppSelector((state) =>
      get(state.layout, "openModalInfo", false)
    );

    const dispatch = useDispatch();

    const getNodeInformation = (publicMerchantId: string): void => {
      const configIds: string = ConfigurationCodeEnum.CN010.concat(
        ",",
        ConfigurationCodeEnum.CN008,
        ",",
        ConfigurationCodeEnum.CN007
      );

      const nodeInfoRequest: GetNodeInfoRequest = {
        publicMerchantId,
        configIds,
      };
      dispatch(getNodeInfo(nodeInfoRequest));
    };

    useEffect(() => {
      if (merchantIdStorage) {
        getNodeInformation(merchantIdStorage);
        setBasicMerchantInformation({
          redirectPath: AppRoutes.LIST_BUSINESS_RULES.replace(
            ":merchantId",
            merchantIdStorage
          ),
        });
        dispatch(getProcessors(merchantIdStorage));
      }
    }, []);

    return {
      nodeInfoMerchant,
      isOpenModal,
    };
  };
