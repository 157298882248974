import { CreateRuleRequest } from "../../types/create_rule_request";
import { INewRuleState } from "../shared/infrastructure/interfaces/INewRuleState";
import { ISaveRuleModal } from "../shared/infrastructure/interfaces/ISimpleModal";
import { ILoadingModal } from "../shared/infrastructure/interfaces/ILoadingModal";

export const DEFAULT_LOADING_MODAL: ILoadingModal = {
  message: "",
  isLoading: false,
};
export const DEFAULT_SIMPLE_MODAL: ISaveRuleModal = {
  action: "",
  buttonPrimaryText: "",
  buttonSecondary: "",
  descriptionText: "",
  isOpen: false,
  titleText: "",
};

export const BUSINESS_RULE_INITIAL_STATE: CreateRuleRequest = {
  merchantId: "",
  type: "",
  service: "card",
  country: [],
  merchantName: "",
  rule: {
    TransactionMerchantCountry: "",
    action: "",
    code: "",
    ignore3DS: false,
    kind: "",
    limit: "",
    maxAmount: "",
    message: "",
    metric: "",
    name: "",
    partnerValidator: "",
    periodicity: "",
    periodicityCount: "",
    periodicityCountDay: "",
    periodicityCountHour: "",
    periodicitySum: "",
    processorLimit: "",
    processorName: "",
    processorPeriodicityCountDay: "",
    processorPeriodicityCountHour: "",
    transactionBIN: "",
    transactionBank: "",
    transactionBrand: "",
    transactionCardIssuer: "",
    transactionCountry: "",
    transactionCreated: "",
    transactionCredential: "",
    transactionCurrency: "",
    transactionEmail: "",
    transactionIP: "",
    transactionIsCreditCard: "",
    transactionIsDeferred: "",
    transactionMaskedCreditCard: "",
    transactionProcessor: "",
    transactionProcessorName: "",
    transactionStatus: "",
    transactionStatusCount: "",
    transactionStatusSum: "",
    transactionTotalAmount: "",
    transactionType: "",
    updatedProcessorRule: false,
  },
};

export const DEFAULT_NEW_RULE_STATE: INewRuleState = {
  rule: BUSINESS_RULE_INITIAL_STATE,
  isRepeated: false,
};
