import { useEffect, useState } from "react";
import { IInformationCustomerFormState } from "./useInformationCustomerForm.interfaces";
import { defaultTo } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { CreateMerchantNodeRequest } from "../../../../types/create_merchant_node_request";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { useForm, UseFormReturn } from "react-hook-form";
import { get, has, isEmpty } from "lodash";
import createMerchantNode, {
  getNodeInfo,
} from "../../../store/thunks/createNode/createNode.thunks";
import { useSnackbar } from "@kushki/connect-ui";
import { setNotification } from "../../../store/actions/createMerchantNode.actions";
import {
  buildRequest,
  getNodeInfoInitialValue,
} from "../../../shared/utils/information_customer_utils";
import { initialState } from "../../../store/reducers/createNode/createNode";
import { FieldsCustomerEnum } from "../../../shared/enums/FieldsCustomerEnum";
import { ErrorEnum } from "../../../shared/enums/ErrorEnum";
import { INFORMATION_CUSTOMER_LABELS } from "../../../shared/constants/labels/information_customer_container_labels";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";

export const useInformationCustomerForm = (): IInformationCustomerFormState => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [countryConstitutionCheck, setCountryConstitutionCheck] =
    useState<boolean>(true);
  const { response, notification } = useAppSelector(
    (state) => state.createMerchant
  );
  const { getNodeInfoResponse } = useAppSelector((state) => state.getNodeInfo);

  const form: UseFormReturn<CreateMerchantNodeRequest> =
    useForm<CreateMerchantNodeRequest>({
      defaultValues: {
        ...getNodeInfoInitialValue(getNodeInfoResponse),
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const [isOpenLoaderModal, setIsOpenLoaderModal] = useState<boolean>(false);
  const [nodeId, setNodeId] = useState<string>("");
  const [publicMerchantId, setPublicMerchantId] = useState<string>("");
  const [isFromUrl, setIsFromUrl] = useState<boolean>(false);
  const [modalOpenEdit, setModalOpenEdit] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<boolean>(false);

  const [getMerchantId, setGetMerchantId] = useState<string>("");
  const [hasParent, setHasParent] = useState<boolean>(false);
  const [editTitle, setEditTitle] = useState<string>(
    INFORMATION_CUSTOMER_LABELS.EDIT_CUSTOMER_TITLE
  );
  const [isBranch, setIsBranch] = useState<boolean>(false);

  const getMerchantParam = () => {
    const getMerchant = defaultTo(searchParams.get("publicMerchantId"), "");

    if (!isEmpty(getMerchant)) {
      setGetMerchantId(getMerchant);
      dispatch(
        getNodeInfo({ configIds: "cn001", publicMerchantId: getMerchant })
      );
      setIsFromUrl(true);
    }
  };

  const handleOpenEditModal = (isOpen: boolean) => setModalOpenEdit(isOpen);

  const getError = (value: string) => {
    const formValue: string = defaultTo(form.getValues(value), "");

    if (formValue.length <= 0) {
      form.register(value, { required: true });
      form.setError(value, { type: "required" });
    }
  };

  const validateForm = (isChecked: boolean) => {
    getError(FieldsCustomerEnum.commercialName);
    getError(FieldsCustomerEnum.clientType);
    getError(FieldsCustomerEnum.operationCountry);

    if (!isChecked) getError(FieldsCustomerEnum.constitutionCountry);

    return form.trigger();
  };

  const handleOnClickContinue = async () => {
    const validForm: boolean = await validateForm(countryConstitutionCheck);

    if (validForm) {
      if (!isFromUrl) {
        setIsOpenLoaderModal(true);

        dispatch(
          createMerchantNode(
            buildRequest(
              form,
              nodeId,
              getMerchantId,
              countryConstitutionCheck,
              hasParent,
              isBranch
            )
          )
        );
      } else {
        setModalOpenEdit(true);
      }
    }
  };

  const buildRedirect = (redirectUrl?: string, merchantId?: string) => {
    const id = merchantId || getMerchantId;

    if (!redirectUrl) {
      const getMerchantBasicInformation = JSON.parse(
        defaultTo(sessionStorage.getItem("basicMerchantInformation"), "{}")
      );

      const getLastRoute = get(
        getMerchantBasicInformation,
        "layout.lastRoute",
        ""
      );

      redirectUrl =
        getLastRoute.length > 0
          ? getLastRoute
          : `/merchant${API_ROUTES.BASIC_DETAILS}`;
      window.location.href = `${redirectUrl}?publicMerchantId=${id}&menuItem=true`;
    } else {
      if (!redirectUrl?.startsWith("/")) redirectUrl = "/" + redirectUrl;

      window.location.href = `/merchant${redirectUrl}?publicMerchantId=${id}&menuItem=true`;
    }
  };

  const handleOnClickBack = () => {
    if (!isFromUrl) {
      navigate(API_ROUTES.OWNER);
    } else {
      buildRedirect();
    }
  };

  const handleContinueModal = async () => {
    setIsOpenLoaderModal(true);
    handleOpenEditModal(false);
    try {
      await dispatch(
        createMerchantNode(
          buildRequest(
            form,
            nodeId,
            getMerchantId,
            countryConstitutionCheck,
            hasParent,
            isBranch
          )
        )
      ).then((response) => {
        if (!has(response, "error")) handleOnClickBack();
      });
    } catch (e) {}
    setIsOpenLoaderModal(false);
  };

  const handleOnChange = () => {
    setErrorName(false);
  };

  const handleOnChangeCheckBox = async (isChecked: boolean) => {
    setCountryConstitutionCheck(isChecked);
    await validateForm(isChecked);
  };

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
      setIsOpenLoaderModal(false);
    }
  }, [notification]);

  useEffect(() => {
    setPublicMerchantId(get(response, "publicMerchantId", ""));
  }, [response]);

  useEffect(() => {
    if (response.code === ErrorEnum.node_create_duplicate) {
      setErrorName(true);
    }
    if (get(response, "merchantId")) buildRedirect();
  }, [response, notification]);

  useEffect(() => {
    setIsOpenLoaderModal(false);
    if (!isEmpty(publicMerchantId)) {
      buildRedirect(API_ROUTES.BASIC_DETAILS, publicMerchantId);
    }
  }, [publicMerchantId]);

  useEffect(() => {
    const nodeId: string = defaultTo(searchParams.get("nodeId"), "");
    const merchantId = defaultTo(searchParams.get("publicMerchantId"), "");

    if (!isEmpty(nodeId)) {
      setNodeId(nodeId);
      setHasParent(true);
    } else if (isEmpty(merchantId)) {
      window.location.href = `/create-node${API_ROUTES.OWNER}`;
    }
    getMerchantParam();
  }, []);

  useEffect(() => {
    if (getNodeInfoResponse !== initialState.getNodeInfoResponse) {
      form.reset(getNodeInfoInitialValue(getNodeInfoResponse));
      setCountryConstitutionCheck(false);
      setNodeId(defaultTo(getNodeInfoResponse.nodeId, ""));
    }
    if (
      !isEmpty(getNodeInfoResponse.entityName) &&
      getNodeInfoResponse.entityName === EntityNameEnum.branch
    ) {
      setEditTitle(INFORMATION_CUSTOMER_LABELS.EDIT_BRANCH_TITLE);
      setIsBranch(true);
    }
  }, [getNodeInfoResponse]);

  return {
    countryConstitutionCheck,
    editTitle,
    errorName,
    form,
    handleContinueModal,
    handleOnChange,
    handleOnChangeCheckBox,
    handleOnClickBack,
    handleOnClickContinue,
    handleOpenEditModal,
    isFromUrl,
    isOpenLoaderModal,
    modalOpenEdit,
  };
};
