/**
 * MenuItemPaths redirect enum
 */
export enum MenuItemPathsEnumPathEnum {
  MERCHANT_DATA = "/merchant/basic-details?publicMerchantId={merchantId}&menuItem=true",
  BILLING_CONFIGURATION = "/financial-configuration/billing?publicMerchantId={merchantId}&menuItem=true",
  RATES_AND_DISCOUNTS = "/console-rates?publicMerchantId={merchantId}&menuItem=true",
  PROCESSING = "/processing/?menuItem=true&publicMerchantId={merchantId}",
  SERVICES = "/service-configuration/?menuItem=true&publicMerchantId={merchantId}",
  DEVELOPERS = "/developers?publicMerchantId={merchantId}&menuItem=true",
  RESUME = "/merchant-resume",
}

export enum MenuItemMassivePathEnum {
  MERCHANT_DATA = "/merchant/batch/basic-details",
  PROCESSING = "/processing/batch/",
  SERVICES = "/service-configuration/batch/",
  BILLING_CONFIGURATION = "/financial-configuration/batch/billing",
  RATES_DISCOUNTS_MASSIVE = "/console-rates",
}

export enum URLS_FOR_CUSTOMER_BRANCH {
  MERCHANT_BASIC_DETAILS = "/merchant/basic-details",
  MERCHANT_BILLING_DETAILS = "/merchant/billing-details",
  MERCHANT_LEGAL_DETAILS = "/merchant/legal-details",
  MERCHANT_CONTACT_DETAILS = "/merchant/contact-details",
  FINANCIAL_BILLING = "/financial-configuration/billing",
  FINANCIAL_DISPERSION = "/financial-configuration/dispersion",
  FINANCIAL_CHARGE = "/financial-configuration/charge",
}
