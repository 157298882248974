import { TEXT_FLAG } from "../../components/TableCells/CellFlag/CellFlag.constants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const CATALOG_CURRENCY_CODE = {
  [TEXT_FLAG.Colombia]: CurrencyEnum.COP,
  [TEXT_FLAG.Mexico]: CurrencyEnum.MXN,
  [TEXT_FLAG.Chile]: CurrencyEnum.CLP,
  [TEXT_FLAG.Peru]: CurrencyEnum.PEN,
  [TEXT_FLAG.EstadosUnidosdeAmerica]: CurrencyEnum.USD,
  [TEXT_FLAG.Ecuador]: CurrencyEnum.USD,
  [TEXT_FLAG.Brasil]: CurrencyEnum.BRL,
  [TEXT_FLAG.CostaRica]: CurrencyEnum.USD,
  [TEXT_FLAG.Nicaragua]: CurrencyEnum.USD,
  [TEXT_FLAG.ElSalvador]: CurrencyEnum.USD,
  [TEXT_FLAG.Panama]: CurrencyEnum.USD,
  [TEXT_FLAG.Guatemala]: CurrencyEnum.USD,
  [TEXT_FLAG.Honduras]: CurrencyEnum.USD,
};
