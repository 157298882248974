import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ButtonConstants } from "../../../shared/infrastructure/constants/ButtonConstants";
import error from "../../../assets/images/error.svg";
import errorDetail from "../../../assets/images/error-detail.svg";
import { ModalConstants } from "../../../shared/infrastructure/constants/ModalConstants";
import { ModalErrorProps } from "./ModalError.interfaces";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelAction: {
      color: theme.palette.grey["800"],
    },
    center: {
      display: "block",
      "margin-left": "auto",
      "margin-right": "auto",
      width: "50%",
    },
    closeButton: {
      "&:disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        marginBottom: theme.spacing(3),
        opacity: 0.5,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      Height: "36px",
      Width: " 117px",
    },
    dialogActions: {
      display: "block",
      padding: "17px",
      textAlign: "center",
    },
    dialogContent: {
      paddingLeft: "90px",
      paddingRight: "90px",
    },
    dialogContentText: {
      marginBottom: 10,
      maxHeight: "220px",
    },
    title: {
      paddingBottom: "10px",
      paddingTop: "22px",
    },
  })
);

export const ModalError: React.FC<ModalErrorProps> = (
  props: React.PropsWithChildren<ModalErrorProps>
) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={"paper"}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          className={classes.dialogContentText}
          id="alert-dialog-description"
        >
          <img
            className={classes.center}
            src={error}
            alt="errorCsv"
            style={{
              maxHeight: "300px",
              paddingTop: "12px",
              width: "auto",
            }}
          />
          <Typography variant="h5" color="primary" className={classes.title}>
            {ModalConstants.ERROR_MESSAGE}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {props.errors?.errors?.map((error, number) => (
              <Grid key={number} container spacing={1}>
                <Grid item xs={3}>
                  <img
                    src={errorDetail}
                    alt="errorCsv"
                    style={{
                      float: "right",
                      maxHeight: "30px",
                      width: "auto",
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    {ModalConstants.ERROR_COLUMN} {error.column}{" "}
                    {ModalConstants.ERROR_ROW} {error.row}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          id={"ButtonBack"}
          onClick={props.handleClose}
          className={classes.closeButton}
        >
          {ButtonConstants.BTN_COME_BACK}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
