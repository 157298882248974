import {
  IComment,
  IHistoricalItem,
  ITransaction,
} from "../../store/interfaces/TransactionsState.interfaces";

const txt =
  "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis au";

const comments: IComment[] = [
  {
    comment: txt,
    created: 12312312312,
    user: "",
  },
  {
    comment: txt,
    created: 12312312312,
    user: "",
  },
  {
    comment: txt,
    created: 12312312312,
    user: "",
  },
];

export const SAMPLE_TRANSACTION: ITransaction[] = [
  {
    acquirerBank: "Banco de Bogotá",
    answerCode: "12345678",
    answerMessage: "Transacción aprobada",
    approvalNumber: "3245127588979",
    bank: "Banco de Bogotá",
    bankID: "3245127588979",
    cardNumber: "**** 4848",
    cardType: "visa",
    certificateState: "Fallido",
    certificateURL: "https://google.com",
    comments: comments,
    cus: "3245127588979",
    date: "01-12-2021",
    document_number: "17223345076",
    email: "chuck@email.com",
    historical: [
      {
        eventType: "file",
        status: "GENERATED",
        updatedAt: "1644410000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "RETRIED",
        updatedAt: "1644420100000",
      },
      {
        amount: 29000.3,
        eventType: "transaction",
        paymentMethod: "card",
        status: "APPROVED",
        updatedAt: "1644200000000",
      },
      {
        amount: 29000.3,
        eventType: "transaction",
        paymentMethod: "card",
        status: "DECLINED",
        updatedAt: "1644300000000",
      },
      {
        eventType: "file",
        status: "FAILED",
        updatedAt: "1644400000000",
      },
      {
        eventType: "file",
        status: "COMPLETED",
        updatedAt: "1644415000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "GENERATED",
        updatedAt: "1644420000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "APPROVED",
        updatedAt: "1644420121000",
      },
    ] as IHistoricalItem[],
    hour: "10:41:25",
    id: Math.random().toString(36).slice(2),
    months: "",
    name: "Chuck Chiriboga Velásquez",
    paymentMethod: "card",
    phoneNumber: "+57 960060307",
    processor: "PSE",
    reattemps: "No",
    refund: "Si",
    refundState: "Inicializada",
    sendAnswer: "Fallido",
    sendStatus: "",
    status: "approved",
    ticketNumber: "840664430198060622",
    transactionAmount: "29,900.00 COP",
    transactionId: "840664430198060622",
    vehiclePlate: "CZR-263",
  },
  {
    acquirerBank: "",
    answerCode: "12345678",
    answerMessage: "Transacción aprobada",
    approvalNumber: "3245127588979",
    bank: "Banco de Bogotá",
    bankID: "3245127588979",
    cardNumber: "**** 4848",
    cardType: "visa",
    certificateState: "Fallido",
    certificateURL: "https://google.com",
    comments: comments,
    cus: "3245127588979",
    date: "01-04-2021",
    document_number: "17223345076",
    email: "chuck@email.com",
    historical: [
      {
        eventType: "file",
        status: "GENERATED",
        updatedAt: "1644410000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "RETRIED",
        updatedAt: "1644420100000",
      },
      {
        amount: 29000.3,
        eventType: "transaction",
        paymentMethod: "card",
        status: "APPROVED",
        updatedAt: "1644200000000",
      },
      {
        amount: 29000.3,
        eventType: "transaction",
        paymentMethod: "card",
        status: "DECLINED",
        updatedAt: "1644300000000",
      },
      {
        eventType: "file",
        status: "FAILED",
        updatedAt: "1644400000000",
      },
      {
        eventType: "file",
        status: "COMPLETED",
        updatedAt: "1644415000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "GENERATED",
        updatedAt: "1644420000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "APPROVED",
        updatedAt: "1644420121000",
      },
    ] as IHistoricalItem[],
    hour: "10:41:25",
    id: Math.random().toString(36).slice(2),
    months: "",
    name: "Chuck Chiriboga Velásquez",
    paymentMethod: "card",
    phoneNumber: "+57 960060307",
    processor: "PSE",
    reattemps: "No",
    refund: "Si",
    refundState: "Inicializada",
    sendAnswer: "Fallido",
    sendStatus: "",
    status: "approved",
    ticketNumber: "840664430111060600",
    transactionAmount: "29,900.00 COP",
    transactionId: "840664430198080622",
    vehiclePlate: "CZR-263",
  },
  {
    acquirerBank: "",
    answerCode: "12345678",
    answerMessage: "Transacción aprobada",
    approvalNumber: "3245127588979",
    bank: "Banco de Bogotá",
    bankID: "3245127588979",
    cardNumber: "**** 4848",
    cardType: "visa",
    certificateState: "Fallido",
    certificateURL: "https://google.com",
    comments: comments,
    cus: "3245127588979",
    date: "01-07-2021",
    document_number: "17223345076",
    email: "chuck@email.com",
    historical: [
      {
        eventType: "file",
        status: "GENERATED",
        updatedAt: "1644410000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "RETRIED",
        updatedAt: "1644420100000",
      },
      {
        amount: 29000.3,
        eventType: "transaction",
        paymentMethod: "card",
        status: "APPROVED",
        updatedAt: "1644200000000",
      },
      {
        amount: 29000.3,
        eventType: "transaction",
        paymentMethod: "card",
        status: "DECLINED",
        updatedAt: "1644300000000",
      },
      {
        eventType: "file",
        status: "FAILED",
        updatedAt: "1644400000000",
      },
      {
        eventType: "file",
        status: "COMPLETED",
        updatedAt: "1644415000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "GENERATED",
        updatedAt: "1644420000000",
      },
      {
        amount: 29000.3,
        eventType: "refund",
        paymentMethod: "card",
        status: "APPROVED",
        updatedAt: "1644420121000",
      },
    ] as IHistoricalItem[],
    hour: "10:41:25",
    id: Math.random().toString(36).slice(2),
    months: "",
    name: "Chuck Chiriboga Velásquez",
    paymentMethod: "card",
    phoneNumber: "+57 960060307",
    processor: "PSE",
    reattemps: "No",
    refund: "Si",
    refundState: "Inicializada",
    sendAnswer: "Fallido",
    sendStatus: "",
    status: "approved",
    ticketNumber: "840664430198060688",
    transactionAmount: "29,900.00 COP",
    transactionId: "840664436198060622",
    vehiclePlate: "CZR-263",
  },
];
