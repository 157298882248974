import React from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { useStyles } from "./ShopifyDomain.styles";
import useShopifyDomainState from "./state/useShopifyDomainState";
import { SaveIcon } from "../../Icons/Icons";
import { IconApproved } from "../../../assets/svgs/IconApproved";
import { ModalChangeUrl } from "../components/ModalChangeUrl/ModalChangeUrl";
import DialogSuccess from "../../DialogSuccess/DialogSuccess";
import NotificationSnackbar from "../../Notification/NotificationSnackbar";
import { VisibilityOff } from "@material-ui/icons";
import { Copy } from "react-feather";

const ShopifyDomain = () => {
  const classes = useStyles();

  const {
    actions: {
      handleChange,
      handleOnClikConfigurateButton,
      handleOnClose,
      handleSaveButton,
      handleCloseModal,
      handleSubmitDomain,
      handleClickShowBlurredText,
      handleClickCopyPublicCredential,
      handleClickCopyPrivateCredential,
    },
    disableSave,
    errorDomain,
    isValid,
    loading,
    shopifyDomain,
    showChangeUrlModal,
    showDialog,
    isSaveDomain,
    handleCloseSuccessDialog,
    showSuccessDialog,
    notification,
    merchantCredentials,
    showBlurredText,
    handleOnCloseNotification,
    hasDomain,
  } = useShopifyDomainState();

  const isNotValidAndThereAreNotCharacteres =
    !isValid && shopifyDomain.trim().length !== 0;

  const renderHelperText = () => {
    const helperText = "Ingresa un dominio válido";

    if (isNotValidAndThereAreNotCharacteres) {
      return <div className={classes.helperText}>{helperText}</div>;
    }
    if (isValid) {
      return (
        <div className={classes.helperText}>
          <IconApproved className={classes.helperTextIcon} />
          {shopifyDomain}
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.labelTitle}>
            Ingresa tu Dominio
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={5}>
            <TextField
              id="shopifyDomain"
              name="shopifyDomain"
              label="Ejemplo: Mitienda.myshopify.com"
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              type="text"
              fullWidth
              variant="outlined"
              error={isNotValidAndThereAreNotCharacteres}
              value={shopifyDomain}
              onChange={handleChange}
              helperText={renderHelperText()}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="default"
              className={classes.saveShopifyButton}
              startIcon={
                loading ? <CircularProgress size={12} /> : <SaveIcon />
              }
              onClick={handleSaveButton}
              disabled={disableSave}
            >
              Guardar
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="outlined"
              color="default"
              className={
                !isSaveDomain
                  ? classes.checkoutButton
                  : classes.checkoutButtonActive
              }
              disabled={!isSaveDomain}
              onClick={handleOnClikConfigurateButton}
            >
              Configura el estilo del checkout
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          direction="row"
          className={classes.containerInfo}
        >
          <InfoIcon />
          <Typography className={classes.labelInfo}>
            Para agregar la aplicación Kushki en Shopify, haz click{" "}
            <Link
              href="https://apps.shopify.com/kushki-1?locale=es"
              target="_blank"
            >
              aquí
            </Link>
          </Typography>
        </Grid>
        {merchantCredentials.hasCredentials && hasDomain && (
          <Grid container item xs={12} className={classes.credentialsSection}>
            <Grid item xs={12} md={5} className={classes.inputTextCredential}>
              <TextField
                id="publicCredential"
                name="publicCredential"
                label="Public API Key"
                disabled
                value={merchantCredentials.publicCredential}
                type="text"
                fullWidth
                variant="outlined"
                InputProps={{
                  type: "text",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className={classes.copyIcon}>
                        <Copy
                          size={16}
                          onClick={handleClickCopyPublicCredential}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="privateCredential"
                name="privateCredential"
                label="Private API Key"
                type="text"
                value={merchantCredentials.privateCredential}
                disabled
                fullWidth
                variant="outlined"
                InputProps={{
                  type: showBlurredText ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        id={"show-obfuscated-text-button"}
                        onClick={handleClickShowBlurredText}
                        edge="end"
                      >
                        {!showBlurredText ? (
                          <VisibilityOff />
                        ) : (
                          <Copy
                            size={16}
                            onClick={handleClickCopyPrivateCredential}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Dialog
        classes={{ paper: classes.dialogDomain }}
        open={showDialog}
        onClose={handleOnClose}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            Validación de dominio
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContainer}>
            {errorDomain}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <ModalChangeUrl
        open={showChangeUrlModal}
        handleClose={handleCloseModal}
        handleAccept={handleSubmitDomain}
      />

      <DialogSuccess
        title={"Dominio guardado con éxito"}
        description={
          "Las credenciales de tu dominio se han creado con éxito bajo el alias Shopify."
        }
        secondaryDescription={
          "En caso que haya realizado primero la asociación del medio de pago en Shopify, debes desinstalarlo y volverlo a instalar con las credenciales de Kushki."
        }
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
      />

      <NotificationSnackbar
        msg={notification.message}
        open={notification.open}
        type={notification.type}
        handlerClose={handleOnCloseNotification}
      />
    </>
  );
};

export default ShopifyDomain;
