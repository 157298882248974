import {
  defaultColumnsSelectableManualContingency,
  defaultHeadersManualContingencyHistory,
} from "../../../shared/infrastructure/filter/ManualContingencyValue";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  downloadManualContingencyWS,
  downloadPreprocessFileWS,
  getHistoricBillingData,
  setDownloadFileDetail,
  setOpenModalDetailTimeLine,
  setStepTimeLineData,
} from "../../../store/actionCreators";
import {
  dateFormat,
  initialFromDate,
  initialToDate,
  ISort,
  IState,
} from "../../BillingDashboard/state/useBillingDashboardState";
import { CountryList } from "../../../components/DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { IBillingDashboardState, INotification } from "../../../store/reducer";
import { delay, get, map, set } from "lodash";
import {
  PaginationTableProps,
  TableHeaders,
} from "@kushki/frontend-molecules/backoffice-table";
import { DateRange } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/src/typings/date";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../../../types/remote/response_search_blling_by_filters";
import { SearchBillingRequest } from "../../../../types/search_billing_request";
import { IColumnsTable } from "../../../shared/infrastructure/table/IColumnsTable";
import { IStepRetentionTimeLine } from "../../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { DownloadLiquidationDetail } from "../../../../types/download_liquidation_detail";
import { DownloadInvoiceDetail } from "../../../../types/download_invoice_detail";
import { DownloadLedgerDetail } from "../../../../types/download_ledger_detail";
import { DownloadDocumentParams } from "../../../components/DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";

export interface BillingManualContingencyState {
  data: ResponseSearchBillingByFilters;
  paginationProps: PaginationTableProps | undefined;
  headers: TableHeaders<object>[];
  sortProps: ISort;
  selectProps: { country: CountryList };
  setLoadingHistoric: boolean;
  notificacion: INotification;
  handleCloseNotification: () => void;
  setLoadingManualContingency: boolean;
  actions: {
    handleSetDate: (dateRange: DateRange) => void;
    range: DateRange;
    query: string;
    handleInputChange: (value: string) => void;
    handleHistoricSearchBar: (value: string) => void;
    downloadFileState: {
      isLoading: boolean;
      downloadFile: () => void;
    };
  };
  settingsColumns: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    handleAppliedColumnsFilters: (restore?: boolean) => void;
    renderColumns: IColumnsTable[];
  };
  modalOver: ModalOverUtils;
}

export type ModalOverUtils = {
  openModal: boolean;
  handlerSelectTrxItem: (row: InvoiceRecord | undefined) => void;
  selectedRow: InvoiceRecord;
  handleOpenModal: () => void;
  handleOpenDialogDetail?: (data: IStepRetentionTimeLine) => void;
  handleCloseDialogDetail?: () => void;
  handlerCloseModal: () => void;
  downloadPreprocessFileWS: (
    params:
      | DownloadLiquidationDetail
      | DownloadInvoiceDetail
      | DownloadLedgerDetail
  ) => void;
};

export const UseBillingManualContingency = (): BillingManualContingencyState => {
  const dispatch = useDispatch();
  const selector = useSelector((state: IBillingDashboardState) => state);
  const [country] = useState<CountryList>(CountryEnum.colombia);
  const [fromDate, setStartDate] = useState<string>(initialFromDate);
  const [toDate, setEndDate] = useState<string>(initialToDate);
  const [filters] = useState<object>({ status: "failed_process" });
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<IState>({
    sortByKey: "",
    sortDirection: "asc",
  });
  const [range, setDateRange] = useState<DateRange>([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);
  const [query, setQuery] = useState<string>("");
  const [notification, setNotificactionState] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [param, setParam] = useState<SearchBillingRequest>({
    from: fromDate,
    to: toDate,
    limit: pageSize,
    offset: 0,
    format: "screen",
    country,
    filter: filters,
  });
  const [selectedColumns, setSelectedColumns] = React.useState<string[]>(
    defaultColumnsSelectableManualContingency
  );
  const [renderColumns, setRenderColumns] = useState<IColumnsTable[]>(
    defaultHeadersManualContingencyHistory.filter(
      (column: TableHeaders<any>) => column.view
    ) as IColumnsTable[]
  );

  const [conciliationTrx, setConciliationtrxTrx] = useState<
    InvoiceRecord | undefined
  >();
  const NO_FILE_FOR_DOWNLOADING = "No existen registros a descargar";
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenDialogDetail = (data: IStepRetentionTimeLine) => {
    dispatch(setStepTimeLineData(data));
    dispatch(setOpenModalDetailTimeLine(true));
  };
  const handleCloseDialogDetail = () => {
    dispatch(setOpenModalDetailTimeLine(false));
  };

  const handlerSelectTrxItem = (row: InvoiceRecord | undefined) => {
    if (row) {
      setConciliationtrxTrx(row);
      handleOpenModal();
    }
  };

  const handleAppliedColumnsFilters = (restore?: boolean) => {
    const validCountry: boolean = [CountryEnum.colombia].includes(country);
    let selectedColumns_aux: string[] = selectedColumns;
    if (restore) {
      setSelectedColumns(defaultColumnsSelectableManualContingency);
      selectedColumns_aux = defaultColumnsSelectableManualContingency;
    }
    if (selectedColumns_aux.length === 0 && validCountry) {
      setRenderColumns(
        defaultHeadersManualContingencyHistory as IColumnsTable[]
      );
      return;
    }
    let aux_columns: IColumnsTable[] = defaultHeadersManualContingencyHistory as IColumnsTable[];
    if (validCountry) {
      aux_columns = aux_columns.filter((column: IColumnsTable) => {
        return !column.filter || selectedColumns_aux.includes(column.id);
      });
    }
    setRenderColumns(aux_columns);
  };
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const orderBy: string | undefined = sort.sortByKey;
    const order: string | undefined = sort.sortDirection;
    const isAsc: boolean = orderBy === property && order === "asc";
    setSort({
      ...sort,
      sortDirection: isAsc ? "desc" : "asc",
      sortByKey: property,
    });
  };
  const handleChangeLimit = (newLimit: string): void => {
    setParam({ ...param, limit: Number(newLimit) });
    setPageSize(Number(newLimit));
  };
  const handleSetDate = (dateRange: DateRange) => {
    setDateRange(dateRange);
    const [firstDate, secondDate]: [
      MaterialUiPickersDate,
      MaterialUiPickersDate
    ] = dateRange;
    const startDate: Date = new Date(firstDate?.toISOString()!);
    const endDate: Date = new Date(secondDate?.toISOString()!);
    setStartDate(format(startOfDay(startDate), dateFormat));
    setEndDate(format(endOfDay(endDate), dateFormat));
    setPage(1);
  };
  const handleInputChange = (event: string) => {
    setQuery(event);
  };
  const handleHistoricSearchBar = (value: string) => {
    if (value !== "Enter") return;
    setPage(1);
    setParam({ ...param, generalField: query === "" ? undefined : query });
  };

  const downloadFileFunction = () => {
    if (get(selector, "historicBillingData.total", 0) > 0) {
      dispatch(
        downloadManualContingencyWS({
          action: "downloadManualContingency",
          data: {
            country: country,
            to: toDate,
            from: fromDate,
          },
        })
      );
    } else {
      setNotificactionState({
        open: true,
        message: NO_FILE_FOR_DOWNLOADING,
        type: "error",
      });
    }
  };

  const handleCloseNotification = () => {
    setNotificactionState({
      open: false,
      message: selector.notification.message,
      type: selector.notification.type,
    });
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown> | undefined,
    value: number
  ) => {
    setPage(value);
    dispatch(
      getHistoricBillingData({
        ...param,
        format: "screen",
        offset: Number(pageSize * (value - 1)),
      })
    );
  };

  const downloadPreprocessFile = (params: DownloadDocumentParams) => {
    dispatch(downloadPreprocessFileWS(params));
  };

  useEffect(() => {
    if (selector.fileDownloadDetail === "") return;

    let a = document.createElement("a");
    a.href = selector.fileDownloadDetail;
    a.download = "";
    a.click();
    a.remove();

    delay(() => {
      dispatch(setDownloadFileDetail(""));
    }, 300);
  }, [selector.fileDownloadDetail]);

  useEffect(() => {
    setParam({ ...param, from: fromDate, to: toDate });
  }, [fromDate, toDate]);

  useEffect(() => {
    dispatch(
      getHistoricBillingData({
        ...param,
      })
    );
  }, [param]);

  useEffect(() => {
    setNotificactionState(selector.notification);
  }, [get(selector, "isLoadingFileDownloadManualContingency", false)]);

  useEffect(() => {
    if (get(selector, "fileDownloadManualContingency", "") === "") return;
    const url = get(selector, "fileDownloadManualContingency", "");

    let a = document.createElement("a");
    a.href = `data:aplication/${"xlsx"};base64,` + url;
    a.download = `manualContingency_${format(new Date(), "yyyy-MM-dd")}.xlsx`;
    a.click();
    a.remove();
    set(selector, "fileDownloadManualContingency", "");
    set(notification, "open", false);
    set(notification, "message", "");
  }, [get(selector, "fileDownloadManualContingency", "")]);

  return {
    actions: {
      handleSetDate,
      range,
      query,
      handleInputChange,
      handleHistoricSearchBar,
      downloadFileState: {
        isLoading: false,
        downloadFile: downloadFileFunction,
      },
    },
    setLoadingHistoric: get(selector, "setLoadingHistoric", false),
    setLoadingManualContingency: get(
      selector,
      "isLoadingFileDownloadManualContingency",
      false
    ),
    data: {
      records: map(get(selector, "historicBillingData.records", []), (item) =>
        set(item, "backgrounColor", "#FFF7E5")
      ),
      total: get(selector, "historicBillingData.total", 0),
    },
    paginationProps:
      get(selector, "historicBillingData.total", 0) !== 0
        ? {
            page,
            limit: Number(pageSize),
            text: "Transacciones por página",
            trxPerPage: Number(pageSize),
            handleChangePage,
            handleChangeLimit,
            size: "small",
            isMobile: false,
            siblingCount: 0,
            total: get(selector, "historicBillingData.total", 0),
          }
        : undefined,
    headers: renderColumns as TableHeaders<any>[],
    notificacion: notification,
    handleCloseNotification,
    sortProps: {
      handleRequestSort,
      order: sort.sortDirection!,
      orderBy: sort.sortByKey!,
    },
    selectProps: { country: country },
    settingsColumns: {
      handleAppliedColumnsFilters,
      selectedColumns,
      setSelectedColumns,
      renderColumns,
    },
    modalOver: {
      handlerSelectTrxItem: handlerSelectTrxItem,
      selectedRow: conciliationTrx!,
      openModal: openModal,
      handlerCloseModal: handleCloseModal,
      handleOpenModal,
      handleOpenDialogDetail,
      handleCloseDialogDetail,
      downloadPreprocessFileWS: downloadPreprocessFile,
    },
  };
};
