/* istanbul ignore file */
/* eslint-disable */
export const environment: {
    kushkiUrl: string;
    kushkiUrlSandbox: string;
    envName: string;
    devTools: boolean;
    wsUrl: string;
    rollbarId: string;
    periodicitySpecialCond: string;
} = {
    kushkiUrl: "https://api.kushkipagos.com",
    kushkiUrlSandbox: "https://api.kushkipagos.com",
    envName: "primary",
    devTools: false,
    wsUrl: "",
    rollbarId: "5b91345cd8fd446692305986020a53a2",
    periodicitySpecialCond: "month"
};

