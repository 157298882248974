export const tipoIndustria: Array<string> = [
  "Cripto y Forex",
  "Finanzas",
  "Aplicaciones",
  "Transporte",
  "Ventas - comercio",
  "Tecnología",
  "Hoteles",
  "Entregas",
  "PSP",
  "Fitness",
  "Construcción",
  "Seguro",
  "Apuestas",
  "Gateway",
  "Educación",
  "Comidas y bebidas",
  "Cuidado de la salud",
  "Asesorias - Consultorias",
  "Gobierno",
  "Entretenimiento",
  "Telecomunicaciones",
  "Utilidades",
  "Media",
  "Aerolíneas",
  "Mensajería",
  "Turismo",
  "Servicios públicos",
  "Servicios profesionales",
];

export interface IBasicData {
  title: string;
  country: string;
  company_type: Array<string>;
  industry_type: Array<string>;
  confirm: string;
  accept: string;
  label: string;
}

export interface Category {
  label: string;
  id: string;

  [k: string]: any;
}
