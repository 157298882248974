import { useMsal } from "@azure/msal-react";
import { authRequest } from "../../../auth-config";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { IPayload } from "../../../../types/payload";
import { IAzureToken } from "../../../../types/azure_token";
import { format } from "date-fns";
import { getConsoleRoles, RoleEnum } from "../../../shared/utils/RoleUtils";
import { RouteEnum } from "../../../shared/utils/RouteEnum";
import { useEffect } from "react";

export interface ILoginState {
  handlers: {
    handleRequestToken: () => void;
    handleLogin: () => void;
  };
}

export interface LoginStateProps {
  isAuthenticated: boolean;
}

export type PayloadType = IAzureToken & JwtPayload;

export const useLoginState = (): ILoginState => {
  const { instance, accounts } = useMsal();

  const getRoute = (groups: string[]): RouteEnum => {
    let other_roles: boolean = false;

    const roles = getConsoleRoles(groups);

    const smartlink_user: boolean =
      groups.length == 1 && (roles.WebcheckoutMaster || roles.WebcheckoutBasic);

    const snr_user = roles.SnrCoordinator || roles.SnrAgent;

    groups.forEach((group: string) => {
      if (group !== RoleEnum.SNR_AGENT && group !== RoleEnum.SNR_COORDINATOR)
        other_roles = true;
    });
    if (snr_user && !other_roles) return RouteEnum.SNR;
    if (smartlink_user) return RouteEnum.SMARTLINKS;
    if (roles.BackofficeUserReader) return RouteEnum.TRANSACTIONS;
    if (roles.BackofficeUserMaker) return RouteEnum.USERS;
    if (roles.BackofficeGlobalReports) return RouteEnum.REPORTS;
    if (roles.FinOpsBackOffice) return RouteEnum.BANK_CONCILIATION_TRANSACTION;
    if (roles.TreasuryBackOffice) return RouteEnum.BANK_CONCILIATION_SUMMARY;

    return RouteEnum.DASHBOARD;
  };

  const requestAccessToken = async (): Promise<string> => {
    const request = {
      account: accounts[0],
      ...authRequest,
    };

    const { accessToken } = await instance.acquireTokenSilent(request);

    return accessToken;
  };

  const handleLogin = async () => {
    localStorage.clear();
    await instance.loginRedirect();
  };

  const handleRequestToken = async () => {
    const accessToken = await requestAccessToken();

    const access: IAzureToken = jwtDecode<PayloadType>(accessToken);

    const payload: IPayload = {
      "[cognito:groups]": access.roles,
      "[cognito:username]": access.given_name,
      auth_time: access.iat,
      email: access.upn,
      exp: access.exp,
      family_name: access.family_name,
      iss: access.iss,
      isVerify: true,
      name: access.given_name,
      token: accessToken,
      token_use: "id",
      zoneinfo: format(new Date(), "z"),
    };

    localStorage.setItem("payload", JSON.stringify(payload));
    localStorage.setItem("jwt", accessToken);

    const initialRoute = getRoute(access.roles || []);

    window.location.assign(`/${initialRoute}`);
  };

  useEffect(() => {
    setTimeout(() => {
      handleLogin();
    }, 2000);

    if (accounts[0]) handleRequestToken();
  }, [accounts]);

  return {
    handlers: {
      handleLogin,
      handleRequestToken,
    },
  };
};
