import { createNamedStyles } from "../../shared/utils";

export const footerStyles = createNamedStyles({
  buttonContainer: {
    alignItems: "center !important",
    margin: "auto",
    justifyContent: "center",
    display: "flex",
  },
  footerGrid: {
    alignItems: "center",
    display: "flex",
  },
  footer: {
    boxShadow:
      "0px -8px 17px rgba(106, 167, 197, 0.15), 0px -1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: {
      xs: "15px 25px 15px 25px",
      sm: "30px 50px 30px 50px",
    },
    backgroundColor: "#FAFCFD",
  },
  label: {
    color: "#64798D",
    fontWeight: 400,
    fontSize: { xs: "15px", md: "13px" },
    fontFamily: "IBM Plex Sans",
  },
  labelContainer: {
    paddingLeft: { sm: "3%", md: "15%", lg: "0%" },
  },
  returnButton: {
    marginRight: "50px",
    border: "1px solid #4498EE",
    color: "#4498EE",
    ":hover": {
      border: "1px solid #4498EE",
    },
  },
});
