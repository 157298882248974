import { CountryEnum } from "../CountryEnum";

export interface IColumnsTable {
  id: string;
  label: string;
  view: boolean;
  filter: boolean;
  orderBy?: string;
  disabled?: boolean;
  country?: CountryEnum[];
  filterLabel?: string;
  disabledFilter?: boolean;
  align?: string;
}

export enum ColumnsEnum {
  TAX_ID = "tax_id",
  MERCHANT_NAME = "merchant_name",
  INTERBANK_ACCOUNT_CODE = "inter_bank_account_code",
  CREATED = "created",
  CYCLE = "cycle",
  INVOICE_ID = "invoice_id",
  INVOICE_DOCUMENT_NUMBER = "invoice_document_number",
  INVOICE_NUMBER = "invoice_number",
  AMOUNT = "amount",
  KIND = "kind",
  INITIAL_STATUS = "initial_status",
  PROCESSING_DATE = "processing_date",
  STATUS = "status",
  STATUS_ALL = "statusAll",
  TRANSACTION_TYPE = "transaction_type",
  SOCIAL_REASON = "social_reason",
  MERCHANT_ID = "merchant_id",
  MUNICIPALITY = "municipality",
  MODEL = "model",
  KUSHKI_TOTAL_RETENTION = "kushki_total_retention",
  DAVIVIENDA_TOTAL_RETENTION = "davivienda_total_retention",
  BACK_BUTTON = "back_button",
  IS_OCB = "is_ocb",

  OWNER_NAME = "owner_name",
  CUSTOMER_NAME = "customer_name",
  IS_OCB_DYNAMO = "isOcb",
  OWNER_NAME_DYNAMO = "ownerName",
  CUSTOMER_NAME_DYNAMO = "customerName",
}

export enum ColumnsLabelsEnum {
  AMOUNT = "Monto Total",
  CYCLE = "Ciclo",
  INVOICE_DOCUMENT_NUMBER = "Nº de documento",
  KIND = "Tipo documento",
  INITIAL_STATUS = "E. Inicial",
  PROCESSING_DATE = "Revisado el",
  SOCIAL_REASON = "Razón Social",
  STATUS = "E. Final",
  TRANSACTION_TYPE = "Tipo de transacción",
  TAX_ID = "Tax ID",
  INTERBANK_ACCOUNT_CODE = "Nº de cuenta interbancaria",
}

const allCountries = [
  CountryEnum.colombia,
  CountryEnum.chile,
  CountryEnum.mexico,
  CountryEnum.peru,
  CountryEnum.ecuador,
];

export const defaultColumnsSelectable: string[] = [
  "invoice_document_number",
  "tax_id",
];
export const columnsTable: IColumnsTable[] = [
  {
    id: ColumnsEnum.TAX_ID,
    label: ColumnsLabelsEnum.TAX_ID,
    view: true,
    orderBy: " ",
    disabled: true,
    filter: true,
  },
  {
    id: ColumnsEnum.SOCIAL_REASON,
    label: "Razón Social",
    view: true,
    orderBy: "social_reason.keyword",
    disabled: false,
    filter: false,
    country: [
      CountryEnum.ecuador,
      CountryEnum.chile,
      CountryEnum.peru,
      CountryEnum.mexico,
    ],
  },
  {
    id: ColumnsEnum.MERCHANT_NAME,
    label: "Branch/Merchant",
    view: false,
    orderBy: "merchant_name.keyword",
    disabled: true,
    filter: true,
    country: [CountryEnum.colombia, CountryEnum.ecuador],
  },
  {
    id: ColumnsEnum.OWNER_NAME,
    label: "Owner",
    view: false,
    filter: true,
    filterLabel: "Owner",
  },
  {
    id: ColumnsEnum.CUSTOMER_NAME,
    label: "Customer",
    view: false,
    filter: true,
    filterLabel: "Customer",
  },
  {
    id: ColumnsEnum.IS_OCB,
    label: "Jerarquía",
    view: false,
    filter: true,
    filterLabel: "Jerarquía",
  },
  {
    id: ColumnsEnum.INVOICE_DOCUMENT_NUMBER,
    label: ColumnsLabelsEnum.INVOICE_DOCUMENT_NUMBER,
    view: true,
    filter: true,
    filterLabel: "Nº de documento",
    orderBy: " ",
    disabled: true,
    country: allCountries,
  },
  {
    id: ColumnsEnum.CYCLE,
    label: ColumnsLabelsEnum.CYCLE,
    view: false,
    orderBy: " ",
    disabled: true,
    filter: true,
  },
  {
    id: ColumnsEnum.TRANSACTION_TYPE,
    label: ColumnsLabelsEnum.TRANSACTION_TYPE,
    view: true,
    filter: true,
    filterLabel: "Tipo de transacción",
    country: allCountries,
  },
  {
    id: ColumnsEnum.CREATED,
    label: "Fecha de creación",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Fecha de creación",
    country: allCountries,
  },
  {
    id: ColumnsEnum.SOCIAL_REASON,
    label: "Razón social",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Razón social",
    country: [CountryEnum.colombia],
  },
  {
    id: ColumnsEnum.MERCHANT_ID,
    label: "Merchant ID",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Merchant ID",
    country: [CountryEnum.colombia, CountryEnum.ecuador],
  },

  {
    id: ColumnsEnum.MUNICIPALITY,
    label: "Municipio",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Municipio",
    country: [CountryEnum.colombia, CountryEnum.ecuador],
  },

  {
    id: ColumnsEnum.MODEL,
    label: "Modelo",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Modelo",
    country: [CountryEnum.colombia, CountryEnum.ecuador],
  },
  {
    id: ColumnsEnum.KUSHKI_TOTAL_RETENTION,
    label: "Retención total Kushki",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Retención total Kushki",
    country: [CountryEnum.colombia, CountryEnum.ecuador],
  },
  {
    id: ColumnsEnum.DAVIVIENDA_TOTAL_RETENTION,
    label: "Retención total Davivienda",
    view: true,
    filter: true,
    disabled: true,
    filterLabel: "Retención total Davivienda",
    country: [CountryEnum.colombia, CountryEnum.ecuador],
  },
  {
    id: ColumnsEnum.INTERBANK_ACCOUNT_CODE,
    label: ColumnsLabelsEnum.INTERBANK_ACCOUNT_CODE,
    view: false,
    filter: true,
    country: [CountryEnum.peru],
  },
  {
    id: ColumnsEnum.AMOUNT,
    label: ColumnsLabelsEnum.AMOUNT,
    view: true,
    orderBy: "amount",
    disabled: false,
    filter: false,
  },
  {
    id: ColumnsEnum.KIND,
    label: ColumnsLabelsEnum.KIND,
    view: true,
    filter: false,
    orderBy: " ",
    disabled: true,
    align: "center",
  },
  {
    id: ColumnsEnum.INITIAL_STATUS,
    label: ColumnsLabelsEnum.INITIAL_STATUS,
    view: true,
    filter: false,
    orderBy: " ",
    disabled: false,
    align: "center",
  },
  {
    id: ColumnsEnum.PROCESSING_DATE,
    label: ColumnsLabelsEnum.PROCESSING_DATE,
    view: true,
    filter: false,
    orderBy: " ",
    disabled: true,
  },
  {
    id: ColumnsEnum.STATUS,
    label: ColumnsLabelsEnum.STATUS,
    view: true,
    filter: false,
    orderBy: " ",
    disabled: true,
    align: "center",
  },
  {
    id: ColumnsEnum.BACK_BUTTON,
    label: "",
    view: true,
    filter: false,
    orderBy: "",
    disabled: true,
    align: "center",
  },
];

export enum ColumnsRetentionEnum {
  TAX_ID = "taxId",
  MERCHANT_NAME = "merchantName",
  OWNER = "ownerName",
  CUSTOMER = "customerName",
  HIERARCHY = "hierarchy",
  CYCLE = "cycle",
  RET_IVA = "retIva",
  RET_FUE = "retFue",
  RET_TOTAL_AMOUNT = "retTotalAmount",
  INITIAL_STATUS = "initialStatus",
  EDIT = "edit",
  STATUS = "status",
  STATUS_PROCESS = "status-process",
  STATUS_PENDING = "status-pending",
  STATUS_OMIT = "status-omit",
  STATUS_INITIALIZED = "status-initialized",
  STATUS_REJECTED = "status-rejected",
  CITY_ID = "citytId",
  MODEL = "processorType",
  RET_TOTAL_AMOUNT_DAVI = "retTotalAmountDavi",
  MERCHANT_ID = "merchantId",
  SOCIAL_REASON = "socialReason",
}
export const defaultColumnsSelectableBilling: string[] = ["invoiceId", "cycle"];
export const defaultRetentionColumnsSelectable: string[] = [
  ColumnsRetentionEnum.TAX_ID,
  ColumnsRetentionEnum.MERCHANT_NAME,
  ColumnsRetentionEnum.CYCLE,
  ColumnsRetentionEnum.RET_IVA,
  ColumnsRetentionEnum.RET_FUE,
  ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
];
export const defaultRetentionColumnsSelectableColombia: string[] = [
  ColumnsRetentionEnum.MERCHANT_NAME,
  ColumnsRetentionEnum.CYCLE,
  ColumnsRetentionEnum.CITY_ID,
  ColumnsRetentionEnum.MODEL,
  ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
  ColumnsRetentionEnum.RET_TOTAL_AMOUNT_DAVI,
];
export const columnsTableBilling: IColumnsTable[] = [
  {
    id: "taxId",
    label: "Tax ID",
    view: true,
    filter: false,
  },
  {
    id: "socialReason",
    label: "Razón social",
    view: true,
    filter: false,
  },
  {
    id: "merchantName",
    label: "Branch/Merchant",
    view: false,
    filter: true,
  },
  {
    id: ColumnsEnum.OWNER_NAME_DYNAMO,
    label: "Owner",
    view: false,
    filter: true,
  },

  {
    id: ColumnsEnum.CUSTOMER_NAME_DYNAMO,
    label: "Customer",
    view: false,
    filter: true,
  },
  {
    id: ColumnsEnum.IS_OCB_DYNAMO,
    label: "Jerarquía",
    view: false,
    filter: true,
  },
  {
    id: "invoiceId",
    label: "Nº de documento",
    view: true,
    filter: true,
    filterLabel: "Nº de documento",
  },
  {
    id: "cycle",
    label: "Ciclo",
    view: true,
    filter: true,
  },

  {
    id: "transactionType",
    label: "Tipo de Transacción",
    view: false,
    filter: true,
    filterLabel: "Tipo de Transacción",
  },

  {
    id: "created",
    label: "Fecha de creación",
    view: false,
    filter: true,
  },
  {
    id: "interbankAccountCode",
    label: ColumnsLabelsEnum.INTERBANK_ACCOUNT_CODE,
    view: false,
    filter: true,
  },
  {
    id: "amount",
    label: "Monto Total",
    view: true,
    filter: false,
  },
  {
    id: "kind",
    label: "Tipo documento",
    view: true,
    filter: false,
  },
];

export const fixedColumnsRetention: string[] = [
  ColumnsRetentionEnum.TAX_ID,
  ColumnsRetentionEnum.MERCHANT_NAME,
  ColumnsRetentionEnum.RET_IVA,
  ColumnsRetentionEnum.RET_FUE,
  ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
  ColumnsRetentionEnum.EDIT,
  ColumnsRetentionEnum.STATUS_PROCESS,
  ColumnsRetentionEnum.STATUS_PENDING,
  ColumnsRetentionEnum.STATUS_INITIALIZED,
  ColumnsRetentionEnum.STATUS_REJECTED,
];

export const fixedColumnsRetentionColombia: string[] = [
  ColumnsRetentionEnum.MERCHANT_NAME,
  ColumnsRetentionEnum.CYCLE,
  ColumnsRetentionEnum.CITY_ID,
  ColumnsRetentionEnum.MODEL,
  ColumnsRetentionEnum.RET_TOTAL_AMOUNT,
  ColumnsRetentionEnum.RET_TOTAL_AMOUNT_DAVI,
  ColumnsRetentionEnum.STATUS_PROCESS,
  ColumnsRetentionEnum.STATUS_PENDING,
  ColumnsRetentionEnum.STATUS_INITIALIZED,
  ColumnsRetentionEnum.STATUS_REJECTED,
];

export enum admittedColumnsEnum {
  admittedColumnRetention = 6,
}
