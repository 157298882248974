import React from "react";
import {
  createStyles,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

export interface PaginationTableProps {
  pages: number[];
  currentPages: number;
  total: number;
  limit: number;
  page: number;
  isMobile: boolean;
  notificationsPerPage: number;
  handleChangePage: (_event: React.ChangeEvent<unknown>, value: number) => void;
  handleChangePageSize: (value: number) => void;
  size?: "small" | "medium" | "large";
  siblingCount?: number;
}

const PaginationTable: React.FC<PaginationTableProps> = (
  props: PaginationTableProps
) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        padding: `${props.isMobile ? "2px 0px 4px 4px" : "8px 27px 8px 20px"}`,
        backgroundColor: "#FFFFFF",
      },
      buttonIconPagination: {
        textTransform: "none",
      },
      marginButton: {
        marginTop: -6,
        display: "flex",
      },
      typographyLabelPagination: {
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: 11,
      },
    })
  );
  const classes = useStyles();
  const lastElement =
    (props.page - 1) * props.notificationsPerPage + props.notificationsPerPage;
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={3}
            className={classes.marginButton}
            container
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Typography variant={"body2"}>Transacciones por página</Typography>
            <Select
              disableUnderline
              value={props.currentPages}
              onChange={(e) => {
                props.handleChangePageSize(e.target.value as number);
              }}
              style={{ paddingLeft: "24px" }}
            >
              {props.pages.map((page: number) => (
                <MenuItem key={page} value={page}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3} className={classes.marginButton}>
            <Typography
              color={"textSecondary"}
              variant={"body2"}
              className={classes.typographyLabelPagination}
            >
              {`${(props.page - 1) * props.notificationsPerPage + 1}-${
                lastElement > props.total ? props.total : lastElement
              } de ${props.total}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justify="flex-end"
            alignItems="flex-start"
            direction="row"
          >
            <Pagination
              count={Math.ceil(Number(props.total / props.limit))}
              onChange={props.handleChangePage}
              page={props.page}
              size={props.size}
              siblingCount={props.siblingCount}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default PaginationTable;
