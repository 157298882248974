/* istanbul ignore file */
export enum CountryEnum {
  BRAZIL = "Brazil",
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  ECUADOR = "Ecuador",
  MEXICO = "Mexico",
  PERU = "Peru",
  GUATEMALA = "Guatemala",
  PANAMA = "Panama",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  SALVADOR = "El Salvador",
  COSTA_RICA = "Costa Rica",
  COSTA_RICA_FLAG = "CostaRica",
  SALVADOR_FLAG = "ElSalvador",
}

export enum CountryNameEnum {
  BRASIL = "Brasil",
}

export const COUNTRY_TRANSLATOR: Record<string, string> = {
  [CountryEnum.BRAZIL]: CountryNameEnum.BRASIL,
};
