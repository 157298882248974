import React from "react";
import { get } from "lodash";
import { useRequestDispersionState } from "./state/useRequestDispersionState";
import { IUsePayoutsDispersionIndex } from "../../containers/PayoutsDispersionIndex/PayoutsDispersionIndex";
import { RequestDispersionComplete } from "../DispersionsTabSection/RequestDispersionComplete";
import { RequestDispersionError } from "../DispersionsTabSection/RequestDispersionError";
import {
  LabelsDispersion,
  StatusEnum,
} from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { payoutsDispersionStyle } from "./style/PayoutsDispersionStyle.style";
import { Collapse, IconButton } from "@material-ui/core";

export const RequestDispersion: React.FC<IUsePayoutsDispersionIndex> = (
  props: IUsePayoutsDispersionIndex
) => {
  const {
    fileStateDispersionResponse,
    showAlertOnDispersionsValidation,
    handleClose,
  } = useRequestDispersionState();
  const classes = payoutsDispersionStyle();

  switch (get(fileStateDispersionResponse, "status")) {
    case StatusEnum.COMPLETE:
      return <RequestDispersionComplete />;
    case StatusEnum.ERROR:
      return (
        <RequestDispersionError
          handleSetValue={props.handleSetValue}
          fileStateDispersionResponse={fileStateDispersionResponse}
        />
      );
    default:
      return (
        <>
          {showAlertOnDispersionsValidation && (
            <Collapse in={showAlertOnDispersionsValidation}>
              <Alert
                className={classes.errorAlert}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {LabelsDispersion.ERROR_PROCESSING_FILES}
              </Alert>
            </Collapse>
          )}
          <RequestDispersionComplete />
        </>
      );
  }
};
