import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { infoContentDataStyles as style } from "./InfoContentData.styles";
import { IconCircleInformation } from "@kushki/connect-ui";

export interface Info {
  tag: string;
  value: string;
  description?: string;
  tooltip?: boolean;
}

export interface InfoContentProps {
  data: Info[];
  isFromTable?: boolean;
}

export const InfoContentData: React.FC<InfoContentProps> = (
  props: InfoContentProps
) => {
  const { data, isFromTable } = props;

  return (
    <Grid container spacing={2} sx={style.gridContainer}>
      {data.length > 0 &&
        data.map((info: Info, index: number) => {
          return (
            <>
              <Grid item xs={4} md={4} key={index}>
                <Typography
                  sx={
                    isFromTable
                      ? style.texBillingTitleTable
                      : style.texBillingTitle
                  }
                >
                  {info.tag}:
                </Typography>
              </Grid>
              <Grid item xs={8} md={8} sx={style.flex}>
                <Typography
                  sx={
                    isFromTable
                      ? style.descriptionCardsTable
                      : style.descriptionCards
                  }
                >
                  {info.value}
                </Typography>
                {info.tooltip && (
                  <Tooltip
                    title={info.description ? info.description : ""}
                    placement="top"
                    arrow={true}
                    sx={style.flex}
                  >
                    <Box data-testid={"firstTooltip"} sx={style.flex}>
                      <IconCircleInformation
                        fontSize="inherit"
                        sx={{
                          height: "14.67px",
                          marginLeft: "10px",
                          width: "14.67px",
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Grid>
            </>
          );
        })}
    </Grid>
  );
};
