import React, { FC } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import RadioGroupComponent from "../RadioGroupComponent/RadioGroupComponent";
import { IBasicInfoProps } from "./BasicInfoComponent.interfaces";
import { basicInfoStyles } from "./BasicInfoComponent.styles";
import { InputNameEnum } from "../../shared/constants/processorConstants";

const BasicInfoComponent: FC<IBasicInfoProps> = (props: IBasicInfoProps) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent sx={basicInfoStyles.cardContent}>
          <Grid container sx={basicInfoStyles.modelContent}>
            <Grid container>
              <Grid item sx={basicInfoStyles.titleChip}>
                <Typography
                  key={`chiptitle_${Date.now().toString()}`}
                  sx={basicInfoStyles.title}
                >
                  {props.modelTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MaskSkeleton
                sx={basicInfoStyles.radioSkeleton}
                isLoading={props.isLoading}
              >
                <RadioGroupComponent
                  updateList={props.modelOptions.info.updateList}
                  name={props.modelOptions.info.name}
                  items={props.modelOptions.info.items}
                  control={props.control}
                  groupName={InputNameEnum.MERCHANT_MODEL}
                />
              </MaskSkeleton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container>
              <Grid item sx={basicInfoStyles.titleChip}>
                <Typography
                  key={`chiptitle_${Date.now().toString()}`}
                  sx={basicInfoStyles.title}
                >
                  {props.categoryTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MaskSkeleton
                sx={basicInfoStyles.radioSkeleton}
                isLoading={props.isLoading}
              >
                <RadioGroupComponent
                  updateList={props.categoryOptions.info.updateList}
                  name={props.categoryOptions.info.name}
                  items={props.categoryOptions.info.items}
                  control={props.control}
                  groupName={InputNameEnum.MERCHANT_CATEGORY}
                />
              </MaskSkeleton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default BasicInfoComponent;
