import React, { FC } from "react";
import { Box } from "@mui/material";
import { basicStateContainerStyles as styles } from "./BasicStateContainer.styles";
import { BasicContainer } from "../BasicContainer/BasicContainer";
import { IButtonOptions } from "../../components/FooterOptions/FooterOptions.interfaces";
import { get, isEmpty } from "lodash";
import { ModalLoader } from "@kushki/connect-ui";
import { ActionFooterEnum } from "../../shared/enums/ActionFooterEnum";
import { Routes } from "../../shared/constants/routes";
import { LayoutStepContainer } from "../LayoutStepContainer/LayoutStepContainer";
import { useBasicFormStateContainerGeneral } from "../../shared/utils/state/useBasicFormStateContainerGeneral";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { BasicContainerSkeleton } from "../../components/Skeletons/BasicContainerSkeleton/BasicContainerSkeleton";
import { isAvailableCountry } from "../../shared/services/CatalogPersonTypeByCountry";
import { CountryEnum } from "../../shared/countryEnum";

const BasicStateFormContainer: FC = () => {
  const {
    footerLabel,
    form,
    headerProps,
    countryElements,
    saveValues,
    continueButton,
    saveButton,
    textSave,
    modalOpen,
    countriesInfo: { country, constitutionalCountry },
    auxRules,
    showGenericInput,
    isEdit,
    isDisable,
    isLoadingBasicInformation,
  } = useBasicFormStateContainerGeneral(false);

  const secondaryButton: IButtonOptions = {
    isDisabled: isDisable
      ? isDisable
      : isEmpty(form.watch("rfc"))
      ? true
      : saveButton,
    isHidden: true,
    onAction: async () => {
      saveValues(ActionFooterEnum.SAVE);
    },
  };

  const primaryButton: IButtonOptions = {
    isDisabled: continueButton,
    isHidden: true,
    onAction: () => {
      saveValues(ActionFooterEnum.CONTINUE);
    },
  };
  const publicMerchantId: string = get(headerProps.nodeInfo, "merchantId", "");

  return (
    <>
      <LayoutStepContainer
        headerProps={{
          apiUrl: `${Routes.CREATE_NODE}?publicMerchantId=${publicMerchantId}`,
          country: isAvailableCountry(country) ? country : CountryEnum.generic,
          id: publicMerchantId,
          isEdit,
          subTitle: "País de operación: ",
          tabsInfo: headerProps.tabsNodeInfo,
          title: get(headerProps.nodeInfo, "generalInfo.name"),
        }}
        renderComponent={
          <Box sx={isEdit ? styles.boxContentEdit : styles.boxContent}>
            {isLoadingBasicInformation ? (
              <BasicContainerSkeleton />
            ) : (
              <>
                <BasicContainer
                  country={constitutionalCountry}
                  title={get(headerProps.nodeInfo, "generalInfo.clientType")}
                  form={form}
                  countryElements={countryElements}
                  auxRules={auxRules}
                  showGenericInput={showGenericInput}
                  isDisable={isDisable}
                />
                <ModalLoader
                  descriptionText={LOADER_DESCRIPTION}
                  titleText={LOADER_TITLE}
                  isOpen={modalOpen}
                />
              </>
            )}
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: textSave,
          label: footerLabel,
          primaryButton,
          secondaryButton,
        }}
      />
    </>
  );
};

export default BasicStateFormContainer;
