import React, { PropsWithChildren } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { IFabButtonProps } from "../../../../shared/infrastructure/interfaces/IFabButtonProps";
import { theme } from "@kushki/connect-ui";
import { get } from "lodash";
import { TitleSection } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";

export const FabButton: React.FC<IFabButtonProps> = (
  props: PropsWithChildren<IFabButtonProps>
) => {
  return (
    <>
      <Grid item sx={{ mt: "3px" }}>
        <IconButton
          sx={{ boxShadow: 3 }}
          onClick={props.handleOnClick}
          disabled={props.disabled || props.disableUnSelected}
          color={
            props.disabled || props.disableUnSelected ? "secondary" : "default"
          }
        >
          {props.icon}
        </IconButton>
      </Grid>
      <Grid item sx={{ mt: "3px" }}>
        <Typography
          variant={"h6"}
          color={
            get(props, "disabled", false)
              ? get(theme, "palette.error.dark")
              : get(props, "disableUnSelected", false)
              ? get(theme, "palette.neutral.grey6")
              : get(theme, "palette.primary.main")
          }
        >
          {get(props, "disabled", false)
            ? TitleSection.CONDITION_ALREADY_SELECTED
            : props.title}
        </Typography>
      </Grid>
    </>
  );
};
