import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MainPrefix } from "@shared/constants/thunkPrefixes";

import {
  getFileComments,
  saveActionAlarm,
  saveMassiveAlarm,
  searchAlarmByFilters,
  setAlarmAction,
} from "@store/thunks/alarms/alarms.thunk";
import {
  IAlarmsState,
  IDetailCardAlarm,
} from "@store/interfaces/Alarms.interfaces";

export const initialState: IAlarmsState = {
  alarms: { results: [], total: 0 },
  detailCardAlarms: { isOpen: false, selectedAlarm: null },
  isLoadingDownload: false,
  isLoadingFilesComment: false,
  isLoadingSetAlarmAction: false,
  isLoadingTable: false,
  isLoadingUpdateAlarm: false,
};

export const alarmsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(searchAlarmByFilters.fulfilled, (state, action) => {
        state.alarms = action.payload;
        state.isLoadingTable = false;
      })
      .addCase(searchAlarmByFilters.pending, (state) => {
        state.isLoadingTable = true;
      })
      .addCase(searchAlarmByFilters.rejected, (state) => {
        state.isLoadingTable = true;
      })
      .addCase(saveActionAlarm.fulfilled, (state) => {
        state.isLoadingUpdateAlarm = false;
      })
      .addCase(saveActionAlarm.pending, (state) => {
        state.isLoadingUpdateAlarm = true;
      })
      .addCase(saveActionAlarm.rejected, (state) => {
        state.isLoadingUpdateAlarm = false;
      })
      .addCase(saveMassiveAlarm.fulfilled, (state) => {
        state.isLoadingUpdateAlarm = false;
      })
      .addCase(saveMassiveAlarm.pending, (state) => {
        state.isLoadingUpdateAlarm = true;
      })
      .addCase(saveMassiveAlarm.rejected, (state) => {
        state.isLoadingUpdateAlarm = false;
      })
      .addCase(setAlarmAction.fulfilled, (state) => {
        state.isLoadingSetAlarmAction = false;
      })
      .addCase(setAlarmAction.pending, (state) => {
        state.isLoadingSetAlarmAction = true;
      })
      .addCase(setAlarmAction.rejected, (state) => {
        state.isLoadingSetAlarmAction = false;
      });

    builder
      .addCase(getFileComments.pending, (state) => {
        state.isLoadingFilesComment = true;
      })
      .addCase(getFileComments.rejected, (state) => {
        state.isLoadingFilesComment = false;
      })
      .addCase(getFileComments.fulfilled, (state) => {
        state.isLoadingFilesComment = false;
      });
  },
  initialState,
  name: MainPrefix.ALARMS,
  reducers: {
    setDetailCardAlarms: (state, action: PayloadAction<IDetailCardAlarm>) => {
      state.detailCardAlarms = action.payload;
    },
    setIsLoadingDownload: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDownload = action.payload;
    },
    setUploadedFiles: (state, { payload }) => {
      state.uploadedFiles = payload;
    },
  },
});

export default alarmsSlice.reducer;

export const { setUploadedFiles, setDetailCardAlarms, setIsLoadingDownload } =
  alarmsSlice.actions;
