import { format } from "date-fns-tz";
import { DateRange } from "@material-ui/pickers";

const formatDate = (trxDate: string, formatDate: string): string => {
  return format(new Date(trxDate.toString().replace("Z", "")), formatDate, {
    timeZone: "UTC",
  });
};
const nowDate = new Date();

const getDateFixed = (date: Date | null) =>
  date ? date.toDateString().slice(0, 16) : "";

const isValidDates = (
  dateRange: DateRange<Date>,
  dayBefore: Date,
  nowDate: Date
) =>
  getDateFixed(dateRange[0]) === getDateFixed(dayBefore) &&
  getDateFixed(dateRange[1]) === getDateFixed(nowDate);

const getDayBefore = () => {
  const now = new Date();
  now.setDate(now.getDate() - 2);
  return now;
};
const getMinDate = (): Date => {
  const now = new Date();
  now.setDate(now.getDate() - 180);
  return now;
};

export { formatDate, isValidDates, getDayBefore, getMinDate, nowDate };
