import React from "react";
import { getCategories, RuleDetail } from "../../RuleDetail/RuleDetail";
import { RuleListProps } from "../BusinessRules.interfaces";
import { StatusRuleEnum } from "../../../shared/enums/StatusRuleEnum";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { Accordion, IconMore } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { TagTextAccordion } from "./RuleList.data";
import { TagColorEnum } from "../../../shared/enums/TagColorEnum";
import { useRuleList } from "./state/useRuleList";
import { defaultTo } from "lodash";
import { PayoutRule } from "../../../../types/get_payouts_business_rules_response";

export const RuleList: React.FC<RuleListProps> = ({
  rules,
  payoutRules,
  deleteModalInfo,
}: RuleListProps) => {
  const { getDropDownList, transformConditionToCategory } = useRuleList(
    deleteModalInfo
  );
  const { businessConfiguration, isMassiveBranches } = useAppSelector(
    (store) => store.layout
  );

  return (
    <>
      {defaultTo(rules, [])
        .slice(0, 5)
        .map((ruleItem, index) => {
          return (
            <Box key={index} style={{ marginBottom: "1rem" }}>
              <Accordion
                text={ruleItem.rule.name}
                divider={true}
                isOpen={false}
                nesting={1}
                nestingLabel={""}
                tagText={getTagText(ruleItem.status, isMassiveBranches)}
                tagColor={getTagColor(ruleItem.status)}
                iconButton={
                  <IconMore color={ruleItem.isSent ? "disabled" : "primary"} />
                }
                listDropdown={
                  ruleItem.isSent ? undefined : getDropDownList(ruleItem.id)
                }
              >
                <RuleDetail
                  processorName={ruleItem.rule.processorName}
                  categories={getCategories(
                    ruleItem.rule,
                    businessConfiguration
                  )}
                ></RuleDetail>
              </Accordion>
            </Box>
          );
        })}
      {defaultTo(payoutRules, [])
        .slice(0, 5)
        .map((payoutRule: PayoutRule, index) => (
          <Box key={index} style={{ marginBottom: "1rem" }}>
            <Accordion
              text={defaultTo(payoutRule.name, "")}
              divider={true}
              isOpen={false}
              nesting={1}
              nestingLabel={""}
              tagText={getTagText(
                defaultTo(payoutRule.status, ""),
                isMassiveBranches
              )}
              tagColor={getTagColor(defaultTo(payoutRule.status, ""))}
              iconButton={
                <IconMore color={payoutRule.isSent ? "disabled" : "primary"} />
              }
              listDropdown={
                payoutRule.isSent
                  ? undefined
                  : getDropDownList(payoutRule.id, true)
              }
            >
              <RuleDetail
                processorName={payoutRule.processorName}
                categories={transformConditionToCategory(
                  defaultTo(payoutRule.conditions, [])
                )}
              />
            </Accordion>
          </Box>
        ))}
    </>
  );
};

const getTagText = (
  status: string,
  isMassiveBranches: boolean
): TagTextAccordion => {
  switch (status) {
    case StatusRuleEnum.ENABLED:
      return isMassiveBranches
        ? TagTextAccordion.Activated
        : TagTextAccordion.Enabled;
    case StatusRuleEnum.DISABLED:
      return TagTextAccordion.Disabled;
    case StatusRuleEnum.DRAFT:
      return TagTextAccordion.Draft;
    default:
      return TagTextAccordion.Unknown;
  }
};

const getTagColor = (status: string): TagColorEnum => {
  switch (status) {
    case StatusRuleEnum.ENABLED:
      return TagColorEnum.Success;
    case StatusRuleEnum.DISABLED:
      return TagColorEnum.Warning;
    case StatusRuleEnum.DRAFT:
      return TagColorEnum.Error;
    default:
      return TagColorEnum.Error;
  }
};
