import Breadcrumb from "../../components/commons/Breadcrumb/Breadcrumb";
import { get } from "lodash";
import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { EcuadorSection } from "../../components/EcuadorSection/EcuadorSection";
import { routes } from "../../shared/infrastructure/constants/routes";
import { MexicoSection } from "../../components/MexicoSection/MexicoSection";
import { CentralCountries } from "../../shared/infrastructure/constants/DeferredTableConstants";
import { CountriesEnum } from "../../shared/infrastructure/constants/CountriesEnum";
import { CentralAmericaSection } from "../../components/CentralAmericaSection/CentralAmericaSection";
import { BrazilSection } from "../../components/BrazilSection/BrazilSection";

const useStyles = makeStyles(() => ({
  h1: {
    fontSize: "40px",
    fontWeight: 200,
    lineHeight: "52px",
    marginBottom: "24px",
  },
}));

export const EditDeferred: React.FC = () => {
  const classes = useStyles();
  const merchantState = useSelector((state: IAppState) => state.merchant);

  const getComponent = (country: CountriesEnum) => {
    if (country === CountriesEnum.ECUADOR) return <EcuadorSection />;
    if (country === CountriesEnum.BRAZIL) return <BrazilSection />;
    if (CentralCountries.includes(country)) return <CentralAmericaSection />;

    return <MexicoSection />;
  };

  return (
    <>
      <Container maxWidth="lg">
        <Breadcrumb
          items={[
            { label: "Comercios", url: routes.MERCHANT_LIST },
            { label: get(merchantState, "name", ""), url: "/" },
          ]}
          lastItem={"Crear diferido"}
        />
        <Typography className={classes.h1} color={"primary"}>
          Configuración de diferidos
        </Typography>

        {getComponent(
          get(merchantState, "country", CountriesEnum.ECUADOR) as CountriesEnum
        )}
      </Container>
    </>
  );
};
