import { ModalContent } from "@kushki/connect-ui";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import React from "react";
import { CommercialExecutiveList } from "../../../types/commercial_executive_list";
import { ContactDataRequest } from "../../../types/contact_data_request";
import { SchemaValueName } from "../../../types/schema_value_name";
import { getContactDataRules } from "../../shared/constants/contact_data_form_constants";
import {
  ButtonTextMX,
  ContactDataLabelMX,
  ModalAddContactMX,
} from "../../shared/enum/ContactDataStateMX/ContactDataLabelMX";
import { CATEGORY_MERCHANT } from "../../shared/enum/ContactDataStateMX/MerchantCompanyInfo";
import {
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "../../shared/enum/modalTypeEnum";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import { ContactDataEditDelete } from "../ContactDataAreasComponent/ContactDataAreas.interfaces";
import SelectItemsField from "../FormControl/SelectItemsField/SelectItemsField";
import { ModalAddContactComponent } from "../ModalAddContact/ModalAddContactComponent";
import { contactDataCardStyles as styles } from "./ContactDataCard.styles";
import { ContactDataAreas } from "../ContactDataAreasComponent/ContactDataAreas";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";

export interface IContactDataCard {
  handleOpenModal: (isOpen: boolean) => void;
  handleCancelModal: (isOpen: boolean) => void;
  openModal: boolean;
  isEditing: boolean;
  commercialExecutive: string;
  form: UseFormReturn<ContactDataRequest>;
  commercialExecutiveList: CommercialExecutiveList[];
  merchantSize: string;
  contactDataModal: {
    name: string;
    email: string;
  };
  onSubmit: () => void;
  categoryAreas: ICategory[];
  dataCompleted: {
    isDataCompleted: boolean;
    handleIsDataCompleted: (validateDataCompleted: boolean) => void;
  };
  dataNoErrors: {
    isDataNoErrors: boolean;
    handleIsDataNoErrors: (validateDataNoErrors: boolean) => void;
  };
  nameValidation: {
    isNameValid: boolean;
    handleIsNameValid: (isNameValid: boolean) => void;
  };
  isAreasSelected: boolean;
  contactDataAreasProps: {
    anchorEl: HTMLButtonElement | null;
    openModalDelete: boolean;
    openPopover: boolean;
    valuesItem: {};
    handleOpenEdit: () => void;
    handleOpenModalDelete: (isOpen: boolean) => void;
    handleOpenPopover: (
      event: React.MouseEvent<HTMLButtonElement>,
      info: ContactDataEditDelete
    ) => void;
    handleClosePopover: () => void;
    handlerDeleteContactPerson: () => void;
    handlerOnCancelEdit: () => void;
  };
  isCentralizedBranch: boolean;
  isDisableAddPersonBtn: boolean;

  handleFormChange(
    name: string,
    value: string,
    selectedItems?: ICategory[]
  ): void;
}

export const ContactDataCard = (props: IContactDataCard) => {
  const { isCentralizedBranch } = props;

  return (
    <>
      <Card sx={styles.cardContent}>
        <CardContent>
          <Box sx={styles.boxContent2}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={styles.labelTitle}>
                  {ContactDataLabelMX.CONTACT_DATA_TITLE}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={styles.addContact}
                >
                  <Grid item xs={8}>
                    <Typography
                      variant={"subtitle2"}
                      color={"textPrimary"}
                      sx={styles.subTitleText}
                    >
                      {ContactDataLabelMX.CONTACT_DATA_SUBTITLE}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <ComponentSecurityWrapper
                        componentId={
                          ComponentIdsEnum.M_CLIENTS_ADD_CONTACT_PERSON
                        }
                      >
                        <Button
                          variant="outlined"
                          sx={styles.buttonStyle}
                          disabled={props.isDisableAddPersonBtn}
                          onClick={() => {
                            props.handleOpenModal(true);
                          }}
                          startIcon={<Add />}
                        >
                          {ButtonTextMX.ADD_CONTACT}
                        </Button>
                      </ComponentSecurityWrapper>
                      <ModalContent
                        titleText={
                          !props.isEditing
                            ? ModalAddContactMX.TITLE_MODAL_ADD
                            : ModalAddContactMX.TITLE_MODAL_EDIT
                        }
                        descriptionText={""}
                        isOpen={props.openModal}
                        onClose={() => {
                          !props.isEditing
                            ? props.handleCancelModal(false)
                            : props.contactDataAreasProps.handlerOnCancelEdit();
                        }}
                        buttonAction={props.onSubmit}
                        buttonText={
                          !props.isEditing
                            ? ButtonTextMX.ADD
                            : ButtonTextMX.SAVE
                        }
                        buttonType={ModalContentButtonTypeEnum.PRIMARY}
                        type={ModalContentTypeEnum.DEFAULT}
                        buttonPrimaryDisabled={isCentralizedBranch}
                      >
                        <ModalAddContactComponent
                          handleFormChange={props.handleFormChange}
                          contactData={props.contactDataModal}
                          isNameValid={props.nameValidation.isNameValid}
                          categoryAreas={props.categoryAreas}
                          isDataCompleted={props.dataCompleted.isDataCompleted}
                          handleIsDataNoErrors={
                            props.dataNoErrors.handleIsDataNoErrors
                          }
                          isAreasSelected={props.isAreasSelected}
                          isCentralizedBranch={isCentralizedBranch}
                          isEditing={props.isEditing}
                          form={props.form}
                        />
                      </ModalContent>
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={styles.addContactSubtitle}>
                  <Typography
                    variant={"subtitle2"}
                    color={"textPrimary"}
                    sx={styles.subTitleText2}
                  >
                    {ContactDataLabelMX.INDICATIONS}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={styles.divider} />
            <Grid container>
              <Grid item xs={12} sx={{ padding: 3 }}>
                <ContactDataAreas
                  categoryAreas={props.categoryAreas}
                  nameValidation={props.nameValidation}
                  openModal={props.openModal}
                  isAreasSelected={props.isAreasSelected}
                  dataCompleted={props.dataCompleted}
                  dataNoErrors={props.dataNoErrors}
                  contactDataModal={props.contactDataModal}
                  onSubmit={props.onSubmit}
                  isCentralizedBranch={isCentralizedBranch}
                  handleFormChange={props.handleFormChange}
                  contactDataAreasProps={props.contactDataAreasProps}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  color={"textPrimary"}
                  sx={styles.subTitleText3}
                >
                  {ContactDataLabelMX.EXECUTIVES_DATA}
                </Typography>
                <Grid container spacing={2} sx={styles.addContact}>
                  <Grid item xs={6} sx={styles.addContactSave}>
                    <SelectItemsField
                      rules={
                        getContactDataRules()[FieldsEnum.commercialExecutive]
                      }
                      disabled={isCentralizedBranch}
                      name={FieldsEnum.commercialExecutive}
                      control={props.form.control}
                      errors={props.form.formState.errors}
                      label={ContactDataLabelMX.KUSHKI_EXECUTIVES}
                      items={props.commercialExecutiveList.map(
                        (executiveData: CommercialExecutiveList) => (
                          <MenuItem
                            key={executiveData.id}
                            value={executiveData.id}
                          >
                            {executiveData.commercialName}
                          </MenuItem>
                        )
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SelectItemsField
                      rules={getContactDataRules()[FieldsEnum.merchantSize]}
                      disabled={isCentralizedBranch}
                      name={FieldsEnum.merchantSize}
                      control={props.form.control}
                      errors={props.form.formState.errors}
                      label={ContactDataLabelMX.MERCHANT_CATEGORIZATION}
                      items={CATEGORY_MERCHANT.map(
                        (categoryMerchantSize: SchemaValueName) => (
                          <MenuItem
                            key={categoryMerchantSize.value}
                            value={categoryMerchantSize.value}
                          >
                            {categoryMerchantSize.name}
                          </MenuItem>
                        )
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
