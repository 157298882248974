export enum CheckoutContainerLabels {
  MAIN_TITLE = "Express Checkout",
}

export enum LabelButton {
  BUTTON_RETURN = "Regresar",
  BUTTON_QR = "Generar QR",
  BUTTON_CONTINUE = "Continuar",
  BUTTON_INICIO = "Volver al inicio",
}

export enum FormSummaryLabels {
  TITLE = "Revisa la información antes de generar el QR",
  SUBTOTAL_IVA = "Subtotal IVA",
  SUBTOTAL_IVA0 = "Subtotal IVA0",
  TOTAL = "Total",
  DESCRIPTION = "Descripción:",
}

export enum SnackBarMessage {
  QR_GENERATION_ERROR = "Ha ocurrido un error al generar el QR. Por favor intenta nuevamente",
}
