export enum PaymentMethodsEnum {
  TRANSFER = "transfer",
  CARD = "card",
  CARD_PRESENT = "card_present",
  CASH = "cash",
}
export enum PaymentMethodsUpperEnum {
  TRANSFER = "TRANSFER",
  CARD = "CARD",
  CASH = "CASH",
}
export enum PaymentMethodsLabelEnum {
  TRANSFER = "Transferencia",
  CARD = "Tarjeta Online",
  CARD_PRESENT = "Tarjeta Presencial",
  CASH = "Efectivo",
}

export enum PaymentSubMethodsEnum {
  CARD_PRESENT = "CARD PRESENT",
  POS = "POS",
}

export const PaymentMethodLabel: Record<PaymentMethodsEnum, string> = {
  [PaymentMethodsEnum.TRANSFER]: PaymentMethodsLabelEnum.TRANSFER,
  [PaymentMethodsEnum.CARD]: PaymentMethodsLabelEnum.CARD,
  [PaymentMethodsEnum.CARD_PRESENT]: PaymentMethodsLabelEnum.CARD_PRESENT,
  [PaymentMethodsEnum.CASH]: PaymentMethodsLabelEnum.CASH,
};
