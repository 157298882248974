import React from "react";
import { TableSimple } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { IDataTableComponent } from "../../shared/infrastructure/interfaces/IGlobalTableComponent";
import { useDataTableComponentState } from "./state/useDataTableComponentState";
import { ComponentTextTitleEnum } from "../../shared/infrastructure/enums/ComponentTextTitleEnum";
import { get } from "lodash";
import { ItemsPerPage } from "../../shared/infrastructure/enums/DataTableEnum";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { PathsEnum } from "../../shared/infrastructure/enums/PathsEnum";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { ModalRejectRuleComponent } from "../ModalRejectRuleComponent/ModalRejectRuleComponent";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import noFilter from "../../assets/images/icons/noFilter.svg";

export enum TableBodyCellEnum {
  TITLE = "TITLE",
  TEXT = "TEXT",
  TAG = "TAG",
  OPTIONS = "OPTIONS",
  TEXT_ACTION = "TEXT_ACTION",
}

export const DataTableComponent: React.FC<IDataTableComponent> = (
  props: IDataTableComponent
) => {
  const {
    cells,
    rows,
    isLoading,
    isLoadingRules,
    confirmModalProps,
    setAlertSuccessMsg,
    setAlertErrorMsg,
    handleSelectedPage,
    selectedPage,
  } = useDataTableComponentState(props);

  return (
    <React.Fragment>
      <TableSimple
        footerPagination={{
          onItemsPerPageChange: (value: number) =>
            props.actions.handleItemsPerPage(value),
          isDisable: false,
          itemsPerPage: props.itemsPerPage,
          onPageClick: (value: number) => handleSelectedPage(value),
          selectedPage,
          paginationText: ComponentTextTitleEnum.FOOTER_PAGINATION_TITLE,
          textFieldSelector: ItemsPerPage,
          totalItems: get(props, PathsEnum.SEARCH_RULE_TOTAL, 0),
        }}
        header={{
          cells,
          headerProps: {
            isDisable: false,
          },
        }}
        messageBlock={{
          body: LabelEnum.EMPTY_TABLE_BODY,
          image: (
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              width="100%"
            >
              <Box maxHeight="305px" maxWidth="240.48px">
                <img src={noFilter} alt={"Empty result"} />
              </Box>
            </Box>
          ),
          title: LabelEnum.EMPTY_TABLE,
          typeMessage: 1,
        }}
        rows={rows}
        isLoading={isLoading || isLoadingRules}
        showPagination
      />
      <ModalContainer
        tabName={props.tabName}
        setAlertSuccessMsg={setAlertSuccessMsg}
        setAlertErrorMsg={setAlertErrorMsg}
        isLoadingRules={isLoadingRules!}
      />
      <ModalRejectRuleComponent
        tabName={props.tabName}
        setAlertSuccessMsg={setAlertSuccessMsg}
        setAlertErrorMsg={setAlertErrorMsg}
      />
      <ConfirmModal {...confirmModalProps} />
    </React.Fragment>
  );
};
